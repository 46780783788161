<div *ngIf="workspace && share" data-m-target="Add new user modal">
  <pp-share-edit-email-input
    *ngIf="!ppEdit"
    [(ppEmailList)]="ppEmails"
    (ppEmailListChange)="updateEmails($event)"
    [ppWorkspace]="workspace"
    (ppUpdate)="updateShare()"
    class="shareModalHeader--top"
  ></pp-share-edit-email-input>

  <div class="shareEdit__user" *ngIf="ppEdit">
    <span class="sw-icon sw-icon-name"></span>

    <span
      ppTooltip
      [ppTrimTooltip]="true"
      [ppTitle]="
        share.userId && users[share.userId].userName
          ? users[share.userId].userName
          : share.userId
          ? users[share.userId].email
          : share.userEmail
      "
      class="sw-icon-text userEmailText"
    >
      {{
        share.userId && users[share.userId].userName
          ? users[share.userId].userName
          : share.userId
          ? users[share.userId].email
          : share.userEmail
      }}
    </span>
  </div>

  <h6 class="shareModalHeader">
    {{ 'access_level' | ppTranslate }}
  </h6>

  <div class="shareModal__radio-row">
    <pp-radio
      [data-m-target]="'Access level ' + access.label + ' button'"
      *ngFor="let access of shareOptions"
      ppStyle="blue"
      [ppChecked]="access.value === share.shareOption"
      (ppOnValueChange)="onUserTypeSwitched(access)"
    >
      <label class="control-custom control-custom--radio">
        {{ access.label }}
      </label>
    </pp-radio>
  </div>

  <p [class.share__admin-description]="share?.shareOption === EUserRole.SITE_ADMIN">
    <ng-container *ngIf="share?.shareOption === EUserRole.SITE_ADMIN">
      {{ 'share_admin_description' | ppTranslate }}
    </ng-container>

    <ng-container *ngIf="share?.shareOption === EUserRole.NORMAL">
      {{ 'share_normal_description' | ppTranslate }}
    </ng-container>

    <ng-container *ngIf="share?.shareOption === EUserRole.LIMITED">
      {{ 'share_guest_description' | ppTranslate }}
    </ng-container>
  </p>

  <pp-share-edit-tags
    *ngIf="share.shareOption !== EUserRole.SITE_ADMIN"
    [(ppShare)]="share"
    [ppWorkspaceId]="workspaceId"
    (ppChange)="updateShare()"
    [ppTagError]="ppTagError"
  ></pp-share-edit-tags>

  <div class="shareButton__container">
    <pp-button
      data-m-target="Advanced access level button"
      ppId="toggleAdvancedUserModalBtn"
      ppStyle="text"
      class="toggleAdvancedButton"
      (ppAction)="toggleAdvanced()"
      *ngIf="(!share || share?.shareOption !== EUserRole.SITE_ADMIN) && !ppShowAdvanced"
    >
      <span class="advancedAccess__text">
        {{ 'advanced' | ppTranslate }}
        <i class="fa fa-caret-down settingsTable__icon icon-gap--right2x" aria-hidden="true"></i>
      </span>
    </pp-button>
  </div>

  <ng-container *ngIf="ppShowAdvanced && share.shareOption !== EUserRole.SITE_ADMIN">
    <pp-share-edit-activity
      [(ppShare)]="share"
      [ppWorkspaceId]="workspaceId"
      (ppChange)="updateShare()"
    ></pp-share-edit-activity>

    <pp-share-edit-site-plan
      [(ppShare)]="share"
      [ppWorkspaceId]="workspaceId"
      (ppChange)="updateShare()"
    ></pp-share-edit-site-plan>

    <pp-share-edit-export
      [(ppShare)]="share"
      [ppWorkspaceId]="workspaceId"
      (ppChange)="updateShare()"
    ></pp-share-edit-export>

    <pp-share-edit-comments
      [(ppShare)]="share"
      [ppWorkspaceId]="workspaceId"
      (ppChange)="updateShare()"
    ></pp-share-edit-comments>

    <pp-share-edit-advanced-access
      [(ppShare)]="share"
      [ppWorkspaceId]="workspaceId"
      (ppChange)="updateShare()"
    ></pp-share-edit-advanced-access>
  </ng-container>
</div>
