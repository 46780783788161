<pp-modal>
  <ng-container heading>
    {{ 'change_priority' | ppTranslate }}
  </ng-container>

  <ng-container body>
    <p class="modal__body-title">{{ 'priority' | ppTranslate }}</p>

    <pp-radio
      *ngFor="let priority of priorities"
      ppStyle="blue"
      [ppChecked]="priority.value === selectedPriority"
      (ppOnValueChange)="togglePriority(priority)"
    >
      <label class="control-custom control-custom--radio priority__wrapper">
        <pp-priority-button (ppAction)="togglePriority(priority)" [ppPriority]="priority.value">
        </pp-priority-button>
      </label>
    </pp-radio>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'save' | ppTranslate"
      [ppProcess]="processing"
      (ppCancel)="hideModal()"
      (ppConfirm)="save()"
      [ppDisabled]="!selectedPriority"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
