import { cloneDeep } from 'lodash';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { UserDropdownComponent } from 'src/app/project/modules/users/user-dropdown/user-dropdown.component';
import { UsersDropdownComponent } from '../../../users/users-dropdown/users-dropdown.component';

import { TAllUsers } from '@project/view-models';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { TUsersDropdownData } from '../../../users/users-dropdown/users-dropdown.model';
import { UsersService } from '../../../users/users.service';
import { WorkspaceService } from '../../../workspace/workspace.service';

@Component({
  selector: 'pp-point-assignees',
  templateUrl: './point-assignees.component.html',
  styleUrls: ['./point-assignees.component.scss'],
})
export class PointAssigneesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() ppSelectedAssignees: string[] = [];
  @Input() ppDisabled = false;
  @Input() ppWorkspaceId: string;
  @Input() ppBulkChanges = false;
  @Output() ppOnChange = new EventEmitter<string[]>();

  private readonly destroy$ = new Subject<void>();

  offline = false;
  selectedAssignees: string[] = [];
  addAssigneesDropdownVisible = false;
  allUsers: TAllUsers = {};
  EIconPath = EIconPath;

  private workspaces$: Observable<TWorkspacesById>;
  private offline$: Observable<boolean>;

  private users: string[];
  private unselectedAssignees: string[] = [];
  private workspaceId: string = null;
  private dropdown: TDropdown = this.dropdownService.getDropdown();

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
      offline: boolean;
    }>,
    private activeService: ActiveService,
    private usersService: UsersService,
    private workspaceService: WorkspaceService,
    private dropdownService: DropdownService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.offline$ = this.store.pipe(select(EStore.OFFLINE));
  }

  ngOnInit() {
    this.selectedAssignees = [];

    if (this.ppSelectedAssignees) {
      this.ppSelectedAssignees.forEach((assignee) => {
        this.selectedAssignees.push(assignee);
      });
    } else {
      this.selectedAssignees = [];
    }

    this.selectedAssignees = this.usersService.sortUsers(this.selectedAssignees);

    if (!this.ppWorkspaceId) {
      this.workspaceId = this.activeService.getActiveWorkspaceId();
    } else {
      this.workspaceId = this.ppWorkspaceId;
    }

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      const workspace = workspaces[this.workspaceId];

      if (workspace) {
        this.users = workspace.users;
      }
    });

    this.offline$.pipe(takeUntil(this.destroy$)).subscribe((offline) => {
      this.offline = offline;

      if (offline) {
        this.dropdownService.hideDropdown();
        this.hideAssigneesDropdown(false);
      }
    });

    this.allUsers = this.usersService.getUsers();

    this.updateUnselectedAssignees();
  }

  ngOnChanges() {
    this.selectedAssignees = [];
    this.allUsers = this.usersService.getUsers();

    if (!this.ppWorkspaceId) {
      this.workspaceId = this.activeService.getActiveWorkspaceId();
    } else {
      this.workspaceId = this.ppWorkspaceId;
    }

    this.users = this.workspaceService.getWorkspaces()[this.workspaceId].users;

    if (this.ppSelectedAssignees) {
      this.ppSelectedAssignees.forEach((assignee) => {
        this.selectedAssignees.push(assignee);
      });
    }

    this.selectedAssignees = this.usersService.sortUsers(this.selectedAssignees);

    this.updateUnselectedAssignees();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  showAssigneesDropdown(): void {
    const buttonId = this.ppBulkChanges ? 'addAssigneesButtonBulk' : 'addAssigneesButton';

    if (!this.offline) {
      if (this.dropdown.visible && this.dropdown.buttonId === buttonId) {
        this.dropdownService.hideDropdown();
        this.hideAssigneesDropdown(false);
      } else {
        this.addAssigneesDropdownVisible = true;

        this.dropdownService.setData<TUsersDropdownData>({
          unselectedUsers: this.unselectedAssignees,
          allUsers: this.users,
          disabled: this.ppDisabled,
          type: 'assignees',
          showToggle: false,
        });

        this.dropdownService.showDropdown(buttonId, UsersDropdownComponent, {
          callback: (id) => this.selectAssignee(id),
          onClose: () => {
            this.hideAssigneesDropdown(true);
          },
          suppressScrollbar: true,
        });
      }
    }
  }

  hideAssigneesDropdown(update: boolean = false): void {
    this.addAssigneesDropdownVisible = false;

    if (update) {
      this.ppOnChange.emit(this.selectedAssignees);
    }
  }

  selectAssignee(userId: string): void {
    this.selectedAssignees = cloneDeep(this.selectedAssignees);

    if (userId) {
      this.selectedAssignees.includes(userId)
        ? this.removeSelectedAssignee(userId)
        : this.selectedAssignees.push(userId);

      this.selectedAssignees = this.usersService.sortUsers(this.selectedAssignees);
    } else {
      this.selectedAssignees = [];
    }

    this.updateUnselectedAssignees();
  }

  removeSelectedAssignee(id: string): void {
    const index = this.selectedAssignees.findIndex((assignee) => assignee === id);

    this.selectedAssignees = cloneDeep(this.selectedAssignees);
    this.selectedAssignees.splice(index, 1);
    this.selectedAssignees = this.usersService.sortUsers(this.selectedAssignees);

    this.updateUnselectedAssignees();

    if (this.ppOnChange) {
      this.ppOnChange.emit(this.selectedAssignees);
    }
  }

  updateUnselectedAssignees(): void {
    const users = this.usersService.getUsers();

    if (this.users) {
      this.unselectedAssignees = this.users.filter((userId) => {
        if (!users[userId]?.verified) {
          return false;
        }

        for (let i = 0; i < this.selectedAssignees.length; i++) {
          if (this.selectedAssignees[i] === userId) {
            return false;
          }
        }

        return true;
      });
    }
  }

  toggleUserDropdown(buttonId: string, userId: string): void {
    if (!this.offline) {
      if (this.dropdown.visible && this.dropdown.buttonId === buttonId) {
        this.dropdownService.hideDropdown();
      } else {
        this.dropdownService.setData({
          user: userId,
          disabled: this.ppDisabled,
          message: 'Remove from point',
        });

        this.dropdownService.showDropdown(buttonId, UserDropdownComponent, {
          callback: (id) => this.removeSelectedAssignee(id),
        });
      }
    }
  }
}
