import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TCurrencySymbol } from 'src/app/project/modules/custom-fields/currencies/currencies.consts';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { TAllCustomFields } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { TCustomFieldFilter } from 'src/app/project/modules/filters/site-filter.model';

@Component({
  selector: 'pp-filter-field-formula',
  templateUrl: './filter-field-formula.component.html',
  styleUrls: ['../../../site-filter-dropdown/site-filter-dropdown.component.scss'],
})
export class FilterFieldFormulaComponent implements OnChanges {
  @Input() ppCustomFields: TAllCustomFields;
  @Input() ppField: TCustomFieldFilter;
  @Output() ppApply = new EventEmitter();

  unit: string = '';
  outputType: ECustomFieldType;
  leftUnit: boolean;

  ngOnChanges(): void {
    this.getCurrency();
  }

  updateCostField(field: TCustomFieldFilter): void {
    field.excluded = false;

    this.applyFilters();
  }

  applyFilters(): void {
    this.ppApply.emit();
  }

  private getCurrency(): void {
    const field = this.ppCustomFields[this.ppField.workspaceId][this.ppField.id];

    switch (field.outputType) {
      case ECustomFieldType.NUMBERS:
        this.unit = field.unit;
        this.leftUnit = false;
        break;
      case ECustomFieldType.PERCENTAGE:
        this.unit = '%';
        this.leftUnit = false;

        break;
      case ECustomFieldType.COST:
        this.unit = field.currencyCode + ' ' + field.currencySymbol;
        this.leftUnit = true;

        break;
    }
  }
}
