<section class="activeViewSection">
  <pp-active-view-preview
    [ppHideText]="ppHideText"
    [ppWorkspaceId]="ppWorkspaceId"
  ></pp-active-view-preview>

  <pp-save-to-view-button
    [ppWorkspaceId]="ppWorkspaceId"
    (ppSaveToCurrentView)="saveToCurrentView()"
    [ppProcessing]="ppProcessing"
  ></pp-save-to-view-button>
</section>
