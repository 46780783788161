import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import {
  TAdvancedFilterAssignee,
  TAdvancedFilterCreatedBy,
  TAdvancedFilterSingle,
  TFilterAssigneeOptions,
  TFilterCreatedByOptions,
} from '../../../../models/advanced-filter.model';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';
import { getAdvancedCreatedByOptions } from './advanced-filter-created-by-utils';
import { Observable, Subject, takeUntil } from 'rxjs';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { Store, select } from '@ngrx/store';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { getAdvancedAssigneeOptions } from './advanced-filter-assignees-utils';
import { TSelectOption } from 'src/app/project/components/input/select/select.model';

@Component({
  selector: 'pp-advanced-filter-users',
  templateUrl: './advanced-filter-users.component.html',
  styleUrls: ['./advanced-filter-users.component.scss'],
})
export class AdvancedFilterUserComponent implements OnChanges, OnDestroy {
  @Input() ppFilter: TAdvancedFilterSingle;
  @Input() ppIndex: number;
  @Input() ppType: 'assignees' | 'created_by';
  @Output() ppFilterChange = new EventEmitter<TAdvancedFilterSingle>();

  value: string[];
  option: TFilterCreatedByOptions | TFilterAssigneeOptions;
  filter: TAdvancedFilterCreatedBy | TAdvancedFilterAssignee;

  advancedFilterUserOptions: TSelectOption[];
  EAdvancedFilterOptions = EAdvancedFilterOptions;
  private workspaces$ = new Observable<TWorkspacesById>();
  private readonly destroy$ = new Subject<void>();
  workspaceUsers: string[];

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
    }>,
    private activeService: ActiveService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      const workspaceId = this.activeService.getActiveWorkspaceId();
      const workspace = workspaces[workspaceId];

      if (workspace) {
        this.workspaceUsers = workspace.users;
      }
    });
  }

  ngOnChanges(): void {
    this.advancedFilterUserOptions =
      this.ppType === 'created_by' ? getAdvancedCreatedByOptions() : getAdvancedAssigneeOptions();

    if (!this.ppFilter.value) {
      this.ppFilter.value = [];
    }

    this.filter = this.ppFilter as TAdvancedFilterCreatedBy | TAdvancedFilterAssignee;

    this.value = this.filter.value;
    this.option = this.filter.option;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  changeFieldCondition(newCondition: TFilterCreatedByOptions | TFilterAssigneeOptions): void {
    if (
      (newCondition === EAdvancedFilterOptions.IS ||
        newCondition === EAdvancedFilterOptions.IS_NOT) &&
      this.filter.value.length
    ) {
      this.filter.value.length = 1;
    }

    this.filter.option = newCondition;
    this.option = newCondition;

    this.applyChanges();
  }

  applyChanges(): void {
    this.ppFilterChange.emit(this.filter);
  }

  addUserId(userId: string): void {
    if (
      this.option === EAdvancedFilterOptions.IS_ANY_OF ||
      this.option === EAdvancedFilterOptions.IS_NONE_OF ||
      this.option === EAdvancedFilterOptions.IS_ALL_OF
    ) {
      this.filter.value.push(userId);
    } else {
      this.filter.value = [userId];
    }

    this.applyChanges();
  }

  removeUserId(userId: string): void {
    this.filter.value = this.filter.value.filter((id) => id !== userId);
    this.applyChanges();
  }

  clearUsers(): void {
    this.filter.value = [];
    this.applyChanges();
  }
}
