import { Observable, of } from 'rxjs';
import { TSavedView } from '../../saved-views/models/saved-view.model';
import { TSavedViews } from '../../saved-views/models/saved-views.model';
import { SavedViewsService } from '../../saved-views/saved-views.service';

export function getSavedViewObservable({
  savedViewsResponse,
  savedViewsService,
  customFields,
  workspaceId,
}: {
  savedViewsResponse: TSavedViews;
  savedViewsService: SavedViewsService;
  customFields: string[];
  workspaceId: string;
}): Observable<TSavedView> {
  const personalDefaultViewId = savedViewsResponse?.personal?.defaultViewId;
  const sharedDefaultViewId = savedViewsResponse?.shared?.sharedDefaultViewId;

  if (personalDefaultViewId && checkIfViewExists(personalDefaultViewId, savedViewsResponse)) {
    return savedViewsService.fetchView({
      workspaceId,
      viewId: personalDefaultViewId,
      customFields,
    });
  }

  if (sharedDefaultViewId && checkIfViewExists(sharedDefaultViewId, savedViewsResponse)) {
    return savedViewsService.fetchView({
      workspaceId,
      viewId: sharedDefaultViewId,
      customFields,
    });
  }

  return of(null);
}

function checkIfViewExists(viewId: string, savedViewsResponse: TSavedViews) {
  return (
    checkIfDefaultViewExistsInPersonalViews(viewId, savedViewsResponse) ||
    checkIfDefaultViewExistsInSharedViews(viewId, savedViewsResponse)
  );
}

function checkIfDefaultViewExistsInSharedViews(
  viewId: string,
  savedViewsResponse: TSavedViews,
): boolean {
  return savedViewsResponse.shared?.views.some((view) => view.id === viewId);
}

function checkIfDefaultViewExistsInPersonalViews(viewId: string, savedViewsResponse: TSavedViews) {
  return savedViewsResponse.personal?.views.some((view) => view.id === viewId);
}
