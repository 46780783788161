<div class="selectDropdown" [ngStyle]="{ width: isMobile ? 'unset' : dropdownWidth }">
  <pp-hide-dropdown (ppHideDropdown)="hideDropdown()"></pp-hide-dropdown>

  <pp-search-box
    [ppPlaceholder]="'filter_items' | ppTranslate"
    (ppAction)="setKeyword($event)"
    [ppFocusOnInit]="true"
    ppSize="wide"
    *ngIf="!noSearchBar"
    class="dropdown__searchBar"
  ></pp-search-box>

  <ul class="dropdownWrapper">
    <li
      *ngFor="let item of filteredItems"
      (click)="selectItem(item)"
      class="dropdownItem"
      [class.dropdownItem--selected]="selectedItem?.value === item.value"
    >
      <img
        ppImage
        class="select__icon icon-gap--right2x"
        [src]="item.pngIcon"
        *ngIf="item.pngIcon"
      />
      <pp-icon *ngIf="item.iconSrc" [ppSrc]="item.iconSrc" ppClass="setSize14"></pp-icon>
      <span
        ppTooltip
        [ppTitle]="item.label"
        [ppTrimTooltip]="true"
        [ppOptions]="{ placement: 'right' }"
        class="select__label"
      >
        {{ item.label }}
      </span>
    </li>
  </ul>
</div>
