import { Component } from '@angular/core';

import { Store } from '@ngrx/store';
import { DeselectWorkspacePoints } from 'src/app/project/modules/points/points.actions';

import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { PointHalfModalService } from 'src/app/project/modules/points/point-half-modal/point-half-modal.service';
import { PointsFetchingService } from 'src/app/project/modules/points/points-fetching.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { Modal, ModalService } from '../../../../components/modal/modal.service';
import { PromptService } from '../../../../components/prompt/prompt.service';
import { PointsService } from '../../../points/points.service';

import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';

import { of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';

@Component({
  selector: 'pp-points-delete-modal',
  templateUrl: './points-delete-modal.component.html',
  styleUrls: ['./points-delete-modal.component.scss'],
})
export class PointsDeleteModalComponent {
  modal: Modal = this.modalService.getModal();
  selectedPoints = this.pointsService.getSelectedPoints();

  processing = false;

  constructor(
    private store: Store,
    private modalService: ModalService,
    private pointsService: PointsService,
    private promptService: PromptService,
    private sitePointFilterService: SitePointFilterService,
    private activeService: ActiveService,
    private pointsFetchingService: PointsFetchingService,
    private pointHalfModalService: PointHalfModalService,
    private translationPipe: TranslationPipe,
  ) {}

  hideModal(): void {
    this.modalService.hideModal();
  }

  deletePoints(): void {
    if (this.processing) {
      return;
    }

    const workspaceId = this.activeService.getActiveWorkspaceId();
    const selectedPointIds = this.selectedPoints.map((point) => point._id);
    const numberOfPoints = selectedPointIds.length;

    logEventInGTAG(EGoogleEventSite.SITE__BULK_CHANGES__DELETE, {
      event_category: EGoogleEventCategory.SITE,
      amount: numberOfPoints,
    });

    this.processing = true;

    this.pointsFetchingService
      .deletePoints(selectedPointIds)
      .pipe(
        tap(() => {
          const promptText = this.translationPipe.transform('prompt_points_delete');
          const openedPointId = this.pointHalfModalService.getOpenedPointId();

          if (selectedPointIds.includes(openedPointId)) {
            this.pointHalfModalService.hideModal();
          }

          this.promptService.showSuccess(promptText);
          this.store.dispatch(new DeselectWorkspacePoints({ workspaceId }));
          this.sitePointFilterService.filterPoints();
          this.hideModal();
        }),
        catchError(() => {
          const promptText = this.translationPipe.transform('prompt_points_delete_error');

          this.promptService.showError(promptText);

          return of();
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }
}
