import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { IconModule } from '../icon/icon-module';
import { WarningBoxComponent } from './warning-box.component';

@NgModule({
  declarations: [WarningBoxComponent],
  imports: [CommonModule, PipesModule, IconModule],
  exports: [WarningBoxComponent],
})
export class WarningBoxModule {}
