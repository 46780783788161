import { TPoint } from '../../../points/points.model';
import { TColumn } from '../columns/column.model';
import { getValues } from './get-values';

export function sortNumbers(
  _a: TPoint | string,
  _b: TPoint | string,
  sortColumns: Partial<TColumn>[],
  index: number,
  { property = null }: { property?: string } = {},
): number {
  let values = {
    a: _a,
    b: _b,
  };

  if (property) {
    values = getValues(_a, _b, property);
  }

  if (values.a && !values.b) {
    return -1;
  } else if (values.b && !values.a) {
    return 1;
  } else if (+values.a < +values.b) {
    return sortColumns[index].sortOrder === 'ASC' ? -1 : 1;
  } else if (+values.a > +values.b) {
    return sortColumns[index].sortOrder === 'ASC' ? 1 : -1;
  }

  return 1;
}
