<pp-action-bar></pp-action-bar>

<pp-not-authorized-layout [class.loginScreen]="hubspotMarketingPageUrl">
  <section class="authBox">
    <header>
      <h4>{{ 'login' | ppTranslate }}</h4>
      <p class="authBox__copy">{{ 'please_enter_your_details' | ppTranslate }}.</p>
    </header>

    <form class="authBox__inputs-container" novalidate>
      <div class="authBox__input-wrapper">
        <label class="sr-only" for="signin-email"> {{ 'email' | ppTranslate }} </label>
        <pp-icon
          [ppSrc]="EIconPath.ICON_MISC_MAIL_18"
          alt="outline of a letter"
          ppColor="grey-600"
          ppClass="setSize18"
        ></pp-icon>

        <input
          id="signin-email"
          class="authBox__input"
          name="signinEmail"
          autocomplete="email"
          type="email"
          [placeholder]="'enter_your_email' | ppTranslate"
          [(ngModel)]="email"
          (keydown.enter)="onKeyDownEnter($event)"
          autofocus
          required
        />
      </div>
      <div class="authBox__input-wrapper">
        <label class="sr-only" for="signin-password">
          {{ 'enter_your_password' | ppTranslate }}
        </label>
        <pp-icon
          [ppSrc]="EIconPath.ICON_MISC_PASSWORD_18"
          alt="outline of a lock"
          ppColor="grey-600"
          ppClass="setSize18"
        ></pp-icon>

        <pp-password-visibility
          (ppVisibilityChange)="passInputType = $event"
        ></pp-password-visibility>

        <input
          id="signin-password"
          class="authBox__input"
          name="signin-password"
          autocomplete="current-password"
          [type]="passInputType"
          [placeholder]="'enter_your_password' | ppTranslate"
          [(ngModel)]="password"
          (keydown.enter)="onKeyDownEnter($event)"
          required
        />
      </div>
    </form>

    <footer class="authBox__footer--gap-l">
      <div class="login__row">
        <div class="form-group login__remember" *ngIf="!(isiPhone || isiPod || isiPad)">
          <pp-checkbox
            [ppChecked]="rememberMe"
            ppType="solid"
            (ppOnValueChange)="onRememberMeChecked()"
          ></pp-checkbox>
          <label class="authBox__label">
            {{ 'remember_me' | ppTranslate }}
          </label>
        </div>

        <button class="login__password-button" (click)="forgotPassword()">
          {{ 'forgot_password' | ppTranslate }}
        </button>
      </div>

      <pp-button
        ppStyle="primary"
        ppSize="large"
        ppId="logInBtn"
        [ppProcess]="processing"
        (ppAction)="login()"
      >
        {{ 'sign_in' | ppTranslate }}
      </pp-button>
    </footer>
  </section>
</pp-not-authorized-layout>

<div class="media-bridge-container" *ngIf="hubspotMarketingPageUrl">
  <iframe
    [src]="hubspotMarketingPageUrl"
    width="100%"
    height="100%"
    frameborder="0"
    allowfullscreen
  ></iframe>
</div>
