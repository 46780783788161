<pp-modal>
  <ng-container heading>
    {{ 'change_flag_status' | ppTranslate }}
  </ng-container>

  <ng-container body>
    <p class="flag__title">{{ 'red_flags' | ppTranslate }}</p>

    <div class="flag__wrapper">
      <div class="flag__radio-wrapper">
        <pp-radio [ppChecked]="selectedFlag" (ppOnValueChange)="toggleFlag(true)" ppStyle="red">
          <pp-icon
            [ppSrc]="EIconPath.ICON_ACTIVITIES_RED_FLAG_ACTIVATED_14"
            [ppDisplayContents]="true"
          ></pp-icon>
          {{ 'flagged' | ppTranslate }}
        </pp-radio>
      </div>
      <div class="flag__radio-wrapper">
        <pp-radio [ppChecked]="!selectedFlag" (ppOnValueChange)="toggleFlag(false)">
          <pp-icon
            [ppSrc]="EIconPath.ICON_ACTIVITIES_RED_FLAG_14"
            [ppDisplayContents]="true"
          ></pp-icon>
          {{ 'unflagged' | ppTranslate }}
        </pp-radio>
      </div>
    </div>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'save' | ppTranslate"
      (ppCancel)="hideModal()"
      (ppConfirm)="save()"
      [ppProcess]="processing"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
