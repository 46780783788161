import { TAccount } from '../../../account/account.model';
import { TFilters } from '../../../filters/site-filter.model';
import { TWorkspacesById } from '../../../workspace/workspace.model';

export function addWorkspaceToSiteFilter(
  account: TAccount,
  filters: TFilters,
  index: number,
  workspaces: TWorkspacesById,
): void {
  account.workspaces.forEach((workspaceId) => {
    const workspace = workspaces[workspaceId];

    const existingWorkspace = filters.sites[index].workspaces.find(
      (savedWorkspace) => savedWorkspace.id === workspace.workspaceId,
    );

    if (!existingWorkspace) {
      filters.sites[index].workspaces.push({
        name: workspace.siteName,
        id: workspace.workspaceId,
        value: true,
        appliedValue: true,
        default: true,
      });
    }
  });
}
