<div class="outputType__header">
  {{ 'output_type' | ppTranslate }}
</div>

<pp-select
  [ppItems]="outputTypes"
  [ppSelectedItem]="outputTypes[outputTypeIndex]"
  (ppSelectItem)="selectItem($event)"
  [ppNoSearchBar]="true"
  require
>
  <ng-container selected>
    <span class="formulaOutputPicker__option" *ngIf="outputTypeIndex !== -1">
      {{ outputTypes[outputTypeIndex].label }}
    </span>

    <span class="formulaOutputPicker__placeholder" *ngIf="outputTypeIndex === -1">
      {{ 'choose_formula_output_type' | ppTranslate }}
    </span>
  </ng-container>
</pp-select>
