import {
  DAY_IN_MILLISECONDS,
  HOUR_IN_MILLISECONDS,
  MINUTE_IN_MILLISECONDS,
} from 'src/app/core/helpers/date/date.consts';

export function transformTimeCF(value: number | string, showHoursOnly: boolean | string): string {
  if (!value || value === null || value === '0') {
    return null;
  }

  let mergedTime = '';

  if (+value < 0) {
    mergedTime += '-';
  }

  value = +value;

  const displayDays = Math.floor(value / DAY_IN_MILLISECONDS);

  value = value - displayDays * DAY_IN_MILLISECONDS;

  let displayHours = Math.floor(value / HOUR_IN_MILLISECONDS);

  value = value - displayHours * HOUR_IN_MILLISECONDS;

  const displayMinutes = Math.floor(value / MINUTE_IN_MILLISECONDS);

  if (!showHoursOnly) {
    mergedTime += displayDays + 'd / ';

    if (displayHours < 10) {
      mergedTime += '0';
    }

    mergedTime += displayHours + ':';

    if (displayMinutes < 10) {
      mergedTime += '0';
    }

    mergedTime += displayMinutes;
  } else {
    displayHours += displayDays * 24;

    mergedTime = displayHours.toString();

    if (displayHours < 10) {
      mergedTime = '0' + mergedTime;
    }

    mergedTime += ':';

    if (displayMinutes < 10) {
      mergedTime += '0';
    }

    mergedTime += displayMinutes;
  }

  return mergedTime;
}

export function transformSplitTimeCF(value: string | number): number[] {
  if (!value || value === null) {
    value = '0';
  }

  value = +value;

  const displayDays = Math.floor(value / DAY_IN_MILLISECONDS);

  value = value - displayDays * DAY_IN_MILLISECONDS;

  const displayHours = Math.floor(value / HOUR_IN_MILLISECONDS);

  value = value - displayHours * HOUR_IN_MILLISECONDS;

  const displayMinutes = Math.floor(value / MINUTE_IN_MILLISECONDS);

  return [displayMinutes, displayHours, displayDays];
}
