import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  SetActiveAccountId,
  SetActivePointId,
  SetActiveUserId,
  SetActiveWorkspaceId,
} from './active.actions';

import { EStore } from '../../shared/enums/store.enum';
import { TActive } from './active.model';
import { SET_ACTIVE } from './active.store';

@Injectable({
  providedIn: 'root',
})
export class ActiveService {
  private active$: Observable<TActive>;

  private active: TActive = {
    userId: null,
    accountId: null,
    workspaceId: null,
    _id: null,
  };

  constructor(private store: Store<{ active: TActive }>) {
    this.active$ = this.store.pipe(select(EStore.ACTIVE));

    this.active$.subscribe((active) => {
      this.active = active;

      SET_ACTIVE(this.active);

      if (typeof this.active === 'undefined') {
        this.active = {
          userId: null,
          accountId: null,
          workspaceId: null,
          _id: null,
        };
      }
    });
  }

  getActiveAccountId(): string {
    return this.active?.accountId;
  }

  getActiveWorkspaceId(): string {
    return this.active?.workspaceId;
  }

  getActivePointId(): string {
    return this.active?._id;
  }

  clearActiveUser(): void {
    this.store.dispatch(new SetActiveAccountId(null));
    this.store.dispatch(new SetActiveUserId(null));
  }

  clearActiveStore(): void {
    this.store.dispatch(new SetActivePointId(null));
    this.store.dispatch(new SetActiveWorkspaceId(null));
    this.store.dispatch(new SetActiveAccountId(null));
    this.store.dispatch(new SetActiveUserId(null));
  }

  setActiveWorkspaceId(workspaceId: string): void {
    this.store.dispatch(new SetActiveWorkspaceId(workspaceId));
  }
}
