import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  ViewChild,
} from '@angular/core';

import { KEY_ESC, SortingService, TAnyFunction, clickOutside, pressKey, uuid } from '@core/helpers';
import { WindowService } from '@core/services';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { createCustomScroller } from 'src/app/core/helpers/create-custom-scroller';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { SelectTagDropdownComponent } from 'src/app/project/components/input/select-tag/select-dropdown/select-tag-dropdown.component';
import { TSelectTagDropdownData } from 'src/app/project/components/input/select-tag/select-dropdown/select-tag-dropdown.model';
import { getWidthNumber } from 'src/app/project/components/input/select/utils/get-width-number';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { WorkspaceService } from '../../workspace/workspace.service';

export type TTagSelectOptions = {
  disabled?: boolean;
  showIcon?: boolean;
  hasError?: boolean;
  noTagRemovedDebounceTime?: boolean;
  tagsLimited?: boolean;
  singleSelectOnly?: boolean;
  addTagScrollCallback?: boolean;
  singleLineInput?: boolean;
  showSaveIndicator?: boolean;
  showClearBtn?: boolean;
  showUpdateIndicator?: boolean;
  combinedTags?: boolean;
  noBorder?: boolean;
  width?: string;
};

@Component({
  selector: 'pp-tag-select',
  templateUrl: './tag-select.component.html',
  styleUrls: ['./tag-select.component.scss'],
})
export class TagSelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() ppPointId: string = null;
  @Input() ppSelectedTags: string[] = [];
  @Input() ppReadOnlyTags: string[] = [];
  @Input() ppWorkspaceId: string;
  @Input() ppClass: string;
  @Input() ppOptions: TTagSelectOptions = {};
  @Input() ppPlaceholder: string;
  @Input() ppNoneAccess: boolean;
  @Output() ppOnChange = new EventEmitter<string[]>();
  @Output() ppOnClose = new EventEmitter<string[]>();

  @ViewChild('selectComponent', { static: true }) element: ElementRef;
  @ViewChild('selectContent', { static: true }) contentElement: ElementRef;

  selectedTags: string[] = [];
  EIconPath = EIconPath;
  placeholder: string;
  buttonId = uuid();
  dropdown: TDropdown = this.dropdownService.getDropdown();
  focused = false;

  private unselectedTags: string[] = [];
  private allTags: string[] = [];
  private hideButtons = true;
  private readonly destroy$ = new Subject<void>();
  private readonly tagRemoved$ = new Subject<void>();
  private workspace: TWorkspace = null;
  private closeOnEscRef: TAnyFunction;
  private clickOutsideRef: TAnyFunction;
  private window = this.windowService.getGlobalObject();

  constructor(
    private workspaceService: WorkspaceService,
    private sortingService: SortingService,
    private translationPipe: TranslationPipe,
    private windowService: WindowService,
    private dropdownService: DropdownService,
  ) {}

  ngOnInit() {
    this.setSelectedTags();
    this.setAllTags();
    this.setUnselectedTags();
    this.subscribeToTagRemoved();
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    this.setAllTags();
    this.setPlaceholder();
    this.setWorkspace();
    this.setHideButton();
    this.checkFirstChange(changes);
    this.trySetElementWidth();
    this.tryHideDropdownWhenDisabled();
  }

  ngAfterViewInit() {
    this.closeOnEscRef = (event: KeyboardEvent): void =>
      pressKey(event, KEY_ESC, () => {
        this.onDropdownHide();
      });

    this.clickOutsideRef = (event: MouseEvent): void =>
      clickOutside(event, this.element.nativeElement, () => {
        event.stopImmediatePropagation();

        this.onDropdownHide();
      });

    if (this.ppOptions.singleLineInput) {
      createCustomScroller('os-theme-input', this.contentElement.nativeElement);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  removeSelectedTag(index: number): void {
    this.selectedTags.splice(index, 1);
    this.setUnselectedTags();

    if (!this.dropdown.visible || this.dropdown.buttonId !== this.buttonId) {
      this.tagRemoved$.next();
    }
  }

  onDropdownHide(): void {
    this.dropdown.visible = false;
    this.focused = false;

    this.window.removeEventListener('keydown', this.closeOnEscRef, true);
    this.window.removeEventListener('click', this.clickOutsideRef, true);

    if (this.ppOnClose) {
      this.ppOnClose.emit(this.selectedTags);
    }
  }

  clearAll(): void {
    this.selectedTags = [];
    this.setUnselectedTags();

    this.tagRemoved$.next();
  }

  showPlaceholder(): boolean {
    return !this.selectedTags.length && (!this.ppOptions.disabled || !!this.ppPlaceholder);
  }

  toggleDropdown(): void {
    if (this.ppOptions.disabled) {
      return;
    }

    if (this.dropdown.visible && this.dropdown.buttonId === this.buttonId) {
      this.dropdownService.hideDropdown();
    } else {
      this.setDropdownData();

      this.focused = true;

      this.dropdownService.showDropdown(this.buttonId, SelectTagDropdownComponent, {
        callback: (option) => this.selectItem(option),
        onClose: () => this.onDropdownHide(),
        popper: {
          positionFixed: true,
          placement: 'bottom-start',
        },
        suppressScrollbar: true,
        addTagDropdownScrollCallback: true,
        addScrollCallback: true,
      });
    }
  }

  showSelectCaret(): boolean {
    const showingSaveIndicator =
      this.ppOptions.showSaveIndicator && this.selectedTags.length > 0 && this.focused;

    const showingSomethingElseAlready = showingSaveIndicator || this.ppOptions.showUpdateIndicator;

    return !showingSomethingElseAlready && this.ppOptions.singleLineInput;
  }

  getErrorMessage(): string {
    return !(this.allTags.length > 0)
      ? this.translationPipe.transform('no_tags_created_for_site')
      : this.unselectedTags?.length === 0
      ? this.translationPipe.transform('no_additional_tags_to_display')
      : null;
  }

  private selectTag(tag: string): void {
    if (this.ppOptions.singleSelectOnly) {
      this.selectedTags = [tag];
    } else {
      this.selectedTags.push(tag);
      this.selectedTags.sort((a, b) => this.sortingService.naturalSort(a, b));
    }

    this.setUnselectedTags();
  }

  private selectItem(option: string): void {
    if (!option) {
      this.clearAll();
    } else {
      this.selectTag(option);
    }
  }

  private tryHideDropdownWhenDisabled(): void {
    if (
      this.ppOptions.disabled &&
      this.dropdown.visible &&
      this.dropdown.buttonId === this.buttonId
    ) {
      this.dropdownService.hideDropdown();
    }
  }

  private checkFirstChange(changes: { [key: string]: SimpleChange }): void {
    const pointIdFirstChange = changes.ppPointId && !changes.ppPointId.isFirstChange();
    const selectedTagsFirstChange =
      changes.ppSelectedTags && !changes.ppSelectedTags.isFirstChange();
    const optionsChanged = changes.ppOptions;

    if (pointIdFirstChange || selectedTagsFirstChange || optionsChanged) {
      this.setSelectedTags();
      this.setUnselectedTags();
    }
  }

  private setPlaceholder(): void {
    this.placeholder = this.ppPlaceholder
      ? this.ppPlaceholder
      : this.translationPipe.transform('select_tags...');
  }

  private setSelectedTags(): void {
    this.selectedTags = [...this.ppSelectedTags];
  }

  private setAllTags(): void {
    if (this.workspace) {
      this.allTags = this.workspace.tags;
    } else {
      this.allTags = this.getOverviewTags();
    }
  }

  private getOverviewTags(): string[] {
    const allTags: string[] = [];
    const workspaces = this.workspaceService.getWorkspaces();

    Object.values(workspaces).forEach((workspace) => {
      workspace.tags.forEach((tag) => {
        if (!allTags.includes(tag)) {
          allTags.push(tag);
        }
      });
    });

    return allTags;
  }

  private setUnselectedTags(): void {
    this.unselectedTags = this.allTags.filter((tag) => {
      for (let i = 0; i < this.selectedTags.length; i += 1) {
        if (this.selectedTags[i] === tag) {
          return false;
        }
      }

      return true;
    });

    this.setDropdownData();
  }

  private subscribeToTagRemoved(): void {
    this.tagRemoved$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(this.ppOptions.noTagRemovedDebounceTime ? 0 : 1500),
        tap(() => {
          this.ppOnChange.emit(this.selectedTags);
        }),
      )
      .subscribe();
  }

  private setWorkspace(): void {
    this.workspace = this.workspaceService.findWorkspace(this.ppWorkspaceId);
  }

  private setHideButton(): void {
    this.hideButtons = this.workspace?.tags?.length === 0 || this.ppOptions.tagsLimited;
  }

  private trySetElementWidth() {
    if (this.ppOptions.width) {
      this.element.nativeElement.style.width = this.ppOptions.width;
    }
  }

  private setDropdownData(): void {
    const dropdownData: TSelectTagDropdownData = {
      items: this.unselectedTags,
      message: this.getErrorMessage(),
      hideButtons: this.hideButtons,
      width: getWidthNumber(this.ppOptions.width, this.element.nativeElement.offsetWidth),
      noneAccess: this.ppNoneAccess,
    };

    this.dropdownService.setData(dropdownData);
  }
}
