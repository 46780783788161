import { Injectable } from '@angular/core';

import { TAttachment, TAttachments } from './attachments.model';

import { TMediaObject } from '../point-modal/point-attachments/point-attachments.service';
import { EDocumentType } from 'src/app/project/shared/enums/document-type.enum';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsMediaGalleryService {
  mediaList: TAttachment[] = [];

  constructor() {}

  private createMediaObject(media: TAttachment): TMediaObject {
    const mediaObject: TMediaObject = {
      type: media.type,
      id: media.attachmentId,
      w: 0,
      h: 0,
      fileName: media.fileName,
      mimeType: media.mimeType,
      createdOn: media.createdOn.toString(),
    };

    this.mediaList.push(media);

    if (media.type === EDocumentType.IMAGE) {
      mediaObject.src = media.bounded1200Url;
      mediaObject.extension = media.mimeType.split('/').pop();
    }

    if (media.type === EDocumentType.IMAGE_360) {
    }

    if (media.type === EDocumentType.VIDEO) {
      mediaObject.mimeType = media.mimeType;
      mediaObject.id = media.attachmentId;
      mediaObject.fileName = media.fileName;
    }

    return mediaObject;
  }

  createMediaArray(media: TAttachments['media']): TMediaObject[] {
    let orderedIds = [];

    media.dates.forEach((date) => {
      orderedIds = orderedIds.concat(date.attachmentIds);
    });

    return orderedIds.map((id) => this.createMediaObject(media.attachments[id]));
  }

  getMediaList(): TAttachment[] {
    return this.mediaList;
  }
}
