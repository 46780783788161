import { unionBy } from 'lodash';
import { EPropName } from '../dashboard/dashlet/dashlet-activities/dashlet-activity/dashlet-activity-update-changes/prop-names-enum';

export const mergeImages = (change, nextActivity, activity) => {
  if (change.newValue.length > change.oldValue.length) {
    const newValue = unionBy(change.newValue, nextActivity.data.changeList[0].newValue, 'id');

    change.newValue = newValue.reverse();

    if (nextActivity.data.changeList[0].oldValue) {
      change.oldValue = nextActivity.data.changeList[0].oldValue.reverse();
    } else {
      change.oldValue = [];
    }

    activity.mergedTime = nextActivity.header.createdEpochMillis;

    return true;
  } else {
    return false;
  }
};

export const merge360Images = (change, nextActivity, activity) => {
  if (change.newValue.length > change.oldValue.length) {
    const newValue = unionBy(change.newValue, nextActivity.data.changeList[0].newValue, 'id');

    change.newValue = newValue.reverse();

    if (nextActivity.data.changeList[0].oldValue) {
      change.oldValue = nextActivity.data.changeList[0].oldValue.reverse();
    } else {
      change.oldValue = [];
    }

    activity.mergedTime = nextActivity.header.createdEpochMillis;

    return true;
  } else {
    return false;
  }
};

export const mergeVideos = (change, nextActivity, activity) => {
  if (change.newValue.length > change.oldValue.length) {
    const newValue = unionBy(change.newValue, nextActivity.data.changeList[0].newValue, 'id');

    change.newValue = newValue.reverse();

    if (nextActivity.data.changeList[0].oldValue) {
      change.oldValue = nextActivity.data.changeList[0].oldValue.reverse();
    } else {
      change.oldValue = [];
    }

    activity.mergedTime = nextActivity.header.createdEpochMillis;

    return true;
  } else {
    return false;
  }
};

export const mergeRemovedImages = (change, nextActivity, activity) => {
  const oldValue = unionBy(change.oldValue, nextActivity.data.changeList[0].oldValue, 'id');

  const difference = nextActivity.data.changeList[0].oldValue.filter((value) => {
    const sameValue = change.oldValue.find((_value) => _value.id === value.id);

    return !sameValue;
  });

  if (!change.deleted) {
    const originalDifference = change.oldValue.filter((value) => {
      const sameValue = change.newValue.find((_value) => _value.id === value.id);

      return !sameValue;
    });

    if (originalDifference[0]) {
      change.deleted = [...originalDifference];
    } else {
      change.deleted = [];
    }
  }

  change.deleted.push(...difference);

  change.oldValue = oldValue.reverse();

  if (nextActivity.data.changeList[0].newValue) {
    change.newValue = nextActivity.data.changeList[0].newValue.reverse();
  } else {
    change.newValue = [];
  }

  activity.mergedTime = nextActivity.header.createdEpochMillis;

  return true;
};

export const mergeAddedFiles = (change, nextActivity, activity) => {
  if (change.newValue.length > change.oldValue.length) {
    const newValue = unionBy(
      change.newValue,
      nextActivity.data.changeList[0].newValue,
      'id',
    ).reverse();

    change.newValue = newValue.reverse();

    if (nextActivity.data.changeList[0].oldValue) {
      change.oldValue = nextActivity.data.changeList[0].oldValue.reverse();
    } else {
      change.oldValue = [];
    }

    activity.mergedTime = nextActivity.header.createdEpochMillis;

    return true;
  } else {
    return false;
  }
};

export const mergeRemovedDocuments = (change, nextActivity, activity) => {
  const oldValue = unionBy(change.oldValue, nextActivity.data.changeList[0].oldValue, 'id');

  const difference = nextActivity.data.changeList[0].oldValue.filter((value) => {
    const sameValue = change.oldValue.find((_value) => _value.id === value.id);

    return !sameValue;
  });

  if (!change.removedFiles) {
    const originalDifference = change.oldValue.filter((value) => {
      const sameValue = change.newValue.find((_value) => _value.id === value.id);

      return !sameValue;
    });

    if (originalDifference[0]) {
      change.removedFiles = [originalDifference[0].caption];
    } else {
      change.removedFiles = [];
    }
  }

  change.removedFiles.push(difference[0]?.caption);

  change.oldValue = oldValue.reverse();

  if (nextActivity.data.changeList[0].newValue) {
    change.newValue = nextActivity.data.changeList[0].newValue.reverse();
  } else {
    change.newValue = [];
  }

  activity.mergedTime = nextActivity.header.createdEpochMillis;

  return true;
};

export const mergeRemovedDocument = (activity) => {
  if (activity.data?.changeList) {
    for (let i = 0; i < activity.data.changeList.length; i++) {
      if (activity.data.changeList[i].propName === EPropName.DOCUMENTS) {
        const _activity = activity.data.changeList[i];
        const removedDocuments = _activity.oldValue.length - _activity.newValue.length;

        if (!_activity.removedFiles) {
          _activity.removedFiles = [];
        }

        const documentsToRemove = removedDocuments - _activity.removedFiles.length;

        if (documentsToRemove > 0) {
          for (let i = 0; i < documentsToRemove; i++) {
            _activity.removedFiles.push('file' + _activity.removedFiles.length + 1);
          }
        }
      }
    }
  }
};

export const mergeLocationActivities = (activity) => {
  if (activity.data?.changeList) {
    for (let i = activity.data.changeList.length - 1; i > 0; i--) {
      if (
        (activity.data.changeList[i].propName === EPropName.ADDITIONAL_PINS ||
          activity.data.changeList[i].propName === EPropName.POLYGONS ||
          activity.data.changeList[i].propName === EPropName.PIN ||
          activity.data.changeList[i].propName === EPropName.PINS) &&
        activity.data.changeList[i - 1] &&
        (activity.data.changeList[i - 1].propName === EPropName.ADDITIONAL_PINS ||
          activity.data.changeList[i - 1].propName === EPropName.POLYGONS ||
          activity.data.changeList[i - 1].propName === EPropName.PIN ||
          activity.data.changeList[i - 1].propName === EPropName.PINS)
      ) {
        activity.data.changeList.splice(i, 1);
      }
    }
  }
};

export const mergeAssignees = (prevChange, nextChange): void => {
  if (nextChange.newValue.length > nextChange.oldValue.length) {
    prevChange.added = [
      ...Array.from(
        new Set([...prevChange.added, ...getArraysDelta(nextChange.newValue, nextChange.oldValue)]),
      ),
    ];
  } else {
    prevChange.deleted = [
      ...Array.from(
        new Set([
          ...prevChange.deleted,
          ...getArraysDelta(nextChange.oldValue, nextChange.newValue),
        ]),
      ),
    ];
  }
};

export const checkActivityUser = (activity, allUsers) => {
  if (activity.header?.createdBy.id && allUsers[activity.header.createdBy.id]) {
    activity.header.createdBy = allUsers[activity.header.createdBy.id];
  }
};

const getArraysDelta = <T = any>(biggerArray: T[], smallerArray: T[]): string[] => {
  const arraysDifferenceLength = biggerArray.length - smallerArray.length;
  const changes = [];

  biggerArray.some((newItem) => {
    if (!smallerArray.includes(newItem)) {
      changes.push(newItem);
    }

    return changes.length === arraysDifferenceLength;
  });

  return changes;
};
