import { Component, OnInit } from '@angular/core';
import { Observable, catchError, of, tap } from 'rxjs';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { Modal, ModalService } from 'src/app/project/components/modal/modal.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { ArchiveSitesService } from '../archive-sites.service';
import { TTranslationKey } from 'src/app/project/features/translate/types';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';

@Component({
  selector: 'pp-account-sites-archive-modal',
  templateUrl: './account-sites-archive-modal.component.html',
  styleUrls: ['./account-sites-archive-modal.component.scss'],
})
export class AccountSitesArchiveModalComponent implements OnInit {
  private modal: Modal;

  archiveLimitError = false;
  accountId: string;
  selectedSites: string[];
  unarchivedSitesCount: number;
  heading: TTranslationKey;

  constructor(
    private modalService: ModalService,
    private archiveSitesService: ArchiveSitesService,
    private promptService: PromptService,
    private translationPipe: TranslationPipe,
  ) {}

  ngOnInit(): void {
    this.modal = this.modalService.getModal();
    const modalData = this.modal.data;

    this.accountId = modalData.accountId;
    this.selectedSites = modalData.selectedSites;
    this.unarchivedSitesCount = modalData.unarchivedSitesCount;
    this.setHeader();
  }

  setHeader(): void {
    this.heading = this.archiveLimitError ? 'sites_limit_reached' : 'unarchive_site';
  }

  unarchiveSite(): void {
    if (this.archiveLimitError) {
      this.archiveSitesService
        .handleUnarchiveSiteBeyondLimit(
          this.accountId,
          this.selectedSites,
          this.unarchivedSitesCount + this.selectedSites.length,
        )
        .pipe(
          tap(() => {
            this.hideModal(false);
          }),
        )
        .subscribe();
    } else {
      this.archiveSitesService
        .unarchiveSites(this.accountId, this.selectedSites)
        .pipe(
          tap(() => {
            this.hideModal(false);
          }),
          catchError((error) => this.handleUnarchiveSitesError(error)),
        )
        .subscribe();
    }
  }

  handleUnarchiveSitesError(error: any): Observable<null> {
    if (error.status === EStatusCode.UPGRADE_REQUIRED) {
      this.archiveLimitError = true;
      this.setHeader();
    } else {
      const promptMessage = this.translationPipe.transform('unarchive_site_error');
      this.promptService.showError(promptMessage);
      this.hideModal(true);
    }

    return of(null);
  }

  hideModal(cancel: boolean): void {
    this.modalService.hideModal(cancel);
  }
}
