import { cloneDeep } from 'lodash';
import { TColumn } from './column.model';

let grouping: TColumn[] = [];
let sortedPoints: string[] = [];
// GET

export const GET_GROUPING = (): TColumn[] => grouping;

// SET & MODIFY

export const SET_GROUPING = (_grouping: TColumn[]): void => {
  grouping = _grouping;
};

// CLEAR

export const CLEAR_GROUPING = (): void => {
  grouping.length = 0;
};

export const SET_GROUPED_POINTS = (sortedPointIds: string[]): void => {
  sortedPoints = sortedPointIds;
};

export const GET_GROUPED_POINTS = (): string[] => sortedPoints;

export const REMOVE_GROUP = (column: TColumn): void => {
  grouping = grouping.filter((_column) => _column.index !== column.index);

  grouping.forEach((_column, _index) => {
    _column.groupIndex = _index;
  });
};

export const REPLACE_GROUP = (column: TColumn, oldColumn: TColumn): void => {
  const indexToDelete = grouping.findIndex((_column) => _column.index === oldColumn.index);

  grouping.splice(indexToDelete, 1, column);
};

export const MOVE_GROUP_COLUMN = (columnIndex, destinationIndex): void => {
  const column = cloneDeep(grouping[columnIndex]);

  grouping.splice(columnIndex, 1);
  grouping.splice(destinationIndex, 0, column);

  grouping.forEach((_column, _index) => {
    _column.groupIndex = _index;
  });
};
