import { createElement } from 'src/app/core/helpers/dom';
import { createFooterCellInnerElement } from './create-footer-cell-inner-element';
import { checkShowingTotal } from './check-showing-total';
import { TColumn } from '../../../columns/column.model';
import { GET_COLUMNS } from '../../../columns/columns.store';
import { GET_GROUPING } from '../../../columns/grouping.store';

export function createFooterCellElement({
  child = null,
  className = null,
  center = false,
  width = 'auto',
  column = null,
  isGroupedHeader = false,
}: {
  child?: HTMLElement;
  className?: string;
  center?: boolean;
  resize?: boolean;
  width?: string | number;
  column?: TColumn;
  isGroupedHeader?: boolean;
} = {}): HTMLElement {
  const cellInnerElement = createFooterCellInnerElement(child, column);
  const grouping = GET_GROUPING();
  let columnWidth = width;

  if (center) {
    cellInnerElement.style.textAlign = 'center';
    cellInnerElement.style.padding = '0';

    cellInnerElement.style.display = 'flex';
    cellInnerElement.style.justifyContent = 'center';
    cellInnerElement.style.alignItems = 'center';
  }

  const isShowingTotal = checkShowingTotal(column);
  const isLastElement = column?.index === GET_COLUMNS().length - 1;

  const classList = ['table__cell__footer'];
  if (className) {
    classList.push(className);
  }

  if (isLastElement && grouping.length) {
    columnWidth = `calc(${width} + ${grouping.length * 16}px)`;
  }

  if (isShowingTotal && !isGroupedHeader) {
    classList.push('table__cell__footer--showTotal');
  }

  const cellElement = createElement<HTMLElement>('div', {
    attrs: {
      class: classList.join(' '),
      style: {
        width: columnWidth,
      },
    },
    children: [cellInnerElement],
  });

  return cellElement;
}
