import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { DeviceService } from '@core/services';
import { tap } from 'rxjs';
import { TopBarService } from '../../../layout/top-bar/top-bar.service';
import { TAsset } from '../../asset-service/asset.consts';
import { AssetDashboardService } from './asset-card/asset-dashboard/asset-dashboard.service';
import { AssetReorderService } from './asset-reorder.service';

@Component({
  selector: 'pp-fleet-assets-page',
  templateUrl: './fleet-assets-page.component.html',
  styleUrls: ['./fleet-assets-page.component.scss'],
})
export class FleetAssetsPageComponent implements OnDestroy {
  @Input() ppFleetId: string;
  @Input() ppAssets: TAsset[];
  @ViewChild('fleetAssets') fleetAssetsElement: ElementRef;
  @ViewChild('assetReorderLine') assetReorderLine: ElementRef;

  visible: boolean;
  isBrowserSafari: boolean;

  constructor(
    private assetReorderService: AssetReorderService,
    private topBarService: TopBarService,
    private deviceService: DeviceService,
    private assetDashboardService: AssetDashboardService,
  ) {
    this.isBrowserSafari = this.deviceService.isBrowserSafari();

    this.assetReorderService.reorderedAssetIndexChange$
      .pipe(
        tap((newIndex) => {
          this.visible = newIndex !== null;

          if (newIndex === null) {
            return;
          }

          this.adjustReorderLinePosition(newIndex);
        }),
      )
      .subscribe();

    this.assetReorderService.reorderedAssetIdChange$
      .pipe(
        tap((assetId) => {
          if (!assetId) {
            this.assetReorderLine.nativeElement.style.visibility = 'hidden';
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.assetDashboardService.setExpandedAssetId(null);
  }

  trackByFunction(index: number, item: TAsset): string {
    return item.id;
  }

  private adjustReorderLinePosition(newIndex: number): void {
    let addLastElementOffset = false;

    if (this.assetReorderService.getReorderedAssetId() === null) {
      return;
    }

    if (!this.fleetAssetsElement.nativeElement.children[newIndex]) {
      addLastElementOffset = true;
      newIndex--;
    }

    const parentElement: HTMLElement = this.getScrollableParent();

    const targetElementCoords =
      this.fleetAssetsElement.nativeElement.children[newIndex].getBoundingClientRect();

    const topBarElement = this.topBarService.getTopBarElement();
    const topBarOffset = topBarElement.getBoundingClientRect().height;
    const margin = 8;
    const lineWidth = 4;
    const scrollOffset = parentElement?.scrollTop || 0;
    const lastElementOffset = addLastElementOffset ? targetElementCoords.height : 0;
    const safariOffset = this.isBrowserSafari ? 8 : 0;

    const newPosition =
      targetElementCoords.top +
      scrollOffset -
      topBarOffset -
      margin / 2 -
      lineWidth / 2 +
      lastElementOffset +
      safariOffset;

    this.assetReorderLine.nativeElement.style.top = `${newPosition}px`;
    this.assetReorderLine.nativeElement.style.visibility = 'visible';
  }

  private getScrollableParent(): HTMLElement {
    const parentsNumber = 4; // 4 is the number of nesting levels between the fleetAssetsElement and the scrollable parent

    let parentElement: HTMLElement = this.fleetAssetsElement.nativeElement;

    for (let i = 0; i < parentsNumber; i++) {
      parentElement = parentElement.parentNode as HTMLElement;
    }
    return parentElement;
  }
}
