<div class="assignees__content">
  <pp-user-picker
    [ppAllUsers]="workspaceUsers"
    [ppSelectedUsers]="selectedUsers"
    [ppId]="'addUsersButton__' + ppFilterType"
    (ppSelectUserCallback)="selectUser($event)"
    (ppRemoveUserCallback)="removeUser($event)"
    (ppClearAll)="selectUser(null)"
    [ppType]="'users'"
  ></pp-user-picker>
</div>
