import { GET_COLLAPSED_GROUPS, GET_TABLE } from '../../../table.ui.store';
import { createElement } from 'src/app/core/helpers/dom';
import { createHeaderNameElement } from './create-header-name-element';
import { createPointCountElement } from '../create-point-count-element';
import { GET_GROUPING } from '../../../columns/grouping.store';
import { ApiService } from '@core/http';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TTableItem } from '../../../custom-table/table.model';
import Table from '../../../custom-table/table/Table';
import { getMergedHeaderClassList } from './utils/get-merged-header-class-list';
import { getHeaderClassList } from './utils/get-header-class-list';
import { getChildrenList } from './utils/get-children-list';
import { getClassList } from './utils/get-class-list';
import { createColumnCellsElement } from './utils/create-columns-type-element';
import { getArrowElement } from './utils/get-arrow-element';
import { getHeaderRowBackgroundElement } from './utils/get-header-row-background-element';

export function createHeaderRowElement(
  _index: number,
  groupLevel?: number,
  apiService?: ApiService,
  dropdownService?: DropdownService,
): HTMLElement {
  const table = GET_TABLE();
  const groupsCollapsed = GET_COLLAPSED_GROUPS();
  const item = table.items[_index];

  const classList = getClassList(groupsCollapsed, item, groupLevel);

  const headerElement = createHeaderElement({
    table,
    _index,
    apiService,
    dropdownService,
    groupLevel,
    item,
  });

  const element = createElement('div', {
    attrs: {
      class: classList,
    },
    children: [headerElement, createColumnCellsElement(_index, groupLevel)],
  });

  return element;
}

function createHeaderElement({
  table,
  _index,
  apiService,
  dropdownService,
  groupLevel,
  item,
}: {
  table: Table;
  _index: number;
  apiService: ApiService;
  dropdownService: DropdownService;
  groupLevel: number;
  item: TTableItem;
}): HTMLElement {
  const grouping = GET_GROUPING();
  const groupsCollapsed = GET_COLLAPSED_GROUPS();

  const mergedHeaderClassList: string[] = getMergedHeaderClassList(groupLevel, grouping, item);
  const backgroundElement = getHeaderRowBackgroundElement(groupLevel, grouping, item);
  const arrowElement = getArrowElement(groupsCollapsed, item, groupLevel);
  const childrenList: HTMLElement[] = getChildrenList(groupLevel);
  const headerClassList = getHeaderClassList(groupLevel, grouping);
  let headerPadding = groupLevel * 16;

  if (grouping.length === 1) {
    headerPadding += 2;
  }

  const elementWidth = groupLevel === 3 ? 152 : groupLevel === 2 ? 167 : 184;

  return createElement('div', {
    attrs: {
      class: mergedHeaderClassList.join(' '),
      style: {
        paddingLeft: `${headerPadding}px`,
      },
    },
    children: [
      ...childrenList,

      backgroundElement,
      arrowElement,
      createElement('div', {
        attrs: {
          class: headerClassList,
        },
        children: [
          createElement('div', {
            attrs: {
              class: 'table__headerCell',
              style: {
                maxWidth: `${table.width - 150}px`,
                width: `${elementWidth}px`,
              },
            },
            children: [
              createHeaderNameElement(_index),
              createHeaderAndOptionsElement(_index, apiService, dropdownService),
            ],
          }),
        ],
      }),
    ],
  });
}

function createHeaderAndOptionsElement(
  _index: number,
  apiService: ApiService,
  dropdownService: DropdownService,
): HTMLElement {
  return createElement('div', {
    attrs: {
      class: 'table__headerCell--options',
    },
    children: [createPointCountElement(_index, apiService, dropdownService)],
  });
}
