<div class="siteSettingsIntegrationsTooltip">
  {{ ppText }}

  <span *ngIf="ppAssetName" class="siteSettingsIntegrationInfo__assetName">{{ ppAssetName }}</span>

  <div class="siteSettingsIntegrationsInfo" ppTooltip [ppTitle]="ppTooltip">
    <pp-icon [ppSrc]="EIconPath.ICON_BADGE_INFO" ppClass="setSize14" ppColor="inherit"></pp-icon>

    <span class="siteSettingsIntegrationsInfo__text">{{ 'more_info' | ppTranslate }}</span>
  </div>
</div>
