<h5 class="accountOptions__header">{{ 'extra_features' | ppTranslate }}</h5>

<div>
  <div *ngFor="let accountFeature of featureList" class="editFeature__option">
    <pp-toggle
      ppId="toggleOfflineFeature"
      ppStyle="blue"
      class="editFeature__toggle"
      (ppAction)="toggleFeature(accountFeature.value)"
      [ppChecked]="features[accountFeature.value]"
      [ppReverseLayout]="true"
    >
      <span class="toggleButtonText"> {{ accountFeature.label }} </span>
    </pp-toggle>
  </div>
</div>
