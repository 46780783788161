import { Component, Input, OnChanges } from '@angular/core';
import { EAuthorizationProgressStep } from 'src/app/project/components/authorization-progress/authorization-progress.consts';
import {
  EIntegrationStatus,
  TWorkspaceCustomFields,
} from 'src/app/project/modules/custom-fields/custom-fields.model';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { VolyIntegrationService } from 'src/app/project/modules/integrations/voly-integration.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import {
  COST_CENTRE_CF_NAME,
  EXPENDITURE_CF_NAME,
} from './site-settings-integrations-custom-fields.consts';

type TIntegrationField = {
  fieldName: string;
  active: boolean;
  customFieldId: string;
  subList?: any[];
};

@Component({
  selector: 'pp-site-settings-integrations-custom-fields',
  templateUrl: './site-settings-integrations-custom-fields.component.html',
  styleUrl: './site-settings-integrations-custom-fields.component.scss',
})
export class SiteSettingsIntegrationsCustomFieldsComponent implements OnChanges {
  @Input() ppIntegrationStep: EAuthorizationProgressStep;
  @Input() ppDisabled: boolean;
  @Input() ppWorkspaceId: string;

  EIconPath = EIconPath;
  integrationFields: TIntegrationField[] = [];
  disabled: boolean;

  private order = [
    'Net - Voly',
    'VAT - Voly',
    'Total - Voly',
    EXPENDITURE_CF_NAME,
    COST_CENTRE_CF_NAME,
  ];

  constructor(
    private customFieldsService: CustomFieldsService,
    private volyIntegrationService: VolyIntegrationService,
  ) {}

  ngOnChanges(): void {
    const customFields = this.customFieldsService.getWorkspaceCustomFields(this.ppWorkspaceId);

    if (customFields && this.ppIntegrationStep === EAuthorizationProgressStep.ACTIVE) {
      this.setIntegrationFields(customFields);
    } else {
      this.setDefaultIntegrationFields();
    }

    this.disabled = this.ppIntegrationStep !== EAuthorizationProgressStep.ACTIVE || this.ppDisabled;
  }

  private sortEndingsInOrder(a: string, b: string, order: string[]) {
    const aIndex = order.findIndex((suffix) => a.endsWith(suffix));
    const bIndex = order.findIndex((suffix) => b.endsWith(suffix));

    // If both have the same suffix or neither have the specified suffixes, sort alphabetically
    if (aIndex === bIndex) {
      return a.localeCompare(b);
    }

    // If a has one of the suffixes and b does not, a comes first
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;

    // Otherwise, sort by the order of the suffixes
    return aIndex - bIndex;
  }

  private setIntegrationFields(customFields: TWorkspaceCustomFields): void {
    let integrationFields = Object.values(customFields).filter(
      (field) => field.volyIntegrationActive === EIntegrationStatus.ON,
    );

    integrationFields = integrationFields.sort((a, b) => {
      return this.sortEndingsInOrder(a.label, b.label, this.order);
    });

    this.integrationFields = integrationFields.map((field) => {
      const newField: TIntegrationField = {
        fieldName: field.label + (field.currencyCode ? ` (${field.currencyCode})` : ''),
        active: field.display,
        customFieldId: field.id,
      };

      if (field.subList) newField.subList = field.subList;

      return newField;
    });
  }

  private setDefaultIntegrationFields(): void {
    this.integrationFields = [
      {
        fieldName: 'Invoice Net - Voly',
        active: false,
        customFieldId: null,
      },
      {
        fieldName: 'Invoice VAT - Voly',
        active: false,
        customFieldId: null,
      },
      {
        fieldName: 'Invoice Total - Voly',
        active: false,
        customFieldId: null,
      },
      {
        fieldName: EXPENDITURE_CF_NAME,
        active: false,
        customFieldId: null,
        subList: [],
      },
      {
        fieldName: COST_CENTRE_CF_NAME,
        active: false,
        customFieldId: null,
        subList: [],
      },
    ];
  }
}
