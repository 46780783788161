import { TShare } from 'src/app/project/modules/share/share.model';
import { GET_USERS } from 'src/app/project/modules/users/users.store';
import { humanizeShareOption } from '../../../share/share-utils/humanize-share';
import { EUserRole } from '../../../share/share-utils/user-roles';

export function searchShares(items: TShare[], filter: string): TShare[] {
  const allUsers = GET_USERS();

  if (!items || !filter) {
    return items;
  }

  return items.filter((item) => {
    const user = allUsers[item.userId];

    if (
      item.tagLimited &&
      item.defectTags.length === 0 &&
      'none'.indexOf(filter.toLowerCase()) !== -1
    ) {
      return true;
    }

    if (!item.tagLimited && 'all'.indexOf(filter.toLowerCase()) !== -1) {
      return true;
    }

    if (item.defectTags) {
      for (let i = 0; i < item.defectTags.length; i++) {
        if (item.defectTags[i].toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
          return true;
        }
      }
    }

    if (
      user &&
      ((user.userName && user.userName.toLowerCase().indexOf(filter.toLowerCase()) !== -1) ||
        checkRole(filter, item.shareOption as EUserRole) ||
        user.email.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
        (item.shareOption.toLowerCase() === 'limited' &&
          'guest'.indexOf(filter.toLowerCase()) !== -1))
    ) {
      return true;
    }

    return false;
  });
}

function checkRole(keyword: string, role: EUserRole): boolean {
  const roleString = humanizeShareOption(role).toLowerCase();

  return roleString.indexOf(keyword.toLowerCase()) !== -1;
}
