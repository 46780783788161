import { Injectable } from '@angular/core';
import { isEqual } from 'lodash';
import { Subject } from 'rxjs';
import { logErrorInSentry } from '../../../errors/response-error';
import { TShare } from '../../../share/share.model';
import { TSharesByWorkspace } from './edit-account-user-modal.consts';

@Injectable({
  providedIn: 'root',
})
export class EditAccountUserModalDataService {
  private selectedWorkspaces: string[] = [];
  private emailList: string[] = [];
  private sharesMap: TSharesByWorkspace = {};

  private readonly _emailListChange$ = new Subject<string[]>();
  readonly emailListChange$ = this._emailListChange$.asObservable();

  updateWorkspacesData(selectedWorkspaces: string[]): void {
    this.setSelectedWorkspaces(selectedWorkspaces);
    this.filterSharesMap();
  }

  getSelectedWorkspaces(): string[] {
    return this.selectedWorkspaces;
  }

  setEmailList(emailList: string[]): void {
    if (!isEqual(this.emailList, emailList)) {
      this.emailList = [...emailList];

      this._emailListChange$.next(emailList);
    }
  }

  getEmailList(): string[] {
    return this.emailList;
  }

  getSharesMap(): TSharesByWorkspace {
    return this.sharesMap;
  }

  setSharesMap(sharesMap: TSharesByWorkspace): void {
    this.sharesMap = sharesMap;
  }

  getShare(workspaceId: string): TShare {
    return this.sharesMap[workspaceId];
  }

  setShare(workspaceId: string, share: TShare): void {
    if (workspaceId === null) {
      const error = new Error('workspaceId cannot be null');
      logErrorInSentry(error);
      throw error;
    }

    this.sharesMap[workspaceId] = share;
  }

  clearData(): void {
    this.updateWorkspacesData([]);
    this.setEmailList([]);
    this.setSharesMap({});
  }

  filterSharesMap(): void {
    Object.keys(this.sharesMap).forEach((workspaceId) => {
      if (!this.selectedWorkspaces.includes(workspaceId)) {
        delete this.sharesMap[workspaceId];
      }
    });
  }

  private setSelectedWorkspaces(selectedWorkspaces: string[]): void {
    this.selectedWorkspaces = selectedWorkspaces;
  }
}
