import { Injectable } from '@angular/core';
import { TColumn } from './column.model';
import {
  ADD_COLUMN,
  CLEAR_COLUMNS,
  CLEAR_COLUMN_GROUP_ORDER_AND_INDEX,
  CLEAR_COLUMN_SORT_ORDER_AND_INDEX,
  DELETE_COLUMN,
  GET_COLUMNS,
  HIDE_COLUMN,
  MOVE_COLUMN,
  REARRANGE_COLUMNS_GROUPS,
  REARRANGE_COLUMNS_SORTS,
  REINDEX_COLUMNS,
  SET_COLUMNS,
  SET_COLUMN_GROUP_INDEX,
  SET_COLUMN_GROUP_ORDER,
  SET_COLUMN_SORT_INDEX,
  SET_COLUMN_SORT_ORDER,
  SET_COLUMN_WIDTH,
  SHOW_COLUMN,
} from './columns.store';

@Injectable({
  providedIn: 'root',
})
export class TableColumnsService {
  getColumns(): TColumn[] {
    return GET_COLUMNS();
  }

  setColumns(columns: TColumn[]): void {
    SET_COLUMNS(columns);
  }

  setColumnWidth(columnIndex: number, width: number): void {
    SET_COLUMN_WIDTH(columnIndex, width);
  }

  setColumnSortIndex(columnIndex: number, sortIndex?: number): void {
    SET_COLUMN_SORT_INDEX(columnIndex, sortIndex);
  }

  setColumnSortOrder(columnIndex: number, sortOrder?: 'ASC' | 'DESC'): void {
    SET_COLUMN_SORT_ORDER(columnIndex, sortOrder);
  }

  clearColumnGroupOrderAndIndex(): void {
    CLEAR_COLUMN_GROUP_ORDER_AND_INDEX();
  }

  clearColumnSortOrderAndIndex(): void {
    CLEAR_COLUMN_SORT_ORDER_AND_INDEX();
  }

  setColumnGroupIndex(columnIndex: number, groupIndex?: number): void {
    SET_COLUMN_GROUP_INDEX(columnIndex, groupIndex);
  }

  rearrangeColumnsGroups(): void {
    REARRANGE_COLUMNS_GROUPS();
  }

  rearrangeColumnsSorts(): void {
    REARRANGE_COLUMNS_SORTS();
  }

  setColumnGroupOrder(columnIndex: number, groupOrder?: 'ASC' | 'DESC'): void {
    SET_COLUMN_GROUP_ORDER(columnIndex, groupOrder);
  }

  hideColumn(columnIndex: number): void {
    HIDE_COLUMN(columnIndex);
  }

  showColumn(columnIndex: number): void {
    SHOW_COLUMN(columnIndex);
  }

  addColumn(column: TColumn): void {
    ADD_COLUMN(column);
  }

  deleteColumn(columnIndex: number): void {
    DELETE_COLUMN(columnIndex);
  }

  moveColumn(columnIndex: number, destinationIndex: number): void {
    MOVE_COLUMN(columnIndex, destinationIndex);
  }

  reindexColumns(): void {
    REINDEX_COLUMNS();
  }

  clearColumns(): void {
    CLEAR_COLUMNS();
  }
}
