import { cloneDeep } from 'lodash';

export function trimTimeframe(
  timeframeArray: number[],
  minDate: number,
  maxDate: number,
  timelineColumns: string[],
): { timeframes: number[]; columnHeaders: string[] } {
  const leftElementsPadding = 4;
  const rightElementsPadding = 6;

  let timeframes = cloneDeep(timeframeArray);
  let columnHeaders = cloneDeep(timelineColumns);

  const startIndex = getTrimmedTimelineStartIndex(timeframes, minDate);

  if (startIndex > leftElementsPadding) {
    trimTimelineOnLeftSide(timeframes, startIndex, leftElementsPadding, columnHeaders);
  }

  const endIndex = getTrimmedTimelineEndIndex(timeframes, maxDate);

  if (timeframes.length - endIndex > rightElementsPadding) {
    ({ timeframes, columnHeaders } = trimTimeLineOnRightSide(
      timeframes,
      endIndex,
      rightElementsPadding,
      columnHeaders,
    ));
  }

  return {
    timeframes,
    columnHeaders,
  };
}

function trimTimeLineOnRightSide(
  timeframes: number[],
  endIndex: number,
  rightElementsPadding: number,
  columnHeaders: string[],
) {
  timeframes = timeframes.slice(0, -(timeframes.length - endIndex - rightElementsPadding)); // negative value removes elements from end of the array
  columnHeaders = columnHeaders.slice(0, -(columnHeaders.length - endIndex - rightElementsPadding));
  return { timeframes, columnHeaders };
}

function trimTimelineOnLeftSide(
  timeframes: number[],
  startIndex: number,
  leftElementsPadding: number,
  columnHeaders: string[],
) {
  timeframes.splice(0, startIndex - leftElementsPadding);
  columnHeaders.splice(0, startIndex - leftElementsPadding);
}

function getTrimmedTimelineEndIndex(timeframes: number[], maxDate: number) {
  return timeframes.findIndex((timeframe, index) => {
    const nextTimeframe = timeframes[index + 1];

    if (maxDate >= timeframe && maxDate <= nextTimeframe) {
      return true;
    }

    return false;
  });
}

function getTrimmedTimelineStartIndex(timeframes: number[], minDate: number) {
  return timeframes.findIndex((timeframe, index) => {
    const nextTimeframe = timeframes[index + 1];

    if (minDate >= timeframe && minDate <= nextTimeframe) {
      return true;
    }

    return false;
  });
}
