import { cloneDeep } from 'lodash';
import { UpdatePointsCustomFields } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';

export function updatePointsCustomFields(
  oldState: TPointsByWorkspace,
  action: UpdatePointsCustomFields,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);
  let pointIndex = null;

  action.payload.pointIds.forEach((_pointId) => {
    pointIndex = state[action.payload.workspaceId].entities.findIndex(
      (point) => point._id === _pointId,
    );
    const point = state[action.payload.workspaceId].entities[pointIndex];

    if (point) {
      point.header.updatedEpochMillis = new Date().getTime();

      Object.keys(action.payload.customFields).forEach((_key) => {
        point.customFieldsMap[_key].value = action.payload.customFields[_key].customFieldValue;

        if (action.payload.customFields[_key].customFieldValueId) {
          point.customFieldsMap[_key].idOfChosenElement =
            action.payload.customFields[_key].customFieldValueId;
        }
      });

      state[action.payload.workspaceId].entities.splice(pointIndex, 1, point);
    }
  });

  return state;
}
