import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { AutonumericModule } from 'src/app/project/directives/autonumeric/autonumeric.module';
import { FocusModule } from 'src/app/project/directives/focus/focus.module';
import { ImagesFallbackModule } from 'src/app/project/directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from 'src/app/project/directives/on-rendered/on-rendered.module';
import { SwitchCasesModule } from 'src/app/project/directives/switch-cases/switch-cases.module';
import { TooltipsModule } from 'src/app/project/features/tooltip/tooltips.module';
import { IconModule } from '../../../components/icon/icon-module';
import { ButtonModule } from '../../button/button.module';
import { SaveIndicatorModule } from '../../save-indicator/save-indicator.module';
import { SearchBoxModule } from '../../search-box/search-box.module';
import { TagModule } from '../../tag/tag.module';
import { HideDropdownModule } from '../hide-dropdown/hide-dropdown.module';
import { SelectDropdownSelectedMarkModule } from '../select-dropdown-selected-mark/select-dropdown-selected-mark.module';
import { SelectDropdownComponent } from './select-dropdown/select-dropdown.component';
import { SelectComponent } from './select.component';

@NgModule({
  declarations: [SelectComponent, SelectDropdownComponent],
  imports: [
    CommonModule,
    TagModule,
    PipesModule,
    SearchBoxModule,
    ButtonModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SaveIndicatorModule,
    SwitchCasesModule,
    IconModule,
    HideDropdownModule,
    SelectDropdownSelectedMarkModule,
  ],
  exports: [SelectComponent, SelectDropdownComponent],
})
export class SelectModule {}
