<div
  class="userChipWrapper"
  [class.userChip--error]="ppHasError"
  [class.userChipWrapper--disabled]="ppDisabled"
>
  <div class="userChip" [class.userChip--disabled]="ppDisabled">
    <img ppImage class="userChip__image" *ngIf="userAvatarSrc" [src]="userAvatarSrc" />

    <span class="userChip__username" [class.userChip__username--error]="ppHasError">
      {{ userName }}
    </span>
  </div>

  <button
    class="userChip__delete"
    [class.userChip__delete--error]="ppHasError"
    (click)="removeUser($event)"
    *ngIf="!ppDisabled"
  >
    <pp-icon
      [ppSrc]="EIconPath.ADVANCED_FILTERS_CLEAR_14"
      ppClass="setSize14"
      ppColor="grey-500"
    ></pp-icon>
  </button>
</div>
