import Snap from 'ol/interaction/Snap';

import { Injectable } from '@angular/core';
import { PlanPinsService } from '../plan-pins.service';
import { PlanPointVariablesService } from './plan-point-variables.service';
import { TPlanData } from '../plan-data.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureSnapService {
  constructor(
    private planPinsService: PlanPinsService,
    private planPointVariablesService: PlanPointVariablesService,
  ) {}

  setSnapInteraction(): void {
    const source = this.planPinsService.getVectorSource();

    this.planPointVariablesService.setSnapInteraction(
      new Snap({
        source: source,
        pixelTolerance: 25,
      }),
    );
  }

  addSnapInteraction(plan: TPlanData): void {
    const snapInteraction = this.planPointVariablesService.getSnapInteraction();

    plan.instance.point.addInteraction(snapInteraction);
  }
}
