import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { TCurrencyData } from '../../modules/custom-fields/currencies/currencies.consts';
import { API_currency_list } from './currencies-paths';

@Injectable({
  providedIn: 'root',
})
export class CurrenciesApiProviderService {
  constructor(private apiService: ApiService) {}

  public getCurrenciesList(): Observable<TCurrencyData[]> {
    const url = API_currency_list();

    return this.apiService.get(url);
  }
}
