import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SharesApiProviderService } from '@core/api';
import { ApiService } from '@core/http';
import { Subject, timer } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { ScreenService } from 'src/app/core/services/window/screen.service';
import {
  ConfirmModalComponent,
  TConfirmModalParams,
} from 'src/app/project/components/confirm-modal/confirm-modal.component';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { setChangedAccounts } from 'src/app/project/modules/account/account';
import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { AccountSettingsDropdownComponent } from 'src/app/project/modules/account/account-settings-dropdown/account-settings-dropdown.component';
import { FolderSettingsDropdownComponent } from 'src/app/project/modules/account/folder-settings-dropdown/folder-settings-dropdown.component';
import { SiteSettingsDropdownComponent } from 'src/app/project/modules/site/site-settings-dropdown/site-settings-dropdown.component';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSidePanel,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { TRequestFolder } from 'src/app/project/view-models/account-simple-response.model';
import AccountListPanel from '../../account-list/account-list-panel/AccountListPanel';
import { TAccountListCallbacks } from '../../account-list/account-list/AccountList';
import { TVirtualScrollerFolder } from '../../account-list/account-list/account-list-folder';
import {
  ADD_EDITED_FOLDER,
  ADD_VISIBLE_EMPTY_FOLDER,
  DELETE_HIGHLIGHTED_SITE,
  SET_HIGHLIGHTED_SITE,
} from '../../account-list/utils/account-list.ui.store';
import { TSiteSettingsDropdownData } from '../../site/site-settings-dropdown/site-settings-dropdown.model';
import { BasicWorkspaceService } from '../../workspace/basic-workspace.service';
import { SidePanelService } from './side-panel.service';

@Injectable({
  providedIn: 'root',
})
export class AccountListService implements OnDestroy {
  private destroy$ = new Subject<void>();
  accountsPanel: AccountListPanel;
  private dropdown: TDropdown = this.dropdownService.getDropdown();
  private isMobile: boolean;

  private highlightedSiteTimerMs = 1000;
  private accountsPanelElement: HTMLElement;

  constructor(
    private router: Router,
    private dropdownService: DropdownService,
    private accountService: AccountService,
    private promptService: PromptService,
    private modalService: ModalService,
    private sharesApiProviderService: SharesApiProviderService,
    private basicWorkspaceService: BasicWorkspaceService,
    private screenService: ScreenService,
    private sidePanelService: SidePanelService,
    private translationPipe: TranslationPipe,
    private apiService: ApiService,
  ) {
    this.screenService.isMobile$.pipe(takeUntil(this.destroy$)).subscribe((isMobile) => {
      this.isMobile = isMobile;
    });

    this.destroyAccountListPanel();

    this.sidePanelService.accountPanelDestroy$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.destroyAccountListPanel();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  setAccountsPanelElement(accountsPanelElement: HTMLElement): void {
    this.accountsPanelElement = accountsPanelElement;
  }

  setAccountsPanel(expanded: boolean): void {
    // Temp logic work around (window.innerWidth >= 901)
    if (expanded && !this.isMobile) {
      this.createAccountListPanel();
    } else {
      this.destroyAccountListPanel();
    }
  }

  createAccountListPanel(): void {
    const accountListCallbacks: TAccountListCallbacks = {
      accountSettingsCallback: (_buttonId, _accountId) =>
        this.accountSettingsCallback(_buttonId, _accountId),
      folderSettingsCallback: ({ buttonId, accountId, folderId }) =>
        this.folderSettingsCallback({ buttonId, accountId, folderId }),
      siteSettingsCallback: (_buttonId, _workspaceId) =>
        this.siteSettingsCallback(_buttonId, _workspaceId),
      openSiteCallback: (_workspaceId) => this.openSiteCallback(_workspaceId),
      createSiteCallback: (_accountId) => this.createSiteCallback(_accountId),
      editFolderCallback: (folder) => this.editFolderCallback(folder),
      createFolderCallback: (folder) => this.createFolderCallback(folder),
      errorCallback: (level, error) => this.errorCallback(level, error),
      addSiteToFolderCallback: (workspaceId, accountId, folderId) =>
        this.addSiteToFolderCallback(workspaceId, accountId, folderId),
      removeSiteFromFolderCallback: (workspaceId, accountId) =>
        this.removeSiteFromFolderCallback(workspaceId, accountId),
      cancelCallback: (accountId) => this.cancelCallback(accountId),
    };

    this.accountsPanel = new AccountListPanel(
      this.sharesApiProviderService,
      this.basicWorkspaceService,
      this.screenService,
      this.apiService,
      accountListCallbacks,
    );

    this.accountsPanelElement.appendChild(this.accountsPanel.element);
  }

  destroyAccountListPanel(): void {
    if (this.accountsPanel) {
      this.accountsPanel.destroy();
    }

    this.accountsPanel = null;
  }

  accountSettingsCallback(_buttonId: string, _accountId: string): void {
    if (this.dropdown.visible && this.dropdown.buttonId === _buttonId) {
      this.dropdownService.hideDropdown();
    } else {
      this.dropdownService.setData({
        accountId: _accountId,
      });

      this.dropdownService.showDropdown(_buttonId, AccountSettingsDropdownComponent, {
        addScrollCallback: true,
      });
    }
  }

  folderSettingsCallback({
    buttonId,
    accountId,
    folderId,
  }: {
    buttonId: string;
    accountId: string;
    folderId: string;
  }): void {
    if (this.dropdown.visible && this.dropdown.buttonId === buttonId) {
      this.dropdownService.hideDropdown();
    } else {
      this.dropdownService.setData({
        folder: folderId,
        account: accountId,
        button: buttonId,
      });

      this.dropdownService.showDropdown(buttonId, FolderSettingsDropdownComponent, {
        addScrollCallback: true,
        callback: (parameter) => {
          if (parameter === 'RENAME') {
            ADD_EDITED_FOLDER(accountId, folderId);
          } else if (parameter === 'DELETE') {
            const account = this.accountsPanel
              .getAccounts()
              .find((_account) => _account.id === accountId);
            const folder = account.accountFolders.find((_folder) => _folder.id === folderId);

            const modalData: TConfirmModalParams = {
              message:
                this.translationPipe.transform('delete_folder_message_1') +
                ` <strong>${folder.name}</strong> ` +
                this.translationPipe.transform('delete_folder_message_2'),
              heading: this.translationPipe.transform('delete_folder'),
              redButton: true,
              confirmText: this.translationPipe.transform('Delete'),
            };

            this.modalService.setData(modalData);

            this.modalService.showModal(ConfirmModalComponent, {
              callback: () => {
                this.accountService
                  .deleteFolder(accountId, folderId)
                  .pipe(
                    takeUntil(this.destroy$),
                    tap((response) => {
                      if (this.accountsPanel) {
                        this.accountsPanel.updateFolders(accountId, response);
                      }

                      this.promptService.showSuccess('The folder has been deleted.');
                    }),
                  )
                  .subscribe();
              },
            });
          }

          this.accountsPanel.load();
        },
      });
    }
  }

  siteSettingsCallback(_buttonId: string, _workspaceId: string): void {
    if (this.dropdown.visible && this.dropdown.buttonId === _buttonId) {
      this.dropdownService.hideDropdown();
    } else {
      this.dropdownService.setData<TSiteSettingsDropdownData>({
        workspaceId: _workspaceId,
        fromPanel: true,
      });

      this.dropdownService.showDropdown(_buttonId, SiteSettingsDropdownComponent, {
        addScrollCallback: true,
        callback: () => {
          this.sidePanelService.collapsePanel();
        },
      });
    }
  }

  openSiteCallback(_workspaceId: string): void {
    this.sidePanelService.collapsePanel();
    this.sidePanelService.switchSite(_workspaceId);
  }

  createSiteCallback(_accountId: string): void {
    logEventInGTAG(EGoogleEventSidePanel.SIDE_PANEL_NEW_SITE, {
      event_category: EGoogleEventCategory.SIDE_PANEL,
    });

    this.router.navigate(['/settings/site/new', _accountId]);

    this.sidePanelService.collapsePanel();
  }

  editFolderCallback(folder: TVirtualScrollerFolder): void {
    const body: TRequestFolder = {
      name: folder.name,
    };

    this.accountService
      .editFolder(folder.accountId, folder.id, body)
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          ADD_VISIBLE_EMPTY_FOLDER(folder.accountId, folder.name);

          this.accountsPanel.updateFolders(folder.accountId, response);

          this.promptService.showSuccess('The folder has been renamed.');
        }),
      )
      .subscribe();
  }

  createFolderCallback(folder: TVirtualScrollerFolder): void {
    const accounts = this.accountsPanel.getAccounts();
    const account = accounts.find((_account) => _account.id === folder.accountId);

    if (account.accountFolders) {
      const existingFolder = account.accountFolders.find((_folder) => _folder.name === folder.name);

      if (existingFolder) {
        ADD_VISIBLE_EMPTY_FOLDER(folder.accountId, folder.name);

        const prompt = 'The folder has been created.';

        this.promptService.showSuccess(prompt);

        this.accountsPanel.removeNewFolder(folder.accountId);
        this.accountsPanel.load();
      } else {
        this.accountService
          .addNewFolder(folder.accountId, folder.name)
          .pipe(
            takeUntil(this.destroy$),
            tap((response) => {
              ADD_VISIBLE_EMPTY_FOLDER(folder.accountId, folder.name);
              this.accountsPanel.updateFolders(folder.accountId, response);

              this.promptService.showSuccess('The folder has been created.');
            }),
          )
          .subscribe();
      }
    }
  }

  cancelCallback(accountId: string): void {
    this.accountsPanel.removeNewFolder(accountId);

    this.accountsPanel.load();
  }

  errorCallback(level: string, error: string): void {
    if (level === 'WARN') {
      this.promptService.showWarning(error);
    } else {
      this.promptService.showError(error);
    }
  }

  addSiteToFolderCallback(workspaceId: string, accountId: string, folderId: string): void {
    const body: TRequestFolder = {
      workspacesToAdd: [workspaceId],
    };

    this.accountService
      .editFolder(accountId, folderId, body)
      .pipe(
        takeUntil(this.destroy$),
        switchMap((response) => {
          SET_HIGHLIGHTED_SITE(workspaceId);
          this.accountsPanel.updateFolders(accountId, response);
          setChangedAccounts(true);

          return timer(this.highlightedSiteTimerMs).pipe(
            tap(() => {
              DELETE_HIGHLIGHTED_SITE();
              this.accountsPanel.updateFolders(accountId, response);
            }),
          );
        }),
      )
      .subscribe();
  }

  removeSiteFromFolderCallback(workspaceId: string, accountId: string): void {
    const accounts = this.accountsPanel.getAccounts();
    const account = accounts.find((_account) => _account.id === accountId);
    const folder = account.accountFolders.find((_folder) =>
      _folder.workspaceIds?.includes(workspaceId),
    );

    if (folder) {
      ADD_VISIBLE_EMPTY_FOLDER(accountId, folder.name);

      const body: TRequestFolder = {
        workspacesToDelete: [workspaceId],
      };

      this.accountService
        .editFolder(accountId, folder.id, body)
        .pipe(
          takeUntil(this.destroy$),
          switchMap((response) => {
            SET_HIGHLIGHTED_SITE(workspaceId);
            setChangedAccounts(true);
            this.accountsPanel.updateFolders(accountId, response);

            return timer(this.highlightedSiteTimerMs).pipe(
              tap(() => {
                DELETE_HIGHLIGHTED_SITE();
                this.accountsPanel.updateFolders(accountId, response);
              }),
            );
          }),
        )
        .subscribe();
    }
  }

  createNewFolder(accountId: string): void {
    this.accountsPanel.addNewFolder(accountId);
  }

  updateSiteName(siteId: string, siteName: string): void {
    if (this.accountsPanel) {
      // can be triggered through site top bar and there is no accounts panel
      this.accountsPanel.updateSiteName(siteId, siteName);
    }
  }
}
