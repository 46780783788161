<div class="dashletActivity">
  <img
    class="dashletActivity__icon dashletActivity__icon--narrow"
    [src]="EIconPath.ACTIVITY_DELETE"
  />

  <div class="dashletActivity__text">
    {{ 'removed_point' | ppTranslate }}
  </div>
</div>
