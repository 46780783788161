import * as DOMPurify from 'dompurify';
import i18next, { InitOptions, TOptionsBase } from 'i18next';

// options from i18next.t, exported to type to make it shorter
export type TTranslateOptions = TOptionsBase &
  InitOptions & {
    returnDetails?: true;
    returnObjects?: true;
  } & {
    [key: string]: string | number;
  };

export function translate(value: string, options?: TTranslateOptions): string {
  if (options) {
    return DOMPurify.sanitize(i18next.t(value, options));
  } else {
    return DOMPurify.sanitize(i18next.t(value));
  }
}
