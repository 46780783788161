<div class="siteIntegration__text">{{ 'integration_voly_description' | ppTranslate }}</div>

<pp-button
  (ppAction)="onIntegrationStart()"
  ppStyle="primary"
  ppSize="large"
  [ppDisabled]="ppDisabled"
  [ppTooltipTitle]="ppDisabled ? ('integration_button_disabled_tooltip' | ppTranslate) : ''"
  >{{ 'get_started' | ppTranslate }}</pp-button
>
