<div class="warningBox">
  <pp-icon
    [ppSrc]="EIconPath.ICON_BADGE_WARNING_18"
    ppColor="inherit"
    ppClass="setSize18"
  ></pp-icon>

  <section class="warningBox__text">
    {{ ppOpeningMessageKey | ppTranslate }}
    <span *ngFor="let warning of ppWarningMessages">{{ warning }}</span>
    {{ ppClosingMessageKey | ppTranslate }}
  </section>
</div>
