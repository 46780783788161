import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { MultipleInputSelectOptionComponent } from './multiple-input-select-option/multiple-input-select-option.component';
import { MultipleInputSelectComponent } from './multiple-input-select.component';

@NgModule({
  declarations: [MultipleInputSelectComponent, MultipleInputSelectOptionComponent],
  imports: [CommonModule, PipesModule],
  exports: [MultipleInputSelectComponent],
})
export class MultipleInputSelectModule {}
