<pp-button-dropdown
  [ppSrc]="EIconPath.ICON_TABLE_EXPORT"
  [ppText]="'export' | ppTranslate"
  [ppActive]="false"
  (ppAction)="exportTimeline()"
  ppTooltip
  id="timelineExportBtn"
  data-m-target="Timeline Export Button"
  [ppTitle]="'export' | ppTranslate"
></pp-button-dropdown>
