import dayjs from 'dayjs';
import { transformDate } from 'src/app/project/shared/date-transformer';
import { TPoint } from '../../points/points.model';
import { GET_PREFERENCES } from '../../preferences/preferences.store';

export function fillCreatedValue(point: TPoint): string {
  if (point.header && dayjs(point.header.createdEpochMillis).isValid()) {
    const preferences = GET_PREFERENCES();

    return transformDate({
      value: dayjs(point.header.createdEpochMillis),
      inputHourFormat: '',
      format: preferences.dateFormat,
      localTime: false,
    });
  }

  return '';
}
