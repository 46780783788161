import { TFilters } from '../site-filter.model';

export function compareFilterSites(firstFilter: TFilters, secondFilter: TFilters): boolean {
  const sites1 = firstFilter.sites;
  const sites2 = secondFilter.sites;

  if (sites1.length !== sites2.length) {
    return false;
  }

  for (let index = 0; index < sites1.length; index++) {
    const site1 = sites1[index];
    const site2 = sites2.find((site) => site.accountName === site1.accountName);

    if (!site2) {
      return false;
    }

    for (let workspacesIndex = 0; workspacesIndex < site1.workspaces.length; workspacesIndex++) {
      const workspace1 = site1.workspaces[workspacesIndex];
      const workspace2 = site2.workspaces.find((workspace) => workspace.name === workspace1.name);

      if (!workspace2) {
        return false;
      }

      if (workspace1.value !== workspace2.value) {
        return false;
      }
    }
  }

  return true;
}
