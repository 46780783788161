<pp-password-update-new-password-page
  [(ppPasswordResetUser)]="passwordResetUser"
  [ppProcessing]="isProcessing"
  (ppUpdate)="updatePassword()"
  *ngIf="!showingCode"
></pp-password-update-new-password-page>

<pp-two-factor-authentication-code-page
  *ngIf="showingCode"
  [ppPhoneNumber]="phoneNumber"
  [ppProcessing]="isProcessing"
  (ppConfirm2FA)="updatePassword($event)"
  (ppGoBack)="goToPasswordChange()"
  [ppErrorMessage]="errorMessage"
  (ppResend)="resendCode()"
></pp-two-factor-authentication-code-page>
