import { Component, Input, OnChanges } from '@angular/core';
import { uuid } from '@core/helpers';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { TAccountUser } from 'src/app/project/modules/users/account.user.model';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { UserManagementUserOptionsDropdownComponent } from '../user-management-user-options-dropdown/user-management-user-options-dropdown.component';
import { TUserManagementUserOptionsDropdownData } from '../user-management-user-options-dropdown/user-management-user-options-dropdown.model';

@Component({
  selector: 'pp-user-management-sites-chip',
  templateUrl: './user-management-sites-chip.component.html',
  styleUrls: ['./user-management-sites-chip.component.scss'],
})
export class UserManagementSitesChipComponent implements OnChanges {
  @Input() ppUser: TAccountUser;
  @Input() ppAccountId: string;

  dropdown: TDropdown;
  disabled: boolean;
  private isAccountAdminSelected: boolean;
  private isAccountAdminActive: boolean;
  EIconPath = EIconPath;
  elementId: string = uuid();

  constructor(
    private dropdownService: DropdownService,
    private workspaceService: WorkspaceService,
    private usersService: UsersService,
  ) {
    this.dropdown = this.dropdownService.getDropdown();
  }

  ngOnChanges(): void {
    this.checkIfActiveUserIsAccountAdmin();
    this.checkIfSelectedUserIsAccountAdmin();
    this.setDisabledState();
  }

  toggleUserDropdown(event: MouseEvent): void {
    event.preventDefault();
    event.stopImmediatePropagation();

    if (this.disabled) {
      return;
    }

    if (this.dropdown.visible && this.dropdown.buttonId === this.elementId) {
      this.dropdownService.hideDropdown();
    } else {
      this.dropdownService.setData<TUserManagementUserOptionsDropdownData>({
        user: this.ppUser,
        accountId: this.ppAccountId,
        isAccountAdminSelected: this.isAccountAdminSelected,
        isAccountAdminActive: this.isAccountAdminActive,
      });

      this.dropdownService.showDropdown(
        this.elementId,
        UserManagementUserOptionsDropdownComponent,
        {},
      );
    }
  }

  private checkIfSelectedUserIsAccountAdmin(): void {
    this.isAccountAdminSelected = this.ppUser.shares.some(
      (share) => share.shareOption === EUserRole.ACCOUNT_ADMIN,
    );
  }

  private checkIfActiveUserIsAccountAdmin(): void {
    const workspaces = this.workspaceService.getWorkspaces();

    this.isAccountAdminActive = Object.values(workspaces).some((workspace) => {
      return (
        workspace.share.shareOption === EUserRole.ACCOUNT_ADMIN &&
        workspace.accountId === this.ppAccountId
      );
    });
  }

  private setDisabledState(): void {
    const user = this.usersService.getUser(this.ppUser.userId);

    this.disabled = this.isAccountAdminSelected && this.isAccountAdminActive && user.verified;
  }
}
