import { Component, Input } from '@angular/core';
import { uuid } from '@core/helpers';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { SelectDropdownComponent } from 'src/app/project/components/input/select/select-dropdown/select-dropdown.component';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EDIT_SIMPLE_SHARE_DROPDOWN_WIDTH } from '../../../account/account-settings/account-user-modal/edit-account-user-modal.consts';
import { ESemiSimplePermission } from '../../../share/permissions.consts';
import {
  convertSemiGranularPermissionToSemiSimplePermission,
  convertSemiSimplePermissionToSemiGranularPermission,
} from '../../../share/share-utils/permissions-converter.utils';
import { TShare, TUpdateShareDTO } from '../../../share/share.model';
import { EColumn } from '../../user-management.consts';
import { UserManagementTableCellsService } from '../user-management-table-cells.service';
import { checkIfCellEditable } from '../utils/check-if-cell-editable';
import { getDropdownDataForColumn } from '../utils/dropdown-data.utils';
import { getDisabledUserManagementTooltipText } from '../utils/get-disabled-user-management-cell-tooltip-text';
import { getViewDataByPermission } from '../utils/permission-view-data.util';
import { TPermissionItemByColumn } from '../utils/permissions-per-column.utils';
import { createPartialShareDTOForExports } from '../utils/share-for-column.utils';

@Component({
  selector: 'pp-user-management-export-cell',
  templateUrl: './user-management-export-cell.component.html',
  styleUrls: ['./user-management-export-cell.component.scss'],
})
export class UserManagementExportCellComponent {
  @Input() ppShare: TShare;

  private column = EColumn.EXPORT;
  private permission: ESemiSimplePermission;
  buttonId: string = uuid();
  iconPath: EIconPath;
  text: string;
  editable: boolean;
  disabledTooltipText: string;
  constructor(
    private dropdownService: DropdownService,
    private userManagementTableCellsService: UserManagementTableCellsService,
  ) {}

  ngOnChanges(): void {
    this.setPermissionFromShare();
    this.setPermissionViewData();
    this.setEditable();
    this.disabledTooltipText = getDisabledUserManagementTooltipText(this.ppShare.shareOption);
  }

  private setPermissionFromShare(): void {
    const semiGranularPermission = this.ppShare.advancedAccessLevels.exports.permission;
    const semiSimplePermission =
      convertSemiGranularPermissionToSemiSimplePermission(semiGranularPermission);

    this.permission = this.tryConvertViewPermissionToAllow(semiSimplePermission);
  }

  private tryConvertViewPermissionToAllow(
    permission: ESemiSimplePermission,
  ): ESemiSimplePermission {
    if (permission === ESemiSimplePermission.VIEW) {
      return ESemiSimplePermission.ALLOW;
    }

    return permission;
  }

  private setPermissionViewData(): void {
    const permission = getViewDataByPermission(this.permission);

    this.iconPath = permission.iconPath;
    this.text = permission.text;
  }

  toggleDropdown(): void {
    if (!this.editable) {
      return;
    }

    if (this.dropdownService.getDropdown().visible) {
      this.dropdownService.hideDropdown();
    } else {
      this.setDropdownData();

      this.dropdownService.showDropdown(this.buttonId, SelectDropdownComponent, {
        callback: (permission: TPermissionItemByColumn) => this.trySelectPermission(permission),
        width: EDIT_SIMPLE_SHARE_DROPDOWN_WIDTH,
      });
    }
  }

  private setDropdownData(): void {
    this.dropdownService.setData(
      getDropdownDataForColumn(this.column, this.ppShare.shareOption, this.permission),
    );
  }

  private trySelectPermission(permissionItem: TPermissionItemByColumn): void {
    if (permissionItem.value === this.permission) {
      return;
    }

    this.setPermission(permissionItem.value as ESemiSimplePermission);
    this.setPermissionViewData();
    this.updateShare(permissionItem.value as ESemiSimplePermission);
  }

  private setPermission(permission: ESemiSimplePermission): void {
    this.permission = this.tryConvertViewPermissionToAllow(permission);
  }

  private updateShare(permission: ESemiSimplePermission): void {
    this.userManagementTableCellsService
      .updateShare(
        this.ppShare.userId,
        this.ppShare.shareId,
        this.createPartialShareDTO(permission),
      )
      .subscribe();
  }

  private createPartialShareDTO(permission: ESemiSimplePermission): TUpdateShareDTO {
    return createPartialShareDTOForExports(
      convertSemiSimplePermissionToSemiGranularPermission(permission),
    );
  }

  private setEditable(): void {
    this.editable = checkIfCellEditable(this.ppShare.shareOption);
  }
}
