import { createElement } from 'src/app/core/helpers/dom';
import { EBasicFieldShort } from '../../../../../../shared/enums/basic-fields-enums';
import { createHeaderTooltip } from './create-header-tooltip';
import { createSortingIndicatorElement } from './create-sorting-indicator-element';
import { TColumn } from '../../../columns/column.model';

export function createHeaderPriorityCellInnerElement(column: TColumn): HTMLElement {
  const sortingElement = column?.sortOrder
    ? createSortingIndicatorElement(column.sortIndex, column.sortOrder)
    : '';

  const nameElement = createElement<HTMLElement>('span', {
    attrs: {
      class: 'site-table__sum__title site-table__sum__title--centered',
      id: 'sortingElement',
    },
    children: [EBasicFieldShort.PRIORITY],
  });

  const wrapperElement = createElement<HTMLElement>('div', {
    attrs: {
      class: 'site-table__sum__wrapper',
    },
    children: [nameElement, sortingElement],
  });

  const cellNameElement = createElement<HTMLElement>('span', {
    attrs: {
      class: 'table__head__cell--inner table__head__cell--inner--NoPadding',
    },
    children: [wrapperElement],
  });

  const cellInnerElement = createElement<HTMLElement>('div', {
    attrs: {
      class:
        'table__cell__inner__namedColumn table__cell__inner table__cell__inner--noPadding table__head__cell',
    },
    children: [cellNameElement],
  });

  createHeaderTooltip('Priority', cellNameElement, false);

  return cellInnerElement;
}
