import { TAccountFeatures } from '../account.model';

export function getDefaultAccountFeatures(): TAccountFeatures {
  return {
    offline: false,
    pdfSignatures: false,
    globalSitePreferences: false,
    timeline: false,
    reminders: false,
    aalSitePlan: false,
    aalExport: false,
    pdfYcoLayout: false,
    savedViews: false,
    advancedFilters: false,
    fleetManagement: false,
  };
}
