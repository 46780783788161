import { Component, Input } from '@angular/core';
import { TAssetSite } from '../../../../asset-service/asset.consts';

@Component({
  selector: 'pp-asset-card-sites',
  templateUrl: './asset-card-sites.component.html',
  styleUrls: ['./asset-card-sites.component.scss'],
})
export class AssetCardSitesComponent {
  @Input() ppSites: TAssetSite[];
}
