import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EUserRole } from '../../share/share-utils/user-roles';
import { UserService } from '../../user/user.service';
import { WorkspaceService } from '../../workspace/workspace.service';
import { TSavedView } from '../models/saved-view.model';
import { SavedViewsService } from '../saved-views.service';

@Component({
  selector: 'pp-save-to-view-button',
  templateUrl: './save-to-view-button.component.html',
  styleUrls: ['./save-to-view-button.component.scss'],
})
export class SaveToViewButtonComponent implements OnChanges {
  @Output() ppSaveToCurrentView = new EventEmitter<void>();
  @Input() ppWorkspaceId: string;
  @Input() ppProcessing = false;

  view: TSavedView;
  viewEditable: boolean;
  tooltipTitle: string;

  constructor(
    private savedViewsService: SavedViewsService,
    private workspaceService: WorkspaceService,
    private translationPipe: TranslationPipe,
    private userService: UserService,
  ) {}

  ngOnChanges(): void {
    const activeViewId = this.savedViewsService.getSelectedViewId(this.ppWorkspaceId);

    this.view = this.savedViewsService.getView(activeViewId);
    this.checkViewPermissions();
    this.generateTooltipTitle();
  }

  saveToCurrentView(): void {
    this.ppSaveToCurrentView.emit();
  }

  private checkViewPermissions(): void {
    const workspaceData = this.workspaceService.getWorkspace(this.ppWorkspaceId);
    const user = this.userService.getUser();

    if (!this.view) {
      this.viewEditable = false;
    } else {
      this.viewEditable =
        workspaceData.share?.shareOption === EUserRole.SITE_ADMIN ||
        workspaceData.share?.shareOption === EUserRole.OWNER ||
        workspaceData.share?.shareOption === EUserRole.ACCOUNT_ADMIN ||
        user.isSuperUser ||
        this.view.viewType === 'PERSONAL';
    }
  }

  private generateTooltipTitle(): void {
    if (this.view && this.viewEditable) {
      this.tooltipTitle = null;
    } else if (this.view && !this.viewEditable) {
      this.tooltipTitle = this.translationPipe.transform('view_update_permission_error');
    } else {
      this.tooltipTitle = this.translationPipe.transform('no_view_applied');
    }
  }
}
