import { cloneDeep } from 'lodash';
import { TReminder } from 'src/app/project/modules/reminders/reminder.model';
import { TReminderList } from '../reminder-list.model';

export function pushFutureReminder(
  futureReminders: TReminderList,
  reminderDateFormatted: string,
  reminderDate: Date,
  reminder: TReminder,
): TReminderList {
  const updatedReminders = cloneDeep(futureReminders);

  if (updatedReminders.numberOfDisplayedValues < updatedReminders.maxValues) {
    const existingDate = updatedReminders.values.find(
      (date) => date.formattedDate === reminderDateFormatted,
    );

    if (!existingDate) {
      updatedReminders.values.push({
        formattedDate: reminderDateFormatted,
        date: reminderDate,
        values: [reminder.reminderId],
      });
    } else {
      if (!existingDate.values.includes(reminder.reminderId)) {
        existingDate.values.push(reminder.reminderId);
      }
    }

    updatedReminders.numberOfDisplayedValues++;
  }

  updatedReminders.totalValues++;

  return updatedReminders;
}
