<div class="descriptionActivity" [class.activityChange__comment]="ppComment">
  <span (ppOnRendered)="initEditor($event)"></span>

  <span
    class="ppLink activityChange__show-more-less"
    (click)="expandDescription()"
    *ngIf="!expanded && long"
  >
    {{ 'show_more' | ppTranslate }}
  </span>

  <span
    class="ppLink activityChange__show-more-less"
    (click)="collapseDescription()"
    *ngIf="expanded && long"
  >
    {{ 'show_less' | ppTranslate }}
  </span>
</div>
