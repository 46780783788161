export let active: {
  userId?: string;
  accountId?: string;
  workspaceId?: string;
  _id?: string;
} = {};

// GETTERS

export const GET_ACTIVE = () => active;

// SETTERS

export const SET_ACTIVE = (_active) => {
  active = _active;
};

// Clear

export const CLEAR_ACTIVE = () => {
  active = {};
};
