import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { TAccountUser } from 'src/app/project/modules/users/account.user.model';
import { humanizeShareOption } from '../../../share/share-utils/humanize-share';
import { EUserRole } from '../../../share/share-utils/user-roles';
import { UsersService } from '../../../users/users.service';
import { WorkspaceService } from '../../../workspace/workspace.service';

@Injectable({
  providedIn: 'root',
})
export class AccountUsersTableService {
  supportEmail = 'support@pinpointworks.com';

  constructor(private workspaceService: WorkspaceService, private usersService: UsersService) {}

  searchAccountUsers(users: TAccountUser[], keyword: string): TAccountUser[] {
    const clonedUsers = cloneDeep(users);
    const filteredUsers = this.filterOutSupportEmailFromUsers(clonedUsers);

    if (!keyword || keyword.length === 0) {
      return filteredUsers;
    } else {
      return filteredUsers.filter((accountUser) => {
        const userMatch = this.checkUserMatch(accountUser, keyword);

        if (userMatch) {
          return true;
        }

        return this.checkShareMatch(accountUser, keyword);
      });
    }
  }

  private filterOutSupportEmailFromUsers(users: TAccountUser[]): TAccountUser[] {
    return users.filter((user) => user.email !== this.supportEmail);
  }

  private checkShareMatch(accountUser: TAccountUser, keyword: string): boolean {
    accountUser.shares = accountUser.shares.filter((share) => {
      const workspaceName = this.workspaceService.getWorkspace(share.workspaceId).siteName;
      const roleName = humanizeShareOption(share.shareOption as EUserRole);

      const workspaceNameMatch = workspaceName.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
      const roleNameMatch = roleName.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;

      return workspaceNameMatch || roleNameMatch;
    });

    return accountUser.shares.length > 0;
  }

  private checkUserMatch(accountUser: TAccountUser, keyword: string): boolean {
    const user = this.usersService.getUser(accountUser.userId);
    const verifiedUserName = user?.userName;

    const userNameMatch = verifiedUserName
      ? verifiedUserName.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
      : false;
    const userEmailMatch = accountUser.email.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;

    return userNameMatch || userEmailMatch;
  }
}
