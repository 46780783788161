import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TAttachments } from '../attachments/attachments.model';

import { GalleryOverlayService } from 'src/app/project/components/gallery-overlay/gallery-overlay.service';
import { PointsService } from '../points.service';
import { EStore } from 'src/app/project/shared/enums/store.enum';

@Component({
  selector: 'pp-point-gallery',
  templateUrl: './point-gallery.component.html',
  styleUrls: ['./point-gallery.component.scss'],
})
export class PointGalleryComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  _id = '';
  attachmentId = '';

  private attachments$: Observable<TAttachments>;
  attachments: TAttachments;

  constructor(
    private store: Store<{
      attachments: TAttachments;
    }>,
    private activatedRoute: ActivatedRoute,
    private galleryOverlayService: GalleryOverlayService,
    private pointsService: PointsService,
  ) {
    this.attachments$ = this.store.pipe(select(EStore.ATTACHMENTS));
  }

  ngOnInit(): void {
    this.activatedRoute.parent.params.subscribe((params) => {
      this._id = params._id;
    });

    this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.attachmentId = params.attachmentId;

      if (this.attachments && this.attachmentId) {
        this.openGallery();
      }
    });

    this.attachments$.pipe(takeUntil(this.destroy$)).subscribe((attachments) => {
      this.attachments = attachments;

      if (this.attachments && this.attachmentId) {
        this.openGallery();
      }
    });
  }

  ngOnDestroy() {
    this.galleryOverlayService.hide();

    this.galleryOverlayService.removeListeners();

    this.destroy$.next();
  }

  openGallery(): void {
    const point = this.pointsService.findPoint(this._id);

    this.galleryOverlayService.openGallery({
      _id: this._id,
      attachmentId: this.attachmentId,
      workspaceId: point.workspaceRef.id,
    });

    this.galleryOverlayService.addListeners();
  }
}
