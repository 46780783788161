import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { Modal, ModalService } from '../../../components/modal/modal.service';
import { PromptService } from '../../../components/prompt/prompt.service';
import { SiteDataService } from '../../site/site-data.service';
import { TagsService } from '../tags.service';

import { SortingService } from '@core/helpers';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TTag } from 'src/app/project/data-providers/api-providers/tag-api-provider/tag-usage.model';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSettings,
} from 'src/app/project/services/analytics/google-analytics.consts';

@Component({
  selector: 'pp-delete-tag',
  templateUrl: './delete-tag-modal.component.html',
  styleUrls: ['./delete-tag-modal.component.scss'],
})
export class DeleteTagModalComponent implements OnInit, OnDestroy {
  modal: Modal;
  tag: TTag;
  info: string;

  usersNumber: number;

  constructor(
    private store: Store,
    private siteDataService: SiteDataService,
    private promptService: PromptService,
    private tagsService: TagsService,
    private modalService: ModalService,
    private workspaceService: WorkspaceService,
    private activeService: ActiveService,
    private translationPipe: TranslationPipe,
    private sortingService: SortingService,
  ) {}

  ngOnInit() {
    this.modal = this.modalService.getModal();
    this.tag = this.modal.data;
    this.generateInfo(this.tag);
    this.info = this.getInfo();
  }

  ngOnDestroy() {
    this.clearTag();
    this.clearInfo();
  }

  deleteTag(): void {
    if (!this.modal.processing) {
      this.modalService.setModal({ processing: true });

      const workspaceId = this.activeService.getActiveWorkspaceId();
      const workspaces = this.workspaceService.getWorkspaces();
      const index = workspaces[workspaceId].tags.indexOf(this.tag.name);

      workspaces[workspaceId].tags.splice(index, 1);

      const tags = { value: workspaces[workspaceId].tags };

      this.siteDataService
        .deleteTags(workspaceId, [this.tag.name], true)
        .pipe(
          tap(() => {
            const promptText = this.translationPipe.transform('prompt_tag_delete');

            this.tagsService.deleteTag(this.tag);

            logEventInGTAG(EGoogleEventSettings.SETTINGS__TAGS__DELETE, {
              event_category: EGoogleEventCategory.SETTINGS,
            });

            this.promptService.showSuccess(promptText);
            this.modalService.setModal({ processing: false });
            this.modalService.hideModal();
          }),
          catchError((error) => {
            const promptText = this.translationPipe.transform('prompt_tag_delete_error');

            this.promptService.showError(promptText);
            this.modalService.setModal({ processing: false });

            return of(error);
          }),
        )
        .subscribe();
    }
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  getTag(): TTag {
    return this.tag;
  }

  clearTag(): TTag {
    this.tag = null;

    return this.getTag();
  }

  getInfo(): string {
    return this.info;
  }

  setInfo(info: string): string {
    this.clearInfo();

    this.info = info;

    return this.getInfo();
  }

  clearInfo(): string {
    this.info = null;

    return this.getInfo();
  }

  generateInfo(tag: TTag): void {
    let info = '';

    const pointUsage = parseInt(tag.defects, 10);
    this.usersNumber = parseInt(tag.shares, 10);

    const pointInfo = `${pointUsage} ${this.translationPipe.transform(
      pointUsage === 1 ? 'point_lowercase' : 'points_lowercase',
    )}`;
    const userInfo = `${this.usersNumber} ${this.translationPipe.transform(
      this.usersNumber === 1 ? 'user_lowercase' : 'users_lowercase',
    )}`;

    const hasBeenTaggedTo = this.translationPipe.transform('has_been_tagged_to');

    if (pointUsage > 0 && this.usersNumber > 0) {
      info = `${hasBeenTaggedTo} ${pointInfo} ${this.translationPipe.transform(
        'and',
      )} ${userInfo}.`;
    } else if (pointUsage > 0) {
      info = `${hasBeenTaggedTo} ${pointInfo}.`;
    } else if (this.usersNumber > 0) {
      info = `${hasBeenTaggedTo} ${userInfo}.`;
    }

    this.setInfo(info);
  }
}
