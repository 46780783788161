<pp-advanced-filter-option-select
  class="advancedFilter__option"
  [ppItems]="advancedFilterDurationOptions"
  [ppOption]="option"
  (ppOptionChange)="changeFieldCondition($event)"
  ppClass="advancedFilter__option--condition"
></pp-advanced-filter-option-select>

<pp-time-input
  *ngIf="
    option === EAdvancedFilterOptions.IS ||
    option === EAdvancedFilterOptions.IS_NOT ||
    option === EAdvancedFilterOptions.IS_GREATER_THAN ||
    option === EAdvancedFilterOptions.IS_LESS_THAN
  "
  [ppCanEdit]="true"
  [ppShowHoursOnly]="showHoursOnly"
  [(ppValue)]="value"
  (ppUpdate)="changeFieldValue($event)"
  class="advancedFilter__time"
  [ppWidth]="inputWidth"
></pp-time-input>
