import { Injectable } from '@angular/core';

import { RemindersService } from '../reminders.service';

@Injectable({
  providedIn: 'root',
})
export class RemindersUtilityService {
  constructor(private remindersService: RemindersService) {}

  getIdOfNextReminderPoint(_id: string): string {
    return this.getReminderPointId(_id, true);
  }

  getIdOfPreviousReminderPoint(_id: string): string {
    return this.getReminderPointId(_id, false);
  }

  getReminderPointId(_id: string, isNextPoint: boolean): string {
    const reminders = this.remindersService.getReminders();
    const sortedReminders = this.remindersService.getSortedReminders();
    const pointIndex = this.getReminderPointIndex(_id, isNextPoint);

    return reminders.find((reminder) => reminder.reminderId === sortedReminders[pointIndex])
      .refObject.id;
  }

  getReminderPointIndex(_id: string, isNextPoint: boolean): number {
    const reminders = this.remindersService.getReminders();
    const sortedReminders = this.remindersService.getSortedReminders();

    const pointIndex = sortedReminders.findIndex((reminderId) => {
      const reminder = reminders.find((_reminder) => _reminder.reminderId === reminderId);

      return reminder.refObject?.id === _id;
    });

    const searchedPointIndex = this.findRequestedReminderPointIndex(pointIndex, isNextPoint);

    return searchedPointIndex;
  }

  findRequestedReminderPointIndex(pointIndex: number, isNextPoint: boolean): number {
    const reminders = this.remindersService.getReminders();
    const sortedReminders = this.remindersService.getSortedReminders();

    let pointFound = false;
    let searchedPointIndex;

    while (!pointFound) {
      if (isNextPoint) {
        if (!sortedReminders[pointIndex + 1]) {
          searchedPointIndex = 0;
        } else {
          searchedPointIndex = pointIndex + 1;
        }
      } else {
        if (pointIndex === 0) {
          searchedPointIndex = sortedReminders.length - 1;
        } else {
          searchedPointIndex = pointIndex - 1;
        }
      }

      if (
        reminders.find((reminder) => reminder.reminderId === sortedReminders[searchedPointIndex])
          .refObject
      ) {
        pointFound = true;
      } else {
        pointIndex = searchedPointIndex;
      }
    }

    return searchedPointIndex;
  }
}
