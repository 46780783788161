import { TSelectOption } from 'src/app/project/components/input/select-tag/select-tag.model';
import { translate } from 'src/app/project/features/translate/translate';
import { EUserRole } from './user-roles';

export type TUserLabels = {
  [role in EUserRole]: string;
};

export function getUserLabels(): TUserLabels {
  return {
    OWNER: translate('owner'),
    ADMIN: translate('admin'),
    NORMAL: translate('normal'),
    LIMITED: translate('guest'),
    ACCOUNT_ADMIN: translate('account_admin'),
  };
}

export function getUserLabelsArray(): TSelectOption[] {
  const userLabels = getUserLabels();

  return Object.keys(userLabels).map((key) => {
    return {
      value: key,
      label: userLabels[key as EUserRole],
    };
  });
}

export function getEditableUserLabelsArray(): TSelectOption[] {
  const userLabels = getUserLabels();

  return Object.keys(userLabels)
    .map((key) => {
      return {
        value: key,
        label: userLabels[key as EUserRole],
      };
    })
    .filter((user) => {
      return user.value !== EUserRole.OWNER && user.value !== EUserRole.ACCOUNT_ADMIN;
    });
}
