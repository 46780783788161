<ng-container *ngIf="user$ | async as user">
  <section class="userMenuDropdown userMenuDropdown__separator userMenuDropdown__header">
    <img
      ppImage
      [ppSecureSrc]="'api/v1/images/' + user.avatarId + '/file/size/square/40'"
      class="userMenuDropdown__avatar"
      *ngIf="user.avatarId"
    />

    <img
      [src]="EIconPath.PLACEHOLDER_USER"
      class="userMenuDropdown__avatar"
      *ngIf="!user.avatarId"
    />

    <ul class="resetList">
      <li
        ppTooltip
        class="userMenuDropdown__username"
        [ppTitle]="user.userName"
        [ppTrimTooltip]="true"
      >
        {{ user.userName }}
      </li>

      <li
        ppTooltip
        class="userMenuDropdown__user-email"
        [ppTitle]="user.email"
        [ppTrimTooltip]="true"
      >
        {{ user.email }}
      </li>
    </ul>
  </section>

  <section class="userMenuDropdown__separator">
    <button
      class="dropdownItem userDropdownItem"
      *ngIf="user.accountId"
      (click)="hideDropdown('/settings/account')"
    >
      <pp-icon
        ppColor="grey-600"
        ppClass="setSize18"
        class="userDropdownItem__icon"
        [ppSrc]="EIconPath.ICON_USER_SETTINGS_COG_18"
      ></pp-icon>
      {{ 'account_settings' | ppTranslate }}
    </button>

    <button class="dropdownItem userDropdownItem" (click)="hideDropdown('/settings/user')">
      <pp-icon
        ppColor="grey-600"
        ppClass="setSize18"
        class="userDropdownItem__icon"
        [ppSrc]="EIconPath.ICON_USER_SETTINGS_COG_18"
      ></pp-icon>
      {{ 'profile_settings' | ppTranslate }}
    </button>

    <button
      class="dropdownItem userDropdownItem"
      (click)="openCookiesSettings()"
      *ngIf="cookiesEnabled"
    >
      <pp-icon
        ppColor="grey-600"
        ppClass="setSize18"
        class="userDropdownItem__icon"
        [ppSrc]="EIconPath.ICON_USER_SETTINGS_COOKIE_18"
      ></pp-icon>
      {{ 'cookie_preferences' | ppTranslate }}
    </button>
  </section>

  <section class="userMenuDropdown__separator">
    <button
      class="dropdownItem userDropdownItem"
      [class.dropdownItem--disabled]="offline"
      (click)="openHelpCentre()"
    >
      <pp-icon
        ppColor="grey-600"
        ppClass="setSize18"
        class="userDropdownItem__icon"
        [ppSrc]="EIconPath.ICON_USER_SETTINGS_HELP_18"
      ></pp-icon>
      {{ 'help_centre' | ppTranslate }}
    </button>

    <button
      class="dropdownItem userDropdownItem"
      [class.dropdownItem--disabled]="offline"
      (click)="openChangelog()"
    >
      <pp-icon
        ppColor="grey-600"
        ppClass="setSize18"
        class="userDropdownItem__icon"
        [ppSrc]="EIconPath.ICON_USER_SETTINGS_MEGAPHONE_18"
      ></pp-icon>
      {{ 'whats_new' | ppTranslate }}
    </button>

    <button
      class="dropdownItem userDropdownItem"
      [class.dropdownItem--disabled]="offline"
      (click)="openUpgradePage()"
    >
      <pp-icon
        ppColor="grey-600"
        ppClass="setSize18"
        class="userDropdownItem__icon"
        [ppSrc]="EIconPath.ICON_USER_SETTINGS_UPGRADE_18"
      ></pp-icon>
      {{ 'upgrade' | ppTranslate }}
    </button>

    <button class="dropdownItem userDropdownItem" [class.dropdownItem--disabled]="offline">
      <a
        class="flex"
        href="mailto:support@pinpointworks.com"
        *ngIf="!offline"
        (click)="hideDropdown('contact')"
      >
        <pp-icon
          ppColor="grey-600"
          ppClass="setSize18"
          class="userDropdownItem__icon"
          [ppSrc]="EIconPath.ICON_USER_SETTINGS_CONTACT_18"
        ></pp-icon>
        {{ 'contact_us' | ppTranslate }}
      </a>

      <span *ngIf="offline">
        <pp-icon
          ppColor="grey-600"
          ppClass="setSize18"
          class="userDropdownItem__icon"
          [ppSrc]="EIconPath.ICON_USER_SETTINGS_CONTACT_18"
        ></pp-icon>
        {{ 'contact_us' | ppTranslate }}
      </span>
    </button>

    <button
      class="dropdownItem userDropdownItem"
      [class.dropdownItem--disabled]="offline"
      (click)="openHiring()"
    >
      <pp-icon
        ppColor="grey-600"
        ppClass="setSize18"
        class="userDropdownItem__icon"
        [ppSrc]="EIconPath.ICON_USER_SETTINGS_PERSON_SEARCH_18"
      ></pp-icon>
      {{ 'hiring' | ppTranslate }}
    </button>
  </section>

  <section class="userMenuDropdown__separator">
    <button
      class="dropdownItem userDropdownItem"
      [class.dropdownItem--disabled]="offline"
      (click)="logout()"
    >
      <pp-icon
        ppColor="grey-600"
        ppClass="setSize18"
        class="userDropdownItem__icon"
        [ppSrc]="EIconPath.ICON_USER_SETTINGS_LOG_OUT_18"
      ></pp-icon>
      {{ 'logout' | ppTranslate }}
    </button>
  </section>

  <footer class="userDropdown__footer">
    <img class="userDropdown__logo" [src]="EIconPath.USER_DROPDOWN_LOGO" />

    <p class="userDropdown__version">{{ 'version' | ppTranslate }}: {{ version }}</p>
  </footer>
</ng-container>
