<div class="activeViewPreview">
  <span
    ppTooltip
    [ppTitle]="activeView?.name"
    [ppTrimTooltip]="true"
    class="activeViewPreview__name"
    *ngIf="!ppHideText"
  >
    {{ activeView?.name }}
  </span>

  <span class="savedViews__icon-container" *ngIf="activeView">
    <pp-icon
      ppTooltip
      [ppMobileTooltip]="true"
      [ppTitle]="ppHideText ? activeView?.name : null"
      [ppSrc]="EIconPath.ICON_NAV_TABLE_VIEW_14"
      ppColor="grey-400"
      [ppDisplayContents]="true"
      ppClass="setSize14"
    ></pp-icon>
  </span>
</div>
