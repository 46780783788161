<h6 class="dashletFlip__title">
  {{ 'select_time_period' | ppTranslate }}
</h6>

<ul class="periodSelect">
  <li
    *ngFor="let timePeriod of periods"
    class="periodSelect__option"
    [ngClass]="{ 'periodSelect__option--active': ppPeriod === timePeriod.value }"
    (click)="updatePeriod(timePeriod.value)"
  >
    {{ timePeriod.name | ppTranslate }}
  </li>
</ul>
