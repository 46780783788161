import { Injectable, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationsService } from 'src/app/project/modules/notifications/notifications.service';
import { PointsDetailsService } from 'src/app/project/modules/points/points-details.service';
import { PointsFetchingService } from 'src/app/project/modules/points/points-fetching.service';
import { RemindersService } from 'src/app/project/modules/reminders/reminders.service';
import { SiteService } from 'src/app/project/modules/site/site.service';
import { SocketService } from 'src/app/project/modules/sockets/socket.service';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { WhiteLabelService } from 'src/app/project/modules/white-label/white-label.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { AccountService } from '../modules/account/account-service/account.service';
import { CustomFieldsService } from '../modules/custom-fields/custom-fields.service';
import { PreferencesService } from '../modules/preferences/preferences-service/preferences.service';
import { SharesService } from '../modules/share/shares.service';
import { SiteDataService } from '../modules/site/site-data.service';
import { UserService } from '../modules/user/user.service';
import { WorkspaceService } from '../modules/workspace/workspace.service';
import { CookieService } from './cookie.service';

import { CLEAR_NOTIFICATIONS } from 'src/app/project/modules/notifications/notifications.store';
import { CLEAR_TILES } from 'src/app/project/modules/plan/tiles.store';
import { CLEAR_POINTS } from 'src/app/project/modules/points/points.store';
import { CLEAR_PREFERENCES } from 'src/app/project/modules/preferences/preferences.store';
import { CLEAR_SHARES } from 'src/app/project/modules/share/shares.store';
import { CLEAR_USER } from 'src/app/project/modules/user/user.store';
import { CLEAR_USERS } from 'src/app/project/modules/users/users.store';
import { CLEAR_WORKSPACES } from 'src/app/project/modules/workspace/workspace.store';
import { CLEAR_ACTIVE } from 'src/app/project/services/active/active.store';

import { clearUserAuth } from 'src/app/core/http/user-auth';
import { clearObjectStores } from 'src/app/project/helpers/database/database';
import { AccountUsageService } from '../modules/account/account-usage/account-usage.service';
import { TwoFactorAuthenticationPhoneNumberService } from '../modules/auth/2fa/two-factor-authentication-phone-number.service';
import { logout$ } from '../modules/auth/logout.service';
import { AdvancedFilterService } from '../modules/filters-advanced/advanced-filter.service';
import { FleetService } from '../modules/fleet-management/fleet-service/fleet.service';
import { REMINDERS_ALERT_COOKIE_NAME } from '../modules/reminders/reminders.constants';
import { SavedViewsService } from '../modules/saved-views/saved-views.service';
import { TableColumnsService } from '../modules/site/site-table/columns/table-columns.service';
import { AUTH_COOKIE_NAME } from '../shared/constants/auth.constants';

@Injectable({
  providedIn: 'root',
})
export class ClearService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(
    private accountService: AccountService,
    private preferencesService: PreferencesService,
    private sharesService: SharesService,
    private siteDataService: SiteDataService,
    private userService: UserService,
    private workspaceService: WorkspaceService,
    private customFieldsService: CustomFieldsService,
    private usersService: UsersService,
    private notificationsService: NotificationsService,
    private pointsFetchingService: PointsFetchingService,
    private siteService: SiteService,
    private pointsDetailsService: PointsDetailsService,
    private socketService: SocketService,
    private cookieService: CookieService,
    private remindersService: RemindersService,
    private activeService: ActiveService,
    private whiteLabelService: WhiteLabelService,
    private savedViewsService: SavedViewsService,
    private advancedFilterService: AdvancedFilterService,
    private twoFactorAuthenticationPhoneNumberService: TwoFactorAuthenticationPhoneNumberService,
    private tableColumnsService: TableColumnsService,
    private accountUsageService: AccountUsageService,
    private fleetService: FleetService,
  ) {
    logout$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.clearAllData(true);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  clearAllData(isLogout: boolean = false): void {
    this.accountService.clearAccount();
    this.pointsFetchingService.clearPoints();
    this.pointsDetailsService.clearPointsDetails();
    this.preferencesService.clearPreferences();
    this.sharesService.clearShares();
    this.sharesService.clearWorkspaceShares();
    this.siteDataService.clearSite();
    this.userService.clearUser();
    this.workspaceService.clearWorkspaces();
    this.customFieldsService.clearCustomFields();
    this.usersService.clearUsers();
    this.notificationsService.clearNotifications();
    this.siteService.clearSitePlanWidthPreferences();
    this.socketService.disconnect();
    this.activeService.clearActiveUser();
    this.activeService.clearActiveStore();
    this.whiteLabelService.setDefaultStyle();
    this.savedViewsService.clearSavedViews();
    this.advancedFilterService.clearAdvancedFilters();
    this.twoFactorAuthenticationPhoneNumberService.clearConfirmationPhoneNumber();
    this.accountUsageService.clearUsage();
    this.fleetService.clearFleets();

    CLEAR_USER();
    CLEAR_USERS();
    CLEAR_POINTS();
    CLEAR_PREFERENCES();
    CLEAR_ACTIVE();
    CLEAR_USERS();
    CLEAR_NOTIFICATIONS();
    CLEAR_SHARES();
    this.tableColumnsService.clearColumns();
    CLEAR_WORKSPACES();
    CLEAR_TILES();

    clearObjectStores();

    this.remindersService.clearRemindersNumber();
    clearUserAuth();
    this.cookieService.clearCookie(REMINDERS_ALERT_COOKIE_NAME);
  }

  clearUserCookie(): void {
    // If it's present from previous app version logging in doesn't work
    this.cookieService.clearCookie(AUTH_COOKIE_NAME);
  }
}
