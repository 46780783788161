import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import { TShare } from 'src/app/project/modules/share/share.model';
import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import {
  EditShareModalComponent,
  TEditShareModalData,
} from 'src/app/project/modules/share/edit-share-modal/edit-share-modal.component';

import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { SyncService } from 'src/app/project/modules/offline/sync.service';
import { SharesService, TWorkspaceShares } from 'src/app/project/modules/share/shares.service';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { SiteSettingsService } from '../../site-settings-service/site-settings.service';

import { ActivatedRoute } from '@angular/router';
import { SortingService } from '@core/helpers';
import { TAllUsers } from '@project/view-models';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { getUserLabels, TUserLabels } from 'src/app/project/modules/share/share-utils/user-labels';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { SelectedSharesService } from 'src/app/project/modules/user-management/services/selected-shares.service';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSettings,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { EIconPath } from '../../../../../shared/enums/icons.enum';
import { SiteSettingsUsersService } from '../site-settings-users.service';

@Component({
  selector: 'pp-site-settings-users-table',
  templateUrl: './site-settings-users-table.component.html',
  styleUrls: ['./site-settings-users-table.component.scss'],
})
export class SiteSettingsUsersTableComponent implements OnInit, OnDestroy, OnChanges {
  private readonly destroy$ = new Subject<void>();

  @Input() ppOffline: boolean;
  @Input() ppKeyword = '';
  @Output() ppSharesLengthChange = new EventEmitter<number>();
  @Input() ppSharesLength: number;

  shareOptionLabels: TUserLabels = getUserLabels();

  workspaceShares: TWorkspaceShares;

  workspaceId = '';
  users$: Observable<TAllUsers>;
  users: TAllUsers;
  EUserRole = EUserRole;
  EIconPath = EIconPath;

  sortedFilteredShares: TShare[] = [];

  workspace: TWorkspace;
  filteredShares = [];

  isAllSelected: boolean = false;
  selectedShares: string[] = [];

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
      users: TAllUsers;
    }>,
    private sharesService: SharesService,
    private promptService: PromptService,
    private modalService: ModalService,
    private usersService: UsersService,
    private syncService: SyncService,
    private workspaceService: WorkspaceService,
    private translationPipe: TranslationPipe,
    private siteSettingsService: SiteSettingsService,
    private sortingService: SortingService,
    private activatedRoute: ActivatedRoute,
    private siteSettingsUsersService: SiteSettingsUsersService,
    private selectedSharesService: SelectedSharesService,
  ) {
    this.users$ = this.store.pipe(select(EStore.USERS));

    this.users$.pipe(takeUntil(this.destroy$)).subscribe((users) => {
      this.users = users;

      this.updateShares();
    });

    this.siteSettingsUsersService.newShareModalClosed$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateShares();
      });
  }

  ngOnInit() {
    this.users = this.usersService.getUsers();
    this.subscribeToBulkShareChanges();

    if (!(Object.keys(this.users).length > 0)) {
      this.syncService.firstLoad(this.workspaceId).pipe(takeUntil(this.destroy$)).subscribe();
    }

    this.activatedRoute.parent.paramMap.subscribe((params) => {
      this.selectedSharesService.clearSelectedShares();
      this.workspaceId = params['params'].id;
      if (this.workspaceId) {
        this.sharesService.fetchWorkspaceShares(this.workspaceId, { refetch: true }).then(() => {
          this.updateShares();
        });
      }
    });
  }

  ngOnChanges() {
    this.updateShares();
  }

  subscribeToBulkShareChanges(): void {
    this.selectedSharesService.selectedSharesIdsChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateIsAllSelected();
      });

    this.siteSettingsUsersService.selectedSharesDeleted$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateShares();
      });
  }

  updateIsAllSelected(): void {
    this.isAllSelected =
      this.filteredShares.length - 1 === this.selectedSharesService.getSelectedSharesIds().length
        ? true
        : false;
  }

  updateShares(): void {
    if (this.workspaceId) {
      this.workspace = this.workspaceService.findWorkspace(this.workspaceId);
    }

    this.workspaceShares = this.sharesService.getWorkspaceShares();

    if (this.workspaceShares) {
      this.filteredShares = this.siteSettingsService
        .searchShares(this.workspaceShares.data, this.ppKeyword)
        .filter((share) => share.shareOption !== EUserRole.OWNER);

      this.sortedFilteredShares = this.filteredShares.sort((a, b) => {
        const firstName = this.users[a.userId]?.userName
          ? this.users[a.userId]?.userName
          : this.users[a.userId]?.email;

        const secondName = this.users[b.userId]?.userName
          ? this.users[b.userId]?.userName
          : this.users[b.userId]?.email;

        return this.sortingService.naturalSort(firstName, secondName);
      });

      this.ppSharesLengthChange.emit(this.sortedFilteredShares.length);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  resendInvitation(share: TShare): void {
    this.sharesService
      .resendInvitation(share.shareId)
      .pipe(
        tap(() => {
          const promptText = this.translationPipe.transform('prompt_invitation_resend');

          logEventInGTAG(EGoogleEventSettings.SETTINGS__USER__RESEND_INVITATION, {
            event_category: EGoogleEventCategory.SETTINGS,
          });

          this.promptService.showSuccess(promptText);
        }),
        catchError(() => {
          const promptText = this.translationPipe.transform('prompt_invitation_resend_error');

          this.promptService.showError(promptText);

          return of(null);
        }),
      )
      .subscribe();
  }

  editShare(share: TShare): void {
    const modalData: TEditShareModalData = {
      share: share,
      workspaceId: this.workspaceId,
    };

    this.modalService.setData(modalData);

    this.modalService.showModal(EditShareModalComponent, {
      onClose: () => {
        this.updateShares();
      },
    });
  }

  selectAllShares(): void {
    if (this.isAllSelected) {
      this.selectedSharesService.clearSelectedShares();
    } else {
      const shareIds = this.sortedFilteredShares
        .filter(
          (share) =>
            share.shareOption !== EUserRole.OWNER && share.shareOption !== EUserRole.ACCOUNT_ADMIN,
        )
        .map((share) => share.shareId);
      this.selectedSharesService.selectAllUserShares(shareIds);
    }
  }
}
