import { Component, Input } from '@angular/core';
import { EIconPath } from '../../../../../shared/enums/icons.enum';
import { TCustomFieldAccessOption, TShare } from '../../../../share/share.model';
import { PrepareShareService } from '../account-user-modal-shares-list/account-user-modal-shares-list-share/prepare-share.service';
import { EditAccountUserModalDataService } from '../edit-account-user-modal-data.service';

@Component({
  selector: 'pp-account-user-modal-custom-fields',
  templateUrl: './account-user-modal-custom-fields.component.html',
  styleUrls: ['./account-user-modal-custom-fields.component.scss'],
})
export class AccountUserModalCustomFieldsComponent {
  @Input() ppWorkspaceId: string;

  share: TShare;
  EIconPath = EIconPath;
  shareBackup: TShare;

  constructor(
    private prepareShareService: PrepareShareService,
    private editAccountUserModalDataService: EditAccountUserModalDataService,
  ) {}

  ngOnInit(): void {
    this.prepareShare();
  }

  updateShare(): void {
    this.editAccountUserModalDataService.setShare(this.ppWorkspaceId, this.share);
  }

  updateCustomField(index: number, customField: TCustomFieldAccessOption): void {
    this.share.advancedAccessLevels.customFields[index] = customField;
    this.updateShare();
  }

  private prepareShare(): void {
    this.share = this.prepareShareService.prepareShare(this.ppWorkspaceId);
  }
}
