import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { AccountService } from '../account/account-service/account.service';
import { UserService } from '../user/user.service';
import { WorkspaceService } from '../workspace/workspace.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoginRedirectService {
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private workspaceService: WorkspaceService,
    private accountService: AccountService,
  ) {}

  redirectAfterLogin(): void {
    if (this.tryRedirectToCachedUrl()) {
      return;
    }

    if (this.tryRedirectToActiveWorkspace()) {
      return;
    }

    this.redirectToAvailableWorkspaceOrUserSettings();
  }

  private redirectToAvailableWorkspaceOrUserSettings(): void {
    this.workspaceService
      .fetchWorkspaces()
      .pipe(
        tap(() => {
          if (this.tryRedirectToAvailableWorkspace()) {
            return;
          }

          this.redirectToUserSettings();
        }),
      )
      .subscribe();
  }

  private tryRedirectToAvailableWorkspace(): boolean {
    const accounts = this.accountService.getAccounts();
    const accountWithWorkspaces = accounts?.find((account) => !!account.workspaces.length);

    if (accountWithWorkspaces) {
      this.router.navigate(['/site', accountWithWorkspaces.workspaces[0]]);

      return true;
    }

    return false;
  }

  private redirectToUserSettings() {
    this.router.navigate(['/settings/user']);
  }

  private tryRedirectToCachedUrl(): boolean {
    const cachedUrlPath = this.authService.getCachedUrlPath();

    if (cachedUrlPath) {
      this.router.navigateByUrl(cachedUrlPath);
      return true;
    }

    return false;
  }

  private tryRedirectToActiveWorkspace(): boolean {
    const user = this.userService.getUser();

    if (user.activeWorkspaceId) {
      this.router.navigate(['/site', user.activeWorkspaceId]);
      return true;
    }

    return false;
  }
}
