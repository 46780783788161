import { GET_TABLE } from '../../../../table.ui.store';
import { createElement } from 'src/app/core/helpers/dom';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { getSvg } from 'src/app/core/helpers/get-svg';
import { ApiService } from '@core/http';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TableGroupDropdownComponent } from '../../../../table-group-dropdown/table-group-dropdown.component';
import { TTableGroupDropdownData } from '../../../../table-group-dropdown/table-group-dropdown.model';

export function createOptionsElement(
  _index: number,
  apiService: ApiService,
  dropdownService: DropdownService,
): HTMLElement {
  const table = GET_TABLE();
  const item = table.items[_index];
  const classList = ['table__headerOptions'];
  const buttonId = 'table__headerOptions__button' + item.id;

  const iconWrapper = createElement('div', {
    attrs: {
      class: 'table__headerOptions__button',
    },
    children: [
      createElement('img', {
        attrs: {
          src: EIconPath.ICON_TABLE_OPTIONS,
        },
      }),
    ],
  });

  const element = createElement('div', {
    attrs: {
      class: classList,
      id: buttonId,
    },
    eventListeners: {
      click: () => {
        dropdownService.setData<TTableGroupDropdownData>({
          index: _index,
          item: item,
        });

        dropdownService.showDropdown(buttonId, TableGroupDropdownComponent);
      },
    },
    children: [iconWrapper],
  });

  getSvg(apiService, EIconPath.ICON_THREE_DOTS).then((svg) => {
    iconWrapper.innerHTML = svg;
  });

  return element;
}
