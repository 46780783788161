import { TPoint } from '../../../points/points.model';
import { isPointFlagged } from '../../../points/utils/is-flagged';
import { TColumn } from '../columns/column.model';
import { sortBoolean } from './sort-boolean';
import { sortTitle } from './sort-title';
import { sort } from './sorting';

export function sortFlagged(
  firstPoint: TPoint,
  secondPoint: TPoint,
  sortColumns: Partial<TColumn>[],
  index: number,
): number {
  const firstPointFlag = isPointFlagged(firstPoint);
  const secondPointFlag = isPointFlagged(secondPoint);

  const result = sortBoolean(firstPointFlag, secondPointFlag, sortColumns, index);

  if (result) {
    return -result; //PS-1745 Reverse order for flagged points
  }

  if (sortColumns[index + 1]) {
    return sort(firstPoint, secondPoint, sortColumns, index + 1);
  } else {
    return sortTitle(firstPoint, secondPoint, sortColumns, index);
  }
}
