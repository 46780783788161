<pp-modal [ppScrollOnDefault]="true" [ppWidth]="690">
  <ng-container heading>
    {{ (modalType === ECopyMoveModalType.COPY ? 'copy_points' : 'move_points') | ppTranslate }}

    <div
      ppTooltip
      [ppOptions]="{ placement: 'right' }"
      [ppMobileTooltip]="true"
      [ppSanitize]="false"
      ppTitle="
<div style='text-align: left'>
{{
        (modalType === ECopyMoveModalType.COPY ? 'copy_points_tooltip_1' : 'move_points_tooltip_1')
          | ppTranslate
      }}
<br>
<br>
{{
        (modalType === ECopyMoveModalType.COPY ? 'copy_points_tooltip_2' : 'move_points_tooltip_2')
          | ppTranslate
      }}
</div>"
      class="iconWrapper"
    >
      <pp-icon
        [ppSrc]="EIconPath.ICON_BADGE_INFO_WHITE"
        ppColor="white"
        [ppDisplayContents]="true"
        class="info__icon"
      ></pp-icon>
    </div>
  </ng-container>

  <ng-container body>
    <div *ngIf="firstStep">
      <p class="copyMove__header">{{ 'select_destination_site' | ppTranslate }}</p>
      <pp-select-grouped
        [ppItems]="items"
        [ppSelectedItem]="{ name: selectedWorkspaceName, id: selectedWorkspaceId }"
        (ppSelectItem)="selectItem($event)"
      >
        <ng-container selected>
          <h6 class="selectList__selected" *ngIf="selectedWorkspaceName">
            {{ selectedWorkspaceName }}
          </h6>

          <h6 class="selectList__selected" *ngIf="!selectedWorkspaceName">
            {{ 'select_a_site' | ppTranslate }}
          </h6>
        </ng-container>
      </pp-select-grouped>
    </div>

    <div *ngIf="secondStep && !longProcessing">
      <header class="conflict__header">
        {{
          (modalType === ECopyMoveModalType.COPY ? 'copy_points_to_the' : 'move_points_to_the')
            | ppTranslate
        }}
        {{ selectedWorkspaceName }}
        {{ 'copy_move_points_to_the_site' | ppTranslate }}.
      </header>

      <div class="size_error" *ngIf="totalSize > maxAttachmentsSize">
        <div class="warningIconWrapper">
          <pp-icon [ppSrc]="EIconPath.ICON_BANNER_WARNING" [ppDisplayContents]="true"></pp-icon>
        </div>

        <div>
          <ng-container *ngIf="modalType === ECopyMoveModalType.COPY">
            {{ 'copy_size_error_1' | ppTranslate }} <span class="bold_red">{{ sizeMB }}MB</span>.
            {{ 'copy_size_error_2' | ppTranslate }}
            <span class="bold">{{ maxAttachmentSizeMB }}MB</span>.
            {{ 'copy_size_error_3' | ppTranslate }}
          </ng-container>

          <ng-container *ngIf="modalType === ECopyMoveModalType.MOVE">
            {{ 'move_size_error_1' | ppTranslate }} <span class="bold_red">{{ sizeMB }}MB</span>.
            {{ 'move_size_error_2' | ppTranslate }}
            <span class="bold">{{ maxAttachmentSizeMB }}B</span>.
            {{ 'move_size_error_3' | ppTranslate }}
          </ng-container>
        </div>
      </div>

      <pp-field-conflict-list
        [ppFieldConflicts]="fieldConflicts"
        [ppPointPinsToBeCentered]="pointPinsToBeCentered"
        [ppSelectedWorkspaceName]="selectedWorkspaceName"
      ></pp-field-conflict-list>

      <div class="copyMove__warning" *ngIf="integrationWarning">
        <pp-warning-box
          [ppOpeningMessageKey]="
            modalType === ECopyMoveModalType.COPY
              ? 'copy_point_integration_warning'
              : 'move_point_integration_warning'
          "
        ></pp-warning-box>
      </div>
    </div>

    <div class="copyMove__extras" *ngIf="!longProcessing">
      <header class="copyMove__header">{{ 'include' | ppTranslate }}</header>

      <div *ngFor="let item of itemsToInclude">
        <pp-checkbox
          class="copyMove__extras__option"
          [ppChecked]="item.selected"
          (ppOnValueChange)="updateItemsToInclude(item)"
        >
          {{ item.label | ppTranslate }}
          <span class="copyMove__extras__option__size">{{
            item.sizeMB !== null ? ' - ' + item.sizeMB + 'MB' : ''
          }}</span>
        </pp-checkbox>
      </div>
    </div>

    <h6 *ngIf="longProcessing" class="conflict__list-header conflict__list-header--site">
      {{ 'request_being_processed' | ppTranslate }}
    </h6>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      *ngIf="firstStep"
      [ppHideCancel]="processing"
      [ppConfirmText]="(modalType === ECopyMoveModalType.COPY ? 'copy' : 'move') | ppTranslate"
      (ppCancel)="hideModal()"
      (ppConfirm)="check()"
      [ppProcess]="processing"
      [ppDisabled]="!selectedWorkspaceId"
    ></pp-modal-generic-footer>

    <pp-modal-generic-footer
      *ngIf="secondStep"
      [ppConfirmText]="'confirm' | ppTranslate"
      [ppHideCancel]="processing"
      (ppCancel)="hideModal()"
      (ppConfirm)="confirm()"
      [ppProcess]="processing"
      [ppDisabled]="!selectedWorkspaceId || totalSize > maxAttachmentsSize"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
