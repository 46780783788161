import { Injectable } from '@angular/core';
import { TWorkspaceResponse } from '@project/view-models';
import { catchError, Observable, tap } from 'rxjs';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { PromptService } from '../../components/prompt/prompt.service';
import { IntegrationsApiProviderService } from '../../data-providers/api-providers/integrations-api-provider/integrations-api-provider.service';
import { TranslationPipe } from '../../features/translate/translation.pipe';
import { getErrorMessage } from '../../helpers/database/get-error-message';
import { CustomFieldsService } from '../custom-fields/custom-fields.service';
import { ResponseErrorService } from '../errors/response-error.service';
import { EVolyIntegrationStatus } from './utils/integrations.consts';

@Injectable({
  providedIn: 'root',
})
export class VolyIntegrationService {
  constructor(
    private integrationsApiProviderService: IntegrationsApiProviderService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private responseErrorService: ResponseErrorService,
    private customFieldsService: CustomFieldsService,
  ) {}

  changeIntegrationStatus(
    workspaceId: string,
    integrationStatus: EVolyIntegrationStatus,
  ): Observable<TWorkspaceResponse> {
    return this.integrationsApiProviderService
      .changeVolyIntegrationStatus(workspaceId, integrationStatus)
      .pipe(
        tap(() => {
          const prompt = this.translationPipe.transform('integration_status_change_success');

          this.promptService.showSuccess(prompt);
        }),
        catchError((error) => {
          switch (error.status) {
            case EStatusCode.BAD_REQUEST:
            case EStatusCode.FORBIDDEN:
              getErrorMessage(error).then((message) => {
                this.promptService.showError(message);
              });
              break;
            default:
              const prompt = this.translationPipe.transform('integration_status_change_error');
              this.promptService.showError(prompt);
              break;
          }

          return this.responseErrorService.handleRequestError(error);
        }),
      );
  }

  changeCustomFieldVisibility(
    workspaceId: string,
    customFieldId: string,
    display: boolean,
  ): Observable<void> {
    return this.integrationsApiProviderService
      .changeVolyCustomFieldVisibility(workspaceId, customFieldId, display)
      .pipe(
        tap(() => {
          const prompt = this.translationPipe.transform('custom_field_visibility_change_success');

          this.promptService.showSuccess(prompt);

          this.customFieldsService.updateWorkspaceCustomFieldVisibility(
            workspaceId,
            customFieldId,
            display,
          );
        }),
        catchError((error) => {
          switch (error.status) {
            case EStatusCode.BAD_REQUEST:
            case EStatusCode.FORBIDDEN:
              getErrorMessage(error).then((message) => {
                this.promptService.showError(message);
              });
              break;
            default:
              const prompt = this.translationPipe.transform('custom_field_visibility_change_error');
              this.promptService.showError(prompt);
              break;
          }

          return this.responseErrorService.handleRequestError(error);
        }),
      );
  }
}
