import { Injectable, OnDestroy } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UpdateUserUnreadNotifications } from '../user/user.actions';
import { TUser } from '../user/user.model';

import { ReactionsService } from '../../services/reactions.service';
import { ResponseErrorService } from '../errors/response-error.service';

import {
  CLEAR_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  GET_NOTIFICATIONS,
  MARK_NOTIFICATION_AS_ANSWERED,
  MARK_NOTIFICATION_AS_READ,
  MARK_NOTIFICATION_AS_UNREAD,
  MARK_NOTIFICATIONS_READ,
  SET_NOTIFICATIONS,
} from './notifications.store';

import { NotificationsApiProviderService } from '@core/api';
import { TMarkNotificationAsReadRequest } from '../../data-providers/api-providers/notification-api-provider/notification-requests.model';
import { logEventInGTAG } from '../../services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventNotifications,
} from '../../services/analytics/google-analytics.consts';
import { LocalStorageService } from '../../services/local-storage.service';
import { ELocalStorageItems } from '../../shared/enums/local-storage-items.enum';
import { EStore } from '../../shared/enums/store.enum';
import { TResponseEntity } from '../../view-models/response.models';
import { logErrorInSentry } from '../errors/response-error';
import { TReaction } from '../reactions/reactions.model';
import { TNotificationFilter, TPushNotification } from './notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly cancelFetchingNotifications$ = new Subject<void>();
  private readonly startCheckingNewNotifications$ = new Subject<void>();
  private readonly startUpdateUnreadStatus$ = new Subject<void>();

  private notificationsFetched: {
    fetched?: boolean;
  } = {
    fetched: false,
  };

  private notifications = GET_NOTIFICATIONS();

  private filters: TNotificationFilter = {
    account: null,
    site: null,
  };

  private allNotificationsFetched = false;
  private lastNotificationsUpdate: number = new Date().getTime();

  notificationsMode: {
    mode: string;
  } = {
    mode: 'all',
  };

  user$: Observable<TUser>;
  user: TUser;

  constructor(
    private store: Store<{
      user: TUser;
    }>,
    private responseErrorService: ResponseErrorService,
    private reactionsService: ReactionsService,
    private notificationsApiProviderService: NotificationsApiProviderService,
    private localStorageService: LocalStorageService,
  ) {
    this.user$ = this.store.pipe(select(EStore.USER));

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });

    // Uncomment to test checking for socket notifications locally (sockets don't work on localhost)
    // document.addEventListener('keydown', (event) => {
    //   if (event.key === 'Enter') {
    //     this.checkForNewNotifications();
    //   }
    // });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  saveShowAgainNotification(): void {
    this.localStorageService.saveItem(ELocalStorageItems.DONT_SHOW_CLEAR_ALL, 'true');
  }

  removeShowAgainNotification(): void {
    this.localStorageService.removeItem(ELocalStorageItems.DONT_SHOW_CLEAR_ALL);
  }

  fetchNotifications({
    offset = 0,
    refetch = false,
  }: {
    offset?: number;
    refetch?: boolean;
  } = {}): Observable<TReaction[]> {
    this.cancelFetchingNotifications$.next();

    return this.notificationsApiProviderService
      .fetchNotifications(
        this.notificationsMode.mode,
        offset,
        this.filters.account,
        this.filters.site,
      )
      .pipe(
        takeUntil(this.cancelFetchingNotifications$),
        map((response) => response || []),
        map((notificationsResponse): string[] => {
          const firstNotificationTime = notificationsResponse[0]?.timestampEpochMillis;

          if (typeof firstNotificationTime === 'number') {
            if (firstNotificationTime > this.lastNotificationsUpdate) {
              this.lastNotificationsUpdate = firstNotificationTime;
            }
          }

          this.allNotificationsFetched = notificationsResponse.length < 10;

          if (refetch) {
            this.emptyNotifications();
          }

          const [notifications, reactionsList] =
            this.getParsedNotificationsData(notificationsResponse);

          this.notifications[this.notificationsMode.mode].push(...notifications);

          return reactionsList;
        }),
        switchMap((reactionsList) => this.reactionsService.fetchCommentsReaction(reactionsList)),
        tap(() => {
          this.notificationsFetched.fetched = true;

          SET_NOTIFICATIONS(
            this.notifications[this.notificationsMode.mode],
            this.notificationsMode.mode,
          );
        }),
        catchError(this.responseErrorService.handleRequestError),
      );
  }

  checkForNewNotifications(): void {
    if (!this.user.userId) {
      return;
    }

    this.startCheckingNewNotifications$.next();
    const timeSinceLastSync = this.lastNotificationsUpdate;

    this.notificationsApiProviderService
      .checkForNewNotifications(
        this.notificationsMode.mode,
        timeSinceLastSync,
        this.filters.account,
        this.filters.site,
      )
      .pipe(
        takeUntil(this.startCheckingNewNotifications$),
        map((response): string[] => {
          if (response.length > 0) {
            this.store.dispatch(new UpdateUserUnreadNotifications(true));

            this.lastNotificationsUpdate = +response[0].timestampEpochMillis;
          }

          const [newNotifications, reactionsList] = this.getParsedNotificationsData(response);

          if (newNotifications) {
            this.notifications[this.notificationsMode.mode].unshift(...newNotifications);
          }

          return reactionsList;
        }),
        switchMap((reactionsList) => this.reactionsService.fetchCommentsReaction(reactionsList)),
        tap(() => {
          SET_NOTIFICATIONS(
            this.notifications[this.notificationsMode.mode],
            this.notificationsMode.mode,
          );
        }),
        catchError((error) => {
          if (error.status !== 504) {
            logErrorInSentry(error);
            this.responseErrorService.errors(error.status);
          }

          return of(null);
        }),
      )
      .subscribe();
  }

  changeNotificationReadStatus(notificationId: string, readStatus: boolean): void {
    const body: TMarkNotificationAsReadRequest = {
      markAsRead: readStatus,
    };

    this.notificationsApiProviderService
      .markNotificationAsRead(notificationId, readStatus, body)
      .pipe(
        tap(() => {
          if (readStatus) {
            MARK_NOTIFICATION_AS_READ(notificationId);
          } else {
            MARK_NOTIFICATION_AS_UNREAD(notificationId);
          }
        }),
        catchError(this.responseErrorService.handleRequestError),
      )
      .subscribe();
  }

  markNotificationAsCommented(notificationId: string): void {
    this.notificationsApiProviderService
      .markNotificationAsCommented(notificationId, {})
      .pipe(
        tap(() => {
          MARK_NOTIFICATION_AS_ANSWERED(notificationId);
        }),
        catchError(this.responseErrorService.handleRequestError),
      )
      .subscribe();
  }

  updateUnreadStatus(): Observable<TResponseEntity> {
    this.startUpdateUnreadStatus$.next();

    return this.notificationsApiProviderService
      .updateUnreadStatus({})
      .pipe(
        takeUntil(this.startUpdateUnreadStatus$),
        catchError(this.responseErrorService.handleRequestError),
      );
  }

  markAllNotificationsRead(): void {
    this.notificationsApiProviderService
      .markAllNotificationsRead({})
      .pipe(
        tap(() => {
          MARK_NOTIFICATIONS_READ();

          logEventInGTAG(EGoogleEventNotifications.NOTIFICATIONS__MARK_ALL_AS_READ, {
            event_category: EGoogleEventCategory.NOTIFICATIONS,
          });
        }),
        catchError(this.responseErrorService.handleRequestError),
      )
      .subscribe();
  }

  deleteNotification(notificationId: string): void {
    this.notificationsApiProviderService
      .deleteNotification(notificationId)
      .pipe(
        tap(() => {
          DELETE_NOTIFICATION(notificationId);

          logEventInGTAG(EGoogleEventNotifications.NOTIFICATIONS__CLEAR, {
            event_category: EGoogleEventCategory.NOTIFICATIONS,
          });
        }),
        catchError(this.responseErrorService.handleRequestError),
      )
      .subscribe();
  }

  removeNotifications(): void {
    this.notificationsApiProviderService
      .deleteNotifications()
      .pipe(
        tap(() => {
          CLEAR_NOTIFICATIONS();
          this.notifications = GET_NOTIFICATIONS();

          logEventInGTAG(EGoogleEventNotifications.NOTIFICATIONS__CLEAR, {
            event_category: EGoogleEventCategory.NOTIFICATIONS,
          });
        }),
        catchError(this.responseErrorService.handleRequestError),
      )
      .subscribe();
  }

  getNotification(notificationId: string): TPushNotification {
    return this.notifications[this.notificationsMode.mode].find(
      (notification) => notification.id === notificationId,
    );
  }

  getAllNotificationsFetched(): boolean {
    return this.allNotificationsFetched;
  }

  clearNotifications(): void {
    CLEAR_NOTIFICATIONS();
  }

  notificationsAvailable(): {
    fetched?: boolean;
  } {
    return this.notificationsFetched;
  }

  setNotificationsMode(mode: string): void {
    this.notificationsMode.mode = mode;
  }

  getNotificationsMode(): {
    mode: string;
  } {
    return this.notificationsMode;
  }

  setFilters(filters: TNotificationFilter): void {
    logEventInGTAG(EGoogleEventNotifications.NOTIFICATIONS_FILTER, {
      event_category: EGoogleEventCategory.NOTIFICATIONS,
    });

    this.filters = filters;
  }

  getFilters(): TNotificationFilter {
    return this.filters;
  }

  private emptyNotifications(): void {
    this.notifications = {
      all: [],
      unread: [],
      mentioned: [],
      assigned: [],
    };
  }

  private getParsedNotificationsData(
    notificationsResponse: TPushNotification[],
  ): [TPushNotification[], string[]] {
    const reactionsList = [];

    const newNotifications = notificationsResponse.map((notification) => {
      if (notification.changeBody?.commentId) {
        reactionsList.push(notification.changeBody.commentId);
      }

      return { ...notification };
    });

    return [newNotifications, reactionsList];
  }
}
