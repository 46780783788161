<section class="actionBar" [ngClass]="ppScrollPosition > 0 ? '' : 'actionBar--border'">
  <div class="actionBar__left">
    <h3
      class="actionBar__point-id"
      *ngIf="point?.sequenceNumber.toString() !== NEW_POINT_ID"
      data-test="pointCardId"
    >
      {{ point?.sequenceNumber }}
    </h3>

    <pp-point-header
      class="actionBar__point-header"
      [ppNew]="ppNew"
      [ppPointId]="point?._id"
      [ppWorkspaceId]="point?.workspaceRef.id"
      [ppId]="point?.sequenceNumber"
      [ppTitle]="point?.title"
      [ppCanEdit]="ppCanEdit"
    ></pp-point-header>
  </div>

  <div class="actionBar__middle" data-test="siteInformation" *ngIf="point && workspace">
    <div
      ppTooltip
      [ppTitle]="workspace.accountName + ' - ' + workspace.siteName"
      [ppTrimTooltip]="true"
      class="actionBar__account"
    >
      <span
        ppTooltip
        [ppTitle]="workspace.accountName"
        [ppTrimTooltip]="true"
        class="actionBar__account-text"
      >
        {{ workspace.accountName }}
      </span>

      <span class="actionBar__account-text" *ngIf="workspace.accountName">
        <img class="actionBar__account-arrow" [src]="EIconPath.ARROW_RIGHT_BLANK" />
      </span>

      <span
        ppTooltip
        [ppTitle]="workspace.siteName"
        [ppTrimTooltip]="true"
        class="actionBar__account-text"
      >
        {{ workspace.siteName }}
      </span>
    </div>
  </div>

  <div class="actionBar__bottom">
    <div class="actionBar__priority" *ngIf="point">
      <h6 class="actionBar__priority-text">{{ 'priority' | ppTranslate }}</h6>

      <pp-priority-button
        id="priorityButton"
        [ppPriority]="point?.priority"
        (ppAction)="showPriorityDropdown('priorityButton')"
        [ppDisabled]="!ppCanEdit"
        [ppProcessing]="updatingPriority"
        data-test="priorityButton"
      >
      </pp-priority-button>
    </div>

    <div class="actionBar__status" *ngIf="point">
      <h6 class="actionBar__status-text">{{ 'status' | ppTranslate }}</h6>

      <pp-status-button
        [ppStatus]="point?.status"
        id="statusButton"
        (ppAction)="showStatusDropdown('statusButton')"
        [ppDisabled]="!ppCanEdit"
        [ppProcessing]="updatingStatus"
        data-test="statusButton"
      >
      </pp-status-button>
    </div>
  </div>

  <pp-point-created-by
    *ngIf="!ppNew && point"
    [ppCreatedByName]="point?.header.createdBy.caption"
    [ppCreatedByAvatar]="point?.header.createdBy.primaryImageId"
    [ppCreatedById]="point?.header.createdBy.id"
    [ppWorkspaceId]="point?.workspaceRef.id"
  ></pp-point-created-by>
</section>
