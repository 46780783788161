import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import { TUser } from 'src/app/project/modules/user/user.model';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { SidePanelService } from 'src/app/project/modules/layout/side-panel/side-panel.service';
import { WhiteLabelService } from 'src/app/project/modules/white-label/white-label.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { PromptService } from '../../../../components/prompt/prompt.service';
import { OfflineService } from '../../../offline/offline.service';
import { SiteDataService } from '../../site-data.service';

import { Title } from '@angular/platform-browser';
import { ScreenService } from '@core/services';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EPdfLogoTarget } from 'src/app/project/modules/white-label/pdfLogo-targets-enum';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSettings,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EStore } from 'src/app/project/shared/enums/store.enum';

@Component({
  selector: 'pp-site-settings-logo',
  templateUrl: './site-settings-logo.component.html',
  styleUrls: ['./site-settings-logo.component.scss'],
})
export class SiteSettingsLogoComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  processingLogoUpdate = false;
  newLogo: File = null;

  private user$: Observable<TUser>;
  private workspaces$: Observable<TWorkspacesById>;
  private offline$: Observable<boolean>;
  offline: boolean;
  workspaceId: string = null;
  uploadedImage: string | ArrayBuffer = null;
  hasWorkspaces = false;
  hasWorkspace = false;
  logoId = '';
  workspaces: TWorkspacesById;
  user: TUser;

  constructor(
    private store: Store<{
      user: TUser;
      workspaces: TWorkspacesById;
      offline: boolean;
    }>,
    private activatedRoute: ActivatedRoute,
    private promptService: PromptService,
    private whiteLabelService: WhiteLabelService,
    private activeService: ActiveService,
    private offlineService: OfflineService,
    private sidePanelService: SidePanelService,
    private siteDataService: SiteDataService,
    private translationPipe: TranslationPipe,
    private titleService: Title,
    private screenService: ScreenService,
  ) {
    this.user$ = this.store.pipe(select(EStore.USER));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.offline$ = this.store.pipe(select(EStore.OFFLINE));
  }

  ngOnInit() {
    this.titleService.setTitle('Site settings | Pinpoint Works');

    this.screenService.isDesktop$.pipe(takeUntil(this.destroy$)).subscribe((isDesktop) => {
      if (!isDesktop) {
        this.sidePanelService.collapsePanel();
      }
    });

    this.activatedRoute.parent.paramMap.subscribe((params) => {
      this.workspaceId = params.get('id');
      this.uploadedImage = null;

      if (this.workspaces?.[this.workspaceId]) {
        this.logoId = this.workspaces[this.workspaceId]?.siteLogoRef?.id;
      }
    });

    this.offline$.pipe(takeUntil(this.destroy$)).subscribe((offline) => {
      this.offline = offline;
    });

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspaces = workspaces;
      this.hasWorkspaces = !!workspaces;
      const workspace = workspaces[this.workspaceId];
      this.hasWorkspace = !!workspace;
      this.logoId = workspace?.siteLogoRef?.id;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  setLogo(images: File[]): void {
    const offline = this.offlineService.getOffline();
    const reader = new FileReader();

    if (!offline) {
      [this.newLogo] = images;
    }

    reader.onload = (): void => {
      this.uploadedImage = reader.result;
    };

    reader.readAsDataURL(images[0]);
  }

  updateLogo(): void {
    const workspaceId = this.activeService.getActiveWorkspaceId();

    this.processingLogoUpdate = true;

    this.whiteLabelService
      .uploadPdfLogo(workspaceId, this.newLogo, EPdfLogoTarget.SITE)
      .pipe(
        tap((imageId) => {
          const promptText = this.translationPipe.transform(
            this.logoId ? 'prompt_logo_PDF_update' : 'prompt_logo_PDF_upload',
          );

          this.logoId = imageId;
          this.processingLogoUpdate = false;
          this.newLogo = null;

          logEventInGTAG(EGoogleEventSettings.SETTINGS__PDF_LOGO__UPDATE, {
            event_category: EGoogleEventCategory.SETTINGS,
          });
          this.promptService.showSuccess(promptText);
        }),
        catchError((error) => {
          const promptText = this.translationPipe.transform('prompt_logo_PDF_update_error');

          this.processingLogoUpdate = false;

          this.promptService.showError(promptText);

          throw error;
        }),
      )
      .subscribe();
  }

  cancelNewLogo(): void {
    this.newLogo = null;
    this.uploadedImage = null;
  }
}
