import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { TAccount } from 'src/app/project/modules/account/account.model';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DeviceService } from 'src/app/core/services/device.service';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { searchAccount } from 'src/app/project/modules/account/account-utils/search-account';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { TNotificationFilter } from '../notification.model';
import {
  TNotificationFilterData,
  TSelectableNotificationWorkspaces,
} from './notification-filters.model';

@Component({
  selector: 'pp-notification-filter-sites',
  templateUrl: './notification-filter-sites.component.html',
  styleUrls: ['./notification-filter-sites.component.scss'],
})
export class NotificationFilterSitesComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @ViewChild('list') sitesList: ElementRef;

  private searchBox = '';
  scrolledToBottom: boolean;

  expandedAccounts: string[] = [];
  filteredSelectableWorkspaces: TSelectableNotificationWorkspaces = {};

  selectedSite = '';
  selectedAccount = '';
  EIconPath = EIconPath;

  private accounts: TAccount[];
  workspaces: TWorkspacesById;
  filteredAccount: TAccount[] = [];

  public isiPod = false;
  public isMobile = false;
  public isiPad = false;

  private dropdown: TDropdown<TNotificationFilterData>;
  private selectableWorkspaces: TSelectableNotificationWorkspaces = {};

  constructor(
    private dropdownService: DropdownService,
    private deviceService: DeviceService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.isiPod = this.deviceService.isiPod();
    this.isMobile = this.deviceService.isMobile();
    this.isiPad = this.deviceService.isiPad();
    this.dropdown = this.dropdownService.getDropdown();

    this.handleDropdownData();
    this.generateAccounts();
    this.updateSearchBox(this.searchBox);

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.hideDropdown();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  hideDropdown(): void {
    this.dropdownService.hideDropdown();
  }

  selectSite(workspaceId: string): void {
    this.selectedSite = workspaceId;
    this.selectedAccount = null;

    const filters: TNotificationFilter = {
      site: workspaceId,
      account: null,
    };

    this.dropdown.callback(filters);
  }

  clearSelectedSites(): void {
    this.selectedSite = null;
    this.selectedAccount = null;

    const filters: TNotificationFilter = {
      site: null,
      account: null,
    };

    this.dropdown.callback(filters);

    this.hideDropdown();
  }

  handleScroll(event: Event): void {
    if (event.target instanceof HTMLElement) {
      this.scrolledToBottom =
        event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
    }
  }

  toggleAccount(accountId: string, event: Event): void {
    const index = this.expandedAccounts.findIndex(
      (searchedAccountId) => accountId === searchedAccountId,
    );

    event.preventDefault();
    event.stopPropagation();

    if (index > -1) {
      this.expandedAccounts.splice(index, 1);
    } else {
      this.expandedAccounts.push(accountId);
    }
  }

  selectAccount(accountId: string): void {
    this.selectedSite = null;
    this.selectedAccount = accountId;

    const filters: TNotificationFilter = {
      site: null,
      account: accountId,
    };

    this.dropdown.callback(filters);
  }

  updateSearchBox(keyword: string): void {
    this.searchBox = keyword;

    this.filteredAccount = searchAccount(this.accounts, this.searchBox, this.workspaces);

    Object.keys(this.selectableWorkspaces).forEach((accountId) => {
      this.filteredSelectableWorkspaces[accountId] = WorkspaceService.searchWorkspace(
        this.selectableWorkspaces[accountId],
        this.searchBox,
        this.workspaces,
      );
    });
  }

  private generateAccounts(): void {
    this.selectableWorkspaces = {};

    this.accounts.forEach((account) => {
      this.selectableWorkspaces[account.accountId] = [];

      account.workspaces.forEach((workspaceId) => {
        if (workspaceId === this.selectedSite) {
          this.expandedAccounts.push(account.accountId);
        }

        this.selectableWorkspaces[account.accountId].push(workspaceId);
      });
    });
  }

  private handleDropdownData(): void {
    if (!this.dropdown.data) {
      throw new Error('Data for this dropdown is required');
    }

    if (!this.dropdown.callback) {
      throw new Error('Callback for this dropdown is required');
    }

    const dropdownData: TNotificationFilterData = this.dropdown.data;

    this.selectedAccount = dropdownData.selectedFilters.account;
    this.selectedSite = dropdownData.selectedFilters.site;

    this.accounts = dropdownData.accounts;
    this.workspaces = dropdownData.workspaces;
  }

  onListRendered(): void {
    if (this.sitesList.nativeElement.scrollHeight === this.sitesList.nativeElement.clientHeight) {
      this.scrolledToBottom = true;
    }
  }
}
