import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { PlanDataService, TPlanData } from '../../../plan/plan-data.service';
import { EditPointService, TPointFullModal } from '../../point-full-modal/edit-point.service';
import { PointsUpdateService } from '../../points-update.service';
import { PointsService } from '../../points.service';
import { PointActivityService } from '../point-timeline/point-activity.service';

import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { NEW_POINT_ID } from 'src/app/project/shared/constants/point.const';
import { TPin, TPolygon } from '../../../../view-models/point-response.model';
import { TPoint } from '../../points.model';

type TSavePintDebounceData = {
  id: string;
  body: TPointBody;
  workspaceId: string;
  pins: TPin[];
  polygons: TPolygon[];
};

type TPointBody = {
  pins?: TPin[];
  polygons?: TPolygon[];
};

@Injectable({
  providedIn: 'root',
})
export class PointPlanService {
  private plan: TPlanData = this.planDataService.getPlan();
  private fullModal: TPointFullModal = this.editPointService.getModal();

  constructor(
    private store: Store,
    private pointsService: PointsService,
    private pointActivityService: PointActivityService,
    private editPointService: EditPointService,
    private planDataService: PlanDataService,
    private activeService: ActiveService,
    private pointsUpdateService: PointsUpdateService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private sitePointFilterService: SitePointFilterService,
  ) {}

  savePoint(pins: TPin[] = [], polygons: TPolygon[] = []): Observable<void | TPoint> {
    const pointId = this.activeService.getActivePointId();
    const point = this.pointsService.findPoint(pointId);
    const workspaceId = point.workspaceRef.id;

    let changedPins = false;
    let changedPolygons = false;
    const body: TPointBody = {};
    let polygonsBody = { polygons: [] };

    if (polygons.length > 0) {
      const polygonBody = { polygons: [] };

      polygons.forEach((polygon) => {
        const polygonData = {
          vertices: [],
        };

        polygon[0].forEach((vertex) => {
          polygonData.vertices.push({
            x: vertex[0].toFixed(4),
            y: vertex[1].toFixed(4),
          });
        });

        polygonBody.polygons.push(polygonData);
      });

      polygonsBody = polygonBody;
    }

    if (point.polygons.length !== polygons.length) {
      changedPolygons = true;
    } else if (point.polygons.length === 0) {
      changedPolygons = false;
    } else {
      polygons.forEach((polygon) => {
        polygon[0].forEach((vertice, index) => {
          if (
            !(
              Math.abs(vertice[0] - point.polygons[0]?.[0]?.[index]?.[0]) > 0.01 ||
              Math.abs(vertice[1] - point.polygons[0]?.[0]?.[index]?.[1]) > 0.01
            )
          ) {
            changedPolygons = true;
          }
        });
      });
    }

    if (point.pins.length !== pins.length) {
      changedPins = true;
    }

    pins.forEach((pin, index) => {
      pin.x = Number(pin.x.toFixed(4));
      pin.y = Number(pin.y.toFixed(4));

      if (
        !point.pins[index] ||
        (point.pins[index] &&
          (Math.abs(point.pins[index].x - pin.x) > 0.01 ||
            Math.abs(point.pins[index].y - pin.y) > 0.01))
      ) {
        changedPins = true;
      }
    });

    if (changedPins) {
      body.pins = pins;
    }

    if (changedPolygons) {
      body.polygons = polygonsBody.polygons;
    }

    if (pointId === NEW_POINT_ID) {
      const foundPoint = this.pointsService.findPoint(pointId);

      foundPoint.pins = pins;
      foundPoint.polygons = polygons;

      return of(null);
    }

    return this.updatePointField({
      id: pointId,
      body,
      workspaceId,
      pins,
      polygons,
    }).pipe(
      tap(() => {
        if (this.fullModal.visible) {
          this.sitePointFilterService.filterPoints();
        }
      }),
    );
  }

  private updatePointField({
    id,
    body,
    workspaceId,
  }: TSavePintDebounceData): Observable<void | TPoint> {
    return this.pointsUpdateService.updatePointField(id, body).pipe(
      tap(() => {
        this.pointActivityService.refreshTimeline(workspaceId, id);
        this.plan.site.vector.layer.getSource().refresh();
      }),
      catchError((error) => {
        let promptText = this.translationPipe.transform('prompt_changes_error');

        if (error.status === EStatusCode.FORBIDDEN) {
          promptText = this.translationPipe.transform('prompt_changes_permission_denied');
        }

        this.promptService.showError(promptText);

        return of(null);
      }),
    );
  }
}
