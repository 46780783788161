import { Injectable } from '@angular/core';
import { DefaultShareService } from '../../../../../share/default-share.service';
import { EUserRole } from '../../../../../share/share-utils/user-roles';
import { TShare } from '../../../../../share/share.model';
import { EditAccountUserModalDataService } from '../../edit-account-user-modal-data.service';

@Injectable({
  providedIn: 'root',
})
export class PrepareShareService {
  constructor(
    private editAccountUserModalDataService: EditAccountUserModalDataService,
    private defaultShareService: DefaultShareService,
  ) {}

  prepareShare(workspaceId: string): TShare {
    let share = this.editAccountUserModalDataService.getShare(workspaceId);

    if (!share) {
      const newShare: TShare = {
        shareOption: EUserRole.NORMAL,
        workspaceId,
        userId: null,
        shareId: null,
        hidden: false,
      };
      share = this.defaultShareService.resetShareToDefaults(newShare);
      this.editAccountUserModalDataService.setShare(workspaceId, share);
    }

    return share;
  }
}
