export enum EAdvancedFilterOptions {
  CONTAINS = 'CONTAINS',
  DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN',
  IS = 'IS',
  IS_NOT = 'IS_NOT',
  IS_EMPTY = 'IS_EMPTY',
  IS_NOT_EMPTY = 'IS_NOT_EMPTY',
  IS_GREATER_THAN = 'IS_GREATER_THAN',
  IS_LESS_THAN = 'IS_LESS_THAN',
  IS_CHECKED = 'IS_CHECKED',
  IS_NOT_CHECKED = 'IS_NOT_CHECKED',
  IS_BEFORE = 'IS_BEFORE',
  IS_AFTER = 'IS_AFTER',
  IS_BETWEEN = 'IS_BETWEEN',
  IS_ANY_OF = 'IS_ANY_OF',
  IS_NONE_OF = 'IS_NONE_OF',
  IS_ALL_OF = 'IS_ALL_OF',
  IS_EXACTLY = 'IS_EXACTLY',
  IS_A_PART_OF = 'IS_A_PART_OF',
  IS_FLAGGED = 'IS_FLAGGED',
  IS_NOT_FLAGGED = 'IS_NOT_FLAGGED',
}
