import { cloneDeep } from 'lodash';
import { TShare } from '../../share.model';

export function setAdminPermissions(share: TShare): TShare {
  const shareCopy = cloneDeep(share);

  shareCopy.tagLimited = false;
  shareCopy.advancedAccessLevels.tags.permission.edit = true;
  shareCopy.advancedAccessLevels.tags.permission.read = true;
  shareCopy.advancedAccessLevels.timeline.permission.read = true;
  shareCopy.advancedAccessLevels.timeline.comments.permission.read = true;
  shareCopy.advancedAccessLevels.timeline.comments.permission.edit = true;
  shareCopy.advancedAccessLevels.exports.permission.read = true;
  shareCopy.advancedAccessLevels.sitePlan.permission.read = true;

  if (shareCopy.advancedAccessLevels.customFields?.length > 0) {
    shareCopy.advancedAccessLevels.customFields.forEach((customField) => {
      customField.permission.read = true;
      customField.permission.edit = true;
    });
  }

  return shareCopy;
}
