<div class="pointCF__field">
  <label class="pointCF__label">
    <div class="pointCF__label-image--container">
      <pp-icon [ppSrc]="EIconPath.ICON_FIELDS_CHECKBOX_18" ppColor="inherit" ppClass="setSize18" />
    </div>

    <span
      class="pointCF__label-container"
      ppTooltip
      ppTitle="{{ ppFieldLabel }}"
      [ppTrimTooltip]="true"
    >
      {{ ppFieldLabel }}
    </span>
  </label>

  <div class="pointCF__content">
    <div class="pointCF__content--checkbox">
      <pp-checkbox
        [ppChecked]="ppValue && ppValue.toString() !== 'false'"
        [ppDisabled]="!ppCanEdit"
        (ppOnValueChange)="checkField()"
      ></pp-checkbox>
    </div>
  </div>
</div>
