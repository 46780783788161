<div class="newAssetSitePickerWorkspace">
  <div class="newAssetSitePickerSite">
    <div class="collapseChevronContainer">
      <pp-icon
        [ppSrc]="
          expanded ? EIconPath.ICON_MISC_DOWN_CHEVRON_24 : EIconPath.ICON_MISC_RIGHT_CHEVRON_24
        "
        *ngIf="customFields.length > 0"
        ppClass="setSize24"
        ppColor="inherit"
        (click)="updateExpanded()"
      ></pp-icon>
    </div>

    <pp-checkbox
      ppSize="medium"
      ppType="solid"
      [ppChecked]="checked"
      (click)="updateChecked()"
    ></pp-checkbox>

    <span>
      <span class="newAssetSitePickerName">
        {{ workspace.siteName }}
      </span>

      <span class="newAssetSitePickerRole">({{ role }})</span>
    </span>
  </div>

  <ng-container *ngIf="expanded">
    <div class="newAssetSitePickerFields" *ngFor="let customField of customFields">
      <pp-radio
        ppSize="small"
        [ppChecked]="checkedCustomFieldId === customField.value"
        (ppOnValueChange)="updateCustomFieldChecked(customField)"
        ppStyle="blue"
      ></pp-radio>
      <span>{{ customField.label }}</span>
    </div>
  </ng-container>
</div>
