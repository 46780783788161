import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from '../../shared/enums/icons.enum';

@Component({
  selector: 'pp-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent {
  @Input() ppText: string;
  // TODO: check if we can merge those 2 (ppDeletable and ppDisabled); we should be fine with only ppDisabled but need to check all usages
  @Input() ppDeletable = false;
  @Input() ppDisabled = false;
  @Input() ppNoMargin = false;

  @Output() ppDelete = new EventEmitter();

  EIconPath = EIconPath;

  delete(): void {
    this.ppDelete.emit();
  }
}
