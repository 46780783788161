<div #scrollContainer class="assetCard__sitesTableContainer">
  <div *ngIf="!scrolledToEnd" class="scrollOverflow">
    <div class="scrollOverflowIconContainer">
      <pp-icon
        class="scrollOverflowIcon"
        [ppSrc]="EIconPath.ICON_ARROW_DOWN"
        ppColor="grey-300"
      ></pp-icon>
      <div></div>
    </div>
  </div>
  <pp-asset-card-sites-table-header class="assetCard__tableHeader">
  </pp-asset-card-sites-table-header>
  <div class="assetCard__sitesScrollContainer">
    <pp-asset-card-sites-table-row
      *ngFor="let site of ppSites"
      [ppAssetSite]="site"
      (ppNavigate)="navigateToSite($event)"
    ></pp-asset-card-sites-table-row>
  </div>
</div>
