import { Component, Input } from '@angular/core';
import { EIconPath } from '../../../../../../../shared/enums/icons.enum';
import { TActivityChange } from '../../activity-change.model';

@Component({
  selector: 'pp-dashlet-activity-documents',
  templateUrl: './dashlet-activity-documents.component.html',
  styleUrls: ['../../dashlet-activity.component.scss'],
})
export class DashletActivityDocumentsComponent {
  @Input() ppChange: TActivityChange<string[]>;

  EIconPath = EIconPath;
}
