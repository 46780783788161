import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TopBarService {
  private topBarElement: HTMLElement;

  constructor() {}

  setTopBarElement(topBarElement: HTMLElement): void {
    this.topBarElement = topBarElement;
  }

  getTopBarElement(): HTMLElement {
    return this.topBarElement;
  }
}
