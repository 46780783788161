<div class="confirmationBlock__wrapper">
  <div class="confirmationBlock">
    <div class="confirmationBlock__message">
      {{ 'new_account_admin_confirm_message' | ppTranslate }}
    </div>
    <div class="confirmationBlock__buttons">
      <pp-button ppStyle="secondary" ppSize="large" (ppAction)="cancel()">
        {{ 'cancel' | ppTranslate }}
      </pp-button>

      <pp-button
        ppStyle="primary"
        ppSize="large"
        (ppAction)="confirm()"
        [ppDisabled]="ppProcessing"
      >
        {{ 'new_account_admin_confirm_message_button' | ppTranslate }}
        <app-spinner [ppButton]="true" [ppLight]="true" [ppHidden]="!ppProcessing"></app-spinner>
      </pp-button>
    </div>
  </div>
</div>
