<pp-bulk-changes-bar
  [ppSelectedNumber]="ppSelectedShares.length"
  *ngIf="ppSelectedShares.length > 0"
  [ppSelectedTextKey]="'users_selected'"
  (ppClose)="deselectAll()"
  [ppZIndex]="ppZIndex"
>
  <pp-bulk-changes-button
    [ppIconPath]="EIconPath.ICON_BIN_24"
    [ppText]="'remove' | ppTranslate"
    (ppAction)="removeShares()"
  ></pp-bulk-changes-button>
</pp-bulk-changes-bar>
