import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TAnyFunction } from '@core/helpers';
import { Subject } from 'rxjs';
import { EIconPath } from '../../../../shared/enums/icons.enum';
@Component({
  selector: 'pp-import-tag-file',
  templateUrl: './import-tag-file.component.html',
  styleUrls: ['../import-tag-modal.component.scss'],
})
export class ImportTagFileComponent implements OnInit {
  @ViewChild('dropzone', { static: false }) dropzoneElement: ElementRef;
  @ViewChild('dragenter', { static: true }) dragenterElement: ElementRef;

  @Input() ppProcessing: boolean;

  @Input() ppFileLoaded: boolean;
  @Output() ppFileLoadedChange = new EventEmitter();

  @Input() ppFileName;
  @Output() ppFileNameChange = new EventEmitter();

  @Output() ppRemoveFile = new EventEmitter();
  @Output() ppImportFileTags = new EventEmitter();
  @Output() ppOpenSpreadsheet = new EventEmitter();

  private drop: TAnyFunction;
  private dragenter: TAnyFunction;
  private dragleave: TAnyFunction;
  private dragover: TAnyFunction;
  private readonly destroy$ = new Subject<void>();
  EIconPath = EIconPath;

  constructor() {}

  ngOnInit(): void {
    this.drop = (event: DragEvent): void => {
      event.preventDefault();

      const files = event.dataTransfer.files;

      this.openSpreadsheet(files[0]);
    };

    this.dragenter = (event: DragEvent): void => {
      event.preventDefault();
    };

    this.dragleave = (event: DragEvent): void => {
      event.preventDefault();
    };

    this.dragover = (event: DragEvent): void => {
      event.preventDefault();
    };
  }

  ngOnDestroy() {
    if (this.dragenterElement) {
      this.dragenterElement.nativeElement.removeEventListener('dragover', this.dragover, false);
      this.dragenterElement.nativeElement.removeEventListener('dragenter', this.dragenter, false);
    }

    if (this.dropzoneElement) {
      this.dropzoneElement.nativeElement.removeEventListener('drop', this.drop, false);
      this.dropzoneElement.nativeElement.removeEventListener('dragover', this.dragover, false);
      this.dropzoneElement.nativeElement.removeEventListener('dragleave', this.dragleave, false);
    }

    this.destroy$.next();
  }

  ngAfterViewInit() {
    if (this.dragenterElement) {
      this.dragenterElement.nativeElement.addEventListener('dragover', this.dragover, false);
      this.dragenterElement.nativeElement.addEventListener('dragenter', this.dragenter, false);
    }

    if (this.dropzoneElement) {
      this.dropzoneElement.nativeElement.addEventListener('drop', this.drop, false);
      this.dropzoneElement.nativeElement.addEventListener('dragover', this.dragover, false);
      this.dropzoneElement.nativeElement.addEventListener('dragleave', this.dragleave, false);
    }
  }

  removeFile(): void {
    this.ppRemoveFile.emit();
  }

  importFileTags(event: File[]): void {
    this.ppImportFileTags.emit(event);
  }

  openSpreadsheet(file: File): void {
    this.ppOpenSpreadsheet.emit(file);
  }
}
