import { translate } from 'src/app/project/features/translate/translate';
import { EDashletType } from '../../dashlets-enums';

export function generateFooterText(type: EDashletType): string {
  let footerText = '';

  switch (type) {
    case EDashletType.COMMENT_CREATE:
      footerText = translate('comments_made');
      break;
    case EDashletType.DEFECT_CREATE:
      footerText = translate('dashlet_export_points_created');
      break;
    case EDashletType.HIGH:
      footerText = translate('points_changed_to_high');
      break;
    case EDashletType.MEDIUM:
      footerText = translate('points_changed_to_medium');
      break;
    case EDashletType.LOW:
      footerText = translate('points_changed_to_low');
      break;
    case EDashletType.ONHOLD:
      footerText = translate('dashboard_export_points_changed_to_on_hold');
      break;
    case EDashletType.IN_PROGRESS:
      footerText = translate('dashboard_export_points_changed_to_in_progress');
      break;
    case EDashletType.OPEN:
      footerText = translate('dashboard_export_points_changed_to_open');
      break;
    case EDashletType.TO_REVIEW:
      footerText = translate('dashboard_export_points_changed_to_review');
      break;
    case EDashletType.CANCELED:
      footerText = translate('points_changed_to_canceled');
      break;
    case EDashletType.CLOSED:
      footerText = translate('points_completed');
      break;
    default:
      break;
  }

  return footerText;
}
