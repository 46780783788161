import { Injectable } from '@angular/core';
import { TPlanData } from '../plan-data.service';
import { EPlanPointEventType, PlanPointEventsService } from './plan-point-events.service';
import { EPlanPointMode } from '../../../shared/enums/plan-point-mode.enum';
import Feature from 'ol/Feature';
import Draw from 'ol/interaction/Draw';
import Translate from 'ol/interaction/Translate';
import Modify from 'ol/interaction/Modify';
import Select from 'ol/interaction/Select';
import Snap from 'ol/interaction/Snap';
import { TAnyFunction } from '@core/helpers';
import { MapBrowserEvent } from 'ol';

@Injectable({
  providedIn: 'root',
})
export class PlanPointVariablesService {
  private featureClicked: {
    clicked: boolean;
  } = {
    clicked: false,
  };

  private dragInteraction: Translate;
  private changedFeature: Feature = null;
  private clickedPolygon: Feature;
  private plan: TPlanData;
  private drawPolygon: Draw;
  private mode = EPlanPointMode.POINT;
  private modifyInteraction: Modify;
  private selectInteraction: Select;
  private snapInteraction: Snap;
  private modifyingPolygon = false;

  private clickListenerRef: TAnyFunction;
  private polygonDrawStartListenerRef: TAnyFunction;
  private polygonDrawEndListenerRef: TAnyFunction;
  private escListenerRef: TAnyFunction;
  private polygonStopModifyListenerRef: TAnyFunction;
  private polygonStartModifyListenerRef: TAnyFunction;
  private dblclickListenerRef: TAnyFunction;
  private pointermoveListenerRef: TAnyFunction;
  private moveendListenerRef: TAnyFunction;
  private dragListenerRef: TAnyFunction;

  constructor(private planPointEventsService: PlanPointEventsService) {}

  setFeatureClicked(clicked: boolean): void {
    this.featureClicked.clicked = clicked;

    this.planPointEventsService.emit(EPlanPointEventType.FEATURE_CLICKED, clicked);
  }

  getFeatureClicked(): {
    clicked: boolean;
  } {
    return this.featureClicked;
  }

  getDragInteraction(): Translate {
    return this.dragInteraction;
  }

  setDragInteraction(interaction: Translate): void {
    this.dragInteraction = interaction;
  }

  getChangedFeature(): Feature {
    return this.changedFeature;
  }

  setChangedFeature(feature: Feature): void {
    this.changedFeature = feature;
  }

  setClickedPolygon(clickedPolygon: Feature): void {
    this.clickedPolygon = clickedPolygon;
  }

  getClickedPolygon(): Feature {
    return this.clickedPolygon;
  }

  getPlan(): TPlanData {
    return this.plan;
  }

  setPlan(plan: TPlanData): void {
    this.plan = plan;
  }

  getClickListenerRef(): TAnyFunction {
    return this.clickListenerRef;
  }

  setClickListenerRef(ref: (event: MapBrowserEvent<UIEvent>) => void): void {
    this.clickListenerRef = ref;
  }

  getDrawPolygon(): Draw {
    return this.drawPolygon;
  }

  setDrawPolygon(interaction: Draw): void {
    this.drawPolygon = interaction;
  }

  getMode(): EPlanPointMode {
    return this.mode;
  }

  setMode(mode: EPlanPointMode): void {
    this.mode = mode;
  }

  getModifyInteraction(): Modify {
    return this.modifyInteraction;
  }

  setModifyInteraction(interaction: Modify): void {
    this.modifyInteraction = interaction;
  }

  getSelectInteraction(): Select {
    return this.selectInteraction;
  }

  setSelectInteraction(interaction: Select): void {
    this.selectInteraction = interaction;
  }

  getSnapInteraction(): Snap {
    return this.snapInteraction;
  }

  setSnapInteraction(interaction: Snap): void {
    this.snapInteraction = interaction;
  }

  getPolygonDrawStartListenerRef(): TAnyFunction {
    return this.polygonDrawStartListenerRef;
  }

  setPolygonDrawStartListenerRef(ref: TAnyFunction): void {
    this.polygonDrawStartListenerRef = ref;
  }

  getPolygonDrawEndListenerRef(): TAnyFunction {
    return this.polygonDrawEndListenerRef;
  }

  setPolygonDrawEndListenerRef(ref: TAnyFunction): void {
    this.polygonDrawEndListenerRef = ref;
  }

  getEscListenerRef(): TAnyFunction {
    return this.escListenerRef;
  }

  setEscListenerRef(ref: TAnyFunction): void {
    this.escListenerRef = ref;
  }

  getPolygonStopModifyListenerRef(): TAnyFunction {
    return this.polygonStopModifyListenerRef;
  }

  setPolygonStopModifyListenerRef(ref: TAnyFunction): void {
    this.polygonStopModifyListenerRef = ref;
  }

  getPolygonStartModifyListenerRef(): TAnyFunction {
    return this.polygonStartModifyListenerRef;
  }

  setPolygonStartModifyListenerRef(ref: TAnyFunction): void {
    this.polygonStartModifyListenerRef = ref;
  }

  getDblclickListenerRef(): TAnyFunction {
    return this.dblclickListenerRef;
  }

  setDblClickListenerRef(ref: TAnyFunction): void {
    this.dblclickListenerRef = ref;
  }

  getPointerMoveListenerRef(): TAnyFunction {
    return this.pointermoveListenerRef;
  }

  setPointerMoveListenerRef(ref: TAnyFunction): void {
    this.pointermoveListenerRef = ref;
  }

  getModifyingPolygon(): boolean {
    return this.modifyingPolygon;
  }

  setModifyingPolygon(modifying: boolean): void {
    this.modifyingPolygon = modifying;
  }

  getMoveendListenerRef(): TAnyFunction {
    return this.moveendListenerRef;
  }

  setMoveendListenerRef(ref: TAnyFunction): void {
    this.moveendListenerRef = ref;
  }

  getDragListenerRef(): TAnyFunction {
    return this.dragListenerRef;
  }

  setDragListenerRef(ref: TAnyFunction): void {
    this.dragListenerRef = ref;
  }
}
