import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { AccountSharesService } from './account-shares.service';
import { TShare, TUpdateShareDTO } from './share.model';
import { SharesService } from './shares.service';

@Injectable({
  providedIn: 'root',
})
export class AccountShareUpdateService {
  constructor(
    private sharesService: SharesService,
    private accountSharesService: AccountSharesService,
  ) {}

  updateShare(shareId: string, body: TUpdateShareDTO): Observable<TShare> {
    return this.sharesService
      .updateShare(shareId, body)
      .pipe(tap((response) => this.accountSharesService.updateShare(response.userId, response)));
  }
}
