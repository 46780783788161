<div class="shareFleetInviteSectionEmailInputWrapper">
  <pp-share-edit-email-input
    class="shareFleetInviteSectionEmailInput"
    [(ppEmailList)]="ppEmailList"
    (ppEmailListChange)="updateEmails($event)"
  ></pp-share-edit-email-input>

  <button
    [class.shareFleetInviteSectionEmailButton--disabled]="ppDisabled"
    class="shareFleetInviteSectionEmailButton"
    (click)="inviteUsers()"
  >
    <app-spinner *ngIf="ppProcessing"></app-spinner>
    <span *ngIf="!ppProcessing">
      {{ 'invite' | ppTranslate }}
    </span>
  </button>
</div>
