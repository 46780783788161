import { Injectable } from '@angular/core';

import { TPoint, TPointCustomFields } from '../points.model';

import { Store } from '@ngrx/store';
import { GET_SHARE } from 'src/app/project/modules/share/shares.store';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { TPointResponse } from 'src/app/project/view-models/point-response.model';
import { CustomFieldsService } from '../../custom-fields/custom-fields.service';
import { WorkspaceService } from '../../workspace/workspace.service';
import { UpdatePoint } from '../points.actions';
import { generateNewPoint } from './generate-new-point';
import { generatePoint } from './generate-point';

@Injectable({
  providedIn: 'root',
})
export class PointsGenerateService {
  constructor(
    private store: Store,
    private workspaceService: WorkspaceService,
    private customFieldsService: CustomFieldsService,
  ) {}

  generateNewPoint(workspaceId: string): TPoint {
    const workspace = this.workspaceService.getActiveWorkspace();
    const customFields = this.generatePointCustomFields(workspace, workspaceId);
    const share = GET_SHARE(workspaceId);

    const tags = share.defectTags ? share.defectTags : [];

    return generateNewPoint(customFields, workspaceId, tags);
  }

  generatePointCustomFields(workspace: TWorkspace, workspaceId: string): TPointCustomFields {
    const customFieldsData = this.customFieldsService.getCustomFields();
    const customFields: TPointCustomFields = {};

    if (workspace && workspace.customFields) {
      workspace.customFields.forEach((customFieldId) => {
        const customField = customFieldsData[workspaceId][customFieldId];
        if (customField) {
          customFields[customField.id] = {
            value: null,
            customFieldTemplateId: customField.id,
          };
        }
      });
    }

    return customFields;
  }

  generatePoint(point: TPointResponse | TPoint): TPoint {
    const workspace = this.workspaceService.findWorkspace(point.workspaceRef.id);
    const customFields = this.processCustomFields(point, workspace);

    return generatePoint(point, customFields);
  }

  correctPoint(point: TPoint): TPoint {
    if (point && !point.fullyInitialized) {
      const correctedPoint = this.generatePoint(point);

      this.store.dispatch(
        new UpdatePoint({
          workspaceId: point.workspaceRef.id,
          point: correctedPoint,
        }),
      );

      return correctedPoint;
    }

    return point;
  }

  processCustomFields(point: TPointResponse | TPoint, workspace: TWorkspace): TPointCustomFields {
    const customFields = this.generatePointCustomFields(workspace, workspace.workspaceId);

    if (point.customFieldsMap) {
      Object.keys(point.customFieldsMap).forEach((customFieldId) => {
        const customField = point.customFieldsMap[customFieldId];
        if (customFields[customField.customFieldTemplateId]) {
          customFields[customField.customFieldTemplateId].value = customField.value;
          customFields[customField.customFieldTemplateId].idOfChosenElement =
            customField.idOfChosenElement;
        }
      });
    }

    return customFields;
  }
}
