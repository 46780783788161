import { priorityOrder } from '../columns/property-sorting';
import { dehumanizePriority } from 'src/app/project/modules/points/priorities';
import { TColumn } from '../columns/column.model';
import { TGroupedPoints } from './group-model';

export function groupByPriority(_column: TColumn, _a: TGroupedPoints, _b: TGroupedPoints): number {
  if (priorityOrder[dehumanizePriority(_a.value)] < priorityOrder[dehumanizePriority(_b.value)]) {
    return _column.groupOrder === 'ASC' ? -1 : 1;
  } else if (
    priorityOrder[dehumanizePriority(_a.value)] > priorityOrder[dehumanizePriority(_b.value)]
  ) {
    return _column.groupOrder === 'ASC' ? 1 : -1;
  }
}
