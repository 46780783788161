<ng-container [ngSwitch]="ppNotification.changeBody.cfFieldType">
  <div class="notification__image-wrapper">
    <pp-icon
      class="notification__image"
      [ppSrc]="iconSrc"
      ppClass="setSize14"
      ppColor="primary-600"
    />
  </div>

  <ng-container *ngSwitchCase="ECustomFieldType.TEXT">
    <span class="notification__blue-text">{{ 'set' | ppTranslate }}</span>
    {{ 'the' | ppTranslate }}
    <span class="notification__bold">{{ ppNotification.changeBody.label }}</span>
    {{ 'of_a_point_you' | ppTranslate }}
    {{ notificationReason }}:
  </ng-container>

  <ng-container
    *ppSwitchCases="[
      ECustomFieldType.DATE,
      ECustomFieldType.COST,
      ECustomFieldType.PERCENTAGE,
      ECustomFieldType.LIST,
      ECustomFieldType.NUMBERS,
      ECustomFieldType.TIME,
      ECustomFieldType.TIMELINE,
      ECustomFieldType.FORMULA,
    ]"
  >
    <span *ngIf="ppNotification.changeBody.newValue && !ppNotification.changeBody.oldValue">
      <span class="notification__blue-text">{{ 'set' | ppTranslate }}</span>
      {{ 'the' | ppTranslate }}
      <span class="notification__bold">{{ ppNotification.changeBody.label }}</span>
      {{ 'of_a_point_you' | ppTranslate }}
      {{ notificationReason }}: {{ ppNotification.changeBody.newValue }}.
    </span>

    <span *ngIf="ppNotification.changeBody.newValue && ppNotification.changeBody.oldValue">
      <span class="notification__blue-text">{{ 'changed' | ppTranslate }}</span>
      {{ 'the' | ppTranslate }}
      <span class="notification__bold">{{ ppNotification.changeBody.label }}</span>
      {{ 'of_a_point_you' | ppTranslate }}
      {{ notificationReason }}
      {{ 'from' | ppTranslate }}: {{ ppNotification.changeBody.oldValue }}
      {{ 'to' | ppTranslate | lowercase }}: {{ ppNotification.changeBody.newValue }}.
    </span>

    <span *ngIf="!ppNotification.changeBody.newValue && ppNotification.changeBody.oldValue">
      <span class="notification__blue-text">{{ 'cleared' | ppTranslate }}</span>
      {{ 'the' | ppTranslate }}
      <span class="notification__bold">{{ ppNotification.changeBody.label }}</span>
      {{ 'of_a_point_you' | ppTranslate }}
      {{ notificationReason }}.
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="ECustomFieldType.RICHTEXT">
    <ng-container>
      <span class="notification__blue-text">{{ 'set' | ppTranslate }}</span>
      {{ 'the' | ppTranslate }}
      <span class="notification__bold">{{ ppNotification.changeBody.label }}</span>
      {{ 'of_a_point_you' | ppTranslate }}
      {{ notificationReason }}:
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="ECustomFieldType.CHECKBOX">
    <span *ngIf="ppNotification.changeBody?.newValue === 'yes'">
      <span class="notification__blue-text">{{ 'checked' | ppTranslate }}</span>
      {{ 'the' | ppTranslate }}
      <span class="notification__bold">{{ ppNotification.changeBody.label }}</span>
      {{ 'of_a_point_you' | ppTranslate }}
      {{ notificationReason }}.
    </span>

    <span *ngIf="!(ppNotification.changeBody?.newValue === 'yes')">
      <span class="notification__blue-text">{{ 'unchecked' | ppTranslate }}</span>
      {{ 'the' | ppTranslate }}
      <span class="notification__bold">{{ ppNotification.changeBody.label }}</span>
      {{ 'of_a_point_you' | ppTranslate }}
      {{ notificationReason }}.
    </span>
  </ng-container>
</ng-container>
