import { naturalSort } from '@core/helpers';
import { TAllUsers } from '@project/view-models';

export function setUserList(
  users: TAllUsers,
  userIds: string[],
): {
  value: string;
  id: string;
  email: string;
  avatar: string;
}[] {
  const userList = [];

  userIds.forEach((userId) => {
    if (users[userId] && users[userId].verified) {
      userList.push({
        value: users[userId].userName,
        id: userId,
        email: users[userId].email,
        avatar: users[userId].userName,
      });
    }
  });

  userList.sort((a, b) => naturalSort(a.value, b.value));

  return userList;
}
