import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TimeInputComponent } from './time-input.component';



@NgModule({
  declarations: [TimeInputComponent],
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [TimeInputComponent]
})
export class TimeInputModule { }
