import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountSubscriptionComponent } from './account-subscription.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { AutonumericModule } from '../../directives/autonumeric/autonumeric.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SwitchCasesModule } from '../../directives/switch-cases/switch-cases.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { LayoutModule } from '../../modules/layout/layout.module';
import { ButtonModule } from '../button/button.module';
import { LimitBarModule } from '../limit-bar/limit-bar.module';
import { PlaceholdersModule } from '../../modules/placeholders/placeholders.module';

@NgModule({
  declarations: [AccountSubscriptionComponent],
  imports: [
    CommonModule,
    SpinnerModule,
    TooltipsModule,
    AutonumericModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    PipesModule,
    LayoutModule,
    ButtonModule,
    LimitBarModule,
    PlaceholdersModule,
  ],
  exports: [AccountSubscriptionComponent],
})
export class AccountSubscriptionModule {}
