import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DashboardService } from '../../dashboard-service/dashboard.service';

import { TDashlet, TDashletSiteRange } from 'src/app/project/modules/preferences/preferences.model';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventDashboard,
  EGoogleEventFleetManagement,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { AssetDashboardService } from '../../../fleet-management/fleet-management-fleet-page/fleet-assets/asset-card/asset-dashboard/asset-dashboard.service';
import { EDashletPeriod } from '../../dashboard-timeframes-enums';
import { EDashletType } from '../../dashlets-enums';

@Component({
  selector: 'pp-dashlet-flip',
  templateUrl: './dashlet-flip.component.html',
  styleUrls: ['./dashlet-flip.component.scss'],
})
export class DashletFlipComponent implements OnInit {
  @Input() ppDashlet: TDashlet;
  @Input() ppAssetId: string;
  @Input() ppFlipped: boolean;
  @Input() ppDefaultWorkspaces: string[];
  @Output() ppToggleFlip = new EventEmitter<void>();
  @Output() ppSelectPeriod = new EventEmitter<EDashletPeriod>();
  @Output() ppSelectSiteRange = new EventEmitter<TDashletSiteRange>();

  dashletTypes = EDashletType;
  period: EDashletPeriod;
  siteRange: TDashletSiteRange;

  constructor(
    private dashboardService: DashboardService,
    private assetDashboardService: AssetDashboardService,
  ) {}

  ngOnInit() {
    this.period = this.ppDashlet.period;

    this.siteRange = {
      workspaceIds: this.ppDashlet.selectedRange.workspaceIds,
      accountId: this.ppDashlet.selectedRange.accountId,
    };
  }

  updatePeriod(data: EDashletPeriod): EDashletPeriod {
    this.period = data;

    return this.period;
  }

  removeDashlet(): void {
    if (this.ppAssetId) {
      logEventInGTAG(EGoogleEventFleetManagement.ASSET_DASHLET_REMOVE, {
        event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
        event_details: this.ppDashlet.name,
      });

      return this.assetDashboardService.removeDashlet(this.ppAssetId, this.ppDashlet);
    }

    logEventInGTAG(EGoogleEventDashboard.DASHBOARD__DASHLET__DELETE, {
      event_category: EGoogleEventCategory.DASHBOARD,
      event_details: this.ppDashlet.name,
    });

    this.dashboardService.removeDashlet(this.ppDashlet);
  }

  apply(): void {
    if (this.period !== this.ppDashlet.period) {
      this.ppSelectPeriod.emit(this.period);
    }

    if (
      this.siteRange.workspaceIds !== this.ppDashlet.selectedRange.workspaceIds ||
      this.siteRange.accountId !== this.ppDashlet.selectedRange.accountId
    ) {
      this.ppSelectSiteRange.emit(this.siteRange);
    }

    this.ppToggleFlip.emit();
  }

  flipDashlet(): void {
    this.period = this.ppDashlet.period;

    this.siteRange = {
      workspaceIds: this.ppDashlet.selectedRange.workspaceIds,
      accountId: this.ppDashlet.selectedRange.accountId,
    };

    this.ppToggleFlip.emit();
  }
}
