<li
  id="userDropdownBtn"
  data-m-target="Profile settings button"
  class="sidePanel__user"
  (click)="toggleUserDropdown('userDropdownBtn')"
>
  <img
    ppImage
    [ppSecureSrc]="'api/v1/images/' + ppUser.avatarId + '/file/size/square/40'"
    class="userDropdown__avatar"
    [class.userDropdown__avatar--disabled]="ppDisabled"
    *ngIf="ppUser.avatarId"
  />

  <img
    ppImage
    [src]="EIconPath.PLACEHOLDER_USER"
    class="userDropdown__avatar"
    [class.userDropdown__avatar--disabled]="ppDisabled"
    *ngIf="!ppUser.avatarId"
  />

  <div class="sidePanel__user-details" *ngIf="ppIsMobile && !sidePanel.rightPanelExpanded">
    <p class="sidePanel__user-details-email">
      {{ ppUser.userName }}
    </p>

    <h6 class="sidePanel__user-details-username">
      {{ ppUser.email }}
    </h6>
  </div>
</li>
