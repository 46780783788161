import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PromptService } from '../../components/prompt/prompt.service';
import { TranslationPipe } from '../../features/translate/translation.pipe';
import { logEventInGTAG } from '../../services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventFleetManagement,
} from '../../services/analytics/google-analytics.consts';
import { EFleetManagementRoutesSegments } from './fleet-management.routes';

@Injectable({
  providedIn: 'root',
})
export class FleetManagementRoutesService {
  constructor(
    private router: Router,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
  ) {}

  goToFleet(fleetId: string): void {
    if (fleetId) {
      this.router.navigate([
        EFleetManagementRoutesSegments.FLEET_MANAGEMENT,
        EFleetManagementRoutesSegments.FLEET,
        fleetId,
      ]);
    } else {
      this.goToNewFleetPage();
    }
  }

  goToAddAssetPage(fleetId: string): void {
    logEventInGTAG(EGoogleEventFleetManagement.GO_TO_NEW_ASSET_PAGE, {
      event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
    });

    this.router.navigate([
      EFleetManagementRoutesSegments.FLEET_MANAGEMENT,
      EFleetManagementRoutesSegments.FLEET,
      fleetId,
      EFleetManagementRoutesSegments.ASSET,
      EFleetManagementRoutesSegments.NEW,
    ]);
  }

  goToEditAssetPage(fleetId: string, assetId: string): void {
    logEventInGTAG(EGoogleEventFleetManagement.ASSET_GO_TO_EDIT, {
      event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
    });

    this.router.navigate([
      EFleetManagementRoutesSegments.FLEET_MANAGEMENT,
      EFleetManagementRoutesSegments.FLEET,
      fleetId,
      EFleetManagementRoutesSegments.ASSET,
      assetId,
    ]);
  }

  goToNewFleetPage(): void {
    logEventInGTAG(EGoogleEventFleetManagement.GO_TO_CREATE_NEW_FLEET_PAGE, {
      event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
    });

    this.router.navigate([
      EFleetManagementRoutesSegments.FLEET_MANAGEMENT,
      EFleetManagementRoutesSegments.NEW,
    ]);
  }

  redirectFromMissingFleet(activeFleetId: string): void {
    const prompt = this.translationPipe.transform('prompt_fleet_not_found');
    this.promptService.showWarning(prompt);

    this.goToFleet(activeFleetId);
  }
}
