import { Observable, throwError } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { ResponseErrorService } from '../../../modules/errors/response-error.service';

import { catchError } from 'rxjs/operators';
import { authorizeXMLHttpRequest } from 'src/app/core/helpers/authorize-xml-http-request';
import {
  API_files_documents_update,
  API_files_images_update,
  API_files_video_update,
} from 'src/app/project/data-providers/api-providers/files-api-provider/files-paths';
import { UserApiProviderService } from 'src/app/project/data-providers/api-providers/user-api-provider/user-api-provider.service';
import { WorkspaceApiProviderService } from 'src/app/project/data-providers/api-providers/workspace-api-provider/workspace-api-provider.service';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { EPlanFormat } from 'src/app/project/modules/site/site-new/site-plan-format/plan-format.enum';
import { NEW_POINT_ID } from 'src/app/project/shared/constants/point.const';
import { TranslationPipe } from '../../../features/translate/translation.pipe';
import { TFileToUpload } from '../../../modules/points/point-modal/point-attachments/point-attachments.service';
import { TUserResponse } from '../../../modules/user/response-models/user-response-model';
import { TSiteResponse } from '../../../modules/workspace/site-response.model';

export type UploadData = {
  progress: number;
  uploaded: boolean;
  uploading: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  uploadData: UploadData = {
    progress: 0,
    uploaded: false,
    uploading: false,
  };

  constructor(
    private responseErrorService: ResponseErrorService,
    private promptService: PromptService,
    private translationPipe: TranslationPipe,
    private userApiProviderService: UserApiProviderService,
    private workspaceApiProviderService: WorkspaceApiProviderService,
  ) {}

  getUploadData(): UploadData {
    return this.uploadData;
  }

  handleError() {
    return (error: HttpErrorResponse): Observable<never> => {
      let promptText = this.translationPipe.transform('prompt_file_upload_error');

      if (
        error.error['errors'] &&
        error.error['errors'][0] &&
        error.error['errors'][0].rootCauseMessage &&
        error.error['errors'][0].rootCauseMessage ===
          'IllegalStateException: Multipart Mime part the_file exceeds max filesize'
      ) {
        promptText = this.translationPipe.transform('prompt_file_size_too_large_error');
      }

      this.promptService.showError(promptText);

      this.responseErrorService.errors(error.status);
      logErrorInSentry(error);

      return throwError(error);
    };
  }

  uploadSitePlan(
    workspaceId: string,
    image: File,
    planFormat: EPlanFormat,
    contentType: string, // FIXME ContentType is deprecated here?
  ): Observable<TSiteResponse> {
    const formData = new FormData();

    formData.append('image_file', image);

    return this.workspaceApiProviderService
      .uploadSitePlan(workspaceId, formData, planFormat)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  importSitePlan(workspaceId: string, sourceWorkspaceId: string): Observable<TSiteResponse> {
    return this.workspaceApiProviderService.importSitePlan(sourceWorkspaceId, workspaceId);
  }

  uploadImage(
    workspaceId: string,
    _id: string,
    image: TFileToUpload,
    {
      updatePoint = false,
      is360 = false,
      isHeicHeif = false,
    }: {
      updatePoint?: boolean;
      is360?: boolean;
      isHeicHeif?: boolean;
    } = {},
  ): Promise<unknown> {
    const formData = new FormData();
    let photo360 = '';
    let apiVersion = 'v2';

    if (is360) {
      photo360 = '/upload-360-image';
      apiVersion = 'v1';

      const blob = image.file.slice(0, -1, image.file.type);
      const newFile = new File(
        [blob],
        image.file.name.replace(/&/g, 'AND').replace('HEIC', 'heic').replace('HEIF', 'heif'),
        { type: image.file.type },
      );
      image.file = newFile;
    } else if (isHeicHeif) {
      const blob = image.file;
      const newFile = new File(
        [blob],
        image.file.name.replace('HEIC', 'heic').replace('HEIF', 'heif'),
        {
          type: image.file.type,
        },
      );

      image.file = newFile;
    }

    formData.append('image_file', image.file);

    if (_id === NEW_POINT_ID) {
      formData.append('itemRefId', undefined);
    } else {
      formData.append('itemRefId', _id);
    }

    let req = new XMLHttpRequest();

    return new Promise((resolve, reject) => {
      req.open('POST', API_files_images_update(apiVersion, workspaceId, photo360, updatePoint));
      req = authorizeXMLHttpRequest(req);
      req.onload = resolve;

      req.upload.onprogress = (event): void => {
        this.updateItemUploadProgress(event, image);
      };

      req.onprogress = (event): void => {
        this.updateItemUploadProgress(event, image);
      };

      req.onerror = (error): void => {
        image.uploaded = false;
        image.uploading = false;

        this.handleError();
        reject(error);
      };

      req.send(formData);
    });
  }

  uploadVideo(
    workspaceId: string,
    _id: string,
    pointTitle: string = '',
    video: TFileToUpload,
    {
      updatePoint = false,
    }: {
      updatePoint?: boolean;
    },
  ): Promise<unknown> {
    const formData = new FormData();

    formData.append('video', video.file);

    if (_id === NEW_POINT_ID || !updatePoint) {
      formData.append('itemRefId', undefined);
    } else {
      formData.append('itemRefId', _id);
    }

    formData.append('itemRefType', 'DefectType');
    formData.append('itemRefCaption', pointTitle);

    let req = new XMLHttpRequest();

    req.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        this.updateItemUploadProgress(event, video);
      }
    });

    return new Promise((resolve, reject) => {
      req.open('POST', API_files_video_update(workspaceId, updatePoint));
      req = authorizeXMLHttpRequest(req);
      req.onload = resolve;
      req.onerror = (): void => {
        this.handleError();
        reject();
      };
      req.send(formData);
    });
  }

  uploadFile(
    workspaceId: string,
    _id: string,
    pointTitle: string = '',
    file: TFileToUpload,
    { updatePoint = false }: { updatePoint?: boolean } = {},
  ): Promise<unknown> {
    const formData = new FormData();

    formData.append('the_file', file.file);

    if (_id === NEW_POINT_ID) {
      formData.append('itemRefId', undefined);
    } else {
      formData.append('itemRefId', _id);
    }

    formData.append('itemRefType', 'DefectType');
    formData.append('itemRefCaption', pointTitle);

    let req = new XMLHttpRequest();

    req.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        this.updateItemUploadProgress(event, file);
      }
    });

    return new Promise((resolve, reject) => {
      req.open('POST', API_files_documents_update(workspaceId, updatePoint));
      req = authorizeXMLHttpRequest(req);
      req.onload = resolve;
      req.onerror = (): void => {
        this.handleError();
        reject();
      };
      req.send(formData);
    });
  }

  addImagesToDoc(type: string, id: string, images: File[]): Observable<TUserResponse> {
    const formData = new FormData();

    for (let i = 0; i < images.length; i += 1) {
      formData.append('image_file', images[i]);
    }

    return this.userApiProviderService
      .uploadAvatar(type.toLowerCase(), id, formData)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  private updateItemUploadProgress(event: ProgressEvent, item: TFileToUpload): void {
    if (event.lengthComputable) {
      item.progress = Math.round((event.loaded / event.total) * 100);
      item.uploaded = event.loaded === event.total;
      item.uploading = event.loaded !== event.total;
    }
  }
}
