<div
  #dragenter
  class="containerOne"
  [class.containerOne--half]="ppSide && ppSitePlan"
  (dragenter)="dragEnter($event)"
  (dragover)="dragover($event)"
>
  <div #dropzone class="dropzone" [hidden]="!showDropzone" (drop)="drop($event)">
    <div
      class="dropzone--full-height dropzone__custom-container dropzone__custom-container--drag dropzone--no-files"
    >
      <p>
        {{ 'drop_here_1' | ppTranslate }}
        <strong>{{ 'drop_here_2' | ppTranslate }}</strong>
        {{ 'drop_here_3' | ppTranslate }}
      </p>

      <img
        [src]="EIconPath.INPUT_UPLOAD_ILLUSTRATION"
        alt="Upload Illustration"
        class="dropzone__upload-illustration"
        srcset=""
      />
    </div>
  </div>

  <ng-content select="[header]"></ng-content>

  <div
    #pointBody
    data-m-target="Point modal section"
    class="point__body"
    (scroll)="calculateScroll($event)"
    [style.padding-right.px]="paddingPx"
    (ppOnRendered)="setPadding($event)"
  >
    <div class="actionBar--shadow" *ngIf="scrollPosition > 0">&nbsp;</div>
    <ng-content select="[body]"></ng-content>
  </div>

  <ng-content select="[footer]"></ng-content>
</div>

<div class="containerTwo" *ngIf="ppSide && ppSitePlan">
  <ng-content select="[side]"></ng-content>
</div>

<router-outlet *ngIf="!ppNew"></router-outlet>
