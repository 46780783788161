import { TPointCustomField } from '@project/view-models';
import { GET_CUSTOM_FIELDS } from '../../custom-fields/custom-fields.store';

export function getNumbersExportValue(
  exportType: string,
  pointCustomField: TPointCustomField,
): string {
  const customField = GET_CUSTOM_FIELDS()[pointCustomField.customFieldTemplateId];

  switch (exportType) {
    case 'xlsx':
      return pointCustomField.value;
    default:
      return customField.unit
        ? `${customField.unit} ${pointCustomField.value}`
        : pointCustomField.value;
  }
}
