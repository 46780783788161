import { cloneDeep } from 'lodash';

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Modal, ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { PointsDetailsService } from 'src/app/project/modules/points/points-details.service';
import { RemindersService } from '../reminders.service';

import { TGuid } from '@core/helpers';
import { of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { getTimeAsUtcMidday } from '../../../../core/helpers/date/date';
import { TReminderPointModalData } from './reminder-point-modal.model';

@Component({
  selector: 'pp-reminder-point-modal',
  templateUrl: './reminder-point-modal.component.html',
  styleUrls: [
    './reminder-point-modal.component.scss',
    '../../points/point-modal/point-fields/point-fields.component.scss',
    '../../points/point-modal/point-fields/point-fields-text/point-fields-text.component.scss',
  ],
})
export class ReminderPointModalComponent implements OnInit {
  @ViewChild('input') descriptionElement: ElementRef;
  @ViewChild('reminderNameInput') inputElement: ElementRef;

  modal: Modal;
  _id: TGuid;
  reminderValue: number = null;
  note = '';
  error = false;
  todayDateEpoch: number;
  allowPastDates = false;
  isFocused = false;
  characterLimit = 100;
  private reminderId: string = null;

  processing: boolean;
  EIconPath = EIconPath;

  constructor(
    private modalService: ModalService,
    private remindersService: RemindersService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private pointsDetailsService: PointsDetailsService,
    private router: Router,
  ) {
    this.todayDateEpoch = new Date().setHours(0, 0, 0, 0);
  }

  ngOnInit(): void {
    this.modal = this.modalService.getModal();
    const modalData: TReminderPointModalData = this.modal.data;
    this._id = modalData._id;
    this.reminderValue = modalData.reminderValue;
    this.note = modalData.note;
    this.reminderId = modalData.reminderId;
    this.allowPastDates = modalData.allowPastDates;
  }

  hideModal(cancel: boolean = true): void {
    this.modalService.hideModal(cancel);
  }

  saveReminder(): void {
    this.inputElement.nativeElement.blur();

    if (this.error || (!this._id && !this.note) || !this.reminderValue || this.processing) {
      return;
    }

    if (this.reminderId) {
      this.updateReminder();
    } else {
      this.createReminder();
    }
  }

  updateDate(dates: Date[]): void {
    this.reminderValue = getTimeAsUtcMidday(dates[0]);
  }

  onFocusChange(isFocused: boolean): void {
    this.isFocused = isFocused;
  }

  clearDescription(): void {
    this.note = '';
  }

  checkCharacterLimit(): void {
    this.note = this.note.trim();

    this.checkError();

    if (this.error) {
      const promptText = this.translationPipe.transform('prompt_text_over_limit', {
        characterLimit: this.characterLimit,
      });

      this.promptService.showWarning(promptText);
    }
  }

  checkError(event: Event = null): void {
    if (event?.target instanceof HTMLInputElement) {
      if (!this.note) {
        this.note = '';
      }

      const note = event ? event.target.value : this.note;

      this.error = note.length > this.characterLimit;
    }
  }

  private updateReminder(): void {
    this.processing = true;

    this.remindersService
      .updateReminder(this.reminderId, this._id, this.reminderValue, this.note)
      .pipe(
        tap((response) => {
          const prompt = this.translationPipe.transform('prompt_reminder_updated');

          this.pointsDetailsService.updateReminder(this._id, this.reminderId, response);
          this.remindersService.getRemindersForCurrentDay();

          this.promptService.showSuccess(prompt);
          this.hideModal(false);
        }),
        catchError((error) => {
          logErrorInSentry(error);
          let prompt = this.translationPipe.transform('prompt_reminder_updated_error');

          if (error.status === EStatusCode.FORBIDDEN) {
            prompt = this.translationPipe.transform('prompt_reminder_permission_denied');

            this.router.navigate(['/site']);
          }

          this.promptService.showError(prompt);
          return of([]);
        }),
        finalize(() => {
          this.hideModal();
        }),
      )
      .subscribe();
  }

  private createReminder(): void {
    this.processing = true;

    this.remindersService
      .createReminder(this._id, this.reminderValue, this.note)
      .pipe(
        tap((response) => {
          const prompt = this.translationPipe.transform('prompt_reminder_created');

          if (this._id) {
            this.pointsDetailsService.createReminder(this._id, response);
          }

          this.promptService.showSuccess(prompt);
          this.remindersService.addReminder(cloneDeep(response));
          this.remindersService.getRemindersForCurrentDay();
          this.hideModal(false);
        }),
        catchError((error) => {
          logErrorInSentry(error);
          let prompt = this.translationPipe.transform('prompt_reminder_created_error');

          if (error.status === EStatusCode.FORBIDDEN) {
            prompt = this.translationPipe.transform('prompt_reminder_permission_denied');

            this.hideModal();
            this.router.navigate(['/site']);
          }

          this.promptService.showError(prompt);
          this.processing = false;

          return of([]);
        }),
      )
      .subscribe();
  }

  checkKeyup(event: KeyboardEvent): void {
    if (event.code === 'ArrowDown' || event.code === 'ArrowUp') {
      event.stopImmediatePropagation();
    }
  }
}
