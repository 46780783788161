import { Component, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { AddUsersIssuesService } from '../add-users-issues.service';

@Component({
  selector: 'pp-add-users-error-box',
  templateUrl: './add-users-error-box.component.html',
  styleUrls: ['./add-users-error-box.component.scss'],
})
export class AddUsersErrorBoxComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  errorMessages: string[] = [];
  EIconPath = EIconPath;

  constructor(private createAccountUserModalIssuesService: AddUsersIssuesService) {
    this.subscribeToErrorChange();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  handleErrors(): void {
    this.errorMessages = this.createAccountUserModalIssuesService.getErrorMessages();
  }

  private subscribeToErrorChange() {
    this.createAccountUserModalIssuesService.errorChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe((errors) => {
        this.handleErrors();
      });
  }
}
