import { TDashletGraph } from '../../dashlet-graph.model';
import { generateBarGraphOptions } from './bar-graph-options';

export function createHistoricPriorityGraph(isMobile, isExport): TDashletGraph {
  return {
    type: 'bar',
    data: {
      labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
      datasets: [
        {
          data: [],
          label: 'High',
          backgroundColor: '#e54f50',
          borderColor: '#e54f50',
          hoverBackgroundColor: '#e54f50',
          hoverBorderColor: '#e54f50',
        },
        {
          data: [],
          label: 'Medium',
          backgroundColor: '#ffce29',
          borderColor: '#ffce29',
          hoverBackgroundColor: '#ffce29',
          hoverBorderColor: '#ffce29',
        },
        {
          data: [],
          label: 'Low',
          backgroundColor: '#4da0e5',
          borderColor: '#4da0e5',
          hoverBackgroundColor: '#4da0e5',
          hoverBorderColor: '#4da0e5',
        },
      ],
    },
    options: generateBarGraphOptions(isMobile, isExport),
  };
}
