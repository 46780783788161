import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { UserService } from 'src/app/project/modules/user/user.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { TAccount } from '../../../../account.model';
import { AccountSitesService } from '../account-sites.service';

@Component({
  selector: 'pp-account-sites-header-buttons',
  templateUrl: './account-sites-header-buttons.component.html',
  styleUrls: ['./account-sites-header-buttons.component.scss'],
})
export class AccountSitesHeaderButtonsComponent implements OnDestroy {
  @Input() ppAccountId: string;

  showArchived: boolean;
  canSeeArchived = false;

  private readonly destroy$ = new Subject<void>();
  private accounts$: Observable<TAccount[]>;

  constructor(
    private store: Store<{
      accounts: TAccount[];
    }>,
    private router: Router,
    private accountSitesService: AccountSitesService,
    private activeService: ActiveService,
    private userService: UserService,
  ) {
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));

    this.accountSitesService.showArchivedChange$
      .pipe(
        takeUntil(this.destroy$),
        tap((showArchived) => {
          this.showArchived = showArchived;
        }),
      )
      .subscribe();

    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
      if (accounts.length > 0) {
        const accountId = this.activeService.getActiveAccountId();
        const account = accounts.find((account) => account.accountId === accountId);

        this.canSeeArchived = this.userService.getUser().userId === account.accountOwnerId;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  newSite(): void {
    this.router.navigate(['/settings/site/new', this.ppAccountId]);
  }

  trySetKeyword(keyword: string): void {
    if (keyword === this.accountSitesService.getKeyword()) {
      return;
    }

    this.accountSitesService.setKeyword(keyword);
  }

  toggleShowArchivedData(): void {
    this.accountSitesService.toggleShowArchived(!this.showArchived);
  }
}
