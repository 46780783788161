import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { TPoints, TPointsByWorkspace } from 'src/app/project/modules/points/points.model';

import { RemindersService } from '../../reminders.service';
import { PointsDetailsService } from 'src/app/project/modules/points/points-details.service';

import { TAllFilters } from 'src/app/project/modules/filters/site-filter.model';
import { TGroupedReminders, TReminderList } from './reminder-list.model';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { ReminderListService } from './reminder-list.service';
import { EIconPath } from '../../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-reminder-list',
  templateUrl: './reminder-list.component.html',
  styleUrls: ['./reminder-list.component.scss'],
})
export class ReminderListComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @Input() ppFetched = false;
  @Input() ppOffline = false;

  todayReminders: TReminderList = {
    values: [],
    numberOfDisplayedValues: 0,
    maxValues: 10,
    totalValues: 0,
  };

  pastReminders: TReminderList = {
    values: [],
    numberOfDisplayedValues: 0,
    maxValues: 10,
    totalValues: 0,
  };

  futureReminders: TReminderList = {
    values: [],
    numberOfDisplayedValues: 0,
    maxValues: 10,
    totalValues: 0,
  };

  groupsExpanded = this.remindersService.getGroupsExpanded();
  groupedReminders: TGroupedReminders = {};
  EIconPath = EIconPath;

  private points$: Observable<TPointsByWorkspace>;

  constructor(
    private store: Store<{
      points: TPointsByWorkspace;
      siteFilter: TAllFilters;
    }>,
    private remindersService: RemindersService,
    private pointsDetailsService: PointsDetailsService,
    private reminderListService: ReminderListService,
  ) {
    this.points$ = this.store.pipe(select(EStore.POINTS));
  }

  ngOnInit(): void {
    this.remindersService.setReminderList(this);

    this.points$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.updateReminders();
    });

    this.pointsDetailsService.reactionsChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe((reminderId: string) => {
        this.handleReactionChange(reminderId);
      });

    this.updateReminders();
  }

  ngOnChanges() {
    this.updateReminders();
  }

  ngOnDestroy() {
    this.remindersService.setReminderList(null);

    this.destroy$.next();
  }

  toggleGroup(group: 'past' | 'today' | 'future'): void {
    this.groupsExpanded[group] = !this.groupsExpanded[group];
  }

  updateReminders(): void {
    if (!this.ppFetched) {
      return;
    }

    const { groupedReminders, pastReminders, todayReminders, futureReminders } =
      this.reminderListService.updateReminders(
        this.pastReminders,
        this.todayReminders,
        this.futureReminders,
      );

    this.groupedReminders = groupedReminders;
    this.pastReminders = pastReminders;
    this.todayReminders = todayReminders;
    this.futureReminders = futureReminders;
  }

  loadMorePast(): void {
    this.pastReminders.maxValues += 10;

    this.updateReminders();
  }

  loadMoreToday(): void {
    this.todayReminders.maxValues += 10;

    this.updateReminders();
  }

  loadMoreFuture(): void {
    this.futureReminders.maxValues += 10;

    this.updateReminders();
  }

  private handleReactionChange(reminderId: string): void {
    if (reminderId) {
      this.todayReminders.values.forEach((group) => {
        group.values = group.values.filter((_reminderId) => _reminderId !== reminderId);
      });

      this.pastReminders.values.forEach((group) => {
        group.values = group.values.filter((_reminderId) => _reminderId !== reminderId);
      });

      this.futureReminders.values.forEach((group) => {
        group.values = group.values.filter((_reminderId) => _reminderId !== reminderId);
      });
    }

    this.updateReminders();
  }
}
