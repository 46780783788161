import {
  TAdvancedAccessLevels,
  TGranularPermission,
  TSemiGranularPermission,
  TUpdateShareDTO,
} from 'src/app/project/modules/share/share.model';

export const createPartialShareDTOForComments = (
  granularPermission: TGranularPermission,
): TUpdateShareDTO => {
  return createPartialShareDTOWrapper({
    timeline: {
      comments: createPermissionWrapper(granularPermission),
    },
  });
};

const createPartialShareDTOWrapper = (advancedAccessLevel: Partial<TAdvancedAccessLevels>) => {
  return {
    advancedAccessLevels: advancedAccessLevel,
  };
};

const createPermissionWrapper = (granularPermission: TGranularPermission) => {
  return {
    permission: granularPermission,
  };
};

export const createPartialShareDTOForTags = (
  granularPermission: TGranularPermission,
): TUpdateShareDTO => {
  return createPartialShareDTOWrapper({
    tags: createPermissionWrapper(granularPermission),
  });
};

export const createPartialShareDTOForCF = (
  granularPermission: TGranularPermission,
  customFieldId: string,
): TUpdateShareDTO => {
  if (!customFieldId) {
    return {};
  }

  return createPartialShareDTOWrapper({
    customFields: [
      {
        templateId: customFieldId,
        ...createPermissionWrapper(granularPermission),
      },
    ],
  });
};

export const createPartialShareDTOForActivities = (
  semiGranularPermission: TSemiGranularPermission,
): TUpdateShareDTO => {
  return createPartialShareDTOWrapper({
    timeline: createSemiPermissionWrapper(semiGranularPermission),
  });
};

const createSemiPermissionWrapper = (semiGranularPermission: TSemiGranularPermission) => {
  return {
    permission: semiGranularPermission,
  };
};

export const createPartialShareDTOForExports = (
  semiGranularPermission: TSemiGranularPermission,
): TUpdateShareDTO => {
  return createPartialShareDTOWrapper({
    exports: createSemiPermissionWrapper(semiGranularPermission),
  });
};
