import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NotificationsModule } from '../notifications/notifications.module';
import { SupportedBrowsersModule } from '../supported-browsers/supported-browsers.module';

import { ScrollingModule as ExperimentalScrollingModule } from '@angular/cdk-experimental/scrolling';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { OfflineModule } from '../offline/offline.module';

import { ContentComponent } from './content/content.component';

import { TopBarComponent } from './top-bar/top-bar.component';

import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { locationHostFactory } from '../../../core/services/window/locationFactory';
import { ButtonModule } from '../../components/button/button.module';
import { IconModule } from '../../components/icon/icon-module';
import { HideDropdownModule } from '../../components/input/hide-dropdown/hide-dropdown.module';
import { SelectModule } from '../../components/input/select/select.module';
import { ModalModule } from '../../components/modal/modal.module';
import { SearchBoxModule } from '../../components/search-box/search-box.module';
import { ToggleModule } from '../../components/toggle/toggle.module';
import { AutonumericModule } from '../../directives/autonumeric/autonumeric.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SecureSrcModule } from '../../directives/secure-src/secure-src.module';
import { SwitchCasesModule } from '../../directives/switch-cases/switch-cases.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { ActionBarComponent } from './action-bar/action-bar.component';
import { GridComponent } from './grid/grid.component';
import { SidePanelBackComponent } from './side-panel/side-panel-component/side-panel-back/side-panel-back.component';
import { SidePanelDashboardComponent } from './side-panel/side-panel-component/side-panel-dashboard/side-panel-dashboard.component';
import { SidePanelFleetManagementComponent } from './side-panel/side-panel-component/side-panel-fleet-management/side-panel-fleet-management.component';
import { SidePanelLogoComponent } from './side-panel/side-panel-component/side-panel-logo/side-panel-logo.component';
import { SidePanelNotificationsComponent } from './side-panel/side-panel-component/side-panel-notifications/side-panel-notifications.component';
import { SidePanelOverviewComponent } from './side-panel/side-panel-component/side-panel-overview/side-panel-overview.component';
import { SidePanelRemindersComponent } from './side-panel/side-panel-component/side-panel-reminders/side-panel-reminders.component';
import { SidePanelSitesComponent } from './side-panel/side-panel-component/side-panel-sites/side-panel-sites.component';
import { SidePanelTimelineComponent } from './side-panel/side-panel-component/side-panel-timeline/side-panel-timeline.component';
import { SidePanelUserComponent } from './side-panel/side-panel-component/side-panel-user/side-panel-user.component';
import { SidePanelComponent } from './side-panel/side-panel-component/side-panel.component';
import { SidePanelService } from './side-panel/side-panel.service';
import { FleetSettingsDropdownComponent } from './top-bar/top-bar-fleet-management-header/fleet-settings-dropdown/fleet-settings-dropdown.component';
import { TopBarFleetManagementHeaderComponent } from './top-bar/top-bar-fleet-management-header/top-bar-fleet-management-header.component';
import { TopBarFeetOptionsFleetSelectDropdownComponent } from './top-bar/top-bar-fleet-options/top-bar-fleet-options-fleet-select/top-bar-feet-options-fleet-select-dropdown/top-bar-feet-options-fleet-select-dropdown.component';
import { TopBarFleetOptionsFleetSelectComponent } from './top-bar/top-bar-fleet-options/top-bar-fleet-options-fleet-select/top-bar-fleet-options-fleet-select.component';
import { TopBarFleetOptionsComponent } from './top-bar/top-bar-fleet-options/top-bar-fleet-options.component';
import { LogoutModalComponent } from './user-menu-dropdown/logout-modal/logout-modal.component';
import { UserMenuDropdownComponent } from './user-menu-dropdown/user-menu-dropdown.component';
import { TopBarFleetOptionsAssetSearchComponent } from './top-bar/top-bar-fleet-options/top-bar-fleet-options-asset-search/top-bar-fleet-options-asset-search.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ScrollingModule,
    FormsModule,
    ExperimentalScrollingModule,
    ButtonModule,
    OfflineModule,
    ToggleModule,
    SupportedBrowsersModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    NotificationsModule,
    PipesModule,
    ModalModule,
    IconModule,
    SecureSrcModule,
    SelectModule,
    HideDropdownModule,
    SearchBoxModule,
  ],
  exports: [
    ContentComponent,
    TopBarComponent,
    SidePanelComponent,
    GridComponent,
    ActionBarComponent,
  ],
  providers: [SidePanelService, { provide: 'HOSTNAME', useFactory: locationHostFactory }],
  declarations: [
    ContentComponent,
    TopBarComponent,
    SidePanelComponent,
    UserMenuDropdownComponent,
    GridComponent,
    ActionBarComponent,
    LogoutModalComponent,
    SidePanelBackComponent,
    SidePanelLogoComponent,
    SidePanelDashboardComponent,
    SidePanelOverviewComponent,
    SidePanelTimelineComponent,
    SidePanelRemindersComponent,
    SidePanelNotificationsComponent,
    SidePanelUserComponent,
    SidePanelSitesComponent,
    SidePanelFleetManagementComponent,
    TopBarFleetOptionsComponent,
    TopBarFleetOptionsFleetSelectComponent,
    TopBarFleetManagementHeaderComponent,
    FleetSettingsDropdownComponent,
    TopBarFeetOptionsFleetSelectDropdownComponent,
    TopBarFleetOptionsAssetSearchComponent,
  ],
})
export class LayoutModule {}
