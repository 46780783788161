import JSZip from 'jszip';
import { cloneDeep } from 'lodash';

import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  ViewChild,
} from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import {
  ToggleAllFilesSelection,
  ToggleAllMediaSelection,
  ToggleFileSelection,
  ToggleMediaSelection,
} from '../../attachments/attachments.actions';

import {
  TAttachment,
  TAttachments,
  TAttachmentsFiles,
  TAttachmentsMedia,
} from '../../attachments/attachments.model';

import { GalleryOverlayService } from 'src/app/project/components/gallery-overlay/gallery-overlay.service';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { AttachmentsService } from 'src/app/project/modules/points/attachments/attachments.service';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { RequestService } from 'src/app/project/services/requests/request.service';
import { PromptService } from '../../../../components/prompt/prompt.service';
import { TooltipDirective } from '../../../../features/tooltip/tooltip.directive';
import { AttachmentsFilesService } from '../../attachments/attachments-files.service';
import { AttachmentsMediaService } from '../../attachments/attachments-media.service';
import { PointsService } from '../../points.service';
import { PointAttachmentsDeleteService } from './point-attachments-delete.service';
import { PointAttachmentsService, TFilesToUpload } from './point-attachments.service';
import { PointDocumentsService } from './point-documents/point-documents.service';

import { ApiService } from '@core/http';
import { TAllUsers } from '@project/view-models';
import {
  API_files_custom_type,
  API_files_documents_file,
} from 'src/app/project/data-providers/api-providers/files-api-provider/files-paths';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EDocumentType } from 'src/app/project/shared/enums/document-type.enum';
import { EFileType } from 'src/app/project/shared/enums/file-type.enum';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { getExtenstion } from '../../../../../core/helpers/get-extenstion';
import { DownloadService } from '../../../../services/download.service';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { RenameFileModalComponent } from './rename-file-modal/rename-file-modal.component';

@Component({
  selector: 'pp-point-attachments',
  templateUrl: './point-attachments.component.html',
  styleUrls: ['./point-attachments.component.scss'],
})
export class PointAttachmentsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(TooltipDirective, { static: true }) tooltipDirective = null;

  @Input() ppWorkspaceId: string;
  @Input() ppPointId: string;
  @Input() ppNew = false;
  @Input() ppCanEdit: boolean;
  @Input() ppFull: boolean;

  private readonly destroy$ = new Subject<void>();

  EDocumentType = EDocumentType;
  filesToUpload: TFilesToUpload = this.pointAttachmentsService.getFilesToUpload();
  uploadingPointId: string = null;

  selectedMediaCount = this.pointAttachmentsService.getSelectedMediaCount();
  selectedFilesCount = this.pointAttachmentsService.getSelectedFilesCount();
  downloadingMedia = false;

  showProgressBar = true;

  allUsers: TAllUsers;
  EIconPath = EIconPath;

  media: TAttachmentsMedia = {
    dates: [],
    attachments: {},
  };

  files: TAttachmentsFiles = {
    attachmentIds: [],
    attachments: {},
  };

  sortedAttachments: string[];

  isAvatarDefault = false;

  private attachments$: Observable<TAttachments>;

  constructor(
    private store: Store<{
      attachments: TAttachments;
    }>,
    private pointsService: PointsService,
    private pointAttachmentsService: PointAttachmentsService,
    private promptService: PromptService,
    private galleryOverlayService: GalleryOverlayService,
    private attachmentsService: AttachmentsService,
    private attachmentsMediaService: AttachmentsMediaService,
    private attachmentsFilesService: AttachmentsFilesService,
    private pointAttachmentsDeleteService: PointAttachmentsDeleteService,
    private requestService: RequestService,
    private pointDocumentsService: PointDocumentsService,
    private translationPipe: TranslationPipe,
    private modalService: ModalService,
    private usersService: UsersService,
    private downloadService: DownloadService,
    private apiService: ApiService,
  ) {
    this.attachments$ = this.store.pipe(select(EStore.ATTACHMENTS));
  }

  ngOnInit() {
    this.attachments$.pipe(takeUntil(this.destroy$)).subscribe((attachments) => {
      this.media = cloneDeep(attachments.media);
      this.files = cloneDeep(attachments.files);

      this.sortedAttachments = this.files.attachmentIds.sort(
        (a, b) => +this.files.attachments[b].createdOn - +this.files.attachments[a].createdOn,
      );

      this.files.attachmentIds.forEach((attachmentId) => {
        this.files.attachments[attachmentId].isPreviewAvailable = this.isPreviewAvailable(
          this.files.attachments[attachmentId],
        );
      });

      this.pointAttachmentsDeleteService.setMediaAndFiles(this.media, this.files);
      this.allUsers = this.usersService.getUsers();
    });

    this.pointAttachmentsDeleteService.setPoint(this.ppPointId, this.ppWorkspaceId, this.ppFull);
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    this.pointAttachmentsDeleteService.setPoint(this.ppPointId, this.ppWorkspaceId, this.ppFull);

    if (changes.ppCanEdit && Object.keys(changes).length === 1) {
      return;
    }

    this.attachmentsService.clearAttachments();

    if (!this.ppNew) {
      this.attachmentsFilesService.fetchFiles(this.ppPointId);
      this.attachmentsMediaService.fetchMedia(this.ppPointId).subscribe();
    }

    if (changes.ppPointId && this.ppPointId === this.uploadingPointId) {
      this.selectedMediaCount.number = 0;
      this.showProgressBar = true;
    } else if (changes.ppPointId) {
      this.selectedMediaCount.number = 0;
      this.showProgressBar = false;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.attachmentsService.clearAttachments();
  }

  showDefaultAvatar(): void {
    this.isAvatarDefault = true;
  }

  uploadMediaAndFiles(files: File[]): void {
    this.pointAttachmentsService.uploadMediaAndFiles(files, this.ppNew, this.ppPointId);
  }

  cancelUpload(index: number): void {
    this.pointAttachmentsService.cancelUpload(index);
  }

  private countSelectedMedia(): void {
    this.pointAttachmentsService.countSelectedMedia();
  }

  async downloadSelectedMedia(): Promise<void> {
    this.downloadingMedia = true;
    const media = Object.keys(this.media.attachments).map((key) => this.media.attachments[key]);
    const zip: JSZip = new JSZip();

    const selectedMedia = media.filter((mediaItem) => {
      if (mediaItem.selected) {
        return mediaItem;
      }
    });

    if (selectedMedia.length === 1) {
      const mediaItem = selectedMedia[0];

      if (mediaItem.type === 'Image') {
        this.downloadSingleImage(mediaItem);
      } else if (mediaItem.type === 'Image360') {
        this.downloadSingleImage360(mediaItem);
      } else if (mediaItem.type === 'Video') {
        this.downloadFile(mediaItem, 'video');
      } else {
        this.downloadFile(mediaItem, 'document');
      }
      return;
    }

    logEventInGTAG(EGoogleEventSite.SITE__POINT__ATTACHMENT_DOWNLOAD, {
      event_category: EGoogleEventCategory.SITE,
      amount: selectedMedia.length,
    });

    const mediaSize = this.pointAttachmentsService.calculateSelectedSize(selectedMedia);
    const promptText = this.translationPipe.transform('prompt_attachments_size_info', {
      size: mediaSize,
    });
    this.promptService.showSuccess(promptText);

    await Promise.all(
      selectedMedia.map(async (mediaItem) => {
        const mediaUrl = this.attachmentsService.createMediaUrl(mediaItem);
        const mediaBlob = await this.apiService
          .getFile(mediaUrl)
          .toPromise()
          .then((r) => r.blob());

        await zip.file(
          this.attachmentsService.createMediaFileName(
            mediaItem.attachmentId,
            mediaItem.fileName,
            mediaItem.mimeType,
          ),
          mediaBlob,
        );
      }),
    );

    zip.generateAsync({ type: 'blob', compression: 'DEFLATE' }).then((content) => {
      this.downloadZip(content);
    });
  }

  downloadZip(blob: Blob): void {
    const point = this.pointsService.findPoint(this.ppPointId);

    this.downloadService.saveFile(blob, `PinpointMedia-Point${point.sequenceNumber}.zip`);
    this.downloadingMedia = false;
  }

  downloadFile(file: TAttachment, type: string): void {
    const encodedUri = encodeURI(API_files_custom_type(type, file.attachmentId));
    const mediaSize = this.pointAttachmentsService.calculateSelectedSize([file]);
    const promptText = this.translationPipe.transform('prompt_attachments_size_info', {
      size: mediaSize,
    });

    let attachmentName = file.fileName || ' unnamed';

    const extension = getExtenstion(file.fileName);

    if ((!extension || extension.length === 0) && file.mimeType === EFileType.MP4) {
      attachmentName += '.mp4';
    }

    this.promptService.showSuccess(promptText);

    logEventInGTAG(EGoogleEventSite.SITE__POINT__ATTACHMENT_DOWNLOAD, {
      event_category: EGoogleEventCategory.SITE,
      amount: 1,
    });

    this.downloadService.downloadGET({ url: encodedUri, fileName: attachmentName }).subscribe();
    this.downloadingMedia = false;
  }

  openDocument(file: TAttachment): void {
    this.pointDocumentsService.openDocument(file);
  }

  downloadSingleImage360(mediaItem: TAttachment): void {
    const mediaSize = this.pointAttachmentsService.calculateSelectedSize([mediaItem]);
    const promptText = this.translationPipe.transform('prompt_attachments_size_info', {
      size: mediaSize,
    });
    const mediaUrl = this.attachmentsService.createMediaUrl(mediaItem);

    this.downloadService
      .downloadGET({ url: mediaUrl })
      .pipe(
        tap(() => {
          this.downloadingMedia = false;
          this.promptService.showSuccess(promptText);

          logEventInGTAG(EGoogleEventSite.SITE__POINT__ATTACHMENT_DOWNLOAD, {
            event_category: EGoogleEventCategory.SITE,
            amount: 1,
          });
        }),
      )
      .subscribe();
  }

  async downloadSingleImage(mediaItem: TAttachment): Promise<void> {
    const mediaSize = this.pointAttachmentsService.calculateSelectedSize([mediaItem]);
    const promptText = this.translationPipe.transform('prompt_attachments_size_info', {
      size: mediaSize,
    });

    const mediaUrl = this.attachmentsService.createMediaUrl(mediaItem);

    this.downloadService.downloadGET({ url: mediaUrl, fileName: mediaItem.fileName }).subscribe();

    this.promptService.showSuccess(promptText);

    logEventInGTAG(EGoogleEventSite.SITE__POINT__ATTACHMENT_DOWNLOAD, {
      event_category: EGoogleEventCategory.SITE,
      amount: 1,
    });

    this.downloadingMedia = false;
  }

  deleteAttachment(file: TAttachment): void {
    this.pointAttachmentsDeleteService.deleteAttachment(file);
  }

  openGallery(mediaItem: TAttachment): void {
    if (this.selectedMediaCount.number > 0) {
      this.selectMedia(mediaItem);
    } else {
      if (!this.ppNew) {
        const rotationInProgress = this.requestService.isRequestOfTypeInProcess('imageRotate');

        if (rotationInProgress) {
          const promptText = this.translationPipe.transform(
            'prompt_attachments_rotation_in_progress',
          );

          this.promptService.showWarning(promptText);

          return;
        }

        const attachmentId = mediaItem.attachmentId;

        this.galleryOverlayService.navigateToAttachment(attachmentId);
      }
    }
  }

  setUploadPointId(event: string): void {
    this.showProgressBar = true;
    this.uploadingPointId = event;
  }

  isPreviewAvailable(file: TAttachment): boolean {
    const isPdf = this.pointDocumentsService.isPdf(file);
    const isText = this.pointDocumentsService.isText(file);

    if (isPdf || isText) {
      return true;
    } else {
      return false;
    }
  }

  deleteBulkMedia(): void {
    const media = Object.keys(this.media.attachments).map((key) => this.media.attachments[key]);

    const selectedMedia = media.filter((mediaItem) => {
      if (mediaItem.selected) {
        return mediaItem;
      }
    });

    this.pointAttachmentsDeleteService.deleteFiles(selectedMedia);
  }

  deleteBulkFiles(): void {
    const files = Object.keys(this.files.attachments).map((key) => this.files.attachments[key]);

    const selectedFiles = files.filter((fileItem) => {
      if (fileItem.selected) {
        return fileItem;
      }
    });

    this.pointAttachmentsDeleteService.deleteFiles(selectedFiles);
  }

  selectMedia(media: TAttachment): void {
    media.selected = !media.selected;

    this.store.dispatch(
      new ToggleMediaSelection({
        attachmentId: media.attachmentId,
        selected: media.selected,
      }),
    );

    this.countSelectedMedia();
  }

  selectFile(file: TAttachment): void {
    file.selected = !file.selected;

    this.store.dispatch(
      new ToggleFileSelection({
        attachmentId: file.attachmentId,
        selected: file.selected,
      }),
    );

    this.countSelectedMedia();
  }

  selectAllFiles(): void {
    if (this.selectedFilesCount.number === this.files.attachmentIds.length) {
      this.files.attachmentIds.forEach((attachmentId) => {
        this.files.attachments[attachmentId].selected = false;
      });

      this.store.dispatch(
        new ToggleAllFilesSelection({
          selected: false,
        }),
      );
    } else {
      this.files.attachmentIds.forEach((attachmentId) => {
        this.files.attachments[attachmentId].selected = true;
      });

      this.store.dispatch(
        new ToggleAllFilesSelection({
          selected: true,
        }),
      );
    }

    this.countSelectedMedia();
  }

  selectAllMedia(): void {
    if (this.selectedMediaCount.number === Object.keys(this.media.attachments).length) {
      Object.keys(this.media.attachments).forEach((attachmentId) => {
        this.media.attachments[attachmentId].selected = false;
      });

      this.store.dispatch(
        new ToggleAllMediaSelection({
          selected: false,
        }),
      );
    } else {
      Object.keys(this.media.attachments).forEach((attachmentId) => {
        this.media.attachments[attachmentId].selected = true;
      });

      this.store.dispatch(
        new ToggleAllMediaSelection({
          selected: true,
        }),
      );
    }

    this.countSelectedMedia();
  }

  async downloadSelectedFiles(): Promise<void> {
    this.downloadingMedia = true;
    const files = Object.keys(this.files.attachments).map((key) => this.files.attachments[key]);
    const zip: JSZip = new JSZip();

    const selectedFiles = files.filter((fileItem) => {
      if (fileItem.selected) {
        return fileItem;
      }
    });

    if (selectedFiles.length === 1) {
      const fileItem = selectedFiles[0];

      this.downloadFile(fileItem, 'document');
      return;
    }

    logEventInGTAG(EGoogleEventSite.SITE__POINT__ATTACHMENT_DOWNLOAD, {
      event_category: EGoogleEventCategory.SITE,
      amount: selectedFiles.length,
    });

    const mediaSize = this.pointAttachmentsService.calculateSelectedSize(selectedFiles);
    const promptText = this.translationPipe.transform('prompt_attachments_size_info', {
      size: mediaSize,
    });

    this.promptService.showSuccess(promptText);

    await Promise.all(
      selectedFiles.map(async (fileItem) => {
        const mediaUrl = encodeURI(API_files_documents_file(fileItem.attachmentId));
        const mediaBlob = await this.apiService
          .getFile(mediaUrl)
          .toPromise()
          .then((r) => r.blob());

        await zip.file(
          this.attachmentsService.createMediaFileName(
            fileItem.attachmentId,
            fileItem.fileName,
            fileItem.mimeType,
          ),
          mediaBlob,
        );
      }),
    );

    zip.generateAsync({ type: 'blob', compression: 'DEFLATE' }).then((content) => {
      this.downloadZip(content);
    });
  }

  renameAttachment(attachment: TAttachment): void {
    this.modalService.setData({
      attachment,
    });

    this.modalService.showModal(RenameFileModalComponent);
  }
}
