import {
  AUTH_LOCAL_STORAGE_KEY,
  AUTH_TOKEN_HEADER_NAME,
  USER_EMAIL_HEADER_NAME,
} from 'src/app/project/shared/constants/auth.constants';

let userAuth: TUserAuth = null;

export type TUserAuth = {
  email?: string;
  token?: string;
  expiration?: string | null;
};

export function getUserAuth(): TUserAuth {
  return userAuth;
}

export function setUserAuth(auth: TUserAuth): void {
  userAuth = auth;
  localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(auth));
}

export function readUserAuthFromLocalStorage(): TUserAuth {
  const userAuthFromLocalStorage = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);

  if (userAuthFromLocalStorage) {
    userAuth = JSON.parse(userAuthFromLocalStorage);
  } else {
    userAuth = {
      email: null,
      token: null,
      expiration: null,
    };
  }

  return userAuth;
}

export function clearUserAuth(): void {
  userAuth = null;

  localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
}

export function getAuthHeaders(): Record<string, string | never> {
  readUserAuthFromLocalStorage();

  if (userAuth?.token && userAuth?.email) {
    return {
      [AUTH_TOKEN_HEADER_NAME]: userAuth.token,
      [USER_EMAIL_HEADER_NAME]: userAuth.email,
    };
  }

  return {};
}
