import { TLogoRefOrAdminUserRef } from '../../view-models/account-response.model';
import { TAccountFolder } from '../../view-models/account-simple-response.model';
import { EUserRole } from '../share/share-utils/user-roles';

export type TShareId = string;

export enum EAccountSubscriptionType {
  LIMITED = 'LIMITED',
  UNLIMITED = 'UNLIMITED',
}

export type TAccount = {
  accountId: string;
  accountName: string;
  accountType: string;
  industryType?: string;
  subscriptionType?: string;
  accountManager?: string;
  accountFeatures?: TAccountFeatures;
  websiteUrl?: string;
  workspaces: string[];
  accountOwnerId?: string;
  accountFolders: TAccountFolder[];
  logoRef?: TLogoRefOrAdminUserRef;
};

export type TAccountFeatures = {
  offline: boolean;
  pdfSignatures: boolean;
  globalSitePreferences: boolean;
  timeline: boolean;
  reminders: boolean;
  aalSitePlan: boolean;
  aalExport: boolean;
  pdfYcoLayout: boolean;
  savedViews: boolean;
  advancedFilters: boolean;
  fleetManagement: boolean;
};

export type TAccountLimits = {
  SHARES_ACCOUNT_ADMIN: number;
  SHARES_ADMIN: number;
  SHARES_GUEST: number;
  SHARES_NORMAL: number;
  SITES: number;
};

export type TAccountSubscription = {
  accountId: string;
  subscriptionType: EAccountSubscriptionType;
  usages: TAccountLimits;
  limits: Partial<TAccountLimits>;
};

export type TSubscriptionLimit = {
  max: number;
  current: number;
};

export type TAccountSubscriptionUpdate = {
  accountId: string;
  subscriptionType?: EAccountSubscriptionType;
  limits: Partial<TAccountLimits>;
};

export type TSimulatedUsageBody = {
  createList: TSimulatedUsageCreateShare[];
  importList: TSimulatedUsageImportShare[];
  editList: TSimulatedUsageEditShare[];
  deleteList: TSimulatedUsageDeleteShare[];
};

export type TSimulatedUsageCreateShare = {
  shareOption: EUserRole;
  userEmail: string;
};

export type TSimulatedUsageEditShare = {
  shareOption: EUserRole;
  shareId: string;
};

export type TSimulatedUsageImportShare = {
  shareOption: EUserRole;
  userId: string;
};

export type TSimulatedUsageDeleteShare = TShareId;
