<span
  class="pointCF__currency-symbol"
  [ngClass]="{ 'pointCF__currency-symbol--readOnly': ppReadOnly }"
>
  %
</span>

<input
  #input
  [ppAutonumeric]="autonumericOptions"
  class="pointCF__input pointCF__input--currency"
  [class.pointCF__input--error]="ppUpdating === 'failed'"
  [(ngModel)]="value"
  (change)="updateField()"
  *ngIf="!ppReadOnly"
  placeholder=" --"
  inputmode="numeric"
  pattern="[0-9]*"
  (keydown.enter)="blurInput($event)"
  (keydown)="onKeyDown($event)"
/>

<button class="pointCF__clear" (click)="clearCustomField()" *ngIf="!ppReadOnly && !ppUpdating">
  &#xd7;
</button>
