import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';

import { attachmentsReducer } from './attachments.reducer';

import { AttachmentsService } from './attachments.service';
import { AttachmentsFilesService } from './attachments-files.service';
import { AttachmentsMediaService } from './attachments-media.service';
import { AttachmentsMediaThumbnailsService } from './attachments-media-thumbnails.service';
import { AttachmentsUploadService } from './attachments-upload.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('attachments', attachmentsReducer)],
  providers: [
    AttachmentsService,
    AttachmentsFilesService,
    AttachmentsMediaService,
    AttachmentsMediaThumbnailsService,
    AttachmentsUploadService,
  ],
})
export class AttachmentsModule {}
