<header class="dashletHead dashletHead__front" [class.dashletHead__front--disabled]="!ppCanEdit">
  <div class="dashletHead__acc-site" *ngIf="ppDashlet.name !== EDashletType.COST_COMPARISON">
    <p class="dashletHead__account" ppTooltip [ppTitle]="accountTooltip" [ppSanitize]="false">
      {{ accountName }}
    </p>

    <p class="dashletHead__site" ppTooltip [ppTitle]="siteTooltip" [ppSanitize]="false">
      {{ siteName }}
    </p>
  </div>

  <div class="dashletHead__title-front">
    {{ title }}

    <pp-icon
      [ppSrc]="EIconPath.ICON_BANNER_INFO_24"
      *ngIf="tooltipText"
      ppTooltip
      [ppTitle]="tooltipText"
      ppClass="setSize24"
      ppColor="inherit"
    ></pp-icon>
  </div>

  <button
    data-m-target="Dashlet settings button"
    class="dashletHead__button"
    (click)="flipDashlet()"
    *ngIf="ppCanEdit"
  >
    <pp-icon [ppSrc]="EIconPath.ICON_NAV_COG_24" ppClass="setSize24" ppColor="inherit" />
  </button>
</header>

<header class="dashletHead dashletHead__back" data-m-target="Dashlet settings back button">
  <button class="dashletHead__backButton" (click)="ppToggleFlip.emit()">
    <pp-icon
      [ppSrc]="EIconPath.ICON_LEFT_MENU_ARROW_BACK_14"
      ppColor="inherit"
      ppClass="setSize14"
    ></pp-icon>
    <p class="dashletHead__backButton--text">
      {{ 'back' | ppTranslate }}
    </p>
  </button>

  <h5 class="dashletHead__title">{{ 'dashlet_settings' | ppTranslate }}</h5>
</header>
