import { TPoint } from 'src/app/project/modules/points/points.model';
import { TFilters } from '../site-filter.model';

export function checkExcludedTags(point: TPoint, filters: TFilters): boolean {
  if (filters.excludedTags.appliedValue.length === 0) {
    return true;
  }

  const tags = point.tags;

  if (tags.length === 0 && filters.excludedTags.appliedValue.length > 0) {
    return true;
  }

  const combined = filters.excludedTags.combinedTags;

  if (combined) {
    let numberOfMatchedTags = 0;

    for (
      let appliedTagIndex = 0;
      appliedTagIndex < filters.excludedTags.appliedValue.length;
      appliedTagIndex++
    ) {
      const appliedTag = filters.excludedTags.appliedValue[appliedTagIndex];

      if (tags.includes(appliedTag)) {
        numberOfMatchedTags++;
      }
    }

    if (numberOfMatchedTags === filters.excludedTags.appliedValue.length) {
      return false;
    }
  } else {
    for (
      let appliedTagIndex = 0;
      appliedTagIndex < filters.excludedTags.appliedValue.length;
      appliedTagIndex++
    ) {
      const appliedTag = filters.excludedTags.appliedValue[appliedTagIndex];

      if (tags.includes(appliedTag)) {
        return false;
      }
    }

    return true;
  }

  return true;
}
