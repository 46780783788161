import { Injectable } from '@angular/core';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { PlanDataService } from 'src/app/project/modules/plan/plan-data.service';
import { PermissionsService } from 'src/app/project/modules/share/permissions.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { TElementPerWorkspace, TExportPoints, TExportPointsWorkspace } from './export-body.model';
import { PointExportFilterService } from './point-export-filter.service';
import { CustomTableService } from '../site/site-table/custom-table/custom-table.service';

@Injectable({
  providedIn: 'root',
})
export class PointExportUtilsService {
  constructor(
    private activeService: ActiveService,
    private workspaceService: WorkspaceService,
    private planDataService: PlanDataService,
    private permissionsService: PermissionsService,
    private pointExportFilterService: PointExportFilterService,
    private customTableService: CustomTableService,
  ) {}

  pushPlan(): { mapZoomString: string } {
    const workspaceId = this.activeService.getActiveWorkspaceId();
    const workspaces = this.workspaceService.getWorkspaces();
    const plan = this.planDataService.getPlan();
    const sitePlanEnabled = this.permissionsService.isSitePlanEnabled(workspaceId);

    let mapZoom: number;
    let mapZoomString: string;

    if (
      workspaces[workspaceId] &&
      workspaces[workspaceId].sitePlan &&
      workspaces[workspaceId].sitePlan.sitePlanURL &&
      plan.instance.site &&
      sitePlanEnabled
    ) {
      mapZoom = plan.instance.site.getView().getZoom();

      if (!mapZoom || mapZoom < 1) {
        mapZoom = 1;
      }

      mapZoom = Math.floor(mapZoom);
    }

    if (mapZoom) {
      mapZoomString = mapZoom.toString();
    }

    return { mapZoomString };
  }

  getOverviewElementsPerWorkspace(pointIds: string[] | TElementPerWorkspace[]): string[] {
    return pointIds as string[];
  }

  getElementsPerWorkspace(
    workspaceId: string,
    coords: string[],
    pointIds: string[] | TElementPerWorkspace[],
  ): TElementPerWorkspace[] {
    const sitePlanEnabled = this.permissionsService.isSitePlanEnabled(workspaceId);

    const elementPerWorkspace: TElementPerWorkspace = {
      workspaceId,
      pointIds: pointIds as string[],
    };

    if (sitePlanEnabled && coords !== null) {
      elementPerWorkspace.exportCoordinates = {
        x1: coords[0],
        y1: coords[1],
        x2: coords[2],
        y2: coords[3],
      };
    }

    const elementsPerWorkspace = [elementPerWorkspace];

    return elementsPerWorkspace;
  }

  sortOverviewPoints(points: TExportPoints, workspaceId: string): string[] {
    const table = this.customTableService.getTable();

    const sortedItems = points[workspaceId].points.sort((a, b) => {
      const firstPoint = a;
      const secondPoint = b;

      const indexOfFirstPoint = table.points.findIndex((_point) => _point._id === firstPoint);

      const indexOfSecondPoint = table.points.findIndex((_point) => _point._id === secondPoint);

      const indexOfFirstPointInTable = table.items.findIndex(
        (_item) => _item.index === indexOfFirstPoint,
      );

      const indexOfSecondPointInTable = table.items.findIndex(
        (_item) => _item.index === indexOfSecondPoint,
      );

      return indexOfFirstPointInTable - indexOfSecondPointInTable;
    });

    return sortedItems;
  }

  getOverviewReportPoints(): TElementPerWorkspace[] {
    const pointsAsArray = [];
    const points = this.pointExportFilterService.filterOverviewPoints();

    Object.keys(points).forEach((workspaceId) => {
      const sortedItems = this.sortOverviewPoints(points, workspaceId);
      const sitePlanEnabled = this.permissionsService.isSitePlanEnabled(workspaceId);

      const elementPerWorkspace: TElementPerWorkspace = {
        workspaceId,
        pointIds: sortedItems,
      };

      if (sitePlanEnabled) {
        elementPerWorkspace.exportCoordinates = {
          x1: this.getExtentCoordinates(points[workspaceId], 0),
          y1: this.getExtentCoordinates(points[workspaceId], 1),
          x2: this.getExtentCoordinates(points[workspaceId], 2),
          y2: this.getExtentCoordinates(points[workspaceId], 3),
        };
      }

      pointsAsArray.push(elementPerWorkspace);
    });

    return pointsAsArray;
  }

  private getExtentCoordinates(
    workspacePoints: TExportPointsWorkspace,
    extentIndex: number,
  ): string {
    return workspacePoints.extent
      ? workspacePoints.extent[extentIndex].toFixed(4).toString()
      : '0.0';
  }
}
