import { HttpClient } from '@angular/common/http';
import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { catchError, of, tap } from 'rxjs';
import { getAuthHeaders } from 'src/app/core/http/user-auth';
import { EIconPath } from '../../shared/enums/icons.enum';

@Directive({
  selector: '[ppSecureSrc]',
})
export class SecureSrcDirective implements OnChanges {
  @Input('ppSecureSrc') src: string;
  constructor(private elementRef: ElementRef, private http: HttpClient) {}

  ngOnChanges(): void {
    this.http
      .get(this.src, {
        headers: {
          ...getAuthHeaders(),
        },
        responseType: 'blob',
      })
      .pipe(
        tap((blob) => {
          if (blob) {
            const url = URL.createObjectURL(blob);
            this.setImageSrc(url);
          } else {
            this.setImageSrc(EIconPath.PLACEHOLDER_IMAGE);
          }
        }),
        catchError((error) => {
          this.setImageSrc(EIconPath.PLACEHOLDER_IMAGE);

          return of(error);
        }),
      )
      .subscribe();
  }

  private setImageSrc(source: string) {
    const imgElement = this.elementRef.nativeElement;

    if (imgElement.src) {
      URL.revokeObjectURL(imgElement.src);
    }

    imgElement.src = source;

    imgElement.onload = (): void => {
      URL.revokeObjectURL(EIconPath.PLACEHOLDER_IMAGE);
    };
  }
}
