<pp-modal>
  <ng-container heading> {{ 'delete_site' | ppTranslate }} </ng-container>

  <ng-container body>
    <span class="no-margin">{{ 'delete_site_modal_1' | ppTranslate }}</span>
    <span class="deleteSite__bold">
      {{ siteName }}
    </span>
    <span class="no-margin">{{ 'delete_site_modal_2' | ppTranslate }}</span>

    <div class="deleteSite_userInfo">
      <span class="no-margin">{{ 'delete_site_modal_3' | ppTranslate }}</span>
    </div>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'Delete' | ppTranslate"
      [ppProcess]="processing"
      (ppCancel)="hideModal()"
      (ppConfirm)="deleteSite()"
      [ppError]="true"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
