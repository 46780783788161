import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { TAccountUserError } from './response-error.consts';

@Injectable({
  providedIn: 'root',
})
export class AccountUserErrorService {
  constructor() {}

  parseAccountUserError(error): Observable<TAccountUserError[]> {
    return from(
      new Promise<TAccountUserError[]>((resolve) => {
        error.text().then((text) => {
          const textJSON: {
            errors: TAccountUserError[];
          } = JSON.parse(text);

          resolve(textJSON.errors);
        });
      }),
    );
  }
}
