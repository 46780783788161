<div class="previewContainer">
  <div class="logoPreview__wrapper">
    <img class="logoPreview" [ppSecureSrc]="logoSrc" *ngIf="logoSrc" />
    <img class="logoPreview" [src]="logoBuffer" *ngIf="logoBuffer" />
  </div>

  <svg
    width="317"
    height="177"
    viewBox="0 0 317 177"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_0_1)">
      <rect width="317" height="176.992" rx="6" fill="white" />
      <line
        x1="26.949"
        y1="27.3439"
        x2="643.736"
        y2="27.3439"
        stroke="#DCE1E4"
        stroke-width="0.446947"
      />
      <rect x="19.6588" y="0.303711" width="7.29039" height="8.9637" fill="#313A45" />
      <rect
        x="26.6907"
        y="0.297852"
        width="617.048"
        height="26.8282"
        [attr.fill]="ppWhiteLabel.data.panelAccent ? ppWhiteLabel.data.panelAccent : '#2D3A40'"
      />
      <g filter="url(#filter0_d_0_1)">
        <rect
          x="35.8882"
          y="33.6013"
          width="53.6336"
          height="14.3023"
          rx="7.15115"
          fill="#0084F8"
        />
      </g>
      <g filter="url(#filter1_d_0_1)">
        <rect
          x="26.6885"
          y="-0.294922"
          width="155.489"
          height="359.911"
          [attr.fill]="ppWhiteLabel.data.panelAccent ? ppWhiteLabel.data.panelAccent : '#2D3A40'"
        />
      </g>
      <rect
        x="-0.128418"
        y="-0.294922"
        width="26.8168"
        height="359.345"
        [attr.fill]="ppWhiteLabel.data.panelBg ? ppWhiteLabel.data.panelBg : '#181D23'"
      />
      <line
        x1="6.69135"
        y1="147.687"
        x2="19.8732"
        y2="147.687"
        stroke="#30363B"
        stroke-width="0.893893"
      />
      <path
        *ngIf="!logoSrc"
        d="M14.8058 14.4029C14.499 14.1709 14.1318 14.0277 13.746 13.9896C13.3601 13.9515 12.9711 14.02 12.6231 14.1873C12.275 14.3546 11.9819 14.6139 11.777 14.936C11.5721 15.258 11.4636 15.6298 11.4639 16.0091C11.461 16.3379 11.5423 16.6622 11.7006 16.9523C11.9461 17.4239 12.3629 17.9269 12.6405 18.3108C14.2662 20.5163 14.9042 21.1641 14.0129 23.1295C13.8415 23.4335 13.6779 23.745 13.5386 24.0489V24.0565C12.8938 22.5856 11.6879 21.1032 10.6789 19.6694C10.1802 18.9663 9.54314 18.0946 9.2899 17.2876C9.1625 16.8728 9.09689 16.4422 9.09509 16.0091C9.09531 15.3774 9.23653 14.7533 9.50891 14.1805C9.78128 13.6077 10.1782 13.1 10.672 12.6928L11.1785 12.6842C12.5509 12.6766 13.9243 13.0357 14.6996 14.2266C14.7398 14.2825 14.7753 14.3414 14.8058 14.4029"
        fill="#569FD7"
      />
      <path
        *ngIf="!logoSrc"
        d="M13.5396 11.6616C14.6399 11.6617 15.7011 12.0608 16.518 12.7817C17.3349 13.5027 17.8495 14.4943 17.9623 15.5648C18.0751 16.6354 17.7781 17.7088 17.1286 18.5776C16.4792 19.4463 15.5235 20.0487 14.4464 20.2683C14.3568 20.1006 14.2662 19.9405 14.16 19.7805C13.7918 19.2089 13.3594 18.6458 12.9513 18.0942L12.8285 17.9189C13.0563 18.0002 13.2971 18.0409 13.5396 18.039C14.0563 18.04 14.5549 17.852 14.9373 17.512C15.3197 17.172 15.5583 16.7045 15.6064 16.2012C15.6883 15.602 15.4116 12.3018 11.1785 12.3333C11.8851 11.8968 12.7039 11.6655 13.5396 11.6664"
        fill="#FFD02E"
      />
      <path
        *ngIf="!logoSrc"
        d="M13.5395 14.3789C13.8692 14.3789 14.1914 14.4745 14.4654 14.6536C14.7395 14.8327 14.9531 15.0873 15.0792 15.3852C15.2054 15.683 15.2384 16.0108 15.1741 16.327C15.1098 16.6432 14.951 16.9336 14.718 17.1616C14.4849 17.3896 14.188 17.5448 13.8647 17.6077C13.5414 17.6706 13.2063 17.6383 12.9018 17.5149C12.5973 17.3916 12.337 17.1826 12.1539 16.9146C11.9707 16.6465 11.873 16.3314 11.873 16.009C11.873 15.5766 12.0486 15.162 12.3611 14.8563C12.6736 14.5506 13.0975 14.3789 13.5395 14.3789"
        fill="#E45050"
      />
      <path
        *ngIf="!logoSrc"
        d="M14.389 23.6174C15.0923 23.7127 15.5744 23.929 15.5744 24.169C15.5744 24.5044 14.6676 24.7769 13.5397 24.7769C12.4118 24.7769 11.5049 24.5053 11.5049 24.17C11.5049 23.9299 11.992 23.7146 12.6894 23.6184C12.711 23.6402 12.7302 23.6642 12.7468 23.6898C12.3709 23.7537 12.1176 23.8737 12.1176 24.0014C12.1176 24.2176 12.7546 24.3824 13.5397 24.3824C14.3247 24.3824 14.9618 24.2148 14.9618 24.0014C14.9618 23.8737 14.7163 23.7537 14.3325 23.6898C14.3569 23.666 14.3734 23.6422 14.39 23.626L14.389 23.6174Z"
        fill="white"
      />
      <rect
        x="3.00256"
        y="62.7668"
        width="20.5596"
        height="20.5596"
        rx="10.2798"
        [attr.fill]="
          ppWhiteLabel.data.panelFontHighlight ? ppWhiteLabel.data.panelFontHighlight : '#0084F8'
        "
      />
      <rect
        x="3.00031"
        y="35.9504"
        width="20.5596"
        height="20.5596"
        rx="10.2798"
        [attr.fill]="ppWhiteLabel.data.panelFont ? ppWhiteLabel.data.panelFont : '#313B48'"
      />
      <rect x="9.25964" y="69.0247" width="8.04504" height="8.04504" rx="1.34084" fill="white" />
      <rect x="9.25964" y="95.8416" width="8.04504" height="8.04504" rx="1.34084" fill="white" />
      <rect x="9.25964" y="122.658" width="8.04504" height="8.04504" rx="1.34084" fill="white" />
      <rect x="9.25964" y="165.565" width="8.04504" height="8.04504" rx="1.34084" fill="white" />
      <rect x="9.25995" y="42.2073" width="8.04504" height="8.04504" rx="1.34084" fill="white" />
      <rect
        x="34"
        y="33"
        width="140"
        height="17"
        rx="8.5"
        stroke-width="2"
        [attr.stroke]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
      />
      <circle
        cx="44.0833"
        cy="41.5"
        r="3.75"
        [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
        [attr.stroke]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
        stroke-width="2"
      />
      <path
        d="M55 39H102V44H55V39Z"
        [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
        [attr.stroke]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
        stroke-width="2"
      />
    </g>
    <rect x="0.5" y="0.5" width="316" height="175.992" rx="5.5" stroke="#CBD5DC" />
    <rect
      x="33"
      y="110"
      width="142"
      height="17"
      rx="3"
      [attr.fill]="ppWhiteLabel.data.panelTextHover ? ppWhiteLabel.data.panelTextHover : '#629BCE'"
    />
    <path
      d="M50 116H147V121H50V116Z"
      [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
    />
    <path
      d="M51 63H83V66H51V63Z"
      [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
      [attr.stroke]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
      stroke-width="2"
    />
    <path
      d="M34 15H89V22H34V15Z"
      [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
      [attr.stroke]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
      stroke-width="2"
    />
    <path
      d="M51 99H112V102H51V99Z"
      [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
      [attr.stroke]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
      stroke-width="2"
    />
    <path
      d="M51 81H136V84H51V81Z"
      [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
      [attr.stroke]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
      stroke-width="2"
    />
    <mask
      id="mask0_0_1"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="25"
      y="52"
      width="24"
      height="24"
    >
      <rect
        x="25"
        y="52"
        width="24"
        height="24"
        rx="1"
        [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
      />
    </mask>
    <g mask="url(#mask0_0_1)">
      <path
        d="M37.7071 66.2929C37.3166 66.6834 36.6834 66.6834 36.2929 66.2929L33.7071 63.7071C33.0771 63.0771 33.5233 62 34.4142 62H39.5858C40.4767 62 40.9229 63.0771 40.2929 63.7071L37.7071 66.2929Z"
        [attr.fill]="ppWhiteLabel.data.panelText ? ppWhiteLabel.data.panelText : '#6E7881'"
      />
    </g>
    <mask
      id="mask1_0_1"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="118"
      y="118"
      width="26"
      height="26"
    >
      <rect x="118.5" y="118.5" width="25" height="25" rx="1" fill="#D9D9D9" stroke="#FFFEFE" />
    </mask>
    <g mask="url(#mask1_0_1)">
      <path
        d="M129.058 134.188L132.11 140.756C132.343 141.258 132.94 141.475 133.441 141.24L135.26 140.389C135.759 140.156 135.975 139.561 135.742 139.061L132.685 132.5H137.554C138.504 132.5 138.919 131.3 138.172 130.714L126.118 121.242C125.462 120.727 124.5 121.194 124.5 122.029V137.442C124.5 138.414 125.747 138.815 126.313 138.024L129.058 134.188Z"
        fill="#1C1B1F"
        stroke="#FFFEFE"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_0_1"
        x="31.4187"
        y="30.9196"
        width="62.5725"
        height="23.2412"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.78779" />
        <feGaussianBlur stdDeviation="2.23473" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0.517647 0 0 0 0 0.972549 0 0 0 0.15 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
      </filter>
      <filter
        id="filter1_d_0_1"
        x="17.5421"
        y="-7.61204"
        width="173.782"
        height="378.203"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.82928" />
        <feGaussianBlur stdDeviation="4.5732" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
      </filter>
      <clipPath id="clip0_0_1">
        <rect width="317" height="176.992" rx="6" fill="white" />
      </clipPath>
    </defs>
  </svg>
</div>
