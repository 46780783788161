import { fabric } from 'fabric';

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { GalleryOverlayService } from 'src/app/project/components/gallery-overlay/gallery-overlay.service';
import {
  IMAGE_ANNOTATIONS_DELETE,
  ListenerService,
} from '../../../../core/helpers/listener.service';
import { ModalService } from '../../modal/modal.service';
import { PromptService } from '../../prompt/prompt.service';
import { ImageAnnotationsService } from './image-annotations.service';

import { Subject, of } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { TranslationPipe } from '../../../features/translate/translation.pipe';
import { EIconPath } from '../../../shared/enums/icons.enum';
import {
  ConfirmModalComponent,
  TConfirmModalParams,
} from '../../confirm-modal/confirm-modal.component';
import {
  TAnnotationsAddedParams,
  TAnnotationsCoords,
  TRotationAngleChangeParams,
} from './image-annotation.model';
import { ImageAnnotationsArrowService } from './image-annotations-arrow.service';
import { EImageAnnotationsColor } from './image-annotations-color.enum';
import { ImageAnnotationsEllipseService } from './image-annotations-ellipse.service';
import {
  EImageAnnotationsState,
  ImageAnnotationsStateService,
} from './image-annotations-state.service';
import { ImageAnnotationsTextService } from './image-annotations-text.service';

@Component({
  selector: 'pp-image-annotations',
  templateUrl: './image-annotations.component.html',
  styleUrls: ['./image-annotations.component.scss'],
})
export class ImageAnnotationsComponent implements OnInit, OnDestroy {
  @ViewChild('imageCanvasElement', { static: true }) imageCanvasElement: ElementRef;
  @ViewChild('imageCanvasPlaceholderElement', { static: true })
  imageCanvasPlaceholderElement: ElementRef;
  @Input() ppImageId = '';
  @Input() ppWidth: number = null;
  @Input() ppHeight: number = null;
  @Input() ppOverlayIndex: number = null;
  @Input() ppRotationAngle: number = null;
  @Input() ppImageName = '';
  @Input() ppImageCreatedOn: string | number = '';
  @Output() ppGetButtonsStatusChange = new EventEmitter();
  @Output() ppAnnotationSaving = new EventEmitter();
  @Output() ppAnnotationAdded = new EventEmitter<TAnnotationsAddedParams>();
  @Output() ppRotationAngleChanged = new EventEmitter<TRotationAngleChangeParams>();
  @Input() ppPointId = '';

  private readonly destroy$ = new Subject<void>();

  canvasFabric: fabric.Canvas = null;
  EImageAnnotationsState = EImageAnnotationsState;

  addingAnnotation = false;
  currentMode: EImageAnnotationsState = null;
  EIconPath = EIconPath;

  colors: {
    key: string;
    value: EImageAnnotationsColor;
  }[] = [
    {
      value: EImageAnnotationsColor.WHITE,
      key: 'WHITE',
    },
    {
      value: EImageAnnotationsColor.BLACK,
      key: 'BLACK',
    },
    {
      value: EImageAnnotationsColor.YELLOW,
      key: 'YELLOW',
    },
    {
      value: EImageAnnotationsColor.RED,
      key: 'RED',
    },
    {
      value: EImageAnnotationsColor.BLUE,
      key: 'BLUE',
    },
    {
      value: EImageAnnotationsColor.GREEN,
      key: 'GREEN',
    },
  ];

  pickedColorName: EImageAnnotationsColor = EImageAnnotationsColor.BLUE;
  private pickedColorCode: string;

  colorsVisible = false;
  processing = false;

  constructor(
    private imageAnnotationsService: ImageAnnotationsService,
    private promptService: PromptService,
    private listenerService: ListenerService,
    private modalService: ModalService,
    private galleryOverlayService: GalleryOverlayService,
    private translationPipe: TranslationPipe,
    private imageAnnotationsStateService: ImageAnnotationsStateService,
    private imageAnnotationsTextService: ImageAnnotationsTextService,
    private imageAnnotationsArrowService: ImageAnnotationsArrowService,
    private imageAnnotationsEllipseService: ImageAnnotationsEllipseService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnInit() {
    this.imageAnnotationsStateService.modeChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe((newMode) => {
        this.currentMode = newMode;
      });

    this.imageAnnotationsStateService.colorChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ colorName, colorCode }) => {
        this.pickedColorName = colorName;
        this.pickedColorCode = colorCode;
      });

    const { canvasWidth, canvasHeight } = this.imageAnnotationsService.getImageCanvasElementSize();
    this.imageCanvasElement.nativeElement.width = canvasWidth;
    this.imageCanvasElement.nativeElement.height = canvasHeight;

    this.addingAnnotation = true;

    this.imageAnnotationsService.registerCanvasFabric();

    this.canvasFabric = this.imageAnnotationsService.getCanvasFabric();

    this.registerCanvasFabricEvents();

    this.listenerService.add(IMAGE_ANNOTATIONS_DELETE, () => this.deleteObject());

    this.arrowMode();
  }

  private registerCanvasFabricEvents(): void {
    this.canvasFabric.on('object:moved', () => {
      logEventInGTAG(EGoogleEventSite.SITE__GALLERY__OBJECT__MOVE, {
        event_category: EGoogleEventCategory.SITE,
      });
    });

    this.canvasFabric.on('mouse:down', (e: { pointer: TAnnotationsCoords }): void => {
      const activeObjects = this.canvasFabric.getActiveObjects();

      if (activeObjects.length === 0) {
        if (this.currentMode === EImageAnnotationsState.ADDING_ARROW) {
          this.imageAnnotationsArrowService.addArrow(this.pickedColorCode, true, e.pointer);
        } else if (this.currentMode === EImageAnnotationsState.ADDING_TEXT) {
          this.imageAnnotationsTextService.addText(this.pickedColorCode, true, e.pointer);
        } else if (this.currentMode === EImageAnnotationsState.ADDING_ELLIPSE) {
          this.imageAnnotationsService.resetState();
          this.imageAnnotationsEllipseService.addEllipse(this.pickedColorCode, e.pointer);
        }
      }
    });
  }

  arrowMode(): void {
    logEventInGTAG(EGoogleEventSite.SITE__GALLERY__TOOL, {
      event_category: EGoogleEventCategory.SITE,
      event_details: 'ARROW',
    });

    this.imageAnnotationsStateService.setMode(EImageAnnotationsState.ADDING_ARROW);
    this.imageAnnotationsTextService.cancelEditing();
    this.imageAnnotationsService.initStrictMode();
  }

  textMode(): void {
    logEventInGTAG(EGoogleEventSite.SITE__GALLERY__TOOL, {
      event_category: EGoogleEventCategory.SITE,
      event_details: 'TEXT',
    });

    this.imageAnnotationsStateService.setMode(EImageAnnotationsState.ADDING_TEXT);
    this.imageAnnotationsService.initStrictMode();
  }

  freeDrawingMode(): void {
    logEventInGTAG(EGoogleEventSite.SITE__GALLERY__TOOL, {
      event_category: EGoogleEventCategory.SITE,
      event_details: 'DRAWING',
    });

    this.imageAnnotationsStateService.setMode(EImageAnnotationsState.FREE_DRAWING);
    this.imageAnnotationsTextService.cancelEditing();
    this.imageAnnotationsService.initFreeDrawingMode(this.pickedColorCode);
  }

  ellipseMode(): void {
    logEventInGTAG(EGoogleEventSite.SITE__GALLERY__TOOL, {
      event_category: EGoogleEventCategory.SITE,
      event_details: 'ELLIPSE',
    });

    this.imageAnnotationsStateService.setMode(EImageAnnotationsState.ADDING_ELLIPSE);
    this.imageAnnotationsTextService.cancelEditing();
    this.imageAnnotationsService.initStrictMode();
  }

  changeColor(color: EImageAnnotationsColor): void {
    this.colorsVisible = false;
    this.imageAnnotationsStateService.setColor(color);
    this.imageAnnotationsService.applyColor(this.pickedColorCode);
    this.imageAnnotationsTextService.enterTextObjectEditing();
  }

  deleteObject(): void {
    logEventInGTAG(EGoogleEventSite.SITE__GALLERY__OBJECT__DELETE, {
      event_category: EGoogleEventCategory.SITE,
    });

    this.imageAnnotationsService.deleteActiveObjects();
  }

  showModal(): void {
    const modalData: TConfirmModalParams = {
      message: this.translationPipe.transform('annotation_info'),
      heading: this.translationPipe.transform('save_annotation'),
      redButton: true,
      confirmText: this.translationPipe.transform('confirm'),
    };

    this.modalService.setData(modalData);

    this.modalService.showModal(ConfirmModalComponent, {
      blur: false,
      callback: () => {
        this.save();
      },
    });
  }

  closeCanvas(): void {
    this.imageAnnotationsStateService.setMode(null);

    this.imageCanvasElement.nativeElement.width = 0;
    this.imageCanvasElement.nativeElement.height = 0;

    this.imageAnnotationsService.closeCanvas();
  }

  cancel(): void {
    this.addingAnnotation = false;

    this.imageAnnotationsService.resetState();
    this.closeCanvas();
    this.ppGetButtonsStatusChange.next(false);
  }

  setColorsVisible(colorsVisible: boolean): void {
    this.colorsVisible = colorsVisible;
  }

  private save(): void {
    this.ppAnnotationSaving.next(true);
    this.processing = true;

    this.updateCanvasPlaceholderSize();

    this.imageAnnotationsService
      .save(
        this.ppImageId,
        this.ppPointId,
        this.imageCanvasElement.nativeElement,
        this.ppImageName,
        this.ppImageCreatedOn,
      )
      .pipe(
        takeUntil(this.destroy$),
        tap(({ updatedImageId, base64String }) => {
          this.ppAnnotationAdded.next({
            index: this.ppOverlayIndex,
            id: updatedImageId,
            base64src: base64String,
          });

          this.ppRotationAngleChanged.next({
            angle: 0,
            index: this.ppOverlayIndex,
          });

          const promptText = this.translationPipe.transform('prompt_annotation_save');
          this.promptService.showSuccess(promptText);
          this.galleryOverlayService.navigateToAttachment(updatedImageId);
        }),
        catchError((error) => {
          logErrorInSentry(error);
          const promptText = this.translationPipe.transform('prompt_annotation_save_error');
          this.promptService.showError(promptText);

          return of();
        }),
        finalize(() => {
          this.closeCanvas();
          this.processing = false;
          this.addingAnnotation = false;
          this.ppAnnotationSaving.next(false);
          this.ppGetButtonsStatusChange.next(false);
          this.imageAnnotationsService.resetState();
        }),
      )
      .subscribe();
  }

  private updateCanvasPlaceholderSize(): void {
    const { width, height } = this.imageAnnotationsService.getCanvasDimensions();

    this.imageCanvasPlaceholderElement.nativeElement.width = width;
    this.imageCanvasPlaceholderElement.nativeElement.height = height;
  }
}
