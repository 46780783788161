import { RESIZE_HANDLE_WIDTH } from './timeline-variables';
import { adjustTimelineElementClass } from './adjust-timeline-element-class';

export function adjustTimelineElementRight({
  newWidth,
  element,
  changeAmount,
  width,
}: {
  newWidth: number;
  element: HTMLElement;
  changeAmount: number;
  width: number;
}): void {
  adjustTimelineElementClass(newWidth, element);

  if (!(changeAmount + width < RESIZE_HANDLE_WIDTH)) {
    element.style.width = `${newWidth}px`;
  } else if (changeAmount + width < RESIZE_HANDLE_WIDTH) {
    element.style.width = `${-newWidth}px`;
    element.style.marginLeft = `${changeAmount + width}px`;
  }
}
