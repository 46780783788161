import { ApiService } from '@core/http';
import { translate } from 'src/app/project/features/translate/translate';
import { EBasicField } from '../../../../../../shared/enums/basic-fields-enums';
import { TColumn } from '../../../columns/column.model';
import { createHeaderNameElement } from './create-header-name-element';

export function createHeaderCellTypeElement(_column: TColumn, apiService: ApiService): HTMLElement {
  switch (_column.name) {
    case EBasicField.PRIORITY:
    case EBasicField.STATUS:
    case EBasicField.SEQUENCE_NUMBER:
      return createHeaderNameElement(
        translate(_column.name.toLowerCase()),
        _column.width,
        _column,
        true,
        apiService,
      );
    case EBasicField.TITLE:
    case EBasicField.DESCRIPTION:
    case EBasicField.CREATED:
    case EBasicField.UPDATED:
    case EBasicField.ASSIGNEES:
    case EBasicField.TAGS:
      return createHeaderNameElement(
        translate(_column.name.toLowerCase()),
        _column.width,
        _column,
        false,
        apiService,
      );
    case EBasicField.CREATED_BY:
      return createHeaderNameElement(
        translate('createdBy'),
        _column.width,
        _column,
        false,
        apiService,
      );
    default:
      return createHeaderNameElement(_column.name, _column.width, _column, false, apiService);
  }
}
