import { Component, OnDestroy, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Observable, Subject, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { AccountSharesService } from '../../../share/account-shares.service';
import { TShare } from '../../../share/share.model';
import { TAccountUser } from '../../../users/account.user.model';
import { WorkspaceService } from '../../../workspace/workspace.service';
import { AccountService } from '../../account-service/account.service';
import { TAccount } from '../../account.model';
import { PrepareShareService } from '../account-user-modal/account-user-modal-shares-list/account-user-modal-shares-list-share/prepare-share.service';
import { EditAccountUserModalDataService } from '../account-user-modal/edit-account-user-modal-data.service';
import { EEditAccountUserStep } from '../account-user-modal/edit-account-user-modal.consts';
import { EditAccountUserModalService } from '../account-user-modal/edit-account-user-modal.service';
import { AddUsersIssuesService } from '../add-users-modal/add-users-issues.service';

@Component({
  selector: 'pp-downgrade-account-user-modal',
  templateUrl: './downgrade-account-user-modal.component.html',
  styleUrls: ['./downgrade-account-user-modal.component.scss'],
})
export class DowngradeAccountUserModalComponent implements OnDestroy, OnInit {
  private readonly destroy$ = new Subject<void>();
  processing: boolean;
  savingShare: boolean;
  account: TAccount;
  currentStep: EEditAccountUserStep = EEditAccountUserStep.SELECT_SITES;
  EEditAccountUserStep = EEditAccountUserStep;
  customFieldsWorkspaceId: string = null;
  showingError: boolean;
  customFieldsWorkspaceName: string;
  nextStepButtonText: string;
  isNextStepButtonDisabled: boolean;
  // TODO: shareBackup should be private, refactor tests
  shareBackup: TShare;
  private accountId: string;

  constructor(
    private promptService: PromptService,
    private modalService: ModalService,
    private translationPipe: TranslationPipe,
    private accountService: AccountService,
    private editAccountUserModalService: EditAccountUserModalService,
    private accountSharesService: AccountSharesService,
    private editAccountUserModalDataService: EditAccountUserModalDataService,
    private prepareShareService: PrepareShareService,
    private addUsersIssuesService: AddUsersIssuesService,
    private workspaceService: WorkspaceService,
  ) {}

  ngOnInit(): void {
    this.setAccountId();
    this.fetchAccountShares();
    this.setNextStepButtonText();
    this.addUsersIssuesService.clearData();
    this.setIsNextStepButtonDisabled();
  }

  ngOnDestroy(): void {
    this.addUsersIssuesService.clearData();
    this.destroy$.next();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  nextStep(): void {
    this.showingError = false;
    switch (this.currentStep) {
      case EEditAccountUserStep.SELECT_SITES:
        this.goToSharesListStepFromSelectSitesStep();
        break;
      case EEditAccountUserStep.SHARES_LIST:
        this.savingShare = true;
        this.saveShares();
        break;
      case EEditAccountUserStep.CUSTOM_FIELDS_EDIT:
        this.goToSharesListStepFromCustomFieldsEditStep(false);
        break;
    }
    this.setNextStepButtonText();
  }

  previousStep(): void {
    this.showingError = false;
    switch (this.currentStep) {
      case EEditAccountUserStep.SHARES_LIST:
        this.goToSelectSitesStepFromSharesListStep();
        break;
      case EEditAccountUserStep.CUSTOM_FIELDS_EDIT:
        this.goToSharesListStepFromCustomFieldsEditStep(true);
        break;
      case EEditAccountUserStep.SELECT_SITES:
        this.hideModal();
        break;
    }
    this.setNextStepButtonText();
  }

  goToCustomFieldsEditStep(workspaceId: string): void {
    this.setCurrentStep(EEditAccountUserStep.CUSTOM_FIELDS_EDIT);
    this.setCustomFieldsWorkspace(workspaceId);
    this.setShareBackup();
    this.setIsNextStepButtonDisabled();
    this.setNextStepButtonText();
  }

  setIsNextStepButtonDisabled(): void {
    const selectedWorkspaces = this.editAccountUserModalDataService.getSelectedWorkspaces();

    this.isNextStepButtonDisabled = selectedWorkspaces.length === 0;
  }

  private setCurrentStep(step: EEditAccountUserStep): void {
    this.currentStep = step;
  }

  private setShareBackup(): void {
    this.shareBackup = cloneDeep(
      this.prepareShareService.prepareShare(this.customFieldsWorkspaceId),
    );
  }

  private goToSharesListStepFromSelectSitesStep(): void {
    this.setCurrentStep(EEditAccountUserStep.SHARES_LIST);
    this.setIsNextStepButtonDisabled();
  }

  private goToSharesListStepFromCustomFieldsEditStep(canceledStep: boolean): void {
    if (canceledStep) {
      this.editAccountUserModalDataService.setShare(this.customFieldsWorkspaceId, this.shareBackup);
    }
    this.setCurrentStep(EEditAccountUserStep.SHARES_LIST);
    this.setCustomFieldsWorkspace(null);
    this.addUsersIssuesService.clearData();
    this.setIsNextStepButtonDisabled();
  }

  private goToSelectSitesStepFromSharesListStep(): void {
    this.setCurrentStep(EEditAccountUserStep.SELECT_SITES);
    this.setIsNextStepButtonDisabled();
    this.addUsersIssuesService.clearData();
  }

  private saveShares(isFromChangeLimitModal: boolean = false): Promise<boolean> {
    this.showingError = false;

    return new Promise((resolve) => {
      this.accountSharesService
        .fetchAccountShares(this.accountId)
        .pipe(
          tap((accountShares) => {
            this.editAccountUserModalService
              .downgradeAccountAdmin(accountShares)
              .pipe(
                tap(() => {
                  this.handleAddUsersSuccess(isFromChangeLimitModal);
                  resolve(true);
                }),
                catchError((error) => this.handleAddUsersError(error, accountShares)),
              )
              .subscribe();
          }),
        )
        .subscribe();
    });
  }

  private handleAddUsersError(error: Response, accountShares: TAccountUser[]): Observable<null> {
    this.savingShare = false;
    if (error.status === EStatusCode.BAD_REQUEST) {
      this.handleBadRequestError(error);
    } else if (error.status === EStatusCode.UPGRADE_REQUIRED) {
      this.handleUpgradeRequiredError(accountShares);
    } else {
      this.handleUnknownError();
    }
    return of(null);
  }

  private handleBadRequestError(error: Response): void {
    this.showingError = true;
    this.addUsersIssuesService
      .handleCreateAccountUserError(error)
      .pipe(
        tap(() => {
          this.setIsNextStepButtonDisabled();
        }),
      )
      .subscribe();
  }

  private handleUpgradeRequiredError(accountShares: TAccountUser[]): void {
    const sharesToDelete: string[] = this.getDeletedSharesList(accountShares);

    this.editAccountUserModalService.showEditUserLimitModal({
      accountId: this.accountId,
      callback: (cancelled) => this.handleUpgradeRequiredErrorCallback(cancelled),
      editedShares: this.editAccountUserModalDataService.getSharesMap(),
      deletedShares: sharesToDelete,
    });
  }

  private handleUnknownError(): void {
    const promptText = this.translationPipe.transform('prompt_share_error');
    this.promptService.showError(promptText);
  }

  private handleAddUsersSuccess(isFromChangeLimitsModal: boolean): void {
    this.accountSharesService.resetAccountShares(this.accountId).subscribe();

    const prompt = this.translationPipe.transform('prompt_user_shares_created');
    this.promptService.showSuccess(prompt);
    if (!isFromChangeLimitsModal) {
      this.modalService.hideModal(false);
    }
  }

  private fetchAccountShares(): void {
    this.processing = true;
    this.account = this.accountService.getAccount(this.accountId);
    this.accountSharesService
      .fetchAccountShares(this.accountId)
      .pipe(
        tap(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  private handleUpgradeRequiredErrorCallback(cancelled: boolean): Promise<boolean> {
    if (!cancelled) {
      return this.saveShares(true);
    }
    return Promise.resolve(true);
  }

  private setAccountId(): void {
    this.accountId = this.modalService.getModal().data.accountId;
  }

  private setNextStepButtonText(): void {
    this.nextStepButtonText = this.translationPipe.transform(
      this.currentStep === EEditAccountUserStep.SELECT_SITES
        ? 'next'
        : this.currentStep === EEditAccountUserStep.SHARES_LIST
        ? 'update_user'
        : 'save_site_permissions',
    );
  }

  private setCustomFieldsWorkspace(workspaceId: string) {
    this.customFieldsWorkspaceId = workspaceId;
    this.customFieldsWorkspaceName = workspaceId
      ? this.workspaceService.getWorkspace(this.customFieldsWorkspaceId).siteName
      : null;
  }

  private getDeletedSharesList(accountShares: TAccountUser[]): string[] {
    const newShares = this.editAccountUserModalDataService.getSharesMap();
    const emails = this.editAccountUserModalDataService.getEmailList();

    const accountAdminShares = accountShares.find(
      (accountShare) => accountShare.email === emails[0],
    );

    const sharesToDelete: string[] = [];

    accountAdminShares.shares.forEach((share) => {
      if (!newShares[share.workspaceId]) {
        sharesToDelete.push(share.shareId);
      }
    });
    return sharesToDelete;
  }
}
