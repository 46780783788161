import * as ExcelJS from 'exceljs';
import { FILLED_TIMELINE_ELEMENT_KEY } from '../../site/site-timeline/site-timeline-utils/timeframes-enums';
import { basicColumns, timelineStartRow } from '../timeline-export.const';

export function styleTimeline(worksheet: ExcelJS.Worksheet): void {
  const rowCount = worksheet.rowCount;
  const columnCount = worksheet.columnCount;

  for (let rowIndex = timelineStartRow; rowIndex < rowCount; rowIndex++) {
    const row = worksheet.getRow(rowIndex + 1);

    for (let columnIndex = basicColumns.length + 1; columnIndex < columnCount; columnIndex++) {
      const cell = row.getCell(columnIndex + 1);

      if (cell.text === FILLED_TIMELINE_ELEMENT_KEY) {
        applyFilledStyle(cell);
      } else if (columnIndex % 2) {
        applyOddColumnStyle(cell);
      }

      applyBorderStyle(cell);
    }
  }
}
function applyBorderStyle(cell: ExcelJS.Cell): void {
  cell.border = {
    top: {
      style: 'thin',
      color: { argb: 'FFFFFFFF' },
    },
    bottom: {
      style: 'thin',
      color: { argb: 'FFFFFFFF' },
    },
  };
}

function applyOddColumnStyle(cell: ExcelJS.Cell): void {
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'EBEFF1',
    },
  };
}

function applyFilledStyle(cell: ExcelJS.Cell): void {
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: '6E7881',
    },
  };

  cell.value = '';
}
