import Select from 'ol/interaction/Select';

import { Injectable } from '@angular/core';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { PointsService } from 'src/app/project/modules/points/points.service';
import { PlanPinsService } from '../plan-pins.service';
import { FeatureModifyService } from './feature-modify.service';
import { PlanPointStylesService } from './plan-point-styles/plan-point-styles.service';
import { PlanPointVariablesService } from './plan-point-variables.service';
import { PlanPolygonService } from './plan-polygon.service';
import { EPlanPointMode } from '../../../shared/enums/plan-point-mode.enum';
import Modify from '../Modify';
import { Polygon } from 'ol/geom';
import { fixModifyInteraction } from './modify-interaction-fix';
import { TPlanFeature } from '../plan-feature.model';

@Injectable({
  providedIn: 'root',
})
export class PolygonChangeService {
  constructor(
    private planPinsService: PlanPinsService,
    private pointsService: PointsService,
    private activeService: ActiveService,
    private planPointStylesService: PlanPointStylesService,
    private planPointVariablesService: PlanPointVariablesService,
    private featureModifyService: FeatureModifyService,
    private planPolygonService: PlanPolygonService,
  ) {}

  polygonChangeInteraction(feature: TPlanFeature = null): void {
    const _id = this.activeService.getActivePointId();
    const point = this.pointsService.findPoint(_id);
    const plan = this.planPointVariablesService.getPlan();
    const mode = this.planPointVariablesService.getMode();
    let modifyInteraction = this.planPointVariablesService.getModifyInteraction();
    let selectInteraction = this.planPointVariablesService.getSelectInteraction();
    const polygonStopModifyListenerRef =
      this.planPointVariablesService.getPolygonStopModifyListenerRef();
    const polygonStartModifyListenerRef =
      this.planPointVariablesService.getPolygonStartModifyListenerRef();

    let index: number;

    if (feature) {
      index = this.planPolygonService.getPolygonIndex(
        (feature.getGeometry() as Polygon).getCoordinates()[0],
      );
    }

    if (mode === EPlanPointMode.POLYGON) {
      const drawPolygon = this.planPointVariablesService.getDrawPolygon();

      plan.instance.point.removeInteraction(drawPolygon);
    }

    if (selectInteraction) {
      plan.instance.point.removeInteraction(selectInteraction);
    }

    if (modifyInteraction) {
      plan.instance.point.removeInteraction(modifyInteraction);
    }

    if (modifyInteraction) {
      this.featureModifyService.removeModifyInteraction();
    }

    this.planPointVariablesService.setSelectInteraction(
      new Select({
        style: this.planPointStylesService.createNormalStyle(point.priority),
      }),
    );

    selectInteraction = this.planPointVariablesService.getSelectInteraction();

    if (feature) {
      selectInteraction.getFeatures().push(feature);
    }

    plan.instance.point.addInteraction(selectInteraction);

    this.planPointVariablesService.setModifyInteraction(
      new Modify({
        features: selectInteraction.getFeatures(),
        style: this.planPointStylesService.createNormalStyle(point.priority),
      }),
    );

    if (feature) {
      this.planPinsService.updatePolygon(plan.point, feature, index);
    }

    modifyInteraction = this.planPointVariablesService.getModifyInteraction();
    modifyInteraction = fixModifyInteraction(modifyInteraction);

    modifyInteraction.on('modifyend', polygonStopModifyListenerRef);
    modifyInteraction.on('modifystart', polygonStartModifyListenerRef);

    plan.instance.point.addInteraction(modifyInteraction);
  }
}
