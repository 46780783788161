import { Injectable } from '@angular/core';
import { API_activities_point } from '@core/api/paths';
import { TEntityWithPagination } from '@core/helpers';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { TActivityResponse } from '../../../view-models/activity-response.model';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesApiProviderService {
  constructor(private apiService: ApiService) {}

  fetchPointActivities(
    _id: string,
    params: string,
  ): Observable<TEntityWithPagination<TActivityResponse[]>> {
    const url = API_activities_point(_id, params);

    return this.apiService.getWithPagination<TActivityResponse[]>(url);
  }
}
