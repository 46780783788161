import { Component } from '@angular/core';

@Component({
  selector: 'pp-notification-description-mention',
  templateUrl: './notification-description-mention.component.html',
  styleUrls: ['../notification.component.scss'],
})
export class NotificationDescriptionMentionComponent {
  constructor() {}
}
