import { cloneDeep } from 'lodash';

import { Fill, Stroke, Style, Circle, Icon } from 'ol/style.js';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import clone from 'lodash/clone';
import GeoJSON from 'ol/format/GeoJSON';

import { Inject, Injectable, NgZone } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { TPoint, TPoints, TPointsByWorkspace } from '../points/points.model';
import { Observable, Subject, timer } from 'rxjs';

import { PlanDataService, TPlanData } from './plan-data.service';
import { ActiveService } from '../../services/active/active.service';
import { PointsService } from '../points/points.service';
import { DeviceService } from '@core/services';

import { GET_ACTIVE_PLAN } from './plan.store';
import { drawTriangle, getPolygonHighlight, roundRect } from './plan-drawing-features';
import { EPriority } from '../points/priorities';
import { EPlanPointMode } from '../../shared/enums/plan-point-mode.enum';
import { tap } from 'rxjs/operators';
import { EStore } from '../../shared/enums/store.enum';
import { NEW_POINT_ID } from '../../shared/constants/point.const';
import { EPlanModule } from './plan-module.enum';
import { TFeatureGeometry, TPlanFeature, TPlanPoint } from './plan-feature.model';
import { Geometry, Polygon } from 'ol/geom';
import { EPlanFeatureType } from '../../shared/enums/plan-feature-type.enum';
import { TPin } from '@project/view-models';
import { DOCUMENT } from '@angular/common';
import { areaToPolygon } from '../points/point-generate/area-to-polygon';

@Injectable({
  providedIn: 'root',
})
export class PlanPinsService {
  private plan: TPlanData = this.planDataService.getPlan();
  private points$: Observable<TPointsByWorkspace>;
  private isBrowserSafari = this.deviceService.isBrowserSafari();
  private source: VectorSource;
  private readonly _pinsUpdated$ = new Subject<void>();
  readonly pinsUpdated$ = this._pinsUpdated$.asObservable();

  private blockAddingPins = false;
  private blockAddingPinsTimerMs = 500;

  private activePlan = GET_ACTIVE_PLAN();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<{ points: TPointsByWorkspace }>,
    private planDataService: PlanDataService,
    private activeService: ActiveService,
    private pointsService: PointsService,
    private deviceService: DeviceService,
    private ngZone: NgZone,
  ) {
    if (this.isBrowserSafari) {
      this.points$ = this.store.pipe(select(EStore.POINTS));

      this.points$.subscribe(() => {
        if (this.plan.instance && this.plan.instance.point) {
          const point = this.plan.instance.point;

          if (point._id && point._id !== NEW_POINT_ID) {
            this.addPointMarker(point._id, EPlanModule.POINT, point.workspaceRef.id);
          }
        }
      });
    }
  }

  enlargePin(id: number): void {
    this.restorePin();

    this.plan.site.pins.forEach((feature) => {
      if (feature.point.sequenceNumber === id) {
        this.plan.enlargedPins.push(feature);

        feature.setStyle(
          this.createSelectedStyles(feature.point.sequenceNumber, feature.point.priority),
        );
      }
    });

    this.plan.site.polygons.forEach((feature) => {
      if (feature.point.sequenceNumber === id) {
        this.plan.enlargedPins.push(feature);

        feature.setStyle(
          this.createSelectedStyles(feature.point.sequenceNumber, feature.point.priority),
        );
      }
    });
  }

  restorePin(): void {
    if (this.plan.enlargedPins) {
      this.plan.enlargedPins.forEach((pin) => {
        pin.setStyle(this.createNormalStyle(pin.point.priority));
      });

      this.plan.enlargedPins = [];
    }
  }

  createNormalStyle(priority: EPriority): Style[] {
    let pinColor = '#FFFFFF';
    let fillColor = '#FFFFFF';

    if (priority === EPriority.LOW) {
      pinColor = 'rgb(77, 160, 229)';
      fillColor = 'rgba(77, 160, 229, 0.2)';
    }

    if (priority === EPriority.MEDIUM) {
      pinColor = 'rgb(255, 206, 41)';
      fillColor = 'rgba(255, 206, 41, 0.2)';
    }

    if (priority === EPriority.HIGH) {
      pinColor = 'rgb(229, 79, 80)';
      fillColor = 'rgba(229, 79, 80, 0.2)';
    }

    const styles = [
      new Style({
        stroke: new Stroke({
          color: pinColor,
          width: 3,
        }),
        fill: new Fill({
          color: fillColor,
        }),
      }),
      new Style({
        image: new Circle({
          radius: 7,
          fill: new Fill({
            color: pinColor,
          }),
          stroke: new Stroke({
            width: 2,
            color: 'white',
          }),
        }),
      }),
    ];

    return styles;
  }

  createPolygonStyle(priority: EPriority): Style[] {
    let pinColor = '#FFFFFF';
    let fillColor = '#FFFFFF';

    if (priority === EPriority.LOW) {
      pinColor = 'rgb(77, 160, 229)';
      fillColor = 'rgba(77, 160, 229, 0.2)';
    }

    if (priority === EPriority.MEDIUM) {
      pinColor = 'rgb(255, 206, 41)';
      fillColor = 'rgba(255, 206, 41, 0.2)';
    }

    if (priority === EPriority.HIGH) {
      pinColor = 'rgb(229, 79, 80)';
      fillColor = 'rgba(229, 79, 80, 0.2)';
    }

    return [
      new Style({
        image: new Circle({
          radius: 7,
          fill: new Fill({
            color: pinColor,
          }),
          stroke: new Stroke({
            width: 2,
            color: 'white',
          }),
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: pinColor,
          width: 3,
        }),
        fill: new Fill({
          color: fillColor,
        }),
      }),
    ];
  }

  createSelectedStyles(id: string | number, priority: EPriority): Style[] {
    const pinActiveSize = 36 + id.toString().length * 8;
    const pinActiveSizeY = 40;

    const canvas = this.document.createElement('canvas');
    canvas.width = pinActiveSize;
    canvas.height = pinActiveSizeY;
    const context = canvas.getContext('2d');

    const pinWidth = 12 + id.toString().length * 8;
    let pinColor = '#FFFFFF';
    let fillColor = '#FFFFFF';

    if (priority === EPriority.LOW) {
      pinColor = 'rgb(77, 160, 229)';
      fillColor = 'rgba(77, 160, 229, 0.4)';
    }

    if (priority === EPriority.MEDIUM) {
      pinColor = 'rgb(255, 206, 41)';
      fillColor = 'rgba(255, 206, 41, 0.4)';
    }

    if (priority === EPriority.HIGH) {
      pinColor = 'rgb(229, 79, 80)';
      fillColor = 'rgba(229, 79, 80, 0.4)';
    }

    context.fillStyle = pinColor;
    context.strokeStyle = '#ffffff';
    context.lineWidth = 2;
    roundRect(context, (pinActiveSize - pinWidth) / 2, 13, pinWidth, 20, 5, true, true);

    context.fillStyle = '#ffffff';
    drawTriangle(context, pinActiveSize, 10, 6);

    context.fillStyle = pinColor;
    drawTriangle(context, pinActiveSize, 6, 3);

    context.fillStyle = 'white';
    context.font = 'bold 1.4rem "Source Sans Pro"';
    context.textAlign = 'center';

    context.fillText(id.toString(), pinActiveSize / 2, pinActiveSizeY / 2 + 8);

    const style = [
      new Style({
        // pin highlight
        image: new Icon({
          src: canvas.toDataURL(),
          scale: 1,
          anchor: [0.48, 0.7],
          opacity: 1,
          imgSize: [pinActiveSize, pinActiveSize],
        }),
        zIndex: Infinity,
      }),
      new Style({
        // pin
        image: new Circle({
          radius: 8,
          fill: new Fill({
            color: [255, 255, 255, 0.01],
          }),
        }),
        zIndex: Infinity,
      }),
      new Style({
        // polygon
        stroke: new Stroke({
          color: pinColor,
          width: 3,
        }),
        fill: new Fill({
          color: fillColor,
        }),
      }),
      new Style({
        // ID number in polygon
        image: new Icon({
          src: canvas.toDataURL(),
          scale: 1,
          anchor: [0.48, 1.0],
          opacity: 1,
          imgSize: [pinActiveSize, pinActiveSize],
        }),
        zIndex: Infinity,
        geometry: (feature: Feature) => getPolygonHighlight(feature),
      }),
    ];

    return style;
  }

  createPointMarker(point: TPlanPoint, module: EPlanModule, edit: boolean = false): void {
    if (point.pins) {
      point.pins.forEach((pin) => {
        const geometry = new Point([Math.abs(pin.x), -Math.abs(pin.y)]);

        const feature: TPlanFeature = new Feature({ geometry });

        feature.point = point;
        feature.setStyle(this.createNormalStyle(point.priority));
        feature.visible = true;

        if (!this.plan.point.pins) {
          this.plan.point.pins = [];
        }

        this.plan.point.pins.push(feature as unknown as TPin);
      });

      this._pinsUpdated$.next();
    }

    if (point.polygons) {
      point.polygons.forEach((polygon, index) => {
        if (polygon !== null) {
          this.createPointPolygon(
            {
              polygon: polygon,
              priority: point.priority,
              sequenceNumber: point.sequenceNumber,
              _id: point._id,
              workspaceRef: point.workspaceRef,
            },
            module,
            index,
          );
        }
      });

      this._pinsUpdated$.next();
    }

    if (module === EPlanModule.POINT && this.plan.instance.point) {
      if (edit) {
        return;
      }

      let newExtent;

      if (this.plan.point.polygons?.length > 0) {
        newExtent = clone(this.plan.point.polygons[0].getGeometry().getExtent());

        this.plan.point.polygons.forEach((polygon) => {
          const polygonExtent = polygon.getGeometry().getExtent();

          newExtent[0] = Math.min(newExtent[0], polygonExtent[0]);
          newExtent[1] = Math.min(newExtent[1], polygonExtent[1]);
          newExtent[2] = Math.max(newExtent[2], polygonExtent[2]);
          newExtent[3] = Math.max(newExtent[3], polygonExtent[3]);
        });
      }

      if (this.plan.point.pins?.length > 0) {
        if (!newExtent) {
          newExtent = (this.plan.point.pins[0] as unknown as Feature).getGeometry().getExtent();
        }

        this.plan.point.pins.forEach((pin) => {
          const pinExtent = (pin as unknown as Feature).getGeometry().getExtent();

          newExtent[0] = Math.min(newExtent[0], pinExtent[0]);
          newExtent[1] = Math.min(newExtent[1], pinExtent[1]);
          newExtent[2] = Math.max(newExtent[2], pinExtent[2]);
          newExtent[3] = Math.max(newExtent[3], pinExtent[3]);
        });
      }

      if (newExtent) {
        this.plan.instance.point.getView().fit(newExtent);
      }

      if (this.plan.instance.point.getView().getZoom() > 3) {
        this.plan.instance.point.getView().setZoom(3);
      }
    } else if (module === EPlanModule.SITE) {
      if (point.pin) {
        const geometry = new Point([Math.abs(point.pin.x), -Math.abs(point.pin.y)]);

        const feature = new Feature({ geometry }) as TPlanFeature;

        feature.point = point;
        feature.setStyle(this.createNormalStyle(point.priority));
        feature.visible = true;

        this.plan.site.pins.push(feature);
      }
    }
  }

  createPointPolygon(point: TPlanPoint, module: EPlanModule, index: number): void {
    if (point.polygon) {
      const geojsonObject = {
        type: 'FeatureCollection',
        crs: {
          type: 'name',
          properties: {
            name: 'EPSG:3857',
          },
        },
        features: [],
      };

      geojsonObject.features.push({
        type: 'Feature',
        geometry: {
          type: EPlanFeatureType.POLYGON,
          coordinates: [...areaToPolygon([point.polygon])[0]],
        },
      });

      const polygonFeatures = new GeoJSON().readFeatures(geojsonObject);
      const feature = polygonFeatures[0] as TPlanFeature;

      feature.point = point;
      feature.polygonIndex = index;
      feature.visible = true;

      if (module === EPlanModule.POINT && this.plan.instance.point) {
        const plan = GET_ACTIVE_PLAN();

        if (plan.active) {
          feature.setStyle(this.createPolygonStyle(point.priority));
        } else {
          feature.setStyle(this.createNormalStyle(point.priority));
        }

        this.plan.point.polygons.push(feature);
      } else if (module === EPlanModule.SITE) {
        feature.setStyle(this.createNormalStyle(point.priority));

        this.plan.site.polygons.push(feature);
      } else {
        feature.setStyle(this.createNormalStyle(point.priority));
      }
    }
  }

  addPointMarker(
    _id: string,
    module: EPlanModule,
    workspaceId?: string,
    edit: boolean = false,
  ): void {
    if (this.plan.instance[module] && this.plan[module].vector.layer) {
      this.plan.instance[module].removeLayer(this.plan[module].vector.layer);
    }

    let priority = EPriority.MEDIUM;
    let points: TPoint[];

    if (workspaceId) {
      points = this.pointsService.getPointsForWorkspace(workspaceId);
    } else {
      workspaceId = this.activeService.getActiveWorkspaceId();
      points = this.pointsService.getPoints();
    }
    let point: TPlanPoint = null;

    if (_id) {
      const pointToPin = points.find((_point) => _point._id === _id);

      if (pointToPin) {
        priority = pointToPin.priority;

        point = {
          pins: pointToPin.pins,
          polygons: pointToPin.polygons,
          priority,
          sequenceNumber: pointToPin.sequenceNumber,
          _id: pointToPin._id,
          workspaceRef: pointToPin.workspaceRef,
        };
      }
    }

    if (!point) {
      point = {
        pins: [],
        polygons: [],
        priority,
        sequenceNumber: null,
        _id: null,
        workspaceRef: {
          id: workspaceId,
        },
      };
    }

    this.createPointMarker(point, module, edit);

    this.addLayer(module, {
      priority,
    });
  }

  addPointMarkers(points: TPoint[], module: EPlanModule): void {
    if (this.plan.instance[module] && this.plan[module].vector.layer) {
      this.plan.instance[module].removeLayer(this.plan[module].vector.layer);
    }

    this.plan.site.pins = [];
    this.plan.site.polygons = [];

    points.forEach((point) => {
      if (point.polygons && point.polygons.length > 0) {
        point.polygons.forEach((polygon, index) => {
          if (polygon !== null) {
            this.createPointPolygon(
              {
                polygon: polygon,
                priority: point.priority,
                sequenceNumber: point.sequenceNumber,
                _id: point._id,
                workspaceRef: point.workspaceRef,
              },
              module,
              index,
            );
          }
        });
      }

      if (point.pins) {
        point.pins.forEach((pin) => {
          if (pin !== null) {
            this.createPointMarker(
              {
                pin: {
                  x: pin.x,
                  y: pin.y,
                },
                priority: point.priority,
                sequenceNumber: point.sequenceNumber,
                _id: point._id,
                workspaceRef: point.workspaceRef,
              },
              module,
            );
          }
        });
      }
    });

    this._pinsUpdated$.next();
    this.addLayer(module, {});
  }

  updatePointMarkers(module: EPlanModule): void {
    const visiblePoints = this.pointsService.getMapPoints();

    this.addPointMarkers(visiblePoints, module);
  }

  createVectorSource(features: Feature[]): VectorSource {
    return new VectorSource({ features });
  }

  createVectorLayer(features: Feature[]): VectorLayer<any> {
    this.source = this.createVectorSource(features);

    return new VectorLayer({
      source: this.source,
      updateWhileInteracting: true,
      updateWhileAnimating: true,
    });
  }

  getVectorSource(): VectorSource<Geometry> {
    return this.source;
  }

  addLayer(
    module: EPlanModule,
    {
      priority = null,
      mode = null,
    }: {
      priority?: EPriority;
      mode?: EPlanPointMode;
    },
  ): void {
    if (module === EPlanModule.POINT) {
      if (!this.plan.point.pins) {
        this.plan.point.pins = [];
      }
      if (!this.plan.point.polygons) {
        this.plan.point.polygons = [];
      }

      const polygonsGeo = this.createGeoJSON(this.plan.point.polygons);
      const polygonFeatures = new GeoJSON().readFeatures(polygonsGeo);

      if (priority) {
        polygonFeatures.forEach((polygon) => {
          if (this.activePlan.active) {
            polygon.setStyle(this.createPolygonStyle(priority));
          } else {
            polygon.setStyle(this.createNormalStyle(priority));
          }
        });
      }

      if (
        polygonFeatures.length > 0 ||
        mode === EPlanPointMode.POLYGON ||
        mode === EPlanPointMode.DRAW
      ) {
        this.plan[module].vector.layer = this.createVectorLayer([
          ...polygonFeatures,
          ...(this.plan.point.pins as unknown as Feature<Geometry>[]),
        ]);
      } else {
        this.plan[module].vector.layer = this.createVectorLayer([
          ...(this.plan.point.pins as unknown as Feature<Geometry>[]),
        ]);
      }
    } else {
      this.plan[module].vector.layer = this.createVectorLayer([
        ...this.plan.site.pins,
        ...this.plan.site.polygons,
      ]);
    }

    if (this.plan.instance[module]) {
      this.plan.instance[module].addLayer(this.plan[module].vector.layer);
    }
  }

  addExtraMarker(point: TPlanPoint, coordinates: TPin): TPlanFeature {
    const _id = this.activeService.getActivePointId();
    const pointData = this.pointsService.findPoint(_id);
    const module = EPlanModule.POINT;

    const geometry = new Point([Math.abs(coordinates[0]), -Math.abs(coordinates[1])]);

    const feature = new Feature({ geometry }) as TPlanFeature;

    feature.point = point;
    feature.visible = true;

    feature.setStyle(this.createNormalStyle(pointData.priority));

    if (!this.plan.point.pins) {
      this.plan.point.pins = [];
    }

    this.plan.point.pins.push(feature as unknown as TPin);
    this._pinsUpdated$.next();

    if (this.plan.instance[module] && this.plan[module].vector.layer) {
      this.plan.instance[module].removeLayer(this.plan[module].vector.layer);
    }

    this.addLayer(module, {
      priority: pointData.priority,
    });

    return feature;
  }

  addPolygon(point: TPlanPoint, feature: TPlanFeature): TPlanFeature {
    const _id = this.activeService.getActivePointId();
    const pointData = this.pointsService.findPoint(_id);
    const module = EPlanModule.POINT;

    feature.point = point;
    feature.visible = true;

    if (this.activePlan.active) {
      feature.setStyle(this.createPolygonStyle(pointData.priority));
    } else {
      feature.setStyle(this.createNormalStyle(pointData.priority));
    }

    if (!this.plan.point.polygons) {
      this.plan.point.polygons = [];
    }

    this.plan.point.polygons.push(feature);

    if (this.plan.instance[module] && this.plan[module].vector.layer) {
      this.plan.instance[module].removeLayer(this.plan[module].vector.layer);
    }

    this.addLayer(module, {
      priority: pointData.priority,
    });

    this._pinsUpdated$.next();
    return feature;
  }

  updatePolygon(point: TPlanPoint, feature: TPlanFeature, polygonIndex: number): TPlanFeature {
    if ((feature.getGeometry() as TFeatureGeometry).flatCoordinates.length > 2) {
      const _id = this.activeService.getActivePointId();
      const pointData = this.pointsService.findPoint(_id);
      const index = polygonIndex;
      const module = EPlanModule.POINT;

      feature.point = point;
      feature.visible = true;

      if (this.activePlan.active) {
        feature.setStyle(this.createPolygonStyle(pointData.priority));
      } else {
        feature.setStyle(this.createNormalStyle(pointData.priority));
      }

      if (index !== -1) {
        this.plan.point.polygons[index] = feature;
      }

      if (this.plan.instance[module] && this.plan[module].vector.layer) {
        this.plan.instance[module].removeLayer(this.plan[module].vector.layer);
      }

      this.addLayer(module, {
        priority: pointData.priority,
      });

      return feature;
    }
  }

  deleteFeature(feature: Feature): void {
    const index = this.plan.point.pins.indexOf(feature as unknown as TPin);

    const style = new Style({
      image: new Circle({
        radius: 0,
        scale: 0,
      }),
    });

    this.plan.point.pins.splice(index, 1);
    this._pinsUpdated$.next();

    feature.setStyle(style);
  }

  deleteVertice(index: number, polygonIndex: number = 0): void {
    const coordinates = this.plan.point.polygons[polygonIndex].getGeometry().getCoordinates()[0];
    const newCoordinates = [];

    coordinates.splice(index, 1);

    if (index === 0) {
      coordinates.splice(coordinates.length - 1, 1);

      coordinates.push(coordinates[0]);
    }

    if (index === coordinates.length) {
      coordinates.splice(0, 1);
      coordinates.push(coordinates[0]);
    }

    newCoordinates.push(coordinates);

    this.plan.point.polygons[polygonIndex].getGeometry().setCoordinates(newCoordinates);

    const feature = cloneDeep(this.plan.point.polygons[polygonIndex]);

    this.deletePolygon(polygonIndex);
    this.addPolygon(this.plan.point, feature);
    // TODO Sometimes doesn't work perfectly

    this.resetLayer(EPlanModule.POINT, EPlanPointMode.POLYGON);
  }

  deletePolygon(polygonIndex: number): void {
    this.plan.point.polygons = this.plan.point.polygons.filter(
      (_polygon, _index) => _index !== polygonIndex,
    );

    const _id = this.activeService.getActivePointId();
    const pointData = this.pointsService.findPoint(_id);

    const module = EPlanModule.POINT;

    if (this.plan.instance[module] && this.plan[module].vector.layer) {
      this.plan.instance[module].removeLayer(this.plan[module].vector.layer);
    }

    this.addLayer(module, {
      priority: pointData.priority,
    });

    this._pinsUpdated$.next();
  }

  createGeoJSON(features: TPlanFeature[]): {
    type: string;
    crs: { type: string; properties: { name: string } };
    features: TPlanFeature[];
  } {
    const geojsonObject = {
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: {
          name: 'EPSG:3857',
        },
      },
      features: [],
    };

    features.forEach((feature) => {
      const geometry = (feature.getGeometry() as Polygon).getCoordinates();
      const coordinates = [...geometry][0];

      geojsonObject.features.push({
        type: 'Feature',
        geometry: {
          type: EPlanFeatureType.POLYGON,
          coordinates: [coordinates],
        },
      });
    });

    return geojsonObject;
  }

  resetLayer(module: EPlanModule, mode: EPlanPointMode): void {
    const _id = this.activeService.getActivePointId();
    const pointData = this.pointsService.findPoint(_id);

    if (mode === EPlanPointMode.POINT) {
      this.blockAddingPins = true;

      this.ngZone.runOutsideAngular(() => {
        timer(this.blockAddingPinsTimerMs)
          .pipe(
            tap(() => {
              this.blockAddingPins = false;
            }),
          )
          .subscribe();
      });
    }

    if (this.plan.instance[module] && this.plan[module].vector.layer) {
      this.plan.instance[module].removeLayer(this.plan[module].vector.layer);
    }

    this.addLayer(module, {
      priority: pointData.priority,
      mode,
    });
  }

  getBlockAddingPins(): boolean {
    return this.blockAddingPins;
  }
}
