<li
  ppTooltip
  data-m-target="Notifications button"
  ppTitle="Notifications"
  class="mainNav__element mainNav__element--notifications"
  [class.mainNav__element--disabled]="
    !hasWorkspaces || !notificationsAvailable.fetched || ppDisabled
  "
  [ppOptions]="{ placement: 'right' }"
>
  <a
    id="notificationsMenuItem"
    class="mainNav__element--href notifications__button mainNav__element--with-image"
    [class.mainNav__element--active]="sidePanel.notificationsExpanded"
    [class.mainNav__element--disabled]="
      !hasWorkspaces || !notificationsAvailable.fetched || ppDisabled
    "
    (click)="toggleNotifications()"
  >
    <img
      [class.sidePanel__bell-icon]="!(ppIsMobile && !sidePanel.rightPanelExpanded)"
      [class.sidePanel__bell-icon--mobile]="ppIsMobile"
      [src]="
        ppUser.unreadNotifications && !sidePanel.notificationsExpanded
          ? EIconPath.NOTIFICATION_BELL_NEW_NOTIFICATIONS
          : EIconPath.NOTIFICATION_BELL
      "
    />

    <h6
      class="mainNav__name mainNav__name--notifications"
      [class.mainNav__name--show]="sidePanel.expanded"
      *ngIf="ppIsMobile && !sidePanel.rightPanelExpanded"
    >
      {{ 'notifications' | ppTranslate }}
    </h6>
  </a>
</li>
