import { Injectable, OnDestroy } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { TPoint, TPoints } from 'src/app/project/modules/points/points.model';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { TAllFilters, TFilters } from '../site-filter.model';

import {
  checkAccountForKeyword,
  checkCreatedByForKeyword,
  checkCreatedKeyword,
  checkDescriptionKeyword,
  checkSequenceNumberKeyword,
  checkTagsForKeyword,
  checkTitleKeyword,
  checkUpdatedKeyword,
  checkWorkspaceForKeyword,
} from '../point-search';

import { EWorkspaces } from 'src/app/project/modules/workspace/workspaces.enum';
import { EStore } from '../../../shared/enums/store.enum';
import { checkCustomWorkspaceId } from '../../workspace/workspace';
import {
  checkAssigneesForKeyword,
  checkCustomFieldsForKeyword,
  checkOverviewCustomFieldsForKeyword,
  checkPointsWithKeyword,
} from './filter-keyword.utils';

@Injectable({
  providedIn: 'root',
})
export class FilterKeywordService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private filters: TFilters;

  private siteFilters$ = new Observable<TAllFilters>();
  private workspaces$ = new Observable<TWorkspacesById>();
  private workspaceId: string;
  private allFilters: TAllFilters;

  constructor(
    private store: Store<{
      points: TPoints;
      siteFilter: TAllFilters;
      workspaces: TWorkspacesById;
    }>,
    private activeService: ActiveService,
    private customFieldsService: CustomFieldsService,
    private usersService: UsersService,
  ) {
    this.siteFilters$ = this.store.pipe(select(EStore.SITE_FILTER));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.siteFilters$.pipe(takeUntil(this.destroy$)).subscribe((allFilters) => {
      this.allFilters = allFilters;
      this.filters = this.allFilters?.[this.workspaceId];
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.workspaceId = this.activeService.getActiveWorkspaceId();

      if (!this.workspaceId) {
        this.workspaceId = checkCustomWorkspaceId();
      }

      this.filters = this.allFilters?.[this.workspaceId];
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  checkKeyword(point: TPoint, filters: TFilters): boolean {
    this.filters = filters;
    const allUsers = this.usersService.getUsers();
    const customFields = this.customFieldsService.getCustomFields();

    if (this.filters.keyword) {
      if (
        (this.workspaceId === EWorkspaces.OVERVIEW || this.workspaceId === EWorkspaces.TIMELINE) &&
        (checkSequenceNumberKeyword(point, this.filters.keyword) ||
          checkTitleKeyword(point, this.filters.keyword) ||
          checkCreatedByForKeyword(point, this.filters.keyword, allUsers) ||
          checkAssigneesForKeyword(point, this.filters.keyword, allUsers) ||
          checkDescriptionKeyword(point, this.filters.keyword) ||
          checkTagsForKeyword(point, this.filters.keyword) ||
          checkOverviewCustomFieldsForKeyword(point, this.filters.keyword, customFields) ||
          checkAccountForKeyword(point, this.filters.keyword) ||
          checkWorkspaceForKeyword(point, this.filters.keyword) ||
          checkCreatedKeyword(point, this.filters.keyword) ||
          checkUpdatedKeyword(point, this.filters.keyword) ||
          checkPointsWithKeyword(point, this.filters))
      ) {
        return true;
      } else if (
        checkSequenceNumberKeyword(point, this.filters.keyword) ||
        checkTitleKeyword(point, this.filters.keyword) ||
        checkCreatedByForKeyword(point, this.filters.keyword, allUsers) ||
        checkAssigneesForKeyword(point, this.filters.keyword, allUsers) ||
        checkDescriptionKeyword(point, this.filters.keyword) ||
        checkTagsForKeyword(point, this.filters.keyword) ||
        checkCustomFieldsForKeyword(point, customFields, this.filters) ||
        checkCreatedKeyword(point, this.filters.keyword) ||
        checkUpdatedKeyword(point, this.filters.keyword) ||
        checkPointsWithKeyword(point, this.filters)
      ) {
        return true;
      }

      return false;
    } else {
      return true;
    }
  }
}
