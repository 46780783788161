import { createElement } from 'src/app/core/helpers/dom';
import { addCommonStyles } from './dashboard-export.utils';

export function createAccountElement(accountName, workspaceName, scale) {
  const accountElement = createElement('div');

  accountElement.style.fontSize = `${56 * scale}px`;
  accountElement.style.lineHeight = `${70 * scale}px`;
  accountElement.style.top = `${48 * scale}px`;
  accountElement.style.whiteSpace = 'nowrap';
  accountElement.style.maxWidth = `${640 * scale}px`;
  accountElement.style.overflow = 'hidden';
  accountElement.style.textOverflow = 'ellipsis';
  accountElement.style.fontFamily = 'Source Sans Pro, sans-serif';

  addCommonStyles(accountElement);

  accountElement.innerHTML = `${accountName} <br> ${workspaceName}`;

  return accountElement;
}
