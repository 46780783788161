import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pp-new-account-admin-modal-confirmation-block',
  templateUrl: './new-account-admin-modal-confirmation-block.component.html',
  styleUrls: ['./new-account-admin-modal-confirmation-block.component.scss'],
})
export class NewAccountAdminModalConfirmationBlockComponent {
  @Output() ppCancel = new EventEmitter<void>();
  @Output() ppConfirm = new EventEmitter<void>();

  @Input() ppProcessing: boolean = false;

  cancel(): void {
    this.ppCancel.emit();
  }

  confirm(): void {
    this.ppConfirm.emit();
  }
}
