import { TAllUsers } from '@project/view-models';

let users: TAllUsers = {};

// Get

export const GET_USERS = (): TAllUsers => users;

// Set

export const SET_USERS = (_users: TAllUsers): void => {
  users = _users;
};

// Modify

// ---

// Clear

export const CLEAR_USERS = (): void => {
  users = {};
};
