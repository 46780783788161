import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
@Component({
  selector: 'pp-button-modal-confirm',
  templateUrl: './button-modal-confirm.component.html',
  styleUrls: ['./button-modal-confirm.component.scss'],
})
export class ButtonModalConfirmComponent implements AfterViewInit {
  @ViewChild('modalConfirmButton') confirmButton: ElementRef;
  @Input() ppDisabled = false;
  @Input() ppProcess = false;
  @Input() ppDataTarget: string;
  @Input() ppError: boolean;

  @Output() ppAction = new EventEmitter();

  ngAfterViewInit(): void {
    if (this.ppDataTarget) {
      this.confirmButton.nativeElement.setAttribute('data-m-target', this.ppDataTarget);
    }
  }

  action(): void {
    if (!this.ppProcess && this.ppAction && !this.ppDisabled) {
      this.ppAction.emit();
    }
  }
}
