<pp-modal>
  <ng-container heading>
    {{ 'change_subscription' | ppTranslate }}
  </ng-container>

  <ng-container body>
    <h5 class="upgradeToggle">
      {{ 'upgrade' | ppTranslate }}
      <pp-toggle
        [ppChecked]="subscriptionData.subscriptionType === EAccountSubscriptionType.UNLIMITED"
        ppStyle="blue"
        (ppAction)="toggleUpgrade()"
      ></pp-toggle>

      <img
        class="upgradeToggle__icon"
        [src]="EIconPath.MISC_INFO"
        ppTooltip
        [ppSanitize]="false"
        [ppTitle]="'subscription_update_tooltip' | ppTranslate"
      />
    </h5>

    <div *ngIf="subscriptionData.subscriptionType">
      <h5 class="changeLimitsHeader">{{ 'change_limits' | ppTranslate }}</h5>

      <div class="changeLimit__row">
        <span>
          {{ 'number_of_account_admin_users' | ppTranslate }}
        </span>

        <pp-account-settings-number-picker
          *ngIf="subscriptionData.subscriptionType === EAccountSubscriptionType.LIMITED"
          class="changeLimit__numberPicker"
          [(ppValue)]="subscriptionData.limits.SHARES_ACCOUNT_ADMIN"
          [ppMinValue]="subscriptionData.usages.SHARES_ACCOUNT_ADMIN"
        ></pp-account-settings-number-picker>

        <pp-account-setting-unlimited-info
          class="changeLimit__numberPicker"
          *ngIf="subscriptionData.subscriptionType === EAccountSubscriptionType.UNLIMITED"
        ></pp-account-setting-unlimited-info>
      </div>

      <div class="changeLimit__row">
        <span>
          {{ 'number_of_admin_users' | ppTranslate }}
        </span>

        <pp-account-settings-number-picker
          *ngIf="subscriptionData.subscriptionType === EAccountSubscriptionType.LIMITED"
          class="changeLimit__numberPicker"
          [(ppValue)]="subscriptionData.limits.SHARES_ADMIN"
          [ppMinValue]="subscriptionData.usages.SHARES_ADMIN"
        ></pp-account-settings-number-picker>

        <pp-account-setting-unlimited-info
          class="changeLimit__numberPicker"
          *ngIf="subscriptionData.subscriptionType === EAccountSubscriptionType.UNLIMITED"
        ></pp-account-setting-unlimited-info>
      </div>

      <div class="changeLimit__row">
        <span>
          {{ 'number_of_normal_users' | ppTranslate }}
        </span>

        <pp-account-settings-number-picker
          *ngIf="subscriptionData.subscriptionType === EAccountSubscriptionType.LIMITED"
          class="changeLimit__numberPicker"
          [(ppValue)]="subscriptionData.limits.SHARES_NORMAL"
          [ppMinValue]="subscriptionData.usages.SHARES_NORMAL"
        ></pp-account-settings-number-picker>

        <pp-account-setting-unlimited-info
          class="changeLimit__numberPicker"
          *ngIf="subscriptionData.subscriptionType === EAccountSubscriptionType.UNLIMITED"
        ></pp-account-setting-unlimited-info>
      </div>

      <div class="changeLimit__row">
        <span>
          {{ 'number_of_guest_users' | ppTranslate }}
        </span>

        <pp-account-settings-number-picker
          *ngIf="subscriptionData.subscriptionType === EAccountSubscriptionType.LIMITED"
          class="changeLimit__numberPicker"
          [(ppValue)]="subscriptionData.limits.SHARES_GUEST"
          [ppMinValue]="subscriptionData.usages.SHARES_GUEST"
        ></pp-account-settings-number-picker>

        <pp-account-setting-unlimited-info
          class="changeLimit__numberPicker"
          *ngIf="subscriptionData.subscriptionType === EAccountSubscriptionType.UNLIMITED"
        ></pp-account-setting-unlimited-info>
      </div>
      <div class="changeLimit__row">
        <span>
          {{ 'number_of_sites' | ppTranslate }}
        </span>

        <pp-account-settings-number-picker
          *ngIf="subscriptionData.subscriptionType === EAccountSubscriptionType.LIMITED"
          class="changeLimit__numberPicker"
          [(ppValue)]="subscriptionData.limits.SITES"
          [ppMinValue]="subscriptionData.usages.SITES"
        ></pp-account-settings-number-picker>

        <pp-account-setting-unlimited-info
          class="changeLimit__numberPicker"
          *ngIf="subscriptionData.subscriptionType === EAccountSubscriptionType.UNLIMITED"
        ></pp-account-setting-unlimited-info>
      </div>
    </div>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'change_subscription' | ppTranslate"
      (ppCancel)="hideModal()"
      (ppConfirm)="changeLimits()"
      [ppProcess]="processing"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
