import { Component, Input } from '@angular/core';
import { uuid } from '@core/helpers';
import { catchError, of } from 'rxjs';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { ChangeLimitModalComponent } from 'src/app/project/components/change-limit-modal/change-limit-modal.component';
import { TChangeLimitsModalData } from 'src/app/project/components/change-limit-modal/change-limit-modal.model';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TSelectOption } from 'src/app/project/components/input/select-tag/select-tag.model';
import { SelectDropdownComponent } from 'src/app/project/components/input/select/select-dropdown/select-dropdown.component';
import { TSelectDropdownData } from 'src/app/project/components/input/select/select-dropdown/select-dropdown.model';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { translate } from 'src/app/project/features/translate/translate';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EDIT_SIMPLE_SHARE_DROPDOWN_WIDTH } from '../../../account/account-settings/account-user-modal/edit-account-user-modal.consts';
import { AccountSharesService } from '../../../share/account-shares.service';
import { DefaultShareService } from '../../../share/default-share.service';
import {
  SHARE_ROLE_OPTIONS,
  getUserRole,
} from '../../../share/share-modal/share-edit/edit-share-options';
import { EUserRole } from '../../../share/share-utils/user-roles';
import { TShare, TUpdateShareDTO } from '../../../share/share.model';
import { SharesService } from '../../../share/shares.service';
import { WorkspaceService } from '../../../workspace/workspace.service';
import { USER_MANAGEMENT_CUSTOMIZED_SHARE_MARK } from '../../user-management.consts';
import { UserManagementTableCellsService } from '../user-management-table-cells.service';

@Component({
  selector: 'pp-user-management-access-level-cell',
  templateUrl: './user-management-access-level-cell.component.html',
  styleUrls: ['./user-management-access-level-cell.component.scss'],
})
export class UserManagementAccessLevelCellComponent {
  @Input() ppShare: TShare;

  userRole: string;
  buttonId: string = uuid();
  isDefaultShare: boolean;
  USER_MANAGEMENT_CUSTOMIZED_SHARE_MARK = USER_MANAGEMENT_CUSTOMIZED_SHARE_MARK;
  editable: boolean;
  tooltipText: string;

  constructor(
    private dropdownService: DropdownService,
    private modalService: ModalService,
    private defaultShareService: DefaultShareService,
    private userManagementTableCellsService: UserManagementTableCellsService,
    private workspaceService: WorkspaceService,
    private sharesService: SharesService,
    private accountSharesService: AccountSharesService,
    private translationPipe: TranslationPipe,
  ) {}

  ngOnChanges(): void {
    this.setUserRoleTranslationKey(this.ppShare.shareOption);
    this.setIsDefaultShare();
    this.checkIfEditable();
    this.setTooltipText();
  }

  // TODO: use EUserRole as type instead of string
  private setUserRoleTranslationKey(userRole: string): void {
    this.userRole = getUserRole(userRole as EUserRole);
  }

  toggleDropdown(): void {
    if (!this.editable) {
      return;
    }

    if (this.dropdownService.getDropdown().visible) {
      this.dropdownService.hideDropdown();
    } else {
      this.setDropdownData();

      this.dropdownService.showDropdown(this.buttonId, SelectDropdownComponent, {
        callback: (shareOption: TSelectOption) => this.selectAccessLevel(shareOption),
        width: EDIT_SIMPLE_SHARE_DROPDOWN_WIDTH,
      });
    }
  }

  private setDropdownData(): void {
    this.dropdownService.setData<TSelectDropdownData>({
      noSearchBar: true,
      items: SHARE_ROLE_OPTIONS().filter((option) => option.value !== EUserRole.ACCOUNT_ADMIN),
      selectedItem: this.tryFindSelectedPermissionItem(),
    });
  }

  private tryFindSelectedPermissionItem(): TSelectOption {
    try {
      return SHARE_ROLE_OPTIONS().find((option) => option.label === translate(this.userRole));
    } catch (error) {
      throw error;
    }
  }

  private selectAccessLevel(shareOption: TSelectOption): void {
    if (shareOption.value === this.ppShare.shareOption) {
      return;
    }

    this.setUserRoleTranslationKey(shareOption.value);
    this.updateShare(shareOption.value);
  }

  // TODO: use EUserRole as type instead of string
  private updateShare(userRole: string): void {
    const newShare: TShare = { ...this.ppShare, shareOption: userRole as EUserRole };
    const newShareDTO: TShare = this.getNewShareDTO(userRole, newShare);

    this.userManagementTableCellsService
      .updateShare(this.ppShare.userId, this.ppShare.shareId, newShareDTO)
      .pipe(
        catchError((error) => {
          if (error.status === EStatusCode.UPGRADE_REQUIRED) {
            this.showChangeLimitsModal(newShareDTO);
          }

          return of();
        }),
      )
      .subscribe(() => {
        this.accountSharesService.emitSharesChange();
      });
  }

  private getNewShareDTO(userRole: string, newShare: TShare): TShare {
    switch (userRole) {
      case EUserRole.SITE_ADMIN:
      case EUserRole.ACCOUNT_ADMIN:
        return this.defaultShareService.resetShareToDefaults(newShare);
      case EUserRole.NORMAL:
        return newShare;
      case EUserRole.LIMITED:
        return this.defaultShareService.resetShareToLimited(newShare);
    }
  }

  private showChangeLimitsModal(share: TUpdateShareDTO): void {
    this.modalService.setData<TChangeLimitsModalData>({
      firstMessageKey: 'users_limit_reached_description_1',
      secondMessageKey: 'users_limit_reached_description_2',
      confirmKey: 'edit_user',
      header: 'users_limit_reached',
      accountId: this.workspaceService.getWorkspace(share.workspaceId).accountId,
      limitsToIncrease: this.sharesService.addLimitToIncrease(share.shareOption as EUserRole),
    });

    this.modalService.showModal(ChangeLimitModalComponent, {
      onBeforeClose: (cancelled) => {
        if (!cancelled) {
          this.updateShare(share.shareOption);
        } else {
          this.accountSharesService.emitSharesChange();
        }

        return Promise.resolve(true);
      },
    });
  }

  private setIsDefaultShare(): void {
    this.isDefaultShare = this.defaultShareService.checkIfShareIsDefault(this.ppShare);
  }

  private checkIfEditable(): void {
    this.editable = this.ppShare.shareOption !== EUserRole.ACCOUNT_ADMIN;
  }

  private setTooltipText(): void {
    if (!this.editable) {
      this.tooltipText = this.translationPipe.transform('cant_edit_account_admin_permission');
    } else if (!this.isDefaultShare) {
      this.tooltipText = this.translationPipe.transform('user_access_customized');
    } else {
      this.tooltipText = '';
    }
  }
}
