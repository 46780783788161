import { Action } from '@ngrx/store';

import { TAttachments, TAttachment } from './attachments.model';

export enum EActionType {
  CLEAR_ATTACHMENTS = '[Point attachments] Clear attachments',
  SET_MEDIA = '[Point attachments] Set media',
  SET_FILES = '[Point attachments] Set files',
  ADD_NEW_MEDIA = '[Point attachments] Add new media',
  ADD_NEW_FILE = '[Point attachments] Add new file',
  DELETE_MEDIA = '[Point attachments] Delete media',
  DELETE_MULTIPLE_MEDIA = '[Point attachments] Delete multiple media',
  DELETE_FILE = '[Point attachments] Delete file',
  UPDATE_MEDIA = '[Point attachments] Update media',
  TOGGLE_MEDIA = '[Point attachments] Toggle media',
  TOGGLE_FILE = '[Point attachments] Toggle file',
  TOGGLE_ALL_FILES = '[Point attachments] Toggle all files',
  TOGGLE_ALL_MEDIA = '[Point attachments] Toggle all media',
  REPLACE_MEDIA = '[Point attachments] Replace media',
  UPDATE_MEDIA_NAME = '[Point attachments] Update media name',
}

export class ClearAttachments implements Action {
  readonly type = EActionType.CLEAR_ATTACHMENTS;

  constructor() {}
}

export class SetMedia implements Action {
  readonly type = EActionType.SET_MEDIA;

  constructor(
    readonly payload: {
      dates: TAttachments['media']['dates'];
      attachments: TAttachments['media']['attachments'];
    },
  ) {}
}

export class SetFiles implements Action {
  readonly type = EActionType.SET_FILES;

  constructor(
    readonly payload: {
      attachmentIds: string[];
      attachments: TAttachments['files']['attachments'];
    },
  ) {}
}

export class AddNewMedia implements Action {
  readonly type = EActionType.ADD_NEW_MEDIA;

  constructor(
    readonly payload: {
      imageToStore: TAttachment;
      dateFormat: string;
    },
  ) {}
}

export class AddNewFile implements Action {
  readonly type = EActionType.ADD_NEW_FILE;

  constructor(readonly payload: TAttachment) {}
}

export class DeleteMedia implements Action {
  readonly type = EActionType.DELETE_MEDIA;

  constructor(readonly payload: TAttachment['attachmentId']) {}
}

export class DeleteMultipleMedia implements Action {
  readonly type = EActionType.DELETE_MULTIPLE_MEDIA;

  constructor(readonly payload: string[]) {}
}

export class DeleteFile implements Action {
  readonly type = EActionType.DELETE_FILE;

  constructor(readonly payload: string) {}
}

export class UpdateMedia implements Action {
  readonly type = EActionType.UPDATE_MEDIA;

  constructor(
    readonly payload: {
      oldAttachmentId: string;
      attachment: TAttachment;
    },
  ) {}
}

export class UpdateMediaName implements Action {
  readonly type = EActionType.UPDATE_MEDIA_NAME;

  constructor(
    readonly payload: {
      attachmentId: string;
      name: string;
    },
  ) {}
}

export class ToggleMediaSelection implements Action {
  readonly type = EActionType.TOGGLE_MEDIA;

  constructor(
    readonly payload: {
      attachmentId: string;
      selected: boolean;
    },
  ) {}
}

export class ToggleFileSelection implements Action {
  readonly type = EActionType.TOGGLE_FILE;

  constructor(
    readonly payload: {
      attachmentId: string;
      selected: boolean;
    },
  ) {}
}

export class ToggleAllFilesSelection implements Action {
  readonly type = EActionType.TOGGLE_ALL_FILES;

  constructor(
    readonly payload: {
      selected: boolean;
    },
  ) {}
}

export class ToggleAllMediaSelection implements Action {
  readonly type = EActionType.TOGGLE_ALL_MEDIA;

  constructor(
    readonly payload: {
      selected: boolean;
    },
  ) {}
}

export class ReplaceMedia implements Action {
  readonly type = EActionType.REPLACE_MEDIA;

  constructor(
    readonly payload: {
      imageToStore: TAttachment;
      dateFormat: string;
      oldAttachmentId: string;
    },
  ) {}
}

export type ActionsUnion =
  | ClearAttachments
  | SetMedia
  | SetFiles
  | AddNewMedia
  | AddNewFile
  | DeleteMedia
  | DeleteMultipleMedia
  | DeleteFile
  | UpdateMedia
  | ToggleMediaSelection
  | ToggleFileSelection
  | ToggleAllFilesSelection
  | ToggleAllMediaSelection
  | ReplaceMedia
  | UpdateMediaName;
