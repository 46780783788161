import { Injectable } from '@angular/core';
import { SortingService } from '@core/helpers';
import { TReminderList, TGroupedReminders } from './reminder-list.model';

@Injectable({
  providedIn: 'root',
})
export class SortRemindersService {
  constructor(private sortingService: SortingService) {}

  sortReminders(
    pastReminders: TReminderList,
    todayReminders: TReminderList,
    futureReminders: TReminderList,
    groupedReminders: TGroupedReminders,
  ): void {
    pastReminders.values.sort((a, b) =>
      this.sortingService.naturalSort(a.date.getTime(), b.date.getTime()),
    );
    todayReminders.values.sort((a, b) =>
      this.sortingService.naturalSort(a.date.getTime(), b.date.getTime()),
    );
    futureReminders.values.sort((a, b) =>
      this.sortingService.naturalSort(a.date.getTime(), b.date.getTime()),
    );

    todayReminders.values.forEach((group) => {
      group.values = group.values.filter((value) => groupedReminders[value]);
      group.values = group.values.sort((a, b) =>
        this.sortingService.naturalSort(
          groupedReminders[a].refObject?.name,
          groupedReminders[b].refObject?.name,
        ),
      );
    });

    pastReminders.values.forEach((group) => {
      group.values = group.values.filter((value) => groupedReminders[value]);
      group.values = group.values.sort((a, b) =>
        this.sortingService.naturalSort(
          groupedReminders[a].refObject?.name,
          groupedReminders[b].refObject?.name,
        ),
      );
    });

    futureReminders.values.forEach((group) => {
      group.values = group.values.filter((value) => groupedReminders[value]);
      group.values = group.values.sort((a, b) =>
        this.sortingService.naturalSort(
          groupedReminders[a].refObject?.name,
          groupedReminders[b].refObject?.name,
        ),
      );
    });
  }
}
