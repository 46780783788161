import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TSelectOption } from 'src/app/project/components/input/select/select.model';
import { TableColumnsService } from 'src/app/project/modules/site/site-table/columns/table-columns.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { TAdvancedFilterSingle } from '../../../models/advanced-filter.model';
import {
  filterIdToColumn,
  getAdvancedFilterFields,
  searchNewValue,
} from './advanced-filter-field-select-utils';

@Component({
  selector: 'pp-advanced-filter-field-select',
  templateUrl: './advanced-filter-field-select.component.html',
  styleUrls: ['./advanced-filter-field-select.component.scss'],
})
export class AdvancedFilterFieldSelectComponent implements OnInit, OnChanges {
  @Input() ppFilter: TAdvancedFilterSingle;
  @Output() ppFilterChange = new EventEmitter<TAdvancedFilterSingle>();
  @Input() ppWorkspaceId: string;

  tableColumns: TSelectOption[] = [];
  value: string;

  constructor(
    private workspaceService: WorkspaceService,
    private tableColumnsService: TableColumnsService,
  ) {}

  ngOnInit(): void {
    this.getFields();
  }

  ngOnChanges(): void {
    const column = filterIdToColumn(this.ppFilter.id);

    this.value = column ? column.name : null;
  }

  getFields(): void {
    const columns = this.tableColumnsService.getColumns();
    const workspace = this.workspaceService.findWorkspace(this.ppWorkspaceId);

    this.tableColumns = getAdvancedFilterFields(columns, workspace);
  }

  changeFieldValue(newValue: string): void {
    const value = searchNewValue(newValue, this.ppWorkspaceId);

    this.ppFilter.id = value.id;
    this.ppFilter.type = value.type;
    this.value = newValue;
    this.ppFilterChange.emit(this.ppFilter);
  }
}
