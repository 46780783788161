<div class="sharesHeaders__accessibleTags">
  <div
    class="shareHeadersAccessibleTags__select"
    ppTooltip
    [ppTitle]="ppDisabled ? disabledTooltipText : ''"
  >
    <pp-tag-select
      [ppSelectedTags]="ppShare.defectTags"
      [ppWorkspaceId]="ppShare.workspaceId"
      [ppOptions]="tagSelectOptions"
      [ppPlaceholder]="'unrestricted' | ppTranslate"
      (ppOnChange)="updateShare($event)"
      (ppOnClose)="updateShare($event)"
    ></pp-tag-select>
  </div>
</div>
