<div class="notification__image-wrapper">
  <img class="notification__image" [src]="EIconPath.NOTIFICATION_ICON_VIDEO" />
</div>

<ng-container
  *ngIf="
    (ppNotification.changeBody.newValue &&
      ppNotification.changeBody.newValue.length > ppNotification.changeBody.oldValue.length) ||
    !ppNotification.changeBody.oldValue
  "
>
  <ng-container
    *ngIf="
      (!ppNotification.changeBody.oldValue && ppNotification.changeBody.newValue.length === 1) ||
      ppNotification.changeBody.newValue.length - ppNotification.changeBody.oldValue.length === 1
    "
  >
    <span class="notification__blue-text">{{ 'added' | ppTranslate }}</span>
    {{ 'a' | ppTranslate }}
    <span class="notification__bold">{{ 'video' | ppTranslate | lowercase }}</span>
    {{ 'to_a_point_you' | ppTranslate }}
    {{ notificationReason }}.
  </ng-container>

  <ng-container
    *ngIf="
      (!ppNotification.changeBody.oldValue && ppNotification.changeBody.newValue.length > 1) ||
      ppNotification.changeBody.newValue.length - ppNotification.changeBody.oldValue.length > 1
    "
  >
    <span class="notification__blue-text">{{ 'added' | ppTranslate }}</span>
    {{
      ppNotification.changeBody.oldValue
        ? ppNotification.changeBody.newValue.length - ppNotification.changeBody.oldValue.length
        : ppNotification.changeBody.newValue.length
    }}
    <span class="notification__bold">{{ 'videos' | ppTranslate | lowercase }}</span>
    {{ 'to_a_point_you' | ppTranslate }}
    {{ notificationReason }}.
  </ng-container>
</ng-container>

<ng-container
  *ngIf="
    (ppNotification.changeBody.oldValue &&
      ppNotification.changeBody.oldValue.length > ppNotification.changeBody.newValue.length) ||
    !ppNotification.changeBody.newValue
  "
>
  <ng-container
    *ngIf="
      (!ppNotification.changeBody.newValue && ppNotification.changeBody.oldValue.length === 1) ||
      ppNotification.changeBody.oldValue.length - ppNotification.changeBody.newValue.length === 1
    "
  >
    <span class="notification__blue-text"> {{ 'removed' | ppTranslate }}</span>
    {{ 'a' | ppTranslate }}
    <span class="notification__bold"> {{ 'video' | ppTranslate | lowercase }}</span>
    {{ 'from_a_point_you' | ppTranslate }}
    {{ notificationReason }}.
  </ng-container>

  <ng-container
    *ngIf="
      (!ppNotification.changeBody.newValue && ppNotification.changeBody.oldValue.length > 1) ||
      ppNotification.changeBody.oldValue.length - ppNotification.changeBody.newValue.length > 1
    "
  >
    <span span class="notification__blue-text"> {{ 'removed' | ppTranslate }}</span>
    {{
      ppNotification.changeBody.oldValue
        ? ppNotification.changeBody.newValue.length - ppNotification.changeBody.oldValue.length
        : ppNotification.changeBody.newValue.length
    }}
    <span class="notification__bold"> {{ 'videos' | ppTranslate | lowercase }}</span>
    {{ 'from_a_point_you' | ppTranslate }}
    {{ notificationReason }}.
  </ng-container>
</ng-container>
