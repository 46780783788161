import { Component } from '@angular/core';
import { ResetTimelineService } from './reset-timeline.service';

@Component({
  selector: 'pp-reset-timeline-button',
  templateUrl: './reset-timeline-button.component.html',
  styleUrls: ['./reset-timeline-button.component.scss'],
})
export class ResetTimelineButtonComponent {
  constructor(private resetTimelineService: ResetTimelineService) {}

  resetTimeline(): void {
    this.resetTimelineService.resetTimeline();
  }
}
