import VirtualScroller from 'src/app/project/modules/external/virtual-scroller/VirtualScroller';
import Site from '../site/Site';
import { TFlattenedAccount } from './account-list-flatten-account';
import { TAnyFunction } from '@core/helpers';
import { ApiService } from '@core/http';

type TAddSiteElementType = {
  _index: number;
  virtualScroller: VirtualScroller;
  accountsFlattened: TFlattenedAccount[];
  apiService: ApiService;
  siteSettingsCallback: TAnyFunction;
  openSiteCallback: TAnyFunction;
  addSiteToFolderCallback: TAnyFunction;
  removeSiteFromFolderCallback: TAnyFunction;
  dragoverCallback: TAnyFunction;
};

export const addSiteElement = ({
  _index,
  virtualScroller,
  accountsFlattened,
  apiService,
  siteSettingsCallback,
  openSiteCallback,
  addSiteToFolderCallback,
  removeSiteFromFolderCallback,
  dragoverCallback,
}: TAddSiteElementType): HTMLElement => {
  let data = accountsFlattened[_index];

  if (virtualScroller) {
    data = virtualScroller.data[_index];
  }

  return new Site(data, apiService, {
    siteSettingsCallback: siteSettingsCallback,
    openSiteCallback: openSiteCallback,
    addSiteToFolderCallback: addSiteToFolderCallback,
    removeSiteFromFolderCallback: removeSiteFromFolderCallback,
    dragoverCallback: (folderId: string): void => dragoverCallback(folderId),
  }).element;
};
