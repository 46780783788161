export function generatePlainText(value): string {
  let mergedText = '';

  try {
    value.ops.forEach((textLine) => {
      if (typeof textLine.insert === 'string') {
        mergedText += textLine.insert;
      } else if (textLine.insert.mention) {
        mergedText += '@' + textLine.insert.mention.value;
      }
    });
  } catch (error) {
    return '';
  }

  return mergedText;
}
