<button class="dropdownItem dropdownItem--btn" id="editSiteNameBtn" (click)="newDashlet()">
  {{ 'new_dashlet' | ppTranslate }}
</button>

<button
  class="dropdownItem dropdownItem--btn"
  id="exportDashboardBtn"
  [class.dropdownItem--disabled]="!exportsEnabled"
  (click)="exportDashboard()"
>
  {{ 'export_dashboard' | ppTranslate }}
</button>

<button class="dropdownItem dropdownItem--btn" id="defaultLayoutBtn" (click)="setDefaultLayout()">
  {{ 'set_default_layout' | ppTranslate }}
</button>
