import { ERowType } from 'src/app/project/shared/enums/row-type.enum';
import { TGroupedPoints } from '../../../grouping/group-model';
import { GET_COLLAPSED_GROUPS } from '../../../table.ui.store';
import { TTableItem } from '../../table.model';

export function generateGroupedPointItems(groupedTable: TGroupedPoints[]): TTableItem[] {
  const collapsedGroups = GET_COLLAPSED_GROUPS();

  let items: TTableItem[] = [];

  items = groupedTable.flatMap((groupedPoints, groupIndex) => {
    const points: TTableItem[] = [];
    const collapsed = collapsedGroups.has(groupedPoints.id);

    if (groupIndex === 0) {
      points.push({
        type: ERowType.EMPTY,
      });
    }

    points.push({
      type: ERowType.HEADER,
      context: groupedPoints.value,
      id: groupedPoints.id,
    });

    if (!collapsed) {
      generateFirstGroupPoints(groupedPoints, points);
    }

    points.push({
      type: ERowType.EMPTY,
    });

    return points;
  });

  return items;
}

function generateFirstGroupPoints(group: TGroupedPoints, points: TTableItem[]): void {
  if (group.group) {
    generateSecondGroupPoints(group, points);
  } else {
    points.push(
      ...group.points.map((point) => ({
        type: ERowType.POINT,
        index: point,
      })),
    );
  }
}

function generateSecondGroupPoints(group: TGroupedPoints, points: TTableItem[]): void {
  const collapsedGroups = GET_COLLAPSED_GROUPS();

  group.group.forEach((subGroup) => {
    const collapsedsubGroup = collapsedGroups.has(subGroup.id);

    points.push({
      type: ERowType.GROUP_2,
      context: subGroup.value,
      id: subGroup.id,
    });

    if (!collapsedsubGroup) {
      if (subGroup.group) {
        generateThirdGroupPoints(subGroup, points);
      } else {
        points.push(
          ...subGroup.points.map((point) => ({
            type: ERowType.POINT,
            index: point,
          })),
        );
      }
    }

    points.push({
      type: ERowType.EMPTY_ROW_GROUP_1,
    });
  });
}

function generateThirdGroupPoints(subGroup: TGroupedPoints, points: TTableItem[]): void {
  const collapsedGroups = GET_COLLAPSED_GROUPS();

  subGroup.group.forEach((subSubGroup) => {
    const collapsedSubsubGroup = collapsedGroups.has(subSubGroup.id);

    points.push({
      type: ERowType.GROUP_3,
      context: subSubGroup.value,
      id: subSubGroup.id,
    });

    if (!collapsedSubsubGroup) {
      points.push(
        ...subSubGroup.points.map((point) => ({
          type: ERowType.POINT,
          index: point,
        })),
      );
    }

    points.push({
      type: ERowType.EMPTY_ROW_GROUP_2,
    });
  });
}
