import { TPointCustomField } from '@project/view-models';
import * as ExcelJS from 'exceljs';
import { getTimeUTC } from 'src/app/core/helpers/date/date';
import { DAY_IN_MILLISECONDS } from 'src/app/core/helpers/date/date.consts';
import { transformDate } from '../../../shared/date-transformer';
import { ECustomFieldType } from '../../custom-fields/custom-field-types-enums';
import { GET_CUSTOM_FIELDS } from '../../custom-fields/custom-fields.store';
import { TPoint } from '../../points/points.model';
import { humanizePriority } from '../../points/priorities';
import { humanizeStatus } from '../../points/statuses';
import { TPreferences } from '../../preferences/preferences.model';
import { GET_PREFERENCES } from '../../preferences/preferences.store';
import {
  ETimelineTimeframe,
  FILLED_TIMELINE_ELEMENT_KEY,
} from '../../site/site-timeline/site-timeline-utils/timeframes-enums';
import { getDateUTC } from './get-date-utc';
import { getEndOfWeek } from './get-end-of-week';
import { getStartOfMonth } from './get-start-of-month';
import { ETimelineExportColumn } from './timeline-export.consts';

export function addPointRows(points: TPoint[], worksheet: ExcelJS.Worksheet): void {
  const preferences = GET_PREFERENCES();
  const pointRows = [];
  let timelineField: TPointCustomField;

  points.forEach((point) => {
    const row: {
      [key: string]: string | number;
    } = {};

    let customFieldIndex: string;
    let [startDate, endDate] = [null, null];

    for (const key in point.customFieldsMap) {
      const customField = GET_CUSTOM_FIELDS()[key];

      if (customField.type === ECustomFieldType.TIMELINE) {
        customFieldIndex = key;
      }
    }

    timelineField = point.customFieldsMap[customFieldIndex];
    const [startTimeEpoch, endTimeEpoch] = timelineField.value.split('~');
    const startTime = new Date(parseFloat(startTimeEpoch));
    startDate = getTimeUTC(startTime);

    const endTime = new Date(parseFloat(endTimeEpoch));
    endDate = getTimeUTC(endTime);

    worksheet.columns.forEach((column, index) => {
      switch (column.key) {
        case ETimelineExportColumn.EMPTY:
          row[ETimelineExportColumn.EMPTY] = '';

          break;
        case ETimelineExportColumn.PRIORITY:
          row[ETimelineExportColumn.PRIORITY] = humanizePriority(point.priority);

          break;
        case ETimelineExportColumn.STATUS:
          row[ETimelineExportColumn.STATUS] = humanizeStatus(point.status);

          break;
        case ETimelineExportColumn.ID:
          row[ETimelineExportColumn.ID] = point.sequenceNumber;

          break;
        case ETimelineExportColumn.TITLE:
          row[ETimelineExportColumn.TITLE] = `${point.title}`;

          break;
        case ETimelineExportColumn.START_DATE:
          if (timelineField) {
            const epochValue = startDate;

            row[ETimelineExportColumn.START_DATE] = transformDate({
              value: epochValue,
              inputHourFormat: '',
              format: preferences.dateFormat,
              localTime: false,
            });
          } else {
            row[ETimelineExportColumn.START_DATE] = '';
          }

          break;
        case ETimelineExportColumn.END_DATE:
          if (timelineField) {
            const epochValue = endDate;

            row[ETimelineExportColumn.END_DATE] = transformDate({
              value: epochValue,
              inputHourFormat: '',
              format: preferences.dateFormat,
              localTime: false,
            });
          } else {
            row[ETimelineExportColumn.END_DATE] = '';
          }

          break;
        case ETimelineExportColumn.DURATION: {
          const diffTime = Math.abs(parseFloat(endTimeEpoch) - parseFloat(startTimeEpoch));
          const diffDays = Math.ceil(diffTime / DAY_IN_MILLISECONDS);

          row[ETimelineExportColumn.DURATION] = diffDays + 1;

          break;
        }
        default: {
          addTimelineCustomColumnRow(
            worksheet,
            index,
            preferences,
            column,
            startDate,
            endDate,
            row,
          );

          break;
        }
      }
    });

    pointRows.push(row);
  });

  pointRows.forEach((row) => {
    worksheet.addRow(row);
  });
}

function addTimelineCustomColumnRow(
  worksheet: ExcelJS.Worksheet,
  index: number,
  preferences: TPreferences,
  column: Partial<ExcelJS.Column>,
  startDate: any,
  endDate: any,
  row: { [key: string]: string | number },
): void {
  const nextColumn = worksheet.columns[index + 1];
  const nextColumnKey: number = nextColumn ? parseFloat(nextColumn.key) : Number.MAX_SAFE_INTEGER;
  const timeframe = preferences?.timeline?.timeframe;
  const columnKey = parseFloat(column.key);

  switch (timeframe) {
    case ETimelineTimeframe.MONTH: {
      addMonthRow(startDate, columnKey, endDate, nextColumnKey, row, column);

      break;
    }
    case ETimelineTimeframe.WEEK: {
      addWeekRow(columnKey, startDate, column, endDate, nextColumnKey, row);

      break;
    }
    default:
      addDayRow(startDate, endDate, +column.key, nextColumnKey, row);
      break;
  }
}

function addDayRow(
  startDate: number,
  endDate: number,
  columnKey: number,
  nextColumnKey: number,
  row: { [key: string]: string | number },
) {
  const startDateUTC = getDateUTC(startDate);
  const endDateUTC = getDateUTC(endDate);
  const columnKeyUTC = getDateUTC(columnKey);
  const nextColumnKeyUTC = getDateUTC(nextColumnKey);

  const startDateMatch = startDateUTC <= columnKeyUTC && columnKeyUTC <= endDateUTC;
  const endDateMatch = startDateUTC >= columnKeyUTC && nextColumnKeyUTC >= endDateUTC;

  if (startDateMatch || endDateMatch) {
    row[columnKey] = FILLED_TIMELINE_ELEMENT_KEY;
  }
}

function addWeekRow(
  columnKey: number,
  startDate: any,
  column: Partial<ExcelJS.Column>,
  endDate: any,
  nextColumnKey: number,
  row: { [key: string]: string | number },
) {
  const columnEnd = getEndOfWeek(columnKey);

  if (
    ((startDate < column.key || startDate < columnEnd) &&
      (column.key < endDate || columnEnd < endDate)) ||
    (startDate > column.key && nextColumnKey > endDate)
  ) {
    row[column.key] = FILLED_TIMELINE_ELEMENT_KEY;
  }
}

function addMonthRow(
  startDate: any,
  columnKey: number,
  endDate: any,
  nextColumnKey: number,
  row: { [key: string]: string | number },
  column: Partial<ExcelJS.Column>,
) {
  const startOfMonth = getStartOfMonth(new Date(startDate));

  if (
    (startOfMonth <= columnKey && columnKey <= endDate) ||
    (startOfMonth >= columnKey && nextColumnKey >= endDate)
  ) {
    row[column.key] = FILLED_TIMELINE_ELEMENT_KEY;
  }
}
