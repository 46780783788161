import { Component } from '@angular/core';

import { TPreferences } from 'src/app/project/modules/preferences/preferences.model';
import { TUser } from 'src/app/project/modules/user/user.model';
import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { TimelineExportService } from 'src/app/project/modules/exports/timeline-export.service';
import { getTimeframeOptions } from '../../site-timeline/site-timeline-utils/timeframe-options';
import { EIconPath } from '../../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-timeline-export-button',
  templateUrl: './timeline-export-button.component.html',
  styleUrls: ['./timeline-export-button.component.scss', '../timeline-options.component.scss'],
})
export class TimelineExportButtonComponent {
  options = getTimeframeOptions();
  EIconPath = EIconPath;

  public user: TUser;
  workspace: TWorkspace;
  workspaces: TWorkspacesById;
  siteId = '';
  scrollPosition = 0;
  scrolledToBottom = false;
  savingPreferences = false;
  preferences: TPreferences;
  timeframeHovered = false;
  dropdownVisible = false;

  hovered = false;

  constructor(private timelineExportService: TimelineExportService) {}

  exportTimeline(): void {
    this.timelineExportService.exportLocally();
  }

  timeframeHover(): void {
    this.timeframeHovered = true;
  }

  timeframeHoverEnd(): void {
    this.timeframeHovered = false;
  }
}
