import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { setChangedAccounts } from '../../account/account';
import { DeleteWorkspace } from '../../workspace/workspace.actions';
import { WorkspaceService } from '../../workspace/workspace.service';

@Injectable({
  providedIn: 'root',
})
export class DeleteSiteService {
  constructor(private store: Store, private workspaceService: WorkspaceService) {}

  deleteSite(workspaceId: string): Observable<void> {
    return this.workspaceService.deleteWorkspace(workspaceId).pipe(
      tap(() => {
        this.store.dispatch(
          new DeleteWorkspace({
            workspaceId,
          }),
        );

        setChangedAccounts(true);
      }),
    );
  }
}
