// https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html?h=tooltip
export function createDashletTooltip(tooltipModel, _chart) {
  // Tooltip Element
  const tooltipEl = document.getElementById('chartjs-tooltip');

  if (tooltipEl) {
    createElementOnFirstRender(tooltipEl);

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }

    setCaretPosition(tooltipEl, tooltipModel);

    setText(tooltipModel, getBody, tooltipEl);

    // `this` will be the overall tooltip
    const position = _chart.canvas.getBoundingClientRect();

    setStyles(tooltipEl, position, tooltipModel);
  }

  function getBody(bodyItem): any {
    return bodyItem.lines;
  }
}

function setStyles(tooltipEl: HTMLElement, position: DOMRect, tooltipModel: any): void {
  tooltipEl.style.opacity = '1';
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
  tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
  tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
  tooltipEl.style.pointerEvents = 'none';
}

function setText(tooltipModel: any, getBody: (bodyItem: any) => any, tooltipEl: HTMLElement): void {
  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || [];
    const bodyLines = tooltipModel.body.map(getBody);

    let innerHtml = '<thead>';

    titleLines.forEach(function (title: string) {
      innerHtml += '<tr><th>' + title + '</th></tr>';
    });
    innerHtml += '</thead><tbody>';

    bodyLines.forEach(function (body, i) {
      let title = body[0];

      const mySubString = title.substring(title.indexOf('(') - 1, title.lastIndexOf(')') + 1);

      title = title.replace(mySubString, '');
      body[0] = title;

      const colors = tooltipModel.labelColors[i];
      let style = 'background:' + colors.backgroundColor;
      style += '; border-color:' + colors.borderColor;
      style += '; border-width: 0.2rem';
      const span = '<span style="' + style + '"></span>';
      innerHtml += '<tr><td>' + span + body + '</td></tr>';
    });
    innerHtml += '</tbody>';

    const tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;

    tooltipModel.width = tableRoot.offsetWidth + 20;
  }
}

function setCaretPosition(tooltipEl: HTMLElement, tooltipModel: any): void {
  if (tooltipEl) {
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    tooltipEl.classList.add(tooltipModel.yAlign || 'no-transform');
  }
}

function createElementOnFirstRender(tooltipEl: HTMLElement): void {
  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<table aria-hidden="true"></table>';
    document.body.appendChild(tooltipEl);
  }
}
