import { cloneDeep } from 'lodash';

import { ActionsUnion, EActionType } from './workspace.actions';
import { TWorkspacesById } from './workspace.model';

export const initialState: TWorkspacesById = {};

let state: TWorkspacesById = {};

export function workspaceReducer(
  oldState: TWorkspacesById = initialState,
  action: ActionsUnion,
): TWorkspacesById {
  switch (action.type) {
    case EActionType.SET_WORKSPACES: {
      const payload = cloneDeep(action.payload);

      state = cloneDeep(oldState);

      Object.keys(action.payload).forEach((workspaceId) => {
        state[workspaceId] = payload[workspaceId];
      });

      return state;
    }

    case EActionType.SET_WORKSPACE:
      state = cloneDeep(oldState);

      state[action.payload.workspaceId] = action.payload.workspace;

      return state;

    case EActionType.CLEAR_WORKSPACES:
      return {};

    case EActionType.UPDATE_ACCOUNT_NAME:
      state = cloneDeep(oldState);

      state[action.payload.workspaceId].accountName = action.payload.accountName;

      return state;

    case EActionType.UPDATE_WORKSPACE_LOGO:
      state = cloneDeep(oldState);

      if (state[action.payload.workspaceId]) {
        state[action.payload.workspaceId].siteLogoRef = {
          id: action.payload.imageId,
          caption: '',
        };
      }

      return state;

    case EActionType.UPDATE_SITE_NAME:
      state = cloneDeep(oldState);

      state[action.payload.workspaceId].siteName = action.payload.siteName;

      return state;

    case EActionType.UPDATE_WORKSPACE_USERS:
      state = cloneDeep(oldState);

      state[action.payload.workspaceId].users = action.payload.users;

      return state;

    case EActionType.UPDATE_WORKSPACE_TAGS:
      state = cloneDeep(oldState);

      state[action.payload.workspaceId].tags = action.payload.tags;

      return state;

    case EActionType.UPDATE_WORKSPACE_CUSTOM_FIELDS:
      state = cloneDeep(oldState);

      state[action.payload.workspaceId].customFields = action.payload.customFields;

      return state;

    case EActionType.REMOVE_WORKSPACE_USER: {
      state = cloneDeep(oldState);

      const index = state[action.payload.workspaceId].users.findIndex(
        (user) => user === action.payload.user,
      );

      state[action.payload.workspaceId].users.splice(index, 1);

      return state;
    }

    case EActionType.ADD_CUSTOM_FIELD:
      state = cloneDeep(oldState);

      state[action.payload.workspaceId].customFields.push(action.payload.customFieldId);

      return state;

    case EActionType.REMOVE_CUSTOM_FIELD: {
      state = cloneDeep(oldState);

      const customFieldIndex = state[action.payload.workspaceId].customFields.findIndex(
        (field) => +field === +action.payload.customFieldId,
      );

      state[action.payload.workspaceId].customFields.splice(customFieldIndex, 1);

      return state;
    }

    case EActionType.UPDATE_CUSTOM_FIELD: {
      state = cloneDeep(oldState);

      const customFieldToUpdateIndex = state[action.payload.workspaceId].customFields.findIndex(
        (customFieldId) => customFieldId === action.payload.customFieldId,
      );

      if (customFieldToUpdateIndex > -1) {
        state[action.payload.workspaceId].customFields.splice(
          customFieldToUpdateIndex,
          1,
          action.payload.customFieldId,
        );
      }

      return state;
    }

    case EActionType.UPDATE_POINT_IDS:
      state = cloneDeep(oldState);

      if (state[action.payload.workspaceId]) {
        state[action.payload.workspaceId].pointIds = action.payload.pointIds;
      }

      return state;

    case EActionType.UPDATE_ALL_POINT_IDS:
      state = cloneDeep(oldState);

      Object.keys(action.payload.pointIdsByWorkspace).forEach((workspaceId) => {
        if (state[workspaceId]) {
          state[workspaceId].pointIds = action.payload.pointIdsByWorkspace[workspaceId];
        }
      });

      return state;

    case EActionType.DELETE_WORKSPACE:
      state = cloneDeep(oldState);

      delete state[action.payload.workspaceId];

      return state;

    case EActionType.DELETE_WORKSPACES:
      state = cloneDeep(oldState);

      action.payload.workspaceIds.forEach((workspaceId) => {
        delete state[workspaceId];
      });

      return state;

    case EActionType.UPDATE_WORKSPACE_SHARE:
      state = cloneDeep(oldState);

      if (state[action.payload.workspaceId]) {
        state[action.payload.workspaceId].share = action.payload.share;
      }

      return state;

    case EActionType.UPDATE_WORKSPACE_INTEGRATIONS:
      state = cloneDeep(oldState);

      if (state[action.payload.workspaceId]) {
        state[action.payload.workspaceId].integrations = action.payload.integrations;
      }

      return state;
    default:
      return oldState;
  }
}
