import { TPoint } from 'src/app/project/modules/points/points.model';
import { TFilters } from '../site-filter.model';

export function checkPriority(point: TPoint, filters: TFilters): boolean {
  for (let index = 0; index < filters.priority.length; index++) {
    const filterPriority = filters.priority[index];
    const priority = point.priority;

    if (filterPriority.label === priority && filterPriority.appliedValue) {
      return true;
    }
  }

  return false;
}
