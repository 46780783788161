import { EFormulaPieceType, TFormulaPiece } from './formula-input.type';

export function deconstructFormula(value: string): TFormulaPiece[] {
  const result: TFormulaPiece[] = [];
  let i = 0;

  while (i < value.length) {
    if (value[i] === '{') {
      // Handle 'field' case by detecting the value inside {}
      const endBracket = value.indexOf('}', i);

      if (endBracket !== -1) {
        const fieldValue = value.slice(i + 1, endBracket);
        result.push({ type: EFormulaPieceType.CUSTOM_FIELD, value: fieldValue });
        i = endBracket + 1; // Move past the closing bracket
      }
    } else {
      // Handle 'operator' case
      result.push({ type: EFormulaPieceType.OPERATOR, value: value[i] });
      i++; // Move to the next character
    }
  }

  return result;
}
