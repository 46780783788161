import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-dashlet-cost-comparison-setup',
  templateUrl: './dashlet-cost-comparison-setup.component.html',
  styleUrl: './dashlet-cost-comparison-setup.component.scss',
})
export class DashletCostComparisonSetupComponent {
  @Input() ppCanEdit: boolean;
  @Output() ppEditDashlet = new EventEmitter<void>();

  EIconPath = EIconPath;

  editDashlet(): void {
    if (!this.ppCanEdit) {
      return;
    }

    this.ppEditDashlet.emit();
  }
}
