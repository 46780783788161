<div
  #selectComponent
  [id]="buttonId"
  class="select"
  [class.select__single-line]="ppOptions.singleLineInput"
  [class]="ppClass"
  [class.select--disabled]="ppOptions.disabled"
  [class.select--inputFocused]="focused && !ppOptions.noBorder"
  [class.select--error]="ppOptions.hasError"
  [class.select--noBorder]="ppOptions.noBorder"
  [class.select--caret]="showSelectCaret()"
  (click)="toggleDropdown()"
>
  <div #selectContent class="select__content">
    <ng-container selected class="select__content--wrapper">
      <div class="select__content--wrapper">
        <img
          [src]="EIconPath.BASIC_FIELD_TAGS"
          class="tagSelect__tag-icon icon-gap--right2x"
          *ngIf="!ppOptions.disabled && ppOptions.showIcon"
        />

        <pp-tag
          *ngIf="ppOptions.combinedTags && selectedTags.length > 0"
          [ppText]="selectedTags.join(', ')"
          [ppDeletable]="true"
          (ppDelete)="clearAll()"
        ></pp-tag>

        <ng-container *ngIf="!ppOptions.combinedTags">
          <pp-tag
            *ngFor="let tag of selectedTags; let i = index"
            [ppText]="tag"
            [ppDisabled]="ppOptions.disabled || ppReadOnlyTags.includes(tag)"
            [ppDeletable]="
              !ppOptions.disabled &&
              !ppReadOnlyTags.includes(tag) &&
              (!ppOptions.tagsLimited || selectedTags.length > 1)
            "
            (ppDelete)="removeSelectedTag(i)"
          ></pp-tag>
        </ng-container>

        <p
          class="tagSelect__placeholder"
          [class.tagSelect__placeholder--noFaint]="ppPlaceholder"
          *ngIf="showPlaceholder()"
        >
          {{ placeholder }}
        </p>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="!ppOptions.disabled">
    <pp-save-indicator
      *ngIf="ppOptions.showSaveIndicator"
      [ppFocused]="focused"
      [ppFilled]="selectedTags.length > 0"
      [ppShowClearBtn]="ppOptions.showClearBtn"
      [ppUpdating]="ppOptions.showUpdateIndicator"
      (ppAction)="clearAll()"
    ></pp-save-indicator>

    <div class="select__caret" *ngIf="showSelectCaret()">
      <div class="select__icon-container">
        <pp-icon
          [ppSrc]="
            dropdown.visible && dropdown.buttonId === buttonId
              ? EIconPath.ICON_MISC_UP_ARROW_18
              : EIconPath.ICON_MISC_DOWN_ARROW_18
          "
          ppColor="grey-400"
          ppClass="setSize18"
        ></pp-icon>
      </div>
    </div>
  </ng-container>
</div>
