import { Component, Input } from '@angular/core';
import { TAssetLabel } from 'src/app/project/modules/fleet-management/asset-service/asset.consts';

@Component({
  selector: 'pp-asset-card-information-labels',
  templateUrl: './asset-card-information-labels.component.html',
  styleUrls: ['./asset-card-information-labels.component.scss'],
})
export class assetCardInformationLabelsComponent {
  @Input() ppLabels: TAssetLabel[];

  constructor() {}
}
