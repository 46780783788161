<section class="siteIntegration__authorizeConnectionWrapper">
  <section class="siteIntegration__authorizeConnection">
    <header class="siteIntegration__authorizeConnectionHeader">
      {{ headerText }}
    </header>

    <header class="siteIntegration__authorizeConnectionSubheader" *ngIf="subheaderText">
      {{ subheaderText }}
    </header>

    <pp-authorization-progress [ppStep]="ppIntegrationStep"></pp-authorization-progress>

    <div
      class="siteIntegration__authorizeConnectionTimeout"
      *ngIf="ppIntegrationStep === EAuthorizationProgressStep.PENDING"
    >
      <span *ngIf="!timeExpired">
        {{
          'authorize_connection_timeout'
            | ppTranslate
              : {
                  time: remainingTime,
                }
        }}
      </span>
      <span *ngIf="timeExpired">{{ 'integration_authentication_time_out' | ppTranslate }}</span>
    </div>

    <pp-site-settings-integrations-tooltips
      [ppIntegrationStep]="ppIntegrationStep"
      (ppCancel)="cancelConnection()"
      (ppDeactivate)="showDeactivateConnectionModal()"
      [ppDisabled]="ppDisabled"
      [ppSiteName]="siteName"
      [ppAssetName]="assetName"
    ></pp-site-settings-integrations-tooltips>
  </section>

  <pp-button
    *ngIf="ppIntegrationStep === EAuthorizationProgressStep.INITIAL"
    (ppAction)="showAuthorizeConnectionModal()"
    ppStyle="primary"
    ppSize="large"
    [ppDisabled]="ppDisabled"
    >{{ 'authorize_connection' | ppTranslate }}</pp-button
  >
</section>
