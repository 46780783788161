import copyExif from 'src/app/core/helpers/copy-exif.js';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AttachmentCopyExifService {
  constructor() {}

  copyExif(image: File, compressedImage: File): Promise<unknown> {
    return copyExif(image, compressedImage);
  }
}
