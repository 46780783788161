import { EDashletType } from '../dashlets-enums';
import { TNewDashletData } from './new-dashlet-data.model';

export const SIMPLE_DASHLETS: TNewDashletData[] = [
  {
    key: 'activity',
    value: EDashletType.ACTIVITIES,
    icon: 'fas fa-tachometer-alt',
    preview_url: 'assets/images/dashlet-preview/activities-preview.svg',
  },
  {
    key: 'added_comments',
    value: EDashletType.COMMENT_CREATE,
    icon: 'fas fa-comment',
    preview_url: 'assets/images/dashlet-preview/comments.svg',
  },
  {
    key: 'high_priority',
    value: EDashletType.HIGH,
    icon: 'dashletTypes__priority-icon high',
    preview_url: 'assets/images/dashlet-preview/high-priority.svg',
  },
  {
    key: 'medium_priority',
    value: EDashletType.MEDIUM,
    icon: 'dashletTypes__priority-icon medium',
    preview_url: 'assets/images/dashlet-preview/medium-priority.svg',
  },
  {
    key: 'low_priority',
    value: EDashletType.LOW,
    icon: 'dashletTypes__priority-icon low',
    preview_url: 'assets/images/dashlet-preview/low-priority.svg',
  },
  {
    key: 'created_points',
    value: EDashletType.DEFECT_CREATE,
    icon: 'sw-icon sw-icon-new-point',
    preview_url: 'assets/images/dashlet-preview/created-points.svg',
  },
  {
    key: 'open',
    value: EDashletType.OPEN,
    icon: null,
    preview_url: 'assets/images/dashlet-preview/open.svg',
  },
  {
    key: 'in_progress',
    value: EDashletType.IN_PROGRESS,
    icon: null,
    preview_url: 'assets/images/dashlet-preview/in-progress.svg',
  },
  {
    key: 'to_review',
    value: EDashletType.TO_REVIEW,
    icon: null,
    preview_url: 'assets/images/dashlet-preview/to-review.svg',
  },
  {
    key: 'on_hold',
    value: EDashletType.ONHOLD,
    icon: null,
    preview_url: 'assets/images/dashlet-preview/on-hold.svg',
  },
  {
    key: 'completed',
    value: EDashletType.CLOSED,
    icon: null,
    preview_url: 'assets/images/dashlet-preview/completed.svg',
  },
  {
    key: 'canceled',
    value: EDashletType.CANCELED,
    icon: null,
    preview_url: 'assets/images/dashlet-preview/canceled.svg',
  },
];
