import { Component, OnDestroy } from '@angular/core';
import { DeviceService } from '@core/services';
import { Subject, takeUntil } from 'rxjs';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { checkIfHasRole } from 'src/app/project/modules/account/check-if-has-role';
import { FleetManagementRoutesService } from 'src/app/project/modules/fleet-management/fleet-management-routes.service';
import { FleetService } from 'src/app/project/modules/fleet-management/fleet-service/fleet.service';
import { EFleetShareOption } from 'src/app/project/modules/fleet-management/fleet-sharing/fleet-sharing.consts';
import { MAX_AMOUNT_OF_FLEETS_PER_ACCOUNT } from 'src/app/project/modules/fleet-management/fleet.consts';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventFleetManagement,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import {
  TTopBarFleetOptionsFleetSelectDropdownData,
  TTopBarFleetOptionsFleetSelectDropdownSelectOption,
} from './top-bar-fleet-options-fleet-select-dropdown.consts';

@Component({
  selector: 'pp-top-bar-feet-options-fleet-select-dropdown',
  templateUrl: './top-bar-feet-options-fleet-select-dropdown.component.html',
  styleUrls: ['./top-bar-feet-options-fleet-select-dropdown.component.scss'],
})
export class TopBarFeetOptionsFleetSelectDropdownComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  dropdown: TDropdown = this.dropdownService.getDropdown();
  items: TTopBarFleetOptionsFleetSelectDropdownSelectOption[];
  selectedItem: TTopBarFleetOptionsFleetSelectDropdownSelectOption;
  isMobile = false;
  filteredItems: TTopBarFleetOptionsFleetSelectDropdownSelectOption[] = [];
  searchedKeyword = '';
  EIconPath = EIconPath;
  createButtonEnabled = false;

  constructor(
    private dropdownService: DropdownService,
    private deviceService: DeviceService,
    private fleetManagementRoutesService: FleetManagementRoutesService,
    private fleetService: FleetService,
    private accountService: AccountService,
    private workspaceService: WorkspaceService,
  ) {
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit(): void {
    this.setDataFromDropdown();

    this.dropdownService.dropdownDataChanged$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setDataFromDropdown();
    });

    this.filterItems();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  hideDropdown(): void {
    this.dropdownService.hideDropdown();
  }

  selectItem(item: string | TTopBarFleetOptionsFleetSelectDropdownSelectOption): void {
    logEventInGTAG(EGoogleEventFleetManagement.FLEET_SELECT, {
      event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
    });

    this.dropdown.callback(item);

    this.hideDropdown();
  }

  setKeyword(keyword: string): void {
    this.searchedKeyword = keyword;

    logEventInGTAG(EGoogleEventFleetManagement.FLEET_SEARCH, {
      event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
    });

    this.filterItems();
  }

  createNewFleet(): void {
    this.fleetService.setActiveFleetId(null);
    this.fleetManagementRoutesService.goToNewFleetPage();
    this.dropdownService.hideDropdown();
  }

  private filterItems(): void {
    const filteredItems = [];

    this.items.forEach((item) => {
      if (item.label.toLowerCase().includes(this.searchedKeyword.toLowerCase())) {
        filteredItems.push(item);
      }
    });

    this.filteredItems = filteredItems;
  }

  private setDataFromDropdown(): void {
    const dropdownData: TTopBarFleetOptionsFleetSelectDropdownData = this.dropdown.data;

    this.items = dropdownData.items;
    this.selectedItem = dropdownData.selectedItem;

    this.createButtonEnabled = this.checkIfCanCreateAnotherAccount();
  }

  private checkIfCanCreateAnotherAccount(): boolean {
    const isOwner = this.checkIfOwner();

    if (isOwner) {
      return true;
    }

    const numberOfAccountAdminAccounts = this.countAccountsWithAccountAdminRole();
    const ownedFleets = this.countOwnedFleets();

    return numberOfAccountAdminAccounts * MAX_AMOUNT_OF_FLEETS_PER_ACCOUNT > ownedFleets.length;
  }

  private countOwnedFleets(): TTopBarFleetOptionsFleetSelectDropdownSelectOption[] {
    return this.items.filter((item) => {
      const fleet = this.fleetService.getFleet(item.value);

      return fleet.fleetShareOption === EFleetShareOption.EDIT;
    });
  }

  private countAccountsWithAccountAdminRole(): number {
    const accounts = this.accountService.getAccounts();

    let numberOfAccountAdminAccounts = 0;

    for (const account of accounts) {
      const isAccountAdmin = checkIfHasRole(
        EUserRole.ACCOUNT_ADMIN,
        account?.workspaces,
        this.workspaceService.getWorkspaces(),
      );

      const fleetManagementEnabled = account.accountFeatures.fleetManagement;

      if (isAccountAdmin && fleetManagementEnabled) {
        numberOfAccountAdminAccounts++;
      }
    }
    return numberOfAccountAdminAccounts;
  }

  private checkIfOwner(): boolean {
    const accounts = this.accountService.getAccounts();

    for (const account of accounts) {
      const isOwner = checkIfHasRole(
        EUserRole.OWNER,
        account?.workspaces,
        this.workspaceService.getWorkspaces(),
      );

      if (isOwner) {
        return true;
      }
    }

    return false;
  }
}
