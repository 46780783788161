import { Injectable } from '@angular/core';

import { ResponseErrorService } from '../errors/response-error.service';

import { AuthApiProviderService } from '@core/api';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { setUserAuth } from 'src/app/core/http/user-auth';
import { clearObjectStores } from '../../helpers/database/database';
import { AUTH_COOKIE_REMEMBER_DAYS } from '../../shared/constants/auth.constants';
import { TLoggedResponse } from '../../view-models/logged-response-model';
import { TLoginResponse } from '../../view-models/login-response-model';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private responseErrorService: ResponseErrorService,
    private authApiProviderService: AuthApiProviderService,
  ) {}

  login({
    email,
    password,
    remember,
    twoFactorAuthCode,
  }: {
    email: string;
    password: string;
    remember: boolean;
    twoFactorAuthCode?: string;
  }): Observable<TLoggedResponse> {
    const params = new FormData();

    params.append('email', email);
    params.append('passwordHash', password);

    if (twoFactorAuthCode) {
      params.append('twoFactorAuthCode', twoFactorAuthCode);
    }

    return this.authApiProviderService.login(params).pipe(
      switchMap((response) => this.processLoginResponse(response, remember)),
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  saveToken(authToken: string, email: string, remember: boolean): void {
    const expirationDate = this.getExpirationDate(remember);

    setUserAuth({ email, token: authToken, expiration: expirationDate });
  }

  private processLoginResponse(
    response: TLoginResponse,
    remember: boolean,
  ): Observable<TLoggedResponse> {
    this.saveToken(response.authToken, response.user, remember);
    clearObjectStores();

    return this.authApiProviderService.getLoggedUser().pipe(
      catchError((error) => {
        this.responseErrorService.handleRequestError(error);

        throw error;
      }),
    );
  }

  private getExpirationDate(remember: boolean): string {
    const currentDate = new Date();

    if (remember) {
      return new Date(currentDate.setDate(currentDate.getDate() + AUTH_COOKIE_REMEMBER_DAYS))
        .getTime()
        .toString();
    } else {
      // Do we want to make refresh expiration date when used opens app while still being logged in so it doesn't log them while using the app?
      return new Date(currentDate.setDate(currentDate.getDate() + 1)).getTime().toString();
    }
  }
}
