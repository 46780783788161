import { createElement } from 'src/app/core/helpers/dom';
import { GET_TIMELINE_COLUMNS } from '../timeframes/timeline-timeframes';
import { GET_TIMELINE } from '../timeline.ui.store';
import { createTimelineHeadCellTypeElementTop } from './create-timeline-head-cell-type-element-top';

export function createTimelineTopElement(): HTMLElement {
  const timeline = GET_TIMELINE();
  const months = GET_TIMELINE_COLUMNS();

  return createElement('div', {
    attrs: {
      class: 'timeline__head__row',
      style: {
        width: `${timeline.width}px`,
      },
    },
    eventListeners: {
      wheel: (_event) => {
        // TODO: detect Shift key
        const newLeft = timeline.timelineHead.element.scrollLeft - _event.wheelDeltaX;

        timeline.timelineBody.virtualScroller.disableSmoothScrolling();
        timeline.timelineHead.scrollLeft(newLeft);
        timeline.timelineBody.virtualScroller.scrollLeft(newLeft);
        timeline.timelineBody.virtualScroller.enableSmoothScrolling();
      },
    },
    children: [
      ...months.topElements.flatMap((_column, _index) =>
        createTimelineHeadCellTypeElementTop(_column),
      ),
    ],
  });
}
