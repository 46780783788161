import { Injectable } from '@angular/core';
import { API_tags_used } from '@core/api/paths';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { TTag } from './tag-usage.model';

@Injectable({
  providedIn: 'root',
})
export class TagsApiProviderService {
  constructor(private apiService: ApiService) {}

  fetchTagUsage(workspaceId: string): Observable<TTag[]> {
    const url = API_tags_used(workspaceId);

    return this.apiService.get<TTag[]>(url);
  }
}
