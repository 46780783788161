import { getTimelineGroupColumnWidth } from '../resizer-width';
import { TPreferences } from 'src/app/project/modules/preferences/preferences.model';
import { TTimelineColumns } from '../../timeframes/timeline-timeframes';

export function getNewTimelineElementPosition(
  element: HTMLElement,
  preferences: TPreferences,
  elementRect: DOMRect,
  timelineData: TTimelineColumns,
): {
  newStartDate: number;
  newEndDate: number;
} {
  const groupWidth = getTimelineGroupColumnWidth();
  const style = window.getComputedStyle(element);
  const matrix = new WebKitCSSMatrix(style.transform);
  const marginLeft = parseFloat(style.marginLeft.replace('px', ''));
  const newStartPosition = marginLeft + matrix.m41 - (preferences.timeline?.group ? groupWidth : 0);
  const newEndPosition = newStartPosition + elementRect.width;
  const nearestTimeframeIndex = Math.round(newStartPosition / timelineData.bottomWidth);
  const nearestTimeframeIndexEnd = Math.round(newEndPosition / timelineData.bottomWidth);
  const endTileOffset = 2;
  const positionOffset = 1;

  const newStartDate = timelineData.timeframes[nearestTimeframeIndex - positionOffset];
  const newEndDate = timelineData.timeframes[nearestTimeframeIndexEnd - endTileOffset];

  return { newStartDate, newEndDate };
}
