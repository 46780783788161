import { Inject, Injectable } from '@angular/core';
import i18next from 'i18next';
import englishTranslation from '../../project/features/translate/english.json';
import { EInstance } from '../shared/enums/instances';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  constructor(@Inject('HOSTNAME') private host: string) {}

  initTranslations(): void {
    i18next.init({
      lng: 'en',
      debug: this.checkTestEnvironment(),
      resources: {
        en: englishTranslation,
      },
    });
  }

  private checkTestEnvironment(): boolean {
    return this.host !== EInstance.LIVE && this.host !== EInstance.ASM;
  }
}
