import { createElement } from 'src/app/core/helpers/dom';
import { getChartName } from './dashboard-export-elements/dashboard-export-chart-name';

export function createDashletHeaderElement(accountName, workspaceName, chartType, scale) {
  const dashletHeader = createElement('div');

  dashletHeader.style.position = 'absolute';
  dashletHeader.style.left = '50%';
  dashletHeader.style.transform = 'translateX(-50%)';
  dashletHeader.style.fontFamily = 'Source Sans Pro, sans-serif';
  dashletHeader.style.top = `${100 * scale}px`;
  dashletHeader.style.textAlign = 'center';

  const chartNameElement = createElement('div');

  chartNameElement.style.fontSize = `${100 * scale}px`;
  chartNameElement.style.lineHeight = `${125 * scale}px`;
  chartNameElement.style.color = '#000000';
  chartNameElement.style.fontFamily = 'Source Sans Pro, sans-serif';
  chartNameElement.style.whiteSpace = 'nowrap';
  chartNameElement.style.maxWidth = `${2000 * scale}px`;
  chartNameElement.style.overflow = 'hidden';
  chartNameElement.style.textOverflow = 'ellipsis';
  chartNameElement.style.fontWeight = '400';

  const selectedRangeElement = createElement('div');

  selectedRangeElement.style.fontSize = `${72 * scale}px`;
  selectedRangeElement.style.lineHeight = `${90 * scale}px`;
  selectedRangeElement.style.color = '#97A3B7';
  selectedRangeElement.style.fontFamily = 'Source Sans Pro, sans-serif';
  selectedRangeElement.style.fontWeight = '600';
  selectedRangeElement.style.whiteSpace = 'nowrap';
  selectedRangeElement.style.maxWidth = `${2000 * scale}px`;
  selectedRangeElement.style.overflow = 'hidden';
  selectedRangeElement.style.textOverflow = 'ellipsis';

  chartNameElement.innerHTML = getChartName(chartType);
  selectedRangeElement.innerHTML = accountName + ' - ' + workspaceName;

  dashletHeader.appendChild(chartNameElement);
  dashletHeader.appendChild(selectedRangeElement);

  return dashletHeader;
}
