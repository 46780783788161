<div class="settingsAccountButtons">
  <div class="settingsAccountButtons__archivedToggle" *ngIf="canSeeArchived">
    <pp-toggle
      ppTooltip
      [ppTitle]="(showArchived ? 'archived_sites_visible' : 'archived_sites_hidden') | ppTranslate"
      [ppChecked]="showArchived"
      ppStyle="filter"
      [ppReverseLayout]="true"
      (ppAction)="toggleShowArchivedData()"
    >
      <span class="toggleButtonText"> {{ 'show_archived_sites' | ppTranslate }} </span>
    </pp-toggle>
  </div>

  <div class="settingsAccountButtons__searchBox">
    <pp-search-box
      [ppPlaceholder]="'search_sites' | ppTranslate"
      ppSize="wide"
      (ppAction)="trySetKeyword($event)"
    ></pp-search-box>
  </div>

  <pp-button
    ppStyle="primary"
    ppSize="large"
    ppId="user_management_new_user_button"
    (ppAction)="newSite()"
  >
    {{ 'add_new_site' | ppTranslate }}
  </pp-button>
</div>
