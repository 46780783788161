import { Component } from '@angular/core';
import { SharesApiProviderService } from '@core/api';
import { Observable, catchError, finalize, of, tap } from 'rxjs';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { ChangeLimitModalComponent } from 'src/app/project/components/change-limit-modal/change-limit-modal.component';
import { TChangeLimitsModalData } from 'src/app/project/components/change-limit-modal/change-limit-modal.model';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { getErrorMessage } from 'src/app/project/helpers/database/get-error-message';
import { ResponseErrorService } from '../../errors/response-error.service';
import { AccountSharesService } from '../../share/account-shares.service';
import { EUserRole } from '../../share/share-utils/user-roles';
import { TCreateAccountAdminDTO } from '../../share/share.model';
import { TWorkspacesById } from '../../workspace/workspace.model';
import { WorkspaceService } from '../../workspace/workspace.service';
import { AccountService } from '../account-service/account.service';
import { TAccount, TSimulatedUsageCreateShare } from '../account.model';
import { TAddAccountAdminModalData } from './new-account-admin-modal.consts';

@Component({
  selector: 'pp-new-account-admin-modal',
  templateUrl: './new-account-admin-modal.component.html',
  styleUrls: ['./new-account-admin-modal.component.scss'],
})
export class NewAccountAdminModalComponent {
  processing: boolean;
  account: TAccount;
  workspaces: TWorkspacesById;
  upgradingUser = false;
  showingConfirmation: boolean;
  warningMessages: string[] = [];
  emailList: string[] = [];

  private accountId: string;

  constructor(
    private modalService: ModalService,
    private accountService: AccountService,
    private workspaceService: WorkspaceService,
    private translationPipe: TranslationPipe,
    private sharesApiProviderService: SharesApiProviderService,
    private promptService: PromptService,
    private responseErrorService: ResponseErrorService,
    private accountSharesService: AccountSharesService,
  ) {
    this.setModalData();
    this.setWarningMessages();

    this.account = this.accountService.getAccount(this.accountId);
    this.workspaces = this.workspaceService.getWorkspaces();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  setEmailList(emailList: string[]): void {
    this.emailList = emailList;
  }

  goToConfirmStep(): void {
    this.showingConfirmation = true;
  }

  addAccountAdmins(): void {
    if (this.processing) {
      return;
    }

    this.sendAddAccountAdminRequest(true);
  }

  private sendAddAccountAdminRequest(hideModal: boolean): Promise<boolean> {
    return new Promise((resolve) => {
      this.processing = true;

      const requestBody: TCreateAccountAdminDTO[] = this.getRequestBody();

      this.sharesApiProviderService
        .createAccountAdmin(requestBody)
        .pipe(
          tap(() => {
            this.handleCreateAccountAdminResponse(hideModal);
            resolve(true);
          }),
          catchError((error) => {
            return this.handleCreateAccountAdminError(error);
          }),
          finalize(() => {
            this.processing = false;
          }),
        )
        .subscribe();
    });
  }

  private getRequestBody(): TCreateAccountAdminDTO[] {
    return this.emailList.map((email) => {
      return {
        email,
      };
    });
  }

  private handleCreateAccountAdminError(error: any): Observable<null> {
    if (error.status === EStatusCode.UPGRADE_REQUIRED) {
      this.handleUpgradeRequiredError();

      return of(null);
    } else {
      getErrorMessage(error).then((message) => {
        this.promptService.showError(message);
      });

      return this.responseErrorService.handleRequestError(error);
    }
  }

  private handleUpgradeRequiredError(): void {
    const createList: TSimulatedUsageCreateShare[] = this.emailList.map((email) => {
      return {
        shareOption: EUserRole.ACCOUNT_ADMIN,
        userEmail: email,
      };
    });

    this.modalService.hideModal().then(() => {
      this.modalService.setData<TChangeLimitsModalData>({
        firstMessageKey: 'users_limit_reached_description_1',
        secondMessageKey: 'users_limit_reached_description_2',
        confirmKey: 'add_user',
        header: 'users_limit_reached',
        accountId: this.accountId,
        changedLimits: {
          createList,
          editList: [],
          importList: [],
          deleteList: [],
        },
      });

      this.modalService.showModal(ChangeLimitModalComponent, {
        onBeforeClose: (cancelled) => this.handleUpgradeRequiredErrorCallback(cancelled),
      });
    });
  }

  private handleUpgradeRequiredErrorCallback(cancelled: boolean): Promise<boolean> {
    if (!cancelled) {
      return this.sendAddAccountAdminRequest(false);
    }

    return Promise.resolve(true);
  }

  private handleCreateAccountAdminResponse(hideModal = true): void {
    const prompt = this.translationPipe.transform('new_account_admin_success');
    this.promptService.showSuccess(prompt);
    this.accountSharesService.resetAccountShares(this.accountId).subscribe();

    if (hideModal) {
      this.hideModal();
    }
  }

  private checkUpgradingUser(modalData: TAddAccountAdminModalData): void {
    this.upgradingUser = !!modalData.user;

    if (this.upgradingUser) {
      this.emailList = [modalData.user.email];
    }
  }

  private setWarningMessages(): void {
    this.warningMessages = [this.translationPipe.transform('new_account_admin_warning')];
  }

  private setModalData(): void {
    const modalData: TAddAccountAdminModalData = this.modalService.getModal().data;

    this.accountId = modalData.accountId;

    this.checkUpgradingUser(modalData);
  }
}
