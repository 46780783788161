<div class="filterDropdown__section filterDropdown__section--location">
  <div class="filterDropdown__heading">
    <h6>{{ 'location' | ppTranslate }}</h6>

    <button
      class="filterDropdown__clear--text"
      *ngIf="!ppFilters.showPointsWithLocation || !ppFilters.showPointsWithoutLocation"
      (click)="clearLocation()"
      role="button"
    >
      {{ 'clear' | ppTranslate }}
    </button>
  </div>

  <div class="filterDropdown__content filterDropdown__checkbox filterDropdown__checkbox--location">
    <pp-checkbox
      class="customField__toggle"
      [ppChecked]="ppFilters.showPointsWithLocation"
      (ppOnValueChange)="togglePointsWithLocation()"
      ppType="solid"
    >
      {{ 'points_with_location' | ppTranslate }}
    </pp-checkbox>
  </div>

  <div class="filterDropdown__content filterDropdown__checkbox filterDropdown__checkbox--location">
    <pp-checkbox
      class="customField__toggle"
      [ppChecked]="ppFilters.showPointsWithoutLocation"
      (ppOnValueChange)="togglePointsWithoutLocation()"
      ppType="solid"
    >
      {{ 'points_without_location' | ppTranslate }}
    </pp-checkbox>
  </div>

  <hr />
</div>
