import { cloneDeep } from 'lodash';
import { UpdatePointCustomField } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';
import { updateStorePointCustomField } from './update-store-point-custom-field';

export function updatePointCustomFields(
  oldState: TPointsByWorkspace,
  action: UpdatePointCustomField,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);

  return updateStorePointCustomField(state, action.payload);
}
