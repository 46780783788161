import { Component } from '@angular/core';
import { EIconPath } from '../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-supported-browsers-page',
  templateUrl: './supported-browsers-page.component.html',
  styleUrls: ['./supported-browsers-page.component.scss'],
})
export class SupportedBrowsersPageComponent {
  recommendedBrowsers: TBrowsers[] = [
    {
      browserName: 'Google Chrome',
      imageSrc: EIconPath.BROWSER_CHROME,
    },
    {
      browserName: 'Mozilla Firefox',
      imageSrc: EIconPath.BROWSER_FIREFOX,
    },
    {
      browserName: 'Opera',
      imageSrc: EIconPath.BROWSER_OPERA,
    },
  ];

  supportedBrowsers: TBrowsers[] = [
    {
      browserName: 'Microsoft Edge',
      imageSrc: EIconPath.BROWSER_EDGE,
    },
    {
      browserName: 'Safari',
      imageSrc: EIconPath.BROWSER_SAFARI,
    },
  ];

  EIconPath = EIconPath;
}

export type TBrowsers = {
  browserName: string;
  imageSrc: string;
};
