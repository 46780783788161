<div class="base" [class.base__blurred]="routerPath.startsWith('/reminders/(full:point')">
  <pp-supported-browsers-bar></pp-supported-browsers-bar>
  <pp-action-bar></pp-action-bar>
  <pp-reminder-alert></pp-reminder-alert>
  <pp-top-bar></pp-top-bar>
  <pp-side-panel></pp-side-panel>

  <div id="reminderContent" class="remindersContent" [class.remindersContent__down]="ui.actionBar">
    <div id="siteHeader" data-m-target="Work list menu" class="reminders__header">
      <pp-reminders-options
        [ppDataFetched]="dataFetched && filterDataFetched"
      ></pp-reminders-options>
    </div>

    <div class="remindersContent__page">
      <pp-reminder-list
        class="remindersList"
        [ppFetched]="dataFetched"
        [ppOffline]="offline"
        [class.remindersList__point]="routerPath !== '/reminders'"
        [class.remindersList--hidden]="!dataFetched"
      ></pp-reminder-list>

      <pp-reminders-placeholder *ngIf="!dataFetched"></pp-reminders-placeholder>

      <div
        class="reminderPointContainer"
        *ngIf="dataFetched"
        [class.reminderPointContainer--hidden]="routerPath === '/reminders'"
      >
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<router-outlet name="full"></router-outlet>
