import { Component, EventEmitter, Input, Output } from '@angular/core';
import { uuid } from '@core/helpers';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { UsersDropdownComponent } from 'src/app/project/modules/users/users-dropdown/users-dropdown.component';
import { TUsersDropdownData } from 'src/app/project/modules/users/users-dropdown/users-dropdown.model';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-asset-label-user-picker',
  templateUrl: './asset-label-user-picker.component.html',
  styleUrls: ['./asset-label-user-picker.component.scss'],
})
export class AssetLabelUserPickerComponent {
  @Input() ppAssigneeId: string;
  @Output() ppAssigneeIdChange: EventEmitter<string> = new EventEmitter<string>();

  elementId = uuid();
  dropdown = this.dropdownService.getDropdown();
  users = this.usersService.getUsers();
  EIconPath = EIconPath;

  constructor(private dropdownService: DropdownService, private usersService: UsersService) {}

  toggleDropdown(): void {
    if (this.dropdown.visible && this.dropdown.buttonId === this.elementId) {
      this.dropdownService.hideDropdown();
    } else {
      this.updateDropdownData();

      this.dropdownService.showDropdown(this.elementId, UsersDropdownComponent, {
        callback: (id) => this.selectUserCallback(id),
        suppressScrollbar: true,
      });
    }
  }

  private selectUserCallback(userId: string): void {
    this.ppAssigneeId = userId;
    this.ppAssigneeIdChange.emit(userId);

    this.dropdownService.hideDropdown();
  }

  private updateDropdownData(): void {
    const allUsers = Object.keys(this.usersService.getUsers());

    const dropdownData: TUsersDropdownData = {
      unselectedUsers: allUsers,
      disabled: false,
      type: 'users',
      showToggle: false,
      allUsers: allUsers,
    };

    this.dropdownService.setData(dropdownData);
  }
}
