import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TDashletSiteRange } from 'src/app/project/modules/preferences/preferences.model';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { MAX_STACKED_STATUS_WORKSPACES_LENGTH } from '../../../../utils/dashboard-consts';

@Component({
  selector: 'pp-dashlet-timeline-single-workspace',
  templateUrl: './dashlet-timeline-single-workspace.component.html',
  styleUrl: './dashlet-timeline-single-workspace.component.scss',
})
export class DashletTimelineSingleWorkspaceComponent implements OnChanges {
  @Input() ppWorkspace: TWorkspace;
  @Input() ppSiteRange: TDashletSiteRange;
  @Input() ppLimitedWorkspaces: boolean;
  @Output() ppToggleWorkspace = new EventEmitter<string>();

  MAX_STACKED_STATUS_WORKSPACES_LENGTH = MAX_STACKED_STATUS_WORKSPACES_LENGTH;
  disabled: boolean;

  ngOnChanges(): void {
    this.disabled = this.checkDisabled();
  }

  toggleWorkspace(): void {
    this.ppToggleWorkspace.emit(this.ppWorkspace.workspaceId);
  }

  checkDisabled(): boolean {
    if (!this.ppLimitedWorkspaces) {
      return false;
    }

    const alreadySelected = this.ppSiteRange.workspaceIds.includes(this.ppWorkspace.workspaceId);

    if (alreadySelected) {
      return false;
    }

    return this.ppSiteRange.workspaceIds.length >= MAX_STACKED_STATUS_WORKSPACES_LENGTH;
  }
}
