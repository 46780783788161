import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

import { createElement } from '@core/helpers';
import { createAccountElement } from './dashboard-export-elements/dashboard-account-element';
import { getBackgroundColor } from './dashboard-export-elements/dashboard-background-color';
import { createDateElement } from './dashboard-export-elements/dashboard-date-elements';
import { createFooterElement } from './dashboard-export-elements/dashboard-footer-element';
import { createValueElement } from './dashboard-export-elements/dashboard-value-element';

dayjs.extend(weekday);

export function generateSimpleDashlet({
  type,
  period,
  accountName,
  workspaceName,
  value,
  width,
  height,
  scale,
}) {
  const dashletElement = createElement('div', {
    children: [
      createAccountElement(accountName, workspaceName, scale),
      createDateElement(period, scale),
      createValueElement(value, scale),
      createFooterElement(type, scale),
    ],
  });

  const backgroundColor = getBackgroundColor(type);

  dashletElement.style.width = `${width * scale}px`;
  dashletElement.style.height = `${height * scale}px`;
  dashletElement.style.backgroundColor = backgroundColor;
  dashletElement.style.borderRadius = `${40 * scale}px`;
  dashletElement.style.fontFamily = 'Source Sans Pro, sans-serif';
  dashletElement.style.position = 'relative';

  return dashletElement;
}
