import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pp-modal-generic-footer',
  templateUrl: './modal-generic-footer.component.html',
  styleUrls: ['./modal-generic-footer.component.scss'],
})
export class ModalGenericFooterComponent {
  @Input() ppConfirmText: string;
  @Input() ppProcess = false;
  @Input() ppDisabled = false;
  @Input() ppConfirmDataTarget: string = null;
  @Input() ppCancelDataTarget: string = null;
  @Input() ppConfirmTooltip: string = null;
  @Input() ppError = false;
  @Input() ppHideCancel = false;

  @Output() ppCancel = new EventEmitter();
  @Output() ppConfirm = new EventEmitter();

  constructor() {}

  confirm(): void {
    this.ppConfirm.emit();
  }

  cancel(): void {
    this.ppCancel.emit();
  }
}
