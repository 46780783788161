<div
  id="statusButton"
  (click)="action()"
  class="statusButton"
  [class.statusButton--disabled]="ppDisabled"
>
  <span class="statusButton__text">
    <img
      class="statusIcon statusIcon--sm statusIcon--white icon-gap--right"
      [src]="ppStatus | ppStatusImage: true"
    />

    <span id="statusName">{{ statusName }}</span>
  </span>
</div>
