import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { IconModule } from '../icon/icon-module';
import { UserChipModule } from '../user-chip/user-chip.module';
import { UserPickerComponent } from './user-picker.component';

@NgModule({
  declarations: [UserPickerComponent],
  imports: [CommonModule, UserChipModule, IconModule, PipesModule],
  exports: [UserPickerComponent],
})
export class UserPickerModule {}
