<section class="exportOrientation">
  <header class="exportOrientationHeader">
    {{ 'asset_export_orientation' | ppTranslate }}

    <pp-info-icon
      ppTooltip
      [ppTitle]="'asset_export_orientation_tooltip' | ppTranslate"
    ></pp-info-icon>
  </header>

  <div class="exportOrientationButtons">
    <div class="exportOrientationButton" (click)="selectMode(EExportOrientation.PORTRAIT)">
      <pp-radio [ppChecked]="ppPageOrientation === EExportOrientation.PORTRAIT"></pp-radio>

      {{ 'portrait' | ppTranslate }}
    </div>

    <div class="exportOrientationButton" (click)="selectMode(EExportOrientation.LANDSCAPE)">
      <pp-radio [ppChecked]="ppPageOrientation === EExportOrientation.LANDSCAPE"></pp-radio>

      {{ 'landscape' | ppTranslate }}
    </div>
  </div>
</section>
