import { Component, Input, OnChanges } from '@angular/core';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';

@Component({
  selector: 'pp-user-management-table-footer',
  templateUrl: './user-management-table-footer.component.html',
  styleUrls: ['./user-management-table-footer.component.scss'],
})
export class UserManagementTableFooterComponent implements OnChanges {
  @Input() ppNumberOfUsers: number;

  footerMessage: string;

  constructor(private translationPipe: TranslationPipe) {}

  ngOnChanges(): void {
    this.generateFooterMessage();
  }

  generateFooterMessage(): void {
    if (this.ppNumberOfUsers === 1) {
      this.footerMessage = this.translationPipe.transform('user_management_user_message_one', {
        count: this.ppNumberOfUsers,
      });
    } else {
      this.footerMessage = this.translationPipe.transform('user_management_user_message_other', {
        count: this.ppNumberOfUsers,
      });
    }
  }
}
