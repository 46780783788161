import { humanizePriority } from 'src/app/project/modules/points/priorities';
import { humanizeStatus } from 'src/app/project/modules/points/statuses';
import { transformDate } from 'src/app/project/shared/date-transformer';
import { EBasicField, EBasicFieldShort } from 'src/app/project/shared/enums/basic-fields-enums';
import { transformAssigneesValue } from './transform-assignees-value';
import { transformDefaultValue } from './transform-default-value';
import { transformDescriptionValue } from './transform-description-value';
import { transformFlagValue } from './transform-flag-value';
import { transformTagsValue } from './transform-tags-value';
import { transformUserValue } from './transform-user-value';

export function transformValue(columnName: string, columnValue: any): string {
  // Column value is any due to bad logic that calls this
  switch (columnName) {
    case EBasicField.CREATED:
      return transformDate({ value: columnValue.createdEpochMillis, localTime: false });
    case EBasicField.UPDATED:
      return transformDate({ value: columnValue.updatedEpochMillis, localTime: false });
    case EBasicField.CREATED_BY:
      return transformUserValue(columnValue);
    case EBasicField.ASSIGNEES:
      return transformAssigneesValue(columnValue);
    case EBasicField.TAGS:
      return transformTagsValue(columnValue);
    case EBasicField.DESCRIPTION:
      return transformDescriptionValue(columnValue);
    case EBasicField.STATUS:
      return humanizeStatus(columnValue);
    case EBasicField.PRIORITY:
      return humanizePriority(columnValue);
    case EBasicField.FLAGGED:
    case EBasicFieldShort.FLAGGED:
      return transformFlagValue(columnValue);
    default:
      return transformDefaultValue(columnValue);
  }
}
