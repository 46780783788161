<div class="noAssetSearchResultPage">
  <div class="noSearchResultIcon">
    <pp-icon
      [ppSrc]="EIconPath.ICON_SEARCH_ASSET_NO_RESULT"
      [ppDisplayContents]="true"
      ppColor="inherit"
    ></pp-icon>
  </div>

  <div class="noSearchResultText">
    <header class="noSearchResultText__header">
      {{ 'no_asset_search_result_header' | ppTranslate }}
    </header>

    <span class="noSearchResultText__subheader">
      {{ 'no_asset_search_result_subheader' | ppTranslate }}
    </span>
  </div>
</div>
