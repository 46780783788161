import { Action } from '@ngrx/store';

import { TUser } from './user.model';

export enum EActionType {
  SET_USER = '[User] Set',
  CLEAR_USER = '[User] Clear',
  SET_UPDATING = '[User] Updating',
  SET_AVATAR_ID = '[User] Set Avatar ID',
  UPDATE_USER_NAME = '[User] Update userName',
  UPDATE_USER_TYPE = '[User] Update userType',
  UPDATE_ACTIVE_WORKSPACE_ID = '[User] Update activeWorkspaceId',
  UPDATE_USER_UNREAD_NOTIFICATIONS = '[User] Update Unread Notifications',
  UPDATE_USER_TWO_FACTOR_AUTHENTICATION = '[User] Update Two Factor Authentication',
  UPDATE_USER_EMAIL = '[User] Update Email',
}

export class SetUser implements Action {
  readonly type = EActionType.SET_USER;
  constructor(readonly payload: TUser) {}
}

export class ClearUser implements Action {
  readonly type = EActionType.CLEAR_USER;
  constructor() {}
}

export class SetAvatarId implements Action {
  readonly type = EActionType.SET_AVATAR_ID;
  constructor(readonly payload: TUser['avatarId']) {}
}

export class UpdateUserName implements Action {
  readonly type = EActionType.UPDATE_USER_NAME;
  constructor(
    readonly payload: {
      userName: TUser['userName'];
    },
  ) {}
}

export class UpdateUserEmail implements Action {
  readonly type = EActionType.UPDATE_USER_EMAIL;
  constructor(
    readonly payload: {
      email: TUser['email'];
    },
  ) {}
}

export class UpdateUserUnreadNotifications implements Action {
  readonly type = EActionType.UPDATE_USER_UNREAD_NOTIFICATIONS;
  constructor(readonly payload: boolean) {}
}

export class UpdateUserType implements Action {
  readonly type = EActionType.UPDATE_USER_TYPE;
  constructor(
    readonly payload: {
      userType: TUser['userType'];
    },
  ) {}
}

export class UpdateActiveWorkspaceId implements Action {
  readonly type = EActionType.UPDATE_ACTIVE_WORKSPACE_ID;
  constructor(
    readonly payload: {
      activeWorkspaceId: TUser['activeWorkspaceId'];
    },
  ) {}
}

export class UpdateUserTwoFactorAuthentication implements Action {
  readonly type = EActionType.UPDATE_USER_TWO_FACTOR_AUTHENTICATION;
  constructor(
    readonly payload: {
      enabled2fa: TUser['enabled2fa'];
    },
  ) {}
}

export type ActionsUnion =
  | SetUser
  | ClearUser
  | SetAvatarId
  | UpdateUserName
  | UpdateUserType
  | UpdateActiveWorkspaceId
  | UpdateUserUnreadNotifications
  | UpdateUserTwoFactorAuthentication
  | UpdateUserEmail;
