import { TWorkspacesById } from '../workspace.model';

export function searchWorkspace(
  workspacesIds: string[],
  filter: string,
  allWorkspaces: TWorkspacesById,
): string[] {
  if (!workspacesIds || !filter) {
    return workspacesIds;
  }

  const filteredWorkspaces = workspacesIds.filter((workspaceId) => {
    const workspace = allWorkspaces[workspaceId];

    return (
      workspace.siteName && workspace.siteName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  });

  if (filteredWorkspaces.length === 0) {
    return workspacesIds;
  } else {
    return filteredWorkspaces;
  }
}
