import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { createCustomScroller } from 'src/app/core/helpers/create-custom-scroller';
import { TUsersDropdownType } from '../../modules/users/users-dropdown/users-dropdown-data.enum';
import { UsersDropdownComponent } from '../../modules/users/users-dropdown/users-dropdown.component';
import { TUsersDropdownData } from '../../modules/users/users-dropdown/users-dropdown.model';
import { EIconPath } from '../../shared/enums/icons.enum';
import { TAllUsers } from '../../view-models/user.view-model';
import { DropdownService } from '../dropdown/dropdown-service/dropdown.service';
import { TDropdown } from '../dropdown/dropdown.consts';

@Component({
  selector: 'pp-user-picker',
  templateUrl: './user-picker.component.html',
  styleUrls: ['./user-picker.component.scss'],
})
export class UserPickerComponent implements OnChanges {
  @ViewChild('userList') userListElement: ElementRef;
  @Input() ppAllUsers: string[];
  @Input() ppSelectedUsers: string[];
  @Input() ppId: string;
  @Input() ppType: TUsersDropdownType;
  @Input() ppDisabled = false;
  @Output() ppSelectUserCallback = new EventEmitter<string>();
  @Output() ppRemoveUserCallback = new EventEmitter<string>();
  @Output() ppClearAll = new EventEmitter<void>();
  dropdown: TDropdown = this.dropdownService.getDropdown();
  private dropdownVisible = false;
  buttonId: string;
  users: TAllUsers;
  unselectedUsers: string[];
  EIconPath = EIconPath;

  constructor(private dropdownService: DropdownService) {}

  ngOnChanges(): void {
    this.buttonId = 'user-picker' + this.ppId;

    if (this.dropdown.visible && this.dropdown.buttonId === this.buttonId) {
      this.updateDropdownData();
    }
  }

  ngAfterViewInit(): void {
    createCustomScroller('os-theme-input', this.userListElement.nativeElement);
  }

  toggleAddUserDropdown(buttonId: string): void {
    if (this.dropdown.visible && this.dropdown.buttonId === buttonId) {
      this.dropdownVisible = false;
      this.dropdownService.hideDropdown();
    } else {
      this.updateDropdownData();

      this.dropdownVisible = true;

      this.dropdownService.showDropdown(buttonId, UsersDropdownComponent, {
        callback: (id) => this.selectUserCallback(id),
        onClose: () => (this.dropdownVisible = false),
        suppressScrollbar: true,
      });
    }
  }

  private updateDropdownData(): void {
    this.unselectedUsers = this.ppAllUsers.filter((user) => !this.ppSelectedUsers.includes(user));

    const dropdownData: TUsersDropdownData = {
      unselectedUsers: this.unselectedUsers,
      disabled: false,
      type: this.ppType,
      showToggle: false,
      allUsers: this.ppAllUsers,
    };

    this.dropdownService.setData(dropdownData);
  }

  selectUserCallback(userId: string): void {
    if (!userId) {
      this.ppClearAll.emit();
    } else {
      this.ppSelectUserCallback.emit(userId);
    }
  }

  removeUserCallback(userId: string): void {
    this.ppRemoveUserCallback.emit(userId);
  }
}
