import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { TExportDashlet } from '../dashboard-export-modal/dashboard-export-dashlet.model';
import { DashletEventsService } from '../dashlet/dashlet-events/dashlet-events.service';
import { TDashboardImageElementData } from './dashboard-export-elements/dashboard-image-element';
import { generateExportDashletSimple } from './dashboard-export-elements/dashboard-simple-dashlet';
import { findDashletData } from './dashboard-export-service/find-dashlet-data';

@Injectable({
  providedIn: 'root',
})
export class DashboardSimpleDashletService {
  constructor(private dashletEventsService: DashletEventsService) {}

  generateExportSimpleDashlet(dashlet: TExportDashlet): Observable<TDashboardImageElementData> {
    return this.dashletEventsService
      .fetchEvents({
        type: dashlet.name,
        period: dashlet.period,
        accountId: dashlet.selectedRange.accountId,
        workspaceIds: dashlet.selectedRange.workspaceIds,
      })
      .pipe(
        switchMap((data) => {
          const requestData = this.dashletEventsService.getRequestQueue();

          const dashletData = findDashletData(dashlet, requestData);
          return generateExportDashletSimple(dashletData, dashlet);
        }),
      );
  }
}
