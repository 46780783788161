import { Component, OnDestroy } from '@angular/core';
import { DeviceService } from '@core/services';
import { Subject, takeUntil } from 'rxjs';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { DropdownService } from '../../../dropdown/dropdown-service/dropdown.service';
import { TDropdown } from '../../../dropdown/dropdown.consts';
import { TSelectMultipleOption } from '../select-multiple.model';
import { TSelectMultipleDropdownData } from './select-multiple-dropdown.model';

@Component({
  selector: 'pp-select-multiple-dropdown',
  templateUrl: './select-multiple-dropdown.component.html',
  styleUrls: ['./select-multiple-dropdown.component.scss'],
})
export class SelectMultipleDropdownComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  items: TSelectMultipleOption[];
  EIconPath = EIconPath;
  iconClass: string;

  public isiPod = false;
  public isMobile = false;
  public isiPad = false;
  public isTablet = false;
  dropdown: TDropdown = this.dropdownService.getDropdown();
  noSearchBar = false;
  dropdownWidth: string = `280px`;

  constructor(private dropdownService: DropdownService, private deviceService: DeviceService) {
    this.isiPod = this.deviceService.isiPod();
    this.isMobile = this.deviceService.isMobile();
    this.isiPad = this.deviceService.isiPad();
    this.isTablet = this.deviceService.isTablet();

    this.setDataFromDropdown();

    this.dropdownService.dropdownDataChanged$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setDataFromDropdown();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  setDataFromDropdown(): void {
    const dropdownData: TSelectMultipleDropdownData = this.dropdown.data;

    this.noSearchBar = dropdownData.noSearchBar;
    this.items = dropdownData.items;
    this.iconClass = dropdownData.iconClass;

    if (this.isMobile) {
      this.dropdownWidth = 'unset';
    }
  }

  selectItem(item: TSelectMultipleOption): void {
    item.selected = !item.selected;

    this.dropdown.callback(item);
  }

  hideDropdown(): void {
    this.dropdownService.hideDropdown();
  }
}
