import {
  AUTH_TOKEN_HEADER_NAME,
  USER_EMAIL_HEADER_NAME,
} from 'src/app/project/shared/constants/auth.constants';
import { getUserAuth } from '../http/user-auth';

export function authorizeXMLHttpRequest(request: XMLHttpRequest): XMLHttpRequest {
  const userAuth = getUserAuth();

  if (userAuth?.email && userAuth?.token) {
    request.setRequestHeader(AUTH_TOKEN_HEADER_NAME, getUserAuth().token);
    request.setRequestHeader(USER_EMAIL_HEADER_NAME, getUserAuth().email);
  }

  return request;
}
