/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccountOptionsPlaceholderComponent } from './account-settings-placeholders/account-options-placeholder/account-options-placeholder.component';
import { AccountSettingsPlaceholderParagraphComponent } from './account-settings-placeholders/account-settings-placeholder-paragraph/account-settings-placeholder-paragraph.component';
import { AccountSettingsSitesPlaceholderComponent } from './account-settings-placeholders/account-settings-sites-placeholder/account-settings-sites-placeholder.component';
import { AccountSubscriptionPlaceholderComponent } from './account-settings-placeholders/account-subscription-placeholder/account-subscription-placeholder.component';
import { PointActivityPlaceholderComponent } from './point-activity-placeholder/point-activity-placeholder.component';
import { RemindersPlaceholderComponent } from './reminders-placeholder/reminders-placeholder.component';
import { SiteTablePlaceholderComponent } from './site-table-placeholder/site-table-placeholder.component';
import { WhiteLabelPlaceholderComponent } from './white-label-placeholder/white-label-placeholder.component';
import { AccountUserManagementPlaceholderComponent } from './account-user-management-placeholder/account-user-management-placeholder.component';

@NgModule({
  declarations: [
    AccountSettingsPlaceholderParagraphComponent,
    AccountSubscriptionPlaceholderComponent,
    AccountSettingsSitesPlaceholderComponent,
    AccountUserManagementPlaceholderComponent,
    WhiteLabelPlaceholderComponent,
    AccountOptionsPlaceholderComponent,
    RemindersPlaceholderComponent,
    SiteTablePlaceholderComponent,
    PointActivityPlaceholderComponent,
  ],
  imports: [CommonModule],
  exports: [
    AccountSettingsPlaceholderParagraphComponent,
    AccountSubscriptionPlaceholderComponent,
    AccountSettingsSitesPlaceholderComponent,
    AccountUserManagementPlaceholderComponent,
    WhiteLabelPlaceholderComponent,
    AccountOptionsPlaceholderComponent,
    RemindersPlaceholderComponent,
    SiteTablePlaceholderComponent,
    PointActivityPlaceholderComponent,
  ],
})
export class PlaceholdersModule {}
