import { TCustomFieldFilter } from '../../../filters/site-filter.model';
import { TWorkspacesById } from '../../../workspace/workspace.model';

export function updateExistingCustomFieldFilter(
  customFieldIds: { workspaceId: string; customFieldId: string },
  existingField: TCustomFieldFilter,
  workspaces: TWorkspacesById,
): void {
  const workspaceId = customFieldIds.workspaceId;
  const customFieldId = customFieldIds.customFieldId;

  const existingSite = existingField.sites.filter((site) => site.workspaceId === workspaceId);

  if (!existingSite) {
    existingField.sites.push({
      siteName: workspaces[workspaceId].siteName,
      accountName: workspaces[workspaceId].accountName,
      workspaceId,
      filterId: customFieldId,
    });
  } else {
    const existingFieldId = existingSite.find((site) => site.filterId === customFieldId);

    if (!existingFieldId) {
      existingField.sites.push({
        siteName: workspaces[workspaceId].siteName,
        accountName: workspaces[workspaceId].accountName,
        workspaceId,
        filterId: customFieldId,
      });
    }
  }
}
