import { TFilters, TRemindersFilter } from '../site-filter.model';

export function clearFilterReminders(filters: TFilters): TRemindersFilter {
  filters.reminders = {
    startDate: null,
    endDate: null,
  };

  return filters.reminders;
}
