import { TPolygon } from 'src/app/project/view-models/point-response.model';

export type TPolygonCoordinatesFlat = [number, number];
export type TSinglePolygon = TPolygonCoordinatesFlat[];
export type TPointPolygon = TSinglePolygon[];

export function areaToPolygon(pointPolygons: TPolygon[] | number[][][]): number[][][] {
  let polygons: number[][][] = [];

  if (pointPolygons) {
    polygons = pointPolygons.map((polygon) => {
      if (polygon.vertices) {
        const pointPolygon: number[][][] = [];
        const singlePolygon: number[][] = [];

        polygon.vertices.forEach((vertice) => {
          singlePolygon.push([vertice.x, vertice.y]);
        });

        pointPolygon.push(singlePolygon);

        return pointPolygon;
      } else {
        return polygon;
      }
    });
  }

  return polygons;
}
