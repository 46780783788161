import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { TSavedViews } from 'src/app/project/modules/saved-views/models/saved-views.model';
import {
  API_views_default,
  API_views_delete,
  API_views_reorder,
  API_views_view,
  API_views_workspace,
} from './saved-views.paths';
import { TSavedView } from 'src/app/project/modules/saved-views/models/saved-view.model';
import { ESavedView } from 'src/app/project/modules/saved-views/models/saved-view.enum';

@Injectable({
  providedIn: 'root',
})
export class SavedViewsProviderService {
  constructor(private apiService: ApiService) {}

  getViews(workspaceId: string): Observable<TSavedViews> {
    const url = API_views_workspace(workspaceId);
    return this.apiService.get(url);
  }

  getView(workspaceId: string, viewId: string): Observable<TSavedView> {
    const url = API_views_view(workspaceId, viewId);

    return this.apiService.get(url);
  }

  createView(workspaceId: string, view: Partial<TSavedView>): Observable<TSavedView> {
    const url = API_views_workspace(workspaceId);

    return this.apiService.post(url, view);
  }

  editView(workspaceId: string, view: Partial<TSavedView>): Observable<TSavedView> {
    const url = API_views_view(workspaceId, view.id);
    return this.apiService.put(url, view);
  }

  deleteView(viewId: string, workspaceId: string): Observable<null> {
    const url = API_views_delete(workspaceId, viewId);

    return this.apiService.delete(url);
  }

  setDefaultView(workspaceId: string, viewId: string, type: ESavedView): Observable<TSavedViews> {
    const url = API_views_default();
    const body = {
      viewType: type,
      viewId,
      workspaceId,
    };

    return this.apiService.put(url, body);
  }

  deleteDefaultView(workspaceId: string, type: ESavedView): Observable<null> {
    const url = API_views_default();

    const body = {
      viewType: type,
      viewId: null,
      workspaceId,
    };

    return this.apiService.put(url, body);
  }

  reorderViews(
    workspaceId: string,
    viewId: string,
    viewType: ESavedView,
    newOrder: number,
  ): Observable<null> {
    const url = API_views_reorder();
    const body = {
      workspaceId,
      viewId,
      viewType,
      index: newOrder,
    };

    return this.apiService.put(url, body);
  }
}
