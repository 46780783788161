<pp-select
  [ppItems]="logicOptions"
  [ppSelectedItem]="{ value: ppLogic, label: '' }"
  (ppSelectItem)="changeLogic($event)"
  [ppNoSearchBar]="true"
  require
  *ngIf="ppIndex === 1"
>
  <ng-container selected>
    <span class="advancedFilter__select-content" *ngIf="selectedOption">
      {{ selectedOption.label }}
    </span>

    <span *ngIf="!selectedOption"> {{ 'select_option' | ppTranslate }} </span>
  </ng-container>
</pp-select>

<p class="advancedFilter__logic" *ngIf="ppIndex > 1">{{ selectedOption.label }}</p>
<p class="advancedFilter__logic" *ngIf="ppIndex === 0">{{ 'where' | ppTranslate }}</p>
