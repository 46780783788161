<pp-modal [ppThin]="true">
  <ng-container heading>
    {{ 'export' | ppTranslate }}
  </ng-container>

  <ng-container body>
    <h5 class="dashletExport__header">
      {{ 'page_layout' | ppTranslate }}
    </h5>

    <div class="siteOptionsExportModal__radio-buttons">
      <pp-radio
        *ngFor="let format of exportFormats"
        [ppChecked]="format.value === selectedFormat"
        (ppOnValueChange)="toggleFormat(format)"
        ppStyle="blue"
      >
        <label class="control-custom control-custom--radio">
          {{ format.label }}
        </label>
      </pp-radio>
    </div>

    <h5 class="dashletExport__header">
      {{ 'select_dashlets_to_export' | ppTranslate }}

      <i
        ppTooltip
        class="fa fa-info-circle dashletExport__header-info"
        aria-hidden="true"
        [ppMobileTooltip]="true"
        [ppTitle]="'activities_cannot_be_exported' | ppTranslate"
      ></i>
    </h5>

    <h6 class="dashletExport__header" *ngIf="simpleDashlets.length">
      {{ 'simple_dashlets' | ppTranslate }}
    </h6>

    <div class="dashboardExport__dashlet-list">
      <div class="dashboardExport__dashlet" *ngFor="let dashlet of simpleDashlets">
        <pp-checkbox
          [ppChecked]="dashlet.selected"
          (ppOnValueChange)="toggleExportDashlet(dashlet)"
        >
          <label>
            {{ dashletNames[dashlet.name] | ppTranslate }} (<span
              class="dashboardExport__dashlet--bold"
            >
              {{ dashlet.accountName }} - {{ dashlet.workspaceName }}</span
            >)
          </label>
        </pp-checkbox>
      </div>
    </div>

    <h6 class="dashletExport__header" *ngIf="advancedDashlets.length">
      {{ 'advanced_dashlets' | ppTranslate }}
    </h6>

    <div class="dashboardExport__dashlet-list">
      <div class="dashboardExport__dashlet" *ngFor="let dashlet of advancedDashlets">
        <pp-checkbox
          [ppChecked]="dashlet.selected"
          (ppOnValueChange)="toggleExportDashlet(dashlet)"
        >
          <label>
            {{ dashletNames[dashlet.name] | ppTranslate }} (<span
              class="dashboardExport__dashlet--bold"
            >
              {{ dashlet.accountName }} - {{ dashlet.workspaceName }}</span
            >)
          </label>
        </pp-checkbox>
      </div>
    </div>

    <div #exportZone></div>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'export' | ppTranslate"
      [ppProcess]="processing"
      (ppCancel)="hideModal()"
      (ppConfirm)="export()"
      [ppDisabled]="!anyDashletSelected"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
