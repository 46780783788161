import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { ButtonModule } from '../../components/button/button.module';
import { IconModule } from '../../components/icon/icon-module';
import { CheckboxModule } from '../../components/input/checkbox/checkbox.module';
import { RadioModule } from '../../components/input/radio/radio.module';
import { SelectGroupedModule } from '../../components/input/select-grouped/select-grouped.module';
import { SelectModule } from '../../components/input/select/select.module';
import { ModalModule } from '../../components/modal/modal.module';
import { ToggleModule } from '../../components/toggle/toggle.module';
import { AutonumericModule } from '../../directives/autonumeric/autonumeric.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SwitchCasesModule } from '../../directives/switch-cases/switch-cases.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { CustomFieldsModule } from '../custom-fields/custom-fields.module';
import { FiltersModule } from '../filters/filters.module';
import { LayoutModule } from '../layout/layout.module';
import { OfflineModule } from '../offline/offline.module';
import { PlanModule } from '../plan/plan.module';
import { PointsModule } from '../points/points.module';
import { RemindersModule } from '../reminders/reminders.module';
import { siteRoutes } from '../site/site.routes';
import { SupportedBrowsersModule } from '../supported-browsers/supported-browsers.module';
import { TagsModule } from '../tags/tags.module';
import { PointExportModalComponent } from './point-export-modal/point-export-modal.component';
import { PointExportWarningComponent } from './point-export-modal/point-export-warning/point-export-warning';
import { PointExportService } from './point-export.service';

@NgModule({
  declarations: [PointExportModalComponent, PointExportWarningComponent],
  imports: [
    CommonModule,
    CommonModule,
    FormsModule,
    RouterModule.forChild(siteRoutes),
    SelectModule,
    ButtonModule,
    ScrollingModule,
    LayoutModule,
    PlanModule,
    CheckboxModule,
    RadioModule,
    PointsModule,
    RemindersModule,
    CustomFieldsModule,
    TagsModule,
    SupportedBrowsersModule,
    OfflineModule,
    FiltersModule,
    PipesModule,
    PipesModule,
    ToggleModule,
    ModalModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    IconModule,
    SelectGroupedModule,
  ],
  providers: [PointExportService],
})
export class ExportsModule {}
