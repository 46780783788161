<div class="fleetExportAsset">
  <header class="fleetExportAssetHeader" (click)="toggleAsset()">
    <pp-checkbox
      ppType="solid"
      [ppChecked]="ppExportAsset.selected"
      *ngIf="ppSelectable"
    ></pp-checkbox>

    {{ ppExportAsset.name }}
  </header>

  <pp-fleet-export-page-dashlet
    *ngFor="let dashlet of ppExportAsset.assets"
    [ppExportDashlet]="dashlet"
    [ppDisabled]="!ppExportAsset.selected"
    (ppExportDashletChange)="onDashletToggle()"
  ></pp-fleet-export-page-dashlet>
</div>
