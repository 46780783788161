<div class="costComparisonErrorWrapper">
  <div class="costComparisonErrorBanner">
    <pp-icon
      [ppSrc]="EIconPath.ICON_BANNER_INFO_24"
      ppClass="setSize24"
      ppColor="inherit"
    ></pp-icon>

    <p>
      {{ 'dashlet_cost_comparison_currencies_error_1' | ppTranslate }}
      <span class="costComparisonErrorCallbackItem" (click)="editDashlet()">{{
        'dashlet_cost_comparison_currencies_error_2' | ppTranslate
      }}</span>
      {{ 'dashlet_cost_comparison_currencies_error_3' | ppTranslate }}
    </p>
  </div>
</div>
