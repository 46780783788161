import { GET_COLUMNS } from '../columns/columns.store';
import { GET_TABLE } from '../table.ui.store';

import { ApiService } from '@core/http';
import { createElement } from 'src/app/core/helpers/dom';
import { ERowType } from 'src/app/project/shared/enums/row-type.enum';
import { EBasicField, EBasicFieldShort } from '../../../../shared/enums/basic-fields-enums';
import { TColumn } from '../columns/column.model';
import { GET_GROUPING } from '../columns/grouping.store';
import { createAccountElement } from './body-cells-fields/create-account-element';
import { createAssigneesElement } from './body-cells-fields/create-assignees-element';
import { createCreatedByElement } from './body-cells-fields/create-created-by-element';
import { createCreatedElement } from './body-cells-fields/create-created-element';
import { createCustomFieldElement } from './body-cells-fields/create-custom-field-element';
import { createDescriptionElement } from './body-cells-fields/create-description-element';
import { createFlagElement } from './body-cells-fields/create-flag-element';
import { createIdElement } from './body-cells-fields/create-id-element';
import { createPriorityElement } from './body-cells-fields/create-priority-element';
import { createSiteElement } from './body-cells-fields/create-site-element';
import { createStatusElement } from './body-cells-fields/create-status-element';
import { createTagsElement } from './body-cells-fields/create-tags-element';
import { createTitleElement } from './body-cells-fields/create-title-element';
import { createUpdatedElement } from './body-cells-fields/create-updated-element';
import { createHeaderRowCellTypeElement } from './body-row/create-header-row/create-header-row-cell-type-element';

export function createColumnCellsElement(
  pointIndex: number,
  isPoint: boolean = false,
  apiService: ApiService,
): HTMLElement {
  const table = GET_TABLE();
  const columns = GET_COLUMNS();
  const offsetX = table.tableBody.virtualScroller.offsetX;
  const visibleXIndexes = table.tableBody.virtualScroller.visibleXIndexes;
  const children = [];
  const grouping = GET_GROUPING();

  visibleXIndexes.forEach((_visibleXIndex) => {
    const columnIndex = table.visibleColumnIndexes[_visibleXIndex];

    if (columns[columnIndex]) {
      children.push(
        createCellTypeElement({
          index: pointIndex,
          column: columns[columnIndex],
          isPoint,
          apiService,
        }),
      );
    }
  });

  const classList = ['table__columnCells'];

  switch (grouping.length) {
    case 1:
      classList.push('table__columnCells--group1');
      break;
    case 2:
      classList.push('table__columnCells--group2');
      break;
    case 3:
      classList.push('table__columnCells--group3');
      break;
  }

  return createElement('div', {
    attrs: {
      class: classList.join(' '),
      style: {
        transform: `translateX(${offsetX}px)`,
      },
    },
    children,
  });
}

// TODO: _items is a temporary thing
export function createCellTypeElement({
  index,
  column,
  items = false,
  isPoint = false,
  apiService,
}: {
  index: number;
  column: TColumn;
  items?: boolean;
  isPoint?: boolean;
  apiService: ApiService;
}): HTMLElement {
  const table = GET_TABLE();
  let itemIndex = index;

  if (
    !isPoint &&
    (table.items[index].type === ERowType.HEADER ||
      table.items[index].type === ERowType.GROUP_1 ||
      table.items[index].type === ERowType.GROUP_2 ||
      table.items[index].type === ERowType.GROUP_3)
  ) {
    let groupLevel: number;

    if (
      table.items[index].type === ERowType.HEADER ||
      table.items[index].type === ERowType.GROUP_1
    ) {
      groupLevel = 1;
    } else if (table.items[index].type === ERowType.GROUP_2) {
      groupLevel = 2;
    } else if (table.items[index].type === ERowType.GROUP_3) {
      groupLevel = 3;
    }

    return createHeaderRowCellTypeElement(index, column, groupLevel);
  }

  if (items) {
    itemIndex = table.items[itemIndex].index;
  }

  switch (column.name) {
    case EBasicField.ACCOUNT:
      return createAccountElement(itemIndex, column);
    case EBasicField.SITE:
      return createSiteElement(itemIndex, column);
    case EBasicField.PRIORITY:
    case EBasicFieldShort.PRIORITY:
      return createPriorityElement(itemIndex, column);
    case EBasicField.STATUS:
    case EBasicFieldShort.STATUS:
      return createStatusElement(itemIndex, column, apiService);
    case EBasicField.SEQUENCE_NUMBER:
    case EBasicFieldShort.SEQUENCE_NUMBER:
      return createIdElement(itemIndex, column);
    case EBasicField.TITLE:
      return createTitleElement(itemIndex, column, apiService);
    case EBasicField.DESCRIPTION:
      return createDescriptionElement(itemIndex, column);
    case EBasicField.CREATED:
      return createCreatedElement(itemIndex, column);
    case EBasicField.UPDATED:
      return createUpdatedElement(itemIndex, column);
    case EBasicField.CREATED_BY:
      return createCreatedByElement(itemIndex, column);
    case EBasicField.ASSIGNEES:
      return createAssigneesElement(itemIndex, column);
    case EBasicField.TAGS:
      return createTagsElement(itemIndex, column);
    case EBasicFieldShort.FLAGGED:
    case EBasicField.FLAGGED:
      return createFlagElement(itemIndex, column);
    default:
      return createCustomFieldElement(itemIndex, column);
  }
}
