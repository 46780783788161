import {
  TWorkspaceSimpleResponse,
  TAccountSidePanel,
  TAccountSimpleResponse,
} from 'src/app/project/view-models/account-simple-response.model';
import { translate } from 'src/app/project/features/translate/translate';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { GET_COLLAPSED_ACCOUNTS } from '../utils/account-list.ui.store';
import { checkAccountAdminPermissions } from './account-list-check-admin-permission';
import { NEW_FOLDER_ID } from '../utils/account-list-constants';
import { ESidePanelItem } from '../utils/side-panel-item.enum';
import { TAccount } from 'src/app/project/modules/account/account.model';

export type TFlattenedAccount = {
  account?: TAccount;
  type: string;
  draggable?: boolean;
  folderId?: string;
  workspace?: TWorkspace;
  accountId?: string;
  id?: string;
  name?: string;
  workspaces?: TWorkspaceSimpleResponse[];
  workspaceId?: string;
  accountType?: string;
};

export function flattenAccounts(accounts: TAccountSimpleResponse[]): TFlattenedAccount[] {
  return accounts.flatMap((simpleAccount) => {
    const _account = simpleAccount as TAccountSidePanel;

    const accountAdminPermission = checkAccountAdminPermissions(_account);

    const account = {
      type: ESidePanelItem.ACCOUNT,
      account: _account,
    };

    if (GET_COLLAPSED_ACCOUNTS().has(_account.id)) {
      if (Array.isArray(_account.accountFolders)) {
        let newFolder = false;

        _account.accountFolders.forEach((_folder) => {
          if (_folder.id === NEW_FOLDER_ID) {
            newFolder = true;
          }
        });

        if (newFolder) {
          const folder = {
            type: ESidePanelItem.FOLDER,
            name: '',
            id: NEW_FOLDER_ID,
            workspaces: _account.workspaces,
            accountId: _account.id,
          };

          return [account, folder];
        }
      }

      return account;
    }

    const newSiteButton = {
      type: ESidePanelItem.NEW_SITE_BUTTON,
      accountId: _account.id,
      name: translate('new_site'),
    };

    if (accountAdminPermission) {
      return [account, ..._account.elements, newSiteButton];
    } else {
      return [account, ..._account.elements];
    }
  }) as TFlattenedAccount[];
}
