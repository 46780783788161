import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { TUser } from 'src/app/project/modules/user/user.model';
import { TAccount } from '../account.model';

import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { SidePanelService } from 'src/app/project/modules/layout/side-panel/side-panel.service';
import { UserService } from 'src/app/project/modules/user/user.service';
import { AccountService } from '../account-service/account.service';

import { AccountListService } from 'src/app/project/modules/layout/side-panel/account-list.service';
import { GET_SIDE_PANEL_SERVICE } from 'src/app/project/modules/layout/side-panel/side-panel-store';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSiteList,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EUserRole } from '../../share/share-utils/user-roles';
import { WorkspaceService } from '../../workspace/workspace.service';

@Component({
  selector: 'pp-account-settings-dropdown',
  templateUrl: './account-settings-dropdown.component.html',
  styleUrls: ['./account-settings-dropdown.component.scss'],
})
export class AccountSettingsDropdownComponent implements OnInit {
  dropdown: TDropdown;
  user: TUser;
  account: TAccount;
  isAccountAdmin: boolean;
  private SidePanelService: SidePanelService;

  constructor(
    private dropdownService: DropdownService,
    private router: Router,
    private userService: UserService,
    private accountService: AccountService,
    private accountListService: AccountListService,
    private workspaceService: WorkspaceService,
  ) {}

  ngOnInit() {
    this.dropdown = this.dropdownService.getDropdown();

    if (!this.dropdown.data) {
      throw new Error('Data for this dropdown is required');
    }

    this.user = this.userService.getUser();
    this.account = this.accountService.getAccount(this.dropdown.data.accountId);
    this.SidePanelService = GET_SIDE_PANEL_SERVICE();

    this.checkIfIsAccountAdmin();
  }

  hideDropdown(): void {
    this.dropdownService.hideDropdown();
  }

  openAccountSettings(): void {
    this.SidePanelService.collapsePanel();

    logEventInGTAG(EGoogleEventSiteList.SITE_LIST_ACCOUNT_SETTINGS, {
      event_category: EGoogleEventCategory.SITE_LIST,
    });

    this.hideDropdown();

    this.router.navigate(['/settings/account', this.account.accountId]);
  }

  createNewFolder(): void {
    this.accountListService.createNewFolder(this.account.accountId);

    this.hideDropdown();
  }

  private checkIfIsAccountAdmin(): void {
    const workspaces = this.workspaceService.getWorkspaces();

    this.isAccountAdmin = this.account.workspaces.some((workspaceId) => {
      return workspaces[workspaceId].share.shareOption === EUserRole.ACCOUNT_ADMIN;
    });
  }
}
