import { Base64 } from 'js-base64';
import { TPoint } from '../points.model';

export function getDescriptionMentions(point: TPoint): string[] {
  const descriptionMentions = [];

  if (point.descriptionRich) {
    const richTextComponent = JSON.parse(Base64.decode(point.descriptionRich));

    richTextComponent.ops.forEach((textLine) => {
      if (textLine.insert && textLine.insert.mention) {
        descriptionMentions.push(textLine.insert.mention.id);
      }
    });
  }

  return descriptionMentions;
}
