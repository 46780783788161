import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import Tooltip from 'src/app/project/features/tooltip/Tooltip';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { sanitizeHTML } from 'src/app/core/helpers/text-sanitizer';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { TImportUser } from '../share-modal-import.component';
import { TAllUsers } from '@project/view-models';
import { EIconPath } from '../../../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-share-import-user-row',
  templateUrl: './share-import-user-row.component.html',
  styleUrls: ['./share-import-user-row.component.scss'],
})
export class ShareImportUserRowComponent implements OnInit, AfterViewInit {
  @ViewChild('nameRow') userRow: ElementRef;

  @Input() ppUserSelected: boolean;
  @Input() ppChecked: boolean;
  @Input() ppUser: TImportUser;
  @Input() ppRole: string;
  @Input() ppLimitedTags: boolean;
  @Input() ppTags: string[];
  @Input() ppAlreadyExists: boolean;
  @Input() ppAlreadySelected: boolean;

  @Output() ppSelectUser = new EventEmitter<void>();
  @Output() ppEditShare = new EventEmitter<TImportUser>();

  parentElement: HTMLElement;
  tooltip: Tooltip;
  users: TAllUsers;
  EIconPath = EIconPath;

  constructor(
    private elementRef: ElementRef,
    private translationPipe: TranslationPipe,
    private usersService: UsersService,
  ) {}

  ngOnInit() {
    this.users = this.usersService.getUsers();
  }

  ngAfterViewInit() {
    this.parentElement = this.elementRef.nativeElement;

    if (!this.tooltip) {
      this.tooltip = new Tooltip({
        options: {},
        title: this.ppAlreadySelected
          ? this.translationPipe.transform('user_already_selected')
          : this.ppAlreadyExists
          ? this.translationPipe.transform('user_already_exists_site')
          : this.ppUser.email,
        mobileTooltip: false,
        childTooltip: true,
        element: this.userRow.nativeElement,
      });

      this.tooltip.createTooltip();
    }
  }

  selectUser(): void {
    if (!this.ppAlreadyExists && !this.ppAlreadySelected) {
      this.ppSelectUser.emit();
    }
  }

  editShare(): void {
    if (!this.ppAlreadyExists && !this.ppAlreadySelected) {
      this.ppEditShare.emit();
    }
  }

  mouseEnterField(name: string): void {
    this.createTooltip(name);
  }

  mouseLeaveField(): void {
    this.createTooltip(
      this.ppAlreadySelected
        ? this.translationPipe.transform('user_already_selected')
        : this.ppAlreadyExists
        ? this.translationPipe.transform('user_already_exists_site')
        : this.ppUser.email,
    );
  }

  createTooltip(text: string): void {
    if (this.tooltip?._element?._tippy) {
      // in case afterViewInit didn't run yet
      this.tooltip._element._tippy.setContent(sanitizeHTML(text));
    }
  }
}
