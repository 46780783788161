import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject, of } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';

import { PromptService } from '../../../components/prompt/prompt.service';
import { WhiteLabelService } from '../../white-label/white-label.service';
import { LoginService } from '../login.service';
import { PasswordUpdateService } from '../password-update/password-update.service';

import { blurInput } from 'src/app/core/helpers/blur-input';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { routeToUrl } from 'src/app/core/helpers/route-to-url';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { TNewUserRequest } from 'src/app/project/view-models/reset-user-password-response.model';
import { AUTH_EMAIL_REGEX, EAuthRoute } from '../../../shared/constants/auth.constants';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { UserService } from '../../user/user.service';
import { TPasswordInputVisibilityType } from '../auth.types';
import { LoginRedirectService } from '../login-redirect.service';

@Component({
  selector: 'pp-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss'],
})
export class UserCreateComponent implements OnInit, OnDestroy {
  user: {
    passwordHash?: string;
    email?: string;
    userName?: string;
  };

  processing: boolean;
  passInputType: TPasswordInputVisibilityType;
  acceptedTerms: boolean;
  acceptedNewsletter: boolean;
  inputDirty = false;
  correctPassword = false;
  confirmPassword = '';
  EIconPath = EIconPath;

  private resetId: string;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private passwordUpdateService: PasswordUpdateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private promptService: PromptService,
    private whiteLabelService: WhiteLabelService,
    private translationPipe: TranslationPipe,
    private userService: UserService,
    private loginRedirectService: LoginRedirectService,
  ) {}

  ngOnInit(): void {
    this.user = {};
    this.processing = false;
    this.passInputType = 'password';
    this.acceptedTerms = false;
    this.acceptedNewsletter = false;

    this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.resetId = params['params'].id;
    });

    this.whiteLabelService.setDefaultStyle();

    this.passwordUpdateService
      .getPasswordResetUser(this.resetId)
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          this.user = response;
        }),
        catchError((error) => {
          if (error.status === EStatusCode.NOT_FOUND) {
            const promptText = this.translationPipe.transform('prompt_password_link_invalid');

            this.promptService.showWarning(promptText, {
              duration: 30,
            });
          }

          this.router.navigate([routeToUrl(EAuthRoute.LOGIN)]);

          return of();
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  createLoginDetails(): void {
    if (!this.isDataCorrect() || !this.isEmailCorrect()) {
      return;
    }

    if (!this.processing) {
      const newUser: TNewUserRequest = {
        email: this.user.email,
        name: this.user.userName,
        newsletter: this.acceptedNewsletter,
        passwordHash: this.user.passwordHash,
      };

      this.processing = true;

      this.passwordUpdateService
        .createLoginDetails(this.resetId, newUser)
        .pipe(
          takeUntil(this.destroy$),
          tap((response) => {
            this.loginService.saveToken(response.authToken, response.user.email, false);
            this.handleUserCreationSuccess();
          }),
          catchError(() => {
            const promptText = this.translationPipe.transform('prompt_error');

            this.promptService.showError(promptText);

            return of();
          }),
          finalize(() => {
            this.processing = false;
          }),
        )
        .subscribe();
    }
  }
  private handleUserCreationSuccess(): void {
    this.userService
      .fetchUser()
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.navigateAfterCreatingLoginDetails();
        }),
      )
      .subscribe();
  }

  isDataCorrect(): boolean {
    return !(
      !this.user.userName ||
      !this.user.passwordHash ||
      !this.acceptedTerms ||
      !this.correctPassword ||
      this.user.passwordHash !== this.confirmPassword
    );
  }

  isEmailCorrect(): boolean {
    if (!AUTH_EMAIL_REGEX.test(this.user.email)) {
      const promptText = this.translationPipe.transform('prompt_email_invalid');
      this.promptService.showError(promptText);

      return false;
    } else {
      return true;
    }
  }

  setInputDirty(): void {
    this.inputDirty = true;
  }

  toggleNewsletter(): void {
    this.acceptedNewsletter = !this.acceptedNewsletter;
  }

  validatePassword(valid: boolean): void {
    this.correctPassword = valid;
  }

  toggleAcceptedTerms(): void {
    this.acceptedTerms = !this.acceptedTerms;
  }

  blurInput(event: Event): void {
    blurInput(event.target);
  }

  navigateAfterCreatingLoginDetails(): void {
    this.loginRedirectService.redirectAfterLogin();
    this.router.navigate([routeToUrl(EAuthRoute.TWO_FACTOR_AUTHENTICATION_SETUP)]);
  }
}
