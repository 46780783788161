<ng-container *ngIf="isBrowserUnsupported && !isSavedInCookies">
  <section class="browsersBar">
    <p>
      {{ 'browser_not_supported_bar_1' | ppTranslate }}
      <a routerLink="/supported-browsers">
        {{ 'browser_not_supported_bar_2' | ppTranslate }}
      </a>
      {{ 'browser_not_supported_bar_3' | ppTranslate }}
    </p>

    <button (click)="dismiss()">{{ 'dismiss' | ppTranslate }}</button>
  </section>
</ng-container>
