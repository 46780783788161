<button
  [id]="buttonId"
  class="actionBar__right"
  *ngIf="buttonVisible"
  (click)="showMoreOptions()"
  ppTooltip
  [ppTitle]="'more_options' | ppTranslate"
  data-test="threeDotsButton"
>
  <pp-icon ppClass="setSize18" ppColor="grey-500" [ppSrc]="EIconPath.ICON_NAV_THREE_DOTS_VERTICAL_18"/>
</button>
