<pp-modal>
  <ng-container heading>
    {{ 'change_password_for' | ppTranslate }}
    <strong>{{ user.userName }}</strong>
  </ng-container>

  <ng-container body>
    <form class="updatePassword" name="passwordForm" novalidate="" (submit)="changePassword()">
      <h5 class="modal__body-title">{{ 'old_password' | ppTranslate }}</h5>

      <div class="form-group">
        <div class="modalInput__input-wrapper">
          <span class="sw-icon sw-icon-password"></span>

          <input
            class="modalInput__input input input-border-bottom"
            id="oldPassword"
            [type]="passwordInputTypes.oldPassword"
            name="password"
            [placeholder]="'old_password' | ppTranslate"
            [(ngModel)]="oldPassword"
            autocomplete="off"
            (keydown.enter)="blurInput($event)"
            autofocus
            required
          />

          <pp-password-visibility
            (ppVisibilityChange)="setPasswordVisibilityType('oldPassword', $event)"
          ></pp-password-visibility>
        </div>
      </div>

      <h5 class="modal__body-title">{{ 'new_password' | ppTranslate }}</h5>

      <div class="form-group">
        <div class="modalInput__input-wrapper">
          <span class="sw-icon sw-icon-password"></span>

          <input
            class="modalInput__input input input-border-bottom"
            id="newPassword"
            name="new-password"
            [type]="passwordInputTypes.newPassword"
            [placeholder]="'new_password' | ppTranslate"
            [(ngModel)]="newPassword"
            autocomplete="off"
            (keydown.enter)="blurInput($event)"
            (keyup)="newPasswordDirty = true"
            autofocus
            required
          />

          <pp-password-visibility
            (ppVisibilityChange)="setPasswordVisibilityType('newPassword', $event)"
          ></pp-password-visibility>
        </div>
      </div>

      <h5 class="modal__body-title">{{ 'confirm_password' | ppTranslate }}</h5>

      <div class="form-group">
        <div class="modalInput__input-wrapper newPasswordModalInput__input-wrapper-wrapper">
          <span class="sw-icon sw-icon-password"></span>

          <input
            class="modalInput__input input input-border-bottom"
            id="confirmPassword"
            name="confirm-password"
            [type]="passwordInputTypes.confirmPassword"
            [placeholder]="'confirm_password' | ppTranslate"
            [(ngModel)]="confirmPassword"
            autocomplete="off"
            (keydown.enter)="blurInput($event)"
            autofocus
            required
          />

          <pp-password-visibility
            (ppVisibilityChange)="setPasswordVisibilityType('confirmPassword', $event)"
          ></pp-password-visibility>

          <pp-icon
            *ngIf="newPassword !== confirmPassword && confirmPassword.length > 0"
            ppSrc="EIconPath.ICON_MISC_INFO"
            alt="outline of a lock"
            ppColor="grey-600"
            ppClass="setSize18"
            ppTooltip
            [ppTitle]="'passwords_dont_match' | ppTranslate"
            [ppMobileTooltip]="true"
          ></pp-icon>
        </div>
      </div>

      <pp-password-validation
        *ngIf="newPasswordDirty"
        (ppValidate)="validatePassword($event)"
        [ppPassword]="newPassword"
      ></pp-password-validation>
    </form>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'change' | ppTranslate"
      (ppCancel)="hideModal()"
      (ppConfirm)="changePassword()"
      [ppProcess]="processing"
      [ppDisabled]="!isPasswordCorrect || newPassword !== confirmPassword"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
