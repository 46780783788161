<div class="pointCF__field">
  <label
    ppTooltip
    class="pointCF__label"
    ppTitle="{{ ppCustomField?.label }}"
    [ppTrimTooltip]="true"
  >
    <img
      class="pointCF__bulk-changes-field-icon"
      [src]="EIconPath.CUSTOM_FIELD_TIMELINE_ACTIVITY"
    />
    <span class="pointCF__label-container">
      {{ ppCustomField?.label }}
    </span>
  </label>

  <div class="pointCF__content">
    <pp-custom-field-timeline
      [ppField]="ppCustomField"
      [ppValue]="ppCustomFields[ppFieldId]"
      [ppWorkspaceId]="ppWorkspaceId"
      [ppFieldId]="ppFieldId"
      (ppUpdate)="updateTimeline($event, ppCustomField)"
    ></pp-custom-field-timeline>
  </div>
</div>
