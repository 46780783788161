import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UsersService } from '../../modules/users/users.service';
import { EIconPath } from '../../shared/enums/icons.enum';
import { TWorkspaceUser } from '../../view-models/user.view-model';

@Component({
  selector: 'pp-user-chip',
  templateUrl: './user-chip.component.html',
  styleUrls: ['./user-chip.component.scss'],
})
export class UserChipComponent implements OnChanges {
  @Input() ppUserId: string;
  @Input() ppEmail: string;
  @Output() ppRemoveUserCallback = new EventEmitter<string>();
  @Input() ppDisabled: boolean;
  @Input() ppHasError: boolean;
  EIconPath = EIconPath;

  userName: string;
  userAvatarSrc: string;

  constructor(private usersService: UsersService) {}

  ngOnChanges(): void {
    this.prepareUser();
  }

  removeUser(event: MouseEvent): void {
    if (this.ppDisabled) {
      return;
    }

    event.stopPropagation();

    this.ppRemoveUserCallback.emit(this.ppUserId);
  }

  private prepareUser(): void {
    const user = this.getUser();

    this.userAvatarSrc = this.getUserAvatarSrc(user);
    this.userName = this.getUserName(user);
  }

  private getUser(): TWorkspaceUser {
    const users = this.usersService.getUsers();
    const usersList = Object.values(users);
    let userId = this.ppUserId;

    if (this.ppEmail && usersList.length > 0) {
      userId = usersList.find((user) => user.email === this.ppEmail)?.userId;
    }

    return users[userId];
  }

  private getUserAvatarSrc(user: TWorkspaceUser): string {
    if (user?.avatarPublicUrl) {
      return user.avatarPublicUrl;
    } else if (user) {
      return EIconPath.PLACEHOLDER_USER;
    } else if (this.ppEmail) {
      return null;
    } else {
      return EIconPath.PLACEHOLDER_USER;
    }
  }

  private getUserName(user: TWorkspaceUser): string {
    if (user?.userName) {
      return user.userName;
    } else if (user?.email) {
      return user.email;
    } else {
      return this.ppEmail;
    }
  }
}
