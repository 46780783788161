export function getTextWithMentionsMarked(quill): string {
  // https://github.com/quilljs/quill/issues/2698#issuecomment-515890990
  return quill
    .getContents()
    .filter(function (op) {
      return typeof op.insert === 'string' || op.insert.mention;
    })
    .map(function (op) {
      if (op.insert.mention) {
        return (op.insert.mention = '@'); // it will try to highlight mention if "@" is a keyword, but backend won't search for keywords shorter than 3 characters
      }
      return op.insert;
    })
    .join('');
}
