import { Component, EventEmitter, Input, Output } from '@angular/core';
import { createElement } from '@core/helpers';
import { html, render } from 'lit-html';
import { translate } from 'src/app/project/features/translate/translate';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EStatus } from 'src/app/project/shared/enums/status.enum';
import { TFleetStatuses } from '../../fleet.consts';

@Component({
  selector: 'pp-fleet-management-new-fleet-status-weights',
  templateUrl: './fleet-management-new-fleet-status-weights.component.html',
  styleUrls: ['./fleet-management-new-fleet-status-weights.component.scss'],
})
export class FleetManagementNewFleetStatusWeightsComponent {
  @Input() ppStatuses: TFleetStatuses;
  @Output() ppStatusesChange = new EventEmitter<TFleetStatuses>();

  EIconPath = EIconPath;
  infoTooltip: string = '';
  EStatus = EStatus;

  ngOnChanges(): void {
    this.generateTooltip();
  }

  updateWeight(status: EStatus, weight: number): void {
    this.ppStatuses[status].weight = weight;
    this.ppStatusesChange.emit(this.ppStatuses);
  }

  private generateTooltip(): void {
    const wrapperElement = createElement('div');
    const tooltip = html`<div class="tooltip__fleetStatuses">
      <div>${translate('new_fleet_status_tooltip_1')}</div>
      <ul>
        <li>• <strong>${translate('open')}</strong> – 0%</li>
        <li>• <strong>${translate('in_progress')}</strong> – 50%</li>
        <li>• <strong>${translate('to_review')}</strong> – 50%</li>
        <li>• <strong>${translate('on_hold')}</strong> – 0%</li>
        <li>• <strong>${translate('completed')}</strong> – 100%</li>
        <li>• <strong>${translate('canceled')}</strong> – 100%</li>
      </ul>
      <div>${translate('new_fleet_status_tooltip_2')}</div>
    </div>`;

    render(tooltip, wrapperElement);

    this.infoTooltip = wrapperElement.innerHTML;
  }
}
