import { Component, Input, OnInit } from '@angular/core';
import { finalize, tap } from 'rxjs/operators';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { TUser } from '../../user.model';
import { UserSettingsService } from '../user-settings.service';

@Component({
  selector: 'pp-user-settings-avatar',
  templateUrl: './user-settings-avatar.component.html',
  styleUrls: ['../user-settings.component.scss'],
})
export class UserSettingsAvatarComponent implements OnInit {
  @Input() ppUser: TUser;
  deletingAvatar = false;
  uploadedImage = null;
  updatingAvatar = false;
  EIconPath = EIconPath;

  constructor(private userSettingsService: UserSettingsService) {}

  ngOnInit(): void {}

  uploadUserImage(file: File[]): void {
    const reader = new FileReader();

    this.updatingAvatar = true;

    reader.onload = (): void => {
      this.uploadedImage = reader.result;
    };

    reader.readAsDataURL(file[0]);

    this.userSettingsService
      .uploadUserImage(file, this.ppUser.userId)
      .pipe(
        tap(() => {
          this.uploadedImage = null;
        }),
        finalize(() => {
          this.updatingAvatar = false;
        }),
      )
      .subscribe();
  }

  deleteCustomAvatar(): void {
    this.deletingAvatar = true;

    this.userSettingsService
      .deleteCustomAvatar(this.ppUser.userId)
      .pipe(
        finalize(() => {
          this.deletingAvatar = false;
        }),
      )
      .subscribe();
  }
}
