import { TWorkspaceUser } from '@project/view-models';
import { TemplateResult, html } from 'lit-html';
import { sanitizeHTML } from 'src/app/core/helpers/text-sanitizer';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { TRenderItem } from '../rich-text.model';
import { generateUserAvatar } from './generate-user-avatar';

export function generateListItem(item: TRenderItem, user: TWorkspaceUser): TemplateResult<1> {
  const avatarSrc = user?.avatarPublicUrl ? user.avatarPublicUrl : EIconPath.PLACEHOLDER_USER;

  const userAvatar = generateUserAvatar(avatarSrc, user.userName);

  const listItem = html` <li class="users__item">
    ${userAvatar}
    <ul class="users__select-details">
      <li class="users__select-username">${sanitizeHTML(user.userName)}</li>

      <li class="users__select-user-email">${user.email}</li>
    </ul>
  </li>`;

  return listItem;
}
