<section class="siteSettingsIntegrationsAuditLog">
  <div class="integrationFieldsDescription">
    <header class="integrationFieldsDescription__header">
      {{ 'integrations_voly_audit_log_header' | ppTranslate }}
    </header>
  </div>

  <div class="integrationFields">
    <pp-site-settings-integrations-audit-log-row [ppLog]="log" *ngFor="let log of auditLogs">
    </pp-site-settings-integrations-audit-log-row>
  </div>
</section>
