import { Component, OnDestroy } from '@angular/core';
import { Subject, take, takeUntil } from 'rxjs';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventFleetManagement,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { FleetManagementRoutesService } from '../../../fleet-management/fleet-management-routes.service';
import { FleetService } from '../../../fleet-management/fleet-service/fleet.service';
import { TFleetSharingModalComponent } from '../../../fleet-management/fleet-sharing-modal/fleet-sharing-modal-component.consts';
import { FleetSharingModalComponent } from '../../../fleet-management/fleet-sharing-modal/fleet-sharing-modal.component';
import { FleetSharingModalService } from '../../../fleet-management/fleet-sharing-modal/fleet-sharing-modal.service';
import { EFleetShareOption } from '../../../fleet-management/fleet-sharing/fleet-sharing.consts';

@Component({
  selector: 'pp-top-bar-fleet-options',
  templateUrl: './top-bar-fleet-options.component.html',
  styleUrls: ['./top-bar-fleet-options.component.scss'],
})
export class TopBarFleetOptionsComponent implements OnDestroy {
  canEdit: boolean;
  private readonly shouldCloseModal = new Subject<boolean>();
  private readonly destroy$ = new Subject<void>();

  constructor(
    private fleetManagementRoutesService: FleetManagementRoutesService,
    private fleetService: FleetService,
    private modalService: ModalService,
    private fleetSharingModalService: FleetSharingModalService,
  ) {
    this.checkFleetPermissions();

    this.fleetService.activeFleetChange$.subscribe(() => {
      this.checkFleetPermissions();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  addAsset(): void {
    const activeFleetId = this.fleetService.getActiveFleetId();

    this.fleetManagementRoutesService.goToAddAssetPage(activeFleetId);
  }

  shareFleet(): void {
    const modalData: TFleetSharingModalComponent = {
      showError: false,
    };

    logEventInGTAG(EGoogleEventFleetManagement.FLEET_SHARE_MODAL, {
      event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
    });

    this.modalService.setData(modalData);
    this.modalService.showModal(FleetSharingModalComponent, {
      onBeforeClose: (cancelled) => {
        if (cancelled) {
          return this.tryCloseModal();
        }

        return Promise.resolve(true);
      },
    });
  }

  private tryCloseModal(): Promise<boolean> {
    const unsavedEmails = this.fleetSharingModalService.getEmailList();
    const confirmClose = this.fleetSharingModalService.getConfirmClose();

    if (unsavedEmails.length && !confirmClose) {
      this.modalService.setData<TFleetSharingModalComponent>({
        showError: true,
      });

      return this.shouldCloseModal.pipe(takeUntil(this.destroy$), take(1)).toPromise();
    } else {
      return Promise.resolve(true);
    }
  }

  private checkFleetPermissions(): void {
    const fleet = this.fleetService.getFleet(this.fleetService.getActiveFleetId());

    this.canEdit = fleet ? fleet.fleetShareOption === EFleetShareOption.EDIT : false;
  }
}
