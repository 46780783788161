<div
  #list
  (ppOnRendered)="onTableRendered($event)"
  class="listCF__wrapper"
  (scroll)="scrollHandler($event)"
>
  <div
    *ngFor="let matchedListItem of matchedListItems"
    (click)="select(matchedListItem, matchedListItem.level); $event.stopPropagation()"
  >
    <span
      class="listCF__li-content listCF__search-results-item"
      [class.listCF__li-content--selected]="isSelected(matchedListItem.id)"
      [innerHTML]="matchedListItem.path | highlightFragment: ppFilter"
    ></span>
  </div>

  <div *ngIf="matchedListItems?.length === 0 && ppFilter !== ''" (click)="$event.stopPropagation()">
    <p class="listCF__not-found-message">
      {{ 'no_search_result' | ppTranslate }}
    </p>
  </div>

  <ng-container *ngIf="ppFilter === ''">
    <ng-container *ngFor="let item of ppItem.subList">
      <div
        class="listCF__list-item"
        *ngIf="item.label"
        [class.listCF__list-item--has-sublist]="item.subList?.length > 0"
      >
        <div class="listCF__li-content" [class.listCF__li-content--selected]="isSelected(item.id)">
          <button
            (click)="toggleExpand(item); $event.stopPropagation()"
            class="listCF__arrow-wrapper"
          >
            <pp-icon
              [ppSrc]="
                expanded[item.id]
                  ? EIconPath.ICON_NAV_ARROW_DOWN_14
                  : EIconPath.ICON_NAV_ARROW_RIGHT_14
              "
              ppClass="setSize14"
              ppColor="grey-700"
            ></pp-icon>
          </button>

          <div class="listCF__text" (click)="select(item); $event.stopPropagation()">
            <label>
              {{ item.label }}
            </label>
            <p class="listCF__sublist-count">({{ item.subList?.length }})</p>
          </div>
        </div>

        <div class="listCF__sublist-wrapper" *ngIf="item.subList?.length > 0 && expanded[item.id]">
          <pp-custom-field-list-select
            class="listCF__sublist"
            [ppField]="ppField"
            [ppItem]="item"
            [ppSelectedId]="ppSelectedId"
            [ppLevel]="ppLevel + 1"
            (ppSelect)="selectDeep($event)"
          ></pp-custom-field-list-select>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
