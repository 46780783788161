import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IconModule } from '../icon/icon-module';
import { UserChipComponent } from './user-chip.component';

@NgModule({
  declarations: [UserChipComponent],
  imports: [CommonModule, FormsModule, IconModule],
  exports: [UserChipComponent],
})
export class UserChipModule {}
