import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';
import { EAdvancedFilterTimelineDate } from '../../../../models/advanced-filter-timeline-date.enum';
import {
  TAdvancedFilterSingle,
  TAdvancedFilterTimeline,
  TFilterTimelineOptions,
} from '../../../../models/advanced-filter.model';
import {
  getAdvancedTimelineDates,
  getAdvancedTimelineOptions,
} from './advanced-filter-timeline-utils';

@Component({
  selector: 'pp-advanced-filter-timeline',
  templateUrl: './advanced-filter-timeline.component.html',
  styleUrls: ['./advanced-filter-timeline.component.scss'],
})
export class AdvancedFilterTimelineComponent {
  @Input() ppFilter: TAdvancedFilterSingle;
  @Output() ppFilterChange = new EventEmitter<TAdvancedFilterSingle>();

  option: TFilterTimelineOptions;
  filter: TAdvancedFilterTimeline;

  startDate: number;
  endDate: number;
  selectedDate: EAdvancedFilterTimelineDate;

  advancedFilterTimelineOptions = getAdvancedTimelineOptions();
  advancedFilterTimelineDates = getAdvancedTimelineDates();
  EAdvancedFilterTimelineDate = EAdvancedFilterTimelineDate;
  EAdvancedFilterOptions = EAdvancedFilterOptions;

  ngOnChanges(): void {
    if (!this.ppFilter.value) {
      this.ppFilter.value = [null, null];
    }

    this.filter = this.ppFilter as TAdvancedFilterTimeline;

    this.startDate = this.filter.value?.[0] ? this.filter.value[0] : null;
    this.endDate = this.filter.value?.[1] ? this.filter.value[1] : null;
    this.option = this.filter.option;
    this.selectedDate = this.filter.date;
  }

  changeFieldCondition(newCondition: TFilterTimelineOptions): void {
    this.filter.option = newCondition;
    this.option = newCondition;
    this.applyChanges();
  }

  changeFieldDate(newDate: EAdvancedFilterTimelineDate): void {
    this.filter.date = newDate;
    this.selectedDate = newDate;
    this.applyChanges();
  }

  applyChanges(): void {
    this.ppFilterChange.emit(this.filter);
  }

  changeFieldValue(value: Date[], index): void {
    const date = value[0];
    date.setHours(0, 0, 0, 0);

    this.filter.value[index] = date.getTime();

    switch (index) {
      case 0:
        this.startDate = date.getTime();
        break;
      case 1:
        this.endDate = date.getTime();
        break;
    }
    this.applyChanges();
  }
}
