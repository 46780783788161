import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { EDateFormat } from './enums/date-format.enum';

dayjs.extend(utc);

export type TTransformDateValue = dayjs.Dayjs | Date | string | number;

export function transformDate({
  value,
  inputHourFormat,
  format,
  localTime,
}: {
  value: TTransformDateValue;
  inputHourFormat?: string;
  format?: string;
  localTime?: boolean;
}): string {
  const hourFormat = inputHourFormat || '';

  if (value) {
    const dayJSValue = localTime ? dayjs(+value) : dayjs(+value).utc();

    switch (format) {
      case EDateFormat.ISO:
        return dayJSValue.format('YYYY-MM-DD' + hourFormat);
      case 'US':
        return dayJSValue.format('MM/DD/YYYY' + hourFormat);
      case 'FRIENDLY':
        return dayJSValue.format('DD MMM YYYY' + hourFormat);
      case 'EUROPEAN':
        return dayJSValue.format('DD/MM/YYYY' + hourFormat);
      default:
        return dayJSValue.format('YYYY-MM-DD' + hourFormat);
    }
  } else {
    return '';
  }
}
