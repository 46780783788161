import { Component, OnInit } from '@angular/core';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';

import { DropdownService } from '../../../../components/dropdown/dropdown-service/dropdown.service';

import { EStatus } from '../../../../shared/enums/status.enum';

@Component({
  selector: 'app-point-status-dropdown',
  templateUrl: './point-status-dropdown.component.html',
  styleUrls: ['./point-status-dropdown.component.scss'],
})
export class PointStatusDropdownComponent implements OnInit {
  private dropdown: TDropdown;

  statuses: EStatus[] = [
    EStatus.OPEN,
    EStatus.IN_PROGRESS,
    EStatus.TO_REVIEW,
    EStatus.ONHOLD,
    EStatus.CLOSED,
    EStatus.CANCELED,
  ];

  constructor(private dropdownService: DropdownService) {}

  ngOnInit() {
    this.dropdown = this.dropdownService.getDropdown();

    if (!this.dropdown.callback) {
      throw new Error('Callback for this dropdown is required');
    }
  }

  selectOption(option: EStatus): void {
    this.dropdown.callback(option);
    this.dropdownService.hideDropdown();
  }
}
