import { TemplateResult, html } from 'lit-html';

export function generateUserAvatar(avatarSrc: string, userName: string): TemplateResult {
  return html` <img
    ppTooltip
    class="user__avatar"
    src="${avatarSrc}"
    [ppMobileTooltip]="true"
    ppTitle="${userName}"
  />`;
}
