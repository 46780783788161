import { Injectable } from '@angular/core';
import { TGuid } from '@core/helpers';
import { cloneDeep } from 'lodash';
import { Observable, Subject, catchError, map, of, tap } from 'rxjs';
import { SharesApiProviderService } from '../../data-providers/api-providers/share-api-provider/shares-api-provider.service';
import { ResponseErrorService } from '../errors/response-error.service';
import { TAccountUser } from '../users/account.user.model';
import { UsersService } from '../users/users.service';
import { WorkspaceService } from '../workspace/workspace.service';
import { EUserRole } from './share-utils/user-roles';
import { TShare, TSharesPerAccount } from './share.model';
import { generateShare } from './share.utils';

@Injectable({
  providedIn: 'root',
})
export class AccountSharesService {
  accountShares: TSharesPerAccount = {};
  private accountSharesObservable: {
    [accountId: string]: Observable<TAccountUser[]>;
  } = {};

  private readonly _accountSharesChange = new Subject<TSharesPerAccount>();
  readonly accountSharesChange$ = this._accountSharesChange.asObservable();

  constructor(
    private sharesApiProviderService: SharesApiProviderService,
    private responseErrorService: ResponseErrorService,
    private workspaceService: WorkspaceService,
    private usersService: UsersService,
  ) {}

  fetchAccountShares(accountId: TGuid): Observable<TAccountUser[]> {
    if (this.accountShares && this.accountShares[accountId]) {
      return of(this.accountShares[accountId]);
    }

    if (!this.accountSharesObservable[accountId]) {
      this.accountSharesObservable[accountId] = this.sharesApiProviderService
        .getShareAccount(accountId)
        .pipe(
          map((shares) => {
            const fixedShares = this.handleFetchedShares(shares);

            this.accountShares[accountId] = fixedShares;
            this.accountSharesObservable[accountId] = null;

            this.emitSharesChange();

            return fixedShares;
          }),
          catchError((error) => {
            this.accountSharesObservable[accountId] = null;
            return this.responseErrorService.handleRequestError(error);
          }),
        );
    }

    return this.accountSharesObservable[accountId];
  }

  removeAccountUser(accountId: string, userId: string): void {
    this.accountShares[accountId] = this.accountShares[accountId].filter(
      (user) => user.userId !== userId,
    );

    this.emitSharesChange();
  }

  updateShare(userId: string, share: TShare): void {
    const accounts = Object.values(this.accountShares);
    const workspace = this.workspaceService.getWorkspace(share.workspaceId);
    const updatedShare = generateShare(share, share.workspaceId, workspace);

    for (let i = 0; i < accounts.length; i++) {
      const account = accounts[i];
      const user = account.find((accountUser) => accountUser.userId === userId);

      if (user) {
        user.shares = user.shares.map((workspaceShare) =>
          workspaceShare.shareId === updatedShare.shareId ? updatedShare : workspaceShare,
        );
      }
    }
  }

  removeShares(accountId: string, shareIds: string[]): void {
    this.accountShares[accountId] = this.accountShares[accountId].filter((user) => {
      user.shares = user.shares.filter((share) => !shareIds.includes(share.shareId));

      return user.shares.length > 0;
    });

    this.emitSharesChange();
  }

  emitSharesChange(): void {
    this._accountSharesChange.next(this.accountShares);
  }

  updateAccountUserTwoFactorAuthentication(enabled: boolean, userId: string): void {
    const accounts = Object.values(this.accountShares);

    for (let i = 0; i < accounts.length; i++) {
      const account = accounts[i];
      const user = account.find((accountUser) => accountUser.userId === userId);

      if (user) {
        user.enabled2fa = enabled;
      }
    }

    this.emitSharesChange();
  }

  private handleFetchedShares(shares: TAccountUser[]): TAccountUser[] {
    const nonOwnerShares = this.removeOwnerFromShares(shares);
    return this.addMissingDataToShares(nonOwnerShares);
  }

  private removeOwnerFromShares(shares: TAccountUser[]): TAccountUser[] {
    return cloneDeep(shares).filter(
      (share) =>
        !share.shares.some((workspaceShare) => workspaceShare.shareOption === EUserRole.OWNER),
    );
  }

  private addMissingDataToShares(nonOwnerShares: TAccountUser[]): TAccountUser[] {
    return nonOwnerShares.map((share) => {
      share.shares = share.shares.map((workspaceShare) => {
        const workspace = this.workspaceService.getWorkspace(workspaceShare.workspaceId);

        return generateShare(workspaceShare, workspaceShare.workspaceId, workspace);
      });

      return share;
    });
  }

  resetAccountShares(accountId: string): Observable<TAccountUser[]> {
    delete this.accountShares[accountId];

    return this.fetchAccountShares(accountId).pipe(
      tap(() => {
        this.usersService
          .fetchAllUsers()
          .pipe(
            tap(() => {
              this.emitSharesChange();
            }),
          )
          .subscribe();
      }),
    );
  }
}
