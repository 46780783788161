import { ESavedView } from '../../../models/saved-view.enum';
import { TSavedViews, TViewShort } from '../../../models/saved-views.model';

export function checkViewNameTaken(
  views: TSavedViews,
  type: ESavedView,
  name: string,
  id: string,
): boolean {
  let viewList: TViewShort[];

  switch (type) {
    case ESavedView.PERSONAL: {
      viewList = views.personal.views;
      break;
    }

    case ESavedView.SHARED: {
      viewList = views.shared.views;
      break;
    }
  }

  return viewList.some((view) => view.name === name && view.id !== id);
}
