import { MINIMUM_WIDTH_FOR_EXPANDED_TIMELINE_ELEMENT } from './timeline-variables';

export function adjustTimelineElementClass(newWidth: number, element: HTMLElement): void {
  if (newWidth > MINIMUM_WIDTH_FOR_EXPANDED_TIMELINE_ELEMENT) {
    element.classList.add('timeline_fieldElement--long');
    element.classList.remove('timeline_fieldElement--short');
  } else if (newWidth > -MINIMUM_WIDTH_FOR_EXPANDED_TIMELINE_ELEMENT) {
    element.classList.add('timeline_fieldElement--short');
    element.classList.remove('timeline_fieldElement--long');
  } else {
    element.classList.add('timeline_fieldElement--long');
    element.classList.remove('timeline_fieldElement--short');
  }
}
