import { Component, EventEmitter, Input, Output } from '@angular/core';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventIntegrations,
} from 'src/app/project/services/analytics/google-analytics.consts';

@Component({
  selector: 'pp-site-settings-integrations-get-started',
  templateUrl: './site-settings-integrations-get-started.component.html',
  styleUrls: ['./site-settings-integrations-get-started.component.scss'],
})
export class SiteSettingsIntegrationsGetStartedComponent {
  @Input() ppDisabled: boolean;
  @Output() ppEnableIntegration = new EventEmitter<void>();

  onIntegrationStart(): void {
    logEventInGTAG(EGoogleEventIntegrations.GET_STARTED, {
      event_category: EGoogleEventCategory.INTEGRATIONS,
    });

    this.ppEnableIntegration.emit();
  }
}
