import { ApiService } from '@core/http';
import { TColumn } from '../../../columns/column.model';
import { GET_TABLE } from '../../../table.ui.store';
import { createHeaderCellElement } from './create-header-cell-element';

export function createHeaderNameElement(
  _name: string,
  _width: string | number,
  _column: TColumn,
  _center: boolean = false,
  apiService: ApiService,
): HTMLElement {
  const table = GET_TABLE();

  return createHeaderCellElement({
    name: _name,
    center: _center,
    width: `${_width}px`,
    child: _name,
    column: _column,
    resize: table.tableHead?.canResize,
    apiService,
  });
}
