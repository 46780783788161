import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { finalize, tap } from 'rxjs/operators';
import { TTranslationKey } from '../../features/translate/types';
import { AccountSubscriptionService } from '../../modules/account/account-subscription.service';
import {
  TAccountLimits,
  TAccountSubscriptionUpdate,
  TSimulatedUsageBody,
} from '../../modules/account/account.model';
import { logEventInGTAG } from '../../services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventNewSite,
} from '../../services/analytics/google-analytics.consts';
import { Modal, ModalService } from '../modal/modal.service';
import { TChangeLimitsModalData } from './change-limit-modal.model';

@Component({
  selector: 'pp-change-limit-modal',
  templateUrl: './change-limit-modal.component.html',
  styleUrls: ['./change-limit-modal.component.scss'],
})
export class ChangeLimitModalComponent implements OnInit {
  firstMessageKey: TTranslationKey;
  secondMessageKey: TTranslationKey;
  confirmKey: TTranslationKey;
  header: TTranslationKey;
  accountId: string;
  limitsToIncrease: TAccountLimits;
  processing = false;
  contactUsEmail = 'mailto:support@pinpointworks.com';

  private modal: Modal;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private modalService: ModalService,
    private accountSubscriptionService: AccountSubscriptionService,
  ) {}

  ngOnInit(): void {
    this.modal = this.modalService.getModal();
    this.processModalData();
  }

  cancelModal(): void {
    this.modalService.hideModal();
  }

  increaseLimit(): void {
    if (this.processing) {
      return;
    }

    this.processing = true;

    const newSubscription = {
      limits: {
        ...this.limitsToIncrease,
      },
      accountId: this.accountId,
    };

    this.updateSubscription(newSubscription);

    logEventInGTAG(EGoogleEventNewSite.CREATE_SITE_SUBSCRIPTION_INCREASE_LIMIT, {
      event_category: EGoogleEventCategory.CREATE_SITE,
    });
  }

  contactUs(): void {
    logEventInGTAG(EGoogleEventNewSite.CREATE_SITE_SUBSCRIPTION_CONTACT, {
      event_category: EGoogleEventCategory.CREATE_SITE,
    });

    this.document.location.href = this.contactUsEmail;
  }

  private throwMissingDataError(): void {
    throw new Error('Change limits modal component: no data provided');
  }

  private setLimitsFromModal(modalData: TChangeLimitsModalData): void {
    this.processing = true;

    this.accountSubscriptionService
      .fetchSubscription(this.accountId)
      .pipe(
        tap((subscription) => {
          this.limitsToIncrease = {
            SHARES_ACCOUNT_ADMIN:
              modalData.limitsToIncrease.SHARES_ACCOUNT_ADMIN +
              subscription.usages.SHARES_ACCOUNT_ADMIN,
            SHARES_ADMIN:
              modalData.limitsToIncrease.SHARES_ADMIN + subscription.usages.SHARES_ADMIN,
            SHARES_GUEST:
              modalData.limitsToIncrease.SHARES_GUEST + subscription.usages.SHARES_GUEST,
            SHARES_NORMAL:
              modalData.limitsToIncrease.SHARES_NORMAL + subscription.usages.SHARES_NORMAL,
            SITES: modalData.limitsToIncrease.SITES + subscription.usages.SITES,
          };
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  private fetchSimulatedUsage(modalData: TChangeLimitsModalData): void {
    const body: TSimulatedUsageBody = {
      createList: modalData.changedLimits.createList || [],
      editList: modalData.changedLimits.editList || [],
      importList: modalData.changedLimits.importList || [],
      deleteList: modalData.changedLimits.deleteList || [],
    };

    this.processing = true;

    this.accountSubscriptionService
      .fetchSimulatedUsage(modalData.accountId, body)
      .pipe(
        tap((response) => {
          this.limitsToIncrease = response.usages;
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  private processModalData(): void {
    const modalData: TChangeLimitsModalData = this.modal.data;
    this.firstMessageKey = modalData.firstMessageKey;
    this.secondMessageKey = modalData.secondMessageKey;
    this.accountId = modalData.accountId;
    this.confirmKey = modalData.confirmKey;
    this.header = modalData.header;

    if (modalData.limitsToIncrease) {
      this.setLimitsFromModal(modalData);
    } else if (modalData.changedLimits) {
      this.fetchSimulatedUsage(modalData);
    } else {
      this.throwMissingDataError();
    }
  }

  private updateSubscription(newSubscription: TAccountSubscriptionUpdate): void {
    this.accountSubscriptionService
      .updateSubscription(newSubscription, this.accountId)
      .pipe(
        tap(() => {
          this.modalService.hideModal(false).catch(() => {
            // the closing of the modal was aborted
            this.processing = false;
          });
        }),
      )
      .subscribe();
  }
}
