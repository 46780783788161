import { EInstance } from 'src/app/project/shared/enums/instances';

export const environment = {
  clone: false,
  clone2: false,
  release: false,
  clone3: false,
  ecs: false,
  production: false,
};

export function setEnvironment(host: string): void {
  console.log(host);
  switch (host) {
    case EInstance.CLONE:
      environment.clone = true;
      break;
    case EInstance.CLONE2:
      environment.clone2 = true;
      break;
    case EInstance.CLONE3:
      environment.clone3 = true;
      break;
    case EInstance.LIVE:
    case EInstance.ASM:
      environment.production = true;
      break;
    case EInstance.ECS:
      environment.ecs = true;
      break;
    case EInstance.RELEASE:
      environment.release = true;
      break;
    default:
      environment.production = false;
      environment.ecs = false;
      environment.clone = false;
      environment.clone2 = false;
      environment.clone3 = false;
      environment.release = false;
      break;
  }
}
