import { getWeekNumber } from './get-week-number';
import { splitYearIntoWeeks } from './split-year-into-weeks';

export function getWeekBottomElements(from: Date, to: Date): string[] {
  let elements = [];

  const weeks = splitYearIntoWeeks(from, to);

  weeks.forEach((_week) => {
    const weekNumber = getWeekNumber(_week);

    elements = [...elements, `Week ${weekNumber}`];
  });

  return elements;
}
