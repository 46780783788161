import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeviceService } from '@core/services';
import { Subject, takeUntil } from 'rxjs';
import { TCurrencyData } from 'src/app/project/modules/custom-fields/currencies/currencies.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { DropdownService } from '../../dropdown/dropdown-service/dropdown.service';
import { TDropdown } from '../../dropdown/dropdown.consts';
import { TCurrencyPickerDropdownData } from './currency-picker-dropdown.consts';

@Component({
  selector: 'pp-currency-picker-dropdown',
  templateUrl: './currency-picker-dropdown.component.html',
  styleUrls: ['./currency-picker-dropdown.component.scss'],
})
export class CurrencyPickerDropdownComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  dropdownWidth: string = '558px';
  isMobile = false;
  filteredItems: TCurrencyData[] = [];
  selectedItem: TCurrencyData = null;
  EIconPath = EIconPath;

  private dropdown: TDropdown = this.dropdownService.getDropdown();
  private searchedKeyword = '';
  private items: TCurrencyData[];

  constructor(private dropdownService: DropdownService, private deviceService: DeviceService) {
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit(): void {
    this.setDataFromDropdown();

    this.dropdownService.dropdownDataChanged$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setDataFromDropdown();
    });

    this.filterItems();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  hideDropdown(): void {
    this.dropdownService.hideDropdown();
  }

  selectItem(item: TCurrencyData): void {
    this.dropdown.callback(item);

    this.hideDropdown();
  }

  setKeyword(keyword: string): void {
    this.searchedKeyword = keyword;

    this.filterItems();
  }

  private filterItems(): void {
    const filteredItems = [];

    this.items.forEach((item) => {
      if (
        item.currencyDisplayName.toLowerCase().includes(this.searchedKeyword.toLowerCase()) ||
        item.currencyCode.toLowerCase().includes(this.searchedKeyword.toLowerCase()) ||
        item.currencySymbol.toLowerCase().includes(this.searchedKeyword.toLowerCase())
      ) {
        filteredItems.push(item);
      }
    });

    this.filteredItems = filteredItems;
  }

  private setDataFromDropdown(): void {
    const dropdownData: TCurrencyPickerDropdownData = this.dropdown.data;

    this.items = dropdownData.items;
    this.selectedItem = dropdownData.selectedItem;
    this.dropdownWidth = dropdownData.width ? dropdownData.width : `0px`;
  }
}
