<div
  class="limitBar"
  [class.limitBar_full]="ppUnlimited ? false : ppCurrentValue === ppMaxValue"
  [class.limitBar__exceeded]="ppUnlimited ? false : ppCurrentValue > ppMaxValue"
>
  <div class="limitBar__text">
    {{ ppCurrentValue }}
    <ng-container *ngIf="!ppUnlimited">{{ 'of' | ppTranslate }} {{ ppMaxValue }}</ng-container>
    {{ ppTitleKey | ppTranslate }}
  </div>

  <meter
    class="limitMeter"
    [value]="ppCurrentValue"
    [max]="ppUnlimited ? ppCurrentValue : ppMaxValue"
    min="0"
  ></meter>
</div>
