import { Component, Input, OnInit } from '@angular/core';
import { TWorkspaceUser } from '@project/view-models';
import { Subject, debounceTime, takeUntil, tap } from 'rxjs';
import { TAssetLabel } from 'src/app/project/modules/fleet-management/asset-service/asset.consts';
import { AssetService } from 'src/app/project/modules/fleet-management/asset-service/asset.service';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventFleetManagement,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-asset-card-information-label',
  templateUrl: './asset-card-information-label.component.html',
  styleUrls: ['./asset-card-information-label.component.scss'],
})
export class AssetCardInformationLabelComponent implements OnInit {
  @Input() ppLabel: TAssetLabel;
  keyword: string = '';
  private destroy$ = new Subject<void>();
  private searchKeywordDebounceTime: number = 500;

  colorCode = '';
  user: TWorkspaceUser;
  tooltipText: string = '';

  constructor(private usersService: UsersService, private assetService: AssetService) {
    this.assetService.keywordChange$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(this.searchKeywordDebounceTime),
        tap((keyword) => {
          this.keyword = keyword;
        }),
      )
      .subscribe();
  }

  ngOnInit() {
    //26 is the hex equivalent of 0.15 alpha
    this.colorCode = this.ppLabel.color + '26';
    this.user = this.usersService.getUser(this.ppLabel.assigneeId);
    if (!this.ppLabel.assigneeId || !this.user) return;
    this.generateTooltipContent();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  logLabelClick(): void {
    logEventInGTAG(EGoogleEventFleetManagement.ASSET_LABEL_CLICK, {
      event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
    });
  }

  logLabelHover(): void {
    logEventInGTAG(EGoogleEventFleetManagement.ASSET_LABEL_HOVER, {
      event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
    });
  }

  private generateTooltipContent(): void {
    this.tooltipText =
      `<div class="tooltipTable">
            <div class="tooltipTableRow">
              <div class="tooltipAvatar__wrapper">
                <img class="labelTooltipAvatar" src="${this.user.avatarPublicUrl}" alt="${EIconPath.PLACEHOLDER_USER}"/>
              </div>
              <span class="tooltipText--short tooltipTableCell">
                <span class="tooltipInnerCell" >
                  <span class="labelTooltip__userName">` +
      this.user.userName +
      `</span>
                  <span class="labelTooltip__email"> ` +
      this.user.email +
      `</span>
                </span>
              </span>
            </div>
        </div>`;
  }
}
