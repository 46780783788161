import { Injectable } from '@angular/core';
import {
  API_export_pdf_detailed,
  API_export_pdf_detailed_overview,
  API_export_pdf_individual,
  API_export_pdf_individual_zip,
  API_export_pdf_simple,
  API_export_pdf_simple_overview,
  API_export_pdf_site_plan,
  API_export_word_detailed,
  API_export_word_individual,
  API_export_word_individual_zip,
  API_export_word_simple_overview,
} from '@core/api/paths';
import { Observable, of, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { TFilters } from 'src/app/project/modules/filters/site-filter.model';
import { PromptService } from '../../components/prompt/prompt.service';
import { TranslationPipe } from '../../features/translate/translation.pipe';
import { DownloadService } from '../../services/download.service';
import { PreferencesService } from '../preferences/preferences-service/preferences.service';
import { TElementPerWorkspace, TExportBody, TSectionVisibilitySettings } from './export-body.model';
import { PointExportFilterService } from './point-export-filter.service';
import { PointExportLocalService } from './point-export-local.service';
import { PointExportPreferencesService } from './point-export-preferences.service';
import { PointExportUtilsService } from './point-export-utils.service';
import { PointExportVisibilitySettingsService } from './point-export-visibility-settings.service';
import { TExportData } from './utils/export-data';
import { EExportFormat } from './utils/export-page-format-enum';
import { EExportTypeName } from './utils/export-type-names-enum';

export type TExportFunctionParams = {
  workspaceId: string;
  pointIds: string[] | TElementPerWorkspace[];
  customFieldIds: string[];
  exportData: TExportData;
  filters: TFilters;
  coords: string[];
  exportType: EExportTypeName;
  isOverview: boolean;
  isPdf: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class PointExportService {
  constructor(
    private promptService: PromptService,
    private translationPipe: TranslationPipe,
    private preferencesService: PreferencesService,
    private pointExportVisibilitySettingsService: PointExportVisibilitySettingsService,
    private downloadService: DownloadService,
    private pointExportPreferencesService: PointExportPreferencesService,
    private pointExportFilterService: PointExportFilterService,
    private pointExportLocalService: PointExportLocalService,
    private pointExportUtilsService: PointExportUtilsService,
  ) {}

  export({
    workspaceId,
    pointIds,
    customFieldIds,
    exportData,
    filters,
    coords,
    exportType,
    isOverview,
    isPdf,
  }: TExportFunctionParams): Observable<Response> {
    if (pointIds?.length === 0) {
      this.showPermissionDeniedWarninig();

      return throwError('');
    }

    const mapData = this.pointExportUtilsService.pushPlan();
    const exportFilters = this.pointExportFilterService.generateFilter(filters);
    const format: EExportFormat =
      exportType === EExportTypeName.ZIP || exportType === EExportTypeName.ZIP_WORD
        ? EExportFormat.A4
        : exportData.format;

    const sectionsVisibilitySettings: TSectionVisibilitySettings =
      this.pointExportVisibilitySettingsService.getSectionVisibilitySettings(
        exportType,
        exportData,
        customFieldIds,
        exportFilters,
      );

    const elementsPerWorkspace = isOverview
      ? this.pointExportUtilsService.getOverviewElementsPerWorkspace(pointIds)
      : this.pointExportUtilsService.getElementsPerWorkspace(workspaceId, coords, pointIds);

    const body: TExportBody = {
      timeGMT: exportData.timeZone,
      format,
      mapZoom: mapData.mapZoomString,
      elementsPerWorkspace,
      sectionsVisibilitySettings,
      exportFilter: exportFilters,
    };

    switch (exportType) {
      case EExportTypeName.CSV:
        this.pointExportLocalService.exportLocally('csv');

        return of(null);
      case EExportTypeName.XLSX:
        this.pointExportLocalService.exportLocally('xlsx');

        return of(null);
      case EExportTypeName.DETAILED_LIST:
        if (isOverview) {
          return this.exportOverviewDetailedList(sectionsVisibilitySettings, body);
        }

        this.pointExportPreferencesService.updatePreferences({
          workspaceId,
          exportType: 'pdf',
          sectionsVisibilitySettings,
          format: exportData.format,
        });

        return this.exportFromUrl(API_export_pdf_detailed(), body);
      case EExportTypeName.PDF:
        return this.exportIndividualPdf(workspaceId, sectionsVisibilitySettings, exportData, body);
      case EExportTypeName.SIMPLE_LIST:
        if (isOverview) {
          return this.exportOverviewSimpleList(body);
        } else {
          return this.exportSimpleList(workspaceId, body);
        }

      case EExportTypeName.SITE_PLAN:
        return this.exportFromUrl(API_export_pdf_site_plan(), body);
      case EExportTypeName.WORD:
        return this.exportWord(workspaceId, sectionsVisibilitySettings, body);

      case EExportTypeName.INDIVIDUAL_WORD:
        return this.exportIndividualWord(workspaceId, sectionsVisibilitySettings, body);
      case EExportTypeName.WORD_SIMPLE:
        return this.exportSimpleWord(body);
      case EExportTypeName.ZIP:
        return this.exportZip(isPdf, workspaceId, sectionsVisibilitySettings, body);
      case EExportTypeName.ZIP_WORD:
        return this.exportZipWord(workspaceId, sectionsVisibilitySettings, body);
    }
  }

  private exportZipWord(
    workspaceId: string,
    sectionsVisibilitySettings: TSectionVisibilitySettings,
    body: TExportBody,
  ): Observable<Response> {
    this.pointExportPreferencesService.updatePreferences({
      workspaceId,
      exportType: 'word',
      sectionsVisibilitySettings,
      format: null,
    });

    return this.exportFromUrl(API_export_word_individual_zip(), body);
  }

  private exportZip(
    isPdf: boolean,
    workspaceId: string,
    sectionsVisibilitySettings: TSectionVisibilitySettings,
    body: TExportBody,
  ): Observable<Response> {
    if (isPdf) {
      this.pointExportPreferencesService.updatePreferences({
        workspaceId,
        exportType: 'pdf',
        sectionsVisibilitySettings,
      });
    } else {
      this.pointExportPreferencesService.updatePreferences({
        workspaceId,
        exportType: 'zip',
        sectionsVisibilitySettings,
      });
    }

    return this.exportFromUrl(API_export_pdf_individual_zip(), body);
  }

  private exportSimpleWord(body: TExportBody): Observable<Response> {
    this.pointExportPreferencesService.updateOverviewPreferences({
      exportType: 'word',
      sectionsVisibilitySettings: body.sectionsVisibilitySettings,
      format: body.format,
    });

    return this.exportFromUrl(API_export_word_simple_overview(), body);
  }

  private exportIndividualWord(
    workspaceId: string,
    sectionsVisibilitySettings: TSectionVisibilitySettings,
    body: TExportBody,
  ): Observable<Response> {
    this.pointExportPreferencesService.updatePreferences({
      workspaceId,
      exportType: 'word',
      sectionsVisibilitySettings,
    });

    return this.exportFromUrl(API_export_word_individual(), body);
  }

  private exportWord(
    workspaceId: string,
    sectionsVisibilitySettings: TSectionVisibilitySettings,
    body: TExportBody,
  ): Observable<Response> {
    this.pointExportPreferencesService.updatePreferences({
      workspaceId,
      exportType: 'word',
      sectionsVisibilitySettings,
    });

    return this.exportFromUrl(API_export_word_detailed(), body);
  }

  private exportSimpleList(workspaceId: string, body: TExportBody): Observable<Response> {
    this.pointExportPreferencesService.updatePreferences({
      workspaceId,
      exportType: 'simple',
      sectionsVisibilitySettings: body.sectionsVisibilitySettings,
      format: body.format,
    });

    return this.exportFromUrl(API_export_pdf_simple(), body);
  }

  private exportOverviewSimpleList(body: TExportBody): Observable<Response> {
    this.pointExportPreferencesService.updateOverviewPreferences({
      exportType: 'simple',
      sectionsVisibilitySettings: body.sectionsVisibilitySettings,
      format: body.format,
    });

    return this.exportFromUrl(API_export_pdf_simple_overview(), body);
  }

  private exportIndividualPdf(
    workspaceId: string,
    sectionsVisibilitySettings: TSectionVisibilitySettings,
    exportData: TExportData,
    body: TExportBody,
  ): Observable<Response> {
    this.pointExportPreferencesService.updatePreferences({
      workspaceId,
      exportType: 'pdf',
      sectionsVisibilitySettings,
      format: exportData.format,
    });

    return this.exportFromUrl(API_export_pdf_individual(), body);
  }

  private exportOverviewDetailedList(
    sectionsVisibilitySettings: TSectionVisibilitySettings,
    body: TExportBody,
  ): Observable<Response> {
    const preferences = this.preferencesService.getPreferences();

    preferences.overviewExports.pdf = sectionsVisibilitySettings;

    this.pointExportPreferencesService.updateOverviewPreferences({
      exportType: 'pdf',
      sectionsVisibilitySettings: body.sectionsVisibilitySettings,
      format: body.format,
    });

    return this.exportFromUrl(API_export_pdf_detailed_overview(), body);
  }

  private showPermissionDeniedWarninig(): void {
    const prompt = this.translationPipe.transform('export_permission_denied');

    this.promptService.showWarning(prompt);
  }

  private exportFromUrl(url: string, body?: TExportBody): Observable<Response> {
    return this.downloadService.fromRequest(url, body).pipe(
      tap((response) => {
        if (response.status === EStatusCode.ACCEPTED) {
          const promptText = this.translationPipe.transform(
            'prompt_export_will_shortly_receive_a_link_via_email',
          );

          this.promptService.showSuccess(promptText, { duration: 15 });
        }
      }),
    );
  }
}
