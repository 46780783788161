import { Component, Input } from '@angular/core';
import { EIconPath } from '../../../../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-dashlet-activity-new-comment',
  templateUrl: './dashlet-activity-new-comment.component.html',
  styleUrls: ['../dashlet-activity.component.scss'],
})
export class DashletActivityNewCommentComponent {
  @Input() ppRichText = '';
  @Input() ppPlainText = '';

  EIconPath = EIconPath;
}
