import { TColumn } from '../../../../columns/column.model';

export function getMarginOffset(grouping: TColumn[], groupingLevel: number): number {
  let marginOffset = 0;

  if (grouping.length === 3) {
    switch (groupingLevel) {
      case 2:
        marginOffset = 16;
        break;
      case 1:
        marginOffset = 32;
        break;
      default:
        marginOffset = 0;
        break;
    }
  } else if (grouping.length === 2) {
    if (groupingLevel === 0) {
      marginOffset = -16;
    } else if (groupingLevel === 1) {
      marginOffset = 16;
    }
  }
  return marginOffset;
}
