import { Component, OnInit } from '@angular/core';
import { DeviceService } from '@core/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { DropdownService } from '../../../dropdown/dropdown-service/dropdown.service';
import { TDropdown } from '../../../dropdown/dropdown.consts';
import { TSelectOption } from '../../select/select.model';
import { TFontSelectDropdownData } from './font-select-dropdown.model';

@Component({
  selector: 'pp-font-select-dropdown',
  templateUrl: './font-select-dropdown.component.html',
  styleUrls: ['./font-select-dropdown.component.scss'],
})
export class FontSelectDropdownComponent implements OnInit {
  private destroy$ = new Subject<void>();

  dropdown: TDropdown = this.dropdownService.getDropdown();
  dropdownWidth: string = '240px';
  isMobile = false;
  isTablet = false;
  items: TSelectOption[];
  selectedItem: TSelectOption;
  EIconPath = EIconPath;

  constructor(private dropdownService: DropdownService, private deviceService: DeviceService) {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
  }

  ngOnInit(): void {
    if (!this.dropdown.data) {
      throw new Error('Data for this dropdown is required');
    }

    if (!this.dropdown.callback) {
      throw new Error('Callback for this dropdown is required');
    }

    this.setDataFromDropdown();

    this.dropdownService.dropdownDataChanged$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setDataFromDropdown();
    });
  }

  setDataFromDropdown(): void {
    const dropdownData: TFontSelectDropdownData = this.dropdown.data;

    this.items = dropdownData.items;
    this.selectedItem = dropdownData.selectedItem;

    if (this.isMobile) {
      this.dropdownWidth = 'unset';
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  hideDropdown(): void {
    this.dropdownService.hideDropdown();
  }

  selectItem(item: TSelectOption): void {
    this.dropdown.callback(item);
  }
}
