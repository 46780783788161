import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { TShare } from '../share.model';

import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { Modal, ModalService } from '../../../components/modal/modal.service';
import { PromptService } from '../../../components/prompt/prompt.service';

import { finalize, tap } from 'rxjs/operators';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { checkEmailsValidity } from 'src/app/project/shared/utils/check-emails';
import { TShareModalComponentData } from './share-modal-component.model';
import { ShareModalService } from './share-modal.service';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss'],
})
export class ShareModalComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly _closeShare$ = new Subject<void>();
  readonly closeShare$ = this._closeShare$.asObservable();

  modal: Modal = this.modalService.getModal();
  workspaceId: string;

  processingShare = false;
  tagError = false;

  workspace: TWorkspace = null;

  share: TShare;
  shareEmails: string[] = [];

  constructor(
    private modalService: ModalService,
    private promptService: PromptService,
    private workspaceService: WorkspaceService,
    private translationPipe: TranslationPipe,
    private shareModalService: ShareModalService,
  ) {}

  ngOnInit() {
    this.setModalData();
    this.setWorkspace();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  createShare(): void {
    if (this.checkNewShareErrors()) {
      return;
    }

    this.saveShares();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  private saveShares(): void {
    this.processingShare = true;

    this.shareModalService
      .saveShareFromMultipleEmails(this.share, this.shareEmails, this.workspace.workspaceId)
      .pipe(
        tap(() => {
          this.hideModal();
        }),
        finalize(() => {
          this.processingShare = false;
        }),
      )
      .subscribe();
  }

  private setWorkspace() {
    this.workspace = this.workspaceService.findWorkspace(this.workspaceId);
  }

  private showInvalidEmailPrompt(): void {
    const promptText = this.translationPipe.transform('prompt_email_invalid');

    this.promptService.showError(promptText);
  }

  private showTagErrorPrompt(): void {
    const prompt = this.translationPipe.transform('share_no_tags_error');

    this.promptService.showWarning(prompt);
  }

  private setModalData(): void {
    const modalData = this.modal.data as TShareModalComponentData;

    this.workspaceId = modalData.workspaceId;
  }

  private checkNewShareErrors(): boolean {
    return this.processingShare || this.checkTagError() || this.checkEmailError();
  }

  private checkTagError(): boolean {
    const userWithInvalidTagAccess = this.share.tagLimited && this.share.defectTags.length === 0;

    if (userWithInvalidTagAccess) {
      this.showTagErrorPrompt();
      this.tagError = true;

      return true;
    }

    return false;
  }

  private checkEmailError(): boolean {
    if (!checkEmailsValidity(this.shareEmails)) {
      this.showInvalidEmailPrompt();

      return true;
    }

    return false;
  }
}
