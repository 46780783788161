<div class="advancedFilters__container">
  <h6 *ngIf="advancedFilter.filters.length === 0">
    {{ 'no_filters_applied_to_table' | ppTranslate }}
  </h6>

  <ng-container *ngFor="let filter of advancedFilter.filters; let i = index">
    <div
      class="advancedFilters__row-container"
      [id]="'advancedFilters__row-container--' + i"
      [class.advancedFilters__row-container--shown]="visibleFilters[i]"
    >
      <pp-advanced-filters-row
        [ppFilter]="filter"
        [ppIndex]="i"
        [ppWorkspaceId]="ppWorkspaceId"
        [(ppLogic)]="advancedFilter.logic"
        (ppDeleteFilter)="deleteFilter(i)"
        (ppFilterChange)="updateFilter(i, $event)"
      ></pp-advanced-filters-row>
    </div>
  </ng-container>

  <button class="advancedFilters__new-filter-button" (click)="addNewFilter()">
    <pp-icon
      [ppSrc]="EIconPath.ICON_NAV_PLUS_14"
      ppColor="primary-600"
      [ppDisplayContents]="true"
    ></pp-icon>
    {{ 'add_filter_condition' | ppTranslate }}
  </button>
</div>
