import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TwoFactorAuthenticationPhoneNumberService {
  private confirmationPhoneNumber: string;

  constructor() {}

  setConfirmationPhoneNumber(email: string): void {
    this.confirmationPhoneNumber = email;
  }

  getConfirmationPhoneNumber(): string {
    return this.confirmationPhoneNumber;
  }

  clearConfirmationPhoneNumber(): void {
    this.confirmationPhoneNumber = null;
  }
}
