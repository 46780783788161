<div class="filterContent__cost">
  <pp-filter-field-numbers-input
    [ppPlaceholder]="'minimum' | ppTranslate"
    [ppLeftUnit]="leftUnit"
    [(ppValue)]="ppField.min"
    [ppUnit]="unit"
    (ppValueChange)="updateCostField(ppField); applyFilters()"
    class="filterContent__costInput"
  ></pp-filter-field-numbers-input>

  <pp-filter-field-numbers-input
    [ppPlaceholder]="'maximum' | ppTranslate"
    [ppLeftUnit]="leftUnit"
    [(ppValue)]="ppField.max"
    [ppUnit]="unit"
    (ppValueChange)="updateCostField(ppField); applyFilters()"
    class="filterContent__costInput"
  ></pp-filter-field-numbers-input>
</div>
