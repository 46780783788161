import { ESavedView } from '../../../models/saved-view.enum';
import { TSavedViews } from '../../../models/saved-views.model';
import { checkViewNameTaken } from './check-view-name-taken';

export function generateViewName(
  views: TSavedViews,
  type: ESavedView,
  name: string,
  viewId: string,
): string {
  let iteration = 0;
  let newName = name;

  while (checkViewNameTaken(views, type, newName, viewId)) {
    iteration++;
    newName = name + ' ' + iteration;
  }

  return newName;
}
