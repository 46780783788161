import './core/helpers/date/date.extensions';

import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { Subject, of } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { VERSION } from 'src/environments/version';
import { environment, setEnvironment } from '../environments/environment';
import { routeToUrl } from './core/helpers/route-to-url';
import { clearUserAuth, readUserAuthFromLocalStorage } from './core/http/user-auth';
import { UpdateService } from './project/modules/offline/update.service';
import { SUPPORTED_BROWSERS_URL } from './project/modules/supported-browsers/supported-browsers.constants';
import { SupportedBrowsersService } from './project/modules/supported-browsers/supported-browsers.service';
import { UserService } from './project/modules/user/user.service';
import { WhiteLabelService } from './project/modules/white-label/white-label.service';
import { GoogleAnalyticsService } from './project/services/analytics/google-analytics.service';
import { ClearService } from './project/services/clear.service';
import { TranslationService } from './project/services/translation.service';
import { EAuthRoute } from './project/shared/constants/auth.constants';

setEnvironment(window.location.host); // Sets if before any app code runs

@Component({
  selector: 'pp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', './project/modules/white-label/white-label.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private userService: UserService,
    private whiteLabelService: WhiteLabelService,
    private updateService: UpdateService,
    private supportedBrowsersService: SupportedBrowsersService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private clearService: ClearService,
    private translationService: TranslationService,
  ) {
    this.updateService.subscribeToAppUpdates();
    this.checkUserAuth();
    this.clearService.clearUserCookie();

    if (environment.production) {
      this.googleAnalyticsService.subscribeToRouteChange();
    } else {
      console.log(`APP HASH: ${VERSION.hash}`);
    }

    this.translationService.initTranslations();
    this.whiteLabelService.setDefaultStyle();
  }

  ngOnInit(): void {
    if (!this.checkIfNavigateToUnsupportedBrowser()) {
      this.fetchUser();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private checkIfNavigateToUnsupportedBrowser(): boolean {
    const unsupportedBrowser = this.supportedBrowsersService.isBrowserUnsupported();
    const browserWarningDisabled = this.supportedBrowsersService.readCookie() === 'true';

    if (unsupportedBrowser && !browserWarningDisabled) {
      this.router.navigate([SUPPORTED_BROWSERS_URL]);

      return true;
    }

    return false;
  }

  private fetchUser(): void {
    this.userService
      .fetchUser()
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.tryRedirectToActiveWorkspace();
        }),
        catchError(() => {
          this.tryRedirectToLogin();

          return of();
        }),
      )
      .subscribe();
  }

  private tryRedirectToLogin(): void {
    if (this.router.url === '/') {
      this.router.navigate([routeToUrl(EAuthRoute.LOGIN)]);
    }
  }

  private tryRedirectToActiveWorkspace(): void {
    const user = this.userService.getUser();

    if (user.activeWorkspaceId) {
      if (this.router.url === '/') {
        this.router.navigate(['/site', user.activeWorkspaceId]);
      }
    }
  }

  private checkUserAuth(): void {
    const userAuth = readUserAuthFromLocalStorage();

    if (userAuth?.expiration) {
      if (+userAuth.expiration < new Date().getTime()) {
        clearUserAuth();
      }
    }
  }
}
