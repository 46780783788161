import { createElement } from '@core/helpers';
import { TColumn } from '../../../../columns/column.model';
import { TTableItem } from '../../../../custom-table/table.model';
import { GET_COLLAPSED_GROUPS } from '../../../../table.ui.store';

export function getHeaderRowBackgroundElement(
  groupLevel: number,
  grouping: TColumn[],
  item: TTableItem,
): HTMLElement | string {
  const groupsCollapsed = GET_COLLAPSED_GROUPS();
  const classList = ['table_header_merged__background'];

  if (!groupsCollapsed.has(item.id)) {
    return '';
  }

  switch (groupLevel) {
    case 2:
      if (grouping.length === 3) {
        classList.push('table__row__grouping--second-color');
      } else if (grouping.length === 2) {
        classList.push('table_header_merged--level-2');
      }

      break;
    case 3:
      classList.push('table__row__grouping--third-color');
      break;
  }

  return createElement('div', {
    attrs: {
      class: classList.join(' '),
    },
  });
}
