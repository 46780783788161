<div [id]="elementId" (click)="toggleDropdown()">
  <pp-button *ngIf="!ppAssigneeId" ppStyle="secondary" ppSize="x-small">
    <span class="newAssigneeLabelButtonText">
      {{ 'new_asset_label_new_assignee_button' | ppTranslate }}
    </span>
  </pp-button>

  <img
    ppTooltip
    [ppTitle]="users[ppAssigneeId].userName"
    class="newAssetLabelUser"
    *ngIf="ppAssigneeId && users[ppAssigneeId]"
    [src]="users[ppAssigneeId].avatarPublicUrl"
  />
  <img
    class="newAssetLabelUser"
    *ngIf="ppAssigneeId && !users[ppAssigneeId]"
    [src]="EIconPath.PLACEHOLDER_USER"
  />
</div>
