import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Subject, debounceTime, takeUntil, tap } from 'rxjs';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventFiltering,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { SiteFilterDropdownService } from '../../filters/site-filter-dropdown/site-filter-dropdown-service/site-filter-dropdown.service';
import { AdvancedFilterService } from '../advanced-filter.service';
import {
  TAdvancedFilter,
  TAdvancedFilterSingle,
  TNewFilterPlaceholder,
} from '../models/advanced-filter.model';

@Component({
  selector: 'pp-advanced-filters-dropdown',
  templateUrl: './advanced-filters-dropdown.component.html',
  styleUrls: ['./advanced-filters-dropdown.component.scss'],
})
export class AdvancedFiltersDropdownComponent implements OnChanges, OnDestroy {
  @Input() ppWorkspaceId: string;

  EIconPath = EIconPath;
  animationDurationMs = 150;
  visibleFilters: {
    [index: number]: boolean;
  } = {};

  advancedFilter: TAdvancedFilter;
  private readonly applyFilters$ = new Subject<void>();
  private applyFiltersDebounceTimeMs = 2000; // It lags when you try to fill all the fields and it's trying to filter on every change
  private readonly destroy$ = new Subject<void>();

  constructor(
    private advancedFilterService: AdvancedFilterService,
    private siteFilterDropdownService: SiteFilterDropdownService,
  ) {
    this.applyFilters$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(this.applyFiltersDebounceTimeMs),
        tap(() => {
          this.siteFilterDropdownService.applyAdvancedFilters(this.ppWorkspaceId);
        }),
      )
      .subscribe();

    this.setVisibleFilters();
  }

  ngOnChanges(): void {
    this.setVisibleFilters();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  setVisibleFilters(): void {
    this.advancedFilter = this.advancedFilterService.getAdvancedFilter(this.ppWorkspaceId);

    this.advancedFilter.filters.forEach((filter, index) => {
      this.visibleFilters[index] = true;
    });
  }

  addNewFilter(): void {
    const newFilter: TNewFilterPlaceholder = {
      type: null,
      value: null,
      option: null,
      id: null,
    };

    this.advancedFilter.filters.push(newFilter);

    setTimeout(() => {
      this.visibleFilters[this.advancedFilter.filters.length - 1] = true;
    }, 20);

    logEventInGTAG(EGoogleEventFiltering.ADD_ADVANCED_FILTER, {
      event_category: EGoogleEventCategory.FILTERING,
    });
  }

  deleteFilter(index: number): void {
    this.visibleFilters[index] = false;

    setTimeout(() => {
      this.advancedFilter.filters.splice(index, 1);
      this.advancedFilterService.setAdvancedFilter(this.ppWorkspaceId, this.advancedFilter);

      this.applyFilters();
      this.setVisibleFilters();
    }, this.animationDurationMs);

    logEventInGTAG(EGoogleEventFiltering.DELETE_ADVANCED_FILTER, {
      event_category: EGoogleEventCategory.FILTERING,
    });
  }

  updateFilter(index: number, newFilter: TAdvancedFilterSingle): void {
    this.advancedFilter.filters[index] = newFilter;
    this.advancedFilterService.setAdvancedFilter(this.ppWorkspaceId, this.advancedFilter);

    this.applyFilters();

    logEventInGTAG(EGoogleEventFiltering.UPDATE_ADVANCED_FILTER, {
      event_category: EGoogleEventCategory.FILTERING,
      event_label: newFilter.type,
    });
  }

  applyFilters(): void {
    this.applyFilters$.next();
  }
}
