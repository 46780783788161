import { TPoint } from 'src/app/project/modules/points/points.model';
import { TFilters } from '../site-filter.model';

export function checkAssignees(point: TPoint, filters: TFilters): boolean {
  const assignees = point.assignees;

  if (filters.assignees.appliedValue.length === 0) {
    return true;
  }

  for (const filter of filters.assignees.appliedValue) {
    if (assignees.includes(filter)) {
      return true;
    }
  }

  return false;
}
