import { Client, IFrame, IMessage } from '@stomp/stompjs';

import { Inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { UpdateUserUnreadNotifications } from '../user/user.actions';

import { getAuthHeaders } from 'src/app/core/http/user-auth';
import { EInstance } from '../../shared/enums/instances';
import { SidePanelService } from '../layout/side-panel/side-panel.service';
import { NotificationsService } from '../notifications/notifications.service';
import { UserService } from '../user/user.service';
import { EUserType } from '../users/user-types-enum';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private client: Client;
  private connectUrl = '';
  private notificationsUrl = '';
  private outgoingHeartbeatMs = 29 * 1000;
  private incomingHeartbeatMs = 0;
  private reconnectDelayMs = 60 * 1000;
  private connectionTimeoutMs = 30 * 1000;

  constructor(
    @Inject('HOSTNAME') private host: string,
    private store: Store,
    private notificationsService: NotificationsService,
    private sidePanelService: SidePanelService,
    private userService: UserService,
  ) {
    this.connectUrl = `wss://${this.host}/api/v1/ws-endpoint`;
    this.notificationsUrl = '/user/topic/notifications';

    if (this.host === 'localhost:4200') {
      this.connectUrl = `wss://${EInstance.CLONE}/api/v1/ws-endpoint`; // localhost clone
    }

    // this.connectUrl = `wss://${EInstance.CLONE}/api/v1/ws-endpoint`; // localhost clone
    // this.connectUrl = `wss://${EInstance.CLONE2}/api/v1/ws-endpoint`; // localhost clone2
    // this.connectUrl = `wss://${EInstance.CLONE3}/api/v1/ws-endpoint`; // localhost clone3
    // this.connectUrl = 'ws://localhost:8080/api/v1/ws-endpoint'; // For local backend
  }

  connect(): void {
    if (!this.client) {
      const user = this.userService.getUser();

      this.client = new Client({
        brokerURL: this.connectUrl,
        connectHeaders: getAuthHeaders(),
        heartbeatIncoming: this.incomingHeartbeatMs,
        heartbeatOutgoing: this.outgoingHeartbeatMs,
        reconnectDelay: this.reconnectDelayMs,
        connectionTimeout: this.connectionTimeoutMs,
      });

      if (user.userType !== EUserType.DEVELOPER && user.userType !== EUserType.TESTER) {
        this.client.debug = (): void => {};
      }

      this.client.onConnect = (frame): void => {
        this.logDevMessage('Websocket - connected');
        this.logDevMessage(frame);
        this.connectCallback();
      };

      this.client.onStompError = (error): void => {
        this.errorCallback(error);
      };

      this.client.onWebSocketError = (error: any): void => {
        this.errorCallback(error);
      };

      this.client.onWebSocketClose = (closeEvent: CloseEvent): void => {
        this.logDevMessage('Websocket - closed');
        this.logDevMessage(closeEvent);
      };

      this.client.activate();
    }
  }

  disconnect(): void {
    if (this.client) {
      this.client.deactivate();
    }

    this.client = null;
  }

  private subscribeCallback(_message: IMessage): void {
    this.logDevMessage('Websocket - received a message');
    this.logDevMessage(_message);

    if (_message.body) {
      if (!this.sidePanelService.getSidePanel().notificationsExpanded) {
        this.store.dispatch(new UpdateUserUnreadNotifications(true));
      }

      this.notificationsService.checkForNewNotifications();
    } else {
      this.logDevMessage('Websocket - received an empty message');
    }
  }

  private errorCallback(error: IFrame): void {
    this.logDevMessage(error);

    this.disconnect();
  }

  private connectCallback(): void {
    if (this.client) {
      this.logDevMessage('Connected');

      this.client.subscribe(this.notificationsUrl, (message) => this.subscribeCallback(message));
    }
  }

  private logDevMessage(message): void {
    const user = this.userService.getUser();

    if (user.userType === EUserType.DEVELOPER || user.userType === EUserType.TESTER) {
      console.log(message);
    }
  }
}
