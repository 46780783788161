import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { AdvancedFilterService } from '../filters-advanced/advanced-filter.service';
import { EAdvancedFilterLogic } from '../filters-advanced/models/advanced-filter-logic.enum';
import { ClearFilterService } from '../filters/clear-filter-service/clear-filter.service';
import { SiteFilterDropdownService } from '../filters/site-filter-dropdown/site-filter-dropdown-service/site-filter-dropdown.service';
import { TFilters } from '../filters/site-filter.model';
import { SiteOptionColumnDropdownService } from './site-options/site-options-column-dropdown/site-option-column-dropdown.service';
import { GroupButtonDropdownService } from './site-options/site-options-group-button/group-button-dropdown/group-button-dropdown.service';
import { SortButtonDropdownService } from './site-options/site-options-sort-button/sort-button-dropdown/sort-button-dropdown.service';
import { SiteOptionsService } from './site-options/site-options.service';

@Injectable({
  providedIn: 'root',
})
export class ResetTableService {
  constructor(
    private clearFilterService: ClearFilterService,
    private advancedFilterService: AdvancedFilterService,
    private siteFilterDropdownService: SiteFilterDropdownService,
    private sortButtonDropdownService: SortButtonDropdownService,
    private groupButtonDropdownService: GroupButtonDropdownService,
    private siteOptionColumnDropdownService: SiteOptionColumnDropdownService,
    private siteOptionsService: SiteOptionsService,
  ) {}

  resetTable(workspaceId: string): void {
    this.resetColumns(workspaceId, true);
    this.resetFilters(workspaceId);
    this.resetSorting();
    this.resetGrouping();
  }

  resetFilters(workspaceId: string): TFilters {
    const filters = this.clearFilterService.clearFilters();
    this.advancedFilterService.setAdvancedFilter(workspaceId, {
      enabled: false,
      filters: [],
      logic: EAdvancedFilterLogic.AND,
    });
    this.siteFilterDropdownService.applyFilters(filters, workspaceId);

    return cloneDeep(filters);
  }

  resetSorting(): void {
    this.sortButtonDropdownService.removeSorting();
  }

  resetGrouping(): void {
    this.groupButtonDropdownService.removeGrouping();

    const groupButton = this.siteOptionsService.getGroupButton();

    if (groupButton) {
      groupButton.update();
    }
  }

  resetColumns(workspaceId: string, resetGroupingAndSorting = false): void {
    this.siteOptionColumnDropdownService.resetColumns(workspaceId, resetGroupingAndSorting);
  }
}
