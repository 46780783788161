import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { GET_HIGHLIGHTED_SITE } from '../utils/account-list.ui.store';

export function getClassList(site: TWorkspace, folderId: string, draggable: boolean): string[] {
  const classList = ['site-nav__element'];
  const highlightedSite = GET_HIGHLIGHTED_SITE();

  if (site.workspaceId === highlightedSite) {
    classList.push('siteNav--highlight');
  }

  if (window.location.href.includes(site.workspaceId)) {
    classList.push('siteNav--active');
  }

  if (folderId) {
    if (draggable) {
      classList.push('site-nav__inFolder--draggable');
    } else {
      classList.push('site-nav__inFolder');
    }
  } else if (draggable) {
    classList.push('site-nav__draggable');
  }
  return classList;
}
