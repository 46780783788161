import { Injectable } from '@angular/core';
import { TFilters } from 'src/app/project/modules/filters/site-filter.model';
import { TPlanData, PlanDataService } from 'src/app/project/modules/plan/plan-data.service';
import { Modal, ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { PointsService } from '../points/points.service';
import { PointExportZipService } from './point-export-zip.service';
import { PointExportService } from './point-export.service';
import { TExportCustomField } from './utils/export-custom-field';
import { TExportData } from './utils/export-data';
import { EExportTypeName } from './utils/export-type-names-enum';
import { Observable } from 'rxjs';
import { getCoords } from './utils/get-coords';
import { EPlanModule } from '../plan/plan-module.enum';

@Injectable({
  providedIn: 'root',
})
export class PointExportPdfService {
  private modal: Modal = this.modalService.getModal();
  private planData: TPlanData = this.planDataService.getPlan();

  constructor(
    private translationPipe: TranslationPipe,
    private workspaceService: WorkspaceService,
    private pointExportZipService: PointExportZipService,
    private pointsService: PointsService,
    private promptService: PromptService,
    private pointExportService: PointExportService,
    private planDataService: PlanDataService,
    private modalService: ModalService,
  ) {}

  exportPdf(
    exportedCustomFields: TExportCustomField[],
    exportData: TExportData,
    filters: TFilters,
  ): Observable<Response> {
    const customFields: string[] = [];
    const workspaces = this.workspaceService.getWorkspaces();

    let pointWithoutAttachments = false;

    exportedCustomFields.forEach((field) => {
      if (field.enabled) {
        customFields.push(field.id);
      }
    });

    const coords = getCoords(
      workspaces,
      this.modal.data.workspaceId,
      this.planData,
      EPlanModule.POINT,
    );

    if (this.modal.data._id) {
      const point = this.pointsService.findPoint(this.modal.data._id);

      if (!point.containsAttachments) {
        pointWithoutAttachments = true;
      }
    }

    if (exportData.showDocuments && !pointWithoutAttachments) {
      return this.exportToZip(exportData, filters, customFields);
    } else {
      return this.exportSinglePdf(customFields, exportData, filters, coords);
    }
  }

  private exportSinglePdf(
    customFields: string[],
    exportData: TExportData,
    filters: TFilters,
    coords: string[],
  ): Observable<Response> {
    const promptText = this.translationPipe.transform('prompt_export_start_single');

    this.promptService.showSuccess(promptText, { duration: 15 });

    return this.pointExportService.export({
      workspaceId: this.modal.data.workspaceId,
      pointIds: [this.modal.data._id],
      customFieldIds: customFields,
      exportData: exportData,
      filters: filters,
      isPdf: false,
      exportType: EExportTypeName.PDF,
      coords,
      isOverview: false,
    });
  }

  private exportToZip(
    exportData: TExportData,
    filters: TFilters,
    customFields: string[],
  ): Observable<Response> {
    return this.pointExportZipService.exportZip({
      _id: this.modal.data._id,
      isPdf: true,
      data: this.modal.data,
      exportData: exportData,
      filters: filters,
      exportedCustomFields: customFields,
    });
  }
}
