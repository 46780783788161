import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { CustomFieldsService } from '../custom-fields/custom-fields.service';
import { checkCustomWorkspaceId } from '../workspace/workspace';
import { TWorkspace } from '../workspace/workspace.model';
import { createCustomFieldFilters } from './site-filter-data-service/create-custom-field-filter';
import { getDefaultFilter } from './site-filter-data-service/default-filter';
import { SetSiteFilter } from './site-filter.actions';
import { TAllFilters, TFilters } from './site-filter.model';

@Injectable({
  providedIn: 'root',
})
export class DefaultFilterService {
  constructor(private store: Store, private customFieldsService: CustomFieldsService) {}

  setDefaultFilter(allFilters: TAllFilters, workspaceId: string): void {
    const filters = getDefaultFilter();
    const currentWorkspaceId = workspaceId || checkCustomWorkspaceId();

    if (typeof allFilters !== 'undefined') {
      allFilters[workspaceId] = filters;
    }

    this.store.dispatch(
      new SetSiteFilter({
        filter: filters,
        workspaceId: currentWorkspaceId,
      }),
    );
  }

  generateDefaultFilter({
    filter,
    workspace,
    workspaceId,
  }: {
    filter?: TFilters;
    workspace: TWorkspace;
    workspaceId: string;
  }): void {
    const customFields = this.customFieldsService.getCustomFields();
    let newFilter = this.checkExistingFilter(filter);

    newFilter = createCustomFieldFilters({
      filters: cloneDeep(newFilter),
      savedFilter: {},
      workspace,
      customFields,
    });

    this.store.dispatch(
      new SetSiteFilter({
        filter: newFilter,
        workspaceId: workspaceId,
      }),
    );
  }

  checkHasAllKeys(filter: TFilters, defaultFilter: TFilters): TFilters {
    let defaultKeys = Object.keys(defaultFilter);
    let newFilter = cloneDeep(filter);

    defaultKeys.forEach((key) => {
      newFilter[key] = filter[key] !== undefined ? filter[key] : defaultFilter[key];
    });
    return newFilter;
  }

  private checkExistingFilter(filter: TFilters): TFilters {
    let newFilter = filter;

    if (filter && Object.keys(filter).length > 0) {
      newFilter = this.checkHasAllKeys(filter, getDefaultFilter());
    } else {
      newFilter = getDefaultFilter();
    }

    return cloneDeep(newFilter);
  }
}
