import { Injectable } from '@angular/core';

import { TDashletGraph } from '../dashlet-graph.model';
import { EDashletType } from '../dashlets-enums';
import { addGraphDatasetLabels } from './add-graph-dataset-labels';
import { addGraphLabels } from './add-graph-labels';
import { createChartLabels } from './create-chart-labels';

@Injectable({
  providedIn: 'root',
})
export class DashboardChartService {
  createChartLabels(capitalizedPeriodWord: string, length: number, period: string): string[] {
    return createChartLabels(capitalizedPeriodWord, length, period);
  }

  addGraphLabels(type: EDashletType, data: number[]): string[] {
    return addGraphLabels(type, data);
  }

  addGraphDatasetLabels(graph: TDashletGraph, type: EDashletType, data: number[][]): void {
    addGraphDatasetLabels(graph, type, data);
  }
}
