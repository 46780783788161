import { GridsterConfig, GridsterItem } from 'angular-gridster2';

import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

import { SetActiveAccountId } from '../../services/active/active.actions';
import { TDashlet, TPreferences } from '../preferences/preferences.model';
import { TUISettings } from '../ui/ui.model';

import { DeviceService } from '../../../core/services/device.service';
import { SidePanelService } from '../layout/side-panel/side-panel.service';
import { SyncService } from '../offline/sync.service';
import { PreferencesService } from '../preferences/preferences-service/preferences.service';
import { SiteDataService, TSiteData } from '../site/site-data.service';
import { UserService } from '../user/user.service';
import { WorkspaceService } from '../workspace/workspace.service';
import { DashboardService } from './dashboard-service/dashboard.service';
import { DashletEventsService } from './dashlet/dashlet-events/dashlet-events.service';

import { Title } from '@angular/platform-browser';
import { logEventInGTAG } from '../../services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventDashboard,
} from '../../services/analytics/google-analytics.consts';
import { EStore } from '../../shared/enums/store.enum';
import { TSidePanel } from '../layout/side-panel/side-panel.model';
import { SiteService } from '../site/site.service';
import {
  getGridsterConfig,
  getGridsterConfigOffline,
  getGridsterConfigTouch,
  TGridsterItemCallbacks,
} from './dashboard-gridster-config/gridster-config';
import { DashboardDataFetchingService } from './dashboard-data-fetching.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  site: TSiteData = this.siteDataService.getSite();
  sidePanel: TSidePanel = this.sidePanelService.getSidePanel();
  workspacesFetched = false;
  dashlets: TDashlet[];

  ui$: Observable<TUISettings>;
  ui: TUISettings;
  offline$: Observable<boolean>;
  offline = false;
  private preferences$: Observable<TPreferences>;

  gridsterOptions: GridsterConfig;
  isMobile = false;
  isTouchDevice = false;

  dashboard: Array<GridsterItem>;
  private readonly destroy$ = new Subject<void>();
  private readonly dashletsLoaded$ = new Subject<void>();

  constructor(
    private store: Store<{ ui: TUISettings; preferences: TPreferences; offline: boolean }>,
    private userService: UserService,
    private siteDataService: SiteDataService,
    private sidePanelService: SidePanelService,
    private dashboardService: DashboardService,
    private syncService: SyncService,
    private deviceService: DeviceService,
    private workspaceService: WorkspaceService,
    private preferencesService: PreferencesService,
    private dashletEventsService: DashletEventsService,
    private titleService: Title,
    private siteService: SiteService,
    private dashletReportsService: DashboardDataFetchingService,
  ) {
    this.preferences$ = this.store.pipe(select(EStore.PREFERENCES));
    this.ui$ = this.store.pipe(select(EStore.UI));
    this.offline$ = this.store.pipe(select(EStore.OFFLINE));
    this.isMobile = this.deviceService.isMobile();
    this.isTouchDevice = this.deviceService.isTouchDevice();
  }

  ngOnInit() {
    this.titleService.setTitle('Dashboard | Pinpoint Works');
    this.store.dispatch(new SetActiveAccountId(null));
    this.siteService.setFetched(true);

    this.initDashlets();

    this.sidePanelService.initSidePanel({ expandedInitially: false });

    this.offline$.pipe(takeUntil(this.destroy$)).subscribe((offline) => {
      this.offlineParamsHandler(offline);
    });

    this.ui$.pipe(takeUntil(this.destroy$)).subscribe((ui) => {
      this.ui = ui;
    });

    this.dashboardService.dashlets$.subscribe((dashlets) => {
      this.dashlets = dashlets;
    });

    this.workspaceService
      .generateWorkspaces()
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.workspacesFetched = true;
        }),
      )
      .subscribe();
  }

  ngAfterViewInit() {
    if (this.isMobile && screen.orientation.angle === 0) {
      this.dashboardService.setBodyTagFixed();

      this.destroy$
        .pipe(
          take(1),
          tap(() => {
            this.dashboardService.unsetBodyTagFixed();
          }),
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    this.dashletEventsService.clearDashletData();
    this.dashletReportsService.clearDashletData();
    this.destroy$.next();
  }

  updateUserDashlets(skipCheck: boolean = true): void {
    this.dashboardService.updateUserDashlets(skipCheck);
  }

  dashletTrackByName(index: number, item: TDashlet): string {
    return item.name;
  }

  private initDashlets(): void {
    const user = this.userService.getUser();
    const preferences = this.preferencesService.getPreferences();

    if (user.userId && preferences.dashlets) {
      this.dashboardService.initDashlets();
    } else {
      this.syncService
        .firstLoad()
        .pipe(
          takeUntil(this.destroy$),
          tap(() => {
            this.preferences$.pipe(takeUntil(this.dashletsLoaded$)).subscribe((newPreferences) => {
              if (newPreferences.dashlets) {
                this.dashletsLoaded$.next();

                this.dashboardService.initDashlets();
              }
            });
          }),
        )
        .subscribe();
    }
  }

  private offlineParamsHandler(offline: boolean): void {
    this.offline = offline;

    if (this.isTouchDevice) {
      this.gridsterOptions = getGridsterConfigTouch();
    } else {
      const gridsterCallbacks: TGridsterItemCallbacks = {
        itemChangeCallback: () => this.itemChange(),
        itemResizeCallback: () => this.itemResize(),
      };

      if (!this.offline) {
        this.gridsterOptions = getGridsterConfig(gridsterCallbacks);
      } else {
        this.gridsterOptions = getGridsterConfigOffline(gridsterCallbacks);
      }
    }
  }

  private itemChange(): void {
    logEventInGTAG(EGoogleEventDashboard.DASHBOARD__DASHLET__CHANGE_POSITION, {
      event_category: EGoogleEventCategory.DASHBOARD,
    });

    this.updateUserDashlets();
  }

  private itemResize(): void {
    this.updateUserDashlets(false);
  }
}
