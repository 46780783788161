import { GET_SCROLL_POSITION, SET_SCROLL_POSITION } from '../utils/account-list.ui.store';
import { GET_ACTIVE } from 'src/app/project/services/active/active.store';

import VirtualScroller from '../../external/virtual-scroller/VirtualScroller';
import { groupAccountListData } from './group-account-list-data';
import { addFolderElement } from './account-list-folder';
import { addAccountElement } from './account-list-account';
import { addSiteElement } from './account-list-site';
import { addCreateSiteElement } from './account-list-create-site';
import { addEmptyFolderMessageElement } from './account-list-empty-folder-message';
import { dragoverCallback } from './account-list-dragover-callback';
import { flattenAccounts, TFlattenedAccount } from './account-list-flatten-account';
import {
  checkChangedElements,
  checkDeletedIndexes,
  checkMissingElements,
  checkNewAccountData,
  checkOldAccountData,
} from './account-list-update-utils';
import { TAccountSimpleResponse } from 'src/app/project/view-models/account-simple-response.model';
import { ESidePanelItem } from '../utils/side-panel-item.enum';
import { TAnyFunction } from '@core/helpers';
import { isBrowserSafari } from 'src/app/core/helpers/device';
import { ApiService } from '@core/http';
import { createEmptyElement } from '../utils/create-empty-element';

export type TAccountListCallbacks = {
  accountSettingsCallback: (_buttonId: string, _accountId: string) => void;
  folderSettingsCallback: ({
    buttonId,
    accountId,
    folderId,
  }: {
    buttonId: string;
    accountId: string;
    folderId: string;
  }) => void;
  siteSettingsCallback: (_buttonId: string, _workspaceId: string) => void;
  openSiteCallback: (_workspaceId: string) => void;
  createSiteCallback: (_accountId: string) => void;
  editFolderCallback: (folder) => void;
  createFolderCallback: (folder) => void;
  errorCallback: (level: string, error: string) => void;
  addSiteToFolderCallback: (workspaceId: string, accountId: string, folderId: string) => void;
  removeSiteFromFolderCallback: (workspaceId: string, accountId: string) => void;
  cancelCallback: (accountId: string) => void;
};

export default class AccountList {
  private parentElement: HTMLElement;
  private accountSettingsCallback: TAnyFunction;
  private siteSettingsCallback: TAnyFunction;
  private openSiteCallback: TAnyFunction;
  private createSiteCallback: TAnyFunction;
  private folderSettingsCallback: TAnyFunction;
  private createFolderCallback: TAnyFunction;
  private editFolderCallback: TAnyFunction;
  private errorCallback: TAnyFunction;
  private addSiteToFolderCallback: TAnyFunction;
  private removeSiteFromFolderCallback: TAnyFunction;
  private cancelCallback: TAnyFunction;

  private accounts: TAccountSimpleResponse[];
  private accountsFlattened: TFlattenedAccount[];
  private virtualScroller: VirtualScroller;
  private apiService: ApiService;

  private topShadow = null;
  private bottomShadow = null;

  constructor(
    _parentElement: HTMLElement,
    _accounts: TAccountSimpleResponse[],
    apiService: ApiService,
    callbacks: TAccountListCallbacks,
  ) {
    this.parentElement = _parentElement;
    this.accounts = _accounts;
    this.accountSettingsCallback = callbacks.accountSettingsCallback;
    this.folderSettingsCallback = callbacks.folderSettingsCallback;
    this.siteSettingsCallback = callbacks.siteSettingsCallback;
    this.openSiteCallback = callbacks.openSiteCallback;
    this.createSiteCallback = callbacks.createSiteCallback;
    this.createFolderCallback = callbacks.createFolderCallback;
    this.editFolderCallback = callbacks.editFolderCallback;
    this.errorCallback = callbacks.errorCallback;
    this.addSiteToFolderCallback = callbacks.addSiteToFolderCallback;
    this.removeSiteFromFolderCallback = callbacks.removeSiteFromFolderCallback;
    this.cancelCallback = callbacks.cancelCallback;
    this.apiService = apiService;

    this.accounts = groupAccountListData(this.accounts);
    this.accountsFlattened = flattenAccounts(this.accounts);

    const scrollPosition = GET_SCROLL_POSITION();

    this.virtualScroller = new VirtualScroller(this.parentElement, this.accountsFlattened, {
      rowHeight: 35,
      createElementCallback: (_index: number): HTMLElement => this.addMissingElement(_index),
      scrollTopCallback: (_event: Event): void => {
        if (_event.target instanceof HTMLElement) {
          const isSafari = isBrowserSafari();

          if (!isSafari) {
            this.toggleShadows(_event.target);
          }

          SET_SCROLL_POSITION(_event.target.scrollTop);
        }
      },
      scrollPosition: scrollPosition || 0,
      marginBottom: 7,
      scrollbarTheme: 'os-theme-side-panel',
    });

    if (scrollPosition === null) {
      const activeWorkspaceId = GET_ACTIVE().workspaceId;
      const activeIndex = this.accountsFlattened.findIndex(
        (_item) => _item.workspaceId === activeWorkspaceId,
      );

      if (activeIndex > -1) {
        this.virtualScroller.disableSmoothScrolling();
        this.virtualScroller.scrollTo(activeIndex);
        this.virtualScroller.enableSmoothScrolling();
      }
    }
  }

  addMissingElement(_index: number): HTMLElement {
    if (!this.accountsFlattened[_index]) {
      return createEmptyElement();
    } else if (this.accountsFlattened[_index].type === ESidePanelItem.ACCOUNT) {
      return addAccountElement({
        _index,
        virtualScroller: this.virtualScroller,
        accountsFlattened: this.accountsFlattened,
        apiService: this.apiService,
        accountSettingsCallback: (_buttonId, _accountId) =>
          this.accountSettingsCallback(_buttonId, _accountId),
        accounts: this.accounts,
        load: (data, keepScrollPosition) => this.load(data, keepScrollPosition),
      });
    } else if (this.accountsFlattened[_index].type === ESidePanelItem.WORKSPACE) {
      return addSiteElement({
        _index,
        virtualScroller: this.virtualScroller,
        apiService: this.apiService,
        accountsFlattened: this.accountsFlattened,
        siteSettingsCallback: (_buttonId, _workspaceId) =>
          this.siteSettingsCallback(_buttonId, _workspaceId),
        openSiteCallback: (workspaceId) => this.openSiteCallback(workspaceId),
        addSiteToFolderCallback: (workspaceId, accountId, folderId) =>
          this.addSiteToFolderCallback(workspaceId, accountId, folderId),
        removeSiteFromFolderCallback: (workspaceId, accountId) =>
          this.removeSiteFromFolderCallback(workspaceId, accountId),
        dragoverCallback: (folderId) => dragoverCallback(folderId, this.virtualScroller),
      });
    } else if (this.accountsFlattened[_index].type === ESidePanelItem.FOLDER) {
      return addFolderElement({
        _index,
        virtualScroller: this.virtualScroller,
        accountsFlattened: this.accountsFlattened,
        apiService: this.apiService,
        folderSettingsCallback: ({ buttonId, accountId, folderId }) =>
          this.folderSettingsCallback({ buttonId, accountId, folderId }),
        cancelCallback: (accountId) => this.cancelCallback(accountId),
        createFolderCallback: (folder) => this.createFolderCallback(folder),
        editFolderCallback: (folder) => this.editFolderCallback(folder),
        load: (data, keepScrollPosition) => this.load(data, keepScrollPosition),
        errorCallback: (level, error) => this.errorCallback(level, error),
        dragoverCallback: (folderId) => dragoverCallback(folderId, this.virtualScroller),
        accounts: this.accounts,
      });
    } else if (this.accountsFlattened[_index].type === ESidePanelItem.NEW_SITE_BUTTON) {
      return addCreateSiteElement({
        _index,
        apiService: this.apiService,
        accountsFlattened: this.accountsFlattened,
        virtualScroller: this.virtualScroller,
        createSiteCallback: (accountId) => this.createSiteCallback(accountId),
        removeSiteFromFolderCallback: (workspaceId, accountId) =>
          this.removeSiteFromFolderCallback(workspaceId, accountId),
        dragoverCallback: (folderId) => dragoverCallback(folderId, this.virtualScroller),
      });
    } else if (this.accountsFlattened[_index].type === ESidePanelItem.FOLDER_EMPTY) {
      return addEmptyFolderMessageElement({
        _index,
        accountsFlattened: this.accountsFlattened,
        virtualScroller: this.virtualScroller,
        dragoverCallback: (folderId) => dragoverCallback(folderId, this.virtualScroller),
      });
    }
  }

  update(_oldData: TAccountSimpleResponse[], _newData: TAccountSimpleResponse[]): void {
    const oldAccountData = _oldData;
    const newAccountData = _newData;

    const { missing, changed } = checkNewAccountData({
      newAccountData,
      oldAccountData,
    });

    const deleted = checkOldAccountData({ oldAccountData, newAccountData });

    this.accounts = groupAccountListData(newAccountData);
    this.accountsFlattened = flattenAccounts(this.accounts);

    const missingIndexes = checkMissingElements(missing, this.accountsFlattened);
    const changedIndexes = checkChangedElements(changed);
    const deletedIndexes = checkDeletedIndexes(deleted);

    if (missingIndexes.length || changedIndexes.length || deletedIndexes.length) {
      this.virtualScroller.load(this.accountsFlattened, true);
    }
  }

  load(_data: TAccountSimpleResponse[], _keepScrollPosition: boolean = false): void {
    this.accounts = groupAccountListData(_data);
    this.accountsFlattened = flattenAccounts(this.accounts);

    this.virtualScroller.load(this.accountsFlattened, _keepScrollPosition);
  }

  private toggleShadows(target: HTMLElement): void {
    const top = target.scrollTop;
    const scrolledToBottom = target.scrollHeight - top === target.clientHeight;

    if (!this.topShadow) {
      this.topShadow = this.parentElement.getElementsByClassName(
        'sidePanel__right__shadow--top',
      )[0];
    }

    if (top === 0) {
      this.topShadow.style.visibility = 'hidden';
    } else {
      this.topShadow.style.visibility = 'visible';
    }

    if (!this.bottomShadow) {
      this.bottomShadow = this.parentElement.getElementsByClassName(
        'sidePanel__right__shadow--bottom',
      )[0];
    }

    if (scrolledToBottom) {
      this.bottomShadow.style.visibility = 'hidden';
    } else {
      this.bottomShadow.style.visibility = 'visible';
    }
  }
}
