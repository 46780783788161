import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';

import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pp-column-resizer',
  templateUrl: './site-column-resizer.component.html',
  styleUrls: ['./site-column-resizer.component.scss'],
})
export class SiteColumnResizerComponent implements OnDestroy {
  @Output() ppDrag = new EventEmitter();
  @Output() ppDrop = new EventEmitter();

  private readonly destroy$ = new Subject<void>();
  private readonly drop$ = new Subject<void>();

  private startResizing = false;

  constructor() {}

  ngOnDestroy() {
    this.destroy$.next();
  }

  onMouseDown(event: Event): void {
    event.stopImmediatePropagation();
    event.preventDefault();
    event.stopPropagation();

    this.startResizing = true;
    this.initResizableSitePlan();
  }

  initResizableSitePlan(): void {
    let positionChanged = false;

    fromEvent(document, 'mousemove')
      .pipe(takeUntil(this.drop$))
      .subscribe((event: MouseEvent) => {
        if (this.startResizing) {
          positionChanged = true;
          this.ppDrag.emit(event.clientX);
        }
      });

    fromEvent(document, 'mouseup')
      .pipe(takeUntil(this.drop$))
      .subscribe((event) => {
        event.stopImmediatePropagation();
        event.preventDefault();
        event.stopPropagation();

        if (this.startResizing) {
          this.startResizing = false;
          this.drop$.next();

          if (positionChanged) {
            this.ppDrop.emit();
          }
        }
      });
  }
}
