import { Component, OnDestroy, OnInit } from '@angular/core';
import { uuid } from '@core/helpers';
import { Subject, takeUntil } from 'rxjs';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { getDefaultFleetStatuses } from 'src/app/project/modules/fleet-management/fleet-management-empty-fleet/utils/get-default-fleet-statuses';
import { FleetManagementRoutesService } from 'src/app/project/modules/fleet-management/fleet-management-routes.service';
import { FleetService } from 'src/app/project/modules/fleet-management/fleet-service/fleet.service';
import { TFleet, TFleetByAccount } from 'src/app/project/modules/fleet-management/fleet.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TopBarFeetOptionsFleetSelectDropdownComponent } from './top-bar-feet-options-fleet-select-dropdown/top-bar-feet-options-fleet-select-dropdown.component';
import {
  TTopBarFleetOptionsFleetSelectDropdownData,
  TTopBarFleetOptionsFleetSelectDropdownSelectOption,
} from './top-bar-feet-options-fleet-select-dropdown/top-bar-fleet-options-fleet-select-dropdown.consts';

@Component({
  selector: 'pp-top-bar-fleet-options-fleet-select',
  templateUrl: './top-bar-fleet-options-fleet-select.component.html',
  styleUrls: ['./top-bar-fleet-options-fleet-select.component.scss'],
})
export class TopBarFleetOptionsFleetSelectComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  selectOptions: TTopBarFleetOptionsFleetSelectDropdownSelectOption[] = [];
  selectedIndex = -1;
  buttonId = uuid();
  EIconPath = EIconPath;
  dropdown: TDropdown = this.dropdownService.getDropdown();

  constructor(
    private fleetService: FleetService,
    private fleetManagementRoutesService: FleetManagementRoutesService,
    private dropdownService: DropdownService,
    private accountService: AccountService,
  ) {}

  ngOnInit(): void {
    this.setFleetList(this.fleetService.getFleets());

    this.fleetService.fleetChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe((fleet: TFleetByAccount) => {
        this.setFleetList(fleet);
        this.setDropdownDataIfOpened();
      });

    this.fleetService.activeFleetChange$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setSelectedOption();
      this.setDropdownDataIfOpened();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  selectItem(item: TTopBarFleetOptionsFleetSelectDropdownSelectOption) {
    this.selectedIndex = this.selectOptions.findIndex((i) => i.value === item.value);

    this.fleetService.setActiveFleetId(item.value);
    this.fleetManagementRoutesService.goToFleet(item.value);
  }

  toggleSelectDropdown(): void {
    if (this.dropdown.visible && this.dropdown.buttonId === this.buttonId) {
      this.dropdownService.hideDropdown();
    } else {
      this.setDropdownData();

      this.dropdownService.showDropdown(
        this.buttonId,
        TopBarFeetOptionsFleetSelectDropdownComponent,
        {
          callback: (option) => this.selectItem(option),
          popper: {
            positionFixed: true,
            placement: 'bottom',
          },
        },
      );
    }
  }

  private setDropdownDataIfOpened(): void {
    if (this.dropdown.visible && this.dropdown.buttonId === this.buttonId) {
      this.setDropdownData();
    }
  }

  private setDropdownData(): void {
    const dropdownData: TTopBarFleetOptionsFleetSelectDropdownData = {
      items: this.selectOptions,
      selectedItem: this.selectOptions[this.selectedIndex],
    };

    this.dropdownService.setData(dropdownData);
  }

  private setFleetList(fleet: TFleetByAccount): void {
    let fleetList: TFleet[] = [];

    Object.keys(fleet).forEach((accountId) => {
      fleetList = [...fleetList, ...Object.values(fleet[accountId])];
    });

    this.setSelectOptions(fleetList);
    this.setSelectedOption();
  }

  private setSelectOptions(fleetList: TFleet[]): void {
    this.selectOptions = fleetList.map((fleet) => {
      fleet.statusSettings = fleet.statusSettings || getDefaultFleetStatuses();
      const account = this.accountService.getAccount(fleet.accountId);

      const displayAccountName = account ? account.accountName : 'Pinpoint Works';

      const tooltip = `${displayAccountName}/ ${fleet.name}`;

      return {
        value: fleet.id,
        label: fleet.name,
        tooltip: tooltip,
      };
    });
  }

  private setSelectedOption(): void {
    const activeFleet = this.fleetService.getActiveFleetId();

    if (activeFleet) {
      this.selectedIndex = this.selectOptions.findIndex((option) => option.value === activeFleet);
    } else {
      this.selectedIndex = -1;
    }
  }
}
