import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RichTextComponent } from './rich-text.component';
import { SpinnerModule } from '../../spinner/spinner.module';
import { SaveIndicatorModule } from '../../save-indicator/save-indicator.module';
import { ButtonModule } from '../../button/button.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { AutonumericModule } from 'src/app/project/directives/autonumeric/autonumeric.module';
import { FocusModule } from 'src/app/project/directives/focus/focus.module';
import { ImagesFallbackModule } from 'src/app/project/directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from 'src/app/project/directives/on-rendered/on-rendered.module';
import { SwitchCasesModule } from 'src/app/project/directives/switch-cases/switch-cases.module';
import { TooltipsModule } from 'src/app/project/features/tooltip/tooltips.module';

@NgModule({
  declarations: [RichTextComponent],
  imports: [
    CommonModule,
    SpinnerModule,
    SaveIndicatorModule,
    SaveIndicatorModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    ButtonModule,
    PipesModule,
  ],
  exports: [RichTextComponent],
})
export class RichTextModule {}
