import { Component, Input } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TTranslationKey } from '../../features/translate/types';

@Component({
  selector: 'pp-warning-box',
  templateUrl: './warning-box.component.html',
  styleUrls: ['./warning-box.component.scss'],
})
export class WarningBoxComponent {
  @Input() ppOpeningMessageKey: TTranslationKey;
  @Input() ppClosingMessageKey: TTranslationKey;
  @Input() ppWarningMessages: string[];

  EIconPath = EIconPath;
}
