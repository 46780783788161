import { TTimelineColumns } from './timeline-timeframes';
import { getDayTopElements } from './get-day-top-elements';
import { getDayTimeframes } from './get-day-timeframe';
import { getDayBottomElements } from './get-day-bottom-elements';

// --------------------------------------------------
// --------------------- DAY ------------------------
// --------------------------------------------------

export function setDay(from: Date, to: Date): TTimelineColumns {
  return {
    topWidth: 343,
    bottomWidth: 49,

    topElements: getDayTopElements(from, to),
    bottomElements: getDayBottomElements(from, to),
    timeframes: getDayTimeframes(from, to),
  };
}
