import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button.component';
import { SpinnerModule } from '../spinner/spinner.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { AutonumericModule } from '../../directives/autonumeric/autonumeric.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SwitchCasesModule } from '../../directives/switch-cases/switch-cases.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { ButtonModalCancelComponent } from '../buttons/modal-buttons/button-modal-cancel/button-modal-cancel.component';
import { ButtonModalConfirmComponent } from '../buttons/modal-buttons/button-modal-confirm/button-modal-confirm.component';
import { PriorityButtonComponent } from '../buttons/priority-button/priority-button.component';
import { StatusButtonComponent } from '../buttons/status-button/status-button.component';
import { NewSiteButtonComponent } from '../buttons/new-site-button/new-site-button.component';
import { MobileNewPointButtonComponent } from '../buttons/mobile-new-point-button/mobile-new-point-button.component';
import { EditButtonComponent } from '../buttons/edit-button/edit-button.component';
import { NewSitePlanButtonComponent } from '../buttons/new-site-plan-button/new-site-plan-button.component';
import { UploadModule } from '../input/upload/upload.module';
import { ButtonSubtleComponent } from '../buttons/button-subtle/button-subtle.component';
import { ButtonDropdownComponent } from '../buttons/button-dropdown/button-dropdown.component';
import { IconModule } from '../icon/icon-module';

@NgModule({
  declarations: [
    ButtonComponent,
    ButtonModalConfirmComponent,
    ButtonModalCancelComponent,
    PriorityButtonComponent,
    StatusButtonComponent,
    NewSiteButtonComponent,
    MobileNewPointButtonComponent,
    EditButtonComponent,
    NewSitePlanButtonComponent,
    ButtonSubtleComponent,
    ButtonDropdownComponent,
  ],
  imports: [
    CommonModule,
    SpinnerModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    UploadModule,
    PipesModule,
    IconModule,
  ],
  exports: [
    ButtonComponent,
    ButtonModalConfirmComponent,
    ButtonModalCancelComponent,
    PriorityButtonComponent,
    StatusButtonComponent,
    NewSiteButtonComponent,
    MobileNewPointButtonComponent,
    EditButtonComponent,
    NewSitePlanButtonComponent,
    ButtonSubtleComponent,
    ButtonDropdownComponent,
  ],
})
export class ButtonModule {}
