import { TAllUsers } from '@project/view-models';
import { cloneDeep } from 'lodash';
import { ActionsUnion, EActionType } from './users.actions';

export const initialState: TAllUsers = {};

export function usersReducer(state: TAllUsers = initialState, action: ActionsUnion): TAllUsers {
  switch (action.type) {
    case EActionType.SET_USERS:
      return { ...action.payload };

    case EActionType.CLEAR_USERS:
      return {};

    case EActionType.UPDATE_USER_NAME: {
      const newState = cloneDeep(state);

      newState[action.payload.userId].userName = action.payload.userName;

      return newState;
    }
    default:
      return state;
  }
}
