import { TPlanData } from '../../plan/plan-data.service';
import { EPlanModule } from '../../plan/plan-module.enum';
import { TWorkspacesById } from '../../workspace/workspace.model';

export function getCoords(
  workspaces: TWorkspacesById,
  workspaceId: string,
  planData: TPlanData,
  module: EPlanModule = EPlanModule.SITE,
): string[] {
  let extent: number[];
  let coords: string[] = [];

  if (module === EPlanModule.POINT && planData.instance.point) {
    extent = planData.instance.point.getView().calculateExtent(planData.instance.point.getSize());
  } else if (planData.instance.site) {
    extent = planData.instance.site.getView().calculateExtent(planData.instance.site.getSize());
  }

  if (extent?.length) {
    coords = extent.map((coord) => coord.toString());
  } else if (workspaces[workspaceId].sitePlan && workspaces[workspaceId].sitePlan.extent) {
    coords = workspaces[workspaceId].sitePlan.extent.map((coord) => coord.toString());
  }

  if (!coords.length) {
    coords = ['', '', '', ''];
  }

  return coords;
}
