<pp-modal>
  <ng-container heading>
    {{ 'delete_points' | ppTranslate }}
  </ng-container>

  <ng-container body>
    <p class="modal__body-title">{{ 'points_to_be_deleted' | ppTranslate }}</p>

    <ul class="pointsList">
      <li class="modal__points-list" *ngFor="let point of selectedPoints">{{ point.title }}</li>
    </ul>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'Delete' | ppTranslate"
      (ppCancel)="hideModal()"
      (ppConfirm)="deletePoints()"
      [ppError]="true"
      [ppProcess]="processing"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
