import { TWorkspaceResponse } from '@project/view-models';
import { TWorkspaceSitePlan } from '../workspace.model';

export function generateWorkspaceSitePlan(workspace: TWorkspaceResponse): TWorkspaceSitePlan {
  const sitePlan: TWorkspaceSitePlan = workspace.sitePlan
    ? {
        extent: workspace.sitePlan.extent,
        minZoom: workspace.sitePlan.minZoom,
        resolutions: workspace.sitePlan.resolutions,
        sitePlanURL: workspace.sitePlan.sitePlanURL,
        version: workspace.sitePlan.version,
      }
    : null;

  return sitePlan;
}
