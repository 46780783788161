import { TAnyFunction } from '@core/helpers';
import { blurInput } from 'src/app/core/helpers/blur-input';

export function checkClearShortcut(event: KeyboardEvent, clearFunction: TAnyFunction): void {
  const isCtrlXClearShortcut = event.ctrlKey && event.key === 'x';
  const isCtrlBackspaceClearShortcut = event.ctrlKey && event.key === 'Backspace';

  if (isCtrlXClearShortcut || isCtrlBackspaceClearShortcut) {
    clearFunction();
    blurInput(event.target);
  }
}
