import { cloneDeep } from 'lodash';

import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { SetWhiteLabel } from './white-label-actions';

import { ResponseErrorService } from '../errors/response-error.service';

import { DOCUMENT } from '@angular/common';
import { WhiteLabelApiProviderService } from '@core/api';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { UpdateWorkspaceLogo } from '../workspace/workspace.actions';
import { EPdfLogoTarget } from './pdfLogo-targets-enum';
import { clearWhiteLabel } from './white-label-utils/clear-white-label';
import { setDefaultStyle } from './white-label-utils/set-default-style';
import { setWhiteLabel } from './white-label-utils/set-white-label';
import { setWhiteLabelStyles } from './white-label-utils/set-white-label-styles';
import {
  DEFAULT_FONT_FAMILY,
  DEFAULT_PANEL_ACCENT,
  DEFAULT_PANEL_BG,
  DEFAULT_PANEL_FONT,
  DEFAULT_PANEL_FONT_HIGHLIGHT,
  DEFAULT_PANEL_TEXT,
  DEFAULT_PANEL_TEXT_HOVER,
  DEFAULT_PRIMARY_100,
  DEFAULT_PRIMARY_200,
  DEFAULT_PRIMARY_300,
  DEFAULT_PRIMARY_400,
  DEFAULT_PRIMARY_50,
  DEFAULT_PRIMARY_500,
  DEFAULT_PRIMARY_600,
  DEFAULT_PRIMARY_700,
  DEFAULT_PRIMARY_75,
  DEFAULT_PRIMARY_800,
  DEFAULT_PRIMARY_900,
} from './white-label-utils/white-label-defaults.const';
import { TWhiteLabel, TWhiteLabelStore } from './white-label.model';

@Injectable({
  providedIn: 'root',
})
export class WhiteLabelService {
  whiteLabelStore: TWhiteLabelStore = {
    data: {},
    created: false,
    accountId: null,
  };

  whiteLabels: {
    [accountId: string]: TWhiteLabel;
  } = {};

  private themeWrapper: HTMLBodyElement = this.document.querySelector('body');
  private refetch = {};

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<{
      whiteLabel: TWhiteLabelStore;
    }>,
    private responseErrorService: ResponseErrorService,
    private whiteLabelApiProviderService: WhiteLabelApiProviderService,
  ) {
    this.setDefaultStyle();
  }

  getWhiteLabelStore(): TWhiteLabelStore {
    return this.whiteLabelStore;
  }

  setWhiteLabel({
    data = undefined,
    accountId = undefined,
    isDefaultLabel = false,
  }: Partial<TWhiteLabelStore> & { isDefaultLabel?: boolean } = {}): TWhiteLabelStore {
    this.whiteLabelStore = setWhiteLabel({
      data,
      accountId,
      oldWhiteLabel: this.whiteLabelStore,
      isDefaultLabel: isDefaultLabel,
    });

    setWhiteLabelStyles(this.themeWrapper, this.whiteLabelStore);

    this.store.dispatch(
      new SetWhiteLabel({
        whiteLabel: this.whiteLabelStore,
      }),
    );

    return this.getWhiteLabelStore();
  }

  clearWhiteLabel(): TWhiteLabelStore {
    this.whiteLabelStore = clearWhiteLabel(this.whiteLabelStore);

    this.store.dispatch(
      new SetWhiteLabel({
        whiteLabel: this.whiteLabelStore,
      }),
    );

    return this.getWhiteLabelStore();
  }

  fetchWhiteLabel(accountId: string, refetch: boolean = false): void {
    this.whiteLabelStore = cloneDeep(this.whiteLabelStore);

    if (this.whiteLabels[accountId] && !refetch) {
      const whiteLabel = this.whiteLabels[accountId];

      if (whiteLabel) {
        this.setWhiteLabel({
          data: whiteLabel,
          accountId,
        });
      } else {
        this.setWhiteLabel({
          data: this.getDefaultWhiteLabel(accountId),
          accountId,
          isDefaultLabel: true,
        });
      }

      return;
    }

    this.whiteLabelApiProviderService
      .fetchWhiteLabel(accountId, this.refetch[accountId])
      .pipe(
        tap((response: TWhiteLabel) => {
          this.refetch[accountId] = false;

          if (Object.keys(response).length) {
            this.setWhiteLabel({
              data: response || this.getDefaultWhiteLabel(accountId),
              accountId,
            });
          } else {
            this.setWhiteLabel({
              data: this.getDefaultWhiteLabel(accountId),
              accountId,
              isDefaultLabel: true,
            });
          }
        }),
        catchError(this.responseErrorService.handleRequestError),
      )
      .subscribe();
  }

  createWhiteLabel(skin: TWhiteLabel, accountId: string): Observable<TWhiteLabel> {
    return this.whiteLabelApiProviderService.createWhiteLabel(accountId, skin).pipe(
      tap((response) => {
        this.refetch[accountId] = true;

        this.setWhiteLabel({
          data: response || {},
          accountId,
        });
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  updateWhiteLabel(
    skin: TWhiteLabel,
    accountId: string,
    {
      update = true,
    }: {
      update?: boolean;
    } = {},
  ): Observable<TWhiteLabel> {
    return this.whiteLabelApiProviderService.updateWhiteLabel(accountId, skin).pipe(
      tap((response) => {
        if (update) {
          this.refetch[accountId] = true;

          this.setWhiteLabel({
            data: response || {},
            accountId,
          });
        }
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  uploadLogo(accountId: string, image: File): Observable<string> {
    const formData = new FormData();
    const headers = new HttpHeaders();

    formData.append('image_file', image);
    headers.append('Content-Type', undefined);

    return this.whiteLabelApiProviderService.uploadLogo(accountId, formData).pipe(
      tap(() => {
        this.refetch[accountId] = true;
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  uploadPdfLogo(targetId: string, image: File, targetType: string): Observable<string> {
    const formData = new FormData();
    const headers = new HttpHeaders();

    formData.append('image_file', image);
    headers.append('Content-Type', undefined);

    return this.whiteLabelApiProviderService.uploadLogo(targetId, formData).pipe(
      tap((imageId) => {
        this.whiteLabelApiProviderService
          .uploadPdfLogo(targetId, targetType, imageId)
          .pipe(
            tap(() => {
              this.setSiteLogo(imageId, targetId);

              if (targetType === EPdfLogoTarget.ACCOUNT) {
                this.refetch[targetId] = true;
              }
            }),
            map(() => imageId),
            catchError(this.responseErrorService.handleRequestError),
          )
          .subscribe();
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  deletePdfLogo(targetId: string, targetType: string): Observable<string> {
    return this.whiteLabelApiProviderService.deletePdfLogo(targetId, targetType).pipe(
      tap(() => {
        if (targetType === EPdfLogoTarget.ACCOUNT) {
          this.refetch[targetId] = true;
        }
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  setDefaultStyle(): void {
    setDefaultStyle(this.themeWrapper);

    this.clearWhiteLabel();
  }

  getDefaultWhiteLabel(accountId: string): TWhiteLabel {
    return {
      logoDark: undefined,
      logoLight: undefined,
      accountRefId: accountId,
      panelBg: DEFAULT_PANEL_BG,
      panelFont: DEFAULT_PANEL_FONT,
      panelFontHighlight: DEFAULT_PANEL_FONT_HIGHLIGHT,
      panelAccent: DEFAULT_PANEL_ACCENT,
      fontFamily: DEFAULT_FONT_FAMILY,
      panelText: DEFAULT_PANEL_TEXT,
      panelTextHover: DEFAULT_PANEL_TEXT_HOVER,
      primary50: DEFAULT_PRIMARY_50,
      primary75: DEFAULT_PRIMARY_75,
      primary100: DEFAULT_PRIMARY_100,
      primary200: DEFAULT_PRIMARY_200,
      primary300: DEFAULT_PRIMARY_300,
      primary400: DEFAULT_PRIMARY_400,
      primary500: DEFAULT_PRIMARY_500,
      primary600: DEFAULT_PRIMARY_600,
      primary700: DEFAULT_PRIMARY_700,
      primary800: DEFAULT_PRIMARY_800,
      primary900: DEFAULT_PRIMARY_900,
    };
  }

  setSiteLogo(imageId: string, workspaceId: string): void {
    this.store.dispatch(
      new UpdateWorkspaceLogo({
        workspaceId,
        imageId,
      }),
    );
  }
}
