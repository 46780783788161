import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DoubleSelectComponent } from './double-select.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [DoubleSelectComponent],
  imports: [CommonModule, PipesModule],
  exports: [DoubleSelectComponent],
})
export class DoubleSelectModule {}
