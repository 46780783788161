import {Injectable} from '@angular/core';
import {API_subscription, API_subscription_id, API_subscription_type} from '@core/api/paths';
import {ApiService} from '@core/http';
import {Observable} from 'rxjs';
import {TSubscription, TSubscriptionRequest} from './subscription-requests.model';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionApiProviderService {
  constructor(private apiService: ApiService) {}

  fetchSubscriptions(): Observable<TSubscription[]> {
    const url = API_subscription();

    return this.apiService.get<TSubscription[]>(url);
  }

  addSubscriptions(subscription: TSubscriptionRequest): Observable<TSubscription> {
    const url = API_subscription();

    return this.apiService.post<TSubscription>(url, subscription);
  }

  updateSubscription(
    subscriptionId: string,
    subscription: TSubscriptionRequest,
  ): Observable<TSubscription> {
    const url = API_subscription_type(subscriptionId);

    return this.apiService.put<TSubscription>(url, subscription);
  }

  deleteSubscription(subscriptionId: string): Observable<null> {
    const url = API_subscription_id(subscriptionId);

    return this.apiService.delete<null>(url);
  }
}
