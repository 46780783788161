import { Component, Input, OnChanges } from '@angular/core';
import { DashletActivitiesService } from '../../../dashlet-activities.service';
import { TActivityChange } from '../../activity-change.model';

@Component({
  selector: 'pp-dashlet-activity-tags',
  templateUrl: './dashlet-activity-tags.component.html',
  styleUrls: ['../../dashlet-activity.component.scss'],
})
export class DashletActivityTagsComponent implements OnChanges {
  @Input() ppChange: TActivityChange<string[]>;

  addedTags: string[];
  removedTags: string[];

  constructor(private dashletActivitiesService: DashletActivitiesService) {}

  ngOnChanges(): void {
    if (this.ppChange.addedTags) {
      this.addedTags = this.ppChange.addedTags.filter(
        (tag) =>
          this.ppChange.newValue.includes(tag) &&
          (!this.ppChange.oldValue ||
            (this.ppChange.oldValue && !this.ppChange.oldValue.includes(tag))),
      );
    } else {
      this.addedTags = [];
    }

    if (this.ppChange.removedTags) {
      this.removedTags = this.ppChange.removedTags.filter(
        (tag) =>
          !this.ppChange.newValue.includes(tag) ||
          !this.isSubset(this.ppChange.newValue, this.ppChange.oldValue),
      );
    } else {
      this.removedTags = [];
    }
  }

  private isSubset(array1: string[], array2: string[]): boolean {
    return this.dashletActivitiesService.isSubset(array1, array2);
  }
}
