<div class="assignees__wrapper" data-test="assigneesSection">
  <label class="pointCF__label" [class.pointAssignees__bulk]="ppBulkChanges">
    <div class="pointCF__label-image--container">
      <pp-icon [ppSrc]="EIconPath.ICON_ACTIVITIES_USER_18" ppColor="inherit" ppClass="setSize18" />
    </div>

    <span
      ppTooltip
      class="pointCF__label-container"
      [ppTitle]="'assignees' | ppTranslate"
      [ppTrimTooltip]="true"
    >
      {{ 'assignees' | ppTranslate }}
    </span>
  </label>

  <div class="assignees__content">
    <div
      class="assigneesSearch__assignees"
      *ngFor="let userId of selectedAssignees"
      id="userButton{{ userId }}"
      (click)="toggleUserDropdown('userButton' + userId, userId); $event.stopImmediatePropagation()"
    >
      <img
        ppTooltip
        ppImage
        class="assignees__avatar"
        [class.assignees-button--disabled]="offline"
        *ngIf="allUsers[userId]?.avatarPublicUrl"
        [src]="allUsers[userId]?.avatarPublicUrl"
        [ppTitle]="allUsers[userId]?.userName"
      />

      <img
        ppTooltip
        class="assignees__avatar"
        [class.assignees-button--disabled]="offline"
        *ngIf="!allUsers[userId]?.avatarPublicUrl && allUsers[userId]"
        [src]="EIconPath.PLACEHOLDER_USER"
        [ppTitle]="allUsers[userId]?.userName"
      />
    </div>

    <pp-icon
      [id]="ppBulkChanges ? 'addAssigneesButtonBulk' : 'addAssigneesButton'"
      class="assignees__select-button"
      [class.assignees-button--disabled]="offline"
      [ppSrc]="EIconPath.ICON_INPUT_PLUS_32"
      [ppColor]="addAssigneesDropdownVisible ? 'primary-600' : 'grey-400'"
      [ppHoverColor]="addAssigneesDropdownVisible ? 'primary-600' : 'grey-600'"
      *ngIf="!ppDisabled"
      (click)="showAssigneesDropdown()"
      data-test="addAssignee"
    ></pp-icon>
  </div>
</div>
