import { GET_PREFERENCES } from 'src/app/project/modules/preferences/preferences.store';
import { GET_TIMELINE_COLUMNS } from '../../timeframes/timeline-timeframes';
import { createTimelineCellElement } from './create-timeline-cell-element';

export function createDefaultTimelineElement(_index: number): HTMLElement {
  const data = GET_TIMELINE_COLUMNS();
  const preferences = GET_PREFERENCES();

  const element = createTimelineCellElement({
    index: _index,
    width: `${data.bottomWidth}px`,
    className: preferences.timeline?.group
      ? 'timeline__cell--group timeline__cell'
      : 'timeline__cell',
  });

  return element;
}
