<div *ngIf="ppLogoUrl || uploadedImage" class="logoPreview__wrapper">
  <label class="logoPreview__title">
    {{ ppLogoLabelKey | ppTranslate }}
  </label>

  <img
    ppImage
    class="logoPreview"
    [ppSecureSrc]="'api/v1/images/' + ppLogoUrl + '/file/size/bounded/400'"
    *ngIf="ppLogoUrl && !uploadedImage"
  />

  <img ppImage class="logoPreview" [src]="uploadedImage" *ngIf="uploadedImage" />

  <div
    [id]="'logoPreviewButton-' + ppLogoLabelKey"
    class="topBar__account-options"
    (click)="toggleLogoPreviewDropdown('logoPreviewButton-' + ppLogoLabelKey)"
  >
    <img [src]="EIconPath.INTERACTION_OPTIONS_DASHBOARD" class="topBar__account-options-icon" />
  </div>
</div>

<pp-button ppStyle="secondary" *ngIf="!ppLogoUrl && !uploadedImage">
  <label class="logoUpload__button" [for]="'LogoUpload' + ppLogoLabelKey">
    {{ 'upload' | ppTranslate }} {{ ppLogoLabelKey | ppTranslate }}
  </label>

  <pp-upload
    id="LogoUpload--update"
    [ppId]="'LogoUpload' + ppLogoLabelKey"
    (ppUpload)="setLogo($event)"
    ppAccept=".jpg, .jpeg, .png, .bmp"
  >
  </pp-upload>
</pp-button>
