import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pp-new-site-button',
  templateUrl: './new-site-button.component.html',
  styleUrls: ['./new-site-button.component.scss'],
})
export class NewSiteButtonComponent {
  @Input() ppProcessing: boolean;
  @Input() ppDisabled: boolean;

  @Output() ppAction = new EventEmitter();

  action(): void {
    if (!this.ppDisabled) {
      this.ppAction.emit();
    }
  }
}
