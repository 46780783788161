import { Component, OnInit, ViewChild } from '@angular/core';

import { ModalDirective } from './modal.directive';
import { ModalService, Modal } from './modal.service';

@Component({
  selector: 'pp-modal-container',
  templateUrl: './modal-container.component.html',
})
export class ModalContainerComponent implements OnInit {
  @ViewChild(ModalDirective, { static: true }) modalHost: ModalDirective;

  private modal: Modal;

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    this.modal = this.modalService.getModal();

    this.modal.host = this.modalHost;
  }
}
