<div class="selectDropdown" [ngStyle]="{ width: isMobile ? 'unset' : '239px' }">
  <pp-hide-dropdown (ppHideDropdown)="hideDropdown()"></pp-hide-dropdown>

  <pp-search-box
    [ppPlaceholder]="'filter_items' | ppTranslate"
    (ppAction)="setKeyword($event)"
    ppSize="wide"
    class="dropdown__searchBar"
  ></pp-search-box>

  <ul class="dropdownWrapper">
    <li
      *ngFor="let item of filteredItems"
      (click)="selectItem(item)"
      class="dropdownItem"
      [class.dropdownItem--selected]="selectedItem?.value === item.value"
      ppTooltip
      [ppTrimTooltip]="false"
      [ppTitle]="item.tooltip"
    >
      <img
        ppImage
        class="select__icon icon-gap--right2x"
        [src]="item.pngIcon"
        *ngIf="item.pngIcon"
      />
      <pp-icon *ngIf="item.iconSrc" [ppSrc]="item.iconSrc" ppClass="setSize14"></pp-icon>
      {{ item.label }}
    </li>

    <hr class="dropdownHorizontalLine" *ngIf="createButtonEnabled" />

    <li class="dropdownItem" (click)="createNewFleet()" *ngIf="createButtonEnabled">
      <div class="topBarFleetManagementNewFleetButtonContent">
        <pp-icon
          [ppSrc]="EIconPath.ICON_NAV_PLUS_18"
          ppClass="setSize18"
          ppColor="inherit"
        ></pp-icon>
        {{ 'create_new_fleet' | ppTranslate }}
      </div>
    </li>
  </ul>
</div>
