<div class="notification__image-wrapper">
  <img class="notification__image" [src]="EIconPath.NOTIFICATION_ICON_IMAGE" />
</div>

<ng-container *ngIf="isNewImage">
  <ng-container *ngIf="isSingleImageAdded">
    <span class="notification__blue-text">{{ 'added' | ppTranslate }}</span>
    {{ 'an' | ppTranslate }}
    <span class="notification__bold">{{ 'image_uppercase' | ppTranslate }}</span>
    {{ 'to_a_point_you' | ppTranslate }}
    {{ notificationReason }}.
  </ng-container>

  <ng-container *ngIf="isMultipleImagesAdded">
    <span class="notification__blue-text">{{ 'added' | ppTranslate }}</span>
    {{
      ppNotification.changeBody.oldValue
        ? ppNotification.changeBody.newValue.length - ppNotification.changeBody.oldValue.length
        : ppNotification.changeBody.newValue.length
    }}
    <span class="notification__bold">{{ 'images_uppercase' | ppTranslate }}</span>
    {{ 'to_a_point_you' | ppTranslate }}
    {{ notificationReason }}.
  </ng-container>
</ng-container>

<ng-container *ngIf="!isNewImage">
  <ng-container *ngIf="isSingleImageRemoved">
    <span class="notification__blue-text">{{ 'removed' | ppTranslate }}</span>
    {{ 'an' | ppTranslate }}
    <span class="notification__bold">{{ 'image_uppercase' | ppTranslate }}</span>
    {{ 'from_a_point_you' | ppTranslate }}
    {{ notificationReason }}.
  </ng-container>

  <ng-container *ngIf="isMultipleImagesRemoved">
    <span class="notification__blue-text">{{ 'removed' | ppTranslate }}</span>
    {{
      ppNotification.changeBody.oldValue
        ? ppNotification.changeBody.newValue.length - ppNotification.changeBody.oldValue.length
        : ppNotification.changeBody.newValue.length
    }}
    <span class="notification__bold">{{ 'images_uppercase' | ppTranslate }}</span>
    {{ 'from_a_point_you' | ppTranslate }}
    {{ notificationReason }}.
  </ng-container>
</ng-container>
