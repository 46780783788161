import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterFieldNumbersInputComponent } from './filter-field-numbers-input.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';

@NgModule({
  declarations: [FilterFieldNumbersInputComponent],
  imports: [CommonModule, PipesModule, FormsModule, TooltipsModule],
  exports: [FilterFieldNumbersInputComponent],
})
export class FilterFieldNumberInputModule {}
