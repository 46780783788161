import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';

@Component({
  selector: 'pp-add-custom-field-import-table-header',
  templateUrl: './add-custom-field-import-table-header.component.html',
  styleUrls: ['./add-custom-field-import-table-header.component.scss'],
})
export class AddCustomFieldImportTableHeaderComponent {
  @Input() ppSite: TWorkspace;
  @Input() ppNumberOfSelectableFields: number;
  @Input() ppSelectedWorkspaceCustomFields: string[];
  @Output() ppSelectAll = new EventEmitter();
  @Output() ppDeselectAll = new EventEmitter();

  constructor() {}

  selectAll(): void {
    this.ppSelectAll.emit();
  }

  deselectAll(): void {
    this.ppDeselectAll.emit();
  }
}
