/* eslint-disable eqeqeq */
// Disabling eslint eqeqeq here because we need to compare values with == and not === to keep the code simple
import { TCustomFieldFilter, TFilters } from '../site-filter.model';

export function compareFilterCustomFields(firstFilter: TFilters, secondFilter: TFilters): boolean {
  const customFields1 = firstFilter.customFields;
  const customFields2 = secondFilter.customFields;

  for (let index = 0; index < customFields1.length; index++) {
    const customField1 = customFields1[index];
    const customField2 = customFields2.find((customField) => customField.id === customField1.id);

    const bothExist = checkIfBothFieldsExist(customField1, customField2);
    const valuesMatch = checkIfValuesMatch(customField1, customField2);
    const bothEmpty = checkIfBothEmpty(customField1, customField2);

    if (!bothExist || !valuesMatch || (!valuesMatch && !bothEmpty)) {
      return false;
    }
  }

  return checkNewFilters(firstFilter, secondFilter);
}

// Check if new filters are default ones or were already applied and change how the table looks
function checkNewFilters(firstFilter: TFilters, secondFilter: TFilters): boolean {
  const newFields = secondFilter.customFields.filter(
    (field) => !firstFilter.customFields.find((field1) => field1.id === field.id),
  );

  if (newFields.length === 0) {
    return true;
  }

  for (let i = 0; i < newFields.length; i++) {
    const field = newFields[i];

    if (
      field.value ||
      field.path ||
      field.text ||
      field.min ||
      field.max ||
      field.min ||
      field.startDate ||
      field.endDate ||
      field.idOfChosenElement ||
      field.excluded
    ) {
      return false;
    }
  }

  return true;
}

function checkIfBothFieldsExist(
  customField1: TCustomFieldFilter,
  customField2: TCustomFieldFilter,
): boolean {
  // This could be done in one if but I kept in this way to make it more readable
  if (!customField2) {
    return false;
  }

  if ((customField1.value && !customField2.value) || (!customField1.value && customField2.value)) {
    return false;
  }

  if ((customField1.min && !customField2.min) || (!customField1.min && customField2.min)) {
    return false;
  }

  if ((customField1.max && !customField2.max) || (!customField1.max && customField2.max)) {
    return false;
  }

  if (
    (customField1.startDate && !customField2.startDate) ||
    (!customField1.startDate && customField2.startDate)
  ) {
    return false;
  }

  if (
    (customField1.endDate && !customField2.endDate) ||
    (!customField1.endDate && customField2.endDate)
  ) {
    return false;
  }

  if (
    (customField1.idOfChosenElement && !customField2.idOfChosenElement) ||
    (!customField1.idOfChosenElement && customField2.idOfChosenElement)
  ) {
    return false;
  }

  if ((customField1.path && !customField2.path) || (!customField1.path && customField2.path)) {
    return false;
  }

  if ((customField1.text && !customField2.text) || (!customField1.text && customField2.text)) {
    return false;
  }

  if (
    (customField1.excluded && !customField2.excluded) ||
    (!customField1.excluded && customField2.excluded)
  ) {
    return false;
  }

  return true;
}

function checkIfValuesMatch(
  customField1: TCustomFieldFilter,
  customField2: TCustomFieldFilter,
): boolean {
  if (
    customField1.value != customField2.value ||
    customField1.min != customField2.min ||
    customField1.max != customField2.max ||
    customField1.startDate != customField2.startDate ||
    customField1.endDate != customField2.endDate ||
    customField1.idOfChosenElement != customField2.idOfChosenElement ||
    customField1.path != customField2.path ||
    customField1.text != customField2.text ||
    !!customField1.excluded != !!customField2.excluded
  ) {
    return false;
  }

  return true;
}

function checkIfBothEmpty(
  customField1: TCustomFieldFilter,
  customField2: TCustomFieldFilter,
): boolean {
  if (
    !(
      !customField1.value &&
      !customField1.min &&
      !customField1.max &&
      !customField1.startDate &&
      !customField1.endDate &&
      !customField1.idOfChosenElement &&
      !customField1.path &&
      !customField1.text &&
      !customField1.excluded &&
      !customField2.value &&
      !customField2.min &&
      !customField2.max &&
      !customField2.startDate &&
      !customField2.endDate &&
      !customField2.idOfChosenElement &&
      !customField2.path &&
      !customField2.text &&
      !customField2.excluded
    )
  ) {
    return false;
  }

  return true;
}
