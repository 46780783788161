<span
  class="pp-tag tagSelect__tag-wrapper"
  [class.tagSelect__tag-wrapper--disabled]="ppDisabled || !ppDeletable"
  [class.tagSelect__tag-wrapper--noMargin]="ppNoMargin"
>
  <p class="tagSelect__tag">
    {{ ppText }}
  </p>

  <button
    class="ppTag__remove"
    (click)="delete(); $event.stopPropagation()"
    *ngIf="ppDeletable && !ppDisabled"
  >
    <pp-icon
      [ppSrc]="EIconPath.ADVANCED_FILTERS_CLEAR_14"
      [ppColor]="'white'"
      [ppDisplayContents]="true"
    ></pp-icon>
  </button>
</span>
