import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';

import { workspaceReducer } from './workspace.reducer';

import { WorkspaceService } from './workspace.service';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('workspaces', workspaceReducer), PipesModule],
  exports: [],
  providers: [WorkspaceService],
  declarations: [],
})
export class WorkspaceModule {}
