import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { TWorkspacesById, TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { TAllCustomFields } from '../../../custom-fields/custom-fields.model';

import { AddCustomFieldModalComponent } from '../../../custom-fields/add-custom-field-modal/add-custom-field-modal.component';

import { ModalService } from '../../../../components/modal/modal.service';
import { SidePanelService } from 'src/app/project/modules/layout/side-panel/side-panel.service';
import { CustomFieldsService } from '../../../custom-fields/custom-fields.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { Title } from '@angular/platform-browser';
import { ScreenService } from '@core/services';
import { TAddCustomFieldModalData } from '../../../custom-fields/add-custom-field-modal/add-custom-field.model';
import { EStore } from 'src/app/project/shared/enums/store.enum';

@Component({
  selector: 'pp-site-settings-custom-fields',
  templateUrl: './site-settings-custom-fields.component.html',
  styleUrls: ['./site-settings-custom-fields.component.scss'],
})
export class SiteSettingsCustomFieldsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private customFields: TAllCustomFields = this.customFieldsService.getCustomFields();

  workspaces$: Observable<TWorkspacesById>;
  private workspace: TWorkspace;
  private offline$: Observable<boolean>;
  offline: boolean;
  workspaceId: string = null;
  workspacesLoaded = true;

  constructor(
    private store: Store<{ workspaces: TWorkspacesById; offline: boolean }>,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private sidePanelService: SidePanelService,
    private customFieldsService: CustomFieldsService,
    private workspaceService: WorkspaceService,
    private titleService: Title,
    private screenService: ScreenService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.offline$ = this.store.pipe(select(EStore.OFFLINE));
  }

  ngOnInit() {
    this.titleService.setTitle('Site settings | Pinpoint Works');
    this.workspacesLoaded = false;

    this.screenService.isDesktop$.pipe(takeUntil(this.destroy$)).subscribe((isDesktop) => {
      if (!isDesktop) {
        this.sidePanelService.collapsePanel();
      }
    });

    this.activatedRoute.parent.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.workspaceId = params.get('id');

      this.setWorkspace();
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setWorkspace();
    });

    this.offline$.pipe(takeUntil(this.destroy$)).subscribe((offline) => {
      this.offline = offline;
    });

    this.workspaceService
      .generateWorkspaces({ refetch: true })
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.workspacesLoaded = true;

          this.setWorkspace();
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  setWorkspace(): void {
    this.workspace = this.workspaceService.findWorkspace(this.workspaceId);
  }

  addCustomField(): void {
    this.modalService.setData<TAddCustomFieldModalData>({ workspaceId: this.workspaceId });

    this.modalService.showModal(AddCustomFieldModalComponent, {
      closeWarning: true,
      callback: () => (this.customFields = this.customFieldsService.getCustomFields()),
    });
  }
}
