import { GET_ACCOUNTS } from 'src/app/project/modules/account/account.store';
import { GET_SHARES } from 'src/app/project/modules/share/shares.store';
import {
  GET_COLUMNS,
  SET_COLUMN_SORT_INDEX,
  SET_COLUMN_SORT_ORDER,
} from '../../columns/columns.store';
import { GET_GROUPING } from '../../columns/grouping.store';
import { CLEAR_SORTING } from '../../columns/sorting.store';
import { GET_TABLE } from '../../table.ui.store';

import { createElement } from 'src/app/core/helpers/dom';
import { createFooterCellTypeElement } from './utils/create-footer-cell-type-element';
import { createFooterRowElement } from './utils/create-footer-row-element';
import { createFooterTotalPointsElement } from './utils/create-footer-total-points-element';

import { checkElement } from '@core/helpers';
import Tooltip from 'src/app/project/features/tooltip/Tooltip';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { TColumn } from '../../columns/column.model';
import Table from '../table/Table';
import {
  getSelectElementWidth,
  getSelectElementWidthPx,
} from '../table/utils/select-element-width';

export default class TableFooter {
  private table: Table;
  minColumnWidth = 60;
  allSelected = false;
  canResize = true;
  tooltips: Tooltip[] = [];
  resizeElement: HTMLElement = null;

  totalPointsElement: HTMLElement = null;

  element: HTMLElement = null;
  rowElement: HTMLElement = createFooterRowElement();

  constructor(_parent: Table) {
    this.table = _parent;

    this.element = this.create();

    this.table.element.appendChild(checkElement(this.element));
  }

  private create(): HTMLElement {
    return createElement('div', {
      attrs: {
        class: 'table__head table__footer',
      },
      children: [this.rowElement],
    });
  }

  clear(): void {
    this.updateWidth(0);
  }

  load(_keepScrollPosition: boolean = false): void {
    this.updateWidth(this.table.width);

    const wrapperElement = this.element.children[0];
    const scrollPosition = _keepScrollPosition ? this.element.scrollLeft : 0;

    this.tooltips.forEach((tooltip) => {
      tooltip.removeTooltip();
    });

    this.tooltips = [];

    while (wrapperElement.firstChild) {
      wrapperElement.removeChild(wrapperElement.firstChild);
    }

    wrapperElement.appendChild(checkElement(createFooterTotalPointsElement()));

    this.checkResizePermission();

    GET_COLUMNS().forEach((_column) => {
      if (!_column.hidden) {
        const cellElement = createFooterCellTypeElement(_column);

        wrapperElement.appendChild(checkElement(cellElement));
      }
    });

    this.table.sortTable();

    this.scrollLeft(scrollPosition);
  }

  updateWidth(_width: number = this.table.width): void {
    const grouping = GET_GROUPING();
    const table = GET_TABLE();
    let footerWidth = _width;

    if (grouping.length) {
      footerWidth = table.width + grouping.length * 16 + getSelectElementWidth(grouping.length);
    }

    (this.element.children[0] as HTMLElement).style.width = `${footerWidth}px`;
  }

  scrollLeft(_left: number): void {
    this.element.scrollLeft = _left;
  }

  checkResizePermission(): void {
    const table = GET_TABLE();
    let canResize = true;

    if (table.workspaceId) {
      const accounts = GET_ACCOUNTS();
      const share = GET_SHARES().find(
        (searchedShare) => searchedShare.workspaceId === table.workspaceId,
      );
      const account = accounts.find((searchedAccount) =>
        searchedAccount.workspaces.includes(table.workspaceId),
      );

      let globalSitePreferences: boolean;

      if (
        account &&
        account.accountFeatures &&
        typeof account.accountFeatures.globalSitePreferences !== 'undefined'
      ) {
        globalSitePreferences = account.accountFeatures.globalSitePreferences;
      } else {
        globalSitePreferences = false;
      }

      if (share) {
        canResize =
          !globalSitePreferences ||
          share.shareOption === EUserRole.SITE_ADMIN ||
          share.shareOption === EUserRole.ACCOUNT_ADMIN ||
          share.shareOption === EUserRole.OWNER;
      }
    }

    this.canResize = canResize;
  }

  addColumn(_columnIndex: number): void {
    const columns = GET_COLUMNS();
    let hiddenColumnsCount = 0;

    for (let index = 0; index < _columnIndex; index += 1) {
      if (columns[index] && columns[index].hidden) {
        hiddenColumnsCount += 1;
      }
    }

    this.rowElement.insertBefore(
      createFooterCellTypeElement(columns[_columnIndex]),
      this.rowElement.children[_columnIndex - hiddenColumnsCount + 1],
    );
  }

  removeColumn(_columnIndex: number): void {
    const columnElement = this.rowElement.children[_columnIndex + 1];

    if (columnElement) {
      columnElement.parentNode.removeChild(columnElement);
    }
  }

  moveColumn(_columnIndex: number, _destinationIndex: number): void {
    const columns = GET_COLUMNS();

    let hiddenColumnsBeforeDestinationCount = 0;
    let hiddenColumnsBeforeColumnCount = 0;

    for (let index = 0; index < _destinationIndex; index += 1) {
      if (columns[index] && columns[index].hidden) {
        hiddenColumnsBeforeDestinationCount += 1;
      }
    }

    for (let index = 0; index < _columnIndex; index += 1) {
      if (columns[index] && columns[index].hidden) {
        hiddenColumnsBeforeColumnCount += 1;
      }
    }

    const columnElement =
      this.rowElement.children[_columnIndex - hiddenColumnsBeforeColumnCount + 1];

    columnElement.parentNode.removeChild(columnElement);

    this.rowElement.insertBefore(
      columnElement,
      this.rowElement.children[_destinationIndex - hiddenColumnsBeforeDestinationCount + 1],
    );
  }

  sortColumn(_column: TColumn): void {
    const sortButton = this.table.siteOptionsService.getSortButton();
    const groupButton = this.table.siteOptionsService.getGroupButton();
    const oldSortOrder = _column.sortOrder;

    CLEAR_SORTING();

    GET_COLUMNS().forEach((__column) => {
      delete __column.sortIndex;
      delete __column.sortOrder;
    });

    let sortOrder = null;

    if (!oldSortOrder) {
      sortOrder = 'ASC';
    } else if (oldSortOrder === 'ASC') {
      sortOrder = 'DESC';
    }

    if (sortOrder) {
      SET_COLUMN_SORT_INDEX(_column.index, 0);
      SET_COLUMN_SORT_ORDER(_column.index, sortOrder);
    } else {
      SET_COLUMN_SORT_INDEX(_column.index);
      SET_COLUMN_SORT_ORDER(_column.index);
    }

    this.table.sortTable({ updatePreferences: true });

    sortButton.update({ closeDropdown: true });
    groupButton.update();
  }

  updateFrozenColumnWidth(): void {
    const selectElement: HTMLElement = document.querySelector('.table__footer__select');
    const grouping = GET_GROUPING();

    if (selectElement) {
      if (grouping.length) {
        selectElement.style.width = getSelectElementWidthPx(grouping.length);
        selectElement.style.marginLeft = `0px`;
      } else {
        selectElement.style.width = getSelectElementWidthPx(grouping.length);
        selectElement.style.marginLeft = '0';
      }
    }
  }

  addTooltip(tooltip: Tooltip): void {
    this.tooltips.push(tooltip);
  }
}
