import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, tap } from 'rxjs';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { FleetApiProviderService } from 'src/app/project/data-providers/api-providers/fleet-api-provider/fleet-api-provider.service';
import { TFleetCreateDTO } from 'src/app/project/data-providers/api-providers/fleet-api-provider/fleet-requests.model';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { getErrorMessage } from 'src/app/project/helpers/database/get-error-message';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventFleetManagement,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { AccountService } from '../../account/account-service/account.service';
import { SiteRedirectService } from '../../auth/site-redirect.service';
import { ResponseErrorService } from '../../errors/response-error.service';
import { FleetManagementRoutesService } from '../fleet-management-routes.service';
import { TFleet, TFleetByAccount, TFleetStatuses } from '../fleet.consts';

@Injectable({
  providedIn: 'root',
})
export class FleetService {
  fleetList: TFleetByAccount = {};

  private _fleetChange$ = new Subject<TFleetByAccount>();
  public fleetChange$ = this._fleetChange$.asObservable();

  private _activeFleetChange$ = new Subject<string>();
  public activeFleetChange$ = this._activeFleetChange$.asObservable();

  private activeFleet: string;
  private fleetAccessEnabled = false;

  constructor(
    private fleetApiProviderService: FleetApiProviderService,
    private responseErrorService: ResponseErrorService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private fleetManagementRoutesService: FleetManagementRoutesService,
    private siteRedirectService: SiteRedirectService,
    private accountService: AccountService,
  ) {}

  createFleet(
    fleetName: string,
    accountId: string,
    statusSettings: TFleetStatuses,
  ): Observable<TFleet> {
    const body: TFleetCreateDTO = {
      name: fleetName,
      statusSettings,
    };

    logEventInGTAG(EGoogleEventFleetManagement.CREATE_NEW_FLEET, {
      event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
    });

    return this.fleetApiProviderService.createFleet(accountId, body).pipe(
      tap((response) => {
        this.addFleetToData(response);

        this._fleetChange$.next(this.fleetList);
      }),
      catchError((error) => {
        if (error.status === EStatusCode.BAD_REQUEST) {
          getErrorMessage(error).then((text) => this.promptService.showError(text));
        }

        return this.responseErrorService.handleRequestError(error);
      }),
    );
  }

  fetchFleets(): Observable<TFleet[]> {
    return this.fleetApiProviderService.getFleets().pipe(
      tap((fleets) => {
        fleets.forEach((fleet) => {
          this.addFleetToData(fleet);

          this._fleetChange$.next(this.fleetList);
        });
      }),
      catchError((error) => {
        if (error.status === EStatusCode.FORBIDDEN) {
          this.siteRedirectService.redirectToSites();
        }
        return this.responseErrorService.handleRequestError(error);
      }),
    );
  }

  getFleets(): TFleetByAccount {
    return this.fleetList;
  }

  clearFleets(): void {
    this.fleetList = {};
    this.activeFleet = null;
  }

  setActiveFleetId(fleetId: string): void {
    this.activeFleet = fleetId;

    this._activeFleetChange$.next(fleetId);
  }

  getActiveFleetId(): string {
    return this.activeFleet;
  }

  getFleet(fleetId: string): TFleet {
    for (const accountId in this.fleetList) {
      if (this.fleetList[accountId][fleetId]) {
        return this.fleetList[accountId][fleetId];
      }
    }
  }

  addAssetToFleet(fleetId: string, assetId: string): void {
    for (const accountId in this.fleetList) {
      if (this.fleetList[accountId][fleetId]) {
        this.fleetList[accountId][fleetId].assetIds.push(assetId);
      }
    }
  }

  deleteFleet(fleetId: string): void {
    this.fleetApiProviderService
      .deleteFleet(fleetId)
      .pipe(
        tap(() => {
          this.handleDeleteFleetSuccess(fleetId);
        }),
        catchError((error) => {
          let prompt = '';

          if (error.status === EStatusCode.BAD_REQUEST) {
            prompt = this.translationPipe.transform('delete_fleet_asset_error');
          } else {
            prompt = this.translationPipe.transform('delete_fleet_generic_error');
          }

          this.promptService.showError(prompt);

          return this.responseErrorService.handleRequestError(error);
        }),
      )
      .subscribe();
  }

  updateFleet(newFleet: Partial<TFleet>): Observable<TFleet> {
    this._fleetChange$.next(this.fleetList);

    return this.fleetApiProviderService.editFleet(newFleet.id, newFleet).pipe(
      tap((response) => {
        const prompt = this.translationPipe.transform('prompt_fleet_updated');
        this.addFleetToData(response);

        logEventInGTAG(EGoogleEventFleetManagement.FLEET_EDIT, {
          event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
        });

        this.promptService.showSuccess(prompt);

        this._fleetChange$.next(this.fleetList);
      }),
    );
  }

  removeAssetFromFleets(assetId: string): void {
    for (const accountId in this.fleetList) {
      for (const fleetId in this.fleetList[accountId]) {
        const fleet = this.fleetList[accountId][fleetId];

        fleet.assetIds = fleet.assetIds.filter((fleetAssetId) => fleetAssetId !== assetId);
      }
    }

    this._fleetChange$.next(this.fleetList);
  }

  checkFleetAccess(): Observable<boolean> {
    return this.fleetApiProviderService.checkFleetManagementAccess().pipe(
      tap((response) => {
        this.fleetAccessEnabled = response;
      }),
    );
  }

  getFleetAccess(): boolean {
    return this.fleetAccessEnabled;
  }

  private handleDeleteFleetSuccess(fleetId): void {
    const prompt = this.translationPipe.transform('delete_fleet_success');
    this.promptService.showSuccess(prompt);

    const fleet: TFleet = this.getFleet(fleetId);
    const accountId = fleet.accountId;
    delete this.fleetList[accountId][fleet.id];

    if (Object.keys(this.fleetList[accountId]).length === 0) {
      delete this.fleetList[accountId];
    }

    this.setNewFleetAfterDelete(accountId);
    this._fleetChange$.next(this.fleetList);
  }

  private setNewFleetAfterDelete(accountId: string): void {
    if (!this.fleetList[accountId]) {
      this.setActiveFleetId(null);
    } else {
      const fleetIds = Object.keys(this.fleetList[accountId]);

      if (fleetIds.length > 0) {
        this.setActiveFleetId(fleetIds[0]);
      } else {
        this.setActiveFleetId(null);
      }
    }

    this.fleetManagementRoutesService.goToFleet(this.activeFleet);
  }

  private addFleetToData(fleet: TFleet) {
    if (!this.fleetList[fleet.accountId]) {
      this.fleetList[fleet.accountId] = {};
    }

    this.fleetList[fleet.accountId][fleet.id] = fleet;
  }
}
