import { TAccountSimpleResponse } from 'src/app/project/view-models/account-simple-response.model';
import VirtualScroller from 'src/app/project/modules/external/virtual-scroller/VirtualScroller';
import Folder from '../folder/Folder';
import { NEW_FOLDER_ID } from '../utils/account-list-constants';
import { TFlattenedAccount } from './account-list-flatten-account';
import { TAnyFunction } from '@core/helpers';
import { ApiService } from '@core/http';

export type TVirtualScrollerFolder = TFlattenedAccount;

type TAddFolderElementParams = {
  _index: number;
  virtualScroller: VirtualScroller;
  accountsFlattened: TFlattenedAccount[];
  apiService: ApiService;
  folderSettingsCallback: TAnyFunction;
  cancelCallback: TAnyFunction;
  createFolderCallback: TAnyFunction;
  editFolderCallback: TAnyFunction;
  load: TAnyFunction;
  errorCallback: TAnyFunction;
  dragoverCallback: TAnyFunction;
  accounts: TAccountSimpleResponse[];
};

export const addFolderElement = ({
  _index,
  virtualScroller,
  accountsFlattened,
  apiService,
  folderSettingsCallback,
  cancelCallback,
  createFolderCallback,
  editFolderCallback,
  load,
  errorCallback,
  dragoverCallback,
  accounts,
}: TAddFolderElementParams): HTMLElement => {
  let folderObj: TVirtualScrollerFolder = null;

  if (virtualScroller) {
    folderObj = virtualScroller.data[_index];
  } else {
    folderObj = accountsFlattened[_index];
  }

  const folder = new Folder(folderObj, apiService, {
    folderSettingsCallback: folderSettingsCallback,
    updateCallback: ({ toggle = false, cancel = false }): void => {
      if (cancel) {
        cancelCallback(folderObj.accountId);
        return;
      }

      if (!toggle && folderObj.id === NEW_FOLDER_ID) {
        createFolderCallback(folderObj);
        return;
      }

      if (!toggle && folderObj.id !== NEW_FOLDER_ID) {
        editFolderCallback(folderObj);
        return;
      }

      load(accounts, true);
    },
    errorCallback: (_error): void => {
      errorCallback('WARN', _error);
    },
    dragoverCallback: (folderId) => dragoverCallback(folderId),
  });

  return folder.element;
};
