import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { reminderRoutes } from './reminders.routes';

import { LayoutModule } from '../layout/layout.module';
import { SupportedBrowsersModule } from '../supported-browsers/supported-browsers.module';
import { FiltersModule } from '../filters/filters.module';

import { RemindersComponent } from './reminders/reminders.component';
import { RemindersService } from './reminders.service';
import { ReminderPointModalComponent } from './reminder-point-modal/reminder-point-modal.component';
import { ReminderAlertComponent } from './reminder-alert/reminder-alert.component';
import { ReminderListComponent } from './reminders/reminder-list/reminder-list.component';
import { ReminderElementComponent } from './reminders/reminder-element/reminder-element.component';
import { ReminderSettingsDropdownComponent } from './reminders/reminder-settings-dropdown/reminder-settings-dropdown.component';
import { DeleteReminderModalComponent } from './delete-reminder-modal/delete-reminder-modal.component';
import { RemindersOptionsComponent } from './reminders/reminders-options/reminders-options.component';
import { ButtonModule } from '../../components/button/button.module';
import { SaveIndicatorModule } from '../../components/save-indicator/save-indicator.module';
import { DatepickerModule } from '../../components/input/datepicker/datepicker.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { ModalModule } from '../../components/modal/modal.module';
import { AutonumericModule } from '../../directives/autonumeric/autonumeric.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SwitchCasesModule } from '../../directives/switch-cases/switch-cases.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { IconModule } from '../../components/icon/icon-module';
import { PlaceholdersModule } from '../placeholders/placeholders.module';
import { ReminderSectionComponent } from './reminders/reminder-list/reminder-section/reminder-section.component';

@NgModule({
  declarations: [
    RemindersComponent,
    ReminderPointModalComponent,
    ReminderAlertComponent,
    ReminderListComponent,
    ReminderElementComponent,
    ReminderSettingsDropdownComponent,
    DeleteReminderModalComponent,
    RemindersOptionsComponent,
    ReminderSectionComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(reminderRoutes),
    FormsModule,
    SaveIndicatorModule,
    DatepickerModule,
    SaveIndicatorModule,
    PipesModule,
    ButtonModule,
    LayoutModule,
    SupportedBrowsersModule,
    FiltersModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    ModalModule,
    IconModule,
    PlaceholdersModule,
  ],
  providers: [RemindersService],
  exports: [ReminderAlertComponent],
})
export class RemindersModule {}
