import { Action } from '@ngrx/store';
import { TAllFilters, TFilters } from './site-filter.model';

export enum EActionType {
  SET_SITE_FILTER = '[Site Filter] Set Site Filter',
  SET_ALL_FILTERS = '[Site Filter] Set All Site Filters',
  CLEAR_FILTERS = '[Site Filter] Clear Site Filters',
  CLEAR_FILTER = '[Site Filter] Clear Site Filter',
  SET_FILTERS_FROM_PREFERENCES = '[Site Filter] Set Filters From Preferences',
}

export class SetSiteFilter implements Action {
  readonly type = EActionType.SET_SITE_FILTER;
  constructor(
    readonly payload: {
      workspaceId: string;
      filter: TFilters;
    },
  ) {}
}

export class SetAllFilters implements Action {
  readonly type = EActionType.SET_ALL_FILTERS;
  constructor(
    readonly payload: {
      filters: TAllFilters;
    },
  ) {}
}
export class SetFiltersFromPreferences implements Action {
  readonly type = EActionType.SET_FILTERS_FROM_PREFERENCES;
  constructor(
    readonly payload: {
      overviewFilter: TFilters;
      timelineFilter: TFilters;
      remindersFilter: TFilters;
    },
  ) {}
}

export class ClearFilters implements Action {
  readonly type = EActionType.CLEAR_FILTERS;
  constructor() {}
}

export class ClearFilter implements Action {
  readonly type = EActionType.CLEAR_FILTER;
  constructor(
    readonly payload: {
      workspaceId: string;
    },
  ) {}
}

export type ActionsUnion =
  | SetSiteFilter
  | SetAllFilters
  | ClearFilters
  | ClearFilter
  | SetFiltersFromPreferences;
