import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { SelectModule } from '../input/select/select.module';
import { FormulaOutputPickerComponent } from './formula-output-picker.component';

@NgModule({
  declarations: [FormulaOutputPickerComponent],
  imports: [CommonModule, SelectModule, PipesModule],
  exports: [FormulaOutputPickerComponent],
})
export class FormulaOutputPickerModule {}
