<div class="selectDropdown" [ngStyle]="{ width: isMobile ? 'unset' : dropdownWidth }">
  <pp-hide-dropdown (ppHideDropdown)="hideDropdown()"></pp-hide-dropdown>

  <ul class="dropdownWrapper dropdownWrapper--grouped">
    <li *ngFor="let item of items" class="select__optionGroup">
      <span class="select__optionValue--title">{{ item.label }}</span>

      <ul class="select__optionValue__sublist">
        <ng-container *ngFor="let option of item.options">
          <li
            class="select__optionValue select__optionValue--enabled"
            (click)="selectItem(option)"
            [class.dropdownItem--selected]="selectedItem?.id === option.id"
            *ngIf="option.enabled"
          >
            <span>{{ option.name }} </span>

            <pp-select-dropdown-selected-mark
              *ngIf="option.message"
              [ppText]="option.message | ppTranslate"
            ></pp-select-dropdown-selected-mark>
          </li>

          <li class="select__optionValue select__optionValue--disabled" *ngIf="!option.enabled">
            <span
              ppTooltip
              [ppTitle]="option.disabledMessage | ppTranslate"
              [ppOptions]="{ placement: 'right' }"
            >
              {{ option.name }}
            </span>

            <pp-select-dropdown-selected-mark
              *ngIf="option.message"
              [ppText]="option.message | ppTranslate"
            ></pp-select-dropdown-selected-mark>
          </li>
        </ng-container>
      </ul>
    </li>
  </ul>
</div>
