import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { EStore } from '../../shared/enums/store.enum';

@Injectable({
  providedIn: 'root',
})
export class OfflineService {
  private offline$: Observable<boolean>;
  private offline: boolean;

  constructor(private store: Store<{ offline: boolean }>) {
    this.offline$ = this.store.pipe(select(EStore.OFFLINE));

    this.offline$.subscribe((offline) => {
      this.offline = offline;
    });
  }

  getOffline(): boolean {
    return this.offline;
  }
}
