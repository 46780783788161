import { Component, OnDestroy, OnInit } from '@angular/core';

import { Modal, ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { PointActivityService } from '../point-activity.service';

import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { PointActivityCommentsService } from '../point-activity-comments.service';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { of, Subject } from 'rxjs';

@Component({
  selector: 'pp-delete-comment-modal',
  templateUrl: './delete-comment-modal.component.html',
  styleUrls: ['./delete-comment-modal.component.scss'],
})
export class DeleteCommentModalComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private modal: Modal;

  processing: boolean;

  constructor(
    private promptService: PromptService,
    private modalService: ModalService,
    private pointActivityService: PointActivityService,
    private pointActivityCommentsService: PointActivityCommentsService,
    private translationPipe: TranslationPipe,
  ) {}

  ngOnInit() {
    this.modal = this.modalService.getModal();
    this.processing = false;
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  deleteComment(): void {
    if (!this.processing) {
      this.processing = true;

      this.pointActivityCommentsService
        .deleteComment(this.modal.data)
        .pipe(
          takeUntil(this.destroy$),
          tap(() => {
            this.pointActivityService.deleteCommentActivity(this.modal.data);

            const promptText = this.translationPipe.transform('prompt_message_deleted');

            this.promptService.showSuccess(promptText);
            this.hideModal();
          }),
          catchError(() => {
            const promptText = this.translationPipe.transform('prompt_comment_delete_error');

            this.promptService.showError(promptText);

            return of();
          }),
          finalize(() => {
            this.processing = false;
          }),
        )
        .subscribe();
    }
  }

  hideModal(): void {
    this.modalService.hideModal();
  }
}
