export enum EStore {
  USER = 'user',
  ACCOUNTS = 'accounts',
  WORKSPACES = 'workspaces',
  PREFERENCES = 'preferences',
  UI = 'ui',
  OFFLINE = 'offline',
  WHITE_LABEL = 'whiteLabel',
  POINTS = 'points',
  SITE_FILTER = 'siteFilter',
  ATTACHMENTS = 'attachments',
  USERS = 'users',
  SYNC = 'sync',
  ACTIVE = 'active',
}
