import { createElement } from '@core/helpers';
import { GET_WORKSPACES } from 'src/app/project/modules/workspace/workspace.store';
import { TColumn } from '../../columns/column.model';
import { GET_TABLE } from '../../table.ui.store';
import { createMarkedKeywordElement } from '../body-cells-search';
import { createCellElement } from '../createCellElement';

export function createSiteElement(_index: number, _column: TColumn): HTMLElement {
  const table = GET_TABLE();
  const workspaces = GET_WORKSPACES();

  const workspaceName = workspaces[table.points[_index].workspaceRef.id].siteName;

  return createCellElement({
    index: _column.index,
    width: `${_column.width}px`,
    child: createElement('div', {
      attrs: {
        class: 'customSiteTable__text',
      },
      children: [createMarkedKeywordElement(workspaceName)],
    }),
  });
}
