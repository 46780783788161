import { createElement } from '@core/helpers';
import { TAllUsers } from '@project/view-models';
import { render } from 'lit-html';
import { TRenderItem } from '../rich-text.model';
import { generateListItem } from './generate-list-item';

export function generateRenderItem(item: TRenderItem, users: TAllUsers): HTMLElement {
  const wrapperElement = createElement('div');
  const listItem = generateListItem(item, users[item.id]);

  render(listItem, wrapperElement);

  return wrapperElement.innerHTML;
}
