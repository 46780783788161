import { TFilters } from '../site-filter.model';

export function clearDateCreated(filters: TFilters, applied: boolean): void {
  filters.date.created.startDate = null;
  filters.date.created.endDate = null;

  if (applied) {
    filters.date.created.appliedValues.startDate = null;
    filters.date.created.appliedValues.endDate = null;
  }
}
