<pp-button
  [ppStyle]="isMobile ? 'secondary' : 'primary'"
  [ppId]="ppId"
  (ppAction)="action($event)"
  [ppSize]="ppSize"
  [ppDisabled]="ppDisabled"
>
  <pp-icon
    [ppSrc]="EIconPath.ICON_MISC_EDIT_14"
    ppColor="white"
    [ppClass]="!ppNoText ? 'ml--n04 setSize14' : 'setSize14'"
  ></pp-icon>
  <span *ngIf="!ppNoText"> {{ ppNoText ? '' : ('edit' | ppTranslate) }}</span>
</pp-button>
