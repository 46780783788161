import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { cloneDeep } from 'lodash';
import { translate } from 'src/app/project/features/translate/translate';
import { accountTypesNames } from 'src/app/project/modules/account/account-utils/account-types-names';
import { TAccount } from 'src/app/project/modules/account/account.model';
import {
  GET_ACCOUNT_MANAGERS,
  GET_INDUSTRIES,
  TAccountExtras,
} from 'src/app/project/modules/account/account.store';
import { TAccountOptionToggle } from './account-option-toggle.model';

@Component({
  selector: 'pp-account-options-toggles',
  templateUrl: './account-options-toggles.component.html',
  styleUrls: ['./account-options-toggles.component.scss'],
})
export class AccountOptionsTogglesComponent implements OnChanges {
  @Input() readonly ppAccount: TAccount;
  @Output() ppAccountChange = new EventEmitter();
  @Output() ppUpdateAccount = new EventEmitter();

  accountTypesNames: TAccountExtras[] = accountTypesNames.map((_account) => ({
    label: translate(_account.label),
    value: _account.value,
  }));

  industries: TAccountExtras[] = GET_INDUSTRIES().map((industry) => ({
    label: translate(industry.label),
    value: industry.value,
  }));

  accountManagers = GET_ACCOUNT_MANAGERS();

  toggles: TAccountOptionToggle[] = [
    {
      label: translate('account_type'),
      value: 'accountType',
      placeholder: translate('account_type_choose'),
      items: this.accountTypesNames,
      index: null,
    },
    {
      label: translate('industry_type'),
      value: 'industryType',
      placeholder: translate('industry_type_choose'),
      items: this.industries,
      index: null,
    },
    {
      label: translate('account_manager'),
      value: 'accountManager',
      placeholder: translate('unset'),
      items: this.accountManagers,
      index: null,
    },
  ];

  constructor() {}

  ngOnChanges(): void {
    this.updateTogglesIndexes();
  }

  selectItem(option: TAccountOptionToggle, item: TAccountExtras): void {
    option.index = option.items.indexOf(item);

    this.updateAccount();
  }

  updateAccount(): void {
    const newAccount = cloneDeep(this.ppAccount);

    this.toggles.forEach((option) => {
      if (option.items[option.index]) {
        const selectedOption = option.items[option.index].value;

        newAccount[option.value] = selectedOption;
      } else {
        newAccount[option.value] = null;
      }
    });

    this.ppAccountChange.emit(newAccount);
  }

  private updateTogglesIndexes(): void {
    this.toggles.forEach((option) => {
      option.index = option.items.findIndex(
        (_type) => _type.value === this.ppAccount[option.value],
      );
    });
  }
}
