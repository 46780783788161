import { cloneDeep } from 'lodash';
import { TogglePointSelection } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';
import { togglePoint } from '../selected-points';

export function togglePointSelection(
  oldState: TPointsByWorkspace,
  action: TogglePointSelection,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);
  let pointIndex = null;

  pointIndex = state[action.payload.workspaceId].entities.findIndex(
    (_point) => _point._id === action.payload._id,
  );

  const point = state[action.payload.workspaceId].entities[pointIndex];

  if (point) {
    togglePoint(point);
  }

  return state;
}
