import { Component, OnInit } from '@angular/core';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';

import { DropdownService } from '../../../../components/dropdown/dropdown-service/dropdown.service';

import { EPriority } from '../../priorities';

@Component({
  selector: 'app-point-priority-dropdown',
  templateUrl: './point-priority-dropdown.component.html',
  styleUrls: ['./point-priority-dropdown.component.scss'],
})
export class PointPriorityDropdownComponent implements OnInit {
  private dropdown: TDropdown;

  priorities: EPriority[] = [EPriority.LOW, EPriority.MEDIUM, EPriority.HIGH];

  constructor(private dropdownService: DropdownService) {}

  ngOnInit() {
    this.dropdown = this.dropdownService.getDropdown();

    if (!this.dropdown.callback) {
      throw new Error('Callback for this dropdown is required');
    }
  }

  selectOption(option: EPriority): void {
    this.dropdown.callback(option);
    this.dropdownService.hideDropdown();
  }
}
