import { GET_CUSTOM_FIELDS } from '../../../custom-fields/custom-fields.store';
import { TColumn } from '../../site-table/columns/column.model';
import { checkCustomFieldVolyTooltip } from './check-custom-field-voly-tooltip';

export function checkFieldVolyTooltip(column: TColumn): string {
  if (column?.customFieldIds?.length > 0) {
    const customFields = GET_CUSTOM_FIELDS();
    const customField = customFields[column.customFieldIds[0]];

    return checkCustomFieldVolyTooltip(customField);
  }

  return '';
}
