import { OnDestroy, Pipe, PipeTransform } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DEFAULT_DATE_FORMAT } from '../../project/modules/preferences/default-date-format';
import { TPreferences } from '../../project/modules/preferences/preferences.model';
import { transformDate } from '../../project/shared/date-transformer';
import { EStore } from '../../project/shared/enums/store.enum';

@Pipe({
  name: 'ppDate',
})
export class DatePipe implements PipeTransform, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  preferences$: Observable<TPreferences>;
  format = DEFAULT_DATE_FORMAT;

  constructor(
    private store: Store<{
      preferences: TPreferences;
    }>,
  ) {
    this.preferences$ = this.store.pipe(select(EStore.PREFERENCES));

    this.preferences$.pipe(takeUntil(this.destroy$)).subscribe((preferences) => {
      this.format = preferences?.dateFormat ? preferences.dateFormat : DEFAULT_DATE_FORMAT;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  transform(value: number | string, hourFormat?: string): string {
    if (Array.isArray(value)) {
      const startDate = transformDate({
        value: value[0],
        inputHourFormat: hourFormat,
        format: this.format,
        localTime: false,
      });
      const endDate = transformDate({
        value: value[1],
        inputHourFormat: hourFormat,
        format: this.format,
        localTime: false,
      });

      return `${startDate} to ${endDate}`;
    } else {
      return transformDate({ value, inputHourFormat: hourFormat, format: this.format });
    }
  }
}
