import { createElement } from 'src/app/core/helpers/dom';
import { checkCurrentDate } from '../../site-timeline-utils/checkCurrentDate';

export function createTimelineCellInnerElement(_index: number): HTMLElement {
  const dateMatches = checkCurrentDate(_index);

  let classList = 'table__cell__inner';

  if (dateMatches) {
    classList += ' timeline__cell__inner__indicator';
  }

  return createElement('div', {
    attrs: {
      class: classList,
    },
  });
}
