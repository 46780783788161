<div class="dashletActivity dashletActivity--flex-start">
  <div class="dashletActivity__container dashletActivity__container--comment-mt">
    <img class="dashletActivity__icon" [src]="EIconPath.BASIC_FIELD_COMMENT" />

    <div class="dashletActivity__text">
      {{ 'added' | ppTranslate }}
      <span class="timeline__strong">{{ 'comment' | ppTranslate }}:</span>
    </div>
  </div>
  <pp-description-dashlet-activity
    [ppDescription]="ppRichText"
    [ppPlainText]="ppPlainText"
    [ppComment]="true"
  ></pp-description-dashlet-activity>
</div>
