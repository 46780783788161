<pp-modal [ppNoPadding]="true" [ppWidth]="627" [ppHideClose]="showingError">
  <ng-container heading>
    <p class="fleetName" ppTooltip [ppTitle]="modalHeader" [ppTrimTooltip]="true">
      {{ modalHeader }}
    </p>
  </ng-container>

  <ng-container body>
    <ng-container *ngIf="!showingError">
      <section class="shareFleetInviteSection">
        <div class="shareFleetInviteSectionContent">
          <header class="shareFleetInviteSectionHeader">{{ 'invite_users' | ppTranslate }}</header>

          <p class="shareFleetInviteSectionDescription">
            {{ 'share_fleet_modal_description' | ppTranslate }}
          </p>
        </div>

        <pp-fleet-sharing-modal-email-input
          [(ppEmailList)]="emailList"
          (ppEmailListChange)="updateEmails($event)"
          [ppDisabled]="errorMessages?.length > 0"
          (ppAction)="inviteUsers()"
          [ppProcessing]="processing"
          [ppFleetShares]="fleetShares"
        ></pp-fleet-sharing-modal-email-input>

        <div class="editAccountUserSiteList__warnings">
          <pp-fleet-sharing-error-box
            *ngIf="errorMessages?.length > 0"
          ></pp-fleet-sharing-error-box>
        </div>
      </section>

      <section class="shareFleetUserList">
        <header class="shareFleetUserListHeader">
          <pp-checkbox
            ppSize="medium"
            ppType="solid"
            [ppChecked]="selectedShares.length === selectableShares.length"
            (ppOnValueChange)="selectAll()"
          ></pp-checkbox>

          <p class="shareFleetUserListHeaderText">
            <span class="shareFleetUserListHeaderText--primary">{{
              'share_fleet_modal_list_header' | ppTranslate
            }}</span>

            <span class="shareFleetUserListHeaderText--secondary">{{
              'selected_sites_number'
                | ppTranslate
                  : {
                      current: selectedShares.length,
                      max: selectableShares.length
                    }
            }}</span>
          </p>
        </header>

        <section class="shareFleetUserListContent">
          <pp-fleet-sharing-user-row
            *ngFor="let fleetShare of fleetShares"
            [ppFleetShare]="fleetShare"
            [ppSelected]="selectedShares.includes(fleetShare.id)"
            (ppDelete)="deleteShare(fleetShare)"
            (ppToggle)="toggleShare(fleetShare)"
          ></pp-fleet-sharing-user-row>
        </section>
      </section>
    </ng-container>

    <section *ngIf="showingError" class="shareFleetError">
      <div class="shareFleetError__message">
        <span class="shareFleetError__messageText">{{
          'fleet_sharing_confirm_close' | ppTranslate
        }}</span>

        <div class="shareFleetError__buttons">
          <pp-button [ppStyle]="'secondary'" (ppAction)="backFromErrorPage()">
            {{ 'back' | ppTranslate }}
          </pp-button>

          <pp-button-modal-confirm [ppError]="true" (ppAction)="closeModalWithUnsavedChanges()">
            {{ 'leave_without_sharing' | ppTranslate }}
          </pp-button-modal-confirm>
        </div>
      </div>
    </section>
  </ng-container>

  <ng-container footer>
    <pp-button-modal-cancel
      [ppDataTarget]="'cancelShareFleetModalButton'"
      ppId="modalCancelButton"
      (ppAction)="cancel()"
      *ngIf="!showingError"
    >
      {{ 'cancel' | ppTranslate }}
    </pp-button-modal-cancel>
  </ng-container>
</pp-modal>

<pp-fleet-sharing-bulk-changes-bar
  [ppZIndex]="900"
  [ppSelectedShares]="selectedShares"
  (ppDeleteShares)="deleteShares()"
  (ppDeselectAll)="deselectAll()"
></pp-fleet-sharing-bulk-changes-bar>
