import { Component, OnInit, OnDestroy } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TUser } from 'src/app/project/modules/user/user.model';
import { TAccount } from '../../account.model';

import { SiteService } from 'src/app/project/modules/site/site.service';
import { Title } from '@angular/platform-browser';
import { translate } from 'src/app/project/features/translate/translate';
import { EStore } from '../../../../shared/enums/store.enum';
import { ActiveService } from 'src/app/project/services/active/active.service';

@Component({
  selector: 'pp-account-general-settings',
  templateUrl: './account-general-settings.component.html',
  styleUrls: ['../account-settings.component.scss'],
})
export class AccountGeneralSettingsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  accountId = '';
  accountName = '';
  addingUsers = false;

  user$: Observable<TUser>;
  user: TUser;
  private accounts$: Observable<TAccount[]>;
  account: TAccount;
  offline$: Observable<boolean>;
  offline = false;

  constructor(
    private store: Store<{
      user: TUser;
      accounts: TAccount[];
      offline: boolean;
    }>,
    private siteService: SiteService,
    private titleService: Title,
    private activeService: ActiveService,
  ) {
    this.user$ = this.store.pipe(select(EStore.USER));
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
    this.offline$ = this.store.pipe(select(EStore.OFFLINE));
  }

  ngOnInit() {
    this.titleService.setTitle(translate('account_settings_title'));
    this.siteService.setFetched(true);

    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
      if (accounts.length > 0) {
        this.accountId = this.activeService.getActiveAccountId();
        this.account = accounts.find((account) => account.accountId === this.accountId);
      }
    });

    this.offline$.pipe(takeUntil(this.destroy$)).subscribe((offline) => {
      this.offline = offline;
    });

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
