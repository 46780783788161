import { TShare } from 'src/app/project/modules/share/share.model';

export function checkDifferentAccessLevelsPerSite(shares: TShare[]): boolean {
  const accessLevelsPerSite: {
    [workspaceId: string]: string[];
  } = generateAccessLevelsPerSite(shares);
  const workspaceIds = Object.keys(accessLevelsPerSite);

  for (let workspaceId of workspaceIds) {
    const accessLevels = accessLevelsPerSite[workspaceId];

    if (checkDifferentAccessLevelInSite(accessLevels)) {
      return true;
    }
  }

  return false;
}

function generateAccessLevelsPerSite(shares: TShare[]) {
  const accessLevelsPerSite: {
    [workspaceId: string]: string[];
  } = {};

  shares.forEach((share) => {
    if (!accessLevelsPerSite[share.workspaceId]) {
      accessLevelsPerSite[share.workspaceId] = [];
    }

    accessLevelsPerSite[share.workspaceId].push(share.shareOption);
  });
  return accessLevelsPerSite;
}

function checkDifferentAccessLevelInSite(accessLevels: string[]): boolean {
  return accessLevels.some((accessLevel) => {
    return accessLevel !== accessLevels[0];
  });
}
