import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-button-dropdown',
  templateUrl: './button-dropdown.component.html',
  styleUrls: ['./button-dropdown.component.scss'],
})
export class ButtonDropdownComponent {
  @Input() ppActive: boolean;
  @Input() ppText = '';
  @Input() ppSrc: EIconPath;
  @Input() ppNumber: string;
  @Input() ppDisabled: boolean;
  @Output() ppAction = new EventEmitter<MouseEvent>();

  action(): void {
    if (!this.ppDisabled) {
      this.ppAction.emit();
    }
  }
}
