<pp-rich-text
  class="description__container"
  [ppId]="'descriptionContainer'"
  [ppPointId]="ppPointId"
  [ppCanEdit]="ppCanEdit"
  [ppValue]="{ richText: ppDescriptionRich, plainText: ppDescription }"
  [ppWorkspaceId]="ppWorkspaceId"
  [ppPlaceholder]="'enter_description' | ppTranslate"
  [ppDisabledPlaceholder]="'enter_description' | ppTranslate"
  (ppUpdate)="updateField($event)"
  (ppUpdateUserList)="updateUserList()"
  [ppUserListIds]="userListIds"
  [ppOptions]="richTextOptions"
  data-test="pointDescription"
></pp-rich-text>
