import Timeline from './Timeline';

let timeline: Timeline = null;

// GETTERS

export const GET_TIMELINE = (): Timeline => timeline;

// SETTERS

export const SET_TIMELINE = (_timeline: Timeline): void => {
  timeline = _timeline;
};

// CLEAR

export const CLEAR_TIMELINE = (): void => {
  timeline = null;
};
