<div
  ppTooltip
  [ppSanitize]="false"
  class="assetCard__label"
  [ppOptions]="{ theme: 'labelUser', placement: 'bottom-start', arrow: false }"
  [style.background-color]="colorCode"
  [ppTitle]="tooltipText"
  (click)="logLabelClick()"
  (mouseenter)="logLabelHover()"
>
  <p
    class="assetCard__labelText"
    [style.color]="ppLabel.color"
    [innerHtml]="ppLabel.name | highlightKeyword: keyword"
  ></p>
</div>
