import { TShare } from 'src/app/project/modules/share/share.model';
import { EUserRole } from './user-roles';

export function generateNewSiteShare(
  userId: string,
  workspaceId: string,
  limitedUser: boolean,
): TShare {
  const share: TShare = {
    userId: userId,
    shareOption: limitedUser ? EUserRole.SITE_ADMIN : EUserRole.OWNER,
    workspaceId: workspaceId,
    advancedAccessLevels: {
      customFields: [],
      tags: {
        permission: {
          read: true,
          edit: true,
        },
      },
      timeline: {
        permission: {
          read: true,
        },
        comments: {
          permission: {
            read: true,
            edit: true,
          },
        },
      },
      sitePlan: {
        permission: {
          read: true,
        },
      },
      exports: {
        permission: {
          read: true,
        },
      },
    },
    defectTags: [],
    shareId: '',
    hidden: false,
    tagLimited: false,
  };

  return share;
}
