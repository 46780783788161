import { Component, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subject, finalize, takeUntil, tap } from 'rxjs';
import {
  ConfirmModalComponent,
  TConfirmModalParams,
} from 'src/app/project/components/confirm-modal/confirm-modal.component';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TFleetCreateDTO } from 'src/app/project/data-providers/api-providers/fleet-api-provider/fleet-requests.model';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { FleetManagementRoutesService } from '../fleet-management-routes.service';
import { EFleetManagementRoutesParams } from '../fleet-management.routes';
import { FleetService } from '../fleet-service/fleet.service';
import { getFirstAvailableFleet } from '../fleet-service/get-first-available-fleet';
import { TFleet } from '../fleet.consts';
import { checkEmptyFleetChanges } from './utils/check-empty-fleet-chages';
import { checkExistingFleetChanges } from './utils/check-existing-fleet-changes';
import { getDefaultFleetStatuses } from './utils/get-default-fleet-statuses';

@Component({
  selector: 'pp-fleet-management-empty-fleet',
  templateUrl: './fleet-management-empty-fleet.component.html',
  styleUrls: ['./fleet-management-empty-fleet.component.scss'],
})
export class FleetManagementEmptyFleetComponent implements OnDestroy {
  EIconPath = EIconPath;
  accountId: string = '';
  processing: boolean;
  fleetId: string;
  canGoBack: boolean;
  editingFleet: boolean;

  newFleet: TFleetCreateDTO = {
    name: '',
    statusSettings: getDefaultFleetStatuses(),
  };

  private readonly destroy$ = new Subject<void>();

  constructor(
    private fleetService: FleetService,
    private promptService: PromptService,
    private translationPipe: TranslationPipe,
    private fleetManagementRoutesService: FleetManagementRoutesService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
  ) {
    this.setTitle();

    this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.fleetId = params.get(EFleetManagementRoutesParams.FLEET_ID);

      if (this.fleetId) {
        const fleet = this.fleetService.getFleet(this.fleetId);
        this.editingFleet = true;

        this.accountId = fleet.accountId;
        this.newFleet.name = fleet.name;
        this.newFleet.statusSettings = fleet.statusSettings;
      }
    });

    this.canGoBack = Object.keys(this.fleetService.getFleets()).length > 0;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  selectAccount(accountId: string): void {
    this.accountId = accountId;
  }

  saveChanges(): void {
    if (this.editingFleet) {
      this.updateFleet();
    } else {
      this.createFleet();
    }
  }

  goBack(): void {
    let hasUnsavedChanges = false;

    if (!this.fleetId) {
      hasUnsavedChanges = checkEmptyFleetChanges({
        ...this.newFleet,
        accountId: this.accountId,
      });
    } else {
      hasUnsavedChanges = checkExistingFleetChanges(this.fleetService.getFleet(this.fleetId), {
        ...this.newFleet,
        accountId: this.accountId,
        id: this.fleetId,
      });
    }

    if (hasUnsavedChanges) {
      this.showConfirmUnsavedChangesModal();
      return;
    }

    this.redirectToPreviousPage();
  }

  private redirectToPreviousPage(): void {
    if (this.fleetId) {
      this.fleetManagementRoutesService.goToFleet(this.fleetId);
    } else {
      const firstAvailableFleet = getFirstAvailableFleet(this.fleetService.getFleets());

      this.fleetManagementRoutesService.goToFleet(firstAvailableFleet?.id);
    }
  }

  private updateFleet(): void {
    if (!this.accountId) {
      this.showNoAccountSelectedWarning();
      return;
    }

    this.processing = true;

    const body: Partial<TFleet> = {
      ...this.newFleet,
      accountId: this.accountId,
      id: this.fleetId,
    };

    this.fleetService
      .updateFleet(body)
      .pipe(
        tap(() => {
          this.fleetManagementRoutesService.goToFleet(this.fleetId);
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  private createFleet(): void {
    if (!this.accountId) {
      this.showNoAccountSelectedWarning();
      return;
    }

    this.processing = true;

    this.fleetService
      .createFleet(this.newFleet.name, this.accountId, this.newFleet.statusSettings)
      .pipe(
        tap((response) => {
          const prompt = this.translationPipe.transform('prompt_fleet_created');
          this.promptService.showSuccess(prompt);

          this.fleetService.setActiveFleetId(response.id);
          this.fleetManagementRoutesService.goToFleet(response.id);
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  private showNoAccountSelectedWarning(): void {
    const prompt = this.translationPipe.transform('prompt_fleet_create_no_account_selected');

    this.promptService.showWarning(prompt);
  }

  private setTitle(): void {
    this.titleService.setTitle(
      this.translationPipe.transform('fleet_management') +
        ' - ' +
        this.translationPipe.transform('create_new_fleet') +
        ' | Pinpoint Works',
    );
  }

  private showConfirmUnsavedChangesModal(): void {
    const modalData: TConfirmModalParams = {
      message: this.translationPipe.transform('modal_unsaved_changes_message_leave_page'),
      heading: this.translationPipe.transform('modal_unsaved_changes_header'),
      redButton: true,
      confirmText: this.translationPipe.transform('close_without_saving'),
      boldText: true,
    };

    this.modalService.setData(modalData);

    this.modalService.showModal(ConfirmModalComponent, {
      blur: false,
      callback: () => {
        this.redirectToPreviousPage();
      },
    });
  }
}
