<div class="filterDropdown__section">
  <div class="filterDropdown__heading">
    <h6>{{ 'reminders_due_date' | ppTranslate }}</h6>
    <button
      class="filterDropdown__clear--text"
      *ngIf="ppFilters.reminders.startDate || ppFilters.reminders.endDate"
      (click)="clearReminders()"
    >
      {{ 'clear' | ppTranslate }}
    </button>
  </div>

  <div class="filterContent__date-range">
    <div class="filterContent__date-wrapper">
      <pp-datepicker
        class="siteFilter__date-picker"
        [ppFilterDatepicker]="true"
        [ppDate]="ppFilters.reminders.startDate"
        [ppMaxDate]="ppFilters.reminders.endDate"
        (ppSelect)="updateRemindersStartDate($event)"
        [ppPlaceholder]="'from' | ppTranslate"
      ></pp-datepicker>
    </div>

    <div class="filterContent__date-wrapper">
      <pp-datepicker
        class="siteFilter__date-picker"
        [ppFilterDatepicker]="true"
        [ppDate]="ppFilters.reminders.endDate"
        [ppMinDate]="ppFilters.reminders.startDate"
        (ppSelect)="updateRemindersEndDate($event)"
        [ppPlaceholder]="'to' | ppTranslate | lowercase"
      ></pp-datepicker>
    </div>
  </div>

  <hr />
</div>
