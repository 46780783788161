import { TPreferences } from './preferences.model';

let preferences: TPreferences = null;
let workspacePreferences: TPreferences = null;

// Get

export const GET_PREFERENCES = (): TPreferences => preferences;

// TODO Workspace preferences feature is unused (?)
export const GET_WORKSPACE_PREFERENCES = (): TPreferences => workspacePreferences;

// Set

export const SET_PREFERENCES = (_preferences: TPreferences): void => {
  preferences = _preferences;
};

export const SET_WORKSPACE_PREFERENCES = (_workspacePreferences: TPreferences): void => {
  workspacePreferences = _workspacePreferences;
};

export const CLEAR_PREFERENCES = (): void => {
  preferences = null;
};

export const CLEAR_WORKSPACE_PREFERENCES = (): void => {
  workspacePreferences = null;
};
