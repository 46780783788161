import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from '../../shared/enums/icons.enum';

@Component({
  selector: 'pp-custom-field-tile-small',
  templateUrl: './custom-field-tile-small.component.html',
  styleUrl: './custom-field-tile-small.component.scss',
})
export class CustomFieldTileSmallComponent {
  @Input() ppImage: EIconPath;
  @Input() ppText = '';
  @Input() ppDisabled = false;
  @Output() ppOnSelect = new EventEmitter<void>();

  EIconPath = EIconPath;

  optionSelect(): void {
    if (this.ppDisabled) {
      return;
    }

    this.ppOnSelect.emit();
  }
}
