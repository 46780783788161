import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';

import { uiReducer } from './ui.reducer';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('ui', uiReducer), PipesModule],
})
export class UiModule {}
