import { TPoint } from 'src/app/project/modules/points/points.model';
import { TFilters } from '../site-filter.model';

export function checkReminders(point: TPoint, filters: TFilters): boolean {
  if ((filters.reminders?.startDate || filters.reminders?.endDate) && !point.reminderEpochMillis) {
    return false;
  }

  if (filters.reminders?.startDate && +point.reminderEpochMillis < filters.reminders.startDate) {
    return false;
  }

  return !(filters.reminders?.endDate && +point.reminderEpochMillis > filters.reminders.endDate);
}
