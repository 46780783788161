import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { InputFocusService } from '../../../core/services/input-focus.service';

@Directive({
  selector: '[ppFocus]',
})
export class FocusDirective implements AfterViewInit {
  constructor(private el: ElementRef, private inputFocusService: InputFocusService) {}

  ngAfterViewInit(): void {
    this.inputFocusService.focus(this.el.nativeElement);
  }
}
