import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EExportOrientation } from '../../../exports/utils/export-orientation.enum';

@Component({
  selector: 'pp-fleet-export-page-orientation-picker',
  templateUrl: './fleet-export-page-orientation-picker.component.html',
  styleUrl: './fleet-export-page-orientation-picker.component.scss',
})
export class FleetExportPageOrientationPickerComponent {
  @Input() ppPageOrientation: EExportOrientation;
  @Output() ppPageOrientationChange = new EventEmitter<EExportOrientation>();

  EExportOrientation = EExportOrientation;

  selectMode(mode: EExportOrientation): void {
    this.ppPageOrientationChange.emit(mode);
  }
}
