import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { IconComponent } from './icon.component';

@NgModule({
  declarations: [IconComponent],
  imports: [CommonModule, FormsModule, OnRenderedModule, AngularSvgIconModule],
  exports: [IconComponent],
})
export class IconModule {}
