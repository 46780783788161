<ng-container *ngIf="dropdown.data">
  <a
    id="editReminerButton"
    data-m-target="Edit reminder button"
    class="dropdownItem"
    (click)="editReminder()"
  >
    {{ 'edit_reminder' | ppTranslate }}
  </a>

  <a
    id="deleteReminderButton"
    data-m-target="Delete reminder button"
    class="dropdownItem"
    (click)="deleteReminder()"
  >
    {{ 'delete_reminder' | ppTranslate }}
  </a>
</ng-container>
