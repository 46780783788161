<table
  aria-label="User sites table"
  class="settingsTable"
  *ngIf="ppWorkspaces && ppWorkspaces.length > 0"
>
  <thead class="settingsTable__head">
    <tr class="userSettingsTable__header-row">
      <th scope="col" class="settingsTable__header">
        {{ 'site' | ppTranslate }}
      </th>

      <th scope="col" class="settingsTable__header userSettingsTable__hide-site">
        {{ 'hide_site' | ppTranslate }}
      </th>

      <th scope="col" class="settingsTable__header userSettingsTable__access">
        {{ 'access' | ppTranslate }}
      </th>

      <th scope="col" class="settingsTable__header userSettingsTable__notification-header">
        {{ 'email_notification' | ppTranslate }}
      </th>

      <th scope="col" class="settingsTable__header" *ngIf="user.isSuperUser"></th>
    </tr>
  </thead>

  <tbody class="settingsTable__body">
    <tr class="userSettingsTable__row" *ngFor="let workspace of workspaces; let i = index">
      <td class="settingsTable__multirow settingsTable__cell">
        <p class="settingsTable__multirow-top">
          {{ workspace.name }}
        </p>

        <p class="settingsTable__multirow-bottom">
          {{ workspace.accountName }}
        </p>
      </td>

      <td class="settingsTable__cell userSettingsTable__hide-site">
        <pp-toggle
          [ppId]="'hideButton_' + workspace.workspaceId"
          ppStyle="blue"
          (ppAction)="toggleSiteHidden(workspace)"
          [ppChecked]="workspace.hidden"
          [ppReverseLayout]="true"
        ></pp-toggle>
      </td>

      <td class="settingsTable__cell userSettingsTable__access">
        {{ shareOptionLabels[workspace?.shareOption] }}
      </td>

      <td class="userSettingsTable__notifications settingsTable__cell">
        <ng-container *ngIf="!offline">
          {{
            subscriptionsById.data[workspace.workspaceId]?.subscriptionType
              ? (notificationLabels[subscriptionsById.data[workspace.workspaceId]?.subscriptionType]
                | ppTranslate)
              : ('notification_none' | ppTranslate)
          }}
        </ng-container>

        <ng-container *ngIf="offline"> {{ 'data_not_available' | ppTranslate }} </ng-container>

        <pp-edit-button
          (ppAction)="editNotifications(workspace)"
          [ppDisabled]="offline"
          ppId="editNotificationsBtn"
          data-m-target="Edit notifications button"
          class="userSettingsTable__notifications-btn"
          [ppNoText]="true"
        ></pp-edit-button>
      </td>

      <td class="settingsTable__cell" *ngIf="user.isSuperUser">
        <pp-delete-button
          (ppAction)="removeWorkspace(workspace.workspaceId)"
          [ppProcess]="deletingWorkspace[workspace.workspaceId]"
        ></pp-delete-button>
      </td>
    </tr>
  </tbody>
</table>
