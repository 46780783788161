import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { TWorkspace } from '../workspace/workspace.model';

import { PromptService } from '../../components/prompt/prompt.service';
import { ActiveService } from '../../services/active/active.service';
import { TPoint } from '../points/points.model';
import { PointsService } from '../points/points.service';
import { WorkspaceService } from '../workspace/workspace.service';
import { PlanDataService, TPlanData } from './plan-data.service';
import { PlanPinsService } from './plan-pins.service';
import { PlanPointService } from './plan-point/plan-point.service';
import { PlanSiteService } from './plan-site.service';
import { PlanService } from './plan.service';

import { TSimpleChangesMap } from '@core/helpers';
import { Subject, of } from 'rxjs';
import { catchError, debounceTime, finalize, takeUntil, tap } from 'rxjs/operators';
import { NEW_POINT_ID } from '../../shared/constants/point.const';
import { EIconPath } from '../../shared/enums/icons.enum';
import { EInstance } from '../../shared/enums/instances';
import { generateErrorPrompt, logErrorInSentry } from '../errors/response-error';
import { EPlanModule } from './plan-module.enum';
import { FeatureDragService } from './plan-point/feature-drag.service';
import { FeatureTranslateService } from './plan-point/feature-translate.service';
import { PlanPointClickService } from './plan-point/plan-point-click.service';
import { PlanPointDoubleClickService } from './plan-point/plan-point-double-click.service';
import { PlanPointDragService } from './plan-point/plan-point-drag.service';
import { PlanPointPointerMoveEndService } from './plan-point/plan-point-pointer-move-end.service';
import { PlanPointPointerMoveService } from './plan-point/plan-point-pointer-move.service';
import { PlanUnsubscribeService } from './plan-unsubscribe.service';
import { SET_PLAN_COMPONENT } from './plan.store';
import { getTiles } from './tiles';

@Component({
  selector: 'pp-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
})
export class PlanComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('planElement', { static: true }) planElement: ElementRef;

  @Input() ppModule: EPlanModule;
  @Input() ppCanEdit: boolean;
  @Input() ppWorkspaceId: string = null;
  @Input() ppPointId: string = null;
  @Input() ppHalfModal = false;
  @Input() ppActive: boolean = null;
  @Input() ppNoPins = false;
  @Input() ppWidth = null;

  private readonly destroy$ = new Subject<void>();
  private readonly resize$ = new Subject<void>();

  loadingPlan = true;
  workspace: TWorkspace;
  firstLoad = true;
  EIconPath = EIconPath;

  private plan: TPlanData = this.planDataService.getPlan();
  private planRebuildDebounceTimeMs = 200;

  constructor(
    @Inject('HOSTNAME') private host: string,
    private planDataService: PlanDataService,
    private planPinsService: PlanPinsService,
    private planSiteService: PlanSiteService,
    private planPointService: PlanPointService,
    private planService: PlanService,
    private activeService: ActiveService,
    private workspaceService: WorkspaceService,
    private pointsService: PointsService,
    private promptService: PromptService,
    private featureTranslateService: FeatureTranslateService,
    private featureDragService: FeatureDragService,
    private planPointClickService: PlanPointClickService,
    private planPointDoubleClickService: PlanPointDoubleClickService,
    private planPointPointerMoveEndService: PlanPointPointerMoveEndService,
    private planPointDragService: PlanPointDragService,
    private planPointPointerMoveService: PlanPointPointerMoveService,
    private planUnsubscribeService: PlanUnsubscribeService,
  ) {}

  ngOnInit(): void {
    this.resize$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(this.planRebuildDebounceTimeMs),
        tap(() => {
          if (this.workspace && this.workspace.sitePlan) {
            this.updatePlanSize();
          }
        }),
      )
      .subscribe();
  }

  ngOnChanges(changes: TSimpleChangesMap) {
    if (changes.ppWorkspaceId) {
      this.updateWorkspace(changes.ppWorkspaceId.currentValue);
    } else if (!this.ppWorkspaceId) {
      this.updateWorkspace();
    }

    if (this.workspace?.sitePlan) {
      if (this.host !== EInstance.ASM && !(changes.ppWidth && Object.keys(changes).length === 1)) {
        this.loadingPlan = true;
        this.planService
          .generateS3Credentials(this.ppWorkspaceId)
          .pipe(
            takeUntil(this.destroy$),
            tap(() => {
              this.createPlan(changes);
            }),
            catchError((error) => {
              const prompt = generateErrorPrompt(error, 'prompt_site_plan_loading_error');

              logErrorInSentry(error);

              console.error(error);

              this.promptService.showError(prompt, { duration: 30 });

              return of();
            }),
            finalize(() => {
              this.loadingPlan = false;
            }),
          )
          .subscribe();
      } else {
        this.createPlan(changes);
      }
    }

    if (this.ppModule === EPlanModule.POINT) {
      SET_PLAN_COMPONENT(this);
    }
  }

  createPlan(changes: TSimpleChangesMap): void {
    if (changes.ppWorkspaceId && this.ppModule === 'site') {
      if (this.workspace && this.workspace.sitePlan) {
        this.loadingPlan = true;

        getTiles(this.ppWorkspaceId, this.workspace.sitePlan.version).finally(() => {
          this.loadingPlan = false;

          this.createPlanForSite(this.ppWorkspaceId);
        });
      }
    }

    if (changes.ppWidth) {
      this.resize$.next();
    }

    if (changes.ppPointId && this.ppModule === 'point') {
      this.planDataService.clearPointPlan();
      this.createPlanForPoint();
    }

    if (
      changes.ppPointId &&
      !changes.ppPointId.isFirstChange() &&
      this.ppModule === EPlanModule.POINT
    ) {
      if (changes.ppWorkspaceId) {
        getTiles(this.ppWorkspaceId, this.workspace.sitePlan.version).finally(() => {
          this.createPlanForPoint(this.ppActive);
        });
      } else {
        this.planPointService.updateFeature(this.ppPointId, this.ppWorkspaceId);
      }
    }

    if (changes.ppActive && !changes.ppActive.isFirstChange()) {
      getTiles(this.ppWorkspaceId, this.workspace.sitePlan.version).finally(() => {
        this.createPlanForPoint(this.ppActive);
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();

    this.planUnsubscribeService.unsubscribeEvents(this.ppModule, this.ppCanEdit);
  }

  createPlanForSite(workspaceId: string): void {
    this.planUnsubscribeService.unsubscribePlanSiteEvents();

    this.planService.createPlan({ element: this.planElement.nativeElement }, workspaceId);

    if (!this.ppNoPins) {
      this.planPinsService.updatePointMarkers(EPlanModule.SITE);
      this.planSiteService.pointermoveEventOn();
      this.planSiteService.clickEventOn();
      this.planSiteService.dblclickEventOn();
      this.planSiteService.moveendEventOn();
    }
  }

  createPlanForPoint(ppActive: boolean = false): void {
    let planZoom = this.planService.getZoom(EPlanModule.POINT);
    let position = this.planService.getCenter(EPlanModule.POINT);

    if (this.workspace && !this.workspace.sitePlan) {
      return;
    }

    this.planUnsubscribeService.unsubscribePlanPointEvents(true);

    this.planService.createPlan(
      {
        module: EPlanModule.POINT,
        element: this.planElement.nativeElement,
      },
      this.ppWorkspaceId,
    );

    if (this.ppPointId === NEW_POINT_ID) {
      const point: TPoint = this.pointsService.findPoint(this.ppPointId);

      if (this.firstLoad) {
        planZoom = this.planService.getZoom(EPlanModule.SITE);
        position = this.planService.getCenter(EPlanModule.SITE);

        this.firstLoad = false;
      }

      if (point && !point.pins) {
        point.pins = [];
      }

      this.planPinsService.addPointMarker(this.ppPointId, EPlanModule.POINT, this.ppWorkspaceId);

      if (position) {
        this.planService.setCenter(position, EPlanModule.POINT);
      }

      this.planService.setZoom(planZoom, EPlanModule.POINT);
    } else {
      this.planPinsService.addPointMarker(this.ppPointId, EPlanModule.POINT, this.ppWorkspaceId);

      if (position) {
        this.planService.setCenter(position, EPlanModule.POINT);
        this.planService.setZoom(planZoom, EPlanModule.POINT);
      }
    }

    this.planPointDoubleClickService.dblclickEventOn();
    this.planPointPointerMoveEndService.moveendEventOn();

    if (this.ppCanEdit) {
      if (this.plan.point.pins) {
        this.plan.point.pins.forEach((pin) => {
          this.featureTranslateService.createInteraction(pin);
        });
      }

      if (!(this.plan.point?.polygons?.length > 0)) {
        this.featureDragService.featureDragOn();
      }

      this.planPointPointerMoveService.pointermoveEventOn();
      this.planPointDragService.pointerDragEventOn();
      this.planPointClickService.clickEventOn();
      this.planPointPointerMoveEndService.moveendEventOn();
    }

    this.planService.toggleZoom(ppActive);
  }

  private updatePlanSize(): void {
    getTiles(this.ppWorkspaceId, this.workspace.sitePlan.version).finally(() => {
      this.createPlanForSite(this.ppWorkspaceId);
    });
  }

  private updateWorkspace(newWorkspaceId: string = null): void {
    this.ppWorkspaceId = newWorkspaceId || this.activeService.getActiveWorkspaceId();
    this.workspace = this.workspaceService.findWorkspace(this.ppWorkspaceId);
  }
}
