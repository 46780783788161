import Quill from 'quill';
import { Base64 } from 'js-base64';

import { Component, Input, OnInit } from '@angular/core';
import { TRichText } from 'src/app/project/components/input/rich-text/rich-text-ops.model';

@Component({
  selector: 'pp-description-dashlet-activity',
  templateUrl: './description-dashlet-activity.component.html',
  styleUrls: ['./description-dashlet-activity.component.scss'],
})
export class DescriptionDashletActivityComponent implements OnInit {
  @Input() ppDescription: string;
  @Input() ppPlainText = '';
  @Input() ppComment = false;

  expanded = false;
  long = false;

  private quill: Quill = null;
  private richText: TRichText;
  private length: number = null;
  private textLinesNumber: number = null;
  private splitText: string[] = [];

  constructor() {}

  ngOnInit() {
    if (this.ppDescription) {
      try {
        this.richText = JSON.parse(Base64.decode(this.ppDescription));
      } catch {}
    }
  }

  initEditor(descriptionElement: HTMLElement): void {
    this.quill = new Quill(descriptionElement, {
      readOnly: true,
      modules: {
        toolbar: false,
      },
    });

    this.initContent();
  }

  expandDescription(): void {
    this.expanded = true;

    if (this.richText) {
      this.quill.setContents(this.richText.ops);
    }
  }

  collapseDescription(): void {
    this.expanded = false;

    if (this.richText) {
      this.quill.setContents(this.richText.ops);

      this.trimTextLines();
    }
  }

  private initContent(): void {
    if (this.richText) {
      this.quill.setContents(this.richText.ops);

      this.length = this.quill.getText().trim().length;
      this.splitText = this.quill.getText().split(/\r\n|\r|\n/);
    } else {
      this.quill.setText(this.ppPlainText);

      this.length = this.quill.getLength();
    }

    this.textLinesNumber = this.quill.getText().split(/\r\n|\r|\n/).length;

    this.trimTextLines();
  }

  private trimTextLines(): void {
    if (this.textLinesNumber > 3) {
      let lengthToTrim =
        this.splitText[0].length + this.splitText[1].length + this.splitText[2].length;

      if (lengthToTrim > 200) {
        lengthToTrim = 200;
      }

      this.long = true;
      this.quill.editor.deleteText(lengthToTrim, this.length);
      this.quill.editor.insertText(lengthToTrim, '...', 'bold', true);
    } else if (this.length > 200) {
      this.long = true;
      this.quill.editor.deleteText(200, this.length);
      this.quill.editor.insertText(200, '...', 'bold', true);
    }
  }
}
