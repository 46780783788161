import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { tap } from 'rxjs';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { TShare, TTags, TUpdateShareDTO } from '../../../share/share.model';
import { TTagSelectOptions } from '../../../tags/tag-select/tag-select.component';
import { UserManagementTableCellsService } from '../user-management-table-cells.service';
import { checkIfCellEditable } from '../utils/check-if-cell-editable';
import { getDisabledUserManagementTooltipText } from '../utils/get-disabled-user-management-cell-tooltip-text';

@Component({
  selector: 'pp-user-management-restricted-tags-cell',
  templateUrl: './user-management-restricted-tags-cell.component.html',
  styleUrls: ['./user-management-restricted-tags-cell.component.scss'],
})
export class UserManagementRestrictedTagsCellComponent implements OnChanges {
  @ViewChild('trimElement') trimElement: ElementRef;
  @Input() ppShare: TShare;

  editable: boolean;
  tagSelectOptions: TTagSelectOptions;
  placeholderText: string;
  disabledTooltipText: string;

  constructor(
    private userManagementTableCellsService: UserManagementTableCellsService,
    private translationPipe: TranslationPipe,
  ) {}

  ngOnChanges(): void {
    this.setEditable();
    this.setTagSelectOptions();
    this.setPlaceholderText(this.ppShare.tagLimited);
    this.disabledTooltipText = getDisabledUserManagementTooltipText(this.ppShare.shareOption);
  }

  private setEditable(): void {
    this.editable = checkIfCellEditable(this.ppShare.shareOption);
  }

  private setTagSelectOptions(): void {
    this.tagSelectOptions = {
      disabled: !this.editable,
      singleLineInput: true,
      noBorder: true,
      width: '24rem',
    };
  }

  private setPlaceholderText(tagLimited: boolean): void {
    this.placeholderText = tagLimited
      ? this.translationPipe.transform('none')
      : this.translationPipe.transform('unrestricted');
  }

  tryUpdateShareOnClose(tags: TTags): void {
    if (isEqual(tags, this.ppShare.defectTags)) {
      return;
    }

    this.tryUpdateShare(tags);
  }

  tryUpdateShare(tags: TTags): void {
    if (isEqual(tags, this.ppShare.defectTags) && !this.ppShare.tagLimited) {
      return;
    }

    const newPartialShare: TUpdateShareDTO = {
      defectTags: tags,
      tagLimited: tags.length > 0,
    };

    this.ppShare.tagLimited = false;

    this.userManagementTableCellsService
      .updateShare(this.ppShare.userId, this.ppShare.shareId, newPartialShare)
      .pipe(
        tap((response) => {
          this.setPlaceholderText(response.tagLimited);
        }),
      )
      .subscribe();
  }
}
