export enum ECustomFieldType {
  TEXT = 'TEXT',
  LIST = 'LIST',
  DATE = 'DATE',
  COST = 'COST',
  NUMBERS = 'NUMBERS',
  RICHTEXT = 'RICHTEXT',
  TIME = 'TIME',
  PERCENTAGE = 'PERCENTAGE',
  CHECKBOX = 'CHECKBOX',
  TIMELINE = 'TIMELINE',
  FORMULA = 'FORMULA',
}
