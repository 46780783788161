<div class="siteOptions overviewSiteOptions">
  <div *ngIf="workspaces$ | async as workspaces">
    <pp-site-filter-dropdown
      class="filterDropdown"
      [class.margin--right]="
        globalSitePreferences &&
        workspace &&
        workspace.share.shareOption !== EUserRole.SITE_ADMIN &&
        workspace.share.shareOption !== EUserRole.OWNER &&
        workspace.share.shareOption !== EUserRole.ACCOUNT_ADMIN
      "
    ></pp-site-filter-dropdown>

    <pp-button-dropdown
      [ppSrc]="EIconPath.ICON_GROUP_24"
      [ppText]="'group' | ppTranslate"
      [ppActive]="!!preferences.timeline?.group"
      (ppAction)="toggleGroupingDropdown('groupingDropdown')"
      ppTooltip
      id="groupingDropdown"
      data-m-target="Grouping button"
      [ppTitle]="'group' | ppTranslate"
    ></pp-button-dropdown>

    <pp-timeframe-button></pp-timeframe-button>
    <pp-timeline-export-button></pp-timeline-export-button>
  </div>

  <div class="siteOptions__rightOptions">
    <ng-container *ngIf="ppDataFetched">
      <pp-site-filter></pp-site-filter>

      <pp-reset-timeline-button></pp-reset-timeline-button>
    </ng-container>

    <div class="options-pagination hideMobile" *ngIf="ppDataFetched">
      <span class="point-list__pagination siteOptions__pointsNumberText">
        {{ 'showing' | ppTranslate }}
        {{ timeline?.points.length }}
        {{ timeline?.points.length === 1 ? 'point' : 'points' }}
      </span>
    </div>
  </div>
</div>
