import { EMAIL_REGEX } from '../constants/email-regex';

export function checkEmailValidity(email: string): boolean {
  return EMAIL_REGEX.test(email);
}

export function checkEmailsValidity(emails: string[]): boolean {
  let regexMatch = true;

  emails.forEach((email) => {
    if (!checkEmailValidity(email)) {
      regexMatch = false;
    }
  });

  return regexMatch;
}
