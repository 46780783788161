import { TAccountExtras } from '../account.store';
import { EAccountTypes } from './account-types-enums';

export const accountTypesNames: TAccountExtras[] = [
  { label: 'trial', value: EAccountTypes.TRIAL },
  { label: 'subscribed', value: EAccountTypes.SUBSCRIBED },
  { label: 'unsubscribed', value: EAccountTypes.UNSUBSCRIBED },
  { label: 'test', value: EAccountTypes.TEST },
  { label: 'unset', value: EAccountTypes.UNSET },
];
