import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TExportAsset } from '../../fleet-management-fleet-page/fleet-assets/export-asset-modal/export-asset-modal.component.consts';

@Component({
  selector: 'pp-fleet-export-page-assets-picker',
  templateUrl: './fleet-export-page-assets-picker.component.html',
  styleUrl: './fleet-export-page-assets-picker.component.scss',
})
export class FleetExportPageAssetsPickerComponent {
  @Input() ppExportAssets: TExportAsset[];
  @Output() ppExportAssetsChange = new EventEmitter<TExportAsset[]>();
  @Input() ppSelectable: boolean;

  allSelected = false;
  isFleetExport = false;

  ngOnChanges(): void {
    this.isFleetExport = this.ppExportAssets.length > 1;
  }

  ngDoCheck(): void {
    this.allSelected = this.ppExportAssets.every((asset) => asset.selected);
  }

  exportAssetsChange(): void {
    this.ppExportAssetsChange.emit(this.ppExportAssets);
  }

  toggleAll(): void {
    if (!this.ppSelectable || !this.isFleetExport) {
      return;
    }

    this.ppExportAssets = this.ppExportAssets.map((asset) => ({
      ...asset,
      selected: !this.allSelected,
    }));
    this.exportAssetsChange();
  }
}
