<div
  #fontSelectComponent
  id="fontSelectComponent"
  class="select select--font"
  (click)="toggleDropdown()"
>
  <div class="select__content">
    <span
      class="select__content--selected"
      [style]="'fontFamily:' + (ppSelectedFont ? ppSelectedFont.value : 'Source Sans Pro')"
    >
      {{ ppSelectedFont ? ppSelectedFont.label : 'Source Sans Pro' }}
    </span>
  </div>

  <div class="select__caret select__caret--alwaysVisible">
    <i class="fa fa-caret-down" aria-hidden="true" *ngIf="!dropdown.visible"></i>
    <i class="fa fa-caret-up" aria-hidden="true" *ngIf="dropdown.visible"></i>
  </div>
</div>
