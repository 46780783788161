import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { getDisabledUserManagementTooltipText } from 'src/app/project/modules/user-management/user-management-table-cells/utils/get-disabled-user-management-cell-tooltip-text';

@Component({
  selector: 'pp-account-user-modal-shares-list-share-custom-fields',
  templateUrl: './account-user-modal-shares-list-share-custom-fields.component.html',
  styleUrls: ['./account-user-modal-shares-list-share-custom-fields.component.scss'],
})
export class AccountUserModalSharesListShareCustomFieldsComponent {
  @Input() ppUserRole: string;
  @Input() ppDisabledByCustomFields: boolean;

  @Output() ppEditCustomFields = new EventEmitter<void>();

  disabledIfAdminTooltipText: string;
  disabledIfAdmin: boolean;

  ngOnChanges(): void {
    this.disabledIfAdminTooltipText = getDisabledUserManagementTooltipText(this.ppUserRole);
    this.disabledIfAdmin =
      this.ppUserRole === EUserRole.SITE_ADMIN || this.ppUserRole === EUserRole.ACCOUNT_ADMIN;
  }

  showCustomFieldsTable(): void {
    if (this.ppDisabledByCustomFields || this.disabledIfAdmin) {
      return;
    }

    this.ppEditCustomFields.emit();
  }
}
