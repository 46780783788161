<pp-advanced-filter-option-select
  class="advancedFilter__option"
  [ppItems]="advancedFilterPercentageOptions"
  [ppOption]="option"
  (ppOptionChange)="changeFieldCondition($event)"
  ppClass="advancedFilter__option--condition"
></pp-advanced-filter-option-select>

<ng-container
  *ngIf="
    option === EAdvancedFilterOptions.IS ||
    option === EAdvancedFilterOptions.IS_NOT ||
    option === EAdvancedFilterOptions.IS_LESS_THAN ||
    option === EAdvancedFilterOptions.IS_GREATER_THAN
  "
>
  <pp-advanced-filter-number-input
    ppUnit="%"
    [(ppValue)]="value"
    (ppValueChange)="changeFieldValue($event)"
  ></pp-advanced-filter-number-input>
</ng-container>
