import { Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { elementCreated$ } from '@core/helpers';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Directive({
  selector: '[ppOnRendered]',
})
export class OnRenderedDirective implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @Output()
  public ppOnRendered = new EventEmitter<HTMLElement>();

  constructor(private elementRef: ElementRef) {
    const element: HTMLElement = this.elementRef.nativeElement;

    elementCreated$(this.elementRef)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.ppOnRendered.emit(element);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
