<div class="editAccountUserSiteList__emails">
  <div class="editAccountUserSiteList__warnings" *ngIf="warningMessages?.length > 0">
    <pp-add-users-warning-box></pp-add-users-warning-box>
  </div>

  <h6 class="editAccountUserSiteList__emailsHeader">{{ 'users' | ppTranslate }}</h6>

  <pp-share-edit-email-input
    [(ppEmailList)]="emailList"
    [ppDisabled]="ppEmailsReadOnly"
    (ppEmailListChange)="setEmailList($event)"
  ></pp-share-edit-email-input>
</div>

<div class="editAccountUserSiteList__sitesHeader">
  <div class="editAccountUserSiteList__sitesHeaderLeft">
    <h6 class="editAccountUserSiteList__sitesHeaderMain">{{ 'sites' | ppTranslate }}</h6>
    <span class="editAccountUserSiteList__sitesHeaderSub">{{
      'selected_sites_number'
        | ppTranslate: { current: selectedWorkspaces.length, max: ppAccount.workspaces.length }
    }}</span>
  </div>

  <div class="editAccountUserSiteList__sitesHeader_right">
    <pp-checkbox
      [ppChecked]="isAllSelected"
      ppSize="medium"
      ppType="solid"
      (ppOnValueChange)="toggleAllWorkspaces()"
    ></pp-checkbox>
  </div>
</div>

<div class="editAccountUserSiteList__sitesSearch">
  <pp-search-box
    [ppPlaceholder]="'search_sites' | ppTranslate"
    ppSize="wide"
    (ppAction)="setKeyword($event)"
  ></pp-search-box>
</div>

<table aria-label="Edit account user table" class="editAccountUserSiteList__table">
  <tbody>
    <tr
      class="editAccountUserSiteList__row"
      *ngFor="
        let workspaceId of filteredWorkspacesIds;
        let first = first;
        let odd = odd;
        let even = even
      "
      [class.editAccountUserSiteList__row--first]="first"
      [class.editAccountUserSiteList__row--odd]="odd"
      [class.editAccountUserSiteList__row--even]="even"
    >
      <td
        ppTooltip
        [ppTitle]="workspaces[workspaceId].siteName"
        [ppTrimTooltip]="true"
        class="editAccountUserSiteList__siteName"
      >
        {{ workspaces[workspaceId].siteName }}
      </td>

      <td>
        <pp-checkbox
          [ppChecked]="selectedWorkspaces.includes(workspaceId)"
          ppSize="medium"
          ppType="solid"
          (ppOnValueChange)="toggleWorkspace(workspaceId)"
        ></pp-checkbox>
      </td>
    </tr>
  </tbody>
</table>
