import { Injectable } from '@angular/core';

import { TRequest } from './request.model';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  private requestsInProcess: Array<TRequest> = [];

  constructor() {}

  getRequestsInProcess(): TRequest[] {
    return this.requestsInProcess;
  }

  addRequest(request: TRequest): TRequest[] {
    this.requestsInProcess.push(request);

    request.promise.finally(() => {
      const index = this.requestsInProcess.indexOf(request);

      this.requestsInProcess.splice(index, 1);
    });

    return this.requestsInProcess;
  }

  isRequestInProcess(id: string): boolean {
    const index = this.requestsInProcess.findIndex((request) => request.id === id);

    return index > -1;
  }

  isRequestOfTypeInProcess(type: string): boolean {
    for (let i = 0; i < this.requestsInProcess.length; i++) {
      if (this.requestsInProcess[i].type === type) {
        return true;
      }
    }

    return false;
  }
}
