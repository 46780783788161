import { cloneDeep } from 'lodash';
import { DeletePoint } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';

export function deletePoint(oldState: TPointsByWorkspace, action: DeletePoint): TPointsByWorkspace {
  const state = cloneDeep(oldState);
  let pointIndex = null;

  if (state[action.payload.workspaceId]) {
    pointIndex = state[action.payload.workspaceId].entities.findIndex(
      (_point) => _point._id === action.payload._id,
    );

    if (pointIndex > -1) {
      state[action.payload.workspaceId].entities.splice(pointIndex, 1);
    }
  }

  return state;
}
