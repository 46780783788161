import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { DeleteMultipleMedia } from '../../attachments/attachments.actions';
import { RemoveAttachments } from '../../points.actions';

import {
  TAttachment,
  TAttachmentsFiles,
  TAttachmentsMedia,
} from '../../attachments/attachments.model';
import { DeleteFilesModalComponent } from './delete-files-modal/delete-files-modal.component';

import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PointsService } from '../../points.service';
import { PointAttachmentsService } from './point-attachments.service';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { TDeleteFilesRequest } from 'src/app/project/data-providers/api-providers/files-api-provider/files-requests.model';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { EBasicField } from 'src/app/project/shared/enums/basic-fields-enums';
import { CustomTableService } from '../../../site/site-table/custom-table/custom-table.service';

@Injectable({
  providedIn: 'root',
})
export class PointAttachmentsDeleteService {
  _id: string;
  workspaceId: string;
  full: boolean;

  media: TAttachmentsMedia = {
    dates: [],
    attachments: {},
  };

  files: TAttachmentsFiles = {
    attachmentIds: [],
    attachments: {},
  };

  constructor(
    private store: Store,
    private modalService: ModalService,
    private pointAttachmentsService: PointAttachmentsService,
    private pointsService: PointsService,
    private customTableService: CustomTableService,
  ) {}

  removeAttachments(attachments: TDeleteFilesRequest): void {
    const attachmentIds = [];
    const table = this.customTableService.getTable();

    Object.keys(attachments).forEach((attachmentType) => {
      attachments[attachmentType].forEach((attachmentId) => {
        delete this.media[attachmentId];

        attachmentIds.push(attachmentId);

        this.media.dates.forEach((date, index) => {
          const findIndex = date.attachmentIds.findIndex((id) => id === attachmentId);

          if (findIndex !== -1) {
            this.media.dates[index].attachmentIds.splice(findIndex, 1);
          }

          if (this.media.dates[index].attachmentIds.length === 0) {
            this.media.dates.splice(index, 1);
          }
        });

        this.files.attachmentIds.forEach((fileId, index) => {
          if (fileId === attachmentId) {
            this.files.attachmentIds.splice(index, 1);
          }
        });
      });
    });

    this.store.dispatch(new DeleteMultipleMedia(attachmentIds));
    this.pointAttachmentsService.countSelectedMedia();

    const point = this.pointsService.findPoint(this._id);

    const containsAttachments =
      Object.keys(this.media.attachments).length > 0 ||
      Object.keys(this.files.attachments).length > 0;

    this.store.dispatch(
      new RemoveAttachments({
        containsAttachments,
        _id: this._id,
        workspaceId: this.workspaceId,
      }),
    );

    point.containsAttachments = containsAttachments;

    table.updatePointAttachments(this._id, point);

    this.customTableService.updatePoint({
      _id: this._id,
      field: EBasicField.TITLE,
      newValue: point.title,
      updatedDate: new Date().getTime(),
    });

    this.media.dates = this.media.dates.filter((date) => date.attachmentIds.length > 0);
  }

  deleteAttachment(file: TAttachment): Observable<void> {
    const attachmentIds: TDeleteFilesRequest = {
      videos: [],
      documents: [],
      images: [],
      images360: [],
    };

    switch (file.type) {
      case 'File':
        attachmentIds.documents.push(file.attachmentId);

        break;
      case 'Image':
        attachmentIds.images.push(file.attachmentId);

        break;
      case 'Video':
        attachmentIds.videos.push(file.attachmentId);

        break;
      case 'Image360':
        attachmentIds.images360.push(file.attachmentId);

        break;
      default:
        logErrorInSentry(`Unsupported file type ${file.type}`);

        break;
    }

    this.modalService.setData({ _id: this._id, attachmentIds });

    return this.modalService
      .showModal(DeleteFilesModalComponent, {
        blur: !this.full,
        callback: () => {
          this.removeAttachments(attachmentIds);
        },
      })
      .pipe(take(1));
  }

  deleteFiles(files: TAttachment[]): void {
    const attachmentIds: TDeleteFilesRequest = {
      documents: [],
      videos: [],
      images: [],
      images360: [],
    };

    files.forEach((file) => {
      if (file.type === 'Image') {
        attachmentIds.images.push(file.attachmentId);
      } else if (file.type === 'Image360') {
        attachmentIds.images360.push(file.attachmentId);
      } else if (file.type === 'Video') {
        attachmentIds.videos.push(file.attachmentId);
      } else if (file.fileName) {
        attachmentIds.documents.push(file.attachmentId);
      }
    });

    this.modalService.setData({ _id: this._id, attachmentIds });

    this.modalService.showModal(DeleteFilesModalComponent, {
      blur: !this.full,
      callback: () => {
        this.removeAttachments(attachmentIds);
      },
    });
  }

  setPoint(_id: string, workspaceId: string, full: boolean): void {
    this._id = _id;
    this.workspaceId = workspaceId;
    this.full = full;
  }

  setMediaAndFiles(media: TAttachmentsMedia, files: TAttachmentsFiles): void {
    this.media = media;
    this.files = files;
  }
}
