import { Component, Input, OnChanges } from '@angular/core';
import { EIconPath } from '../../shared/enums/icons.enum';
import { EAuthorizationProgressBarStep } from './authorization-progress-bar/authorization-progress-bar.component.consts';
import {
  EAuthorizationProgressStep,
  TAuthorizationProgressIcon,
} from './authorization-progress.consts';

@Component({
  selector: 'pp-authorization-progress',
  templateUrl: './authorization-progress.component.html',
  styleUrls: ['./authorization-progress.component.scss'],
})
export class AuthorizationProgressComponent implements OnChanges {
  @Input() ppStep: EAuthorizationProgressStep;

  firstIcon: TAuthorizationProgressIcon;
  secondIcon: TAuthorizationProgressIcon;
  barProgress: EAuthorizationProgressBarStep;

  ngOnChanges(): void {
    switch (this.ppStep) {
      case EAuthorizationProgressStep.PENDING:
        this.setPendingState();
        break;
      case EAuthorizationProgressStep.ACTIVE:
        this.setActiveState();
        break;
      case EAuthorizationProgressStep.INITIAL:
      default:
        this.setInitialState();
        break;
    }
  }

  private setInitialState(): void {
    this.firstIcon = {
      src: EIconPath.ICON_INTEGRATION_WIFI,
      spinning: false,
      rotation: 0,
      active: false,
    };

    this.secondIcon = {
      src: EIconPath.ICON_INTEGRATION_WIFI,
      spinning: false,
      rotation: 180,
      active: false,
    };

    this.barProgress = EAuthorizationProgressBarStep.EMPTY;
  }

  private setActiveState(): void {
    this.firstIcon = {
      src: EIconPath.ICON_INTEGRATION_CHECK,
      spinning: false,
      rotation: 0,
      active: true,
    };

    this.secondIcon = {
      src: EIconPath.ICON_INTEGRATION_CHECK,
      spinning: false,
      rotation: 0,
      active: true,
    };

    this.barProgress = EAuthorizationProgressBarStep.FULL;
  }

  private setPendingState(): void {
    this.firstIcon = {
      src: EIconPath.ICON_INTEGRATION_CACHED,
      spinning: true,
      rotation: 0,
      active: true,
    };

    this.secondIcon = {
      src: EIconPath.ICON_INTEGRATION_WIFI,
      spinning: false,
      rotation: 180,
      active: false,
    };

    this.barProgress = EAuthorizationProgressBarStep.HALF;
  }
}
