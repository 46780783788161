import { Directive, HostListener, ElementRef } from '@angular/core';

import { OfflineService } from '../../modules/offline/offline.service';
import { ResponseErrorService } from '../../modules/errors/response-error.service';
import { EIconPath } from '../../shared/enums/icons.enum';

@Directive({
  selector: '[ppImage]',
})
export class ImagesFallbackDirective {
  defaultPath = EIconPath.PLACEHOLDER_IMAGE;
  public _element: HTMLImageElement;

  constructor(
    private elementRef: ElementRef,
    private offlineService: OfflineService,
    private responseErrorService: ResponseErrorService,
  ) {
    this._element = this.elementRef.nativeElement;
  }

  @HostListener('error', ['$event'])
  onError(): void {
    const offline = this.offlineService.getOffline();

    this.responseErrorService.errors(500);

    if (!offline) {
      this._element.src = this.defaultPath;
    }
  }
}
