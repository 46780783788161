import { Component, Input } from '@angular/core';
import { Subject, debounceTime, takeUntil, tap } from 'rxjs';
import { TTranslationKey } from 'src/app/project/features/translate/types';
import { TAssetDetails } from 'src/app/project/modules/fleet-management/asset-service/asset.consts';
import { AssetService } from 'src/app/project/modules/fleet-management/asset-service/asset.service';

@Component({
  selector: 'pp-asset-card-information-details',
  templateUrl: './asset-card-information-details.component.html',
  styleUrls: ['./asset-card-information-details.component.scss'],
})
export class AssetCardInformationDetailsComponent {
  @Input() ppDetails: TAssetDetails;
  keyword: string = '';
  private destroy$ = new Subject<void>();
  private searchKeywordDebounceTime: number = 500;

  detailsList: { key: TTranslationKey; value: string }[];

  constructor(private assetService: AssetService) {
    this.assetService.keywordChange$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(this.searchKeywordDebounceTime),
        tap((keyword) => {
          this.keyword = keyword;
        }),
      )
      .subscribe();
  }

  ngOnChanges(): void {
    this.detailsList = [
      { key: 'asset_details_imo_number', value: this.ppDetails.imoNumber },
      { key: 'asset_details_build', value: this.ppDetails.build },
      { key: 'asset_details_beam', value: this.ppDetails.beam },
      { key: 'asset_details_draft', value: this.ppDetails.draft },
      { key: 'asset_details_length', value: this.ppDetails.length },
      { key: 'asset_details_gross_tonnage', value: this.ppDetails.grossTonnage },
    ];
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
