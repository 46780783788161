import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AccountApiProviderService } from '../../data-providers/api-providers/account-api-provider/account-api-provider.service';
import { ResponseErrorService } from '../errors/response-error.service';
import {
  TAccountSubscription,
  TAccountSubscriptionUpdate,
  TSimulatedUsageBody,
} from './account.model';

@Injectable({
  providedIn: 'root',
})
export class AccountSubscriptionService {
  private subscriptionData: TAccountSubscription;

  constructor(
    private accountApiProviderService: AccountApiProviderService,
    private responseErrorService: ResponseErrorService,
  ) {}

  fetchSubscription(accountId: string): Observable<TAccountSubscription> {
    return this.accountApiProviderService.fetchAccountSubscription(accountId).pipe(
      tap((response) => {
        this.subscriptionData = response;
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  updateSubscription(
    newSubscription: TAccountSubscriptionUpdate,
    accountId: string,
  ): Observable<TAccountSubscription> {
    return this.accountApiProviderService
      .updateAccountSubscription(newSubscription, accountId)
      .pipe(
        tap((response) => {
          this.subscriptionData = response;
        }),
        catchError(this.responseErrorService.handleRequestError),
      );
  }

  getSubscriptionData(): TAccountSubscription {
    return this.subscriptionData;
  }

  fetchSimulatedUsage(
    accountId: string,
    body: TSimulatedUsageBody,
  ): Observable<TAccountSubscription> {
    return this.accountApiProviderService.fetchSimulatedUsage(accountId, body);
  }
}
