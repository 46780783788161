import { TAccountUserManagementHeaderBasicField } from '../account-user-management-header.model';

export function getUserManagementBasicFields(): TAccountUserManagementHeaderBasicField[] {
  return [
    {
      translationKey: 'access_level',
      class: 'umAccount__cell--access',
    },
    {
      translationKey: 'restrict_to_only_see_certain_tags',
      class: 'umAccount__cell--tagRestricted',
    },
    {
      translationKey: 'export',
      class: 'umAccount__cell--export',
    },
    {
      translationKey: 'comments',
      class: 'umAccount__cell--comments',
    },
    {
      translationKey: 'activity',
      class: 'umAccount__cell--activity',
    },
    {
      translationKey: 'tags',
      class: 'umAccount__cell--tags',
    },
  ];
}
