import { TAssetDetails, TAssetSite } from './asset.consts';

export function checkAssetName(assetName: string, keyword: string): boolean {
  keyword = keyword.toLowerCase();
  return assetName.toLowerCase().includes(keyword);
}

export function checkAssetDetails(details: TAssetDetails, keyword: string): boolean {
  const detailValues = Object.values(details);
  keyword = keyword.toLowerCase();

  for (let i = 0; i < detailValues.length; i++) {
    const detail = detailValues[i];

    if (detail.toLowerCase().includes(keyword)) {
      return true;
    }
  }
  return false;
}

export function checkAssetSites(sites: TAssetSite[], keyword: string): boolean {
  keyword = keyword.toLowerCase();

  for (let i = 0; i < sites.length; i++) {
    const site = sites[i];

    if (site.siteName.toLowerCase().includes(keyword)) {
      return true;
    }
  }
  return false;
}
