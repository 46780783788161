export function getMonday(epoch: number): number {
  const date = new Date(epoch);
  const day = date.getDay() || 7;

  if (day !== 0) {
    date.setHours(-24 * (day - 1));
  }

  return date.getTime();
}
