import { Action } from '@ngrx/store';

export enum EActionType {
  SetOffline = '[Offline] Set',
}

export class SetOffline implements Action {
  readonly type = EActionType.SetOffline;
  constructor(readonly payload: boolean) {}
}

export type ActionsUnion = SetOffline;
