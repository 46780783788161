<pp-no-assets-created-yet-page
  *ngIf="assets.length === 0 && dataFetched && !keyword"
  [ppFleetId]="ppFleetId"
></pp-no-assets-created-yet-page>

<pp-no-asset-search-result-page
  *ngIf="assets.length === 0 && dataFetched && keyword"
></pp-no-asset-search-result-page>

<pp-fleet-assets-page [ppAssets]="assets"></pp-fleet-assets-page>
