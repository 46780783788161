import { TPoint } from '../../../points/points.model';
import { TColumn } from '../columns/column.model';
import { GET_WORKSPACES } from '../../../workspace/workspace.store';
import { sortText } from './sort-text';
import { sort } from './sorting';
import { sortId } from './sort-id';

export function sortSiteName(
  firstPoint: TPoint,
  secondPoint: TPoint,
  sortColumns: Partial<TColumn>[],
  index: number,
): number {
  const workspaces = GET_WORKSPACES();

  const aSiteName = workspaces[firstPoint.workspaceRef.id].siteName;
  const bSiteName = workspaces[secondPoint.workspaceRef.id].siteName;

  const result = sortText(aSiteName, bSiteName, sortColumns, index);

  if (result) {
    return result;
  }

  if (sortColumns[index + 1]) {
    return sort(firstPoint, secondPoint, sortColumns, index + 1);
  } else {
    return sortId(firstPoint, secondPoint, sortColumns, index);
  }
}
