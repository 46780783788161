import { createElement } from 'src/app/core/helpers/dom';
import { ERowType } from 'src/app/project/shared/enums/row-type.enum';
import { GET_TIMELINE } from '../timeline.ui.store';

export function createTimelineEmptyRowElement(_index: number): HTMLElement {
  return createElement('div', {
    attrs: {
      class: ((): string[] => getEmptyElementClassList(_index))(),
    },
  });
}

function getEmptyElementClassList(_index: number): string[] {
  const timeline = GET_TIMELINE();

  const classList = ['table__row table__row--empty'];
  const nextItem = timeline.items[_index + 1];

  const isLastRow =
    nextItem &&
    nextItem.type !== ERowType.POINT &&
    nextItem.type !== ERowType.HEADER &&
    nextItem.type !== ERowType.GROUP_1 &&
    nextItem.type !== ERowType.GROUP_2 &&
    nextItem.type !== ERowType.GROUP_3;

  if (isLastRow) {
    classList.push('table__row--empty--last');
  }

  return classList;
}
