import { createElement } from '@core/helpers';
import { createElementWithBackgroundColor } from './create-element-with-background-color';

export function generateDoubleGroupingEndElements(
  groupingLevel: number,
  collapsed: boolean,
): HTMLElement[] {
  const childrenList: HTMLElement[] = [];

  switch (groupingLevel) {
    case 1:
      childrenList.push(
        createElementWithBackgroundColor({
          firstClass: 'table__cell__extra table__cell__extra--firstColor_firstPosition',
          secondClass: `table__cell__extra table__cell__extra--thirdColor_zeroPosition table__cell__extra--border ${
            collapsed
              ? 'table__cell__extra--collapsed table__cell__extra--thirdColor_secondPosition--collapsed'
              : ''
          }`,
        }),
      );

      childrenList.push(
        createElement('div', {
          attrs: {
            class: 'table__cell__extra table__cell__extra--firstColor_secondPosition',
          },
        }),
      );
      break;
    case 2:
      childrenList.push(
        createElementWithBackgroundColor({
          firstClass: 'table__cell__extra table__cell__extra--thirdColor_zeroPosition',
          secondClass: `table__cell__extra table__cell__extra--fourthColor_2_0 table__cell__extra--border  ${
            collapsed ? 'table__cell__extra--collapsed' : ''
          }`,
        }),
      );

      childrenList.push(
        createElement('div', {
          attrs: {
            class: 'table__cell__extra table__cell__extra--firstColor_secondPosition',
          },
        }),
      );

      childrenList.push(
        createElement('div', {
          attrs: {
            class: 'table__cell__extra table__cell__extra--thirdColor_firstPosition',
          },
        }),
      );
      break;
  }

  return childrenList;
}
