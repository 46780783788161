import { createElement } from 'src/app/core/helpers/dom';
import { formatCurrency } from 'src/app/core/helpers/format-currency';
import { GET_CUSTOM_FIELDS } from 'src/app/project/modules/custom-fields/custom-fields.store';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { TColumn } from '../../columns/column.model';
import { createMarkedKeywordElement } from '../body-cells-search';
import { createCellElement } from '../createCellElement';

export function createCostElement(
  _index: number,
  _column: TColumn,
  _customField: TPointCustomField<string>,
): HTMLElement {
  const customFields = GET_CUSTOM_FIELDS();
  const customField = customFields[_customField.customFieldTemplateId];
  let formattedValue = '';

  if (
    _customField.value &&
    _column.customFieldIds &&
    _column.customFieldIds.includes(_customField.customFieldTemplateId.toString())
  ) {
    const costCurrencySymbol = customField.currencySymbol;
    const costValue = parseFloat(_customField.value);

    formattedValue =
      (costValue && costValue !== null) || costValue === 0
        ? formatCurrency(costValue, costCurrencySymbol, 2)
        : '';
  } else if (_customField.value) {
    const costCurrencySymbol = customField.currencySymbol;
    const costValue = parseFloat(_customField.value);
    formattedValue =
      costValue && costValue !== null ? formatCurrency(costValue, costCurrencySymbol, 2) : '';
  }

  return createCellElement({
    index: _column.index,
    width: `${_column.width}px`,
    child: createElement('div', {
      attrs: {
        class: 'customSiteTable__text customSiteTable__text--cost',
      },
      children: [createMarkedKeywordElement(formattedValue ? formattedValue : '')],
    }),
  });
}
