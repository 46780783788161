import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TRichTextValue } from '../../../../components/input/rich-text/rich-text.model';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { TCustomField } from '../../custom-fields.model';
import {
  TBulkCustomFields,
  TBulkCustomFieldsExtended,
  TBulkMentions,
} from '../bulk-custom-fields.model';

@Component({
  selector: 'pp-bulk-field-rich-text',
  templateUrl: './bulk-field-rich-text.component.html',
  styleUrls: ['../bulk-custom-fields-modal.component.scss'],
})
export class BulkFieldRichTextComponent {
  @Input() ppCustomField: TCustomField;
  @Input() ppCustomFields: TBulkCustomFields<TRichTextValue>;
  @Input() ppFieldId: string;
  @Input() ppCustomFieldsExtended: TBulkCustomFieldsExtended<string>;
  @Input() ppWorkspaceId: string;
  @Input() ppMentions: TBulkMentions;

  @Output() ppCustomFieldsExtendedChange = new EventEmitter<TBulkCustomFieldsExtended>();
  @Output() ppCustomFieldsChange = new EventEmitter<TBulkCustomFields>();
  @Output() ppMentionsChange = new EventEmitter<TBulkMentions>();

  EIconPath = EIconPath;

  constructor() {}

  updateRichText(value: TRichTextValue, field: TCustomField): void {
    this.ppCustomFields[field.id] = value;

    this.ppCustomFieldsExtended[field.id] = {
      value: this.ppCustomFields[field.id].richTextComponent,
      plainText: this.ppCustomFields[field.id].plainText,
      label: field.label,
      type: field.type,
    };

    this.ppMentions[field.id] = value.mentions;

    this.ppCustomFieldsChange.emit(this.ppCustomFields);
    this.ppCustomFieldsExtendedChange.emit(this.ppCustomFieldsExtended);
    this.ppMentionsChange.emit(this.ppMentions);
  }
}
