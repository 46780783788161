import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { CurrenciesApiProviderService } from 'src/app/project/data-providers/currencies-api-provider/currencies-api-provider.service';
import { TCurrencyData } from './currencies.consts';

@Injectable({
  providedIn: 'root',
})
export class CurrenciesService {
  private currencies: TCurrencyData[] = [];

  constructor(private currenciesApiProviderService: CurrenciesApiProviderService) {}

  fetchCurrenciesList(): Observable<TCurrencyData[]> {
    if (this.currencies.length) {
      return of(this.currencies);
    }

    return this.currenciesApiProviderService.getCurrenciesList().pipe(
      tap((currencies) => {
        this.setCurrencyList(currencies);
      }),
    );
  }

  getCurrencies(): TCurrencyData[] {
    return this.currencies;
  }

  getCurrencyByCode(currencyCode: string): TCurrencyData {
    return this.currencies.find((currency) => currency.currencyCode === currencyCode);
  }

  private setCurrencyList(currencies: TCurrencyData[]): void {
    this.currencies = currencies;
  }
}
