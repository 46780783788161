import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-advanced-filter-text-input',
  templateUrl: './advanced-filter-text-input.component.html',
  styleUrls: ['./advanced-filter-text-input.component.scss'],
})
export class AdvancedFilterTextInputComponent {
  @Input() ppType: 'text' | 'number' = 'text';
  @Input() ppValue: string;
  @Output() ppValueChange = new EventEmitter<string>();

  focused = false;
  EIconPath = EIconPath;

  onChange(): void {
    this.ppValueChange.emit(this.ppValue);
  }

  onFocus(focused: boolean): void {
    this.focused = focused;
  }

  clearInput(): void {
    this.ppValue = '';
    this.onChange();
  }
}
