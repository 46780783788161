import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';

import { preferencesReducer } from './preferences.reducer';

import { PreferencesService } from './preferences-service/preferences.service';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('preferences', preferencesReducer), PipesModule],
  providers: [PreferencesService],
})
export class PreferencesModule {}
