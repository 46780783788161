import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { of, Subject } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';

import { PromptService } from '../../../components/prompt/prompt.service';
import { WhiteLabelService } from '../../white-label/white-label.service';
import { PasswordUpdateService } from './password-update.service';

import { TPasswordResetResponse } from '@project/view-models';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { routeToUrl } from 'src/app/core/helpers/route-to-url';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { getErrorMessage } from 'src/app/project/helpers/database/get-error-message';
import { EAuthRoute } from 'src/app/project/shared/constants/auth.constants';
import {
  TResetPasswordRequest,
  TResetUserPasswordResponse,
} from 'src/app/project/view-models/reset-user-password-response.model';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { LoginService } from '../login.service';

@Component({
  selector: 'pp-password-update',
  templateUrl: './password-update.component.html',
  styleUrls: ['./password-update.component.scss'],
})
export class PasswordUpdateComponent implements OnInit, OnDestroy {
  passwordResetUser: TPasswordResetResponse;

  isProcessing = false;
  EIconPath = EIconPath;
  showingCode = false;
  errorMessage: string;
  phoneNumber: string;
  private resetId: string;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private passwordUpdateService: PasswordUpdateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private promptService: PromptService,
    private whiteLabelService: WhiteLabelService,
    private translationPipe: TranslationPipe,
    private loginService: LoginService,
  ) {}

  ngOnInit(): void {
    this.subscribeToPathChange();
    this.whiteLabelService.setDefaultStyle();

    this.getPasswordResetUser();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  updatePassword(code: string = null): void {
    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;

    const body: TResetPasswordRequest = this.getResetPasswordBody(code);

    this.passwordUpdateService
      .resetPassword(this.resetId, body)
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          this.handleResetPasswordResponse(response);
        }),
        catchError((error) => {
          this.handlePasswordResetError(error);

          return of();
        }),
        finalize(() => {
          this.isProcessing = false;
        }),
      )
      .subscribe();
  }

  goToPasswordChange(): void {
    this.showingCode = false;
  }

  resendCode(): void {
    const body: TResetPasswordRequest = this.getResetPasswordBody(null);

    this.passwordUpdateService
      .resetPassword(this.resetId, body)
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          const prompt = this.translationPipe.transform('2fa_resend_prompt');

          this.promptService.showSuccess(prompt);
        }),
        catchError((error) => {
          this.handlePasswordResetError(error);

          return of();
        }),
        finalize(() => {
          this.isProcessing = false;
        }),
      )
      .subscribe();
  }

  private handlePasswordResetError(error: any): void {
    if (error.status === EStatusCode.FORBIDDEN) {
      getErrorMessage(error).then((phoneNumber) => {
        this.phoneNumber = phoneNumber;
      });

      this.showingCode = true;
    } else {
      this.showErrorPrompt(error);
    }
  }

  private handleResetPasswordResponse(response: TResetUserPasswordResponse) {
    this.loginService.saveToken(response.authToken, response.user.email, false);

    this.redirectAfterReset(response);
  }

  private redirectAfterReset(response: TResetUserPasswordResponse) {
    const activeWorkspaceRef = response.user.activeWorkspaceRef;

    if (activeWorkspaceRef?.id) {
      this.router.navigate(['/site', response.user.activeWorkspaceRef.id]);
    } else {
      this.router.navigate(['/']);
    }
  }

  private getResetPasswordBody(code: string): TResetPasswordRequest {
    return {
      email: this.passwordResetUser.email,
      passwordHash: this.passwordResetUser.passwordHash,
      twoFactorAuthCode: code,
    };
  }

  private subscribeToPathChange() {
    this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.resetId = params['params'].id;
    });
  }

  private showErrorPrompt(error: any) {
    let promptText = this.translationPipe.transform('prompt_error');

    if (error.status === EStatusCode.BAD_REQUEST) {
      promptText = this.translationPipe.transform('passwords_are_the_same');
    }

    this.promptService.showError(promptText);
  }

  private tryShowInvalidResetLinkError(error: any) {
    if (error.status === EStatusCode.NOT_FOUND) {
      const promptText = this.translationPipe.transform('prompt_password_link_invalid');

      this.promptService.showWarning(promptText, {
        duration: 30,
      });
    }
  }

  private getPasswordResetUser(): void {
    this.passwordUpdateService
      .getPasswordResetUser(this.resetId)
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          this.passwordResetUser = response;
        }),
        catchError((error) => {
          this.tryShowInvalidResetLinkError(error);

          this.router.navigate([routeToUrl(EAuthRoute.LOGIN)]);

          return of();
        }),
      )
      .subscribe();
  }
}
