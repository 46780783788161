import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';

import { WhiteLabelEditComponent } from './white-label-edit/white-label-edit.component';
import { WhiteLabelService } from './white-label.service';

import { whiteLabelReducer } from './white-label.reducer';
import { ButtonModule } from '../../components/button/button.module';
import { SpinnerModule } from '../../components/spinner/spinner.module';
import { UploadModule } from '../../components/input/upload/upload.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { AutonumericModule } from '../../directives/autonumeric/autonumeric.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SwitchCasesModule } from '../../directives/switch-cases/switch-cases.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { LayoutModule } from '../layout/layout.module';
import { ColorPickerModule } from '../../components/input/color-picker/color-picker.module';
import { SelectModule } from '../../components/input/select/select.module';
import { LogoUploadModule } from '../../components/input/logo-upload/logo-upload.module';
import { WhiteLabelPreviewComponent } from './white-label-edit/white-label-preview/white-label-preview.component';
import { FontSelectModule } from '../../components/input/font-select/font-select.module';
import { SecureSrcModule } from '../../directives/secure-src/secure-src.module';
import { PlaceholdersModule } from '../placeholders/placeholders.module';

const declareAndExport = [WhiteLabelEditComponent];

@NgModule({
  imports: [
    CommonModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    UploadModule,
    FormsModule,
    SpinnerModule,
    ButtonModule,
    StoreModule.forFeature('whiteLabel', whiteLabelReducer),
    PipesModule,
    LayoutModule,
    ColorPickerModule,
    SelectModule,
    LogoUploadModule,
    FontSelectModule,
    SecureSrcModule,
    PlaceholdersModule,
  ],
  declarations: [...declareAndExport, WhiteLabelPreviewComponent],
  exports: [...declareAndExport],
  providers: [WhiteLabelService],
})
export class WhiteLabelModule {}
