<input
  #daysInput
  [ppAutonumeric]="autonumericOptions"
  class="pointCF__input pointCF__input--time"
  [(ngModel)]="days"
  placeholder="d"
  inputmode="numeric"
  pattern="[0-9]*"
  (change)="updateField()"
  (keydown.enter)="updateField()"
  *ngIf="!showHoursOnly"
/>

<input
  #hoursInput
  [ppAutonumeric]="autonumericOptions"
  class="pointCF__input pointCF__input--time"
  [(ngModel)]="hours"
  placeholder="h"
  inputmode="numeric"
  pattern="[0-9]*"
  (change)="updateField()"
  (keydown.enter)="updateField()"
/>

<input
  #minutesInput
  [ppAutonumeric]="autonumericOptions"
  class="pointCF__input pointCF__input--time"
  [(ngModel)]="minutes"
  placeholder="min"
  inputmode="numeric"
  pattern="[0-9]*"
  (change)="updateField()"
  (keydown.enter)="updateField()"
/>
