<pp-bulk-changes-bar
  [ppSelectedNumber]="selectedShares.length"
  *ngIf="selectedShares.length > 0"
  (ppClose)="deselectAll()"
>
  <pp-bulk-changes-button
    ppTooltip
    [ppTitle]="
      !oneUserSelected && !oneWorkspaceSelected
        ? ('user_management_bulk_changes_edit_disabled_tooltip' | ppTranslate)
        : ''
    "
    [ppIconPath]="EIconPath.ICON_MISC_EDIT_24"
    [ppDisabled]="!oneUserSelected && !oneWorkspaceSelected"
    [ppText]="'edit' | ppTranslate"
    (ppAction)="editShares()"
  ></pp-bulk-changes-button>

  <pp-bulk-changes-button
    [ppIconPath]="EIconPath.ICON_BIN_24"
    [ppText]="'remove' | ppTranslate"
    (ppAction)="deleteShares()"
  ></pp-bulk-changes-button>
</pp-bulk-changes-bar>
