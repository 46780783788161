import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, of, tap } from 'rxjs';
import { routeToUrl } from 'src/app/core/helpers/route-to-url';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EAuthRoute } from 'src/app/project/shared/constants/auth.constants';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { UpdateService } from '../../../offline/update.service';
import { UserService } from '../../../user/user.service';
import { WorkspaceService } from '../../../workspace/workspace.service';
import { AuthService } from '../../auth.service';
import { LoginRedirectService } from '../../login-redirect.service';
import { TwoFactorAuthenticationPhoneNumberService } from '../two-factor-authentication-phone-number.service';
import { TWO_FACTOR_AUTHENTICATION_CODE_LENGTH } from '../two-factor-authentication.consts';

@Component({
  selector: 'pp-two-factor-authentication-login',
  templateUrl: './two-factor-authentication-login.component.html',
  styleUrls: ['./two-factor-authentication-login.component.scss'],
})
export class TwoFactorAuthenticationLoginComponent implements OnInit {
  EIconPath = EIconPath;
  processing: boolean;
  errorMessage: string;
  confirmationPhoneNumber: string;
  code: string = '';
  codeLength = TWO_FACTOR_AUTHENTICATION_CODE_LENGTH;

  constructor(
    private translationPipe: TranslationPipe,
    private loginRedirectService: LoginRedirectService,
    private authService: AuthService,
    private twoFactorAuthenticationPhoneNumberService: TwoFactorAuthenticationPhoneNumberService,
    private updateService: UpdateService,
    private userService: UserService,
    private workspaceService: WorkspaceService,
    private router: Router,
  ) {}

  goBack(): void {
    this.router.navigate(['/login']);
  }

  ngOnInit(): void {
    this.setConfirmationPhoneNumber();
    this.tryRedirectToLoginWhenNoConfirmationNumber();
  }

  setCode(code: string): void {
    this.code = code;
  }

  resend(): void {
    this.authService.loginWithCachedData().subscribe();
  }

  verifyCode(): void {
    this.authService
      .loginWithCachedData(this.code)
      .pipe(
        tap(() => this.handleLoginSuccess()),
        catchError(() => {
          this.errorMessage = this.translationPipe.transform('2fa_code_error');

          return of(null);
        }),
      )
      .subscribe();
  }

  private setConfirmationPhoneNumber(): void {
    this.confirmationPhoneNumber =
      this.twoFactorAuthenticationPhoneNumberService.getConfirmationPhoneNumber();
  }

  private handleLoginSuccess(): void {
    this.updateService.checkForUpdateAfterLogin();

    this.workspaceService
      .fetchWorkspaces()
      .pipe(
        tap(() => {
          this.userService
            .fetchUser()
            .pipe(
              tap(() => {
                this.loginRedirectService.redirectAfterLogin();
              }),
            )
            .subscribe();
        }),
      )
      .subscribe();
  }

  private tryRedirectToLoginWhenNoConfirmationNumber(): void {
    if (!this.confirmationPhoneNumber) {
      this.router.navigate([routeToUrl(EAuthRoute.LOGIN)]);
    }
  }
}
