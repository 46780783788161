import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EStatus } from 'src/app/project/shared/enums/status.enum';
import { TFleetStatuses } from '../../fleet.consts';

@Component({
  selector: 'pp-fleet-management-new-fleet-status-filter',
  templateUrl: './fleet-management-new-fleet-status-filter.component.html',
  styleUrls: ['./fleet-management-new-fleet-status-filter.component.scss'],
})
export class FleetManagementNewFleetStatusFilterComponent {
  @Input() ppStatuses: TFleetStatuses;
  @Output() ppStatusesChange = new EventEmitter<TFleetStatuses>();

  EIconPath = EIconPath;
  EStatus = EStatus;

  updateFilter(status: EStatus, include: boolean): void {
    this.ppStatuses[status].include = include;
    this.ppStatusesChange.emit(this.ppStatuses);
  }
}
