import { TDashletGraph } from '../../dashlet-graph.model';
import { generateDoughnutGraphOptions } from './doughnut-graph-options';

export function createCurrentStatusGraph(isMobile: boolean, isExport: boolean): TDashletGraph {
  return {
    type: 'doughnut',
    data: {
      labels: ['Open', 'In Progress', 'To Review', 'On Hold', 'Completed', 'Canceled'],
      datasets: [
        {
          data: [],
          backgroundColor: ['#3170a7', '#6BC8F9', '#f3db12', '#ff9801', '#65b92e', '#838B99'],
        },
      ],
    },
    options: generateDoughnutGraphOptions(isMobile, isExport),
  };
}
