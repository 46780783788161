export enum EExportTypeName {
  ZIP = 'zip',
  SIMPLE_LIST = 'points_simplelist',
  DETAILED_LIST = 'points_detaillist',
  CSV = 'Csv_export',
  XLSX = 'Xlsx_export',
  WORD = 'word',
  PDF = 'pdf',
  SITE_PLAN = 'site_plan',
  WORD_SIMPLE = 'wordSimple',
  ZIP_WORD = 'zip_word',
  INDIVIDUAL_WORD = 'individual_word',
}
