import { TGuid } from '@core/helpers';

export const API_reminders = (): string => 'api/v1/reminders';
export const API_reminders_reminder = (reminderId: TGuid): string =>
  `api/v1/reminders/${reminderId}`;
export const API_reminders_current_day = (): string => '/api/v1/reminders/number/current-day';

export const API_reminders_from_to = (from?: number, to?: number): string => {
  let url = '/api/v1/reminders';

  if (from || to) {
    url += `?from=${from}&to=%${to}`;
  }

  return url;
};
