var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
/**
 * @module ol/renderer/Map
 */
import Disposable from '../Disposable.js';
import { TRUE } from '../functions.js';
import { abstract } from '../util.js';
import { compose as composeTransform, makeInverse } from '../transform.js';
import { getWidth } from '../extent.js';
import { shared as iconImageCache } from '../style/IconImageCache.js';
import { inView } from '../layer/Layer.js';
import { wrapX } from '../coordinate.js';
/**
 * @typedef HitMatch
 * @property {import("../Feature.js").FeatureLike} feature Feature.
 * @property {import("../layer/Layer.js").default} layer Layer.
 * @property {import("../geom/SimpleGeometry.js").default} geometry Geometry.
 * @property {number} distanceSq Squared distance.
 * @property {import("./vector.js").FeatureCallback<T>} callback Callback.
 * @template T
 */
/**
 * @abstract
 */
var MapRenderer = /** @class */function (_super) {
  __extends(MapRenderer, _super);
  /**
   * @param {import("../PluggableMap.js").default} map Map.
   */
  function MapRenderer(map) {
    var _this = _super.call(this) || this;
    /**
     * @private
     * @type {import("../PluggableMap.js").default}
     */
    _this.map_ = map;
    return _this;
  }
  /**
   * @abstract
   * @param {import("../render/EventType.js").default} type Event type.
   * @param {import("../PluggableMap.js").FrameState} frameState Frame state.
   */
  MapRenderer.prototype.dispatchRenderEvent = function (type, frameState) {
    abstract();
  };
  /**
   * @param {import("../PluggableMap.js").FrameState} frameState FrameState.
   * @protected
   */
  MapRenderer.prototype.calculateMatrices2D = function (frameState) {
    var viewState = frameState.viewState;
    var coordinateToPixelTransform = frameState.coordinateToPixelTransform;
    var pixelToCoordinateTransform = frameState.pixelToCoordinateTransform;
    composeTransform(coordinateToPixelTransform, frameState.size[0] / 2, frameState.size[1] / 2, 1 / viewState.resolution, -1 / viewState.resolution, -viewState.rotation, -viewState.center[0], -viewState.center[1]);
    makeInverse(pixelToCoordinateTransform, coordinateToPixelTransform);
  };
  /**
   * @param {import("../coordinate.js").Coordinate} coordinate Coordinate.
   * @param {import("../PluggableMap.js").FrameState} frameState FrameState.
   * @param {number} hitTolerance Hit tolerance in pixels.
   * @param {boolean} checkWrapped Check for wrapped geometries.
   * @param {import("./vector.js").FeatureCallback<T>} callback Feature callback.
   * @param {S} thisArg Value to use as `this` when executing `callback`.
   * @param {function(this: U, import("../layer/Layer.js").default): boolean} layerFilter Layer filter
   *     function, only layers which are visible and for which this function
   *     returns `true` will be tested for features.  By default, all visible
   *     layers will be tested.
   * @param {U} thisArg2 Value to use as `this` when executing `layerFilter`.
   * @return {T|undefined} Callback result.
   * @template S,T,U
   */
  MapRenderer.prototype.forEachFeatureAtCoordinate = function (coordinate, frameState, hitTolerance, checkWrapped, callback, thisArg, layerFilter, thisArg2) {
    var result;
    var viewState = frameState.viewState;
    /**
     * @param {boolean} managed Managed layer.
     * @param {import("../Feature.js").FeatureLike} feature Feature.
     * @param {import("../layer/Layer.js").default} layer Layer.
     * @param {import("../geom/Geometry.js").default} geometry Geometry.
     * @return {T|undefined} Callback result.
     */
    function forEachFeatureAtCoordinate(managed, feature, layer, geometry) {
      return callback.call(thisArg, feature, managed ? layer : null, geometry);
    }
    var projection = viewState.projection;
    var translatedCoordinate = wrapX(coordinate.slice(), projection);
    var offsets = [[0, 0]];
    if (projection.canWrapX() && checkWrapped) {
      var projectionExtent = projection.getExtent();
      var worldWidth = getWidth(projectionExtent);
      offsets.push([-worldWidth, 0], [worldWidth, 0]);
    }
    var layerStates = frameState.layerStatesArray;
    var numLayers = layerStates.length;
    var matches = /** @type {Array<HitMatch<T>>} */[];
    var tmpCoord = [];
    for (var i = 0; i < offsets.length; i++) {
      for (var j = numLayers - 1; j >= 0; --j) {
        var layerState = layerStates[j];
        var layer = layerState.layer;
        if (layer.hasRenderer() && inView(layerState, viewState) && layerFilter.call(thisArg2, layer)) {
          var layerRenderer = layer.getRenderer();
          var source = layer.getSource();
          if (layerRenderer && source) {
            var coordinates = source.getWrapX() ? translatedCoordinate : coordinate;
            var callback_1 = forEachFeatureAtCoordinate.bind(null, layerState.managed);
            tmpCoord[0] = coordinates[0] + offsets[i][0];
            tmpCoord[1] = coordinates[1] + offsets[i][1];
            result = layerRenderer.forEachFeatureAtCoordinate(tmpCoord, frameState, hitTolerance, callback_1, matches);
          }
          if (result) {
            return result;
          }
        }
      }
    }
    if (matches.length === 0) {
      return undefined;
    }
    var order = 1 / matches.length;
    matches.forEach(function (m, i) {
      return m.distanceSq += i * order;
    });
    matches.sort(function (a, b) {
      return a.distanceSq - b.distanceSq;
    });
    matches.some(function (m) {
      return result = m.callback(m.feature, m.layer, m.geometry);
    });
    return result;
  };
  /**
   * @abstract
   * @param {import("../pixel.js").Pixel} pixel Pixel.
   * @param {import("../PluggableMap.js").FrameState} frameState FrameState.
   * @param {number} hitTolerance Hit tolerance in pixels.
   * @param {function(import("../layer/Layer.js").default<import("../source/Source").default>, (Uint8ClampedArray|Uint8Array)): T} callback Layer
   *     callback.
   * @param {function(import("../layer/Layer.js").default<import("../source/Source").default>): boolean} layerFilter Layer filter
   *     function, only layers which are visible and for which this function
   *     returns `true` will be tested for features.  By default, all visible
   *     layers will be tested.
   * @return {T|undefined} Callback result.
   * @template T
   */
  MapRenderer.prototype.forEachLayerAtPixel = function (pixel, frameState, hitTolerance, callback, layerFilter) {
    return abstract();
  };
  /**
   * @param {import("../coordinate.js").Coordinate} coordinate Coordinate.
   * @param {import("../PluggableMap.js").FrameState} frameState FrameState.
   * @param {number} hitTolerance Hit tolerance in pixels.
   * @param {boolean} checkWrapped Check for wrapped geometries.
   * @param {function(this: U, import("../layer/Layer.js").default): boolean} layerFilter Layer filter
   *     function, only layers which are visible and for which this function
   *     returns `true` will be tested for features.  By default, all visible
   *     layers will be tested.
   * @param {U} thisArg Value to use as `this` when executing `layerFilter`.
   * @return {boolean} Is there a feature at the given coordinate?
   * @template U
   */
  MapRenderer.prototype.hasFeatureAtCoordinate = function (coordinate, frameState, hitTolerance, checkWrapped, layerFilter, thisArg) {
    var hasFeature = this.forEachFeatureAtCoordinate(coordinate, frameState, hitTolerance, checkWrapped, TRUE, this, layerFilter, thisArg);
    return hasFeature !== undefined;
  };
  /**
   * @return {import("../PluggableMap.js").default} Map.
   */
  MapRenderer.prototype.getMap = function () {
    return this.map_;
  };
  /**
   * Render.
   * @abstract
   * @param {?import("../PluggableMap.js").FrameState} frameState Frame state.
   */
  MapRenderer.prototype.renderFrame = function (frameState) {
    abstract();
  };
  /**
   * @param {import("../PluggableMap.js").FrameState} frameState Frame state.
   * @protected
   */
  MapRenderer.prototype.scheduleExpireIconCache = function (frameState) {
    if (iconImageCache.canExpireCache()) {
      frameState.postRenderFunctions.push(expireIconCache);
    }
  };
  return MapRenderer;
}(Disposable);
/**
 * @param {import("../PluggableMap.js").default} map Map.
 * @param {import("../PluggableMap.js").FrameState} frameState Frame state.
 */
function expireIconCache(map, frameState) {
  iconImageCache.expire();
}
export default MapRenderer;
