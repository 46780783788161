import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TAccount } from 'src/app/project/modules/account/account.model';
import { TDashlet, TDashletSiteRange } from 'src/app/project/modules/preferences/preferences.model';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { MAX_STACKED_STATUS_WORKSPACES_LENGTH } from '../../../utils/dashboard-consts';

@Component({
  selector: 'pp-dashlet-timeline-account-multiple',
  templateUrl: './dashlet-timeline-account-multiple.component.html',
  styleUrl: './dashlet-timeline-account-multiple.component.scss',
})
export class DashletTimelineAccountMultipleComponent implements OnChanges {
  @Input() ppAccount: TAccount;
  @Input() ppSiteRange: TDashletSiteRange;
  @Input() ppWorkspaces: TWorkspacesById;
  @Input() ppBlockedTimelines: string[];
  @Input() ppLimitedWorkspaces: boolean;
  @Input() ppDefaultWorkspaces: string[];
  @Input() ppDashlet: TDashlet;
  @Output() ppUpdateSiteRange = new EventEmitter<TDashletSiteRange>();

  workspaceList: {
    workspaceId: string;
    blocked: boolean;
  }[];

  MAX_STACKED_STATUS_WORKSPACES_LENGTH = MAX_STACKED_STATUS_WORKSPACES_LENGTH;

  ngOnChanges() {
    const filteredWorkspaces = this.ppDefaultWorkspaces
      ? this.ppAccount.workspaces.filter((workspaceId) =>
          this.ppDefaultWorkspaces.includes(workspaceId),
        )
      : this.ppAccount.workspaces;

    this.workspaceList = filteredWorkspaces.map((workspaceId) => ({
      workspaceId,
      blocked: this.ppBlockedTimelines.includes(workspaceId),
    }));
  }

  toggleWorkspace(workspaceId: string): void {
    const alreadySelected = this.ppSiteRange.workspaceIds.includes(workspaceId);

    if (
      this.ppLimitedWorkspaces &&
      !alreadySelected &&
      this.ppSiteRange.workspaceIds.length >= MAX_STACKED_STATUS_WORKSPACES_LENGTH
    ) {
      return;
    }

    const workspaceIds = alreadySelected
      ? this.ppSiteRange.workspaceIds.filter((id) => id !== workspaceId)
      : [...this.ppSiteRange.workspaceIds, workspaceId];

    this.ppUpdateSiteRange.emit({
      accountId: null,
      workspaceIds,
    });
  }

  trackByFunction(
    index: number,
    item: {
      workspaceId: string;
      blocked: boolean;
    },
  ): string {
    return item.workspaceId;
  }
}
