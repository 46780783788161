export enum EFileExtension {
  JPG = '.jpg',
  JPEG = '.jpeg',
  PNG = '.png',
  GIF = '.gif',
  BMP = '.bmp',
  WEBP = '.webp',
  MP4 = '.mp4',
  MPEG = '.mpeg',
  OGG = '.ogv',
  WEBM = '.webm',
  GP3 = '.3gp',
  GP32 = '.3g2',
  WAVE = '.wav',
  WAV = '.wav',
}
