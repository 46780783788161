import { TAllCustomFields, TCustomFields } from './custom-fields.model';

let customFields: TCustomFields = {};

// Get

export const GET_CUSTOM_FIELDS = (): TCustomFields => customFields;

// Set

export const SET_CUSTOM_FIELDS = (_customFields: TAllCustomFields): void => {
  const customFieldsList = {};

  Object.keys(_customFields).forEach((workspaceId) => {
    Object.keys(_customFields[workspaceId]).forEach((customFieldId) => {
      customFieldsList[customFieldId] = _customFields[workspaceId][customFieldId];

      customFieldsList[customFieldId].workspaceId = workspaceId;
    });
  });

  customFields = customFieldsList;
};
