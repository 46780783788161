import { TAnyFunction } from '@core/helpers';
import Quill from 'quill';

export function fixDragAndDrop() {
  // https://github.com/quilljs/quill/issues/2040#issuecomment-441413485
  const Block = Quill.import('blots/block');

  class Div extends Block {
    static tagName: string;
    static blotName: string;
    static allowedChildren: TAnyFunction[];
  }

  Div.tagName = 'div';
  Div.blotName = 'div';
  Div.allowedChildren = Block.allowedChildren;
  Div.allowedChildren.push(Block);

  Quill.register(Div);
  // end of workaround
}
