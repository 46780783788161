import { TPoint } from './points.model';

let points: TPoint[] = [];
let pointsById: {
  [_id: string]: TPoint;
} = {};

// Get

export const GET_POINTS = (): TPoint[] => points;

export const GET_POINTS_BY_ID = (): {
  [_id: string]: TPoint;
} => pointsById;

export const GET_POINT = (_pointId: string): TPoint => pointsById[_pointId];

// Set

export const SET_POINTS = (_points: TPoint[]): void => {
  points = _points;

  points.forEach((_point) => {
    pointsById[_point._id] = _point;
  });
};

// Modify

// ---

// Clear

export const CLEAR_POINTS = (): void => {
  points.length = 0;
  pointsById = {};
};
