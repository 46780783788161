<tr class="accountSettingsSiteRow" [class.accountSettingsSiteRow--disabled]="ppSite.archived">
  <td class="settingsTable__cell" [class.settingsTable__cell--withCheckbox]="ppCanSeeArchived">
    <div class="settingsTable__siteWrapper">
      <pp-checkbox
        *ngIf="ppCanSeeArchived"
        ppType="solid"
        ppSize="medium"
        [class.settingsTable__checkbox--archived]="ppSite.archived"
        [class.settingsTable__checkbox]="!ppSite.archived"
        [ppChecked]="isSelected"
        (ppOnValueChange)="toggleSelected()"
      >
        <a
          ppTooltip
          [ppTitle]="ppSite.siteName"
          [ppTrimTooltip]="true"
          class="settingsTable__siteLink"
          [class.settingsTable__siteLink--disabled]="ppSite.archived"
          (click)="navigateToSite()"
        >
          {{ ppSite.siteName }}
        </a>
      </pp-checkbox>

      <a
        ppTooltip
        [ppTitle]="ppSite.siteName"
        [ppTrimTooltip]="true"
        *ngIf="!ppCanSeeArchived"
        class="settingsTable__siteLink"
        (click)="navigateToSite()"
      >
        {{ ppSite.siteName }}
      </a>

      <pp-archived-chip *ngIf="ppSite.archived"></pp-archived-chip>
    </div>
  </td>

  <td class="settingsTable__cell">
    {{ numberOfUsers }}
    {{ numberOfUsers !== 1 ? 'users' : 'user' }}
  </td>

  <td class="settingsTable__cell">
    {{ pointCount }}
    {{ pointCount !== 1 ? 'points' : 'point' }}
  </td>

  <td class="settingsTable__cell settingsTable__column--size">
    {{ totalSize }}
  </td>
</tr>
