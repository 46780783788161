import { TSelectOption } from 'src/app/project/components/input/select/select.model';
import { translate } from 'src/app/project/features/translate/translate';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';

export function getAdvancedDateOptions(): TSelectOption[] {
  return [
    {
      value: EAdvancedFilterOptions.IS,
      label: translate('is') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_BEFORE,
      label: translate('is_before') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_AFTER,
      label: translate('is_after') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_BETWEEN,
      label: translate('is_between') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_EMPTY,
      label: translate('is_empty') + '.',
    },
    {
      value: EAdvancedFilterOptions.IS_NOT_EMPTY,
      label: translate('is_not_empty') + '.',
    },
  ];
}

export function getAdvancedDateCreatedOptions(): TSelectOption[] {
  return [
    {
      value: EAdvancedFilterOptions.IS,
      label: translate('is') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_BEFORE,
      label: translate('is_before') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_AFTER,
      label: translate('is_after') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_BETWEEN,
      label: translate('is_between') + '...',
    },
  ];
}

export function getAdvancedDateUpdatedOptions(): TSelectOption[] {
  return getAdvancedDateCreatedOptions();
}
