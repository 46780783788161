import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClearFilterService } from '../../clear-filter-service/clear-filter.service';
import { TFilters } from '../../site-filter.model';

@Component({
  selector: 'pp-filter-field-created-by',
  templateUrl: './filter-field-created-by.component.html',
  styleUrls: ['../../site-filter-dropdown/site-filter-dropdown.component.scss'],
})
export class FilterFieldCreatedByComponent {
  @Input() ppFilters: TFilters;
  @Output() ppApply = new EventEmitter();

  constructor(private clearFilterService: ClearFilterService) {}

  clearCreatedBy(): void {
    this.ppFilters.createdBy.value = [];
    this.ppFilters.createdBy.appliedValue = [];

    this.clearFilterService.clearCreatedBy();

    this.applyFilters();
  }

  applyFilters(): void {
    this.ppApply.emit();
  }
}
