export const API_fleet_create = (accountId: string): string =>
  `api/v1/fleet?accountId=${accountId}`;

export const API_fleet = () => `api/v1/fleet`;

export const API_fleet_by_id = (fleetId: string): string => `api/v1/fleet/${fleetId}`;

export const API_fleet_shares = (fleetId: string): string => `api/v1/fleet/${fleetId}/shares`;

export const API_fleet_access = (): string => `/api/v1/fleet/access`;
