import { Component, Input } from '@angular/core';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { FleetManagementRoutesService } from '../../fleet-management-routes.service';
import { FleetService } from '../../fleet-service/fleet.service';
import { EFleetShareOption } from '../../fleet-sharing/fleet-sharing.consts';

@Component({
  selector: 'pp-no-assets-created-yet-page',
  templateUrl: './no-assets-created-yet-page.component.html',
  styleUrls: ['./no-assets-created-yet-page.component.scss'],
})
export class NoAssetsCreatedYetPageComponent {
  @Input() ppFleetId: string;

  EIconPath = EIconPath;
  canEdit: boolean;

  constructor(
    private fleetManagementRoutesService: FleetManagementRoutesService,
    private fleetService: FleetService,
  ) {}

  ngOnChanges(): void {
    const fleet = this.fleetService.getFleet(this.ppFleetId);

    this.canEdit = fleet?.fleetShareOption === EFleetShareOption.EDIT;
  }

  addAsset(): void {
    this.fleetManagementRoutesService.goToAddAssetPage(this.ppFleetId);
  }
}
