import { cloneDeep } from 'lodash';
import { AddPoint } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';

export function addPoint(oldState: TPointsByWorkspace, action: AddPoint): TPointsByWorkspace {
  const state = cloneDeep(oldState);

  state[action.payload.workspaceId].entities = [
    ...state[action.payload.workspaceId].entities,
    action.payload.point,
  ];

  return state;
}
