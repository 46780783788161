import { UrlSegment } from '@angular/router';
import { cloneDeep } from 'lodash';
import { EWorkspaces } from '../../modules/workspace/workspaces.enum';

export function simplifyRoute(urlSegments: UrlSegment[]): string {
  let finalUrl = '';

  if (!urlSegments) {
    return finalUrl;
  }

  const segments = cloneDeep(urlSegments);

  segments.forEach((segment, index) => {
    const nextSegment = segments[index + 1];

    if (segment.path === 'point' && nextSegment) {
      nextSegment.path = 'POINT';
    }

    if (segment.path === 'fleet' && nextSegment) {
      nextSegment.path = 'FLEET';
    }

    if (segment.path === 'site' && nextSegment) {
      const isSiteWithCustomId =
        nextSegment.path === EWorkspaces.OVERVIEW ||
        nextSegment.path === EWorkspaces.TIMELINE ||
        nextSegment.path === EWorkspaces.REMINDERS;

      if (!isSiteWithCustomId) {
        nextSegment.path = 'SITE';
      }
    }

    if (segment.path === 'account' && nextSegment) {
      nextSegment.path = 'ACCOUNT';
    }

    finalUrl += `/${segment.path}`;
  });

  return finalUrl;
}
