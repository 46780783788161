import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TTranslationKey } from 'src/app/project/features/translate/types';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { DropdownService } from '../../dropdown/dropdown-service/dropdown.service';
import { TDropdown } from '../../dropdown/dropdown.consts';
import { LogoUploadDropdownComponent } from './logo-upload-dropdown/logo-upload-dropdown.component';

@Component({
  selector: 'pp-logo-upload',
  templateUrl: './logo-upload.component.html',
  styleUrls: ['./logo-upload.component.scss'],
})
export class LogoUploadComponent implements OnInit, OnDestroy {
  @Input() ppLogoLabelKey: TTranslationKey;
  @Input() ppClear: Observable<void>;

  @Input() ppLogoUrl: string;
  @Output() ppLogoUrlChange = new EventEmitter<string>();

  @Output() ppUpdateLogo = new EventEmitter<File[]>();

  clearSubscription: Subscription;
  EIconPath = EIconPath;

  uploadedImage: string | ArrayBuffer;
  private dropdown: TDropdown = this.dropdownService.getDropdown();

  constructor(private dropdownService: DropdownService) {}

  ngOnInit() {
    this.clearSubscription = this.ppClear.subscribe(() => {
      this.uploadedImage = null;
    });
  }

  ngOnDestroy(): void {
    this.clearSubscription.unsubscribe();
  }

  setLogo(images: File[]): void {
    if (images) {
      const reader = new FileReader();

      this.ppUpdateLogo.emit(images);

      reader.onload = (): void => {
        this.uploadedImage = reader.result;
      };

      reader.readAsDataURL(images[0]);
    } else {
      this.uploadedImage = null;
      this.ppLogoUrl = '';
      this.ppLogoUrlChange.emit('');
      this.ppUpdateLogo.emit(null);
    }
  }

  toggleLogoPreviewDropdown(buttonId: string): void {
    if (this.dropdown.visible && this.dropdown.buttonId === buttonId) {
      this.dropdownService.hideDropdown();
    } else {
      this.dropdownService.showDropdown(buttonId, LogoUploadDropdownComponent, {
        callback: (files: File[]) => this.setLogo(files),
      });
    }
  }
}
