<div
  class="notification"
  *ngIf="notification"
  [ngClass]="notification.markedAsRead ? 'notification--read' : 'notification--unread'"
  [class.dataFetching]="!ppDataFetched?.dataFetched"
  [class.no-avatar]="!user"
  (click)="openPoint($event)"
>
  <div>
    <img
      ppImage
      *ngIf="user"
      ppTooltip
      [ppTitle]="user ? user.userName : ('deleted_user' | ppTranslate)"
      class="notification__avatar"
      [src]="user ? user?.avatarPublicUrl : EIconPath.MISC_USER"
    />
  </div>

  <div class="notification__content">
    <div class="notification__header">
      <h6
        ppTooltip
        [ppTitle]="user?.userName"
        [ppTrimTooltip]="true"
        class="notification__userName"
      >
        {{ user?.userName ? user.userName : ('deleted_user' | ppTranslate) }}
      </h6>

      <span ppTooltip [ppTitle]="timeStampHover" class="notification__date">
        {{ timeStamp }}
      </span>
    </div>

    <div class="notification__description">
      <ng-container
        *ngIf="
          !isCustomField ||
          ENotificationType.POINT_MENTION_CUSTOM_FIELD === notification.pushNotificationType
        "
      >
        <pp-notification-legacy
          [ppNotification]="notification"
          *ngIf="notificationVersion === 1"
        ></pp-notification-legacy>

        <ng-container *ngIf="notificationVersion === 2">
          <ng-container [ngSwitch]="notification.pushNotificationType">
            <pp-notification-flag
              *ngSwitchCase="ENotificationType.POINT_EDITION_FLAGGED"
              [ppNotification]="notification"
            >
            </pp-notification-flag>

            <pp-notification-description
              *ngSwitchCase="ENotificationType.POINT_EDITION_DESCRIPTION"
              [ppNotification]="notification"
            >
            </pp-notification-description>

            <pp-notification-comment
              *ngSwitchCase="ENotificationType.POINT_EDITION_COMMENT"
              [ppNotificationReason]="notification.notificationReason"
            >
            </pp-notification-comment>

            <pp-notification-title
              *ngSwitchCase="ENotificationType.POINT_EDITION_TITLE"
              [ppNotification]="notification"
            >
            </pp-notification-title>

            <pp-notification-status
              *ngSwitchCase="ENotificationType.POINT_EDITION_STATUS"
              [ppNotification]="notification"
            >
            </pp-notification-status>

            <pp-notification-priority
              *ngSwitchCase="ENotificationType.POINT_EDITION_PRIORITY"
              [ppNotification]="notification"
            >
            </pp-notification-priority>

            <pp-notification-tags
              *ngSwitchCase="ENotificationType.POINT_EDITION_TAGS"
              [ppNotification]="notification"
            ></pp-notification-tags>

            <pp-notification-assignee
              *ngSwitchCase="ENotificationType.POINT_EDITION_ASSIGNEE"
              [ppNotification]="notification"
            ></pp-notification-assignee>

            <pp-notification-location
              *ngIf="
                notification.pushNotificationType === ENotificationType.POINT_EDITION_PIN_MOVE ||
                notification.pushNotificationType ===
                  ENotificationType.POINT_EDITION_ADDITIONAL_PINS_MOVE ||
                notification.pushNotificationType === ENotificationType.POINT_EDITION_POLYGONS_MOVE
              "
              [ppNotificationReason]="notification.notificationReason"
            >
            </pp-notification-location>

            <pp-notification-attachment
              *ngIf="
                notification.pushNotificationType === ENotificationType.POINT_EDITION_ATTACHMENT ||
                notification.pushNotificationType === ENotificationType.POINT_EDITION_DOCUMENTS
              "
              [ppNotification]="notification"
            >
            </pp-notification-attachment>

            <pp-notification-images
              *ngSwitchCase="ENotificationType.POINT_EDITION_IMAGES"
              [ppNotification]="notification"
            >
            </pp-notification-images>

            <pp-notification-images360
              *ngSwitchCase="ENotificationType.POINT_EDITION_IMAGES360"
              [ppNotification]="notification"
            >
            </pp-notification-images360>

            <pp-notification-videos
              *ngSwitchCase="ENotificationType.POINT_EDITION_VIDEOS"
              [ppNotification]="notification"
            >
            </pp-notification-videos>

            <pp-notification-comment-mention
              *ngSwitchCase="ENotificationType.POINT_MENTION_COMMENT"
            ></pp-notification-comment-mention>

            <pp-notification-description-mention
              *ngSwitchCase="ENotificationType.POINT_MENTION_DESCRIPTION"
            ></pp-notification-description-mention>

            <pp-notification-new-point-mention
              *ngSwitchCase="ENotificationType.POINT_MENTION_NEW_POINT"
            ></pp-notification-new-point-mention>

            <pp-notification-new-point-assignee
              *ngSwitchCase="ENotificationType.POINT_CREATION_ASSIGNEE"
            ></pp-notification-new-point-assignee>

            <pp-notification-comment-reaction
              [reactionType]="notification.reactionDetails.reactionType"
              *ngSwitchCase="ENotificationType.REACTION_COMMENT"
            >
            </pp-notification-comment-reaction>

            <pp-notification-custom-field-mention
              [ppLabel]="notification.changeBody.label"
              *ngSwitchCase="ENotificationType.POINT_MENTION_CUSTOM_FIELD"
            ></pp-notification-custom-field-mention>
          </ng-container>
        </ng-container>
      </ng-container>

      <pp-notification-custom-field
        *ngIf="
          isCustomField &&
          notificationVersion === 2 &&
          notification.pushNotificationType !== ENotificationType.POINT_MENTION_CUSTOM_FIELD
        "
        [ppNotification]="notification"
      ></pp-notification-custom-field>

      <pp-notification-rich-text
        [ppNotification]="notification"
        [ppReaction]="reaction"
        (ppRespond)="respond($event)"
        [ppCommentSent]="commentSent"
        [ppRespondingToComment]="respondingToComment"
        *ngIf="
          (notification.pushNotificationType === ENotificationType.POINT_EDITION_COMMENT &&
            notification.changeBody) ||
          notification.changeBody?.cfFieldType === ECustomFieldType.RICHTEXT ||
          notification.changeBody?.cfFieldType === ECustomFieldType.TEXT ||
          (notificationVersion === 2 &&
            (notification.pushNotificationType === ENotificationType.POINT_EDITION_DESCRIPTION ||
              notification.pushNotificationType === ENotificationType.POINT_MENTION_COMMENT ||
              notification.pushNotificationType === ENotificationType.POINT_MENTION_DESCRIPTION))
        "
      ></pp-notification-rich-text>
    </div>

    <pp-notification-footer
      (ppOpenSite)="openSite($event)"
      [ppNotification]="notification"
      (ppOpenPoint)="openPoint(null)"
      [ppRespondingToComment]="respondingToComment"
      [ppCommentSent]="commentSent"
      [ppReaction]="reaction"
      [ppAccountName]="ppAccountName"
    ></pp-notification-footer>

    <pp-notification-response
      *ngIf="respondingToComment"
      [ppNewComment]="newComment"
      [(ppRespondingToComment)]="respondingToComment"
      [ppCommentSent]="commentSent"
      [ppNotificationId]="ppNotificationId"
      [ppNotification]="notification"
      (ppComment)="comment($event)"
    ></pp-notification-response>
  </div>
</div>
