<div class="pointCF__field">
  <label
    ppTooltip
    class="pointCF__label"
    ppTitle="{{ ppCustomField?.label }}"
    [ppTrimTooltip]="true"
  >
    <img class="pointCF__bulk-changes-field-icon" [src]="EIconPath.CUSTOM_FIELD_TEXT_ACTIVITY" />

    <span class="pointCF__label-container">
      {{ ppCustomField?.label }}
    </span>
  </label>

  <div class="pointCF__content">
    <textarea
      rows="1"
      ppTooltip
      [ppTitle]="ppCustomFields[ppCustomField.id]"
      [ppTrimTooltip]="true"
      autocomplete="off"
      class="customFieldText__input pointCF__input input custom-list-input"
      type="text"
      name="customtext{{ ppCustomField?.label }}"
      [(ngModel)]="ppCustomFields[ppCustomField.id]"
      [placeholder]="'enter_text' | ppTranslate"
      (input)="onInput($event)"
      (keydown.enter)="blurInput($event)"
      (change)="updateText(ppCustomField)"
      #input
      (keydown)="onKeyDown($event, ppCustomField.id)"
    ></textarea>

    <button class="pointCF__clear" (click)="clearCustomField(ppCustomField.id)">&#xd7;</button>
  </div>
</div>
