<div class="notification__image-wrapper">
  <img class="notification__image" [src]="EIconPath.NOTIFICATION_ICON_TITLE" />
</div>

<span>
  <span class="notification__blue-text">{{ 'changed' | ppTranslate }}</span>
  {{ 'the' | ppTranslate }}
  <span class="notification__bold">{{ 'title' | ppTranslate }}</span>
  {{ 'of_a_point_you' | ppTranslate }}
  {{ notificationReason }}:
</span>

<div>"{{ ppNotification.changeBody.newValue }}"</div>
