import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TSelectOption } from 'src/app/project/components/input/select/select.model';
import { GET_CUSTOM_FIELDS } from 'src/app/project/modules/custom-fields/custom-fields.store';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';
import {
  TAdvancedFilterFormula,
  TAdvancedFilterSingle,
  TFilterNumberOptions,
} from '../../../../models/advanced-filter.model';
import { ENumberInputUnitPosition } from '../../../input-fields/advanced-filter-number-input/number-input.model';
import { getAdvancedFormulaOptions } from './advanced-filter-formula-utils';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';

@Component({
  selector: 'pp-advanced-filter-formula',
  templateUrl: './advanced-filter-formula.component.html',
  styleUrls: ['./advanced-filter-formula.component.scss'],
})
export class AdvancedFilterFormulaComponent {
  @Input() ppFilter: TAdvancedFilterSingle;
  @Output() ppFilterChange = new EventEmitter<TAdvancedFilterSingle>();

  value: number;
  option: TFilterNumberOptions;
  filter: TAdvancedFilterFormula;
  unit: string;
  unitPosition: ENumberInputUnitPosition;
  outputType: ECustomFieldType;

  advancedFilterFormulaOptions: TSelectOption[];
  EAdvancedFilterOptions = EAdvancedFilterOptions;

  ngOnChanges(): void {
    this.filter = this.ppFilter as TAdvancedFilterFormula;
    this.advancedFilterFormulaOptions = getAdvancedFormulaOptions();

    this.value = this.filter.value;
    this.option = this.filter.option;
    const field = GET_CUSTOM_FIELDS()[this.filter.id];

    this.outputType = field.outputType;

    switch (this.outputType) {
      case ECustomFieldType.COST:
        this.unitPosition = ENumberInputUnitPosition.LEFT;
        this.unit = field.currencyCode + ' ' + field.currencySymbol;
        break;
      case ECustomFieldType.NUMBERS:
        this.unitPosition = ENumberInputUnitPosition.RIGHT;
        this.unit = field.unit;
        break;
      case ECustomFieldType.PERCENTAGE:
        this.unitPosition = ENumberInputUnitPosition.RIGHT;
        this.unit = '%';
        break;
    }
  }

  changeFieldCondition(newCondition: TFilterNumberOptions): void {
    this.ppFilter.option = newCondition;
    this.option = newCondition;
    this.applyChanges();
  }

  applyChanges(): void {
    this.ppFilterChange.emit(this.ppFilter);
  }

  changeFieldValue(value: number): void {
    this.ppFilter.value = value;
    this.applyChanges();
  }
}
