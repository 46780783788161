import { Component, Input } from '@angular/core';
import { EIconPath } from '../../../../../../shared/enums/icons.enum';
import { TActivityChange } from '../activity-change.model';

@Component({
  selector: 'pp-dashlet-activity-copy',
  templateUrl: './dashlet-activity-copy.component.html',
  styleUrls: ['../dashlet-activity.component.scss'],
})
export class DashletActivityCopyComponent {
  @Input() ppChange: TActivityChange;

  EIconPath = EIconPath;
}
