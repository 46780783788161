import { Injectable } from '@angular/core';
import { SortingService } from '@core/helpers';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImportTagModalService {
  public selectableTagsChange$ = new Subject<void>();

  selectableTags: string[] = [];

  constructor(private sortingService: SortingService) {}

  getSelectableTags(): string[] {
    return this.selectableTags;
  }

  setSelectableTags(tags: string[]): void {
    this.selectableTags = tags;

    this.selectableTagsChange$.next();
  }

  setSpreadsheetTags(spreadData: string[], initialWorkspaceTags: string[]): string[] {
    const selectableTags = [];
    let data = Array.from(new Set(spreadData));
    data = data
      .map((tag) => tag.trim())
      .filter((tag) => tag !== '')
      .sort((a, b) => this.sortingService.naturalSort(a, b));

    data.forEach((tag) => {
      if (!initialWorkspaceTags.includes(tag) && tag.length) {
        selectableTags.push(tag);
      }
    });

    this.setSelectableTags(selectableTags);

    return data;
  }
}
