import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { ClearFilterService } from '../../clear-filter-service/clear-filter.service';
import { TFilters, TSiteFilter, TSitesFilter } from '../../site-filter.model';

@Component({
  selector: 'pp-filter-field-sites',
  templateUrl: './filter-field-sites.component.html',
  styleUrls: ['../../site-filter-dropdown/site-filter-dropdown.component.scss'],
})
export class FilterFieldSitesComponent implements OnChanges {
  @Input() ppFilters: TFilters;
  @Input() ppWorkspaces: TWorkspacesById;

  @Output() ppApply = new EventEmitter();

  allSitesEnabled: {
    [accountName: string]: boolean;
  } = {};

  areSitesDefault = true;

  constructor(private clearFilterService: ClearFilterService) {}

  ngOnChanges(): void {
    this.ppFilters.sites.forEach((account) => {
      this.checkSiteFilter(account);
    });
  }

  clearSites(): void {
    const newSitesFilter = this.clearFilterService.clearSites();

    this.ppFilters.sites = newSitesFilter.sites;
    this.ppFilters.sitesDefault = newSitesFilter.default;

    Object.keys(this.allSitesEnabled).forEach((accountName) => {
      this.allSitesEnabled[accountName] = true;
    });

    this.checkAllSitesDefault();

    this.applyFilters();
  }

  selectAllSites(accountFilterItem: TSitesFilter): void {
    accountFilterItem.workspaces.forEach((site) => {
      site.value = true;
    });

    this.allSitesEnabled[accountFilterItem.accountName] = true;
    this.checkAllSitesDefault();

    this.applyFilters();
  }

  deselectAllSites(accountFilterItem: TSitesFilter): void {
    accountFilterItem.workspaces.forEach((site) => {
      site.value = false;
    });

    this.allSitesEnabled[accountFilterItem.accountName] = false;
    this.checkAllSitesDefault();

    this.applyFilters();
  }

  updateSiteValue(site: TSiteFilter, account: TSitesFilter): void {
    site.value = !site.value;

    if (!site.id) {
      Object.keys(this.ppWorkspaces).forEach((workspaceId) => {
        if (this.ppWorkspaces[workspaceId].siteName === site.name) {
          site.id = this.ppWorkspaces[workspaceId].workspaceId;
        }
      });
    }

    this.checkSiteFilter(account);
    this.checkAllSitesDefault();

    this.applyFilters();
  }

  applyFilters(): void {
    this.ppApply.emit();
  }

  checkSiteFilter(accountFilterItem: TSitesFilter): void {
    const appliedSites = accountFilterItem.workspaces.filter((site) => site.value);

    this.allSitesEnabled[accountFilterItem.accountName] =
      appliedSites.length === accountFilterItem.workspaces.length;
  }

  checkAllSitesDefault(): void {
    this.areSitesDefault = true;

    this.ppFilters.sites.forEach((site) => {
      site.workspaces.forEach((workspace) => {
        if (workspace.value !== workspace.default) {
          this.areSitesDefault = false;
        }
      });
    });
  }
}
