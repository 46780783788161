import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { KEY_ESC, TAnyFunction, clickOutside, pressKey, uuid } from '@core/helpers';
import { WindowService } from '@core/services';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { DropdownService } from '../../dropdown/dropdown-service/dropdown.service';
import { TDropdown } from '../../dropdown/dropdown.consts';
import { TItemToAdd, TSelectItem } from '../select-tag/select-tag.model';
import { arraysEqual } from '../select/utils/arrays-equal.utils';
import { getWidthNumber } from '../select/utils/get-width-number';
import { SelectGroupedDropdownComponent } from './select-grouped-dropdown/select-grouped-dropdown.component';
import { TSelectGroupedDropdownData } from './select-grouped-dropdown/select-grouped-dropdown.model';

@Component({
  selector: 'pp-select-grouped',
  templateUrl: './select-grouped.component.html',
  styleUrls: ['./select-grouped.component.scss'],
})
export class SelectGroupedComponent implements AfterViewInit, OnChanges {
  @ViewChild('selectComponent', { static: true }) element: ElementRef;
  @ViewChild('selectContent', { static: true }) contentElement: ElementRef;

  @Input() ppItems: TSelectItem[];
  @Input() ppSelectedItem: TItemToAdd;
  @Input() ppDisabled = false;
  @Output() ppSelectItem = new EventEmitter<TItemToAdd>();

  buttonId = uuid();
  dropdown: TDropdown = this.dropdownService.getDropdown();
  focused = false;
  EIconPath = EIconPath;

  private closeOnEscRef: TAnyFunction;
  private clickOutsideRef: TAnyFunction;
  private window = this.windowService.getGlobalObject();

  constructor(private windowService: WindowService, private dropdownService: DropdownService) {}

  ngOnChanges(changes: SimpleChanges) {
    const dropdownOpened = this.dropdown.visible && this.dropdown.buttonId === this.buttonId;
    const dropdownDataChanged =
      changes.ppItems && !arraysEqual(changes.ppItems.currentValue, changes.ppItems.previousValue);

    if (!dropdownOpened) {
      return;
    }

    if (changes.ppItems && dropdownDataChanged) {
      this.setDropdownData();
    }

    if (this.ppDisabled) {
      this.dropdownService.hideDropdown();
    }
  }

  ngAfterViewInit() {
    this.closeOnEscRef = (event: KeyboardEvent): void =>
      pressKey(event, KEY_ESC, () => {
        this.hideDropdown();
      });

    this.clickOutsideRef = (event: MouseEvent): void =>
      clickOutside(event, this.element.nativeElement, () => {
        event.stopImmediatePropagation();

        this.hideDropdown();
      });
  }

  toggleDropdown(): void {
    if (this.ppDisabled) {
      return;
    }

    if (this.dropdown.visible && this.dropdown.buttonId === this.buttonId) {
      this.dropdownService.hideDropdown();
    } else {
      this.setDropdownData();

      this.focused = true;

      this.dropdownService.showDropdown(this.buttonId, SelectGroupedDropdownComponent, {
        callback: (option) => this.selectItem(option),
        onClose: () => this.hideDropdown(),
        popper: {
          positionFixed: true,
          placement: 'bottom-start',
        },
        suppressScrollbar: false,
        addScrollCallback: false,
      });
    }
  }

  private selectItem(option: TItemToAdd): void {
    this.ppSelectItem.emit(option);

    this.hideDropdown();
  }

  private setDropdownData(): void {
    const dropdownData: TSelectGroupedDropdownData = {
      items: this.ppItems,
      selectedItem: this.ppSelectedItem,
      width: getWidthNumber(null, this.element.nativeElement.offsetWidth),
    };

    this.dropdownService.setData(dropdownData);
  }

  private hideDropdown(): void {
    this.dropdown.visible = false;
    this.focused = false;

    this.window.removeEventListener('keydown', this.closeOnEscRef, true);
    this.window.removeEventListener('click', this.clickOutsideRef, true);
  }
}
