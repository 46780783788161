import { Injectable } from '@angular/core';
import { API_workspace_custom_fields_excel } from '@core/api/paths';
import { ApiService } from '@core/http';
import { map, Observable } from 'rxjs';
import {
  TCustomField,
  TCustomFieldDTO,
} from 'src/app/project/modules/custom-fields/custom-fields.model';
import { CustomFieldModelFactory } from 'src/app/project/modules/custom-fields/utils/custom-field-model-factory';

@Injectable({
  providedIn: 'root',
})
export class CustomFieldsApiProviderService {
  constructor(private apiService: ApiService) {}

  importCustomFieldsFromExcel(workspaceId: string, formData: FormData): Observable<TCustomField> {
    const url = API_workspace_custom_fields_excel(workspaceId);

    return this.apiService
      .postWithFormData<TCustomFieldDTO>(url, formData)
      .pipe(map((field) => CustomFieldModelFactory.createFromDTO(field)));
  }
}
