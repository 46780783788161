import { TSelectOption } from 'src/app/project/components/input/select/select.model';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';
import { translate } from 'src/app/project/features/translate/translate';
import { EPriority } from 'src/app/project/modules/points/priorities';
import { TSelectMultipleOption } from 'src/app/project/components/input/select-multiple/select-multiple.model';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

export function getAdvancedPriorityOptions(): TSelectOption[] {
  return [
    {
      value: EAdvancedFilterOptions.IS,
      label: translate('is') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_NOT,
      label: translate('is_not') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_ANY_OF,
      label: translate('is_any_of') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_NONE_OF,
      label: translate('is_none_of') + '...',
    },
  ];
}

export function getAdvancedPriorityValues(): TSelectMultipleOption[] {
  return [
    {
      value: EPriority.HIGH,
      label: translate('high'),
      selected: false,
      iconSrc: EIconPath.ICON_BADGE_PRIORITY_HIGH_12,
    },
    {
      value: EPriority.MEDIUM,
      label: translate('medium'),
      selected: false,
      iconSrc: EIconPath.ICON_BADGE_PRIORITY_MEDIUM_12,
    },
    {
      value: EPriority.LOW,
      label: translate('low'),
      selected: false,
      iconSrc: EIconPath.ICON_BADGE_PRIORITY_LOW_12,
    },
  ];
}
