import { cloneDeep } from 'lodash';

import { EWorkspaces } from '../workspace/workspaces.enum';
import { ActionsUnion, EActionType } from './site-filter.actions';

export const initialState = {};

let newState = {};

export function siteFilterReducer(state = initialState, action: ActionsUnion) {
  switch (action.type) {
    case EActionType.SET_SITE_FILTER:
      newState = cloneDeep(state);

      newState[action.payload.workspaceId] = action.payload.filter;

      return newState;

    case EActionType.SET_ALL_FILTERS:
      if (!action.payload.filters) {
        newState = {};
      } else {
        newState = action.payload.filters;
      }

      return newState;

    case EActionType.CLEAR_FILTERS:
      newState = {};

      return newState;

    case EActionType.CLEAR_FILTER:
      newState = cloneDeep(state);

      newState[action.payload.workspaceId] = {};

      return newState;

    case EActionType.SET_FILTERS_FROM_PREFERENCES:
      newState = cloneDeep(state);

      if (action.payload.overviewFilter) {
        newState[EWorkspaces.OVERVIEW] = action.payload.overviewFilter;
      }

      if (action.payload.timelineFilter) {
        newState[EWorkspaces.TIMELINE] = action.payload.timelineFilter;
      }

      if (action.payload.remindersFilter) {
        newState[EWorkspaces.REMINDERS] = action.payload.remindersFilter;
      }

      return newState;

    default:
      return state;
  }
}
