import { cloneDeep } from 'lodash';

import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { TAllFilters, TFilters } from '../site-filter.model';

import { ActiveService } from '../../../services/active/active.service';

import { EStore } from '../../../shared/enums/store.enum';
import { SavedViewsService } from '../../saved-views/saved-views.service';
import { TableColumnsService } from '../../site/site-table/columns/table-columns.service';
import { checkCustomWorkspaceId } from '../../workspace/workspace';
import { SelectableFiltersService } from '../update-selectable-filters/selectable-filters.service';

@Injectable({
  providedIn: 'root',
})
export class SiteFilterService {
  private readonly destroy$ = new Subject<void>();

  private filters: TFilters;

  private siteFilters$ = new Observable<TAllFilters>();
  private workspaces$ = new Observable<TWorkspacesById>();
  private workspaceId: string;
  private allFilters: TAllFilters;

  constructor(
    private store: Store<{
      siteFilter: TAllFilters;
      workspaces: TWorkspacesById;
    }>,
    private activeService: ActiveService,
    private updateSelectableFiltersService: SelectableFiltersService,
    private savedViewsService: SavedViewsService,
    private tableColumnsService: TableColumnsService,
  ) {
    this.siteFilters$ = this.store.pipe(select(EStore.SITE_FILTER));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.siteFilters$.pipe(takeUntil(this.destroy$)).subscribe((allFilters) => {
      this.allFilters = allFilters;
      this.filters = cloneDeep(this.allFilters?.[this.workspaceId]);

      this.checkActiveView();
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.workspaceId = this.activeService.getActiveWorkspaceId();

      if (!this.workspaceId) {
        this.workspaceId = checkCustomWorkspaceId();
      }

      this.filters = cloneDeep(this.allFilters?.[this.workspaceId]);

      this.checkActiveView();
    });
  }

  cancelFilters(): void {
    this.filters.sites.forEach((account) => {
      account.value = account.appliedValue;

      account.workspaces.forEach((workspace) => {
        workspace.value = workspace.appliedValue;
      });
    });

    this.filters.priority.forEach((priority) => {
      priority.value = priority.appliedValue;
    });

    this.filters.status.forEach((status) => {
      status.value = status.appliedValue;
    });

    this.filters.tags.value = this.filters.tags.appliedValue.slice();
    this.filters.excludedTags.value = this.filters.excludedTags.appliedValue.slice();
    this.filters.createdBy.value = this.filters.createdBy.appliedValue.slice();
    this.filters.assignees.value = this.filters.assignees.appliedValue.slice();

    this.updateSelectableFiltersService.updateSelectableTags();
    this.updateSelectableFiltersService.updateSelectableExcludedTags();

    this.filters.date.created.startDate = this.filters.date.created.appliedValues.startDate;
    this.filters.date.created.endDate = this.filters.date.created.appliedValues.endDate;
    this.filters.date.updated.startDate = this.filters.date.updated.appliedValues.startDate;
    this.filters.date.updated.endDate = this.filters.date.updated.appliedValues.endDate;
  }

  getFilters(): TFilters {
    return this.filters;
  }

  private checkActiveView(): void {
    if (this.workspaceId) {
      this.savedViewsService.tryUpdateActiveView(
        this.workspaceId,
        this.tableColumnsService.getColumns(),
        this.filters,
      );
    }
  }
}
