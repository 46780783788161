<div class="formulaBuilder__input" [class.formulaBuilder__input--empty]="ppFormula.length === 0">
  <div class="formulaBuilder__inputPlaceholder" *ngIf="ppFormula.length === 0">
    {{ 'formula_builder_placeholder' | ppTranslate }}
  </div>

  <div
    cdkDropList
    cdkDropListOrientation="mixed"
    class="formulaList"
    (cdkDropListDropped)="drop($event)"
  >
    <pp-formula-piece
      class="draggableFormulaPiece"
      *ngFor="let formulaPiece of ppFormula; let index = index"
      [ppFormulaPiece]="formulaPiece"
      [ppWorkspaceId]="ppWorkspaceId"
      [ppCanDelete]="true"
      (ppDelete)="deleteFormulaPiece(index)"
      cdkDrag
    ></pp-formula-piece>
  </div>
</div>
