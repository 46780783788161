import { GET_COLUMNS } from '../../columns/columns.store';
import { GET_GROUPING } from '../../columns/grouping.store';
import { GET_TABLE } from '../../table.ui.store';

import { createElement } from 'src/app/core/helpers/dom';

import { checkElement } from '@core/helpers';
import { getCrossbrowserEventPath } from 'src/app/core/helpers/compose-event-path';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { TColumn } from '../../columns/column.model';
import Table from '../table/Table';

const selectColumnWidth = 48;
let table: Table = null;
let column: TColumn = null;
let cellElement: HTMLElement = null;
let shadowElement: HTMLElement = null;
let placementIndicatorElement: HTMLElement = null;
let offsetX = 0;
let rect: DOMRect = null;
let active = false;
let stationary = false;
let passedMiddlePoint = false;
let activeColumn: number = null;
let mousePosition: number = null;
let activeColumnWidth: number = null;
let start: number = null;
let end: number = null;
let middlePoint: number = null;
let stationaryTimeout: NodeJS.Timeout = null;
let columnPlacementIndicatorTimeout: NodeJS.Timeout = null;
let scrollInterval: number = null;
const edgeSize = 100;

export const moveColumnsMousedown = (
  _event: MouseEvent,
  _column: TColumn,
  _cellElement: HTMLElement,
): void => {
  active = true;
  cellElement = _cellElement;
  column = _column;
  activeColumn = _column.index;
  offsetX = _event.offsetX;
  table = GET_TABLE();
  rect = table.tableBody.virtualScroller.element.getBoundingClientRect();

  const target = _event.target as HTMLElement;

  if (
    target.classList.value.indexOf('table__head__cell--inner') !== -1 ||
    target.classList.value.indexOf('table__cell__inner__namedColumn') !== -1 ||
    target.classList.value.indexOf('site-table__total') !== -1
  ) {
    const path = getCrossbrowserEventPath(_event);

    offsetX += (path[0] as HTMLElement).offsetLeft;
  }

  shadowElement = createColumnShadowElement(rect, _event, _column);
  placementIndicatorElement = createColumnPlacementIndicatorElement(rect);

  document.body.appendChild(checkElement(shadowElement));
  document.body.appendChild(checkElement(placementIndicatorElement));
  table.tableBody.virtualScroller.disableSmoothScrolling();

  table.tableBody.virtualScroller.visibleIndexes.forEach((_index) => {
    const element = table.tableBody.virtualScroller.dataElements[_index].querySelector(
      `[data-index="${_column.index}"]`,
    );

    if (element) {
      element.classList.add('table__cell--dim');
    }
  });

  cellElement.classList.add('table__head__cell--dim');
  table.tableHead.element.classList.add('table__head--columnMove');

  document.body.classList.add('inheritCursors');
  document.body.style.cursor = 'grabbing';

  updateActiveColumn(_event);

  document.onmouseup = mouseup;
  document.onmousemove = mousemove;
};

const mouseup = (_event: MouseEvent): void => {
  const columns = GET_COLUMNS();
  const visibleColumns = columns
    .filter((_column) => !_column.hidden)
    .sort((a, b) => a.index - b.index);
  const columnIndex = visibleColumns.indexOf(column);

  _event.preventDefault();

  active = false;
  table.tableBody.virtualScroller.enableSmoothScrolling();

  if (activeColumn > columnIndex && !passedMiddlePoint) {
    activeColumn -= 1;
  } else if (activeColumn < columnIndex && passedMiddlePoint) {
    activeColumn += 1;
  }

  const destinationColumn = visibleColumns[activeColumn];
  const finalColumnIndex = columns.findIndex(
    (_column) => _column.index === destinationColumn.index,
  );

  if (column.index !== finalColumnIndex) {
    table.moveColumn(column.index, finalColumnIndex);

    logEventInGTAG(EGoogleEventSite.SITE__COLUMNS__MOVE, {
      event_category: EGoogleEventCategory.SITE,
    });
  }

  passedMiddlePoint = false;

  if (shadowElement) {
    shadowElement.parentNode.removeChild(shadowElement);

    shadowElement = null;
  }

  if (placementIndicatorElement) {
    placementIndicatorElement.parentNode.removeChild(placementIndicatorElement);

    placementIndicatorElement = null;
  }

  if (
    table.tableBody.virtualScroller.visibleXIndexes.find((_index) => _index === columnIndex) !==
    undefined
  ) {
    table.tableBody.virtualScroller.visibleIndexes.forEach((_index) => {
      const element = table.tableBody.virtualScroller.dataElements[_index].querySelector(
        `[data-index="${column.index}"]`,
      );

      if (element) {
        element.classList.remove('table__cell--dim');
      }
    });
  }

  cellElement.classList.remove('table__head__cell--dim');
  table.tableHead.element.classList.remove('table__head--columnMove');

  document.body.classList.remove('inheritCursors');
  document.body.style.cursor = 'unset';

  document.onmouseup = null;
  document.onmousemove = null;

  table.savePreferencesCallback();
};

const mousemove = (_event: MouseEvent): void => {
  _event.preventDefault();

  updateActiveColumn(_event);
  updatePlacementIndicatorElement();
  clearTimeout(stationaryTimeout);

  stationaryTimeout = setTimeout(() => {
    stationary = true;

    handleMousemove(_event);
  }, 50);

  stationary = false;
  shadowElement.style.left = `${_event.clientX - offsetX}px`;
};

function handleMousemove(_event: MouseEvent): void {
  const edgeLeft = rect.left + edgeSize;
  const edgeRight = rect.left + rect.width - edgeSize;
  const isInLeftEdge = _event.clientX < edgeLeft;
  const isInRightEdge = _event.clientX > edgeRight;

  if (!(isInLeftEdge || isInRightEdge)) {
    return;
  }

  const virtualScrollerWidth = Math.max(
    table.tableBody.virtualScroller.element.scrollWidth,
    table.tableBody.virtualScroller.element.offsetWidth,
    table.tableBody.virtualScroller.element.clientWidth,
  );

  const maxScrollX = virtualScrollerWidth - rect.width;

  if (stationary) {
    if (scrollInterval) {
      clearInterval(scrollInterval);

      scrollInterval = null;
    }

    scrollInterval = window.setInterval(() => {
      if (!active || !stationary) {
        clearInterval(scrollInterval);

        scrollInterval = null;
      } else {
        adjustWindowScroll();
      }
    }, 30);
  } else {
    if (scrollInterval) {
      clearInterval(scrollInterval);

      scrollInterval = null;
    }

    adjustWindowScroll();
  }

  function adjustWindowScroll(): boolean {
    const currentScrollX = table.tableBody.virtualScroller.scrollElement.scrollLeft;
    const maxStep = 20;
    const canScrollLeft = currentScrollX > 0;
    const canScrollRight = currentScrollX < maxScrollX;
    let nextScrollX = currentScrollX;

    if (isInLeftEdge && canScrollLeft) {
      const intensity = (edgeLeft - _event.clientX) / edgeSize;
      nextScrollX = nextScrollX - maxStep * intensity;
    } else if (isInRightEdge && canScrollRight) {
      const intensity = (_event.clientX - edgeRight) / edgeSize;
      nextScrollX = nextScrollX + maxStep * intensity;
    }

    nextScrollX = Math.max(0, Math.min(maxScrollX, nextScrollX));

    if (nextScrollX !== currentScrollX) {
      table.tableBody.virtualScroller.scrollLeft(nextScrollX);
      updateActiveColumn(_event);
      updatePlacementIndicatorElement();

      return true;
    }

    return false;
  }
}

function updateActiveColumn(_event: MouseEvent): void {
  const scrollLeft = table.tableBody.virtualScroller.scrollElement.scrollLeft;
  mousePosition = _event.clientX - rect.left - selectColumnWidth + scrollLeft;

  if (!columnPlacementIndicatorTimeout) {
    columnPlacementIndicatorTimeout = setTimeout(() => {
      columnPlacementIndicatorTimeout = null;
    }, 100);

    for (let index = 0; index < table.visibleColumnPositions.length; index += 1) {
      if (mousePosition <= 0) {
        activeColumn = 0;
        break;
      } else if (table.visibleColumnPositions[index] >= mousePosition) {
        activeColumn = index - 1;
        break;
      } else if (
        table.visibleColumnPositions[table.visibleColumnPositions.length - 1] < mousePosition
      ) {
        activeColumn = table.visibleColumnPositions.length - 1;
        break;
      }
    }
  }
}

function updatePlacementIndicatorElement(): void {
  const scrollLeft = table.tableBody.virtualScroller.scrollElement.scrollLeft;
  const grouping = GET_GROUPING();

  activeColumnWidth = GET_COLUMNS()[table.visibleColumnIndexes[activeColumn]].width;
  start = table.visibleColumnPositions[activeColumn];
  end = start + activeColumnWidth;
  middlePoint = start + activeColumnWidth / 2;

  if (mousePosition > middlePoint) {
    placementIndicatorElement.style.left = `${
      rect.left + 36 + end - scrollLeft + grouping.length * 16
    }px`;
    passedMiddlePoint = true;
  } else {
    placementIndicatorElement.style.left = `${
      rect.left + 36 + start - scrollLeft + grouping.length * 16
    }px`;
    passedMiddlePoint = false;
  }
}

function createColumnShadowElement(
  _rect: DOMRect,
  _event: MouseEvent,
  _column: TColumn,
): HTMLElement {
  return createElement('div', {
    attrs: {
      class: 'table__shadowColumn',
      style: {
        top: `${_rect.top - 32}px`,
        left: `${_event.clientX - offsetX}px`,
        width: `${_column.width}px`,
        height: `${table.tableBody.virtualScroller.viewportHeight + 32}px`,
      },
    },
  });
}

function createColumnPlacementIndicatorElement(_rect: DOMRect): HTMLElement {
  const classList = 'table__columnPlacementIndicator';

  return createElement('div', {
    attrs: {
      class: classList,
      style: {
        top: `${_rect.top - 32}px`,
        height: `${table.tableBody.virtualScroller.viewportHeight + 32}px`,
      },
    },
  });
}
