export enum EDefaultColumnWidths {
  PRIORITY = 60,
  STATUS = 60,
  ID = 60,
  RED_FLAG = 75,
  TITLE = 350,
  DESCRIPTION = 350,
  CREATED = 90,
  UPDATED = 90,
  CREATED_BY = 120,
  ASSIGNEES = 150,
  TAGS = 350,
  CUSTOM_FIELD = 150,
}
