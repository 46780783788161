import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { tap } from 'rxjs';
import { TAsset } from '../../../asset-service/asset.consts';
import { AssetReorderService } from '../asset-reorder.service';
import { AssetDashboardService } from './asset-dashboard/asset-dashboard.service';

@Component({
  selector: 'pp-asset-card',
  templateUrl: './asset-card.component.html',
  styleUrls: ['./asset-card.component.scss'],
})
export class AssetCardComponent implements OnInit {
  @ViewChild('assetCard') assetCardElement: ElementRef;
  @Input() ppAsset: TAsset;
  @Input() ppIndex: number;

  isBeingReordered: boolean;
  isExpanded: boolean;

  isClosing: boolean;

  constructor(
    private assetReorderService: AssetReorderService,
    private assetDashboardService: AssetDashboardService,
    private ngZone: NgZone,
  ) {
    this.assetDashboardService.expandedAssetIdChange$
      .pipe(
        tap((expandedAssetId) => {
          this.setDashboardState(expandedAssetId);
        }),
      )
      .subscribe();

    this.assetReorderService.reorderedAssetIdChange$
      .pipe(
        tap((assetId) => {
          this.checkIfIsBeingReordered(assetId);
        }),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.setDashboardState(this.assetDashboardService.getExpandedAssetId());
  }

  private setDashboardState(expandedAssetId: string) {
    if (!this.ppAsset) {
      return;
    }

    const isExpanded = expandedAssetId === this.ppAsset.id;

    if (isExpanded) {
      this.isExpanded = true;
      this.isClosing = false;
    } else {
      this.isClosing = true;

      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.ngZone.run(() => {
            this.isExpanded = false;
            this.isClosing = false;
          });
        }, 500);
      });
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();

    if (this.assetReorderService.getReorderedAssetId() === null) {
      return;
    }

    const targetElementCoords = this.assetCardElement.nativeElement.getBoundingClientRect();
    const elementCenterY =
      targetElementCoords.top + (targetElementCoords.bottom - targetElementCoords.top) / 2;
    const scrolledBeyondFistHalf = event.clientY > elementCenterY;

    if (scrolledBeyondFistHalf) {
      this.assetReorderService.setNewAssetIndex(this.ppIndex + 1);
    } else {
      this.assetReorderService.setNewAssetIndex(this.ppIndex);
    }
  }

  private checkIfIsBeingReordered(assetId: string): void {
    this.isBeingReordered = assetId === this.ppAsset.id;

    if (this.isBeingReordered) {
      this.assetCardElement.nativeElement.classList.add('assetCard__reordered');
    } else {
      this.assetCardElement.nativeElement.classList.remove('assetCard__reordered');
    }
  }
}
