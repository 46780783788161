import { ComponentType } from '@angular/cdk/overlay';
import { TDropdown } from '../dropdown.consts';
import { TDropdownModifiers } from './dropdown-service.consts';

export type TSetDropdownParams = {
  dropdown?: TDropdown;
  visible?: boolean;
  buttonId?: string;
  component?: ComponentType<unknown>;
  modifiers?: TDropdownModifiers[];
  long?: boolean;
  secondary?: boolean;
};

export function setDropdown({
  dropdown = null,
  visible = undefined,
  buttonId = undefined,
  component = undefined,
  modifiers = undefined,
  long = false,
  secondary = false,
}: TSetDropdownParams = {}): TDropdown {
  dropdown.long = long;
  dropdown.secondary = secondary;

  if (visible !== undefined) {
    dropdown.visible = visible;
  }

  if (buttonId !== undefined) {
    dropdown.buttonId = buttonId;
  }

  if (component !== undefined) {
    dropdown.component = component;
  }

  if (modifiers !== undefined) {
    dropdown.popper.modifiers = modifiers;
  }

  return dropdown;
}

export function setDropdownModifiers(_modifiers) {
  let modifiers;

  if (!_modifiers) {
    modifiers = [
      {
        name: 'computeStyles',
        options: {
          gpuAcceleration: false,
        },
      },
    ];
  } else {
    modifiers = _modifiers;
  }

  return modifiers;
}

export function loadComponent(dropdown: TDropdown): void {
  const viewContainerRef = dropdown.dropdownHost.viewContainerRef;
  viewContainerRef.clear();

  viewContainerRef.createComponent(dropdown.component);
}

export function clearDropdown(dropdown: TDropdown): void {
  dropdown.data = null;
  dropdown.visible = false;
  dropdown.long = false;
  dropdown.options = null;
  // popper positioning issue
  dropdown.visibilityHidden = false;
  dropdown.buttonId = null;
  dropdown.buttonElement = null;

  dropdown.callback = null;
  dropdown.onClose = null;

  dropdown.popper.instance = null;
  dropdown.popper.placement = 'bottom-start';
  dropdown.popper.positionFixed = false;
  dropdown.popper.modifiers = [
    {
      name: 'computeStyles',
      options: {
        gpuAcceleration: false,
      },
    },
  ];

  if (dropdown.dropdownElement) {
    dropdown.dropdownElement.scrollTo(0, 0);
  }

  if (dropdown.changeObserver) {
    dropdown.changeObserver.disconnect();
  }

  if (dropdown.component) {
    const viewContainerRef = dropdown.dropdownHost.viewContainerRef;

    viewContainerRef.clear();
  }
}
