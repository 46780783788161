import { Injectable } from '@angular/core';

import { SiteTableColumnsDataService, TColumnsData } from './site-table-columns-data.service';
import { SiteTableColumnsService } from './site-table-columns.service';
import { EDefaultColumnWidths } from './columns/default-column-widths-enum';

@Injectable({
  providedIn: 'root',
})
export class SiteTableColumnWidthService {
  private columns: TColumnsData = this.siteTableColumnsDataService.getColumns();
  private defaultColumnWidths = {
    Priority: EDefaultColumnWidths.PRIORITY,
    Status: EDefaultColumnWidths.STATUS,
    ID: EDefaultColumnWidths.ID,
    Title: EDefaultColumnWidths.TITLE,
    Description: EDefaultColumnWidths.DESCRIPTION,
    Created: EDefaultColumnWidths.CREATED,
    Updated: EDefaultColumnWidths.UPDATED,
    Tags: EDefaultColumnWidths.TAGS,
    CustomField: EDefaultColumnWidths.CUSTOM_FIELD,
    CreatedBy: EDefaultColumnWidths.CREATED_BY,
    Assignees: EDefaultColumnWidths.ASSIGNEES,
  };

  constructor(
    private siteTableColumnsDataService: SiteTableColumnsDataService,
    private siteTableColumnsService: SiteTableColumnsService,
  ) {}

  getDefaultColWidths(): {
    [key: string]: number;
  } {
    return this.defaultColumnWidths;
  }

  updateColumnWidthPrefs(columnName: string, width: number): void {
    const column = this.columns.data.find((_column) => _column.name === columnName);

    if (column) {
      column.width = width;

      this.siteTableColumnsService.updateColumnPrefs();
    }
  }
}
