import { translate } from 'src/app/project/features/translate/translate';
import { EDashletType } from '../dashlets-enums';

export function addGraphLabels(type: EDashletType, data: number[]): string[] {
  switch (type) {
    case EDashletType.CURRENT_STATUS:
    case EDashletType.OVER_TIME_STATUS:
      return [
        `${translate('open')} (${data[0]})`,
        `${translate('in_progress')} (${data[1]})`,
        `${translate('to_review')} (${data[2]})`,
        `${translate('on_hold')} (${data[3]})`,
        `${translate('completed')} (${data[4]})`,
        `${translate('canceled')} (${data[5]})`,
      ];
    case EDashletType.CURRENT_PRIORITY:
    case EDashletType.OVER_TIME_PRIORITY:
      return [
        `${translate('high')} (${data[0]})`,
        `${translate('medium')} (${data[1]})`,
        `${translate('low')} (${data[2]})`,
      ];
    default:
      return [];
  }
}
