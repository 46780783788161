import { VERSION } from '../../../environments/version';

import { Inject, Injectable, NgZone } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import { TUser } from 'src/app/project/modules/user/user.model';

import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { WindowService } from '@core/services';
import { takeUntil } from 'rxjs/operators';
import { accountTypesNames } from 'src/app/project/modules/account/account-utils/account-types-names';
import { TAccount } from 'src/app/project/modules/account/account.model';
import {
  GET_ACCOUNT_MANAGERS,
  GET_INDUSTRIES,
} from 'src/app/project/modules/account/account.store';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { translate } from '../features/translate/translate';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  public readonly intercomLoaded$ = new Subject<void>();

  private role: string = null;
  private accountType: string = null;
  private industry: string = null;
  private accountManager: string = null;
  private accounts$: Observable<TAccount[]>;
  private accounts: TAccount[];
  private workspaces$: Observable<TWorkspacesById>;
  private workspaces: TWorkspacesById;

  private user$: Observable<TUser>;
  private user: TUser;
  private window = this.windowService.getGlobalObject();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private ngZone: NgZone,
    private store: Store<{ user: TUser; workspaces: TWorkspacesById; accounts: TAccount[] }>,
    private router: Router,
    private windowService: WindowService,
  ) {
    this.user$ = this.store.pipe(select(EStore.USER));
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.accounts$.subscribe((accounts) => {
      this.accounts = accounts;
    });

    this.workspaces$.subscribe((workspaces) => {
      this.workspaces = workspaces;
    });

    this.user$.subscribe((user) => {
      this.user = user;
    });

    const listener = (): void => {
      ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.shutdownIntercom();
          this.window.removeEventListener('Intercom Disabled', listener, true);
        });
      });
    };

    this.router.events.subscribe((event) => {
      // Event_2 from router.events is incorrect
      this.handleIntercomChange(event as RouterEvent);
    });

    this.window.addEventListener('Intercom Disabled', listener, false);
  }

  shutdownIntercom(): void {
    if (this.windowService.getIntercom()) {
      this.windowService.getIntercom()('shutdown');
    }
  }

  initIntercom(user: TUser): void {
    if (this.windowService.getCookiehub()?.hasConsented('preferences')) {
      const defaultOptions = {
        name: user.userName,
        email: user.email,
        user_id: user.userId,
        user_hash: user.intercomHash,
        created_at: Math.round(user.createdOn / 1000),
        app_version: VERSION.version,
      };

      if (this.role) {
        this.windowService.getIntercom()('boot', {
          ...defaultOptions,
          last_access_level: this.role,
          last_account_type: this.accountType,
          last_industry_type: this.industry, // it's in the same place as role so no need for a separate if
          last_account_manager: this.accountManager,
        });
      } else {
        this.windowService.getIntercom()('boot', defaultOptions);
      }
    }
  }

  updateIntercomSite(
    role: string,
    accountType: string,
    industryType: string,
    accountManager: string,
  ): void {
    const industries = GET_INDUSTRIES();
    const accountManagers = GET_ACCOUNT_MANAGERS();
    const accountTypeName = accountTypesNames.find((_account) => _account.value === accountType);

    this.setIntercomRole(role as EUserRole);

    if (accountTypeName) {
      this.accountType = translate(accountTypeName.label);
    }

    if (industryType) {
      this.industry = industries.find((_industry) => _industry.value === industryType).label;
    } else {
      this.industry = '';
    }

    if (accountManager) {
      this.accountManager = accountManagers.find(
        (_manager) => _manager.value === accountManager,
      ).label;
    } else {
      this.accountManager = 'Unset';
    }

    if (this.user && this.windowService.getIntercom()) {
      this.initIntercom(this.user);
    }
  }

  startIntercom(newUser: TUser): void {
    if (this.windowService.getIntercom()) {
      this.initIntercom(newUser);
    } else {
      const listener = (): void => {
        this.ngZone.runOutsideAngular(() => {
          setTimeout(() => {
            if (this.windowService.getIntercom()) {
              this.initIntercom(newUser);

              this.window.removeEventListener('Intercom Initialized', listener, true);
            }
          }, 1000);
        });
      };

      this.window.addEventListener('Intercom Initialized', listener, false);
    }
  }

  setIntercomRole(role: EUserRole): void {
    if (role === EUserRole.OWNER) {
      this.role = 'Owner';
    } else if (role === EUserRole.SITE_ADMIN) {
      this.role = 'Site Admin';
    } else if (role === EUserRole.NORMAL) {
      this.role = 'Normal';
    } else if (role === EUserRole.LIMITED) {
      this.role = 'Guest';
    } else if (role === EUserRole.ACCOUNT_ADMIN) {
      this.role = 'Account Admin';
    }
  }

  private handleIntercomChange(event: RouterEvent): void {
    if (event instanceof NavigationEnd) {
      const parsedURL = this.router.parseUrl(event.urlAfterRedirects);
      const parsedURLSegments = parsedURL.root.children.primary.segments;

      if (parsedURLSegments[1] && parsedURLSegments[0].path === 'site') {
        if (this.workspaces[parsedURLSegments[1].path]) {
          const account = this.accounts.find(
            (searchedAccount) =>
              searchedAccount.accountId === this.workspaces[parsedURLSegments[1].path].accountId,
          );

          this.updateIntercomSite(
            this.workspaces[parsedURLSegments[1].path].share.shareOption,
            account?.accountType,
            account?.industryType,
            account?.accountManager,
          );
        } else {
          this.workspaces$.pipe(takeUntil(this.intercomLoaded$)).subscribe((workspaces) => {
            if (
              workspaces[parsedURLSegments[1].path] &&
              workspaces[parsedURLSegments[1].path].share.shareOption
            ) {
              const account = this.accounts.find(
                (searchedAccount) =>
                  searchedAccount.accountId ===
                  this.workspaces[parsedURLSegments[1].path].accountId,
              );

              this.updateIntercomSite(
                workspaces[parsedURLSegments[1].path].share.shareOption,
                account?.accountType,
                account?.industryType,
                account?.accountManager,
              );

              this.intercomLoaded$.next();
            }
          });
        }
      } else {
        this.updateIntercomSite('', '', '', '');
      }
    }
  }
}
