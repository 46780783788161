import { GET_PREFERENCES } from 'src/app/project/modules/preferences/preferences.store';
import { createElement } from 'src/app/core/helpers/dom';
import { checkCurrentDate } from '../site-timeline-utils/checkCurrentDate';
import { ETimelineTimeframe } from '../site-timeline-utils/timeframes-enums';
import { createTimelineHeadCellInnerElement } from './create-timeline-head-cell-inner-element';
import {TAnyFunction} from '@core/helpers';

export function createTimelineHeadCellElement({
  name: _name = null,
  child: _child = null,
  secondaryName: _secondaryName = null,
  center: _center = false,
  resize: _resize = true,
  width: _width = 'auto',
  callback: _callback = null,
  column: _column = null,
  bottom: _bottom = false,
  index: _index = null,
}: {
  name: string;
  child: string;
  secondaryName?: string;
  center: boolean;
  width: string;
  column: string;
  resize: boolean;
  bottom: boolean;
  callback?: TAnyFunction;
  index?: number;
}): HTMLElement {
  const cellInnerElement = createTimelineHeadCellInnerElement(
    _child,
    _column,
    _bottom,
    _secondaryName,
  );

  const className = getElementStyling(_center, cellInnerElement, _name, _bottom);

  const cellElement = createElement('div', {
    attrs: {
      class: className,
      style: {
        width: _column['last'] ? '100%' : _width,
      },
    },
    children: [cellInnerElement],
  });

  if (_bottom) {
    const dateMatches = checkCurrentDate(_index);

    if (dateMatches) {
      cellInnerElement.children[0]
        .querySelector('span')
        ?.classList.add('timeline__head__bottomElement--active');
    }
  }

  return cellElement;
}

function getElementStyling(
  _center: boolean,
  cellInnerElement: HTMLElement,
  _name: string,
  _bottom: boolean,
): string {
  let className = 'timeline__cell__head';

  if (_center) {
    cellInnerElement.style.textAlign = 'center';
    cellInnerElement.style.padding = '0';

    if (!_name) {
      cellInnerElement.style.display = 'flex';
      cellInnerElement.style.justifyContent = 'center';
      cellInnerElement.style.alignItems = 'center';
    }
  }

  if (_bottom) {
    const timeframe = GET_PREFERENCES().timeline?.timeframe;

    if (timeframe === ETimelineTimeframe.WEEK) {
      className += ' timeline__cell__head__bottom__week';
    } else {
      className += ' timeline__cell__head__bottom';
    }
  } else {
    const timeframe = GET_PREFERENCES().timeline?.timeframe;

    if (timeframe === ETimelineTimeframe.WEEK) {
      className += ' timeline__cell__head__top__week';
    } else {
      className += ' timeline__cell__head__top';
    }
  }
  return className;
}
