import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeviceService } from '@core/services';
import { Subject, takeUntil } from 'rxjs';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { DropdownService } from '../../../dropdown/dropdown-service/dropdown.service';
import { TDropdown } from '../../../dropdown/dropdown.consts';
import { TItemToAdd, TSelectItem } from '../../select-tag/select-tag.model';
import { TSelectGroupedDropdownData } from './select-grouped-dropdown.model';

@Component({
  selector: 'pp-select-grouped-dropdown',
  templateUrl: './select-grouped-dropdown.component.html',
  styleUrls: ['./select-grouped-dropdown.component.scss'],
})
export class SelectGroupedDropdownComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  dropdown: TDropdown = this.dropdownService.getDropdown();
  dropdownWidth: string = '558px';

  public isMobile = false;

  items: TSelectItem[];
  selectedItem: TItemToAdd = null;
  EIconPath = EIconPath;

  constructor(private dropdownService: DropdownService, private deviceService: DeviceService) {
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit(): void {
    this.setDataFromDropdown();

    this.dropdownService.dropdownDataChanged$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setDataFromDropdown();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  hideDropdown(): void {
    this.dropdownService.hideDropdown();
  }

  selectItem(item: TItemToAdd): void {
    this.dropdown.callback(item);

    this.hideDropdown();
  }

  private setDataFromDropdown(): void {
    const dropdownData: TSelectGroupedDropdownData = this.dropdown.data;

    this.items = dropdownData.items;
    this.selectedItem = dropdownData.selectedItem;
    this.dropdownWidth = dropdownData.width;
  }
}
