import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { PreviousPageService } from 'src/app/core/services/previous-page.service';
import { TAssetSite } from 'src/app/project/modules/fleet-management/asset-service/asset.consts';
import { FleetService } from 'src/app/project/modules/fleet-management/fleet-service/fleet.service';
import { FilterAssetService } from 'src/app/project/modules/preferences/filter-asset.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-asset-card-sites-table',
  templateUrl: './asset-card-sites-table.component.html',
  styleUrls: ['./asset-card-sites-table.component.scss'],
})
export class AssetCardSitesTableComponent implements AfterViewInit {
  @Input() ppSites: TAssetSite[];

  EIconPath = EIconPath;

  scrolledToEnd = false;
  @ViewChild('scrollContainer') scrollContainer: ElementRef;

  constructor(
    private previousPageService: PreviousPageService,
    private router: Router,
    private filterAssetService: FilterAssetService,
    private fleetService: FleetService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.checkIfScrolledToEnd();
    this.setScrollOverflowListener();
    this.changeDetectorRef.detectChanges();
  }

  setScrollOverflowListener(): void {
    const container = this.scrollContainer.nativeElement as HTMLElement;

    container.addEventListener('scroll', () => {
      this.checkIfScrolledToEnd();
    });
  }

  checkIfScrolledToEnd(): void {
    const container = this.scrollContainer.nativeElement as HTMLElement;

    const maxScroll = container.scrollHeight - container.clientHeight - 10;

    if (container.scrollTop >= maxScroll) {
      this.scrolledToEnd = true;
    } else {
      this.scrolledToEnd = false;
    }
  }

  navigateToSite(workspaceId: string): void {
    const fleetId = this.fleetService.getActiveFleetId();
    const fleet = this.fleetService.getFleet(fleetId);
    const fleetStatuses = fleet.statusSettings;

    this.filterAssetService.setOpeningSiteFromAsset(true, fleetStatuses, false);
    this.previousPageService.setPreviousFleetUrl(this.router.url);
    this.router.navigate(['/site', workspaceId]);
  }
}
