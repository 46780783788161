import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { TCustomField } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TAdvancedFilterListValue } from '../../../models/advanced-filter.model';
import { AdvancedFilterListInputDropdownComponent } from './advanced-filter-list-input-dropdown/advanced-filter-list-input-dropdown.component';
import { TAdvancedFilterListDropdownData } from './advanced-filter-list-input-dropdown/advanced-filter-list-input-dropdown.model';

@Component({
  selector: 'pp-advanced-filter-list-input',
  templateUrl: './advanced-filter-list-input.component.html',
  styleUrls: ['./advanced-filter-list-input.component.scss'],
})
export class AdvancedFilterListInputComponent implements OnChanges {
  @Input() ppSelected: TAdvancedFilterListValue;
  @Input() ppField: TCustomField;
  @Output() ppSelectCf = new EventEmitter();

  EIconPath = EIconPath;
  id: string;

  dropdown: TDropdown = this.dropdownService.getDropdown();

  constructor(private dropdownService: DropdownService) {}

  ngOnChanges(): void {
    this.id = 'filtercflist' + this.ppField.id.toString() + this.ppField.workspaceId.toString();
  }

  showList(): void {
    this.dropdownService.setData<TAdvancedFilterListDropdownData>({
      field: this.ppField,
      selected: this.ppSelected,
    });

    if (this.dropdown.visible && this.dropdown.buttonId === this.id) {
      this.dropdownService.hideDropdown();
    } else {
      this.dropdownService.showDropdown(this.id, AdvancedFilterListInputDropdownComponent, {
        suppressScrollbar: true,
        callback: (item) => {
          this.selectCf(item);
        },
      });
    }
  }

  selectCf(selectedValue: TAdvancedFilterListValue): void {
    this.ppSelected = selectedValue;
    this.ppSelectCf.emit(selectedValue);
  }
}
