import * as ExcelJS from 'exceljs';
import { basicColumns } from '../timeline-export.const';

export function styleBasicFields(worksheet: ExcelJS.Worksheet): void {
  const rowCount = worksheet.rowCount;

  for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
    const row = worksheet.getRow(rowIndex + 1);

    for (let columnIndex = 1; columnIndex < basicColumns.length + 1; columnIndex++) {
      // 1 so we ignore empty column
      const cell = row.getCell(columnIndex + 1);

      setBorderColor(cell);

      if (rowIndex === 0) {
        // bold headers
        cell.font = {
          bold: true,
        };
      }
    }
  }
}
function setBorderColor(cell: ExcelJS.Cell): void {
  cell.border = {
    top: {
      style: 'thin',
      color: { argb: '24292D' },
    },
    bottom: {
      style: 'thin',
      color: { argb: '24292D' },
    },
    left: {
      style: 'thin',
      color: { argb: '24292D' },
    },
    right: {
      style: 'thin',
      color: { argb: '24292D' },
    },
  };
}
