import { Placement } from '@popperjs/core';

import { GET_DROPDOWNS, REMOVE_DROPDOWN, SET_DROPDOWN } from './dropdown.ui.store';

import { createElement, pressEsc, TAnyFunction } from '@core/helpers';
import { ApiService } from '@core/http';
import { GroupButtonDropdownService } from '../../modules/site/site-options/site-options-group-button/group-button-dropdown/group-button-dropdown.service';
import { SiteOptionsService } from '../../modules/site/site-options/site-options.service';

export type TCustomDropdownParams = {
  name?: string;
  parentName?: string;
  anchorElement?: HTMLElement;
  contentElement?: HTMLElement;
  onClose?: TAnyFunction;
  onSelect?: TAnyFunction;
  closeOnSelect?: boolean;
  class?: string;
  placement?: Placement;
  offset?: number[];
  width?: number | string;
  minWidth?: number;
  maxHeight?: number;
  siteOptionsService?: SiteOptionsService;
  groupButtonDropdownService?: GroupButtonDropdownService;
  apiService?: ApiService;
  type?: string;
};

export default abstract class AbstractDropdown {
  name: string = null;
  parentName: string = null;
  anchorElement: HTMLElement = null;
  groupButtonDropdownService: GroupButtonDropdownService = null;
  apiService: ApiService = null;
  contentElement: HTMLElement = null;
  onClose: TAnyFunction = null;
  onSelect: TAnyFunction = null;
  closeOnSelect: boolean = null;
  class: string = null;
  placement: Placement;
  offset: number[] = null;
  width: number | string = null;
  minWidth: number = null;
  maxHeight: number = null;

  onEsc: TAnyFunction = null;
  onOutsideClick: TAnyFunction = null;
  siteOptionsService: SiteOptionsService;

  element: HTMLElement = null;

  protected columnSectionElement: HTMLElement;

  constructor({
    name: _name = null,
    parentName: _parentName = null,
    anchorElement: _anchorElement = null,
    contentElement: _contentElement = null,
    onClose: _onClose = null,
    onSelect: _onSelect = null,
    closeOnSelect: _closeOnSelect = true,
    class: _class = null,
    placement: _placement = <Placement>'bottom-start',
    offset: _offset = [0, 0],
    width: _width = null,
    minWidth: _minWidth = null,
    maxHeight: _maxHeight = null,
    siteOptionsService,
    groupButtonDropdownService,
    apiService,
  }: TCustomDropdownParams = {}) {
    this.name = _name;
    this.parentName = _parentName;
    this.anchorElement = _anchorElement;
    this.contentElement = _contentElement;
    this.onClose = _onClose;
    this.onSelect = _onSelect;
    this.closeOnSelect = _closeOnSelect;
    this.class = _class;
    this.placement = _placement;
    this.offset = _offset;
    this.width = _width;
    this.minWidth = _minWidth;
    this.maxHeight = _maxHeight;
    this.siteOptionsService = siteOptionsService;
    this.groupButtonDropdownService = groupButtonDropdownService;
    this.apiService = apiService;
  }

  init(): void {
    this.element = this.createElement();

    document.body.appendChild(this.element);
    this.updatePosition();

    this.onEsc = (_event: KeyboardEvent): void => {
      pressEsc(_event, () => {
        this.destroy();
      });
    };

    this.onOutsideClick = (_event): void => {
      const dropdowns = GET_DROPDOWNS();
      let close = true;
      const childDropdowns = [];

      Object.keys(dropdowns).forEach((_key) => {
        if (dropdowns[_key].parentName === this.name) {
          childDropdowns.push(dropdowns[_key]);
        }
      });

      if (childDropdowns[0]?.element.contains(_event.target)) {
        close = false;
      }

      if (close) {
        if (this.anchorElement.contains(_event.target)) {
          this.destroy(true);
        } else if (!this.element.contains(_event.target)) {
          this.destroy();
        }
      }
    };

    window.addEventListener('keydown', this.onEsc, true);
    window.addEventListener('click', this.onOutsideClick, true);

    SET_DROPDOWN(this.name, this);

    this.updateHeight();
  }

  // --------------------------------------------------
  // -------------------- ELEMENTS --------------------
  // --------------------------------------------------

  createElement(): HTMLElement {
    const style: {
      [key: string]: string;
    } = {};

    if (this.width !== null) {
      style.width = this.width.toString();
    }

    if (this.minWidth !== null) {
      style.minWidth = this.minWidth.toString();
    }

    if (this.maxHeight !== null) {
      style.maxHeight = this.maxHeight.toString();
    }

    return createElement('div', {
      attrs: {
        class: 'dropdown dropdown--secondary',
        style,
      },
      children: [this.contentElement],
    });
  }

  // --------------------------------------------------
  // --------------------- METHODS --------------------
  // --------------------------------------------------

  destroy(_anchor: boolean = false): void {
    if (this.onClose) {
      this.onClose(_anchor);
    }

    this.element.parentNode.removeChild(this.element);
    window.removeEventListener('keydown', this.onEsc, true);
    window.removeEventListener('click', this.onOutsideClick, true);
    REMOVE_DROPDOWN(this.name);
  }

  update(): void {
    this.updatePosition();
    this.updateHeight();
  }

  updatePosition(): void {
    const anchorRect = this.anchorElement.getBoundingClientRect();

    switch (this.placement) {
      case 'bottom-start':
        this.element.style.top = `${anchorRect.top + anchorRect.height + this.offset[1]}px`;
        this.element.style.left = `${anchorRect.left + this.offset[0]}px`;
        break;
      default:
        break;
    }
  }

  updateHeight(): void {
    const dropdownRect = this.element.getBoundingClientRect();
    const saveMargin = 24;
    const maxHeight = document.body.offsetHeight - dropdownRect.top - saveMargin;

    if (this.element.offsetHeight > maxHeight) {
      this.element.style.maxHeight = `${maxHeight}px`;
    }
  }
}
