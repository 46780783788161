import { TAccount } from '../../../account/account.model';
import { TFilters } from '../../../filters/site-filter.model';

export function removeMissingAccounts(filters: TFilters, accounts: TAccount[]): void {
  filters.sites = filters.sites.filter((_account) => {
    let accountFound = false;

    for (let i = 0; i < Object.keys(accounts).length; i++) {
      if (accounts[Object.keys(accounts)[i]].accountName === _account.accountName) {
        accountFound = true;

        break;
      }
    }

    return accountFound;
  });
}
