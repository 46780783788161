<pp-button-dropdown
  [ppSrc]="EIconPath.INTERACTION_TIMEFRAME"
  [ppText]="'timeframe' | ppTranslate"
  [ppActive]="false"
  (ppAction)="toggleSelectDropdown('timeframeDropdown')"
  ppTooltip
  id="timeframeDropdown"
  data-m-target="Timeframe button"
  [ppTitle]="'timeframe' | ppTranslate"
></pp-button-dropdown>
