<div class="notifications__toolbar">
  <div
    id="notification__options"
    class="notification__options"
    [class.notification__options--active]="dropdownVisible"
    (click)="showNotificationOptionsDropdown()"
  >
    <pp-icon
      [ppDisplayContents]="true"
      ppColor="grey-500"
      [ppSrc]="EIconPath.ICON_THREE_DOTS_5"
    ></pp-icon>
  </div>

  <div class="notification__closeMobile" (click)="toggleNotifications()">
    <pp-icon
      [ppDisplayContents]="true"
      ppColor="grey-500"
      [ppSrc]="EIconPath.INTERACTION_CLOSE"
    ></pp-icon>
  </div>
</div>
