import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TTranslationKey } from '../../features/translate/types';
import { EIconPath } from '../../shared/enums/icons.enum';

@Component({
  selector: 'pp-bulk-changes-bar',
  templateUrl: './bulk-changes-bar.component.html',
  styleUrls: ['./bulk-changes-bar.component.scss'],
})
export class BulkChangesBarComponent {
  @Input() ppSelectedNumber: number;
  @Input() ppZIndex: number;
  @Input() ppSelectedTextKey: TTranslationKey = 'permissions_selected';
  @Output() ppClose = new EventEmitter<void>();

  EIconPath = EIconPath;

  close(): void {
    this.ppClose.emit();
  }
}
