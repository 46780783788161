import { GET_TABLE } from '../../table.ui.store';
import { createElement } from 'src/app/core/helpers/dom';
import { createCellElement } from '../createCellElement';
import { EIconPath } from '../../../../../shared/enums/icons.enum';
import { GET_GROUPING } from '../../columns/grouping.store';
import { ERowType } from 'src/app/project/shared/enums/row-type.enum';
import { TColumn } from '../../columns/column.model';
import Table from '../../custom-table/table/Table';
import { getSelectElementWidthPx } from '../../custom-table/table/utils/select-element-width';

export function createSelectElement(_index: number): HTMLElement {
  const table = GET_TABLE();
  const groupingRowElements: HTMLElement[] = [];
  const grouping = GET_GROUPING();
  let className = 'table__select';

  const selectElement = createInnerSelectElement(table, _index);
  const wrapperElement = createWrapperElement(grouping, table, _index, selectElement);

  if (grouping.length) {
    className += ' table__row--grouped';
    getGroupingRowElements(grouping, groupingRowElements);
  }

  const innerElementClass = getInnerElementClass(grouping.length);

  return createCellElement({
    className,
    width: getSelectElementWidthPx(grouping.length),
    center: true,
    innerElementClass,
    callback: () => {
      handleCallback(table, _index, wrapperElement, selectElement);
    },
    child: createElement('div', {
      children: [...groupingRowElements, wrapperElement],
    }),
  });
}

function createWrapperElement(
  grouping: TColumn[],
  table: Table,
  _index: number,
  selectElement: HTMLImageElement,
): HTMLElement {
  const wrapperClassList: string[] = getWrapperClassList(grouping, table, _index);

  const wrapperElement = createElement('div', {
    attrs: {
      class: wrapperClassList.join(' '),
    },
    children: [selectElement],
  });
  return wrapperElement;
}

function createInnerSelectElement(table: Table, _index: number): HTMLImageElement {
  let src = EIconPath.ICON_CHECHMARK_TABLE_FALSE;
  const classList = ['customSiteTable__checkbox'];

  if (table.selectedPoints.has(table.points[_index]._id)) {
    src = EIconPath.ICON_CHECHMARK_TABLE_SMALL;
    classList.push('customSiteTable__checkbox--selected');
  }

  const selectElement = createElement('div', {
    attrs: {
      class: 'customSiteTable__checkbox__container radial-out',
    },
    children: [
      createElement('img', {
        attrs: {
          class: classList.join(' '),
          src,
        },
      }),
    ],
  }) as HTMLImageElement;
  return selectElement;
}

function handleCallback(
  table: Table,
  _index: number,
  wrapperElement: any,
  selectElement: HTMLImageElement,
): void {
  const point = table.points[_index];
  const checkmarkElement = selectElement.children[0] as HTMLImageElement;

  if (table.selectedPoints.has(point._id)) {
    table.selectedPoints.delete(point._id);
    (wrapperElement.parentNode.parentNode.parentNode.parentNode as HTMLElement).classList.remove(
      'table__row--selected',
    );
    selectElement.classList.add('radial-out');

    checkmarkElement.src = EIconPath.ICON_CHECHMARK_TABLE_FALSE;
    checkmarkElement.classList.remove('customSiteTable__checkbox--selected');
  } else {
    table.selectedPoints.add(point._id);
    (wrapperElement.parentNode.parentNode.parentNode.parentNode as HTMLElement).classList.add(
      'table__row--selected',
    );
    selectElement.classList.remove('radial-out');

    checkmarkElement.src = EIconPath.ICON_CHECHMARK_TABLE_SMALL;
    checkmarkElement.classList.add('customSiteTable__checkbox--selected');
  }

  table.selectPointCallback(point);

  if (table.selectedPoints.size === table.points.length) {
    table.tableHead.selectPoints();
  } else {
    table.tableHead.deselectPoints();
  }
}

function getGroupingRowElements(grouping: TColumn[], groupingRowElements: HTMLElement[]): void {
  grouping.forEach((group, index) => {
    groupingRowElements.push(
      createElement('div', {
        attrs: {
          class: ((): string[] => {
            const classList = ['table__row--grouping'];

            switch (index) {
              case 0:
                classList.push('table__row--grouping--first-color');
                classList.push(getRowGroupingOrderClassName(0, grouping.length));
                break;
              case 1:
                classList.push('table__row__grouping--second-color');
                classList.push(getRowGroupingOrderClassName(1, grouping.length));

                break;
              case 2:
                classList.push(
                  'table__row__grouping--third-color table__row--grouping--last-third',
                );
                classList.push(getRowGroupingOrderClassName(2, grouping.length));

                break;
            }

            return classList;
          })(),
        },
      }),
    );
  });

  groupingRowElements.push(
    createElement('div', {
      attrs: {
        class: ((): string[] => {
          const classList: string[] = ['customSiteTable__checkbox__wrapper__background'];

          switch (grouping.length) {
            case 1:
              classList.push('table__row--grouping--first-color');
              break;
            case 2:
              classList.push('table__row__grouping--second-color');
              break;
            case 3:
              classList.push('table__row__grouping--third-color');
              break;
          }

          return classList;
        })(),
      },
    }),
  );
}

function getWrapperClassList(grouping: TColumn[], table: Table, _index: number): string[] {
  const wrapperClassList: string[] = ['customSiteTable__checkbox__wrapper'];

  if (grouping.length) {
    const currentItem = table.items.findIndex((item) => item.index === _index);
    const nextItem = table.items[currentItem + 1];
    const isLastRow = nextItem && nextItem.type !== ERowType.POINT;
    wrapperClassList.push('table__row--grouped');

    if (isLastRow) {
      wrapperClassList.push('customSiteTable__checkbox__wrapper--last');
    }
  } else {
    wrapperClassList.push('customSiteTable__checkbox__wrapper--noBorder');
  }
  return wrapperClassList;
}

function getRowGroupingOrderClassName(elementOrder: number, groupingLength: number): string {
  const adjustedOrder = groupingLength - elementOrder;

  switch (adjustedOrder) {
    case 1:
      return 'table__row--grouping--first-margin';
    case 2:
      return 'table__row__grouping--second-margin';
    case 3:
      return 'table__row__grouping--third-margin';
  }
}

function getInnerElementClass(groupingLength: number): string {
  let innerElementClass = 'table__select__inner';

  switch (groupingLength) {
    case 1:
      innerElementClass += ' table__select__inner--first';
      break;
    case 2:
      innerElementClass += ' table__select__inner--second';
      break;
    case 3:
      innerElementClass += ' table__select__inner--third';
      break;
  }

  return innerElementClass;
}
