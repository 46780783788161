import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccountApiProviderService } from '@core/api';
import { Store, select } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { TAccount } from 'src/app/project/modules/account/account.model';
import { TPreferences } from 'src/app/project/modules/preferences/preferences.model';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { TUser } from '../../user.model';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { ResponseErrorService } from 'src/app/project/modules/errors/response-error.service';
import { TWorkspaceSimpleResponse } from 'src/app/project/view-models/account-simple-response.model';
import { UserService } from '../../user.service';

@Component({
  selector: 'pp-user-settings-sites',
  templateUrl: './user-settings-sites.component.html',
  styleUrls: ['../user-settings.component.scss'],
})
export class UserSettingsSitesComponent implements OnInit, OnDestroy {
  workspaces: TWorkspaceSimpleResponse[] = [];
  offline$: Observable<boolean>;
  offline: boolean;
  preferences$: Observable<TPreferences>;
  preferences: TPreferences;
  user$: Observable<TUser>;
  user: TUser;
  accounts: TAccount[] = [];

  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store<{
      preferences: TPreferences;
    }>,
    private accountApiProviderService: AccountApiProviderService,
    private responseErrorService: ResponseErrorService,
    private userService: UserService,
  ) {
    this.preferences$ = this.store.pipe(select(EStore.PREFERENCES));
  }

  ngOnInit(): void {
    const user = this.userService.getUser();
    const showUnsubscribed = !user.isSuperUser;
    this.preferences$
      .pipe(
        takeUntil(this.destroy$),
        tap((preferences) => {
          this.preferences = cloneDeep(preferences);
        }),
      )
      .subscribe();

    this.accountApiProviderService
      .fetchAccountsSimpleHidden(showUnsubscribed)
      .pipe(
        tap((accounts) => {
          accounts.forEach((account) => {
            account.workspaces.forEach((accountWorkspace) => {
              this.workspaces.push({
                ...accountWorkspace,
                accountName: account.name,
              });
            });
          });
        }),
        catchError(this.responseErrorService.handleRequestError),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
