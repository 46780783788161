import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { transformDate } from 'src/app/project/shared/date-transformer';

import { ActionsUnion, EActionType } from './attachments.actions';
import { TAttachments } from './attachments.model';

export const initialState: TAttachments = {
  media: {
    dates: [],
    attachments: {},
  },
  files: {
    attachmentIds: [],
    attachments: {},
  },
};

let state: TAttachments = {
  media: {
    dates: [],
    attachments: {},
  },
  files: {
    attachmentIds: [],
    attachments: {},
  },
};

export function attachmentsReducer(
  oldState: TAttachments = initialState,
  action: ActionsUnion,
): TAttachments {
  switch (action.type) {
    case EActionType.CLEAR_ATTACHMENTS:
      state = cloneDeep(oldState);

      state.media.dates.length = 0;
      state.media.attachments = {};
      state.files.attachmentIds.length = 0;
      state.files.attachments = {};

      return state;

    case EActionType.SET_MEDIA:
      state = cloneDeep(oldState);

      state.media.dates = action.payload.dates;
      state.media.attachments = action.payload.attachments;

      return state;

    case EActionType.SET_FILES:
      state = cloneDeep(oldState);

      state.files.attachmentIds = action.payload.attachmentIds;
      state.files.attachments = action.payload.attachments;

      return state;

    case EActionType.ADD_NEW_MEDIA:
      state = cloneDeep(oldState);

      if (!state.media.attachments[action.payload.imageToStore.attachmentId]) {
        const date = dayjs(action.payload.imageToStore.createdOn);
        const fullDate = date.format('YYYYMMDD');
        const index = state.media.dates.findIndex((d) => d.fullDate === fullDate);
        const newAttachment = {};

        if (index > -1) {
          state.media.dates[index].attachmentIds.unshift(action.payload.imageToStore.attachmentId);
        } else {
          let timeStamp = null;

          timeStamp = transformDate({
            value: date,
            inputHourFormat: ' [at] hh:mm a',
            format: action.payload.dateFormat,
            localTime: false,
          });

          state.media.dates.unshift({
            fullDate,
            formattedDate: timeStamp,
            attachmentIds: [action.payload.imageToStore.attachmentId],
          });
        }

        newAttachment[action.payload.imageToStore.attachmentId] = action.payload.imageToStore;

        state.media.attachments = Object.assign(newAttachment, state.media.attachments);

        return state;
      }

      return oldState;

    case EActionType.REPLACE_MEDIA:
      state = cloneDeep(oldState);

      delete state.media.attachments[action.payload.oldAttachmentId];

      state.media.dates.forEach((date) => {
        const index = date.attachmentIds.findIndex(
          (attachmentId) => attachmentId === action.payload.oldAttachmentId,
        );

        if (index !== -1) {
          date.attachmentIds.splice(index, 1, action.payload.imageToStore.attachmentId);
        }
      });

      if (!state.media.attachments[action.payload.imageToStore.attachmentId]) {
        const newAttachment = {};

        newAttachment[action.payload.imageToStore.attachmentId] = action.payload.imageToStore;
        state.media.attachments = Object.assign(newAttachment, state.media.attachments);
      }

      return state;

    case EActionType.ADD_NEW_FILE:
      state = cloneDeep(oldState);

      if (!state.files.attachments[action.payload.attachmentId]) {
        state.files.attachmentIds.unshift(action.payload.attachmentId);
        state.files.attachments[action.payload.attachmentId] = action.payload;

        return state;
      }

      return oldState;

    case EActionType.DELETE_MEDIA:
      state = cloneDeep(oldState);

      delete state.media.attachments[action.payload];

      state.media.dates.forEach((date) => {
        date.attachmentIds = date.attachmentIds.filter(
          (attachmentId) => attachmentId !== action.payload,
        );
      });

      state.media.dates.forEach((date, index) => {
        if (state.media.dates[index].attachmentIds.length === 0) {
          state.media.dates.splice(index, 1);
        }
      });

      return state;

    case EActionType.DELETE_MULTIPLE_MEDIA:
      state = cloneDeep(oldState);

      action.payload.forEach((attachmentId) => {
        delete state.media.attachments[attachmentId];
        delete state.files.attachments[attachmentId];

        state.files.attachmentIds = state.files.attachmentIds.filter(
          (_attachmentId) => _attachmentId !== attachmentId,
        );

        state.media.dates.forEach((date) => {
          date.attachmentIds = date.attachmentIds.filter(
            (dateAttachmentId) => dateAttachmentId !== attachmentId,
          );
        });
      });

      state.media.dates.forEach((date, index) => {
        if (state.media.dates[index].attachmentIds.length === 0) {
          state.media.dates.splice(index, 1);
        }
      });

      state.media.dates = state.media.dates.filter((date) => date.attachmentIds.length > 0);

      return state;

    case EActionType.DELETE_FILE:
      state = cloneDeep(oldState);

      delete state.files.attachments[action.payload];
      state.files.attachmentIds = state.files.attachmentIds.filter(
        (attachmentId) => attachmentId !== action.payload,
      );

      return state;

    case EActionType.TOGGLE_MEDIA:
      state = cloneDeep(oldState);

      state.media.attachments[action.payload.attachmentId].selected = action.payload.selected;

      return state;

    case EActionType.TOGGLE_FILE:
      state = cloneDeep(oldState);

      state.files.attachments[action.payload.attachmentId].selected = action.payload.selected;

      return state;

    case EActionType.TOGGLE_ALL_FILES:
      state = cloneDeep(oldState);

      Object.keys(state.files.attachments).forEach((attachmentId) => {
        state.files.attachments[attachmentId].selected = action.payload.selected;
      });

      return state;

    case EActionType.TOGGLE_ALL_MEDIA:
      state = cloneDeep(oldState);

      Object.keys(state.media.attachments).forEach((attachmentId) => {
        state.media.attachments[attachmentId].selected = action.payload.selected;
      });

      return state;

    case EActionType.UPDATE_MEDIA: {
      state = cloneDeep(oldState);

      const attachmentId = action.payload.attachment.attachmentId;
      const date = dayjs(action.payload.attachment.createdOn);
      const fullDate = date.format('YYYYMMDD');
      const dateIndex = state.media.dates.findIndex((d) => d.fullDate === fullDate);

      if (dateIndex > -1) {
        const attachmentIndex = state.media.dates[dateIndex].attachmentIds.findIndex(
          (_attachmentId) => _attachmentId === action.payload.oldAttachmentId,
        );

        if (attachmentIndex > -1) {
          state.media.dates[dateIndex].attachmentIds.splice(attachmentIndex, 1, attachmentId);
        } else {
          state.media.dates[dateIndex].attachmentIds.unshift(attachmentId);
        }
      } else {
        state.media.dates.unshift({
          fullDate,
          formattedDate: date.format('ddd, MMM D'),
          attachmentIds: [attachmentId],
        });
      }

      delete state.media.attachments[action.payload.oldAttachmentId];

      state.media.dates.forEach((_date) => {
        _date.attachmentIds = _date.attachmentIds.filter(
          (_attachmentId) => _attachmentId !== action.payload.oldAttachmentId,
        );
      });

      state.media.attachments[attachmentId] = action.payload.attachment;

      return state;
    }

    case EActionType.UPDATE_MEDIA_NAME:
      state = cloneDeep(oldState);

      if (state.media.attachments[action.payload.attachmentId]) {
        state.media.attachments[action.payload.attachmentId].fileName = action.payload.name;
      }

      if (state.files.attachments[action.payload.attachmentId]) {
        state.files.attachments[action.payload.attachmentId].fileName = action.payload.name;
      }

      return state;

    default:
      return oldState;
  }
}
