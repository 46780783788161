import { Injectable, OnDestroy } from '@angular/core';
import { merge, Subject, timer } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';

export type TPrompt = {
  visibility: boolean;
  type: 'error' | 'warning' | 'success';
  text: string;
  heading: string;
  hide: () => void;
  duration: number;
  element?: HTMLElement;
};

export type TPromptParams = { heading?: string; duration?: number; left?: boolean };

export type TSetPromptParams = {
  visibility?: boolean;
  type?: 'error' | 'warning' | 'success';
  text?: string;
  heading?: string;
  duration?: number;
};

@Injectable({
  providedIn: 'root',
})
export class PromptService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  prompts: TPrompt[];
  position: {
    left: boolean;
  } = {
    left: false,
  };

  private setPromptVisibilityTimerMs = 300;
  private promptRemoveTimerMs = 500;
  private promptContainerElement: HTMLElement;

  constructor() {
    this.prompts = [];
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  getPrompts(): TPrompt[] {
    return this.prompts;
  }

  setPrompt({
    visibility = undefined,
    type = undefined,
    text = undefined,
    heading = undefined,
    duration = undefined,
  }: TSetPromptParams = {}): TPrompt {
    const promptItem: TPrompt = {
      visibility: false,
      type: null,
      text: null,
      heading: null,
      hide: () => {},
      duration: null,
    };

    if (visibility !== undefined) {
      timer(this.setPromptVisibilityTimerMs)
        .pipe(
          tap(() => {
            promptItem.visibility = visibility;
          }),
        )
        .subscribe();
    }

    if (type !== undefined) {
      promptItem.type = type;
    }

    if (text !== undefined) {
      promptItem.text = text;
    }

    if (heading !== undefined) {
      promptItem.heading = heading;
    }

    if (duration !== undefined) {
      promptItem.duration = duration;
    }

    this.prompts.push(promptItem);

    return promptItem;
  }

  clearPrompt(promptRef: TPrompt): void {
    if (promptRef.hide) {
      promptRef.hide();
    }
  }

  showPrompt({
    type = undefined,
    text = undefined,
    heading = undefined,
    duration = undefined,
  }: TSetPromptParams = {}): void {
    const promptRef = this.setPrompt({
      visibility: true,
      type,
      text,
      heading,
      duration,
    });

    const index = this.prompts.length - 1;

    const hidePrompt$ = new Subject<void>();

    timer(duration * 1000)
      .pipe(
        takeUntil(merge(hidePrompt$, this.destroy$)),
        finalize(() => {
          this.hidePrompt(promptRef);
        }),
      )
      .subscribe();

    this.prompts[index].hide = (): void => {
      hidePrompt$.next();
    };
  }

  showError(
    text: string,
    { heading = 'Error', duration = 5, left = false }: TPromptParams = {},
  ): void {
    this.showPrompt({
      type: 'error',
      text,
      heading,
      duration,
    });

    this.position.left = left;
  }

  showWarning(
    text: string,
    { heading = 'Warning', duration = 5, left = false }: TPromptParams = {},
  ): void {
    this.showPrompt({
      type: 'warning',
      text,
      heading,
      duration,
    });

    this.position.left = left;
  }

  showSuccess(
    text: string,
    { heading = 'Success', duration = 5, left = false }: TPromptParams = {},
  ): void {
    this.showPrompt({
      type: 'success',
      text,
      heading,
      duration,
    });

    this.position.left = left;
  }

  getPosition(): { left: boolean } {
    return this.position;
  }

  private hidePrompt(promptRef: TPrompt): void {
    const index = this.prompts.indexOf(promptRef);

    if (index !== -1 && promptRef.element && this.promptContainerElement) {
      let prompts = this.promptContainerElement.children;

      for (let i = index + 1; i < prompts.length; i++) {
        if (prompts[i]?.classList) {
          prompts[i].classList.add('prompt--collapse');
        }
      }

      promptRef.element.classList.add('prompt--close');

      timer(this.promptRemoveTimerMs)
        .pipe(
          tap(() => {
            prompts = this.promptContainerElement.children;

            for (let i = index + 1; i < prompts.length; i++) {
              if (prompts[i]?.classList) {
                prompts[i].classList.remove('prompt--collapse');
              }
            }

            this.prompts.splice(this.prompts.indexOf(promptRef), 1);
          }),
        )
        .subscribe();
    }
  }

  setPromptContainerElement(element: HTMLElement): void {
    this.promptContainerElement = element;
  }

  setPromptElement(promptRef: TPrompt, element: HTMLElement): void {
    if (!promptRef.visibility) {
      // TODO refactor to support Prompt array immutability
      promptRef.element = element;
    }
  }
}
