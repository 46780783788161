import { createElement } from 'src/app/core/helpers/dom';
import { EIconPath } from '../../../../../../shared/enums/icons.enum';

export function createSortingIndicatorElement(_index: number, _order: string): HTMLElement {
  const sortingElement = createElement<HTMLElement>('div', {
    attrs: {
      class: 'tableSortingElement',
    },
    children: [
      createElement<HTMLElement>('img', {
        attrs: {
          class:
            _order === 'ASC'
              ? 'tableSortingElement__arrow tableSortingElement__ASC'
              : 'tableSortingElement__arrow',
          src: EIconPath.ARROW_DOWN_LONG,
        },
      }),
      createElement<HTMLElement>('span', {
        children: [typeof _index === 'number' ? _index + 1 : 1],
      }),
    ],
  });

  return sortingElement;
}
