<div class="notification__image-wrapper">
  <img class="notification__image" [src]="EIconPath.NOTIFICATION_ICON_ASSIGNEES" />
</div>

<ng-container *ngIf="isNewValue">
  <ng-container *ngIf="assignedYou">
    <span class="notification__blue-text">{{ 'you' | ppTranslate }}</span>
    {{ 'were' | ppTranslate }}
    <span class="notification__bold">{{ 'assigned_lowercase' | ppTranslate }}</span>
    {{ 'to_a_point' | ppTranslate }}.
  </ng-container>

  <ng-container *ngIf="!assignedYou">
    <span>
      <span class="notification__blue-text">{{ 'added' | ppTranslate }}</span>
      {{ 'the_following' | ppTranslate }}
      <span class="notification__bold">{{ ppNotification.changeBody.label }}</span>
      {{ 'to_a_point_you' | ppTranslate }}
      {{ notificationReason }}:
    </span>

    <div class="notification__assignee-wrapper">
      <ng-container *ngFor="let userId of ppNotification.changeBody.newValue">
        <img
          ppTooltip
          [ppTitle]="allUsers[userId] ? allUsers[userId].userName : 'Deleted user'"
          class="notification__assignee"
          *ngIf="!ppNotification.changeBody?.oldValue.includes(userId)"
          [src]="allUsers[userId] ? allUsers[userId].avatarPublicUrl : EIconPath.PLACEHOLDER_USER"
        />
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="isOldValue">
  <span>
    <span class="notification__blue-text">{{ 'removed' | ppTranslate }}</span>
    {{ 'the_following' | ppTranslate }}
    <span class="notification__bold">{{ ppNotification.changeBody.label }}</span>
    {{ 'from_a_point_you' | ppTranslate }}
    {{ notificationReason }}:
  </span>

  <div class="notification__assignee-wrapper">
    <ng-container *ngFor="let userId of ppNotification.changeBody.oldValue">
      <img
        ppTooltip
        [ppTitle]="allUsers[userId] ? allUsers[userId].userName : 'Deleted user'"
        class="notification__assignee"
        *ngIf="!ppNotification.changeBody?.newValue.includes(userId)"
        [src]="allUsers[userId] ? allUsers[userId].avatarPublicUrl : EIconPath.PLACEHOLDER_USER"
      />
    </ng-container>
  </div>
</ng-container>
