import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TIconColor } from 'src/app/project/components/icon/icon-colors.model';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EUserRole } from '../../../share/share-utils/user-roles';
import { WorkspaceService } from '../../../workspace/workspace.service';
import { ESavedView } from '../../models/saved-view.enum';
import { TSavedViews } from '../../models/saved-views.model';

@Component({
  selector: 'pp-saved-views-dropdown-switch',
  templateUrl: './saved-views-dropdown-switch.component.html',
  styleUrls: ['./saved-views-dropdown-switch.component.scss'],
})
export class SavedViewsDropdownSwitchComponent implements OnInit {
  @Input() ppSelectedOption: ESavedView;
  @Input() ppSavedViews: TSavedViews;
  @Input() ppWorkspaceId: string;
  @Output() ppSelectedOptionChange = new EventEmitter<ESavedView>();

  EIconPath = EIconPath;

  ESavedView = ESavedView;

  enableSharedViews = true;
  savedViewsButtonCopy = '';

  personalTabColor: TIconColor = 'grey-500';
  sharedTabColor: TIconColor = 'grey-500';

  constructor(
    private workspaceService: WorkspaceService,
    private translationPipe: TranslationPipe,
  ) {}

  ngOnInit(): void {
    this.sharedViewsClickable();
    this.updateTabColor(this.ppSelectedOption);
  }

  onTabHover(tab: ESavedView.PERSONAL | ESavedView.SHARED, hover: boolean): void {
    switch (tab) {
      case ESavedView.PERSONAL:
        if (this.ppSelectedOption === ESavedView.PERSONAL) {
          this.personalTabColor = 'primary-600';
        } else if (hover) {
          this.personalTabColor = 'primary-500';
        } else {
          this.personalTabColor = 'grey-500';
        }
        break;
      case ESavedView.SHARED:
        if (this.ppSelectedOption === ESavedView.SHARED) {
          this.sharedTabColor = 'primary-600';
        } else if (hover && this.enableSharedViews) {
          this.sharedTabColor = 'primary-500';
        } else {
          this.sharedTabColor = 'grey-500';
        }
    }
  }

  updateTabColor(tab: ESavedView.PERSONAL | ESavedView.SHARED): void {
    if (tab === ESavedView.SHARED) {
      this.personalTabColor = 'grey-500';
      this.sharedTabColor = 'primary-600';
    } else {
      this.personalTabColor = 'primary-600';
      this.sharedTabColor = 'grey-500';
    }
  }

  selectOption(option: ESavedView): void {
    this.ppSelectedOption = option;
    this.ppSelectedOptionChange.emit(option);
    option === ESavedView.PERSONAL
      ? this.updateTabColor(ESavedView.PERSONAL)
      : this.updateTabColor(ESavedView.SHARED);
  }

  sharedViewsClickable(): void {
    const workspaceData = this.workspaceService.getWorkspace(this.ppWorkspaceId);

    if (
      workspaceData.share?.shareOption === EUserRole.SITE_ADMIN ||
      workspaceData.share?.shareOption === EUserRole.ACCOUNT_ADMIN ||
      workspaceData.share?.shareOption === EUserRole.OWNER ||
      this.ppSavedViews.shared.views.length > 0
    ) {
      this.enableSharedViews = true;
      this.savedViewsButtonCopy = '';
    } else {
      this.enableSharedViews = false;
      this.savedViewsButtonCopy = this.translationPipe.transform('no_shared_views');
    }
  }
}
