<div class="accountName__wrapper">
  <div
    #accountNameInput
    [contentEditable]="editing"
    id="accountNameInput"
    class="accountSettings__acc accountName__text"
    name="accountNameInput"
    (keydown.enter)="blurInput($event)"
    (blur)="updateAccount($event)"
  >
    {{ accountName }}
  </div>

  <button (click)="startEditing()" *ngIf="!ppDisabled">
    <img class="accountName__editIcon" [src]="EIconPath.INTERACTION_EDIT" />
  </button>
</div>
