export function getIsBasicFieldName(name: string): boolean {
  const basicFieldNames = [
    'p',
    's',
    'priority',
    'status',
    'id',
    'title',
    'description',
    'created',
    'updated',
    'tags',
    'site',
    'account',
    'created by',
    'assignees',
    'flagged',
    'flag',
  ];

  return basicFieldNames.includes(name.toLowerCase());
}
