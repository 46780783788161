import {
  AfterViewChecked,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, of } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import { SetActiveAccountId, SetActiveWorkspaceId } from '../../../services/active/active.actions';
import { SetBasicFetched } from '../../offline/sync.actions';
import { TPointsByWorkspace } from '../../points/points.model';
import { TUISettings } from '../../ui/ui.model';
import { TUser } from '../../user/user.model';
import { TWorkspace, TWorkspacesById } from '../../workspace/workspace.model';

import { SiteFilterDataService } from 'src/app/project/modules/filters/site-filter-data-service/site-filter-data.service';
import { SidePanelService } from '../../layout/side-panel/side-panel.service';
import { SyncService } from '../../offline/sync.service';
import { EditPointService } from '../../points/point-full-modal/edit-point.service';
import { PointHalfModalService } from '../../points/point-half-modal/point-half-modal.service';
import { PointsFetchingService } from '../../points/points-fetching.service';
import { UserService } from '../../user/user.service';
import { WorkspaceService } from '../../workspace/workspace.service';
import { SiteDataService, TSavedSites, TSiteData } from '../site-data.service';
import { SiteTableColumnsService } from '../site-table/site-table-columns.service';
import { SiteService } from '../site.service';

import { Title } from '@angular/platform-browser';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { SelectableFiltersService } from 'src/app/project/modules/filters/update-selectable-filters/selectable-filters.service';
import { TSidePanel } from 'src/app/project/modules/layout/side-panel/side-panel.model';
import { EPlanModule } from 'src/app/project/modules/plan/plan-module.enum';
import { ActiveWorkspaceService } from 'src/app/project/modules/user/active-workspace.service';
import { EWorkspaces } from 'src/app/project/modules/workspace/workspaces.enum';
import { ScreenService } from '../../../../core/services/window/screen.service';
import { EStore } from '../../../shared/enums/store.enum';
import { SiteRedirectService } from '../../auth/site-redirect.service';
import { FilterAssetService } from '../../preferences/filter-asset.service';
import { SetSitePlanWidth } from '../../ui/ui.actions';
import { CustomTableService } from '../site-table/custom-table/custom-table.service';
import { getSitePlanResizedWidth } from './utils/getSitePlanResizedWidth';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss'],
})
export class SiteComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('body') siteBody: ElementRef;

  site: TSiteData = this.siteDataService.getSite();
  sidePanel: TSidePanel = this.sidePanelService.getSidePanel();

  workspaceId: string = null;
  dataFetched = this.siteService.getFetched();
  tableFetched = this.siteService.getTableFetched();

  workspace: TWorkspace;
  private points$: Observable<TPointsByWorkspace>;
  private ui$: Observable<TUISettings>;
  ui: TUISettings;
  private user: TUser;
  EPlanModule = EPlanModule;
  processing = false;

  siteElementSizes = {
    siteTableWidthPercentage: null,
    sitePlanWidth: null,
  };

  private workspaces$: Observable<TWorkspacesById>;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store<{
      ui: TUISettings;
      points: TPointsByWorkspace;
      workspaces: TWorkspacesById;
    }>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private workspaceService: WorkspaceService,
    private siteDataService: SiteDataService,
    private siteTableColumnsService: SiteTableColumnsService,
    private updateSelectableFiltersService: SelectableFiltersService,
    private sidePanelService: SidePanelService,
    private pointHalfModalService: PointHalfModalService,
    private editPointService: EditPointService,
    private siteService: SiteService,
    private syncService: SyncService,
    private pointsFetchingService: PointsFetchingService,
    private siteFilterDataService: SiteFilterDataService,
    private screenService: ScreenService,
    private titleService: Title,
    private activeWorkspaceService: ActiveWorkspaceService,
    private customTableService: CustomTableService,
    private siteRedirectService: SiteRedirectService,
    private filterAssetService: FilterAssetService,
  ) {
    this.points$ = this.store.pipe(select(EStore.POINTS));
    this.ui$ = this.store.pipe(select(EStore.UI));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    const savedSites = this.siteDataService.getSitePlanFromLocalStorage();
    this.updateSitePlanVisibility(savedSites);
  }

  ngOnInit() {
    this.titleService.setTitle('Loading... | Pinpoint Works');

    this.screenService
      .observeOn$(['isDesktop', 'isLandscape'])
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ isDesktop, isLandscape }) => {
        const planFullWidth = !isDesktop || isLandscape;
        this.siteDataService.setSitePlanFullWidth(planFullWidth);
      });

    this.siteService.setTableFetched(false);
    this.sidePanelService.initSidePanel({ expandedInitially: false });

    const screenState = this.screenService.getCurrentState();

    if (!screenState.isDesktop || screenState.isLandscape) {
      this.siteDataService.setSitePlanVisibility({
        sitePlanVisible: false,
        sitePlanFullWidth: true,
      });
    }

    this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.workspaceId = params.get('id');
      this.store.dispatch(new SetActiveWorkspaceId(this.workspaceId));

      if (this.router.url.startsWith('/site') && !this.router.url.startsWith('/site/timeline')) {
        this.siteService.setFetched(false);
      } else {
        this.siteService.setFetched(true);
      }
    });

    this.syncService
      .firstLoad()
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.user = this.userService.getUser();

          this.activatedRoute.paramMap
            .pipe(takeUntil(this.destroy$))
            .subscribe((params) => this.processParamsResponse(params));
        }),
      )
      .subscribe();

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspace = workspaces[this.workspaceId];

      this.updateTitle();
    });

    this.ui$.pipe(takeUntil(this.destroy$)).subscribe((ui) => {
      this.ui = ui;

      if (this.workspaceId && this.ui.sitePlanWidth[this.workspaceId]) {
        this.siteElementSizes.sitePlanWidth = +this.ui.sitePlanWidth[this.workspaceId]
          ? +this.ui.sitePlanWidth[this.workspaceId]
          : 50;
      }
    });
  }

  ngAfterViewChecked() {
    this.siteService.emitSiteViewChecked();
  }

  ngOnDestroy() {
    this.siteDataService.clearSite();
    this.pointHalfModalService.hideModal();
    this.editPointService.hideModal();
    this.siteService.setFetched(true);
    this.destroy$.next();
  }

  updateTitle(): void {
    if (this.workspace && this.workspaceId) {
      this.titleService.setTitle(
        `${this.workspace.accountName} — ${this.workspace.siteName} | Pinpoint Works`,
      );
    }
  }

  openUserSettings(): void {
    this.router.navigate(['/settings/user']);
  }

  processParamsResponse(params: ParamMap): void {
    this.workspaceId = params.get('id');
    this.store.dispatch(new SetActiveWorkspaceId(this.workspaceId));

    if (this.workspaceId === EWorkspaces.OVERVIEW || this.workspaceId === EWorkspaces.TIMELINE) {
      return;
    }

    if (!this.workspaceId && this.user.activeWorkspaceId) {
      this.router.navigate(['/site', this.user.activeWorkspaceId]);
      this.workspaceId = this.user.activeWorkspaceId;

      return;
    }

    const currentWorkspace = this.workspaceService.findWorkspace(this.workspaceId);
    const savedSites = this.siteDataService.getSitePlanFromLocalStorage();
    const table = this.customTableService.getTable();

    if (this.workspaceId !== this.user.activeWorkspaceId && currentWorkspace) {
      this.activeWorkspaceService.updateActiveWorkspaceId(this.workspaceId);
    }

    this.siteService.setTableFetched(false);
    this.siteService.setFetched(false);

    if (table) {
      // activated route can trigger before anything is loaded
      table.updatePoints([]);
    }

    this.loadWorkspace(savedSites);
    this.updatePlanWidth();
  }

  loadWorkspace(savedSites: TSavedSites): void {
    this.processing = true;

    this.workspaceService
      .generateWorkspace(this.workspaceId)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.workspace = this.workspaceService.findWorkspace(this.workspaceId);

          this.store.dispatch(new SetActiveAccountId(this.workspace.accountId));
          this.store.dispatch(new SetActiveWorkspaceId(this.workspaceId));

          this.updateTitle();
          this.updateFilters();
          this.siteTableColumnsService.initColumns();
          this.fetchPoints(savedSites);
        }),
        catchError((error) => {
          if (error.status === EStatusCode.FORBIDDEN) {
            this.siteRedirectService.redirectToSites();
          }

          return of();
        }),
      )
      .subscribe();
  }

  onPlanResizerDrag(resizerClientX: number): void {
    this.siteElementSizes.sitePlanWidth = getSitePlanResizedWidth(
      resizerClientX,
      this.siteBody.nativeElement.clientWidth,
    );
  }

  onPlanResizerDrop(): void {
    this.store.dispatch(
      new SetSitePlanWidth({
        workspaceId: this.workspaceId,
        width: this.siteElementSizes.sitePlanWidth.toString(),
      }),
    );
    this.siteService.saveUpdatedSitePlanWidth(
      this.ui.sitePlanWidth,
      this.workspaceId,
      this.siteElementSizes.sitePlanWidth,
    );
  }

  updatePlanWidth(): void {
    if (this.workspaceId && this.ui.sitePlanWidth[this.workspaceId]) {
      this.siteElementSizes.sitePlanWidth = +this.ui.sitePlanWidth[this.workspaceId]
        ? +this.ui.sitePlanWidth[this.workspaceId]
        : 50;
    } else if (this.workspaceId && !this.ui.sitePlanWidth[this.workspaceId]) {
      this.siteElementSizes.sitePlanWidth = null;
    }
  }

  fetchPoints(savedSites: TSavedSites): void {
    this.pointsFetchingService
      .fetchPoints(this.workspaceId, { forceFetch: true })
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.updateSitePlanVisibility(savedSites);
          this.siteService.setFetched(true);
          this.store.dispatch(new SetBasicFetched(true));
          this.siteFilterDataService.createCustomFieldFilters();
          this.filterAssetService.setOpeningSiteFromAsset(false, null, false);
          this.processing = false;
        }),
        catchError((error) => {
          logErrorInSentry(error);

          this.siteService.setFetched(false);

          return of();
        }),
      )
      .subscribe();
  }

  updateFilters(): void {
    this.updateSelectableFiltersService.updateSelectableTags();
    this.updateSelectableFiltersService.updateSelectableExcludedTags();
  }

  checkIfPointOpened(): boolean {
    return this.router.url.includes('point');
  }

  private updateSitePlanVisibility(savedSites: TSavedSites): void {
    const { isDesktop, isLandscape } = this.screenService.getCurrentState();

    if (isDesktop && !isLandscape && typeof this.workspace?.sitePlan !== 'undefined') {
      if (savedSites && savedSites[this.workspaceId]) {
        const sitePlanVisible = savedSites[this.workspaceId].sitePlanVisible;

        this.siteDataService.setSitePlanVisibility({
          sitePlanVisible: sitePlanVisible && !!this.workspace?.sitePlan,
          sitePlanFullWidth: false,
        });
      } else {
        this.siteDataService.setSitePlanVisibility({
          sitePlanVisible: !!this.workspace?.sitePlan,
          sitePlanFullWidth: false,
          workspaceId: this.workspaceId,
        });
      }
    }
  }
}
