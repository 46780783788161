<div class="filterDropdown__section filterDropdown__section--priority">
  <div class="filterDropdown__heading">
    <h6>{{ 'priority' | ppTranslate }}</h6>
    <button
      class="filterDropdown__clear--text"
      *ngIf="!isPriorityDefault"
      (click)="clearPriority()"
    >
      {{ 'clear' | ppTranslate }}
    </button>
  </div>

  <div
    class="filterDropdown__content filterDropdown__checkbox"
    *ngFor="let priority of ppFilters.priority"
  >
    <pp-checkbox
      [ppChecked]="priority.value"
      (ppOnValueChange)="updatePriorityValue(priority)"
      class="priorityCheckbox"
      ppType="solid"
    >
      <pp-priority-button [ppPriority]="priority.label"></pp-priority-button>
    </pp-checkbox>
  </div>

  <hr />
</div>
