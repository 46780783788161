import { GET_TABLE } from '../../../table.ui.store';
import { createElement } from 'src/app/core/helpers/dom';
import { ERowType } from 'src/app/project/shared/enums/row-type.enum';
import { EIconPath } from '../../../../../../shared/enums/icons.enum';
import { createHeaderCellElement } from './create-header-cell-element';
import { getSelectElementWidthPx } from '../../table/utils/select-element-width';
import { GET_GROUPING } from '../../../columns/grouping.store';

export function createHeaderSelectElement(): HTMLElement {
  const table = GET_TABLE();
  const grouping = GET_GROUPING();

  const allSelected = table.selectedPoints.size === table.points.length && table.points.length > 0;

  const selectElement = createElement<HTMLElement>('span', {
    attrs: {
      class:
        'customSiteTable__checkbox__container customSiteTable__checkbox__container radial-out radial-out--header',
      id: 'sortingElement',
    },
    children: [
      createElement<HTMLElement>('img', {
        attrs: {
          class: allSelected
            ? 'customSiteTable__checkbox customSiteTable__checkbox--selected'
            : ' customSiteTable__checkbox',
          src: allSelected
            ? EIconPath.ICON_CHECHMARK_TABLE_SMALL
            : EIconPath.ICON_CHECHMARK_TABLE_FALSE,
        },
      }),
    ],
  });

  const className = 'table__select table__head__select';

  return createHeaderCellElement({
    className,
    center: true,
    width: getSelectElementWidthPx(grouping.length),
    resize: false,
    callback: () => {
      let selectAll = false;

      const element = selectElement.children[0] as HTMLImageElement;

      if (table.selectedPoints.size === table.points.length) {
        table.tableHead.allSelected = false;
        element.src = EIconPath.ICON_CHECHMARK_TABLE_FALSE;

        element.classList.remove('customSiteTable__checkbox--selected');
      } else {
        table.tableHead.allSelected = true;
        selectAll = true;
        element.src = EIconPath.ICON_CHECHMARK_TABLE_SMALL;
        element.classList.add('customSiteTable__checkbox--selected');
      }

      table.points.forEach((_point, _index) => {
        if (table.tableHead.allSelected) {
          table.selectedPoints.add(_point._id);
        } else {
          table.selectedPoints.delete(_point._id);
        }
      });

      table.selectAllPointsCallback(selectAll);
      table.sortTable();
    },
    child: selectElement,
  });
}
