import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
} from '@angular/core';

import { GalleryOverlayService } from 'src/app/project/components/gallery-overlay/gallery-overlay.service';
import { AttachmentsMediaService } from 'src/app/project/modules/points/attachments/attachments-media.service';
import { PointsFetchingService } from 'src/app/project/modules/points/points-fetching.service';
import { AttachmentsService } from '../../../modules/points/attachments/attachments.service';
import { RequestService } from '../../../services/requests/request.service';
import { PromptService } from '../../prompt/prompt.service';
import { ImageRotationService } from './image-rotation.service';

import { Subject, merge } from 'rxjs';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { TranslationPipe } from '../../../features/translate/translation.pipe';
import { EIconPath } from '../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-image-rotation',
  templateUrl: './image-rotation.component.html',
  styleUrls: ['./image-rotation.component.scss'],
})
export class ImageRotationComponent implements OnInit, OnChanges, OnDestroy {
  @Output() ppRotationAngleChanged = new EventEmitter();
  @Output() ppImageIdChanged = new EventEmitter();
  @Input() ppImageId: string = null;
  @Input() ppOverlayIndex: number = null;
  @Input() ppRotationAngle: number = null;
  @Input() ppImageName = '';
  @Input() ppImageMimeType = '';
  @Input() ppImageCreatedOn = '';
  @Input() ppPointId = '';

  private readonly destroy$ = new Subject<void>();
  private readonly imageRotationUpdate$ = new Subject<void>();
  private readonly stopImageRotationUpdate$ = new Subject<void>();
  private imageRotationUpdateDebounceTimeMs = 1000;

  private lastUpdatedRotationAngle: number = null;

  rotationAngle: number = this.ppRotationAngle ? this.ppRotationAngle : 0;
  serverRotationAngle = 0;
  removeImageIdEventEmitter = false;
  EIconPath = EIconPath;

  constructor(
    private imageRotationService: ImageRotationService,
    private promptService: PromptService,
    private attachmentsService: AttachmentsService,
    private requestService: RequestService,
    private galleryOverlayService: GalleryOverlayService,
    private translationPipe: TranslationPipe,
    private pointsFetchingService: PointsFetchingService,
    private attachmentsMediaService: AttachmentsMediaService,
  ) {}

  ngOnInit(): void {
    this.createImageRotationUpdateDebounce();
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (changes.ppImageId && !changes.ppImageId.isFirstChange()) {
      if (this.serverRotationAngle !== 0) {
        const imageId = changes.ppImageId.previousValue;

        this.updateImageRotation(imageId, this.serverRotationAngle, this.ppPointId);
      }

      this.rotationAngle = this.ppRotationAngle ? this.ppRotationAngle : 0;
      this.serverRotationAngle = 0;
      this.lastUpdatedRotationAngle = null;
    }
  }

  ngOnDestroy() {
    if (this.rotationAngle !== 0) {
      this.updateImageRotation(this.ppImageId, this.serverRotationAngle, this.ppPointId);
    }

    this.removeImageIdEventEmitter = true;
  }

  rotate(): void {
    const isRequestInProcess = this.requestService.isRequestInProcess(this.ppImageId);

    if (isRequestInProcess) {
      this.showInProgressWarning();

      return;
    }

    logEventInGTAG(EGoogleEventSite.SITE__GALLERY__ROTATE, {
      event_category: EGoogleEventCategory.SITE,
    });

    this.updateRotationAngles('clockwise');

    this.imageRotationUpdate$.next();
  }

  updateImageRotation(imageId: string, serverRotationAngle: number, _id: string): void {
    const attachment = this.attachmentsService.findMediaAttachment(imageId);

    if (attachment) {
      const isRequestInProcess = this.requestService.isRequestInProcess(imageId);

      if (isRequestInProcess) {
        this.showInProgressWarning();
        this.updateRotationAngles('counterclockwise');

        return;
      }

      this.stopImageRotationUpdate$.next();
      this.createImageRotationUpdateDebounce();

      if (
        !this.lastUpdatedRotationAngle ||
        (this.lastUpdatedRotationAngle && this.lastUpdatedRotationAngle !== this.rotationAngle)
      ) {
        if (this.serverRotationAngle !== 0) {
          this.lastUpdatedRotationAngle = this.rotationAngle;

          this.imageRotationService
            .updateImageRotation(imageId, serverRotationAngle, _id)
            .then((response) => {
              const rotatedImageId = response;
              this.galleryOverlayService.setTempRotation(this.rotationAngle);

              this.pointsFetchingService
                .fetchPoint(_id)
                .pipe(
                  takeUntil(this.destroy$),
                  switchMap(() =>
                    this.attachmentsMediaService.fetchMedia(_id).pipe(
                      tap(() => {
                        this.galleryOverlayService.updateRotatedImage(imageId, rotatedImageId);
                      }),
                    ),
                  ),
                )
                .subscribe();
            })
            .catch(() => {
              const prompt = this.translationPipe.transform('prompt_attachments_rotation_failed');
              this.promptService.showError(prompt);
            });
        }
      }
    }
  }

  updateRotationAngles(direction: 'clockwise' | 'counterclockwise'): void {
    if (direction === 'clockwise') {
      if (this.rotationAngle === 270) {
        this.rotationAngle = 0;
      } else {
        this.rotationAngle += 90;
      }

      if (this.serverRotationAngle === 270) {
        this.serverRotationAngle = 0;
      } else {
        this.serverRotationAngle += 90;
      }
    } else {
      if (this.rotationAngle === 0) {
        this.rotationAngle = 270;
      } else {
        this.rotationAngle -= 90;
      }

      if (this.serverRotationAngle === 0) {
        this.serverRotationAngle = 270;
      } else {
        this.serverRotationAngle -= 90;
      }
    }

    this.ppRotationAngleChanged.next({
      angle: this.rotationAngle,
      index: this.ppOverlayIndex,
    });
  }

  private createImageRotationUpdateDebounce(): void {
    this.imageRotationUpdate$
      .pipe(
        takeUntil(merge(this.destroy$, this.stopImageRotationUpdate$)),
        debounceTime(this.imageRotationUpdateDebounceTimeMs),
        tap(() => {
          this.imageRotationUpdate();
        }),
      )
      .subscribe();
  }

  private imageRotationUpdate(): void {
    const isRequestInProcess = this.requestService.isRequestInProcess(this.ppImageId);

    if (isRequestInProcess) {
      this.showInProgressWarning();
      this.updateRotationAngles('counterclockwise');

      return;
    }

    this.updateImageRotation(this.ppImageId, this.serverRotationAngle, this.ppPointId);
  }

  private showInProgressWarning(): void {
    const promptText = this.translationPipe.transform('prompt_rotation_in_progress');

    this.promptService.showWarning(promptText);
  }
}
