import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, of, Subject, throwError } from 'rxjs';

import { UpdateAllPointIds, UpdatePointIds } from '../workspace/workspace.actions';
import {
  ClearPoints,
  DeletePoint,
  DeletePoints,
  SetAllPoints,
  SetPoints,
  UpdatePoint,
} from './points.actions';

import { TWorkspacesById } from '../workspace/workspace.model';
import { TPoint, TPointListByWorkspace, TPointsByWorkspace } from './points.model';

import { ActiveService } from '../../services/active/active.service';
import { ResponseErrorService } from '../errors/response-error.service';
import { OfflineService } from '../offline/offline.service';
import { WorkspaceService } from '../workspace/workspace.service';
import { PointsGenerateService } from './point-generate/points-generate.service';
import { PointsService } from './points.service';

import { Router } from '@angular/router';
import { PointsApiProviderService } from '@core/api';
import { TPointResponse } from '@project/view-models';
import { catchError, finalize, map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { EStore } from '../../shared/enums/store.enum';
import { ECustomFieldType } from '../custom-fields/custom-field-types-enums';
import { GET_CUSTOM_FIELDS } from '../custom-fields/custom-fields.store';
import { SitePointFilterService } from '../filters/site-point-filter.service';
import { getPointStatusesToFilter } from '../filters/utils/get-statuses-to-filter';
import { EWorkspaces } from '../workspace/workspaces.enum';
import { PointsPaginationService } from './points-pagination.service';
import { removeSelectedPoint } from './selected-points';

@Injectable({
  providedIn: 'root',
})
export class PointsFetchingService {
  private clear$ = new Subject<void>();

  private points$: Observable<TPointResponse[]>;
  private allPoints$: Observable<TPointResponse[]>;

  private pointsFetched = false;
  private pointsFetching = false;

  private allPointsFetching = false;
  private workspaces$: Observable<TWorkspacesById>;
  private workspaces: TWorkspacesById;

  constructor(
    private store: Store<{
      points: TPointsByWorkspace;
      workspaces: TWorkspacesById;
    }>,
    private offlineService: OfflineService,
    private pointsGenerateService: PointsGenerateService,
    private responseErrorService: ResponseErrorService,
    private workspaceService: WorkspaceService,
    private pointsService: PointsService,
    private activeService: ActiveService,
    private router: Router,
    private pointsApiProviderService: PointsApiProviderService,
    private pointsPaginationService: PointsPaginationService,
    private sitePointFilterService: SitePointFilterService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.workspaces$.subscribe((workspaces) => {
      this.workspaces = workspaces;
    });
  }

  fetchPoint(_id: string): Observable<TPoint> {
    return this.pointsApiProviderService.fetchPoint(_id).pipe(
      map((response) => {
        const point = this.pointsGenerateService.generatePoint(response);

        this.store.dispatch(
          new UpdatePoint({
            workspaceId: point.workspaceRef.id,
            point,
          }),
        );

        return point;
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  fetchPoints(
    workspaceId: string,
    {
      forceFetch = false,
    }: {
      forceFetch?: boolean;
    } = {},
  ): Observable<TPointResponse[]> {
    if (!this.pointsFetching || forceFetch) {
      this.pointsFetching = true;

      this.points$ = this.pointsPaginationService.fetchPoints(workspaceId).pipe(
        tap((response) => this.updatePointsIdsStore(workspaceId, response)),
        shareReplay(),
        catchError(this.responseErrorService.handleRequestError),
        finalize(() => {
          this.pointsFetching = false;
        }),
      );
    }

    return this.points$;
  }

  updatePointsIdsStore(_workspaceId: string, _response: TPointResponse[]): void {
    const points = _response ? _response : [];

    this.store.dispatch(
      new SetPoints({
        workspaceId: _workspaceId,
        points,
      }),
    );

    const pointIds = points.map(({ _id }) => _id);

    this.store.dispatch(
      new UpdatePointIds({
        workspaceId: _workspaceId,
        pointIds,
      }),
    );
  }

  fetchAllPoints({
    timeline = false,
    accounts = [],
    customFields = [],
    filtering = false,
  }: {
    timeline?: boolean;
    accounts?: string[];
    customFields?: string[];
    filtering?: boolean;
  } = {}): Observable<TPointResponse[]> {
    const offline = this.offlineService.getOffline();

    if (offline) {
      if (this.pointsService.getAllPoints().length) {
        if (this.workspaces) {
          this.updateStoreWithLastPointsData();
        }

        return of(null);
      }

      return throwError(null);
    }

    if (
      !this.allPointsFetching // TODO Add !this.allPointsFetched &&
    ) {
      this.allPointsFetching = true;

      const statuses = getPointStatusesToFilter(
        this.sitePointFilterService.allFilters.overview?.status,
      );

      this.allPoints$ = this.pointsPaginationService
        .fetchAllPoints(accounts, customFields, statuses, filtering)
        .pipe(
          takeUntil(this.clear$),
          tap((response) => {
            if (!timeline || (timeline && this.router.url.includes(EWorkspaces.TIMELINE))) {
              this.storePointsData(response, timeline);
            }
          }),
          shareReplay(),
          catchError(this.responseErrorService.handleRequestError),
          finalize(() => {
            this.allPointsFetching = false;
          }),
        );
    }

    return this.allPoints$;
  }

  fetchPointsByIds(pointList: string[]): Observable<TPointResponse[]> {
    if (!pointList.length) {
      return of(null);
    }
    return this.pointsApiProviderService.fetchPointsById(pointList).pipe(
      tap((response) => {
        this.storePointsData(response);
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  clearPoints(): void {
    this.pointsFetching = false;
    this.pointsFetched = false;
    this.allPointsFetching = false;

    this.clear$.next();

    this.store.dispatch(new ClearPoints());
  }

  deletePoint(_id: string): Observable<null> {
    const workspaceId = this.activeService.getActiveWorkspaceId();

    return this.pointsApiProviderService.deletePoint(_id).pipe(
      tap(() => {
        removeSelectedPoint(_id);

        this.store.dispatch(
          new DeletePoint({
            workspaceId,
            _id,
          }),
        );
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  deletePoints(pointIds: string[]): Observable<null> {
    const workspaceId = this.activeService.getActiveWorkspaceId();

    return this.pointsApiProviderService.deletePoints(pointIds).pipe(
      tap(() => {
        pointIds.forEach((id) => removeSelectedPoint(id));

        this.store.dispatch(
          new DeletePoints({
            workspaceId,
            pointIds,
          }),
        );
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  private getPointsWithTimeline(response: TPointResponse[]): TPointResponse[] {
    return response.filter((point) => {
      // only points with timeline value filled are displayed
      for (let i = 0; i < Object.keys(point.customFieldsMap).length; i++) {
        const pointCustomField = point.customFieldsMap[Object.keys(point.customFieldsMap)[i]];
        const customField = GET_CUSTOM_FIELDS()[pointCustomField.customFieldTemplateId];

        if (customField.type === ECustomFieldType.TIMELINE && pointCustomField.value) {
          return true;
        }
      }

      return false;
    });
  }

  private storePointsData(response: TPointResponse[], withTimeline: boolean = false): void {
    const points = withTimeline ? this.getPointsWithTimeline(response) : response;

    const workspaces = this.workspaceService.getWorkspaces();
    const pointsByWorkspace: TPointListByWorkspace = {};
    const pointIdsByWorkspace = {};

    Object.keys(workspaces).forEach((workspaceId) => {
      pointsByWorkspace[workspaceId] = [];
      pointIdsByWorkspace[workspaceId] = [];
    });

    points.forEach((point) => {
      if (!pointsByWorkspace[point.workspaceRef.id]) {
        pointsByWorkspace[point.workspaceRef.id] = [];
      }

      if (!pointIdsByWorkspace[point.workspaceRef.id]) {
        pointIdsByWorkspace[point.workspaceRef.id] = [];
      }

      pointsByWorkspace[point.workspaceRef.id].push(point);
      pointIdsByWorkspace[point.workspaceRef.id].push(point._id);
    });

    this.store.dispatch(
      new SetAllPoints({
        pointsByWorkspace,
      }),
    );

    this.store.dispatch(
      new UpdateAllPointIds({
        pointIdsByWorkspace,
      }),
    );
  }

  private updateStoreWithLastPointsData(): void {
    const points = this.pointsService.getAllPoints();
    const pointIds = [];
    const allPoints = [];
    const pointsByWorkspace = {};
    const pointIdsByWorkspace: {
      [workspaceId: string]: string[];
    } = {};

    Object.keys(this.workspaces).forEach((workspaceId) => {
      pointsByWorkspace[workspaceId] = [];
      pointIdsByWorkspace[workspaceId] = [];
    });

    Object.keys(points).forEach((workspaceId) => {
      Object.keys(points[workspaceId]).forEach((_id) => {
        const point = points[workspaceId][_id];

        if (!pointsByWorkspace[point.workspaceRef.id]) {
          pointsByWorkspace[point.workspaceRef.id] = [];
        }

        if (!pointIdsByWorkspace[point.workspaceRef.id]) {
          pointIdsByWorkspace[point.workspaceRef.id] = [];
        }

        pointsByWorkspace[point.workspaceRef.id].push(point);
        pointIdsByWorkspace[point.workspaceRef.id].push(point._id);
      });
    });

    Object.keys(points).forEach((workspaceId) => {
      allPoints.push(points[workspaceId]);
    });

    allPoints.forEach((point) => {
      pointIds.push(point._id);
    });

    this.store.dispatch(
      new UpdateAllPointIds({
        pointIdsByWorkspace,
      }),
    );
  }
}
