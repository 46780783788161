import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EActivityPropName } from '../../../../../../shared/enums/activity-prop-name.enum';
import { TAttachment } from '../../../../attachments/attachments.model';

@Component({
  selector: 'pp-attachment-added-activity',
  templateUrl: './attachment-added-activity.component.html',
  styleUrls: ['../activity-change.component.scss'],
})
export class AttachmentAddedActivityComponent {
  @Input() ppChange;
  @Input() ppMediaAttachments: {
    [key: string]: TAttachment;
  };
  @Output() ppOpenGallery = new EventEmitter();

  EActivityPropName = EActivityPropName;
  EIconPath = EIconPath;

  constructor() {}

  openGallery(imageId: string): void {
    this.ppOpenGallery.emit(imageId);
  }
}
