import { GET_TABLE } from '../../../../table.ui.store';
import { createElement } from 'src/app/core/helpers/dom';
import { GET_GROUPING } from '../../../../columns/grouping.store';
import { GET_COLUMNS } from '../../../../columns/columns.store';
import { getMarginOffset } from './get-margin-offset';
import { createHeaderRowCellTypeElement } from '../create-header-row-cell-type-element';

export function createColumnCellsElement(index: number, groupingLevel: number): HTMLElement {
  const table = GET_TABLE();
  const columns = GET_COLUMNS();
  const offsetX = table.tableBody.virtualScroller.offsetX;
  const visibleXIndexes = table.tableBody.virtualScroller.visibleXIndexes;
  const children = [];
  const grouping = GET_GROUPING();

  visibleXIndexes.forEach((_visibleXIndex) => {
    const columnIndex = table.visibleColumnIndexes[_visibleXIndex];

    if (columns[columnIndex]) {
      children.push(createHeaderRowCellTypeElement(index, columns[columnIndex], groupingLevel));
    }
  });

  const marginOffset = getMarginOffset(grouping, groupingLevel);

  const columnCellsClass =
    'table__columnCells' +
    ((groupingLevel === 2 && grouping.length === 2) ||
    (groupingLevel === 1 && grouping.length === 1)
      ? ' table__columnCells--grouped--2'
      : '');

  return createElement('div', {
    attrs: {
      class: columnCellsClass,
      style: {
        transform: `translateX(${offsetX}px)`,
        marginLeft: `${marginOffset}px`,
      },
    },
    children,
  });
}
