import { Component, Input } from '@angular/core';
import { TWhiteLabelStore } from '../../white-label.model';

@Component({
  selector: 'pp-white-label-preview',
  templateUrl: './white-label-preview.component.html',
  styleUrls: ['./white-label-preview.component.scss'],
})
export class WhiteLabelPreviewComponent {
  @Input() ppWhiteLabel: TWhiteLabelStore;
  @Input() ppWebLogoImages: File[];
  @Input() ppWebLogoData: string;

  logoSrc: string = null;
  logoBuffer: string | ArrayBuffer = null;

  ngOnChanges() {
    if (this.ppWebLogoImages) {
      const reader = new FileReader();

      reader.onload = (): void => {
        this.logoBuffer = reader.result;
        this.logoSrc = null;
      };

      reader.readAsDataURL(this.ppWebLogoImages[0]);
    } else if (this.ppWebLogoData) {
      this.logoSrc = 'api/v1/images/' + this.ppWebLogoData + '/file/size/bounded/400';
      this.logoBuffer = null;
    } else {
      this.logoSrc = null;
      this.logoBuffer = null;
    }
  }
}
