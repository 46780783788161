import { Injectable } from '@angular/core';

export type TColumnsData = {
  data: TSingleColumnData[];
  colWidths: number[];
  hidden: number[];
  sorting: {
    column: number;
    sortOrder: 'DESC' | 'ASC';
  };
  default: {
    name: string;
  }[];
};

export type TSingleColumnData = {
  name: string;
  hidden?: boolean;
  width?: number;
  index?: number;
  sortOrder?: 'DESC' | 'ASC';
  workspaceId?: string;
  fieldId?: string;
};

@Injectable({
  providedIn: 'root',
})
export class SiteTableColumnsDataService {
  private columns: TColumnsData;
  private columnsAll: TColumnsData;

  constructor() {
    this.columns = {
      data: [],
      colWidths: [],
      hidden: [],
      sorting: {
        column: 6,
        sortOrder: 'DESC',
      },
      default: [
        { name: 'Priority' },
        { name: 'Status' },
        { name: 'ID' },
        { name: 'Title' },
        { name: 'Description' },
        { name: 'Created' },
        { name: 'Updated' },
        { name: 'Created By' },
        { name: 'Assignees' },
        { name: 'Tags' },
      ],
    };

    this.columnsAll = {
      data: [],
      colWidths: [],
      hidden: [],
      sorting: {
        column: 8,
        sortOrder: 'DESC',
      },
      default: [
        { name: 'Account' },
        { name: 'Site' },
        { name: 'Priority' },
        { name: 'Status' },
        { name: 'ID' },
        { name: 'Title' },
        { name: 'Description' },
        { name: 'Created' },
        { name: 'Updated' },
        { name: 'Created By' },
        { name: 'Assignees' },
        { name: 'Tags' },
      ],
    };
  }

  getColumns(): TColumnsData {
    return this.columns;
  }

  getAllColumns(): TColumnsData {
    return this.columnsAll;
  }

  setColumns(...args: Partial<TColumnsData>[]): TColumnsData {
    args.forEach((arg) => {
      Object.keys(arg).forEach((key) => {
        this.columns[key] = arg[key];
      });
    });

    return this.getColumns();
  }

  setAllColumns(...args: Partial<TColumnsData>[]): TColumnsData {
    args.forEach((arg) => {
      Object.keys(arg).forEach((key) => {
        this.columnsAll[key] = arg[key];
      });
    });

    return this.getAllColumns();
  }

  clearColumns({
    all = true,
    data = false,
    totalCost = false,
  }: {
    all?: boolean;
    data?: boolean;
    totalCost?: boolean;
  } = {}): TColumnsData {
    if (data || totalCost) {
      all = false;
    }

    if (all || data) {
      this.columns.data.length = 0;
      this.columns.colWidths.length = 0;
      this.columns.hidden.length = 0;
      this.columns.sorting.column = 6;
      this.columns.sorting.sortOrder = 'DESC';
    }

    return this.getColumns();
  }
}
