import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange } from '@angular/core';

import { TDashlet } from 'src/app/project/modules/preferences/preferences.model';

import { PromptService } from '../../../../components/prompt/prompt.service';
import { DashletEventsService } from './dashlet-events.service';

import { of, Subject } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { generateErrorPrompt } from 'src/app/project/modules/errors/response-error';
import { ResponseErrorService } from 'src/app/project/modules/errors/response-error.service';
import { EDashletPeriod } from '../../dashboard-timeframes-enums';
import { EDashletType } from '../../dashlets-enums';

@Component({
  selector: 'pp-dashlet-events',
  templateUrl: './dashlet-events.component.html',
  styleUrls: ['./dashlet-events.component.scss'],
})
export class DashletEventsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() ppDashlet: TDashlet;
  @Input() ppPeriod: string;
  @Input() ppAccountId: string;
  @Input() ppWorkspaceIds: string[];
  @Input() ppLoading: boolean;

  private readonly destroy$ = new Subject<void>();

  eventsNumber: number;
  dashletPeriods = EDashletPeriod;
  dashletTypes = EDashletType;

  constructor(
    private promptService: PromptService,
    private dashletEventsService: DashletEventsService,
    private responseErrorService: ResponseErrorService,
  ) {}

  ngOnInit() {
    this.eventsNumber = null;
    this.ppLoading = false;

    this.fetchEvents();
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (
      (changes.ppPeriod && !changes.ppPeriod.isFirstChange()) ||
      (changes.ppAccountId && !changes.ppAccountId.isFirstChange()) ||
      (changes.ppWorkspaceIds && !changes.ppWorkspaceIds.isFirstChange())
    ) {
      this.fetchEvents();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  fetchEvents(): void {
    this.ppLoading = true;

    this.dashletEventsService
      .fetchEvents({
        type: this.ppDashlet.name,
        period: this.ppPeriod,
        accountId: this.ppAccountId,
        workspaceIds: this.ppWorkspaceIds,
      })
      .pipe(
        takeUntil(this.destroy$),
        tap((data) => {
          this.eventsNumber = data.numberOfEvents;
        }),
        catchError((error) => {
          const promptText = generateErrorPrompt(error, 'prompt_error');

          this.responseErrorService.errors(error);
          this.promptService.showError(promptText);

          return of();
        }),
        finalize(() => {
          this.ppLoading = false;
        }),
      )
      .subscribe();
  }
}
