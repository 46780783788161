import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TClassList } from '@core/helpers';
import { EIconPath } from '../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})
export class RadioComponent implements OnChanges {
  @Input() ppDisabled = false;
  @Input() ppChecked = false;
  @Input() ppNoMargin = false;
  @Input() ppSize: 'small' | 'medium' = 'medium';
  @Input() ppStyle = '';
  @Input() 'data-m-target' = '';
  @Output() ppOnValueChange = new EventEmitter();

  class: TClassList;
  EIconPath = EIconPath;

  ngOnChanges() {
    this.class = {
      'radio--small': this.ppSize === 'small',
      'radio--blue': this.ppStyle === 'blue' && !this.ppDisabled,
      'radio--disabled': this.ppDisabled,
      'radio--noMargin': this.ppNoMargin,
    };
  }

  toggleRadio(): void {
    if (!this.ppDisabled) {
      this.ppOnValueChange.emit();
    }
  }
}
