import Timeline from '../../Timeline';
import { GET_TIMELINE } from '../../timeline.ui.store';
import { timelineProperties, TIMELINE_TIMEOUT_DURATION } from './timeline-variables';

export function handleTimelineMousemove(_event: MouseEvent): void {
  const timeline = GET_TIMELINE();
  const rect = timeline.timelineBody.virtualScroller.element.getBoundingClientRect();
  const edgeSize = 100;
  const edgeLeft = rect.left + edgeSize;
  const edgeRight = rect.left + rect.width - edgeSize;
  const isInLeftEdge = _event.clientX < edgeLeft;
  const isInRightEdge = _event.clientX > edgeRight;

  if (!(isInLeftEdge || isInRightEdge)) {
    return;
  }

  const maxScrollX = getMaxScrollX(timeline, rect);

  if (timelineProperties.stationary) {
    if (timelineProperties.scrollInterval) {
      clearInterval(timelineProperties.scrollInterval);

      timelineProperties.scrollInterval = null;
    }

    timelineProperties.scrollInterval = setInterval(() => {
      if (!timelineProperties.active || !timelineProperties.stationary) {
        clearInterval(timelineProperties.scrollInterval);

        timelineProperties.scrollInterval = null;
      } else {
        adjustWindowScroll();
      }
    }, TIMELINE_TIMEOUT_DURATION * 2);
  } else {
    if (timelineProperties.scrollInterval) {
      clearInterval(timelineProperties.scrollInterval);

      timelineProperties.scrollInterval = null;
    }

    adjustWindowScroll();
  }

  function adjustWindowScroll(): boolean {
    const currentScrollX = timeline.timelineBody.virtualScroller.scrollElement.scrollLeft;
    const maxStep = 20;
    const canScrollLeft = currentScrollX > 0;
    const canScrollRight = currentScrollX < maxScrollX;
    let nextScrollX = currentScrollX;

    if (isInLeftEdge && canScrollLeft) {
      const intensity = (edgeLeft - _event.clientX) / edgeSize;
      nextScrollX = nextScrollX - maxStep * intensity;
    } else if (isInRightEdge && canScrollRight) {
      const intensity = (_event.clientX - edgeRight) / edgeSize;
      nextScrollX = nextScrollX + maxStep * intensity;
    }

    nextScrollX = Math.max(0, Math.min(maxScrollX, nextScrollX));

    if (nextScrollX !== currentScrollX) {
      timeline.timelineBody.virtualScroller.scrollLeft(nextScrollX);
      return true;
    }

    return false;
  }
}

function getMaxScrollX(timeline: Timeline, rect: DOMRect): number {
  const virtualScrollerWidth = Math.max(
    timeline.timelineBody.virtualScroller.element.scrollWidth,
    timeline.timelineBody.virtualScroller.element.offsetWidth,
    timeline.timelineBody.virtualScroller.element.clientWidth,
  );

  return virtualScrollerWidth - rect.width;
}
