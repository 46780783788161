import { createElement } from 'src/app/core/helpers/dom';
import { GET_GROUPING } from '../../../../columns/grouping.store';

export function createEmptyGroupLevelIndicator(groupLevel: number): HTMLElement {
  const grouping = GET_GROUPING();

  if (groupLevel > 0) {
    let style = {};

    return createElement('div', {
      attrs: {
        class: `table__groupLevelIndicator table__groupLevelIndicator--${groupLevel}--${grouping.length}`,
        style,
      },
    });
  } else if (grouping.length > 1) {
    return createElement('div', {
      attrs: {
        class: `table__groupLevelIndicator table__groupLevelIndicator--${groupLevel}--${grouping.length}`,
      },
    });
  } else {
    return null;
  }
}
