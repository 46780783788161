import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { TAllFilters, TFilters, TSelectableTags } from '../site-filter.model';

import { ActiveService } from 'src/app/project/services/active/active.service';
import { ClearFilterService } from '../clear-filter-service/clear-filter.service';
import { SiteFilterDataService } from '../site-filter-data-service/site-filter-data.service';
import { SelectableFiltersService } from '../update-selectable-filters/selectable-filters.service';

import { DOCUMENT } from '@angular/common';
import { SortingService } from '@core/helpers';
import { checkCustomWorkspaceId } from 'src/app/project/modules/workspace/workspace';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EStore } from '../../../shared/enums/store.enum';
import { TTagSelectOptions } from '../../tags/tag-select/tag-select.component';

@Component({
  selector: 'pp-filter-tags',
  templateUrl: './filter-tags.component.html',
  styleUrls: ['./filter-tags.component.scss'],
})
export class FilterTagsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() ppFilters: TFilters;
  @Output() ppOnValueChange = new EventEmitter();

  filters: TFilters;
  tagSelectOptionsForInclude: TTagSelectOptions;
  tagSelectOptionsForExclude: TTagSelectOptions;
  selectableTags: TSelectableTags;
  selectableExcludedTags: TSelectableTags;
  allTags: TSelectableTags;
  EIconPath = EIconPath;

  private readonly destroy$ = new Subject<void>();
  private dropdownVisible: boolean;
  private workspaces$ = new Observable<TWorkspacesById>();
  workspaceId: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<{
      siteFilter: TAllFilters;
      workspaces: TWorkspacesById;
    }>,
    private updateSelectableFiltersService: SelectableFiltersService,
    private siteFilterDataService: SiteFilterDataService,
    private sortingService: SortingService,
    private activeService: ActiveService,
    private clearFilterService: ClearFilterService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.filters = this.ppFilters;

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.workspaceId = this.activeService.getActiveWorkspaceId();

      if (!this.workspaceId) {
        this.workspaceId = checkCustomWorkspaceId();
      }
    });
  }

  ngOnInit() {
    this.dropdownVisible = false;

    this.selectableTags = this.siteFilterDataService.getSelectableTags();
    this.selectableExcludedTags = this.siteFilterDataService.getSelectableExcludedTags();

    this.allTags = this.siteFilterDataService.getAllTags();

    this.filterTags();
  }

  ngOnDestroy() {
    this.destroy$.next();

    this.document.removeEventListener('click', this.eventHandler);
  }

  ngAfterViewInit() {
    this.document.addEventListener('click', this.eventHandler);
  }

  ngOnChanges() {
    this.filters = this.ppFilters;

    this.selectableTags = this.siteFilterDataService.getSelectableTags();
    this.selectableExcludedTags = this.siteFilterDataService.getSelectableExcludedTags();

    this.allTags = this.siteFilterDataService.getAllTags();

    this.filterTags();
    this.setTagSelectOptions();
  }

  eventHandler(event: Event): void {
    if (event.target instanceof HTMLElement) {
      if (!event.target.closest('#jsTagSearch') && this.dropdownVisible) {
        this.dropdownVisible = false;
      }
    }
  }

  filterTags(): void {
    this.selectableTags.data = this.allTags.data.filter((tag) => {
      for (let i = 0; i < this.filters.tags.value.length; i += 1) {
        const tagSelected = this.filters.tags.value[i];

        if (tag === tagSelected) {
          return false;
        }
      }

      return true;
    });

    this.selectableTags.data.sort((a, b) => this.sortingService.naturalSort(a, b));
    this.selectableTags.data = Array.from(new Set(this.selectableTags.data));

    this.selectableExcludedTags.data = this.selectableTags.data.filter((tag) => {
      for (let i = 0; i < this.filters.excludedTags.value.length; i += 1) {
        const tagSelected = this.filters.excludedTags.value[i];

        if (tag === tagSelected) {
          return false;
        }
      }

      return true;
    });
  }

  private setTagSelectOptions(): void {
    this.tagSelectOptionsForInclude = {
      showIcon: false,
      noTagRemovedDebounceTime: true,
      singleLineInput: true,
      combinedTags: this.filters.tags.combinedTags,
    };

    this.tagSelectOptionsForExclude = {
      showIcon: false,
      noTagRemovedDebounceTime: true,
      singleLineInput: true,
      combinedTags: this.filters.excludedTags.combinedTags,
    };
  }

  selectTags(tags: string[]): void {
    this.filters.tags.value = tags;
    this.updateSelectableFiltersService.updateSelectableTags();
    this.ppOnValueChange.emit();
  }

  selectExcludedTags(tags: string[]): void {
    this.filters.excludedTags.value = tags;
    this.updateSelectableFiltersService.updateSelectableExcludedTags();
    this.ppOnValueChange.emit();
  }

  removeSelectedTag(index: number): void {
    this.filters.tags.value.splice(index, 1);
    this.updateSelectableFiltersService.updateSelectableTags();
    this.ppOnValueChange.emit();
  }

  removeSelectedExcludedTag(index: number): void {
    this.filters.excludedTags.value.splice(index, 1);
    this.updateSelectableFiltersService.updateSelectableExcludedTags();
    this.ppOnValueChange.emit();
  }

  clearAll(): void {
    this.filters.tags = this.clearFilterService.clearTags();
    this.ppOnValueChange.emit();

    this.updateSelectableFiltersService.updateSelectableTags();
  }

  clearAllExcluded(): void {
    this.filters.excludedTags = this.clearFilterService.clearExcludedTags();
    this.ppOnValueChange.emit();

    this.updateSelectableFiltersService.updateSelectableExcludedTags();
  }

  toggleCombineTags(): void {
    this.filters.tags.combinedTags = !this.filters.tags.combinedTags;

    this.ppOnValueChange.emit();
  }

  toggleCombineExcludedTags(): void {
    this.filters.excludedTags.combinedTags = !this.filters.excludedTags.combinedTags;

    this.ppOnValueChange.emit();
  }

  refreshTags(): void {
    this.updateSelectableFiltersService.updateSelectableTags();
    this.updateSelectableFiltersService.updateSelectableExcludedTags();
  }
}
