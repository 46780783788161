import { EDashletPeriod } from 'src/app/project/modules/dashboard/dashboard-timeframes-enums';
import { EDashletType } from 'src/app/project/modules/dashboard/dashlets-enums';
import { TDashlet } from 'src/app/project/modules/preferences/preferences.model';

export function getAssetDefaultDashlets(): TDashlet[] {
  return [
    {
      x: 12,
      y: 0,
      rows: 9,
      cols: 8,
      selectedRange: { workspaceIds: [], workspacesCosts: {}, accountId: null },
      name: EDashletType.COST_COMPARISON,
      period: null,
    },
    {
      x: 16,
      y: 9,
      rows: 7,
      cols: 4,
      selectedRange: { workspaceIds: [], accountId: null },
      period: EDashletPeriod.WEEK,
      name: EDashletType.DEFECT_CREATE,
    },
    {
      x: 12,
      y: 9,
      rows: 7,
      cols: 4,
      selectedRange: { workspaceIds: [], accountId: null },
      period: EDashletPeriod.WEEK,
      name: EDashletType.CLOSED,
    },
    {
      x: 0,
      y: 9,
      rows: 7,
      cols: 12,
      selectedRange: { workspaceIds: [], accountId: null },
      name: EDashletType.ACTIVITIES,
      period: null,
    },
    {
      x: 0,
      y: 0,
      rows: 9,
      cols: 12,
      selectedRange: { workspaceIds: [], accountId: null },
      period: EDashletPeriod.WEEK,
      name: EDashletType.OVER_TIME_STATUS,
    },
  ];
}
