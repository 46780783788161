import { TSelectOption } from 'src/app/project/components/input/select/select.model';
import { translate } from 'src/app/project/features/translate/translate';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';

export function getAdvancedLocationOptions(): TSelectOption[] {
  return [
    {
      value: EAdvancedFilterOptions.IS_EMPTY,
      label: translate('exists') + '.',
    },
    {
      value: EAdvancedFilterOptions.IS_NOT_EMPTY,
      label: translate('does_not_exist') + '.',
    },
  ];
}
