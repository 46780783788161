import Tooltip from 'src/app/project/features/tooltip/Tooltip';

import { createElement, TAnyFunction } from '@core/helpers';

import { ApiService } from '@core/http';
import { getSvg } from 'src/app/core/helpers/get-svg';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { TFlattenedAccount } from '../account-list/account-list-flatten-account';
import { GET_DRAGGED_SITE } from '../utils/account-list.ui.store';

export default class CreateSite {
  private data: TFlattenedAccount;
  private createSiteCallback: TAnyFunction;
  private removeSiteFromFolderCallback: TAnyFunction;
  private dragoverCallback: TAnyFunction;

  element: HTMLElement;
  private nameElement: HTMLElement;
  private createSiteElement: HTMLElement;
  private iconElement: HTMLElement;
  private apiService: ApiService;

  nameTooltip: Tooltip;

  constructor(
    _data: TFlattenedAccount,
    apiService: ApiService,
    {
      createSiteCallback,
      removeSiteFromFolderCallback,
      dragoverCallback,
    }: {
      createSiteCallback: TAnyFunction;
      removeSiteFromFolderCallback: TAnyFunction;
      dragoverCallback: TAnyFunction;
    },
  ) {
    this.data = _data;
    this.apiService = apiService;
    this.createSiteCallback = createSiteCallback;
    this.dragoverCallback = dragoverCallback;
    this.removeSiteFromFolderCallback = removeSiteFromFolderCallback;

    this.element = this.create();
  }

  create(): HTMLElement {
    const nameBodyElement = createElement('div', {
      attrs: {
        class: [
          'mainNav__name',
          'siteNav__site-name',
          'createName__body',
          'folder__empty--opacity',
        ],
      },
      eventListeners: {
        dragover: (_event) => {
          const draggedSite = GET_DRAGGED_SITE();

          if (draggedSite) {
            _event.preventDefault();
          }

          this.dragoverCallback(null);
        },
        drop: () => {
          const draggedSite = GET_DRAGGED_SITE();

          if (draggedSite) {
            this.removeSiteFromFolderCallback(draggedSite.workspaceId, draggedSite.accountId);
          }
        },
      },
      children: [this.createNameIcon(), this.createNameElement()],
    });

    this.createSiteElement = createElement('div', {
      attrs: {
        class: 'siteNav__element-new-site',
        id: `sidePanelSiteNewButton${this.data.accountId}`,
        'data-test': 'addNewSiteButton',
      },
      eventListeners: {
        click: (_event) => {
          _event.stopPropagation();

          const top = this.createSiteElement.getBoundingClientRect().top;

          this.createSiteCallback(this.data.accountId, top);
        },
      },
      children: [nameBodyElement],
    });

    this.nameTooltip = new Tooltip({
      title: 'Create new site',
      mobileTooltip: false,
      childTooltip: false,
      element: this.nameElement,
      trimTooltip: false,
      trimElement: this.nameElement,
    });

    this.nameTooltip.createTooltip();

    return this.createSiteElement;
  }

  createNameElement(): HTMLElement {
    this.nameElement = createElement('span', {
      attrs: {
        class: ['siteNav__site-name-text', 'filterPanel__tooltip', 'siteNav__new-site-text'],
      },
      children: [this.data.name],
    });

    return this.nameElement;
  }

  createNameIcon(): HTMLElement {
    this.iconElement = createElement('img', {
      attrs: {
        src: EIconPath.PLUS_NEW_SITE,
      },
    });

    const nameIconElement = createElement('div', {
      attrs: {
        class: 'site-nav__new-site-icon site-nav__new-site-icon--standard',
      },
      children: [this.iconElement],
    });

    getSvg(this.apiService, EIconPath.ICON_PLUS_NEW_SITE).then((svg) => {
      nameIconElement.innerHTML = svg;
    });

    return nameIconElement;
  }
}
