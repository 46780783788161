import { cloneDeep } from 'lodash';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { from, Observable, Subject } from 'rxjs';
import { finalize, switchMap, takeUntil, tap } from 'rxjs/operators';

import { TAccount } from 'src/app/project/modules/account/account.model';
import { AddCustomFieldModalComponent } from 'src/app/project/modules/custom-fields/add-custom-field-modal/add-custom-field-modal.component';
import { ImportTagModalComponent } from 'src/app/project/modules/tags/import-tag-modal/import-tag-modal.component';
import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { ModalService } from 'src/app/project/components/modal/modal.service';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { SharesService, TWorkspaceShares } from '../../shares.service';

import { SortingService } from '@core/helpers';
import { EListSelectTypes } from 'src/app/project/components/site-list-select/site-list-select-enums';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { TAddCustomFieldModalData } from '../../../custom-fields/add-custom-field-modal/add-custom-field.model';
import { TImportTagModalData } from '../../../tags/import-tag-modal/import-tag-modal.model';
import { getUserLabels } from '../../share-utils/user-labels';
import { EUserRole } from '../../share-utils/user-roles';
import { TShare } from '../../share.model';
import { generateShare, getActualAALCustomFields } from '../../share.utils';
import { TSelectedUsers } from './share-import.models';

export type TImportUser = Partial<TShare> & {
  email: string;
  accessLevel: string;
  tags: string[];
  tagLimited: boolean;
  userId: string;
  avatarPublicUrl: string;
};
@Component({
  selector: 'pp-share-modal-import',
  templateUrl: './share-modal-import.component.html',
  styleUrls: ['./share-modal-import.component.scss'],
})
export class ShareModalImportComponent implements OnInit, OnDestroy {
  @Input() ppSelectedUsers: TSelectedUsers = {};
  @Input() ppEditedShares: {
    [userId: string]: TShare;
  } = {};
  @Input() ppWorkspaceId = '';
  @Input() ppCloseShare$: Observable<void>;

  @Input() ppTotalSelectedImportFields: {
    number: number;
  };

  @Output() ppEditedSharesChange = new EventEmitter<{
    [userId: string]: TShare;
  }>();
  @Output() ppOnImportedUserChange = new EventEmitter<string>();

  private readonly destroy$ = new Subject<void>();

  private workspaceShares: TWorkspaceShares = this.sharesService.getWorkspaceShares();

  private workspaces$: Observable<TWorkspacesById>;
  private workspaces: TWorkspacesById;
  private accounts$: Observable<TAccount[]>;
  accounts: TAccount[];
  private accountId = '';
  accountOwnerId: string;
  editedShare: TShare = null;
  private targetWorkspaceShares: TShare[] = null;

  site: TWorkspace;
  private users = this.usersService.getUsers();

  EListSelectTypes = EListSelectTypes;
  activeStep: 'sites' | 'users' = 'sites';
  selectableUsers: string[] = [];
  initialWorkspaceUsers: string[] = [];
  openedWorkspaceId = '';
  usersPerAccount: {
    [accountId: string]: number;
  } = {};
  usersPerWorkspace: {
    [workspaceId: string]: number;
  } = {};
  numberOfSelectableUsers = 0;
  selectedAllUsers: string[] = [];
  shares: {
    [workspaceId: string]: TImportUser[];
  } = {};
  processing = false;
  private modalElement: HTMLElement;
  EUserRole = EUserRole;
  EIconPath = EIconPath;
  shareOptions = getUserLabels();

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
      accounts: TAccount[];
    }>,
    private usersService: UsersService,
    private workspaceService: WorkspaceService,
    private sharesService: SharesService,
    private sortingService: SortingService,
    private modalService: ModalService,
    private router: Router,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
  }

  ngOnInit() {
    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      if (!this.workspaces) {
        this.usersService.fetchWorkspaceUsers(this.ppWorkspaceId).subscribe();
      }

      this.workspaces = workspaces;
      this.initialWorkspaceUsers = [];

      if (this.ppWorkspaceId) {
        const workspace = this.workspaceService.findWorkspace(this.ppWorkspaceId);

        this.accountId = workspace.accountId;
        if (this.workspaceShares) {
          this.workspaceShares.data.forEach((share) => {
            this.initialWorkspaceUsers.push(share.userId);
          });
        }
      }
    });

    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
      this.accounts = accounts;
      this.selectedAllUsers = [];

      const foundAccount = this.accounts.find((account) => account.accountId === this.accountId);

      this.accountOwnerId = foundAccount.accountOwnerId;

      this.accounts.forEach((account) => {
        this.ppSelectedUsers[account.accountId] = {};
        this.usersPerAccount[account.accountId] = 0;

        account.workspaces.forEach((workspace) => {
          this.ppSelectedUsers[account.accountId][workspace] = {};
          this.usersPerWorkspace[workspace] = 0;
        });
      });
    });

    this.ppCloseShare$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.updateShare();
      this.closeShare();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  openSiteUsers(workspaceId: string): void {
    if (workspaceId === this.ppWorkspaceId) {
      return;
    }

    this.activeStep = 'users';
    this.site = this.workspaces[workspaceId];
    this.selectableUsers = [];
    this.numberOfSelectableUsers = 0;
    this.openedWorkspaceId = workspaceId;

    if (this.shares[workspaceId]) {
      this.updateSelectableUsers(workspaceId);

      return;
    }

    this.processing = true;

    from(
      this.sharesService.fetchWorkspaceShares(workspaceId, {
        update: false,
        forceFetch: true,
      }),
    )
      .pipe(
        takeUntil(this.destroy$),
        switchMap((shares) => {
          const targetWorkspaceId = this.ppWorkspaceId;
          const workspaces = this.workspaceService.getWorkspaces();
          const targetTags = workspaces[targetWorkspaceId].tags;
          this.targetWorkspaceShares = shares;

          return this.usersService.fetchWorkspaceUsers(workspaceId).pipe(
            takeUntil(this.destroy$),
            tap((users) => {
              this.shares[workspaceId] = [];

              shares.forEach((share) => {
                const userData = users.find((user) => user.userId === share.userId);

                let tags = share.defectTags ? share.defectTags : [];

                tags = tags.filter((tag) => targetTags.includes(tag));

                if (userData) {
                  // OWNERS don't appear here
                  const user: TImportUser = {
                    email: userData.email,
                    accessLevel: share.shareOption,
                    tags: tags,
                    avatarPublicUrl: userData.avatarPublicUrl,
                    tagLimited: share.tagLimited,
                    userId: share.userId,
                  };

                  this.shares[workspaceId].push(user);
                }
              });

              this.updateSelectableUsers(workspaceId);
            }),
          );
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  back(): void {
    this.activeStep = 'sites';
  }

  selectUser(user: TImportUser): void {
    if (this.ppSelectedUsers[this.site.accountId][this.site.workspaceId][user.userId]) {
      const allUsersIndex = this.selectedAllUsers.findIndex(
        (searchedUser) => user.userId === searchedUser,
      );

      this.usersPerWorkspace[this.site.workspaceId] -= 1;
      this.usersPerWorkspace[this.site.accountId] -= 1;
      this.usersPerAccount[this.site.accountId] -= 1;

      this.selectedAllUsers.splice(allUsersIndex, 1);

      delete this.ppSelectedUsers[this.site.accountId][this.site.workspaceId][user.userId];
    } else {
      let role = user.accessLevel;

      if (role === EUserRole.OWNER || role === EUserRole.ACCOUNT_ADMIN) {
        role = EUserRole.SITE_ADMIN;
      }

      this.ppSelectedUsers[this.site.accountId][this.site.workspaceId][user.userId] = {
        role: role,
      };

      if (!this.ppEditedShares[user.userId]) {
        const workspaceId = this.ppWorkspaceId;
        const workspaces = this.workspaceService.getWorkspaces();
        const foundShare = this.getShareWithActualAALFields(user.userId);

        this.ppEditedShares[user.userId] = generateShare(
          foundShare,
          workspaceId,
          workspaces[workspaceId],
        );
      }

      this.selectedAllUsers.push(user.userId);
      this.usersPerWorkspace[this.site.workspaceId] += 1;
      this.usersPerAccount[this.site.accountId] += 1;
    }

    this.calculateTotalNumberOfSelectedUsers();
  }

  selectAll(): void {
    this.shares[this.openedWorkspaceId].forEach((user) => {
      if (
        !this.initialWorkspaceUsers.includes(user.userId) &&
        !(
          this.selectedAllUsers.includes(user.userId) &&
          !this.ppSelectedUsers[this.site.accountId][this.site.workspaceId][user.userId]
        ) &&
        !this.ppSelectedUsers[this.site.accountId][this.site.workspaceId][user.userId] &&
        !(this.accountOwnerId === user.userId) &&
        !(user.accessLevel === EUserRole.OWNER)
      ) {
        this.selectUser(user);
      }
    });

    this.calculateTotalNumberOfSelectedUsers();
  }

  deselectAll(): void {
    this.shares[this.openedWorkspaceId].forEach((user) => {
      if (
        !this.initialWorkspaceUsers.includes(user.userId) &&
        !(
          this.selectedAllUsers.includes(user.userId) &&
          !this.ppSelectedUsers[this.site.accountId][this.site.workspaceId][user.userId]
        ) &&
        this.ppSelectedUsers[this.site.accountId][this.site.workspaceId][user.userId] &&
        !(user.accessLevel === EUserRole.OWNER)
      ) {
        this.selectUser(user);
      }
    });

    this.calculateTotalNumberOfSelectedUsers();
  }

  calculateTotalNumberOfSelectedUsers(): void {
    this.ppTotalSelectedImportFields.number = 0;

    Object.keys(this.usersPerAccount).forEach((accountId) => {
      this.ppTotalSelectedImportFields.number += this.usersPerAccount[accountId];
    });
  }

  goToCustomFieldsSettings(): void {
    this.router.navigate(['/settings/site/', this.ppWorkspaceId, 'custom-fields']);

    this.modalService.hideModal().then(() => {
      this.modalService.setData<TAddCustomFieldModalData>({
        importingCustomFields: true,
        workspaceId: this.ppWorkspaceId,
      });

      this.modalService.showModal(AddCustomFieldModalComponent, {
        closeWarning: true,
      });
    });
  }

  goToTagsSettings(): void {
    this.router.navigate(['/settings/site/', this.ppWorkspaceId, 'tags']);

    this.modalService.hideModal().then(() => {
      this.modalService.setData<TImportTagModalData>({
        workspaceId: this.ppWorkspaceId,
      });

      this.modalService.showModal(ImportTagModalComponent, {
        closeWarning: true,
      });
    });
  }

  editShare(share: TImportUser): void {
    this.modalScrollTop();

    const foundShare = this.getShareWithActualAALFields(share.userId);
    const editedShare = this.ppEditedShares[foundShare.userId];
    this.editedShare = cloneDeep(editedShare || foundShare);
    const user = this.users[share.userId];

    this.ppOnImportedUserChange.emit(user.userName || user.email);
  }

  updateShare(): void {
    this.ppEditedShares[this.editedShare.userId] = this.editedShare;
    this.ppEditedSharesChange.emit(this.ppEditedShares);
  }

  closeShare(): void {
    this.ppOnImportedUserChange.emit(null);
    this.editedShare = null;
  }

  private getShareWithActualAALFields(userId: string): TShare {
    const foundShare = this.targetWorkspaceShares.find((_share) => _share.userId === userId);

    if (foundShare.advancedAccessLevels) {
      foundShare.advancedAccessLevels.customFields = getActualAALCustomFields(
        foundShare.advancedAccessLevels.customFields || [],
        this.workspaces[this.ppWorkspaceId].customFields,
      );
    }

    return foundShare;
  }

  private modalScrollTop(): void {
    if (!this.modalElement) {
      this.modalElement = this.modalService.getModalBaseElement();
    }

    this.modalElement.scrollTop = 0;
  }

  private updateSelectableUsers(workspaceId: string): void {
    this.shares[workspaceId].forEach((user) => {
      if (
        !this.initialWorkspaceUsers.includes(user.userId) &&
        user.userId !== this.accountOwnerId
      ) {
        this.selectableUsers.push(user.userId);

        if (
          user.accessLevel !== EUserRole.OWNER &&
          !(
            this.selectedAllUsers.includes(user.userId) &&
            !this.ppSelectedUsers[this.site.accountId][this.site.workspaceId][user.userId]
          )
        ) {
          this.numberOfSelectableUsers += 1;
        }
      }
    });
  }
}
