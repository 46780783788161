import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TTagSelectOptions } from 'src/app/project/modules/tags/tag-select/tag-select.component';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';
import {
  TAdvancedFilterSingle,
  TAdvancedFilterTag,
  TFilterTagOptions,
} from '../../../../models/advanced-filter.model';
import { getAdvancedTagOptions } from './advanced-filter-tag-utils';

@Component({
  selector: 'pp-advanced-filter-tag',
  templateUrl: './advanced-filter-tag.component.html',
  styleUrls: ['./advanced-filter-tag.component.scss'],
})
export class AdvancedFilterTagComponent {
  @Input() ppFilter: TAdvancedFilterSingle;
  @Input() ppWorkspaceId: string;
  @Output() ppFilterChange = new EventEmitter<TAdvancedFilterSingle>();

  value: string[] = [];
  option: TFilterTagOptions;
  filter: TAdvancedFilterTag;
  tagSelectOptions: TTagSelectOptions;

  advancedFilterTagOptions = getAdvancedTagOptions();
  EAdvancedFilterOptions = EAdvancedFilterOptions;

  ngOnChanges(): void {
    this.filter = this.ppFilter as TAdvancedFilterTag;

    this.value = this.filter.value ? this.filter.value : [];
    this.option = this.filter.option;

    this.setTagSelectOptions();
  }

  private setTagSelectOptions(): void {
    this.tagSelectOptions = {
      showIcon: false,
      noTagRemovedDebounceTime: true,
      singleLineInput: true,
      width: '280px',
      singleSelectOnly:
        this.option === EAdvancedFilterOptions.IS || this.option === EAdvancedFilterOptions.IS_NOT,
    };
  }

  changeFieldCondition(newCondition: TFilterTagOptions): void {
    if (
      (newCondition === EAdvancedFilterOptions.IS ||
        newCondition === EAdvancedFilterOptions.IS_NOT) &&
      this.filter?.value?.length
    ) {
      this.filter.value = [this.filter.value[0]]; // New array to trigger ngOnChanges
      this.value = this.filter.value;
    }

    this.ppFilter.option = newCondition;
    this.option = newCondition;

    this.setTagSelectOptions();
    this.applyChanges();
  }

  applyChanges(): void {
    this.ppFilterChange.emit(this.ppFilter);
  }

  changeFieldValue(value: string[]): void {
    this.value = value;
    this.ppFilter.value = value;
    this.applyChanges();
  }
}
