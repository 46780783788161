import { Component, OnDestroy, OnInit } from '@angular/core';
import { API_files_images_size_original } from '@core/api/paths';
import { Subject } from 'rxjs';
import { DownloadService } from 'src/app/project/services/download.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { NewAssetService } from '../new-asset.service';

@Component({
  selector: 'pp-asset-name-and-image',
  templateUrl: './asset-name-and-image.component.html',
  styleUrls: ['./asset-name-and-image.component.scss'],
})
export class AssetNameAndImageComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  assetName: string;
  EIconPath = EIconPath;
  uploadedImage: string | ArrayBuffer;
  imageUrl: string;

  constructor(private newAssetService: NewAssetService, private downloadService: DownloadService) {}

  ngOnInit(): void {
    const imageId = this.newAssetService.getNewAsset().imageId;
    this.assetName = this.newAssetService.getNewAsset().name;
    this.imageUrl = imageId ? API_files_images_size_original(imageId) : '';

    const newImage = this.newAssetService.getAssetImage();

    if (newImage) {
      this.setLogo([newImage]);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  updateAssetName(): void {
    this.newAssetService.setAssetName(this.assetName);
  }

  setLogo(images: File[]): void {
    this.newAssetService.setAssetImage(images[0]);

    if (images) {
      const reader = new FileReader();

      reader.onload = (): void => {
        this.uploadedImage = reader.result;
      };

      reader.readAsDataURL(images[0]);
    } else {
      this.uploadedImage = null;
    }
  }

  downloadImage(): void {
    if (this.uploadedImage) {
      this.downloadService.saveFile(this.newAssetService.getAssetImage(), 'image.png');
    } else {
      this.downloadService
        .downloadGET({
          url: this.imageUrl,
          fileName: 'image.png',
        })
        .subscribe();
    }
  }

  removeImage(): void {
    this.newAssetService.setAssetImage(null);
    this.newAssetService.setAssetImageId('');
    this.uploadedImage = null;
    this.imageUrl = '';
  }
}
