export const TWO_FACTOR_AUTHENTICATION_CODE_LENGTH = 6;

export type TTwoFactorAuthenticationSetupDTO = {
  phoneNumber: string;
  channel: ETwoFactorAuthenticationChannel;
};

export type TTwoFactorAuthenticationCode = string;

export type TTwoFactorAuthenticationConfirmDTO = {
  twoFactorAuthCode: TTwoFactorAuthenticationCode;
  phoneNumber: string;
  channel: ETwoFactorAuthenticationChannel;
};

export enum ETwoFactorAuthenticationChannel {
  SMS = `SMS`,
  WHATSAPP = `WHATSAPP`,
}

export type TTwoFactorAuthDeleteDTO = {
  userId: string;
  twoFactorAuthCode?: string;
};
