import { Component } from '@angular/core';

@Component({
  selector: 'pp-white-label-placeholder',
  templateUrl: './white-label-placeholder.component.html',
  styleUrls: ['./white-label-placeholder.component.scss']
})
export class WhiteLabelPlaceholderComponent {

}
