import { Component } from '@angular/core';

@Component({
  selector: 'pp-reminders-placeholder',
  templateUrl: './reminders-placeholder.component.html',
  styleUrls: ['./reminders-placeholder.component.scss'],
})
export class RemindersPlaceholderComponent {
  placeholders: number[];
  private numberOfPlaceholdersTop = 3;

  constructor() {
    this.placeholders = Array.from(Array(this.numberOfPlaceholdersTop).keys());
  }
}
