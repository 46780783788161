<pp-modal class="confirmSavePlanModal">
  <ng-container heading> {{ 'clear_all' | ppTranslate }} </ng-container>

  <ng-container body>
    <p class="no-margin confirmSavePlanModal__text">
      {{ 'confirm_clear_all_locations' | ppTranslate }}
    </p>

    <div class="confirmMessage">
      <pp-checkbox [ppChecked]="dontShowAgain" (ppOnValueChange)="checkCookies()">
        {{ 'dont_show_message_again' | ppTranslate }}
      </pp-checkbox>
    </div>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'clear_all' | ppTranslate"
      [ppError]="true"
      (ppCancel)="hideModal(true)"
      (ppConfirm)="hideModal(false)"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
