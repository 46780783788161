<div
  #dropdownEl
  [id]="ppId ? ppId : dropdown.buttonId + '-dropdown'"
  class="dropdown"
  [hidden]="!dropdown?.visible"
  [class.dropdown--hidden]="dropdown?.visibilityHidden"
  [class.dropdown--long]="dropdown?.long"
  [class.dropdown--secondary]="dropdown?.secondary"
>
  <div>
    <ng-template dropdown-host></ng-template>

    <ul class="dropdownList" *ngIf="dropdown?.options">
      <li class="dropdownItem" *ngFor="let option of dropdown?.options" (click)="option.action()">
        {{ option.name }}
      </li>
    </ul>
  </div>
</div>
