import { GET_TABLE } from '../../../table.ui.store';
import Tooltip from 'src/app/project/features/tooltip/Tooltip';

export function createHeaderTooltip(
  title: string,
  element: HTMLElement,
  trimTooltip: boolean = true,
): void {
  const table = GET_TABLE();

  const nameTooltip = new Tooltip({
    title: title,
    mobileTooltip: false,
    childTooltip: false,
    trimTooltip,
    element: element,
    trimElement: element,
  });

  table.tableHead?.addTooltip(nameTooltip);

  nameTooltip.createTooltip();
}
