<div class="assetCard__sitesHeaderContainer">
  <div class="assetCard__siteNameColumn">{{ 'site' | ppTranslate }}</div>
  <div
    ppTooltip
    class="assetCard__progressColumn assetCard__siteColumnData"
    [ppSanitize]="false"
    [ppTitle]="progressTooltip"
  >
    {{ 'progress' | ppTranslate }}
  </div>
  <div
    ppTooltip
    class="assetCard__pointsColumn assetCard__siteColumnData"
    [ppTitle]="'asset_card_tooltip_points' | ppTranslate"
  >
    {{ 'points' | ppTranslate }}
  </div>
  <div
    ppTooltip
    [ppTitle]="'asset_card_tooltip_budget' | ppTranslate"
    class="assetCard__budgetColumn assetCard__siteColumnData"
  >
    {{ 'budget' | ppTranslate }}
  </div>

  <div
    ppTooltip
    class="assetCard__redFlagsColumn assetCard__siteColumnData"
    [ppTitle]="'asset_card_tooltip_red_flags' | ppTranslate"
  >
    {{ 'red_flags' | ppTranslate }}
  </div>
</div>
