import Timeline from '../../Timeline';
import { adjustTimelineElementLeft } from './adjust-timeline-element-left';

export function onResizeHandleLeftScrollMove({
  timeline,
  mouseMoveChangeAmount,
  initialScroll,
  _width,
  resizeHandlerElement,
}: {
  timeline: Timeline;
  mouseMoveChangeAmount: number;
  initialScroll: number;
  _width: number;
  resizeHandlerElement: HTMLElement;
}): void {
  const newScroll = timeline.timelineBody.virtualScroller.scrollElement.scrollLeft;

  const changeAmount = mouseMoveChangeAmount + newScroll - initialScroll;
  const newWidth = _width - changeAmount;

  adjustTimelineElementLeft({
    newWidth,
    element: resizeHandlerElement.parentElement,
    changeAmount,
    width: _width,
  });
}
