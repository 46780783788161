<pp-modal>
  <ng-container heading>
    {{ 'Delete' | ppTranslate }}
    <strong>{{ field.label }}</strong>
    {{ 'field' | ppTranslate }}
  </ng-container>

  <ng-container body>
    <div class="warningMessage__expanded" *ngIf="field.type === ECustomFieldType.FORMULA">
      <header class="warningMessage__expanded--header">
        {{ 'formula_delete_warning_header' | ppTranslate }}
      </header>
      {{ 'formula_delete_warning' | ppTranslate }}
    </div>

    <p class="no-margin word-break" *ngIf="field.type !== ECustomFieldType.FORMULA">
      {{ 'delete_warning' | ppTranslate }}
      <strong>{{ field.label }}</strong>
      {{ 'field' | ppTranslate }}?
    </p>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'Delete' | ppTranslate"
      [ppProcess]="processing"
      (ppCancel)="hideModal()"
      (ppConfirm)="delete()"
      [ppError]="true"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
