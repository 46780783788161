<div class="filterDropdown__section filterDropdown__section--assignees">
  <div class="filterDropdown__heading">
    <h6>{{ 'assignees' | ppTranslate }}</h6>
    <button
      class="filterDropdown__clear--text"
      *ngIf="ppFilters.assignees.value.length > 0"
      (click)="clearAssignees()"
    >
      {{ 'clear' | ppTranslate }}
    </button>
  </div>

  <pp-filter-users
    [ppFilters]="ppFilters"
    ppFilterType="assignees"
    (ppOnValueChange)="applyFilters()"
  ></pp-filter-users>

  <hr />
</div>
