import { Injectable } from '@angular/core';

import { SiteTableColumnsDataService, TColumnsData } from './site-table-columns-data.service';
import { SiteTableColumnsService } from './site-table-columns.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { PreferencesService } from 'src/app/project/modules/preferences/preferences-service/preferences.service';

@Injectable({
  providedIn: 'root',
})
export class SiteTableColumnSortService {
  private columns: TColumnsData = this.siteTableColumnsDataService.getColumns();

  constructor(
    private siteTableColumnsDataService: SiteTableColumnsDataService,
    private siteTableColumnsService: SiteTableColumnsService,
    private activeService: ActiveService,
    private preferencesService: PreferencesService,
  ) {}

  updateColumnSortingPrefs(column: number = 6, order: 'DESC' | 'ASC' = 'ASC'): void {
    const preferences = this.preferencesService.getPreferences();
    const workspaceId = this.activeService.getActiveWorkspaceId();

    this.columns.data.forEach((col) => {
      const colArg = col;

      if (colArg.index === column) {
        colArg.sortOrder = order;
      } else {
        colArg.sortOrder = undefined;
      }
    });

    this.columns.sorting.column = column;
    this.columns.sorting.sortOrder = order;

    if (
      preferences.workspaces &&
      preferences.workspaces[workspaceId] &&
      preferences.workspaces[workspaceId].columns[column] &&
      preferences.workspaces[workspaceId].columns[column].sortOrder !== order
    ) {
      this.siteTableColumnsService.updateColumnPrefs();
    }
  }
}
