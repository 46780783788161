import { SET_GROUPED_POINTS } from '../columns/grouping.store';
import { TPoint } from 'src/app/project/modules/points/points.model';
import { TGroupedPoints } from './group-model';

export function setPointsInOrder(groupedPointIndexes: TGroupedPoints[], _points: TPoint[]): void {
  const pointsOrder: string[] = [];

  groupedPointIndexes.forEach((pointsGroup) => {
    if (pointsGroup.group) {
      pointsGroup.group.forEach((subGroup) => {
        if (subGroup.group) {
          subGroup.group.forEach((subSubGroup) => {
            subSubGroup.points.forEach((pointIndex) => {
              const point: TPoint = _points[pointIndex];

              pointsOrder.push(point._id);
            });
          });
        } else {
          subGroup.points.forEach((pointIndex) => {
            const point: TPoint = _points[pointIndex];

            pointsOrder.push(point._id);
          });
        }
      });
    } else {
      pointsGroup.points.forEach((pointIndex) => {
        const point: TPoint = _points[pointIndex];

        pointsOrder.push(point._id);
      });
    }
  });

  SET_GROUPED_POINTS(pointsOrder);
}
