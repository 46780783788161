import { TSelectOption } from 'src/app/project/components/input/select/select.model';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';
import { translate } from 'src/app/project/features/translate/translate';

export function getAdvancedCreatedByOptions(): TSelectOption[] {
  return [
    {
      value: EAdvancedFilterOptions.IS,
      label: translate('is') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_NOT,
      label: translate('is_not') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_ANY_OF,
      label: translate('is_any_of') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_NONE_OF,
      label: translate('is_none_of') + '...',
    },
  ];
}
