<div class="notification__image-wrapper">
  <img class="notification__image" [src]="EIconPath.NOTIFICATION_ICON_DESCRIPTION" />
</div>

<span>
  <span
    *ngIf="ppNotification.changeBody.newValue && !ppNotification.changeBody.oldValue"
    class="notification__blue-text"
    >{{ 'set' | ppTranslate }}</span
  >
  <span
    *ngIf="ppNotification.changeBody.newValue && ppNotification.changeBody.oldValue"
    class="notification__blue-text"
    >{{ 'changed' | ppTranslate }}</span
  >
  <span
    *ngIf="!ppNotification.changeBody.newValue && !ppNotification.changeBody.oldValue"
    class="notification__blue-text"
    >{{ 'cleared' | ppTranslate }}</span
  >

  {{ 'the' | ppTranslate }}
  <span class="notification__bold">{{ ppNotification.changeBody.label }}</span>
  {{ 'of_a_point_you' | ppTranslate }}
  {{ notificationReason }}.
</span>
