<div class="filterContent__cost">
  <pp-filter-field-numbers-input
    [ppUnit]="ppCustomFields[ppField.workspaceId][ppField.id].unit"
    [ppPlaceholder]="'minimum' | ppTranslate"
    [(ppValue)]="ppField.min"
    (ppValueChange)="updateNumbersField(ppField); applyFilters()"
    class="filterContent__costInput"
  ></pp-filter-field-numbers-input>

  <pp-filter-field-numbers-input
    [ppUnit]="ppCustomFields[ppField.workspaceId][ppField.id].unit"
    [ppPlaceholder]="'maximum' | ppTranslate"
    [(ppValue)]="ppField.max"
    (ppValueChange)="updateNumbersField(ppField); applyFilters()"
    class="filterContent__costInput"
  ></pp-filter-field-numbers-input>
</div>
