<div id="sortDropdown" #sortDropdown class="siteOptions__dropdown">
  <pp-button-dropdown
    [ppSrc]="EIconPath.ICON_TABLE_SORT"
    [ppText]="'sort' | ppTranslate"
    [ppNumber]="sorting.length > 0 ? ' / ' + sorting.length : ''"
    [ppActive]="
      sorting.length > 0 || (dropdown.visible && dropdown.buttonId === sortDropdownButtonId)
    "
    (ppAction)="toggleSortDropdown()"
    ppTooltip
    [id]="sortDropdownButtonId"
    data-m-target="Sort dropdown button"
    ppTitle="Sort points"
  ></pp-button-dropdown>
</div>
