import { cloneDeep } from 'lodash';
import * as lodash from 'lodash';
import { UpdatePointsAssignees } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';

export function updatePointsAssignees(
  oldState: TPointsByWorkspace,
  action: UpdatePointsAssignees,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);
  let pointIndex = null;

  action.payload.pointIds.forEach((_pointId) => {
    pointIndex = state[action.payload.workspaceId].entities.findIndex(
      (point) => point._id === _pointId,
    );
    const point = state[action.payload.workspaceId].entities[pointIndex];

    if (point) {
      point.header.updatedEpochMillis = new Date().getTime();
      point.assignees = lodash.union(point.assignees, action.payload.assignees);
      state[action.payload.workspaceId].entities.splice(pointIndex, 1, point);
    }
  });

  return state;
}
