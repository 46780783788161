<div class="editAccountUserCustomFields__wrapper">
  <div class="editAccountUserCustomFields__fields">
    <header class="editAccountUserCustomFields__fieldsHeader">
      {{ 'custom_fields' | ppTranslate }}
    </header>

    <section class="editAccountUserCustomFields__fieldsTable">
      <pp-account-user-modal-custom-field
        *ngFor="
          let customField of share.advancedAccessLevels.customFields;
          let even = even;
          let index = index
        "
        [ppCustomField]="customField"
        (ppCustomFieldChange)="updateCustomField(index, $event)"
        [ppWorkspaceId]="ppWorkspaceId"
        [ppShareOption]="share.shareOption"
        [ppEven]="even"
      ></pp-account-user-modal-custom-field>
    </section>
  </div>
</div>
