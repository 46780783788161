import { Injectable } from '@angular/core';
import { TLoggedResponse } from '../../view-models/logged-response-model';

import { Observable } from 'rxjs';
import { clearUserAuth, getUserAuth } from 'src/app/core/http/user-auth';
import { LoginService } from './login.service';
import { LogoutService } from './logout.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private cachedUrlPath: string = null;
  private cachedUserPassword: string;
  private cachedUserEmail: string;
  private cachedRememberMe: boolean;

  constructor(private loginService: LoginService, private logoutService: LogoutService) {}

  isAuthenticated(): boolean {
    const userAuth = getUserAuth();

    return !!userAuth?.token;
  }

  setCachedUrlPath(url: string): void {
    this.cachedUrlPath = url;
  }

  getCachedUrlPath(): string {
    return this.cachedUrlPath;
  }

  clearAuth(): void {
    clearUserAuth();
  }

  login(email: string, password: string, remember: boolean): Observable<TLoggedResponse> {
    this.setCachedData(email, password, remember);

    return this.loginService.login({ email, password, remember });
  }

  logout(): void {
    return this.logoutService.logout();
  }

  loginWithCachedData(code: string = null): Observable<TLoggedResponse> {
    return this.loginService.login({
      email: this.cachedUserEmail,
      password: this.cachedUserPassword,
      remember: this.cachedRememberMe,
      twoFactorAuthCode: code,
    });
  }

  private setCachedData(email: string, password: string, remember: boolean): void {
    this.cachedUserEmail = email;
    this.cachedUserPassword = password;
    this.cachedRememberMe = remember;
  }
}
