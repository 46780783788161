<div class="dashlet-flip__content-wrapper dashletFlip__back">
  <pp-dashlet-head [ppDashlet]="ppDashlet" (ppToggleFlip)="flipDashlet()"> </pp-dashlet-head>

  <div
    class="dashletFlip__container"
    [class.dashletFlip__container--active]="ppFlipped"
    #dashletFlipContainer
  >
    <pp-dashlet-period-select
      *ngIf="
        ppDashlet.period &&
        ppDashlet.name !== dashletTypes.CURRENT_STATUS &&
        ppDashlet.name !== dashletTypes.CURRENT_PRIORITY
      "
      [ppDashlet]="ppDashlet"
      [(ppPeriod)]="period"
    ></pp-dashlet-period-select>

    <pp-dashlet-workspace-select
      *ngIf="!ppAssetId"
      [ppDashlet]="ppDashlet"
      [(ppSiteRange)]="siteRange"
    ></pp-dashlet-workspace-select>

    <pp-dashlet-workspace-select-multiple
      *ngIf="ppAssetId"
      [ppDashlet]="ppDashlet"
      [ppDefaultWorkspaces]="ppDefaultWorkspaces"
      [ppLimitedWorkspaces]="
        ppDashlet.name === dashletTypes.CURRENT_STACKED_STATUS ||
        ppDashlet.name === dashletTypes.COST_COMPARISON
      "
      [(ppSiteRange)]="siteRange"
    ></pp-dashlet-workspace-select-multiple>
  </div>

  <div class="dashletFlip__footer">
    <pp-delete-button
      (mousedown)="$event.stopPropagation()"
      (ppAction)="removeDashlet()"
    ></pp-delete-button>

    <pp-button-modal-confirm
      ppDataTarget="Dashlet settings apply button"
      ppStyle="primary"
      (ppAction)="apply()"
    >
      {{ 'save' | ppTranslate }}
    </pp-button-modal-confirm>
  </div>
</div>
