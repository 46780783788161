import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, of } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';

import { TUser } from 'src/app/project/modules/user/user.model';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { SidePanelService } from 'src/app/project/modules/layout/side-panel/side-panel.service';
import { PlanService } from 'src/app/project/modules/plan/plan.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { UploadService } from '../../../../components/input/upload/upload.service';
import { PromptService } from '../../../../components/prompt/prompt.service';
import { OfflineService } from '../../../offline/offline.service';

import { Title } from '@angular/platform-browser';
import { ScreenService } from '@core/services';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import {
  setChangedWorkspace,
  setChangedWorkspaces,
} from 'src/app/project/modules/workspace/workspace';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSettings,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { DownloadService } from 'src/app/project/services/download.service';
import { EFileType } from 'src/app/project/shared/enums/file-type.enum';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { AttachmentsService } from '../../../points/attachments/attachments.service';
import { DEFAULT_PLAN_FORMAT } from '../../site-new/site-plan-format/default-plan-format';
import { EPlanFormat } from '../../site-new/site-plan-format/plan-format.enum';

@Component({
  selector: 'pp-site-settings-plan',
  templateUrl: './site-settings-plan.component.html',
  styleUrls: ['./site-settings-plan.component.scss'],
})
export class SiteSettingsPlanComponent implements OnInit, OnDestroy {
  @ViewChild('sitePlanImage') sitePlanImage: ElementRef<HTMLImageElement>;
  @ViewChild('uploadedPlanImage') uploadedPlanImage: ElementRef<HTMLImageElement>;

  private readonly destroy$ = new Subject<void>();

  processingSitePlanUpdate = false;
  newSitePlan: File = null;

  user$: Observable<TUser>;
  user: TUser;
  workspaces$: Observable<TWorkspacesById>;
  offline$: Observable<boolean>;
  offline: boolean;
  workspaceId: string = null;
  uploadedImage: string | ArrayBuffer = null;
  workspaces: TWorkspacesById;
  planFormat: EPlanFormat = DEFAULT_PLAN_FORMAT;
  uploadedPdf: File;
  contentType: string;
  planId = '';
  planHeight: number;
  planWidth: number;

  constructor(
    private store: Store<{
      user: TUser;
      workspaces: TWorkspacesById;
      offline: boolean;
    }>,
    private activatedRoute: ActivatedRoute,
    private promptService: PromptService,
    private uploadService: UploadService,
    private activeService: ActiveService,
    private offlineService: OfflineService,
    private sidePanelService: SidePanelService,
    private translationPipe: TranslationPipe,
    private planService: PlanService,
    private titleService: Title,
    private screenService: ScreenService,
    private downloadService: DownloadService,
    private attachmentsService: AttachmentsService,
  ) {
    this.user$ = this.store.pipe(select(EStore.USER));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.offline$ = this.store.pipe(select(EStore.OFFLINE));
  }

  ngOnInit() {
    this.titleService.setTitle('Site settings | Pinpoint Works');

    this.screenService.isDesktop$.pipe(takeUntil(this.destroy$)).subscribe((isDesktop) => {
      if (!isDesktop) {
        this.sidePanelService.collapsePanel();
      }
    });

    this.activatedRoute.parent.paramMap.subscribe((params) => {
      this.workspaceId = params.get('id');
      this.uploadedImage = null;

      this.checkPlan();
    });

    this.offline$.pipe(takeUntil(this.destroy$)).subscribe((offline) => {
      this.offline = offline;
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspaces = workspaces;

      this.checkPlan();
    });

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  checkPlan(): void {
    this.planId = this.workspaces?.[this.workspaceId]?.siteImageRef?.id || null;
  }

  setSitePlan(files: File[]): void {
    const file = files[0];
    this.contentType = file.type;

    if (file.type === 'application/pdf') {
      this.uploadedImage = null;
      this.uploadedPdf = file;
    } else {
      const offline = this.offlineService.getOffline();
      this.uploadedPdf = null;

      const reader = new FileReader();
      if (!offline) {
        [this.newSitePlan] = files;
      }
      reader.onload = (): void => {
        this.uploadedImage = reader.result;
      };
      reader.readAsDataURL(file);
    }
  }

  updateSitePlan(): void {
    this.processingSitePlanUpdate = true;
    const workspaceId = this.activeService.getActiveWorkspaceId();
    const newPlan = this.uploadedPdf ? this.uploadedPdf : this.newSitePlan;

    this.uploadService
      .uploadSitePlan(workspaceId, newPlan, this.planFormat, this.contentType)
      .pipe(
        tap((response) => {
          const promptText = this.translationPipe.transform('prompt_site_plan_updated');

          this.planId = response.siteImageRef.id;
          this.newSitePlan = null;
          this.uploadedPdf = null;

          setChangedWorkspace(this.workspaceId);
          setChangedWorkspaces(true);
          this.planService.clearS3Credentials();

          logEventInGTAG(EGoogleEventSettings.SETTINGS__SITE_PLAN__UPDATE, {
            event_category: EGoogleEventCategory.SETTINGS,
          });
          this.promptService.showSuccess(promptText);
        }),
        catchError(() => {
          const promptText = this.translationPipe.transform('prompt_site_plan_updated_error');

          this.promptService.showError(promptText);
          return of();
        }),
        finalize(() => {
          this.processingSitePlanUpdate = false;
        }),
      )
      .subscribe();
  }

  cancelNewSitePlan(): void {
    this.newSitePlan = null;
    this.uploadedImage = null;
  }

  selectFormat(format: EPlanFormat): void {
    this.planFormat = format;
  }

  updateImageSize(image: 'site' | 'upload'): void {
    if (image === 'site') {
      this.planWidth = this.sitePlanImage.nativeElement.naturalWidth;
      this.planHeight = this.sitePlanImage.nativeElement.naturalHeight;
    } else {
      this.planWidth = this.uploadedPlanImage.nativeElement.naturalWidth;
      this.planHeight = this.uploadedPlanImage.nativeElement.naturalHeight;
    }
  }

  async downloadPlan(): Promise<void> {
    if (this.uploadedImage) {
      let name;

      switch (this.contentType) {
        case EFileType.JPEG:
        case EFileType.JPG:
          name = 'plan.jpg';
          break;
        case EFileType.PNG:
          name = 'plan.png';
          break;
      }

      this.downloadService.saveFile(this.uploadedImage as string, name);
    } else {
      const mediaUrl = 'api/v1/images/' + this.planId + '/file/size/original';

      this.downloadService.downloadGET({ url: mediaUrl, fileName: 'plan.jpg' }).subscribe();
    }
  }
}
