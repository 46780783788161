import { GET_COLUMNS } from '../../columns/columns.store';

import { createRowElement } from '../../body-elements/body-row/create-row-element';
import { createCellTypeElement } from '../../body-elements/body-cells';

import Table from '../table/Table';
import VirtualScroller from '../../../../external/virtual-scroller/VirtualScroller';
import { PointAttachmentsService } from '../../../../points/point-modal/point-attachments/point-attachments.service';
import { findPointIndexInTable } from '../utils/find-point-index-in-table';
import { ERowType } from 'src/app/project/shared/enums/row-type.enum';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { ApiService } from '@core/http';
import { getFrozenColumnsWidth } from './get-frozen-columns-width';

export default class TableBody {
  private table: Table;
  virtualScroller: VirtualScroller = null;
  private pointAttachmentsService: PointAttachmentsService;
  private dropdownService: DropdownService;
  private apiService: ApiService;

  constructor(
    parent: Table,
    pointAttachmentsService: PointAttachmentsService,
    dropdownService: DropdownService,
    apiService: ApiService,
  ) {
    this.table = parent;
    this.pointAttachmentsService = pointAttachmentsService;
    this.dropdownService = dropdownService;
    this.apiService = apiService;
    const fileUploading = this.pointAttachmentsService.getAttachmentUploading();

    this.virtualScroller = new VirtualScroller(this.table.element, [], {
      className: fileUploading ? 'table__body table__body--disabled' : 'table__body',
      columnWidths: GET_COLUMNS().map((column) => column.width),
      rowHeight: 36,
      marginBottom: 8,
      frozenColumnWidth: getFrozenColumnsWidth(),
      createElementCallback: (index: number): HTMLElement =>
        createRowElement(index, this.apiService, this.dropdownService),
      createCellElementCallback: (index: number, columnIndex: number): HTMLElement =>
        createCellTypeElement({
          index,
          column: GET_COLUMNS()[this.table.visibleColumnIndexes[columnIndex]],
          items: true,
          apiService: this.apiService,
        }),
      scrollLeftCallback: (left: number): void => {
        this.table.tableHead.scrollLeft(left);
        this.table.tableFooter.scrollLeft(left);
      },
      scrollbarTheme: 'os-theme-table',
    });

    window.addEventListener('resize', () => this.resizeEventHandler());

    this.pointAttachmentsService.fileUploading$.subscribe((isUploading) => {
      if (isUploading) {
        this.virtualScroller.element.classList.add('table__body--disabled');
      } else {
        this.virtualScroller.element.classList.remove('table__body--disabled');
      }
    });
  }

  resizeEventHandler(): void {
    this.load();
  }

  update(): void {
    this.updateWidth();
  }

  clear(): void {
    this.virtualScroller.load([]);
    this.virtualScroller.updateWidth(0);
  }

  updateWidth(): void {
    this.virtualScroller.updateWidth(this.table.width);
  }

  load(keepScrollPosition?: boolean): void {
    this.updateWidth();
    this.virtualScroller.updateColumnWidths(
      GET_COLUMNS()
        .filter((column) => !column.hidden)
        .map((column) => column.width),
    );
    this.virtualScroller.disableSmoothScrolling();
    this.virtualScroller.load(this.table.items, keepScrollPosition);
    this.virtualScroller.enableSmoothScrolling();

    this.virtualScroller.handleVerticalScroll();
  }

  addColumn(columnIndex: number): void {
    const columns = GET_COLUMNS();
    let hiddenColumnsCount = 0;

    for (let index = 0; index < columnIndex; index += 1) {
      if (columns[index] && columns[index].hidden) {
        hiddenColumnsCount += 1;
      }
    }

    if (
      this.virtualScroller.visibleXIndexes.find(
        (index) => index === columnIndex - hiddenColumnsCount,
      )
    ) {
      this.virtualScroller.dataElements.forEach((element, index) => {
        if (element) {
          const columnCellsElement = element.children[1];

          columnCellsElement.insertBefore(
            createCellTypeElement({
              index,
              column: columns[columnIndex],
              apiService: this.apiService,
            }),
            columnCellsElement.children[columnIndex - hiddenColumnsCount],
          );
        }
      });
    }
  }

  removeColumn(columnIndex: number): void {
    this.virtualScroller.dataElements.forEach((element, index) => {
      if (element) {
        const columnCellsElement = element.children[1];
        const columnElement = columnCellsElement.children[columnIndex];

        columnElement.parentNode.removeChild(columnElement);
      }
    });
  }

  moveColumn(columnIndex: number, destinationIndex: number): void {
    const columns = GET_COLUMNS();

    let hiddenColumnsBeforeDestinationCount = 0;
    let hiddenColumnsBeforeColumnCount = 0;

    for (let index = 0; index < destinationIndex; index += 1) {
      if (columns[index] && columns[index].hidden) {
        hiddenColumnsBeforeDestinationCount += 1;
      }
    }

    for (let index = 0; index < columnIndex; index += 1) {
      if (columns[index] && columns[index].hidden) {
        hiddenColumnsBeforeColumnCount += 1;
      }
    }

    this.virtualScroller.dataElements.forEach((element, index) => {
      if (element) {
        const columnCellsElement = element.children[1];

        if (columnCellsElement) {
          const columnElement =
            columnCellsElement.children[columnIndex - hiddenColumnsBeforeColumnCount];

          if (columnElement) {
            columnElement.parentNode.removeChild(columnElement);

            columnCellsElement.insertBefore(
              columnElement,
              columnCellsElement.children[destinationIndex - hiddenColumnsBeforeDestinationCount],
            );
          }
        }
      }
    });
  }

  updateColumns(): void {
    this.virtualScroller.updateColumnWidths(
      GET_COLUMNS()
        .filter((column) => !column.hidden)
        .map((column) => column.width),
    );
    this.virtualScroller.updateXvalues();
  }

  updateFrozenColumnWidth(): void {
    this.virtualScroller.updateFrozenColumnWidth(getFrozenColumnsWidth());

    this.virtualScroller.load(this.table.items, true);
  }

  // Points

  deselectPoints(): void {
    this.table.selectedPoints.clear();
  }

  highlightPoint(sequenceNumber: number): void {
    const pointIndexInTable = findPointIndexInTable(
      this.table.points,
      this.table.items,
      sequenceNumber,
    );

    if (pointIndexInTable !== -1) {
      this.virtualScroller.scrollTo(pointIndexInTable);
    }

    if (this.virtualScroller.dataElements[pointIndexInTable]) {
      this.virtualScroller.dataElements[pointIndexInTable].classList.add('table__row--highlight');
    }
  }

  dehighlightPoint(sequenceNumber: number): void {
    const pointIndex = this.table.points.findIndex(
      (point) => point.sequenceNumber === sequenceNumber,
    );

    const pointIndexInTable = this.table.items.findIndex(
      (item) => item.type === ERowType.POINT && item.index === pointIndex,
    );

    if (this.virtualScroller.dataElements[pointIndexInTable]) {
      this.virtualScroller.dataElements[pointIndexInTable].classList.remove(
        'table__row--highlight',
      );
    }
  }

  updatePoint(pointIndex: number, columnIndex: number): void {
    const columnToUpdateIndex = this.virtualScroller?.visibleXIndexes.indexOf(columnIndex);

    if (
      columnToUpdateIndex !== -1 &&
      this.virtualScroller?.dataElements[pointIndex]?.children[1]?.children?.[columnToUpdateIndex]
    ) {
      const oldChild =
        this.virtualScroller.dataElements[pointIndex].children[1].children[columnToUpdateIndex];

      this.virtualScroller.dataElements[pointIndex].children[1].replaceChild(
        createCellTypeElement({
          index: pointIndex,
          column: GET_COLUMNS()[columnIndex],
          apiService: this.apiService,
        }),
        oldChild,
      );
    }
  }
}
