<ng-container *ngIf="dropdown.data && user">
  <a
    id="accountSettingsDropdownButton"
    data-m-target="Open account settings dropdown button"
    class="dropdownItem"
    *ngIf="user.isSuperUser || user.userId === account.accountOwnerId || isAccountAdmin"
    (click)="openAccountSettings()"
  >
    {{ 'account_settings' | ppTranslate }}
  </a>

  <a
    id="newFolderDropdownButton"
    data-m-target="Create new folder dropdown button"
    class="dropdownItem"
    (click)="createNewFolder()"
  >
    {{ 'create_new_folder' | ppTranslate }}
  </a>
</ng-container>
