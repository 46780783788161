import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AccountService } from '../../../account/account-service/account.service';
import { CombineCustomFieldsService } from '../../../custom-fields/combine-custom-fields/combine-custom-fields.service';
import { TCombinedCustomField } from '../../../custom-fields/combine-custom-fields/combined-custom-field.model';
import { TWorkspace } from '../../../workspace/workspace.model';
import { WorkspaceService } from '../../../workspace/workspace.service';

@Injectable({
  providedIn: 'root',
})
export class AccountUserManagementService {
  private userManagementSearchKeyword: string;
  private readonly _userManagementSearchKeywordChange = new Subject<string>();
  readonly userManagementSearchKeywordChange$ =
    this._userManagementSearchKeywordChange.asObservable();

  constructor(
    private accountService: AccountService,
    private workspaceService: WorkspaceService,
    private combineCustomFieldsService: CombineCustomFieldsService,
  ) {}

  getAccountCustomFields(accountId: string): TCombinedCustomField[] {
    const account = this.accountService.getAccount(accountId);
    const workspaces: TWorkspace[] = [];

    account.workspaces.forEach((workspaceId) => {
      const workspace = this.workspaceService.getWorkspace(workspaceId);
      workspaces.push(workspace);
    });

    const combinedCustomFields = this.combineCustomFieldsService.combineCustomFields(workspaces);

    return combinedCustomFields;
  }

  setKeyword(keyword: string): void {
    this.userManagementSearchKeyword = keyword;

    this._userManagementSearchKeywordChange.next(keyword);
  }

  getKeyword(): string {
    return this.userManagementSearchKeyword;
  }
}
