import { cloneDeep } from 'lodash';
import { translate } from 'src/app/project/features/translate/translate';
import { GET_CUSTOM_FIELDS } from '../../custom-fields/custom-fields.store';
import { TDashlet } from '../../preferences/preferences.model';
import { GET_WORKSPACES } from '../../workspace/workspace.store';
import { EDashletType } from '../dashlets-enums';
import { checkBudgetCurrenciesMatch } from '../utils/check-budget-currencies';
import { TExportDashlet } from './dashboard-export-dashlet.model';

export function generateDashletList(
  allDashlets: TDashlet[],
  accounts,
  workspaces,
): TExportDashlet[] {
  let dashlets = cloneDeep(allDashlets).filter((_dashlet) => {
    const isActivities = _dashlet.name === EDashletType.ACTIVITIES;
    const isCostComparison = _dashlet.name === EDashletType.COST_COMPARISON;

    if (isCostComparison) {
      const isCostComparisonFilled = Object.keys(_dashlet.selectedRange.workspacesCosts).some(
        (workspaceId) => {
          const workspaceExists = GET_WORKSPACES()[workspaceId];

          if (!workspaceExists) {
            return false;
          }

          const customFields = GET_CUSTOM_FIELDS();
          const firstFieldId = _dashlet.selectedRange.workspacesCosts[workspaceId][0];
          const secondFieldId = _dashlet.selectedRange.workspacesCosts[workspaceId][1];

          return (
            firstFieldId &&
            secondFieldId &&
            customFields[firstFieldId] &&
            customFields[secondFieldId]
          );
        },
      );

      if (!isCostComparisonFilled) {
        return false;
      }

      if (!checkBudgetCurrenciesMatch(_dashlet.selectedRange.workspacesCosts)) {
        return false;
      }
    }

    return !isActivities;
  });

  dashlets = dashlets.sort((a, b) => {
    if (a.y !== b.y) {
      return a.y - b.y;
    } else {
      return a.x - b.x;
    }
  });

  return dashlets.map((_dashlet) => {
    let accountName = translate('all_accounts');
    let workspaceName = translate('all_sites');

    if (_dashlet.selectedRange.accountId) {
      accountName = accounts.find(
        (_account) => _account.accountId === _dashlet.selectedRange.accountId,
      ).accountName;
    }

    if (
      _dashlet.selectedRange.workspaceIds.length === 1 &&
      _dashlet.selectedRange.workspaceIds[0]
    ) {
      workspaceName = workspaces[_dashlet.selectedRange.workspaceIds[0]].siteName;
    }

    return {
      name: _dashlet.name,
      selectedRange: _dashlet.selectedRange,
      period: _dashlet.period,
      selected: true,
      accountName,
      workspaceName,
    };
  });
}
