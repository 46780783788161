import { TWorkspaceUser } from '@project/view-models';
import { Base64 } from 'js-base64';

export function generateNewCommentBase64(user: TWorkspaceUser): string {
  return Base64.encode(
    JSON.stringify({
      ops: [
        {
          insert: ' ',
        },
        {
          insert: {
            mention: {
              denotationChar: '@',
              id: user.userId,
              index: '0',
              value: user.userName,
            },
          },
        },
        {
          insert: ' ',
        },
        {
          insert: '\n',
        },
      ],
    }),
  );
}
