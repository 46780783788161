import { Component, Input, OnChanges } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TPushNotification } from '../../notification.model';

@Component({
  selector: 'pp-notification-flag',
  templateUrl: './notification-flag.component.html',
  styleUrls: ['./notification-flag.component.scss'],
})
export class NotificationFlagComponent implements OnChanges {
  @Input() ppNotification: TPushNotification<boolean>;

  EIconPath = EIconPath;
  flagged: boolean;

  ngOnChanges(): void {
    this.flagged = this.ppNotification.changeBody.newValue;
  }
}
