import { Component, OnDestroy, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Observable, Subject, of } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { checkEmailsValidity } from 'src/app/project/shared/utils/check-emails';
import { AccountSharesService } from '../../../share/account-shares.service';
import { TShare } from '../../../share/share.model';
import { WorkspaceService } from '../../../workspace/workspace.service';
import { AccountService } from '../../account-service/account.service';
import { TAccount } from '../../account.model';
import { PrepareShareService } from '../account-user-modal/account-user-modal-shares-list/account-user-modal-shares-list-share/prepare-share.service';
import { EditAccountUserModalDataService } from '../account-user-modal/edit-account-user-modal-data.service';
import { EEditAccountUserStep } from '../account-user-modal/edit-account-user-modal.consts';
import { EditAccountUserModalService } from '../account-user-modal/edit-account-user-modal.service';
import { AddUsersIssuesService } from './add-users-issues.service';

@Component({
  selector: 'pp-add-users-modal',
  templateUrl: './add-users-modal.component.html',
  styleUrls: ['./add-users-modal.component.scss'],
})
export class AddUsersModalComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  processing: boolean;
  savingShare: boolean;
  account: TAccount;
  currentStep: EEditAccountUserStep = EEditAccountUserStep.SELECT_SITES;
  EEditAccountUserStep = EEditAccountUserStep;
  customFieldsWorkspaceId: string = null;
  showingError: boolean;
  customFieldsWorkspaceName: string;
  nextStepButtonText: string;
  nextStepButtonTooltip: string;
  isNextStepButtonDisabled: boolean;

  // TODO: shareBackup should be private, refactor tests
  shareBackup: TShare;
  private accountId: string;
  private newUserEmails: string[] = [];
  private isAnyEmailInvalid: boolean;
  private isAnyUserWithError: boolean;
  private isAnyUserDuplicated: boolean;

  constructor(
    private promptService: PromptService,
    private modalService: ModalService,
    private translationPipe: TranslationPipe,
    private accountService: AccountService,
    private editAccountUserModalService: EditAccountUserModalService,
    private accountSharesService: AccountSharesService,
    private editAccountUserModalDataService: EditAccountUserModalDataService,
    private prepareShareService: PrepareShareService,
    private addUsersIssuesService: AddUsersIssuesService,
    private workspaceService: WorkspaceService,
  ) {
    this.subscribeToEmailListChange();
  }

  ngOnInit() {
    this.setAccountId();
    this.editAccountUserModalDataService.clearData();
    this.fetchAccountShares();
    this.setNextStepButtonText();
    this.addUsersIssuesService.clearData();
  }

  ngOnDestroy(): void {
    this.addUsersIssuesService.clearData();

    this.destroy$.next();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  nextStep(): void {
    this.showingError = false;

    switch (this.currentStep) {
      case EEditAccountUserStep.SELECT_SITES:
        this.goToSharesListStepFromSelectSitesStep();
        break;
      case EEditAccountUserStep.SHARES_LIST:
        this.savingShare = true;
        this.saveShares();
        break;
      case EEditAccountUserStep.CUSTOM_FIELDS_EDIT:
        this.goToSharesListStepFromCustomFieldsEditStep(false);
        break;
    }

    this.setNextStepButtonText();
  }

  previousStep(): void {
    this.showingError = false;

    switch (this.currentStep) {
      case EEditAccountUserStep.SHARES_LIST:
        this.goToSelectSitesStepFromSharesListStep();
        break;
      case EEditAccountUserStep.CUSTOM_FIELDS_EDIT:
        this.goToSharesListStepFromCustomFieldsEditStep(true);
        break;
      case EEditAccountUserStep.SELECT_SITES:
        this.hideModal();
        break;
    }

    this.setNextStepButtonText();
  }

  goToCustomFieldsEditStep(workspaceId: string): void {
    this.setCurrentStep(EEditAccountUserStep.CUSTOM_FIELDS_EDIT);
    this.setCustomFieldsWorkspace(workspaceId);
    this.setShareBackup();
    this.setNextStepButtonTooltip();
    this.setIsNextStepButtonDisabled();
    this.setNextStepButtonText();
  }

  private setCurrentStep(step: EEditAccountUserStep): void {
    this.currentStep = step;
  }

  private setShareBackup(): void {
    this.shareBackup = cloneDeep(
      this.prepareShareService.prepareShare(this.customFieldsWorkspaceId),
    );
  }

  private subscribeToEmailListChange(): void {
    this.editAccountUserModalDataService.emailListChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.handleEmailListChange();
      });
  }

  private handleEmailListChange(): void {
    this.setNewUserEmails();
    this.setIsAnyEmailInvalid();
    this.setIsAnyUserWithError();
    this.setIsAnyUserDuplicated();
    this.setNextStepButtonTooltip();
    this.setIsNextStepButtonDisabled();
  }

  private goToSharesListStepFromSelectSitesStep(): void {
    this.setCurrentStep(EEditAccountUserStep.SHARES_LIST);
    this.setIsAnyUserWithError();
    this.setNextStepButtonTooltip();
    this.setIsNextStepButtonDisabled();
  }

  private goToSharesListStepFromCustomFieldsEditStep(canceledStep: boolean): void {
    if (canceledStep) {
      this.editAccountUserModalDataService.setShare(this.customFieldsWorkspaceId, this.shareBackup);
    }

    this.setCurrentStep(EEditAccountUserStep.SHARES_LIST);
    this.setCustomFieldsWorkspace(null);
    this.addUsersIssuesService.clearData();
    this.setIsAnyUserWithError();
    this.setNextStepButtonTooltip();
    this.setIsNextStepButtonDisabled();
  }

  private goToSelectSitesStepFromSharesListStep(): void {
    this.setCurrentStep(EEditAccountUserStep.SELECT_SITES);
    this.setNextStepButtonTooltip();
    this.setIsNextStepButtonDisabled();
    this.addUsersIssuesService.clearData();
  }

  private saveShares(isFromChangeLimitModal: boolean = false): Promise<boolean> {
    this.showingError = false;

    return new Promise((resolve) => {
      const newShares = this.editAccountUserModalDataService.getSharesMap();

      this.editAccountUserModalService
        .createShares(newShares, this.newUserEmails)
        .pipe(
          tap(() => {
            this.handleAddUsersSuccess(isFromChangeLimitModal);
            resolve(true);
          }),
          catchError((error) => this.handleAddUsersError(error)),
        )
        .subscribe();
    });
  }

  private handleAddUsersError(error: Response): Observable<null> {
    this.savingShare = false;

    if (error.status === EStatusCode.BAD_REQUEST) {
      this.handleBadRequestError(error);
    } else if (error.status === EStatusCode.UPGRADE_REQUIRED) {
      this.handleUpgradeRequiredError();
    } else {
      this.handleUnknownError();
    }

    return of(null);
  }

  private handleBadRequestError(error: Response): void {
    this.showingError = true;

    this.addUsersIssuesService
      .handleCreateAccountUserError(error)
      .pipe(
        tap(() => {
          this.setIsAnyUserWithError();
          this.setNextStepButtonTooltip();
          this.setIsNextStepButtonDisabled();
        }),
      )
      .subscribe();
  }

  private handleUpgradeRequiredError(): void {
    this.editAccountUserModalService.showAddUserLimitModal({
      userEmails: this.newUserEmails,
      accountId: this.accountId,
      callback: (cancelled) => this.handleUpgradeRequiredErrorCallback(cancelled),
      newShares: this.editAccountUserModalDataService.getSharesMap(),
    });
  }

  private handleUnknownError(): void {
    const promptText = this.translationPipe.transform('prompt_share_error');

    this.promptService.showError(promptText);
  }

  private handleAddUsersSuccess(isFromChangeLimitsModal: boolean): void {
    const prompt = this.translationPipe.transform('prompt_user_shares_created');
    this.promptService.showSuccess(prompt);

    if (!isFromChangeLimitsModal) {
      this.modalService.hideModal(false);
    }
  }

  private fetchAccountShares(): void {
    this.processing = true;
    this.account = this.accountService.getAccount(this.accountId);

    this.accountSharesService
      .fetchAccountShares(this.accountId)
      .pipe(
        tap(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  private handleUpgradeRequiredErrorCallback(cancelled: boolean): Promise<boolean> {
    if (!cancelled) {
      return this.saveShares(true);
    }

    return Promise.resolve(true);
  }

  private setAccountId(): void {
    this.accountId = this.modalService.getModal().data.accountId;
  }

  private setNextStepButtonText(): void {
    this.nextStepButtonText = this.translationPipe.transform(
      this.currentStep === EEditAccountUserStep.SELECT_SITES
        ? 'next'
        : this.currentStep === EEditAccountUserStep.SHARES_LIST
        ? 'add_users'
        : 'save_site_permissions',
    );
  }

  private setCustomFieldsWorkspace(workspaceId: string) {
    this.customFieldsWorkspaceId = workspaceId;
    this.customFieldsWorkspaceName = workspaceId
      ? this.workspaceService.getWorkspace(this.customFieldsWorkspaceId).siteName
      : null;
  }

  private setNewUserEmails(): void {
    this.newUserEmails = this.editAccountUserModalDataService.getEmailList();
  }

  private setIsAnyEmailInvalid(): void {
    this.isAnyEmailInvalid = !checkEmailsValidity(this.newUserEmails);
  }

  private setIsAnyUserWithError(): void {
    const userEmailsWithErrors = this.addUsersIssuesService.getUserEmailsWithErrors();

    this.isAnyUserWithError = userEmailsWithErrors.some((userEmailWithErrors) =>
      this.newUserEmails.includes(userEmailWithErrors),
    );
  }

  private setIsAnyUserDuplicated(): void {
    const warningMessages = this.addUsersIssuesService.getWarningMessages();
    const isDuplicatedEmailWarning = warningMessages.includes(
      this.translationPipe.transform('warning_duplicate_emails'),
    );

    this.isAnyUserDuplicated = isDuplicatedEmailWarning;
  }

  private setNextStepButtonTooltip(): void {
    this.nextStepButtonTooltip = null;

    if (this.currentStep !== EEditAccountUserStep.SHARES_LIST) {
      return;
    }

    if (this.isAnyUserDuplicated) {
      this.nextStepButtonTooltip = this.translationPipe.transform('duplicate_emails_tooltip');
      return;
    }

    if (this.isAnyEmailInvalid) {
      this.nextStepButtonTooltip = this.translationPipe.transform('prompt_email_invalid');
      return;
    }

    if (this.isAnyUserWithError) {
      this.nextStepButtonTooltip = this.translationPipe.transform(
        'user_management_add_users_error_tooltip',
      );
      return;
    }
  }

  private setIsNextStepButtonDisabled(): void {
    this.isNextStepButtonDisabled =
      this.currentStep === EEditAccountUserStep.SHARES_LIST &&
      (this.isAnyUserDuplicated || this.isAnyEmailInvalid || this.isAnyUserWithError);
  }
}
