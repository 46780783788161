import { Component, Injector, OnDestroy } from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { setSecondaryDropdown } from 'src/app/project/components/dropdown/secondary-dropdown';
import { ESavedView } from '../models/saved-view.enum';
import { SavedViewsService } from '../saved-views.service';
import { TSavedViews } from '../models/saved-views.model';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { SavedViewsButtonComponent } from '../saved-views-button/saved-views-button.component';

@Component({
  selector: 'pp-saved-views-dropdown',
  templateUrl: './saved-views-dropdown.component.html',
  styleUrls: ['./saved-views-dropdown.component.scss'],
  providers: [DropdownService],
})
export class SavedViewsDropdownComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  injectedDropdownService: DropdownService;
  dropdownVisible: boolean;
  selectedOption: ESavedView = ESavedView.PERSONAL;
  savedViews: TSavedViews;
  workspaceId: string;
  ESavedView = ESavedView;

  savedViewsButtonComponent: SavedViewsButtonComponent;

  constructor(
    private injector: Injector,
    private savedViewsService: SavedViewsService,
    private activeService: ActiveService,
  ) {
    // TODO Move to other component to be used thoughout the app
    // Separate instance of a dropdown service to allow 2 dropdowns to be opened at the same time
    this.injectedDropdownService = this.injector.get(DropdownService);
    setSecondaryDropdown(this.injectedDropdownService);
    this.workspaceId = this.activeService.getActiveWorkspaceId();

    this.savedViews = this.savedViewsService.getSavedViews(this.workspaceId);
    this.setSelectedViewId();

    this.savedViewsService.viewsChange$
      .pipe(
        takeUntil(this.destroy$),
        tap((views) => {
          this.savedViews = views[this.workspaceId];
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  setSelectedViewId(): void {
    const selectedViewId = this.savedViewsService.getSelectedViewId(this.workspaceId);
    selectedViewId
      ? this.setActiveSwitch(selectedViewId)
      : (this.selectedOption = ESavedView.PERSONAL);
  }

  setActiveSwitch(viewId: string): void {
    const personalViews = this.savedViews.personal.views;
    personalViews.find((view) => view.id === viewId)
      ? (this.selectedOption = ESavedView.PERSONAL)
      : (this.selectedOption = ESavedView.SHARED);
  }
}
