<pp-advanced-filter-option-select
  class="advancedFilter__option"
  [ppItems]="ADVANCED_FILTER_PRIORITY_OPTIONS"
  [ppOption]="option"
  (ppOptionChange)="changeFieldCondition($event)"
  ppClass="advancedFilter__option--condition"
></pp-advanced-filter-option-select>

<pp-advanced-filter-option-select
  class="advancedFilter__option"
  [ppItems]="ADVANCED_FILTER_PRIORITY_VALUES"
  [ppOption]="value[0]"
  (ppOptionChange)="changeFieldValue($event)"
  *ngIf="option === EAdvancedFilterOptions.IS || option === EAdvancedFilterOptions.IS_NOT"
  ppClass="advancedFilter__option--long"
></pp-advanced-filter-option-select>

<pp-select-multiple
  class="advancedFilter__option"
  ppClass="advancedFilter__option--long"
  [ppId]="'priorityFilter' + ppIndex"
  [ppItems]="ADVANCED_FILTER_PRIORITY_VALUES"
  [ppNoSearchBar]="true"
  *ngIf="
    option === EAdvancedFilterOptions.IS_ANY_OF || option === EAdvancedFilterOptions.IS_NONE_OF
  "
  (ppSelectItem)="changeFieldValueMultiple()"
  ppIconClass="setSize12"
></pp-select-multiple>
