import { Injectable } from '@angular/core';
import { Chart } from 'chart.js';
import { map } from 'rxjs';
import { translate } from 'src/app/project/features/translate/translate';
import { EStatus } from 'src/app/project/shared/enums/status.enum';
import { WorkspaceService } from '../../../workspace/workspace.service';
import { TExportDashlet } from '../../dashboard-export-modal/dashboard-export-dashlet.model';
import { DashboardService } from '../../dashboard-service/dashboard.service';
import { TDashletStackedResponse } from '../../dashboard.consts';
import { TDashletGraph } from '../../dashlet-graph.model';
import { generateStackedBarGraphOptions } from './generate-stacked-bar-graph-options';

@Injectable({
  providedIn: 'root',
})
export class CreateStackedStatusGraphService {
  constructor(
    private workspaceService: WorkspaceService,
    private dashboardService: DashboardService,
  ) {}

  createStackedStatusGraph(response: TDashletStackedResponse, isExport: boolean): TDashletGraph {
    const labels = Object.keys(response).map((workspaceId) => {
      const workspace = this.workspaceService.getWorkspace(workspaceId);

      return workspace ? workspace.siteName : '';
    });

    const data = {
      labels: labels,
      datasets: [
        {
          label: translate('open'),
          data: this.getStatusData(response, EStatus.OPEN),
          backgroundColor: '#3170A7',
        },
        {
          label: translate('in_progress'),
          data: this.getStatusData(response, EStatus.IN_PROGRESS),
          backgroundColor: '#6BC8F9',
        },
        {
          label: translate('to_review'),
          data: this.getStatusData(response, EStatus.TO_REVIEW),
          backgroundColor: '#F3DB12',
        },
        {
          label: translate('on_hold'),
          data: this.getStatusData(response, EStatus.ONHOLD),
          backgroundColor: '#FF9801',
        },
        {
          label: translate('completed'),
          data: this.getStatusData(response, EStatus.CLOSED),
          backgroundColor: '#65B92E',
        },
        {
          label: translate('canceled'),
          data: this.getStatusData(response, EStatus.CANCELED),
          backgroundColor: '#838B99',
        },
      ],
    };

    return {
      type: 'bar',
      data: data,
      options: generateStackedBarGraphOptions(isExport),
    };
  }

  fetchExportStackedStatusGraph(
    dashlet: TExportDashlet,
    graph: TDashletGraph,
    canvas: HTMLCanvasElement,
  ) {
    return this.dashboardService
      .fetchStackedStatus({
        accountId: dashlet.selectedRange.accountId,
        workspaceIds: dashlet.selectedRange.workspaceIds,
      })
      .pipe(
        map((response) => {
          const context = canvas.getContext('2d');
          graph = this.createStackedStatusGraph(response, true);

          graph.options = { ...graph.options, animation: false, responsive: true };
          const chart = new Chart(context, graph);

          return {
            chart,
            totalPoints: 0,
          };
        }),
      );
  }

  private getStatusData(response: TDashletStackedResponse, status: EStatus): number[] {
    return Object.keys(response).map((workspaceId) => {
      return response[workspaceId].statuses[status] || 0;
    });
  }
}
