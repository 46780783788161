import { TDatabase } from './database.model';

export const MAINv3: TDatabase = {
  name: 'main',
  version: 3,
  objectStores: [
    {
      name: 'base',
      keyPath: 'type',
    },
    {
      name: 'users',
      keyPath: 'userId',
    },
    {
      name: 'shares',
      keyPath: 'shareId',
      keyPathIndex: 'workspaceId',
      index: 'worksapceId',
    },
    {
      name: 'skins',
      keyPath: 'accountRefId',
    },
    {
      name: 'accounts',
      keyPath: '_id',
    },
    {
      name: 'accounts_simple',
      keyPath: 'id',
    },
    {
      name: 'workspaces',
      keyPath: '_id',
    },
    {
      name: 'workspace_preferences',
      keyPath: '_id',
      keyPathIndex: 'workspaceId',
      index: 'worksapceId',
    },
    {
      name: 'export_preferences',
      keyPath: '_id',
      keyPathIndex: 'workspaceId',
      index: 'worksapceId',
    },
    {
      name: 'workspace_shares',
      keyPath: 'share.shareId',
      keyPathIndex: 'share.userId',
      index: 'userId',
    },
    {
      name: 'tiles',
      keyPath: 'workspaceId',
    },
    {
      name: 'points',
      keyPath: '_id',
    },
  ],
};
