<pp-select
  [ppItems]="items"
  [ppSelectedItem]="items[selectedIndex]"
  (ppSelectItem)="selectItem($event)"
  [ppNoSearchBar]="false"
  [ppDisabled]="ppDisabled"
  require
>
  <ng-container selected>
    <span class="fleetAccountSelect__content" *ngIf="selectedIndex !== -1">
      {{ items[selectedIndex].label }}
    </span>

    <span class="fleetAccountSelect__placeholder" *ngIf="selectedIndex === -1">
      {{ 'choose_account' | ppTranslate }}
    </span>
  </ng-container>
</pp-select>
