import { GET_TIMELINE_COLUMNS as getColumns } from './timeline-columns';

export type TTimeframeElement = {
  name: string;
  secondaryName: string;
  width: number;
};

export type TTimelineColumns = {
  topWidth: number;
  bottomWidth: number;

  topElements: TTimeframeElement[];
  bottomElements: string[];
  timeframes: number[];
};

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const GET_TIMELINE_COLUMNS = (): TTimelineColumns => getColumns();
