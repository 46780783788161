export enum EWhiteLabelFont {
  SOURCE_SANS_PRO = 'Source Sans Pro',
  WORK_SANS = "'Work Sans', sans-serif;",
  OPEN_SANS = "'Open Sans', sans-serif;",
  RALEWAY = "'Raleway', sans-serif",
  GEORGIA = 'Georgia',
  MONTSERRAT = "'Montserrat', sans-serif",
  COMIC_SANS_MS = "'Comic Sans MS', sans-serif",
  FUTURA_PT = 'Futura PT',
}
