import { Routes } from '@angular/router';

import { COMMON_GUARD_SERVICES } from '../../services/guards/guard-services.const';

import { UserSettingsComponent } from './user-settings/user-settings.component';

export const userRoutes: Routes = [
  {
    path: 'settings/user',
    component: UserSettingsComponent,
    canActivate: COMMON_GUARD_SERVICES,
  },
];
