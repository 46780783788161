import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { SelectedSharesService } from 'src/app/project/modules/user-management/services/selected-shares.service';

@Component({
  selector: 'pp-site-settings-users-select-cell',
  templateUrl: './site-settings-users-select-cell.component.html',
  styleUrls: ['./site-settings-users-select-cell.component.scss'],
})
export class SiteSettingsUsersSelectCellComponent implements OnInit, OnDestroy {
  @Input() ppShareId: string;
  @Input() ppDisabled: boolean;

  private readonly destroy$ = new EventEmitter<void>();

  isSelected: boolean;

  constructor(private selectedSharesService: SelectedSharesService) {}

  ngOnInit(): void {
    this.isSelected = this.selectedSharesService.getSelectedSharesIds().includes(this.ppShareId);

    this.selectedSharesService.selectedSharesIdsChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedShares) => {
        this.isSelected = selectedShares.includes(this.ppShareId);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  selectCell(): void {
    if (this.ppDisabled) {
      return;
    }

    this.selectedSharesService.toggleSiteSelected(this.ppShareId);
  }
}
