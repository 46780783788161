import { TDashlet } from 'src/app/project/modules/preferences/preferences.model';
import { EDashletPeriod } from '../dashboard-timeframes-enums';
import { EDashletType } from '../dashlets-enums';

export const DEFAULT_DASHLETS: TDashlet[] = [
  {
    x: 0,
    y: 0,
    rows: 18,
    cols: 24,
    selectedRange: {
      accountId: null,
      workspaceIds: [],
    },
    period: null,
    name: EDashletType.ACTIVITIES,
  },
  {
    x: 24,
    y: 0,
    rows: 6,
    cols: 6,
    selectedRange: {
      accountId: null,
      workspaceIds: [],
    },
    period: EDashletPeriod.WEEK,
    name: EDashletType.DEFECT_CREATE,
  },
  {
    x: 24,
    y: 6,
    rows: 6,
    cols: 6,
    selectedRange: {
      accountId: null,
      workspaceIds: [],
    },
    period: EDashletPeriod.WEEK,
    name: EDashletType.COMMENT_CREATE,
  },
  {
    x: 24,
    y: 12,
    rows: 6,
    cols: 6,
    selectedRange: {
      accountId: null,
      workspaceIds: [],
    },
    period: EDashletPeriod.WEEK,
    name: EDashletType.CLOSED,
  },
  {
    x: 0,
    y: 18,
    rows: 10,
    cols: 15,
    selectedRange: {
      accountId: null,
      workspaceIds: [],
    },
    period: null,
    name: EDashletType.CURRENT_STATUS,
  },
  {
    x: 15,
    y: 18,
    rows: 10,
    cols: 15,
    selectedRange: {
      accountId: null,
      workspaceIds: [],
    },
    period: null,
    name: EDashletType.CURRENT_PRIORITY,
  },
  {
    x: 0,
    y: 28,
    rows: 10,
    cols: 15,
    selectedRange: {
      accountId: null,
      workspaceIds: [],
    },
    period: EDashletPeriod.WEEK,
    name: EDashletType.OVER_TIME_STATUS,
  },
  {
    x: 15,
    y: 28,
    rows: 10,
    cols: 15,
    selectedRange: {
      accountId: null,
      workspaceIds: [],
    },
    period: EDashletPeriod.WEEK,
    name: EDashletType.OVER_TIME_PRIORITY,
  },
];
