import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { SetBasicFetched } from 'src/app/project/modules/offline/sync.actions';
import { SetActiveWorkspaceId } from 'src/app/project/services/active/active.actions';

import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { SidePanelService } from 'src/app/project/modules/layout/side-panel/side-panel.service';
import { SyncService } from 'src/app/project/modules/offline/sync.service';
import { PointsFetchingService } from 'src/app/project/modules/points/points-fetching.service';
import { PreferencesService } from 'src/app/project/modules/preferences/preferences-service/preferences.service';
import { SiteService } from 'src/app/project/modules/site/site.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { SiteDataService, TSiteData } from '../../site/site-data.service';
import { RemindersService } from '../reminders.service';

import { TUISettings } from '../../ui/ui.model';

import { Title } from '@angular/platform-browser';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { TSidePanel } from 'src/app/project/modules/layout/side-panel/side-panel.model';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { TAccount } from '../../account/account.model';

@Component({
  selector: 'pp-reminders',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.scss'],
})
export class RemindersComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  site: TSiteData = this.siteDataService.getSite();
  sidePanel: TSidePanel = this.sidePanelService.getSidePanel();
  dataFetched = false;
  filterDataFetched = false;

  offline$: Observable<boolean>;
  offline = false;

  ui$: Observable<TUISettings>;
  ui: TUISettings;
  routerPath = this.router.url;

  constructor(
    private store: Store<{
      ui: TUISettings;
      offline: boolean;
    }>,
    private sidePanelService: SidePanelService,
    private siteDataService: SiteDataService,
    private workspaceService: WorkspaceService,
    private remindersService: RemindersService,
    private accountService: AccountService,
    private syncService: SyncService,
    private preferencesService: PreferencesService,
    private pointsFetchingService: PointsFetchingService,
    private siteService: SiteService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private router: Router,
    private titleService: Title,
  ) {
    this.ui$ = this.store.pipe(select(EStore.UI));
    this.offline$ = this.store.pipe(select(EStore.OFFLINE));

    this.offline$.pipe(takeUntil(this.destroy$)).subscribe((offline) => {
      this.offline = offline;
    });

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.routerPath = event.url;
      }
    });
  }

  ngOnInit() {
    this.titleService.setTitle(this.translationPipe.transform('reminders') + ' | Pinpoint Works');

    this.sidePanelService.initSidePanel({ expandedInitially: false });

    this.ui$.pipe(takeUntil(this.destroy$)).subscribe((ui) => {
      this.ui = ui;
    });

    this.initReminders();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private initReminders(): void {
    let accounts = this.accountService.getAccounts();
    const workspaces = this.workspaceService.getWorkspaces();

    if (!this.offline && (!Array.isArray(accounts) || Object.entries(workspaces).length === 0)) {
      this.store.dispatch(new SetActiveWorkspaceId(null));
      this.firstLoad();
    } else {
      accounts = this.accountService.getAccounts();

      let remindersEnabled = this.checkRemindersEnabled(accounts);

      if (!remindersEnabled) {
        this.redirectFromReminders();
      } else {
        this.store.dispatch(new SetActiveWorkspaceId(null));

        this.workspaceService
          .generateWorkspaces()
          .pipe(
            takeUntil(this.destroy$),
            switchMap(() => this.preferencesService.fetchPreferences()),
            tap(() => {
              this.filterDataFetched = true;

              accounts = this.accountService.getAccounts();
              remindersEnabled = this.checkRemindersEnabled(accounts);

              if (!remindersEnabled) {
                this.redirectFromReminders();
              } else {
                this.fetchReminders();
              }
            }),
          )
          .subscribe();
      }
    }
  }

  private firstLoad(): void {
    this.syncService
      .firstLoad()
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => this.workspaceService.generateWorkspaces()),
        switchMap(() => this.preferencesService.fetchPreferences()),
        tap(() => {
          this.filterDataFetched = true;

          const accounts = this.accountService.getAccounts();
          const remindersEnabled = this.checkRemindersEnabled(accounts);

          if (!remindersEnabled) {
            this.redirectFromReminders();
          } else {
            this.remindersService.getRemindersForCurrentDay();
            this.fetchReminders();
          }
        }),
      )
      .subscribe();
  }

  private fetchRemindersPoints(): void {
    const reminders = this.remindersService.getReminders();
    const pointList: string[] = [];

    reminders.forEach((reminder) => {
      if (reminder.refObject?.id) {
        pointList.push(reminder.refObject.id);
      }
    });

    this.pointsFetchingService
      .fetchPointsByIds(pointList)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.dataFetched = true;

          this.siteService.setFetched(true);
          this.store.dispatch(new SetBasicFetched(true));
        }),
      )
      .subscribe();
  }

  private redirectFromReminders(): void {
    const prompt = this.translationPipe.transform('prompt_reminder_access_denied');

    this.promptService.showError(prompt);
    this.router.navigate(['/site']);
  }

  private checkRemindersEnabled(accounts: TAccount[]): boolean {
    return !!accounts.some((account) => !!account.accountFeatures.reminders);
  }

  private fetchReminders(): void {
    this.remindersService
      .fetchReminders()
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.fetchRemindersPoints();
        }),
      )
      .subscribe();
  }
}
