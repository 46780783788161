import { TSelectOption } from 'src/app/project/components/input/select/select.model';
import { translate } from 'src/app/project/features/translate/translate';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';

export function getAdvancedCheckboxOptions(): TSelectOption[] {
  return [
    {
      value: EAdvancedFilterOptions.IS_CHECKED,
      label: translate('is_checked') + '.',
    },
    {
      value: EAdvancedFilterOptions.IS_NOT_CHECKED,
      label: translate('is_unchecked') + '.',
    },
  ];
}
