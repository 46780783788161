import { EFormulaOperator } from './formula-operators.enum';

export enum EFormulaPieceType {
  OPERATOR = 'operator',
  CUSTOM_FIELD = 'custom_field',
}

export type TFormulaPiece = {
  type: EFormulaPieceType;
  value: string | EFormulaOperator;
};
