import { EBasicField, EBasicFieldShort } from 'src/app/project/shared/enums/basic-fields-enums';
import { TPoint } from '../../points/points.model';
import { isPointFlagged } from '../../points/utils/is-flagged';
import { EAdvancedFilterType } from '../models/advanced-filter-type.enum';

export function filterIdToPointProperty(id: string, point: TPoint): any {
  switch (id) {
    case null:
      return null;
    case EBasicField.PRIORITY:
      return point.priority;
    case EBasicField.STATUS:
      return point.status;
    case EBasicField.ASSIGNEES:
      return point.assignees;
    case EBasicField.TITLE:
      return point.title;
    case EBasicField.DESCRIPTION:
      return point.description;
    case EBasicField.CREATED:
      return point.header.createdEpochMillis;
    case EBasicField.UPDATED:
      return point.header.updatedEpochMillis;
    case EBasicField.CREATED_BY:
      return point.header.createdBy.id;
    case EBasicField.TAGS:
      return point.tags;
    case EBasicField.SEQUENCE_NUMBER:
    case EBasicFieldShort.SEQUENCE_NUMBER:
      return point.sequenceNumber;
    case EAdvancedFilterType.LOCATION:
      return point.polygons?.length > 0 || point.pins?.length > 0;
    case EAdvancedFilterType.REMINDER:
      return point.reminderEpochMillis;
    case EAdvancedFilterType.FLAGGED:
      return isPointFlagged(point);
    default: {
      return point.customFieldsMap?.[id]?.value;
    }
  }
}
