<pp-asset-card-information-toggle
  class="assetCard__toggle"
  (ppToggle)="setToggle($event)"
></pp-asset-card-information-toggle>

<div *ngIf="isDetailsToggled" class="assetCard__information">
  <pp-asset-card-information-details [ppDetails]="ppDetails"></pp-asset-card-information-details>
</div>

<div *ngIf="!isDetailsToggled" class="assetCard__information" (scrollend)="logLabelsScroll()">
  <pp-asset-card-information-labels
    class="assetCard__labels"
    [ppLabels]="ppLabels"
  ></pp-asset-card-information-labels>
</div>
