<div class="assetDashboard" [@openClose]="isOpen ? 'open' : 'closed'">
  <gridster
    class="dashboard__content"
    [options]="gridsterOptions"
    *ngIf="dashlets.length > 0 && defaultWorkspaces"
  >
    <gridster-item
      [item]="dashlet"
      *ngFor="let dashlet of dashlets"
      [ngClass]="dashlet.name"
      class="dashboard__gridster-item"
    >
      <pp-dashlet
        [ppCanEdit]="canEdit"
        [ppDashlet]="dashlet"
        [ppAssetId]="ppAsset.id"
        [ppDefaultWorkspaces]="defaultWorkspaces"
        (ppUpdateUserDashlets)="updateUserDashlets()"
      ></pp-dashlet>
    </gridster-item>
  </gridster>

  <div class="dashboard__content dashboard__empty" *ngIf="dashlets.length === 0">
    <div class="dashboard__emptyMessage">
      <p class="dashboard__emptyMessageText">
        {{ 'empty_asset_dashboard_message' | ppTranslate }}
      </p>

      <pp-button ppStyle="secondary" (click)="addDashlet()" *ngIf="canEdit">
        <div class="dashboard__emptyMessageButton">
          {{ 'add_dashlet' | ppTranslate }}

          <pp-icon
            [ppSrc]="EIconPath.ICON_NAV_PLUS_14"
            ppClass="setSize14"
            ppColor="inherit"
          ></pp-icon>
        </div>
      </pp-button>
    </div>
  </div>
</div>
