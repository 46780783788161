import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import {
  setChangedWorkspace,
  setChangedWorkspaces,
} from 'src/app/project/modules/workspace/workspace';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSettings,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { ChangeLimitModalComponent } from '../../../components/change-limit-modal/change-limit-modal.component';
import { TChangeLimitsModalData } from '../../../components/change-limit-modal/change-limit-modal.model';
import { ModalService } from '../../../components/modal/modal.service';
import { TSimulatedUsageBody } from '../../account/account.model';
import { SiteSettingsUsersService } from '../../site/site-settings/site-settings-users/site-settings-users.service';
import { WorkspaceService } from '../../workspace/workspace.service';
import { ShareBulkService } from '../share-bulk.service';
import { EUserRole } from '../share-utils/user-roles';
import { TCreateShareDTO, TNewUser } from '../share.model';
import { SharesService } from '../shares.service';

@Injectable({
  providedIn: 'root',
})
export class ShareModalService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly shouldCloseModal = new Subject<boolean>();

  constructor(
    private sharesService: SharesService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private modalService: ModalService,
    private siteSettingsUsersService: SiteSettingsUsersService,
    private shareBulkService: ShareBulkService,
    private workspaceService: WorkspaceService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  saveShareFromMultipleEmails(
    share: TCreateShareDTO,
    emails: string[],
    workspaceId: string,
  ): Observable<TNewUser[]> {
    const sharesToSend: TCreateShareDTO[] = [];
    const workspace = this.workspaceService.getWorkspace(workspaceId);

    emails.forEach((email) => {
      const newShare = { ...share };
      newShare.userEmail = email;
      sharesToSend.push(newShare);
    });

    return this.createMultipleShares(sharesToSend, workspace, workspaceId);
  }

  private createMultipleShares(
    sharesToSend: TCreateShareDTO[],
    workspace: TWorkspace,
    workspaceId: string,
  ): Observable<TNewUser[]> {
    return this.shareBulkService.bulkCreateShare(sharesToSend).pipe(
      tap((response) => {
        this.handleBulkSharesCreateSuccess(response, workspace, workspaceId);
      }),
      catchError((error) => this.handleBulkSharesCreateError(error, sharesToSend, workspace)),
    );
  }

  private handleBulkSharesCreateError(
    error: { status: number },
    sharesToSend: TCreateShareDTO[],
    workspace: TWorkspace,
  ): Observable<never> {
    logErrorInSentry(error);
    this.shouldCloseModal.next(false);

    switch (error.status) {
      case 400:
        {
          const promptText = this.translationPipe.transform('prompt_share_error_already_shared');

          this.promptService.showWarning(promptText);
        }

        break;
      case 426:
        this.showCreateUserLimitModal(sharesToSend, workspace);

        break;
      default:
        {
          const promptText = this.translationPipe.transform('prompt_share_error');

          this.promptService.showError(promptText);
        }
        break;
    }

    return throwError(error);
  }

  private handleBulkSharesCreateSuccess(
    response: TNewUser[],
    workspace: TWorkspace,
    workspaceId: string,
  ): void {
    logEventInGTAG(EGoogleEventSettings.SETTINGS__USER__NEW, {
      event_category: EGoogleEventCategory.SETTINGS,
    });

    this.addWorkspaceShares(response);
    this.siteSettingsUsersService.newShareCloseTriggerEvent();
    this.setCreatedUsers(response, workspace, workspaceId);
    this.modalService.hideModal();
    this.showBulkSharesCreatePrompt();
    setChangedWorkspaces(true);
    setChangedWorkspace(workspaceId);
  }

  private setCreatedUsers(response: TNewUser[], workspace: TWorkspace, workspaceId: string): void {
    response.forEach((user) => {
      this.sharesService.setCreatedUser(workspace, user, workspaceId);
    });
  }

  private addWorkspaceShares(response: TNewUser[]): void {
    const shares = response.map((shareResponse) => shareResponse.share);

    this.sharesService.addWorkspaceShares(shares);
  }

  private showBulkSharesCreatePrompt(): void {
    const promptText = this.translationPipe.transform('prompt_site_shared');
    this.promptService.showSuccess(promptText);
  }

  private showCreateUserLimitModal(sharesToSend: TCreateShareDTO[], workspace: TWorkspace): void {
    const changedLimits: TSimulatedUsageBody = {
      createList: sharesToSend.map((user) => ({
        shareOption: user.shareOption as EUserRole,
        userEmail: user.userEmail,
      })),
      editList: [],
      importList: [],
      deleteList: [],
    };

    const modalData: TChangeLimitsModalData = {
      firstMessageKey: 'users_limit_reached_description_1',
      secondMessageKey: 'users_limit_reached_description_2',
      confirmKey: 'add_user',
      header: 'users_limit_reached',
      accountId: workspace.accountId,
      changedLimits,
    };

    this.modalService.hideModal().then(() => {
      this.modalService.setData<TChangeLimitsModalData>(modalData);

      this.modalService.showModal(ChangeLimitModalComponent, {
        onBeforeClose: (cancelled) => {
          if (!cancelled) {
            this.createMultipleShares(sharesToSend, workspace, workspace.workspaceId).subscribe();
          }

          return this.getShouldCloseModalResult(cancelled);
        },
      });
    });
  }

  private getShouldCloseModalResult(cancelled: boolean): Promise<boolean> {
    if (!cancelled) {
      return this.shouldCloseModal.pipe(takeUntil(this.destroy$), take(1)).toPromise();
    }

    return Promise.resolve(true);
  }
}
