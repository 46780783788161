import {
  Component,
  Output,
  EventEmitter,
  Input,
  AfterViewInit,
  ElementRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'pp-button-modal-cancel',
  templateUrl: './button-modal-cancel.component.html',
  styleUrls: ['./button-modal-cancel.component.scss'],
})
export class ButtonModalCancelComponent implements AfterViewInit {
  @ViewChild('modalCancelButton') cancelButton: ElementRef;
  @Input() ppDataTarget: string;
  @Output() ppAction = new EventEmitter();

  ngAfterViewInit(): void {
    if (this.ppDataTarget) {
      this.cancelButton.nativeElement.setAttribute('data-m-target', this.ppDataTarget);
    }
  }

  action(): void {
    this.ppAction.emit();
  }
}
