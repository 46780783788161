export type TWorkspaceUserDTO = {
  userId: string;
  email: string;
  name: string;
  avatarId: string;
  verified: boolean;
  lastActivityEpochMillis: number;
  avatarPublicUrl: string;
  workspaces: string[];
};

export type TWorkspaceUser = {
  userId: string;
  userName: string;
  email?: string;
  verified?: boolean;
  avatarPublicUrl?: string;
  lastActivityEpochMillis?: number;
  workspaces?: string[];
};

export type TAllUsers = {
  [userId: string]: TWorkspaceUser;
};

export type TImportUser = {
  userName: string;
  email: string;
  userId: string;
  avatarPublicUrl: string;
  primaryImageId: string;
  workspaces: string[];
  verified: boolean;
  lastActivityEpochMillis: number;
};

export class UserViewModelFactory {
  static createFromDTO(dto: TWorkspaceUserDTO[]): TWorkspaceUser[] {
    return dto.map((simpleUser) => ({
      userName: simpleUser.name,
      email: simpleUser.email,
      userId: simpleUser.userId,
      primaryImageId: simpleUser.avatarId,
      workspaces: simpleUser.workspaces,
      verified: simpleUser.verified,
      lastActivityEpochMillis: simpleUser.lastActivityEpochMillis,
      avatarPublicUrl: simpleUser.avatarPublicUrl,
    }));
  }
}
