import { translate } from 'src/app/project/features/translate/translate';
import { EBasicField } from 'src/app/project/shared/enums/basic-fields-enums';
import { TColumn } from '../../../columns/column.model';
import { createFooterNameElement } from './create-footer-name-element';

export function createFooterCellTypeElement(column: TColumn): HTMLElement {
  switch (column.name) {
    case EBasicField.PRIORITY:
    case EBasicField.STATUS:
    case EBasicField.SEQUENCE_NUMBER:
      return createFooterNameElement({ width: column.width, column, center: true });
    case EBasicField.TITLE:
    case EBasicField.DESCRIPTION:
    case EBasicField.CREATED:
    case EBasicField.UPDATED:
    case EBasicField.ASSIGNEES:
    case EBasicField.TAGS:
      return createFooterNameElement({ width: column.width, column, center: false });
    case EBasicField.CREATED_BY:
      return createFooterNameElement({ width: column.width, column, center: false });
    default:
      return createFooterNameElement({ width: column.width, column, center: false });
  }
}
