<tr class="fleetSharingUserRow">
  <pp-checkbox
    ppSize="medium"
    ppType="solid"
    [class.checkbox--hidden]="editPermissions === EFleetShareOption.EDIT"
    [ppChecked]="ppSelected"
    (ppOnValueChange)="toggleShare()"
  ></pp-checkbox>

  <div class="fleetSharingUser">
    <div class="fleetSharingUserWrap">
      <img
        ppImage
        [src]="user?.avatarPublicUrl ? user.avatarPublicUrl : EIconPath.PLACEHOLDER_USER"
        class="fleetSharingUserAvatar"
      />

      <div class="fleetSharingUserDetails">
        <div class="fleetSharingUserDetails--name">
          {{ user?.userName }}
        </div>

        <div class="fleetSharingUserDetails--email">
          {{ user?.email }}
        </div>
      </div>
    </div>
  </div>

  <div class="fleetSharingDelete">
    <span class="fleetSharingAdminInfo" *ngIf="editPermissions === EFleetShareOption.EDIT">
      {{ 'share_fleet_modal_admin_info' | ppTranslate }}
    </span>

    <pp-icon
      *ngIf="editPermissions === EFleetShareOption.READ"
      ppTooltip
      [ppTitle]="'share_fleet_modal_list_delete_tooltip' | ppTranslate"
      class="fleetSharingDeleteIcon"
      (click)="deleteShare()"
      [ppSrc]="EIconPath.ICON_BIN_18"
      ppClass="setSize18"
      ppColor="red"
    ></pp-icon>
  </div>
</tr>
