import { createPopper } from '@popperjs/core';
import { isString } from 'lodash';

import { TDropdown } from '../dropdown.consts';

import { ComponentType } from '@angular/cdk/overlay';
import { loadComponent, setDropdown, setDropdownModifiers } from './dropdown-service-utils';
import { TDropdownModifiers, TDropdownParams } from './dropdown-service.consts';

export function showDropdown(
  dropdown: TDropdown,
  buttonId: string,
  component: ComponentType<unknown>,
  {
    callback = undefined,
    onClose = undefined,
    popper = undefined,
    modifiers = null,
    suppressScrollbar = false,
    width = null,
    long = false,
    addTagDropdownScrollCallback = false,
    addScrollCallback = false,
    secondary = false,
  }: TDropdownParams = {},
): void {
  if (!buttonId) {
    console.error('Dropdown requires a button id to work');
  }

  modifiers = setDropdownModifiers(modifiers);

  setDropdown({
    dropdown: dropdown,
    visible: true,
    buttonId,
    component,
    long,
    secondary,
  });

  setDropdownProperties(dropdown, {
    callback,
    onClose,
    popper,
    width,
  });

  dropdown.visibilityHidden = true;

  createDropdown({
    dropdown,
    addScrollCallback,
    addTagDropdownScrollCallback,
    modifiers,
    suppressScrollbar,
  }).then(() => {
    dropdown.visibilityHidden = false;
  });
}

function setDropdownProperties(
  dropdown: TDropdown,
  {
    callback = undefined,
    onClose = undefined,
    popper = undefined,
    width = null,
  }: TDropdownParams = {},
): void {
  if (width) {
    dropdown.data.width = width;
  }

  if (callback) {
    dropdown.callback = callback;
  }

  if (onClose) {
    dropdown.onClose = onClose;
  }

  if (popper && popper.placement) {
    dropdown.popper.placement = popper.placement;
  }

  if (popper) {
    if (popper.placement) {
      dropdown.popper.placement = popper.placement;
    }

    if (popper.positionFixed) {
      dropdown.popper.positionFixed = popper.positionFixed;
    }
  }

  if (isString(dropdown.buttonId)) {
    dropdown.buttonElement = document.getElementById(dropdown.buttonId);
  } else {
    dropdown.buttonElement = dropdown.buttonId;
  }
}

const createDropdown = ({
  dropdown,
  addScrollCallback,
  addTagDropdownScrollCallback,
  modifiers,
  suppressScrollbar,
}: {
  dropdown: TDropdown;
  addScrollCallback: boolean;
  addTagDropdownScrollCallback: boolean;
  modifiers: TDropdownModifiers;
  suppressScrollbar: boolean;
}): Promise<void> => {
  if (dropdown.buttonElement && dropdown.dropdownElement && dropdown.popper.placement) {
    return new Promise((resolve) => {
      dropdown.popper.instance = createPopper(dropdown.buttonElement, dropdown.dropdownElement, {
        placement: dropdown.popper.placement,
        strategy: dropdown.popper.positionFixed ? 'fixed' : 'absolute',
        modifiers: modifiers,
      });

      window.addEventListener('keydown', dropdown.onEsc, true);
      window.addEventListener('click', dropdown.onOutsideClick, true);

      if (addScrollCallback) {
        window.addEventListener('scroll', dropdown.onScroll, true);
      }

      if (addTagDropdownScrollCallback) {
        window.addEventListener('scroll', dropdown.tagDropdownScrollCallback, true);
      }

      if (dropdown.component) {
        loadComponent(dropdown);
      }

      if (suppressScrollbar) {
        dropdown.dropdownElement.setAttribute('style', 'overflow-y: hidden !important');
      } else {
        dropdown.dropdownElement.setAttribute('style', 'overflow-y: unset');
      }

      dropdown.changeObserver = new MutationObserver(() => {
        if (dropdown.popper.instance) {
          dropdown.popper.instance.forceUpdate();
          dropdown.popper.instance.update();
        }
      });

      dropdown.changeObserver.observe(dropdown.buttonElement, {
        subtree: true,
        attributes: true,
        childList: true,
        characterData: true,
      });

      setTimeout(() => {
        if (dropdown.popper.instance) {
          dropdown.popper.instance.update().then(() => {
            resolve();
          });
        }
      });
    });
  }

  return new Promise(() => {});
};
