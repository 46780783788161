import { EIconPath } from './enums/icons.enum';
import { EStatus } from './enums/status.enum';

export function statusToImage(status: EStatus, white: boolean): EIconPath {
  switch (status) {
    case EStatus.CANCELED:
      return white ? EIconPath.STATUS_CANCELED_WHITE : EIconPath.STATUS_CANCELED;
    case EStatus.CLOSED:
      return white ? EIconPath.STATUS_CLOSED_WHITE : EIconPath.STATUS_CLOSED;

    case EStatus.IN_PROGRESS:
      return white ? EIconPath.STATUS_IN_PROGRESS_WHITE : EIconPath.STATUS_IN_PROGRESS;
    case EStatus.ONHOLD:
      return white ? EIconPath.STATUS_ON_HOLD_WHITE : EIconPath.STATUS_ON_HOLD;

    case EStatus.OPEN:
      return white ? EIconPath.STATUS_OPEN_WHITE : EIconPath.STATUS_OPEN;

    case EStatus.TO_REVIEW:
      return white ? EIconPath.STATUS_TO_REVIEW_WHITE : EIconPath.STATUS_TO_REVIEW;
  }
}

export function getTableStatusSrc(status: EStatus): EIconPath {
  switch (status) {
    case EStatus.CANCELED:
      return EIconPath.ICON_TABLE_CANCELED;
    case EStatus.CLOSED:
      return EIconPath.ICON_TABLE_CLOSED;

    case EStatus.IN_PROGRESS:
      return EIconPath.ICON_TABLE_IN_PROGRESS;
    case EStatus.ONHOLD:
      return EIconPath.ICON_TABLE_ON_HOLD;

    case EStatus.OPEN:
      return EIconPath.ICON_TABLE_OPEN;

    case EStatus.TO_REVIEW:
      return EIconPath.ICON_TABLE_TO_REVIEW;
  }
}
