<div class="dashletBody__options">
  <div class="dashletBody__event-period" [ngSwitch]="ppDashlet.period">
    <h6 *ngSwitchCase="dashletPeriods.DAY">{{ 'today' | ppTranslate }}</h6>
    <h6 *ngSwitchCase="dashletPeriods.WEEK">{{ 'this_week' | ppTranslate }}</h6>
    <h6 *ngSwitchCase="dashletPeriods.LAST_WEEK">{{ 'last_7_days' | ppTranslate }}</h6>
    <h6 *ngSwitchCase="dashletPeriods.MONTH">{{ 'this_month' | ppTranslate }}</h6>
    <h6 *ngSwitchCase="dashletPeriods.LAST_MONTH">{{ 'last_30_days' | ppTranslate }}</h6>
    <h6 *ngSwitchCase="dashletPeriods.YEAR">{{ 'this_year' | ppTranslate }}</h6>
    <h6 *ngSwitchCase="dashletPeriods.LAST_YEAR">{{ 'last_365_days' | ppTranslate }}</h6>
  </div>
</div>

<div class="dashletBody__event-count">
  {{ eventsNumber }}
</div>

<div class="dashletBody__event-title" [ngSwitch]="ppDashlet.name">
  <ng-container *ngSwitchCase="dashletTypes.DEFECT_CREATE">
    <ng-container *ngIf="eventsNumber !== 1">
      {{ 'dashlet_export_points_created' | ppTranslate }}
    </ng-container>

    <ng-container *ngIf="eventsNumber === 1">
      {{ 'point_created_one' | ppTranslate }}
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="dashletTypes.COMMENT_CREATE">
    <ng-container *ngIf="eventsNumber !== 1">
      {{ 'comments_made' | ppTranslate }}
    </ng-container>

    <ng-container *ngIf="eventsNumber === 1">
      {{ 'comment_created_one' | ppTranslate }}
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="dashletTypes.HIGH">
    <ng-container *ngIf="eventsNumber !== 1">
      {{ 'points_changed_high_priority_other' | ppTranslate }}
    </ng-container>

    <ng-container *ngIf="eventsNumber === 1">
      {{ 'points_changed_high_priority_one' | ppTranslate }}
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="dashletTypes.MEDIUM">
    <ng-container *ngIf="eventsNumber !== 1">
      {{ 'points_changed_medium_priority_other' | ppTranslate }}
    </ng-container>

    <ng-container *ngIf="eventsNumber === 1">
      {{ 'points_changed_medium_priority_one' | ppTranslate }}
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="dashletTypes.LOW">
    <ng-container *ngIf="eventsNumber !== 1">
      {{ 'points_changed_low_priority_other' | ppTranslate }}
    </ng-container>

    <ng-container *ngIf="eventsNumber === 1">
      {{ 'points_changed_low_priority_one' | ppTranslate }}
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="dashletTypes.OPEN">
    <ng-container *ngIf="eventsNumber !== 1">
      {{ 'points_changed_to_open_dashboard_other' | ppTranslate }}
    </ng-container>

    <ng-container *ngIf="eventsNumber === 1">
      {{ 'points_changed_to_open_dashboard_one' | ppTranslate }}
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="dashletTypes.IN_PROGRESS">
    <ng-container *ngIf="eventsNumber !== 1">
      {{ 'points_changed_to_in_progress_dashboard_other' | ppTranslate }}
    </ng-container>

    <ng-container *ngIf="eventsNumber === 1">
      {{ 'points_changed_to_in_progress_dashboard_one' | ppTranslate }}
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="dashletTypes.ONHOLD">
    <ng-container *ngIf="eventsNumber !== 1">
      {{ 'points_changed_to_on_hold_dashboard_other' | ppTranslate }}
    </ng-container>

    <ng-container *ngIf="eventsNumber === 1">
      {{ 'points_changed_to_on_hold_dashboard_one' | ppTranslate }}
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="dashletTypes.CLOSED">
    <ng-container *ngIf="eventsNumber !== 1">
      {{ 'points_changed_to_completed_dashboard_other' | ppTranslate }}
    </ng-container>

    <ng-container *ngIf="eventsNumber === 1">
      {{ 'points_changed_to_completed_dashboard_one' | ppTranslate }}
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="dashletTypes.TO_REVIEW">
    <ng-container *ngIf="eventsNumber !== 1">
      {{ 'points_changed_to_review_dashboard_other' | ppTranslate }}
    </ng-container>

    <ng-container *ngIf="eventsNumber === 1">
      {{ 'points_changed_to_review_dashboard_one' | ppTranslate }}
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="dashletTypes.CANCELED">
    <ng-container *ngIf="eventsNumber !== 1">
      {{ 'points_changed_to_canceled_dashboard_other' | ppTranslate }}
    </ng-container>

    <ng-container *ngIf="eventsNumber === 1">
      {{ 'points_changed_to_canceled_dashboard_one' | ppTranslate }}
    </ng-container>
  </ng-container>
</div>
