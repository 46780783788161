<ng-container *ngFor="let option of toggles">
  <h5 class="accountOptions__header">{{ option.label }}</h5>

  <div class="accountOptions__item">
    <div>
      <pp-select
        [ppItems]="option.items"
        [ppSelectedItem]="option.items[option.index]"
        (ppSelectItem)="selectItem(option, $event)"
        [ppNoSearchBar]="true"
        require
      >
        <ng-container selected>
          <span class="accountSettings__select-content" *ngIf="option.index !== -1">
            {{ option.items[option.index].label }}
          </span>

          <span class="accountSettings__select-placeholder" *ngIf="option.index === -1">
            {{ option.placeholder }}
          </span>
        </ng-container>
      </pp-select>
    </div>
  </div>
</ng-container>
