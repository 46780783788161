import { Component } from '@angular/core';

import { ModalService } from 'src/app/project/components/modal/modal.service';
import { CookieService } from 'src/app/project/services/cookie.service';
import { PLAN_COOKIE_CLEAR_NAME } from '../../../../../shared/constants/point.plan.constants';

@Component({
  selector: 'pp-point-plan-clear-all-modal',
  templateUrl: './point-plan-clear-all-modal.component.html',
  styleUrls: ['./point-plan-clear-all-modal.component.scss'],
})
export class PointPlanClearAllModalComponent {
  dontShowAgain = false;

  constructor(private modalService: ModalService, private cookieService: CookieService) {}

  hideModal(cancel: boolean): void {
    this.saveCookie();

    this.modalService.hideModal(cancel);
  }

  saveCookie(): void {
    if (this.dontShowAgain) {
      this.cookieService.setCookie(PLAN_COOKIE_CLEAR_NAME, 'true');
    }
  }

  checkCookies(): void {
    this.dontShowAgain = !this.dontShowAgain;
  }
}
