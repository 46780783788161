import { TAccount } from 'src/app/project/modules/account/account.model';
import { TDashlet } from 'src/app/project/modules/preferences/preferences.model';

export function checkAccount(ppDashlet: TDashlet, accounts: TAccount[]): TAccount {
  let account: TAccount;

  if (accounts.length > 0 && ppDashlet.selectedRange.accountId) {
    account = accounts.find(
      (searchedAccount) => searchedAccount.accountId === ppDashlet.selectedRange.accountId,
    );

    if (!account) {
      account = null;
    }
  }

  return account;
}
