import { Component } from '@angular/core';

@Component({
  selector: 'pp-dashlet-activity-new-point',
  templateUrl: './dashlet-activity-new-point.component.html',
  styleUrls: ['../dashlet-activity.component.scss'],
})
export class DashletActivityNewPointComponent {
  constructor() {}
}
