import { EWhiteLabelFont } from './white-label-fonts.enum';

export type TWhiteLabelFontOption = {
  label: string;
  value: EWhiteLabelFont;
};

export const WHITE_LABEL_FONTS: TWhiteLabelFontOption[] = [
  { label: 'Source Sans Pro', value: EWhiteLabelFont.SOURCE_SANS_PRO },
  { label: 'Work Sans', value: EWhiteLabelFont.WORK_SANS },
  { label: 'Open Sans', value: EWhiteLabelFont.OPEN_SANS },
  { label: 'Raleway', value: EWhiteLabelFont.RALEWAY },
  { label: 'Georgia', value: EWhiteLabelFont.GEORGIA },
  { label: 'Montserrat', value: EWhiteLabelFont.MONTSERRAT },
  { label: 'Comic Sans MS', value: EWhiteLabelFont.COMIC_SANS_MS },
  { label: 'Futura PT', value: EWhiteLabelFont.FUTURA_PT },
];
