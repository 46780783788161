import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { EDashletType } from '../../dashlets-enums';
import { ADVANCED_DASHLETS } from '../new-dashlet-advanced-dashlets';
import { TNewDashletData } from '../new-dashlet-data.model';
import { SIMPLE_DASHLETS } from '../new-dashlet-simple-dashlets';
import { ENewDashletTabs } from '../new-dashlet-tabs-enum';

@Component({
  selector: 'pp-new-dashlet-modal-dashlets',
  templateUrl: './new-dashlet-modal-dashlets.component.html',
  styleUrl: './new-dashlet-modal-dashlets.component.scss',
})
export class NewDashletModalDashletsComponent implements OnChanges {
  @Input() ppAssetId: string;
  @Output() ppAddDashlet: EventEmitter<EDashletType> = new EventEmitter<EDashletType>();

  currentTab: string;
  simpleDashlets: TNewDashletData[];
  advancedDashlets: TNewDashletData[];
  dashletTabs = ENewDashletTabs;
  EDashletType = EDashletType;
  settingCostFields = false;

  ngOnChanges(): void {
    this.currentTab = ENewDashletTabs.SIMPLE_DASHLETS;
    this.simpleDashlets = SIMPLE_DASHLETS;
    this.advancedDashlets = ADVANCED_DASHLETS.filter(
      (dashlet) =>
        (dashlet.value !== EDashletType.CURRENT_STACKED_STATUS &&
          dashlet.value !== EDashletType.COST_COMPARISON) ||
        this.ppAssetId,
    );
  }

  setTab(tab: ENewDashletTabs): void {
    this.currentTab = tab;
  }

  addDashlet(newDashlet: EDashletType): void {
    this.ppAddDashlet.emit(newDashlet);
  }
}
