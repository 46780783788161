import { Injectable } from '@angular/core';
import {
  API_white_label,
  API_white_label_add_image,
  API_white_label_delete_image,
  API_white_label_image,
} from '@core/api/paths';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { TWhiteLabel } from 'src/app/project/modules/white-label/white-label.model';

@Injectable({
  providedIn: 'root',
})
export class WhiteLabelApiProviderService {
  constructor(private apiService: ApiService) {}

  createWhiteLabel(accountId: string, body: TWhiteLabel): Observable<TWhiteLabel> {
    const url = API_white_label(accountId);

    return this.apiService.put<TWhiteLabel>(url, body);
  }

  updateWhiteLabel(accountId: string, body: TWhiteLabel): Observable<TWhiteLabel> {
    const url = API_white_label(accountId);

    return this.apiService.post<TWhiteLabel>(url, body);
  }

  uploadLogo(accountId: string, formData: FormData): Observable<string> {
    const url = API_white_label_image(accountId);

    return this.apiService.postWithFormData<string>(url, formData);
  }

  uploadPdfLogo(targetId: string, targetType: string, imageId: string): Observable<string> {
    const url = API_white_label_add_image(targetId, targetType, imageId);

    return this.apiService.post<string>(url, null);
  }

  deletePdfLogo(targetId: string, targetType: string): Observable<string> {
    const url = API_white_label_delete_image(targetId, targetType);

    return this.apiService.delete<string>(url);
  }

  fetchWhiteLabel(accountId: string, refetch: boolean): Observable<TWhiteLabel> {
    const url = API_white_label(accountId);

    return this.apiService.get<TWhiteLabel>(url);
  }
}
