import { TWhiteLabelStore } from '../white-label.model';
import {
  DEFAULT_PRIMARY_100,
  DEFAULT_PRIMARY_200,
  DEFAULT_PRIMARY_300,
  DEFAULT_PRIMARY_400,
  DEFAULT_PRIMARY_50,
  DEFAULT_PRIMARY_500,
  DEFAULT_PRIMARY_600,
  DEFAULT_PRIMARY_700,
  DEFAULT_PRIMARY_75,
  DEFAULT_PRIMARY_800,
  DEFAULT_PRIMARY_900,
} from './white-label-defaults.const';

export function setPrimary50(themeWrapper: HTMLBodyElement, whiteLabel: TWhiteLabelStore): void {
  themeWrapper.style.setProperty('--primary50', whiteLabel.data.primary50);
}

export function setPrimary75(themeWrapper: HTMLBodyElement, whiteLabel: TWhiteLabelStore): void {
  themeWrapper.style.setProperty('--primary75', whiteLabel.data.primary75);
}

export function setPrimary100(themeWrapper: HTMLBodyElement, whiteLabel: TWhiteLabelStore): void {
  themeWrapper.style.setProperty('--primary100', whiteLabel.data.primary100);
}

export function setPrimary200(themeWrapper: HTMLBodyElement, whiteLabel: TWhiteLabelStore): void {
  themeWrapper.style.setProperty('--primary200', whiteLabel.data.primary200);
}

export function setPrimary300(themeWrapper: HTMLBodyElement, whiteLabel: TWhiteLabelStore): void {
  themeWrapper.style.setProperty('--primary300', whiteLabel.data.primary300);
}

export function setPrimary400(themeWrapper: HTMLBodyElement, whiteLabel: TWhiteLabelStore): void {
  themeWrapper.style.setProperty('--primary400', whiteLabel.data.primary400);
}

export function setPrimary500(themeWrapper: HTMLBodyElement, whiteLabel: TWhiteLabelStore): void {
  themeWrapper.style.setProperty('--primary500', whiteLabel.data.primary500);
}

export function setPrimary600(themeWrapper: HTMLBodyElement, whiteLabel: TWhiteLabelStore): void {
  themeWrapper.style.setProperty('--primary600', whiteLabel.data.primary600);
}

export function setPrimary700(themeWrapper: HTMLBodyElement, whiteLabel: TWhiteLabelStore): void {
  themeWrapper.style.setProperty('--primary700', whiteLabel.data.primary700);
}

export function setPrimary800(themeWrapper: HTMLBodyElement, whiteLabel: TWhiteLabelStore): void {
  themeWrapper.style.setProperty('--primary800', whiteLabel.data.primary800);
}

export function setPrimary900(themeWrapper: HTMLBodyElement, whiteLabel: TWhiteLabelStore): void {
  themeWrapper.style.setProperty('--primary900', whiteLabel.data.primary900);
}

export function setDefaultPrimary50(themeWrapper: HTMLBodyElement): void {
  themeWrapper.style.setProperty('--primary50', DEFAULT_PRIMARY_50);
}

export function setDefaultPrimary75(themeWrapper: HTMLBodyElement): void {
  themeWrapper.style.setProperty('--primary75', DEFAULT_PRIMARY_75);
}

export function setDefaultPrimary100(themeWrapper: HTMLBodyElement): void {
  themeWrapper.style.setProperty('--primary100', DEFAULT_PRIMARY_100);
}

export function setDefaultPrimary200(themeWrapper: HTMLBodyElement): void {
  themeWrapper.style.setProperty('--primary200', DEFAULT_PRIMARY_200);
}

export function setDefaultPrimary300(themeWrapper: HTMLBodyElement): void {
  themeWrapper.style.setProperty('--primary300', DEFAULT_PRIMARY_300);
}

export function setDefaultPrimary400(themeWrapper: HTMLBodyElement): void {
  themeWrapper.style.setProperty('--primary400', DEFAULT_PRIMARY_400);
}

export function setDefaultPrimary500(themeWrapper: HTMLBodyElement): void {
  themeWrapper.style.setProperty('--primary500', DEFAULT_PRIMARY_500);
}

export function setDefaultPrimary600(themeWrapper: HTMLBodyElement): void {
  themeWrapper.style.setProperty('--primary600', DEFAULT_PRIMARY_600);
}

export function setDefaultPrimary700(themeWrapper: HTMLBodyElement): void {
  themeWrapper.style.setProperty('--primary700', DEFAULT_PRIMARY_700);
}

export function setDefaultPrimary800(themeWrapper: HTMLBodyElement): void {
  themeWrapper.style.setProperty('--primary800', DEFAULT_PRIMARY_800);
}

export function setDefaultPrimary900(themeWrapper: HTMLBodyElement): void {
  themeWrapper.style.setProperty('--primary900', DEFAULT_PRIMARY_900);
}
