import { TTranslationKey } from '../../../features/translate/types';

export type TNotificationLabels = {
  [key: string]: TTranslationKey;
};

export const notificationLabels: TNotificationLabels = {
  IMMEDIATE: 'instant_notifications',
  DIGEST_SIX: 'notifications_digest_six',
  DIGEST_DAY: 'notifications_digest_day',
  DIGEST_WEEK: 'notification_digest_week',
  NONE: 'notification_none',
};
