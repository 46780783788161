<li
  ppTooltip
  ppTitle="Reminders"
  class="mainNav__element mainNav__element--with-image"
  [class.mainNav__element--reminders]="!ppIsLandscape"
  [class.mainNav__element--active]="ppRouterPath.startsWith('/reminders')"
  [class.mainNav__element--disabled]="ppDisabled"
  [ppOptions]="{ placement: 'right' }"
  (click)="goToReminders()"
>
  <img
    id="reminderMenuItem"
    data-m-target="Reminder icon"
    class="filterPanel__tooltip remindersIcon"
    [src]="
      remindersNumber.number > 0
        ? EIconPath.SIDE_PANEL_REMINDER_NOTIFICATION
        : EIconPath.SIDE_PANEL_REMINDER
    "
  />
  <h6
    class="filterPanel__title filterPanel__title--img"
    *ngIf="ppIsMobile && !sidePanel.rightPanelExpanded"
  >
    {{ 'reminders' | ppTranslate }}
  </h6>
</li>
