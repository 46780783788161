import isNumber from 'lodash/isNumber';
import { TPoint } from 'src/app/project/modules/points/points.model';
import { TPlanData } from '../../plan/plan-data.service';
import { areaToPolygon } from '../../points/point-generate/area-to-polygon';
import { TFilters } from '../site-filter.model';

export function checkLocation(point: TPoint, filters: TFilters, plan: TPlanData): boolean {
  if (!plan.instance.site) {
    return true;
  }

  const planView = plan.instance.site.getView();

  if (planView) {
    const extent = planView.calculateExtent(plan.instance.site.getSize());

    if (
      extent.length &&
      isNumber(extent[0]) &&
      isNumber(extent[1]) &&
      isNumber(extent[2]) &&
      isNumber(extent[3])
    ) {
      const planExtent = {
        x1: extent[0],
        y1: extent[1],
        x2: extent[2],
        y2: extent[3],
      };

      if (point.polygons?.length > 0) {
        const planLengthX = Math.ceil(planExtent.x2 - planExtent.x1);
        const planLengthY = Math.ceil(planExtent.y2 - planExtent.y1);
        const polygons = areaToPolygon(point.polygons);

        for (let i = 0; i < 10; i++) {
          const xcoord = planExtent.x1 + (planLengthX / 10) * i;
          for (let j = 0; j < 10; j++) {
            const ycoord = planExtent.y1 + (planLengthY / 10) * j;

            for (const polygon of polygons) {
              if (inside([xcoord, ycoord], polygon[0])) {
                return true;
              }
            }
          }
        }

        for (const polygon of polygons) {
          for (let i = 0; i < polygon[0].length; i++) {
            const coordinates = polygon[0][i];

            const inRangeX =
              Math.abs(coordinates[0]) > planExtent.x1 && Math.abs(coordinates[0]) < planExtent.x2;
            const inRangeY =
              -Math.abs(coordinates[1]) < planExtent.y2 &&
              -Math.abs(coordinates[1]) > planExtent.y1;

            if (polygon && inRangeX && inRangeY) {
              return true;
            }
          }
        }
      }

      if (point.pins?.length > 0) {
        for (const pin of point.pins) {
          const inRangeX = Math.abs(pin.x) > planExtent.x1 && Math.abs(pin.x) < planExtent.x2;
          const inRangeY = -Math.abs(pin.y) < planExtent.y2 && -Math.abs(pin.y) > planExtent.y1;

          if (pin && inRangeX && inRangeY) {
            return true;
          }
        }
      } else if (!(point.polygons?.length > 0)) {
        return filters.showPointsWithoutLocation;
      }
    }
  }

  return false;
}

function inside(point: number[], vs: number[]): boolean {
  // ray-casting algorithm based on
  // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html

  const x = point[0],
    y = point[1];

  let isInside = false;

  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const xi = vs[i][0],
      yi = vs[i][1];
    const xj = vs[j][0],
      yj = vs[j][1];

    const intersect = yi >= y != yj >= y && x <= ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) {
      isInside = !isInside;
    }
  }

  return isInside;
}
