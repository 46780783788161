import { Component, Input } from '@angular/core';
import { EIconPath } from '../../../../../../../shared/enums/icons.enum';
import { TActivityChange } from '../../activity-change.model';

@Component({
  selector: 'pp-dashlet-activity-videos',
  templateUrl: './dashlet-activity-videos.component.html',
  styleUrls: ['../../dashlet-activity.component.scss'],
})
export class DashletActivityVideosComponent {
  @Input() ppChange: TActivityChange<string[]>;

  EIconPath = EIconPath;
}
