import { TAllUsers } from '@project/view-models';
import { TPoint } from '../../points/points.model';

export function fillAssigneesValue(point: TPoint, users: TAllUsers): string {
  const assignees: string[] = [];

  point.assignees.forEach((userId) => {
    if (users[userId]) {
      assignees.push(users[userId].userName);
    }
  });

  return assignees.join(' ');
}
