import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TCustomField } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { GET_CUSTOM_FIELDS } from 'src/app/project/modules/custom-fields/custom-fields.store';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';
import {
  TAdvancedFilterList,
  TAdvancedFilterListValue,
  TAdvancedFilterSingle,
  TFilterListOptions,
} from '../../../../models/advanced-filter.model';
import { getAdvancedListOptions } from './advanced-filter-list-utils';

@Component({
  selector: 'pp-advanced-filter-list',
  templateUrl: './advanced-filter-list.component.html',
  styleUrls: ['./advanced-filter-list.component.scss'],
})
export class AdvancedFilterListComponent {
  @Input() ppFilter: TAdvancedFilterSingle;
  @Output() ppFilterChange = new EventEmitter<TAdvancedFilterSingle>();

  value: TAdvancedFilterListValue;
  option: TFilterListOptions;
  filter: TAdvancedFilterList;
  unit: string;
  field: TCustomField;

  advancedFilterListOptions = getAdvancedListOptions();
  EAdvancedFilterOptions = EAdvancedFilterOptions;

  ngOnChanges(): void {
    this.filter = this.ppFilter as TAdvancedFilterList;

    this.value = this.filter.value;
    this.option = this.filter.option;
    this.field = GET_CUSTOM_FIELDS()[this.filter.id];
  }

  changeFieldCondition(newCondition: TFilterListOptions): void {
    this.filter.option = newCondition;
    this.option = newCondition;
    this.applyChanges();
  }

  applyChanges(): void {
    this.ppFilter = this.filter;
    this.ppFilterChange.emit(this.ppFilter);
  }

  changeFieldValue(value: TAdvancedFilterListValue): void {
    this.filter.value = value;
    this.applyChanges();
  }
}
