<div
  ppTooltip
  id="select__close"
  class="select__closeBtn"
  *ngIf="isMobile || isTablet"
  [ppTitle]="'close_select_dropdown' | ppTranslate"
  (click)="hideDropdown()"
>
  <img
    [src]="EIconPath.INTERACTION_CLOSE"
    class="point__control-icon--close"
    id="closeModalBtn_icon"
  />
</div>
