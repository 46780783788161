import { cloneDeep } from 'lodash';
import { TWhiteLabelStore } from '../white-label.model';

export function clearWhiteLabel(oldWhiteLabel: TWhiteLabelStore): TWhiteLabelStore {
  const whiteLabel = cloneDeep(oldWhiteLabel);

  whiteLabel.data = {};
  whiteLabel.created = false;
  whiteLabel.accountId = null;

  return whiteLabel;
}
