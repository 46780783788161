import { Component, Input } from '@angular/core';
import { EAuthorizationProgressBarStep } from './authorization-progress-bar.component.consts';

@Component({
  selector: 'pp-authorization-progress-bar',
  templateUrl: './authorization-progress-bar.component.html',
  styleUrls: ['./authorization-progress-bar.component.scss'],
})
export class AuthorizationProgressBarComponent {
  @Input() ppStep: EAuthorizationProgressBarStep;

  EAuthorizationProgressBarStep = EAuthorizationProgressBarStep;
}
