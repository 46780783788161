import { timelineProperties, TIMELINE_TIMEOUT_DURATION } from './timeline-variables';
import { handleTimelineMousemove } from './handle-timeline-mouse-move';
import Timeline from '../../Timeline';

export function onTimelineBasicElementMouseMove({
  _event,
  element,
  startPosition,
  difference,
  elementRect,
  scrollMoveChangeAmount,
  mouseMoveChangeAmount,
  timeline,
}: {
  _event: MouseEvent;
  element: HTMLElement;
  startPosition: number;
  difference: number;
  elementRect: DOMRect;
  scrollMoveChangeAmount: number;
  mouseMoveChangeAmount: number;
  timeline: Timeline;
}): number {
  _event.preventDefault();

  element.style.transform = `translateX(${
    startPosition - difference + (_event.x - elementRect.left) + scrollMoveChangeAmount
  }px)`;

  mouseMoveChangeAmount = _event.x - elementRect.left;

  timeline.setResizing(true);
  clearTimeout(timelineProperties.stationaryTimeout);

  timelineProperties.stationaryTimeout = setTimeout(() => {
    timelineProperties.stationary = true;

    handleTimelineMousemove(_event);
  }, TIMELINE_TIMEOUT_DURATION);

  timelineProperties.stationary = false;
  return mouseMoveChangeAmount;
}
