import * as Sentry from '@sentry/browser';
import { cloneDeep } from 'lodash';
import { VERSION } from '../../../../environments/version';

import { HostListener, Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { TUserResponse } from './response-models/user-response-model';
import { ClearUser, SetUser, UpdateUserName, UpdateUserType } from './user.actions';
import { TUser } from './user.model';
import { SET_USER } from './user.store';

import { UserApiProviderService } from '@core/api';
import { IntercomService } from '../../services/intercom.service';
import { AuthService } from '../auth/auth.service';
import { ResponseErrorService } from '../errors/response-error.service';

import { DOCUMENT } from '@angular/common';
import { WindowService } from '@core/services';
import { takeUntil } from 'rxjs/operators';
import { routeToUrl } from 'src/app/core/helpers/route-to-url';
import { getUserAuth } from 'src/app/core/http/user-auth';
import {
  TUpdateUserRequest,
  TUpdateUserType,
} from '../../data-providers/api-providers/user-api-provider/user-requests.model';
import { EAuthRoute } from '../../shared/constants/auth.constants';
import { EStore } from '../../shared/enums/store.enum';
import { logout$ } from '../auth/logout.service';
import { getSentryEnabled, logErrorInSentry } from '../errors/response-error';
import { SUPPORTED_BROWSERS_PATH } from '../supported-browsers/supported-browsers.constants';

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  private user$: Observable<TUser>;
  private user: TUser;

  private readonly destroy$ = new Subject<void>();
  private window = this.windowService.getGlobalObject();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<{ user: TUser }>,
    private router: Router,
    private authService: AuthService,
    private intercomService: IntercomService,
    private userApiProviderService: UserApiProviderService,
    private responseErrorService: ResponseErrorService,
    private windowService: WindowService,
  ) {
    this.user$ = this.store.pipe(select(EStore.USER));

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = cloneDeep(user);

      SET_USER(cloneDeep(user));
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  @HostListener('window:focus', ['$event'])
  onWindowFocus(): void {
    if (
      this.router.url.includes('passwords') ||
      this.router.url.includes('verify') ||
      this.router.url.includes(EAuthRoute.SIGNUP) ||
      this.router.url.includes(SUPPORTED_BROWSERS_PATH) ||
      this.router.url.includes('reset') ||
      this.router.url.includes(EAuthRoute.LOGIN)
    ) {
      return;
    }

    const userAuth = getUserAuth();

    if (userAuth?.email && this.user?.email) {
      if (userAuth?.email !== this.user.email) {
        this.logout();
      }
    } else if (!userAuth?.email) {
      this.logout();
    }
  }

  getUser(): TUser {
    return this.user;
  }

  clearUser(): void {
    this.store.dispatch(new ClearUser());
  }

  generateUser(entity: TUserResponse, isSuperUser: boolean): TUser {
    let unreadNotifications = false;

    if (entity.notificationStatus) {
      unreadNotifications = entity.notificationStatus.unreadNotifications;
    }

    return {
      createdOn: entity.header.createdEpochMillis,
      activeWorkspaceId: entity.activeWorkspaceRef ? entity.activeWorkspaceRef.id : null,
      accountId: entity.accountRef ? entity.accountRef.id : null,
      isSuperUser,
      email: entity.email,
      userName: entity.name,
      userType: entity.userType,
      userId: entity._id,
      intercomHash: entity.intercomHash,
      avatarId: entity.images.length > 0 ? entity.images[0].id : null,
      unreadNotifications,
      enabled2fa: entity.enabled2fa,
      setup2faAfter: entity.setup2faAfter,
    };
  }

  fetchUser(): Observable<TUserResponse> {
    return this.userApiProviderService.fetchUser().pipe(
      catchError((error) => {
        this.authService.clearAuth();
        this.responseErrorService.errors(error.status);
        logErrorInSentry(error);

        this.intercomService.shutdownIntercom();

        return throwError(error);
      }),
      switchMap((userResponse) => {
        return this.userApiProviderService.fetchIsSuperUser().pipe(
          map((isSuperUserResponse) => {
            const event = this.document.createEvent('Event');
            const newUser = this.generateUser(userResponse, isSuperUserResponse);

            event.initEvent('login', true, true);
            this.window.dispatchEvent(event);

            this.intercomService.startIntercom(newUser);
            this.store.dispatch(new SetUser(newUser));
            this.configureSentry(newUser);

            return userResponse;
          }),
          catchError((error) => {
            logErrorInSentry(error);

            throw new Error(error);
          }),
        );
      }),
    );
  }

  updateUserSimplified(userId: string, user: TUpdateUserRequest): Observable<TUserResponse> {
    return this.userApiProviderService
      .updateUserSimplified(userId, user)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  updateUserName(userId: string, userName: string): Observable<TUserResponse> {
    if (!navigator.onLine) {
      return throwError(null);
    }

    const body: TUpdateUserRequest = {
      name: userName,
    };

    return this.updateUserSimplified(userId, body).pipe(
      tap((response) => {
        this.store.dispatch(
          new UpdateUserName({
            userName,
          }),
        );
      }),
    );
  }

  deleteUserAvatar(userId: string): Observable<TUserResponse> {
    return this.userApiProviderService
      .deleteUserAvatar(userId)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  updateUserType(userId: string, userType: string): Observable<TUserResponse> {
    const body: TUpdateUserType = {
      userType,
    };

    return this.userApiProviderService.updateUserType(userId, body).pipe(
      tap((response) => {
        this.store.dispatch(
          new UpdateUserType({
            userType,
          }),
        );
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  private logout(): void {
    logout$.next();

    this.router.navigate([routeToUrl(EAuthRoute.LOGIN)]);
  }

  private configureSentry(newUser: TUser): void {
    if (getSentryEnabled()) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: newUser.userId,
          username: newUser.userName,
          email: newUser.email,
        });

        scope.setTag('version', VERSION.version);
      });
    }
  }
}
