import Translate from '../Translate';

import { ElementRef, Injectable, OnDestroy } from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { PlanService } from '../plan.service';
import { PlanDataService } from '../plan-data.service';
import { PlanPinsService } from '../plan-pins.service';
import { PlanPointClickService } from './plan-point-click.service';
import { PlanPointVariablesService } from './plan-point-variables.service';
import { FeatureTranslateService } from './feature-translate.service';
import { FeatureDragService } from './feature-drag.service';
import { PolygonDrawService } from './polygon-draw.service';
import { PlanPointDoubleClickService } from './plan-point-double-click.service';
import { PlanPointPointerMoveService } from './plan-point-pointer-move.service';
import { PlanPointPointerMoveEndService } from './plan-point-pointer-move-end.service';
import { PlanPointDragService } from './plan-point-drag.service';
import { FeatureModifyService } from './feature-modify.service';
import { EPlanPointMode } from '../../../shared/enums/plan-point-mode.enum';
import { DeviceService } from 'src/app/core/services/device.service';
import { EPlanModule } from '../plan-module.enum';
import { Coordinate } from 'ol/coordinate';
import { TPin } from '@project/view-models';
import { MapBrowserEvent } from 'ol';

@Injectable({
  providedIn: 'root',
})
export class PlanPointService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private isTouchDevice: boolean = this.deviceService.isTouchDevice();
  private deleteElement: ElementRef;

  private deleteIcon: {
    visible: boolean;
  } = {
    visible: false,
  };

  constructor(
    private planService: PlanService,
    private planDataService: PlanDataService,
    private planPinsService: PlanPinsService,
    private planPointClickService: PlanPointClickService,
    private planPointVariablesService: PlanPointVariablesService,
    private featureTranslateService: FeatureTranslateService,
    private featureDragService: FeatureDragService,
    private polygonDrawService: PolygonDrawService,
    private planPointDoubleClickService: PlanPointDoubleClickService,
    private planPointPointerMoveService: PlanPointPointerMoveService,
    private planPointPointerMoveEndService: PlanPointPointerMoveEndService,
    private planPointDragService: PlanPointDragService,
    private featureModifyService: FeatureModifyService,
    private deviceService: DeviceService,
  ) {
    this.planService.selectionChange$.pipe(takeUntil(this.destroy$)).subscribe((item) => {
      this.planPointVariablesService.setMode(item);
      this.featureModifyService.setModifyingPolygon(false);
      const plan = this.planPointVariablesService.getPlan();
      const mode = this.planPointVariablesService.getMode();

      if (mode === EPlanPointMode.POLYGON || mode === EPlanPointMode.DRAW) {
        this.featureDragService.featureDragOff();
      }

      if (plan.instance.point) {
        // rare cases when entire plan is closed while editing it
        this.planPointClickService.clickEventOff();
        this.planPointClickService.clickEventOn();
      }
    });

    this.planPointVariablesService.setPlan(this.planDataService.getPlan());

    this.planPointVariablesService.setDragInteraction(new Translate());

    this.planPointVariablesService.setPointerMoveListenerRef((event: MapBrowserEvent<MouseEvent>) => {
      this.planPointPointerMoveService.pointermoveListener(event);
      const mode = this.planPointVariablesService.getMode();

      if (this.deleteElement) {
        const deleteIconRect = this.deleteElement.nativeElement.getBoundingClientRect();

        if (!this.isTouchDevice || mode === EPlanPointMode.POLYGON) {
          if (
            event.originalEvent.clientX >= deleteIconRect.x &&
            event.originalEvent.clientX <= deleteIconRect.x + deleteIconRect.width &&
            event.originalEvent.clientY >= deleteIconRect.y &&
            event.originalEvent.clientY <= deleteIconRect.y + deleteIconRect.height
          ) {
            this.deleteElement.nativeElement.classList.add('point__site-plan-delete--hover');
          } else {
            this.deleteElement.nativeElement.classList.remove('point__site-plan-delete--hover');
          }
        } else {
          if (
            event.originalEvent.clientX >= deleteIconRect.x &&
            event.originalEvent.clientX <= deleteIconRect.x + deleteIconRect.width &&
            event.originalEvent.clientY >= deleteIconRect.y + 50 &&
            event.originalEvent.clientY <= deleteIconRect.y + deleteIconRect.height + 50
          ) {
            this.deleteElement.nativeElement.classList.add('point__site-plan-delete--hover');
          } else {
            this.deleteElement.nativeElement.classList.remove('point__site-plan-delete--hover');
          }
        }
      }
    });

    this.planPointVariablesService.setClickListenerRef((event) =>
      this.planPointClickService.clickListener(event),
    );

    this.planPointVariablesService.setDblClickListenerRef((event) =>
      this.planPointDoubleClickService.dblclickListener(event),
    );

    this.planPointVariablesService.setDragListenerRef((event) =>
      this.planPointDragService.dragListener(),
    );

    this.planPointVariablesService.setMoveendListenerRef(() =>
      this.planPointPointerMoveEndService.moveendListener(),
    );

    this.planPointVariablesService.setPolygonDrawStartListenerRef((event) =>
      this.polygonDrawService.polygonDrawStartListener(),
    );

    this.planPointVariablesService.setPolygonDrawEndListenerRef((event) =>
      this.polygonDrawService.polygonDrawEndListener(event),
    );

    this.planPointVariablesService.setPolygonStopModifyListenerRef((event) =>
      this.featureModifyService.polygonStopModifyListener(event),
    );

    this.planPointVariablesService.setPolygonStartModifyListenerRef((event) =>
      this.featureModifyService.polygonStartModifyListener(event),
    );

    this.planPointVariablesService.setEscListenerRef((event) =>
      this.polygonDrawService.escListener(event),
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  setDeleteIconVisibility(visible: boolean): void {
    this.deleteIcon.visible = visible;
  }

  restoreDeletedFeature(deletedFeatureCoords: Coordinate[][]): void {
    const plan = this.planPointVariablesService.getPlan();
    const newFeature = this.planPinsService.addExtraMarker(
      plan.point,
      deletedFeatureCoords as unknown as TPin,
    );

    this.featureTranslateService.createInteraction(newFeature);

    this.featureDragService.featureDragOff();
    this.featureDragService.featureDragOn();
  }

  updateFeature(_id: string, workspaceId: string, edit: boolean = false): void {
    this.planPinsService.addPointMarker(_id, EPlanModule.POINT, workspaceId, edit);
  }

  setDeleteElement(element: ElementRef): void {
    this.deleteElement = element;
  }
}
