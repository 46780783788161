import { Injectable } from '@angular/core';
import { ResponseErrorService } from '../../errors/response-error.service';
import { AuthApiProviderService } from '@core/api';
import { catchError } from 'rxjs/operators';
import {
  TNewUserRequest,
  TResetPasswordRequest,
  TResetUserPasswordResponse,
} from 'src/app/project/view-models/reset-user-password-response.model';
import { TPasswordResetResponse } from 'src/app/project/view-models/password-reset-response.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PasswordUpdateService {
  constructor(
    private responseErrorService: ResponseErrorService,
    private authApiProviderService: AuthApiProviderService,
  ) {}

  getPasswordResetUser(resetId: string): Observable<TPasswordResetResponse> {
    return this.authApiProviderService
      .getPasswordResetUser(resetId)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  resetPassword(
    resetId: string,
    body: TResetPasswordRequest,
  ): Observable<TResetUserPasswordResponse> {
    return this.authApiProviderService
      .resetUserPassword(resetId, body)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  createLoginDetails(
    inviteId: string,
    newUser: TNewUserRequest,
  ): Observable<TResetUserPasswordResponse> {
    return this.authApiProviderService
      .setupPassword(inviteId, newUser)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  checkPasswordLength(password: string): boolean {
    if (password.length < 8 || password.length > 128) {
      return false;
    } else {
      return true;
    }
  }

  checkPasswordUpperCase(password: string): boolean {
    if (password.toLowerCase() !== password) {
      return true;
    } else {
      return false;
    }
  }

  checkPasswordLowerCase(password: string): boolean {
    if (password.toUpperCase() !== password) {
      return true;
    } else {
      return false;
    }
  }

  checkPasswordNumber(password: string): boolean {
    return /\d/.test(password);
  }
}
