import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelectedSharesService {
  private selectedSharesIds: string[] = [];
  private readonly _selectedSharesIdsChange$ = new BehaviorSubject<string[]>(
    this.selectedSharesIds,
  );
  readonly selectedSharesIdsChange$ = this._selectedSharesIdsChange$.asObservable();

  toggleSiteSelected(shareId: string): void {
    if (this.selectedSharesIds.includes(shareId)) {
      this.selectedSharesIds = this.selectedSharesIds.filter(
        (selectedShareId) => selectedShareId !== shareId,
      );
    } else {
      this.selectedSharesIds.push(shareId);
    }

    this.emitSelectedSharesIdsChange();
  }

  selectAllUserShares(shareIds: string[]): void {
    shareIds.forEach((shareId) => {
      if (!this.selectedSharesIds.includes(shareId)) {
        this.selectedSharesIds.push(shareId);
      }
    });

    this.emitSelectedSharesIdsChange();
  }

  clearSelectedShares(): void {
    this.selectedSharesIds = [];
    this.emitSelectedSharesIdsChange();
  }

  getSelectedSharesIds(): string[] {
    return this.selectedSharesIds;
  }

  setSelectedSharesIds(shareIds: string[]): void {
    this.selectedSharesIds = shareIds;
    this.emitSelectedSharesIdsChange();
  }

  private emitSelectedSharesIdsChange(): void {
    this._selectedSharesIdsChange$.next(this.selectedSharesIds);
  }
}
