import { TFilters } from '../site-filter.model';

export function clearDateUpdated(filters: TFilters, applied?: boolean): void {
  filters.date.updated.startDate = null;
  filters.date.updated.endDate = null;

  if (applied) {
    filters.date.updated.appliedValues.startDate = null;
    filters.date.updated.appliedValues.endDate = null;
  }
}
