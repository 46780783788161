import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GET_ACTIVE_PLAN } from 'src/app/project/modules/plan/plan.store';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { TPoint } from '../points.model';
import { PointModalHeaderCollapsedService } from './point-modal-header-collapsed.service';
import { PointModalHeaderFullService } from './point-modal-header-full.service';

@Injectable({
  providedIn: 'root',
})
export class PointModalHeaderService {
  constructor(
    private router: Router,
    private pointModalHeaderCollapsedService: PointModalHeaderCollapsedService,
    private pointModalHeaderFullService: PointModalHeaderFullService,
  ) {}

  expandModal({
    attachmentUploading,
    _id,
    workspaceId,
    isReminders,
    isTimeline,
    isOverview,
  }: {
    attachmentUploading: boolean;
    _id: string;
    workspaceId: string;
    isReminders: boolean;
    isTimeline: boolean;
    isOverview: boolean;
  }): void {
    const activePlan = GET_ACTIVE_PLAN();

    if (!attachmentUploading && !activePlan.active) {
      let url;

      if (isReminders) {
        url = `/reminders/(full:point/${_id})`;
      } else if (isTimeline) {
        url = `/site/timeline/(full:point/${_id})`;
      } else if (isOverview) {
        url = `/site/overview/(full:point/${_id})`;
      } else {
        url = `/site/${workspaceId}/(full:point/${_id})`;
      }

      logEventInGTAG(EGoogleEventSite.SITE__POINT__EXPAND, {
        event_category: EGoogleEventCategory.SITE,
      });

      this.router.navigateByUrl(url);
    }
  }

  nextPoint({
    attachmentUploading,
    point,
    _id,
    isReminders,
    isTimeline,
    isOverview,
    isFull,
  }: {
    attachmentUploading: boolean;
    point: TPoint;
    _id: string;
    isReminders: boolean;
    isTimeline: boolean;
    isOverview: boolean;
    isFull: boolean;
  }): void {
    if (isFull) {
      return this.pointModalHeaderFullService.nextPointFull({
        attachmentUploading,
        point,
        _id,
        isReminders,
        isTimeline,
        isOverview,
      });
    } else {
      return this.pointModalHeaderCollapsedService.nextPointCollapsed({
        attachmentUploading,
        point,
        _id,
        isReminders,
        isTimeline,
        isOverview,
      });
    }
  }

  prevPoint({
    attachmentUploading,
    point,
    _id,
    isReminders,
    isTimeline,
    isOverview,
    isFull,
  }: {
    attachmentUploading: boolean;
    point: TPoint;
    _id: string;
    isReminders: boolean;
    isTimeline: boolean;
    isOverview: boolean;
    isFull: boolean;
  }): void {
    if (isFull) {
      return this.pointModalHeaderFullService.prevPointFull({
        attachmentUploading,
        point,
        _id,
        isReminders,
        isTimeline,
        isOverview,
      });
    } else {
      return this.pointModalHeaderCollapsedService.prevPointCollapsed({
        attachmentUploading,
        point,
        _id,
        isReminders,
        isTimeline,
        isOverview,
      });
    }
  }

  collapseModal({
    attachmentUploading,
    isReminders,
    isTimeline,
    isOverview,
    point,
  }: {
    attachmentUploading: boolean;
    isReminders: boolean;
    isTimeline: boolean;
    isOverview: boolean;
    point: TPoint;
  }): void {
    const activePlan = GET_ACTIVE_PLAN();

    if (!attachmentUploading && !activePlan.active) {
      logEventInGTAG(EGoogleEventSite.SITE__POINT__COLLAPSE, {
        event_category: EGoogleEventCategory.SITE,
      });

      let url: string;

      if (isReminders) {
        url = `/reminders/point/${point._id}`;
      } else if (isTimeline) {
        url = `/site/timeline/point/${point._id}`;
      } else if (isOverview) {
        url = `/site/overview/point/${point._id}`;
      } else {
        url = `/site/${point.workspaceRef.id}/point/${point._id}`;
      }

      this.router.navigateByUrl(url);
    }
  }
}
