import { isString } from 'lodash';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetter',
})
export class FirstLetterPipe implements PipeTransform {
  transform(value: string): string {
    if (isString(value)) {
      return value.charAt(0);
    }

    return null;
  }
}
