<section class="advancedCFAccess__tags-wrap advancedCFAccess__section">
  <h6 class="shareModalHeader">
    {{ 'tags' | ppTranslate }}

    <i
      ppTooltip
      [ppOptions]="{ placement: 'right' }"
      class="fa fa-info-circle settingsSection__header-info"
      aria-hidden="true"
      [ppTitle]="'tags_limited_tooltip' | ppTranslate"
      [ppMobileTooltip]="true"
    ></i>
  </h6>

  <pp-toggle
    ppId="toggleLimitedTags"
    (ppAction)="updateLimitTags()"
    [ppChecked]="ppShare.tagLimited"
    [ppReverseLayout]="true"
    ppStyle="blue"
  >
    <span class="toggleInnerText">{{ 'tags_limited_description' | ppTranslate }}</span>
  </pp-toggle>

  <div class="limit-tags advancedCFAccess__tag-select" *ngIf="ppShare.tagLimited">
    <p class="advancedCFAccess__description">Choose tags:</p>

    <pp-tag-select
      [ppSelectedTags]="ppShare.defectTags"
      (ppOnChange)="updateTags($event)"
      (ppOnClose)="updateTags($event)"
      [ppWorkspaceId]="ppWorkspaceId"
      [ppOptions]="tagSelectOptions"
    ></pp-tag-select>
  </div>
</section>
