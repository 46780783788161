import { TPoint } from 'src/app/project/modules/points/points.model';
import { TFilters } from '../site-filter.model';

export function checkPointWithLocation(point: TPoint, filters: TFilters): boolean {
  const pointHasLocation = point.polygons?.length > 0 || point.pins?.length > 0;

  if (!filters.showPointsWithLocation && pointHasLocation) {
    return false;
  }

  if (!filters.showPointsWithoutLocation && !pointHasLocation) {
    return false;
  }

  return true;
}
