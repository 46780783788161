import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { IntegrationsApiProviderService } from 'src/app/project/data-providers/api-providers/integrations-api-provider/integrations-api-provider.service';
import { TAuditLog } from 'src/app/project/modules/integrations/utils/integrations.consts';

@Injectable({
  providedIn: 'root',
})
export class SiteSettingsIntegrationsAuditLogService {
  private auditLogs: TAuditLog[] = [];

  constructor(private integrationsApiProviderService: IntegrationsApiProviderService) {}

  getAuditLog(workspaceId: string): Observable<TAuditLog[]> {
    return this.integrationsApiProviderService.getAuditLog(workspaceId).pipe(
      tap((response) => {
        this.auditLogs = response;
      }),
    );
  }

  getLogs(): TAuditLog[] {
    return this.auditLogs;
  }

  clearLogs(): void {
    this.auditLogs = [];
  }
}
