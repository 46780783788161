import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AssetService } from '../../asset-service/asset.service';
import { FleetService } from '../../fleet-service/fleet.service';
import { TFleet } from '../../fleet.consts';

@Injectable({
  providedIn: 'root',
})
export class AssetReorderService {
  private newAssetIndex: number = null;
  private reorderedAssetId: string = null;

  private readonly _reorderedAssetIdChange$ = new Subject<string>();
  readonly reorderedAssetIdChange$ = this._reorderedAssetIdChange$.asObservable();

  private readonly _reorderedAssetIndexChange$ = new Subject<number>();
  readonly reorderedAssetIndexChange$ = this._reorderedAssetIndexChange$.asObservable();

  constructor(private fleetService: FleetService, private assetService: AssetService) {}

  setReorderedAssetId(assetId: string): void {
    this.reorderedAssetId = assetId;
    this._reorderedAssetIdChange$.next(assetId);
  }

  getReorderedAssetId(): string {
    return this.reorderedAssetId;
  }

  setNewAssetIndex(index: number): void {
    this.newAssetIndex = index;

    this._reorderedAssetIndexChange$.next(index);
  }

  getNewAssetIndex(): number {
    return this.newAssetIndex;
  }

  reorderAssets(): void {
    const fleetId = this.fleetService.getActiveFleetId();
    const fleet = this.fleetService.getFleet(fleetId);

    const newIndex = fleet.assetIds[this.newAssetIndex]
      ? this.newAssetIndex
      : fleet.assetIds.length - 1;

    const newAssetIds = this.getReorderedAssetIds(fleet, this.reorderedAssetId, newIndex);

    this.fleetService
      .updateFleet({
        ...fleet,
        assetIds: newAssetIds,
      })

      .subscribe(() => {
        this.assetService.updateAssetsOrder();
      });

    this.setNewAssetIndex(null);
    this.setReorderedAssetId(null);
  }

  private getReorderedAssetIds(fleet: TFleet, assetId: string, newIndex: number) {
    const newAssetIds = [...fleet.assetIds];
    const assetIndex = newAssetIds.indexOf(assetId);
    newAssetIds.splice(assetIndex, 1);
    newAssetIds.splice(newIndex, 0, assetId);
    return newAssetIds;
  }
}
