export enum ESimplePermission {
  EDIT = 'edit',
  VIEW = 'view',
  NONE = 'none',
}

export enum ESemiSimplePermission {
  ALLOW = 'semi_allow',
  VIEW = 'semi_view',
  NONE = 'semi_none',
}
