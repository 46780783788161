import { cloneDeep } from 'lodash';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { TAllFilters, TFilters } from '../site-filter.model';

import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { ActiveService } from 'src/app/project/services/active/active.service';

import { TAllUsers } from '@project/view-models';
import { TUsersDropdownType } from 'src/app/project/modules/users/users-dropdown/users-dropdown-data.enum';
import { checkCustomWorkspaceId } from 'src/app/project/modules/workspace/workspace';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { EStore } from '../../../shared/enums/store.enum';

@Component({
  selector: 'pp-filter-users',
  templateUrl: './filter-users.component.html',
  styleUrls: ['./filter-users.component.scss'],
})
export class FilterUsersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() ppFilters: TFilters;
  @Input() ppFilterType: TUsersDropdownType = 'assignees';

  @Output() ppOnValueChange = new EventEmitter();

  users: TAllUsers;
  dropdownVisible: boolean;
  filters: TFilters;
  EIconPath = EIconPath;
  workspaceUsers: string[] = [];

  private readonly destroy$ = new Subject<void>();
  private dropdown: TDropdown = this.dropdownService.getDropdown();
  private workspaces$ = new Observable<TWorkspacesById>();
  private workspaceId: string;
  selectedUsers: string[] = [];
  private siteFilters$ = new Observable<TAllFilters>();
  allFilters: TAllFilters;

  constructor(
    private store: Store<{
      siteFilter: TAllFilters;
      workspaces: TWorkspacesById;
    }>,

    private usersService: UsersService,
    private dropdownService: DropdownService,
    private activeService: ActiveService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.siteFilters$ = this.store.pipe(select(EStore.SITE_FILTER));

    this.siteFilters$.pipe(takeUntil(this.destroy$)).subscribe((allFilters) => {
      this.filtersHandler(allFilters);
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspacesHandler(workspaces);
    });
  }

  ngOnInit() {
    this.dropdownVisible = false;
    this.users = this.usersService.getUsers();

    this.getSelectableUsers();
  }

  ngOnChanges() {
    this.filters = this.ppFilters;

    this.getSelectableUsers();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  selectUser(user: string): void {
    if (user) {
      if (this.filters[this.ppFilterType].value.includes(user)) {
        this.removeUser(user);
      } else {
        this.filters[this.ppFilterType].value.push(user);
      }
    } else {
      this.filters[this.ppFilterType].value = [];
    }

    this.sortUsers();

    this.ppOnValueChange.emit();
    this.getSelectableUsers();
  }

  removeUser(id: string): void {
    const index = this.filters[this.ppFilterType].value.findIndex((userId) => userId === id);

    this.filters[this.ppFilterType].value.splice(index, 1);
    this.ppOnValueChange.emit();
  }

  sortUsers(): void {
    const newFilters = cloneDeep(this.filters);

    newFilters.createdBy.value = this.usersService.sortUsers(newFilters.createdBy.value);

    this.filters = newFilters;
  }

  private getSelectableUsers(): void {
    this.selectedUsers = this.workspaceUsers.filter((user) =>
      this.filters[this.ppFilterType].value.includes(user),
    );
  }

  private workspacesHandler(workspaces: TWorkspacesById): void {
    this.workspaceId = this.activeService.getActiveWorkspaceId();
    const workspace = workspaces[this.workspaceId];

    if (workspace) {
      this.workspaceUsers = workspace.users;
    } else {
      this.workspaceUsers = Object.keys(this.usersService.getUsers());
    }

    if (!this.workspaceId) {
      this.workspaceId = checkCustomWorkspaceId();
    }

    this.filters = this.allFilters[this.workspaceId];

    if (this.filters) {
      this.sortUsers();
      this.getSelectableUsers();
    }
  }

  private filtersHandler(allFilters: TAllFilters): void {
    this.allFilters = cloneDeep(allFilters);
    this.filters = this.allFilters?.[this.workspaceId];

    if (this.filters) {
      this.sortUsers();
      this.getSelectableUsers();
    }
  }
}
