import { Directive, ElementRef, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Props } from 'tippy.js';

import Tooltip from './Tooltip';

@Directive({
  selector: '[ppTooltip]',
})
export class TooltipDirective implements OnInit, OnChanges, OnDestroy {
  @Input() ppOptions: Partial<Props> = {};
  @Input() ppTitle = '';
  @Input() ppMobileTooltip = false;
  @Input() ppTrimTooltip = false;
  @Input() ppChildTooltip = false;
  @Input() ppSanitize = true;

  public _element: HTMLElement;
  public _host: HTMLElement;
  isTrimmed = true;

  tooltip: Tooltip;

  constructor(private elementRef: ElementRef) {
    this._element = this.elementRef.nativeElement;
    this._host = this.elementRef.nativeElement;
  }

  ngOnInit() {
    if (!this.tooltip) {
      this.tooltip = new Tooltip({
        options: this.ppOptions,
        title: this.ppTitle,
        mobileTooltip: this.ppMobileTooltip,
        trimTooltip: this.ppTrimTooltip,
        childTooltip: this.ppChildTooltip,
        element: this._element,
        sanitize: this.ppSanitize,
      });

      this.tooltip.createTooltip();
    }
  }

  ngOnChanges() {
    if (this.tooltip) {
      this.tooltip.removeTooltip();
    }

    this.tooltip = new Tooltip({
      options: this.ppOptions,
      title: this.ppTitle,
      mobileTooltip: this.ppMobileTooltip,
      trimTooltip: this.ppTrimTooltip,
      childTooltip: this.ppChildTooltip,
      element: this._element,
      sanitize: this.ppSanitize,
    });

    this.tooltip.createTooltip();
  }

  ngOnDestroy() {
    if (this.tooltip) {
      this.tooltip.removeTooltip();
    }
  }
}
