<div
  class="advancedFilterText"
  [class.advancedFilterText--filled]="ppValue?.length > 0"
  [class.advancedFilterText--active]="focused"
>
  <input
    class="advancedFilterTextInput"
    [type]="ppType"
    [(ngModel)]="ppValue"
    (change)="onChange()"
    (focus)="onFocus(true)"
    (blur)="onFocus(false)"
    [placeholder]="'enter_text' | ppTranslate"
  />

  <button
    class="advancedFilterText__close"
    (click)="clearInput()"
    [class.advancedFilterText__visible]="focused && ppValue?.length > 0"
  >
    <pp-icon ppClass="setSize14" ppColor="grey-600" [ppSrc]="EIconPath.ICON_CLOSE_14"></pp-icon>
  </button>
</div>
