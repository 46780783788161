import { createElement } from 'src/app/core/helpers/dom';
import { formatCurrency } from 'src/app/core/helpers/format-currency';
import { GET_CUSTOM_FIELDS } from 'src/app/project/modules/custom-fields/custom-fields.store';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { TColumn } from '../../columns/column.model';
import { createMarkedKeywordElement } from '../body-cells-search';
import { createCellElement } from '../createCellElement';

export function createNumbersElement(
  _index: number,
  _column: TColumn,
  _customField: TPointCustomField<string>,
): HTMLElement {
  let value = _customField.value;
  const customFields = GET_CUSTOM_FIELDS();
  const customField = customFields[_customField.customFieldTemplateId];
  const unit = customField.unit ? customField.unit : '';
  const decimalPlaces = customField.decimalPlaces ? customField.decimalPlaces : 0;

  if (
    (_column.customFieldIds &&
      !_column.customFieldIds.includes(_customField.customFieldTemplateId.toString())) ||
    !value
  ) {
    value = '';
  } else {
    value = parseFloat(value).toFixed(parseFloat(decimalPlaces.toString()));
  }

  const formattedValue = customField.showCommas
    ? formatCurrency(parseFloat(value), unit, parseInt(customField.decimalPlaces.toString()))
    : value
      ? unit + ' ' + value
      : '';

  return createCellElement({
    index: _column.index,
    width: `${_column.width}px`,
    child: createElement('div', {
      attrs: {
        class: 'customSiteTable__text customSiteTable__text--cost',
      },
      children: [createMarkedKeywordElement(formattedValue)],
    }),
  });
}
