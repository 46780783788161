import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { map, Observable } from 'rxjs';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import {
  TAsset,
  TNewAsset,
} from 'src/app/project/modules/fleet-management/asset-service/asset.consts';
import { DownloadService } from 'src/app/project/services/download.service';
import { AssetModelFactory } from './asset-model-factory';
import { API_asset, API_asset_export, API_asset_image, API_fleet_asset } from './asset-paths';
import { TAssetCreateDTO, TExportAssetPayload } from './asset-requests.model';

@Injectable({
  providedIn: 'root',
})
export class AssetApiProviderService {
  constructor(
    private apiService: ApiService,
    private downloadService: DownloadService,
    private translationPipe: TranslationPipe,
  ) {}

  fetchAssets(fleetId: string): Observable<TAsset[]> {
    const url = API_fleet_asset(fleetId);

    return this.apiService.get<TAsset[]>(url).pipe(
      map((assets) => {
        return assets.map((asset) => AssetModelFactory.createFromDTO(asset));
      }),
    );
  }

  createAsset(fleetId: string, body: TAssetCreateDTO): Observable<TAsset> {
    const url = API_fleet_asset(fleetId);

    return this.apiService
      .post<TAsset>(url, body)
      .pipe(map((asset) => AssetModelFactory.createFromDTO(asset)));
  }

  fetchAsset(assetId: string): Observable<TAsset> {
    const url = API_asset(assetId);

    return this.apiService.get<TAsset>(url).pipe(
      map((asset) => {
        return AssetModelFactory.createFromDTO(asset);
      }),
    );
  }

  editAsset(assetId: string, body: Partial<TNewAsset>): Observable<TAsset> {
    const url = API_asset(assetId);

    return this.apiService.put<TAsset>(url, body).pipe(
      map((asset) => {
        return AssetModelFactory.createFromDTO(asset);
      }),
    );
  }

  deleteAsset(assetId: string): Observable<null> {
    const url = API_asset(assetId);

    return this.apiService.delete<null>(url);
  }

  uploadAssetImage(image: File): Observable<null> {
    const url = API_asset_image();

    const formData = new FormData();
    formData.append('image_file', image);

    return this.apiService.postWithFormData<null>(url, formData);
  }

  exportAsset(body: TExportAssetPayload): Observable<Response> {
    const promptErrorText = this.translationPipe.transform('prompt_export_error_dashboard');

    return this.downloadService.fromRequest(API_asset_export(), body, promptErrorText);
  }
}
