import { timelineProperties, TIMELINE_TIMEOUT_DURATION } from './timeline-variables';
import { adjustTimelineElementRight } from './adjust-timeline-element-right';
import { handleTimelineMousemove } from './handle-timeline-mouse-move';
import Timeline from '../../Timeline';

export function onResizeHandleRightMouseMove({
  _event,
  resizeHandlerElementRect,
  offsetX,
  startOffset,
  timeline,
  _width,
  mouseMoveChangeAmount,
  initialScroll,
  resizeHandlerElement,
}: {
  _event: MouseEvent;
  resizeHandlerElementRect: Partial<DOMRect>;
  offsetX: number;
  startOffset: number;
  timeline: Timeline;
  _width: number;
  mouseMoveChangeAmount: number;
  initialScroll: number;
  resizeHandlerElement: HTMLElement;
}): { mouseMoveChangeAmount: number; initialScroll: number } {
  _event.preventDefault();

  timeline.setResizing(true);

  const changeAmount =
    _event.x -
    resizeHandlerElementRect.right -
    offsetX -
    (startOffset - timeline.timelineBody.virtualScroller.offsetX);
  const newWidth = _width + changeAmount;

  mouseMoveChangeAmount = changeAmount;
  initialScroll = timeline.timelineBody.virtualScroller.scrollElement.scrollLeft;

  adjustTimelineElementRight({
    newWidth,
    element: resizeHandlerElement.parentElement,
    changeAmount,
    width: _width,
  });

  clearTimeout(timelineProperties.stationaryTimeout);

  timelineProperties.stationaryTimeout = setTimeout(() => {
    timelineProperties.stationary = true;

    handleTimelineMousemove(_event);
  }, TIMELINE_TIMEOUT_DURATION);

  timelineProperties.stationary = false;
  return { mouseMoveChangeAmount, initialScroll };
}
