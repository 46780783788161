import { Injectable } from '@angular/core';
import { PlanDataService, TPlanData } from './plan-data.service';
import { EPlanModule } from './plan-module.enum';
import { FeatureDragService } from './plan-point/feature-drag.service';
import { FeatureModifyService } from './plan-point/feature-modify.service';
import { PlanPointClickService } from './plan-point/plan-point-click.service';
import { PlanPointDoubleClickService } from './plan-point/plan-point-double-click.service';
import { PlanPointDragService } from './plan-point/plan-point-drag.service';
import { PlanPointPointerMoveEndService } from './plan-point/plan-point-pointer-move-end.service';
import { PlanPointPointerMoveService } from './plan-point/plan-point-pointer-move.service';
import { PolygonDrawService } from './plan-point/polygon-draw.service';
import { PlanSiteService } from './plan-site.service';

@Injectable({
  providedIn: 'root',
})
export class PlanUnsubscribeService {
  private plan: TPlanData = this.planDataService.getPlan();

  constructor(
    private planDataService: PlanDataService,
    private planPointDoubleClickService: PlanPointDoubleClickService,
    private polygonDrawService: PolygonDrawService,
    private featureModifyService: FeatureModifyService,
    private planPointPointerMoveEndService: PlanPointPointerMoveEndService,
    private featureDragService: FeatureDragService,
    private planPointPointerMoveService: PlanPointPointerMoveService,
    private planPointDragService: PlanPointDragService,
    private planPointClickService: PlanPointClickService,
    private planSiteService: PlanSiteService,
  ) {}

  unsubscribeEvents(module: EPlanModule, canEdit: boolean): void {
    if (module === EPlanModule.POINT) {
      this.unsubscribePlanPointEvents();

      if (this.plan.instance.point) {
        if (canEdit) {
          this.featureDragService.featureDragOff();
          this.planPointPointerMoveService.pointermoveEventOff();
          this.planPointDragService.pointerDragEventOff();
          this.planPointClickService.clickEventOff();
        }

        this.planDataService.clearPointPlan();
      }
    } else {
      this.unsubscribePlanSiteEvents();
    }
  }

  unsubscribePlanSiteEvents(): void {
    if (this.plan.instance.site) {
      this.planSiteService.pointermoveEventOff();
      this.planSiteService.clickEventOff();
      this.planSiteService.dblclickEventOff();
      this.planSiteService.moveendEventOff();

      this.planDataService.clearPlan();
    }
  }

  unsubscribePlanPointEvents(clear: boolean = false): void {
    if (this.plan.instance.point) {
      this.planPointDoubleClickService.dblclickEventOff();
      this.planPointPointerMoveEndService.moveendEventOff();
      this.polygonDrawService.polygonDrawListnerOff();
      this.featureModifyService.polygonModifyListenerOff();

      if (clear) {
        this.planDataService.clearPointPlan();
      }
    }
  }
}
