import { Component, Input, OnChanges } from '@angular/core';
import { uuid } from '@core/helpers';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { SelectDropdownComponent } from 'src/app/project/components/input/select/select-dropdown/select-dropdown.component';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EDIT_SIMPLE_SHARE_DROPDOWN_WIDTH } from '../../../account/account-settings/account-user-modal/edit-account-user-modal.consts';
import { TCombinedCustomField } from '../../../custom-fields/combine-custom-fields/combined-custom-field.model';
import { ESimplePermission } from '../../../share/permissions.consts';
import {
  convertGranularPermissionToSimplePermission,
  convertSimplePermissionToGranularPermission,
} from '../../../share/share-utils/permissions-converter.utils';
import { EUserRole } from '../../../share/share-utils/user-roles';
import { TGranularPermission, TShare, TUpdateShareDTO } from '../../../share/share.model';
import { WorkspaceService } from '../../../workspace/workspace.service';
import { EColumn } from '../../user-management.consts';
import { UserManagementTableCellsService } from '../user-management-table-cells.service';
import { checkIfCellEditable } from '../utils/check-if-cell-editable';
import { getDropdownDataForColumn } from '../utils/dropdown-data.utils';
import { getDisabledUserManagementTooltipText } from '../utils/get-disabled-user-management-cell-tooltip-text';
import { getViewDataByPermission } from '../utils/permission-view-data.util';
import { TPermissionItemByColumn } from '../utils/permissions-per-column.utils';
import { createPartialShareDTOForCF } from '../utils/share-for-column.utils';

@Component({
  selector: 'pp-user-management-custom-field-cell',
  templateUrl: './user-management-custom-field-cell.component.html',
  styleUrls: ['./user-management-custom-field-cell.component.scss'],
})
export class UserManagementCustomFieldCellComponent implements OnChanges {
  @Input() ppShare: TShare;
  @Input() ppCustomField: TCombinedCustomField;

  private column = EColumn.CUSTOM_FIELD;
  private permission: ESimplePermission;
  customFieldId: string;
  buttonId: string = uuid();
  iconPath: EIconPath;
  text: string;
  editable: boolean;
  tooltipText: string;

  constructor(
    private workspaceService: WorkspaceService,
    private dropdownService: DropdownService,
    private userManagementTableCellsService: UserManagementTableCellsService,
    private translationPipe: TranslationPipe,
  ) {}

  ngOnChanges(): void {
    this.setCustomFieldId();
    this.setPermissionFromShare();
    this.setPermissionViewData();
    this.setEditable();
    this.setTooltipText();
  }

  private setCustomFieldId(): void {
    this.customFieldId = this.ppCustomField.combinedIds.find(
      (combinedCustomField) => combinedCustomField.workspaceId === this.ppShare.workspaceId,
    )?.customFieldId;
  }

  private setPermissionFromShare(): void {
    const granularPermission = this.getGranularPermission();

    if (!granularPermission) {
      if (this.checkIfFieldExists()) {
        this.permission = this.getDefaultSimplePermissionByUserRole();
      } else {
        this.permission = null;
      }
    } else {
      this.permission = convertGranularPermissionToSimplePermission(granularPermission);
    }
  }

  private getGranularPermission(): TGranularPermission {
    const shareCustomField = this.ppShare.advancedAccessLevels.customFields.find(
      (field) => field.templateId == this.customFieldId,
    );

    return shareCustomField?.permission;
  }

  private checkIfFieldExists(): boolean {
    const workspaceId = this.ppShare.workspaceId;
    const workspace = this.workspaceService.findWorkspace(workspaceId);
    const workspaceCustomFields = workspace.customFields;

    if (!workspaceCustomFields.includes(this.customFieldId)) {
      return false;
    }

    return true;
  }

  private getDefaultSimplePermissionByUserRole(): ESimplePermission {
    switch (this.ppShare.shareOption) {
      case EUserRole.SITE_ADMIN:
      case EUserRole.OWNER:
      case EUserRole.NORMAL:
      case EUserRole.ACCOUNT_ADMIN:
        return ESimplePermission.EDIT;
      case EUserRole.LIMITED:
        return ESimplePermission.VIEW;
    }
  }

  private setPermissionViewData(): void {
    const permission = getViewDataByPermission(this.permission);

    this.iconPath = permission.iconPath;
    this.text = permission.text;
  }

  toggleDropdown(): void {
    if (!this.editable || !this.customFieldId) {
      return;
    }

    if (this.dropdownService.getDropdown().visible) {
      this.dropdownService.hideDropdown();
    } else {
      this.setDropdownData();

      this.dropdownService.showDropdown(this.buttonId, SelectDropdownComponent, {
        callback: (permission: TPermissionItemByColumn) => this.trySelectPermission(permission),
        width: EDIT_SIMPLE_SHARE_DROPDOWN_WIDTH,
      });
    }
  }

  private setDropdownData(): void {
    this.dropdownService.setData(
      getDropdownDataForColumn(
        this.column,
        this.ppShare.shareOption,
        this.permission,
        this.customFieldId,
      ),
    );
  }

  private trySelectPermission(permissionItem: TPermissionItemByColumn): void {
    if (permissionItem.value === this.permission) {
      return;
    }

    this.setPermission(permissionItem.value as ESimplePermission);
    this.setPermissionViewData();
    this.updateShare(permissionItem.value as ESimplePermission);
  }

  private setPermission(permission: ESimplePermission): void {
    this.permission = permission;
  }

  private updateShare(permission: ESimplePermission): void {
    this.userManagementTableCellsService
      .updateShare(
        this.ppShare.userId,
        this.ppShare.shareId,
        this.createPartialShareDTO(permission),
      )
      .subscribe();
  }

  private createPartialShareDTO(permission: ESimplePermission): TUpdateShareDTO {
    return createPartialShareDTOForCF(
      convertSimplePermissionToGranularPermission(permission),
      this.customFieldId,
    );
  }

  private setEditable(): void {
    this.editable = checkIfCellEditable(this.ppShare.shareOption);
  }

  private setTooltipText(): void {
    if (!this.editable) {
      this.tooltipText = getDisabledUserManagementTooltipText(this.ppShare.shareOption);
    } else if (!this.customFieldId) {
      this.tooltipText = this.translationPipe.transform(
        'user_management_custom_field_does_not_exist',
      );
    } else {
      this.tooltipText = null;
    }
  }
}
