import { Injectable } from '@angular/core';

import { TranslationPipe } from '../../features/translate/translation.pipe';
import { EPriority } from './priorities';
import {EStatus} from '../../shared/enums/status.enum';

@Injectable({
  providedIn: 'root',
})
export class PointsHelperService {
  constructor(private translationPipe: TranslationPipe) {}

  humanizePriorityName(priority: EPriority): string {
    switch (priority) {
      case EPriority.HIGH:
        return this.translationPipe.transform('high');
      case EPriority.MEDIUM:
        return this.translationPipe.transform('medium');
      case EPriority.LOW:
        return this.translationPipe.transform('low');
      default:
        return '';
    }
  }

  humanizeStatusName(status: EStatus): string {
    switch (status) {
      case EStatus.OPEN:
        return this.translationPipe.transform('open');
      case EStatus.IN_PROGRESS:
        return this.translationPipe.transform('in_progress');
      case EStatus.ONHOLD:
        return this.translationPipe.transform('on_hold');
      case EStatus.TO_REVIEW:
        return this.translationPipe.transform('to_review');
      case EStatus.CANCELED:
        return this.translationPipe.transform('canceled');
      case EStatus.CLOSED:
        return this.translationPipe.transform('completed');
      default:
        return '';
    }
  }

  priorityToColor(priority: EPriority): string {
    switch (priority) {
      case EPriority.HIGH:
        return 'red';
      case EPriority.MEDIUM:
        return 'yellow';
      case EPriority.LOW:
        return 'blue';
      default:
        return 'gray';
    }
  }

  statusPriorityToColor(statusPriority: string): string {
    switch (statusPriority) {
      case EStatus.OPEN:
        return 'gray';
      case EStatus.IN_PROGRESS:
        return 'purple';
      case EStatus.ONHOLD:
        return 'orange';
      case EStatus.TO_REVIEW:
        return 'olive';
      case EStatus.CANCELED:
        return 'maroon';
      case EStatus.CLOSED:
        return 'green';
      case EPriority.HIGH:
        return 'red';
      case EPriority.MEDIUM:
        return 'yellow';
      case EPriority.LOW:
        return 'blue';
      default:
        return 'OTHER';
    }
  }

  humanizeActivityChange(activityChange: string): string {
    switch (activityChange) {
      case EStatus.OPEN:
        return 'Open';
      case EStatus.IN_PROGRESS:
        return 'In Progress';
      case EStatus.ONHOLD:
        return 'On Hold';
      case EStatus.TO_REVIEW:
        return 'To Review';
      case EStatus.CANCELED:
        return 'Canceled';
      case EStatus.CLOSED:
        return 'Completed';
      case EPriority.HIGH:
        return 'High';
      case EPriority.MEDIUM:
        return 'Medium';
      case EPriority.LOW:
        return 'Low';
      default:
        return activityChange;
    }
  }
}
