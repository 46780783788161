import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, catchError, tap } from 'rxjs';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { ResponseErrorService } from '../../../errors/response-error.service';
import { UpdateUserTwoFactorAuthentication } from '../../../user/user.actions';
import { LoginRedirectService } from '../../login-redirect.service';
import { ETwoFactorAuthenticationChannel } from '../two-factor-authentication.consts';
import { TwoFactorAuthenticationService } from '../two-factor-authentication.service';
import { ETwoFactorAuthenticationSetupStep } from './two-factor-authentication-setup.consts';

@Component({
  selector: 'pp-two-factor-authentication-setup',
  templateUrl: './two-factor-authentication-setup.component.html',
  styleUrls: ['./two-factor-authentication-setup.component.scss'],
})
export class TwoFactorAuthenticationSetupComponent implements OnDestroy {
  EIconPath = EIconPath;
  phoneNumber: string = '';
  processing: boolean = false;
  ETwoFactorAuthenticationSetupStep = ETwoFactorAuthenticationSetupStep;
  currentStep: ETwoFactorAuthenticationSetupStep =
    ETwoFactorAuthenticationSetupStep.ENTER_PHONE_NUMBER;
  errorMessage: string;
  channel: ETwoFactorAuthenticationChannel = null;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private twoFactorAuthenticationService: TwoFactorAuthenticationService,
    private loginRedirectService: LoginRedirectService,
    private responseErrorService: ResponseErrorService,
    private translationPipe: TranslationPipe,
    private store: Store,
    private promptService: PromptService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  confirm2FA(code: string): void {
    this.twoFactorAuthenticationService
      .confirm2FA(code, this.phoneNumber, this.channel)
      .pipe(
        tap(() => {
          this.goToSuccessStep();
        }),
        catchError((error) => {
          this.checkInvalidPhoneNumberError(error);

          return this.responseErrorService.handleRequestError(error);
        }),
      )
      .subscribe(() => {});
  }

  goToCodeStep(): void {
    this.currentStep = ETwoFactorAuthenticationSetupStep.ENTER_CODE;
  }

  goToNumberStep(): void {
    this.currentStep = ETwoFactorAuthenticationSetupStep.ENTER_PHONE_NUMBER;
  }

  goToSuccessStep(): void {
    this.currentStep = ETwoFactorAuthenticationSetupStep.SUCCESS;
  }

  continueFromSuccess(): void {
    this.loginRedirectService.redirectAfterLogin();
    this.updateUserInStore();
  }

  resendCode(): void {
    this.twoFactorAuthenticationService
      .setup2FA(this.phoneNumber, this.channel)
      .pipe(
        tap(() => {
          const prompt = this.translationPipe.transform('2fa_resend_prompt');

          this.promptService.showSuccess(prompt);
        }),
      )
      .subscribe();
  }

  private checkInvalidPhoneNumberError(error: any): void {
    if (error.status === EStatusCode.BAD_REQUEST) {
      this.errorMessage = this.translationPipe.transform('2fa_invalid_phone_number');
    } else if (error.status === EStatusCode.INTERNAL_SERVER_ERROR) {
      this.errorMessage = this.translationPipe.transform('2fa_code_error');
    }
  }

  private updateUserInStore(): void {
    this.store.dispatch(new UpdateUserTwoFactorAuthentication({ enabled2fa: true }));
  }
}
