import { cloneDeep } from 'lodash';

import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { TAllFilters, TFilters } from '../site-filter.model';

import { ActiveService } from '../../../services/active/active.service';
import { PermissionsService } from '../../share/permissions.service';

import { SortingService } from '@core/helpers';
import { EStore } from '../../../shared/enums/store.enum';
import { checkCustomWorkspaceId } from '../../workspace/workspace';
import { WorkspaceService } from '../../workspace/workspace.service';
import { SiteFilterDataService } from '../site-filter-data-service/site-filter-data.service';
import { updateSelectableExcludedTags } from './update-selectable-excluded-tags';
import { updateSelectableTags } from './update-selectable-tags';

@Injectable({
  providedIn: 'root',
})
export class SelectableFiltersService {
  private readonly destroy$ = new Subject<void>();

  private filters: TFilters;
  private siteFilters$ = new Observable<TAllFilters>();
  private workspaces$ = new Observable<TWorkspacesById>();
  private workspaceId: string;
  private allFilters: TAllFilters;

  constructor(
    private store: Store<{
      siteFilter: TAllFilters;
      workspaces: TWorkspacesById;
    }>,
    private siteFilterDataService: SiteFilterDataService,
    private workspaceService: WorkspaceService,
    private sortingService: SortingService,
    private permissionsService: PermissionsService,
    private activeService: ActiveService,
  ) {
    this.siteFilters$ = this.store.pipe(select(EStore.SITE_FILTER));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.siteFilters$.pipe(takeUntil(this.destroy$)).subscribe((allFilters) => {
      this.allFilters = allFilters;
      this.filters = cloneDeep(this.allFilters?.[this.workspaceId]);
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.workspaceId = this.activeService.getActiveWorkspaceId();

      if (!this.workspaceId) {
        this.workspaceId = checkCustomWorkspaceId();
      }

      this.filters = cloneDeep(this.allFilters?.[this.workspaceId]);
    });
  }

  updateSelectableTags(): void {
    const workspace = this.workspaceService.getActiveWorkspace();
    const workspaces = this.workspaceService.getWorkspaces();
    const [selectableTags, allTags] = updateSelectableTags(
      workspace,
      this.filters,
      workspaces,
      this.permissionsService,
    );
    this.filters.tags.value.sort((a, b) => this.sortingService.naturalSort(a, b));
    this.filters.excludedTags.value.sort((a, b) => this.sortingService.naturalSort(a, b));
    this.siteFilterDataService.setSelectableTags(selectableTags);
    this.siteFilterDataService.setAllTags(allTags);
  }

  updateSelectableExcludedTags(): void {
    const workspace = this.workspaceService.getActiveWorkspace();
    const workspaces = this.workspaceService.getWorkspaces();
    const [selectableExcludedTags, allTags] = updateSelectableExcludedTags(
      workspace,
      this.filters,
      workspaces,
      this.permissionsService,
    );
    this.filters.excludedTags.value.sort((a, b) => this.sortingService.naturalSort(a, b));
    this.siteFilterDataService.setSelectableExcludedTags(selectableExcludedTags);
    this.siteFilterDataService.setAllTags(allTags);
  }
}
