import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NewAssetSitePickerExpandingService {
  private expandedAccountIds: string[] = [];
  private expandedWorkspaceIds: string[] = [];

  constructor() {}

  isAccountExpanded(accountId: string): boolean {
    return this.expandedAccountIds.includes(accountId);
  }

  isWorkspaceExpanded(workspaceId: string): boolean {
    return this.expandedWorkspaceIds.includes(workspaceId);
  }

  setAccountExpanded(accountId: string, expanded: boolean): void {
    if (expanded) {
      this.expandedAccountIds.push(accountId);
    } else {
      this.expandedAccountIds = this.expandedAccountIds.filter((id) => id !== accountId);
    }
  }

  setWorkspaceExpanded(workspaceId: string, expanded: boolean): void {
    if (expanded) {
      this.expandedWorkspaceIds.push(workspaceId);
    } else {
      this.expandedWorkspaceIds = this.expandedWorkspaceIds.filter((id) => id !== workspaceId);
    }
  }

  clearExpandedAccounts(): void {
    this.expandedAccountIds = [];
  }

  clearExpandedWorkspaces(): void {
    this.expandedWorkspaceIds = [];
  }

  clearAllExpanded(): void {
    this.clearExpandedAccounts();
    this.clearExpandedWorkspaces();
  }
}
