import { isPointFlagged } from 'src/app/project/modules/points/utils/is-flagged';
import { ERowType } from 'src/app/project/shared/enums/row-type.enum';
import { TColumn } from '../../columns/column.model';
import { GET_GROUPING } from '../../columns/grouping.store';
import Table from '../../custom-table/table/Table';
import { GET_TABLE } from '../../table.ui.store';

export function generatePointRowClasses(_index: number): string[] {
  const table = GET_TABLE();
  const item = table.items[_index];
  const pointIndex = item.index;
  const selected = table.selectedPoints.has(table.points[pointIndex]._id);
  const active = table.activePointId === table.points[pointIndex]._id;
  const grouping = GET_GROUPING();
  const flagged = isPointFlagged(table.points[pointIndex]);

  const classList = ['table__row'];

  if (grouping.length) {
    classList.push('table__row--grouped');
  }

  if (flagged) {
    classList.push('table__row--flagged');
  } else if (selected) {
    classList.push('table__row--selected');
  }

  if (active) {
    classList.push('table__row--active');
  }

  if (isLastGroupRow(grouping, table, _index)) {
    classList.push('table__row--lastGroupRow');
  }

  return classList;
}

function isLastGroupRow(grouping: TColumn[], table: Table, _index: number): boolean {
  if (grouping.length) {
    const nextItem = table.items[_index + 1];

    if (
      nextItem &&
      (nextItem.type === ERowType.EMPTY ||
        nextItem.type === ERowType.EMPTY_ROW_GROUP_1 ||
        nextItem.type === ERowType.EMPTY_ROW_GROUP_2 ||
        nextItem.type === ERowType.EMPTY_ROW_GROUP_3)
    ) {
      return true;
    }
  }

  return false;
}
