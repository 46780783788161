import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { blurInput } from 'src/app/core/helpers/blur-input';
import { TAutoNumericOptions } from 'src/app/core/helpers/create-autonumeric';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-account-settings-number-picker',
  templateUrl: './account-settings-number-picker.component.html',
  styleUrls: ['./account-settings-number-picker.component.scss'],
})
export class AccountSettingsNumberPickerComponent implements OnInit {
  @Input() ppValue: number;
  @Input() ppMaxValue: number = null;
  @Input() ppMinValue: number = null;

  @Output() ppValueChange = new EventEmitter<number>();

  autonumericOptions: TAutoNumericOptions;
  EIconPath = EIconPath;

  ngOnInit(): void {
    const autonumericOptions: TAutoNumericOptions = {
      watchExternalChanges: true,
      modifyValueOnWheel: true,
      minimumValue: '0',
      allowDecimalPadding: false,
      decimalPlaces: 0,
      decimalPlacesRawValue: 0,
    };

    if (this.ppMaxValue) {
      autonumericOptions.maximumValue = this.ppMaxValue.toString();
    }

    this.autonumericOptions = autonumericOptions;
  }

  increaseValue(): void {
    this.ppValue += 1;

    this.updateValue();
  }

  decreaseValue(): void {
    if ((this.ppMinValue || this.ppMinValue === 0) && this.ppValue > this.ppMinValue) {
      this.ppValue -= 1;

      this.updateValue();
    }
  }

  updateValue(): void {
    if (this.ppValue < this.ppMinValue) {
      this.ppValue = this.ppMinValue;
    }

    this.ppValueChange.emit(+this.ppValue);
  }

  blurInput(event: Event): void {
    blurInput(event.target);
  }
}
