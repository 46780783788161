import { createElement } from '@core/helpers';

export function createElementWithBackgroundColor({
  firstClass,
  secondClass,
}: {
  firstClass: string;
  secondClass: string;
}): HTMLElement {
  return createElement('div', {
    attrs: {
      class: firstClass,
    },
    children: [
      createElement('div', {
        attrs: {
          class: secondClass,
        },
      }),
    ],
  });
}
