<pp-icon
  [ppSrc]="EIconPath.ICON_USER_GROUPS_VISIBLE_18"
  alt="outline of an eye"
  *ngIf="passInputType === 'password'"
  (click)="togglePasswordVisibility()"
  ppColor="grey-600"
  ppClass="setSize18"
></pp-icon>

<pp-icon
  [ppSrc]="EIconPath.ICON_MISC_VISIBILITY_OFF_18"
  alt="outline of an eye with a slash through it"
  *ngIf="passInputType === 'text'"
  (click)="togglePasswordVisibility()"
  ppColor="grey-600"
  ppClass="setSize18"
></pp-icon>
