import { TPoint } from '../../../points/points.model';
import { EAdvancedFilterOptions } from '../../models/advanced-filter-options.enum';
import { TAdvancedFilterFlag } from '../../models/advanced-filter.model';
import { filterIdToPointProperty } from '../../utils/filter-id-to-property';

export function filterFlagAdvanced(point: TPoint, filter: TAdvancedFilterFlag): boolean {
  const fieldValue: boolean = filterIdToPointProperty(filter.id, point);

  switch (filter.option) {
    case EAdvancedFilterOptions.IS_FLAGGED:
      return fieldValue;
    case EAdvancedFilterOptions.IS_NOT_FLAGGED:
      return !fieldValue;
  }
}
