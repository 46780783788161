import { Injectable } from '@angular/core';

import { Observable, of, Subject } from 'rxjs';

import { ReactionsApiProviderService } from '@core/api';
import { TGuid } from '@core/helpers';
import { TAllUsers } from '@project/view-models';
import { catchError, finalize, tap } from 'rxjs/operators';
import { translate } from '../features/translate/translate';
import { ResponseErrorService } from '../modules/errors/response-error.service';
import { TPointReactions, TReaction, TReactions } from '../modules/reactions/reactions.model';
import { UsersService } from '../modules/users/users.service';
import { EIconPath } from '../shared/enums/icons.enum';

@Injectable({
  providedIn: 'root',
})
export class ReactionsService {
  private readonly reactions: TReactions;

  private readonly _reactionsChange$ = new Subject<void>();
  readonly reactionsChange$ = this._reactionsChange$.asObservable();

  constructor(
    private responseErrorService: ResponseErrorService,
    private usersService: UsersService,
    private reactionsApiProviderService: ReactionsApiProviderService,
  ) {
    this.reactions = {};
  }

  likeComment(commentId: string, _id: string, like: boolean = true): Observable<TReaction> {
    const likeRequest$ = like
      ? this.reactionsApiProviderService.like(commentId)
      : this.reactionsApiProviderService.removeLike(commentId);

    return likeRequest$.pipe(
      tap((response) => {
        this.fillReaction(_id, response);

        if (this.reactions.notifications) {
          this.fillNotificationReaction(response);
        }

        this._reactionsChange$.next();
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  fetchReactions(_id: string): Observable<TReaction[]> {
    if (!this.reactions[_id]) {
      this.reactions[_id] = {
        data: [],
        workspaceId: null,
        _id: null,
        fetch$: null,
      };
    }

    const pointReactions: TPointReactions = this.reactions[_id];

    pointReactions.fetch$ = this.reactionsApiProviderService.getPointReactions(_id).pipe(
      tap((response) => {
        pointReactions.data = [...response].reverse();
        pointReactions._id = _id;

        this._reactionsChange$.next();
      }),
      catchError(this.responseErrorService.handleRequestError),
      finalize(() => {}),
    );

    return pointReactions.fetch$;
  }

  getReactions(): TReactions {
    return this.reactions;
  }

  fetchCommentsReaction(commentIds: TGuid[]): Observable<TReaction[]> {
    if (commentIds.length === 0) {
      this.reactions.notifications = {
        data: [],
      };

      return of([]);
    }

    return this.reactionsApiProviderService.getCommentsReactions(commentIds).pipe(
      tap((response) => {
        if (response) {
          this.reactions.notifications = {
            data: response,
          };
        }

        this._reactionsChange$.next();
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  getReactionTooltip(reaction: TReaction): string {
    const users: TAllUsers = this.usersService.getUsers();
    const tooltipUsers = [];

    let reactionTooltip = '<div class="tooltipTable">';

    if (reaction) {
      reaction.like.forEach((likedUser) => {
        if (users[likedUser]) {
          const avatarURL = users[likedUser]?.avatarPublicUrl;
          const userName = users[likedUser]?.userName;

          tooltipUsers.push(likedUser);

          reactionTooltip +=
            `
            <div class="tooltipTableRow">
              <div class="tooltipAvatar__wrapper">
                <img class="tooltipAvatar" src="${avatarURL}" alt="${EIconPath.PLACEHOLDER_USER}">
              </div>

              <span class="tooltipText--short tooltipTableCell">
                <span class="tooltipInnerCell">` +
            userName +
            `</span>
              </span>
            </div>
          `;
        } else {
          reactionTooltip += `
            <div class="tooltipTableRow">
              <div class="tooltipAvatar__wrapper">
                <img class="tooltipAvatar" src="${EIconPath.PLACEHOLDER_USER}">
              </div>

              <span class="tooltipText--short tooltipTableCell">
                <span class="tooltipInnerCell">${translate('deleted_user')}</span>
              </span>
            </div>
          `;
        }
      });
    }

    reactionTooltip += '</div>';

    if (tooltipUsers.length === 0) {
      reactionTooltip = '';
    }

    return reactionTooltip;
  }

  private fillReaction(_id: TGuid, reactionData: TReaction): void {
    const existingReaction = this.reactions[_id]?.data.find(
      (reaction) => reaction.targetRef.id === reactionData.targetRef.id,
    );

    if (existingReaction) {
      existingReaction.like = reactionData.like;
    } else {
      if (this.reactions[_id]) {
        this.reactions[_id].data.push(reactionData);
      } else {
        this.reactions[_id] = {
          data: [reactionData],
          workspaceId: null,
          _id: null,
          fetch$: null,
        };
      }
    }
  }

  private fillNotificationReaction(reactionData: TReaction): void {
    const notificationReaction = this.reactions.notifications.data.find(
      (reaction) => reaction.targetRef.id === reactionData.targetRef.id,
    );

    if (notificationReaction) {
      notificationReaction.like = reactionData.like;
    } else {
      this.reactions.notifications.data.push(reactionData);
    }
  }
}
