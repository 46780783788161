<div class="form-group">
  <div
    class="modalInput__input-wrapper advancedCFAccess__email-input"
    (click)="focusInput()"
    ppTooltip
    [ppTitle]="ppDisabled ? '' : ('share_edit_email_input_tooltip' | ppTranslate)"
  >
    <div class="inputWrapper" [class.inputWrapper--disabled]="ppDisabled">
      <input
        *ngIf="!ppDisabled"
        #shareModalEmailInput
        id="shareModalEmailInput"
        class="modalInput__input input share__email-input"
        [class.share__email-input--hidden]="!focused && emailList.length > 0"
        name="email"
        type="text"
        [placeholder]="'email_address' | ppTranslate"
        (keyup)="onEmailChange()"
        (change)="onEmailChange()"
        (ppOnRendered)="onInputRendered($event)"
        [(ngModel)]="emailInput"
        (focus)="onFocus()"
        (blur)="onBlur()"
        (keydown.enter)="blurInput($event)"
        autocomplete="off"
      />

      <div class="userChips" *ngIf="!focused">
        <pp-user-chip
          *ngFor="let email of emailList; let index = index"
          [ppDisabled]="ppDisabled"
          [ppEmail]="email"
          [ppHasError]="checkEmailError(email, index)"
          (ppRemoveUserCallback)="removeEmailFromList(index)"
        ></pp-user-chip>
      </div>
    </div>
  </div>
</div>
