import { Pipe, PipeTransform } from '@angular/core';

import { translate, TTranslateOptions } from './translate';
import { TTranslationKey } from './types';

@Pipe({
  name: 'ppTranslate',
})
export class TranslationPipe implements PipeTransform {
  transform(value: TTranslationKey, options?: TTranslateOptions): string {
    return translate(value, options);
  }
}
