import { Action } from '@ngrx/store';

import { TAllUsers } from '@project/view-models';

export enum EActionType {
  SET_USERS = '[Users] Set',
  CLEAR_USERS = '[Users] Clear',
  UPDATE_USER_NAME = '[Users] Update Username',
}

export class SetUsers implements Action {
  readonly type = EActionType.SET_USERS;
  constructor(readonly payload: TAllUsers) {}
}

export class ClearUsers implements Action {
  readonly type = EActionType.CLEAR_USERS;
  constructor() {}
}

export class UpdateUserName implements Action {
  readonly type = EActionType.UPDATE_USER_NAME;
  constructor(
    readonly payload: {
      userId: string;
      userName: string;
    },
  ) {}
}

export type ActionsUnion = SetUsers | ClearUsers | UpdateUserName;
