import { Pipe, PipeTransform } from '@angular/core';
import { clone } from 'lodash';

@Pipe({
  name: 'highlightFragment',
})
export class HighlightFragmentPipe implements PipeTransform {
  transform(customFieldItem: string, filter: string): string {
    if (filter) {
      const result = [];
      let processedText = clone(customFieldItem.toString());
      let finishedText = '';

      while (true) {
        const startIndex = processedText
          ? processedText.toLowerCase().indexOf(filter.toLowerCase())
          : -1;

        if (startIndex !== -1) {
          const endIndex = startIndex + filter.length;

          result.push({
            text: processedText.substring(0, startIndex),
            highlight: false,
          });

          result.push({
            text: processedText.substring(startIndex, endIndex),
            highlight: true,
          });

          processedText = processedText.substring(endIndex);
        } else {
          result.push({
            text: processedText,
            highlight: false,
          });

          break;
        }
      }

      result.forEach((fragment) => {
        if (fragment.highlight) {
          finishedText = finishedText + '<strong>' + fragment.text + '</strong>';
        } else {
          finishedText = finishedText + fragment.text;
        }
      });

      return finishedText;
    } else {
      const result = customFieldItem;

      return result;
    }
  }
}
