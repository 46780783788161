<pp-modal [ppDisableScroll]="true">
  <ng-container heading> {{ 'add_assignees' | ppTranslate }} </ng-container>

  <ng-container body>
    <div>
      <p class="modal__body-title">{{ 'select_assignees' | ppTranslate }}</p>

      <pp-point-assignees
        (ppOnChange)="updateAssignees($event)"
        [ppBulkChanges]="true"
      ></pp-point-assignees>
    </div>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'save' | ppTranslate"
      [ppProcess]="processing"
      (ppCancel)="hideModal()"
      (ppConfirm)="save()"
      [ppDisabled]="!selectedAssignees"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
