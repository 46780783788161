import { Component, Input, OnInit } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-not-authorized-layout',
  templateUrl: './not-authorized-layout.component.html',
  styleUrls: ['./not-authorized-layout.component.scss'],
})
export class NotAuthorizedLayoutComponent implements OnInit {
  @Input() ppHideLogo: boolean;

  EIconPath = EIconPath;

  ngOnInit(): void {
    this.setFocusStateOnInputs();
  }

  setFocusStateOnInputs(): void {
    const childElements = document.querySelectorAll('.authBox__input');

    childElements.forEach(function (childElement) {
      childElement.addEventListener('focus', function () {
        const parentElement = childElement.parentNode as HTMLElement;
        parentElement.classList.add('focused');
      });

      childElement.addEventListener('blur', function () {
        const parentElement = childElement.parentNode as HTMLElement;
        parentElement.classList.remove('focused');
      });
    });
  }
}
