import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { TWorkspacesById } from '../../../../workspace/workspace.model';
import { WorkspaceService } from '../../../../workspace/workspace.service';
import { TAccount } from '../../../account.model';
import { AddUsersIssuesService } from '../../add-users-modal/add-users-issues.service';
import { EditAccountUserModalDataService } from '../edit-account-user-modal-data.service';

@Component({
  selector: 'pp-account-user-modal-site-list',
  templateUrl: './account-user-modal-site-list.component.html',
  styleUrls: ['./account-user-modal-site-list.component.scss'],
})
export class AccountUserModalSiteListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() ppAccount: TAccount;
  @Input() ppEmailsReadOnly: boolean;
  @Output() ppSelectedWorkspacesChange = new EventEmitter<string[]>();

  private readonly destroy$ = new Subject<void>();

  selectedWorkspaces: string[] = [];
  workspaces: TWorkspacesById;
  private keyword = '';
  filteredWorkspacesIds: string[] = [];
  emailList: string[] = [];
  isAllSelected: boolean;
  warningMessages: string[] = [];

  constructor(
    private workspaceService: WorkspaceService,
    private editAccountUserModalDataService: EditAccountUserModalDataService,
    private createAccountUserModalIssuesService: AddUsersIssuesService,
  ) {
    this.subscribeToWarningChange();
  }

  ngOnInit(): void {
    this.selectedWorkspaces = this.editAccountUserModalDataService.getSelectedWorkspaces();
    this.workspaces = this.workspaceService.getWorkspaces();
    this.emailList = this.editAccountUserModalDataService.getEmailList();
    this.setIsAllSelected();
  }

  ngOnChanges(): void {
    this.filterSites();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  setKeyword(keyword: string): void {
    this.keyword = keyword;

    this.filterSites();
  }

  toggleAllWorkspaces(): void {
    if (this.isAllSelected) {
      this.selectedWorkspaces = this.selectedWorkspaces.filter(
        (id) => !this.filteredWorkspacesIds.includes(id),
      );
    } else {
      this.filteredWorkspacesIds.forEach((workspaceId) => {
        if (!this.selectedWorkspaces.includes(workspaceId)) {
          this.selectedWorkspaces.push(workspaceId);
        }
      });
    }

    this.updateSelectedWorkspaces();
  }

  toggleWorkspace(workspaceId: string): void {
    if (this.selectedWorkspaces.includes(workspaceId)) {
      this.selectedWorkspaces = this.selectedWorkspaces.filter((id) => id !== workspaceId);
    } else {
      this.selectedWorkspaces.push(workspaceId);
    }

    this.updateSelectedWorkspaces();
  }

  private updateSelectedWorkspaces(): void {
    this.editAccountUserModalDataService.updateWorkspacesData(this.selectedWorkspaces);

    this.setIsAllSelected();

    this.ppSelectedWorkspacesChange.emit(this.selectedWorkspaces);
  }

  private setIsAllSelected(): void {
    this.isAllSelected = this.filteredWorkspacesIds.every((workspaceId) =>
      this.selectedWorkspaces.includes(workspaceId),
    );
  }

  private filterSites(): void {
    if (!this.keyword) {
      this.filteredWorkspacesIds = this.ppAccount.workspaces;
    } else {
      this.filteredWorkspacesIds = this.ppAccount.workspaces.filter((workspaceId) =>
        this.workspaces[workspaceId].siteName.toLowerCase().includes(this.keyword.toLowerCase()),
      );
    }

    this.setIsAllSelected();
  }

  setEmailList(emailList: string[]): void {
    this.editAccountUserModalDataService.setEmailList(emailList);
  }

  private setWarningMessages(): void {
    this.warningMessages = this.createAccountUserModalIssuesService.getWarningMessages();
  }

  private subscribeToWarningChange(): void {
    this.createAccountUserModalIssuesService.warningChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.setWarningMessages();
      });
  }
}
