export enum EPriority {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export const humanizePriority = (_priority: EPriority): string => {
  switch (_priority) {
    case EPriority.HIGH:
      return 'High';
    case EPriority.MEDIUM:
      return 'Medium';
    case EPriority.LOW:
      return 'Low';
    default:
      return _priority;
  }
};

export const dehumanizePriority = (_priority: string): EPriority => {
  switch (_priority) {
    case 'High':
      return EPriority.HIGH;
    case 'Medium':
      return EPriority.MEDIUM;
    case 'Low':
      return EPriority.LOW;
    default:
      return null;
  }
};
