<pp-modal *ngIf="workspace" [ppScrollOnDefault]="true" [ppFooterShadow]="true" [ppWidth]="640">
  <ng-container heading>
    {{ 'edit_user' | ppTranslate }}
  </ng-container>

  <ng-container body>
    <pp-share-edit
      [(ppShare)]="share"
      [ppEmails]="[share?.userEmail]"
      (ppEmailsChange)="share ? (share.userEmail = $event[0]) : null"
      [ppShowAdvanced]="false"
      [ppWorkspaceId]="workspace.workspaceId"
      [ppTagError]="tagError"
      [ppEdit]="true"
    ></pp-share-edit>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'save' | ppTranslate"
      (ppCancel)="hideModal()"
      (ppConfirm)="createShare()"
      [ppProcess]="processingShare"
      [ppDisabled]="!share.shareOption"
      ppCancelDataTarget="Add New User Modal Close Button"
      ppConfirmDataTarget="Invite user button"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
