<article class="authPage">
  <div class="authPage__background"></div>
  <section class="authPage__content">
    <h1 class="authPage__title" *ngIf="!ppHideLogo">
      <img
        class="authPage__logo"
        [src]="EIconPath.LOGO"
        alt="Pinpoint Works logo"
        title="Pinpoint Works"
      />
    </h1>
    <ng-content></ng-content>
  </section>
</article>
