import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { TAllCustomFields } from 'src/app/project/modules/custom-fields/custom-fields.model';

export function removeDuplicates(customFields: TAllCustomFields): TAllCustomFields {
  const fieldList = {};
  const sortedFields: TAllCustomFields = {};

  Object.keys(customFields).forEach((workspaceId) => {
    if (!sortedFields[workspaceId]) {
      sortedFields[workspaceId] = {};
    }

    Object.keys(customFields[workspaceId]).forEach((customFieldId) => {
      if (!fieldList[customFields[workspaceId][customFieldId].label]) {
        fieldList[customFields[workspaceId][customFieldId].label] = {};
        fieldList[customFields[workspaceId][customFieldId].label].name =
          customFields[workspaceId][customFieldId].label;

        if (customFields[workspaceId][customFieldId].type === ECustomFieldType.COST) {
          if (!fieldList[customFields[workspaceId][customFieldId].label].currency) {
            fieldList[customFields[workspaceId][customFieldId].label].currency = [];
          }

          fieldList[customFields[workspaceId][customFieldId].label].currency.push(
            customFields[workspaceId][customFieldId].currencyCode,
          );
        }

        sortedFields[workspaceId][customFieldId] = customFields[workspaceId][customFieldId];
      } else {
        if (customFields[workspaceId][customFieldId].type !== ECustomFieldType.DATE) {
          if (customFields[workspaceId][customFieldId].type === ECustomFieldType.COST) {
            if (
              !(
                fieldList[customFields[workspaceId][customFieldId].label].currency &&
                fieldList[customFields[workspaceId][customFieldId].label].currency.includes(
                  customFields[workspaceId][customFieldId].currencyCode,
                )
              )
            ) {
              if (!fieldList[customFields[workspaceId][customFieldId].label].currency) {
                fieldList[customFields[workspaceId][customFieldId].label].currency = [];
              }

              fieldList[customFields[workspaceId][customFieldId].label].currency.push(
                customFields[workspaceId][customFieldId].currencyCode,
              );
              sortedFields[workspaceId][customFieldId] = customFields[workspaceId][customFieldId];
            }
          } else {
            sortedFields[workspaceId][customFieldId] = customFields[workspaceId][customFieldId];
          }
        }
      }
    });
  });

  return sortedFields;
}
