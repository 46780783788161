<button
  id="filterNotificationsBtn"
  data-m-target="Filter notifications dropdown button"
  class="dropdownItem notifications__dropdown-button"
  (click)="filterNotifications()"
>
  <div class="iconWrapper">
    <pp-icon
      [ppSrc]="EIconPath.ICON_INTERACTION_FILTER"
      ppClass="notificationIcon"
      [ppDisplayContents]="true"
    ></pp-icon>
  </div>
  {{ 'filter' | ppTranslate }}
</button>

<button
  id="markAllNotificationsBtn"
  data-m-target="Mark all as read notifications dropdown button"
  class="dropdownItem notifications__dropdown-button"
  (click)="markAllAsRead()"
>
  <div class="iconWrapper">
    <pp-icon
      [ppSrc]="EIconPath.ICON_MARK_ALL_READ"
      ppClass="notificationIcon"
      [ppDisplayContents]="true"
    ></pp-icon>
  </div>
  {{ 'mark_all_as_read' | ppTranslate }}
</button>

<button
  id="clearNotificationsBtn"
  data-m-target="Clear notifications dropdown button"
  class="dropdownItem notifications__dropdown-button"
  (click)="showClearNotificationsModal()"
>
  <div class="iconWrapper">
    <pp-icon
      [ppSrc]="EIconPath.ICON_CLEAR_ALL"
      ppClass="notificationIcon"
      [ppDisplayContents]="true"
    ></pp-icon>
  </div>
  {{ 'clear_all_notifications' | ppTranslate }}
</button>
