<pp-action-bar></pp-action-bar>

<pp-not-authorized-layout [ppHideLogo]="true">
  <section class="authBox">
    <header>
      <section class="authPage__logoWrapper">
        <img [src]="EIconPath.LOGO_2FA" alt="Pinpoint Works logo" title="Pinpoint Works" />
      </section>
    </header>

    <div class="authPage__subheader">
      <h4>{{ '2fa_success_header' | ppTranslate }}</h4>
      <p class="authBox__copy">
        {{ '2fa_success_description' | ppTranslate }}
      </p>
    </div>

    <div class="authPage__icon-wrapper">
      <pp-icon
        class="authPage__successIcon"
        [ppSrc]="EIconPath.SUCCESS_2FA"
        ppColor="grey-600"
        alt="black arrow pointing back"
      ></pp-icon>
    </div>

    <footer class="authBox__footer--gap-s">
      <pp-button ppStyle="primary" ppSize="large" ppId="logInBtn" (ppAction)="continue()">
        {{ '2fa_success_continue' | ppTranslate }}
      </pp-button>
    </footer>
  </section>
</pp-not-authorized-layout>
