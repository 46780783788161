import { Component } from '@angular/core';

@Component({
  selector: 'pp-account-options-placeholder',
  templateUrl: './account-options-placeholder.component.html',
  styleUrls: ['./account-options-placeholder.component.scss']
})
export class AccountOptionsPlaceholderComponent {

}
