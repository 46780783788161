import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import {
  EListSelectTypes,
  ESiteListSelectTypesMultiple,
  ESiteListSelectTypesSingle,
} from '../site-list-select-enums';

@Component({
  selector: 'pp-site-list-select-field',
  templateUrl: './site-list-select-field.component.html',
  styleUrl: './site-list-select-field.component.scss',
})
export class SiteListSelectFieldComponent implements OnChanges {
  @Input() ppWorkspaceId: string;
  @Input() ppType: EListSelectTypes;
  @Input() ppActiveWorkspaceId: string;
  @Input() ppFieldsPerWorkspace: {
    [workspaceId: string]: number;
  };

  @Output() ppAction = new EventEmitter<string>();

  public SiteListSelectTypesSingle = ESiteListSelectTypesSingle;
  public SiteListSelectTypesMultiple = ESiteListSelectTypesMultiple;

  public disabledTooltip: string;
  public disabledReason: 'active' | 'noFields' | 'noTags' | 'noUsers';

  workspaces: TWorkspacesById;
  workspace: TWorkspace;

  constructor(
    private workspaceService: WorkspaceService,
    private translationPipe: TranslationPipe,
  ) {}

  ngOnChanges(): void {
    this.workspaces = this.workspaceService.getWorkspaces();
    this.workspace = this.workspaces[this.ppWorkspaceId];

    if (!this.workspace) {
      return;
    }

    this.checkDisabledReason();
  }

  openSiteCallback(workspaceId: string): void {
    this.ppAction.emit(workspaceId);
  }

  private checkDisabledReason(): void {
    if (this.ppWorkspaceId === this.ppActiveWorkspaceId) {
      this.disabledReason = 'active';
      this.disabledTooltip = this.translationPipe.transform('site_currently_on');
    } else if (
      this.ppType === EListSelectTypes.CUSTOM_FIELD &&
      !(this.workspace.customFields?.length > 0)
    ) {
      this.disabledReason = 'noFields';
      this.disabledTooltip = this.translationPipe.transform('no_custom_fields_available');
    } else if (this.ppType === EListSelectTypes.TAG && !(this.workspace.tags?.length > 0)) {
      this.disabledReason = 'noTags';
      this.disabledTooltip = this.translationPipe.transform('no_tags_available');
    } else if (this.ppType === EListSelectTypes.SHARE && !(this.workspace.users?.length > 0)) {
      this.disabledReason = 'noUsers';
      this.disabledTooltip = this.translationPipe.transform('no_users_available');
    } else {
      this.disabledReason = null;
      this.disabledTooltip = null;
    }
  }
}
