<pp-modal>
  <ng-container heading>
    {{ 'activity' | ppTranslate }}

    <img [src]="EIconPath.INPUT_COG_GRAY" (click)="toggleSettings()" />
  </ng-container>

  <ng-container body>
    <ng-container *ngIf="!dashletSettingsVisible">
      <div class="dashletModal__body-container" #dashletActivities>
        <div class="alert alert-info" *ngIf="activities.length === 0 && fetched">
          {{ 'no_recent_activity' | ppTranslate }}
        </div>

        <ul
          class="dashletActivities__ul"
          *ngIf="activities.length > 0 && fetched; else placeholderActivities"
        >
          <pp-dashlet-activity
            *ngFor="let activity of activities; let i = index"
            [ppActivity]="activity"
            [ppAllUsers]="allUsers"
            [ppWorkspaces]="workspaces"
          ></pp-dashlet-activity>

          <app-spinner [ppDashboard]="true" *ngIf="ppLoading"></app-spinner>
        </ul>

        <ng-template #placeholderActivities>
          <pp-dashlet-activities-placeholder *ngIf="!fetched"></pp-dashlet-activities-placeholder>
        </ng-template>

        <pp-button
          ppStyle="primary"
          ppId="loadMoreActivitiesButton"
          (ppAction)="fetchActivities()"
          *ngIf="!allActivitiesLoaded && !ppLoading"
        >
          {{ 'load_more' | ppTranslate }}
        </pp-button>
      </div>
    </ng-container>

    <ng-container *ngIf="dashletSettingsVisible">
      <pp-activities-dashlet-modal-settings
        (ppSelectSiteRange)="selectSiteRange($event)"
        [ppDashlet]="dashlet"
      >
      </pp-activities-dashlet-modal-settings>
    </ng-container>
  </ng-container>

  <ng-container footer>
    <!-- TODO Footer component -->
    <pp-delete-button
      (ppAction)="removeDashlet()"
      *ngIf="dashletSettingsVisible"
    ></pp-delete-button>

    <pp-button
      data-m-target="Dashlet settings apply button"
      ppStyle="primary"
      (ppAction)="apply()"
      ppSize="small"
      *ngIf="dashletSettingsVisible"
    >
      <i class="fas fa-check" aria-hidden="true"></i>
      {{ 'apply' | ppTranslate }}
    </pp-button>
  </ng-container>
</pp-modal>
