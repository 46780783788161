<pp-modal [ppNoPadding]="true" [ppWidth]="598">
  <ng-container heading>
    {{ modalTitle }}
  </ng-container>

  <ng-container body>
    <div class="exportAssetsModalBody">
      <pp-fleet-export-cover-page-picker
        *ngIf="isFleetExport"
        [(ppIncludeCoverPage)]="includeCoverPage"
      ></pp-fleet-export-cover-page-picker>

      <pp-fleet-export-page-format-picker
        [(ppPageFormat)]="pageFormat"
      ></pp-fleet-export-page-format-picker>

      <pp-fleet-export-page-orientation-picker
        [(ppPageOrientation)]="pageOrientation"
      ></pp-fleet-export-page-orientation-picker>

      <pp-fleet-export-page-assets-picker
        [ppSelectable]="isFleetExport"
        [(ppExportAssets)]="exportAssets"
      ></pp-fleet-export-page-assets-picker>
    </div>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'export' | ppTranslate"
      (ppCancel)="hideModal(true)"
      (ppConfirm)="exportAsset()"
      [ppDisabled]="checkDisabledModal()"
      [ppProcess]="processing"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
