import { NEW_POINT_ID } from 'src/app/project/shared/constants/point.const';
import { EStatus } from '../../../shared/enums/status.enum';
import { TPoint, TPointCustomFields } from '../points.model';
import { EPriority } from '../priorities';

export function generateNewPoint(
  customFields: TPointCustomFields,
  workspaceId: string,
  tags: string[],
): TPoint {
  const point: TPoint = {
    header: {
      updatedEpochMillis: new Date().getTime(),
      createdEpochMillis: new Date().getTime(),
    },
    customFieldsMap: customFields,
    description: '',
    descriptionRich: '',
    pins: [],
    polygons: [],
    priority: EPriority.MEDIUM,
    status: EStatus.OPEN,
    sequenceNumber: NEW_POINT_ID,
    title: '',
    tags,
    assignees: [],
    _id: NEW_POINT_ID,
    workspaceRef: {
      id: workspaceId,
    },
    fullyInitialized: true,
    flagged: false,
  };

  return point;
}
