import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TCurrencySymbol } from 'src/app/project/modules/custom-fields/currencies/currencies.consts';
import { TAllCustomFields } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { TCustomFieldFilter } from 'src/app/project/modules/filters/site-filter.model';

@Component({
  selector: 'pp-filter-field-cost',
  templateUrl: './filter-field-cost.component.html',
  styleUrls: ['../../../site-filter-dropdown/site-filter-dropdown.component.scss'],
})
export class FilterFieldCostComponent {
  @Input() ppCustomFields: TAllCustomFields;
  @Input() ppField: TCustomFieldFilter;
  @Output() ppApply = new EventEmitter();

  currency: TCurrencySymbol;

  ngOnChanges(): void {
    this.getCurrency();
  }

  updateCostField(field: TCustomFieldFilter): void {
    field.excluded = false;

    this.applyFilters();
  }

  applyFilters(): void {
    this.ppApply.emit();
  }

  private getCurrency(): void {
    const field = this.ppCustomFields[this.ppField.workspaceId][this.ppField.id];
    this.currency = field.currencyCode + ' ' + field.currencySymbol;
  }
}
