import { Subject } from 'rxjs';
import { DropdownService } from './dropdown-service/dropdown.service';
import { DropdownComponent } from './dropdown.component';

let secondaryDropdown: DropdownComponent;
let secondaryDropdownService: DropdownService;

export const secondaryDropdownServiceChange$ = new Subject<void>();

export const SET_SECONDARY_DROPDOWN = (dropdown: DropdownComponent): void => {
  secondaryDropdown = dropdown;
};

export const GET_SECONDARY_DROPDOWN = (): DropdownComponent => secondaryDropdown;

export const GET_SECONDARY_DROPDOWN_SERVICE = (): DropdownService => secondaryDropdownService;

export function setSecondaryDropdown(secondaryService: DropdownService): void {
  secondaryDropdownService = secondaryService;
  const secondaryDropdownComponent = GET_SECONDARY_DROPDOWN();
  secondaryDropdownComponent.dropdown = secondaryDropdownService.getDropdown();
  secondaryDropdownService.setDropdownElement(document.getElementById('secondaryDropdown'));
  secondaryDropdownService.getDropdown().dropdownHost = GET_SECONDARY_DROPDOWN().dropdownHost;

  secondaryDropdownServiceChange$.next();
}
