import { cloneDeep } from 'lodash';
import { RemoveAttachments } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';

export function removeAttachments(
  oldState: TPointsByWorkspace,
  action: RemoveAttachments,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);
  let pointIndex = null;

  pointIndex = state[action.payload.workspaceId].entities.findIndex(
    (_point) => _point._id === action.payload._id,
  );
  const point = state[action.payload.workspaceId].entities[pointIndex];

  if (point) {
    point.header.updatedEpochMillis = new Date().getTime();
    point.containsAttachments = action.payload.containsAttachments;
  }

  return state;
}
