import { Injectable } from '@angular/core';
import { TAnyFunction } from '@core/helpers';
import { Observable } from 'rxjs';
import { ChangeLimitModalComponent } from 'src/app/project/components/change-limit-modal/change-limit-modal.component';
import { TChangeLimitsModalData } from 'src/app/project/components/change-limit-modal/change-limit-modal.model';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { ShareBulkService } from '../../../share/share-bulk.service';
import { EUserRole } from '../../../share/share-utils/user-roles';
import { TCreateShareDTO, TNewUser, TUpdateShareDTO } from '../../../share/share.model';
import { TAccountUser } from '../../../users/account.user.model';
import { TSimulatedUsageCreateShare, TSimulatedUsageEditShare } from '../../account.model';
import { EditAccountUserModalDataService } from './edit-account-user-modal-data.service';
import {
  TAccountEditedShare,
  TEditedAccountShares,
  TSharesByWorkspace,
} from './edit-account-user-modal.consts';

@Injectable({
  providedIn: 'root',
})
export class EditAccountUserModalService {
  constructor(
    private shareBulkService: ShareBulkService,
    private modalService: ModalService,
    private editAccountUserModalDataService: EditAccountUserModalDataService,
  ) {}

  editShares(
    newShareTemplates: TSharesByWorkspace,
    selectedShares: TAccountEditedShare[],
  ): Observable<TNewUser[]> {
    const sharesToSend: TUpdateShareDTO[] = [];
    const workspaceIds = Object.keys(newShareTemplates);

    if (workspaceIds.length > 0) {
      workspaceIds.forEach((newShareWorkspaceId) => {
        const newShare = newShareTemplates[newShareWorkspaceId];

        selectedShares.forEach((selectedShare) => {
          if (selectedShare.workspaceId !== newShareWorkspaceId) {
            return;
          }

          selectedShare.shares.forEach((share) => {
            const shareToSend: TUpdateShareDTO = {
              ...newShare,
              shareId: share.shareId,
              userEmail: share.email,
            };

            sharesToSend.push(shareToSend);
          });
        });
      });
    }

    return this.shareBulkService.bulkEditShares(sharesToSend);
  }

  createShares(newShares: TSharesByWorkspace, emails: string[]): Observable<TNewUser[]> {
    const sharesToSend = this.prepareSharesToSend(newShares, emails);

    if (Object.keys(newShares).length > 0) {
      return this.shareBulkService.bulkCreateShare(sharesToSend);
    }
  }

  downgradeAccountAdmin(accountShares: TAccountUser[]): Observable<TNewUser[]> {
    const emails = this.editAccountUserModalDataService.getEmailList();
    const newShares = this.addShareIdsToNewShares(emails, accountShares);

    if (Object.keys(newShares).length > 0) {
      return this.shareBulkService.downgradeAccountAdmin(newShares);
    }
  }

  private addShareIdsToNewShares(
    emails: string[],
    accountShares: TAccountUser[],
  ): TCreateShareDTO[] {
    const sharesToSend: TCreateShareDTO[] = [];
    const newShares = this.editAccountUserModalDataService.getSharesMap();
    const accountAdminEmail = emails[0];

    const accountAdminShares = accountShares.find(
      (accountShare) => accountShare.email === accountAdminEmail,
    );

    Object.keys(newShares).forEach((workspaceId) => {
      const newShare = newShares[workspaceId];
      const accountAdminShare = accountAdminShares.shares.find(
        (share) => share.workspaceId === workspaceId,
      );

      newShare.shareId = accountAdminShare.shareId;
      newShare.userEmail = accountAdminEmail;
      sharesToSend.push(newShare);
    });

    return sharesToSend;
  }

  showEditUserLimitModal({
    callback,
    accountId,
    editedShares,
    deletedShares,
  }: {
    callback: TAnyFunction;
    accountId: string;
    editedShares: TEditedAccountShares;
    deletedShares: string[];
  }): void {
    const editList: TSimulatedUsageEditShare[] = this.prepareEditList(editedShares);
    const modalData: TChangeLimitsModalData = this.setModalData({
      accountId,
      editList,
      deleteList: deletedShares,
    });

    this.showChangeLimitModal(modalData, callback);
  }

  showDeleteUserModalData(accountId, deleteList: string[], callback: TAnyFunction): void {
    const modalData: TChangeLimitsModalData = {
      firstMessageKey: 'users_limit_reached_description_1',
      secondMessageKey: 'users_limit_reached_description_2',
      confirmKey: 'remove_users_button',
      header: 'users_limit_reached',
      accountId: accountId,
      changedLimits: {
        createList: [],
        editList: [],
        importList: [],
        deleteList: deleteList,
      },
    };

    this.modalService.setData<TChangeLimitsModalData>(modalData);

    this.modalService.showModal(ChangeLimitModalComponent, {
      onBeforeClose: (cancelled) => callback(cancelled),
    });
  }

  // TODO: rename showAddUserLimitModal or showChangeLimitModal -- they sound like 2 different modals but point to the same thing
  showAddUserLimitModal({
    callback,
    accountId,
    userEmails,
    newShares,
  }: {
    callback: TAnyFunction;
    accountId: string;
    userEmails: string[];
    newShares: TSharesByWorkspace;
  }): void {
    const createList: TSimulatedUsageCreateShare[] = this.prepareCreateList(userEmails, newShares);

    const modalData: TChangeLimitsModalData = this.setModalData({ accountId, createList });
    this.showChangeLimitModal(modalData, callback);
  }

  private prepareEditList(editedShares: TEditedAccountShares): TSimulatedUsageEditShare[] {
    return Object.keys(editedShares).map((workspaceId) => ({
      shareOption: editedShares[workspaceId].shareOption as EUserRole,
      shareId: editedShares[workspaceId].shareId,
    }));
  }

  private showChangeLimitModal(modalData: TChangeLimitsModalData, callback: TAnyFunction): void {
    this.modalService.hideModal().then(() => {
      this.modalService.setData<TChangeLimitsModalData>(modalData);

      this.modalService.showModal(ChangeLimitModalComponent, {
        onBeforeClose: (cancelled) => callback(cancelled),
      });
    });
  }

  private prepareCreateList(
    userEmails: string[],
    newShares: TSharesByWorkspace,
  ): TSimulatedUsageCreateShare[] {
    let createList: TSimulatedUsageCreateShare[] = [];

    userEmails.forEach((email) => {
      const userCreateEmails = Object.keys(newShares).map((workspaceId) => ({
        shareOption: newShares[workspaceId].shareOption as EUserRole,
        userEmail: email,
      }));

      createList = [...createList, ...userCreateEmails];
    });

    return createList;
  }

  private setModalData({
    accountId,
    createList = [],
    editList = [],
    deleteList = [],
  }: {
    accountId: string;
    createList?: TSimulatedUsageCreateShare[];
    editList?: TSimulatedUsageEditShare[];
    deleteList?: string[];
  }): TChangeLimitsModalData {
    return {
      firstMessageKey: 'users_limit_reached_description_1',
      secondMessageKey: 'users_limit_reached_description_2',
      confirmKey: 'add_user',
      header: 'users_limit_reached',
      accountId: accountId,
      changedLimits: {
        createList,
        editList,
        importList: [],
        deleteList: deleteList,
      },
    };
  }

  private prepareSharesToSend(newShares: TSharesByWorkspace, emails: string[]): TCreateShareDTO[] {
    const sharesToSend: TCreateShareDTO[] = [];
    const workspaceIds = Object.keys(newShares);

    if (workspaceIds.length > 0) {
      workspaceIds.forEach((workspaceId) => {
        const newShare = newShares[workspaceId];

        emails.forEach((email) => {
          const shareToSend: TCreateShareDTO = { ...newShare, userEmail: email };

          sharesToSend.push(shareToSend);
        });
      });
    }

    return sharesToSend;
  }
}
