<div class="topBarFleetOptions">
  <pp-top-bar-fleet-options-asset-search class="searchBar"></pp-top-bar-fleet-options-asset-search>
  <pp-top-bar-fleet-options-fleet-select
    class="selectAccount"
  ></pp-top-bar-fleet-options-fleet-select>

  <div class="topBarFleetOptions__buttonContainer">
    <pp-button ppStyle="primary" ppSize="large" (ppAction)="addAsset()" *ngIf="canEdit">
      <p class="fleetOptionsButtonText">
        {{ 'add_asset' | ppTranslate }}
      </p>
    </pp-button>

    <pp-button ppStyle="primary" ppSize="large" (ppAction)="shareFleet()" *ngIf="canEdit">
      <p class="fleetOptionsButtonText">
        {{ 'share' | ppTranslate }}
      </p>
    </pp-button>
  </div>
</div>
