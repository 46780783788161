import * as XLSX from 'xlsx';

import { Injectable } from '@angular/core';

import { Subject, tap } from 'rxjs';
import { WindowService } from '@core/services';
import { TAttachment } from 'src/app/project/modules/points/attachments/attachments.model';
import { getExtenstion } from 'src/app/core/helpers/get-extenstion';
import { FilesApiProviderService } from '@core/api';

type AOA = any[][];

@Injectable({
  providedIn: 'root',
})
export class PointDocumentsService {
  private documentOpened$ = new Subject<boolean>();
  documentOpenedObservable$ = this.documentOpened$.asObservable();
  documentOpened = false;

  file: TAttachment;
  scale = 1;
  data: AOA = [
    [1, 2],
    [3, 4],
  ];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName = 'SheetJS.xlsx';

  constructor(
    private windowService: WindowService,
    private filesApiProviderService: FilesApiProviderService,
  ) {}

  openDocument(file: TAttachment): void {
    this.setFile(file);

    if (file.mimeType === 'application/pdf') {
      this.filesApiProviderService
        .fetchDocumentS3Link(file.attachmentId)
        .pipe(
          tap((response) => {
            this.windowService.open(response);
          }),
        )
        .subscribe();
    } else {
      this.documentOpened = true;

      this.openDocumentTrigger(false);
      this.openDocumentTrigger(true);
    }
  }

  openDocumentTrigger(loaded: boolean): void {
    this.documentOpened$.next(loaded);
  }

  closeDocument(): void {
    this.file = null;
    this.documentOpened = false;
  }

  getFile(): TAttachment {
    return this.file;
  }

  setFile(file: TAttachment): void {
    this.file = file;
  }

  getScale(): number {
    return this.scale;
  }

  isSpreadSheet(file: File): boolean {
    const extension = getExtenstion(file?.name).toLowerCase();

    if (
      file.type === 'application/vnd.oasis.opendocument.spreadsheet' ||
      file.type === 'application/vnd.sun.xml.calc' ||
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel' ||
      file.type === 'text/xml' ||
      file.type === 'text/csv' ||
      extension === 'xlxs' ||
      extension === 'csv' ||
      extension === 'dbf' ||
      extension === 'dif' ||
      extension === 'xls' ||
      extension === 'xlsx' ||
      extension === 'uos'
    ) {
      return true;
    }

    return false;
  }

  isPdf(file: TAttachment): boolean {
    return file.mimeType === 'application/pdf';
  }

  isText(file: TAttachment): boolean {
    return file.mimeType === 'text/plain';
  }

  getDocumentOpened(): boolean {
    return this.documentOpened;
  }
}
