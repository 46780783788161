import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { TAccount } from 'src/app/project/modules/account/account.model';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { EGoogleEventLabel } from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EUserRole } from '../../../share-utils/user-roles';
import { TCustomFieldAccessOption, TShare } from '../../../share.model';
import { EAccessParameter } from '../access-parameters';
import { ShareEditService } from '../share-edit.service';

@Component({
  selector: 'pp-share-edit-site-plan',
  templateUrl: './share-edit-site-plan.component.html',
  styleUrls: ['./share-edit-site-plan.component.scss'],
})
export class ShareEditSitePlanComponent {
  @Input() ppShare: TShare;
  @Input() ppWorkspaceId: string;
  @Output() ppChange = new EventEmitter<void>();
  @Output() ppShareChange = new EventEmitter<TShare>();

  EUserRole = EUserRole;
  EIconPath = EIconPath;
  EGoogleEventLabel = EGoogleEventLabel;
  accessParameters = EAccessParameter;
  workspace: TWorkspace;
  account: TAccount;

  constructor(
    private shareEditService: ShareEditService,
    private workspaceService: WorkspaceService,
    private accountService: AccountService,
  ) {}

  ngOnChanges(): void {
    this.workspace = this.workspaceService.getWorkspace(this.ppWorkspaceId);
    this.account = this.accountService.getAccount(this.workspace.accountId);
  }

  correctProperty(
    property: EGoogleEventLabel,
    parameter?: EAccessParameter,
    field?: TCustomFieldAccessOption,
  ): void {
    this.ppShare = this.shareEditService.correctProperty({
      workspaceId: this.ppWorkspaceId,
      share: this.ppShare,
      property,
      parameter,
      field,
    });

    this.ppChange.emit();
  }
}
