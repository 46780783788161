import Tooltip from 'src/app/project/features/tooltip/Tooltip';

import { GET_ACCOUNTS } from 'src/app/project/modules/account/account.store';
import { GET_USER } from 'src/app/project/modules/user/user.store';
import {
  ADD_COLLAPSED_FOLDER,
  ADD_VISIBLE_EMPTY_FOLDER,
  DELETE_COLLAPSED_FOLDER,
  DELETE_EDITED_FOLDER,
  GET_COLLAPSED_FOLDERS,
  GET_DRAGGED_SITE,
  GET_EDITED_FOLDER,
  GET_VISIBLE_EMPTY_FOLDERS,
} from '../utils/account-list.ui.store';

import { createElement, TAnyFunction } from '@core/helpers';
import { ApiService } from '@core/http';
import { getSvg } from 'src/app/core/helpers/get-svg';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { TFlattenedAccount } from '../account-list/account-list-flatten-account';
import { NEW_FOLDER_ID } from '../utils/account-list-constants';
import { createMarkedKeywordSidePanelElement } from '../utils/side-panel-search';

export default class Folder {
  private folder: TFlattenedAccount;
  private folderSettingsCallback: TAnyFunction;
  private updateCallback: TAnyFunction;
  private errorCallback: TAnyFunction;

  private dragoverCallback: TAnyFunction;
  private collapsed: boolean;

  private folderIconElement: HTMLImageElement;
  private nameElement: HTMLElement;
  private inputElement: HTMLInputElement;
  private settingsElement: HTMLElement;
  private folderElement: HTMLElement;
  private clearIconElement: HTMLElement;
  private nameTooltip;
  private settingsTooltip;
  private editing: boolean;
  private apiService: ApiService;

  element: HTMLElement;

  constructor(
    _folder: TFlattenedAccount,
    apiService: ApiService,
    {
      folderSettingsCallback,
      updateCallback,
      errorCallback,
      dragoverCallback,
    }: {
      folderSettingsCallback: TAnyFunction;
      updateCallback: TAnyFunction;
      errorCallback: TAnyFunction;
      dragoverCallback: TAnyFunction;
    },
  ) {
    const editedFolder = GET_EDITED_FOLDER();

    this.apiService = apiService;
    this.folder = _folder;
    this.folderSettingsCallback = folderSettingsCallback;
    this.updateCallback = updateCallback;
    this.errorCallback = errorCallback;
    this.dragoverCallback = dragoverCallback;
    this.collapsed =
      GET_COLLAPSED_FOLDERS()[this.folder.accountId] &&
      GET_COLLAPSED_FOLDERS()[this.folder.accountId].indexOf(this.folder.id) !== -1;
    this.folderIconElement = null;
    this.nameElement = null;
    this.inputElement = null;
    this.settingsElement = null;

    if (
      editedFolder &&
      editedFolder.accountId === this.folder.accountId &&
      editedFolder._folderId === this.folder.id
    ) {
      this.editing = true;
    } else {
      this.editing = false;
    }

    this.element = this.create();

    if (this.editing) {
      setTimeout(() => {
        this.inputElement.focus();
        this.inputElement.selectionStart = this.inputElement.value.length;
        this.inputElement.selectionEnd = this.inputElement.value.length;
      }, 100);
    }
  }

  // --------------------------------------------------
  // -------------------- ELEMENTS --------------------
  // --------------------------------------------------

  create(): HTMLElement {
    if (!this.editing) {
      this.folderElement = createElement('div', {
        attrs: {
          class: 'folderNav__element folderNav__element-head',
          'data-folder_id': this.folder.id,
          'data-account_id': this.folder.accountId,
        },
        eventListeners: {
          click: () => {
            this.toggle();
          },
          dragover: (_event) => {
            const draggedSite = GET_DRAGGED_SITE();

            if (draggedSite) {
              _event.preventDefault();

              if (this.folder.accountId === draggedSite.accountId) {
                this.dragoverCallback(this.folder.id);
              } else {
                this.dragoverCallback(null);
              }
            } else {
              this.dragoverCallback(null);
            }
          },
        },
        children: [
          this.createArrowIconElement(),
          this.createNameElement(),
          this.createSettingsElement(),
        ],
      });

      this.nameTooltip = new Tooltip({
        options: {},
        title: this.folder.name,
        mobileTooltip: false,
        trimTooltip: true,
        childTooltip: false,
        element: this.folderElement,
        trimElement: this.nameElement,
      });

      this.nameTooltip.createTooltip();
    } else {
      this.folderElement = createElement('div', {
        attrs: {
          class: 'folderNav__element folderNav__element-head',
          'data-folder_id_empty': this.folder.id,
        },
        children: [this.createInputElement(), this.createClearIconElement()],
      });
    }

    ADD_VISIBLE_EMPTY_FOLDER(this.folder.accountId, this.folder.name);
    return this.folderElement;
  }

  createClearIconElement(): HTMLElement {
    this.clearIconElement = createElement('div', {
      attrs: {
        class: 'folderInput__close',
      },
      eventListeners: {
        click: () => {
          DELETE_EDITED_FOLDER();

          this.updateCallback({ toggle: true, cancel: true });
        },
      },
      children: [
        createElement('img', {
          attrs: {
            class: 'closeIcon',
            src: EIconPath.INTERACTION_DELETE_TEXT,
          },
        }),
      ],
    });

    return this.clearIconElement;
  }
  createArrowIconElement(): HTMLElement {
    this.folderIconElement = createElement('img', {
      attrs: {
        src: this.collapsed ? EIconPath.ARROW_RIGHT_WHITE : EIconPath.INTERACTION_CLOSE_ACCOUNT,
      },
    });

    const arrowElement = createElement('div', {
      attrs: {
        class: 'account-nav__arrow',
      },
      children: [this.folderIconElement],
    });

    getSvg(
      this.apiService,
      this.collapsed ? EIconPath.ICON_SIDE_PANEL_ARROW_RIGHT : EIconPath.ICON_SIDE_PANEL_ARROW_DOWN,
    ).then((svg) => {
      arrowElement.innerHTML = svg;
    });

    return arrowElement;
  }

  createNameElement(): HTMLElement {
    this.nameElement = createElement('span', {
      attrs: {
        class: ['folder-nav__folder-name', 'account-nav__folder-name-text'],
      },
      children: [createMarkedKeywordSidePanelElement(this.folder.name)],
    });

    return this.nameElement;
  }

  createInputElement(): HTMLElement {
    this.inputElement = createElement('input', {
      attrs: {
        class: ['folder-nav__folder-input', 'account-nav__folder-name-text'],
        value: this.folder.name,
      },
      eventListeners: {
        blur: (event) => {
          const path = event.path || (event.composedPath && event.composedPath()); // Firefox doesn't have "path" property in event

          if (path) {
            for (let i = 0; i < path.length; i++) {
              if (path[i].classList) {
                for (let j = 0; j < path[i].classList.length; j++) {
                  if (path[i].classList[j] === 'folder-nav__folder-input') {
                    return;
                  }
                }
              }
            }
          }

          this.saveInput(event);
        },
        input: () => {
          this.inputElement.classList.remove('folder-nav__folder-input--error');
        },
        keyup: (event) => {
          if (event.key === 'Enter' || event.keyCode === 13) {
            this.saveInput(event);
          }
        },
      },
    });

    return this.inputElement;
  }

  createSettingsElement(): HTMLElement {
    const user = GET_USER();
    const account = this.folder.account;

    let adminUser = false;

    if (this.folder.workspaces.length === 0) {
      adminUser = true;
    } else {
      for (let workspaceIndex = 0; workspaceIndex < account.workspaces.length; workspaceIndex++) {
        if (
          this.folder.workspaces[workspaceIndex] &&
          (this.folder.workspaces[workspaceIndex].shareOption === EUserRole.SITE_ADMIN ||
            this.folder.workspaces[workspaceIndex].shareOption === EUserRole.ACCOUNT_ADMIN)
        ) {
          adminUser = true;

          break;
        }
      }
    }

    if (
      (user.accountId && user.accountId === this.folder.accountId) ||
      user.isSuperUser ||
      adminUser
    ) {
      this.settingsElement = createElement('a', {
        attrs: {
          id: `sidePanelFolderSettingsDropdownButton${this.folder.accountId}-${this.folder.id}`,
          class: [
            'mainNav__options',
            'mainNav__option',
            'mainNav__option--account',
            'filterPanel__tooltip',
          ],
        },
        eventListeners: {
          click: (_event) => {
            _event.stopPropagation();
            this.folderSettingsCallback({
              folderId: this.folder.id,
              accountId: this.folder.accountId,
              buttonId: this.settingsElement.id,
            });
          },
        },
        children: [
          createElement('img', {
            attrs: {
              src: EIconPath.INPUT_COG_GRAY,
            },
          }),
        ],
      });

      getSvg(this.apiService, EIconPath.ICON_COG_GREY).then((svg) => {
        this.settingsElement.innerHTML = svg;
      });

      this.settingsTooltip = new Tooltip({
        options: {},
        title: 'Folder Settings',
        mobileTooltip: false,
        trimTooltip: false,
        childTooltip: false,
        element: this.settingsElement,
      });

      this.settingsTooltip.createTooltip();
    } else {
      this.settingsElement = createElement('a');
    }

    return this.settingsElement;
  }

  // --------------------------------------------------
  // --------------------- METHODS --------------------
  // --------------------------------------------------

  toggle(): void {
    if (this.collapsed) {
      DELETE_COLLAPSED_FOLDER(this.folder.accountId, this.folder.id);

      this.updateCallback({ toggle: true });
    } else {
      ADD_COLLAPSED_FOLDER(this.folder.accountId, this.folder.id);

      this.updateCallback({ toggle: true });
    }
  }

  saveInput(event: Event): void {
    if (event.target instanceof HTMLInputElement) {
      const target = event.target as HTMLInputElement;

      if (target.value === '' || !target.value) {
        this.inputElement.classList.add('folder-nav__folder-input--error');

        this.errorCallback('Please add a folder name.');
      } else if (target.value === this.folder.name) {
        DELETE_EDITED_FOLDER();

        this.updateCallback({ cancel: true });

        return;
      } else {
        let nameTaken = false;
        const visibleEmptyFolders = GET_VISIBLE_EMPTY_FOLDERS();
        const accounts = GET_ACCOUNTS();
        const account = accounts.find((_account) => _account.accountId === this.folder.accountId);

        if (account.accountFolders) {
          // if for accounts that didn't have folders (and we're creating new one)
          account.accountFolders.forEach((_folder) => {
            if (
              _folder.name === target.value &&
              (_folder.workspaceIds?.length > 0 ||
                this.folder.id !== NEW_FOLDER_ID ||
                (visibleEmptyFolders[account.accountId] &&
                  visibleEmptyFolders[account.accountId].indexOf(_folder.name) !== -1))
            ) {
              nameTaken = true;
            }
          });
        }

        if (nameTaken) {
          this.inputElement.classList.add('folder-nav__folder-input--error');

          this.errorCallback(
            'Oops! This folder name already exists. Please choose a different name.',
          );
        } else {
          this.inputElement.classList.remove('folder-nav__folder-input--error');

          this.folder.name = target.value;

          DELETE_EDITED_FOLDER();

          this.updateCallback({});
        }
      }
    }
  }
}
