import { Injectable } from '@angular/core';
import { TActivity, TCommentsData } from './point-activity.service';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { logErrorInSentry } from '../../../errors/response-error';
import { Observable, throwError } from 'rxjs';
import { TCommentData } from '../../comment-response.model';
import { CommentApiProviderService } from '@core/api';
import { ResponseErrorService } from '../../../errors/response-error.service';
import { ActivityFormatterService } from './activity-formatter.service';
import { TActivityResponse } from '@project/view-models';

@Injectable({ providedIn: 'root' })
export class PointActivityCommentsService {
  private readonly comments: TCommentsData;

  private comments$: Observable<TCommentData[]>;

  constructor(
    private commentApiProviderService: CommentApiProviderService,
    private responseErrorService: ResponseErrorService,
    private activityFormatterService: ActivityFormatterService,
  ) {
    this.comments = {
      data: [],
      workspaceId: null,
      _id: null,
      firstFetch: true,
      fetching: false,
    };
  }

  getComments(): TCommentsData {
    return this.comments;
  }

  fetchComments(pointId: string, refetch: boolean = false): Observable<TCommentData[]> {
    if (
      this.comments.firstFetch ||
      (refetch && !this.comments.fetching) ||
      (pointId !== this.comments._id && !this.comments.fetching)
    ) {
      this.comments.firstFetch = false;
      this.comments.fetching = true;
      this.comments.data = [];

      this.comments$ = this.commentApiProviderService.fetchComments(pointId).pipe(
        tap((response) => {
          this.comments.fetching = false;
          this.comments.data = response.slice().reverse() as unknown as TActivityResponse[];
          this.comments._id = pointId;

          this.comments.data =
            this.activityFormatterService.getFormattedDatetimeActivities<TActivity>(
              this.comments.data,
            );
        }),
        shareReplay(),
        catchError((error) => {
          this.comments.fetching = false;

          logErrorInSentry(error);
          this.responseErrorService.errors(error.status);

          return throwError(error);
        }),
      );
    }

    return this.comments$;
  }

  deleteComment(commentId: string): Observable<null> {
    return this.commentApiProviderService.deleteComment(commentId).pipe(
      tap(() => {
        const commentToDeleteIndex = this.comments.data.findIndex(
          (comment) => comment._id === commentId,
        );

        if (commentToDeleteIndex > -1) {
          this.comments.data.splice(commentToDeleteIndex, 1);
        }
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }
}
