import { transformTimeCF } from '../../custom-fields/custom-field-time/custom-field-time-transformer';
import { ECustomFieldType } from '../../custom-fields/custom-field-types-enums';
import { TPoint } from '../../points/points.model';
import { TColumn } from '../../site/site-table/columns/column.model';
import { calculateGroupColumnSum } from './calculate-group-sum';

export function generateGroupCellValue(column: TColumn, points: TPoint[], customFields): string {
  if (column.customFieldIds) {
    let value = 0;

    switch (customFields[column.customFieldIds[0]].type) {
      case ECustomFieldType.NUMBERS:
      case ECustomFieldType.COST:
        return calculateGroupColumnSum(points, column).toString();
      case ECustomFieldType.FORMULA:
        switch (customFields[column.customFieldIds[0]].outputType) {
          case ECustomFieldType.NUMBERS:
          case ECustomFieldType.COST:
            return calculateGroupColumnSum(points, column).toString();
          default:
            return '';
        }
      case ECustomFieldType.TIME:
        value = calculateGroupColumnSum(points, column);

        const showHoursOnly = customFields[column.customFieldIds[0]].showHoursOnly;
        const formattedValue = transformTimeCF(value, showHoursOnly);
        return formattedValue ? formattedValue : showHoursOnly ? '00:00' : '0d / 00:00';
      default:
        return '';
    }
  } else {
    return '';
  }
}
