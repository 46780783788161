<div id="tabContent" class="tabContent">
  <div class="">
    <pp-grid [ppOptions]="{ bodyPadding: true }">
      <ng-container title>
        {{ 'tags' | ppTranslate }}

        <i
          ppTooltip
          [ppOptions]="{ placement: 'right' }"
          class="fa fa-info-circle settingsSection__header-info"
          aria-hidden="true"
          [ppMobileTooltip]="true"
          [ppSanitize]="false"
          ppTitle="
          <p style='text-align: left'>
              {{ 'tag_settings_1' | ppTranslate }}
            <br><br>
            {{ 'tag_settings_2' | ppTranslate }}
            <br><br>
            {{ 'tag_settings_3' | ppTranslate }}
          </p>"
        ></i>
      </ng-container>

      <ng-container titleBar-right>
        <pp-button
          *ngIf="
            workspace.share.shareOption === EUserRole.OWNER ||
            workspace.share.shareOption === EUserRole.SITE_ADMIN ||
            workspace.share.shareOption === EUserRole.ACCOUNT_ADMIN ||
            user.isSuperUser
          "
          class="hidden--mobile"
          ppStyle="primary"
          ppId="tagImportButton"
          (ppAction)="toggleTagsImportModal()"
          [ppDisabled]="offline || !workspacesLoaded"
        >
          {{ 'import_tags' | ppTranslate }}
        </pp-button>
      </ng-container>

      <ng-container body>
        <div *ngIf="tags">
          <ng-container *ngIf="workspacesLoaded && tagsLoaded">
            <pp-tag
              *ngFor="let tag of tags"
              [ppText]="tag.name + (+tag.defects > 0 ? '(' + tag.defects + ')' : '')"
              [ppDeletable]="true"
              [ppDisabled]="false"
              (ppDelete)="deleteTag(tag)"
            ></pp-tag>
          </ng-container>

          <div
            class="settingsSection__no-content"
            *ngIf="tags.length < 1 && workspacesLoaded && tagsLoaded"
          >
            {{ 'no_tags_found' | ppTranslate }}
          </div>

          <div class="settingsSection__no-content" *ngIf="!workspacesLoaded || !tagsLoaded">
            <app-spinner></app-spinner>
          </div>

          <form
            class="settingsTags__add-tag-form"
            *ngIf="workspacesLoaded && tagsLoaded"
            name="tagForm"
            [ngClass]="{ 'settingsTags__add-tag-form--center': tags.length < 1 }"
          >
            <input
              #tagNameInput
              name="newTag"
              class="input input-border-bottom"
              type="text"
              [(ngModel)]="newTag"
              (input)="checkNewTag($event)"
              placeholder="Create a new tag..."
              (keydown.enter)="addTag()"
              [class.tagInput__error]="newTag.trim().length > 50 || tagNameInvalid"
              [disabled]="offline"
              require
            />

            <pp-button
              ppStyle="secondary"
              ppId="addNewTagBtn"
              ppSize="small"
              (ppAction)="addTag()"
              [ppProcess]="processingTag"
              [ppDisabled]="offline"
            >
              {{ 'create' | ppTranslate }}
            </pp-button>
          </form>
        </div>
      </ng-container>
    </pp-grid>
  </div>
</div>
