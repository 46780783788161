import { TSelectOption } from 'src/app/project/components/input/select/select.model';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';
import { translate } from 'src/app/project/features/translate/translate';
import { TSelectMultipleOption } from 'src/app/project/components/input/select-multiple/select-multiple.model';
import { EStatus } from 'src/app/project/shared/enums/status.enum';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

export function getAdvancedStatusOptions(): TSelectOption[] {
  return [
    {
      value: EAdvancedFilterOptions.IS,
      label: translate('is') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_NOT,
      label: translate('is_not') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_ANY_OF,
      label: translate('is_any_of') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_NONE_OF,
      label: translate('is_none_of') + '...',
    },
  ];
}

export function getAdvancedStatusValues(): TSelectMultipleOption[] {
  return [
    {
      value: EStatus.OPEN,
      label: translate('open'),
      selected: false,
      iconSrc: EIconPath.ICON_BADGE_STATUS_OPEN_14,
    },
    {
      value: EStatus.IN_PROGRESS,
      label: translate('in_progress'),
      selected: false,
      iconSrc: EIconPath.ICON_BADGE_STATUS_IN_PROGRESS_14,
    },
    {
      value: EStatus.TO_REVIEW,
      label: translate('to_review'),
      selected: false,
      iconSrc: EIconPath.ICON_BADGE_STATUS_TO_REVIEW_14,
    },
    {
      value: EStatus.ONHOLD,
      label: translate('on_hold'),
      selected: false,
      iconSrc: EIconPath.ICON_BADGE_STATUS_ON_HOLD_14,
    },
    {
      value: EStatus.CLOSED,
      label: translate('completed'),
      selected: false,
      iconSrc: EIconPath.ICON_BADGE_STATUS_CLOSED_14,
    },
    {
      value: EStatus.CANCELED,
      label: translate('canceled'),
      selected: false,
      iconSrc: EIconPath.ICON_BADGE_STATUS_CANCELED_14,
    },
  ];
}
