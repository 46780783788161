import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountSitesArchiveModalComponent } from './account-sites-archive-modal.component';
import { ModalModule } from 'src/app/project/components/modal/modal.module';
import { ButtonModule } from 'src/app/project/components/button/button.module';
import { TooltipsModule } from 'src/app/project/features/tooltip/tooltips.module';
import { AutonumericModule } from 'src/app/project/directives/autonumeric/autonumeric.module';
import { FocusModule } from 'src/app/project/directives/focus/focus.module';
import { ImagesFallbackModule } from 'src/app/project/directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from 'src/app/project/directives/on-rendered/on-rendered.module';
import { SwitchCasesModule } from 'src/app/project/directives/switch-cases/switch-cases.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [AccountSitesArchiveModalComponent],
  imports: [
    CommonModule,
    ModalModule,
    ButtonModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    PipesModule,
  ],
  exports: [AccountSitesArchiveModalComponent],
})
export class AccountSitesArchiveModule {}
