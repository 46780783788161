export const API_auth_login = (): string => `/api/v1/auth/login`;
export const API_auth_logged = (): string => `/api/v1/auth/logged`;
export const API_auth_logout = (): string => '/api/v1/auth/logout';
export const API_auth_password_reset = (): string => '/api/v1/auth/passwords/reset';
export const API_auth_password_reset_id = (resetId: string): string =>
  `/api/v1/auth/passwords/reset/${resetId}`;
export const API_auth_update_password = (resetId: string, acceptedNewsletter: string): string =>
  `/api/v1/auth/passwords/reset/${resetId}?newsletter=${acceptedNewsletter}`;
export const API_auth_password_setup = (inviteId: string): string =>
  `/api/v1/auth/passwords/setup/${inviteId}`;
export const API_auth_signup = (): string => '/api/v2/auth/signup?source=web';
export const API_auth_verify = (): string => '/api/v1/auth/verify';
