import flatpickr from 'flatpickr';

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { TClassList } from '@core/helpers';
import { Instance } from 'flatpickr/dist/types/instance';
import { getTimeUTC } from 'src/app/core/helpers/date/date';
import { DEFAULT_DATE_FORMAT } from 'src/app/project/modules/preferences/default-date-format';
import { PreferencesService } from 'src/app/project/modules/preferences/preferences-service/preferences.service';
import { TPreferences } from 'src/app/project/modules/preferences/preferences.model';
import { EDateFormat } from 'src/app/project/shared/enums/date-format.enum';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnChanges, OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @ViewChild('datepickerElement', { static: false }) inputElement: ElementRef;

  @Input() ppDate: number | string;
  @Input() ppShowClearButton: boolean;
  /**
   * Emits only after date range selecting is complete.
   */
  @Input() ppDateMultiple: string[] = null;
  @Input() ppMinDate: number | string;
  @Input() ppMaxDate: number | string;
  @Input() ppDisabled = false;
  @Input() ppError = false;
  @Input() ppFilterDatepicker = false;
  @Input() ppHighlight = false;
  @Input() ppPlaceholder = 'Select a date...';
  @Input() ppMultipleDatesInput = false;
  @Input() ppUpdating: boolean;
  @Output() ppSelect = new EventEmitter<Date[]>();
  @Output() ppSetDatepicker = new EventEmitter<Instance>();
  @Output() ppClear = new EventEmitter<void>();

  class: TClassList;
  preferences$: Observable<TPreferences>;
  format = DEFAULT_DATE_FORMAT;

  EIconPath = EIconPath;

  datepicker: Instance;
  private numberOfDatesToEmit: 1 | 2 = 1;

  constructor(private preferencesService: PreferencesService) {
    this.setDateFormat();
  }

  ngOnInit() {
    this.datepicker = null;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setElementClass();

    if (!this.datepicker) {
      return;
    }

    this.setMinMaxDate();

    if (this.ppDateMultiple) {
      this.setDatepickerMultiple(changes);
      this.numberOfDatesToEmit = 2;

      return;
    }

    this.setDatepicker(changes);
  }

  onRendered(): void {
    this.createDatepicker();
  }

  createDatepicker(): void {
    this.clearExistingDatepicker();

    const display = this.generateDisplayFormat();

    this.datepicker = flatpickr(this.inputElement.nativeElement, {
      disableMobile: true,
      clickOpens: false,
      dateFormat: display,
      mode: this.ppMultipleDatesInput ? 'range' : 'single',
      weekNumbers: true,

      onChange: (selectedDates) => {
        if (selectedDates.length === this.numberOfDatesToEmit) {
          this.ppSelect.emit(selectedDates);
        }
      },
    });

    this.ppSetDatepicker.emit(this.datepicker);

    this.setMinMaxDate();

    if (this.ppDateMultiple) {
      this.setupMultipleDateDatepicker();
    } else if (this.ppDate) {
      this.setupSingleDateDatepicker();
    } else {
      this.datepicker.clear();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();

    if (this.datepicker) {
      this.datepicker.destroy();
    }
  }

  clearField(): void {
    this.datepicker.clear();
    this.ppSelect.emit(null);

    if (this.datepicker.isOpen) {
      this.datepicker.close();
    }
  }

  onClick(event: Event): void {
    event.preventDefault();
    event.stopImmediatePropagation();

    if (!this.ppDisabled && this.datepicker) {
      this.datepicker.toggle();
    }
  }

  private setDatepicker(changes: SimpleChanges): void {
    const dateCleared =
      changes.ppDate &&
      (changes.ppDate.currentValue === undefined ||
        isNaN(changes.ppDate.currentValue) ||
        changes.ppDate.currentValue === null);

    const dateFilled = changes.ppDate && changes.ppDate.currentValue;

    if (dateCleared) {
      this.datepicker.clear();
    }

    if (dateFilled) {
      if (this.ppMultipleDatesInput) {
        this.datepicker.clear();
      } else {
        const date = getTimeUTC(new Date(+changes.ppDate.currentValue));

        this.datepicker.setDate(new Date(+date));
      }

      this.inputElement.nativeElement.blur();
    }
  }

  private setDatepickerMultiple(changes: SimpleChanges): void {
    const date: string[] = this.ppDateMultiple;

    if (changes && this.datepicker) {
      if (!date || date === null) {
        this.datepicker.clear();
      }
    }

    if (!+changes.ppDateMultiple.currentValue[0] || !+changes.ppDateMultiple.currentValue[1]) {
      this.datepicker.clear();
    } else {
      const startValueUTC = getTimeUTC(new Date(+changes.ppDateMultiple.currentValue[0]));
      const endValueUTC = getTimeUTC(new Date(+changes.ppDateMultiple.currentValue[1]));

      this.datepicker.setDate([new Date(+startValueUTC), new Date(+endValueUTC)]);
    }
  }

  private setMinMaxDate(): void {
    if (this.ppMinDate) {
      this.datepicker.set('minDate', new Date(+this.ppMinDate));
    } else {
      this.datepicker.set('minDate', null);
    }

    if (this.ppMaxDate) {
      this.datepicker.set('maxDate', new Date(+this.ppMaxDate));
    } else {
      this.datepicker.set('maxDate', null);
    }
  }

  private setDateFormat(): void {
    const preferences = this.preferencesService.getPreferences();

    this.format = preferences?.dateFormat ? preferences.dateFormat : DEFAULT_DATE_FORMAT;
  }

  private setElementClass(): void {
    this.class = {
      'datepicker--filters': this.ppFilterDatepicker,
    };
  }

  private setupSingleDateDatepicker(): void {
    const date = getTimeUTC(new Date(+this.ppDate));

    this.datepicker.setDate(new Date(date));
  }

  private setupMultipleDateDatepicker(): void {
    if (!+this.ppDateMultiple[0] || !+this.ppDateMultiple[1]) {
      this.datepicker.clear();
    } else {
      const startValueUTC = getTimeUTC(new Date(+this.ppDateMultiple[0]));
      const endValueUTC = getTimeUTC(new Date(+this.ppDateMultiple[1]));

      this.datepicker.setDate([new Date(+startValueUTC), new Date(+endValueUTC)]);
    }
  }

  private clearExistingDatepicker(): void {
    if (this.datepicker) {
      this.datepicker.destroy();
      this.datepicker = null;
    }
  }

  private generateDisplayFormat(): string {
    switch (this.format) {
      case EDateFormat.ISO:
        return 'Y-m-d';
      case EDateFormat.US:
        return 'm/d/Y';
      case EDateFormat.FRIENDLY:
        return 'd M Y';
      case EDateFormat.EUROPEAN:
        return 'd/m/Y';
      default:
        return 'Y-m-d';
    }
  }
}
