import { ApiService } from '@core/http';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { ERowType } from 'src/app/project/shared/enums/row-type.enum';
import { GET_TABLE } from '../../table.ui.store';
import { createEmptyRowElement } from './create-empty-row-element';
import { createHeaderRowElement } from './create-header-row/create-header-row-element';
import { createPointRowElement } from './create-point-row-element';

export function createRowElement(
  index: number,
  apiService: ApiService,
  dropdownService: DropdownService,
): HTMLElement {
  const table = GET_TABLE();
  const item = table.items[index];

  if (!item) {
    return createEmptyRowElement(index, 0);
  } else if (item.type === ERowType.POINT) {
    return createPointRowElement(index, apiService);
  } else if (item.type === ERowType.EMPTY) {
    return createEmptyRowElement(index, 0);
  } else if (item.type === ERowType.HEADER) {
    return createHeaderRowElement(index, 1, apiService, dropdownService);
  } else if (item.type === ERowType.GROUP_1) {
    return createHeaderRowElement(index, 1, apiService, dropdownService);
  } else if (item.type === ERowType.GROUP_2) {
    return createHeaderRowElement(index, 2, apiService, dropdownService);
  } else if (item.type === ERowType.GROUP_3) {
    return createHeaderRowElement(index, 3, apiService, dropdownService);
  } else if (item.type === ERowType.EMPTY_ROW_GROUP_1) {
    return createEmptyRowElement(index, 1);
  } else if (item.type === ERowType.EMPTY_ROW_GROUP_2) {
    return createEmptyRowElement(index, 2);
  } else if (item.type === ERowType.EMPTY_ROW_GROUP_3) {
    return createEmptyRowElement(index, 3);
  }
}
