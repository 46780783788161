import { Base64 } from 'js-base64';
import { cloneDeep } from 'lodash';
import Quill from 'quill';
import 'quill-mention';

import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  RendererFactory2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, of } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { UpdatePointComment } from '../../points.actions';

import { TPreferences } from 'src/app/project/modules/preferences/preferences.model';
import { TUser } from 'src/app/project/modules/user/user.model';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { TPoint } from '../../points.model';

import { DeviceService } from 'src/app/core/services/device.service';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { PromptService } from '../../../../components/prompt/prompt.service';
import { PreferencesService } from '../../../preferences/preferences-service/preferences.service';
import { UserService } from '../../../user/user.service';
import { PointsUpdateService } from '../../points-update.service';
import { PointActivityService } from '../point-timeline/point-activity.service';

import { SortingService, TAnyFunction } from '@core/helpers';
import { ClickOutsideHandler } from '@core/services';
import { TAllUsers } from '@project/view-models';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { richTextFormats } from 'src/app/project/components/input/rich-text/rich-text-formats';
import { generateRenderItem } from 'src/app/project/components/input/rich-text/utils/generate-render-item';
import { generateSource } from 'src/app/project/components/input/rich-text/utils/generate-source';
import { setUserList } from 'src/app/project/components/input/rich-text/utils/set-user-list';
import { TNewComment } from 'src/app/project/data-providers/api-providers/comment-api-provider/comment-requests.model';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EAccessField } from 'src/app/project/shared/enums/access-field.enum';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { TRichTextUserList } from '../../../../components/input/rich-text/rich-text.model';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { WorkspaceService } from '../../../workspace/workspace.service';
import { PointsUsersService } from '../../points-users.service';

@Component({
  selector: 'pp-point-comment-input',
  templateUrl: './point-comment-input.component.html',
  styleUrls: ['./point-comment-input.component.scss'],
})
export class PointCommentInputComponent implements OnDestroy, OnChanges {
  private readonly destroy$ = new Subject<void>();

  @ViewChild('comment', { static: false }) commentElement: ElementRef;
  @ViewChild('commentBox', { static: false }) commentBox: ElementRef;

  @Input() ppWorkspaceId: string;
  @Input() ppPointId: string;

  user$: Observable<TUser>;
  preferences$: Observable<TPreferences>;
  private workspaces$: Observable<TWorkspacesById>;
  textLength = 0;
  characterLimit = 5000;

  preferences: TPreferences;
  user: TUser;

  processing: boolean;
  private savingPreferences: boolean;
  private quill: Quill;
  private timelineHeadingElement: HTMLElement;
  private userList: TRichTextUserList[] = [];
  private users: TAllUsers;
  public id = 'comment';
  changed = false;

  isEditingQuill: {
    editing: boolean;
    fieldId: string;
  } = {
    editing: false,
    fieldId: null,
  };

  isMobile = false;
  range = 0;
  EIconPath = EIconPath;

  private newComment: TNewComment;
  private clickOutsideHandler: ClickOutsideHandler;
  private renderer: Renderer2;

  constructor(
    private store: Store<{
      user: TUser;
      preferences: TPreferences;
      points: TPoint[];
      workspaces: TWorkspacesById;
    }>,
    private rendererFactory: RendererFactory2,
    private userService: UserService,
    private preferencesService: PreferencesService,
    private promptService: PromptService,
    private pointActivityService: PointActivityService,
    private pointsUpdateService: PointsUpdateService,
    private usersService: UsersService,
    private deviceService: DeviceService,
    private translationPipe: TranslationPipe,
    private sortingService: SortingService,
    private pointsUsersService: PointsUsersService,
    private workspaceService: WorkspaceService,
  ) {
    this.user$ = this.store.pipe(select(EStore.USER));
    this.preferences$ = this.store.pipe(select(EStore.PREFERENCES));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.isMobile = this.deviceService.isMobile();
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.processing = false;
    this.savingPreferences = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ppPointId) {
      this.updateUserList();
    }
  }

  onCommentBoxRendered(): void {
    this.newComment = {
      comment: '',
      commentRich: '',
    };

    this.preferencesService
      .fetchPreferences()
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          const preferences = this.preferencesService.getPreferences();

          if (preferences.commentOnEnter === undefined) {
            preferences.commentOnEnter = true;
          }

          this.initEditor();
        }),
      )
      .subscribe();

    this.preferences$.pipe(takeUntil(this.destroy$)).subscribe((preferences) => {
      this.preferences = cloneDeep(preferences);
    });

    this.users = this.usersService.getUsers();
    this.setUserList();

    this.timelineHeadingElement = this.pointActivityService.getPointTimelineHeading();

    this.clickOutsideHandler = new ClickOutsideHandler(
      this.commentBox.nativeElement,
      this.destroy$,
    );
    this.clickOutsideHandler.caught$.subscribe((event) => {
      this.onClickOutside(event);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private updateUserList(): void {
    this.pointsUsersService
      .fetchUsersWithAccessToPoint(this.ppPointId, EAccessField.COMMENT)
      .pipe(takeUntil(this.destroy$))
      .subscribe((userIds) => {
        this.setUserList(userIds);
      });
  }

  initEditor(): void {
    const modules = {
      mention: {
        allowedChars: /^[a-zA-Z0-9_.-@+]*$/,
        mentionDenotationChars: ['@'],
        source: (searchTerm: string, renderList: TAnyFunction): void => {
          generateSource(searchTerm, renderList, this.userList);
        },
        positioningStrategy: 'fixed',
        isolateCharacter: true,
        renderItem: (item, searchTerm) => generateRenderItem(item, this.users),
      },
      toolbar: '#commentToolbar',
      keyboard: {
        bindings: {
          smartbreak: {
            key: 13,
            handler: (range): void => {
              if (this.preferences.commentOnEnter) {
                this.saveComment();
              } else {
                this.quill.insertText(range.index, '\n');
              }
            },
          },
        },
      },
    };

    this.quill = new Quill(this.commentElement.nativeElement, {
      modules,
      theme: 'snow',
      placeholder: 'Write a comment...',
      formats: richTextFormats,
    });

    this.quill.on('selection-change', (range) => {
      if (range) {
        this.renderer.addClass(
          this.commentElement.nativeElement.parentNode,
          'commentBox--expanded',
        );

        this.addClickListener();
        this.isEditingQuill = { editing: true, fieldId: this.id };
      }
    });

    this.quill.on('text-change', () => {
      const richTextComponent = this.quill.getContents();
      let mergedText = '';

      this.range = this.quill?.clipboard?.quill?.selection?.savedRange?.index;

      richTextComponent.ops.forEach((textLine) => {
        if (typeof textLine.insert === 'string') {
          mergedText += textLine.insert;
        } else if (textLine.insert.mention) {
          mergedText += '@' + textLine.insert.mention.value;
        }
      });

      this.textLength = mergedText.length;
    });

    this.quill.on('selection-change', (change) => {
      if (change?.index) {
        this.range = change.index;
      }
    });
  }

  saveSendPreferences(): void {
    this.preferences.commentOnEnter = !this.preferences.commentOnEnter;
    if (this.quill) {
      if (typeof this.range !== 'number') {
        this.range = 0;
      }

      this.quill.setSelection(this.range, 0);
    }

    let activated = 'activated';

    if (this.preferences.commentOnEnter) {
      activated = 'deactivated';
    }

    logEventInGTAG(EGoogleEventSite.SITE__POINT__COMMENT_ON_ENTER, {
      event_category: EGoogleEventCategory.SITE,
      event_details: activated,
    });

    if (!this.savingPreferences) {
      this.savingPreferences = true;

      const body: TPreferences = {
        commentOnEnter: this.preferences.commentOnEnter,
      };

      this.preferencesService
        .updatePreferences(body)
        .pipe(
          finalize(() => {
            this.savingPreferences = false;
          }),
        )
        .subscribe();
    }
  }

  saveComment(): void {
    const richText = this.quill.getContents();
    const richTextBase64 = Base64.encode(JSON.stringify(richText));

    let mergedText = '';

    richText.ops.forEach((textLine) => {
      if (typeof textLine.insert === 'string') {
        mergedText += textLine.insert;
      } else if (textLine.insert.mention) {
        mergedText += '@' + textLine.insert.mention.value;
      }
    });

    if (this.quill) {
      if (mergedText.length > this.characterLimit) {
        const promptText = this.translationPipe.transform('prompt_text_over_limit', {
          characterLimit: this.characterLimit,
        });

        this.promptService.showWarning(promptText);

        return;
      }

      this.newComment.comment = mergedText.trim();
      this.newComment.commentRich = richTextBase64;
      this.newComment.mentions = [];

      richText.ops.forEach((textLine) => {
        if (textLine.insert && textLine.insert.mention) {
          this.newComment.mentions.push(textLine.insert.mention.id);
        }
      });
    }

    if (!this.newComment.comment?.trim()) {
      const promptText = this.translationPipe.transform('prompt_comment_empty_error');

      this.promptService.showWarning(promptText);

      return;
    }

    if (this.processing) {
      return;
    }

    this.processing = true;
    this.textLength = 0;

    logEventInGTAG(EGoogleEventSite.SITE__POINT__COMMENT, {
      event_category: EGoogleEventCategory.SITE,
    });

    this.store.dispatch(
      new UpdatePointComment({
        workspaceId: this.ppWorkspaceId,
        _id: this.ppPointId,
      }),
    );

    this.pointsUpdateService
      .addComment(this.ppPointId, this.newComment)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.newComment.comment = '';
          this.newComment.commentRich = '';

          if (this.quill) {
            this.quill.setContents();
          }

          this.pointActivityService.refreshTimeline(this.ppWorkspaceId, this.ppPointId, {
            refreshComments: true,
          });

          this.pointActivityService.scrollToTimelineHeading({ behavior: 'smooth', block: 'start' });
          this.removeClickListener();
          this.quill.blur();
        }),
        catchError((error) => {
          const promptText = this.translationPipe.transform('prompt_comment_saved_error');

          if (error.status === EStatusCode.GONE) {
            this.translationPipe.transform('prompt_point_removed');
          }

          this.promptService.showError(promptText);

          return of();
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  showMentionsDropdown(): void {
    this.quill.getModule('mention').openMenu(' @');
  }

  addClickListener = (): void => {
    this.clickOutsideHandler.enable();
    this.changed = true;
  };

  removeClickListener = (): void => {
    this.clickOutsideHandler.disable();

    if (this.quill.getText()?.trim() === '') {
      this.changed = false;

      this.renderer.removeClass(
        this.commentElement.nativeElement.parentNode,
        'commentBox--expanded',
      );

      this.isEditingQuill = { editing: false, fieldId: this.id };
    }
  };

  private onClickOutside(event: MouseEvent): void {
    event.preventDefault();
    event.stopImmediatePropagation();

    this.removeClickListener();

    if (this.quill) {
      this.quill.blur();
    }
  }

  private setUserList(userIds?: string[]): void {
    if (userIds === undefined) {
      const workspace = this.workspaceService.getWorkspace(this.ppWorkspaceId);
      this.userList = setUserList(this.users, workspace.users);
    } else {
      this.userList = setUserList(this.users, userIds);
    }
  }

  private getVerifiedUserList(userIds: string[]): TRichTextUserList[] {
    const verifiedUsers: TRichTextUserList[] = [];

    userIds.forEach((userId) => {
      if (this.users[userId] && this.users[userId].verified) {
        verifiedUsers.push({
          value: this.users[userId].userName,
          id: userId,
          email: this.users[userId].email,
          avatar: this.users[userId].userName,
        });
      }
    });

    verifiedUsers.sort((a, b) => this.sortingService.naturalSort(a.value, b.value));

    return verifiedUsers;
  }
}
