<div class="notification__buttons notification__buttons--mobile">
  <div
    class="notification__check notification__mark-as-read notification__button notification__button--read notification__enable-on-hover"
    ppTooltip
    [ppTitle]="'mark_as_unread' | ppTranslate"
    (click)="markAsUnread($event)"
    *ngIf="ppNotification.markedAsRead"
  >
    <img
      class="notification__mark-as-unread-button notification__mark-as-read-button"
      [src]="EIconPath.NOTIFICATION_CHECK"
    />
    <img
      class="notification__mark-as-unread-button--hover notification__mark-as-read-button"
      [src]="EIconPath.NOTIFICATION_CHECK_BLUE"
    />
  </div>

  <div
    class="notification__check notification__mark-as-read notification__button"
    ppTooltip
    [ppTitle]="'mark_as_read' | ppTranslate"
    (click)="markAsRead($event)"
    *ngIf="!ppNotification.markedAsRead"
  >
    <img
      class="notification__check--hidden notification__mark-as-read-button"
      [src]="EIconPath.NOTIFICATION_CHECK"
    />
  </div>

  <pp-notification-clear
    [ppActive]="ppNotification.markedAsRead"
    (ppCallback)="removeNotification($event)"
    [ppEnableOnHover]="false"
    [ppTooltipTitle]="'clear_notification'"
  ></pp-notification-clear>

  <div
    ppTooltip
    [ppTitle]="'respond' | ppTranslate"
    class="notification__button notification__respond"
    *ngIf="
      (canRespond || ppNotification.changeBody?.cfFieldType === ECustomFieldType.RICHTEXT) &&
      !ppRespondingToComment &&
      !ppCommentSent &&
      commentsEditable
    "
    (click)="respond($event)"
  >
    <img class="notification__respond-image--grey" [src]="EIconPath.NOTIFICATION_RESPOND" />
    <img class="notification__respond-image--blue" [src]="EIconPath.NOTIFICATION_RESPOND_BLUE" />
  </div>

  <div
    ppTooltip
    [ppTitle]="'comment_sent' | ppTranslate"
    class="notification__button notification__respond"
    *ngIf="ppCommentSent && !ppRespondingToComment"
  >
    <img [src]="EIconPath.NOTIFICATION_SENT_TICK" />
  </div>

  <div
    ppTooltip
    [ppTitle]="ppReaction?.like.includes(activeUser.userId) ? 'Unlike comment' : 'Like comment'"
    (click)="likeComment($event)"
    class="notification__button notification__respond"
    *ngIf="
      (ppNotification.pushNotificationType === ENotificationType.POINT_EDITION_COMMENT ||
        ppNotification.pushNotificationType === ENotificationType.POINT_MENTION_COMMENT) &&
      ppNotification.changeBody?.commentId
    "
  >
    <img
      *ngIf="ppReaction?.like.includes(activeUser.userId)"
      [src]="EIconPath.INTERACTION_LIKE_FILLED"
    />
    <img *ngIf="!ppReaction?.like.includes(activeUser.userId)" [src]="EIconPath.INTERACTION_LIKE" />
  </div>
</div>

<div class="notification__footer" *ngIf="ppNotification.refObject" (click)="openSite($event)">
  <div
    ppTooltip
    [ppTitle]="ppNotification.refObject.name"
    [ppTrimTooltip]="true"
    class="notification__point"
    (click)="openPoint()"
  >
    <ng-container
      *ngIf="ppNotification.pushNotificationType === ENotificationType.REACTION_COMMENT"
    >
      {{ ppNotification.reactionDetails.pointRef.sequenceNumber }} -
      {{ ppNotification.reactionDetails.pointRef.name }}
    </ng-container>

    <ng-container
      *ngIf="ppNotification.pushNotificationType !== ENotificationType.REACTION_COMMENT"
    >
      {{ ppNotification.refObject.sequenceNumber }} -
      {{ ppNotification.refObject.name }}
    </ng-container>
  </div>

  <span ppTooltip [ppTitle]="ppAccountName" [ppTrimTooltip]="true" class="notification__site">
    {{ ppAccountName }}
  </span>

  <span class="notification__site">
    <img class="notification__footer-arrow" [src]="EIconPath.ARROW_RIGHT_BLANK" />
  </span>

  <span
    ppTooltip
    [ppTitle]="ppNotification.siteName"
    [ppTrimTooltip]="true"
    class="notification__site"
  >
    {{ ppNotification.siteName }}
  </span>

  <div class="notification__buttons notification__buttons--desktop">
    <div
      class="notification__check notification__mark-as-read notification__button notification__enable-on-hover"
      ppTooltip
      [ppTitle]="'mark_as_read' | ppTranslate"
      (click)="markAsRead($event)"
      *ngIf="!ppNotification.markedAsRead"
    >
      <img
        class="notification__check--hidden notification__mark-as-read-button"
        [src]="EIconPath.NOTIFICATION_CHECK"
      />
    </div>

    <div
      class="notification__check notification__mark-as-read notification__button notification__button--read notification__enable-on-hover"
      ppTooltip
      [ppTitle]="'mark_as_unread' | ppTranslate"
      (click)="markAsUnread($event)"
      *ngIf="ppNotification.markedAsRead"
    >
      <img
        class="notification__mark-as-unread-button notification__mark-as-read-button"
        [src]="EIconPath.NOTIFICATION_CHECK"
      />
      <img
        class="notification__mark-as-unread-button--hover notification__mark-as-read-button"
        [src]="EIconPath.NOTIFICATION_CHECK_BLUE"
      />
    </div>

    <pp-notification-clear
      [ppActive]="ppNotification.markedAsRead"
      (ppCallback)="removeNotification($event)"
      [ppEnableOnHover]="true"
      [ppTooltipTitle]="'clear_notification'"
    ></pp-notification-clear>
  </div>
</div>
