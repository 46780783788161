import { cloneDeep } from 'lodash';
import { TAsset } from 'src/app/project/modules/fleet-management/asset-service/asset.consts';

export class AssetModelFactory {
  static createFromDTO(response: TAsset): TAsset {
    const dto = cloneDeep(response);

    if (!dto.dashlets) {
      dto.dashlets = [];
    }

    dto.dashlets.forEach((dashlet) => {
      if (!dashlet.selectedRange.accountId) {
        dashlet.selectedRange.accountId = null;
      }

      if (!dashlet.selectedRange.workspaceIds) {
        dashlet.selectedRange.workspaceIds = [];
      }
    });

    return dto;
  }
}
