import { Component } from '@angular/core';

@Component({
  selector: 'pp-account-subscription-placeholder',
  templateUrl: './account-subscription-placeholder.component.html',
  styleUrls: ['./account-subscription-placeholder.component.scss']
})
export class AccountSubscriptionPlaceholderComponent {

}
