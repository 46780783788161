<section class="siteSettingsIntegrationsCustomFields">
  <div class="integrationFieldsDescription">
    <header class="integrationFieldsDescription__header">
      {{ 'integrations_voly_custom_fields_header' | ppTranslate }}
    </header>

    <p class="integrationFieldsDescription__subheader">
      {{ 'integrations_voly_custom_fields_subheader' | ppTranslate }}
    </p>
  </div>

  <div class="integrationFields">
    <div class="integrationFieldContainer" *ngFor="let field of integrationFields; let last = last">
      <pp-site-settings-integrations-custom-field-row
        [ppField]="field"
        [ppDisabled]="disabled"
        [ppWorkspaceId]="ppWorkspaceId"
      ></pp-site-settings-integrations-custom-field-row>

      <hr *ngIf="!last" class="integrationFieldSpacer" />
    </div>
  </div>
</section>
