import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from '../../../../../../shared/enums/icons.enum';
import { TColumn } from '../../../../site-table/columns/column.model';
import { ESortOrder } from '../../../../site-table/sort-order-enum';

@Component({
  selector: 'pp-new-group-or-sort-column',
  templateUrl: './new-group-or-sort-column.component.html',
  styleUrls: ['./new-group-or-sort-column.component.scss'],
})
export class NewGroupOrSortColumnComponent {
  EIconPath = EIconPath;
  emptyGroupOrder: ESortOrder;

  @Input() ppOrder: number;
  @Input() ppType: 'group' | 'sort';

  @Output() ppAddGroup = new EventEmitter<TColumn>();
  @Output() ppCancel = new EventEmitter<void>();

  updateEmptyGroupOrder(order: ESortOrder): void {
    this.emptyGroupOrder = order;
  }

  updateEmptyGroupColumn(column: TColumn): void {
    this.ppAddGroup.emit({
      ...column,
      groupOrder: this.emptyGroupOrder || ESortOrder.ASC,
    });
  }

  cancel(): void {
    this.ppCancel.emit();
  }
}
