import { GET_TABLE } from '../table.ui.store';

import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { createElement } from 'src/app/core/helpers/dom';
import { checkForKeyword } from 'src/app/core/pipes/highlight-keyword/utils/search-for-keyword';
import {checkElement} from '@core/helpers';

export function createMarkedKeywordElement(text: string): HTMLElement {
  const markedKeywordElement = createElement('span', {
    attrs: {
      class: ['customSiteTable__text--inner'],
    },
  });

  const table = GET_TABLE();
  const keyword: string = table.keyword;

  markKeyword(text, markedKeywordElement, keyword);

  return markedKeywordElement;
}

export function markKeyword(text: string, element: Element, keyword: string): void {
  const splitText = checkForKeyword(text, keyword);

  splitText.forEach((textFragment) => {
    const elementClass = textFragment.highlight
      ? 'text--highlighted customSiteTable__text'
      : 'customSiteTable__text';

    const textElement = createElement('span', {
      attrs: {
        class: elementClass,
      },
      children: [textFragment.text],
    });

    if (element instanceof HTMLElement) {
      element.appendChild(checkElement(textElement));
    } else {
      logErrorInSentry(new Error(`${element} is not an HTMLElement - Table Body`));
    }
  });
}
