import { Component } from '@angular/core';
import { DeviceService } from '@core/services';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import {
  TCustomField,
  TCustomFieldList,
} from 'src/app/project/modules/custom-fields/custom-fields.model';
import { TAdvancedFilterListValue } from '../../../../models/advanced-filter.model';
import { TAdvancedFilterListDropdownData } from './advanced-filter-list-input-dropdown.model';

@Component({
  selector: 'pp-advanced-filter-list-input-dropdown',
  templateUrl: './advanced-filter-list-input-dropdown.component.html',
  styleUrls: ['./advanced-filter-list-input-dropdown.component.scss'],
})
export class AdvancedFilterListInputDropdownComponent {
  dropdown: TDropdown;
  filterListItemsBy = '';

  isMobile = false;
  dropdownWidth: string = '280px';

  item: TCustomField;
  selected: TAdvancedFilterListValue;
  field: TCustomField;

  constructor(private dropdownService: DropdownService, private deviceService: DeviceService) {}

  ngOnInit(): void {
    this.dropdown = this.dropdownService.getDropdown();
    this.isMobile = this.deviceService.isMobile();

    if (!this.dropdown.data) {
      throw new Error('Data for this dropdown is required');
    }

    if (!this.dropdown.callback) {
      throw new Error('Callback for this dropdown is required');
    }

    const dropdownData: TAdvancedFilterListDropdownData = this.dropdown.data;

    this.item = dropdownData.field;
    this.selected = dropdownData.selected;
    this.field = dropdownData.field;

    if (this.dropdown.data.width) {
      this.dropdownWidth = this.dropdown.data.width;
    }

    if (this.isMobile) {
      this.dropdownWidth = 'unset';
    }
  }

  select(item: TCustomFieldList): void {
    this.dropdown.callback(item);
    this.hideDropdown();
  }

  hideDropdown(): void {
    this.dropdownService.hideDropdown();
  }

  clearList(): void {
    this.dropdown.callback(null);
    this.hideDropdown();
  }

  setSearchBox(keyword: string): void {
    this.filterListItemsBy = keyword;
  }
}
