<td
  class="userManagement__cell userManagementTableSite__cell tagRestrictedCell"
  ppTooltip
  [ppTitle]="editable ? '' : disabledTooltipText"
  [class.userManagement__cell--disabled]="!editable"
>
  <pp-tag-select
    [ppSelectedTags]="ppShare.defectTags"
    [ppWorkspaceId]="ppShare.workspaceId"
    [ppOptions]="tagSelectOptions"
    [ppPlaceholder]="placeholderText"
    [ppNoneAccess]="ppShare.tagLimited && ppShare.defectTags.length === 0"
    (ppOnChange)="tryUpdateShare($event)"
    (ppOnClose)="tryUpdateShareOnClose($event)"
  ></pp-tag-select>
</td>
