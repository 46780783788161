import { authorizeXMLHttpRequest } from 'src/app/core/helpers/authorize-xml-http-request';
import { EFileType } from '../../shared/enums/file-type.enum';

export function createB64DataFromUrl(_url: string): Promise<string> {
  return new Promise((_resolve) => {
    let xhr = new XMLHttpRequest();

    xhr.onload = (): void => {
      const arrayBufferView = new Uint8Array(xhr.response);
      const blob = new Blob([arrayBufferView], { type: EFileType.PNG });
      const reader = new FileReader();

      reader.onloadend = function (): void {
        const base64data = reader.result['split'](',')[1];
        _resolve(base64data);
      };

      reader.readAsDataURL(blob);
    };

    xhr.responseType = 'arraybuffer';

    xhr.open('GET', _url, true);
    xhr = authorizeXMLHttpRequest(xhr);
    xhr.setRequestHeader('Cache-Control', 'no-cache');
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Access-Control-Allow-Methods', 'GET');
    xhr.send();
  });
}

export function createBlob(b64data: string, mimeType: string, sliceSize: number = 512): Blob {
  const byteCharacters = atob(b64data.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: mimeType });

  return blob;
}
