<!-- notranslate - https://github.com/quilljs/quill/issues/2286 -->

<div
  #richTextComponentContainer
  notranslate
  class="richTextComponent__container"
  (ppOnRendered)="onRichTextRendered()"
  [class.richTextComponent__container--border]="
    ppOptions.defaultBorder && (!activeQuill.editing || activeQuill.fieldId !== ppId) && ppCanEdit
  "
  [class.richTextComponent__container--defaultBorder]="
    !(ppOptions.defaultBorder && (!activeQuill.editing || activeQuill.fieldId !== ppId)) &&
    !ppOptions.comment
  "
  [class.richTextComponent__container--focused]="(focused || showingModal) && ppCanEdit"
  [class.richTextComponent__container--disabled]="!showDefaultStyle"
  [class.richTextComponent__container--failed]="error || ppOptions.characterLimit - textLength < 0"
  [class.richTextComponent__container--blue]="showingBlueBorder && ppCanEdit"
  [class.richTextComponent__container--field]="ppOptions.pointField"
  [class.richTextComponent__container--noBorder]="showDefaultStyle && !ppCanEdit"
  [class.richTextComponent__container--noPointerEvents]="
    ppOptions.processing && ppOptions.notification
  "
  [class.richText--comment]="ppOptions.comment"
  [class.richText--marginBottom]="ppOptions.showButtons && showDefaultStyle && editing"
  [class.richTextComponent__small]="
    ppOptions.small && (!activeQuill.editing || activeQuill.fieldId !== ppId)
  "
  [class.richTextComponent__notification]="ppOptions.notification"
  [class.richTextComponent__container--bulk]="ppOptions.bulkChanges"
  [class.richText--clearable]="ppOptions.clearable"
  [class.richText--hideToolbars]="!editing"
>
  <app-spinner
    [ppInputTitle]="true"
    [ppHidden]="!ppOptions.processing || ppOptions.notification"
  ></app-spinner>

  <i
    class="fas fa-check richTextComponent__saved-check"
    aria-hidden="true"
    *ngIf="ppOptions.success && !ppOptions.clearable && !ppOptions.notification"
  ></i>

  <div #richTextComponent (click)="activateRichText($event)"></div>

  <pp-save-indicator
    *ngIf="!ppOptions.processing && ppOptions.clearable && !ppOptions.notification"
    [ppFilled]="!!ppValue?.richText"
    [ppUpdating]="false"
    [ppShowClearBtn]="ppCanEdit"
    (ppAction)="clearField()"
    [ppFocused]="activeQuill.editing && activeQuill.fieldId === ppId"
  ></pp-save-indicator>

  <div
    #richTextComponentToolbar
    (mousedown)="startEditing('user')"
    class="richTextComponent__toolbar"
    [class.richTextComponent__toolbar--visible]="
      (ppCanEdit && !ppOptions.processing) || ppOptions.notification || showingModal
    "
  >
    <button class="ql-bold"></button>
    <button class="ql-italic"></button>
    <button class="ql-underline"></button>
    <button class="ql-strike"></button>
    <button class="ql-link"></button>
    <button class="ql-list" value="ordered"></button>
    <button class="ql-list" value="bullet"></button>
    <button (click)="showMentionsDropdown($event)">&#64;</button>

    <div
      class="richTextComponent__characterLimit"
      [class.richTextComponent__characterLimit--green]="ppOptions.characterLimit - textLength >= 0"
      [class.richTextComponent__characterLimit--red]="ppOptions.characterLimit - textLength < 0"
      *ngIf="ppOptions.characterLimit - textLength < 500"
    >
      {{ textLength }} / {{ ppOptions.characterLimit }}
    </div>
  </div>

  <div
    class="description__actions telegramSendButton"
    *ngIf="ppOptions.showButtons && ppCanEdit && editing"
  >
    <pp-button ppId="cancelDescriptionEditBtn" (ppAction)="cancel($event)" ppStyle="text">
      {{ 'cancel' | ppTranslate }}
    </pp-button>

    <pp-button
      ppStyle="primary"
      ppId="updateDescriptionBtn"
      (ppAction)="update(true)"
      [ppProcess]="ppOptions.processing"
    >
      {{ (ppOptions.notification ? 'comment' : 'update') | ppTranslate }}
    </pp-button>
  </div>

  <div
    class="richTextComponent__processing"
    *ngIf="ppOptions.notification && (ppOptions.processing || ppOptions.success)"
  >
    <div class="richText_spinner" *ngIf="!ppOptions.success">
      <app-spinner [ppNotification]="true"></app-spinner>
    </div>

    <div class="richTextComponent__processing__sent" *ngIf="ppOptions.success">
      <img
        class="richTextComponent__processing__sent--img"
        [src]="EIconPath.NOTIFICATION_SENT_TICK"
      />

      {{ 'comment_sent' | ppTranslate }}
    </div>
  </div>
</div>
