import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getCustomFieldIconSrc } from 'src/app/project/modules/custom-fields/custom-fields.data';
import {
  EIntegrationStatus,
  TCustomField,
} from 'src/app/project/modules/custom-fields/custom-fields.model';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { checkCustomFieldVolyTooltip } from 'src/app/project/modules/site/site-options/utils/check-custom-field-voly-tooltip';
import { EGoogleEventLabel } from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EUserRole } from '../../../share-utils/user-roles';
import { TCustomFieldAccessOption, TShare } from '../../../share.model';
import { EAccessParameter } from '../access-parameters';
import { ShareEditService } from '../share-edit.service';

@Component({
  selector: 'pp-share-edit-advanced-access',
  templateUrl: './share-edit-advanced-access.component.html',
  styleUrls: ['./share-edit-advanced-access.component.scss'],
})
export class ShareEditAdvancedAccessComponent {
  @Input() ppShare: TShare;
  @Input() ppWorkspaceId: string;
  @Output() ppChange = new EventEmitter<void>();
  @Output() ppShareChange = new EventEmitter<TShare>();

  EUserRole = EUserRole;
  EIconPath = EIconPath;
  EGoogleEventLabel = EGoogleEventLabel;
  accessParameters = EAccessParameter;
  EIntegrationStatus = EIntegrationStatus;
  customFields = this.customFieldsService.getCustomFields();

  constructor(
    private customFieldsService: CustomFieldsService,
    private shareEditService: ShareEditService,
  ) {}

  correctTagsAccessLogic(parameter: EAccessParameter): void {
    this.ppShare = this.shareEditService.correctTagsAccessLogic(
      parameter,
      this.ppShare,
      this.ppWorkspaceId,
    );

    this.ppShareChange.emit(this.ppShare);
    this.ppChange.emit();
  }

  correctProperty(
    property: EGoogleEventLabel,
    parameter?: EAccessParameter,
    field?: TCustomFieldAccessOption,
  ): void {
    this.ppShare = this.shareEditService.correctProperty({
      workspaceId: this.ppWorkspaceId,
      share: this.ppShare,
      property,
      parameter,
      field,
    });

    this.ppShareChange.emit(this.ppShare);
    this.ppChange.emit();
  }

  getCustomFieldIconSrc(customField: TCustomField): EIconPath {
    return getCustomFieldIconSrc(customField);
  }

  getCustomFieldIconTooltip(customField: TCustomField): string {
    return checkCustomFieldVolyTooltip(customField);
  }
}
