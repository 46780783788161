import { TFilters } from '../site-filter.model';

export function compareFilterUpdatedDate(firstFilter: TFilters, secondFilter: TFilters): boolean {
  const updated1 = firstFilter.date.updated;
  const updated2 = secondFilter.date.updated;

  if (!updated1.applied && !updated2.applied) {
    return true;
  }

  if (updated1.startDate !== updated2.startDate) {
    return false;
  }

  if (updated1.endDate !== updated2.endDate) {
    return false;
  }

  return true;
}
