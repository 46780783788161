<pp-modal [ppThin]="true" [ppDisableScroll]="true">
  <ng-container heading> {{ 'rename_attachment' | ppTranslate }} </ng-container>

  <ng-container body>
    <div
      class="renameFile__input textCF__input"
      [class.renameFile__input--error]="error || fileName.length > characterLimit"
      [class.renameFile__input--focused]="focused"
    >
      <input
        #input
        ppFocus
        class="renameFile__input--text"
        placeholder="Add an attachment name"
        [(ngModel)]="fileName"
        (blur)="onBlur()"
        (focus)="onFocus()"
        (keydown.enter)="renameFile()"
      />

      <pp-save-indicator
        [ppFilled]="fileName?.length > 0"
        [ppUpdating]="false"
        [ppShowClearBtn]="true"
        (ppAction)="clear()"
        [ppFocused]="focused"
      ></pp-save-indicator>
    </div>

    <div
      class="richTextComponent__characterLimit"
      [class.richTextComponent__characterLimit--red]="fileName.length > characterLimit"
      *ngIf="fileName && fileName.length > characterLimit * 0.9"
    >
      {{ fileName.length }} / {{ characterLimit }}
    </div>

    <p class="renameFile__error" *ngIf="error">
      {{ 'attachment_name_cannot_be_empty' | ppTranslate }}
    </p>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'save' | ppTranslate"
      [ppProcess]="processing"
      (ppCancel)="hideModal()"
      (ppConfirm)="confirm()"
      [ppDisabled]="fileName.length > characterLimit"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
