<div class="dashletActivity">
  <ng-container [ngSwitch]="type">
    <img
      *ngSwitchCase="customFieldTypes.LIST"
      class="dashletActivity__icon"
      [src]="EIconPath.CUSTOM_FIELD_LIST_ACTIVITY"
    />
    <img
      *ngSwitchCase="customFieldTypes.PERCENTAGE"
      class="dashletActivity__icon"
      [src]="EIconPath.CUSTOM_FIELD_PERCENTAGE_ACTIVITY"
    />
    <img
      *ngSwitchCase="customFieldTypes.NUMBERS"
      class="dashletActivity__icon"
      [src]="EIconPath.CUSTOM_FIELD_NUMBERS_ACTIVITY"
    />
    <img
      *ngSwitchCase="customFieldTypes.COST"
      class="dashletActivity__icon"
      [src]="EIconPath.CUSTOM_FIELD_COST_ACTIVITY"
    />
    <img
      *ngSwitchCase="customFieldTypes.TIME"
      class="dashletActivity__icon"
      [src]="EIconPath.CUSTOM_FIELD_TIME_ACTIVITY"
    />
    <img
      *ngSwitchCase="customFieldTypes.DATE"
      class="dashletActivity__icon"
      [src]="EIconPath.CUSTOM_FIELD_DATE_ACTIVITY"
    />
    <img
      *ngSwitchCase="customFieldTypes.CHECKBOX"
      class="dashletActivity__icon"
      [src]="EIconPath.CUSTOM_FIELD_CHECKBOX_ACTIVITY"
    />
    <img
      *ngSwitchCase="customFieldTypes.RICHTEXT"
      class="dashletActivity__icon"
      [src]="EIconPath.CUSTOM_FIELD_RICH_TEXT_ACTIVITY"
    />
    <img
      *ngSwitchCase="customFieldTypes.TEXT"
      class="dashletActivity__icon"
      [src]="EIconPath.CUSTOM_FIELD_TEXT_ACTIVITY"
    />
    <img
      *ngSwitchCase="customFieldTypes.TIMELINE"
      class="dashletActivity__icon"
      [src]="EIconPath.CUSTOM_FIELD_TIMELINE_ACTIVITY"
    />

    <img
      *ngSwitchCase="EIntegrationStatus.ON"
      class="dashletActivity__icon"
      [src]="EIconPath.ICON_INTEGRATION_VOLY_ACTIVE_SMALL"
    />

    <img
      *ngSwitchCase="EIntegrationStatus.DISABLED"
      class="dashletActivity__icon"
      [src]="EIconPath.ICON_INTEGRATION_VOLY_INACTIVE_SMALL"
    />
  </ng-container>

  <div class="dashletActivity__text">
    <ng-container *ngIf="!ppChange.oldValue">
      {{ 'changed' | ppTranslate }}
      <span class="dashletActivities__item-quoted timeline__strong">{{ ppChange.label }}</span>
      {{ 'to' | ppTranslate | lowercase }}
      <span class="dashletActivities__item-quoted">{{ ppChange.newValue }}.</span>
    </ng-container>

    <ng-container *ngIf="ppChange.oldValue" class="dashletActivity">
      {{ ppChange.label }} {{ 'changed' | ppTranslate }} {{ 'to' | ppTranslate | lowercase }}
      <span class="dashletActivities__item-quoted">{{ ppChange.newValue }}.</span>
    </ng-container>
  </div>
</div>
