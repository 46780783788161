<button
  class="bulkChangesBar__button"
  (click)="action()"
  [class.bulkChangesBar__button--disabled]="ppDisabled"
>
  <pp-icon
    [ppSrc]="ppIconPath"
    ppColor="inherit"
    ppClass="setSize24"
    [ppDisplayContents]="true"
  ></pp-icon>

  <span class="bulkChangesBar__button-label">{{ ppText }}</span>
</button>
