import { cloneDeep } from 'lodash';

import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { TShare } from '../share.model';

import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { Modal, ModalService } from '../../../components/modal/modal.service';

import { finalize, tap } from 'rxjs/operators';
import { ImportUsersModalService } from './import-users-modal.service';
import { TSelectedUsers } from './share-modal-import/share-import.models';
import { getImportUserList } from './share-modal-import/share-modal-import.functions';

@Component({
  selector: 'app-share-modal-import',
  templateUrl: './import-users-modal.component.html',
  styleUrls: ['./import-users-modal.component.scss'],
})
export class ImportUsersModalComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private readonly _closeShare$ = new Subject<void>();
  readonly closeShare$ = this._closeShare$.asObservable();

  modal: Modal = this.modalService.getModal();
  workspaceId: string;

  importedUser: string = null;
  processingShare = false;
  selectedUsers: TSelectedUsers = {};
  editedShares: { [userId: string]: TShare } = {};
  dropdown: TDropdown = this.dropdownService.getDropdown();
  dropdownVisible = false;
  tagError = false;

  workspaces: TWorkspacesById = null;
  workspace: TWorkspace = null;

  totalSelectedImportFields: {
    number: number;
  } = {
    number: 0,
  };

  share: TShare;

  constructor(
    private modalService: ModalService,
    private workspaceService: WorkspaceService,
    private dropdownService: DropdownService,
    private importUsersModalService: ImportUsersModalService,
  ) {}

  ngOnInit() {
    this.workspaceId = this.modal.data;
    this.workspaces = this.workspaceService.getWorkspaces();
    this.workspace = cloneDeep(this.workspaces[this.workspaceId]);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  importUsers(): void {
    if (this.importedUser && !this.processingShare) {
      this._closeShare$.next();

      return;
    }

    this.processingShare = true;

    const userList = getImportUserList(this.selectedUsers, this.editedShares);

    this.importUsersModalService
      .importShares(this.workspace, userList, this.workspaceId)
      .pipe(
        tap(() => {
          this.hideModal();
        }),
        finalize(() => {
          this.processingShare = false;
        }),
      )
      .subscribe();
  }

  hideDropdown(): void {
    this.dropdownVisible = false;
    this.dropdownService.hideDropdown();
  }

  onImportedUserChange(user: string): void {
    this.importedUser = user;
  }
}
