import { Injectable } from '@angular/core';

import { RequestService } from '../../../services/requests/request.service';
import { ResponseErrorService } from 'src/app/project/modules/errors/response-error.service';
import { FilesApiProviderService } from 'src/app/project/data-providers/api-providers/files-api-provider/files-api-provider.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ImageRotationService {
  constructor(
    private requestService: RequestService,
    private responseErrorService: ResponseErrorService,
    private filesApiProviderService: FilesApiProviderService,
  ) {}

  updateImageRotation(imageId: string, angle: number, _id: string): Promise<string> {
    const promise = this.filesApiProviderService
      .updateImageRotation(imageId, angle, _id)
      .pipe(catchError(this.responseErrorService.handleRequestError))
      .toPromise();

    this.requestService.addRequest({
      type: 'imageRotate',
      id: imageId,
      promise,
    });

    return promise;
  }
}
