import { Component, Input, OnInit } from '@angular/core';
import { WorkspaceService } from '../../../../../../workspace/workspace.service';

@Component({
  selector: 'pp-account-user-modal-shares-list-share-site',
  templateUrl: './account-user-modal-shares-list-share-site.component.html',
  styleUrls: ['./account-user-modal-shares-list-share-site.component.scss'],
})
export class AccountUserModalSharesListShareSiteComponent implements OnInit {
  @Input() ppWorkspaceId: string;
  @Input() ppHasError: boolean;

  siteName: string;

  constructor(private workspaceService: WorkspaceService) {}

  ngOnInit(): void {
    this.siteName = this.workspaceService.getWorkspace(this.ppWorkspaceId).siteName;
  }
}
