import { Subject } from 'rxjs';
import { TColumn } from './column.model';
import { GET_GROUPING } from './grouping.store';
import { GET_SORTING } from './sorting.store';

let columns: TColumn[] = [];
export const columnsChange$ = new Subject<void>();

// GET

export const GET_COLUMNS = (): TColumn[] => columns;

// SET & MODIFY

export const SET_COLUMNS = (_columns: TColumn[]): void => {
  columns = _columns;

  columnsChange$.next();
};

export const SET_COLUMN_WIDTH = (_columnIndex: number, _width: number): void => {
  if (columns[_columnIndex]) {
    columns[_columnIndex].width = _width;

    columnsChange$.next();
  }
};

export const SET_COLUMN_SORT_INDEX = (_columnIndex: number, _sortIndex?: number): void => {
  if (_sortIndex !== undefined) {
    columns[_columnIndex].sortIndex = _sortIndex;
  } else {
    delete columns[_columnIndex].sortIndex;
  }

  columnsChange$.next();
};

export const SET_COLUMN_SORT_ORDER = (_columnIndex: number, _sortOrder?: 'ASC' | 'DESC'): void => {
  if (_sortOrder !== undefined) {
    columns[_columnIndex].sortOrder = _sortOrder;
  } else {
    delete columns[_columnIndex].sortOrder;
  }

  columnsChange$.next();
};

export const CLEAR_COLUMN_GROUP_ORDER_AND_INDEX = (): void => {
  columns.forEach((_column) => {
    delete _column.groupIndex;
    delete _column.groupOrder;
  });

  columnsChange$.next();
};

export const CLEAR_COLUMN_SORT_ORDER_AND_INDEX = (): void => {
  columns.forEach((_column) => {
    delete _column.sortIndex;
    delete _column.sortOrder;
  });

  columnsChange$.next();
};

export const SET_COLUMN_GROUP_INDEX = (_columnIndex: number, _groupIndex?: number): void => {
  columns.forEach((_column) => {
    if (_column.groupIndex === _groupIndex) {
      _column.groupIndex += 1;
    }
  });

  if (_groupIndex !== undefined) {
    columns[_columnIndex].groupIndex = _groupIndex;
  } else {
    delete columns[_columnIndex].groupIndex;
  }

  columnsChange$.next();
};

export const REARRANGE_COLUMNS_GROUPS = (): void => {
  const groups = GET_GROUPING();

  columns.forEach((_column) => {
    _column.groupIndex = undefined;
    _column.groupOrder = undefined;
  });

  groups.forEach((group, _index) => {
    const column = columns.find((_column) => _column.name === group.name);

    column.groupIndex = _index;
    column.groupOrder = group.groupOrder || 'ASC';
  });

  columnsChange$.next();
};

export const REARRANGE_COLUMNS_SORTS = (): void => {
  const sorts = GET_SORTING();

  columns.forEach((_column) => {
    _column.sortIndex = undefined;
    _column.sortOrder = undefined;
  });

  sorts.forEach((sort, _index) => {
    const column = columns.find((_column) => _column.name === sort.name);

    column.sortIndex = _index;
    column.sortOrder = sort.sortOrder || 'ASC';
  });

  columnsChange$.next();
};

export const SET_COLUMN_GROUP_ORDER = (
  _columnIndex: number,
  _groupOrder?: 'ASC' | 'DESC',
): void => {
  if (_groupOrder !== undefined) {
    columns[_columnIndex].groupOrder = _groupOrder;
  } else {
    delete columns[_columnIndex].groupOrder;
  }

  columnsChange$.next();
};

export const HIDE_COLUMN = (_columnIndex: number): void => {
  columns[_columnIndex].hidden = true;

  columnsChange$.next();
};

export const SHOW_COLUMN = (_columnIndex: number): void => {
  columns[_columnIndex].hidden = false;

  columnsChange$.next();
};

export const ADD_COLUMN = (_column: TColumn): void => {
  columns.push(_column);

  columnsChange$.next();
};

export const DELETE_COLUMN = (_columnIndex: number): void => {
  columns.splice(_columnIndex, 1);

  columnsChange$.next();
};

export const MOVE_COLUMN = (_columnIndex: number, _destinationIndex: number): void => {
  const column = columns[_columnIndex];

  DELETE_COLUMN(_columnIndex);
  columns.splice(_destinationIndex, 0, column);
  REINDEX_COLUMNS();

  columnsChange$.next();
};

export const REINDEX_COLUMNS = (): void => {
  columns.forEach((_column, _index) => {
    _column.index = _index;
  });

  columnsChange$.next();
};

// CLEAR

export const CLEAR_COLUMNS = (): void => {
  columns.length = 0;

  columnsChange$.next();
};
