import { Component, OnInit, OnDestroy } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SiteDataService, TSiteData } from '../../site/site-data.service';
import { SidePanelService } from '../side-panel/side-panel.service';

import { TUISettings } from '../../ui/ui.model';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { TSidePanel } from '../side-panel/side-panel.model';

@Component({
  selector: 'pp-settings-container',
  templateUrl: './settings-container.component.html',
  styleUrls: ['./settings-container.component.scss'],
})
export class SettingsContainerComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  site: TSiteData = this.siteDataService.getSite();
  sidePanel: TSidePanel = this.sidePanelService.getSidePanel();
  ui$: Observable<TUISettings>;
  ui: TUISettings;

  constructor(
    private store: Store<{ ui: TUISettings }>,
    private siteDataService: SiteDataService,
    private sidePanelService: SidePanelService,
  ) {
    this.ui$ = this.store.pipe(select(EStore.UI));
  }

  ngOnInit(): void {
    this.sidePanelService.initSidePanel({ expandedInitially: false });

    this.ui$.pipe(takeUntil(this.destroy$)).subscribe((ui) => {
      this.ui = ui;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
