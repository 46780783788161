import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { tap } from 'rxjs/operators';
import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { PreferencesService } from 'src/app/project/modules/preferences/preferences-service/preferences.service';
import { TWorkspacePreferences } from 'src/app/project/modules/preferences/preferences.model';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { TSectionVisibilitySettings } from './export-body.model';
import { EExportFormat } from './utils/export-page-format-enum';

type TPreferencesRequest = {
  workspaceId?: string;
  exportType: string;
  sectionsVisibilitySettings: TSectionVisibilitySettings;
  format?: EExportFormat;
};

@Injectable({
  providedIn: 'root',
})
export class PointExportPreferencesService {
  constructor(
    private workspaceService: WorkspaceService,
    private accountService: AccountService,
    private preferencesService: PreferencesService,
  ) {}

  updatePreferences({
    workspaceId,
    exportType,
    sectionsVisibilitySettings,
    format = null,
  }: TPreferencesRequest): void {
    const workspaces = this.workspaceService.getWorkspaces();
    const account = this.accountService.getAccount(workspaces[workspaceId].accountId);

    this.preferencesService
      .fetchWorkspacePreferences(workspaceId, account.accountFeatures)
      .pipe(
        tap((response) => {
          const preferences: TWorkspacePreferences = cloneDeep(response);

          preferences.exports[exportType] = sectionsVisibilitySettings;

          if (format) {
            preferences.exports[exportType].pageFormat = format;
          }

          this.preferencesService.updateExportPreferences(preferences, workspaceId);
        }),
      )
      .subscribe();
  }

  updateOverviewPreferences({
    exportType,
    sectionsVisibilitySettings,
    format = null,
  }: TPreferencesRequest): void {
    const preferences = this.preferencesService.getPreferences();

    preferences.overviewExports[exportType] = sectionsVisibilitySettings;

    if (format) {
      preferences.overviewExports[exportType].pageFormat = format;
    }

    this.preferencesService.updatePreferences(preferences).subscribe();
  }
}
