<pp-settings-container>
  <div class="settingsUser__username-title">
    <h2 class="settingsUser__username-title--inner">
      {{ user.userName }}
    </h2>
  </div>

  <div class="settingsGrid settingsContent__container settingsContent__container--profile">
    <pp-user-settings-avatar [ppUser]="user"></pp-user-settings-avatar>
    <pp-user-settings-profile></pp-user-settings-profile>
    <pp-user-settings-preferences></pp-user-settings-preferences>
    <pp-user-settings-type></pp-user-settings-type>
  </div>

  <pp-user-settings-sites class="settingsContent__container--profile"></pp-user-settings-sites>
</pp-settings-container>
