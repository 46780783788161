import { buildWeeks } from './build-weeks';

export function getDayBottomElements(from: Date, to: Date): string[] {
  let elements = [];

  const weeks = buildWeeks(from, to);

  weeks.forEach((_week) => {
    const day = _week[0];

    const weekDays = [];

    while (day <= _week[1]) {
      weekDays.push(day.getDate().toString());

      day.setDate(day.getDate() + 1);
    }

    elements = [...elements, ...weekDays];
  });

  return elements;
}
