<pp-filter-field-reminders
  *ngIf="ppRemindersEnabled"
  [ppFilters]="ppFilters"
  (ppApply)="applyFilters()"
></pp-filter-field-reminders>

<pp-filter-field-sites
  [ppFilters]="ppFilters"
  [ppWorkspaces]="ppWorkspaces"
  (ppApply)="applyFilters()"
></pp-filter-field-sites>

<pp-filter-field-location
  *ngIf="ppSitePlanExists"
  [ppFilters]="ppFilters"
  (ppApply)="applyFilters()"
></pp-filter-field-location>

<pp-filter-field-priority
  [ppFilters]="ppFilters"
  (ppApply)="applyFilters()"
></pp-filter-field-priority>

<pp-filter-field-status [ppFilters]="ppFilters" (ppApply)="applyFilters()"></pp-filter-field-status>

<pp-filter-field-flag [ppFilters]="ppFilters" (ppApply)="applyFilters()"></pp-filter-field-flag>

<pp-filter-field-tags
  *ngIf="ppTagsVisible"
  [ppFilters]="ppFilters"
  (ppApply)="applyFilters()"
></pp-filter-field-tags>

<pp-filter-field-created-by
  *ngIf="ppIsDropdownVisible"
  [ppFilters]="ppFilters"
  (ppApply)="applyFilters()"
></pp-filter-field-created-by>

<pp-filter-field-assignees
  *ngIf="ppIsDropdownVisible"
  [ppFilters]="ppFilters"
  (ppApply)="applyFilters()"
></pp-filter-field-assignees>

<pp-filter-field-date-created
  *ngIf="ppIsDropdownVisible"
  [ppFilters]="ppFilters"
  (ppApply)="applyFilters()"
></pp-filter-field-date-created>

<pp-filter-field-date-updated
  *ngIf="ppIsDropdownVisible"
  [ppFilters]="ppFilters"
  (ppApply)="applyFilters()"
></pp-filter-field-date-updated>

<pp-filter-field-custom-fields
  [ppFilters]="ppFilters"
  (ppApply)="applyFilters()"
></pp-filter-field-custom-fields>
