import { SortingService } from '@core/helpers';
import { cloneDeep } from 'lodash';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { TUser } from 'src/app/project/modules/user/user.model';
import { GET_USER } from 'src/app/project/modules/user/user.store';
import {
  TAccountFolder,
  TAccountSidePanel,
  TAccountSimpleResponse,
  TWorkspaceSimpleResponse,
} from 'src/app/project/view-models/account-simple-response.model';
import { NEW_FOLDER_ID } from '../utils/account-list-constants';
import { GET_COLLAPSED_FOLDERS, GET_VISIBLE_EMPTY_FOLDERS } from '../utils/account-list.ui.store';
import { ESidePanelItem } from '../utils/side-panel-item.enum';

export function groupAccountListData(_data: TAccountSimpleResponse[]): TAccountSidePanel[] {
  const data = sortData(_data) as TAccountSidePanel[];

  data.forEach((_account) => {
    _account.workspaces = _account.workspaces.sort((_a, _b) =>
      new SortingService().naturalSort(_a.name, _b.name),
    );

    const workspaces = cloneDeep(_account.workspaces);
    const user = GET_USER();
    let adminUser = false;
    let draggable = false;

    workspaces.forEach((_workspace) => {
      if (
        _workspace.shareOption === EUserRole.SITE_ADMIN ||
        _workspace.shareOption === EUserRole.OWNER ||
        _workspace.shareOption === EUserRole.ACCOUNT_ADMIN
      ) {
        adminUser = true;
      }
    });

    _account.elements = [];

    if (!_account.accountFolders) {
      _account.accountFolders = [];
    }

    if (Array.isArray(_account.accountFolders)) {
      _account.accountFolders = sortData(_account.accountFolders);

      draggable = processAccountFolders(_account, workspaces, adminUser, user);
    }

    workspaces.forEach((_workspace) => {
      _account.elements.push({
        type: ESidePanelItem.WORKSPACE,
        folderId: null,
        workspace: _workspace,
        draggable: draggable && adminUser,
      });
    });
  });

  return data;
}

function sortData(
  data: TAccountSimpleResponse[] | TAccountFolder[],
): TAccountSimpleResponse[] | TAccountFolder[] {
  return data.sort((_a, _b) => {
    if (_a.name !== _b.name) {
      return new SortingService().naturalSort(_a.name, _b.name);
    }

    return new SortingService().naturalSort(_a.id, _b.id);
  });
}

function processAccountFolders(
  _account: TAccountSidePanel,
  workspaces: TWorkspaceSimpleResponse[],
  adminUser: boolean,
  user: TUser,
): boolean {
  let draggable = false;

  _account.accountFolders.forEach((_folder) => {
    const collapsed =
      GET_COLLAPSED_FOLDERS()[_account.id] &&
      GET_COLLAPSED_FOLDERS()[_account.id].indexOf(_folder.id) !== -1;
    const visibleEmptyFolders = GET_VISIBLE_EMPTY_FOLDERS();

    let visibleEmptyFolder = false;

    if (visibleEmptyFolders[_account.id]) {
      visibleEmptyFolder = visibleEmptyFolders[_account.id].indexOf(_folder.name) !== -1;
    }

    if (_folder.workspaceIds) {
      _folder.workspaceIds = _folder.workspaceIds.filter(
        (_workspaceId) =>
          workspaces.findIndex((_workspace) => _workspace.workspaceId === _workspaceId) !== -1,
      );
    }

    if (_folder.workspaceIds?.length > 0 || _folder.id === NEW_FOLDER_ID || visibleEmptyFolder) {
      draggable = true;

      addFolder(_account, _folder);
    }

    if (_folder.workspaceIds?.length > 0) {
      _folder = sortFolderWorkspaceIds(_folder, _account);

      addFoldersInWorkspace({
        _folder,
        workspaces,
        collapsed,
        _account,
        draggable: adminUser || user.isSuperUser,
      });
    } else if (!collapsed && _folder.id !== NEW_FOLDER_ID && visibleEmptyFolder) {
      addEmptyFolder(_account, _folder);
    }
  });

  return draggable;
}

function sortFolderWorkspaceIds(
  _folder: TAccountFolder,
  _account: TAccountSidePanel,
): TAccountFolder {
  _folder.workspaceIds.sort((_a, _b) => {
    const a = _account.workspaces.find((_workspace) => _workspace.workspaceId === _a);
    const b = _account.workspaces.find((_workspace) => _workspace.workspaceId === _b);

    const aName = a ? a.name : ''; // after updating folder backend returns all workspaces in folders, even if user can't access them. To be fixed on backend
    const bName = b ? b.name : '';

    return new SortingService().naturalSort(aName, bName);
  });

  return _folder;
}

function addFoldersInWorkspace({
  _folder,
  workspaces,
  collapsed,
  _account,
  draggable,
}: {
  _folder: TAccountFolder;
  workspaces: TWorkspaceSimpleResponse[];
  collapsed: boolean;
  _account: TAccountSidePanel;
  draggable: boolean;
}): void {
  _folder.workspaceIds.forEach((_workspaceId) => {
    const workspaceIndex = workspaces.findIndex(
      (_workspace) => _workspace.workspaceId === _workspaceId,
    );

    if (workspaces[workspaceIndex]) {
      // workspaces[workspaceIndex] because there is a bug on backend when someone adds workspace to 2 folders
      if (!collapsed) {
        _account.elements.push({
          type: ESidePanelItem.WORKSPACE,
          folderId: _folder.id,
          workspace: workspaces[workspaceIndex],
          draggable,
        });
      }

      workspaces.splice(workspaceIndex, 1);
    }
  });
}

function addEmptyFolder(_account: TAccountSidePanel, _folder: TAccountFolder): void {
  _account.elements.push({
    type: ESidePanelItem.FOLDER_EMPTY,
    folderId: _folder.id,
    accountId: _account.id,
  });
}

function addFolder(_account: TAccountSidePanel, _folder: TAccountFolder): void {
  _account.elements.push({
    type: ESidePanelItem.FOLDER,
    name: _folder.name,
    id: _folder.id,
    workspaces: _account.workspaces,
    accountId: _account.id,
    account: _account,
  });
}
