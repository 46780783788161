import { AfterViewInit, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject, catchError, debounceTime, finalize, of, tap } from 'rxjs';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TAsset } from '../asset-service/asset.consts';
import { AssetService } from '../asset-service/asset.service';
import { FleetManagementLandingPageService } from '../fleet-management-landing-page/fleet-management-landing-page.service';
import { FleetService } from '../fleet-service/fleet.service';
import { TFleet } from '../fleet.consts';

@Component({
  selector: 'pp-fleet-management-fleet-page',
  templateUrl: './fleet-management-fleet-page.component.html',
  styleUrls: ['./fleet-management-fleet-page.component.scss'],
})
export class FleetManagementFleetPageComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() ppFleetId: string;

  private readonly destroy$ = new Subject<void>();
  private keywordSearchDebounceTime = 500;

  assets: TAsset[] = [];
  EIconPath = EIconPath;
  dataFetched: boolean;
  keyword: string;

  constructor(
    private assetService: AssetService,
    private fleetService: FleetService,
    private fleetManagementLandingPageService: FleetManagementLandingPageService,
    private titleService: Title,
    private translationPipe: TranslationPipe,
  ) {
    this.assetService.assetsChange$
      .pipe(
        debounceTime(this.keywordSearchDebounceTime),
        tap((assets) => {
          const fleet = this.fleetService.getFleet(this.ppFleetId);

          this.assets = assets.sort((a, b) => {
            return fleet.assetIds.indexOf(a.id) - fleet.assetIds.indexOf(b.id);
          });

          this.setTitle(fleet);
        }),
      )
      .subscribe();

    this.assetService.keywordChange$
      .pipe(
        tap((keyword) => {
          this.keyword = keyword;
        }),
      )
      .subscribe();
  }

  ngOnChanges(): void {
    this.assetService
      .fetchAssetsForFleet(this.ppFleetId)
      .pipe(
        tap((accountAssets) => {
          this.assets = accountAssets;
        }),
        catchError((error) => {
          return of(error);
        }),
        finalize(() => {
          this.dataFetched = true;
        }),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.fleetManagementLandingPageService.resetFleetManagementScroll();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private setTitle(fleet: TFleet) {
    this.titleService.setTitle(
      this.translationPipe.transform('fleet_management') +
        ' - ' +
        fleet?.name +
        ' | Pinpoint Works',
    );
  }
}
