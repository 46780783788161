<div class="pointCF__field pointCF__field--expandable">
  <label class="pointCF__label pointCF__label-text">
    <div class="pointCF__label-image--container">
      <pp-icon [ppSrc]="EIconPath.ICON_FIELDS_TEXT_18" ppColor="inherit" ppClass="setSize18" />
    </div>

    <span
      ppTooltip
      class="pointCF__label-container"
      ppTitle="{{ ppFieldLabel }}"
      [ppTrimTooltip]="true"
    >
      {{ ppFieldLabel }}
    </span>
  </label>

  <div class="pointCF__content" #textCF>
    <div
      class="pointCF__input customFieldText"
      [class.pointCF__input--focus]="focused"
      [class.pointCF__input--disabled]="!ppCanEdit"
      [class.pointCF__input--error]="characterLimit - inputValue.length < 0"
    >
      <textarea
        rows="1"
        ppTooltip
        [ppTitle]="inputValue"
        [ppTrimTooltip]="true"
        autocomplete="off"
        [attr.disabled]="ppCanEdit ? null : true"
        [attr.readonly]="ppCanEdit ? null : true"
        class="customFieldText__input input custom-list-input"
        type="text"
        [class.customFieldText__input--disabled]="!ppCanEdit"
        name="label"
        [(ngModel)]="inputValue"
        (blur)="onBlur()"
        (focus)="onFocus()"
        (keydown)="onKeydown($event)"
        (change)="updateField()"
        [placeholder]="ppCanEdit ? ('enter_text' | ppTranslate) : '--'"
        (keyup)="onKeyUp($event)"
        (input)="onInput($event)"
        #input
      ></textarea>
    </div>

    <pp-save-indicator
      [ppFocused]="focused"
      [ppFilled]="inputValue?.length > 0"
      [ppUpdating]="updating"
      [ppShowClearBtn]="ppCanEdit"
      (ppAction)="clearCustomField()"
    ></pp-save-indicator>

    <div
      class="customFieldText__characterLimit"
      [class.customFieldText__characterLimit--green]="characterLimit - inputValue.length >= 0"
      [class.customFieldText__characterLimit--red]="characterLimit - inputValue.length < 0"
      *ngIf="inputValue && characterLimit - inputValue.length < 500 && (focused || error)"
    >
      {{ inputValue.length }} / {{ characterLimit }}
    </div>
  </div>
</div>
