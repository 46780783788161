<dd class="settingsSection__form-item">
  <div class="userSettings2FA">
    <span class="userSettings2FA__text">{{ '2fa' | ppTranslate }}</span>

    <pp-button
      ppId="changePasswordBtn"
      data-m-target="Change password button"
      ppStyle="primary"
      ppSize="small"
      (ppAction)="buttonCallback()"
    >
      {{ buttonText }}
    </pp-button>
  </div>
</dd>
