import { Component, Input, OnInit } from '@angular/core';
import { PointsHelperService } from 'src/app/project/modules/points/points-helper.service';
import { EPriority } from 'src/app/project/modules/points/priorities';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { TPushNotification } from '../../notification.model';
import { getNotificationReasonText } from '../../utils/notification-reason';

@Component({
  selector: 'pp-notification-priority',
  templateUrl: './notification-priority.component.html',
  styleUrls: ['../notification.component.scss'],
})
export class NotificationPriorityComponent implements OnInit {
  @Input() ppNotification: TPushNotification<EPriority>;

  oldPriorityText: string;
  oldPriorityColor: string;
  newPriorityText: string;
  newPriorityColor: string;
  notificationReason: string;
  EIconPath = EIconPath;

  constructor(private pointsHelperService: PointsHelperService) {}

  ngOnInit(): void {
    this.oldPriorityText = this.humanizePriorityName(this.ppNotification.changeBody.oldValue);
    this.oldPriorityColor = this.priorityToColor(this.ppNotification.changeBody.oldValue);
    this.newPriorityText = this.humanizePriorityName(this.ppNotification.changeBody.newValue);
    this.newPriorityColor = this.priorityToColor(this.ppNotification.changeBody.newValue);
    this.notificationReason = getNotificationReasonText(this.ppNotification.notificationReason);
  }

  private humanizePriorityName(priority: EPriority): string {
    return this.pointsHelperService.humanizePriorityName(priority);
  }

  private priorityToColor(priority: EPriority): string {
    return this.pointsHelperService.priorityToColor(priority);
  }
}
