<div
  #selectComponent
  [id]="buttonId"
  class="select"
  [class.select--disabled]="ppDisabled"
  [class.select--inputFocused]="focused"
  (click)="toggleDropdown()"
>
  <div #selectContent class="select__content">
    <ng-content select="[selected]"></ng-content>
  </div>

  <div class="select__caret" *ngIf="!ppDisabled">
    <div class="select__icon-container">
      <pp-icon
        [ppSrc]="
          dropdown.visible && dropdown.buttonId === buttonId
            ? EIconPath.ICON_MISC_UP_ARROW_18
            : EIconPath.ICON_MISC_DOWN_ARROW_18
        "
        ppColor="grey-400"
        ppClass="setSize18"
      ></pp-icon>
    </div>
  </div>
</div>
