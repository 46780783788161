<section class="siteSettingsIntegrationsTooltips">
  <pp-site-settings-integrations-tooltips-tooltip
    [ppText]="
      (ppIntegrationStep === EAuthorizationProgressStep.ACTIVE
        ? 'connection_authorized'
        : 'authorize_connection'
      ) | ppTranslate
    "
    [ppAssetName]="ppSiteName"
    [ppTooltip]="'integrations_pinpoint_tooltip' | ppTranslate"
  ></pp-site-settings-integrations-tooltips-tooltip>

  <pp-button
    [ppTooltipTitle]="ppDisabled ? ('cancel_authorization_not_allowed_tooltip' | ppTranslate) : ''"
    *ngIf="ppIntegrationStep === EAuthorizationProgressStep.PENDING"
    ppStyle="secondary"
    (ppAction)="cancelConnection()"
    [ppDisabled]="ppDisabled"
  >
    {{ 'cancel_authorization' | ppTranslate }}
  </pp-button>

  <pp-button
    *ngIf="ppIntegrationStep === EAuthorizationProgressStep.ACTIVE"
    ppStyle="secondary"
    (ppAction)="deactivateConnection()"
    [ppDisabled]="ppDisabled"
    [ppTooltipTitle]="
      ppDisabled ? ('disconnect_authorization_not_allowed_tooltip' | ppTranslate) : ''
    "
  >
    {{ 'disconnect_from_voly' | ppTranslate }}
  </pp-button>

  <pp-site-settings-integrations-tooltips-tooltip
    [ppText]="'authorization_on_voly' | ppTranslate"
    [ppAssetName]="ppAssetName"
    [ppTooltip]="'integrations_voly_tooltip' | ppTranslate"
  ></pp-site-settings-integrations-tooltips-tooltip>
</section>
