import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TWO_FACTOR_AUTHENTICATION_CODE_LENGTH } from '../two-factor-authentication.consts';

@Component({
  selector: 'pp-two-factor-authentication-code-page',
  templateUrl: './two-factor-authentication-code-page.component.html',
  styleUrls: ['./two-factor-authentication-code-page.component.scss'],
})
export class TwoFactorAuthenticationCodePageComponent {
  @Input() ppProcessing: boolean;
  @Input() ppPhoneNumber: string;
  @Input() ppErrorMessage: string;

  @Output() ppConfirm2FA = new EventEmitter<string>();
  @Output() ppGoBack = new EventEmitter<void>();
  @Output() ppResend = new EventEmitter<void>();

  code: string = '';
  EIconPath = EIconPath;
  codeLength = TWO_FACTOR_AUTHENTICATION_CODE_LENGTH;

  confirm2FA(): void {
    this.ppConfirm2FA.emit(this.code);
  }

  resend(): void {
    this.ppResend.emit();
  }

  setCode(code: string): void {
    this.code = code;
  }

  goBack(): void {
    this.ppGoBack.emit();
  }
}
