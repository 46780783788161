import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  TSubscription,
  TSubscriptionRequest,
} from 'src/app/project/data-providers/api-providers/subscription-api-provider/subscription-requests.model';
import { SubscriptionsService } from '../subscriptions.service';
import { ESubscriptionType } from '../user-settings/subscription-types.enum';

@Injectable({
  providedIn: 'root',
})
export class EditNotificationModalService {
  constructor(private subscriptionsService: SubscriptionsService) {}

  createSubscription(
    subscriptionType: ESubscriptionType,
    workspaceId: string,
    userRef: string,
  ): Observable<TSubscription> {
    const subscription: TSubscriptionRequest = {
      subscriptionType: subscriptionType,
      workspaceId: workspaceId,
      userRef: userRef,
    };

    return this.subscriptionsService.addSubscription(subscription);
  }

  updateSubscription(
    subscriptionId: string,
    subscriptionType: ESubscriptionType,
  ): Observable<TSubscription> {
    const subscription: TSubscriptionRequest = {
      subscriptionType: subscriptionType,
    };

    return this.subscriptionsService.updateSubscription(subscriptionId, subscription);
  }

  deleteSubscription(subscriptionId: string): Observable<null> {
    return this.subscriptionsService.deleteSubscription(subscriptionId);
  }
}
