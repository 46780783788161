import { getEmptyGroupName } from './empty-group-name';

export function transformDefaultValue(_value: string): string {
  const emptyGroupValue = getEmptyGroupName();
  let value = _value;

  if (!_value) {
    value = emptyGroupValue;
  }
  return value;
}
