import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClearFilterService } from '../../clear-filter-service/clear-filter.service';
import { TFilters } from '../../site-filter.model';

@Component({
  selector: 'pp-filter-field-assignees',
  templateUrl: './filter-field-assignees.component.html',
  styleUrls: ['../../site-filter-dropdown/site-filter-dropdown.component.scss'],
})
export class FilterFieldAssigneesComponent {
  @Input() ppFilters: TFilters;
  @Output() ppApply = new EventEmitter();

  constructor(private clearFilterService: ClearFilterService) {}

  clearAssignees(): void {
    this.ppFilters.assignees = this.clearFilterService.clearAssignees();

    this.applyFilters();
  }

  applyFilters(): void {
    this.ppApply.emit();
  }
}
