import { GET_TABLE } from '../../../table.ui.store';
import { createElement } from 'src/app/core/helpers/dom';
import { translate } from 'src/app/project/features/translate/translate';
import { createFooterCellElement } from './create-footer--cell-element';
import { getSelectElementWidthPx } from '../../table/utils/select-element-width';
import { GET_GROUPING } from '../../../columns/grouping.store';

export function createFooterTotalPointsElement(): HTMLElement {
  const table = GET_TABLE();
  const grouping = GET_GROUPING();

  const totalPointsText: string =
    translate('showing') +
    ' ' +
    table?.points.length +
    ' ' +
    translate(table.points.length === 1 ? 'point_lowercase' : 'points_lowercase');

  const totalPointsElement = createElement<HTMLElement>('span', {
    attrs: {
      class: 'table__footer__totalPoints',
      id: 'sortingElement',
    },
    children: [totalPointsText],
  });

  if (table.tableFooter) {
    table.tableFooter.totalPointsElement = totalPointsElement;
  }

  const className = 'table__select table__footer__select';

  return createFooterCellElement({
    className,
    center: true,
    width: getSelectElementWidthPx(grouping.length),
    child: totalPointsElement,
  });
}
