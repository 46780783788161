import {
  API_preferences_sites_workspaces,
  API_preferences_users_overview,
  API_preferences_users_workspaces,
} from 'src/app/project/data-providers/api-providers/preferences-api-provider/preferences-paths';
import { TGuid } from '@core/helpers';
import { EWorkspaces } from 'src/app/project/modules/workspace/workspaces.enum';

export function getWorkspacePreferencesRequestURL(
  workspaceId: string,
  isGlobalPreferences: boolean,
  userId: TGuid,
): string {
  if (isGlobalPreferences) {
    return API_preferences_sites_workspaces(workspaceId);
  }
  if (workspaceId === EWorkspaces.OVERVIEW) {
    return API_preferences_users_overview(userId);
  }

  return API_preferences_users_workspaces(workspaceId);
}
