import dayjs from 'dayjs';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { TCustomFieldFilter } from '../site-filter.model';

export function filterDateCustomField(
  filterField: TCustomFieldFilter,
  pointCustomField: TPointCustomField,
): boolean {
  if (filterField.appliedValues.startDate || filterField.appliedValues.endDate) {
    if (filterField.appliedValues.startDate && filterField.appliedValues.endDate) {
      const endOfDay = dayjs(filterField.appliedValues.endDate).endOf('day');

      if (
        pointCustomField.value >= filterField.appliedValues.startDate &&
        pointCustomField.value <= endOfDay
      ) {
        return true;
      }
    } else if (filterField.appliedValues.startDate && !filterField.appliedValues.endDate) {
      if (parseInt(pointCustomField.value, 10) >= filterField.appliedValues.startDate) {
        return true;
      }
    } else if (!filterField.appliedValues.startDate && filterField.appliedValues.endDate) {
      const endOfDay = dayjs(filterField.appliedValues.endDate).endOf('day');

      if (pointCustomField.value !== null && pointCustomField.value <= endOfDay) {
        return true;
      }
    }
  } else {
    return true;
  }

  return false;
}
