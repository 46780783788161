import { cloneDeep } from 'lodash';

import { Component, Inject, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, of } from 'rxjs';
import { catchError, concatMap, delay, takeUntil, tap } from 'rxjs/operators';

import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { PromptService } from '../../../components/prompt/prompt.service';
import { WhiteLabelService } from '../white-label.service';

import { DOCUMENT } from '@angular/common';
import { TAccountResponse } from '@project/view-models';
import {
  ConfirmModalComponent,
  TConfirmModalParams,
} from 'src/app/project/components/confirm-modal/confirm-modal.component';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { EStore } from '../../../shared/enums/store.enum';
import { UpdateAccountLogo } from '../../account/account.actions';
import { TAccount } from '../../account/account.model';
import { EPdfLogoTarget } from '../pdfLogo-targets-enum';
import { TWhiteLabelFontOption, WHITE_LABEL_FONTS } from '../white-label-fonts';
import { setWhiteLabelStyles } from '../white-label-utils/set-white-label-styles';
import { TWhiteLabel, TWhiteLabelStore } from '../white-label.model';

@Component({
  selector: 'pp-white-label-edit',
  templateUrl: './white-label-edit.component.html',
  styleUrls: ['./white-label-edit.component.scss'],
})
export class WhiteLabelEditComponent implements OnDestroy {
  websiteUrl: string = null;
  themeWrapper = this.document.querySelector('body');

  footerLogoData: string = null;
  webLogoData: string = null;
  topRightLogoData: string = null;

  whiteLabel$: Observable<TWhiteLabelStore>;
  whiteLabel: TWhiteLabelStore;
  whiteLabelBackup: TWhiteLabelStore;
  private accounts$: Observable<TAccount[]>;
  clearWhiteLabelSubject: Subject<void> = new Subject<void>();

  accountId: string;
  account: TAccount;
  selectedFontIndex = 0;
  fonts = WHITE_LABEL_FONTS;
  private requestDelay = 1000;

  newWebLogo: File[];
  newFooterLogo: File[];
  newTopRightLogo: File[];

  processing: boolean;
  loaded = false;

  private readonly destroy$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<{
      whiteLabel: TWhiteLabelStore;
      accounts: TAccount[];
    }>,
    private accountService: AccountService,
    private whiteLabelService: WhiteLabelService,
    private promptService: PromptService,
    private translationPipe: TranslationPipe,
    private activeService: ActiveService,
    private modalService: ModalService,
  ) {
    this.whiteLabel$ = this.store.pipe(select(EStore.WHITE_LABEL));
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));

    this.whiteLabel$.pipe(takeUntil(this.destroy$)).subscribe((whiteLabel) => {
      this.whiteLabel = cloneDeep(whiteLabel);

      if (this.whiteLabel?.data && Object.keys(this.whiteLabel.data).length > 0) {
        this.footerLogoData = this.whiteLabel.data.logoLight;
        this.webLogoData = this.whiteLabel.data.logoDark;

        this.loaded = true;

        this.selectedFontIndex = this.fonts.findIndex(
          (font) => font.value === this.whiteLabel.data.fontFamily,
        );

        this.selectedFontIndex = this.selectedFontIndex === -1 ? 0 : this.selectedFontIndex;
      }
    });

    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
      if (accounts.length > 0) {
        this.accountId = this.activeService.getActiveAccountId();
        this.account = cloneDeep(accounts.find((account) => account.accountId === this.accountId));

        if (this.account) {
          this.websiteUrl = this.account.websiteUrl;
          this.topRightLogoData = this.account.logoRef?.id;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  resetWebLogo(): void {
    this.webLogoData = this.whiteLabel.data.logoDark;
  }

  resetFooterLogo(): void {
    this.footerLogoData = this.whiteLabel.data.logoLight;
  }

  updateWebsite(): Observable<TAccountResponse> {
    return this.accountService
      .updateAccountWebsite(this.accountId, this.websiteUrl)
      .pipe(takeUntil(this.destroy$));
  }

  updateWebLogo(images: File[]): Observable<string> {
    return this.whiteLabelService.uploadLogo(this.accountId, images[0]).pipe(
      tap((response) => {
        this.webLogoData = response;
        this.newWebLogo = undefined;
      }),
    );
  }

  updateFooterLogo(images: File[]): Observable<string> {
    return this.whiteLabelService.uploadLogo(this.accountId, images[0]).pipe(
      tap((response) => {
        this.footerLogoData = response;
        this.newFooterLogo = undefined;
      }),
    );
  }

  uploadTopRightLogo(images: File[]): Observable<string> {
    return this.whiteLabelService
      .uploadPdfLogo(this.accountId, images[0], EPdfLogoTarget.ACCOUNT)
      .pipe(
        tap((response) => {
          this.store.dispatch(
            new UpdateAccountLogo({
              accountId: this.accountId,
              logoId: response,
            }),
          );

          this.newTopRightLogo = undefined;
        }),
      );
  }

  deleteTopRightLogo(): Observable<string> {
    return this.whiteLabelService.deletePdfLogo(this.accountId, EPdfLogoTarget.ACCOUNT).pipe(
      tap(() => {
        this.store.dispatch(
          new UpdateAccountLogo({
            accountId: this.accountId,
            logoId: null,
          }),
        );

        this.newTopRightLogo = undefined;
      }),
    );
  }

  updateWhiteLabel(clear: boolean = false): void {
    if (!this.accountId) {
      return;
    }

    this.processing = true;

    const observables: Observable<any>[] = [this.updateWebsite()];

    if (this.newWebLogo) {
      observables.push(this.updateWebLogo(this.newWebLogo));
    }

    if (this.newFooterLogo) {
      observables.push(this.updateFooterLogo(this.newFooterLogo));
    }

    if (this.newTopRightLogo) {
      observables.push(this.uploadTopRightLogo(this.newTopRightLogo));
    } else if (this.newTopRightLogo === null) {
      observables.push(this.deleteTopRightLogo());
    }

    // TODO API review, turn it into one request
    // Requests are sent in order with a delay between them to avoid conflicts on backend
    observables
      .reduce(
        (acc, request) =>
          acc.pipe(
            // Concatenate the request observables
            concatMap(() =>
              request.pipe(
                // Delay each request by given value
                delay(this.requestDelay),
              ),
            ),
          ),
        of(null),
      )
      .subscribe((response) => {
        this.processLogosUpdateResponse(clear);
      });
  }

  private processLogosUpdateResponse(clear: boolean): void {
    this.whiteLabel.data.logoDark = this.webLogoData;
    this.whiteLabel.data.logoLight = this.footerLogoData;
    this.whiteLabel.data.fontFamily = this.fonts[this.selectedFontIndex].value;

    const {
      promise,
      promptText,
      errorText,
    }: { promise: Observable<TWhiteLabel>; promptText: string; errorText: string } =
      this.getRequestData(clear);

    promise
      .pipe(
        tap(() => {
          this.processing = false;
          setWhiteLabelStyles(this.themeWrapper, this.whiteLabel);
          this.accountService.setWebsite(this.accountId, this.websiteUrl);

          this.promptService.showSuccess(promptText);
        }),
        catchError((error) => {
          console.error(error);

          this.processing = false;

          this.promptService.showError(errorText);
          throw error;
        }),
      )
      .subscribe();
  }

  private getRequestData(clear: boolean): {
    promise: Observable<TWhiteLabel>;
    promptText: string;
    errorText: string;
  } {
    let promptText: string;
    let promise: Observable<TWhiteLabel>;
    let errorText: string;

    if (clear) {
      promptText = this.translationPipe.transform('prompt_white_label_clear');
      errorText = this.translationPipe.transform('prompt_white_label_clear_error');
      promise = this.whiteLabelService.updateWhiteLabel(this.whiteLabel.data, this.accountId);
    } else if (this.whiteLabel.created) {
      promptText = this.translationPipe.transform('prompt_white_label_update');
      errorText = this.translationPipe.transform('prompt_white_label_update_error');
      promise = this.whiteLabelService.updateWhiteLabel(this.whiteLabel.data, this.accountId);
    } else {
      promptText = this.translationPipe.transform('prompt_white_label_created_success');
      errorText = this.translationPipe.transform('prompt_white_label_created_error');
      promise = this.whiteLabelService.createWhiteLabel(this.whiteLabel.data, this.accountId);
    }
    return { promise, promptText, errorText };
  }

  selectFont(format: TWhiteLabelFontOption): void {
    this.selectedFontIndex = this.fonts.indexOf(format);
  }

  setNewWebLogo(images: File[]): void {
    this.newWebLogo = images;
  }

  setNewFooterLogo(images: File[]): void {
    this.newFooterLogo = images;
  }

  setNewTopRightLogo(images: File[]): void {
    this.newTopRightLogo = images;
  }

  clearWhiteLabel(): void {
    if (!this.accountId) {
      return;
    }

    const modalData: TConfirmModalParams = {
      message: this.translationPipe.transform('white_label_clear_message'),
      heading: this.translationPipe.transform('white_label_clear_header'),
      redButton: false,
      confirmText: this.translationPipe.transform('clear_all'),
      boldText: true,
    };

    this.modalService.setData(modalData);

    this.modalService.showModal(ConfirmModalComponent, {
      blur: false,
      callback: () => {
        this.clearWhiteLabelCallback();
      },
    });
  }

  private clearWhiteLabelCallback(): void {
    this.newFooterLogo = null;
    this.newTopRightLogo = null;
    this.newWebLogo = null;
    this.topRightLogoData = null;

    this.selectedFontIndex = 0;
    this.whiteLabel.data.fontFamily = this.fonts[this.selectedFontIndex].value;

    this.whiteLabel.data.logoDark = '';
    this.whiteLabel.data.logoLight = '';
    this.whiteLabel.data.panelAccent = '';
    this.whiteLabel.data.panelBg = '';
    this.whiteLabel.data.panelFont = '';
    this.whiteLabel.data.panelFontHighlight = '';
    this.whiteLabel.data.panelText = '';
    this.whiteLabel.data.panelTextHover = '';
    this.whiteLabel.data.primary50 = '';
    this.whiteLabel.data.primary75 = '';
    this.whiteLabel.data.primary100 = '';
    this.whiteLabel.data.primary200 = '';
    this.whiteLabel.data.primary300 = '';
    this.whiteLabel.data.primary400 = '';
    this.whiteLabel.data.primary500 = '';
    this.whiteLabel.data.primary600 = '';
    this.whiteLabel.data.primary700 = '';
    this.whiteLabel.data.primary800 = '';
    this.whiteLabel.data.primary900 = '';

    this.footerLogoData = '';
    this.webLogoData = '';
    this.newTopRightLogo = null;
    this.websiteUrl = '';

    if (this.account.logoRef) {
      this.account.logoRef.id = '';
    }

    this.clearWhiteLabelSubject.next();

    this.updateWhiteLabel(true);
  }
}
