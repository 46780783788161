import { TFilters, TTagsFilter } from '../site-filter.model';

export function clearTags(filters: TFilters): TTagsFilter {
  filters.tags.value = [];
  filters.tags.appliedValue = [];

  filters.tags.combinedTags = false;

  return filters.tags;
}
