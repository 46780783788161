// Commented out entries are quill.js formats that we don't currently use, but are available
export const richTextFormats = [
  // 'background',
  'bold',
  // 'color',
  // 'font',
  // 'code',
  'italic',
  'link',
  // 'size',
  'strike',
  // 'script',
  'underline',
  // 'blockquote',
  // 'header',
  'indent',
  'list',
  // 'align',
  // 'direction',
  // 'code-block',
  // 'formula'
  // 'image'
  // 'video'
  'mention',
];
