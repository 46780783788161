import { Component, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { TPoint } from 'src/app/project/modules/points/points.model';

import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { ModalService } from '../../../../components/modal/modal.service';
import { PromptService } from '../../../../components/prompt/prompt.service';
import { ActiveService } from '../../../../services/active/active.service';
import { PointsUpdateService } from '../../points-update.service';
import { PointsService } from '../../points.service';

import { Subject, throwError } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EStatus } from '../../../../shared/enums/status.enum';
import { CustomTableService } from '../../../site/site-table/custom-table/custom-table.service';

type TStatus = {
  value: EStatus;
  label: string;
};

@Component({
  selector: 'pp-point-bulk-status-modal',
  templateUrl: './point-bulk-status-modal.component.html',
  styleUrls: ['./point-bulk-status-modal.component.scss'],
})
export class PointBulkStatusModalComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  workspace = this.workspaceService.findWorkspace(this.activeService.getActiveWorkspaceId());

  processing = false;
  selectedStatus: TStatus['value'] = null;

  statuses: TStatus[] = [
    {
      value: EStatus.OPEN,
      label: this.translationPipe.transform('open'),
    },
    {
      value: EStatus.IN_PROGRESS,
      label: this.translationPipe.transform('in_progress'),
    },
    {
      value: EStatus.TO_REVIEW,
      label: this.translationPipe.transform('to_review'),
    },
    {
      value: EStatus.ONHOLD,
      label: this.translationPipe.transform('on_hold'),
    },
    {
      value: EStatus.CLOSED,
      label: this.translationPipe.transform('completed'),
    },
    {
      value: EStatus.CANCELED,
      label: this.translationPipe.transform('canceled'),
    },
  ];

  constructor(
    private store: Store<{ points: TPoint[] }>,
    private workspaceService: WorkspaceService,
    private pointsService: PointsService,
    private promptService: PromptService,
    private modalService: ModalService,
    private activeService: ActiveService,
    private sitePointFilterService: SitePointFilterService,
    private pointsUpdateService: PointsUpdateService,
    private translationPipe: TranslationPipe,
    private customTableService: CustomTableService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  save(): void {
    this.processing = true;
    const selectedPoints = this.pointsService.getSelectedPoints();
    const pointIds = selectedPoints.map((point) => point._id);
    const numberOfSelectedPoints = pointIds.length;

    logEventInGTAG(EGoogleEventSite.SITE__BULK_CHANGES__EDIT, {
      event_category: EGoogleEventCategory.SITE,
      event_label: 'STATUS',
      amount: numberOfSelectedPoints,
    });

    this.pointsUpdateService
      .updatePointsStatus(pointIds, this.selectedStatus)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          const promptText = this.translationPipe.transform('prompt_points_update_success');
          this.promptService.showSuccess(promptText);
          this.sitePointFilterService.filterPoints({ _keepScrollPosition: true });
          this.hideModal();
        }),
        catchError((error) => {
          const promptText = this.translationPipe.transform('prompt_points_update_error');

          this.promptService.showError(promptText);

          return throwError(error);
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  toggleStatus(status: TStatus): void {
    this.selectedStatus = status.value;
  }
}
