import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

export function getInverseOfTranslation(viewPort: CdkVirtualScrollViewport): string {
  // https://stackoverflow.com/questions/64439244/cdk-virtual-scrolling-with-sticky-header-html-table-angular
  if (!viewPort) {
    return '-0px';
  }
  const offset = viewPort.getOffsetToRenderedContentStart();

  return `-${offset}px`;
}
