import AutoNumeric from 'autonumeric';

export type TAutoNumericOptions = AutoNumeric.Options & {
  watchExternalChanges?: boolean;
};

export function createAutonumeric(
  element: string | HTMLInputElement,
  options?: TAutoNumericOptions,
): AutoNumeric {
  const autonumericOptions: AutoNumeric.Options = options || {
    watchExternalChanges: true,
    modifyValueOnWheel: false,
  };

  return new AutoNumeric(element, null, autonumericOptions);
}
