<div class="reminderAlert__background" (click)="closePanel()" *ngIf="ui.reminder"></div>

<div class="reminderAlert" *ngIf="ui.reminder">
  <button class="reminderAlert__close" (click)="closePanel()">
    <img [src]="EIconPath.INTERACTION_CLOSE" />
  </button>
  <img class="reminderAlert__image" [src]="EIconPath.REMINDER_ALERT_ICON" />

  <p class="reminderAlert__text">
    {{ 'you_have' | ppTranslate }} {{ remindersNumber }}
    {{ (remindersNumber === 1 ? 'reminder' : 'reminders') | ppTranslate }}
    {{ 'due_today' | ppTranslate }}
  </p>

  <div class="reminderAlert__button">
    <pp-button (ppAction)="closePanel(true)" ppStyle="primary" ppId="closeReminderAlertButton">
      {{ 'go_to_reminders' | ppTranslate }}
    </pp-button>
  </div>
</div>
