import { decodeBase64Text } from 'src/app/project/components/input/rich-text/decode-base64-text';
import { generatePlainText } from 'src/app/project/components/input/rich-text/generate-plain-text';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { GET_CUSTOM_FIELDS } from 'src/app/project/modules/custom-fields/custom-fields.store';
import { TPoint } from 'src/app/project/modules/points/points.model';

export function updatePointCustomFields(
  pointIndex: number,
  fieldLabel: string,
  newValue: unknown,
  points: TPoint[],
): void {
  if (!newValue) {
    newValue = '';
  }

  if (pointIndex !== -1) {
    const customFieldId = Object.keys(points[pointIndex].customFieldsMap).find(
      (searchedCustomFieldId) => {
        const customField = GET_CUSTOM_FIELDS()[searchedCustomFieldId];
        return customField.label === fieldLabel;
      },
    );

    if (customFieldId) {
      const pointCustomField = points[pointIndex].customFieldsMap[customFieldId];
      const customField = GET_CUSTOM_FIELDS()[customFieldId];

      if (customField.type === ECustomFieldType.RICHTEXT) {
        pointCustomField.value = generatePlainText(decodeBase64Text(newValue.toString()));
      } else {
        pointCustomField.value = newValue.toString();
      }
    }
  }
}
