import { Component, Input, OnInit } from '@angular/core';
import { EIconPath } from '../../../../../../../shared/enums/icons.enum';
import { TActivityChange } from '../../activity-change.model';

@Component({
  selector: 'pp-dashlet-activity-images360',
  templateUrl: './dashlet-activity-images360.component.html',
  styleUrls: ['../../dashlet-activity.component.scss'],
})
export class DashletActivityImages360Component implements OnInit {
  @Input() ppChange: TActivityChange<string[]>;

  isAdd: boolean;
  numberOfChanges: number;
  EIconPath = EIconPath;

  constructor() {}

  ngOnInit(): void {
    this.isAdd = this.ppChange.newValue.length > this.ppChange.oldValue.length;

    if (this.isAdd) {
      this.numberOfChanges = this.ppChange.newValue.length - this.ppChange.oldValue.length;
    } else {
      this.numberOfChanges = this.ppChange.oldValue.length - this.ppChange.newValue.length;
    }
  }
}
