import { Injectable } from '@angular/core';
import { ELocalStorageItems } from '../shared/enums/local-storage-items.enum';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  saveItem(key: ELocalStorageItems, item: string): void {
    localStorage.setItem(key, item);
  }

  removeItem(key: ELocalStorageItems): void {
    localStorage.removeItem(key);
  }

  getItem<T = any>(key: ELocalStorageItems): T {
    return JSON.parse(localStorage.getItem(key));
  }

  getString(key: ELocalStorageItems): string {
    return JSON.parse(localStorage.getItem(key));
  }
}
