import { createElement } from 'src/app/core/helpers/dom';
import { ADD_COLLAPSED_GROUP } from '../../../site-table/table.ui.store';
import { GET_TIMELINE } from '../../timeline.ui.store';
import { createTimelineCellElement } from '../body-cells/create-timeline-cell-element';
import { TTimelineItem } from '../../timeline.model';
import { EIconPath } from '../../../../../shared/enums/icons.enum';

export function createCollapseElement(_item: TTimelineItem): HTMLElement {
  return createTimelineCellElement({
    className: 'timeline__arrow',
    width: '4rem',
    center: true,
    callback: () => {
      const timeline = GET_TIMELINE();

      ADD_COLLAPSED_GROUP(_item.id);
      timeline.sortTimeline();
    },
    child: createElement('img', {
      attrs: {
        class: '_groupTimeline__field__order__desc',
        src: EIconPath.ARROW_DOWN_GRAY,
      },
    }),
  });
}
