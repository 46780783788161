import { createElement } from 'src/app/core/helpers/dom';
import { ERowType } from 'src/app/project/shared/enums/row-type.enum';
import { GET_GROUPING } from '../../columns/grouping.store';
import Table from '../../custom-table/table/Table';
import { GET_TABLE } from '../../table.ui.store';

export function createEmptyRowElement(_index: number, groupLevel?: number): HTMLElement {
  const table = GET_TABLE();
  const groupingRowElements = handleGroupedTableEmptyRow(groupLevel, _index);

  const emptyElement = createElement('div', {
    attrs: {
      class: ((): string[] => getEmptyElementClassList(table, _index, groupLevel))(),
    },
    children: [
      createElement('div', {
        attrs: {
          style: {
            display: 'contents',
          },
        },
        children: groupingRowElements,
      }),
    ],
  });

  return emptyElement;
}

function handleGroupedTableEmptyRow(groupLevel: number, _index: number): HTMLElement[] {
  const grouping = GET_GROUPING();
  const table = GET_TABLE();
  const groupingRowElements: HTMLElement[] = [];

  if (grouping.length && groupLevel) {
    const nextItem = table.items[_index + 1];

    grouping.forEach((group, index) => {
      if (index > groupLevel) {
        return;
      }

      let lastElement = false;

      if (index === groupLevel) {
        lastElement = true;
      }

      const isLastRow =
        nextItem &&
        nextItem.type !== ERowType.POINT &&
        nextItem.type !== ERowType.HEADER &&
        nextItem.type !== ERowType.GROUP_1 &&
        nextItem.type !== ERowType.GROUP_2 &&
        nextItem.type !== ERowType.GROUP_3 &&
        lastElement;

      const element = generateEmptyRowStartExtraElement(isLastRow, index);
      const endElement = generateEmptyRowEndExtraElement(isLastRow, index);

      groupingRowElements.push(element);
      groupingRowElements.push(endElement);
    });
  }

  return groupingRowElements;
}

function generateEmptyRowStartExtraElement(isLastRow: boolean, index: number): HTMLElement {
  const children = getGroupedTableEmptyRowChildren(isLastRow, index);

  const element = createElement('div', {
    attrs: {
      class: ((): string[] => getElementsClassList(index, isLastRow, children))(),
    },
    children,
  });
  return element;
}

function generateEmptyRowEndExtraElement(isLastRow: boolean, index: number): HTMLElement {
  const children = getGroupedTableEmptyRowChildren(isLastRow, index, true);

  const element = createElement('div', {
    attrs: {
      class: ((): string[] => getElementsClassList(index, isLastRow, children, true))(),
    },
    children,
  });
  return element;
}

function getGroupedTableEmptyRowChildren(
  isLastRow: boolean,
  index: number,
  isEnd: boolean = false,
): HTMLElement[] {
  const children: HTMLElement[] = [];

  if (isLastRow) {
    const classNames: string[] = [];

    switch (index) {
      case 0:
        classNames.push(isEnd ? 'emptyRowGroup--1--last--end' : 'emptyRowGroup--1--last');
        break;
      case 1:
        classNames.push(isEnd ? 'emptyRowGroup--2--last--end' : 'emptyRowGroup--2--last');

        break;
      case 2:
        classNames.push(isEnd ? 'emptyRowGroup--3--last--end' : 'emptyRowGroup--3--last');
        break;
    }

    children.push(
      createElement('div', {
        attrs: {
          class: classNames.join(' '),
        },
      }),
    );
  }

  return children;
}

function getElementsClassList(
  index: number,
  isLastRow: boolean,
  children: any[],
  isEnd: boolean = false,
): string[] {
  const classList = ['table__row--grouping'];

  switch (index) {
    case 0:
      classList.push(isEnd ? 'emptyRowGroup--1--end' : 'emptyRowGroup--1');
      break;
    case 1:
      if (GET_GROUPING().length !== 3) {
        classList.push(isEnd ? 'emptyRowGroup--2--end' : 'emptyRowGroup--2');
      } else {
        classList.push(isEnd ? 'emptyRowGroup--2--end--thirdGroup' : 'emptyRowGroup--2');
      }

      break;
    case 2:
      if (GET_GROUPING().length !== 3) {
        classList.push(isEnd ? 'emptyRowGroup--3--end' : 'emptyRowGroup--3');
      } else {
        classList.push(isEnd ? 'emptyRowGroup--3--end--thirdGroup' : 'emptyRowGroup--3');
      }

      if (isLastRow) {
        classList.push('emptyRowGroup--3--noBorder');
      }
      break;
  }

  if (isLastRow) {
    switch (index) {
      case 0:
        classList.push('table__row__grouping--third-color');
        break;
      case 1:
        classList.push('table__row--grouping--first-color');

        break;
      case 2:
        classList.push('table__row__grouping--second-color');
        break;
    }
  }

  if (children.length) {
    classList.push('emptyRowGroup--noBorder');
  }

  return classList;
}

function getEmptyElementClassList(table: Table, _index: number, groupLevel: number): string[] {
  const classList = ['table__row table__row--empty'];
  const nextItem = table.items[_index + 1];

  const isLastRow =
    nextItem &&
    nextItem.type !== ERowType.POINT &&
    nextItem.type !== ERowType.HEADER &&
    nextItem.type !== ERowType.GROUP_1 &&
    nextItem.type !== ERowType.GROUP_2 &&
    nextItem.type !== ERowType.GROUP_3;
  if (groupLevel) {
    classList.push(`table__row--empty-${groupLevel}`);
  }

  if (isLastRow) {
    classList.push('table__row--empty--last');
  }

  return classList;
}
