import { EIconPath } from '../../../shared/enums/icons.enum';
import { EExportFormat } from '../utils/export-page-format-enum';
import { EExportTypeName } from '../utils/export-type-names-enum';

export type TExportOutputFormat = {
  label: string;
  value: EExportFormat;
};

export type TExportImageSizes = {
  label: string;
  value: EExportImageSize;
};

export enum EExportImageSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  LARGE_SCALED = 'largeScaled',
}
export type TExportType = {
  label: string;
  pngIcon: string;
  value: EExportTypeName;
  enable: boolean;
  outputFormat?: TExportOutputFormat[];
  extension: string;
};

const outputPrintSize = [
  { label: 'A4', value: EExportFormat.A4 },
  { label: 'Letter', value: EExportFormat.LETTER },
];

const outputPrintSize__sitePlan = [
  { label: 'A4', value: EExportFormat.A4 },
  { label: 'A3', value: EExportFormat.A3 },
  { label: 'Letter', value: EExportFormat.LETTER },
  { label: 'Legal', value: EExportFormat.LEGAL },
];

export const imageSizes = [
  { label: 'Small', value: EExportImageSize.SMALL },
  { label: 'Medium', value: EExportImageSize.MEDIUM },
  { label: 'Large', value: EExportImageSize.LARGE },
  { label: 'Large – Original Ratio', value: EExportImageSize.LARGE_SCALED },
];

const pdfIconSrc = EIconPath.EXPORT_PDF;
const csvIconSrc = EIconPath.EXPORT_CSV;
const zipIconSrc = EIconPath.EXPORT_ZIP;
const wordIconSrc = EIconPath.EXPORT_WORD;
const mapIconSrc = EIconPath.EXPORT_PLAN;

export const simpleList: TExportType = {
  label: 'Simple List',
  pngIcon: pdfIconSrc,
  value: EExportTypeName.SIMPLE_LIST,
  enable: true,
  outputFormat: outputPrintSize,
  extension: 'pdf',
};

export const detailedList: TExportType = {
  label: 'Detailed List',
  pngIcon: pdfIconSrc,
  value: EExportTypeName.DETAILED_LIST,
  enable: true,
  outputFormat: outputPrintSize,
  extension: 'pdf',
};

export const csvOutput: TExportType = {
  label: 'CSV Output',
  pngIcon: csvIconSrc,
  value: EExportTypeName.CSV,
  enable: true,
  extension: 'csv',
};

export const xlsxOutput: TExportType = {
  label: 'XLSX Output',
  pngIcon: csvIconSrc,
  value: EExportTypeName.XLSX,
  enable: true,
  extension: 'xlsx',
};

export const individualReports: TExportType = {
  label: 'Individual reports',
  pngIcon: zipIconSrc,
  value: EExportTypeName.ZIP,
  enable: true,
  extension: 'zip',
};

export const pdf: TExportType = {
  label: 'PDF',
  pngIcon: pdfIconSrc,
  value: EExportTypeName.PDF,
  enable: true,
  outputFormat: outputPrintSize,
  extension: 'pdf',
};

export const word: TExportType = {
  label: 'Word',
  pngIcon: wordIconSrc,
  value: EExportTypeName.WORD,
  enable: true,
  outputFormat: outputPrintSize,
  extension: '',
};

export const wordSimple: TExportType = {
  label: 'Simple Word',
  pngIcon: wordIconSrc,
  value: EExportTypeName.WORD_SIMPLE,
  enable: true,
  outputFormat: outputPrintSize,
  extension: '',
};

export const sitePlan: TExportType = {
  label: 'Site Plan',
  pngIcon: mapIconSrc,
  value: EExportTypeName.SITE_PLAN,
  enable: true,
  extension: 'pdf',
  outputFormat: outputPrintSize__sitePlan,
};

export const exportTypes = [simpleList, detailedList, csvOutput, individualReports, sitePlan];
