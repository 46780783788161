import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountSitesService {
  private siteSearchKeyword: string;
  private readonly _siteSearchKeywordChange = new Subject<string>();
  readonly siteSearchKeywordChange$ = this._siteSearchKeywordChange.asObservable();
  private readonly _showArchivedChange = new Subject<boolean>();
  readonly showArchivedChange$ = this._showArchivedChange.asObservable();

  private selectedSitesIds: string[] = [];
  private readonly _selectedSitesIdsChange$ = new BehaviorSubject<string[]>(this.selectedSitesIds);
  readonly selectedSitesIdsChange$ = this._selectedSitesIdsChange$.asObservable();

  setKeyword(keyword: string): void {
    this.siteSearchKeyword = keyword;

    this._siteSearchKeywordChange.next(keyword);
  }

  getKeyword(): string {
    return this.siteSearchKeyword;
  }

  toggleSiteSelected(shareId: string): void {
    if (this.selectedSitesIds.includes(shareId)) {
      this.selectedSitesIds = this.selectedSitesIds.filter(
        (selectedSitesIds) => selectedSitesIds !== shareId,
      );
    } else {
      this.selectedSitesIds.push(shareId);
    }

    this.emitSelectedSitesIdsChange();
  }

  clearSelectedSites(): void {
    this.selectedSitesIds = [];
    this.emitSelectedSitesIdsChange();
  }

  getSelectedSitesIds(): string[] {
    return this.selectedSitesIds;
  }

  toggleShowArchived(selected: boolean): void {
    this._showArchivedChange.next(selected);
  }

  private emitSelectedSitesIdsChange(): void {
    this._selectedSitesIdsChange$.next(this.selectedSitesIds);
  }
}
