<div class="gridTile" [class.gridTile__disabled]="ppDisabled" (click)="optionSelect()">
  <div class="gridTile__imageContainer">
    <pp-icon
      class="gridTile__image"
      ppClass="setSize24"
      [ppSrc]="ppImage"
      ppColor="primary-600"
    ></pp-icon>
  </div>

  <div class="gridTile__right">
    {{ ppText }}
  </div>
</div>
