import { createElement } from 'src/app/core/helpers/dom';
import { TColumn } from '../../../columns/column.model';
import { createTotalCustomFieldCellInnerElement } from './create-footer-custom-field-cell-inner-element';

export function createFooterCellInnerElement(child: HTMLElement, column: TColumn): HTMLElement {
  if (column) {
    return createTotalCustomFieldCellInnerElement(child, column);
  } else {
    const cellInnerElement = createElement<HTMLElement>('div', {
      attrs: {
        class: 'table__cell__inner table__head__cell',
      },
      children: [
        createElement<HTMLElement>('span', {
          children: [child],
        }),
      ],
    });

    return cellInnerElement;
  }
}
