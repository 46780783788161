import { Injectable } from '@angular/core';

import * as ExcelJS from 'exceljs';
import { DownloadService } from '../../services/download.service';
import { TPoint } from '../points/points.model';
import { GET_TIMELINE } from '../site/site-timeline/timeline.ui.store';
import { addPointRows } from './timeline/add-point-rows';
import { createWorksheet } from './timeline/create-worksheet';
import { getColumnHeaders } from './timeline/get-column-headers';
import { getMinMaxDate } from './timeline/get-min-max-date';
import { styleBasicFields } from './timeline/style-basic-fields';
import { stylePriorityColumn } from './timeline/style-priority-columns';
import { styleTimeline } from './timeline/style-timeline';
import { styleTimelineHeaders } from './timeline/style-timeline-headers';

@Injectable({
  providedIn: 'root',
})
export class TimelineExportService {
  constructor(private downloadService: DownloadService) {}

  async exportLocally(): Promise<void> {
    const workbook = createWorksheet();
    const worksheet = workbook.getWorksheet('Timeline Export');
    const points = this.generatePoints();
    const [minDate, maxDate] = getMinMaxDate(points);

    worksheet.state = 'visible';
    worksheet.columns = getColumnHeaders(minDate, maxDate);

    addPointRows(points, worksheet);
    stylePriorityColumn(worksheet);
    styleTimelineHeaders(worksheet);

    styleTimeline(worksheet);
    styleBasicFields(worksheet);
    this.saveExport(workbook);
  }

  generatePoints(): TPoint[] {
    const timeline = GET_TIMELINE();
    const items = timeline.items;

    const points = [];

    items.forEach((item) => {
      const timelinePoint = timeline.points[item.index];

      if (timelinePoint) {
        points.push(timelinePoint);
      }
    });

    return points;
  }

  saveExport(workbook: ExcelJS.Workbook): void {
    workbook.xlsx.writeBuffer().then((data) => {
      const exportData = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      this.downloadService.saveFile(exportData, 'Pinpoint Works Timeline Export');
    });
  }
}
