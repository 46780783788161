import Timeline from './Timeline';

import { createTimelineRowElement } from './timeline-body-row';

import { GET_TIMELINE_COLUMNS } from './timeframes/timeline-timeframes';
import { GET_PREFERENCES } from 'src/app/project/modules/preferences/preferences.store';

import Tooltip from 'src/app/project/features/tooltip/Tooltip';
import { createTimelineCellTypeElement } from './body-elements/body-cells/create-cell-type-element';
import VirtualScroller from 'src/app/project/modules/external/virtual-scroller/VirtualScroller';

let position = { left: 0, x: 0 };
let element = null;

export default class TimelineBody {
  private timeline: Timeline;
  virtualScroller: VirtualScroller = null;
  tooltips: Tooltip[] = [];

  constructor(_parent: Timeline) {
    this.timeline = _parent;
    let data = GET_TIMELINE_COLUMNS();

    this.virtualScroller = new VirtualScroller(this.timeline.element, this.timeline.items, {
      className: 'timeline__body',
      id: 'timeline__body',
      columnWidths: data.bottomElements.map((_column) => data.bottomWidth),
      rowHeight: 52,
      createElementCallback: (_index: number): HTMLElement => createTimelineRowElement(_index),
      createCellElementCallback: (_index: number, _columnIndex: number): HTMLElement => {
        data = GET_TIMELINE_COLUMNS();

        return createTimelineCellTypeElement(_columnIndex);
      },
      scrollLeftCallback: (_left: number): void => {
        this.timeline.timelineHead.scrollLeft(_left);
      },
      scrollbarTheme: 'os-theme-table',
      marginBottom: 10,
    });

    window.addEventListener('resize', () => this.resizeEventHandler());
    this.virtualScroller.element.addEventListener('mousedown', this.mouseDownHandler);
  }

  resizeEventHandler(): void {
    const preferences = GET_PREFERENCES();

    if (preferences.timeline?.group && window.innerWidth >= 901) {
      this.virtualScroller.updateExtraWidth(30);
    } else {
      this.virtualScroller.updateExtraWidth(0);
    }

    this.load();
  }

  update(): void {
    this.updateWidth();
  }

  clear(): void {
    this.virtualScroller.load([]);
    this.virtualScroller.updateWidth(0);
  }

  updateWidth(): void {
    this.virtualScroller.updateWidth(this.timeline.width);
  }

  load(_keepScrollPosition?: boolean): void {
    const data = GET_TIMELINE_COLUMNS();

    this.tooltips.forEach((tooltip) => {
      tooltip.removeTooltip();
    });

    this.tooltips = [];

    this.updateWidth();
    this.virtualScroller.updateColumnWidths(data.bottomElements.map((_column) => data.bottomWidth));
    this.virtualScroller.disableSmoothScrolling();
    this.virtualScroller.load(this.timeline.items, _keepScrollPosition);
    this.virtualScroller.enableSmoothScrolling();
  }

  updateColumns(): void {
    const data = GET_TIMELINE_COLUMNS();

    this.virtualScroller.updateColumnWidths(data.bottomElements.map((_column) => data.bottomWidth));
    this.virtualScroller.updateXvalues();
  }

  addTooltip(tooltip: Tooltip): void {
    this.tooltips.push(tooltip);
  }

  mouseDownHandler(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    element = document.getElementById('timeline__body');

    position = {
      // The current scroll
      left: element.scrollLeft,
      // Get the current mouse position
      x: event.clientX,
    };

    window.addEventListener('mousemove', mouseMoveHandler);
    window.addEventListener('mouseup', mouseUpHandler);
  }
}

const mouseUpHandler = (event: MouseEvent): void => {
  event.preventDefault();
  event.stopPropagation();

  window.removeEventListener('mousemove', mouseMoveHandler);
  window.removeEventListener('mouseup', mouseUpHandler);
};

const mouseMoveHandler = (event: MouseEvent): void => {
  // How far the mouse has been moved
  const dx = event.clientX - position.x;

  // Scroll the element
  element.scrollLeft = position.left - dx;
};
