import { Component, Input } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';

@Component({
  selector: 'pp-new-site-plan-button',
  templateUrl: './new-site-plan-button.component.html',
  styleUrls: ['./new-site-plan-button.component.scss'],
})
export class NewSitePlanButtonComponent {
  @Input() ppNoSitePlan: boolean;
  @Input() ppSitePlan: File;
  @Input() ppDisabled: boolean;

  @Output() ppUploadSitePlan = new EventEmitter<File[]>();

  uploadSitePlan(event: File[]): void {
    this.ppUploadSitePlan.emit(event);
  }
}
