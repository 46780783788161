import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Modal, ModalService } from '../../../components/modal/modal.service';
import { PromptService } from '../../../components/prompt/prompt.service';
import { WorkspaceService } from '../../workspace/workspace.service';
import { SiteDataService } from '../site-data.service';

import { TWorkspacesById } from '../../workspace/workspace.model';

import { Subject, of } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { AccountListService } from 'src/app/project/modules/layout/side-panel/account-list.service';

@Component({
  selector: 'app-edit-site-name-modal',
  templateUrl: './edit-site-name-modal.component.html',
  styleUrls: ['./edit-site-name-modal.component.scss'],
})
export class EditSiteNameModalComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @ViewChild('siteNameInput') siteNameInputElement: ElementRef;

  private modal: Modal = this.modalService.getModal();

  private localSite: {
    name?: string;
    id?: string;
  } = {};

  private workspaces: TWorkspacesById;
  private workspaceId: string;

  siteName = '';
  processing = false;

  constructor(
    private workspaceService: WorkspaceService,
    private siteDataService: SiteDataService,
    private promptService: PromptService,
    private modalService: ModalService,
    private translationPipe: TranslationPipe,
    private accountListService: AccountListService,
  ) {}

  ngOnInit() {
    this.workspaces = this.workspaceService.getWorkspaces();
    this.workspaceId = this.modal.data;
    this.localSite.name = this.workspaces[this.workspaceId].siteName;
    this.localSite.id = this.workspaces[this.workspaceId].workspaceId;
    this.siteName = this.workspaces[this.workspaceId].siteName;
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  save(): void {
    this.siteName = this.siteName.trim();
    this.siteNameInputElement.nativeElement.blur();

    if (this.siteName.length === 0) {
      const promptText = this.translationPipe.transform('prompt_empty_name');

      this.promptService.showWarning(promptText);
    } else if (this.siteName !== this.localSite.name) {
      this.localSite.name = this.siteName;
      this.processing = true;

      this.siteDataService
        .updateSiteName(this.localSite.id, this.localSite.name, false)
        .pipe(
          takeUntil(this.destroy$),
          tap(() => {
            const promptText = this.translationPipe.transform('prompt_site_name_change');

            this.workspaceService.updateWorkspaceName(this.workspaceId, this.siteName);

            this.siteName = this.localSite.name;

            this.accountListService.updateSiteName(this.localSite.id, this.localSite.name);
            this.promptService.showSuccess(promptText);
            this.hideModal(false);
          }),
          catchError((error) => {
            logErrorInSentry(error);

            if (error.status === EStatusCode.BAD_REQUEST) {
              const promptText = this.translationPipe.transform(
                'prompt_site_name_change_already_exists',
              );

              this.promptService.showWarning(promptText);
            } else {
              const promptText = this.translationPipe.transform('prompt_site_name_change_error');

              this.promptService.showError(promptText);
            }

            return of();
          }),
          finalize(() => {
            this.processing = false;
          }),
        )
        .subscribe();
    }
  }

  disabled(): boolean {
    if (this.siteName.length === 0 || this.siteName === this.localSite.name) {
      return true;
    }

    return false;
  }

  hideModal(cancel: boolean = true): void {
    this.modalService.hideModal(cancel);
  }
}
