import { cloneDeep } from 'lodash';
import { buildDateString } from './build-date-string';
import { getWeekNumber } from './get-week-number';
import { splitYearIntoWeeks } from './split-year-into-weeks';
import { TTimeframeElement } from './timeline-timeframes';

export function getDayTopElements(from: Date, to: Date): TTimeframeElement[] {
  const elements = [];
  const weeks = splitYearIntoWeeks(from, to);

  weeks.forEach((week) => {
    let width = 0;
    const day = cloneDeep(week[0]);

    const weekNumber = getWeekNumber(week);

    while (day <= week[1]) {
      width += 49;

      day.setDate(day.getDate() + 1);
    }

    const element = {
      name: `Week ${weekNumber}`,
      secondaryName: ` (${buildDateString({ date: week[0] })} - ${buildDateString({
        date: week[1],
      })}, ${week[1].getFullYear()})`,
      width,
    };

    elements.push(element);
  });

  return elements;
}
