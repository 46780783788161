<div class="formulaBuilder">
  <pp-formula-input
    [(ppFormula)]="ppFormula"
    (ppFormulaChange)="updateFormula($event)"
    [ppWorkspaceId]="ppWorkspaceId"
  ></pp-formula-input>
  <pp-formula-builder-operators
    (ppSelectOperator)="selectOperator($event)"
  ></pp-formula-builder-operators>
  <pp-formula-builder-custom-fields
    [ppFieldId]="ppFieldId"
    (ppSelectOperator)="selectOperator($event)"
    [ppWorkspaceId]="ppWorkspaceId"
    [ppFormula]="ppFormula"
  ></pp-formula-builder-custom-fields>
</div>
