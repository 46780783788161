export enum EDashletType {
  DEFECT_CREATE = 'DEFECT_CREATE',
  COMMENT_CREATE = 'COMMENT_CREATE',
  CLOSED = 'CLOSED',
  CURRENT_STATUS = 'CURRENT_STATUS',
  CURRENT_PRIORITY = 'CURRENT_PRIORITY',
  OVER_TIME_STATUS = 'OVER_TIME_STATUS',
  OVER_TIME_PRIORITY = 'OVER_TIME_PRIORITY',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
  ONHOLD = 'ONHOLD',
  IN_PROGRESS = 'IN_PROGRESS',
  OPEN = 'OPEN',
  TO_REVIEW = 'TO_REVIEW',
  CANCELED = 'CANCELED',
  DEFECT_UPDATE = 'DEFECT_UPDATE',
  DEFECT_DELETE = 'DEFECT_DELETE',
  ACTIVITIES = 'ACTIVITIES',
  CURRENT_STACKED_STATUS = 'CURRENT_STACKED_STATUS',
  COST_COMPARISON = 'COST_COMPARISON',
}
