export function checkAnyDashletSelected(simpleDashlets, advancedDashlets) {
  for (const dashlet of simpleDashlets) {
    if (dashlet.selected) {
      return true;
    }
  }

  for (const dashlet of advancedDashlets) {
    if (dashlet.selected) {
      return true;
    }
  }

  return false;
}
