import { Component, Input } from '@angular/core';
import { EIconPath } from '../../../../../../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-dashlet-activity-tags-list',
  templateUrl: './dashlet-activity-tags-list.component.html',
  styleUrls: ['../../../dashlet-activity.component.scss'],
})
export class DashletActivityTagsListComponent {
  @Input() readonly ppLabel: string;
  @Input() readonly ppTags: string[];

  EIconPath = EIconPath;
}
