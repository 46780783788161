import { cloneDeep } from 'lodash';
import { UpdatePointTitle } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';

export function updatePointTitle(
  oldState: TPointsByWorkspace,
  action: UpdatePointTitle,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);
  let pointIndex = null;

  if (state[action.payload.workspaceId]) {
    pointIndex = state[action.payload.workspaceId].entities.findIndex(
      (_point) => _point._id === action.payload._id,
    );
    const point = state[action.payload.workspaceId].entities[pointIndex];

    if (point) {
      point.header.updatedEpochMillis = new Date().getTime();
      point.title = action.payload.title;
      state[action.payload.workspaceId].entities.splice(pointIndex, 1, point);
    }
  }

  return state;
}
