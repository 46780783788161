import { createElement, TAnyFunction } from '@core/helpers';
import { TFlattenedAccount } from '../account-list/account-list-flatten-account';

import { GET_DRAGGED_SITE } from '../utils/account-list.ui.store';
import { translate } from 'src/app/project/features/translate/translate';

export default class FolderMessage {
  private data: TFlattenedAccount;

  element: HTMLElement;
  private nameElement: HTMLElement;
  private createSiteElement: HTMLElement;

  private dragoverCallback;

  constructor(
    _data: TFlattenedAccount,
    {
      dragoverCallback,
    }: {
      dragoverCallback: TAnyFunction;
    },
  ) {
    this.data = _data;
    this.dragoverCallback = dragoverCallback;

    this.element = this.create();
  }

  create(): HTMLElement {
    const nameBodyElement = createElement('div', {
      attrs: {
        class: ['mainNav__name', 'siteNav__site-name', 'createName__body'],
        'data-account_id': this.data.accountId,
        'data-folder_id': this.data.folderId,
      },
      children: [this.createNameElement()],
    });

    this.createSiteElement = createElement('div', {
      attrs: {
        class: 'siteNav__element-empty-folder',
        'data-folder_id_empty': this.data.folderId,
      },
      eventListeners: {
        dragover: (_event) => {
          const draggedSite = GET_DRAGGED_SITE();

          if (draggedSite) {
            _event.preventDefault();

            if (this.data.accountId === draggedSite.accountId) {
              this.dragoverCallback(this.data.folderId);
            } else {
              this.dragoverCallback(null);
            }
          } else {
            this.dragoverCallback(null);
          }
        },
      },
      children: [nameBodyElement],
    });

    return this.createSiteElement;
  }

  createNameElement(): HTMLElement {
    this.nameElement = createElement('span', {
      attrs: {
        class: [
          'siteNav__site-name-text',
          'filterPanel__tooltip',
          'siteNav__new-site-text',
          'emptyFolder-body',
          'folder__empty--opacity',
        ],
      },
      children: [translate('folder_empty')],
    });

    return this.nameElement;
  }
}
