<button
  #modalCancelButton
  ppTooltip
  id="modalCancelButton"
  (click)="action()"
  class="exportCancelButton"
>
  <span class="exportCancelButton__text">
    <ng-content></ng-content>
  </span>
</button>
