import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'pp-account-settings-placeholder-paragraph',
  templateUrl: './account-settings-placeholder-paragraph.component.html',
  styleUrls: ['./account-settings-placeholder-paragraph.component.scss'],
})
export class AccountSettingsPlaceholderParagraphComponent implements OnInit, OnChanges {
  @Input() ppNumberOfLines: number = 2;
  @Input() ppShowEndElement: boolean = true;

  linesArray: number[];

  ngOnInit(): void {
    this.fillLinesArray();
  }

  ngOnChanges(): void {
    this.fillLinesArray();
  }

  private fillLinesArray(): void {
    this.linesArray = new Array(this.ppNumberOfLines);
  }
}
