import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable, of, switchMap, tap } from 'rxjs';
import { getTimeZone } from 'src/app/core/helpers/get-timezone';
import { AssetApiProviderService } from 'src/app/project/data-providers/api-providers/asset-api-provider/asset-api-provider.service';
import { TExportAssetPayload } from 'src/app/project/data-providers/api-providers/asset-api-provider/asset-requests.model';
import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { TExportDashlet } from 'src/app/project/modules/dashboard/dashboard-export-modal/dashboard-export-dashlet.model';
import { DashboardAdvancedDashletService } from 'src/app/project/modules/dashboard/dashboard-export/dashboard-advanced-dashlet.service';
import { TDashboardImageElementData } from 'src/app/project/modules/dashboard/dashboard-export/dashboard-export-elements/dashboard-image-element';
import { createDashletWrapperElement } from 'src/app/project/modules/dashboard/dashboard-export/dashboard-export-service/create-dashlet-wrapper-element';
import { DashboardSimpleDashletService } from 'src/app/project/modules/dashboard/dashboard-export/dashboard-simple-dashlet.service';
import { getReportSelectedRange } from 'src/app/project/modules/dashboard/dashlet/dashlet-component/get-dashlet-selected-range';
import { EDashletType } from 'src/app/project/modules/dashboard/dashlets-enums';
import { EExportOrientation } from 'src/app/project/modules/exports/utils/export-orientation.enum';
import { EExportFormat } from 'src/app/project/modules/exports/utils/export-page-format-enum';
import { TDashletSiteRange } from 'src/app/project/modules/preferences/preferences.model';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { AssetService } from '../../../../asset-service/asset.service';
import { TExportAsset } from '../../export-asset-modal/export-asset-modal.component.consts';

@Injectable({
  providedIn: 'root',
})
export class AssetExportService {
  dashletsWrapperElement: HTMLElement;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private dashboardAdvancedDashletService: DashboardAdvancedDashletService,
    private assetService: AssetService,
    private accountService: AccountService,
    private workspaceService: WorkspaceService,
    private dashboardSimpleDashletService: DashboardSimpleDashletService,
    private assetApiProviderService: AssetApiProviderService,
  ) {}

  export({
    assetsAndDashlets,
    coverPage,
    pageFormat,
    pageLayout,
  }: {
    assetsAndDashlets: TExportAsset[];
    coverPage: boolean;
    pageFormat: EExportFormat;
    pageLayout: EExportOrientation;
  }): Observable<Response> {
    let listOfObservables: Observable<TDashboardImageElementData[]>[] = [];
    this.dashletsWrapperElement = createDashletWrapperElement();

    const body: TExportAssetPayload = {
      assetsAndDashlets: {},
      coverPage,
      pageFormat,
      pageLayout: pageLayout,
      timeGMT: getTimeZone(),
    };

    assetsAndDashlets.forEach((assetAndDashlet) => {
      listOfObservables.push(
        this.generateExportDashlets(assetAndDashlet.assets, assetAndDashlet.id).pipe(
          tap((dashlets) => {
            body.assetsAndDashlets[assetAndDashlet.id] = dashlets;
          }),
        ),
      );
    });

    if (listOfObservables.length > 0) {
      return forkJoin(listOfObservables).pipe(
        switchMap(() => {
          if (this.document.body.contains(this.dashletsWrapperElement)) {
            this.document.body.removeChild(this.dashletsWrapperElement);
          }

          return this.assetApiProviderService.exportAsset(body);
        }),
      );
    } else {
      if (this.document.body.contains(this.dashletsWrapperElement)) {
        this.document.body.removeChild(this.dashletsWrapperElement);
      }

      return this.assetApiProviderService.exportAsset(body);
    }
  }

  generateExportDashlets(
    dashletList: TExportDashlet[],
    assetId: string,
  ): Observable<TDashboardImageElementData[]> {
    const asyncHandlers: Observable<TDashboardImageElementData>[] = [];
    const defaultWorkspaces = this.assetService
      .getAsset(assetId)
      .sites.map((site) => site.workspaceId);

    // comment to show images in the app
    this.document.body.appendChild(this.dashletsWrapperElement);

    dashletList.forEach((dashlet) => {
      if (dashlet.selected) {
        asyncHandlers.push(this.generateDashletImage(dashlet, defaultWorkspaces, assetId));
      }
    });

    if (asyncHandlers.length === 0) {
      return of([]);
    } else {
      return forkJoin(asyncHandlers);
    }
  }

  private generateDashletImage(
    dashlet: TExportDashlet,
    defaultWorkspaces: string[],
    assetId: string,
  ): Observable<TDashboardImageElementData> {
    const accounts = this.accountService.getAccounts();
    const workspaces = this.workspaceService.getWorkspaces();

    const selectedRange = getReportSelectedRange(
      dashlet.name,
      dashlet.selectedRange as TDashletSiteRange,
      defaultWorkspaces,
      workspaces,
      accounts,
    );

    const isAdvancedDashlet =
      dashlet.name === EDashletType.COST_COMPARISON ||
      dashlet.name === EDashletType.CURRENT_STACKED_STATUS ||
      dashlet.name === EDashletType.OVER_TIME_STATUS ||
      dashlet.name === EDashletType.OVER_TIME_PRIORITY ||
      dashlet.name === EDashletType.CURRENT_STATUS ||
      dashlet.name === EDashletType.CURRENT_PRIORITY;

    if (!isAdvancedDashlet) {
      return this.dashboardSimpleDashletService.generateExportSimpleDashlet(dashlet);
    } else {
      return this.dashboardAdvancedDashletService.generateAdvancedDashlet(
        this.dashletsWrapperElement,
        { ...dashlet, selectedRange },
        assetId,
      );
    }
  }
}
