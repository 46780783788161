import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import {
  EIntegrationStatus,
  TAllCustomFields,
} from 'src/app/project/modules/custom-fields/custom-fields.model';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { checkCustomWorkspaceId } from 'src/app/project/modules/workspace/workspace';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EStore } from '../../../../shared/enums/store.enum';
import { ClearFilterService } from '../../clear-filter-service/clear-filter.service';
import { TCustomFieldFilter, TFilters } from '../../site-filter.model';

@Component({
  selector: 'pp-filter-field-custom-fields',
  templateUrl: './filter-field-custom-fields.component.html',
  styleUrls: ['../../site-filter-dropdown/site-filter-dropdown.component.scss'],
})
export class FilterFieldCustomFieldsComponent implements OnDestroy {
  @Input() ppFilters: TFilters;
  @Output() ppApply = new EventEmitter();

  EIconPath = EIconPath;

  customFields: TAllCustomFields = this.customFieldsService.getCustomFields();
  customFieldTypes = ECustomFieldType;
  workspaceId: string;
  selectedCF: string = null;
  iconSrc: EIconPath;
  EIntegrationStatus = EIntegrationStatus;

  private workspaces$ = new Observable<TWorkspacesById>();
  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
    }>,
    private customFieldsService: CustomFieldsService,
    private activeService: ActiveService,
    private clearFilterService: ClearFilterService,
    private translationPipe: TranslationPipe,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.workspaceId = this.activeService.getActiveWorkspaceId();

      if (!this.workspaceId) {
        this.workspaceId = checkCustomWorkspaceId();
      }

      this.customFields = this.customFieldsService.getCustomFields();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  applyFilters(): void {
    this.ppApply.emit();
  }

  setNoneCustomFieldFilter(customField: TCustomFieldFilter): void {
    if (!customField.excluded) {
      this.clearCustomFieldFilter(customField);
    }

    const customFieldToExclude = this.ppFilters.customFields.find(
      (field) => field.id.toString() === customField.id.toString(),
    );

    customFieldToExclude.excluded = !customField.excluded;

    this.applyFilters();
  }

  clearCustomFieldFilter(field: TCustomFieldFilter): void {
    this.ppFilters.customFields = this.clearFilterService.clearCustomFieldFilter(false, field);
    this.ppFilters.customFields = this.sortCustomFieldFilters();
  }

  sortCustomFieldFilters(): TCustomFieldFilter[] {
    return this.ppFilters.customFields.sort((a, b) => {
      if (
        this.customFields[a.workspaceId] &&
        this.customFields[b.workspaceId] &&
        this.customFields[a.workspaceId][a.id] &&
        this.customFields[b.workspaceId][b.id]
      ) {
        const result = this.customFields[a.workspaceId][a.id].label.localeCompare(
          this.customFields[b.workspaceId][b.id].label,
        );
        if (result > 0) {
          return 1;
        } else if (result < 0) {
          return -1;
        }
      } else {
        return 0;
      }
    });
  }

  selectCustomField(field: string): void {
    this.selectedCF = field;
  }

  customFieldsTrackById(index: number, item: TCustomFieldFilter): string | number {
    return item.id;
  }

  getTooltipTitle(volyIntegrationActive: EIntegrationStatus): string {
    switch (volyIntegrationActive) {
      case EIntegrationStatus.ON:
        return this.translationPipe.transform('custom_field_voly_tooltip');
      case EIntegrationStatus.DISABLED:
        return this.translationPipe.transform('custom_field_voly_disabled_tooltip');
      default:
        return '';
    }
  }

  getIconSrc(volyIntegrationActive: EIntegrationStatus): EIconPath {
    switch (volyIntegrationActive) {
      case EIntegrationStatus.ON:
        return EIconPath.ICON_INTEGRATION_VOLY_ACTIVE_SMALL;
      case EIntegrationStatus.DISABLED:
        return EIconPath.ICON_INTEGRATION_VOLY_INACTIVE_SMALL;
      default:
        return null;
    }
  }
}
