<!-- Removed 360 images -->
<ng-container *ngIf="ppChange.propName === EActivityPropName.IMAGES_360">
  <span class="activityChange__icon activityChange__icon--svg">
    <img class="activityChange__icon-svg" [src]="EIconPath.ACTIVITY_IMAGE_360_DARK" />
  </span>

  <div class="activityChange" *ngIf="ppChange.deleted.length === 0">
    {{ 'removed' | ppTranslate }}
    <strong class="timeline__strong">{{ '360_photos' | ppTranslate }}.</strong>
  </div>

  <div class="activityChange" *ngIf="ppChange.deleted.length > 0">
    {{ 'removed' | ppTranslate }}
    <strong class="timeline__strong">{{ ppChange.deleted.length }}</strong>
    {{
      ppChange.deleted.length === 1 ? ('360_photo' | ppTranslate) : ('360_photos' | ppTranslate)
    }}.
  </div>
</ng-container>

<!-- Removed photos -->
<ng-container *ngIf="ppChange.propName === EActivityPropName.IMAGES">
  <span class="activityChange__icon">
    <img class="activityChange__icon-svg" [src]="EIconPath.BASIC_FIELD_IMAGE" />
  </span>

  <div class="activityChange" *ngIf="ppChange.deleted.length === 0">
    {{ 'removed' | ppTranslate }}
    <strong class="timeline__strong">{{ 'images' | ppTranslate }}.</strong>
  </div>

  <div class="activityChange" *ngIf="ppChange.deleted.length > 0">
    {{ 'removed' | ppTranslate }}
    <strong class="timeline__strong">{{ ppChange.deleted.length }}</strong>
    {{ ppChange.deleted.length === 1 ? ('image' | ppTranslate) : ('images' | ppTranslate) }}.

    <div class="activityChange__media-wrapper" *ngIf="ppChange.deleted.length">
      <span class="activityChange__media-item" *ngFor="let value of ppChange.deleted">
        {{ value.caption }}

        <div>
          <img
            *ngIf="value.id"
            ppImage
            class="activityChange__uploaded-image activityChange__uploaded-image--deleted"
            draggable="false"
            [ppSecureSrc]="'api/v1/images/' + value.id + '/file/size/square/40'"
            onerror="this.onerror=null;this.src=EIconPath.PLACEHOLDER_IMAGE"
          />
        </div>
      </span>
    </div>
  </div>
</ng-container>

<!-- Removed video -->
<ng-container *ngIf="ppChange.propName === EActivityPropName.VIDEOS">
  <div *ngIf="ppChange.deleted.length === 0" style="display: flex">
    <span class="activityChange__icon">
      <img class="activityChange__icon-svg" [src]="EIconPath.BASIC_FIELD_VIDEO" />
    </span>

    <div class="activityChange">
      {{ 'removed' | ppTranslate }}
      <strong class="timeline__strong">{{ 'videos' | ppTranslate | lowercase }}.</strong>
    </div>
  </div>

  <div style="display: flex" *ngIf="ppChange.deleted.length > 0">
    <span class="activityChange__icon">
      <img class="activityChange__icon-svg" [src]="EIconPath.BASIC_FIELD_VIDEO" />
    </span>

    <div class="activityChange">
      {{ 'removed' | ppTranslate }}
      <strong class="timeline__strong">{{ ppChange.deleted.length }}</strong>
      {{
        ppChange.deleted.length === 1
          ? ('video' | ppTranslate | lowercase)
          : ('videos' | ppTranslate | lowercase)
      }}.
    </div>
  </div>
</ng-container>

<!-- Removed file -->
<ng-container *ngIf="ppChange.propName === EActivityPropName.DOCUMENTS">
  <span class="activityChange__icon">
    <img class="activityChange__icon-svg" [src]="EIconPath.BASIC_FIELD_FILE" />
  </span>

  <ng-container *ngIf="ppChange.removedFiles">
    <div *ngIf="!ppChange.removedFiles?.length" class="activityChange">
      {{ 'removed' | ppTranslate }}
      <strong class="timeline__strong">{{ 'files' | ppTranslate | lowercase }}.</strong>
    </div>

    <div *ngIf="ppChange.removedFiles?.length > 0" class="activityChange">
      {{ 'removed' | ppTranslate }}
      <strong class="timeline__strong">{{ ppChange.removedFiles.length }}</strong>
      {{
        ppChange.removedFiles.length === 1
          ? ('file' | ppTranslate | lowercase)
          : ('files' | ppTranslate | lowercase)
      }}{{ ppChange.removedFiles[0] ? ':' : '.' }}

      <span
        *ngFor="let file of ppChange.removedFiles.slice().reverse(); let last = last"
        class="activity__file-name"
        >{{ file }}</span
      >
    </div>
  </ng-container>

  <ng-container *ngIf="!ppChange.removedFiles">
    <div *ngIf="!ppChange.deleted?.length" class="activityChange">
      {{ 'removed' | ppTranslate }}
      <strong class="timeline__strong">{{ 'files' | ppTranslate | lowercase }}.</strong>
    </div>

    <div *ngIf="ppChange.deleted?.length > 0" class="activityChange">
      {{ 'removed' | ppTranslate }}
      <strong class="timeline__strong">{{ ppChange.deleted.length }}</strong>
      {{
        ppChange.deleted.length === 1
          ? ('file' | ppTranslate | lowercase)
          : ('files' | ppTranslate | lowercase)
      }}{{ ppChange.deleted[0].caption ? ':' : '.' }}

      <span
        *ngFor="let file of ppChange.deleted.slice().reverse(); let last = last"
        class="activity__file-name"
        >{{ file.caption }}</span
      >
    </div>
  </ng-container>
</ng-container>
