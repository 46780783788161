<div
  #promptContainer
  class="promptContainer"
  [ngClass]="{
    'promptContainer--sidePanelExpanded': sidePanel?.expanded,
    'promptContainer--left': position.left
  }"
>
  <div
    *ngFor="let prompt of prompts; let i = index"
    class="prompt"
    (ppOnRendered)="onPromptRendered($event, prompt)"
    [ngClass]="{
      'prompt--error': prompt.type === 'error',
      'prompt--warning': prompt.type === 'warning',
      'prompt--success': prompt.type === 'success',
      'prompt--visible': prompt.visibility
    }"
    (click)="hidePrompt(prompt)"
  >
    <p class="prompt__text">{{ prompt.text }}</p>

    <div class="prompt__timeout prompt__timeout--move">
      <div
        class="prompt__timeout--bar"
        [ngStyle]="{ animation: 'progressAnimation ' + prompt.duration + 's linear' }"
      ></div>
    </div>
  </div>
</div>
