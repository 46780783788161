import { Injectable } from '@angular/core';
import { Feature } from 'ol';
import { Subject } from 'rxjs';

export enum EPlanPointEventType {
  TRANSLATE_START,
  POLYGON_CREATED,
  TRANSLATE_END,
  POLYGON_VERTICE_CLICKED,
  POLYGON_CLICKED,
  FEATURE_CLICKED,
  POLYGON_VERTICE_TRANSLATE_START,
  POLYGON_VERTICE_TRANSLATE_END,
}

@Injectable({
  providedIn: 'root',
})
export class PlanPointEventsService {
  private readonly _translateStart$ = new Subject<Feature>();
  private readonly _polygonCreated$ = new Subject<void>();
  private readonly _translateEnd$ = new Subject<Feature>();
  private readonly _polygonVerticeClicked$ = new Subject<any>();
  private readonly _polygonClicked$ = new Subject<any>();
  private readonly _featureClicked$ = new Subject<any>();
  private readonly _polygonVerticeTranslateStart$ = new Subject<any>();
  private readonly _polygonVerticeTranslateEnd$ = new Subject<any>();

  readonly translateStart$ = this._translateStart$.asObservable();
  readonly polygonCreated$ = this._polygonCreated$.asObservable();
  readonly translateEnd$ = this._translateEnd$.asObservable();
  readonly polygonVerticeClicked$ = this._polygonVerticeClicked$.asObservable();
  readonly polygonClicked$ = this._polygonClicked$.asObservable();
  readonly featureClicked$ = this._featureClicked$.asObservable();
  readonly polygonVerticeTranslateStart$ = this._polygonVerticeTranslateStart$.asObservable();
  readonly polygonVerticeTranslateEnd$ = this._polygonVerticeTranslateEnd$.asObservable();

  constructor() {}

  emit(eventType: EPlanPointEventType, emittedObject): void {
    switch (eventType) {
      case EPlanPointEventType.TRANSLATE_START:
        this._translateStart$.next(emittedObject);
        break;

      case EPlanPointEventType.POLYGON_CREATED:
        this._polygonCreated$.next(emittedObject);

        break;
      case EPlanPointEventType.TRANSLATE_END:
        this._translateEnd$.next(emittedObject);

        break;
      case EPlanPointEventType.POLYGON_VERTICE_CLICKED:
        this._polygonVerticeClicked$.next(emittedObject);

        break;
      case EPlanPointEventType.POLYGON_CLICKED:
        this._polygonClicked$.next(emittedObject);

        break;
      case EPlanPointEventType.FEATURE_CLICKED:
        this._featureClicked$.next(emittedObject);

        break;
      case EPlanPointEventType.POLYGON_VERTICE_TRANSLATE_START:
        this._polygonVerticeTranslateStart$.next(emittedObject);

        break;
      case EPlanPointEventType.POLYGON_VERTICE_TRANSLATE_END:
        this._polygonVerticeTranslateEnd$.next(emittedObject);
        break;
    }
  }
}
