<div class="reminderElement__wrapper">
  <div class="reminderElement" (click)="openPoint()">
    <div class="note__upperRow" *ngIf="ppPoint">
      <div [class]="'reminderPriority reminderPriority--' + ppPoint.priority">
        <img class="reminderStatus" [src]="ppPoint.status | ppStatusImage: true" />
      </div>

      <div class="note">
        <p
          ppTooltip
          [ppTitle]="ppPoint.sequenceNumber + ' - ' + ppPoint.title"
          [ppTrimTooltip]="true"
          class="note__title"
        >
          {{ ppPoint.sequenceNumber }} - {{ ppPoint.title }}
        </p>

        <div
          ppTooltip
          [ppTitle]="workspace.accountName + ' - ' + workspace.siteName"
          [ppTrimTooltip]="true"
          class="note__site"
        >
          <span
            ppTooltip
            [ppTitle]="workspace.accountName"
            [ppTrimTooltip]="true"
            class="note__site__text"
          >
            {{ workspace.accountName }}
          </span>

          <span class="note__site__text">
            <img class="notification__footer-arrow" [src]="EIconPath.ARROW_RIGHT_BLANK" />
          </span>

          <span
            ppTooltip
            [ppTitle]="workspace.siteName"
            [ppTrimTooltip]="true"
            class="note__site__text"
          >
            {{ workspace.siteName }}
          </span>
        </div>
      </div>
    </div>

    <div [class.note__lowerRow--withPoint]="ppPoint" class="note__lowerRow" *ngIf="ppDescription">
      <img class="reminderNote" [src]="EIconPath.REMINDER_NOTE" />
      {{ ppDescription }}
    </div>

    <div
      [id]="'reminderSettings--' + ppId"
      class="reminderSettings"
      (click)="openSettings($event, 'reminderSettings--' + ppId)"
    >
      <img class="reminderSettings__image" [src]="EIconPath.MISC_MORE" />
    </div>
  </div>
</div>
