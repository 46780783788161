import { cloneDeep } from 'lodash';
import { DeselectWorkspacePoints } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';
import { removeSelectedPoint } from '../selected-points';

export function deselectWorkspacePoints(
  oldState: TPointsByWorkspace,
  action: DeselectWorkspacePoints,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);

  if (state[action.payload.workspaceId]) {
    state[action.payload.workspaceId].entities.forEach((_point) => {
      removeSelectedPoint(_point._id);
    });
  }

  return state;
}
