<section class="fleetNewItemSection">
  <div class="fleetNewItemSectionDescription">
    <header class="fleetNewItemSectionDescription__title">
      {{ 'new_fleet_status_weight_header' | ppTranslate }}
    </header>
    <div class="fleetNewItemSectionDescription__subtitle">
      {{ 'new_fleet_status_weight_subheader' | ppTranslate }}
    </div>

    <div class="fleetStatusWeightInfo">
      <pp-icon [ppSrc]="EIconPath.ICON_BADGE_INFO" ppClass="setSize14" ppColor="inherit"></pp-icon>

      <span
        class="fleetStatusWeightInfo__text"
        ppTooltip
        [ppTitle]="infoTooltip"
        [ppSanitize]="false"
        >{{ 'more_info' | ppTranslate }}</span
      >
    </div>
  </div>

  <div class="fleetStatusWeight__items">
    <pp-fleet-management-new-fleet-status-input
      [ppStatus]="EStatus.OPEN"
      [ppWeight]="ppStatuses[EStatus.OPEN].weight"
      (ppWeightChange)="updateWeight(EStatus.OPEN, $event)"
    ></pp-fleet-management-new-fleet-status-input>

    <pp-fleet-management-new-fleet-status-input
      [ppStatus]="EStatus.IN_PROGRESS"
      [ppWeight]="ppStatuses[EStatus.IN_PROGRESS].weight"
      (ppWeightChange)="updateWeight(EStatus.IN_PROGRESS, $event)"
    ></pp-fleet-management-new-fleet-status-input>

    <pp-fleet-management-new-fleet-status-input
      [ppStatus]="EStatus.TO_REVIEW"
      [ppWeight]="ppStatuses[EStatus.TO_REVIEW].weight"
      (ppWeightChange)="updateWeight(EStatus.TO_REVIEW, $event)"
    ></pp-fleet-management-new-fleet-status-input>
  </div>

  <div class="fleetStatusWeight__items">
    <pp-fleet-management-new-fleet-status-input
      [ppStatus]="EStatus.ONHOLD"
      [ppWeight]="ppStatuses[EStatus.ONHOLD].weight"
      (ppWeightChange)="updateWeight(EStatus.ONHOLD, $event)"
    ></pp-fleet-management-new-fleet-status-input>

    <pp-fleet-management-new-fleet-status-input
      [ppStatus]="EStatus.CLOSED"
      [ppWeight]="ppStatuses[EStatus.CLOSED].weight"
      (ppWeightChange)="updateWeight(EStatus.CLOSED, $event)"
    ></pp-fleet-management-new-fleet-status-input>

    <pp-fleet-management-new-fleet-status-input
      [ppStatus]="EStatus.CANCELED"
      [ppWeight]="ppStatuses[EStatus.CANCELED].weight"
      (ppWeightChange)="updateWeight(EStatus.CANCELED, $event)"
    ></pp-fleet-management-new-fleet-status-input>
  </div>
</section>
