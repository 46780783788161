<li
  ppTooltip
  ppTitle="Overview"
  class="mainNav__element mainNav__element--with-image"
  [class.mainNav__element--spacing]="!ppIsLandscape && (ppTimelineEnabled || ppRemindersEnabled)"
  [class.mainNav__element--no-spacing]="ppIsLandscape"
  [class.mainNav__element--active]="ppRouterPath === '/site/overview'"
  [class.mainNav__element--disabled]="!user.activeWorkspaceId || overviewBlocked || ppDisabled"
  [ppOptions]="{ placement: 'right' }"
  (click)="goToOverview()"
>
  <img
    data-m-target="Overview button"
    class="filterPanel__tooltip mainNav__element--overview-img"
    [src]="EIconPath.SIDE_PANEL_OVERVIEW"
  />

  <h6
    class="filterPanel__title filterPanel__title--img"
    *ngIf="ppIsMobile && !sidePanel.rightPanelExpanded"
  >
    {{ 'overview' | ppTranslate }}
  </h6>
</li>
