import { GET_COLUMNS } from '../site-table/columns/columns.store';

export const LONG_PROCESSING_TIME_MS = 5000;

const highlights = {
  columnsHighlight: false,
};

export const checkTableColumnsHover = () => {
  let highlight = false;

  const columns = GET_COLUMNS();

  for (let i = 0; i < columns.length; i++) {
    if (columns[i].hidden) {
      highlight = true;

      break;
    }
  }

  highlights.columnsHighlight = highlight;
};

export const getHighlights = () => highlights;
