import { Injectable } from '@angular/core';
import {
  API_files_S3_link,
  API_files_bulk,
  API_files_bulk_new,
  API_files_documents_point,
  API_files_images_360_item_ref,
  API_files_images_item_ref_V2,
  API_files_images_rotate,
  API_files_images_upload_annotated,
  API_files_images_xml,
  API_files_rename,
  API_files_video_point,
  API_files_video_s3,
} from '@core/api/paths';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import {
  AttachmentViewModelFactory,
  TAttachmentDTO,
} from '../../../view-models/attachment.view-model';
import { TDeleteFilesRequest } from './files-requests.model';
import { map } from 'rxjs/operators';
import { TAttachment } from '../../../modules/points/attachments/attachments.model';
import { EDocumentType } from '../../../shared/enums/document-type.enum';

@Injectable({
  providedIn: 'root',
})
export class FilesApiProviderService {
  constructor(private apiService: ApiService) {}

  updateImageRotation(imageId: string, angle: number, _id: string): Observable<string> {
    const url = API_files_images_rotate(imageId, angle, _id);

    return this.apiService.post<string>(url, null);
  }

  updateImageAnnotation(imageId: string, formData: FormData): Observable<string> {
    const url = API_files_images_upload_annotated(imageId);

    return this.apiService.postWithFormData<string>(url, formData);
  }

  fetchFiles(_id: string): Observable<TAttachmentDTO[]> {
    const url = API_files_documents_point(_id);

    return this.apiService.get<TAttachmentDTO[]>(url);
  }

  deleteFiles(_id: string, body: TDeleteFilesRequest): Observable<void> {
    const url = API_files_bulk(_id);

    return this.apiService.delete<void>(url, body);
  }

  deleteNewPointFiles(body: TDeleteFilesRequest): Observable<void> {
    const url = API_files_bulk_new();

    return this.apiService.delete<void>(url, body);
  }

  renameAttachment(
    urlType: string,
    attachmentId: string,
    body: string,
  ): Observable<TAttachmentDTO[]> {
    const url = API_files_rename(urlType, attachmentId);

    return this.apiService.put<TAttachmentDTO[]>(url, body);
  }

  fetchVideoData(id: string): Observable<TAttachment[]> {
    const url = API_files_video_point(id);

    return this.apiService
      .get<TAttachmentDTO[]>(url)
      .pipe(map((dto) => AttachmentViewModelFactory.createFromDTO(dto, id, EDocumentType.VIDEO)));
  }

  fetchImage360Data(id: string): Observable<TAttachment[]> {
    const url = API_files_images_360_item_ref(id);

    return this.apiService
      .get<TAttachmentDTO[]>(url)
      .pipe(
        map((dto) => AttachmentViewModelFactory.createFromDTO(dto, id, EDocumentType.IMAGE_360)),
      );
  }

  fetchImagesForOnline(id: string): Observable<TAttachment[]> {
    const url = API_files_images_item_ref_V2(id);

    return this.apiService
      .get<TAttachmentDTO[]>(url)
      .pipe(map((dto) => AttachmentViewModelFactory.createFromDTO(dto, id, EDocumentType.IMAGE)));
  }

  createImage360(imageId: string): Observable<string> {
    const url = API_files_images_xml(imageId);

    return this.apiService.get<string>(url);
  }

  fetchVideoUrl(videoId: string): Observable<string> {
    const url = API_files_video_s3(videoId);

    return this.apiService.get<string>(url);
  }

  fetchDocumentS3Link(documentId: string): Observable<string> {
    const url = API_files_S3_link(documentId);

    return this.apiService.get<string>(url);
  }
}
