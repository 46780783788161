import { Component, OnInit } from '@angular/core';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TAssetDetails } from '../../../asset-service/asset.consts';
import { NewAssetService } from '../new-asset.service';

type TAssetDetail = {
  label: string;
  value: string;
  placeholder: string;
  key: string;
};

@Component({
  selector: 'pp-new-asset-details',
  templateUrl: './new-asset-details.component.html',
  styleUrls: ['./new-asset-details.component.scss'],
})
export class NewAssetDetailsComponent implements OnInit {
  assetDetails: TAssetDetail[];
  EIconPath = EIconPath;

  private desiredOrder: (keyof TAssetDetails)[] = [
    'imoNumber',
    'build',
    'length',
    'grossTonnage',
    'draft',
    'beam',
  ];

  constructor(private newAssetService: NewAssetService, private translationPipe: TranslationPipe) {}

  ngOnInit(): void {
    this.setDetailsList();
  }

  updateAssetDetails(): void {
    const newDetails: Partial<TAssetDetails> = {};

    this.assetDetails.forEach((detail) => {
      newDetails[detail.key] = detail.value;
    });

    this.newAssetService.setAssetDetails(newDetails as TAssetDetails);
  }

  private setDetailsList(): void {
    const assetDetails = this.newAssetService.getNewAsset().details;
    const orderMap: {
      [key: string]: number;
    } = {};

    this.desiredOrder.forEach((item, index) => {
      orderMap[item] = index;
    });

    this.assetDetails = Object.keys(assetDetails)
      .map((key: keyof TAssetDetails) => {
        return {
          label: this.getDetailLabel(key),
          value: assetDetails[key],
          placeholder: this.getDetailPlaceholder(key),
          key,
        };
      })
      .sort((a, b) => this.sortDetails(a, b, orderMap));
  }

  private getDetailLabel(key: keyof TAssetDetails): string {
    switch (key) {
      case 'beam':
        return this.translationPipe.transform('asset_details_beam');
      case 'draft':
        return this.translationPipe.transform('asset_details_draft');
      case 'length':
        return this.translationPipe.transform('asset_details_length');
      case 'grossTonnage':
        return this.translationPipe.transform('asset_details_gross_tonnage');
      case 'imoNumber':
        return this.translationPipe.transform('asset_details_imo_number');
      case 'build':
        return this.translationPipe.transform('asset_details_build');
      default:
        return '';
    }
  }

  private getDetailPlaceholder(key: keyof TAssetDetails): string {
    switch (key) {
      case 'beam':
        return '20.5m...';
      case 'draft':
        return '4.5m...';
      case 'length':
        return '50.5m...';
      case 'grossTonnage':
        return '2000GT...';
      case 'imoNumber':
        return '1378HD31AB...';
      case 'build':
        return '2021, Germany...';
      default:
        return '';
    }
  }

  private sortDetails(
    a: TAssetDetail,
    b: TAssetDetail,
    orderMap: {
      [key: string]: number;
    },
  ): number {
    if (a.key in orderMap && b.key in orderMap) {
      return orderMap[a.key] - orderMap[b.key];
    }

    if (a.key in orderMap) {
      return -1;
    }

    if (b.key in orderMap) {
      return 1;
    }

    return 0;
  }
}
