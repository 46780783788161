export function getErrorMessage(error): Promise<string> {
  return new Promise((resolve) => {
    error.text().then((text) => {
      const textJSON = JSON.parse(text);

      if (textJSON.message) {
        resolve(textJSON.message);
      }

      if (textJSON.errorMessage) {
        resolve(textJSON.errorMessage);
      }

      if (textJSON.errors) {
        resolve(textJSON.errors[0].message);
      }

      if (textJSON.error) {
        resolve(textJSON.error);
      }

      resolve(null);
    });
  });
}
