import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { TAccount } from 'src/app/project/modules/account/account.model';
import { TDashlet, TDashletSiteRange } from 'src/app/project/modules/preferences/preferences.model';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

@Component({
  selector: 'pp-dashlet-timeline-account',
  templateUrl: './dashlet-timeline-account.component.html',
  styleUrls: ['./dashlet-timeline-account.component.scss'],
})
export class DashletTimelineAccountComponent implements OnChanges {
  @Input() ppAccount: TAccount;
  @Input() ppSiteRange: TDashletSiteRange;
  @Input() ppWorkspaces: TWorkspacesById;
  @Input() ppBlockedTimelines: string[];
  @Input() ppDashlet: TDashlet;
  @Output() ppUpdateSiteRange = new EventEmitter<TDashletSiteRange>();

  workspaceList: {
    workspaceId: string;
    blocked: boolean;
  }[];

  constructor() {}

  ngOnChanges() {
    this.workspaceList = this.ppAccount.workspaces.map((workspaceId) => ({
      workspaceId,
      blocked: this.ppBlockedTimelines.includes(workspaceId),
    }));
  }

  updateSiteRange(data: TDashletSiteRange): void {
    this.ppUpdateSiteRange.emit(data);
  }
}
