import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { AutonumericModule } from 'src/app/project/directives/autonumeric/autonumeric.module';
import { FocusModule } from 'src/app/project/directives/focus/focus.module';
import { ImagesFallbackModule } from 'src/app/project/directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from 'src/app/project/directives/on-rendered/on-rendered.module';
import { SwitchCasesModule } from 'src/app/project/directives/switch-cases/switch-cases.module';
import { TooltipsModule } from 'src/app/project/features/tooltip/tooltips.module';
import { IconModule } from '../../icon/icon-module';
import { SaveIndicatorModule } from '../../save-indicator/save-indicator.module';
import { DatepickerComponent } from './datepicker.component';

@NgModule({
  declarations: [DatepickerComponent],
  imports: [
    CommonModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    PipesModule,
    SaveIndicatorModule,
    IconModule,
  ],
  exports: [DatepickerComponent],
})
export class DatepickerModule {}
