import { TPoint } from '../../../points/points.model';
import { TColumn } from '../columns/column.model';
import { getValues } from './get-values';

export function sortLength(
  _a: TPoint | string,
  _b: TPoint | string,
  sortColumns: Partial<TColumn>[],
  index: number,
  { property = null }: { property?: string } = {},
): number {
  let values: any = {
    a: _a,
    b: _b,
  };

  if (property) {
    values = getValues(_a, _b, property);
  }

  if (values.a.length === 0 && values.b.length !== 0) {
    return 1;
  }

  if (values.a.length !== 0 && values.b.length === 0) {
    return -1;
  }

  if (values.a.length < values.b.length) {
    return sortColumns[index].sortOrder === 'ASC' ? -1 : 1;
  } else if (values.a.length > values.b.length) {
    return sortColumns[index].sortOrder === 'ASC' ? 1 : -1;
  }

  return 0;
}
