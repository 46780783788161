import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TAccountUser } from '../../../users/account.user.model';

@Injectable({
  providedIn: 'root',
})
export class UserManagementUserSectionService {
  private readonly _expandedUsersChange = new Subject<string[]>();
  public readonly expandedUsersChange$ = this._expandedUsersChange.asObservable();

  private readonly _filteredUsersChange = new Subject<TAccountUser[]>();
  public readonly filteredUsersChange$ = this._filteredUsersChange.asObservable();

  private expandedUsers: string[] = [];
  private filteredUsers: TAccountUser[] = [];

  isExpandedUser(userId: string): boolean {
    return this.expandedUsers.includes(userId);
  }

  toggleExpandedUser(userId: string, expanded: boolean): void {
    if (expanded && !this.expandedUsers.includes(userId)) {
      this.expandedUsers.push(userId);
    } else if (!expanded && this.expandedUsers.includes(userId)) {
      this.expandedUsers = this.expandedUsers.filter((id) => id !== userId);
    }

    this._expandedUsersChange.next(this.expandedUsers);
  }

  clearExpandedUsers(): void {
    this.expandedUsers = [];

    this._expandedUsersChange.next(this.expandedUsers);
  }

  getExpandedUsers(): string[] {
    return this.expandedUsers;
  }

  setFilteredUsers(users: TAccountUser[]): void {
    this.filteredUsers = users;

    this._filteredUsersChange.next(this.filteredUsers);
  }

  getFilteredUsers(): TAccountUser[] {
    return this.filteredUsers;
  }

  setExpandedUsers(expandedUsers: string[]): void {
    this.expandedUsers = expandedUsers;

    this._expandedUsersChange.next(this.expandedUsers);
  }
}
