import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { PhoneInputComponent } from './phone-input.component';

@NgModule({
  declarations: [PhoneInputComponent],
  imports: [CommonModule, PipesModule],
  exports: [PhoneInputComponent],
})
export class PhoneInputModule {}
