<div class="authorizationProgress">
  <section class="authorizationStatusIndicator">
    <div class="authorizationStatus">
      <pp-authorization-progress-icon [ppIcon]="firstIcon"></pp-authorization-progress-icon>

      <pp-authorization-progress-bar [ppStep]="barProgress"></pp-authorization-progress-bar>

      <pp-authorization-progress-icon [ppIcon]="secondIcon"></pp-authorization-progress-icon>
    </div>
  </section>

  <section class="authorizationStatusInfo"></section>
</div>
