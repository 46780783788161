import { EFormulaPieceType, TFormulaPiece } from './formula-input.type';

export function buildFormula(formula: TFormulaPiece[]): string {
  return formula
    .map((piece) => {
      if (piece.type === EFormulaPieceType.OPERATOR) {
        return piece.value;
      } else if (piece.type === EFormulaPieceType.CUSTOM_FIELD) {
        return `{${piece.value}}`;
      }
      return '';
    })
    .join('');
}
