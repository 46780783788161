import { GET_TABLE } from '../site/site-table/table.ui.store';
import { TPoint } from './points.model';

let selectedPoints: string[] = [];

export function getSelectedPoints(): string[] {
  return selectedPoints;
}

export function clearSelectedPoints(): void {
  selectedPoints = [];
}

export function setSelectedPoints(points: string[]): void {
  const table = GET_TABLE();

  selectedPoints = points;

  if (table) {
    table.selectedPoints = new Set(points);

    if (table.selectedPoints.size === table.points.length) {
      table.tableHead.selectPoints();
    } else {
      table.tableHead.deselectPoints();
    }
  }
}

export function addSelectedPoint(_id: string): void {
  if (!selectedPoints.includes(_id)) {
    selectedPoints.push(_id);
  }
}

export function removeSelectedPoint(_id: string): void {
  const pointIndex = selectedPoints.indexOf(_id);
  const table = GET_TABLE();

  if (pointIndex !== -1) {
    selectedPoints.splice(pointIndex, 1);
  }

  if (table) {
    if (table.selectedPoints.has(_id)) {
      table.selectedPoints.delete(_id);
    }

    if (table.selectedPoints.size === table.points.length) {
      table.tableHead.selectPoints();
    } else {
      table.tableHead.deselectPoints();
    }
  }
}

export function togglePoint(point: TPoint): boolean {
  if (point) {
    const selectedPointsIds = getSelectedPoints();
    const pointSelected = selectedPointsIds.includes(point._id);

    if (pointSelected) {
      removeSelectedPoint(point._id);
      return false;
    } else {
      addSelectedPoint(point._id);
      return true;
    }
  }

  return false;
}
