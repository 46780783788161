<table class="reminders__placeholder">
  <tr class="reminders__placeholder__row" *ngFor="let placeholder of placeholders">
    <div class="reminders__placeholder__header__item reminders__placeholder__header__item--header">
      &nbsp;
    </div>
    <div
      class="reminders__placeholder__header__item reminders__placeholder__header__item--header__bottom"
    >
      &nbsp;
    </div>

    <div class="reminders__placeholder__cell">
      <div class="reminders__placeholder__item">&nbsp;</div>
    </div>

    <div class="reminders__placeholder__cell">
      <div class="reminders__placeholder__item">&nbsp;</div>
    </div>
  </tr>
</table>
