import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { TSidePanel } from '../../layout/side-panel/side-panel.model';
import { SidePanelService } from '../../layout/side-panel/side-panel.service';
import { TSync } from '../../offline/sync.model';
import { FleetManagementRoutesService } from '../fleet-management-routes.service';
import { EFleetManagementRoutesParams } from '../fleet-management.routes';
import { FleetService } from '../fleet-service/fleet.service';
import { TFleet } from '../fleet.consts';
import { PreviousPageService } from 'src/app/core/services/previous-page.service';

@Component({
  selector: 'pp-fleet-page',
  templateUrl: './fleet-page.component.html',
  styleUrls: ['./fleet-page.component.scss'],
})
export class FleetPageComponent {
  sidePanel: TSidePanel = this.sidePanelService.getSidePanel();

  synced = false;
  private sync$: Observable<TSync>;
  private readonly synced$ = new Subject<void>();
  private readonly destroy$ = new Subject<void>();

  fleet: TFleet;
  fleetId: string;

  constructor(
    private sidePanelService: SidePanelService,
    private store: Store<{ sync: TSync }>,
    private fleetService: FleetService,
    private activatedRoute: ActivatedRoute,
    private fleetManagementRoutesService: FleetManagementRoutesService,
    private previousPageService: PreviousPageService,
  ) {
    this.previousPageService.clearUrlStack();
    this.sync$ = this.store.pipe(select(EStore.SYNC));

    this.sync$.pipe(takeUntil(this.synced$)).subscribe((sync) => {
      if (sync.synced) {
        this.synced$.next();

        this.synced = true;
      }
    });

    this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.fleetId = params.get(EFleetManagementRoutesParams.FLEET_ID);

      this.checkIfFleetLoaded(this.fleetId);
    });

    this.fleetService.fleetChange$.pipe(takeUntil(this.destroy$)).subscribe((fleetId) => {
      this.fleetId = this.fleetService.getActiveFleetId();

      this.checkIfFleetLoaded(this.fleetId);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private checkIfFleetLoaded(fleetId: string) {
    if (fleetId) {
      this.fleet = this.fleetService.getFleet(fleetId);
      const activeFleetId = this.fleetService.getActiveFleetId();
      const activeFleet = this.fleetService.getFleet(activeFleetId);

      if (!this.fleet && activeFleet) {
        this.fleetManagementRoutesService.redirectFromMissingFleet(activeFleetId);
      } else {
        this.fleetService.setActiveFleetId(fleetId);
      }
    }
  }
}
