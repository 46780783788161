import VirtualScroller from 'src/app/project/modules/external/virtual-scroller/VirtualScroller';
import FolderMessage from '../folder-message/FolderMessage';
import { TFlattenedAccount } from './account-list-flatten-account';
import { TAnyFunction } from '@core/helpers';

type TAddEmptyFolderMessageElementParams = {
  _index: number;
  accountsFlattened: TFlattenedAccount[];
  virtualScroller: VirtualScroller;
  dragoverCallback: TAnyFunction;
};

export const addEmptyFolderMessageElement = ({
  _index,
  accountsFlattened,
  virtualScroller,
  dragoverCallback,
}: TAddEmptyFolderMessageElementParams): HTMLElement => {
  let data = accountsFlattened[_index];

  if (virtualScroller) {
    data = virtualScroller.data[_index];
  }

  return new FolderMessage(data, {
    dragoverCallback: (folderId): void => dragoverCallback(folderId),
  }).element;
};
