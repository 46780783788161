import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { TAccount } from 'src/app/project/modules/account/account.model';
import { TDashlet, TDashletSiteRange } from 'src/app/project/modules/preferences/preferences.model';
import { PermissionsService } from 'src/app/project/modules/share/permissions.service';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { EStore } from 'src/app/project/shared/enums/store.enum';

@Component({
  selector: 'pp-dashlet-workspace-select',
  templateUrl: './dashlet-workspace-select.component.html',
  styleUrl: './dashlet-workspace-select.component.scss',
})
export class DashletWorkspaceSelectComponent implements OnDestroy {
  @Input() ppDashlet: TDashlet;
  @Input() ppSiteRange: TDashletSiteRange;
  @Output() ppSiteRangeChange = new EventEmitter<TDashletSiteRange>();

  accounts: TAccount[] = null;
  accounts$: Observable<TAccount[]>;
  workspaces$: Observable<TWorkspacesById>;
  workspaces: TWorkspacesById;
  private readonly destroy$ = new Subject<void>();
  disabledWorkspaces: string[] = [];

  constructor(
    private store: Store<{ accounts: TAccount[]; workspaces: TWorkspacesById }>,
    private permissionsService: PermissionsService,
  ) {
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces: TWorkspacesById) => {
      this.workspaces = workspaces;
      this.setDisabledWorkspaces();
    });

    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
      this.accounts = accounts;
    });
  }

  private setDisabledWorkspaces(): void {
    this.disabledWorkspaces = [];

    Object.keys(this.workspaces).forEach((workspaceId) => {
      const activitiesEnabled = this.permissionsService.areActivitiesEnabled(workspaceId);
      const canSeeTags = this.permissionsService.getTagPermissions(workspaceId).read;
      const canSeeComments = this.permissionsService.getCommentPermissions(workspaceId).read;

      if ((!activitiesEnabled || !canSeeTags) && !canSeeComments) {
        this.disabledWorkspaces.push(workspaceId);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  updateSiteRange(range: TDashletSiteRange): void {
    this.ppSiteRange = range;

    this.ppSiteRangeChange.emit(this.ppSiteRange);
  }

  clearSiteRange(): void {
    this.ppSiteRange = {
      workspaceIds: [],
      accountId: null,
    };

    this.ppSiteRangeChange.emit(this.ppSiteRange);
  }
}
