import { TAllCustomFields } from '../../../custom-fields/custom-fields.model';
import { TFilters } from '../../../filters/site-filter.model';

export function removeDeletedCustomFieldsFromFilters(
  filters: TFilters,
  customFields: TAllCustomFields,
): void {
  filters.customFields = filters.customFields.filter((_customField) => {
    if (!customFields[_customField.workspaceId]?.[_customField.id]) {
      return false;
    } else {
      return true;
    }
  });
}
