import { TShare, TWorkspaceSharesDict } from './share.model';

let shares: TShare[] = [];

const sharesByWorkspaceId: TWorkspaceSharesDict = {};

// Get

export const GET_SHARE = (_workspaceId: string): TShare => sharesByWorkspaceId[_workspaceId];

export const GET_SHARES = (): TShare[] => shares;

// Set

export const SET_SHARES = (_shares: TShare[]): void => {
  if (_shares) {
    shares = _shares;

    shares.forEach((_share) => {
      sharesByWorkspaceId[_share.workspaceId] = _share;
    });
  }
};

// Modify

export const ADD_SHARE = (_share: TShare): void => {
  const shareIndex = shares.findIndex((__share) => __share.shareId === _share.shareId);

  if (shareIndex > -1) {
    shares[shareIndex] = _share;
  } else {
    shares.push(_share);
  }

  sharesByWorkspaceId[_share.workspaceId] = _share;
};

// Clear

export const CLEAR_SHARES = (): void => {
  shares.length = 0;
};
