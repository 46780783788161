<div
  id="{{ 'filtercflist' + ppField.id.toString() + ppField.workspaceId.toString() }}"
  class="select select--w100"
  (click)="
    showList('filtercflist' + ppField.id.toString() + ppField.workspaceId.toString(), ppField)
  "
  [class.select--inputFocused]="ppSelectedCF === ppField.id"
>
  <span class="select__placeholder" *ngIf="!ppField.path">
    {{ 'select_option' | ppTranslate }}
  </span>

  <span *ngIf="ppField.path">
    {{ ppField.path }}
  </span>

  <div class="select__icon-container">
    <pp-icon
      [ppSrc]="
        ppSelectedCF === ppField.id
          ? EIconPath.ICON_MISC_UP_ARROW_18
          : EIconPath.ICON_MISC_DOWN_ARROW_18
      "
      ppColor="grey-400"
      ppClass="setSize18"
    ></pp-icon>
  </div>
</div>
