<div class="dashletActivity" *ngIf="ppAllUsers">
  <ng-container *ngIf="ppChange.added.length > 0">
    <ng-container>
      <img class="dashletActivity__icon" [src]="EIconPath.BASIC_FIELD_ASSIGNEES" />

      <div class="dashletActivity__text">{{ 'added' | ppTranslate }}:</div>
    </ng-container>

    <div class="activityChange__assignees" *ngFor="let assignee of ppChange.added; let last = last">
      <img
        ppImage
        [src]="ppAllUsers[assignee].avatarPublicUrl"
        *ngIf="ppAllUsers[assignee]?.avatarPublicUrl"
        class="dashboard__activity-assignees-avatar"
      />

      <img
        class="dashboard__activity-assignees-avatar"
        *ngIf="!ppAllUsers[assignee]?.avatarPublicUrl"
        [src]="EIconPath.BASIC_FIELD_ASSIGNEES"
      />

      <ng-container *ngIf="ppAllUsers[assignee]">
        <span class="dashletActivities__item-quoted">{{ ppAllUsers[assignee].userName }}</span>
      </ng-container>

      <ng-container *ngIf="!ppAllUsers[assignee]">
        <span class="dashletActivities__item-quoted">({{ 'deleted_user' | ppTranslate }})</span>
      </ng-container>

      <span *ngIf="!last">,</span>
      <span *ngIf="last">.</span>
    </div>
  </ng-container>

  <ng-container *ngIf="ppChange.deleted.length > 0">
    <ng-container>
      <img class="dashletActivity__icon" [src]="EIconPath.BASIC_FIELD_ASSIGNEES" />

      <div class="dashletActivity__text">{{ 'removed' | ppTranslate }}:</div>
    </ng-container>

    <div
      class="activityChange__assignees"
      *ngFor="let assignee of ppChange.deleted; let last = last"
    >
      <img
        ppImage
        [src]="ppAllUsers[assignee].avatarPublicUrl"
        *ngIf="ppAllUsers[assignee]?.avatarPublicUrl"
        class="dashboard__activity-assignees-avatar"
      />

      <img
        class="dashboard__activity-assignees-avatar"
        *ngIf="!ppAllUsers[assignee]?.avatarPublicUrl"
        [src]="EIconPath.BASIC_FIELD_ASSIGNEES"
      />

      <ng-container *ngIf="ppAllUsers[assignee]">
        <span class="dashletActivities__item-quoted">{{ ppAllUsers[assignee].userName }}</span>
      </ng-container>

      <ng-container *ngIf="!ppAllUsers[assignee]">
        <span class="dashletActivities__item-quoted">({{ 'deleted_user' | ppTranslate }})</span>
      </ng-container>

      <span *ngIf="!last">,</span>
      <span *ngIf="last">.</span>
    </div>
  </ng-container>
</div>
