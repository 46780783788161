import { EPriority } from 'src/app/project/modules/points/priorities';
import { Fill, Stroke, Style, Circle } from 'ol/style.js';
import Feature from 'ol/Feature';

export function createDrawingStyle(priority: string, feature: Feature): Style[] {
  let pinColor = '#FFFFFF';
  let fillColor = '#FFFFFF';

  if (priority === EPriority.LOW) {
    pinColor = 'rgb(77, 160, 229)';
    fillColor = 'rgba(77, 160, 229, 0.2)';
  }

  if (priority === EPriority.MEDIUM) {
    pinColor = 'rgb(255, 206, 41)';
    fillColor = 'rgba(255, 206, 41, 0.2)';
  }

  if (priority === EPriority.HIGH) {
    pinColor = 'rgb(229, 79, 80)';
    fillColor = 'rgba(229, 79, 80, 0.2)';
  }

  const styles: {
    [key: string]: Style;
  } = {
    Point: new Style({
      image: new Circle({
        radius: 6.5,
        stroke: new Stroke({
          color: 'white',
          width: 2,
        }),
        fill: new Fill({
          color: pinColor,
        }),
      }),
    }),
    LineString: new Style({
      stroke: new Stroke({
        color: pinColor,
        width: 3,
      }),
    }),
    Polygon: new Style({
      fill: new Fill({
        color: fillColor,
      }),
    }),
  };

  return [styles[feature.getGeometry().getType()]];
}
