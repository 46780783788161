import {TAnyFunction} from './types';

export const KEY_ESC = 'KEY_ESC';
export const KEY_LEFT = 'KEY_LEFT';
export const KEY_RIGHT = 'KEY_RIGHT';
export const KEY_DELETE = 'KEY_DELETE';

export function whichTransitionEvent(): string {
  const el = document.createElement('fakeElement');

  const transitions = {
    transition: 'transitionend',
    OTransition: 'oTransitionEnd',
    MozTransition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd',
  };

  for (const t in transitions) {
    if (el.style[t] !== undefined) {
      return transitions[t];
    }
  }

  return null;
}

export function pressKey(event: KeyboardEvent, key: string, callback: TAnyFunction): void {
  switch (key) {
    case KEY_ESC:
      if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
        callback();
      }

      break;
    case KEY_LEFT:
      if (event.keyCode === 37) {
        callback();
      }

      break;
    case KEY_RIGHT:
      if (event.keyCode === 39) {
        callback();
      }

      break;

    case KEY_DELETE:
      if (event.keyCode === 46) {
        callback();
      }

      break;

    default:
      break;
  }
}

export function pressEsc(event: KeyboardEvent, callback: TAnyFunction): void {
  pressKey(event, KEY_ESC, callback);
}

export function clickOutside(
  event: Event,
  element: HTMLElement | HTMLElement[],
  callback: TAnyFunction,
): void {
  const target = event.target;

  if (Array.isArray(element)) {
    let test = false;

    element.forEach((el) => {
      if (el.contains(event.target as Node)) {
        test = true;
      }
    });

    if (!test) {
      callback();
    }
  } else if (!element || !element.contains(target as Node)) {
    callback();
  }
}
