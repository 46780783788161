import { cloneDeep } from 'lodash';

import { EActionType, ActionsUnion } from './sync.actions';
import { TSync } from './sync.model';

export const initialState: TSync = {
  synced: false,
  syncing: false,
  basicFetched: false,
  messages: [],
};

let newState: TSync = {
  synced: false,
  syncing: false,
  basicFetched: false,
  messages: [],
};

export function syncReducer(state: TSync = initialState, action: ActionsUnion): TSync {
  switch (action.type) {
    case EActionType.SET_SYNCED:
      newState = cloneDeep(state);

      newState.synced = action.payload;

      return newState;

    case EActionType.SET_SYNCING:
      newState = cloneDeep(state);

      newState.syncing = action.payload;

      return newState;

    case EActionType.SET_BASIC_FETCHED:
      newState = cloneDeep(state);

      newState.basicFetched = action.payload;

      return newState;

    case EActionType.CLEAR_MESSAGES_ARRAY:
      newState = cloneDeep(state);

      newState.messages = [];

      return newState;

    default:
      return state;
  }
}
