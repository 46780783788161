import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SharesApiProviderService } from '../../data-providers/api-providers/share-api-provider/shares-api-provider.service';
import { ResponseErrorService } from '../errors/response-error.service';
import { TCreateAccountAdminDTO, TCreateShareDTO, TNewUser, TUpdateShareDTO } from './share.model';

@Injectable({
  providedIn: 'root',
})
export class ShareBulkService {
  constructor(
    private sharesApiProviderService: SharesApiProviderService,
    private responseErrorService: ResponseErrorService,
  ) {}

  removeShares(shares: string[]): Observable<null> {
    return this.sharesApiProviderService
      .bulkRemoveShares(shares)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  removeAccountUser(userId: string, accountId: string): Observable<null> {
    return this.sharesApiProviderService
      .removeAccountUser(userId, accountId)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  bulkCreateShare(shares: TCreateShareDTO[]): Observable<TNewUser[]> {
    return this.sharesApiProviderService
      .bulkCreateShare(shares)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  bulkEditShares(shares: TUpdateShareDTO[]): Observable<TNewUser[]> {
    return this.sharesApiProviderService
      .bulkEditShares(shares)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  removeAccountAdmin(email: string): Observable<null> {
    const body: TCreateAccountAdminDTO = {
      email: email,
    };

    return this.sharesApiProviderService
      .removeAccountAdmin(body)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  downgradeAccountAdmin(shares: TCreateShareDTO[]): Observable<TNewUser[]> {
    return this.sharesApiProviderService
      .downgradeAccountAdmin(shares)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }
}
