import { Component, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { AssetService } from 'src/app/project/modules/fleet-management/asset-service/asset.service';
import { AssetDashboardService } from 'src/app/project/modules/fleet-management/fleet-management-fleet-page/fleet-assets/asset-card/asset-dashboard/asset-dashboard.service';
import { TDashlet } from 'src/app/project/modules/preferences/preferences.model';
import { TDashletBudgetItem } from '../../../new-dashlet-modal/new-dashlet-modal-cost-fields/new-dashlet-modal-cost-fields.consts';
import { TEditCostComparisonModalData } from './edit-cost-comparison-modal.consts';

@Component({
  selector: 'pp-edit-cost-comparison-dashlet-modal',
  templateUrl: './edit-cost-comparison-dashlet-modal.component.html',
  styleUrl: './edit-cost-comparison-dashlet-modal.component.scss',
})
export class EditCostComparisonDashletModalComponent implements OnInit {
  dashlet: TDashlet;
  assetId: string;
  initialDashletData: TDashletBudgetItem[];

  constructor(
    private modalService: ModalService,
    private assetService: AssetService,
    private assetDashboardService: AssetDashboardService,
  ) {}

  ngOnInit(): void {
    const modalData: TEditCostComparisonModalData = this.modalService.getModal().data;

    this.dashlet = modalData.dashlet;
    this.assetId = modalData.assetId;

    this.initialDashletData = Object.keys(this.dashlet.selectedRange.workspacesCosts).map(
      (workspaceId) => {
        const workspaceCost = this.dashlet.selectedRange.workspacesCosts[workspaceId];

        return {
          workspaceId,
          budgetFieldId: workspaceCost[0],
          costFieldId: workspaceCost[1],
        };
      },
    );
  }

  editDashlet(data: TDashletBudgetItem[]): void {
    const dashlets = cloneDeep(this.assetService.getAsset(this.assetId).dashlets);
    const dashletIndex = this.findDashlet(dashlets);
    const workspaceCosts: { [workspaceId: string]: [string, string] } = {};

    data.forEach((item) => {
      workspaceCosts[item.workspaceId] = [item.budgetFieldId, item.costFieldId];
    });

    dashlets[dashletIndex].selectedRange.workspacesCosts = workspaceCosts;

    this.assetDashboardService.updateAssetDashboard(dashlets, this.assetId);
    this.modalService.hideModal();
  }

  deleteDashlet(): void {
    const dashlets = cloneDeep(this.assetService.getAsset(this.assetId).dashlets);
    const dashletIndex = this.findDashlet(dashlets);

    dashlets.splice(dashletIndex, 1);

    this.assetDashboardService.updateAssetDashboard(dashlets, this.assetId);
    this.modalService.hideModal();
  }

  private findDashlet(dashlets: TDashlet[]): number {
    return dashlets.findIndex((dashlet) => {
      return (
        dashlet.name === this.dashlet.name &&
        dashlet.cols === this.dashlet.cols &&
        dashlet.rows === this.dashlet.rows &&
        dashlet.x === this.dashlet.x &&
        dashlet.y === this.dashlet.y
      );
    });
  }
}
