import { checkElement, createElement, range, TAnyFunction } from '@core/helpers';
import { OverlayScrollbars } from 'overlayscrollbars';
import { logErrorInSentry } from '../../errors/response-error';
import { createEmptyElement } from './create-empty-element';
import { TVirtualScrollerData, TVirtualScrollerOptions } from './virtual-scroller.model';

export default class VirtualScroller {
  data: TVirtualScrollerData[];
  columnWidths: number[];
  visibleXIndexes: number[] = [];
  visibleIndexes: number[] = [];
  marginBottom = 0;
  element: HTMLElement;
  dataElements: HTMLElement[];
  viewportWidth = 0;
  viewportHeight = 0;
  offsetX = 0;

  private parentElement: HTMLElement;
  private classList: string | string[];
  private id: string;
  private extraWidth: number;
  private frozenColumnWidth: number;
  private rowHeight: number;
  private createElementCallback: TAnyFunction;
  private createCellElementCallback: TAnyFunction;
  private scrollTopCallback: TAnyFunction;
  private scrollLeftCallback: TAnyFunction;
  scrollElement: HTMLElement;

  private maxVisibleElementsCount = 0;
  private visibleElementsCount = 0;
  private startNodeX = 0;
  private startNode = 0;
  private offsetY = 0;
  private scrollTopPx = 0;
  private scrollLeftPx = 0;

  private dataWrapperElement: HTMLElement;
  private viewportElement: HTMLElement;

  constructor(
    parentElement: HTMLElement,
    data: TVirtualScrollerData[],
    {
      className = '',
      columnWidths = [],
      extraWidth = 0,
      frozenColumnWidth = 0,
      rowHeight = 0,
      scrollPosition = 0,
      createElementCallback = null,
      createCellElementCallback = null,
      scrollTopCallback = null,
      scrollLeftCallback = null,
      id: id = null,
      marginBottom: marginBottom = 0,
      scrollbarTheme = 'os-theme-dark',
    }: TVirtualScrollerOptions = {},
  ) {
    this.parentElement = parentElement;
    this.data = data;
    this.classList = className;
    this.id = id;
    this.marginBottom = marginBottom;
    this.columnWidths = columnWidths;
    this.updateExtraWidth(extraWidth);
    this.updateFrozenColumnWidth(frozenColumnWidth);
    this.rowHeight = rowHeight;
    this.createElementCallback = createElementCallback;
    this.createCellElementCallback = createCellElementCallback;
    this.scrollTopCallback = scrollTopCallback;
    this.scrollLeftCallback = scrollLeftCallback;

    this.load(data);

    this.element = this.create();

    this.parentElement.appendChild(checkElement(this.element));

    this.disableSmoothScrolling();

    // Disable smooth scrolling doesn't instantly update HTML and there's a delay before it starts working which stops the scrollbar from scrolling to correct position
    setTimeout(() => {
      this.scrollTop(scrollPosition);
      this.enableSmoothScrolling();
    });

    this.createCustomScroller(scrollbarTheme);
  }

  private createCustomScroller(scrollbarTheme: string): void {
    const osInstance = OverlayScrollbars(
      {
        target: this.element,
      },
      {
        scrollbars: {
          theme: scrollbarTheme,
        },
      },
      {
        scroll: (instance, event) => {
          this.scrollEventListener(event as MouseEvent);
        },
      },
    );

    this.scrollElement = osInstance.elements().viewport;
  }

  scrollTo(elementIndex: number): void {
    if (!this.dataElements[elementIndex]) {
      this.addMissingElement(elementIndex);
    }

    const offset = elementIndex * this.rowHeight;

    if (elementIndex < this.visibleIndexes[0]) {
      this.scrollTop(offset - 2.6 * this.rowHeight);
    } else if (elementIndex > this.visibleIndexes[this.visibleIndexes.length - 1]) {
      this.scrollTop(offset - this.viewportHeight + 3.6 * this.rowHeight);
    }
  }

  scrollLeft(left: number): void {
    this.scrollElement.scrollLeft = left;
  }

  disableSmoothScrolling(): void {
    this.element.style.scrollBehavior = 'auto';
  }

  enableSmoothScrolling(): void {
    this.element.style.scrollBehavior = 'smooth';
  }

  updateExtraWidth(width: number): void {
    this.extraWidth = width;
  }

  updateWidth(width: number): void {
    if (this.dataWrapperElement) {
      this.dataWrapperElement.style.width = `${(width + this.extraWidth) / 10}em`;
    }
  }

  updateXvalues(reset?: boolean): void {
    let columnIndex = 0;
    const scrollLeft = this.scrollElement?.scrollLeft || 0;

    if (!reset) {
      let offsetX = this.columnWidths[columnIndex];

      while (offsetX <= scrollLeft - this.frozenColumnWidth) {
        columnIndex += 1;
        offsetX += this.columnWidths[columnIndex];
      }

      this.startNodeX = columnIndex;
      this.offsetX = offsetX - this.columnWidths[columnIndex];
    } else {
      this.startNodeX = 0;
      this.offsetX = 0;
    }

    this.updateVisibleXIndexes();
  }

  updateVisibleXIndexes(update: boolean = true): number[] {
    let columnIndex = this.startNodeX;
    const scrollLeft = this.scrollElement?.scrollLeft || 0;

    let visibleColumnWidths =
      this.offsetX + this.columnWidths[columnIndex] - scrollLeft + this.frozenColumnWidth;
    const visibleIndexes = [columnIndex];

    while (
      visibleColumnWidths <= this.viewportWidth &&
      columnIndex < this.columnWidths.length - 1
    ) {
      columnIndex += 1;
      visibleColumnWidths += this.columnWidths[columnIndex];

      visibleIndexes.push(columnIndex);
    }

    if (update) {
      this.visibleXIndexes = visibleIndexes;
    }

    return visibleIndexes;
  }

  updateFrozenColumnWidth(frozenColumnWidth: number): void {
    this.frozenColumnWidth = frozenColumnWidth;
  }

  load(data: TVirtualScrollerData[], keepScrollPosition: boolean = false): void {
    if (this.viewportElement) {
      while (this.viewportElement.firstChild) {
        this.viewportElement.removeChild(this.viewportElement.firstChild);
      }
    }

    this.updateData(data);
    this.updateViewportWidth();
    this.updateViewportHeight();

    if (!keepScrollPosition) {
      if (this.element) {
        this.scrollTop(0);
        this.scrollLeft(0);
      }

      this.updateXvalues(true);
      this.updateStartNode(0);
      this.updateOffsetY(0);
    } else {
      this.updateXvalues();
    }

    this.updateHeight();
    this.updateVisibleElementsCount();
    this.updateVisibleIndexes();

    if (this.element) {
      this.element.style.height = `${this.viewportHeight}px`;
    }

    for (let index = 0; index < this.visibleElementsCount; index += 1) {
      this.addMissingElement(index + this.startNode);

      if (this.viewportElement) {
        this.viewportElement.appendChild(checkElement(this.dataElements[index + this.startNode]));
      }
    }

    if (this.element && this.scrollLeftCallback) {
      this.scrollLeftCallback(this.element.scrollLeft);
    }
  }

  updateColumnWidths(columnWidths: number[]): void {
    this.columnWidths = columnWidths;
  }

  private create(): HTMLElement {
    return createElement<HTMLElement>('div', {
      attrs: {
        class: this.classList,
        id: this.id,
        style: {
          height: `${this.viewportHeight}px`,
          overflow: 'auto',
          scrollBehavior: 'smooth',
          scrollbarWidth: 'thin',
          scrollbarColor: '#535e63 !important',
        },
      },
      children: [this.createDataWrapperElement()],
    });
  }

  private createDataWrapperElement(): HTMLElement {
    this.dataWrapperElement = createElement('div', {
      attrs: {
        class: 'virtualScroller__dataWrapper',
        style: {
          height: `${this.dataElements.length * this.rowHeight}px`,
        },
      },
      children: [createEmptyElement(), this.createViewportWrapper()],
    });

    return this.dataWrapperElement;
  }

  private createViewportWrapper(): HTMLElement {
    const children = [];

    for (let index = 0; index < this.visibleElementsCount; index += 1) {
      children.push(this.dataElements[index]);
    }

    this.viewportElement = createElement('div', {
      attrs: {},
      children,
    });

    return this.viewportElement;
  }

  // --------------------------------------------------
  // --------------------- METHODS --------------------
  // --------------------------------------------------

  // Scroll

  handleVerticalScroll(): void {
    // Safari can scroll beyond possible client Height with bounce effect, we need to ignore that or lose elements
    if (
      this.scrollElement.scrollHeight - this.scrollElement.scrollTop <
      this.scrollElement.clientHeight
    ) {
      return;
    }

    const previousStartNode = this.startNode;
    const previousOffsetY = this.offsetY;

    this.updateStartNode();
    this.updateOffsetY();

    const startNodeDifference = Math.abs(previousStartNode - this.startNode);

    // Scroll down
    if (previousOffsetY < this.offsetY) {
      this.handleScrollDown(startNodeDifference);
    }

    // Scroll up
    if (previousOffsetY > this.offsetY) {
      this.handleScrollUp(startNodeDifference);
    }

    this.fixVerticalScroll(startNodeDifference);
    this.handleVerticalOffset(previousOffsetY);
  }

  private handleVerticalOffset(previousOffsetY: number): void {
    if (previousOffsetY !== this.offsetY) {
      this.viewportElement.style.transform = `translateY(${this.offsetY}px)`;

      this.updateVisibleIndexes();
    }
  }

  private handleScrollUp(startNodeDifference: number): void {
    let startIndex = startNodeDifference;

    if (startNodeDifference > this.visibleElementsCount) {
      startIndex = this.visibleElementsCount;

      while (this.viewportElement.firstChild) {
        this.viewportElement.removeChild(this.viewportElement.firstChild);
      }
    }

    for (let index = startIndex - 1; index >= 0; index -= 1) {
      const newElementIndex = this.startNode + index;

      if (!this.dataElements[newElementIndex]) {
        this.addMissingElement(newElementIndex);
      }

      this.viewportElement.insertBefore(
        this.dataElements[newElementIndex],
        this.viewportElement.firstChild,
      );

      if (startNodeDifference <= this.visibleElementsCount) {
        this.viewportElement.removeChild(this.viewportElement.lastChild);
      }
    }
  }

  private handleScrollDown(startNodeDifference: number): void {
    let startIndex = 0;

    // Clear all element if none of the existing elements are going to be visible after the scroll
    if (startNodeDifference > this.visibleElementsCount) {
      startIndex = startNodeDifference - this.visibleElementsCount;

      while (this.viewportElement.firstChild) {
        this.viewportElement.removeChild(this.viewportElement.firstChild);
      }
    }

    for (let index = startIndex; index < startNodeDifference; index += 1) {
      if (startNodeDifference <= this.visibleElementsCount && this.viewportElement.firstChild) {
        this.viewportElement.removeChild(this.viewportElement.firstChild);
      }

      // first
      const startNodeTemp = this.startNode - startNodeDifference + 1;
      // last
      const newElementIndex = startNodeTemp + this.visibleElementsCount - 1 + index;

      if (!this.dataElements[newElementIndex]) {
        this.addMissingElement(newElementIndex);
      }

      if (this.dataElements[newElementIndex]) {
        this.viewportElement.appendChild(checkElement(this.dataElements[newElementIndex]));
      }
    }
  }

  private handleHorizontalScroll(): void {
    const previousStartNodeX = this.startNodeX;
    const previousOffsetX = this.offsetX;
    const previousFirstColumnIndex = this.visibleXIndexes[0];
    const previousLastColumnIndex = this.visibleXIndexes[this.visibleXIndexes.length - 1];

    this.updateXvalues();

    const startNodeXDifference = Math.abs(previousStartNodeX - this.startNodeX);
    const endNodeXDifference = Math.abs(
      previousLastColumnIndex - this.visibleXIndexes[this.visibleXIndexes.length - 1],
    );

    // Scroll right
    if (
      previousFirstColumnIndex < this.visibleXIndexes[0] ||
      previousLastColumnIndex < this.visibleXIndexes[this.visibleXIndexes.length - 1]
    ) {
      this.handleScrollRight(previousLastColumnIndex, startNodeXDifference, endNodeXDifference);
    }

    // Scroll left
    if (
      previousFirstColumnIndex > this.visibleXIndexes[0] ||
      previousLastColumnIndex > this.visibleXIndexes[this.visibleXIndexes.length - 1]
    ) {
      this.handleScrollLeft(previousFirstColumnIndex, startNodeXDifference, endNodeXDifference);
    }

    this.handleHorizontalOffset(previousOffsetX);

    this.fixHorizontalScroll();
  }

  private handleHorizontalOffset(previousOffsetX: number): void {
    if (previousOffsetX !== this.offsetX) {
      this.dataElements.forEach((element) => {
        if (element) {
          const columnCellsElement = element.children[1] as HTMLElement;

          if (columnCellsElement) {
            columnCellsElement.style.transform = `translateX(${this.offsetX}px)`;
          }
        }
      });
    }
  }

  private handleScrollLeft(
    previousFirstColumnIndex: number,
    startNodeXDifference: number,
    endNodeXDifference: number,
  ): void {
    this.dataElements.forEach((element, dataElementIndex) => {
      if (element) {
        if (element.classList.contains('table__row--empty')) {
          return;
        }

        const columnCellsElement = element.children[1] as HTMLElement;

        // NOTE: points vs. empty and header
        if (columnCellsElement) {
          if (this.visibleXIndexes[this.visibleXIndexes.length - 1] < previousFirstColumnIndex) {
            while (columnCellsElement.firstChild) {
              columnCellsElement.removeChild(columnCellsElement.firstChild);
            }

            for (let index = 0; index < this.visibleXIndexes.length; index += 1) {
              columnCellsElement.appendChild(
                checkElement(
                  this.createCellElementCallback(dataElementIndex, this.visibleXIndexes[index]),
                ),
              );
            }
          } else {
            for (let index = startNodeXDifference - 1; index >= 0; index -= 1) {
              columnCellsElement.insertBefore(
                this.createCellElementCallback(dataElementIndex, this.startNodeX + index),
                columnCellsElement.firstChild,
              );
            }

            for (let index = endNodeXDifference - 1; index >= 0; index -= 1) {
              columnCellsElement.removeChild(columnCellsElement.lastChild);
            }
          }
        }
      }
    });
  }

  private handleScrollRight(
    previousLastColumnIndex: number,
    startNodeXDifference: number,
    endNodeXDifference: number,
  ): void {
    this.dataElements.forEach((element, dataElementIndex) => {
      if (element) {
        if (element.classList && element.classList.contains('table__row--empty')) {
          return;
        }

        let columnCellsElement = element.children[1] as HTMLElement;

        if (!columnCellsElement) {
          columnCellsElement = element.children[0] as HTMLElement;
        }

        // NOTE: points vs. empty and header
        if (columnCellsElement) {
          if (this.visibleXIndexes[0] > previousLastColumnIndex) {
            while (columnCellsElement.firstChild) {
              columnCellsElement.removeChild(columnCellsElement.firstChild);
            }

            for (let index = 0; index < this.visibleXIndexes.length; index += 1) {
              if (columnCellsElement instanceof HTMLElement) {
                columnCellsElement.appendChild(
                  checkElement(
                    this.createCellElementCallback(dataElementIndex, this.visibleXIndexes[index]),
                  ),
                );
              } else {
                logErrorInSentry(
                  new Error(`${columnCellsElement} is not an HTMLElement - Virtual Scroller`),
                );
              }
            }
          } else {
            for (let index = 0; index < startNodeXDifference; index += 1) {
              if (!columnCellsElement.firstChild) {
                console.log(columnCellsElement);
                throw new Error();
              }

              if (columnCellsElement instanceof HTMLElement) {
                columnCellsElement.removeChild(columnCellsElement.firstChild);
              } else {
                logErrorInSentry(
                  new Error(`${columnCellsElement} is not an HTMLElement - Virtual Scroller`),
                );
              }
            }

            for (let index = 0; index < endNodeXDifference; index += 1) {
              if (columnCellsElement instanceof HTMLElement) {
                columnCellsElement.appendChild(
                  checkElement(
                    this.createCellElementCallback(
                      dataElementIndex,
                      this.visibleXIndexes[
                        this.visibleXIndexes.length - endNodeXDifference + index
                      ],
                    ),
                  ),
                );
              } else {
                logErrorInSentry(
                  new Error(`${columnCellsElement} is not an HTMLElement - Virtual Scroller`),
                );
              }
            }
          }
        }
      }
    });
  }

  private fixHorizontalScroll(): void {
    let error = false;

    this.dataElements.forEach((element, dataElementIndex) => {
      if (element) {
        const columnCellsElement = element.children[1] as HTMLElement;

        if (
          columnCellsElement &&
          columnCellsElement.children.length !== this.visibleXIndexes.length
        ) {
          error = true;

          for (
            let index = columnCellsElement.children.length;
            index < this.visibleXIndexes.length;
            index++
          ) {
            columnCellsElement.appendChild(
              checkElement(
                this.createCellElementCallback(dataElementIndex, this.visibleXIndexes[index]),
              ),
            );
          }
        }
      }
    });

    if (error) {
      logErrorInSentry('Missing elements in horizontal scroll');
    }
  }

  private fixVerticalScroll(startNodeDifference: number): void {
    if (
      this.visibleElementsCount !== this.viewportElement.children.length &&
      this.visibleElementsCount > this.viewportElement.children.length
    ) {
      const newStartNode = this.startNode;

      while (this.viewportElement.firstChild) {
        this.viewportElement.removeChild(this.viewportElement.firstChild);
      }

      for (let i = 0; i <= this.visibleElementsCount; i += 1) {
        const newElementIndex = newStartNode + (this.visibleElementsCount - 1) - i;

        if (!this.dataElements[newElementIndex]) {
          this.addMissingElement(newElementIndex);
        }

        this.viewportElement.insertBefore(
          this.dataElements[newElementIndex],
          this.viewportElement.firstChild,
        );

        if (startNodeDifference <= this.visibleElementsCount) {
          this.viewportElement.removeChild(this.viewportElement.lastChild);
        }
      }

      for (let index = 0; index < this.visibleElementsCount; index++) {
        this.addMissingElement(index + this.startNode);

        if (this.viewportElement) {
          this.viewportElement.appendChild(checkElement(this.dataElements[index + this.startNode]));
        }
      }
    }
  }

  scrollEventListener(event: MouseEvent): { scrollTop: number; scrollLeft: number } {
    const target = event.target as HTMLElement;

    if (this.scrollTopPx !== target.scrollTop) {
      this.scrollTopPx = target.scrollTop;

      this.handleVerticalScroll();

      if (this.scrollTopCallback) {
        this.scrollTopCallback(event); // entire event is more useful as callback here
      }
    }

    if (this.scrollLeftPx !== target.scrollLeft) {
      this.scrollLeftPx = target.scrollLeft;

      this.handleHorizontalScroll();

      if (this.scrollLeftCallback) {
        this.scrollLeftCallback(this.scrollLeftPx);
      }
    }
    return { scrollTop: this.scrollTopPx, scrollLeft: this.scrollLeftPx };
  }

  private scrollTop(top: number): void {
    this.scrollElement.scrollTop = top;
  }

  // Updating

  private updateViewportWidth(): void {
    this.viewportWidth = this.parentElement.offsetWidth;
  }

  private updateViewportHeight(): void {
    let siblingsHeight = 0;

    Array.from(this.parentElement.children).forEach((child: HTMLElement) => {
      if (child !== this.element) {
        const childStyle = window.getComputedStyle(child);

        if (childStyle.position !== 'absolute') {
          siblingsHeight +=
            child.offsetHeight +
            +childStyle.marginTop.replace(/\D/g, '') +
            +childStyle.marginBottom.replace(/\D/g, '');
        }
      }
    });

    this.viewportHeight = this.parentElement.offsetHeight - siblingsHeight - this.marginBottom;
  }

  private updateHeight(): void {
    if (this.dataWrapperElement) {
      this.dataWrapperElement.style.height = `${
        (this.dataElements.length * this.rowHeight) / 10
      }em`;
    }
  }

  private updateVisibleElementsCount(): void {
    const maxVisibleElementsOffset = 1; // avoids clipping when scrolling so there's never a gap between the last element and the bottom of the viewport

    this.maxVisibleElementsCount =
      Math.ceil(this.viewportHeight / this.rowHeight) + maxVisibleElementsOffset;
    this.visibleElementsCount =
      this.data.length < this.maxVisibleElementsCount
        ? this.data.length
        : this.maxVisibleElementsCount;
    this.visibleElementsCount = Math.max(0, this.visibleElementsCount);
  }

  private updateStartNode(startNode?: number): void {
    if (startNode === undefined) {
      this.startNode = Math.floor(this.scrollElement.scrollTop / this.rowHeight);
      this.startNode = Math.max(0, this.startNode);
    } else {
      this.startNode = startNode;
    }
  }

  private updateOffsetY(offsetY?: number): void {
    if (offsetY === undefined) {
      this.offsetY = this.startNode * this.rowHeight;
    } else {
      this.offsetY = offsetY;
    }

    if (this.viewportElement) {
      this.viewportElement.style.transform = `translateY(${this.offsetY}px)`;
    }
  }

  private updateVisibleIndexes(): void {
    this.visibleIndexes = range(this.startNode, this.visibleElementsCount);
  }
  private updateData(data: TVirtualScrollerData[]): void {
    this.data = data;
    this.dataElements = new Array(this.data.length).fill(null);
  }

  private addMissingElement(index: number): void {
    this.dataElements[index] = this.createElementCallback(index);
  }
}
