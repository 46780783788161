<div class="newSiteDropdown">
  <h5 class="newSitesFilter__header">Filter by sites</h5>

  <div class="newSiteDropdown__search">
    <pp-search-box
      ppPlaceholder="Select sites"
      (ppAction)="updateSearchBox($event)"
      ppSize="wide"
    ></pp-search-box>
  </div>

  <ul class="dropdownWrapper sites__list">
    <div
      class="list__wrapper"
      #list
      (scroll)="handleScroll($event)"
      (ppOnRendered)="onListRendered()"
    >
      <li *ngFor="let account of filteredAccount">
        <div
          class="accountRow"
          [class.accountRow__checked]="selectedAccount === account.accountId"
          (click)="selectAccount(account.accountId)"
        >
          <div class="sites__caret--wrapper">
            <img
              class="sites__caret"
              [class.caret--inactive]="!expandedAccounts.includes(account.accountId)"
              (click)="toggleAccount(account.accountId, $event)"
              [src]="EIconPath.INTERACTION_CARET_DOWN_GRAY"
            />
          </div>

          <span>
            {{ account.accountName }}
          </span>
        </div>

        <ul *ngIf="expandedAccounts.includes(account.accountId)" class="sites__inner-list">
          <li
            class="workspaceRow"
            [class.workspaceRow__checked]="workspaceId === selectedSite"
            *ngFor="
              let workspaceId of filteredSelectableWorkspaces[account.accountId];
              let i = index
            "
            (click)="selectSite(workspaceId)"
          >
            <div ppStyle="blue">
              {{ workspaces[workspaceId].siteName }}
            </div>
          </li>
        </ul>
      </li>

      <div class="newSitesFilter__no-results" *ngIf="filteredAccount.length === 0">
        {{ 'no_search_result' | ppTranslate }}
      </div>
    </div>
  </ul>
</div>

<div class="newSiteDropdown__footer">
  <div *ngIf="!scrolledToBottom" class="optionsDropdown__shadow">&nbsp;</div>

  <div class="optionsDropdown__shadow-footer-buttons">
    <pp-button ppStyle="secondary" (ppAction)="clearSelectedSites()">
      {{ 'clear_all' | ppTranslate }}
    </pp-button>

    <pp-button ppStyle="primary" (ppAction)="hideDropdown()">
      {{ 'done' | ppTranslate }}
    </pp-button>
  </div>
</div>
