import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import * as FileSaver from 'file-saver';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { PromptService } from '../components/prompt/prompt.service';
import { TranslationPipe } from '../features/translate/translation.pipe';
import { logErrorInSentry } from '../modules/errors/response-error';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(
    private apiService: ApiService,
    private promptService: PromptService,
    private translationPipe: TranslationPipe,
  ) {}

  fromRequest(url: string, body?, promptErrorText?: string): Observable<Response> {
    return this.apiService.download(url, body).pipe(
      tap((response: Response) => {
        const contentType = response.headers.get('Content-type');

        // Errors like 429 still are returned in tap
        if (!response.status.toString().startsWith('20')) {
          return this.handleDownloadError(response, promptErrorText);
        } else if (response.status === EStatusCode.CREATED) {
          if (contentType !== 'application/json') {
            return this.saveFileFromResponse(response);
          }
        }
      }),
      catchError((error) => this.handleDownloadError(error, promptErrorText)),
    );
  }

  private handleDownloadError(error: any, promptErrorText: string): Observable<any> {
    logErrorInSentry(error);

    if (error.status === EStatusCode.TOO_MANY_REQUESTS) {
      const promptText = this.translationPipe.transform('prompt_export_busy');

      this.promptService.showWarning(promptText, { duration: 15 });
    } else {
      const promptText = promptErrorText || this.translationPipe.transform('prompt_export_error');

      this.promptService.showError(promptText);
    }

    return of(null);
  }

  downloadGET({
    url,
    promptErrorText,
    fileName,
  }: {
    url: string;
    promptErrorText?: string;
    fileName?: string;
  }): Observable<Response> {
    return this.apiService.downloadGET(url).pipe(
      tap((response) => {
        const contentType = response.headers.get('Content-type');

        if (response.status === EStatusCode.CREATED || response.status === EStatusCode.OK) {
          if (contentType !== 'application/json') {
            return this.saveFileFromResponse(response, fileName);
          }
        }
      }),
      catchError((error) => {
        logErrorInSentry(error);
        console.error(error);

        if (error.status === EStatusCode.TOO_MANY_REQUESTS) {
          const promptText = this.translationPipe.transform('prompt_export_busy');

          this.promptService.showWarning(promptText, { duration: 15 });
        } else {
          const promptText =
            promptErrorText || this.translationPipe.transform('prompt_export_error');

          this.promptService.showError(promptText);
        }

        return of(null);
      }),
    );
  }

  saveFile(file: Blob | string, filename: string): void {
    FileSaver.saveAs(file, filename);
  }

  private getFileName(contentDisposition: string): string {
    return contentDisposition.substring(
      contentDisposition.indexOf('"') + 1,
      contentDisposition.lastIndexOf('"'),
    );
  }

  private saveFileFromResponse(response: Response, fileName?: string): Promise<void> {
    const name = fileName || this.getFileName(response.headers.get('content-disposition'));

    return response.blob().then((blob) => {
      this.saveFile(blob, name);
    });
  }
}
