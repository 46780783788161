import { Component, OnChanges, Input } from '@angular/core';
import {TClassList} from '@core/helpers';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnChanges {
  @Input() ppLoader: boolean;
  @Input() ppButton: boolean;
  @Input() ppHidden: boolean;
  @Input() ppLight: boolean;
  @Input() ppInput: boolean;
  @Input() ppInputTitle: boolean;
  @Input() ppSelectSpacing: boolean;
  @Input() ppInputSync: boolean;
  @Input() ppUsersList: boolean;
  @Input() ppNotification: boolean;
  @Input() ppDashboard: boolean;

  class: TClassList;

  constructor() {}

  ngOnChanges() {
    this.class = {
      'spinner--loader': this.ppLoader,
      'spinner--button': this.ppButton,
      'spinner--hidden': this.ppHidden,
      'spinner--light': this.ppLight,
      'spinner--input': this.ppInput,
      'spinner--inputTitle': this.ppInputTitle,
      'spinner--select': this.ppSelectSpacing,
      'spinner--inputSync': this.ppInputSync,
      'spinner--usersList': this.ppUsersList,
      'spinner--notification': this.ppNotification,
      'spinner--dashboard': this.ppDashboard,
    };
  }
}
