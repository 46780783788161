import { TFilters, TTagsFilter } from '../site-filter.model';

export function clearExcludedTags(filters: TFilters): TTagsFilter {
  filters.excludedTags.value = [];
  filters.excludedTags.appliedValue = [];

  filters.excludedTags.combinedTags = false;

  return filters.excludedTags;
}
