<pp-modal [zIndex]="zIndex" [ppNoFooter]="true" [ppNoPadding]="true" [ppWidth]="462">
  <ng-container heading>
    {{ '2fa_modal_header' | ppTranslate }}
  </ng-container>

  <ng-container body>
    <div class="userTwoFactorAuthenticationModalBody">
      <section class="userTwoFactorAuthenticationModalBody__description">
        <h4>{{ '2fa_code_header' | ppTranslate }}</h4>

        <p class="userTwoFactorAuthenticationModalBody__text">
          {{ '2fa_code_description_1' | ppTranslate }}
          <span class="userTwoFactorAuthenticationModalBody__number">{{
            modalData.phoneNumber
          }}</span>
          {{ '2fa_code_description_2' | ppTranslate }}
        </p>
      </section>

      <section class="userTwoFactorAuthenticationModalBody__code">
        <pp-digit-input
          [ppDigitsNumber]="codeLength"
          [(ppInput)]="code"
          (ppInputChange)="updateCode($event)"
          [ppShowingError]="modalData.showingError"
          (ppConfirm)="callback()"
        ></pp-digit-input>

        <pp-two-factor-authentication-confirm-code-button
          class="userTwoFactorAuthenticationModalBody__button"
          [ppCode]="code"
          [ppCodeLength]="codeLength"
          (ppConfirm2FA)="callback()"
        ></pp-two-factor-authentication-confirm-code-button>
      </section>

      <div class="login__row">
        <pp-countdown
          ppTranslationKey="2fa_resend_code_1"
          ppTranslationKeyPrimaryColor="2fa_resend_code_2"
          [ppDurationSeconds]="60"
          (ppCallback)="resend()"
        ></pp-countdown>
      </div>
    </div>
  </ng-container>
</pp-modal>
