import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';

import { PointAttachmentsService } from '../../modules/points/point-modal/point-attachments/point-attachments.service';

@Injectable({
  providedIn: 'root',
})
export class AttachmentUploadGuardService {
  isUploadingAttachment = false;

  constructor(private pointAttachmentsService: PointAttachmentsService) {
    this.pointAttachmentsService.fileUploading$.subscribe((isUploading) => {
      this.isUploadingAttachment = isUploading;
    });
  }

  canActivate(next: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean {
    if (this.isUploadingAttachment) {
      return false;
    }

    return true;
  }
}

export const AttachmentUploadGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): boolean => {
  return inject(AttachmentUploadGuardService).canActivate(next, state);
};
