<pp-settings-container>
  <pp-settings-header>
    <header class="settingsSite__header">
      <div class="settingsSite__siteName">
        <pp-account-name [ppAccount]="account" [ppDisabled]="isAccountAdmin"></pp-account-name>
      </div>

      <div class="settingsAccount__lowerBar">
        <pp-account-settings-tabs [ppAccountId]="accountId"></pp-account-settings-tabs>

        <pp-account-user-management-header-buttons
          [ppAccountId]="account?.accountId"
          *ngIf="url.includes(EAccountSettingsTabPath.USER_MANAGEMENT)"
        ></pp-account-user-management-header-buttons>

        <pp-account-sites-header-buttons
          [ppAccountId]="account?.accountId"
          *ngIf="url.includes(EAccountSettingsTabPath.SITES)"
        ></pp-account-sites-header-buttons>
      </div>
    </header>
  </pp-settings-header>

  <ng-container>
    <router-outlet></router-outlet>
  </ng-container>
</pp-settings-container>
