import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RemindersService } from '../reminders/reminders.service';
import { SiteOverviewService } from '../site/site-overview/site-overview.service';
import { SiteTimelineService } from '../site/site-timeline/site-timeline.service';
import { TWorkspacesById } from '../workspace/workspace.model';
import { EWorkspaces } from '../workspace/workspaces.enum';
import { CheckFilterService } from './check-filter/check-filter.service';
import { DefaultFilterService } from './default-filter.service';
import { SetSiteFilter } from './site-filter.actions';
import { TAllFilters, TFilters } from './site-filter.model';

@Injectable({
  providedIn: 'root',
})
export class CorrectFilterService {
  constructor(
    private store: Store,
    private siteOverviewService: SiteOverviewService,
    private siteTimelineService: SiteTimelineService,
    private remindersService: RemindersService,
    private filterDatabaseService: CheckFilterService,
    private defaultFilterService: DefaultFilterService,
  ) {}

  correctFilter({
    workspaces,
    currentWorkspaceId,
    allFilters,
    savedFilter,
    filters,
  }: {
    workspaces: TWorkspacesById;
    currentWorkspaceId: string;
    allFilters: TAllFilters;
    savedFilter: TAllFilters;
    filters: TFilters;
  }): void {
    if (workspaces && Object.keys(workspaces).length > 0) {
      if (allFilters?.[currentWorkspaceId]) {
        this.updateExistingFilter(currentWorkspaceId, allFilters, savedFilter, filters);
      } else if (savedFilter?.[currentWorkspaceId]) {
        this.correctSavedFilter(savedFilter, currentWorkspaceId, filters, allFilters);
      }
    }
  }

  private updateExistingFilter(
    currentWorkspaceId: string,
    allFilters: TAllFilters,
    savedFilter: TAllFilters,
    filters: TFilters,
  ): void {
    switch (currentWorkspaceId) {
      case EWorkspaces.OVERVIEW:
        this.updateOverviewFilter(allFilters);
        break;
      case EWorkspaces.TIMELINE:
        this.updateTimelineFilter(allFilters);
        break;
      case EWorkspaces.REMINDERS:
        this.updateRemindersFilter(allFilters);
        break;
      default:
        this.updateDefaultFilter(allFilters, currentWorkspaceId, savedFilter, filters);
        break;
    }
  }

  private updateDefaultFilter(
    allFilters: TAllFilters,
    currentWorkspaceId: string,
    savedFilter: TAllFilters,
    filters: TFilters,
  ): void {
    if (!allFilters?.[currentWorkspaceId]) {
      this.defaultFilterService.setDefaultFilter(allFilters, currentWorkspaceId);
    } else {
      const correctedFilter = this.filterDatabaseService.checkSavedFilters(
        savedFilter,
        currentWorkspaceId,
        filters,
      );

      if (correctedFilter?.[currentWorkspaceId]) {
        this.store.dispatch(
          new SetSiteFilter({
            filter: correctedFilter[currentWorkspaceId],
            workspaceId: currentWorkspaceId,
          }),
        );
      }
    }
  }

  private updateRemindersFilter(allFilters: TAllFilters): void {
    if (!allFilters[EWorkspaces.REMINDERS]) {
      this.remindersService.updateRemindersFilter();
    } else {
      this.store.dispatch(
        new SetSiteFilter({
          filter: allFilters[EWorkspaces.REMINDERS],
          workspaceId: EWorkspaces.REMINDERS,
        }),
      );
    }
  }

  private updateTimelineFilter(allFilters: TAllFilters): void {
    if (!allFilters[EWorkspaces.TIMELINE]) {
      this.siteTimelineService.updateTimelineFilter();
    } else {
      this.store.dispatch(
        new SetSiteFilter({
          filter: allFilters[EWorkspaces.TIMELINE],
          workspaceId: EWorkspaces.TIMELINE,
        }),
      );
    }
  }

  private updateOverviewFilter(allFilters: TAllFilters): void {
    if (!allFilters[EWorkspaces.OVERVIEW]) {
      this.siteOverviewService.updateOverviewFilter();
    } else {
      this.store.dispatch(
        new SetSiteFilter({
          filter: allFilters[EWorkspaces.OVERVIEW],
          workspaceId: EWorkspaces.OVERVIEW,
        }),
      );
    }
  }

  private correctSavedFilter(
    savedFilter: TAllFilters,
    currentWorkspaceId: string,
    filters: TFilters,
    allFilters: TAllFilters,
  ): void {
    const correctedFilter = this.filterDatabaseService.checkSavedFilters(
      savedFilter,
      currentWorkspaceId,
      filters,
    );

    switch (currentWorkspaceId) {
      case EWorkspaces.OVERVIEW:
        this.correctOverviewFilter(correctedFilter);
        break;
      case EWorkspaces.TIMELINE:
        this.correctTimelineFilter(correctedFilter);
        break;
      case EWorkspaces.REMINDERS:
        this.correctRemindersFilter(correctedFilter);
        break;
      default:
        this.correctDefaultFilter(correctedFilter, currentWorkspaceId, allFilters);
        break;
    }
  }

  private correctDefaultFilter(
    correctedFilter: TAllFilters,
    currentWorkspaceId: string,
    allFilters: TAllFilters,
  ): void {
    if (!correctedFilter?.[currentWorkspaceId]) {
      this.defaultFilterService.setDefaultFilter(allFilters, currentWorkspaceId);
    } else {
      this.store.dispatch(
        new SetSiteFilter({
          filter: correctedFilter[currentWorkspaceId],
          workspaceId: currentWorkspaceId,
        }),
      );
    }
  }

  private correctRemindersFilter(correctedFilter: TAllFilters): void {
    if (!correctedFilter?.[EWorkspaces.REMINDERS]) {
      this.remindersService.updateRemindersFilter();
    } else {
      this.store.dispatch(
        new SetSiteFilter({
          filter: correctedFilter[EWorkspaces.REMINDERS],
          workspaceId: EWorkspaces.REMINDERS,
        }),
      );
    }
  }

  private correctTimelineFilter(correctedFilter: TAllFilters): void {
    if (!correctedFilter?.[EWorkspaces.TIMELINE]) {
      this.siteTimelineService.updateTimelineFilter();
    } else {
      this.store.dispatch(
        new SetSiteFilter({
          filter: correctedFilter[EWorkspaces.TIMELINE],
          workspaceId: EWorkspaces.TIMELINE,
        }),
      );
    }
  }

  private correctOverviewFilter(correctedFilter: TAllFilters): void {
    if (!correctedFilter?.[EWorkspaces.OVERVIEW]) {
      this.siteOverviewService.updateOverviewFilter();
    } else {
      this.store.dispatch(
        new SetSiteFilter({
          filter: correctedFilter[EWorkspaces.OVERVIEW],
          workspaceId: EWorkspaces.OVERVIEW,
        }),
      );
    }
  }
}
