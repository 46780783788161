import { Component, OnInit } from '@angular/core';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { TPoint } from 'src/app/project/modules/points/points.model';
import { TUser } from 'src/app/project/modules/user/user.model';

import { DeletePointModalComponent } from 'src/app/project/modules/points/delete-point-modal/delete-point-modal.component';
import { PointsCopyMoveModalComponent } from 'src/app/project/modules/site/site-options/points-copy-move-modal/points-copy-move-modal.component';

import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PointExportModalComponent } from 'src/app/project/modules/exports/point-export-modal/point-export-modal.component';
import { TExportModalData } from 'src/app/project/modules/exports/utils/export-modal-data';
import { ECopyMoveModalType } from 'src/app/project/modules/site/site-options/points-copy-move-modal/copy-move-modal-type..enum';
import { TPointsCopyMoveModalData } from 'src/app/project/modules/site/site-options/points-copy-move-modal/points-copy-move-modal.model';
import { UserService } from 'src/app/project/modules/user/user.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { WindowService } from '@core/services';

@Component({
  selector: 'pp-point-action-bar-options-dropdown',
  templateUrl: './point-action-bar-options-dropdown.component.html',
  styleUrls: ['./point-action-bar-options-dropdown.component.scss'],
})
export class PointActionBarOptionsDropdownComponent implements OnInit {
  dropdown: TDropdown = this.dropdownService.getDropdown();
  point: TPoint = null;
  isFull = false;
  user: TUser;

  canExport = true;
  canMove = false;
  canCopy = false;
  canDelete = false;
  EIconPath = EIconPath;

  constructor(
    private dropdownService: DropdownService,
    private modalService: ModalService,
    private userService: UserService,
    private windowService: WindowService,
  ) {}

  ngOnInit() {
    if (!this.dropdown.data) {
      throw new Error('Data for this dropdown is required');
    }

    this.point = this.dropdown.data.point;
    this.isFull = this.dropdown.data.isFull;
    this.user = this.userService.getUser();

    this.canExport = this.dropdown.data.canExport;
    this.canMove = this.dropdown.data.canMove;
    this.canCopy = this.dropdown.data.canCopy;
    this.canDelete = this.dropdown.data.canDelete;
  }

  hideDropdown(): void {
    this.dropdownService.hideDropdown();
  }

  export(): void {
    const modalData: TExportModalData = {
      workspaceId: this.point.workspaceRef.id,
      _id: this.point._id,
      pointExport: true,
      formats: {
        pdf: true,
        word: true,
      },
    };

    this.modalService.setData(modalData);

    if (this.isFull) {
      this.modalService.showModal(PointExportModalComponent, { blur: false });
    } else {
      this.modalService.showModal(PointExportModalComponent, { blur: true });
    }

    this.hideDropdown();
  }

  deletePoint(): void {
    this.modalService.setData(this.point._id);

    if (this.isFull) {
      this.modalService.showModal(DeletePointModalComponent, { blur: false });
    } else {
      this.modalService.showModal(DeletePointModalComponent, { blur: true });
    }

    this.hideDropdown();
  }

  copyPoint(): void {
    this.modalService.setData<TPointsCopyMoveModalData>({
      selectedPoint: this.point,
      modalType: ECopyMoveModalType.COPY,
      workspaceId: this.point.workspaceRef.id,
    });

    this.modalService.showModal(PointsCopyMoveModalComponent, {
      closeWarning: true,
    });

    this.hideDropdown();
  }

  movePoint(): void {
    this.modalService.setData<TPointsCopyMoveModalData>({
      selectedPoint: this.point,
      modalType: ECopyMoveModalType.MOVE,
      workspaceId: this.point.workspaceRef.id,
    });

    this.modalService.showModal(PointsCopyMoveModalComponent, {
      closeWarning: true,
    });

    this.hideDropdown();
  }

  linkPoint(): void {
    const root = this.windowService.getGlobalObject().location.origin;
    const workspaceId = this.point.workspaceRef.id;
    const linkText = root + '/#/site/' + workspaceId + '/(full:point/' + this.point._id + ')';
    navigator.clipboard.writeText(linkText);
    this.hideDropdown();
  }
}
