import { TShare } from '../share.model';

export function checkRequestChangedAAL(share: TShare, originalShare: TShare): boolean {
  return (
    checkCustomFieldsChanged(share, originalShare) ||
    checkTagsChanged(share, originalShare) ||
    checkCommentsChanged(share, originalShare) ||
    checkExportsChanged(share, originalShare) ||
    checkSitePlanChanged(share, originalShare) ||
    checkTimelineChanged(share, originalShare)
  );
}

function checkCustomFieldsChanged(share: TShare, originalShare: TShare): boolean {
  let changedCustomFieldsAAL = false;

  share.advancedAccessLevels.customFields.forEach((customField, index) => {
    if (!originalShare.advancedAccessLevels?.customFields) {
      changedCustomFieldsAAL = true;
    } else if (!originalShare.advancedAccessLevels?.customFields[index]) {
      changedCustomFieldsAAL = true;
    } else {
      if (
        share.advancedAccessLevels.customFields[index].permission.read !==
        originalShare.advancedAccessLevels?.customFields[index].permission.read
      ) {
        changedCustomFieldsAAL = true;
      }

      if (
        share.advancedAccessLevels.customFields[index].permission.edit !==
        originalShare.advancedAccessLevels?.customFields[index].permission.edit
      ) {
        changedCustomFieldsAAL = true;
      }
    }
  });

  return changedCustomFieldsAAL;
}

function checkTagsChanged(share: TShare, originalShare: TShare): boolean {
  return (
    share.advancedAccessLevels.tags.permission.edit !==
      originalShare.advancedAccessLevels?.tags.permission.edit ||
    share.advancedAccessLevels.tags.permission.read !==
      originalShare.advancedAccessLevels?.tags.permission.read
  );
}

function checkCommentsChanged(share: TShare, originalShare: TShare): boolean {
  return (
    share.advancedAccessLevels.timeline?.comments.permission.edit !==
      originalShare.advancedAccessLevels?.timeline?.comments.permission.edit ||
    share.advancedAccessLevels.timeline?.comments.permission.read !==
      originalShare.advancedAccessLevels?.timeline?.comments.permission.read
  );
}

function checkExportsChanged(share: TShare, originalShare: TShare): boolean {
  return (
    share.advancedAccessLevels?.exports?.permission.read !==
    originalShare.advancedAccessLevels?.exports?.permission.read
  );
}

function checkSitePlanChanged(share: TShare, originalShare: TShare): boolean {
  return (
    share.advancedAccessLevels?.sitePlan?.permission.read !==
    originalShare.advancedAccessLevels?.sitePlan?.permission.read
  );
}

function checkTimelineChanged(share: TShare, originalShare: TShare): boolean {
  return (
    share.advancedAccessLevels.timeline?.permission.read !==
    originalShare.advancedAccessLevels?.timeline?.permission.read
  );
}
