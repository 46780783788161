import { Component } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-point-export-warning',
  templateUrl: './point-export-warning.component.html',
  styleUrls: ['./point-export-warning.component.scss'],
})
export class PointExportWarningComponent {
  EIconPath = EIconPath;
}
