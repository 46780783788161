import { translate } from 'src/app/project/features/translate/translate';
import { EUserRole } from './user-roles';

export function humanizeShareOption(role: EUserRole): string {
  switch (role) {
    case EUserRole.OWNER:
      return translate('owner');
    case EUserRole.ACCOUNT_ADMIN:
      return translate('account_admin');
    case EUserRole.SITE_ADMIN:
      return translate('site_admin');
    case EUserRole.NORMAL:
      return translate('normal');
    case EUserRole.LIMITED:
      return translate('guest');
    default:
      return '';
  }
}
