import { GET_TABLE } from '../../table.ui.store';
import { createElement } from 'src/app/core/helpers/dom';
import { createMarkedKeywordElement } from '../body-cells-search';
import { createCellElement } from '../createCellElement';
import { TColumn } from '../../columns/column.model';

export function createIdElement(_index: number, _column: TColumn): HTMLElement {
  const table = GET_TABLE();

  return createCellElement({
    index: _column.index,
    width: `${_column.width}px`,
    center: true,
    child: createElement('div', {
      attrs: {
        class: 'customSiteTable__text',
      },
      children: [createMarkedKeywordElement(table.points[_index].sequenceNumber.toString())],
    }),
  });
}
