import { TTimelineColumns } from './timeline-timeframes';
import { getMonthTimeframes } from './get-month-timeframes';
import { getMonthBottomElements } from './get-month-bottom-elements';
import { getMonthTopElements } from './get-month-top-elements';

// --------------------------------------------------
// --------------------- MONTH ----------------------
// --------------------------------------------------

export function setMonth(startYear: number, numberOfYears: number): TTimelineColumns {
  return {
    topWidth: 147,
    bottomWidth: 49,

    topElements: getMonthTopElements(startYear, numberOfYears),
    bottomElements: getMonthBottomElements(numberOfYears),
    timeframes: getMonthTimeframes(startYear, numberOfYears),
  };
}
