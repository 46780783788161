import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { CookieService } from '../cookie.service';
import { getAuthHeaders, getUserAuth } from 'src/app/core/http/user-auth';
import {
  AUTH_TOKEN_HEADER_NAME,
  USER_EMAIL_HEADER_NAME,
} from '../../shared/constants/auth.constants';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const httpMethod = req.method;
    const userAuth = getUserAuth();

    if (
      (httpMethod === 'PUT' || httpMethod === 'POST' || httpMethod === 'DELETE') &&
      userAuth?.token
    ) {
      // for not secured endpoints like login, /api/v1/auth/verify/**", "/api/v1/auth/password-reset/**", "/api/v1/auth/passwords/**",
      // cookie will be null so we need to add some placeholder value to header (it cannot be null)

      const authHeaders = getAuthHeaders();

      const modifiedReq = req.clone({
        headers: req.headers.set(AUTH_TOKEN_HEADER_NAME, authHeaders.token),
      });

      const finalReq = modifiedReq.clone({
        headers: modifiedReq.headers.set(USER_EMAIL_HEADER_NAME, authHeaders.email),
      });

      return next.handle(finalReq);
    } else {
      return next.handle(req);
    }
  }
}
