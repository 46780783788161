import { TPoint } from '../../../points/points.model';

export function getValues(_a: string | TPoint, _b: string | TPoint, _property: string): any {
  // TODO Make a typesafe way to get properties from a point
  const properties = _property.split('.');
  let aValue = _a;
  let bValue = _b;

  properties.forEach((__property) => {
    aValue = aValue[__property];
    bValue = bValue[__property];
  });

  return {
    a: aValue,
    b: bValue,
  };
}
