import { TPoint } from 'src/app/project/modules/points/points.model';
import { TFilters } from '../site-filter.model';

export function checkStatus(point: TPoint, filters: TFilters): boolean {
  for (let index = 0; index < filters.status.length; index++) {
    const filterStatus = filters.status[index];
    const status = point.status;

    if (filterStatus.label === status && filterStatus.appliedValue) {
      return true;
    }
  }

  return false;
}
