import { createElement } from 'src/app/core/helpers/dom';
import { ELocalStorageItems } from 'src/app/project/shared/enums/local-storage-items.enum';

import { GET_TIMELINE } from '../timeline.ui.store';

export function createTimelineResizeElement(): HTMLElement {
  const themeWrapper = document.querySelector('body');

  const resizeElement = createElement('div', {
    attrs: {
      class: 'timeline__groupedTitleCell--resize',
    },
    eventListeners: {
      mousedown: (_event) => {
        _event.preventDefault();
        _event.stopPropagation();

        document.onmouseup = mouseup;
        document.onmousemove = mousemove;
      },
      mouseenter: () => {
        themeWrapper.style.setProperty('--timelineResizeBgColor', '#0084F8');
      },
      mouseleave: () => {
        themeWrapper.style.setProperty('--timelineResizeBgColor', 'unset');
      },
    },
  });

  const mousemove = (_event: MouseEvent): void => {
    _event.preventDefault();
    _event.stopPropagation();

    const width = _event.pageX > 300 ? (_event.pageX - 60 < 600 ? _event.pageX - 60 : 600) : 240;

    themeWrapper.style.setProperty('--timelineGroupWidth', width + 'px');
  };

  const mouseup = (): void => {
    const width = themeWrapper.style.getPropertyValue('--timelineGroupWidth');
    const timeline = GET_TIMELINE();

    localStorage.setItem(ELocalStorageItems.TIMELINE_GROUP_COL_WIDTH, width);

    document.onmouseup = null;
    document.onmousemove = null;

    timeline.load(true);
  };

  return resizeElement;
}
