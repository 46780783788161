import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, forkJoin } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { SetActiveAccountId, SetActiveWorkspaceId } from '../../../services/active/active.actions';
import { SetBasicFetched } from '../../offline/sync.actions';
import { TUISettings } from '../../ui/ui.model';

import { Title } from '@angular/platform-browser';
import { SelectableFiltersService } from 'src/app/project/modules/filters/update-selectable-filters/selectable-filters.service';
import { TSidePanel } from 'src/app/project/modules/layout/side-panel/side-panel.model';
import { PointsFetchingService } from 'src/app/project/modules/points/points-fetching.service';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { AccountService } from '../../account/account-service/account.service';
import { SidePanelService } from '../../layout/side-panel/side-panel.service';
import { SyncService } from '../../offline/sync.service';
import { EditPointService } from '../../points/point-full-modal/edit-point.service';
import { PreferencesService } from '../../preferences/preferences-service/preferences.service';
import { WorkspaceService } from '../../workspace/workspace.service';
import { SiteDataService, TSiteData } from '../site-data.service';
import { SiteTableColumnsService } from '../site-table/site-table-columns.service';
import { SiteService } from '../site.service';
import { SiteOverviewService } from './site-overview.service';

@Component({
  selector: 'pp-site-overview',
  templateUrl: './site-overview.component.html',
  styleUrls: ['./site-overview.component.scss'],
})
export class SiteOverviewComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  site: TSiteData = this.siteDataService.getSite();
  sidePanel: TSidePanel = this.sidePanelService.getSidePanel();

  ui$: Observable<TUISettings>;
  ui: TUISettings;
  offline$: Observable<boolean>;
  offline = false;

  tableCreated = false;
  dataFetched = false;

  constructor(
    private store: Store<{
      offline: boolean;
      ui: TUISettings;
    }>,
    private siteOverviewService: SiteOverviewService,
    private siteDataService: SiteDataService,
    private sidePanelService: SidePanelService,
    private workspaceService: WorkspaceService,
    private syncService: SyncService,
    private preferencesService: PreferencesService,
    private accountService: AccountService,
    private siteTableColumnsService: SiteTableColumnsService,
    private editPointService: EditPointService,
    private pointsFetchingService: PointsFetchingService,
    private siteService: SiteService,
    private updateSelectableFiltersService: SelectableFiltersService,
    private titleService: Title,
  ) {
    this.ui$ = this.store.pipe(select(EStore.UI));
    this.offline$ = this.store.pipe(select(EStore.OFFLINE));

    this.offline$.pipe(takeUntil(this.destroy$)).subscribe((offline) => {
      this.offline = offline;
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Loading... | Pinpoint Works');

    const workspaces = this.workspaceService.getWorkspaces();
    const accounts = this.accountService.getAccounts();
    this.store.dispatch(new SetActiveAccountId(null));
    this.sidePanelService.initSidePanel({ expandedInitially: false });

    if (!this.offline && (!Array.isArray(accounts) || Object.entries(workspaces).length === 0)) {
      this.store.dispatch(new SetActiveWorkspaceId(null));

      this.syncService
        .firstLoad()
        .pipe(
          takeUntil(this.destroy$),
          tap(() => {
            this.loadData();
          }),
        )
        .subscribe();
    } else {
      this.store.dispatch(new SetActiveWorkspaceId(null));

      this.loadData();
    }

    this.ui$.pipe(takeUntil(this.destroy$)).subscribe((ui) => {
      this.ui = ui;
    });
  }

  private loadData(): void {
    forkJoin([
      this.workspaceService.generateWorkspaces(),
      this.preferencesService.fetchPreferences(),
      this.pointsFetchingService.fetchAllPoints(),
    ])
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.onDataFetched();
        }),
      )
      .subscribe();
  }

  onDataFetched(): void {
    this.dataFetched = true;

    this.updateTitle();
    this.updateSelectableFiltersService.updateSelectableTags();
    this.updateSelectableFiltersService.updateSelectableExcludedTags();
    this.siteTableColumnsService.initAllColumns();

    this.siteService.setFetched(true);
    this.store.dispatch(new SetBasicFetched(true));
    this.siteOverviewService.updateOverviewFilter();
  }

  ngOnDestroy() {
    this.siteDataService.clearSite();
    this.editPointService.hideModal();

    this.destroy$.next();
  }

  updateTitle(): void {
    this.titleService.setTitle('Overview | Pinpoint Works');
  }

  hidePlaceholder(): void {
    this.tableCreated = true;
  }
}
