<pp-action-bar></pp-action-bar>

<pp-not-authorized-layout [ppHideLogo]="true">
  <section class="authBox">
    <header>
      <section class="authPage__logoWrapper">
        <img [src]="EIconPath.LOGO_2FA" alt="Pinpoint Works logo" title="Pinpoint Works" />
      </section>
    </header>

    <div class="authPage__subheader">
      <h4>{{ '2fa_setup' | ppTranslate }}</h4>
      <p class="authBox__copy">{{ '2fa_enter_phone' | ppTranslate }}</p>
    </div>

    <pp-two-factor-authentication-mode-picker
      [ppValue]="ppChannel"
      (ppValueChange)="onChannelChange($event)"
    ></pp-two-factor-authentication-mode-picker>

    <form class="authBox__inputs-container" novalidate>
      <pp-phone-input
        [(ppNumber)]="ppPhoneNumber"
        (ppValidate)="checkValidation($event)"
        (ppNumberChange)="phoneNumberChange()"
      ></pp-phone-input>
    </form>

    <footer class="authBox__footer--gap-s">
      <div class="authPage__error" *ngIf="errorMessage">
        <pp-icon
          [ppSrc]="EIconPath.ICON_BADGE_INFO"
          ppColor="inherit"
          ppClass="setSize14"
        ></pp-icon>

        <span>
          {{ errorMessage }}
        </span>
      </div>

      <pp-button
        [ppDisabled]="!ppChannel || !ppPhoneNumber || !!errorMessage"
        ppStyle="primary"
        ppSize="large"
        ppId="logInBtn"
        [ppProcess]="ppProcessing"
        (ppAction)="setUp2FA()"
      >
        {{ '2fa_send_code' | ppTranslate }}
      </pp-button>

      <div class="authPage__setUpLater">
        <button class="authPage__setUpLaterText" (click)="setUpLater()">
          {{ '2fa_set_up_later' | ppTranslate }}
        </button>
      </div>
    </footer>
  </section>
</pp-not-authorized-layout>
