import { Component, Input } from '@angular/core';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { ENotificationReactionType } from '../../notification-reaction-type.enum';

@Component({
  selector: 'pp-notification-comment-reaction',
  templateUrl: './notification-comment-reaction.component.html',
  styleUrls: ['../notification.component.scss'],
})
export class NotificationCommentReactionComponent {
  @Input() reactionType: ENotificationReactionType;

  ENotificationReactionType = ENotificationReactionType;
  EIconPath = EIconPath;
}
