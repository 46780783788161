import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClearFilterService } from '../../clear-filter-service/clear-filter.service';
import { TFilters } from '../../site-filter.model';

@Component({
  selector: 'pp-filter-field-flag',
  templateUrl: './filter-field-flag.component.html',
  styleUrls: ['../../site-filter-dropdown/site-filter-dropdown.component.scss'],
})
export class FilterFieldFlagComponent {
  @Input() ppFilters: TFilters;
  @Output() ppApply = new EventEmitter();

  constructor(private clearFilterService: ClearFilterService) {}

  toggleUnflaggedPoints(): void {
    this.ppFilters.showUnflaggedPoints = !this.ppFilters.showUnflaggedPoints;

    this.applyFilters();
  }

  toggleFlaggedPoints(): void {
    this.ppFilters.showFlaggedPoints = !this.ppFilters.showFlaggedPoints;

    this.applyFilters();
  }

  clearFlag(): void {
    this.ppFilters.showFlaggedPoints = this.clearFilterService.clearFlaggedPoints();
    this.ppFilters.showUnflaggedPoints = this.clearFilterService.clearUnflaggedPoints();

    this.applyFilters();
  }

  applyFilters(): void {
    this.ppApply.emit();
  }
}
