import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { Store } from '@ngrx/store';

import { UpdatePointCustomField } from 'src/app/project/modules/points/points.actions';
import { TPoint } from 'src/app/project/modules/points/points.model';

import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { PointsUpdateService } from 'src/app/project/modules/points/points-update.service';
import { PointsService } from 'src/app/project/modules/points/points.service';
import { PromptService } from '../../../../../components/prompt/prompt.service';
import { PointActivityService } from '../../point-timeline/point-activity.service';
import { PointFieldsService } from '../point-fields.service';

import { ClickOutsideHandler } from '@core/services';
import { of, Subject, timer } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { checkClearShortcut } from 'src/app/project/modules/custom-fields/utils/check-clear-shortcut';
import { CustomTableService } from 'src/app/project/modules/site/site-table/custom-table/custom-table.service';
import { autosizeTextArea } from 'src/app/project/shared/utils/autosize-text-area';
import { EIconPath } from '../../../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-point-fields-text',
  templateUrl: './point-fields-text.component.html',
  styleUrls: ['./point-fields-text.component.scss', '../point-fields.component.scss'],
})
export class PointFieldsTextComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() ppWorkspaceId: string;
  @Input() ppPointId: string;
  @Input() ppFieldId: string;
  @Input() ppFieldLabel: string;
  @Input() ppNew: boolean;
  @Input() ppCanEdit: boolean;

  private readonly destroy$ = new Subject<void>();

  @ViewChild('input') inputContainer: ElementRef;
  @ViewChild('textCF') textCustomField: ElementRef;

  inputValue = '';
  characterLimit = 5000;

  updating = false;
  error = false;
  focused = false;
  value = '';
  EIconPath = EIconPath;

  private clickOutsideHandler: ClickOutsideHandler;
  private cancelUpdateField$ = new Subject<void>();
  private updateFieldTimerMs = 300;
  private removeClickListenerTimerMs = 100;
  private refreshTextareaSizeTimerMs = 100;

  constructor(
    private store: Store,
    private promptService: PromptService,
    private pointFieldsService: PointFieldsService,
    private pointActivityService: PointActivityService,
    private pointsUpdateService: PointsUpdateService,
    private pointsService: PointsService,
    private translationPipe: TranslationPipe,
    private sitePointFilterService: SitePointFilterService,
    private ngZone: NgZone,
    private customTableService: CustomTableService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.ppPointId && !changes.value) {
      return;
    }

    const point: TPoint = this.pointsService.findPoint(this.ppPointId);

    this.value = point?.customFieldsMap[this.ppFieldId]?.value || '';
    this.inputValue = this.value || '';

    this.updateInputSizeOnChanges();
  }

  ngAfterViewInit() {
    this.clickOutsideHandler = new ClickOutsideHandler(
      this.textCustomField.nativeElement,
      this.destroy$,
    );

    this.clickOutsideHandler.caught$.subscribe((event) => {
      this.onClickOutside(event);
    });

    autosizeTextArea(this.inputContainer.nativeElement);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  clearCustomField(): void {
    this.inputContainer.nativeElement.blur();
    this.value = null;
    this.inputValue = '';

    this.updateField(true);
  }

  updateField(clear?: boolean): void {
    const value = this.inputValue ? this.inputValue.trim() : '';

    if (value.length > this.characterLimit) {
      const promptText = this.translationPipe.transform('prompt_text_over_limit', {
        characterLimit: this.characterLimit,
      });
      this.error = true;

      this.promptService.showWarning(promptText);

      return;
    }

    const _id = this.ppPointId;
    const fieldId = this.ppFieldId;
    const fieldValue = clear ? null : value;
    const workspaceId = this.ppWorkspaceId;

    this.cancelUpdateField$.next();

    timer(this.updateFieldTimerMs)
      .pipe(
        takeUntil(this.cancelUpdateField$),
        tap(() => {
          this.updateFieldDeferred(_id, fieldId, fieldValue, workspaceId);
        }),
      )
      .subscribe();
  }

  onBlur(): void {
    this.focused = false;

    if (
      typeof this.inputValue === 'string' &&
      this.inputValue &&
      this.inputValue.trim().length > this.characterLimit
    ) {
      this.error = true;
    }

    this.ngZone.runOutsideAngular(() => {
      timer(this.removeClickListenerTimerMs).subscribe(() => {
        this.clickOutsideHandler.disable();
      });
    });
  }

  onFocus(): void {
    this.focused = true;
    this.error = false;

    this.clickOutsideHandler.enable();
  }

  onKeydown(event: KeyboardEvent): void {
    checkClearShortcut(event, () => this.clearCustomField());

    event.stopPropagation();
    event.stopImmediatePropagation();

    if (event.key === 'Enter') {
      this.blurInput(event);
    }
  }

  onKeyUp(event: KeyboardEvent): void {
    event.stopPropagation();
    event.stopImmediatePropagation();
  }

  onInput(event: Event): void {
    const element = event.target as HTMLElement;

    autosizeTextArea(element);
  }

  private updateInputSizeOnChanges(): void {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        if (this.inputContainer) {
          autosizeTextArea(this.inputContainer.nativeElement);
        }
      }, this.refreshTextareaSizeTimerMs);
    });
  }

  private blurInput(event: Event): void {
    const target = event.target as HTMLElement;

    target.blur();
  }

  private updateFieldDeferred(
    _id: string,
    fieldId: string,
    fieldValue: string,
    workspaceId: string,
  ): void {
    if (this.ppNew) {
      this.updateFieldForNewPoint(_id, fieldId, fieldValue, workspaceId);

      return;
    }

    this.updating = true;

    const customField: Record<string, string>[] = this.pointFieldsService.createCustomField2(
      fieldId,
      fieldValue,
    );

    this.inputContainer.nativeElement.blur();

    this.pointsUpdateService
      .updatePointField(_id, { customFieldsMap: customField })
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          this.handleUpdateResponse(_id, workspaceId);
        }),
        catchError((error) => {
          return this.handleUpdateError(error);
        }),
        finalize(() => {
          this.updating = false;
        }),
      )
      .subscribe();
  }

  private handleUpdateError(error: any) {
    this.pointFieldsService.showUpdatePointFieldError(error);

    this.error = true;

    return of(error);
  }

  private handleUpdateResponse(_id: string, workspaceId: string) {
    this.error = false;
    const promptText = this.translationPipe.transform('prompt_point_text_update');
    this.promptService.showSuccess(promptText);
    this.pointActivityService.refreshTimeline(workspaceId, _id);
    this.sitePointFilterService.filterPoints({ _keepScrollPosition: true });
  }

  private onClickOutside(event: MouseEvent): void {
    event.stopImmediatePropagation();

    this.inputContainer.nativeElement.blur();
  }

  private updateFieldForNewPoint(
    pointId: string,
    fieldId: string,
    fieldValue: string,
    workspaceId: string,
  ): void {
    this.store.dispatch(
      new UpdatePointCustomField({
        workspaceId: workspaceId,
        pointId: pointId,
        customFieldId: fieldId,
        customFieldValue: fieldValue,
      }),
    );
  }
}
