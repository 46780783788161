import Tooltip from 'src/app/project/features/tooltip/Tooltip';
import Timeline from '../../Timeline';

export function createTimelineCustomFieldTooltip(
  date: string,
  element: HTMLElement,
  timeline: Timeline,
): Tooltip {
  const tooltip = new Tooltip({
    options: {
      arrow: true,
      inertia: true,
      animation: 'fade',
      theme: 'pinpoint',
    },
    title: date,
    mobileTooltip: false,
    trimTooltip: false,
    childTooltip: false,
    element: element,
    trimElement: element,
    sanitize: true,
  });

  timeline.timelineBody?.addTooltip(tooltip);

  tooltip.createTooltip();
  return tooltip;
}
