import {
  TAsset,
  TAssetDetails,
  TAssetLabel,
  TAssetSite,
} from '../../../asset-service/asset.consts';

export function areAssetsEqual(
  oldAsset: TAsset,
  newAsset: TAsset,
  assetImageExists: boolean,
): boolean {
  if (!oldAsset) {
    return isNewAssetEmpty(newAsset, assetImageExists);
  }

  const detailsEqual = areDetailsEqual(oldAsset.details, newAsset.details);
  const nameEqual = oldAsset.name === newAsset.name;
  const sitesEqual = areSitesEqual(oldAsset.sites, newAsset.sites);
  const labelsEqual = areLabelsEqual(oldAsset.labels, newAsset.labels);
  const imageEqual = oldAsset.imageId === newAsset.imageId;

  return detailsEqual && nameEqual && sitesEqual && labelsEqual && imageEqual && !assetImageExists;
}

function isNewAssetEmpty(newAsset: TAsset, assetImageExists: boolean) {
  const detailsEmpty = areDetailsEmpty(newAsset.details);
  const nameEmpty = !newAsset.name;
  const sitesEmpty = !newAsset.sites.length;
  const labelsEmpty = !newAsset.labels.length;
  const imageEmpty = !newAsset.imageId;

  return detailsEmpty && nameEmpty && sitesEmpty && labelsEmpty && imageEmpty && !assetImageExists;
}

function areDetailsEmpty(asset: TAssetDetails): boolean {
  return Object.values(asset).every((value) => !value);
}

function areDetailsEqual(oldDetails: TAssetDetails, newDetails: TAssetDetails): boolean {
  return Object.entries(oldDetails).every(([key, value]) => newDetails[key] === value);
}

function areSitesEqual(oldSites: TAssetSite[], newSites: TAssetSite[]): boolean {
  return (
    oldSites.length === newSites.length &&
    oldSites.every((site) =>
      newSites.find((newSite) => {
        return (
          newSite.budgetCustomFieldId === site.budgetCustomFieldId &&
          newSite.workspaceId === site.workspaceId
        );
      }),
    )
  );
}

function areLabelsEqual(oldLabels: TAssetLabel[], newLabels: TAssetLabel[]): boolean {
  return (
    oldLabels.length === newLabels.length &&
    oldLabels.every((label) =>
      newLabels.find((newLabel) => {
        return (
          newLabel.name === label.name &&
          newLabel.color === label.color &&
          newLabel.assigneeId === label.assigneeId
        );
      }),
    )
  );
}
