import { monthNames } from './timeline-timeframes';

export function getMonthBottomElements(numberOfYears: number): string[] {
  let elements = [];

  for (let i = 0; i < numberOfYears; i++) {
    elements = [...elements, ...monthNames];
  }

  return elements;
}
