import { Component, Input, OnChanges } from '@angular/core';
import { TDashletActivity } from '../dashlet-activity.model';
import { EPropName } from './prop-names-enum';
import { TAllUsers } from '@project/view-models';
import { DashletActivitiesService } from '../../dashlet-activities.service';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';

@Component({
  selector: 'pp-dashlet-activity-update-changes',
  templateUrl: './dashlet-activity-update-changes.component.html',
  styleUrls: ['../dashlet-activity.component.scss'],
})
export class DashletActivityUpdateChangesComponent implements OnChanges {
  @Input() ppAllUsers: TAllUsers;
  @Input() ppActivity: TDashletActivity;

  propNames = EPropName;
  shouldBeHidden = false;

  constructor(private dashletActivitiesService: DashletActivitiesService) {}

  ngOnChanges(): void {
    if (this.ppActivity) {
      for (let index = 0; index < this.ppActivity.data.changeList.length; index++) {
        const change = this.ppActivity.data.changeList[index];

        if (
          change.propName === EPropName.DESCRIPTION &&
          this.checkActivityDescriptionHidden(index)
        ) {
          this.shouldBeHidden = true;

          break;
        }
      }
    }
  }

  private checkActivityDescriptionHidden(index: number): boolean {
    const activities = this.dashletActivitiesService.getDashletActivities();
    let shouldBeHidden = false;

    if (!activities[index]) {
      logErrorInSentry({
        message: 'Missing activity data in dashboard',
        activities: activities,
        index: index,
      });
    }

    const changeList = activities[index]?.data.changeList;
    const isDescription = !!changeList?.find((change) => change.propName === EPropName.DESCRIPTION);

    if (isDescription) {
      shouldBeHidden =
        this.checkHidden(activities[index - 1]) ||
        this.checkHidden(activities[index + 1]) ||
        this.checkHidden(activities[index]);
    }

    return shouldBeHidden;
  }

  private checkHidden(activity: TDashletActivity): boolean {
    if (activity && activity.data.changeList) {
      for (let i = 0; i < activity.data.changeList.length; i++) {
        const change = activity.data.changeList[i];

        if (
          change.propName === EPropName.DESCRIPTION_RICH &&
          typeof change.newValue !== 'undefined'
        ) {
          return true;
        }
      }
    }

    return false;
  }
}
