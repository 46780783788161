import { EActionType, ActionsUnion } from './offline.actions';

export const initialState = false;

export function offlineReducer(state = initialState, action: ActionsUnion) {
  switch (action.type) {
    case EActionType.SetOffline:
      return action.payload;

    default:
      return state;
  }
}
