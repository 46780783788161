import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TTranslationKey } from 'src/app/project/features/translate/types';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-notification-clear',
  templateUrl: './notification-clear.component.html',
  styleUrls: ['./notification-clear.component.scss'],
})
export class NotificationClearComponent {
  @Input() ppActive = false;
  @Input() ppEnableOnHover = false;
  @Input() ppTooltipTitle: TTranslationKey;
  @Input() ppClearAll = false;
  @Output() ppCallback = new EventEmitter<MouseEvent>();

  EIconPath = EIconPath;
  clearHover = false;

  onClearHover(hover: boolean): void {
    this.clearHover = hover;
  }

  callback(event: MouseEvent): void {
    this.ppCallback.emit(event);
  }
}
