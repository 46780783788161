import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TFilters } from '../../site-filter.model';

import { ClearFilterService } from '../../clear-filter-service/clear-filter.service';

@Component({
  selector: 'pp-filter-field-date-created',
  templateUrl: './filter-field-date-created.component.html',
  styleUrls: ['../../site-filter-dropdown/site-filter-dropdown.component.scss'],
})
export class FilterFieldDateCreatedComponent {
  @Input() ppFilters: TFilters;
  @Output() ppApply = new EventEmitter();

  constructor(private clearFilterService: ClearFilterService) {}

  updateDate(selectedDates: Date[], type: string): void {
    this.ppFilters.date.created[type] = new Date(selectedDates[0]).getTime();

    this.applyFilters();
  }

  clearDateCreated(): void {
    this.clearFilterService.clearDateCreated();

    this.ppFilters.date.created.startDate = null;
    this.ppFilters.date.created.endDate = null;

    this.applyFilters();
  }

  applyFilters(): void {
    this.ppApply.emit();
  }
}
