import { EAccessParameter } from './access-parameters';

export function correctParameter(parameter: EAccessParameter): {
  canRead: boolean;
  canEdit: boolean;
} {
  let edit: boolean, read: boolean;

  if (parameter === EAccessParameter.READ) {
    edit = false;
    read = true;
  } else if (parameter === EAccessParameter.HIDE) {
    read = false;
    edit = false;
  } else if (parameter === EAccessParameter.EDIT) {
    read = true;
    edit = true;
  }

  return {
    canRead: read,
    canEdit: edit,
  };
}
