<div class="notification__image-wrapper">
  <img
    class="notification__like-comment-button--liked notification__image"
    [src]="EIconPath.INTERACTION_LIKE"
  />
  <!-- TODO Add images for different reaction types when we have them -->
</div>

<ng-container [ngSwitch]="reactionType">
  <span class="notification__blue-text" *ngSwitchCase="ENotificationReactionType.LIKE">{{
    'liked' | ppTranslate
  }}</span>
  <span class="notification__blue-text" *ngSwitchDefault>{{ 'reacted_to' | ppTranslate }}</span>
  <!-- We don't have other reactions than LIKE right now, but we can add them so it's better to have a generic fallback if user gets something else -->

  {{ 'your' | ppTranslate }}
  <span class="notification__bold">{{ 'comment' | ppTranslate }}</span
  >.
</ng-container>
