<td class="userManagement__selectCell checkboxCell" [class.checkboxCell--disabled]="isAccountAdmin">
  <div class="checkboxWrapper">
    <pp-checkbox
      [ppDisabled]="isAccountAdmin"
      [ppChecked]="isSelected"
      (ppOnValueChange)="selectCell()"
      ppSize="medium"
      class="checkbox"
      ppType="solid"
    ></pp-checkbox>
  </div>
</td>
