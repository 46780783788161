import { Component, OnInit, OnDestroy } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TUISettings } from '../../ui/ui.model';
import { RemoveComponentFromView } from '../../ui/ui.actions';
import { WindowService } from '@core/services';
import { EStore } from 'src/app/project/shared/enums/store.enum';

@Component({
  selector: 'pp-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
})
export class ActionBarComponent implements OnInit, OnDestroy {
  ui: TUISettings;

  private ui$: Observable<TUISettings>;
  private readonly destroy$ = new Subject<void>();

  constructor(private store: Store<{ ui: TUISettings }>, private windowService: WindowService) {
    this.ui$ = this.store.pipe(select(EStore.UI));
  }

  ngOnInit(): void {
    this.ui$.pipe(takeUntil(this.destroy$)).subscribe((ui) => {
      this.ui = ui;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  updateApp(): void {
    this.store.dispatch(
      new RemoveComponentFromView({
        componentName: 'actionBar',
      }),
    );

    this.windowService.reload();
  }
}
