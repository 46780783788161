import { Injectable } from '@angular/core';

import { AuthApiProviderService } from '@core/api';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { translate } from 'src/app/project/features/translate/translate';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { TUserResponse } from '../../user/response-models/user-response-model';

@Injectable({
  providedIn: 'root',
})
export class PasswordResetService {
  private email = '';

  constructor(
    private authApiProviderService: AuthApiProviderService,
    private promptService: PromptService,
  ) {}

  resetPassword(email: string): Observable<TUserResponse> {
    return this.authApiProviderService.resetPassword(email).pipe(
      catchError((error) => {
        let promptText = translate('prompt_error');

        if (error.status === EStatusCode.FORBIDDEN) {
          promptText = translate('prompt_email_unverified');

          this.promptService.showError(promptText, { duration: 30 });
        } else {
          this.promptService.showError(promptText);
        }

        logErrorInSentry({
          ...error,
          email,
        });

        return of(null);
      }),
    );
  }

  setEmail(email: string): void {
    this.email = email;
  }

  getEmail(): string {
    return this.email;
  }
}
