import { TFilters } from '../site-filter.model';

export function compareFilterCreatedDate(firstFilter: TFilters, secondFilter: TFilters): boolean {
  const created1 = firstFilter.date.created;
  const created2 = secondFilter.date.created;

  if (!created1.applied && !created2.applied) {
    return true;
  }

  if (created1.startDate !== created2.startDate) {
    return false;
  }

  if (created1.endDate !== created2.endDate) {
    return false;
  }

  return true;
}
