<section class="passwordCheck__box">
  <p class="passwordCheck__title">{{ 'your_password_must' | ppTranslate }}</p>
  <div class="passwordCheck__grid">
    <div class="passwordCheck__row" [class.passwordCheck__row--error]="!correctLength">
      <img [src]="correctLength ? EIconPath.GREEN_CHECK_CIRCLE : EIconPath.RED_CANCEL_CIRCLE" />
      <p class="passwordCheck__copy">{{ 'password_limit_1' | ppTranslate }}</p>
    </div>

    <div class="passwordCheck__row" [class.passwordCheck__row--error]="!correctUpperCase">
      <img [src]="correctUpperCase ? EIconPath.GREEN_CHECK_CIRCLE : EIconPath.RED_CANCEL_CIRCLE" />
      <p class="passwordCheck__copy">{{ 'password_limit_2' | ppTranslate }}</p>
    </div>

    <div class="passwordCheck__row" [class.passwordCheck__row--error]="!correctLowerCase">
      <img [src]="correctLowerCase ? EIconPath.GREEN_CHECK_CIRCLE : EIconPath.RED_CANCEL_CIRCLE" />
      <p class="passwordCheck__copy">{{ 'password_limit_3' | ppTranslate }}</p>
    </div>

    <div class="passwordCheck__row" [class.passwordCheck__row--error]="!correctNumber">
      <img [src]="correctNumber ? EIconPath.GREEN_CHECK_CIRCLE : EIconPath.RED_CANCEL_CIRCLE" />
      <p class="passwordCheck__copy">{{ 'password_limit_4' | ppTranslate }}</p>
    </div>
  </div>
</section>
