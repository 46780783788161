<div class="pointCF__field">
  <label
    ppTooltip
    class="pointCF__label"
    ppTitle="{{ ppCustomField?.label }}"
    [ppTrimTooltip]="true"
  >
    <img
      class="pointCF__bulk-changes-field-icon"
      [src]="EIconPath.CUSTOM_FIELD_CHECKBOX_ACTIVITY"
    />

    <span class="pointCF__label-container">
      {{ ppCustomField?.label }}
    </span>
  </label>

  <div class="pointCF__content pointCF__content--checkbox">
    <pp-custom-fields-checkbox
      class="bulkChanges__checkbox"
      [ppField]="ppCustomField"
      [ppValue]="ppCustomFields[ppFieldId]"
      (ppUpdate)="updateCheckbox($event, ppCustomField)"
    ></pp-custom-fields-checkbox>
  </div>
</div>
