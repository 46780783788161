import Tooltip from 'src/app/project/features/tooltip/Tooltip';

import { createElement } from 'src/app/core/helpers/dom';
import { createHeaderEmptyElement } from './body-elements/body-cells-frozen/create-header-empty-element';

import { GET_TIMELINE_COLUMNS } from './timeframes/timeline-timeframes';
import { GET_PREFERENCES } from 'src/app/project/modules/preferences/preferences.store';
import { createTimelineTopElement } from './timeline-head-elements/create-timeline-top-element';
import { createTimelineBottomElement } from './timeline-head-elements/create-timeline-bottom-element';
import { createTimelineHeadCellTypeElementTop } from './timeline-head-elements/create-timeline-head-cell-type-element-top';
import { createTimelineHeadCellTypeElementBottom } from './timeline-head-elements/create-timeline-head-cell-type-element-bottom';
import Timeline from './Timeline';
import { checkElement } from '@core/helpers';

export default class TableHead {
  canResize = true;
  tooltips: Tooltip[] = [];

  element: HTMLElement = null;
  rowBottomElement: HTMLElement = createTimelineBottomElement();
  rowTopElement: HTMLElement = createTimelineTopElement();

  private timeline: Timeline;

  constructor(_parent: Timeline) {
    this.timeline = _parent;

    this.element = this.create();

    this.timeline.element.appendChild(checkElement(this.element));
  }

  private create(): HTMLElement {
    return createElement('div', {
      attrs: {
        class: 'timeline__head',
      },
      children: [this.rowTopElement, this.rowBottomElement],
    });
  }

  clear(): void {
    this.updateWidth(0);
  }

  load(_keepScrollPosition: boolean = false): void {
    this.updateWidth(this.timeline.width);

    const wrapperElement = this.element.children[0];
    const bottomWrapperElement = this.element.children[1];
    const scrollPosition = _keepScrollPosition ? this.element.scrollLeft : 0;
    const preferences = GET_PREFERENCES();

    this.tooltips.forEach((tooltip) => {
      tooltip.removeTooltip();
    });

    this.tooltips = [];

    while (wrapperElement.firstChild) {
      wrapperElement.removeChild(wrapperElement.firstChild);
    }

    while (bottomWrapperElement.firstChild) {
      bottomWrapperElement.removeChild(bottomWrapperElement.firstChild);
    }

    if (preferences.timeline?.group) {
      wrapperElement.appendChild(checkElement(createHeaderEmptyElement()));
      bottomWrapperElement.appendChild(checkElement(createHeaderEmptyElement()));
    }

    GET_TIMELINE_COLUMNS().topElements.forEach((_column) => {
      const cellElement = createTimelineHeadCellTypeElementTop(_column);

      wrapperElement.appendChild(checkElement(cellElement));
    });

    GET_TIMELINE_COLUMNS().bottomElements.forEach((_column, index) => {
      const cellElement = createTimelineHeadCellTypeElementBottom(_column, false, index);

      bottomWrapperElement.appendChild(checkElement(cellElement));
    });

    this.timeline.sortTimeline();

    this.element.scrollLeft = scrollPosition;
  }

  updateWidth(_width: number = this.timeline.width): void {
    (this.element.children[0] as any).style.width = `${_width}px`;
    (this.element.children[1] as any).style.width = `${_width}px`;
  }

  scrollLeft(_left: number): void {
    this.element.scrollLeft = _left;
  }

  addTooltip(tooltip: Tooltip): void {
    this.tooltips.push(tooltip);
  }
}
