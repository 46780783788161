<pp-advanced-filter-option-select
  class="advancedFilter__option"
  [ppItems]="advancedFilterListOptions"
  [ppOption]="option"
  (ppOptionChange)="changeFieldCondition($event)"
  ppClass="advancedFilter__option--condition"
></pp-advanced-filter-option-select>

<ng-container
  *ngIf="
    option === EAdvancedFilterOptions.IS ||
    option === EAdvancedFilterOptions.IS_NOT ||
    option === EAdvancedFilterOptions.IS_A_PART_OF
  "
>
  <pp-advanced-filter-list-input
    [ppField]="field"
    [ppSelected]="value"
    (ppSelectCf)="changeFieldValue($event)"
  ></pp-advanced-filter-list-input>
</ng-container>
