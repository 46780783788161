import { createElement } from '@core/helpers';

export function html2image({
  element,
  width,
  height,
  wrapperWidth,
  wrapperHeight,
  scale,
  borderRadius,
}) {
  return generateCanvas(element, width, height, wrapperWidth, wrapperHeight, scale, borderRadius);
}

async function generateCanvas(
  htmlElement,
  width,
  height,
  wrapperWidth,
  wrapperHeight,
  scale,
  borderRadius,
) {
  return new Promise((resolve) => {
    const offset = -8; // converting to image leaves 0.8rem gap on left and top that needs to be removed

    const canvasWrapper = createElement('div');
    canvasWrapper.style.width = 'fit-content';
    canvasWrapper.style.height = 'fit-content';
    canvasWrapper.style.marginLeft = '10rem';

    const canvas = createElement('canvas');
    canvas.width = wrapperWidth * scale;
    canvas.height = wrapperHeight * scale;
    canvas.style.borderRadius = `${borderRadius * scale}px`;

    canvasWrapper.appendChild(canvas);

    // uncomment to show images in the app
    // document.body.style.overflow = 'unset';
    // canvasWrapper.appendChild(htmlElement);
    // document.body.appendChild(canvasWrapper);

    const ctx = canvas.getContext('2d');

    render_html_to_canvas(
      htmlElement.outerHTML,
      ctx,
      offset,
      offset,
      2300 * scale,
      1600 * scale,
    ).then(() => {
      resolve(canvas);
    });
  });
}

function render_html_to_canvas(html, ctx, x, y, width, height) {
  return new Promise((resolve) => {
    let xml = html_to_xml(html);
    xml = xml.replace(/\#/g, '%23');

    const data =
      'data:image/svg+xml;charset=utf-8,' +
      '<svg xmlns="http://www.w3.org/2000/svg" width="' +
      width +
      '" height="' +
      height +
      '">' +
      '<foreignObject width="100%" height="100%">' +
      xml +
      '</foreignObject>' +
      '</svg>';

    const img = new Image();

    img.onload = () => {
      ctx.drawImage(img, x, y);

      resolve(img);
    };

    img.src = data;
  });
}

function html_to_xml(html) {
  const doc = document.implementation.createHTMLDocument('');
  doc.write(html);

  // You must manually set the xmlns if you intend to immediately serialize
  // the HTML document to a string as opposed to appending it to a
  // <foreignObject> in the DOM
  doc.documentElement.setAttribute('xmlns', doc.documentElement.namespaceURI);

  // Get well-formed markup
  html = new XMLSerializer().serializeToString(doc.body);

  return html;
}
