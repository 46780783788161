<pp-point-heading>
  <ng-container heading> {{ 'attachments' | ppTranslate }} </ng-container>

  <ng-container secondary>
    <div class="attachments__dropzone-message copyReg hide--tablet-down">
      <pp-icon
        [ppSrc]="EIconPath.ICON_MISC_ATTACH_18"
        ppColor="inherit"
        ppClass="setSize18"
      ></pp-icon>
      {{ 'drop_to_upload_1' | ppTranslate }}

      <label class="dropzone__label" for="fileUpload">
        <a class="ppLink ppLink--normal" (click)="(null)">{{ 'drop_to_upload_2' | ppTranslate }}</a>

        <pp-upload
          [ppId]="'fileUpload'"
          [ppMultiple]="true"
          (ppUpload)="uploadMediaAndFiles($event)"
        ></pp-upload>
      </label>
    </div>

    <div
      class="attachments__dropzone-message copyHeadingReg attachments__dropzone-btn hide--desktop"
    >
      <label for="fileUpload">
        <i class="fa fa-paperclip dropzone__icon" aria-hidden="true"></i>
        {{ 'upload' | ppTranslate }}

        <pp-upload
          [ppId]="'fileUpload'"
          [ppMultiple]="true"
          (ppUpload)="uploadMediaAndFiles($event)"
        ></pp-upload>
      </label>
    </div>
  </ng-container>
</pp-point-heading>

<div class="attachments">
  <div class="attachments__items">
    <ng-container *ngFor="let file of filesToUpload.data">
      <div class="attachments__uploading-item" *ngIf="file._id === ppPointId">
        <div
          class="attachments__uploading-item-wrap"
          *ngIf="showProgressBar || !(file.uploaded || file.uploading)"
        >
          <div class="attachments__uploading-filename">
            {{ file.name }}
          </div>

          <pp-progress-bar
            ppSize="small"
            [ppProgress]="file.progress"
            [ppPending]="!(file.uploaded || file.uploading)"
            [ppPointId]="ppPointId"
            (ppAction)="cancelUpload(file.index)"
            (ppUploadingPointId)="setUploadPointId($event)"
          >
            {{ file.uploaded ? 'Uploaded' : file.uploading ? file.progress + '%' : ' Pending' }}
          </pp-progress-bar>
        </div>
      </div>
    </ng-container>

    <div
      class="attachments__no-files dropzone--no-files dropzone__custom-container"
      data-test="attachmentsDragDropZone"
      *ngIf="files.attachmentIds.length === 0 && media.dates.length === 0"
    >
      <p class="hide--tablet-down">
        {{ 'drag_your_1' | ppTranslate }} <strong>{{ 'drag_your_2' | ppTranslate }}</strong>
        {{ 'drag_your_3' | ppTranslate }} <strong>{{ 'drag_your_4' | ppTranslate }}</strong>
        {{ 'drag_your_5' | ppTranslate }}
      </p>

      <span class="attachments__no-files-msg--mobile hide--desktop">
        {{ 'no_uploaded_files' | ppTranslate }}

        <label class="attachments__no-files-label--mobile" for="fileUpload">
          <a class="ppLink ppLink--normal" (click)="(null)">{{ 'browse' | ppTranslate }}</a>

          <pp-upload
            [ppId]="'fileUpload'"
            [ppMultiple]="true"
            (ppUpload)="uploadMediaAndFiles($event)"
          ></pp-upload>
        </label>
      </span>

      <img
        [src]="EIconPath.INPUT_UPLOAD_ILLUSTRATION"
        alt="Upload Illustration"
        class="dropzone__upload-illustration"
        srcset=""
      />
    </div>

    <section *ngIf="media.dates.length > 0">
      <div class="attachments__subtitle--wrapper">
        <pp-checkbox
          ppType="solid"
          class="files--checkbox files__checkbox-header"
          [ppChecked]="selectedMediaCount.number === (media.attachments | keyvalue).length"
          (ppOnValueChange)="selectAllMedia()"
        ></pp-checkbox>

        <h6 class="attachments__subtitle attachments__subtitle--files">
          {{ 'MEDIA' | ppTranslate }}
        </h6>

        <p class="attachments__subtitle-explaination" *ngIf="selectedMediaCount.number === 0">
          {{ 'images_videos_360_photos' | ppTranslate }}
        </p>
      </div>

      <div class="attachmentFiles--bulk hide--mobile" *ngIf="selectedMediaCount.number > 0">
        <span class="attachments__subtitle-filecount">
          <strong>{{ selectedMediaCount.number }}</strong>
          {{ selectedMediaCount.number === 1 ? ('item' | ppTranslate) : ('items' | ppTranslate) }}
          {{ 'selected_lowercase' | ppTranslate }}
        </span>

        <div
          ppTooltip
          ppTitle="Download"
          id="downloadSelectedMediaBtn"
          class="files__file-button files__file-button--media files__file-button--long files__file-button--hoverable"
          (click)="downloadSelectedMedia()"
        >
          <img [src]="EIconPath.INTERACTION_DOWNLOAD" class="files__download-icon" />
          <span>{{ 'download' | ppTranslate }}</span>
        </div>

        <div
          id="mediaBulkDelete"
          class="files__file-button files__file-button--media files__file-button--delete"
          (click)="deleteBulkMedia()"
          *ngIf="ppCanEdit"
        >
          <img [src]="EIconPath.INTERACTION_DELETE_ATTACHMENT_ICON" class="files__delete-icon" />
        </div>
      </div>

      <div class="media__days">
        <div class="media__day" *ngFor="let date of media.dates; let dayIndex = index">
          {{ date.formattedDate }}

          <div class="media__thumbnails">
            <ng-container *ngFor="let mediaItemId of date.attachmentIds; let mediaIndex = index">
              <div
                ppTooltip
                class="media__thumbnail"
                [class.media__thumbnail--selected]="media.attachments[mediaItemId].selected"
                [ngClass]="ppNew ? 'media__thumbnail--new' : ''"
                ppTitle="{{ media.attachments[mediaItemId].fileName }}"
                (click)="openGallery(media.attachments[mediaItemId])"
              >
                <img
                  ppImage
                  class="thumbnail"
                  [ppSecureSrc]="media.attachments[mediaItemId].square100Url"
                  *ngIf="media.attachments[mediaItemId].type !== EDocumentType.VIDEO"
                />

                <div
                  class="media__icon-wrapper"
                  [class.media__icon-wrapper--video]="
                    media.attachments[mediaItemId].type === EDocumentType.VIDEO
                  "
                  [ngSwitch]="media.attachments[mediaItemId].type"
                >
                  <span
                    class="sw-icon sw-icon-photo media__icon"
                    *ngSwitchCase="EDocumentType.IMAGE"
                  ></span>
                  <i
                    class="fa fa-play media__icon media__icon--video"
                    *ngSwitchCase="EDocumentType.VIDEO"
                  ></i>
                  <img
                    class="media__icon"
                    [src]="EIconPath.ACTIVITY_IMAGE_360_DARK"
                    *ngSwitchCase="EDocumentType.IMAGE_360"
                  />
                </div>

                <pp-checkbox
                  ppType="solid"
                  class="media__checkbox hide--mobile"
                  [ppChecked]="media.attachments[mediaItemId].selected"
                  (ppOnValueChange)="selectMedia(media.attachments[mediaItemId])"
                ></pp-checkbox>

                <div class="media__buttons">
                  <div
                    ppTooltip
                    id="renameMediaButton"
                    class="files__file-button media__rename-btn files__file-button--hoverable"
                    ppTitle="Rename attachment"
                    (click)="
                      renameAttachment(media.attachments[mediaItemId]); $event.stopPropagation()
                    "
                    *ngIf="ppCanEdit"
                  >
                    <img [src]="EIconPath.INTERACTION_POINT_RENAME" class="files__delete-icon" />
                  </div>

                  <div
                    ppTooltip
                    id="attachmentsBulkDelete"
                    class="media__delete-btn files__file-button files__file-button--delete no-margin"
                    ppTitle="Delete"
                    (click)="
                      deleteAttachment(media.attachments[mediaItemId]); $event.stopPropagation()
                    "
                    *ngIf="ppCanEdit"
                  >
                    <img
                      [src]="EIconPath.INTERACTION_DELETE_ATTACHMENT_ICON"
                      class="files__delete-icon"
                    />
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </section>

    <section *ngIf="files.attachmentIds.length > 0">
      <div class="attachments__subtitle--wrapper">
        <pp-checkbox
          ppType="solid"
          class="files--checkbox files__checkbox-header"
          [ppChecked]="selectedFilesCount.number === files.attachmentIds.length"
          (ppOnValueChange)="selectAllFiles()"
        ></pp-checkbox>

        <h6 class="attachments__subtitle attachments__subtitle--files">
          {{ 'FILES' | ppTranslate }}
        </h6>
      </div>

      <span class="attachmentFiles--bulk hide--mobile" *ngIf="selectedFilesCount.number > 0">
        <span class="attachments__subtitle-filecount">
          <strong>{{ selectedFilesCount.number }}</strong>
          {{ selectedFilesCount.number === 1 ? ('item' | ppTranslate) : ('items' | ppTranslate) }}
          selected
        </span>

        <div
          ppTooltip
          ppTitle="Download"
          id="downloadSelectedFilesBtn"
          class="files__file-button files__file-button--media files__file-button--long files__file-button--hoverable"
          (click)="downloadSelectedFiles()"
        >
          <img [src]="EIconPath.INTERACTION_DOWNLOAD" class="files__download-icon" />
          <span>{{ 'download' | ppTranslate }}</span>
        </div>

        <div
          ppTooltip
          id="filesBulkDelete"
          class="files__file-button files__file-button--delete files__file-button--media"
          ppTitle="Delete"
          (click)="deleteBulkFiles()"
          *ngIf="ppCanEdit"
        >
          <img [src]="EIconPath.INTERACTION_DELETE_ATTACHMENT_ICON" class="files__delete-icon" />
        </div>
      </span>

      <div class="files__wrapper">
        <div class="files__file" *ngFor="let fileId of sortedAttachments">
          <pp-checkbox
            ppType="solid"
            class="files--checkbox"
            [ppChecked]="files.attachments[fileId].selected"
            (ppOnValueChange)="selectFile(files.attachments[fileId])"
          ></pp-checkbox>

          <div class="files__file-icon">
            <i class="far fa-file-alt" aria-hidden="true"></i>
          </div>

          <div
            ppTooltip
            class="files__file-name"
            ppTitle="{{ files.attachments[fileId].fileName }}"
            [ppTrimTooltip]="true"
            [ppChildTooltip]="true"
          >
            <p>
              {{
                files.attachments[fileId].fileName ? files.attachments[fileId].fileName : 'unnamed'
              }}
            </p>
          </div>

          <p class="files__file-datetime">
            {{ files.attachments[fileId].createdOn | ppDate: ' h:mma' }}
          </p>

          <div class="files__file-avatar">
            <img
              ppTooltip
              ppImage
              class="files__file-avatar-image"
              [ppTitle]="
                allUsers[files.attachments[fileId].uploaderId]
                  ? allUsers[files.attachments[fileId].uploaderId].userName
                  : files.attachments[fileId].uploaderName
              "
              [src]="allUsers[files.attachments[fileId].uploaderId].avatarPublicUrl"
              *ngIf="
                files.attachments[fileId].uploaderAvatarId &&
                !isAvatarDefault &&
                allUsers[files.attachments[fileId].uploaderId]
              "
              (error)="showDefaultAvatar()"
            />

            <img
              ppTooltip
              ppImage
              class="files__file-avatar-image"
              [ppTitle]="
                allUsers[files.attachments[fileId].uploaderId]
                  ? allUsers[files.attachments[fileId].uploaderId].userName
                  : files.attachments[fileId].uploaderName
              "
              [ppSecureSrc]="
                'api/v1/images/' +
                files.attachments[fileId].uploaderAvatarId +
                '/file/size/square/40'
              "
              *ngIf="
                files.attachments[fileId].uploaderAvatarId &&
                !isAvatarDefault &&
                !allUsers[files.attachments[fileId].uploaderId]
              "
              (error)="showDefaultAvatar()"
            />

            <img
              ppTooltip
              class="files__file-avatar-image timeline__avatar timeline__avatar--default"
              draggable="false"
              [src]="EIconPath.PLACEHOLDER_USER"
              *ngIf="files.attachments[fileId].uploaderAvatarId && isAvatarDefault"
              [ppTitle]="
                allUsers[files.attachments[fileId].uploaderId]
                  ? allUsers[files.attachments[fileId].uploaderId].userName
                  : files.attachments[fileId].uploaderName
              "
            />
          </div>

          <div class="files__file-buttons">
            <div
              ppTooltip
              [id]="'attachmentDownloadBtn-' + fileId"
              class="files__file-button files__file-button--hoverable"
              ppTitle="Download"
              (click)="downloadFile(files.attachments[fileId], 'document')"
            >
              <img [src]="EIconPath.INTERACTION_DOWNLOAD" class="files__download-icon" />
            </div>

            <div
              ppTooltip
              class="files__file-button files__file-button--hoverable"
              ppTitle="Rename attachment"
              (click)="renameAttachment(files.attachments[fileId])"
            >
              <img [src]="EIconPath.INTERACTION_POINT_RENAME" class="files__rename-icon" />
            </div>

            <div
              ppTooltip
              class="files__file-button files__file-button--hoverable"
              *ngIf="files.attachments[fileId].isPreviewAvailable"
              ppTitle="Preview"
              (click)="openDocument(files.attachments[fileId])"
            >
              <img [src]="EIconPath.INTERACTION_POINT_PREVIEW" class="files__preview-icon" />
            </div>

            <div
              ppTooltip
              class="files__file-button files__file-button--disabled"
              *ngIf="!files.attachments[fileId].isPreviewAvailable"
              ppTitle="Preview is not available for this file type"
              [class.files__previewButton--disabled]="true"
            >
              <img [src]="EIconPath.INTERACTION_POINT_PREVIEW" class="files__preview-icon" />
            </div>

            <div
              ppTooltip
              [id]="'attachmentDeleteBtn-{{ fileId }}'"
              class="files__file-button files__file-button--delete"
              ppTitle="Delete"
              (click)="deleteAttachment(files.attachments[fileId])"
              *ngIf="ppCanEdit"
            >
              <img
                [src]="EIconPath.INTERACTION_DELETE_ATTACHMENT_ICON"
                class="files__delete-icon"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
