import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { getTimeAsUtcMidday } from 'src/app/core/helpers/date/date';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';

@Component({
  selector: 'pp-custom-field-timeline',
  templateUrl: './custom-field-timeline.component.html',
  styleUrls: ['./custom-field-timeline.component.scss'],
})
export class CustomFieldTimelineComponent implements OnChanges {
  @Input() ppField: TPointCustomField;
  @Input() ppValue: string;
  @Input() ppReadOnly = false;
  @Input() ppUpdating: boolean | string = false;
  @Input() ppWorkspaceId = '';
  @Input() ppFieldId = '';
  @Output() ppUpdate = new EventEmitter();

  fieldHighlight = false;
  value = [null, null];

  constructor() {}

  ngOnChanges(): void {
    if (this.ppValue) {
      this.value = this.ppValue.split('~');
    } else {
      this.value = [null, null];
    }
  }

  clearCustomField(): void {
    this.value = [null, null];

    this.updateField();
  }

  updateDate(dates: Date[]): void {
    const [fromDate, toDate] = dates;

    this.value = [getTimeAsUtcMidday(fromDate).toString(), getTimeAsUtcMidday(toDate).toString()];

    this.updateField();
  }

  updateField(): void {
    if (!((this.value[0] && !this.value[1]) || (!this.value[0] && this.value[1]))) {
      this.fieldHighlight = false;

      if (this.ppUpdate) {
        const value = this.value;
        let newValue = null;

        if (value) {
          newValue = this.value.join('~');
        }

        this.ppUpdate.emit(newValue);
      }
    } else {
      this.fieldHighlight = true;
    }
  }
}
