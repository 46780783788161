import { cloneDeep } from 'lodash';

import { Injectable, OnDestroy } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { TAllFilters, TCustomFieldFilter, TFilters } from '../site-filter.model';

import { ActiveService } from 'src/app/project/services/active/active.service';

import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { checkCustomWorkspaceId } from 'src/app/project/modules/workspace/workspace';
import { EStore } from '../../../shared/enums/store.enum';
import { TCustomField } from '../../custom-fields/custom-fields.model';

@Injectable({
  providedIn: 'root',
})
export class AddCustomFieldToFilterService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private filters: TFilters;

  private siteFilters$ = new Observable<TAllFilters>();
  private allFilters: TAllFilters;
  private workspaces$ = new Observable<TWorkspacesById>();
  private workspaceId: string;
  private workspaces: TWorkspacesById;

  constructor(
    private store: Store<{
      siteFilter: TAllFilters;
      workspaces: TWorkspacesById;
    }>,
    private activeService: ActiveService,
  ) {
    this.siteFilters$ = this.store.pipe(select(EStore.SITE_FILTER));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.siteFilters$.pipe(takeUntil(this.destroy$)).subscribe((allFilters) => {
      this.allFilters = allFilters;
      this.filters = cloneDeep(this.allFilters?.[this.workspaceId]);
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspaceId = this.activeService.getActiveWorkspaceId();

      if (!this.workspaceId) {
        this.workspaceId = checkCustomWorkspaceId();
      }

      this.filters = cloneDeep(this.allFilters?.[this.workspaceId]);
      this.workspaces = workspaces;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  addCustomFieldToFilter(customField: TCustomField, workspaceId: string): void {
    if (!this.filters || customField.type === ECustomFieldType.RICHTEXT) {
      // If there are no filters they'll get generated when site is opened so no need to add anything there
      return;
    }

    const { siteName, accountName } = this.workspaces[workspaceId];
    const commonFields = this.getCFFilterCommonFields(
      customField,
      workspaceId,
      siteName,
      accountName,
    );
    let specificFields: Partial<TCustomFieldFilter> = {};

    switch (customField.type) {
      case ECustomFieldType.DATE:
      case ECustomFieldType.TIMELINE:
        specificFields = this.getDateFields();
        break;
      case ECustomFieldType.COST:
      case ECustomFieldType.PERCENTAGE:
      case ECustomFieldType.FORMULA:
      case ECustomFieldType.NUMBERS:
      case ECustomFieldType.TIME:
        specificFields = this.getMinMaxFields();
        break;
      case ECustomFieldType.CHECKBOX:
        specificFields = this.getNumericalValueFields();
        break;
      case ECustomFieldType.LIST:
        specificFields = this.getListFields();
        break;
    }

    this.filters.customFields.push({
      ...commonFields,
      ...specificFields,
    });
  }

  private getCFFilterCommonFields(
    customField: TCustomField,
    workspaceId: string,
    siteName: string,
    accountName: string,
  ): TCustomFieldFilter {
    return {
      id: customField.id.toString(),
      workspaceId,
      sites: [
        {
          filterId: customField.id.toString(),
          workspaceId,
          accountName,
          siteName,
        },
      ],
    };
  }

  private getDateFields(): Partial<TCustomFieldFilter> {
    return {
      appliedValues: {
        startDate: null,
        endDate: null,
      },
      startDate: null,
      endDate: null,
    };
  }

  private getMinMaxFields(): Partial<TCustomFieldFilter> {
    return {
      appliedValues: {
        min: null,
        max: null,
      },
      min: null,
      max: null,
    };
  }

  private getNumericalValueFields(): Partial<TCustomFieldFilter> {
    return {
      appliedValues: {
        value: null,
      },
      value: null,
    };
  }

  private getListFields(): Partial<TCustomFieldFilter> {
    return {
      appliedValues: {
        idOfChosenElement: null,
        path: null,
        text: null,
      },
      idOfChosenElement: null,
      path: null,
      text: null,
    };
  }
}
