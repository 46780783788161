export enum ESiteListSelectTypesSingle {
  TAG = 'tag_selected',
  SHARE = 'user_selected',
  CUSTOM_FIELD = 'field_selected',
}

export enum ESiteListSelectTypesMultiple {
  TAG = 'tags_selected',
  SHARE = 'users_selected',
  CUSTOM_FIELD = 'fields_selected',
}

export enum EListSelectTypes {
  TAG = 'tag',
  SHARE = 'share',
  CUSTOM_FIELD = 'customField',
}
