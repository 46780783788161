export const API_share = (): string => '/api/v2/share';
export const API_share_workspace = (workspaceId: string): string =>
  `/api/v2/share/workspace/${workspaceId}`;
export const API_share_simple_update = (shareId: string): string =>
  `/api/v2/share/${shareId}/simple-update`;
export const API_share_id = (shareId: string): string => `/api/v2/share/${shareId}`;
export const API_share_resend = (shareId: string): string => `/api/v2/share/${shareId}/resend`;
export const API_share_account = (accountId: string): string =>
  `/api/v2/share/account/${accountId}/all-users`;
export const API_share_workspace_import = (targetWorkspaceId: string): string =>
  `/api/v2/share/workspace/${targetWorkspaceId}/import-users`;
export const API_share_workspace_target = (workspaceId: string): string =>
  `/api/v2/share/target/${workspaceId}`;
export const API_share_create = (workspaceId: string): string =>
  `/api/v2/share/workspace/${workspaceId}/user`;
export const API_share_import_excel = (workspaceId: string): string =>
  `/api/v1/share-imports/excel/${workspaceId}`;
export const API_share_bulk = (): string => 'api/v2/share/bulk';
export const API_share_account_user = (userId: string, accountId: string): string =>
  `api/v2/share/account/${accountId}/user/${userId}`;
export const API_share_bulk_edit = (): string => 'api/v2/share/bulk/simple-update';
export const API_share_account_admin = (): string => 'api/v2/share/account-admin';
export const API_share_account_admin_delete = (): string => `api/v2/share/account-admin-delete`;
export const API_share_account_admin_downgrade = (): string =>
  `api/v2/share/account-admin-downgrade`;
