import { GET_GROUPING } from '../../site-table/columns/grouping.store';

import { createElement } from 'src/app/core/helpers/dom';

import { ApiService } from '@core/http';
import { getSvg } from 'src/app/core/helpers/get-svg';
import { TCustomDropdownParams } from 'src/app/project/components/dropdown/AbstractDropdown';
import Tooltip from 'src/app/project/features/tooltip/Tooltip';
import { translate } from 'src/app/project/features/translate/translate';
import { TColumn } from '../../site-table/columns/column.model';
import { GET_COLUMNS } from '../../site-table/columns/columns.store';
import { GET_SORTING } from '../../site-table/columns/sorting.store';
import AbstractActionFieldsDropdown from '../actionDropdown/AbstractActionFieldsDropdown';
import { checkFieldVolyTooltip } from '../utils/check-field-voly-tooltip';
import { getFieldIcon } from '../utils/get-field-icon';

export default class GroupByDropdown extends AbstractActionFieldsDropdown {
  columns: TColumn[] = [];

  columnSectionElement: HTMLElement = null;

  constructor(_dropdownProperties: TCustomDropdownParams) {
    const modifiedColumns = _dropdownProperties.type === 'group' ? GET_GROUPING() : GET_SORTING();

    super(_dropdownProperties, modifiedColumns);

    this.columns = GET_COLUMNS();

    modifiedColumns.forEach((groupOrSortColumn) => {
      this.columns = this.columns.filter((column) => column.name !== groupOrSortColumn.name);
    });

    this.createContentElement();
  }

  // --------------------------------------------------
  // -------------------- ELEMENTS --------------------
  // --------------------------------------------------

  createContentElement(): HTMLElement {
    return createElement('div', {
      attrs: {
        class: '_groupByDropdown',
      },
      children: [this.createTitleElement(), this.createColumnSectionElement()],
    });
  }

  createTitleElement(): HTMLElement {
    return createElement('div', {
      attrs: {
        class: '_groupByDropdown__title',
      },
      children: [translate('select_a_field')],
    });
  }

  createColumnSectionElement(): HTMLElement {
    let children = [];

    if (this.columns.length) {
      children = [...this.columns.map((_column) => this.createFieldElement(_column))];
    } else {
      children.push(translate('no_more_fields_to_choose_from'));
    }

    this.columnSectionElement = createElement('div', {
      children,
    });

    return this.columnSectionElement;
  }

  createFieldElement(column: TColumn): HTMLElement {
    const fieldElement = createElement('div', {
      attrs: {
        class: 'dropdownItem dropdownItem__group',
      },
      eventListeners: {
        click: () => {
          this.onSelect(column);
          this.update();
          this.destroy();
        },
      },
      children: [createFieldIcon(this.apiService, column), createNameElement(column.name)],
    });

    return fieldElement;
  }
}

function createFieldIcon(apiService: ApiService, column: TColumn): HTMLElement {
  const iconPath = getFieldIcon(column);

  const iconElement = createElement('img', {
    attrs: {
      src: iconPath,
    },
  }) as HTMLImageElement;

  const iconElementWrapper = createElement('div', {
    attrs: {
      class: 'iconElementWrapper',
    },
    children: [iconElement],
  });

  getSvg(apiService, iconPath).then((svg) => {
    iconElementWrapper.innerHTML = svg;
  });

  const tooltip = new Tooltip({
    options: {},
    title: checkFieldVolyTooltip(column),
    mobileTooltip: false,
    trimTooltip: false,
    childTooltip: false,
    element: iconElementWrapper,
  });

  tooltip.createTooltip();

  return iconElementWrapper;
}

function createNameElement(name: string): HTMLElement {
  const element = createElement('span', {
    attrs: {
      class: 'dropdownItem__groupName',
    },
    children: [name],
  });

  createNameElementTooltip(element, name);

  return element;
}

function createNameElementTooltip(nameElement: HTMLElement, title: string): void {
  const nameTooltip = new Tooltip({
    options: {},
    title,
    mobileTooltip: false,
    trimTooltip: true,
    childTooltip: false,
    element: nameElement,
    trimElement: nameElement,
  });

  nameTooltip.createTooltip();
}
