import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { EDropdownWidth, TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { TColumn } from '../../site-table/columns/column.model';
import { GET_GROUPING } from '../../site-table/columns/grouping.store';
import { SiteOptionsService } from '../site-options.service';
import { GroupButtonDropdownComponent } from './group-button-dropdown/group-button-dropdown.component';

@Component({
  selector: 'pp-site-options-group-button',
  templateUrl: './site-options-group-button.component.html',
  styleUrls: ['./site-options-group-button.component.scss'],
})
export class SiteOptionsGroupButtonComponent implements OnInit {
  @Input() ppDataFetched: boolean;
  @ViewChild('groupDropdown') element: ElementRef;

  groupSelected: boolean;
  dropdownOpened: boolean;
  EIconPath = EIconPath;
  dropdown: TDropdown = this.dropdownService.getDropdown();
  dropdownVisible: boolean;
  groupDropdownBtnId = 'groupDropdownBtn';
  grouping: TColumn[] = GET_GROUPING();
  private dropdownWidth = EDropdownWidth.PX_427;

  constructor(
    private siteOptionsService: SiteOptionsService,
    private dropdownService: DropdownService,
  ) {}

  ngOnInit(): void {
    this.update();

    this.siteOptionsService.setGroupButton(this);
  }

  update(): void {
    this.grouping = GET_GROUPING();

    this.groupSelected = this.grouping.length > 0;
  }

  toggleGroupDropdown(): void {
    if (this.dropdown.visible && this.dropdown.buttonId === this.groupDropdownBtnId) {
      this.dropdownVisible = false;
      this.dropdownService.hideDropdown();
    } else {
      this.dropdownVisible = true;
      this.dropdownService.setData({});

      this.dropdownService.showDropdown(this.groupDropdownBtnId, GroupButtonDropdownComponent, {
        onClose: () => this.hideDropdown(),
        popper: {
          positionFixed: true,
          placement: 'bottom-start',
        },
        width: this.dropdownWidth,
      });
    }
  }

  hideDropdown(): void {
    this.dropdown.visible = false;
    this.dropdownVisible = false;
    this.update();
  }
}
