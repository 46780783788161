import { Component, Input, OnChanges } from '@angular/core';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-user-management-user-two-factor-authentication-chip',
  templateUrl: './user-management-user-two-factor-authentication-chip.component.html',
  styleUrls: ['./user-management-user-two-factor-authentication-chip.component.scss'],
})
export class UserManagementUserTwoFactorAuthenticationChipComponent implements OnChanges {
  @Input() ppTwoFactorAuthenticationEnabled: boolean;

  iconSrc: EIconPath;
  tooltipText: string;

  constructor(private translationPipe: TranslationPipe) {}

  ngOnChanges(): void {
    this.setTooltip();
    this.setIconSrc();
  }

  private setTooltip(): void {
    this.tooltipText = this.ppTwoFactorAuthenticationEnabled
      ? this.translationPipe.transform('2fa_um_enabled')
      : this.translationPipe.transform('2fa_um_not_enabled');
  }

  private setIconSrc(): void {
    this.iconSrc = this.ppTwoFactorAuthenticationEnabled
      ? EIconPath.ICON_NOTIFICATION_CHECK_14
      : EIconPath.ICON_CLOSE_14;
  }
}
