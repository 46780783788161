import { EPriority } from 'src/app/project/modules/points/priorities';
import { Fill, Stroke, Style, Circle, Icon } from 'ol/style.js';

export function createDraggingStyle(priority: EPriority, isMobileInput: boolean): Style[] {
  let pinColor = '#FFFFFF';
  let strokeColor = '#FFFFFF';
  let fillColor = '#FFFFFF';

  if (priority === EPriority.LOW) {
    pinColor = 'rgb(77, 160, 229)';
    strokeColor = 'rgba(77, 160, 229, 0.3)';
    fillColor = 'rgba(77, 160, 229, 0.2)';
  }

  if (priority === EPriority.MEDIUM) {
    pinColor = 'rgb(255, 206, 41)';
    strokeColor = 'rgba(255, 206, 41, 0.3)';
    fillColor = 'rgba(255, 206, 41, 0.2)';
  }

  if (priority === EPriority.HIGH) {
    pinColor = 'rgb(229, 79, 80)';
    strokeColor = 'rgba(229, 79, 80, 0.3)';
    fillColor = 'rgba(255, 206, 41, 0.2)';
  }

  if (!isMobileInput) {
    const style = [
      new Style({
        image: new Circle({
          radius: 6.5,
          fill: new Fill({
            color: pinColor,
          }),
          stroke: new Stroke({
            width: 30,
            color: strokeColor,
          }),
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: pinColor,
          width: 3,
        }),
        fill: new Fill({
          color: fillColor,
        }),
      }),
    ];

    return style;
  } else {
    return createMobileDraggingStyle(priority);
  }
}

function createMobileDraggingStyle(priority: EPriority): Style[] {
  const pinActiveSize = 46;
  const pinActiveSizeY = 100;
  const canvas = document.createElement('canvas');

  canvas.width = pinActiveSize;
  canvas.height = pinActiveSizeY;

  const context = canvas.getContext('2d');

  let pinColor = '#FFFFFF';
  let strokeColor = '#FFFFFF';
  let fillColor = '#FFFFFF';

  if (priority === EPriority.LOW) {
    pinColor = 'rgb(77, 160, 229)';
    strokeColor = 'rgba(77, 160, 229, 0.3)';
    fillColor = 'rgba(77, 160, 229, 0.2)';
  }

  if (priority === EPriority.MEDIUM) {
    pinColor = 'rgb(255, 206, 41)';
    strokeColor = 'rgba(255, 206, 41, 0.3)';
    fillColor = 'rgba(255, 206, 41, 0.2)';
  }

  if (priority === EPriority.HIGH) {
    pinColor = 'rgb(229, 79, 80)';
    strokeColor = 'rgba(229, 79, 80, 0.3)';
    fillColor = 'rgba(229, 79, 80, 0.2)';
  }

  context.fillStyle = pinColor;
  context.strokeStyle = strokeColor;
  context.lineWidth = 30;
  context.beginPath();
  context.arc(23, 30, 7, 0, 2 * Math.PI);
  context.fill();
  context.stroke();

  const style = [
    new Style({
      image: new Icon({
        src: canvas.toDataURL(),
        scale: 1,
        anchor: [0.48, 0.85],
        opacity: 1,
        imgSize: [pinActiveSize, pinActiveSizeY],
      }),
      zIndex: Infinity,
    }),
    new Style({
      image: new Circle({
        radius: 8,
        fill: new Fill({
          color: [255, 255, 255, 0.01],
        }),
      }),
      zIndex: Infinity,
    }),
    new Style({
      stroke: new Stroke({
        color: pinColor,
        width: 3,
      }),
      fill: new Fill({
        color: fillColor,
      }),
    }),
  ];

  return style;
}
