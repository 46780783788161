import { EWorkspaces } from './workspaces.enum';

export type TChangedWorkspace = {
  [workspaceId: string]: boolean;
};

let changedWorkspaces = false;
const changedWorkspace: TChangedWorkspace = {};

export function checkCustomWorkspaceId(): string {
  const isOverview = window.location.href.indexOf(EWorkspaces.OVERVIEW) !== -1;
  const isTimeline = window.location.href.indexOf(EWorkspaces.TIMELINE) !== -1;
  const isReminders = window.location.href.indexOf(EWorkspaces.REMINDERS) !== -1;

  let workspaceId = '';

  if (isOverview) {
    workspaceId = EWorkspaces.OVERVIEW;
  } else if (isTimeline) {
    workspaceId = EWorkspaces.TIMELINE;
  } else if (isReminders) {
    workspaceId = EWorkspaces.REMINDERS;
  }

  return workspaceId;
}

export function setChangedWorkspaces(changed: boolean): void {
  changedWorkspaces = changed;
}

export function setChangedWorkspace(workspaceId: string, set: boolean = true): void {
  changedWorkspace[workspaceId] = set;
}

export function getChangedWorkspaces(): boolean {
  return changedWorkspaces;
}

export function getChangedWorkspace(): TChangedWorkspace {
  return changedWorkspace;
}
