import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { getDragIcon } from 'src/app/core/helpers/dragging/get-drag-icon';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { ESavedView } from '../../models/saved-view.enum';
import { TSavedViews, TViewShort } from '../../models/saved-views.model';
import { SavedViewsService } from '../../saved-views.service';

@Component({
  selector: 'pp-saved-views-list',
  templateUrl: './saved-views-list.component.html',
  styleUrls: ['./saved-views-list.component.scss'],
})
export class SavedViewsListComponent {
  @ViewChild('viewsList') viewsList: ElementRef;
  @Input() ppSavedViews: TSavedViews;
  @Input() ppType: ESavedView;
  @Input() ppWorkspaceId: string;
  @Input() ppInjectedDropdownService: DropdownService;

  EIconPath = EIconPath;

  ESavedView = ESavedView;

  destinationIndex: number = null;
  draggedView: TViewShort = null;
  rowHeight = 18;
  rowGap = 8;
  linePosition = this.rowHeight + this.rowGap;
  sortedViews: TViewShort[];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private savedViewsService: SavedViewsService,
    private activeService: ActiveService,
  ) {}

  onDragStart(event: DragEvent, i: number, view: TViewShort): void {
    const img = new Image();

    this.draggedView = view;
    this.draggedView.index = i;
    img.src = getDragIcon();
    this.linePosition =
      this.viewsList.nativeElement.children[i].offsetTop -
      this.rowHeight -
      this.rowGap +
      this.rowHeight / 2;

    event.dataTransfer.setData('text/plain', view.name);
    event.dataTransfer.setDragImage(img, 0, 0);
  }

  onDragOver(event: DragEvent, index: number, view: TViewShort): void {
    event.preventDefault();
    event.stopPropagation();

    const itemRowElement = this.document.getElementById('itemRow-' + view.id);
    const targetElementCoords = itemRowElement.getBoundingClientRect();
    const elementCenterY = (targetElementCoords.bottom + targetElementCoords.top) / 2;

    let offset = 0;

    this.destinationIndex = index;
    let linePositionIndex = this.destinationIndex;

    if (event.clientY > elementCenterY) {
      this.destinationIndex += 1;

      linePositionIndex = this.destinationIndex;

      if (index >= this.draggedView.index) {
        this.destinationIndex -= 1;
      }
    } else {
      if (index > this.draggedView.index) {
        this.destinationIndex -= 1;
      }
    }

    if (linePositionIndex > this.viewsList.nativeElement.children.length - 1) {
      linePositionIndex = this.viewsList.nativeElement.children.length - 1;

      offset = this.rowHeight + this.rowGap;
    }

    if (linePositionIndex === 0) {
      this.linePosition = this.rowGap;
    } else {
      this.linePosition =
        this.viewsList.nativeElement.children[linePositionIndex].offsetTop -
        this.rowHeight -
        this.rowGap -
        this.rowHeight / 2 +
        offset;
    }
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragEnd(): void {
    const views: TViewShort[] =
      this.ppType === ESavedView.SHARED
        ? this.ppSavedViews.shared.views
        : this.ppSavedViews.personal.views;
    if (views) {
      const workspaceId = this.activeService.getActiveWorkspaceId();

      if (this.draggedView.index !== this.destinationIndex && this.destinationIndex !== null) {
        const view = views.find((_column) => _column.name === this.draggedView.name);
        this.savedViewsService
          .reorderViews(workspaceId, view.id, this.ppType, this.destinationIndex)
          .subscribe();
      }
      this.draggedView = null;
      this.destinationIndex = null;
    }
  }
}
