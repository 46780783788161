import { Component, OnDestroy, OnInit } from '@angular/core';

import { PasswordResetService } from './password-reset.service';
import { PromptService } from '../../../components/prompt/prompt.service';
import { WhiteLabelService } from '../../white-label/white-label.service';

import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { AUTH_EMAIL_REGEX } from '../../../shared/constants/auth.constants';
import { blurInput } from 'src/app/core/helpers/blur-input';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EIconPath } from '../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  email: string;
  processing: boolean;
  requestSent: boolean;
  EIconPath = EIconPath;

  constructor(
    private passwordResetService: PasswordResetService,
    private promptService: PromptService,
    private whiteLabelService: WhiteLabelService,
    private translationPipe: TranslationPipe,
  ) {}

  ngOnInit() {
    this.email = this.passwordResetService.getEmail();
    this.processing = false;
    this.requestSent = false;

    this.whiteLabelService.setDefaultStyle();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  resetPassword(): void {
    if (this.processing) {
      return;
    }

    if (!AUTH_EMAIL_REGEX.test(this.email)) {
      this.showEmailInvalidError();

      return;
    }

    this.processing = true;

    this.passwordResetService
      .resetPassword(this.email)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.requestSent = true;
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  private showEmailInvalidError(): void {
    const promptText = this.translationPipe.transform('prompt_email_invalid');

    this.promptService.showError(promptText);
  }

  blurInput(event: Event): void {
    blurInput(event.target);
  }
}
