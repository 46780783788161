import { Component, OnInit } from '@angular/core';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';

import { DeviceService } from 'src/app/core/services/device.service';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { DropdownService } from '../../../components/dropdown/dropdown-service/dropdown.service';
import { TCustomField, TCustomFieldList } from '../custom-fields.model';

@Component({
  selector: 'app-custom-field-list-select-dropdown',
  templateUrl: './custom-field-list-select-dropdown.component.html',
  styleUrls: ['./custom-field-list-select-dropdown.component.scss'],
})
export class CustomFieldListSelectDropdownComponent implements OnInit {
  dropdown: TDropdown;
  filterListItemsBy = '';

  isMobile = false;
  hovered = false;
  focused = false;
  scrolledToBottom = false;
  dropdownWidth: string = '305px';

  item: TPointCustomField;
  selected: string;
  field: TCustomField;

  constructor(
    private dropdownService: DropdownService,
    private deviceService: DeviceService,
  ) {}

  ngOnInit(): void {
    this.dropdown = this.dropdownService.getDropdown();
    this.isMobile = this.deviceService.isMobile();

    if (!this.dropdown.data) {
      throw new Error('Data for this dropdown is required');
    }

    if (!this.dropdown.callback) {
      throw new Error('Callback for this dropdown is required');
    }

    this.item = this.dropdown.data.field;
    this.selected = this.dropdown.data.selected;
    this.field = this.dropdown.data.field;

    if (this.dropdown.data.width) {
      this.dropdownWidth = this.dropdown.data.width;
    }

    if (this.isMobile) {
      this.dropdownWidth = 'unset';
    }
  }

  select(item: TCustomFieldList): void {
    this.dropdown.callback(item);
    this.hideDropdown();
  }

  hideDropdown(): void {
    this.dropdownService.hideDropdown();
  }

  handleScroll(scrolledToBottom: boolean): void {
    this.scrolledToBottom = scrolledToBottom;
  }

  clearList(): void {
    this.dropdown.callback(null);
    this.hideDropdown();
  }

  setSearchBox(keyword: string): void {
    this.filterListItemsBy = keyword;
  }
}
