import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { TDashlet } from 'src/app/project/modules/preferences/preferences.model';

import { ModalService } from 'src/app/project/components/modal/modal.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventDashboard,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { AccountService } from '../../../account/account-service/account.service';
import { WorkspaceService } from '../../../workspace/workspace.service';
import { EDashletType } from '../../dashlets-enums';
import { EditCostComparisonDashletModalComponent } from './edit-cost-comparison-dashlet-modal/edit-cost-comparison-dashlet-modal.component';
import { TEditCostComparisonModalData } from './edit-cost-comparison-dashlet-modal/edit-cost-comparison-modal.consts';
import { getAccountHeaderAccountName } from './utils/get-dashlet-header-account-name';
import { getDashletHeaderSiteName } from './utils/get-dashlet-header-site-name';

@Component({
  selector: 'pp-dashlet-head',
  templateUrl: './dashlet-head.component.html',
  styleUrls: ['./dashlet-head.component.scss'],
})
export class DashletHeadComponent implements OnChanges {
  @Input() ppDashlet: TDashlet;
  @Input() ppAssetId: string;
  @Input() ppCanEdit: boolean;
  @Input() ppAccountName: string;
  @Input() ppWorkspaces: string[]; // hacks around Angular change detection so it reruns when you change workspace ids
  @Output() ppToggleFlip = new EventEmitter();

  EIconPath = EIconPath;
  siteName: string;
  private sitesNames: string[];
  siteTooltip: string;
  accountName: string;
  private accountNames: string[] = [];
  accountTooltip: string;
  EDashletType = EDashletType;
  title: string;
  tooltipText: string;

  constructor(
    private workspaceService: WorkspaceService,
    private accountService: AccountService,
    private translationPipe: TranslationPipe,
    private modalService: ModalService,
  ) {}

  ngOnChanges(): void {
    const accountData = getAccountHeaderAccountName(
      this.ppAccountName,
      this.ppWorkspaces,
      this.workspaceService.getWorkspaces(),
      this.accountService.getAccounts(),
    );
    this.accountName = accountData.accountName;
    this.accountTooltip = accountData.tooltip;
    const siteData = getDashletHeaderSiteName(
      this.ppWorkspaces,
      this.workspaceService.getWorkspaces(),
    );
    this.siteName = siteData.siteName;
    this.siteTooltip = siteData.tooltip;
    this.setTitle();
  }

  flipDashlet(): void {
    if (this.ppDashlet.name === EDashletType.COST_COMPARISON) {
      this.modalService.setData<TEditCostComparisonModalData>({
        dashlet: this.ppDashlet,
        assetId: this.ppAssetId,
      });

      this.modalService.showModal(EditCostComparisonDashletModalComponent);

      return;
    }

    logEventInGTAG(EGoogleEventDashboard.DASHBOARD__DASHLET__FLIP, {
      event_category: EGoogleEventCategory.DASHBOARD,
      event_details: this.ppDashlet.name,
    });

    this.ppToggleFlip.emit();
  }

  private setTitle(): void {
    switch (this.ppDashlet.name) {
      case EDashletType.ACTIVITIES:
        this.title = this.translationPipe.transform('activity');
        this.tooltipText = '';
        break;
      case EDashletType.CURRENT_STATUS:
        this.title = this.translationPipe.transform('dashlets_live_status');
        this.tooltipText = '';
        break;
      case EDashletType.CURRENT_PRIORITY:
        this.title = this.translationPipe.transform('dashlets_live_priority');
        this.tooltipText = '';
        break;
      case EDashletType.OVER_TIME_STATUS:
        this.title = this.translationPipe.transform('dashlet_over_time_status');
        this.tooltipText = '';
        break;
      case EDashletType.OVER_TIME_PRIORITY:
        this.title = this.translationPipe.transform('dashlet_over_time_priority');
        this.tooltipText = '';
        break;
      case EDashletType.CURRENT_STACKED_STATUS:
        this.title = this.translationPipe.transform('dashlet_stacked_status');
        this.tooltipText = '';
        break;
      case EDashletType.COST_COMPARISON:
        this.title = this.translationPipe.transform('dashlet_cost_comparison');
        this.tooltipText = this.translationPipe.transform('dashlet_cost_comparison_tooltip');
        break;
      default:
        this.tooltipText = '';
        this.title = '';
    }
  }
}
