import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PointsHelperService } from 'src/app/project/modules/points/points-helper.service';
import { EStatus } from 'src/app/project/shared/enums/status.enum';

@Component({
  selector: 'pp-status-button',
  templateUrl: './status-button.component.html',
  styleUrls: ['./status-button.component.scss'],
})
export class StatusButtonComponent {
  @Input() ppStatus: EStatus;
  @Input() ppDisabled = false;
  @Input() ppProcessing = false;

  @Output() ppAction = new EventEmitter();

  statusName: string;

  constructor(private pointsHelperService: PointsHelperService) {}

  ngOnChanges() {
    this.statusName = this.humanizeStatusName(this.ppStatus);
  }

  action(): void {
    this.ppAction.emit();
  }

  humanizeStatusName(status: EStatus): string {
    return this.pointsHelperService.humanizeStatusName(status);
  }

  statusToColor(status: EStatus): string {
    return this.pointsHelperService.statusPriorityToColor(status);
  }
}
