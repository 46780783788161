import { ApiService } from '@core/http';
import { createElement } from 'src/app/core/helpers/dom';
import { humanizeStatus } from 'src/app/project/modules/points/statuses';
import { getTableStatusSrc } from '../../../../../shared/status-to-image';
import { TColumn } from '../../columns/column.model';
import { GET_TABLE } from '../../table.ui.store';
import { createCellElement } from '../createCellElement';

export function createStatusElement(
  index: number,
  column: TColumn,
  apiService: ApiService,
): HTMLElement {
  const table = GET_TABLE();

  const imageElementWrapper = createElement('img', {
    attrs: {
      class: 'statusIcon statusIcon--gray statusIcon--table',
      title: humanizeStatus(table.points[index].status),
      src: getTableStatusSrc(table.points[index].status),
    },
  });

  return createCellElement({
    index: column.index,
    width: `${column.width}px`,
    center: true,
    child: imageElementWrapper,
  });
}
