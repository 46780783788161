<pp-modal>
  <ng-container heading> {{ 'export' | ppTranslate }} </ng-container>

  <ng-container body>
    <pp-point-export-warning *ngIf="showCsvExportWarning"></pp-point-export-warning>

    <div>
      <pp-select
        [ppItems]="exportTypes"
        [ppSelectedItem]="exportData.selected"
        (ppSelectItem)="selectItem($event)"
        [ppNoSearchBar]="true"
      >
        <ng-container selected>
          <p class="siteOptionsExportModal__select-content" *ngIf="exportData.selected">
            <img
              class="siteOptionsExportModal__select-icon icon-gap--right2x"
              [src]="exportData.selected.pngIcon"
            />
            {{ exportData.selected.label }}
          </p>

          <p class="siteOptionsExportModal__select-placeholder" *ngIf="!exportData.selected">
            {{ 'choose_format' | ppTranslate }}
          </p>
        </ng-container>
      </pp-select>
    </div>

    <div
      *ngIf="
        exportData.selected?.value === EExportTypeName.SIMPLE_LIST ||
        exportData.selected?.value === EExportTypeName.DETAILED_LIST ||
        exportData.selected?.value === EExportTypeName.PDF ||
        exportData.selected?.value === EExportTypeName.WORD ||
        exportData.selected?.value === EExportTypeName.WORD_SIMPLE
      "
    >
      <h5 class="modal__body-title--margin">{{ 'page_format' | ppTranslate }}</h5>

      <div class="siteOptionsExportModal__radio-buttons">
        <pp-radio
          *ngFor="let option of exportData.selected?.outputFormat"
          [ppChecked]="option.value === exportData.format"
          (ppOnValueChange)="toggleFormat(option)"
          ppStyle="blue"
        >
          <label class="control-custom control-custom--radio">
            {{ option.label }}
          </label>
        </pp-radio>
      </div>
    </div>

    <div *ngIf="exportData.selected?.value === EExportTypeName.SITE_PLAN">
      <h5 class="modal__body-title--margin">{{ 'page_format' | ppTranslate }}</h5>

      <div class="siteOptionsExportModal__radio-buttons">
        <pp-radio
          *ngFor="let option of exportData.selected?.outputFormat"
          [ppChecked]="option.value === exportData.format"
          (ppOnValueChange)="toggleFormat(option)"
          ppStyle="blue"
        >
          <label class="control-custom control-custom--radio">
            {{ option.label }}
          </label>
        </pp-radio>
      </div>
    </div>

    <div
      *ngIf="
        exportData.selected?.value === EExportTypeName.SIMPLE_LIST ||
        exportData.selected?.value === EExportTypeName.WORD_SIMPLE ||
        exportData.selected?.value === EExportTypeName.DETAILED_LIST ||
        exportData.selected?.value === EExportTypeName.ZIP ||
        exportData.selected?.value === EExportTypeName.PDF ||
        exportData.selected?.value === EExportTypeName.WORD
      "
    >
      <h5
        class="modal__body-title--margin"
        *ngIf="
          ((exportData.selected?.value === EExportTypeName.SIMPLE_LIST ||
            exportData.selected?.value === EExportTypeName.WORD_SIMPLE) &&
            account?.accountFeatures.pdfSignatures) ||
          (exportData.selected?.value !== EExportTypeName.SIMPLE_LIST &&
            exportData.selected?.value !== EExportTypeName.WORD_SIMPLE)
        "
      >
        {{ 'include' | ppTranslate }}
      </h5>

      <div [ngClass]="isBrowserSafari ? '' : 'siteOptionsExportModal__options'">
        <ng-container *ngFor="let data of selectedData">
          <div class="siteOptionsExportModal__row">
            <pp-checkbox
              [ppChecked]="exportData[data.id]"
              [ppDisabled]="fileExportDisabled && data.id === EExportData.FILES"
              (ppOnValueChange)="toggleValue(data.id)"
            >
              <label class="control-custom control-custom--checkbox">
                {{ data.name }}
              </label>
            </pp-checkbox>
          </div>
        </ng-container>
      </div>

      <div
        class="modal__body-container--margin-bottom"
        *ngIf="
          imageSizes &&
          (exportData.selected?.value === EExportTypeName.DETAILED_LIST ||
            exportData.selected?.value === EExportTypeName.WORD ||
            exportData.selected?.value === EExportTypeName.ZIP ||
            exportData.selected?.value === EExportTypeName.PDF)
        "
      >
        <h5 class="modal__body-title--margin modal__body-title--images">
          {{ 'images_size' | ppTranslate }}
          <pp-icon
            [ppSrc]="EIconPath.ICON_BADGE_INFO"
            ppColor="grey-400"
            ppClass="setSize14"
            ppTooltip
            [ppTitle]="'prompt_image_export' | ppTranslate"
          ></pp-icon>
        </h5>

        <div class="siteOptionsExportModal__options">
          <div *ngFor="let option of imageSizes" class="siteOptionsExportModal__row">
            <pp-radio
              [ppChecked]="option.value === exportData.imagesSize"
              (ppOnValueChange)="toggleImageSize(option)"
              [ppDisabled]="!exportData.showImages"
              ppStyle="blue"
            >
              <label class="control-custom control-custom--radio">
                {{ option.label }}
              </label>
            </pp-radio>
          </div>
        </div>

        <pp-toggle
          ppId="toggleImageFilterFeature"
          ppStyle="blue"
          class="editFeature__toggle"
          (ppAction)="toggleImageFilter()"
          [ppChecked]="exportData.imagesDatesRange"
          [ppDisabled]="!exportData.showImages"
          [ppReverseLayout]="true"
        >
          <span class="toggleButtonText"> {{ 'export_images_based_on_date' | ppTranslate }} </span>

          <i
            ppTooltip
            class="fa fa-info-circle toggleButtonIcon"
            [ppTitle]="'image_dates_export_info' | ppTranslate"
          ></i>
        </pp-toggle>
      </div>

      <div
        *ngIf="
          exportData.selected?.value === EExportTypeName.DETAILED_LIST ||
          exportData.selected?.value === EExportTypeName.WORD ||
          exportData.selected?.value === EExportTypeName.ZIP ||
          exportData.selected?.value === EExportTypeName.PDF ||
          exportData.selected?.value === EExportTypeName.WORD_SIMPLE ||
          exportData.selected?.value === EExportTypeName.SIMPLE_LIST
        "
      >
        <pp-toggle
          ppId="toggleURLFeature"
          ppStyle="blue"
          class="editFeature__toggle"
          [class.editFeature__toggle--simple]="
            exportData.selected?.value === EExportTypeName.SIMPLE_LIST
          "
          (ppAction)="toggleURL()"
          [ppChecked]="exportData.showUrl"
          [ppReverseLayout]="true"
        >
          <span class="toggleButtonText"> {{ 'add_url_link' | ppTranslate }} </span>

          <i
            ppTooltip
            class="fa fa-info-circle toggleButtonIcon"
            [ppTitle]="'url_export_info' | ppTranslate"
          ></i>
        </pp-toggle>
      </div>

      <ng-container
        *ngIf="
          workspace &&
          (exportData.selected?.value === EExportTypeName.ZIP ||
            exportData.selected?.value === EExportTypeName.PDF ||
            exportData.selected?.value === EExportTypeName.DETAILED_LIST) &&
          workspace.customFields.length > 0
        "
      >
        <h5 class="modal__body-title--margin">{{ 'custom_fields' | ppTranslate }}:</h5>

        <div [ngClass]="isBrowserSafari ? '' : 'siteOptionsExportModal__options'">
          <div class="siteOptionsExportModal__row" *ngFor="let field of customFields">
            <pp-checkbox
              [ppChecked]="field.enabled"
              (ppOnValueChange)="toggleCustomField(field)"
              [ppDisabled]="!exportData.showCustomFields"
            >
              <label
                ppTooltip
                [ppTrimTooltip]="true"
                [ppTitle]="customFieldList[workspace.workspaceId][field.id]?.label"
                class="control-custom control-custom--checkbox"
              >
                {{ customFieldList[workspace.workspaceId][field.id].label }}
              </label>
            </pp-checkbox>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'export' | ppTranslate"
      [ppProcess]="processing"
      [ppDisabled]="!exportData.selected"
      (ppCancel)="hideModal()"
      (ppConfirm)="export()"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
