import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { of, Subject } from 'rxjs';

import { VerifyService } from './verify.service';
import { PromptService } from '../../../components/prompt/prompt.service';
import { WhiteLabelService } from '../../white-label/white-label.service';

import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIconPath } from '../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit, OnDestroy {
  verifyStatus: {
    processing: boolean;
    success: boolean;
    error: boolean;
  };

  private paramId: string;
  private readonly destroy$ = new Subject<void>();
  EIconPath = EIconPath;

  constructor(
    private verifyService: VerifyService,
    private activatedRoute: ActivatedRoute,
    private promptService: PromptService,
    private whiteLabelService: WhiteLabelService,
    private translationPipe: TranslationPipe,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.paramId = params['params'].id;
    });

    this.verifyStatus = {
      processing: false,
      success: false,
      error: false,
    };

    this.verify();
    this.whiteLabelService.setDefaultStyle();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  verify(): void {
    this.verifyStatus.processing = true;

    this.verifyService
      .verify(this.paramId)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.verifyStatus.success = true;
        }),
        catchError(() => {
          this.verifyStatus.error = true;
          const promptText = this.translationPipe.transform('prompt_error');

          this.promptService.showError(promptText);

          return of();
        }),
        finalize(() => {
          this.verifyStatus.processing = false;
        }),
      )
      .subscribe();
  }
}
