import { Component, Input, OnInit } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { getNotificationReasonText } from '../../utils/notification-reason';
import { ENotificationReason } from '../../utils/notification-reason.enum';

@Component({
  selector: 'pp-notification-location',
  templateUrl: './notification-location.component.html',
  styleUrls: ['../notification.component.scss'],
})
export class NotificationLocationComponent implements OnInit {
  @Input() ppNotificationReason: ENotificationReason;

  notificationReason: string;
  EIconPath = EIconPath;

  constructor() {}

  ngOnInit(): void {
    this.notificationReason = getNotificationReasonText(this.ppNotificationReason);
  }
}
