import { createElement } from 'src/app/core/helpers/dom';
import { markKeyword } from 'src/app/project/modules/site/site-table/body-elements/body-cells-search';

import { GET_SEARCH_VALUE } from './account-list.ui.store';

export function createMarkedKeywordSidePanelElement(text: string): Element {
  const markedKeywordElement = createElement('span', {
    attrs: {
      class: ['sidePanelName--inner'],
    },
  });

  const keyword: string = GET_SEARCH_VALUE();

  markKeyword(text, markedKeywordElement, keyword);

  return markedKeywordElement;
}
