import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';
import {
  TAdvancedFilterPercentage,
  TAdvancedFilterSingle,
  TFilterPercentageOptions,
} from '../../../../models/advanced-filter.model';
import { getAdvancedPercentageOptions } from './get-advanced-percentage-utils';

@Component({
  selector: 'pp-advanced-filter-percentage',
  templateUrl: './advanced-filter-percentage.component.html',
  styleUrls: ['./advanced-filter-percentage.component.scss'],
})
export class AdvancedFilterPercentageComponent {
  @Input() ppFilter: TAdvancedFilterSingle;
  @Output() ppFilterChange = new EventEmitter<TAdvancedFilterSingle>();

  value: number;
  option: TFilterPercentageOptions;
  filter: TAdvancedFilterPercentage;

  advancedFilterPercentageOptions = getAdvancedPercentageOptions();
  EAdvancedFilterOptions = EAdvancedFilterOptions;

  ngOnChanges(): void {
    this.filter = this.ppFilter as TAdvancedFilterPercentage;

    this.value = this.filter.value;
    this.option = this.filter.option;
  }

  changeFieldCondition(newCondition: TFilterPercentageOptions): void {
    this.ppFilter.option = newCondition;
    this.option = newCondition;
    this.applyChanges();
  }

  applyChanges(): void {
    this.ppFilterChange.emit(this.ppFilter);
  }

  changeFieldValue(value: number): void {
    this.ppFilter.value = value;
    this.applyChanges();
  }
}
