import {
  TWorkspaceSimpleResponse,
  TAccountSimpleResponse,
} from 'src/app/project/view-models/account-simple-response.model';
import { GET_COLLAPSED_ACCOUNTS } from '../utils/account-list.ui.store';
import { TFlattenedAccount } from './account-list-flatten-account';

export function checkNewAccountData({
  newAccountData,
  oldAccountData,
}: {
  newAccountData: TAccountSimpleResponse[];
  oldAccountData: TAccountSimpleResponse[];
}): {
  changed: (TAccountSimpleResponse | TWorkspaceSimpleResponse)[];
  missing: (TAccountSimpleResponse | TWorkspaceSimpleResponse)[];
} {
  const missing: (TAccountSimpleResponse | TWorkspaceSimpleResponse)[] = [];
  const changed: (TAccountSimpleResponse | TWorkspaceSimpleResponse)[] = [];

  newAccountData.forEach((_account) => {
    const account = oldAccountData.find((__account) => __account.id === _account.id);

    if (account) {
      if (account.name !== _account.name) {
        changed.push(_account);
      }

      if (GET_COLLAPSED_ACCOUNTS().has(account.id)) {
        _account.workspaces = account.workspaces;
      } else {
        _account.workspaces.forEach((_workspace) => {
          const workspace = account.workspaces.find(
            (__workspace) => __workspace.workspaceId === _workspace.workspaceId,
          );

          if (workspace) {
            if (workspace.name !== _workspace.name) {
              changed.push(_workspace);
            }
          } else {
            _workspace.accountId = _account.id;

            missing.push(_workspace);
          }
        });
      }
    } else {
      missing.push(_account);
    }
  });

  return { missing, changed };
}

export function checkOldAccountData({
  oldAccountData,
  newAccountData,
}: {
  oldAccountData: TAccountSimpleResponse[];
  newAccountData: TAccountSimpleResponse[];
}): (TAccountSimpleResponse | TWorkspaceSimpleResponse)[] {
  const deleted: (TAccountSimpleResponse | TWorkspaceSimpleResponse)[] = [];

  oldAccountData.forEach((_account) => {
    const account = newAccountData.find((__account) => __account.id === _account.id);

    if (account) {
      _account.workspaces.forEach((_workspace) => {
        const workspace = account.workspaces.find(
          (__workspace) => __workspace.workspaceId === _workspace.workspaceId,
        );

        if (!workspace) {
          deleted.push(_workspace);
        }
      });
    } else {
      deleted.push(_account);
    }
  });

  return deleted;
}

export function checkMissingElements(
  missing: (TAccountSimpleResponse | TWorkspaceSimpleResponse)[],
  accountsFlattened: TFlattenedAccount[],
): number[] {
  const missingIndexes: number[] = [];

  missing.forEach((_missingObject) => {
    const missingAccountObject = _missingObject as TAccountSimpleResponse;

    if (missingAccountObject.workspaces) {
      const index = accountsFlattened.findIndex(
        (_account) => _account.id === missingAccountObject.id,
      );

      missingIndexes.push(index);
    } else {
      const missingWorkspaceObject = _missingObject as TWorkspaceSimpleResponse;

      const index = accountsFlattened.findIndex(
        (_workspace) => _workspace.workspaceId === missingWorkspaceObject.workspaceId,
      );

      missingIndexes.push(index);
    }
  });

  return missingIndexes;
}

export function checkChangedElements(
  changed: (TAccountSimpleResponse | TWorkspaceSimpleResponse)[],
): number[] {
  const changedIndexes: number[] = [];

  changed.forEach((_changedObject) => {
    const changedAccountObject = _changedObject as TAccountSimpleResponse;

    if (changedAccountObject.workspaces) {
      const index = this.accountsFlattened.findIndex(
        (_account) => _account.id === changedAccountObject.id,
      );

      changedIndexes.push(index);
    } else {
      const changedWorkspaceObject = _changedObject as TWorkspaceSimpleResponse;

      const index = this.accountsFlattened.findIndex(
        (_workspace) => _workspace.workspaceId === changedWorkspaceObject.workspaceId,
      );

      changedIndexes.push(index);
    }
  });

  return changedIndexes;
}

export function checkDeletedIndexes(
  deleted: (TAccountSimpleResponse | TWorkspaceSimpleResponse)[],
): number[] {
  const deletedIndexes: number[] = [];

  deleted.forEach((_deletedObject) => {
    const deletedAccountObject = _deletedObject as TAccountSimpleResponse;

    if (deletedAccountObject.workspaces) {
      const index = this.virtualScroller.data.findIndex(
        (_account) => _account.id === deletedAccountObject.id,
      );

      deletedIndexes.push(index);
    } else {
      const deletedWorkspaceObject = _deletedObject as TWorkspaceSimpleResponse;

      const index = this.virtualScroller.data.findIndex(
        (_workspace) => _workspace.workspaceId === deletedWorkspaceObject.workspaceId,
      );

      deletedIndexes.push(index);
    }
  });

  return deletedIndexes;
}
