import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PreviousPageService } from 'src/app/core/services/previous-page.service';
import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { getDefaultFilter } from 'src/app/project/modules/filters/site-filter-data-service/default-filter';
import { SetSiteFilter } from 'src/app/project/modules/filters/site-filter.actions';
import { TFilters } from 'src/app/project/modules/filters/site-filter.model';
import { FilterAssetService } from 'src/app/project/modules/preferences/filter-asset.service';
import { createSitesFilter } from 'src/app/project/modules/site/site-overview/utils/create-sites-filter';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { EWorkspaces } from 'src/app/project/modules/workspace/workspaces.enum';
import { TAsset } from '../../../asset-service/asset.consts';
import { FleetService } from '../../../fleet-service/fleet.service';

@Injectable({
  providedIn: 'root',
})
export class AssetCardRedirectService {
  constructor(
    private accountService: AccountService,
    private workspaceService: WorkspaceService,
    private store: Store,
    private router: Router,
    private previousPageService: PreviousPageService,
    private filterAssetService: FilterAssetService,
    private fleetService: FleetService,
  ) {}

  openOverviewFromAsset(asset: TAsset): void {
    const routerLink = '/site/overview';

    this.setOverviewFilters(asset);
    this.previousPageService.setPreviousFleetUrl(this.router.url);

    this.router.navigate([routerLink]);
  }

  openSiteWithRedFlags(workspaceId: string): void {
    const routerLink = `/site/${workspaceId}`;
    this.previousPageService.setPreviousSiteUrl(this.router.url);
    const fleetId = this.fleetService.getActiveFleetId();
    const fleet = this.fleetService.getFleet(fleetId);
    const fleetStatuses = fleet.statusSettings;

    this.filterAssetService.setOpeningSiteFromAsset(true, fleetStatuses, true);

    this.router.navigate([routerLink]);
  }

  private setOverviewFilters(asset: TAsset) {
    const filters = getDefaultFilter();
    const accounts = this.accountService.getAccounts();

    createSitesFilter(accounts, filters, this.workspaceService.getWorkspaces());

    this.setFilterSites(filters, asset);
    this.setFilterStatuses(filters, asset);

    this.store.dispatch(
      new SetSiteFilter({
        filter: filters,
        workspaceId: EWorkspaces.OVERVIEW,
      }),
    );
  }

  private setFilterSites(filters: TFilters, asset: TAsset) {
    filters.sites.forEach((account) => {
      account.workspaces.forEach((workspace) => {
        const workspaceId = workspace.id;

        const workspaceIsPartOfAsset = asset.sites.find((site) => {
          return site.workspaceId === workspaceId;
        });

        if (workspaceIsPartOfAsset) {
          workspace.value = true;
          workspace.appliedValue = true;
        } else {
          workspace.value = false;
          workspace.appliedValue = false;
        }
      });
    });
  }

  private setFilterStatuses(filters: TFilters, asset: TAsset) {
    const fleet = this.fleetService.getFleet(asset.fleetId);

    filters.status.forEach((filterStatus) => {
      const statusSettings = fleet.statusSettings[filterStatus.label];

      filterStatus.value = statusSettings.include;
      filterStatus.appliedValue = statusSettings.include;
    });
  }
}
