import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { TPoint } from 'src/app/project/modules/points/points.model';
import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { ReminderSettingsDropdownComponent } from '../reminder-settings-dropdown/reminder-settings-dropdown.component';

import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { PointAttachmentsService } from '../../../points/point-modal/point-attachments/point-attachments.service';
import { TReminderSettingsDropdownData } from '../reminder-settings-dropdown/reminder-settings-dropdown.model';

@Component({
  selector: 'pp-reminder-element',
  templateUrl: './reminder-element.component.html',
  styleUrls: ['./reminder-element.component.scss'],
})
export class ReminderElementComponent implements OnChanges, OnInit, OnDestroy {
  @Input() ppId: string;
  @Input() ppPointId: string;
  @Input() ppWorkspaceId: string;
  @Input() ppDate: number;
  @Input() ppDescription: string;
  @Input() ppFetched = false;
  @Input() ppOffline = false;
  @Input() ppPoint: TPoint;

  private readonly destroy$ = new Subject<void>();

  private dropdown: TDropdown = this.dropdownService.getDropdown();
  private optionsDropdownVisible = false;
  workspace: TWorkspace = null;
  private point: TPoint;

  private workspaces$: Observable<TWorkspacesById>;
  private workspaces: TWorkspacesById;
  EIconPath = EIconPath;

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
    }>,
    private dropdownService: DropdownService,
    private workspaceService: WorkspaceService,
    private router: Router,
    private pointAttachmentsService: PointAttachmentsService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
  }

  ngOnInit(): void {
    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspaces = workspaces;
    });
  }

  ngOnChanges(): void {
    this.workspace = this.workspaceService.findWorkspace(this.ppWorkspaceId);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  openPoint(): void {
    const attachmentUploading = this.pointAttachmentsService.getAttachmentUploading();

    if (this.ppPointId && !attachmentUploading) {
      this.router.navigate(['/reminders', 'point', this.ppPointId]);
    }
  }

  openSettings(event: MouseEvent, buttonId: string): void {
    event.preventDefault();
    event.stopImmediatePropagation();

    if (!this.ppOffline) {
      if (this.dropdown.visible && this.dropdown.buttonId === buttonId) {
        this.optionsDropdownVisible = false;
        this.dropdownService.hideDropdown();
      } else {
        const dropdownData: TReminderSettingsDropdownData = {
          _id: this.ppPointId,
          date: this.ppDate,
          reminderId: this.ppId,
          pointName: this.point?.title,
          note: this.ppDescription,
        };

        this.dropdownService.setData(dropdownData);

        this.optionsDropdownVisible = true;

        this.dropdownService.showDropdown(buttonId, ReminderSettingsDropdownComponent, {
          onClose: () => (this.optionsDropdownVisible = false),
        });
      }
    }
  }
}
