export enum ETimelineExportColumn {
  EMPTY = 'empty',
  ID = 'ID',
  TITLE = 'Title',
  PRIORITY = 'Priority',
  STATUS = 'Status',
  START_DATE = 'Start Date',
  END_DATE = 'End Date',
  DURATION = 'Duration (Days)',
}
