import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { REMINDERS_ALERT_COOKIE_NAME } from 'src/app/project/modules/reminders/reminders.constants';
import { environment } from 'src/environments/environment';
import { isBrowserSafari } from '../../core/helpers/device';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(@Inject(DOCUMENT) private document: Document) {
    const cookies = this.document.cookie.split(';');

    let cookiehubCookies = 0;

    for (let i = 0; i < cookies.length; i++) {
      if (cookies[i].includes('cookiehub')) {
        cookiehubCookies += 1;
      }
    }

    if (cookiehubCookies > 1) {
      this.document.cookie =
        'cookiehub' + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.clone.pinpointworks.com';
      this.document.cookie =
        'cookiehub' + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=clone.pinpointworks.com';
      this.document.cookie =
        'cookiehub' + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.app.pinpointworks.com';
      this.document.cookie =
        'cookiehub' + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=app.pinpointworks.com';
      this.document.cookie = 'cookiehub' + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;';
    }
  }

  readCookie(cookieName: string): string {
    const nameEQ = cookieName + '=';
    const cookies = this.document.cookie.split(';');

    for (let i = 0; i < cookies.length; i += 1) {
      let cookie = cookies[i];

      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1, cookie.length);
      }

      if (cookie.indexOf(nameEQ) === 0) {
        return cookie.substring(nameEQ.length, cookie.length);
      }
    }

    return null;
  }

  setCookie(name: string, token: string, days?: number): string {
    let expires = '';

    if (days) {
      const date = new Date();

      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = ` expires=${date.toUTCString()};`;
    }

    let secureFlag = ' secure;';
    const isTestEnv = !(
      environment.clone ||
      environment.clone2 ||
      environment.release ||
      environment.clone3 ||
      environment.ecs ||
      environment.production
    );

    // Safari ignores cookie with secure flag on localhost
    if (isTestEnv && isBrowserSafari()) {
      secureFlag = '';
    }

    this.document.cookie = `${name}=${token};${secureFlag} SameSite=None;${expires} path=/`;

    return this.document.cookie;
  }

  setReminderCookie(): string {
    const date = new Date();

    date.setHours(23, 59, 59, 0);

    const expires = `; expires=${date.toUTCString()}`;

    this.document.cookie = `${
      REMINDERS_ALERT_COOKIE_NAME + '=true' + '; secure; SameSite=None;' + expires
    }; path=/`;

    return this.document.cookie;
  }

  clearCookie(name: string): string {
    return this.setCookie(name, '', -1);
  }
}
