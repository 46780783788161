import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Modal, ModalService } from 'src/app/project/components/modal/modal.service';
import { TAllFilters, TFilters } from 'src/app/project/modules/filters/site-filter.model';
import { checkCustomWorkspaceId } from 'src/app/project/modules/workspace/workspace';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { logEventInGTAG } from '../../services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from '../../services/analytics/google-analytics.consts';
import { EStore } from '../../shared/enums/store.enum';
import { TPoint } from '../points/points.model';
import { PointExportDetailedListService } from './point-export-detailed-list.service';
import { PointExportLocalService } from './point-export-local.service';
import { PointExportPdfService } from './point-export-pdf.service';
import { PointExportSimpleListService } from './point-export-simple-list.service';
import { PointExportSitePlanService } from './point-export-site-plan.service';
import { PointExportWordService } from './point-export-word.service';
import { PointExportZipService } from './point-export-zip.service';
import { TExportCustomField } from './utils/export-custom-field';
import { TExportData } from './utils/export-data';
import { EExportTypeName } from './utils/export-type-names-enum';
import { sendExportGoogleEvent } from './utils/send-export-google-event';

@Injectable({
  providedIn: 'root',
})
export class PointExportRequestService {
  private modal: Modal = this.modalService.getModal();

  private siteFilters$ = new Observable<TAllFilters>();
  private workspaces$ = new Observable<TWorkspacesById>();
  private filters: TFilters;
  private allFilters: TAllFilters;
  private workspaceId: string;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store<{
      siteFilter: TAllFilters;
    }>,

    private modalService: ModalService,
    private pointExportZipService: PointExportZipService,
    private pointExportWordService: PointExportWordService,
    private pointExportSitePlanService: PointExportSitePlanService,
    private pointExportSimpleListService: PointExportSimpleListService,
    private pointExportDetailedListService: PointExportDetailedListService,
    private activeService: ActiveService,
    private pointExportPdfService: PointExportPdfService,
    private pointExportLocalService: PointExportLocalService,
  ) {
    this.siteFilters$ = this.store.pipe(select(EStore.SITE_FILTER));

    this.siteFilters$.pipe(takeUntil(this.destroy$)).subscribe((allFilters) => {
      this.allFilters = allFilters;
      this.setFilters();
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.workspaceId = this.activeService.getActiveWorkspaceId();

      this.setFilters();
    });
  }

  setFilters(): void {
    this.workspaceId = this.activeService.getActiveWorkspaceId();

    if (!this.workspaceId) {
      this.workspaceId = checkCustomWorkspaceId();
    }

    this.filters = this.allFilters?.[this.workspaceId];
  }

  export(
    exportData: TExportData,
    point: TPoint,
    customFields: TExportCustomField[],
  ): Observable<Response> {
    this.sendExportGoogleEvent(exportData);

    switch (exportData.selected.value) {
      case EExportTypeName.ZIP:
        return this.exportZip(exportData, customFields);
      case EExportTypeName.CSV:
        this.pointExportLocalService.exportLocally('csv');

        return of(null);
      case EExportTypeName.XLSX:
        this.pointExportLocalService.exportLocally('xlsx');

        return of(null);
      case EExportTypeName.PDF:
        return this.exportPdf(exportData, customFields, this.filters);
      case EExportTypeName.WORD:
        return this.exportWord(point, customFields, exportData);
      case EExportTypeName.SITE_PLAN:
        return this.pointExportSitePlanService.exportSitePlan({
          exportedPoint: point,
          data: this.modal.data,
          exportData: exportData,
          filters: this.filters,
        });
      case EExportTypeName.SIMPLE_LIST:
        return this.exportSimpleList(point, exportData);
      case EExportTypeName.WORD_SIMPLE:
        return this.pointExportWordService.exportOverviewWordSimpleList({
          exportData: exportData,
          filters: this.filters,
        });
      case EExportTypeName.DETAILED_LIST:
        return this.exportDetailedList(point, customFields, exportData);
    }
  }

  private exportDetailedList(
    point: TPoint,
    customFields: TExportCustomField[],
    exportData: TExportData,
  ): Observable<Response> {
    const overviewExportParameters = {
      exportedCustomFields: customFields,
      exportData: exportData,
      filters: this.filters,
    };

    if (this.modal.data.workspaceId) {
      return this.pointExportDetailedListService.exportDetailedList({
        ...overviewExportParameters,
        exportedPoint: point,
        data: this.modal.data,
      });
    } else {
      return this.pointExportDetailedListService.exportOverviewDetailedList(
        overviewExportParameters,
      );
    }
  }

  private exportSimpleList(point: TPoint, exportData: TExportData): Observable<Response> {
    const overviewExportParameters = {
      exportData: exportData,
      filters: this.filters,
    };

    if (this.modal.data.workspaceId) {
      return this.pointExportSimpleListService.exportSimpleList({
        ...overviewExportParameters,
        exportedPoint: point,
        data: this.modal.data,
      });
    } else {
      return this.pointExportSimpleListService.exportOverviewSimpleList(overviewExportParameters);
    }
  }

  private exportWord(
    point: TPoint,
    customFields: TExportCustomField[],
    exportData: TExportData,
  ): Observable<Response> {
    return this.pointExportWordService.exportWord({
      exportedPoint: point,
      data: this.modal.data,
      exportedCustomFields: customFields,
      exportData: exportData,
      filters: this.filters,
    });
  }

  private exportPdf(
    exportData: TExportData,
    customFields: TExportCustomField[],
    filters: TFilters,
  ): Observable<Response> {
    if (exportData.showImages) {
      logEventInGTAG(EGoogleEventSite.SITE__EXPORT__IMAGES, {
        event_category: EGoogleEventCategory.SITE,
        event_details: exportData.imagesSize,
      });
    }

    return this.pointExportPdfService.exportPdf(customFields, exportData, filters);
  }

  private exportZip(
    exportData: TExportData,
    customFields: TExportCustomField[],
  ): Observable<Response> {
    if (exportData.showImages) {
      logEventInGTAG(EGoogleEventSite.SITE__EXPORT__IMAGES, {
        event_category: EGoogleEventCategory.SITE,
        event_details: exportData.imagesSize,
      });
    }

    return this.pointExportZipService.exportZip({
      _id: null,
      isPdf: false,
      data: this.modal.data,
      exportData: exportData,
      filters: this.filters,
      exportedCustomFields: customFields,
    });
  }

  private sendExportGoogleEvent(exportData: TExportData): void {
    sendExportGoogleEvent(exportData.selected.value, this.modal.data.pointExport);
  }
}
