<div id="savedViewsDropdown" #savedViewsDropdown class="siteOptions__dropdown">
  <pp-button-dropdown
    [ppSrc]="EIconPath.ICON_SAVED_VIEWS_VIEW"
    [ppActive]="dropdownVisible || isViewSelected"
    [ppText]="'views' | ppTranslate"
    (ppAction)="toggleViewsDropdown()"
    ppTooltip
    id="viewsDropdownBtn"
    data-m-target="Saved Views dropdown button"
    [ppTitle]="'saved_views' | ppTranslate"
  ></pp-button-dropdown>
</div>
