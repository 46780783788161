import {
  Directive,
  Input,
  Host,
  TemplateRef,
  ViewContainerRef,
  OnInit,
  DoCheck,
} from '@angular/core';
import { NgSwitch } from '@angular/common';

// https://gist.github.com/jonrimmer/eaabd619e2edeaebed83b7bc68f33daf
@Directive({
  selector: '[ppSwitchCases]',
})
export class SwitchCasesDirective implements OnInit, DoCheck {
  private ngSwitch: any;
  private _created = false;

  @Input() ppSwitchCases: unknown[];

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<Record<string, unknown>>,
    @Host() ngSwitch: NgSwitch,
  ) {
    this.ngSwitch = ngSwitch;
  }

  ngOnInit() {
    (this.ppSwitchCases || []).forEach(() => this.ngSwitch._addCase());
  }

  ngDoCheck(): void {
    let enforce = false;
    (this.ppSwitchCases || []).forEach(
      (value) => (enforce = this.ngSwitch._matchCase(value) || enforce),
    );
    this.enforceState(enforce);
  }

  enforceState(created: boolean): void {
    if (created && !this._created) {
      this._created = true;
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (!created && this._created) {
      this._created = false;
      this.viewContainer.clear();
    }
  }
}
