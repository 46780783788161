import { TColumn } from '../../../../columns/column.model';
import { TTableItem } from '../../../../custom-table/table.model';
import { GET_COLLAPSED_GROUPS } from '../../../../table.ui.store';

export function getMergedHeaderClassList(
  groupLevel: number,
  grouping: TColumn[],
  item: TTableItem,
): string[] {
  const mergedHeaderClassList: string[] = ['table_header_merged'];
  const groupsCollapsed = GET_COLLAPSED_GROUPS();

  if (groupsCollapsed.has(item.id)) {
    mergedHeaderClassList.push('table_header_merged--collapsed');
  }

  switch (groupLevel) {
    case 1:
      mergedHeaderClassList.push('table_header_merged--level-1');

      if (grouping.length === 1) {
        mergedHeaderClassList.push('table__columnCells--grouped--1');
      }

      break;
    case 2:
      mergedHeaderClassList.push('table_header_merged--level-2');

      if (grouping.length === 2) {
        mergedHeaderClassList.push('table__columnCells--grouped--2');
      }

      break;
    case 3:
      mergedHeaderClassList.push('table_header_merged--level-3');
      break;
  }
  return mergedHeaderClassList;
}
