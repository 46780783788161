export const timelineProperties = {
  stationary: false,
  stationaryTimeout: null,
  scrollInterval: null,
  active: false,
  timelineTable: null,
};

export const MINIMUM_WIDTH_FOR_EXPANDED_TIMELINE_ELEMENT = 95;
export const RESIZE_HANDLE_WIDTH = 8;
export const TIMELINE_TIMEOUT_DURATION = 25;
