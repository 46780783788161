<pp-button-modal-cancel
  [class.modalCancelButton--hidden]="ppHideCancel"
  [ppDataTarget]="ppCancelDataTarget"
  ppId="modalCancelButton"
  (ppAction)="cancel()"
>
  {{ 'cancel' | ppTranslate }}
</pp-button-modal-cancel>

<pp-button-modal-confirm
  ppTooltip
  [ppTitle]="ppConfirmTooltip"
  [ppDataTarget]="ppConfirmDataTarget"
  (ppAction)="confirm()"
  [ppDisabled]="ppDisabled"
  [ppError]="ppError"
  [ppProcess]="ppProcess"
>
  {{ ppConfirmText }}
</pp-button-modal-confirm>
