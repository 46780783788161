<section class="editAccountUserCF" [class.editAccountUserCF--even]="ppEven">
  <div class="editAccountUserCF__data">
    <pp-icon [ppSrc]="iconSrc" ppClass="setSize18" ppColor="grey-500"></pp-icon>

    <span
      class="editAccountUserCF__name"
      ppTooltip
      [ppTitle]="customField.label"
      [ppTrimTooltip]="true"
      >{{ customField.label }}
    </span>
  </div>

  <div class="editAccountUserCF-edit">
    <td id="{{ buttonId }}" class="permissionCell" (click)="toggleDropdown()">
      <div class="permissionCell__content">
        <pp-icon ppClass="setSize14" [ppSrc]="permissionIconPath" ppColor="grey-600"></pp-icon>

        <span class="permissionCell__text">{{ permissionText }}</span>

        <pp-icon
          [ppSrc]="
            dropdown.visible && dropdown.buttonId === buttonId
              ? EIconPath.ICON_MISC_UP_ARROW_18
              : EIconPath.ICON_MISC_DOWN_ARROW_18
          "
          ppColor="grey-400"
          ppClass="setSize18"
        ></pp-icon>
      </div>
    </td>
  </div>
</section>
