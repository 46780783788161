import { EAccountTypes } from 'src/app/project/modules/account/account-utils/account-types-enums';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { GET_SHARES } from 'src/app/project/modules/share/shares.store';
import { GET_USER } from 'src/app/project/modules/user/user.store';
import { TAccountSidePanel } from 'src/app/project/view-models/account-simple-response.model';

export function checkAccountAdminPermissions(account: TAccountSidePanel): boolean {
  const shares = GET_SHARES();
  const user = GET_USER();
  const accountAdmin = checkIfIsAccountAdmin(account);

  let accountAdminPermission = false;

  if (user.isSuperUser || account.ownerId === user.userId || accountAdmin) {
    accountAdminPermission = true;
  } else if (
    account.accountType === EAccountTypes.TRIAL ||
    account.accountType === EAccountTypes.UNSUBSCRIBED
  ) {
    accountAdminPermission = false;
  } else {
    for (let i = 0; i < account.workspaces.length; i++) {
      const share = shares.find(
        (_share) => _share.workspaceId === account.workspaces[i].workspaceId,
      );

      if (
        share &&
        (share.shareOption === EUserRole.SITE_ADMIN ||
          share.shareOption === EUserRole.OWNER ||
          share.shareOption === EUserRole.ACCOUNT_ADMIN)
      ) {
        accountAdminPermission = true;

        break;
      }
    }
  }

  return accountAdminPermission;
}
function checkIfIsAccountAdmin(account: TAccountSidePanel) {
  return account.workspaces.some((workspace) => workspace.shareOption === EUserRole.ACCOUNT_ADMIN);
}
