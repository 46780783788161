<section class="exportFormat">
  <header class="exportFormatHeader">
    {{ 'asset_export_format' | ppTranslate }}
  </header>

  <div class="exportFormatButtons">
    <div class="exportFormatButton" (click)="selectFormat(EExportFormat.A4)">
      <pp-radio [ppChecked]="ppPageFormat === EExportFormat.A4"></pp-radio>

      {{ 'page_format_a4' | ppTranslate }}
    </div>

    <div class="exportFormatButton" (click)="selectFormat(EExportFormat.LETTER)">
      <pp-radio [ppChecked]="ppPageFormat === EExportFormat.LETTER"></pp-radio>

      {{ 'page_format_letter' | ppTranslate }}
    </div>
  </div>
</section>
