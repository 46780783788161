import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TAdvancedFilterSingle, TAdvancedFilter } from '../../models/advanced-filter.model';
import { EAdvancedFilterType } from '../../models/advanced-filter-type.enum';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-advanced-filters-row',
  templateUrl: './advanced-filters-row.component.html',
  styleUrls: ['./advanced-filters-row.component.scss'],
})
export class AdvancedFiltersRowComponent {
  @Input() ppFilter: TAdvancedFilterSingle;
  @Input() ppWorkspaceId: string;
  @Input() ppIndex: number;
  @Input() ppLogic: TAdvancedFilter['logic'];

  EIconPath = EIconPath;
  buttonHovered = false;

  @Output() ppDeleteFilter = new EventEmitter<void>();
  @Output() ppFilterChange = new EventEmitter<TAdvancedFilterSingle>();
  @Output() ppLogicChange = new EventEmitter<TAdvancedFilter['logic']>();

  EAdvancedFilterType = EAdvancedFilterType;

  deleteFilter(): void {
    this.ppDeleteFilter.emit();
  }

  changeField(): void {
    this.applyChanges();
  }

  applyChanges(): void {
    this.ppFilterChange.emit(this.ppFilter);
  }

  changeLogic(newLogic: TAdvancedFilter['logic']): void {
    this.ppLogicChange.emit(newLogic);
    this.applyChanges();
  }

  changeFilterType(newFilter: TAdvancedFilterSingle): void {
    this.ppFilter = {
      ...newFilter,
      option: null,
      value: null,
    };

    this.applyChanges();
  }

  buttonHover(isHovered: boolean): void {
    this.buttonHovered = isHovered;
  }
}
