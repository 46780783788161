export const API_files_video_point = (_id: string): string => `/api/v1/video/itemref/${_id}`;
export const API_files_video_file = (videoId: string): string => `/api/v1/video/${videoId}/file`;
export const API_files_video_s3 = (videoId: string): string => `/api/v1/video/${videoId}/s3-link`;
export const API_files_video_update = (workspaceId: string, updatePoint: boolean): string =>
  `/api/v1/video/${workspaceId}?updatePoint=${updatePoint}`;

export const API_files_documents_point = (_id: string): string => `/api/v1/document/itemref/${_id}`;
export const API_files_documents_file = (documentId: string): string =>
  `/api/v1/document/${documentId}/file`;
export const API_files_documents_update = (workspaceId: string, updatePoint: boolean): string =>
  `/api/v1/document/${workspaceId}?updatePoint=${updatePoint}`;

export const API_files_images_size_original = (videoId: string): string =>
  `/api/v1/images/${videoId}/file/size/original`;
export const API_files_images_size_40 = (imageId: string): string =>
  `api/v1/images/${imageId}/file/size/square/40`;
export const API_files_images_update = (
  apiVersion: string,
  workspaceId: string,
  photo360: string,
  updatePoint: boolean,
): string => `/api/${apiVersion}/images/${workspaceId}${photo360}?updatePoint=${updatePoint}`;
export const API_files_images_rotate = (imageId: string, angle: number, _id: string): string =>
  `/api/v1/images/${imageId}/rotate?cw=${angle}&pointId=${_id}`;
export const API_files_images_upload_annotated = (imageId: string): string =>
  `api/v1/images/${imageId}/upload-annotated`;
export const API_files_images_square_size = (imageId: string, size: number): string =>
  `api/v1/images/${imageId}/file/size/square/${size}`;
export const API_files_images_bounded_size = (imageId: string, size: number): string =>
  `api/v1/images/${imageId}/file/size/bounded/${size}`;
export const API_files_images_xml = (imageId: string): string =>
  `api/v1/images/${imageId}/xml-file`;
export const API_files_images_item_ref_V2 = (_id: string): string =>
  `/api/v2/images/itemref/${_id}`;
export const API_files_images_360_item_ref = (_id: string): string =>
  `/api/v1/images/360/itemref/${_id}`;

export const API_files_custom_type = (type: string, attachmentId: string): string =>
  `/api/v1/${type}/${attachmentId}/file`;
export const API_files_rename = (type: string, attachmentId: string): string =>
  `/api/v1/${type}/${attachmentId}/name`;

export const API_files_bulk = (_id: string): string => `/api/v1/file/bulk?pointId=${_id}`;
export const API_files_bulk_new = (): string => '/api/v1/file/bulk';
export const API_files_S3_link = (documentId: string): string =>
  `api/v1/document/${documentId}/s3-link`;
