import { Component, OnInit } from '@angular/core';
import {
  ConfirmModalComponent,
  TConfirmModalParams,
} from 'src/app/project/components/confirm-modal/confirm-modal.component';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { ELocalStorageItems } from 'src/app/project/shared/enums/local-storage-items.enum';
import { NotificationsService } from '../notifications.service';
import {
  TNotificationDropdownOption,
  TNotificationsOptionsDropdownData,
} from './notifications-options-dropdown.model';

@Component({
  selector: 'pp-notification-options-dropdown',
  templateUrl: './notification-options-dropdown.component.html',
  styleUrls: ['./notification-options-dropdown.component.scss'],
})
export class NotificationOptionsDropdownComponent implements OnInit {
  dropdown: TDropdown<TNotificationsOptionsDropdownData>;
  EIconPath = EIconPath;

  constructor(
    private dropdownService: DropdownService,
    private translationPipe: TranslationPipe,
    private notificationsService: NotificationsService,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.dropdown = this.dropdownService.getDropdown();
  }

  filterNotifications(): void {
    const dropdownOption: TNotificationDropdownOption = 'filter';
    this.callback(dropdownOption);
  }

  markAllAsRead(): void {
    const dropdownOption: TNotificationDropdownOption = 'mark_all_as_read';

    this.callback(dropdownOption);
    this.dropdownService.hideDropdown();
  }

  showClearNotificationsModal(): void {
    const dontShowModal = localStorage.getItem(ELocalStorageItems.DONT_SHOW_CLEAR_ALL);
    dontShowModal === 'true' ? this.clearNotifications() : this.showModal();
  }

  showModal(): void {
    const modalData: TConfirmModalParams = {
      message: this.translationPipe.transform('confirm_clear_all_notifications'),
      heading: this.translationPipe.transform('clear_all'),
      redButton: true,
      showCheckbox: true,
      boldText: true,
      extraPadding: true,
      checkboxText: this.translationPipe.transform('dont_show_message_again'),
      confirmText: this.translationPipe.transform('clear_all'),
      dontShowAgainCallback: (dontShowAgain) => this.dontShowAgainCallback(dontShowAgain),
    };

    this.dropdownService.hideDropdown();
    this.modalService.setData(modalData);
    this.modalService.showModal(ConfirmModalComponent, {
      onClose: (cancel: boolean) => {
        if (cancel) {
          this.notificationsService.removeNotifications();
        } else {
          this.notificationsService.removeShowAgainNotification();
        }
      },
    });
  }

  clearNotifications(): void {
    const dropdownOption: TNotificationDropdownOption = 'clear_all_notifications';
    this.callback(dropdownOption);
    this.dropdownService.hideDropdown();
  }

  dontShowAgainCallback(dontShowAgain: boolean): void {
    dontShowAgain
      ? this.notificationsService.saveShowAgainNotification()
      : this.notificationsService.removeShowAgainNotification();
  }

  callback(option: TNotificationDropdownOption): void {
    this.dropdown.callback(option);
  }
}
