import { createElement } from 'src/app/core/helpers/dom';
import { translate } from 'src/app/project/features/translate/translate';
import { EDashletType } from '../dashlets-enums';

export function createTotalPointsElement(
  totalPoints: number,
  scale: number,
  chartType: EDashletType,
): HTMLElement {
  const totalPointsElement = createElement('div');

  if (
    chartType === EDashletType.COST_COMPARISON ||
    chartType === EDashletType.CURRENT_STACKED_STATUS
  ) {
    return totalPointsElement;
  }

  const imageScale = 1;

  totalPointsElement.style.position = 'absolute';
  totalPointsElement.style.fontWeight = '600';
  totalPointsElement.style.fontSize = `${64 * scale}px`;
  totalPointsElement.style.color = '#26292D';
  totalPointsElement.style.fontFamily = 'Source Sans Pro, sans-serif';
  totalPointsElement.style.right = `${26 * imageScale}px`;
  totalPointsElement.style.bottom = `${16 * imageScale}px`;

  totalPointsElement.innerHTML = translate('total_points_number', { totalPoints });

  return totalPointsElement;
}
