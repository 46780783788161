import { ApiService } from '@core/http';
import { createElement } from 'src/app/core/helpers/dom';
import { GET_ACTIVE_PLAN } from 'src/app/project/modules/plan/plan.store';
import { GET_GROUPING } from '../../columns/grouping.store';
import { GET_TABLE } from '../../table.ui.store';
import { createColumnCellsElement } from '../body-cells';
import { createSelectElement } from '../body-cells-frozen/create-select-element';
import { generatePointRowClasses } from './generate-point-row-classes';

export function createPointRowElement(_index: number, apiService: ApiService): HTMLElement {
  const table = GET_TABLE();
  const item = table.items[_index];
  const pointIndex = item.index;
  const grouping = GET_GROUPING();

  const pointRowElement: HTMLElement = createElement('div', {
    attrs: {
      class: ((): string[] => {
        return generatePointRowClasses(_index);
      })(),
    },
    eventListeners: {
      click: () => {
        const activePlan = GET_ACTIVE_PLAN();

        if (!activePlan.active) {
          table.openPointCallback(table.points[pointIndex]);
          table.activatePoint(table.points[pointIndex].sequenceNumber);
        }
      },
      mouseenter: () => {
        if (table.highlightPinCallback && table.points[pointIndex]) {
          // checking if point exists for rare cases when user moves mouse when table is in process of being reloaded
          table.highlightPinCallback(table.points[pointIndex].sequenceNumber);
        }
      },
      mouseleave: () => {
        if (table.dehighlightPinCallback) {
          table.dehighlightPinCallback();
        }
      },
    },
    children: [
      createSelectElement(pointIndex),
      createColumnCellsElement(pointIndex, true, apiService),
    ],
  });

  if (grouping.length) {
    // pointRowElement.style.marginLeft = `${16 * grouping.length}px`;
  }

  return pointRowElement;
}
