import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import {
  API_reminders,
  API_reminders_current_day,
  API_reminders_from_to,
  API_reminders_reminder,
} from '@core/api/paths';
import { TGuid } from '@core/helpers';
import {
  TCreateReminderDTO,
  TReminder,
  TUpdateReminderDTO,
} from 'src/app/project/modules/reminders/reminder.model';

@Injectable({
  providedIn: 'root',
})
export class RemindersApiProviderService {
  constructor(private apiService: ApiService) {}

  getAll(from?: number, to?: number): Observable<TReminder[]> {
    const url = API_reminders_from_to(from, to);
    return this.apiService.get<TReminder[]>(url);
  }

  create(_id: string, date: number, description: string): Observable<TReminder> {
    const url = API_reminders();
    const body: TCreateReminderDTO = {
      timestampEpochMillis: date,
    };

    if (_id) {
      body.pointId = _id;
    }

    if (description) {
      body.note = description;
    }
    return this.apiService.put<TReminder>(url, body);
  }

  update(id: TGuid, _id: TGuid, date: number, description: string): Observable<TReminder> {
    const body: TUpdateReminderDTO = {
      pointId: _id,
      timestampEpochMillis: date,
      note: description,
    };
    const url = API_reminders_reminder(id);

    return this.apiService.post<TReminder>(url, body);
  }

  delete(reminderId: TGuid): Observable<void> {
    const url = API_reminders_reminder(reminderId);

    return this.apiService.delete(url);
  }

  getCurrentRemindersCount(): Observable<number> {
    const url = API_reminders_current_day();

    return this.apiService.get<number>(url);
  }
}
