import { TAccountSimpleResponse } from 'src/app/project/view-models/account-simple-response.model';
import VirtualScroller from 'src/app/project/modules/external/virtual-scroller/VirtualScroller';
import Account from '../account/Account';
import { TFlattenedAccount } from './account-list-flatten-account';
import { TAnyFunction } from '@core/helpers';
import { ApiService } from '@core/http';

type TAddAccountElementTypeParams = {
  _index: number;
  virtualScroller: VirtualScroller;
  accountsFlattened: TFlattenedAccount[];
  apiService: ApiService;
  accountSettingsCallback: TAnyFunction;
  accounts: TAccountSimpleResponse[];
  load: TAnyFunction;
};

export const addAccountElement = ({
  _index,
  virtualScroller,
  accountsFlattened,
  apiService,
  accountSettingsCallback,
  accounts,
  load,
}: TAddAccountElementTypeParams): HTMLElement => {
  let accountObj = null;

  if (virtualScroller) {
    accountObj = virtualScroller.data[_index];
  } else {
    accountObj = accountsFlattened[_index];
  }

  const account = new Account(accountObj.account, apiService, {
    accountSettingsCallback: accountSettingsCallback,
    updateCallback: (): void => {
      load(accounts, true);
    },
  });

  return account.element;
};
