import { TPolygon } from 'src/app/project/view-models/point-response.model';

export function polygonsToArea(polygons): TPolygon[] {
  const polygonBody: TPolygon[] = [];

  if (polygons) {
    polygons.forEach((polygon) => {
      const polygonData: TPolygon = {
        vertices: [],
      };

      polygon[0].forEach((vertex) => {
        polygonData.vertices.push({
          x: vertex[0].toFixed(4),
          y: vertex[1].toFixed(4),
        });
      });

      polygonBody.push(polygonData);
    });
  }

  return polygonBody;
}
