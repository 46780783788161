import { translate } from '../features/translate/translate';
import { TCustomFieldFilter } from '../modules/filters/site-filter.model';

export function generateFilterTooltip(customFieldFilter: TCustomFieldFilter): string {
  let tooltip = `
    ${translate('toggle_off_filter')}

    <div style='text-align: left; margin-top: 1rem'>
    ${translate('custom_field_used_on_sites')}

    <ul>`;

  if (customFieldFilter.sites) {
    customFieldFilter.sites.forEach((siteInFilter) => {
      tooltip +=
        '<li>•	<strong>' +
        siteInFilter.accountName +
        '</strong> – ' +
        siteInFilter.siteName +
        '</li>';
    });
  }

  tooltip += `
      </ul>
    </div>
  `;

  return tooltip;
}
