import { GET_TABLE } from '../table.ui.store';
import { getEmptyGroupName } from './empty-group-name';

export function transformAssigneesValue(_value: string[]): string {
  const emptyGroupValue = getEmptyGroupName();
  const table = GET_TABLE();
  let value = '';

  const userList = [];

  _value.forEach((userId) => {
    const user = table.users.find((_user) => _user.userId === userId);

    if (user) {
      userList.push(user.userName);
    }
  });

  value = userList.length ? userList.join(', ') : emptyGroupValue;
  return value;
}
