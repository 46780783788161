import { Injectable } from '@angular/core';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { TFilters } from 'src/app/project/modules/filters/site-filter.model';
import { TPlanData, PlanDataService } from 'src/app/project/modules/plan/plan-data.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { TPoint } from '../points/points.model';
import { PointsService } from '../points/points.service';
import { PointExportService } from './point-export.service';
import { TExportData } from './utils/export-data';
import { TExportModalData } from './utils/export-modal-data';
import { EExportTypeName } from './utils/export-type-names-enum';
import { Observable } from 'rxjs';
import { getCoords } from './utils/get-coords';

@Injectable({
  providedIn: 'root',
})
export class PointExportSitePlanService {
  private planData: TPlanData = this.planDataService.getPlan();

  constructor(
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private pointExportService: PointExportService,
    private activeService: ActiveService,
    private workspaceService: WorkspaceService,
    private pointsService: PointsService,
    private planDataService: PlanDataService,
  ) {}

  exportSitePlan({
    exportedPoint,
    data,
    exportData,
    filters,
  }: {
    exportedPoint: TPoint;
    data: TExportModalData;
    exportData: TExportData;
    filters: TFilters;
  }): Observable<Response> {
    const workspaceId = exportedPoint
      ? exportedPoint.workspaceRef.id
      : this.activeService.getActiveWorkspaceId();
    const workspaces = this.workspaceService.getWorkspaces();

    const promptText = this.translationPipe.transform('prompt_export_start');
    const pointIds = this.pointsService.getSelectedPointsExport();

    const coords = getCoords(workspaces, workspaceId, this.planData);

    this.promptService.showSuccess(promptText, { duration: 15 });

    return this.pointExportService.export({
      workspaceId: data.workspaceId,
      pointIds,
      customFieldIds: null,
      exportData,
      filters: filters,
      isPdf: false,
      exportType: EExportTypeName.SITE_PLAN,
      coords,
      isOverview: false,
    });
  }
}
