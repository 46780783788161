<div class="point__createdBy__field" data-test="createdBy">
  <label ppTooltip class="point__createdBy__label" ppTitle="Created By">
    {{ 'created_by' | ppTranslate }}
  </label>

  <div class="pointCF__content">
    <ng-container *ngIf="users?.includes(ppCreatedById)">
      <img
        ppTooltip
        ppImage
        class="timeline__avatar"
        draggable="false"
        *ngIf="allUsers[ppCreatedById]?.avatarPublicUrl"
        [src]="allUsers[ppCreatedById]?.avatarPublicUrl"
        [ppTitle]="allUsers[ppCreatedById]?.userName"
        [ppMobileTooltip]="true"
      />

      <img
        ppTooltip
        class="timeline__avatar"
        draggable="false"
        *ngIf="!allUsers[ppCreatedById]?.avatarPublicUrl"
        [src]="EIconPath.PLACEHOLDER_USER"
        [ppTitle]="allUsers[ppCreatedById]?.userName"
        [ppMobileTooltip]="true"
      />
    </ng-container>

    <ng-container *ngIf="!users?.includes(ppCreatedById)">
      <img
        ppTooltip
        ppImage
        class="timeline__avatar timeline__avatar--disabled"
        draggable="false"
        *ngIf="ppCreatedByAvatar"
        [ppSecureSrc]="'api/v1/images/' + ppCreatedByAvatar + '/file/size/square/40'"
        [ppTitle]="ppCreatedByName + ' (deleted)'"
        [ppMobileTooltip]="true"
      />

      <img
        ppTooltip
        class="timeline__avatar timeline__avatar--disabled"
        draggable="false"
        *ngIf="!ppCreatedByAvatar"
        [src]="EIconPath.PLACEHOLDER_USER"
        [ppTitle]="ppCreatedByName + ' (deleted)'"
        [ppMobileTooltip]="true"
      />
    </ng-container>
  </div>
</div>
