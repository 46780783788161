<div class="umAccount">
  <div class="umAccount__cell umAccount__cell--site">
    <div class="checkboxWrapper" ppTooltip [ppTitle]="selectAllTooltip">
      <pp-checkbox
        [ppChecked]="isAllSelected"
        (ppOnValueChange)="selectAll()"
        ppSize="medium"
        class="checkbox"
        ppType="solid"
        [ppDisabled]="selectAllDisabled"
      ></pp-checkbox>
    </div>

    <div
      class="arrowWrapper"
      (click)="expandAll()"
      ppTooltip
      [class.arrowWrapper--disabled]="expandAllDisabled"
      [ppTitle]="expandAllTooltip"
    >
      <pp-icon
        [ppSrc]="
          allUsersExpanded ? EIconPath.ICON_NAV_ARROW_DOWN_14 : EIconPath.ICON_NAV_ARROW_RIGHT_14
        "
        ppClass="setSize14"
        ppColor="inherit"
      ></pp-icon>
    </div>

    <div class="umAccount__cellContent umAccount__cellContent--site">
      {{ 'site' | ppTranslate }}
    </div>
  </div>

  <div *ngFor="let basicField of basicFields" class="umAccount__cell" [ngClass]="basicField.class">
    <div class="umAccount__cellContent">
      {{ basicField.translationKey | ppTranslate }}
    </div>
  </div>

  <div
    class="umAccount__cell umAccount__cell--customField"
    *ngFor="let customField of ppCustomFields"
  >
    <pp-account-user-management-header-custom-field
      [ppCustomField]="customField"
    ></pp-account-user-management-header-custom-field>
  </div>
</div>
