import { MINUTE_IN_MILLISECONDS } from './date/date.consts';

export function getTimezoneOffset(): number {
  return new Date().getTimezoneOffset() * MINUTE_IN_MILLISECONDS; // convert to ms
}

export function getTimezoneOffsetAdjusted(): number {
  let timezoneOffset = new Date().getTimezoneOffset(); // minutes;

  // We save dates on server as mid-day, so +12/-12 switches the date to the next/previous day and we need to compensate for that
  if (timezoneOffset / 60 === -12) {
    timezoneOffset += 60 * 24;
  } else if (timezoneOffset / 60 === 12) {
    timezoneOffset -= 60 * 24;
  }

  return timezoneOffset * MINUTE_IN_MILLISECONDS; // convert to ms
}
