import { createElement } from 'src/app/core/helpers/dom';
import { createTimelineCellInnerElement } from './create-timeline-cell-inner-element';
import {TAnyFunction} from '@core/helpers';

export function createTimelineCellElement({
  index: _index = null,
  child: _child = null,
  className: _className = null,
  center: _center = true,
  width: _width = 'auto' as string | number,
  callback: _callback = null,
}: {
  index?: number;
  child?: HTMLElement;
  className?: string;
  center?: boolean;
  width?: string | number;
  callback?: TAnyFunction;
} = {}): HTMLElement {
  const cellInnerElement = _child ? _child : createTimelineCellInnerElement(_index);

  if (!_center) {
    cellInnerElement.style.width = '100%';
  }

  const cellElement = createElement('div', {
    attrs: {
      class: _className ? `${_className}` : 'timeline__cell',
      style: {
        width: _width,
      },
      'data-index': _index,
    },
    children: [cellInnerElement],
  });

  if (_center) {
    cellInnerElement.style.padding = '0';
  }

  if (_callback) {
    cellElement.addEventListener('click', (_event) => {
      _event.stopPropagation();

      _callback();
    });
  }

  return cellElement;
}
