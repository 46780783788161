import { Injectable, NgZone } from '@angular/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, Observable, Subject, catchError, map, of, tap } from 'rxjs';
import { PromptService } from '../../components/prompt/prompt.service';
import { SavedViewsProviderService } from '../../data-providers/api-providers/saved-views-provider/saved-views-provider.service';
import { TranslationPipe } from '../../features/translate/translation.pipe';
import { ResponseErrorService } from '../errors/response-error.service';
import { CompareFiltersService } from '../filters/compare-filters-service/compare-filters.service';
import { TFilters } from '../filters/site-filter.model';
import { TColumn } from '../site/site-table/columns/column.model';
import { UserService } from '../user/user.service';
import { CompareColumnsWithSavedViewService } from './compare-columns-with-saved-view.service';
import { ESavedView } from './models/saved-view.enum';
import { TSavedView } from './models/saved-view.model';
import { TSavedViews, TViewShort, TViewsByWorkspace } from './models/saved-views.model';
import { fixView } from './utils/fix-view';

@Injectable({
  providedIn: 'root',
})
export class SavedViewsService {
  private savedViewsList: {
    [workspaceId: string]: TSavedViews;
  } = {};

  private individualViews: {
    [viewId: string]: TSavedView;
  } = {};

  private readonly _viewsChange$ = new BehaviorSubject<TViewsByWorkspace>(this.savedViewsList);
  readonly viewsChange$ = this._viewsChange$.asObservable();
  private selectedViewId: {
    [workspaceId: string]: string;
  } = {};
  private activeViewId: string;

  private createInputSubject = new Subject<string>();
  createInputEvent$ = this.createInputSubject.asObservable();

  constructor(
    private savedViewsProviderService: SavedViewsProviderService,
    private userService: UserService,
    private responseErrorService: ResponseErrorService,
    private ngZone: NgZone,
    private compareFiltersService: CompareFiltersService,
    private compareColumnsWithSavedViewService: CompareColumnsWithSavedViewService,
    private promptService: PromptService,
    private translationPipe: TranslationPipe,
  ) {}

  fetchSavedViews(workspaceId: string): Observable<TSavedViews> {
    if (this.savedViewsList[workspaceId]) {
      if (!this.selectedViewId[workspaceId]) {
        this.setDefaultSelectedView(this.savedViewsList[workspaceId], workspaceId);
      }

      return of(this.savedViewsList[workspaceId]);
    }

    return this.savedViewsProviderService.getViews(workspaceId).pipe(
      map((views) => {
        const fixedView: Partial<TSavedViews> = this.fixWorkspaceViews(views, workspaceId);

        this.setDefaultSelectedView(fixedView, workspaceId);

        this.savedViewsList[workspaceId] = fixedView as TSavedViews;
        this._viewsChange$.next(this.savedViewsList);

        return fixedView as TSavedViews;
      }),
    );
  }

  getSavedViews(workspaceId: string): TSavedViews {
    return this.savedViewsList[workspaceId];
  }

  fetchView({
    workspaceId,
    viewId,
    customFields,
  }: {
    workspaceId: string;
    viewId: string;
    customFields: string[];
  }): Observable<TSavedView> {
    if (this.individualViews[viewId]) {
      return of(fixView(this.individualViews[viewId], customFields));
    }

    return this.savedViewsProviderService.getView(workspaceId, viewId).pipe(
      map((view) => {
        this.individualViews[viewId] = fixView(view, customFields);

        return view;
      }),
    );
  }

  getView(viewId: string): TSavedView {
    return this.individualViews[viewId];
  }

  createView(
    view: Partial<TSavedView>,
    workspaceId: string,
    duplicatingView?: boolean,
  ): Observable<TSavedView> {
    return this.savedViewsProviderService.createView(workspaceId, view).pipe(
      tap((newView) => {
        this.individualViews[newView.id] = newView;

        if (view.viewType === ESavedView.PERSONAL) {
          this.savedViewsList[workspaceId].personal.views.push({
            id: newView.id,
            name: newView.name,
          });
        } else {
          this.savedViewsList[workspaceId].shared.views.push({
            id: newView.id,
            name: newView.name,
          });
        }

        if (!duplicatingView) {
          this.setSelectedViewId(workspaceId, newView.id);
        }

        this.ngZone.runOutsideAngular(() => {
          setTimeout(() => {
            this.createInputSubject.next(newView.id);
            this._viewsChange$.next(this.savedViewsList);
          });
        });
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  editSavedView(workspaceId: string, view: TSavedView): Observable<TSavedView> {
    return this.savedViewsProviderService.editView(workspaceId, view).pipe(
      tap((newView) => {
        this.updateView(newView, workspaceId);
      }),
    );
  }

  tryUpdateActiveView(workspaceId: string, columns: TColumn[], filters: TFilters): void {
    if (!this.savedViewsList[workspaceId]) {
      return;
    }

    if (this.checkIfViewIsActive(workspaceId, columns, filters)) {
      this.setActiveViewId(this.selectedViewId[workspaceId]);
    } else {
      this.setActiveViewId(null);
    }
  }

  checkIfViewIsActive(workspaceId: string, columns: TColumn[], filters: TFilters): boolean {
    if (!this.savedViewsList[workspaceId]) {
      return false;
    }

    const selectedViewId = this.selectedViewId[workspaceId];
    const selectedView = this.getView(selectedViewId);

    if (!selectedView) {
      return false;
    } else {
      const activeViewColumns = selectedView.columns;
      const activeViewFilters = selectedView.filters.basic;
      const areFiltersEqual = this.compareFiltersService.areFiltersEqual(
        activeViewFilters,
        filters,
      );

      const areColumnsEqual = this.compareColumnsWithSavedViewService.compareColumns(
        activeViewColumns,
        columns,
      );

      if (areColumnsEqual && areFiltersEqual) {
        return true;
      } else {
        return false;
      }
    }
  }

  setDefaultView(workspaceId: string, viewId: string, type: ESavedView): Observable<TSavedViews> {
    return this.savedViewsProviderService.setDefaultView(workspaceId, viewId, type).pipe(
      tap(() => {
        if (type === ESavedView.PERSONAL) {
          this.savedViewsList[workspaceId].personal.defaultViewId = viewId;
        } else {
          this.savedViewsList[workspaceId].shared.sharedDefaultViewId = viewId;
        }

        this._viewsChange$.next(this.savedViewsList);
      }),
    );
  }

  deleteDefaultView(workspaceId: string, type: ESavedView): Observable<null> {
    return this.savedViewsProviderService.deleteDefaultView(workspaceId, type).pipe(
      tap(() => {
        if (type === ESavedView.PERSONAL) {
          this.savedViewsList[workspaceId].personal.defaultViewId = null;
        } else {
          this.savedViewsList[workspaceId].shared.sharedDefaultViewId = null;
        }

        this._viewsChange$.next(this.savedViewsList);
      }),
    );
  }

  reorderViews(
    workspaceId: string,
    viewId: string,
    viewType: ESavedView,
    newOrder: number,
  ): Observable<null> {
    return this.savedViewsProviderService
      .reorderViews(workspaceId, viewId, viewType, newOrder)
      .pipe(
        tap((response) => {
          this.savedViewsList[workspaceId] = this.fixWorkspaceViews(response, workspaceId);

          this._viewsChange$.next(this.savedViewsList);
        }),
        catchError(this.responseErrorService.handleRequestError),
      );
  }

  deleteView(viewId: string, workspaceId: string, type: ESavedView): void {
    this.savedViewsProviderService
      .deleteView(viewId, workspaceId)
      .pipe(
        tap(() => {
          delete this.individualViews[viewId];

          if (type === ESavedView.PERSONAL) {
            const itemIndex = this.savedViewsList[workspaceId].personal.views.findIndex(
              (view) => view.id === viewId,
            );

            this.savedViewsList[workspaceId].personal.views.splice(itemIndex, 1);

            // Delete if it was default
            if (this.savedViewsList[workspaceId].personal.defaultViewId === viewId) {
              this.deleteDefaultView(workspaceId, ESavedView.PERSONAL).subscribe();
            }
          } else {
            const itemIndex = this.savedViewsList[workspaceId].shared.views.findIndex(
              (view) => view.id === viewId,
            );

            this.savedViewsList[workspaceId].shared.views.splice(itemIndex, 1);

            if (this.savedViewsList[workspaceId].shared.sharedDefaultViewId === viewId) {
              this.deleteDefaultView(workspaceId, ESavedView.SHARED).subscribe();
            }
          }

          this._viewsChange$.next(this.savedViewsList);
        }),
        catchError(this.responseErrorService.handleRequestError),
      )
      .subscribe();
  }

  renameView(viewId: string, newName: string, workspaceId: string): Observable<TSavedView> {
    return this.savedViewsProviderService.editView(workspaceId, { name: newName, id: viewId }).pipe(
      tap((view) => {
        this.updateView(view, workspaceId);
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  updateDefaultPersonalView(viewId: string, workspaceId: string): void {
    this.savedViewsList[workspaceId].personal.defaultViewId = viewId;

    this._viewsChange$.next(this.savedViewsList);
  }

  updateDefaultSharedView(viewId: string, workspaceId: string): void {
    this.savedViewsList[workspaceId].shared.sharedDefaultViewId = viewId;

    this._viewsChange$.next(this.savedViewsList);
  }

  private updateView(view: TSavedView, workspaceId: string): void {
    this.individualViews[view.id] = view;

    const shortView = this.findView(view.id, workspaceId);

    shortView.name = view.name;

    this._viewsChange$.next(this.savedViewsList);
  }

  findView(viewId: string, workspaceId: string): TViewShort {
    if (!this.savedViewsList[workspaceId]) {
      return null;
    }

    const foundView = this.savedViewsList[workspaceId].personal.views.find(
      (view) => viewId === view.id,
    );

    if (foundView) {
      return foundView;
    }

    return this.savedViewsList[workspaceId].shared.views.find((view) => viewId === view.id);
  }

  getSelectedViewId(workspaceId: string): string {
    return this.selectedViewId[workspaceId];
  }

  setSelectedViewId(workspaceId: string, viewId: string): void {
    this.selectedViewId[workspaceId] = viewId;
    this._viewsChange$.next(this.savedViewsList);
  }

  clearSavedViews(): void {
    this.savedViewsList = {};
    this.individualViews = {};
    this.selectedViewId = {};
  }

  getSelectedViewIds(): { [workspaceId: string]: string } {
    return this.selectedViewId;
  }

  saveToView(newView: TSavedView, workspaceId: string): Observable<TSavedView> {
    return this.savedViewsProviderService.editView(workspaceId, newView).pipe(
      tap((view) => {
        const prompt = this.translationPipe.transform('prompt_saved_view_updated');
        this.updateView(view, workspaceId);
        this.promptService.showSuccess(prompt);
      }),
      catchError((error) => {
        const prompt = this.translationPipe.transform('prompt_error');
        this.promptService.showError(prompt);

        return this.responseErrorService.handleRequestError(error);
      }),
    );
  }

  private fixWorkspaceViews(views: TSavedViews, workspaceId: string): TSavedViews {
    let fixedView: Partial<TSavedViews> = cloneDeep(views);

    if (!fixedView) {
      fixedView = {};
    }

    if (!fixedView.shared) {
      fixedView.shared = {
        views: [],
        sharedDefaultViewId: null,
        workspaceId,
      };
    }

    if (!fixedView.personal) {
      const user = this.userService.getUser();

      fixedView.personal = {
        workspaceId,
        userId: user.userId,
        defaultViewId: null,
        views: [],
      };
    }
    return fixedView as TSavedViews;
  }

  private setDefaultSelectedView(fixedView: Partial<TSavedViews>, workspaceId: string): void {
    fixedView.personal.defaultViewId
      ? this.setSelectedViewId(workspaceId, fixedView.personal.defaultViewId)
      : this.setSelectedViewId(workspaceId, fixedView.shared.sharedDefaultViewId);
  }

  private setActiveViewId(viewId: string): void {
    this.activeViewId = viewId;
    this._viewsChange$.next(this.savedViewsList);
  }

  getActiveViewId(): string {
    return this.activeViewId;
  }
}
