import { Component, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { WorkspaceService } from '../../../workspace/workspace.service';

@Component({
  selector: 'pp-user-management-site-cell',
  templateUrl: './user-management-site-cell.component.html',
  styleUrls: ['./user-management-site-cell.component.scss'],
})
export class UserManagementSiteCellComponent implements OnChanges {
  @Input() ppWorkspaceId: string;
  @Output() ppNavigate = new EventEmitter<string>();

  siteName: string;

  constructor(private workspaceService: WorkspaceService) {}

  ngOnChanges(): void {
    this.setSiteName();
  }

  emitNavigateToSite(): void {
    this.ppNavigate.emit(this.ppWorkspaceId);
  }

  private setSiteName(): void {
    this.siteName = this.workspaceService.getWorkspace(this.ppWorkspaceId).siteName;
  }
}
