import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TAdvancedFilter } from '../../../models/advanced-filter.model';
import { TSelectOption } from 'src/app/project/components/input/select/select.model';
import { translate } from 'src/app/project/features/translate/translate';
import { EAdvancedFilterLogic } from '../../../models/advanced-filter-logic.enum';

@Component({
  selector: 'pp-advanced-filter-condition',
  templateUrl: './advanced-filter-condition.component.html',
  styleUrls: ['./advanced-filter-condition.component.scss'],
})
export class AdvancedFilterConditionComponent implements OnChanges {
  @Input() ppLogic: TAdvancedFilter['logic'];
  @Input() ppIndex: number;

  @Output() ppLogicChange = new EventEmitter<TAdvancedFilter['logic']>();

  logicOptions: TSelectOption[] = [
    {
      value: EAdvancedFilterLogic.AND,
      label: translate('and'),
    },
    {
      value: EAdvancedFilterLogic.OR,
      label: translate('or'),
    },
  ];

  selectedOption: TSelectOption;

  ngOnChanges(): void {
    this.findSelectedOption();
  }

  findSelectedOption(): void {
    this.selectedOption = this.logicOptions.find((item) => item.value === this.ppLogic);
  }

  changeLogic(newLogic: TSelectOption): void {
    this.ppLogicChange.emit(newLogic.value as EAdvancedFilterLogic);
  }
}
