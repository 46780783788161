import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import {
  setChangedWorkspace,
  setChangedWorkspaces,
} from 'src/app/project/modules/workspace/workspace';
import { UpdateWorkspaceUsers } from 'src/app/project/modules/workspace/workspace.actions';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventUserAccess,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { ChangeLimitModalComponent } from '../../../components/change-limit-modal/change-limit-modal.component';
import { TChangeLimitsModalData } from '../../../components/change-limit-modal/change-limit-modal.model';
import { ModalService } from '../../../components/modal/modal.service';
import { SiteSettingsUsersService } from '../../site/site-settings/site-settings-users/site-settings-users.service';
import { EUserRole } from '../share-utils/user-roles';
import { TImportShareWorkspaceUsersDTO, TShare } from '../share.model';
import { SharesService } from '../shares.service';

@Injectable({
  providedIn: 'root',
})
export class ImportUsersModalService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly shouldCloseModal = new Subject<boolean>();

  constructor(
    private store: Store,
    private sharesService: SharesService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private modalService: ModalService,
    private siteSettingsUsersService: SiteSettingsUsersService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  importShares(
    workspace: TWorkspace,
    userList: TImportShareWorkspaceUsersDTO,
    shareWorkspaceId: string,
    isFromChangeLimitsModal: boolean = false,
  ): Observable<TShare[]> {
    return this.sharesService.importShares(shareWorkspaceId, userList).pipe(
      tap((response) => {
        this.onImportSharesSuccess({
          response,
          userList,
          workspace,
          shareWorkspaceId,
          isFromChangeLimitsModal,
        });
      }),
      catchError((error) =>
        this.onImportSharesError({ error, workspace, userList, shareWorkspaceId }),
      ),
    );
  }

  private onImportSharesError({
    error,
    workspace,
    userList,
    shareWorkspaceId,
  }: {
    error;
    workspace: TWorkspace;
    userList: TImportShareWorkspaceUsersDTO;
    shareWorkspaceId: string;
  }): Observable<never> {
    logErrorInSentry(error);
    this.shouldCloseModal.next(false);

    const promptText = this.translationPipe.transform('prompt_share_import_error');

    if (error.status === EStatusCode.UPGRADE_REQUIRED) {
      this.showImportUsersLimitModal(workspace, userList, shareWorkspaceId);

      return;
    }

    this.promptService.showError(promptText);

    return throwError(error);
  }

  private onImportSharesSuccess({
    response,
    userList,
    workspace,
    shareWorkspaceId,
    isFromChangeLimitsModal,
  }: {
    response: TShare[];
    userList: TImportShareWorkspaceUsersDTO;
    workspace: TWorkspace;
    shareWorkspaceId: string;
    isFromChangeLimitsModal: boolean;
  }) {
    const promptText = this.translationPipe.transform('prompt_share_import');

    this.sharesService.addWorkspaceShares(response);
    this.addWorkspaceUsers(userList, workspace, shareWorkspaceId);
    // checking if the method has been called from the other component
    if (isFromChangeLimitsModal) {
      this.shouldCloseModal.next(true);
    } else {
      this.modalService.hideModal();
    }
    this.modalService.hideModal();

    setChangedWorkspace(shareWorkspaceId, true);
    this.siteSettingsUsersService.newShareCloseTriggerEvent();

    logEventInGTAG(EGoogleEventUserAccess.USER_ACCESS__IMPORT, {
      event_category: EGoogleEventCategory.USER_ACCESS,
    });
    this.promptService.showSuccess(promptText);
    setChangedWorkspaces(true);
    setChangedWorkspace(shareWorkspaceId);
  }

  private addWorkspaceUsers(
    userList: TImportShareWorkspaceUsersDTO,
    workspace: TWorkspace,
    workspaceId: string,
  ): void {
    userList.forEach((user) => {
      workspace.users.push(user.userId);
    });

    this.store.dispatch(
      new UpdateWorkspaceUsers({
        workspaceId,
        users: workspace.users,
      }),
    );
  }

  private showImportUsersLimitModal(
    workspace: TWorkspace,
    userList: TImportShareWorkspaceUsersDTO,
    shareWorkspaceId: string,
  ): void {
    this.modalService.hideModal().then(() => {
      this.setModalData(workspace.accountId, userList);

      this.modalService.showModal(ChangeLimitModalComponent, {
        onBeforeClose: (cancelled) => {
          if (!cancelled) {
            this.importShares(workspace, userList, shareWorkspaceId, true).subscribe();
          }

          return this.getShouldCloseModalResult(cancelled);
        },
      });
    });
  }

  private getShouldCloseModalResult(cancelled: boolean): Promise<boolean> {
    if (!cancelled) {
      return this.shouldCloseModal.pipe(takeUntil(this.destroy$), take(1)).toPromise();
    }

    return Promise.resolve(true);
  }

  private setModalData(accountId: string, userList: TImportShareWorkspaceUsersDTO): void {
    const modalData: TChangeLimitsModalData = {
      firstMessageKey: 'users_limit_reached_description_1',
      secondMessageKey: 'users_limit_reached_description_2',
      confirmKey: 'add_user',
      header: 'users_limit_reached',
      accountId: accountId,
      changedLimits: {
        createList: [],
        editList: [],
        deleteList: [],
        importList: userList.map((user) => ({
          userId: user.userId,
          shareOption: user.shareOption as EUserRole,
        })),
      },
    };

    this.modalService.setData<TChangeLimitsModalData>(modalData);
  }
}
