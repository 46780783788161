import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { EUserRole } from '../../../share/share-utils/user-roles';
import { TShare } from '../../../share/share.model';
import { SelectedSharesService } from '../../services/selected-shares.service';

@Component({
  selector: 'pp-user-management-select-cell',
  templateUrl: './user-management-select-cell.component.html',
  styleUrls: ['./user-management-select-cell.component.scss'],
})
export class UserManagementSelectCellComponent implements OnInit, OnDestroy {
  @Input() ppShare: TShare;

  private readonly destroy$ = new EventEmitter<void>();

  isSelected: boolean;
  isAccountAdmin: boolean;

  constructor(private selectedSharesService: SelectedSharesService) {}

  ngOnInit(): void {
    this.isSelected = this.selectedSharesService
      .getSelectedSharesIds()
      .includes(this.ppShare.shareId);
    this.isAccountAdmin = this.ppShare.shareOption === EUserRole.ACCOUNT_ADMIN;

    this.selectedSharesService.selectedSharesIdsChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedShares) => {
        this.isSelected = selectedShares.includes(this.ppShare.shareId);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  selectCell(): void {
    if (this.isAccountAdmin) {
      return;
    }

    this.selectedSharesService.toggleSiteSelected(this.ppShare.shareId);
  }
}
