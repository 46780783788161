export enum EPropName {
  IMAGES = 'images',
  IMAGES360 = 'images360',
  VIDEOS = 'videos',
  DOCUMENTS = 'documents',
  STATUS = 'status',
  PRIORITY = 'priority',
  DESCRIPTION = 'description',
  DESCRIPTION_RICH = 'descriptionRich',
  TITLE = 'title',
  TAGS = 'tags',
  ASSIGNEES = 'assignees',
  CUSTOM_FIELD = 'customFieldSimplyList',
  PIN = 'pin',
  ADDITIONAL_PINS = 'additionalPins',
  PINS = 'pins',
  POLYGONS = 'polygons',
  FLAGGED = 'flagged',
}
