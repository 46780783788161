import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { IconModule } from '../icon/icon-module';
import { HideDropdownModule } from '../input/hide-dropdown/hide-dropdown.module';
import { SelectModule } from '../input/select/select.module';
import { SearchBoxModule } from '../search-box/search-box.module';
import { CurrencyPickerDropdownComponent } from './currency-picker-dropdown/currency-picker-dropdown.component';
import { CurrencyPickerComponent } from './currency-picker.component';

@NgModule({
  declarations: [CurrencyPickerComponent, CurrencyPickerDropdownComponent],
  imports: [
    CommonModule,
    SelectModule,
    PipesModule,
    IconModule,
    SearchBoxModule,
    HideDropdownModule,
  ],
  exports: [CurrencyPickerComponent],
})
export class CurrencyPickerModule {}
