import { getTimeAsUtcStartOfDay } from '../../../../../core/helpers/date/date';
import { ECustomFieldType } from '../../../custom-fields/custom-field-types-enums';
import { GET_CUSTOM_FIELDS } from '../../../custom-fields/custom-fields.store';
import { GET_TIMELINE } from '../timeline.ui.store';

export function getBaseTimelineDateRange(): Date[] {
  const timeline = GET_TIMELINE();
  const points = timeline.points;

  let minDateEpoch = 95614819200000; // 30 November 4999
  let maxDateEpoch = 155001600000; // 30 November 1974
  let minDate: Date = null;
  let maxDate: Date = null;
  let fieldsFilled = false;

  points.forEach((_point) => {
    const timelineFieldId = Object.keys(_point.customFieldsMap).find((_customFieldId) => {
      const customField = GET_CUSTOM_FIELDS()[_customFieldId];

      return customField.type === ECustomFieldType.TIMELINE;
    });

    if (timelineFieldId) {
      const timelineField = _point.customFieldsMap[timelineFieldId];

      if (timelineField.value) {
        const values = timelineField.value.split('~');
        const startDate = parseFloat(values[0]);
        const endDate = parseFloat(values[1]);

        fieldsFilled = true;
        minDateEpoch = Math.min(minDateEpoch, startDate);
        maxDateEpoch = Math.max(maxDateEpoch, endDate);
      }
    }
  });

  if (fieldsFilled) {
    const utcMinDate = getTimeAsUtcStartOfDay(new Date(minDateEpoch));
    const utcMaxDate = getTimeAsUtcStartOfDay(new Date(maxDateEpoch));

    minDate = new Date(utcMinDate);
    maxDate = new Date(utcMaxDate);
  }

  return [minDate, maxDate];
}
