import { EVolyIntegrationStatus } from '../../../modules/integrations/utils/integrations.consts';

export const API_integration_voly_status_change = (
  workspaceId: string,
  status: EVolyIntegrationStatus,
) => `/api/v1/workspace/${workspaceId}/integrations/voly?status=${status}`;

export const API_integration_voly_custom_field_visibility_change = (
  workspaceId: string,
  customFieldId: string,
  display: boolean,
) =>
  `/api/v1/workspaces/${workspaceId}/custom-field-templates/${customFieldId}/integrations/voly?display=${display}`;

export const API_integration_voly_audit_log = (workspaceId: string) =>
  `/api/v1/workspace/${workspaceId}/integrations/voly/activities`;
