<div
  class="formulaPiece"
  [class.formulaPiece__clickable]="ppClickable"
  [class.formulaPiece__clickable--disabled]="ppClickable && (ppDisabled || disabledFormula)"
  [class.formulaPiece__customField]="ppFormulaPiece.type === EFormulaPieceType.CUSTOM_FIELD"
  [class.formulaPiece__customFieldDisabled]="
    (ppDisabled && ppFormulaPiece.type === EFormulaPieceType.CUSTOM_FIELD) || disabledFormula
  "
  [class.formulaPiece__operatorDisabled]="
    ppDisabled && ppFormulaPiece.type == EFormulaPieceType.OPERATOR
  "
  [draggable]="ppCanDelete"
  ppTooltip
  [ppTitle]="tooltipKey ? (tooltipKey | ppTranslate) : null"
  (click)="triggerAction()"
>
  <div class="formulaPiece__icon" (click)="deleteFormulaPiece()">
    <pp-icon [ppSrc]="icon" *ngIf="icon" ppClass="setSize14" ppColor="inherit"></pp-icon>
  </div>

  <div class="formulaPiece__value">{{ pieceName }}</div>

  <div class="formulaPiece__delete" *ngIf="ppCanDelete" (click)="deleteFormulaPiece()">
    <pp-icon [ppSrc]="EIconPath.ICON_CLOSE_14" ppClass="setSize14" ppColor="inherit"></pp-icon>
  </div>
</div>
