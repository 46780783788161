<ng-container>
  <div class="notification__image-wrapper">
    <img class="notification__image" [src]="EIconPath.NOTIFICATION_ICON_FILE" />
  </div>

  <ng-container *ngIf="isNewValue">
    <span class="notification__blue-text">{{ 'added' | ppTranslate }}</span>

    <ng-container *ngIf="addedSingleFile">
      {{ 'a' | ppTranslate }}
      <span class="notification__bold">{{ 'file_uppercase' | ppTranslate }}</span>
    </ng-container>

    <ng-container *ngIf="addedMultipleFiles">
      {{
        ppNotification.changeBody.oldValue
          ? ppNotification.changeBody.newValue.length - ppNotification.changeBody.oldValue.length
          : ppNotification.changeBody.newValue.length
      }}
      <span class="notification__bold">{{ 'files_uppercase' | ppTranslate }}</span>
    </ng-container>

    {{ 'to_a_point_you' | ppTranslate }}
    {{ notificationReason }}.
  </ng-container>

  <ng-container *ngIf="!isNewValue">
    <span class="notification__blue-text">{{ 'removed' | ppTranslate }}</span>

    <ng-container *ngIf="removedSingleFile">
      {{ 'a' | ppTranslate }}
      <span class="notification__bold">{{ 'file_uppercase' | ppTranslate }}</span>
    </ng-container>

    <ng-container *ngIf="removedMultipleFiles">
      {{
        ppNotification.changeBody.oldValue
          ? ppNotification.changeBody.newValue.length - ppNotification.changeBody.oldValue.length
          : ppNotification.changeBody.newValue.length
      }}
      <span class="notification__bold">{{ 'files_uppercase' | ppTranslate }}</span>
    </ng-container>

    {{ 'from_a_point_you' | ppTranslate }}
    {{ notificationReason }}.
  </ng-container>
</ng-container>
