import { CLEAR_TILES, SET_TILES } from './tiles.store';

import { addItemToStore, deleteFromStore, getItemFromStore } from '../../helpers/database/database';
import { createBlob } from './plan-methods';

export type TTilesResponse = {
  workspaceId: string;
  tiles: TTilesDatabase;
  version: number;
};

export type TTilesDatabase = {
  [tileCoords: string]: string;
};

export type TTiles = {
  [tileCoords: string]: Blob;
};

export function getTiles(_workspaceId: string, _version: number): Promise<unknown> {
  return new Promise((_resolve) => {
    checkTilePresence(_workspaceId, _version)
      .then((_tiles: TTilesDatabase) => {
        const tiles: TTiles = {};

        Object.keys(_tiles).forEach((key) => {
          tiles[key] = createBlob(_tiles[key], 'image/png');
        });

        CLEAR_TILES();
        SET_TILES(tiles);
      })
      .catch(() => {
        CLEAR_TILES();
      })
      .finally(() => {
        _resolve(null);
      });
  });
}

export function checkTilePresence(_workspaceId: string, _version: number): Promise<unknown> {
  return new Promise((_resolve, _reject) => {
    getItemFromStore<TTilesResponse>('main', 'tiles', _workspaceId)
      .then((_response) => {
        if (_response.version === _version) {
          _resolve(_response.tiles);
        } else {
          _reject();
        }
      })
      .catch(() => {
        _reject();
      });
  });
}

export function updateTileStore(
  _workspaceId: string,
  _tiles: {
    [key: string]: Blob;
  },
): Promise<unknown> {
  return new Promise((_resolve, _reject) => {
    const response = {
      workspaceId: _workspaceId,
      tiles: _tiles,
      version: 0,
    };

    getItemFromStore('main', 'workspaces', _workspaceId)
      .then((_workspaceResponse: any) => {
        response.version = _workspaceResponse.sitePlan.version;

        getItemFromStore<TTilesResponse>('main', 'tiles', _workspaceId)
          .then((_tilesResponse) => {
            const tiles: TTiles = {};

            Object.keys(_tiles).forEach((key) => {
              tiles[key] = createBlob(_tilesResponse[key], 'image/png');
            });

            response.tiles = { ...tiles, ..._tiles };

            if (_workspaceResponse.sitePlan.version === _tilesResponse.version) {
              addItemToStore('main', 'tiles', response);
              _resolve(_tiles);
            } else {
              deleteFromStore('main', 'tiles', _workspaceId)
                .then(() => {
                  addItemToStore('main', 'tiles', response);
                  _resolve(_tiles);
                })
                .catch(() => {
                  _reject();
                });
            }
          })
          .catch(() => {
            addItemToStore('main', 'tiles', response);
            _resolve(_tiles);
          });
      })
      .catch(() => {
        addItemToStore('main', 'tiles', response);
        _resolve(_tiles);
      });
  });
}
