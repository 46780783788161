import { Routes } from '@angular/router';
import { COMMON_GUARD_SERVICES } from '../../services/guards/guard-services.const';
import { FleetManagementEmptyFleetComponent } from './fleet-management-empty-fleet/fleet-management-empty-fleet.component';
import { NewAssetPageComponent } from './fleet-management-fleet-page/new-asset-page/new-asset-page.component';
import { FleetManagementLandingPageComponent } from './fleet-management-landing-page/fleet-management-landing-page.component';
import { FleetPageComponent } from './fleet-page/fleet-page.component';

export enum EFleetManagementRoutesSegments {
  FLEET_MANAGEMENT = 'fleet-management',
  NEW = 'new',
  FLEET = 'fleet',
  ID = 'id',
  ASSET = 'asset',
  SETTINGS = 'settings',
}

export enum EFleetManagementRoutesParams {
  FLEET_ID = 'id',
  ASSET_ID = 'assetId',
}

export const fleetManagementRoutes: Routes = [
  {
    path: EFleetManagementRoutesSegments.FLEET_MANAGEMENT,
    component: FleetManagementLandingPageComponent,
    canActivate: COMMON_GUARD_SERVICES,
    children: [
      {
        path: EFleetManagementRoutesSegments.NEW,
        component: FleetManagementEmptyFleetComponent,
      },
      {
        path: '',
        pathMatch: 'full',
        component: FleetPageComponent,
      },
      {
        path: `${EFleetManagementRoutesSegments.FLEET}/:${EFleetManagementRoutesParams.FLEET_ID}`,
        component: FleetPageComponent,
        pathMatch: 'full',
      },
      {
        path: `${EFleetManagementRoutesSegments.FLEET}/:${EFleetManagementRoutesParams.FLEET_ID}/${EFleetManagementRoutesSegments.SETTINGS}`,
        component: FleetManagementEmptyFleetComponent,
        pathMatch: 'full',
      },
      {
        path: `${EFleetManagementRoutesSegments.FLEET}/:${EFleetManagementRoutesParams.FLEET_ID}/${EFleetManagementRoutesSegments.ASSET}/:${EFleetManagementRoutesParams.ASSET_ID}`,
        component: NewAssetPageComponent,
      },
    ],
  },
];
