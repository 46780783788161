import { Component } from '@angular/core';
import { EIconPath } from '../../shared/enums/icons.enum';

@Component({
  selector: 'pp-archived-chip',
  templateUrl: './archived-chip.component.html',
  styleUrls: ['./archived-chip.component.scss'],
})
export class ArchivedChipComponent {
  EIconPath = EIconPath;
}
