import VirtualScroller from 'src/app/project/modules/external/virtual-scroller/VirtualScroller';

export function dragoverCallback(folderId: string, virtualScroller: VirtualScroller): void {
  if (folderId) {
    virtualScroller.visibleIndexes.forEach((_index) => {
      const _element = virtualScroller.dataElements[_index];

      if (_element) {
        switch (folderId) {
          case _element.dataset?.folder_id_empty:
            _element.classList.add('sidePanel__dragHighlight__folder--empty');
            break;
          case _element.dataset?.folder_id:
            _element.classList.add('sidePanel__dragHighlight__folder');
            break;
          case _element.dataset?.folder_id_site:
            _element.classList.add('sidePanel__dragHighlight');
            break;
          default:
            _element.classList.remove('sidePanel__dragHighlight__folder');
            _element.classList.remove('sidePanel__dragHighlight');
            _element.classList.remove('sidePanel__dragHighlight__folder--empty');
        }
      }
    });
  } else {
    virtualScroller.dataElements.forEach((_element) => {
      if (_element?.dataset) {
        _element.classList.remove('sidePanel__dragHighlight__folder');
        _element.classList.remove('sidePanel__dragHighlight');
        _element.classList.remove('sidePanel__dragHighlight__folder--empty');
      }
    });
  }
}
