<div class="dashletActivity">
  <img
    class="dashletActivity__icon dashletActivity__icon--narrow"
    [src]="EIconPath.POINT_ACTIVITY_MOVE"
  />

  <div class="dashletActivity__text">
    <ng-container [ngSwitch]="ppChange.propName">
      {{ 'moved_this_point_from' | ppTranslate }}
      <span class="dashletActivities__item-quoted">{{ ppChange.oldValue }}</span>

      {{ 'to' | ppTranslate | lowercase }}
      <span class="dashletActivities__item-quoted">{{ ppChange.newValue }}.</span>
    </ng-container>
  </div>
</div>
