import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pp-saved-view-preview-header',
  templateUrl: './saved-view-preview-header.component.html',
  styleUrls: ['./saved-view-preview-header.component.scss'],
})
export class SavedViewPreviewHeaderComponent {
  @Input() ppWorkspaceId: string;
  @Input() ppHideText = false;
  @Input() ppProcessing = false;
  @Output() ppSaveToCurrentView = new EventEmitter<void>();

  saveToCurrentView(): void {
    this.ppSaveToCurrentView.next();
  }
}
