import { fabric } from 'fabric';
import * as Hammer from 'hammerjs';

import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TUser } from 'src/app/project/modules/user/user.model';
import { TAccount } from '../../modules/account/account.model';

import { TPoint } from 'src/app/project/modules/points/points.model';
import { PointsService } from 'src/app/project/modules/points/points.service';
import { TUISettings } from 'src/app/project/modules/ui/ui.model';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { GALLERY_OVERLAY_ESC, ListenerService } from '../../../core/helpers/listener.service';
import { DeviceService } from '../../../core/services/device.service';
import { WorkspaceService } from '../../modules/workspace/workspace.service';
import { GalleryOverlayService, TOverlay } from './gallery-overlay.service';
import { ImageRotationComponent } from './image-rotation/image-rotation.component';

import { DOCUMENT } from '@angular/common';
import { canEditPoint } from 'src/app/project/modules/share/share-utils/share-permissions';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { ScreenService } from '../../../core/services/window/screen.service';
import { TMediaObject } from '../../modules/points/point-modal/point-attachments/point-attachments.service';
import { logEventInGTAG } from '../../services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from '../../services/analytics/google-analytics.consts';
import { EIconPath } from '../../shared/enums/icons.enum';
import {
  TAnnotationsAddedParams,
  TRotationAngleChangeParams,
} from './image-annotations/image-annotation.model';

@Component({
  selector: 'pp-gallery-overlay',
  templateUrl: './gallery-overlay.component.html',
  styleUrls: ['./gallery-overlay.component.scss'],
})
export class GalleryOverlayComponent implements OnInit, OnDestroy {
  @ViewChild('imageElement') imageElement: ElementRef<HTMLImageElement>;
  @ViewChild('zoomCanvasElement') zoomCanvasElement: ElementRef;
  @ViewChild(ImageRotationComponent) imageRotationComponent: ImageRotationComponent;

  private readonly destroy$ = new Subject<void>();

  private user$: Observable<TUser>;
  private accounts$: Observable<TAccount[]>;
  workspace: TWorkspace = null;

  ui$: Observable<TUISettings>;
  ui: TUISettings;

  EUserRole = EUserRole;
  EIconPath = EIconPath;

  zoomCanvasFabric: fabric.Canvas = null;
  overlay: TOverlay = this.galleryOverlayService.getOverlay();
  annotationsActive = false;
  annotationSaving = false;
  rotationAngle: number = null;
  isZoomed = false;
  public canDelete = false;
  account: TAccount;
  isMobile = false;
  isTablet = false;
  user: TUser;
  previousZoom = 1;
  savedZoom = 1;
  point: TPoint;
  accounts: TAccount[];
  _id: string;
  workspaceId: string;
  attachmentId: string;

  loadedURL = '';
  tempRotation = this.galleryOverlayService.getTempRotation();

  documentBodyElement: ElementRef['nativeElement'];
  zoomController: Hammer.Manager;

  @HostListener('window:popstate', ['$event'])
  onPopState(): void {
    this.hide();
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<{
      user: TUser;
      accounts: TAccount[];
      ui: TUISettings;
    }>,
    public galleryOverlayService: GalleryOverlayService,
    private workspaceService: WorkspaceService,
    private deviceService: DeviceService,
    private listenerService: ListenerService,
    private pointsService: PointsService,
    private activeService: ActiveService,
    private screenService: ScreenService,
  ) {
    this.ui$ = this.store.pipe(select(EStore.UI));
    this.user$ = this.store.pipe(select(EStore.USER));
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
  }

  ngOnInit() {
    this.listenerService.add(GALLERY_OVERLAY_ESC, () => this.hide());
    this.galleryOverlayService.setOverlayComponent(this);

    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });

    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
      const accountId = this.activeService.getActiveAccountId();
      this.accounts = accounts;

      if (accountId && Array.isArray(accounts) && accounts.length) {
        this.account = accounts.find((account) => account.accountId === accountId);
      } else if (Array.isArray(accounts) && accounts.length && this.workspace) {
        this.account = accounts.find((account) => account.accountId === this.workspace.accountId);
      } else {
        this.account = null;
      }
    });

    this.ui$.pipe(takeUntil(this.destroy$)).subscribe((ui) => {
      this.ui = ui;
    });

    this.documentBodyElement = this.document.body;
  }

  ngOnDestroy() {
    this.listenerService.remove(GALLERY_OVERLAY_ESC);
    this.destroy$.next();
  }

  setData(_id: string, workspaceId: string, attachmentId: string): void {
    this._id = _id;
    this.workspaceId = workspaceId;
    this.attachmentId = attachmentId;
    const accountId = this.activeService.getActiveAccountId();

    this.point = this.pointsService.findPoint(_id);
    this.workspace = this.workspaceService.findWorkspace(this.point.workspaceRef.id);
    this.canDelete = canEditPoint(this.workspace.share.shareOption, this.user);

    if (accountId && Array.isArray(this.accounts) && this.accounts.length) {
      this.account = this.accounts.find((account) => account.accountId === accountId);
    } else if (Array.isArray(this.accounts) && this.accounts.length && this.workspace) {
      this.account = this.accounts.find(
        (account) => account.accountId === this.workspace.accountId,
      );
    } else {
      this.account = null;
    }
  }

  previous(): void {
    this.isZoomed = false;

    this.removeZoom();

    if (!this.annotationsActive) {
      this.galleryOverlayService.previous();
    }
  }

  next(): void {
    this.isZoomed = false;

    this.removeZoom();

    if (!this.annotationsActive) {
      this.galleryOverlayService.next();
    }
  }

  hide(): void {
    this.isZoomed = false;
    this.loadedURL = '';

    this.removeZoom();

    if (!this.annotationsActive) {
      this.galleryOverlayService.hide();
    }
  }

  addAnnotation(open?: boolean): void {
    this.isZoomed = false;

    this.removeZoom();

    if (open) {
      this.annotationsActive = true;
      this.galleryOverlayService.removeListeners();
      logEventInGTAG(EGoogleEventSite.SITE__GALLERY__ANNOTATE, {
        event_category: EGoogleEventCategory.SITE,
      });
    } else {
      this.annotationsActive = false;
      this.galleryOverlayService.addListeners();
    }
  }

  updateAnnotationSavingState(event: boolean): void {
    this.annotationSaving = event;
  }

  rotateImage(event: TRotationAngleChangeParams): void {
    this.isZoomed = false;
    this.overlay.elements[event.index].rotationAngle = event.angle;

    this.removeZoom();
  }

  updateImageWithAnnotation(event: TAnnotationsAddedParams): void {
    this.overlay.elements[event.index].id = event.id;
    this.overlay.elements[event.index].base64src = event.base64src;
  }

  downloadImage(): void {
    logEventInGTAG(EGoogleEventSite.SITE__GALLERY__DOWNLOAD, {
      event_category: EGoogleEventCategory.SITE,
    });

    this.galleryOverlayService.downloadImage();
  }

  deleteImage(): void {
    this.isZoomed = false;

    logEventInGTAG(EGoogleEventSite.SITE__GALLERY__DELETE, {
      event_category: EGoogleEventCategory.SITE,
    });
    this.removeZoom();
    this.galleryOverlayService.deleteImage();
  }

  zoomImage(): void {
    const initialScale =
      this.imageElement.nativeElement.height / this.imageElement.nativeElement.naturalHeight;

    if (!this.isZoomed) {
      const imgInstance = new fabric.Image(this.imageElement.nativeElement, {});
      const pinch = new Hammer.Pinch();

      delete Hammer.defaults.cssProps.userSelect;
      this.zoomController = new Hammer.Manager(this.documentBodyElement);

      this.zoomController.add(pinch);
      this.zoomController.get('pinch').set({ enable: true });

      const windowDimensions = this.screenService.getDimensions();
      this.zoomCanvasElement.nativeElement.width = windowDimensions.innerWidth;
      this.zoomCanvasElement.nativeElement.height = windowDimensions.innerHeight;

      this.zoomCanvasFabric = new fabric.Canvas('zoomCanvasElement', {
        perPixelTargetFind: true,
        targetFindTolerance: 5,
        stateful: true,
      });

      this.zoomCanvasFabric.allowTouchScrolling = true;

      this.zoomController.on('pinch', (ev) => {
        let zoom = this.savedZoom * ev.scale;

        if (zoom > 10) {
          zoom = 10;
        } else if (zoom < initialScale) {
          zoom = initialScale;
        }

        this.zoomCanvasFabric.zoomToPoint(new fabric.Point(ev.center.x, ev.center.y), zoom);
      });

      this.zoomController.on('pinchstart', () => {
        this.savedZoom = this.zoomCanvasFabric.getZoom();
      });

      this.zoomCanvasFabric.on('mouse:wheel', (opt) => {
        const delta = -opt.e.deltaY;
        const point = new fabric.Point(opt.e.x, opt.e.y);
        let zoom = this.zoomCanvasFabric.getZoom();

        zoom = zoom + delta / 500;

        if (zoom > 10) {
          zoom = 10;
        } else if (zoom < initialScale) {
          zoom = initialScale;
        }

        this.zoomCanvasFabric.zoomToPoint(point, zoom);
        opt.e.preventDefault();
        opt.e.stopPropagation();
      });

      this.zoomCanvasFabric.on('object:modified', (options) => {
        const { innerWidth, innerHeight } = this.screenService.getDimensions();

        const obj = options.target;
        const boundingRect = obj.getBoundingRect(true);
        const zoom = this.zoomCanvasFabric.getZoom();
        const topLimit = obj.oCoords.tl.y < 0;
        const bottomLimit = obj.oCoords.bl.y > innerHeight;
        const leftLimit = obj.oCoords.tl.x < 0;
        const rightLimit = obj.oCoords.tr.x > innerWidth;

        if (
          (leftLimit && !rightLimit && zoom * obj.width <= innerWidth) ||
          (!leftLimit && rightLimit && zoom * obj.width > innerWidth)
        ) {
          obj.left = obj.aCoords.tl.x - obj.oCoords.tl.x;
        }

        if (
          (topLimit && !bottomLimit && zoom * obj.height <= innerHeight) ||
          (!topLimit && bottomLimit && zoom * obj.height > innerHeight)
        ) {
          obj.top = obj.aCoords.tl.y - obj.oCoords.tl.y;
        }

        if (
          (rightLimit && !leftLimit && zoom * obj.width <= innerWidth) ||
          (!rightLimit && leftLimit && zoom * obj.width > innerWidth)
        ) {
          obj.left = obj.aCoords.tl.x - obj.oCoords.tl.x - (boundingRect.width * zoom - innerWidth);
        }

        if (
          (bottomLimit && !topLimit && zoom * obj.height <= innerHeight) ||
          (!bottomLimit && topLimit && zoom * obj.height > innerHeight)
        ) {
          obj.top =
            obj.aCoords.tl.y - obj.oCoords.tl.y - (boundingRect.height * zoom - innerHeight);
        }

        obj.setCoords();
        obj.saveState();
      });

      this.zoomCanvasFabric.add(imgInstance);
      imgInstance.center().setCoords();

      this.zoomCanvasFabric.item(0).hasControls = this.zoomCanvasFabric.item(0).hasBorders = false;

      if (this.overlay.elements[this.overlay.index].rotationAngle) {
        imgInstance.rotate(this.overlay.elements[this.overlay.index].rotationAngle);
      } else {
        imgInstance.rotate(0);
      }

      this.zoomCanvasFabric.zoomToPoint(
        new fabric.Point(
          this.zoomCanvasFabric.getWidth() / 2,
          this.zoomCanvasFabric.getHeight() / 2,
        ),
        2,
      );
      this.previousZoom = 2;

      this.zoomCanvasFabric.renderAll();
      this.imageElement.nativeElement.style.visibility = 'hidden';

      logEventInGTAG(EGoogleEventSite.SITE__GALLERY__ZOOM, {
        event_category: EGoogleEventCategory.SITE,
      });
    } else {
      this.removeZoom();
    }

    this.isZoomed = !this.isZoomed;
  }

  removeZoom(): void {
    if (this.zoomController) {
      this.zoomController.off('pinch');
      delete this.zoomController;
    }

    if (this.zoomCanvasFabric) {
      this.zoomCanvasFabric.off('mouse:wheel');
    }

    if (this.zoomCanvasElement) {
      this.zoomCanvasElement.nativeElement.width = 0;
      this.zoomCanvasElement.nativeElement.height = 0;

      if (this.zoomCanvasFabric && this.zoomCanvasFabric._objects.length > 0) {
        this.zoomCanvasFabric.dispose();
      }

      if (this.imageElement.nativeElement) {
        this.imageElement.nativeElement.style.visibility = 'visible';
      }
    }

    this.previousZoom = 1;
  }

  loadImage(item: TMediaObject): void {
    this.loadedURL = item.base64src;
    this.tempRotation.rotation = null;
  }

  onImageElementRendered(imageElement: HTMLElement): void {
    this.galleryOverlayService.setImageElement(imageElement);
  }
}
