import { Component, OnDestroy } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import { TUser } from '../user.model';

import { ModalService } from '../../../components/modal/modal.service';
import { PromptService } from '../../../components/prompt/prompt.service';

import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { blurInput } from 'src/app/core/helpers/blur-input';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { getErrorMessage } from 'src/app/project/helpers/database/get-error-message';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventUserSettings,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { ResponseErrorService } from '../../errors/response-error.service';
import { UserUpdateService } from '../user-update.service';

type TPasswordInputType = 'password' | 'text';

type TPasswordInputs = {
  oldPassword: TPasswordInputType;
  newPassword: TPasswordInputType;
  confirmPassword: TPasswordInputType;
};

@Component({
  selector: 'app-update-password-modal',
  templateUrl: './update-password-modal.component.html',
  styleUrls: ['./update-password-modal.component.scss'],
})
export class UpdatePasswordModalComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly requestFinished$ = new Subject<void>();

  passwordInputTypes: TPasswordInputs = {
    oldPassword: 'password',
    newPassword: 'password',
    confirmPassword: 'password',
  };

  oldPassword = '';
  newPassword = '';
  confirmPassword = '';
  processing = false;
  newPasswordDirty = false;
  isPasswordCorrect = false;

  user$: Observable<TUser>;
  user: TUser;
  EIconPath = EIconPath;

  constructor(
    private store: Store<{ user: TUser }>,
    private promptService: PromptService,
    private modalService: ModalService,
    private translationPipe: TranslationPipe,
    private userUpdateService: UserUpdateService,
    private responseErrorService: ResponseErrorService,
  ) {
    this.user$ = this.store.pipe(select(EStore.USER));

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  changePassword(): void {
    if (this.processing || !this.isPasswordCorrect) {
      return;
    }

    this.processing = true;

    this.userUpdateService
      .updateUser(
        { oldPassword: this.oldPassword, newPassword: this.newPassword },
        this.user.userId,
      )
      .pipe(
        takeUntil(this.requestFinished$),
        tap((response) => {
          if (response) {
            this.processPasswordUpdateResponse();
          } else {
            this.processPasswordUpdateCancel();
          }
        }),
        catchError((error) => {
          this.displayErrorMessage(error);

          return this.responseErrorService.handleRequestError(error);
        }),
        finalize(() => {
          this.processing = false;
          this.requestFinished$.next();
        }),
      )
      .subscribe();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  validatePassword(valid: boolean): void {
    this.isPasswordCorrect = valid;
  }

  blurInput(event: Event): void {
    blurInput(event.target);
  }

  setPasswordVisibilityType(type: keyof TPasswordInputs, value: TPasswordInputType): void {
    this.passwordInputTypes[type] = value;
  }

  private displayErrorMessage(error: any) {
    getErrorMessage(error).then((errorMessage) => {
      // https://volyteam.atlassian.net/jira/software/c/projects/PS/boards/68?selectedIssue=PS-482
      // TODO Use translation keys instead of raw message from backend
      this.promptService.showError(errorMessage);
    });
  }

  private processPasswordUpdateResponse(): void {
    const promptText = this.translationPipe.transform('prompt_password_change');

    this.promptService.showSuccess(promptText);
    logEventInGTAG(EGoogleEventUserSettings.PROFILE_SETTINGS__PASSWORD, {
      event_category: EGoogleEventCategory.PROFILE_SETTINGS,
    });
    this.hideModal();
  }

  private processPasswordUpdateCancel(): void {
    const promptText = this.translationPipe.transform('prompt_password_change_cancel');

    this.promptService.showWarning(promptText);
  }
}
