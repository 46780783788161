import { Injectable, OnDestroy } from '@angular/core';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { PointPlanService } from 'src/app/project/modules/points/point-modal/point-plan/point-plan.service';
import { NEW_POINT_ID } from 'src/app/project/shared/constants/point.const';
import { PointsService } from 'src/app/project/modules/points/points.service';
import { EPriority } from 'src/app/project/modules/points/priorities';
import { DeviceService } from 'src/app/core/services/device.service';
import { EPlanPointEventType, PlanPointEventsService } from './plan-point-events.service';
import { PlanPointStylesService } from './plan-point-styles/plan-point-styles.service';
import { PlanPointVariablesService } from './plan-point-variables.service';
import { TOUCH_SCREEN_OFFSET } from '../../../shared/constants/plan-point.const';
import { TPlanData } from '../plan-data.service';
import { Feature } from 'ol';
import { Geometry, Point, Polygon } from 'ol/geom';
import { TranslateEvent } from 'ol/interaction/Translate';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeatureTranslateService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private draggedFeature: Feature<Geometry> = null;

  private normalStyle = {
    LOW: this.planPointStylesService.createNormalStyle(EPriority.LOW),
    MEDIUM: this.planPointStylesService.createNormalStyle(EPriority.MEDIUM),
    HIGH: this.planPointStylesService.createNormalStyle(EPriority.HIGH),
  };

  private draggingStyle = {
    LOW: this.planPointStylesService.createDraggingStyle(EPriority.LOW),
    MEDIUM: this.planPointStylesService.createDraggingStyle(EPriority.MEDIUM),
    HIGH: this.planPointStylesService.createDraggingStyle(EPriority.HIGH),
  };

  constructor(
    private deviceService: DeviceService,
    private activeService: ActiveService,
    private pointsService: PointsService,
    private planPointEventsService: PlanPointEventsService,
    private planPointVariablesService: PlanPointVariablesService,
    private pointPlanService: PointPlanService,
    private planPointStylesService: PlanPointStylesService,
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
  }

  createInteraction(feature: Feature): void {
    const plan = this.planPointVariablesService.getPlan();

    this.planPointVariablesService
      .getDragInteraction()
      .on('translatestart', (event) => this.featureTranslateStart(event, feature, plan));
    this.planPointVariablesService.getDragInteraction().on('translateend', (event) => {
      this.featureTranslateEnd(event, plan);
      this.planPointVariablesService.getDragInteraction();
    });
  }

  featureTranslateStart(event: TranslateEvent, feature: Feature, plan: TPlanData): void {
    const _id = this.activeService.getActivePointId();
    const point = this.pointsService.findPoint(_id);

    const translatedPins = event.features;
    const translatedPin = translatedPins.item(0);
    const X1 = (translatedPin.getGeometry() as Polygon).getCoordinates()[0];
    const Y1 = (translatedPin.getGeometry() as Polygon).getCoordinates()[1];
    const X2 = (feature.getGeometry() as Polygon).getCoordinates()[0];
    const Y2 = (feature.getGeometry() as Polygon).getCoordinates()[1];

    this.planPointVariablesService.setFeatureClicked(false);

    if (X1 === X2 && Y1 === Y2) {
      feature.setStyle(this.draggingStyle[point.priority]);
      this.planPointVariablesService.setChangedFeature(feature);

      this.draggedFeature = feature;
      plan.element.style.cssText = 'cursor: move';

      this.planPointEventsService.emit(EPlanPointEventType.TRANSLATE_START, feature);
    }
  }

  featureTranslateEnd(event: TranslateEvent, plan: TPlanData): void {
    const isMobileInput = this.deviceService.isTouchDevice();
    const _id = this.activeService.getActivePointId();
    const translatedPin = this.draggedFeature;
    const point = this.pointsService.findPoint(_id);

    let [x, y] = event.coordinate;

    if (translatedPin) {
      if (isMobileInput) {
        const pixelCoords = plan.instance.point.getPixelFromCoordinate([x, y]);

        pixelCoords[1] = pixelCoords[1] - TOUCH_SCREEN_OFFSET;

        const newCoords = plan.instance.point.getCoordinateFromPixel(pixelCoords);

        [x, y] = newCoords;

        (translatedPin.getGeometry() as Point).setCoordinates(newCoords);
      }

      plan.element.style.cssText = 'cursor: normal';

      translatedPin.setStyle(this.normalStyle[point.priority]);

      if (-y > -(plan.extent[1] + plan.extent[3])) {
        y = plan.extent[1] + plan.extent[3];

        (translatedPin.getGeometry() as Point).setCoordinates([x, y]);
      }

      if (x < 0) {
        x = 1;

        (translatedPin.getGeometry() as Point).setCoordinates([x, y]);
      }

      if (x > plan.extent[2] + plan.extent[0]) {
        x = plan.extent[2] + plan.extent[0];

        (translatedPin.getGeometry() as Point).setCoordinates([x, y]);
      }

      if (-y < 0) {
        y = -1;

        (translatedPin.getGeometry() as Point).setCoordinates([x, y]);
      }

      if (_id === NEW_POINT_ID) {
        this.pointPlanService
          .savePoint([{ x: Math.abs(x), y: Math.abs(y) }])
          .pipe(takeUntil(this.destroy$))
          .subscribe();
      }

      this.planPointEventsService.emit(EPlanPointEventType.TRANSLATE_END, this.draggedFeature);
      this.draggedFeature = null;
    }
  }
}
