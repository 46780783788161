import { Injectable } from '@angular/core';
import { PlanPointVariablesService } from './plan-point-variables.service';
import { Subject, timer } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PlanPointDragService {
  private readonly dragListenerDebounce$ = new Subject<void>();
  private dragListenerDebounceTimeMs = 300;
  private startedClickInside = false;

  constructor(private planPointVariablesService: PlanPointVariablesService) {}

  pointerDragEventOn(): void {
    const plan = this.planPointVariablesService.getPlan();
    const dragListenerRef = this.planPointVariablesService.getDragListenerRef();
    this.pointerDragEventOff();

    plan.instance.point.on('pointerdrag', dragListenerRef);
  }

  pointerDragEventOff(): void {
    const plan = this.planPointVariablesService.getPlan();
    const dragListenerRef = this.planPointVariablesService.getDragListenerRef();

    plan.instance.point.un('pointerdrag', dragListenerRef);
  }

  dragListener(): void {
    this.startedClickInside = true;
    this.dragListenerDebounce$.next();

    timer(this.dragListenerDebounceTimeMs)
      .pipe(
        takeUntil(this.dragListenerDebounce$),
        tap(() => {
          this.startedClickInside = false;
        }),
      )
      .subscribe();
  }

  getStartedClickInside(): boolean {
    return this.startedClickInside;
  }
}
