import { Component, OnInit } from '@angular/core';

import { DeleteCommentModalComponent } from '../delete-comment-modal/delete-comment-modal.component';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';

import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-comment-delete-dropdown',
  templateUrl: './comment-delete-dropdown.component.html',
  styleUrls: ['./comment-delete-dropdown.component.scss'],
})
export class CommentDeleteDropdownComponent implements OnInit {
  dropdown: TDropdown = this.dropdownService.getDropdown();

  commentId = '';
  EIconPath = EIconPath;

  constructor(private dropdownService: DropdownService, private modalService: ModalService) {}

  ngOnInit() {
    this.commentId = this.dropdown.data.commentId;
  }

  hideDropdown(): void {
    this.dropdownService.hideDropdown();
  }

  deleteComment(): void {
    this.modalService.setData(this.dropdown.data.commentId);

    this.modalService.showModal(DeleteCommentModalComponent, { blur: false });

    this.hideDropdown();
  }
}
