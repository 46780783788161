export const API_export_pdf_individual_zip = (): string =>
  'api/v1/point-exports/pdf/export-types/individual-zip-site';
export const API_export_word_individual_zip = (): string =>
  'api/v1/point-exports/word/export-types/individual-zip-site';
export const API_export_pdf_detailed = (): string =>
  'api/v1/point-exports/pdf/export-types/detailed-list-site';
export const API_export_word_detailed = (): string =>
  'api/v1/point-exports/word/export-types/detailed-list-site';
export const API_export_pdf_individual = (): string =>
  'api/v1/point-exports/pdf/export-types/individual';
export const API_export_word_individual = (): string =>
  'api/v1/point-exports/word/export-types/individual';
export const API_export_pdf_detailed_overview = (): string =>
  'api/v1/point-exports/pdf/export-types/detailed-list-overview';
export const API_export_pdf_simple_overview = (): string =>
  `api/v1/point-exports/pdf/export-types/simple-list-overview`;
export const API_export_word_simple_overview = (): string =>
  `api/v1/point-exports/word/export-types/simple-list-overview`;
export const API_export_pdf_simple = (): string =>
  `api/v1/point-exports/pdf/export-types/simple-list-site`;
export const API_export_pdf_site_plan = (): string =>
  'api/v1/point-exports/pdf/export-types/site-plan';
