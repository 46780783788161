<pp-advanced-filter-option-select
  class="advancedFilter__option"
  [ppItems]="advancedFilterDateOptions"
  [ppOption]="option"
  (ppOptionChange)="changeFieldCondition($event)"
  ppClass="advancedFilter__option--condition"
></pp-advanced-filter-option-select>

<div class="advancedFilter__dateContainer">
  <pp-datepicker
    [ppDate]="startDate"
    [class.advancedFilter__dateWide]="
      option === EAdvancedFilterOptions.IS_BEFORE ||
      option === EAdvancedFilterOptions.IS_AFTER ||
      option === EAdvancedFilterOptions.IS
    "
    [ppMaxDate]="ppType === 'date_created' || ppType === 'date_updated' ? today : null"
    (ppSelect)="changeFieldValue($event, 0)"
    [ppPlaceholder]="
      (option === EAdvancedFilterOptions.IS_BETWEEN ? 'from...' : 'select_date') | ppTranslate
    "
    *ngIf="
      option === EAdvancedFilterOptions.IS_BETWEEN ||
      option === EAdvancedFilterOptions.IS_BEFORE ||
      option === EAdvancedFilterOptions.IS_AFTER ||
      option === EAdvancedFilterOptions.IS
    "
  ></pp-datepicker>

  <pp-datepicker
    [ppDate]="endDate"
    (ppSelect)="changeFieldValue($event, 1)"
    [ppMaxDate]="ppType === 'date_created' || ppType === 'date_updated' ? today : null"
    [ppPlaceholder]="'to...' | ppTranslate"
    *ngIf="option === EAdvancedFilterOptions.IS_BETWEEN"
  ></pp-datepicker>
</div>
