import { TTimeframeElement } from './timeline-timeframes';

export function getMonthTopElements(startYear: number, numberOfYears: number): TTimeframeElement[] {
  const elements = [];

  for (let i = 0; i < numberOfYears; i++) {
    for (let quarter = 0; quarter < 4; quarter++) {
      elements.push({
        name: `Q${quarter + 1} ${startYear + i}`,
        width: 147,
      });
    }
  }

  return elements;
}
