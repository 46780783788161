import { GET_TABLE } from '../../table.ui.store';
import { createElement } from 'src/app/core/helpers/dom';
import { createMarkedKeywordElement } from '../body-cells-search';
import { createCellElement } from '../createCellElement';
import Tooltip from 'src/app/project/features/tooltip/Tooltip';
import { TColumn } from '../../columns/column.model';
import { EIconPath } from '../../../../../shared/enums/icons.enum';
import { getSvg } from 'src/app/core/helpers/get-svg';
import { ApiService } from '@core/http';

export function createTitleElement(
  index: number,
  column: TColumn,
  apiService: ApiService,
): HTMLElement {
  const table = GET_TABLE();
  let element;

  const containsAttachments = table.points[index].containsAttachments;

  if (containsAttachments) {
    const imageElement = createElement('img', {
      attrs: {
        src: EIconPath.ICON_TABLE_ATTACHMENTS,
      },
    });
    const imageElementWrapper = createElement('div', {
      attrs: {
        class: 'customSiteTable--attachments',
      },
      children: [imageElement],
    });

    const imageTooltip = new Tooltip({
      title: 'Contains attachments',
      mobileTooltip: false,
      childTooltip: false,
      element: imageElementWrapper,
      trimTooltip: false,
      trimElement: imageElementWrapper,
      options: {
        placement: 'right',
      },
    });

    imageTooltip.createTooltip();

    element = createCellElement({
      index: column.index,
      width: `${column.width}px`,
      child: createElement('div', {
        attrs: {
          class: 'customSiteTable__text customSiteTable__text--title',
        },
        children: [createMarkedKeywordElement(table.points[index].title), imageElementWrapper],
      }),
    });
  } else {
    element = createCellElement({
      index: column.index,
      width: `${column.width}px`,
      child: createElement('div', {
        attrs: {
          class: 'customSiteTable__text',
        },
        children: [createMarkedKeywordElement(table.points[index].title)],
      }),
    });
  }

  return element;
}
