declare global {
  // eslint-disable-next-line
  interface Window {
    DocumentTouch: any;
  }
}

export function isMobile(): boolean {
  return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function isTablet(): boolean {
  return /(iPad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i.test(
    navigator.userAgent,
  );
}

export function isIphone(): boolean {
  return /iPhone/i.test(navigator.userAgent || navigator.vendor);
}

export function isIpod(): boolean {
  return /iPod/i.test(navigator.userAgent || navigator.vendor);
}

export function isIpad(): boolean {
  return /iPad/i.test(navigator.userAgent || navigator.vendor);
}

export function isBrowserSafari(): boolean {
  return (
    navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1
  );
}

export function isBrowserFirefox(): boolean {
  const isFirefox = navigator.userAgent.indexOf('Firefox') > 0;

  return isFirefox;
}

export function isBrowserChrome(): boolean {
  const isChrome = navigator.userAgent.indexOf('Chrome') !== -1;

  return isChrome;
}

export function isBrowserOpera(): boolean {
  const isOpera =
    navigator.userAgent.indexOf('Opera') !== -1 || navigator.userAgent.indexOf('OPR') !== -1;

  return isOpera;
}

export function isBrowserEdge(): boolean {
  const isEdge = /Edg/.test(navigator.userAgent);

  return isEdge;
}

export function isTouchDevice(): boolean {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

  if (
    'ontouchstart' in window ||
    (window.DocumentTouch && document instanceof window.DocumentTouch)
  ) {
    return true;
  }

  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');

  return window.matchMedia(query).matches;
}
