import { ApplicationRef, Injectable, NgZone } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

import { Store } from '@ngrx/store';
import { concat, interval } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { AddComponentToView } from '../ui/ui.actions';
import { TUISettings } from '../ui/ui.model';

import { PromptService } from '../../components/prompt/prompt.service';

import { environment } from 'src/environments/environment';
import { TranslationPipe } from '../../features/translate/translation.pipe';
import { logErrorInSentry } from '../errors/response-error';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  loginDelay = 30 * 1000;

  constructor(
    private swUpdate: SwUpdate,
    private store: Store<{ ui: TUISettings }>,
    private applicationRef: ApplicationRef,
    private promptService: PromptService,
    private ngZone: NgZone,
    private translationPipe: TranslationPipe,
  ) {}

  subscribeToAppUpdates(): void {
    if (!environment.production) {
      console.log(`SERVICE WORKER UPDATE ENABLED: ${this.swUpdate.isEnabled}`);
    }

    this.checkForUpdateAfterAppStart();

    if (this.swUpdate.isEnabled) {
      if (!environment.production) {
        // eslint-disable-next-line no-console
        console.time();
      }

      this.swUpdate.versionUpdates.subscribe((event) => {
        if (event.type !== 'VERSION_READY') {
          return;
        }

        if (!environment.production) {
          // eslint-disable-next-line no-console
          console.timeEnd();
        }

        this.store.dispatch(
          new AddComponentToView({
            componentName: 'actionBar',
          }),
        );
      });

      this.swUpdate.unrecoverable.subscribe((event) => {
        const promptText = this.translationPipe.transform('prompt_sw_unrecoverable');

        logErrorInSentry(event.reason);
        this.promptService.showError(promptText, { duration: 60 });
      });

      const appIsStable$ = this.applicationRef.isStable.pipe(
        first((isStable) => isStable === true),
        tap(() => {
          if (!environment.production) {
            console.log('APP STABLE');
          }
        }),
      );

      const everySixHours$ = interval(6 * 60 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

      everySixHoursOnceAppIsStable$.subscribe(() => {
        this.swUpdate.checkForUpdate();
      });

      this.swUpdate.checkForUpdate();
    }
  }

  checkForUpdateAfterLogin(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate();
    }
  }

  checkForUpdateAfterAppStart(): void {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        if (this.swUpdate.isEnabled) {
          this.swUpdate.checkForUpdate();
        }
      }, this.loginDelay);
    });
  }
}
