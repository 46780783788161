<li
  ppTooltip
  ppTitle="Timeline"
  class="mainNav__element mainNav__element--with-image"
  [class.mainNav__element--active]="ppRouterPath.startsWith('/site/timeline')"
  [class.mainNav__element--spacing]="ppRemindersEnabled && !ppIsLandscape"
  [class.mainNav__element--no-spacing]="ppIsLandscape"
  [class.mainNav__element--disabled]="ppDisabled"
  [ppOptions]="{ placement: 'right' }"
  (click)="goToTimeline()"
>
  <img
    id="timelineMenuItem"
    data-m-target="Timeline icon"
    class="filterPanel__tooltip timelineIcon"
    [src]="EIconPath.SIDE_PANEL_TIMELINE"
  />
  <h6
    class="filterPanel__title filterPanel__title--img"
    *ngIf="ppIsMobile && !sidePanel.rightPanelExpanded"
  >
    {{ 'timeline' | ppTranslate }}
  </h6>
</li>
