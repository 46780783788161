<div class="radio" (click)="toggleRadio()">
  <div
    class="radio__inputBox"
    [ngClass]="class"
    [class.radio--blue--checked]="ppStyle === 'blue' && ppChecked && !ppDisabled"
  >
    <div
      *ngIf="ppChecked"
      class="radioCircle"
      [class.radioCircle--disabled]="ppChecked && ppDisabled"
    ></div>
  </div>

  <div class="radio__label">
    <ng-content></ng-content>
  </div>
</div>
