export enum EAccessParameter {
  EDIT = 'edit',
  READ = 'read',
  HIDE = 'hide',
}

export enum EAccessLevel {
  VIEW_ONLY = 'view only',
  HIDE_COMMENTS = 'hide comments',
  VIEW_COMMENTS = 'view comments',
  MAKE_COMMENTS = 'make comments',
}
