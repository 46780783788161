<div class="base">
  <pp-supported-browsers-bar></pp-supported-browsers-bar>
  <pp-action-bar></pp-action-bar>
  <pp-reminder-alert></pp-reminder-alert>
  <pp-top-bar [ppFleetLoaded]="fleetExists"></pp-top-bar>
  <pp-side-panel></pp-side-panel>

  <div
    #fleetManagement
    class="fleetManagement"
    [class.fleetManagement--full-width]="!sidePanel.expandedInitially"
    [class.fleetManagement--down]="ui.actionBar"
    [class.fleetManagement--topBarSpacing]="applySpacingFromTopBar"
  >
    <router-outlet></router-outlet>
  </div>
</div>
