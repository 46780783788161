import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { SetFiles } from './attachments.actions';

import { ResponseErrorService } from 'src/app/project/modules/errors/response-error.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { OfflineService } from '../../offline/offline.service';
import { TAttachment } from './attachments.model';

import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { FilesApiProviderService } from 'src/app/project/data-providers/api-providers/files-api-provider/files-api-provider.service';
import { TDeleteFilesRequest } from 'src/app/project/data-providers/api-providers/files-api-provider/files-requests.model';
import { PointsApiProviderService } from 'src/app/project/data-providers/api-providers/points-api-provider/points-api-provider.service';
import { TPointSizesRequest } from 'src/app/project/data-providers/api-providers/points-api-provider/points-requests.model';
import { EDocumentType } from 'src/app/project/shared/enums/document-type.enum';
import { TPointsSizeResponse } from 'src/app/project/view-models/points-size-response.model';
import { TAttachmentDTO } from '../../../view-models/attachment.view-model';
import { TCopyMoveOptions } from '../../site/site-options/points-copy-move-modal/copy-move-options';

export type TFetchFilesResponse = {
  attachmentIds?: string[];
  attachments?: TFetchFilesAttachments;
};

export type TFetchFilesAttachments = {
  [attachmentId: string]: TAttachment;
};

@Injectable({
  providedIn: 'root',
})
export class AttachmentsFilesService {
  constructor(
    private store: Store,
    private responseErrorService: ResponseErrorService,
    private activeService: ActiveService,
    private offlineService: OfflineService,
    private filesApiProviderService: FilesApiProviderService,
    private pointsApiProviderService: PointsApiProviderService,
  ) {}

  fetchFiles(_id: string): void {
    this.filesApiProviderService
      .fetchFiles(_id)
      .pipe(
        tap((response: TAttachmentDTO[]) => {
          const fileAttachments: TAttachment[] = response.map((_attachment) => ({
            fileName: _attachment.fileName,
            totalBytes: _attachment.totalBytes,
            originalFileSize: _attachment.blobs[0].size,
            mimeType: _attachment.mimeType,
            type: EDocumentType.FILE,
            createdOn: _attachment.header.createdEpochMillis,
            uploaderName: _attachment.header.createdBy.caption,
            uploaderId: _attachment.header.createdBy.id,
            uploaderAvatarId: _attachment.header.createdBy.primaryImageId,
            attachmentId: _attachment._id,
            workspaceId: _attachment.workspaceRef.id,
          }));

          const filesByIds: TFetchFilesAttachments = {};
          const attachmentIds = fileAttachments.map((_attachment) => _attachment.attachmentId);

          fileAttachments.forEach((_file) => {
            filesByIds[_file.attachmentId] = _file;
          });

          this.store.dispatch(
            new SetFiles({
              attachmentIds,
              attachments: filesByIds,
            }),
          );
        }),
        catchError(this.responseErrorService.handleRequestError),
      )
      .subscribe();
  }

  deleteFiles(attachmentIds: TDeleteFilesRequest, newPoint: boolean = false): Observable<void> {
    const _id = this.activeService.getActivePointId();

    const filesApiMethod$ = newPoint
      ? this.filesApiProviderService.deleteNewPointFiles(attachmentIds)
      : this.filesApiProviderService.deleteFiles(_id, attachmentIds);

    return filesApiMethod$.pipe(catchError(this.responseErrorService.handleRequestError));
  }

  fetchPointSizes(
    pointIds: string[],
    workspaceId: string,
    targetWorkspaceId: string,
    options: TCopyMoveOptions,
  ): Observable<TPointsSizeResponse> {
    const body: TPointSizesRequest = {
      pointIds: pointIds,
      sourceWorkspaceId: workspaceId,
      targetWorkspaceId: targetWorkspaceId,
      options,
    };

    return this.pointsApiProviderService
      .fetchPointSizes(body)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  renameAttachment(
    attachmentId: string,
    newName: string,
    type: string,
  ): Observable<TAttachmentDTO[]> {
    let urlType: string;

    if (type === 'Video') {
      urlType = 'video';
    } else if (type === 'Image') {
      urlType = 'images';
    } else if (type === 'File') {
      urlType = 'document';
    } else {
      urlType = 'images';
    }

    return this.filesApiProviderService
      .renameAttachment(urlType, attachmentId, newName)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }
}
