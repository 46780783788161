<div class="dashletActivity">
  <ng-container *ngIf="ppChange.addedFiles.length > 0">
    <img class="dashletActivity__icon" [src]="EIconPath.BASIC_FIELD_FILE" />

    <div class="dashletActivity__text">
      {{ 'added' | ppTranslate }}
      <span class="timeline__strong">
        {{ ppChange.newValue.length - ppChange.oldValue.length }}
      </span>
      {{
        ppChange.newValue.length - ppChange.oldValue.length > 1
          ? ('files' | ppTranslate | lowercase)
          : ('file' | ppTranslate | lowercase)
      }}.
    </div>
  </ng-container>

  <ng-container *ngIf="ppChange.removedFiles?.length > 0">
    <img class="dashletActivity__icon" [src]="EIconPath.BASIC_FIELD_FILE" />

    <div class="dashletActivity__text">
      {{ 'removed' | ppTranslate }}
      <span class="timeline__strong">{{ ppChange.removedFiles.length }}</span>
      {{
        ppChange.removedFiles.length > 1
          ? ('files' | ppTranslate | lowercase)
          : ('file' | ppTranslate | lowercase)
      }}.
    </div>
  </ng-container>
</div>
