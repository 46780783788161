<div class="colorPicker">
  <div class="colorPicker__name">{{ displayName }}</div>

  <div class="colorPicker__color-wrapper">
    <input
      class="colorPicker__input"
      type="text"
      [(ngModel)]="ppColor"
      (change)="update()"
      [class.colorPicker__input-error]="error"
    />

    <div
      class="whiteLabelColor__preview"
      [ngStyle]="{ backgroundColor: ppColor, borderColor: ppColor }"
    >
      &nbsp;
    </div>
  </div>
</div>
