<section class="groupDropdown">
  <header>
    <h6>
      {{ 'sort_by' | ppTranslate }}
      <pp-info-icon
        ppClass="setSize14"
        ppTooltip
        [ppOptions]="{ placement: 'right' }"
        [ppMobileTooltip]="true"
        [ppSanitize]="false"
        [ppTitle]="'sort_table_tooltip' | ppTranslate"
      />
    </h6>

    <pp-saved-view-preview-header *ngIf="savedViewsEnabled" [ppHideText]="false" [ppWorkspaceId]="workspace.workspaceId" (ppSaveToCurrentView)="saveSortsToView()" [ppProcessing]="saveViewProcessing"></pp-saved-view-preview-header>
  </header>

  <div class="groupDropdown__table" (scroll)="scrollHandler($event)">
    <div class="groupDropdown__border-wrapper">
      <div
        class="groupDropdown__cell--border"
        *ngIf="draggedColumn !== null"
        [style.marginTop.px]="linePosition"
      ></div>
    </div>

    <div class="groupDropdown__content" #columnTable>
      <h6 class="groupDropdown__content_empty" *ngIf="!sorting.length && !emptySortsVisible.length">
        {{ 'no_sorting_applied' | ppTranslate }}
      </h6>

      <ng-container *ngFor="let column of sorting; index as index">
        <div
          class="groupDropdownItem--grab"
          [class.groupDropdownItem--opacity]="draggedColumn?.name === column.name"
          (dragover)="onDragOver($event, index, column)"
          (drop)="onDrop($event)"
          (dragend)="onDragEnd()"
        >
          <div
            id="{{ 'itemRow-' + column.name }}"
            class="groupDropdown__row"
            draggable="true"
            (dragstart)="onDragStart($event, index, column)"
          >
            <pp-icon
              [ppSrc]="EIconPath.ICON_DRAG_18"
              ppColor="grey-400"
              ppClass="setSize18"
            ></pp-icon>

            <p class="groupDropdown__header">
              {{ (index === 0 ? 'sort_by' : 'then_by') | ppTranslate }}
            </p>

            <pp-group-or-sort-button-column ppType="sort" [ppColumn]="column" (ppUpdate)="updateColumn($event, column)"></pp-group-or-sort-button-column>
            <pp-group-or-sort-button-order [ppColumn]="column" (ppUpdate)="updateOrder($event, column)" [ppIsSorting]="true"></pp-group-or-sort-button-order>

            <pp-icon
              [ppSrc]="EIconPath.ICON_CLOSE_18"
              class="groupDropdown__close-icon setSize18"
              ppColor="grey-400"
              (click)="deleteSort(column)"
            ></pp-icon>
          </div>
        </div>
      </ng-container>

      <pp-new-group-or-sort-column
        *ngFor="let _ of emptySortsVisible"
        [ppOrder]="sorting.length + 1"
        (ppAddGroup)="updateEmptySortColumn($event)"
        (ppCancel)="deleteEmptySort()"
        ppType="sort"
      ></pp-new-group-or-sort-column>
    </div>
  </div>

  <hr>

  <footer>
    <pp-add-group-or-sort-button
    [ppText]="'add_sorting_condition' | ppTranslate"

      (ppAction)="addEmptySort()"
    ></pp-add-group-or-sort-button>

    <div class="groupDropdown__buttons">
      <pp-button
        [ppTooltipTitle]="'reset_sorting' | ppTranslate"
        ppStyle="text"
        ppId="resetSortingBtn"
        (ppAction)="clearAll()"
      >
        {{ 'clear_all' | ppTranslate }}
      </pp-button>

      <pp-button
        ppStyle="primary"
        ppId="closeGroupingBtn"
        (ppAction)="hideDropdown()"
      >
        {{ 'done' | ppTranslate }}
      </pp-button>
    </div>
  </footer>
</section>

