import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';
import { cloneDeep } from 'lodash';

import { Injectable, OnDestroy } from '@angular/core';

import { Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map, shareReplay, takeUntil, tap } from 'rxjs/operators';

import { PermissionsService } from 'src/app/project/modules/share/permissions.service';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { ReactionsService } from 'src/app/project/services/reactions.service';
import { ActiveService } from '../../../../services/active/active.service';
import { ResponseErrorService } from '../../../errors/response-error.service';
import { OfflineService } from '../../../offline/offline.service';

import { DeviceService } from '@core/services';
import { TAllUsers } from '@project/view-models';
import { ActivitiesApiProviderService } from 'src/app/project/data-providers/api-providers/activities-api-provider/activities-api-provider.service';
import { checkActivityUser } from 'src/app/project/modules/activities/activities-merge';
import { TActivityResponse } from 'src/app/project/view-models/activity-response.model';
import { ActivityFormatterService } from './activity-formatter.service';
import { PointActivityCommentsService } from './point-activity-comments.service';
import { PointActivityMergeService } from './point-activity-merge.service';

dayjs.extend(relativeTime);
dayjs.extend(isBetween);

export type TActivity = TActivityResponse;
export type TPagination = {
  totalPages?: number;
  firstResult?: number;
  lastResult?: number;
  pageNumber?: number;
  pageSize?: number;
  totalResults?: number;
};

export type TActivityData = {
  data: TActivity[];
  pagination: TPagination;
  pageNumber: number;
  fetching: boolean;
  fetched: boolean;
};

export type TCommentsData = {
  data: TActivity[];
  workspaceId: string;
  _id: string;
  firstFetch: boolean;
  fetching: boolean;
};

type TFetchPointActivitiesRawParams = {
  pageSize: number;
  pageNumber: number;
  getComments?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class PointActivityService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private clear$ = new Subject<void>();
  private activity$: Observable<TActivityData>;

  private readonly activity: TActivityData;
  private pointTimelineHeadingElement: HTMLElement;
  private pageSize = 20;

  private readonly activityDefaults: TActivityData = {
    data: [],
    pagination: {},
    pageNumber: 1,
    fetching: false,
    fetched: false,
  };

  constructor(
    private responseErrorService: ResponseErrorService,
    private offlineService: OfflineService,
    private activeService: ActiveService,
    private permissionsService: PermissionsService,
    private reactionsService: ReactionsService,
    private usersService: UsersService,
    private activitiesApiProviderService: ActivitiesApiProviderService,
    private pointActivityCommentsService: PointActivityCommentsService,
    private activityFormatterService: ActivityFormatterService,
    private pointActivityMergeService: PointActivityMergeService,
    private deviceService: DeviceService,
  ) {
    this.activity = this.activityDefaults;

    this.clear$
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.resetActivityToDefaults();
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  getActivity(): TActivityData {
    return this.activity;
  }

  clearActivity(): void {
    this.clear$.next();
  }

  refreshTimeline(
    workspaceId: string,
    _id: string,
    {
      refreshComments = false,
    }: {
      refreshComments?: boolean;
    } = {},
  ): void {
    const offline = this.offlineService.getOffline();
    const activePointId = this.activeService.getActivePointId();

    if (offline || _id !== activePointId) {
      return;
    }

    const timelinePermissions = this.permissionsService.areActivitiesEnabled(workspaceId);
    const tagPermissions = this.permissionsService.getTagPermissions(workspaceId);
    const commentPermissions = this.permissionsService.getCommentPermissions(workspaceId);
    const hasAccessToTimeline = timelinePermissions && tagPermissions.read;

    if (!hasAccessToTimeline && !commentPermissions.read) {
      return;
    }

    if (!hasAccessToTimeline) {
      if (refreshComments) {
        this.reactionsService.fetchReactions(_id).subscribe(() => {
          this.pointActivityCommentsService
            .fetchComments(_id, true)
            .pipe(takeUntil(this.destroy$))
            .subscribe();
        });
      }

      return;
    }

    this.reactionsService.fetchReactions(_id).subscribe(() => {
      this.fetchActivity(workspaceId, _id).pipe(takeUntil(this.destroy$)).subscribe();

      if (refreshComments) {
        this.pointActivityCommentsService
          .fetchComments(_id, true)
          .pipe(takeUntil(this.destroy$))
          .subscribe();
      }
    });
  }

  fetchActivity(
    workspaceId: string,
    _id: string,
    {
      pageNumber = 1,
    }: {
      pageNumber?: number;
    } = {},
  ): Observable<TActivityData> {
    // FIXME Do we need to 'Return same active request' feature here?
    if (this.activity.fetching) {
      return this.activity$;
    }

    const offline = this.offlineService.getOffline();
    if (offline) {
      return of(null);
    }

    this.activity.fetching = true;

    const activityParams: TFetchPointActivitiesRawParams = {
      pageSize: this.pageSize,
      pageNumber,
    };

    const commentPermissions = this.permissionsService.getCommentPermissions(workspaceId);
    const blockedComments = !commentPermissions.read;

    if (blockedComments) {
      activityParams.getComments = false;
    }

    const params = Object.entries(activityParams).reduce(
      (urlParams, [key, value]) =>
        `${urlParams}&${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      '',
    );

    this.activity$ = this.activitiesApiProviderService.fetchPointActivities(_id, params).pipe(
      takeUntil(this.clear$),
      shareReplay(),
      map((response) => {
        this.activity.pageNumber = pageNumber;
        this.activity.fetched = true;

        if (response === null) {
          this.activity.pagination = {};
          this.activity.data = [];

          return;
        }

        this.activity.pagination = response.pagination;
        this.activity.data = this.getParsedActivities(response.entity, pageNumber);

        return this.activity;
      }),
      catchError(this.responseErrorService.handleRequestError),
      finalize(() => {
        this.activity.fetching = false;
      }),
    );

    return this.activity$;
  }

  private getParsedActivities(
    responseActivities: TActivityResponse[],
    pageNumber: number,
  ): TActivity[] {
    const newActivities = cloneDeep(responseActivities);
    const allUsers: TAllUsers = this.usersService.getUsers();
    let activities: TActivityResponse[];

    if (pageNumber > 1) {
      activities = [...this.activity.data, ...newActivities];
    } else {
      activities = [...newActivities];
    }

    activities = this.pointActivityMergeService.mergeActivities(activities);

    activities.forEach((activity) => {
      checkActivityUser(activity, allUsers);
    });

    activities =
      this.activityFormatterService.getFormattedDatetimeActivities<TActivity>(activities);

    return activities;
  }

  deleteCommentActivity(commentId: string): void {
    const commentToDeleteIndexActivity = this.activity.data.findIndex(
      (activity) => activity.data?.childRef?.id === commentId,
    );

    if (commentToDeleteIndexActivity > -1) {
      this.activity.data.splice(commentToDeleteIndexActivity, 1);
    }
  }

  getPointTimelineHeading(): HTMLElement {
    return this.pointTimelineHeadingElement;
  }

  setPointTimelineHeading(element: HTMLElement): void {
    this.pointTimelineHeadingElement = element;
  }

  getTimelineHeadingTop(): number | null {
    if (this.pointTimelineHeadingElement) {
      const rect = this.pointTimelineHeadingElement.getBoundingClientRect();

      return rect.top;
    }

    return null;
  }

  scrollToTimelineHeading(options: ScrollIntoViewOptions): void {
    // Firefox has a bug with scrollIntoView, scrolls the entire main page and leaves it weirdly scrolled
    if (!this.deviceService.isBrowserFirefox()) {
      this.pointTimelineHeadingElement.scrollIntoView(options);
    }
  }

  private resetActivityToDefaults(): void {
    this.activity.data = [];
    this.activity.pagination = {};
    this.activity.pageNumber = this.activityDefaults.pageNumber;
    this.activity.fetching = this.activityDefaults.fetching;
    this.activity.fetched = this.activityDefaults.fetched;
  }
}
