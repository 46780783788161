import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { SavedViewsProviderService } from 'src/app/project/data-providers/api-providers/saved-views-provider/saved-views-provider.service';
import { ResponseErrorService } from '../../errors/response-error.service';
import { ESavedView } from '../models/saved-view.enum';
import { SavedViewsService } from '../saved-views.service';
import { TSavedViews } from '../models/saved-views.model';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';

@Injectable({
  providedIn: 'root',
})
export class SavedViewsDropdownService {
  constructor(
    private savedViewsProviderService: SavedViewsProviderService,
    private responseErrorService: ResponseErrorService,
    private savedViewsService: SavedViewsService,
    private dropdownService: DropdownService,
  ) {}

  setPersonalDefaultView(workspaceId: string, viewId: string): Observable<TSavedViews> {
    return this.savedViewsProviderService
      .setDefaultView(workspaceId, viewId, ESavedView.PERSONAL)
      .pipe(
        tap(() => {
          this.savedViewsService.updateDefaultPersonalView(viewId, workspaceId);
        }),
        catchError(this.responseErrorService.handleRequestError),
      );
  }

  setSharedDefaultView(workspaceId: string, viewId: string): Observable<TSavedViews> {
    return this.savedViewsProviderService
      .setDefaultView(workspaceId, viewId, ESavedView.SHARED)
      .pipe(
        tap(() => {
          this.savedViewsService.updateDefaultSharedView(viewId, workspaceId);
        }),
        catchError(this.responseErrorService.handleRequestError),
      );
  }

  duplicateView(viewId: string, workspaceId: string, type: ESavedView): void {
    this.savedViewsProviderService
      .getView(workspaceId, viewId)
      .pipe(
        map((view) => {
          const viewToDuplicate = view;
          viewToDuplicate.viewType = type;
          delete viewToDuplicate.id;

          viewToDuplicate.name = this.createViewName(viewToDuplicate.name, workspaceId, type);

          return this.savedViewsService
            .createView(viewToDuplicate, workspaceId, true)
            .pipe(catchError(this.responseErrorService.handleRequestError))
            .subscribe();
        }),
        catchError(this.responseErrorService.handleRequestError),
      )
      .subscribe();
  }

  createViewName(name: string, workspaceId: string, type: ESavedView): string {
    let newViewName = name;
    const allViews = this.savedViewsService.getSavedViews(workspaceId);
    const viewsForType = type === ESavedView.PERSONAL ? allViews.personal : allViews.shared;
    if (name.includes('copy')) {
      newViewName = this.removeCopySubstring(name);
    }

    const viewNameCopies = viewsForType.views.filter((view) =>
      view.name.includes(newViewName),
    ).length;
    if (viewNameCopies > 0) {
      newViewName = `${newViewName} (copy ${viewNameCopies})`;
    }
    return newViewName;
  }

  removeCopySubstring(inputString: string): string {
    return inputString.replace(/\s*\(copy\s*\d*\)\s*/g, '').trim();
  }

  getDropdownService(): DropdownService {
    return this.dropdownService;
  }
}
