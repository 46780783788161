import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { WindowService, TWindowDimensions } from './window.service';
import { isEqual } from 'lodash';
import { filter } from 'rxjs/operators';

type TScreenState = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isLandscape: boolean;
};

type TScreenObservableName = 'isMobile' | 'isTablet' | 'isDesktop' | 'isLandscape';

@Injectable({
  providedIn: 'root',
})
export class ScreenService {
  /*
    Screen size modes
   */
  private readonly mobileMaxWidthPx = 901;
  private readonly tabletMaxWidthPx = 1124;

  private isMobile = this.getIsMobile(this.windowService.getWindowDimensions().innerWidth);
  private isTablet = this.getIsTablet(this.windowService.getWindowDimensions().innerWidth);
  private isDesktop = !this.isMobile && !this.isTablet;

  private readonly _isMobile$ = new BehaviorSubject<boolean>(this.isMobile);
  readonly isMobile$ = this._isMobile$.asObservable();

  private readonly _isTablet$ = new BehaviorSubject<boolean>(this.isTablet);
  readonly isTablet$ = this._isTablet$.asObservable();

  private readonly _isDesktop$ = new BehaviorSubject<boolean>(this.isDesktop);
  readonly isDesktop$ = this._isDesktop$.asObservable();

  /*
    Screen orientation
   */
  private readonly landscapeMaxHeightPx = 500;

  private isLandscape = this.getIsLandscape(this.windowService.getWindowDimensions());

  private readonly _isLandscape$ = new BehaviorSubject<boolean>(this.isLandscape);
  readonly isLandscape$ = this._isLandscape$.asObservable();

  /*
    Screen full state
  */
  private prevState: TScreenState;

  private readonly _screenState$ = new BehaviorSubject<TScreenState>(this.getCurrentState());
  readonly screenState$ = this._screenState$.asObservable();

  constructor(private windowService: WindowService) {
    this.windowService.resize$.subscribe((dimensions) => {
      this.prevState = this.getCurrentState();

      this.updateIsMobile(dimensions.innerWidth);
      this.updateIsTablet(dimensions.innerWidth);
      this.updateIsDesktop();
      this.updateIsLandscape(dimensions);

      const newState = this.getCurrentState();

      if (!isEqual(this.prevState, newState)) {
        this._screenState$.next(newState);
      }
    });
  }

  observeOn$(triggers: TScreenObservableName[]): Observable<TScreenState> {
    let initialEvent = true;
    return this.screenState$.pipe(
      filter((newState) => {
        if (initialEvent) {
          // Behavior Subject imitation
          initialEvent = false;
          return true;
        }
        for (const trigger of triggers) {
          if (this.prevState[trigger] !== newState[trigger]) {
            return true;
          }
        }
        return false;
      }),
    );
  }

  getCurrentState(): TScreenState {
    return {
      isMobile: this.isMobile,
      isTablet: this.isTablet,
      isDesktop: this.isDesktop,
      isLandscape: this.isLandscape,
    };
  }

  getDimensions(): TWindowDimensions {
    return this.windowService.getWindowDimensions();
  }

  private updateIsMobile(innerWidth: number): void {
    const isMobile = this.getIsMobile(innerWidth);

    if (isMobile !== this.isMobile) {
      this.isMobile = isMobile;
      this._isMobile$.next(isMobile);
    }
  }

  private updateIsTablet(innerWidth: number): void {
    const isTablet = this.getIsTablet(innerWidth);

    if (isTablet !== this.isTablet) {
      this.isTablet = isTablet;
      this._isTablet$.next(isTablet);
    }
  }

  private updateIsDesktop(): void {
    const isDesktop = this.getIsDesktop();

    if (isDesktop !== this.isDesktop) {
      this.isDesktop = isDesktop;
      this._isDesktop$.next(isDesktop);
    }
  }

  private updateIsLandscape(dimensions: TWindowDimensions): void {
    const computedIsLandscape = this.getIsLandscape(dimensions);

    if (computedIsLandscape !== this.isLandscape) {
      this.isLandscape = computedIsLandscape;
      this._isLandscape$.next(computedIsLandscape);
    }
  }

  private getIsMobile(innerWidth: number): boolean {
    return innerWidth < this.mobileMaxWidthPx;
  }

  private getIsTablet(innerWidth: number): boolean {
    return !this.isMobile && innerWidth <= this.tabletMaxWidthPx;
  }

  private getIsDesktop(): boolean {
    return !this.isMobile && !this.isTablet;
  }

  private getIsLandscape({ innerWidth, innerHeight }: TWindowDimensions): boolean {
    const orientation = this.windowService.orientation;

    return (
      (orientation === 90 || orientation === -90) &&
      innerWidth > innerHeight &&
      innerHeight < this.landscapeMaxHeightPx
    );
  }
}
