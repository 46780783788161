<div
  #commentBox
  data-m-target="Comments Box"
  (ppOnRendered)="onCommentBoxRendered()"
  [class.richTextComponent__container--failed]="characterLimit - textLength < 0"
  class="comment-form"
  *ngIf="user"
  data-test="commentBox"
>
  <div class="pointViewComments__input" *ngIf="user">
    <img
      [src]="EIconPath.PLACEHOLDER_USER"
      class="pointViewComments__avatar pointViewComments__avatar--placeholder"
      draggable="false"
      *ngIf="!user.avatarId"
    />

    <img
      ppImage
      [ppSecureSrc]="'api/v1/images/' + user.avatarId + '/file/size/square/40'"
      class="pointViewComments__avatar"
      draggable="false"
      *ngIf="user.avatarId"
    />

    <div class="commentBox__container">
      <form name="newCommentform">
        <div class="commentBox">
          <div class="commentInput" #comment></div>

          <div id="commentToolbar">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
            <button class="ql-strike"></button>
            <button class="ql-link"></button>
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button (click)="showMentionsDropdown()">&#64;</button>

            <div
              class="richTextComponent__characterLimit"
              [class.richTextComponent__characterLimit--green]="characterLimit - textLength >= 0"
              [class.richTextComponent__characterLimit--red]="characterLimit - textLength < 0"
              *ngIf="characterLimit - textLength < 500"
            >
              {{ textLength }} / {{ characterLimit }}
            </div>
          </div>

          <div
            class="description__actions telegramSendButton"
            *ngIf="isEditingQuill.editing && isEditingQuill.fieldId === id"
          >
            <div class="pointViewComments__enterSend hide--mobile">
              <div class="pointViewComments__checkbox-container" *ngIf="preferences">
                <pp-checkbox
                  [ppChecked]="preferences.commentOnEnter"
                  ppType="solid"
                  (ppOnValueChange)="saveSendPreferences()"
                ></pp-checkbox>
                <label>{{ 'press_enter_to_send' | ppTranslate }}</label>
              </div>
            </div>

            <pp-button
              ppStyle="primary"
              ppId="saveCommentBtn"
              (ppAction)="saveComment()"
              data-test="sendCommentButton"
              [ppProcess]="processing"
            >
              <span class="pointViewComments__comment-text"> {{ 'comment' | ppTranslate }} </span>
            </pp-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
