import { createElement } from 'src/app/core/helpers/dom';
import Tooltip from 'src/app/project/features/tooltip/Tooltip';
import { GET_TIMELINE } from '../timeline.ui.store';

export function createTimelineHeadCellInnerElement(
  _child: string,
  _column: string,
  _bottom: boolean = false,
  _secondaryName: string = null,
): HTMLElement {
  const children = getChildren(_bottom, _child, _secondaryName);

  const cellNameElement = createElement('div', {
    attrs: {
      class: 'timeline__head__cell--inner',
    },
    children: children,
  });

  const cellInnerElement = createElement('div', {
    attrs: {
      class: 'table__cell__inner timeline__head__cell',
    },
    children: [cellNameElement],
  });

  addTooltip(cellNameElement, _child);

  return cellInnerElement;
}

function getChildren(_bottom: boolean, _child: string, _secondaryName: string): HTMLElement[] {
  const children = [
    createElement('span', {
      attrs: {
        class: _bottom ? 'timeline__head__bottomElement' : 'timeline__head__topElement',
      },
      children: [_child],
    }),
  ];

  if (_secondaryName) {
    children.push(
      createElement('span', {
        attrs: {
          class: 'timeline__head__topElement--secondary',
        },
        children: [_secondaryName],
      }),
    );
  }

  return children;
}

function addTooltip(cellNameElement: HTMLElement, _child: string): void {
  const timeline = GET_TIMELINE();

  const tooltipElement = cellNameElement;

  const nameTooltip = new Tooltip({
    title: _child,
    mobileTooltip: false,
    childTooltip: false,
    element: tooltipElement,
    trimTooltip: true,
    trimElement: tooltipElement,
  });

  timeline.timelineHead?.addTooltip(nameTooltip);

  nameTooltip.createTooltip();
}
