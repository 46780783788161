import { createElement } from 'src/app/core/helpers/dom';
import { DELETE_COLLAPSED_GROUP } from '../../../site-table/table.ui.store';
import { GET_TIMELINE } from '../../timeline.ui.store';
import { createTimelineCellElement } from '../body-cells/create-timeline-cell-element';
import { TTimelineItem } from '../../timeline.model';
import { EIconPath } from '../../../../../shared/enums/icons.enum';

export function createExpandElement(_item: TTimelineItem): HTMLElement {
  return createTimelineCellElement({
    className: 'timeline__arrow table__arrow--expand',
    width: '4rem',
    center: true,
    callback: () => {
      const timeline = GET_TIMELINE();

      DELETE_COLLAPSED_GROUP(_item.id);

      timeline.sortTimeline();
    },
    child: createElement('img', {
      attrs: {
        class: '_groupTimeline__field__order__asc _groupTimeline__field__order__asc--vertical',
        src: EIconPath.ARROW_RIGHT_GRAY,
      },
    }),
  });
}
