import { Injectable } from '@angular/core';

import { DashboardApiProviderService } from '@core/api';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ResponseErrorService } from '../../../errors/response-error.service';
import { EDashletPeriod } from '../../dashboard-timeframes-enums';
import { TDashletEventResponse, TDashletRequestData } from '../../dashboard.consts';
import { EDashletType } from '../../dashlets-enums';
import { TDashletEventsRequestData } from './dashlet-events.consts';

export type TDashletEventsParams = {
  type?: string;
  period?: string;
  accountId?: string;
  workspaceIds?: string[];
};

export type TDashletData = TDashletRequestData & {
  data: number;
};
@Injectable({
  providedIn: 'root',
})
export class DashletEventsService {
  private requestsQueue: TDashletEventsRequestData[] = [];

  constructor(
    private responseErrorService: ResponseErrorService,
    private dashboardApiProviderService: DashboardApiProviderService,
  ) {}

  fetchEvents({
    type = '',
    period = null,
    accountId = null,
    workspaceIds = [],
  }: TDashletEventsParams): Observable<TDashletEventResponse> {
    let request: Observable<TDashletEventResponse>;

    const existingRequest = this.findExistingRequest(type, period, workspaceIds, accountId);

    if (existingRequest) {
      request = existingRequest.request;
    } else {
      const body: TDashletRequestData = {
        type: type as EDashletType,
        period: period as EDashletPeriod,
        ...(accountId ? { accountId } : {}),
        ...(workspaceIds.length ? { workspaceIds } : {}),
      };

      request = this.dashboardApiProviderService.fetchEvents(body);

      this.requestsQueue.push({
        request,
        type: type as EDashletType,
        period: period as EDashletPeriod,
        accountId,
        workspaceIds,
      });
    }

    return request.pipe(
      tap((response) => {
        const existingRequest = this.findExistingRequest(type, period, workspaceIds, accountId);

        existingRequest.data = response.numberOfEvents;
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  clearDashletData(): void {
    this.requestsQueue = [];
  }

  getRequestQueue(): TDashletEventsRequestData[] {
    return this.requestsQueue;
  }

  private findExistingRequest(
    type: string,
    period: string,
    workspaceIds: string[],
    accountId: string,
  ): TDashletEventsRequestData {
    return this.requestsQueue.find(
      (_dashlet) =>
        _dashlet.type === type &&
        _dashlet.period === period &&
        _dashlet.workspaceIds.length === workspaceIds.length &&
        _dashlet.workspaceIds.every((workspaceId) => workspaceIds.includes(workspaceId)) &&
        (_dashlet.accountId === accountId || (!_dashlet.accountId && !accountId)),
    );
  }
}
