import { Injectable } from '@angular/core';
import { PlanPointVariablesService } from './plan-point-variables.service';

@Injectable({
  providedIn: 'root',
})
export class PlanPointPointerMoveEndService {
  constructor(private planPointVariablesService: PlanPointVariablesService) {}

  moveendEventOn(): void {
    const plan = this.planPointVariablesService.getPlan();
    const moveendListenerRef = this.planPointVariablesService.getMoveendListenerRef();

    plan.instance.point.on('moveend', moveendListenerRef);
  }

  moveendEventOff(): void {
    const plan = this.planPointVariablesService.getPlan();
    const moveendListenerRef = this.planPointVariablesService.getMoveendListenerRef();

    plan.instance.point.un('moveend', moveendListenerRef);
  }

  moveendListener(): void {
    const plan = this.planPointVariablesService.getPlan();

    if (plan.instance.point) {
      if (plan.zoom !== plan.instance.point.getView().getZoom()) {
        plan.zoom = plan.instance.point.getView().getZoom();
      }

      if (
        plan.center[0] !== plan.instance.point.getView().getCenter()[0] ||
        plan.center[1] !== plan.instance.point.getView().getCenter()[1]
      ) {
        plan.center = plan.instance.point.getView().getCenter();
      }
    }
  }
}
