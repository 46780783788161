import { TAccount } from '../../../account/account.model';
import { TFilters } from '../../../filters/site-filter.model';
import { TWorkspacesById } from '../../../workspace/workspace.model';
import { addWorkspaceToSiteFilter } from './check-new-workspace-for-site-filters';

export function generateSiteWorkspaceFilters(
  account: TAccount,
  filters: TFilters,
  workspaces: TWorkspacesById,
): void {
  const index = filters.sites.findIndex((_site) => _site.accountName === account.accountName);

  addWorkspaceToSiteFilter(account, filters, index, workspaces);

  filters.sites[index].workspaces = filters.sites[index].workspaces.filter((_workspace) =>
    account.workspaces.includes(_workspace.id),
  );
}
