import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { TPasswordInputVisibilityType } from '../auth.types';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-password-visibility',
  templateUrl: './password-visibility.component.html',
  styleUrls: ['./password-visibility.component.scss'],
})
export class PasswordVisibilityComponent implements OnInit {
  @Output() ppVisibilityChange = new EventEmitter<TPasswordInputVisibilityType>();

  passInputType: TPasswordInputVisibilityType = 'password';
  EIconPath = EIconPath;

  ngOnInit(): void {
    this.emitCurrentValue();
  }

  togglePasswordVisibility(): void {
    this.passInputType = this.passInputType === 'password' ? 'text' : 'password';
    this.emitCurrentValue();
  }

  private emitCurrentValue(): void {
    this.ppVisibilityChange.emit(this.passInputType);
  }
}
