<div class="pointCF__field">
  <label
    ppTooltip
    class="pointCF__label"
    ppTitle="{{ ppCustomField?.label }}"
    [ppTrimTooltip]="true"
  >
    <img class="pointCF__bulk-changes-field-icon" [src]="EIconPath.CUSTOM_FIELD_COST_ACTIVITY" />

    <span class="pointCF__label-container">
      {{ ppCustomField?.label }}
    </span>
  </label>

  <pp-custom-field-cost
    [ppWorkspaceId]="ppCustomField.workspaceId"
    [ppFieldId]="ppCustomField.id"
    [ppFieldLabel]="ppCustomField.label"
    [ppFieldCurrencySymbol]="ppCustomField.currencySymbol"
    [ppFieldCurrencyCode]="ppCustomField.currencyCode"
    [ppFieldValue]="'' + ppCustomFields[ppFieldId]"
    [ppNew]="false"
    [ppCanEdit]="true"
    (ppUpdate)="updateCost($event, ppCustomField)"
  ></pp-custom-field-cost>
</div>
