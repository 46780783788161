import { GridsterConfig } from 'angular-gridster2';

export type TGridsterItemCallbacks = {
  itemChangeCallback: () => void;
  itemResizeCallback: () => void;
};

const defaultConfig: GridsterConfig = {
  gridType: 'scrollVertical',
  minCols: 30,
  maxCols: 30,
  minRows: 20,
  maxRows: 100,
  minItemRows: 6,
  minItemCols: 5,
  displayGrid: 'onDrag&Resize',
  draggable: {
    enabled: false,
  },
  resizable: {
    enabled: false,
  },
};

export function getGridsterConfig({
  itemChangeCallback,
  itemResizeCallback,
}: TGridsterItemCallbacks): GridsterConfig {
  return {
    ...defaultConfig,
    itemChangeCallback,
    itemResizeCallback,
    draggable: {
      enabled: true,
    },
    resizable: {
      enabled: true,
    },
  };
}

export function getGridsterConfigTouch(): GridsterConfig {
  return {
    ...defaultConfig,
  };
}

export function getGridsterConfigOffline({
  itemChangeCallback,
  itemResizeCallback,
}: TGridsterItemCallbacks): GridsterConfig {
  return {
    ...defaultConfig,
    itemChangeCallback,
    itemResizeCallback,
    displayGrid: 'none',
  };
}
