import { isEqual } from 'lodash';
import { ESemiSimplePermission, ESimplePermission } from '../permissions.consts';
import { TGranularPermission, TSemiGranularPermission } from '../share.model';

export function convertGranularPermissionToSimplePermission(
  granularPermission: TGranularPermission,
): ESimplePermission {
  if (isEqual(granularPermission, { read: true, edit: true })) {
    return ESimplePermission.EDIT;
  }

  if (isEqual(granularPermission, { read: true, edit: false })) {
    return ESimplePermission.VIEW;
  }

  if (isEqual(granularPermission, { read: false, edit: false })) {
    return ESimplePermission.NONE;
  }

  throw new Error(`${JSON.stringify(granularPermission)} did not match any pattern.`);
}

export function convertSemiGranularPermissionToSemiSimplePermission(
  semiGranularPermission: TSemiGranularPermission,
): ESemiSimplePermission {
  if (isEqual(semiGranularPermission, { read: true })) {
    return ESemiSimplePermission.VIEW;
  }

  if (isEqual(semiGranularPermission, { read: false })) {
    return ESemiSimplePermission.NONE;
  }

  throw new Error(`${JSON.stringify(semiGranularPermission)} did not match any pattern.`);
}

export function convertSimplePermissionToGranularPermission(
  simplePermission: ESimplePermission,
): TGranularPermission {
  switch (simplePermission) {
    case ESimplePermission.EDIT:
      return { read: true, edit: true };
    case ESimplePermission.VIEW:
      return { read: true, edit: false };
    case ESimplePermission.NONE:
      return { read: false, edit: false };
    default:
      throw new Error(`${JSON.stringify(simplePermission)} did not match any pattern.`);
  }
}

export function convertSemiSimplePermissionToSemiGranularPermission(
  semiSimplePermission: ESemiSimplePermission,
): TSemiGranularPermission {
  switch (semiSimplePermission) {
    case ESemiSimplePermission.ALLOW:
    case ESemiSimplePermission.VIEW:
      return { read: true };
    case ESemiSimplePermission.NONE:
      return { read: false };
    default:
      throw new Error(`${JSON.stringify(semiSimplePermission)} did not match any pattern.`);
  }
}
