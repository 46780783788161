import { TMultiSelectOption } from 'src/app/project/components/multiple-input-select/multiple-input-select.consts';
import { ETwoFactorAuthenticationChannel } from '../two-factor-authentication.consts';

export const twoFactorAuthenticationChannelOptions: TMultiSelectOption[] = [
  {
    labelKey: 'send_via_sms',
    value: ETwoFactorAuthenticationChannel.SMS,
  },
  {
    labelKey: 'send_via_whatsapp',
    value: ETwoFactorAuthenticationChannel.WHATSAPP,
  },
];
