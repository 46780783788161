import { cloneDeep } from 'lodash';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Subject, takeUntil, tap } from 'rxjs';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { TShare } from '../../share.model';

import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { Modal, ModalService } from '../../../../components/modal/modal.service';
import { CustomFieldsService } from '../../../custom-fields/custom-fields.service';
import { UsersService } from '../../../users/users.service';

import { TAllUsers } from '@project/view-models';
import { TSelectOption } from 'src/app/project/components/input/select-tag/select-tag.model';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { EUserRole } from '../../share-utils/user-roles';
import { generateShare, getMissingCustomFieldsAccessList } from '../../share.utils';
import { EAccessParameter } from './access-parameters';
import { SHARE_ROLE_OPTIONS } from './edit-share-options';
import { getWorkspaceCustomFieldsPermissions } from './edit-share-set-all-custom-fields-permissions';
import { setAdminPermissions } from './set-admin-permissions';
import { ShareEditService } from './share-edit.service';

@Component({
  selector: 'pp-share-edit',
  templateUrl: './share-edit.component.html',
  styleUrls: ['./share-edit.component.scss'],
})
export class ShareEditComponent implements OnInit, OnDestroy {
  @Input() ppShare: TShare | Partial<TShare>;
  @Input() ppShowAdvanced: boolean;
  @Input() ppEdit: boolean;
  @Input() ppWorkspaceId: string;
  @Input() ppTagError = false;
  @Input() ppEmails: string[];
  @Output() ppEmailsChange = new EventEmitter<string[]>();
  @Output() ppShareChange = new EventEmitter<TShare>();

  private readonly destroy$ = new Subject<void>();

  EUserRole = EUserRole;
  shareOptions: TSelectOption[] = SHARE_ROLE_OPTIONS().filter(
    (option) => option.value !== EUserRole.ACCOUNT_ADMIN,
  );
  customFields = this.customFieldsService.getCustomFields();
  modal: Modal = this.modalService.getModal();
  workspaceId: string;
  users: TAllUsers;
  workspace: TWorkspace = null;
  share: TShare;
  EIconPath = EIconPath;

  constructor(
    private modalService: ModalService,
    private workspaceService: WorkspaceService,
    private customFieldsService: CustomFieldsService,
    private usersService: UsersService,
    private shareEditService: ShareEditService,
  ) {
    this.shareEditService.shareChange$
      .pipe(
        takeUntil(this.destroy$),
        tap((share: TShare) => {
          this.ppShare = share;
          this.ppShareChange.emit(share);
        }),
      )
      .subscribe();
  }

  ngOnInit() {
    this.users = this.usersService.getUsers();
    this.workspaceId = this.ppWorkspaceId;
    this.workspace = this.workspaceService.getWorkspace(this.workspaceId);

    this.share = generateShare(cloneDeep(this.ppShare), this.workspaceId, this.workspace);

    this.updateShareDefectTags();
    this.updateShareCustomFieldsAccessList(true, true);
    this.share = this.shareEditService.updateShareTimelineAccess(this.share, this.workspaceId);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  onUserTypeSwitched(option: TSelectOption): void {
    this.share.shareOption = option.value;

    if (this.share.shareOption === EUserRole.SITE_ADMIN) {
      this.share = this.shareEditService.clearTags(this.share, this.workspaceId);
      this.share = setAdminPermissions(this.share);
    }

    this.initCustomFieldAccessList();
    this.updateShare();
  }

  updateShare(): void {
    this.share.workspaceId = this.workspaceId;

    if (this.share.shareOption === EUserRole.SITE_ADMIN) {
      this.share.defectTags.length = 0;
    }

    this.shareEditService.emitNewShare(this.share);
  }

  toggleAdvanced(): void {
    this.ppShowAdvanced = true;
  }

  private initCustomFieldAccessList(allFields: boolean = true): void {
    if (this.workspace.customFields) {
      if (this.share.shareOption === EUserRole.LIMITED) {
        if (allFields) {
          this.share.advancedAccessLevels.customFields = getWorkspaceCustomFieldsPermissions({
            workspaceCustomFields: this.workspace.customFields,
            permissionRead: true,
            permissionEdit: false,
          });

          this.share = this.shareEditService.correctTagsAccessLogic(
            EAccessParameter.READ,
            this.share,
            this.workspaceId,
          );
        } else {
          this.share = this.shareEditService.correctTagsAccessLogic(
            EAccessParameter.EDIT,
            this.share,
            this.workspaceId,
          );
          this.updateShareCustomFieldsAccessList(true, false);
        }
      } else if (this.share.shareOption === EUserRole.NORMAL) {
        this.share = this.shareEditService.correctTagsAccessLogic(
          this.share.tagLimited ? EAccessParameter.READ : EAccessParameter.EDIT,
          this.share,
          this.workspaceId,
        );

        if (allFields) {
          this.share.advancedAccessLevels.customFields = getWorkspaceCustomFieldsPermissions({
            workspaceCustomFields: this.workspace.customFields,
            permissionRead: true,
            permissionEdit: true,
          });
        } else {
          this.updateShareCustomFieldsAccessList(true, true);
        }
      }
    }
  }

  private updateShareCustomFieldsAccessList(
    permissionRead: boolean,
    permissionEdit: boolean,
  ): void {
    const missingCustomFieldsAccessList = getMissingCustomFieldsAccessList(
      this.workspace.customFields,
      this.share.advancedAccessLevels.customFields,
      permissionRead,
      permissionEdit,
    );

    this.share.advancedAccessLevels.customFields.push(...missingCustomFieldsAccessList);
  }

  private updateShareDefectTags(): void {
    this.share.defectTags = this.share.defectTags.filter((tag) =>
      this.workspace.tags.includes(tag),
    );
  }

  updateEmails(emails: string[]): void {
    this.ppEmails = emails;
    this.ppEmailsChange.emit(emails);
  }
}
