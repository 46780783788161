import { EUserRole } from '../../share-utils/user-roles';
import { TShare } from '../../share.model';
import { EAccessParameter } from './access-parameters';

export function clearTags(share: TShare): EAccessParameter.EDIT | EAccessParameter.READ {
  let access = EAccessParameter.READ;

  if (share.advancedAccessLevels.tags.permission.read) {
    if (share.shareOption === EUserRole.NORMAL) {
      access = EAccessParameter.EDIT;
    }
  }

  return access;
}
