import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { IconModule } from '../icon/icon-module';
import { ArchivedChipComponent } from './archived-chip.component';

@NgModule({
  declarations: [ArchivedChipComponent],
  imports: [CommonModule, IconModule, PipesModule],
  exports: [ArchivedChipComponent],
})
export class ArchivedChipModule {}
