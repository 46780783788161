import { increaseDays } from './increase-days';

// --------------------------------------------------
// --------------------- HELPERS -----------------------
// --------------------------------------------------

export function buildWeeks(start: Date, end: Date): Date[][] {
  const weeks: Date[][] = [];
  let current = new Date(start);

  while (current < end) {
    const beginOfWeek = new Date(current);
    let endOfWeek = increaseDays(current, 6);

    endOfWeek = endOfWeek > end ? end : endOfWeek;

    weeks.push([beginOfWeek, endOfWeek]);
    current = increaseDays(current, 1);
  }

  return weeks;
}
