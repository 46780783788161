import { naturalSort } from '@core/helpers';
import { TWorkspaceResponse, TWorkspaceUser } from '@project/view-models';
import { cloneDeep } from 'lodash';
import { TAllCustomFields } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { TAccountSummaryResponse } from 'src/app/project/view-models/account-response.model';
import { CustomFieldModelFactory } from '../../custom-fields/utils/custom-field-model-factory';
import { TWorkspace, TWorkspacesById, TWorkspaceSitePlan } from '../workspace.model';
import { generateWorkspaceSitePlan } from './generate-workspace-site-plan';

export function checkWorkspace(
  workspace: TWorkspaceResponse,
  customFieldList: TAllCustomFields,
  usersResponse: TWorkspaceUser[],
  workspaceList: TWorkspacesById,
  workspaces: Partial<TWorkspace>,
  account: TAccountSummaryResponse,
): { newWorkspaceList: TWorkspacesById; newCustomFieldList: TAllCustomFields } {
  const newCustomFieldList = cloneDeep(customFieldList);
  const newWorkspaceList = cloneDeep(workspaceList);

  const customFieldIds = [];
  const userIds = [];

  newCustomFieldList[workspace._id] = {};

  if (usersResponse) {
    usersResponse.forEach((user) => {
      if (user.workspaces.includes(workspace._id)) {
        userIds.push(user.userId);
      }
    });
  }

  if (workspace.customFields) {
    workspace.customFields.forEach((field) => {
      if (!customFieldIds.includes(field.id.toString())) {
        customFieldIds.push(field.id.toString());
      }

      newCustomFieldList[workspace._id][field.id] = CustomFieldModelFactory.createFromDTO(field);
    });
  }

  const sitePlan: TWorkspaceSitePlan = generateWorkspaceSitePlan(workspace);

  newWorkspaceList[workspace._id] = {
    share: workspaces[workspace._id].share,
    accountId: account.id,
    accountName: account.name,
    customFields: customFieldIds,
    siteImageRef: workspace.siteImageRef,
    sitePlan,
    tags: cloneDeep(workspace.tags).sort((a, b) => naturalSort(a, b)),
    siteId: workspace.siteRef.id,
    siteName: workspace.siteName,
    workspaceId: workspace._id,
    integrations: workspace.integrations,
    users: userIds,
  };

  return { newWorkspaceList, newCustomFieldList };
}
