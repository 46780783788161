import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { ButtonModule } from '../../components/button/button.module';
import { DoubleSelectModule } from '../../components/double-select/double-select.module';
import { IconModule } from '../../components/icon/icon-module';
import { SelectTagModule } from '../../components/input/select-tag/select-tag.module';
import { SelectModule } from '../../components/input/select/select.module';
import { UploadModule } from '../../components/input/upload/upload.module';
import { ModalModule } from '../../components/modal/modal.module';
import { SaveIndicatorModule } from '../../components/save-indicator/save-indicator.module';
import { SiteListSelectModule } from '../../components/site-list-select/site-list-select.module';
import { SpinnerModule } from '../../components/spinner/spinner.module';
import { TagModule } from '../../components/tag/tag.module';
import { AutonumericModule } from '../../directives/autonumeric/autonumeric.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SwitchCasesModule } from '../../directives/switch-cases/switch-cases.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { BulkTagsModalComponent } from './bulk-tags-modal/bulk-tags-modal.component';
import { DeleteTagModalComponent } from './delete-tag-modal/delete-tag-modal.component';
import { ImportTagFileComponent } from './import-tag-modal/import-tag-file/import-tag-file.component';
import { ImportTagModalComponent } from './import-tag-modal/import-tag-modal.component';
import { ImportTagSelectComponent } from './import-tag-modal/import-tag-select/import-tag-select.component';
import { TagSelectComponent } from './tag-select/tag-select.component';
import { TagsService } from './tags.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    DoubleSelectModule,
    TagModule,
    UploadModule,
    ModalModule,
    SelectModule,
    SiteListSelectModule,
    PipesModule,
    TooltipsModule,
    AutonumericModule,
    SaveIndicatorModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    SpinnerModule,
    IconModule,
    SelectTagModule,
  ],
  providers: [TagsService],
  exports: [TagSelectComponent],
  declarations: [
    TagSelectComponent,
    DeleteTagModalComponent,
    BulkTagsModalComponent,
    ImportTagModalComponent,
    ImportTagSelectComponent,
    ImportTagFileComponent,
  ],
})
export class TagsModule {}
