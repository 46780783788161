import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TPointResponse } from '../../view-models/point-response.model';
import { PointsApiProviderService } from '@core/api';
import { getItemsFromStore } from '../../helpers/database/database';
import { TStatusFilterRequest } from '../filters/utils/get-statuses-to-filter';
import { isEqual } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class PointsPaginationService {
  private fetchedStatuses: TStatusFilterRequest[] = [];
  private extendedPoints: TPointResponse[] = [];

  constructor(private pointsApiProviderService: PointsApiProviderService) {}

  fetchAllPoints(
    accounts: string[],
    customFields: string[],
    statuses: TStatusFilterRequest[],
    filtering?: boolean,
  ): Observable<TPointResponse[]> {
    if (navigator.onLine) {
      return this.fetchAllPointsPagination({ accounts, customFields, statuses, filtering });
    }

    return from(getItemsFromStore<TPointResponse[]>('main', 'points'));
  }

  private fetchAllPointsPagination({
    accounts,
    customFields,
    pageNumber = '',
    points = [],
    statuses = [],
    filtering = false,
  }: {
    accounts: string[];
    customFields: string[];
    pageNumber?: string;
    points?: TPointResponse[];
    statuses?: TStatusFilterRequest[];
    filtering?: boolean;
  }): Observable<TPointResponse[]> {
    if (isEqual(statuses, this.fetchedStatuses) && this.extendedPoints.length && filtering) {
      return of(this.extendedPoints);
    }

    this.fetchedStatuses = statuses;

    return this.pointsApiProviderService
      .fetchAllPointsPagination({ pageNumber, accounts, customFields, statuses })
      .pipe(
        switchMap((response) => {
          const nextPageAfter = response.pagination.nextPageAfter;
          const extendedPoints = [...points, ...response.points];

          if (nextPageAfter) {
            return this.fetchAllPointsPagination({
              accounts,
              customFields,
              pageNumber: nextPageAfter,
              points: extendedPoints,
              statuses,
            });
          }

          this.extendedPoints = extendedPoints;

          return of(extendedPoints);
        }),
      );
  }

  fetchPoints(workspaceId: string): Observable<TPointResponse[]> {
    return this.fetchPointsPagination(workspaceId);
  }

  private fetchPointsPagination(
    workspaceId: string,
    points: TPointResponse[] = [],
    pageNumber: string = '',
  ): Observable<TPointResponse[]> {
    return this.pointsApiProviderService.fetchPoints(workspaceId, pageNumber).pipe(
      switchMap((response) => {
        const nextPageAfter = response.pagination.nextPageAfter;
        const extendedPoints = [...points, ...response.points];

        if (nextPageAfter) {
          return this.fetchPointsPagination(workspaceId, extendedPoints, nextPageAfter);
        }

        this.extendedPoints = extendedPoints;

        return of(extendedPoints);
      }),
    );
  }
}
