import { createElement } from '@core/helpers';

export function generateSingleGroupingEndElements(collapsed: boolean): HTMLElement[] {
  const childrenList: HTMLElement[] = [];
  let classList = 'table__cell__extra';

  if (collapsed) {
    classList += ' table__cell__extra--collapsed';
  }

  childrenList.push(
    createElement('div', {
      attrs: {
        class: classList,
      },
    }),
  );

  return childrenList;
}
