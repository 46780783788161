import { EDashletPeriod } from '../../dashboard-timeframes-enums';
import { TTranslationKey } from '../../../../features/translate/types';

export type TTimePeriods = {
  name: TTranslationKey;
  value: EDashletPeriod;
};

export const overTimePeriods: TTimePeriods[] = [
  {
    name: 'days',
    value: EDashletPeriod.DAY,
  },
  {
    name: 'weeks',
    value: EDashletPeriod.WEEK,
  },
  {
    name: 'months',
    value: EDashletPeriod.MONTH,
  },
  {
    name: 'year',
    value: EDashletPeriod.YEAR,
  },
];

export const timePeriods: TTimePeriods[] = [
  {
    name: 'today',
    value: EDashletPeriod.DAY,
  },
  {
    name: 'this_week',
    value: EDashletPeriod.WEEK,
  },
  {
    name: 'last_7_days',
    value: EDashletPeriod.LAST_WEEK,
  },
  {
    name: 'this_month',
    value: EDashletPeriod.MONTH,
  },
  {
    name: 'last_30_days',
    value: EDashletPeriod.LAST_MONTH,
  },
  {
    name: 'this_year',
    value: EDashletPeriod.YEAR,
  },
  {
    name: 'last_365_days',
    value: EDashletPeriod.LAST_YEAR,
  },
];
