<pp-settings-container>
  <h4 class="settingsNewSite__header">
    {{ account?.accountName }}
  </h4>

  <div class="settingsNewSite settings-page user-settings content-panel--new">
    <div class="details">
      <div id="siteNameForm">
        <div class="settingsNewSite__section">
          <h5 class="settingsNewSite__section-header">{{ 'add_site_name' | ppTranslate }}</h5>

          <div
            class="inputWrapper"
            [class.inputWrapper--error]="inputDirty && !site.name"
            [class.inputWrapper--focus]="focused"
          >
            <input
              name="siteName"
              class="settingsNewSite__input--text"
              placeholder="Enter site name..."
              type="text"
              [(ngModel)]="site.name"
              (focusout)="onInputFocusLost()"
              (focus)="onInputFocus()"
              (keydown.enter)="blurInput($event)"
              ppFocus
            />
          </div>

          <div class="inputError" *ngIf="inputDirty && !site.name && !focused">
            {{ 'this_field_is_mandatory' | ppTranslate }}
          </div>
        </div>

        <div class="settingsNewSite__section settingsNewSite__sectionOptions">
          <pp-checkbox
            class="settingsNewSite__noSiteCheckbox"
            [ppChecked]="noSitePlan"
            (ppOnValueChange)="toggleNoSitePlan()"
          >
            <h5 class="settingsNewSite__section-header--noSitePlan">
              {{ 'no_site_plan' | ppTranslate }}
            </h5>
          </pp-checkbox>

          <pp-search-box
            class="settingsNewSite__searchBox"
            ppPlaceholder="Search Sites"
            (ppAction)="updateSearchBox($event)"
          ></pp-search-box>
        </div>

        <div
          class="settingsNewSite__section settingsNewSite__section--sites"
          [class.settingsNewSite__section--disabled]="noSitePlan"
        >
          <div class="settingsNewSite__sitePanel">
            <div class="siteList__wrapper">
              <cdk-virtual-scroll-viewport itemSize="1" class="siteList" #list>
                <ng-container *cdkVirtualFor="let workspaceId of sitesToDisplay">
                  <div
                    class="siteList__list"
                    *ngIf="
                      workspaces[workspaceId]?.siteImageRef &&
                      ((selectedFilters && selectedFilters[workspaceId]) || !selectedFilters)
                    "
                  >
                    <div class="siteTile" (click)="selectWorkspace(workspaceId)">
                      <img
                        ppTooltip
                        ppImage
                        [ppTitle]="workspaces[workspaceId]?.siteName"
                        class="settingsNewSite__siteTile"
                        [class.settingsNewSite__siteTile--active]="
                          workspaceId === selectedWorkspaceId
                        "
                        [ppSecureSrc]="
                          'api/v1/images/' +
                          workspaces[workspaceId]?.siteImageRef.id +
                          '/file/size/bounded/400'
                        "
                        [ppOptions]="{
                          appendTo: 'parent',
                          popperOptions: {}
                        }"
                      />
                    </div>
                  </div>
                </ng-container>
              </cdk-virtual-scroll-viewport>
            </div>

            <div class="newSite__sitePlan">
              <h3
                class="newSite__sitePlan--none"
                *ngIf="!selectedWorkspaceId && !sitePlan && !uploadedPdf"
              >
                <div class="newSite__sitePlan--none--text">
                  {{ 'no_site_plan_selected' | ppTranslate }}
                </div>
              </h3>

              <pp-plan
                *ngIf="selectedWorkspaceId && !sitePlan"
                [ppModule]="EPlanModule.SITE"
                [ppWorkspaceId]="selectedWorkspaceId"
                [ppNoPins]="true"
              ></pp-plan>

              <img ppImage class="newSitePlanImage" *ngIf="uploadedImage" [src]="uploadedImage" />

              <div class="newSite__sitePlan--none" *ngIf="uploadedPdf">
                <div class="newSite__sitePlan--none--text">
                  {{ 'pdf_preview_not_available' | ppTranslate }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="settingsNewSite__footer">
          <div class="additionalOptions">
            <pp-new-site-plan-button
              [ppSitePlan]="sitePlan"
              [ppNoSitePlan]="noSitePlan"
              [ppDisabled]="noSitePlan"
              (ppUploadSitePlan)="uploadSitePlan($event)"
              *ngIf="!limitedUser"
            ></pp-new-site-plan-button>

            <pp-site-plan-format
              [ppDisabled]="noSitePlan"
              *ngIf="!limitedUser"
              (ppSelectFormat)="selectFormat($event)"
            ></pp-site-plan-format>

            <div class="settingsNewSite__contactUs__wrapper" *ngIf="limitedUser">
              <span> {{ 'need_to_add_new_site_plan' | ppTranslate }} </span>

              <pp-button (ppAction)="contactUs()" ppStyle="secondary">
                {{ 'contact_us' | ppTranslate }}
              </pp-button>
            </div>
          </div>

          <div class="createButton__wrapper">
            <pp-new-site-button
              [ppProcessing]="processing"
              [ppDisabled]="
                !site.name || !(sitePlan || uploadedPdf || selectedWorkspaceId || noSitePlan)
              "
              (ppAction)="create()"
            ></pp-new-site-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</pp-settings-container>
