import { Component, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { AddUsersIssuesService } from '../add-users-issues.service';

@Component({
  selector: 'pp-add-users-warning-box',
  templateUrl: './add-users-warning-box.component.html',
  styleUrls: ['./add-users-warning-box.component.scss'],
})
export class AddUsersModalWarningBoxComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  warningMessages: string[] = [];
  EIconPath = EIconPath;

  constructor(private createAccountUserModalIssuesService: AddUsersIssuesService) {
    this.subscribeToWarningChange();

    this.setWarningMessages();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  setWarningMessages(): void {
    this.warningMessages = this.createAccountUserModalIssuesService.getWarningMessages();
  }

  private subscribeToWarningChange() {
    this.createAccountUserModalIssuesService.warningChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe((warnings) => {
        this.setWarningMessages();
      });
  }
}
