import { createElement, TAnyFunction } from '@core/helpers';
import { ApiService } from '@core/http';
import { tap } from 'rxjs/operators';
import { getSvg } from 'src/app/core/helpers/get-svg';
import { EIconPath } from '../../../shared/enums/icons.enum';

export default class AccountSearch {
  element: HTMLElement;

  private callback: TAnyFunction;
  private searchIconElement: HTMLElement;
  private inputElement: HTMLInputElement;
  private clearIconElement: HTMLElement;
  private clearIconVisible = false;
  private apiService: ApiService;

  constructor(apiService: ApiService, _callback: TAnyFunction) {
    this.apiService = apiService;
    this.callback = _callback;
    this.createPlaceholder();

    this.createSearchIconElement().then((text) => {
      const searchIconElement = text;

      this.element.children.item(0).innerHTML = searchIconElement as string;
      this.searchIconElement = this.element.children.item(0) as HTMLElement;
    });
  }

  private createPlaceholder(): void {
    this.element = createElement('div', {
      attrs: {
        class: 'sidePanel__search',
      },
      children: [
        this.createPlaceholderSearchIcon(),
        this.createInputElement(),
        this.createClearIconElement(),
      ],
    });
  }

  private createPlaceholderSearchIcon(): HTMLElement {
    return createElement('div', {
      attrs: {
        id: 'searchIconWrapper',
        class: 'searchIconWrapper',
      },
    });
  }

  private createSearchIconElement(): Promise<string | void> {
    return this.apiService.getSvg(EIconPath.SEARCH_ICON).then((response) => response.text());
  }

  private createInputElement(): HTMLElement {
    this.inputElement = createElement('input', {
      attrs: {
        class: 'input sidePanel__search-input',
        type: 'text',
        placeholder: 'Search Accounts & Sites',
      },
      eventListeners: {
        input: (_event) => {
          if (_event.target.value.length && !this.clearIconVisible) {
            this.clearIconVisible = true;
            this.clearIconElement.style.visibility = 'visible';
          } else if (!_event.target.value.length && this.clearIconVisible) {
            this.clearIconVisible = false;
            this.clearIconElement.style.visibility = 'hidden';
          }

          this.callback(this.inputElement.value);
        },
        focus: () => {
          this.searchIconElement.style.display = 'none';

          this.element.classList.add('sidePanel__search--focused');
        },
        blur: (_event) => {
          if (!_event.target.value.length) {
            this.searchIconElement.style.display = 'block';
          }

          this.element.classList.remove('sidePanel__search--focused');
        },
      },
    });

    return this.inputElement;
  }

  private createClearIconElement(): HTMLElement {
    const clearIconElement = createElement('div', {
      attrs: {
        class: 'sidePanel__search-close',
        style: {
          visibility: 'hidden',
        },
      },
      eventListeners: {
        click: () => {
          this.inputElement.value = '';
          this.clearIconVisible = false;
          this.clearIconElement.style.visibility = 'hidden';
          this.searchIconElement.style.display = 'block';

          this.callback(this.inputElement.value);
        },
      },
    });

    getSvg(this.apiService, EIconPath.ICON_SEARCH_CLOSE).then((svg) => {
      clearIconElement.innerHTML = svg;
    });

    this.clearIconElement = clearIconElement;

    return this.clearIconElement;
  }
}
