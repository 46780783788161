<div class="seenBy" data-test="seenBySection">
  <div class="seenBy__title">{{ 'seen_by' | ppTranslate }}:</div>

  <div
    ppTooltip
    [ppSanitize]="false"
    [ppTitle]="seenByUsers.length > 3 ? null : tooltipText"
    [ppOptions]="{ placement: 'bottom' }"
    (mouseenter)="generateSeenBy()"
    class="seenBy__avatars"
    [class.seenBy__avatars--1]="seenByUsers.length === 1"
    [class.seenBy__avatars--2]="seenByUsers.length === 2"
    [class.seenBy__avatars--3]="seenByUsers.length > 2"
  >
    <img
      class="seenBy__avatar seenBy__first-avatar"
      *ngIf="seenByUsers[0]"
      [src]="users[seenByUsers[0]]?.avatarPublicUrl"
    />

    <img
      class="seenBy__avatar seenBy__second-avatar"
      *ngIf="seenByUsers[1]"
      [src]="users[seenByUsers[1]]?.avatarPublicUrl"
    />

    <img
      class="seenBy__avatar seenBy__third-avatar"
      *ngIf="seenByUsers[2]"
      [src]="users[seenByUsers[2]]?.avatarPublicUrl"
    />

    <div
      class="seenBy__avatar seenBy__more"
      *ngIf="seenByUsers.length > 3"
      ppTooltip
      [ppSanitize]="false"
      [ppTitle]="tooltipText"
      [ppOptions]="{ placement: 'bottom' }"
    >
      <span class="seenBy__counter"> +{{ seenByUsers.length - 2 }} </span>
    </div>
  </div>
</div>
