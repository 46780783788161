import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationsComponent } from './notifications.component';
import { NotificationComponent } from './notification/notification.component';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { NotificationTopToolbarComponent } from './notification-top-toolbar/notification-top-toolbar.component';
import { NotificationLegacyComponent } from './notification/notification-legacy/notification-legacy.component';
import { NotificationCustomFieldComponent } from './notification/notification-custom-field/notification-custom-field.component';
import { NotificationRichTextComponent } from './notification/notification-rich-text/notification-rich-text.component';
import { NotificationFooterComponent } from './notification/notification-footer/notification-footer.component';
import { NotificationResponseComponent } from './notification/notification-response/notification-response.component';
import { NotificationDescriptionComponent } from './notification/notification-description/notification-description.component';
import { NotificationCommentComponent } from './notification/notification-comment/notification-comment.component';
import { NotificationTitleComponent } from './notification/notification-title/notification-title.component';
import { NotificationStatusComponent } from './notification/notification-status/notification-status.component';
import { NotificationPriorityComponent } from './notification/notification-priority/notification-priority.component';
import { NotificationTagsComponent } from './notification/notification-tags/notification-tags.component';
import { NotificationAssigneeComponent } from './notification/notification-assignee/notification-assignee.component';
import { NotificationLocationComponent } from './notification/notification-location/notification-location.component';
import { NotificationAttachmentComponent } from './notification/notification-attachment/notification-attachment.component';
import { NotificationImagesComponent } from './notification/notification-images/notification-images.component';
import { NotificationImages360Component } from './notification/notification-images360/notification-images360.component';
import { NotificationVideosComponent } from './notification/notification-videos/notification-videos.component';
import { NotificationCommentMentionComponent } from './notification/notification-comment-mention/notification-comment-mention.component';
import { NotificationDescriptionMentionComponent } from './notification/notification-description-mention/notification-description-mention.component';
import { NotificationNewPointMentionComponent } from './notification/notification-new-point-mention/notification-new-point-mention.component';
import { NotificationNewPointAssigneeComponent } from './notification/notification-new-point-assignee/notification-new-point-assignee.component';
import { NotificationCommentReactionComponent } from './notification/notification-comment-reaction/notification-comment-reaction.component';
import { NotificationCustomFieldMentionComponent } from './notification/notification-custom-field-mention/notification-custom-field-mention.component';
import { ButtonModule } from '../../components/button/button.module';
import { SpinnerModule } from '../../components/spinner/spinner.module';
import { TagModule } from '../../components/tag/tag.module';
import { RichTextModule } from '../../components/input/rich-text/rich-text.module';
import { NotificationModeSelectModule } from '../../components/notification-mode-select/notification-mode-select.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { SearchBoxModule } from '../../components/search-box/search-box.module';
import { AutonumericModule } from '../../directives/autonumeric/autonumeric.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SwitchCasesModule } from '../../directives/switch-cases/switch-cases.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { NotificationClearComponent } from './notification/notification-clear/notification-clear.component';
import { IconModule } from '../../components/icon/icon-module';
import { NotificationOptionsDropdownComponent } from './notification-options-dropdown/notification-options-dropdown.component';
import { SecureSrcModule } from '../../directives/secure-src/secure-src.module';
import { NotificationFlagComponent } from './notification/notification-flag/notification-flag.component';

@NgModule({
  declarations: [
    NotificationsComponent,
    NotificationComponent,
    NotificationTopToolbarComponent,
    NotificationLegacyComponent,
    NotificationCustomFieldComponent,
    NotificationRichTextComponent,
    NotificationFooterComponent,
    NotificationResponseComponent,
    NotificationDescriptionComponent,
    NotificationCommentComponent,
    NotificationTitleComponent,
    NotificationStatusComponent,
    NotificationPriorityComponent,
    NotificationTagsComponent,
    NotificationAssigneeComponent,
    NotificationLocationComponent,
    NotificationAttachmentComponent,
    NotificationImagesComponent,
    NotificationImages360Component,
    NotificationVideosComponent,
    NotificationCommentMentionComponent,
    NotificationDescriptionMentionComponent,
    NotificationNewPointMentionComponent,
    NotificationNewPointAssigneeComponent,
    NotificationCommentReactionComponent,
    NotificationCustomFieldMentionComponent,
    NotificationClearComponent,
    NotificationOptionsDropdownComponent,
    NotificationFlagComponent,
  ],
  imports: [
    CommonModule,
    TagModule,
    ButtonModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    ScrollingModule,
    SpinnerModule,
    RichTextModule,
    NotificationModeSelectModule,
    SearchBoxModule,
    PipesModule,
    SearchBoxModule,
    IconModule,
    SecureSrcModule,
  ],
  exports: [NotificationsComponent],
})
export class NotificationsModule {}
