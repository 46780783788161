import { Component, EventEmitter, Input } from '@angular/core';
import { takeUntil, tap } from 'rxjs';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EditAccountUserModalComponent } from '../../account/account-settings/account-user-modal/edit-account-user-modal.component';
import { TEditAccountUserModalParams } from '../../account/account-settings/account-user-modal/edit-account-user-modal.consts';
import { AccountSharesService } from '../../share/account-shares.service';
import { SelectedSharesService } from '../services/selected-shares.service';
import { UserManagementBulkChangesBarService } from './user-management-bulk-changes-bar.service';

@Component({
  selector: 'pp-user-management-bulk-changes-bar',
  templateUrl: './user-management-bulk-changes-bar.component.html',
  styleUrls: ['./user-management-bulk-changes-bar.component.scss'],
})
export class UserManagementBulkChangesBarComponent {
  @Input() ppAccountId: string;

  private readonly destroy$ = new EventEmitter<void>();

  selectedShares: string[] = [];
  EIconPath = EIconPath;

  oneUserSelected = false;
  oneWorkspaceSelected = false;

  constructor(
    private selectedSharesService: SelectedSharesService,
    private userManagementBulkChangesBarService: UserManagementBulkChangesBarService,
    private modalService: ModalService,
    private accountSharesService: AccountSharesService,
  ) {}

  ngOnInit(): void {
    this.selectedSharesService.selectedSharesIdsChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedShares) => {
        this.selectedShares = selectedShares;

        if (this.ppAccountId) {
          this.processAccountShares();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  editShares(): void {
    this.setCreateUserModalData();

    this.modalService.showModal(EditAccountUserModalComponent, {
      callback: () => {
        this.accountSharesService.resetAccountShares(this.ppAccountId).subscribe();
      },
    });
  }

  deleteShares(): void {
    this.userManagementBulkChangesBarService.deleteShares(this.ppAccountId, this.selectedShares);
  }

  deselectAll(): void {
    this.selectedSharesService.clearSelectedShares();
  }

  private setCreateUserModalData(): void {
    this.modalService.setData<TEditAccountUserModalParams>({
      accountId: this.ppAccountId,
    });
  }

  private processAccountShares() {
    this.accountSharesService
      .fetchAccountShares(this.ppAccountId)
      .pipe(
        takeUntil(this.destroy$),
        tap((accountUsers) => {
          const filteredUsers = this.userManagementBulkChangesBarService.filterAccountUsers(
            accountUsers,
            this.selectedShares,
          );

          this.oneUserSelected = filteredUsers.length === 1;
          this.oneWorkspaceSelected = filteredUsers.every(
            (user) =>
              user.shares.length === 1 &&
              user.shares[0].workspaceId === filteredUsers[0].shares[0].workspaceId,
          );
        }),
      )
      .subscribe();
  }
}
