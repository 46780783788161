import { Injectable } from '@angular/core';

import { ResponseErrorService } from '../errors/response-error.service';
import { SortingService } from '@core/helpers';
import { TagsApiProviderService } from '@core/api';
import { TTag } from '../../data-providers/api-providers/tag-api-provider/tag-usage.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, filter, finalize, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  private readonly _tags$ = new BehaviorSubject<TTag[]>(null);
  readonly tagsUpdated$ = this._tags$.asObservable().pipe(filter((tags) => tags !== null));

  private fetch$: Observable<TTag[]>;
  private firstFetch: boolean;
  private fetching: boolean;
  private fetched: boolean;

  constructor(
    private responseErrorService: ResponseErrorService,
    private sortingService: SortingService,
    private tagsApiProviderService: TagsApiProviderService,
  ) {}

  fetchTagUsage(workspaceId: string): Observable<TTag[]> {
    if (this.firstFetch || !this.fetching) {
      this.firstFetch = false;
      this.fetching = true;

      this.fetch$ = this.tagsApiProviderService.fetchTagUsage(workspaceId).pipe(
        tap((tags) => {
          this.fetched = true;

          this.setTags(tags);
        }),
        catchError(this.responseErrorService.handleRequestError),
        finalize(() => {
          this.fetching = false;
        }),
      );
    }

    return this.fetch$;
  }

  addTag(newTag: string): void {
    this.addTags([
      {
        name: newTag,
      },
    ]);
  }

  setTags(newTags: TTag[]): void {
    const newTagsSet = [...newTags];

    newTagsSet.sort((a, b) => this.sortingService.naturalSort(a.name, b.name));
    this._tags$.next(newTagsSet);
  }

  addTags(newTags: TTag[]): void {
    const newTagsSet = [...this._tags$.getValue(), ...newTags];

    newTagsSet.sort((a, b) => this.sortingService.naturalSort(a.name, b.name));
    this._tags$.next(newTagsSet);
  }

  deleteTag(tagToDelete: TTag): void {
    const currentTagsSet = this._tags$.getValue();
    const newTagsSet = [...currentTagsSet];
    const tagIndex = currentTagsSet.indexOf(tagToDelete);

    newTagsSet.splice(tagIndex, 1);

    this._tags$.next(newTagsSet);
  }
}
