<pp-not-authorized-layout>
  <div class="auth-column">
    <footer class="authPage__footer centered-text" *ngIf="!verifyStatus.processing">
      <div *ngIf="verifyStatus.success">
        <p class="authPage__bottom-link">{{ 'you_can_now_log_in' | ppTranslate }}</p>

        <pp-button ppStyle="primary" href routerLink="/login">
          <span class="button__text">{{ 'log_in' | ppTranslate }}</span>
        </pp-button>
      </div>

      <div *ngIf="verifyStatus.error">
        <p class="authPage__bottom-link">
          {{ 'verify_link_invalid_1' | ppTranslate }}
          <a href="/#/login">{{ 'verify_link_invalid_2' | ppTranslate }}</a>
          {{ 'verify_link_invalid_3' | ppTranslate }}
        </p>
      </div>
    </footer>
  </div>
</pp-not-authorized-layout>
