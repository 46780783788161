import { Injectable, OnDestroy } from '@angular/core';
import { SortingService } from '@core/helpers';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, throwError } from 'rxjs';
import { generateErrorPrompt } from 'src/app/project/modules/errors/response-error';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { SiteDataService } from 'src/app/project/modules/site/site-data.service';
import {
  setChangedWorkspace,
  setChangedWorkspaces,
} from 'src/app/project/modules/workspace/workspace';
import { UpdateWorkspaceTags } from 'src/app/project/modules/workspace/workspace.actions';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { ETagImportStep } from './tag-import-active-step.enum';
import { EStore } from '../../../shared/enums/store.enum';
import { TTag } from '../../../data-providers/api-providers/tag-api-provider/tag-usage.model';

@Injectable({
  providedIn: 'root',
})
export class ImportTagService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private workspaces$: Observable<TWorkspacesById>;

  private workspaces: TWorkspacesById;

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
    }>,

    private translationPipe: TranslationPipe,
    private siteDataService: SiteDataService,
    private promptService: PromptService,
    private sortingService: SortingService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspaces = workspaces;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  calculateTotalSelectedTagsNumber(
    activeStep: ETagImportStep,
    selectedFileTags: string[],
    selectedTags: {
      [accountId: string]: {
        [workspaceId: string]: string[];
      };
    },
    tagsPerAccount: {
      [accountId: string]: number;
    },
  ): { mergedTags: string[]; totalSelectedTags: number } {
    let totalSelectedTags = 0;
    let mergedTags = [];

    if (activeStep === ETagImportStep.FILE) {
      totalSelectedTags += selectedFileTags.length;
    } else {
      Object.keys(selectedTags).forEach((account) => {
        Object.keys(selectedTags[account]).forEach((workspaceId) => {
          mergedTags = [...mergedTags, ...selectedTags[account][workspaceId]];
        });
      });

      Object.keys(tagsPerAccount).forEach((accountId) => {
        totalSelectedTags += tagsPerAccount[accountId];
      });
    }

    return { mergedTags, totalSelectedTags };
  }

  importTags({
    fileLoaded,
    selectedTags,
    selectedFileTags,
    activeWorkspaceId,
  }: {
    fileLoaded: boolean;
    selectedTags: {
      [accountId: string]: {
        [workspaceId: string]: string[];
      };
    };
    selectedFileTags: string[];
    activeWorkspaceId: string;
  }): Observable<TTag[]> {
    let tagsToUpload = [];

    if (!fileLoaded) {
      let mergedTags = [];

      Object.keys(selectedTags).forEach((account) => {
        Object.keys(selectedTags[account]).forEach((workspaceId) => {
          mergedTags = [...mergedTags, ...selectedTags[account][workspaceId]];
        });
      });

      tagsToUpload = mergedTags;
    } else {
      tagsToUpload = selectedFileTags;
    }

    tagsToUpload = Array.from(new Set(tagsToUpload));

    return this.siteDataService.addTags(activeWorkspaceId, tagsToUpload).pipe(
      map(() => {
        const newTags: TTag[] = [];
        const workspaceTags = [...this.workspaces[activeWorkspaceId].tags];

        tagsToUpload.forEach((tag) => {
          newTags.push({ name: tag });
          workspaceTags.push(tag);
        });

        newTags.sort((a, b) => this.sortingService.naturalSort(a.name, b.name));
        workspaceTags.sort((a, b) => this.sortingService.naturalSort(a, b));

        this.store.dispatch(
          new UpdateWorkspaceTags({
            workspaceId: activeWorkspaceId,
            tags: workspaceTags,
          }),
        );

        setChangedWorkspaces(true);
        setChangedWorkspace(activeWorkspaceId);

        const promptText =
          tagsToUpload.length > 1
            ? this.translationPipe.transform('prompt_tag_import_multiple')
            : this.translationPipe.transform('prompt_tag_import_single');

        this.promptService.showSuccess(promptText);

        return newTags;
      }),
      catchError((error) => {
        const promptText = generateErrorPrompt(error, 'prompt_error');

        this.promptService.showError(promptText);

        return throwError(error);
      }),
    );
  }
}
