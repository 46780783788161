<pp-white-label-placeholder *ngIf="!loaded"></pp-white-label-placeholder>

<div class="tabContent" [class.hidden]="!loaded">
  <pp-grid>
    <ng-container title> {{ 'white_label' | ppTranslate }} </ng-container>

    <ng-container body>
      <div class="whiteLabelEdit" *ngIf="whiteLabel.data">
        <section class="whiteLabel__section">
          <h6>Typography and branding</h6>

          <label>
            <pp-font-select
              [ppSelectedFont]="fonts[selectedFontIndex]"
              (ppSelectFont)="selectFont($event)"
            ></pp-font-select>
          </label>

          <pp-logo-upload
            ppLogoLabelKey="web_logo"
            [(ppLogoUrl)]="webLogoData"
            (ppUpdateLogo)="setNewWebLogo($event)"
            [ppClear]="clearWhiteLabelSubject.asObservable()"
          ></pp-logo-upload>

          <div class="whiteLabel__preview-container">
            <label>Preview of theme</label>

            <pp-white-label-preview
              [ppWhiteLabel]="whiteLabel"
              [ppWebLogoData]="webLogoData"
              [ppWebLogoImages]="newWebLogo"
            ></pp-white-label-preview>
          </div>
        </section>

        <section class="whiteLabel__section">
          <h6>Customize the theme colors (with #HEX codes)</h6>

          <div class="whiteLabel__colours-container">
            <pp-color-picker
              [(ppColor)]="whiteLabel.data.panelBg"
              ppNameKey="side_panel_left"
            ></pp-color-picker>
            <pp-color-picker
              [(ppColor)]="whiteLabel.data.panelAccent"
              ppNameKey="side_panel_right"
            ></pp-color-picker>
            <pp-color-picker
              [(ppColor)]="whiteLabel.data.panelFont"
              ppNameKey="left_panel_hover"
            ></pp-color-picker>
            <pp-color-picker
              [(ppColor)]="whiteLabel.data.panelFontHighlight"
              ppNameKey="left_panel_active_color"
            ></pp-color-picker>
            <pp-color-picker
              [(ppColor)]="whiteLabel.data.panelText"
              ppNameKey="side_panel_text_color"
            ></pp-color-picker>

            <pp-color-picker
              [(ppColor)]="whiteLabel.data.panelTextHover"
              ppNameKey="side_panel_text_color_hover"
            ></pp-color-picker>

            <pp-color-picker
              [(ppColor)]="whiteLabel.data.primary50"
              ppNameKey="primary_50"
            ></pp-color-picker>

            <pp-color-picker
              [(ppColor)]="whiteLabel.data.primary75"
              ppNameKey="primary_75"
            ></pp-color-picker>

            <pp-color-picker
              [(ppColor)]="whiteLabel.data.primary100"
              ppNameKey="primary_100"
            ></pp-color-picker>

            <pp-color-picker
              [(ppColor)]="whiteLabel.data.primary200"
              ppNameKey="primary_200"
            ></pp-color-picker>

            <pp-color-picker
              [(ppColor)]="whiteLabel.data.primary300"
              ppNameKey="primary_300"
            ></pp-color-picker>

            <pp-color-picker
              [(ppColor)]="whiteLabel.data.primary400"
              ppNameKey="primary_400"
            ></pp-color-picker>

            <pp-color-picker
              [(ppColor)]="whiteLabel.data.primary500"
              ppNameKey="primary_500"
            ></pp-color-picker>

            <pp-color-picker
              [(ppColor)]="whiteLabel.data.primary600"
              ppNameKey="primary_600"
            ></pp-color-picker>

            <pp-color-picker
              [(ppColor)]="whiteLabel.data.primary700"
              ppNameKey="primary_700"
            ></pp-color-picker>

            <pp-color-picker
              [(ppColor)]="whiteLabel.data.primary800"
              ppNameKey="primary_800"
            ></pp-color-picker>

            <pp-color-picker
              [(ppColor)]="whiteLabel.data.primary900"
              ppNameKey="primary_900"
            ></pp-color-picker>
          </div>
        </section>

        <section class="whiteLabel__section">
          <h6>{{ 'website' | ppTranslate }}</h6>

          <input
            class="whiteLabelEdit__website-input"
            type="text"
            placeholder="Website name here"
            [(ngModel)]="websiteUrl"
          />

          <h6 class="whiteLabel__header">{{ 'pdf_logos' | ppTranslate }}</h6>

          <pp-logo-upload
            ppLogoLabelKey="footer_logo"
            [(ppLogoUrl)]="footerLogoData"
            (ppUpdateLogo)="setNewFooterLogo($event)"
            [ppClear]="clearWhiteLabelSubject.asObservable()"
          ></pp-logo-upload>

          <pp-logo-upload
            ppLogoLabelKey="top_right_logo"
            [ppLogoUrl]="topRightLogoData"
            (ppUpdateLogo)="setNewTopRightLogo($event)"
            [ppClear]="clearWhiteLabelSubject.asObservable()"
          ></pp-logo-upload>
        </section>
      </div>

      <div class="whiteLabel__buttons-container">
        <pp-button
          ppId="clearWhiteLabelButton"
          ppStyle="text"
          (ppAction)="clearWhiteLabel()"
          [ppProcess]="processing"
        >
          <span class="whiteLabel__clear-button-text">
            {{ 'clear_all' | ppTranslate }}
          </span>
        </pp-button>

        <pp-button
          ppId="createWhiteLabelBtn"
          ppStyle="primary"
          (ppAction)="updateWhiteLabel()"
          [ppProcess]="processing"
        >
          {{ 'save_changes' | ppTranslate }}
        </pp-button>
      </div>
    </ng-container>
  </pp-grid>
</div>
