<div class="dashletActivity">
  <div class="dashletActivity__container">
    <img class="dashletActivity__icon" [src]="EIconPath.BASIC_FIELD_STATUS" />

    <div class="dashletActivity__text">
      {{ 'changed' | ppTranslate }}
      <span class="timeline__strong">{{ 'status' | ppTranslate }}</span>
      {{ 'to' | ppTranslate | lowercase }}
      <span class="dashletActivities__item-quoted timeline__strong activityChange--{{ color }}">
        {{ activityChange }}.</span
      >
    </div>
  </div>
</div>
