import { Action } from '@ngrx/store';
import { TSitePlanWidth } from './ui.model';

export enum ActionTypes {
  ADD_TO_VIEW = '[Component] AddToView',
  REMOVE_FROM_VIEW = '[Component] RemoveFromView',
  SET_SITE_PLAN_WIDTH = '[Component] SetSitePlanWidth',
  SET_SITE_PLAN_WIDTH_FROM_DB = '[Component] SetSitePlanWidthFromDb',
}

export class AddComponentToView implements Action {
  readonly type = ActionTypes.ADD_TO_VIEW;
  constructor(
    readonly payload: {
      componentName: string;
    },
  ) {}
}

export class RemoveComponentFromView implements Action {
  readonly type = ActionTypes.REMOVE_FROM_VIEW;
  constructor(
    readonly payload: {
      componentName: string;
    },
  ) {}
}

export class SetSitePlanWidth implements Action {
  readonly type = ActionTypes.SET_SITE_PLAN_WIDTH;
  constructor(
    readonly payload: {
      workspaceId: string;
      width: string;
    },
  ) {}
}

export class SetSitePlanPreferencesFromDB implements Action {
  readonly type = ActionTypes.SET_SITE_PLAN_WIDTH_FROM_DB;
  constructor(
    readonly payload: {
      sitePlanWidth: TSitePlanWidth;
    },
  ) {}
}

export type ActionsUnion =
  | AddComponentToView
  | RemoveComponentFromView
  | SetSitePlanWidth
  | SetSitePlanPreferencesFromDB;
