<table
  aria-label="Custom fields table"
  class="settingsTable"
  *ngIf="workspace.customFields.length > 0 && (customFields | keyvalue).length > 0"
>
  <thead class="customFieldTable__head">
    <tr class="customFieldTable__row">
      <th scope="col" class="customFieldTable__header">
        {{ 'name' | ppTranslate }}
      </th>

      <th scope="col" class="customFieldTable__header--center">
        {{ 'type' | ppTranslate }}
      </th>

      <th scope="col" class="customFieldTable__header--end">
        {{ 'actions' | ppTranslate }}
      </th>
    </tr>
  </thead>

  <div
    class="settingsTable__line--wrapper"
    [style.marginTop.px]="linePosition"
    [class.dragging]="dragging"
    *ngIf="onDragOverIndex !== null"
  >
    <div class="settingsTable__line" [class.dragging]="dragging"></div>
  </div>

  <ng-container *ngFor="let fieldId of workspace.customFields; index as index">
    <tbody
      *ngIf="
        customFields[ppWorkspaceId]?.[fieldId] &&
        customFields[ppWorkspaceId]?.[fieldId]?.display !== false
      "
      class="settingsTable__item-grab"
      [class.settingsTable__item--opacity]="draggedCustomFieldId === fieldId"
      (dragover)="onDragOver($event, index, fieldId)"
      (dragenter)="$event.preventDefault()"
      (dragleave)="$event.preventDefault()"
      (drop)="onDrop($event)"
      (dragend)="onDragEnd()"
    >
      <tr
        id="{{ 'itemRow-' + fieldId }}"
        class="customFieldTable__row"
        draggable="true"
        (dragstart)="onDragStart($event, fieldId, index)"
      >
        <td class="customFieldTable__cell">
          <div class="settingsTable__cell--row" [class.dragging]="dragging">
            <div data-m-target="Custom Field Reorder Button" class="settingsTable__drag-icon"></div>

            <span class="settingsTable__cell--fieldName">
              {{ customFields[ppWorkspaceId][fieldId]?.label }}
            </span>
          </div>
        </td>

        <td class="customFieldTable__cell--center" [class.dragging]="dragging">
          <div class="settingsTable__icon--wrapper">
            <img
              ppImage
              class="settingsTable__icon settingsTable__icon--type"
              [src]="getCustomFieldIconSrc(customFields[ppWorkspaceId][fieldId])"
            />
          </div>

          <span class="settingsTable__type">
            {{
              volyCustomFieldLabel[customFields[ppWorkspaceId][fieldId]?.volyIntegrationActive] ||
                customFieldLabels[customFields[ppWorkspaceId][fieldId]?.type]
            }}
          </span>
        </td>

        <td class="customFieldTable__cell--end">
          <pp-button
            ppTooltip
            [ppTitle]="
              customFields[ppWorkspaceId][fieldId]?.lockedTemplate
                ? 'This custom field is read only'
                : ''
            "
            ppStyle="primary"
            [class.dragging]="dragging"
            ppId="{{ 'siteSettingsCFOptions' + index }}"
            (ppAction)="editCustomField(fieldId)"
            ppSize="small"
            [ppDisabled]="offline || customFields[ppWorkspaceId][fieldId]?.lockedTemplate"
          >
            <pp-icon
              [ppSrc]="EIconPath.ICON_MISC_EDIT_14"
              ppColor="white"
              ppClass="ml--n04 setSize14"
            ></pp-icon>
            {{ 'edit' | ppTranslate }}
          </pp-button>

          <pp-delete-button
            ppTooltip
            [ppTitle]="getRemoveTooltipTitle(customFields[ppWorkspaceId][fieldId])"
            [ppId]="'siteSettingsCFDelete' + index"
            [ppDisabled]="offline || customFields[ppWorkspaceId][fieldId]?.lockedTemplate"
            [ppDragging]="dragging"
            (ppAction)="deleteCustomField(fieldId)"
          ></pp-delete-button>
        </td>
      </tr>
    </tbody>
  </ng-container>
</table>
