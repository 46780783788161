<div class="full-height">
  <pp-supported-browsers-bar></pp-supported-browsers-bar>
  <pp-action-bar></pp-action-bar>
  <pp-reminder-alert></pp-reminder-alert>
  <pp-top-bar></pp-top-bar>
  <pp-side-panel></pp-side-panel>

  <div
    class="settingsContent"
    [class.settingContent--no-sidebar]="sidePanel.hidden"
    [class.settingsContent--collapsed-sidebar]="!sidePanel.expandedInitially && !sidePanel.hidden"
    [class.settingsContent--down]="ui.actionBar"
  >
    <div class="settingsContent__container">
      <ng-content></ng-content>
    </div>
  </div>
</div>
