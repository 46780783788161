import { Injectable } from '@angular/core';
import { isEqual } from 'lodash';
import { Subject } from 'rxjs';
import { EDashletType } from 'src/app/project/modules/dashboard/dashlets-enums';
import { TDashletBudgetItem } from 'src/app/project/modules/dashboard/new-dashlet-modal/new-dashlet-modal-cost-fields/new-dashlet-modal-cost-fields.consts';
import { TDashlet } from 'src/app/project/modules/preferences/preferences.model';
import { AssetService } from '../../../../asset-service/asset.service';
import { generateNewAssetDashlet } from './utils/generate-new-asset-dashlet';

@Injectable({
  providedIn: 'root',
})
export class AssetDashboardService {
  private readonly _expandedAssetIdChange = new Subject<string>();
  readonly expandedAssetIdChange$ = this._expandedAssetIdChange.asObservable();

  private expandedAssetId: string;

  constructor(private assetService: AssetService) {}

  setExpandedAssetId(assetId: string): void {
    this.expandedAssetId = assetId;

    this._expandedAssetIdChange.next(assetId);
  }

  getExpandedAssetId(): string {
    return this.expandedAssetId;
  }

  addDashlet(field: { value: EDashletType }, assetId: string): void {
    const asset = this.assetService.getAsset(assetId);
    const newDashlet = generateNewAssetDashlet(field.value);

    asset.dashlets.push(newDashlet);

    this.assetService
      .editAsset(assetId, {
        dashlets: asset.dashlets,
      })
      .subscribe();
  }

  addCostComparisonDashlet(assetId: string, data: TDashletBudgetItem[]): void {
    const asset = this.assetService.getAsset(assetId);
    const newDashlet = generateNewAssetDashlet(EDashletType.COST_COMPARISON);

    newDashlet.selectedRange.workspacesCosts = {};

    data.forEach((item) => {
      newDashlet.selectedRange.workspacesCosts[item.workspaceId] = [
        item.budgetFieldId,
        item.costFieldId,
      ];
    });

    asset.dashlets.push(newDashlet);

    this.assetService
      .editAsset(assetId, {
        dashlets: asset.dashlets,
      })
      .subscribe();
  }

  addStackedStatusDashlet(assetId: string): void {
    const asset = this.assetService.getAsset(assetId);
    const newDashlet = generateNewAssetDashlet(EDashletType.CURRENT_STACKED_STATUS);

    const items: string[] = [];

    for (let site of asset.sites) {
      if (items.length < 5) {
        items.push(site.workspaceId);
      } else {
        break;
      }
    }

    newDashlet.selectedRange.workspaceIds = items;

    asset.dashlets.push(newDashlet);

    this.assetService
      .editAsset(assetId, {
        dashlets: asset.dashlets,
      })
      .subscribe();
  }

  removeDashlet(assetId: string, dashlet: TDashlet): void {
    const asset = this.assetService.getAsset(assetId);
    const dashlets = asset.dashlets;

    const dashletIndex = dashlets.findIndex((assetDashlet) => {
      return (
        assetDashlet.name === dashlet.name &&
        dashlet.x === assetDashlet.x &&
        dashlet.y === assetDashlet.y &&
        dashlet.period === assetDashlet.period &&
        dashlet.selectedRange.accountId === assetDashlet.selectedRange.accountId &&
        isEqual(dashlet.selectedRange.workspaceIds, assetDashlet.selectedRange.workspaceIds) &&
        dashlet.cols === assetDashlet.cols &&
        dashlet.rows === assetDashlet.rows
      );
    });

    dashlets.splice(dashletIndex, 1);

    this.assetService
      .editAsset(assetId, {
        dashlets: dashlets,
      })
      .subscribe();
  }

  updateAssetDashboard(dashlets: TDashlet[], assetId: string): void {
    const currentDashlets = this.assetService.getAsset(assetId).dashlets;
    const assetsEqual = isEqual(currentDashlets, dashlets);

    if (assetsEqual) {
      return;
    }

    this.assetService
      .editAsset(assetId, {
        dashlets: dashlets,
      })
      .subscribe();
  }
}
