import { TFilters } from '../site-filter.model';
import { areArraysEqualSets } from './are-arrays-equal-sets';

export function compareFilterAssignees(firstFilter: TFilters, secondFilter: TFilters): boolean {
  const assignees1 = firstFilter.assignees;
  const assignees2 = secondFilter.assignees;

  if (assignees1.value.length !== assignees2.value.length) {
    return false;
  }

  return areArraysEqualSets(assignees1.value, assignees2.value);
}
