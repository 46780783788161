import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'pp-two-factor-authentication-confirm-code-button',
  templateUrl: './two-factor-authentication-confirm-code-button.component.html',
  styleUrls: ['./two-factor-authentication-confirm-code-button.component.scss'],
})
export class TwoFactorAuthenticationConfirmCodeButtonComponent implements OnChanges {
  @Output() ppConfirm2FA = new EventEmitter<void>();
  @Input() ppCode: string;
  @Input() ppProcessing: boolean;
  @Input() ppCodeLength: number;
  isCodeCorrect: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    this.checkIfCodeIsCorrect();
  }

  confirm2FA(): void {
    this.ppConfirm2FA.emit();
  }

  private checkIfCodeIsCorrect(): void {
    this.isCodeCorrect = this.ppCode.length === this.ppCodeLength;
  }
}
