import { Injectable, OnDestroy } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { SubscriptionApiProviderService } from '@core/api';
import {
  TSubscription,
  TSubscriptionRequest,
} from '../../data-providers/api-providers/subscription-api-provider/subscription-requests.model';
import { ResponseErrorService } from '../errors/response-error.service';

export type TSubscriptions = {
  data: TSubscription[];
};

export type TSubscriptionsById = {
  data: {
    [subscriptionId: string]: TSubscription;
  };
};

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private subscriptions: TSubscriptions;
  private subscriptionsById: TSubscriptionsById;

  constructor(
    private subscriptionApiProviderService: SubscriptionApiProviderService,
    private responseErrorService: ResponseErrorService,
  ) {
    this.subscriptions = { data: [] };
    this.subscriptionsById = { data: {} };
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  getSubscriptions(): TSubscriptions {
    return this.subscriptions;
  }

  setSubscriptions(data: TSubscription[]): TSubscriptions {
    this.subscriptions.data = data;

    return this.getSubscriptions();
  }

  getSubscriptionsById(): TSubscriptionsById {
    return this.subscriptionsById;
  }

  createSubscriptionsById(): void {
    this.subscriptions.data.forEach((subscription) => {
      this.subscriptionsById.data[subscription.workspaceId] = subscription;
    });
  }

  fetchSubscriptions(): Observable<TSubscription[]> {
    return this.subscriptionApiProviderService.fetchSubscriptions().pipe(
      tap((response) => {
        this.subscriptions.data = response;
        this.createSubscriptionsById();
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  addSubscription(subscription: TSubscriptionRequest): Observable<TSubscription> {
    return this.subscriptionApiProviderService
      .addSubscriptions(subscription)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  updateSubscription(
    subscriptionId: string,
    subscription: TSubscriptionRequest,
  ): Observable<TSubscription> {
    return this.subscriptionApiProviderService
      .updateSubscription(subscriptionId, subscription)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  deleteSubscription(subscriptionId: string): Observable<null> {
    return this.subscriptionApiProviderService
      .deleteSubscription(subscriptionId)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }
}
