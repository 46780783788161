import { TFilters, TSitesFilter } from '../site-filter.model';
import { isSitesDefault } from './is-sites-default';

export function clearSites(
  filters: TFilters,
  applied?: boolean,
): { sites: TSitesFilter[]; default: boolean } {
  filters.sites.forEach((account) => {
    if (account.value !== account.default) {
      account.value = account.default;

      if (applied) {
        account.appliedValue = account.default;
      }
    }

    account.workspaces.forEach((site) => {
      if (site.value !== site.default) {
        site.value = site.default;

        if (applied) {
          site.appliedValue = site.default;
        }
      }
    });
  });

  filters.sitesDefault = isSitesDefault(filters);

  return {
    sites: filters.sites,
    default: filters.sitesDefault,
  };
}
