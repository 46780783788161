import * as XLSX from 'xlsx';

type AOA = any[][];

export function onFileReaderLoad(event: ProgressEvent<FileReader>): string[] {
  const bstr = event.target.result;
  const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
  const wsname: string = wb.SheetNames[0];
  const ws: XLSX.WorkSheet = wb.Sheets[wsname];

  const data = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
  let spreadData: string[] = [];

  data.forEach((row) => {
    spreadData = [...spreadData, ...row];
  });

  spreadData = spreadData.filter((tag) => tag !== null && tag);

  spreadData.forEach((tag, index) => {
    spreadData[index] = tag?.toString();

    if (spreadData[index].length > 30) {
      spreadData[index] = spreadData[index].substring(0, 30);
    }
  });

  return spreadData;
}
