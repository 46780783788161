import { cloneDeep } from 'lodash';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, forkJoin } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { TAccount } from '../../account/account.model';
import { TPreferences } from '../../preferences/preferences.model';
import { TUser } from '../user.model';

import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { SyncService } from 'src/app/project/modules/offline/sync.service';
import { SiteService } from 'src/app/project/modules/site/site.service';
import { PreferencesService } from '../../preferences/preferences-service/preferences.service';
import { WorkspaceService } from '../../workspace/workspace.service';
import { SubscriptionsService } from '../subscriptions.service';
import { UserService } from '../user.service';

import { Title } from '@angular/platform-browser';
import { EStore } from 'src/app/project/shared/enums/store.enum';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  workspaces: TWorkspace[] = [];
  preferences$: Observable<TPreferences>;
  preferences: TPreferences;
  user$: Observable<TUser>;
  user: TUser;
  private accounts$: Observable<TAccount[]>;
  accounts: TAccount[] = [];

  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store<{
      user: TUser;
      accounts: TAccount[];
    }>,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private workspaceService: WorkspaceService,
    private subscriptionsService: SubscriptionsService,
    private preferencesService: PreferencesService,
    private syncService: SyncService,
    private accountService: AccountService,
    private router: Router,
    private siteService: SiteService,
    private titleService: Title,
  ) {
    this.user$ = this.store.pipe(select(EStore.USER));
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
  }

  ngOnInit() {
    const accounts = this.accountService.getAccounts();

    this.titleService.setTitle('Profile settings | Pinpoint Works');

    if (!accounts.length) {
      this.syncService.firstLoad().pipe(takeUntil(this.destroy$)).subscribe();
    }

    this.activatedRoute.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.paramsHandler());

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((_user) => this.userHandler(_user));

    this.accounts$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_accounts) => this.accountsHandler(_accounts));

    this.userService
      .fetchUser()
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() =>
          forkJoin([
            this.workspaceService.generateBasicWorkspaces(),
            this.preferencesService.fetchPreferences(),
            this.subscriptionsService.fetchSubscriptions(),
          ]),
        ),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  paramsHandler(): void {
    const fetched = !this.router.url.startsWith('/site');
    this.siteService.setFetched(fetched);
  }

  userHandler(_user: TUser): void {
    this.user = _user;
  }

  accountsHandler(_accounts: TAccount[]): void {
    const accounts = cloneDeep(_accounts);

    if (Array.isArray(accounts)) {
      accounts.forEach((account) => {
        this.accounts[account.accountId] = account;
      });
    }
  }
}
