import { Injectable } from '@angular/core';
import { TSectionVisibilitySettings } from './export-body.model';
import { TExportData } from './utils/export-data';
import { TExportFilter } from './utils/export-filter.model';
import { EExportTypeName } from './utils/export-type-names-enum';

@Injectable({
  providedIn: 'root',
})
export class PointExportVisibilitySettingsService {
  constructor() {}

  getSectionVisibilitySettings(
    exportType: EExportTypeName,
    exportData: TExportData,
    customFieldIds: string[],
    exportFilters: TExportFilter,
  ): TSectionVisibilitySettings {
    const imagesDatesRange = {
      fromEpochMillis: exportFilters.updatedDate.startDate
        ? exportFilters.updatedDate.startDate.toString()
        : '',
      toEpochMillis: exportFilters.updatedDate.endDate
        ? exportFilters.updatedDate.endDate.toString()
        : '',
    };

    let sectionsVisibilitySettings: TSectionVisibilitySettings = {
      showId: exportData.showId,
      showTitle: exportData.showTitle,
      showDescription: exportData.showDescription,
      showPriority: exportData.showPriority,
      showStatus: exportData.showStatus,
      showTags: exportData.showTags,
      showCustomFields: exportData.showCustomFields,
      showCreationDate: exportData.showCreationDate,
      showComments: exportData.showComments,
      showExportDate: exportData.showExportDate,
      showImages: exportData.showImages,
      imagesSize: exportData.imagesSize,
      showFlagged: exportData.showFlagged,
      customFieldIds: customFieldIds ? customFieldIds.map((id) => id.toString()) : [],
      showUrl: exportData.showUrl,
    };

    let extraSettings = {};

    if (exportType === EExportTypeName.SIMPLE_LIST) {
      sectionsVisibilitySettings = this.getSimpleListSettings(exportData);
    } else if (exportType === EExportTypeName.ZIP || exportType === EExportTypeName.PDF) {
      extraSettings = this.getIndividualReportSettings(exportData);
    } else if (exportType === EExportTypeName.WORD || exportType === EExportTypeName.ZIP_WORD) {
      extraSettings = this.getWordSettings(exportData);
    } else if (exportType === EExportTypeName.DETAILED_LIST) {
      extraSettings = this.getDetailedListSettings(exportData);
    }

    sectionsVisibilitySettings = Object.assign(sectionsVisibilitySettings, extraSettings);

    if (exportData.imagesDatesRange) {
      sectionsVisibilitySettings.imagesDatesRange = imagesDatesRange;
    }

    return sectionsVisibilitySettings;
  }

  private getDetailedListSettings(exportData: TExportData): TSectionVisibilitySettings {
    return {
      showExportBy: exportData.showExportBy,
      showSiteMap: exportData.showSiteMap,
      showAssignees: exportData.showAssignees,
      showCreatedBy: exportData.showCreatedBy,
      showSignature: exportData.showSignature,
      showFlagged: exportData.showFlagged,
    };
  }

  private getWordSettings(exportData: TExportData): TSectionVisibilitySettings {
    return {
      showEditDate: exportData.showEditDate,
      showDocuments: exportData.showDocuments,
    };
  }

  private getIndividualReportSettings(exportData: TExportData): TSectionVisibilitySettings {
    return {
      showExportBy: exportData.showExportBy,
      showSiteMap: exportData.showSiteMap,
      showAssignees: exportData.showAssignees,
      showCreatedBy: exportData.showCreatedBy,
      showSignature: exportData.showSignature,
      showActivities: exportData.showActivities,
      showDocuments: exportData.showDocuments,
      showFlagged: exportData.showFlagged,
    };
  }

  private getSimpleListSettings(exportData: TExportData): TSectionVisibilitySettings {
    return {
      showSignature: exportData.showSignature,
      showUrl: exportData.showUrl,
    };
  }
}
