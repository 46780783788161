<div class="notification__image-wrapper">
  <img class="notification__image" [src]="EIconPath.NOTIFICATION_ICON_TAGS" />
</div>

<ng-container
  *ngIf="
    (ppNotification.changeBody.newValue &&
      ppNotification.changeBody.newValue.length > ppNotification.changeBody.oldValue.length) ||
    !ppNotification.changeBody.oldValue
  "
>
  <span>
    <span class="notification__blue-text">{{ 'added' | ppTranslate }}</span>
    {{ 'the_following' | ppTranslate }}
    <span class="notification__bold">{{ ppNotification.changeBody.label }}</span>
    {{ 'to_a_point_you' | ppTranslate }}
    {{ notificationReason }}:
  </span>

  <div class="notification__tags">
    <ng-container *ngFor="let tag of ppNotification.changeBody.newValue">
      <pp-tag *ngIf="!ppNotification.changeBody?.oldValue.includes(tag)" [ppText]="tag"></pp-tag>
    </ng-container></div
></ng-container>

<ng-container
  *ngIf="
    (ppNotification.changeBody.oldValue &&
      ppNotification.changeBody.oldValue.length > ppNotification.changeBody.newValue.length) ||
    !ppNotification.changeBody.newValue
  "
>
  <span>
    <span class="notification__blue-text">{{ 'removed' | ppTranslate }}</span>
    {{ 'the_following' | ppTranslate }}
    <span class="notification__bold">{{ ppNotification.changeBody.label }}</span>
    {{ 'from_a_point_you' | ppTranslate }}
    {{ notificationReason }}:
  </span>

  <div class="notification__tags">
    <ng-container *ngFor="let tag of ppNotification.changeBody.oldValue">
      <pp-tag *ngIf="!ppNotification.changeBody?.newValue.includes(tag)" [ppText]="tag"></pp-tag>
    </ng-container>
  </div>
</ng-container>
