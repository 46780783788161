export const API_notifications_filters = (
  mode: string,
  offset: number,
  accountFilters: string,
  siteFilters: string,
): string => {
  let url = `/api/v1/push-notifications/${mode}?offset=${offset}`;

  if (accountFilters) {
    url += `&accountId=${accountFilters}`;
  }

  if (siteFilters) {
    url += `&workspaceId=${siteFilters}`;
  }

  return url;
};

export const API_notifications_timestamp = (
  mode: string,
  timeSinceLastSync: number,
  accountFilters: string,
  siteFilters: string,
): string => {
  let url = `/api/v1/push-notifications/${mode}?timestamp=${timeSinceLastSync}`;

  if (accountFilters) {
    url += `&accountId=${accountFilters}`;
  }

  if (siteFilters) {
    url += `&workspaceId=${siteFilters}`;
  }

  return url;
};

export const API_notifications_mark_as_read = (notificationId: string, status: boolean): string =>
  `/api/v1/push-notifications/${notificationId}?markAsRead=${status}`;
export const API_notifications_mark_as_commented = (notificationId: string): string =>
  `/api/v1/push-notifications/${notificationId}?markAsRead=true&markAsAnswered=true`;
export const API_notifications_update_unread = (): string =>
  '/api/v1/push-notifications/user-unread-status';
export const API_notifications_mark_all_as_read = (): string =>
  '/api/v1/push-notifications?markAllAsRead=true';
export const API_notifications_notification = (notificationId: string): string =>
  `/api/v1/push-notifications/${notificationId}`;
export const API_notification_subscribe = (_id: string, status: boolean): string =>
  `/api/v1/push-notifications/object-subscriptions/${_id}?subscribe=${status}`;
export const API_NOTIFICATIONS = (): string => `/api/v1/push-notifications`;
