<div class="assetCard__toggleContainer">
  <div class="assetCard__toggleButtonContainer">
    <button
      class="toggle-button details"
      (click)="toggleDetails()"
      [ngClass]="detailsSelected ? 'active' : ''"
    >
      <div>{{ 'details' | ppTranslate }}</div>
    </button>
  </div>
  <div class="assetCard__toggleButtonContainer">
    <button
      class="toggle-button labels"
      (click)="toggleLabels()"
      [ngClass]="detailsSelected ? '' : 'active'"
    >
      <div>{{ 'labels' | ppTranslate }}</div>
    </button>
  </div>
</div>
