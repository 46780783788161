import { cloneDeep } from 'lodash';
import { UpdatePoint } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';

export function updatePoint(oldState: TPointsByWorkspace, action: UpdatePoint): TPointsByWorkspace {
  const state = cloneDeep(oldState);
  let pointIndex = null;

  if (state[action.payload.workspaceId]) {
    pointIndex = state[action.payload.workspaceId].entities.findIndex(
      (_point) => _point._id === action.payload.point._id,
    );

    if (pointIndex > -1) {
      state[action.payload.workspaceId].entities.splice(pointIndex, 1, action.payload.point);

      return state;
    }

    state[action.payload.workspaceId].entities = [
      ...state[action.payload.workspaceId].entities,
      action.payload.point,
    ];
  } else {
    if (!state[action.payload.workspaceId]) {
      state[action.payload.workspaceId] = {};
    }

    state[action.payload.workspaceId].entities = [action.payload.point];
  }

  return state;
}
