import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SortingService } from '@core/services';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, finalize, takeUntil, tap } from 'rxjs/operators';
import { getInverseOfTranslation } from 'src/app/core/helpers/get-inverse-of-translation';
import { PreviousPageService } from 'src/app/core/services/previous-page.service';
import { UserService } from 'src/app/project/modules/user/user.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { TAccountUsage, TWorkspaceUsage } from '../../../account-usage/account-usage.consts';
import { AccountUsageService } from '../../../account-usage/account-usage.service';
import { TAccount } from '../../../account.model';
import { AccountSitesService } from './account-sites.service';

@Component({
  selector: 'pp-account-settings-sites',
  templateUrl: './account-settings-sites.component.html',
  styleUrls: ['./account-settings-sites.component.scss'],
})
export class AccountSettingsSitesComponent implements OnDestroy {
  @ViewChild(CdkVirtualScrollViewport, { static: false }) public viewPort: CdkVirtualScrollViewport;

  accountId: string;
  account: TAccount;

  private destroy$ = new Subject<void>();
  usage: TAccountUsage;
  private accounts$: Observable<TAccount[]>;
  processing = true;
  showArchivedData = false;
  canSeeArchived = false;
  archivedSitesCount = 0;
  visibleSites: TWorkspaceUsage[] = [];
  private archivedSites: TWorkspaceUsage[] = [];
  private activeSites: TWorkspaceUsage[] = [];
  private searchKeywordDebounceTime: number = 500;
  unfilteredSites: TWorkspaceUsage[] = [];
  unarchivedSitesCount: number = 0;

  constructor(
    private store: Store<{
      accounts: TAccount[];
    }>,

    private router: Router,
    private accountUsageService: AccountUsageService,
    private activeService: ActiveService,
    private previousPageService: PreviousPageService,
    private userService: UserService,
    private sortingService: SortingService,
    private accountSitesService: AccountSitesService,
  ) {
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));

    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
      if (accounts.length > 0) {
        this.accountId = this.activeService.getActiveAccountId();
        this.account = accounts.find((account) => account.accountId === this.accountId);
        this.canSeeArchived = this.userService.getUser().userId === this.account.accountOwnerId;

        this.processing = true;

        if (this.accountId) {
          this.fetchUsage(false);
        }
      }
    });

    this.accountSitesService.siteSearchKeywordChange$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(this.searchKeywordDebounceTime),
        tap(() => {
          this.visibleSites = this.searchVisibleSites(
            this.unfilteredSites,
            this.accountSitesService.getKeyword(),
          );
        }),
      )
      .subscribe();

    this.accountSitesService.showArchivedChange$
      .pipe(
        takeUntil(this.destroy$),
        tap((showArchived) => {
          this.showArchivedData = showArchived;

          this.prepareWorkspaces();
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.accountSitesService.setKeyword(null);
    this.accountSitesService.clearSelectedSites();
  }

  navigateToSite(workspaceId: string): void {
    this.previousPageService.setPreviousSiteUrl(this.router.url);
    this.router.navigate(['/settings/site', workspaceId, 'users']);
  }

  refreshSiteData(): void {
    this.fetchUsage(false);
  }

  public get inverseOfTranslation(): string {
    return getInverseOfTranslation(this.viewPort);
  }

  private fetchUsage(refetch: boolean): void {
    this.accountUsageService
      .fetchAccountUsage(this.accountId, refetch)
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          this.usage = response;

          this.countArchivedSites();
          this.countUnarchivedSites();
          this.prepareWorkspaces();
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  private countArchivedSites(): void {
    this.archivedSitesCount = Object.values(this.usage).filter((value) => value.archived).length;
  }

  private countUnarchivedSites(): void {
    this.unarchivedSitesCount = Object.values(this.usage).filter((value) => !value.archived).length;
  }

  private splitArchivedSites(): void {
    this.archivedSites = Object.values(this.usage).filter((value) => value.archived);
    this.activeSites = Object.values(this.usage).filter((value) => !value.archived);
  }

  private prepareWorkspaces(): void {
    const keyword = this.accountSitesService.getKeyword();

    this.splitArchivedSites();

    const visibleSites = this.showArchivedData
      ? this.activeSites.concat(this.archivedSites)
      : this.activeSites;

    this.unfilteredSites = this.sortSites(visibleSites);

    this.visibleSites = this.searchVisibleSites(this.unfilteredSites, keyword);
  }

  private sortSites(visibleSites: TWorkspaceUsage[]): TWorkspaceUsage[] {
    return visibleSites.sort((a, b) => {
      if (a.archived && !b.archived) {
        return -1;
      }

      if (!a.archived && b.archived) {
        return 1;
      }

      return this.sortingService.naturalSort(a.siteName, b.siteName);
    });
  }

  private searchVisibleSites(visibleSites: TWorkspaceUsage[], keyword: string): TWorkspaceUsage[] {
    if (!keyword) {
      return visibleSites;
    }

    return visibleSites.filter((site) => {
      const siteName = site.siteName;
      return siteName.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
    });
  }
}
