<pp-bulk-changes-bar
  [ppSelectedNumber]="selectedShares.length"
  *ngIf="selectedShares.length > 0"
  (ppClose)="deselectAll()"
>
  <pp-bulk-changes-button
    [ppIconPath]="EIconPath.ICON_BIN_24"
    [ppText]="'remove' | ppTranslate"
    (ppAction)="showDeleteSharesModal()"
  ></pp-bulk-changes-button>
</pp-bulk-changes-bar>
