import { createElement } from 'src/app/core/helpers/dom';
import { TPoint } from 'src/app/project/modules/points/points.model';
import { TColumn } from '../../../columns/column.model';
import { createTotalCustomFieldCellInnerElement } from '../../../custom-table/table-footer/utils/create-footer-custom-field-cell-inner-element';

export function createGroupedHeaderCellInnerElement(
  child: HTMLElement,
  column: TColumn,
  points: TPoint[],
): HTMLElement {
  if (column) {
    return createTotalCustomFieldCellInnerElement(child, column, points);
  } else {
    const cellInnerElement = createElement<HTMLElement>('div', {
      attrs: {
        class: 'table__cell__inner table__head__cell',
      },
      children: [
        createElement<HTMLElement>('span', {
          children: [child],
        }),
      ],
    });

    return cellInnerElement;
  }
}
