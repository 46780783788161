import { TDashletGraph } from '../../dashlet-graph.model';
import { generateDoughnutGraphOptions } from './doughnut-graph-options';

export function createCurrentPriorityGraph(isMobile, isExport): TDashletGraph {
  return {
    type: 'doughnut',
    data: {
      labels: ['High', 'Medium', 'Low'],
      datasets: [
        {
          data: [],
          backgroundColor: ['#e54f50', '#ffce29', '#4da0e5'],
        },
      ],
    },
    options: generateDoughnutGraphOptions(isMobile, isExport),
  };
}
