import { AngularSvgIconModule } from 'angular-svg-icon';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { ServiceWorkerModule } from '@angular/service-worker';

import { SentryErrorHandler } from './project/services/sentry-error-handler';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { activeReducer } from './project/services/active/active.reducer';

import { AppComponent } from './app.component';
import { AuthModule } from './project/modules/auth/auth.module';
import { LayoutModule } from './project/modules/layout/layout.module';
import { UiModule } from './project/modules/ui/ui.module';
import { UserModule } from './project/modules/user/user.module';
import { UsersModule } from './project/modules/users/users.module';
import { WorkspaceModule } from './project/modules/workspace/workspace.module';

import { appRoutes } from './app.routing';

import { AccountModule } from './project/modules/account/account.module';
import { CustomFieldsModule } from './project/modules/custom-fields/custom-fields.module';
import { DashboardModule } from './project/modules/dashboard/dashboard.module';
import { FiltersModule } from './project/modules/filters/filters.module';
import { NotificationsModule } from './project/modules/notifications/notifications.module';
import { OfflineModule } from './project/modules/offline/offline.module';
import { PlanModule } from './project/modules/plan/plan.module';
import { PointsModule } from './project/modules/points/points.module';
import { PreferencesModule } from './project/modules/preferences/preferences.module';
import { RemindersModule } from './project/modules/reminders/reminders.module';
import { ShareModule } from './project/modules/share/share.module';
import { SiteModule } from './project/modules/site/site.module';
import { SupportedBrowsersModule } from './project/modules/supported-browsers/supported-browsers.module';
import { TagsModule } from './project/modules/tags/tags.module';
import { WhiteLabelModule } from './project/modules/white-label/white-label.module';

import { ResponseErrorService } from './project/modules/errors/response-error.service';
import { ActiveService } from './project/services/active/active.service';
import { TokenInterceptor } from './project/services/interceptors/csrf.token.interceptor';
import { ReactionsService } from './project/services/reactions.service';

import { environment } from '../environments/environment';
import { PipesModule } from './core/pipes/pipes.module';
import { ButtonModule } from './project/components/button/button.module';
import { ConfirmModalModule } from './project/components/confirm-modal/confirm-modal.module';
import { DropdownModule } from './project/components/dropdown/dropdown.module';
import { EditNameModalModule } from './project/components/edit-name-modal/edit-name-modal.module';
import { GalleryOverlayModule } from './project/components/gallery-overlay/gallery-overlay.module';
import { ModalModule } from './project/components/modal/modal.module';
import { PromptModule } from './project/components/prompt/prompt.module';
import { AutonumericModule } from './project/directives/autonumeric/autonumeric.module';
import { FocusModule } from './project/directives/focus/focus.module';
import { ImagesFallbackModule } from './project/directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from './project/directives/on-rendered/on-rendered.module';
import { SwitchCasesModule } from './project/directives/switch-cases/switch-cases.module';
import { TooltipsModule } from './project/features/tooltip/tooltips.module';
import { AccountSitesArchiveModule } from './project/modules/account/account-settings/account-settings-pages/account-settings-sites/account-sites-archive-modal/account-sites-archive-modal.module';
import { ExportsModule } from './project/modules/exports/exports.module';
import { FleetManagementModule } from './project/modules/fleet-management/fleet-management.module';
import { SocketModule } from './project/modules/sockets/socket/socket.module';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule.forRoot(appRoutes, { useHash: true }),
    StoreDevtoolsModule.instrument({
      maxAge: 100, // Retains last 100 states
      trace: true,
    }),
    StoreModule.forFeature('active', activeReducer),
    AuthModule,
    OfflineModule,
    WorkspaceModule,
    UiModule,
    UserModule,
    UsersModule,
    LayoutModule,
    SiteModule,
    ShareModule,
    ModalModule,
    ConfirmModalModule,
    AccountSitesArchiveModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    PointsModule,
    ExportsModule,
    TagsModule,
    DropdownModule,
    AccountModule,
    PromptModule,
    PlanModule,
    CustomFieldsModule,
    DashboardModule,
    FleetManagementModule,
    RemindersModule,
    WhiteLabelModule,
    GalleryOverlayModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled:
        environment.production ||
        environment.clone ||
        environment.clone2 ||
        environment.release ||
        environment.clone3 ||
        environment.ecs,
    }),
    PreferencesModule,
    SupportedBrowsersModule,
    FiltersModule,
    NotificationsModule,
    SocketModule,
    ButtonModule,
    PipesModule,
    AngularSvgIconModule.forRoot(),
    EditNameModalModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    ResponseErrorService,
    ActiveService,
    ReactionsService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
