import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import { TPin, TPolygon } from 'src/app/project/view-models/point-response.model';

export const getPlanPointPinsCoordinates = (planPointPins: TPin[]): TPin[] => {
  const pins: TPin[] = [];

  if (planPointPins) {
    planPointPins.forEach((feature) => {
      const featureCoordinates = (
        (feature as unknown as Feature).getGeometry() as Point
      ).getCoordinates();

      if (featureCoordinates[0]) {
        pins.push({
          x: Math.abs(featureCoordinates[0]),
          y: Math.abs(featureCoordinates[1]),
        });
      }
    });
  }

  return pins;
};

export const getPlanPointPolygonsCoordinates = (planPointPolygons: TPolygon[]): TPolygon[] => {
  const polygons: TPolygon[] = [];

  planPointPolygons.forEach((polygon: any) => {
    const coordinates = polygon.getGeometry().getCoordinates();

    for (let i = 0; i < coordinates[0].length; i++) {
      for (let j = 0; j < coordinates[0][i].length; j++) {
        coordinates[0][i][j] = +coordinates[0][i][j].toFixed(4);
      }
    }

    polygons.push(coordinates);
  });

  return polygons;
};
