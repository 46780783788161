import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TWorkspaceUser } from '@project/view-models';
import { TAccountUser } from 'src/app/project/modules/users/account.user.model';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-user-management-user-row',
  templateUrl: './user-management-user-row.component.html',
  styleUrls: ['./user-management-user-row.component.scss'],
})
export class UserManagementUserRowComponent implements OnChanges {
  @Input() ppExpanded: boolean;
  @Input() ppUser: TAccountUser;
  @Input() ppAccountId: string;
  @Output() ppExpandedChange = new EventEmitter<boolean>();

  EIconPath = EIconPath;
  user: TWorkspaceUser;

  constructor(private usersService: UsersService) {}

  ngOnChanges(): void {
    this.setUser();
  }

  toggleExpand(): void {
    this.ppExpandedChange.emit(!this.ppExpanded);
  }

  private setUser(): void {
    this.user = this.usersService.getUser(this.ppUser.userId);
  }
}
