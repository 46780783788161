import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { Subject } from 'rxjs';

import { ListenerService, MODAL_ESC } from '../../../core/helpers/listener.service';
import { EIconPath } from '../../shared/enums/icons.enum';
import { Modal, ModalService } from './modal.service';

@Component({
  selector: 'pp-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnChanges, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @ViewChild('modalBody', { static: true }) modalBodyEl: ElementRef;

  @Input() ppWide: boolean;
  @Input() ppDisableScroll: boolean;
  @Input() ppNoPadding: boolean;
  @Input() ppScrollOnDefault: boolean;
  @Input() ppNoFooter: boolean;
  @Input() ppWidth: number;
  @Input() ppThin = false;
  @Input() ppCloseOnClickOutside = false;
  @Input() ppScrollX = false;
  @Input() ppFooterShadow = false;
  @Input() ppHideClose = false;
  @Input() zIndex: number;

  modal: Modal;
  needsScroll = false;
  scrolledToBottom = true;
  private scrollListenerRef = null;
  private containerResizeObserver: ResizeObserver;
  EIconPath = EIconPath;

  constructor(private listenerService: ListenerService, private modalService: ModalService) {}

  ngOnInit() {
    this.modal = this.modalService.getModal();
    this.scrollListenerRef = (event): void => this.scrollHandler(event.target);

    this.initContainerHeightObserver();
  }

  ngOnChanges(): void {
    this.updateScrollState();
  }

  ngOnDestroy() {
    this.listenerService.remove(MODAL_ESC);
    if (this.containerResizeObserver) {
      this.containerResizeObserver.disconnect();
    }
    this.modalService.clearModalBaseElement();
    this.destroy$.next();
  }

  scrollHandler(target: HTMLElement): void {
    this.scrolledToBottom = target.scrollHeight - target.scrollTop - target.clientHeight < 2;
  }

  hide(clickedOutside: boolean = false): void {
    this.modalService.hideModal(true, clickedOutside);
  }

  hideFromClickOutside(): void {
    if (this.ppCloseOnClickOutside) {
      this.hide(false);
    }
  }

  private updateScrollState(): void {
    this.needsScroll =
      ((this.modalBodyEl &&
        this.modalBodyEl.nativeElement.scrollHeight >
          this.modalBodyEl.nativeElement.offsetHeight) ||
        this.ppScrollOnDefault) &&
      !this.ppDisableScroll;

    this.scrolledToBottom =
      this.modalBodyEl.nativeElement.scrollHeight -
        this.modalBodyEl.nativeElement.scrollTop -
        this.modalBodyEl.nativeElement.clientHeight <
      2;
  }

  onRendered(): void {
    this.listenerService.add(MODAL_ESC, () => this.hide());
    this.modalBodyEl.nativeElement.addEventListener('scroll', this.scrollListenerRef);

    this.modal.visible = true;
  }

  private initContainerHeightObserver(): void {
    let prevHeight: number;

    this.containerResizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (!prevHeight || entry.contentRect.height !== prevHeight) {
          prevHeight = entry.contentRect.height;

          this.updateScrollState();
        }
      }
    });
    this.containerResizeObserver.observe(this.modalBodyEl.nativeElement);
  }

  onModalBaseRendered($event: HTMLElement) {
    this.modalService.setModalBaseElement($event);
  }
}
