import { pressKey, KEY_ESC, KEY_LEFT, KEY_RIGHT, KEY_DELETE } from '@core/helpers';

import { Injectable } from '@angular/core';
import { WindowService } from '@core/services';

export const MODAL_ESC = 'MODAL_ESC';
export const GALLERY_OVERLAY_ESC = 'GALLERY_OVERLAY_ESC';
export const GALLERY_OVERLAY_LEFT = 'GALLERY_OVERLAY_LEFT';
export const GALLERY_OVERLAY_RIGHT = 'GALLERY_OVERLAY_RIGHT';
export const IMAGE_ANNOTATIONS_DELETE = 'IMAGE_ANNOTATIONS_DELETE';

type TListener = {
  func: (event: KeyboardEvent) => void;
  type: string;
};

@Injectable({
  providedIn: 'root',
})
export class ListenerService {
  listeners: TListener[] = [];

  private window = this.windowService.getGlobalObject();

  constructor(private windowService: WindowService) {}

  checkForExistingListener(type: string): boolean {
    if (this.listeners.find((listener: TListener) => listener.type === type)) {
      this.remove(type);

      return true;
    }

    return false;
  }

  add(type: string, callback: () => void): void {
    this.checkForExistingListener(type);

    let key = KEY_ESC;

    if (type === GALLERY_OVERLAY_LEFT) {
      key = KEY_LEFT;
    } else if (type === GALLERY_OVERLAY_RIGHT) {
      key = KEY_RIGHT;
    } else if (type === IMAGE_ANNOTATIONS_DELETE) {
      key = KEY_DELETE;
    }

    const listener: TListener = {
      func: (event: KeyboardEvent) =>
        pressKey(event, key, (): void => {
          callback();
        }),
      type,
    };

    this.listeners.push(listener);

    const listenerIndex = this.listeners.length - 1;

    this.window.addEventListener('keydown', this.listeners[listenerIndex].func, true);
  }

  remove(type: string): void {
    const listenerIndex = this.listeners.findIndex((listener: TListener) => listener.type === type);

    if (listenerIndex >= 0 && this.listeners[listenerIndex] && this.listeners[listenerIndex].func) {
      this.window.removeEventListener('keydown', this.listeners[listenerIndex].func, true);
      this.listeners.splice(listenerIndex, 1);
    }
  }
}
