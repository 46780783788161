import { Component, Input, OnInit } from '@angular/core';
import { SavedViewsService } from '../saved-views.service';
import { TSavedView } from '../models/saved-view.model';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-active-view-preview',
  templateUrl: './active-view-preview.component.html',
  styleUrls: ['./active-view-preview.component.scss'],
})
export class ActiveViewPreviewComponent implements OnInit {
  @Input() ppWorkspaceId: string;
  @Input() ppHideText: boolean;
  activeView: TSavedView;

  EIconPath = EIconPath;

  constructor(private savedViewsService: SavedViewsService) {}

  ngOnInit(): void {
    this.activeView = this.savedViewsService.getView(
      this.savedViewsService.getSelectedViewId(this.ppWorkspaceId),
    );
  }
}
