import { Component, OnInit, OnDestroy } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TUISettings } from '../../ui/ui.model';

import { RemindersService } from '../reminders.service';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { EIconPath } from '../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-reminder-alert',
  templateUrl: './reminder-alert.component.html',
  styleUrls: ['./reminder-alert.component.scss'],
})
export class ReminderAlertComponent implements OnInit, OnDestroy {
  remindersNumber = 1;
  ui: TUISettings;

  private readonly destroy$ = new Subject<void>();
  private ui$: Observable<TUISettings>;
  EIconPath = EIconPath;

  constructor(
    private store: Store<{ ui: TUISettings }>,
    private remindersService: RemindersService,
  ) {
    this.ui$ = this.store.pipe(select(EStore.UI));
  }

  ngOnInit(): void {
    this.ui$.pipe(takeUntil(this.destroy$)).subscribe((ui) => {
      this.ui = ui;

      this.remindersNumber = this.remindersService.getRemindersNumber().number;
    });
  }

  closePanel(redirect: boolean = false): void {
    this.remindersService.hideRemindersPopup(redirect);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
