<section class="dashletFlip__banner" *ngIf="ppLimitedWorkspaces">
  <pp-icon [ppSrc]="EIconPath.ICON_BANNER_INFO_18" ppColor="grey-700" ppClass="setSize18"></pp-icon>

  {{ 'asset_workspaces_limit_info' | ppTranslate }}
</section>

<section class="dashletFlip__header">
  <h6 class="dashletFlip__title">
    {{ 'select_site' | ppTranslate | uppercase }}
  </h6>

  <p class="dashletFlip__section-description">
    {{ 'choose_sites_data_display' | ppTranslate }}
  </p>
</section>

<ul class="dashletFlip__accounts">
  <li class="dashletFlip__account-wrapper" (click)="clearSiteRange()" *ngIf="!ppLimitedWorkspaces">
    <pp-checkbox
      [ppChecked]="!ppSiteRange.workspaceIds.length && !ppSiteRange.accountId"
    ></pp-checkbox>

    <p class="dashletSiteacc__acc-label">{{ 'all_accounts_sites' | ppTranslate }}</p>
  </li>

  <ng-container *ngIf="filteredAccounts?.length > 0">
    <li
      class="dashletFlip__account"
      [ngClass]="{
        'dashletFlip__account--active':
          account.accountId === ppSiteRange.accountId && !ppSiteRange.workspaceIds.length,
      }"
      *ngFor="let account of filteredAccounts"
    >
      <span class="dashletFlip__account-wrapper" (click)="toggleAccount(account.accountId)">
        <pp-checkbox
          [ppChecked]="ppSiteRange.accountId === account.accountId"
          [ppDisabled]="ppLimitedWorkspaces"
          *ngIf="!ppLimitedWorkspaces"
        ></pp-checkbox>

        <p
          class="dashletSiteacc__acc-label"
          [class.dashletSiteacc__acc-label--disabled]="ppLimitedWorkspaces"
        >
          {{ account.accountName }} - {{ 'all_sites' | ppTranslate }}
        </p>
      </span>

      <pp-dashlet-timeline-account-multiple
        [ppAccount]="account"
        [ppLimitedWorkspaces]="ppLimitedWorkspaces"
        [ppDashlet]="ppDashlet"
        [ppSiteRange]="ppSiteRange"
        [ppBlockedTimelines]="disabledWorkspaces"
        [ppDefaultWorkspaces]="ppDefaultWorkspaces"
        [ppWorkspaces]="workspaces"
        (ppUpdateSiteRange)="updateSiteRange($event)"
      ></pp-dashlet-timeline-account-multiple>
    </li>
  </ng-container>
</ul>
