import { cloneDeep } from 'lodash';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { of, Subject } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';

import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { TShare, TUpdateShareDTO } from '../share.model';

import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { Modal, ModalService } from '../../../components/modal/modal.service';
import { PromptService } from '../../../components/prompt/prompt.service';
import { CustomFieldsService } from '../../custom-fields/custom-fields.service';
import { SharesService } from '../shares.service';

import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { ChangeLimitModalComponent } from 'src/app/project/components/change-limit-modal/change-limit-modal.component';
import { TChangeLimitsModalData } from 'src/app/project/components/change-limit-modal/change-limit-modal.model';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSettings,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EUserRole } from '../share-utils/user-roles';
import { generateShare } from '../share.utils';
import { checkRequestChangedAAL } from './check-changed-aal';

export type TEditShareModalData = {
  share: TShare;
  workspaceId: string;
};
@Component({
  selector: 'pp-edit-share-modal',
  templateUrl: './edit-share-modal.component.html',
  styleUrls: ['../share.modal.scss'],
})
export class EditShareModalComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly shouldCloseModal = new Subject<boolean>();

  customFields = this.customFieldsService.getCustomFields();
  modal: Modal = this.modalService.getModal();

  processingShare = false;
  originalShare: TShare;
  tagError = false;

  workspace: TWorkspace = null;
  share: TShare;

  private workspaceId: string;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private promptService: PromptService,
    private sharesService: SharesService,
    private workspaceService: WorkspaceService,
    private customFieldsService: CustomFieldsService,
    private translationPipe: TranslationPipe,
  ) {}

  ngOnInit() {
    const modalData: TEditShareModalData = this.modal.data;

    this.workspaceId = modalData.workspaceId;
    this.workspace = this.workspaceService.getWorkspace(this.workspaceId);

    this.share = cloneDeep(modalData.share);
    this.originalShare = cloneDeep(modalData.share);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  createShare(isFromChangeLimitsModal: boolean = false): void {
    if (!this.processingShare) {
      this.processingShare = true;

      const body = this.createRequestBody();

      if (this.share.tagLimited && this.share.defectTags.length === 0) {
        this.processingShare = false;
        this.tagError = true;

        const prompt = this.translationPipe.transform('share_no_tags_error');

        this.promptService.showWarning(prompt);

        return;
      }

      this.sharesService
        .updateShare(this.share.shareId, body)
        .pipe(
          tap((response) => {
            const newShare = generateShare(response, this.workspaceId, this.workspace);
            const promptText = this.translationPipe.transform('prompt_share_level_edit');

            logEventInGTAG(EGoogleEventSettings.SETTINGS__USER__EDIT, {
              event_category: EGoogleEventCategory.SETTINGS,
            });

            this.processingShare = false;

            if (this.router.url.startsWith('/settings/site/')) {
              this.sharesService.updateWorkspaceShare(this.share.userId, newShare);
            }

            this.promptService.showSuccess(promptText);
            // checking if the method has been called from the other component
            if (isFromChangeLimitsModal) {
              this.shouldCloseModal.next(true);
            } else {
              this.modalService.hideModal();
            }
          }),
          catchError((error) => {
            this.processingShare = false;
            this.shouldCloseModal.next(false);

            if (error.status === EStatusCode.BAD_REQUEST) {
              const promptText = this.translationPipe.transform(
                'prompt_share_error_already_shared',
              );

              this.promptService.showWarning(promptText);
            } else if (error.status === EStatusCode.UPGRADE_REQUIRED) {
              this.showEditUserLimitModal();
              return;
            } else {
              const promptText = this.translationPipe.transform('prompt_share_error');

              this.promptService.showError(promptText);
            }

            return of(null);
          }),
        )
        .subscribe();
    }
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  createRequestBody(): TUpdateShareDTO {
    const body: TUpdateShareDTO = {};

    const aalIsChanged = checkRequestChangedAAL(this.share, this.originalShare);

    if (this.share.shareOption !== this.originalShare.shareOption) {
      body.shareOption = this.share.shareOption;
    }

    if (this.share.tagLimited !== this.originalShare.tagLimited) {
      body.tagLimited = this.share.tagLimited;
    }

    if (this.share.defectTags !== this.originalShare.defectTags) {
      body.defectTags = this.share.defectTags;
    }

    if (aalIsChanged) {
      body.advancedAccessLevels = this.share.advancedAccessLevels;
    }

    return body;
  }

  private showEditUserLimitModal(): void {
    const modalData: TChangeLimitsModalData = {
      firstMessageKey: 'users_limit_reached_description_1',
      secondMessageKey: 'users_limit_reached_description_2',
      confirmKey: 'edit_user',
      header: 'users_limit_reached',
      accountId: this.workspace.accountId,
    };

    modalData.limitsToIncrease = this.sharesService.addLimitToIncrease(
      this.share.shareOption as EUserRole,
    );

    this.modalService.hideModal().then(() => {
      this.modalService.setData<TChangeLimitsModalData>(modalData);

      this.modalService.showModal(ChangeLimitModalComponent, {
        onBeforeClose: (cancelled) => this.getCreateShareResult(cancelled),
      });
    });
  }

  getCreateShareResult(cancelled: boolean): Promise<boolean> {
    if (!cancelled) {
      this.createShare(true);

      return this.shouldCloseModal.pipe(takeUntil(this.destroy$), take(1)).toPromise();
    }

    return Promise.resolve(true);
  }
}
