import { Injectable } from '@angular/core';
import { PlanPointVariablesService } from './plan-point-variables.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureDragService {
  constructor(private planPointVariablesService: PlanPointVariablesService) {}

  featureDragOff(): void {
    const plan = this.planPointVariablesService.getPlan();

    plan.instance.point.removeInteraction(this.planPointVariablesService.getDragInteraction());
  }

  featureDragOn(): void {
    const plan = this.planPointVariablesService.getPlan();

    plan.instance.point.addInteraction(this.planPointVariablesService.getDragInteraction());
  }
}
