<pp-modal
  *ngIf="workspace"
  [ppScrollOnDefault]="true"
  [ppThin]="true"
  [ppWidth]="640"
  [ppCloseOnClickOutside]="false"
  [ppFooterShadow]="true"
>
  <ng-container heading>
    {{ 'add_user' | ppTranslate }}
  </ng-container>

  <ng-container body>
    <div>
      <pp-share-edit
        [(ppShare)]="share"
        [(ppEmails)]="shareEmails"
        [ppShowAdvanced]="false"
        [ppWorkspaceId]="workspaceId"
        [ppTagError]="tagError"
        [ppEdit]="false"
      ></pp-share-edit>
    </div>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'save' | ppTranslate"
      [ppProcess]="processingShare"
      (ppCancel)="hideModal()"
      (ppConfirm)="createShare()"
      [ppDisabled]="!shareEmails.length || !share.shareOption"
      ppCancelDataTarget="Add New User Modal Close Button"
      ppConfirmDataTarget="Invite user button"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
