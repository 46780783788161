import { Component, Input, OnInit } from '@angular/core';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { TPushNotification } from '../../notification.model';
import { getNotificationReasonText } from '../../utils/notification-reason';
import { TAllUsers } from '@project/view-models';
import { EIconPath } from '../../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-notification-assignee',
  templateUrl: './notification-assignee.component.html',
  styleUrls: ['../notification.component.scss'],
})
export class NotificationAssigneeComponent implements OnInit {
  @Input() ppNotification: TPushNotification<string[]>;
  @Input() ppActiveUserId: string;

  allUsers: TAllUsers;
  notificationReason: string;

  assignedYou = false;
  isNewValue = false;
  isOldValue = false;
  EIconPath = EIconPath;

  constructor(private usersService: UsersService) {}

  ngOnInit(): void {
    this.allUsers = this.usersService.getUsers();

    this.notificationReason = getNotificationReasonText(this.ppNotification.notificationReason);

    this.checkAssignedUser();
    this.checkOldValue();
    this.checkNewValue();
  }

  checkNewValue(): void {
    this.isNewValue =
      (this.ppNotification.changeBody.newValue &&
        this.ppNotification.changeBody.newValue.length >
          this.ppNotification.changeBody.oldValue.length) ||
      !this.ppNotification.changeBody.oldValue;
  }

  checkOldValue(): void {
    this.isOldValue =
      (this.ppNotification.changeBody.oldValue &&
        this.ppNotification.changeBody.oldValue.length >
          this.ppNotification.changeBody.newValue.length) ||
      !this.ppNotification.changeBody.newValue;
  }

  checkAssignedUser(): void {
    this.assignedYou =
      this.ppNotification.changeBody.newValue.length -
        this.ppNotification.changeBody.oldValue.length ===
        1 &&
      this.ppNotification.changeBody.newValue.includes(this.ppActiveUserId) &&
      !this.ppNotification.changeBody.oldValue.includes(this.ppActiveUserId);
  }
}
