import { TAccount } from 'src/app/project/modules/account/account.model';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

export function checkRemindersEnabled(
  workspaces: TWorkspacesById,
  accounts: TAccount[],
  workspaceId: string,
): boolean {
  if (workspaces[workspaceId]) {
    const account = accounts.find(
      (_account) => _account.accountId === workspaces[workspaceId].accountId,
    );

    return account.accountFeatures.reminders;
  } else {
    let remindersEnabled = false;

    accounts.forEach((account) => {
      if (account.accountFeatures.reminders) {
        remindersEnabled = true;
      }
    });

    return remindersEnabled;
  }
}
