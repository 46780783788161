<pp-button
  class="auth__confirm2FAButton"
  ppStyle="primary"
  ppSize="large"
  ppId="logInBtn"
  [ppProcess]="ppProcessing"
  (ppAction)="confirm2FA()"
  [ppDisabled]="!isCodeCorrect"
>
  {{ '2fa_code_confirm' | ppTranslate }}
</pp-button>
