import { EUserRole } from '../share/share-utils/user-roles';
import { TWorkspacesById } from '../workspace/workspace.model';

export function checkIfHasRole(
  role: EUserRole,
  accountWorkspaces: string[],
  workspaces: TWorkspacesById,
): boolean {
  return accountWorkspaces?.some((workspaceId) => {
    const workspace = workspaces[workspaceId];

    return workspace?.share.shareOption === role;
  });
}
