<pp-button
  ppId="newReminderButton"
  data-m-target="New reminder button"
  (ppAction)="newReminder()"
  ppStyle="primaryRounded"
  ppSize="large"
>
  <span class="newReminder__button">
    <img [src]="EIconPath.PLUS" class="plusIcon" />
    <span class="newReminder__text"> {{ 'new_reminder' | ppTranslate }} </span>
  </span>
</pp-button>

<pp-site-filter-dropdown
  [ppDataFetched]="ppDataFetched"
  class="filterDropdown"
></pp-site-filter-dropdown>
