import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-two-factor-authentication-mode-picker-option',
  templateUrl: './two-factor-authentication-mode-picker-option.component.html',
  styleUrls: ['./two-factor-authentication-mode-picker-option.component.scss'],
})
export class TwoFactorAuthenticationModePickerOptionComponent {
  @Input() ppIcon: EIconPath;
  @Input() ppText: string;
  @Input() ppSelected: boolean;

  @Output() ppSelect: EventEmitter<void> = new EventEmitter<void>();

  onValueChange(): void {
    this.ppSelect.emit();
  }
}
