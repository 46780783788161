import { Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { Polygon } from 'ol/geom';

export function getPolygonVerticeIndex(
  feature: Feature,
  polygonMoveStartCoordinates: Coordinate[],
): number {
  const coordinates = (feature.getGeometry() as Polygon).getCoordinates()[0];

  let index = null;

  for (let i = 0; i < coordinates.length; i++) {
    if (
      coordinates[i][0] !== polygonMoveStartCoordinates[i][0] ||
      coordinates[i][1] !== polygonMoveStartCoordinates[i][1]
    ) {
      index = i;

      break;
    }
  }

  return index;
}
