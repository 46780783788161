import { GET_SHARES } from 'src/app/project/modules/share/shares.store';
import { GET_USER } from 'src/app/project/modules/user/user.store';

import { createElement, TAnyFunction } from '@core/helpers';

import { GET_DRAGGED_SITE, SET_DRAGGED_SITE } from '../utils/account-list.ui.store';

import { ApiService } from '@core/http';
import { getSvg } from 'src/app/core/helpers/get-svg';
import Tooltip from 'src/app/project/features/tooltip/Tooltip';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { TFlattenedAccount } from '../account-list/account-list-flatten-account';
import { createEmptyElement } from '../utils/create-empty-element';
import { createDragIndicatorElement } from './create-drag-indicator-element';
import { createNameElement } from './create-name-element';
import { createSettingsTooltip } from './create-settings-tooltip';
import { createSiteTooltip } from './create-site-tooltip';
import { getClassList } from './get-class-list';

export default class Site {
  private site: TWorkspace;
  private siteSettingsCallback: TAnyFunction;
  private openSiteCallback: TAnyFunction;
  private folderId: string;
  private draggable: boolean;
  private addSiteToFolderCallback: TAnyFunction;
  private removeSiteFromFolderCallback: TAnyFunction;
  private dragoverCallback: TAnyFunction;
  private apiService: ApiService;

  public element: HTMLElement;
  private nameElement: HTMLElement;
  private dragIndicatorElement: HTMLElement;
  settingsTooltip: Tooltip;
  nameTooltip: Tooltip;
  private documentDragOverRef: TAnyFunction;

  private x = 0;
  private y = 0;

  constructor(
    _data: TFlattenedAccount,
    apiService: ApiService,
    {
      siteSettingsCallback,
      openSiteCallback,
      addSiteToFolderCallback,
      removeSiteFromFolderCallback,
      dragoverCallback,
    }: {
      siteSettingsCallback: TAnyFunction;
      openSiteCallback: TAnyFunction;
      addSiteToFolderCallback: TAnyFunction;
      removeSiteFromFolderCallback: TAnyFunction;
      dragoverCallback: TAnyFunction;
    },
  ) {
    this.site = _data.workspace;
    this.folderId = _data.folderId;
    this.draggable = _data.draggable;
    this.apiService = apiService;
    this.siteSettingsCallback = siteSettingsCallback;
    this.openSiteCallback = openSiteCallback;
    this.addSiteToFolderCallback = addSiteToFolderCallback;
    this.removeSiteFromFolderCallback = removeSiteFromFolderCallback;
    this.dragoverCallback = dragoverCallback;

    this.element = this.create();

    this.nameTooltip = createSiteTooltip(this.site, this.element, this.nameElement);

    // On Firefox we don't have access to event x and y in dragend event so that's a workaround
    this.documentDragOverRef = (event): void => {
      this.x = event.x;
      this.y = event.y;
    };
  }

  create(): HTMLElement {
    const classList = getClassList(this.site, this.folderId, this.draggable);

    this.dragIndicatorElement = createDragIndicatorElement(this.apiService);
    this.nameElement = createNameElement(this.folderId, this.site);

    const element = createElement('div', {
      attrs: {
        class: classList,
        'data-siteid': this.site.workspaceId,
        'data-account_id': this.site.accountId,
        'data-folder_id_site': this.folderId,
        id: `sidePanelSiteOpenButton${this.site.workspaceId}`,
      },
      eventListeners: {
        click: (_event) => {
          _event.stopPropagation();

          this.openSiteCallback(this.site.workspaceId);
        },
        dragstart: (_event) => {
          this.dragStartListener(_event);
        },
        dragover: (_event) => {
          this.dragoverCallbackListener(_event);
        },
        dragend: () => {
          this.dragendListener();
        },
      },
      children: [
        this.dragIndicatorElement,
        createElement('div', {
          attrs: {
            class: ['mainNav__name', 'siteNav__site-name'],
          },
          children: [this.nameElement],
        }),
        this.createSettingsElement(),
      ],
    });

    if (this.draggable) {
      // draggable=false still counts as draggable for HTML so we only add this property if needed
      element.setAttribute('draggable', true);
    }

    return element;
  }

  private dragendListener(): void {
    const targetElements = document.elementsFromPoint(this.x, this.y);
    let elementFound = false;

    window.removeEventListener('dragover', this.documentDragOverRef);

    for (const targetElement of targetElements) {
      let folderId = targetElement.getAttribute('data-folder_id');
      const accountId = targetElement.getAttribute('data-account_id');

      if (!folderId) {
        folderId = targetElement.getAttribute('data-folder_id_site');
      }

      if (folderId !== 'null' && this.site.accountId === accountId) {
        this.addSiteToFolderCallback(this.site.workspaceId, this.site.accountId, folderId);

        elementFound = true;

        return;
      }
    }

    if (!elementFound && this.folderId) {
      this.removeSiteFromFolderCallback(this.site.workspaceId, this.site.accountId);
    }

    SET_DRAGGED_SITE(null);

    this.dragoverCallback(null);
  }

  private dragoverCallbackListener(_event: Event): void {
    const draggedSite = GET_DRAGGED_SITE();

    if (draggedSite) {
      _event.preventDefault();

      if (this.folderId && this.site.accountId === draggedSite.accountId) {
        this.dragoverCallback(this.folderId);
      } else {
        this.dragoverCallback(null);
      }
    } else {
      this.dragoverCallback(null);
    }
  }

  private dragStartListener(_event: DragEvent): void {
    window.addEventListener('dragover', this.documentDragOverRef);

    const rect = this.dragIndicatorElement.getBoundingClientRect();
    const clickedInArea =
      _event.x > rect.left - 5 &&
      _event.x < rect.right + 5 &&
      _event.y > rect.top - 5 &&
      _event.y < rect.bottom + 5;

    if (clickedInArea) {
      SET_DRAGGED_SITE({
        workspaceId: this.site.workspaceId,
        accountId: this.site.accountId,
      });
    } else {
      _event.preventDefault();
      _event.stopPropagation();
    }
  }

  createSettingsElement(): HTMLElement {
    const user = GET_USER();
    const share = GET_SHARES().find((_share) => _share.workspaceId === this.site.workspaceId);

    if (
      (user.accountId && user.accountId === this.site.accountId) ||
      user.isSuperUser ||
      share?.shareOption === EUserRole.SITE_ADMIN ||
      share?.shareOption === EUserRole.ACCOUNT_ADMIN
    ) {
      const settingsElement = createElement('a', {
        attrs: {
          id: `sidePanelSiteSettingsDropdownButton${this.site.workspaceId}`,
          class: [
            'mainNav__options',
            'mainNav__option',
            'mainNav__option--site',
            'filterPanel__tooltip',
          ],
        },
        eventListeners: {
          click: (_event) => {
            _event.stopPropagation();
            this.siteSettingsCallback(settingsElement.id, this.site.workspaceId);
          },
        },
        children: [
          createElement('img', {
            attrs: {
              src: EIconPath.INPUT_COG_GRAY,
            },
          }),
        ],
      });

      getSvg(this.apiService, EIconPath.ICON_COG_GREY).then((svg) => {
        settingsElement.innerHTML = svg;
      });

      settingsElement.setAttribute('data-m-target', 'Side panel site settings button');
      this.settingsTooltip = createSettingsTooltip(settingsElement);

      return settingsElement;
    }

    return createEmptyElement();
  }
}
