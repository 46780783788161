<div class="sharesHeaders__access">
  <div class="editAccountUserShareList__shareHeadersAccessSelect">
    <pp-select
      [ppItems]="shareOptions"
      [ppSelectedItem]="shareOptions[index]"
      (ppSelectItem)="selectItem($event)"
      [ppNoSearchBar]="true"
      require
    >
      <ng-container selected>
        <span
          class="accountSettings__select-content"
          *ngIf="index !== -1"
          ppTooltip
          [ppTitle]="checkIfShareIsDefault() ? '' : ('user_access_customized' | ppTranslate)"
        >
          {{ shareOptions[index].label
          }}{{ checkIfShareIsDefault() ? '' : USER_MANAGEMENT_CUSTOMIZED_SHARE_MARK }}
        </span>
      </ng-container>
    </pp-select>
  </div>
</div>
