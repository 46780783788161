<article class="point__container" *ngIf="modal?.visible">
  <div class="point__backdrop">
    <div class="point__animation" *ngIf="workspaces && point">
      <pp-point-modal-header
        [ppAttachmentUploading]="attachmentUploading"
        [ppFull]="true"
        [ppPoint]="point"
        [ppPointId]="_id"
        [ppWorkspaceId]="workspaceId"
        [(ppPointDetails)]="pointDetails"
        (ppHide)="hide()"
        [ppSite]="site"
        [ppNew]="_id === NEW_POINT_ID"
        [ppDetailsFetched]="detailsFetched"
        (ppUpdate)="generatePoint(point)"
      ></pp-point-modal-header>

      <pp-point-container
        [ppNew]="_id === NEW_POINT_ID"
        [ppPointId]="_id"
        [ppSide]="isDesktopScreen && !!workspaces[point?.workspaceRef.id]?.siteImageRef"
        [ppSitePlan]="!!workspaces[point?.workspaceRef.id]?.siteImageRef"
        (click)="$event.stopPropagation()"
        (ppUpdateScrollPosition)="updateScrollPosition($event)"
        [ppFull]="true"
      >
        <ng-container header>
          <header>
            <pp-point-action-bar
              [ppNew]="_id === NEW_POINT_ID"
              [ppPointId]="_id"
              [ppWorkspaceId]="point?.workspaceRef.id"
              [ppCanEdit]="canEditPoint && !processingSave"
              [ppCanDelete]="canDeletePoint"
              [ppFull]="true"
              [ppUser]="user"
              [ppScrollPosition]="scrollPosition"
            ></pp-point-action-bar>
          </header>
        </ng-container>

        <ng-container body>
          <section>
            <pp-point-description
              [ppNew]="_id === NEW_POINT_ID"
              [ppPointId]="_id"
              [ppWorkspaceId]="point?.workspaceRef.id"
              [ppDescription]="point?.description"
              [ppDescriptionRich]="point?.descriptionRich"
              [ppCanEdit]="canEditPoint && !processingSave"
            ></pp-point-description>
          </section>

          <div class="point__custom-fields">
            <pp-point-fields
              [ppNew]="_id === NEW_POINT_ID"
              [ppPointId]="_id"
              [ppWorkspaceId]="point?.workspaceRef.id"
              [ppTags]="point?.tags"
              [ppCanEdit]="canEditPoint"
              [ppAssignees]="point?.assignees"
            ></pp-point-fields>
          </div>

          <section class="point__mobile-plan" *ngIf="!isDesktopScreen">
            <pp-point-plan
              ppModule="point"
              [ppPointId]="_id"
              [ppWorkspaceId]="point?.workspaceRef.id"
              [ppCanEdit]="canEditPoint && !processingSave"
            ></pp-point-plan>
          </section>

          <section data-m-target="Point attachments section">
            <pp-point-attachments
              *ngIf="!offline && point"
              [ppNew]="_id === NEW_POINT_ID"
              [ppWorkspaceId]="point?.workspaceRef.id"
              [ppPointId]="_id"
              [ppCanEdit]="canEditPoint && !processingSave"
              [ppFull]="true"
            ></pp-point-attachments>

            <p class="point__section--disabled" *ngIf="offline">
              {{ 'attachments_not_available_offline' | ppTranslate }}
            </p>
          </section>

          <section *ngIf="_id !== NEW_POINT_ID">
            <pp-point-activity
              *ngIf="!offline"
              [ppWorkspaceId]="point?.workspaceRef.id"
              [ppPointId]="_id"
              [ppCommentsEnabled]="commentsEditable && !offline && !!point"
              (ppScrollToComment)="setInitialScroll()"
              [ppDetailsFetched]="freshPointFetched"
            ></pp-point-activity>

            <p class="point__section--disabled" *ngIf="offline">
              {{ 'activity_not_available_offline' | ppTranslate }}
            </p>
          </section>
        </ng-container>

        <ng-container footer>
          <div
            data-m-target="Point comment input field"
            class="point__comment-input"
            [ngClass]="{ 'point__comment-input--iPhone': isMobile && isiPhone }"
            *ngIf="_id !== NEW_POINT_ID && commentsEditable && !offline"
          >
            <pp-point-comment-input
              [ppWorkspaceId]="point?.workspaceRef.id"
              [ppPointId]="_id"
            ></pp-point-comment-input>
          </div>

          <footer class="newPoint__footer" *ngIf="_id === NEW_POINT_ID">
            <pp-button
              ppId="cancelCreateNewPointBtn"
              ppStyle="text"
              (ppAction)="hide()"
              data-test="cancelButton"
            >
              {{ 'cancel' | ppTranslate }}
            </pp-button>

            <pp-button
              ppTooltip
              [ppTitle]="emptyTitle ? ('tooltip_title_empty' | ppTranslate) : ''"
              ppStyle="primary"
              ppId="createNewPointBtn"
              (ppAction)="save()"
              [ppDisabled]="processingSave || attachmentUploading || emptyTitle"
              [ppProcess]="processingSave"
              data-test="createPointButton"
            >
              {{
                !(point?.pins?.length > 0 || point?.polygons?.length > 0) && workspace?.siteImageRef
                  ? ('create_point_no_location' | ppTranslate)
                  : ('create_point' | ppTranslate)
              }}
            </pp-button>
          </footer>
        </ng-container>

        <ng-container side *ngIf="isDesktopScreen">
          <pp-point-plan
            *ngIf="point || _id === NEW_POINT_ID"
            ppModule="point"
            [ppFull]="true"
            [ppCanEdit]="canEditPoint && !processingSave"
            [ppWorkspaceId]="point?.workspaceRef.id"
            [ppPointId]="_id"
          ></pp-point-plan>
        </ng-container>
      </pp-point-container>
    </div>
  </div>
</article>
