<div class="formulaCustomFields">
  {{ 'custom_fields' | ppTranslate }}:

  <pp-formula-piece
    class="formulaCustomField"
    [ppDisabled]="disabled"
    [class.formulaCustomField__disabled]="disabled"
    *ngFor="let customField of customFields"
    [ppWorkspaceId]="ppWorkspaceId"
    [ppFormulaPiece]="customField"
    (ppAction)="addCustomField(customField)"
    [ppClickable]="true"
  ></pp-formula-piece>
</div>
