import {
  merge360Images,
  mergeAddedFiles,
  mergeRemovedDocuments,
  mergeRemovedImages,
  mergeVideos,
} from 'src/app/project/modules/activities/activities-merge';
import { EDashletAcitivity } from '../dashlet/dashlet-activities/dashlet-activities-enums';

export function mergeDashboardActivities(activity, index, activities) {
  const nextActivity = activities[index + 1];

  if (activity.data?.changeList) {
    activity.data.changeList.forEach((change) => {
      if (change.filesChange === 'DELETED') {
        if (change.oldValue.length === 0) {
          change.oldValue.push('file' + change.newValue.length + 1);
        }

        change.oldValue.push('file' + change.newValue.length + 1);
      } else if (change.filesChange === 'ADDED') {
        if (change.newValue.length === 0) {
          change.newValue.push('file' + change.newValue.length + 1);
        }

        change.newValue.push('file' + change.newValue.length + 1);
      }
    });
  }

  // mergedTime - so we can merge activities if LAST activity is 30 seconds apart from the next one, not the first

  if (nextActivity) {
    if (
      activity.header.createdBy.id === nextActivity.header.createdBy.id &&
      (activity.header.createdEpochMillis - nextActivity.header.createdEpochMillis <= 30000 ||
        activity.mergedTime - nextActivity.header.createdEpochMillis <= 30000) &&
      activity.data?.ref?.id === nextActivity.data?.ref?.id &&
      nextActivity.activityType !== EDashletAcitivity.NEW_DOCUMENT
    ) {
      let foundActivity = false;

      if (!(activity.data.comment || nextActivity.data.comment) && activity.data.changeList) {
        activity.data.changeList.forEach((change) => {
          if (
            // adding images
            change.label === 'Images' &&
            nextActivity.data.changeList &&
            nextActivity.data.changeList[0].label === 'Images' &&
            !(
              nextActivity.data.changeList[0].oldValue.length >
              nextActivity.data.changeList[0].newValue.length
            )
          ) {
            foundActivity = mergeAddedFiles(change, nextActivity, activity);

            if (foundActivity) {
              activities.splice(index + 1, 1);
            }
          } else if (
            // removing images
            change.label === 'Images' &&
            nextActivity.data.changeList &&
            nextActivity.data.changeList[0].label === 'Images' &&
            nextActivity.data.changeList[0].oldValue.length >
              nextActivity.data.changeList[0].newValue.length
          ) {
            foundActivity = mergeRemovedImages(change, nextActivity, activity);

            if (foundActivity) {
              activities.splice(index + 1, 1);
            }
          }

          if (
            change.label === '360 Images' &&
            nextActivity.data.changeList &&
            nextActivity.data.changeList[0].label === '360 Images' &&
            !(
              nextActivity.data.changeList[0].oldValue.length >
              nextActivity.data.changeList[0].newValue.length
            )
          ) {
            foundActivity = merge360Images(change, nextActivity, activity);

            if (foundActivity) {
              activities.splice(index + 1, 1);
            }
          }

          if (
            change.label === 'Videos' &&
            nextActivity.data.changeList &&
            nextActivity.data.changeList[0].label === 'Videos' &&
            !(
              nextActivity.data.changeList[0].oldValue.length >
              nextActivity.data.changeList[0].newValue.length
            )
          ) {
            foundActivity = mergeVideos(change, nextActivity, activity);

            if (foundActivity) {
              activities.splice(index + 1, 1);
            }
          }

          if (
            change.label === 'Documents' &&
            nextActivity.data.changeList &&
            nextActivity.data.changeList[0].label === 'Documents' &&
            !(
              nextActivity.data.changeList[0].oldValue.length >
              nextActivity.data.changeList[0].newValue.length
            )
          ) {
            foundActivity = mergeAddedFiles(change, nextActivity, activity);

            if (foundActivity) {
              activities.splice(index + 1, 1);
            }
          } else if (
            change.label === 'Documents' &&
            nextActivity.data.changeList &&
            nextActivity.data.changeList[0].label === 'Documents' &&
            nextActivity.data.changeList[0].oldValue.length >
              nextActivity.data.changeList[0].newValue.length
          ) {
            foundActivity = mergeRemovedDocuments(change, nextActivity, activity);

            if (foundActivity) {
              activities.splice(index + 1, 1);
            }
          }
        });

        if (!foundActivity) {
          activity.data.changeList.push(...nextActivity.data.changeList);
          activity.mergedTime = nextActivity.header.createdEpochMillis;

          activities.splice(index + 1, 1);
        }

        mergeDashboardActivities(activity, index, activities);
      }
    }

    if (activity.data?.changeList) {
      for (let i = 0; i < activity.data.changeList.length; i++) {
        if (
          activity.data.changeList[i].propName === 'description' &&
          activity.data.changeList[i + 1] &&
          activity.data.changeList[i + 1].propName === 'descriptionRich'
        ) {
          activity.mergedTime = nextActivity.header.createdEpochMillis;
          activity.data.changeList.splice(i, 1);
        }

        if (
          activity.data.changeList[i].propName === 'descriptionRich' &&
          activity.data.changeList[i + 1] &&
          activity.data.changeList[i + 1].propName === 'description'
        ) {
          activity.mergedTime = nextActivity.header.createdEpochMillis;
          activity.data.changeList.splice(i + 1, 1);
        }
      }
    }
  }
}
