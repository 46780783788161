<pp-modal
  *ngIf="workspaces"
  [ppScrollOnDefault]="true"
  [ppThin]="true"
  [ppWidth]="640"
  [ppCloseOnClickOutside]="false"
  [ppFooterShadow]="true"
>
  <ng-container heading>
    {{ (importedUser ? 'edit_user' : 'add_user') | ppTranslate }}
    {{ importedUser ? importedUser : '' }}
  </ng-container>

  <ng-container body>
    <div class="shareModal">
      <pp-share-modal-import
        [ppWorkspaceId]="workspaceId"
        [ppSelectedUsers]="selectedUsers"
        [(ppEditedShares)]="editedShares"
        (ppOnImportedUserChange)="onImportedUserChange($event)"
        [ppTotalSelectedImportFields]="totalSelectedImportFields"
        [ppCloseShare$]="closeShare$"
      ></pp-share-modal-import>
    </div>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="(importedUser ? 'save' : 'import_users') | ppTranslate"
      [ppProcess]="processingShare"
      (ppCancel)="hideModal()"
      (ppConfirm)="importUsers()"
      [ppDisabled]="totalSelectedImportFields.number === 0 && !importedUser"
      ppCancelDataTarget="Add New User Modal Close Button"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
