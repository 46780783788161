import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TAllCustomFields } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { TCustomFieldFilter } from 'src/app/project/modules/filters/site-filter.model';
import { blurInput } from 'src/app/core/helpers/blur-input';

@Component({
  selector: 'pp-filter-field-numbers',
  templateUrl: './filter-field-numbers.component.html',
  styleUrls: ['../../../site-filter-dropdown/site-filter-dropdown.component.scss'],
})
export class FilterFieldNumbersComponent {
  @Input() ppCustomFields: TAllCustomFields;
  @Input() ppField: TCustomFieldFilter;
  @Output() ppApply = new EventEmitter<void>();

  updateNumbersField(field: TCustomFieldFilter): void {
    field.excluded = false;
  }

  applyFilters(): void {
    this.ppApply.emit();
  }
}
