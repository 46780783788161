import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logout$ } from '../../auth/logout.service';
import { TUserResponse } from '../response-models/user-response-model';
import { UserUpdateService } from '../user-update.service';
import { UpdateUserEmail } from '../user.actions';
import { UserService } from '../user.service';

@Injectable({
  providedIn: 'root',
})
export class UpdateEmailService {
  constructor(
    private userUpdateService: UserUpdateService,
    private store: Store,
    private userService: UserService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
  ) {}

  updateEmail(newEmail: string): Observable<TUserResponse> {
    const user = this.userService.getUser();

    return this.userUpdateService.updateUser({ email: newEmail }, user.userId).pipe(
      tap((response) => {
        if (response) {
          this.showSuccessPrompt();

          this.updateUserEmailInStore(response);
          logout$.next();
        } else {
          this.showCanceledPrompt();
        }
      }),
    );
  }

  private showSuccessPrompt(): void {
    const prompt = this.translationPipe.transform('prompt_email_change_success');
    this.promptService.showSuccess(prompt);
  }

  private updateUserEmailInStore(response: TUserResponse) {
    this.store.dispatch(
      new UpdateUserEmail({
        email: response.email,
      }),
    );
  }

  private showCanceledPrompt(): void {
    const prompt = this.translationPipe.transform('prompt_email_change_canceled');
    this.promptService.showWarning(prompt);
  }
}
