<div #timeCF class="pointCF__field">
  <label class="pointCF__label">
    <div class="pointCF__label-image--container">
      <pp-icon [ppSrc]="EIconPath.ICON_FIELDS_TIME_18" ppColor="inherit" ppClass="setSize18" />
    </div>

    <span
      ppTooltip
      class="pointCF__label-container"
      ppTitle="{{ ppFieldLabel }}"
      [ppTrimTooltip]="true"
    >
      {{ ppFieldLabel }}
    </span>
  </label>

  <div class="pointCF__content pointCF__content--time">
    <section class="time__content__left">
      <pp-time-input
        class="time__input"
        [ppCanEdit]="ppCanEdit"
        [ppShowHoursOnly]="showHoursOnly"
        (ppOnModelChange)="onModelChange()"
        [(ppValue)]="newValue"
        (ppUpdate)="setNewValue($event)"
        [ppClearObservable]="clearObservable$"
        (ppSave)="updateField()"
      ></pp-time-input>

      <div class="iconWrapper">
        <pp-icon
          class="time_save_button"
          ppClass="setSize32"
          [ppSrc]="EIconPath.ICON_INPUT_SQUARE_PLUS_32"
          [ppDisplayContents]="true"
          [ppColor]="this.newValue ? 'primary-600' : 'grey-400'"
          [ppHoverColor]="this.newValue ? 'primary-600' : 'grey-600'"
          *ngIf="ppCanEdit"
          (click)="updateField()"
        ></pp-icon>
      </div>
    </section>

    <div class="time_total">
      <span class="pointCF__label--total"> {{ 'total' | ppTranslate }} </span>

      <span class="totalNumber">
        <ng-container *ngIf="!showHoursOnly">
          {{ +value < 0 ? '-' : '' }}{{ displayDays }}d / {{ displayHours < 10 ? '0' : ''
          }}{{ displayHours }}:{{ displayMinutes < 10 ? '0' : '' }}{{ displayMinutes }}
        </ng-container>

        <ng-container *ngIf="showHoursOnly">
          {{ +value < 0 ? '-' : '' }}{{ displayDays * 24 + displayHours < 10 ? '0' : ''
          }}{{ displayDays * 24 + displayHours }}:{{ displayMinutes < 10 ? '0' : ''
          }}{{ displayMinutes }}
        </ng-container>
      </span>
    </div>
  </div>
</div>
