<section #assetsFooter class="assetsFooter" (dragover)="onDragOver($event)">
  <button class="assetsFooterText">
    {{
      'showing_assets'
        | ppTranslate
          : {
              count: ppAssetCount
            }
    }}
  </button>
</section>
