import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { catchError, of, tap } from 'rxjs';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { routeToUrl } from 'src/app/core/helpers/route-to-url';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { getErrorMessage } from 'src/app/project/helpers/database/get-error-message';
import { TwoFactorAuthenticationService } from 'src/app/project/modules/auth/2fa/two-factor-authentication.service';
import { ResponseErrorService } from 'src/app/project/modules/errors/response-error.service';
import { EAuthRoute } from 'src/app/project/shared/constants/auth.constants';
import { UpdateUserWithTwoFactorAuthenticationModalComponent } from '../../../update-user-with-two-factor-authentication-modal/update-user-with-two-factor-authentication-modal.component';
import { UpdateUserWithTwoFactorAuthenticationModalService } from '../../../update-user-with-two-factor-authentication-modal/update-user-with-two-factor-authentication-modal.service';
import { UpdateUserTwoFactorAuthentication } from '../../../user.actions';
import { TUser } from '../../../user.model';

@Component({
  selector: 'pp-user-settings-profile-two-factor-authorization',
  templateUrl: './user-settings-profile-two-factor-authorization.component.html',
  styleUrls: ['./user-settings-profile-two-factor-authorization.component.scss'],
})
export class UserSettingsProfileTwoFactorAuthorizationComponent implements OnChanges {
  @Input() ppUser: TUser;

  buttonText: string;

  constructor(
    private translationPipe: TranslationPipe,
    private twoFactorAuthenticationService: TwoFactorAuthenticationService,
    private router: Router,
    private responseErrorService: ResponseErrorService,
    private modalService: ModalService,
    private updateUserWithTwoFactorAuthenticationModalService: UpdateUserWithTwoFactorAuthenticationModalService,
    private store: Store,
    private promptService: PromptService,
  ) {}

  ngOnChanges(): void {
    this.setButtonText();
  }

  buttonCallback(): void {
    if (this.ppUser.enabled2fa) {
      this.disable2FA();
    } else {
      this.setup2FA();
    }
  }

  private setButtonText(): void {
    this.buttonText = this.ppUser.enabled2fa
      ? this.translationPipe.transform('2fa_disable')
      : this.translationPipe.transform('2fa_set_up');
  }

  private disable2FA(): void {
    this.twoFactorAuthenticationService
      .deleteMy2FA(this.ppUser.userId)
      .pipe(
        catchError((error) => {
          if (error.status === EStatusCode.FORBIDDEN) {
            getErrorMessage(error).then((phoneNumber) => {
              this.showTwoFactorAuthenticationModal(phoneNumber);
            });

            return of(null);
          } else {
            return this.responseErrorService.handleRequestError(error);
          }
        }),
      )
      .subscribe();
  }

  private setup2FA(): void {
    this.router.navigate([routeToUrl(EAuthRoute.TWO_FACTOR_AUTHENTICATION_SETUP)]);
  }

  private showTwoFactorAuthenticationModal(phoneNumber: string): void {
    this.updateUserWithTwoFactorAuthenticationModalService.clearData();
    this.updateUserWithTwoFactorAuthenticationModalService.setPhoneNumber(phoneNumber);

    this.modalService.showModal(UpdateUserWithTwoFactorAuthenticationModalComponent, {
      callback: () => {
        this.twoFactorAuthenticationModalCallback();
      },
    });
  }

  private twoFactorAuthenticationModalCallback(): void {
    const twoFactorAuthCode = this.updateUserWithTwoFactorAuthenticationModalService.getCode();

    this.twoFactorAuthenticationService
      .deleteMy2FA(this.ppUser.userId, twoFactorAuthCode)
      .pipe(
        tap(() => {
          this.store.dispatch(new UpdateUserTwoFactorAuthentication({ enabled2fa: false }));
          this.modalService.hideModal();
          this.promptService.showSuccess(this.translationPipe.transform('2fa_disabled'));
        }),
        catchError((error) => {
          this.showCodeErrorPrompt();

          return of(null);
        }),
      )
      .subscribe();
  }

  private showCodeErrorPrompt(): void {
    const prompt = this.translationPipe.transform('2fa_code_error');
    this.updateUserWithTwoFactorAuthenticationModalService.setShowingError(true);
    this.promptService.showError(prompt);
  }
}
