import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  ClearAccounts,
  SetAccount,
  UpdateAccount,
  UpdateAccountStructure,
  UpdateAccountWebsiteUrl,
} from '../account.actions';
import { TAccount } from '../account.model';

import { ResponseErrorService } from '../../errors/response-error.service';
import { WhiteLabelService } from '../../white-label/white-label.service';

import { AccountApiProviderService } from '@core/api';
import { catchError, tap } from 'rxjs/operators';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { PromptService } from '../../../components/prompt/prompt.service';
import { API_account_users_export } from '../../../data-providers/api-providers/account-api-provider/account-paths';
import { TranslationPipe } from '../../../features/translate/translation.pipe';
import { DownloadService } from '../../../services/download.service';
import { EStore } from '../../../shared/enums/store.enum';
import {
  TAccountResponse,
  TAccountSummaryResponse,
} from '../../../view-models/account-response.model';
import {
  TAccountSimpleResponse,
  TRequestFolder,
} from '../../../view-models/account-simple-response.model';
import { TCustomField } from '../../custom-fields/custom-fields.model';
import { CustomFieldModelFactory } from '../../custom-fields/utils/custom-field-model-factory';
import { TFilters, TSitesFilter } from '../../filters/site-filter.model';
import { setFullAccountsChanged } from '../account';
import { SET_ACCOUNT } from '../account.store';
import { findMatchingAccount } from './find-matching-account';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private accounts$: Observable<TAccount[]>;
  private accounts: TAccount[];

  constructor(
    private store: Store<{ accounts: TAccount[] }>,
    private responseErrorService: ResponseErrorService,
    private whiteLabelService: WhiteLabelService,
    private accountApiProviderService: AccountApiProviderService,
    private downloadService: DownloadService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
  ) {
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));

    this.accounts$.subscribe((accounts) => {
      this.accounts = accounts;
    });
  }

  static getAccountsWorkspacesCustomFields(accounts: TAccountSummaryResponse[]): TCustomField[] {
    const customFields: TCustomField[] = [];

    accounts.forEach((_account) => {
      _account.workspaces.forEach((_workspace) => {
        if (_workspace.customFields) {
          customFields.push(
            ..._workspace.customFields.map((field) => CustomFieldModelFactory.createFromDTO(field)),
          );
        }
      });
    });

    return customFields;
  }

  getAccount(accountId: string): TAccount {
    return this.accounts.find((account) => account.accountId === accountId);
  }

  getAccounts(): TAccount[] {
    return this.accounts;
  }

  clearAccount(): void {
    this.store.dispatch(new ClearAccounts());
  }

  fetchAccount(_accountId: string): Observable<TAccount> {
    return this.accountApiProviderService.fetchAccount(_accountId).pipe(
      tap((account) => {
        setFullAccountsChanged(false);

        this.store.dispatch(new SetAccount(account));
        SET_ACCOUNT(account);

        this.onAccountFetched(account);
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  private onAccountFetched(account: TAccount): void {
    this.whiteLabelService.fetchWhiteLabel(account.accountId);

    this.store.dispatch(new UpdateAccount({ accountId: account.accountId, account }));
  }

  updateAccountWebsite(accountId: string, websiteUrl: string): Observable<TAccountResponse> {
    const body: Partial<TAccountResponse> = {
      websiteUrl,
    };

    return this.accountApiProviderService
      .updateAccount(accountId, body)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  updateAccount(accountId: string, body: Partial<TAccountResponse>): Observable<TAccountResponse> {
    return this.accountApiProviderService.updateAccount(accountId, body).pipe(
      tap(() => {
        this.store.dispatch(
          new UpdateAccount({
            accountId,
            account: body as unknown as TAccount,
          }),
        );
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  addNewFolder(accountId: string, name: string): Observable<TAccountSimpleResponse> {
    const body: TRequestFolder = {
      name,
    };

    return this.accountApiProviderService.addNewFolder(accountId, body).pipe(
      tap((response) => {
        this.store.dispatch(
          new UpdateAccountStructure({
            accountId,
            accountFolders: response.folders,
          }),
        );
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  editFolder(
    accountId: string,
    folderId: string,
    body: TRequestFolder,
  ): Observable<TAccountSimpleResponse> {
    return this.accountApiProviderService.editFolder(accountId, folderId, body).pipe(
      tap((response) => {
        this.store.dispatch(
          new UpdateAccountStructure({
            accountId,
            accountFolders: response.folders,
          }),
        );
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  deleteFolder(accountId: string, folderId: string): Observable<TAccountSimpleResponse> {
    return this.accountApiProviderService.deleteFolder(accountId, folderId).pipe(
      tap((response) => {
        this.store.dispatch(
          new UpdateAccountStructure({
            accountId,
            accountFolders: response.folders,
          }),
        );
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  static findMatchingAccount(existingAccount: TAccount, filters: TFilters): TSitesFilter {
    return findMatchingAccount(existingAccount, filters);
  }

  exportAccountUsers(accountId: string): Observable<Response> {
    const url = API_account_users_export(accountId);
    const prompt = this.translationPipe.transform('prompt_export_users_start');

    this.promptService.showSuccess(prompt);

    return this.downloadService.downloadGET({ url }).pipe(
      tap((response) => {
        if (response.status === EStatusCode.ACCEPTED) {
          const promptText = this.translationPipe.transform(
            'prompt_export_will_shortly_receive_a_link_via_email',
          );

          this.promptService.showSuccess(promptText, { duration: 15 });
        }
      }),
    );
  }

  setWebsite(accountId: string, websiteUrl: string): void {
    this.store.dispatch(
      new UpdateAccountWebsiteUrl({
        accountId,
        websiteUrl,
      }),
    );
  }
}
