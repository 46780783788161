import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { inject } from '@angular/core';
import { routeToUrl } from 'src/app/core/helpers/route-to-url';
import { AuthService } from '../../modules/auth/auth.service';
import { EAuthRoute } from '../../shared/constants/auth.constants';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService  {
  isUploadingAttachment = false;

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot,
  ): boolean {
    if (this.authService.isAuthenticated()) {
      return true;
    }

    if (routerState.url !== routeToUrl(EAuthRoute.LOGIN)) {
      this.authService.setCachedUrlPath(routerState.url);
    }

    this.router.navigate([routeToUrl(EAuthRoute.LOGIN)]);
    return false;
  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(AuthGuardService).canActivate(next, state);
}
