import { TPoint } from '../../../points/points.model';
import { EAdvancedFilterOptions } from '../../models/advanced-filter-options.enum';
import { TAdvancedFilterPriority } from '../../models/advanced-filter.model';

export function filterPriorityAdvanced(point: TPoint, filter: TAdvancedFilterPriority): boolean {
  if (!filter.value?.length) {
    return true;
  }

  switch (filter.option) {
    case EAdvancedFilterOptions.IS:
      return point.priority === filter.value[0];
    case EAdvancedFilterOptions.IS_NOT:
      return point.priority !== filter.value[0];
    case EAdvancedFilterOptions.IS_ANY_OF:
      return filter.value.includes(point.priority);
    case EAdvancedFilterOptions.IS_NONE_OF:
      return !filter.value.includes(point.priority);
  }
}
