<thead>
  <tr class="tableHeader--import">
    <td class="checkbox__row">
      <pp-checkbox
        [ppChecked]="false"
        ppType="solid"
        ppSize="medium"
        *ngIf="
          ppSite &&
          ppNumberOfSelectableFields !== ppSelectedWorkspaceCustomFields.length &&
          ppNumberOfSelectableFields !== 0
        "
        (ppOnValueChange)="selectAll()"
      ></pp-checkbox>

      <pp-checkbox
        [ppChecked]="true"
        ppType="solid"
        ppSize="medium"
        *ngIf="
          ppSite &&
          ppNumberOfSelectableFields === ppSelectedWorkspaceCustomFields.length &&
          ppNumberOfSelectableFields !== 0
        "
        (ppOnValueChange)="deselectAll()"
      ></pp-checkbox>

      <pp-checkbox
        [ppChecked]="false"
        [ppDisabled]="true"
        ppType="solid"
        ppSize="medium"
        *ngIf="
          ppSite &&
          ppNumberOfSelectableFields === ppSelectedWorkspaceCustomFields.length &&
          ppNumberOfSelectableFields === 0
        "
      ></pp-checkbox>
    </td>

    <td class="name__row">{{ 'name' | ppTranslate }}</td>
    <td>{{ 'type' | ppTranslate }}</td>
  </tr>
</thead>
