import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';
import {
  TAdvancedFilterLocation,
  TAdvancedFilterSingle,
  TFilterLocationOptions,
} from '../../../../models/advanced-filter.model';
import { getAdvancedLocationOptions } from './advanced-filter-location-utils';

@Component({
  selector: 'pp-advanced-filter-location',
  templateUrl: './advanced-filter-location.component.html',
  styleUrls: ['./advanced-filter-location.component.scss'],
})
export class AdvancedFilterLocationComponent {
  @Input() ppFilter: TAdvancedFilterSingle;
  @Output() ppFilterChange = new EventEmitter<TAdvancedFilterSingle>();

  option: TFilterLocationOptions;
  filter: TAdvancedFilterLocation;

  advancedFilterLocationOptions = getAdvancedLocationOptions();
  EAdvancedFilterOptions = EAdvancedFilterOptions;

  ngOnChanges(): void {
    this.filter = this.ppFilter as TAdvancedFilterLocation;

    this.option = this.filter.option;
  }

  changeFieldCondition(newCondition: TFilterLocationOptions): void {
    this.ppFilter.option = newCondition;
    this.option = newCondition;
    this.applyChanges();
  }

  applyChanges(): void {
    this.ppFilterChange.emit(this.ppFilter);
  }

  changeFieldValue(value: string): void {
    this.ppFilter.value = value;
    this.applyChanges();
  }
}
