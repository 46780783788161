<pp-modal [ppDisableScroll]="true">
  <ng-container heading>
    {{ 'add_tags' | ppTranslate }}
  </ng-container>

  <ng-container body>
    <div>
      <p class="modal__body-title">{{ 'select_tags' | ppTranslate }}</p>

      <pp-tag-select
        [ppReadOnlyTags]="commonTags"
        [ppSelectedTags]="selectedTags"
        (ppOnChange)="updateTags($event)"
        (ppOnClose)="updateTags($event)"
        [ppWorkspaceId]="workspaceId"
        [ppOptions]="tagSelectOptions"
      ></pp-tag-select>
    </div>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'save' | ppTranslate"
      (ppCancel)="hideModal()"
      (ppConfirm)="save()"
      [ppProcess]="processing"
      [ppDisabled]="!selectedTags"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
