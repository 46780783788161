import { cloneDeep } from 'lodash';

import { Injectable, OnDestroy } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, combineLatest, from, of } from 'rxjs';

import { TUser } from '../../user/user.model';
import { TWorkspacesById } from '../../workspace/workspace.model';
import {
  ClearPreferences,
  SetPreferences,
  SetWorkspacePreferences,
  UpdateOverviewPreferences,
  UpdatePreferences,
  UpdateWorkspaceExportPreferences,
  UpdateWorkspacePreferences,
} from '../preferences.actions';
import { TPreferences, TWorkspacePreferences } from '../preferences.model';

import { AccountService } from '../../account/account-service/account.service';
import { ResponseErrorService } from '../../errors/response-error.service';
import { OfflineService } from '../../offline/offline.service';
import { UserService } from '../../user/user.service';

import { SET_PREFERENCES, SET_WORKSPACE_PREFERENCES } from '../preferences.store';
import { processWorkspacePreferences } from './fetch-workspace-preferences-utils';
import { processUserPreferencesResponse } from './process-user-preferences-response';
import { processPreferencesResponse } from './update-preferences-utils';

import { PreferencesApiProviderService } from '@core/api';
import {
  catchError,
  debounceTime,
  finalize,
  map,
  shareReplay,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { TAccountFeatures } from 'src/app/project/modules/account/account.model';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { EWorkspaces } from 'src/app/project/modules/workspace/workspaces.enum';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { AdvancedFilterService } from '../../filters-advanced/advanced-filter.service';
import { DefaultFilterService } from '../../filters/default-filter.service';
import { createSavedViewCustomFieldFilters } from '../../filters/site-filter-data-service/create-saved-views-filter';
import { SetFiltersFromPreferences } from '../../filters/site-filter.actions';
import { TAllFilters, TFilters } from '../../filters/site-filter.model';
import { TSavedView } from '../../saved-views/models/saved-view.model';
import { TSavedViews } from '../../saved-views/models/saved-views.model';
import { SavedViewsService } from '../../saved-views/saved-views.service';
import { TColumn } from '../../site/site-table/columns/column.model';
import { processColumns } from '../../site/site-table/columns/columns';
import { TableColumnsService } from '../../site/site-table/columns/table-columns.service';
import { CustomTableService } from '../../site/site-table/custom-table/custom-table.service';
import { FilterAssetService } from '../filter-asset.service';
import { compareColumns } from './compare-columns';

@Injectable({
  providedIn: 'root',
})
export class PreferencesService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly savePreferences$ = new Subject<string>();
  private savePreferencesDebounceTimeMs = 2000;

  private userPreferencesFetched: boolean;

  private userPreferencesPromise: Observable<TPreferences> = null;

  private preferences$: Observable<TPreferences>;
  private preferences: TPreferences;

  private workspaces$: Observable<TWorkspacesById>;
  private workspaces: TWorkspacesById;
  private siteFilters$ = new Observable<TAllFilters>();
  allFilters: TAllFilters;

  private fetchWorkspacePreferences$: Observable<TPreferences>;
  private fetchExportPreferences$: Observable<TWorkspacePreferences>;

  constructor(
    private store: Store<{
      user: TUser;
      preferences: TPreferences;
      workspaces: TWorkspacesById;
      siteFilter: TAllFilters;
    }>,
    private responseErrorService: ResponseErrorService,
    private userService: UserService,
    private offlineService: OfflineService,
    private accountService: AccountService,
    private preferencesApiProviderService: PreferencesApiProviderService,
    private customTableService: CustomTableService,
    private savedViewsService: SavedViewsService,
    private advancedFilterService: AdvancedFilterService,
    private defaultFilterService: DefaultFilterService,
    private tableColumnsService: TableColumnsService,
    private filterAssetService: FilterAssetService,
  ) {
    this.preferences$ = this.store.pipe(select(EStore.PREFERENCES));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.siteFilters$ = this.store.pipe(select(EStore.SITE_FILTER));

    this.preferences$.subscribe((preferences) => {
      this.preferences = cloneDeep(preferences);

      SET_PREFERENCES(this.preferences);
    });

    this.workspaces$.subscribe((workspaces) => {
      this.workspaces = workspaces;
    });

    this.siteFilters$.pipe(takeUntil(this.destroy$)).subscribe((allFilters) => {
      this.allFilters = cloneDeep(allFilters);
    });

    this.savePreferences$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(this.savePreferencesDebounceTimeMs),
        tap((workspaceId) => {
          this.sendSavePreferencesRequest(workspaceId);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  getPreferences(): TPreferences {
    return this.preferences;
  }

  clearPreferences(): void {
    this.userPreferencesPromise = null;
    this.fetchWorkspacePreferences$ = null;
    this.fetchExportPreferences$ = null;

    this.store.dispatch(new ClearPreferences());
  }

  fetchPreferences({
    refetch = false,
    forceFetch = false,
  }: {
    refetch?: boolean;
    forceFetch?: boolean;
  } = {}): Observable<TPreferences> {
    const user = this.userService.getUser();

    if (!this.userPreferencesFetched || refetch || forceFetch || !this.userPreferencesPromise) {
      this.userPreferencesPromise = this.generateUserPreferencesPromise(user);
    }

    return this.userPreferencesPromise;
  }

  generateUserPreferencesPromise(user: TUser): Observable<TPreferences> {
    return this.getUserPreferences(user.userId, this.preferences).pipe(
      tap((response) => {
        this.userPreferencesFetched = true;

        this.store.dispatch(
          new SetPreferences(processUserPreferencesResponse(response, this.preferences)),
        );

        this.setFilterPreferences(response);
      }),
      shareReplay(),
      catchError((error) => {
        this.userPreferencesFetched = false;

        return this.responseErrorService.handleRequestError(error);
      }),
    );
  }

  updatePreferences(newPreferences: TPreferences): Observable<TPreferences | null> {
    const offline = this.offlineService.getOffline();
    const user = this.userService.getUser();

    if (offline) {
      return from([]);
    }

    return this.preferencesApiProviderService
      .updateUserPreferences(user.userId, newPreferences)
      .pipe(
        map((response) => {
          const preferences = processPreferencesResponse(response, this.preferences);

          this.store.dispatch(new UpdatePreferences(preferences));
          SET_PREFERENCES(preferences);

          return preferences;
        }),
        catchError(this.responseErrorService.handleRequestError),
      );
  }

  fetchWorkspacePreferences(
    workspaceId: string,
    accountFeatures: TAccountFeatures,
  ): Observable<TWorkspacePreferences> {
    const isGlobalPreferences = !!accountFeatures?.globalSitePreferences;

    const savedViewId = this.savedViewsService.getSelectedViewId(workspaceId);
    const savedView = this.savedViewsService.getView(savedViewId);

    // TODO Remove workspace preferences when saved views are globally enabled
    const requestList = savedView
      ? [
          of({
            columns: savedView.columns,
            filters: savedView.filters.basic,
          }),
          this.fetchExportPreferences(workspaceId),
        ]
      : [
          this.getWorkspacePreferences(workspaceId, isGlobalPreferences),
          this.fetchExportPreferences(workspaceId),
        ];

    return combineLatest(requestList).pipe(
      map(([workspacePreferencesResponse, exportPreferencesResponse]) => {
        const workspacePreferences = processWorkspacePreferences(
          workspacePreferencesResponse,
          exportPreferencesResponse,
          savedView ? null : this.allFilters[workspaceId],
        );

        this.store.dispatch(
          new SetWorkspacePreferences({ workspaceId, preferences: workspacePreferences }),
        );

        this.setWorkspaceFilter(workspaceId, workspacePreferences.filters);

        return workspacePreferences;
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  updateWorkspacePreferences(
    newPreferences: TWorkspacePreferences,
    workspaceId: string = '',
  ): Observable<TPreferences | null> {
    const workspace = this.workspaces[workspaceId];
    const account = workspace ? this.accountService.getAccount(workspace.accountId) : null;
    const isGlobalPreferences = !!account?.accountFeatures?.globalSitePreferences;
    const user = this.userService.getUser();

    if (
      isGlobalPreferences &&
      workspace.share.shareOption !== EUserRole.SITE_ADMIN &&
      workspace.share.shareOption !== EUserRole.OWNER &&
      workspace.share.shareOption !== EUserRole.ACCOUNT_ADMIN
    ) {
      return from([]);
    }

    return this.preferencesApiProviderService
      .updateWorkspacePreferences(workspaceId, user.userId, isGlobalPreferences, newPreferences)
      .pipe(
        tap((response) => {
          const table = this.customTableService.getTable();
          let instance;

          if (workspaceId === EWorkspaces.OVERVIEW) {
            instance = new UpdateOverviewPreferences({
              preferences: processColumns(response.overviewColumns),
            });
          } else {
            (response as TWorkspacePreferences).columns = processColumns(
              (response as TWorkspacePreferences).columns,
            );

            instance = new UpdateWorkspacePreferences({
              workspaceId,
              preferences: { ...response },
            });
          }

          this.store.dispatch(instance);

          if (table) {
            table.generateColumns();
            table.load(true);
          }
        }),
        catchError(this.responseErrorService.handleRequestError),
      );
  }

  updateExportPreferences(newPreferences: TWorkspacePreferences, workspaceId: string = ''): void {
    if (this.offlineService.getOffline()) {
      return;
    }

    this.preferencesApiProviderService
      .updateExportPreferences(
        workspaceId,
        newPreferences,
        this.preferences.workspaces[workspaceId].columns,
      )
      .pipe(
        tap((response) => {
          this.store.dispatch(
            new UpdateWorkspaceExportPreferences({
              workspaceId,
              preferences: { ...response },
            }),
          );
        }),
        catchError((error) => {
          this.responseErrorService.logRequestError(error);

          return of(null);
        }),
      )
      .subscribe();
  }

  savePreferences(workspaceId: string): void {
    const workspace = this.workspaces[workspaceId];
    const account = this.accountService.getAccount(workspace?.accountId);
    const savedViewsEnabled = account?.accountFeatures?.savedViews;
    const newColumns = this.tableColumnsService.getColumns();
    let oldColumns: TColumn[] = this.getSavedColumns(workspaceId);

    const matchingColumns = compareColumns(oldColumns, newColumns);

    this.savedViewsService.tryUpdateActiveView(
      workspaceId,
      newColumns,
      this.allFilters[workspaceId],
    );

    if (!matchingColumns) {
      this.savePreferences$.next(workspaceId);
    }
  }

  private getSavedColumns(workspaceId: string): TColumn[] {
    if (workspaceId === EWorkspaces.OVERVIEW) {
      return this.preferences.overviewColumns;
    } else {
      const workspace = this.workspaces[workspaceId];
      const account = this.accountService.getAccount(workspace.accountId);
      const savedViewsEnabled = account?.accountFeatures?.savedViews;

      if (savedViewsEnabled) {
        const savedView = this.savedViewsService.getView(
          this.savedViewsService.getSelectedViewId(workspaceId),
        );

        if (savedView) {
          return savedView.columns;
        } else {
          return this.preferences.workspaces[workspaceId].columns;
        }
      } else {
        return this.preferences.workspaces[workspaceId].columns;
      }
    }

    return [];
  }

  private getUserPreferences(
    userId: string,
    oldPreferences: TPreferences,
  ): Observable<TPreferences> {
    return this.preferencesApiProviderService
      .getUserPreferences(userId)
      .pipe(map((response) => processUserPreferencesResponse(response, oldPreferences)));
  }

  private getWorkspacePreferences(
    _workspaceId: string,
    _globalPreferences: boolean,
  ): Observable<TWorkspacePreferences> {
    if (this.preferences.workspaces[_workspaceId]) {
      return of(this.preferences.workspaces[_workspaceId]);
    }

    if (!this.fetchWorkspacePreferences$) {
      this.fetchWorkspacePreferences$ = this.preferencesApiProviderService
        .getWorkspacePreferences(_workspaceId, _globalPreferences)
        .pipe(
          map((response) => response || {}),
          tap((response) => {
            SET_WORKSPACE_PREFERENCES(response);
          }),
          finalize(() => {
            this.fetchWorkspacePreferences$ = null;
          }),
        );
    }

    return this.fetchWorkspacePreferences$;
  }

  private fetchExportPreferences(_workspaceId: string): Observable<TWorkspacePreferences> {
    if (!this.fetchExportPreferences$) {
      this.fetchExportPreferences$ = this.preferencesApiProviderService
        .getExportPreferences(_workspaceId)
        .pipe(
          map((response) => response || {}),
          finalize(() => {
            this.fetchExportPreferences$ = null;
          }),
        );
    }

    return this.fetchExportPreferences$;
  }

  private sendSavePreferencesRequest(workspaceId: string): void {
    this.updateWorkspacePreferences({ columns: this.tableColumnsService.getColumns() }, workspaceId)
      .pipe(
        catchError((error) => {
          const errorData = {
            message: 'Error while updating workspace preferences',
            error,
          };
          console.error(errorData);
          return of(null);
        }),
      )
      .subscribe();
  }

  setFilterPreferences(preferences: TPreferences): void {
    const filters = this.allFilters;

    if (preferences.overviewFilters) {
      filters.overview = preferences.overviewFilters;
    }

    if (preferences.timelineFilters) {
      filters.timeline = preferences.timelineFilters;
    }

    if (preferences.remindersFilters) {
      filters.reminders = preferences.remindersFilters;
    }

    if (preferences.workspaces) {
      Object.keys(preferences.workspaces).forEach((workspaceId) => {
        const preferencesWorkspace = preferences.workspaces[workspaceId];
        const workspace = this.workspaces[workspaceId];
        let isSavedViews = false;

        if (workspace) {
          const account = this.accountService.getAccount(workspace.accountId);
          isSavedViews = account?.accountFeatures?.savedViews;
        }

        if (!isSavedViews) {
          this.saveFilter(preferencesWorkspace.filters, workspaceId);
        }
      });
    }

    this.store.dispatch(
      new SetFiltersFromPreferences({
        overviewFilter: filters.overview,
        timelineFilter: filters.timeline,
        remindersFilter: filters.reminders,
      }),
    );
  }

  setWorkspaceFilter(workspaceId: string, filters: TFilters): void {
    const workspace = this.workspaces[workspaceId];
    let isSavedViews = false;

    if (workspace) {
      const account = this.accountService.getAccount(workspace.accountId);
      isSavedViews = account?.accountFeatures?.savedViews;
    }

    if (!isSavedViews) {
      this.saveFilter(filters, workspaceId);
    }
  }

  getCurrentWorkspacePrefs({
    preferences,
    workspaceId,
    savedViews,
    selectedViewId,
  }: {
    preferences: TPreferences;
    workspaceId: string;
    savedViews: TSavedViews;
    selectedViewId?: string;
  }): TWorkspacePreferences {
    let currentWorkspacePrefs: TWorkspacePreferences = null;

    if (preferences && preferences.workspaces) {
      currentWorkspacePrefs = preferences.workspaces[workspaceId];
    }

    if (savedViews) {
      if (selectedViewId) {
        const view = this.savedViewsService.getView(selectedViewId);
        currentWorkspacePrefs = this.setViewToPreferences(view, currentWorkspacePrefs, workspaceId);
      } else if (savedViews.personal.defaultViewId) {
        const view = this.savedViewsService.getView(savedViews.personal.defaultViewId);
        currentWorkspacePrefs = this.setViewToPreferences(view, currentWorkspacePrefs, workspaceId);
      } else if (savedViews.shared.sharedDefaultViewId) {
        const view = this.savedViewsService.getView(savedViews.shared.sharedDefaultViewId);
        currentWorkspacePrefs = this.setViewToPreferences(view, currentWorkspacePrefs, workspaceId);
      } else {
        currentWorkspacePrefs.filters = createSavedViewCustomFieldFilters({
          filters: currentWorkspacePrefs.filters,
          workspaceId,
        });
      }
    }
    return currentWorkspacePrefs;
  }

  private setViewToPreferences(
    view: TSavedView,
    currentWorkspacePrefs: TWorkspacePreferences,
    workspaceId: string,
  ): TWorkspacePreferences {
    const prefs = currentWorkspacePrefs || {};

    if (view && view.columns) {
      prefs.columns = [...view.columns];

      prefs.filters = createSavedViewCustomFieldFilters({
        filters: view.filters.basic,
        workspaceId,
      });

      this.advancedFilterService.setAdvancedFilter(workspaceId, view.filters.advanced);
    }
    return prefs;
  }

  setFiltersFromView(filters: TFilters, workspaceId: string): void {
    this.saveFilter(filters, workspaceId);
  }

  private saveFilter(filters: TFilters, workspaceId: string): void {
    if (this.filterAssetService.getOpeningSiteFromAsset()) {
      this.filterAssetService.createAssetFilter(workspaceId, filters);
    } else {
      this.defaultFilterService.generateDefaultFilter({
        filter: filters,
        workspace: this.workspaces[workspaceId],
        workspaceId,
      });
    }
  }
}
