<div class="selectAll">
  <button
    *ngIf="
      ppSite &&
      ppNumberOfSelectableFields !==
        ppSelectedCustomFields[ppSite.accountId][ppSite.workspaceId].length
    "
    (click)="selectAll()"
  >
    {{ 'select_all' | ppTranslate }}
  </button>

  <button
    *ngIf="
      ppSite &&
      ppNumberOfSelectableFields ===
        ppSelectedCustomFields[ppSite.accountId][ppSite.workspaceId].length &&
      ppNumberOfSelectableFields !== 0
    "
    (click)="deselectAll()"
  >
    {{ 'deselect_all' | ppTranslate }}
  </button>

  <button
    class="selectAll--disabled"
    *ngIf="
      ppSite &&
      ppNumberOfSelectableFields ===
        ppSelectedCustomFields[ppSite.accountId][ppSite.workspaceId].length &&
      ppNumberOfSelectableFields === 0
    "
    (click)="selectAll()"
  >
    {{ 'select_all' | ppTranslate }}
  </button>
</div>
