import { createElement } from 'src/app/core/helpers/dom';
import { GET_PREFERENCES } from 'src/app/project/modules/preferences/preferences.store';
import { transformDate } from 'src/app/project/shared/date-transformer';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { TColumn } from '../../columns/column.model';
import { createMarkedKeywordElement } from '../body-cells-search';
import { createCellElement } from '../createCellElement';

export function createTimelineElement(
  _index: number,
  _column: TColumn,
  _customField: TPointCustomField<string>,
): HTMLElement {
  const preferences = GET_PREFERENCES();

  let value = _customField.value;
  let date = '';

  if (
    _column.customFieldIds &&
    !_column.customFieldIds.includes(_customField.customFieldTemplateId.toString())
  ) {
    value = null;
  }

  if (value) {
    const dates = value.split('~');

    date =
      transformDate({
        value: dates[0],
        inputHourFormat: '',
        format: preferences.dateFormat,
        localTime: false,
      }) +
      ' - ' +
      transformDate({
        value: dates[1],
        inputHourFormat: '',
        format: preferences.dateFormat,
        localTime: false,
      });
  }

  return createCellElement({
    index: _column.index,
    width: `${_column.width}px`,
    child: createElement('div', {
      attrs: {
        class: 'customSiteTable__text',
      },
      children: [createMarkedKeywordElement(date ? date : '')],
    }),
  });
}
