import Tooltip from 'src/app/project/features/tooltip/Tooltip';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';

export function createSiteTooltip(
  site: TWorkspace,
  element: HTMLElement,
  nameElement: HTMLElement,
): Tooltip {
  const nameTooltip = new Tooltip({
    title: site.name,
    element: element,
    mobileTooltip: false,
    childTooltip: false,
    trimTooltip: true,
    trimElement: nameElement,
  });

  nameTooltip.createTooltip();

  return nameTooltip;
}
