/* eslint-disable no-bitwise */
// Bitwise operations are required to make it work because of quill.js internal logic
import { TRichTextUserList } from '../rich-text.model';
import { TAnyFunction } from '@core/helpers';

export function generateSource(
  searchTerm: string,
  renderList: TAnyFunction,
  userList: TRichTextUserList[],
): void {
  const values = userList;

  if (searchTerm.length === 0) {
    renderList(values, searchTerm);

    return;
  } else {
    const matches: TRichTextUserList[] = [];

    for (let i = 0; i < values.length; i++) {
      const matchUserName = ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase());
      const matchEmail = ~values[i].email.toLowerCase().indexOf(searchTerm.toLowerCase());

      if (matchUserName || matchEmail) {
        matches.push(values[i]);
      }
    }

    renderList(matches, searchTerm);
  }
}
