import { TPreferences } from 'src/app/project/modules/preferences/preferences.model';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import Timeline from '../../Timeline';
import { TTimelineColumns } from '../../timeframes/timeline-timeframes';
import { getNewTimelineElementPosition } from './get-new-timeline-element-position';
import { TIMELINE_TIMEOUT_DURATION, timelineProperties } from './timeline-variables';

export function onResizeHandleRightMouseUp({
  _event,
  _element,
  resizeHandlerElement,
  preferences,
  timelineData,
  timeline,
  _pointIndex,
  _timelineField,
}: {
  _event: MouseEvent;
  _element: HTMLElement;
  resizeHandlerElement: HTMLElement;
  preferences: TPreferences;
  timelineData: TTimelineColumns;
  timeline: Timeline;
  _pointIndex: number;
  _timelineField: TPointCustomField;
}): void {
  _event.preventDefault();

  (resizeHandlerElement.parentNode.parentNode as HTMLElement).style.cursor = 'pointer';

  (resizeHandlerElement.parentNode as HTMLElement).style.cursor = 'grab';
  timelineProperties.active = false;

  if (timeline.resizing) {
    const elementRect = _element.getBoundingClientRect();

    const { newStartDate, newEndDate } = getNewTimelineElementPosition(
      _element,
      preferences,
      elementRect,
      timelineData,
    );

    timeline.updatePointCallback({
      pointIndex: _pointIndex,
      field: _timelineField,
      startDate: newStartDate,
      endDate: newEndDate,
    });
  }

  setTimeout(() => {
    timeline.setResizing(false);
  }, TIMELINE_TIMEOUT_DURATION * 2);

  document.onmouseup = null;
  document.onmousemove = null;
  timelineProperties.timelineTable.onscroll = null;
}
