export default async (src, dest) => {
  return new Promise((resolve) => {
    retrieveExif(src).then((exif) => {
      resolve(
        new Blob([dest.slice(0, 2), exif, dest.slice(2)], {
          type: 'image/jpeg',
        }),
      );
    });
  });
};

export const SOS = 0xffda,
  APP1 = 0xffe1,
  EXIF = 0x45786966,
  retrieveExif = (blob) =>
    new Promise((resolve) => {
      const reader = new FileReader();

      reader.addEventListener('load', ({ target: { result: buffer } }) => {
        const view = new DataView(buffer);
        let offset = 0;

        // If image is not JPEG, return the image instead of crashing the process
        if (view.getUint16(offset) !== 0xffd8) return resolve(new Blob());
        offset += 2;
        let marker = view.getUint16(offset);

        while (marker !== SOS) {
          const size = view.getUint16(offset + 2);
          if (marker === APP1 && view.getUint32(offset + 4) === EXIF)
            return resolve(blob.slice(offset, offset + 2 + size));
          offset += 2 + size;
          marker = view.getUint16(offset);
        }
        return resolve(new Blob());
      });

      reader.readAsArrayBuffer(blob);
    });
