<pp-advanced-filter-option-select
  class="advancedFilter__option"
  [ppItems]="advancedFilterUserOptions"
  [ppOption]="option"
  ppClass="advancedFilter__option--condition"
  (ppOptionChange)="changeFieldCondition($event)"
></pp-advanced-filter-option-select>

<pp-user-picker
  *ngIf="
    workspaceUsers &&
    (option === EAdvancedFilterOptions.IS ||
      option === EAdvancedFilterOptions.IS_NOT ||
      option === EAdvancedFilterOptions.IS_ANY_OF ||
      option === EAdvancedFilterOptions.IS_ALL_OF ||
      option === EAdvancedFilterOptions.IS_NONE_OF)
  "
  [ppAllUsers]="workspaceUsers"
  [ppSelectedUsers]="filter.value"
  [ppId]="'filterUsersPicker' + ppIndex"
  (ppSelectUserCallback)="addUserId($event)"
  (ppRemoveUserCallback)="removeUserId($event)"
  (ppClearAll)="clearUsers()"
  [ppType]="'users'"
></pp-user-picker>
