import { Component, EventEmitter, Output } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-two-factor-authentication-success',
  templateUrl: './two-factor-authentication-success-page.component.html',
  styleUrls: ['./two-factor-authentication-success-page.component.scss'],
})
export class TwoFactorAuthenticationSuccessPageComponent {
  @Output() ppContinue = new EventEmitter<void>();

  EIconPath = EIconPath;

  continue(): void {
    this.ppContinue.emit();
  }
}
