import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TFilters } from '../../site-filter.model';

@Component({
  selector: 'pp-filter-field-tags',
  templateUrl: './filter-field-tags.component.html',
  styleUrls: ['../../site-filter-dropdown/site-filter-dropdown.component.scss'],
})
export class FilterFieldTagsComponent {
  @Input() ppFilters: TFilters;
  @Output() ppApply = new EventEmitter();

  constructor() {}

  applyFilters(): void {
    this.ppApply.emit();
  }
}
