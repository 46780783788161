import { EDashletType } from '../../dashlets-enums';

export function getBackgroundColor(type) {
  switch (type) {
    case EDashletType.COMMENT_CREATE:
      return '#79629C';
    case EDashletType.DEFECT_CREATE:
      return '#429488';
    case EDashletType.HIGH:
      return '#E54F50';
    case EDashletType.MEDIUM:
      return '#FFCE29';
    case EDashletType.LOW:
      return '#4DA0E5';
    case EDashletType.ONHOLD:
      return '#FF9801';
    case EDashletType.IN_PROGRESS:
      return '#6BC8F9';
    case EDashletType.OPEN:
      return '#3170A7';
    case EDashletType.CLOSED:
      return '#65B92E';
    case EDashletType.TO_REVIEW:
      return '#F3DB12';
    case EDashletType.CANCELED:
      return '#838B99';
    default:
      break;
  }
}
