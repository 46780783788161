import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, finalize, of, switchMap, takeUntil, tap } from 'rxjs';
import {
  ConfirmModalComponent,
  TConfirmModalParams,
} from 'src/app/project/components/confirm-modal/confirm-modal.component';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TAssetCreateDTO } from 'src/app/project/data-providers/api-providers/asset-api-provider/asset-requests.model';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TAsset } from '../../asset-service/asset.consts';
import { AssetService } from '../../asset-service/asset.service';
import { FleetManagementLandingPageService } from '../../fleet-management-landing-page/fleet-management-landing-page.service';
import { FleetManagementRoutesService } from '../../fleet-management-routes.service';
import {
  EFleetManagementRoutesParams,
  EFleetManagementRoutesSegments,
} from '../../fleet-management.routes';
import { FleetService } from '../../fleet-service/fleet.service';
import { NewAssetSitePickerService } from './new-asset-site-picker/new-asset-site-picker.service';
import { NewAssetService } from './new-asset.service';
import { areAssetsEqual } from './utils/are-assets-equal';
import _ from 'lodash';
import { TDashlet } from '../../../preferences/preferences.model';

@Component({
  selector: 'pp-new-asset-page',
  templateUrl: './new-asset-page.component.html',
  styleUrls: ['./new-asset-page.component.scss'],
})
export class NewAssetPageComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  EIconPath = EIconPath;
  processing: boolean;
  private isNewAsset: boolean;
  private assetId: string;
  emptyLabel: boolean = false;
  missingName: boolean = true;

  constructor(
    private fleetManagementRoutesService: FleetManagementRoutesService,
    private fleetService: FleetService,
    private newAssetService: NewAssetService,
    private assetService: AssetService,
    private newAssetSitePickerService: NewAssetSitePickerService,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private fleetManagementLandingPageService: FleetManagementLandingPageService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.assetId = params.get(EFleetManagementRoutesParams.ASSET_ID);
      this.isNewAsset = this.assetId === EFleetManagementRoutesSegments.NEW;
      this.newAssetService.setAssetImage(null);

      if (this.isNewAsset) {
        this.newAssetService.clearNewAsset();
        this.newAssetSitePickerService.clearAssetSites();
      } else {
        const asset = this.assetService.getAsset(this.assetId);

        this.newAssetService.setNewAsset(asset);
        this.newAssetSitePickerService.setAssetSites(asset.sites);
      }
    });

    this.isSaveButtonDisabled();

    this.newAssetService.newAssetChange$
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.isSaveButtonDisabled();
        }),
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.fleetManagementLandingPageService.resetFleetManagementScroll();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  goBack(): void {
    const oldAsset = this.assetService.getAsset(this.assetId);
    const newAsset = this.newAssetService.getNewAsset();
    const hasUnsavedChanges = !areAssetsEqual(
      oldAsset,
      newAsset as TAsset,
      !!this.newAssetService.getAssetImage(),
    );

    if (hasUnsavedChanges) {
      this.showConfirmUnsavedChangesModal();
    } else {
      this.fleetManagementRoutesService.goToFleet(this.fleetService.getActiveFleetId());
    }
  }

  saveAsset(): void {
    const newAsset = this.newAssetService.getNewAsset();
    this.processing = true;

    const uploadImageObservable = this.newAssetService.getAssetImage()
      ? this.assetService.uploadAssetImage(this.newAssetService.getAssetImage())
      : of(null);

    uploadImageObservable
      .pipe(
        switchMap((imageId) => {
          const assetObservable = this.getAssetObservable({ newAsset, imageId });

          return assetObservable.pipe(
            takeUntil(this.destroy$),
            tap(() => {
              this.showSuccessPrompt();
              this.fleetManagementRoutesService.goToFleet(this.fleetService.getActiveFleetId());
            }),
            finalize(() => {
              this.processing = false;
            }),
          );
        }),
      )
      .subscribe();
  }

  filterRemovedWorkspacesFromDashlets(asset: TAssetCreateDTO): TDashlet[] {
    let dashlets = asset.dashlets;
    let updatedDashlets = [];

    let workspaceIds = asset.sites.map((site) => {
      return site.workspaceId;
    });

    for (let i = 0; i < dashlets.length; i++) {
      let dashlet = dashlets[i];

      if (!_.isEmpty(dashlet.selectedRange.workspacesCosts)) {
        dashlet.selectedRange.workspacesCosts = _.pickBy(
          dashlet.selectedRange.workspacesCosts,
          (value, key) => _.includes(workspaceIds, key),
        );
      } else {
        dashlet.selectedRange.workspaceIds = _.filter(
          dashlet.selectedRange.workspaceIds,
          (workspace) => _.includes(workspaceIds, workspace),
        );
      }

      updatedDashlets.push(dashlet);
    }

    return updatedDashlets;
  }

  private showSuccessPrompt(): void {
    const promptText = this.isNewAsset
      ? this.translationPipe.transform('new_asset_success')
      : this.translationPipe.transform('edit_asset_success');

    this.promptService.showSuccess(promptText);
  }

  private getAssetObservable({
    newAsset,
    imageId,
  }: {
    newAsset: TAssetCreateDTO;
    imageId: string;
  }) {
    if (this.isNewAsset) {
      return this.assetService.createAsset({
        fleetId: this.fleetService.getActiveFleetId(),
        sites: newAsset.sites,
        name: newAsset.name,
        imageId: imageId,
        details: newAsset.details,
        labels: newAsset.labels,
        dashlets: newAsset.dashlets,
      });
    } else {
      const updatedDashlets = this.filterRemovedWorkspacesFromDashlets(newAsset);

      return this.assetService.editAsset(this.assetId, {
        sites: newAsset.sites,
        name: newAsset.name,
        imageId: imageId ? imageId : newAsset.imageId,
        details: newAsset.details,
        labels: newAsset.labels,
        dashlets: updatedDashlets,
      });
    }
  }

  private showConfirmUnsavedChangesModal(): void {
    const modalData: TConfirmModalParams = {
      message: this.translationPipe.transform('modal_unsaved_changes_message_leave_page'),
      heading: this.translationPipe.transform('modal_unsaved_changes_header'),
      redButton: true,
      confirmText: this.translationPipe.transform('close_without_saving'),
      boldText: true,
    };

    this.modalService.setData(modalData);

    this.modalService.showModal(ConfirmModalComponent, {
      blur: false,
      callback: () => {
        this.fleetManagementRoutesService.goToFleet(this.fleetService.getActiveFleetId());
      },
    });
  }

  isSaveButtonDisabled(): boolean {
    const assetName = this.newAssetService.getNewAsset().name;
    const assetLabels = this.newAssetService.getNewAsset().labels;

    this.emptyLabel = assetLabels.some((asset) => !asset.name.trim());
    this.missingName = !assetName.trim();

    return this.emptyLabel || this.missingName;
  }
}
