export enum EInstance {
  ASM = 'pinpoint.asm-yachts.com',
  CLONE = 'clone.pinpointworks.com',
  CLONE2 = 'clone.pinpointworks.com:444',
  RELEASE = 'release.pinpointworks.com',
  CLONE3 = 'clone.pinpointworks.com:445',
  ECS = 'dev-ecs.pinpointworks.com',
  LIVE = 'app.pinpointworks.com',
  LOCALHOST = 'localhost',
}
