import { Modify } from 'ol/interaction';

export function fixModifyInteraction(modifyInteraction: any): Modify {
  modifyInteraction.handleUpEvent_old = modifyInteraction.handleUpEvent;

  modifyInteraction.handleUpEvent = function (event): void {
    try {
      this.handleUpEvent_old(event);
    } catch (error) {
      const maps = document.getElementsByClassName('ol-viewport');

      for (let i = 0; i < maps.length; i++) {
        if (maps[i].classList) {
          maps[i].classList.remove('draggingPolygon');
        }
      }
    }
  };

  return modifyInteraction;
}
