import { createElement } from 'src/app/core/helpers/dom';
import Tooltip from 'src/app/project/features/tooltip/Tooltip';
import { EIconPath } from '../../../../../shared/enums/icons.enum';
import { TColumn } from '../../columns/column.model';
import { GET_TABLE } from '../../table.ui.store';
import { createCellElement } from '../createCellElement';

export function createAssigneesElement(_index: number, _column: TColumn): HTMLElement {
  const table = GET_TABLE();

  const userList: string[] = [];

  const element = createCellElement({
    index: _column.index,
    width: `${_column.width}px`,
    child: createElement('div', {
      attrs: {
        class: 'table__avatar__container',
      },
      children: table.points[_index].assignees.map((_assigneeId) => {
        let src = '';
        let matchesKeyword = false;

        const user = table.users.find((_user) => _user.userId === _assigneeId);

        if (user?.avatarPublicUrl) {
          matchesKeyword =
            table.keyword &&
            user.userName.toLowerCase().indexOf(table.keyword.toLowerCase()) !== -1;

          src = user.avatarPublicUrl;
        } else {
          src = EIconPath.PLACEHOLDER_USER;
        }

        const image = createElement('img', {
          attrs: {
            class: matchesKeyword ? 'table__avatar table__avatar--highlight' : 'table__avatar',
            src,
            'data-id': _assigneeId,
          },
        });

        if (user) {
          const title = user
            ? user.userName
            : table.points[_index].header.createdBy.caption + '(deleted)';

          userList.push(title);
        }

        return image;
      }),
    }),
  });

  const nameTooltip = new Tooltip({
    title: userList.join(', ') || '',
    mobileTooltip: false,
    childTooltip: false,
    element: element,
    trimTooltip: false,
    trimElement: element,
  });

  nameTooltip.createTooltip();

  return element;
}
