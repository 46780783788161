<pp-grid>
  <ng-container title> {{ 'my_sites' | ppTranslate }} </ng-container>

  <pp-user-settings-table-container
    body
    [ppWorkspaces]="workspaces"
  ></pp-user-settings-table-container>

  <div class="settingsSection__no-content" *ngIf="!workspaces && workspaces.length === 0">
    {{ 'no_sites_found' | ppTranslate }}
  </div>
</pp-grid>
