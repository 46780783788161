import { createElement } from 'src/app/core/helpers/dom';
import Tooltip from 'src/app/project/features/tooltip/Tooltip';
import { translate } from 'src/app/project/features/translate/translate';
import { isPointFlagged } from 'src/app/project/modules/points/utils/is-flagged';
import { EIconPath } from '../../../../../shared/enums/icons.enum';
import { TColumn } from '../../columns/column.model';
import { GET_TABLE } from '../../table.ui.store';
import { createCellElement } from '../createCellElement';

export function createFlagElement(index: number, column: TColumn): HTMLElement {
  const table = GET_TABLE();
  const point = table.points[index];
  const flagged = isPointFlagged(point);

  const element = createCellElement({
    index: column.index,
    width: `${column.width}px`,
    child: createElement('div', {
      attrs: {
        class: 'table_flag_container',
      },
      children: [createChildElement(flagged)],
    }),
  });

  addFlagTooltip(element, flagged);

  return element;
}

function createChildElement(flagged: boolean): HTMLImageElement {
  return createElement('img', {
    attrs: {
      class: 'table__flag',
      src: flagged
        ? EIconPath.ICON_ACTIVITIES_RED_FLAG_ACTIVATED_14
        : EIconPath.ICON_ACTIVITIES_RED_FLAG_14,
    },
  });
}

function addFlagTooltip(element: HTMLElement, flagged: boolean): void {
  const tooltipMessage = flagged ? 'flagged' : 'unflagged';

  const flagTooltip = new Tooltip({
    title: translate(tooltipMessage),
    mobileTooltip: false,
    childTooltip: false,
    element: element,
    trimTooltip: false,
    trimElement: element,
  });

  flagTooltip.createTooltip();
}
