<div
  (click)="action()"
  id="priorityButton"
  class="priorityButton"
  [class.priorityButton__high]="ppPriority === 'HIGH'"
  [class.priorityButton__medium]="ppPriority === 'MEDIUM'"
  [class.priorityButton__low]="ppPriority === 'LOW'"
  [class.priorityButton--disabled]="ppDisabled"
>
  <span id="buttonText" class="priorityButton__text">
    <app-spinner id="prioritySpinner" *ngIf="ppProcessing"></app-spinner>
    <span id="priorityName" *ngIf="!ppProcessing">{{ priorityName }}</span>
  </span>
</div>
