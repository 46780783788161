import { Injectable } from '@angular/core';
import { TShare } from 'src/app/project/modules/share/share.model';
import { searchShares } from './SearchShares';

@Injectable({
  providedIn: 'root',
})
export class SiteSettingsService {
  constructor() {}

  searchShares(items: TShare[], filter: string): TShare[] {
    return searchShares(items, filter);
  }
}
