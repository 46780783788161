<div class="pointCF__field">
  <label
    ppTooltip
    class="pointCF__label"
    ppTitle="{{ ppCustomField?.label }}"
    [ppTrimTooltip]="true"
  >
    <img class="pointCF__bulk-changes-field-icon" [src]="EIconPath.CUSTOM_FIELD_DATE_ACTIVITY" />

    <span class="pointCF__label-container">
      {{ ppCustomField?.label }}
    </span>
  </label>

  <div class="pointCF__content">
    <pp-datepicker
      [ppDate]="ppCustomFields[ppFieldId]"
      (ppSelect)="updateDate($event, ppCustomField)"
    ></pp-datepicker>
  </div>
</div>
