import { Component, OnInit } from '@angular/core';

import { Modal, ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';

import { TWorkspaceSharesDict } from 'src/app/project/modules/share/share.model';

import { TWorkspaceUser } from '@project/view-models';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSettings,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { AccountSharesService } from '../../share/account-shares.service';
import { ShareBulkService } from '../../share/share-bulk.service';
import { TDeleteAccountUserModalData } from './delete-account-user-modal.model';

@Component({
  selector: 'pp-delete-account-user-modal',
  templateUrl: './delete-account-user-modal.component.html',
  styleUrls: ['./delete-account-user-modal.component.scss'],
})
export class DeleteAccountUserModalComponent implements OnInit {
  modal: Modal<TDeleteAccountUserModalData>;
  processing: boolean;
  shareNumber = 0;
  accountId = '';
  shares: TWorkspaceSharesDict;
  user: TWorkspaceUser;
  isAccountAdmin: boolean;

  constructor(
    private promptService: PromptService,
    private modalService: ModalService,
    private translationPipe: TranslationPipe,
    private shareBulkService: ShareBulkService,
    private accountSharesService: AccountSharesService,
  ) {}

  ngOnInit() {
    this.modal = this.modalService.getModal();

    this.setModalData();

    this.processing = false;
  }

  private setModalData(): void {
    this.accountId = this.modal.data.accountId;
    this.user = this.modal.data.user;
    this.isAccountAdmin = this.modal.data.isAccountAdmin;
  }

  deleteButtonCallback(): void {
    if (!this.processing) {
      this.processing = true;

      const request = this.isAccountAdmin
        ? this.shareBulkService.removeAccountAdmin(this.user.email)
        : this.shareBulkService.removeAccountUser(this.user.userId, this.accountId);

      request
        .pipe(
          tap(() => {
            this.processRemovedUser();

            this.modalService.hideModal(false, false);
          }),
          catchError((error) => {
            const promptText = this.translationPipe.transform('prompt_share_delete_error');
            this.processing = false;

            logErrorInSentry(error);
            this.promptService.showError(promptText);

            return of();
          }),
        )
        .subscribe();
    }
  }

  private processRemovedUser(): void {
    const promptText = this.translationPipe.transform('prompt_user_account_deleted');
    this.accountSharesService.removeAccountUser(this.accountId, this.user.userId);

    logEventInGTAG(EGoogleEventSettings.SETTINGS__ACCOUNT__USER__DELETE, {
      event_category: EGoogleEventCategory.ACCOUNT,
    });

    this.promptService.showSuccess(promptText);
  }

  hideModal(): void {
    this.modalService.hideModal();
  }
}
