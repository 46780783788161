import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SavedViewsButtonComponent } from './saved-views-button/saved-views-button.component';
import { ButtonModule } from '../../components/button/button.module';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { ModalModule } from '../../components/modal/modal.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { SavedViewsDropdownComponent } from './saved-views-dropdown/saved-views-dropdown.component';
import { SavedViewsOptionsDropdownComponent } from './saved-views-options-dropdown/saved-views-options-dropdown.component';
import { SavedViewsDropdownSwitchComponent } from './saved-views-dropdown/saved-views-dropdown-switch/saved-views-dropdown-switch.component';
import { SavedViewsDropdownRowComponent } from './saved-views-dropdown/saved-views-dropdown-row/saved-views-dropdown-row.component';
import { SavedViewsDropdownNewViewButtonComponent } from './saved-views-dropdown/saved-views-dropdown-new-view-button/saved-views-dropdown-new-view-button.component';
import { SavedViewsListComponent } from './saved-views-dropdown/saved-views-list/saved-views-list.component';
import { IconModule } from '../../components/icon/icon-module';
import { SavedViewsDoneButtonComponent } from './saved-views-dropdown/saved-views-done-button/saved-views-done-button.component';
import { SaveToViewButtonComponent } from './save-to-view-button/save-to-view-button.component';
import { ActiveViewPreviewComponent } from './active-view-preview/active-view-preview.component';
import { SavedViewPreviewHeaderComponent } from './saved-view-preview-header/saved-view-preview-header.component';
import { SpinnerModule } from '../../components/spinner/spinner.module';

@NgModule({
  declarations: [
    SavedViewsButtonComponent,
    SavedViewsDropdownComponent,
    SavedViewsOptionsDropdownComponent,
    SavedViewsDropdownSwitchComponent,
    SavedViewsDropdownRowComponent,
    SavedViewsDropdownNewViewButtonComponent,
    SavedViewsListComponent,
    SavedViewsDoneButtonComponent,
    SaveToViewButtonComponent,
    ActiveViewPreviewComponent,
    SavedViewPreviewHeaderComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    FormsModule,
    PipesModule,
    TooltipsModule,
    ImagesFallbackModule,
    ModalModule,
    PipesModule,
    IconModule,
    SpinnerModule,
  ],
  exports: [SavedViewsButtonComponent, SavedViewPreviewHeaderComponent],
})
export class SavedViewsModule {}
