import { TCombinedCustomField } from '../../../custom-fields/combine-custom-fields/combined-custom-field.model';
import { TFilters } from '../../../filters/site-filter.model';
import { TWorkspacesById } from '../../../workspace/workspace.model';

export function addNewFieldToFilter(
  filters: TFilters,
  combinedCustomField: TCombinedCustomField,
  workspaces: TWorkspacesById,
): void {
  filters.customFields.push({
    id: combinedCustomField.id,
    workspaceId: combinedCustomField.workspaceId,
    appliedValues: {
      min: null,
      max: null,
    },
    min: null,
    max: null,
    sites: combinedCustomField.combinedIds.map((id) => ({
      siteName: workspaces[id.workspaceId].siteName,
      accountName: workspaces[id.workspaceId].accountName,
      workspaceId: id.workspaceId,
      filterId: id.customFieldId,
    })),
  });
}
