import { Injectable } from '@angular/core';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { TFilters } from 'src/app/project/modules/filters/site-filter.model';
import { PermissionsService } from 'src/app/project/modules/share/permissions.service';
import { SiteTablePointsService } from 'src/app/project/modules/site/site-table/site-table-points.service';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { TPoint, TPointsByWorkspace } from '../points/points.model';
import { PointsService } from '../points/points.service';
import { getSelectedPoints } from '../points/selected-points';
import { TableSortingService } from '../site/site-table/columns/table-sorting.service';
import { CustomTableService } from '../site/site-table/custom-table/custom-table.service';
import { TExportPoints } from './export-body.model';
import { TExportDateFilter, TExportFilter } from './utils/export-filter.model';
import { getOrderedBy } from './utils/get-ordered-by';

@Injectable({
  providedIn: 'root',
})
export class PointExportFilterService {
  constructor(
    private permissionsService: PermissionsService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private workspaceService: WorkspaceService,
    private pointsService: PointsService,
    private siteTablePointsService: SiteTablePointsService,
    private customTableService: CustomTableService,
    private tableSortingService: TableSortingService,
  ) {}

  filterPoints(points: TPoint[]): TPoint[] {
    const originalLength = points.length;

    points = points.filter((_point) =>
      // Remove points that don't have export enabled in AAL
      this.permissionsService.areExportsEnabled(_point.workspaceRef.id),
    );

    const filteredLength = points.length;

    if (originalLength !== filteredLength) {
      const prompt = this.translationPipe.transform('export_filtered_warning');

      this.promptService.showWarning(prompt);
    }

    return points;
  }

  generateFilter(filters: TFilters): TExportFilter {
    const priorities = this.getPriorities(filters);
    const statuses = this.getStatuses(filters);
    const tags = this.getTags(filters);
    const excludedTags = this.getExcludedTags(filters);
    const createdDate = this.getCreatedDate(filters);
    const updatedDate = this.getUpdatedDate(filters);
    const orderedBy = getOrderedBy(this.tableSortingService.getSorting());
    const pointsWithoutLocation = filters.showPointsWithoutLocation;
    const pointsWithLocation = filters.showPointsWithLocation;
    const showFlaggedPoints = filters.showFlaggedPoints;
    const showUnflaggedPoints = filters.showUnflaggedPoints;
    const reminders = filters.reminders;

    const exportFilters: TExportFilter = {
      priority: priorities.join(','),
      status: statuses.join(','),
      tags,
      orderedBy,
      excludedTags,
      createdDate,
      updatedDate,
      pointsWithoutLocation,
      pointsWithLocation,
      showUnflaggedPoints,
      showFlaggedPoints,
      reminders,
    };

    return exportFilters;
  }

  getPriorities(filters: TFilters): string[] {
    const priorityObjects = filters.priority.filter((priority) => priority.appliedValue);
    const priorities = priorityObjects.map((priority) => priority.label);

    return priorities;
  }

  getStatuses(filters: TFilters): string[] {
    const statusObjects = filters.status.filter((status) => status.appliedValue);
    const statuses = statusObjects.map((status) => status.label);

    return statuses;
  }

  getTags(filters: TFilters): string {
    let tagString = '';

    if (filters.tags.appliedValue) {
      if (filters.tags.appliedValue.length > 0) {
        tagString = filters.tags.appliedValue.toString();

        if (!filters.tags.combinedTags) {
          tagString = tagString.split(',').join('|');
        }
      }
    }

    return tagString;
  }

  getExcludedTags(filters: TFilters): string {
    let tagString = '';

    if (filters.excludedTags.appliedValue) {
      if (filters.excludedTags.appliedValue.length > 0) {
        tagString = filters.excludedTags.appliedValue.toString();

        if (!filters.excludedTags.combinedTags) {
          tagString = tagString.split(',').join('|');
        }
      }
    }

    return tagString;
  }

  getCreatedDate(filters: TFilters): TExportDateFilter {
    return filters.date.created.appliedValues;
  }

  getUpdatedDate(filters: TFilters): TExportDateFilter {
    return filters.date.updated.appliedValues;
  }

  filterOverviewPoints(): TExportPoints {
    const allPoints = this.pointsService.getAllPoints();
    const workspaces = this.workspaceService.getWorkspaces();
    const points: TExportPoints = {};
    const selectedPointsIds = getSelectedPoints();

    let selectedPoints: TPoint[] = [];

    Object.keys(allPoints).forEach((workspaceId) => {
      selectedPoints.push(
        ...allPoints[workspaceId].entities.filter((point) => selectedPointsIds.includes(point._id)),
      );
    });

    if (selectedPoints.length > 0) {
      selectedPoints = this.sortSelectedPoints(selectedPoints, points, workspaces);
    } else {
      let visiblePoints = this.customTableService.getTable().points;
      const visiblePointsIds = this.customTableService.getTable().getVisiblePointIds();

      Object.keys(allPoints).forEach((workspaceId) => {
        visiblePoints = [
          ...visiblePoints,
          ...allPoints[workspaceId].entities.filter((point) =>
            visiblePointsIds.includes(point._id),
          ),
        ];
      });

      visiblePoints = this.filterPoints(visiblePoints);

      if (visiblePoints.length > 0) {
        this.sortVisiblePoints(points, workspaces);
      } else {
        this.sortAllPoints(allPoints, points, workspaces);
      }
    }

    return points;
  }

  private sortSelectedPoints(
    selectedPoints: TPoint[],
    points: TExportPoints,
    workspaces: TWorkspacesById,
  ): TPoint[] {
    selectedPoints = this.filterPoints(selectedPoints);

    const sortedSelectedPoints = this.siteTablePointsService.sortPoints(selectedPoints);

    sortedSelectedPoints.forEach((point) => {
      if (!points[point.workspaceRef.id]) {
        points[point.workspaceRef.id] = {};
      }

      if (points[point.workspaceRef.id].points) {
        points[point.workspaceRef.id].points.push(point._id);
      } else {
        points[point.workspaceRef.id].points = [point._id];
      }

      if (!points[point.workspaceRef.id].extent) {
        points[point.workspaceRef.id].extent = this.getWorkspaceExtent(
          workspaces,
          point.workspaceRef.id,
        );
      }
    });

    return selectedPoints;
  }

  private getWorkspaceExtent(workspaces: TWorkspacesById, workspaceId: string): number[] {
    if (workspaces[workspaceId].sitePlan) {
      return workspaces[workspaceId].sitePlan.extent;
    } else {
      return null;
    }
  }

  private sortAllPoints(
    allPoints: TPointsByWorkspace,
    points: TExportPoints,
    workspaces: TWorkspacesById,
  ): void {
    Object.keys(allPoints).forEach((workspaceId) => {
      if (this.permissionsService.areExportsEnabled(workspaceId)) {
        if (!points[workspaceId]) {
          points[workspaceId] = {};
        }

        points[workspaceId].points = allPoints[workspaceId].entities.map((point) => point._id);

        if (!points[workspaceId].extent) {
          points[workspaceId].extent = this.getWorkspaceExtent(workspaces, workspaceId);
        }
      }
    });
  }

  private sortVisiblePoints(points: TExportPoints, workspaces: TWorkspacesById): void {
    const table = this.customTableService.getTable();
    const sortedVisiblePoints = table.points;

    sortedVisiblePoints.forEach((point) => {
      if (this.permissionsService.areExportsEnabled(point.workspaceRef.id)) {
        if (!points[point.workspaceRef.id]) {
          points[point.workspaceRef.id] = {};
        }

        if (points[point.workspaceRef.id].points) {
          points[point.workspaceRef.id].points.push(point._id);
        } else {
          points[point.workspaceRef.id].points = [point._id];
        }

        if (!points[point.workspaceRef.id].extent) {
          points[point.workspaceRef.id].extent = this.getWorkspaceExtent(
            workspaces,
            point.workspaceRef.id,
          );
        }
      }
    });
  }
}
