import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  TEntity,
  TEntityWithPagination,
  fetchDelete,
  fetchFile,
  fetchGet,
  fetchPost,
  fetchPostWithFormData,
  fetchPut,
} from '@core/helpers';
import { Observable, OperatorFunction, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { ApiHelper } from './api.helper';
import { getAuthHeaders } from './user-auth';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  protected constructor(private http: HttpClient) {}

  public getWithPagination<T = any>(url: string): Observable<TEntityWithPagination<T>> {
    return from(fetchGet(url)) as Observable<TEntityWithPagination<T>>;
  }

  public get<T = any>(url: string): Observable<T> {
    return from(fetchGet(url)).pipe(this.entityMapOperator<T>());
  }

  getSvg(path: EIconPath): Promise<Response> {
    return fetch(path);
  }

  getFile(path: string): Observable<Response> {
    return from(fetchFile(path)).pipe(
      map((response) => {
        if (response.ok) {
          return response;
        } else {
          response.text().then((text) => {
            logErrorInSentry(text);
          });

          return null;
        }
      }),
    );
  }

  public post<T = any>(url: string, data: object | string = {}): Observable<T> {
    return from(fetchPost(url, data)).pipe(this.entityMapOperator<T>());
  }

  public postWithFormData<T = any>(url: string, data: FormData): Observable<T> {
    return from(fetchPostWithFormData(url, data)).pipe(this.entityMapOperator<T>());
  }

  public put<T = any>(url: string, data: object | string = {}): Observable<T> {
    return from(fetchPut(url, data)).pipe(this.entityMapOperator<T>());
  }

  public delete<T = void>(url: string, body?: unknown): Observable<T> {
    const options: { headers: HttpHeaders; body?: any } = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        ...getAuthHeaders(),
      }),
    };

    if (body) {
      options.body = body;
    }

    return from(fetchDelete(url, options.body)).pipe(this.entityMapOperator<T>());
  }

  public download(url: string, body?: BodyInit): Observable<Response> {
    return from(
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          ['Content-Type']: 'application/json',
          ...getAuthHeaders(),
        },
      }),
    );
  }

  public downloadGET(url: string): Observable<Response> {
    return from(
      fetch(url, {
        method: 'GET',
        headers: {
          ['Content-Type']: 'application/json',
          ...getAuthHeaders(),
        },
      }),
    );
  }

  private entityMapOperator<T>(): OperatorFunction<TEntity<T>, T> {
    return map((dto: TEntity<T>) => ApiHelper.fromDTO(dto));
  }
}
