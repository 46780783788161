import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalleryOverlayComponent } from './gallery-overlay.component';
import { GalleryOverlayAnnotationsLayerComponent } from './gallery-overlay-annotations-layer/gallery-overlay-annotations-layer.component';
import { GalleryOverlaySphericalComponent } from './gallery-overlay-spherical/gallery-overlay-spherical.component';
import { GalleryOverlayVideoComponent } from './gallery-overlay-video/gallery-overlay-video.component';
import { ImageAnnotationsArrowService } from './image-annotations/image-annotations-arrow.service';
import { ImageAnnotationsService } from './image-annotations/image-annotations.service';
import { ImageAnnotationsEllipseService } from './image-annotations/image-annotations-ellipse.service';
import { ImageAnnotationsStateService } from './image-annotations/image-annotations-state.service';
import { ImageAnnotationsTextService } from './image-annotations/image-annotations-text.service';
import { ImageAnnotationsComponent } from './image-annotations/image-annotations.component';
import { ImageRotationComponent } from './image-rotation/image-rotation.component';
import { ImageRotationService } from './image-rotation/image-rotation.service';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { ButtonModule } from '../button/button.module';
import { ModalModule } from '../modal/modal.module';
import { GalleryOverlayService } from './gallery-overlay.service';
import { AutonumericModule } from '../../directives/autonumeric/autonumeric.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SwitchCasesModule } from '../../directives/switch-cases/switch-cases.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { SecureSrcModule } from '../../directives/secure-src/secure-src.module';

@NgModule({
  declarations: [
    GalleryOverlayComponent,
    GalleryOverlayAnnotationsLayerComponent,
    GalleryOverlaySphericalComponent,
    GalleryOverlayVideoComponent,
    ImageAnnotationsComponent,
    ImageRotationComponent,
  ],
  imports: [
    CommonModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    PipesModule,
    ButtonModule,
    ModalModule,
    SecureSrcModule,
  ],
  exports: [
    GalleryOverlayComponent,
    GalleryOverlayAnnotationsLayerComponent,
    GalleryOverlaySphericalComponent,
    GalleryOverlayVideoComponent,
    ImageAnnotationsComponent,
    ImageRotationComponent,
  ],
  providers: [
    ImageAnnotationsArrowService,
    ImageAnnotationsEllipseService,
    ImageAnnotationsStateService,
    ImageAnnotationsTextService,
    ImageAnnotationsService,
    ImageRotationService,
    GalleryOverlayService,
  ],
})
export class GalleryOverlayModule {}
