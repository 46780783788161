import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';

@Component({
  selector: 'pp-custom-fields-checkbox',
  templateUrl: './custom-fields-checkbox.component.html',
  styleUrls: ['./custom-fields-checkbox.component.scss'],
})
export class CustomFieldsCheckboxComponent implements OnInit, AfterViewInit {
  @Input() ppField: TPointCustomField;
  @Input() ppValue: boolean;
  @Input() ppReadOnly = false;
  @Input() ppUpdating: boolean | string = false;
  @Output() ppUpdate = new EventEmitter();

  value = false;

  constructor() {}

  ngOnInit(): void {
    this.value = this.ppValue;
  }

  ngAfterViewInit(): void {}

  clearCustomField(): void {
    this.value = null;

    this.updateField();
  }

  updateField(): void {
    if (this.ppUpdate) {
      this.value = !this.value;

      this.ppUpdate.emit(this.value);
    }
  }
}
