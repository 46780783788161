import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TSelectOption } from 'src/app/project/components/input/select/select.model';
import { GET_CUSTOM_FIELDS } from 'src/app/project/modules/custom-fields/custom-fields.store';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';
import {
  TAdvancedFilterNumber,
  TAdvancedFilterSingle,
  TFilterNumberOptions,
} from '../../../../models/advanced-filter.model';
import { ENumberInputUnitPosition } from '../../../input-fields/advanced-filter-number-input/number-input.model';
import {
  getAdvancedNumbersOptions,
  getAdvancedSequenceNumbersOptions,
} from './advanced-filter-number-utils';

@Component({
  selector: 'pp-advanced-filter-number',
  templateUrl: './advanced-filter-number.component.html',
  styleUrls: ['./advanced-filter-number.component.scss'],
})
export class AdvancedFilterNumberComponent {
  @Input() ppFilter: TAdvancedFilterSingle;
  @Output() ppFilterChange = new EventEmitter<TAdvancedFilterSingle>();
  @Input() ppType: 'number' | 'sequence_number' | 'cost';

  value: number;
  option: TFilterNumberOptions;
  filter: TAdvancedFilterNumber;
  unit: string;
  unitPosition: ENumberInputUnitPosition;

  advancedFilterNumbersOptions: TSelectOption[];
  EAdvancedFilterOptions = EAdvancedFilterOptions;

  ngOnChanges(): void {
    this.filter = this.ppFilter as TAdvancedFilterNumber;
    this.advancedFilterNumbersOptions =
      this.ppType === 'sequence_number'
        ? getAdvancedSequenceNumbersOptions()
        : getAdvancedNumbersOptions();

    this.unitPosition =
      this.ppType === 'cost' ? ENumberInputUnitPosition.LEFT : ENumberInputUnitPosition.RIGHT;

    this.value = this.filter.value;
    this.option = this.filter.option;
    const field = GET_CUSTOM_FIELDS()[this.filter.id];

    // ID is not a CF
    if (field) {
      this.unit = field.currencySymbol
        ? field.currencyCode + ' ' + field.currencySymbol
        : field.unit; // Makes it work for both cost and numbers
    }
  }

  changeFieldCondition(newCondition: TFilterNumberOptions): void {
    this.ppFilter.option = newCondition;
    this.option = newCondition;
    this.applyChanges();
  }

  applyChanges(): void {
    this.ppFilterChange.emit(this.ppFilter);
  }

  changeFieldValue(value: number): void {
    this.ppFilter.value = value;
    this.applyChanges();
  }
}
