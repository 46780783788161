import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSettings,
} from 'src/app/project/services/analytics/google-analytics.consts';

export function sendImportEvent(fileImport: boolean): void {
  let source = 'site';

  if (fileImport) {
    source = 'file';
  }

  logEventInGTAG(EGoogleEventSettings.SETTINGS__TAGS__IMPORT, {
    event_category: EGoogleEventCategory.SETTINGS,
    event_details: source,
  });
}
