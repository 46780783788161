import { Injectable } from '@angular/core';

import { ResponseErrorService } from '../../errors/response-error.service';

import { AuthApiProviderService } from '@core/api';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VerifyService {
  constructor(
    private authApiProviderService: AuthApiProviderService,
    private responseErrorService: ResponseErrorService,
  ) {}

  verify(id: string): Observable<null> {
    return this.authApiProviderService
      .verify(id)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }
}
