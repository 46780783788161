import { Component, Input, OnInit } from '@angular/core';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { TFileChangeInfo, TPushNotification } from '../../notification.model';
import { getNotificationReasonText } from '../../utils/notification-reason';

@Component({
  selector: 'pp-notification-videos',
  templateUrl: './notification-videos.component.html',
  styleUrls: ['../notification.component.scss'],
})
export class NotificationVideosComponent implements OnInit {
  @Input() ppNotification: TPushNotification<TFileChangeInfo[]>;

  notificationReason: string;
  EIconPath = EIconPath;

  ngOnInit(): void {
    this.notificationReason = getNotificationReasonText(this.ppNotification.notificationReason);
  }
}
