import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { PointHalfModalService } from '../points/point-half-modal/point-half-modal.service';
import { SiteDataService, TSiteData } from '../site/site-data.service';
import { SiteTablePointsService } from '../site/site-table/site-table-points.service';
import { PlanDataService, TPlanData } from './plan-data.service';
import { PlanPinsService } from './plan-pins.service';
import { PlanService } from './plan.service';

import { TAnyFunction } from '@core/helpers';
import { MapBrowserEvent } from 'ol';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { EPlanFeatureType } from '../../shared/enums/plan-feature-type.enum';
import { CustomTableService } from '../site/site-table/custom-table/custom-table.service';
import { TFeatureGeometry, TPlanFeature } from './plan-feature.model';
import { EPlanModule } from './plan-module.enum';

@Injectable({
  providedIn: 'root',
})
export class PlanSiteService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly filter$ = new Subject<void>();

  private plan: TPlanData;
  private site: TSiteData = this.siteDataService.getSite();

  private readonly pointermoveListenerRef: TAnyFunction;
  private readonly clickListenerRef: TAnyFunction;
  private readonly dblclickListenerRef: TAnyFunction;
  private readonly moveendListenerRef: TAnyFunction;
  private previouslyHighlightedFeature: TPlanFeature = null;
  private filterDebounceTimeMs = 150;

  constructor(
    private router: Router,
    private planService: PlanService,
    private planDataService: PlanDataService,
    private planPinsService: PlanPinsService,
    private sitePointFilterService: SitePointFilterService,
    private siteTablePointsService: SiteTablePointsService,
    private siteDataService: SiteDataService,
    private pointHalfModalService: PointHalfModalService,
    private customTableService: CustomTableService,
  ) {
    this.plan = this.planDataService.getPlan();
    this.plan.resolution = 0;

    this.pointermoveListenerRef = (event): void => this.pointermoveListener(event);
    this.clickListenerRef = (event): void => this.clickListener(event);
    this.dblclickListenerRef = (event): void => this.dblclickListener(event);
    this.moveendListenerRef = (): void => this.moveendListener();

    this.filter$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(this.filterDebounceTimeMs),
        tap(() => {
          this.filterPointList();
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  // pointermove

  pointermoveEventOff(): void {
    this.plan.instance.site.un('pointermove', this.pointermoveListenerRef);
  }

  pointermoveEventOn(): void {
    this.plan.instance.site.on('pointermove', this.pointermoveListenerRef);
  }

  pointermoveListener(event): void {
    let highlightedFeature = null;

    if (event.dragging) {
      this.filter$.next();

      return;
    }

    const table = this.customTableService.getTable();
    const previouslySequenceNumber = this.previouslyHighlightedFeature
      ? this.previouslyHighlightedFeature.point.sequenceNumber
      : null;

    const hit = this.plan.instance.site.forEachFeatureAtPixel(
      event.pixel,
      (feature: TPlanFeature) => {
        highlightedFeature = feature;

        if (feature.visible) {
          this.planPinsService.enlargePin(+feature.point.sequenceNumber);

          if (table && highlightedFeature.point.sequenceNumber !== previouslySequenceNumber) {
            table.highlightPoint(+feature.point.sequenceNumber);
          }

          return true;
        }

        return false;
      },
    );

    const highlightedSequenceNumber = highlightedFeature
      ? highlightedFeature.point.sequenceNumber
      : null;

    if (
      table &&
      this.previouslyHighlightedFeature &&
      highlightedSequenceNumber !== previouslySequenceNumber
    ) {
      table.dehighlightPoint(+this.previouslyHighlightedFeature.point.sequenceNumber);
    }

    this.previouslyHighlightedFeature = highlightedFeature;

    if (this.plan.enlargedPins && !highlightedFeature) {
      this.siteTablePointsService.dehighlightPoint();

      this.plan.enlargedPins.forEach((pin) => {
        pin.setStyle(this.planPinsService.createNormalStyle(pin.point.priority));
      });

      this.plan.enlargedPins = [];
    }

    if (hit) {
      this.plan.element.style.cssText = 'cursor: pointer';
    } else {
      this.plan.element.style.cssText = 'cursor: normal';
    }
  }

  // click

  clickEventOff(): void {
    this.plan.instance.site.un('click', this.clickListenerRef);
  }

  clickEventOn(): void {
    this.plan.instance.site.on('click', this.clickListenerRef);
  }

  clickListener(event: MapBrowserEvent<any>): void {
    const features = this.plan.instance.site.getFeaturesAtPixel(event.pixel) as TPlanFeature[];

    let featureType = EPlanFeatureType.POLYGON;

    features.forEach((feature) => {
      if ((feature.getGeometry() as TFeatureGeometry).flatCoordinates.length === 2) {
        featureType = EPlanFeatureType.POINT;
      }
    });

    for (let i = 0; i < features.length; i++) {
      const feature = features[i];
      let type = EPlanFeatureType.POLYGON;

      this.plan.element.style.cssText = 'cursor: normal';

      if ((feature.getGeometry() as TFeatureGeometry).flatCoordinates.length === 2) {
        type = EPlanFeatureType.POINT;
      }

      if (featureType === type) {
        if (this.site.sitePlanFullWidth) {
          this.pointHalfModalService.hideModal();
          this.router.navigate([
            '/site',
            feature.point.workspaceRef.id,
            { outlets: { full: ['point', feature.point._id] } },
          ]);
        } else {
          this.router.navigate([
            '/site',
            feature.point.workspaceRef.id,
            'point',
            feature.point._id,
          ]);
        }

        break;
      }
    }
  }

  // dblclick

  dblclickEventOff(): void {
    this.plan.instance.site.un('dblclick', this.dblclickListenerRef);
  }

  dblclickEventOn(): void {
    this.plan.instance.site.on('dblclick', this.dblclickListenerRef);
  }

  dblclickListener(event: MouseEvent): void {
    this.planService.fitPlan(EPlanModule.SITE);

    this.planDataService.setPlan({
      resolution: this.plan.instance.site.getView().getResolution(),
    });

    this.filterPointList();

    event.preventDefault();
  }

  // moveend

  moveendEventOff(): void {
    this.plan.instance.site.un('moveend', this.moveendListenerRef);
  }

  moveendEventOn(): void {
    this.plan.instance.site.on('moveend', this.moveendListenerRef);
  }

  moveendListener(): void {
    this.filterPointList();

    if (this.plan.zoom !== this.plan.instance.site.getView().getZoom()) {
      this.planDataService.setPlan({
        zoom: this.plan.instance.site.getView().getZoom(),
        extent: this.plan.instance.site
          .getView()
          .calculateExtent(this.plan.instance.site.getSize()),
      });
    }

    if (
      this.plan.center[0] !== this.plan.instance.site.getView().getCenter()[0] ||
      this.plan.center[1] !== this.plan.instance.site.getView().getCenter()[1]
    ) {
      this.planDataService.setPlan({
        center: this.plan.instance.site.getView().getCenter(),
        extent: this.plan.instance.site
          .getView()
          .calculateExtent(this.plan.instance.site.getSize()),
      });
    }
  }

  //

  private filterPointList(): void {
    const extent = this.plan.instance.site
      .getView()
      .calculateExtent(this.plan.instance.site.getSize());

    this.planDataService.setPlan({ extent });
    this.sitePointFilterService.filterPoints({ _keepScrollPosition: true });
  }
}
