import { Component, OnDestroy, OnInit } from '@angular/core';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { TAccount } from 'src/app/project/modules/account/account.model';
import { TUser } from 'src/app/project/modules/user/user.model';

import { DeleteReminderModalComponent } from '../../delete-reminder-modal/delete-reminder-modal.component';
import { ReminderPointModalComponent } from '../../reminder-point-modal/reminder-point-modal.component';

import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { TReminderPointModalData } from '../../reminder-point-modal/reminder-point-modal.model';
import { TReminderSettingsDropdownData } from './reminder-settings-dropdown.model';

@Component({
  selector: 'pp-reminder-settings-dropdown',
  templateUrl: './reminder-settings-dropdown.component.html',
  styleUrls: ['./reminder-settings-dropdown.component.scss'],
})
export class ReminderSettingsDropdownComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  dropdown: TDropdown;
  user: TUser;
  account: TAccount;

  reminderId: string;
  date = null;
  _id;
  pointName = '';
  note = '';

  constructor(
    private dropdownService: DropdownService,
    private modalService: ModalService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.dropdown = this.dropdownService.getDropdown();

    if (!this.dropdown.data) {
      throw new Error('Data for this dropdown is required');
    }

    const dropdownData: TReminderSettingsDropdownData = this.dropdown.data;

    this.reminderId = dropdownData.reminderId;
    this.date = dropdownData.date;
    this.note = dropdownData.note;
    this._id = dropdownData._id;
    this.pointName = dropdownData.pointName;

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.dropdownService.hideDropdown();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  hideDropdown(): void {
    this.dropdownService.hideDropdown();
  }

  editReminder(): void {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const fieldValue = new Date(this.date);
    fieldValue.setHours(0, 0, 0, 0);

    const allowPastDates = today.getTime() > fieldValue.getTime();

    const modalData: TReminderPointModalData = {
      _id: this._id,
      reminderValue: this.date,
      reminderId: this.reminderId,
      note: this.note,
      allowPastDates: allowPastDates,
    };

    this.modalService.setData(modalData);

    this.modalService.showModal(ReminderPointModalComponent);

    this.hideDropdown();
  }

  deleteReminder(): void {
    this.modalService.setData({
      _id: this._id,
      reminderValue: this.date,
      pointName: this.pointName,
      reminderId: this.reminderId,
    });

    this.modalService.showModal(DeleteReminderModalComponent);

    this.hideDropdown();
  }
}
