import { splitYearIntoWeeks } from './split-year-into-weeks';

export function getDayTimeframes(from: Date, to: Date): number[] {
  let elements = [];

  const weeks = splitYearIntoWeeks(from, to);

  weeks.forEach((_week) => {
    const day = _week[0];

    const weekDays = [];

    while (day <= _week[1]) {
      day.setDate(day.getDate() + 1);

      weekDays.push(day.getTime());
    }

    elements = [...elements, ...weekDays];
  });

  return elements;
}
