import { TCustomField } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { TColumn } from '../../../columns/column.model';
import { GET_COLUMNS } from '../../../columns/columns.store';

export function checkColumnsToAdd(customFields: TCustomField[]): TColumn[] {
  const columnsToAdd = [];

  const visibleCustomFields = customFields.filter((customField) => customField.display);

  visibleCustomFields.forEach((customField) => {
    const column = GET_COLUMNS().find(
      (searchedColumn) => searchedColumn.name === customField.label,
    );

    const columnToAdd = columnsToAdd.find(
      (searchedColumnToAdd) =>
        searchedColumnToAdd.label === customField.label &&
        searchedColumnToAdd.unit === customField.unit &&
        searchedColumnToAdd.currency === customField.currencyCode,
    );

    if (columnToAdd) {
      if (!columnToAdd.customFieldIds.includes(customField.id.toString())) {
        columnToAdd.customFieldIds.push(customField.id.toString());
      }

      if (customField.showTotal || typeof customField.showTotal === 'undefined') {
        columnToAdd.showTotal = true;
      }
    } else if (!column && !columnToAdd) {
      columnsToAdd.push({
        name: customField.label,
        showTotal: customField.showTotal === true,
        customFieldIds: [customField.id.toString()],
        unit: customField.unit,
        decimalPlaces: customField.decimalPlaces,
        currency: customField.currencyCode,
      } as TColumn);
    }
  });

  return columnsToAdd;
}
