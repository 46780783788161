<article>
  <div class="browsersPage__background"></div>
  <section class="browsersPage__section">
    <img class="browsersPage__logo" [src]="EIconPath.LOGO" />
    <div class="browsersPage__browsers-box">
      <h4>{{ 'browser_not_supported' | ppTranslate }}</h4>

      <ul class="browsersPage__browsers">
        <h6>{{ 'recommend_browsers' | ppTranslate }}</h6>
        <div>
          <ng-container *ngFor="let browser of recommendedBrowsers">
            <li>
              <img [src]="browser.imageSrc" />
              {{ browser.browserName }}
            </li>
          </ng-container>
        </div>
      </ul>

      <ul class="browsersPage__browsers">
        <h6>{{ 'we_also_support' | ppTranslate }}</h6>
        <div>
          <ng-container *ngFor="let browser of supportedBrowsers">
            <li>
              <img [src]="browser.imageSrc" />
              {{ browser.browserName }}
            </li>
          </ng-container>
        </div>
      </ul>

      <footer>
        <a routerLink="/"> {{ 'ignore' | ppTranslate }} </a>
      </footer>
    </div>
  </section>
</article>
