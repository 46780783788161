import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { PromptService } from '../../components/prompt/prompt.service';

import { throwError } from 'rxjs';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { routeToUrl } from 'src/app/core/helpers/route-to-url';
import { TranslationPipe } from '../../features/translate/translation.pipe';
import { EAuthRoute } from '../../shared/constants/auth.constants';
import { logout$ } from '../auth/logout.service';
import { SUPPORTED_BROWSERS_URL } from '../supported-browsers/supported-browsers.constants';
import { logErrorInSentry } from './response-error';

@Injectable({
  providedIn: 'root',
})
export class ResponseErrorService {
  private serverMaintenance = false;

  private error: {
    redirected: boolean;
    priority: number;
  };

  constructor(
    private router: Router,
    private promptService: PromptService,
    private translationPipe: TranslationPipe,
  ) {
    this.error = {
      redirected: false,
      priority: null,
    };
  }

  errors(status, priority?) {
    this.error.priority = priority;

    if (status === EStatusCode.UNAUTHORIZED) {
      if (
        this.router.url !== '/' &&
        this.router.url !== routeToUrl(EAuthRoute.LOGIN) &&
        this.router.url !== SUPPORTED_BROWSERS_URL &&
        this.router.url !== routeToUrl(EAuthRoute.SIGNUP) &&
        !this.router.url.startsWith('/verify') &&
        this.router.url !== '/reset-password' &&
        !this.router.url.startsWith('/reset') &&
        !this.router.url.startsWith('/passwords') &&
        !this.router.url.startsWith(routeToUrl(EAuthRoute.TWO_FACTOR_AUTHENTICATION_SETUP))
      ) {
        logout$.next();
      }
    } else if (status === 502 && !this.serverMaintenance) {
      const prompt = this.translationPipe.transform('prompt_maintenance');

      this.promptService.showWarning(prompt, { duration: 30 });

      this.serverMaintenance = true;
    }
  }

  logRequestError(error): void {
    this.errors(error.status);
    logErrorInSentry(error);
  }

  handleRequestError = (error) => {
    this.logRequestError(error);

    return throwError(error);
  };
}
