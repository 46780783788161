<div *ngIf="ppChange.propName === 'videos'" class="dashletActivity">
  <div class="dashletActivity__container">
    <ng-container *ngIf="ppChange.oldValue.length < ppChange.newValue.length">
      <img class="dashletActivity__icon" [src]="EIconPath.BASIC_FIELD_VIDEO" />

      <div class="dashletActivity__text">
        {{ 'added' | ppTranslate }}
        <span class="timeline__strong">{{
          ppChange.newValue.length - ppChange.oldValue.length
        }}</span>
        {{
          ppChange.newValue.length - ppChange.oldValue.length > 1
            ? ('videos' | ppTranslate | lowercase)
            : ('video' | ppTranslate | lowercase)
        }}.
      </div>
    </ng-container>

    <ng-container *ngIf="ppChange.newValue.length < ppChange.oldValue.length">
      <img class="dashletActivity__icon" [src]="EIconPath.BASIC_FIELD_VIDEO" />

      <div class="dashletActivity__text">
        {{ 'removed' | ppTranslate }}
        <span class="timeline__strong">{{
          ppChange.oldValue.length - ppChange.newValue.length
        }}</span>
        {{
          ppChange.oldValue.length - ppChange.newValue.length > 1
            ? ('videos' | ppTranslate | lowercase)
            : ('video' | ppTranslate | lowercase)
        }}.
      </div>
    </ng-container>
  </div>
</div>
