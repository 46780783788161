<pp-advanced-filter-option-select
  class="advancedFilter__option"
  [ppItems]="advancedFilterTimelineDates"
  [ppOption]="selectedDate"
  (ppOptionChange)="changeFieldDate($event)"
  ppClass="advancedFilter__option--condition"
></pp-advanced-filter-option-select>

<div class="advancedFilter__dateContainer">
  <pp-advanced-filter-option-select
    class="advancedFilter__option"
    [ppItems]="advancedFilterTimelineOptions"
    [ppOption]="option"
    (ppOptionChange)="changeFieldCondition($event)"
    ppClass="advancedFilter__option--minWidth"
    *ngIf="
      filter.date === EAdvancedFilterTimelineDate.START_DATE ||
      filter.date === EAdvancedFilterTimelineDate.END_DATE
    "
  ></pp-advanced-filter-option-select>

  <pp-datepicker
    [ppDate]="startDate"
    (ppSelect)="changeFieldValue($event, 0)"
    [ppPlaceholder]="
      (option === EAdvancedFilterOptions.IS_BETWEEN ? 'from...' : 'select_date') | ppTranslate
    "
    *ngIf="
      option === EAdvancedFilterOptions.IS_BETWEEN ||
      option === EAdvancedFilterOptions.IS_BEFORE ||
      option === EAdvancedFilterOptions.IS_AFTER ||
      option === EAdvancedFilterOptions.IS
    "
  ></pp-datepicker>

  <div *ngIf="option === EAdvancedFilterOptions.IS_BETWEEN">-</div>

  <pp-datepicker
    [ppDate]="endDate"
    (ppSelect)="changeFieldValue($event, 1)"
    [ppPlaceholder]="'to...' | ppTranslate"
    *ngIf="option === EAdvancedFilterOptions.IS_BETWEEN"
  ></pp-datepicker>
</div>
