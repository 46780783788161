<div id="filterDropdown" class="siteFilters__dropdown">
  <ul class="dropdownWrapper">
    <li
      class="dropdownItem"
      (click)="bulkChangeFlag()"
      [class.floatingActions_action--disabled]="pointsHaveDifferentFlags || !canEditFlags"
      [ppTitle]="flagTooltipText"
      ppTooltip
    >
      {{ 'flags' | ppTranslate }}
    </li>

    <li class="dropdownItem" (click)="bulkChangePriority()">{{ 'priority' | ppTranslate }}</li>

    <li class="dropdownItem" (click)="bulkChangeStatus()">{{ 'status' | ppTranslate }}</li>

    <li class="dropdownItem" (click)="bulkChangeCustomFields()" *ngIf="canEditCustomFields">
      {{ 'custom_fields' | ppTranslate }}
    </li>

    <li class="dropdownItem" (click)="bulkAddTags()" *ngIf="canEditTags">
      {{ 'tags' | ppTranslate }}
    </li>

    <li class="dropdownItem" (click)="bulkChangeAssignees()">{{ 'assignees' | ppTranslate }}</li>
  </ul>
</div>
