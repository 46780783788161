import { Injectable } from '@angular/core';
import { EPlanModule } from '../plan-module.enum';
import { PlanService } from '../plan.service';
import { GET_ACTIVE_PLAN } from '../plan.store';
import { PlanPointVariablesService } from './plan-point-variables.service';

@Injectable({
  providedIn: 'root',
})
export class PlanPointDoubleClickService {
  constructor(
    private planPointVariablesService: PlanPointVariablesService,
    private planService: PlanService,
  ) {}

  // dblclick

  dblclickEventOn(): void {
    const plan = this.planPointVariablesService.getPlan();
    const dblclickListenerRef = this.planPointVariablesService.getDblclickListenerRef();

    plan.instance.point.on('dblclick', dblclickListenerRef);
  }

  dblclickEventOff(): void {
    const plan = this.planPointVariablesService.getPlan();
    const dblclickListenerRef = this.planPointVariablesService.getDblclickListenerRef();

    plan.instance.point.un('dblclick', dblclickListenerRef);
  }

  dblclickListener(event): void {
    const plan = this.planPointVariablesService.getPlan();
    const activePlan = GET_ACTIVE_PLAN();

    if (!activePlan.drawingPolygon) {
      this.planService.fitPlan(EPlanModule.POINT);

      plan.resolution = plan.instance.point.getView().getResolution();
    }

    event.preventDefault();
  }
}
