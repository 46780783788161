import { createElement } from '@core/helpers';
import { ApiService } from '@core/http';
import { getSvg } from 'src/app/core/helpers/get-svg';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

export function createDragIndicatorElement(apiService: ApiService): HTMLElement {
  const dragIndicatorElementImage = createElement('img', {
    attrs: {
      src: EIconPath.INTERACTION_DRAG_INDICATOR,
    },
  });

  const dragIndicatorElement = createElement('div', {
    attrs: {
      class: ['siteNav__drag-icon'],
    },
    children: [dragIndicatorElementImage],
  });

  getSvg(apiService, EIconPath.ICON_INTERACTION_DRAG_INDICATOR).then((svg) => {
    dragIndicatorElement.innerHTML = svg;
  });

  return dragIndicatorElement;
}
