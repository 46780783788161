<div class="full-height">
  <pp-supported-browsers-bar></pp-supported-browsers-bar>
  <pp-action-bar></pp-action-bar>
  <pp-reminder-alert></pp-reminder-alert>
  <pp-top-bar></pp-top-bar>
  <pp-side-panel></pp-side-panel>

  <div
    id="site"
    class="site"
    [class.site--no-sidebar]="sidePanel.hidden"
    [class.site--down]="ui.actionBar"
    *ngIf="!offline"
  >
    <div id="siteHeader" data-m-target="Work list menu" class="site__header">
      <pp-timeline-options [ppDataFetched]="dataFetched"></pp-timeline-options>
    </div>

    <div class="site__body">
      <div id="timelineView" data-m-target="Work list timeline" class="site__table-wrapper">
        <pp-site-table-timeline *ngIf="dataFetched"></pp-site-table-timeline>

        <div *ngIf="!dataFetched">
          <pp-site-table-placeholder></pp-site-table-placeholder>
        </div>
      </div>
    </div>
  </div>

  <div class="site overview--disabled" *ngIf="offline">
    Timeline is not available in offline mode.
  </div>
</div>

<router-outlet name="full"></router-outlet>
