import { Component, OnDestroy, OnInit } from '@angular/core';

import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { Modal, ModalService } from '../../../components/modal/modal.service';
import { PromptService } from '../../../components/prompt/prompt.service';
import { EditPointService } from '../point-full-modal/edit-point.service';
import { PointHalfModalService } from '../point-half-modal/point-half-modal.service';
import { PointsFetchingService } from '../points-fetching.service';

import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';

import { Subject, of } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';

@Component({
  selector: 'app-delete-point-modal',
  templateUrl: './delete-point-modal.component.html',
  styleUrls: ['./delete-point-modal.component.scss'],
})
export class DeletePointModalComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private modal: Modal;

  processing: boolean;

  constructor(
    private promptService: PromptService,
    private modalService: ModalService,
    private editPointService: EditPointService,
    private pointHalfModalService: PointHalfModalService,
    private sitePointFilterService: SitePointFilterService,
    private pointsFetchingService: PointsFetchingService,
    private translationPipe: TranslationPipe,
  ) {}

  ngOnInit() {
    this.modal = this.modalService.getModal();
    this.processing = false;
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  deletePoint(): void {
    if (this.processing) {
      return;
    }

    this.processing = true;

    logEventInGTAG(EGoogleEventSite.SITE__POINT__DELETE, {
      event_category: EGoogleEventCategory.SITE,
    });

    this.pointsFetchingService
      .deletePoint(this.modal.data)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          const promptText = this.translationPipe.transform('prompt_point_deleted');

          this.editPointService.hideModal(true);
          this.pointHalfModalService.hideModal();

          this.promptService.showSuccess(promptText);
          this.sitePointFilterService.filterPoints();
          this.hideModal();
        }),
        catchError((error) => {
          const promptText = this.translationPipe.transform('prompt_point_deleted_error');

          logErrorInSentry(error);

          this.promptService.showError(promptText);
          return of();
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }
}
