import { getDefaultAccountFeatures } from '../modules/account/account-utils/default-account-features';
import { TAccount, TAccountFeatures } from '../modules/account/account.model';
import { TWorkspaceResponse } from './workspace-response.model';

export type TAccountResponse = {
  companyName?: string;
  logoRef?: TLogoRefOrAdminUserRef;
  adminUserRef?: TLogoRefOrAdminUserRef;
  websiteUrl?: string;
  accountType?: string;
  industryType?: string;
  accountManager?: string;
  subscriptionType?: string;
  accountOwnerId?: string;
  accountFeatures?: TAccountFeatures;
  _id?: string;
};

export type TLogoRefOrAdminUserRef = {
  id: string;
  type?: string;
  caption?: string;
};

export type TAccountSummaryResponse = {
  id: string;
  name: string;
  accountFeatures: TAccountFeatures;
  workspaces: TWorkspaceResponse[];
};

export class AccountViewModelFactory {
  static createFromDTO(dto: TAccountResponse): TAccount {
    return {
      accountType: dto.accountType,
      accountId: dto._id,
      accountName: dto.companyName,
      accountFeatures: dto.accountFeatures || getDefaultAccountFeatures(),
      accountOwnerId: dto.accountOwnerId || dto.adminUserRef?.id,
      workspaces: [],
      industryType: dto.industryType,
      subscriptionType: dto.subscriptionType,
      accountManager: dto.accountManager,
      accountFolders: [],
      websiteUrl: dto.websiteUrl,
      logoRef: dto.logoRef,
    };
  }
}
