import { Component, OnInit } from '@angular/core';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';

import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { EFolderSettingsOptions } from './folder-settings-enum';

@Component({
  selector: 'pp-folder-settings-dropdown',
  templateUrl: './folder-settings-dropdown.component.html',
  styleUrls: ['./folder-settings-dropdown.component.scss'],
})
export class FolderSettingsDropdownComponent implements OnInit {
  dropdown: TDropdown;

  constructor(private dropdownService: DropdownService) {}

  ngOnInit() {
    this.dropdown = this.dropdownService.getDropdown();

    if (!this.dropdown.data) {
      throw new Error('Data for this dropdown is required');
    }
  }

  hideDropdown(): void {
    this.dropdownService.hideDropdown();
  }

  renameFolder(): void {
    this.dropdown.callback(EFolderSettingsOptions.RENAME);

    this.hideDropdown();
  }

  deleteFolder(): void {
    this.dropdown.callback(EFolderSettingsOptions.DELETE);

    this.hideDropdown();
  }
}
