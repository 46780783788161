import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { SetMedia } from './attachments.actions';

import { ResponseErrorService } from 'src/app/project/modules/errors/response-error.service';
import { AttachmentsMediaThumbnailsService } from './attachments-media-thumbnails.service';
import { OfflineService } from 'src/app/project/modules/offline/offline.service';
import { FilesApiProviderService } from 'src/app/project/data-providers/api-providers/files-api-provider/files-api-provider.service';
import { catchError, tap } from 'rxjs/operators';
import { TAttachment } from './attachments.model';
import { forkJoin, Observable, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsMediaService {
  constructor(
    private store: Store,
    private responseErrorService: ResponseErrorService,
    private attachmentsMediaThumbnailsService: AttachmentsMediaThumbnailsService,
    private offlineService: OfflineService,
    private filesApiProviderService: FilesApiProviderService,
  ) {}

  getImageData(id: string): Observable<TAttachment[]> {
    // TODO use real attachments model
    const offline = this.offlineService.getOffline();

    if (offline) {
      return of([]);
    }

    return this.filesApiProviderService
      .fetchImagesForOnline(id)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  fetchImage360Data(id: string): Observable<TAttachment[]> {
    const offline = this.offlineService.getOffline();

    if (offline) {
      return of([]);
    }

    return this.filesApiProviderService
      .fetchImage360Data(id)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  fetchVideoData(id: string): Observable<TAttachment[]> {
    const offline = this.offlineService.getOffline();

    if (offline) {
      return of([]);
    }

    return this.filesApiProviderService
      .fetchVideoData(id)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  fetchMedia(id: string): Observable<unknown> {
    return forkJoin([
      this.getImageData(id),
      this.fetchImage360Data(id),
      this.fetchVideoData(id),
    ]).pipe(
      tap(([imageData, image360Data, videoData]) => {
        const media: TAttachment[] = [...imageData, ...image360Data, ...videoData];

        media.sort((a, b) => b.createdOn - a.createdOn);

        const groupedByDate = this.attachmentsMediaThumbnailsService.groupByDate(media);
        const mediaByIds = {};

        media.forEach((item) => {
          mediaByIds[item.attachmentId] = item;
        });

        this.store.dispatch(
          new SetMedia({
            dates: groupedByDate,
            attachments: mediaByIds,
          }),
        );
      }),
      catchError((error) => {
        this.responseErrorService.errors(error.status);

        return throwError(error);
      }),
    );
  }
}
