import {EStatus} from '../../shared/enums/status.enum';

export const humanizeStatus = (_status: EStatus): string => {
  switch (_status) {
    case EStatus.OPEN:
      return 'Open';
    case EStatus.IN_PROGRESS:
      return 'In Progress';
    case EStatus.ONHOLD:
      return 'On Hold';
    case EStatus.TO_REVIEW:
      return 'To Review';
    case EStatus.CANCELED:
      return 'Canceled';
    case EStatus.CLOSED:
      return 'Completed';
    default:
      return _status;
  }
};

export const dehumanizeStatus = (_status: string): EStatus => {
  switch (_status) {
    case 'Open':
      return EStatus.OPEN;
    case 'In Progress':
      return EStatus.IN_PROGRESS;
    case 'On Hold':
      return EStatus.ONHOLD;
    case 'To Review':
      return EStatus.TO_REVIEW;
    case 'Canceled':
      return EStatus.CANCELED;
    case 'Completed':
      return EStatus.CLOSED;
    default:
      return null;
  }
};
