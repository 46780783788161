import { TPoint } from '../../points/points.model';

export function fillDescriptionValue(point: TPoint): string {
  if (point.description) {
    const descriptionChars = point.description.trimEnd().split('');

    for (let i = descriptionChars.length - 1; i > -1; i--) {
      if (descriptionChars[i].charCodeAt(0) === 10) {
        descriptionChars.splice(i, 1, ';', String.fromCharCode(32));
      }
    }

    const description = descriptionChars.join('');

    return `${description.replace(/"/g, '""')}`;
  }

  return '';
}
