import { Component, Input, OnChanges } from '@angular/core';
import { DAY_IN_MILLISECONDS } from 'src/app/core/helpers/date/date.consts';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import {
  EIntegrationActivityType,
  TAuditLog,
} from 'src/app/project/modules/integrations/utils/integrations.consts';
import { PreferencesService } from 'src/app/project/modules/preferences/preferences-service/preferences.service';
import { transformDate } from 'src/app/project/shared/date-transformer';
import { EDateFormat } from 'src/app/project/shared/enums/date-format.enum';

@Component({
  selector: 'pp-site-settings-integrations-audit-log-row',
  templateUrl: './site-settings-integrations-audit-log-row.component.html',
  styleUrl: './site-settings-integrations-audit-log-row.component.scss',
})
export class SiteSettingsIntegrationsAuditLogRowComponent implements OnChanges {
  @Input() ppLog: TAuditLog;

  date: string;
  message: string;

  constructor(
    private preferencesService: PreferencesService,
    private translationPipe: TranslationPipe,
  ) {}

  ngOnChanges(): void {
    this.setDateString();
    this.setMessage();
  }

  private setDateString(): void {
    this.date = this.transformDate(this.ppLog.timestampEpochMillis);
  }

  private transformDate(epoch: number, addHours = true): string {
    const preferences = this.preferencesService.getPreferences();
    const timePreferences = preferences.dateFormat;

    let hourFormat = '';

    if (addHours) {
      switch (timePreferences) {
        case EDateFormat.US:
        case EDateFormat.FRIENDLY:
          hourFormat = ' [at] h:mm:ssa';
          break;
        case EDateFormat.ISO:
        case EDateFormat.EUROPEAN:
        default:
          hourFormat = ' [at] HH:mm:ss';
          break;
      }
    }

    return transformDate({
      value: epoch,
      inputHourFormat: hourFormat,
      format: timePreferences,
      localTime: true,
    });
  }

  private setMessage(): void {
    switch (this.ppLog.integrationActivityType) {
      case EIntegrationActivityType.PENDING: {
        const time = this.transformDate(this.ppLog.timestampEpochMillis, false);
        const timeoutTime = this.transformDate(
          this.ppLog.timestampEpochMillis + 7 * DAY_IN_MILLISECONDS,
          false,
        );

        this.message = this.translationPipe.transform('integration_log_pending', {
          email: this.ppLog.userEmail,
          time: time,
          timeoutTime: timeoutTime,
          interpolation: { escapeValue: false } as any,
        });
        break;
      }
      case EIntegrationActivityType.PENDING_TIMEOUT: {
        this.message = this.translationPipe.transform('integration_log_timeout');
        break;
      }
      case EIntegrationActivityType.CANCEL: {
        this.message = this.translationPipe.transform('integration_log_pending_off_from_pinpoint', {
          email: this.ppLog.userEmail,
          interpolation: { escapeValue: false } as any,
        });
        break;
      }
      case EIntegrationActivityType.CONNECT: {
        this.message = this.translationPipe.transform('integration_log_connected_voly', {
          asset: this.ppLog.assetName,
          interpolation: { escapeValue: false } as any,
        });
        break;
      }
      case EIntegrationActivityType.DISCONNECT_PW: {
        this.message = this.translationPipe.transform('integration_log_disconnected_pinpoint', {
          email: this.ppLog.userEmail,
          interpolation: { escapeValue: false } as any,
        });
        break;
      }
      case EIntegrationActivityType.DISCONNECT_VOLY: {
        this.message = this.translationPipe.transform('integration_log_disconnected_voly');
        break;
      }
      default: {
        this.message = this.translationPipe.transform('other');
      }
    }
  }
}
