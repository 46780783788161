<div class="filterContent__cost">
  <pp-filter-field-numbers-input
    [ppPlaceholder]="'minimum' | ppTranslate"
    ppUnit="%"
    [(ppValue)]="ppField.min"
    (ppValueChange)="updatePercentageField(ppField); applyFilters()"
  ></pp-filter-field-numbers-input>

  <pp-filter-field-numbers-input
    [ppPlaceholder]="'maximum' | ppTranslate"
    [(ppValue)]="ppField.max"
    ppUnit="%"
    (ppValueChange)="updatePercentageField(ppField); applyFilters()"
  ></pp-filter-field-numbers-input>
</div>
