import { pointCheckboxValueToBoolean } from '../../../points/point-modal/point-fields/point-fields-checkbox/point-checkbox-to-boolean';
import { TPoint } from '../../../points/points.model';
import { EAdvancedFilterOptions } from '../../models/advanced-filter-options.enum';
import { TAdvancedFilterCheckbox } from '../../models/advanced-filter.model';
import { filterIdToPointProperty } from '../../utils/filter-id-to-property';

export function filterCheckboxAdvanced(point: TPoint, filter: TAdvancedFilterCheckbox): boolean {
  const fieldValue = pointCheckboxValueToBoolean(filterIdToPointProperty(filter.id, point));

  switch (filter.option) {
    case EAdvancedFilterOptions.IS_CHECKED:
      return fieldValue;
    case EAdvancedFilterOptions.IS_NOT_CHECKED:
      return !fieldValue;
  }
}
