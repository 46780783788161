<div class="gridTile" [class.gridTile__disabled]="ppDisabled" (click)="optionSelect()">
  <section class="gridTile__content">
    <div class="gridTile__left">
      <div class="gridTile__imageContainer">
        <pp-icon
          class="gridTile__image"
          [ppSrc]="ppImage"
          ppColor="primary-600"
          [ppOverrideHeight]="40"
          [ppOverrideWidth]="40"
          [ppDisplayContents]="true"
        >
        </pp-icon>
      </div>
    </div>

    <div class="gridTile__right">
      <h5 class="gridTile__title">{{ ppGridTitle }}</h5>
      <div class="gridTile__text">{{ ppText }}</div>
    </div>
  </section>

  <section class="gridTile__example" *ngIf="ppShowExamples">
    <pp-icon
      [ppSrc]="EIconPath.ICON_BANNER_INFO_18"
      ppColor="grey-700"
      ppClass="setSize18"
    ></pp-icon>

    <div class="gridTile__exampleText">
      <span class="gridTile__exampleText--header">{{ 'examples' | ppTranslate }}:</span>

      <span class="gridTile__exampleText--text">
        <ng-content select="[examples]"></ng-content>
      </span>
    </div>
  </section>
</div>
