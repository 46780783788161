import { Component, OnChanges, Input } from '@angular/core';

@Component({
  selector: 'pp-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnChanges {
  @Input() ppOptions: {
    bodyPadding?: boolean;
    bodyClass?: string;
    skipHeader?: boolean;
    headerPaddingSmall?: boolean;
  };

  gridClass: {
    [className: string]: string | boolean;
  };

  constructor() {}

  ngOnChanges() {
    this.gridClass = {
      [this.ppOptions.bodyClass]: this.ppOptions.bodyClass,
      'settingsSection__body-padding': this.ppOptions.bodyPadding,
    };
  }
}
