import { Component } from '@angular/core';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { TWO_FACTOR_AUTHENTICATION_CODE_LENGTH } from '../../auth/2fa/two-factor-authentication.consts';
import { TUpdateUserWithTwoFactorAuthenticationModalData } from './update-user-with-two-factor-authentication-modal.component.consts';
import { UpdateUserWithTwoFactorAuthenticationModalService } from './update-user-with-two-factor-authentication-modal.service';

@Component({
  selector: 'pp-update-user-with-two-factor-authentication-modal',
  templateUrl: './update-user-with-two-factor-authentication-modal.component.html',
  styleUrls: ['./update-user-with-two-factor-authentication-modal.component.scss'],
})
export class UpdateUserWithTwoFactorAuthenticationModalComponent {
  public zIndex = 900;
  code = '';
  codeLength = TWO_FACTOR_AUTHENTICATION_CODE_LENGTH;
  modalData: TUpdateUserWithTwoFactorAuthenticationModalData;

  constructor(
    private modalService: ModalService,
    private updateUserWithTwoFactorAuthenticationModalService: UpdateUserWithTwoFactorAuthenticationModalService,
  ) {
    this.modalData =
      this.updateUserWithTwoFactorAuthenticationModalService.getUpdateUserWithTwoFactorAuthenticationModalData();
    this.code = this.updateUserWithTwoFactorAuthenticationModalService.getCode();
  }

  callback(): void {
    this.modalService.triggerCallback();
  }

  resend(): void {
    this.updateCode('');
    this.callback();
  }

  updateCode(code: string): void {
    this.updateUserWithTwoFactorAuthenticationModalService.setCode(code);
  }
}
