<td
  id="{{ buttonId }}"
  ppTooltip
  [ppTitle]="tooltipText"
  [class.userManagement__cell--disabled]="!editable || !customFieldId"
  class="userManagement__cell userManagementTableSite__cell permissionCell"
  (click)="toggleDropdown()"
>
  <div class="permissionCell__content">
    <pp-icon ppClass="setSize14" [ppSrc]="iconPath" ppColor="grey-600"></pp-icon>

    <span class="permissionCell__text">{{ text }}</span>
  </div>
</td>
