import { Component, OnInit } from '@angular/core';

import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PointHalfModalService } from '../point-half-modal/point-half-modal.service';
import { EditPointService } from '../point-full-modal/edit-point.service';

@Component({
  selector: 'pp-unsaved-files-modal',
  templateUrl: './unsaved-files-modal.component.html',
  styleUrls: ['./unsaved-files-modal.component.scss'],
})
export class UnsavedFilesModalComponent implements OnInit {
  constructor(
    private modalService: ModalService,
    private pointHalfModalService: PointHalfModalService,
    private editPointService: EditPointService,
  ) {}

  ngOnInit() {}

  closePoint(): void {
    this.pointHalfModalService.hideModal();
    this.editPointService.hideModal(true);
    this.hideModal();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }
}
