import { Injectable } from '@angular/core';
import { Chart } from 'chart.js';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TDashlet } from '../preferences/preferences.model';
import { addGraphDatasetLabels } from './dashboard-chart/add-graph-dataset-labels';
import { DashboardChartService } from './dashboard-chart/dashboard-chart.service';
import { TExportDashlet } from './dashboard-export-modal/dashboard-export-dashlet.model';
import { DashboardService } from './dashboard-service/dashboard.service';
import { EDashletPeriod } from './dashboard-timeframes-enums';
import { TDashletReportResponse } from './dashboard.consts';
import { TChartSettings } from './dashboard.model';
import { TDashletGraph } from './dashlet-graph.model';
import { EDashletType } from './dashlets-enums';

@Injectable({
  providedIn: 'root',
})
export class DashboardReportService {
  constructor(
    private dashboardService: DashboardService,
    private dashboardChartService: DashboardChartService,
  ) {}

  fetchCurrentTypeCount(
    dashlet: TDashlet | TExportDashlet,
    graph: TDashletGraph,
    ctx: HTMLCanvasElement,
  ): Observable<TChartSettings> {
    return this.dashboardService
      .fetchCurrentTypeCount({
        type: dashlet.name,
        accountId: dashlet.selectedRange.accountId,
        workspaceIds: dashlet.selectedRange.workspaceIds,
      })
      .pipe(
        map((response) => {
          let totalPoints: number;

          switch (dashlet.name) {
            case EDashletType.CURRENT_STATUS:
              totalPoints = Object.values(response.statuses).reduce((a, b) => a + b, 0);
              break;
            case EDashletType.CURRENT_PRIORITY:
              totalPoints = Object.values(response.priorities).reduce((a, b) => a + b, 0);
              break;
          }
          const context = ctx.getContext('2d');

          let value: number[] = [];

          switch (dashlet.name) {
            case EDashletType.CURRENT_STATUS:
              value = [
                response.statuses.OPEN,
                response.statuses.IN_PROGRESS,
                response.statuses.TO_REVIEW,
                response.statuses.ONHOLD,
                response.statuses.CLOSED,
                response.statuses.CANCELED,
              ];

              break;
            case EDashletType.CURRENT_PRIORITY:
              value = [
                response.priorities.HIGH,
                response.priorities.MEDIUM,
                response.priorities.LOW,
              ];
              break;
          }

          graph.data.datasets[0].data = value;
          graph.data.labels = this.dashboardChartService.addGraphLabels(dashlet.name, value);

          graph.options = { ...graph.options, animation: false, responsive: true };

          const chart = new Chart(context, graph);

          return {
            chart,
            totalPoints,
          };
        }),
      );
  }

  fetchExportReports(
    dashlet: TExportDashlet,
    initialGraph: TDashletGraph,
    ctx: HTMLCanvasElement,
  ): Observable<TChartSettings> {
    return this.fetchReports(
      dashlet,
      initialGraph,
      dashlet.selectedRange.workspaceIds,
      dashlet.selectedRange.accountId,
    ).pipe(
      map(({ graph, totalPoints, response }) => {
        const context = ctx.getContext('2d');
        addGraphDatasetLabels(graph, dashlet.name, response);

        graph.options = { ...graph.options, animation: false, responsive: true };
        const chart = new Chart(context, graph);

        return {
          chart,
          totalPoints,
        };
      }),
    );
  }

  fetchReports(
    dashlet: TDashlet | TExportDashlet,
    graph: TDashletGraph,
    workspaceIds: string[],
    accountId: string,
  ): Observable<{
    graph: TDashletGraph;
    totalPoints: number;
    response: number[][];
  }> {
    const length = 5;
    const period: EDashletPeriod = dashlet.period as EDashletPeriod;
    const capitalizedPeriodWord = period.charAt(0).toUpperCase() + period.slice(1);

    const labelArray = this.dashboardChartService.createChartLabels(
      capitalizedPeriodWord,
      length,
      dashlet.period,
    );

    return this.dashboardService
      .fetchReports({
        type: dashlet.name,
        period,
        length,
        accountId,
        workspaceIds,
      })
      .pipe(
        map((response) => {
          let addFixedStep = false;
          let totalPoints = 0;

          let parsedResponse: number[][] = this.parseReportValue(dashlet, response);

          parsedResponse.forEach((data, index) => {
            if (graph.data.datasets[index]) {
              graph.data.datasets[index].data = data;
            }
          });

          this.setGraphFixedStep(parsedResponse, graph, addFixedStep);
          this.setGraphData(graph, dashlet.name, parsedResponse, period, labelArray);

          parsedResponse.forEach((option) => {
            totalPoints += option[option.length - 1];
          });

          return {
            graph,
            totalPoints,
            response: parsedResponse,
          };
        }),
      );
  }

  private parseReportValue(
    dashlet: TDashlet | TExportDashlet,
    response: TDashletReportResponse,
  ): number[][] {
    let parsedResponse: number[][] = [];
    switch (dashlet.name) {
      case EDashletType.OVER_TIME_PRIORITY:
        const value = Object.values(response.priorities).map((status) => status);
        value.reverse().forEach((data) => {
          parsedResponse.push(cloneDeep(data).reverse());
        });

        break;
      case EDashletType.OVER_TIME_STATUS:
        parsedResponse.push(response.statuses.OPEN.reverse());
        parsedResponse.push(response.statuses.IN_PROGRESS.reverse());
        parsedResponse.push(response.statuses.TO_REVIEW.reverse());
        parsedResponse.push(response.statuses.ONHOLD.reverse());
        parsedResponse.push(response.statuses.CLOSED.reverse());
        parsedResponse.push(response.statuses.CANCELED.reverse());

        break;
    }
    return parsedResponse;
  }

  setGraphFixedStep(dataList: number[][], graph: TDashletGraph, addFixedStep: boolean): void {
    dataList.forEach((data, index) => {
      if (graph.data.datasets[index]) {
        graph.data.datasets[index].data = data;
      }
    });
  }

  setGraphData(
    graph: TDashletGraph,
    type: EDashletType,
    response: number[][],
    period: string,
    labelArray: string[],
  ): void {
    this.dashboardChartService.addGraphDatasetLabels(graph, type, response);

    graph.period = period.toUpperCase() as EDashletPeriod;
    graph.data.labels = labelArray;
  }
}
