import { Inject, Injectable } from '@angular/core';
import { PlanPinsService } from '../plan-pins.service';
import { PlanPolygonService } from './plan-polygon.service';
import { PlanPointCorrectFeaturesService } from './plan-point-correct-features.service';
import { EPlanPointEventType, PlanPointEventsService } from './plan-point-events.service';
import { PlanPointVariablesService } from './plan-point-variables.service';
import { ModifyEvent } from 'ol/interaction/Modify';
import { Polygon } from 'ol/geom';
import { DOCUMENT } from '@angular/common';
import { TPlanFeature } from '../plan-feature.model';

@Injectable({
  providedIn: 'root',
})
export class FeatureModifyService {
  modifyingPolygon = this.planPointVariablesService.getModifyingPolygon();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private planPointVariablesService: PlanPointVariablesService,
    private planPointEventsService: PlanPointEventsService,
    private planPointCorrectFeaturesService: PlanPointCorrectFeaturesService,
    private planPinsService: PlanPinsService,
    private planPolygonService: PlanPolygonService,
  ) {}

  removeModifyInteraction(): void {
    const plan = this.planPointVariablesService.getPlan();
    const modifyInteraction = this.planPointVariablesService.getModifyInteraction();
    const selectInteraction = this.planPointVariablesService.getSelectInteraction();

    if (modifyInteraction) {
      plan.instance.point.removeInteraction(modifyInteraction);
      this.planPointVariablesService.setModifyInteraction(null);
    }

    if (selectInteraction) {
      plan.instance.point.removeInteraction(selectInteraction);

      this.planPointVariablesService.setSelectInteraction(null);
    }
  }

  polygonStartModifyListener(event: ModifyEvent): void {
    const collection = event.features;
    const feature = collection.item(0);

    this.setModifyingPolygon(true);
    this.planPointEventsService.emit(EPlanPointEventType.POLYGON_VERTICE_TRANSLATE_START, feature);
    this.planPointEventsService.emit(EPlanPointEventType.POLYGON_VERTICE_CLICKED, null);
    this.planPointEventsService.emit(EPlanPointEventType.POLYGON_CLICKED, null);

    const maps = this.document.getElementsByClassName('ol-viewport');

    for (let i = 0; i < maps.length; i++) {
      if (maps[i]?.classList) {
        maps[i].classList.add('draggingPolygon');
      }
    }
  }

  polygonModifyListenerOff(): void {
    const modifyInteraction = this.planPointVariablesService.getModifyInteraction();
    const polygonStopModifyListenerRef =
      this.planPointVariablesService.getPolygonStopModifyListenerRef();
    const polygonStartModifyListenerRef =
      this.planPointVariablesService.getPolygonStartModifyListenerRef();

    if (modifyInteraction) {
      modifyInteraction.un('modifyend', polygonStopModifyListenerRef);
      modifyInteraction.un('modifystart', polygonStartModifyListenerRef);
    }
  }

  polygonStopModifyListener(event: ModifyEvent): void {
    const collection = event.features;
    const maps = this.document.getElementsByClassName('ol-viewport');
    const feature = collection.item(0);
    const plan = this.planPointVariablesService.getPlan();

    this.setModifyingPolygon(false);

    const index = this.planPolygonService.getPolygonIndex(
      (feature.getGeometry() as Polygon).getCoordinates()[0],
    );
    const newCoords = this.planPointCorrectFeaturesService.correctPolygonPosition({
      feature: feature as TPlanFeature,
      plan,
    });

    this.planPointEventsService.emit(EPlanPointEventType.POLYGON_VERTICE_TRANSLATE_END, feature);

    for (let i = 0; i < maps.length; i++) {
      if (maps[i]?.classList) {
        maps[i].classList.remove('draggingPolygon');
      }
    }

    (feature.getGeometry() as Polygon).setCoordinates(newCoords);

    this.planPinsService.updatePolygon(plan.point, (collection.item(0) as any).clone(), index);
    this.planPointEventsService.emit(EPlanPointEventType.POLYGON_CREATED, null);
  }

  setModifyingPolygon(modifying: boolean): void {
    this.planPointVariablesService.setModifyingPolygon(modifying);
    this.modifyingPolygon = this.planPointVariablesService.getModifyingPolygon();
  }
}
