export enum EExportData {
  ID = 'showId',
  TITLE = 'showTitle',
  DESCRIPTION = 'showDescription',
  PRIORITY = 'showPriority',
  STATUS = 'showStatus',
  TAGS = 'showTags',
  CUSTOM_FIELDS = 'showCustomFields',
  EXPORTED_BY = 'showExportBy',
  EXPORT_DATE = 'showExportDate',
  COMMENTS = 'showComments',
  SITE_PLAN = 'showSiteMap',
  ASSIGNEES = 'showAssignees',
  CREATED_BY = 'showCreatedBy',
  CREATED_DATE = 'showCreationDate',
  ACTIVITY = 'showActivities',
  LUERSSEN_SIGNATURE = 'showSignature',
  IMAGES = 'showImages',
  FILES = 'showDocuments',
  EDIT_DATE = 'showEditDate',
  SITE_MAP = 'showSiteMap',
  SHOW_FLAGS = 'showFlagged',
}
