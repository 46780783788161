import { EDashletType } from '../dashlets-enums';
import { TExportDashlet } from './dashboard-export-dashlet.model';

export function generateSimpleDashletList(dashletList): TExportDashlet[] {
  return dashletList.filter(
    (_dashlet) =>
      _dashlet.name !== EDashletType.CURRENT_PRIORITY &&
      _dashlet.name !== EDashletType.OVER_TIME_STATUS &&
      _dashlet.name !== EDashletType.CURRENT_STATUS &&
      _dashlet.name !== EDashletType.OVER_TIME_PRIORITY,
  );
}
