import { TPoint } from 'src/app/project/modules/points/points.model';
import { TFilters } from '../site-filter.model';

export function checkTags(point: TPoint, filters: TFilters): boolean {
  if (filters.tags.appliedValue.length === 0) {
    return true;
  }

  const tags = point.tags;

  if (tags.length === 0 && filters.tags.appliedValue.length > 0) {
    return false;
  } else {
    const combined = filters.tags.combinedTags;

    if (combined) {
      for (
        let appliedTagIndex = 0;
        appliedTagIndex < filters.tags.appliedValue.length;
        appliedTagIndex++
      ) {
        const appliedTag = filters.tags.appliedValue[appliedTagIndex];

        if (!tags.includes(appliedTag)) {
          return false;
        }
      }

      return true;
    } else {
      for (
        let appliedTagIndex = 0;
        appliedTagIndex < filters.tags.appliedValue.length;
        appliedTagIndex++
      ) {
        const appliedTag = filters.tags.appliedValue[appliedTagIndex];

        for (let pointTagIndex = 0; pointTagIndex < tags.length; pointTagIndex++) {
          const pointTag = tags[pointTagIndex];

          if (pointTag === appliedTag) {
            return true;
          }
        }
      }
    }
  }

  return false;
}
