import { GET_CUSTOM_FIELDS } from 'src/app/project/modules/custom-fields/custom-fields.store';
import { createElement } from 'src/app/core/helpers/dom';
import { transformTimeCF } from 'src/app/project/modules/custom-fields/custom-field-time/custom-field-time-transformer';
import { checkElement } from '@core/helpers';
import { createFooterTooltip } from './create-footer-tooltip';
import { TColumn } from '../../../columns/column.model';
import { GET_TABLE } from '../../../table.ui.store';
import { TPoint } from 'src/app/project/modules/points/points.model';

export function createTotalTimeCellInnerElement(
  cellNameElement: HTMLElement,
  column: TColumn,
  points: TPoint[],
): void {
  const table = GET_TABLE();
  const customFields = GET_CUSTOM_FIELDS();

  cellNameElement.classList.add('table__head__cell--innerSum');

  const showHoursOnly = customFields[column.customFieldIds[0]].showHoursOnly;
  let value = 0;

  const searchedPoints = points ? points : table.points;

  searchedPoints.forEach((point) => {
    if (column.customFieldIds) {
      column.customFieldIds.forEach((customFieldId) => {
        if (point.customFieldsMap[customFieldId]) {
          value += +point.customFieldsMap[customFieldId].value;
        }
      });
    } else if (point.customFieldsMap[column.customFieldIds[0]]) {
      value += +point.customFieldsMap[column.customFieldIds[0]].value;
    }
  });

  const formattedValue = transformTimeCF(value, showHoursOnly);

  const valueElement = createElement<HTMLElement>('span', {
    attrs: {
      class: 'site-table__total',
    },
    children: [formattedValue ? formattedValue : '0d / 00:00'],
  });

  cellNameElement.appendChild(checkElement(valueElement));

  createFooterTooltip(formattedValue, valueElement);
}
