<div *ngIf="ppChange.propName === 'title'" class="dashletActivity">
  <div class="dashletActivity__container">
    <img class="dashletActivity__icon" [src]="EIconPath.BASIC_FIELD_TITLE" />

    <div class="dashletActivity__text">
      {{ 'title_changed_to' | ppTranslate }}
      <span class="dashletActivities__item-quoted">"{{ ppChange.newValue }}".</span>
    </div>
  </div>
</div>
