<div class="costInput" [class.costInput--focused]="focused" (click)="focusInput()">
  <span class="costInput__icon-container" *ngIf="ppLeftUnit && ppUnit">
    <span class="costInput__unit" ppTooltip [ppTrimTooltip]="true" [ppTitle]="ppUnit">
      {{ ppUnit }}
    </span>
  </span>

  <input
    #input
    class="costInput__input"
    type="number"
    [placeholder]="ppPlaceholder"
    [(ngModel)]="ppValue"
    pattern="/^[0-9]+(\.[0-9]{1,2})?$/"
    step="1"
    (change)="updateField()"
    (keypress)="preventNonNumberInput($event)"
    (keydown.enter)="blurInput($event)"
    (focus)="onInputFocused(true)"
    (blur)="onInputFocused(false)"
  />

  <div
    class="costInput__icon-container costInput__icon-container--border-right"
    *ngIf="!ppLeftUnit && ppUnit"
  >
    <span class="costInput__unit" ppTooltip [ppTrimTooltip]="true" [ppTitle]="ppUnit">
      {{ ppUnit }}
    </span>
  </div>
</div>
