import { Component } from '@angular/core';

@Component({
  selector: 'pp-account-settings-sites-placeholder',
  templateUrl: './account-settings-sites-placeholder.component.html',
  styleUrls: ['./account-settings-sites-placeholder.component.scss']
})
export class AccountSettingsSitesPlaceholderComponent {

}
