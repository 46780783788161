import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { invert } from 'lodash';
import { EImageAnnotationsColor } from './image-annotations-color.enum';

export enum EImageAnnotationsState {
  FREE_DRAWING = 'FREE_DRAWING',
  ADDING_ARROW = 'ADDING_ARROW',
  ADDING_ELLIPSE = 'ADDING_ELLIPSE',
  ADDING_TEXT = 'ADDING_TEXT',
}

type TColorInfo = {
  colorName: EImageAnnotationsColor;
  colorCode: string;
};

@Injectable({
  providedIn: 'root',
})
export class ImageAnnotationsStateService {
  private readonly _modeChange$ = new BehaviorSubject<EImageAnnotationsState | null>(null);
  readonly modeChange$ = this._modeChange$.asObservable();

  private defaultColor = EImageAnnotationsColor.BLUE;

  private hexColors: {
    [key in EImageAnnotationsColor]: string;
  } = {
    white: '#fff',
    black: '#000',
    red: '#e54f50',
    blue: '#4da0e5',
    green: '#7bc154',
    yellow: '#ffce29',
  };

  private colorNames = invert(this.hexColors) as {
    [key: string]: EImageAnnotationsColor;
  };

  private readonly _colorChange$ = new BehaviorSubject<TColorInfo>({
    colorName: this.defaultColor,
    colorCode: this.hexColors[this.defaultColor],
  });
  readonly colorChange$ = this._colorChange$.asObservable();

  setMode(mode: EImageAnnotationsState): void {
    this._modeChange$.next(mode);
  }

  setColor(colorName: EImageAnnotationsColor): void {
    this._colorChange$.next({
      colorName: colorName,
      colorCode: this.hexColors[colorName],
    });
  }

  setColorCode(colorCode: string): void {
    this._colorChange$.next({
      colorName: this.colorNames[colorCode],
      colorCode: colorCode,
    });
  }
}
