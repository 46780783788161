import { cloneDeep } from 'lodash';
import { FilterAllPoints } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';
import { togglePoint } from '../selected-points';

export function filterAllPoints(
  oldState: TPointsByWorkspace,
  action: FilterAllPoints,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);

  Object.keys(action.payload.pointsByWorkspace).forEach((workspaceId) => {
    state[workspaceId].entities.forEach((point) => {
      if (action.payload.pointsToToggle.includes(point._id)) {
        togglePoint(point);
      }
    });
  });

  return state;
}
