import { Component, Input, OnInit } from '@angular/core';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { TFileChangeInfo, TPushNotification } from '../../notification.model';
import { getNotificationReasonText } from '../../utils/notification-reason';

@Component({
  selector: 'pp-notification-images',
  templateUrl: './notification-images.component.html',
  styleUrls: ['../notification.component.scss'],
})
export class NotificationImagesComponent implements OnInit {
  @Input() ppNotification: TPushNotification<TFileChangeInfo[]>;

  notificationReason: string;
  isNewImage: boolean;
  isSingleImageAdded: boolean;
  isMultipleImagesAdded: boolean;
  isSingleImageRemoved: boolean;
  isMultipleImagesRemoved: boolean;
  EIconPath = EIconPath;

  constructor() {}

  ngOnInit(): void {
    this.notificationReason = getNotificationReasonText(this.ppNotification.notificationReason);

    this.checkNewImage();
    this.checkSingleImageAdded();
    this.checkMultipleImagesAdded();
    this.checkSingleImageRemoved();
    this.checkMultipleImagesRemoved();
  }

  private checkMultipleImagesRemoved(): void {
    this.isMultipleImagesRemoved =
      (!this.ppNotification.changeBody.newValue &&
        this.ppNotification.changeBody.oldValue.length > 1) ||
      this.ppNotification.changeBody.oldValue.length -
        this.ppNotification.changeBody.newValue.length >
        1;
  }

  private checkSingleImageRemoved(): void {
    this.isSingleImageRemoved =
      (!this.ppNotification.changeBody.newValue &&
        this.ppNotification.changeBody.oldValue.length === 1) ||
      this.ppNotification.changeBody.oldValue.length -
        this.ppNotification.changeBody.newValue.length ===
        1;
  }

  private checkMultipleImagesAdded(): void {
    this.isMultipleImagesAdded =
      (!this.ppNotification.changeBody.oldValue &&
        this.ppNotification.changeBody.newValue.length > 1) ||
      this.ppNotification.changeBody.newValue.length -
        this.ppNotification.changeBody.oldValue.length >
        1;
  }

  private checkSingleImageAdded(): void {
    this.isSingleImageAdded =
      (!this.ppNotification.changeBody.oldValue &&
        this.ppNotification.changeBody.newValue.length === 1) ||
      this.ppNotification.changeBody.newValue.length -
        this.ppNotification.changeBody.oldValue.length ===
        1;
  }

  private checkNewImage(): void {
    this.isNewImage =
      (this.ppNotification.changeBody.newValue &&
        this.ppNotification.changeBody.newValue.length >
          this.ppNotification.changeBody.oldValue.length) ||
      !this.ppNotification.changeBody.oldValue;
  }
}
