import { Injectable } from '@angular/core';
import { TFilters } from '../site-filter.model';
import { compareFilterAssignees } from './compare-filter-assignees';
import { compareFilterCreatedBy } from './compare-filter-created-by';
import { compareFilterCreatedDate } from './compare-filter-created-date';
import { compareFilterCustomFields } from './compare-filter-custom-fields';
import { compareFilterExcludedTags } from './compare-filter-excluded-tags';
import { compareFilterKeyword } from './compare-filter-keyword';
import { compareFilterPriority } from './compare-filter-priority';
import { compareFilterReminders } from './compare-filter-reminders';
import { compareFilterShowFlaggedPoints } from './compare-filter-show-flagged-points';
import { compareFilterShowPointsWithLocation } from './compare-filter-show-points-with-location';
import { compareFilterShowPointsWithoutLocation } from './compare-filter-show-points-without-location';
import { compareFilterShowUnflaggedPoints } from './compare-filter-show-unflagged-points';
import { compareFilterSites } from './compare-filter-sites';
import { compareFilterStatus } from './compare-filter-status';
import { compareFilterTags } from './compare-filter-tags';
import { compareFilterUpdatedDate } from './compare-filter-updated-date';

@Injectable({
  providedIn: 'root',
})
export class CompareFiltersService {
  areFiltersEqual(filter1: TFilters, filter2: TFilters): boolean {
    if (!filter1 || !filter2) {
      return false;
    }

    const compareFunctions = [
      compareFilterKeyword,
      compareFilterPriority,
      compareFilterStatus,
      compareFilterTags,
      compareFilterExcludedTags,
      compareFilterCreatedDate,
      compareFilterUpdatedDate,
      compareFilterCreatedBy,
      compareFilterAssignees,
      compareFilterShowPointsWithoutLocation,
      compareFilterShowPointsWithLocation,
      compareFilterShowUnflaggedPoints,
      compareFilterShowFlaggedPoints,
      compareFilterSites,
      compareFilterReminders,
      compareFilterCustomFields,
    ];

    for (let index = 0; index < compareFunctions.length; index++) {
      if (!compareFunctions[index](filter1, filter2)) {
        return false;
      }
    }

    return true;
  }
}
