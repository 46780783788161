<div
  *ngIf="selectOptions.length > 0"
  [id]="buttonId"
  class="select"
  (click)="toggleSelectDropdown()"
>
  <div class="select__content">
    <span
      ppTooltip
      [ppTrimTooltip]="true"
      [ppChildTooltip]="true"
      [ppTitle]="selectOptions[selectedIndex].label"
      class="fleetAccountSelect__content"
      *ngIf="selectedIndex !== -1"
    >
      {{ selectOptions[selectedIndex].label }}
    </span>

    <span class="fleetAccountSelect__placeholder" *ngIf="selectedIndex === -1">
      {{ 'select_a_fleet' | ppTranslate }}
    </span>
  </div>

  <div class="select__caret">
    <div class="select__icon-container">
      <pp-icon
        [ppSrc]="
          dropdown.visible && dropdown.buttonId === buttonId
            ? EIconPath.ICON_MISC_UP_ARROW_18
            : EIconPath.ICON_MISC_DOWN_ARROW_18
        "
        ppColor="grey-400"
        ppClass="setSize18"
      ></pp-icon>
    </div>
  </div>
</div>
