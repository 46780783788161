<pp-account-options-placeholder *ngIf="!ppAccount && !ppOffline"></pp-account-options-placeholder>

<pp-grid [ppOptions]="{ skipHeader: true }" *ngIf="ppAccount || ppOffline">
  <ng-container body>
    <div class="accountOptions" *ngIf="ppAccount">
      <pp-account-options-toggles
        *ngIf="ppShowHiddenOptions"
        [(ppAccount)]="ppAccount"
      ></pp-account-options-toggles>

      <pp-account-options-features
        *ngIf="ppShowHiddenOptions"
        [ppAccount]="ppAccount"
        (ppUpdateFeatures)="updateAccountFeatures($event)"
      ></pp-account-options-features>

      <div class="accountOptions__confirm">
        <pp-button
          ppStyle="primary"
          ppId="saveAccTypeBtn"
          [ppProcess]="updatingAccount"
          [class.cursor--disabled]="ppOffline"
          (ppAction)="updateAccount()"
          [ppDisabled]="ppOffline"
        >
          {{ 'save_changes' | ppTranslate }}
        </pp-button>
      </div>
    </div>
  </ng-container>
</pp-grid>
