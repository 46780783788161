import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoUploadComponent } from './logo-upload.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { UploadModule } from '../upload/upload.module';
import { ButtonModule } from '../../button/button.module';
import { LogoUploadDropdownComponent } from './logo-upload-dropdown/logo-upload-dropdown.component';
import { SecureSrcModule } from 'src/app/project/directives/secure-src/secure-src.module';

@NgModule({
  declarations: [LogoUploadComponent, LogoUploadDropdownComponent],
  imports: [CommonModule, PipesModule, UploadModule, ButtonModule, SecureSrcModule],
  exports: [LogoUploadComponent],
})
export class LogoUploadModule {}
