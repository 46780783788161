import { cloneDeep } from 'lodash';
import { AddWorkspaceSortedPointIds } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';

export function addWorkspaceSortedPointIds(
  oldState: TPointsByWorkspace,
  action: AddWorkspaceSortedPointIds,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);

  state[action.payload.workspaceId].sortedPointIds = action.payload.sortedPointIds;

  return state;
}
