import { Component, Input } from '@angular/core';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventFiltering,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { TWorkspacesById } from '../../workspace/workspace.model';
import { SiteFilterDropdownService } from '../site-filter-dropdown/site-filter-dropdown-service/site-filter-dropdown.service';
import { TFilters } from '../site-filter.model';

@Component({
  selector: 'pp-basic-filters',
  templateUrl: './basic-filters.component.html',
  styleUrls: ['./basic-filters.component.scss'],
})
export class BasicFiltersComponent {
  @Input() ppFilters: TFilters;
  @Input() ppWorkspaceId: string;
  @Input() ppRemindersEnabled: boolean;
  @Input() ppWorkspaces: TWorkspacesById;
  @Input() ppSitePlanExists: boolean;
  @Input() ppTagsVisible: boolean;
  @Input() ppIsDropdownVisible: boolean;

  constructor(private siteFilterDropdownService: SiteFilterDropdownService) {}

  applyFilters(): void {
    logEventInGTAG(EGoogleEventFiltering.EDIT_BASIC_FILTER, {
      event_category: EGoogleEventCategory.FILTERING,
    });

    this.siteFilterDropdownService.applyFilters(this.ppFilters, this.ppWorkspaceId);
  }
}
