import { Component } from '@angular/core';
import { EIconPath } from '../../../../../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-dashlet-activity-location',
  templateUrl: './dashlet-activity-location.component.html',
  styleUrls: ['../../dashlet-activity.component.scss'],
})
export class DashletActivityLocationComponent {
  EIconPath = EIconPath;
}
