import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';

import { offlineReducer } from './offline.reducer';
import { syncReducer } from './sync.reducer';

import { SyncService } from './sync.service';
import { OfflineService } from './offline.service';
import { UpdateService } from './update.service';

import { ButtonModule } from '../../components/button/button.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot({
      offline: offlineReducer,
      sync: syncReducer,
    }),
    ButtonModule,
    PipesModule,
  ],
  exports: [],
  providers: [SyncService, OfflineService, UpdateService],
  declarations: [],
})
export class OfflineModule {}
