import { TFilters } from '../site-filter.model';

export function compareFilterShowFlaggedPoints(
  firstFilter: TFilters,
  secondFilter: TFilters,
): boolean {
  const flag1 = firstFilter.showFlaggedPoints;
  const flag2 = secondFilter.showFlaggedPoints;

  return flag1 === flag2;
}
