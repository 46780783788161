import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TAssetSite, TNewAssetSite } from '../../../asset-service/asset.consts';
import { NewAssetService } from '../new-asset.service';

@Injectable({
  providedIn: 'root',
})
export class NewAssetSitePickerService {
  private _keywordChange$ = new Subject<string>();
  public keywordChange$ = this._keywordChange$.asObservable();

  private keyword = '';

  assetSites: {
    [workspaceId: string]: TNewAssetSite;
  } = {};

  constructor(private newAssetService: NewAssetService) {}

  getKeyword(): string {
    return this.keyword;
  }

  setKeyword(keyword: string): void {
    this.keyword = keyword;

    this._keywordChange$.next(this.keyword);
  }

  clearAssetSites(): void {
    this.assetSites = {};

    this.updateNewAssetSites();
  }

  setAssetSites(assetSites: TNewAssetSite[]): void {
    this.assetSites = {};

    assetSites.forEach((assetSite) => {
      this.assetSites[assetSite.workspaceId] = assetSite;
    });

    this.updateNewAssetSites();
  }

  getAssetSites(): {
    [workspaceId: string]: TNewAssetSite;
  } {
    return this.assetSites;
  }

  addAssetSite(assetSite: TNewAssetSite): void {
    this.assetSites[assetSite.workspaceId] = assetSite;

    this.updateNewAssetSites();
  }

  removeAssetSite(assetSiteId: string): void {
    delete this.assetSites[assetSiteId];

    this.updateNewAssetSites();
  }

  updateNewAssetSites(): void {
    const newAssets: TNewAssetSite[] = Object.keys(this.assetSites).map((workspaceId) => {
      return {
        workspaceId,
        budgetCustomFieldId: this.assetSites[workspaceId].budgetCustomFieldId,
      };
    });

    this.newAssetService.setAssetSites(newAssets);
  }
}
5;
