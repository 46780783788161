import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'pp-asset-card-footer',
  templateUrl: './asset-card-footer.component.html',
  styleUrls: ['./asset-card-footer.component.scss'],
})
export class AssetCardFooterComponent {
  @Input() ppAssetCount: number;
  @ViewChild('assetsFooter') assetsFooter: ElementRef;

  onDragOver(event: DragEvent): void {
    event.preventDefault();

    const scrollableElement = this.getScrollableParent();

    scrollableElement.scrollTop += 1;
  }

  private getScrollableParent(): HTMLElement {
    const parentsNumber = 5; // 4 is the number of nesting levels between the fleetAssetsElement and the scrollable parent

    let parentElement: HTMLElement = this.assetsFooter.nativeElement;

    for (let i = 0; i < parentsNumber; i++) {
      parentElement = parentElement.parentNode as HTMLElement;
    }
    return parentElement;
  }
}
