<button
  class="dropdownItem dropdownItem--btn"
  id="editAssetButton"
  data-test="editAssetButton"
  (click)="editAsset()"
  *ngIf="permission === EFleetShareOption.EDIT"
>
  {{ 'edit_asset' | ppTranslate }}
</button>

<button
  class="dropdownItem dropdownItem--btn"
  id="addDashletButton"
  data-test="addDashletButton"
  (click)="addDashlet()"
  *ngIf="canAddDashlets && permission === EFleetShareOption.EDIT"
>
  {{ 'add_dashlet' | ppTranslate }}
</button>

<button
  class="dropdownItem"
  id="exportAssetButton"
  data-test="exportAssetButton"
  (click)="exportAsset()"
>
  {{ 'export_asset' | ppTranslate }}
</button>

<button
  class="dropdownItem dropdownItem--btn deleteAssetButton"
  id="deleteAssetButton"
  data-test="deleteAssetButton"
  (click)="deleteAsset()"
  *ngIf="permission === EFleetShareOption.EDIT"
>
  {{ 'delete_asset' | ppTranslate }}
</button>
