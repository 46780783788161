import { createElement } from '../../../../../../../../core/helpers/dom';

import { ApiService } from '@core/http';
import { getSvg } from '../../../../../../../../core/helpers/get-svg';
import AbstractDropdown, {
  TCustomDropdownParams,
} from '../../../../../../../components/dropdown/AbstractDropdown';
import { translate } from '../../../../../../../features/translate/translate';
import { TColumn } from '../../../../../site-table/columns/column.model';
import { GET_COLUMNS } from '../../../../../site-table/columns/columns.store';
import { GET_GROUPING } from '../../../../../site-table/columns/grouping.store';
import { ESortOrder } from '../../../../../site-table/sort-order-enum';
import { EIconPath } from '../../../../../../../shared/enums/icons.enum';

export default class GroupOrSortOrderDropdown extends AbstractDropdown {
  columns: TColumn[] = [];

  columnSectionElement: HTMLElement = null;

  constructor(_dropdownProperties: TCustomDropdownParams) {
    super({
      ..._dropdownProperties,
    });

    const grouping = GET_GROUPING();
    this.columns = GET_COLUMNS();

    grouping.forEach((_sortColumn) => {
      this.columns = this.columns.filter((_column) => _column.name !== _sortColumn.name);
    });

    this.contentElement = this.createContentElement();

    this.init();
  }

  private createContentElement(): HTMLElement {
    return createElement('div', {
      attrs: {
        class: '_groupByDropdown',
      },
      children: [this.createTitleElement(), this.createColumnSectionElement()],
    });
  }

  private createTitleElement(): HTMLElement {
    return createElement('div', {
      attrs: {
        class: '_groupByDropdown__title',
      },
      children: [translate('select_order')],
    });
  }

  private createFieldIcon(apiService: ApiService, order: 'ASC' | 'DESC'): HTMLElement {
    const src = order === 'ASC' ? EIconPath.ICON_MISC_A_Z_18 : EIconPath.ICON_MISC_Z_A_18;

    const iconElement = createElement('img', {
      attrs: {
        src,
      },
    }) as HTMLImageElement;

    const iconElementWrapper = createElement('div', {
      attrs: {
        class: 'iconElementWrapper',
      },
      children: [iconElement],
    });

    getSvg(apiService, src).then((svg) => {
      iconElementWrapper.innerHTML = svg;
    });

    return iconElementWrapper;
  }

  private createColumnSectionElement(): HTMLElement {
    let children = [];

    if (this.columns.length) {
      children = [this.createAscElement(), this.createDescElement()];
    } else {
      children.push(translate('no_more_fields_to_choose_from'));
    }

    this.columnSectionElement = createElement('div', {
      children,
    });

    return this.columnSectionElement;
  }

  private createAscElement(): HTMLElement {
    const fieldElement = createElement('div', {
      attrs: {
        class: 'dropdownItem dropdownItem__group',
      },
      eventListeners: {
        click: () => {
          this.onSelect(ESortOrder.ASC);
          this.update();
          this.destroy();
        },
      },
      children: [this.createFieldIcon(this.apiService, 'ASC'), translate('ascending')],
    });

    return fieldElement;
  }

  private createDescElement(): HTMLElement {
    const fieldElement = createElement('div', {
      attrs: {
        class: 'dropdownItem dropdownItem__group',
      },
      eventListeners: {
        click: () => {
          this.onSelect(ESortOrder.DESC);
          this.update();
          this.destroy();
        },
      },
      children: [this.createFieldIcon(this.apiService, 'DESC'), translate('descending')],
    });

    return fieldElement;
  }
}
