import { EStatus } from 'src/app/project/shared/enums/status.enum';
import { TStatusFilter } from '../site-filter.model';

export type TStatusFilterRequest =
  | 'open'
  | 'in_progress'
  | 'to_review'
  | 'onhold'
  | 'closed'
  | 'canceled';

export function getPointStatusesToFilter(statuses: TStatusFilter[]): TStatusFilterRequest[] {
  const requestStatuses: TStatusFilterRequest[] = [];

  if (!statuses?.length) {
    return [];
  }

  statuses.forEach((status) => {
    if (status.value) {
      switch (status.label) {
        case EStatus.OPEN:
          requestStatuses.push('open');
          break;
        case EStatus.IN_PROGRESS:
          requestStatuses.push('in_progress');
          break;
        case EStatus.TO_REVIEW:
          requestStatuses.push('to_review');
          break;
        case EStatus.ONHOLD:
          requestStatuses.push('onhold');
          break;
        case EStatus.CLOSED:
          requestStatuses.push('closed');
          break;
        case EStatus.CANCELED:
          requestStatuses.push('canceled');
          break;
      }
    }
  });

  return requestStatuses;
}
