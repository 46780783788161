import { TUser } from './user.model';

let user: TUser = {};

// Get

export const GET_USER = (): TUser => user;

// Set

export const SET_USER = (_user: TUser): void => {
  user = _user;
};

// Modify

// ---

// Clear

export const CLEAR_USER = (): void => {
  user = null;
};
