import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutonumericDirective } from './autonumeric.directive';

@NgModule({
  declarations: [AutonumericDirective],
  imports: [CommonModule],
  exports: [AutonumericDirective],
})
export class AutonumericModule {}
