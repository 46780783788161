import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { ButtonModule } from '../../components/button/button.module';
import { ChangeLimitModalModule } from '../../components/change-limit-modal/change-limit-modal.module';
import { DoubleSelectModule } from '../../components/double-select/double-select.module';
import { IconModule } from '../../components/icon/icon-module';
import { CheckboxModule } from '../../components/input/checkbox/checkbox.module';
import { RadioModule } from '../../components/input/radio/radio.module';
import { ModalModule } from '../../components/modal/modal.module';
import { SiteListSelectModule } from '../../components/site-list-select/site-list-select.module';
import { SpinnerModule } from '../../components/spinner/spinner.module';
import { TagModule } from '../../components/tag/tag.module';
import { ToggleModule } from '../../components/toggle/toggle.module';
import { UserChipModule } from '../../components/user-chip/user-chip.module';
import { AutonumericModule } from '../../directives/autonumeric/autonumeric.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SwitchCasesModule } from '../../directives/switch-cases/switch-cases.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { TagsModule } from '../tags/tags.module';
import { DeleteShareModalComponent } from './delete-share-modal/delete-share-modal.component';
import { EditShareModalComponent } from './edit-share-modal/edit-share-modal.component';
import { ImportUsersModalComponent } from './import-users-modal/import-users-modal.component';
import { ShareImportTagListComponent } from './import-users-modal/share-modal-import/share-import-tag-list/share-import-tag-list.component';
import { ShareImportUserRowComponent } from './import-users-modal/share-modal-import/share-import-user-row/share-import-user-row.component';
import { ShareModalImportComponent } from './import-users-modal/share-modal-import/share-modal-import.component';
import { PermissionsService } from './permissions.service';
import { ShareEditActivityComponent } from './share-modal/share-edit/share-edit-activity/share-edit-activity.component';
import { ShareEditAdvancedAccessComponent } from './share-modal/share-edit/share-edit-advanced-access/share-edit-advanced-access.component';
import { ShareEditCommentsComponent } from './share-modal/share-edit/share-edit-comments/share-edit-comments.component';
import { ShareEditEmailInputComponent } from './share-modal/share-edit/share-edit-email-input/share-edit-email-input.component';
import { ShareEditExportComponent } from './share-modal/share-edit/share-edit-export/share-edit-export.component';
import { ShareEditSitePlanComponent } from './share-modal/share-edit/share-edit-site-plan/share-edit-site-plan.component';
import { ShareEditTagsComponent } from './share-modal/share-edit/share-edit-tags/share-edit-tags.component';
import { ShareEditComponent } from './share-modal/share-edit/share-edit.component';
import { ShareModalComponent } from './share-modal/share-modal.component';
import { SharesService } from './shares.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    TagsModule,
    SpinnerModule,
    ToggleModule,
    CheckboxModule,
    TagModule,
    RadioModule,
    DoubleSelectModule,
    SiteListSelectModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    ModalModule,
    PipesModule,
    ChangeLimitModalModule,
    UserChipModule,
    IconModule,
  ],
  providers: [PermissionsService, SharesService],
  declarations: [
    ShareModalComponent,
    ImportUsersModalComponent,
    DeleteShareModalComponent,
    ShareModalImportComponent,
    EditShareModalComponent,
    ShareEditComponent,
    ShareImportTagListComponent,
    ShareImportUserRowComponent,
    ShareEditEmailInputComponent,
    ShareEditAdvancedAccessComponent,
    ShareEditCommentsComponent,
    ShareEditExportComponent,
    ShareEditSitePlanComponent,
    ShareEditActivityComponent,
    ShareEditTagsComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  exports: [ShareEditComponent, ShareImportTagListComponent, ShareEditEmailInputComponent],
})
export class ShareModule {}
