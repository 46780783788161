import { cloneDeep } from 'lodash';
import { UpdatePointsPriorities } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';

export function updatePointsPriorities(
  oldState: TPointsByWorkspace,
  action: UpdatePointsPriorities,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);
  let pointIndex = null;

  if (state[action.payload.workspaceId]) {
    action.payload.points.forEach((_id) => {
      pointIndex = state[action.payload.workspaceId].entities.findIndex(
        (_point) => _point._id === _id,
      );
      const point = state[action.payload.workspaceId].entities[pointIndex];

      if (point) {
        point.header.updatedEpochMillis = new Date().getTime();
        point.priority = action.payload.priority;
        state[action.payload.workspaceId].entities.splice(pointIndex, 1, point);
      }
    });
  }

  return state;
}
