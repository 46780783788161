<div
  class="sitePlanFormatSelect"
  [class.sitePlanFormatSelect--disabled]="ppDisabled"
  id="sitPlanFormatSelect"
  (click)="toggleSelectDropdown('sitPlanFormatSelect')"
>
  <span class="sitePlanFormatSelect--text" *ngIf="selectedPlanFormatIndex !== -1">
    {{ planFormats[selectedPlanFormatIndex].label }}
  </span>

  <span class="sitePlanFormatSelect--text" *ngIf="selectedPlanFormatIndex === -1">
    {{ 'select_plan_format' | ppTranslate }}
  </span>

  <pp-icon
    class="sitePlanFormatSelect--arrow"
    [ppSrc]="EIconPath.ICON_ARROW_DOWN"
    ppColor="grey-400"
  ></pp-icon>
</div>

<pp-icon
  ppTooltip
  [class.sitePlanFormatSelect--disabled]="ppDisabled"
  [ppSanitize]="false"
  [ppTitle]="tooltipTitle"
  [ppSrc]="EIconPath.ICON_MISC_INFO"
  ppColor="grey-400"
  ppHoverColor="grey-700"
  class="infoIcon"
></pp-icon>
