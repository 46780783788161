import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { TAccount } from 'src/app/project/modules/account/account.model';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { EIconPath } from '../../shared/enums/icons.enum';
import {
  EListSelectTypes,
  ESiteListSelectTypesMultiple,
  ESiteListSelectTypesSingle,
} from './site-list-select-enums';

@Component({
  selector: 'pp-site-list-select',
  templateUrl: './site-list-select.component.html',
  styleUrls: ['./site-list-select.component.scss'],
})
export class SiteListSelectComponent implements OnInit, OnDestroy {
  @Input() readonly ppAccounts: TAccount[];
  @Input() ppFieldsPerAccount: {
    [accountId: string]: number;
  };
  @Input() ppFieldsPerWorkspace: {
    [workspaceId: string]: number;
  };
  @Input() ppType: EListSelectTypes = null;

  @Output() ppAction = new EventEmitter<string>();

  private readonly destroy$ = new Subject<void>();

  private workspaces$: Observable<TWorkspacesById>;
  workspaces: TWorkspacesById;

  public SiteListSelectTypesSingle = ESiteListSelectTypesSingle;
  public SiteListSelectTypesMultiple = ESiteListSelectTypesMultiple;
  public ListSelectTypes = EListSelectTypes;
  EIconPath = EIconPath;

  activeWorkspaceId = '';

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
    }>,
    private activeService: ActiveService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
  }

  expandedAccounts: string[] = [];

  ngOnInit(): void {
    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.activeWorkspaceId = this.activeService.getActiveWorkspaceId();

      this.workspaces = workspaces;
    });
  }

  toggleAccount(accountId: string): void {
    const expandedAccountIndex = this.expandedAccounts.findIndex(
      (account) => account === accountId,
    );

    if (expandedAccountIndex > -1) {
      this.expandedAccounts.splice(expandedAccountIndex, 1);
    } else {
      this.expandedAccounts.push(accountId);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  openSiteCallback(workspaceId: string): void {
    this.ppAction.emit(workspaceId);
  }
}
