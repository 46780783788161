<div class="headerOptions">
  <div class="headerOptions__option">
    <label class="headerOptions__text" for="LogoUploadDropdown">
      {{ 'upload_new_logo' | ppTranslate }}
    </label>

    <pp-upload
      id="LogoUpload--update"
      [ppId]="'LogoUploadDropdown'"
      (ppUpload)="uploadNewLogo($event)"
      ppAccept=".jpg, .jpeg, .png, .bmp"
    >
    </pp-upload>
  </div>

  <div class="headerOptions__option headerOptions__option__delete" (click)="deleteLogo()">
    <span class="headerOptions__text text__delete">{{ 'delete_current_logo' | ppTranslate }}</span>
  </div>
</div>
