import { TPoint } from '../../../points/points.model';
import { EAdvancedFilterOptions } from '../../models/advanced-filter-options.enum';
import { TAdvancedFilterDate } from '../../models/advanced-filter.model';
import { filterIdToPointProperty } from '../../utils/filter-id-to-property';

export function filterDateAdvanced(point: TPoint, filter: TAdvancedFilterDate): boolean {
  const filterValue = filter.value?.length ? filter.value : [null, null];

  const fieldValue = filterIdToPointProperty(filter.id, point) as number;
  let fieldValueToFilter: number;

  if (fieldValue) {
    const date = new Date(+fieldValue);
    date.setHours(0, 0, 0, 0);
    fieldValueToFilter = date.getTime();
  }

  switch (filter.option) {
    case EAdvancedFilterOptions.IS:
      return !filterValue[0] || fieldValueToFilter === filterValue[0];
    case EAdvancedFilterOptions.IS_BEFORE:
      return !filterValue[0] || fieldValueToFilter < filterValue[0];
    case EAdvancedFilterOptions.IS_AFTER:
      return !filterValue[0] || fieldValueToFilter > filterValue[0];
    case EAdvancedFilterOptions.IS_BETWEEN:
      return (
        filterValue[0] &&
        filterValue[1] &&
        fieldValueToFilter > filterValue[0] &&
        fieldValueToFilter < filterValue[1]
      );
    case EAdvancedFilterOptions.IS_EMPTY:
      return !fieldValueToFilter;
    case EAdvancedFilterOptions.IS_NOT_EMPTY:
      return !!fieldValueToFilter;
  }
}
