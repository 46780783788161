import { TFilters, TStatusFilter } from '../site-filter.model';
import { isStatusDefault } from './is-status-default';

export function clearStatus(filters: TFilters, applied?: boolean): TStatusFilter[] {
  filters.status.forEach((status) => {
    if (status.value !== status.default) {
      status.value = status.default;

      if (applied) {
        status.appliedValue = status.default;
      }
    }
  });

  filters.statusDefault = isStatusDefault(filters);

  return filters.status;
}
