<div
  #editGroupButton
  class="select groupNameSelect"
  (click)="showDropdown()"
  [class.select--inputFocused]="dropdown"
>
  <span class="groupName" [class.groupName--placeholder]="!ppColumn?.name">
    <pp-icon
      ppTooltip
      [ppTitle]="iconTooltip"
      *ngIf="iconSrc"
      [ppSrc]="iconSrc"
      ppClass="setSize14"
    ></pp-icon>
    <span
      class="groupName__text"
      ppTooltip
      [ppTrimTooltip]="true"
      [ppTitle]="ppColumn?.name ? ppColumn.name : ('select_option' | ppTranslate)"
    >
      {{ ppColumn?.name ? ppColumn.name : ('select_option' | ppTranslate) }}
    </span>
  </span>

  <pp-icon
    [ppSrc]="dropdown ? EIconPath.ICON_MISC_UP_ARROW_18 : EIconPath.ICON_MISC_DOWN_ARROW_18"
    ppClass="setSize18"
    ppColor="grey-400"
  />
</div>
