import Quill from 'quill';
import { Base64 } from 'js-base64';

import { Component, ViewChild, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';

import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { TUser } from 'src/app/project/modules/user/user.model';
import { TReaction } from 'src/app/project/modules/reactions/reactions.model';

import { ReactionsService } from 'src/app/project/services/reactions.service';
import { UserService } from 'src/app/project/modules/user/user.service';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { SiteFilterDataService } from 'src/app/project/modules/filters/site-filter-data-service/site-filter-data.service';
import { getTextWithMentionsMarked } from 'src/app/project/components/input/rich-text/rich-text';
import { TAllFilters } from 'src/app/project/modules/filters/site-filter.model';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { TRichText } from 'src/app/project/components/input/rich-text/rich-text-ops.model';
import { TAllUsers } from '@project/view-models';
import { EIconPath } from '../../../../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-description-activity',
  templateUrl: './description-activity.component.html',
  styleUrls: ['./description-activity.component.scss'],
})
export class DescriptionActivityComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @ViewChild('description', { static: true }) descriptionElement: ElementRef;

  @Input() ppPlainText = '';
  @Input() ppDescription: string;
  @Input() ppComment = false;
  @Input() ppId = '';
  @Input() ppPointId = '';

  private quill: Quill;
  private richText: TRichText;
  expanded = false;
  long = false;
  length: number;
  textLines = 0;
  splitText = [];
  user: TUser;
  reaction: TReaction;
  users: TAllUsers;
  reactionTooltip = '';
  EIconPath = EIconPath;

  siteFilters$ = new Observable<TAllFilters>();

  constructor(
    private store: Store<{
      siteFilter: TAllFilters;
    }>,
    private reactionsService: ReactionsService,
    private userService: UserService,
    private usersService: UsersService,
    private siteFilterDataService: SiteFilterDataService,
  ) {
    this.siteFilters$ = this.store.pipe(select(EStore.SITE_FILTER));

    this.siteFilters$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.quill) {
        this.initContent();
      }
    });
  }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.users = this.usersService.getUsers();

    this.reactionsService.reactionsChange$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setReactions();
    });
    this.setReactions();

    if (this.ppDescription) {
      try {
        this.richText = JSON.parse(Base64.decode(this.ppDescription));
      } catch {
        this.richText = { ops: [] };
      }
    }

    this.initEditor();
    this.initContent();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  setReactions(): void {
    this.reaction = this.reactionsService
      .getReactions()
      [this.ppPointId]?.data.find((_reaction) => _reaction.targetRef.id === this.ppId);

    this.reactionTooltip = this.reactionsService.getReactionTooltip(this.reaction);
  }

  initEditor(): void {
    this.quill = new Quill(this.descriptionElement.nativeElement, {
      readOnly: true,
      modules: {
        toolbar: false,
      },
    });
  }

  initContent(): void {
    if (this.quill && this.richText) {
      this.quill.setContents(this.richText.ops);

      this.length = this.quill.getText().trim().length;
      this.splitText = this.quill.getText().split(/\r\n|\r|\n/);
      this.textLines = this.splitText.length;

      if (this.textLines > 3) {
        let lengthToTrim =
          this.splitText[0].length + this.splitText[1].length + this.splitText[2].length;

        if (lengthToTrim > 200) {
          lengthToTrim = 200;
        }

        this.long = true;
        this.quill.editor.deleteText(lengthToTrim, this.length);
        this.quill.editor.insertText(lengthToTrim, '...', 'bold', true);
      } else if (this.length > 200) {
        this.long = true;
        this.quill.editor.deleteText(200, this.length);
        this.quill.editor.insertText(200, '...', 'bold', true);
      }
    } else if (this.quill) {
      this.quill.setText(this.ppPlainText);

      this.length = this.quill.getLength();
      this.splitText = this.quill.getText().split(/\r\n|\r|\n/);
      this.textLines = this.splitText.length;

      if (this.textLines > 3) {
        let lengthToTrim =
          this.splitText[0].length + this.splitText[1].length + this.splitText[2].length;

        if (lengthToTrim > 200) {
          lengthToTrim = 200;
        }

        this.long = true;
        this.quill.editor.deleteText(lengthToTrim, this.length);
        this.quill.editor.insertText(lengthToTrim, '...', 'bold', true);
      } else if (this.length > 200) {
        this.long = true;
        this.quill.editor.deleteText(200, this.length);
        this.quill.editor.insertText(200, '...', 'bold', true);
      }
    }

    this.highlightWords();
  }

  highlightWords(): void {
    const filters = this.siteFilterDataService.getFilters();
    const keyword = filters.keyword;
    const result: number[] = [];

    if (keyword) {
      const length = keyword.length;

      let totalText: string = getTextWithMentionsMarked(this.quill);
      let extraLength = 0;

      while (true) {
        const startIndex = totalText ? totalText.toLowerCase().indexOf(keyword.toLowerCase()) : -1;

        if (startIndex !== -1) {
          const endIndex = startIndex + keyword.length;

          result.push(startIndex + extraLength);

          extraLength = extraLength + endIndex;

          totalText = totalText.substring(endIndex);
        } else {
          break;
        }
      }

      result.forEach((index) =>
        this.quill.formatText(index, length, {
          background: '#0084F8',
          color: 'white',
        }),
      );
    }
  }

  expandDescription(): void {
    this.expanded = true;

    if (this.richText) {
      this.quill.setContents(this.richText.ops);
    } else if (this.ppPlainText) {
      this.quill.setText(this.ppPlainText);
    }

    this.highlightWords();
  }

  collapseDescription(): void {
    this.expanded = false;

    if (this.richText) {
      this.quill.setContents(this.richText.ops);
    } else if (this.ppPlainText) {
      this.quill.setText(this.ppPlainText);
    }

    if (this.textLines > 3) {
      let lengthToTrim =
        this.splitText[0].length + this.splitText[1].length + this.splitText[2].length;

      if (lengthToTrim > 200) {
        lengthToTrim = 200;
      }

      this.long = true;
      this.quill.editor.deleteText(lengthToTrim, this.length);
      this.quill.editor.insertText(lengthToTrim, '...', 'bold', true);
    } else if (this.length > 200) {
      this.long = true;
      this.quill.editor.deleteText(200, this.length);
      this.quill.editor.insertText(200, '...', 'bold', true);
    }

    this.highlightWords();
  }

  likeComment(): void {
    const liked = this.reaction?.like.includes(this.user.userId);

    this.reactionsService.likeComment(this.ppId, this.ppPointId, !liked).subscribe();
  }
}
