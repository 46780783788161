import { Component, EventEmitter, Input, Output } from '@angular/core';
import { preventNonNumberInput } from 'src/app/core/helpers/check-if-number';
import { EStatus } from 'src/app/project/shared/enums/status.enum';

@Component({
  selector: 'pp-fleet-management-new-fleet-status-input',
  templateUrl: './fleet-management-new-fleet-status-input.component.html',
  styleUrls: ['./fleet-management-new-fleet-status-input.component.scss'],
})
export class FleetManagementNewFleetStatusInputComponent {
  @Input() ppStatus: EStatus;
  @Input() ppWeight: number;
  @Output() ppWeightChange = new EventEmitter<number>();

  onWeightChange(weight: number): void {
    if (weight > 100) {
      weight = 100;
    } else if (weight < 0) {
      weight = 0;
    }

    this.ppWeight = weight;
    this.ppWeightChange.emit(weight);
  }

  preventNonNumberInput(event: KeyboardEvent): void {
    preventNonNumberInput(event);
  }

  onInput(event: Event): void {
    const target = event.target as HTMLInputElement;

    if (+target.value > 100) {
      target.value = '100';
    } else if (+target.value < 0) {
      target.value = '0';
    }
  }

  preventEmptyInputValue(event: Event): void {
    const target = event.target as HTMLInputElement;

    if (target.value) {
      return;
    }

    target.value = '0';
    this.ppWeightChange.emit(+target.value);
  }
}
