import { ERowType } from 'src/app/project/shared/enums/row-type.enum';
import { TPoint } from '../../../../points/points.model';
import { TTableItem } from '../table.model';

export function findPointIndexInTable(
  points: TPoint[],
  items: TTableItem[],
  pointSequenceNumber: string | number,
): number {
  const pointIndex = points.findIndex((_point) => _point.sequenceNumber === pointSequenceNumber);

  const pointIndexInTable = items.findIndex(
    (_item) => _item.type === ERowType.POINT && _item.index === pointIndex,
  );

  return pointIndexInTable;
}
