import { EDashletPeriod } from 'src/app/project/modules/dashboard/dashboard-timeframes-enums';
import { EDashletType } from 'src/app/project/modules/dashboard/dashlets-enums';
import { TDashlet } from 'src/app/project/modules/preferences/preferences.model';

export function generateNewAssetDashlet(dashletType: EDashletType): TDashlet {
  let dashletSize = { rows: 9, cols: 12 };

  switch (dashletType) {
    case EDashletType.HIGH:
    case EDashletType.MEDIUM:
    case EDashletType.LOW:
    case EDashletType.OPEN:
    case EDashletType.IN_PROGRESS:
    case EDashletType.ONHOLD:
    case EDashletType.CLOSED:
    case EDashletType.COMMENT_CREATE:
    case EDashletType.DEFECT_CREATE:
    case EDashletType.TO_REVIEW:
    case EDashletType.CANCELED:
    case EDashletType.DEFECT_DELETE:
    case EDashletType.DEFECT_UPDATE:
      dashletSize = { rows: 7, cols: 4 };
      break;
    case EDashletType.ACTIVITIES:
      dashletSize = { rows: 12, cols: 7 };
      break;
    case EDashletType.COST_COMPARISON:
      dashletSize = { rows: 9, cols: 8 };
      break;
    case EDashletType.CURRENT_PRIORITY:
    case EDashletType.CURRENT_STATUS:
      dashletSize = { rows: 9, cols: 6 };
      break;
    case EDashletType.CURRENT_STACKED_STATUS:
    case EDashletType.OVER_TIME_PRIORITY:
    case EDashletType.OVER_TIME_STATUS:
      dashletSize = { rows: 9, cols: 12 };
      break;
    default:
      dashletSize = { rows: 9, cols: 12 };
      break;
  }

  return {
    x: 0,
    y: 0,
    rows: dashletSize.rows,
    cols: dashletSize.cols,
    selectedRange: {
      accountId: null,
      workspaceIds: [],
      workspacesCosts: dashletType === EDashletType.COST_COMPARISON ? {} : undefined,
    },
    period:
      dashletType === EDashletType.ACTIVITIES ||
      dashletType === EDashletType.CURRENT_PRIORITY ||
      dashletType === EDashletType.CURRENT_STATUS ||
      dashletType === EDashletType.CURRENT_STACKED_STATUS ||
      dashletType === EDashletType.COST_COMPARISON
        ? null
        : EDashletPeriod.WEEK,
    name: dashletType,
  };
}
