import { TFilters } from '../site-filter.model';

export function compareFilterReminders(firstFilter: TFilters, secondFilter: TFilters): boolean {
  const reminders1 = firstFilter.reminders;
  const reminders2 = secondFilter.reminders;

  if (
    !reminders1.startDate &&
    !reminders2.startDate &&
    !reminders1.endDate &&
    !reminders2.endDate
  ) {
    return true;
  }

  if (reminders1.startDate !== reminders2.startDate) {
    return false;
  }

  if (reminders1.endDate !== reminders2.endDate) {
    return false;
  }

  return true;
}
