import { translate } from 'src/app/project/features/translate/translate';
import {
  ESemiSimplePermission,
  ESimplePermission,
} from 'src/app/project/modules/share/permissions.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

export const getViewDataByPermission = (
  permission: ESimplePermission | ESemiSimplePermission,
): { iconPath: EIconPath; text: string } => {
  switch (permission) {
    case ESimplePermission.NONE:
    case ESemiSimplePermission.NONE:
      return { iconPath: EIconPath.ICON_USER_GROUPS_HIDDEN_14, text: translate('hide') };
    case ESimplePermission.VIEW:
      return { iconPath: EIconPath.ICON_USER_GROUPS_VISIBLE_14, text: translate('view_only') };
    case ESemiSimplePermission.VIEW:
      return { iconPath: EIconPath.ICON_USER_GROUPS_VISIBLE_14, text: translate('view_only') };
    case ESemiSimplePermission.ALLOW:
      return { iconPath: EIconPath.ICON_USER_GROUPS_VISIBLE_14, text: translate('allow') };
    case ESimplePermission.EDIT:
      return { iconPath: EIconPath.ICON_USER_GROUPS_EDITABLE_14, text: translate('can_edit') };
    default:
      return { iconPath: null, text: null };
  }
};
