<pp-modal>
  <ng-container heading>
    {{ 'close_point' | ppTranslate }}
  </ng-container>

  <ng-container body>
    <p>
      {{ 'close_point_modal_description' | ppTranslate }}
    </p>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'close_without_saving' | ppTranslate"
      (ppCancel)="hideModal()"
      (ppConfirm)="closePoint()"
      [ppError]="true"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
