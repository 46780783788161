import { EPriority } from 'src/app/project/modules/points/priorities';
import { Fill, Stroke, Style, Circle } from 'ol/style.js';

export function createNormalStyle(priority: string, isMobileInput: boolean): Style[] {
  let pinColor = '#FFFFFF';
  let fillColor = '#FFFFFF';

  if (priority === EPriority.LOW) {
    pinColor = 'rgb(77, 160, 229)';
    fillColor = 'rgba(77, 160, 229, 0.2)';
  }

  if (priority === EPriority.MEDIUM) {
    pinColor = 'rgb(255, 206, 41)';
    fillColor = 'rgba(255, 206, 41, 0.2)';
  }

  if (priority === EPriority.HIGH) {
    pinColor = 'rgb(229, 79, 80)';
    fillColor = 'rgba(229, 79, 80, 0.2)';
  }

  if (!isMobileInput) {
    const style = [
      new Style({
        image: new Circle({
          radius: 6.5,
          fill: new Fill({
            color: pinColor,
          }),
          stroke: new Stroke({
            width: 2,
            color: 'white',
          }),
        }),
      }),
      new Style({
        stroke: new Stroke({
          color: pinColor,
          width: 3,
        }),
        fill: new Fill({
          color: fillColor,
        }),
      }),
    ];

    return style;
  } else {
    const style = [
      new Style({
        image: new Circle({
          radius: 6.5,
          fill: new Fill({
            color: pinColor,
          }),
          stroke: new Stroke({
            width: 2,
            color: 'white',
          }),
        }),
        zIndex: Infinity,
      }),
      new Style({
        image: new Circle({
          radius: 20,
          fill: new Fill({
            color: [255, 255, 255, 0.01],
          }),
        }),
        zIndex: Infinity,
      }),
      new Style({
        stroke: new Stroke({
          color: pinColor,
          width: 3,
        }),
        fill: new Fill({
          color: fillColor,
        }),
      }),
    ];

    return style;
  }
}
