import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

import { Component, Input, OnChanges, OnDestroy } from '@angular/core';

import { Store, select } from '@ngrx/store';

import { TAllUsers, TViewerDetails } from '@project/view-models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EStore } from 'src/app/project/shared/enums/store.enum';

dayjs.extend(updateLocale);
dayjs.extend(relativeTime);

@Component({
  selector: 'pp-point-seen-by',
  templateUrl: './point-seen-by.component.html',
  styleUrls: ['./point-seen-by.component.scss'],
})
export class PointSeenByComponent implements OnChanges, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @Input() ppSeenBy: {
    [key: string]: TViewerDetails;
  } = {};

  users: TAllUsers;
  seenByUsers = [];
  tooltipText = '';
  private users$;

  constructor(
    private store: Store<{
      users: TAllUsers;
    }>,
  ) {
    this.users$ = this.store.pipe(select(EStore.USERS));

    this.users$.pipe(takeUntil(this.destroy$)).subscribe((users) => {
      this.users = users;

      this.generateSeenBy();
    });

    dayjs.updateLocale('en', {
      relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'Just now',
        m: '1m',
        mm: '%dm',
        h: '1h',
        hh: '%dh',
        d: '1d',
        dd: '%dd',
        M: '1mo',
        MM: '%dmo',
        y: 'y',
        yy: '%dy',
      },
    });
  }

  ngOnChanges() {
    this.generateSeenBy();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  generateSeenBy(): void {
    const seenByUsers = [];

    this.tooltipText = '<table class="tooltipTable">';

    if (this.ppSeenBy) {
      const seenBy = Object.keys(this.ppSeenBy).sort((_a, _b) => {
        const a = this.ppSeenBy[_a].timestampEpochMillis;
        const b = this.ppSeenBy[_b].timestampEpochMillis;

        if (a < b) {
          return 1;
        }

        if (a > b) {
          return -1;
        }

        return 0;
      });

      seenBy.forEach((userId) => {
        if (this.users[userId]) {
          seenByUsers.push(userId);

          const avatarURL = this.users[userId]?.avatarPublicUrl;
          const date = dayjs(this.ppSeenBy[userId].timestampEpochMillis);
          const currentDate = new Date().getTime();
          const removeSuffix = currentDate - this.ppSeenBy[userId].timestampEpochMillis < 60000;
          const timestamp = date.fromNow(removeSuffix);
          const userName = this.users[userId]?.userName;

          this.tooltipText += `
            <div class="tooltipTableRow">
              <div class="tooltipAvatar__wrapper">
                <img class="tooltipAvatar" src="${avatarURL}" alt="${EIconPath.PLACEHOLDER_USER}" />
              </div>

              <span class="tooltipText tooltipTableCell">
                <span class="tooltipInnerCell">${userName} </span>
              </span>

              <span class="tooltipDate tooltipTableCell">
                <p class="tooltipInnerCell">${timestamp}</p>
              </span>
            </div>
          `;
        }
      });
    }

    this.tooltipText += '</table>';

    if (seenByUsers.length === 0) {
      this.tooltipText = '';
    }

    this.seenByUsers = seenByUsers;
  }
}
