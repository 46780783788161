export enum EBasicField {
  PRIORITY = 'Priority',
  STATUS = 'Status',
  SEQUENCE_NUMBER = 'sequenceNumber',
  TITLE = 'Title',
  DESCRIPTION = 'Description',
  CREATED = 'Created',
  UPDATED = 'Updated',
  CREATED_BY = 'Created By',
  ASSIGNEES = 'Assignees',
  TAGS = 'Tags',
  ACCOUNT = 'Account',
  SITE = 'Site',
  FLAGGED = 'flagged',
  REMINDER = 'Reminder',
}

export enum EBasicFieldShort {
  PRIORITY = 'P',
  STATUS = 'S',
  SEQUENCE_NUMBER = 'ID',
  FLAGGED = 'Flag',
}
