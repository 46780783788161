import { Component, OnDestroy, OnInit } from '@angular/core';

import { AttachmentsFilesService } from 'src/app/project/modules/points/attachments/attachments-files.service';
import { Modal, ModalService } from '../../../../../components/modal/modal.service';
import { PromptService } from '../../../../../components/prompt/prompt.service';
import { PointActivityService } from '../../point-timeline/point-activity.service';

import { Subject, of } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { NEW_POINT_ID } from 'src/app/project/shared/constants/point.const';

@Component({
  selector: 'pp-delete-files-modal',
  templateUrl: './delete-files-modal.component.html',
  styleUrls: ['./delete-files-modal.component.scss'],
})
export class DeleteFilesModalComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private modal: Modal;

  processing: boolean;

  numberOfAttachments = 0;

  constructor(
    private promptService: PromptService,
    private modalService: ModalService,
    private pointActivityService: PointActivityService,
    private attachmentsFilesService: AttachmentsFilesService,
    private translationPipe: TranslationPipe,
  ) {}

  ngOnInit() {
    this.modal = this.modalService.getModal();

    this.processing = false;

    if (!this.modal.data.attachmentIds) {
      throw new Error('Data for this dropdown is required');
    }

    this.numberOfAttachments =
      this.modal.data.attachmentIds.documents.length +
      this.modal.data.attachmentIds.images.length +
      this.modal.data.attachmentIds.images360.length +
      this.modal.data.attachmentIds.videos.length;
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  confirm(): void {
    if (!this.processing) {
      logEventInGTAG(EGoogleEventSite.SITE__POINT__ATTACHMENT_DELETE, {
        event_category: EGoogleEventCategory.SITE,
      });

      this.deleteFiles();
    }
  }

  deleteFiles(): void {
    const newPoint =
      this.modal.data._id === undefined ||
      this.modal.data._id === null ||
      this.modal.data._id === NEW_POINT_ID;

    const attachmentNumber =
      this.modal.data.attachmentIds.documents.length +
      this.modal.data.attachmentIds.images.length +
      this.modal.data.attachmentIds.images360.length +
      this.modal.data.attachmentIds.videos.length;

    this.attachmentsFilesService
      .deleteFiles(this.modal.data.attachmentIds, newPoint)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          const promptText = this.translationPipe.transform(
            attachmentNumber > 1 ? 'prompt_file_deleted_multiple' : 'prompt_file_deleted',
          );

          this.promptService.showSuccess(promptText);
          this.hideModal(false);

          if (!newPoint) {
            this.pointActivityService.refreshTimeline(
              this.modal.data.workspaceId,
              this.modal.data._id,
            );
          }
        }),
        catchError((error) => {
          let promptText = this.translationPipe.transform(
            attachmentNumber > 1
              ? 'prompt_file_deleted_multiple_error'
              : 'prompt_file_deleted_error',
          );

          if (error.status === EStatusCode.FORBIDDEN) {
            promptText = this.translationPipe.transform('prompt_changes_permission_denied');
          }

          this.promptService.showError(promptText);

          return of();
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  hideModal(cancel: boolean = true): void {
    this.modalService.hideModal(cancel);
  }
}
