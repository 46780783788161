import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { TTranslationKey } from 'src/app/project/features/translate/types';
import { checkEmailValidity } from 'src/app/project/shared/utils/check-emails';
import { AddUsersIssuesService } from '../../../account/account-settings/add-users-modal/add-users-issues.service';
import { TAccountUserError } from '../../../errors/response-error.consts';
import { UsersService } from '../../../users/users.service';
import { TFleetShare } from '../../fleet-sharing/fleet-sharing.consts';

@Component({
  selector: 'pp-fleet-sharing-modal-email-input',
  templateUrl: './fleet-sharing-modal-email-input.component.html',
  styleUrls: ['./fleet-sharing-modal-email-input.component.scss'],
})
export class FleetSharingModalEmailInputComponent {
  @Input() ppEmailList: string[];
  @Output() ppEmailListChange = new EventEmitter<string[]>();
  @Input() ppDisabled: boolean;
  @Input() ppProcessing: boolean;
  @Output() ppAction = new EventEmitter<void>();
  @Input() ppFleetShares: TFleetShare[];

  constructor(
    private usersService: UsersService,
    private translationPipe: TranslationPipe,
    private addUsersIssuesService: AddUsersIssuesService,
  ) {}

  inviteUsers(): void {
    if (this.ppDisabled || this.ppProcessing) {
      return;
    }

    this.ppAction.next();
  }

  updateEmails(emails: string[]): void {
    this.checkEmails(emails);

    this.ppEmailListChange.emit(emails);
  }

  private checkEmails(emails: string[]) {
    const users = this.usersService.getUsers();
    let errorList: TAccountUserError[] = [];

    emails.forEach((email, index) => {
      const user = Object.values(users).find((user) => user.email === email);

      if (this.checkIfEmailAlreadyExists(email)) {
        errorList.push(this.generateError(email, 'fleet_sharing_email_already_exists'));
      } else if (!checkEmailValidity(email)) {
        errorList.push(this.generateError(email, 'fleet_sharing_email_invalid'));
      } else if (user && !user.verified) {
        errorList.push(this.generateError(email, 'fleet_sharing_user_not_verified'));
      } else if (emails.indexOf(email) !== index) {
        errorList.push(this.generateError(email, 'fleet_sharing_email_duplicated'));
      }
    });

    this.addUsersIssuesService.showErrors(errorList);
  }

  private generateError(email: string, errorMessageKey: TTranslationKey): TAccountUserError {
    return {
      email: email,
      errorMessage: email + ': ' + this.translationPipe.transform(errorMessageKey),
      workspaceId: null,
    };
  }

  private checkIfEmailAlreadyExists(email: string): boolean {
    return this.ppFleetShares.some((fleetShare) => {
      const user = this.usersService.getUser(fleetShare.userId);

      return user?.email === email;
    });
  }
}
