<div class="siteOptions" [class.overviewSiteOptions]="ppIsOverview">
  <div class="siteOptions__leftOptions" *ngIf="workspaces$ | async as workspaces">
    <pp-button
      ppId="newPointBtn"
      class="button--icon-left addPointBtn"
      data-m-target="New point button"
      (ppAction)="newPoint()"
      ppStyle="primary"
      ppSize="large"
      [ppDisabled]="!ppDataFetched || offline"
      *ngIf="canCreatePoint && !isMobileScreen"
    >
      <img [src]="EIconPath.PLUS" class="plusIcon" />
      <span class="newPointText">{{ 'new_point' | ppTranslate }}</span>
      <span class="newPointShortText">{{ 'point' | ppTranslate }}</span>
    </pp-button>

    <pp-site-filter-dropdown
      [ppDataFetched]="ppDataFetched"
      class="filterDropdown siteOptions__dropdownButton"
      [class.margin--right]="
        globalSitePreferences &&
        workspace &&
        workspace.share.shareOption !== EUserRole.SITE_ADMIN &&
        workspace.share.shareOption !== EUserRole.OWNER &&
        workspace.share.shareOption !== EUserRole.ACCOUNT_ADMIN
      "
      [class.filterOverviewSpacing]="ppIsOverview"
    ></pp-site-filter-dropdown>

    <pp-site-options-sort-button
      class="siteOptions__dropdownButton"
      [ppDataFetched]="ppDataFetched"
    ></pp-site-options-sort-button>

    <pp-site-options-group-button
      class="siteOptions__dropdownButton"
      [ppDataFetched]="ppDataFetched"
    ></pp-site-options-group-button>

    <div id="columnDropdown" class="siteOptions__dropdownButton">
      <pp-button-dropdown
        [ppSrc]="EIconPath.ICON_TABLE_ICON"
        [ppText]="'table_columns' | ppTranslate"
        [ppActive]="columnsDropdownVisible || hiddenColumnsCount > 0"
        (ppAction)="toggleColumnDropdown('columnDropdown')"
        ppTooltip
        id="columnDropdownBtn"
        data-m-target="Table columns button"
        ppTitle="Display Fields"
        *ngIf="
          !globalSitePreferences ||
          (workspace &&
            (workspace.share.shareOption === EUserRole.SITE_ADMIN ||
              workspace.share.shareOption === EUserRole.OWNER ||
              workspace.share.shareOption === EUserRole.ACCOUNT_ADMIN))
        "
      ></pp-button-dropdown>
    </div>

    <pp-saved-views-button
      [ppWorkspaceId]="workspace.workspaceId"
      [ppDataFetched]="ppDataFetched"
      *ngIf="savedViewsEnabled && ppDataFetched"
      class="siteOptions__dropdownButton"
    ></pp-saved-views-button>

    <pp-button-dropdown
      [ppSrc]="EIconPath.ICON_TABLE_EXPORT"
      [ppText]="'export' | ppTranslate"
      [ppActive]="exportModalVisible"
      (ppAction)="export()"
      ppTooltip
      id="exportListBtn"
      data-m-target="Export points button"
      [ppTitle]="!exportHidden ? '' : ('export_permission_denied' | ppTranslate)"
      *ngIf="canExport"
      [ppDisabled]="table?.points.length === 0 || !ppDataFetched || exportHidden"
      class="siteOptions__dropdownButton"
    ></pp-button-dropdown>

    <pp-floating-actions
      [hidden]="table?.selectedPoints.size === 0 || table?.selectedPoints.size === null"
      [ppNumberOfSelectedPoints]="table?.selectedPoints.size"
      *ngIf="table?.selectedPoints.size > 0 && ppDataFetched"
    ></pp-floating-actions>
  </div>

  <div class="siteOptions__rightOptions">
    <ng-container *ngIf="ppDataFetched">
      <pp-site-filter class="siteOptions__siteFilter"></pp-site-filter>
    </ng-container>

    <pp-reset-table-button
      *ngIf="ppDataFetched"
      [ppWorkspaceId]="ppIsOverview ? EWorkspaces.OVERVIEW : workspace.workspaceId"
    ></pp-reset-table-button>

    <div *ngIf="sitePlanExists">
      <pp-toggle
        ppId="toggleSitePlan"
        data-m-target="Site plan toggle button"
        class="exportListLine"
        (ppAction)="toggleSitePlan(true)"
        [ppChecked]="site.sitePlanVisible"
        ppStyle="blue"
      >
        <span class="siteOptions__sitePlanText">
          <span class="sitePlanToggle">{{ 'site_plan' | ppTranslate }}</span>
          <span class="shortSitePlanToggle">{{ 'plan' | ppTranslate }}</span>
        </span>
      </pp-toggle>
    </div>
  </div>
</div>

<div *ngIf="workspaces$ | async as workspaces">
  <!-- Mobile new point -->
  <pp-mobile-new-point-button
    (ppAction)="newPoint()"
    *ngIf="canCreatePoint && isMobileScreen"
  ></pp-mobile-new-point-button>

  <!-- Mobile site plan toggle -->
  <div class="floatingControl--toggle" *ngIf="sitePlanExists">
    <pp-toggle
      ppId="toggleSitePlan"
      (ppAction)="toggleSitePlan(false)"
      ppStyle="floating"
      [ppChecked]="site.sitePlanVisible"
    ></pp-toggle>
  </div>
</div>
