import { Component, Input } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TActivityChange } from '../../activity-change.model';

@Component({
  selector: 'pp-dashlet-activity-flag',
  templateUrl: './dashlet-activity-flag.component.html',
  styleUrls: ['./dashlet-activity-flag.component.scss'],
})
export class DashletActivityFlagComponent {
  @Input() ppChange: TActivityChange<string>;

  EIconPath = EIconPath;
}
