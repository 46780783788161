import Timeline from '../../Timeline';
import { timelineProperties } from './timeline-variables';
import { TAnyFunction } from '@core/helpers';

export function onTimelineBasicElementMouseDown({
  _canEdit,
  _event,
  element,
  initialScroll,
  timeline,
  elementRect,
  startPosition,
  difference,
  mouseup,
  mousemove,
  scrollmove,
}: {
  _canEdit: boolean;
  _event: MouseEvent;
  element: HTMLElement;
  initialScroll: number;
  timeline: Timeline;
  elementRect: DOMRect;
  startPosition: number;
  difference: number;
  mouseup: TAnyFunction;
  mousemove: TAnyFunction;
  scrollmove: TAnyFunction;
}): { initialScroll: number; elementRect: DOMRect; startPosition: number; difference: number } {
  if (_canEdit) {
    _event.stopPropagation();
    _event.preventDefault();

    const style = window.getComputedStyle(element);
    const matrix = new WebKitCSSMatrix(style.transform);

    timelineProperties.timelineTable = document.getElementById('timeline__body');
    initialScroll = timeline.timelineBody.virtualScroller.scrollElement.scrollLeft;
    elementRect = element.getBoundingClientRect();
    startPosition = matrix.m41;
    timelineProperties.active = true;

    const mousePostition = _event.x;
    const elementPosition = elementRect.left;

    difference = mousePostition - elementPosition;

    element.style.cursor = 'grabbing';
    (element.parentNode as HTMLElement).style.cursor = 'grabbing';

    document.onmouseup = mouseup;
    document.onmousemove = mousemove;
    timelineProperties.timelineTable.onscroll = scrollmove;
  }
  return { initialScroll, elementRect, startPosition, difference };
}
