var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
/**
 * @module ol/control/Rotate
 */
import Control from './Control.js';
import EventType from '../events/EventType.js';
import { CLASS_CONTROL, CLASS_HIDDEN, CLASS_UNSELECTABLE } from '../css.js';
import { easeOut } from '../easing.js';
/**
 * @typedef {Object} Options
 * @property {string} [className='ol-rotate'] CSS class name.
 * @property {string|HTMLElement} [label='⇧'] Text label to use for the rotate button.
 * Instead of text, also an element (e.g. a `span` element) can be used.
 * @property {string} [tipLabel='Reset rotation'] Text label to use for the rotate tip.
 * @property {string} [compassClassName='ol-compass'] CSS class name for the compass.
 * @property {number} [duration=250] Animation duration in milliseconds.
 * @property {boolean} [autoHide=true] Hide the control when rotation is 0.
 * @property {function(import("../MapEvent.js").default):void} [render] Function called when the control should
 * be re-rendered. This is called in a `requestAnimationFrame` callback.
 * @property {function():void} [resetNorth] Function called when the control is clicked.
 * This will override the default `resetNorth`.
 * @property {HTMLElement|string} [target] Specify a target if you want the control to be
 * rendered outside of the map's viewport.
 */
/**
 * @classdesc
 * A button control to reset rotation to 0.
 * To style this control use css selector `.ol-rotate`. A `.ol-hidden` css
 * selector is added to the button when the rotation is 0.
 *
 * @api
 */
var Rotate = /** @class */function (_super) {
  __extends(Rotate, _super);
  /**
   * @param {Options} [opt_options] Rotate options.
   */
  function Rotate(opt_options) {
    var _this = this;
    var options = opt_options ? opt_options : {};
    _this = _super.call(this, {
      element: document.createElement('div'),
      render: options.render,
      target: options.target
    }) || this;
    var className = options.className !== undefined ? options.className : 'ol-rotate';
    var label = options.label !== undefined ? options.label : '\u21E7';
    var compassClassName = options.compassClassName !== undefined ? options.compassClassName : 'ol-compass';
    /**
     * @type {HTMLElement}
     * @private
     */
    _this.label_ = null;
    if (typeof label === 'string') {
      _this.label_ = document.createElement('span');
      _this.label_.className = compassClassName;
      _this.label_.textContent = label;
    } else {
      _this.label_ = label;
      _this.label_.classList.add(compassClassName);
    }
    var tipLabel = options.tipLabel ? options.tipLabel : 'Reset rotation';
    var button = document.createElement('button');
    button.className = className + '-reset';
    button.setAttribute('type', 'button');
    button.title = tipLabel;
    button.appendChild(_this.label_);
    button.addEventListener(EventType.CLICK, _this.handleClick_.bind(_this), false);
    var cssClasses = className + ' ' + CLASS_UNSELECTABLE + ' ' + CLASS_CONTROL;
    var element = _this.element;
    element.className = cssClasses;
    element.appendChild(button);
    _this.callResetNorth_ = options.resetNorth ? options.resetNorth : undefined;
    /**
     * @type {number}
     * @private
     */
    _this.duration_ = options.duration !== undefined ? options.duration : 250;
    /**
     * @type {boolean}
     * @private
     */
    _this.autoHide_ = options.autoHide !== undefined ? options.autoHide : true;
    /**
     * @private
     * @type {number|undefined}
     */
    _this.rotation_ = undefined;
    if (_this.autoHide_) {
      _this.element.classList.add(CLASS_HIDDEN);
    }
    return _this;
  }
  /**
   * @param {MouseEvent} event The event to handle
   * @private
   */
  Rotate.prototype.handleClick_ = function (event) {
    event.preventDefault();
    if (this.callResetNorth_ !== undefined) {
      this.callResetNorth_();
    } else {
      this.resetNorth_();
    }
  };
  /**
   * @private
   */
  Rotate.prototype.resetNorth_ = function () {
    var map = this.getMap();
    var view = map.getView();
    if (!view) {
      // the map does not have a view, so we can't act
      // upon it
      return;
    }
    var rotation = view.getRotation();
    if (rotation !== undefined) {
      if (this.duration_ > 0 && rotation % (2 * Math.PI) !== 0) {
        view.animate({
          rotation: 0,
          duration: this.duration_,
          easing: easeOut
        });
      } else {
        view.setRotation(0);
      }
    }
  };
  /**
   * Update the rotate control element.
   * @param {import("../MapEvent.js").default} mapEvent Map event.
   * @override
   */
  Rotate.prototype.render = function (mapEvent) {
    var frameState = mapEvent.frameState;
    if (!frameState) {
      return;
    }
    var rotation = frameState.viewState.rotation;
    if (rotation != this.rotation_) {
      var transform = 'rotate(' + rotation + 'rad)';
      if (this.autoHide_) {
        var contains = this.element.classList.contains(CLASS_HIDDEN);
        if (!contains && rotation === 0) {
          this.element.classList.add(CLASS_HIDDEN);
        } else if (contains && rotation !== 0) {
          this.element.classList.remove(CLASS_HIDDEN);
        }
      }
      this.label_.style.transform = transform;
    }
    this.rotation_ = rotation;
  };
  return Rotate;
}(Control);
export default Rotate;
