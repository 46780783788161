<div class="savedViews__row" (click)="selectView()">
  <div
    class="savedViews__input-container"
    [ngClass]="{
      'savedViews__input-container--active':
        ppView.id === selectedViewIds[ppViews.personal.workspaceId]
    }"
  >
    <div
      class="savedViews__input"
      contenteditable="false"
      #nameInput
      [innerText]="ppView.name"
      (blur)="handleInputBlur($event)"
      (keydown.enter)="handleEnterKeyPress($event)"
      (focus)="setCursorToEnd(nameInput)"
    ></div>

    <ng-container *ngIf="ppViews.personal.defaultViewId === ppView.id">
      <div class="savedViews__icon-container">
        <pp-icon
          [ppSrc]="EIconPath.ICON_NAV_TABLE_VIEW_18"
          ppColor="primary-600"
          [ppDisplayContents]="true"
        ></pp-icon>
      </div>
    </ng-container>

    <ng-container *ngIf="ppViews.shared.sharedDefaultViewId === ppView.id">
      <div class="savedViews__icon-container">
        <pp-icon
          [ppSrc]="EIconPath.ICON_NAV_TABLE_VIEW_18"
          [ppColor]="'grey-500'"
          [ppDisplayContents]="true"
        ></pp-icon>
      </div>
    </ng-container>
  </div>

  <button
    [id]="'savedViewsDrodownBtn' + ppView.id"
    (click)="showDropdown($event, 'savedViewsDrodownBtn' + ppView.id)"
  >
    <pp-icon
      [ppSrc]="EIconPath.ICON_MISC_3_DOTS_MENU_14"
      [ppColor]="'grey-400'"
      [ppDisplayContents]="true"
    ></pp-icon>
  </button>
</div>
