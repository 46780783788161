<div class="siteList" [class.siteList__invisible]="activeStep !== EImportCustomFieldStep.SITES">
  <pp-site-list-select
    [ppAccounts]="accounts"
    [ppType]="EListSelectTypes.CUSTOM_FIELD"
    [ppFieldsPerAccount]="customFieldsPerAccount"
    [ppFieldsPerWorkspace]="customFieldsPerWorkspace"
    (ppAction)="openSiteCustomFields($event)"
  ></pp-site-list-select>
</div>

<div class="customFieldList" *ngIf="activeStep === EImportCustomFieldStep.CUSTOM_FIELDS">
  <button class="selectCustomFields__back" (click)="back()">
    <img [src]="EIconPath.ARROW_LEFT" />
    {{ 'back_to_site_selection' | ppTranslate }}
  </button>

  <div class="selectUsers_site-name-wrapper">
    <h6 class="selectUsers__site-name" *ngIf="!(data?.length > 0)">
      {{ 'no_custom_fields_available_on_site' | ppTranslate }}
    </h6>

    <div
      ppTooltip
      [ppTrimTooltip]="true"
      [ppTitle]="site.siteName"
      class="selectUsers__site-name"
      *ngIf="data?.length > 0"
    >
      <h6 class="selectUsers__site-name-heading">
        {{ 'select_custom_fields_from' | ppTranslate }} <strong>{{ site.siteName }}</strong>
      </h6>
    </div>

    <pp-add-custom-field-import-select-all
      [ppSite]="site"
      [ppNumberOfSelectableFields]="numberOfSelectableFields"
      [ppSelectedCustomFields]="ppSelectedCustomFields"
      (ppSelectAll)="selectAll()"
      (ppDeselectAll)="deselectAll()"
    ></pp-add-custom-field-import-select-all>
  </div>

  <table
    aria-label="Custom Fields List"
    class="customFields__table-container"
    *ngIf="data?.length > 0"
  >
    <pp-add-custom-field-import-table-header
      [ppSelectedWorkspaceCustomFields]="ppSelectedCustomFields[site.accountId][site.workspaceId]"
      [ppSite]="site"
      [ppNumberOfSelectableFields]="numberOfSelectableFields"
      (ppSelectAll)="selectAll()"
      (ppDeselectAll)="deselectAll()"
    ></pp-add-custom-field-import-table-header>

    <pp-add-custom-field-modal-import-field
      *ngFor="let customField of data"
      [ppCustomFieldId]="customField"
      [ppWorkspaceId]="site.workspaceId"
      [ppAccountId]="site.accountId"
      [ppTimelineEnabled]="ppTimelineEnabled"
      [ppTimelineExists]="ppTimelineExists"
      [ppSelectedCustomFields]="ppSelectedCustomFields"
      [ppSelectedCustomFieldsNames]="selectedCustomFieldsNames"
      [ppInitialWorkspaceCustomFields]="initialWorkspaceCustomFields"
      (ppSelectField)="selectCustomField($event)"
    >
    </pp-add-custom-field-modal-import-field>
  </table>
</div>
