import { cloneDeep } from 'lodash';
import { AddImage, ReplaceImage, AddFile, AddImage360, AddVideo } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';

export function addAttachment(
  oldState: TPointsByWorkspace,
  action: AddImage | ReplaceImage | AddFile | AddImage360 | AddVideo,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);
  let pointIndex = null;

  pointIndex = state[action.payload.workspaceId].entities.findIndex(
    (_point) => _point._id === action.payload._id,
  );

  const point = state[action.payload.workspaceId].entities[pointIndex];

  if (point) {
    point.containsAttachments = true;
    point.header.updatedEpochMillis = new Date().getTime();

    state[action.payload.workspaceId].entities.splice(pointIndex, 1, point);
  }

  return state;
}
