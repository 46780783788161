<div id="tabContent" class="tabContent" *ngIf="user">
  <pp-grid [ppOptions]="{ bodyPadding: true }" *ngIf="hasWorkspaces">
    <ng-container title> {{ 'top_right_logo' | ppTranslate }} </ng-container>

    <ng-container titleBar-right>
      <div class="siteSettings__button-container">
        <pp-button
          ppId="updateLogoBtn--cancel"
          ppStyle="secondary"
          ppSize="small"
          *ngIf="newLogo"
          (ppAction)="cancelNewLogo()"
        >
          {{ 'cancel' | ppTranslate }}
        </pp-button>

        <pp-button [ppStyle]="newLogo ? 'secondary' : 'primary'" ppSize="small" *ngIf="!newLogo">
          <label
            class="settingsNewSite__upload-button"
            [class.settingsNewSite__upload-button--disabled]="offline"
            for="LogoUpload"
          >
            {{ (logoId ? 'update' : 'add') | ppTranslate }}
            {{ 'top_right_logo' | ppTranslate }}
          </label>

          <pp-upload
            id="LogoUpload--update"
            [ppId]="'LogoUpload'"
            (ppUpload)="setLogo($event)"
            [ppDisabled]="offline"
          >
          </pp-upload>
        </pp-button>

        <pp-button
          ppId="updateLogoBtn--update"
          [ppStyle]="newLogo ? 'primary' : 'secondary'"
          [ppDisabled]="!newLogo || offline"
          [ppProcess]="processingLogoUpdate"
          ppSize="small"
          *ngIf="newLogo"
          (ppAction)="updateLogo()"
        >
          {{ 'confirm' | ppTranslate }}
        </pp-button>
      </div>
    </ng-container>

    <ng-container body>
      <div class="settingsSection__no-content" *ngIf="!logoId && !uploadedImage">
        {{ 'no_pdf_logo_found' | ppTranslate }}
      </div>

      <div *ngIf="!hasWorkspaces && !hasWorkspace && !logoId && offline">
        <h5 class="settingsSection__notAvailable">
          {{ 'data_unavailable_offline' | ppTranslate }}
        </h5>
      </div>

      <img
        ppImage
        class="siteSettingsPlan__site-plan"
        [ppSecureSrc]="'api/v1/images/' + logoId + '/file/size/bounded/1200'"
        *ngIf="logoId && !uploadedImage"
      />

      <img
        ppImage
        class="siteSettingsPlan__site-logo-new"
        [src]="uploadedImage"
        *ngIf="uploadedImage"
      />
    </ng-container>
  </pp-grid>
</div>
