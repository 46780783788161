import { cloneDeep } from 'lodash';

import { Injectable } from '@angular/core';

import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';

import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';

import { TAllUsers } from '@project/view-models';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { TAllCustomFields } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { TAllFilters, TFilters } from '../site-filter.model';

@Injectable({
  providedIn: 'root',
})
export class CheckFilterService {
  constructor(
    private usersService: UsersService,
    private customFieldsService: CustomFieldsService,
    private workspaceService: WorkspaceService,
  ) {}

  checkSavedFilters(
    savedFilterToCheck: TAllFilters,
    currentWorkspaceId: string,
    filters: TFilters,
  ): TAllFilters {
    if (savedFilterToCheck) {
      const savedFilter = cloneDeep(savedFilterToCheck);
      const users = this.usersService.getUsers();
      const customFields = this.customFieldsService.getCustomFields();

      if (Object.keys(users).length > 0) {
        Object.keys(savedFilter).forEach((workspaceId) => {
          const workspace = this.workspaceService.findWorkspace(currentWorkspaceId);

          if (workspace && workspace.users) {
            this.checkAssignees(savedFilter, workspaceId, users, workspace);
            this.checkCreatedBy(savedFilter, workspaceId, users, workspace);
            this.correctCustomFields(savedFilter, workspaceId, customFields);
            this.checkCustomFields(filters, savedFilter, workspaceId);

            if (typeof savedFilter[workspaceId].showPointsWithoutLocation === 'undefined') {
              savedFilter[workspaceId].showPointsWithoutLocation = true;
            }

            if (typeof savedFilter[workspaceId].showPointsWithLocation === 'undefined') {
              savedFilter[workspaceId].showPointsWithLocation = true;
            }

            if (typeof savedFilter[workspaceId].showUnflaggedPoints === 'undefined') {
              savedFilter[workspaceId].showUnflaggedPoints = true;
            }

            if (typeof savedFilter[workspaceId].showFlaggedPoints === 'undefined') {
              savedFilter[workspaceId].showFlaggedPoints = true;
            }

            if (typeof savedFilter[workspaceId].reminders === 'undefined') {
              savedFilter[workspaceId].reminders = {
                startDate: null,
                endDate: null,
              };
            }
          }
        });
      }

      this.checkExcludedTags(savedFilter);

      return savedFilter;
    }

    return savedFilterToCheck;
  }

  private checkExcludedTags(savedFilter: TAllFilters): void {
    Object.keys(savedFilter).forEach((workspaceId) => {
      if (!savedFilter[workspaceId].excludedTags) {
        savedFilter[workspaceId].excludedTags = {
          value: [],
          appliedValue: [],
          applied: true,
          combinedTags: false,
        };
      }
    });
  }

  private checkCustomFields(
    filters: TFilters,
    savedFilter: TAllFilters,
    workspaceId: string,
  ): void {
    if (filters?.customFields) {
      filters.customFields.forEach((field) => {
        const existingField = savedFilter[workspaceId].customFields.find(
          (savedCF) => savedCF.id === field.id,
        );

        if (field.workspaceId === workspaceId && !existingField) {
          savedFilter[workspaceId].customFields.push(field);
        }
      });
    }
  }

  private correctCustomFields(
    savedFilter: TAllFilters,
    workspaceId: string,
    customFields: TAllCustomFields,
  ): void {
    if (savedFilter[workspaceId].customFields) {
      savedFilter[workspaceId].customFields.forEach((customField, index) => {
        if (
          !(
            customFields[customField.workspaceId] &&
            customFields[customField.workspaceId][customField.id.toString()]
          ) ||
          customFields[customField.workspaceId]?.[customField.id.toString()].display === false
        ) {
          savedFilter[workspaceId].customFields.splice(index, 1);
        } else {
          if (
            customFields[customField.workspaceId][customField.id.toString()].type ===
              ECustomFieldType.TIMELINE &&
            !savedFilter[workspaceId].customFields[index].appliedValues
          ) {
            savedFilter[workspaceId].customFields[index].appliedValues = {
              startDate: null,
              endDate: null,
            };
          }
        }
      });
    }
  }

  private checkCreatedBy(
    savedFilter: TAllFilters,
    workspaceId: string,
    users: TAllUsers,
    workspace: TWorkspace,
  ): void {
    savedFilter[workspaceId].createdBy.value.forEach((user, index) => {
      if (!users[user]) {
        savedFilter[workspaceId].createdBy.value.splice(index, 1);
        savedFilter[workspaceId].createdBy.appliedValue.splice(index, 1);
      }

      if (workspace) {
        if (!workspace?.users.includes(user)) {
          savedFilter[workspaceId].createdBy.value.splice(index, 1);
          savedFilter[workspaceId].createdBy.appliedValue.splice(index, 1);
        }
      }
    });
  }

  private checkAssignees(
    savedFilter: TAllFilters,
    workspaceId: string,
    users: TAllUsers,
    workspace: TWorkspace,
  ): void {
    savedFilter[workspaceId].assignees.value.forEach((user, index) => {
      if (!users[user]) {
        savedFilter[workspaceId].assignees.value.splice(index, 1);
        savedFilter[workspaceId].assignees.appliedValue.splice(index, 1);
      }

      if (workspace) {
        if (!workspace?.users.includes(user)) {
          savedFilter[workspaceId].assignees.value.splice(index, 1);
          savedFilter[workspaceId].assignees.appliedValue.splice(index, 1);
        }
      }
    });
  }
}
