import * as ExcelJS from 'exceljs';
import { EPriority, humanizePriority } from '../../points/priorities';

export function stylePriorityColumn(worksheet: ExcelJS.Worksheet): void {
  const priorityCol = worksheet.getColumn(4);

  priorityCol.eachCell((cell) => {
    const priority = cell.text;
    const fieldFilled = isFieldFilled(priority);
    const color = getFieldColor(priority);

    if (fieldFilled) {
      addStyleToPriorityCell(cell, color);
    }
  });
}

function addStyleToPriorityCell(cell: ExcelJS.Cell, color: string) {
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: color,
    },
  };

  cell.font = {
    color: {
      argb: 'FFFFFF',
    },
  };
}

function getFieldColor(priority: string) {
  return priority === humanizePriority(EPriority.HIGH)
    ? 'E45050'
    : priority === humanizePriority(EPriority.MEDIUM)
    ? 'FFD02E'
    : priority === humanizePriority(EPriority.LOW)
    ? '569FD7'
    : 'FFFFFF';
}

function isFieldFilled(priority: string) {
  return (
    priority === humanizePriority(EPriority.HIGH) ||
    priority === humanizePriority(EPriority.MEDIUM) ||
    priority === humanizePriority(EPriority.LOW)
  );
}
