import { Component, Input, OnInit } from '@angular/core';
import { TAccount } from 'src/app/project/modules/account/account.model';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { NewAssetSitePickerExpandingService } from '../new-asset-site-picker-expanding.service';

@Component({
  selector: 'pp-new-asset-site-picker-account',
  templateUrl: './new-asset-site-picker-account.component.html',
  styleUrls: ['./new-asset-site-picker-account.component.scss'],
})
export class NewAssetSitePickerAccountComponent implements OnInit {
  @Input() ppAccount: TAccount;

  expanded = false;
  EIconPath = EIconPath;

  constructor(private newAssetSitePickerExpandingService: NewAssetSitePickerExpandingService) {}

  ngOnInit(): void {
    this.expanded = this.newAssetSitePickerExpandingService.isAccountExpanded(
      this.ppAccount.accountId,
    );
  }

  toggleExpand(): void {
    this.expanded = !this.expanded;

    this.newAssetSitePickerExpandingService.setAccountExpanded(
      this.ppAccount.accountId,
      this.expanded,
    );
  }
}
