import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, takeUntil, tap, timer } from 'rxjs';
import { TTranslationKey } from '../../features/translate/types';

@Component({
  selector: 'pp-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent implements OnInit, OnDestroy {
  @Input() ppDurationSeconds: number;
  @Input() ppTranslationKey: TTranslationKey;
  @Input() ppTranslationKeyPrimaryColor: TTranslationKey;
  @Output() ppCallback = new EventEmitter<void>();

  private timer: Observable<number>;
  private readonly destroy$ = new EventEmitter<void>();
  private readonly countdownFinished$ = new EventEmitter<void>();

  timeLeft: number;
  private readonly oneSecondDurationInMs = 1000;

  ngOnInit(): void {
    this.startCountdown();
  }

  ngOnDestroy(): void {
    this.destroy$.emit();
  }

  tryEmitCallback(): void {
    if (this.timeLeft > 0) {
      return;
    }

    this.ppCallback.emit();

    this.resetTimer();
  }

  private startCountdown(): void {
    this.timer = timer(this.oneSecondDurationInMs, this.oneSecondDurationInMs);
    this.timeLeft = this.ppDurationSeconds;

    this.timer
      .pipe(
        takeUntil(this.destroy$),
        takeUntil(this.countdownFinished$),
        tap((value) => {
          this.onCountdownChange();
        }),
      )
      .subscribe();
  }

  private onCountdownChange(): void {
    if (this.timeLeft > 0) {
      this.timeLeft = this.timeLeft - 1;
    } else {
      this.stopCountdown();
    }
  }

  private stopCountdown(): void {
    this.countdownFinished$.emit();
  }

  private resetTimer(): void {
    this.countdownFinished$.emit();
    this.startCountdown();
  }
}
