import { Injectable } from '@angular/core';
import { PlanPinsService } from '../plan-pins.service';
import { PlanService } from '../plan.service';
import { GET_ACTIVE_PLAN } from '../plan.store';
import { EPlanPointMode } from '../../../shared/enums/plan-point-mode.enum';
import { PlanPointVariablesService } from './plan-point-variables.service';
import { MAX_AREAS } from '../../../shared/constants/plan-point.const';
import { EPlanModule } from '../plan-module.enum';
import { Feature, MapBrowserEvent } from 'ol';

@Injectable({
  providedIn: 'root',
})
export class PlanPointPointerMoveService {
  constructor(
    private planPointVariablesService: PlanPointVariablesService,
    private planService: PlanService,
    private planPinsService: PlanPinsService,
  ) {}

  pointermoveListener(event: MapBrowserEvent<MouseEvent>): void {
    const mode = this.planPointVariablesService.getMode();
    const activePlan = GET_ACTIVE_PLAN();
    const modifyingPolygon = this.planPointVariablesService.getModifyingPolygon();
    if (
      !activePlan.drawingPolygon &&
      !modifyingPolygon &&
      (mode === EPlanPointMode.DRAW || mode === EPlanPointMode.POLYGON)
    ) {
      const plan = this.planPointVariablesService.getPlan();

      const hit = plan.instance.point.forEachFeatureAtPixel(
        event.pixel,
        (feature: Feature) => {
          if (feature.getGeometry().getType() === EPlanPointMode.POLYGON) {
            if (mode === EPlanPointMode.DRAW) {
              this.planService.selectionChange$.next(EPlanPointMode.POLYGON);
              this.planPinsService.resetLayer(EPlanModule.POINT, EPlanPointMode.POLYGON);
            }

            const selectInteraction = this.planPointVariablesService.getSelectInteraction();

            selectInteraction.getFeatures().remove(feature);
            selectInteraction.getFeatures().push(feature);

            return true;
          }
        },
        {
          hitTolerance: 25,
        },
      );

      if (!hit) {
        if (
          mode === EPlanPointMode.POLYGON &&
          !modifyingPolygon &&
          plan.point.polygons.length < MAX_AREAS
        ) {
          this.planService.selectionChange$.next(EPlanPointMode.DRAW);
          this.planPinsService.resetLayer(EPlanModule.POINT, EPlanPointMode.POLYGON);
        }
      }
    }
  }

  pointermoveEventOn(): void {
    this.pointermoveEventOff();
    const plan = this.planPointVariablesService.getPlan();
    const pointermoveListenerRef = this.planPointVariablesService.getPointerMoveListenerRef();

    plan.instance.point.on('pointermove', pointermoveListenerRef);
  }

  pointermoveEventOff(): void {
    const plan = this.planPointVariablesService.getPlan();
    const pointermoveListenerRef = this.planPointVariablesService.getPointerMoveListenerRef();

    plan.instance.point.un('pointermove', pointermoveListenerRef);
  }
}
