import { TAccount } from '../../../account/account.model';
import { TDashletSiteRange } from '../../../preferences/preferences.model';
import { TWorkspacesById } from '../../../workspace/workspace.model';
import { EDashletType } from '../../dashlets-enums';

export function getReportSelectedRange(
  dashletName: EDashletType,
  selectedRange: TDashletSiteRange,
  defaultWorkspaces: string[],
  workspaces: TWorkspacesById,
  accounts: TAccount[],
): TDashletSiteRange {
  const workspaceIds = selectedRange.workspaceIds.filter(
    (workspaceId) => !!workspaceId && workspaces[workspaceId],
  ); // Remove nulls from preferences

  if (!defaultWorkspaces) {
    return {
      accountId: selectedRange.accountId,
      workspaceIds: workspaceIds,
    };
  }

  if (dashletName === EDashletType.CURRENT_STACKED_STATUS) {
    return getStackedStatusSelectedRange(workspaceIds);
  }

  if (dashletName === EDashletType.COST_COMPARISON) {
    return {
      accountId: null,
      workspaceIds: [],
      workspacesCosts: selectedRange.workspacesCosts,
    };
  }

  if (selectedRange.accountId) {
    const account = accounts.find((account) => account.accountId === selectedRange.accountId);

    return {
      accountId: null,
      workspaceIds: defaultWorkspaces.filter((workspaceId) => {
        return account.workspaces.includes(workspaceId);
      }),
    };
  }

  if (workspaceIds?.length) {
    return {
      accountId: null,
      workspaceIds: workspaceIds,
    };
  }

  return {
    accountId: null,
    workspaceIds: defaultWorkspaces,
  };
}

function getStackedStatusSelectedRange(workspaceIds: string[]): TDashletSiteRange {
  return {
    accountId: workspaceIds.length ? null : '', // Empty string makes it so API returns nothing
    workspaceIds,
  };
}
