import { TTimeframeElement } from './timeline-timeframes';
import { splitYearIntoMonths } from './split-year-into-months';

export function getWeekTopElements(from: Date, to: Date): TTimeframeElement[] {
  const elements = [];
  const start = new Date(from);
  const end = new Date(to);

  const months = splitYearIntoMonths(start, end);

  months.forEach((month) => {
    elements.push(month);
  });

  return elements;
}
