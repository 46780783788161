import { Observable } from 'rxjs';
import { TExportDashlet } from '../../dashboard-export-modal/dashboard-export-dashlet.model';
import { TDashletEventsRequestData } from '../../dashlet/dashlet-events/dashlet-events.consts';
import { generateSimpleDashlet } from '../simple-dashlet-generate';
import { generateImage, TDashboardImageElementData } from './dashboard-image-element';

export function generateExportDashletSimple(
  dashletData: TDashletEventsRequestData,
  dashlet: TExportDashlet,
): Observable<TDashboardImageElementData> {
  const simpleDashletWidth = 680;
  const simpleDashletHeight = 680;
  const scale = 1; // change that if needed to make sure that pdf isn't pixelated

  const element = generateSimpleDashlet({
    type: dashletData.type,
    period: dashletData.period,
    accountName: dashlet.accountName,
    workspaceName: dashlet.workspaceName,
    value: dashletData.data,
    width: simpleDashletWidth,
    height: simpleDashletHeight,
    scale: scale,
  });

  return generateImage({
    element,
    width: simpleDashletWidth,
    height: simpleDashletHeight,
    wrapperWidth: simpleDashletWidth,
    wrapperHeight: simpleDashletHeight,
    chartType: dashletData.type,
    borderRadius: 0,
    scale,
  });
}
