<section class="usersDropdown" [ngStyle]="{ width: dropdownWidth }">
  <pp-search-box
    ppPlaceholder="Filter users"
    (ppAction)="setSearchBox($event)"
    class="usersSearchBox dropdown__searchBar"
    [ppFocusOnInit]="true"
    ppSize="wide"
    *ngIf="!hideExtraOptions"
  ></pp-search-box>

  <ul
    #list
    (ppOnRendered)="updateScrolledToBottom()"
    class="dropdownWrapper users__list"
    [ngStyle]="{ 'max-height.px': maxHeight }"
    (scroll)="handleScroll($event)"
  >
    <ng-container *ngFor="let userId of selectableUsers">
      <li
        *ngIf="showToggle || unselectedUserIds.includes(userId)"
        (click)="selectUser(userId); $event.stopPropagation()"
        class="users__item"
      >
        <pp-toggle
          [ppChecked]="!unselectedUserIds.includes(userId)"
          ppStyle="blue"
          *ngIf="showToggle"
        ></pp-toggle>

        <img
          ppTooltip
          ppImage
          class="user__avatar"
          *ngIf="users[userId].avatarPublicUrl"
          [src]="users[userId].avatarPublicUrl"
          [ppMobileTooltip]="true"
          [ppTitle]="users[userId].userName"
        />

        <img
          ppTooltip
          class="user__avatar"
          *ngIf="!users[userId].avatarPublicUrl"
          [src]="EIconPath.PLACEHOLDER_USER"
          [ppMobileTooltip]="true"
          [ppTitle]="users[userId].userName"
        />

        <ul class="users__select-details">
          <li class="users__select-username">
            {{ users[userId].userName }}
          </li>

          <li class="users__select-user-email">
            {{ users[userId].email }}
          </li>
        </ul>
      </li>
    </ng-container>
  </ul>

  <div class="warning__message" *ngIf="!selectableUsers || selectableUsers.length === 0">
    {{ 'no_additional_to_display' | ppTranslate: { type: type } }}
  </div>

  <footer class="usersDropdown__footer" *ngIf="!hideExtraOptions">
    <div *ngIf="!scrolledToBottom" class="optionsDropdown__shadow">&nbsp;</div>

    <div class="optionsDropdown__shadow-footer-buttons">
      <pp-button ppStyle="secondary" (ppAction)="clearUsers()">
        {{ 'clear_all' | ppTranslate }}
      </pp-button>

      <pp-button ppStyle="primary" (ppAction)="hideDropdown()">
        {{ 'done' | ppTranslate }}
      </pp-button>
    </div>
  </footer>
</section>
