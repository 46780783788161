import { cloneDeep } from 'lodash';
import { TShare } from '../../share.model';

export function correctTimelineVisibility(share: TShare): boolean {
  let timelinePermission = cloneDeep(share.advancedAccessLevels.timeline.permission.read);

  timelinePermission = !timelinePermission;
  const access = timelinePermission.toString() ? 'on' : 'off';

  return timelinePermission;
}
