<pp-bulk-changes-bar
  [ppSelectedNumber]="selectedSites.length"
  *ngIf="selectedSites.length > 0"
  [ppSelectedTextKey]="'sites_selected'"
  (ppClose)="deselectAll()"
>
  <pp-bulk-changes-button
    *ngIf="allUnarchived"
    [ppIconPath]="EIconPath.ICON_MISC_ARCHIVE_24"
    [ppText]="'archive' | ppTranslate"
    (ppAction)="showArchiveSitesModal()"
  ></pp-bulk-changes-button>

  <pp-bulk-changes-button
    *ngIf="allArchived"
    [ppIconPath]="EIconPath.ICON_MISC_ARCHIVE_24"
    [ppText]="'unarchive' | ppTranslate"
    (ppAction)="showUnarchiveSitesModal()"
  ></pp-bulk-changes-button>

  <pp-bulk-changes-button
    [ppIconPath]="EIconPath.ICON_MISC_ARCHIVE_24"
    [ppText]="'archive' | ppTranslate"
    [ppDisabled]="true"
    *ngIf="!allArchived && !allUnarchived"
  ></pp-bulk-changes-button>
</pp-bulk-changes-bar>
