<div #sidePanelComponent *ngIf="user">
  <div
    class="menuBackdrop"
    [ngClass]="{ 'menuBackdrop--visible': sidePanel.expanded }"
    (click)="collapsePanel()"
  ></div>
  <div
    class="menuBackdrop"
    [ngClass]="{ 'menuBackdrop--visible-desktop': sidePanel.notificationsExpanded }"
    (click)="toggleNotifications()"
  ></div>

  <div
    class="sidePanel__close-notifications"
    *ngIf="sidePanel.notificationsExpanded"
    (click)="toggleNotifications()"
    [class.hiddenMobile]="isMobile"
  >
    <img class="sidePanel__close-notifications-image" [src]="EIconPath.INTERACTION_CLOSE" />
  </div>

  <img
    *ngIf="!sidePanel.expanded && isMobile"
    [class.sidePanel__mobile-button--down]="actionBar"
    class="sidePanel__mobile-button"
    [src]="EIconPath.SIDE_PANEL_MENU"
    (click)="togglePanel()"
  />

  <div
    class="filterPanel sidebar__animation"
    [class.sidePanelBody--expanded]="
      sidePanel.expanded && (!isMobile || sidePanel.rightPanelExpanded)
    "
    [class.filterPanel--hidden]="sidePanel.hidden"
    [class.filterPanel--down]="actionBar"
    [class.sidePanelBody--collapsedMobile]="
      sidePanel.expanded && isMobile && !sidePanel.rightPanelExpanded
    "
  >
    <div
      class="sidePanel__left"
      [ngClass]="isMobile && !sidePanel.rightPanelExpanded ? 'sidePanel__left--mobile' : ''"
    >
      <pp-side-panel-back *ngIf="showBackArrow" [ppDisabled]="disabled"></pp-side-panel-back>

      <div [ngClass]="isMobile && isLandscape ? 'sidePanel__left--listWrapper' : ''">
        <pp-side-panel-logo *ngIf="!showBackArrow" [ppMobile]="isMobile"></pp-side-panel-logo>

        <ul class="sidePanelUl sidePanelUl--top" [class.sidePanelUl--landscape]="isLandscape">
          <pp-side-panel-dashboard
            [ppRouterPath]="routerPath"
            [ppActiveWorkspaceId]="user.activeWorkspaceId"
            [ppIsMobile]="isMobile"
            [ppIsLandscape]="isLandscape"
            [ppDisabled]="disabled"
          ></pp-side-panel-dashboard>

          <pp-side-panel-overview
            [ppTimelineEnabled]="timelineEnabled"
            [ppRemindersEnabled]="remindersEnabled"
            [ppIsMobile]="isMobile"
            [ppRouterPath]="routerPath"
            [ppIsLandscape]="isLandscape"
            [ppDisabled]="disabled"
          ></pp-side-panel-overview>

          <pp-side-panel-timeline
            [ppRemindersEnabled]="remindersEnabled"
            [ppRouterPath]="routerPath"
            [ppIsMobile]="isMobile"
            [ppIsLandscape]="isLandscape"
            *ngIf="timelineEnabled"
            [ppDisabled]="disabled"
          ></pp-side-panel-timeline>
        </ul>

        <hr class="sidePanel__hr" *ngIf="!isLandscape" />

        <ul class="sidePanelUl" [class.sidePanelUl--landscape]="isLandscape">
          <pp-side-panel-sites
            [ppIsLandscape]="isLandscape"
            [ppIsMobile]="isMobile"
            [ppDisabled]="disabled"
          ></pp-side-panel-sites>

          <pp-side-panel-fleet-management
            *ngIf="fleetManagementEnabled"
            [ppRemindersEnabled]="remindersEnabled"
            [ppRouterPath]="routerPath"
            [ppIsMobile]="isMobile"
            [ppIsLandscape]="isLandscape"
            [ppDisabled]="disabled"
          ></pp-side-panel-fleet-management>

          <pp-side-panel-notifications
            [ppIsMobile]="isMobile"
            [ppUser]="user"
            *ngIf="isMobile && isLandscape"
            [ppDisabled]="disabled"
          ></pp-side-panel-notifications>

          <pp-side-panel-user
            [ppIsMobile]="isMobile"
            [ppUser]="user"
            *ngIf="isMobile && isLandscape"
            [ppIsLandscape]="isLandscape"
            [ppDisabled]="disabled"
          ></pp-side-panel-user>
        </ul>
      </div>

      <section
        class="sidePanelControls"
        [ngClass]="isMobile && !sidePanel.rightPanelExpanded ? 'sidePanelControls--mobile' : ''"
        [class.sidePanelControls--down]="actionBar"
        *ngIf="!(isMobile && isLandscape)"
      >
        <ul class="sidePanelControls__list">
          <pp-side-panel-reminders
            [ppRouterPath]="routerPath"
            [ppIsMobile]="isMobile"
            [ppActiveWorkspaceId]="user.activeWorkspaceId"
            [ppIsLandscape]="isLandscape"
            *ngIf="remindersEnabled"
            [ppDisabled]="disabled"
          ></pp-side-panel-reminders>

          <pp-side-panel-notifications
            [ppIsMobile]="isMobile"
            [ppUser]="user"
            [ppDisabled]="disabled"
          ></pp-side-panel-notifications>

          <pp-side-panel-user
            [ppIsMobile]="isMobile"
            [ppUser]="user"
            [ppIsLandscape]="isLandscape"
            [ppDisabled]="disabled"
          ></pp-side-panel-user>
        </ul>
      </section>
    </div>

    <div #accountsPanel></div>
  </div>

  <pp-notifications
    class="notificationsPanel sidebar__animation"
    [ngClass]="sidePanel.expanded ? 'notificationsPanel--right' : ''"
    [class.notificationsPanel--collapsed]="!sidePanel.notificationsExpanded"
    [ppExpanded]="sidePanel.notificationsExpanded"
  ></pp-notifications>
</div>
