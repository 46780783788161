<table
  #tablePlaceholder
  class="siteTable__placeholder"
  *ngIf="!site.sitePlanFullWidth || (site.sitePlanFullWidth && !site.sitePlanVisible)"
>
  <div class="siteTable__placeholder-header-item">&nbsp;</div>

  <tr class="siteTable__placeholder--row" *ngFor="let placeholder of placeholders">
    <div class="siteTable__placeholder--cell">
      <div class="siteTable__placeholder-item">&nbsp;</div>
    </div>
  </tr>
</table>

<div class="sitePlan__placeholder">
  <div
    #sitePlanPlaceholder
    [ngStyle]="{ 'width.%': ppPlanWidth }"
    class="sitePlan__placeholder-content"
    *ngIf="site.sitePlanVisible"
  >
    <img [src]="EIconPath.PLACEHOLDER_MAP" alt="" />
  </div>
</div>
