<section class="settingsSection">
  <header
    class="settingsSection__header"
    [ngClass]="{ 'settingsSection__header--small-padding': ppOptions.headerPaddingSmall }"
    *ngIf="ppOptions && !ppOptions.skipHeader"
  >
    <h5 class="settingsSection__header-title">
      <ng-content select="[title]"></ng-content>
    </h5>

    <div class="settingsSection__header-right">
      <ng-content select="[titleBar-right]"></ng-content>
    </div>
  </header>

  <div class="settingsSection__body" [ngClass]="gridClass">
    <ng-content select="[body]"></ng-content>
  </div>
</section>
