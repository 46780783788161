import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TRichTextOptions } from 'src/app/project/components/input/rich-text/rich-text.consts';
import { TRichTextUpdate } from 'src/app/project/components/input/rich-text/rich-text.model';
import { TNewComment } from 'src/app/project/data-providers/api-providers/comment-api-provider/comment-requests.model';
import { TUser } from 'src/app/project/modules/user/user.model';
import { UserService } from 'src/app/project/modules/user/user.service';
import { EAccessField } from 'src/app/project/shared/enums/access-field.enum';
import { PointsUsersService } from '../../../points/points-users.service';
import { TPushNotification } from '../../notification.model';

@Component({
  selector: 'pp-notification-response',
  templateUrl: './notification-response.component.html',
  styleUrls: ['../notification.component.scss'],
})
export class NotificationResponseComponent implements OnInit, OnChanges {
  @Input() ppNewComment: TNewComment;
  @Input() ppRespondingToComment: boolean;
  @Input() ppCommentSent: boolean;
  @Input() ppNotificationId: string;
  @Input() ppNotification: TPushNotification;
  @Output() ppRespondingToCommentChange = new EventEmitter<boolean>();
  @Output() ppComment = new EventEmitter<TRichTextUpdate>();

  activeUser: TUser;
  userListIds: string[];
  richTextOptions: TRichTextOptions;

  constructor(private userService: UserService, private pointsUsersService: PointsUsersService) {}

  ngOnInit(): void {
    this.activeUser = this.userService.getUser();
  }

  ngOnChanges(): void {
    this.setTagSelectOptions();
  }

  private setTagSelectOptions(): void {
    this.richTextOptions = {
      defaultBorder: true,
      clearable: false,
      notification: true,
      processing: false,
      success: this.ppCommentSent,
      showButtons: true,
    };
  }

  comment(event: TRichTextUpdate): void {
    this.ppComment.emit(event);
  }

  closeRespondComment(event?: Event): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.ppRespondingToCommentChange.emit(false);
  }

  updateUserList(): void {
    this.pointsUsersService
      .fetchUsersWithAccessToPoint(this.ppNotification.refObject.id, EAccessField.COMMENT)
      .subscribe((userIds) => {
        this.userListIds = userIds;
      });
  }
}
