import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TPasswordResetResponse } from '@project/view-models';
import { blurInput } from 'src/app/core/helpers/blur-input';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TPasswordInputVisibilityType } from '../../auth.types';

@Component({
  selector: 'pp-password-update-new-password-page',
  templateUrl: './password-update-new-password-page.component.html',
  styleUrls: ['./password-update-new-password-page.component.scss'],
})
export class PasswordUpdateNewPasswordPageComponent {
  @Input() ppPasswordResetUser: TPasswordResetResponse;
  @Output() ppPasswordResetUserChange = new EventEmitter<TPasswordResetResponse>();
  @Input() ppProcessing = false;
  @Output() ppUpdate = new EventEmitter<void>();

  areTermsAccepted = false;
  isNewPasswordInputDirty = false;
  isPasswordCorrect = false;
  repeatedPassword = '';
  passwordInputType: TPasswordInputVisibilityType = 'password';
  EIconPath = EIconPath;

  toggleAcceptedTerms(): void {
    this.areTermsAccepted = !this.areTermsAccepted;
  }

  checkPassword(): void {
    this.isNewPasswordInputDirty = true;
  }

  validatePassword(valid: boolean): void {
    this.isPasswordCorrect = valid;
  }

  blurInput(event: Event): void {
    blurInput(event.target);
  }

  checkConfirmButtonDisabled(): boolean {
    return (
      !this.ppPasswordResetUser.name ||
      !this.ppPasswordResetUser.passwordHash ||
      !this.areTermsAccepted ||
      !this.isPasswordCorrect ||
      this.ppPasswordResetUser.passwordHash !== this.repeatedPassword
    );
  }

  updatePassword(): void {
    if (!this.checkConfirmButtonDisabled() && !this.ppProcessing) {
      this.ppUpdate.emit();
    }
  }

  updateResetUser(): void {
    this.ppPasswordResetUserChange.emit(this.ppPasswordResetUser);
  }
}
