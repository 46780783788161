// https://www.epochconverter.com/weeknumbers

Date.prototype.getWeek = function (): number {
  const target = new Date(this.valueOf());
  const dayNr = (this.getDay() + 6) % 7;

  target.setDate(target.getDate() - dayNr + 3);

  const firstThursday = target.valueOf();

  target.setMonth(0, 1);

  if (target.getDay() !== 4) {
    target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7));
  }

  return 1 + Math.ceil((firstThursday - target.getTime()) / 604800000);
};

Date.prototype.addMonths = function (monthsNumber: number): void {
  (this as Date).setMonth((this as Date).getMonth() + monthsNumber);
};
