import { processColumns } from 'src/app/project/modules/site/site-table/columns/columns';
import { TWorkspacePreferences } from '../preferences.model';
import { TFilters } from '../../filters/site-filter.model';

export function processWorkspacePreferences(
  workspacePreferencesResponse: TWorkspacePreferences,
  exportPreferencesResponse: TWorkspacePreferences,
  filters: TFilters,
): TWorkspacePreferences {
  const workspace = workspacePreferencesResponse || {};
  const exports = exportPreferencesResponse || {};

  if (!exports.exports) {
    exports.exports = {};
  }

  workspace.exports = exports.exports;

  let workspacePreferences = {
    ...workspace,
  };

  if (!workspace.columns) {
    workspace.columns = [];
  }

  if (!workspacePreferences.columns) {
    workspacePreferences.columns = [];
  }

  if (filters) {
    workspacePreferences.filters = filters;
  } else if (!workspacePreferences.filters) {
    workspacePreferences.filters = {};
  }

  const priority = workspace.columns.find((column) => column.name === 'P');
  const status = workspace.columns.find((column) => column.name === 'S');

  if (priority) {
    priority.name = 'Priority';
  }

  if (status) {
    status.name = 'Status';
  }

  workspace.columns.forEach((column) => {
    if (column.width < 60) {
      column.width = 60;
    }
  });

  workspacePreferences.columns = processColumns(workspacePreferences.columns);

  if (!workspacePreferences) {
    workspacePreferences = {};
  }

  return workspacePreferences;
}
