import { Component, OnInit } from '@angular/core';

import {
  ETimelineGroup,
  TPreferences,
} from 'src/app/project/modules/preferences/preferences.model';
import { TUser } from 'src/app/project/modules/user/user.model';
import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { PreferencesService } from 'src/app/project/modules/preferences/preferences-service/preferences.service';
import { UserService } from 'src/app/project/modules/user/user.service';
import { WorkspaceService } from '../../../workspace/workspace.service';

import { finalize, tap } from 'rxjs/operators';
import { EWorkspaces } from 'src/app/project/modules/workspace/workspaces.enum';
import { TimelineGroupService } from '../timeline-group.service';

@Component({
  selector: 'pp-timeline-grouping-dropdown',
  templateUrl: './timeline-grouping-dropdown.component.html',
  styleUrls: ['./timeline-grouping-dropdown.component.scss'],
})
export class TimelineGroupingDropdownComponent implements OnInit {
  public user: TUser;
  workspace: TWorkspace;
  workspaces: TWorkspacesById;
  siteId = '';
  scrollPosition = 0;
  scrolledToBottom = false;
  savingPreferences = false;
  preferences: TPreferences;

  options = [
    { name: 'Nothing', value: null },
    { name: 'Account', value: ETimelineGroup.ACCOUNT },
    { name: 'Site', value: ETimelineGroup.SITE },
  ];

  constructor(
    private workspaceService: WorkspaceService,
    private userService: UserService,
    private preferencesService: PreferencesService,
    private timelineGroupService: TimelineGroupService,
  ) {}

  ngOnInit() {
    this.workspace = this.workspaceService.getActiveWorkspace();
    this.user = this.userService.getUser();
    this.preferences = this.preferencesService.getPreferences();
    this.workspaces = this.workspaceService.getWorkspaces();
    this.siteId = EWorkspaces.TIMELINE;
  }

  scrollHandler(event: Event): void {
    if (event.target instanceof HTMLElement) {
      this.scrollPosition = event.target.scrollTop;
      this.scrolledToBottom =
        event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
    }
  }

  setGrouping(option: { name: string; value: ETimelineGroup }): void {
    if (!this.savingPreferences) {
      this.savingPreferences = true;

      this.timelineGroupService
        .setGrouping(option.value)
        .pipe(
          tap((response) => {
            this.preferences = response;
          }),
          finalize(() => {
            this.savingPreferences = false;
          }),
        )
        .subscribe();
    }
  }
}
