import { createElement } from 'src/app/core/helpers/dom';
import { GET_COLLAPSED_GROUPS } from '../../../site-table/table.ui.store';
import { GET_TIMELINE } from '../../timeline.ui.store';
import Tooltip from 'src/app/project/features/tooltip/Tooltip';
import { createExpandElement } from './create-expand-element';
import { createCollapseElement } from './create-collapse-element';

export function createHeaderNameElement(_index: number): HTMLElement {
  const timeline = GET_TIMELINE();
  const item = timeline.items[_index];
  const groupsCollapsed = GET_COLLAPSED_GROUPS();

  let arrowElement = null;

  if (groupsCollapsed.has(item.id)) {
    arrowElement = createExpandElement(item);
  } else {
    arrowElement = createCollapseElement(item);
  }

  const nameElement = createElement('div', {
    attrs: {
      class: 'timeline__headerName--text',
    },
    children: [item.context],
  });

  const nameTooltip = new Tooltip({
    title: item.context.toString(),
    mobileTooltip: false,
    childTooltip: false,
    element: nameElement,
    trimTooltip: true,
    trimElement: nameElement,
  });

  nameTooltip.createTooltip();

  return createElement('div', {
    attrs: {
      class: 'timeline__headerName',
    },
    children: [arrowElement, nameElement],
  });
}
