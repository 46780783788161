import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { TooltipsModule } from 'src/app/project/features/tooltip/tooltips.module';
import { HideDropdownComponent } from './hide-dropdown.component';

@NgModule({
  declarations: [HideDropdownComponent],
  imports: [CommonModule, TooltipsModule, PipesModule],
  exports: [HideDropdownComponent],
})
export class HideDropdownModule {}
