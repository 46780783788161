import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { PointsHelperService } from 'src/app/project/modules/points/points-helper.service';
import { EPriority } from 'src/app/project/modules/points/priorities';

@Component({
  selector: 'pp-priority-button',
  templateUrl: './priority-button.component.html',
  styleUrls: ['./priority-button.component.scss'],
})
export class PriorityButtonComponent {
  @Input() ppPriority: EPriority;
  @Input() ppDisabled = false;
  @Input() ppProcessing = false;

  @Output() ppAction = new EventEmitter();

  priorityName: string;

  constructor(private pointsHelperService: PointsHelperService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ppPriority) {
      this.priorityName = this.humanizePriorityName(changes.ppPriority.currentValue);
    }
  }

  action(): void {
    this.ppAction.emit();
  }

  humanizePriorityName(priority: EPriority): string {
    return this.pointsHelperService.humanizePriorityName(priority);
  }
}
