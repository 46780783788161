<div class="fleetExportAssetDashlet" (click)="toggleDashlet()">
  <pp-checkbox
    ppType="solid"
    [ppChecked]="ppExportDashlet.selected"
    [ppDisabled]="ppDisabled"
  ></pp-checkbox>

  <span class="fleetExportAssetDashlet__name">
    {{ DASHLET_NAMES[ppExportDashlet.name] | ppTranslate }}
  </span>

  <span class="fleetExportAssetDashlet__site"> {{ siteName }}</span>

  <pp-info-icon
    ppTooltip
    [ppSanitize]="false"
    [ppTitle]="siteTooltip"
    *ngIf="siteName === ('multiple_sites' | ppTranslate)"
  ></pp-info-icon>
</div>
