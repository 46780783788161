import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { PasswordUpdateService } from '../password-update/password-update.service';

@Component({
  selector: 'pp-password-validation',
  templateUrl: './password-validation.component.html',
  styleUrls: ['./password-validation.component.scss'],
})
export class PasswordValidationComponent implements OnChanges {
  @Input() ppPassword = '';
  @Output() ppValidate = new EventEmitter<boolean>();

  correctLength = false;
  correctUpperCase = false;
  correctLowerCase = false;
  correctNumber = false;
  collapsed = true;
  EIconPath = EIconPath;

  constructor(private passwordUpdateService: PasswordUpdateService) {}

  ngOnChanges(): void {
    this.checkPassword();
  }

  checkPassword(): void {
    if (typeof this.ppPassword !== 'string') {
      this.ppPassword = '';
    }

    this.correctLength = this.passwordUpdateService.checkPasswordLength(this.ppPassword);
    this.correctUpperCase = this.passwordUpdateService.checkPasswordUpperCase(this.ppPassword);
    this.correctLowerCase = this.passwordUpdateService.checkPasswordLowerCase(this.ppPassword);
    this.correctNumber = this.passwordUpdateService.checkPasswordNumber(this.ppPassword);

    const isValid =
      this.correctLength && this.correctUpperCase && this.correctLowerCase && this.correctNumber;

    this.ppValidate.emit(isValid);
  }
}
