import { Injectable } from '@angular/core';
import { SiteOptionsGroupButtonComponent } from './site-options-group-button/site-options-group-button.component';
import { SiteOptionsSortButtonComponent } from './site-options-sort-button/site-options-sort-button.component';

@Injectable({
  providedIn: 'root',
})
export class SiteOptionsService {
  private groupButton: SiteOptionsGroupButtonComponent;
  private sortButton: SiteOptionsSortButtonComponent;

  constructor() {}

  setGroupButton(button: SiteOptionsGroupButtonComponent): void {
    this.groupButton = button;
  }

  getGroupButton(): SiteOptionsGroupButtonComponent {
    return this.groupButton;
  }

  setSortButton(button: SiteOptionsSortButtonComponent): void {
    this.sortButton = button;
  }

  getSortButton(): SiteOptionsSortButtonComponent {
    return this.sortButton;
  }
}
