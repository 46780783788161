import { Injectable } from '@angular/core';
import { AccountApiProviderService } from '@core/api';
import { SortingService } from '@core/services';
import { select, Store } from '@ngrx/store';
import { TAccountSimpleResponse } from '@project/view-models';
import { cloneDeep } from 'lodash';
import { from, Observable, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { EStore } from '../../shared/enums/store.enum';
import { getAccountsChanged } from '../account/account';
import { getDefaultAccountFeatures } from '../account/account-utils/default-account-features';
import { SetAccounts } from '../account/account.actions';
import { TAccount } from '../account/account.model';
import { SET_ACCOUNTS } from '../account/account.store';
import { ResponseErrorService } from '../errors/response-error.service';
import { generateShares } from '../share/share.utils';
import { SharesService } from '../share/shares.service';
import { SET_SHARES } from '../share/shares.store';
import { TUser } from '../user/user.model';
import {
  addSharesToWorkspace,
  TAddShareToWorkspaceResponse,
} from './workspace-utils/add-shares-to-workspace';
import { SetWorkspaces } from './workspace.actions';
import { TIntegrations, TWorkspacesById } from './workspace.model';

@Injectable({
  providedIn: 'root',
})
export class BasicWorkspaceService {
  private readonly destroy$ = new Subject<void>();

  private accounts$: Observable<TAccount[]>;
  private accounts: TAccount[];
  private workspaces$: Observable<TWorkspacesById>;
  private workspaces: TWorkspacesById;
  private user$: Observable<TUser>;
  private isSuperUser: boolean;

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
      accounts: TAccount[];
      user: TUser;
    }>,
    private sharesService: SharesService,
    private accountApiProviderService: AccountApiProviderService,
    private responseErrorService: ResponseErrorService,
    private sortingService: SortingService,
  ) {
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.user$ = this.store.pipe(select(EStore.USER));

    this.accounts$.subscribe((accounts) => {
      this.accounts = accounts;
    });

    this.workspaces$.subscribe((workspaces) => {
      this.workspaces = workspaces;
    });

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.isSuperUser = user.isSuperUser;
    });
  }

  generateBasicWorkspaces(workspaceUsers: { [workspaceId: string]: string[] }): Observable<void> {
    return from(this.sharesService.fetchShares(true)).pipe(
      switchMap((response) => {
        const shares = generateShares(response, this.workspaces);
        const workspaces: TAddShareToWorkspaceResponse = addSharesToWorkspace(shares);

        SET_SHARES(shares);

        return this.fetchBasicWorkspaces(!this.isSuperUser).pipe(
          map((accounts: TAccountSimpleResponse[]) => {
            const workspaceList = this.generateWorkspaceList(accounts, workspaces);

            const generatedAccounts: Partial<TAccount>[] = this.generateAccount(
              accounts,
              this.accounts,
            );

            const accountList: TAccount[] = generatedAccounts.map((account) => ({
              accountType: account.accountType,
              accountId: account.accountId,
              accountName: account.accountName,
              accountFeatures: account.accountFeatures,
              accountOwnerId: account.accountOwnerId,
              workspaces: account.workspaces.sort((workspaceIdA, workspaceIdB) => {
                const workspaceA = workspaceList[workspaceIdA];
                const workspaceB = workspaceList[workspaceIdB];

                return this.sortingService.naturalSort(workspaceA?.siteName, workspaceB?.siteName);
              }),
              industryType: account.industryType,
              subscriptionType: account.subscriptionType,
              accountManager: account.accountManager,
              accountFolders: account.accountFolders,
            }));

            this.store.dispatch(new SetAccounts(accountList));

            SET_ACCOUNTS(accountList);

            Object.keys(workspaceList).forEach((workspaceId) => {
              if (workspaceUsers[workspaceId]) {
                workspaceList[workspaceId].users = workspaceUsers[workspaceId];
              }

              if (!workspaceList[workspaceId].users) {
                workspaceList[workspaceId].users = [];
              }
            });

            this.store.dispatch(new SetWorkspaces(workspaceList));
          }),
        );
      }),
    );
  }

  private generateAccount(
    accounts: TAccountSimpleResponse[],
    existingAccounts: TAccount[],
  ): Partial<TAccount>[] {
    const generatedAccounts: Partial<TAccount>[] = [];

    accounts.forEach((account) => {
      const generatedAccount: Partial<TAccount> = {
        accountFeatures: account.accountFeatures,
        accountFolders: account.accountFolders,
        accountType: account.accountType,
        accountId: account.id,
        industryType: account.industryType,
        accountName: account.name,
        accountOwnerId: account.ownerId,
        workspaces: [],
      };

      if (existingAccounts) {
        const matchingAccount = this.accounts.find(
          (searchedMatchingAccount) => searchedMatchingAccount.accountId === account.id,
        );

        if (matchingAccount?.accountOwnerId) {
          generatedAccount.accountOwnerId = matchingAccount.accountOwnerId;
        }

        if (matchingAccount?.accountFeatures) {
          generatedAccount.accountFeatures = matchingAccount.accountFeatures;
        } else if (account.accountFeatures) {
          generatedAccount.accountFeatures = account.accountFeatures;
        } else {
          generatedAccount.accountFeatures = getDefaultAccountFeatures();
        }
      }

      generatedAccount.workspaces = account.workspaces.map((workspace) => workspace.workspaceId);
      generatedAccounts.push(generatedAccount as TAccount);
    });
    return generatedAccounts;
  }

  private generateWorkspaceList(
    accounts: TAccountSimpleResponse[],
    workspaces: TAddShareToWorkspaceResponse,
  ): TWorkspacesById {
    const workspaceList: TWorkspacesById = {};

    accounts.forEach((account) => {
      account.workspaces.forEach((workspace) => {
        if (this.workspaces[workspace.workspaceId]) {
          workspaceList[workspace.workspaceId] = cloneDeep(this.workspaces[workspace.workspaceId]);
        } else {
          const customFieldIds = [];

          let userIds = [];

          if (
            this.workspaces[workspace.workspaceId] &&
            this.workspaces[workspace.workspaceId].users
          ) {
            userIds = [...this.workspaces[workspace.workspaceId].users];
          }

          let tags = [];

          if (
            this.workspaces[workspace.workspaceId] &&
            this.workspaces[workspace.workspaceId].tags
          ) {
            tags = [...this.workspaces[workspace.workspaceId].tags];
          }

          let siteImageRef = null;

          if (
            this.workspaces[workspace.workspaceId] &&
            this.workspaces[workspace.workspaceId].siteImageRef
          ) {
            siteImageRef = { ...this.workspaces[workspace.workspaceId].siteImageRef };
          }

          let integrations: TIntegrations = {};

          if (
            this.workspaces[workspace.workspaceId] &&
            this.workspaces[workspace.workspaceId].integrations
          ) {
            integrations = { ...this.workspaces[workspace.workspaceId].integrations };
          }

          workspaceList[workspace.workspaceId] = {
            share: workspaces[workspace.workspaceId].share,
            accountId: account.id,
            accountName: account.name,
            customFields: customFieldIds,
            siteImageRef: siteImageRef,
            tags,
            siteId: workspace.siteId,
            siteName: workspace.name,
            workspaceId: workspace.workspaceId,
            users: userIds,
            hidden: workspaces[workspace.workspaceId].hidden,
            integrations,
          };
        }
      });
    });

    return workspaceList;
  }

  fetchBasicWorkspaces(showUnsubscribed: boolean): Observable<TAccountSimpleResponse[]> {
    return this.accountApiProviderService
      .fetchAccountsSimple(getAccountsChanged().simple, showUnsubscribed)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }
}
