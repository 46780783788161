<div [ngStyle]="{ width: dropdownWidth }">
  <pp-custom-field-list-select
    [ppField]="field"
    [ppItem]="field"
    [ppSelectedId]="selected?.id"
    (ppSelect)="select($event)"
    [ppFilter]="filterListItemsBy"
    class="listCF__wrapper"
  ></pp-custom-field-list-select>
</div>
