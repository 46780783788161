<pp-saved-views-dropdown-switch
  [(ppSelectedOption)]="selectedOption"
  [ppWorkspaceId]="workspaceId"
  [ppSavedViews]="savedViews"
></pp-saved-views-dropdown-switch>

<pp-saved-views-list
  *ngIf="savedViews"
  [ppType]="selectedOption"
  [ppInjectedDropdownService]="injectedDropdownService"
  [ppSavedViews]="savedViews"
  [ppWorkspaceId]="workspaceId"
>
</pp-saved-views-list>

<footer>
  <ng-container *ngIf="savedViews">
    <hr class="savedViews__hr" />
  </ng-container>

  <div class="savedViews__buttons-container">
    <pp-saved-views-dropdown-new-view-button
      [ppType]="selectedOption"
      [ppWorkspaceId]="workspaceId"
    ></pp-saved-views-dropdown-new-view-button>

    <pp-saved-views-done-button></pp-saved-views-done-button>
  </div>
</footer>
