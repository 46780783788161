import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { PreferencesService } from '../../preferences/preferences-service/preferences.service';
import { ETimelineGroup, TPreferences } from '../../preferences/preferences.model';
import { GET_PREFERENCES } from '../../preferences/preferences.store';
import { CALCULATE_COLUMNS } from '../site-timeline/timeframes/timeline-columns';
import { GET_TIMELINE } from '../site-timeline/timeline.ui.store';

@Injectable({
  providedIn: 'root',
})
export class TimelineGroupService {
  constructor(private preferencesService: PreferencesService) {}

  setGrouping(value: ETimelineGroup): Observable<TPreferences> {
    const preferences = GET_PREFERENCES();

    const body: TPreferences = {
      timeline: {
        timeframe: preferences.timeline?.timeframe ? preferences.timeline?.timeframe : null,
        group: value,
      },
    };

    return this.preferencesService.updatePreferences(body).pipe(
      switchMap((response) => {
        const timeline = GET_TIMELINE();

        CALCULATE_COLUMNS();
        timeline.load(false);

        return of(response);
      }),
    );
  }
}
