import { TUser } from 'src/app/project/modules/user/user.model';
import { EUserRole } from './user-roles';

export function canCreatePoint(shareOption: string, user: TUser): boolean {
  return (
    shareOption === EUserRole.SITE_ADMIN ||
    shareOption === EUserRole.NORMAL ||
    shareOption === EUserRole.OWNER ||
    shareOption === EUserRole.ACCOUNT_ADMIN ||
    user?.isSuperUser
  );
}

export function canEditPoint(shareOption: string, user: TUser): boolean {
  return (
    shareOption === EUserRole.SITE_ADMIN ||
    shareOption === EUserRole.NORMAL ||
    shareOption === EUserRole.OWNER ||
    shareOption === EUserRole.ACCOUNT_ADMIN ||
    user?.isSuperUser
  );
}

export function canEditFlag(shareOption: string, user: TUser): boolean {
  return (
    shareOption === EUserRole.SITE_ADMIN ||
    shareOption === EUserRole.OWNER ||
    shareOption === EUserRole.ACCOUNT_ADMIN ||
    user?.isSuperUser
  );
}

export function canDeletePoint(shareOption: string, user: TUser): boolean {
  return (
    shareOption === EUserRole.SITE_ADMIN ||
    shareOption === EUserRole.OWNER ||
    shareOption === EUserRole.ACCOUNT_ADMIN ||
    user?.isSuperUser
  );
}
