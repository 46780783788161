import { Inject, Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { TAttachment, TAttachments } from './attachments.model';
import { ClearAttachments } from './attachments.actions';

import {
  API_files_images_size_original,
  API_files_video_file,
} from 'src/app/project/data-providers/api-providers/files-api-provider/files-paths';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { DOCUMENT } from '@angular/common';
import { FilesApiProviderService } from '@core/api';
import { ResponseErrorService } from 'src/app/project/modules/errors/response-error.service';
import { catchError } from 'rxjs/operators';
import { EFileType } from 'src/app/project/shared/enums/file-type.enum';
import { EFileExtension } from 'src/app/project/shared/enums/file-extension.enum';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsService {
  private attachments$: Observable<TAttachments>;

  private attachments: TAttachments = {
    media: {
      dates: [],
      attachments: {},
    },
    files: {
      attachmentIds: [],
      attachments: {},
    },
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<{ attachments: TAttachments }>,
    private filesApiProviderService: FilesApiProviderService,
    private responseErrorService: ResponseErrorService,
  ) {
    this.attachments$ = this.store.pipe(select(EStore.ATTACHMENTS));

    this.attachments$.subscribe((attachments) => {
      this.attachments = attachments;
    });
  }

  findMediaAttachment(attachmentId: string): TAttachment {
    return this.attachments.media.attachments[attachmentId];
  }

  clearAttachments(): void {
    this.store.dispatch(new ClearAttachments());
  }

  getAttachments(): TAttachments {
    return this.attachments;
  }

  //

  createMediaUrl(mediaItem: TAttachment): string {
    switch (mediaItem.type) {
      case 'Image':
      case 'Image360':
        return API_files_images_size_original(mediaItem.attachmentId);
      case 'Video':
        return API_files_video_file(mediaItem.attachmentId);
      default:
        return '';
    }
  }

  createMediaFileName(id: string, fileName: string, mimeType: string): string {
    const baseFileName = id ? `${id}-${fileName}` : fileName;

    switch (mimeType) {
      case EFileType.JPEG:
      case EFileType.JPG:
        return baseFileName + EFileExtension.JPG;
      case EFileType.PNG:
        return baseFileName + EFileExtension.PNG;
      case EFileType.GIF:
        return baseFileName + EFileExtension.GIF;
      case EFileType.BMP:
        return baseFileName + EFileExtension.BMP;
      case EFileType.WEBP:
        return baseFileName + EFileExtension.WEBP;
      case EFileType.MP4:
        return baseFileName + EFileExtension.MP4;
      case EFileType.MPEG:
        return baseFileName + EFileExtension.MPEG;
      case EFileType.OGG:
        return baseFileName + EFileExtension.OGG;
      case EFileType.WEBM:
        return baseFileName + EFileExtension.WEBM;
      case EFileType.GP3:
        return baseFileName + EFileExtension.GP3;
      case EFileType.GP32:
        return baseFileName + EFileExtension.GP32;
      case EFileType.WAVE:
        return baseFileName + EFileExtension.WAVE;
      case EFileType.WAV:
        return baseFileName + EFileExtension.WAV;
      default:
        return baseFileName;
    }
  }

  fetchVideoUrl(videoId: string): Observable<string> {
    return this.filesApiProviderService
      .fetchVideoUrl(videoId)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }
}
