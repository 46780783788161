<div
  class="advancedFilterUserInput"
  [class.advancedFilterUserInput--active]="dropdown.visible && dropdown.buttonId === buttonId"
  #userPicker
  [id]="buttonId"
  (click)="toggleAddUserDropdown(buttonId)"
>
  <div class="advancedFilterUserList" #userList>
    <pp-user-chip
      *ngFor="let userId of ppSelectedUsers"
      [ppUserId]="userId"
      (ppRemoveUserCallback)="removeUserCallback($event)"
    ></pp-user-chip>

    <div class="advancedFilterUserList--placeholder" *ngIf="!ppSelectedUsers?.length">
      {{ 'select_users' | ppTranslate }}
    </div>
  </div>

  <ng-container *ngIf="!ppDisabled">
    <div class="select__caret">
      <div class="select__icon-container">
        <pp-icon
          [ppSrc]="
            dropdown.visible && dropdown.buttonId === buttonId
              ? EIconPath.ICON_MISC_UP_ARROW_18
              : EIconPath.ICON_MISC_DOWN_ARROW_18
          "
          ppColor="grey-400"
          ppClass="setSize18"
        ></pp-icon>
      </div>
    </div>
  </ng-container>
</div>
