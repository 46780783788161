import { ECustomFieldType } from '../../custom-fields/custom-field-types-enums';
import { GET_CUSTOM_FIELDS } from '../../custom-fields/custom-fields.store';
import { TPoint } from '../../points/points.model';

export function getMinMaxDate(points: TPoint[]): number[] {
  let minDate = 95614819200000; // 30 November 4999
  let maxDate = 155001600000; // 30 November 1974

  points.forEach((point) => {
    let customFieldIndex: string;

    customFieldIndex = setCustomFieldIndex(point, customFieldIndex);

    const timelineField = point.customFieldsMap[customFieldIndex];

    const [startDate, endDate] = timelineField.value.split('~');

    minDate = Math.min(minDate, startDate);
    maxDate = Math.max(maxDate, endDate);
  });

  return [minDate, maxDate];
}

function setCustomFieldIndex(point: TPoint, customFieldIndex: string) {
  for (const key in point.customFieldsMap) {
    const customField = GET_CUSTOM_FIELDS()[key];

    if (customField.type === ECustomFieldType.TIMELINE) {
      customFieldIndex = key;
      break;
    }
  }
  return customFieldIndex;
}
