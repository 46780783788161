import {
  TAllCustomFields,

} from 'src/app/project/modules/custom-fields/custom-fields.model';
import { TFilters } from '../../site-filter.model';

export function sortCustomFieldFilters(filters: TFilters, customFields: TAllCustomFields) {
  return filters.customFields.sort((a, b) => {
    if (
      customFields[a.workspaceId] &&
      customFields[b.workspaceId] &&
      customFields[a.workspaceId][a.id] &&
      customFields[b.workspaceId][b.id]
    ) {
      const result = customFields[a.workspaceId][a.id].label.localeCompare(
        customFields[b.workspaceId][b.id].label,
      );
      if (result > 0) {
        return 1;
      } else if (result < 0) {
        return -1;
      }
    } else {
      return 0;
    }
  });
}
