import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { TCustomField } from '../../custom-fields.model';
import { TBulkCustomFields, TBulkCustomFieldsExtended } from '../bulk-custom-fields.model';
import { getTimeAsUtcMidday } from '../../../../../core/helpers/date/date';

@Component({
  selector: 'pp-bulk-field-date',
  templateUrl: './bulk-field-date.component.html',
  styleUrls: ['../bulk-custom-fields-modal.component.scss'],
})
export class BulkFieldDateComponent {
  @Input() ppCustomField: TCustomField;
  @Input() ppCustomFields: TBulkCustomFields<number>;
  @Input() ppFieldId: string;
  @Input() ppCustomFieldsExtended: TBulkCustomFieldsExtended<number>;

  @Output() ppCustomFieldsExtendedChange = new EventEmitter<TBulkCustomFieldsExtended>();
  @Output() ppCustomFieldsChange = new EventEmitter<TBulkCustomFields>();

  EIconPath = EIconPath;

  constructor() {}

  updateDate(selectedDates: Date[], field: TCustomField): void {
    if (selectedDates.length) {
      this.ppCustomFields[field.id] = getTimeAsUtcMidday(selectedDates[0]);

      this.ppCustomFieldsExtended[field.id] = {
        value: this.ppCustomFields[field.id],
        label: field.label,
        type: field.type,
      };

      this.ppCustomFieldsChange.emit(this.ppCustomFields);
      this.ppCustomFieldsExtendedChange.emit(this.ppCustomFieldsExtended);
    }
  }
}
