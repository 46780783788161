export function isModalClicked(path: EventTarget[]): boolean {
  if (path) {
    for (let i = 0; i < path.length; i++) {
      if (path[i] instanceof HTMLElement) {
        const target = path[i] as HTMLElement;

        if (target.classList) {
          for (let j = 0; j < target.classList.length; j++) {
            if (
              target.classList[j] === 'dropdown' ||
              target.classList[j] === 'modal__box' ||
              target.classList[j] === 'modal__backdrop'
            ) {
              return true;
            }
          }
        }
      }
    }
  }

  return false;
}
