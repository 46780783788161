import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from './checkbox.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { IconModule } from '../../icon/icon-module';

@NgModule({
  declarations: [CheckboxComponent],
  imports: [CommonModule, PipesModule, IconModule],
  exports: [CheckboxComponent],
})
export class CheckboxModule {}
