import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagesFallbackDirective } from './images-fallback.directive';

@NgModule({
  declarations: [ImagesFallbackDirective],
  imports: [CommonModule],
  exports: [ImagesFallbackDirective],
})
export class ImagesFallbackModule {}
