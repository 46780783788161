import { ComponentType } from '@angular/cdk/overlay';
import { TAnyFunction } from '@core/helpers';
import { Placement } from '@popperjs/core';
import { Instance } from '@popperjs/core/lib/types';
import { TDropdownModifiers } from './dropdown-service/dropdown-service.consts';
import { DropdownDirective } from './dropdown.directive';

export enum EDropdownWidth {
  REM_21 = '21rem',
  REM_25 = '25rem',
  PX_120 = '120px',
  PX_180 = '180px',
  PX_427 = '427px',
  PX_330 = '330px',
  PX_150 = '150px',
}

export type TPopper = {
  instance: Instance;
  placement: Placement;
  modifiers: TDropdownModifiers[];
  positionFixed: boolean;
};

export type TDropdown<T = any> = {
  data: T;
  dropdownHost: DropdownDirective;
  component: ComponentType<unknown>;
  visible: boolean;
  // popper positioning issue
  visibilityHidden: boolean;
  buttonId: string;
  buttonElement: HTMLElement;
  dropdownElement: HTMLElement;
  popper: TPopper;
  callback: TAnyFunction;
  onEsc: EventListenerOrEventListenerObject;
  onOutsideClick: EventListenerOrEventListenerObject;
  onScroll: EventListenerOrEventListenerObject;
  tagDropdownScrollCallback: EventListenerOrEventListenerObject;
  onClose: TAnyFunction;
  changeObserver: MutationObserver;
  contentElement: HTMLElement;
  long: boolean;
  options?: TDropdownOption[];
  secondary?: boolean;
};

type TDropdownOption = {
  name: string;
  action: () => void;
};
