import { ECustomFieldType } from '../../custom-fields/custom-field-types-enums';
import { CustomFieldsService } from '../../custom-fields/custom-fields.service';
import { TColumn } from '../../site/site-table/columns/column.model';

export function checkPointTimelineField(
  column: TColumn & { timeline?: boolean | undefined },
  customFieldsService: CustomFieldsService,
): boolean {
  const customFieldId = column.customFieldIds?.[0];
  const customFields = customFieldsService.getCustomFields();
  let customFieldData;

  if (customFieldId) {
    for (const workspaceId of Object.keys(customFields)) {
      if (customFields[workspaceId][customFieldId]) {
        customFieldData = customFields[workspaceId][customFieldId];

        break;
      }
    }
  }

  if (customFieldData) {
    return customFieldData.type === ECustomFieldType.TIMELINE;
  }

  return false;
}
