import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { uuid } from '@core/helpers';
import { Subject, takeUntil } from 'rxjs';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EFleetManagementRoutesSegments } from '../../../fleet-management/fleet-management.routes';
import { FleetService } from '../../../fleet-management/fleet-service/fleet.service';
import { FleetSettingsDropdownComponent } from './fleet-settings-dropdown/fleet-settings-dropdown.component';
import { EFleetShareOption } from '../../../fleet-management/fleet-sharing/fleet-sharing.consts';

@Component({
  selector: 'pp-top-bar-fleet-management-header',
  templateUrl: './top-bar-fleet-management-header.component.html',
  styleUrls: ['./top-bar-fleet-management-header.component.scss'],
})
export class TopBarFleetManagementHeaderComponent implements OnDestroy {
  buttonId = uuid();
  private dropdown: TDropdown = this.dropdownService.getDropdown();
  private readonly destroy$ = new Subject<void>();

  EIconPath = EIconPath;
  activeFleetId: string;
  pageName: string;
  displayCogDropdown: boolean = true;

  constructor(
    private dropdownService: DropdownService,
    private fleetService: FleetService,
    private router: Router,
    private translationPipe: TranslationPipe,
  ) {
    this.fleetService.activeFleetChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe((activeFleetId) => {
        this.handleActiveFleet(activeFleetId);
      });

    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }

      this.pageName = this.generatePageName(event.urlAfterRedirects);
    });

    this.pageName = this.generatePageName(this.router.url);

    this.handleActiveFleet(this.fleetService.getActiveFleetId());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  toggleDropdown(): void {
    if (this.dropdown.visible && this.dropdown.buttonId === this.buttonId) {
      this.dropdownService.hideDropdown();
    } else {
      this.dropdownService.showDropdown(this.buttonId, FleetSettingsDropdownComponent);
    }
  }

  checkCogMenuCanBeDisplayed(fleetId: string): boolean {
    const fleet = this.fleetService.getFleet(fleetId);

    if (fleet?.assetIds.length === 0 && fleet?.fleetShareOption !== EFleetShareOption.EDIT) {
      return false;
    }

    return true;
  }

  private handleActiveFleet(activeFleetId: string) {
    this.activeFleetId = activeFleetId;

    this.pageName = this.generatePageName(this.router.url);
  }

  private generatePageName(url: string) {
    const urlSegments = url.split('/').filter((segment) => segment !== '');

    if (urlSegments[0] !== EFleetManagementRoutesSegments.FLEET_MANAGEMENT) {
      return '';
    }

    if (urlSegments[1] === EFleetManagementRoutesSegments.NEW) {
      return this.translationPipe.transform('fleet_top_bar_new_fleet');
    }

    if (urlSegments[1] !== EFleetManagementRoutesSegments.FLEET) {
      return this.translationPipe.transform('fleet_management');
    }

    const fleetId = urlSegments[2];

    if (!urlSegments[3]) {
      const fleet = this.fleetService.getFleet(fleetId);
      this.displayCogDropdown = this.checkCogMenuCanBeDisplayed(urlSegments[2]);

      return fleet?.name;
    }

    if (urlSegments[3] === EFleetManagementRoutesSegments.SETTINGS) {
      return this.translationPipe.transform('fleet_top_bar_settings');
    }

    if (urlSegments[3] === EFleetManagementRoutesSegments.ASSET) {
      if (urlSegments[4] === EFleetManagementRoutesSegments.NEW) {
        return this.translationPipe.transform('fleet_top_bar_new_asset');
      }

      return this.translationPipe.transform('fleet_top_bar_asset');
    }

    return this.translationPipe.transform('fleet_management');
  }
}
