import { EDefaultColumnWidths } from '../../site/site-table/columns/default-column-widths-enum';
import { TSingleColumnData } from '../../site/site-table/site-table-columns-data.service';
import { TSavedView } from '../models/saved-view.model';
import { fixViewCustomFields } from './fix-view-custom-fields';

export function fixView(view: TSavedView, customFields: string[]): TSavedView {
  let newView = fixViewCustomFields(view, customFields);

  newView = addRedFlagIfMissing(newView);

  return newView;
}

function addRedFlagIfMissing(newView: TSavedView) {
  const columns = newView.columns;

  if (!columns.find((column) => column.name === 'Flag')) {
    const redFlagColumnsData: TSingleColumnData = {
      name: 'Flag',
      index: 0,
      hidden: false,
      width: EDefaultColumnWidths.RED_FLAG,
    };

    columns.splice(0, 0, redFlagColumnsData);
  }

  newView.columns = columns.map((column, index) => {
    return { ...column, index: index };
  });

  if (newView.filters) {
    fixNewViewFilters(newView);
  }

  return newView;
}
function fixNewViewFilters(newView: TSavedView): void {
  newView.filters.basic.showFlaggedPoints =
    typeof newView.filters.basic.showFlaggedPoints === 'boolean'
      ? newView.filters.basic.showFlaggedPoints
      : true;
  newView.filters.basic.showUnflaggedPoints =
    typeof newView.filters.basic.showUnflaggedPoints === 'boolean'
      ? newView.filters.basic.showUnflaggedPoints
      : true;
}
