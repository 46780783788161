import { createElement } from 'src/app/core/helpers/dom';
import { GET_PREFERENCES } from 'src/app/project/modules/preferences/preferences.store';
import { transformDate } from 'src/app/project/shared/date-transformer';
import { TColumn } from '../../columns/column.model';
import { GET_TABLE } from '../../table.ui.store';
import { createMarkedKeywordElement } from '../body-cells-search';
import { createCellElement } from '../createCellElement';

export function createUpdatedElement(_index: number, _column: TColumn): HTMLElement {
  const table = GET_TABLE();
  const preferences = GET_PREFERENCES();

  const date = transformDate({
    value: table.points[_index].header.updatedEpochMillis,
    inputHourFormat: '',
    format: preferences.dateFormat,
    localTime: false,
  });

  return createCellElement({
    index: _column.index,
    width: `${_column.width}px`,
    child: createElement('div', {
      attrs: {
        class: 'customSiteTable__text',
      },
      children: [createMarkedKeywordElement(date)],
    }),
  });
}
