import { cloneDeep } from 'lodash';

import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SetSitePlanPreferencesFromDB } from '../ui/ui.actions';

import { TWorkspacesById } from '../workspace/workspace.model';

import { ActiveService } from '../../services/active/active.service';

import { checkCustomWorkspaceId } from '../workspace/workspace';
import { EStore } from '../../shared/enums/store.enum';
import { ELocalStorageItems } from '../../shared/enums/local-storage-items.enum';
import { LocalStorageService } from '../../services/local-storage.service';
import { TSitePlanWidth } from '../ui/ui.model';

@Injectable({
  providedIn: 'root',
})
export class SiteService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  // temporary workaround for Plan resize
  // TODO use default ChangeDetectionStrategy
  private readonly _siteViewChecked$ = new Subject<void>();
  siteViewChecked$ = this._siteViewChecked$.asObservable();

  private fetched = {
    dataFetched: false,
  };

  private tableFetched = {
    dataFetched: true,
  };

  private workspaces$ = new Observable<TWorkspacesById>();
  private workspaceId: string;

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
    }>,
    private activeService: ActiveService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {
    const savedSitePlanPrefs = this.localStorageService.getItem<TSitePlanWidth>(
      ELocalStorageItems.SAVED_SITE_PLAN_PREFERENCES,
    );

    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    if (savedSitePlanPrefs !== null) {
      this.store.dispatch(
        new SetSitePlanPreferencesFromDB({
          sitePlanWidth: savedSitePlanPrefs,
        }),
      );
    }

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.workspaceId = this.activeService.getActiveWorkspaceId();

      if (!this.workspaceId) {
        this.workspaceId = checkCustomWorkspaceId();
      }
    });

    this.activatedRoute.paramMap.subscribe(() => {
      if (!this.router.url.startsWith('/site')) {
        this.fetched.dataFetched = true;
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  getFetched(): { dataFetched: boolean } {
    return this.fetched;
  }

  getTableFetched(): { dataFetched: boolean } {
    return this.tableFetched;
  }

  setFetched(fetched: boolean): void {
    this.fetched.dataFetched = fetched;
  }

  setTableFetched(fetched: boolean): void {
    this.tableFetched.dataFetched = fetched;
  }

  clearSitePlanWidthPreferences(): void {
    this.localStorageService.removeItem(ELocalStorageItems.SAVED_SITE_PLAN_PREFERENCES);
  }

  saveUpdatedSitePlanWidth(
    sitePlanWidth: {
      [workspaceId: string]: number;
    },
    workspaceId: string,
    width: number,
  ): void {
    const newSitePrefs = cloneDeep(sitePlanWidth);
    newSitePrefs[workspaceId] = width;

    this.localStorageService.saveItem(
      ELocalStorageItems.SAVED_SITE_PLAN_PREFERENCES,
      JSON.stringify(newSitePrefs),
    );
  }

  emitSiteViewChecked(): void {
    this._siteViewChecked$.next();
  }
}
