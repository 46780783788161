import { createElement } from '@core/helpers';

export function createEmptyElement(): HTMLElement {
  const element = createElement('span', {
    attrs: {
      class: 'emptyElement',
    },
  });

  element.innerHTML = '&nbsp;';

  return element;
}
