import { Component, Input } from '@angular/core';

import { ReminderPointModalComponent } from '../../reminder-point-modal/reminder-point-modal.component';

import { ModalService } from 'src/app/project/components/modal/modal.service';
import { TReminderPointModalData } from '../../reminder-point-modal/reminder-point-modal.model';
import { EIconPath } from '../../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-reminders-options',
  templateUrl: './reminders-options.component.html',
  styleUrls: ['./reminders-options.component.scss'],
})
export class RemindersOptionsComponent {
  @Input() ppDataFetched = false;

  EIconPath = EIconPath;

  constructor(private modalService: ModalService) {}

  newReminder(): void {
    const modalData: TReminderPointModalData = {};
    this.modalService.setData(modalData);

    this.modalService.showModal(ReminderPointModalComponent);
  }
}
