<div class="annotationsPanel">
  <div class="annotations__btn--active-wrapper" *ngIf="addingAnnotation">
    <pp-button
      ppStyle="text"
      ppTooltip
      class="annotations__btn--active annotation__btn--cancel"
      ppTitle="Cancel"
      (ppAction)="cancel()"
    >
      {{ 'cancel' | ppTranslate }}
    </pp-button>

    <pp-button
      ppStyle="primary"
      ppTooltip
      class="annotations__btn--active annotations__save-btn"
      ppTitle="Save"
      (ppAction)="showModal()"
      [ppProcess]="processing"
    >
      {{ 'save' | ppTranslate }}
    </pp-button>
  </div>

  <div class="annotationsPanel__tools">
    <div
      ppTooltip
      class="annotationsPanel__icon--picker annotationsPanel__backgroundCircle--picker"
      [ngClass]="{
        'annotationsPanel__tools--active': currentMode === EImageAnnotationsState.ADDING_ARROW
      }"
      (click)="arrowMode()"
      ppTitle="Add Arrow"
    >
      <img
        class="annotationsPanel__icon annotationsPanel__backgroundCircle--pickerImage"
        [src]="EIconPath.GALLERY_ARROW_RIGHT"
      />
    </div>

    <div
      ppTooltip
      class="annotationsPanel__icon--picker annotationsPanel__backgroundCircle--picker"
      [ngClass]="{
        'annotationsPanel__tools--active': currentMode === EImageAnnotationsState.ADDING_TEXT
      }"
      (click)="textMode()"
      ppTitle="Add Text"
    >
      <img
        class="annotationsPanel__icon annotationsPanel__backgroundCircle--pickerImage"
        [src]="EIconPath.GALLERY_TEXT"
      />
    </div>

    <div
      ppTooltip
      class="annotationsPanel__icon--picker annotationsPanel__backgroundCircle--picker"
      [ngClass]="{
        'annotationsPanel__tools--active': currentMode === EImageAnnotationsState.FREE_DRAWING
      }"
      (click)="freeDrawingMode()"
      ppTitle="Add Free Drawing"
    >
      <img
        class="annotationsPanel__icon annotationsPanel__backgroundCircle--pickerImage"
        [src]="EIconPath.GALLERY_FREE_DRAWING"
      />
    </div>

    <div
      ppTooltip
      class="annotationsPanel__icon--picker annotationsPanel__backgroundCircle--picker"
      [ngClass]="{
        'annotationsPanel__tools--active': currentMode === EImageAnnotationsState.ADDING_ELLIPSE
      }"
      (click)="ellipseMode()"
      ppTitle="Add Ellipse"
    >
      <img
        class="annotationsPanel__icon annotationsPanel__backgroundCircle--pickerImage"
        [src]="EIconPath.GALLERY_CIRCLE"
      />
    </div>
  </div>

  <div
    class="annotationsPanel__activeColor"
    (mouseenter)="setColorsVisible(true)"
    (mouseleave)="setColorsVisible(false)"
  >
    <div class="annotationsPanel__color-picker" *ngIf="colorsVisible">
      <ng-container *ngFor="let color of colors">
        <div
          ppTooltip
          *ngIf="color.value !== pickedColorName"
          class="annotations__color-btn"
          [ppTitle]="color.value | ppTranslate"
          (click)="changeColor(color.value)"
        >
          <span [class]="'annotations__colorLabel annotationsPanel__icon ' + color.key"></span>
        </div>
      </ng-container>
    </div>

    <span
      class="annotationsPanel__backgroundCircle"
      [class.annotationsPanel__selectedColor]="!colorsVisible"
    >
      <ng-container *ngFor="let color of colors">
        <span
          ppTooltip
          [class]="'annotations__colorLabel annotationsPanel__icon ' + color.key"
          *ngIf="color.value === pickedColorName"
          [ppTitle]="color.value | ppTranslate"
        ></span>
      </ng-container>
    </span>
  </div>

  <div
    ppTooltip
    class="annotationsPanel__delete"
    [ngClass]="
      canvasFabric?.getActiveObjects().length === 0
        ? 'annotationsPanel__delete--disabled'
        : 'annotationsPanel__delete'
    "
    ppTitle="Delete Object"
    (click)="deleteObject()"
  >
    <span class="annotationsPanel__backgroundCircle annotationsPanel__backgroundCircle--hidden">
      <img class="annotationsPanel__trashIcon" [src]="EIconPath.GALLERY_DELETE" />
    </span>
  </div>
</div>

<div class="annotations" [hidden]="processing">
  <canvas #imageCanvasElement id="imageCanvasElement"></canvas>
</div>

<div class="annotations" [hidden]="!processing">
  <canvas #imageCanvasPlaceholderElement id="imageCanvasPlaceholderElement"></canvas>
</div>
