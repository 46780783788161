export const API_white_label = (accountId: string): string => `/api/v1/skins/${accountId}`;
export const API_white_label_image = (accountId: string): string =>
  `api/v1/skins/images/${accountId}`;
export const API_white_label_add_image = (
  targetId: string,
  targetType: string,
  imageId: string,
): string => `/api/v1/skins/logo/${targetId}/${targetType}/${imageId}`;

export const API_white_label_delete_image = (targetId: string, targetType: string): string =>
  `/api/v1/skins/logo/${targetId}/${targetType}`;
