import { getMonday } from 'src/app/core/helpers/get-monday';
import { getSunday } from 'src/app/core/helpers/get-sunday';
import { ETimelineTimeframe } from '../site-timeline-utils/timeframes-enums';

export function getDateRange(
  timeframe: ETimelineTimeframe.DAY | ETimelineTimeframe.WEEK,
  minDate: Date,
  maxDate: Date,
): Date[] {
  const fieldsFilled = minDate !== null;

  let from: Date;
  let to: Date;

  if (timeframe === ETimelineTimeframe.DAY) {
    if (fieldsFilled) {
      minDate.addMonths(-1);
      maxDate.addMonths(1);

      from = minDate;
      to = maxDate;

      const startDay = from.getDay();

      if (startDay !== 1) {
        if (startDay === 0) {
          from.setDate(from.getDate() - 6);
        } else {
          from.setDate(from.getDate() - startDay);
        }
      }
    } else {
      from = new Date(new Date().getFullYear(), 0, 1);
      to = new Date(new Date().getFullYear() + 1, 0, 1);
    }
  } else if (timeframe === ETimelineTimeframe.WEEK) {
    if (fieldsFilled) {
      minDate.addMonths(-3);
      maxDate.addMonths(3);

      from = getMonday(minDate);
      to = getSunday(maxDate);
    } else {
      from = new Date(new Date().getFullYear() - 1, 0, 1);
      to = new Date(new Date().getFullYear() + 1, 0, 1);
    }
  }

  return [from, to];
}
