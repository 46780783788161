const collapsedAccounts: Set<string> = new Set();
const collapsedFolders: {
  [accountId: string]: string[];
} = {};
const visibleEmptyFolders: {
  [accountId: string]: string[];
} = {};

let editedFolder: {
  accountId: string;
  _folderId: string;
} = null;
let scrollPosition: number = null;
let draggedSite: {
  workspaceId: string;
  accountId: string;
} = null;
let highlightedSite: string = null;
let searchValue = '';

// GETTERS

export const GET_COLLAPSED_ACCOUNTS = (): Set<string> => collapsedAccounts;

export const GET_COLLAPSED_FOLDERS = (): {
  [accountId: string]: string[];
} => collapsedFolders;

export const GET_VISIBLE_EMPTY_FOLDERS = (): {
  [accountId: string]: string[];
} => visibleEmptyFolders;

export const GET_SCROLL_POSITION = (): number => scrollPosition;

export const GET_EDITED_FOLDER = (): {
  accountId: string;
  _folderId: string;
} => editedFolder;

export const GET_DRAGGED_SITE = (): {
  workspaceId: string;
  accountId: string;
} => draggedSite;

export const GET_HIGHLIGHTED_SITE = (): string => highlightedSite;

export const GET_SEARCH_VALUE = (): string => searchValue;

// SETTERS

export const ADD_COLLAPSED_ACCOUNT = (_accountId: string): void => {
  collapsedAccounts.add(_accountId);
};

export const ADD_COLLAPSED_FOLDER = (_accountId: string, _folderId: string): void => {
  if (!collapsedFolders[_accountId]) {
    collapsedFolders[_accountId] = [_folderId];
  } else {
    collapsedFolders[_accountId].push(_folderId);
  }
};

export const ADD_VISIBLE_EMPTY_FOLDER = (_accountId: string, _folderName: string): void => {
  if (!visibleEmptyFolders[_accountId]) {
    visibleEmptyFolders[_accountId] = [_folderName];
  } else if (!visibleEmptyFolders[_accountId].includes(_folderName)) {
    visibleEmptyFolders[_accountId].push(_folderName);
  }
};

export const ADD_EDITED_FOLDER = (_accountId: string, _folderId: string): void => {
  editedFolder = {
    accountId: _accountId,
    _folderId: _folderId,
  };
};

export const DELETE_COLLAPSED_ACCOUNT = (_accountId: string): void => {
  collapsedAccounts.delete(_accountId);
};

export const DELETE_COLLAPSED_FOLDER = (_accountId: string, _folderId: string): void => {
  const index = collapsedFolders[_accountId].indexOf(_folderId);

  collapsedFolders[_accountId].splice(index, 1);
};

export const SET_SCROLL_POSITION = (_scrollPosition: number): void => {
  scrollPosition = _scrollPosition;
};

export const SET_DRAGGED_SITE = (site: { workspaceId: string; accountId: string }): void => {
  draggedSite = site;
};

export const SET_HIGHLIGHTED_SITE = (_workspaceId: string): void => {
  highlightedSite = _workspaceId;
};

export const SET_SIDE_PANEL_SEARCH = (_value: string): void => {
  searchValue = _value;
};

// CLEAR

export const DELETE_EDITED_FOLDER = (): void => {
  editedFolder = null;
};

export const DELETE_HIGHLIGHTED_SITE = (): void => {
  highlightedSite = null;
};

export const CLEAR_SIDE_PANEL_SEARCH = (): void => {
  searchValue = '';
};

// ---
