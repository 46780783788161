import { TMouseEventPosition } from '../point-plan.component';

export const isOverDeleteIcon = (
  event: TMouseEventPosition,
  deleteIcon: HTMLElement,
  isTouchDevice: boolean = false,
): boolean => {
  const deleteIconRect = deleteIcon.getBoundingClientRect();
  const touchDeviceCorrectionValuePx = isTouchDevice ? 50 : 0;

  return (
    event.clientX >= deleteIconRect.x &&
    event.clientX <= deleteIconRect.x + deleteIconRect.width &&
    event.clientY >= deleteIconRect.y + touchDeviceCorrectionValuePx &&
    event.clientY <= deleteIconRect.y + deleteIconRect.height + touchDeviceCorrectionValuePx
  );
};
