import { cloneDeep } from 'lodash';

import { ActionTypes, ActionsUnion } from './ui.actions';
import { TUISettings } from './ui.model';

export const initialState: TUISettings = {
  actionBar: false,
  sitePlanWidth: {},
  reminder: false,
};

let newState: TUISettings = {
  actionBar: false,
  sitePlanWidth: {},
  reminder: false,
};

export function uiReducer(state: TUISettings = initialState, action: ActionsUnion): TUISettings {
  switch (action.type) {
    case ActionTypes.ADD_TO_VIEW:
      newState = cloneDeep(state);

      newState[action.payload.componentName] = true;

      return newState;

    case ActionTypes.REMOVE_FROM_VIEW:
      newState = cloneDeep(state);

      newState[action.payload.componentName] = false;

      return newState;

    case ActionTypes.SET_SITE_PLAN_WIDTH:
      newState = cloneDeep(state);

      newState.sitePlanWidth[action.payload.workspaceId] = +action.payload.width;

      return newState;

    case ActionTypes.SET_SITE_PLAN_WIDTH_FROM_DB:
      newState = cloneDeep(state);

      newState.sitePlanWidth = action.payload.sitePlanWidth;

      return newState;

    default:
      return state;
  }
}
