import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TAllUsers } from '@project/view-models';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { DeviceService } from 'src/app/core/services/device.service';
import { SiteDataService, TSiteData } from 'src/app/project/modules/site/site-data.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventDashboard,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from '../../../../../shared/enums/icons.enum';
import { TWorkspacesById } from '../../../../workspace/workspace.model';
import { EDashletAcitivity } from '../dashlet-activities-enums';
import { TDashletActivity } from './dashlet-activity.model';

@Component({
  selector: 'pp-dashlet-activity',
  templateUrl: './dashlet-activity.component.html',
  styleUrls: ['./dashlet-activity.component.scss'],
})
export class DashletActivityComponent implements OnInit, OnDestroy {
  @Input() ppActivity: TDashletActivity;
  @Input() ppWorkspaces: TWorkspacesById;
  @Input() ppAllUsers: TAllUsers;

  private readonly destroy$ = new Subject<void>();

  site: TSiteData = this.siteDataService.getSite();
  isTouchDevice: boolean;
  dashletAcitivities = EDashletAcitivity;
  EIconPath = EIconPath;

  constructor(
    private workspaceService: WorkspaceService,
    private router: Router,
    private siteDataService: SiteDataService,
    private deviceService: DeviceService,
  ) {}

  ngOnInit(): void {
    this.isTouchDevice = this.deviceService.isTouchDevice();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  openPoint(workspaceId: string, _id: string): void {
    if (this.isTouchDevice) {
      return;
    }

    logEventInGTAG(EGoogleEventDashboard.DASHBOARD__ACTIVITY_CLICKED, {
      event_category: EGoogleEventCategory.DASHBOARD,
    });

    this.workspaceService
      .generateWorkspace(workspaceId)
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          this.router.navigate(['/site', workspaceId, 'point', _id]);
        }),
      )
      .subscribe();
  }
}
