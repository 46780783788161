import { Component, Input } from '@angular/core';
import { TAuthorizationProgressIcon } from '../authorization-progress.consts';

@Component({
  selector: 'pp-authorization-progress-icon',
  templateUrl: './authorization-progress-icon.component.html',
  styleUrls: ['./authorization-progress-icon.component.scss'],
})
export class AuthorizationProgressIconComponent {
  @Input() ppIcon: TAuthorizationProgressIcon;
}
