import { Component, OnInit } from '@angular/core';

import { ModalService } from '../../../components/modal/modal.service';
import { DashboardService } from '../dashboard-service/dashboard.service';

import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventDashboard,
  EGoogleEventFleetManagement,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { AssetDashboardService } from '../../fleet-management/fleet-management-fleet-page/fleet-assets/asset-card/asset-dashboard/asset-dashboard.service';
import { EDashletType } from '../dashlets-enums';
import { TDashletBudgetItem } from './new-dashlet-modal-cost-fields/new-dashlet-modal-cost-fields.consts';
import { TNewDashletModalData } from './new-dashlet-modal.consts';

@Component({
  selector: 'app-new-dashlet-modal',
  templateUrl: './new-dashlet-modal.component.html',
  styleUrls: ['./new-dashlet-modal.component.scss'],
})
export class NewDashletModalComponent implements OnInit {
  private newDashlet: {
    value: EDashletType;
  };
  assetId: string;
  EDashletType = EDashletType;
  settingCostFields = false;

  constructor(
    private dashboardService: DashboardService,
    private modalService: ModalService,
    private assetDashboardService: AssetDashboardService,
  ) {}

  ngOnInit() {
    const modalData = this.modalService.getModal().data as TNewDashletModalData;

    this.assetId = modalData.assetId;

    this.newDashlet = {
      value: EDashletType.ACTIVITIES,
    };
  }

  addDashlet(newDashlet: EDashletType): void {
    this.newDashlet.value = newDashlet;

    if (newDashlet === EDashletType.COST_COMPARISON) {
      this.setSettingCost(true);
      return;
    }

    if (newDashlet === EDashletType.CURRENT_STACKED_STATUS) {
      this.addStackedStatusDashlet();
      return;
    }

    if (this.assetId) {
      logEventInGTAG(EGoogleEventFleetManagement.ASSET_DASHLET_ADD, {
        event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
        event_details: newDashlet,
      });

      this.assetDashboardService.addDashlet(this.newDashlet, this.assetId);
    } else {
      logEventInGTAG(EGoogleEventDashboard.DASHBOARD__DASHLET__ADD, {
        event_category: EGoogleEventCategory.DASHBOARD,
        event_details: newDashlet,
      });

      this.dashboardService.addDashlet(this.newDashlet);
    }

    this.hideModal();
  }

  addCostComparisonDashlet(data: TDashletBudgetItem[]) {
    logEventInGTAG(EGoogleEventFleetManagement.ASSET_DASHLET_ADD, {
      event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
      event_details: EDashletType.COST_COMPARISON,
    });

    this.assetDashboardService.addCostComparisonDashlet(this.assetId, data);

    this.hideModal();
  }

  addStackedStatusDashlet() {
    logEventInGTAG(EGoogleEventFleetManagement.ASSET_DASHLET_ADD, {
      event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
      event_details: EDashletType.CURRENT_STACKED_STATUS,
    });

    this.assetDashboardService.addStackedStatusDashlet(this.assetId);

    this.hideModal();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  setSettingCost(setting: boolean): void {
    this.settingCostFields = setting;
  }
}
