import { logErrorInSentry } from '../../modules/errors/response-error';

import { GET_USER } from '../../modules/user/user.store';
import { EUserType } from '../../modules/users/user-types-enum';
import DATABASES from './DATABASES';

let databaseFound = true;

export function openDB(_databaseName: string): Promise<Event> {
  const database = DATABASES.find((_database) => _database.name === _databaseName);

  return new Promise((_resolve, _reject) => {
    const request = window.indexedDB.open(database.name, database.version);

    request.onsuccess = function (_event: Event): void {
      _resolve(_event);
    };

    request.onerror = function (_event: Event): void {
      _reject(_event);
    };

    request.onupgradeneeded = function (_event: Event): void {
      createObjectStores(database.name, _event);
    };
  });
}

export function createObjectStores(_databaseName: string, _event: any): void {
  const databaseInstance = _event.target.result;
  const databaseData = DATABASES.find((__database) => __database.name === _databaseName);
  const objectStores = new Set();

  for (const [, value] of Object.entries(databaseInstance.objectStoreNames)) {
    objectStores.add(value);
  }

  databaseData.objectStores.forEach((_objectStoreData) => {
    if (databaseInstance.objectStoreNames.contains(_objectStoreData.name)) {
      const objectStore = _event.target.transaction.objectStore(_objectStoreData.name);

      if (objectStore.keyPath !== _objectStoreData.keyPath) {
        databaseInstance.deleteObjectStore(_objectStoreData.name);
        databaseInstance.createObjectStore(_objectStoreData.name, {
          keyPath: _objectStoreData.keyPath,
        });
      }
    } else {
      databaseInstance.createObjectStore(_objectStoreData.name, {
        keyPath: _objectStoreData.keyPath,
      });
    }

    if (
      _objectStoreData.index &&
      databaseInstance.objectStoreNames.contains(_objectStoreData.name)
    ) {
      const objectStore = _event.target.transaction.objectStore(_objectStoreData.name);

      if (objectStore.indexNames.contains(_objectStoreData.index)) {
        const index = objectStore.index(_objectStoreData.index);

        if (index.keyPath !== _objectStoreData.keyPathIndex) {
          objectStore.deleteIndex(_objectStoreData.index);
          objectStore.createIndex(_objectStoreData.index, _objectStoreData.keyPathIndex);
        }
      } else {
        objectStore.createIndex(_objectStoreData.index, _objectStoreData.keyPathIndex);
      }
    }
  });
}

export function clearObjectStores(_databaseName?: string): void {
  if (!_databaseName) {
    DATABASES.forEach((_database) => {
      openDB(_database.name).then((_event: any) => {
        _database.objectStores.forEach((_objectStore) => {
          getObjectStore(_event.target.result, _objectStore.name, true).clear();
        });
      });
    });
  } else {
    const database = DATABASES.find((_database) => _database.name === _databaseName);

    openDB(_databaseName).then((_event: any) => {
      database.objectStores.forEach((_objectStore) => {
        getObjectStore(_event.target.result, _objectStore.name, true).clear();
      });
    });
  }
}

export function getObjectStore(_database: any, _storeName: string, readwrite?: boolean): any {
  let mode = 'readonly';

  if (readwrite) {
    mode = 'readwrite';
  }

  return _database.transaction(_storeName, mode).objectStore(_storeName);
}

export function addItemToStore(
  _databaseName: string,
  _storeName: string,
  _data: Record<string, unknown>,
): void {
  const database = DATABASES.find((_database) => _database.name === _databaseName);

  if (!GET_USER()) {
    return;
  }

  if (database) {
    if (
      (GET_USER().userType === EUserType.DEVELOPER ||
        _storeName === 'accounts' ||
        (GET_USER().userType === EUserType.TESTER && _storeName === 'tiles')) &&
      databaseFound
    ) {
      openDB(_databaseName)
        .then((_event: any) => {
          const objectStore = getObjectStore(_event.target.result, _storeName, true);
          const store = database.objectStores.find((_store) => _store.name === _storeName);

          if (store) {
            const keyPath = store.keyPath;

            if (keyPath === objectStore.keyPath) {
              if (checkKeyPath(_data, keyPath)) {
                objectStore.put(_data);
              } else {
                const error = {
                  name: 'KeyPath missing in object',
                  database: _databaseName,
                  store: _storeName,
                  keyPath: keyPath,
                  data: _data,
                };

                logError(error);
              }
            } else {
              const error = {
                name: 'KeyPath not found',
                database: _databaseName,
                store: _storeName,
                keyPath: keyPath,
              };

              logError(error);
            }
          } else {
            const error = {
              name: 'Store not found',
              database: _databaseName,
              store: _storeName,
            };

            logError(error);
          }
        })
        .catch((errorData) => {
          const error = {
            name: 'Database not found',
            database: _databaseName,
            store: _storeName,
            errorData: errorData,
          };

          databaseFound = false;

          logError(error);
        });
    }
  } else {
    const error = {
      name: 'Database not found',
      database: _databaseName,
      store: _storeName,
    };

    databaseFound = false;

    logError(error);
  }
}

export function addItemsToStore(
  _databaseName: string,
  _storeName: string,
  _data: Record<string, unknown>[],
  _clear: boolean = false,
): void {
  if (!GET_USER()) {
    return;
  }

  if ((GET_USER().userType === EUserType.DEVELOPER || _storeName === 'accounts') && databaseFound) {
    openDB(_databaseName)
      .then((_event: any) => {
        const objectStore = getObjectStore(_event.target.result, _storeName, true);

        if (_clear) {
          objectStore.clear();
        }

        _data.forEach((__data) => {
          if (checkKeyPath(__data, objectStore.keyPath)) {
            objectStore.put(__data);
          } else {
            const error = {
              name: 'KeyPath missing in object',
              database: _databaseName,
              store: _storeName,
              keyPath: objectStore.keyPath,
              data: _data,
            };

            logError(error);
          }
        });
      })
      .catch((errorData) => {
        const error = {
          name: 'Database not found',
          database: _databaseName,
          store: _storeName,
          errorData: errorData,
        };

        databaseFound = false;

        logError(error);
      });
  }
}

export function getItemFromStore<T = any>(
  _databaseName: string,
  _storeName: string,
  _id: string,
): Promise<T> {
  const databaseData = DATABASES.find((__database) => __database.name === _databaseName);
  const storeData = databaseData.objectStores.find((_store) => _store.name === _storeName);

  return new Promise((_resolve, _reject) => {
    openDB(_databaseName).then((_event: any) => {
      const store = getObjectStore(_event.target.result, _storeName);
      let request = null;

      if (storeData.index) {
        request = store.index(storeData.index).get(_id);
      } else {
        request = store.get(_id);
      }

      request.onsuccess = function (_event: any): void {
        _resolve(_event.target.result);
      };

      request.onerror = function (_event): void {
        _reject(_event);
      };
    });
  });
}

export function getItemsFromStore<T>(
  _databaseName: string,
  _storeName: string,
  _filter?: {
    property: string;
    data: any;
  },
): Promise<T> {
  return new Promise((_resolve, _reject) => {
    openDB(_databaseName).then((_event: any) => {
      const request = getObjectStore(_event.target.result, _storeName).getAll();

      request.onsuccess = function (_event: any) {
        if (_filter) {
          const properties = _filter.property.split('.');

          const data = _event.target.result.filter((_data) => {
            let property = _data;

            properties.forEach((_property) => {
              property = property[_property];
            });

            if (property === _filter.data) {
              return true;
            }

            return false;
          });

          _resolve(data as T);
        } else {
          _resolve(_event.target.result as T);
        }
      };

      request.onerror = function (_event): void {
        _reject(_event);
      };
    });
  });
}

export function deleteFromStore(
  _databaseName: string,
  _storeName: string,
  _key: string,
): Promise<unknown> {
  return new Promise((_resolve, _reject) => {
    openDB(_databaseName).then((_event: any) => {
      const request = getObjectStore(_event.target.result, _storeName, true).delete(_key);

      request.onsuccess = function (_event: any) {
        _resolve(_event);
      };

      request.onerror = function (_event) {
        _reject(_event);
      };
    });
  });
}

export function logError(error): void {
  logErrorInSentry(error); // we care more about the data than "where" it happened
}

export function getDatabaseFound(): boolean {
  return databaseFound;
}

function checkKeyPath(_data: any, _keyPath: string): boolean {
  let data = _data;

  const pathToKey = _keyPath.split('.');

  for (let index = 0; index < pathToKey.length; index += 1) {
    const _key = pathToKey[index];

    if (data.hasOwnProperty(_key)) {
      if (index < pathToKey.length - 1) {
        // FIXME data is changed to a property of itself
        data = data[_key];
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}
