import { SimpleChanges } from '@angular/core';

export function checkEnableQuill(
  changes: SimpleChanges,
  ppCanEdit: boolean,
  ppProcessing: boolean,
): boolean {
  if (changes.ppCanEdit) {
    if (ppCanEdit) {
      return true;
    } else {
      return false;
    }
  } else if (ppCanEdit) {
    if (changes.ppProcessing && ppProcessing) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}
