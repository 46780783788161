import { Component, OnDestroy, OnInit } from '@angular/core';

import { NewDashletModalComponent } from '../new-dashlet-modal/new-dashlet-modal.component';

import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { DashboardService } from '../dashboard-service/dashboard.service';

import { TUser } from 'src/app/project/modules/user/user.model';

import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ConfirmModalComponent,
  TConfirmModalParams,
} from 'src/app/project/components/confirm-modal/confirm-modal.component';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { UserService } from 'src/app/project/modules/user/user.service';
import { EUserType } from 'src/app/project/modules/users/user-types-enum';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventDashboard,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { PreferencesService } from '../../preferences/preferences-service/preferences.service';
import { DashboardExportModalComponent } from '../dashboard-export-modal/dashboard-export-modal.component';
import { EDashletType } from '../dashlets-enums';
import { TNewDashletModalData } from '../new-dashlet-modal/new-dashlet-modal.consts';

@Component({
  selector: 'pp-dashboard-settings-dropdown',
  templateUrl: './dashboard-settings-dropdown.component.html',
  styleUrls: ['./dashboard-settings-dropdown.component.scss'],
})
export class DashboardSettingsDropdownComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  user: TUser;
  userTypes = EUserType;
  exportsEnabled: boolean;

  constructor(
    private dropdownService: DropdownService,
    private dashboardService: DashboardService,
    private modalService: ModalService,
    private translationPipe: TranslationPipe,
    private userService: UserService,
    private preferencesService: PreferencesService,
    private router: Router,
  ) {
    this.user = this.userService.getUser();
    const preferences = this.preferencesService.getPreferences();

    const dashlets = preferences.dashlets?.data.filter(
      (_dashlet) => _dashlet.name !== EDashletType.ACTIVITIES,
    );

    this.exportsEnabled = dashlets.length > 0;
  }

  ngOnInit(): void {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.hideDropdown();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  setDefaultLayout(): void {
    const modalData: TConfirmModalParams = {
      message: this.translationPipe.transform('confirm_default_layout'),
      heading: this.translationPipe.transform('set_default_layout'),
      redButton: false,
      confirmText: this.translationPipe.transform('confirm'),
      boldText: true,
    };

    this.modalService.setData(modalData);

    this.modalService.showModal(ConfirmModalComponent, {
      blur: false,
      closeWarning: true,
      callback: () => {
        this.dashboardService.setDefaultDashlets();
      },
    });

    this.hideDropdown();
  }

  hideDropdown(): void {
    this.dropdownService.hideDropdown();
  }

  newDashlet(): void {
    logEventInGTAG(EGoogleEventDashboard.DASHBOARD__NEW_BUTTON, {
      event_category: EGoogleEventCategory.DASHBOARD,
    });

    this.modalService.setData<TNewDashletModalData>({});
    this.modalService.showModal(NewDashletModalComponent);

    this.hideDropdown();
  }

  exportDashboard(): void {
    if (this.exportsEnabled) {
      logEventInGTAG(EGoogleEventDashboard.DASHBOARD__EXPORT_BUTTON, {
        event_category: EGoogleEventCategory.DASHBOARD,
      });

      this.modalService.showModal(DashboardExportModalComponent);
    }

    this.hideDropdown();
  }
}
