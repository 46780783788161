import { GET_GROUPING } from '../../../../columns/grouping.store';
import { GET_COLLAPSED_GROUPS, GET_TABLE } from '../../../../table.ui.store';
import { generateDoubleGroupingEndElements } from './generate-double-grouping-end-elements';
import { generateSingleGroupingEndElements } from './generate-single-grouping-end-elements';
import { generateThirdGroupingEndElements } from './generate-third-grouping-end-elements';

export function createHeaderEndElements(groupingLevel: number, index: number): HTMLElement[] {
  const grouping = GET_GROUPING();
  const table = GET_TABLE();
  const collapsedGroups = GET_COLLAPSED_GROUPS();

  const itemId = table.items[index].id;
  const collapsed = collapsedGroups.has(itemId);

  switch (grouping.length) {
    case 1:
      return generateSingleGroupingEndElements(collapsed);
    case 2:
      return generateDoubleGroupingEndElements(groupingLevel, collapsed);
    case 3:
      return generateThirdGroupingEndElements(groupingLevel, collapsed);
  }

  return [];
}
