<div
  class="pointCF__field pointCF__field--expandable"
  *ngIf="tagsVisible"
  data-test="pointTagSelectList"
>
  <label class="pointCF__label pointCF__label--tags">
    <div class="pointCF__label-image--container">
      <pp-icon [ppSrc]="EIconPath.ICON_ACTIVITIES_TAGS_18" ppColor="inherit" ppClass="setSize18" />
    </div>

    <span
      ppTooltip
      class="pointCF__label-container"
      [ppTitle]="'tags' | ppTranslate"
      [ppTrimTooltip]="true"
    >
      {{ 'tags' | ppTranslate }}
    </span>
  </label>

  <div class="pointCF__content pointCF__content--tags">
    <pp-tag-select
      ppClass="tagSelect__auto-width"
      [ppPointId]="ppPointId"
      [ppSelectedTags]="ppTags"
      (ppOnChange)="updateTags($event)"
      (ppOnClose)="updateTags($event)"
      [ppWorkspaceId]="ppWorkspaceId"
      [ppOptions]="tagSelectOptions"
    ></pp-tag-select>
  </div>
</div>
