<pp-new-dashlet-modal-dashlets
  [ppAssetId]="assetId"
  (ppAddDashlet)="addDashlet($event)"
  *ngIf="!settingCostFields"
></pp-new-dashlet-modal-dashlets>

<pp-new-dashlet-modal-cost-fields
  [ppAssetId]="assetId"
  *ngIf="settingCostFields"
  (ppAddDashlet)="addCostComparisonDashlet($event)"
  (ppGoBack)="setSettingCost(false)"
  [ppNewDashlet]="true"
></pp-new-dashlet-modal-cost-fields>
