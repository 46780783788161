<section class="dashlet-flip__content-wrapper dashletFlip__back">
  <div class="dashletFlip__container dashletFlip__container--active" #dashletFlipContainer>
    <h6 class="dashletFlip__title">Select Account & Site</h6>

    <p class="dashletFlip__section-description">
      Choose which sites you want to display the data from
    </p>

    <ul class="dashletFlip__accounts">
      <li
        class="dashletFlip__account"
        [ngClass]="{
          'dashletFlip__account--active':
            !siteRange.accountId && siteRange.workspaceIds.length === 0,
        }"
        (click)="clearSiteRange()"
      >
        <h4 class="dashletSiteacc__acc-circle">A</h4>
        <p class="dashletSiteacc__acc-label">All Accounts - All Sites</p>
      </li>

      <ng-container *ngIf="isAccountsArray">
        <li
          class="dashletFlip__account"
          [ngClass]="{ 'dashletFlip__account--active': account.accountId === siteRange.accountId }"
          *ngFor="let account of accounts"
        >
          <div
            class="dashletFlip__account-wrapper"
            (click)="
              updateSiteRange({
                accountId: account.accountId,
                workspaceIds: [],
              })
            "
          >
            <h4 class="dashletSiteacc__acc-circle">
              {{ account.accountName | firstLetter }}
            </h4>
            <p class="dashletSiteacc__acc-label">{{ account.accountName }} - All Sites</p>
          </div>

          <pp-dashlet-timeline-account
            [ppAccount]="account"
            [ppDashlet]="ppDashlet"
            [ppSiteRange]="siteRange"
            [ppBlockedTimelines]="blockedTimelines"
            [ppWorkspaces]="workspaces"
            (ppUpdateSiteRange)="updateSiteRange($event)"
          ></pp-dashlet-timeline-account>
        </li>
      </ng-container>
    </ul>
  </div>
</section>
