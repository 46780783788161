<div
  class="buttonDropdown"
  [class.buttonDropdown__active]="ppActive"
  (click)="action()"
  [class.buttonDropdown__disabled]="ppDisabled"
>
  <div
    class="buttonDropdown__group"
    [class.buttonDropdown__group__active]="ppActive"
    [class.buttonDropdown__group__disabled]="ppDisabled"
  >
    <pp-icon
      class="buttonDropdown__icon"
      ppClass="buttonDropdown__svg"
      [ppSrc]="ppSrc"
      [ppDisplayContents]="true"
    ></pp-icon>
    <span class="buttonDropdown__text">
      <span class="buttonDropdown__text--hide">{{ ppText }}</span>
      {{ ppNumber }}
    </span>
  </div>
</div>
