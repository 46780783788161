import * as ExcelJS from 'exceljs';

export function createWorksheet(): ExcelJS.Workbook {
  const workbook = new ExcelJS.Workbook();

  workbook.creator = 'Pinpoint Works';
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();
  workbook.calcProperties.fullCalcOnLoad = true;

  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 1,
      visibility: 'visible',
    },
  ];

  workbook.addWorksheet('Timeline Export', {
    views: [
      {
        showGridLines: false,
        state: 'frozen',
        ySplit: 1,
        xSplit: 8,
      },
    ],
  });

  return workbook;
}
