import { RESIZE_HANDLE_WIDTH } from './timeline-variables';
import { adjustTimelineElementClass } from './adjust-timeline-element-class';

export function adjustTimelineElementLeft({
  newWidth,
  element,
  changeAmount,
  width,
}: {
  newWidth: number;
  element: HTMLElement;
  changeAmount: number;
  width: number;
}): void {
  adjustTimelineElementClass(newWidth, element);

  if (!(changeAmount > width - RESIZE_HANDLE_WIDTH)) {
    element.style.width = `${newWidth}px`;
    element.style.marginLeft = `${changeAmount}px`;
  } else if (changeAmount > width + RESIZE_HANDLE_WIDTH) {
    element.style.marginLeft = `${width}px`;
    element.style.width = `${-newWidth}px`;
  }
}
