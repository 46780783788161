import { Component, Input, OnInit } from '@angular/core';
import { PointsHelperService } from 'src/app/project/modules/points/points-helper.service';
import { getNotificationReasonText } from '../../utils/notification-reason';
import { EStatus } from '../../../../shared/enums/status.enum';
import { TPushNotification } from '../../notification.model';
import { EIconPath } from '../../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-notification-status',
  templateUrl: './notification-status.component.html',
  styleUrls: ['../notification.component.scss'],
})
export class NotificationStatusComponent implements OnInit {
  @Input() ppNotification: TPushNotification<EStatus | string>;

  oldStatusText: string;
  oldStatusColor: string;
  newStatusText: string;
  newStatusColor: string;
  notificationReason: string;
  EIconPath = EIconPath;

  constructor(private pointsHelperService: PointsHelperService) {}

  ngOnInit(): void {
    this.oldStatusText = this.humanizeStatusName(
      this.ppNotification.changeBody.oldValue as EStatus,
    );
    this.oldStatusColor = this.statusPriorityToColor(this.ppNotification.changeBody.oldValue);
    this.newStatusText = this.humanizeStatusName(
      this.ppNotification.changeBody.newValue as EStatus,
    );
    this.newStatusColor = this.statusPriorityToColor(this.ppNotification.changeBody.newValue);
    this.notificationReason = getNotificationReasonText(this.ppNotification.notificationReason);
  }

  humanizeStatusName(status: EStatus): string {
    return this.pointsHelperService.humanizeStatusName(status);
  }

  statusPriorityToColor(status: string): string {
    return this.pointsHelperService.statusPriorityToColor(status);
  }
}
