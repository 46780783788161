import { fabric } from 'fabric';

import { Injectable, OnDestroy } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import {
  EImageAnnotationsState,
  ImageAnnotationsStateService,
} from './image-annotations-state.service';
import { Subject } from 'rxjs';
import { TAnnotationsCoords } from './image-annotation.model';

@Injectable({
  providedIn: 'root',
})
export class ImageAnnotationsArrowService implements OnDestroy {
  canvasFabric: fabric.Canvas = null;
  currentMode: EImageAnnotationsState = null;

  private readonly destroy$ = new Subject<void>();

  private arrowPositions: Array<{
    line: string[];
    triangle: string[];
  }> = [];
  private arrowPositionsCurrentIndex = 0;

  private x: string;
  private y: string;
  private x2: string;
  private y2: string;

  constructor(private imageAnnotationsStateService: ImageAnnotationsStateService) {
    imageAnnotationsStateService.modeChange$.pipe(takeUntil(this.destroy$)).subscribe((newMode) => {
      this.currentMode = newMode;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  setCanvasFabric(canvasFabric: fabric.Canvas): void {
    this.canvasFabric = canvasFabric;
  }

  addArrow(color: string, newArrow: boolean, coordinates?: TAnnotationsCoords): void {
    let lineFabric: fabric.Line = null;
    let triangleFabric: fabric.Triangle = null;

    this.x = coordinates.x;
    this.y = coordinates.y;

    if (newArrow) {
      const lineCoordinates = {
        x1: coordinates.x,
        y1: coordinates.y,
        x2: coordinates.x,
        y2: coordinates.y,
      };

      this.resetState();

      lineFabric = this.makeLine(
        [lineCoordinates.x1, lineCoordinates.y1, lineCoordinates.x2, lineCoordinates.y2],
        color,
      );
      triangleFabric = this.makeTriangle(lineFabric.get('x2'), lineFabric.get('y2'), color);
    } else {
      const lastState = this.arrowPositions[this.arrowPositionsCurrentIndex];

      lineFabric = this.makeLine(lastState.line, color);
      triangleFabric = this.makeTriangle(lineFabric.get('x2'), lineFabric.get('y2'), color);
      triangleFabric.angle = lastState.triangle[2];
    }

    this.canvasFabric.add(lineFabric, triangleFabric);
    this.canvasFabric.selectionColor = 'transparent';
    this.canvasFabric.selectionBorderColor = 'transparent';

    this.canvasFabric.on('mouse:move', (e) => {
      if (
        this.currentMode === EImageAnnotationsState.ADDING_ARROW &&
        lineFabric &&
        triangleFabric
      ) {
        const p = e.pointer;

        lineFabric.set({
          x2: p.x,
          y2: p.y,
        });

        this.x2 = p.x;
        this.y2 = p.y;

        lineFabric.setCoords();

        this.calcCoordinatesOnLineMove(lineFabric, triangleFabric);
        this.canvasFabric.renderAll();
      }
    });

    this.canvasFabric.on('mouse:up', () => {
      if (
        this.currentMode === EImageAnnotationsState.ADDING_ARROW &&
        lineFabric &&
        triangleFabric
      ) {
        const activeObject = this.canvasFabric.getActiveObject();
        this.canvasFabric.off('mouse:move');
        this.canvasFabric.selectionColor = 'rgba(100, 100, 255, 0.3)';

        this.canvasFabric.forEachObject((object) => {
          if (object.name === 'line' || object.name === 'triangle') {
            this.canvasFabric.remove(object);
          }
        });

        if (activeObject) {
          let arrowFabric = new fabric.Group();

          this.updateArrowPosition(lineFabric, triangleFabric);
          lineFabric.clone((lineClone) => arrowFabric.addWithUpdate(lineClone));
          triangleFabric.clone((triangleClone) => arrowFabric.addWithUpdate(triangleClone));

          const angle = this.getAngle(lineFabric);
          const offsetX = -Math.abs(Math.cos(angle)) * 8;
          const offsetY = -Math.abs(Math.sin(angle)) * 8;

          let pickedColor;
          this.imageAnnotationsStateService.colorChange$
            .pipe(take(1))
            .subscribe(({ colorCode }) => {
              pickedColor = colorCode;
            });

          arrowFabric.set({
            left: this.x > this.x2 ? this.x2 + offsetX : this.x + offsetX,
            top: this.y > this.y2 ? this.y2 + offsetY : this.y + offsetY,
            hasControls: false,
            fill: pickedColor,
          });

          arrowFabric.name = 'arrow';

          this.canvasFabric.discardActiveObject();

          this.canvasFabric.add(arrowFabric);
          this.canvasFabric.renderAll();

          lineFabric = null;
          triangleFabric = null;
          arrowFabric = null;
        } else {
          lineFabric = null;
          triangleFabric = null;
        }
      }

      this.canvasFabric.off('mouse:up');
    });
  }

  resetState(): void {
    this.arrowPositions = [];
    this.arrowPositionsCurrentIndex = -1;
  }

  private updateArrowPosition(line: fabric.Line, triangle: fabric.Triangle): void {
    this.arrowPositions.push({
      line: [line.x1, line.y1, line.x2, line.y2],
      triangle: [triangle.left, triangle.top, triangle.angle],
    });

    this.arrowPositionsCurrentIndex = this.arrowPositions.length - 1;
  }

  private calcCoordinatesOnLineMove(
    lineFabric: fabric.Line,
    triangleFabric: fabric.Triangle,
  ): void {
    const oldCenterX = (lineFabric.x1 + lineFabric.x2) / 2;
    const oldCenterY = (lineFabric.y1 + lineFabric.y2) / 2;
    const deltaX = lineFabric.left - oldCenterX;
    const deltaY = lineFabric.top - oldCenterY;

    lineFabric.x1 = lineFabric.x1 + deltaX;
    lineFabric.y1 = lineFabric.y1 + deltaY;
    lineFabric.x2 = lineFabric.x2 + deltaX;
    lineFabric.y2 = lineFabric.y2 + deltaY;

    lineFabric
      .set({
        left: (lineFabric.x1 + lineFabric.x2) / 2,
        top: (lineFabric.y1 + lineFabric.y2) / 2,
      })
      .setCoords();
    triangleFabric.set({ left: lineFabric.x2 + deltaX, top: lineFabric.y2 + deltaY }).setCoords();

    triangleFabric.angle = this.getAngle(lineFabric);
  }

  private getAngle(p: fabric.Line): number {
    const x1 = p.x1;
    const y1 = p.y1;
    const x2 = p.x2;
    const y2 = p.y2;

    const angle1 = this.calcAngle(x2 - x1, y2 - y1);

    return -angle1;
  }

  private calcAngle(x: number, y: number): number {
    return (Math.atan2(x, y) * 180) / Math.PI;
  }

  private makeLine(coords: string[], color: string): fabric.Line {
    const l = new fabric.Line(coords, {
      fill: color,
      stroke: color,
      strokeWidth: 5,
      hasControls: false,
      hasRotatingPoint: true,
      perPixelTargetFind: true,
      lockScalingX: true,
      lockScalingY: true,
      originX: 'center',
      originY: 'center',
    });

    l.name = 'line';

    return l;
  }

  private makeTriangle(left: string, top: string, color: string): fabric.Triangle {
    const t = new fabric.Triangle({
      left: left,
      top: top,
      angle: 270,
      height: 15,
      width: 15,
      flipY: true,
      fill: color,
      stroke: color,
      hasControls: false,
      originX: 'center',
      originY: 'center',
    });

    t.name = 'triangle';

    return t;
  }
}
