import { Component, EventEmitter, Output } from '@angular/core';
import { DeviceService } from '@core/services';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-hide-dropdown',
  templateUrl: './hide-dropdown.component.html',
  styleUrls: ['./hide-dropdown.component.scss'],
})
export class HideDropdownComponent {
  @Output() ppHideDropdown = new EventEmitter<void>();

  EIconPath = EIconPath;

  isMobile = false;
  isTablet = false;

  constructor(private deviceService: DeviceService) {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
  }

  hideDropdown(): void {
    this.ppHideDropdown.emit();
  }
}
