import { GET_COLUMNS } from '../columns/columns.store';
import { GET_SORTING, SET_SORTING } from '../columns/sorting.store';

import { EBasicField, EBasicFieldShort } from 'src/app/project/shared/enums/basic-fields-enums';
import { TPoint } from '../../../points/points.model';
import { TColumn } from '../columns/column.model';
import { sortAccountName } from './sort-account-name';
import { sortAssignees } from './sort-assignees';
import { sortCreated } from './sort-created';
import { sortCreatedBy } from './sort-created-by';
import { sortCustomField } from './sort-custom-field';
import { sortDescription } from './sort-description';
import { sortFlagged } from './sort-flagged';
import { sortId } from './sort-id';
import { sortPriority } from './sort-priority';
import { sortSiteName } from './sort-site-name';
import { sortStatus } from './sort-status';
import { sortTags } from './sort-tags';
import { sortTimeline } from './sort-timeline';
import { sortTitle } from './sort-title';
import { sortUpdated } from './sort-updated';

export const generateSorting = (): void => {
  SET_SORTING(
    GET_COLUMNS()
      .filter((_column) => _column.sortOrder)
      .sort((firstPoint, secondPoint) => firstPoint.sortIndex - secondPoint.sortIndex),
  );
};

export const sortPoints = (): ((firstPoint: TPoint, secondPoint: TPoint) => number) => {
  const sortBy = GET_SORTING();

  return (firstPoint: TPoint, secondPoint: TPoint): number =>
    sort(firstPoint, secondPoint, sortBy, 0);
};

export const sortTimelinePoints =
  () =>
  (firstPoint: TPoint, secondPoint: TPoint): number =>
    sortTimeline(firstPoint, secondPoint, 0);

export const sort = (
  firstPoint: TPoint,
  secondPoint: TPoint,
  sortColumns: Partial<TColumn>[],
  index: number,
): number => {
  if (!sortColumns || !sortColumns.length) {
    return sortUpdated(firstPoint, secondPoint, [{ sortOrder: 'DESC' }], 0);
  }

  switch (sortColumns[index].name) {
    case EBasicField.ACCOUNT:
      return sortAccountName(firstPoint, secondPoint, sortColumns, index);
    case EBasicField.SITE:
      return sortSiteName(firstPoint, secondPoint, sortColumns, index);
    case EBasicField.PRIORITY:
    case EBasicFieldShort.PRIORITY:
      return sortPriority(firstPoint, secondPoint, sortColumns, index);
    case EBasicField.STATUS:
    case EBasicFieldShort.STATUS:
      return sortStatus(firstPoint, secondPoint, sortColumns, index);
    case EBasicFieldShort.SEQUENCE_NUMBER:
      return sortId(firstPoint, secondPoint, sortColumns, index);
    case EBasicField.TITLE:
      return sortTitle(firstPoint, secondPoint, sortColumns, index);
    case EBasicField.DESCRIPTION:
      return sortDescription(firstPoint, secondPoint, sortColumns, index);
    case EBasicField.UPDATED:
      return sortUpdated(firstPoint, secondPoint, sortColumns, index);
    case EBasicField.CREATED:
      return sortCreated(firstPoint, secondPoint, sortColumns, index);
    case EBasicField.CREATED_BY:
      return sortCreatedBy(firstPoint, secondPoint, sortColumns, index);
    case EBasicField.TAGS:
      return sortTags(firstPoint, secondPoint, sortColumns, index);
    case EBasicField.ASSIGNEES:
      return sortAssignees(firstPoint, secondPoint, sortColumns, index);
    case EBasicField.FLAGGED:
    case EBasicFieldShort.FLAGGED:
      return sortFlagged(firstPoint, secondPoint, sortColumns, index);
    default:
      return sortCustomField(firstPoint, secondPoint, sortColumns, index);
  }
};
