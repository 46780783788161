import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { TAllCustomFields } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { GET_CUSTOM_FIELDS } from '../../custom-fields/custom-fields.store';
import { TAllFilters, TCustomFieldFilter, TFilters } from '../site-filter.model';

export function createCustomFieldFilters({
  filters,
  savedFilter,
  workspace,
  customFields,
}: {
  filters: TFilters;
  savedFilter: TAllFilters;
  workspace: TWorkspace;
  customFields: TAllCustomFields;
}): TFilters {
  if (!workspace?.customFields) {
    return filters;
  }

  let index = 0;

  workspace.customFields.forEach((fieldId) => {
    let foundFilters: TCustomFieldFilter = null;
    const fieldAlreadyExistsInFilter = filters.customFields.find((filter) => filter.id === fieldId);
    const fieldVisible = GET_CUSTOM_FIELDS()[fieldId].display;

    if (!fieldVisible) {
      return;
    }

    if (!fieldAlreadyExistsInFilter) {
      addBasicCFFilterData(filters, fieldId, workspace);

      foundFilters = checkExistingFilter(savedFilter, workspace, foundFilters, fieldId);

      if (foundFilters) {
        filters.customFields[index] = foundFilters;
      } else {
        addSpecificCustomFieldFilterData(customFields, workspace, fieldId, filters, index);
      }
    }

    index++;
  });

  return filters;
}
function checkExistingFilter(
  savedFilter: TAllFilters,
  workspace: TWorkspace,
  foundFilters: TCustomFieldFilter,
  fieldId: string,
): TCustomFieldFilter {
  if (
    savedFilter &&
    savedFilter[workspace.workspaceId] &&
    savedFilter[workspace.workspaceId].customFields
  ) {
    return savedFilter[workspace.workspaceId].customFields.find((filter) => filter.id === fieldId);
  }

  return foundFilters;
}

function addSpecificCustomFieldFilterData(
  customFields: TAllCustomFields,
  workspace: TWorkspace,
  fieldId: string,
  filters: TFilters,
  index: number,
): void {
  switch (customFields[workspace.workspaceId][fieldId].type) {
    case ECustomFieldType.DATE:
    case ECustomFieldType.TIMELINE:
      createDateFilter(filters, index);
      break;
    case ECustomFieldType.COST:
    case ECustomFieldType.PERCENTAGE:
    case ECustomFieldType.FORMULA:
    case ECustomFieldType.NUMBERS:
    case ECustomFieldType.TIME:
      createNumericFilter(filters, index);
      break;
    case ECustomFieldType.CHECKBOX:
      createCheckboxFilter(filters, index);
      break;
    case ECustomFieldType.LIST:
      createListFilter(filters, index);
      break;
    default:
      break;
  }
}

function addBasicCFFilterData(filters: TFilters, fieldId: string, workspace: TWorkspace): void {
  filters.customFields.push({
    id: fieldId,
    workspaceId: workspace.workspaceId,
    sites: [
      {
        filterId: fieldId,
        workspaceId: workspace.workspaceId,
        accountName: workspace.accountName,
        siteName: workspace.siteName,
      },
    ],
  });
}

function createDateFilter(filters: TFilters, index: number): void {
  filters.customFields[index].startDate = null;
  filters.customFields[index].endDate = null;

  filters.customFields[index].appliedValues = {
    startDate: null,
    endDate: null,
  };
}

function createNumericFilter(filters: TFilters, index: number): void {
  filters.customFields[index].min = null;
  filters.customFields[index].max = null;

  filters.customFields[index].appliedValues = {
    min: null,
    max: null,
  };
}

function createCheckboxFilter(filters: TFilters, index: number): void {
  filters.customFields[index].value = null;

  filters.customFields[index].appliedValues = {
    value: null,
  };
}

function createListFilter(filters: TFilters, index: number): void {
  filters.customFields[index].idOfChosenElement = null;
  filters.customFields[index].path = null;
  filters.customFields[index].text = null;

  filters.customFields[index].appliedValues = {
    idOfChosenElement: null,
    path: null,
    text: null,
  };
}
