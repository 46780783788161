import { Feature } from 'ol';
import { Polygon } from 'ol/geom';

export function checkClickedPolygon(feature: Feature, checkedFeature: Feature): boolean {
  const coordinates = (feature.getGeometry() as Polygon).getCoordinates()[0];
  const _coordinates = (checkedFeature.getGeometry() as Polygon).getCoordinates()[0];

  if (coordinates === _coordinates) {
    return true;
  } else if (
    coordinates === null ||
    _coordinates === null ||
    coordinates.length !== _coordinates.length
  ) {
    return false;
  } else {
    for (let i = 0; i < coordinates.length; i++) {
      if (coordinates[i][0] !== _coordinates[i][0] || coordinates[i][1] !== _coordinates[i][1]) {
        return false;
      }
    }
  }

  return true;
}
