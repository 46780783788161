import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SiteSettingsUsersService {
  public readonly _newShareModalClosed$ = new Subject<void>();
  readonly newShareModalClosed$ = this._newShareModalClosed$.asObservable();

  public readonly _selectedSharesDeleted$ = new Subject<void>();
  readonly selectedSharesDeleted$ = this._selectedSharesDeleted$.asObservable();

  constructor() {}

  newShareCloseTriggerEvent(): void {
    this._newShareModalClosed$.next();
  }

  emitBulkSharesDeleted(): void {
    this._selectedSharesDeleted$.next();
  }
}
