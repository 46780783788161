import { Injectable } from '@angular/core';
import { TEntity } from '@core/helpers';

@Injectable({
  providedIn: 'root',
})
export class ApiHelper {
  static fromDTO<T>(dto: TEntity<T>): T {
    return dto?.entity !== undefined ? dto.entity : (dto as unknown as T);
  }
}
