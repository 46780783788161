export enum EFleetShareOption {
  EDIT = 'EDIT',
  READ = 'READ',
}

export type TFleetShare = {
  id: string;
  userId: string;
  fleetId: string;
  fleetShareOption: EFleetShareOption;
};
