import { createElement } from 'src/app/core/helpers/dom';
import { EStatus } from '../../../../../shared/enums/status.enum';
import { statusToImage } from '../../../../../shared/status-to-image';

export function createTimelineStatusElement(status: string): HTMLElement {
  const element = createElement('img', {
    attrs: {
      class: 'timeline__fieldElement__status',
      src: statusToImage(status as EStatus, true),
    },
  });

  return element;
}
