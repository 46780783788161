<div class="costField">
  <pp-select
    class="costField__select"
    [ppItems]="filteredAssetSites"
    [ppSelectedItem]="assetSites[assetSiteIndex]"
    (ppSelectItem)="selectSite($event)"
    [ppNoSearchBar]="true"
  >
    <ng-container selected>
      <span class="uerSettings__select-content" *ngIf="assetSiteIndex !== -1">
        {{ assetSites[assetSiteIndex].label }}
      </span>

      <span class="costField__input__placeholder" *ngIf="assetSiteIndex === -1">
        {{ 'select_site' | ppTranslate }}
      </span>
    </ng-container>
  </pp-select>

  <div class="verticalLine"></div>

  <pp-select
    class="costField__select"
    [ppItems]="filteredBudgetFields"
    [ppSelectedItem]="workspaceCustomFields[workspaceBudgetIndex]"
    [class.costField__select--emptyError]="budgetFieldError"
    (ppSelectItem)="selectBudgetField($event)"
    [ppNoSearchBar]="true"
    [ppDisabled]="filteredBudgetFields.length === 0"
    ppTooltip
    [ppTitle]="filteredBudgetFields.length === 0 ? ('no_budget_fields' | ppTranslate) : ''"
  >
    <ng-container selected>
      <span
        class="uerSettings__select-content"
        *ngIf="workspaceBudgetIndex !== -1 && !budgetFieldError"
      >
        {{ workspaceCustomFields[workspaceBudgetIndex].label }}
      </span>

      <span
        class="costField__input__placeholder"
        *ngIf="workspaceBudgetIndex === -1 && !budgetFieldError"
      >
        {{ 'select_custom_field' | ppTranslate }}
      </span>

      <span class="costField__input__error" *ngIf="budgetFieldError">
        {{ 'reconfigure' | ppTranslate }}
      </span>
    </ng-container>
  </pp-select>
  <div class="compareArrows">
    <pp-icon
      [ppDisplayContents]="true"
      [ppSrc]="EIconPath.ICON_COMPARE_ARROWS"
      ppColor="inherit"
    ></pp-icon>
  </div>

  <pp-select
    class="costField__select"
    [ppItems]="filteredCostFields"
    [ppSelectedItem]="workspaceCustomFields[workspaceCostIndex]"
    [class.costField__select--emptyError]="costFieldError"
    (ppSelectItem)="selectCostField($event)"
    [ppNoSearchBar]="true"
    [ppDisabled]="filteredCostFields.length === 0"
    ppTooltip
    [ppTitle]="filteredCostFields.length === 0 ? ('no_cost_fields' | ppTranslate) : ''"
  >
    <ng-container selected>
      <span
        class="uerSettings__select-content"
        *ngIf="workspaceCostIndex !== -1 && !costFieldError"
      >
        {{ workspaceCustomFields[workspaceCostIndex].label }}
      </span>

      <span
        class="costField__input__placeholder"
        *ngIf="workspaceCostIndex === -1 && !costFieldError"
      >
        {{ 'select_custom_field' | ppTranslate }}
      </span>

      <span class="costField__input__error" *ngIf="costFieldError">
        {{ 'reconfigure' | ppTranslate }}
      </span>
    </ng-container>
  </pp-select>

  <button class="deleteButton" *ngIf="ppItemRows.length > 1" (click)="deleteRow()">
    <pp-icon [ppSrc]="EIconPath.ICON_BIN_24" ppClass="setSize24" ppColor="red"></pp-icon>
  </button>
</div>
