<div class="pointCF__field">
  <label class="pointCF__label">
    <div class="pointCF__label-image--container">
      <pp-icon [ppSrc]="EIconPath.ICON_FIELDS_DATE_18" ppColor="inherit" ppClass="setSize18" />
    </div>

    <span
      ppTooltip
      class="pointCF__label-container"
      ppTitle="{{ ppFieldLabel }}"
      [ppTrimTooltip]="true"
    >
      {{ ppFieldLabel }}
    </span>
  </label>

  <div class="pointCF__content">
    <pp-datepicker
      [ppShowClearButton]="ppFieldValue && ppCanEdit"
      [ppDate]="ppFieldValue"
      (ppSelect)="updateDate($event)"
      [ppDisabled]="!ppCanEdit"
      [ppUpdating]="updating"
      [ppError]="error"
      (ppSetDatepicker)="setDatepicker($event)"
    ></pp-datepicker>
  </div>
</div>
