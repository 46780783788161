import { Action } from '@ngrx/store';
import { TColumn } from '../site/site-table/columns/column.model';

import { TPreferences, TWorkspacePreferences } from './preferences.model';

export enum EActionType {
  SET_PREFERENCES = '[Preferences] Set',
  SET_WORKSPACE_PREFERENCES = '[Preferences] Set Workspace Preferences',
  CLEAR_PREFERENCES = '[Preferences] Clear',
  SET_UPDATING = '[Preferences] Updating',
  UPDATE_PREFERENCES = '[Preferences] Update preferences',
  UPDATE_WORKSPACE_PREFERENCES = '[Preferences] Update workspace preferences',
  UPDATE_WORKSPACE_EXPORT_PREFERENCES = '[Preferences] Update workspace export preferences',
  UPDATE_OVERVIEW_PREFERENCES = '[Preferences] Update overview preferences',
  UPDATE_WORKSPACE_OFFLINE_PREFERENCES = '[Preferences] Update workspace offline preferences',
}

export class SetPreferences implements Action {
  readonly type = EActionType.SET_PREFERENCES;
  constructor(readonly payload: TPreferences) {}
}

export class SetWorkspacePreferences implements Action {
  readonly type = EActionType.SET_WORKSPACE_PREFERENCES;
  constructor(
    readonly payload: {
      workspaceId: string;
      preferences: TWorkspacePreferences;
    },
  ) {}
}

export class ClearPreferences implements Action {
  readonly type = EActionType.CLEAR_PREFERENCES;
  constructor() {}
}

export class UpdatePreferences implements Action {
  readonly type = EActionType.UPDATE_PREFERENCES;
  constructor(readonly payload: TPreferences) {}
}

export class UpdateWorkspacePreferences implements Action {
  readonly type = EActionType.UPDATE_WORKSPACE_PREFERENCES;
  constructor(
    readonly payload: {
      workspaceId: string;
      preferences: TWorkspacePreferences;
    },
  ) {}
}

export class UpdateWorkspaceExportPreferences implements Action {
  readonly type = EActionType.UPDATE_WORKSPACE_EXPORT_PREFERENCES;
  constructor(
    readonly payload: {
      workspaceId: string;
      preferences: TWorkspacePreferences;
    },
  ) {}
}

export class UpdateOverviewPreferences implements Action {
  readonly type = EActionType.UPDATE_OVERVIEW_PREFERENCES;
  constructor(
    readonly payload: {
      preferences: TColumn[];
    },
  ) {}
}
export class UpdateWorkspaceOfflinePreferences implements Action {
  readonly type = EActionType.UPDATE_WORKSPACE_OFFLINE_PREFERENCES;
  constructor(readonly payload: TPreferences['workspaces']) {}
}

export type ActionsUnion =
  | SetPreferences
  | SetWorkspacePreferences
  | ClearPreferences
  | UpdatePreferences
  | UpdateWorkspacePreferences
  | UpdateOverviewPreferences
  | UpdateWorkspaceOfflinePreferences
  | UpdateWorkspaceExportPreferences;
