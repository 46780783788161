import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import dayjs from 'dayjs';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { transformDate } from '../../../../shared/date-transformer';
import { EDateFormat } from '../../../../shared/enums/date-format.enum';
import { EStore } from '../../../../shared/enums/store.enum';
import { THeader } from '../../../../view-models/response.models';
import { ECustomFieldType } from '../../../custom-fields/custom-field-types-enums';
import { CustomFieldsService } from '../../../custom-fields/custom-fields.service';
import { DEFAULT_DATE_FORMAT } from '../../../preferences/default-date-format';
import { TPreferences } from '../../../preferences/preferences.model';

type TRecordWithHeader = {
  header: THeader;
  [key: string]: unknown;
};

@Injectable({ providedIn: 'root' })
export class ActivityFormatterService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  format: EDateFormat = DEFAULT_DATE_FORMAT;

  preferences$: Observable<TPreferences>;
  preferences: TPreferences;

  constructor(
    private store: Store<{
      preferences: TPreferences;
    }>,
    private customFieldsService: CustomFieldsService,
  ) {
    this.preferences$ = this.store.pipe(select(EStore.PREFERENCES));

    this.preferences$.pipe(takeUntil(this.destroy$)).subscribe((preferences) => {
      this.preferences = preferences;

      this.format = preferences?.dateFormat ? preferences.dateFormat : DEFAULT_DATE_FORMAT;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  getFormattedDatetimeActivities<T>(activities: T[]): T[] {
    // Takes an array of activity or comment objects and adds a new property called 'formattedDatetime'
    // to each activity object
    return activities.map((activity) => this.getFormattedDatetimeActivity(activity));
  }

  getFormattedDatetimeActivity<T>(activity: T): T {
    const formattedActivity = { ...activity } as unknown as TRecordWithHeader;
    const date = dayjs(formattedActivity.header.createdEpochMillis);

    if (
      date.isBetween(dayjs().subtract(1, 'm'), dayjs()) ||
      new Date().getTime() - date.valueOf() <= 0
    ) {
      formattedActivity.header.formattedDatetime = 'Just now';
    } else if (date.isBetween(dayjs().subtract(7, 'd'), dayjs())) {
      formattedActivity.header.formattedDatetime = date.fromNow();
    } else {
      formattedActivity.header.formattedDatetime = transformDate({
        value: date,
        inputHourFormat: ' [at] hh:mm a',
        format: this.format,
        localTime: false,
      });
    }

    formattedActivity.header.timeStampHover = transformDate({
      value: date,
      inputHourFormat: ' [at] hh:mm a',
      format: this.format,
      localTime: false,
    });

    return formattedActivity as unknown as T;
  }

  compareDescriptions(activity): void {
    if (activity.data.changeList) {
      const description = activity.data.changeList.find(
        (change) => change.propName === 'description',
      );
      const descriptionRich = activity.data.changeList.find(
        (change) => change.propName === 'descriptionRich',
      );

      if (
        description &&
        descriptionRich &&
        typeof descriptionRich.oldValue !== 'undefined' &&
        typeof descriptionRich.newValue !== 'undefined'
      ) {
        activity.data.changeList = activity.data.changeList.filter(
          (change) => change.propName !== 'description',
        );
      }
    }
  }

  formatRichText(activity) {
    const customFields = this.customFieldsService.getCustomFields();

    if (activity.data?.changeList) {
      activity.data.changeList.forEach((change) => {
        const matchingCF = customFields?.[activity.workspaceRef.id]?.[change.cfTemplateId];

        if (
          (matchingCF && matchingCF.type === ECustomFieldType.RICHTEXT) ||
          change.cfFieldType === ECustomFieldType.RICHTEXT
        ) {
          change.richText = true;
        }
      });
    }
  }

  formatTags(activity) {
    if (activity.data?.changeList) {
      activity.data.changeList.forEach((change) => {
        if (change.propName === 'tags') {
          const newTags = [];
          const removedTags = [];

          change.newValue.forEach((tag) => {
            if (!change.oldValue.includes(tag)) {
              newTags.push(tag);
            }
          });

          change.oldValue.forEach((tag) => {
            if (!change.newValue.includes(tag)) {
              removedTags.push(tag);
            }
          });

          change.addedTags = newTags;
          change.removedTags = removedTags;
        }
      });
    }
  }

  formatFiles(activity) {
    if (activity.data.changeList) {
      activity.data.changeList.forEach((change) => {
        if (change.propName === 'documents') {
          change.addedFiles = [];

          change.newValue.forEach((value) => {
            const foundIndex = change.oldValue.find((oldValue) => oldValue.id === value.id);

            if (!foundIndex) {
              change.addedFiles.push(value);
            }
          });
        }
      });
    }
  }
}
