<pp-modal [ppWide]="true" [ppNoPadding]="true">
  <ng-container heading> {{ headerText }} </ng-container>

  <ng-container body>
    <div class="newDashletCostFieldsWrapper">
      <div class="newDashletCostFieldsWrapper__back" (click)="goBack()" *ngIf="ppNewDashlet">
        <pp-icon
          [ppSrc]="EIconPath.ICON_LEFT_MENU_ARROW_BACK_18"
          ppColor="inherit"
          ppClass="setSize18"
        ></pp-icon>

        <span> {{ 'back_to_dashlets' | ppTranslate }}</span>
      </div>

      <pp-error-box *ngIf="errorMessages.length > 0" [ppErrors]="errorMessages"></pp-error-box>

      <table class="newDashletCostFieldsTableWrapper">
        <thead class="newDashletCostFieldsTableHeader">
          <tr class="newDashletCostFieldsTableHeader__title">
            <th class="newDashletCostFieldsTableHeader__title--inner">
              {{ 'site_name' | ppTranslate }}
            </th>
          </tr>

          <tr class="newDashletCostFieldsTableHeader__cost">
            {{
              'cost_comparison_budget' | ppTranslate
            }}

            <pp-info-icon
              ppClass="setSize14"
              ppTooltip
              [ppOptions]="{ placement: 'right' }"
              [ppMobileTooltip]="true"
              [ppSanitize]="false"
              [ppTitle]="'cost_comparison_budget_description' | ppTranslate"
            ></pp-info-icon>
          </tr>

          <tr class="newDashletCostFieldsTableHeader__cost">
            {{
              'cost_comparison_cost' | ppTranslate
            }}

            <pp-info-icon
              ppClass="setSize14"
              ppTooltip
              [ppOptions]="{ placement: 'right' }"
              [ppMobileTooltip]="true"
              [ppSanitize]="false"
              [ppTitle]="'cost_comparison_cost_description' | ppTranslate"
            ></pp-info-icon>
          </tr>
        </thead>

        <pp-new-dashlet-modal-cost-field
          [ppAssetId]="ppAssetId"
          *ngFor="let row of itemRows; let index = index"
          [ppData]="row"
          [ppItemRows]="itemRows"
          (ppDeleteRow)="deleteRow(index)"
          (ppSiteRowChange)="siteRowChange($event, index)"
        ></pp-new-dashlet-modal-cost-field>

        <button
          class="newDashletCostFieldsNew"
          [class.newDashletCostFieldsNew--disabled]="itemRows.length >= 5"
          (click)="addRow()"
          ppTooltip
          [ppTitle]="itemRows.length >= 5 ? ('cost_comparison_add_row_disabled' | ppTranslate) : ''"
        >
          {{ 'add_budget' | ppTranslate }}
        </button>
      </table>
    </div>
  </ng-container>

  <ng-container footer>
    <pp-button-modal-cancel *ngIf="ppNewDashlet" (ppAction)="hideModal()">
      {{ 'cancel' | ppTranslate }}
    </pp-button-modal-cancel>

    <button class="deleteDashletButton" *ngIf="!ppNewDashlet" (click)="deleteDashlet()">
      <pp-icon [ppSrc]="EIconPath.ICON_BIN_14" ppClass="setSize14" ppColor="inherit"></pp-icon>

      {{ 'delete_dashlet' | ppTranslate }}
    </button>

    <pp-button-modal-confirm ppTooltip (ppAction)="addDashlet()" [ppDisabled]="isDisabled">
      {{ confirmButtonText }}
    </pp-button-modal-confirm>
  </ng-container>
</pp-modal>
