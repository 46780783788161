import { ApiService } from '@core/http';
import { createElement } from 'src/app/core/helpers/dom';
import { GET_COLUMNS } from '../../../columns/columns.store';
import { GET_TABLE } from '../../../table.ui.store';
import { createHeaderCellTypeElement } from './create-header-cell-type-element';
import { createHeaderSelectElement } from './create-header-select-element';

export function createHeaderRowElement(apiService: ApiService): HTMLElement {
  const table = GET_TABLE();

  return createElement<HTMLElement>('div', {
    attrs: {
      class: 'table__head__row',
      style: {
        width: table.width,
      },
    },
    eventListeners: {
      wheel: (_event) => {
        const newLeft = table.tableHead.element.scrollLeft - _event.wheelDeltaX;

        table.tableBody.virtualScroller.disableSmoothScrolling();
        table.tableHead.scrollLeft(newLeft);
        table.tableFooter.scrollLeft(newLeft);
        table.tableBody.virtualScroller.scrollLeft(newLeft);
        table.tableBody.virtualScroller.enableSmoothScrolling();
      },
    },
    children: [
      createHeaderSelectElement(),
      ...GET_COLUMNS().flatMap((_column) => {
        if (!_column.hidden) {
          return createHeaderCellTypeElement(_column, apiService);
        }

        return '';
      }),
    ],
  });
}
