import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createElement } from 'src/app/core/helpers/dom';
import { html2image } from 'src/app/project/modules/external/html2image';
import { EDashletType } from '../../dashlets-enums';
import { EExportDashletType } from './export-dashlet-types-enum';

export type TDashboardImageElementData = {
  type: EExportDashletType;
  base64: string;
};

export function generateImage({
  element,
  width,
  height,
  wrapperWidth,
  borderRadius,
  wrapperHeight,
  chartType,
  scale = 1,
}): Observable<TDashboardImageElementData> {
  const imageElement = createElement('div');

  const dashletType =
    chartType === EDashletType.CURRENT_STATUS ||
    chartType === EDashletType.CURRENT_PRIORITY ||
    chartType === EDashletType.OVER_TIME_STATUS ||
    chartType === EDashletType.OVER_TIME_PRIORITY ||
    chartType === EDashletType.COST_COMPARISON ||
    chartType === EDashletType.CURRENT_STACKED_STATUS
      ? EExportDashletType.ADVANCED
      : EExportDashletType.SIMPLE;

  return from(
    html2image({
      element,
      width: width,
      height: height,
      wrapperWidth: wrapperWidth,
      wrapperHeight: wrapperHeight,
      borderRadius: borderRadius,
      scale,
    }),
  ).pipe(
    map((canvas: HTMLCanvasElement) => {
      imageElement.style.width = wrapperWidth * scale + 'px';
      imageElement.style.height = wrapperHeight * scale + 'px';
      imageElement.innerHTML = canvas;

      return {
        type: dashletType,
        base64: canvas.toDataURL().replace('data:image/png;base64,', ''), // backend needs base64 string without this prefix
      };
    }),
  );
}
