import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DigitInputComponent } from './digit-input.component';
import { DigitComponent } from './digit/digit.component';

@NgModule({
  declarations: [DigitInputComponent, DigitComponent],
  imports: [CommonModule, FormsModule],
  exports: [DigitInputComponent],
})
export class DigitInputModule {}
