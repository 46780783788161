import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PointModalService {
  private scrollPosition = 0;
  private pointBody: ElementRef;

  constructor() {}

  setScrollPosition(position: number): void {
    this.scrollPosition = position;
  }

  updateScrollPosition(position: number): void {
    if (this.pointBody) {
      // Rich text in notifications doesn't have point body
      this.pointBody.nativeElement.scrollTop = position;
    }
  }

  getScrollPosition(): number {
    return this.scrollPosition;
  }

  setPointBody(body: ElementRef): void {
    this.pointBody = body;
  }
}
