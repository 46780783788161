import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { IconModule } from '../../icon/icon-module';
import { ToggleModule } from '../../toggle/toggle.module';
import { HideDropdownModule } from '../hide-dropdown/hide-dropdown.module';
import { SelectMultipleDropdownComponent } from './select-multiple-dropdown/select-multiple-dropdown.component';
import { SelectMultipleComponent } from './select-multiple.component';

@NgModule({
  declarations: [SelectMultipleDropdownComponent, SelectMultipleComponent],
  imports: [CommonModule, ToggleModule, PipesModule, IconModule, HideDropdownModule],
  exports: [SelectMultipleComponent],
})
export class SelectMultipleModule {}
