<div *ngIf="ppLabels.length === 0" class="assetCard__noLabels assetCard__noLabelsText">
  {{ 'asset_no_labels' | ppTranslate }}
</div>

<div *ngIf="ppLabels.length > 0" class="assetCard__labelContainer">
  <pp-asset-card-information-label
    *ngFor="let label of ppLabels"
    [ppLabel]="label"
  ></pp-asset-card-information-label>
</div>
