import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'pp-double-select',
  templateUrl: './double-select.component.html',
  styleUrls: ['./double-select.component.scss'],
})
export class DoubleSelectComponent implements AfterViewInit {
  @Input() ppFirstOption = '';
  @Input() ppSecondOption = '';
  @Input() ppSelected = 0;
  @Input() ppFirstDataTarget: string;
  @Input() ppSecondDataTarget: string;
  @Input() ppNoBottomBorder = false;

  @Output() ppFirstChoice = new EventEmitter<void>();
  @Output() ppSecondChoice = new EventEmitter<void>();

  @ViewChild('firstDoubleSelectOption') firstDoubleSelectOptionElement: ElementRef;
  @ViewChild('secondDoubleSelectOption') secondDoubleSelectOptionElement: ElementRef;

  constructor() {}

  ngAfterViewInit() {
    if (this.ppFirstDataTarget) {
      this.firstDoubleSelectOptionElement.nativeElement.setAttribute(
        'data-m-target',
        this.ppFirstDataTarget,
      );
    }

    if (this.ppSecondDataTarget) {
      this.secondDoubleSelectOptionElement.nativeElement.setAttribute(
        'data-m-target',
        this.ppSecondDataTarget,
      );
    }
  }

  triggerFirstOption(): void {
    this.ppFirstChoice.emit();
  }

  triggerSecondOption(): void {
    this.ppSecondChoice.emit();
  }
}
