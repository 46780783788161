import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioComponent } from './radio.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [RadioComponent],
  imports: [CommonModule, PipesModule],
  exports: [RadioComponent],
})
export class RadioModule {}
