<pp-modal>
  <ng-container heading>
    {{ 'change_status' | ppTranslate }}
  </ng-container>

  <ng-container body>
    <p class="modal__body-title">{{ 'status' | ppTranslate }}</p>

    <div class="bulkStatus__radio-wrapper" *ngFor="let status of statuses">
      <pp-radio
        [ppChecked]="status.value === selectedStatus"
        (ppOnValueChange)="toggleStatus(status)"
        ppStyle="blue"
      >
        <label class="control-custom control-custom--radio status__wrapper">
          <pp-status-button (ppAction)="toggleStatus(status)" [ppStatus]="status.value">
          </pp-status-button>
        </label>
      </pp-radio>
    </div>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'save' | ppTranslate"
      (ppCancel)="hideModal()"
      (ppConfirm)="save()"
      [ppDisabled]="!selectedStatus"
      [ppProcess]="processing"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
