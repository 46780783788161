import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PromptService } from '../../components/prompt/prompt.service';
import { TranslationPipe } from '../../features/translate/translation.pipe';
import { UserService } from '../user/user.service';
import { WorkspaceService } from '../workspace/workspace.service';

@Injectable({
  providedIn: 'root',
})
export class SiteRedirectService {
  constructor(
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private router: Router,
    private userService: UserService,
    private workspaceService: WorkspaceService,
  ) {}

  redirectToSites(): void {
    const prompt = this.translationPipe.transform('access_denied_redirect');
    const newRoute = this.findNewRoute();

    this.promptService.showWarning(prompt);
    this.router.navigate(newRoute);
  }

  findNewRoute(): string[] {
    const user = this.userService.getUser();
    const workspaces = this.workspaceService.getWorkspaces();

    if (workspaces[user.activeWorkspaceId]) {
      return ['/site', user.activeWorkspaceId];
    }

    if (Object.keys(workspaces).length > 0) {
      return ['/site', Object.keys(workspaces)[0]];
    }

    return ['/settings', 'user'];
  }
}
