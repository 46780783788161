import { TCustomField } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { generateDefaultColumns, generateDefaultOverviewColumns } from '../../../columns/columns';
import { GET_COLUMNS } from '../../../columns/columns.store';

export function checkColumnsToDelete(customFields: TCustomField[], isOverview: boolean): string[] {
  const columnsToDelete = [];
  let defaultColumns = null;

  if (isOverview) {
    defaultColumns = generateDefaultOverviewColumns();
  } else {
    defaultColumns = generateDefaultColumns();
  }

  GET_COLUMNS().forEach((column) => {
    const customField = customFields.find(
      (searchedCustomField) => searchedCustomField.label === column.name,
    );

    if (customField?.display === false) {
      columnsToDelete.push(column.name);
    } else if (
      !customField &&
      !defaultColumns.find((defaultColumn) => defaultColumn.name === column.name)
    ) {
      columnsToDelete.push(column.name);
    }
  });

  return columnsToDelete;
}
