import { TPoint } from '../../../points/points.model';
import { TColumn } from '../columns/column.model';

export const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});

export function sortTagsAlphabetically(
  firstPoint: TPoint,
  secondPoint: TPoint,
  sortColumns: Partial<TColumn>[],
  index: number,
  _tagIndex: number,
): number {
  const values = {
    a: firstPoint.tags[_tagIndex].trim(),
    b: secondPoint.tags[_tagIndex].trim(),
  };

  if (values.a === '' && values.b !== '') {
    return 1;
  }

  if (values.a !== '' && values.b === '') {
    return -1;
  }

  if (typeof values.a !== 'string' && typeof values.b === 'string') {
    return 1;
  } else if (typeof values.a === 'string' && typeof values.b !== 'string') {
    return -1;
  } else if (typeof values.a !== 'string' && typeof values.b !== 'string') {
    return 0;
  }

  const result = collator.compare(values.a.toLowerCase(), values.b.toLowerCase());

  if (result < 0) {
    return sortColumns[index].sortOrder === 'ASC' ? -1 : 1;
  } else if (result > 0) {
    return sortColumns[index].sortOrder === 'ASC' ? 1 : -1;
  }

  return 0;
}
