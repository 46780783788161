import VirtualScroller from 'src/app/project/modules/external/virtual-scroller/VirtualScroller';
import CreateSite from '../create-site/CreateSite';
import { TFlattenedAccount } from './account-list-flatten-account';
import { TAnyFunction } from '@core/helpers';
import { ApiService } from '@core/http';

export type TAddCreateSiteElementParams = {
  _index: number;
  accountsFlattened: TFlattenedAccount[];
  virtualScroller: VirtualScroller;
  apiService: ApiService;
  createSiteCallback: TAnyFunction;
  removeSiteFromFolderCallback: TAnyFunction;
  dragoverCallback: TAnyFunction;
};

export const addCreateSiteElement = ({
  _index,
  accountsFlattened,
  virtualScroller,
  apiService,
  createSiteCallback,
  removeSiteFromFolderCallback,
  dragoverCallback,
}: TAddCreateSiteElementParams): HTMLElement => {
  let data = accountsFlattened[_index];

  if (virtualScroller) {
    data = virtualScroller.data[_index];
  }

  return new CreateSite(data, apiService, {
    createSiteCallback: createSiteCallback,
    removeSiteFromFolderCallback: removeSiteFromFolderCallback,
    dragoverCallback: (folderId: string): void => dragoverCallback(folderId),
  }).element;
};
