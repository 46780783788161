import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToggleComponent } from './toggle.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [ToggleComponent],
  imports: [CommonModule, PipesModule],
  exports: [ToggleComponent],
})
export class ToggleModule {}
