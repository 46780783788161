import { Component, Input, OnInit } from '@angular/core';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { EBasicField } from 'src/app/project/shared/enums/basic-fields-enums';
import { EIconPath } from '../../../../../shared/enums/icons.enum';
import { TFieldConflict } from '../../site-options.model';

@Component({
  selector: 'pp-field-conflict-list',
  templateUrl: './field-conflict-list.component.html',
  styleUrls: ['./field-conflict-list.component.scss'],
})
export class FieldConflictListComponent implements OnInit {
  @Input() ppPointPinsToBeCentered: boolean;
  @Input() ppSelectedWorkspaceName: string;
  @Input() ppFieldConflicts: TFieldConflict[];

  ECustomFieldType = ECustomFieldType;
  EBasicField = EBasicField;
  EIconPath = EIconPath;

  constructor() {}

  ngOnInit(): void {}
}
