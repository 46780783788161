import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { clone, cloneDeep } from 'lodash';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, of } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { TUser } from 'src/app/project/modules/user/user.model';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { UpdatePointPriority, UpdatePointStatus } from '../../points.actions';
import { TPoint, TPointsByWorkspace } from '../../points.model';

import { PointPriorityDropdownComponent } from '../../point-priority/point-priority-dropdown/point-priority-dropdown.component';
import { PointStatusDropdownComponent } from '../../point-status/point-status-dropdown/point-status-dropdown.component';

import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { DropdownService } from '../../../../components/dropdown/dropdown-service/dropdown.service';
import { PlanPointService } from '../../../plan/plan-point/plan-point.service';
import { PointsHelperService } from '../../points-helper.service';
import { PointsUpdateService } from '../../points-update.service';
import { PointsService } from '../../points.service';
import { PointActivityService } from '../point-timeline/point-activity.service';

import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { GET_PLAN_COMPONENT } from 'src/app/project/modules/plan/plan.store';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { NEW_POINT_ID } from 'src/app/project/shared/constants/point.const';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { EStatus } from '../../../../shared/enums/status.enum';
import { CustomTableService } from '../../../site/site-table/custom-table/custom-table.service';
import { EPriority } from '../../priorities';
import { EActionBarUpdateSource } from './point-action-bar-update-source.enum';

@Component({
  selector: 'pp-point-action-bar',
  templateUrl: './point-action-bar.component.html',
  styleUrls: ['./point-action-bar.component.scss'],
})
export class PointActionBarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() ppPointId: string;
  @Input() ppWorkspaceId: string;
  @Input() ppNew: boolean;
  @Input() ppCanEdit: boolean;
  @Input() ppCanDelete: boolean;
  @Input() ppFull: boolean;
  @Input() readonly ppUser: TUser = {};
  @Input() ppScrollPosition: number;

  private readonly destroy$ = new Subject<void>();
  private dropdown: TDropdown = this.dropdownService.getDropdown();

  private points$: Observable<TPointsByWorkspace>;
  offline$: Observable<boolean>;
  offline = false;
  NEW_POINT_ID = NEW_POINT_ID;

  updatingStatus = false;
  updatingStatusCheckbox = false;
  updatingPriority = false;
  point: TPoint;
  EIconPath = EIconPath;

  workspace: TWorkspace;

  constructor(
    private store: Store<{ offline: boolean; points: TPointsByWorkspace }>,
    private pointsService: PointsService,
    private dropdownService: DropdownService,
    private pointActivityService: PointActivityService,
    private planPointService: PlanPointService,
    private sitePointFilterService: SitePointFilterService,
    private promptService: PromptService,
    private pointsHelperService: PointsHelperService,
    private pointsUpdateService: PointsUpdateService,
    private translationPipe: TranslationPipe,
    private workspaceService: WorkspaceService,
    private customTableService: CustomTableService,
  ) {
    this.offline$ = this.store.pipe(select(EStore.OFFLINE));
    this.points$ = this.store.pipe(select(EStore.POINTS));
  }

  ngOnInit() {
    this.offline$.pipe(takeUntil(this.destroy$)).subscribe((offline) => {
      this.offline = offline;
    });

    this.points$.pipe(takeUntil(this.destroy$)).subscribe((points) => {
      if (this.ppWorkspaceId && points[this.ppWorkspaceId]) {
        // entirely possible to open modal when points aren't done fetching
        const point = points[this.ppWorkspaceId].entities.find(
          (searchedPoint) => searchedPoint._id === this.ppPointId,
        );

        this.initPoint(cloneDeep(point));
      }
    });

    this.initPoint(this.pointsService.findPoint(this.ppPointId));
  }

  ngOnChanges() {
    this.initPoint(cloneDeep(this.pointsService.findPoint(this.ppPointId)));
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  // Status

  toggleCloseStatus(): void {
    if (this.ppCanEdit) {
      if (this.point?.status === EStatus.CLOSED) {
        this.updateStatus(EStatus.OPEN, EActionBarUpdateSource.TOGGLE);
      } else {
        this.updateStatus(EStatus.CLOSED, EActionBarUpdateSource.TOGGLE);
      }
    }
  }

  showStatusDropdown(buttonId: string): void {
    if (this.ppCanEdit && !this.offline) {
      if (this.dropdown.visible && this.dropdown.buttonId === buttonId) {
        this.dropdownService.hideDropdown();
      } else {
        this.dropdownService.showDropdown(buttonId, PointStatusDropdownComponent, {
          callback: (status) => this.updateStatus(status, EActionBarUpdateSource.DROPDOWN),
        });
      }
    }
  }

  updateStatus(status: EStatus, updateSource: EActionBarUpdateSource): void {
    const _id = clone(this.ppPointId);

    if (this.updatingStatusCheckbox || this.updatingStatus) {
      return;
    }

    this.point.status = status;

    if (status === EStatus.CLOSED) {
      logEventInGTAG(EGoogleEventSite.SITE__POINT__COMPLETED, {
        event_category: EGoogleEventCategory.SITE,
      });
    }

    if (this.ppNew) {
      this.updateStatusForNewPoint(status, _id);

      return;
    }

    if (updateSource === 'dropdown') {
      this.updatingStatus = true;
    } else {
      this.updatingStatusCheckbox = true;
    }

    this.pointsUpdateService
      .updatePointField(_id, { status })
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          if (updateSource === 'dropdown') {
            this.updatingStatus = false;
          } else {
            this.updatingStatusCheckbox = false;
          }

          const promptText = this.translationPipe.transform('prompt_point_status_update');
          this.promptService.showSuccess(promptText);
          this.pointActivityService.refreshTimeline(this.ppWorkspaceId, _id);
          this.sitePointFilterService.filterPoints({ _keepScrollPosition: true });
        }),
        catchError((error) => {
          let promptText = this.translationPipe.transform('prompt_changes_error');

          this.updatingStatus = false;
          this.updatingStatusCheckbox = false;

          if (error.status === EStatusCode.FORBIDDEN) {
            promptText = this.translationPipe.transform('prompt_changes_permission_denied');
          }

          this.promptService.showError(promptText);
          return of();
        }),
      )
      .subscribe();
  }

  // Priority

  priorityToColor(priority: EPriority): string {
    if (priority) {
      return this.pointsHelperService.priorityToColor(priority);
    }
  }

  showPriorityDropdown(buttonId: string): void {
    if (this.ppCanEdit && !this.offline) {
      if (this.dropdown.visible && this.dropdown.buttonId === buttonId) {
        this.dropdownService.hideDropdown();
      } else {
        this.dropdownService.showDropdown(buttonId, PointPriorityDropdownComponent, {
          callback: (priority) => this.updatePriority(priority),
        });
      }
    }
  }

  updatePriority(priority: EPriority): void {
    const _id = this.ppPointId;

    if (this.ppNew) {
      this.updatePriorityForNewPoint(priority, this.ppPointId);

      return;
    }

    this.updatingPriority = true;

    this.pointsUpdateService
      .updatePointField(_id, { priority })
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          this.point.priority = priority;

          const promptText = this.translationPipe.transform('prompt_point_priority_update');

          this.promptService.showSuccess(promptText);
          this.pointActivityService.refreshTimeline(this.ppWorkspaceId, _id);

          this.updateSitePlan();
          this.sitePointFilterService.filterPoints({ _keepScrollPosition: true });
        }),
        catchError((error) => {
          let promptText = this.translationPipe.transform('prompt_changes_error');

          if (error.status === EStatusCode.FORBIDDEN) {
            promptText = this.translationPipe.transform('prompt_changes_permission_denied');
          } else {
            logErrorInSentry(error);
          }

          this.promptService.showError(promptText);

          return of();
        }),
        finalize(() => {
          this.updatingPriority = false;
        }),
      )
      .subscribe();
  }

  updateSitePlan(): void {
    const plan = GET_PLAN_COMPONENT();

    this.planPointService.updateFeature(this.ppPointId, this.ppWorkspaceId, true);
    this.sitePointFilterService.filterPoints();

    if (plan) {
      plan.createPlanForPoint();
    }
  }

  initPoint(point: TPoint): void {
    this.point = point;

    const workspaces = this.workspaceService.getWorkspaces();

    this.workspace = this.point ? workspaces[this.point.workspaceRef.id] : null;
  }

  private updateStatusForNewPoint(status: EStatus, pointId: string): void {
    this.store.dispatch(
      new UpdatePointStatus({
        workspaceId: this.ppWorkspaceId,
        _id: pointId,
        status,
      }),
    );
  }

  private updatePriorityForNewPoint(priority: EPriority, pointId: string): void {
    this.store.dispatch(
      new UpdatePointPriority({
        workspaceId: this.ppWorkspaceId,
        _id: pointId,
        priority,
      }),
    );

    this.point.priority = priority;

    this.updateSitePlan();
  }
}
