import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TTagSelectOptions } from 'src/app/project/modules/tags/tag-select/tag-select.component';
import { EGoogleEventLabel } from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TCustomFieldAccessOption, TShare } from '../../../share.model';
import { EAccessParameter } from '../access-parameters';
import { ShareEditService } from '../share-edit.service';

@Component({
  selector: 'pp-share-edit-tags',
  templateUrl: './share-edit-tags.component.html',
  styleUrls: ['./share-edit-tags.component.scss'],
})
export class ShareEditTagsComponent {
  @Input() ppShare: TShare;
  @Input() ppWorkspaceId: string;
  @Input() ppTagError: boolean;
  @Output() ppChange = new EventEmitter<void>();
  @Output() ppShareChange = new EventEmitter<TShare>();

  EIconPath = EIconPath;
  EGoogleEventLabel = EGoogleEventLabel;
  accessParameters = EAccessParameter;
  tagSelectOptions: TTagSelectOptions;

  constructor(private shareEditService: ShareEditService) {}

  ngOnChanges(): void {
    this.setTagSelectOptions();
  }

  private setTagSelectOptions(): void {
    this.tagSelectOptions = {
      showIcon: true,
      noTagRemovedDebounceTime: true,
      hasError: this.ppShare.defectTags.length === 0 && this.ppShare.tagLimited && this.ppTagError,
    };
  }

  correctProperty(
    property: EGoogleEventLabel,
    parameter?: EAccessParameter,
    field?: TCustomFieldAccessOption,
  ): void {
    this.ppShare = this.shareEditService.correctProperty({
      workspaceId: this.ppWorkspaceId,
      share: this.ppShare,
      property,
      parameter,
      field,
    });

    this.ppChange.emit();
  }

  updateLimitTags(): void {
    this.ppShare.tagLimited = !this.ppShare.tagLimited;

    this.ppShare = this.shareEditService.clearTags(this.ppShare, this.ppWorkspaceId);
    this.ppShareChange.emit(this.ppShare);
    this.ppChange.emit();
  }

  updateTags(tags: string[]): void {
    this.ppShare.defectTags = tags.slice();

    this.ppShareChange.emit(this.ppShare);
    this.ppChange.emit();
  }
}
