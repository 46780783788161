import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GET_ACTIVE_PLAN } from 'src/app/project/modules/plan/plan.store';
import { RemindersUtilityService } from 'src/app/project/modules/reminders/reminders/reminders-utility.service';
import Table from 'src/app/project/modules/site/site-table/custom-table/table/Table';
import Timeline from 'src/app/project/modules/site/site-timeline/Timeline';
import { GET_TIMELINE } from 'src/app/project/modules/site/site-timeline/timeline.ui.store';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { CustomTableService } from '../../site/site-table/custom-table/custom-table.service';
import { TPoint } from '../points.model';
import { PointSwitchService } from './point-switch.service';

@Injectable({
  providedIn: 'root',
})
export class PointModalHeaderCollapsedService {
  constructor(
    private router: Router,
    private remindersUtilityService: RemindersUtilityService,
    private pointSwitchService: PointSwitchService,
    private customTableService: CustomTableService,
  ) {}

  nextPointCollapsed({
    attachmentUploading,
    point,
    _id,
    isReminders,
    isTimeline,
    isOverview,
  }: {
    attachmentUploading: boolean;
    point: TPoint;
    _id: string;
    isReminders: boolean;
    isTimeline: boolean;
    isOverview: boolean;
  }): void {
    const activePlan = GET_ACTIVE_PLAN();

    if (!attachmentUploading && !activePlan.active) {
      if (isReminders) {
        const nextPointId = this.remindersUtilityService.getIdOfNextReminderPoint(_id);

        this.router.navigate(['/reminders', 'point', nextPointId]);
      } else {
        const table: Timeline | Table = isTimeline
          ? GET_TIMELINE()
          : this.customTableService.getTable();
        const nextPoint: TPoint = this.pointSwitchService.getNextPoint(point, table, _id);

        if (nextPoint?._id) {
          if (table instanceof Table && table.tableBody) {
            table.activatePoint(nextPoint.sequenceNumber);
          }

          if (isTimeline) {
            this.router.navigate(['/site/timeline', 'point', nextPoint._id]);
          } else if (isOverview) {
            this.router.navigate(['/site/overview', 'point', nextPoint._id]);
          } else {
            this.router.navigate(['/site', point.workspaceRef.id, 'point', nextPoint._id]);
          }
        }
      }

      logEventInGTAG(EGoogleEventSite.SITE__POINT__NEXT, {
        event_category: EGoogleEventCategory.SITE,
      });
    }
  }

  prevPointCollapsed({
    attachmentUploading,
    point,
    _id,
    isReminders,
    isTimeline,
    isOverview,
  }: {
    attachmentUploading: boolean;
    point: TPoint;
    _id: string;
    isReminders: boolean;
    isTimeline: boolean;
    isOverview: boolean;
  }): void {
    const activePlan = GET_ACTIVE_PLAN();

    if (!attachmentUploading && !activePlan.active) {
      if (isReminders) {
        const previousPointId = this.remindersUtilityService.getIdOfPreviousReminderPoint(_id);

        this.router.navigate(['/reminders', 'point', previousPointId]);
      } else {
        const table: Timeline | Table = isTimeline
          ? GET_TIMELINE()
          : this.customTableService.getTable();
        const prevPoint: TPoint = this.pointSwitchService.getPreviousPoint(point, table, _id);

        if (prevPoint) {
          const prevPointId = prevPoint._id;

          if (table instanceof Table && table.tableBody) {
            table.activatePoint(prevPoint.sequenceNumber);
          }

          if (isTimeline) {
            this.router.navigate(['/site/timeline', 'point', prevPointId]);
          } else if (isOverview) {
            this.router.navigate(['/site/overview', 'point', prevPointId]);
          } else {
            this.router.navigate(['/site', point.workspaceRef.id, 'point', prevPointId]);
          }
        }
      }

      logEventInGTAG(EGoogleEventSite.SITE__POINT__PREVIOUS, {
        event_category: EGoogleEventCategory.SITE,
      });
    }
  }
}
