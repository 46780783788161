import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-new-asset-label',
  templateUrl: './new-asset-label.component.html',
  styleUrls: ['./new-asset-label.component.scss'],
})
export class NewAssetLabelComponent {
  @Input() ppColor: string;
  @Output() ppColorChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() ppName: string;
  @Output() ppNameChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() ppAssigneeId: string;
  @Output() ppAssigneeIdChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() ppLabelChange = new EventEmitter<void>();
  @Output() ppDeleteLabel = new EventEmitter<void>();

  EIconPath = EIconPath;

  updateName(name: string): void {
    this.ppName = name;
    this.ppNameChange.emit(this.ppName);

    this.updateLabel();
  }

  updateColor(color: string): void {
    this.ppColor = color;
    this.ppColorChange.emit(this.ppColor);

    this.updateLabel();
  }

  updateAssignee(userId: string): void {
    this.ppAssigneeId = userId;
    this.ppAssigneeIdChange.emit(this.ppAssigneeId);
    this.updateLabel();
  }

  updateLabel(): void {
    this.ppLabelChange.emit();
  }

  deleteLabel(): void {
    this.ppDeleteLabel.emit();
  }
}
