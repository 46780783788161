import { TFilters } from '../site-filter.model';

export function compareFilterShowPointsWithLocation(
  firstFilter: TFilters,
  secondFilter: TFilters,
): boolean {
  const location1 = firstFilter.showPointsWithLocation;
  const location2 = secondFilter.showPointsWithLocation;

  return location1 === location2;
}
