<article class="point__container" *ngIf="modal.visible">
  <div class="point__backdrop">
    <div class="point__animation" *ngIf="hasWorkspaces && point">
      <pp-point-modal-header
        [ppAttachmentUploading]="attachmentUploading"
        [ppFull]="false"
        [ppPoint]="point"
        [ppPointId]="_id"
        [ppWorkspaceId]="workspaceId"
        [(ppPointDetails)]="pointDetails"
        (ppHide)="hide()"
        [ppNew]="false"
        [ppDetailsFetched]="detailsFetched"
        (ppUpdate)="generatePoint(point)"
      ></pp-point-modal-header>

      <pp-point-container
        [ppPointId]="point?._id"
        (click)="$event.stopPropagation()"
        (ppUpdateScrollPosition)="updateScrollPosition($event)"
      >
        <ng-container header>
          <header>
            <pp-point-action-bar
              [ppPointId]="point?._id"
              [ppWorkspaceId]="point?.workspaceRef.id"
              [ppCanEdit]="canEditPoint"
              [ppCanDelete]="canDeletePoint"
              [ppUser]="user"
              [ppScrollPosition]="scrollPosition"
            ></pp-point-action-bar>
          </header>
        </ng-container>

        <ng-container body>
          <section>
            <pp-point-description
              *ngIf="point"
              [ppPointId]="point?._id"
              [ppWorkspaceId]="point?.workspaceRef.id"
              [ppDescription]="point?.description"
              [ppDescriptionRich]="point?.descriptionRich"
              [ppCanEdit]="canEditPoint"
            ></pp-point-description>
          </section>

          <section class="point__custom-fields">
            <pp-point-fields
              *ngIf="point"
              [ppPointId]="point?._id"
              [ppWorkspaceId]="point?.workspaceRef.id"
              [ppTags]="point?.tags"
              [ppCanEdit]="canEditPoint"
              [ppAssignees]="point?.assignees"
            ></pp-point-fields>
          </section>

          <section>
            <pp-point-plan
              *ngIf="point"
              [ppPointId]="point?._id"
              [ppWorkspaceId]="point?.workspaceRef.id"
              [ppCanEdit]="canEditPoint"
              [ppWidth]="planWidth"
            ></pp-point-plan>
          </section>

          <section>
            <pp-point-attachments
              *ngIf="!offline && point"
              [ppWorkspaceId]="point?.workspaceRef.id"
              [ppPointId]="point?._id"
              [ppCanEdit]="canEditPoint"
            ></pp-point-attachments>

            <p class="point__section--disabled" *ngIf="offline">
              {{ 'attachments_not_available_offline' | ppTranslate }}
            </p>
          </section>

          <section>
            <pp-point-activity
              *ngIf="!offline && point"
              [ppWorkspaceId]="point?.workspaceRef.id"
              [ppPointId]="point?._id"
              [ppCommentsEnabled]="commentsEditable && !offline && !!point"
              [ppAttachmentUploading]="attachmentUploading"
              [ppDetailsFetched]="freshPointFetched"
              (ppScrollToComment)="setInitialScroll()"
            ></pp-point-activity>

            <p class="point__section--disabled" *ngIf="offline">
              {{ 'activity_not_available_offline' | ppTranslate }}
            </p>
          </section>
        </ng-container>

        <ng-container footer>
          <div
            data-m-target="Point comment input field"
            class="point__comment-input"
            *ngIf="commentsEditable && !offline && point"
          >
            <pp-point-comment-input
              [ppWorkspaceId]="point?.workspaceRef.id"
              [ppPointId]="point?._id"
            ></pp-point-comment-input>
          </div>
        </ng-container>
      </pp-point-container>
    </div>
  </div>
</article>
