import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getDisabledUserManagementTooltipText } from 'src/app/project/modules/user-management/user-management-table-cells/utils/get-disabled-user-management-cell-tooltip-text';
import { TShare, TTags } from '../../../../../../share/share.model';
import { TTagSelectOptions } from '../../../../../../tags/tag-select/tag-select.component';

@Component({
  selector: 'pp-account-user-modal-shares-list-share-accessible-tags',
  templateUrl: './account-user-modal-shares-list-share-accessible-tags.component.html',
  styleUrls: ['./account-user-modal-shares-list-share-accessible-tags.component.scss'],
})
export class AccountUserModalSharesListShareAccessibleTagsComponent {
  @Input() ppShare: TShare;
  @Output() ppShareChange = new EventEmitter<TShare>();
  @Input() ppDisabled: boolean;

  tagSelectOptions: TTagSelectOptions = {
    disabled: false,
    singleLineInput: true,
    noBorder: true,
    width: '14.6rem',
  };

  disabledTooltipText: string;

  ngOnChanges(): void {
    this.setTagSelectOptionDisabled();
    this.disabledTooltipText = getDisabledUserManagementTooltipText(this.ppShare.shareOption);
  }

  updateShare(tags: TTags): void {
    const newShare: TShare = {
      ...this.ppShare,
      defectTags: tags,
      tagLimited: tags.length > 0,
    };

    this.ppShareChange.emit(newShare);
  }

  private setTagSelectOptionDisabled(): void {
    this.tagSelectOptions.disabled = this.ppDisabled;
  }
}
