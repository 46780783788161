import { EDropdownWidth } from 'src/app/project/components/dropdown/dropdown.consts';
import { EUserRole } from '../../../share/share-utils/user-roles';
import { TShare } from '../../../share/share.model';

export const EDIT_SIMPLE_SHARE_DROPDOWN_WIDTH = EDropdownWidth.PX_120;

export type TEditAccountUserModalParams = {
  accountId: string;
};

export type TAccountAddedWorkspaces = {
  [workspaceId: string]: EUserRole;
};

export type TEditedAccountShares = {
  [workspaceId: string]: TShare;
};

export type TRemovedAccountShares = {
  [workspaceId: string]: EUserRole;
};

export enum EEditAccountUserStep {
  SELECT_SITES = 'SELECT_SITES',
  SHARES_LIST = 'SHARES_LIST',
  CUSTOM_FIELDS_EDIT = 'CUSTOM_FIELDS_EDIT',
}

export type TSharesByWorkspace = {
  [workspaceId: string]: TShare;
};

export type TAccountEditedShare = {
  workspaceId: string;
  shares: {
    shareId: string;
    email: string;
  }[];
};
