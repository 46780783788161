import { cloneDeep } from 'lodash';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, forkJoin, of, throwError } from 'rxjs';

import { SET_ACCOUNTS } from '../account/account.store';

import { SetAccounts } from '../account/account.actions';
import { TAccount } from '../account/account.model';
import {
  ClearWorkspaces,
  DeleteWorkspace,
  SetWorkspace,
  SetWorkspaces,
  UpdateSiteName,
} from './workspace.actions';
import { TWorkspace, TWorkspacesById } from './workspace.model';

import { SortingService, TGuid } from '@core/helpers';
import { ActiveService } from '../../services/active/active.service';
import { AccountService } from '../account/account-service/account.service';
import { CustomFieldsService } from '../custom-fields/custom-fields.service';
import { ResponseErrorService } from '../errors/response-error.service';
import { PreferencesService } from '../preferences/preferences-service/preferences.service';
import { SharesService } from '../share/shares.service';
import { UsersService } from '../users/users.service';

import { AccountApiProviderService, WorkspaceApiProviderService } from '@core/api';
import { TAccountSummaryResponse, TImportUser, TWorkspaceUserDTO } from '@project/view-models';
import { catchError, finalize, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { EStore } from '../../shared/enums/store.enum';
import { TWorkspaceResponse } from '../../view-models/workspace-response.model';
import { logErrorInSentry } from '../errors/response-error';
import { TWorkspacePreferences } from '../preferences/preferences.model';
import { TSavedView } from '../saved-views/models/saved-view.model';
import { SavedViewsService } from '../saved-views/saved-views.service';
import { TShare } from '../share/share.model';
import { generateShare, generateShares } from '../share/share.utils';
import { TUser } from '../user/user.model';
import { BasicWorkspaceService } from './basic-workspace.service';
import { GenerateWorkspaceService } from './generate-workspace.service';
import { getChangedWorkspaces, setChangedWorkspace, setChangedWorkspaces } from './workspace';
import { addSharesToWorkspace } from './workspace-utils/add-shares-to-workspace';
import { checkAccounts } from './workspace-utils/check-accounts';
import { getSavedViewObservable } from './workspace-utils/get-saved-views-observable';
import { searchWorkspace } from './workspace-utils/search-workspace';
import { SET_WORKSPACES } from './workspace.store';

export type TWorkspaceDataResponse = {
  workspace: TWorkspaceResponse;
  account: TAccount;
  share: TShare;
  workspacePreferences: TWorkspacePreferences;
  savedView: TSavedView;
  workspaceUsers: TImportUser[];
};

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  private readonly destroy$ = new Subject<void>();

  private accountsFetch$: Observable<TAccountSummaryResponse[]>;
  private workspacesFetched: boolean;
  private workspacesFetching: boolean;
  private workspaceUsers: {
    [workspaceId: string]: string[];
  } = {};

  private workspaces$: Observable<TWorkspacesById>;
  private workspaces: TWorkspacesById;
  private accounts$: Observable<TAccount[]>;
  private accounts: TAccount[];
  private user$: Observable<TUser>;
  private isSuperUser: boolean;

  private generateWorkspaces$: Observable<TWorkspacesById> = null;

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
      accounts: TAccount[];
      user: TUser;
    }>,
    private responseErrorService: ResponseErrorService,
    private sharesService: SharesService,
    private activeService: ActiveService,
    private preferencesService: PreferencesService,
    private accountService: AccountService,
    private router: Router,
    private customFieldsService: CustomFieldsService,
    private usersService: UsersService,
    private accountApiProviderService: AccountApiProviderService,
    private workspaceApiProviderService: WorkspaceApiProviderService,
    private basicWorkspaceService: BasicWorkspaceService,
    private generateWorkspaceService: GenerateWorkspaceService,
    private sortingService: SortingService,
    private savedViewsService: SavedViewsService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
    this.user$ = this.store.pipe(select(EStore.USER));

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspaces = cloneDeep(workspaces);

      SET_WORKSPACES(this.workspaces);
    });

    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
      this.accounts = accounts;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  public static searchWorkspace(
    workspacesIds: string[],
    filter: string,
    allWorkspaces: TWorkspacesById,
  ): string[] {
    return searchWorkspace(workspacesIds, filter, allWorkspaces);
  }

  fetchWorkspaceData(workspaceId: string): Observable<TWorkspaceDataResponse> {
    return this.workspaceApiProviderService.fetchWorkspace(workspaceId).pipe(
      takeUntil(this.destroy$),
      switchMap((workspace: TWorkspaceResponse) => {
        setChangedWorkspace(workspaceId, false);

        return forkJoin([
          this.accountService.fetchAccount(workspace.accountRef.id),
          this.sharesService.fetchWorkspaceShare(workspaceId),
          of(workspace),
        ]);
      }),
      switchMap(([accountResponse, shareResponse, workspace]) => {
        const account = cloneDeep(accountResponse);
        const share = generateShare(
          cloneDeep(shareResponse),
          workspaceId,
          this.workspaces[workspaceId],
        );

        return forkJoin([
          this.preferencesService.fetchWorkspacePreferences(workspaceId, account.accountFeatures),
          this.usersService.fetchWorkspaceUsers(workspaceId),
          of(workspace),
          of(account),
          of(share),
          account.accountFeatures?.savedViews
            ? this.savedViewsService.fetchSavedViews(workspaceId)
            : of(null),
        ]);
      }),
      switchMap(
        ([
          workspacePreferences,
          workspaceUsersResponse,
          workspace,
          account,
          share,
          savedViewsResponse,
        ]) => {
          const savedViewsObservable: Observable<TSavedView> = getSavedViewObservable({
            savedViewsResponse,
            savedViewsService: this.savedViewsService,
            customFields: workspace.customFields.map(({ id }) => id),
            workspaceId,
          });

          const users = (workspaceUsersResponse as TWorkspaceUserDTO[]).map<TImportUser>(
            (value) => ({
              userName: value.name,
              email: value.email,
              userId: value.userId,
              primaryImageId: value.avatarId,
              workspaces: value.workspaces,
              avatarPublicUrl: value.avatarPublicUrl,
              verified: value.verified,
              lastActivityEpochMillis: value.lastActivityEpochMillis,
            }),
          );

          this.workspaceUsers[workspaceId] = users.map<string>(({ userId }) => userId);

          const resolveResponse: Partial<TWorkspaceDataResponse> = {
            workspace,
            account,
            share,
            workspacePreferences,
            workspaceUsers: users,
          };

          return forkJoin([of(resolveResponse), savedViewsObservable]);
        },
      ),
      map(
        ([workspaceResponse, savedViewResponse]) =>
          ({
            ...workspaceResponse,
            savedView: savedViewResponse,
          } as TWorkspaceDataResponse),
      ),
      catchError((error) => {
        if (error.status === EStatusCode.FORBIDDEN) {
          this.router.navigate(['/settings/user']);
        }

        return throwError(error);
      }),
    );
  }

  generateWorkspace(workspaceId: string): Observable<TWorkspace> {
    if (!workspaceId) {
      this.router.navigate(['/settings/user']);
    }

    return this.fetchWorkspaceData(workspaceId).pipe(
      takeUntil(this.destroy$),
      map((responses) => {
        const customFieldList = this.generateWorkspaceService.generateWorkspaceCustomFields(
          responses.workspace,
          workspaceId,
        );
        const ownWorkspaceObject = this.generateWorkspaceService.generateWorkspace(responses);

        this.customFieldsService.setWorkspaceCustomFields(
          workspaceId,
          customFieldList[workspaceId],
        );

        this.store.dispatch(
          new SetWorkspace({
            workspaceId: ownWorkspaceObject.workspaceId,
            workspace: ownWorkspaceObject,
          }),
        );

        return ownWorkspaceObject;
      }),
      catchError((error) => {
        logErrorInSentry(error);
        this.responseErrorService.errors(error.status);

        return throwError(error);
      }),
    );
  }

  getWorkspaces(): TWorkspacesById {
    return this.workspaces;
  }

  getWorkspace(workspaceId: TGuid): TWorkspace {
    return this.workspaces[workspaceId];
  }

  findWorkspace(workspaceId?: TGuid): TWorkspace | null {
    if (workspaceId && this.workspaces) {
      return this.workspaces[workspaceId];
    }

    return null;
  }

  getActiveWorkspace(): TWorkspace {
    const workspaceId = this.activeService.getActiveWorkspaceId();

    return this.workspaces[workspaceId];
  }

  clearWorkspaces(): void {
    this.accountsFetch$ = null;
    this.workspacesFetching = false;
    this.workspacesFetched = false;

    this.store.dispatch(new ClearWorkspaces());
  }

  generateWorkspaces({
    refetch = false,
    fromNewSite = false,
    showHidden = false,
  }: {
    refetch?: boolean;
    fromNewSite?: boolean;
    showHidden?: boolean;
  } = {}): Observable<TWorkspacesById> {
    if (getChangedWorkspaces()) {
      refetch = true;
      this.workspacesFetched = false;

      setChangedWorkspaces(false);
    }

    if (!this.generateWorkspaces$ || refetch) {
      if (fromNewSite && this.workspacesFetched) {
        this.generateWorkspaces$ = null;

        return of(null);
      }

      this.generateWorkspaces$ = forkJoin([
        this.sharesService.fetchShares(refetch),
        this.usersService.fetchAllUsers({ refetch }),
        this.fetchWorkspaces({ refetch, forceFetch: !this.workspacesFetched, showHidden }),
      ]).pipe(
        map(([shares, users, accounts]) => {
          const generatedShares = generateShares(shares, this.workspaces);
          const workspaces = addSharesToWorkspace(generatedShares);

          const { checkedAccounts, workspaceList, customFieldList } = checkAccounts(
            accounts,
            workspaces,
            users,
            this.accounts,
          );

          const accountList = checkedAccounts.map<TAccount>((account) => ({
            accountId: account.accountId,
            accountName: account.accountName,
            accountType: account.accountType,
            accountFeatures: account.accountFeatures,
            workspaces: account.workspaces.sort((workspaceIdA, workspaceIdB) => {
              const workspaceA = this.workspaces[workspaceIdA];
              const workspaceB = this.workspaces[workspaceIdB];

              return this.sortingService.naturalSort(workspaceA?.siteName, workspaceB?.siteName);
            }),
            accountOwnerId: account.accountOwnerId,
            industryType: account.industryType,
            subscriptionType: account.subscriptionType,
            accountManager: account.accountManager,
            accountFolders: account.accountFolders,
            logoRef: account.logoRef,
            websiteUrl: account.websiteUrl,
          }));

          this.customFieldsService.setCustomFields(customFieldList);
          this.store.dispatch(new SetAccounts(accountList));

          SET_ACCOUNTS(accountList);

          Object.keys(workspaceList).forEach((workspaceId) => {
            if (this.workspaceUsers[workspaceId]) {
              workspaceList[workspaceId].users = this.workspaceUsers[workspaceId] || [];
            }
          });

          this.store.dispatch(new SetWorkspaces(workspaceList));

          return workspaceList;
        }),
        shareReplay(),
        finalize(() => {
          this.generateWorkspaces$ = null;
        }),
      );
    }

    return this.generateWorkspaces$;
  }

  fetchWorkspaces({
    refetch = false,
    forceFetch = false,
    showHidden = false,
  }: {
    refetch?: boolean;
    forceFetch?: boolean;
    showHidden?: boolean;
  } = {}): Observable<TAccountSummaryResponse[]> {
    const workspacesChanged = getChangedWorkspaces();

    if (workspacesChanged) {
      this.accountsFetch$ = null;
    }

    // FiXME Couldn't it be simplified with this.accountsFetch$?
    if (
      (!this.workspacesFetched && !this.workspacesFetching) ||
      (refetch && !this.workspacesFetching) ||
      forceFetch ||
      workspacesChanged
    ) {
      const showUnsubscribed = !this.isSuperUser;
      this.workspacesFetching = true;

      if (workspacesChanged) {
        setChangedWorkspaces(false);
      }

      this.accountsFetch$ = this.accountApiProviderService
        .fetchAccounts(refetch || forceFetch, showUnsubscribed, showHidden)
        .pipe(
          shareReplay(),
          catchError(this.responseErrorService.handleRequestError),
          finalize(() => {
            this.workspacesFetching = false;
          }),
        );
    }

    return this.accountsFetch$;
  }

  generateBasicWorkspaces(): Observable<void> {
    return this.basicWorkspaceService.generateBasicWorkspaces(this.workspaceUsers);
  }

  updateWorkspaceName(workspaceId: string, name: string): void {
    this.store.dispatch(
      new UpdateSiteName({
        workspaceId,
        siteName: name,
      }),
    );
  }

  deleteWorkspace(workspaceId: string): Observable<null> {
    return this.workspaceApiProviderService.deleteWorkspace(workspaceId).pipe(
      tap(() => {
        this.store.dispatch(
          new DeleteWorkspace({
            workspaceId,
          }),
        );
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }
}
