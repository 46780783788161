import { TTranslationKey } from 'src/app/project/features/translate/types';

export type TAccountSettingsTab = {
  hidden: boolean;
  path: string;
  translationKey: TTranslationKey;
  dataTarget: string;
};

export enum EAccountSettingsTabPath {
  SITES = 'sites',
  SUBSCRIPTION = 'subscription',
  SKINS = 'skins',
  OPTIONS = 'options',
  USER_MANAGEMENT = 'user-management',
}

export const ACCOUNT_SETTINGS_TABS: TAccountSettingsTab[] = [
  {
    hidden: true,
    path: EAccountSettingsTabPath.SITES,
    translationKey: 'sites',
    dataTarget: 'Account settings sites button',
  },
  {
    hidden: true,
    path: EAccountSettingsTabPath.SUBSCRIPTION,
    translationKey: 'subscription',
    dataTarget: 'Account settings subscription button',
  },
  {
    hidden: true,
    path: EAccountSettingsTabPath.SKINS,
    translationKey: 'white_label',
    dataTarget: 'Account settings White Label button',
  },
  {
    hidden: true,
    path: EAccountSettingsTabPath.OPTIONS,
    translationKey: 'options',
    dataTarget: 'Account settings options button',
  },
  {
    hidden: true,
    path: EAccountSettingsTabPath.USER_MANAGEMENT,
    translationKey: 'user_management',
    dataTarget: 'Account settings user management button',
  },
];
