import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { PromptService, TPrompt } from './prompt.service';
import { SidePanelService } from '../../modules/layout/side-panel/side-panel.service';
import { TSidePanel } from 'src/app/project/modules/layout/side-panel/side-panel.model';

@Component({
  selector: 'pp-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss'],
})
export class PromptComponent implements OnInit, AfterViewInit {
  @ViewChild('promptContainer') promptContainerElement: ElementRef;

  sidePanel: TSidePanel;
  prompts: TPrompt[] = [];
  position = this.promptService.getPosition();

  constructor(private promptService: PromptService, private sidePanelService: SidePanelService) {}

  ngOnInit() {
    this.sidePanel = this.sidePanelService.getSidePanel();
    this.prompts = this.promptService.getPrompts();
  }

  ngAfterViewInit(): void {
    this.promptService.setPromptContainerElement(this.promptContainerElement.nativeElement);
  }

  hidePrompt(prompt: TPrompt): void {
    this.promptService.clearPrompt(prompt);
  }

  onPromptRendered(element: HTMLElement, promptRef: TPrompt): void {
    if (!promptRef.visibility) {
      this.promptService.setPromptElement(promptRef, element);
    }
  }
}
