import { cloneDeep } from 'lodash';
import { TShare } from '../../share.model';

export function correctExportsVisibility(share: TShare): boolean {
  let exportPermission = cloneDeep(share.advancedAccessLevels.exports.permission.read);

  exportPermission = !exportPermission;
  const access = exportPermission.toString() ? 'on' : 'off';

  return exportPermission;
}
