import { Feature } from 'ol';
import { MultiPoint, Polygon } from 'ol/geom.js';

export function roundRect(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radiusValue: number,
  fill: boolean,
  stroke: boolean,
): void {
  let radius;

  if (typeof stroke === 'undefined') {
    stroke = true;
  }

  if (typeof radiusValue === 'undefined') {
    radius = 5;
  }

  if (typeof radiusValue === 'number') {
    radius = { tl: radiusValue, tr: radiusValue, br: radiusValue, bl: radiusValue };
  } else {
    const defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };

    Object.keys(defaultRadius).forEach((side) => {
      radius[side] = radius[side] || defaultRadius[side];
    });
  }

  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();

  if (fill) {
    ctx.fill();
  }

  if (stroke) {
    ctx.stroke();
  }
}

export function drawTriangle(
  context: CanvasRenderingContext2D,
  pinActiveSize: number,
  width: number,
  height: number,
): void {
  const heightStart = 32;

  context.beginPath();
  context.moveTo(pinActiveSize / 2 - width / 2, heightStart);
  context.lineTo(pinActiveSize / 2, heightStart + height);
  context.lineTo(pinActiveSize / 2 + width / 2, heightStart);
  context.fill();
}

export function lineFromPoints(P: number[], Q: number[]): number[] {
  // y + ax + b
  const [xA, yA] = P;
  const [xB, yB] = Q;

  const a = (yA - yB) / (xA - xB);
  const b = yA - ((yA - yB) / (xA - xB)) * xA;

  return [a, b];
}

export function getPolygonHighlight(feature: Feature): MultiPoint {
  const coordinates = (feature.getGeometry() as Polygon).getCoordinates()[0];

  if (Array.isArray(coordinates)) {
    // if object is polygon
    let minX = coordinates[0][0];
    let maxX = coordinates[0][0];

    coordinates.forEach((_coordinate) => {
      minX = Math.min(minX, _coordinate[0]);
      maxX = Math.max(maxX, _coordinate[0]);
    });

    const xValue = (minX + maxX) / 2; // middle of polygon
    let yValue = coordinates[0][1];
    const potentialValues: number[] = [];

    for (let i = 0; i < coordinates.length; i++) {
      let [a, b] = [0, 0];
      let y1, y2, x1, x2;
      let match = false;
      let equationPossible = true;

      if (coordinates[i + 1]) {
        // get equation of polygon side
        if (coordinates[i][0] === coordinates[i + 1][0]) {
          // (x1 - x2) can't be zero, but vertical line won't be the solution anyway
          equationPossible = false;
        } else {
          [a, b] = lineFromPoints(coordinates[i], coordinates[i + 1]);

          y1 = coordinates[i][1];
          y2 = coordinates[i + 1][1];
          x1 = coordinates[i][0];
          x2 = coordinates[i + 1][0];
        }
      } else {
        if (coordinates[i][0] === coordinates[0][0]) {
          // (x1 - x2) can't be zero, but vertical line won't be the solution anyway
          equationPossible = false;
        } else {
          [a, b] = lineFromPoints(coordinates[i], coordinates[0]);

          y1 = coordinates[i][1];
          y2 = coordinates[0][1];
          x1 = coordinates[i][0];
          x2 = coordinates[0][0];
        }
      }

      if (
        ((x1 <= x2 && xValue >= x1 && xValue <= x2) || (x1 > x2 && xValue < x1 && xValue > x2)) &&
        equationPossible
      ) {
        // in range x
        const outcome = a * xValue + b;

        if (y1 <= y2 && outcome >= y1 && outcome <= y2) {
          // check if polygon side goes through middle of polygon
          match = true;
        } else if (y1 > y2 && outcome < y1 && outcome > y2) {
          match = true;
        }

        if (match) {
          potentialValues.push(outcome);
        }
      }
    }

    yValue = Math.max(...potentialValues); // get the highest y so it's displayed over polygon

    return new MultiPoint([[xValue, yValue]]);
  } else {
    return null;
  }
}
