<td class="userManagement__siteCell userManagementTableSite__cell siteCell--short">
  <div class="siteCell__wrapper">
    <span
      (click)="emitNavigateToSite()"
      class="siteCell__name"
      ppTooltip
      [ppTrimTooltip]="true"
      [ppTitle]="siteName"
    >
      {{ siteName }}
    </span>
  </div>
</td>
