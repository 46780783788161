import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-fleet-management-new-fleet-name-and-account',
  templateUrl: './fleet-management-new-fleet-name-and-account.component.html',
  styleUrls: ['./fleet-management-new-fleet-name-and-account.component.scss'],
})
export class FleetManagementNewFleetNameAndAccountComponent {
  @Input() ppFleetName: string;
  @Output() ppFleetNameChange = new EventEmitter<string>();
  @Input() ppAccountId: string;
  @Output() ppAccountIdChange = new EventEmitter<string>();
  @Input() ppAccountSelectDisabled: boolean;

  EIconPath = EIconPath;

  selectAccount(accountId: string): void {
    this.ppAccountIdChange.emit(accountId);
  }

  updateFleetName(name: string): void {
    this.ppFleetNameChange.emit(name);
  }
}
