<pp-two-factor-authentication-number-page
  *ngIf="currentStep === ETwoFactorAuthenticationSetupStep.ENTER_PHONE_NUMBER"
  [(ppPhoneNumber)]="phoneNumber"
  [ppProcessing]="processing"
  (ppStepChange)="goToCodeStep()"
  [(ppChannel)]="channel"
></pp-two-factor-authentication-number-page>

<pp-two-factor-authentication-code-page
  *ngIf="currentStep === ETwoFactorAuthenticationSetupStep.ENTER_CODE"
  [ppPhoneNumber]="phoneNumber"
  [ppProcessing]="processing"
  (ppConfirm2FA)="confirm2FA($event)"
  (ppGoBack)="goToNumberStep()"
  [ppErrorMessage]="errorMessage"
  (ppResend)="resendCode()"
></pp-two-factor-authentication-code-page>

<pp-two-factor-authentication-success
  *ngIf="currentStep === ETwoFactorAuthenticationSetupStep.SUCCESS"
  (ppContinue)="continueFromSuccess()"
></pp-two-factor-authentication-success>
