import { createElement } from 'src/app/core/helpers/dom';

export function createDashletWrapperElement(): HTMLElement {
  const dashletsWrapperElement = createElement('div'); // Charts from chart.js need to be displayed for export to work
  dashletsWrapperElement.style.display = 'inline-flex';
  dashletsWrapperElement.style.overflow = 'scroll';
  dashletsWrapperElement.style.maxWidth = '90vw';
  dashletsWrapperElement.style.flexWrap = 'wrap';
  dashletsWrapperElement.style.marginLeft = '100px';

  return dashletsWrapperElement;
}
