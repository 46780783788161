import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PromptService } from './prompt.service';
import { PromptComponent } from './prompt.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';

@NgModule({
  declarations: [PromptComponent],
  imports: [CommonModule, PipesModule, OnRenderedModule],
  exports: [PromptComponent],
  providers: [PromptService],
})
export class PromptModule {}
