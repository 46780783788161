import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { TColumn } from '../../../columns/column.model';
import { GET_COLUMNS } from '../../../columns/columns.store';

export function digestColumnPreferences(): {
  groupingColumns: TColumn[];
  sortedColumns: TColumn[];
} {
  const groupingColumns: TColumn[] = [];
  const sortingColumns: TColumn[] = [];

  GET_COLUMNS().forEach((column) => {
    if (column.groupOrder) {
      groupingColumns.push(column);
    }

    if (column.sortOrder) {
      sortingColumns.push(column);
    }
  });

  const sortedColumns = sortingColumns.sort((a, b) => a.sortIndex - b.sortIndex);
  let errorReported = false;

  // Checking if order is correct in case sorting shows incorrect numbers
  for (let i = 0; i < sortedColumns.length; i++) {
    if (
      sortedColumns[i].sortIndex !== i &&
      !(sortedColumns.length === 1 && !sortedColumns[i].sortIndex)
    ) {
      if (!errorReported) {
        logErrorInSentry(
          new Error(`Column sortIndex is incorrect. sorting: ${JSON.stringify(sortedColumns)}`),
        );

        errorReported = true;
      }

      sortedColumns[i].sortIndex = i;
    }
  }

  return { groupingColumns, sortedColumns };
}
