import { createElement } from 'src/app/core/helpers/dom';
import { GET_COLUMNS } from '../../../columns/columns.store';
import { GET_GROUPING } from '../../../columns/grouping.store';
import { GET_TABLE } from '../../../table.ui.store';
import { createFooterCellTypeElement } from './create-footer-cell-type-element';
import { createFooterTotalPointsElement } from './create-footer-total-points-element';

export function createFooterRowElement(): HTMLElement {
  const table = GET_TABLE();
  const grouping = GET_GROUPING();
  let footerWidth = table.width;

  if (grouping.length) {
    footerWidth = table.width + grouping.length * 16;
  }

  return createElement<HTMLElement>('div', {
    attrs: {
      class: 'table__footer__row',
      style: {
        width: `${footerWidth}px`,
      },
    },
    eventListeners: {
      wheel: (event) => {
        const newLeft = table.tableHead.element.scrollLeft - event.wheelDeltaX;

        table.tableBody.virtualScroller.disableSmoothScrolling();
        table.tableHead.scrollLeft(newLeft);
        table.tableFooter.scrollLeft(newLeft);
        table.tableBody.virtualScroller.scrollLeft(newLeft);
        table.tableBody.virtualScroller.enableSmoothScrolling();
      },
    },
    children: [
      createFooterTotalPointsElement(),
      ...GET_COLUMNS().flatMap((column) => {
        if (!column.hidden) {
          return createFooterCellTypeElement(column);
        }

        return '';
      }),
    ],
  });
}
