import { Injectable } from '@angular/core';
import { Observable, catchError, tap } from 'rxjs';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { FleetApiProviderService } from 'src/app/project/data-providers/api-providers/fleet-api-provider/fleet-api-provider.service';
import {
  TFleetShareCreateDTO,
  TFleetShareDeleteDTO,
} from 'src/app/project/data-providers/api-providers/fleet-api-provider/fleet-requests.model';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { getErrorMessage } from 'src/app/project/helpers/database/get-error-message';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventFleetManagement,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { ResponseErrorService } from '../../errors/response-error.service';
import { EFleetShareOption, TFleetShare } from './fleet-sharing.consts';

@Injectable({
  providedIn: 'root',
})
export class FleetSharingService {
  constructor(
    private fleetApiProviderService: FleetApiProviderService,
    private responseErrorService: ResponseErrorService,
    private promptService: PromptService,
    private translationPipe: TranslationPipe,
  ) {}

  shareFleet(fleetId: string, emailList: string[]): Observable<TFleetShare[]> {
    const body: TFleetShareCreateDTO[] = emailList.map((email) => {
      return {
        userEmail: email,
        fleetShareOption: EFleetShareOption.READ,
      };
    });

    return this.fleetApiProviderService.shareFleet(fleetId, body).pipe(
      tap((response) => {
        const prompt = this.translationPipe.transform('prompt_fleet_shared');
        this.promptService.showSuccess(prompt);
      }),
      catchError((error) => {
        switch (error.status) {
          case EStatusCode.BAD_REQUEST:
            error.text().then((text) => {
              const textJSON = JSON.parse(text);

              textJSON.errors.forEach((error) => {
                this.promptService.showError(error.userEmail + ': ' + error.errorMessage);
              });
            });
            break;
          case EStatusCode.FORBIDDEN: {
            const prompt = this.translationPipe.transform('prompt_fleet_share_unauthorized');
            this.promptService.showError(prompt);
            break;
          }
          case EStatusCode.NOT_FOUND: {
            const prompt = this.translationPipe.transform('prompt_fleet_not_found');
            this.promptService.showError(prompt);
            break;
          }
          default: {
            const prompt = this.translationPipe.transform('prompt_fleet_share_error');
            this.promptService.showError(prompt);
            break;
          }
        }

        return this.responseErrorService.handleRequestError(error);
      }),
    );
  }

  deleteFleetShares(fleetId: string, shareIds: string[]): Observable<void> {
    const body: TFleetShareDeleteDTO = shareIds;

    logEventInGTAG(EGoogleEventFleetManagement.FLEET_SHARE_DELETE, {
      event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
      event_details: shareIds.length.toString(),
    });

    return this.fleetApiProviderService.deleteFleetShares(fleetId, body).pipe(
      tap(() => {
        const prompt = this.translationPipe.transform('prompt_fleet_share_deleted');
        this.promptService.showSuccess(prompt);
      }),
      catchError((error) => {
        switch (error.status) {
          case EStatusCode.BAD_REQUEST:
            getErrorMessage(error).then((message) => {
              this.promptService.showError(message);
            });
            break;
          case EStatusCode.FORBIDDEN: {
            const prompt = this.translationPipe.transform('prompt_fleet_share_delete_unauthorized');
            this.promptService.showError(prompt);
            break;
          }
          case EStatusCode.NOT_FOUND: {
            const prompt = this.translationPipe.transform('prompt_fleet_not_found');
            this.promptService.showError(prompt);
            break;
          }
          default: {
            const prompt = this.translationPipe.transform('prompt_fleet_share_delete_error');
            this.promptService.showError(prompt);
            break;
          }
        }

        return this.responseErrorService.handleRequestError(error);
      }),
    );
  }

  getFleetShares(fleetId: string): Observable<TFleetShare[]> {
    return this.fleetApiProviderService.getFleetShares(fleetId).pipe(
      catchError((error) => {
        const prompt = this.translationPipe.transform('prompt_fleet_share_get_error');

        this.promptService.showError(prompt);

        return this.responseErrorService.handleRequestError(error);
      }),
    );
  }
}
