<div *ngIf="ppChange.oldValue" class="dashletActivity dashletActivity--flex-start">
  <div class="dashletActivity__container dashletActivity__container--mr">
    <img class="dashletActivity__icon" [src]="EIconPath.CUSTOM_FIELD_RICH_TEXT_ACTIVITY" />

    <div class="dashletActivity__text">
      {{ 'changed' | ppTranslate }}
      <span class="dashletActivities__item-quoted timeline__strong">{{ ppChange.label }}</span>
      {{ 'to' | ppTranslate | lowercase }}
    </div>
  </div>

  <pp-description-dashlet-activity
    [ppDescription]="ppChange.newValue"
  ></pp-description-dashlet-activity>
</div>

<div *ngIf="!ppChange.oldValue" class="dashletActivity dashletActivity--flex-start">
  <div class="dashletActivity__container dashletActivity__container--mr">
    <img class="dashletActivity__icon" [src]="EIconPath.CUSTOM_FIELD_RICH_TEXT_ACTIVITY" />

    <div class="dashletActivity__text">
      <span class="timeline__strong">{{ ppChange.label }}</span> {{ 'changed' | ppTranslate }}
      {{ 'to' | ppTranslate | lowercase }}
    </div>
  </div>

  <pp-description-dashlet-activity
    [ppDescription]="ppChange.newValue"
  ></pp-description-dashlet-activity>
</div>
