import { Directive, ElementRef, Input, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[data-test]',
})
export class DataTestDirective {
  @Input('data-test') dataTest: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataTest']) {
      this.renderer.setAttribute(this.el.nativeElement, 'data-test', this.dataTest);
    }
  }
}
