<pp-modal>
  <ng-container heading>
    {{
      'remove_username_from_all_sites'
        | ppTranslate: { username: user.userName ? user.userName : user.email }
    }}
  </ng-container>

  <ng-container body>
    <p>
      {{
        'remove_username_from_site_confirm'
          | ppTranslate: { username: user.userName ? user.userName : user.email }
      }}
    </p>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'remove_user_from_all_sites' | ppTranslate"
      [ppProcess]="processing"
      (ppCancel)="hideModal()"
      (ppConfirm)="deleteButtonCallback()"
      [ppError]="true"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
