import { TGroupedPoints } from './group-model';

const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});

export function timelineGroupBy(_a: TGroupedPoints, _b: TGroupedPoints): number {
  const a = _a.value.toString().toLowerCase();
  const b = _b.value.toString().toLowerCase();

  if (collator.compare(a, b) < 0) {
    return -1;
  } else if (collator.compare(a, b) > 0) {
    return +1;
  }
}
