<pp-modal>
  <ng-container heading>
    {{ 'update_email' | ppTranslate }}
  </ng-container>

  <ng-container body>
    <div class="updateEmail__wrapper">
      <section class="updateEmail__text">
        <div>
          {{ 'update_email_modal_text_1' | ppTranslate
          }}<span class="updateEmail__text--email">{{ user.email }}</span
          >.
        </div>
        <div>
          {{ 'update_email_modal_text_2' | ppTranslate }}
        </div>

        <ul>
          <li>- {{ 'update_email_modal_text_3' | ppTranslate }}</li>
          <li>- {{ 'update_email_modal_text_4' | ppTranslate }}</li>
          <li>- {{ 'update_email_modal_text_5' | ppTranslate }}</li>
        </ul>
      </section>

      <input
        class="updateEmail__input"
        [placeholder]="'new_email' | ppTranslate"
        [(ngModel)]="newEmail"
        (ngModelChange)="onEmailChange()"
        type="email"
      />

      <input
        class="updateEmail__input"
        [placeholder]="'confirm_email' | ppTranslate"
        [(ngModel)]="confirmEmail"
        (ngModelChange)="onConfirmEmailChange()"
        type="email"
      />
    </div>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'change_email' | ppTranslate"
      [ppConfirmTooltip]="
        !emailCorrect
          ? ('email_not_valid' | ppTranslate)
          : !emailsTheSame
          ? ('emails_not_the_same' | ppTranslate)
          : ''
      "
      (ppCancel)="hideModal()"
      (ppConfirm)="changeEmail()"
      [ppProcess]="processing"
      [ppDisabled]="!emailCorrect || !emailsTheSame"
      (keydown.enter)="blurInput($event)"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
