import { SupportedBrowsersService } from './supported-browsers.service';
import { supportedBrowserRoutes } from './supported-browsers.routes';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupportedBrowsersPageComponent } from './supported-browsers-page/supported-browsers-page.component';
import { RouterModule } from '@angular/router';
import { SupportedBrowsersBarComponent } from './supported-browsers-bar/supported-browsers-bar.component';
import { ButtonModule } from '../../components/button/button.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [SupportedBrowsersPageComponent, SupportedBrowsersBarComponent],
  imports: [CommonModule, RouterModule.forChild(supportedBrowserRoutes), ButtonModule, PipesModule],
  exports: [SupportedBrowsersBarComponent],
  providers: [SupportedBrowsersService],
})
export class SupportedBrowsersModule {}
