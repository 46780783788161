import { Style } from 'exceljs';

export const basicColumns = [
  'ID',
  'Title',
  'Priority',
  'Status',
  'Start Date',
  'End Date',
  'Duration (Days)',
];

export const centeredStyle: Partial<Style> = {
  alignment: {
    vertical: 'middle',
    wrapText: true,
    horizontal: 'center',
  },
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const timelineStartRow = 1;
