<ng-container>
  <span *ngIf="!ppTags[0] && !ppLimitedTags">
    {{ 'all' | ppTranslate }}
  </span>

  <span *ngIf="!ppTags[0] && ppLimitedTags">
    {{ 'none' | ppTranslate }}
  </span>

  <pp-tag
    [ppText]="ppTags[0]"
    [ppDeletable]="false"
    [ppDisabled]="ppDisabled"
    ppTooltip
    [ppTrimTooltip]="true"
    [ppTitle]="ppTags[0]"
    *ngIf="ppTags[0]"
  ></pp-tag>

  <pp-tag
    [ppText]="ppTags[1]"
    [ppDeletable]="false"
    [ppDisabled]="ppDisabled"
    ppTooltip
    [ppTrimTooltip]="true"
    [ppTitle]="ppTags[1]"
    *ngIf="ppTags[1]"
  ></pp-tag>

  <span
    class="tags__showMore"
    *ngIf="ppTags[2]"
    ppTooltip
    [ppSanitize]="false"
    [ppTitle]="tagsTooltip"
  >
    + {{ ppTags.length - 2 }} {{ 'more' | ppTranslate }}
  </span>
</ng-container>
