import Tooltip from 'src/app/project/features/tooltip/Tooltip';

import { GET_USER } from 'src/app/project/modules/user/user.store';
import {
  ADD_COLLAPSED_ACCOUNT,
  DELETE_COLLAPSED_ACCOUNT,
  GET_COLLAPSED_ACCOUNTS,
} from '../utils/account-list.ui.store';

import { createElement, TAnyFunction } from '@core/helpers';
import { ApiService } from '@core/http';
import { getSvg } from 'src/app/core/helpers/get-svg';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { TFlattenedAccount } from '../account-list/account-list-flatten-account';
import { createMarkedKeywordSidePanelElement } from '../utils/side-panel-search';

export default class Account {
  private account: TFlattenedAccount;
  private accountSettingsCallback: TAnyFunction;
  private updateCallback: TAnyFunction;

  private collapsed: boolean;

  private arrowIconElement: HTMLImageElement;
  private nameElement: HTMLElement;
  private settingsElement: HTMLElement;
  private accountElement: HTMLElement;
  private nameTooltip;
  private settingsTooltip;
  private apiService: ApiService;

  element: HTMLElement;

  constructor(
    _account: TFlattenedAccount,
    apiService: ApiService,
    {
      accountSettingsCallback,
      updateCallback,
    }: {
      accountSettingsCallback: TAnyFunction;
      updateCallback: TAnyFunction;
    },
  ) {
    this.account = _account;
    this.accountSettingsCallback = accountSettingsCallback;
    this.updateCallback = updateCallback;

    this.collapsed = GET_COLLAPSED_ACCOUNTS().has(this.account.id);

    this.arrowIconElement = null;
    this.nameElement = null;
    this.settingsElement = null;
    this.apiService = apiService;

    this.element = this.create();
  }

  // --------------------------------------------------
  // -------------------- ELEMENTS --------------------
  // --------------------------------------------------

  create(): HTMLElement {
    const accountUnsubscribed =
      GET_USER().isSuperUser && this.account.accountType === 'UNSUBSCRIBED';

    this.accountElement = createElement('div', {
      attrs: {
        class: `accountNav__element accountNav__element-head ${
          accountUnsubscribed ? 'account-nav_element--unsubscribed' : ''
        }`,
        'data-accountid': this.account.id,
      },
      eventListeners: {
        click: () => {
          this.toggle();
        },
      },
      children: [
        this.createArrowIconElement(accountUnsubscribed),
        this.createNameElement(),
        this.createSettingsElement(),
      ],
    });

    this.nameTooltip = new Tooltip({
      options: {},
      title: this.account.name,
      mobileTooltip: false,
      trimTooltip: true,
      childTooltip: false,
      element: this.accountElement,
      trimElement: this.nameElement,
    });

    this.nameTooltip.createTooltip();

    return this.accountElement;
  }

  createArrowIconElement(accountUnsubscribed: boolean): HTMLElement {
    this.arrowIconElement = createElement('img', {
      attrs: {
        src: this.collapsed ? EIconPath.ARROW_RIGHT_WHITE : EIconPath.INTERACTION_CLOSE_ACCOUNT,
      },
    }) as HTMLImageElement;

    const arrowElement = createElement('div', {
      attrs: {
        class: `account-nav__arrow ${
          accountUnsubscribed ? 'account-nav__arrow--unsubscribed' : ''
        }`,
      },
      children: [this.arrowIconElement],
    });

    getSvg(
      this.apiService,
      this.collapsed ? EIconPath.ICON_SIDE_PANEL_ARROW_RIGHT : EIconPath.ICON_SIDE_PANEL_ARROW_DOWN,
    ).then((svg) => {
      arrowElement.innerHTML = svg;
    });

    return arrowElement;
  }

  createNameElement(): HTMLElement {
    this.nameElement = createElement('h6', {
      attrs: {
        class: ['mainNav__name', 'accountNav__account-name', 'accountNav__account-name-text'],
      },
      children: [createMarkedKeywordSidePanelElement(this.account.name)],
    });

    return this.nameElement;
  }

  createSettingsElement(): HTMLElement {
    const user = GET_USER();

    let adminUser = false;
    let accountAdminUser = false;

    for (
      let workspaceIndex = 0;
      workspaceIndex < this.account.workspaces.length;
      workspaceIndex++
    ) {
      if (this.account.workspaces[workspaceIndex].shareOption === EUserRole.ACCOUNT_ADMIN) {
        accountAdminUser = true;

        break;
      }

      if (this.account.workspaces[workspaceIndex].shareOption === EUserRole.SITE_ADMIN) {
        adminUser = true;

        break;
      }
    }

    if (
      (user.accountId && user.accountId === this.account.id) ||
      user.isSuperUser ||
      adminUser ||
      accountAdminUser
    ) {
      this.settingsElement = createElement('a', {
        attrs: {
          'data-test': 'accountSettingsCog',
          id: `sidePanelAccountSettingsDropdownButton${this.account.id}`,
          class: [
            'mainNav__options',
            'mainNav__option',
            'mainNav__option--account',
            'filterPanel__tooltip',
          ],
        },
        eventListeners: {
          click: (_event) => {
            _event.stopPropagation();
            this.accountSettingsCallback(this.settingsElement.id, this.account.id);
          },
        },
        children: [
          createElement('img', {
            attrs: {
              src: EIconPath.INPUT_COG_GRAY,
            },
          }),
        ],
      });

      getSvg(this.apiService, EIconPath.ICON_COG_GREY).then((svg) => {
        this.settingsElement.innerHTML = svg;
      });

      this.settingsTooltip = new Tooltip({
        options: {},
        title: 'Account Settings',
        mobileTooltip: false,
        trimTooltip: false,
        childTooltip: false,
        element: this.settingsElement,
      });

      this.settingsTooltip.createTooltip();
    } else {
      this.settingsElement = createElement('a');
    }

    return this.settingsElement;
  }

  // --------------------------------------------------
  // --------------------- METHODS --------------------
  // --------------------------------------------------

  toggle(): void {
    if (this.collapsed) {
      DELETE_COLLAPSED_ACCOUNT(this.account.id);

      this.updateCallback(false);
    } else {
      ADD_COLLAPSED_ACCOUNT(this.account.id);

      this.updateCallback(true);
    }
  }
}
