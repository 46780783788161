import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { KEY_ESC, TAnyFunction, clickOutside, pressKey } from '@core/helpers';
import { DeviceService } from '@core/services';
import { createCustomScroller } from 'src/app/core/helpers/create-custom-scroller';
import { WindowService } from 'src/app/core/services/window/window.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { DropdownService } from '../../dropdown/dropdown-service/dropdown.service';
import { EDropdownWidth, TDropdown } from '../../dropdown/dropdown.consts';
import { arraysEqual } from '../select/utils/arrays-equal.utils';
import { SelectMultipleDropdownComponent } from './select-multiple-dropdown/select-multiple-dropdown.component';
import { TSelectMultipleDropdownData } from './select-multiple-dropdown/select-multiple-dropdown.model';
import { TSelectMultipleOption } from './select-multiple.model';

@Component({
  selector: 'pp-select-multiple',
  templateUrl: './select-multiple.component.html',
  styleUrls: ['./select-multiple.component.scss'],
})
export class SelectMultipleComponent {
  @ViewChild('selectMultipleComponent', { static: true }) element: ElementRef;
  @ViewChild('selectContent') selectContentRef: ElementRef;
  @Input() ppItems: TSelectMultipleOption[];
  @Input() ppNoSearchBar = false;
  @Input() ppId: string;
  @Input() ppClass: string;
  @Input() ppIconClass = '';

  @Output() ppSelectItem = new EventEmitter<TSelectMultipleOption>();

  dropdown: TDropdown = this.dropdownService.getDropdown();
  private closeOnEscRef: TAnyFunction;
  private clickOutsideRef: TAnyFunction;
  dropDownAlign: string;
  isMobile = false;
  isTablet = false;
  dropdownVisible = false;
  selectedItems: TSelectMultipleOption[] = [];
  EIconPath = EIconPath;

  private window = this.windowService.getGlobalObject();

  constructor(
    private deviceService: DeviceService,
    private windowService: WindowService,
    private dropdownService: DropdownService,
  ) {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateSelectedItems();

    if (
      changes.ppItems &&
      !arraysEqual(changes.ppItems.currentValue, changes.ppItems.previousValue) &&
      this.dropdown.visible
    ) {
      this.setDropdownData();
    }
  }

  ngAfterViewInit() {
    this.closeOnEscRef = (event: KeyboardEvent): void =>
      pressKey(event, KEY_ESC, () => {
        this.hideDropdown();
      });

    this.clickOutsideRef = (event: MouseEvent): void =>
      clickOutside(event, this.element.nativeElement, () => {
        event.stopImmediatePropagation();

        this.hideDropdown();
      });

    createCustomScroller('os-theme-input', this.selectContentRef.nativeElement);
  }

  toggleDropdown(): void {
    this.toggleSelectDropdown(this.ppId);
  }

  hideDropdown(): void {
    this.dropdown.visible = false;
    this.dropdownVisible = false;

    this.window.removeEventListener('keydown', this.closeOnEscRef, true);
    this.window.removeEventListener('click', this.clickOutsideRef, true);
  }

  selectItem(option: TSelectMultipleOption): void {
    this.updateSelectedItems();
    this.ppSelectItem.emit(option);
  }

  toggleSelectDropdown(buttonId: string): void {
    if (this.dropdown.visible && this.dropdown.buttonId === buttonId) {
      this.dropdownVisible = false;
      this.dropdownService.hideDropdown();
    } else {
      let width = this.element.nativeElement.clientWidth;

      this.setDropdownData();

      this.dropdownVisible = true;

      if (width < 150) {
        width = EDropdownWidth.PX_150;
      }

      this.dropdownService.showDropdown(buttonId, SelectMultipleDropdownComponent, {
        callback: (option) => this.selectItem(option),
        onClose: () => this.hideDropdown(),
        popper: {
          positionFixed: true,
          placement: 'bottom',
        },
        width,
        suppressScrollbar: false,
        addScrollCallback: false,
      });
    }
  }

  setDropdownData(): void {
    const dropdownData: TSelectMultipleDropdownData = {
      items: this.ppItems,
      noSearchBar: this.ppNoSearchBar,
      iconClass: this.ppIconClass,
    };

    this.dropdownService.setData(dropdownData);
  }

  checkItemsSelected(): boolean {
    return this.ppItems.some((item) => item.selected);
  }

  updateSelectedItems(): void {
    this.selectedItems = this.ppItems.filter((item) => item.selected);
  }
}
