import { cloneDeep } from 'lodash';

import { GET_ACCOUNTS, SET_ACCOUNTS } from 'src/app/project/modules/account/account.store';
import { SET_SHARES } from 'src/app/project/modules/share/shares.store';
import { GET_USER } from 'src/app/project/modules/user/user.store';
import {
  ADD_COLLAPSED_ACCOUNT,
  ADD_EDITED_FOLDER,
  CLEAR_SIDE_PANEL_SEARCH,
  DELETE_COLLAPSED_ACCOUNT,
  GET_SEARCH_VALUE,
  GET_VISIBLE_EMPTY_FOLDERS,
  SET_SIDE_PANEL_SEARCH,
} from '../utils/account-list.ui.store';

import { createElement } from '@core/helpers';

import { SharesApiProviderService } from '@core/api';
import { ApiService } from '@core/http';
import { Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { isBrowserSafari } from 'src/app/core/helpers/device';
import { translate } from 'src/app/project/features/translate/translate';
import { generateShares } from 'src/app/project/modules/share/share.utils';
import {
  TAccountSimpleResponse,
  TWorkspaceSimpleResponse,
} from 'src/app/project/view-models/account-simple-response.model';
import { ScreenService } from '../../../../core/services/window/screen.service';
import { BasicWorkspaceService } from '../../workspace/basic-workspace.service';
import AccountList, { TAccountListCallbacks } from '../account-list/AccountList';
import AccountSearch from '../account-search/AccountSearch';
import { NEW_FOLDER_ID } from '../utils/account-list-constants';
import { createEmptyElement } from '../utils/create-empty-element';

export default class AccountListPanel {
  private readonly destroy$ = new Subject<void>();

  element: HTMLElement = null;

  private accounts: TAccountSimpleResponse[] = [];
  private accountSearch: AccountSearch = null;

  private mobileHiddenClassName = 'hiddenMobile';

  private accountList: AccountList = null;

  constructor(
    private sharesApiProviderService: SharesApiProviderService,
    private basicWorkspaceService: BasicWorkspaceService,
    private screenService: ScreenService,
    private apiService: ApiService,
    private accountListCallbacks: TAccountListCallbacks,
  ) {
    CLEAR_SIDE_PANEL_SEARCH();

    this.accountSearch = new AccountSearch(this.apiService, (_value) => {
      this.accountSearchCallback(_value);
    });

    this.screenService.isMobile$.pipe(take(1)).subscribe((isMobile) => {
      this.element = this.create(isMobile);

      const user = GET_USER();

      this.populateAccounts(!user.isSuperUser);
    });

    this.screenService.isMobile$.pipe(takeUntil(this.destroy$)).subscribe((isMobile) => {
      if (isMobile) {
        this.element.classList.add(this.mobileHiddenClassName);
      } else {
        this.element.classList.remove(this.mobileHiddenClassName);
      }
    });
  }

  // --------------------------------------------------
  // -------------------- ELEMENTS --------------------
  // --------------------------------------------------

  create(isMobile: boolean): HTMLElement {
    const childrenList = [this.createHeaderElement(), this.accountSearch.element];
    const isSafari = isBrowserSafari();

    if (!isSafari) {
      childrenList.push(...[this.createTopShadowElement(), this.createBottomShadowElement()]);
    }

    return createElement('div', {
      attrs: {
        class: isMobile
          ? `sidePanel__right sidePanel__right--mobileEnabled ${this.mobileHiddenClassName}`
          : 'sidePanel__right',
      },
      children: childrenList,
    });
  }

  private populateAccounts(fetchUnsubscribed: boolean): void {
    this.basicWorkspaceService
      .fetchBasicWorkspaces(fetchUnsubscribed)
      .pipe(
        tap((_response: TAccountSimpleResponse[]) => {
          _response.forEach((_account) => {
            _account.workspaces.forEach((_workspace) => {
              _workspace.accountId = _account.id;
            });
          });

          this.accounts = cloneDeep(_response);

          this.accountList = new AccountList(
            this.element,
            this.accounts,
            this.apiService,
            this.accountListCallbacks,
          );

          this.element.appendChild(this.createMarginElementBottom());
          this.sharesApiProviderService
            .getAll()
            .pipe(
              tap((response) => {
                const workspaces = {};

                this.accounts.forEach((account) => {
                  account.workspaces.forEach((workspace) => {
                    workspaces[workspace.workspaceId] = workspace;
                  });
                });

                const shares = generateShares(response, workspaces);

                SET_SHARES(cloneDeep(shares));

                this.accountList.update(this.accounts, cloneDeep(_response));
                this.accounts = cloneDeep(_response);
                this.accountSearchCallback(GET_SEARCH_VALUE());

                this.load(false);
              }),
            )
            .subscribe();
        }),
      )
      .subscribe();
  }

  createMarginElementBottom(): HTMLElement {
    return createElement('div', {
      attrs: {
        class: 'sidePanel__right__bottomMargin',
      },
    });
  }

  createHeaderElement(): HTMLElement {
    return createElement('h4', {
      attrs: {
        class: 'sidePanel__right-header',
      },
      children: [
        'Accounts & Sites',
        this.createToggleCollapseElement(),
        this.createToggleUnsubscribedElement(),
      ],
    });
  }

  createTopShadowElement(): HTMLElement {
    return createElement('div', {
      attrs: {
        class: 'sidePanel__right__shadow sidePanel__right__shadow--top',
      },
    });
  }

  createBottomShadowElement(): HTMLElement {
    return createElement('div', {
      attrs: {
        class: 'sidePanel__right__shadow sidePanel__right__shadow--bottom',
      },
    });
  }

  createToggleCollapseElement(): HTMLElement {
    if (GET_USER().isSuperUser) {
      let collapsed = true;

      return createElement('span', {
        attrs: {
          style: {
            marginLeft: '1rem',
            fontSize: '$spacer-m',
            fontWeight: 'normal',
            textDecoration: 'underline',
            cursor: 'pointer',
          },
        },
        eventListeners: {
          click: () => {
            if (collapsed) {
              collapsed = false;

              this.accounts.forEach((_account) => {
                DELETE_COLLAPSED_ACCOUNT(_account.id);
              });
            } else {
              collapsed = true;

              this.accounts.forEach((_account) => {
                ADD_COLLAPSED_ACCOUNT(_account.id);
              });
            }

            this.accountList.load(this.accounts);
          },
        },
        children: [translate('toggle_collapse')],
      });
    }

    return createEmptyElement();
  }

  createToggleUnsubscribedElement(): HTMLElement {
    if (GET_USER().isSuperUser) {
      let showingUnsubscribed = false;

      return createElement('span', {
        attrs: {
          style: {
            marginLeft: '1rem',
            fontSize: '$spacer-m',
            fontWeight: 'normal',
            textDecoration: 'underline',
            cursor: 'pointer',
            display: 'block',
          },
        },
        eventListeners: {
          click: () => {
            if (showingUnsubscribed) {
              showingUnsubscribed = false;
            } else {
              showingUnsubscribed = true;
            }

            this.toggleUnsubscribed(showingUnsubscribed);
          },
        },
        children: [translate('toggle_unsubscribed')],
      });
    }

    return createEmptyElement();
  }

  toggleUnsubscribed(showUnsubscribed: boolean): void {
    this.basicWorkspaceService
      .fetchBasicWorkspaces(showUnsubscribed)
      .pipe(
        tap((_response: TAccountSimpleResponse[]) => {
          _response.forEach((_account) => {
            _account.workspaces.forEach((_workspace) => {
              _workspace.accountId = _account.id;
            });
          });

          this.accounts = cloneDeep(_response);
          this.accountSearchCallback(GET_SEARCH_VALUE());

          this.accountList.load(this.accounts, false);
        }),
      )
      .subscribe();
  }

  // --------------------------------------------------
  // --------------------- METHODS --------------------
  // --------------------------------------------------

  destroy(): void {
    this.destroy$.next();
    this.element.parentNode.removeChild(this.element);
  }

  load(keepScrollPosition: boolean = true): void {
    this.accountList.load(this.accounts, keepScrollPosition);
  }

  updateFolders(accountId: string, newAccount: TAccountSimpleResponse): void {
    const accounts = cloneDeep(GET_ACCOUNTS());
    const accountIndex = this.accounts.findIndex((_account) => _account.id === accountId);
    const _accountIndex = accounts.findIndex((_account) => _account.accountId === accountId);

    this.accounts[accountIndex].accountFolders = cloneDeep(newAccount.folders);
    accounts[_accountIndex].accountFolders = cloneDeep(newAccount.folders);

    SET_ACCOUNTS(accounts);

    this.load();
  }

  addNewFolder(accountId: string): void {
    const accountIndex = this.accounts.findIndex((_account) => _account.id === accountId);

    this.accounts[accountIndex].accountFolders.push({
      id: NEW_FOLDER_ID,
      name: '',
    });

    ADD_EDITED_FOLDER(accountId, NEW_FOLDER_ID);

    this.load();
  }

  getAccounts(): TAccountSimpleResponse[] {
    return this.accounts;
  }

  removeNewFolder(_accountId: string): void {
    const accountIndex = this.accounts.findIndex((_account) => _account.id === _accountId);
    const _folderId = this.accounts[accountIndex].accountFolders.findIndex(
      (_folder) => _folder.id === NEW_FOLDER_ID,
    );

    this.accounts[accountIndex].accountFolders.splice(_folderId, 1);
  }

  updateSiteName(siteId: string, siteName: string): void {
    const site = this.findSite(siteId);

    if (site) {
      site.name = siteName;
    }

    this.load();
  }

  findSite(siteId: string): TWorkspaceSimpleResponse {
    for (let i = 0; i < this.accounts.length; i++) {
      const account = this.accounts[i];

      for (let j = 0; j < account.workspaces.length; j++) {
        const workspace = account.workspaces[j];

        if (workspace.workspaceId === siteId) {
          return workspace;
        }
      }
    }
  }

  private accountSearchCallback(_value: string): void {
    SET_SIDE_PANEL_SEARCH(_value);

    if (!this.accountList) {
      return;
    }

    if (_value === '') {
      this.accountList.load(this.accounts);
    } else {
      const filteredData = cloneDeep(this.accounts).filter((_account: TAccountSimpleResponse) => {
        const matchesSuperUserSearch = this.matchesSuperUserSearch(_account, _value);

        if (matchesSuperUserSearch) {
          return true;
        }

        if (_account.name.toLowerCase().includes(_value.toLowerCase())) {
          return true;
        }

        return this.matchesWorkspaceSearch(_account, _value);
      });

      this.accountList.load(filteredData);
    }
  }

  private matchesSuperUserSearch(_account: TAccountSimpleResponse, _value: string): boolean {
    if (GET_USER().isSuperUser && _value.startsWith('type=')) {
      const type = _value.split('type=')[1].toUpperCase();

      if (_account.accountType === type) {
        return true;
      }
    }

    return false;
  }

  private matchesWorkspaceSearch(_account: TAccountSimpleResponse, _value: string): boolean {
    const workspaces = this.generateSearchWorkspaces(_account, _value);

    _account.workspaces = workspaces;

    if (workspaces.length) {
      return true;
    } else if (_account.accountFolders) {
      const emptyFolders = GET_VISIBLE_EMPTY_FOLDERS();

      for (let i = 0; i < _account.accountFolders.length; i++) {
        const folder = _account.accountFolders[i];

        if (
          emptyFolders[_account.id] &&
          emptyFolders[_account.id].includes(folder.name) &&
          folder.name.toLowerCase().includes(_value.toLowerCase())
        ) {
          return true;
        }
      }
    }

    return false;
  }

  private generateSearchWorkspaces(
    _account: TAccountSimpleResponse,
    _value: string,
  ): TWorkspaceSimpleResponse[] {
    return _account.workspaces.filter((_workspace) => {
      if (_workspace.name.toLowerCase().includes(_value.toLowerCase())) {
        return true;
      }

      if (_account.accountFolders) {
        for (let i = 0; i < _account.accountFolders.length; i++) {
          const folder = _account.accountFolders[i];

          if (folder.workspaceIds && folder.name.toLowerCase().includes(_value.toLowerCase())) {
            for (
              let workspaceFolderIndex = 0;
              workspaceFolderIndex < folder.workspaceIds.length;
              workspaceFolderIndex++
            ) {
              if (folder.workspaceIds[workspaceFolderIndex] === _workspace.workspaceId) {
                return true;
              }
            }
          }
        }
      }

      return false;
    });
  }
}
