import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, of } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { UpdateUserName } from 'src/app/project/modules/users/users.actions';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventUserSettings,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { TUserResponse } from '../../response-models/user-response-model';
import { UpdateEmailModalComponent } from '../../update-email-modal/update-email-modal.component';
import { UpdatePasswordModalComponent } from '../../update-password-modal/update-password-modal.component';
import { SetAvatarId } from '../../user.actions';
import { TUser } from '../../user.model';
import { UserService } from '../../user.service';

@Component({
  selector: 'pp-user-settings-profile',
  templateUrl: './user-settings-profile.component.html',
  styleUrls: ['./user-settings-profile.component.scss'],
})
export class UserSettingsProfileComponent implements OnInit, OnDestroy {
  @ViewChild('userNameInput') userNameInputElement: ElementRef;

  private user$: Observable<TUser>;
  private readonly destroy$ = new Subject<void>();

  userName = '';
  user: TUser;
  updatingUserName = false;

  constructor(
    private store: Store<{
      user: TUser;
    }>,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private userService: UserService,
    private modalService: ModalService,
  ) {
    this.user$ = this.store.pipe(select(EStore.USER));
  }

  ngOnInit(): void {
    this.subscribeToUserChange();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  tryUpdateUserName(): void {
    this.userNameInputElement.nativeElement.blur();

    if (this.updatingUserName) {
      return;
    }

    if (this.userName === this.user.userName) {
      return;
    }

    if (this.userName.length === 0) {
      const promptText = this.translationPipe.transform('prompt_empty_name');

      this.promptService.showWarning(promptText);
      return;
    }

    this.updateUserName();
  }

  editPassword(): void {
    this.modalService.showModal(UpdatePasswordModalComponent, {
      closeWarning: true,
    });
  }

  editEmail(): void {
    this.modalService.showModal(UpdateEmailModalComponent, {
      closeWarning: true,
    });
  }

  private subscribeToUserChange(): void {
    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => this.handleUser(user));
  }

  private updateUserName(): void {
    this.updatingUserName = true;

    this.userService
      .updateUserName(this.user.userId, this.userName)
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          this.handleUpdateUserResponse(response);
        }),
        catchError(() => {
          this.showUpdateUserError();

          return of();
        }),
        finalize(() => {
          this.updatingUserName = false;
        }),
      )
      .subscribe();
  }

  private handleUpdateUserResponse(response: TUserResponse): void {
    this.userService.generateUser(response, this.user.isSuperUser);

    this.updateAvatarIdInStore(response);
    this.updateUserNameInStore();

    this.emitGoogleEventForUserNameChange();
    this.showUpdateUserSuccess();
  }

  private emitGoogleEventForUserNameChange(): void {
    logEventInGTAG(EGoogleEventUserSettings.PROFILE_SETTINGS__USERNAME, {
      event_category: EGoogleEventCategory.PROFILE_SETTINGS,
    });
  }

  private updateUserNameInStore(): void {
    this.store.dispatch(new UpdateUserName({ userId: this.user.userId, userName: this.userName }));
  }

  private updateAvatarIdInStore(response: TUserResponse): void {
    // Default user avatar changes with user name
    this.store.dispatch(new SetAvatarId(response.images[0].id));
  }

  private showUpdateUserSuccess(): void {
    const promptText = this.translationPipe.transform('prompt_name_change_success');

    this.promptService.showSuccess(promptText);
  }

  private showUpdateUserError(): void {
    const promptText = this.translationPipe.transform('save_changes_failed');

    this.promptService.showError(promptText);
  }

  private handleUser(user: TUser): void {
    this.user = user;
    this.userName = this.user.userName;
  }
}
