import { Component, OnInit } from '@angular/core';
import { EIconPath } from '../../../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-dashlet-activities-placeholder',
  templateUrl: './dashlet-activities-placeholder.component.html',
  styleUrls: ['../dashlet-activity/dashlet-activity.component.scss'],
})
export class DashletActivitiesPlaceholderComponent implements OnInit {
  placeholders: number[];

  private numberOfPlaceholders = 10;

  EIconPath = EIconPath;

  ngOnInit(): void {
    this.placeholders = Array.from(Array(this.numberOfPlaceholders).keys());
  }
}
