import { Component, Input, OnChanges, OnDestroy } from '@angular/core';

import { TAccount, TAccountFeatures } from 'src/app/project/modules/account/account.model';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { AccountService } from 'src/app/project/modules/account/account-service/account.service';

import { Subject, of } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { setChangedAccounts } from 'src/app/project/modules/account/account';
import { getDefaultAccountFeatures } from 'src/app/project/modules/account/account-utils/default-account-features';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { setChangedWorkspaces } from 'src/app/project/modules/workspace/workspace';
import { TAccountResponse } from 'src/app/project/view-models/account-response.model';

@Component({
  selector: 'pp-account-settings-options',
  templateUrl: './account-settings-options.component.html',
  styleUrls: ['./account-settings-options.component.scss'],
})
export class AccountSettingsOptionsComponent implements OnChanges, OnDestroy {
  @Input() ppAccount: TAccount;
  @Input() ppWorkspaces: TWorkspacesById;
  @Input() ppOffline: boolean;
  @Input() ppShowHiddenOptions: boolean;

  private readonly destroy$ = new Subject<void>();

  updatingAccount = false;
  accountName: string;

  features = getDefaultAccountFeatures();

  constructor(
    private accountService: AccountService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
  ) {}

  ngOnChanges(): void {
    this.initAccount();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  initAccount(): void {
    if (this.ppAccount) {
      this.accountName = this.ppAccount.accountName;
      this.features = this.ppAccount.accountFeatures;
    }
  }

  updateAccountFeatures(features: TAccountFeatures): void {
    this.features = features;
  }

  blurInput(event: Event): void {
    (event.target as HTMLElement).blur();
  }

  updateAccount(): void {
    const body: Partial<TAccountResponse> = {
      accountFeatures: this.features,
      accountManager: this.ppAccount.accountManager,
      accountType: this.ppAccount.accountType,
      industryType: this.ppAccount.industryType,
      subscriptionType: this.ppAccount.subscriptionType,
    };

    this.updatingAccount = true;

    this.accountService
      .updateAccount(this.ppAccount.accountId, body)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          const prompt = this.translationPipe.transform('prompt_account_update');

          setChangedWorkspaces(true);
          setChangedAccounts(true);

          this.promptService.showSuccess(prompt);
        }),
        catchError((error) => {
          logErrorInSentry(error);
          const prompt = this.translationPipe.transform('prompt_error');

          this.promptService.showError(prompt);

          return of();
        }),
        finalize(() => {
          this.updatingAccount = false;
        }),
      )
      .subscribe();
  }
}
