import { createElement } from 'src/app/core/helpers/dom';
import { EDashletPeriod } from '../../dashboard-timeframes-enums';
import { addCommonStyles, getEndDate, getStartDate } from './dashboard-export.utils';

export function createDateElement(period: EDashletPeriod, scale: number): HTMLElement {
  const dateElement = createElement<HTMLElement>('div');

  dateElement.style.fontSize = `${42 * scale}px`;
  dateElement.style.lineHeight = `${53 * scale}px`;
  dateElement.style.top = `${220 * scale}px`;
  dateElement.style.fontWeight = 'bold';
  dateElement.style.fontFamily = 'Source Sans Pro, sans-serif';

  addCommonStyles(dateElement);

  const startDate = getStartDate({ period });
  const endDate = getEndDate();

  dateElement.innerHTML = `${startDate} ${startDate ? '-' : ''} ${endDate}`.trim();

  return dateElement;
}
