import { translate } from 'src/app/project/features/translate/translate';
import { ENotificationReason } from './notification-reason.enum';

export function getNotificationReasonText(notificationReason: ENotificationReason): string {
  return notificationReason === ENotificationReason.ASSIGNED
    ? translate('are_assigned_to')
    : notificationReason === ENotificationReason.CREATED
    ? translate('created')
    : translate('are_subscribed_to');
}
