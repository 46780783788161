import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntil } from 'rxjs';
import { TWorkspaceUsage } from '../../../../account-usage/account-usage.consts';
import { AccountSitesService } from '../account-sites.service';

@Component({
  selector: 'pp-account-settings-site',
  templateUrl: './account-settings-site.component.html',
  styleUrls: ['./account-settings-site.component.scss'],
})
export class AccountSettingsSiteComponent implements OnInit, OnChanges, OnDestroy {
  @Input() ppSite: TWorkspaceUsage;
  @Input() ppCanSeeArchived: boolean;

  @Output() ppNavigate = new EventEmitter<string>();

  private readonly destroy$ = new EventEmitter<void>();

  totalSize: string;
  pointCount: number;
  numberOfUsers: number;
  isSelected: boolean = false;

  constructor(private accountSitesService: AccountSitesService) {}

  ngOnInit(): void {
    this.accountSitesService.selectedSitesIdsChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedSites) => {
        this.isSelected = selectedSites.includes(this.ppSite.workspaceId);
      });
  }

  ngOnChanges(): void {
    this.tryInitData();

    this.isSelected = this.accountSitesService
      .getSelectedSitesIds()
      .includes(this.ppSite.workspaceId);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  navigateToSite(): void {
    if (!this.ppSite.archived) {
      this.ppNavigate.emit(this.ppSite.workspaceId);
    }
  }

  toggleSelected(): void {
    this.accountSitesService.toggleSiteSelected(this.ppSite.workspaceId);
  }

  private tryInitData(): void {
    if (this.ppSite) {
      this.calculateTotalSize();
      this.setPointCount();
      this.setNumberOfUsers();
    }
  }

  private calculateTotalSize(): void {
    this.totalSize = this.ppSite ? this.formatBytes(this.ppSite.totalBytes) : '0';
  }

  private setPointCount(): void {
    this.pointCount = this.ppSite ? this.ppSite.pointCount : 0;
  }

  private setNumberOfUsers(): void {
    this.numberOfUsers = this.ppSite ? this.ppSite.userCount : 0;
  }

  private formatBytes(bytes: number, decimals: number = 2): string {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  }
}
