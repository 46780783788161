<div class="selectDropdown" [ngStyle]="{ width: isMobile ? 'unset' : dropdownWidth }">
  <pp-hide-dropdown (ppHideDropdown)="hideDropdown()"></pp-hide-dropdown>

  <pp-search-box
    [ppPlaceholder]="'search_currencies' | ppTranslate"
    (ppAction)="setKeyword($event)"
    [ppFocusOnInit]="true"
    ppSize="wide"
    class="dropdown__searchBar"
  ></pp-search-box>

  <ul class="dropdownWrapper">
    <li
      *ngFor="let item of filteredItems"
      (click)="selectItem(item)"
      class="dropdownItem"
      [class.dropdownItem--selected]="selectedItem?.currencyCode === item.currencyCode"
    >
      <div class="currencyDropdownRow">
        <span class="currencyDropdownShort">
          {{ item.currencyCode }}
        </span>
        <span class="currencyDropdownName">
          {{ item.currencySymbol }} {{ item.currencyDisplayName }}
        </span>
      </div>
    </li>
  </ul>
</div>
