import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaveIndicatorComponent } from './save-indicator.component';
import { SpinnerModule } from '../spinner/spinner.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { IconModule } from '../icon/icon-module';

@NgModule({
  declarations: [SaveIndicatorComponent],
  imports: [CommonModule, SpinnerModule, PipesModule, IconModule],
  exports: [SaveIndicatorComponent],
})
export class SaveIndicatorModule {}
