import { cloneDeep } from 'lodash';

import { ActionsUnion, EActionType } from './preferences.actions';
import { TPreferences } from './preferences.model';

export const initialState: TPreferences = {
  workspaces: {},
};

let newState: TPreferences = {
  workspaces: {},
};

let actionState;

export function preferencesReducer(
  state: TPreferences = initialState,
  action: ActionsUnion,
): TPreferences {
  switch (action.type) {
    case EActionType.SET_PREFERENCES:
      newState = cloneDeep(state);

      actionState = cloneDeep(action.payload);

      if (!newState.workspaces) {
        newState.workspaces = {};
      }

      if (!actionState.workspaces) {
        actionState.workspaces = newState.workspaces;
      }

      newState = {
        ...actionState,
      };

      return newState;

    case EActionType.SET_WORKSPACE_PREFERENCES:
      newState = cloneDeep(state);

      if (!newState.workspaces) {
        newState.workspaces = {};
      }

      newState.workspaces[action.payload.workspaceId] = action.payload.preferences;

      return newState;

    case EActionType.UPDATE_OVERVIEW_PREFERENCES:
      newState = cloneDeep(state);

      newState.overviewColumns = action.payload.preferences;

      return newState;

    case EActionType.CLEAR_PREFERENCES:
      return {
        workspaces: {},
      };

    case EActionType.UPDATE_PREFERENCES:
      newState = cloneDeep(state);

      Object.keys(action.payload).forEach((key) => {
        newState[key] = action.payload[key];
      });

      return newState;

    case EActionType.UPDATE_WORKSPACE_PREFERENCES: {
      newState = cloneDeep(state);
      const preferences = cloneDeep(action.payload.preferences);

      if (!newState.workspaces) {
        newState.workspaces = {};
      }

      if (newState.workspaces[action.payload.workspaceId]) {
        preferences.exports = newState.workspaces[action.payload.workspaceId].exports;
      }

      newState.workspaces[action.payload.workspaceId] = action.payload.preferences;

      return newState;
    }
    case EActionType.UPDATE_WORKSPACE_EXPORT_PREFERENCES:
      newState = cloneDeep(state);

      if (!newState.workspaces) {
        newState.workspaces = {};
      }

      if (!newState.workspaces[action.payload.workspaceId]) {
        newState.workspaces[action.payload.workspaceId] = {};
      }

      newState.workspaces[action.payload.workspaceId].exports = action.payload.preferences.exports;

      return newState;

    case EActionType.UPDATE_WORKSPACE_OFFLINE_PREFERENCES:
      newState = cloneDeep(state);

      if (!newState.workspaces) {
        newState.workspaces = {};
      }

      if (newState.workspaces) {
        Object.keys(action.payload).forEach((key) => {
          if (!newState.workspaces[key]) {
            newState.workspaces[key] = {};
          }
        });
      }

      return newState;

    default:
      return state;
  }
}
