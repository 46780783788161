import { Pipe, PipeTransform } from '@angular/core';

import { checkForKeyword } from './highlight-keyword/utils/search-for-keyword';
import { sanitizeHTML } from '../helpers/text-sanitizer';

@Pipe({
  name: 'highlightKeyword',
})
export class HighlightKeywordPipe implements PipeTransform {
  transform(text: string, keyword: string): string {
    const splitText = checkForKeyword(text, keyword);

    let result = '';

    splitText.forEach((textFragment) => {
      const elementClass = textFragment.highlight ? 'text--highlighted' : '';

      result += '<span class=' + elementClass + '>' + sanitizeHTML(textFragment.text) + '</span>';
    });

    return result;
  }
}
