import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SocketService } from '../socket.service';
import { locationHostFactory } from '../../../../core/services/window/locationFactory';
import { ButtonModule } from 'src/app/project/components/button/button.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, FormsModule, ButtonModule, PipesModule],
  providers: [SocketService, { provide: 'HOSTNAME', useFactory: locationHostFactory }],
  schemas: [NO_ERRORS_SCHEMA],
})
export class SocketModule {}
