<div class="pointCF__field">
  <label
    ppTooltip
    class="pointCF__label pointCF__label--rich-text"
    ppTitle="{{ ppCustomField?.label }}"
    [ppTrimTooltip]="true"
  >
    <img
      class="pointCF__bulk-changes-field-icon"
      [src]="EIconPath.CUSTOM_FIELD_RICH_TEXT_ACTIVITY"
    />

    <span class="pointCF__label-container">
      {{ ppCustomField?.label }}
    </span>
  </label>

  <div class="pointCF__content">
    <pp-custom-field-rich-text
      [ppField]="ppCustomField"
      [ppWorkspaceId]="ppWorkspaceId"
      [ppValue]="ppCustomFields[ppFieldId]"
      (ppUpdate)="updateRichText($event, ppCustomField)"
    ></pp-custom-field-rich-text>
  </div>
</div>
