<section class="newAssetBackWrapper" *ngIf="canGoBack">
  <button class="newAssetBackButton" (click)="goBack()" data-test="backToFleetButton">
    <pp-icon [ppSrc]="EIconPath.ARROW_BACK" ppColor="inherit" ppClass="setSize18"></pp-icon>
    {{ 'back_to_fleet' | ppTranslate }}
  </button>
</section>

<section class="emptyFleetContainer">
  <div class="emptyFleet">
    <pp-fleet-management-new-fleet-name-and-account
      [(ppAccountId)]="accountId"
      [ppAccountSelectDisabled]="editingFleet"
      [(ppFleetName)]="newFleet.name"
    ></pp-fleet-management-new-fleet-name-and-account>

    <hr class="newAssetHr" />

    <pp-fleet-management-new-fleet-status-weights
      [(ppStatuses)]="newFleet.statusSettings"
    ></pp-fleet-management-new-fleet-status-weights>

    <hr class="newAssetHr" />

    <pp-fleet-management-new-fleet-status-filter
      [(ppStatuses)]="newFleet.statusSettings"
    ></pp-fleet-management-new-fleet-status-filter>

    <div class="emptyFleetCreateButton">
      <pp-button
        ppStyle="primary"
        (ppAction)="saveChanges()"
        ppSize="large"
        [ppDisabled]="!newFleet.name || !accountId"
        >{{ (fleetId ? 'update_fleet' : 'create_fleet') | ppTranslate }}</pp-button
      >
    </div>
  </div>
</section>
