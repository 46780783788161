<div
  class="twoFactorAuthModePickerOptionWrapper"
  [class.twoFactorAuthModePickerOptionWrapper--selected]="ppSelected"
  (click)="onValueChange()"
>
  <div class="twoFactorAuthModePickerOption">
    <div class="twoFactorAuthModelPickerOptionTop">
      <div
        class="twoFactorAuthModelPickerOptionIconWrapper"
        [class.twoFactorAuthModelPickerOptionIconWrapper--selected]="ppSelected"
      >
        <pp-icon
          [ppSrc]="ppIcon"
          ppClass="setSize18"
          [ppColor]="ppSelected ? 'primary-400' : 'grey-400'"
        ></pp-icon>
      </div>

      <pp-radio [ppChecked]="ppSelected" [ppNoMargin]="true"></pp-radio>
    </div>

    <span class="twoFactorAuthModelPickerOptionBottom">{{ ppText }}</span>
  </div>
</div>
