import { createElement } from 'src/app/core/helpers/dom';
import { GET_TIMELINE } from '../../timeline.ui.store';

export function createPointCountElement(_index: number): HTMLElement {
  const timeline = GET_TIMELINE();
  const item = timeline.items[_index];
  const pointCount = timeline.groupedPoints.find((_group) => _group.value === item.context).points
    .length;

  return createElement('div', {
    attrs: {
      class: 'timeline__pointCount',
    },
    children: [pointCount],
  });
}
