import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EExportFormat } from '../../../exports/utils/export-page-format-enum';

@Component({
  selector: 'pp-fleet-export-page-format-picker',
  templateUrl: './fleet-export-page-format-picker.component.html',
  styleUrl: './fleet-export-page-format-picker.component.scss',
})
export class FleetExportPageFormatPickerComponent {
  @Input() ppPageFormat: EExportFormat;
  @Output() ppPageFormatChange = new EventEmitter<EExportFormat>();

  EExportFormat = EExportFormat;

  selectFormat(mode: EExportFormat): void {
    this.ppPageFormatChange.emit(mode);
  }
}
