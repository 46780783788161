export function getMonthTimeframes(startYear: number, numberOfYears: number): number[] {
  let elements = [];

  for (let i = 0; i < numberOfYears; i++) {
    for (let _months = 0; _months < 12; _months++) {
      const nextEpoch = new Date(startYear + i, _months + 1, 1).getTime();

      elements = [...elements, nextEpoch];
    }
  }

  return elements;
}
