import {
  Component,
  OnChanges,
  AfterViewInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'pp-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnChanges, AfterViewInit {
  @ViewChild('uploadComponent', { static: true }) element: ElementRef;

  @Input() ppId: string;
  @Input() ppAccept: string;
  @Input() ppMultiple: boolean;
  @Input() ppDisabled: boolean;
  @Output() ppUpload = new EventEmitter<File[]>();

  constructor() {}

  ngOnChanges() {
    if (this.ppMultiple) {
      this.element.nativeElement.setAttribute('multiple', 'multiple');
    } else {
      this.element.nativeElement.removeAttribute('multiple');
    }
  }

  ngAfterViewInit() {
    this.element.nativeElement.addEventListener('change', (event) => {
      const fileList = [];

      Array.from(event.target.files).forEach((file) => {
        fileList.push(file);
      });

      this.ppUpload.emit(fileList);

      event.target.value = '';
    });
  }
}
