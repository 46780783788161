<div id="groupDropdown" #groupDropdown class="siteOptions__dropdown">
  <pp-button-dropdown
    [ppSrc]="EIconPath.ICON_GROUP_24"
    [ppText]="'group' | ppTranslate"
    [ppNumber]="grouping.length ? ' / ' + grouping.length : ''"
    [ppActive]="groupSelected || dropdownVisible"
    (ppAction)="toggleGroupDropdown()"
    ppTooltip
    data-m-target="Group dropdown button"
    [id]="groupDropdownBtnId"
    ppTitle="Group points"
  ></pp-button-dropdown>
</div>
