import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { getDragIcon } from 'src/app/core/helpers/dragging/get-drag-icon';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { GET_ACCOUNT } from 'src/app/project/modules/account/account.store';
import { ResponseErrorService } from 'src/app/project/modules/errors/response-error.service';
import { checkSavedViewsEnabled } from 'src/app/project/modules/filters/site-filter-dropdown/site-filter-dropdown-utils/check-saved-views-enabled';
import { PreferencesService } from 'src/app/project/modules/preferences/preferences-service/preferences.service';
import { TSavedView } from 'src/app/project/modules/saved-views/models/saved-view.model';
import { SavedViewsService } from 'src/app/project/modules/saved-views/saved-views.service';
import { PermissionsService } from 'src/app/project/modules/share/permissions.service';
import { TUser } from 'src/app/project/modules/user/user.model';
import { UserService } from 'src/app/project/modules/user/user.service';
import { checkCustomWorkspaceId } from 'src/app/project/modules/workspace/workspace';
import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventGrouping,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { GoogleAnalyticsService } from 'src/app/project/services/analytics/google-analytics.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { ResetTableService } from '../../../reset-table.service';
import { TColumn } from '../../../site-table/columns/column.model';
import { GET_GROUPING } from '../../../site-table/columns/grouping.store';
import { TableColumnsService } from '../../../site-table/columns/table-columns.service';
import { CustomTableService } from '../../../site-table/custom-table/custom-table.service';
import Table from '../../../site-table/custom-table/table/Table';
import {
  SiteTableColumnsDataService,
  TColumnsData,
} from '../../../site-table/site-table-columns-data.service';
import { ESortOrder } from '../../../site-table/sort-order-enum';
import { GroupButtonDropdownService } from './group-button-dropdown.service';

@Component({
  selector: 'pp-group-button-dropdown',
  templateUrl: './group-button-dropdown.component.html',
  styleUrls: ['./group-button-dropdown.component.scss'],
})
export class GroupButtonDropdownComponent implements OnDestroy {
  @ViewChild('columnTable') columnsTableElement: ElementRef;

  columns: TColumnsData = this.siteTableColumnsDataService.getColumns();
  private readonly destroy$ = new Subject<void>();

  public tagsVisible: boolean;
  public user: TUser;
  workspace: TWorkspace;
  workspaces: TWorkspacesById;
  siteId = '';
  draggedColumn: TColumn = null;
  destinationIndex: number = null;
  rowHeight = 28;
  rowGap = 8;
  linePosition = this.rowHeight + this.rowGap;
  scrollPosition = 0;
  scrolledToBottom = false;
  globalSitePreferences = false;
  grouping: TColumn[] = null;
  EIconPath = EIconPath;
  emptyGroupsVisible: number[] = [];

  savedViewsEnabled = false;
  isDropdownVisible: boolean;
  activeViewName = '';
  activeView: TSavedView;
  saveViewProcessing = false;

  private table: Table = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private dropdownService: DropdownService,
    private siteTableColumnsDataService: SiteTableColumnsDataService,
    private workspaceService: WorkspaceService,
    private activeService: ActiveService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private userService: UserService,
    private permissionsService: PermissionsService,
    private preferencesService: PreferencesService,
    private groupButtonDropdownService: GroupButtonDropdownService,
    private customTableService: CustomTableService,
    private savedViewsService: SavedViewsService,
    private accountService: AccountService,
    private responseErrorService: ResponseErrorService,
    private tableColumnsService: TableColumnsService,
    private resetTableService: ResetTableService,
  ) {
    this.groupButtonDropdownService.groupingDataChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.grouping = GET_GROUPING();
      });
  }

  ngOnInit() {
    this.table = this.customTableService.getTable();
    this.grouping = GET_GROUPING();

    this.workspace = this.workspaceService.getActiveWorkspace();
    this.workspaces = this.workspaceService.getWorkspaces();
    const accounts = this.accountService.getAccounts();
    this.user = this.userService.getUser();

    if (this.workspace) {
      const account = GET_ACCOUNT();
      const tagPermissions = this.permissionsService.getTagPermissions(this.workspace.workspaceId);
      this.globalSitePreferences = account.accountFeatures?.globalSitePreferences;
      this.siteId = this.workspace.workspaceId;
      this.tagsVisible = tagPermissions.read;
    } else {
      this.columns = this.siteTableColumnsDataService.getAllColumns();
      this.tagsVisible = true;
      this.siteId = checkCustomWorkspaceId();
      this.globalSitePreferences = false;
    }

    this.savedViewsEnabled = checkSavedViewsEnabled(
      this.workspaces,
      accounts,
      this.workspace?.workspaceId,
    );

    if (this.savedViewsEnabled) {
      const activeViewId = this.savedViewsService.getSelectedViewId(this.workspace.workspaceId);
      this.activeView = this.savedViewsService.getView(activeViewId);
      this.activeViewName = this.activeView?.name;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  toggleColumn(_column: TColumn): void {
    const columnType = this.googleAnalyticsService.getColumnType(_column);
    let workspaceId = this.activeService.getActiveWorkspaceId();

    if (_column.hidden) {
      logEventInGTAG(EGoogleEventSite.SITE__COLUMNS__SHOW, {
        event_category: EGoogleEventCategory.SITE,
        event_details: columnType,
      });
    } else {
      logEventInGTAG(EGoogleEventSite.SITE__COLUMNS__HIDE, {
        event_category: EGoogleEventCategory.SITE,
        event_details: columnType,
      });
    }

    this.table.toggleColumn(_column);

    if (!workspaceId) {
      workspaceId = checkCustomWorkspaceId();
    }

    this.preferencesService.savePreferences(workspaceId);
  }

  clearAll(): void {
    this.resetTableService.resetGrouping();
    this.hideDropdown();
  }

  onDragStart(event: DragEvent, i: number, column: TColumn): void {
    const img = new Image();

    this.draggedColumn = column;
    this.draggedColumn.index = i;
    img.src = getDragIcon();
    this.linePosition =
      this.columnsTableElement.nativeElement.children[i].offsetTop - this.rowGap / 2;

    event.dataTransfer.setData('text/plain', column.name);
    event.dataTransfer.setDragImage(img, 0, 0);
  }

  onDragOver(event: DragEvent, index: number, field: TColumn): void {
    event.preventDefault();
    event.stopPropagation();

    const itemRowElement = this.document.getElementById('itemRow-' + field.name);
    const targetElementCoords = itemRowElement.getBoundingClientRect();
    const elementCenterY =
      targetElementCoords.top + (targetElementCoords.bottom - targetElementCoords.top) / 2;

    let offset = 0;

    this.destinationIndex = index;
    let linePositionIndex = this.destinationIndex;

    if (event.clientY > elementCenterY) {
      this.destinationIndex += 1;

      linePositionIndex = this.destinationIndex;

      if (index >= this.draggedColumn.groupIndex) {
        this.destinationIndex -= 1;
      }
    } else {
      if (index > this.draggedColumn.groupIndex) {
        this.destinationIndex -= 1;
      }
    }

    if (linePositionIndex > this.columnsTableElement.nativeElement.children.length - 1) {
      linePositionIndex = this.columnsTableElement.nativeElement.children.length - 1;

      offset = this.rowHeight + this.rowGap;
    }

    if (linePositionIndex === 0) {
      this.linePosition = this.rowGap / 2;
    } else {
      this.linePosition =
        this.columnsTableElement.nativeElement.children[linePositionIndex].offsetTop +
        offset -
        this.rowGap / 2;
    }
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  updateColumnValue(column: TColumn): void {
    this.toggleColumn(column);
  }

  onDragEnd(): void {
    const columns = this.tableColumnsService.getColumns();

    if (columns) {
      let workspaceId = this.activeService.getActiveWorkspaceId();

      if (this.draggedColumn.groupIndex !== this.destinationIndex) {
        this.groupButtonDropdownService.moveGroup(this.draggedColumn, this.destinationIndex);
        this.grouping = GET_GROUPING();
      }

      this.draggedColumn = null;
      this.destinationIndex = null;

      if (!workspaceId) {
        workspaceId = checkCustomWorkspaceId();
      }

      this.preferencesService.savePreferences(workspaceId);
    }
  }

  scrollHandler(event: Event): void {
    if (event.target instanceof HTMLElement) {
      this.scrollPosition = event.target.scrollTop;
      this.scrolledToBottom =
        event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
    }
  }

  hideDropdown(): void {
    this.dropdownService.hideDropdown();
  }

  deleteGroup(column: TColumn): void {
    this.groupButtonDropdownService.removeGroup(column);

    this.grouping = GET_GROUPING();

    logEventInGTAG(EGoogleEventGrouping.DELETE_GROUPING, {
      event_category: EGoogleEventCategory.GROUPING,
      event_details: this.googleAnalyticsService.getColumnType(column),
    });
  }

  addEmptyGroup(): void {
    this.emptyGroupsVisible.push(1);
  }

  deleteEmptyGroup(): void {
    this.emptyGroupsVisible.pop();
  }

  updateEmptyGroupColumn(column: TColumn): void {
    this.groupButtonDropdownService.addGroup(column, 'ASC');

    logEventInGTAG(EGoogleEventGrouping.ADD_GROUPING, {
      event_category: EGoogleEventCategory.GROUPING,
      event_details: this.googleAnalyticsService.getColumnType(column),
    });

    this.grouping = GET_GROUPING();

    this.emptyGroupsVisible.pop();
  }

  saveGroupsToView(): void {
    this.activeView.columns = this.updateViewColumnsGrouping();

    this.saveViewProcessing = true;

    this.savedViewsService
      .saveToView(this.activeView, this.workspace.workspaceId)
      .pipe(
        tap(() => {
          this.saveViewProcessing = false;
        }),
        catchError((error) => {
          this.saveViewProcessing = false;
          return this.responseErrorService.handleRequestError(error);
        }),
      )
      .subscribe();
  }

  private updateViewColumnsGrouping(): TColumn[] {
    // We need to only update grouping and leave sorting, position, width and hidden as is
    const columns = this.tableColumnsService.getColumns();

    return this.activeView.columns.map((column) => {
      // If we enable saved views for overview we'll also need to check custom field ids because name won't be enough
      const matchingColumn = columns.find((c) => c.name === column.name);

      if (matchingColumn) {
        column.groupIndex = matchingColumn.groupIndex;
        column.groupOrder = matchingColumn.groupOrder;
      } else {
        column.groupIndex = null;
        column.groupOrder = null;
      }

      return column;
    });
  }

  updateOrder(item: ESortOrder, column: TColumn): void {
    this.groupButtonDropdownService.changeGroupOrder(item, column);
  }

  updateColumn(column: TColumn, oldColumn: TColumn): void {
    this.groupButtonDropdownService.replaceGroup(column, oldColumn);

    logEventInGTAG(EGoogleEventGrouping.UPDATE_GROUP, {
      event_category: EGoogleEventCategory.GROUPING,
      event_details: this.googleAnalyticsService.getColumnType(column),
    });
  }
}
