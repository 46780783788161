import { Injectable } from '@angular/core';

import { DeviceService } from '@core/services';
import { map, Observable, of, switchMap } from 'rxjs';
import { TDashlet } from '../../../preferences/preferences.model';
import { TExportDashlet } from '../../dashboard-export-modal/dashboard-export-dashlet.model';
import { DashboardService } from '../../dashboard-service/dashboard.service';
import { TDashletStackedResponse } from '../../dashboard.consts';
import { TDashletGraph } from '../../dashlet-graph.model';
import { EDashletType } from '../../dashlets-enums';
import { CreateCostComparisonGraphService } from './create-cost-comparison-graph.service';
import { CreateStackedStatusGraphService } from './create-stacked-status-graph.service';
import { createCurrentPriorityGraph } from './current-priority-graph';
import { createCurrentStatusGraph } from './current-status-graph';
import { createHistoricPriorityGraph } from './historic-priority-graph';
import { createHistoricStatusGraph } from './historic-status-graph';

export type TDashletNames = {
  account: string;
  workspace: string;
};

@Injectable({
  providedIn: 'root',
})
export class DashletService {
  isMobile = false;
  isTablet = false;

  constructor(
    private deviceService: DeviceService,
    private createStackedStatusGraphService: CreateStackedStatusGraphService,
    private createCostComparisonGraphService: CreateCostComparisonGraphService,
    private dashboardService: DashboardService,
  ) {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
  }

  createCurrentPriorityGraph(isExport: boolean): TDashletGraph {
    return createCurrentPriorityGraph(this.isMobile || this.isTablet, isExport);
  }

  createCurrentStatusGraph(isExport: boolean): TDashletGraph {
    return createCurrentStatusGraph(this.isMobile || this.isTablet, isExport);
  }

  createHistoricStatusGraph(isExport: boolean): TDashletGraph {
    return createHistoricStatusGraph(this.isMobile || this.isTablet, isExport);
  }

  createHistoricPriorityGraph(isExport: boolean): TDashletGraph {
    return createHistoricPriorityGraph(this.isMobile || this.isTablet, isExport);
  }

  createStackedStatusGraph(response: TDashletStackedResponse, isExport: boolean): TDashletGraph {
    return this.createStackedStatusGraphService.createStackedStatusGraph(response, isExport);
  }

  getDashletGraph(dashlet: TDashlet | TExportDashlet): TDashletGraph {
    switch (dashlet.name) {
      case EDashletType.CURRENT_STATUS:
        return this.createCurrentStatusGraph(false);
      case EDashletType.CURRENT_PRIORITY:
        return this.createCurrentPriorityGraph(false);
      case EDashletType.OVER_TIME_STATUS:
        return this.createHistoricStatusGraph(false);
      case EDashletType.OVER_TIME_PRIORITY:
        return this.createHistoricPriorityGraph(false);
    }
  }

  getExportDashletGraph(
    dashlet: TDashlet | TExportDashlet,
    assetId: string,
  ): Observable<TDashletGraph> {
    switch (dashlet.name) {
      case EDashletType.CURRENT_STATUS:
        return this.dashboardService
          .fetchCurrentTypeCount({
            type: dashlet.name,
            accountId: dashlet.selectedRange.accountId,
            workspaceIds: dashlet.selectedRange.workspaceIds,
          })
          .pipe(
            map((response) => {
              return this.createCurrentStatusGraph(true);
            }),
          );
      case EDashletType.CURRENT_PRIORITY:
        return this.dashboardService
          .fetchCurrentTypeCount({
            type: dashlet.name,
            accountId: dashlet.selectedRange.accountId,
            workspaceIds: dashlet.selectedRange.workspaceIds,
          })
          .pipe(
            map((response) => {
              return this.createCurrentPriorityGraph(true);
            }),
          );
      case EDashletType.OVER_TIME_STATUS:
        return this.dashboardService
          .fetchReports({
            type: dashlet.name,
            period: dashlet.period,
            length: 5,
            accountId: dashlet.selectedRange.accountId,
            workspaceIds: dashlet.selectedRange.workspaceIds,
          })
          .pipe(
            map((response) => {
              return this.createHistoricStatusGraph(true);
            }),
          );
      case EDashletType.OVER_TIME_PRIORITY:
        return this.dashboardService
          .fetchReports({
            type: dashlet.name,
            period: dashlet.period,
            length: 5,
            accountId: dashlet.selectedRange.accountId,
            workspaceIds: dashlet.selectedRange.workspaceIds,
          })
          .pipe(
            map((response) => {
              return this.createHistoricPriorityGraph(true);
            }),
          );
      case EDashletType.COST_COMPARISON: {
        return this.createCostComparisonGraph(assetId, dashlet);
      }
      case EDashletType.CURRENT_STACKED_STATUS: {
        return this.createStackedSstatusGraph(dashlet);
      }
    }
  }

  private createStackedSstatusGraph(dashlet: TDashlet | TExportDashlet): Observable<TDashletGraph> {
    return this.dashboardService
      .fetchStackedStatus({
        accountId: dashlet.selectedRange.accountId,
        workspaceIds: dashlet.selectedRange.workspaceIds,
      })
      .pipe(
        switchMap((response) => {
          return of(this.createStackedStatusGraph(response, false));
        }),
      );
  }

  private createCostComparisonGraph(
    assetId: string,
    dashlet: TDashlet | TExportDashlet,
  ): Observable<TDashletGraph> {
    return this.dashboardService
      .fetchCostComparison({
        assetId,
        workspacesCosts: dashlet.selectedRange.workspacesCosts,
      })
      .pipe(
        switchMap((response) => {
          return of(
            this.createCostComparisonGraphService.createCostComparisonGraph(response, true),
          );
        }),
      );
  }
}
