import { cloneDeep } from 'lodash';
import { SetAllPoints } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';

export function setAllPoints(
  oldState: TPointsByWorkspace,
  action: SetAllPoints,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);

  Object.keys(action.payload.pointsByWorkspace).forEach((workspaceId) => {
    if (!state[workspaceId]) {
      state[workspaceId] = {};
    }

    state[workspaceId].entities = action.payload.pointsByWorkspace[workspaceId];
  });

  return state;
}
