<section class="userDropdown__user">
  <div class="userDropdown__user-row">
    <img
      ppImage
      class="userDropdown__avatar"
      *ngIf="users[userId]?.avatarPublicUrl"
      [src]="users[userId].avatarPublicUrl"
    />

    <img
      class="userDropdown__avatar"
      *ngIf="!users[userId]?.avatarPublicUrl"
      [src]="EIconPath.PLACEHOLDER_USER"
    />

    <p class="userDropdown__user-name">
      {{ users[userId].userName }}
    </p>
  </div>

  <button
    class="userDropdown__remove-text"
    (click)="removeAssignee(); $event.stopPropagation()"
    *ngIf="!disabled"
  >
    {{ message }}
  </button>
</section>
