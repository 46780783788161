import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';

import { DeviceService } from 'src/app/core/services/device.service';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { TSelectOption } from '../select-tag.model';
import { TSelectTagDropdownData } from './select-tag-dropdown.model';

@Component({
  selector: 'pp-select-tag-dropdown',
  templateUrl: './select-tag-dropdown.component.html',
  styleUrls: ['./select-tag-dropdown.component.scss'],
})
export class SelectTagDropdownComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  dropdownWidth: string = '558px';
  EIconPath = EIconPath;
  isMobile = false;
  searchBox = '';
  items: string[];
  filteredItems: string[] = [];
  noneAccess = false;
  hideButtons = false;
  message: string = null;
  dropdownDataWidth: string;

  private tagWidths: { [key: string]: number } = {};
  private minimalDropdownWidthForTags: number;
  private readonly dropdownPaddingPx = 12;
  private readonly minimalSearchBoxWidthPx = 160;
  private readonly scrollbarWidthPx = 8;
  private dropdown: TDropdown = this.dropdownService.getDropdown();

  constructor(private dropdownService: DropdownService, private deviceService: DeviceService) {
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit(): void {
    this.setDataFromDropdown();

    this.dropdownService.dropdownDataChanged$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setDataFromDropdown();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  hideDropdown(): void {
    this.dropdownService.hideDropdown();
  }

  selectItem(item: string | TSelectOption): void {
    this.dropdown.callback(item);
    delete this.tagWidths[item as string];
    this.setWidth();
  }

  clearAll(): void {
    this.dropdown.callback();
    this.hideDropdown();
  }

  setSearchBox(keyword: string): void {
    this.searchBox = keyword;

    this.filterItems();
    this.setWidth();
  }

  updateDropdownWidth(element: HTMLElement, item: string): void {
    this.tagWidths[item] = element.clientWidth;

    this.setWidth();
  }

  private setDataFromDropdown(): void {
    const dropdownData: TSelectTagDropdownData = this.dropdown.data;

    this.hideButtons = dropdownData.hideButtons;
    this.items = dropdownData.items;
    this.message = dropdownData.message;
    this.noneAccess = dropdownData.noneAccess;
    this.dropdownDataWidth = dropdownData.width;

    this.filterItems();
    this.setMinimalDropdownWidthForTags(this.calculateMinimalDropdownWidthForTags());

    this.setWidth();
  }

  private setMinimalDropdownWidthForTags(widthPx: number): void {
    this.minimalDropdownWidthForTags = widthPx;
  }

  private calculateMinimalDropdownWidthForTags(): number {
    if (this.dropdownDataWidth) {
      return parseFloat(this.dropdownDataWidth);
    }

    let dropdownWidth = this.dropdownPaddingPx * 2;
    const widthPx = parseFloat(this.dropdownWidth);

    dropdownWidth += this.minimalSearchBoxWidthPx;

    if (dropdownWidth < widthPx) {
      dropdownWidth = widthPx;
    }

    return dropdownWidth;
  }

  private setWidth(): void {
    const filteredTagWidths = this.filteredItems.map(
      (filteredItem) => this.tagWidths[filteredItem],
    );
    const largestTagWidth = Math.max(...filteredTagWidths);
    const largestTagWidthWidth =
      largestTagWidth + this.dropdownPaddingPx * 2 + this.scrollbarWidthPx;

    if (this.minimalDropdownWidthForTags < largestTagWidthWidth) {
      this.dropdownWidth = `${largestTagWidthWidth}px`;
    } else {
      this.dropdownWidth = `${this.minimalDropdownWidthForTags}px`;
    }
  }

  private filterItems(): void {
    const filteredItems = [];

    this.items.forEach((item) => {
      if (item.toLowerCase().includes(this.searchBox.toLowerCase())) {
        filteredItems.push(item);
      }
    });

    this.filteredItems = filteredItems;
  }
}
