import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagComponent } from './tag.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { IconModule } from '../icon/icon-module';

@NgModule({
  declarations: [TagComponent],
  imports: [CommonModule, PipesModule, IconModule],
  exports: [TagComponent],
})
export class TagModule {}
