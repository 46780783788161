import { TAnyFunction } from '@core/helpers';
import { createElement } from 'src/app/core/helpers/dom';
import { TColumn } from '../../../columns/column.model';
import { GET_COLUMNS } from '../../../columns/columns.store';
import { GET_GROUPING } from '../../../columns/grouping.store';
import { GET_TABLE } from '../../../table.ui.store';
import { moveColumnsMousedown } from '../move-columns';
import { createHeaderCellInnerElement } from './create-header-cell-inner-element';
import { createResizeHandleElement } from './create-resize-handle-element';

export function createHeaderCellElement({
  name = null,
  child = null,
  className = null,
  center = false,
  resize = true,
  width = 'auto',
  callback = null,
  column = null,
  apiService = null,
}: {
  name?: string;
  child?: HTMLElement | string;
  className?: string;
  center?: boolean;
  resize?: boolean;
  width?: string;
  callback?: TAnyFunction;
  column?: TColumn;
  apiService?: any;
} = {}): HTMLElement {
  const table = GET_TABLE();
  const cellInnerElement = createHeaderCellInnerElement(child, column, apiService);
  const grouping = GET_GROUPING();
  const isLastElement = column?.index === GET_COLUMNS().length - 1;
  let columnWidth = width;

  if (center) {
    cellInnerElement.style.textAlign = 'center';
    cellInnerElement.style.padding = '0';

    if (!name) {
      cellInnerElement.style.display = 'flex';
      cellInnerElement.style.justifyContent = 'center';
      cellInnerElement.style.alignItems = 'center';
    }
  }

  if (isLastElement && grouping.length) {
    columnWidth = `calc(${width} + ${grouping.length * 16}px)`;
  }

  const cellElement = createElement<HTMLElement>('div', {
    attrs: {
      class: className ? `table__cell__head ${className}` : 'table__cell__head',
      style: {
        width: columnWidth,
      },
    },
    eventListeners: {
      mousedown: (_event: MouseEvent) =>
        resize ? moveColumnsMousedown(_event, column, cellElement) : (): void => {},
    },
    children: [cellInnerElement, resize ? createResizeHandleElement() : ''],
  });

  const childElement =
    cellInnerElement.children[0].querySelector('#sortingElement') ||
    cellInnerElement.children[1].querySelector('#sortingElement');

  childElement?.addEventListener('mousedown', (_event) => {
    _event.stopPropagation();

    if (callback) {
      callback();
    } else {
      table.tableHead.sortColumn(column);
      table.tableHead.load(true);
    }
  });

  return cellElement;
}
