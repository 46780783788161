import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FleetManagementLandingPageService {
  private fleetManagementElement: HTMLElement;

  setFleetManagementElement(fleetManagementElement: HTMLElement): void {
    this.fleetManagementElement = fleetManagementElement;
  }

  getFleetManagementElement(): HTMLElement {
    return this.fleetManagementElement;
  }

  resetFleetManagementScroll(): void {
    this.fleetManagementElement.scrollTop = 0;
  }
}
