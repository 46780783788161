import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getDefaultFilter } from '../filters/site-filter-data-service/default-filter';
import { SetSiteFilter } from '../filters/site-filter.actions';
import { TFilters } from '../filters/site-filter.model';
import { TFleetStatuses } from '../fleet-management/fleet.consts';

@Injectable({
  providedIn: 'root',
})
export class FilterAssetService {
  private openingSiteFromAsset = false;
  private openSiteWithRedFlags = false;
  private filterStatuses: TFleetStatuses;

  constructor(private store: Store) {}

  getOpeningSiteFromAsset(): boolean {
    return this.openingSiteFromAsset;
  }

  setOpeningSiteFromAsset(
    openingSiteFromAsset: boolean,
    filterStatuses: TFleetStatuses,
    redFlags: boolean,
  ): void {
    this.openingSiteFromAsset = openingSiteFromAsset;
    this.filterStatuses = filterStatuses;
    this.openSiteWithRedFlags = redFlags;
  }

  createAssetFilter(workspaceId: string, filters: TFilters): void {
    

    if (this.openSiteWithRedFlags) {
      filters = getDefaultFilter();
      filters.showFlaggedPoints = true;
      filters.showUnflaggedPoints = false;
    }

    this.addFleetStatusFilters(filters);

    this.store.dispatch(
      new SetSiteFilter({
        filter: filters,
        workspaceId: workspaceId,
      }),
    );
  }

  private addFleetStatusFilters(filters: TFilters) {
    filters.status.forEach((status) => {
      status.appliedValue = this.filterStatuses?.[status.label].include;
      status.value = this.filterStatuses?.[status.label].include;
    });

    filters.statusDefault = false;
  }
}
