import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ClearFilterService } from '../../clear-filter-service/clear-filter.service';
import { TFilters, TStatusFilter } from '../../site-filter.model';

@Component({
  selector: 'pp-filter-field-status',
  templateUrl: './filter-field-status.component.html',
  styleUrls: ['../../site-filter-dropdown/site-filter-dropdown.component.scss'],
})
export class FilterFieldStatusComponent implements OnChanges {
  @Input() ppFilters: TFilters;
  @Output() ppApply = new EventEmitter();

  isStatusDefault = true;

  constructor(private clearFilterService: ClearFilterService) {}

  ngOnChanges(): void {
    this.checkStatusDefault();
  }

  updateStatusValue(status: TStatusFilter): void {
    status.value = !status.value;

    this.checkStatusDefault();
    this.applyFilters();
  }

  checkStatusDefault(): void {
    this.isStatusDefault = true;

    this.ppFilters.status.forEach((status) => {
      if (status.value !== status.default) {
        this.isStatusDefault = false;
      }
    });
  }

  clearStatus(): void {
    this.ppFilters.status = this.clearFilterService.clearStatus();

    this.applyFilters();
  }

  applyFilters(): void {
    this.ppApply.emit();
  }
}
