import { EPriority } from 'src/app/project/modules/points/priorities';
import { EStatus } from '../../../shared/enums/status.enum';
import { TFilters } from '../site-filter.model';

export function getDefaultFilter(): TFilters {
  const defaultFilter: TFilters = {
    keyword: '',
    pointsWithKeyword: [],
    priority: [
      {
        label: EPriority.HIGH,
        title: 'High',
        value: true,
        appliedValue: true,
        default: true,
        color: '#4DA0E5',
      },
      {
        label: EPriority.MEDIUM,
        title: 'Medium',
        value: true,
        appliedValue: true,
        default: true,
        color: '#FFCE29',
      },
      {
        label: EPriority.LOW,
        title: 'Low',
        value: true,
        appliedValue: true,
        default: true,
        color: '#E54F50',
      },
    ],
    priorityDefault: true,
    status: [
      {
        label: EStatus.OPEN,
        title: 'Open',
        value: true,
        appliedValue: true,
        default: true,
      },
      {
        label: EStatus.IN_PROGRESS,
        title: 'In progress',
        value: true,
        appliedValue: true,
        default: true,
      },
      {
        label: EStatus.TO_REVIEW,
        title: 'To Review',
        value: true,
        appliedValue: true,
        default: true,
      },
      {
        label: EStatus.ONHOLD,
        title: 'On hold',
        value: true,
        appliedValue: true,
        default: true,
      },
      {
        label: EStatus.CLOSED,
        title: 'Completed',
        value: false,
        appliedValue: false,
        default: false,
      },
      {
        label: EStatus.CANCELED,
        title: 'Canceled',
        value: false,
        appliedValue: false,
        default: false,
      },
    ],
    statusDefault: true,
    tags: {
      value: [],
      appliedValue: [],
      applied: true,
      combinedTags: false,
    },
    excludedTags: {
      value: [],
      appliedValue: [],
      applied: true,
      combinedTags: false,
    },
    date: {
      created: {
        startDate: null,
        endDate: null,
        appliedValues: {
          startDate: null,
          endDate: null,
        },
        applied: false,
      },
      updated: {
        startDate: null,
        endDate: null,
        appliedValues: {
          startDate: null,
          endDate: null,
        },
        applied: false,
      },
    },
    createdBy: {
      value: [],
      appliedValue: [],
      applied: false,
    },
    assignees: {
      value: [],
      appliedValue: [],
      applied: false,
    },
    customFields: [],
    sites: [],
    showPointsWithoutLocation: true,
    showPointsWithLocation: true,
    showUnflaggedPoints: true,
    showFlaggedPoints: true,
    reminders: {
      startDate: null,
      endDate: null,
    },
  };

  return defaultFilter;
}
