<pp-modal>
  <ng-container heading> {{ 'edit_site_name' | ppTranslate }} </ng-container>

  <ng-container body>
    <input
      #siteNameInput
      class="input input-border-bottom input-inline"
      type="text"
      [(ngModel)]="siteName"
      placeholder="Site name"
      (keydown.enter)="save()"
      required
    />
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'save' | ppTranslate"
      (ppCancel)="hideModal()"
      (ppConfirm)="save()"
      [ppProcess]="processing"
      [ppDisabled]="disabled()"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
