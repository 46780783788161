import dayjs from 'dayjs';
import { TPoint } from 'src/app/project/modules/points/points.model';
import { TFilters } from '../site-filter.model';

export function checkUpdatedDate(point: TPoint, filters: TFilters): boolean {
  const updatedDate = point.header.updatedEpochMillis;

  if (filters.date.updated.appliedValues.startDate && filters.date.updated.appliedValues.endDate) {
    if (
      updatedDate >= +filters.date.updated.appliedValues.startDate &&
      updatedDate <= +dayjs(filters.date.updated.appliedValues.endDate).endOf('day')
    ) {
      return true;
    }
  } else if (
    filters.date.updated.appliedValues.startDate &&
    !filters.date.updated.appliedValues.endDate
  ) {
    if (updatedDate >= +filters.date.updated.appliedValues.startDate) {
      return true;
    }
  } else if (
    !filters.date.updated.appliedValues.startDate &&
    filters.date.updated.appliedValues.endDate
  ) {
    if (updatedDate <= +dayjs(filters.date.updated.appliedValues.endDate).endOf('day')) {
      return true;
    }
  } else {
    return true;
  }

  return false;
}
