<div
  id="{{ ppId }}"
  class="deleteButtonWrapper"
  [class.deleteButtonWrapper--disabled]="ppDisabled"
  [class.dragging]="ppDragging"
  (click)="action()"
>
  <app-spinner [ppButton]="true" [ppHidden]="!ppProcess"></app-spinner>

  <pp-icon
    *ngIf="!ppProcess"
    [ppSrc]="EIconPath.ICON_BIN_14"
    ppClass="setSize14"
    ppColor="inherit"
  ></pp-icon>
</div>
