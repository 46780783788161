<div class="toggle-content">
  <ng-content></ng-content>
</div>

<label id="{{ ppId }}" class="toggle" [ngClass]="toggleClass">
  <input type="checkbox" [checked]="ppChecked" [disabled]="ppDisabled" (click)="action($event)" />

  <span class="slider" [ngClass]="sliderClass">
    <img
      [class.slider__current-icon]="!ppChecked"
      [src]="EIconPath.INPUT_TOGGLE_POINT_LIST"
      *ngIf="ppStyle === 'floating'"
    />

    <img
      [class.slider__current-icon]="ppChecked"
      [src]="EIconPath.INPUT_TOGGLE_SITE_PLAN"
      *ngIf="ppStyle === 'floating'"
    />
  </span>
</label>
