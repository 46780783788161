import { SidePanelService } from './side-panel.service';

let sidePanelService: SidePanelService = null;

export function GET_SIDE_PANEL_SERVICE(): SidePanelService {
  return sidePanelService;
}

// This way we can avoid circular dependency injection in components that are called from this service but need to access it
export function SET_SIDE_PANEL_SERVICE(_sidePanelService: SidePanelService): void {
  sidePanelService = _sidePanelService;
}
