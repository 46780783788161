import { Injectable } from '@angular/core';
import { TActivityResponse } from '@project/view-models';
import {
  merge360Images,
  mergeAddedFiles,
  mergeAssignees,
  mergeLocationActivities,
  mergeRemovedDocuments,
  mergeRemovedImages,
  mergeVideos,
} from '../../../activities/activities-merge';

@Injectable({
  providedIn: 'root',
})
export class PointActivityMergeService {
  private unmergeableActivityTypes = ['NEW_DOCUMENT', 'DELETE'];
  private mergeTimeMs = 30000;

  mergeActivities(data: any): any[] {
    const mergedActivities = data.reduce((mergedActivities, activity) => {
      if (!mergedActivities.length) {
        return [activity];
      }

      const prevActivity = mergedActivities[mergedActivities.length - 1];

      if (
        prevActivity.header.createdBy.id !== activity.header.createdBy.id ||
        this.unmergeableActivityTypes.includes(activity.activityType) ||
        this.unmergeableActivityTypes.includes(prevActivity.activityType) ||
        prevActivity.data.comment ||
        activity.data.comment ||
        !activity.data.changeList ||
        this.mergeTimeExeeded(prevActivity, activity) ||
        prevActivity.data.ref.id !== activity.data.ref.id
      ) {
        return [...mergedActivities, activity];
      }

      let merged = false;

      prevActivity.data.changeList.forEach((change) => {
        let changed = false;

        switch (change.label) {
          // Images
          case 'Images':
            changed = this.mergeImagesChanges(change, activity);
            break;

          // 360 Images
          case 'image360':
          case '360 Images':
            changed = this.merge360ImagesChanges(change, activity);
            break;

          // Videos
          case 'Videos':
          case 'video':
            changed = this.mergeVideosChanges(change, activity);
            break;

          // Dicuments
          case 'Documents':
            changed = this.mergeDocumentsChanges(change, activity);
            break;

          // Pins
          case 'additionalPins':
          case 'polygons':
          case 'pins':
            changed = this.mergePinsChanges(change, activity);
            break;

          case 'Assignees':
            changed = this.mergeAssigneesChanges(change, activity);
            break;
        }

        if (changed) {
          merged = true;
          prevActivity.mergedTime = activity.header.createdEpochMillis;
        }
      });

      if (!merged) {
        prevActivity.data.changeList.push(...activity.data.changeList);
      }

      return mergedActivities;
    }, []);

    mergeLocationActivities(mergedActivities);

    return mergedActivities;
  }

  checkAndInitAssigneesChanges(change): void {
    this.correctChangesFields(change);

    if (!change.added && !change.deleted) {
      change.added = [];
      change.deleted = [];

      mergeAssignees(change, change);
    }
  }

  private mergeTimeExeeded(prevActivity: any, activity: TActivityResponse): boolean {
    return (
      prevActivity.header.createdEpochMillis - activity.header.createdEpochMillis >
        this.mergeTimeMs &&
      // mergedTime - so we can merge activities if LAST activity is 30 seconds apart from the next one, not the first
      (!prevActivity.mergedTime ||
        prevActivity.mergedTime - activity.header.createdEpochMillis > this.mergeTimeMs)
    );
  }

  private mergeImagesChanges(prevChange: any, activity: TActivityResponse): boolean {
    const currentChange = activity.data.changeList[0];

    if (currentChange.label !== 'Images' || currentChange.filesChange !== prevChange.filesChange) {
      return false;
    }

    // separate merging logic for simplified data after point has been copied/moved (different response from backend)
    if (currentChange.filesChange === 'ADDED') {
      if (prevChange.newValue.length === 0) {
        prevChange.newValue.push('image' + prevChange.newValue.length + 1);
      }

      prevChange.newValue.push('image' + prevChange.newValue.length + 1);

      return true;
    }

    if (currentChange.filesChange === 'DELETED') {
      if (prevChange.oldValue.length === 0) {
        prevChange.oldValue.push('file' + prevChange.oldValue.length + 1);
      }

      prevChange.oldValue.push('file' + prevChange.newValue.length + 1);

      return true;
    }

    // logic for "normal" images activities

    // adding images
    if (currentChange.oldValue.length <= currentChange.newValue.length) {
      return mergeAddedFiles(prevChange, activity, activity);
    }

    // removing images
    if (
      currentChange.oldValue.length > currentChange.newValue.length &&
      prevChange.oldValue.length > prevChange.newValue.length
    ) {
      return mergeRemovedImages(prevChange, activity, activity);
    }

    return false;
  }

  private merge360ImagesChanges(prevChange: any, activity: TActivityResponse): boolean {
    const currentChange = activity.data.changeList[0];

    // separate merging logic for simplified data after point has been copied/moved (different response from backend)
    if (
      currentChange.label === '360 Images' &&
      prevChange.label === currentChange.label &&
      currentChange.filesChange === 'ADDED'
    ) {
      if (prevChange.newValue.length === 0) {
        prevChange.newValue.push('image360' + prevChange.newValue.length + 1);
      }

      prevChange.newValue.push('image360' + prevChange.newValue.length + 1);

      return true;
    }

    if (
      currentChange.label === 'image360' &&
      prevChange.label === currentChange.label &&
      currentChange.filesChange === 'DELETED'
    ) {
      if (prevChange.oldValue.length === 0) {
        prevChange.oldValue.push('file' + prevChange.oldValue.length + 1);
      }

      prevChange.oldValue.push('file' + prevChange.newValue.length + 1);

      return true;
    }

    // logic for "normal" document activities
    if (
      currentChange.label === '360 Images' &&
      prevChange.label === currentChange.label &&
      currentChange.oldValue.length <= currentChange.newValue.length
    ) {
      return merge360Images(prevChange, activity, activity);
    }

    return false;
  }

  private mergeVideosChanges(prevChange: any, activity: TActivityResponse): boolean {
    const currentChange = activity.data.changeList[0];

    // separate merging logic for simplified data after point has been copied/moved (different response from backend)
    if (
      currentChange.label === 'Videos' &&
      prevChange.label === currentChange.label &&
      currentChange.filesChange === 'ADDED'
    ) {
      if (prevChange.newValue.length === 0) {
        prevChange.newValue.push('video' + prevChange.newValue.length + 1);
      }

      prevChange.newValue.push('video' + prevChange.newValue.length + 1);

      return true;
    }

    if (
      currentChange.label === 'video' &&
      prevChange.label === currentChange.label &&
      currentChange.filesChange === 'DELETED'
    ) {
      if (prevChange.oldValue.length === 0) {
        prevChange.oldValue.push('file' + prevChange.oldValue.length + 1);
      }

      prevChange.oldValue.push('file' + prevChange.newValue.length + 1);

      return true;
    }

    // logic for "normal" document activities
    if (
      currentChange.label === 'Videos' &&
      prevChange.label === currentChange.label &&
      !(currentChange.oldValue.length > currentChange.newValue.length)
    ) {
      return mergeVideos(prevChange, activity, activity);
    }

    return false;
  }

  private mergeDocumentsChanges(prevChange: any, activity: TActivityResponse): boolean {
    const currentChange = activity.data.changeList[0];

    if (currentChange.label !== 'Documents') {
      return false;
    }

    if (currentChange.filesChange === 'ADDED') {
      if (prevChange.newValue.length === 0) {
        prevChange.newValue.push('file' + prevChange.newValue.length + 1);
      }

      // 'file' because we no longer use file names in Activity and it's more convenient
      prevChange.newValue.push('file' + prevChange.newValue.length + 1);

      return true;
    }

    if (currentChange.filesChange === 'DELETED') {
      if (prevChange.oldValue.length === 0) {
        prevChange.oldValue.push('file' + prevChange.oldValue.length + 1);
      }

      prevChange.oldValue.push('file' + prevChange.newValue.length + 1);

      return true;
    }

    // logic for "normal" document activities

    // adding documents
    if (
      currentChange.oldValue.length < currentChange.newValue.length &&
      prevChange.oldValue.length < prevChange.newValue.length
    ) {
      return mergeAddedFiles(prevChange, activity, activity);
    }

    // removing documents
    if (
      currentChange.oldValue.length > currentChange.newValue.length &&
      prevChange.oldValue.length > prevChange.newValue.length
    ) {
      return mergeRemovedDocuments(prevChange, activity, activity);
    }

    return false;
  }

  private mergePinsChanges(change: any, activity: TActivityResponse): boolean {
    const currentChange = activity.data.changeList[0];
    const pinPropNames = ['additionalPins', 'polygons', 'pins'];

    return pinPropNames.includes(currentChange.propName);
  }

  private mergeAssigneesChanges(prevChange: any, activity: any): boolean {
    const currentChange = activity.data.changeList[0];

    if (currentChange.label !== 'Assignees') {
      return false;
    }

    this.correctChangesFields(prevChange);
    this.correctChangesFields(currentChange);

    if (this.isTheSameAction(prevChange, currentChange)) {
      this.checkAndInitAssigneesChanges(prevChange);
      mergeAssignees(prevChange, currentChange);

      return true;
    }

    this.checkAndInitAssigneesChanges(currentChange);

    return false;
  }

  // Workaround for old points. Sometimes api send a model without these fields
  private correctChangesFields(change): void {
    if (!change.newValue) {
      change.newValue = [];
    }
    if (!change.oldValue) {
      change.oldValue = [];
    }
  }

  private isTheSameAction(prevChange, currentChange): boolean {
    return (
      (prevChange.newValue.length > prevChange.oldValue.length &&
        currentChange.newValue.length > currentChange.oldValue.length) ||
      (prevChange.newValue.length < prevChange.oldValue.length &&
        currentChange.newValue.length < currentChange.oldValue.length)
    );
  }
}
