import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'pp-notification-mode-select',
  templateUrl: './notification-mode-select.component.html',
  styleUrls: ['./notification-mode-select.component.scss'],
})
export class NotificationModeSelectComponent {
  @Input() ppSelected = 0;

  @Input() ppFirstDataTarget: string;
  @Input() ppSecondDataTarget: string;
  @Input() ppThirdDataTarget: string;
  @Input() ppFourthDataTarget: string;

  @Input() ppNoBottomBorder = false;

  @Output() ppFirstChoice = new EventEmitter();
  @Output() ppSecondChoice = new EventEmitter();
  @Output() ppThirdChoice = new EventEmitter();
  @Output() ppFourthChoice = new EventEmitter();

  @ViewChild('firstNotificationSelectSelectOption') firstNotificationSelectSelectOption: ElementRef;
  @ViewChild('secondNotificationSelectSelectOption')
  secondNotificationSelectSelectOption: ElementRef;
  @ViewChild('thirdNotificationSelectSelectOption') thirdNotificationSelectSelectOption: ElementRef;
  @ViewChild('fourthNotificationSelectSelectOption')
  fourthNotificationSelectSelectOption: ElementRef;

  ngAfterViewInit() {
    if (this.ppFirstDataTarget) {
      this.fourthNotificationSelectSelectOption.nativeElement.setAttribute(
        'data-m-target',
        this.ppFirstDataTarget,
      );
    }

    if (this.ppSecondDataTarget) {
      this.fourthNotificationSelectSelectOption.nativeElement.setAttribute(
        'data-m-target',
        this.ppSecondDataTarget,
      );
    }

    if (this.ppThirdDataTarget) {
      this.fourthNotificationSelectSelectOption.nativeElement.setAttribute(
        'data-m-target',
        this.ppThirdDataTarget,
      );
    }

    if (this.ppFourthDataTarget) {
      this.fourthNotificationSelectSelectOption.nativeElement.setAttribute(
        'data-m-target',
        this.ppFourthDataTarget,
      );
    }
  }

  triggerFirstOption(): void {
    this.ppFirstChoice.emit();
  }

  triggerSecondOption(): void {
    this.ppSecondChoice.emit();
  }

  triggerThirdOption(): void {
    this.ppThirdChoice.emit();
  }

  triggerFourthOption(): void {
    this.ppFourthChoice.emit();
  }
}
