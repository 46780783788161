import dayjs from 'dayjs';
import { TPoint } from 'src/app/project/modules/points/points.model';
import { TFilters } from '../site-filter.model';

export function checkCreatedDate(point: TPoint, filters: TFilters): boolean {
  const createdDate = point.header.createdEpochMillis;

  if (filters.date.created.appliedValues.startDate && filters.date.created.appliedValues.endDate) {
    if (
      createdDate >= +filters.date.created.appliedValues.startDate &&
      createdDate <= +dayjs(filters.date.created.appliedValues.endDate).endOf('day')
    ) {
      return true;
    }
  } else if (
    filters.date.created.appliedValues.startDate &&
    !filters.date.created.appliedValues.endDate
  ) {
    if (createdDate >= +filters.date.created.appliedValues.startDate) {
      return true;
    }
  } else if (
    !filters.date.created.appliedValues.startDate &&
    filters.date.created.appliedValues.endDate
  ) {
    if (createdDate <= +dayjs(filters.date.created.appliedValues.endDate).endOf('day')) {
      return true;
    }
  } else {
    return true;
  }

  return false;
}
