import { Component } from '@angular/core';

import { ModalService } from '../../../components/modal/modal.service';
import { EditPointService } from '../point-full-modal/edit-point.service';
import { PointAttachmentsService } from '../point-modal/point-attachments/point-attachments.service';

@Component({
  selector: 'pp-close-point-modal',
  templateUrl: './close-point-modal.component.html',
  styleUrls: ['./close-point-modal.component.scss'],
})
export class ClosePointModalComponent {
  constructor(
    private modalService: ModalService,
    private pointAttachmentsService: PointAttachmentsService,
    private editPointService: EditPointService,
  ) {}

  closePoint(): void {
    this.pointAttachmentsService.cancelUploads();
    this.editPointService.hideNewModal(true); // Hide new point modal
    this.hideModal(); // hide "Are you sure you want to close this point without saving?" modal
  }

  hideModal(): void {
    this.modalService.hideModal();
  }
}
