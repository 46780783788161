import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TExportAsset } from '../../fleet-management-fleet-page/fleet-assets/export-asset-modal/export-asset-modal.component.consts';

@Component({
  selector: 'pp-fleet-export-page-asset-picker',
  templateUrl: './fleet-export-page-asset-picker.component.html',
  styleUrl: './fleet-export-page-asset-picker.component.scss',
})
export class FleetExportPageAssetPickerComponent {
  @Input() ppExportAsset: TExportAsset;
  @Input() ppSelectable: boolean;
  @Output() ppExportAssetChange = new EventEmitter<TExportAsset>();

  onDashletToggle(): void {
    this.ppExportAssetChange.emit(this.ppExportAsset);
  }

  toggleAsset(): void {
    if (!this.ppSelectable) {
      return;
    }

    this.ppExportAsset.selected = !this.ppExportAsset.selected;

    this.ppExportAssetChange.emit(this.ppExportAsset);
  }
}
