import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, of } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { UpdateWorkspaceTags } from 'src/app/project/modules/workspace/workspace.actions';

import { TUser } from 'src/app/project/modules/user/user.model';
import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { ImportTagModalComponent } from 'src/app/project/modules/tags/import-tag-modal/import-tag-modal.component';
import { DeleteTagModalComponent } from '../../../tags/delete-tag-modal/delete-tag-modal.component';

import { SortingService } from '@core/helpers';
import { SidePanelService } from 'src/app/project/modules/layout/side-panel/side-panel.service';
import { OfflineService } from 'src/app/project/modules/offline/offline.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { ModalService } from '../../../../components/modal/modal.service';
import { PromptService } from '../../../../components/prompt/prompt.service';
import { TagsService } from '../../../tags/tags.service';
import { SiteDataService } from '../../site-data.service';

import { Title } from '@angular/platform-browser';
import { ScreenService } from '@core/services';
import { TTag } from 'src/app/project/data-providers/api-providers/tag-api-provider/tag-usage.model';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { generateErrorPrompt } from 'src/app/project/modules/errors/response-error';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import {
  setChangedWorkspace,
  setChangedWorkspaces,
} from 'src/app/project/modules/workspace/workspace';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSettings,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { InputFocusService } from '../../../../../core/services/input-focus.service';
import { TImportTagModalData } from '../../../tags/import-tag-modal/import-tag-modal.model';
import { TAG_BANNED_CHARACTERS } from '../../../tags/tag-banned-characters.const';

@Component({
  selector: 'pp-site-settings-tags',
  templateUrl: './site-settings-tags.component.html',
  styleUrls: ['./site-settings-tags.component.scss'],
})
export class SiteSettingsTagsComponent implements OnInit, OnDestroy {
  @ViewChild('tagNameInput') tagNameInputElement: ElementRef;

  private readonly destroy$ = new Subject<void>();

  tags: TTag[] = [];

  EUserRole = EUserRole;
  private workspaceId: string;
  newTag = '';
  processingTag = false;

  private offline$: Observable<boolean>;
  offline: boolean;
  private user$: Observable<TUser>;
  user: TUser;
  private workspaces$: Observable<TWorkspacesById>;
  workspace: TWorkspace;
  workspacesLoaded = true;
  tagsLoaded = false;
  tagNameInvalid: boolean;

  constructor(
    private store: Store<{
      offline: boolean;
      user: TUser;
      workspaces: TWorkspacesById;
    }>,
    private activatedRoute: ActivatedRoute,
    private siteDataService: SiteDataService,
    private tagsService: TagsService,
    private promptService: PromptService,
    private modalService: ModalService,
    private workspaceService: WorkspaceService,
    private activeService: ActiveService,
    private offlineService: OfflineService,
    private sidePanelService: SidePanelService,
    private sortingService: SortingService,
    private translationPipe: TranslationPipe,
    private titleService: Title,
    private screenService: ScreenService,
    private inputFocusService: InputFocusService,
  ) {
    this.offline$ = this.store.pipe(select(EStore.OFFLINE));
    this.user$ = this.store.pipe(select(EStore.USER));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
  }

  ngOnInit() {
    this.titleService.setTitle('Site settings | Pinpoint Works');
    this.workspacesLoaded = false;

    this.tagsService.tagsUpdated$
      .pipe(
        takeUntil(this.destroy$),
        tap((tags) => {
          const workspace = this.workspaceService.getWorkspace(this.workspaceId);
          this.tags = tags;

          if (workspace) {
            this.store.dispatch(
              new UpdateWorkspaceTags({
                workspaceId: this.workspaceId,
                tags: tags.map((tag) => tag.name),
              }),
            );
          }
        }),
      )
      .subscribe();

    this.screenService.isDesktop$.pipe(takeUntil(this.destroy$)).subscribe((isDesktop) => {
      if (!isDesktop) {
        this.sidePanelService.collapsePanel();
      }
    });

    this.activatedRoute.parent.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.workspaceId = params.get('id');
      this.tagsLoaded = false;

      this.tagsService
        .fetchTagUsage(this.workspaceId)
        .pipe(
          takeUntil(this.destroy$),
          tap(() => {
            this.tagsLoaded = true;
          }),
        )
        .subscribe();

      this.setWorkspace();
    });

    this.offline$.pipe(takeUntil(this.destroy$)).subscribe((offline) => {
      this.offline = offline;
    });

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setWorkspace();
    });

    this.workspaceService
      .generateWorkspaces()
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.workspacesLoaded = true;

          this.setWorkspace();
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  setWorkspace(): void {
    this.workspace = this.workspaceService.findWorkspace(this.workspaceId);
  }

  addTag(): void {
    let warning = false;
    let promptText = this.translationPipe.transform('prompt_error');
    this.newTag = this.newTag?.trim();

    this.tagNameInputElement.nativeElement.blur();

    if (this.newTag.length === 0) {
      warning = true;
      promptText = this.translationPipe.transform('prompt_empty_name');
    } else if (this.newTag.length > 50) {
      warning = true;
      promptText = this.translationPipe.transform('prompt_tag_over_limit_50');
    } else {
      const index = this.tags.findIndex((tag) => tag.name === this.newTag);

      if (index === -1) {
        if (this.newTag.indexOf(TAG_BANNED_CHARACTERS[0]) !== -1) {
          warning = true;
          promptText = this.translationPipe.transform('prompt_invalid_character_comma');
        } else if (this.newTag.indexOf(TAG_BANNED_CHARACTERS[1]) !== -1) {
          warning = true;
          promptText = this.translationPipe.transform('prompt_invalid_character_vertical_bar');
        } else if (this.newTag.indexOf(TAG_BANNED_CHARACTERS[2]) !== -1) {
          warning = true;
          promptText = this.translationPipe.transform('prompt_invalid_character_plus');
        } else {
          const workspaceId = this.activeService.getActiveWorkspaceId();
          const workspaces = this.workspaceService.getWorkspaces();

          this.processingTag = true;
          workspaces[workspaceId].tags.push(this.newTag);

          this.siteDataService
            .addTags(workspaceId, [this.newTag], true)
            .pipe(
              tap(() => {
                promptText = this.translationPipe.transform('prompt_new_tag_added');

                this.tagsService.addTag(this.newTag);

                this.newTag = '';

                setChangedWorkspaces(true);
                setChangedWorkspace(workspaceId);

                logEventInGTAG(EGoogleEventSettings.SETTINGS__TAGS__NEW, {
                  event_category: EGoogleEventCategory.SETTINGS,
                });

                this.promptService.showSuccess(promptText);

                this.inputFocusService.focus(this.tagNameInputElement.nativeElement);
              }),
              catchError((error) => {
                promptText = generateErrorPrompt(error, 'prompt_error');

                this.promptService.showError(promptText);

                return of();
              }),
              finalize(() => {
                this.processingTag = false;
              }),
            )
            .subscribe();
        }
      } else {
        warning = true;
        promptText = this.translationPipe.transform('prompt_tag_name_taken');
      }
    }

    if (warning) {
      this.promptService.showWarning(promptText);

      this.inputFocusService.focus(this.tagNameInputElement.nativeElement);
    }
  }

  deleteTag(tag: TTag): void {
    const offline = this.offlineService.getOffline();

    if (!offline) {
      this.modalService.setData(tag);
      this.modalService.showModal(DeleteTagModalComponent);
    }
  }

  toggleTagsImportModal(): void {
    const offline = this.offlineService.getOffline();

    if (!offline) {
      this.modalService.setData<TImportTagModalData>({
        workspaceId: this.workspaceId,
      });

      this.modalService.showModal(ImportTagModalComponent);
    }
  }

  checkNewTag(event: Event): void {
    const newValue = (event.target as HTMLInputElement).value.trim();

    const index = this.tags.findIndex((tag) => tag.name === newValue);

    let containsBannedCharacter = false;

    TAG_BANNED_CHARACTERS.forEach((character) => {
      if (newValue.includes(character)) {
        containsBannedCharacter = true;
      }
    });

    this.tagNameInvalid = index !== -1 || containsBannedCharacter;
  }
}
