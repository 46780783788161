<section
  class="userManagementUserSites"
  [id]="elementId"
  [class.userManagementUserSites--disabled]="disabled"
  [class.userManagementUserSites--active]="dropdown?.visible && dropdown?.buttonId === elementId"
>
  <div class="userManagementUserSites__chip">
    {{ ppUser.shares.length }}
  </div>

  <div class="userManagementUserSites__options" (click)="toggleUserDropdown($event)">
    <pp-icon
      [ppSrc]="EIconPath.ICON_NAV_THREE_DOTS_VERTICAL_18"
      ppClass="setSize18"
      ppColor="grey-600"
      [ppDisplayContents]="true"
    ></pp-icon>
  </div>
</section>
