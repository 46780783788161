import { Injectable } from '@angular/core';
import { Extent } from 'ol/extent';
import { TPlanFeature, TPlanMap, TPlanPoint } from './plan-feature.model';
import VectorLayer from 'ol/layer/Vector';

export type TPlanData = {
  workspaceId: string;
  instance: {
    site: TPlanMap;
    point: TPlanMap;
  };
  site: {
    vector: {
      layer: VectorLayer<any>;
      source: any;
    };
    pins: TPlanFeature[];
    polygons: TPlanFeature[];
    instance: any;
  };
  point: TPlanPoint;
  element: HTMLElement;
  resolution: number;
  vector: {
    layer: VectorLayer<any>;
    source: any;
  };
  enlargedPins: TPlanFeature[];
  zoom: number;
  center: number[];
  extent: number[];
};

export type TSetPlanParams = {
  resolution?: number;
  zoom?: number;
  center?: number[];
  extent?: Extent;
  element?: HTMLElement;
};

@Injectable({
  providedIn: 'root',
})
export class PlanDataService {
  private plan: TPlanData;

  constructor() {
    this.plan = {
      workspaceId: null,
      instance: {
        site: null,
        point: null,
      },
      element: null,
      resolution: 0,
      site: {
        vector: {
          layer: null,
          source: null,
        },
        pins: [],
        instance: null,
        polygons: [],
      },
      point: {
        vector: {
          layer: null,
          source: null,
        },
        instance: null,
        polygons: [],
        priority: null,
        sequenceNumber: null,
        _id: null,
        workspaceRef: { id: null },
      },
      vector: {
        layer: null,
        source: null,
      },
      enlargedPins: [],
      zoom: null,
      center: [],
      extent: [],
    };
  }

  getPlan(): TPlanData {
    return this.plan;
  }

  setPlan({
    element = undefined,
    resolution = undefined,
    zoom = undefined,
    center = undefined,
    extent = undefined,
  }: TSetPlanParams = {}): TPlanData {
    if (element !== undefined) {
      this.plan.element = element;
    }
    if (resolution !== undefined) {
      this.plan.resolution = resolution;
    }

    if (zoom !== undefined) {
      this.plan.zoom = zoom;
    }

    if (center !== undefined) {
      this.plan.center = center;
    }

    if (extent !== undefined) {
      this.plan.extent = extent;
    }

    return this.getPlan();
  }

  clearPointPlan(): void {
    if (this.plan.instance.point) {
      this.plan.instance.point.setTarget(null);
    }

    if (this.plan.point.instance) {
      this.plan.point.instance.setTarget(null);
    }

    this.plan.instance.point = null;
    this.plan.point.pins = [];
    this.plan.point.polygons = [];
    this.plan.point.instance = null;
  }

  clearPlan(): TPlanData {
    this.plan.workspaceId = null;
    if (this.plan.instance.site) {
      this.plan.instance.site.setTarget(null);
    }

    if (this.plan.site.instance) {
      this.plan.site.instance.setTarget(null);
    }

    this.plan.instance.site = null;
    this.plan.site.instance = null;

    this.plan.element = null;
    this.plan.resolution = 0;

    this.plan.vector.layer = null;
    this.plan.vector.source = null;

    this.plan.enlargedPins = [];
    this.plan.zoom = null;
    this.plan.center.length = 0;
    this.plan.extent.length = 0;

    return this.getPlan();
  }
}
