import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TCombinedCustomField } from '../../../custom-fields/combine-custom-fields/combined-custom-field.model';
import { EUserRole } from '../../../share/share-utils/user-roles';
import { SelectedSharesService } from '../../services/selected-shares.service';
import { UserManagementUserSectionService } from '../user-management-user-section/user-management-user-section.service';
import { TAccountUserManagementHeaderBasicField } from './account-user-management-header.model';
import { getUserManagementBasicFields } from './utils/get-account-header-basic-fields';

@Component({
  selector: 'pp-account-user-management-header',
  templateUrl: './account-user-management-header.component.html',
  styleUrls: ['./account-user-management-header.component.scss'],
})
export class AccountUserManagementHeaderComponent implements OnInit, OnDestroy {
  @Input() ppAccountId: string;
  @Input() ppCustomFields: TCombinedCustomField[];

  basicFields: TAccountUserManagementHeaderBasicField[] = [];
  isAllSelected = false;
  allUsersExpanded = false;
  EIconPath = EIconPath;

  selectAllDisabled = false;
  expandAllDisabled = false;

  selectAllTooltip = '';
  expandAllTooltip = '';

  private readonly destroy$ = new Subject<void>();

  constructor(
    private userManagementUserSectionService: UserManagementUserSectionService,
    private selectedSharesService: SelectedSharesService,
    private translationPipe: TranslationPipe,
  ) {
    this.userManagementUserSectionService.expandedUsersChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.checkIfAllExpanded();
      });

    this.selectedSharesService.selectedSharesIdsChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.checkIfAllSelected();
      });

    this.userManagementUserSectionService.filteredUsersChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.checkIfAllExpanded();
        this.checkIfAllSelected();
      });
  }

  ngOnInit(): void {
    this.basicFields = getUserManagementBasicFields();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  selectAll(): void {
    if (this.isAllSelected) {
      this.selectedSharesService.setSelectedSharesIds([]);
    } else {
      const filteredUsers = this.userManagementUserSectionService.getFilteredUsers();
      const shareIds = filteredUsers.flatMap((user) =>
        user.shares
          .filter((share) => share.shareOption !== EUserRole.ACCOUNT_ADMIN)
          .map((share) => share.shareId),
      );

      this.selectedSharesService.setSelectedSharesIds(shareIds);
    }
  }

  expandAll(): void {
    if (this.allUsersExpanded) {
      this.userManagementUserSectionService.clearExpandedUsers();
    } else {
      this.userManagementUserSectionService.setExpandedUsers(
        this.userManagementUserSectionService.getFilteredUsers().map((user) => user.userId),
      );
    }
  }

  private checkIfAllSelected(): void {
    const filteredUsers = this.userManagementUserSectionService.getFilteredUsers();
    const selectedSharesIds = this.selectedSharesService.getSelectedSharesIds();
    let totalSharesNumber = 0;

    const allUsersSelected = filteredUsers.every((user) => {
      const userShares = user.shares
        .filter((user) => user.shareOption !== EUserRole.ACCOUNT_ADMIN)
        .map((share) => share.shareId);

      totalSharesNumber += userShares.length;

      return userShares.every((shareId) => selectedSharesIds.includes(shareId));
    });

    this.isAllSelected = allUsersSelected && totalSharesNumber > 0;

    this.selectAllDisabled = totalSharesNumber === 0;
    this.setSelectAllTooltip();
  }

  private checkIfAllExpanded(): void {
    const expandedUsers = this.userManagementUserSectionService.getExpandedUsers();
    const filteredUsers = this.userManagementUserSectionService.getFilteredUsers();

    this.expandAllDisabled = filteredUsers.length === 0;
    this.allUsersExpanded = filteredUsers.every((user) => expandedUsers.includes(user.userId));
    this.setExpandAllTooltip();
  }

  private setSelectAllTooltip(): void {
    if (this.selectAllDisabled) {
      this.selectAllTooltip = '';
    } else if (this.isAllSelected) {
      this.selectAllTooltip = this.translationPipe.transform('deselect_all');
    } else {
      this.selectAllTooltip = this.translationPipe.transform('select_all');
    }
  }

  private setExpandAllTooltip(): void {
    if (this.expandAllDisabled) {
      this.expandAllTooltip = '';
    } else if (this.allUsersExpanded) {
      this.expandAllTooltip = this.translationPipe.transform('collapse_all');
    } else {
      this.expandAllTooltip = this.translationPipe.transform('expand_all');
    }
  }
}
