import { TColumn } from '../../../columns/column.model';
import { createFooterCellElement } from './create-footer--cell-element';

export function createFooterNameElement({
  width,
  column,
  center = false,
  isGroupedHeader = false,
}: {
  width: number;
  column: TColumn;
  center?: boolean;
  isGroupedHeader?: boolean;
}): HTMLElement {
  return createFooterCellElement({
    center: center,
    width: `${width}px`,
    child: '' as unknown as HTMLElement,
    column: column,
    isGroupedHeader,
  });
}
