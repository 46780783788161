import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Modal, ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { PointsDetailsService } from 'src/app/project/modules/points/points-details.service';
import { RemindersService } from '../reminders.service';

import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventReminders,
} from 'src/app/project/services/analytics/google-analytics.consts';

@Component({
  selector: 'pp-delete-reminder-modal',
  templateUrl: './delete-reminder-modal.component.html',
  styleUrls: ['./delete-reminder-modal.component.scss'],
})
export class DeleteReminderModalComponent implements OnInit {
  processing: boolean;

  private modal: Modal;
  private _id: string;
  private reminderId = null;

  constructor(
    private modalService: ModalService,
    private remindersService: RemindersService,
    private promptService: PromptService,
    private translationPipe: TranslationPipe,
    private pointsDetailsService: PointsDetailsService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.modal = this.modalService.getModal();

    this.processing = false;

    this._id = this.modal.data._id;
    this.reminderId = this.modal.data.reminderId;
  }

  hideModal(cancel: boolean = true): void {
    this.modalService.hideModal(cancel);
  }

  deleteReminder(): void {
    if (!this.processing) {
      logEventInGTAG(EGoogleEventReminders.REMINDERS_DELETE, {
        event_category: EGoogleEventCategory.REMINDERS,
      });

      this.remindersService
        .deleteReminder(this.reminderId)
        .pipe(
          tap(() => {
            const promptText = this.translationPipe.transform('prompt_reminder_deleted');

            this.promptService.showSuccess(promptText);
            this.pointsDetailsService.removePointReminder(this._id, this.reminderId);
            this.remindersService.getRemindersForCurrentDay();

            this.hideModal(false);
          }),
          catchError((error) => {
            let promptText = this.translationPipe.transform('prompt_reminder_deleted_error');

            logErrorInSentry(error);

            if (error.status === EStatusCode.FORBIDDEN) {
              promptText = this.translationPipe.transform('prompt_reminder_permission_denied');

              this.hideModal();
              this.router.navigate(['/site']);
            }

            this.promptService.showError(promptText);

            return of([]);
          }),
        )
        .subscribe();
    }
  }
}
