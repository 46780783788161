<div class="settingsAccountButtons">
  <div class="settingsAccountButtons__searchBox">
    <pp-search-box
      [ppPlaceholder]="'search_users_or_sites' | ppTranslate"
      ppSize="wide"
      (ppAction)="trySetKeyword($event)"
    ></pp-search-box>
  </div>

  <pp-button
    ppStyle="primary"
    ppSize="large"
    ppId="user_management_new_user_button"
    (ppAction)="openAddUsersModal()"
  >
    {{ 'add_users_to_sites' | ppTranslate }}
  </pp-button>

  <pp-account-user-management-create-account-admin-button
    [ppAccountId]="ppAccountId"
    *ngIf="!isAccountAdmin"
  ></pp-account-user-management-create-account-admin-button>

  <pp-account-user-management-export
    [ppAccountId]="ppAccountId"
  ></pp-account-user-management-export>
</div>
