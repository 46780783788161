import { GridsterConfig } from 'angular-gridster2';

export type TGridsterItemCallbacks = {
  itemChangeCallback: () => void;
  itemResizeCallback: () => void;
};

const defaultConfig: GridsterConfig = {
  minCols: 20,
  maxCols: 20,
  minRows: 12,
  minItemRows: 2,
  minItemCols: 2,
  displayGrid: 'onDrag&Resize',
  draggable: {
    enabled: false,
  },
  resizable: {
    enabled: false,
  },
  gridType: 'verticalFixed',
  setGridSize: true,
  mobileBreakpoint: 900,
  fixedRowHeight: 30,
};

export function getAssetGridsterConfigTouch(): GridsterConfig {
  return {
    ...defaultConfig,
  };
}

export function getAssetGridsterConfig(
  { itemChangeCallback, itemResizeCallback }: TGridsterItemCallbacks,
  canEdit: boolean,
): GridsterConfig {
  return {
    ...defaultConfig,
    itemChangeCallback,
    itemResizeCallback,
    draggable: {
      enabled: canEdit,
    },
    resizable: {
      enabled: canEdit,
    },
  };
}
