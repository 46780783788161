export function siteFilterClickOutside(event) {
  const datepickers = document.getElementsByClassName('flatpickr-calendar');
  const dropdown = document.getElementsByClassName('dropdown');
  const path = event.composedPath();

  if (path && path.length) {
    for (let i = 0; i < path.length; i++) {
      if (path[i].className === 'usersDropdown__footer') {
        return false;
      }
    }

    for (let i = 0; i < datepickers.length; i += 1) {
      if (
        datepickers[i].contains(event.target) ||
        datepickers[i].className.indexOf('open') !== -1
      ) {
        return false;
      }
    }

    for (let i = 0; i < dropdown.length; i++) {
      if (dropdown[i].contains(event.target)) {
        return false;
      }
    }

    return true;
  }
}
