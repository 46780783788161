/* eslint-disable max-len */
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { OfflineModule } from '../offline/offline.module';

import { CustomFieldsModule } from '../custom-fields/custom-fields.module';
import { LayoutModule } from '../layout/layout.module';
import { PlanModule } from '../plan/plan.module';
import { PointsModule } from '../points/points.module';
import { RemindersModule } from '../reminders/reminders.module';
import { SupportedBrowsersModule } from '../supported-browsers/supported-browsers.module';
import { TagsModule } from '../tags/tags.module';

import { SiteComponent } from './site-component/site.component';
import { siteRoutes } from './site.routes';
import { SiteService } from './site.service';

import { SiteOptionsComponent } from './site-options/site-options.component';

import { PipesModule } from '../../../core/pipes/pipes.module';
import { AccountSubscriptionModule } from '../../components/account-subscription/account-subscription.module';
import { AuthorizationProgressModule } from '../../components/authorization-progress/authorization-progress.module';
import { BulkChangesBarModule } from '../../components/bulk-changes-bar/bulk-changes-bar.module';
import { ButtonModule } from '../../components/button/button.module';
import { ChangeLimitModalModule } from '../../components/change-limit-modal/change-limit-modal.module';
import { DeleteButtonModule } from '../../components/delete-button/delete-button.module';
import { IconModule } from '../../components/icon/icon-module';
import { IconsModule } from '../../components/icons/icons.module';
import { CheckboxModule } from '../../components/input/checkbox/checkbox.module';
import { RadioModule } from '../../components/input/radio/radio.module';
import { SelectGroupedModule } from '../../components/input/select-grouped/select-grouped.module';
import { SelectModule } from '../../components/input/select/select.module';
import { UploadModule } from '../../components/input/upload/upload.module';
import { ModalModule } from '../../components/modal/modal.module';
import { SearchBoxModule } from '../../components/search-box/search-box.module';
import { SettingsHeaderModule } from '../../components/settings-header/settings-header.module';
import { SpinnerModule } from '../../components/spinner/spinner.module';
import { TagModule } from '../../components/tag/tag.module';
import { ToggleModule } from '../../components/toggle/toggle.module';
import { WarningBoxModule } from '../../components/warning-box/warning-box.module';
import { AutonumericModule } from '../../directives/autonumeric/autonumeric.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SecureSrcModule } from '../../directives/secure-src/secure-src.module';
import { SwitchCasesModule } from '../../directives/switch-cases/switch-cases.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { FiltersModule } from '../filters/filters.module';
import { SettingsContainerComponent } from '../layout/settings-container/settings-container.component';
import { UserSettingsTableContainerComponent } from '../layout/settings-table-container/settings-table-container.component';
import { NotificationFilterSitesComponent } from '../notifications/notification-filter-sites/notification-filter-sites.component';
import { PlaceholdersModule } from '../placeholders/placeholders.module';
import { SavedViewsModule } from '../saved-views/saved-views.module';
import { DeleteSiteModalComponent } from './delete-site-modal/delete-site-modal.component';
import { EditSiteNameModalComponent } from './edit-site-name-modal/edit-site-name-modal.component';
import { SiteColumnResizerComponent } from './site-column-resizer/site-column-resizer.component';
import { SiteNewComponent } from './site-new/site-new.component';
import { SitePlanFormatComponent } from './site-new/site-plan-format/site-plan-format.component';
import { FieldConflictListComponent } from './site-options/points-copy-move-modal/field-conflict-list/field-conflict-list.component';
import { PointsCopyMoveModalComponent } from './site-options/points-copy-move-modal/points-copy-move-modal.component';
import { PointsDeleteModalComponent } from './site-options/points-delete-modal/points-delete-modal.component';
import { ResetTableButtonComponent } from './site-options/reset-table-button/reset-table-button.component';
import { SiteOptionsColumnDropdownComponent } from './site-options/site-options-column-dropdown/site-options-column-dropdown.component';
import { AddGroupOrSortButtonComponent } from './site-options/site-options-group-button/group-button-dropdown/add-group-or-sort-button/add-group-or-sort-button.component';
import { GroupButtonDropdownComponent } from './site-options/site-options-group-button/group-button-dropdown/group-button-dropdown.component';
import { GroupOrSortButtonColumnComponent } from './site-options/site-options-group-button/group-button-dropdown/group-or-sort-button-column/group-or-sort-button-column.component';
import { GroupOrSortButtonOrderComponent } from './site-options/site-options-group-button/group-button-dropdown/group-or-sort-button-order/group-or-sort-button-order.component';
import { NewGroupOrSortColumnComponent } from './site-options/site-options-group-button/group-button-dropdown/new-group-or-sort-column/new-group-or-sort-column.component';
import { SiteOptionsGroupButtonComponent } from './site-options/site-options-group-button/site-options-group-button.component';
import { SiteOptionsMoreFloatingActionsEditDropdownComponent } from './site-options/site-options-more-floating-actions/site-options-more-floating-actions-edit-dropdown/site-options-more-floating-actions-edit-dropdown.component';
import { SiteOptionsMoreFloatingActionsComponent } from './site-options/site-options-more-floating-actions/site-options-more-floating-actions.component';
import { SiteOptionsSortButtonComponent } from './site-options/site-options-sort-button/site-options-sort-button.component';
import { SortButtonDropdownComponent } from './site-options/site-options-sort-button/sort-button-dropdown/sort-button-dropdown.component';
import { SiteOverviewComponent } from './site-overview/site-overview.component';
import { SiteSettingsDropdownComponent } from './site-settings-dropdown/site-settings-dropdown.component';
import { SiteSettingsCustomFieldsTableComponent } from './site-settings/site-settings-custom-fields/site-settings-custom-fields-table/site-settings-custom-fields-table.component';
import { SiteSettingsCustomFieldsComponent } from './site-settings/site-settings-custom-fields/site-settings-custom-fields.component';
import { SiteSettingsIntegrationsAuditLogRowComponent } from './site-settings/site-settings-integrations/site-settings-integrations-audit-log/site-settings-integrations-audit-log-row/site-settings-integrations-audit-log-row.component';
import { SiteSettingsIntegrationsAuditLogComponent } from './site-settings/site-settings-integrations/site-settings-integrations-audit-log/site-settings-integrations-audit-log.component';
import { ConfirmDisconnectIntegrationModalComponent } from './site-settings/site-settings-integrations/site-settings-integrations-authorize-connection/confirm-disconnect-integration-modal/confirm-disconnect-integration-modal.component';
import { SiteSettingsIntegrationsAuthorizeConnectionComponent } from './site-settings/site-settings-integrations/site-settings-integrations-authorize-connection/site-settings-integrations-authorize-connection.component';
import { SiteSettingsIntegrationsCustomFieldsComponent } from './site-settings/site-settings-integrations/site-settings-integrations-custom-fields/site-settings-integrations-custom-fields.component';
import { SiteSettingsIntegrationsGetStartedComponent } from './site-settings/site-settings-integrations/site-settings-integrations-get-started/site-settings-integrations-get-started.component';
import { SiteSettingsIntegrationsTooltipsTooltipComponent } from './site-settings/site-settings-integrations/site-settings-integrations-tooltips/site-settings-integrations-tooltips-tooltip/site-settings-integrations-tooltips-tooltip.component';
import { SiteSettingsIntegrationsTooltipsComponent } from './site-settings/site-settings-integrations/site-settings-integrations-tooltips/site-settings-integrations-tooltips.component';
import { SiteSettingsIntegrationsComponent } from './site-settings/site-settings-integrations/site-settings-integrations.component';
import { SiteSettingsLogoComponent } from './site-settings/site-settings-logo/site-settings-logo.component';
import { SiteSettingsPlanComponent } from './site-settings/site-settings-plan/site-settings-plan.component';
import { SiteSettingsTagsComponent } from './site-settings/site-settings-tags/site-settings-tags.component';
import { DeleteShareButtonComponent } from './site-settings/site-settings-users/site-settings-users-table/delete-share-button/delete-share-button.component';
import { SiteSettingsUsersFloatingActionsComponent } from './site-settings/site-settings-users/site-settings-users-table/site-settings-users-floating-actions/site-settings-users-floating-actions.component';
import { SiteSettingsUsersFooterComponent } from './site-settings/site-settings-users/site-settings-users-table/site-settings-users-footer/site-settings-users-footer.component';
import { SiteSettingsUsersSelectCellComponent } from './site-settings/site-settings-users/site-settings-users-table/site-settings-users-select-cell/site-settings-users-select-cell.component';
import { SiteSettingsUsersTableComponent } from './site-settings/site-settings-users/site-settings-users-table/site-settings-users-table.component';
import { SiteSettingsUsersComponent } from './site-settings/site-settings-users/site-settings-users.component';
import { SiteSettingsComponent } from './site-settings/site-settings.component';
import { SiteTableOverviewComponent } from './site-table-overview/site-table-overview.component';
import { SiteTableTimelineComponent } from './site-table-timeline/site-table-timeline.component';
import { SiteTableColumnSortService } from './site-table/site-table-column-sort.service';
import { SiteTableColumnWidthService } from './site-table/site-table-column-width.service';
import { SiteTableColumnsDataService } from './site-table/site-table-columns-data.service';
import { SiteTableColumnsService } from './site-table/site-table-columns.service';
import { SiteTableEventsService } from './site-table/site-table-events.service';
import { SiteTablePointsService } from './site-table/site-table-points.service';
import { SiteTableComponent } from './site-table/site-table.component';
import { TableGroupDropdownComponent } from './site-table/table-group-dropdown/table-group-dropdown.component';
import { SiteTimelineComponent } from './site-timeline/site-timeline.component';
import { ResetTimelineButtonComponent } from './timeline-options/reset-timeline-button/reset-timeline-button.component';
import { TimeframeButtonComponent } from './timeline-options/timeframe-button/timeframe-button.component';
import { TimelineExportButtonComponent } from './timeline-options/timeline-export-button/timeline-export-button.component';
import { TimelineGroupingDropdownComponent } from './timeline-options/timeline-grouping-dropdown/timeline-grouping-dropdown.component';
import { TimelineOptionsComponent } from './timeline-options/timeline-options.component';
import { SiteSettingsIntegrationsCustomFieldRowComponent } from './site-settings/site-settings-integrations/site-settings-integrations-custom-fields/site-settings-integrations-custom-field-row/site-settings-integrations-custom-field-row.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(siteRoutes),
    ButtonModule,
    ScrollingModule,
    LayoutModule,
    PlanModule,
    PointsModule,
    RemindersModule,
    ToggleModule,
    CheckboxModule,
    DeleteButtonModule,
    CustomFieldsModule,
    TagsModule,
    UploadModule,
    RadioModule,
    SelectModule,
    PipesModule,
    SupportedBrowsersModule,
    OfflineModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    FiltersModule,
    SearchBoxModule,
    ModalModule,
    SpinnerModule,
    SettingsHeaderModule,
    AccountSubscriptionModule,
    ChangeLimitModalModule,
    IconModule,
    SecureSrcModule,
    IconsModule,
    TagModule,
    PlaceholdersModule,
    SavedViewsModule,
    BulkChangesBarModule,
    SelectGroupedModule,
    AuthorizationProgressModule,
    WarningBoxModule,
  ],
  exports: [SiteComponent, SettingsContainerComponent, UserSettingsTableContainerComponent],
  providers: [
    CurrencyPipe,

    SiteService,

    SiteTableColumnsDataService,
    SiteTableColumnsService,
    SiteTableColumnSortService,
    SiteTableColumnWidthService,
    SiteTableEventsService,
    SiteTablePointsService,
  ],
  declarations: [
    SiteComponent,
    SettingsContainerComponent,
    UserSettingsTableContainerComponent,

    SiteOptionsComponent,
    SiteTableComponent,
    SiteSettingsDropdownComponent,
    SiteOptionsColumnDropdownComponent,
    SiteSettingsComponent,
    SiteSettingsCustomFieldsComponent,
    SiteSettingsPlanComponent,
    SiteSettingsLogoComponent,
    SiteSettingsUsersComponent,
    SiteSettingsUsersFloatingActionsComponent,
    SiteSettingsTagsComponent,
    SiteSettingsUsersSelectCellComponent,
    SiteNewComponent,
    NotificationFilterSitesComponent,

    EditSiteNameModalComponent,
    PointsCopyMoveModalComponent,
    PointsDeleteModalComponent,
    SiteOptionsMoreFloatingActionsComponent,
    SiteOverviewComponent,
    SiteTableOverviewComponent,
    SiteSettingsCustomFieldsTableComponent,
    SiteSettingsUsersTableComponent,
    DeleteShareButtonComponent,
    SiteOptionsMoreFloatingActionsEditDropdownComponent,

    SiteTimelineComponent,
    SiteTableTimelineComponent,
    TimelineOptionsComponent,
    TimelineGroupingDropdownComponent,
    TimeframeButtonComponent,
    TimelineExportButtonComponent,
    SiteColumnResizerComponent,
    SitePlanFormatComponent,
    FieldConflictListComponent,
    DeleteSiteModalComponent,
    SiteOptionsGroupButtonComponent,
    SiteOptionsSortButtonComponent,
    GroupButtonDropdownComponent,
    GroupOrSortButtonOrderComponent,
    GroupOrSortButtonColumnComponent,
    AddGroupOrSortButtonComponent,
    TableGroupDropdownComponent,
    NewGroupOrSortColumnComponent,
    SortButtonDropdownComponent,
    ResetTableButtonComponent,
    ResetTimelineButtonComponent,
    SiteSettingsIntegrationsComponent,
    SiteSettingsIntegrationsGetStartedComponent,
    SiteSettingsIntegrationsAuthorizeConnectionComponent,
    SiteSettingsIntegrationsTooltipsComponent,
    SiteSettingsIntegrationsTooltipsTooltipComponent,
    SiteSettingsIntegrationsCustomFieldsComponent,
    SiteSettingsIntegrationsCustomFieldRowComponent,
    ConfirmDisconnectIntegrationModalComponent,
    SiteSettingsIntegrationsAuditLogComponent,
    SiteSettingsIntegrationsAuditLogRowComponent,
    SiteSettingsUsersFooterComponent,
  ],
})
export class SiteModule {}
