import { TColumn } from '../../../../columns/column.model';
import { GET_TABLE } from '../../../../table.ui.store';

export function getHeaderClassList(groupLevel: number, grouping: TColumn[]): string {
  const table = GET_TABLE();
  let headerClassList = 'table__columnCells table__columnCells__header';

  if (groupLevel) {
    if (grouping.length === 2 && groupLevel === 2) {
      headerClassList += ` table__row--header-3`;
      return headerClassList;
    }

    if (grouping.length !== 1) {
      headerClassList += ` table__row--header-${groupLevel}`;
    } else {
      headerClassList += ' table__row--header-3';
    }
  }

  return headerClassList;
}
