import { Component } from '@angular/core';

@Component({
  selector: 'pp-point-heading',
  templateUrl: './point-heading.component.html',
  styleUrls: ['./point-heading.component.scss'],
})
export class PointHeadingComponent {
  constructor() {}
}
