<h4
  class="topBar__account-name topBar__fleetPageName"
  ppTooltip
  [ppTrimTooltip]="true"
  [ppTitle]="pageName"
>
  {{ pageName }}
</h4>

<span
  ppTooltip
  [id]="buttonId"
  data-m-target="Fleet settings button"
  *ngIf="activeFleetId && displayCogDropdown"
  data-test="fleetSettingsButton"
  class="topBar__settings"
  [ppTitle]="'fleet_settings' | ppTranslate"
  (click)="toggleDropdown()"
>
  <span class="topBar__cog-container">
    <pp-icon
      [ppSrc]="EIconPath.ICON_COG_GREY"
      [ppClass]="'top__setting-icon'"
      class="topBar__page--svg"
      [ppDisplayContents]="true"
    ></pp-icon>
  </span>
</span>
