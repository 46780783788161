import { cloneDeep } from 'lodash';
import { GET_WORKSPACE } from '../../workspace/workspace.store';
import { TCustomFieldFilter, TFilters } from '../site-filter.model';
import { getDefaultFilter } from './default-filter';

export function createSavedViewCustomFieldFilters({
  filters,
  workspaceId,
}: {
  filters: TFilters;
  workspaceId: string;
}): TFilters {
  const workspace = GET_WORKSPACE(workspaceId);
  let fixedFilters: TFilters = null;

  if (!filters || Object.values(filters).length === 0) {
    fixedFilters = getDefaultFilter();
  } else {
    fixedFilters = cloneDeep(filters); // TODO Break reference test when adding tests to this file
  }

  workspace.customFields.forEach((fieldId) => {
    const filterExists = fixedFilters.customFields.find((filter) => filter.id === fieldId);

    if (!filterExists) {
      const filterToAdd: TCustomFieldFilter = {
        id: fieldId,
        workspaceId: workspace.workspaceId,
        startDate: null,
        endDate: null,
        min: null,
        max: null,
        value: null,
        path: null,
        text: null,
        idOfChosenElement: null,
        appliedValues: {
          startDate: null,
          endDate: null,
          min: null,
          max: null,
          value: null,
          path: null,
          text: null,
          idOfChosenElement: null,
        },
        sites: [
          {
            filterId: fieldId,
            workspaceId: workspace.workspaceId,
            accountName: workspace.accountName,
            siteName: workspace.siteName,
          },
        ],
      };

      fixedFilters.customFields.push(filterToAdd);
    }
  });

  return fixedFilters;
}
