import { cloneDeep } from 'lodash';

import { Component, Inject, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, takeUntil, tap } from 'rxjs/operators';
import { UpdateWorkspaceCustomFields } from 'src/app/project/modules/workspace/workspace.actions';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import {
  EIntegrationStatus,
  TAllCustomFields,
  TCustomField,
} from '../../../../custom-fields/custom-fields.model';

import { DeleteCustomFieldModalComponent } from 'src/app/project/modules/custom-fields/delete-custom-field-modal/delete-custom-field-modal.component';
import { EditCustomFieldModalComponent } from 'src/app/project/modules/custom-fields/edit-custom-field-modal/edit-custom-field-modal.component';

import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { OfflineService } from 'src/app/project/modules/offline/offline.service';
import { UserService } from 'src/app/project/modules/user/user.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { CustomFieldsService } from '../../../../custom-fields/custom-fields.service';

import { DOCUMENT } from '@angular/common';
import { getDragIcon } from 'src/app/core/helpers/dragging/get-drag-icon';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { TDeleteCustomFieldModalData } from 'src/app/project/modules/custom-fields/delete-custom-field-modal/delete-cstom-field-modal-data';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { TEditCustomFieldModalData } from '../../../../custom-fields/edit-custom-field-modal/edit-custom-field-modal.model';

@Component({
  selector: 'pp-site-settings-custom-fields-table',
  templateUrl: './site-settings-custom-fields-table.component.html',
  styleUrls: ['./site-settings-custom-fields-table.component.scss'],
})
export class SiteSettingsCustomFieldsTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() ppWorkspaceId = '';

  private readonly destroy$ = new Subject<void>();
  private readonly reorderCustomFields$ = new Subject<void>();
  private reorderCustomFieldsDebounceTimeMs = 1000;

  private dropdown: TDropdown = this.dropdownService.getDropdown();

  private workspaces$: Observable<TWorkspacesById>;

  workspace: TWorkspace = null;
  hiddenCustomFields: string[] = [];
  customFields: TAllCustomFields = this.customFieldsService.getCustomFields();
  offline: boolean = this.offlineService.getOffline();

  private sortedCustomFieldIds: Array<string> = [];
  draggedCustomFieldId = '';
  private draggedCustomFieldIndex: number = null;
  onDragOverIndex: number = null;
  linePosition = 50;
  private sortedFieldsBackup: string[];
  dragging = false;
  EIconPath = EIconPath;

  customFieldImgSrc = {
    TEXT: EIconPath.CUSTOM_FIELD_TEXT_ACTIVITY,
    DATE: EIconPath.CUSTOM_FIELD_DATE_ACTIVITY,
    COST: EIconPath.CUSTOM_FIELD_COST_ACTIVITY,
    LIST: EIconPath.CUSTOM_FIELD_LIST_ACTIVITY,
    NUMBERS: EIconPath.CUSTOM_FIELD_NUMBERS_ACTIVITY,
    RICHTEXT: EIconPath.CUSTOM_FIELD_RICH_TEXT_ACTIVITY,
    TIME: EIconPath.CUSTOM_FIELD_TIME_ACTIVITY,
    PERCENTAGE: EIconPath.CUSTOM_FIELD_PERCENTAGE_ACTIVITY,
    FORMULA: EIconPath.ICON_FIELDS_FORMULA_24,
    CHECKBOX: EIconPath.CUSTOM_FIELD_CHECKBOX_ACTIVITY,
    TIMELINE: EIconPath.CUSTOM_FIELD_TIMELINE_ACTIVITY,
  };

  customFieldLabels = {
    TEXT: 'Text',
    DATE: 'Date',
    COST: 'Cost',
    LIST: 'List',
    NUMBERS: 'Numbers',
    RICHTEXT: 'Rich Text',
    TIME: 'Time',
    PERCENTAGE: 'Percentage',
    FORMULA: 'Formula',
    CHECKBOX: 'Checkbox',
    TIMELINE: 'Timeline',
  };

  volyCustomFieldLabel = {
    ON: 'Voly Dynamic',
    DISABLED: 'Voly Dynamic',
    OFF: '',
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<{ workspaces: TWorkspacesById }>,
    private workspaceService: WorkspaceService,
    private customFieldsService: CustomFieldsService,
    private offlineService: OfflineService,
    private userService: UserService,
    private dropdownService: DropdownService,
    private promptService: PromptService,
    private modalService: ModalService,
    private translationPipe: TranslationPipe,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
  }

  ngOnInit() {
    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspace = cloneDeep(workspaces[this.ppWorkspaceId]);
      this.customFields = this.customFieldsService.getCustomFields();
      this.filterVolyCustomFields();
    });

    this.workspaceService
      .generateWorkspace(this.ppWorkspaceId)
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    this.reorderCustomFields$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(this.reorderCustomFieldsDebounceTimeMs),
        tap(() => {
          this.reorderCustomFields();
        }),
      )
      .subscribe();
  }

  ngOnChanges() {
    this.workspace = this.workspaceService.findWorkspace(this.ppWorkspaceId);
    this.customFields = this.customFieldsService.getCustomFields();
    this.filterVolyCustomFields();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  onDragStart(event: DragEvent, id: string, index: number): void {
    const img = new Image();

    this.draggedCustomFieldId = id;
    this.draggedCustomFieldIndex = index;
    this.sortedFieldsBackup = [...this.workspace.customFields];
    this.sortedCustomFieldIds = [...this.workspace.customFields];
    img.src = getDragIcon();

    event.dataTransfer.setDragImage(img, 0, 0);

    this.dragging = true;
  }

  onDragOver(event: DragEvent, index: number, fieldId: string): void {
    const itemRowElement = this.document.getElementById('itemRow-' + fieldId);
    const targetElementCoords = itemRowElement.getBoundingClientRect();
    const elementCenterY =
      targetElementCoords.top + (targetElementCoords.bottom - targetElementCoords.top) / 2;
    const currentCustomFieldIds = [...this.workspace.customFields];

    let columnToReplaceIndex;

    if (event.clientY < elementCenterY) {
      this.onDragOverIndex = index - 1;
      columnToReplaceIndex = index;

      if (index > this.draggedCustomFieldIndex) {
        columnToReplaceIndex = index - 1;
      }
    } else {
      this.onDragOverIndex = index;
      columnToReplaceIndex = index;

      if (index < this.draggedCustomFieldIndex) {
        columnToReplaceIndex = index + 1;
      }
    }

    this.linePosition = 56 * (this.onDragOverIndex + 1);

    event.preventDefault();

    currentCustomFieldIds.splice(this.draggedCustomFieldIndex, 1);
    currentCustomFieldIds.splice(columnToReplaceIndex, 0, this.draggedCustomFieldId);

    this.sortedCustomFieldIds = currentCustomFieldIds;
  }

  onDrop(event: Event): void {
    this.dragging = false;

    event.preventDefault();
    event.stopPropagation();
  }

  onDragEnd(): void {
    this.dragging = false;

    this.workspace.customFields = this.sortedCustomFieldIds;
    this.draggedCustomFieldId = null;
    this.draggedCustomFieldIndex = null;
    this.onDragOverIndex = null;

    if (JSON.stringify(this.sortedFieldsBackup) !== JSON.stringify(this.sortedCustomFieldIds)) {
      this.reorderCustomFields$.next();
    }
  }

  editCustomField(fieldId: string): void {
    const customField = this.customFields[this.workspace.workspaceId][fieldId];

    if (!customField.lockedTemplate) {
      this.modalService.setData<TEditCustomFieldModalData>({
        customField,
        workspaceId: this.workspace.workspaceId,
      });

      this.modalService.showModal(EditCustomFieldModalComponent, {
        closeWarning: true,
        callback: this.dropdown.callback,
      });

      this.dropdownService.hideDropdown();
    }
  }

  deleteCustomField(fieldId: string): void {
    const customField = this.customFields[this.workspace.workspaceId][fieldId];

    if (!customField.lockedTemplate) {
      this.modalService.setData<TDeleteCustomFieldModalData>({
        field: customField,
      });

      this.modalService.showModal(DeleteCustomFieldModalComponent);

      this.dropdownService.hideDropdown();
    }
  }

  getCustomFieldIconSrc(customField: TCustomField): string {
    if (!customField) return this.customFieldImgSrc.TEXT;

    const volyIntegrations = customField.volyIntegrationActive;

    switch (volyIntegrations) {
      case EIntegrationStatus.OFF:
        return this.customFieldImgSrc[customField.type];
      case EIntegrationStatus.ON:
        return EIconPath.ICON_INTEGRATION_VOLY_ACTIVE_BIG;
      case EIntegrationStatus.DISABLED:
        return EIconPath.ICON_INTEGRATION_VOLY_INACTIVE_BIG;
    }
  }

  getRemoveTooltipTitle(customField: TCustomField): string {
    switch (customField.volyIntegrationActive) {
      case EIntegrationStatus.ON:
      case EIntegrationStatus.DISABLED:
        return this.translationPipe.transform('integration_read_only_tooltip');

      default:
        if (customField.lockedTemplate) {
          return this.translationPipe.transform('custom_field_read_only');
        }

        return '';
    }
  }

  private filterVolyCustomFields(): void {
    this.hiddenCustomFields = [];
    const filteredFields = this.workspace.customFields.reduce(
      (acc, customFieldId) => {
        const customField = this.customFields[this.ppWorkspaceId][customFieldId];

        if (!customField.display) {
          acc.hidden.push(customFieldId);
        } else {
          acc.visible.push(customFieldId);
        }

        return acc;
      },
      { visible: [], hidden: [] },
    );

    this.workspace.customFields = filteredFields.visible;
    this.hiddenCustomFields = filteredFields.hidden;
  }

  private reorderCustomFields(): void {
    const reorderCustomFieldsWithHiddenFields = [
      ...this.sortedCustomFieldIds,
      ...this.hiddenCustomFields,
    ];

    this.customFieldsService
      .reorderCustomFields(this.ppWorkspaceId, reorderCustomFieldsWithHiddenFields)
      .pipe(
        tap((response) => {
          const newOrder = [];

          response.customFields.forEach((field) => {
            newOrder.push(field.id);
          });

          this.store.dispatch(
            new UpdateWorkspaceCustomFields({
              workspaceId: this.ppWorkspaceId,
              customFields: newOrder,
            }),
          );

          const promptText = this.translationPipe.transform('prompt_custom_fields_reorder');

          this.promptService.showSuccess(promptText);
        }),
        catchError((error) => {
          const promptText = this.translationPipe.transform('prompt_custom_fields_reorder_error');

          this.promptService.showError(promptText);

          return throwError(error);
        }),
      )
      .subscribe();
  }
}
