<td class="siteSettingsUser__selectCell checkboxCell">
  <div
    class="checkboxWrapper"
    ppTooltip
    [ppTitle]="ppDisabled ? ('cant_edit_account_admin_permission' | ppTranslate) : null"
  >
    <pp-checkbox
      [ppChecked]="isSelected"
      [ppDisabled]="ppDisabled"
      (ppOnValueChange)="selectCell()"
      ppSize="medium"
      class="checkbox"
      ppType="solid"
    ></pp-checkbox>
  </div>
</td>
