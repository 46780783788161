import { translate } from 'src/app/project/features/translate/translate';
import { TDashletGraph } from '../dashlet-graph.model';
import { EDashletType } from '../dashlets-enums';

export function addGraphDatasetLabels(
  graph: TDashletGraph,
  type: EDashletType,
  data: number[][],
): void {
  const lastDataIndex = data[0].length - 1;

  if (type === EDashletType.OVER_TIME_STATUS) {
    graph.data.datasets[0].label = `${translate('open')} (${data[0][lastDataIndex]})`;
    graph.data.datasets[1].label = `${translate('in_progress')} (${data[1][lastDataIndex]})`;
    graph.data.datasets[2].label = `${translate('to_review')} (${data[2][lastDataIndex]})`;
    graph.data.datasets[3].label = `${translate('on_hold')} (${data[3][lastDataIndex]})`;
    graph.data.datasets[4].label = `${translate('completed')} (${data[4][lastDataIndex]})`;
    graph.data.datasets[5].label = `${translate('canceled')} (${data[5][lastDataIndex]})`;
  } else {
    graph.data.datasets[0].label = `${translate('high')} (${data[0][lastDataIndex]})`;
    graph.data.datasets[1].label = `${translate('medium')} (${data[1][lastDataIndex]})`;
    graph.data.datasets[2].label = `${translate('low')} (${data[2][lastDataIndex]})`;
  }
}
