import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, of } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { DEFAULT_DATE_FORMAT } from 'src/app/project/modules/preferences/default-date-format';
import { TPreferences } from 'src/app/project/modules/preferences/preferences.model';
import { EDateFormat } from 'src/app/project/shared/enums/date-format.enum';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { DATE_FORMATS } from '../user-date-formats';
import { UserSettingsService } from '../user-settings.service';

@Component({
  selector: 'pp-user-settings-preferences',
  templateUrl: './user-settings-preferences.component.html',
  styleUrls: ['../user-settings.component.scss'],
})
export class UserSettingsPreferencesComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  savingPreferences = false;
  dateFormats = DATE_FORMATS;
  zoomFactor = 2.0;
  preferences$: Observable<TPreferences>;
  selectedDateFormat = DEFAULT_DATE_FORMAT;
  updatingZoomFactor = false;

  constructor(
    private store: Store<{
      preferences: TPreferences;
    }>,
    private userSettingsService: UserSettingsService,
  ) {
    this.preferences$ = this.store.pipe(select(EStore.PREFERENCES));
  }

  ngOnInit(): void {
    this.preferences$
      .pipe(takeUntil(this.destroy$))
      .subscribe((_preferences) => this.preferencesHandler(_preferences));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  updateZoomFactor(): void {
    if (!this.savingPreferences) {
      this.savingPreferences = true;

      this.userSettingsService
        .updateZoomFactor(this.zoomFactor)
        .pipe(
          catchError(() => of([])),
          finalize(() => {
            this.savingPreferences = false;
          }),
        )
        .subscribe();
    }
  }

  setDateFormat(format: EDateFormat): void {
    this.selectedDateFormat = format;

    this.saveDatePreferences();
  }

  saveDatePreferences(): void {
    if (!this.savingPreferences) {
      this.savingPreferences = true;

      this.userSettingsService
        .saveDatePreferences(this.selectedDateFormat)
        .pipe(
          catchError(() => of([])),
          finalize(() => {
            this.savingPreferences = false;
          }),
        )
        .subscribe();
    }
  }

  preferencesHandler(preferences: TPreferences): void {
    this.selectedDateFormat = preferences?.dateFormat
      ? preferences.dateFormat
      : DEFAULT_DATE_FORMAT;

    if (typeof preferences.mapZoomFactor !== 'undefined') {
      this.zoomFactor = preferences.mapZoomFactor;
    }
  }
}
