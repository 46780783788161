<div class="newAssetSitePickerAccount" (click)="toggleExpand()">
  <pp-icon
    [ppSrc]="expanded ? EIconPath.ICON_MISC_DOWN_CHEVRON_24 : EIconPath.ICON_MISC_RIGHT_CHEVRON_24"
    ppClass="setSize24"
    ppColor="inherit"
  ></pp-icon>

  {{ ppAccount.accountName }}
</div>

<ng-container *ngIf="expanded">
  <pp-new-asset-site-picker-workspace
    *ngFor="let workspaceId of ppAccount.workspaces"
    [ppWorkspaceId]="workspaceId"
  ></pp-new-asset-site-picker-workspace>
</ng-container>
