<div class="filterContent__date-range" *ngIf="ppField">
  <div class="filterContent__date-wrapper">
    <pp-datepicker
      class="siteFilter__date-picker"
      [ppDate]="ppField.startDate"
      [ppFilterDatepicker]="true"
      [ppMaxDate]="ppField.endDate"
      (ppSelect)="
        updateDate($event, ppCustomFields[ppField.workspaceId][ppField.id].label, 'startDate')
      "
      [ppPlaceholder]="'From' | ppTranslate"
    >
    </pp-datepicker>
  </div>

  <div class="filterContent__date-wrapper">
    <pp-datepicker
      class="siteFilter__date-picker"
      [ppDate]="ppField.endDate"
      [ppFilterDatepicker]="true"
      [ppMinDate]="ppField.startDate"
      (ppSelect)="
        updateDate($event, ppCustomFields[ppField.workspaceId][ppField.id].label, 'endDate')
      "
      [ppPlaceholder]="'to' | ppTranslate | lowercase"
    ></pp-datepicker>
  </div>
</div>
