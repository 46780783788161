import { TFilters } from '../site-filter.model';

export function compareFilterStatus(firstFilter: TFilters, secondFilter: TFilters): boolean {
  const status1 = firstFilter.status;
  const status2 = secondFilter.status;

  for (let index = 0; index < status1.length; index++) {
    if (status1[index].value !== status2[index].value) {
      return false;
    }
  }

  return true;
}
