<li
  ppTooltip
  ppTitle="Dashboard"
  class="mainNav__element mainNav__element--with-image"
  [class.mainNav__element--spacing]="!ppIsLandscape"
  [class.mainNav__element--no-spacing]="ppIsLandscape"
  [class.mainNav__element--active]="ppRouterPath === '/dashboard'"
  [class.mainNav__element--disabled]="!ppActiveWorkspaceId || ppDisabled"
  [ppOptions]="{ placement: 'right' }"
  (click)="goToDashboard()"
>
  <img
    data-m-target="Dashboard button"
    class="filterPanel__tooltip mainNav__element--href mainNav__element--img mainNav__element--dashboard-img"
    [src]="EIconPath.SIDE_PANEL_DASHBOARD"
  />

  <h6
    class="filterPanel__title filterPanel__title--img"
    *ngIf="ppIsMobile && !sidePanel.rightPanelExpanded"
  >
    {{ 'dashboard' | ppTranslate }}
  </h6>
</li>
