import { cloneDeep } from 'lodash';
import { TShare } from '../../share.model';

export function correctSitePlanVisibility(share: TShare): boolean {
  let sitePlanPermissions = cloneDeep(share.advancedAccessLevels.sitePlan.permission.read);

  sitePlanPermissions = !sitePlanPermissions;
  const access = sitePlanPermissions.toString() ? 'on' : 'off';

  return sitePlanPermissions;
}
