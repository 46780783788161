import { Pipe, PipeTransform } from '@angular/core';
import { EIconPath } from '../../project/shared/enums/icons.enum';
import { EStatus } from '../../project/shared/enums/status.enum';
import { statusToImage } from '../../project/shared/status-to-image';

@Pipe({
  name: 'ppStatusImage',
})
export class StatusImagePipe implements PipeTransform {
  transform(status: string, white: boolean): EIconPath {
    return statusToImage(status as EStatus, white);
  }
}
