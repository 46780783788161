import { Injectable } from '@angular/core';
import {
  isBrowserChrome,
  isBrowserEdge,
  isBrowserFirefox,
  isBrowserOpera,
  isBrowserSafari,
  isIpad,
  isIphone,
  isIpod,
  isMobile,
  isTablet,
  isTouchDevice,
} from '../helpers/device';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor() {}

  isMobile(): boolean {
    return isMobile();
  }

  isTablet(): boolean {
    return isTablet();
  }

  isiPhone(): boolean {
    return isIphone();
  }

  isiPod(): boolean {
    return isIpod();
  }

  isiPad(): boolean {
    return isIpad();
  }

  isBrowserSafari(): boolean {
    return isBrowserSafari();
  }

  isBrowserFirefox(): boolean {
    return isBrowserFirefox();
  }

  isBrowserChrome(): boolean {
    return isBrowserChrome();
  }

  isBrowserOpera(): boolean {
    return isBrowserOpera();
  }

  isBrowserEdge(): boolean {
    return isBrowserEdge();
  }

  isTouchDevice(): boolean {
    return isTouchDevice();
  }
}
