import { ERowType } from 'src/app/project/shared/enums/row-type.enum';
import { TGroupedPoints } from '../../../grouping/group-model';
import { GET_COLLAPSED_GROUPS } from '../../../table.ui.store';
import { TTableItem } from '../../table.model';
import { TPoint } from 'src/app/project/modules/points/points.model';

export function generateSelectedGroupedPointItems(
  groupedTable: TGroupedPoints[],
  selectedPoints: Set<string>,
): TTableItem[] {
  const collapsedGroups = GET_COLLAPSED_GROUPS();

  let items: TTableItem[] = [];

  const filteredGroups = groupedTable.filter((groupedPoints) => {
    return groupContainsSelectedPoints(groupedPoints.pointObjects, selectedPoints);
  });

  items = filteredGroups.flatMap((groupedPoints, groupIndex) => {
    const points: TTableItem[] = [];
    const collapsed = collapsedGroups.has(groupedPoints.id);

    if (groupIndex === 0) {
      points.push({
        type: ERowType.EMPTY,
      });
    }

    points.push({
      type: ERowType.HEADER,
      context: groupedPoints.value,
      id: groupedPoints.id,
    });

    if (!collapsed) {
      generateFirstGroupPoints(groupedPoints, points, selectedPoints);
    }

    points.push({
      type: ERowType.EMPTY,
    });

    return points;
  });

  return items;
}

function generateFirstGroupPoints(
  group: TGroupedPoints,
  points: TTableItem[],
  selectedPoints: Set<string>,
): void {
  if (!groupContainsSelectedPoints(group.pointObjects, selectedPoints)) {
    return;
  }

  group.pointObjects = group.pointObjects.filter((point: TPoint) =>
    pointIsSelected(point._id, selectedPoints),
  );
  if (group.group) {
    generateSecondGroupPoints(group, points, selectedPoints);
  } else {
    points.push(
      ...group.pointObjects.map((point) => ({
        type: ERowType.POINT,
        pointId: point._id,
      })),
    );
  }
}

function generateSecondGroupPoints(
  group: TGroupedPoints,
  points: TTableItem[],
  selectedPoints: Set<string>,
): void {
  const collapsedGroups = GET_COLLAPSED_GROUPS();

  group.group.forEach((subGroup) => {
    if (!groupContainsSelectedPoints(subGroup.pointObjects, selectedPoints)) {
      return;
    }

    const collapsedSubGroup = collapsedGroups.has(subGroup.id);

    points.push({
      type: ERowType.GROUP_2,
      context: subGroup.value,
      id: subGroup.id,
    });

    if (!collapsedSubGroup) {
      subGroup.pointObjects = subGroup.pointObjects.filter((point: TPoint) =>
        pointIsSelected(point._id, selectedPoints),
      );

      if (subGroup.group) {
        generateThirdGroupPoints(subGroup, points, selectedPoints);
      } else {
        points.push(
          ...subGroup.pointObjects.map((point) => ({
            type: ERowType.POINT,
            pointId: point._id,
          })),
        );
      }
    }

    points.push({
      type: ERowType.EMPTY_ROW_GROUP_1,
    });
  });
}

function generateThirdGroupPoints(
  subGroup: TGroupedPoints,
  points: TTableItem[],
  selectedPoints: Set<string>,
): void {
  const collapsedGroups = GET_COLLAPSED_GROUPS();

  subGroup.group.forEach((subSubGroup) => {
    if (!groupContainsSelectedPoints(subSubGroup.pointObjects, selectedPoints)) {
      return;
    }

    const collapsedSubsubGroup = collapsedGroups.has(subSubGroup.id);

    points.push({
      type: ERowType.GROUP_3,
      context: subSubGroup.value,
      id: subSubGroup.id,
    });

    if (!collapsedSubsubGroup) {
      subSubGroup.pointObjects = subSubGroup.pointObjects.filter((point: TPoint) =>
        pointIsSelected(point._id, selectedPoints),
      );

      points.push(
        ...subSubGroup.pointObjects.map((point) => ({
          type: ERowType.POINT,
          pointId: point._id,
        })),
      );
    }

    points.push({
      type: ERowType.EMPTY_ROW_GROUP_2,
    });
  });
}

function groupContainsSelectedPoints(points: TPoint[], selectedPoints: Set<string>): boolean {
  for (let point of points) {
    if (selectedPoints.has(point._id)) {
      return true;
    }
  }

  return false;
}

function pointIsSelected(pointId: string, selectedPoints: Set<string>): boolean {
  return selectedPoints.has(pointId);
}
