import { cloneDeep } from 'lodash';
import { TShare, TTagAccessLevel } from '../../share.model';
import { EAccessLevel, EAccessParameter } from './access-parameters';
import { correctParameter } from './correct-parameter';

export function correctTagsAccessLogic(
  parameter: EAccessParameter,
  share: TShare,
): TTagAccessLevel {
  let access: EAccessParameter | EAccessLevel = parameter;
  let tagPermission = cloneDeep(share.advancedAccessLevels.tags);

  const { canRead, canEdit } = correctParameter(parameter);

  tagPermission = {
    permission: {
      read: canRead,
      edit: canEdit,
    },
  };

  if (parameter === EAccessParameter.READ) {
    access = EAccessLevel.VIEW_ONLY;
  }

  return tagPermission;
}
