import { clickOutside } from '@core/helpers';
import { Observable, Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';

type TGlobalEventHandlersEventMapKey = keyof GlobalEventHandlersEventMap;

type TClickOutsideHandlerOptions = {
  mouseEventTypes?: TGlobalEventHandlersEventMapKey | TGlobalEventHandlersEventMapKey[];
  enabled?: boolean;
};

export class ClickOutsideHandler {
  private readonly _caught$ = new Subject<MouseEvent>();
  readonly caught$ = this._caught$.asObservable();

  private readonly callback: (MouseEvent) => void;
  private readonly mouseEventTypes: TGlobalEventHandlersEventMapKey[];

  constructor(
    parentElement: HTMLElement,
    destroy$: Observable<void>,
    { mouseEventTypes, enabled }: TClickOutsideHandlerOptions = {
      mouseEventTypes: 'click',
      enabled: false,
    },
  ) {
    this.callback = (event): void =>
      clickOutside(event, parentElement, () => {
        this._caught$.next(event);
      });

    if (Array.isArray(mouseEventTypes)) {
      this.mouseEventTypes = mouseEventTypes;
    } else {
      this.mouseEventTypes = [mouseEventTypes];
    }

    if (enabled) {
      this.enable();
    }

    destroy$
      .pipe(
        take(1),
        tap(() => {
          this.disable();
          this._caught$.complete();
        }),
      )
      .subscribe();
  }

  enable(): void {
    this.mouseEventTypes.forEach((mouseEventType) => {
      window.addEventListener(mouseEventType, this.callback, true);
    });
  }

  disable(): void {
    this.mouseEventTypes.forEach((mouseEventType) => {
      window.removeEventListener(mouseEventType, this.callback, true);
    });
  }
}
