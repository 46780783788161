import { Routes } from '@angular/router';
import { COMMON_GUARD_SERVICES } from '../../services/guards/guard-services.const';

import { DashboardComponent } from './dashboard.component';

export const dashboardRoutes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: COMMON_GUARD_SERVICES,
  },
];
