import { Injectable } from '@angular/core';
import { TWorkspaceUser } from '@project/view-models';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';

@Injectable({
  providedIn: 'root',
})
export class ShareEditEmailInputService {
  readonly commaOrSpacebarRegex = /[ ,]+/;

  constructor(private usersService: UsersService) {}

  getUsersThatMatchKeyword(workspace: TWorkspace | null, keyword: string): string[] {
    const userIds: string[] = [];
    const users = this.usersService.getUsers();
    const keywordsList = this.splitKeywords(keyword);

    for (const userId of Object.keys(users)) {
      if (this.checkIfUserExistsOnWorkspace(users[userId], workspace)) {
        continue;
      }

      const user = users[userId];

      if (this.checkIfUserExistsOnList(user, keywordsList)) {
        continue;
      }

      if (this.checkIfUserMatchesKeyword(user, keywordsList)) {
        userIds.push(userId);
      }
    }

    return userIds;
  }

  splitKeywords(keywords: string): string[] {
    return keywords
      .trim()
      .split(this.commaOrSpacebarRegex)
      .filter((keyword) => keyword.length > 0);
  }

  private checkIfUserMatchesKeyword(user: TWorkspaceUser, keywordsList: string[]): boolean {
    let userMatchesKeyword = false;

    keywordsList.forEach((keyword) => {
      const userNameMatch = this.checkIfWordPartiallyMatchesKeyword(user.userName, keyword);
      const keywordMatch = this.checkIfWordPartiallyMatchesKeyword(user.email, keyword);

      if (keywordMatch || userNameMatch) {
        userMatchesKeyword = true;
      }
    });

    return userMatchesKeyword;
  }

  private checkIfWordPartiallyMatchesKeyword(word: string, keyword: string): boolean {
    if (!word || !keyword) {
      return false;
    }

    return word.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
  }

  private checkIfUserExistsOnWorkspace(
    user: TWorkspaceUser,
    workspace: TWorkspace | null,
  ): boolean {
    if (workspace) {
      return workspace.users?.includes(user.userId);
    }

    return false;
  }

  private checkIfUserExistsOnList(user: TWorkspaceUser, keywordsList: string[]) {
    return keywordsList.includes(user.email);
  }

  getUserKeywordIndex(keywordList: string[], userId: string): number {
    const user = this.usersService.getUser(userId);

    const keywordIndex = keywordList.findIndex((keyword) => {
      return (
        this.checkIfWordPartiallyMatchesKeyword(user.email, keyword) ||
        this.checkIfWordPartiallyMatchesKeyword(user.userName, keyword)
      );
    });

    return keywordIndex !== -1 ? keywordIndex : keywordList.length - 1;
  }
}
