import { Component, Input, OnInit } from '@angular/core';
import { EIconPath } from '../../../../../../../shared/enums/icons.enum';
import { TActivityChange } from '../../activity-change.model';

@Component({
  selector: 'pp-dashlet-activity-images',
  templateUrl: './dashlet-activity-images.component.html',
  styleUrls: ['../../dashlet-activity.component.scss'],
})
export class DashletActivityImagesComponent implements OnInit {
  @Input() ppChange: TActivityChange<string[]>;

  addedImagesNumber: number;
  deletedImagesNumber: number;

  EIconPath = EIconPath;

  ngOnInit(): void {
    if (this.ppChange.newValue.length > this.ppChange.oldValue.length) {
      this.addedImagesNumber = this.ppChange.newValue.length - this.ppChange.oldValue.length;
    } else {
      this.deletedImagesNumber =
        this.ppChange.deleted?.length ||
        this.ppChange.oldValue.length - this.ppChange.newValue.length;
    }
  }
}
