import { Component } from '@angular/core';
import { cloneDeep } from 'lodash';
import { finalize, tap } from 'rxjs';
import { Modal, ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { generateDashletList } from 'src/app/project/modules/dashboard/dashboard-export-modal/generate-dashlet-list';
import { getReportSelectedRange } from 'src/app/project/modules/dashboard/dashlet/dashlet-component/get-dashlet-selected-range';
import { EExportOrientation } from 'src/app/project/modules/exports/utils/export-orientation.enum';
import { EExportFormat } from 'src/app/project/modules/exports/utils/export-page-format-enum';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { TAsset } from '../../../asset-service/asset.consts';
import { AssetService } from '../../../asset-service/asset.service';
import { FleetService } from '../../../fleet-service/fleet.service';
import { TFleet } from '../../../fleet.consts';
import { AssetExportService } from '../asset-card/asset-dashboard/asset-export.service';
import { TExportAsset, TExportAssetParams } from './export-asset-modal.component.consts';

@Component({
  selector: 'pp-export-asset-modal',
  templateUrl: './export-asset-modal.component.html',
  styleUrl: './export-asset-modal.component.scss',
})
export class ExportAssetModalComponent {
  private modal: Modal;
  private assetIds: string[];
  private fleetId: string;
  private fleet: TFleet;
  assets: TAsset[];
  isFleetExport: boolean;
  pageFormat: EExportFormat = EExportFormat.A4;
  modalTitle: string;
  processing = false;

  includeCoverPage = true;
  pageOrientation: EExportOrientation = EExportOrientation.PORTRAIT;
  exportAssets: TExportAsset[] = [];

  constructor(
    private modalService: ModalService,
    private assetService: AssetService,
    private workspaceService: WorkspaceService,
    private accountService: AccountService,
    private assetExportService: AssetExportService,
    private translationPipe: TranslationPipe,
    private fleetService: FleetService,
    private promptService: PromptService,
  ) {}

  ngOnInit(): void {
    this.modal = this.modalService.getModal();
    const modalData: TExportAssetParams = this.modal.data;

    this.assetIds = modalData.assetIds;
    this.assets = this.assetIds.map((assetId) => this.assetService.getAsset(assetId));
    this.fleetId = modalData.fleetId;
    this.fleet = this.fleetService.getFleet(modalData.fleetId);
    this.isFleetExport = this.assetIds.length > 1;
    this.includeCoverPage = this.isFleetExport;

    this.setModalTitle();
    this.generateExportAssets();
  }

  hideModal(cancel: boolean): void {
    this.modalService.hideModal(cancel);
  }

  exportAsset(): void {
    this.processing = true;

    const prompt = this.translationPipe.transform('prompt_export_generation');
    this.promptService.showSuccess(prompt);

    this.assetExportService
      .export({
        assetsAndDashlets: this.exportAssets.filter((asset) => asset.selected),
        coverPage: this.includeCoverPage,
        pageFormat: this.pageFormat,
        pageLayout: this.pageOrientation,
      })
      .pipe(
        finalize(() => (this.processing = false)),
        tap(() => {
          this.modalService.hideModal(false);
        }),
      )
      .subscribe();
  }

  checkDisabledModal(): boolean {
    return this.exportAssets.every((asset) => !asset.selected);
  }

  private generateExportAssets(): void {
    const workspaces = this.workspaceService.getWorkspaces();
    const accounts = this.accountService.getAccounts();

    this.assets.forEach((asset) => {
      this.exportAssets.push({
        name: asset.name,
        assets:
          asset.sites.length > 0
            ? generateDashletList(
                cloneDeep(asset.dashlets).map((dashlet) => ({
                  ...dashlet,
                  selectedRange: getReportSelectedRange(
                    dashlet.name,
                    dashlet.selectedRange,
                    asset.sites.map((site) => site.workspaceId),
                    workspaces,
                    accounts,
                  ),
                })),
                accounts,
                workspaces,
              )
            : [],
        id: asset.id,
        selected: true,
      });
    });
  }

  private setModalTitle(): void {
    switch (this.assetIds.length) {
      case 1:
        this.modalTitle =
          this.translationPipe.transform('export_asset_name_1') + this.assets[0].name;
        +this.translationPipe.transform('export_asset_name_2');
        break;
      default:
        this.modalTitle = this.translationPipe.transform('export_asset_name_1') + this.fleet.name;
        +this.translationPipe.transform('export_asset_name_2');
        break;
    }
  }
}
