import { TFilters, TPriorityFilter } from '../site-filter.model';
import { isPriorityDefault } from './is-priority-default';

export function clearPriority(filters: TFilters, applied?: boolean): TPriorityFilter[] {
  filters.priority.forEach((priority) => {
    if (priority.value !== priority.default) {
      priority.value = priority.default;

      if (applied) {
        priority.appliedValue = priority.default;
      }
    }
  });

  filters.priorityDefault = isPriorityDefault(filters);

  return filters.priority;
}
