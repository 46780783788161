import { TFilters } from '../site-filter.model';
import { areArraysEqualSets } from './are-arrays-equal-sets';

export function compareFilterTags(firstFilter: TFilters, secondFilter: TFilters): boolean {
  const tags1 = firstFilter.tags;
  const tags2 = secondFilter.tags;

  if (tags1.value.length !== tags2.value.length) {
    return false;
  }

  return areArraysEqualSets(tags1.value, tags2.value);
}
