import { Component, Input } from '@angular/core';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { TCombinedCustomField } from 'src/app/project/modules/custom-fields/combine-custom-fields/combined-custom-field.model';
import { EIntegrationStatus } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-account-user-management-header-custom-field',
  templateUrl: './account-user-management-header-custom-field.component.html',
  styleUrls: ['./account-user-management-header-custom-field.component.scss'],
})
export class AccountUserManagementHeaderCustomFieldComponent {
  @Input() ppCustomField: TCombinedCustomField;

  tooltipText: string;
  integrationIcon: EIconPath;

  constructor(
    private translationPipe: TranslationPipe,
    private workspaceService: WorkspaceService,
  ) {}

  ngOnChanges(): void {
    this.setTooltipText();
    this.checkIntegrationIcon();
  }

  private checkIntegrationIcon(): void {
    switch (this.ppCustomField.volyIntegrationActive) {
      case EIntegrationStatus.OFF:
        this.integrationIcon = null;
        break;
      case EIntegrationStatus.ON:
        this.integrationIcon = EIconPath.ICON_INTEGRATION_VOLY_ACTIVE_SMALL;
        break;
      case EIntegrationStatus.DISABLED:
        this.integrationIcon = EIconPath.ICON_INTEGRATION_VOLY_INACTIVE_SMALL;
    }
  }

  private setTooltipText(): void {
    this.tooltipText = this.generateTooltipText();
  }

  private generateTooltipText(): string {
    let tooltip = `
      <div style='text-align: left; margin-top: 1rem'>
      ${this.translationPipe.transform('custom_field_used_on_sites_name', {
        name: this.ppCustomField.label,
      })}

      <ul>`;

    this.ppCustomField.combinedIds.forEach((combinedId) => {
      const workspace = this.workspaceService.getWorkspace(combinedId.workspaceId);

      tooltip +=
        '<li>•	<strong>' + workspace.accountName + '</strong> - ' + workspace.siteName + '</li>';
    });

    tooltip += `
        </ul>
      </div>
    `;

    return tooltip;
  }
}
