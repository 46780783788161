import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { CustomFieldsService } from '../../../custom-fields/custom-fields.service';
import { TDashletBudgetItem } from './new-dashlet-modal-cost-fields.consts';
import { NewDashletModalCostFieldsService } from './new-dashlet-modal-cost-fields.service';
import { ECustomFieldType } from '../../../custom-fields/custom-field-types-enums';

@Component({
  selector: 'pp-new-dashlet-modal-cost-fields',
  templateUrl: './new-dashlet-modal-cost-fields.component.html',
  styleUrl: './new-dashlet-modal-cost-fields.component.scss',
})
export class NewDashletModalCostFieldsComponent implements OnInit, OnChanges {
  @Input() ppAssetId: string;
  @Input() ppNewDashlet: boolean;
  @Input() ppInitialRows: TDashletBudgetItem[] = [
    {
      workspaceId: null,
      costFieldId: null,
      budgetFieldId: null,
    },
  ];
  @Output() ppAddDashlet: EventEmitter<TDashletBudgetItem[]> = new EventEmitter<
    TDashletBudgetItem[]
  >();
  @Output() ppGoBack: EventEmitter<void> = new EventEmitter<void>();
  @Output() ppDeleteDashlet: EventEmitter<void> = new EventEmitter<void>();

  EIconPath = EIconPath;
  itemRows: TDashletBudgetItem[] = [];
  isDisabled: boolean = false;
  errorMessages: string[] = [];

  headerText: string;
  confirmButtonText: string;

  constructor(
    private modalService: ModalService,
    private customFieldsService: CustomFieldsService,
    private translationPipe: TranslationPipe,
    private newDashletModalCostFieldsService: NewDashletModalCostFieldsService,
  ) {}

  ngOnChanges(): void {
    this.setHeader();
    this.setConfirmButtonText();
  }

  ngOnInit(): void {
    this.itemRows = [...this.ppInitialRows];
    this.checkError();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  addDashlet(): void {
    this.ppAddDashlet.emit(this.itemRows);
  }

  goBack(): void {
    this.ppGoBack.emit();
  }

  addRow(): void {
    if (this.itemRows.length >= 5) {
      return;
    }

    this.itemRows.push({
      workspaceId: null,
      costFieldId: null,
      budgetFieldId: null,
    });
  }

  siteRowChange(row: TDashletBudgetItem, index: number): void {
    this.itemRows[index] = row;

    this.checkError();

    this.newDashletModalCostFieldsService.sitesChange();
  }

  deleteRow(index: number): void {
    this.itemRows.splice(index, 1);

    this.checkError();

    this.newDashletModalCostFieldsService.sitesChange();
  }

  deleteDashlet(): void {
    this.ppDeleteDashlet.emit();
  }

  private checkError(): void {
    let currenciesDontMatch = false;
    let fieldMissing = false;
    let isDisabled = false;
    let formulaTypeError = false;
    const errorMessages: string[] = [];

    if (this.itemRows.length === 0) {
      isDisabled = true;
    }

    this.itemRows.forEach((row) => {
      if (!row.budgetFieldId || !row.costFieldId) {
        isDisabled = true;
        return;
      }

      const costCustomField = this.customFieldsService.getWorkspaceCustomField(
        row.workspaceId,
        row.costFieldId,
      );
      const budgetCustomField = this.customFieldsService.getWorkspaceCustomField(
        row.workspaceId,
        row.budgetFieldId,
      );

      if (costCustomField?.type === ECustomFieldType.FORMULA) {
        formulaTypeError =
          costCustomField.outputType === ECustomFieldType.COST ? formulaTypeError : true;
      }

      if (budgetCustomField?.type === ECustomFieldType.FORMULA) {
        formulaTypeError =
          budgetCustomField.outputType === ECustomFieldType.COST ? formulaTypeError : true;
      }

      currenciesDontMatch =
        costCustomField &&
        budgetCustomField &&
        (currenciesDontMatch || costCustomField?.currencyCode !== budgetCustomField?.currencyCode);

      fieldMissing = fieldMissing || !costCustomField || !budgetCustomField;
    });

    if (currenciesDontMatch) {
      errorMessages.push(this.translationPipe.transform('cost_comparison_currency_error'));
    }

    if (fieldMissing || formulaTypeError) {
      errorMessages.push(this.translationPipe.transform('cost_comparison_field_missing_error'));
    }

    this.errorMessages = errorMessages;
    this.isDisabled = isDisabled || currenciesDontMatch || fieldMissing;
  }

  private setHeader(): void {
    this.headerText = this.ppNewDashlet
      ? this.translationPipe.transform('add_dashlet_budget')
      : this.translationPipe.transform('edit_dashlet_budget');
  }

  private setConfirmButtonText(): void {
    this.confirmButtonText = this.ppNewDashlet
      ? this.translationPipe.transform('add_dashlet')
      : this.translationPipe.transform('update_dashlet');
  }
}
