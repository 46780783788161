<div class="dashletActivity">
  <img
    class="dashletActivity__icon dashletActivity__icon--narrow"
    [src]="EIconPath.BASIC_FIELD_COMMENT"
  />

  <div class="dashletActivity__text">
    <ng-container [ngSwitch]="ppChange.propName">
      <ng-container *ngSwitchCase="'pointCopyTo'">
        {{ 'copied_this_point_from' | ppTranslate }}
        <span class="dashletActivities__item-quoted">{{ ppChange.oldValue }}.</span>
      </ng-container>

      <ng-container *ngSwitchCase="'pointCopyFrom'">
        {{ 'copied_this_point_to' | ppTranslate }}
        <span class="dashletActivities__item-quoted">{{ ppChange.newValue }}.</span>
      </ng-container>

      <ng-container *ngSwitchDefault>
        {{ 'copied_this_point_to' | ppTranslate }}
        <span class="dashletActivities__item-quoted">{{ ppChange.newValue }}.</span>
      </ng-container>
    </ng-container>
  </div>
</div>
