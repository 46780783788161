import { cloneDeep } from 'lodash';

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, forkJoin } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { TPoint, TPointsByWorkspace } from 'src/app/project/modules/points/points.model';
import { TUser } from 'src/app/project/modules/user/user.model';

import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { PreferencesService } from 'src/app/project/modules/preferences/preferences-service/preferences.service';
import { UserService } from 'src/app/project/modules/user/user.service';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { WorkspaceService } from '../../workspace/workspace.service';
import {
  SiteTableColumnsDataService,
  TColumnsData,
} from '../site-table/site-table-columns-data.service';
import { SiteTableEventsService } from '../site-table/site-table-events.service';

import { ApiService } from '@core/http';
import { TAllUsers } from '@project/view-models';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { EWorkspaces } from 'src/app/project/modules/workspace/workspaces.enum';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { TActive } from '../../../services/active/active.model';
import { ActiveService } from '../../../services/active/active.service';
import { AccountService } from '../../account/account-service/account.service';
import { TCustomField } from '../../custom-fields/custom-fields.model';
import { PointAttachmentsService } from '../../points/point-modal/point-attachments/point-attachments.service';
import { TPreferences } from '../../preferences/preferences.model';
import { SiteOptionsService } from '../site-options/site-options.service';
import { TableColumnsService } from '../site-table/columns/table-columns.service';
import { CustomTableService } from '../site-table/custom-table/custom-table.service';
import Table from '../site-table/custom-table/table/Table';
import { SiteTableOverviewService } from './site-table-overview.service';

@Component({
  selector: 'pp-site-table-overview',
  templateUrl: './site-table-overview.component.html',
  styleUrls: ['../site-table/site-table.component.scss'],
})
export class SiteTableOverviewComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('tableWrapper', { static: true }) tableWrapperElement: ElementRef;
  @ViewChild('table', { static: true }) tableElement: ElementRef;

  @Output() ppTableCreated = new EventEmitter();

  private readonly destroy$ = new Subject<void>();

  columns: TColumnsData = this.siteTableColumnsDataService.getAllColumns();

  points$: Observable<TPointsByWorkspace>;
  points: TPoint[] = [];
  private active$: Observable<TActive>;
  private table: Table;

  public users: TAllUsers;
  public user: TUser;

  constructor(
    private store: Store<{ points: TPointsByWorkspace; active: TActive }>,
    private siteTableEventsService: SiteTableEventsService,
    private workspaceService: WorkspaceService,
    private siteTableColumnsDataService: SiteTableColumnsDataService,
    private usersService: UsersService,
    private userService: UserService,
    private sitePointFilterService: SitePointFilterService,
    private preferencesService: PreferencesService,
    private router: Router,
    private pointAttachmentsService: PointAttachmentsService,
    private activeService: ActiveService,
    private siteOptionsService: SiteOptionsService,
    private siteTableOverviewService: SiteTableOverviewService,
    private apiService: ApiService,
    private dropdownService: DropdownService,
    private customTableService: CustomTableService,
    private tableColumnsService: TableColumnsService,
  ) {
    this.points$ = this.store.pipe(select(EStore.POINTS));
    this.active$ = this.store.pipe(select(EStore.ACTIVE));
  }

  ngOnInit() {
    this.users = this.usersService.getUsers();
    this.user = this.userService.getUser();

    this.points$.pipe(takeUntil(this.destroy$)).subscribe((pointsStore) => {
      const points = cloneDeep(pointsStore);

      this.points = [];

      Object.keys(points).forEach((workspaceId) => {
        this.points.push(...points[workspaceId].entities);
      });

      this.users = this.usersService.getUsers();
    });

    this.active$.pipe(takeUntil(this.destroy$)).subscribe((active) => {
      if (this.table) {
        this.table.setActivePointId(active._id);
      }
    });
  }

  ngAfterViewInit() {
    this.userService
      .fetchUser()
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => {
          this.table = this.createTableInstance();

          this.tableWrapperElement.nativeElement.replaceChild(
            this.table.element,
            this.tableElement.nativeElement,
          );

          this.table.updateColumns();
          this.table.updateWidth();
          this.table.updatePoints(this.points);
          this.table.load(false);

          this.sitePointFilterService.filterPoints();

          const fetchWorkspaces$ = this.workspaceService.fetchWorkspaces().pipe(
            takeUntil(this.destroy$),
            tap((accounts) => {
              const customFields = AccountService.getAccountsWorkspacesCustomFields(accounts);
              const preferences = this.preferencesService.getPreferences();

              this.customTableService.checkColumns(customFields);
              this.table.updateVisibleColumnIndexes();
              this.table.updateVisibleColumnPositions();
              this.table.load(true);
              this.processPreferencesResponse(preferences);
            }),
          );

          const fetchUsers$ = this.usersService.fetchAllUsers({ refetch: true }).pipe(
            tap((response) => {
              this.table.loadAvatars(response);
            }),
          );

          return forkJoin([fetchWorkspaces$, fetchUsers$]).pipe(
            tap(() => {
              this.ppTableCreated.emit();
            }),
          );
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  openPoint(point: TPoint): void {
    const attachmentUploading = this.pointAttachmentsService.getAttachmentUploading();

    if (!attachmentUploading) {
      this.siteTableEventsService.openPoint(point, { isOverview: true });
    }
  }

  processPreferencesResponse(preferences: TPreferences): void {
    this.table = this.customTableService.getTable();
    this.siteTableOverviewService.processPreferencesResponse(
      preferences,
      this.tableColumnsService.getColumns(),
    );
  }

  isOverviewPage(): boolean {
    return this.router.url === '/site/overview';
  }

  private createTableInstance(): Table {
    return new Table(true, {
      workspaceId: null,
      apiService: this.apiService,
      dropdownService: this.dropdownService,
      selectPointCallback: (_point): void => {
        this.siteTableEventsService.togglePointSelection({
          workspaceId: _point.workspaceRef.id,
          _id: _point._id,
        });
      },
      selectAllPointsCallback: (selectAll: boolean): void => {
        this.siteTableEventsService.toggleAllPointsSelection(selectAll);
      },
      openPointCallback: (_point): void => {
        this.openPoint(_point);
      },
      savePreferencesCallback: (): void => {
        this.preferencesService.savePreferences(EWorkspaces.OVERVIEW);
      },
      getCustomFieldsCallback: (): Observable<TCustomField[]> =>
        this.workspaceService.fetchWorkspaces().pipe(
          takeUntil(this.destroy$),
          map((accounts) => AccountService.getAccountsWorkspacesCustomFields(accounts)),
        ),
      pointAttachmentsService: this.pointAttachmentsService,
      activePointId: this.activeService.getActivePointId(),
      siteOptionsService: this.siteOptionsService,
      customTableService: this.customTableService,
    });
  }
}
