import { Component, Input, OnChanges } from '@angular/core';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { UserService } from 'src/app/project/modules/user/user.service';
import { TPushNotification } from '../../notification.model';
import { ENotificationReason } from '../../utils/notification-reason.enum';
import { ENotificationType } from '../notification-type.enum';

// Old version of notifications that is saved on backend and can be returned but no new notifications use
@Component({
  selector: 'pp-notification-legacy',
  templateUrl: './notification-legacy.component.html',
  styleUrls: ['../notification.component.scss'],
})
export class NotificationLegacyComponent implements OnChanges {
  @Input() ppNotification: TPushNotification<string[]>;

  spanClass: string;
  iconClass: string;
  change: string;
  ENotificationType = ENotificationType;
  isMention: boolean;

  constructor(private userService: UserService, private translationPipe: TranslationPipe) {}

  ngOnChanges(): void {
    this.generateChangeMessage();
    this.checkIsMention();
  }

  private generateChangeMessage(): void {
    const activeUserId = this.userService.getUser().userId;

    switch (this.ppNotification.pushNotificationType) {
      case ENotificationType.POINT_CREATION:
        this.spanClass = 'sw-icon sw-icon-new-point';
        this.change = this.translationPipe.transform('created_point');

        break;
      case ENotificationType.POINT_EDITION:
        this.change = this.translationPipe.transform('edited_point');

        break;
      case ENotificationType.POINT_EDITION_TITLE:
        this.iconClass = 'fa fa-heading';

        this.change =
          this.ppNotification.notificationReason === ENotificationReason.ASSIGNED
            ? this.translationPipe.transform('changed_title_point_assigned')
            : this.translationPipe.transform('changed_title_point_created');

        break;
      case ENotificationType.POINT_EDITION_STATUS:
        this.iconClass = 'fa fa-sync';
        this.change = this.translationPipe.transform('changed_status');

        break;
      case ENotificationType.POINT_EDITION_PRIORITY:
        this.iconClass = 'fa fa-exchange-alt';
        this.change = this.translationPipe.transform('changed_priority');

        break;
      case ENotificationType.POINT_EDITION_DESCRIPTION:
        this.iconClass = 'fa fa-paragraph';
        this.change = this.translationPipe.transform('changed_description');

        break;
      case ENotificationType.POINT_EDITION_COMMENT:
        this.iconClass = 'fa fa-comment';
        this.change = this.translationPipe.transform('added_comment');

        break;
      case ENotificationType.POINT_EDITION_PIN_MOVE:
        this.iconClass = 'fa fa-map-marker-alt';
        this.change = this.translationPipe.transform('changed_location_notification');

        break;
      case ENotificationType.POINT_EDITION_ATTACHMENT:
        this.spanClass = 'sw-icon sw-icon-documents';
        this.change = this.translationPipe.transform('changed_attachment');

        break;

      case ENotificationType.POINT_EDITION_VIDEOS:
        this.spanClass = 'sw-icon sw-icon-videos';
        this.change = this.translationPipe.transform('changed_videos');

        break;

      case ENotificationType.POINT_EDITION_IMAGES:
        this.spanClass = 'sw-icon sw-icon-photo';
        this.change = this.translationPipe.transform('changed_images');

        break;

      case ENotificationType.POINT_EDITION_DOCUMENTS:
        this.spanClass = 'sw-icon sw-icon-documents';
        this.change = this.translationPipe.transform('changed_documents');

        break;

      case ENotificationType.POINT_EDITION_IMAGES360:
        this.spanClass = 'sw-icon sw-icon-photo';
        this.change = this.translationPipe.transform('changed_images_360');

        break;

      case ENotificationType.POINT_EDITION_TAGS:
        this.iconClass = 'fa fa-tag';
        this.change = this.translationPipe.transform('changed_tags');

        break;
      case ENotificationType.POINT_EDITION_CUSTOM_FIELDS:
        this.iconClass = 'fa fa-sliders-h';
        this.change = this.translationPipe.transform('changed_custom_fields');

        break;
      case ENotificationType.POINT_MENTION:
        this.change = this.translationPipe.transform('you_were_mentioned_point');

        break;
      case ENotificationType.POINT_EDITION_ASSIGNEE:
        this.iconClass = 'fa fa-user';

        if (this.ppNotification.changeBody.length) {
          const changeElement =
            this.ppNotification.changeBody[0] || this.ppNotification.changeBody.newValue;

          if (changeElement) {
            if (changeElement.newValue && changeElement.newValue.includes(activeUserId)) {
              this.change = `${this.translationPipe.transform(
                'added',
              )} <strong>${this.translationPipe.transform('you')}</strong>.`;
            } else if (changeElement.oldValue && changeElement.oldValue.includes(activeUserId)) {
              this.change = `${this.translationPipe.transform(
                'removed',
              )} <strong>${this.translationPipe.transform('you')}</strong>.`;
            } else {
              this.change = this.translationPipe.transform('changed_assignees');
            }
          }
        }

        break;
      case ENotificationType.POINT_MENTION_CUSTOM_FIELD:
        this.change = `${this.translationPipe.transform(
          'removed',
        )} <strong>${this.translationPipe.transform(
          'you',
        )}</strong> ${this.translationPipe.transform('in_a_custom_field')}`;

        break;
      case ENotificationType.POINT_REMOVAL:
        this.spanClass = 'sw-icon sw-icon-delete';
        this.change = this.translationPipe.transform('point_was_deleted');

        break;
      case ENotificationType.POINT_MENTION_COMMENT:
        this.iconClass = 'fa fa-comment';
        this.change = `${this.translationPipe.transform(
          'mentioned',
        )} <strong>${this.translationPipe.transform(
          'you',
        )}</strong> ${this.translationPipe.transform('in_a_comment')}`;

        break;
      case ENotificationType.POINT_MENTION_DESCRIPTION:
        this.iconClass = 'fa fa-comment';
        this.change = `${this.translationPipe.transform(
          'mentioned',
        )} <strong>${this.translationPipe.transform(
          'you',
        )}</strong> ${this.translationPipe.transform('in_description')}`;

        break;

      case ENotificationType.POINT_EDITION_ADDITIONAL_PINS_MOVE:
        this.iconClass = 'fa fa-map-marker-alt';
        this.change = this.translationPipe.transform('changed_pin_location');

        break;

      case ENotificationType.POINT_EDITION_POLYGONS_MOVE:
        this.iconClass = 'fa fa-map-marker-alt';
        this.change = this.translationPipe.transform('changed_an_area');

        break;

      default:
        this.change = this.translationPipe.transform('edited_point') + '.';

        break;
    }
  }

  private checkIsMention(): void {
    this.isMention =
      this.ppNotification.pushNotificationType === ENotificationType.POINT_MENTION ||
      this.ppNotification.pushNotificationType === ENotificationType.POINT_MENTION_CUSTOM_FIELD ||
      this.ppNotification.pushNotificationType === ENotificationType.POINT_MENTION_COMMENT ||
      this.ppNotification.pushNotificationType === ENotificationType.POINT_MENTION_DESCRIPTION;
  }
}
