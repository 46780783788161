import { cloneDeep } from 'lodash';
import { TGranularPermission, TShare } from '../../share.model';
import { EAccessLevel, EAccessParameter } from './access-parameters';
import { correctParameter } from './correct-parameter';

export function correctCommentsAccessLogic(
  parameter: EAccessParameter,
  share: TShare,
): { permission: TGranularPermission } {
  let commentsAccess = cloneDeep(share.advancedAccessLevels.timeline.comments);

  const { canRead, canEdit } = correctParameter(parameter);
  let accessLevel = EAccessLevel.HIDE_COMMENTS;

  commentsAccess = {
    permission: {
      read: canRead,
      edit: canEdit,
    },
  };

  if (commentsAccess.permission.read) {
    accessLevel = EAccessLevel.VIEW_COMMENTS;
  } else if (commentsAccess.permission.edit) {
    accessLevel = EAccessLevel.MAKE_COMMENTS;
  }

  return commentsAccess;
}
