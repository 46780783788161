import { Component, ElementRef, Input, OnChanges } from '@angular/core';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { PermissionsService } from 'src/app/project/modules/share/permissions.service';
import { canEditPoint } from 'src/app/project/modules/share/share-utils/share-permissions';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { TUser } from 'src/app/project/modules/user/user.model';
import { UserService } from 'src/app/project/modules/user/user.service';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { PointsService } from '../../../points.service';
import { PointActionBarOptionsDropdownComponent } from '../../point-action-bar/point-action-bar-options-dropdown/point-action-bar-options-dropdown.component';

@Component({
  selector: 'pp-point-options',
  templateUrl: './point-options.component.html',
  styleUrls: ['./point-options.component.scss'],
})
export class PointOptionsComponent implements OnChanges {
  @Input() ppPointId: string;
  @Input() ppFull: boolean;
  @Input() ppNew: boolean;
  @Input() ppWorkspaceId: string;

  EIconPath = EIconPath;
  buttonVisible: boolean;
  buttonId = 'pointOptions';

  private canExport: boolean;
  private canCopy: boolean;
  private canMove: boolean;
  private canDelete: boolean;
  private canEditCustomFields: boolean;
  private canEditTags: boolean;

  private dropdown: TDropdown = this.dropdownService.getDropdown();

  constructor(
    private permissionsService: PermissionsService,
    private dropdownService: DropdownService,
    private workspaceService: WorkspaceService,
    private userService: UserService,
    private elementRef: ElementRef,
    private pointsService: PointsService,
  ) {}

  ngOnChanges(): void {
    this.initPermissions();
  }

  showMoreOptions(): void {
    if (this.dropdown.visible && this.dropdown.buttonId === this.buttonId) {
      this.dropdownService.hideDropdown();
    } else {
      this.setDropdownData();

      this.dropdownService.showDropdown(this.buttonId, PointActionBarOptionsDropdownComponent, {});
    }
  }

  private initPermissions(): void {
    const workspaces = this.workspaceService.getWorkspaces();

    const user = this.userService.getUser();
    const workspace = this.ppWorkspaceId ? workspaces[this.ppWorkspaceId] : null;

    if (workspace === null) {
      this.canEditTags = false;
      this.canEditCustomFields = false;
    } else if (workspace) {
      this.setTagPermission();
      this.setCustomFieldPermission(workspace, user);
    }

    this.setExportPermission();
    this.setCopyPermission(workspace, user);
    this.setMovePermission(workspace, user);
    this.setDeletePermission(workspace, user);
    this.setButtonVisibility();
  }

  private setTagPermission(): void {
    this.canEditTags = this.permissionsService.getTagPermissions(this.ppWorkspaceId).edit;
  }

  private setCustomFieldPermission(workspace: TWorkspace, user: TUser): void {
    const customFieldPermissions = this.permissionsService.checkWorkspaceCustomFieldPermissions(
      this.ppWorkspaceId,
    );

    if (Object.keys(customFieldPermissions).length > 0) {
      Object.keys(customFieldPermissions).forEach((customFieldId) => {
        if (customFieldPermissions[customFieldId].edit) {
          this.canEditCustomFields = true;
        }
      });
    } else {
      this.canEditCustomFields = canEditPoint(workspace.share.shareOption, user);
    }
  }

  private setDeletePermission(workspace: TWorkspace, user: TUser): void {
    this.canDelete =
      workspace && (user.isSuperUser || this.hasAdminPermissions(workspace.share.shareOption));
  }

  private setMovePermission(workspace: TWorkspace, user: TUser): void {
    this.canMove =
      workspace && (this.hasAdminPermissions(workspace.share.shareOption) || user.isSuperUser);
  }

  private setCopyPermission(workspace: TWorkspace, user: TUser): void {
    this.canCopy =
      workspace &&
      (this.hasAdminPermissions(workspace.share.shareOption) ||
        user.isSuperUser ||
        (workspace.share.shareOption === EUserRole.NORMAL &&
          this.canEditCustomFields &&
          this.canEditTags));
  }

  private setExportPermission(): void {
    this.canExport = this.ppWorkspaceId
      ? this.permissionsService.areExportsEnabled(this.ppWorkspaceId)
      : false;
  }

  private setDropdownData(): void {
    this.dropdownService.setData({
      point: this.pointsService.findPoint(this.ppPointId),
      isFull: this.ppFull,
      canExport: this.canExport,
      canMove: this.canMove,
      canCopy: this.canCopy,
      canDelete: this.canDelete,
    });
  }

  private setButtonVisibility(): void {
    const point = this.pointsService.findPoint(this.ppPointId);

    this.buttonVisible =
      !this.ppNew && point && (this.canCopy || this.canMove || this.canExport || this.canDelete);
    const host = this.elementRef.nativeElement;

    host.style.display = this.buttonVisible ? 'block' : 'none';
  }

  private hasAdminPermissions(shareOption: string): boolean {
    return (
      shareOption === EUserRole.SITE_ADMIN ||
      shareOption === EUserRole.ACCOUNT_ADMIN ||
      shareOption === EUserRole.OWNER
    );
  }
}
