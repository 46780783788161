import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-fleet-sharing-bulk-changes-bar',
  templateUrl: './fleet-sharing-bulk-changes-bar.component.html',
  styleUrls: ['./fleet-sharing-bulk-changes-bar.component.scss'],
})
export class FleetSharingBulkChangesBarComponent {
  @Input() ppSelectedShares: string[];
  @Input() ppZIndex: number;
  @Output() ppDeleteShares = new EventEmitter<void>();
  @Output() ppDeselectAll = new EventEmitter<void>();

  EIconPath = EIconPath;

  deselectAll(): void {
    this.ppDeselectAll.emit();
  }

  removeShares(): void {
    this.ppDeleteShares.emit();
  }
}
