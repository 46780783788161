import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TRichTextOptions } from 'src/app/project/components/input/rich-text/rich-text.consts';
import { TNewComment } from 'src/app/project/data-providers/api-providers/comment-api-provider/comment-requests.model';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { TReaction } from 'src/app/project/modules/reactions/reactions.model';
import { PermissionsService } from 'src/app/project/modules/share/permissions.service';
import { TUser } from 'src/app/project/modules/user/user.model';
import { UserService } from 'src/app/project/modules/user/user.service';
import { ReactionsService } from 'src/app/project/services/reactions.service';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { TPushNotification } from '../../notification.model';
import { ENotificationType } from '../notification-type.enum';

@Component({
  selector: 'pp-notification-rich-text',
  templateUrl: './notification-rich-text.component.html',
  styleUrls: ['../notification.component.scss'],
})
export class NotificationRichTextComponent implements OnInit {
  @Input() ppNotification: TPushNotification<string>;
  @Input() ppReaction: TReaction;
  @Input() ppRespondingToComment: boolean;
  @Input() ppCommentSent: boolean;

  @Output() ppRespond = new EventEmitter<Event>();

  ENotificationType = ENotificationType;
  ECustomFieldType = ECustomFieldType;
  activeUser: TUser;
  richText: string;
  plainText: string;
  EIconPath = EIconPath;

  respondingToComment = false;
  commentsEditable = false;
  respondableNotification = false;

  newComment: TNewComment = {
    comment: '',
    commentRich: '',
    mentions: [],
  };

  richTextOptions: TRichTextOptions = {
    showButtons: false,
    clearable: false,
    defaultBorder: false,
    comment: true,
    addQuotation: true,
  };

  constructor(
    private reactionsService: ReactionsService,
    private userService: UserService,
    private permissionsService: PermissionsService,
  ) {}

  ngOnInit(): void {
    this.activeUser = this.userService.getUser();

    this.checkCommentsPermissions();

    this.richText = this.ppNotification.changeBody.commentRich
      ? this.ppNotification.changeBody.commentRich
      : this.ppNotification.pushNotificationType === ENotificationType.POINT_EDITION_DESCRIPTION ||
        this.ppNotification.changeBody?.cfFieldType === ECustomFieldType.TEXT ||
        this.ppNotification.pushNotificationType === ENotificationType.POINT_MENTION_DESCRIPTION
      ? null
      : this.ppNotification.changeBody.newValue;

    this.plainText = this.ppNotification.changeBody.comment
      ? this.ppNotification.changeBody.comment
      : this.ppNotification.changeBody.newValue;

    this.respondableNotification =
      ((this.ppNotification.pushNotificationType === ENotificationType.POINT_EDITION_COMMENT ||
        this.ppNotification.pushNotificationType === ENotificationType.POINT_MENTION_CUSTOM_FIELD ||
        this.ppNotification.pushNotificationType === ENotificationType.POINT_EDITION_DESCRIPTION ||
        this.ppNotification.pushNotificationType === ENotificationType.POINT_MENTION_COMMENT ||
        this.ppNotification.pushNotificationType === ENotificationType.POINT_MENTION_DESCRIPTION) &&
        this.ppNotification.changeBody &&
        this.ppNotification.changeBody !== 'undefined') ||
      this.ppNotification.changeBody?.cfFieldType === ECustomFieldType.RICHTEXT;
  }

  likeComment(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    let like = true;

    if (this.ppReaction) {
      like = !this.ppReaction.like.includes(this.activeUser.userId);
    }

    this.reactionsService
      .likeComment(this.ppNotification.changeBody.commentId, this.ppNotification.refObject.id, like)
      .subscribe(() => {
        this.ppReaction = this.reactionsService
          .getReactions()
          .notifications.data.find(
            (_reaction) => _reaction.targetRef.id === this.ppNotification.changeBody?.commentId,
          );
      });
  }

  respond(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.ppRespond.emit(event);
  }

  checkCommentsPermissions(): void {
    const commentPermissions = this.permissionsService.getCommentPermissions(
      this.ppNotification.workspaceId,
    );

    this.commentsEditable = commentPermissions.edit;
  }
}
