import { Component, Input, OnChanges } from '@angular/core';
import { PointsHelperService } from 'src/app/project/modules/points/points-helper.service';
import { EIconPath } from '../../../../../../../shared/enums/icons.enum';
import { TActivityChange } from '../../activity-change.model';

@Component({
  selector: 'pp-dashlet-activity-status',
  templateUrl: './dashlet-activity-status.component.html',
  styleUrls: ['../../dashlet-activity.component.scss'],
})
export class DashletActivityStatusComponent implements OnChanges {
  @Input() ppChange: TActivityChange<string>;

  color: string;
  activityChange: string;
  EIconPath = EIconPath;

  constructor(private pointsHelperService: PointsHelperService) {}

  ngOnChanges() {
    this.color = this.pointsHelperService.statusPriorityToColor(this.ppChange.newValue);
    this.activityChange = this.pointsHelperService.humanizeActivityChange(this.ppChange.newValue);
  }
}
