<div class="fleetExportAssets">
  <header class="fleetExportAssetsHeader" (click)="toggleAll()">
    <pp-checkbox ppType="solid" [ppChecked]="allSelected" *ngIf="isFleetExport"></pp-checkbox>

    {{ 'select_items_to_export' | ppTranslate }}
  </header>

  <pp-fleet-export-page-asset-picker
    *ngFor="let asset of ppExportAssets; let index = index"
    [ppExportAsset]="asset"
    [ppSelectable]="ppSelectable"
    (ppExportAssetChange)="exportAssetsChange()"
  ></pp-fleet-export-page-asset-picker>
</div>
