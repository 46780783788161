import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScreenService } from '@core/services';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TButtonSize } from '../../button/button.model';

@Component({
  selector: 'pp-edit-button',
  templateUrl: './edit-button.component.html',
  styleUrls: ['./edit-button.component.scss'],
})
export class EditButtonComponent implements OnInit {
  @Input() ppId: string;
  @Input() ppNoText = false;
  @Input() ppDisabled: boolean;
  @Input() ppSize: TButtonSize = 'small';
  @Output() ppAction = new EventEmitter();

  isMobile: boolean;
  EIconPath = EIconPath;

  constructor(private screenService: ScreenService) {}

  ngOnInit(): void {
    this.screenService.isMobile$.pipe().subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  action(event: MouseEvent): void {
    if (!this.ppDisabled) {
      this.ppAction.emit(event);
    }
  }
}
