<div class="newAssetLabel">
  <section class="newAssetLabelInputAndDeleteWrapper">
    <button class="deleteLabelButton">
      <pp-icon
        class="newAssetLabelInputIcon"
        [ppSrc]="EIconPath.ICON_CLOSE_14"
        ppClass="setSize14"
        ppColo="inherit"
        (click)="deleteLabel()"
      ></pp-icon>
    </button>

    <section class="newAssetLabelInputWrapper">
      <input
        class="newAssetLabelInput"
        [(ngModel)]="ppName"
        (ngModelChange)="updateName($event)"
        [placeholder]="'new_asset_label_placeholder' | ppTranslate"
      />

      <pp-icon
        class="newAssetLabelInputIcon"
        [ppSrc]="EIconPath.ICON_CLOSE_14"
        ppClass="setSize14"
        ppColo="inherit"
        (click)="updateName('')"
      ></pp-icon>
    </section>
  </section>

  <section class="newAssetColorPicker">
    <input
      type="color"
      [(ngModel)]="ppColor"
      (ngModelChange)="updateColor($event)"
      class="newAssetColorPickerInput"
    />
  </section>

  <section class="newAssetAssigneePicker">
    <pp-asset-label-user-picker
      [(ppAssigneeId)]="ppAssigneeId"
      (ppAssigneeIdChange)="updateAssignee($event)"
    ></pp-asset-label-user-picker>
  </section>
</div>
