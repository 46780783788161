import { GET_TABLE } from '../../table.ui.store';
import { createElement } from 'src/app/core/helpers/dom';
import { TGroupedPoints } from '../../grouping/group-model';
import { getGroupValueById } from './getGroupValueById';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { ApiService } from '@core/http';
import { createOptionsElement } from './create-header-row/utils/create-options-element';

export function createPointCountElement(
  _index: number,
  apiService: ApiService,
  dropdownService: DropdownService,
): HTMLElement {
  const table = GET_TABLE();
  const item = table.items[_index];

  const groupValue: TGroupedPoints = getGroupValueById(item.id);

  const pointCount = groupValue?.points.length || 0;

  const countElemment = createElement('span', {
    attrs: {
      class: 'table__pointCount__count',
    },
    children: [pointCount],
  });

  return createElement('div', {
    attrs: {
      class: 'table__pointCount',
    },
    children: [countElemment, createOptionsElement(_index, apiService, dropdownService)],
  });
}
