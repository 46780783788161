import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EAdvancedFilterLogic } from './models/advanced-filter-logic.enum';
import { TAdvancedFilter, TAdvancedFilters } from './models/advanced-filter.model';

@Injectable({
  providedIn: 'root',
})
export class AdvancedFilterService {
  advancedFilters: TAdvancedFilters = {};
  private readonly _advancedFiltersChanged$ = new Subject<void>();
  readonly advancedFiltersChanged$ = this._advancedFiltersChanged$.asObservable();

  public getAdvancedFilters(): TAdvancedFilters {
    return this.advancedFilters;
  }

  public setAdvancedFilter(workspaceId: string, advancedFilter: TAdvancedFilter): void {
    this.advancedFilters[workspaceId] = advancedFilter;

    this._advancedFiltersChanged$.next();
  }

  public getAdvancedFilter(workspaceId: string): TAdvancedFilter {
    if (!this.advancedFilters[workspaceId]) {
      this.advancedFilters[workspaceId] = {
        enabled: false,
        logic: EAdvancedFilterLogic.AND,
        filters: [],
      };
    }

    return this.advancedFilters[workspaceId];
  }

  public clearAdvancedFilters(): void {
    this.advancedFilters = {};
  }

  public tryDeleteAdvancedFilterCustomField(customFieldId: string, workspaceId: string): void {
    const advancedFilter = this.getAdvancedFilter(workspaceId);

    if (!advancedFilter) {
      return;
    }

    advancedFilter.filters = advancedFilter.filters.filter((filter) => filter.id !== customFieldId);

    this.setAdvancedFilter(workspaceId, advancedFilter);
  }
}
