import { PlanComponent } from './plan.component';

export type TActivePlan = {
  active: boolean;
  drawingPolygon: boolean;
};

export const activePlan: TActivePlan = {
  active: false,
  drawingPolygon: false,
};

export let pointPlanComponent: PlanComponent = null;

// GETTERS

export const GET_ACTIVE_PLAN = (): TActivePlan => activePlan;

export const GET_PLAN_COMPONENT = (): PlanComponent => pointPlanComponent;

// SETTERS

export const SET_ACTIVE_PLAN = (active: boolean): void => {
  activePlan.active = active;
};

export const SET_DRAWING_POLYGON = (drawing: boolean): void => {
  activePlan.drawingPolygon = drawing;
};

export const SET_PLAN_COMPONENT = (plan: PlanComponent): void => {
  pointPlanComponent = plan;
};
