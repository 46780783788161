<pp-dashlet-activity-tags-list
  *ngIf="addedTags.length > 0"
  ppLabel="{{ 'added' | ppTranslate }}"
  [ppTags]="addedTags"
></pp-dashlet-activity-tags-list>

<pp-dashlet-activity-tags-list
  *ngIf="removedTags.length > 0"
  ppLabel="{{ 'removed' | ppTranslate }}"
  [ppTags]="removedTags"
></pp-dashlet-activity-tags-list>
