<ng-container *ngIf="user">
  <header
    #topBar
    id="nav-bar"
    class="topBar"
    [class.topBar__down]="ui.actionBar"
    *ngIf="workspaces"
  >
    <a
      class="logoBlock showLogoTrue"
      [routerLink]="['/site', user.activeWorkspaceId]"
      *ngIf="routerPath.startsWith('workspace.point') || routerPath === 'settings.user'"
    >
      <i class="fas fa-chevron-left menu-control" aria-hidden="true"></i>
    </a>

    <div
      class="topBar__site"
      [class.topBar__site--mobile]="showMobileSiteName"
      data-m-target="Site name field"
    >
      <h4
        class="topBar__account-name topBar__settings-title"
        *ngIf="routerPath === '/settings/user'"
      >
        {{ 'profile_settings' | ppTranslate }}
      </h4>

      <ng-container *ngIf="routerPath === '/dashboard'">
        <h4 class="topBar__account-name">
          {{ 'dashboard' | ppTranslate }}
        </h4>
        <div
          ppTooltip
          id="dashboardSettingsDropdownButton"
          class="topBar__account-options"
          data-m-target="Dashboard settings button"
          ppTitle="Dashboard Settings"
          (click)="toggleDashboardSettingsDropdown('dashboardSettingsDropdownButton')"
        >
          <img
            [src]="EIconPath.INTERACTION_OPTIONS_DASHBOARD"
            class="topBar__account-options-icon"
          />
        </div>
      </ng-container>

      <ng-container *ngIf="routerPath.startsWith('/fleet-management')">
        <pp-top-bar-fleet-management-header></pp-top-bar-fleet-management-header>
      </ng-container>
      <h4
        class="topBar__page-title topBar__accounts-title"
        *ngIf="routerPath.startsWith('/settings/account')"
      >
        {{ 'account_settings' | ppTranslate }}
      </h4>

      <h4
        class="topBar__page-title topBar__reminders-title"
        *ngIf="routerPath.startsWith('/reminders')"
      >
        {{ 'reminders' | ppTranslate }}
      </h4>

      <h4
        class="topBar__page-title topBar__settings-title"
        *ngIf="
          routerPath.startsWith('/settings/site') && !routerPath.startsWith('/settings/site/new')
        "
      >
        {{ 'site_settings' | ppTranslate }}
      </h4>

      <h4
        class="topBar__page-title topBar__settings-title"
        *ngIf="routerPath.startsWith('/settings/site/new')"
      >
        {{ 'new_site' | ppTranslate }}
      </h4>

      <h4 class="topBar__account-name" *ngIf="showAccountName">
        {{ workspaces[paramId]?.accountName }}
        <span class="topBar__account-name--divider"> / </span>
      </h4>

      <h4 class="topBar__site-name" *ngIf="showSiteName">
        {{ workspaces[paramId]?.siteName }}
      </h4>
      <span
        ppTooltip
        id="siteSettingsDropdownButton"
        data-m-target="Site settings button"
        class="topBar__settings"
        ppTitle="Site Settings"
        (click)="toggleSiteSettingsDropdown('siteSettingsDropdownButton', paramId)"
        *ngIf="
          user.isSuperUser ||
          workspaces[paramId]?.share.shareOption === EUserRole.OWNER ||
          workspaces[paramId]?.share.shareOption === EUserRole.SITE_ADMIN ||
          workspaces[paramId]?.share.shareOption === EUserRole.ACCOUNT_ADMIN
        "
      >
        <span class="topBar__cog-container" [class.topBar__cogContainer--active]="cogActive">
          <pp-icon
            [ppSrc]="EIconPath.ICON_COG_GREY"
            [ppClass]="cogActive ? 'top__setting-icon--active' : 'top__setting-icon'"
            class="topBar__page--svg"
            [ppDisplayContents]="true"
            (mousedown)="settingsClicked()"
            (mouseup)="settingsUnclicked()"
          ></pp-icon>
        </span>
      </span>
      <h4
        class="topBar__account-name topBar__site-name--overview"
        *ngIf="routerPath.startsWith('/site/overview')"
      >
        {{ 'overview' | ppTranslate }}
      </h4>

      <h4
        class="topBar__account-name topBar__site-name--overview"
        *ngIf="routerPath.startsWith('/site/timeline')"
      >
        {{ 'timeline' | ppTranslate }}
      </h4>
    </div>

    <pp-top-bar-fleet-options
      *ngIf="ppFleetLoaded && showFleetOptions"
      class="topBar__fleet-options"
    ></pp-top-bar-fleet-options>
  </header>
</ng-container>
