import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';

import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  ViewChild,
} from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';

import { TDashlet } from 'src/app/project/modules/preferences/preferences.model';
import { TWorkspacesById } from '../../../workspace/workspace.model';
import { ActivitiesDashletModalComponent } from '../activities-dashlet-modal/activities-dashlet-modal.component';

import { DeviceService } from '@core/services';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { SiteDataService, TSiteData } from 'src/app/project/modules/site/site-data.service';
import { PromptService } from '../../../../components/prompt/prompt.service';
import { UsersService } from '../../../users/users.service';
import { DashboardService } from '../../dashboard-service/dashboard.service';
import { DashletActivitiesService } from './dashlet-activities.service';

import { elementChildListUpdated$ } from '@core/helpers';
import { TAllUsers } from '@project/view-models';
import { generateErrorPrompt } from 'src/app/project/modules/errors/response-error';
import { EStore } from '../../../../shared/enums/store.enum';
import { ActivityFormatterService } from '../../../points/point-modal/point-timeline/activity-formatter.service';
import { TDashletActivity } from './dashlet-activity/dashlet-activity.model';

dayjs.extend(relativeTime);
dayjs.extend(isBetween);

@Component({
  selector: 'pp-dashlet-activities',
  templateUrl: './dashlet-activities.component.html',
  styleUrls: ['./dashlet-activities.component.scss'],
})
export class DashletActivitiesComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('dashletActivities', { static: false }) dashletActivitiesElement: ElementRef;
  @ViewChild('activityList', { static: false }) dashletActivityList: ElementRef;
  @Input() ppAccountId: string;
  @Input() ppWorkspaceIds: string[];
  @Input() ppLoading: boolean;
  @Input() ppDashlet: TDashlet;

  private readonly destroy$ = new Subject<void>();
  readonly dashletActivitiesRendered$ = new Subject<void>();

  workspaces$: Observable<TWorkspacesById>;
  workspaces: TWorkspacesById;

  destroyed = false;

  activities: TDashletActivity[];
  fetched: boolean;
  allActivitiesLoaded: boolean;
  allUsers: TAllUsers = {};
  site: TSiteData = this.siteDataService.getSite();
  isiPod = false;
  isMobile = false;
  isiPad = false;
  isTouchDevice = true;

  private page: {
    p: number;
    size: number;
  };

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
    }>,
    private ngZone: NgZone,
    private promptService: PromptService,
    private dashboardService: DashboardService,
    private dashletActivitiesService: DashletActivitiesService,
    private activityFormatterService: ActivityFormatterService,
    private deviceService: DeviceService,
    private usersService: UsersService,
    private siteDataService: SiteDataService,
    private modalService: ModalService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
  }

  ngOnInit() {
    this.activities = [];
    this.fetched = false;
    this.allActivitiesLoaded = false;

    this.page = {
      p: 0,
      size: 20,
    };

    this.isiPod = this.deviceService.isiPod();
    this.isMobile = this.deviceService.isMobile();
    this.isiPad = this.deviceService.isiPad();
    this.isTouchDevice = this.deviceService.isTouchDevice();

    this.fetchActivities();

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspaces = workspaces;
      this.allUsers = this.usersService.getUsers();
    });
  }

  ngOnChanges(changes: { [key: string]: SimpleChange }) {
    if (
      (changes.ppAccountId && !changes.ppAccountId.isFirstChange()) ||
      (changes.ppWorkspaceIds && !changes.ppWorkspaceIds.isFirstChange())
    ) {
      this.page.p = 0;
      this.page.size = 20;
      this.fetched = false;
      this.allActivitiesLoaded = false;

      this.fetchActivities(true);
    }
  }

  ngOnDestroy() {
    this.activities = [];
    this.fetched = false;
    this.allActivitiesLoaded = false;
    this.destroyed = true;

    this.destroy$.next();
  }

  onScroll(event: Event): void {
    event.preventDefault();

    const target = event.target;

    if (!(target instanceof HTMLElement)) {
      return;
    }

    if (target.offsetHeight + target.scrollTop >= target.scrollHeight) {
      if (!this.ppLoading) {
        this.fetchActivities();

        this.ngZone.runOutsideAngular(() => {
          setTimeout(() => {
            target.scrollTop = target.scrollHeight;
          });
        });
      }
    }
  }

  fetchActivities(refresh?: boolean): void {
    if (!this.allActivitiesLoaded && !this.destroyed && !this.ppLoading) {
      this.ppLoading = true;

      if (refresh) {
        this.activities = [];
      }

      this.dashletActivitiesService
        .fetchActivities({
          from: this.page.p * this.page.size,
          size: this.page.size,
          accountId: this.ppAccountId,
          workspaceIds: this.ppWorkspaceIds,
        })
        .pipe(
          tap((response) => {
            this.allActivitiesLoaded = response.length < 20;
            this.page.p += 1;
            this.fetched = true;

            // Merge new activities only
            const newActivities = this.activities.length
              ? [this.activities.pop(), ...response] // Give last previous fetched activity a chance to be merged with next fetched
              : response;

            const parsedActivities = this.dashletActivitiesService.formatActivities(
              newActivities,
              this.allUsers,
            );

            if (refresh) {
              this.activities.length = 0;
              this.activities = parsedActivities;
            } else {
              this.activities = this.activities.concat(parsedActivities);
            }

            this.dashletActivitiesService.setDashletActivities(this.activities);
            this.fetchMoreActivitiesIfNoScroll();
          }),
          catchError((error) => {
            const promptText = generateErrorPrompt(error, 'prompt_error');
            this.promptService.showError(promptText);

            return throwError(error);
          }),
          finalize(() => {
            this.ppLoading = false;
          }),
        )
        .subscribe();
    }
  }

  onDrag(event: Event): void {
    event.preventDefault();
  }

  dashletClicked(event: Event): void {
    if (this.isTouchDevice) {
      event.preventDefault();
      event.stopPropagation();

      this.modalService.setData({
        accountId: this.ppAccountId,
        workspaceIds: this.ppWorkspaceIds,
        loading: this.ppLoading,
        dashlet: this.ppDashlet,
      });

      this.modalService.showModal(ActivitiesDashletModalComponent, {
        onClose: () => {
          const dashlets = this.dashboardService.getDashlets();

          const activitiesDashlet = dashlets.find((dashlet) => dashlet.name === 'ACTIVITIES');

          this.ppWorkspaceIds = activitiesDashlet.selectedRange.workspaceIds;
          this.ppAccountId = activitiesDashlet.selectedRange.accountId;
          this.page.p = 0;
          this.page.size = 20;
          this.fetched = false;
          this.allActivitiesLoaded = false;

          this.fetchActivities(true);
        },
      });
    }
  }

  private fetchMoreActivitiesIfNoScroll(): void {
    const ready$ = this.dashletActivityList
      ? elementChildListUpdated$(this.dashletActivityList.nativeElement)
      : this.dashletActivitiesRendered$;

    ready$
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          if (
            this.dashletActivitiesElement.nativeElement.scrollHeight <=
            this.dashletActivitiesElement.nativeElement.offsetHeight
          ) {
            this.fetchActivities();
          }
        }),
      )
      .subscribe();
  }
}
