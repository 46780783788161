<div class="savedViews__tabs">
  <div
    class="savedViews__slider"
    [style.left]="ppSelectedOption === ESavedView.PERSONAL ? '0.4rem' : '50%'"
  ></div>
  <button
    [class.savedViews__active-tab]="ppSelectedOption === ESavedView.PERSONAL"
    (click)="selectOption(ESavedView.PERSONAL)"
    (mouseenter)="onTabHover(ESavedView.PERSONAL, true)"
    (mouseleave)="onTabHover(ESavedView.PERSONAL, false)"
  >
    <pp-icon
      [ppSrc]="EIconPath.ICON_SAVED_VIEWS_STAR"
      [ppColor]="personalTabColor"
      [ppDisplayContents]="true"
    ></pp-icon>
    {{ 'my_views' | ppTranslate }}
  </button>
  <button
    [class.savedViews__active-tab]="ppSelectedOption === ESavedView.SHARED"
    (click)="selectOption(ESavedView.SHARED)"
    [disabled]="!enableSharedViews"
    (mouseenter)="onTabHover(ESavedView.SHARED, true)"
    (mouseleave)="onTabHover(ESavedView.SHARED, false)"
  >
    <pp-button
      [ppTitle]="savedViewsButtonCopy"
      ppTooltip
      ppStyle="text"
      id="savedViews__header-button"
      [ppDisabled]="!enableSharedViews"
    >
      <pp-icon
        [ppSrc]="EIconPath.ICON_SAVED_VIEWS_GROUPS"
        [ppColor]="sharedTabColor"
        [ppDisplayContents]="true"
      ></pp-icon>
      {{ 'shared_views' | ppTranslate }}
    </pp-button>
  </button>
</div>
