import { TSelectOption } from 'src/app/project/components/input/select/select.model';
import { translate } from 'src/app/project/features/translate/translate';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';

export function getAdvancedFlagOptions(): TSelectOption[] {
  return [
    {
      value: EAdvancedFilterOptions.IS_FLAGGED,
      label: translate('is_flagged') + '.',
    },
    {
      value: EAdvancedFilterOptions.IS_NOT_FLAGGED,
      label: translate('is_unflagged') + '.',
    },
  ];
}
