import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { KEY_ESC, TAnyFunction, clickOutside, pressKey } from '@core/helpers';
import { WindowService } from '@core/services';
import { tap } from 'rxjs';
import {
  TCurrencyCode,
  TCurrencyData,
} from '../../modules/custom-fields/currencies/currencies.consts';
import { CurrenciesService } from '../../modules/custom-fields/currencies/currencies.service';
import { uuid } from '../../shared';
import { EIconPath } from '../../shared/enums/icons.enum';
import { DropdownService } from '../dropdown/dropdown-service/dropdown.service';
import { TDropdown } from '../dropdown/dropdown.consts';
import { getWidthNumber } from '../input/select/utils/get-width-number';
import { CurrencyPickerDropdownComponent } from './currency-picker-dropdown/currency-picker-dropdown.component';
import { TCurrencyPickerDropdownData } from './currency-picker-dropdown/currency-picker-dropdown.consts';

@Component({
  selector: 'pp-currency-picker',
  templateUrl: './currency-picker.component.html',
  styleUrls: ['./currency-picker.component.scss'],
})
export class CurrencyPickerComponent implements OnInit, OnChanges {
  @Input() ppSelectedItem: TCurrencyCode = null;
  @Output() ppSelect = new EventEmitter<TCurrencyData>();
  @ViewChild('selectComponent', { static: true }) element: ElementRef;

  selectedIndex = -1;
  buttonId = uuid();
  EIconPath = EIconPath;
  currencies: TCurrencyData[] = [];
  dropdown: TDropdown;
  private closeOnEscRef: TAnyFunction;
  private clickOutsideRef: TAnyFunction;
  private window = this.windowService.getGlobalObject();

  constructor(
    private currenciesService: CurrenciesService,
    private dropdownService: DropdownService,
    private windowService: WindowService,
  ) {
    this.dropdown = this.dropdownService.getDropdown();
  }

  ngOnInit(): void {
    this.fetchCurrencies();
  }

  ngOnChanges(): void {
    this.checkSelectedItem();
  }

  ngAfterViewInit(): void {
    this.closeOnEscRef = (event: KeyboardEvent): void =>
      pressKey(event, KEY_ESC, () => {
        this.onDropdownHide();
      });

    this.clickOutsideRef = (event: MouseEvent): void =>
      clickOutside(event, this.element.nativeElement, () => {
        event.stopImmediatePropagation();

        this.onDropdownHide();
      });
  }

  toggleDropdown(): void {
    if (this.dropdown.visible && this.dropdown.buttonId === this.buttonId) {
      this.dropdownService.hideDropdown();
    } else {
      this.setDropdownData();

      this.dropdownService.showDropdown(this.buttonId, CurrencyPickerDropdownComponent, {
        callback: (option) => this.selectItem(option),
        onClose: () => this.onDropdownHide(),
        popper: {
          positionFixed: true,
          placement: 'bottom-start',
        },
        suppressScrollbar: false,
        addScrollCallback: false,
      });
    }
  }

  private onDropdownHide(): void {
    this.dropdown.visible = false;

    this.window.removeEventListener('keydown', this.closeOnEscRef, true);
    this.window.removeEventListener('click', this.clickOutsideRef, true);
  }

  private selectItem(item: TCurrencyData): void {
    const currency = this.currencies.find((c) => c.currencyCode === item.currencyCode);

    this.ppSelect.emit(currency);
  }

  private setDropdownData(): void {
    const selectedItem = this.currencies[this.selectedIndex];

    const dropdownData: TCurrencyPickerDropdownData = {
      items: this.currencies,
      selectedItem: selectedItem,
      width: getWidthNumber(null, this.element.nativeElement.offsetWidth),
    };

    this.dropdownService.setData(dropdownData);
  }

  private fetchCurrencies(): void {
    this.currenciesService
      .fetchCurrenciesList()
      .pipe(
        tap((currencies) => {
          this.currencies = currencies;

          this.checkSelectedItem();
        }),
      )
      .subscribe();
  }

  private checkSelectedItem(): void {
    if (this.ppSelectedItem) {
      this.selectedIndex = this.currencies.findIndex(
        (item) => item.currencyCode === this.ppSelectedItem,
      );
    } else {
      this.selectedIndex = -1;
    }
  }
}
