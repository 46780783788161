export function generateExcelHeaders(count: number): string[] {
  const columns = [];
  const length = 26; // Number of letters in the English alphabet
  const ACharCode = 'A'.charCodeAt(0);

  for (let i = 0; i < count; i++) {
    let column = '';
    let temp = i;

    while (temp >= 0) {
      column = String.fromCharCode((temp % length) + ACharCode) + column;
      temp = Math.floor(temp / length) - 1;
    }

    columns.push(column);
  }

  return columns;
}
