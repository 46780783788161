<section class="createAssetContainer">
  <pp-icon
    [ppSrc]="EIconPath.ICON_LEFT_MENU_BOAT_BIG"
    [ppDisplayContents]="true"
    ppColor="grey-400"
  ></pp-icon>

  <p class="createAssetText">{{ 'no_assets_added_yet' | ppTranslate }}</p>

  <pp-button data-test="addAssetButton" ppStyle="primary" (ppAction)="addAsset()" *ngIf="canEdit">{{
    'add_asset' | ppTranslate
  }}</pp-button>
</section>
