<div
  class="pointDocument__canvas"
  [ngClass]="documentVisible ? 'pointDocument__canvas--visible' : ''"
>
  <div class="pointDocument__top-bar">
    <div class="pointDocument__actions">
      <button
        ppTooltip
        class="pointDocument__actions-item"
        ppTitle="Download"
        (click)="downloadFile()"
      >
        <img class="pointDocument__actions-item-icon" [src]="EIconPath.GALLERY_DOWNLOAD" />
      </button>

      <button
        ppTooltip
        class="pointDocument__actions-item"
        ppTitle="Delete"
        (click)="deleteFile()"
        *ngIf="canDelete"
      >
        <img class="pointDocument__actions-item-icon" [src]="EIconPath.GALLERY_DELETE" />
      </button>

      <button
        ppTooltip
        class="pointDocument__actions-item imageClose__btn"
        ppTitle="Close (Esc)"
        (click)="closeDocument(); $event.stopPropagation()"
      >
        <img class="pointDocument__actions-item-icon" [src]="EIconPath.GALLERY_CLOSE" />
      </button>
    </div>
  </div>

  <div class="pointDocument__container">
    <div
      class="pointDocument__canvas--pdf"
      [ngClass]="fileType === 'pdf' ? 'pointDocument__canvas--visible' : ''"
    >
      <div id="documents_canvas" class="pointDocument__document"></div>
    </div>

    <ng-container *ngIf="fileType === 'text'">
      <p class="document__text">
        {{ textMessage }}
      </p>
    </ng-container>
  </div>

  <div *ngIf="!loaded" class="pointDocument__spinner">
    <app-spinner [ppHidden]="false"></app-spinner>
  </div>
</div>
