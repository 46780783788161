import { GET_CUSTOM_FIELDS } from '../../../custom-fields/custom-fields.store';
import { TCustomFieldAccessOption } from '../../share.model';

export function getWorkspaceCustomFieldsPermissions({
  workspaceCustomFields,
  permissionRead,
  permissionEdit,
}: {
  workspaceCustomFields: string[];
  permissionRead: boolean;
  permissionEdit: boolean;
}): TCustomFieldAccessOption[] {
  return workspaceCustomFields.map((customFieldId) => {
    const customField = GET_CUSTOM_FIELDS()[customFieldId];

    return {
      templateId: customFieldId,
      permission: {
        read: permissionRead,
        edit: permissionEdit && !customField.lockedValue,
      },
    };
  });
}
