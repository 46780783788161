<section class="assetCard__headerContainer">
  <div class="assetCard__header">
    <div class="assetCard__nameWrapper">
      <div
        class="asset__dragIcon"
        *ngIf="permission === EFleetShareOption.EDIT"
        data-m-target="Asset Reorder Button"
        draggable="true"
        (dragstart)="onDragStart($event)"
        (dragend)="onDragEnd()"
      >
        <pp-icon
          [ppSrc]="EIconPath.ICON_DRAG_24"
          [ppDisplayContents]="true"
          ppColor="grey-400"
          ppClass="setSize24"
        ></pp-icon>
      </div>

      <div
        class="asset_expandIcon"
        data-m-target="Asset Expand Button"
        (click)="toggleExpanded()"
        [class.asset_expandIcon--disabled]="!ppAsset?.sites.length"
        ppTooltip
        [ppTitle]="!ppAsset?.sites.length ? ('asset_dashboard_no_site' | ppTranslate) : ''"
      >
        <pp-icon
          [ppSrc]="expanded ? EIconPath.ICON_NAV_ARROW_DOWN_24 : EIconPath.ICON_NAV_ARROW_RIGHT_24"
          ppColor="grey-900"
          ppClass="setSize24"
        ></pp-icon>
      </div>

      <div
        class="assetCard__name"
        [class.assetCard__name--clickable]="ppAsset?.sites.length > 0"
        [ppTrimTooltip]="true"
        ppTooltip
        [ppTitle]="ppAsset?.name"
        (click)="openOverviewFromAsset()"
        [innerHtml]="ppAsset?.name | highlightKeyword: keyword"
      ></div>
    </div>

    <button
      [id]="optionsButtonId"
      (click)="toggleDropdown()"
      ppTooltip
      [ppTitle]="'more_options' | ppTranslate"
      data-test="threeDotsButton"
    >
      <pp-icon
        class="assetCard__assetMenu"
        ppClass="setSize18"
        ppColor="grey-500"
        [ppSrc]="EIconPath.ICON_NAV_THREE_DOTS_VERTICAL_18"
      ></pp-icon>
    </button>
  </div>

  <div class="assetCard__imageSection">
    <div *ngIf="!imageUrl" class="assetCard__imageContainer">
      <pp-icon ppColor="grey-400" [ppSrc]="EIconPath.ICON_LEFT_MENU_BOAT_BIG"></pp-icon>
    </div>
    <div *ngIf="imageUrl" class="assetCard__imageContainer">
      <img [draggable]="false" class="assetCard__image" ppImage [ppSecureSrc]="imageUrl" />
    </div>
  </div>
</section>
