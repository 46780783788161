import { TDropdownName } from '../../modules/site/site-options/actionDropdown/AbstractActionDropdown';
import AbstractDropdown from './AbstractDropdown';

const dropdowns: { string?: AbstractDropdown } = {};

// GETTERS

export const GET_DROPDOWNS = (): { string?: AbstractDropdown } => dropdowns;

export const GET_DROPDOWN = <T = AbstractDropdown>(_name: TDropdownName): T => dropdowns[_name];

// SETTERS

export const SET_DROPDOWN = (_name: string, _dropdown: AbstractDropdown): void => {
  dropdowns[_name] = _dropdown;
};

// CLEAR

export const REMOVE_DROPDOWN = (_name: string): void => {
  if (Object.prototype.hasOwnProperty.call(dropdowns, _name)) {
    delete dropdowns[_name];
  }
};
