import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from '../../shared/enums/icons.enum';

@Component({
  selector: 'pp-custom-field-tile',
  templateUrl: './custom-field-tile.component.html',
  styleUrls: ['./custom-field-tile.component.scss'],
})
export class CustomFieldTileComponent {
  @Input() ppImage: EIconPath;
  @Input() ppGridTitle = '';
  @Input() ppText = '';
  @Input() ppDisabled = false;
  @Input() ppTitle = ''; // Workaround so it doesn't throw errors for tippy
  @Input() ppShowExamples: boolean;

  @Output() ppOnSelect = new EventEmitter<void>();

  EIconPath = EIconPath;

  constructor() {}

  optionSelect(): void {
    if (!this.ppDisabled) {
      this.ppOnSelect.emit();
    }
  }
}
