<ul
  class="customFieldList__ul"
  [class.customFieldList__ul--scroll]="ppMaxDepthExceeded"
  *ngIf="ppItem"
>
  <img
    [src]="EIconPath.ARROW_DOWN_GRAY"
    class="customField__expand-icon"
    [class.customField__expand-icon--collapsed]="collapsed"
    [class.customField__expand-icon--scroll]="ppMaxDepthExceeded"
    (click)="toggleList()"
    *ngIf="ppItem.subList?.length > 1"
  />

  <pp-icon
    ppTooltip
    [ppTitle]="'sort_list_alphabetically' | ppTranslate"
    [ppSrc]="sorted ? EIconPath.INTERACTION_SORT_ASCENDING : EIconPath.INTERACTION_SORT_DESCENDING"
    class="customField__sort-icon"
    [class.customField__sort-icon--sorted]="sorted || reverseSorted"
    [class.customField__sort-icon--scroll]="ppMaxDepthExceeded"
    [ppColor]="sorted || reverseSorted ? 'primary-600' : 'grey-400'"
    ppHoverColor="primary-600"
    (click)="alphabetizeListEntries()"
  />

  <div #inputLine class="listCF__line-wrapper">
    <div class="listCF__line"></div>
  </div>

  <ng-container *ngIf="!collapsed">
    <li
      *ngFor="let item of ppItem.subList; let index = index"
      id="{{ 'listItemRow-' + ppItem.label + index }}"
      class="settingsTable__item-grab"
      (dragover)="onDragOver($event, index)"
      (dragenter)="$event.preventDefault()"
      (dragleave)="$event.preventDefault()"
      (drop)="onDrop($event)"
      (mouseenter)="mouseEnter(index)"
      (mouseleave)="mouseLeave(index)"
    >
      <div
        *ngIf="ppItem.subList.length > 1"
        draggable="true"
        class="settingsTable__drag-icon"
        [class.settingsTable__drag-icon--visible]="hovered === index"
        (dragend)="onDragEnd()"
        (dragstart)="onDragStart($event, index)"
      ></div>

      <textarea
        rows="1"
        ppTooltip
        [ppTitle]="item.label"
        [ppTrimTooltip]="true"
        autocomplete="off"
        class="customFieldList__input input input-border-bottom custom-list-input"
        [class.customList__input--error]="item.fieldError"
        type="text"
        name="label"
        [(ngModel)]="item.label"
        [placeholder]="'list_item' | ppTranslate"
        (keydown)="onKeyDown($event)"
        (keyup)="onKeyUp($event, item)"
        (change)="checkItem(item)"
        (input)="onInput($event)"
        (ppOnRendered)="focusElement($event)"
        #input
      ></textarea>

      <div
        class="listCF__buttons-container"
        [class.listCF__buttons-container--scroll]="ppMaxDepthExceeded"
      >
        <button
          class="listCF__button"
          aria-hidden="true"
          (click)="addSublist(item, index)"
          *ngIf="item.label"
        >
          <pp-icon
            [ppSrc]="EIconPath.ICON_NAV_PLUS_14"
            [ppDisplayContents]="true"
            ppColor="primary-600"
            ppHoverColor="primary-700"
          ></pp-icon>
          <span class="hide--mobile">{{ 'add_subitem' | ppTranslate }}</span>
        </button>

        <button
          class="listCF__button"
          aria-hidden="true"
          (click)="removeSublist(item)"
          *ngIf="!ppPrimaryField || ppItem.subList.length > 1"
        >
          <img class="listCF__delete" [src]="EIconPath.INTERACTION_PIN_DELETE" />
        </button>
      </div>

      <div *ngIf="item.subList?.length > 0">
        <pp-custom-field-list
          [ppItem]="item"
          #customFieldListComponent
          [ppMaxDepthExceeded]="ppMaxDepthExceeded"
          (ppChange)="onChange()"
          [ppEmptyLabelError]="ppEmptyLabelError"
        ></pp-custom-field-list>
      </div>

      <div *ngIf="!(item.subList?.length > 0)">
        <pp-custom-field-list
          #customFieldListComponent
          [ppMaxDepthExceeded]="ppMaxDepthExceeded"
          (ppChange)="onChange()"
          [ppEmptyLabelError]="ppEmptyLabelError"
        ></pp-custom-field-list>
      </div>
    </li>
  </ng-container>

  <ng-container *ngIf="collapsed">
    <li
      id="{{ 'listItemRow-' + ppItem.label + 0 }}"
      class="settingsTable__item-grab"
      (dragover)="onDragOver($event, 0)"
      (dragenter)="$event.preventDefault()"
      (dragleave)="$event.preventDefault()"
      (drop)="onDrop($event)"
      (mouseenter)="mouseEnter(0)"
      (mouseleave)="mouseLeave(0)"
    >
      <div
        *ngIf="ppItem.subList?.length > 1"
        draggable="true"
        class="settingsTable__drag-icon"
        [class.settingsTable__drag-icon--visible]="hovered === 0"
        (dragend)="onDragEnd()"
        (dragstart)="onDragStart($event, 0)"
      ></div>

      <textarea
        ppTooltip
        [ppTitle]="ppItem.subList[0].label"
        [ppTrimTooltip]="true"
        autocomplete="off"
        class="customFieldList__input input input-border-bottom custom-list-input"
        [class.customList__input--error]="ppItem.subList[0].fieldError"
        type="text"
        name="label"
        [(ngModel)]="ppItem.subList[0].label"
        [placeholder]="'list_item' | ppTranslate"
        (keyup)="onKeyUp($event, ppItem.subList[0])"
        (change)="checkItem(ppItem.subList[0])"
        (input)="onInput($event)"
        *ngIf="ppItem.subList?.length > 0"
        #input
      ></textarea>
    </li>
  </ng-container>
</ul>

<button
  class="listCF__button listCF__button--mt"
  aria-hidden="true"
  (click)="addSublist(ppItem)"
  *ngIf="ppShowAddItem"
>
  <pp-icon
    class="listCF__plus-icon"
    [ppSrc]="EIconPath.ICON_NAV_PLUS_14"
    [ppDisplayContents]="true"
    ppColor="primary-600"
  ></pp-icon>

  <span>{{ 'add_item' | ppTranslate }}</span>
</button>
