<section class="actionBar" *ngIf="ui.actionBar">
  <p class="actionBar__page-title">
    {{ 'update_action_bar_1' | ppTranslate }}

    <button class="actionBar__link" (click)="updateApp()">
      {{ 'update_action_bar_2' | ppTranslate }}
    </button>

    {{ 'update_action_bar_3' | ppTranslate }}
  </p>
</section>
