import { Injectable } from '@angular/core';
import { Coordinate } from 'ol/coordinate';
import Polygon from 'ol/geom/Polygon';
import { PlanPointVariablesService } from './plan-point-variables.service';

@Injectable({
  providedIn: 'root',
})
export class PlanPolygonService {
  constructor(private planPointVariablesService: PlanPointVariablesService) {}

  getPolygonIndex(coordinates: Coordinate[]): number {
    const plan = this.planPointVariablesService.getPlan();
    const index = plan.point.polygons.findIndex((searchedFeature) => {
      const searchedCoordinates = (searchedFeature.getGeometry() as Polygon).getCoordinates()[0];

      if (Math.abs(coordinates.length - searchedCoordinates.length) > 1 || coordinates.length < 3) {
        return false;
      }

      if (coordinates.length === searchedCoordinates.length) {
        const { numberOfMatches, numberOfChanges } = this.getEqualGeometryMatches(
          coordinates,
          searchedCoordinates,
        );

        if (numberOfChanges === 1 && numberOfMatches === coordinates.length - 2) {
          return true;
        }
      }

      if (coordinates.length > 3) {
        const { numberOfMatches, numberOfChanges } = this.getMultiGeometryMatches(
          coordinates,
          searchedCoordinates,
        );

        if (numberOfMatches >= coordinates.length - 1 && numberOfChanges) {
          return true;
        }

        return false;
      }

      return false;
    });

    return index;
  }

  getEqualGeometryMatches(
    coordinates: Coordinate[],
    searchedCoordinates: Coordinate[],
  ): { numberOfMatches: number; numberOfChanges: number } {
    let numberOfMatches = 0;
    let numberOfChanges = 0;

    for (let i = 0; i < coordinates.length - 1; i++) {
      // first and last coordinate are the same
      if (
        coordinates[i][0] === searchedCoordinates[i][0] &&
        coordinates[i][1] === searchedCoordinates[i][1]
      ) {
        numberOfMatches++;
      } else {
        numberOfChanges++;
      }
    }

    return { numberOfMatches, numberOfChanges };
  }

  getMultiGeometryMatches(
    coordinates: Coordinate[],
    searchedCoordinates: Coordinate[],
  ): { numberOfMatches: number; numberOfChanges: number } {
    let numberOfMatches = 0;
    let numberOfChanges = 0;

    coordinates.forEach((coordinate) => {
      const match = searchedCoordinates.find(
        (_coordinate) => _coordinate[0] === coordinate[0] && _coordinate[1] === coordinate[1],
      );

      if (match) {
        numberOfMatches++;
      } else {
        numberOfChanges++;
      }
    });

    numberOfMatches += Math.abs(coordinates.length - searchedCoordinates.length);

    return { numberOfMatches, numberOfChanges };
  }
}
