export enum ENotificationType {
  POINT_CREATION = 'POINT_CREATION',
  POINT_CREATION_ASSIGNEE = 'POINT_CREATION_ASSIGNEE',
  POINT_EDITION = 'POINT_EDITION',
  POINT_EDITION_TITLE = 'POINT_EDITION_TITLE',
  POINT_EDITION_STATUS = 'POINT_EDITION_STATUS',
  POINT_EDITION_PRIORITY = 'POINT_EDITION_PRIORITY',
  POINT_EDITION_DESCRIPTION = 'POINT_EDITION_DESCRIPTION',
  POINT_EDITION_COMMENT = 'POINT_EDITION_COMMENT',
  POINT_EDITION_PIN_MOVE = 'POINT_EDITION_PIN_MOVE',
  POINT_EDITION_ADDITIONAL_PINS_MOVE = 'POINT_EDITION_ADDITIONAL_PINS_MOVE',
  POINT_EDITION_POLYGONS_MOVE = 'POINT_EDITION_POLYGONS_MOVE',
  POINT_EDITION_ATTACHMENT = 'POINT_EDITION_ATTACHMENT',
  POINT_EDITION_VIDEOS = 'POINT_EDITION_VIDEOS',
  POINT_EDITION_IMAGES = 'POINT_EDITION_IMAGES',
  POINT_EDITION_DOCUMENTS = 'POINT_EDITION_DOCUMENTS',
  POINT_EDITION_IMAGES360 = 'POINT_EDITION_IMAGES360',
  POINT_EDITION_TAGS = 'POINT_EDITION_TAGS',
  POINT_EDITION_CUSTOM_FIELDS = 'POINT_EDITION_CUSTOM_FIELDS',
  POINT_EDITION_ASSIGNEE = 'POINT_EDITION_ASSIGNEE',
  POINT_MENTION_NEW_POINT = 'POINT_MENTION_NEW_POINT',
  POINT_MENTION_DESCRIPTION = 'POINT_MENTION_DESCRIPTION',
  POINT_MENTION_CUSTOM_FIELD = 'POINT_MENTION_CUSTOM_FIELD',
  POINT_MENTION_COMMENT = 'POINT_MENTION_COMMENT',
  POINT_REMOVAL = 'POINT_REMOVAL',
  REACTION_COMMENT = 'REACTION_COMMENT',
  UNSUPPORTED = 'UNSUPPORTED',
  POINT_MENTION = 'POINT_MENTION', // DEPRECATED
  POINT_EDITION_FLAGGED = 'POINT_EDITION_FLAGGED',
}
