import { TTimeframeElement } from '../timeframes/timeline-timeframes';
import { createTimelineHeadCellElement } from './create-timeline-head-cell-element';

export function createTimelineHeadCellTypeElementTop(_column: TTimeframeElement): HTMLElement {
  return createTopNameElement(_column, true);
}

function createTopNameElement(_column: TTimeframeElement, _center: boolean = false): HTMLElement {
  return createTimelineHeadCellElement({
    name: _column.name,
    secondaryName: _column.secondaryName,
    center: _center,
    width: `${_column.width}px`,
    child: _column.name,
    column: _column.name,
    resize: false,
    bottom: false,
  });
}
