import { createElement } from 'src/app/core/helpers/dom';

export function createTimelineEmptyElement(_pointIndex: number): HTMLElement {
  return createElement('div', {
    attrs: {
      class: '',
      style: {
        width: '0',
        position: 'absolute',
      },
      'data-index': _pointIndex,
    },
    children: [''],
  });
}
