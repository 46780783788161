import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { TGuid } from '@core/helpers';
import {
  API_reactions_comments,
  API_reactions_like,
  API_reactions_like_remove,
  API_reactions_point,
} from '@core/api/paths';
import { Observable } from 'rxjs';
import { TReaction } from 'src/app/project/modules/reactions/reactions.model';

@Injectable({
  providedIn: 'root',
})
export class ReactionsApiProviderService {
  constructor(private apiService: ApiService) {}

  getPointReactions(_id: TGuid): Observable<TReaction[]> {
    const url = API_reactions_point(_id);

    return this.apiService.get<TReaction[]>(url);
  }

  like(commentId: TGuid): Observable<TReaction> {
    const url = API_reactions_like(commentId);

    return this.apiService.post<TReaction>(url, {});
  }

  removeLike(commentId: TGuid): Observable<TReaction> {
    const url = API_reactions_like_remove(commentId);

    return this.apiService.post<TReaction>(url, {});
  }

  getCommentsReactions(commentIds: TGuid[]): Observable<TReaction[]> {
    const url = API_reactions_comments();

    return this.apiService.post<TReaction[]>(url, commentIds);
  }
}
