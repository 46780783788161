import { createElement } from 'src/app/core/helpers/dom';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { TColumn } from '../../columns/column.model';
import { createMarkedKeywordElement } from '../body-cells-search';
import { createCellElement } from '../createCellElement';

export function createDefaultElement(
  _index: number,
  _column: TColumn,
  _customField: TPointCustomField<string>,
): HTMLElement {
  let value = _customField?.value ? _customField.value : '';

  if (
    _column.customFieldIds &&
    !_column.customFieldIds.includes(_customField?.customFieldTemplateId.toString())
  ) {
    value = '';
  }

  const formattedValue = value ? _customField.value : '';

  return createCellElement({
    index: _column.index,
    width: `${_column.width}px`,
    child: createElement('div', {
      attrs: {
        class: 'customSiteTable__text',
      },
      children: [createMarkedKeywordElement(formattedValue ? formattedValue : '')],
    }),
  });
}
