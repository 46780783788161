import { TFilters } from '../site-filter.model';

export function compareFilterPriority(firstFilter: TFilters, secondFilter: TFilters): boolean {
  const priority1 = firstFilter.priority;
  const priority2 = secondFilter.priority;

  for (let index = 0; index < priority1.length; index++) {
    if (priority1[index].value !== priority2[index].value) {
      return false;
    }
  }

  return true;
}
