import Draw from 'ol/interaction/Draw';

import { Inject, Injectable } from '@angular/core';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { PlanPinsService } from '../plan-pins.service';
import { SET_DRAWING_POLYGON } from '../plan.store';
import { FeatureDragService } from './feature-drag.service';
import { FeatureTranslateService } from './feature-translate.service';
import { PlanPointCorrectFeaturesService } from './plan-point-correct-features.service';
import { EPlanPointEventType, PlanPointEventsService } from './plan-point-events.service';
import { PlanPointStylesService } from './plan-point-styles/plan-point-styles.service';
import { PlanPointVariablesService } from './plan-point-variables.service';
import { MAX_AREAS, MAX_PINS } from '../../../shared/constants/plan-point.const';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { PointsService } from 'src/app/project/modules/points/points.service';
import { FeatureModifyService } from './feature-modify.service';
import { FeatureSnapService } from './feature-snap.service';
import { PolygonChangeService } from './polygon-change.service';
import { EPlanPointMode } from '../../../shared/enums/plan-point-mode.enum';
import { TPlanData } from '../plan-data.service';
import Style from 'ol/style/Style';
import { Feature, MapBrowserEvent } from 'ol';
import { TPlanFeature } from '../plan-feature.model';
import { Polygon } from 'ol/geom';
import { DOCUMENT } from '@angular/common';
import { TPin } from '@project/view-models';
import { Select } from 'ol/interaction';

@Injectable({
  providedIn: 'root',
})
export class PlanPointClickService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private planPinsService: PlanPinsService,
    private planPointCorrectFeaturesService: PlanPointCorrectFeaturesService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private planPointEventsService: PlanPointEventsService,
    private planPointVariablesService: PlanPointVariablesService,
    private featureTranslateService: FeatureTranslateService,
    private featureDragService: FeatureDragService,
    private planPointStylesService: PlanPointStylesService,
    private activeService: ActiveService,
    private pointsService: PointsService,
    private featureModifyService: FeatureModifyService,
    private featureSnapService: FeatureSnapService,
    private polygonChangeService: PolygonChangeService,
  ) {}

  clickListener(event: MapBrowserEvent<UIEvent>): void {
    let verticeClicked = false;
    const featureClicked = this.planPointVariablesService.getFeatureClicked();
    const plan = this.planPointVariablesService.getPlan();
    const mode = this.planPointVariablesService.getMode();

    if (mode === EPlanPointMode.POINT && !this.planPinsService.getBlockAddingPins()) {
      const hit = plan.instance.point.forEachFeatureAtPixel(event.pixel, (feature) => {
        if ((feature as TPlanFeature).visible) {
          this.planPointVariablesService.setFeatureClicked(true);

          return true;
        }
        return false;
      });

      if (!hit) {
        if (!featureClicked.clicked) {
          if (mode === EPlanPointMode.POINT) {
            if (plan.point.pins.length < MAX_PINS) {
              const coordinate = this.planPointCorrectFeaturesService.correctPinPosition(
                event.coordinate as unknown as TPin,
                plan,
              );
              const newFeature = this.planPinsService.addExtraMarker(plan.point, coordinate);

              this.featureTranslateService.createInteraction(newFeature);
              this.featureDragService.featureDragOff();
              this.featureDragService.featureDragOn();
            } else {
              const prompt = this.translationPipe.transform('prompt_max_pins');

              this.promptService.showWarning(prompt);
            }
          }
        }

        this.clickEventOff();
        this.clickEventOn();
        this.planPointVariablesService.setFeatureClicked(false);
      }
    } else if (!this.planPinsService.getBlockAddingPins()) {
      if (plan.point.polygons) {
        plan.point.polygons.forEach((polygon, index) => {
          const vertices = polygon.getGeometry().getCoordinates()[0];
          const pixel = plan.instance.point.getPixelFromCoordinate(event.coordinate);
          const errorMargin = 20;

          vertices.forEach((vertice) => {
            const verticePixel = plan.instance.point.getPixelFromCoordinate(vertice);

            if (
              Math.abs(pixel[0] - verticePixel[0]) < errorMargin &&
              Math.abs(pixel[1] - verticePixel[1]) < errorMargin
            ) {
              this.planPointEventsService.emit(EPlanPointEventType.POLYGON_VERTICE_CLICKED, {
                coordinates: event.coordinate,
                index: index,
              });

              verticeClicked = true;
              this.planPointVariablesService.setClickedPolygon(polygon);
            }
          });
        });
      }

      if (!verticeClicked) {
        const hit = plan.instance.point.forEachFeatureAtPixel(event.pixel, (feature) => {
          const planFeature = feature as TPlanFeature;

          if (planFeature) {
            if (!(feature.getGeometry() as Polygon).intersectsCoordinate(event.coordinate)) {
              this.planPointEventsService.emit(EPlanPointEventType.POLYGON_VERTICE_CLICKED, {
                coordinates: event.coordinate,
              });

              this.planPointVariablesService.setClickedPolygon(planFeature);
            } else {
              this.planPointEventsService.emit(EPlanPointEventType.POLYGON_CLICKED, planFeature);
              this.planPointVariablesService.setClickedPolygon(planFeature);
            }

            return true;
          }
        });

        if (!hit && plan.point.polygons.length >= MAX_AREAS) {
          const prompt = this.translationPipe.transform('prompt_max_polygons');

          this.promptService.showWarning(prompt);
          this.planPointEventsService.emit(EPlanPointEventType.POLYGON_CLICKED, null);
          this.planPointEventsService.emit(EPlanPointEventType.POLYGON_VERTICE_CLICKED, null);
        } else {
          this.planPointEventsService.emit(EPlanPointEventType.POLYGON_VERTICE_CLICKED, {
            coordinates: event.coordinate,
          });
        }
      }
    } else {
      this.planPointEventsService.emit(EPlanPointEventType.POLYGON_CLICKED, null);
      this.planPointEventsService.emit(EPlanPointEventType.POLYGON_VERTICE_CLICKED, null);
    }
  }

  clickEventOff(): void {
    const plan = this.planPointVariablesService.getPlan();
    const clickListenerRef = this.planPointVariablesService.getClickListenerRef();

    plan.instance.point.un('singleclick', clickListenerRef);

    this.planPointVariablesService.setFeatureClicked(false);
  }

  clickEventOn(): void {
    const plan = this.planPointVariablesService.getPlan();
    const clickListenerRef = this.planPointVariablesService.getClickListenerRef();
    const drawPolygon = this.planPointVariablesService.getDrawPolygon();
    const mode = this.planPointVariablesService.getMode();
    const selectInteraction = this.planPointVariablesService.getSelectInteraction();

    plan.instance.point.on('singleclick', clickListenerRef);

    SET_DRAWING_POLYGON(false);

    if (mode === EPlanPointMode.POLYGON) {
      plan.instance.point.removeInteraction(drawPolygon);
      this.featureDragService.featureDragOff();
    } else {
      this.featureDragService.featureDragOn();
    }

    if (mode === EPlanPointMode.DRAW) {
      this.handleDrawModeClick(drawPolygon, plan, selectInteraction);
    } else if (mode === EPlanPointMode.POLYGON) {
      this.handlePolygonModeClick(plan, drawPolygon);
    } else {
      plan.instance.point.removeInteraction(drawPolygon);
    }
  }

  private handlePolygonModeClick(plan: TPlanData, drawPolygon: Draw): void {
    plan.instance.point.removeInteraction(drawPolygon);

    this.featureSnapService.setSnapInteraction();

    this.featureDragService.featureDragOff();

    if (this.planPointVariablesService.getClickedPolygon()) {
      this.polygonChangeService.polygonChangeInteraction(
        this.planPointVariablesService.getClickedPolygon(),
      );
    } else {
      this.polygonChangeService.polygonChangeInteraction(
        plan.point.polygons[plan.point.polygons.length - 1],
      );
    }

    this.featureSnapService.addSnapInteraction(plan);
  }

  private handleDrawModeClick(drawPolygon: Draw, plan: TPlanData, selectInteraction: Select): Draw {
    const source = this.planPinsService.getVectorSource();
    const _id = this.activeService.getActivePointId();
    const point = this.pointsService.findPoint(_id);
    const polygonDrawStartListenerRef =
      this.planPointVariablesService.getPolygonDrawStartListenerRef();
    const polygonDrawEndListenerRef = this.planPointVariablesService.getPolygonDrawEndListenerRef();

    if (drawPolygon) {
      plan.instance.point.removeInteraction(drawPolygon);
    }

    this.planPointVariablesService.setDrawPolygon(
      new Draw({
        source: source,
        type: 'Polygon',
        style: (feature: Feature): Style[] =>
          this.planPointStylesService.createDrawingStyle(point.priority, feature),
      }),
    );

    if (!drawPolygon) {
      this.document.addEventListener('keydown', this.planPointVariablesService.getEscListenerRef());
    }

    drawPolygon = this.planPointVariablesService.getDrawPolygon();
    drawPolygon.on('drawstart', polygonDrawStartListenerRef);
    drawPolygon.on('drawend', polygonDrawEndListenerRef);

    this.featureModifyService.removeModifyInteraction();

    if (selectInteraction) {
      plan.instance.point.removeInteraction(selectInteraction);
    }

    this.featureDragService.featureDragOff();

    plan.instance.point.addInteraction(drawPolygon);
    return drawPolygon;
  }
}
