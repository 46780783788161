<pp-modal>
  <ng-container heading> {{ 'delete_reminder' | ppTranslate }} </ng-container>

  <ng-container body>
    <p class="no-margin deleteReminderText">{{ 'delete_reminder_confirmation' | ppTranslate }}</p>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'Delete' | ppTranslate"
      (ppCancel)="hideModal()"
      (ppConfirm)="deleteReminder()"
      [ppError]="true"
      [ppProcess]="processing"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
