import { cloneDeep } from 'lodash';
import { UpdatePointReminder } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';

export function updatePointReminder(
  oldState: TPointsByWorkspace,
  action: UpdatePointReminder,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);
  let pointIndex = null;

  if (state[action.payload.workspaceId]) {
    pointIndex = state[action.payload.workspaceId].entities.findIndex(
      (_point) => _point._id === action.payload._id,
    );
    const point = state[action.payload.workspaceId].entities[pointIndex];

    if (point) {
      point.reminderEpochMillis = action.payload.value;
      state[action.payload.workspaceId].entities.splice(pointIndex, 1, point);
    }
  }

  return state;
}
