import { REM_SIZE_IN_PX } from 'src/app/core/helpers/rem-size.const';

export function getWidthNumber(widthString: string, nativeWidth: number): string {
  const minimalBtnWidthPx = 180;
  let width: number;

  if (widthString) {
    width = transformWidthToNumber(widthString, width);
  } else {
    width = nativeWidth;
  }

  if (width < minimalBtnWidthPx) {
    width = minimalBtnWidthPx;
  }

  return `${width}px`;
}

function transformWidthToNumber(widthString: string, width: number): number {
  const widthIsPx = widthString.includes('px');
  const widthIsRem = widthString.includes('rem');
  const widthNumber = parseFloat(widthString);

  if (widthIsPx) {
    return widthNumber;
  } else if (widthIsRem) {
    return widthNumber * REM_SIZE_IN_PX;
  }

  return widthNumber;
}
