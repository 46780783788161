import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ApiService } from '@core/http';
import { TCustomDropdownParams } from '../../../../../../components/dropdown/AbstractDropdown';
import { EDropdownWidth } from '../../../../../../components/dropdown/dropdown.consts';
import { GET_DROPDOWN } from '../../../../../../components/dropdown/dropdown.ui.store';
import { TranslationPipe } from '../../../../../../features/translate/translation.pipe';
import { EIconPath } from '../../../../../../shared/enums/icons.enum';
import { TColumn } from '../../../../site-table/columns/column.model';
import { ESortOrder } from '../../../../site-table/sort-order-enum';
import GroupOrSortOrderDropdown from './group-or-sort-button-order-dropdown/group-or-sort-button-order-dropdown';

@Component({
  selector: 'pp-group-or-sort-button-order',
  templateUrl: './group-or-sort-button-order.component.html',
  styleUrls: ['./group-or-sort-button-order.component.scss'],
})
export class GroupOrSortButtonOrderComponent {
  @Input() ppColumn: TColumn;
  @ViewChild('columnGroupOrderButton') editGroupButtonElement: ElementRef;
  @Output() ppAction = new EventEmitter<ESortOrder>();
  @Input() ppOrder: ESortOrder;
  @Output() ppOrderChange = new EventEmitter<ESortOrder>();
  @Input() ppIsSorting: boolean;
  @Output() ppUpdate = new EventEmitter<ESortOrder>();

  EIconPath = EIconPath;
  closedWithAnchor = false;
  dropdown = GET_DROPDOWN('SITE_GROUP_BY');

  constructor(private translationPipe: TranslationPipe, private apiService: ApiService) {}

  action(): void {
    this.showDropdown();
  }

  showDropdown(): void {
    const dropdownName = 'SITE_GROUP_BY';
    this.dropdown = GET_DROPDOWN(dropdownName);

    if (!this.dropdown && !this.closedWithAnchor) {
      const dropdownParams: TCustomDropdownParams = {
        name: dropdownName,
        anchorElement: this.editGroupButtonElement.nativeElement,
        apiService: this.apiService,
        width: EDropdownWidth.REM_21,
        offset: [0, 8],
        onClose: (_anchor) => {
          this.closedWithAnchor = _anchor;
          this.dropdown = null;
        },
        onSelect: (item: ESortOrder) => {
          if (this.ppColumn) {
            this.ppUpdate.emit(item);
          } else {
            this.ppAction.emit(item);
            this.ppOrderChange.emit(item);
          }
        },
      };

      this.dropdown = new GroupOrSortOrderDropdown(dropdownParams);
    } else {
      this.closedWithAnchor = false;
    }
  }

  transformOrder(order: string): string {
    const orderKey = order === ESortOrder.ASC ? 'ascending' : 'descending';

    return this.translationPipe.transform(orderKey);
  }

  getOrder(): string {
    if (!this.ppColumn) {
      return null;
    }

    if (this.ppIsSorting) {
      return this.ppColumn.sortOrder;
    }

    return this.ppColumn.groupOrder;
  }
}
