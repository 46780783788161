import { Component, Input, OnInit } from '@angular/core';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { EDropdownWidth, TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { SiteFilterService } from '../../filters/site-filter-service/site-filter.service';
import { TableColumnsService } from '../../site/site-table/columns/table-columns.service';
import { SavedViewsDropdownComponent } from '../saved-views-dropdown/saved-views-dropdown.component';
import { SavedViewsService } from '../saved-views.service';

@Component({
  selector: 'pp-saved-views-button',
  templateUrl: './saved-views-button.component.html',
  styleUrls: ['./saved-views-button.component.scss'],
})
export class SavedViewsButtonComponent implements OnInit {
  @Input() ppDataFetched: boolean;
  @Input() ppWorkspaceId: string;

  EIconPath = EIconPath;
  dropdownOpened: boolean;
  dropdown: TDropdown = this.dropdownService.getDropdown();
  dropdownVisible: boolean;
  savedViewsDropdownBtnId = 'viewsDropdownBtn';
  private dropdownWidth = EDropdownWidth.PX_330;

  isViewSelected: boolean;

  constructor(
    private dropdownService: DropdownService,
    private savedViewsService: SavedViewsService,
    private siteFilterService: SiteFilterService,
    private tableColumnsService: TableColumnsService,
  ) {}

  ngOnInit(): void {
    this.checkIfSelectedSavedView();

    this.isViewSelected = this.savedViewsService.checkIfViewIsActive(
      this.ppWorkspaceId,
      this.tableColumnsService.getColumns(),
      this.siteFilterService.getFilters(),
    );
  }

  toggleViewsDropdown(): void {
    if (this.dropdown.visible && this.dropdown.buttonId === this.savedViewsDropdownBtnId) {
      this.dropdownVisible = false;
      this.dropdownService.hideDropdown();
    } else {
      this.dropdownVisible = true;
      this.dropdownService.setData({});

      this.dropdownService.showDropdown(this.savedViewsDropdownBtnId, SavedViewsDropdownComponent, {
        onClose: () => this.hideDropdown(),
        popper: {
          positionFixed: true,
          placement: 'bottom-start',
        },
        width: this.dropdownWidth,
      });
    }
  }

  hideDropdown(): void {
    this.dropdown.visible = false;
    this.dropdownVisible = false;
  }

  checkIfSelectedSavedView(): void {
    this.savedViewsService.viewsChange$.subscribe(() => {
      this.isViewSelected = this.savedViewsService.checkIfViewIsActive(
        this.ppWorkspaceId,
        this.tableColumnsService.getColumns(),
        this.siteFilterService.getFilters(),
      );
    });
  }
}
