import { cloneDeep, isArray } from 'lodash';

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { catchError, finalize, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { SetAccounts } from '../../account/account.actions';

import { TAccount } from 'src/app/project/modules/account/account.model';
import { TUser } from 'src/app/project/modules/user/user.model';
import { SetWorkspaces } from 'src/app/project/modules/workspace/workspace.actions';
import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { DeviceService } from 'src/app/core/services/device.service';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { SyncService } from 'src/app/project/modules/offline/sync.service';
import { WhiteLabelService } from 'src/app/project/modules/white-label/white-label.service';
import { UploadService } from '../../../components/input/upload/upload.service';
import { PromptService } from '../../../components/prompt/prompt.service';
import { WorkspaceService } from '../../workspace/workspace.service';
import { SiteDataService } from '../site-data.service';

import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { SortingService, TGuid } from '@core/helpers';
import { blurInput } from 'src/app/core/helpers/blur-input';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { setChangedAccounts } from 'src/app/project/modules/account/account';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { EPlanModule } from 'src/app/project/modules/plan/plan-module.enum';
import { generateNewSiteShare } from 'src/app/project/modules/share/share-utils/generate-new-site-share';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { TShare } from 'src/app/project/modules/share/share.model';
import { GET_SHARES } from 'src/app/project/modules/share/shares.store';
import { TSiteResponse } from 'src/app/project/modules/workspace/site-response.model';
import { setChangedWorkspaces } from 'src/app/project/modules/workspace/workspace';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventNewSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { ChangeLimitModalComponent } from '../../../components/change-limit-modal/change-limit-modal.component';
import { TChangeLimitsModalData } from '../../../components/change-limit-modal/change-limit-modal.model';
import { ModalService } from '../../../components/modal/modal.service';
import { ActiveWorkspaceService } from '../../user/active-workspace.service';
import { TSiteNewFilterDropdownSelected } from './site-new-filter-dropdown/site-new-filter-dropdown.model';
import { DEFAULT_PLAN_FORMAT } from './site-plan-format/default-plan-format';
import { EPlanFormat } from './site-plan-format/plan-format.enum';

@Component({
  selector: 'app-site-new',
  templateUrl: './site-new.component.html',
  styleUrls: ['./site-new.component.scss'],
})
export class SiteNewComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly shouldCloseModal = new Subject<boolean>();

  accounts$: Observable<TAccount[]>;
  accounts: TAccount[] = [];
  user$: Observable<TUser>;
  user: TUser;
  private workspaces$: Observable<TWorkspacesById>;
  workspaces: TWorkspacesById;
  uploadedImage: string | ArrayBuffer = null;
  limitedUser = true;
  keyword = '';
  planFormat: EPlanFormat = DEFAULT_PLAN_FORMAT;
  EPlanModule = EPlanModule;

  site: {
    name: string;
  } = {
    name: '',
  };

  accountId = '';
  selectedWorkspaceId = '';
  filterListItemsBy = '';

  processing = false;
  hovered = false;
  focused = false;

  sitePlan: File = null;
  account: TAccount;
  sitesToDisplay: string[] = [];
  selectedFilters: TSiteNewFilterDropdownSelected = null;
  noSitePlan = false;
  inputDirty = false;
  uploadedPdf: File;
  contentType: string;

  public isiPod = false;
  public isMobile = false;
  public isiPad = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<{
      user: TUser;
      accounts: TAccount[];
      workspaces: TWorkspacesById;
    }>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private promptService: PromptService,
    private uploadService: UploadService,
    private siteDataService: SiteDataService,
    private accountService: AccountService,
    private syncService: SyncService,
    private dropdownService: DropdownService,
    private deviceService: DeviceService,
    private workspaceService: WorkspaceService,
    private translationPipe: TranslationPipe,
    private customFieldsService: CustomFieldsService,
    private sortingService: SortingService,
    private whiteLabelService: WhiteLabelService,
    private titleService: Title,
    private modalService: ModalService,
    private activeWorkspaceService: ActiveWorkspaceService,
  ) {
    this.user$ = this.store.pipe(select(EStore.USER));
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
  }

  ngOnInit() {
    const accounts = this.accountService.getAccounts();

    this.workspaceService
      .generateWorkspaces({ refetch: true })
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    this.titleService.setTitle('New site | Pinpoint Works');

    this.isiPod = this.deviceService.isiPod();
    this.isMobile = this.deviceService.isMobile();
    this.isiPad = this.deviceService.isiPad();

    if (!accounts.length) {
      this.syncService.firstLoad().pipe(takeUntil(this.destroy$)).subscribe();
    }

    this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.accountId = params.get('id');

      if (this.accountId) {
        this.account = this.accounts.find((account) => account.accountId === this.accountId);

        this.whiteLabelService.fetchWhiteLabel(this.accountId);

        if (this.account && this.account.workspaces && this.user) {
          this.filterSites();
        }

        this.clearView();
      }
    });

    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((newAccounts) => {
      if (isArray(newAccounts)) {
        this.accounts = newAccounts;

        if (this.accountId) {
          this.account = newAccounts.find((account) => account.accountId === this.accountId);

          if (this.account && this.account.workspaces && this.user) {
            this.filterSites();
          }
        }
      }
    });

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;

      if (this.account && this.account.workspaces && this.user && this.workspaces) {
        this.filterSites();
      }
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspaces = cloneDeep(workspaces);

      if (this.account && this.account.workspaces && this.user && this.workspaces) {
        this.filterSites();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  uploadSitePlan(files: File[]): void {
    const file = files[0];
    this.contentType = file.type;

    if (file.type === 'application/pdf') {
      this.uploadedImage = null;
      this.uploadedPdf = file;
    } else {
      const reader = new FileReader();
      this.uploadedPdf = null;

      [this.sitePlan] = files;
      this.selectedWorkspaceId = '';
      this.uploadedImage = null;

      reader.onload = (): void => {
        this.uploadedImage = reader.result;
      };

      reader.readAsDataURL(files[0]);
    }
  }

  handleRedirecting(workspaceId: TGuid, accountId: string, response: TSiteResponse): void {
    if (this.router.url.includes('/settings/site/new/')) {
      if (this.limitedUser) {
        this.router.navigateByUrl(`/settings/site/${workspaceId}/users`);
      } else if (accountId) {
        this.router.navigateByUrl(`/settings/account/${this.accountId}/sites`);
      } else {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigateByUrl(`/site/${response.workspaceRef.id}`);
        });
      }
    }
  }

  create(): void {
    this.site.name = this.site.name.trim();

    if (this.processing) {
      return;
    }

    if (this.account?.workspaces) {
      const nameAlreadyUsed = this.account.workspaces.some(
        (workspaceId) => this.workspaces[workspaceId].siteName.localeCompare(this.site.name) === 0,
      );

      if (nameAlreadyUsed) {
        const promptText = this.translationPipe
          .transform('prompt_site_name_taken')
          .replace('#', this.site.name);

        this.promptService.showWarning(promptText);

        return;
      }
    }

    const accountId = this.accountId || null;

    logEventInGTAG(EGoogleEventNewSite.CREATE_SITE_CREATE_SITE, {
      event_category: EGoogleEventCategory.CREATE_SITE,
      event_details: this.noSitePlan
        ? 'no_site_plan'
        : this.selectedWorkspaceId
          ? 'imported_site_plan'
          : 'new_site_plan',
    });

    this.addSite(accountId);
  }

  private addSite(accountId: string): void {
    this.processing = true;

    this.siteDataService
      .addSite(accountId, this.site)
      .pipe(
        switchMap((response) => {
          this.customFieldsService.setWorkspaceCustomFields(response.workspaceRef.id, {});
          setChangedWorkspaces(true);
          setChangedAccounts(true);

          if (this.noSitePlan) {
            this.processSiteWithSuccess(response, accountId);

            return of();
          }

          if (this.sitePlan || this.uploadedPdf) {
            const plan = this.uploadedPdf ? this.uploadedPdf : this.sitePlan;

            return this.uploadService
              .uploadSitePlan(response.workspaceRef.id, plan, this.planFormat, this.contentType)
              .pipe(
                tap((sitePlanResponse) => {
                  this.processSiteWithSuccess(sitePlanResponse, accountId);
                }),
                catchError((error) => {
                  console.error(error);
                  this.shouldCloseModal.next(false);
                  logErrorInSentry(error);

                  this.processSiteWithWarning(response, accountId);

                  return of();
                }),
              );
          }

          if (this.selectedWorkspaceId) {
            return this.uploadService
              .importSitePlan(response.workspaceRef.id, this.selectedWorkspaceId)
              .pipe(
                tap((sitePlanResponse) => {
                  this.processSiteWithSuccess(sitePlanResponse, accountId);
                }),
                catchError((error) => {
                  this.processing = false;
                  const promptText = this.translationPipe.transform('prompt_site_created_error');

                  console.error(error);
                  logErrorInSentry(error);

                  this.promptService.showError(promptText);

                  return of();
                }),
              );
          }

          this.processSiteWithSuccess(response, accountId);

          return of();
        }),
        catchError((error) => {
          let promptText = this.translationPipe.transform('prompt_site_created_error');

          this.shouldCloseModal.next(true);

          if (error.status === EStatusCode.UPGRADE_REQUIRED) {
            this.showCreateSiteLimitModal(accountId);

            return of();
          }

          if (error.status === EStatusCode.BAD_REQUEST) {
            promptText = this.translationPipe
              .transform('prompt_site_name_taken')
              .replace('#', this.site.name);
          } else {
            console.error(error);

            logErrorInSentry(error);
          }

          this.promptService.showError(promptText);

          return of();
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  private showCreateSiteLimitModal(accountId: string): void {
    this.modalService.setData<TChangeLimitsModalData>({
      firstMessageKey: 'sites_limit_reached_description_1',
      secondMessageKey: 'sites_limit_reached_description_2',
      confirmKey: 'add_site',
      header: 'sites_limit_reached',
      accountId,
      limitsToIncrease: {
        SITES: 10, // ""...your account will be invoiced for 10 additional sites", we're not adding 1 for business reasons
        SHARES_ADMIN: 0,
        SHARES_GUEST: 0,
        SHARES_NORMAL: 0,
        SHARES_ACCOUNT_ADMIN: 0,
      },
    });

    this.modalService.showModal(ChangeLimitModalComponent, {
      onBeforeClose: (cancelled) => this.getAddSiteResult(cancelled, accountId),
    });
  }

  selectWorkspace(workspaceId: string): void {
    this.selectedWorkspaceId = workspaceId;
    this.sitePlan = null;
    this.uploadedImage = null;
  }

  onInputFocus(): void {
    this.focused = true;
  }

  onInputFocusLost(): void {
    this.inputDirty = true;
    this.focused = false;
  }

  clear(): void {
    this.filterListItemsBy = '';
  }

  processSite(site: TSiteResponse, accountId: string): void {
    const workspaceId = site.workspaceRef.id;
    const accounts = cloneDeep(this.accounts);
    const account = accounts.find(
      (searchedAccount) => searchedAccount.accountId === this.accountId,
    );
    const workspaces = this.workspaceService.getWorkspaces();

    setChangedWorkspaces(true);

    if (this.accountId && account) {
      const newWorkspace = this.generateWorkspaceObject(site, account);
      account.workspaces.push(workspaceId);
      workspaces[workspaceId] = newWorkspace as TWorkspace;

      this.store.dispatch(new SetAccounts(accounts));
      this.store.dispatch(new SetWorkspaces(workspaces));
    }

    this.shouldCloseModal.next(true);
    this.activeWorkspaceService.updateActiveWorkspaceId(workspaceId);
    this.handleRedirecting(workspaceId, accountId, site);
  }

  filterSites(): void {
    const newSitesList = [];
    this.sitesToDisplay = [];
    this.limitedUser = !this.user.isSuperUser && this.user.accountId !== this.accountId;

    if (!this.limitedUser) {
      this.accounts.forEach((account) => {
        account.workspaces.forEach((workspaceId) => {
          this.sitesToDisplay.push(workspaceId);
        });
      });
    } else {
      const account = this.accounts.find((_account) => _account.accountId === this.accountId);

      account.workspaces.forEach((workspaceId) => {
        const shares = GET_SHARES();
        const share = shares.find((_share) => _share.workspaceId === workspaceId);

        if (
          share?.shareOption === EUserRole.SITE_ADMIN ||
          share?.shareOption === EUserRole.ACCOUNT_ADMIN
        ) {
          this.sitesToDisplay.push(workspaceId);
        }
      });
    }

    const allSites = cloneDeep(this.sitesToDisplay);

    allSites.forEach((workspaceId) => {
      if (
        this.workspaces[workspaceId]?.siteName.toLowerCase().includes(this.keyword.toLowerCase())
      ) {
        newSitesList.push(workspaceId);
      }
    });

    newSitesList.sort((a, b) =>
      this.sortingService.naturalSort(this.workspaces[a]?.siteName, this.workspaces[b]?.siteName),
    );

    this.sitesToDisplay = newSitesList;
  }

  // FIXME after importing plan site object is used as a parameter
  generateWorkspaceObject(site: TSiteResponse, account: TAccount): TWorkspace {
    const share: TShare = generateNewSiteShare(this.user.userId, site.id, this.limitedUser);

    return {
      accountId: this.accountId,
      accountName: account.accountName,
      header: site.header,
      customFields: [],
      siteId: site.id,
      siteImageRef: site.siteImageRef,
      siteName: site.name,
      sitePlan: site.sitePlan,
      tags: [],
      users: [],
      workspaceId: site.workspaceRef.id,
      share,
    };
  }

  contactUs(): void {
    logEventInGTAG(EGoogleEventNewSite.CREATE_SITE_CONTACT, {
      event_category: EGoogleEventCategory.CREATE_SITE,
    });

    this.document.location.href = 'mailto:support@pinpointworks.com';
  }

  updateSearchBox(event: string): void {
    this.keyword = event;

    this.filterSites();
  }

  toggleNoSitePlan(): void {
    this.noSitePlan = !this.noSitePlan;
  }

  clearView(): void {
    this.site.name = '';
    this.noSitePlan = false;
    this.selectedWorkspaceId = '';
    this.sitePlan = null;
    this.inputDirty = false;
  }

  selectFormat(format: EPlanFormat): void {
    this.planFormat = format;
  }

  private processSiteWithSuccess(siteResponse: TSiteResponse, accountId: string): void {
    const promptText = this.translationPipe.transform('prompt_site_created');

    this.promptService.showSuccess(promptText);
    this.processSite(siteResponse, accountId);
  }

  private processSiteWithWarning(siteResponse: TSiteResponse, accountId: string): void {
    const promptText = this.translationPipe.transform('prompt_site_plan_created_error');

    this.promptService.showWarning(promptText, { duration: 30 });
    this.processSite(siteResponse, accountId);
  }

  blurInput(event: Event): void {
    blurInput(event.target);
  }

  private getAddSiteResult(cancelled: boolean, accountId: string): Promise<boolean> {
    if (!cancelled) {
      this.addSite(accountId);

      return this.shouldCloseModal.pipe(takeUntil(this.destroy$), take(1)).toPromise();
    }

    return Promise.resolve(true);
  }
}
