import { Injectable } from '@angular/core';
import { SortingService } from '@core/helpers';
import { TWorkspaceResponse, TWorkspaceUserDTO } from '@project/view-models';
import { TCustomField } from '../custom-fields/custom-fields.model';
import { generateWorkspaceSitePlan } from './workspace-utils/generate-workspace-site-plan';
import { generateWorkspaceUserIds } from './workspace-utils/generate-workspace-user-ids';
import { TWorkspace, TWorkspaceSitePlan } from './workspace.model';
import { TWorkspaceDataResponse } from './workspace.service';

export type TCustomFieldList = {
  [workspaceId: string]: {
    [fieldId: string]: TCustomField;
  };
};

@Injectable({
  providedIn: 'root',
})
export class GenerateWorkspaceService {
  constructor(private sortingService: SortingService) {}

  generateWorkspace(_responses: TWorkspaceDataResponse): TWorkspace {
    const workspace = _responses.workspace;
    const account = _responses.account;
    const share = _responses.share;
    const workspaceUsers = _responses.workspaceUsers as unknown as TWorkspaceUserDTO[];

    const userIds = generateWorkspaceUserIds(workspaceUsers);

    const customFieldIds = [];

    if (workspace.customFields) {
      workspace.customFields.forEach((field) => {
        customFieldIds.push(field.id.toString());
      });
    }

    const sitePlan: TWorkspaceSitePlan = generateWorkspaceSitePlan(workspace);

    return {
      share,
      accountId: account.accountId,
      accountName: account.accountName,
      customFields: customFieldIds,
      siteImageRef: workspace.siteImageRef,
      siteLogoRef: workspace.siteLogoRef,
      hidden: workspace.hidden,
      sitePlan,
      tags: [...workspace.tags].sort((a, b) => this.sortingService.naturalSort(a, b)),
      siteId: workspace.siteRef.id,
      siteName: workspace.siteName,
      workspaceId: workspace._id,
      loadedFullWorkspace: true,
      users: userIds,
      integrations: workspace.integrations,
    };
  }

  generateWorkspaceCustomFields(
    workspace: TWorkspaceResponse,
    workspaceId: string,
  ): TCustomFieldList {
    const customFieldList: TCustomFieldList = {};
    customFieldList[workspaceId] = {};

    if (workspace.customFields) {
      workspace.customFields.forEach((field) => {
        customFieldList[workspaceId][field.id] = { ...field };
      });
    }

    return customFieldList;
  }
}
