import { Injectable } from '@angular/core';
import { isEqual } from 'lodash';
import { WorkspaceService } from '../workspace/workspace.service';
import { generateShareAdvancedAccessLevels } from './share-utils/generate-share-aal';
import { EUserRole } from './share-utils/user-roles';
import { TAdvancedAccessLevels, TShare } from './share.model';
import { generateShare } from './share.utils';

@Injectable({
  providedIn: 'root',
})
export class DefaultShareService {
  constructor(private workspaceService: WorkspaceService) {}

  resetShareToDefaults(share: TShare): TShare {
    const newShare: TShare = {
      workspaceId: share.workspaceId,
      shareOption: share.shareOption,
      defectTags: [],
      tagLimited: false,
      userId: share.userId,
      shareId: share.shareId,
      hidden: false,
      userEmail: share.userEmail,
      advancedAccessLevels: generateShareAdvancedAccessLevels(null, share.shareOption as EUserRole),
    };

    return generateShare(
      newShare,
      share.workspaceId,
      this.workspaceService.getWorkspace(share.workspaceId),
    );
  }

  checkIfShareIsDefault(share: TShare): boolean {
    const defaultShare = this.resetShareToDefaults(share);

    return isEqual(share, defaultShare);
  }

  resetShareToLimited(share: TShare): TShare {
    const newAdvancedAccessLevels: TAdvancedAccessLevels = {
      exports: share.advancedAccessLevels.exports,
      sitePlan: share.advancedAccessLevels.sitePlan,
      timeline: {
        comments: {
          permission: {
            read: share.advancedAccessLevels.timeline.comments.permission.read,
            edit: share.advancedAccessLevels.timeline.comments.permission.edit,
          },
        },
        permission: {
          read: share.advancedAccessLevels.timeline.permission.read,
        },
      },
      tags: {
        permission: {
          read: share.advancedAccessLevels.tags.permission.read,
          edit: false,
        },
      },
      customFields: share.advancedAccessLevels.customFields.map((customField) => {
        return {
          ...customField,
          permission: {
            read: customField.permission.read,
            edit: false,
          },
        };
      }),
    };

    return {
      ...share,
      shareOption: EUserRole.LIMITED,
      advancedAccessLevels: newAdvancedAccessLevels,
    };
  }
}
