<pp-advanced-filter-option-select
  class="advancedFilter__option"
  [ppItems]="advancedFilterTagOptions"
  [ppOption]="option"
  (ppOptionChange)="changeFieldCondition($event)"
  ppClass="advancedFilter__option--condition"
></pp-advanced-filter-option-select>

<pp-tag-select
  [ppSelectedTags]="value"
  (ppOnChange)="changeFieldValue($event)"
  (ppOnClose)="changeFieldValue($event)"
  [ppWorkspaceId]="ppWorkspaceId"
  [ppOptions]="tagSelectOptions"
  *ngIf="
    option &&
    option !== EAdvancedFilterOptions.IS_EMPTY &&
    option !== EAdvancedFilterOptions.IS_NOT_EMPTY
  "
></pp-tag-select>
