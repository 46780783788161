<div class="pointCF__field pointCF__field--expandable">
  <label class="pointCF__label pointCF__label-text">
    <div class="pointCF__label-image--container">
      <pp-icon [ppSrc]="EIconPath.ICON_FIELDS_RICH_TEXT_18" ppColor="inherit" ppClass="setSize18" />
    </div>

    <span
      ppTooltip
      class="pointCF__label-container"
      ppTitle="{{ ppFieldLabel }}"
      [ppTrimTooltip]="true"
    >
      {{ ppFieldLabel }}
    </span>
  </label>

  <div class="pointCF__content pointCF__content--rich">
    <pp-rich-text
      class="richText__container"
      [ppId]="'richTextContainer' + ppFieldId + '_point'"
      [ppCanEdit]="ppCanEdit"
      [ppValue]="{ richText: richText, plainText: ppPlainText }"
      [ppWorkspaceId]="ppWorkspaceId"
      [ppPointId]="ppPointId"
      [ppPlaceholder]="'enter_text' | ppTranslate"
      (ppUpdate)="updateField($event)"
      (ppUpdateUserList)="updateUserList()"
      [ppUserListIds]="userListIds"
      [ppOptions]="richTextOptions"
    ></pp-rich-text>
  </div>
</div>
