import {
  Component,
  OnDestroy,
  AfterViewInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FilesApiProviderService } from 'src/app/project/data-providers/api-providers/files-api-provider/files-api-provider.service';
import { catchError, tap } from 'rxjs/operators';
import { ResponseErrorService } from 'src/app/project/modules/errors/response-error.service';
import {TAnyFunction} from '@core/helpers';

declare let embedpano: TAnyFunction;
declare let removepano: TAnyFunction;

@Component({
  selector: 'pp-gallery-overlay-spherical',
  templateUrl: './gallery-overlay-spherical.component.html',
  styleUrls: ['./gallery-overlay-spherical.component.scss'],
})
export class GalleryOverlaySphericalComponent implements OnDestroy, OnChanges, AfterViewInit {
  @Input() ppImageId: string;

  constructor(
    private filesApiProviderService: FilesApiProviderService,
    private responseErrorService: ResponseErrorService,
  ) {}

  ngOnDestroy() {
    this.removeImage360();
  }

  ngAfterViewInit() {
    this.createImage360();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ppImageId.previousValue) {
      this.removeImage360(changes.ppImageId.previousValue);
    }

    this.createImage360();
  }

  removeImage360(imageId: string = null): void {
    if (imageId) {
      removepano(`sphericalImageOverlay${imageId}`);
    } else {
      removepano(`sphericalImageOverlay${this.ppImageId}`);
    }
  }

  createImage360(): void {
    this.filesApiProviderService
      .createImage360(this.ppImageId)
      .pipe(
        tap((response) => {
          embedpano({
            id: `sphericalImageOverlay${this.ppImageId}`,
            xml: response,
            target: 'sphericalImageOverlay',
            html5: 'only',
            mobilescale: 1.0,
            passQueryParameters: true,
            onerror: (error) => {
              console.log(error);
            },
          });
        }),
        catchError(this.responseErrorService.handleRequestError),
      )
      .subscribe();
  }
}
