import { Action } from '@ngrx/store';

import { TSync } from './sync.model';

export enum EActionType {
  SET_SYNCED = '[Sync] Set Synced',
  SET_SYNCING = '[Sync] Set Syncing',
  SET_BASIC_FETCHED = '[Sync] Set basicFetched',
  CLEAR_MESSAGES_ARRAY = '[Sync] Clear messages array',
}

export class SetSynced implements Action {
  readonly type = EActionType.SET_SYNCED;
  constructor(readonly payload: TSync['synced']) {}
}

export class SetSyncing implements Action {
  readonly type = EActionType.SET_SYNCING;
  constructor(readonly payload: TSync['syncing']) {}
}

export class SetBasicFetched implements Action {
  readonly type = EActionType.SET_BASIC_FETCHED;
  constructor(readonly payload: TSync['basicFetched']) {}
}

export class ClearMessagesArray implements Action {
  readonly type = EActionType.CLEAR_MESSAGES_ARRAY;
  constructor() {}
}

export type ActionsUnion = SetSynced | SetSyncing | SetBasicFetched | ClearMessagesArray;
