import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { TFleetShare } from 'src/app/project/modules/fleet-management/fleet-sharing/fleet-sharing.consts';
import { TFleet } from 'src/app/project/modules/fleet-management/fleet.consts';
import {
  API_fleet,
  API_fleet_access,
  API_fleet_by_id,
  API_fleet_create,
  API_fleet_shares,
} from './fleet-paths';
import {
  TFleetCreateDTO,
  TFleetShareCreateDTO,
  TFleetShareDeleteDTO,
} from './fleet-requests.model';

@Injectable({
  providedIn: 'root',
})
export class FleetApiProviderService {
  constructor(private apiService: ApiService) {}

  createFleet(accountId: string, body: TFleetCreateDTO): Observable<TFleet> {
    const url = API_fleet_create(accountId);

    return this.apiService.post<TFleet>(url, body);
  }

  getFleets(): Observable<TFleet[]> {
    const url = API_fleet();

    return this.apiService.get<TFleet[]>(url);
  }

  getFleetById(fleetId: string): Observable<TFleet> {
    const url = API_fleet_by_id(fleetId);

    return this.apiService.get<TFleet>(url);
  }

  editFleet(fleetId: string, body: Partial<TFleet>): Observable<TFleet> {
    const url = API_fleet_by_id(fleetId);

    return this.apiService.put<TFleet>(url, body);
  }

  deleteFleet(fleetId: string): Observable<void> {
    const url = API_fleet_by_id(fleetId);

    return this.apiService.delete<void>(url);
  }

  shareFleet(fleetId: string, body: TFleetShareCreateDTO[]): Observable<TFleetShare[]> {
    const url = API_fleet_shares(fleetId);

    return this.apiService.post<TFleetShare[]>(url, body);
  }

  getFleetShares(fleetId: string): Observable<TFleetShare[]> {
    const url = API_fleet_shares(fleetId);

    return this.apiService.get<TFleetShare[]>(url);
  }

  deleteFleetShares(fleetId: string, body: TFleetShareDeleteDTO): Observable<void> {
    const url = API_fleet_shares(fleetId);

    return this.apiService.delete<void>(url, body);
  }

  checkFleetManagementAccess(): Observable<boolean> {
    const url = API_fleet_access();

    return this.apiService.get<boolean>(url);
  }
}
