import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { TFilters } from 'src/app/project/modules/filters/site-filter.model';
import { PlanDataService, TPlanData } from 'src/app/project/modules/plan/plan-data.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { logEventInGTAG } from '../../services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from '../../services/analytics/google-analytics.consts';
import { TPoint } from '../points/points.model';
import { PointsService } from '../points/points.service';
import { PointExportUtilsService } from './point-export-utils.service';
import { PointExportService } from './point-export.service';
import { TExportCustomField } from './utils/export-custom-field';
import { TExportData } from './utils/export-data';
import { TExportModalData } from './utils/export-modal-data';
import { EExportTypeName } from './utils/export-type-names-enum';
import { getCoords } from './utils/get-coords';

@Injectable({
  providedIn: 'root',
})
export class PointExportWordService {
  private planData: TPlanData = this.planDataService.getPlan();

  constructor(
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private pointExportService: PointExportService,
    private activeService: ActiveService,
    private workspaceService: WorkspaceService,
    private pointsService: PointsService,
    private planDataService: PlanDataService,
    private pointExportUtilsService: PointExportUtilsService,
  ) {}

  exportOverviewWordSimpleList({
    exportData,
    filters,
  }: {
    exportData: TExportData;
    filters: TFilters;
  }): Observable<Response> {
    const promptText = this.translationPipe.transform('prompt_export_generation');
    const points = this.pointExportUtilsService.getOverviewReportPoints();

    this.promptService.showSuccess(promptText);

    return this.pointExportService.export({
      workspaceId: null,
      pointIds: points,
      customFieldIds: null,
      exportData: exportData,
      filters: filters,
      isPdf: false,
      exportType: EExportTypeName.WORD_SIMPLE,
      coords: null,
      isOverview: true,
    });
  }

  exportWord({
    exportedPoint,
    data,
    exportedCustomFields,
    exportData,
    filters,
  }: {
    exportedPoint: TPoint;
    data: TExportModalData;
    exportedCustomFields: TExportCustomField[];
    exportData: TExportData;
    filters: TFilters;
  }): Observable<Response> {
    const customFields: string[] = [];
    const workspaceId = exportedPoint
      ? exportedPoint.workspaceRef.id
      : this.activeService.getActiveWorkspaceId();
    const workspaces = this.workspaceService.getWorkspaces();
    const individualReport = !!data.formats.word;
    const pointIds = this.pointsService.getSelectedPointsExport();

    exportedCustomFields.forEach((field) => {
      if (field.enabled) {
        customFields.push(field.id);
      }
    });

    this.sendAnalyticsEvent(exportData);

    const coords = getCoords(workspaces, workspaceId, this.planData);

    const pointWithoutAttachments = this.checkAttachments(data);

    if (!exportData.showDocuments || pointWithoutAttachments) {
      const promptText = this.translationPipe.transform(
        pointIds.length > 1 ? 'prompt_export_start' : 'prompt_export_start_single',
      );

      this.promptService.showSuccess(promptText, { duration: 15 });
    }

    if (exportData.showDocuments && !pointWithoutAttachments) {
      return this.exportZipWord({
        _id: data._id,
        exportedCustomFields,
        exportData,
        filters,
        data,
      });
    } else if (individualReport) {
      return this.exportIndividualReport(data, exportedPoint, customFields, exportData, filters);
    } else {
      return this.exportWordDocument(data, pointIds, customFields, exportData, filters, coords);
    }
  }

  exportZipWord({
    _id,
    exportedCustomFields,
    exportData,
    filters,
    data,
  }: {
    _id: string;
    exportedCustomFields: TExportCustomField[];
    exportData: TExportData;
    filters: TFilters;
    data: TExportModalData;
  }): Observable<Response> {
    const customFields = [];

    let pointIds = [];

    if (!_id) {
      pointIds = this.pointsService.getSelectedPointsExport();
    } else {
      pointIds.push(_id);
    }

    exportedCustomFields.forEach((field) => {
      if (field.enabled) {
        customFields.push(field.id);
      }
    });

    const promptText = this.translationPipe.transform(
      pointIds.length > 1 ? 'prompt_export_start' : 'prompt_export_start_single',
    );

    this.promptService.showSuccess(promptText, { duration: 15 });

    return this.pointExportService.export({
      workspaceId: data.workspaceId,
      pointIds,
      customFieldIds: customFields,
      exportData: exportData,
      filters: filters,
      isPdf: false,
      exportType: EExportTypeName.ZIP_WORD,
      coords: null,
      isOverview: false,
    });
  }

  private checkAttachments(data: TExportModalData): boolean {
    if (data._id) {
      const point = this.pointsService.findPoint(data._id);

      if (!point.containsAttachments) {
        return true;
      }
    }
    return false;
  }

  private sendAnalyticsEvent(exportData: TExportData): void {
    if (exportData.showImages) {
      logEventInGTAG(EGoogleEventSite.SITE__EXPORT__IMAGES, {
        event_category: EGoogleEventCategory.SITE,
        event_details: exportData.imagesSize,
      });
    }
  }

  private exportWordDocument(
    data: TExportModalData,
    pointIds: string[],
    customFields: string[],
    exportData: TExportData,
    filters: TFilters,
    coords: string[],
  ): Observable<Response> {
    return this.pointExportService.export({
      workspaceId: data.workspaceId,
      pointIds,
      customFieldIds: customFields,
      exportData: exportData,
      filters: filters,
      isPdf: false,
      exportType: EExportTypeName.WORD,
      coords,
      isOverview: false,
    });
  }

  private exportIndividualReport(
    data: TExportModalData,
    exportedPoint: TPoint,
    customFields: string[],
    exportData: TExportData,
    filters: TFilters,
  ): Observable<Response> {
    return this.pointExportService.export({
      workspaceId: data.workspaceId,
      pointIds: [exportedPoint._id],
      customFieldIds: customFields,
      exportData: exportData,
      filters: filters,
      isPdf: false,
      exportType: EExportTypeName.INDIVIDUAL_WORD,
      coords: null,
      isOverview: false,
    });
  }
}
