import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';
import {
  TAdvancedFilterCheckbox,
  TAdvancedFilterSingle,
  TFilterCheckboxOptions,
} from '../../../../models/advanced-filter.model';
import { getAdvancedCheckboxOptions } from './advanced-filter-chechbox-utils';

@Component({
  selector: 'pp-advanced-filter-checkbox',
  templateUrl: './advanced-filter-checkbox.component.html',
  styleUrls: ['./advanced-filter-checkbox.component.scss'],
})
export class AdvancedFilterCheckboxComponent {
  @Input() ppFilter: TAdvancedFilterSingle;
  @Output() ppFilterChange = new EventEmitter<TAdvancedFilterSingle>();

  option: TFilterCheckboxOptions;
  filter: TAdvancedFilterCheckbox;

  advancedFilterCheckboxOptions = getAdvancedCheckboxOptions();
  EAdvancedFilterOptions = EAdvancedFilterOptions;

  ngOnChanges(): void {
    this.filter = this.ppFilter as TAdvancedFilterCheckbox;

    this.option = this.filter.option;
  }

  changeFieldCondition(newCondition: TFilterCheckboxOptions): void {
    this.ppFilter.option = newCondition;
    this.option = newCondition;
    this.applyChanges();
  }

  applyChanges(): void {
    this.ppFilterChange.emit(this.ppFilter);
  }

  changeFieldValue(value: string): void {
    this.ppFilter.value = value;
    this.applyChanges();
  }
}
