<div class="accountPlaceholder">
  <header class="accountPlaceholder__header">
    <div class="accountPlaceholder__left-header"></div>
  </header>

  <pp-account-settings-placeholder-paragraph></pp-account-settings-placeholder-paragraph>
  <pp-account-settings-placeholder-paragraph></pp-account-settings-placeholder-paragraph>
  <pp-account-settings-placeholder-paragraph></pp-account-settings-placeholder-paragraph>
  <pp-account-settings-placeholder-paragraph></pp-account-settings-placeholder-paragraph>

  <footer class="accountPlaceholder__footer">
    <div class="rightFooter"></div>
  </footer>
</div>
