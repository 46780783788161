import dayjs from 'dayjs';

import { Injectable, OnDestroy } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { TPreferences } from 'src/app/project/modules/preferences/preferences.model';
import { TAttachment, TAttachmentsMediaDate } from './attachments.model';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { DEFAULT_DATE_FORMAT } from 'src/app/project/modules/preferences/default-date-format';
import { transformDateLocal } from 'src/app/project/shared/date-locale-transformer';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsMediaThumbnailsService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  preferences$: Observable<TPreferences>;
  format = DEFAULT_DATE_FORMAT;
  preferences: TPreferences;

  constructor(
    private store: Store<{
      preferences: TPreferences;
    }>,
  ) {
    this.preferences$ = this.store.pipe(select(EStore.PREFERENCES));

    this.preferences$.pipe(takeUntil(this.destroy$)).subscribe((preferences) => {
      this.preferences = preferences;

      this.format = preferences?.dateFormat ? preferences.dateFormat : DEFAULT_DATE_FORMAT;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  groupByDate(media: TAttachment[]): TAttachmentsMediaDate[] {
    const dates: TAttachmentsMediaDate[] = [];

    media.forEach((item) => {
      const date = dayjs(item.createdOn);
      const fullDate = date.format('YYYYMMDD');
      const index = dates.findIndex((d) => d.fullDate === fullDate);

      if (index > -1) {
        dates[index].attachmentIds.push(item.attachmentId);
      } else {
        let formattedDate = null;

        formattedDate = transformDateLocal(date, ' [at] hh:mm a', this.format);

        dates.push({
          fullDate,
          formattedDate,
          attachmentIds: [item.attachmentId],
        });
      }
    });

    return dates;
  }
}
