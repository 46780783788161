import { Injectable } from '@angular/core';

import { NavigationEnd, Router } from '@angular/router';
import { TColumn } from 'src/app/project/modules/site/site-table/columns/column.model';
import { VERSION } from 'src/environments/version';
import { AccountService } from '../../modules/account/account-service/account.service';
import { GET_CUSTOM_FIELDS } from '../../modules/custom-fields/custom-fields.store';
import { WorkspaceService } from '../../modules/workspace/workspace.service';
import { simplifyRoute } from '../../shared/utils/simplify-route';
import { ActiveService } from '../active/active.service';
import { configGTAG, logEventInGTAG } from './google-analytics';
import {
  EGoogleEventCategory,
  TGoogleEventParams,
  TGoogleTagEventsUnion,
} from './google-analytics.consts';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  private googleAnalyticsAccountCode = 'UA-72811857-2';

  constructor(
    private router: Router,
    private activeService: ActiveService,
    private workspaceService: WorkspaceService,
    private accountService: AccountService,
  ) {}

  logEvent({
    eventName,
    eventCategory,
    eventLabel = null,
    eventValue = null,
    eventDetails = null,
  }: {
    eventName: TGoogleTagEventsUnion;
    eventCategory: EGoogleEventCategory;
    eventLabel?: string;
    eventValue?: number;
    eventDetails?: string;
  }): void {
    const event: TGoogleEventParams = {
      event_category: eventCategory,
      event_label: eventLabel,
      event_value: eventValue,
      event_details: eventDetails,
    };

    logEventInGTAG(eventName, event);
  }

  getColumnType(column: TColumn): string {
    if (column.customFieldIds?.length > 0) {
      const customFields = GET_CUSTOM_FIELDS();
      const customField = customFields[column.customFieldIds[0]];

      return customField ? customField.type : column.name;
    } else {
      return column.name;
    }
  }

  subscribeToRouteChange(): void {
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }

      const active = this.activeService.getActiveWorkspaceId();
      const workspace = this.workspaceService.getWorkspace(active);
      const account = this.accountService.getAccount(workspace?.accountId);
      const parsedURL = this.router.parseUrl(event.urlAfterRedirects);
      const fixedPath = simplifyRoute(parsedURL?.root?.children?.primary?.segments);

      configGTAG(this.googleAnalyticsAccountCode, {
        path: fixedPath,
        version: VERSION.version,
        shareOption: workspace?.share.shareOption,
        industryType: account?.industryType,
        accountType: account?.accountType,
      });
    });
  }
}
