import { cloneDeep } from 'lodash';

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { Store } from '@ngrx/store';
import { UpdateMediaName } from 'src/app/project/modules/points/attachments/attachments.actions';

import { AttachmentsFilesService } from 'src/app/project/modules/points/attachments/attachments-files.service';
import { Modal, ModalService } from '../../../../../components/modal/modal.service';
import { PromptService } from '../../../../../components/prompt/prompt.service';

import { of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';

@Component({
  selector: 'pp-rename-file-modal',
  templateUrl: './rename-file-modal.component.html',
  styleUrls: ['./rename-file-modal.component.scss'],
})
export class RenameFileModalComponent implements OnInit {
  @ViewChild('input') inputElement: ElementRef;

  private modal: Modal;

  processing: boolean;

  attachment;
  error = false;
  focused = false;
  characterLimit = 100;

  extension = '';
  fileName = '';

  constructor(
    private store: Store,
    private promptService: PromptService,
    private modalService: ModalService,
    private attachmentsFilesService: AttachmentsFilesService,
    private translationPipe: TranslationPipe,
  ) {}

  ngOnInit() {
    this.modal = this.modalService.getModal();

    this.processing = false;

    if (!this.modal.data) {
      throw new Error('Data for this dropdown is required');
    }

    this.attachment = cloneDeep(this.modal.data.attachment);
    this.extension = this.attachment.fileName.slice(
      ((this.attachment.fileName.lastIndexOf('.') - 1) >>> 0) + 1,
    );
    this.fileName = this.attachment.fileName.replace(this.extension, '');
  }

  confirm(): void {
    if (!this.processing) {
      logEventInGTAG(EGoogleEventSite.SITE__POINT__ATTACHMENT_RENAME, {
        event_category: EGoogleEventCategory.SITE,
      });

      this.renameFile();
    }
  }

  renameFile(): void {
    this.inputElement.nativeElement.blur();

    if (this.fileName === '') {
      this.error = true;

      return;
    }

    if (this.processing) {
      return;
    }

    this.processing = true;

    this.attachment.fileName = this.fileName + this.extension;

    this.attachmentsFilesService
      .renameAttachment(
        this.attachment.attachmentId,
        this.attachment.fileName,
        this.attachment.type,
      )
      .pipe(
        tap(() => {
          const promptText = this.translationPipe.transform('prompt_file_renamed');

          this.promptService.showSuccess(promptText);
          this.hideModal(false);

          this.store.dispatch(
            new UpdateMediaName({
              attachmentId: this.attachment.attachmentId,
              name: this.attachment.fileName,
            }),
          );
        }),
        catchError(() => {
          const promptText = this.translationPipe.transform('prompt_file_renamed_error');

          this.promptService.showError(promptText);

          return of();
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  hideModal(cancel: boolean = true): void {
    this.modalService.hideModal(cancel);
  }

  onBlur(): void {
    this.focused = false;
  }

  onFocus(): void {
    this.focused = true;
    this.error = false;
  }

  clear(): void {
    this.fileName = '';
  }
}
