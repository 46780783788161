import { GET_GROUPING } from '../../../../columns/grouping.store';
import { createEmptyGroupLevelIndicator } from './create-empty-group-level-indicator';

export function getChildrenList(groupLevel: number): HTMLElement[] {
  const childrenList: HTMLElement[] = [];
  const grouping = GET_GROUPING();

  if (groupLevel === 3) {
    childrenList.push(createEmptyGroupLevelIndicator(3));
  }

  if (groupLevel > 1) {
    childrenList.push(createEmptyGroupLevelIndicator(2));
  }

  if (groupLevel > 0 && !(groupLevel === 1 && grouping.length === 3)) {
    const element = createEmptyGroupLevelIndicator(1);

    if (groupLevel === 3 && grouping.length === 3) {
      element.style.left = '16px';
    }

    childrenList.push(element);
  }

  if (groupLevel === 1 && grouping.length === 3) {
    childrenList.push(createEmptyGroupLevelIndicator(0));
  }

  return childrenList;
}
