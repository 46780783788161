<div
  class="advancedCFAccess__section"
  *ngIf="
    ppShare.shareOption !== EUserRole.SITE_ADMIN &&
    account.accountFeatures.aalSitePlan &&
    workspace.sitePlan
  "
>
  <h6 class="shareModalHeader">
    {{ 'site_plan' | ppTranslate }}

    <i
      ppTooltip
      [ppOptions]="{ placement: 'right' }"
      class="fa fa-info-circle settingsSection__header-info"
      aria-hidden="true"
      [ppTitle]="'site_plan_AAL_description' | ppTranslate"
      [ppMobileTooltip]="true"
    ></i>
  </h6>

  <pp-toggle
    ppId="toggleShowSitePlan"
    (ppAction)="correctProperty(EGoogleEventLabel.SITE_PLAN)"
    [ppChecked]="ppShare.advancedAccessLevels.sitePlan.permission.read"
    [ppReverseLayout]="true"
    ppStyle="blue"
  >
    <span class="toggleInnerText">{{ 'site_plan_show' | ppTranslate }}</span>
  </pp-toggle>
</div>
