<div class="advancedFilter__row">
  <div class="advancedFilter__row-container">
    <pp-advanced-filter-condition
      [ppLogic]="ppLogic"
      (ppLogicChange)="changeLogic($event)"
      [ppIndex]="ppIndex"
    ></pp-advanced-filter-condition>

    <pp-advanced-filter-field-select
      [(ppFilter)]="ppFilter"
      (ppFilterChange)="changeFilterType($event)"
      [ppWorkspaceId]="ppWorkspaceId"
    ></pp-advanced-filter-field-select>

    <!-- Filter types -->
    <ng-container [ngSwitch]="ppFilter.type">
      <pp-advanced-filter-priority
        [ppIndex]="ppIndex"
        [(ppFilter)]="ppFilter"
        (ppFilterChange)="applyChanges()"
        *ngSwitchCase="EAdvancedFilterType.PRIORITY"
      ></pp-advanced-filter-priority>

      <pp-advanced-filter-text
        [(ppFilter)]="ppFilter"
        (ppFilterChange)="applyChanges()"
        *ngSwitchCase="EAdvancedFilterType.TEXT"
      ></pp-advanced-filter-text>

      <pp-advanced-filter-percentage
        [(ppFilter)]="ppFilter"
        (ppFilterChange)="applyChanges()"
        *ngSwitchCase="EAdvancedFilterType.PERCENTAGE"
      ></pp-advanced-filter-percentage>

      <pp-advanced-filter-formula
        [(ppFilter)]="ppFilter"
        (ppFilterChange)="applyChanges()"
        *ngSwitchCase="EAdvancedFilterType.FORMULA"
      ></pp-advanced-filter-formula>

      <!-- Rich text and text use the same component -->
      <pp-advanced-filter-text
        [(ppFilter)]="ppFilter"
        (ppFilterChange)="applyChanges()"
        *ngSwitchCase="EAdvancedFilterType.RICH_TEXT"
      ></pp-advanced-filter-text>

      <pp-advanced-filter-checkbox
        [(ppFilter)]="ppFilter"
        (ppFilterChange)="applyChanges()"
        *ngSwitchCase="EAdvancedFilterType.CHECKBOX"
      ></pp-advanced-filter-checkbox>

      <pp-advanced-filter-duration
        [(ppFilter)]="ppFilter"
        (ppFilterChange)="applyChanges()"
        *ngSwitchCase="EAdvancedFilterType.DURATION"
      ></pp-advanced-filter-duration>

      <pp-advanced-filter-tag
        [(ppFilter)]="ppFilter"
        (ppFilterChange)="applyChanges()"
        *ngSwitchCase="EAdvancedFilterType.TAG"
        [ppWorkspaceId]="ppWorkspaceId"
      ></pp-advanced-filter-tag>

      <pp-advanced-filter-status
        [ppIndex]="ppIndex"
        [(ppFilter)]="ppFilter"
        (ppFilterChange)="applyChanges()"
        *ngSwitchCase="EAdvancedFilterType.STATUS"
      ></pp-advanced-filter-status>

      <pp-advanced-filter-date
        [(ppFilter)]="ppFilter"
        (ppFilterChange)="applyChanges()"
        *ngSwitchCase="EAdvancedFilterType.DATE"
        ppType="date"
      ></pp-advanced-filter-date>

      <pp-advanced-filter-date
        [(ppFilter)]="ppFilter"
        (ppFilterChange)="applyChanges()"
        *ngSwitchCase="EAdvancedFilterType.DATE_CREATED"
        ppType="date_created"
      ></pp-advanced-filter-date>

      <pp-advanced-filter-date
        [(ppFilter)]="ppFilter"
        (ppFilterChange)="applyChanges()"
        *ngSwitchCase="EAdvancedFilterType.DATE_UPDATED"
        ppType="date_updated"
      ></pp-advanced-filter-date>

      <pp-advanced-filter-date
        [(ppFilter)]="ppFilter"
        (ppFilterChange)="applyChanges()"
        *ngSwitchCase="EAdvancedFilterType.REMINDER"
        ppType="date"
      ></pp-advanced-filter-date>

      <pp-advanced-filter-flag
        [(ppFilter)]="ppFilter"
        (ppFilterChange)="applyChanges()"
        *ngSwitchCase="EAdvancedFilterType.FLAGGED"
      ></pp-advanced-filter-flag>

      <pp-advanced-filter-timeline
        [(ppFilter)]="ppFilter"
        (ppFilterChange)="applyChanges()"
        *ngSwitchCase="EAdvancedFilterType.TIMELINE"
      ></pp-advanced-filter-timeline>

      <pp-advanced-filter-users
        [ppIndex]="ppIndex"
        [(ppFilter)]="ppFilter"
        ppType="created_by"
        (ppFilterChange)="applyChanges()"
        *ngSwitchCase="EAdvancedFilterType.CREATED_BY"
      ></pp-advanced-filter-users>

      <pp-advanced-filter-users
        [ppIndex]="ppIndex"
        ppType="assignees"
        [(ppFilter)]="ppFilter"
        (ppFilterChange)="applyChanges()"
        *ngSwitchCase="EAdvancedFilterType.ASSIGNEE"
      ></pp-advanced-filter-users>

      <pp-advanced-filter-number
        [(ppFilter)]="ppFilter"
        (ppFilterChange)="applyChanges()"
        *ngSwitchCase="EAdvancedFilterType.NUMBER"
        ppType="number"
      ></pp-advanced-filter-number>

      <pp-advanced-filter-number
        [(ppFilter)]="ppFilter"
        (ppFilterChange)="applyChanges()"
        *ngSwitchCase="EAdvancedFilterType.SEQUENCE_NUMBER"
        ppType="sequence_number"
      ></pp-advanced-filter-number>

      <pp-advanced-filter-number
        [(ppFilter)]="ppFilter"
        (ppFilterChange)="applyChanges()"
        *ngSwitchCase="EAdvancedFilterType.COST"
        ppType="cost"
      ></pp-advanced-filter-number>

      <pp-advanced-filter-list
        [(ppFilter)]="ppFilter"
        (ppFilterChange)="applyChanges()"
        *ngSwitchCase="EAdvancedFilterType.LIST"
      ></pp-advanced-filter-list>

      <pp-advanced-filter-location
        [(ppFilter)]="ppFilter"
        (ppFilterChange)="applyChanges()"
        *ngSwitchCase="EAdvancedFilterType.LOCATION"
      ></pp-advanced-filter-location>
    </ng-container>

    <!-- End of filter types -->

    <button
      class="advancedFilter__delete-button"
      (click)="deleteFilter()"
      (mouseenter)="buttonHover(true)"
      (mouseleave)="buttonHover(false)"
    >
      <pp-icon
        [ppSrc]="EIconPath.ICON_CLOSE_18"
        [ppColor]="buttonHovered ? 'grey-600' : 'grey-400'"
        ppClass="setSize18"
      ></pp-icon>
    </button>
  </div>
</div>
