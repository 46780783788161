import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { TCustomFieldFilter } from '../site-filter.model';

export function filterCheckboxCustomField(
  filterField: TCustomFieldFilter,
  pointCustomField: TPointCustomField,
): boolean {
  if (filterField.appliedValues.value) {
    return pointCustomField.value === filterField.appliedValues.value.toString();
  } else {
    return true;
  }
}
