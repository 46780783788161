<div class="headerOptions">
  <div class="headerOptions__option" (click)="toggleAllPoints()">
    <p class="headerOptions__text">
      {{ (allSelected ? 'deselect_all' : 'select_all') | ppTranslate }}
    </p>
  </div>

  <div class="headerOptions__option" *ngIf="collapsed" (click)="expandGroup()">
    <p class="headerOptions__text">{{ 'expand_all' | ppTranslate }}</p>
  </div>

  <div class="headerOptions__option" *ngIf="!collapsed" (click)="collapseGroup()">
    <p class="headerOptions__text">{{ 'collapse_all' | ppTranslate }}</p>
  </div>
</div>
