<button
  class="createView__button"
  (click)="createView()"
  [ngClass]="{ 'createView__button--hidden': hidden }"
  (mouseenter)="iconColour = 'primary-500'"
  (mouseleave)="iconColour = 'primary-600'"
>
  <pp-icon
    [ppSrc]="EIconPath.ICON_NAV_PLUS_14"
    [ppColor]="iconColour"
    [ppDisplayContents]="true"
  ></pp-icon>
  {{ 'create_view' | ppTranslate }}
</button>
