import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TAccountResponse } from '@project/view-models';
import { Subject, of } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { blurInput } from 'src/app/core/helpers/blur-input';
import { sanitizeHTML } from 'src/app/core/helpers/text-sanitizer';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { focusOnEnd } from 'src/app/project/shared/focus-on-end';
import { logErrorInSentry } from '../../../errors/response-error';
import { setChangedWorkspaces } from '../../../workspace/workspace';
import { UpdateWorkspaceAccountName } from '../../../workspace/workspace.actions';
import { setChangedAccounts } from '../../account';
import { AccountService } from '../../account-service/account.service';
import { UpdateAccountName } from '../../account.actions';
import { TAccount } from '../../account.model';

@Component({
  selector: 'pp-account-name',
  templateUrl: './account-name.component.html',
  styleUrls: ['./account-name.component.scss'],
})
export class AccountNameComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() ppAccount: TAccount;
  @Input() ppDisabled: boolean;
  @ViewChild('accountNameInput') accountNameInputElement: ElementRef;

  private readonly destroy$ = new Subject<void>();

  accountNameElement: HTMLElement;
  EIconPath = EIconPath;
  accountName: string;
  updatingAccount: boolean;
  editing = false;

  constructor(
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private accountService: AccountService,
    private store: Store,
    private ngZone: NgZone,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.accountNameElement = this.accountNameInputElement.nativeElement;

    this.accountNameElement.addEventListener('keydown', (event) => {
      if (event.code === 'Enter') {
        event.preventDefault();
      }
    });
  }

  ngOnChanges(): void {
    this.setAccountName();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  startEditing(): void {
    this.editing = true;

    // Timeout is required to wait for the input to be rendered
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        focusOnEnd(this.accountNameElement);
      }, 100);
    });
  }

  updateAccount(event: FocusEvent): void {
    if (this.ppDisabled) {
      return;
    }

    const newName = sanitizeHTML((event.target as HTMLElement).innerText);

    if (this.updatingAccount || this.accountName === newName) {
      return;
    }

    if (newName.length === 0) {
      const promptText = this.translationPipe.transform('prompt_empty_name');

      this.promptService.showWarning(promptText);
      return;
    }

    const body: Partial<TAccountResponse> = {
      companyName: newName,
    };

    this.updatingAccount = true;
    this.editing = true;

    this.accountService
      .updateAccount(this.ppAccount.accountId, body)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          const prompt = this.translationPipe.transform('prompt_account_update');
          this.editing = false;

          setChangedWorkspaces(true);
          setChangedAccounts(true);

          this.store.dispatch(
            new UpdateAccountName({
              accountId: this.ppAccount.accountId,
              accountName: newName,
            }),
          );

          this.ppAccount.workspaces.forEach((workspaceId) => {
            this.store.dispatch(
              new UpdateWorkspaceAccountName({
                workspaceId,
                accountName: newName,
              }),
            );
          });

          this.promptService.showSuccess(prompt);
        }),
        catchError((error) => {
          logErrorInSentry(error);
          const prompt = this.translationPipe.transform('prompt_error');

          this.promptService.showError(prompt);

          return of();
        }),
        finalize(() => {
          this.updatingAccount = false;
        }),
      )
      .subscribe();
  }

  blurInput(event: Event): void {
    blurInput(event.target);
  }

  private setAccountName(): void {
    this.accountName = this.ppAccount?.accountName;
  }
}
