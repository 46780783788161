import { Action } from '@ngrx/store';
import { TWhiteLabelStore } from 'src/app/project/modules/white-label/white-label.model';

export enum EActionType {
  SET_WHITE_LABEL = '[White Label] Set White Label',
}

export class SetWhiteLabel implements Action {
  readonly type = EActionType.SET_WHITE_LABEL;
  constructor(
    readonly payload: {
      whiteLabel: TWhiteLabelStore;
    },
  ) {}
}

export type ActionsUnion = SetWhiteLabel;
