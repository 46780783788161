import { EStatus } from '../../shared/enums/status.enum';
import { THeader } from '../../view-models/response.models';
import { EFleetShareOption } from './fleet-sharing/fleet-sharing.consts';

export type TFleet = {
  id: string;
  name: string;
  accountId: string;
  assetIds: string[];
  header: THeader;
  fleetShareOption: EFleetShareOption;
  statusSettings: TFleetStatuses;
};

export type TFleetByAccount = {
  [accountId: string]: TAccountFleet;
};

export type TAccountFleet = {
  [fleetId: string]: TFleet;
};

export type TFleetStatuses = {
  [EStatus.OPEN]: TFleetStatus;
  [EStatus.IN_PROGRESS]: TFleetStatus;
  [EStatus.ONHOLD]: TFleetStatus;
  [EStatus.TO_REVIEW]: TFleetStatus;
  [EStatus.CANCELED]: TFleetStatus;
  [EStatus.CLOSED]: TFleetStatus;
};

export type TFleetStatus = {
  include: boolean;
  weight: number;
};

export const MAX_AMOUNT_OF_FLEETS_PER_ACCOUNT: number = 10;
