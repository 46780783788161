import { cloneDeep } from 'lodash';

import { Injectable, OnDestroy } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { SetSiteFilter } from './site-filter.actions';

import { PromptService } from '../../components/prompt/prompt.service';
import { ActiveService } from '../../services/active/active.service';

import { TWorkspacesById } from '../workspace/workspace.model';
import { TAllFilters, TFilters } from './site-filter.model';

import { Router } from '@angular/router';
import { CommentApiProviderService } from '@core/api';
import { TCommentSearchData } from '@project/view-models';
import { TSearchCommentRequest } from '../../data-providers/api-providers/comment-api-provider/comment-requests.model';
import { TranslationPipe } from '../../features/translate/translation.pipe';
import { EStore } from '../../shared/enums/store.enum';
import { logErrorInSentry } from '../errors/response-error';
import { checkCustomWorkspaceId } from '../workspace/workspace';
import { EWorkspaces } from '../workspace/workspaces.enum';

@Injectable({
  providedIn: 'root',
})
export class SearchKeywordService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private filters: TFilters;

  private siteFilters$ = new Observable<TAllFilters>();
  private workspaces$ = new Observable<TWorkspacesById>();
  private workspaceId: string;
  private allFilters: TAllFilters;

  constructor(
    private store: Store<{
      siteFilter: TAllFilters;
      workspaces: TWorkspacesById;
    }>,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private activeService: ActiveService,
    private commentApiProviderService: CommentApiProviderService,
    private router: Router,
  ) {
    this.siteFilters$ = this.store.pipe(select(EStore.SITE_FILTER));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.siteFilters$.pipe(takeUntil(this.destroy$)).subscribe((allFilters) => {
      this.allFilters = allFilters;
      this.filters = this.allFilters?.[this.workspaceId];
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.workspaceId = this.activeService.getActiveWorkspaceId();

      if (!this.workspaceId) {
        this.workspaceId = checkCustomWorkspaceId();
      }

      this.filters = this.allFilters?.[this.workspaceId];
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  searchCommentKeyword(keyword: string, workspaceIds: string[]): Observable<TCommentSearchData> {
    if (keyword.length < 2) {
      return of({
        pointIds: [],
      });
    }

    const body: TSearchCommentRequest = {
      phrase: keyword,
      workspaceIds,
    };

    return this.commentApiProviderService.searchComment(body).pipe(
      catchError((error) => {
        logErrorInSentry(error);

        const promptText = this.translationPipe.transform('prompt_search_failed');

        this.promptService.showError(promptText);

        return of(null);
      }),
    );
  }

  setKeyword(keyword: string, pointsWithKeyword: string[]): void {
    const newFilters = cloneDeep(this.filters);
    newFilters.keyword = keyword;
    newFilters.pointsWithKeyword = pointsWithKeyword;

    if (!this.workspaceId) {
      const isTimeline = this.router.url.includes(EWorkspaces.TIMELINE);

      this.store.dispatch(
        new SetSiteFilter({
          filter: newFilters,
          workspaceId: isTimeline ? EWorkspaces.TIMELINE : EWorkspaces.OVERVIEW,
        }),
      );
    } else {
      this.store.dispatch(
        new SetSiteFilter({
          filter: newFilters,
          workspaceId: this.workspaceId,
        }),
      );
    }
  }
}
