import { cloneDeep } from 'lodash';
import { SetPoints } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';

export function setPoints(oldState: TPointsByWorkspace, action: SetPoints): TPointsByWorkspace {
  const state = cloneDeep(oldState);

  if (!state[action.payload.workspaceId]) {
    state[action.payload.workspaceId] = {};
  }

  state[action.payload.workspaceId].entities = action.payload.points;

  return state;
}
