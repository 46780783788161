export enum EAccountFeature {
  offline = 'offline',
  pdfSignatures = 'pdfSignatures',
  globalSitePreferences = 'globalSitePreferences',
  timeline = 'timeline',
  reminders = 'reminders',
  aalSitePlan = 'aalSitePlan',
  aalExport = 'aalExport',
  YCO_PDF_LAYOUT = 'pdfYcoLayout',
  SAVED_VIEWS = 'savedViews',
  ADVANCED_FILTERS = 'advancedFilters',
  FLEET_MANAGEMENT = 'fleetManagement',
}
