import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { routeToUrl } from 'src/app/core/helpers/route-to-url';
import { TwoFactorAuthenticationProviderService } from 'src/app/project/data-providers/api-providers/two-factor-authentication-provider/two-factor-authentication-provider.service';
import { getErrorMessage } from 'src/app/project/helpers/database/get-error-message';
import { EAuthRoute } from 'src/app/project/shared/constants/auth.constants';
import { UserService } from '../../user/user.service';
import { TwoFactorAuthenticationPhoneNumberService } from './two-factor-authentication-phone-number.service';
import {
  ETwoFactorAuthenticationChannel,
  TTwoFactorAuthDeleteDTO,
  TTwoFactorAuthenticationCode,
  TTwoFactorAuthenticationConfirmDTO,
  TTwoFactorAuthenticationSetupDTO,
} from './two-factor-authentication.consts';

@Injectable({
  providedIn: 'root',
})
export class TwoFactorAuthenticationService {
  constructor(
    private twoFactorAuthenticationProviderService: TwoFactorAuthenticationProviderService,
    private twoFactorAuthenticationPhoneNumberService: TwoFactorAuthenticationPhoneNumberService,
    private userService: UserService,
    private router: Router,
  ) {}

  setup2FA(phoneNumber: string, channel: ETwoFactorAuthenticationChannel): Observable<any> {
    const body: TTwoFactorAuthenticationSetupDTO = {
      phoneNumber,
      channel,
    };

    return this.twoFactorAuthenticationProviderService.setup2FA(body);
  }

  confirm2FA(
    code: TTwoFactorAuthenticationCode,
    phoneNumber: string,
    channel: ETwoFactorAuthenticationChannel,
  ): Observable<any> {
    const user = this.userService.getUser();

    const body: TTwoFactorAuthenticationConfirmDTO = {
      twoFactorAuthCode: code,
      phoneNumber,
      channel,
    };

    return this.twoFactorAuthenticationProviderService.confirm2FA(user.userId, body);
  }

  deleteMy2FA(userId: string, twoFactorAuthCode?: string): Observable<never> {
    const body: TTwoFactorAuthDeleteDTO = {
      userId,
    };

    if (twoFactorAuthCode) {
      body.twoFactorAuthCode = twoFactorAuthCode;
    }

    return this.twoFactorAuthenticationProviderService.deleteMy2FA(body);
  }

  delete2FA(userId: string): Observable<never> {
    const body: TTwoFactorAuthDeleteDTO = {
      userId,
    };
    return this.twoFactorAuthenticationProviderService.delete2FA(body);
  }

  askLaterFor2FA(userId: string): Observable<null> {
    return this.twoFactorAuthenticationProviderService.askLaterFor2FA(userId);
  }

  handleLoginError(error: any): void {
    getErrorMessage(error).then((message) => {
      this.twoFactorAuthenticationPhoneNumberService.setConfirmationPhoneNumber(message);

      this.router.navigate([routeToUrl(EAuthRoute.TWO_FACTOR_AUTHENTICATION_LOGIN)]);
    });
  }
}
