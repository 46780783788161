<div #tableWrapper class="siteTable__wrapper">
  <div #table ></div>

  <div
    class="overviewPointContainer"
    [class.overviewPointContainer--hidden]="isOverviewPage()"
    (mouseenter)="$event.stopPropagation()"
  >
    <router-outlet></router-outlet>
  </div>
</div>
