import { Component, Input } from '@angular/core';
import { TTranslationKey } from 'src/app/project/features/translate/types';

@Component({
  selector: 'pp-limit-bar',
  templateUrl: './limit-bar.component.html',
  styleUrls: ['./limit-bar.component.scss'],
})
export class LimitBarComponent {
  @Input() ppCurrentValue: number;
  @Input() ppMaxValue: number;
  @Input() ppTitleKey: TTranslationKey;
  @Input() ppUnlimited: boolean;
}
