import { TFilters } from '../site-filter.model';

export function compareFilterShowPointsWithoutLocation(
  firstFilter: TFilters,
  secondFilter: TFilters,
): boolean {
  const location1 = firstFilter.showPointsWithoutLocation;
  const location2 = secondFilter.showPointsWithoutLocation;

  return location1 === location2;
}
