import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { UpdatePoint, UpdatePoints } from './points.actions';

import { ActiveService } from '../../services/active/active.service';
import { ResponseErrorService } from '../errors/response-error.service';

import { CommentApiProviderService, PointsApiProviderService } from '@core/api';
import { TPointUpdate } from '@project/view-models';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TNewComment } from '../../data-providers/api-providers/comment-api-provider/comment-requests.model';
import { TUpdatePointsRequest } from '../../data-providers/api-providers/points-api-provider/points-requests.model';
import { logEventInGTAG } from '../../services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from '../../services/analytics/google-analytics.consts';
import { EStatus } from '../../shared/enums/status.enum';
import { TBulkCustomFieldsExtended } from '../custom-fields/bulk-custom-fields-modal/bulk-custom-fields.model';
import { ECustomFieldType } from '../custom-fields/custom-field-types-enums';
import { logErrorInSentry } from '../errors/response-error';
import { SiteTablePointsService } from '../site/site-table/site-table-points.service';
import { TCommentData } from './comment-response.model';
import { PointsGenerateService } from './point-generate/points-generate.service';
import { TPoint } from './points.model';
import { EPriority } from './priorities';

@Injectable({
  providedIn: 'root',
})
export class PointsUpdateService {
  constructor(
    private store: Store,
    private responseErrorService: ResponseErrorService,
    private activeService: ActiveService,
    private commentApiProviderService: CommentApiProviderService,
    private pointsApiProviderService: PointsApiProviderService,
    private pointsGenerateService: PointsGenerateService,
    private siteTablePointsService: SiteTablePointsService,
  ) {}

  updatePoints(
    pointIds: string[],
    changes: Record<string, unknown>,
    mentions: string[] = [],
  ): Observable<null> {
    const body: TUpdatePointsRequest = {
      pointIds,
      ...changes,
      mentions,
    };

    return this.pointsApiProviderService.updatePoints(body).pipe(
      map((response) => {
        const points = response.map((pointResponse) =>
          this.pointsGenerateService.generatePoint(pointResponse),
        );

        this.store.dispatch(
          new UpdatePoints({
            changedPoints: points,
          }),
        );

        this.siteTablePointsService.updateTablePoints(points);

        return null;
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  updatePointsStatus(pointIds: string[], status: EStatus): Observable<null> {
    return this.updatePoints(pointIds, { status }).pipe(
      catchError((error) => {
        logErrorInSentry(error);

        return throwError(error);
      }),
    );
  }

  updatePointsFlag(pointIds: string[], flagged: boolean): Observable<null> {
    return this.updatePoints(pointIds, { flagged }).pipe(
      catchError((error) => {
        logErrorInSentry(error);

        return throwError(error);
      }),
    );
  }

  updatePointsPriority(pointIds: string[], priority: EPriority): Observable<null> {
    return this.updatePoints(pointIds, { priority }).pipe(
      catchError((error) => {
        logErrorInSentry(error);

        return throwError(error);
      }),
    );
  }

  updatePointsTags(pointIds: string[], tags: string[]): Observable<null> {
    return this.updatePoints(pointIds, { tags }).pipe(
      catchError((error) => {
        logErrorInSentry(error);

        return throwError(error);
      }),
    );
  }

  updatePointsAssignees(pointIds: string[], assignees: string[]): Observable<null> {
    return this.updatePoints(pointIds, { assignees }).pipe(
      catchError((error) => {
        logErrorInSentry(error);

        return throwError(error);
      }),
    );
  }

  updatePointsCustomFields(
    pointIds: string[],
    customFields: TBulkCustomFieldsExtended,
    mentions?: string[],
  ): Observable<null> {
    const customFieldsMap = Object.keys(customFields)
      .filter((key) => {
        if (customFields[key].value !== undefined && customFields[key].value !== null) {
          return true;
        }

        return false;
      })
      .map((key) => {
        if (customFields[key].type === ECustomFieldType.LIST) {
          return { [key]: customFields[key].idOfChosenElement.toString() };
        } else if (customFields[key].type === ECustomFieldType.NUMBERS) {
          return { [key]: customFields[key].value.replace(/,/g, '') };
        } else if (customFields[key].type !== ECustomFieldType.RICHTEXT) {
          return { [key]: customFields[key].value.toString() };
        } else {
          return { [key]: customFields[key].value };
        }
      });

    return this.updatePoints(pointIds, { customFieldsMap }, mentions).pipe(
      catchError((error) => {
        logErrorInSentry(error);

        return throwError(error);
      }),
    );
  }

  addComment(_id: string, pointComment: TNewComment): Observable<TCommentData> {
    return this.commentApiProviderService
      .addComment(_id, pointComment)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }

  updatePointField(_id: string, body: TPointUpdate): Observable<TPoint> {
    if (body.pins) {
      logEventInGTAG(EGoogleEventSite.SITE__POINT__PIN_CHANGE, {
        event_category: EGoogleEventCategory.SITE,
      });
    }
    return this.pointsApiProviderService
      .updatePointField(_id, body)
      .pipe(
        map((response) => {
          const point = this.pointsGenerateService.generatePoint(response);

          this.store.dispatch(
            new UpdatePoint({
              workspaceId: point.workspaceRef.id,
              point,
            }),
          );

          this.siteTablePointsService.updateTablePoint(point);

          return point;
        }),
      )
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }
}
