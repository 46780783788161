import { EBasicField, EBasicFieldShort } from 'src/app/project/shared/enums/basic-fields-enums';
import { TColumn } from '../../site/site-table/columns/column.model';

export function getOrderedBy(sorting: TColumn[]): string {
  if (sorting[0]) {
    switch (sorting[0].name) {
      case EBasicField.PRIORITY:
      case EBasicFieldShort.PRIORITY:
        return 'priority';
      case EBasicField.STATUS:
      case EBasicFieldShort.STATUS:
        return 'status';
      case EBasicField.SEQUENCE_NUMBER:
      case EBasicFieldShort.SEQUENCE_NUMBER:
        return 'id';
      case EBasicField.TITLE:
        return 'title';
      case EBasicField.TAGS:
        return 'tags';
      case EBasicField.CREATED:
        return 'created';
      case EBasicField.UPDATED:
        return 'updated';
      case EBasicField.CREATED_BY:
        return 'createdBy';
      case EBasicField.ASSIGNEES:
        return 'assignees';
      case EBasicField.DESCRIPTION:
        return 'description';
      case EBasicField.FLAGGED:
      case EBasicFieldShort.FLAGGED:
        return 'flag';
      default:
        return 'updated'; // TODO Real CF name, API generates crazy stuff when it doesn't like the name
    }
  }

  return 'updated';
}
