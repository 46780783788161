<div class="formulaOperators">
  {{ 'operators' | ppTranslate }}:

  <pp-formula-piece
    class="formulaOperator"
    *ngFor="let operator of operators"
    [ppFormulaPiece]="operator"
    (click)="addOperator(operator)"
  ></pp-formula-piece>
</div>
