import WebFont from 'webfontloader';
import { TWhiteLabelStore } from '../white-label.model';
import { EWhiteLabelFont } from '../white-label-fonts.enum';

export function setFontFamily(themeWrapper: HTMLBodyElement, whiteLabel: TWhiteLabelStore): void {
  if (whiteLabel.data.fontFamily === EWhiteLabelFont.FUTURA_PT) {
    // Not a WebFont, but available by default anyway
    themeWrapper.style.setProperty('--fontFamily', 'FuturaPTBook');

    return;
  }

  const getFontName = (fontName: string): string => {
    const fontNameArray = fontName.match(/'(.*?)'/);
    return fontNameArray ? fontNameArray[1] : fontName;
  };

  const fontFamily = getFontName(whiteLabel.data.fontFamily).replace(/ /g, '+');

  WebFont.load({
    fontloading: () => {},
    fontactive: (familyName) => {
      themeWrapper.style.setProperty('--fontFamily', familyName);
    },
    google: {
      families: [fontFamily],
    },
  });
}
