<pp-grid class="settingsGrid__item--profile" [ppOptions]="{ bodyPadding: true }">
  <ng-container title> {{ 'profile' | ppTranslate }} </ng-container>

  <ng-container body>
    <dl>
      <dt class="settingsSection__label">{{ 'email' | ppTranslate }}</dt>
      <dd class="settingsSection__form-item">
        <div class="settingsProfile__email">
          <span
            class="settingsProfile__emailText"
            ppTooltip
            [ppTrimTooltip]="true"
            [ppTitle]="user.email"
          >
            {{ user.email }}
          </span>

          <pp-button
            ppId="changeEmailBtn"
            data-m-target="Change email button"
            ppStyle="primary"
            ppSize="small"
            (ppAction)="editEmail()"
          >
            {{ 'update_email' | ppTranslate }}
          </pp-button>
        </div>
      </dd>

      <dt class="settingsSection__label">{{ 'name' | ppTranslate }}</dt>
      <dd class="settingsSection__form-item">
        <form (ngSubmit)="tryUpdateUserName()">
          <input
            #userNameInput
            data-m-target="Displayed name input"
            class="input input-border-bottom settingsProfile__acc"
            name="settings-name"
            type="text"
            [(ngModel)]="userName"
            (keydown.enter)="tryUpdateUserName()"
          />

          <pp-button
            ppId="updateUsernameBtn"
            ppSize="small"
            [ppProcess]="updatingUserName"
            (ppAction)="tryUpdateUserName()"
          >
            {{ 'save' | ppTranslate }}
          </pp-button>
        </form>
      </dd>

      <dt class="settingsSection__label">{{ 'password' | ppTranslate }}</dt>
      <dd class="settingsSection__form-item">
        <div class="settingsProfile__password">
          <span>
            &#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;
          </span>

          <pp-button
            ppId="changePasswordBtn"
            data-m-target="Change password button"
            ppStyle="primary"
            ppSize="small"
            (ppAction)="editPassword()"
          >
            {{ 'change_password' | ppTranslate }}
          </pp-button>
        </div>
      </dd>

      <pp-user-settings-profile-two-factor-authorization
        [ppUser]="user"
      ></pp-user-settings-profile-two-factor-authorization>
    </dl>
  </ng-container>
</pp-grid>
