<section #dragenter class="containerOne">
  <div #dropzone class="dropzone">
    <label
      class="optionsSelect__xls-label"
      for="excelUpload"
      [class.hidden]="ppFileLoaded || ppProcessing"
    >
      <div class="uploadDropzone__label-top">
        <img [src]="EIconPath.INTERACTION_UPLOAD" />

        {{ 'click_to_upload_site_file' | ppTranslate }}
      </div>

      <p class="uploadDropzone__or">- {{ 'or' | ppTranslate }} -</p>

      <p class="uploadDropzone__label-bottom">
        {{ 'drag_and_drop_here' | ppTranslate }}
      </p>
    </label>

    <label class="optionsSelect__xls-label" [class.hidden]="!ppFileLoaded || ppProcessing">
      <p class="uploadDropzone__file">
        {{ ppFileName }}

        <img [src]="EIconPath.INTERACTION_DELETE_X" (click)="removeFile()" />
      </p>
    </label>

    <app-spinner class="importTag__spinner" *ngIf="ppProcessing"></app-spinner>

    <pp-upload ppId="excelUpload" (ppUpload)="importFileTags($event)"></pp-upload>
  </div>
</section>
