import { Component, Input, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { UpdatePointCustomField } from 'src/app/project/modules/points/points.actions';
import { TPoint } from 'src/app/project/modules/points/points.model';

import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { PointsUpdateService } from 'src/app/project/modules/points/points-update.service';
import { PointActivityService } from '../../point-timeline/point-activity.service';
import { PointFieldsService } from '../point-fields.service';

import { Instance } from 'flatpickr/dist/types/instance';
import { of, Subject, timer } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { CustomTableService } from 'src/app/project/modules/site/site-table/custom-table/custom-table.service';
import { getTimeAsUtcMidday } from '../../../../../../core/helpers/date/date';
import { EIconPath } from '../../../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-point-fields-date',
  templateUrl: './point-fields-date.component.html',
  styleUrls: ['./point-fields-date.component.scss', '../point-fields.component.scss'],
})
export class PointFieldsDateComponent implements OnDestroy {
  @Input() ppWorkspaceId: string;
  @Input() ppPointId: string;
  @Input() ppFieldId: string;
  @Input() ppFieldLabel: string;
  @Input() ppFieldValue: string | number;
  @Input() ppNew: boolean;
  @Input() ppCanEdit: boolean;

  private readonly destroy$ = new Subject<void>();

  updating = false;
  success = false;
  error = false;
  focused = false;
  EIconPath = EIconPath;
  datepicker: Instance;

  private successTimerMs = 2500;

  constructor(
    private store: Store<{ points: TPoint[] }>,
    private promptService: PromptService,
    private pointFieldsService: PointFieldsService,
    private pointActivityService: PointActivityService,
    private sitePointFilterService: SitePointFilterService,
    private pointsUpdateService: PointsUpdateService,
    private translationPipe: TranslationPipe,
    private customTableService: CustomTableService,
  ) {}

  ngOnDestroy() {
    this.destroy$.next();
  }

  setDatepicker(datepicker: Instance): void {
    this.datepicker = datepicker;
  }

  clearCustomField(): void {
    this.ppFieldValue = null;
    this.updateField();
  }

  updateDate(dates: Date[]): void {
    if (!dates) {
      this.clearCustomField();

      return;
    }

    this.ppFieldValue = getTimeAsUtcMidday(dates[0]);
    this.updateField();
  }

  updateField(): void {
    const _id = this.ppPointId;
    const value = this.ppFieldValue;
    const workspaceId = this.ppWorkspaceId;
    const customFieldId = this.ppFieldId;

    if (this.ppNew) {
      this.updateFieldForNewPoint(
        this.ppPointId,
        this.ppFieldValue,
        this.ppWorkspaceId,
        this.ppFieldId,
      );

      return;
    }
    const customField = this.pointFieldsService.createCustomField2(
      this.ppFieldId,
      this.ppFieldValue ? this.ppFieldValue.toString() : null,
    );

    this.updating = true;

    this.pointsUpdateService
      .updatePointField(_id, { customFieldsMap: customField })
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          const promptText = this.translationPipe.transform('prompt_point_date_update');

          this.sitePointFilterService.filterPoints({ _keepScrollPosition: true });
          this.promptService.showSuccess(promptText);
          this.pointActivityService.refreshTimeline(this.ppWorkspaceId, _id);

          this.success = true;

          timer(this.successTimerMs).subscribe(() => {
            this.success = false;
          });
        }),
        catchError((error) => {
          this.pointFieldsService.showUpdatePointFieldError(error);

          this.error = true;

          return of();
        }),
        finalize(() => {
          this.updating = false;
        }),
      )
      .subscribe();
  }

  private updateFieldForNewPoint(
    pointId: string,
    fieldValue: string | number,
    workspaceId: string,
    customFieldId: string,
  ): void {
    this.store.dispatch(
      new UpdatePointCustomField({
        workspaceId: workspaceId,
        pointId: pointId,
        customFieldId: customFieldId,
        customFieldValue: fieldValue,
      }),
    );
  }
}
