import { TPoint } from '../../../points/points.model';
import { TColumn } from '../columns/column.model';
import { sortText } from './sort-text';
import { sortTitle } from './sort-title';
import { sort } from './sorting';

export function sortCreatedBy(
  firstPoint: TPoint,
  secondPoint: TPoint,
  sortColumns: Partial<TColumn>[],
  index: number,
): number {
  const valueA = firstPoint.header.createdBy.caption;
  const valueB = secondPoint.header.createdBy.caption;

  const result = sortText(valueA, valueB, sortColumns, index, {});

  if (result) {
    return result;
  }

  if (sortColumns[index + 1]) {
    return sort(firstPoint, secondPoint, sortColumns, index + 1);
  } else {
    return sortTitle(firstPoint, secondPoint, sortColumns, index);
  }
}
