<div class="twoFactorAuthModePicker">
  <pp-two-factor-authentication-mode-picker-option
    [ppText]="'2fa_setup_sms' | ppTranslate"
    [ppIcon]="EIconPath.ICON_MISC_PHONE_18"
    [ppSelected]="ppValue === ETwoFactorAuthenticationChannel.SMS"
    (ppSelect)="selectOption(ETwoFactorAuthenticationChannel.SMS)"
  ></pp-two-factor-authentication-mode-picker-option>

  <pp-two-factor-authentication-mode-picker-option
    [ppSelected]="ppValue === ETwoFactorAuthenticationChannel.WHATSAPP"
    [ppIcon]="EIconPath.ICON_MISC_WHATSAPP_18"
    [ppText]="'2fa_setup_whatsapp' | ppTranslate"
    (ppSelect)="selectOption(ETwoFactorAuthenticationChannel.WHATSAPP)"
  ></pp-two-factor-authentication-mode-picker-option>
</div>
