import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClearFilterService } from '../../clear-filter-service/clear-filter.service';
import { TFilters } from '../../site-filter.model';

@Component({
  selector: 'pp-filter-field-location',
  templateUrl: './filter-field-location.component.html',
  styleUrls: ['../../site-filter-dropdown/site-filter-dropdown.component.scss'],
})
export class FilterFieldLocationComponent {
  @Input() ppFilters: TFilters;
  @Output() ppApply = new EventEmitter();

  constructor(private clearFilterService: ClearFilterService) {}

  togglePointsWithoutLocation(): void {
    this.ppFilters.showPointsWithoutLocation = !this.ppFilters.showPointsWithoutLocation;

    this.applyFilters();
  }

  togglePointsWithLocation(): void {
    this.ppFilters.showPointsWithLocation = !this.ppFilters.showPointsWithLocation;

    this.applyFilters();
  }

  clearLocation(): void {
    this.ppFilters.showPointsWithLocation = this.clearFilterService.clearPointsWithLocation();
    this.ppFilters.showPointsWithoutLocation = this.clearFilterService.clearPointsWithoutLocation();

    this.applyFilters();
  }

  applyFilters(): void {
    this.ppApply.emit();
  }
}
