import { Component, Input } from '@angular/core';
import { uuid } from '@core/helpers';
import { finalize } from 'rxjs';
import { AccountService } from 'src/app/project/modules/account/account-service/account.service';

@Component({
  selector: 'pp-account-user-management-export',
  templateUrl: './account-user-management-export.component.html',
  styleUrls: ['./account-user-management-export.component.scss'],
})
export class AccountUserManagementExportComponent {
  @Input() ppAccountId: string;

  processingExport = false;
  buttonId = uuid();

  constructor(private accountService: AccountService) {}

  exportAccountUsers(): void {
    if (this.processingExport) {
      return;
    }

    this.processingExport = true;

    this.accountService
      .exportAccountUsers(this.ppAccountId)
      .pipe(
        finalize(() => {
          this.processingExport = false;
        }),
      )
      .subscribe();
  }
}
