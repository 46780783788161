<div class="accountPlaceholder">
  <header class="accountPlaceholder__header">
    <div class="accountPlaceholder__left-header"></div>
    <div class="accountPlaceholder__right-header"></div>
  </header>

  <pp-account-settings-placeholder-paragraph></pp-account-settings-placeholder-paragraph>
  <pp-account-settings-placeholder-paragraph></pp-account-settings-placeholder-paragraph>
  <pp-account-settings-placeholder-paragraph></pp-account-settings-placeholder-paragraph>
</div>
