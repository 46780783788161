import { Component, OnInit } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TAssetLabel } from '../../../asset-service/asset.consts';
import { NewAssetService } from '../new-asset.service';

@Component({
  selector: 'pp-new-asset-labels',
  templateUrl: './new-asset-labels.component.html',
  styleUrls: ['./new-asset-labels.component.scss'],
})
export class NewAssetLabelsComponent implements OnInit {
  assetLabels: TAssetLabel[];
  EIconPath = EIconPath;

  private randomColors = [
    '#F07300', //ORANGE
    '#DDBB06', //YELLOW
    '#8EBF00', //SAGE
    '#00D789', //GREEN
    '#0099D0', //BLUE
    '#C000F0', //PURPLE
    '#FF69B4', //PINK
    '#D00505', //RED
  ];

  constructor(private newAssetService: NewAssetService) {}

  ngOnInit(): void {
    this.assetLabels = this.newAssetService.getNewAsset().labels;
  }

  updateAssetLabels(): void {
    this.newAssetService.setAssetLabels(this.assetLabels);
  }

  addNewLabel(): void {
    const randomColor = this.pickRandomColor();

    this.assetLabels.push({ name: '', color: randomColor, assigneeId: null });
    this.newAssetService.setAssetLabels(this.assetLabels);
  }

  deleteLabel(label: TAssetLabel): void {
    this.assetLabels = this.assetLabels.filter((assetLabel) => assetLabel !== label);
    this.updateAssetLabels();
  }

  private pickRandomColor(): string {
    const uniqueColors = this.randomColors.filter(
      (color) => !this.assetLabels.some((label) => label.color === color),
    );

    const colorsToPickFrom = uniqueColors.length ? uniqueColors : this.randomColors;
    const randomColor = colorsToPickFrom[Math.floor(Math.random() * colorsToPickFrom.length)];

    return randomColor;
  }
}
