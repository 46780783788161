import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  ConfirmModalComponent,
  TConfirmModalParams,
} from 'src/app/project/components/confirm-modal/confirm-modal.component';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { VolyIntegrationService } from 'src/app/project/modules/integrations/voly-integration.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import {
  COST_CENTRE_CF_NAME,
  EXPENDITURE_CF_NAME,
} from '../site-settings-integrations-custom-fields.consts';
import { TIntegrationField } from 'src/app/project/modules/integrations/utils/integrations.consts';

@Component({
  selector: 'pp-site-settings-integrations-custom-field-row',
  templateUrl: './site-settings-integrations-custom-field-row.component.html',
  styleUrl: './site-settings-integrations-custom-field-row.component.scss',
})
export class SiteSettingsIntegrationsCustomFieldRowComponent implements OnInit {
  @Input() ppDisabled: boolean;
  @Input() ppField: TIntegrationField;
  @Input() ppWorkspaceId: string;

  disabled: boolean = false;
  toggleError = false;

  EIconPath = EIconPath;
  tooltipText = '';
  formulasIntegrationIsUsed = [];

  constructor(
    private volyIntegrationService: VolyIntegrationService,
    private translationPipe: TranslationPipe,
    private customFieldsService: CustomFieldsService,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    if (!this.ppField.subList) {
      this.tooltipText = this.translationPipe.transform('integration_custom_field_invoice_tooltip');
      this.disabled = this.ppDisabled;
    } else {
      switch (this.ppField.fieldName) {
        case EXPENDITURE_CF_NAME:
          this.tooltipText = this.translationPipe.transform(
            'integration_custom_field_other_tooltip',
            {
              customField: this.translationPipe.transform('expenditure_categories'),
            },
          );
          break;
        case COST_CENTRE_CF_NAME:
          this.tooltipText = this.translationPipe.transform(
            'integration_custom_field_other_tooltip',
            {
              customField: this.translationPipe.transform('cost_centres'),
            },
          );
      }

      this.disabled = this.ppDisabled || this.ppField.subList.length === 0;
    }

    this.canDisableCustomField();
  }

  canDisableCustomField(): void {
    const customFields = this.customFieldsService.getCustomFields()[this.ppWorkspaceId];
    const integrationOperand = '{' + this.ppField.customFieldId + '}';

    Object.keys(customFields).map((cf) => {
      if (
        customFields[cf].type === ECustomFieldType.FORMULA &&
        customFields[cf].formula.includes(integrationOperand)
      ) {
        this.formulasIntegrationIsUsed.push(customFields[cf].label);
      }
    });

    if (this.formulasIntegrationIsUsed.length > 0) {
      this.toggleError = true;
    }
  }

  toggleField(field: TIntegrationField): void {
    if (this.toggleError) {
      this.handleIntegrationWarningModal(this.formulasIntegrationIsUsed);
    } else {
      field.active = !field.active;

      this.volyIntegrationService
        .changeCustomFieldVisibility(this.ppWorkspaceId, field.customFieldId, field.active)
        .subscribe();
    }
  }

  private handleIntegrationWarningModal(formulaFields: string[]): void {
    const message = this.translationPipe.transform('formula_disable_integration_warning', {
      formulaCustomFieldNames: formulaFields.toString(),
      volyIntegrationFieldName: this.ppField.fieldName,
    });

    const modalData: TConfirmModalParams = {
      message: message,
      heading: this.translationPipe.transform('warning'),
      hideCancel: true,
      confirmText: this.translationPipe.transform('ok'),
    };

    this.modalService.setData(modalData);
    this.modalService.showModal(ConfirmModalComponent, {
      blur: false,
    });
  }
}
