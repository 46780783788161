import { EStatus } from 'src/app/project/shared/enums/status.enum';
import { TFilters } from '../site-filter.model';

export function isStatusDefault(filters: TFilters): boolean {
  const appliedStatusLabels = [];
  const defaultStatusLabels = [
    EStatus.OPEN,
    EStatus.IN_PROGRESS,
    EStatus.TO_REVIEW,
    EStatus.ONHOLD,
  ];
  let defaultStates = true;

  filters.status.forEach((status) => {
    if (status.value) {
      appliedStatusLabels.push(status.label);
    }
  });

  if (appliedStatusLabels.length !== defaultStatusLabels.length) {
    return false;
  }

  defaultStatusLabels.forEach((defaultStatusLabel) => {
    if (!appliedStatusLabels.includes(defaultStatusLabel)) {
      defaultStates = false;
    }
  });

  return defaultStates;
}
