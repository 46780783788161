<li
  class="dashletSiteacc__site"
  (click)="toggleWorkspace()"
  ppTooltip
  [ppTitle]="
    disabled
      ? ('can_only_select_x_sites'
        | ppTranslate
          : {
              count: MAX_STACKED_STATUS_WORKSPACES_LENGTH,
            })
      : ''
  "
>
  <pp-checkbox
    [ppChecked]="ppSiteRange.workspaceIds.includes(ppWorkspace.workspaceId)"
    [ppDisabled]="disabled"
  ></pp-checkbox>

  <p class="dashletSiteacc__site-label">
    {{ ppWorkspace?.siteName }}
  </p>
</li>
