import clone from 'lodash/clone';
import { cloneDeep } from 'lodash';

import { TAnyFunction } from '@core/helpers';

import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
  AfterViewInit,
  OnInit,
} from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, timer } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { UpdatePointPin } from '../../points.actions';

import {
  ConfirmModalComponent,
  TConfirmModalParams,
} from 'src/app/project/components/confirm-modal/confirm-modal.component';
import { PointPlanClearAllModalComponent } from './point-plan-clear-all-modal/point-plan-clear-all-modal.component';

import { PointPlanService } from './point-plan.service';
import { TPlanData, PlanDataService } from '../../../plan/plan-data.service';
import { ModalService } from '../../../../components/modal/modal.service';
import { DeviceService } from 'src/app/core/services/device.service';
import { OfflineService } from 'src/app/project/modules/offline/offline.service';
import { PlanPointService } from '../../../plan/plan-point/plan-point.service';
import { PlanPinsService } from '../../../plan/plan-pins.service';
import { PlanService } from '../../../plan/plan.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { PointsService } from '../../points.service';
import { CookieService } from 'src/app/project/services/cookie.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';

import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';

import {
  GET_ACTIVE_PLAN,
  SET_ACTIVE_PLAN,
  SET_DRAWING_POLYGON,
} from 'src/app/project/modules/plan/plan.store';
import { PLAN_COOKIE_CLEAR_NAME } from '../../../../shared/constants/point.plan.constants';
import { PlanPointEventsService } from 'src/app/project/modules/plan/plan-point/plan-point-events.service';
import { PlanPointStylesService } from 'src/app/project/modules/plan/plan-point/plan-point-styles/plan-point-styles.service';
import { PlanPointVariablesService } from 'src/app/project/modules/plan/plan-point/plan-point-variables.service';
import { FeatureModifyService } from 'src/app/project/modules/plan/plan-point/feature-modify.service';
import { PolygonDrawService } from 'src/app/project/modules/plan/plan-point/polygon-draw.service';
import { PolygonChangeService } from 'src/app/project/modules/plan/plan-point/polygon-change.service';
import { PlanPointDragService } from 'src/app/project/modules/plan/plan-point/plan-point-drag.service';
import { EPlanPointMode } from 'src/app/project/shared/enums/plan-point-mode.enum';
import { NEW_POINT_ID } from '../../../../shared/constants/point.const';
import Feature from 'ol/Feature';
import { EPlanModule } from 'src/app/project/modules/plan/plan-module.enum';
import { getCrossbrowserEventPath } from 'src/app/core/helpers/compose-event-path';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { Polygon } from 'ol/geom';
import { Coordinate } from 'ol/coordinate';
import { checkClickedPolygon } from './utils/check-clicked-polygon';
import { isOverDeleteIcon } from './utils/delete-icon-checks';
import { getPolygonVerticeIndex } from './utils/get-polygon-vertice-index';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import {
  getPlanPointPinsCoordinates,
  getPlanPointPolygonsCoordinates,
} from './utils/plan-point-coordinates';
import { ClickOutsideHandler } from '@core/services';
import { isModalClicked } from 'src/app/project/shared/is-modal-clicked';
import { EIconPath } from '../../../../shared/enums/icons.enum';

export type TMouseEventPosition = Pick<MouseEvent, 'clientX' | 'clientY'>;

@Component({
  selector: 'pp-point-plan',
  templateUrl: './point-plan.component.html',
  styleUrls: ['./point-plan.component.scss'],
})
export class PointPlanComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('planElement', { static: false }) planElement: ElementRef;
  @ViewChild('deleteIcon', { static: false }) deleteIcon: ElementRef;

  @Input() ppWorkspaceId: string;
  @Input() ppPointId: string;
  @Input() ppCanEdit: boolean;
  @Input() ppFull: boolean;
  @Input() ppWidth = null;

  public plan: TPlanData = this.planDataService.getPlan();
  clickedCoordinates: [number, number] = null;
  featureClicked = this.planPointVariablesService.getFeatureClicked();

  activePlan = GET_ACTIVE_PLAN();
  updatingPlan = false;
  activeButtons = false;
  locationSaved = false;
  private showCloseModal = false;
  workspaces: TWorkspacesById;
  showingSaveWarningPrompt = false;
  oldCoordinates: number[] = [];
  private oldCoordinatesAdditional = null;
  private modifiedFeatureCoords: Coordinate[][] = null;
  private deletedFeatureCooords: Coordinate[][] = null;
  clickedVertice: number = null;
  clickedVerticeCoordinates: number[] = null;
  private clickedPolygon: number = null;
  private clickedPolygonIndex: number = null;
  private polygonMoveStartCoordinates: Coordinate[] = null;
  verticeToDelete: number = null;
  EPlanModule = EPlanModule;
  NEW_POINT_ID = NEW_POINT_ID;
  EIconPath = EIconPath;

  private isTouchDevice: boolean = this.deviceService.isTouchDevice();

  deleteIconVisible = false;
  private multiplePins = false;
  private promptShown = false;
  showingPrompt = false;
  showUndoDelete = false;
  mode: EPlanPointMode = this.planPointVariablesService.getMode();
  polygonClicked = false;
  polygonClickedCoordinates = null;
  clickedPinCoordinates = null;
  noLocation = false;
  EPlanPointMode = EPlanPointMode;
  planRendered = false;

  private workspaces$: Observable<TWorkspacesById>;

  private readonly destroy$ = new Subject<void>();

  private pointermoveListenerRef: TAnyFunction;
  private clickOutsideHandler: ClickOutsideHandler;
  private pinsUpdated$: Observable<void>;
  private cancelShowUndoDelete$ = new Subject<void>();
  private showUndoDeleteTimerMs: 2000;
  private locationSavedTimerMs = 2500;

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
    }>,
    private pointPlanService: PointPlanService,
    private planDataService: PlanDataService,
    private modalService: ModalService,
    private deviceService: DeviceService,
    private planPointService: PlanPointService,
    private offlineService: OfflineService,
    private planPinsService: PlanPinsService,
    private planService: PlanService,
    private activeService: ActiveService,
    private pointsService: PointsService,
    private translationPipe: TranslationPipe,
    private cookieService: CookieService,
    private promptService: PromptService,
    private sitePointFilterService: SitePointFilterService,
    private planPointEventsService: PlanPointEventsService,
    private planPointStylesService: PlanPointStylesService,
    private planPointVariablesService: PlanPointVariablesService,
    private featureModifyService: FeatureModifyService,
    private polygonDrawService: PolygonDrawService,
    private polygonChangeService: PolygonChangeService,
    private planPointDragService: PlanPointDragService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.planPointEventsService.translateStart$
      .pipe(takeUntil(this.destroy$))
      .subscribe((feature: Feature) => {
        this.modifiedFeatureCoords = (feature.getGeometry() as Polygon).getCoordinates();
        this.activeButtons = true;
        this.deleteIconVisible = true;
        this.showingPrompt = false;
        this.showUndoDelete = false;
        this.showCloseModal = true;
      });

    this.planPointEventsService.polygonVerticeClicked$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.onPolygonVerticeClicked(data);
      });

    this.planPointEventsService.featureClicked$
      .pipe(takeUntil(this.destroy$))
      .subscribe((clicked: boolean) => {
        if (clicked) {
          this.clickedPinCoordinates = cloneDeep(this.clickedCoordinates);
        } else {
          this.clickedPinCoordinates = null;
        }
      });

    this.planService.mapMoved$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.planPointVariablesService.setFeatureClicked(false);
      this.polygonClicked = false;
      this.clickedVertice = null;
    });

    this.planPointEventsService.polygonClicked$
      .pipe(takeUntil(this.destroy$))
      .subscribe((feature) => {
        this.onPolygonClicked(feature);
      });

    this.planPointEventsService.polygonVerticeTranslateStart$
      .pipe(takeUntil(this.destroy$))
      .subscribe((feature: Feature) => {
        this.polygonMoveStartCoordinates = (feature.getGeometry() as Polygon).getCoordinates()[0];

        this.hidePrompt();

        if ((feature.getGeometry() as any).flatCoordinates.length > 8) {
          const numberOfVertices = (feature.getGeometry() as any).flatCoordinates.length / 2;

          this.deleteIconVisible = numberOfVertices > 4;
        }

        this.clickedPolygon = this.getClickedPolygonIndex(feature);
      });

    this.planPointEventsService.polygonVerticeTranslateEnd$
      .pipe(takeUntil(this.destroy$))
      .subscribe((feature: Feature) => {
        this.verticeToDelete = getPolygonVerticeIndex(feature, this.polygonMoveStartCoordinates);
      });

    this.planPointEventsService.polygonCreated$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.activeButtons = true;
      this.showCloseModal = true;
      this.showingPrompt = false;
    });

    this.planService.selectionChange$.pipe(takeUntil(this.destroy$)).subscribe((mode) => {
      this.mode = mode;
      if (this.activePlan.active) {
        this.showingPrompt = true;
      }
    });

    this.planPointEventsService.translateEnd$
      .pipe(takeUntil(this.destroy$))
      .subscribe((feature: Feature) => {
        const _id = this.activeService.getActivePointId();
        const point = this.pointsService.findPoint(_id);

        feature.setStyle(this.planPointStylesService.createNormalStyle(point.priority));
      });
  }

  ngOnInit(): void {
    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.workspaces = workspaces;
    });
    this.pinsUpdated$ = this.planPinsService.pinsUpdated$.pipe(take(1), takeUntil(this.destroy$));
  }

  ngAfterViewInit() {
    this.planPointService.setDeleteElement(this.deleteIcon);

    if (this.planElement) {
      if (this.plan.point.pins) {
        this.multiplePins = this.plan.point.pins.length > 1;
      }

      this.clickOutsideHandler = new ClickOutsideHandler(
        this.planElement.nativeElement,
        this.destroy$,
        { mouseEventTypes: ['click'] }, // TODO Add overlay
      );
      this.clickOutsideHandler.caught$.subscribe((event) => {
        this.onClickOutside(event);
      });
    }

    this.pointermoveListenerRef = (event): void => this.pointermove(event);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  onPlanContainerRendered(): void {
    this.planRendered = true;
  }

  activatePlan(event: MouseEvent = null): void {
    const offline = this.offlineService.getOffline();

    this.clickedVertice = null;
    this.clickedVerticeCoordinates = null;
    this.polygonClickedCoordinates = null;

    if (event) {
      this.clickedCoordinates = [event.offsetX, event.offsetY];
    } else {
      this.clickedCoordinates = null;
    }

    if (!this.promptShown) {
      this.promptShown = true;
      this.showingPrompt = true;
    } else {
      this.showingPrompt = false;
    }

    this.pinsUpdated$.subscribe(() => {
      if (this.plan.point.pins) {
        this.multiplePins = false;

        if (
          this.oldCoordinatesAdditional &&
          this.oldCoordinatesAdditional.length !== this.plan.point.pins.length
        ) {
          this.activeButtons = true;
          this.showCloseModal = true;
        }

        this.plan.point.pins.forEach((pin) => {
          const coords = pin.getGeometry().getCoordinates();

          if (!Number.isNaN(coords[0])) {
            this.multiplePins = true;
          }
        });
      }

      if (this.oldCoordinates.length > 0) {
        this.activeButtons = true;
        this.showCloseModal = true;
      }

      if (this.plan.point.polygons) {
        if (this.activeButtons) {
          this.showCloseModal = true;
        }
      }
    });

    if (!this.activePlan.active) {
      this.saveNewMode(this.mode);
    }

    this.showingSaveWarningPrompt = false;

    if (!offline) {
      this.showCloseModal = false;

      if (this.plan.point.polygons?.length > 0) {
        this.pointermove(event);
      }

      if (this.plan.point.pins) {
        if (!this.oldCoordinatesAdditional) {
          this.oldCoordinatesAdditional = [];

          this.plan.point.pins.forEach((pin) => {
            this.oldCoordinatesAdditional.push({
              pin: pin,
              coordinates: pin.getGeometry().getCoordinates(),
            });
          });
        }

        this.plan.point.pins.forEach((pin) => {
          const matchingPin = this.oldCoordinatesAdditional.find((oldPin) => {
            const coordinatesAdditional = pin.getGeometry().getCoordinates();
            const oldCoordinates = oldPin.coordinates;

            const pinFound =
              Math.abs(coordinatesAdditional[0] - oldCoordinates[0]) < 0.001 &&
              Math.abs(coordinatesAdditional[1] - oldCoordinates[1]) < 0.001;

            return pinFound;
          });

          if (!matchingPin) {
            this.pointermove(event);
          }
        });

        this.oldCoordinatesAdditional = [];

        this.plan.point.pins.forEach((pin) => {
          this.oldCoordinatesAdditional.push({
            pin: pin,
            coordinates: pin.getGeometry().getCoordinates(),
          });
        });
      }

      this.clickOutsideHandler.enable();
      SET_ACTIVE_PLAN(true);
    }

    this.checkPointPinsOnUpdate();
  }

  deactivatePlan(): void {
    if (this.mode === EPlanPointMode.DRAW) {
      this.polygonDrawService.stopDrawingPolygon(true);
    }

    if (this.ppPointId === NEW_POINT_ID) {
      this.save();
    }

    this.promptShown = false;
    this.showCloseModal = false;
    this.clickedCoordinates = null;

    this.showUndoDelete = false;
    this.activeButtons = false;
    this.oldCoordinatesAdditional = null;
    this.showingPrompt = false;
    this.clickedVerticeCoordinates = null;
    this.clickedVertice = null;
    this.planPointVariablesService.setFeatureClicked(false);

    if (this.plan.instance.point) {
      this.plan.instance.point.un('pointermove', this.pointermoveListenerRef);
    }

    this.clickOutsideHandler.disable();
    SET_ACTIVE_PLAN(false);
    SET_DRAWING_POLYGON(false);
    this.activePlan.drawingPolygon = false;
    this.planPointVariablesService.setChangedFeature(null);

    this.polygonClicked = false;
    this.clickedVertice = null;

    this.featureModifyService.removeModifyInteraction();
  }

  save(): void {
    this.showingSaveWarningPrompt =
      (this.mode === EPlanPointMode.POLYGON || this.mode === EPlanPointMode.DRAW) &&
      this.plan.point.polygons?.length === 0;

    this.updatingPlan = true;

    this.savePoint();
  }

  savePoint(): void {
    const pins = getPlanPointPinsCoordinates(this.plan.point.pins);
    const polygons = getPlanPointPolygonsCoordinates(this.plan.point.polygons);

    this.pointPlanService
      .savePoint(pins, polygons)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.updatingPlan = false;
          this.locationSaved = true;
          this.showCloseModal = false;
          this.showingSaveWarningPrompt = false;

          timer(this.locationSavedTimerMs).subscribe(() => {
            this.locationSaved = false;
          });

          if (this.ppPointId !== NEW_POINT_ID) {
            const prompt = this.translationPipe.transform('prompt_point_location_update');

            this.promptService.showSuccess(prompt);

            this.deactivatePlan();
          } else {
            this.store.dispatch(
              new UpdatePointPin({
                workspaceId: this.ppWorkspaceId,
                _id: this.ppPointId,
                pins: pins,
                polygons: polygons,
              }),
            );

            this.sitePointFilterService.filterPoints();
          }
        }),
      )
      .subscribe();
  }

  cancel(): void {
    this.showCloseModal = false;

    this.deactivatePlan();
  }

  pointermove(event: MouseEvent): void {
    const newPosition = {
      clientX: event.clientX,
      clientY: event.clientY,
    };

    this.checkDeleteIcon(newPosition);
  }

  checkDeleteIcon(event: TMouseEventPosition): void {
    const deleteIcon = this.deleteIcon.nativeElement as HTMLElement;

    if (deleteIcon && event) {
      const isOverBinIcon = isOverDeleteIcon(event, deleteIcon, this.isTouchDevice);

      if (isOverBinIcon) {
        const isVertice =
          this.plan.point.polygons?.length > 0 &&
          this.deleteIconVisible &&
          this.verticeToDelete !== null &&
          this.mode !== EPlanPointMode.POINT;

        if (isVertice) {
          this.deleteVertice(this.verticeToDelete);
        } else {
          this.deleteFeature();
        }
      }
    }

    this.deleteIconVisible = false;
    this.activeButtons = true;
    this.showCloseModal = true;

    this.plan.instance.point.un('pointermove', this.pointermoveListenerRef);
  }

  onTouchEnd(event: TouchEvent): void {
    if (this.activePlan.active) {
      const newPosition = {
        clientX: event.changedTouches[0].clientX,
        clientY: event.changedTouches[0].clientY,
      };

      this.checkDeleteIcon(newPosition);
    }
  }

  deleteFeature(fromPopup: boolean = false): void {
    const feature = this.planPointVariablesService.getChangedFeature();

    this.clickedCoordinates = null;
    this.planPointVariablesService.setFeatureClicked(false);

    if (feature && (this.deleteIconVisible || fromPopup)) {
      this.showUndoDelete = true;
      this.activeButtons = true;
      this.showCloseModal = true;
      this.deletedFeatureCooords = clone(this.modifiedFeatureCoords);

      this.planPinsService.deleteFeature(feature);

      this.multiplePins = this.plan.point.pins.length > 1;
      this.planPointService.setDeleteIconVisibility(false);

      if (this.plan.point.pins?.length === 0 && this.plan.point.polygons?.length === 0) {
        this.noLocation = true;
      }

      this.cancelShowUndoDelete$.next();

      timer(this.showUndoDeleteTimerMs)
        .pipe(
          takeUntil(this.cancelShowUndoDelete$),
          tap(() => {
            this.showUndoDelete = false;
          }),
        )
        .subscribe();

      this.checkPointPinsOnUpdate();
    }
  }

  deletePolygon(): void {
    if (typeof this.clickedPolygonIndex === 'number') {
      this.planPinsService.deletePolygon(this.clickedPolygonIndex);
    }

    this.clickedPolygonIndex = null;
    this.polygonClicked = false;
    this.planService.selectionChange$.next(EPlanPointMode.POLYGON);
    this.showingPrompt = false;
    this.activeButtons = true;

    this.featureModifyService.removeModifyInteraction();

    if (this.plan.point.polygons[0]) {
      this.polygonChangeService.polygonChangeInteraction();
    }

    this.planPinsService.resetLayer(EPlanModule.POINT, EPlanPointMode.POLYGON);

    this.noLocation = this.plan.point.polygons.length === 0 && this.plan.point.pins.length === 0;

    this.checkPointPinsOnUpdate();
  }

  deleteArea(): void {
    this.polygonClicked = false;
    const modalData: TConfirmModalParams = {
      message: this.translationPipe.transform('confirm_area_delete'),
      heading: this.translationPipe.transform('delete_area'),
      redButton: true,
      confirmText: this.translationPipe.transform('delete_area'),
    };

    this.modalService.setData(modalData);

    this.modalService.showModal(ConfirmModalComponent, {
      blur: false,
      closeWarning: true,
      callback: () => {
        this.deletePolygon();
      },
    });
  }

  hidePrompt(): void {
    this.showingPrompt = false;
  }

  undoDelete(): void {
    this.showUndoDelete = false;

    this.planPointService.restoreDeletedFeature(this.deletedFeatureCooords);
  }

  switchMode(event: Event, mode: EPlanPointMode): void {
    this.planPointVariablesService.setFeatureClicked(false);
    this.polygonClicked = false;
    this.clickedVertice = null;

    this.featureModifyService.removeModifyInteraction();

    this.saveNewMode(mode);

    event.preventDefault();
    event.stopPropagation();
  }

  saveNewMode(mode: EPlanPointMode, showPrompt: boolean = true): void {
    this.planService.selectionChange$.next(mode);

    this.planPinsService.resetLayer(EPlanModule.POINT, mode);

    if (showPrompt) {
      this.showingPrompt = true;
    }
  }

  hideSaveWarningPrompt(): void {
    this.showingSaveWarningPrompt = false;
  }

  onPolygonClicked(feature: Feature): void {
    if (this.activePlan.drawingPolygon) {
      return;
    }

    if (feature) {
      this.clickedPolygonIndex = this.getClickedPolygonIndex(feature);
      this.polygonClickedCoordinates = cloneDeep(this.clickedCoordinates);
      this.polygonClicked = true;
    } else {
      this.clickedPolygonIndex = null;
      this.polygonClickedCoordinates = null;
      this.polygonClicked = false;
    }
  }

  onPolygonVerticeClicked(data): void {
    const coordinate = data ? data.coordinates : null;
    const index = data ? data.index : null;

    if (typeof index === 'number' && this.plan.point.polygons[index]) {
      const numberOfVertices = this.plan.point.polygons[index]
        .getGeometry()
        .getCoordinates()[0].length;

      if (numberOfVertices > 4) {
        if (coordinate) {
          const errorMargin = 20;
          const pixel = this.plan.instance.point.getPixelFromCoordinate(coordinate);

          this.plan.point.polygons[index]
            .getGeometry()
            .getCoordinates()[0]
            .forEach((vertice, _index) => {
              const verticePixel = this.plan.instance.point.getPixelFromCoordinate(vertice);

              if (
                Math.abs(pixel[0] - verticePixel[0]) < errorMargin &&
                Math.abs(pixel[1] - verticePixel[1]) < errorMargin
              ) {
                this.polygonClicked = false;

                this.clickedVertice = _index;
                this.clickedPolygon = index;
                this.clickedVerticeCoordinates = [vertice[0], vertice[1]];
              }
            });
        } else {
          this.clickedVerticeCoordinates = null;
          this.clickedVertice = null;
        }
      }
    }
  }

  deleteVertice(index: number = null): void {
    if (index !== -1 && index !== null) {
      this.planPinsService.deleteVertice(index, this.clickedPolygon);
    } else {
      this.planPinsService.deleteVertice(this.clickedVertice, this.clickedPolygon);
    }

    this.clickedVerticeCoordinates = null;
    this.clickedVertice = null;

    this.planPinsService.resetLayer(EPlanModule.POINT, EPlanPointMode.POLYGON);
    this.planService.selectionChange$.next(EPlanPointMode.POLYGON);
    this.showingPrompt = false;
  }

  // So we can show "Create point without location" in new point modal and "Confirm" without location in plan confirm button
  checkPointPinsOnUpdate(): void {
    this.pinsUpdated$.subscribe(() => {
      const pins = getPlanPointPinsCoordinates(this.plan.point.pins);
      const polygons = getPlanPointPolygonsCoordinates(this.plan.point.polygons);

      if (this.ppPointId === NEW_POINT_ID) {
        this.pointPlanService.savePoint(pins, polygons).pipe(takeUntil(this.destroy$)).subscribe();

        this.store.dispatch(
          new UpdatePointPin({
            workspaceId: this.ppWorkspaceId,
            _id: this.ppPointId,
            pins,
            polygons,
          }),
        );
      } else {
        this.noLocation =
          this.plan.point.polygons?.length === 0 && this.plan.point.pins?.length === 0;
      }
    });
  }

  clearAll(): void {
    const cookie = this.cookieService.readCookie(PLAN_COOKIE_CLEAR_NAME);

    if (!cookie) {
      this.modalService.showModal(PointPlanClearAllModalComponent, {
        blur: false,
        closeWarning: true,
        callback: () => {
          this.removePinsAndPolygons();
        },
      });
    } else {
      this.removePinsAndPolygons();
    }
  }

  removePinsAndPolygons(): void {
    const mode = clone(this.mode);
    this.polygonDrawService.stopDrawingPolygon(false);

    this.plan.point.pins = [];
    this.plan.point.polygons = [];

    this.planPinsService.resetLayer(EPlanModule.POINT, this.mode);
    this.activeButtons = true;

    if (mode === EPlanPointMode.POLYGON) {
      this.saveNewMode(EPlanPointMode.DRAW, false);
    }
  }

  getClickedPolygonIndex(feature: Feature): number {
    const index = this.plan.point.polygons.findIndex((checkedFeature: Feature) =>
      checkClickedPolygon(feature, checkedFeature),
    );

    return index;
  }

  private onClickOutside(event: MouseEvent): void {
    const startedClickInside = this.planPointDragService.getStartedClickInside();

    if (!startedClickInside) {
      this.handleOutsideClick(event);
    }
  }

  private handleOutsideClick(event: MouseEvent): void {
    this.clickOutsideHandler.disable();
    const path = getCrossbrowserEventPath(event);

    if (isModalClicked(path)) {
      this.clickOutsideHandler.enable();

      return;
    }

    if (this.showCloseModal && this.ppPointId !== NEW_POINT_ID && this.ppCanEdit) {
      event.preventDefault();
      event.stopImmediatePropagation();

      const modalData: TConfirmModalParams = {
        message: this.translationPipe.transform(
          'are_you_sure_you_want_to_close_plan_without_saving',
        ),
        heading: this.translationPipe.transform('close_plan'),
        redButton: true,
        confirmText: this.translationPipe.transform('close_without_saving'),
      };

      this.modalService.setData(modalData);

      this.modalService.showModal(ConfirmModalComponent, {
        blur: false,
        callback: () => {
          this.deactivatePlan();
        },
        onClose: (succeeded, closeTransitionPromise) => {
          if (!succeeded) {
            closeTransitionPromise.finally(() => {
              this.clickOutsideHandler.enable();
            });
          }
        },
      });
    } else {
      if (path) {
        for (let i = 0; i < path.length; i++) {
          const pathElement = path[i];

          if (pathElement instanceof HTMLElement && pathElement.classList) {
            for (let j = 0; j < pathElement.classList.length; j++) {
              if (pathElement.classList[j] === 'point__site-plan-preview') {
                this.clickOutsideHandler.enable();

                return;
              }
            }
          }
        }
      }

      if (this.activePlan.active) {
        if (this.ppPointId !== NEW_POINT_ID) {
          event.preventDefault();
          event.stopImmediatePropagation();
        }

        this.deactivatePlan();
      }
    }
  }
}
