import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { translate } from '../../features/translate/translate';
import { ECustomFieldType } from '../../modules/custom-fields/custom-field-types-enums';
import { EIconPath } from '../../shared/enums/icons.enum';
import { TSelectOption } from '../input/select/select.model';

@Component({
  selector: 'pp-formula-output-picker',
  templateUrl: './formula-output-picker.component.html',
  styleUrl: './formula-output-picker.component.scss',
})
export class FormulaOutputPickerComponent implements OnChanges {
  @Input() ppFormulaOutputType: ECustomFieldType;
  @Output() ppFormulaOutputTypeChange = new EventEmitter<ECustomFieldType>();

  outputTypes: TSelectOption[] = [
    {
      label: translate('numbers'),
      value: ECustomFieldType.NUMBERS,
      iconSrc: EIconPath.ICON_FIELDS_NUMBERS_14,
    },
    {
      label: translate('cost_field'),
      value: ECustomFieldType.COST,
      iconSrc: EIconPath.ICON_FIELDS_COST_14,
    },
    {
      label: translate('percentage'),
      value: ECustomFieldType.PERCENTAGE,
      iconSrc: EIconPath.ICON_FIELDS_PERCENTAGE_14,
    },
  ];

  outputTypeIndex: number = -1;

  ngOnChanges(): void {
    this.setSelectedItem();
  }

  selectItem(item: TSelectOption): void {
    this.outputTypeIndex = this.outputTypes.indexOf(item);

    this.ppFormulaOutputTypeChange.emit(item.value as ECustomFieldType);
  }

  private setSelectedItem(): void {
    this.outputTypeIndex = this.outputTypes.findIndex(
      (item) => item.value === this.ppFormulaOutputType,
    );
  }
}
