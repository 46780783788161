<ul class="dashletActivities__ul">
  <li class="dashletActivities__item" *ngFor="let placeholder of placeholders">
    <div class="dashletActivities__item-wrapper">
      <div class="dashletActivities__item-avatar">
        <img [src]="EIconPath.BASIC_FIELD_ASSIGNEES" />
      </div>

      <div class="dashletActivities__item-center">
        <div
          class="dashletActivities__item-center-top dashletActivities__item-center-top--placeholder"
        >
          <span
            class="dashletActivities__item-acc-site dashletActivities__placeholder dashletActivities__item-acc-site--placeholder"
          >
            &nbsp;
          </span>

          <span
            class="dashletActivities__item-center-top-author dashletActivities__placeholder dashletActivities__item-center-top-author--placeholder"
          >
            &nbsp;
          </span>
        </div>

        <div class="dashletActivities__item-center-bottom">
          <div
            class="dashletActivities__item-activities dashletActivities__placeholder dashletActivities__item-activities--placeholder"
          >
            <div>&nbsp;</div>
          </div>

          <div
            class="dashletActivities__item-date dashletActivities__placeholder dashletActivities__item-date--placeholder"
          >
            &nbsp;
          </div>
        </div>
      </div>
    </div>
  </li>
</ul>
