import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { DropdownDirective } from './dropdown.directive';

import { DeviceService } from '@core/services';
import { Subject, takeUntil } from 'rxjs';
import { EIconPath } from '../../shared/enums/icons.enum';
import { DropdownService } from './dropdown-service/dropdown.service';
import {
  GET_SECONDARY_DROPDOWN_SERVICE,
  SET_SECONDARY_DROPDOWN,
  secondaryDropdownServiceChange$,
} from './secondary-dropdown';

@Component({
  selector: 'pp-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() ppId: string = null;
  @ViewChild(DropdownDirective, { static: true }) dropdownHost: DropdownDirective;
  @ViewChild('dropdownEl') dropdownElement: ElementRef;

  dropdown: TDropdown;
  private readonly destroy$ = new Subject<void>();

  @HostBinding('class.dropdown--active') get class(): boolean {
    return this.dropdown?.visible;
  }

  isiPhone: boolean = this.deviceService.isiPhone();
  isiPod: boolean = this.deviceService.isiPod();
  isiPad: boolean = this.deviceService.isiPad();
  EIconPath = EIconPath;

  constructor(private dropdownService: DropdownService, private deviceService: DeviceService) {}

  ngOnInit() {
    // Default logic for main dropdown
    if (!this.ppId) {
      this.dropdown = this.dropdownService.getDropdown();
      this.dropdown.dropdownHost = this.dropdownHost;
    } else {
      secondaryDropdownServiceChange$.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.dropdown = GET_SECONDARY_DROPDOWN_SERVICE().getDropdown();
      });
    }
  }

  ngAfterViewInit() {
    // Default logic for main dropdown, secondary one is managed separately
    if (!this.ppId) {
      this.dropdownService.setDropdownElement(this.dropdownElement.nativeElement);
    } else {
      SET_SECONDARY_DROPDOWN(this);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  hideDropdown(): void {
    if (this.dropdown.onClose) {
      this.dropdown.onClose();
    }

    this.dropdownService.hideDropdown();
  }
}
