<section class="newAssetBackWrapper">
  <button class="newAssetBackButton" (click)="goBack()" data-test="backToFleetButton">
    <pp-icon [ppSrc]="EIconPath.ARROW_BACK" ppColor="inherit" ppClass="setSize18"></pp-icon>
    {{ 'back_to_fleet' | ppTranslate }}
  </button>
</section>

<section class="newAssetDataWrapper">
  <pp-asset-name-and-image></pp-asset-name-and-image>
  <hr class="newAssetHr" />
  <pp-new-asset-site-picker></pp-new-asset-site-picker>
  <hr class="newAssetHr" />
  <pp-new-asset-details></pp-new-asset-details>
  <hr class="newAssetHr" />
  <div class="newAssetLastRow">
    <pp-new-asset-labels></pp-new-asset-labels>
    <pp-button
      class="newAssetSaveButton"
      ppTooltip
      [ppTitle]="
        emptyLabel
          ? ('new_asset_label_missing' | ppTranslate)
          : missingName
          ? ('new_asset_name_missing' | ppTranslate)
          : ''
      "
      ppStyle="primary"
      ppSize="wide"
      (ppAction)="saveAsset()"
      [ppDisabled]="emptyLabel || missingName"
      [ppProcess]="processing"
      >{{ 'save' | ppTranslate }}</pp-button
    >
  </div>
</section>
