import { translate } from 'src/app/project/features/translate/translate';
import { EUserRole } from '../../../share/share-utils/user-roles';

export function getDisabledUserManagementTooltipText(shareOption: string): string {
  return translate(
    shareOption === EUserRole.ACCOUNT_ADMIN
      ? 'cant_edit_account_admin_permission'
      : 'cant_edit_admin_permissions',
  );
}
