<div class="groupDropdown__row" id="emptyGroupRow">
  <pp-icon
    [ppSrc]="EIconPath.ICON_DRAG_18"
    ppColor="grey-400"
    [ppDisplayContents]="true"
    ppClass="setSize18"
  ></pp-icon>

  <p class="groupDropdown__header">
    {{ (ppOrder === 1 ? (ppType === 'group' ? 'group_by' : 'sort_by') : 'then_by') | ppTranslate }}
  </p>

  <pp-group-or-sort-button-column
    [ppType]="ppType"
    [ppColumn]="null"
    (ppAction)="updateEmptyGroupColumn($event)"
  ></pp-group-or-sort-button-column>

  <pp-group-or-sort-button-order
    [(ppOrder)]="emptyGroupOrder"
    (ppAction)="updateEmptyGroupOrder($event)"
  ></pp-group-or-sort-button-order>

  <pp-icon
    [ppSrc]="EIconPath.ICON_CLOSE_18"
    class="groupDropdown__close-icon"
    ppClass="setSize18"
    ppColor="grey-400"
    (click)="cancel()"
  ></pp-icon>
</div>
