import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TPoint } from 'src/app/project/modules/points/points.model';

import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { PointsService } from 'src/app/project/modules/points/points.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { PointAttachmentsService } from '../../point-attachments/point-attachments.service';
import { PointFieldsService } from '../../point-fields/point-fields.service';
import { PointModalService } from '../../point-modal.service';

import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';

import { DOCUMENT } from '@angular/common';
import { WindowService } from '@core/services';
import { getCrossbrowserEventPath } from 'src/app/core/helpers/compose-event-path';
import { GET_ACTIVE_PLAN } from 'src/app/project/modules/plan/plan.store';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { EIconPath } from '../../../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-point-container',
  templateUrl: './point-container.component.html',
  styleUrls: ['./point-container.component.scss'],
})
export class PointContainerComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChild('pointBody', { static: false }) pointBodyElement: ElementRef;

  @Input() ppPointId: string;
  @Input() ppSide: boolean;
  @Input() ppNew = false;
  @Input() ppFull = false;
  @Input() ppSitePlan = true;
  @Output() ppUpdateScrollPosition = new EventEmitter();

  private readonly destroy$ = new Subject<void>();

  workspaces$: Observable<TWorkspacesById>;
  paddingPx: number = 32;

  public workspaceId: string;
  public scrollPosition = 0;
  point: TPoint = null;
  showDropzone = false;
  private dragoverListenerRef;
  EIconPath = EIconPath;

  dragStartListenerRef = null;
  dragEndListenerRef = null;
  dragInTheApp = false;
  private window = this.windowService.getGlobalObject();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<{
      workspaces: TWorkspacesById;
    }>,
    private pointAttachmentsService: PointAttachmentsService,
    private activeService: ActiveService,
    private promptService: PromptService,
    private pointsService: PointsService,
    private elementRef: ElementRef,
    private pointFieldsService: PointFieldsService,
    private translationPipe: TranslationPipe,
    private pointModalService: PointModalService,
    private windowService: WindowService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.dragoverListenerRef = (event: DragEvent): void => {
      this.dragover(event);
    };

    this.dragStartListenerRef = (): void => {
      this.dragInTheApp = true;
    };

    this.dragEndListenerRef = (): void => {
      this.dragInTheApp = false;
    };
  }

  ngOnInit() {
    this.window.addEventListener('dragstart', this.dragStartListenerRef);
    this.window.addEventListener('dragend', this.dragEndListenerRef);

    this.point = this.pointsService.findPoint(this.ppPointId);

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.point) {
        this.workspaceId = this.point.workspaceRef.id;
      } else {
        this.workspaceId = this.activeService.getActiveWorkspaceId();
      }
    });
  }

  ngOnChanges() {
    this.point = this.pointsService.findPoint(this.ppPointId);
    this.setShadowClass();
  }

  ngOnDestroy() {
    this.window.removeEventListener('dragstart', this.dragStartListenerRef);
    this.window.removeEventListener('dragend', this.dragEndListenerRef);
    this.document.removeEventListener('dragover', this.dragoverListenerRef, false);

    this.destroy$.next();
  }

  ngAfterViewInit() {
    this.document.addEventListener('dragover', this.dragoverListenerRef, false);

    if (this.pointBodyElement) {
      this.pointModalService.setPointBody(this.pointBodyElement);
    }
  }

  dragover(event: DragEvent): void {
    event.preventDefault();

    const path = getCrossbrowserEventPath(event);
    let dragInside = false;

    if (path) {
      path.forEach((element) => {
        const htmlElement = element as HTMLElement;

        if (
          htmlElement.className === 'containerOne' ||
          htmlElement.className === 'containerOne containerOne--half'
        ) {
          dragInside = true;
        }
      });
    }

    this.showDropzone = dragInside;
  }

  dragEnter(event: DragEvent): void {
    event.preventDefault();

    const activePlan = GET_ACTIVE_PLAN();
    const activeQuill = this.pointFieldsService.getIsEditingQuill();

    if (!activePlan.active && !activeQuill.editing && !this.dragInTheApp) {
      this.showDropzone = true;
    }
  }

  drop(event: DragEvent): void {
    event.preventDefault();

    const files = event.dataTransfer.files as unknown as File[];
    this.showDropzone = false;

    if (files.length > 0) {
      this.uploadMediaAndFiles(files);
    } else {
      const prompt = this.translationPipe.transform('prompt_upload_incorrect_file');

      this.promptService.showWarning(prompt);
    }
  }

  uploadMediaAndFiles(files: File[]): void {
    this.pointAttachmentsService.uploadMediaAndFiles(files, this.ppNew, this.ppPointId);
  }

  calculateScroll(event: Event): void {
    const target = event.target as HTMLElement;

    this.pointModalService.setScrollPosition(target.scrollTop);

    this.ppUpdateScrollPosition.emit(target.scrollTop);
    this.scrollPosition = target.scrollTop;
  }

  private setShadowClass(): void {
    const className = 'point-container--shadow';

    if (!this.ppFull && this.ppSitePlan) {
      this.elementRef.nativeElement.classList.add(className);
    } else {
      this.elementRef.nativeElement.classList.remove(className);
    }
  }

  setPadding(element: HTMLElement): void {
    const scrollbarWidth = element.offsetWidth - element.clientWidth;

    this.paddingPx = 32 - scrollbarWidth;
  }
}
