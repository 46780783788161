import { ESubscriptionType } from '../user-settings/subscription-types.enum';
import { TSubscriptionTypeOption } from './subscription-type-option.model';

export const SUBSCRIPTION_TYPES: TSubscriptionTypeOption[] = [
  { value: ESubscriptionType.IMMEDIATE, label: 'instant_notifications' },
  { value: ESubscriptionType.DIGEST_SIX, label: 'notifications_digest_six' },
  { value: ESubscriptionType.DIGEST_DAY, label: 'notifications_digest_day' },
  { value: ESubscriptionType.DIGEST_WEEK, label: 'notification_digest_week' },
  { value: ESubscriptionType.NONE, label: 'notification_none' },
];
