/* eslint-disable no-case-declarations */
import { EActionType, ActionsUnion } from './white-label-actions';
import { TWhiteLabelStore } from './white-label.model';

export const initialState: TWhiteLabelStore = {
  data: {},
  created: false,
  accountId: null,
};

export function whiteLabelReducer(
  state: TWhiteLabelStore = initialState,
  action: ActionsUnion,
): TWhiteLabelStore {
  switch (action.type) {
    case EActionType.SET_WHITE_LABEL:
      const newState = {
        data: action.payload.whiteLabel.data,
        created: action.payload.whiteLabel.created,
        accountId: action.payload.whiteLabel.accountId,
      };

      return newState;

    default:
      return state;
  }
}
