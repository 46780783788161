<td
  id="{{ buttonId }}"
  class="userManagement__cell userManagementTableSite__cell accessCell__table permissionCell"
  (click)="toggleDropdown()"
  [class.userManagement__cell--disabled]="!editable"
  ppTooltip
  [ppTitle]="tooltipText"
>
  {{ userRole }}{{ isDefaultShare ? '' : USER_MANAGEMENT_CUSTOMIZED_SHARE_MARK }}
</td>
