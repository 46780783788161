import { Component, EventEmitter, Output } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-dashlet-cost-comparison-wrong-currencies',
  templateUrl: './dashlet-cost-comparison-wrong-currencies.component.html',
  styleUrl: './dashlet-cost-comparison-wrong-currencies.component.scss',
})
export class DashletCostComparisonWrongCurrenciesComponent {
  @Output() ppEditDashlet = new EventEmitter<void>();

  EIconPath = EIconPath;

  editDashlet(): void {
    this.ppEditDashlet.emit();
  }
}
