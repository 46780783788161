<button
  #modalConfirmButton
  id="modalConfirmButton"
  data-test="modalConfirmButton"
  (click)="action()"
  class="exportConfirmButton"
  [class.modalConfirmButton--disabled]="ppDisabled"
  [class.modalConfirmButton--error]="ppError"
>
  <app-spinner [ppButton]="false" [ppLight]="true" [ppHidden]="!ppProcess"></app-spinner>

  <span class="button__text" [class.button__text--hidden]="ppProcess">
    <ng-content></ng-content>
  </span>
</button>
