import { Component, Input } from '@angular/core';

@Component({
  selector: 'pp-select-dropdown-selected-mark',
  templateUrl: './select-dropdown-selected-mark.component.html',
  styleUrls: ['./select-dropdown-selected-mark.component.scss'],
})
export class SelectDropdownSelectedMarkComponent {
  @Input() ppText: string;
}
