import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GET_CUSTOM_FIELDS } from 'src/app/project/modules/custom-fields/custom-fields.store';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';
import {
  TAdvancedFilterDuration,
  TAdvancedFilterSingle,
  TFilterDurationOptions,
} from '../../../../models/advanced-filter.model';
import { getAdvancedDurationOptions } from './advanced-filter-duration-utils';

@Component({
  selector: 'pp-advanced-filter-duration',
  templateUrl: './advanced-filter-duration.component.html',
  styleUrls: ['./advanced-filter-duration.component.scss'],
})
export class AdvancedFilterDurationComponent {
  @Input() ppFilter: TAdvancedFilterSingle;
  @Output() ppFilterChange = new EventEmitter<TAdvancedFilterSingle>();

  value: number;
  option: TFilterDurationOptions;
  filter: TAdvancedFilterDuration;
  showHoursOnly: boolean;
  shortWidth = '8.4rem';
  longWidth = '13.2rem';
  inputWidth: string;

  advancedFilterDurationOptions = getAdvancedDurationOptions();
  EAdvancedFilterOptions = EAdvancedFilterOptions;

  ngOnChanges(): void {
    this.filter = this.ppFilter as TAdvancedFilterDuration;
    const field = GET_CUSTOM_FIELDS()[this.filter.id];

    this.value = this.filter.value;
    this.option = this.filter.option;

    this.showHoursOnly = field?.showHoursOnly;
    this.inputWidth = this.showHoursOnly ? this.longWidth : this.shortWidth;
  }

  changeFieldCondition(newCondition: TFilterDurationOptions): void {
    this.ppFilter.option = newCondition;
    this.option = newCondition;
    this.applyChanges();
  }

  applyChanges(): void {
    this.ppFilterChange.emit(this.ppFilter);
  }

  changeFieldValue(value: number): void {
    this.ppFilter.value = value;
    this.applyChanges();
  }
}
