import { Injectable } from '@angular/core';
import {
  API_auth_logged,
  API_auth_login,
  API_auth_logout,
  API_auth_password_reset,
  API_auth_password_reset_id,
  API_auth_password_setup,
  API_auth_signup,
  API_auth_verify,
} from '@core/api/paths';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { TUserResponse } from 'src/app/project/modules/user/response-models/user-response-model';
import { TLoggedResponse } from 'src/app/project/view-models/logged-response-model';
import { TLoginResponse } from 'src/app/project/view-models/login-response-model';
import { TPasswordResetResponse } from 'src/app/project/view-models/password-reset-response.model';
import {
  TResetPasswordRequest,
  TResetUserPasswordResponse,
} from 'src/app/project/view-models/reset-user-password-response.model';
import { TSignupModel } from './auth-signup-request.model';

@Injectable({
  providedIn: 'root',
})
export class AuthApiProviderService {
  constructor(private apiService: ApiService) {}

  getLoggedUser(): Observable<TLoggedResponse> {
    const url = API_auth_logged();

    return this.apiService.get<TLoggedResponse>(url);
  }

  login(params: FormData): Observable<TLoginResponse> {
    const url = API_auth_login();

    return this.apiService.postWithFormData<TLoginResponse>(url, params);
  }

  logout(): Observable<TUserResponse> {
    const url = API_auth_logout();

    return this.apiService.post<null>(url, null);
  }

  resetPassword(email: string): Observable<TUserResponse> {
    const url = API_auth_password_reset();

    return this.apiService.post<null>(url, email);
  }

  getPasswordResetUser(resetId: string): Observable<TPasswordResetResponse> {
    const url = API_auth_password_reset_id(resetId);

    return this.apiService.get<TPasswordResetResponse>(url);
  }

  resetUserPassword(
    resetId: string,
    body: TResetPasswordRequest,
  ): Observable<TResetUserPasswordResponse> {
    const url = API_auth_password_reset_id(resetId);

    return this.apiService.post<TResetUserPasswordResponse>(url, body);
  }

  setupPassword(
    inviteId: string,
    body: TResetPasswordRequest,
  ): Observable<TResetUserPasswordResponse> {
    const url = API_auth_password_setup(inviteId);

    return this.apiService.post<TResetUserPasswordResponse>(url, body);
  }

  signup(body: TSignupModel): Observable<TUserResponse> {
    const url = API_auth_signup();

    return this.apiService.post<TUserResponse>(url, body);
  }

  verify(id: string): Observable<null> {
    const url = API_auth_verify();

    return this.apiService.post<null>(url, id);
  }
}
