import { Injectable } from '@angular/core';
import { PromptService } from '../../../components/prompt/prompt.service';
import { TranslationPipe } from '../../../features/translate/translation.pipe';
import { TPlanData } from '../plan-data.service';
import { TPin } from 'src/app/project/view-models/point-response.model';
import { Polygon } from 'ol/geom';
import { Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';

@Injectable({
  providedIn: 'root',
})
export class PlanPointCorrectFeaturesService {
  constructor(private translationPipe: TranslationPipe, private promptService: PromptService) {}

  correctPolygonPosition({ feature, plan }: { feature: Feature; plan: TPlanData }): Coordinate[][] {
    const coordinates = (feature.getGeometry() as Polygon).getCoordinates();

    for (let i = 0; i < coordinates[0].length; i++) {
      if (-coordinates[0][i][1] > -(plan.extent[1] + plan.extent[3]) || -coordinates[0][i][1] < 0) {
        let yOffset = Math.min(-1 - coordinates[0][i][1], 0);

        if (yOffset === 0) {
          yOffset = Math.max(0, plan.extent[1] + plan.extent[3] - coordinates[0][i][1]);
        }

        coordinates[0][i][1] += yOffset;
      }

      if (coordinates[0][i][0] < 0 || coordinates[0][i][0] > plan.extent[2] + plan.extent[0]) {
        let xOffset = Math.max(1 - coordinates[0][i][0], 0);

        if (xOffset === 0) {
          xOffset = Math.min(xOffset, plan.extent[2] + plan.extent[0] - coordinates[0][i][0]);
        }

        coordinates[0][i][0] += xOffset;
      }
    }

    return coordinates;
  }

  correctPinPosition(pin: TPin, plan: TPlanData): TPin {
    const promptMessage = this.translationPipe.transform('cant_place_pins_outside');

    let correctedPin = false;

    if (pin[0] < 1) {
      pin[0] = 1;

      correctedPin = true;
    }

    if (pin[0] > plan.extent[2] + plan.extent[0]) {
      pin[0] = plan.extent[2] + plan.extent[0];

      correctedPin = true;
    }

    if (-pin[1] < 1) {
      pin[1] = -1;

      correctedPin = true;
    }

    if (-pin[1] > -(plan.extent[1] + plan.extent[3])) {
      pin[1] = plan.extent[1] + plan.extent[3];

      correctedPin = true;
    }

    if (correctedPin) {
      this.promptService.showWarning(promptMessage);
    }

    return pin;
  }
}
