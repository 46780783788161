import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadComponent } from './upload.component';
import { UploadService } from './upload.service';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [UploadComponent],
  imports: [CommonModule, PipesModule],
  exports: [UploadComponent],
  providers: [UploadService],
})
export class UploadModule {}
