import { createElement } from 'src/app/core/helpers/dom';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { GET_CUSTOM_FIELDS } from 'src/app/project/modules/custom-fields/custom-fields.store';
import { TPoint } from 'src/app/project/modules/points/points.model';
import { TColumn } from '../../../columns/column.model';
import { checkShowingTotal } from './check-showing-total';
import { createTotalCostCellInnerElement } from './create-footer-cost-cell-inner-element';
import { createTotalNumbersCellInnerElement } from './create-footer-numbers-cell-inner-element';
import { createTotalTimeCellInnerElement } from './create-footer-time-cell-inner-element';
import { createFooterTooltip } from './create-footer-tooltip';

export function createTotalCustomFieldCellInnerElement(
  child: HTMLElement,
  column: TColumn,
  points: TPoint[] = null,
): HTMLElement {
  const customFields = GET_CUSTOM_FIELDS();
  const isShowingTotal = checkShowingTotal(column);

  const nameElement = createElement<HTMLElement>('span', {
    attrs: {
      class: 'site-table__sum__title',
      id: 'sortingElement',
    },
    children: [child],
  });

  const cellNameElement = createElement<HTMLElement>('span', {
    attrs: {
      class: 'table__head__cell--inner table__group__cell--inner--NoPadding',
    },
    children: [nameElement],
  });

  const cellInnerElement = createElement<HTMLElement>('div', {
    attrs: {
      class:
        'table__cell__inner__namedColumn table__cell__inner table__group__cell--inner--NoPadding table__head__cell',
    },
    children: [cellNameElement],
  });

  if (
    column.customFieldIds &&
    customFields[column.customFieldIds[0]]?.type === ECustomFieldType.FORMULA &&
    isShowingTotal
  ) {
    switch (customFields[column.customFieldIds[0]].outputType) {
      case ECustomFieldType.COST:
        createTotalCostCellInnerElement(cellNameElement, column, points);
        break;
      case ECustomFieldType.NUMBERS:
        createTotalNumbersCellInnerElement(cellNameElement, column, points);
        break;
      case ECustomFieldType.TIME:
        createTotalTimeCellInnerElement(cellNameElement, column, points);
    }
  } else if (
    column.customFieldIds &&
    customFields[column.customFieldIds[0]]?.type === ECustomFieldType.COST &&
    isShowingTotal
  ) {
    createTotalCostCellInnerElement(cellNameElement, column, points);
  } else if (
    column.customFieldIds &&
    customFields[column.customFieldIds[0]]?.type === ECustomFieldType.NUMBERS &&
    isShowingTotal
  ) {
    createTotalNumbersCellInnerElement(cellNameElement, column, points);
  } else if (
    column.customFieldIds &&
    customFields[column.customFieldIds[0]]?.type === ECustomFieldType.TIME &&
    isShowingTotal
  ) {
    createTotalTimeCellInnerElement(cellNameElement, column, points);
  }

  createFooterTooltip(column.name, cellNameElement);

  return cellInnerElement;
}
