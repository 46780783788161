import { TAnyFunction, whichTransitionEvent } from '@core/helpers';

import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ComponentType } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { WindowService } from '@core/services';
import { logout$ } from '../../modules/auth/logout.service';
import { ModalDirective } from './modal.directive';

export type Modal<T = any> = {
  visible: boolean;
  processing: boolean;
  blur: boolean;
  data: T;
  host: ModalDirective;
  component: ComponentType<unknown>;
  closeWarning: boolean;
};

type TModalCancelCallback = (succeeded: boolean, closeTransitionPromise: Promise<void>) => void;

type TModalOnBeforeClose = (cancelled: boolean) => Promise<boolean>;

@Injectable({
  providedIn: 'root',
})
export class ModalService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly onCallback$ = new Subject<void>();

  private renderer: Renderer2;
  private modal: Modal;
  private callback: TAnyFunction = null;
  private onClose: TModalCancelCallback;
  private modalBaseElement: HTMLElement;
  private bodyModalOpenCssClass = 'modal__open';
  private window = this.windowService.getGlobalObject();

  private _modalDataChange$ = new Subject<void>();
  public modalDataChange$ = this._modalDataChange$.asObservable();

  /**
   * Allows to cancel the modal closing process, notifying by resolving/rejecting returning Promise
   */
  private onBeforeClose: TModalOnBeforeClose;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private windowService: WindowService,
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);

    this.modal = {
      visible: false,
      processing: false,
      blur: true,
      data: null,
      host: null,
      component: null,
      closeWarning: false,
    };

    logout$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.hideModal();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  getModal(): Modal<any> {
    return this.modal;
  }

  setModal({
    visible = undefined,
    processing = undefined,
    component = undefined,
  }: {
    visible?: boolean;
    processing?: boolean;
    component?: ComponentType<unknown>;
  } = {}): void {
    if (processing !== undefined) {
      this.modal.processing = processing;
    }

    if (visible !== undefined) {
      this.modal.visible = visible;
    }

    if (component !== undefined) {
      this.modal.component = component;
    }
  }

  setData<T>(data: T): void {
    this.modal.data = data;

    this._modalDataChange$.next();
  }

  clearModal(): void {
    this.modal.data = null;
    this.modal.visible = false;
    this.modal.processing = false;
    this.modal.component = null;
  }

  loadComponent(): void {
    const viewContainerRef = this.modal.host.viewContainerRef;
    viewContainerRef.clear();

    viewContainerRef.createComponent(this.modal.component);
  }

  showModal(
    component: ComponentType<unknown>,
    options: {
      blur?: boolean;
      callback?: TAnyFunction;
      onClose?: TModalCancelCallback;
      onBeforeClose?: TModalOnBeforeClose;
      closeWarning?: boolean;
    } = {},
  ): Observable<void> {
    this.modal.component = component;
    this.modal.blur = options.blur;
    this.modal.closeWarning = options.closeWarning;

    this.renderer.addClass(this.document.body, this.bodyModalOpenCssClass);

    if (this.modal.component && !this.modal.visible) {
      this.loadComponent();
    }

    if (options.callback) {
      this.callback = options.callback;
    }

    if (options.onClose) {
      this.onClose = options.onClose;
    }

    if (options.onBeforeClose) {
      this.onBeforeClose = options.onBeforeClose;
    }

    return this.onCallback$.asObservable();
  }

  hideModal(cancel: boolean = true, clickedOutside: boolean = false): Promise<void> {
    if (!this.modal.visible) {
      return Promise.resolve();
    }

    this.renderer.removeClass(this.document.body, this.bodyModalOpenCssClass);

    if (this.modal.closeWarning && clickedOutside) {
      return;
    }

    let cancelled = cancel;

    if (cancelled === null) {
      cancelled = true;
    }

    if (this.onBeforeClose) {
      return this.onBeforeClose(cancelled).then((shouldHideModal) => {
        if (shouldHideModal) {
          return this.closeModal(cancelled);
        } else {
          return Promise.reject();
        }
      });
    }

    return this.closeModal(cancelled);
  }

  setModalBaseElement(element: HTMLElement): void {
    this.modalBaseElement = element;
  }

  getModalBaseElement(): HTMLElement {
    return this.modalBaseElement;
  }

  clearModalBaseElement(): void {
    this.modalBaseElement = null;
  }

  getModalScrollTop(): number | null {
    if (this.modalBaseElement) {
      return this.modalBaseElement.scrollTop;
    }

    return null;
  }

  /**
   * Closing the modal window (with promisized animation) and calling callbacks
   */
  private closeModal(cancelled: boolean): Promise<void> {
    const promise = new Promise<void>((resolve) => {
      const transitionEvent = whichTransitionEvent();

      const removing = (): void => {
        this.window.removeEventListener(transitionEvent, removing);
        this.modal.host.viewContainerRef.clear();
        this.clearModal();
        resolve();
      };

      this.window.addEventListener(transitionEvent, removing);
    });

    this.modal.visible = false;

    if (!cancelled && this.callback) {
      this.callback();
      this.onCallback$.next();
    }

    if (this.onClose) {
      this.onClose(!cancelled, promise);
    }

    this.callback = null;
    this.onClose = null;
    this.onBeforeClose = null;

    return promise;
  }

  triggerCallback(options?: any): void {
    this.callback(options);
  }
}
