<div class="pointCF__field">
  <label class="pointCF__label">
    <div class="pointCF__label-image--container">
      <pp-icon [ppSrc]="EIconPath.ICON_FIELDS_TIMELINE_18" ppColor="inherit" ppClass="setSize18" />
    </div>

    <span
      ppTooltip
      class="pointCF__label-container"
      ppTitle="{{ ppFieldLabel }}"
      [ppTrimTooltip]="true"
    >
      {{ ppFieldLabel }}
    </span>
  </label>

  <div class="pointCF__content">
    <pp-datepicker
      [ppShowClearButton]="ppField.value?.[0] && ppCanEdit"
      [ppDateMultiple]="value"
      (ppSelect)="updateDate($event)"
      [ppDisabled]="!ppCanEdit"
      [ppError]="error"
      ppPlaceholder="From / To"
      [ppHighlight]="fieldHighlight"
      [ppMultipleDatesInput]="true"
      [ppUpdating]="updating"
      (ppSetDatepicker)="setDatepicker($event)"
    ></pp-datepicker>
  </div>
</div>
