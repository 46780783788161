import { Component, Input, ViewChild, ElementRef, OnChanges } from '@angular/core';

import { DeviceService } from '../../../../core/services/device.service';
import { TMediaObject } from '../../../modules/points/point-modal/point-attachments/point-attachments.service';
import { AttachmentsService } from 'src/app/project/modules/points/attachments/attachments.service';
import { tap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'pp-gallery-overlay-video',
  templateUrl: './gallery-overlay-video.component.html',
  styleUrls: ['./gallery-overlay-video.component.scss'],
})
export class GalleryOverlayVideoComponent implements OnChanges {
  @Input() ppVideo: TMediaObject;

  @ViewChild('videoContainer') videoContainerElement: ElementRef;

  isBrowserSafari = false;
  videoUrl: string;

  private readonly videoChange$ = new Subject<void>();

  constructor(
    private deviceService: DeviceService,
    private attachmentsService: AttachmentsService,
  ) {
    this.isBrowserSafari = this.deviceService.isBrowserSafari();
  }

  ngOnChanges() {
    this.videoChange$.next();

    this.attachmentsService
      .fetchVideoUrl(this.ppVideo.id)
      .pipe(
        takeUntil(this.videoChange$),
        tap((videoId) => {
          this.videoUrl = videoId;
        }),
      )
      .subscribe();
  }
}
