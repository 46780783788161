import { cloneDeep } from 'lodash';

import { GET_CUSTOM_FIELDS } from 'src/app/project/modules/custom-fields/custom-fields.store';
import { TCurrencyCode } from '../../../custom-fields/currencies/currencies.consts';
import { TSingleColumnData } from '../site-table-columns-data.service';
import { TColumn } from './column.model';
import { EDefaultColumnWidths } from './default-column-widths-enum';

const BASIC_COLUMNS = [
  'Flag',
  'Account',
  'Site',
  'Priority',
  'Status',
  'ID',
  'Title',
  'Description',
  'Created',
  'Updated',
  'Created By',
  'Assignees',
  'Tags',
];

export const generateDefaultColumns = (): TColumn[] =>
  BASIC_COLUMNS.filter((_column) => _column !== 'Account' && _column !== 'Site').map(
    (_column, _index) => ({
      name: _column,
      index: _index,
      width: getDefaultWidth(_column),
    }),
  );

export const generateDefaultOverviewColumns = (): TColumn[] =>
  BASIC_COLUMNS.map((_column, _index) => ({
    name: _column,
    index: _index,
    width: getDefaultWidth(_column),
  }));

export const getDefaultWidth = (_name: string): number => {
  switch (_name) {
    case 'Priority':
      return EDefaultColumnWidths.PRIORITY;
    case 'Status':
      return EDefaultColumnWidths.STATUS;
    case 'ID':
      return EDefaultColumnWidths.ID;
    case 'Flag':
      return EDefaultColumnWidths.RED_FLAG;
    case 'Title':
      return EDefaultColumnWidths.TITLE;
    case 'Description':
      return EDefaultColumnWidths.DESCRIPTION;
    case 'Created':
      return EDefaultColumnWidths.CREATED;
    case 'Updated':
      return EDefaultColumnWidths.UPDATED;
    case 'Created By':
      return EDefaultColumnWidths.CREATED_BY;
    case 'Assignees':
      return EDefaultColumnWidths.ASSIGNEES;
    case 'Tags':
      return EDefaultColumnWidths.TAGS;
    default:
      return EDefaultColumnWidths.CUSTOM_FIELD;
  }
};

export function getBasicColumns(): string[] {
  return [...BASIC_COLUMNS];
}

export function processColumns(_columns: TColumn[]): TColumn[] {
  let columns = [...findMissingColumns(_columns), ...cloneDeep(_columns)];

  columns = checkForExistingCustomFields(columns);

  return updateColumnProperties(columns);
}

function findMissingColumns(_columns: TColumn[]): TSingleColumnData[] {
  const basicColumns = generateDefaultColumns();
  const missingColumns = [];

  basicColumns.forEach((_basicColumn) => {
    const column = _columns.find((_column) => _column.name === _basicColumn.name);

    if (!column) {
      missingColumns.push({
        name: _basicColumn.name,
        width: _basicColumn.width,
      });
    }
  });

  return missingColumns;
}

function checkForExistingCustomFields(_columns: TSingleColumnData[]): TSingleColumnData[] {
  const customFields = GET_CUSTOM_FIELDS();

  if (Object.keys(customFields).length > 0) {
    return _columns.filter((_column) => {
      if (customFieldColumn(_column.name)) {
        const customField = Object.values(customFields).find(
          (_customField) => _column.name === _customField.label,
        );

        if (!customField) {
          return false;
        }
      }

      return true;
    });
  }

  return _columns;
}

function updateColumnProperties(_columns: TSingleColumnData[]): TColumn[] {
  const customFields = GET_CUSTOM_FIELDS();

  return _columns.map((_column, _index) => {
    const newColumn: TColumn = { ...(_column as any) };

    if (customFieldColumn(_column.name)) {
      if (customFields) {
        if (!_column.hasOwnProperty('customFieldIds')) {
          for (const [, customField] of Object.entries(customFields)) {
            if (_column.name === customField.label) {
              newColumn.currency = customField.currencyCode as TCurrencyCode;
              newColumn.showTotal = customField.showTotal !== false;
              newColumn.customFieldIds = [customField.id.toString()];

              break;
            }
          }
        }
      }
    } else {
      newColumn.name = fixColumnName(_column.name);
    }

    newColumn.width = fixColumnWidth(_column.width);

    return {
      ...newColumn,
      index: _index,
    };
  }) as TColumn[];
}

function customFieldColumn(_columnName: string): boolean {
  if (
    _columnName !== 'Priority' &&
    _columnName !== 'P' &&
    _columnName !== 'Status' &&
    _columnName !== 'S' &&
    _columnName !== 'ID' &&
    _columnName !== 'Title' &&
    _columnName !== 'Description' &&
    _columnName !== 'Created' &&
    _columnName !== 'Updated' &&
    _columnName !== 'Tags' &&
    _columnName !== 'Created By' &&
    _columnName !== 'Assignees' &&
    _columnName !== 'Flag'
  ) {
    return true;
  }

  return false;
}

function fixColumnWidth(_columnWidth: number): number {
  if (_columnWidth < 60) {
    return 60;
  }

  return _columnWidth;
}

function fixColumnName(_columnName: string): string {
  if (_columnName === 'P') {
    return 'Priority';
  }

  if (_columnName === 'S') {
    return 'Status';
  }

  return _columnName;
}
