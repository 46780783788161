import { ADD_COLLAPSED_GROUP, GET_TABLE } from '../../table.ui.store';
import { createElement } from 'src/app/core/helpers/dom';
import { createCellElement } from '../createCellElement';
import { TTableItem } from '../../custom-table/table.model';
import { EIconPath } from '../../../../../shared/enums/icons.enum';
import { ERowType } from 'src/app/project/shared/enums/row-type.enum';
import { GET_GROUPING } from '../../columns/grouping.store';
import { ApiService } from '@core/http';

export function createCollapseElement(item: TTableItem, groupLevel: number): HTMLElement {
  const classList = ['table__arrow'];
  const grouping = GET_GROUPING();

  switch (item.type) {
    case ERowType.GROUP_1:
    case ERowType.HEADER:
      if (grouping.length === 1) {
        classList.push('table__row--header-3');
      } else {
        classList.push('table__row--header-1');
      }
      break;
    case ERowType.GROUP_2:
      if (grouping.length === 3) {
        classList.push('table__row--header-2');
      } else {
        classList.push('table__row--header-3');
      }
      break;
    case ERowType.GROUP_3:
      classList.push('table__row--header-3');
      break;
  }

  const imageElementWrapper = createElement('img', {
    attrs: {
      class: '_groupDropdown__field__order',
      src: EIconPath.ICON_TABLE_CHEVRON_DOWN_GREY,
    },
  });

  const element = createCellElement({
    className: classList.join(' '),
    width: '40px',
    center: true,
    callback: () => {
      const table = GET_TABLE();

      ADD_COLLAPSED_GROUP(item.id);
      table.sortTable();
    },
    child: imageElementWrapper,
  });

  // element.style.marginLeft = `${groupLevel * 16}px`;

  return element;
}
