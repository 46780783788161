import { statusOrder } from '../columns/property-sorting';
import { TPoint } from '../../../points/points.model';
import { TColumn } from '../columns/column.model';
import { sortTitle } from './sort-title';
import { sort } from './sorting';

export function sortStatus(
  firstPoint: TPoint,
  secondPoint: TPoint,
  sortColumns: Partial<TColumn>[],
  index: number,
): number {
  if (statusOrder[firstPoint.status] < statusOrder[secondPoint.status]) {
    return sortColumns[index].sortOrder === 'ASC' ? -1 : 1;
  } else if (statusOrder[firstPoint.status] > statusOrder[secondPoint.status]) {
    return sortColumns[index].sortOrder === 'ASC' ? 1 : -1;
  }

  if (sortColumns[index + 1]) {
    return sort(firstPoint, secondPoint, sortColumns, index + 1);
  } else {
    return sortTitle(firstPoint, secondPoint, sortColumns, index);
  }
}
