import { API_files_images_bounded_size, API_files_images_square_size } from '@core/api/paths';
import { TAttachment } from '../modules/points/attachments/attachments.model';
import { EDocumentType } from '../shared/enums/document-type.enum';
import { TDocRef, THeader } from './response.models';

export type TAttachmentDTO = {
  _id: string;
  blobs: {
    name: string;
    size: number;
  }[];
  fileName: string;
  header: THeader;
  mimeType: string;
  totalBytes: number;
  type: EDocumentType;
  workspaceRef: TDocRef;
  exifCreatedEpochMillis?: string;
  imageId?: string;
  workspaceId?: string;
  originalBytes?: number;
};

type TAttachmentPartial = Pick<
  TAttachment,
  | 'bounded1200Url'
  | 'square100Url'
  | 'originalFileSize'
  | 'type'
  | 'createdOn'
  | 'attachmentId'
  | 'workspaceId'
>;

export class AttachmentViewModelFactory {
  static createFromDTO(
    dto: TAttachmentDTO[],
    id: string,
    attachmentType: EDocumentType,
  ): TAttachment[] {
    return dto.map((attachment) => ({
      fileName: attachment.fileName,
      mimeType: attachment.mimeType,
      uploaderName: attachment.header.createdBy.caption,
      uploaderId: attachment.header.createdBy.id,
      uploaderAvatarId: attachment.header.createdBy.primaryImageId,
      _id: id,
      ...AttachmentViewModelFactory.getAttachmentPartial(attachment, attachmentType),
    }));
  }

  static getAttachmentPartial(
    attachment: TAttachmentDTO,
    attachmentType: EDocumentType,
  ): TAttachmentPartial {
    switch (attachmentType) {
      case EDocumentType.IMAGE:
        return {
          bounded1200Url: API_files_images_bounded_size(attachment.imageId, 1200),
          square100Url: API_files_images_square_size(attachment.imageId, 100),
          originalFileSize: +attachment.totalBytes || +attachment.originalBytes,
          type: attachmentType,
          createdOn: +attachment.exifCreatedEpochMillis
            ? +attachment.exifCreatedEpochMillis
            : +attachment.header.createdEpochMillis,
          attachmentId: attachment.imageId,
          workspaceId: attachment.workspaceId,
        };
      case EDocumentType.IMAGE_360:
        return {
          bounded1200Url: API_files_images_bounded_size(attachment._id, 1200),
          square100Url: API_files_images_square_size(attachment._id, 100),
          originalFileSize: attachment.blobs.find((blob) => blob.name === 'ORIGINAL').size,
          createdOn: attachment.header.createdEpochMillis,
          type: attachmentType,
          attachmentId: attachment._id,
          workspaceId: attachment.workspaceRef.id,
        };
      case EDocumentType.VIDEO:
        return {
          originalFileSize: attachment.blobs.find((blob) => blob.name === 'VIDEO').size,
          type: attachment.type,
          createdOn: attachment.header.createdEpochMillis,
          attachmentId: attachment._id,
          workspaceId: attachment.workspaceRef.id,
        };
    }
  }
}
