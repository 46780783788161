import { createElement } from 'src/app/core/helpers/dom';
import { sanitizeHTML } from 'src/app/core/helpers/text-sanitizer';
import Tooltip from 'src/app/project/features/tooltip/Tooltip';
import { TPoint } from 'src/app/project/modules/points/points.model';
import { isPointFlagged } from 'src/app/project/modules/points/utils/is-flagged';
import { createTimelineFlagElement } from './create-timeline-flag-element';
import { MINIMUM_WIDTH_FOR_EXPANDED_TIMELINE_ELEMENT } from './timeline-variables';

export function createTimelineInnerElement(
  title: string,
  width: number,
  tooltip: Tooltip,
  point: TPoint,
): HTMLElement {
  let originalTitle = '';

  const eventListeners = {
    mouseenter: (): void => {
      if (tooltip._element._tippy) {
        originalTitle = tooltip.title;

        tooltip._element._tippy.setContent(sanitizeHTML(title));
      }
    },
    mouseleave: (): void => {
      if (tooltip._element._tippy) {
        tooltip._element._tippy.setContent(sanitizeHTML(originalTitle));
      }
    },
  };

  if (width > MINIMUM_WIDTH_FOR_EXPANDED_TIMELINE_ELEMENT) {
    return createElement('div', {
      attrs: {
        class: 'timeline__fieldElement__innerText timeline__fieldElement__innerText--long',
      },
      eventListeners,
      children: createChildren(title, point),
    });
  }

  const titleElement = createElement('div', {
    attrs: {
      class: 'timeline__fieldElement__innerTextLimiter',
    },
    children: createChildren(title, point),
  });

  return createElement('div', {
    attrs: {
      class: 'timeline__fieldElement__innerText timeline__fieldElement__innerText--thin',
      style: {
        left: '8px',
      },
    },
    eventListeners,
    children: [titleElement],
  });
}

function createChildren(name: string, point: TPoint): (string | HTMLElement)[] {
  const titleElement = createElement('span', {
    attrs: {
      class: 'timeline__fieldElement__innerText__title',
    },
    children: [name],
  });

  let childrenList: (HTMLElement | string)[] = [titleElement];

  if (isPointFlagged(point)) {
    childrenList.unshift(createTimelineFlagElement(point));
  }

  return childrenList;
}
