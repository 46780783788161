import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';
import {
  TAdvancedFilterFlag,
  TAdvancedFilterSingle,
  TFilterFlagOptions,
} from '../../../../models/advanced-filter.model';
import { getAdvancedFlagOptions } from './get-advanced-flag-options';

@Component({
  selector: 'pp-advanced-filter-flag',
  templateUrl: './advanced-filter-flag.component.html',
  styleUrls: ['./advanced-filter-flag.component.scss'],
})
export class AdvancedFilterFlagComponent {
  @Input() ppFilter: TAdvancedFilterSingle;
  @Output() ppFilterChange = new EventEmitter<TAdvancedFilterSingle>();

  option: TFilterFlagOptions;
  filter: TAdvancedFilterFlag;

  advancedFilterFlagOptions = getAdvancedFlagOptions();
  EAdvancedFilterOptions = EAdvancedFilterOptions;

  ngOnChanges(): void {
    this.filter = this.ppFilter as TAdvancedFilterFlag;

    this.option = this.filter.option;
  }

  changeFieldCondition(newCondition: TFilterFlagOptions): void {
    this.ppFilter.option = newCondition;
    this.option = newCondition;
    this.applyChanges();
  }

  applyChanges(): void {
    this.ppFilterChange.emit(this.ppFilter);
  }
}
