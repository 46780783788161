export function createBarGraphTooltipLabel(tooltipItem): string {
  let newTitle = tooltipItem.dataset.label;

  const lastRowValues = newTitle.substring(
    newTitle.indexOf('(') - 1,
    newTitle.lastIndexOf(')') + 1,
  );

  newTitle = newTitle.replace(lastRowValues, '');
  newTitle = newTitle + ' (' + tooltipItem.formattedValue + ')';

  return newTitle;
}
