import { cloneDeep } from 'lodash';
import { AddAllSortedPointIds } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';

export function addAllSortedPointIds(
  oldState: TPointsByWorkspace,
  action: AddAllSortedPointIds,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);

  Object.keys(state).forEach((workspaceId) => {
    state[workspaceId].sortedAllPointIds = action.payload.sortedPointIds;
  });

  return state;
}
