import { Injectable } from '@angular/core';
import {
  API_user_add_image,
  API_user_avatar,
  API_user_change_password,
  API_user_change_workspace,
  API_user_is_super_admin,
  API_user_self,
  API_user_simplified,
  API_user_type,
  API_user_update_email,
} from '@core/api/paths';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { TUserResponse } from 'src/app/project/modules/user/response-models/user-response-model';
import {
  TChangePasswordRequest,
  TUpdateUserRequest,
  TUpdateUserSimpleRequest,
  TUpdateUserType,
} from './user-requests.model';

@Injectable({
  providedIn: 'root',
})
export class UserApiProviderService {
  constructor(private apiService: ApiService) {}

  updateUserType(userId: string, body: TUpdateUserType): Observable<TUserResponse> {
    const url = API_user_type(userId);

    return this.apiService.put<TUserResponse>(url, body);
  }

  deleteUserAvatar(userId: string): Observable<TUserResponse> {
    const url = API_user_avatar(userId);

    return this.apiService.delete<TUserResponse>(url);
  }

  changePassword(passwords: TChangePasswordRequest): Observable<TUserResponse> {
    const url = API_user_change_password();

    return this.apiService.post<TUserResponse>(url, passwords);
  }

  updateUserSimplified(userId: string, user: TUpdateUserRequest): Observable<TUserResponse> {
    const url = API_user_simplified(userId);

    return this.apiService.put<TUserResponse>(url, user);
  }

  updateActiveWorkspaceId(id: string): Observable<TUserResponse> {
    const url = API_user_change_workspace();

    return this.apiService.put<TUserResponse>(url, { id });
  }

  fetchUser(): Observable<TUserResponse> {
    const url = API_user_self();

    return this.apiService.get<TUserResponse>(url);
  }

  uploadAvatar(type: string, id: string, formData: FormData): Observable<TUserResponse> {
    const url = API_user_add_image(type, id);

    return this.apiService.postWithFormData<TUserResponse>(url, formData);
  }

  fetchIsSuperUser(): Observable<boolean> {
    const url = API_user_is_super_admin();

    return this.apiService.get(url);
  }

  updateUser(body: TUpdateUserSimpleRequest, userId: string): Observable<TUserResponse> {
    const url = API_user_update_email(userId);

    return this.apiService.put<TUserResponse>(url, body);
  }
}
