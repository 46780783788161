import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { EIconPath } from '../../../../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-add-group-or-sort-button',
  templateUrl: './add-group-or-sort-button.component.html',
  styleUrls: ['./add-group-or-sort-button.component.scss'],
})
export class AddGroupOrSortButtonComponent {
  @Input() ppText: string;
  @ViewChild('newGroupButton') newGroupButtonElement: ElementRef;
  @Input() ppDisabled: boolean;
  @Output() ppAction = new EventEmitter<MouseEvent>();

  EIconPath = EIconPath;
  closedWithAnchor = false;

  action(event: MouseEvent): void {
    if (!this.ppDisabled) {
      this.ppAction.emit(event);
    }
  }
}
