import { TDashletGraph } from '../../dashlet-graph.model';
import { generateBarGraphOptions } from './bar-graph-options';

export function createHistoricStatusGraph(isMobile, isExport): TDashletGraph {
  return {
    type: 'bar',
    data: {
      labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
      datasets: [
        {
          data: [],
          label: 'Open',
          backgroundColor: '#3170a7',
          borderColor: '#3170a7',
          hoverBackgroundColor: '#3170a7',
          hoverBorderColor: '#3170a7',
        },
        {
          data: [],
          label: 'In Progress',
          backgroundColor: '#6BC8F9',
          borderColor: '#6BC8F9',
          hoverBackgroundColor: '#6BC8F9',
          hoverBorderColor: '#6BC8F9',
        },
        {
          data: [],
          label: 'To Review',
          backgroundColor: '#f3db12',
          borderColor: '#f3db12',
          hoverBackgroundColor: '#f3db12',
          hoverBorderColor: '#f3db12',
        },
        {
          data: [],
          label: 'On Hold',
          backgroundColor: '#ff9801',
          borderColor: '#ff9801',
          hoverBackgroundColor: '#ff9801',
          hoverBorderColor: '#ff9801',
        },
        {
          data: [],
          label: 'Completed',
          backgroundColor: '#65b92e',
          borderColor: '#65b92e',
          hoverBackgroundColor: '#65b92e',
          hoverBorderColor: '#65b92e',
        },
        {
          data: [],
          label: 'Canceled',
          backgroundColor: '#838B99',
          borderColor: '#838B99',
          hoverBackgroundColor: '#838B99',
          hoverBorderColor: '#838B99',
        },
      ],
    },
    options: generateBarGraphOptions(isMobile, isExport),
  };
}
