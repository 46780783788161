<pp-modal>
  <ng-container heading>
    {{ header | ppTranslate }}
  </ng-container>

  <ng-container body>
    <p class="no-margin sitesLimitReached__text">
      <span>
        {{ firstMessageKey | ppTranslate }}
      </span>
      <span class="sitesLimitReached__link" (click)="contactUs()">
        {{ 'contact_us' | ppTranslate }}
      </span>
      <span>
        {{ secondMessageKey | ppTranslate }}
      </span>
    </p>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppProcess]="processing"
      [ppConfirmText]="confirmKey | ppTranslate"
      (ppCancel)="cancelModal()"
      (ppConfirm)="increaseLimit()"
      [ppError]="false"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
