import { Component, OnDestroy } from '@angular/core';
import { AssetService } from 'src/app/project/modules/fleet-management/asset-service/asset.service';

@Component({
  selector: 'pp-top-bar-fleet-options-asset-search',
  templateUrl: './top-bar-fleet-options-asset-search.component.html',
  styleUrls: ['./top-bar-fleet-options-asset-search.component.scss'],
})
export class TopBarFleetOptionsAssetSearchComponent {
  constructor(private assetService: AssetService) {}

  setKeyword(keyword: string): void {
    this.assetService.filterFleetAssets(keyword.toLowerCase());
  }
}
