<div class="filterDropdown__section filterDropdown__section--createdBy">
  <div class="filterDropdown__heading">
    <h6>{{ 'createdBy' | ppTranslate }}</h6>
    <button
      class="filterDropdown__clear--text"
      *ngIf="ppFilters.createdBy.value.length > 0"
      (click)="clearCreatedBy()"
    >
      {{ 'clear' | ppTranslate }}
    </button>
  </div>

  <pp-filter-users
    ppFilterType="createdBy"
    [ppFilters]="ppFilters"
    (ppOnValueChange)="applyFilters()"
  ></pp-filter-users>

  <hr />
</div>
