<div
  ppTooltip
  [ppTitle]="tooltip"
  data-m-target="Back to site button"
  [ppOptions]="{ placement: 'right' }"
  class="sidePanelLogo__wrap"
  (click)="switchSite()"
  [class.sidePanelLogo__wrap--disabled]="ppDisabled"
>
  <div class="sidePanelLogo__img--hover">
    <img class="sidePanelLogo__img--back" [src]="EIconPath.SIDE_PANEL_BACK_ARROW" />
  </div>
</div>
