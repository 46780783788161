<li
  ppTooltip
  ppTitle="Sites"
  id="dashboardMenuItem"
  class="mainNav__element mainNav__element--with-image"
  [class.mainNav__element--spacing]="!ppIsLandscape"
  [class.mainNav__element--disabled]="ppDisabled"
  [class.mainNav__element--no-spacing]="ppIsLandscape"
  [class.mainNav__element--active]="
    sidePanel.rightPanelExpanded || (sidePanel.expanded && !ppIsMobile)
  "
  [ppOptions]="{ placement: 'right' }"
  (click)="toggleRightPanel()"
>
  <img
    data-m-target="Accounts & Sites icon"
    *ngIf="sidePanel.rightPanelExpanded || (sidePanel.expanded && !ppIsMobile)"
    class="filterPanel__tooltip mainNav__element--href mainNav__element--img sidePanelfolder--open sidePanelfolder"
    [src]="EIconPath.SIDE_PANEL_FOLDER"
  />

  <img
    data-m-target="Accounts & Sites icon"
    *ngIf="!(sidePanel.rightPanelExpanded || (sidePanel.expanded && !ppIsMobile))"
    class="filterPanel__tooltip mainNav__element--href mainNav__element--img sidePanelfolder"
    [src]="EIconPath.SIDE_PANEL_FOLDER_CLOSED"
  />

  <h6
    class="filterPanel__title filterPanel__title--img"
    *ngIf="ppIsMobile && !sidePanel.rightPanelExpanded"
  >
    {{ 'sites' | ppTranslate }}
  </h6>
</li>
