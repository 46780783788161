import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { TFilters } from 'src/app/project/modules/filters/site-filter.model';
import { PlanDataService, TPlanData } from 'src/app/project/modules/plan/plan-data.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { logEventInGTAG } from '../../services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from '../../services/analytics/google-analytics.consts';
import { TPoint } from '../points/points.model';
import { PointsService } from '../points/points.service';
import { PointExportUtilsService } from './point-export-utils.service';
import { PointExportService } from './point-export.service';
import { TExportCustomField } from './utils/export-custom-field';
import { TExportData } from './utils/export-data';
import { TExportModalData } from './utils/export-modal-data';
import { EExportTypeName } from './utils/export-type-names-enum';
import { getCoords } from './utils/get-coords';

@Injectable({
  providedIn: 'root',
})
export class PointExportDetailedListService {
  private planData: TPlanData = this.planDataService.getPlan();

  constructor(
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private pointExportService: PointExportService,
    private activeService: ActiveService,
    private workspaceService: WorkspaceService,
    private pointsService: PointsService,
    private planDataService: PlanDataService,
    private pointExportUtilsService: PointExportUtilsService,
  ) {}

  exportDetailedList({
    exportedPoint,
    exportedCustomFields,
    exportData,
    filters,
    data,
  }: {
    exportedPoint: TPoint;
    exportedCustomFields: TExportCustomField[];
    exportData: TExportData;
    filters: TFilters;
    data: TExportModalData;
  }): Observable<Response> {
    const customFields = [];
    const workspaceId = exportedPoint
      ? exportedPoint.workspaceRef.id
      : this.activeService.getActiveWorkspaceId();
    const workspaces = this.workspaceService.getWorkspaces();
    const pointIds = this.pointsService.getSelectedPointsExport();

    exportedCustomFields.forEach((field) => {
      if (field.enabled) {
        customFields.push(field.id);
      }
    });

    if (exportData.showImages) {
      logEventInGTAG(EGoogleEventSite.SITE__EXPORT__IMAGES, {
        event_category: EGoogleEventCategory.SITE,
        event_details: exportData.imagesSize,
      });
    }

    const coords = getCoords(workspaces, workspaceId, this.planData);

    const promptText = this.translationPipe.transform(
      pointIds.length > 1 ? 'prompt_export_start' : 'prompt_export_start_single',
    );

    this.promptService.showSuccess(promptText, { duration: 15 });

    return this.pointExportService.export({
      workspaceId: data.workspaceId,
      pointIds,
      customFieldIds: customFields,
      exportData: exportData,
      filters: filters,
      isPdf: false,
      exportType: EExportTypeName.DETAILED_LIST,
      coords,
      isOverview: false,
    });
  }

  exportOverviewDetailedList({
    exportedCustomFields,
    exportData,
    filters,
  }: {
    exportedCustomFields: TExportCustomField[];
    exportData: TExportData;
    filters: TFilters;
  }): Observable<Response> {
    const customFields = [];
    const promptText = this.translationPipe.transform('prompt_export_generation');
    const points = this.pointExportUtilsService.getOverviewReportPoints();

    exportedCustomFields.forEach((field) => {
      if (field.enabled) {
        customFields.push(field.id);
      }
    });

    if (exportData.showImages) {
      logEventInGTAG(EGoogleEventSite.SITE__EXPORT__IMAGES, {
        event_category: EGoogleEventCategory.SITE,
        event_details: exportData.imagesSize,
      });
    }

    this.promptService.showSuccess(promptText);

    return this.pointExportService.export({
      workspaceId: null,
      pointIds: points,
      customFieldIds: customFields,
      exportData: exportData,
      filters: filters,
      isPdf: false,
      exportType: EExportTypeName.DETAILED_LIST,
      coords: null,
      isOverview: true,
    });
  }
}
