import {
  TAdvancedAccessLevels,
  TCustomFieldAccessOption,
  TExportAccessLogic,
  TGranularPermission,
  TSemiGranularPermission,
  TSitePlanAccessLogic,
  TTagAccessLevel,
  TTimelineAccessLogic,
} from '../share.model';
import { EUserRole } from './user-roles';

export function generateShareAdvancedAccessLevels(
  oldAdvancedAccessLevels: TAdvancedAccessLevels,
  shareOption: EUserRole,
): TAdvancedAccessLevels {
  const advancedAccessLevels: Partial<TAdvancedAccessLevels> = generateAdvancedAccessLevels(
    oldAdvancedAccessLevels,
    shareOption,
  );

  advancedAccessLevels.tags = generateAALTags(advancedAccessLevels, shareOption);
  advancedAccessLevels.customFields = generateAALCustomFields(advancedAccessLevels);
  advancedAccessLevels.timeline = generateAALTimeline(advancedAccessLevels);
  advancedAccessLevels.exports = generateAALExports(advancedAccessLevels);
  advancedAccessLevels.sitePlan = generateAALSitePlan(advancedAccessLevels);

  return {
    customFields: advancedAccessLevels.customFields,
    tags: advancedAccessLevels.tags,
    timeline: advancedAccessLevels.timeline,
    sitePlan: advancedAccessLevels.sitePlan,
    exports: advancedAccessLevels.exports,
  };
}

function generateAALSitePlan(
  advancedAccessLevels: Partial<TAdvancedAccessLevels>,
): TSitePlanAccessLogic {
  if (!advancedAccessLevels.sitePlan) {
    return {
      permission: {
        read: true,
      },
    };
  }

  return advancedAccessLevels.sitePlan;
}

function generateAALExports(
  advancedAccessLevels: Partial<TAdvancedAccessLevels>,
): TExportAccessLogic {
  if (!advancedAccessLevels.exports) {
    return {
      permission: {
        read: true,
      },
    };
  }

  return advancedAccessLevels.exports;
}

function generateAALTimeline(
  advancedAccessLevels: Partial<TAdvancedAccessLevels>,
): TTimelineAccessLogic {
  const commentPermissions: TGranularPermission =
    generateAALCommentPermissions(advancedAccessLevels);
  const timelinePermissions: TSemiGranularPermission =
    generateAALTimelinePermissions(advancedAccessLevels);

  return {
    comments: {
      permission: commentPermissions,
    },
    permission: timelinePermissions,
  };
}

function generateAALTimelinePermissions(
  advancedAccessLevels: Partial<TAdvancedAccessLevels>,
): TSemiGranularPermission {
  if (advancedAccessLevels.timeline?.permission) {
    return advancedAccessLevels.timeline.permission;
  } else {
    return {
      read: true,
    };
  }
}

function generateAALCommentPermissions(
  advancedAccessLevels: Partial<TAdvancedAccessLevels>,
): TGranularPermission {
  if (advancedAccessLevels.timeline?.comments?.permission) {
    return advancedAccessLevels.timeline.comments.permission;
  } else {
    return {
      read: true,
      edit: true,
    };
  }
}

function generateAALCustomFields(
  advancedAccessLevels: Partial<TAdvancedAccessLevels>,
): TCustomFieldAccessOption[] {
  if (!advancedAccessLevels.customFields) {
    return [];
  }

  return advancedAccessLevels.customFields;
}

function generateAALTags(
  advancedAccessLevels: Partial<TAdvancedAccessLevels>,
  shareOption: EUserRole,
): TTagAccessLevel {
  if (!advancedAccessLevels.tags) {
    return {
      permission: getDefaultTagPermissions(shareOption),
    };
  }

  return advancedAccessLevels.tags;
}

function getDefaultTagPermissions(shareOption: EUserRole): { read: boolean; edit: boolean } {
  return {
    read: true,
    edit: shareOption !== EUserRole.LIMITED,
  };
}

function generateAdvancedAccessLevels(
  advancedAccessLevels: TAdvancedAccessLevels,
  shareOption: EUserRole,
): Partial<TAdvancedAccessLevels> {
  if (!advancedAccessLevels) {
    const tagPermissions = getDefaultTagPermissions(shareOption);

    return {
      customFields: [],
      tags: {
        permission: tagPermissions,
      },
      timeline: {
        comments: {
          permission: {
            edit: true,
            read: true,
          },
        },
        permission: {
          read: true,
        },
      },
      sitePlan: {
        permission: {
          read: true,
        },
      },
      exports: {
        permission: {
          read: true,
        },
      },
    };
  } else {
    return advancedAccessLevels;
  }
}
