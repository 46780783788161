import { cloneDeep } from 'lodash';
import { UpdatePointCustomFieldBulk } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';
import { updateStorePointCustomField } from './update-store-point-custom-field';

export function updatePointCustomFieldBulk(
  oldState: TPointsByWorkspace,
  action: UpdatePointCustomFieldBulk,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);

  action.payload.forEach((point) => {
    updateStorePointCustomField(state, point);
  });

  return state;
}
