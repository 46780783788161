<div
  class="datepicker"
  (ppOnRendered)="onRendered()"
  [class.datepicker--disabled]="ppDisabled"
  [class.datepicker--error]="ppError"
  [class.datepicker--focused]="datepicker?.isOpen"
  (click)="onClick($event)"
>
  <input #datepickerElement class="datepicker__input" type="text" [placeholder]="ppPlaceholder" />

  <div
    [class.datepicker__icon-containter--hide-hover]=" (ppDate || ppDateMultiple?.[0]) && ppShowClearButton"
    class="datepicker__icon-container"
    *ngIf="!ppDisabled && !ppUpdating"
  >
    <pp-icon
      [ppSrc]="
        datepicker?.isOpen ? EIconPath.ICON_MISC_UP_ARROW_18 : EIconPath.ICON_MISC_DOWN_ARROW_18
      "
      ppColor="grey-400"
      ppClass="setSize18"
    ></pp-icon>
  </div>

  <pp-save-indicator
    *ngIf="ppShowClearButton"
    [ppFocused]="datepicker?.isOpen"
    [ppMouseDownHandler]="true"
    [ppFilled]="ppShowClearButton"
    [ppUpdating]="ppUpdating"
    [ppShowClearBtn]="ppShowClearButton"
    (ppAction)="clearField()"
  ></pp-save-indicator>
</div>
