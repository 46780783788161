import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TDashlet } from 'src/app/project/modules/preferences/preferences.model';
import { EDashletPeriod } from '../../../dashboard-timeframes-enums';
import { EDashletType } from '../../../dashlets-enums';
import { overTimePeriods, timePeriods, TTimePeriods } from '../dashlet-flip-periods';

@Component({
  selector: 'pp-dashlet-period-select',
  templateUrl: './dashlet-period-select.component.html',
  styleUrl: './dashlet-period-select.component.scss',
})
export class DashletPeriodSelectComponent implements OnChanges {
  @Input() ppDashlet: TDashlet;
  @Input() ppPeriod: EDashletPeriod;
  @Output() ppPeriodChange = new EventEmitter<EDashletPeriod>();

  dashletTypes = EDashletType;
  periods: TTimePeriods[];

  ngOnChanges(): void {
    switch (this.ppDashlet.name) {
      case EDashletType.OVER_TIME_PRIORITY:
      case EDashletType.OVER_TIME_STATUS:
        this.periods = overTimePeriods;
        break;
      default:
        this.periods = timePeriods;
        break;
    }
  }

  updatePeriod(period: EDashletPeriod): void {
    this.ppPeriod = period;

    this.ppPeriodChange.emit(this.ppPeriod);
  }
}
