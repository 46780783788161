import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TWorkspaceUser } from '@project/view-models';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { UsersService } from '../../../users/users.service';
import { EFleetShareOption, TFleetShare } from '../../fleet-sharing/fleet-sharing.consts';

@Component({
  selector: 'pp-fleet-sharing-user-row',
  templateUrl: './fleet-sharing-user-row.component.html',
  styleUrls: ['./fleet-sharing-user-row.component.scss'],
})
export class FleetSharingUserRowComponent {
  @Input() ppFleetShare: TFleetShare;
  @Input() ppSelected: boolean;

  @Output() ppDelete = new EventEmitter<void>();
  @Output() ppToggle = new EventEmitter<void>();

  EIconPath = EIconPath;

  user: TWorkspaceUser;
  editPermissions: EFleetShareOption;
  EFleetShareOption = EFleetShareOption;

  constructor(private usersService: UsersService) {}

  ngOnChanges(): void {
    this.user = this.usersService.getUser(this.ppFleetShare.userId);

    this.editPermissions = this.ppFleetShare.fleetShareOption;
  }

  deleteShare(): void {
    this.ppDelete.emit();
  }

  toggleShare(): void {
    this.ppToggle.emit();
  }
}
