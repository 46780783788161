import { createElement } from 'src/app/core/helpers/dom';
import { GET_PREFERENCES } from 'src/app/project/modules/preferences/preferences.store';
import { transformDate, TTransformDateValue } from 'src/app/project/shared/date-transformer';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { TColumn } from '../../columns/column.model';
import { createMarkedKeywordElement } from '../body-cells-search';
import { createCellElement } from '../createCellElement';

export function createDateElement(
  _index: number,
  _column: TColumn,
  _customField: TPointCustomField<TTransformDateValue>,
): HTMLElement {
  const preferences = GET_PREFERENCES();

  let value = _customField.value;

  if (
    _column.customFieldIds &&
    !_column.customFieldIds.includes(_customField.customFieldTemplateId.toString())
  ) {
    value = null;
  }

  const date = transformDate({
    value,
    inputHourFormat: '',
    format: preferences.dateFormat,
    localTime: false,
  });

  return createCellElement({
    index: _column.index,
    width: `${_column.width}px`,
    child: createElement('div', {
      attrs: {
        class: 'customSiteTable__text',
      },
      children: [createMarkedKeywordElement(date ? date : '')],
    }),
  });
}
