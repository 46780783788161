import { TDatabase } from './database.model';

export const SYNCTIMESv1: TDatabase = {
  name: 'sync_times',
  version: 1,
  objectStores: [
    {
      name: 'general',
      keyPath: 'type',
    },
    {
      name: 'points_by_site',
      keyPath: 'workspaceId',
    },
  ],
};
