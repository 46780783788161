<pp-modal
  [ppWidth]="currentStep === EEditAccountUserStep.SHARES_LIST ? 1061 : 626"
  [ppScrollOnDefault]="true"
  [ppNoPadding]="true"
>
  <ng-container heading>
    {{
      (currentStep === EEditAccountUserStep.CUSTOM_FIELDS_EDIT
        ? 'user_management_bulk_changes_define_permissions'
        : 'downgrade_account_admin_header'
      ) | ppTranslate: { siteName: customFieldsWorkspaceName }
    }}
  </ng-container>

  <ng-container body *ngIf="!processing">
    <pp-account-user-modal-site-list
      [ppAccount]="account"
      *ngIf="currentStep === EEditAccountUserStep.SELECT_SITES"
      [ppEmailsReadOnly]="true"
      (ppSelectedWorkspacesChange)="setIsNextStepButtonDisabled()"
    ></pp-account-user-modal-site-list>

    <pp-account-user-modal-shares-list
      [ppAccount]="account"
      [ppShowingError]="showingError"
      *ngIf="currentStep === EEditAccountUserStep.SHARES_LIST"
      (ppGoBack)="previousStep()"
      (ppShowCustomFieldsTable)="goToCustomFieldsEditStep($event)"
      [ppEmailsReadOnly]="true"
    ></pp-account-user-modal-shares-list>

    <pp-account-user-modal-custom-fields
      [ppWorkspaceId]="customFieldsWorkspaceId"
      *ngIf="currentStep === EEditAccountUserStep.CUSTOM_FIELDS_EDIT"
    ></pp-account-user-modal-custom-fields>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="nextStepButtonText"
      [ppProcess]="processing || savingShare"
      (ppCancel)="previousStep()"
      (ppConfirm)="nextStep()"
      [ppDisabled]="isNextStepButtonDisabled"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
