import { createElement } from 'src/app/core/helpers/dom';
import { createTimelineResizeElement } from '../resize-element';
import { createTimelineCellElement } from '../body-cells/create-timeline-cell-element';

export function createHeaderEmptyElement(): HTMLElement {
  const selectElement = createElement('div', {
    attrs: {
      class: ['timelineColumnWidth'],
    },
  });

  const resizeElement = createTimelineResizeElement();

  const element = createTimelineCellElement({
    className: 'timeline__emptyHead timelineColumnWidth',
    center: true,
    child: selectElement,
  });

  element.appendChild(resizeElement);

  return element;
}
