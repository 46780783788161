<div class="advancedCFAccess__section">
  <h6 class="shareModalHeader">
    {{ 'advanced_access' | ppTranslate }}

    <i
      ppTooltip
      class="fa fa-info-circle settingsSection__header-info"
      [ppTitle]="'custom_field_list_description' | ppTranslate"
      aria-hidden="true"
      [ppMobileTooltip]="true"
      [ppOptions]="{ placement: 'right' }"
    ></i>
  </h6>

  <table aria-label="Edit share table" class="settingsTable">
    <thead class="settingsTable__head">
      <tr class="settingsTable__row">
        <th scope="col" class="settingsTable__header copy">
          {{ 'field' | ppTranslate }}
        </th>
        <th scope="col" class="advancedCFAccess__option-col settingsTable__header copy">
          {{ 'hide' | ppTranslate }}
        </th>
        <th scope="col" class="advancedCFAccess__option-col settingsTable__header copy">
          {{ 'view_only' | ppTranslate }}
        </th>
        <th
          scope="col"
          class="advancedCFAccess__option-col settingsTable__header copy"
          *ngIf="ppShare.shareOption === EUserRole.NORMAL"
        >
          {{ 'edit' | ppTranslate }}
        </th>
      </tr>
    </thead>

    <tbody class="settingsTable__body">
      <tr class="settingsTable__row">
        <td class="settingsTable__cell">
          <img class="settingsTable__icon" [src]="EIconPath.BASIC_FIELD_TAGS" />

          <span class="settingsTable__label">
            {{ 'tags' | ppTranslate }}
          </span>
        </td>

        <td class="advancedCFAccess__option-col settingsTable__cell">
          <pp-radio
            ppStyle="blue"
            [ppNoMargin]="true"
            [ppChecked]="
              !ppShare.advancedAccessLevels.tags.permission.read &&
              !ppShare.advancedAccessLevels.tags.permission.edit
            "
            (ppOnValueChange)="correctTagsAccessLogic(accessParameters.HIDE)"
          ></pp-radio>
        </td>

        <td class="advancedCFAccess__option-col settingsTable__cell">
          <pp-radio
            ppStyle="blue"
            [ppNoMargin]="true"
            [ppChecked]="
              ppShare.advancedAccessLevels.tags.permission.read &&
              !ppShare.advancedAccessLevels.tags.permission.edit
            "
            (ppOnValueChange)="correctTagsAccessLogic(accessParameters.READ)"
          ></pp-radio>
        </td>

        <td
          class="advancedCFAccess__option-col settingsTable__cell"
          *ngIf="ppShare.shareOption === EUserRole.NORMAL"
        >
          <pp-radio
            ppStyle="blue"
            [ppNoMargin]="true"
            [ppChecked]="
              ppShare.advancedAccessLevels.tags.permission.read &&
              ppShare.advancedAccessLevels.tags.permission.edit
            "
            (ppOnValueChange)="correctTagsAccessLogic(accessParameters.EDIT)"
          ></pp-radio>
        </td>
      </tr>

      <ng-container *ngIf="ppShare.advancedAccessLevels.customFields.length > 0">
        <ng-container *ngFor="let field of ppShare.advancedAccessLevels.customFields">
          <tr
            class="settingsTable__row"
            *ngIf="
              customFields[ppWorkspaceId][field.templateId] &&
              customFields[ppWorkspaceId][field.templateId]?.display
            "
          >
            <td class="settingsTable__cell">
              <img
                class="settingsTable__icon"
                ppTooltip
                [ppTitle]="getCustomFieldIconTooltip(customFields[ppWorkspaceId][field.templateId])"
                [src]="getCustomFieldIconSrc(customFields[ppWorkspaceId][field.templateId])"
              />

              <span class="settingsTable__label">
                {{ customFields[ppWorkspaceId][field.templateId].label }}
              </span>
            </td>

            <td class="advancedCFAccess__option-col settingsTable__cell">
              <pp-radio
                ppStyle="blue"
                [ppNoMargin]="true"
                [ppChecked]="!field.permission.read && !field.permission.edit"
                (ppOnValueChange)="
                  correctProperty(EGoogleEventLabel.CF, accessParameters.HIDE, field)
                "
              ></pp-radio>
            </td>

            <td class="advancedCFAccess__option-col settingsTable__cell">
              <pp-radio
                ppStyle="blue"
                [ppNoMargin]="true"
                [ppChecked]="field.permission.read && !field.permission.edit"
                (ppOnValueChange)="
                  correctProperty(EGoogleEventLabel.CF, accessParameters.READ, field)
                "
              ></pp-radio>
            </td>

            <td
              class="advancedCFAccess__option-col settingsTable__cell"
              *ngIf="ppShare.shareOption === EUserRole.NORMAL"
            >
              <pp-radio
                *ngIf="!customFields[ppWorkspaceId][field.templateId].lockedValue"
                ppStyle="blue"
                [ppNoMargin]="true"
                [ppChecked]="field.permission.edit"
                (ppOnValueChange)="
                  correctProperty(EGoogleEventLabel.CF, accessParameters.EDIT, field)
                "
              ></pp-radio>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</div>
