import { cloneDeep } from 'lodash';
import { UpdatePointPin } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';

export function updatePointPin(
  oldState: TPointsByWorkspace,
  action: UpdatePointPin,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);
  let pointIndex = null;

  pointIndex = state[action.payload.workspaceId].entities.findIndex(
    (_point) => _point._id === action.payload._id,
  );
  const point = state[action.payload.workspaceId].entities[pointIndex];

  if (point) {
    point.header.updatedEpochMillis = new Date().getTime();

    point.pins = action.payload.pins;
    point.polygons = action.payload.polygons;

    state[action.payload.workspaceId].entities.splice(pointIndex, 1, point);
  }

  return state;
}
