<ng-container *ngIf="!isMention">
  <div class="notification__icon--wrapper">
    <span [class]="spanClass" class="notification__icon" *ngIf="spanClass"></span>
    <i [ngClass]="iconClass" class="notification__icon" *ngIf="!spanClass"></i>
  </div>

  <span [innerHTML]="change"></span>
</ng-container>

<ng-container *ngIf="isMention">
  <span class="notification__blue-text">&#64; {{ 'mentioned' | ppTranslate }}</span>
  {{ 'you_in_the' | ppTranslate }}
  <span class="notification__bold">
    {{
      ppNotification.pushNotificationType === ENotificationType.POINT_MENTION
        ? ('point' | ppTranslate)
        : ppNotification.pushNotificationType === ENotificationType.POINT_MENTION_CUSTOM_FIELD
        ? ('custom_field' | ppTranslate)
        : ppNotification.pushNotificationType === ENotificationType.POINT_MENTION_COMMENT
        ? ('comment' | ppTranslate)
        : ('description' | ppTranslate)
    }}.
  </span>
</ng-container>
