export enum ERowType {
  POINT = 'POINT',
  HEADER = 'HEADER',
  EMPTY = 'EMPTY',
  EMPTY_MESSAGE = 'EMPTY_MESSAGE',
  GROUP_1 = 'GROUP_1',
  GROUP_2 = 'GROUP_2',
  GROUP_3 = 'GROUP_3',
  EMPTY_ROW_GROUP_1 = 'EMPTY_ROW_GROUP_1',
  EMPTY_ROW_GROUP_2 = 'EMPTY_ROW_GROUP_2',
  EMPTY_ROW_GROUP_3 = 'EMPTY_ROW_GROUP_3',
}
