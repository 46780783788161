import { Inject, Injectable, OnDestroy } from '@angular/core';

import { interval, merge, Observable, of, Subject, throwError } from 'rxjs';

import Map from 'ol/Map.js';
import Zoom from 'ol/control/Zoom';
import TileGrid from 'ol/tilegrid/TileGrid';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import View from 'ol/View';
import Projection from 'ol/proj/Projection';
import { getCenter } from 'ol/extent';
import { defaults as defaultInteractions } from 'ol/interaction';

import { PlanDataService, TPlanData } from './plan-data.service';
import { WorkspaceService } from '../workspace/workspace.service';
import { PreferencesService } from '../preferences/preferences-service/preferences.service';

import { updateTileStore } from './tiles';
import { createB64DataFromUrl } from './plan-methods';
import { tileStore } from './tiles.store';
import { WorkspaceApiProviderService } from '@core/api';
import { WindowService } from '@core/services';
import { catchError, debounce, finalize, map, take, takeUntil, tap } from 'rxjs/operators';
import { SiteService } from '../site/site.service';
import { ResponseErrorService } from '../errors/response-error.service';
import { TAwsCredentialsResponse } from '../../view-models/aws-credentials-response-model';
import { b64toBlob } from '../../../core/helpers/b64toBlob';
import { EPlanPointMode } from '../../shared/enums/plan-point-mode.enum';
import { EPlanModule } from './plan-module.enum';
import { TWorkspace } from '../workspace/workspace.model';
import { TileCoord } from 'ol/tilecoord';
import ImageTile from 'ol/ImageTile';
import AWS from 'aws-sdk';
import { TPlanMap } from './plan-feature.model';
import { DOCUMENT } from '@angular/common';
import { EIconPath } from '../../shared/enums/icons.enum';
import { EFileType } from '../../shared/enums/file-type.enum';

type ImageData = {
  layer: TileLayer<XYZ>;
  view: View;
};

type TUpdateTileStoreData = {
  workspaceId: string;
  newTiles: {
    [key: string]: Blob;
  };
  onComplete: () => void;
};

type TgenerateS3CredentialsData = {
  workspaceId: string;
  differenceInTimeMs: number;
};

@Injectable({
  providedIn: 'root',
})
export class PlanService implements OnDestroy {
  public selectionChange$ = new Subject<EPlanPointMode>();
  public mapMoved$ = new Subject<void>();
  fetchCredentialsPromise = {};

  credentials = null;

  private readonly destroy$ = new Subject<void>();
  private readonly updateTileStore$ = new Subject<TUpdateTileStoreData>();
  private updateTileStoreDebounceTimeMs = 100;
  private readonly generateS3Credentials$ = new Subject<TgenerateS3CredentialsData>();
  private readonly cancelGenerateS3Credentials$ = new Subject<void>();
  private generateS3CredentialsDebounceTimeCorrectionMs = 5 * 60 * 1000;
  private s3CredentialsGenerationIsPending = false;
  private credentialsWorkspaceId: string = null;

  private planData: TPlanData = this.planDataService.getPlan();
  private extent: number[] = [];
  private expirationDate: number;
  private requestTimeBackend: number;
  private requestTimeLocal: number;

  private tiles = {};

  private s3: AWS.S3;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private planDataService: PlanDataService,
    private workspaceService: WorkspaceService,
    private preferencesService: PreferencesService,
    private workspaceApiProviderService: WorkspaceApiProviderService,
    private siteService: SiteService,
    private windowService: WindowService,
    private responseErrorService: ResponseErrorService,
  ) {
    this.windowService.resize$
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          // Refreshing Plan size on SiteComponent view checked
          // Non-angular lib should update its size after Angular completes re-render on resize
          this.siteService.siteViewChecked$.pipe(take(1)).subscribe(() => {
            this.planData.instance.site?.updateSize();
          });
        }),
      )
      .subscribe();

    this.updateTileStore$
      .pipe(
        takeUntil(this.destroy$),
        debounce(() => interval(this.updateTileStoreDebounceTimeMs)),
        tap(({ workspaceId, newTiles, onComplete }) => {
          updateTileStore(workspaceId, newTiles);
          onComplete();
        }),
      )
      .subscribe();

    this.createGnerateS3CredentialsSubscribtion();

    this.cancelGenerateS3Credentials$
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.createGnerateS3CredentialsSubscribtion();
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  createPlan(
    {
      module = EPlanModule.SITE,
      element = null,
    }: {
      module?: EPlanModule;
      element?: HTMLElement;
    } = {},
    workspaceId: string = null,
  ): ImageData {
    const imageData = this.getImageData(workspaceId);

    this.createMap(imageData.layer, imageData.view, module, element);

    return imageData;
  }

  getImageData(_workspaceId: string): ImageData {
    let workspace: TWorkspace;

    if (!_workspaceId) {
      workspace = this.workspaceService.getActiveWorkspace();
    } else {
      workspace = this.workspaceService.getWorkspaces()[_workspaceId];
    }

    this.extent = workspace.sitePlan.extent;

    const layer = new TileLayer({
      source: this.createSource(workspace),
    });

    const view = this.createView();

    return { layer, view };
  }

  createSource(_workspace: TWorkspace): XYZ {
    let newTiles = {};

    return new XYZ({
      projection: 'PIXELS',
      tileGrid: this.createTileGrid(_workspace),
      tileUrlFunction: (tileCoord: TileCoord): string => {
        const coords = `${tileCoord[0]}/${tileCoord[1]}/${tileCoord[2]}`;

        if (tileStore && tileStore[coords]) {
          const blobUrl = URL.createObjectURL(tileStore[coords]);
          const img = new Image();

          img.src = blobUrl;

          img.onerror = (): void => {
            delete tileStore[coords];

            updateTileStore(_workspace.workspaceId, tileStore);
          };

          return tileStore[coords] as unknown as string;
        } else {
          return `${_workspace.sitePlan.sitePlanURL}${coords}.png`;
        }
      },
      tileLoadFunction: (_imageTile: ImageTile, _src: string): void => {
        const imageTileImage = _imageTile.getImage() as HTMLImageElement;

        if (typeof _src === 'string' && _src.startsWith(_workspace.sitePlan.sitePlanURL)) {
          let src = _src;

          if (this.s3) {
            const timeDifference = this.requestTimeBackend - this.requestTimeLocal;

            const s3ObjectPathElements = _src.split('/');
            let expirationTime = Math.floor((this.expirationDate - this.requestTimeBackend) / 1000);

            expirationTime += timeDifference / 1000;

            const s3ObjectParams = {
              Bucket: s3ObjectPathElements[3],
              Key: s3ObjectPathElements.slice(4).join('/'),
              Expires: expirationTime,
            };

            if (this.tiles[s3ObjectParams.Key]) {
              src = this.tiles[s3ObjectParams.Key];
            } else {
              src = this.s3.getSignedUrl('getObject', s3ObjectParams);

              this.tiles[s3ObjectParams.Key] = src;
            }
          }

          imageTileImage.src = src;

          createB64DataFromUrl(src).then((_response) => {
            const coords = _src.match(
              new RegExp(_workspace.sitePlan.sitePlanURL + '(.*)' + '.png'),
            )[1];
            newTiles[coords] = _response;

            this.updateTileStore$.next({
              workspaceId: _workspace.workspaceId,
              newTiles,
              onComplete: () => {
                newTiles = {};
              },
            });
          });
        } else {
          const contentType = EFileType.PNG;
          const b64Data = _src as any;

          if (b64Data.size) {
            const blobUrl = URL.createObjectURL(b64Data);

            imageTileImage.src = blobUrl;
          } else if (b64Data.indexOf('blob') !== -1) {
            imageTileImage.src = b64Data;
          } else {
            const blob = b64toBlob(b64Data, contentType);
            const blobUrl = URL.createObjectURL(blob);

            imageTileImage.src = blobUrl;
          }
        }
      },
    });
  }

  createMap(layer: TileLayer<XYZ>, view: View, module: EPlanModule, element: HTMLElement): void {
    this.planData.instance[module] = new Map({
      target: element,
      layers: [layer],
      view,
      controls: [],
      interactions: defaultInteractions({
        pinchRotate: false,
      }),
    }) as TPlanMap;

    if (module === EPlanModule.SITE) {
      const zoomPanel = this.createZoom();

      this.planData.instance[module].controls.extend([zoomPanel]);
    }

    this.fitPlan(module);

    const planView = this.planData.instance[module].getView();

    this.planDataService.setPlan({
      element,
      resolution: planView.getResolution(),
      extent: planView.calculateExtent(this.planData.instance[module].getSize()),
      zoom: planView.getZoom(),
    });

    planView.setMaxZoom(24);
    planView.setMinZoom(this.planData.zoom);

    this.planData.instance[module].on('moveend', () => {
      const zoomOutButtons = this.document.getElementsByClassName('ol-zoom-out');
      const zoomInButtons = this.document.getElementsByClassName('ol-zoom-in');

      let zoomOut;
      let zoomIn;

      if (module === EPlanModule.POINT && zoomOutButtons.length > 1) {
        zoomOut = zoomOutButtons[1];
        zoomIn = zoomInButtons[1];
      } else {
        zoomOut = zoomOutButtons[0];
        zoomIn = zoomInButtons[0];
      }
    });

    this.planData.instance[module].on('movestart', () => {
      this.mapMoved$.next();
    });
  }

  createTileGrid(workspace: TWorkspace): TileGrid {
    return new TileGrid({
      extent: this.extent,
      minZoom: workspace.sitePlan.minZoom,
      resolutions: workspace.sitePlan.resolutions,
    });
  }

  createProjection(): Projection {
    return new Projection({
      code: 'xkcd-image',
      units: 'pixels',
      extent: this.extent,
    });
  }

  createView(): View {
    return new View({
      center: getCenter(this.extent),
      projection: this.createProjection(),
      zoomFactor: this.getZoomFactor(),
      constrainOnlyCenter: true,
      extent: this.extent,
    });
  }

  getZoom(module: EPlanModule): number {
    return this.planData.instance[module] ? this.planData.instance[module].getView().getZoom() : 1;
  }

  setZoom(zoom: number, module: EPlanModule): TPlanData {
    if (this.planData.instance[module]) {
      this.planData.instance[module].getView().setZoom(zoom);
    }

    return this.planData;
  }

  setCenter(center: number[], module: EPlanModule): TPlanData {
    if (this.planData.instance[module]) {
      this.planData.instance[module].getView().setCenter(center);
    }

    return this.planData;
  }

  createZoom(): Zoom {
    const zoomInButton = this.document.createElement('div');
    const zoomOutImage = this.document.createElement('img');
    const zoomInImage = this.document.createElement('img');

    zoomInImage.src = EIconPath.PLAN_ZOOM_IN;
    zoomInImage.className = 'ol-zoom-in-icon';

    zoomInButton.appendChild(zoomInImage);
    zoomInButton.className = 'ol-zoom-in-container';
    zoomInButton.setAttribute('data-m-target', 'Site plan zoom button');

    zoomOutImage.src = EIconPath.PLAN_ZOOM_OUT;
    zoomOutImage.className = 'ol-zoom-out-icon';

    const zoom = new Zoom({
      zoomInLabel: zoomInButton,
      zoomOutLabel: zoomOutImage,
    });

    return zoom;
  }

  toggleZoom(addZoom: boolean): void {
    if (addZoom) {
      const zoom = this.createZoom();

      this.planData.instance.point.controls.extend([zoom]);
    }
  }

  getZoomFactor(): number {
    const preferences = this.preferencesService.getPreferences();
    const zoomFactor = preferences.mapZoomFactor;

    return zoomFactor ? zoomFactor : 2.0;
  }

  fitPlan(module: EPlanModule): void {
    this.planData.instance[module].getView().fit(this.extent, {
      size: this.planData.instance[module].getSize(),
    });
  }

  fitSitePlan(): void {
    this.fitPlan(EPlanModule.SITE);
  }

  fitPointPlan(): void {
    this.fitPlan(EPlanModule.POINT);
  }

  getCenter(module: EPlanModule): number[] {
    return this.planData.instance[module]
      ? this.planData.instance[module].getView().getCenter()
      : null;
  }

  generateS3Credentials(workspaceId: string): Observable<void> {
    if (
      ((workspaceId === this.credentialsWorkspaceId && this.s3CredentialsGenerationIsPending) ||
        !workspaceId) &&
      this.expirationDate - new Date().getTime() > 5 * 60 * 1000 &&
      this.credentials &&
      !this.credentials.expired
    ) {
      return of(null);
    }

    this.cancelGenerateS3Credentials$.next();

    return this.getSitePlanCredentials(workspaceId).pipe(
      map((response) => {
        this.tiles = {};

        this.expirationDate = response.expirationEpochMillis;
        this.credentialsWorkspaceId = workspaceId;

        const differenceInTimeMs = this.expirationDate - this.requestTimeBackend;

        this.credentials = new AWS.Credentials({
          accessKeyId: response.accessKeyId,
          secretAccessKey: response.secretAccessKey,
          sessionToken: response.sessionToken,
        });

        const config = new AWS.Config({
          credentials: this.credentials,
          region: 'eu-west-1',
        });

        AWS.config.update(config);

        this.s3 = new AWS.S3();

        this.generateS3Credentials$.next({ workspaceId, differenceInTimeMs });
      }, catchError(this.responseErrorService.handleRequestError)),
    );
  }

  clearS3Credentials(): void {
    this.credentialsWorkspaceId = null;
    this.cancelGenerateS3Credentials$.next();
  }

  getSitePlanCredentials(workspaceId: string): Observable<TAwsCredentialsResponse> {
    if (!this.fetchCredentialsPromise[workspaceId]) {
      this.fetchCredentialsPromise[workspaceId] = this.workspaceApiProviderService
        .getSitePlanCredentials(workspaceId)
        .pipe(
          tap((response) => {
            this.requestTimeBackend = Date.parse(response.headers.get('date'));
            this.requestTimeLocal = new Date().getTime();

            delete this.fetchCredentialsPromise[workspaceId];
          }),
          map(
            (response) =>
              // FIXME read type properly when observe is implemented
              // @ts-ignore
              response.body.entity,
          ),
          catchError((error) => {
            delete this.fetchCredentialsPromise[workspaceId];

            return throwError(error);
          }),
        );
    }

    return this.fetchCredentialsPromise[workspaceId];
  }

  private createGnerateS3CredentialsSubscribtion(): void {
    this.generateS3Credentials$
      .pipe(
        takeUntil(merge(this.cancelGenerateS3Credentials$, this.destroy$)),
        tap(() => {
          this.s3CredentialsGenerationIsPending = true;
        }),
        debounce(({ differenceInTimeMs }) =>
          interval(differenceInTimeMs - this.generateS3CredentialsDebounceTimeCorrectionMs),
        ),
        tap(({ workspaceId }) => {
          this.generateS3Credentials(workspaceId).subscribe();
        }),
        finalize(() => {
          this.s3CredentialsGenerationIsPending = false;
        }),
      )
      .subscribe();
  }
}
