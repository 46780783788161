import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import intlTelInput from 'intl-tel-input';
import { preventNonNumberInput } from 'src/app/core/helpers/check-if-number';
import { TranslationPipe } from '../../features/translate/translation.pipe';
import { createIntlTelInput } from './create-tel-input';
import { VALIDATION_ERROR } from './phone-input.consts';

@Component({
  selector: 'pp-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
})
export class PhoneInputComponent implements AfterViewInit {
  @ViewChild('phoneInput') phoneInput: ElementRef<HTMLInputElement>;

  @Input() ppNumber: string;
  @Output() ppNumberChange = new EventEmitter<string>();
  @Output() ppValidate = new EventEmitter<string>();

  private telInput: intlTelInput;

  constructor(private translationPipe: TranslationPipe) {}

  ngAfterViewInit(): void {
    this.createInput();
    this.setInitialNumber();
    this.addNumberChangeListeners();
  }

  preventNonNumberInput(event: KeyboardEvent): void {
    preventNonNumberInput(event);
  }

  private addNumberChangeListeners(): void {
    this.addChangeListener();
    this.addKeyUpListener();
  }

  private addKeyUpListener(): void {
    this.telInput.telInput.addEventListener('keyup', () => {
      this.emitPhoneNumber();
    });
  }

  private addChangeListener(): void {
    this.telInput.telInput.addEventListener('change', () => {
      this.emitPhoneNumber();
      this.checkIfNumberIsValid();
    });
  }

  private checkIfNumberIsValid(): void {
    const valid = this.telInput.isValidNumber();

    if (!valid) {
      const validationReason = this.telInput.getValidationError();
      let errorMessage = this.getValidationErrorMessage(validationReason);

      this.ppValidate.emit(errorMessage);
    } else {
      this.ppValidate.emit(null);
    }
  }

  private getValidationErrorMessage(validationReason: any): string {
    let errorMessage = '';

    switch (validationReason) {
      case VALIDATION_ERROR.INVALID_COUNTRY_CODE:
        errorMessage = this.translationPipe.transform('invalid_country_code');
        break;
      case VALIDATION_ERROR.TOO_SHORT:
        errorMessage = this.translationPipe.transform('phone_number_too_short');
        break;
      case VALIDATION_ERROR.TOO_LONG:
        errorMessage = this.translationPipe.transform('phone_number_too_long');
        break;
      case VALIDATION_ERROR.IS_POSSIBLE_LOCAL_ONLY:
        errorMessage = this.translationPipe.transform('is_possible_local_only');
        break;
      case VALIDATION_ERROR.INVALID_LENGTH:
        errorMessage = this.translationPipe.transform('phone_number_invalid_length');
        break;
      default:
        break;
    }
    return errorMessage;
  }

  private createInput(): void {
    this.telInput = createIntlTelInput(this.phoneInput.nativeElement, {
      autoPlaceholder: 'aggressive',
      preferredCountries: ['us'],
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/19.2.14/js/utils.js',
      showSelectedDialCode: true,
      i18n: {
        searchPlaceholder: this.translationPipe.transform('search_country'),
      },
    });
  }

  private setInitialNumber(): void {
    const number = this.ppNumber ? +this.ppNumber : '';
    this.telInput.setNumber(number ? `+${number}` : '');
  }

  private emitPhoneNumber(): void {
    const name = this.telInput.getNumber();

    this.ppNumberChange.emit(name);
  }
}
