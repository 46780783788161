import { Routes } from '@angular/router';
import { COMMON_GUARD_SERVICES } from '../../services/guards/guard-services.const';

import { PointFullModalComponent } from '../points/point-full-modal/point-full-modal.component';
import { PointGalleryComponent } from '../points/point-gallery/point-gallery.component';
import { PointHalfModalComponent } from '../points/point-half-modal/point-half-modal.component';

import { RemindersComponent } from './reminders/reminders.component';

export const reminderRoutes: Routes = [
  {
    path: 'reminders',
    component: RemindersComponent,
    canActivate: COMMON_GUARD_SERVICES,
    children: [
      {
        path: 'point/:_id',
        component: PointFullModalComponent,
        outlet: 'full',
        children: [
          {
            path: 'gallery/:attachmentId',
            component: PointGalleryComponent,
          },
        ],
      },
      {
        path: 'point/:_id',
        component: PointHalfModalComponent,
        children: [
          {
            path: 'gallery/:attachmentId',
            component: PointGalleryComponent,
          },
        ],
      },
    ],
  },
];
