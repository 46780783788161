import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { UserApiProviderService } from '@core/api';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { addItemToStore } from '../../helpers/database/database';
import { SetActiveWorkspaceId } from '../../services/active/active.actions';
import { EStore } from '../../shared/enums/store.enum';
import { SiteRedirectService } from '../auth/site-redirect.service';
import { logErrorInSentry } from '../errors/response-error';
import { ResponseErrorService } from '../errors/response-error.service';
import { TUserResponse } from './response-models/user-response-model';
import { SetUser, UpdateActiveWorkspaceId } from './user.actions';
import { TUser } from './user.model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ActiveWorkspaceService implements OnDestroy {
  private updateActiveWorkspaceIdPromise: Promise<TUserResponse>;
  private switchingWorkspace = false;

  private user$: Observable<TUser>;
  private user: TUser;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store<{ user: TUser }>,
    private userApiProviderService: UserApiProviderService,
    private responseErrorService: ResponseErrorService,
    private userService: UserService,
    private siteRedirectService: SiteRedirectService,
  ) {
    this.user$ = this.store.pipe(select(EStore.USER));

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  updateActiveWorkspaceId(workspaceId: string): Promise<TUserResponse> {
    this.store.dispatch(new SetActiveWorkspaceId(workspaceId));

    if (this.updateActiveWorkspaceIdPromise) {
      return this.updateActiveWorkspaceIdPromise;
    }

    this.updateActiveWorkspaceIdPromise =
      this.generateUpdateActiveWorkspacePromise(workspaceId).toPromise();

    return this.updateActiveWorkspaceIdPromise;
  }

  private generateUpdateActiveWorkspacePromise(workspaceId: string): Observable<TUserResponse> {
    if (navigator.onLine) {
      return this.generateUpdateActiveWorkspacePromiseOnline(workspaceId);
    }

    return this.generateUpdateOfflineActiveWorkspacePromise(workspaceId);
  }

  private generateUpdateActiveWorkspacePromiseOnline(
    workspaceId: string,
  ): Observable<TUserResponse> {
    if (this.switchingWorkspace || !workspaceId) {
      return of(null);
    }

    this.switchingWorkspace = true;

    return this.userApiProviderService.updateActiveWorkspaceId(workspaceId).pipe(
      tap((response: TUserResponse) => {
        this.updateActiveWorkspaceIdPromise = null;

        this.store.dispatch(
          new UpdateActiveWorkspaceId({
            activeWorkspaceId: workspaceId,
          }),
        );

        const newUser = this.userService.generateUser(response, this.user.isSuperUser);

        this.store.dispatch(new SetUser(newUser));
        addItemToStore('main', 'base', response);
      }),
      catchError((error) => {
        this.updateActiveWorkspaceIdPromise = null;

        this.responseErrorService.errors(error.status);
        logErrorInSentry(error);

        this.checkError(error);

        return throwError(error);
      }),
      finalize(() => {
        this.switchingWorkspace = false;
      }),
    );
  }

  private checkError(error: HttpErrorResponse): void {
    if (error.status === EStatusCode.BAD_REQUEST) {
      this.siteRedirectService.redirectToSites();
    }
  }

  private generateUpdateOfflineActiveWorkspacePromise(
    workspaceId: string,
  ): Observable<TUserResponse> {
    this.store.dispatch(
      new UpdateActiveWorkspaceId({
        activeWorkspaceId: workspaceId,
      }),
    );

    this.updateActiveWorkspaceIdPromise = null;

    return of(null);
  }
}
