const columnWidthLimitPx = 500;
const sidePanelWidthPx = 60;

export const getSitePlanResizedWidth = (resizerClientX: number, siteBodyWidth: number): number => {
  const sitePlanWidth = getSitePlanWidth(resizerClientX, siteBodyWidth);
  const newSitePlanWidth = getNewSitePlanWidth(sitePlanWidth, siteBodyWidth);
  const siteTableWidth = (siteBodyWidth * (100 - newSitePlanWidth)) / 100;
  let sitePlanFinalWidth;

  if (sitePlanWidth > columnWidthLimitPx && siteTableWidth > columnWidthLimitPx) {
    sitePlanFinalWidth = newSitePlanWidth;
  } else if (siteTableWidth > columnWidthLimitPx) {
    sitePlanFinalWidth = (columnWidthLimitPx / siteBodyWidth) * 100;
  } else {
    sitePlanFinalWidth = getSitePlanMaxWidth(siteBodyWidth);
  }
  return sitePlanFinalWidth;
};

const getSitePlanWidth = (resizerClientX: number, siteBodyWidth: number): number =>
  siteBodyWidth - resizerClientX + sidePanelWidthPx;

const getNewSitePlanWidth = (sitePlanWidth: number, siteBodyWidth: number): number =>
  (sitePlanWidth / siteBodyWidth) * 100;

const getSitePlanMaxWidth = (siteBodyWidth: number): number =>
  getNewSitePlanWidth(
    getSitePlanWidth(columnWidthLimitPx + sidePanelWidthPx, siteBodyWidth),
    siteBodyWidth,
  );
