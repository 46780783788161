import { createExpandElement } from '../../../body-cells-frozen/create-expand-element';
import { createCollapseElement } from '../../../body-cells-frozen/create-collapse-element';
import { TTableItem } from '../../../../custom-table/table.model';
import { ApiService } from '@core/http';

export function getArrowElement(
  groupsCollapsed: Set<string>,
  item: TTableItem,
  groupLevel: number,
): HTMLElement {
  let arrowElement = null;

  if (groupsCollapsed.has(item.id)) {
    arrowElement = createExpandElement(item, groupLevel);
  } else {
    arrowElement = createCollapseElement(item, groupLevel);
  }
  return arrowElement;
}
