import { Component } from '@angular/core';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TConfirmDisconnectIntegrationModalData } from './confirm-disconnect-integration-modal.consts';

@Component({
  selector: 'pp-confirm-disconnect-integration-modal',
  templateUrl: './confirm-disconnect-integration-modal.component.html',
  styleUrl: './confirm-disconnect-integration-modal.component.scss',
})
export class ConfirmDisconnectIntegrationModalComponent {
  EIconPath = EIconPath;

  description: string;

  constructor(
    private modalService: ModalService,
    private translationPipe: TranslationPipe,
  ) {}

  ngOnInit(): void {
    this.setDescriptionText();
  }

  private setDescriptionText(): void {
    const modalData: TConfirmDisconnectIntegrationModalData = this.modalService.getModal().data;
    const assetName = modalData.assetName;

    this.description = this.translationPipe.transform(
      'disconnect_integration_modal_description_1',
      {
        assetName: assetName,
        interpolation: { escapeValue: false } as any,
      },
    );
  }

  hideModal(cancel: boolean): void {
    this.modalService.hideModal(cancel);
  }
}
