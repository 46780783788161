<div
  *ngIf="ppIcon"
  class="authorizationProgressIcon"
  [style.transform]="'rotate(' + ppIcon.rotation + 'deg)'"
  [style.transform.rotate]="ppIcon.rotation + 'deg'"
  [class.authorizationProgressIcon--active]="ppIcon.active"
  [class.authorizationProgressIcon--spinning]="ppIcon.spinning"
>
  <pp-icon [ppDisplayContents]="true" [ppSrc]="ppIcon.src" ppColor="inherit"></pp-icon>
</div>
