import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { tap } from 'rxjs/operators';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { GET_CUSTOM_FIELDS } from '../../../custom-fields/custom-fields.store';
import { PreferencesService } from '../../../preferences/preferences-service/preferences.service';
import { TPreferences } from '../../../preferences/preferences.model';
import { EWorkspaces } from '../../../workspace/workspaces.enum';
import { SiteTableOverviewService } from '../../site-table-overview/site-table-overview.service';
import { TColumn } from '../../site-table/columns/column.model';
import { generateDefaultOverviewColumns } from '../../site-table/columns/columns';
import { TableColumnsService } from '../../site-table/columns/table-columns.service';
import { CustomTableService } from '../../site-table/custom-table/custom-table.service';
import { SiteOptionsService } from '../site-options.service';
import { restoreGroupingAndSorting } from './utils/restore-grouping-and-sorting';

@Injectable({
  providedIn: 'root',
})
export class SiteOptionColumnDropdownService {
  constructor(
    private router: Router,
    private siteOptionsService: SiteOptionsService,
    private preferencesService: PreferencesService,
    private siteTableOverviewService: SiteTableOverviewService,
    private customTableService: CustomTableService,
    private tableColumnsService: TableColumnsService,
  ) {}

  resetColumns(workspaceId: string, resetGroupingAndSorting = false): void {
    const isOverview = this.router.url.search(EWorkspaces.OVERVIEW) !== -1;

    if (!isOverview) {
      this.resetSiteTable(workspaceId, resetGroupingAndSorting);
    } else {
      this.resetOverviewTable(resetGroupingAndSorting);
    }

    logEventInGTAG(EGoogleEventSite.SITE__COLUMNS__RESET, {
      event_category: EGoogleEventCategory.SITE,
    });
  }

  private resetOverviewTable(resetGroupingAndSorting: boolean): void {
    this.preferencesService
      .fetchPreferences()
      .pipe(
        tap((preferences: TPreferences) => {
          this.handleOverviewReset(preferences, resetGroupingAndSorting);
        }),
      )
      .subscribe();
  }

  private handleOverviewReset(preferences: TPreferences, resetGroupingAndSorting: boolean): void {
    const backupColumns = cloneDeep(this.tableColumnsService.getColumns());
    const newPreferences = cloneDeep(preferences);
    const customFields = Object.values(GET_CUSTOM_FIELDS());

    const defaultColumns = generateDefaultOverviewColumns();
    const resettedColumns = this.customTableService.resetColumnData();
    const filteredColumns = this.siteTableOverviewService.filterOverviewColumns(
      defaultColumns,
      resettedColumns,
    );
    const { columns } = this.customTableService.checkColumnsChanged(filteredColumns, customFields);

    const newColumns = this.tryRestoreOverviewGroupingAndSorting(
      resetGroupingAndSorting,
      columns,
      backupColumns,
    );

    this.tableColumnsService.setColumns(newColumns);
    this.updateTable();

    newPreferences.overviewColumns = newColumns;
    this.preferencesService.updatePreferences(newPreferences).subscribe();
  }

  private tryRestoreOverviewGroupingAndSorting(
    resetGroupingAndSorting: boolean,
    columns: TColumn[],
    backupColumns: TColumn[],
  ): TColumn[] {
    const newColumns = resetGroupingAndSorting
      ? columns
      : restoreGroupingAndSorting(backupColumns, columns);

    return newColumns;
  }

  private resetSiteTable(workspaceId: string, resetGroupingAndSorting: boolean): void {
    const preferences = this.preferencesService.getPreferences();
    const workspacePreferences = preferences.workspaces[workspaceId];
    const newPreferences = cloneDeep(workspacePreferences);
    const columnsBackup = cloneDeep(workspacePreferences.columns);
    const columns = this.customTableService.generateDefaultSiteColumns(
      Object.values(GET_CUSTOM_FIELDS()),
    );
    const newColumns = this.tryRestoreTableGroupingAndSorting(
      resetGroupingAndSorting,
      columns,
      columnsBackup,
    );

    this.tableColumnsService.setColumns(newColumns);

    newPreferences.columns = newColumns;
    this.preferencesService.updateWorkspacePreferences(newPreferences, workspaceId).subscribe();
  }

  private tryRestoreTableGroupingAndSorting(
    resetGroupingAndSorting: boolean,
    columns: TColumn[],
    columnsBackup: TColumn[],
  ) {
    const newColumns = resetGroupingAndSorting
      ? columns
      : restoreGroupingAndSorting(columnsBackup, columns);
    this.updateTable();

    return newColumns;
  }

  private updateTable(): void {
    const sortButton = this.siteOptionsService.getSortButton();
    const groupButton = this.siteOptionsService.getGroupButton();
    const table = this.customTableService.getTable();
    table.generateColumns();

    table.tableBody.virtualScroller.disableSmoothScrolling();
    table.updateWidth();
    table.load(false);
    table.tableBody.virtualScroller.enableSmoothScrolling();

    sortButton.update();
    groupButton.update();
  }
}
