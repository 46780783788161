import { GET_GROUPING } from '../../columns/grouping.store';

export function getFrozenColumnsWidth(): number {
  const grouping = GET_GROUPING();

  if (grouping.length) {
    return 36 + grouping.length * 16;
  }

  return 36;
}
