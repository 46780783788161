import { EDateFormat } from 'src/app/project/shared/enums/date-format.enum';

export const DATE_FORMATS: {
  label: string;
  type: EDateFormat;
}[] = [
  {
    label: 'ISO (2020-11-29)',
    type: EDateFormat.ISO,
  },
  {
    label: 'European (29/11/2020)',
    type: EDateFormat.EUROPEAN,
  },
  {
    label: 'US (11/29/2020)',
    type: EDateFormat.US,
  },
  {
    label: 'Friendly (29 Nov 2020)',
    type: EDateFormat.FRIENDLY,
  },
];
