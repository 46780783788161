import { Observable, Subject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { ElementRef } from '@angular/core';
import { cssClasses } from './styles';

type elementObject = {
  svg?: boolean;
  tagName?: string;
  attrs?: {
    class?: string | [string];
    [key: string]: any;
  };
  eventListeners?: {
    [key: string]: EventListenerObject;
  };
  children?: any[];
};

export function createElement<T = any>(
  tagName: string = '',
  {
    attrs = {},
    eventListeners = {},
    children = [],
  }: {
    attrs?: any;
    eventListeners?: any;
    children?: any[];
  } = {},
): T {
  const object: elementObject = Object.create(null);

  Object.assign(object, {
    tagName,
    attrs,
    eventListeners,
    children,
  });

  return render(object) as any;
}

export const renderElement = ({
  svg = false,
  tagName = '',
  attrs = {},
  eventListeners = {},
  children = [],
}: elementObject = {}): Element => {
  let element: HTMLElement | SVGElement = null;

  if (svg) {
    element = document.createElementNS('http://www.w3.org/2000/svg', tagName);
  } else {
    element = document.createElement(tagName);
  }

  Object.keys(attrs).forEach((attr) => {
    if (attr === 'class') {
      let classNames: string = attrs.class.toString();

      if (Array.isArray(attrs.class) && attrs.class.length) {
        classNames = cssClasses(attrs.class);
      }

      element.setAttribute(attr, classNames);
    } else if (attr === 'style') {
      Object.keys(attrs.style).forEach((style) => {
        element.style[style] = attrs.style[style];
      });
    } else if (attr.startsWith('data-')) {
      element.dataset[attr.split('data-')[1]] = attrs[attr];
    } else {
      element.setAttribute(attr, attrs[attr]);
    }
  });

  Object.keys(eventListeners).forEach((eventListener) => {
    element.addEventListener(eventListener, eventListeners[eventListener], { passive: false });
  });

  for (let i = 0; i < children.length; i += 1) {
    const child = children[i];

    if (typeof child === 'string' || typeof child === 'number') {
      element.appendChild(document.createTextNode(child.toString()));
    } else {
      element.appendChild(child);
    }
  }

  return element;
};

const render = (_node: elementObject): Element => renderElement(_node);

export const elementCreated$ = (elementRef: ElementRef): Observable<void> => {
  const _mutation$ = new Subject<void>();

  const elementObserver = new MutationObserver(() => {
    if (elementRef && document.contains(elementRef.nativeElement)) {
      _mutation$.next();
    }
  });
  elementObserver.observe(document, {
    subtree: true,
    attributes: false,
    childList: true,
    characterData: false,
  });

  return _mutation$.pipe(
    take(1),
    finalize(() => {
      elementObserver.disconnect();
    }),
  );
};

export const elementChildListUpdated$ = (
  element: HTMLElement,
  multiple = false,
): Observable<void> => {
  const _mutation$ = new Subject<void>();

  const elementObserver = new MutationObserver(() => {
    _mutation$.next();
    if (!multiple) {
      _mutation$.complete();
    }
  });
  elementObserver.observe(element, { childList: true, attributes: false, characterData: false });

  return _mutation$.pipe(
    finalize(() => {
      elementObserver.disconnect();
    }),
  );
};
