import { Injectable } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { AccountUserErrorService } from '../../../errors/account-user-error.service';
import { TAccountUserError } from '../../../errors/response-error.consts';

@Injectable({
  providedIn: 'root',
})
export class AddUsersIssuesService {
  private readonly _errorChange$ = new Subject<TAccountUserError[]>();
  readonly errorChange$ = this._errorChange$.asObservable();

  private readonly _warningChange$ = new Subject<void>();
  readonly warningChange$ = this._warningChange$.asObservable();

  private workspacesWithErrors: string[] = [];
  private userEmailsWithErrors: string[] = [];
  private errorMessages: string[] = [];

  private warningMessages: string[] = [];

  constructor(private accountUserErrorService: AccountUserErrorService) {}

  handleCreateAccountUserError(error): Observable<TAccountUserError[]> {
    this.clearData();

    return this.accountUserErrorService.parseAccountUserError(error).pipe(
      tap((errors: TAccountUserError[]) => {
        this.setErrors(errors);
        this._errorChange$.next(errors);
      }),
    );
  }

  setWarnings(warningMessages: string[]): void {
    this.warningMessages = warningMessages;

    this._warningChange$.next();
  }

  getWorkspacesWithErrors(): string[] {
    return this.workspacesWithErrors;
  }

  getUserEmailsWithErrors(): string[] {
    return this.userEmailsWithErrors;
  }

  getErrorMessages(): string[] {
    return this.errorMessages;
  }

  getWarningMessages(): string[] {
    return this.warningMessages;
  }

  clearData(): void {
    this.clearErrors();
    this.clearWarnings();
  }

  showErrors(errors: TAccountUserError[]): void {
    this.clearErrors();
    this.setErrors(errors);

    this._errorChange$.next(errors);
  }

  private clearErrors(): void {
    this.workspacesWithErrors = [];
    this.userEmailsWithErrors = [];
    this.errorMessages = [];

    this._errorChange$.next([]);
  }

  private clearWarnings(): void {
    this.warningMessages = [];

    this._warningChange$.next();
  }

  private setErrors(errors: TAccountUserError[]) {
    errors.forEach((error) => {
      this.workspacesWithErrors.push(error.workspaceId);
      this.userEmailsWithErrors.push(error.email);
      this.errorMessages.push(error.errorMessage);
    });
  }
}
