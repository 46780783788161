import { EDateFormat } from '../../shared/enums/date-format.enum';
import { EDashletPeriod } from '../dashboard/dashboard-timeframes-enums';
import { EDashletType } from '../dashboard/dashlets-enums';
import { TSectionVisibilitySettings } from '../exports/export-body.model';
import { TFilters } from '../filters/site-filter.model';
import { TColumn } from '../site/site-table/columns/column.model';
import { ETimelineTimeframe } from '../site/site-timeline/site-timeline-utils/timeframes-enums';

export type TPreferences = {
  commentOnEnter?: boolean;
  dateFormat?: EDateFormat;
  mapZoomFactor?: number;
  workspaces?: {
    [key: string]: TWorkspacePreferences;
  };
  dashlets?: TDashlets;
  overviewColumns?: TColumn[];
  overviewExports?: Omit<TExportPreferences, 'simple'>;
  exports?: TExportPreferences;
  timeline?: TPreferencesTimeline;
  overviewFilters?: TFilters;
  remindersFilters?: TFilters;
  timelineFilters?: TFilters;
};

export type TWorkspacePreferences = {
  columns?: TColumn[];
  exports?: TExportPreferences;
  filters?: TFilters;
};

export type TDashletSiteRange = {
  accountId: string;
  workspaceIds?: string[];
  workspacesCosts?: {
    [workspaceId: string]: string[];
  };
};

export type TDashlet = {
  period: EDashletPeriod;
  selectedRange?: TDashletSiteRange;
  x: number;
  y: number;
  rows: number;
  cols: number;
  name: EDashletType;
};

export type TDashlets = {
  data: TDashlet[];
};

export type TPreferencesTimeline = {
  group?: ETimelineGroup;
  timeframe?: ETimelineTimeframe;
};

export enum ETimelineGroup {
  ACCOUNT = 'ACCOUNT',
  SITE = 'SITE',
}

export type TExportPreference = TSectionVisibilitySettings & { pageFormat?: string };

export type TUpdateExportPreference = {
  exports: TExportPreferences;
  columns: TColumn[];
};

export type TExportPreferences = {
  pdf?: TExportPreference;
  word?: TExportPreference;
  zip?: TExportPreference;
  simple?: TExportPreference;
};
