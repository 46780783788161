import { Component, Input, OnChanges } from '@angular/core';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';

@Component({
  selector: 'pp-site-settings-users-footer',
  templateUrl: './site-settings-users-footer.component.html',
  styleUrl: './site-settings-users-footer.component.scss',
})
export class SiteSettingsUsersFooterComponent implements OnChanges {
  @Input() ppSharesLength: number;

  footerMessage: string;

  constructor(private translationPipe: TranslationPipe) {}

  ngOnChanges(): void {
    this.generateFooterMessage();
  }

  generateFooterMessage(): void {
    if (this.ppSharesLength === 1) {
      this.footerMessage = this.translationPipe.transform('user_management_user_message_one', {
        count: this.ppSharesLength,
      });
    } else {
      this.footerMessage = this.translationPipe.transform('user_management_user_message_other', {
        count: this.ppSharesLength,
      });
    }
  }
}
