import { Component, OnInit } from '@angular/core';
import { DropdownService } from '../../../dropdown/dropdown-service/dropdown.service';
import { TDropdown } from '../../../dropdown/dropdown.consts';

@Component({
  selector: 'pp-logo-upload-dropdown',
  templateUrl: './logo-upload-dropdown.component.html',
  styleUrls: ['./logo-upload-dropdown.component.scss'],
})
export class LogoUploadDropdownComponent implements OnInit {
  dropdown: TDropdown;

  constructor(private dropdownService: DropdownService) {}

  ngOnInit(): void {
    this.dropdown = this.dropdownService.getDropdown();
  }

  uploadNewLogo(images: File[]): void {
    this.dropdown.callback(images);
    this.dropdownService.hideDropdown();
  }

  deleteLogo(): void {
    this.dropdown.callback(null);
    this.dropdownService.hideDropdown();
  }
}
