import { TExportImageSizes, TExportOutputFormat, TExportType, imageSizes } from './export-types';

import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, of } from 'rxjs';
import { catchError, finalize, map, takeUntil, tap } from 'rxjs/operators';
import {
  TExportPreferences,
  TPreferences,
  TWorkspacePreferences,
} from 'src/app/project/modules/preferences/preferences.model';
import { TUser } from 'src/app/project/modules/user/user.model';
import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { DeviceService } from 'src/app/core/services/device.service';
import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { PreferencesService } from 'src/app/project/modules/preferences/preferences-service/preferences.service';
import { UserService } from 'src/app/project/modules/user/user.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { ActiveService } from 'src/app/project/services/active/active.service';

import { getTimeZone } from 'src/app/core/helpers/get-timezone';
import { Modal, ModalService } from 'src/app/project/components/modal/modal.service';
import { TAccount } from 'src/app/project/modules/account/account.model';
import { TAllCustomFields } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { TPoint } from 'src/app/project/modules/points/points.model';
import { PointsService } from 'src/app/project/modules/points/points.service';
import { checkCustomWorkspaceId } from 'src/app/project/modules/workspace/workspace';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { TAttachments } from '../../points/attachments/attachments.model';
import { PointExportRequestService } from '../point-export-request.service';
import { pdf_data } from '../utils/export-const-pdf-data';
import { word_data } from '../utils/export-const-word-data';
import { xlsx_export_data } from '../utils/export-const-xlsx-data';
import { zip_data } from '../utils/export-const-zip';
import { TExportCustomField } from '../utils/export-custom-field';
import { TExportData } from '../utils/export-data';
import { csv_export_data } from '../utils/export-data-csv';
import { points_detaillist_data } from '../utils/export-data-detailed-list';
import { EExportData } from '../utils/export-data-enum';
import { points_simplelist_data } from '../utils/export-data-simple-list';
import { wordSimple_data } from '../utils/export-data-simple-word';
import { site_plan_data } from '../utils/export-data-site-plan';
import { EExportFormat } from '../utils/export-page-format-enum';
import { EExportTypeName } from '../utils/export-type-names-enum';
import { generateExportTypes } from '../utils/generate-export-types';
import { PointExportModalService } from './point-export-modal.service';

type TPreferencesSettings = {
  preferencesType: 'exports' | 'overviewExports';
  preferences: TPreferences | TWorkspacePreferences;
};

@Component({
  selector: 'pp-point-export-modal',
  templateUrl: './point-export-modal.component.html',
  styleUrls: ['./point-export-modal.component.scss'],
})
export class PointExportModalComponent implements OnInit, OnDestroy {
  public workspace: TWorkspace;
  public sitePlanExists = true;
  public isOverview = false;
  public user: TUser;
  public customFieldList: TAllCustomFields;
  public customFields: TExportCustomField[] = [];
  public account: TAccount;
  public exportTypes: TExportType[] = [];
  public imageSizes: TExportImageSizes[] = imageSizes;
  public processing = false;
  public isBrowserSafari = false;

  EIconPath = EIconPath;

  public displaySettings = {
    commentsVisible: true,
    tagsVisible: true,
    timelineVisible: true,
  };

  EExportTypeName = EExportTypeName;

  exportData: TExportData = {
    selected: null,
    format: null,
    showComments: true,
    showActivities: true,
    showDocuments: true,
    showId: true,
    showTitle: true,
    showDescription: true,
    showPriority: true,
    showStatus: true,
    showCreationDate: true,
    showEditDate: true,
    showExportDate: true,
    showTags: true,
    showCustomFields: true,
    showImages: true,
    imagesSize: 'medium',
    timeZone: getTimeZone(),
    showSignature: false,
    showFlagged: true,
    showExportBy: true,
    showSiteMap: true,
    showAssignees: true,
    showCreatedBy: true,
    showMapZoom: true,
    imagesDatesRange: false,
    showUrl: false,
  };

  selectedData: {
    name: string;
    id: string;
  }[];

  points_simplelist_data = points_simplelist_data;
  wordSimple_data = wordSimple_data;
  Csv_export_data = csv_export_data;
  points_detaillist_data = points_detaillist_data;
  pdf_data = pdf_data;
  word_data = word_data;
  zip_data = zip_data;
  Xlsx_export_data = xlsx_export_data;
  site_plan_data = site_plan_data;
  EExportData = EExportData;
  showCsvExportWarning = false;

  private readonly destroy$ = new Subject<void>();

  private accounts$: Observable<TAccount[]>;
  private workspaces$ = new Observable<TWorkspacesById>();
  private attachments$: Observable<TAttachments>;

  private point: TPoint;
  private workspaceId: string;
  private modal: Modal = this.modalService.getModal();
  fileExportDisabled = false;
  containsFiles: boolean;

  constructor(
    private store: Store<{
      accounts: TAccount[];
      workspaces: TWorkspacesById;
      attachments: TAttachments;
    }>,
    private pointsService: PointsService,
    private modalService: ModalService,
    private workspaceService: WorkspaceService,
    private activeService: ActiveService,
    private customFieldsService: CustomFieldsService,
    private deviceService: DeviceService,
    private userService: UserService,
    private preferencesService: PreferencesService,
    private accountService: AccountService,
    private pointExportRequestService: PointExportRequestService,
    private pointExportModalService: PointExportModalService,
  ) {
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.attachments$ = this.store.pipe(select(EStore.ATTACHMENTS));

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.workspaceId = this.activeService.getActiveWorkspaceId();

      this.checkWorkspaceId();
    });

    this.attachments$.pipe(takeUntil(this.destroy$)).subscribe((attachments) => {
      this.containsFiles = attachments.files.attachmentIds.length > 0;
    });
  }

  ngOnInit(): void {
    const _id = this.activeService.getActivePointId();
    const workspaceId = this.point
      ? this.point.workspaceRef.id
      : this.activeService.getActiveWorkspaceId();

    this.customFieldList = this.customFieldsService.getCustomFields();
    this.user = this.userService.getUser();
    this.isBrowserSafari = this.deviceService.isBrowserSafari();

    if (workspaceId) {
      this.displaySettings = this.pointExportModalService.checkExportPermissions(workspaceId);
      this.workspace = this.workspaceService.findWorkspace(workspaceId);

      this.workspace.customFields.forEach((fieldId) => {
        const fieldToList: TExportCustomField = {
          id: fieldId,
          enabled: true,
        };

        if (this.customFieldList[workspaceId]?.[fieldId]?.display) {
          this.customFields.push(fieldToList);
        }
      });

      this.isOverview = false;

      this.sitePlanExists = this.workspace.siteImageRef?.id ? true : false;
      this.exportData.showSiteMap = this.sitePlanExists;

      this.exportData.showComments = this.displaySettings.commentsVisible;
      this.exportData.showTags = this.displaySettings.tagsVisible;

      this.exportData.showActivities = this.displaySettings.timelineVisible;
    } else {
      this.isOverview = true;
      this.workspace = null;
    }

    if (_id) {
      this.point = this.pointsService.findPoint(_id);
      this.fileExportDisabled = !this.containsFiles;
      this.exportData[EExportData.FILES] = !this.fileExportDisabled;
    } else {
      this.point = null;
      this.fileExportDisabled = false;
    }

    this.generateExportTypes();

    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
      this.accountsHandler(accounts);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  selectItem(option: TExportType): void {
    this.exportData.selected = option;

    this.showCsvExportWarning = this.exportData.selected.value === EExportTypeName.CSV;
    this.selectedData = this.getSelectedData(option.value + '_data');

    if (!option.outputFormat) {
      this.exportData.format = EExportFormat.A4;
    } else if (!this.exportData.format) {
      this.exportData.format = option.outputFormat[0].value;
    }

    if (
      this.exportData.selected.value === EExportTypeName.DETAILED_LIST ||
      this.exportData.selected.value === EExportTypeName.PDF ||
      this.exportData.selected.value === EExportTypeName.ZIP
    ) {
      this.exportData.showComments = false;
      this.exportData.showActivities = false;
    }

    this.getPreferencesSettings()
      .pipe(
        takeUntil(this.destroy$),
        tap(({ preferencesType, preferences }) => {
          const exportType = this.getExportType(this.exportData.selected.value);

          if (preferences[preferencesType]?.[exportType]) {
            Object.keys(preferences[preferencesType][exportType]).forEach((property) => {
              this.exportData[property] = preferences[preferencesType][exportType][property];

              if (property === 'showDocuments' && this.fileExportDisabled) {
                this.exportData[property] = false;
              }
            });

            if (exportType === 'pdf' || exportType === 'zip') {
              this.customFields.forEach((customField) => {
                const preferencesField = preferences[preferencesType][
                  exportType
                ].customFieldIds.find((_customFieldId) => customField.id === _customFieldId);

                customField.enabled = !!preferencesField;
              });
            }

            if (preferences[preferencesType]?.[exportType].pageFormat) {
              this.exportData.format = preferences[preferencesType]?.[exportType].pageFormat;
            }
          }
        }),
      )
      .subscribe();
  }

  export(): void {
    if (!this.processing) {
      this.processing = true;

      this.pointExportRequestService
        .export(this.exportData, this.point, this.customFields)
        .pipe(
          takeUntil(this.destroy$),
          catchError((error) => {
            logErrorInSentry(error);

            return of();
          }),
          finalize(() => {
            this.processing = false;

            this.hideModal();
          }),
        )
        .subscribe();
    }
  }

  toggleValue(parameter: string): void {
    this.exportData[parameter] = !this.exportData[parameter];
  }

  toggleFormat(option: TExportOutputFormat): void {
    this.exportData.format = option.value;
  }

  toggleImageSize(option: TExportImageSizes): void {
    this.exportData.imagesSize = option.value;
  }

  getSelectedData(name: string): {
    name: string;
    id: string;
  }[] {
    const selectedData = this[name];

    return selectedData.filter(
      (data) =>
        (data.id !== EExportData.LUERSSEN_SIGNATURE &&
          data.id !== EExportData.COMMENTS &&
          data.id !== EExportData.TAGS &&
          data.id !== EExportData.ACTIVITY &&
          data.id !== EExportData.CUSTOM_FIELDS &&
          data.id !== EExportData.SITE_MAP &&
          data.id !== EExportData.ASSIGNEES) ||
        (data.id === EExportData.LUERSSEN_SIGNATURE &&
          this.account?.accountFeatures.pdfSignatures) ||
        (data.id === EExportData.TAGS && this.displaySettings.tagsVisible) ||
        (data.id === EExportData.COMMENTS && this.displaySettings.commentsVisible) ||
        data.id === EExportData.CUSTOM_FIELDS ||
        (data.id === EExportData.ACTIVITY && this.displaySettings.timelineVisible) ||
        (data.id === EExportData.SITE_MAP && this.sitePlanExists) ||
        data.id === EExportData.ASSIGNEES,
    );
  }

  toggleCustomField(field: TExportCustomField): void {
    field.enabled = !field.enabled;
  }

  toggleImageFilter(): void {
    this.exportData.imagesDatesRange = !this.exportData.imagesDatesRange;
  }

  toggleURL(): void {
    this.exportData.showUrl = !this.exportData.showUrl;
  }

  checkWorkspaceId(): void {
    if (!this.workspaceId) {
      this.workspaceId = checkCustomWorkspaceId();
    }
  }

  private generateExportTypes(): void {
    this.exportTypes = generateExportTypes(this.modal.data, this.workspace?.siteImageRef?.id);
  }

  private accountsHandler(accounts: TAccount[]): void {
    let accountId = this.activeService.getActiveAccountId();

    if (this.point) {
      const pointWorkspaceId = this.point.workspaceRef.id;
      const workspace = this.workspaceService.findWorkspace(pointWorkspaceId);

      accountId = workspace.accountId;
    }

    if (accountId && Array.isArray(accounts) && accounts.length) {
      this.account = accounts.find((account) => account.accountId === accountId);
    }
  }

  private getPreferencesSettings(): Observable<TPreferencesSettings> {
    if (this.isOverview) {
      return of({
        preferencesType: 'overviewExports',
        preferences: this.preferencesService.getPreferences(),
      });
    }

    const account = this.accountService.getAccount(this.workspace.accountId);

    return this.preferencesService
      .fetchWorkspacePreferences(this.workspaceId, account.accountFeatures)
      .pipe(
        map((preferences) => ({
          preferencesType: 'exports',
          preferences,
        })),
      );
  }

  private getExportType(typeName: EExportTypeName): keyof TExportPreferences {
    switch (typeName) {
      case EExportTypeName.DETAILED_LIST:
      case EExportTypeName.PDF:
        return 'pdf';
      case EExportTypeName.ZIP:
        return 'zip';
      case EExportTypeName.WORD:
        return 'word';
      case EExportTypeName.SIMPLE_LIST:
        return 'simple';
      default:
        return null;
    }
  }
}
