<pp-grid
  class="settingsGrid__item--userType"
  [ppOptions]="{ bodyPadding: true, bodyClass: 'settingsSection__user-type' }"
  *ngIf="userTypeChangeEnabled"
>
  <ng-container title> {{ 'user_type' | ppTranslate }} </ng-container>

  <ng-container body>
    <label class="settingsSection__label">{{ 'user_type' | ppTranslate }}</label>

    <div class="settingsSection__saveButton">
      <pp-select
        [ppItems]="userTypes"
        [ppSelectedItem]="userTypes[userTypeIndex]"
        (ppSelectItem)="selectItem($event)"
        [ppNoSearchBar]="true"
        require
      >
        <ng-container selected>
          <span class="uerSettings__select-content" *ngIf="userTypeIndex !== -1">
            {{ userTypes[userTypeIndex].label }}
          </span>

          <span class="uerSettings__select-placeholder" *ngIf="userTypeIndex === -1">
            {{ 'choose_user_type' | ppTranslate }}
          </span>
        </ng-container>
      </pp-select>
    </div>

    <div class="settingsSection__button-container">
      <pp-button
        ppStyle="secondary"
        ppId="saveAccTypeBtn"
        (ppAction)="updateUserType()"
        ppSize="small"
        [ppProcess]="updatingUserType"
      >
        {{ 'save' | ppTranslate }}
      </pp-button>
    </div>
  </ng-container>
</pp-grid>
