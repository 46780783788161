import { Component } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-no-asset-search-result-page',
  templateUrl: './no-asset-search-result-page.component.html',
  styleUrls: ['./no-asset-search-result-page.component.scss'],
})
export class NoAssetSearchResultPageComponent {
  EIconPath = EIconPath;
}
