import { EBasicField, EBasicFieldShort } from 'src/app/project/shared/enums/basic-fields-enums';
import { TColumn } from '../columns/column.model';
import { getEmptyGroupName } from './empty-group-name';
import { groupByPriority } from './group-by-priority';
import { groupByStatus } from './group-by-status';
import { TGroupedPoints } from './group-model';

const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});

export function sortGroups(
  column: TColumn,
  firstGroup: TGroupedPoints,
  secondGroup: TGroupedPoints,
): number {
  switch (column.name) {
    case EBasicField.FLAGGED:
    case EBasicFieldShort.FLAGGED:
      return -compareNames(firstGroup, secondGroup, column.groupOrder); // PS-1745 Reverse order for flagged
    case EBasicField.PRIORITY:
      return groupByPriority(column, firstGroup, secondGroup);
    case EBasicField.STATUS:
      return groupByStatus(column, firstGroup, secondGroup);
    default: {
      return compareNames(firstGroup, secondGroup, column.groupOrder);
    }
  }
}

function compareNames(
  firstGroup: TGroupedPoints,
  secondGroup: TGroupedPoints,
  groupOrder: 'ASC' | 'DESC',
): number {
  const firstGroupName = firstGroup.value.toString().toLowerCase();
  const secondGroupName = secondGroup.value.toString().toLowerCase();
  const emptyGroupName = getEmptyGroupName();

  if (firstGroupName === emptyGroupName) {
    return 1;
  } else if (secondGroupName === emptyGroupName) {
    return -1;
  } else if (collator.compare(firstGroupName, secondGroupName) < 0) {
    return groupOrder === 'ASC' ? -1 : 1;
  } else if (collator.compare(firstGroupName, secondGroupName) > 0) {
    return groupOrder === 'ASC' ? 1 : -1;
  }

  return 0;
}
