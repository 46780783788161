<pp-modal>
  <ng-container heading> {{ 'delete_points' | ppTranslate }} </ng-container>

  <ng-container body>
    <p>{{ 'are_you_sure_you_want_to_delete_point' | ppTranslate }}</p>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'Delete' | ppTranslate"
      [ppProcess]="processing"
      (ppCancel)="hideModal()"
      (ppConfirm)="deletePoint()"
      [ppError]="true"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
