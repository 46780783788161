import { setDefaultFontFamily } from './set-default-font-family';
import { setDefaultPanelAccent } from './set-default-panel-accent';
import { setDefaultPanelBg } from './set-default-panel-bg';
import { setDefaultPanelFont } from './set-default-panel-font';
import { setDefaultPanelFontHighlight } from './set-default-panel-font-highlight';
import { setDefaultPanelText } from './set-default-panel-text';
import { setDefaultPanelTextHover } from './set-default-panel-text-hover';
import {
  setDefaultPrimary100,
  setDefaultPrimary200,
  setDefaultPrimary300,
  setDefaultPrimary400,
  setDefaultPrimary50,
  setDefaultPrimary500,
  setDefaultPrimary600,
  setDefaultPrimary700,
  setDefaultPrimary75,
  setDefaultPrimary800,
  setDefaultPrimary900,
} from './set-primary-colors';

export function setDefaultStyle(themeWrapper: HTMLBodyElement): void {
  setDefaultPanelFont(themeWrapper);
  setDefaultPanelBg(themeWrapper);
  setDefaultPanelAccent(themeWrapper);
  setDefaultPanelFontHighlight(themeWrapper);
  setDefaultFontFamily(themeWrapper);
  setDefaultPanelText(themeWrapper);
  setDefaultPanelTextHover(themeWrapper);
  setDefaultPrimary50(themeWrapper);
  setDefaultPrimary75(themeWrapper);
  setDefaultPrimary100(themeWrapper);
  setDefaultPrimary200(themeWrapper);
  setDefaultPrimary300(themeWrapper);
  setDefaultPrimary400(themeWrapper);
  setDefaultPrimary500(themeWrapper);
  setDefaultPrimary600(themeWrapper);
  setDefaultPrimary700(themeWrapper);
  setDefaultPrimary800(themeWrapper);
  setDefaultPrimary900(themeWrapper);
}
