<div *ngIf="ppChange.newValue" class="dashletActivity dashletActivity--flex-start">
  <div class="dashletActivity__container">
    <img class="dashletActivity__icon" [src]="EIconPath.BASIC_FIELD_DESCRIPTION" />

    <div class="dashletActivity__text">
      {{ 'changed' | ppTranslate }}
      <span class="timeline__strong">{{ 'description' | ppTranslate }}</span>
      {{ 'to' | ppTranslate | lowercase }}
    </div>
  </div>
  <pp-description-dashlet-activity
    [ppDescription]="ppChange.newValue"
  ></pp-description-dashlet-activity>
</div>
