import { TDashlet } from '../../preferences/preferences.model';
import { EDashletPeriod } from '../dashboard-timeframes-enums';
import { EDashletType } from '../dashlets-enums';

export function generateNewDashlet(dashletType: EDashletType): TDashlet {
  let dashletSize = { x: 10, y: 15 };

  if (
    dashletType === EDashletType.HIGH ||
    dashletType === EDashletType.MEDIUM ||
    dashletType === EDashletType.LOW ||
    dashletType === EDashletType.OPEN ||
    dashletType === EDashletType.IN_PROGRESS ||
    dashletType === EDashletType.ONHOLD ||
    dashletType === EDashletType.CLOSED ||
    dashletType === EDashletType.COMMENT_CREATE ||
    dashletType === EDashletType.DEFECT_CREATE ||
    dashletType === EDashletType.TO_REVIEW ||
    dashletType === EDashletType.CANCELED
  ) {
    dashletSize = { x: 6, y: 6 };
  } else if (dashletType === EDashletType.ACTIVITIES) {
    dashletSize = { x: 18, y: 24 };
  }

  return {
    x: 0,
    y: 0,
    rows: dashletSize.x,
    cols: dashletSize.y,
    selectedRange: {
      accountId: null,
      workspaceIds: [],
    },
    period:
      dashletType === EDashletType.ACTIVITIES ||
      dashletType === EDashletType.CURRENT_PRIORITY ||
      dashletType === EDashletType.CURRENT_STATUS
        ? null
        : EDashletPeriod.WEEK,
    name: dashletType,
  };
}
