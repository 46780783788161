import { getEmptyGroupName } from './empty-group-name';

export function transformDescriptionValue(_value: string): string {
  const emptyGroupValue = getEmptyGroupName();
  let value = '';

  value = _value ? _value.trim() : '';

  if (!value) {
    value = emptyGroupValue;
  }

  return value;
}
