import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import {
  TTwoFactorAuthDeleteDTO,
  TTwoFactorAuthenticationConfirmDTO,
  TTwoFactorAuthenticationSetupDTO,
} from 'src/app/project/modules/auth/2fa/two-factor-authentication.consts';
import {
  API_2FA_ASK_LATER,
  API_2FA_CONFIRM,
  API_2FA_REMOVE,
  API_2FA_REMOVE_SELF,
  API_2FA_SETUP,
} from './two-factor-authentication.paths';

@Injectable({
  providedIn: 'root',
})
export class TwoFactorAuthenticationProviderService {
  constructor(private apiService: ApiService) {}

  setup2FA(body: TTwoFactorAuthenticationSetupDTO): Observable<null> {
    const url = API_2FA_SETUP();

    return this.apiService.post(url, body);
  }

  confirm2FA(userId: string, body: TTwoFactorAuthenticationConfirmDTO): Observable<null> {
    const url = API_2FA_CONFIRM(userId);

    return this.apiService.put(url, body);
  }

  delete2FA(body: TTwoFactorAuthDeleteDTO): Observable<never> {
    const url = API_2FA_REMOVE(body.userId);

    return this.apiService.delete(url, body);
  }

  deleteMy2FA(body: TTwoFactorAuthDeleteDTO): Observable<never> {
    const url = API_2FA_REMOVE_SELF();
    return this.apiService.delete(url, body);
  }

  askLaterFor2FA(userId: string): Observable<null> {
    const url = API_2FA_ASK_LATER(userId);

    return this.apiService.put<null>(url);
  }
}
