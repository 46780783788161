import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { TAccount } from 'src/app/project/modules/account/account.model';
import { TPreferences } from 'src/app/project/modules/preferences/preferences.model';
import { EUserType } from 'src/app/project/modules/users/user-types-enum';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { USER_TYPE_CHANGE_KEY } from '../../../../shared/constants/user-type-consts';
import { TUser } from '../../user.model';
import { UserSettingsService } from '../user-settings.service';

@Component({
  selector: 'pp-user-settings-type',
  templateUrl: './user-settings-type.component.html',
  styleUrls: ['./user-settings-type.component.scss'],
})
export class UserSettingsTypeComponent implements OnInit, OnDestroy {
  userTypeChangeEnabled = false;
  userTypeIndex: number;
  userTypes = this.userSettingsService.getUserTypes();
  updatingUserName = false;
  updatingUserType = false;
  preferences$: Observable<TPreferences>;
  preferences: TPreferences;
  user$: Observable<TUser>;
  user: TUser;
  accounts: TAccount[] = [];

  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store<{
      preferences: TPreferences;
      user: TUser;
      accounts: TAccount[];
      workspaces: TWorkspacesById;
    }>,
    private activatedRoute: ActivatedRoute,
    private userSettingsService: UserSettingsService,
  ) {
    this.user$ = this.store.pipe(select(EStore.USER));
    this.preferences$ = this.store.pipe(select(EStore.PREFERENCES));
  }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      if (params.get('key') === USER_TYPE_CHANGE_KEY) {
        this.userTypeChangeEnabled = true;
      }
    });

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((_user) => this.userHandler(_user));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  userHandler(user: TUser): void {
    this.user = user;

    if (this.user.userType) {
      this.userTypeIndex = this.userTypes.findIndex((_type) => _type.value === this.user.userType);

      if (
        this.userTypes[this.userTypeIndex] &&
        (this.userTypes[this.userTypeIndex].value === EUserType.DEVELOPER ||
          this.userTypes[this.userTypeIndex].value === EUserType.TESTER)
      ) {
        this.userTypeChangeEnabled = true;
      }
    } else {
      this.userTypeIndex = 3;
    }
  }

  updateUserType(): void {
    if (this.userTypes[this.userTypeIndex].value !== this.user.userType) {
      this.updatingUserType = true;

      this.userSettingsService
        .updateUserType(this.user.userId, this.userTypeIndex)
        .pipe(
          catchError((error) => {
            this.updatingUserName = false;

            throw error;
          }),
          finalize(() => {
            this.updatingUserType = false;
          }),
        )
        .subscribe();
    }
  }

  selectItem(item: { label: string; value: EUserType }): void {
    this.userTypeIndex = this.userTypes.indexOf(item);
  }
}
