<tr
  ppTooltip
  [ppTitle]="customField.label"
  class="customField"
  [class.customField-selected]="
    ppSelectedCustomFields[ppAccountId][ppWorkspaceId].includes(ppCustomFieldId)
  "
  (click)="selectCustomField(ppCustomFieldId)"
  *ngIf="fieldEnabled"
>
  <td class="checkbox__row">
    <pp-checkbox
      [ppChecked]="ppSelectedCustomFields[ppAccountId][ppWorkspaceId].includes(ppCustomFieldId)"
      ppSize="medium"
      ppType="solid"
    >
    </pp-checkbox>
  </td>

  <td class="name__row">
    {{ customField.label }}
  </td>

  <td class="type__row">
    {{ humanizedTypeNames[customField.type] }}
  </td>
</tr>

<tr class="customField customField--disabled" *ngIf="!fieldEnabled">
  <td class="checkbox__row">
    <pp-checkbox [ppChecked]="false" [ppDisabled]="true" ppSize="medium" ppType="solid">
    </pp-checkbox>
  </td>

  <td class="name__row">
    <span ppTooltip [ppTitle]="tooltipText" [ppOptions]="{ placement: 'right' }">
      {{ customField.label }}
    </span>
  </td>

  <td class="type__row">
    <span ppTooltip [ppTitle]="tooltipText" [ppOptions]="{ placement: 'right' }">
      {{ humanizedTypeNames[customField.type] }}
    </span>
  </td>
</tr>
