import { Component, OnInit } from '@angular/core';

import { EDropdownWidth, TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { SelectDropdownComponent } from 'src/app/project/components/input/select/select-dropdown/select-dropdown.component';
import { TPreferences } from 'src/app/project/modules/preferences/preferences.model';
import { TUser } from 'src/app/project/modules/user/user.model';
import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { PreferencesService } from 'src/app/project/modules/preferences/preferences-service/preferences.service';
import { UserService } from 'src/app/project/modules/user/user.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';

import { finalize, tap } from 'rxjs/operators';
import { TSelectDropdownData } from 'src/app/project/components/input/select/select-dropdown/select-dropdown.model';
import { GET_PREFERENCES } from 'src/app/project/modules/preferences/preferences.store';
import { GET_TIMELINE } from 'src/app/project/modules/site/site-timeline/timeline.ui.store';
import { EWorkspaces } from 'src/app/project/modules/workspace/workspaces.enum';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import {
  TTimeframeOption,
  getTimeframeOptions,
} from '../../site-timeline/site-timeline-utils/timeframe-options';

@Component({
  selector: 'pp-timeframe-button',
  templateUrl: './timeframe-button.component.html',
  styleUrls: ['../timeline-options.component.scss'],
})
export class TimeframeButtonComponent implements OnInit {
  options = getTimeframeOptions();

  public user: TUser;
  workspace: TWorkspace;
  workspaces: TWorkspacesById;
  siteId = '';
  scrollPosition = 0;
  scrolledToBottom = false;
  savingPreferences = false;
  preferences: TPreferences;
  timeframeHovered = false;
  dropdownVisible = false;
  private dropdown: TDropdown = this.dropdownService.getDropdown();
  EIconPath = EIconPath;

  constructor(
    private dropdownService: DropdownService,
    private workspaceService: WorkspaceService,
    private userService: UserService,
    private preferencesService: PreferencesService,
  ) {}

  ngOnInit(): void {
    this.workspace = this.workspaceService.getActiveWorkspace();
    this.user = this.userService.getUser();
    this.preferences = this.preferencesService.getPreferences();

    this.workspaces = this.workspaceService.getWorkspaces();
    this.siteId = EWorkspaces.TIMELINE;
  }

  toggleSelectDropdown(buttonId: string): void {
    if (this.dropdown.visible && this.dropdown.buttonId === buttonId) {
      this.dropdownVisible = false;
      this.dropdownService.hideDropdown();
    } else {
      const width = EDropdownWidth.PX_180;

      const timeframe = GET_PREFERENCES().timeline?.timeframe;

      this.dropdownService.setData<TSelectDropdownData>({
        noSearchBar: true,
        items: this.options.map((option) => {
          return {
            value: option.value,
            label: option.name,
          };
        }),
        selectedItem: timeframe
          ? {
              value: timeframe,
              label: this.options.find((option) => option.value === timeframe).name,
            }
          : null,
        width: width,
      });

      this.dropdownVisible = true;

      this.dropdownService.showDropdown(buttonId, SelectDropdownComponent, {
        callback: (option: TTimeframeOption) => this.setTimeframe(option),
        onClose: () => this.hideDropdown(),
        popper: {
          positionFixed: true,
          placement: 'bottom-start',
        },
        width,
        suppressScrollbar: true,
      });
    }
  }

  hideDropdown(): void {
    this.dropdown.visible = false;
    this.dropdownVisible = false;
  }

  setTimeframe(option: TTimeframeOption): void {
    if (!this.savingPreferences) {
      const preferences = GET_PREFERENCES();

      const body: TPreferences = {
        timeline: {
          timeframe: option.value,
          group: preferences.timeline?.group ? preferences.timeline?.group : null,
        },
      };

      this.preferencesService
        .updatePreferences(body)
        .pipe(
          tap((response) => {
            const timeline = GET_TIMELINE();

            this.preferences = response;

            timeline.switchTimeframe();
          }),
          finalize(() => {
            this.savingPreferences = false;
          }),
        )
        .subscribe();
    }
  }

  timeframeHover(): void {
    this.timeframeHovered = true;
  }

  timeframeHoverEnd(): void {
    this.timeframeHovered = false;
  }
}
