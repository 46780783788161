<!-- Preferences -->
<pp-grid class="settingsGrid__item--preferences" [ppOptions]="{ bodyPadding: true }">
  <ng-container title> {{ 'preferences' | ppTranslate }} </ng-container>

  <ng-container body>
    <div class="settingsGrid__row">
      <p class="settingsSection__label">{{ 'site_plan_zoom_speed' | ppTranslate }}</p>

      <i
        ppTooltip
        [ppMobileTooltip]="true"
        class="fa fa-info-circle settingsSection__header-info"
        aria-hidden="true"
        [ppTitle]="'site_plan_zoom_speed_description' | ppTranslate"
      ></i>
    </div>

    <label id="zoom-factor" class="">
      <form class="slider__container settingsSection__form-item" (ngSubmit)="updateZoomFactor()">
        <input
          id="slider"
          data-m-target="Site plan zoom slider"
          type="range"
          name="zoomFactor"
          min="1.1"
          max="3"
          step="0.1"
          [(ngModel)]="zoomFactor"
        />

        <pp-button
          ppId="saveZoomSpeedBtn"
          ppSize="small"
          [ppProcess]="updatingZoomFactor"
          (ppAction)="updateZoomFactor()"
        >
          {{ 'save' | ppTranslate }}
        </pp-button>
      </form>
    </label>

    <p class="settingsSection__label">{{ 'date_display_format' | ppTranslate }}</p>

    <div class="dateButtons">
      <div *ngFor="let dateFormat of dateFormats" class="dateFormatCheckbox">
        <pp-radio
          ppStyle="blue"
          [ppChecked]="selectedDateFormat === dateFormat.type"
          (ppOnValueChange)="setDateFormat(dateFormat.type)"
        >
          <label class="control-custom control-custom--radio controlCustom--no-height">
            {{ dateFormat.label }}
          </label>
        </pp-radio>
      </div>
    </div>
  </ng-container>
</pp-grid>
