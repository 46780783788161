<div
  #selectMultipleComponent
  [id]="ppId"
  class="select"
  (click)="toggleDropdown()"
  [class]="ppClass"
  [class.select--focused]="dropdown.visible && dropdown.buttonId === ppId"
>
  <div class="select__content" #selectContent>
    <span class="select__content--selected">
      <ng-container *ngIf="!checkItemsSelected()">
        <span class="select__placeholder"> {{ 'select_option' | ppTranslate }} </span>
      </ng-container>

      <ng-container *ngFor="let item of selectedItems; let last = last">
        <ng-container *ngIf="item.selected"> {{ item.label }}{{ last ? '' : ', ' }} </ng-container>
      </ng-container>
    </span>
  </div>

  <div class="select__caret">
    <div class="select__icon-container">
      <pp-icon
        [ppSrc]="
          dropdown.visible && dropdown.buttonId === ppId
            ? EIconPath.ICON_MISC_UP_ARROW_18
            : EIconPath.ICON_MISC_DOWN_ARROW_18
        "
        ppColor="grey-400"
        ppClass="setSize18"
      ></pp-icon>
    </div>
  </div>
</div>
