import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { LayoutModule } from '../../modules/layout/layout.module';
import { ModalModule } from '../modal/modal.module';
import { EditNameModalComponent } from './edit-name-modal.component';

@NgModule({
  declarations: [EditNameModalComponent],
  imports: [CommonModule, ModalModule, LayoutModule, PipesModule, FormsModule],
  exports: [EditNameModalComponent],
})
export class EditNameModalModule {}
