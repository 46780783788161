import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalContainerComponent } from './modal-container.component';
import { ModalComponent } from './modal.component';
import { ModalService } from './modal.service';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { ModalDirective } from './modal.directive';
import { AutonumericModule } from '../../directives/autonumeric/autonumeric.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SwitchCasesModule } from '../../directives/switch-cases/switch-cases.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { ModalGenericFooterComponent } from './modal-generic-footer/modal-generic-footer.component';
import { ButtonModule } from '../button/button.module';

@NgModule({
  declarations: [
    ModalComponent,
    ModalContainerComponent,
    ModalDirective,
    ModalGenericFooterComponent,
  ],
  imports: [
    CommonModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    PipesModule,
    ButtonModule,
  ],
  exports: [ModalComponent, ModalContainerComponent, ModalGenericFooterComponent],
  providers: [ModalService],
})
export class ModalModule {}
