<li class="dashletActivities__item">
  <div class="dashletActivities__item-wrapper">
    <div
      ppTooltip
      class="dashletActivities__item-avatar"
      ppTitle="{{ ppActivity.header.createdBy.caption || ppActivity.header.createdBy['userName'] }}"
    >
      <img
        ppImage
        class="dashletActivities__item-avatar--img"
        [src]="EIconPath.BASIC_FIELD_ASSIGNEES"
        *ngIf="!ppActivity.header.createdBy.primaryImageId"
      />

      <img
        ppImage
        class="dashletActivities__item-avatar--img"
        [ppSecureSrc]="
          'api/v1/images/' + ppActivity.header.createdBy.primaryImageId + '/file/size/square/40'
        "
        *ngIf="ppActivity.header.createdBy.primaryImageId"
      />
    </div>

    <div class="dashletActivities__item-center">
      <div
        class="dashletActivities__item-center-top"
        *ngIf="ppWorkspaces[ppActivity.workspaceRef.id]"
      >
        <span class="dashletActivities__item-acc-site">
          {{ ppWorkspaces[ppActivity.workspaceRef.id]?.accountName }} -
          {{ ppWorkspaces[ppActivity.workspaceRef.id]?.siteName }} -
        </span>

        <span
          class="dashletActivities__item-center-top-author"
          *ngIf="ppActivity.data.ref && ppActivity.data.ref.type === 'DefectType'"
          (click)="openPoint(ppActivity.workspaceRef.id, ppActivity.data.ref.id)"
        >
          {{ ppActivity.data.ref.sequenceNumber }} - {{ ppActivity.data.ref.caption }}
        </span>
      </div>

      <div class="dashletActivities__item-center-bottom">
        <div class="dashletActivities__item-activities">
          <pp-dashlet-activity-delete *ngIf="ppActivity.activityType === dashletAcitivities.DELETE">
          </pp-dashlet-activity-delete>

          <pp-dashlet-activity-copy
            [ppChange]="ppActivity.data.changeList[0]"
            *ngIf="ppActivity.activityType === dashletAcitivities.POINT_COPY"
          >
          </pp-dashlet-activity-copy>

          <pp-dashlet-activity-move
            [ppChange]="ppActivity.data.changeList[0]"
            *ngIf="ppActivity.activityType === dashletAcitivities.POINT_MOVE"
          >
          </pp-dashlet-activity-move>

          <pp-dashlet-activity-new-point
            *ngIf="
              ppActivity.activityType === dashletAcitivities.NEW_DOCUMENT &&
              !ppActivity.data.comment
            "
          ></pp-dashlet-activity-new-point>

          <pp-dashlet-activity-new-comment
            *ngIf="
              ppActivity.activityType === dashletAcitivities.NEW_DOCUMENT && ppActivity.data.comment
            "
            [ppRichText]="ppActivity.data.commentRich"
            [ppPlainText]="ppActivity.data.comment"
          ></pp-dashlet-activity-new-comment>

          <pp-dashlet-activity-update-changes
            *ngIf="ppActivity.activityType === dashletAcitivities.UPDATE_CHANGES"
            [ppAllUsers]="ppAllUsers"
            [ppActivity]="ppActivity"
          ></pp-dashlet-activity-update-changes>
        </div>

        <p
          ppTooltip
          [ppTitle]="ppActivity.header.timeStampHover"
          class="dashletActivities__item-date"
        >
          <i class="dashletActivities__item-dateIcon fa fa-clock" aria-hidden="true"></i>
          {{ ppActivity.header.formattedDatetime }}
        </p>
      </div>
    </div>
  </div>
</li>
