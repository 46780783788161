import { createElement } from '@core/helpers';
import { createElementWithBackgroundColor } from './create-element-with-background-color';

export function generateThirdGroupingEndElements(
  groupingLevel: number,
  collapsed: boolean,
): HTMLElement[] {
  const childrenList: HTMLElement[] = [];

  switch (groupingLevel) {
    case 1:
      handleFirstGroupingLevel(childrenList, collapsed);

      break;
    case 2:
      handleSecondGroupingLevel(childrenList, collapsed);

      break;
    case 3:
      handleThirdGroupingLevel(childrenList, collapsed);

      break;
  }

  return childrenList;
}
function handleThirdGroupingLevel(childrenList: HTMLElement[], collapsed: boolean): void {
  childrenList.push(
    createElementWithBackgroundColor({
      firstClass: 'table__cell__extra table__cell__extra--secondColor_zeroPosition',
      secondClass: `table__cell__extra table__cell__extra--border table__cell__extra--fourthColor_3_0  ${
        collapsed ? 'table__cell__extra--collapsed' : ''
      }`,
    }),
  );

  childrenList.push(
    createElement('div', {
      attrs: {
        class:
          'table__cell__extra table__cell__extra--secondColor_firstPosition table__cell__extra--border-right',
      },
    }),
  );

  childrenList.push(
    createElement('div', {
      attrs: {
        class:
          'table__cell__extra table__cell__extra--thirdColor_secondPosition table__cell__extra--border-right',
      },
    }),
  );

  childrenList.push(
    createElement('div', {
      attrs: {
        class: 'table__cell__extra table__cell__extra--firstColor_thirdPosition',
      },
    }),
  );
}

function handleSecondGroupingLevel(childrenList: HTMLElement[], collapsed: boolean): void {
  childrenList.push(
    createElement('div', {
      attrs: {
        class:
          'table__cell__extra table__cell__extra--thirdColor_secondPosition table__cell__extra--border-right table__cell__extra--adjustedSecondPosition',
      },
    }),
  );

  childrenList.push(
    createElement('div', {
      attrs: {
        class:
          'table__cell__extra table__cell__extra--firstColor_thirdPosition table__cell__extra--adjustedThirdPosition',
      },
    }),
  );

  childrenList.push(
    createElementWithBackgroundColor({
      firstClass: 'table__cell__extra table__cell__extra--3_1_background',
      secondClass: `table__cell__extra table__cell__extra--border table__cell__extra--secondColor_3_1  ${
        collapsed ? 'table__cell__extra--collapsed' : ''
      }`,
    }),
  );
}

function handleFirstGroupingLevel(childrenList: HTMLElement[], collapsed: boolean): void {
  childrenList.push(
    createElement('div', {
      attrs: {
        class: 'table__cell__extra table__cell__extra--firstColor_thirdPosition',
      },
    }),
  );

  childrenList.push(
    createElementWithBackgroundColor({
      firstClass: 'table__cell__extra table__cell__extra--firstColor_3_2_background',
      secondClass: `table__cell__extra table__cell__extra--border table__cell__extra--thirdColor_secondPosition  ${
        collapsed
          ? 'table__cell__extra--collapsed table__cell__extra--thirdColor_secondPosition--collapsed'
          : ''
      }`,
    }),
  );

  childrenList.push(
    createElement('div', {
      attrs: {
        class: `table__cell__extra table__cell__extra--3_1 table__cell__extra--border-top ${
          collapsed ? 'table__cell__extra--thirdColor_firstPosition--collapsed' : ''
        }`,
      },
    }),
  );
}
