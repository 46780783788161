import { statusOrder } from '../columns/property-sorting';
import { dehumanizeStatus } from 'src/app/project/modules/points/statuses';
import { TColumn } from '../columns/column.model';
import { TGroupedPoints } from './group-model';

export function groupByStatus(_column: TColumn, _a: TGroupedPoints, _b: TGroupedPoints): number {
  if (statusOrder[dehumanizeStatus(_a.value)] < statusOrder[dehumanizeStatus(_b.value)]) {
    return _column.groupOrder === 'ASC' ? -1 : 1;
  } else if (statusOrder[dehumanizeStatus(_a.value)] > statusOrder[dehumanizeStatus(_b.value)]) {
    return _column.groupOrder === 'ASC' ? 1 : -1;
  }
}
