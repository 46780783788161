import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ScreenService } from '@core/services';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { EAuthorizationProgressStep } from 'src/app/project/components/authorization-progress/authorization-progress.consts';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { EVolyIntegrationStatus } from '../../../integrations/utils/integrations.consts';
import { SidePanelService } from '../../../layout/side-panel/side-panel.service';
import { EUserRole } from '../../../share/share-utils/user-roles';
import { TWorkspace, TWorkspacesById } from '../../../workspace/workspace.model';
import { WorkspaceService } from '../../../workspace/workspace.service';
import { SiteSettingsIntegrationsAuditLogService } from './site-settings-integrations-audit-log/site-settings-integrations-audit-log.service';
import { EIntegrationStep } from './site-settings-integrations.consts';

@Component({
  selector: 'pp-site-settings-integrations',
  templateUrl: './site-settings-integrations.component.html',
  styleUrls: ['./site-settings-integrations.component.scss'],
})
export class SiteSettingsIntegrationsComponent implements OnInit, OnDestroy {
  disableInteractionButton = false;
  EIconPath = EIconPath;
  EIntegrationStep = EIntegrationStep;
  EAuthorizationProgressStep = EAuthorizationProgressStep;
  step: EIntegrationStep = EIntegrationStep.GET_STARTED;
  integrationStep = EAuthorizationProgressStep.INITIAL;
  workspace: TWorkspace;
  canSeeAuditLog = false;

  private readonly destroy$ = new Subject<void>();
  private workspaces$: Observable<TWorkspacesById>;
  private workspaceId: string | null = null;

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
    }>,
    private activatedRoute: ActivatedRoute,
    private sidePanelService: SidePanelService,
    private titleService: Title,
    private screenService: ScreenService,
    private workspaceService: WorkspaceService,
    private translationPipe: TranslationPipe,
    private siteSettingsAuditLogService: SiteSettingsIntegrationsAuditLogService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
  }

  ngOnInit(): void {
    this.siteSettingsAuditLogService.clearLogs();

    this.titleService.setTitle(
      this.translationPipe.transform('site_settings') + ' | Pinpoint Works',
    );

    this.screenService.isDesktop$.pipe(takeUntil(this.destroy$)).subscribe((isDesktop) => {
      if (!isDesktop) {
        this.sidePanelService.collapsePanel();
      }
    });

    this.activatedRoute.parent.paramMap.subscribe((params) => {
      this.workspaceId = params.get('id');

      this.handleWorkspaceChange();
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.handleWorkspaceChange();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  onIntegrationStart(): void {
    this.step = EIntegrationStep.AUTHORIZE_CONNECTION;
  }

  private handleWorkspaceChange(): void {
    this.workspace = this.workspaceService.findWorkspace(this.workspaceId);
    this.canSeeAuditLog = false;

    this.disableInteractionButton =
      this.workspace?.share?.shareOption !== EUserRole.OWNER &&
      this.workspace?.share?.shareOption !== EUserRole.ACCOUNT_ADMIN;

    if (this.workspace?.integrations?.voly?.status === EVolyIntegrationStatus.ON) {
      this.step = EIntegrationStep.AUTHORIZE_CONNECTION;
      this.integrationStep = EAuthorizationProgressStep.ACTIVE;
    } else if (this.workspace?.integrations?.voly?.pendingUntil) {
      this.integrationStep = EAuthorizationProgressStep.PENDING;
      this.step = EIntegrationStep.AUTHORIZE_CONNECTION;
    } else {
      if (this.disableInteractionButton) {
        this.step = EIntegrationStep.GET_STARTED;
      }

      this.integrationStep = EAuthorizationProgressStep.INITIAL;
    }

    this.fetchLogs();
  }

  private fetchLogs(): void {
    this.siteSettingsAuditLogService
      .getAuditLog(this.workspaceId)
      .pipe(
        tap((response) => {
          this.canSeeAuditLog = response.length > 0;

          if (this.canSeeAuditLog) {
            this.step = EIntegrationStep.AUTHORIZE_CONNECTION;
          } else {
            this.workspace?.integrations?.voly?.status === EVolyIntegrationStatus.ON ||
            this.workspace?.integrations?.voly?.pendingUntil
              ? (this.step = EIntegrationStep.AUTHORIZE_CONNECTION)
              : (this.step = EIntegrationStep.GET_STARTED);
          }
        }),
      )
      .subscribe();
  }
}
