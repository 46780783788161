import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { customFieldIconSrcBlue } from 'src/app/project/modules/custom-fields/custom-fields.data';
import { TPushNotification } from '../../notification.model';
import { getNotificationReasonText } from '../../utils/notification-reason';
import { ENotificationType } from '../notification-type.enum';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-notification-custom-field',
  templateUrl: './notification-custom-field.component.html',
  styleUrls: ['./notification-custom-field.component.scss'],
})
export class NotificationCustomFieldComponent implements OnInit, OnChanges {
  @Input() ppNotification: TPushNotification<string>;

  ENotificationType = ENotificationType;
  iconSrc: EIconPath = null;
  iconClass = '';
  notificationReason: string;
  ECustomFieldType = ECustomFieldType;

  constructor() {}

  ngOnInit() {
    this.notificationReason = getNotificationReasonText(this.ppNotification.notificationReason);
  }

  ngOnChanges(): void {
    this.createCustomFieldDescription();
  }

  private createCustomFieldDescription(): void {
    this.iconSrc = customFieldIconSrcBlue[this.ppNotification.changeBody.cfFieldType];
  }
}
