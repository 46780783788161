import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TAccount } from 'src/app/project/modules/account/account.model';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { TDashlet, TDashletSiteRange } from 'src/app/project/modules/preferences/preferences.model';
import { PermissionsService } from 'src/app/project/modules/share/permissions.service';
import { EStore } from 'src/app/project/shared/enums/store.enum';

@Component({
  selector: 'pp-activities-dashlet-modal-settings',
  templateUrl: './activities-dashlet-modal-settings.component.html',
  styleUrls: ['./activities-dashlet-modal-settings.component.scss'],
})
export class ActivitiesDashletModalSettingsComponent implements OnInit, OnDestroy {
  @Input() ppDashlet: TDashlet;
  @Output() ppSelectSiteRange = new EventEmitter<TDashletSiteRange>();

  blockedTimelines: string[] = [];
  isAccountsArray = false;
  accounts$: Observable<TAccount[]>;
  accounts: TAccount[] = null;
  workspaces$: Observable<TWorkspacesById>;
  workspaces: TWorkspacesById;

  siteRange: TDashletSiteRange;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store<{ accounts: TAccount[]; workspaces: TWorkspacesById }>,
    private permissionsService: PermissionsService,
  ) {
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
  }

  ngOnInit() {
    this.siteRange = {
      workspaceIds: this.ppDashlet.selectedRange.workspaceIds,
      accountId: this.ppDashlet.selectedRange.accountId,
    };

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces: TWorkspacesById) => {
      this.processWorkspaces(workspaces);
    });

    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
      this.processAccounts(accounts);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  updateSiteRange(data: TDashletSiteRange): void {
    this.siteRange = {
      workspaceIds: data.workspaceIds,
      accountId: data.accountId,
    };

    this.ppSelectSiteRange.emit(this.siteRange);
  }

  clearSiteRange(): void {
    this.siteRange = {
      workspaceIds: [],
      accountId: null,
    };

    this.ppSelectSiteRange.emit(this.siteRange);
  }

  private processWorkspaces(workspaces: TWorkspacesById): void {
    this.workspaces = workspaces;
    this.blockedTimelines = [];

    Object.keys(workspaces).forEach((workspaceId) => {
      const timelineEnabled = this.permissionsService.areActivitiesEnabled(workspaceId);
      const tagPermissions = this.permissionsService.getTagPermissions(workspaceId);
      const commentPermissions = this.permissionsService.getCommentPermissions(workspaceId);

      if ((!timelineEnabled || !tagPermissions.read) && !commentPermissions.read) {
        this.blockedTimelines.push(workspaceId);
      }
    });
  }

  private processAccounts(accounts: TAccount[]): void {
    this.accounts = accounts;
    this.isAccountsArray = Array.isArray(this.accounts);
  }
}
