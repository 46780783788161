import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TSelectOption } from 'src/app/project/components/input/select/select.model';

@Component({
  selector: 'pp-advanced-filter-option-select',
  templateUrl: './advanced-filter-option-select.component.html',
  styleUrls: ['./advanced-filter-option-select.component.scss'],
})
export class AdvancedFilterOptionSelectComponent {
  @Input() ppItems: TSelectOption[];
  @Input() ppClass: string;
  @Input() ppOption: any;

  @Output() ppOptionChange = new EventEmitter<any>();

  selectedOption: TSelectOption;

  ngOnChanges(): void {
    this.findSelectedOption();
  }

  changeFieldCondition(newCondition: TSelectOption): void {
    this.ppOption = newCondition.value;
    this.findSelectedOption();
    this.onOptionChanged();
  }

  findSelectedOption(): void {
    this.selectedOption = this.ppItems.find((item) => item.value === this.ppOption);
  }

  onOptionChanged(): void {
    this.ppOptionChange.emit(this.ppOption);
  }
}
