<div class="pointCF__content">
  <pp-datepicker
    class="timelineCF__datepicker"
    [ppDateMultiple]="value"
    (ppSelect)="updateDate($event)"
    ppPlaceholder="From / To"
    [ppHighlight]="fieldHighlight"
    [ppMultipleDatesInput]="true"
  ></pp-datepicker>
</div>

<button class="pointCF__clear" (click)="clearCustomField()" *ngIf="!ppReadOnly && !ppUpdating">
  &#xd7;
</button>
