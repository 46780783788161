<pp-modal>
  <ng-container heading>
    {{ (numberOfAttachments > 1 ? 'delete_attachments' : 'delete_attachment') | ppTranslate }}
  </ng-container>

  <ng-container body>
    <p>
      {{
        (numberOfAttachments > 1 ? 'delete_attachments_confirm' : 'delete_attachment_confirm')
          | ppTranslate
      }}
    </p>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'Delete' | ppTranslate"
      [ppProcess]="processing"
      (ppCancel)="hideModal()"
      (ppConfirm)="confirm()"
      [ppError]="true"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
