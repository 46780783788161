import { Component } from '@angular/core';
import { EIconPath } from '../../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-notification-new-point-assignee',
  templateUrl: './notification-new-point-assignee.component.html',
  styleUrls: ['../notification.component.scss'],
})
export class NotificationNewPointAssigneeComponent {
  EIconPath = EIconPath;
}
