import { Injectable } from '@angular/core';
import { TUpdateUserWithTwoFactorAuthenticationModalData } from './update-user-with-two-factor-authentication-modal.component.consts';

@Injectable({
  providedIn: 'root',
})
export class UpdateUserWithTwoFactorAuthenticationModalService {
  private code: string = '';
  private updateUserWithTwoFactorAuthenticationModalData: TUpdateUserWithTwoFactorAuthenticationModalData =
    {
      showingError: false,
      phoneNumber: '',
    };

  setCode(code: string): void {
    this.code = code;
  }

  getCode(): string {
    return this.code;
  }

  clearCode(): void {
    this.code = '';
  }

  setShowingError(showingError: boolean): void {
    this.updateUserWithTwoFactorAuthenticationModalData.showingError = showingError;
  }

  getUpdateUserWithTwoFactorAuthenticationModalData(): TUpdateUserWithTwoFactorAuthenticationModalData {
    return this.updateUserWithTwoFactorAuthenticationModalData;
  }

  clearUpdateUserWithTwoFactorAuthenticationModalData(): void {
    this.updateUserWithTwoFactorAuthenticationModalData = {
      showingError: false,
      phoneNumber: '',
    };
  }

  setPhoneNumber(phoneNumber: string): void {
    this.updateUserWithTwoFactorAuthenticationModalData.phoneNumber = phoneNumber;
  }

  getPhoneNumber(): string {
    return this.updateUserWithTwoFactorAuthenticationModalData.phoneNumber;
  }

  clearData(): void {
    this.clearUpdateUserWithTwoFactorAuthenticationModalData();
    this.clearCode();
  }
}
