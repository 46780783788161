<section class="columnsDropdown">
  <header>
    <h6>
      {{ 'table_columns' | ppTranslate }}
      <pp-info-icon
        ppClass="setSize14"
        ppTooltip
        [ppOptions]="{ placement: 'right' }"
        [ppMobileTooltip]="true"
        [ppSanitize]="false"
        ppTitle="<div style='text-align: left'>
        {{ 'table_columns_dropdown_tooltip_1' | ppTranslate }}
          <br/><br/>
          {{ 'table_columns_dropdown_tooltip_2' | ppTranslate }}
          <br/><br/>
          {{ 'table_columns_dropdown_tooltip_3' | ppTranslate }}
      </div>"
      ></pp-info-icon>
    </h6>

    <pp-saved-view-preview-header
      *ngIf="savedViewsEnabled"
      [ppHideText]="true"
      [ppWorkspaceId]="workspace?.workspaceId"
      (ppSaveToCurrentView)="saveColumnsToView()"
      [ppProcessing]="saveViewProcessing"
    ></pp-saved-view-preview-header>
  </header>
  <div class="columnsDropdown__border-wrapper">
    <div
      class="columnsDropdown__cell--border"
      [style.marginTop.px]="linePosition"
      *ngIf="draggedColumn !== null"
    ></div>
  </div>
  <div
    class="columnsDropdown__columns-table"
    [class.columnsDropdown__columns-table--globalPrefs]="globalSitePreferences"
    (scroll)="scrollHandler($event)"
  >
    <div class="columnsDropdown__table-content" #columnTable>
      <ng-container *ngFor="let column of sortedColumns; index as index">
        <div
          [class.columnsDropdownItem--opacity]="draggedColumn?.name === column.name"
          (dragover)="onDragOver($event, index, column)"
          (drop)="onDrop($event)"
          (dragend)="onDragEnd()"
        >
          <label
            id="{{ 'itemRow-' + column.name }}"
            class="columnsDropdown__label"
            draggable="true"
            (dragstart)="onDragStart($event, index, column)"
          >
            <div
              class="columnsDropdown__flex-container"
              ppTooltip
              [ppTitle]="setColumnIconTooltip(column)"
            >
              <pp-icon
                [ppSrc]="EIconPath.ICON_DRAG_18"
                ppColor="grey-400"
                [ppDisplayContents]="true"
                ppClass="setSize18"
              ></pp-icon>

              <pp-icon
                [ppSrc]="setColumnIcon(column)"
                ppColor="grey-600"
                [ppDisplayContents]="true"
                ppClass="columnIconName"
              ></pp-icon>

              <p
                ppTooltip
                [ppTrimTooltip]="true"
                [ppTitle]="column.name"
                class="columnsDropdown__name"
              >
                {{ column.name === 'P' ? 'Priority' : '' }}
                {{ column.name === 'S' ? 'Status' : '' }}
                {{ column.name !== 'P' && column.name !== 'S' ? column.name : '' }}
              </p>
            </div>

            <pp-toggle
              (ppAction)="updateColumnValue(column)"
              [ppChecked]="!column.hidden"
              ppStyle="columns"
              [ppDisabled]="
                columns.hidden.length === columns.data.length - 1 &&
                columns.hidden.indexOf(column.index) === -1
              "
            >
            </pp-toggle>
          </label>
        </div>
      </ng-container>
    </div>
  </div>

  <hr />

  <footer>
    <div *ngIf="!scrolledToBottom" class="columnsDropdown__shadow">&nbsp;</div>

    <div class="columnsDropdown__footer-buttons">
      <pp-button
        [ppTooltipTitle]="'reset_columns' | ppTranslate"
        ppStyle="text"
        ppId="resetTableBtn"
        (ppAction)="resetColumns()"
      >
        {{ 'clear_all' | ppTranslate }}
      </pp-button>

      <pp-button ppStyle="primary" ppId="resetTableDoneBtn" (ppAction)="hideDropdown()">
        {{ 'done' | ppTranslate }}
      </pp-button>
    </div>
  </footer>
</section>
