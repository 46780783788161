import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import { translate } from 'src/app/project/features/translate/translate';
import { EDashletPeriod } from '../dashboard-timeframes-enums';

dayjs.extend(weekOfYear);

export function createChartLabels(
  capitalizedPeriodWord: string,
  length: number,
  period: string,
): string[] {
  const labelArray: string[] = [];

  switch (period) {
    case EDashletPeriod.DAY:
      for (let i = length - 1; i >= 0; i -= 1) {
        labelArray.push(dayjs().subtract(i, 'd').format('ddd'));
      }

      break;
    case EDashletPeriod.WEEK:
      for (let i = length - 1; i >= 0; i -= 1) {
        labelArray.push(`${translate('week')} ${dayjs().subtract(i, 'w').week()}`);
      }

      break;
    case EDashletPeriod.MONTH:
      for (let i = length - 1; i >= 0; i -= 1) {
        labelArray.push(dayjs().subtract(i, 'M').format('MMM'));
      }

      break;
    case EDashletPeriod.YEAR:
      for (let i = length - 1; i >= 0; i -= 1) {
        labelArray.push(dayjs().subtract(i, 'y').year().toString());
      }

      break;
    default:
      for (let i = 1; i <= length; i += 1) {
        labelArray.push(`${capitalizedPeriodWord} ${i}`);
      }
  }

  return labelArray;
}
