<pp-modal [ppWide]="true">
  <ng-container heading> {{ 'add_dashlets' | ppTranslate }} </ng-container>

  <ng-container tabs>
    <ul class="modalTabs">
      <div class="modalTabs__select">
        <pp-double-select
          [ppFirstOption]="'SIMPLE_DASHLETS' | ppTranslate"
          [ppSecondOption]="'ADVANCED_DASHLETS' | ppTranslate"
          [ppFirstDataTarget]="'simple_dashlets_modal_tab' | ppTranslate"
          [ppSecondDataTarget]="'advanced_dashlet_modal_tab' | ppTranslate"
          (ppFirstChoice)="setTab(dashletTabs.SIMPLE_DASHLETS)"
          (ppSecondChoice)="setTab(dashletTabs.ADVANCED_DASHLETS)"
          [ppSelected]="currentTab === dashletTabs.ADVANCED_DASHLETS ? 1 : 0"
          [ppNoBottomBorder]="true"
          class="hideMobile"
        ></pp-double-select>
      </div>
    </ul>
  </ng-container>

  <ng-container body>
    <div class="modalTabs__tab-content" *ngIf="currentTab === dashletTabs.SIMPLE_DASHLETS">
      <p>
        {{ 'new_dashlet_simple_description' | ppTranslate }}
        <br />
        {{ 'multiple_dashlets_may_be_added' | ppTranslate }}
      </p>

      <div class="dashletTypes__container">
        <div
          ppTooltip
          [ppOptions]="{ placement: 'right', maxWidth: dashlet.value === 'ACTIVITIES' ? '' : 350 }"
          class="dashletTypes__dashlet"
          *ngFor="let dashlet of simpleDashlets"
          [ppSanitize]="false"
          ppTitle="<div class='dashletTypes__dashlet-preview-title'>{{
            dashlet.key | ppTranslate
          }} Preview</div> <img class='dashletTypes__dashlet-preview-img' src='{{
            dashlet.preview_url
          }}'>"
        >
          <p class="dashletTypes__title">
            {{ dashlet.key | ppTranslate }}
          </p>

          <span class="{{ dashlet.icon }}" aria-hidden="true" *ngIf="dashlet.icon"></span>

          <img
            ppImage
            class="dashletTypes__status-icon statusIcon statusIcon--md statusIcon--gray"
            [src]="dashlet.value | ppStatusImage: false"
            *ngIf="!dashlet.icon"
          />

          <pp-button
            data-m-target="Add new dashlet button"
            ppId="addDashletBtn-{{ dashlet.value }}"
            ppStyle="secondary"
            ppSize="small"
            (ppAction)="addDashlet(dashlet.value)"
          >
            {{ 'add' | ppTranslate }}
          </pp-button>
        </div>
      </div>
    </div>

    <div class="modalTabs__tab-content" *ngIf="currentTab === dashletTabs.ADVANCED_DASHLETS">
      <p>
        {{ 'new_dashlet_advanced_description' | ppTranslate }}
        <br />
        {{ 'multiple_dashlets_may_be_added' | ppTranslate }}
      </p>

      <div class="dashletTypes__container">
        <div
          ppTooltip
          [ppOptions]="{ placement: 'right' }"
          class="dashletTypes__dashlet"
          *ngFor="let dashlet of advancedDashlets"
          [ppSanitize]="false"
          ppTitle="<div class='dashletTypes__dashlet-preview-title'>{{
            dashlet.key | ppTranslate
          }} Preview</div> <img class='dashletTypes__dashlet-preview-img' src='{{
            dashlet.preview_url
          }}'>"
        >
          <p class="dashletTypes__title">
            {{ dashlet.key | ppTranslate }}
          </p>

          <img src="{{ dashlet.icon }}" *ngIf="dashlet.icon" class="dashletTypes__graph-icon" />

          <pp-button
            ppStyle="secondary"
            ppId="addDashletBtn-{{ dashlet.value }}"
            ppSize="small"
            (ppAction)="addDashlet(dashlet.value)"
          >
            {{ 'add' | ppTranslate }}
          </pp-button>
        </div>
      </div>
    </div>
  </ng-container>
</pp-modal>
