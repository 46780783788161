<div class="progressBar">
  <div
    role="progressbar"
    class="progressBar__progress"
    [ngClass]="{
      'progressBar--small': ppSize === 'small',
      'progressBar--normal': ppSize === 'normal'
    }"
    [style.width]="ppProgress + '%'"
  ></div>

  <div
    class="progressbar__content"
    [ngClass]="{
      'progressbar__content--small': ppSize === 'small',
      'progressbar__content--normal': ppSize === 'normal'
    }"
  >
    <ng-content></ng-content>
  </div>

  <div class="progressBar__cancel-upload" (click)="action()" *ngIf="ppPending">&#xd7;</div>
</div>
