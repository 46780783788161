import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { IntercomService } from '../../services/intercom.service';

import { AuthApiProviderService } from '@core/api';
import { routeToUrl } from 'src/app/core/helpers/route-to-url';
import { EAuthRoute } from '../../shared/constants/auth.constants';
import { SUPPORTED_BROWSERS_URL } from '../supported-browsers/supported-browsers.constants';

// Convert to LogoutService's private field after moving from fetch.ts to Angular HttpClient
export const logout$ = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
export class LogoutService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private isLoggingOut = false;

  constructor(
    private intercomService: IntercomService,
    private router: Router,
    private authApiProviderService: AuthApiProviderService,
  ) {
    logout$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.onLogout();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  logout(): void {
    this.isLoggingOut = true;

    this.authApiProviderService.logout().subscribe();

    logout$.next();
  }

  onLogout(): void {
    this.intercomService.shutdownIntercom();

    if (this.router.url !== SUPPORTED_BROWSERS_URL) {
      this.router.navigate([routeToUrl(EAuthRoute.LOGIN)]);
    }
  }

  getIsLoggingOut(): boolean {
    return this.isLoggingOut;
  }

  setIsLoggingOutComplete(): void {
    this.isLoggingOut = false;
  }
}
