import { cloneDeep } from 'lodash';
import { getDefaultAccountFeatures } from './account-utils/default-account-features';

import { EActionTypes, ActionsUnion } from './account.actions';
import { TAccount } from './account.model';
import { naturalSort } from '@core/helpers';

export const initialState: TAccount[] = [];

let newState: TAccount[] = [];
let account: TAccount = null;

export function accountReducer(state: TAccount[] = initialState, action: ActionsUnion): TAccount[] {
  switch (action.type) {
    case EActionTypes.SET_ACCOUNTS:
      newState = cloneDeep(action.payload);

      return newState.sort((a, b) => naturalSort(a.accountName, b.accountName));

    case EActionTypes.SET_ACCOUNT: {
      newState = cloneDeep(state);
      const newAccount = cloneDeep(action.payload);
      const accountIndex = newState.findIndex(
        (_account) => _account.accountId === newAccount.accountId,
      );

      if (accountIndex !== -1) {
        newAccount.accountFolders = newState[accountIndex].accountFolders;
        newAccount.workspaces = newState[accountIndex].workspaces;

        newState.splice(accountIndex, 1, newAccount);
      } else {
        newState.push(newAccount);
      }

      return newState.sort((a, b) => naturalSort(a.accountName, b.accountName));
    }

    case EActionTypes.CLEAR_ACCOUNTS:
      return [];

    case EActionTypes.UPDATE_ACCOUNT:
      newState = cloneDeep(state);

      account = newState.find(
        (stateAccount) => stateAccount.accountId === action.payload.accountId,
      );

      if (action.payload.account.accountFeatures) {
        account.accountFeatures = action.payload.account.accountFeatures;
      }

      if (action.payload.account.industryType) {
        account.industryType = action.payload.account.industryType;
      }

      if (action.payload.account.accountType) {
        account.accountType = action.payload.account.accountType;
      }

      if (action.payload.account.subscriptionType) {
        account.subscriptionType = action.payload.account.subscriptionType;
      }

      if (action.payload.account.accountManager) {
        account.accountManager = action.payload.account.accountManager;
      }

      return newState.sort((a, b) => naturalSort(a.accountName, b.accountName));

    case EActionTypes.UPDATE_ACCOUNT_NAME:
      newState = cloneDeep(state);

      account = newState.find(
        (stateAccount) => stateAccount.accountId === action.payload.accountId,
      );
      account.accountName = action.payload.accountName;

      return [...newState.sort((a, b) => naturalSort(a.accountName, b.accountName))];

    case EActionTypes.UPDATE_ACCOUNT_WEBSITE_URL:
      newState = cloneDeep(state);

      account = newState.find(
        (stateAccount) => stateAccount.accountId === action.payload.accountId,
      );

      if (account) {
        account.websiteUrl = action.payload.websiteUrl;
      }

      return [...newState];

    case EActionTypes.UPDATE_ACCOUNT_FOLDERS:
      newState = cloneDeep(state);

      account = newState.find(
        (stateAccount) => stateAccount.accountId === action.payload.accountId,
      );
      account.accountFolders = action.payload.accountFolders;

      return [...newState];

    case EActionTypes.UPDATE_ACCOUNT_LOGO:
      newState = cloneDeep(state);

      account = newState.find(
        (stateAccount) => stateAccount.accountId === action.payload.accountId,
      );
      account.logoRef = {
        id: action.payload.logoId,
      };

      return [...newState];

    case EActionTypes.SET_DEFAULT_ACCOUNT_FEATURES:
      newState = cloneDeep(state);

      account = newState.find(
        (stateAccount) => stateAccount.accountId === action.payload.accountId,
      );

      account.accountFeatures = getDefaultAccountFeatures();

      return [...newState];

    default:
      return state;
  }
}
