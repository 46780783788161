import { createElement } from 'src/app/core/helpers/dom';
import { GET_FILTERS } from '../../../../filters/filter-store';

export function createEmptyMessageNameElement(): HTMLElement {
  const filters = GET_FILTERS();
  let message = 'No points to display on the Timeline.';

  if (!filters || filters.numberOfFilters > 0) {
    message = 'No matching filter results.';
  }

  return createElement('div', {
    children: [message],
  });
}
