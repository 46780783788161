import { Component, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { GALLERY_OVERLAY_ESC, ListenerService } from 'src/app/core/helpers/listener.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { PointAttachmentsDeleteService } from '../point-attachments-delete.service';
import { PointAttachmentsService } from '../point-attachments.service';
import { PointDocumentsService } from './point-documents.service';

import { authorizeXMLHttpRequest } from 'src/app/core/helpers/authorize-xml-http-request';
import {
  API_files_custom_type,
  API_files_documents_file,
} from 'src/app/project/data-providers/api-providers/files-api-provider/files-paths';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { canEditPoint } from 'src/app/project/modules/share/share-utils/share-permissions';
import { UserService } from 'src/app/project/modules/user/user.service';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { DownloadService } from '../../../../../services/download.service';
import { EIconPath } from '../../../../../shared/enums/icons.enum';
import { TAttachment } from '../../../attachments/attachments.model';

@Component({
  selector: 'pp-point-documents',
  templateUrl: './point-documents.component.html',
  styleUrls: ['./point-documents.component.scss'],
})
export class PointDocumentsComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  documentVisible = false;
  file: TAttachment;
  fileType: string;
  canDelete = false;
  loaded = false;

  fileName = '';
  textMessage = '';
  EIconPath = EIconPath;

  constructor(
    private pointDocumentsService: PointDocumentsService,
    private promptService: PromptService,
    private workspaceService: WorkspaceService,
    private pointAttachmentsDeleteService: PointAttachmentsDeleteService,
    private listenerService: ListenerService,
    private pointAttachmentsService: PointAttachmentsService,
    private translationPipe: TranslationPipe,
    private downloadService: DownloadService,
    private userService: UserService,
  ) {
    this.pointDocumentsService.documentOpenedObservable$
      .pipe(takeUntil(this.destroy$))
      .subscribe((loaded) => {
        if (!loaded) {
          this.loaded = false;
        }

        if (loaded) {
          if (this.documentVisible) {
            this.file = this.pointDocumentsService.getFile();
            const workspace = this.workspaceService.findWorkspace(this.file.workspaceId);
            const user = this.userService.getUser();
            this.canDelete = canEditPoint(workspace.share.shareOption, user);

            if (this.pointDocumentsService.isPdf(this.file)) {
              this.loaded = true;
              this.fileType = 'pdf';
            } else if (this.pointDocumentsService.isText(this.file)) {
              this.fileType = 'text';

              this.listenerService.add(GALLERY_OVERLAY_ESC, () => this.closeDocument());

              this.openTextFile(this.file);
            } else {
              const promptText = this.translationPipe.transform('prompt_file_processing_error');

              this.promptService.showError(promptText);
              this.closeDocument();
            }
          } else {
            this.closeDocument();
          }
        } else {
          this.documentVisible = true;
        }
      });
  }

  ngOnDestroy() {
    this.listenerService.remove(GALLERY_OVERLAY_ESC);

    this.destroy$.next();
  }

  closeDocument(): void {
    this.documentVisible = false;
    this.textMessage = '';
    this.fileType = '';

    this.pointDocumentsService.closeDocument();
  }

  openTextFile(file: TAttachment): void {
    const encodedUri = encodeURI(API_files_documents_file(file.attachmentId));
    let request = new XMLHttpRequest();

    request.open('GET', encodedUri, true);
    request = authorizeXMLHttpRequest(request);
    request.responseType = 'blob';

    request.onload = (): void => {
      const fileReader = new FileReader();
      const spreadsheet = new File([request.response], file.fileName);

      fileReader.onload = (event: ProgressEvent<FileReader>): void => {
        this.textMessage = event.target.result.toString();
        this.loaded = true;
      };

      fileReader.readAsBinaryString(spreadsheet);
    };

    request.send();
  }

  downloadFile(): void {
    const type = 'document';
    const encodedUri = encodeURI(API_files_custom_type(type, this.file.attachmentId));
    const mediaSize = this.pointAttachmentsService.calculateSelectedSize([this.file]);
    const promptText = this.translationPipe.transform('prompt_attachments_size_info', {
      size: mediaSize,
    });

    const attachmentName = this.file.fileName || ' unnamed';

    this.promptService.showSuccess(promptText);

    logEventInGTAG(EGoogleEventSite.SITE__POINT__ATTACHMENT_DOWNLOAD, {
      event_category: EGoogleEventCategory.SITE,
      amount: 1,
    });

    this.downloadService.downloadGET({ url: encodedUri, fileName: attachmentName }).subscribe();
  }

  deleteFile(): void {
    this.pointAttachmentsDeleteService
      .deleteAttachment(this.file)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.closeDocument();
        }),
      )
      .subscribe();
  }
}
