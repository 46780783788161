import { createElement } from 'src/app/core/helpers/dom';
import { addCommonStyles } from './dashboard-export.utils';

export function createValueElement(value, scale) {
  const dateElement = createElement('div');

  dateElement.style.fontSize = `${200 * scale}px`;
  dateElement.style.lineHeight = `${251 * scale}px`;
  dateElement.style.top = `${292 * scale}px`;
  dateElement.style.fontWeight = 'bold';
  dateElement.style.fontFamily = 'Source Sans Pro, sans-serif';

  addCommonStyles(dateElement);

  dateElement.innerHTML = value;

  return dateElement;
}
