import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TColumn } from '../../../site-table/columns/column.model';
import { TableColumnsService } from '../../../site-table/columns/table-columns.service';
import { TableSortingService } from '../../../site-table/columns/table-sorting.service';
import { CustomTableService } from '../../../site-table/custom-table/custom-table.service';
import { ESortOrder } from '../../../site-table/sort-order-enum';
import { generateSorting } from '../../../site-table/sorting/sorting';
import { SiteOptionsService } from '../../site-options.service';

@Injectable({
  providedIn: 'root',
})
export class SortButtonDropdownService {
  private readonly _sortingChanged$ = new Subject<void>();
  readonly sortingDataChanged$ = this._sortingChanged$.asObservable();

  constructor(
    private customTableService: CustomTableService,
    private siteOptionsService: SiteOptionsService,
    private tableSortingService: TableSortingService,
    private tableColumnsService: TableColumnsService,
  ) {}

  addSort(column: TColumn, order: 'ASC' | 'DESC', apply = true): void {
    const sorting = this.tableSortingService.getSorting();

    this.tableColumnsService.setColumnSortIndex(column.index, sorting.length);
    this.tableColumnsService.setColumnSortOrder(column.index, order);

    if (apply) {
      this.applySorting();
    }
  }

  replaceSort(column: TColumn, oldColumn: TColumn): void {
    const order = oldColumn.sortOrder ? oldColumn.sortOrder : 'ASC';
    const index = oldColumn.sortIndex;

    this.removeSort(oldColumn, false);
    this.addSort(column, order, false);

    generateSorting();

    this.moveSort(column, index);
  }

  changeSortOrder(order: ESortOrder, column: TColumn): void {
    this.tableColumnsService.setColumnSortOrder(column.index, order);
    this.applySorting();
  }

  removeSorting(): void {
    this.tableColumnsService.clearColumnSortOrderAndIndex();
    this.tableSortingService.clearSorting();

    this.applySorting();
  }

  removeSort(_column: TColumn, apply: boolean = true): void {
    this.tableSortingService.removeSort(_column);
    this.tableColumnsService.rearrangeColumnsSorts();

    if (apply) {
      this.applySorting();
    }
  }

  moveSort(draggedColumn: TColumn, destinationIndex: number): void {
    const columnIndex = this.tableSortingService.getSorting().indexOf(draggedColumn);

    this.tableSortingService.moveSortColumn(columnIndex, destinationIndex);
    this.tableColumnsService.rearrangeColumnsSorts();

    this.applySorting();
  }

  private applySorting(): void {
    const sortButton = this.siteOptionsService.getSortButton();
    const table = this.customTableService.getTable();

    generateSorting();

    table.sortTable({ updatePreferences: true, keepScrollPosition: false });
    table.load(false);

    sortButton.update();
    this._sortingChanged$.next();
  }
}
