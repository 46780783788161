<section class="options-dropdown_section">
  <div class="table__wrapper" (scroll)="scrollHandler($event)">
    <table aria-label="Timeline grouping table" class="table">
      <thead>
        <th scope="col" class="timeframe-dropdown__heading">{{ 'group_by' | ppTranslate }}</th>
      </thead>

      <tbody>
        <tr
          *ngFor="let option of options"
          class="siteOptionsTable__item"
          (click)="setGrouping(option)"
        >
          <pp-radio [ppChecked]="preferences.timeline?.group === option.value" ppStyle="blue">
            <p class="siteOptionsTable__columnName">
              {{ option.name }}
            </p>
          </pp-radio>
        </tr>
      </tbody>
    </table>
  </div>
</section>
