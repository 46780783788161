import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { blurInput } from 'src/app/core/helpers/blur-input';
import { preventNonNumberInput } from 'src/app/core/helpers/check-if-number';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-filter-field-numbers-input',
  templateUrl: './filter-field-numbers-input.component.html',
  styleUrls: ['./filter-field-numbers-input.component.scss'],
})
export class FilterFieldNumbersInputComponent {
  @ViewChild('input') inputElement: ElementRef;
  @Input() ppUnit: string;
  @Input() ppPlaceholder: string;
  @Input() ppValue: number;
  @Input() ppLeftUnit: boolean;
  @Output() ppValueChange = new EventEmitter();

  EIconPath = EIconPath;
  focused = false;

  focusInput(): void {
    this.inputElement.nativeElement.focus();
  }

  blurInput(event: Event): void {
    blurInput(event.target);
  }

  updateField(): void {
    this.ppValueChange.emit(this.ppValue);
  }

  onInputFocused(inputIsFocused: boolean): void {
    this.focused = inputIsFocused;
  }

  preventEvent(event: MouseEvent): void {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  preventNonNumberInput(event: KeyboardEvent): void {
    preventNonNumberInput(event);
  }
}
