<div
  [id]="id"
  class="select advancedFilterListSelect"
  (click)="showList()"
  [class.select--focused]="dropdown.visible && dropdown.buttonId === id"
>
  <span
    class="advancedFilterListSelect__text"
    [class.advancedFilterListSelect__text--placeholder]="!ppSelected"
    ppTooltip
    [ppTitle]="ppSelected ? ppSelected.path : ''"
    [ppTrimTooltip]="true"
  >
    {{ ppSelected ? ppSelected.path : ('select_option' | ppTranslate) }}
  </span>

  <div class="select__icon-container">
    <pp-icon
      [ppSrc]="
        dropdown.visible && dropdown.buttonId === id
          ? EIconPath.ICON_MISC_UP_ARROW_18
          : EIconPath.ICON_MISC_DOWN_ARROW_18
      "
      ppColor="grey-400"
      ppClass="setSize18"
    ></pp-icon>
  </div>
</div>
