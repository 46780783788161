<div class="tabContent tabContent--noPadding">
  <pp-account-settings-sites-placeholder *ngIf="processing"></pp-account-settings-sites-placeholder>

  <ng-container *ngIf="!processing">
    <cdk-virtual-scroll-viewport itemSize="40" class="accountList__scroll">
      <table class="settingsTable settingsTable--wide" aria-label="Account sites table">
        <thead class="settingsTable__head" [style.top]="inverseOfTranslation">
          <tr>
            <th
              class="settingsTable__header secondaryBody--semibold settingsTable__column--sites settingsTable__column--siteName"
              scope="col"
            >
              {{ 'site_name' | ppTranslate }}
              <span class="siteListCounter" *ngIf="canSeeArchived">
                {{
                  'account_settings_sites_header'
                    | ppTranslate
                      : {
                          all: showArchivedData
                            ? unfilteredSites.length
                            : unfilteredSites.length + archivedSitesCount,
                          archived: archivedSitesCount
                        }
                }}
              </span>

              <span class="siteListCounter" *ngIf="!canSeeArchived"
                >({{ unfilteredSites.length }})</span
              >
            </th>
            <th
              class="settingsTable__header secondaryBody--semibold settingsTable__column--sites"
              scope="col"
            >
              {{ 'number_of_users' | ppTranslate }}
            </th>
            <th
              class="settingsTable__header secondaryBody--semibold settingsTable__column--sites"
              scope="col"
            >
              {{ 'total_points' | ppTranslate }}
            </th>
            <th
              class="settingsTable__header secondaryBody--semibold settingsTable__column--sites"
              scope="col"
            >
              {{ 'used_storage' | ppTranslate }}
            </th>
          </tr>
        </thead>

        <tbody class="settingsTable__body">
          <ng-container *cdkVirtualFor="let site of visibleSites; let index = index">
            <pp-account-settings-site
              class="settingsTable_site"
              [ppSite]="site"
              (ppNavigate)="navigateToSite($event)"
              [ppCanSeeArchived]="canSeeArchived"
            ></pp-account-settings-site>
          </ng-container>
        </tbody>
      </table>
    </cdk-virtual-scroll-viewport>

    <pp-account-settings-site-footer
      *ngIf="!processing"
      [ppNumberOfVisibleSites]="visibleSites.length"
    ></pp-account-settings-site-footer>

    <div class="accountUsers__spinner" *ngIf="processing">
      <app-spinner></app-spinner>
    </div>
  </ng-container>
</div>

<pp-account-settings-sites-bulk-changes
  [ppAccountId]="accountId"
  [ppUnarchivedSitesCount]="unarchivedSitesCount"
  (ppUpdate)="refreshSiteData()"
></pp-account-settings-sites-bulk-changes>
