import { Routes } from '@angular/router';

import { COMMON_GUARD_SERVICES } from '../../services/guards/guard-services.const';
import { WhiteLabelEditComponent } from '../white-label/white-label-edit/white-label-edit.component';

import { AccountUserManagementComponent } from '../user-management/account-user-management/account-user-management.component';
import { AccountGeneralSettingsComponent } from './account-settings/account-general-settings/account-general-settings.component';
import { AccountSettingsSitesComponent } from './account-settings/account-settings-pages/account-settings-sites/account-settings-sites.component';
import { AccountSettingsSubscriptionComponent } from './account-settings/account-settings-pages/account-settings-subscription/account-settings-subscription.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';

export const accountRoutes: Routes = [
  {
    path: 'settings/account/:id',
    component: AccountSettingsComponent,
    canActivate: COMMON_GUARD_SERVICES,
    children: [
      {
        path: '',
        redirectTo: 'user-management',
        pathMatch: 'full',
      },
      {
        path: 'sites',
        component: AccountSettingsSitesComponent,
      },
      {
        path: 'skins',
        component: WhiteLabelEditComponent,
      },
      {
        path: 'options',
        component: AccountGeneralSettingsComponent,
      },
      {
        path: 'subscription',
        component: AccountSettingsSubscriptionComponent,
      },
      {
        path: 'user-management',
        component: AccountUserManagementComponent,
      },
    ],
  },
];
