<div class="sharesHeaders__comments">
  <div
    class="editAccountUserShareList__shareHeadersCommentsSelect"
    ppTooltip
    [ppTitle]="ppDisabled ? disabledTooltipText : ''"
  >
    <pp-select
      [ppItems]="shareOptions"
      [ppSelectedItem]="shareOptions[index]"
      (ppSelectItem)="updatePermissions($event)"
      [ppNoSearchBar]="true"
      [ppDisabled]="ppDisabled"
      require
    >
      <ng-container selected>
        <span class="accountSettings__select-content" *ngIf="index !== -1">
          {{ shareOptions[index].label }}
        </span>
      </ng-container>
    </pp-select>
  </div>
</div>
