import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { LimitBarComponent } from './limit-bar.component';

@NgModule({
  declarations: [LimitBarComponent],
  imports: [PipesModule, FormsModule, CommonModule],
  exports: [LimitBarComponent],
})
export class LimitBarModule {}
