import { Component, Input, OnInit } from '@angular/core';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { getNotificationReasonText } from '../../utils/notification-reason';
import { ENotificationReason } from '../../utils/notification-reason.enum';

@Component({
  selector: 'pp-notification-comment',
  templateUrl: './notification-comment.component.html',
  styleUrls: ['../notification.component.scss'],
})
export class NotificationCommentComponent implements OnInit {
  @Input() ppNotificationReason: ENotificationReason;

  notificationReason: string;
  EIconPath = EIconPath;

  constructor() {}

  ngOnInit(): void {
    this.notificationReason = getNotificationReasonText(this.ppNotificationReason);
  }
}
