import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pp-fleet-export-cover-page-picker',
  templateUrl: './fleet-export-cover-page-picker.component.html',
  styleUrl: './fleet-export-cover-page-picker.component.scss',
})
export class FleetExportCoverPagePickerComponent {
  @Input() ppIncludeCoverPage: boolean;
  @Output() ppIncludeCoverPageChange = new EventEmitter<boolean>();

  toggleValue(): void {
    this.ppIncludeCoverPageChange.emit(!this.ppIncludeCoverPage);
  }
}
