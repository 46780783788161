import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { PreferencesService } from '../../../preferences/preferences-service/preferences.service';
import { TPreferences } from '../../../preferences/preferences.model';
import { EWorkspaces } from '../../../workspace/workspaces.enum';
import { ResetTableService } from '../../reset-table.service';
import { CALCULATE_COLUMNS } from '../../site-timeline/timeframes/timeline-columns';
import { GET_TIMELINE } from '../../site-timeline/timeline.ui.store';

@Injectable({
  providedIn: 'root',
})
export class ResetTimelineService {
  constructor(
    private resetTableService: ResetTableService,
    private preferencesService: PreferencesService,
  ) {}

  resetTimeline(): void {
    this.resetTableService.resetFilters(EWorkspaces.TIMELINE);
    this.resetTimeframeAndGroup().subscribe();
  }

  private resetTimeframeAndGroup(): Observable<TPreferences> {
    const body: TPreferences = {
      timeline: {
        timeframe: null,
        group: null,
      },
    };

    return this.preferencesService.updatePreferences(body).pipe(
      switchMap((response) => {
        const timeline = GET_TIMELINE();

        CALCULATE_COLUMNS();
        timeline.load(false);

        return of(response);
      }),
    );
  }
}
