import { translate } from 'src/app/project/features/translate/translate';
import { EIntegrationStatus, TCustomField } from '../../../custom-fields/custom-fields.model';

export function checkCustomFieldVolyTooltip(customField: TCustomField): string {
  if (!customField) {
    return '';
  }

  if (customField.volyIntegrationActive === EIntegrationStatus.ON) {
    return translate('custom_field_voly_tooltip');
  } else if (customField.volyIntegrationActive === EIntegrationStatus.DISABLED) {
    return '';
  }

  return '';
}
