import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TSelectOption } from 'src/app/project/components/input/select/select.model';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';
import {
  TAdvancedFilterDate,
  TAdvancedFilterSingle,
  TFilterDateOptions,
} from '../../../../models/advanced-filter.model';
import {
  getAdvancedDateCreatedOptions,
  getAdvancedDateOptions,
  getAdvancedDateUpdatedOptions,
} from './advanced-filter-date-utils';

@Component({
  selector: 'pp-advanced-filter-date',
  templateUrl: './advanced-filter-date.component.html',
  styleUrls: ['./advanced-filter-date.component.scss'],
})
export class AdvancedFilterDateComponent implements OnChanges {
  @Input() ppFilter: TAdvancedFilterSingle;
  @Output() ppFilterChange = new EventEmitter<TAdvancedFilterSingle>();
  @Input() ppType: 'date_created' | 'date_updated' | 'date';

  option: TFilterDateOptions;
  filter: TAdvancedFilterDate;

  startDate: number;
  endDate: number;
  today = new Date().getTime();

  advancedFilterDateOptions: TSelectOption[];
  EAdvancedFilterOptions = EAdvancedFilterOptions;

  ngOnChanges(): void {
    switch (this.ppType) {
      case 'date_created':
        this.advancedFilterDateOptions = getAdvancedDateCreatedOptions();
        break;
      case 'date_updated':
        this.advancedFilterDateOptions = getAdvancedDateUpdatedOptions();
        break;
      case 'date':
      default:
        this.advancedFilterDateOptions = getAdvancedDateOptions();
        break;
    }

    if (!this.ppFilter.value) {
      this.ppFilter.value = [null, null];
    }

    this.filter = this.ppFilter as TAdvancedFilterDate;

    this.startDate = this.filter.value?.[0] ? this.filter.value[0] : null;
    this.endDate = this.filter.value?.[1] ? this.filter.value[1] : null;
    this.option = this.filter.option;
  }

  changeFieldCondition(newCondition: TFilterDateOptions): void {
    this.filter.option = newCondition;
    this.option = newCondition;
    this.applyChanges();
  }

  applyChanges(): void {
    this.ppFilterChange.emit(this.filter);
  }

  changeFieldValue(value: Date[], index): void {
    const date = value[0];
    date.setHours(0, 0, 0, 0);

    this.filter.value[index] = date.getTime();

    switch (index) {
      case 0:
        this.startDate = value[0].getTime();
        break;
      case 1:
        this.endDate = value[0].getTime();
        break;
    }
    this.applyChanges();
  }
}
