import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { ETwoFactorAuthenticationChannel } from '../two-factor-authentication.consts';

@Component({
  selector: 'pp-two-factor-authentication-mode-picker',
  templateUrl: './two-factor-authentication-mode-picker.component.html',
  styleUrls: ['./two-factor-authentication-mode-picker.component.scss'],
})
export class TwoFactorAuthenticationModePickerComponent {
  @Input() ppValue: string;
  @Output() ppValueChange = new EventEmitter<string>();

  EIconPath = EIconPath;
  ETwoFactorAuthenticationChannel = ETwoFactorAuthenticationChannel;

  selectOption(option: string): void {
    this.ppValue = option;
    this.ppValueChange.emit(this.ppValue);
  }
}
