import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject, debounceTime, takeUntil, tap } from 'rxjs';
import { formatCurrency } from 'src/app/core/helpers/format-currency';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { TAssetSite } from 'src/app/project/modules/fleet-management/asset-service/asset.consts';
import { AssetService } from 'src/app/project/modules/fleet-management/asset-service/asset.service';
import { AssetCardRedirectService } from '../../../asset-card-redirect.service';

@Component({
  selector: 'pp-asset-card-sites-table-row',
  templateUrl: './asset-card-sites-table-row.component.html',
  styleUrls: ['./asset-card-sites-table-row.component.scss'],
})
export class AssetCardSitesTableRowComponent implements OnInit {
  @Input() ppAssetSite: TAssetSite;
  @Output() ppNavigate = new EventEmitter<string>();

  keyword: string = '';
  budget: string = '';
  customFieldName: string = '';

  private destroy$ = new Subject<void>();
  private searchKeywordDebounceTime: number = 500;

  constructor(
    private assetCardRedirectService: AssetCardRedirectService,
    private assetService: AssetService,
    private customFieldsService: CustomFieldsService,
  ) {
    this.assetService.keywordChange$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(this.searchKeywordDebounceTime),
        tap((keyword) => {
          this.keyword = keyword;
        }),
      )
      .subscribe();
  }

  ngOnInit() {
    this.formatBudget();

    this.checkBudgetCustomFieldName();
  }

  private checkBudgetCustomFieldName(): void {
    if (!this.ppAssetSite.budgetCustomFieldId) {
      return;
    }

    const customFieldId = this.ppAssetSite.budgetCustomFieldId;
    const workspaceId = this.ppAssetSite.workspaceId;
    const customField = this.customFieldsService.getWorkspaceCustomField(
      workspaceId,
      customFieldId,
    );

    this.customFieldName = customField?.label;
  }

  navigateToSite(): void {
    this.ppNavigate.emit(this.ppAssetSite.workspaceId);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  formatBudget(): void {
    if (typeof this.ppAssetSite.budget === 'number') {
      this.budget = formatCurrency(
        this.ppAssetSite.budget,
        this.ppAssetSite.budgetCurrencySymbol,
        2,
      );

      this.budget = this.budget.replace(/\.00$/, '');
    }
  }

  openSiteWithRedFlags(): void {
    this.assetCardRedirectService.openSiteWithRedFlags(this.ppAssetSite.workspaceId);
  }
}
