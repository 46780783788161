import { Injectable } from '@angular/core';
import { WorkspaceApiProviderService } from '@core/api';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { ResponseErrorService } from 'src/app/project/modules/errors/response-error.service';
import { setChangedWorkspaces } from 'src/app/project/modules/workspace/workspace';
import { DeleteWorkspaces } from 'src/app/project/modules/workspace/workspace.actions';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { TAccountUsageResponse } from '../../../account-usage/account-usage.consts';
import { AccountUsageService } from '../../../account-usage/account-usage.service';

import { AccountSubscriptionService } from '../../../account-subscription.service';
import { TAccountLimits, TAccountSubscription } from '../../../account.model';

@Injectable({
  providedIn: 'root',
})
export class ArchiveSitesService {
  constructor(
    private workspaceApiProviderService: WorkspaceApiProviderService,
    private promptService: PromptService,
    private responseErrorService: ResponseErrorService,
    private accountUsageService: AccountUsageService,
    private translationPipe: TranslationPipe,
    private accountSubscriptionService: AccountSubscriptionService,
    private activeService: ActiveService,
    private store: Store,
    private workspaceService: WorkspaceService,
  ) {}

  archiveSites(accountId: string, workspaceIds: string[]): Observable<TAccountUsageResponse> {
    return this.workspaceApiProviderService.archiveSites(workspaceIds).pipe(
      map((response) => {
        this.accountUsageService.updateSiteData(accountId, response);
        this.displayArchiveSuccessPrompt(true);
        this.tryToClearActiveWorkspace(workspaceIds);

        this.store.dispatch(
          new DeleteWorkspaces({
            workspaceIds,
          }),
        );

        return response;
      }),
      catchError((error) => {
        return this.handleError(error);
      }),
    );
  }

  unarchiveSites(accountId: string, workspaceIds: string[]): Observable<TAccountUsageResponse> {
    return this.workspaceApiProviderService.unarchiveSites(workspaceIds).pipe(
      map((response) => {
        this.accountUsageService.updateSiteData(accountId, response);
        setChangedWorkspaces(true);
        this.displayArchiveSuccessPrompt(false);

        return response;
      }),
      catchError((error) => {
        if (error.status === EStatusCode.UPGRADE_REQUIRED) {
          throw error;
        } else {
          return this.handleError(error);
        }
      }),
    );
  }

  handleUnarchiveSiteBeyondLimit(
    accountId: string,
    workspaceIds: string[],
    numberOfSitesAfterUnarchive: number,
  ): Observable<TAccountUsageResponse> {
    return this.accountSubscriptionService.fetchSubscription(accountId).pipe(
      switchMap((subscription) => {
        const increasedLimits: TAccountLimits = {
          SHARES_ACCOUNT_ADMIN: subscription.usages.SHARES_ACCOUNT_ADMIN,
          SHARES_ADMIN: subscription.usages.SHARES_ADMIN,
          SHARES_GUEST: subscription.usages.SHARES_GUEST,
          SHARES_NORMAL: subscription.usages.SHARES_NORMAL,
          SITES: numberOfSitesAfterUnarchive,
        };

        return this.updateSubscription(accountId, workspaceIds, increasedLimits);
      }),
    );
  }

  private updateSubscription(
    accountId: string,
    workspaceIds: string[],
    limits: TAccountLimits,
  ): Observable<TAccountUsageResponse> {
    const newSubscription = {
      limits: {
        ...limits,
      },
      accountId: accountId,
    };

    return this.accountSubscriptionService.updateSubscription(newSubscription, accountId).pipe(
      switchMap(() => {
        return this.unarchiveSites(accountId, workspaceIds);
      }),
    );
  }

  private handleError(error: any): Observable<null> {
    const prompt = this.translationPipe.transform('archive_sites_error');
    this.promptService.showError(prompt);

    if (error.status === EStatusCode.BAD_REQUEST) {
      return of(null);
    }

    return this.responseErrorService.handleRequestError(error);
  }

  private displayArchiveSuccessPrompt(siteArchived: boolean): void {
    const promptText = siteArchived ? 'prompt_sites_archived' : 'prompt_sites_unarchived';
    const translatedPromptText = this.translationPipe.transform(promptText);
    this.promptService.showSuccess(translatedPromptText);
  }

  private tryToClearActiveWorkspace(workspaceIds: string[]): void {
    const activeWorkspaceId = this.activeService.getActiveWorkspaceId();

    if (workspaceIds.includes(activeWorkspaceId)) {
      const workspaces = this.workspaceService.getWorkspaces();

      const newWorkspaceId = Object.values(workspaces).find((workspace) => {
        return workspace.workspaceId !== activeWorkspaceId;
      })?.workspaceId;

      this.activeService.setActiveWorkspaceId(newWorkspaceId);
    }
  }
}
