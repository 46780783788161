import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { TFilters } from '../site-filter.model';

export function checkAccounts(filters: TFilters, workspace: TWorkspace): boolean {
  if (filters.sites.length === 0 || !workspace) {
    return true;
  }

  const accountName = workspace.accountName;

  for (let index = 0; index < filters.sites.length; index++) {
    const accountFilter = filters.sites[index];

    if (accountFilter.accountName === accountName && accountFilter.appliedValue) {
      return true;
    }
  }

  return false;
}
