import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EGoogleEventLabel } from 'src/app/project/services/analytics/google-analytics.consts';
import { EUserRole } from '../../share-utils/user-roles';
import { TCustomFieldAccessOption, TShare } from '../../share.model';
import { EAccessParameter } from './access-parameters';
import { correctCFAccessLogic } from './correct-cf-access-logic';
import { correctCommentsAccessLogic } from './correct-comments-access-logic';
import { correctExportsVisibility } from './correct-exports-visibility';
import { correctSitePlanVisibility } from './correct-site-plan-visibility';
import { correctTagsAccessLogic } from './correct-tags-access-logic';
import { correctTimelineVisibility } from './correct-timeline-visibility';
import { clearTags } from './edit-share-clear-tags';

@Injectable({
  providedIn: 'root',
})
export class ShareEditService {
  private readonly _shareChange$ = new Subject<TShare>();
  readonly shareChange$ = this._shareChange$.asObservable();

  constructor() {}

  correctProperty({
    workspaceId,
    share,
    property,
    parameter,
    field,
  }: {
    workspaceId: string;
    share: TShare;
    property: EGoogleEventLabel;
    parameter?: EAccessParameter;
    field?: TCustomFieldAccessOption;
  }): TShare {
    switch (property) {
      case EGoogleEventLabel.CF:
        share.advancedAccessLevels.customFields = correctCFAccessLogic(field, parameter, share);
        break;
      case EGoogleEventLabel.COMMENT:
        share.advancedAccessLevels.timeline.comments = correctCommentsAccessLogic(parameter, share);
        break;
      case EGoogleEventLabel.TIMELINE:
        share.advancedAccessLevels.timeline.permission.read = correctTimelineVisibility(share);
        break;
      case EGoogleEventLabel.SITE_PLAN:
        share.advancedAccessLevels.sitePlan.permission.read = correctSitePlanVisibility(share);
        break;
      case EGoogleEventLabel.EXPORT:
        share.advancedAccessLevels.exports.permission.read = correctExportsVisibility(share);
        break;
    }

    return this.updateShare(share, workspaceId);
  }

  updateShare(share: TShare, workspaceId: string): TShare {
    share.workspaceId = workspaceId;

    if (share.shareOption === EUserRole.SITE_ADMIN) {
      share.defectTags.length = 0;
    }

    this._shareChange$.next(share);

    return share;
  }

  emitNewShare(share: TShare): void {
    this._shareChange$.next(share);
  }

  clearTags(share: TShare, workspaceId: string): TShare {
    share.defectTags.length = 0;
    const clearedTagsAccess = clearTags(share);

    return this.correctTagsAccessLogic(clearedTagsAccess, share, workspaceId);
  }

  correctTagsAccessLogic(parameter: EAccessParameter, share: TShare, workspaceId: string): TShare {
    share.advancedAccessLevels.tags = correctTagsAccessLogic(parameter, share);

    this.updateShareTimelineAccess(share, workspaceId);
    return this.updateShare(share, workspaceId);
  }

  updateShareTimelineAccess(share: TShare, workspaceId: string): TShare {
    if (!share.advancedAccessLevels.tags.permission.read) {
      share.advancedAccessLevels.timeline.permission.read = false;
    }

    return share;
  }
}
