import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pp-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  @Input() ppSize: string; // small, normal
  @Input() ppProgress: number;
  @Input() ppPending: boolean;
  @Input() ppPointId: string;
  @Output() ppAction = new EventEmitter();
  @Output() ppUploadingPointId = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {
    this.uploadingPointId();
  }

  action(): void {
    if (this.ppAction) {
      this.ppAction.emit();
    }
  }

  uploadingPointId(): void {
    this.ppUploadingPointId.emit(this.ppPointId);
  }
}
