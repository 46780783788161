import { TFilters } from '../site-filter.model';

export function compareFilterShowUnflaggedPoints(
  firstFilter: TFilters,
  secondFilter: TFilters,
): boolean {
  const flag1 = firstFilter.showUnflaggedPoints;
  const flag2 = secondFilter.showUnflaggedPoints;

  return flag1 === flag2;
}
