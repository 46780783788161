<div class="optionsSelect" [class.optionsSelect__noBorder]="ppNoBottomBorder">
  <div
    #firstNotificationSelectSelectOption
    id="firstNotificationSelectSelectOption"
    class="option"
    [class.option__noBorder]="ppNoBottomBorder"
    [class.option--selected]="ppSelected === 0"
    (mouseup)="triggerFirstOption()"
  >
    <h6 [class.option__text--noBorder]="ppNoBottomBorder">
      {{ 'all' | ppTranslate }}
    </h6>
  </div>

  <div
    #secondNotificationSelectSelectOption
    id="secondNotificationSelectSelectOption"
    class="option"
    [class.option__noBorder]="ppNoBottomBorder"
    [class.option--selected]="ppSelected === 1"
    (mouseup)="triggerSecondOption()"
  >
    <h6 [class.option__text--noBorder]="ppNoBottomBorder">
      {{ 'unread' | ppTranslate }}
    </h6>
  </div>

  <div
    #thirdNotificationSelectSelectOption
    id="thirdNotificationSelectSelectOption"
    class="option"
    [class.option__noBorder]="ppNoBottomBorder"
    [class.option--selected]="ppSelected === 2"
    (mouseup)="triggerThirdOption()"
  >
    <div [class.option__text--noBorder]="ppNoBottomBorder">
      <h6 class="hideMobile">{{ 'i_was_mentioned' | ppTranslate }}</h6>
      <h6 class="showMobile">{{ 'mentioned' | ppTranslate }}</h6>
    </div>
  </div>

  <div
    #fourthNotificationSelectSelectOption
    id="fourthNotificationSelectSelectOption"
    class="option"
    [class.option__noBorder]="ppNoBottomBorder"
    [class.option--selected]="ppSelected === 3"
    (mouseup)="triggerFourthOption()"
  >
    <div [class.option__text--noBorder]="ppNoBottomBorder">
      <h6 class="hideMobile">{{ 'assigned_to_me' | ppTranslate }}</h6>
      <h6 class="showMobile">{{ 'assigned' | ppTranslate }}</h6>
    </div>
  </div>
</div>
