import { createElement } from 'src/app/core/helpers/dom';
import { addCommonStyles } from './dashboard-export.utils';
import { generateFooterText } from './dashboard-footer-text-element';

export function createFooterElement(type, scale) {
  const footerElement = createElement('div');
  const footerText = generateFooterText(type);

  addCommonStyles(footerElement);
  footerElement.style.fontSize = `${56 * scale}px`;
  footerElement.style.lineHeight = `${70 * scale}px`;
  footerElement.style.left = '50%';
  footerElement.style.transform = 'translateX(-50%)';
  footerElement.style.position = 'absolute';
  footerElement.style.width = '100%';
  footerElement.style.fontFamily = 'Source Sans Pro, sans-serif';
  footerElement.style.top = `${562 * scale}px`;

  footerElement.innerHTML = footerText;

  return footerElement;
}
