import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NewDashletModalCostFieldsService {
  private _sitesChange$ = new Subject<void>();
  public sitesChange$ = this._sitesChange$.asObservable();

  constructor() {}

  sitesChange(): void {
    this._sitesChange$.next();
  }
}
