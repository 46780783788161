import Timeline from '../../Timeline';

export function onTimelineBasicElementScrollMove({
  timeline,
  mouseMoveChangeAmount,
  initialScroll,
  scrollMoveChangeAmount,
  element,
  startPosition,
  difference,
}: {
  timeline: Timeline;
  mouseMoveChangeAmount: number;
  initialScroll: number;
  scrollMoveChangeAmount: number;
  element: HTMLElement;
  startPosition: number;
  difference: number;
}): number {
  const newScroll = timeline.timelineBody.virtualScroller.scrollElement.scrollLeft;
  const changeAmount = mouseMoveChangeAmount + newScroll - initialScroll;

  scrollMoveChangeAmount = newScroll - initialScroll;

  element.style.transform = `translateX(${startPosition - difference + changeAmount}px)`;
  return scrollMoveChangeAmount;
}
