import { Injectable } from '@angular/core';
import { Observable, Subscriber, catchError, finalize, of, tap } from 'rxjs';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { ModalService } from '../../components/modal/modal.service';
import { PromptService } from '../../components/prompt/prompt.service';
import { UserApiProviderService } from '../../data-providers/api-providers/user-api-provider/user-api-provider.service';
import { TUpdateUserSimpleRequest } from '../../data-providers/api-providers/user-api-provider/user-requests.model';
import { TranslationPipe } from '../../features/translate/translation.pipe';
import { getErrorMessage } from '../../helpers/database/get-error-message';
import { TUserResponse } from './response-models/user-response-model';
import { UpdateUserWithTwoFactorAuthenticationModalComponent } from './update-user-with-two-factor-authentication-modal/update-user-with-two-factor-authentication-modal.component';
import { UpdateUserWithTwoFactorAuthenticationModalService } from './update-user-with-two-factor-authentication-modal/update-user-with-two-factor-authentication-modal.service';

@Injectable({
  providedIn: 'root',
})
export class UserUpdateService {
  private updatingUserWith2FA = false;

  constructor(
    private userApiProviderService: UserApiProviderService,
    private modalService: ModalService,
    private updateUserWithTwoFactorAuthenticationModalService: UpdateUserWithTwoFactorAuthenticationModalService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
  ) {}

  updateUser(body: TUpdateUserSimpleRequest, userId: string): Observable<TUserResponse> {
    return this.userApiProviderService.updateUser(body, userId).pipe(
      tap((response) => {
        return of(response);
      }),
      catchError((error) => {
        if (error.status === EStatusCode.FORBIDDEN) {
          return this.updateUserWithTwoFactorAuthentication(body, userId, error);
        } else {
          throw error;
        }
      }),
    );
  }

  private updateUserWithTwoFactorAuthentication(
    body: TUpdateUserSimpleRequest,
    userId: string,
    error: any,
  ): Observable<TUserResponse> {
    return new Observable<TUserResponse>((observer) => {
      this.clearUpdateUserModal();

      getErrorMessage(error).then((message) => {
        this.updateUserWithTwoFactorAuthenticationModalService.setPhoneNumber(message);

        this.modalService.hideModal().then(() => {
          this.modalService.showModal(UpdateUserWithTwoFactorAuthenticationModalComponent, {
            callback: () => {
              this.updateUserModalCallback(body, userId, observer);
            },
            onClose: (success) => {
              if (!success) {
                observer.next(null);
              }
            },
          });
        });
      });
    });
  }

  private updateUserModalCallback(
    body: TUpdateUserSimpleRequest,
    userId: string,
    observer: Subscriber<TUserResponse>,
  ): void {
    if (this.updatingUserWith2FA) {
      return;
    }

    this.updatingUserWith2FA = true;

    const twoFactorAuthCode = this.updateUserWithTwoFactorAuthenticationModalService.getCode();

    const newBody = { ...body, twoFactorAuthCode };
    this.userApiProviderService
      .updateUser(newBody, userId)
      .pipe(
        tap((response) => {
          this.modalService.hideModal(false);
          observer.next(response);
          observer.complete();
        }),
        catchError((error) => {
          if (twoFactorAuthCode) {
            this.handleCodeError();
          }

          throw error;
        }),
        finalize(() => {
          this.updatingUserWith2FA = false;
        }),
      )
      .subscribe();
  }

  private handleCodeError(): void {
    const prompt = this.translationPipe.transform('2fa_code_error');
    this.updateUserWithTwoFactorAuthenticationModalService.setShowingError(true);
    this.promptService.showError(prompt);
  }

  private clearUpdateUserModal(): void {
    this.updateUserWithTwoFactorAuthenticationModalService.clearCode();
    this.updateUserWithTwoFactorAuthenticationModalService.clearUpdateUserWithTwoFactorAuthenticationModalData();
  }
}
