<div
  class="filterDropdown__section filterDropdown__section--site"
  *ngIf="ppFilters.sites.length > 0"
>
  <div class="filterDropdown__heading">
    <h6>{{ 'sites' | ppTranslate }}</h6>

    <button class="filterDropdown__clear--text" *ngIf="!areSitesDefault" (click)="clearSites()">
      {{ 'clear' | ppTranslate }}
    </button>
  </div>

  <div class="filterDropdown__content">
    <div
      *ngFor="let account of ppFilters.sites; let index = index"
      class="filterDropdown__checkbox filterDropdown__checkbox--account control-custom control-custom--checkbox"
      [class.filterDropdown__checkbox--margin]="index !== ppFilters.sites.length - 1"
    >
      <div class="filterDropdown__account-item">
        <h6>{{ account.accountName }}</h6>
        <button
          class="filterDropdown__account-item--select"
          *ngIf="!allSitesEnabled[account.accountName]"
          (click)="selectAllSites(account)"
        >
          {{ 'select_all' | ppTranslate }}
        </button>
        <button
          class="filterDropdown__account-item--select"
          *ngIf="allSitesEnabled[account.accountName]"
          (click)="deselectAllSites(account)"
        >
          {{ 'deselect_all' | ppTranslate }}
        </button>
      </div>

      <div class="filterDropdown__content">
        <label
          *ngFor="let site of account.workspaces; last as isLast; even as isEven"
          [class.filterDropdown__checkbox--long]="isLast && isEven"
          class="filterDropdown__checkbox filterDropdown__checkbox--margin filterDropdown__checkbox--site control-custom control-custom--checkbox"
        >
          <pp-checkbox [ppChecked]="site.value" (ppOnValueChange)="updateSiteValue(site, account)">
            <span
              class="checkbox__text"
              ppTooltip
              [ppTitle]="ppWorkspaces[site.id].siteName"
              [ppTrimTooltip]="true"
              *ngIf="site.id && ppWorkspaces && ppWorkspaces[site.id]"
            >
              {{ ppWorkspaces[site.id].siteName }}
            </span>

            <span
              class="checkbox__text"
              ppTooltip
              [ppTitle]="site.name"
              [ppTrimTooltip]="true"
              *ngIf="!site.id || !ppWorkspaces || !ppWorkspaces[site.id]"
            >
              {{ site.name }}
            </span>
          </pp-checkbox>
        </label>
      </div>
    </div>
  </div>

  <hr />
</div>
