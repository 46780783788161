<div class="siteFilter">
  <div
    data-m-target="Filter by keyword input"
    class="inputGroup__icon search-box"
    [class.search-box--hover]="hovered"
    [class.searchBox--focus]="focused || filters.keyword.length > 0"
    (mouseenter)="setHovered(true)"
    (mouseleave)="setHovered(false)"
  >
    <img
      [src]="EIconPath.SEARCH_DARK"
      class="inputSearch__icon"
      *ngIf="!focused && filters.keyword.length === 0"
    />

    <input
      id="searchBoxKeywords"
      class="searchInput searchInput__name input inputSearch__text"
      [class.inputSearch__no-icon]="focused || filters.keyword.length > 0"
      name="filter-text"
      type="text"
      [placeholder]="
        (workspaceId === EWorkspaces.OVERVIEW ? 'search_overview' : 'search_this_site')
          | ppTranslate
      "
      [(ngModel)]="filters.keyword"
      (input)="filter$.next(filters.keyword)"
      (focus)="setFocused(true)"
      (focusout)="setFocused(false)"
    />

    <div class="closeIcon__wrapper">
      <img
        id="searchInputKeywordsClear"
        *ngIf="filters.keyword.length > 0"
        class="closeIcon"
        [src]="EIconPath.INTERACTION_DELETE_TEXT"
        (click)="clearSearchKeywords()"
      />
    </div>
  </div>
</div>
