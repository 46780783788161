import { TCustomFieldList } from '../custom-fields.model';

export const compareLists = (list1: TCustomFieldList[], list2: TCustomFieldList[]): boolean => {
  if (list1.length !== list2.length) {
    return false;
  }

  for (let i = 0; i < list1.length; i++) {
    if (!compareItems(list1[i], list2[i])) {
      return false;
    }
  }

  return true;
};

const compareItems = (item1: TCustomFieldList, item2: TCustomFieldList): boolean => {
  if (item1.label !== item2.label) {
    return false;
  }

  if (item1.subList && item2.subList) {
    return compareLists(item1.subList, item2.subList);
  } else if (!item1.subList && !item2.subList) {
    return true;
  }

  return false;
};
