import { Component, OnInit } from '@angular/core';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';

import { TAllUsers } from '@project/view-models';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { UsersService } from '../users.service';

@Component({
  selector: 'pp-user-dropdown',
  templateUrl: './user-dropdown.component.html',
  styleUrls: ['./user-dropdown.component.scss'],
})
export class UserDropdownComponent implements OnInit {
  dropdown: TDropdown = this.dropdownService.getDropdown();
  users: TAllUsers = {};
  userId: string;
  disabled: boolean;
  message: string;
  EIconPath = EIconPath;

  constructor(private dropdownService: DropdownService, private usersService: UsersService) {}

  ngOnInit() {
    if (!this.dropdown.data) {
      throw new Error('Data for this dropdown is required');
    }

    if (!this.dropdown.callback) {
      throw new Error('Callback for this dropdown is required');
    }

    this.users = this.usersService.getUsers();
    this.userId = this.dropdown.data.user;
    this.disabled = this.dropdown.data.disabled;
    this.message = this.dropdown.data.message;
  }

  hideDropdown(): void {
    this.dropdownService.hideDropdown();
  }

  removeAssignee(): void {
    if (!this.disabled) {
      this.dropdown.callback(this.userId);
      this.hideDropdown();
    }
  }
}
