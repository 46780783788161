import { SupportedBrowsersPageComponent } from './supported-browsers-page/supported-browsers-page.component';
import { Routes } from '@angular/router';
import { SUPPORTED_BROWSERS_PATH } from './supported-browsers.constants';

export const supportedBrowserRoutes: Routes = [
  {
    path: SUPPORTED_BROWSERS_PATH,
    component: SupportedBrowsersPageComponent,
  },
];
