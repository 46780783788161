import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ADVANCED_SETTINGS_KEY } from 'src/app/project/shared/constants/advanced-settings-key';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { EUserRole } from '../../../share/share-utils/user-roles';
import { TUser } from '../../../user/user.model';
import { EUserType } from '../../../users/user-types-enum';
import { WorkspaceService } from '../../../workspace/workspace.service';
import { TAccount } from '../../account.model';
import { checkIfHasRole } from '../../check-if-has-role';
import {
  ACCOUNT_SETTINGS_TABS,
  EAccountSettingsTabPath,
  TAccountSettingsTab,
} from './account-settings-tabs.const';

@Component({
  selector: 'pp-account-settings-tabs',
  templateUrl: './account-settings-tabs.component.html',
  styleUrls: ['./account-settings-tabs.component.scss'],
})
export class AccountSettingsTabsComponent implements OnInit {
  @Input() ppAccountId: string;

  private user$: Observable<TUser>;
  private user: TUser;
  private isAccountAdmin: boolean;
  private readonly destroy$ = new Subject<void>();
  private accounts$: Observable<TAccount[]>;
  private accounts: TAccount[];
  private account: TAccount;

  showHiddenOptions = false;
  accountSettingsTabs: TAccountSettingsTab[] = ACCOUNT_SETTINGS_TABS;

  constructor(
    private store: Store<{
      user: TUser;
      accounts: TAccount[];
    }>,
    private activatedRoute: ActivatedRoute,
    private workspaceService: WorkspaceService,
  ) {
    this.user$ = this.store.pipe(select(EStore.USER));
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
  }

  ngOnInit(): void {
    this.listenToUserChange();
    this.listenToParamsChange();
    this.listenToAccountsChange();
  }

  ngOnChanges(): void {
    this.findAccount();
    this.setTabsVisibility();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private listenToAccountsChange(): void {
    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
      this.accounts = accounts;
      this.setTabsVisibility();

      this.findAccount();
    });
  }

  private findAccount(): void {
    if (this.accounts?.length > 0) {
      this.account = this.accounts.find((account) => account.accountId === this.ppAccountId);
    }
  }

  private listenToUserChange(): void {
    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;

      this.updateShowHiddenOptions();
      this.setTabsVisibility();
    });
  }

  private updateShowHiddenOptions(): void {
    if (
      this.user.userType === EUserType.DEVELOPER ||
      this.user.userType === EUserType.TESTER ||
      this.user.isSuperUser
    ) {
      this.showHiddenOptions = true;
    }
  }

  private setTabsVisibility(): void {
    this.checkIfAccountAdmin();

    this.accountSettingsTabs.forEach((tab) => {
      const isUserManagementTab = tab.path === EAccountSettingsTabPath.USER_MANAGEMENT;
      const isSitesTab = tab.path === EAccountSettingsTabPath.SITES;
      const isOptionsTab = tab.path === EAccountSettingsTabPath.OPTIONS;

      const tabHiddenForAccountAdmin = this.isAccountAdmin && !(isUserManagementTab || isSitesTab);
      const tabHiddenWithASecretKey =
        isOptionsTab && (!this.showHiddenOptions || this.isAccountAdmin);

      tab.hidden = tabHiddenForAccountAdmin || tabHiddenWithASecretKey;
    });
  }

  private checkIfAccountAdmin(): void {
    this.isAccountAdmin = checkIfHasRole(
      EUserRole.ACCOUNT_ADMIN,
      this.account?.workspaces,
      this.workspaceService.getWorkspaces(),
    );
  }

  private listenToParamsChange(): void {
    this.activatedRoute.queryParamMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.get('key') === ADVANCED_SETTINGS_KEY) {
        this.showHiddenOptions = true;

        this.setTabsVisibility();
      }
    });
  }
}
