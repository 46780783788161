import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from '../modal/modal.module';
import { ButtonModule } from '../button/button.module';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { AutonumericModule } from '../../directives/autonumeric/autonumeric.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SwitchCasesModule } from '../../directives/switch-cases/switch-cases.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { ChangeLimitModalComponent } from './change-limit-modal.component';

@NgModule({
  declarations: [ChangeLimitModalComponent],
  imports: [
    CommonModule,
    ModalModule,
    ButtonModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    PipesModule,
  ],
  exports: [ChangeLimitModalComponent],
})
export class ChangeLimitModalModule {}
