import * as ExcelJS from 'exceljs';
import { translate } from 'src/app/project/features/translate/translate';
import { transformDate } from '../../../shared/date-transformer';
import { TPreferences } from '../../preferences/preferences.model';
import { GET_PREFERENCES } from '../../preferences/preferences.store';
import { ETimelineTimeframe } from '../../site/site-timeline/site-timeline-utils/timeframes-enums';
import { GET_TIMELINE_COLUMNS } from '../../site/site-timeline/timeframes/timeline-timeframes';
import { centeredStyle, monthNames } from '../timeline-export.const';
import { getMonday } from './get-monday';
import { ETimelineExportColumn } from './timeline-export.consts';
import { trimTimeframe } from './trim-timeframe';

export function getColumnHeaders(minDate: number, maxDate: number): Partial<ExcelJS.Column>[] {
  const preferences = GET_PREFERENCES();
  const timeframe = GET_PREFERENCES().timeline?.timeframe;
  const timelineColumns = GET_TIMELINE_COLUMNS();

  const trimmedTimeline = trimTimeframe(
    timelineColumns.timeframes,
    minDate,
    maxDate,
    timelineColumns.bottomElements,
  );
  const timeframesEpochs = trimmedTimeline.timeframes;
  const columnBottomElements = trimmedTimeline.columnHeaders;

  const columns: Partial<ExcelJS.Column>[] = setColumns();

  switch (timeframe) {
    case ETimelineTimeframe.DAY:
      setDayHeaders(timeframesEpochs, preferences, columns);
      break;
    case ETimelineTimeframe.MONTH:
      setMonthHeaders(timeframesEpochs, columns);
      break;
    default:
      setWeekHeaders(timeframesEpochs, columns, columnBottomElements);
      break;
  }

  return columns;
}

function setWeekHeaders(
  timeframesEpochs: number[],
  columns: Partial<ExcelJS.Column>[],
  columnBottomElements: string[],
): void {
  for (let i = 0; i < timeframesEpochs.length; i++) {
    const epoch = timeframesEpochs[i];
    const date = new Date(epoch);

    columns.push({
      header: columnBottomElements[i] + ' - ' + date.getFullYear(),
      key: getMonday(epoch).toString(),
      width: 3,
      style: centeredStyle,
      font: {
        bold: false,
      },
    });
  }
}

function setMonthHeaders(timeframesEpochs: number[], columns: Partial<ExcelJS.Column>[]): void {
  for (let i = 0; i < timeframesEpochs.length; i++) {
    const epoch = timeframesEpochs[i];
    const date = new Date(epoch);
    const monthName = monthNames[date.getMonth()];

    columns.push({
      header: monthName + ' - ' + date.getFullYear(),
      key: epoch.toString(),
      width: 3,
      style: centeredStyle,
      font: {
        bold: false,
      },
    });
  }
}

function setDayHeaders(
  timeframesEpochs: number[],
  preferences: TPreferences,
  columns: Partial<ExcelJS.Column>[],
): void {
  for (let i = 0; i < timeframesEpochs.length; i++) {
    const epoch = timeframesEpochs[i];
    const date = transformDate({
      value: epoch,
      inputHourFormat: '',
      format: preferences.dateFormat,
      localTime: false,
    });

    columns.push({
      header: date,
      key: epoch.toString(),
      width: 3,
      style: centeredStyle,
      font: {
        bold: false,
      },
    });
  }
}

function setColumns(): Partial<ExcelJS.Column>[] {
  return [
    {
      header: '',
      key: ETimelineExportColumn.EMPTY,
      width: 5,
      style: centeredStyle,
      font: {
        bold: true,
      },
    },
    {
      header: translate('id'),
      key: ETimelineExportColumn.ID,
      width: 5,
      style: centeredStyle,
      font: {
        bold: true,
      },
    },
    {
      header: translate('title'),
      key: ETimelineExportColumn.TITLE,
      width: 20,
      font: {
        bold: true,
      },
    },
    {
      header: translate('priority'),
      key: ETimelineExportColumn.PRIORITY,
      width: 10,
      style: centeredStyle,
      font: {
        bold: true,
      },
    },
    {
      header: translate('status'),
      key: ETimelineExportColumn.STATUS,
      width: 10,
      style: centeredStyle,
      font: {
        bold: true,
      },
    },
    {
      header: translate('start_date'),
      key: ETimelineExportColumn.START_DATE,
      width: 15,
      style: centeredStyle,
      font: {
        bold: true,
      },
    },
    {
      header: translate('end_date'),
      key: ETimelineExportColumn.END_DATE,
      width: 15,
      style: centeredStyle,
      font: {
        bold: true,
      },
    },
    {
      header: translate('timeline_export_duration_days'),
      key: ETimelineExportColumn.DURATION,
      width: 10,
      style: centeredStyle,
      font: {
        bold: true,
      },
    },
  ];
}
