import { Action } from '@ngrx/store';

import { TActive } from './active.model';

export enum EActionType {
  SET_ACTIVE_USER_ID = '[Active] User ID',
  SET_ACTIVE_ACCOUNT_ID = '[Active] Account ID',
  SET_ACTIVE_WORKSPACE_ID = '[Active] Workspace ID',
  SET_ACTIVE_POINT_ID = '[Active] Point ID',
}

export class SetActiveUserId implements Action {
  readonly type = EActionType.SET_ACTIVE_USER_ID;
  constructor(readonly payload: TActive['userId']) {}
}

export class SetActiveAccountId implements Action {
  readonly type = EActionType.SET_ACTIVE_ACCOUNT_ID;
  constructor(readonly payload: TActive['accountId']) {}
}

export class SetActiveWorkspaceId implements Action {
  readonly type = EActionType.SET_ACTIVE_WORKSPACE_ID;
  constructor(readonly payload: TActive['workspaceId']) {}
}

export class SetActivePointId implements Action {
  readonly type = EActionType.SET_ACTIVE_POINT_ID;
  constructor(readonly payload: TActive['_id']) {}
}

export type ActionsUnion =
  | SetActiveUserId
  | SetActiveAccountId
  | SetActiveWorkspaceId
  | SetActivePointId;
