import { SET_TIMELINE } from './timeline.ui.store';
import { GET_TIMELINE_COLUMNS } from './timeframes/timeline-timeframes';
import { CALCULATE_COLUMNS, CLEAR_TIMELINE_COLUMNS } from './timeframes/timeline-columns';
import { GET_PREFERENCES } from 'src/app/project/modules/preferences/preferences.store';
import { GET_COLLAPSED_GROUPS } from '../site-table/table.ui.store';

import { createElement } from 'src/app/core/helpers/dom';
import { sortTimelinePoints } from '../site-table/sorting/sorting';
import { groupTimeline } from '../site-table/grouping/group-timeline';
import { checkTableColumnsHover } from '../site-options/site-options';
import { setTimelineGroupColumnWidth } from './body-elements/resizer-width';

import TimelineHead from './TimelineHead';
import TimelineBody from './TimelineBody';
import { TPoint } from 'src/app/project/modules/points/points.model';
import { TTimelineItem, TTimelineParams, TUpdatePointCallback } from './timeline.model';
import { TAnyFunction } from '@core/helpers';
import { TImportUser, TWorkspaceUser } from '@project/view-models';
import { ERowType } from 'src/app/project/shared/enums/row-type.enum';
import { SiteOptionsService } from '../site-options/site-options.service';

export default class Timeline {
  workspaceId: string;
  openPointCallback: TAnyFunction;
  highlightPinCallback: TAnyFunction;
  dehighlightPinCallback: TAnyFunction;
  updatePointCallback: TUpdatePointCallback;
  resizing = false;

  visibleColumnIndexes: number[] = [];
  visibleColumnPositions: number[] = [];
  users: TImportUser[] = [];
  points: TPoint[] = [];
  items: TTimelineItem[] = [];
  width = 0;
  selectedPoints: Set<null> = new Set();
  keyword = '';
  groupedPoints;

  timelineHead: TimelineHead = null;
  timelineBody: TimelineBody = null;
  siteOptionsService: SiteOptionsService;

  element: HTMLElement = null;

  constructor({
    workspaceId: _workspaceId = null,
    openPointCallback: _openPointCallback = null,
    highlightPinCallback: _highlightPinCallback = null,
    dehighlightPinCallback: _dehighlightPinCallback = null,
    updatePointCallback: _updatePointCallback = null,
    siteOptionsService,
  }: TTimelineParams) {
    this.workspaceId = _workspaceId;
    this.openPointCallback = _openPointCallback;
    this.highlightPinCallback = _highlightPinCallback;
    this.dehighlightPinCallback = _dehighlightPinCallback;
    this.updatePointCallback = _updatePointCallback;
    this.siteOptionsService = siteOptionsService;

    setTimelineGroupColumnWidth();

    SET_TIMELINE(this);

    this.element = this.create();

    this.timelineHead = new TimelineHead(this);
    this.timelineBody = new TimelineBody(this);
  }

  private create(): HTMLElement {
    return createElement('div', {
      attrs: {
        style: {
          height: '100%',
        },
      },
    });
  }

  switchTimeframe(): void {
    CALCULATE_COLUMNS();

    this.timelineHead.load(true);
    this.timelineBody.load(true);

    this.setDefaultScrollPosition();
  }

  load(_keepScrollPosition?: boolean): void {
    CALCULATE_COLUMNS();

    this.updateWidth();

    if (!_keepScrollPosition) {
      this.setDefaultScrollPosition();
    }

    this.timelineHead.load(true);
    this.timelineBody.load(true);

    this.timelineHead.scrollLeft(this.timelineBody.virtualScroller.scrollElement.scrollLeft);
  }

  private setDefaultScrollPosition(): void {
    const today = new Date().getTime();
    const timelineData = GET_TIMELINE_COLUMNS();
    let todayIndex = 0;

    for (let i = 1; i < timelineData.timeframes.length; i++) {
      if (timelineData.timeframes[i - 1] < today && timelineData.timeframes[i] >= today) {
        todayIndex = i;

        break;
      }
    }

    this.timelineBody.virtualScroller.disableSmoothScrolling();

    const newPosition =
      todayIndex * timelineData.bottomWidth - this.timelineBody.virtualScroller.viewportWidth * 0.2;

    this.timelineBody.virtualScroller.scrollLeft(newPosition);
    this.timelineBody.virtualScroller.enableSmoothScrolling();
  }

  clear(): void {
    this.updateWidth(0);
    this.timelineHead.clear();
    this.timelineBody.clear();
    CLEAR_TIMELINE_COLUMNS();
  }

  updateWidth(_width?: number): void {
    const headerData = GET_TIMELINE_COLUMNS();

    let width = 0;

    headerData.topElements.forEach((_element) => {
      width += _element.width;
    });

    if (_width === undefined && headerData) {
      this.width = width;
    } else {
      this.width = _width;
    }
  }

  sortTimeline(): void {
    this.sortPoints();
    this.generateItems();
    this.timelineBody.virtualScroller.load(this.items, true);
  }

  updateColumns(): void {
    this.timelineBody.updateColumns();
  }

  updateVisibleColumnIndexes(): void {
    this.visibleColumnIndexes.length = 0;
    this.visibleColumnIndexes = GET_TIMELINE_COLUMNS().bottomElements.map(
      (_column, _index) => _index,
    );

    checkTableColumnsHover();
  }

  updateVisibleColumnPositions(): void {
    const data = GET_TIMELINE_COLUMNS();
    this.visibleColumnPositions.length = 0;

    this.visibleColumnIndexes.forEach((_columnIndex, _index) => {
      if (_index === 0) {
        if (this.visibleColumnIndexes.length > 1) {
          this.visibleColumnPositions[_index + 1] = data.bottomWidth;
        }

        this.visibleColumnPositions[_index] = 0;
      } else if (this.visibleColumnIndexes.length > _index + 1) {
        if (this.visibleColumnIndexes.length > _index + 1) {
          this.visibleColumnPositions[_index + 1] =
            this.visibleColumnPositions[_index] + data.bottomWidth;
        }
      }
    });
  }

  updatePoints(_points: TPoint[]): void {
    this.points = _points;

    this.generateItems();
  }

  addPoint(_point: TPoint): void {
    this.points.push(_point);
    this.sortTimeline();
  }

  sortPoints(): void {
    this.points.sort(sortTimelinePoints());

    const sortButton = this.siteOptionsService.getSortButton();

    if (sortButton) {
      sortButton.update({ updateTable: false });
    }
  }

  loadAvatars(_users: TImportUser[] | TWorkspaceUser[]): void {
    this.users = _users as TImportUser[];

    this.timelineBody.load(true);
  }

  setKeyword(_keyword: string): void {
    this.keyword = _keyword;
  }

  generateItems(): void {
    const collapsedGroups = GET_COLLAPSED_GROUPS();
    const preferences = GET_PREFERENCES();

    if (window.location.hash.startsWith('#/site/timeline/point/')) {
      this.element.classList.add('table__body--collapsed');
    } else {
      this.element.classList.remove('table__body--collapsed');
    }

    this.items.length = 0;

    if (preferences.timeline?.group) {
      this.groupedPoints = groupTimeline(this.points);

      if (window.innerWidth >= 901) {
        this.timelineBody.virtualScroller.updateExtraWidth(30);
      } else {
        this.timelineBody.virtualScroller.updateExtraWidth(0);
      }

      this.timelineBody.virtualScroller.updateFrozenColumnWidth(46);
      this.element.classList.add('table__body--grouped');

      this.items = (this.groupedPoints as any).flatMap((_group, _groupIndex) => {
        const points = [];
        const collapsed = collapsedGroups.has(_group.id);

        points.push({
          type: ERowType.HEADER,
          context: _group.value,
          id: _group.id,
        });

        if (!collapsed) {
          points.push(
            ..._group.points.map((_point) => ({
              type: ERowType.POINT,
              index: _point,
            })),
          );
        }

        return points;
      });
    } else {
      this.timelineBody.virtualScroller.updateExtraWidth(0);
      this.timelineBody.virtualScroller.updateFrozenColumnWidth(0);
      this.element.classList.remove('table__body--grouped');

      if (this.points.length === 0) {
        this.items = [
          {
            type: ERowType.EMPTY_MESSAGE,
            index: 0,
          },
        ];
      } else {
        this.items = this.points.map((_point, _index) => ({
          type: ERowType.POINT,
          index: _index,
        }));
      }
    }

    this.updateWidth();
  }

  setResizing(resizing: boolean): void {
    this.resizing = resizing;
  }
}
