<!-- Added 360 images -->
<ng-container *ngIf="ppChange.propName === EActivityPropName.IMAGES_360">
  <span class="activityChange__icon activityChange__icon--svg">
    <img class="activityChange__icon-svg" [src]="EIconPath.ACTIVITY_IMAGE_360_DARK" />
  </span>

  <div class="activityChange" *ngIf="ppChange.added.length === 0">
    {{ 'added' | ppTranslate }}
    <strong class="timeline__strong">{{ '360_photos' | ppTranslate }}.</strong>
  </div>

  <div class="activityChange" *ngIf="ppChange.added.length > 0">
    {{ 'added' | ppTranslate }}
    <strong class="timeline__strong">{{ ppChange.added.length }}</strong>
    {{ ppChange.added.length === 1 ? ('360_photos' | ppTranslate) : ('360_photos' | ppTranslate) }}.
  </div>
</ng-container>

<!-- Added photos -->
<ng-container *ngIf="ppChange.propName === EActivityPropName.IMAGES">
  <span class="activityChange__icon">
    <img class="activityChange__icon-svg" [src]="EIconPath.BASIC_FIELD_IMAGE" />
  </span>

  <div class="activityChange" *ngIf="ppChange.added.length === 0">
    {{ 'added' | ppTranslate }}
    <strong class="timeline__strong">{{ 'images' | ppTranslate }}.</strong>
  </div>

  <div class="activityChange" *ngIf="ppChange.added.length > 0">
    {{ 'added' | ppTranslate }}
    <strong class="timeline__strong">{{ ppChange.added.length }}</strong>
    {{ ppChange.added.length === 1 ? 'image' : 'images' }}.

    <div class="activityChange__media-wrapper" *ngIf="ppChange.added.length">
      <span class="activityChange__media-item" *ngFor="let value of ppChange.added">
        {{ value.caption }}

        <div (click)="openGallery(value.id)">
          <img
            *ngIf="value.id"
            ppImage
            class="activityChange__uploaded-image"
            [class.activityChange__uploaded-image--deleted]="!ppMediaAttachments[value.id]"
            draggable="false"
            [ppSecureSrc]="'api/v1/images/' + value.id + '/file/size/square/40'"
            onerror="this.onerror=null;this.src=EIconPath.PLACEHOLDER_IMAGE"
          />
        </div>
      </span>
    </div>
  </div>
</ng-container>

<!-- Added video -->
<ng-container *ngIf="ppChange.propName === EActivityPropName.VIDEOS">
  <div *ngIf="ppChange.added.length === 0" style="display: flex">
    <span class="activityChange__icon">
      <img class="activityChange__icon-svg" [src]="EIconPath.BASIC_FIELD_VIDEO" />
    </span>

    <div class="activityChange">
      {{ 'added' | ppTranslate }}
      <strong class="timeline__strong">{{ 'videos' | ppTranslate | lowercase }}.</strong>
    </div>
  </div>

  <div style="display: flex" *ngIf="ppChange.added.length > 0">
    <span class="activityChange__icon">
      <img class="activityChange__icon-svg" [src]="EIconPath.BASIC_FIELD_VIDEO" />
    </span>

    <div class="activityChange">
      {{ 'added' | ppTranslate }}
      <strong class="timeline__strong">{{ ppChange.added.length }}</strong>
      {{
        ppChange.added.length === 1
          ? ('video' | ppTranslate | lowercase)
          : ('videos' | ppTranslate | lowercase)
      }}.
    </div>
  </div>
</ng-container>

<!-- Added file -->
<ng-container *ngIf="ppChange.propName === EActivityPropName.DOCUMENTS">
  <span class="activityChange__icon">
    <img class="activityChange__icon-svg" [src]="EIconPath.BASIC_FIELD_FILE" />
  </span>

  <div *ngIf="!ppChange.added.length" class="activityChange">
    {{ 'added' | ppTranslate }}
    <strong class="timeline__strong">{{ 'files' | ppTranslate | lowercase }}.</strong>
  </div>

  <div *ngIf="ppChange.added.length > 0" class="activityChange">
    {{ 'added' | ppTranslate }}
    <strong class="timeline__strong">{{ ppChange.added.length }}</strong>
    {{
      ppChange.added.length === 1
        ? ('file' | ppTranslate | lowercase)
        : ('files' | ppTranslate | lowercase)
    }}{{ ppChange.added[0].caption ? ':' : '.' }}

    <span
      *ngFor="let file of ppChange.added.slice().reverse(); let last = last"
      class="activity__file-name"
      >{{ file.caption }}</span
    >
  </div>
</ng-container>
