import { Component, OnInit } from '@angular/core';
import { SupportedBrowsersService } from '../supported-browsers.service';

@Component({
  selector: 'pp-supported-browsers-bar',
  templateUrl: './supported-browsers-bar.component.html',
  styleUrls: ['./supported-browsers-bar.component.scss'],
})
export class SupportedBrowsersBarComponent implements OnInit {
  isBrowserUnsupported: boolean;
  isSavedInCookies: boolean;

  constructor(private supportedBrowsersService: SupportedBrowsersService) {}

  ngOnInit() {
    this.isBrowserUnsupported = this.supportedBrowsersService.isBrowserUnsupported();
    this.isSavedInCookies = this.supportedBrowsersService.readCookie() === 'true';
  }

  dismiss() {
    this.supportedBrowsersService.setCookie();
    this.isSavedInCookies = true;
  }
}
