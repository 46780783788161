import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from '../../shared/enums/icons.enum';

@Component({
  selector: 'pp-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.scss'],
})
export class DeleteButtonComponent {
  @Input() ppId: string;
  @Input() ppDisabled: boolean;
  @Input() ppDragging: boolean;
  @Input() ppProcess: boolean;

  @Output() ppAction = new EventEmitter<void>();

  EIconPath = EIconPath;

  action(): void {
    if (this.ppDisabled || this.ppDragging || this.ppProcess) {
      return;
    }

    this.ppAction.emit();
  }
}
