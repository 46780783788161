import { EStatus } from 'src/app/project/shared/enums/status.enum';
import { TFleetStatuses } from '../../fleet.consts';

export function getDefaultFleetStatuses(): TFleetStatuses {
  return {
    [EStatus.OPEN]: {
      include: true,
      weight: 0,
    },
    [EStatus.IN_PROGRESS]: {
      include: true,
      weight: 50,
    },
    [EStatus.TO_REVIEW]: {
      include: true,
      weight: 50,
    },
    [EStatus.ONHOLD]: {
      include: true,
      weight: 0,
    },
    [EStatus.CLOSED]: {
      include: true,
      weight: 100,
    },
    [EStatus.CANCELED]: {
      include: true,
      weight: 100,
    },
  };
}
