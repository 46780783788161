import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TFilters } from '../../site-filter.model';

import { ClearFilterService } from '../../clear-filter-service/clear-filter.service';

@Component({
  selector: 'pp-filter-field-date-updated',
  templateUrl: './filter-field-date-updated.component.html',
  styleUrls: ['../../site-filter-dropdown/site-filter-dropdown.component.scss'],
})
export class FilterFieldDateUpdatedComponent {
  @Input() ppFilters: TFilters;
  @Output() ppApply = new EventEmitter();

  constructor(private clearFilterService: ClearFilterService) {}

  clearDateUpdated(): void {
    this.clearFilterService.clearDateUpdated();

    this.ppFilters.date.updated.startDate = null;
    this.ppFilters.date.updated.endDate = null;

    this.applyFilters();
  }

  applyFilters(): void {
    this.ppApply.emit();
  }

  updateDate(selectedDates: Date[], type: string): void {
    this.ppFilters.date.updated[type] = selectedDates[0].getTime();

    this.applyFilters();
  }
}
