import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { HideDropdownModule } from '../hide-dropdown/hide-dropdown.module';
import { FontSelectDropdownComponent } from './font-select-dropdown/font-select-dropdown.component';
import { FontSelectComponent } from './font-select.component';

@NgModule({
  declarations: [FontSelectComponent, FontSelectDropdownComponent],
  imports: [CommonModule, PipesModule, HideDropdownModule],
  exports: [FontSelectComponent],
})
export class FontSelectModule {}
