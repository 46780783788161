import { cloneDeep } from 'lodash';
import { TogglePointsSelection } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';
import { togglePoint } from '../selected-points';

export function togglePointsSelection(
  oldState: TPointsByWorkspace,
  action: TogglePointsSelection,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);
  let pointIndex = null;

  for (const _id of action.payload.pointIds) {
    pointIndex = state[action.payload.workspaceId].entities.findIndex(
      (_point) => _point._id === _id,
    );

    const point = state[action.payload.workspaceId].entities[pointIndex];

    if (point) {
      togglePoint(point);
    }
  }

  return state;
}
