import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecureSrcDirective } from './secure-src.directive';

@NgModule({
  declarations: [SecureSrcDirective],
  imports: [CommonModule],
  exports: [SecureSrcDirective],
})
export class SecureSrcModule {}
