import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { IconModule } from '../icon/icon-module';
import { BulkChangesBarComponent } from './bulk-changes-bar.component';
import { BulkChangesButtonComponent } from './bulk-changes-button/bulk-changes-button.component';

@NgModule({
  declarations: [BulkChangesBarComponent, BulkChangesButtonComponent],
  imports: [CommonModule, TooltipsModule, PipesModule, IconModule],
  exports: [BulkChangesBarComponent, BulkChangesButtonComponent],
})
export class BulkChangesBarModule {}
