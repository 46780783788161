import { createElement } from 'src/app/core/helpers/dom';
import { GET_PREFERENCES } from 'src/app/project/modules/preferences/preferences.store';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { GET_TIMELINE_COLUMNS } from '../../timeframes/timeline-timeframes';
import { GET_TIMELINE } from '../../timeline.ui.store';
import { onResizeHandleMouseDown } from './on-resize-handle-mouse-down';
import { onResizeHandleLeftMouseMove } from './on-resize-handle-mouse-move';
import { onResizeHandleLeftScrollMove } from './on-resize-handle-scroll-move';
import { onResizeHandleLeftUp } from './on-resize-handle-up';

export function createTimelineResizeHandleLeft(
  _pointIndex: number,
  _width: number,
  _timelineField: TPointCustomField,
  _canEdit: boolean,
  _element: HTMLElement,
): HTMLElement {
  let resizeHandlerElementRect: Partial<DOMRect> = {};
  let offsetX = 0;
  let startOffset = 0;
  let classList = 'timeline__resizeHandle timeline__resizeHandle--left';
  const timeline = GET_TIMELINE();
  const timelineData = GET_TIMELINE_COLUMNS();
  const preferences = GET_PREFERENCES();
  let initialScroll;
  let mouseMoveChangeAmount;

  if (_canEdit) {
    classList += ' timeline__resizeHandle--editable';
  }

  const mouseup = (_event: MouseEvent): void => {
    onResizeHandleLeftUp({
      _event,
      _element,
      resizeHandlerElement,
      preferences,
      timelineData,
      timeline,
      _pointIndex,
      _timelineField,
    });
  };

  const scrollmove = (): void => {
    onResizeHandleLeftScrollMove({
      timeline,
      mouseMoveChangeAmount,
      initialScroll,
      _width,
      resizeHandlerElement,
    });
  };

  const mousemove = (_event: MouseEvent): void => {
    ({ mouseMoveChangeAmount, initialScroll } = onResizeHandleLeftMouseMove({
      _event,
      resizeHandlerElementRect,
      offsetX,
      startOffset,
      timeline,
      _width,
      mouseMoveChangeAmount,
      initialScroll,
      resizeHandlerElement,
    }));
  };

  const resizeHandlerElement = createElement('div', {
    attrs: {
      class: classList,
    },
    eventListeners: {
      click: () => {
        if (!timeline.resizing) {
          timeline.openPointCallback(timeline.points[_pointIndex]);
        }
      },
      mousedown: (_event) => {
        ({ initialScroll, startOffset, offsetX, resizeHandlerElementRect } =
          onResizeHandleMouseDown({
            _canEdit,
            initialScroll,
            _event,
            resizeHandlerElement,
            startOffset,
            offsetX,
            resizeHandlerElementRect,
            mouseup,
            mousemove,
            scrollmove,
          }));
      },
    },
  });

  return resizeHandlerElement;
}
