import { TColumn } from '../../../site-table/columns/column.model';

export function restoreGroupingAndSorting(columnsBackup: TColumn[], columns: TColumn[]): TColumn[] {
  columnsBackup.forEach((column) => {
    const matchingColumn = columns.find((_column) => _column.name === column.name);

    if (!matchingColumn) {
      return;
    }

    matchingColumn.sortIndex = column.sortIndex;
    matchingColumn.sortOrder = column.sortOrder;
    matchingColumn.groupIndex = column.groupIndex;
    matchingColumn.groupOrder = column.groupOrder;
  });

  return columns;
}
