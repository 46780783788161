<app-spinner [ppInput]="true" [ppHidden]="!ppUpdating"></app-spinner>

<pp-icon
  [ppSrc]="EIconPath.ICON_CLOSE_14"
  ppColor="grey-600"
  *ngIf="ppShowClearBtn && !ppUpdating && ppFilled"
  class="saveIndicator__clear"
  [class.saveIndicator__clear--visible]="ppFocused"
  ppClass="setSize14"
  (click)="action($event)"
  (mousedown)="ppMouseDownHandler ? action($event) : preventMouseDownDefault($event)"
></pp-icon>
