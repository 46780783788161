import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TExportDashlet } from '../../../dashboard/dashboard-export-modal/dashboard-export-dashlet.model';
import { DASHLET_NAMES } from '../../../dashboard/dashboard-export-modal/dashlet-names.const';
import { getDashletHeaderSiteName } from '../../../dashboard/dashlet/dashlet-head/utils/get-dashlet-header-site-name';
import { WorkspaceService } from '../../../workspace/workspace.service';

@Component({
  selector: 'pp-fleet-export-page-dashlet',
  templateUrl: './fleet-export-page-dashlet.component.html',
  styleUrl: './fleet-export-page-dashlet.component.scss',
})
export class FleetExportPageDashletComponent implements OnInit {
  @Input() ppExportDashlet: TExportDashlet;
  @Input() ppDisabled: boolean;
  @Output() ppExportDashletChange = new EventEmitter<TExportDashlet>();

  DASHLET_NAMES = DASHLET_NAMES;

  siteName: string;
  siteTooltip: string;

  constructor(private workspaceService: WorkspaceService) {}

  ngOnInit(): void {
    const siteData = getDashletHeaderSiteName(
      this.ppExportDashlet.selectedRange.workspaceIds,
      this.workspaceService.getWorkspaces(),
    );

    this.siteName = siteData.siteName;
    this.siteTooltip = siteData.tooltip;
  }

  toggleDashlet(): void {
    if (this.ppDisabled) {
      return;
    }

    this.ppExportDashlet.selected = !this.ppExportDashlet.selected;

    this.ppExportDashletChange.emit(this.ppExportDashlet);
  }
}
