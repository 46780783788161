import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlanComponent } from './plan.component';

import { PlanDataService } from './plan-data.service';
import { PlanPinsService } from './plan-pins.service';
import { PlanPointService } from './plan-point/plan-point.service';
import { PlanSiteService } from './plan-site.service';
import { PlanService } from './plan.service';
import { locationHostFactory } from '../../../core/services/window/locationFactory';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  imports: [CommonModule, PipesModule],
  providers: [
    PlanDataService,
    PlanPinsService,
    PlanPointService,
    PlanSiteService,
    PlanService,
    { provide: 'HOSTNAME', useFactory: locationHostFactory },
  ],
  exports: [PlanComponent],
  declarations: [PlanComponent],
})
export class PlanModule {}
