<ng-container>
  <div class="timeline__activity" *ngFor="let placeholder of placeholders">
    <span class="timeline__avatar-wrapper">
      <img
        class="timeline__avatar timeline__avatar--default"
        draggable="false"
        [src]="EIconPath.PLACEHOLDER_USER"
      />
    </span>

    <div class="timeline__activity-item timeline__activity-item--other">
      <div class="timeline__activity-content">
        <span class="timeline__new-point timeline__activity-item--placeholder"> &nbsp; </span>
      </div>

      <div
        class="timeline__activity-datetime hide--mobile timeline__activity-datetime--placeholder"
      >
        <span class="timeline__activity-datetime-item--placeholder"> &nbsp; </span>

        <i aria-hidden="true" class="fa fa-clock timeline__activity-datetime-icon"></i>
      </div>
    </div>
  </div>
</ng-container>
