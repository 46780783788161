export function getNumbersExportFormat(decimalPlaces: number, customFieldUnit: string): string {
  const decimalsCorrect = !isNaN(decimalPlaces) && typeof decimalPlaces === 'number';
  const numberOfDecimalPlaces: number = decimalsCorrect ? decimalPlaces : 2;

  let hashFormat = '#';
  let numberFormat = '0';

  if (numberOfDecimalPlaces > 0) {
    hashFormat += ',' + '#'.repeat(numberOfDecimalPlaces);
    numberFormat += '.' + '0'.repeat(numberOfDecimalPlaces);
  }

  const unit = customFieldUnit ? customFieldUnit : '';

  return hashFormat + numberFormat + '"' + unit + '"';
}
