<section class="savedViews__views" #viewsList>
  <div class="savedViews__border-wrapper">
    <div
      class="savedViews__cell--border"
      [style.marginTop.px]="linePosition"
      *ngIf="draggedView !== null"
    ></div>
  </div>

  <ng-container *ngIf="ppType === ESavedView.PERSONAL">
    <ng-container *ngFor="let view of ppSavedViews.personal.views; let i = index">
      <div
        class="savedViews__drag-row-container"
        (dragover)="onDragOver($event, i, view)"
        (drop)="onDrop($event)"
        (dragend)="onDragEnd()"
      >
        <div
          class="savedViews__drag-row"
          id="{{ 'itemRow-' + view.id }}"
          draggable="true"
          (dragstart)="onDragStart($event, i, view)"
        >
          <pp-icon
            [ppSrc]="EIconPath.ICON_DRAG_18"
            ppColor="grey-400"
            [ppDisplayContents]="true"
            ppClass="setSize18"
          ></pp-icon>
          <pp-saved-views-dropdown-row
            [ppView]="view"
            [ppType]="ppType"
            [ppViews]="ppSavedViews"
            [ppSecondaryDropdownService]="ppInjectedDropdownService"
          ></pp-saved-views-dropdown-row>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="ppType === ESavedView.SHARED">
    <ng-container *ngFor="let view of ppSavedViews.shared.views; let i = index">
      <div
        class="savedViews__drag-row-container"
        (dragover)="onDragOver($event, i, view)"
        (drop)="onDrop($event)"
        (dragend)="onDragEnd()"
      >
        <div
          class="savedViews__drag-row"
          [id]="'itemRow-' + view.id"
          draggable="true"
          (dragstart)="onDragStart($event, i, view)"
        >
          <pp-icon
            [ppSrc]="EIconPath.ICON_DRAG_18"
            ppColor="grey-400"
            [ppDisplayContents]="true"
            ppClass="setSize18"
          ></pp-icon>
          <pp-saved-views-dropdown-row
            [ppView]="view"
            [ppType]="ppType"
            [ppViews]="ppSavedViews"
            [ppSecondaryDropdownService]="ppInjectedDropdownService"
          ></pp-saved-views-dropdown-row>
        </div>
      </div>
    </ng-container>
  </ng-container>
</section>
