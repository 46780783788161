type TAccountsChanged = {
  simple: boolean;
  full: boolean;
};

const accountsChanged: TAccountsChanged = {
  simple: false,
  full: false,
};

// FIXME if we scrap database idea remove this file
export function setFullAccountsChanged(changed: boolean): void {
  accountsChanged.full = changed;
}

export function setChangedAccounts(changed: boolean): void {
  accountsChanged.simple = changed;
  accountsChanged.full = changed;
}

export function getAccountsChanged(): TAccountsChanged {
  return accountsChanged;
}
