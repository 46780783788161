<div class="assetCard__detailsValuesContainer">
  <div class="assetCard__detailField" *ngFor="let detail of detailsList">
    <label class="assetCard__detailLabel">{{ detail.key | ppTranslate }}</label>
    <span
      class="assetCard__detailsValue"
      ppTooltip
      [ppTrimTooltip]="true"
      [ppTitle]="detail.value"
      [innerHtml]="detail.value | highlightKeyword: keyword"
    ></span>
  </div>
</div>
