<button
  ppTooltip
  [ppTitle]="ppTooltipTitle"
  id="{{ ppId }}"
  [ngClass]="class"
  (click)="action($event)"
  [style.cursor]="ppDisabled ? 'not-allowed' : 'pointer'"
>
  <app-spinner
    class="button__spinner"
    [ppButton]="true"
    [ppLight]="lightSpinner"
    [ppHidden]="!ppProcess"
  ></app-spinner>

  <span class="button__text" [class.button__text--hidden]="ppProcess">
    <ng-content></ng-content>
  </span>
</button>
