import { TAccountSummaryResponse, TWorkspaceUser } from '@project/view-models';
import { cloneDeep } from 'lodash';
import { getDefaultAccountFeatures } from 'src/app/project/modules/account/account-utils/default-account-features';
import { TAccount } from 'src/app/project/modules/account/account.model';
import { TAllCustomFields } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { TWorkspacesById } from '../workspace.model';
import { TAddShareToWorkspaceResponse } from './add-shares-to-workspace';
import { checkWorkspace } from './check-workspace';

export function checkAccounts(
  accounts: TAccountSummaryResponse[],
  workspaces: TAddShareToWorkspaceResponse,
  usersResponse: TWorkspaceUser[],
  existingAccounts: TAccount[],
): {
  checkedAccounts: Partial<TAccount>[];
  workspaceList: TWorkspacesById;
  customFieldList: TAllCustomFields;
} {
  const checkedAccounts: Partial<TAccount>[] = [];
  let workspaceList: TWorkspacesById = {};
  let customFieldList: TAllCustomFields = {};

  accounts.forEach((account) => {
    const checkedAccount: Partial<TAccount> = {
      accountName: account.name,
      accountId: account.id,
    };

    if (existingAccounts) {
      const matchingAccount = existingAccounts.find(
        (searchedMatchingAccount) => searchedMatchingAccount.accountId === account.id,
      );

      if (matchingAccount) {
        checkedAccount.accountOwnerId = matchingAccount.accountOwnerId;
        checkedAccount.accountFolders = cloneDeep(matchingAccount.accountFolders);
        checkedAccount.accountType = matchingAccount.accountType;
        checkedAccount.accountManager = matchingAccount.accountManager;
        checkedAccount.industryType = matchingAccount.industryType;
        checkedAccount.subscriptionType = matchingAccount.subscriptionType;
        checkedAccount.logoRef = matchingAccount.logoRef;
        checkedAccount.websiteUrl = matchingAccount.websiteUrl;
      }

      if (account.accountFeatures) {
        checkedAccount.accountFeatures = account.accountFeatures;
      } else if (matchingAccount?.accountFeatures) {
        checkedAccount.accountFeatures = { ...matchingAccount.accountFeatures };
      } else {
        checkedAccount.accountFeatures = getDefaultAccountFeatures();
      }
    }

    account.workspaces.forEach((workspace) => {
      const result = checkWorkspace(
        workspace,
        customFieldList,
        usersResponse,
        workspaceList,
        workspaces,
        account,
      );

      workspaceList = result.newWorkspaceList;
      customFieldList = result.newCustomFieldList;
    });

    checkedAccount.workspaces = account.workspaces
      .filter((_workspace) => !_workspace.hidden)
      .map((_workspace) => _workspace._id);

    checkedAccounts.push(checkedAccount as TAccount);
  });

  return { checkedAccounts, workspaceList, customFieldList };
}
