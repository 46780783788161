import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { TAllUsers } from '@project/view-models';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { UsersService } from '../../../users/users.service';

@Component({
  selector: 'pp-point-created-by',
  templateUrl: './point-created-by.component.html',
  styleUrls: ['./point-created-by.component.scss'],
})
export class PointCreatedByComponent implements OnInit, OnChanges {
  @Input() ppCreatedByName: string;
  @Input() ppCreatedByAvatar: string;
  @Input() ppCreatedById: string;
  @Input() ppWorkspaceId: string;

  allUsers: TAllUsers = {};
  users: string[];
  EIconPath = EIconPath;

  constructor(private usersService: UsersService, private workspaceService: WorkspaceService) {}

  ngOnInit() {
    const workspace = this.workspaceService.getWorkspaces()[this.ppWorkspaceId];

    this.allUsers = this.usersService.getUsers();
    this.users = workspace.users;
  }

  ngOnChanges() {
    const workspace = this.workspaceService.getWorkspaces()[this.ppWorkspaceId];

    this.allUsers = this.usersService.getUsers();
    this.users = workspace.users;
  }
}
