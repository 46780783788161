import { TFilters } from '../site-filter.model';

export function isSitesDefault(filters: TFilters): boolean {
  const appliedAccounts = filters.sites.filter((account) => account.value);

  if (appliedAccounts.length !== filters.sites.length) {
    return false;
  } else {
    for (let i = 0; i < filters.sites.length; i++) {
      const appliedSites = filters.sites[i].workspaces.filter((site) => site.value);

      if (appliedSites.length !== filters.sites[i].workspaces.length) {
        return false;
      }
    }
  }

  return true;
}
