import { TPoint } from 'src/app/project/modules/points/points.model';
import { TFilters } from '../site-filter.model';

export function checkPointWithFlag(point: TPoint, filters: TFilters): boolean {
  const pointIsFlagged = point.flagged;

  if (!filters.showFlaggedPoints && pointIsFlagged) {
    return false;
  }

  if (!filters.showUnflaggedPoints && !pointIsFlagged) {
    return false;
  }

  return true;
}
