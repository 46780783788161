import { TFilters } from './site-filter.model';

export let filters: TFilters = null;

// GETTERS

export const GET_FILTERS = (): TFilters => filters;

// SETTERS

export const SET_FILTERS = (_filters: TFilters): void => {
  filters = _filters;
};
