import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from './dropdown.component';
import { DropdownService } from './dropdown-service/dropdown.service';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { DropdownDirective } from './dropdown.directive';

@NgModule({
  declarations: [DropdownComponent, DropdownDirective],
  imports: [CommonModule, PipesModule],
  exports: [DropdownComponent],
  providers: [DropdownService, DropdownDirective],
})
export class DropdownModule {}
