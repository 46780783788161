import { Injectable, OnDestroy } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TAttachment, TAttachments } from '../../attachments/attachments.model';

import { DeviceService } from '@core/services';
import { RequestService } from 'src/app/project/services/requests/request.service';
import { PromptService } from '../../../../components/prompt/prompt.service';
import { OfflineService } from '../../../offline/offline.service';
import { AttachmentsUploadService } from '../../attachments/attachments-upload.service';
import { PointsService } from '../../points.service';

import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { POINT_MAX_FILE_SIZE } from 'src/app/project/shared/constants/point.constants';
import { EBasicField } from 'src/app/project/shared/enums/basic-fields-enums';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { getExtenstion } from '../../../../../core/helpers/get-extenstion';
import { CustomTableService } from '../../../site/site-table/custom-table/custom-table.service';

export type TFilesToUpload = {
  data: TFileToUpload[];
};

export type TMediaObject = {
  type: string;
  id: string;
  w: number;
  h: number;
  src?: string;
  extension?: string;
  mimeType?: string;
  _id?: string;
  fileName?: string;
  base64src?: string;
  createdOn?: string;
  rotationAngle?: number;
};

export type TFileToUpload = {
  file: File;
  name: string;
  index: number;
  type: string;
  progress: number;
  uploaded: boolean;
  uploading: boolean;
  _id?: string;
};

@Injectable({
  providedIn: 'root',
})
export class PointAttachmentsService implements OnDestroy {
  public readonly fileUploading$ = new Subject<boolean>();
  private readonly destroy$ = new Subject<void>();

  private attachments$: Observable<TAttachments>;

  private filesToUpload: TFilesToUpload = {
    data: [],
  };

  private selectedMediaCount: {
    number: number;
  } = {
    number: 0,
  };

  private selectedFilesCount: {
    number: number;
  } = {
    number: 0,
  };

  media: TAttachments['media'] = {
    dates: [],
    attachments: {},
  };

  files: TAttachments['files'] = {
    attachmentIds: [],
    attachments: {},
  };

  private uploadedFilesNumber = 0;
  private uploadPromises: Promise<any>[] = [];
  private attachmentUploading: boolean;

  constructor(
    private store: Store<{
      attachments: TAttachments;
    }>,
    private attachmentsUploadService: AttachmentsUploadService,
    private deviceService: DeviceService,
    private offlineService: OfflineService,
    private promptService: PromptService,
    private requestService: RequestService,
    private translationPipe: TranslationPipe,
    private customTableService: CustomTableService,
    private pointsService: PointsService,
  ) {
    this.attachments$ = this.store.pipe(select(EStore.ATTACHMENTS));

    this.attachments$.pipe(takeUntil(this.destroy$)).subscribe((attachments) => {
      this.media = attachments.media;
      this.files = attachments.files;
    });

    this.fileUploading$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isUploading) => this.fileUploadingHandler(isUploading));
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  getFilesToUpload(): TFilesToUpload {
    return this.filesToUpload;
  }

  cancelUpload(index: number): void {
    this.filesToUpload.data.splice(index, 1);

    // Update indexes
    this.filesToUpload.data.forEach((file, i) => {
      file.index = i;
    });
  }

  cancelUploads(): void {
    for (let index = 0; index < this.filesToUpload.data.length; index += 1) {
      const file = this.filesToUpload.data[index];

      if (!file.uploaded) {
        this.filesToUpload.data.splice(index, this.filesToUpload.data.length + 1 - index);
      }
    }

    // Update indexes
    this.filesToUpload.data.forEach((file, i) => {
      file.index = i;
    });
  }

  sortFiles(files: File[]): TFileToUpload[] {
    return Array.from(files)
      .sort((a: File, b: File) => {
        if (a.name < b.name) {
          return -1;
        }

        if (a.name > b.name) {
          return 1;
        }

        return 0;
      })
      .map((file: File, index) => ({
        file,
        name: file.name,
        index: index + this.filesToUpload.data.length,
        type: null,
        progress: 0,
        success: false,
        error: false,
        uploaded: false,
        uploading: false,
      }));
  }

  private uploadFile(
    file: { file: File; type: string },
    index: number,
    newPoint: boolean,
    _id: string,
  ): Promise<void> {
    let extension = getExtenstion(file.file?.name);

    if (extension) {
      extension = extension.toLowerCase();
    }

    const isHeicHeif = extension === 'heic' || extension === 'heif';
    const isTiff = extension === 'tiff' || extension === 'tif';

    const uploadPromise = this.getUploadPromise(
      file,
      isHeicHeif,
      this.filesToUpload.data[index],
      newPoint,
      _id,
      isTiff,
    );
    const promise = uploadPromise.then(() => this.uploadNextFile(newPoint, _id));

    this.requestService.addRequest({
      type: 'attachmentUpload',
      id: 'attachment',
      promise,
    });

    return promise;
  }

  private uploadNextFile(newPoint: boolean, _id: string): void {
    const nextFileIndex = this.filesToUpload.data.findIndex((file) => file.uploaded === false);

    if (nextFileIndex === -1) {
      this.filesToUpload.data.length = 0;

      const point = this.pointsService.findPoint(_id);
      const table = this.customTableService.getTable();
      const prompt = this.translationPipe.transform(
        this.uploadedFilesNumber > 1 ? 'attachments_upload' : 'attachment_upload',
      );

      if (table) {
        table.updatePointAttachments(_id, point);
      }

      this.customTableService.updatePoint({
        _id: _id,
        field: EBasicField.TITLE,
        newValue: point.title,
        updatedDate: new Date().getTime(),
      });

      this.fileUploading$.next(false);

      this.promptService.showSuccess(prompt);
    } else {
      this.uploadFile(this.filesToUpload.data[nextFileIndex], nextFileIndex, newPoint, _id);
    }
  }

  uploadMediaAndFiles(files: File[], newPoint: boolean, _id: string): Promise<void> {
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > POINT_MAX_FILE_SIZE) {
        const promptText = this.translationPipe.transform('prompt_attachments_size_warning');

        this.promptService.showError(promptText);
        return;
      }
    }

    const sortedFiles = this.sortFiles(files);
    let startUploading = true;

    this.fileUploading$.next(true);
    this.uploadedFilesNumber = files.length;

    sortedFiles.forEach((file) => {
      file._id = _id;
    });

    if (this.filesToUpload.data.length > 0) {
      startUploading = false;
      this.filesToUpload.data.push(...sortedFiles);
    } else {
      this.filesToUpload.data = sortedFiles;
    }

    // 360 check
    this.filesToUpload.data.forEach((file: { file: File; type: string }) => {
      const reader = new FileReader();
      let extension = getExtenstion(file.file?.name);

      if (extension) {
        extension = extension.toLowerCase();
      }

      const isHeicHeif = extension === 'heic' || extension === 'heif';

      const promise = new Promise((resolve, reject) => {
        reader.onload = async (): Promise<void> => {
          if (file.file.type.includes('tiff') || file.file.type.includes('tif')) {
            resolve(null);
          } else if (!isHeicHeif && file.file.type.includes('image')) {
            const image = new Image();
            const readerResult: string = reader.result as string;
            const is360 = await this.check360(file.file);

            image.onload = (): void => {
              if (is360) {
                file.type = 'image360';
              }

              resolve(null);
            };

            image.onerror = (error): void => {
              reject(error);
            };

            image.src = readerResult;
          } else {
            resolve(null);
          }
        };
      });

      this.uploadPromises.push(promise);
      reader.readAsDataURL(file.file);
    });

    return Promise.all(this.uploadPromises).then(() => {
      if (startUploading && this.filesToUpload.data[0]) {
        this.uploadPromises = [];

        return this.uploadFile(this.filesToUpload.data[0], 0, newPoint, _id).catch(() => {
          this.filesToUpload.data = [];
        });
      }
    });
  }

  check360(blob: File): Promise<unknown> {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (): void => {
        const text = reader.result as string;
        const is360 = text.search('equirectangular');
        if (is360 !== -1) {
          resolve(true);
        } else {
          resolve(false);
        }
      };

      reader.readAsText(blob);
    });
  }

  calculateSelectedSize(selectedMedia: TAttachment[]): string {
    let size = 0;

    selectedMedia.forEach((mediaItem) => {
      if (typeof mediaItem.originalFileSize === 'number') {
        size = size + mediaItem.originalFileSize;
      }
    });

    const totalSizeMB = size / Math.pow(1024, 2);

    return totalSizeMB.toFixed(2);
  }

  getSelectedMediaCount(): { number: number } {
    return this.selectedMediaCount;
  }

  getSelectedFilesCount(): { number: number } {
    return this.selectedFilesCount;
  }

  countSelectedMedia(): void {
    const media = Object.keys(this.media.attachments).map((key) => this.media.attachments[key]);
    const selectedMediaCount = media.reduce(
      (acc, currentItem) => (currentItem.selected ? ++acc : acc),
      0,
    );

    const files = Object.keys(this.files.attachments).map((key) => this.files.attachments[key]);
    const selectedFilesCount = files.reduce(
      (acc, currentItem) => (currentItem.selected ? ++acc : acc),
      0,
    );

    this.selectedMediaCount.number = selectedMediaCount;
    this.selectedFilesCount.number = selectedFilesCount;
  }

  private getUploadPromise(
    file: Pick<TFileToUpload, 'file' | 'type'>,
    isHeicHeif: boolean,
    video: TFileToUpload,
    newPoint: boolean,
    _id: string,
    isTiff: boolean,
  ): Promise<unknown> {
    if (file.file.type.includes('video')) {
      return this.attachmentsUploadService.uploadVideo(video, newPoint, _id);
    } else if (
      (isHeicHeif || (file.file.type.includes('image') && !file.file.type.includes('svg'))) &&
      !isTiff
    ) {
      if (file.type === 'image360') {
        return this.attachmentsUploadService.uploadImage360(video, newPoint, _id);
      } else if (isHeicHeif) {
        return this.attachmentsUploadService.uploadHeicHeif(video, newPoint, _id);
      } else {
        return this.attachmentsUploadService.uploadImage(video, newPoint, _id);
      }
    }

    return this.attachmentsUploadService.uploadGenericFile(video, newPoint, _id);
  }

  fileUploadingHandler(isUploading: boolean): void {
    this.attachmentUploading = isUploading;
  }

  getAttachmentUploading(): boolean {
    return this.attachmentUploading;
  }
}
