<div class="dashletActivity">
  <div class="dashletActivity__container">
    <pp-icon class="dashletActivity__icon" [ppSrc]="EIconPath.ICON_ACTIVITIES_RED_FLAG_14" ppClass="setSize14" ppColor="grey-500" *ngIf="!ppChange.newValue"/>
    <img class="dashletActivity__icon" [src]="EIconPath.ICON_ACTIVITIES_RED_FLAG_ACTIVATED_14"  *ngIf="ppChange.newValue"/>

    <div class="dashletActivity__text">
      {{ (ppChange.newValue ? 'point_flagged' : 'point_unflagged') | ppTranslate }}
    </div>
  </div>
</div>
