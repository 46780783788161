import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class HubspotService {
  constructor(private domSanitizer: DomSanitizer) {}

  getHubspotURL(): SafeResourceUrl {
    const url = window['hubspotURL'];

    if (!url) {
      return null;
    }

    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
