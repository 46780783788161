import { Component, Input } from '@angular/core';

@Component({
  selector: 'pp-account-settings-site-footer',
  templateUrl: './account-settings-site-footer.component.html',
  styleUrls: ['./account-settings-site-footer.component.scss'],
})
export class AccountSettingsSiteFooterComponent {
  @Input() ppNumberOfVisibleSites: number;
}
