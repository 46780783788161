import { GET_TABLE } from '../../table.ui.store';
import { TGroupedPoints } from '../../grouping/group-model';

export function getGroupValueById(id: string): TGroupedPoints {
  const table = GET_TABLE();
  let groupValue: TGroupedPoints;
  let depth = 0;

  groupValue = table.groupedPoints.find((_group) => {
    if (_group.id === id) {
      depth = 1;
      return true;
    }

    return false;
  });

  if (!groupValue) {
    for (const group of table.groupedPoints) {
      groupValue = group.group.find((_subGroup) => _subGroup.id === id);

      if (groupValue) {
        depth = 2;
        break;
      }
    }
  }

  if (!groupValue) {
    for (const group of table.groupedPoints) {
      for (const subGroup of group.group) {
        groupValue = subGroup.group.find((_subSubGroup) => _subSubGroup.id === id);

        if (groupValue) {
          depth = 3;
          break;
        }
      }

      if (groupValue) {
        break;
      }
    }
  }

  groupValue.depth = depth;

  return groupValue;
}
