<div class="advancedCFAccess__section">
  <h6 class="shareModalHeader">
    {{ 'export' | ppTranslate }}

    <i
      ppTooltip
      [ppOptions]="{ placement: 'right' }"
      class="fa fa-info-circle settingsSection__header-info"
      aria-hidden="true"
      [ppTitle]="'export_AAL_description' | ppTranslate"
      [ppMobileTooltip]="true"
    ></i>
  </h6>

  <pp-toggle
    ppId="toggleShowExports"
    (ppAction)="correctProperty(EGoogleEventLabel.EXPORT)"
    [ppChecked]="ppShare.advancedAccessLevels.exports.permission.read"
    [ppReverseLayout]="true"
    ppStyle="blue"
  >
    <span class="toggleInnerText">{{ 'show_export' | ppTranslate }}</span>
  </pp-toggle>
</div>
