import { TPreferences } from 'src/app/project/modules/preferences/preferences.model';

export class PreferencesViewModelFactory {
  static createFromDTO(dto: TPreferences): TPreferences {
    const dashletsData = dto.dashlets?.data
      ? dto.dashlets.data.map((dashlet) => {
          const hasWorkspaceIds = !!dashlet.selectedRange.workspaceIds;
          const oldWorkspace = (dashlet.selectedRange as any).workspaceId;
          const transformedWorkspaceIds = oldWorkspace ? [oldWorkspace] : [];

          const workspaceIds = hasWorkspaceIds
            ? dashlet.selectedRange.workspaceIds
            : transformedWorkspaceIds; // In case BE returns an old model with single workspace ID

          return {
            ...dashlet,
            selectedRange: {
              accountId: dashlet.selectedRange.accountId,
              workspaceIds: workspaceIds.filter((id) => !!id),
            },
          };
        })
      : [];

    return {
      ...dto,
      dashlets: {
        data: dashletsData,
      },
    };
  }
}
