import { Injectable } from '@angular/core';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { TFilters } from 'src/app/project/modules/filters/site-filter.model';
import { TPlanData, PlanDataService } from 'src/app/project/modules/plan/plan-data.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { TPoint } from '../points/points.model';
import { PointsService } from '../points/points.service';
import { PointExportUtilsService } from './point-export-utils.service';
import { PointExportService } from './point-export.service';
import { TExportData } from './utils/export-data';
import { TExportModalData } from './utils/export-modal-data';
import { EExportTypeName } from './utils/export-type-names-enum';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PointExportSimpleListService {
  private plan: TPlanData = this.planDataService.getPlan();

  constructor(
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private pointExportService: PointExportService,
    private activeService: ActiveService,
    private workspaceService: WorkspaceService,
    private pointsService: PointsService,
    private planDataService: PlanDataService,
    private pointExportUtilsService: PointExportUtilsService,
  ) {}

  exportSimpleList({
    exportedPoint,
    exportData,
    filters,
    data,
  }: {
    exportedPoint: TPoint;
    exportData: TExportData;
    filters: TFilters;
    data: TExportModalData;
  }): Observable<Response> {
    const workspaceId = exportedPoint
      ? exportedPoint.workspaceRef.id
      : this.activeService.getActiveWorkspaceId();
    const workspaces = this.workspaceService.getWorkspaces();
    const pointIds = this.pointsService.getSelectedPointsExport();
    let coords = [];

    if (this.plan.extent?.length) {
      coords = this.plan.extent;
    } else if (workspaces[workspaceId].sitePlan && workspaces[workspaceId].sitePlan.extent) {
      coords = workspaces[workspaceId].sitePlan.extent;
    }

    if (!coords.length) {
      coords = ['', '', '', ''];
    }

    const promptText = this.translationPipe.transform(
      pointIds.length > 1 ? 'prompt_export_start' : 'prompt_export_start_single',
    );

    this.promptService.showSuccess(promptText, { duration: 15 });

    return this.pointExportService.export({
      workspaceId: data.workspaceId,
      pointIds,
      customFieldIds: null,
      exportData: exportData,
      filters: filters,
      isPdf: false,
      exportType: EExportTypeName.SIMPLE_LIST,
      coords,
      isOverview: false,
    });
  }

  exportOverviewSimpleList({
    exportData,
    filters,
  }: {
    exportData: TExportData;
    filters: TFilters;
  }): Observable<Response> {
    const promptText = this.translationPipe.transform('prompt_export_generation');

    this.promptService.showSuccess(promptText);
    const points = this.pointExportUtilsService.getOverviewReportPoints();

    return this.pointExportService.export({
      workspaceId: null,
      pointIds: points,
      customFieldIds: null,
      exportData: exportData,
      filters: filters,
      isPdf: false,
      exportType: EExportTypeName.SIMPLE_LIST,
      coords: null,
      isOverview: true,
    });
  }
}
