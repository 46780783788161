<pp-modal
  [ppWidth]="627"
  [ppScrollOnDefault]="!showingConfirmation"
  [ppNoPadding]="true"
  [ppNoFooter]="showingConfirmation"
>
  <ng-container heading>
    {{ 'add_account_admin' | ppTranslate }}
  </ng-container>

  <ng-container body>
    <div class="editAccountUserSiteList__emails">
      <div class="editAccountUserSiteList__warnings">
        <pp-warning-box [ppWarningMessages]="warningMessages"></pp-warning-box>
      </div>

      <h6 class="editAccountUserSiteList__emailsHeader">{{ 'users' | ppTranslate }}</h6>

      <pp-share-edit-email-input
        [(ppEmailList)]="emailList"
        (ppEmailListChange)="setEmailList($event)"
        [ppDisabled]="upgradingUser"
      ></pp-share-edit-email-input>
    </div>

    <ng-container *ngIf="!showingConfirmation">
      <div class="editAccountUserSiteList__sitesHeader">
        <div class="editAccountUserSiteList__sitesHeaderLeft">
          <h6 class="editAccountUserSiteList__sitesHeaderMain">{{ 'sites' | ppTranslate }}</h6>
        </div>
      </div>

      <table aria-label="Edit account user table" class="editAccountUserSiteList__table">
        <tbody>
          <tr
            class="editAccountUserSiteList__row"
            *ngFor="
              let workspaceId of account.workspaces;
              let first = first;
              let odd = odd;
              let even = even
            "
            [class.editAccountUserSiteList__row--first]="first"
            [class.editAccountUserSiteList__row--odd]="odd"
            [class.editAccountUserSiteList__row--even]="even"
          >
            <td
              ppTooltip
              [ppTitle]="workspaces[workspaceId].siteName"
              [ppTrimTooltip]="true"
              class="editAccountUserSiteList__siteName"
            >
              {{ workspaces[workspaceId].siteName }}
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>

    <pp-new-account-admin-modal-confirmation-block
      *ngIf="showingConfirmation"
      (ppCancel)="hideModal()"
      (ppConfirm)="addAccountAdmins()"
      [ppProcessing]="processing"
    >
    </pp-new-account-admin-modal-confirmation-block>
  </ng-container>

  <ng-container footer *ngIf="!showingConfirmation">
    <pp-modal-generic-footer
      [ppConfirmText]="'new_account_admin_confirm_button' | ppTranslate"
      [ppProcess]="processing"
      (ppCancel)="hideModal()"
      [ppDisabled]="!emailList.length"
      (ppConfirm)="goToConfirmStep()"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
