import { GET_TIMELINE_COLUMNS } from '../timeframes/timeline-timeframes';
import { createTimelineHeadCellElement } from './create-timeline-head-cell-element';

export function createTimelineHeadCellTypeElementBottom(
  _column: string,
  _top: boolean,
  _index: number,
): HTMLElement {
  const months = GET_TIMELINE_COLUMNS();

  return createTimelineHeadCellElement({
    name: _column,
    center: true,
    width: `${months.bottomWidth}px`,
    child: _column,
    column: _column,
    resize: false,
    bottom: true,
    index: _index,
  });
}
