import { Component, OnChanges, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'pp-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss'],
})
export class FilterDropdownComponent implements OnChanges {
  @Input() ppShow = false;
  @Input() ppFullWidth: boolean;
  @Input() ppAlign: string;
  @Input() ppMinWidth: string;

  cssClasses: {
    [key: string]: boolean;
  };

  constructor() {}

  @HostBinding('class.dropdown--active') get dropdownClass(): boolean {
    return this.ppShow;
  }

  ngOnChanges() {
    this.cssClasses = {
      'dropdown--full-width': this.ppFullWidth,
      'dropdown--left-align': !this.ppAlign,
      'dropdown--right-align': this.ppAlign === 'right',
    };
  }
}
