<ng-container *ngIf="user">
  <pp-user-management-user-row
    [ppUser]="ppUser"
    [ppAccountId]="ppAccountId"
    [ppExpanded]="expanded"
    (ppExpandedChange)="onExpandedChange($event)"
  ></pp-user-management-user-row>

  <pp-user-management-user-section-sites
    *ngIf="expanded"
    [ppUser]="ppUser"
    [ppCustomFields]="ppCustomFields"
    [ppIsClosing]="isClosing"
  ></pp-user-management-user-section-sites>
</ng-container>
