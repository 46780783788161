import { TPoint } from 'src/app/project/modules/points/points.model';
import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { TFilters } from '../site-filter.model';

export function checkSites(
  point: TPoint,
  filters: TFilters,
  workspace: TWorkspace,
  workspaces: TWorkspacesById,
): boolean {
  if (filters.sites.length === 0 || !workspace) {
    return true;
  }

  for (let index = 0; index < filters.sites.length; index++) {
    const accountFilter = filters.sites[index];

    for (let i = 0; i < accountFilter.workspaces.length; i++) {
      if (accountFilter.workspaces[i].id) {
        if (
          accountFilter.workspaces[i].appliedValue &&
          workspaces[accountFilter.workspaces[i].id]?.workspaceId === point.workspaceRef.id
        ) {
          return true;
        }
      } else if (
        accountFilter.workspaces[i].id === point.workspaceRef.id &&
        accountFilter.workspaces[i].appliedValue
      ) {
        return true;
      }
    }
  }

  return false;
}
