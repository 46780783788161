import { TPoint } from 'src/app/project/modules/points/points.model';
import { TColumn } from '../../../columns/column.model';
import { createGroupedHeaderCellElement } from './create-grouped-header--cell-element';

export function createGroupedHeaderNameElement({
  width,
  column,
  center = false,
  isGroupedHeader = false,
  points = null,
  index = null,
  groupingLevel = null,
}: {
  width: number;
  column: TColumn;
  center?: boolean;
  isGroupedHeader?: boolean;
  points?: TPoint[];
  index: number;
  groupingLevel: number;
}): HTMLElement {
  return createGroupedHeaderCellElement({
    center: center,
    width,
    child: '' as unknown as HTMLElement,
    column: column,
    isGroupedHeader,
    points,
    index,
    groupingLevel,
  });
}
