import { TPoint } from '../../../points/points.model';
import { TColumn } from '../columns/column.model';
import { sortLength } from './sort-length';
import { sortTitle } from './sort-title';
import { sort } from './sorting';
import { sortTagsAlphabetically } from './sort-tags-alphabetically';

export function sortTags(
  firstPoint: TPoint,
  secondPoint: TPoint,
  sortColumns: Partial<TColumn>[],
  index: number,
): number {
  let result = sortLength(firstPoint, secondPoint, sortColumns, index, {
    property: 'tags',
  });

  if (result === 0) {
    for (let i = 0; i < firstPoint.tags.length; i++) {
      result = sortTagsAlphabetically(firstPoint, secondPoint, sortColumns, index, i);

      if (result !== 0) {
        break;
      }
    }
  }

  if (result) {
    return result;
  }

  if (sortColumns[index + 1]) {
    return sort(firstPoint, secondPoint, sortColumns, index + 1);
  } else {
    return sortTitle(firstPoint, secondPoint, sortColumns, index);
  }
}
