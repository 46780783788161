import { PermissionsService } from 'src/app/project/modules/share/permissions.service';
import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { TFilters } from '../site-filter.model';

export function updateSelectableTags(
  workspace: TWorkspace,
  filters: TFilters,
  workspaces: TWorkspacesById,
  permissionsService: PermissionsService,
): string[][] {
  let selectableTags: string[] = [];
  let allTags: string[] = [];

  if (workspace && workspace.tags) {
    allTags = workspace.tags;

    selectableTags = workspace.tags.filter((tag) => {
      for (let i = 0; i < filters.tags.value.length; i++) {
        if (filters.tags.value[i] === tag) {
          return false;
        }
      }

      for (let i = 0; i < filters.excludedTags.value.length; i++) {
        if (filters.excludedTags.value[i] === tag) {
          return false;
        }
      }

      return true;
    });
  } else {
    if (!workspaces.state) {
      Object.keys(workspaces).forEach((workspaceId) => {
        if (workspaces[workspaceId].tags) {
          const tagPermissions = permissionsService.getTagPermissions(workspaceId);

          if (!tagPermissions.read) {
            return;
          }

          allTags = [...allTags, ...workspaces[workspaceId].tags];

          selectableTags = [
            ...selectableTags,
            ...workspaces[workspaceId].tags.filter((tag) => {
              for (let i = 0; i < filters.tags.value.length; i++) {
                if (filters.tags.value[i] === tag) {
                  return false;
                }
              }

              for (let i = 0; i < filters.excludedTags.value.length; i++) {
                if (filters.excludedTags.value[i] === tag) {
                  return false;
                }
              }

              for (let i = 0; i < selectableTags.length; i++) {
                if (selectableTags[i] === tag) {
                  return false;
                }
              }

              return true;
            }),
          ];
        }
      });

      selectableTags = Array.from(new Set(selectableTags));
    }
  }

  return [selectableTags, allTags];
}
