import { ECustomFieldType } from '../../custom-fields/custom-field-types-enums';
import { EIntegrationStatus } from '../../custom-fields/custom-fields.model';
import { GET_CUSTOM_FIELDS } from '../../custom-fields/custom-fields.store';
import { GET_WORKSPACE } from '../../workspace/workspace.store';

export function checkBudgetCurrenciesMatch(workspacesCosts: {
  [workspaceId: string]: string[];
}): boolean {
  return Object.keys(workspacesCosts).every((workspaceId) => {
    const requestWorkspace = workspacesCosts[workspaceId];
    let currenciesMatching = true;

    const customFields = GET_CUSTOM_FIELDS();
    const firstCustomField = customFields[requestWorkspace[0]];
    const secondCustomField = customFields[requestWorkspace[1]];
    let formulaTypeError = false;

    if (!GET_WORKSPACE(workspaceId)) {
      return true;
    }

    if (
      firstCustomField?.type === ECustomFieldType.FORMULA &&
      firstCustomField.outputType !== ECustomFieldType.COST
    ) {
      formulaTypeError = true;
    }
    if (
      secondCustomField?.type === ECustomFieldType.FORMULA &&
      secondCustomField.outputType !== ECustomFieldType.COST
    ) {
      formulaTypeError = true;
    }

    if (
      !firstCustomField ||
      !secondCustomField ||
      firstCustomField.currencyCode !== secondCustomField.currencyCode ||
      !firstCustomField.display ||
      !secondCustomField.display ||
      firstCustomField.volyIntegrationActive === EIntegrationStatus.DISABLED ||
      secondCustomField.volyIntegrationActive === EIntegrationStatus.DISABLED ||
      formulaTypeError
    ) {
      currenciesMatching = false;
    }

    return currenciesMatching;
  });
}
