import { TFleet } from '../../fleet.consts';
import { areFleetsEqual } from '../are-fleets-equal';

export function checkExistingFleetChanges(fleet: TFleet, newFleet: Partial<TFleet>): boolean {
  const oldFleet = {
    ...fleet,
    statusSettings: fleet.statusSettings,
  };

  return !areFleetsEqual(oldFleet, newFleet);
}
