import { API_auth_logout } from '@core/api/paths';
import { TAnyFunction } from '@core/helpers';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { getAuthHeaders } from 'src/app/core/http/user-auth';
import { logErrorInSentry } from 'src/app/project/modules/errors/response-error';
import { logout$ } from '../modules/auth/logout.service';
import { EAuthRoute } from './constants/auth.constants';

export type TFetchObject = Promise<unknown>;

let requests: TRequests = {};

export type TRequests = {
  [workspaceId: string]: {
    controller: AbortController;
    status: 'fetching' | 'fetched' | 'error';
    queue: TAnyFunction[];
    promise: Promise<unknown>;
  };
};

const logoutEndpoint = `POST|${API_auth_logout()}`;

export const fetchFile = (path: string): Promise<Response> =>
  fetch(path, {
    headers: {
      ...getAuthHeaders(),
    },
  });

const fetchAPI = (_url, options: RequestInit): TFetchObject => {
  const _options = options;
  const endpoint = `${_options.method}|${_url}`;
  const controller = new AbortController();
  const signal = controller.signal;
  _options.signal = signal;

  const promise = (): Promise<any> =>
    new Promise((resolve, reject) => {
      fetch(_url, _options).then((_response) => {
        if (requests[endpoint]?.queue?.length) {
          const nextPromise = requests[endpoint].queue.shift();

          requests[endpoint].promise = nextPromise();
        } else if (_options.method === 'GET') {
          if (_response.ok) {
            requests[endpoint].controller = null;
            requests[endpoint].status = 'fetched';
          } else if (requests[endpoint]) {
            requests[endpoint].controller = null;
            requests[endpoint].status = 'error';
          }
        } else {
          delete requests[endpoint];
        }

        if (!_response.ok) {
          const url = window.location.href;

          if (
            _response.status === EStatusCode.UNAUTHORIZED &&
            url.indexOf('passwords') === -1 &&
            url.indexOf(EAuthRoute.SIGNUP) === -1 &&
            url.indexOf(EAuthRoute.TWO_FACTOR_AUTHENTICATION_SETUP) === -1 &&
            url.indexOf(EAuthRoute.TWO_FACTOR_AUTHENTICATION_LOGIN) === -1
          ) {
            logout$.next();

            reject(_response);
          } else {
            reject(_response);

            throw new Error(_response.status + '');
          }
        }

        if (_response.headers.get('Content-type') === null) {
          resolve(null);
        } else {
          _response
            .json()
            .then((_data) => {
              resolve(_data);
            })
            .catch((error) => {
              resolve(null);
              logErrorInSentry(error);
            });
        }
      });
    });

  // TODO Remove the "if request is queued return in in the next call even if body is wrong". Or switch to Angular HTTP for all requests
  if (_options.method === 'POST' || _options.method === 'PUT' || _options.method === 'DELETE') {
    return promise();
  } else if (requests[endpoint]) {
    if (_options.method === 'GET') {
      requests[endpoint].promise = promise();
      requests[endpoint].status = 'fetching';
      requests[endpoint].controller = controller;
    } else {
      requests[endpoint].queue.push(promise);
    }
  } else {
    requests[endpoint] = {
      promise: promise(),
      status: 'fetching',
      controller,
      queue: [],
    };
  }

  return requests[endpoint].promise;
};

export const fetchGet = (_url, _useActiveRequestSec: number = 0): TFetchObject => {
  const options: RequestInit = {
    method: 'GET',
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json',
    },
  };

  const endpoint = `${options.method}|${_url}`;

  if (
    requests[endpoint] &&
    (!requests[endpoint].controller ||
      (requests[endpoint].controller && !requests[endpoint].controller.signal.aborted))
  ) {
    if (requests[endpoint].promise && requests[endpoint].status === 'fetching') {
      return requests[endpoint].promise;
    }
  }

  const fetchObject = fetchAPI(_url, options);

  return fetchObject;
};

export const fetchPost = (_url, _body): TFetchObject => {
  const options: RequestInit = {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      ...getAuthHeaders(),
    }),
  };

  if (_body) {
    if (typeof _body === 'string') {
      options.body = _body;
    } else {
      options.body = JSON.stringify(_body);
    }
  }

  return fetchAPI(_url, options);
};

export const fetchPostWithFormData = (url, body: FormData): TFetchObject => {
  const options: RequestInit = {
    method: 'POST',
    body,
    headers: new Headers({
      ...getAuthHeaders(),
    }),
  };

  return fetchAPI(url, options);
};

export const fetchPut = (_url, _body): TFetchObject => {
  const options: RequestInit = {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'application/json',
      ...getAuthHeaders(),
    }),
  };

  if (_body) {
    if (typeof _body === 'string') {
      options.body = _body;
    } else {
      options.body = JSON.stringify(_body);
    }
  }

  return fetchAPI(_url, options);
};

export const fetchDelete = (_url, body?): TFetchObject => {
  const options: RequestInit = {
    method: 'DELETE',
    headers: new Headers({
      'Content-Type': 'application/json',
      ...getAuthHeaders(),
    }),
  };

  if (body) {
    if (typeof body === 'string') {
      options.body = body;
    } else {
      options.body = JSON.stringify(body);
    }
  }

  return fetchAPI(_url, options);
};

export function clearRequestsData(isLogout: boolean = false): void {
  let logoutRequest;
  Object.keys(requests).forEach((requestId) => {
    const request = requests[requestId];

    if (isLogout && requestId === logoutEndpoint) {
      logoutRequest = request;

      return;
    }

    if (requests.controller) {
      request.controller.abort();
    }
  });

  requests = {};
  if (logoutRequest) {
    requests[logoutEndpoint] = logoutRequest;
  }
}
