import { decodeBase64Text } from 'src/app/project/components/input/rich-text/decode-base64-text';
import { generatePlainText } from 'src/app/project/components/input/rich-text/generate-plain-text';
import { TPoint } from '../../../points/points.model';
import { EAdvancedFilterOptions } from '../../models/advanced-filter-options.enum';
import { TAdvancedFilterText } from '../../models/advanced-filter.model';
import { filterIdToPointProperty } from '../../utils/filter-id-to-property';

export function filterRichTextAdvanced(point: TPoint, filter: TAdvancedFilterText): boolean {
  const fieldValue = filterIdToPointProperty(filter.id, point) as string;
  const pointValue = generatePlainText(decodeBase64Text(fieldValue));

  const valueToFilter = filter.value ? filter.value.trim() : '';
  const pointValueToFilter = pointValue ? pointValue.trim() : '';

  switch (filter.option) {
    case EAdvancedFilterOptions.IS:
      return (
        pointValueToFilter.localeCompare(valueToFilter, undefined, { sensitivity: 'base' }) === 0
      );
    case EAdvancedFilterOptions.IS_NOT:
      return (
        pointValueToFilter.localeCompare(valueToFilter, undefined, { sensitivity: 'base' }) !== 0
      );
    case EAdvancedFilterOptions.CONTAINS:
      return pointValueToFilter.toLowerCase().indexOf(valueToFilter.toLowerCase()) !== -1;
    case EAdvancedFilterOptions.DOES_NOT_CONTAIN:
      return pointValueToFilter.toLowerCase().indexOf(valueToFilter.toLowerCase()) === -1;
    case EAdvancedFilterOptions.IS_EMPTY:
      return !pointValueToFilter;
    case EAdvancedFilterOptions.IS_NOT_EMPTY:
      return !!pointValueToFilter;
  }
}
