import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ENumberInputUnitPosition } from './number-input.model';
@Component({
  selector: 'pp-advanced-filter-number-input',
  templateUrl: './advanced-filter-number-input.component.html',
  styleUrls: ['./advanced-filter-number-input.component.scss'],
})
export class AdvancedFilterNumberInputComponent {
  @Input() ppValue: number;
  @Input() ppUnit: string;
  @Input() ppUnitPosition: ENumberInputUnitPosition;
  @Output() ppValueChange = new EventEmitter<number>();

  ENumberInputUnitPosition = ENumberInputUnitPosition;
  focus = false;

  onChange(newValue: number): void {
    this.ppValue = newValue;
    this.ppValueChange.emit(+this.ppValue);
  }

  onFocus(focused: boolean): void {
    this.focus = focused;
  }

  checkIfNumber(event: KeyboardEvent): void {
    // Firefox number type input allows letters which we don't want
    const charCode = event.key;

    if (isNaN(+charCode)) {
      event.preventDefault();
    }
  }
}
