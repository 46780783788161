import { createElement } from 'src/app/core/helpers/dom';
import { TAnyFunction } from '@core/helpers';
import { GET_COLUMNS } from '../columns/columns.store';
import { GET_GROUPING } from '../columns/grouping.store';

export type TCreateCellElement = {
  index?: number;
  child?: HTMLElement;
  className?: string;
  center?: boolean;
  width?: string;
  callback?: TAnyFunction;
  innerElementClass?: string;
};

export function createCellElement({
  index: _index = null,
  child: _child = null,
  className: _className = null,
  center: _center = false,
  width: _width = 'auto',
  callback: _callback = null,
  innerElementClass = null,
}: TCreateCellElement = {}): HTMLElement {
  const childrenList: HTMLElement[] = [];
  const cellInnerElement = createCellInnerElement(_child, innerElementClass);
  const classList = ['table__cell'];

  childrenList.push(cellInnerElement);

  const grouping = GET_GROUPING();
  const isLast = _index === GET_COLUMNS().length - 1;

  if (isLast && grouping.length) {
    const lastCellInnerElements = createCellExtraElements();
    childrenList.push(...lastCellInnerElements);
  }

  if (!_center) {
    cellInnerElement.style.width = '100%';
  }

  if (_className) {
    classList.push(_className);
  }

  if (grouping.length) {
    classList.push('table__cell--group');
  }

  const cellElement = createElement('div', {
    attrs: {
      class: classList.join(' '),
      style: {
        width: _width,
      },
      'data-index': _index,
    },
    children: childrenList,
  });

  if (_center) {
    (cellInnerElement as HTMLElement).style.padding = '0';
  }

  if (_callback) {
    cellElement.addEventListener('click', (_event) => {
      _event.stopPropagation();

      _callback();
    });
  }

  return cellElement;
}

function createCellInnerElement(_child: HTMLElement, className: string): HTMLElement {
  let classList = 'table__cell__inner ';

  if (className) {
    classList += className;
  }

  return createElement('div', {
    attrs: {
      class: classList,
    },
    children: [_child],
  });
}

function createCellExtraElements(): HTMLElement[] {
  const grouping = GET_GROUPING();

  switch (grouping.length) {
    case 0:
    case 1:
      return generateFirstGroupExtraElement();
    case 2:
      return generateSecondGroupExtraElements();
    case 3:
      return generateThirdGroupExtraElements();
  }

  return [];
}
function generateThirdGroupExtraElements(): HTMLElement[] {
  const elements: HTMLElement[] = [];

  elements.push(
    createElement('div', {
      attrs: {
        class: 'table__cell__extra__point_3_1',
      },
    }),
  );

  elements.push(
    createElement('div', {
      attrs: {
        class: 'table__cell__extra__point_3_2',
      },
    }),
  );

  elements.push(
    createElement('div', {
      attrs: {
        class: 'table__cell__extra__point_3_3',
      },
    }),
  );

  return elements;
}

function generateSecondGroupExtraElements(): HTMLElement[] {
  const elements: HTMLElement[] = [];

  elements.push(
    createElement('div', {
      attrs: {
        class: 'table__cell__extra__point_2_1',
      },
    }),
  );

  elements.push(
    createElement('div', {
      attrs: {
        class: 'table__cell__extra__point_2_2',
      },
    }),
  );
  return elements;
}

function generateFirstGroupExtraElement(): HTMLElement[] {
  const elements: HTMLElement[] = [];

  elements.push(
    createElement('div', {
      attrs: {
        class: 'table__cell__extra__point',
      },
    }),
  );
  return elements;
}
