import { Routes } from '@angular/router';

import { EAuthRoute } from '../../shared/constants/auth.constants';
import { TwoFactorAuthenticationLoginComponent } from './2fa/two-factor-authentication-login/two-factor-authentication-login.component';
import { TwoFactorAuthenticationSetupComponent } from './2fa/two-factor-authentication-setup/two-factor-authentication-setup.component';
import { LoginComponent } from './login/login.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { PasswordUpdateComponent } from './password-update/password-update.component';
import { RegisterComponent } from './register/register.component';
import { UserCreateComponent } from './user-create/user-create.component';
import { VerifyComponent } from './verify/verify.component';

export const authRoutes: Routes = [
  {
    path: EAuthRoute.LOGIN,
    component: LoginComponent,
  },
  {
    path: EAuthRoute.RESET_PASSWORD,
    component: PasswordResetComponent,
  },
  {
    path: EAuthRoute.PASSWORD_SETUP,
    component: UserCreateComponent,
  },
  {
    path: EAuthRoute.PASSWORD_RESET,
    component: PasswordUpdateComponent,
  },
  {
    path: EAuthRoute.VERIFY,
    component: VerifyComponent,
  },
  {
    path: EAuthRoute.SIGNUP,
    component: RegisterComponent,
  },
  {
    path: EAuthRoute.TWO_FACTOR_AUTHENTICATION_SETUP,
    component: TwoFactorAuthenticationSetupComponent,
  },
  {
    path: EAuthRoute.TWO_FACTOR_AUTHENTICATION_LOGIN,
    component: TwoFactorAuthenticationLoginComponent,
  },
];
