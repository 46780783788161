import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { EDropdownWidth, TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { TSelectOption } from 'src/app/project/components/input/select-tag/select-tag.model';
import { SelectDropdownComponent } from 'src/app/project/components/input/select/select-dropdown/select-dropdown.component';
import { TSelectDropdownData } from 'src/app/project/components/input/select/select-dropdown/select-dropdown.model';
import { translate } from 'src/app/project/features/translate/translate';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EPlanFormat } from './plan-format.enum';

@Component({
  selector: 'pp-site-plan-format',
  host: {
    '[class.sitePlanFormat--disabled]': 'isChanged',
  },
  templateUrl: './site-plan-format.component.html',
  styleUrls: ['./site-plan-format.component.scss'],
})
export class SitePlanFormatComponent {
  @Input() ppDisabled = false;
  @Output() ppSelectFormat = new EventEmitter<EPlanFormat>();

  EIconPath = EIconPath;
  dropdown: TDropdown = this.dropdownService.getDropdown();

  planFormats: TSelectOption[] = [
    { label: 'PNG 8', value: EPlanFormat.PNG8 },
    { label: 'PNG 24', value: EPlanFormat.PNG24 },
  ];

  selectedPlanFormatIndex = 0;
  tooltipTitle = translate('plan_format_tooltip_1') + '<br>' + translate('plan_format_tooltip_2');

  constructor(private dropdownService: DropdownService) {}

  selectItem(format: { label: string; value: EPlanFormat }): void {
    this.selectedPlanFormatIndex = this.planFormats.indexOf(format);

    this.ppSelectFormat.emit(this.planFormats[this.selectedPlanFormatIndex].value as EPlanFormat);
  }

  toggleSelectDropdown(buttonId: string): void {
    if (this.ppDisabled) {
      return;
    }

    if (this.dropdown.visible && this.dropdown.buttonId === buttonId) {
      this.dropdownService.hideDropdown();
    } else {
      this.setDropdownData();

      this.dropdownService.showDropdown(buttonId, SelectDropdownComponent, {
        callback: (option) => this.selectItem(option),
        popper: {
          positionFixed: true,
          placement: 'bottom',
        },
        width: EDropdownWidth.PX_180,
        suppressScrollbar: true,
        addScrollCallback: false,
      });
    }
  }

  setDropdownData(): void {
    const dropdownData: TSelectDropdownData = {
      noSearchBar: true,
      items: this.planFormats,
      selectedItem: this.planFormats[this.selectedPlanFormatIndex],
    };

    this.dropdownService.setData(dropdownData);
  }
}
