import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TFormulaPiece } from '../utils/formula-input.type';

@Component({
  selector: 'pp-formula-input',
  templateUrl: './formula-input.component.html',
  styleUrl: './formula-input.component.scss',
})
export class FormulaInputComponent {
  @Input() ppFormula: TFormulaPiece[];
  @Output() ppFormulaChange: EventEmitter<TFormulaPiece[]> = new EventEmitter<TFormulaPiece[]>();
  @Input() ppWorkspaceId: string;

  deleteFormulaPiece(index: number): void {
    this.ppFormula.splice(index, 1);
    this.ppFormulaChange.emit(this.ppFormula);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.ppFormula, event.previousIndex, event.currentIndex);

    this.ppFormulaChange.emit(this.ppFormula);
  }
}
