import { splitYearIntoWeeks } from './split-year-into-weeks';

export function getWeekTimeframes(from: Date, to: Date): number[] {
  let elements = [];

  const weeks = splitYearIntoWeeks(from, to);

  weeks.forEach((_week) => {
    elements = [...elements, _week[1].getTime()];
  });

  return elements;
}
