import { cloneDeep } from 'lodash';
import * as lodash from 'lodash';
import { UpdatePointsTags } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';

export function updatePointsTags(
  oldState: TPointsByWorkspace,
  action: UpdatePointsTags,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);
  let pointIndex = null;

  action.payload.pointIds.forEach((_pointId) => {
    pointIndex = state[action.payload.workspaceId].entities.findIndex(
      (point) => point._id === _pointId,
    );
    const point = state[action.payload.workspaceId].entities[pointIndex];

    if (point) {
      point.header.updatedEpochMillis = new Date().getTime();
      point.tags = lodash.union(point.tags, action.payload.tags);
      state[action.payload.workspaceId].entities.splice(pointIndex, 1, point);
    }
  });

  return state;
}
