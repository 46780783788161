<pp-grid class="settingsGrid__item--avatar" [ppOptions]="{ bodyClass: 'settingsAvatar__body' }">
  <ng-container title> {{ 'avatar' | ppTranslate }} </ng-container>

  <ng-container body>
    <img
      ppImage
      class="settingsAvatar__avatar"
      [ppSecureSrc]="
        uploadedImage ? uploadedImage : 'api/v1/images/' + ppUser.avatarId + '/file/size/square/100'
      "
      *ngIf="ppUser && ppUser.avatarId"
    />

    <img
      class="settingsAvatar__avatar"
      [src]="EIconPath.PLACEHOLDER_USER"
      *ngIf="ppUser && !ppUser.avatarId"
    />

    <label
      id="changeAvatarLabel"
      data-m-target="Avatar change button"
      class="buttonPrimary buttonPrimary--small settingsAvatar__avatar-btn"
      for="userImage"
    >
      <app-spinner [ppButton]="true" [ppLight]="true" [ppHidden]="!updatingAvatar"></app-spinner>

      <span class="button__text" [class.button__text--hidden]="updatingAvatar">
        {{ 'change_avatar' | ppTranslate }}
      </span>
    </label>

    <pp-upload
      ppId="userImage"
      (ppUpload)="uploadUserImage($event)"
      [ppAccept]="'image/*'"
    ></pp-upload>

    <button class="settingsAvatar__delete-avatar-btn" (click)="deleteCustomAvatar()">
      <app-spinner [ppButton]="true" [ppLight]="false" [ppHidden]="!deletingAvatar"></app-spinner>

      <span class="settingsAvatar__text" [class.button__text--hidden]="deletingAvatar">
        {{ 'Delete' | ppTranslate }}
      </span>
    </button>
  </ng-container>
</pp-grid>
