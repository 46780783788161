import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSite,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { TColumn } from '../../../site-table/columns/column.model';
import {
  CLEAR_GROUPING,
  GET_GROUPING,
  MOVE_GROUP_COLUMN,
  REMOVE_GROUP,
} from '../../../site-table/columns/grouping.store';
import { TableColumnsService } from '../../../site-table/columns/table-columns.service';
import { CustomTableService } from '../../../site-table/custom-table/custom-table.service';
import { generateGrouping } from '../../../site-table/grouping/group';
import { ESortOrder } from '../../../site-table/sort-order-enum';
import { SiteOptionsService } from '../../site-options.service';

@Injectable({
  providedIn: 'root',
})
export class GroupButtonDropdownService {
  private readonly _groupingChanged$ = new Subject<void>();
  readonly groupingDataChanged$ = this._groupingChanged$.asObservable();

  constructor(
    private customTableService: CustomTableService,
    private siteOptionsService: SiteOptionsService,
    private tableColumnsService: TableColumnsService,
  ) {}

  addGroup(column: TColumn, order: 'ASC' | 'DESC', apply = true): void {
    const grouping = GET_GROUPING();

    this.tableColumnsService.setColumnGroupIndex(column.index, grouping.length);
    this.tableColumnsService.setColumnGroupOrder(column.index, order);

    if (apply) {
      this.applyGrouping();
    }
  }

  replaceGroup(column: TColumn, oldColumn: TColumn): void {
    const order = oldColumn.groupOrder ? oldColumn.groupOrder : 'ASC';
    const index = oldColumn.groupIndex;

    this.removeGroup(oldColumn, false);
    this.addGroup(column, order, false);

    generateGrouping();

    this.moveGroup(column, index);
  }

  changeGroupOrder(order: ESortOrder, column: TColumn): void {
    this.tableColumnsService.setColumnGroupOrder(column.index, order);
    this.applyGrouping();
  }

  removeGrouping(): void {
    this.tableColumnsService.clearColumnGroupOrderAndIndex();
    CLEAR_GROUPING();

    this.applyGrouping();
  }

  removeGroup(_column: TColumn, apply: boolean = true): void {
    REMOVE_GROUP(_column);
    this.tableColumnsService.rearrangeColumnsGroups();

    if (apply) {
      this.applyGrouping();
    }
  }

  moveGroup(draggedColumn: TColumn, destinationIndex: number): void {
    const columnIndex = GET_GROUPING().indexOf(draggedColumn);

    MOVE_GROUP_COLUMN(columnIndex, destinationIndex);
    this.tableColumnsService.rearrangeColumnsGroups();

    logEventInGTAG(EGoogleEventSite.SITE_GROUP_MOVE, {
      event_category: EGoogleEventCategory.SITE,
    });

    this.applyGrouping();
  }

  private applyGrouping(): void {
    const groupButton = this.siteOptionsService.getGroupButton();
    const table = this.customTableService.getTable();

    generateGrouping();

    table.sortTable({ updatePreferences: true, keepScrollPosition: false });
    table.load(false);

    groupButton.update();
    this._groupingChanged$.next();
  }
}
