import { cloneDeep } from 'lodash';

import { EActionType, ActionsUnion } from './active.actions';
import { TActive } from './active.model';

export const initialState: TActive = {
  userId: null,
  accountId: null,
  workspaceId: null,
  _id: null,
};

export let newState = {
  userId: null,
  accountId: null,
  workspaceId: null,
  _id: null,
};

export function activeReducer(state = initialState, action: ActionsUnion) {
  switch (action.type) {
    case EActionType.SET_ACTIVE_USER_ID:
      newState = cloneDeep(state);

      newState.userId = action.payload;

      return newState;

    case EActionType.SET_ACTIVE_ACCOUNT_ID:
      newState = cloneDeep(state);

      newState.accountId = action.payload;

      return newState;

    case EActionType.SET_ACTIVE_WORKSPACE_ID:
      newState = cloneDeep(state);

      newState.workspaceId = action.payload;

      return newState;

    case EActionType.SET_ACTIVE_POINT_ID:
      newState = cloneDeep(state);

      newState._id = action.payload;

      return newState;

    default:
      return state;
  }
}
