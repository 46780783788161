import { ESavedView } from '../models/saved-view.enum';
import { TSavedViews, TViewShort } from '../models/saved-views.model';

export type TSavedViewsOptionsDropdownData = {
  view: TViewShort;
  type: ESavedView;
  views: TSavedViews;
};

export enum ESavedViewsOptionsDropdownCallback {
  RENAME = 'rename',
  SET_PERSONAL_DEFAULT = 'setPersonalDefault',
  SET_SHARED_DEFAULT = 'setSharedDefault',
  CLEAR_PERSONAL_DEFAULT = 'clearPersonalDefault',
  CLEAR_SHARED_DEFAULT = 'clearSharedDefault',
  DUPLICATE = 'duplicate',
  DUPLICATE_TO_SHARED = 'duplicateToShared',
  DUPLICATE_TO_PERSONAL = 'duplicateToPersonal',
  DELETE = 'delete',
}
