export enum EFileType {
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
  BMP = 'image/bmp',
  WEBP = 'image/webp',
  MP4 = 'video/mp4',
  MPEG = 'video/mpeg',
  OGG = 'video/ogg',
  WEBM = 'video/webm',
  GP3 = 'video/3gpp',
  GP32 = 'video/3gpp2',
  WAVE = 'audio/wave',
  WAV = 'audio/wav',
}
