<pp-modal [zIndex]="900" [ppNoPadding]="true">
  <ng-container heading>
    {{ 'confirm_disconnect' | ppTranslate }}
  </ng-container>

  <ng-container body>
    <div class="disconnectModalBody">
      <section class="warningBox">
        <pp-icon [ppSrc]="EIconPath.ICON_BANNER_WARNING" [ppDisplayContents]="true"></pp-icon>

        <span class="warningBox__text">
          {{ 'disconnect_integration_modal_warning' | ppTranslate }}
        </span>
      </section>

      <p class="disconnectModalBody__text">
        <span class="disconnectModalBody__textLine">
          {{ description }}
        </span>
        <span class="disconnectModalBody__textLine">
          {{ 'disconnect_integration_modal_description_2' | ppTranslate }}
        </span>

        <span class="disconnectModalBody__textLine">
          {{ 'disconnect_integration_modal_description_3' | ppTranslate }}
        </span>
      </p>
    </div>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'disconnect_integration_modal_confirm' | ppTranslate"
      (ppCancel)="hideModal(true)"
      (ppConfirm)="hideModal(false)"
      [ppError]="true"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
