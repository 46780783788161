<h6 class="dashletFlip__title">
  {{ 'select_account_site' | ppTranslate }}
</h6>

<p class="dashletFlip__section-description">
  {{ 'choose_sites_data_display' | ppTranslate }}
</p>

<ul class="dashletFlip__accounts">
  <li
    class="dashletFlip__account"
    [ngClass]="{
      'dashletFlip__account--active': !ppSiteRange?.workspaceIds[0] && !ppSiteRange?.accountId,
    }"
    (click)="clearSiteRange()"
  >
    <h4 class="dashletSiteacc__acc-circle">A</h4>
    <p class="dashletSiteacc__acc-label">{{ 'all_accounts_sites' | ppTranslate }}</p>
  </li>

  <ng-container *ngIf="accounts?.length > 0">
    <li
      class="dashletFlip__account"
      [ngClass]="{
        'dashletFlip__account--active':
          account.accountId === ppSiteRange.accountId && !ppSiteRange.workspaceIds.length,
      }"
      *ngFor="let account of accounts"
    >
      <span
        class="dashletFlip__account-wrapper"
        (click)="
          updateSiteRange({
            workspaceIds: [],
            accountId: account.accountId,
          })
        "
      >
        <h4 class="dashletSiteacc__acc-circle">
          {{ account.accountName | firstLetter }}
        </h4>
        <p class="dashletSiteacc__acc-label">
          {{ account.accountName }} - {{ 'all_sites' | ppTranslate }}
        </p>
      </span>

      <pp-dashlet-timeline-account
        [ppAccount]="account"
        [ppDashlet]="ppDashlet"
        [ppSiteRange]="ppSiteRange"
        [ppBlockedTimelines]="disabledWorkspaces"
        [ppWorkspaces]="workspaces"
        (ppUpdateSiteRange)="updateSiteRange($event)"
      ></pp-dashlet-timeline-account>
    </li>
  </ng-container>
</ul>
