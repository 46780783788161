import { TFleet, TFleetByAccount } from '../fleet.consts';

export function getFirstAvailableFleet(fleetByAccount: TFleetByAccount): TFleet | undefined {
  for (const accountId in fleetByAccount) {
    if (fleetByAccount.hasOwnProperty(accountId)) {
      const fleets = fleetByAccount[accountId];
      for (const fleetId in fleets) {
        if (fleets.hasOwnProperty(fleetId)) {
          const fleet = fleets[fleetId];
          if (fleet) {
            return fleet;
          }
        }
      }
    }
  }
  return undefined; // If no TFleet object is found
}
