<div class="pointCF__field" [ngSwitch]="volyIntegrationActive">
  <label
    ppTooltip
    class="pointCF__label"
    ppTitle="{{ ppCustomField?.label }}"
    [ppTrimTooltip]="true"
  >
    <img
      *ngSwitchCase="EIntegrationStatus.OFF"
      class="pointCF__bulk-changes-field-icon"
      [src]="EIconPath.CUSTOM_FIELD_LIST_ACTIVITY"
    />

    <img
      *ngSwitchCase="EIntegrationStatus.ON"
      class="pointCF__bulk-changes-field-icon"
      [src]="EIconPath.ICON_INTEGRATION_VOLY_ACTIVE_SMALL"
    />

    <img
      *ngSwitchCase="EIntegrationStatus.DISABLED"
      class="pointCF__bulk-changes-field-icon"
      [src]="EIconPath.ICON_INTEGRATION_VOLY_INACTIVE_SMALL"
    />

    <span class="pointCF__label-container"> {{ ppCustomField?.label }} </span>
  </label>

  <div class="pointCF__content">
    <div>
      <div
        id="{{ 'bulkclist' + ppFieldId }}"
        class="pointCF__input pointCF__input--list"
        (click)="showList('bulkclist' + ppFieldId, ppCustomField)"
      >
        <span
          ppTooltip
          [ppTrimTooltip]="true"
          [ppTitle]="ppCustomFieldsExtended[ppFieldId].value"
          class="pointCF__list-cf-text"
          *ngIf="ppCustomFieldsExtended[ppFieldId]?.value"
          >{{ ppCustomFieldsExtended[ppFieldId].value }}</span
        >

        <span
          class="pointCF__input-list-placeholder"
          *ngIf="!ppCustomFieldsExtended[ppFieldId]?.value"
          >Select...</span
        >
      </div>
    </div>

    <button class="pointCF__clear" (click)="clearCustomField(ppCustomField)">&#xd7;</button>
    <i class="fa fa-caret-down pointCF__dropdown-icon" aria-hidden="true"></i>
  </div>
</div>
