import { Injectable } from '@angular/core';
import { EPlanModule } from '../plan-module.enum';
import { PlanPinsService } from '../plan-pins.service';
import { SET_DRAWING_POLYGON } from '../plan.store';
import { FeatureDragService } from './feature-drag.service';
import { FeatureModifyService } from './feature-modify.service';
import { PlanPointClickService } from './plan-point-click.service';
import { PlanPointCorrectFeaturesService } from './plan-point-correct-features.service';
import { EPlanPointEventType, PlanPointEventsService } from './plan-point-events.service';
import { EPlanPointMode } from '../../../shared/enums/plan-point-mode.enum';
import { PlanPointVariablesService } from './plan-point-variables.service';
import { TPlanFeature } from '../plan-feature.model';
import { Point } from 'ol/geom';
import { DrawEvent } from 'ol/interaction/Draw';

@Injectable({
  providedIn: 'root',
})
export class PolygonDrawService {
  constructor(
    private planPointVariablesService: PlanPointVariablesService,
    private featureDragService: FeatureDragService,
    private planPinsService: PlanPinsService,
    private featureModifyService: FeatureModifyService,
    private planPointClickService: PlanPointClickService,
    private planPointCorrectFeaturesService: PlanPointCorrectFeaturesService,
    private planPointEventsService: PlanPointEventsService,
  ) {}

  polygonDrawStartListener(): void {
    this.featureDragService.featureDragOff();

    SET_DRAWING_POLYGON(true);
  }

  polygonDrawListnerOff(): void {
    const drawPolygon = this.planPointVariablesService.getDrawPolygon();
    const polygonDrawStartListenerRef =
      this.planPointVariablesService.getPolygonDrawStartListenerRef();
    const polygonDrawEndListenerRef =
      this.planPointVariablesService.getPolygonDrawStartListenerRef();

    if (drawPolygon) {
      drawPolygon.un('drawstart', polygonDrawStartListenerRef);
      drawPolygon.un('drawend', polygonDrawEndListenerRef);
    }
  }

  polygonDrawEndListener(event: DrawEvent): void {
    this.onPolygonDrawEnd(event.feature);
    this.featureDragService.featureDragOff();

    SET_DRAWING_POLYGON(false);
  }

  stopDrawingPolygon(deactivate: boolean = false): void {
    const drawPolygon = this.planPointVariablesService.getDrawPolygon();

    if (!drawPolygon) {
      return;
    }

    const plan = this.planPointVariablesService.getPlan();
    const modifyInteraction = this.planPointVariablesService.getModifyInteraction();
    const polygonDrawEndListenerRef =
      this.planPointVariablesService.getPolygonDrawStartListenerRef();

    drawPolygon.un('drawend', polygonDrawEndListenerRef);
    drawPolygon.finishDrawing();
    this.planPinsService.resetLayer(EPlanModule.POINT, EPlanPointMode.POLYGON);
    drawPolygon.on('drawend', polygonDrawEndListenerRef);

    if (deactivate) {
      if (drawPolygon) {
        plan.instance.point.removeInteraction(drawPolygon);
      }

      if (modifyInteraction) {
        this.featureModifyService.removeModifyInteraction();
      }
    }

    this.featureDragService.featureDragOff();
    this.planPointClickService.clickEventOff();

    if (!deactivate) {
      this.planPointClickService.clickEventOn();
    }
  }

  onPolygonDrawEnd(feature: TPlanFeature): void {
    const plan = this.planPointVariablesService.getPlan();
    const newCoords = this.planPointCorrectFeaturesService.correctPolygonPosition({
      feature,
      plan,
    });

    (feature.getGeometry() as Point).setCoordinates(newCoords);
    this.planPinsService.addPolygon(plan.point, feature);
    this.planPointClickService.clickEventOff();

    this.planPointClickService.clickEventOn();
    this.planPointEventsService.emit(EPlanPointEventType.POLYGON_CREATED, null);
  }

  escListener(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.stopDrawingPolygon();
    }
  }
}
