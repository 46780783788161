import { cloneDeep } from 'lodash';
import { DeletePoints } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';

export function deletePoints(
  oldState: TPointsByWorkspace,
  action: DeletePoints,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);
  let pointIndex = null;

  if (state[action.payload.workspaceId]) {
    action.payload.pointIds.forEach((_id) => {
      pointIndex = state[action.payload.workspaceId].entities.findIndex(
        (_point) => _point._id === _id,
      );

      if (pointIndex > -1) {
        state[action.payload.workspaceId].entities.splice(pointIndex, 1);
      }
    });
  }

  return state;
}
