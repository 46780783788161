export const priorityOrder = {
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
};

export const statusOrder = {
  OPEN: 1,
  IN_PROGRESS: 2,
  TO_REVIEW: 3,
  ONHOLD: 4,
  CLOSED: 5,
  CANCELED: 6,
};
