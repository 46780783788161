import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { KEY_ESC, TAnyFunction, clickOutside, pressKey } from '@core/helpers';
import { WindowService } from '@core/services';
import {
  TWhiteLabelFontOption,
  WHITE_LABEL_FONTS,
} from 'src/app/project/modules/white-label/white-label-fonts';
import { DropdownService } from '../../dropdown/dropdown-service/dropdown.service';
import { EDropdownWidth, TDropdown } from '../../dropdown/dropdown.consts';
import { FontSelectDropdownComponent } from './font-select-dropdown/font-select-dropdown.component';
import { TFontSelectDropdownData } from './font-select-dropdown/font-select-dropdown.model';

@Component({
  selector: 'pp-font-select',
  templateUrl: './font-select.component.html',
  styleUrls: ['./font-select.component.scss'],
})
export class FontSelectComponent implements AfterViewInit {
  @ViewChild('fontSelectComponent', { static: true }) element: ElementRef;

  @Input() ppSelectedFont: TWhiteLabelFontOption;
  @Output() ppSelectFont = new EventEmitter();

  dropdown: TDropdown = this.dropdownService.getDropdown();
  dropdownVisible: boolean;
  focused: boolean;

  fonts = WHITE_LABEL_FONTS;

  private closeOnEscRef: TAnyFunction;
  private clickOutsideRef: TAnyFunction;
  private window = this.windowService.getGlobalObject();

  constructor(private dropdownService: DropdownService, private windowService: WindowService) {}

  ngAfterViewInit(): void {
    this.closeOnEscRef = (event: KeyboardEvent): void =>
      pressKey(event, KEY_ESC, () => {
        this.hideDropdown();
      });

    this.clickOutsideRef = (event: MouseEvent): void =>
      clickOutside(event, this.element.nativeElement, () => {
        event.stopImmediatePropagation();

        this.hideDropdown();
      });
  }

  toggleDropdown(): void {
    this.toggleSelectDropdown();
  }

  hideDropdown(): void {
    this.dropdown.visible = false;
    this.focused = false;
    this.dropdownVisible = false;
    this.setFocusState(false);

    this.window.removeEventListener('keydown', this.closeOnEscRef, true);
    this.window.removeEventListener('click', this.clickOutsideRef, true);
  }

  toggleSelectDropdown(): void {
    const buttonId = 'fontSelectComponent';

    if (this.dropdown.visible && this.dropdown.buttonId === buttonId) {
      this.dropdownVisible = false;
      this.dropdownService.hideDropdown();
    } else {
      let width = this.element.nativeElement.clientWidth;

      this.setDropdownData();

      this.dropdownVisible = true;
      this.setFocusState(true);

      if (width < 150) {
        width = EDropdownWidth.PX_150;
      }

      this.dropdownService.showDropdown(buttonId, FontSelectDropdownComponent, {
        callback: (option) => this.selectItem(option),
        onClose: () => this.hideDropdown(),
        popper: {
          positionFixed: true,
          placement: 'bottom',
        },
        width,
      });
    }
  }

  selectItem(option: TWhiteLabelFontOption): void {
    this.ppSelectFont.emit(option);

    this.hideDropdown();
  }

  setDropdownData(): void {
    const dropdownData: TFontSelectDropdownData = {
      items: this.fonts,
      selectedItem: this.ppSelectedFont,
    };

    this.dropdownService.setData(dropdownData);
  }

  setFocusState(dropdownOpen: boolean): void {
    dropdownOpen
      ? this.element.nativeElement.classList.add('focused')
      : this.element.nativeElement.classList.remove('focused');
  }
}
