import { formatCurrency } from 'src/app/core/helpers/format-currency';
import { decodeBase64Text } from 'src/app/project/components/input/rich-text/decode-base64-text';
import { generatePlainText } from 'src/app/project/components/input/rich-text/generate-plain-text';
import { transformTimeCF } from 'src/app/project/modules/custom-fields/custom-field-time/custom-field-time-transformer';
import { GET_PREFERENCES } from 'src/app/project/modules/preferences/preferences.store';
import { transformDate } from 'src/app/project/shared/date-transformer';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { ECustomFieldType } from '../../custom-fields/custom-field-types-enums';
import { GET_CUSTOM_FIELDS } from '../../custom-fields/custom-fields.store';

export function checkCostForKeyword(pointCustomField: TPointCustomField, keyword: string): boolean {
  let formattedValue = '';
  const customField = GET_CUSTOM_FIELDS()[pointCustomField.customFieldTemplateId];

  if (pointCustomField.value) {
    const costCurrencySymbol = pointCustomField.currencySymbol;
    const costValue = parseFloat(pointCustomField.value);
    formattedValue = formatCurrency(costValue, costCurrencySymbol, +customField.decimalPlaces);
  }

  return formattedValue.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
}

export function checkNumbersForKeyword(
  pointCustomField: TPointCustomField,
  keyword: string,
): boolean {
  const customField = GET_CUSTOM_FIELDS()[pointCustomField.customFieldTemplateId];

  const unit = customField.unit ? customField.unit : '';
  const formattedValue = pointCustomField?.value ? pointCustomField.value + ' ' + unit : '';

  return formattedValue.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
}

export function checkPercentageForKeyword(
  pointCustomField: TPointCustomField,
  keyword: string,
): boolean {
  const formattedValue = pointCustomField?.value ? pointCustomField.value + ' %' : '';

  return formattedValue.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
}

export function checkFormulaForKeyword(
  pointCustomField: TPointCustomField,
  keyword: string,
): boolean {
  const customField = GET_CUSTOM_FIELDS()[pointCustomField.customFieldTemplateId];

  switch (customField.outputType) {
    case ECustomFieldType.NUMBERS:
      return checkNumbersForKeyword(pointCustomField, keyword);
    case ECustomFieldType.COST:
      return checkCostForKeyword(pointCustomField, keyword);
    case ECustomFieldType.PERCENTAGE:
      return checkPercentageForKeyword(pointCustomField, keyword);
    default:
      return false;
  }
}

export function checkDateForKeyword(customField: TPointCustomField, keyword: string): boolean {
  let formattedValue = '';
  const preferences = GET_PREFERENCES();

  if (customField.value) {
    formattedValue = transformDate({
      value: customField.value,
      inputHourFormat: '',
      format: preferences.dateFormat,
      localTime: false,
    });
  }

  return formattedValue.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
}

export function checkTimelineForKeyword(customField: TPointCustomField, keyword: string): boolean {
  const preferences = GET_PREFERENCES();
  let formattedValue = '';

  if (customField.value) {
    const values = customField.value.split('~');

    formattedValue =
      transformDate({
        value: values[0],
        inputHourFormat: '',
        format: preferences.dateFormat,
        localTime: false,
      }) +
      ' - ' +
      transformDate({
        value: values[1],
        inputHourFormat: '',
        format: preferences.dateFormat,
        localTime: false,
      });
  }

  return formattedValue.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
}

export function checkRichTextForKeyword(customField: TPointCustomField, keyword: string): boolean {
  const plainText = generatePlainText(decodeBase64Text(customField.value));

  return plainText.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
}

export function checkTimeForKeyword(pointCustomField: TPointCustomField, keyword: string): boolean {
  let formattedValue = '';

  if (pointCustomField.value) {
    const customField = GET_CUSTOM_FIELDS()[pointCustomField.customFieldTemplateId];

    formattedValue = transformTimeCF(pointCustomField.value, customField.showHoursOnly);
  }

  return formattedValue && formattedValue.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
}

export function checkTextForKeyword(
  pointCustomField: TPointCustomField,
  _keyword: string,
): boolean {
  return (
    pointCustomField.value &&
    pointCustomField.value.toLowerCase().indexOf(_keyword.toLowerCase()) > -1
  );
}
