<pp-not-authorized-layout>
  <section class="authBox">
    <ng-container *ngIf="requestSent">
      <header>
        <h4>{{ 'email_sent_to' | ppTranslate }}</h4>
        <p class="register__copy">{{ credentials.email }}</p>
        <p class="register__copy">{{ 'check_your_inbox' | ppTranslate }}</p>
      </header>

      <div class="register__problems-container">
        <p class="register__copy">{{ 'having_problem_email' | ppTranslate }}</p>

        <ul class="register__list-container">
          <li>{{ 'having_problem_email_1' | ppTranslate }}</li>
          <li>{{ 'having_problem_email_2' | ppTranslate }}</li>
          <li>{{ 'having_problem_email_3' | ppTranslate }}</li>

          <li>
            {{ 'sign_up_another_account' | ppTranslate }}
            <span class="auth__signup-link" (click)="requestSent = false">
              {{ 'here' | ppTranslate }}</span
            >
          </li>
        </ul>

        <p class="register__help-copy">
          {{ 'here_to_help' | ppTranslate }}

          <a href="mailto:support@pinpointworks.com"> {{ 'support_email' | ppTranslate }} </a>
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="!requestSent">
      <header>
        <h4>{{ 'create_account' | ppTranslate }}</h4>
      </header>

      <form name="resetForm" class="authBox__inputs-container">
        <div class="authBox__input-wrapper">
          <label class="sr-only" for="new-signup-name">{{ 'full_name' | ppTranslate }}</label>
          <pp-icon
            [ppSrc]="EIconPath.ICON_MISC_PROFILE_18"
            ppColor="grey-600"
            alt="outline of a user in a circle"
            ppClass="setSize18"
          ></pp-icon>

          <input
            class="authBox__input"
            type="text"
            [placeholder]="'full_name' | ppTranslate"
            [(ngModel)]="credentials.companyName"
            name="fullName"
            (keydown.enter)="blurInput($event)"
          />
        </div>

        <div class="authBox__input-wrapper">
          <label class="sr-only" for="new-signup-email">{{ 'email_address' | ppTranslate }}</label>
          <pp-icon
            [ppSrc]="EIconPath.ICON_MISC_MAIL_18"
            alt="outline of a letter"
            ppClass="setSize18"
            ppColor="grey-600"
          ></pp-icon>

          <input
            class="authBox__input"
            type="email"
            [placeholder]="'email_address' | ppTranslate"
            [(ngModel)]="credentials.email"
            name="email"
            (keydown.enter)="blurInput($event)"
          />
        </div>

        <div class="authBox__input-wrapper">
          <label class="sr-only" for="new-signup-password">{{ 'password' | ppTranslate }}</label>
          <pp-icon
            [ppSrc]="EIconPath.ICON_MISC_PASSWORD_18"
            alt="outline of a lock"
            ppColor="grey-600"
            ppClass="setSize18"
          ></pp-icon>

          <pp-password-visibility
            (ppVisibilityChange)="passInputType = $event"
          ></pp-password-visibility>

          <input
            class="authBox__input"
            [type]="passInputType"
            [placeholder]="'password' | ppTranslate"
            [(ngModel)]="credentials.passwordHash"
            name="password"
            (keydown.enter)="blurInput($event)"
            (keyup)="checkPassword()"
          />
        </div>

        <div class="authBox__input-wrapper">
          <label class="sr-only" for="new-signup-password">Confirm Password</label>
          <pp-icon
            [ppSrc]="EIconPath.ICON_MISC_PASSWORD_18"
            alt="outline of a lock"
            ppColor="grey-600"
            ppClass="setSize18"
          ></pp-icon>

          <input
            class="authBox__input"
            [type]="passInputType"
            placeholder="Confirm Password"
            [(ngModel)]="confirmPassword"
            name="password"
            (keydown.enter)="blurInput($event)"
            (keyup)="checkPassword()"
          />

          <pp-icon
            *ngIf="credentials.passwordHash !== confirmPassword && confirmPassword.length > 0"
            [ppSrc]="EIconPath.ICON_MISC_INFO"
            alt="outline of a lock"
            ppColor="grey-600"
            ppClass="setSize18"
            ppTooltip
            [ppTitle]="'passwords_dont_match' | ppTranslate"
            [ppMobileTooltip]="true"
          ></pp-icon>
        </div>

        <pp-password-validation
          *ngIf="inputDirty"
          (ppValidate)="validatePassword($event)"
          [ppPassword]="credentials.passwordHash"
        ></pp-password-validation>

        <div class="authBox__cb-container">
          <pp-checkbox
            [ppChecked]="acceptedTerms"
            ppSize="large"
            ppType="solid"
            (ppOnValueChange)="toggleAcceptedTerms()"
          ></pp-checkbox>
          <label class="authBox__label">
            {{ 'accept' | ppTranslate }}
            <a
              rel="noopener noreferrer"
              href="https://www.pinpointworks.com/terms-and-conditions"
              target="_blank"
            >
              {{ 'terms_and_conditions' | ppTranslate }}
            </a>
            {{ 'and' | ppTranslate }}
            <a
              rel="noopener noreferrer"
              href="https://www.pinpointworks.com/privacy-policy"
              target="_blank"
            >
              {{ 'privacy_policy' | ppTranslate }}
            </a>
          </label>
        </div>

        <pp-button
          ppStyle="primary"
          ppId="signUpBtn"
          [ppProcess]="processing"
          [ppDisabled]="
            !credentials.companyName ||
            !credentials.email ||
            !credentials.passwordHash ||
            !acceptedTerms ||
            !correctPassword ||
            credentials.passwordHash !== confirmPassword
          "
          (ppAction)="signup()"
        >
          {{ 'sign_in' | ppTranslate }}
        </pp-button>
      </form>
    </ng-container>

    <ng-container *ngIf="!requestSent">
      <a class="authBox__return-link" routerLink="/login">
        <pp-icon
          [ppSrc]="EIconPath.ICON_LEFT_MENU_ARROW_BACK"
          ppColor="grey-600"
          alt="black arrow pointing back"
        ></pp-icon>
        {{ 'already_user' | ppTranslate }}
      </a>
    </ng-container>
  </section>
</pp-not-authorized-layout>
