import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { TTranslationKey } from 'src/app/project/features/translate/types';
import { PromptService } from '../../prompt/prompt.service';

@Component({
  selector: 'pp-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnChanges {
  @Input() private ppNameKey: TTranslationKey;

  @Input() ppColor: string;
  @Output() ppColorChange = new EventEmitter<string>();

  displayName: string;
  error: boolean;

  constructor(private translationPipe: TranslationPipe, private promptService: PromptService) {}

  ngOnChanges(): void {
    this.displayName = this.translationPipe.transform(this.ppNameKey);
  }

  update(): void {
    this.error = !this.isHexColor();

    if (this.error) {
      this.promptService.showError(this.translationPipe.transform('color_must_be_hex'));
    } else {
      this.ppColorChange.emit(this.ppColor);
    }
  }

  private isHexColor(): boolean {
    // Regular expression to match HEX color format
    const hexColorRegex = /^#([0-9A-F]{3}){1,2}$/i;

    return hexColorRegex.test(this.ppColor);
  }
}
