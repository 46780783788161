import { TColumn } from '../../site/site-table/columns/column.model';
import { checkIfColumnMatches } from './check-if-column-matches';

export function compareColumns(oldColumns: TColumn[], newColumns: TColumn[]): boolean {
  if (oldColumns.length !== newColumns.length) {
    return false;
  }

  for (const column of oldColumns) {
    const matchingColumn = newColumns.find((newColumn) => newColumn.name === column.name);

    if (!checkIfColumnMatches(column, matchingColumn)) {
      return false;
    }
  }

  return true;
}
