import { Component, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, catchError, finalize, takeUntil, tap } from 'rxjs';
import { blurInput } from 'src/app/core/helpers/blur-input';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { checkEmailValidity } from 'src/app/project/shared/utils/check-emails';
import { ResponseErrorService } from '../../errors/response-error.service';
import { TUser } from '../user.model';
import { UpdateEmailService } from './update-email.service';

@Component({
  selector: 'pp-update-email-modal',
  templateUrl: './update-email-modal.component.html',
  styleUrls: ['./update-email-modal.component.scss'],
})
export class UpdateEmailModalComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  user$: Observable<TUser>;
  user: TUser;
  EIconPath = EIconPath;
  processing = false;
  emailCorrect: boolean;
  newEmail = '';
  confirmEmail = '';
  emailsTheSame: boolean;

  constructor(
    private store: Store<{ user: TUser }>,
    private promptService: PromptService,
    private modalService: ModalService,
    private translationPipe: TranslationPipe,
    private updateEmailService: UpdateEmailService,
    private responseErrorService: ResponseErrorService,
  ) {
    this.user$ = this.store.pipe(select(EStore.USER));

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  onEmailChange(): void {
    this.emailCorrect = checkEmailValidity(this.newEmail);
    this.checkIfEmailsMatch();
  }

  onConfirmEmailChange(): void {
    this.checkIfEmailsMatch();
  }

  private checkIfEmailsMatch() {
    this.emailsTheSame = this.confirmEmail === this.newEmail;
  }

  changeEmail(): void {
    if (this.processing) {
      return;
    }

    this.processing = true;

    this.updateEmailService
      .updateEmail(this.newEmail)
      .pipe(
        tap(() => {
          this.hideModal();
        }),
        catchError((error) => {
          const prompt = this.translationPipe.transform('prompt_email_change_error');

          this.promptService.showError(prompt);

          return this.responseErrorService.handleRequestError(error);
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  blurInput(event: Event): void {
    blurInput(event.target);
  }
}
