import { Injectable } from '@angular/core';
import { GET_GROUPING, GET_GROUPED_POINTS } from '../../site/site-table/columns/grouping.store';
import Table from '../../site/site-table/custom-table/table/Table';
import Timeline from '../../site/site-timeline/Timeline';
import { TPoint } from '../points.model';

@Injectable({
  providedIn: 'root',
})
export class PointSwitchService {
  constructor() {}

  getPreviousPoint(point: TPoint, table: Timeline | Table, _id: string): TPoint {
    const grouping = GET_GROUPING();

    let prevPoint: TPoint;

    if (grouping.length) {
      const sortedPoints = GET_GROUPED_POINTS();
      const pointIndex = sortedPoints.findIndex((pointId) => pointId === point._id);

      let prevPointIndex = pointIndex - 1;

      if (pointIndex === 0) {
        prevPointIndex = sortedPoints.length - 1;
      } else if (pointIndex === -1) {
        prevPointIndex = 0;
      }

      const prevPointId = sortedPoints[prevPointIndex];

      prevPoint = table.points.find((searchedPoint) => searchedPoint._id === prevPointId);
    } else {
      const pointIndex = table.points.findIndex((_point) => _point._id === _id);
      let prevPointIndex = pointIndex - 1;

      if (pointIndex === 0) {
        prevPointIndex = table.points.length - 1;
      } else if (pointIndex === -1) {
        prevPointIndex = 0;
      }

      prevPoint = table.points[prevPointIndex];
    }
    return prevPoint;
  }

  getNextPoint(point: TPoint, table: Timeline | Table, _id: string): TPoint {
    const grouping = GET_GROUPING();

    let nextPoint: TPoint;

    if (grouping.length) {
      const sortedPoints = GET_GROUPED_POINTS();
      const pointIndex = sortedPoints.findIndex((pointId) => pointId === point._id);

      let nextPointIndex = pointIndex + 1;

      if (pointIndex === sortedPoints.length - 1) {
        nextPointIndex = 0;
      } else if (pointIndex === -1) {
        nextPointIndex = 0;
      }

      const nextPointId = sortedPoints[nextPointIndex];

      nextPoint = table.points.find((searchedPoint) => searchedPoint._id === nextPointId);
    } else {
      const pointIndex = table.points.findIndex((_point) => _point._id === _id);
      let prevPointIndex = pointIndex + 1;

      if (pointIndex === table.points.length - 1) {
        prevPointIndex = 0;
      } else if (pointIndex === -1) {
        prevPointIndex = 0;
      }

      nextPoint = table.points[prevPointIndex];
    }

    return nextPoint;
  }
}
