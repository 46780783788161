import { THeader } from '@project/view-models';
import { GET_TABLE } from '../table.ui.store';
import { getEmptyGroupName } from './empty-group-name';

export function transformUserValue(_value: THeader): string {
  const emptyGroupValue = getEmptyGroupName();
  const table = GET_TABLE();
  const user = table.users.find((_user) => _user.userId === _value.createdBy.id);
  let value = '';

  if (user) {
    value = user.userName;
  } else if (_value?.createdBy?.caption) {
    value = _value.createdBy.caption;
  } else {
    value = emptyGroupValue;
  }

  return value;
}
