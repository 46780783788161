import { Component, Input, OnChanges } from '@angular/core';
import { TAuditLog } from 'src/app/project/modules/integrations/utils/integrations.consts';
import { SiteSettingsIntegrationsAuditLogService } from './site-settings-integrations-audit-log.service';

@Component({
  selector: 'pp-site-settings-integrations-audit-log',
  templateUrl: './site-settings-integrations-audit-log.component.html',
  styleUrl: './site-settings-integrations-audit-log.component.scss',
})
export class SiteSettingsIntegrationsAuditLogComponent implements OnChanges {
  @Input() ppWorkspaceId: string;

  auditLogs: TAuditLog[] = [];

  constructor(
    private siteSettingsIntegrationsAuditLogService: SiteSettingsIntegrationsAuditLogService,
  ) {}

  ngOnChanges(): void {
    this.auditLogs = this.siteSettingsIntegrationsAuditLogService.getLogs();
  }
}
