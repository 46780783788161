import { createElement } from 'src/app/core/helpers/dom';
import { createTimelineEmptyMessageElement } from './body-elements/body-cells-frozen/create-timeline-empty-message-element';
import { createTimelineLeftElement } from './body-elements/body-cells-frozen/create-timeline-left-element';
import { createTimelineLeftElementGroupHeader } from './body-elements/body-cells-frozen/create-timeline-left-element-group-header';

import { GET_PREFERENCES } from 'src/app/project/modules/preferences/preferences.store';
import { ERowType } from 'src/app/project/shared/enums/row-type.enum';
import { TPoint } from '../../points/points.model';
import { isPointFlagged } from '../../points/utils/is-flagged';
import { createTimelineColumnCellsElement } from './body-elements/body-cells/create-column-cells-element';
import { createTimelineCustomFieldElement } from './body-elements/body-cells/create-timeline-custom-field-element';
import { createTimelineEmptyRowElement } from './body-elements/create-timeline-empty-row-element';
import { GET_TIMELINE } from './timeline.ui.store';

export function createTimelineRowElement(index: number): HTMLElement {
  const timeline = GET_TIMELINE();
  const item = timeline.items[index];

  if (!item) {
    return createTimelineEmptyRowElement(index);
  }

  if (item.type === ERowType.POINT) {
    return createPointRowElement(index);
  } else if (item.type === ERowType.HEADER) {
    return createHeaderRowElement(index);
  } else if (item.type === ERowType.EMPTY_MESSAGE) {
    return createEmptyMessageRowElement(index);
  }
}

function createPointRowElement(_index: number): HTMLElement {
  const timeline = GET_TIMELINE();
  const item = timeline.items[_index];
  const pointIndex = item.index;
  const preferences = GET_PREFERENCES();

  timeline.timelineBody.tooltips.forEach((tooltip) => {
    tooltip.removeTooltip();
  });

  timeline.timelineBody.tooltips = [];

  let classList: string[] = getPointClassList(timeline.points[pointIndex]);

  const rowElement = createElement('div', {
    attrs: {
      class: classList,
    },
    eventListeners: {
      mouseenter: () => {
        if (timeline.highlightPinCallback && timeline.points[pointIndex]) {
          // checking if point exists for rare cases when user moves mouse when table is in process of being reloaded
          timeline.highlightPinCallback(timeline.points[pointIndex].sequenceNumber);
        }
      },
      mouseleave: () => {
        if (timeline.dehighlightPinCallback) {
          timeline.dehighlightPinCallback();
        }
      },
    },
    children: preferences.timeline?.group
      ? [
          createTimelineLeftElement(timeline.points[pointIndex]),
          createTimelineColumnCellsElement(),
          createTimelineCustomFieldElement(pointIndex),
        ]
      : [createTimelineCustomFieldElement(pointIndex), createTimelineColumnCellsElement()],
  });

  return rowElement;
}

function getPointClassList(point: TPoint) {
  let classList: string[] = ['timeline__row'];

  if (isPointFlagged(point)) {
    classList.push('timeline__row--flagged');
  }
  return classList;
}

function createHeaderRowElement(_index: number): HTMLElement {
  const rowElement = createElement('div', {
    attrs: {
      class: 'timeline__header__row',
    },
    children: [createTimelineLeftElementGroupHeader(_index), createTimelineColumnCellsElement()],
  });

  return rowElement;
}

function createEmptyMessageRowElement(_index: number): HTMLElement {
  const rowElement = createElement('div', {
    attrs: {
      class: 'timeline__row',
    },
    children: [createTimelineEmptyMessageElement(), createTimelineColumnCellsElement()],
  });

  return rowElement;
}
