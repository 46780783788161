<div class="newSitePlanButton" [class.newSitePlanButton--disabled]="ppDisabled">
  <label
    for="sitePlanUpload"
    class="newSitePlanButton__text"
    [class.newSitePlanButton__text--disabled]="ppNoSitePlan"
  >
    {{ ppSitePlan ? 'Change Site Plan' : 'Add Site Plan' }}
  </label>

  <pp-upload
    [ppId]="'sitePlanUpload'"
    (ppUpload)="uploadSitePlan($event)"
    [ppDisabled]="ppNoSitePlan"
    ppAccept=".jpg, .jpeg, .png, .bmp, .pdf"
  ></pp-upload>
</div>
