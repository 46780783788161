import { Component, OnInit } from '@angular/core';
import { EIconPath } from '../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-point-activity-placeholder',
  templateUrl: './point-activity-placeholder.component.html',
  styleUrls: ['./point-activity-placeholder.component.scss'],
})
export class PointActivityPlaceholderComponent implements OnInit {
  placeholders: number[];
  private numberOfPlaceholders = 3;
  EIconPath = EIconPath;

  ngOnInit(): void {
    this.placeholders = Array(this.numberOfPlaceholders)
      .fill(null)
      .map((x, i) => i);
  }
}
