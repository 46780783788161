import { Component } from '@angular/core';
import { createElement } from '@core/helpers';
import { html, render } from 'lit-html';
import { translate } from 'src/app/project/features/translate/translate';
import { FleetService } from 'src/app/project/modules/fleet-management/fleet-service/fleet.service';
import { TFleetStatuses } from 'src/app/project/modules/fleet-management/fleet.consts';
import { EStatus } from 'src/app/project/shared/enums/status.enum';

@Component({
  selector: 'pp-asset-card-sites-table-header',
  templateUrl: './asset-card-sites-table-header.component.html',
  styleUrls: ['./asset-card-sites-table-header.component.scss'],
})
export class AssetCardSitesTableHeaderComponent {
  statuses: TFleetStatuses;

  progressTooltip: string;

  constructor(private fleetService: FleetService) {
    this.statuses = this.fleetService.getFleet(this.fleetService.getActiveFleetId()).statusSettings;

    this.generateTooltip();
  }

  generateTooltip(): void {
    const wrapperElement = createElement('div');
    const tooltip = html`<div>
      <div>${translate('asset_card_tooltip_progress1')}</div>
      <br />
      <ul>
        <li>• <strong>${translate('open')}</strong> – ${this.statuses[EStatus.OPEN].weight}%</li>
        <li>
          • <strong>${translate('in_progress')}</strong> –
          ${this.statuses[EStatus.IN_PROGRESS].weight}%
        </li>
        <li>
          • <strong>${translate('to_review')}</strong> – ${this.statuses[EStatus.TO_REVIEW].weight}%
        </li>
        <li>
          • <strong>${translate('on_hold')}</strong> – ${this.statuses[EStatus.ONHOLD].weight}%
        </li>
        <li>
          • <strong>${translate('completed')}</strong> – ${this.statuses[EStatus.CLOSED].weight}%
        </li>
        <li>
          • <strong>${translate('canceled')}</strong> – ${this.statuses[EStatus.CANCELED].weight}%
        </li>
      </ul>
      <br />
    </div>`;

    render(tooltip, wrapperElement);

    this.progressTooltip = wrapperElement.innerHTML;
  }
}
