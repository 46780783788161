<div class="optionsSelect" [class.optionsSelect--no-border]="ppNoBottomBorder">
  <div
    #firstDoubleSelectOption
    class="option"
    [class.option--no-border]="ppNoBottomBorder"
    [class.option--selected]="ppSelected === 0"
    (mouseup)="triggerFirstOption()"
  >
    <h6 [class.option__text--no-border]="ppNoBottomBorder">
      {{ ppFirstOption }}
    </h6>
  </div>

  <div
    #secondDoubleSelectOption
    class="option"
    [class.option--no-border]="ppNoBottomBorder"
    [class.option--selected]="ppSelected === 1"
    (mouseup)="triggerSecondOption()"
  >
    <h6 [class.option__text--no-border]="ppNoBottomBorder">
      {{ ppSecondOption }}
    </h6>
  </div>
</div>
