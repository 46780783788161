<div class="siteIntegrations">
  <section class="siteIntegrations__frame">
    <section class="siteIntegrations__logos">
      <div class="logoWrapper">
        <img [src]="EIconPath.ICON_BADGE_LOGO_VOLY_BLACK" />
      </div>

      <img [src]="EIconPath.ICON_COLORED_COMPARE_ARROWS" />

      <div class="logoWrapper">
        <img [src]="EIconPath.ICON_BADGE_LOGO_PINPOINT_BLACK" />
      </div>
    </section>

    <pp-site-settings-integrations-get-started
      [ppDisabled]="disableInteractionButton"
      *ngIf="step === EIntegrationStep.GET_STARTED"
      (ppEnableIntegration)="onIntegrationStart()"
    >
    </pp-site-settings-integrations-get-started>

    <pp-site-settings-integrations-authorize-connection
      [ppDisabled]="disableInteractionButton"
      [(ppIntegrationStep)]="integrationStep"
      [ppWorkspaceId]="workspace.workspaceId"
      *ngIf="step === EIntegrationStep.AUTHORIZE_CONNECTION"
    ></pp-site-settings-integrations-authorize-connection>

    <pp-site-settings-integrations-custom-fields
      [ppWorkspaceId]="workspace.workspaceId"
      [ppIntegrationStep]="integrationStep"
      [ppDisabled]="disableInteractionButton"
    ></pp-site-settings-integrations-custom-fields>

    <pp-site-settings-integrations-audit-log
      *ngIf="canSeeAuditLog"
      [ppWorkspaceId]="workspace.workspaceId"
    ></pp-site-settings-integrations-audit-log>
  </section>
</div>
