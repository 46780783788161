import { Component, Input, OnInit } from '@angular/core';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { TFileChangeInfo, TPushNotification } from '../../notification.model';
import { getNotificationReasonText } from '../../utils/notification-reason';

@Component({
  selector: 'pp-notification-attachment',
  templateUrl: './notification-attachment.component.html',
  styleUrls: ['../notification.component.scss'],
})
export class NotificationAttachmentComponent implements OnInit {
  @Input() ppNotification: TPushNotification<TFileChangeInfo[]>;

  isNewValue = false;
  addedSingleFile = false;
  removedSingleFile = false;
  addedMultipleFiles = false;
  removedMultipleFiles = false;
  notificationReason: string;
  EIconPath = EIconPath;

  constructor() {}

  ngOnInit(): void {
    this.isNewValue = this.checkNewValue();
    this.addedSingleFile = this.checkAddedSingleFile();
    this.addedMultipleFiles = this.checkAddedMultipleFiles();
    this.removedSingleFile = this.checkRemovedSingleFile();
    this.removedMultipleFiles = this.checkRemovedMultipleFiles();

    this.notificationReason = getNotificationReasonText(this.ppNotification.notificationReason);
  }

  private checkNewValue(): boolean {
    return (
      (this.ppNotification.changeBody.newValue &&
        this.ppNotification.changeBody.newValue.length >
          this.ppNotification.changeBody.oldValue.length) ||
      !this.ppNotification.changeBody.oldValue
    );
  }

  private checkAddedSingleFile(): boolean {
    return (
      (!this.ppNotification.changeBody.oldValue &&
        this.ppNotification.changeBody.newValue.length === 1) ||
      this.ppNotification.changeBody.newValue.length -
        this.ppNotification.changeBody.oldValue.length ===
        1
    );
  }

  private checkAddedMultipleFiles(): boolean {
    return (
      (!this.ppNotification.changeBody.oldValue &&
        this.ppNotification.changeBody.newValue.length > 1) ||
      this.ppNotification.changeBody.newValue.length -
        this.ppNotification.changeBody.oldValue.length >
        1
    );
  }

  private checkRemovedSingleFile(): boolean {
    return (
      (!this.ppNotification.changeBody.newValue &&
        this.ppNotification.changeBody.oldValue.length === 1) ||
      this.ppNotification.changeBody.oldValue.length -
        this.ppNotification.changeBody.newValue.length ===
        1
    );
  }

  private checkRemovedMultipleFiles(): boolean {
    return (
      (!this.ppNotification.changeBody.newValue &&
        this.ppNotification.changeBody.oldValue.length > 1) ||
      this.ppNotification.changeBody.oldValue.length -
        this.ppNotification.changeBody.newValue.length >
        1
    );
  }
}
