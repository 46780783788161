import { Component, Input } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TTranslationKey } from '../../features/translate/types';

@Component({
  selector: 'pp-error-box',
  templateUrl: './error-box.component.html',
  styleUrls: ['./error-box.component.scss'],
})
export class ErrorBoxComponent {
  @Input() ppOpeningMessageKey: TTranslationKey;
  @Input() ppClosingMessageKey: TTranslationKey;
  @Input() ppErrors: string[];

  EIconPath = EIconPath;
}
