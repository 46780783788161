<div
  class="siteList__site"
  [class.siteList__siteName--disabled]="disabledReason"
  (click)="openSiteCallback(ppWorkspaceId)"
>
  <ng-container
    *ngIf="
      disabledReason === 'noFields' || disabledReason === 'noTags' || disabledReason === 'noUsers'
    "
  >
    <h6
      ppTooltip
      [ppTitle]="disabledTooltip"
      [ppOptions]="{ placement: 'right' }"
      class="siteList__siteName"
    >
      {{ workspaces[ppWorkspaceId]?.siteName }}
    </h6>
  </ng-container>

  <ng-container *ngIf="disabledReason === 'active' || !disabledReason">
    <h6
      ppTooltip
      [ppTitle]="ppWorkspaceId === ppActiveWorkspaceId ? ('site_currently_on' | ppTranslate) : ''"
      [ppOptions]="{ placement: 'right' }"
      class="siteList__siteName"
    >
      {{ workspaces[ppWorkspaceId]?.siteName }}
    </h6>
  </ng-container>

  <p class="selectedCustomFieldsCounter copy" *ngIf="ppFieldsPerWorkspace[ppWorkspaceId] > 1">
    {{ ppFieldsPerWorkspace[ppWorkspaceId] }}
    {{ SiteListSelectTypesMultiple[ppType] | ppTranslate }}
  </p>

  <p class="selectedCustomFieldsCounter copy" *ngIf="ppFieldsPerWorkspace[ppWorkspaceId] === 1">
    {{ ppFieldsPerWorkspace[ppWorkspaceId] }} {{ SiteListSelectTypesSingle[ppType] | ppTranslate }}
  </p>
</div>
