import { fabric } from 'fabric';

import { Component, OnChanges, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'pp-gallery-overlay-annotations-layer',
  templateUrl: './gallery-overlay-annotations-layer.component.html',
  styleUrls: ['./gallery-overlay-annotations-layer.component.scss'],
})
export class GalleryOverlayAnnotationsLayerComponent implements OnChanges {
  @ViewChild('svgContainerElement', { static: true }) svgContainerElement: ElementRef;
  @Input() ppImageId: number;
  @Input() ppWidth: number;
  @Input() ppHeight: number;
  @Input() ppRotationAngle: number = null;

  canvas: fabric.Canvas = null;

  constructor() {}

  ngOnChanges() {
    this.openCanvas();
  }

  openCanvas(): void {
    this.svgContainerElement.nativeElement.width = this.ppWidth;
    this.svgContainerElement.nativeElement.height = this.ppHeight;

    if (this.canvas) {
      this.canvas.clear();
      this.canvas.setDimensions({ width: this.ppWidth, height: this.ppHeight });
    } else {
      this.canvas = new fabric.Canvas('svgContainerElement', {});
    }
  }
}
