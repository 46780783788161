import { TSelectOption } from 'src/app/project/components/input/select/select.model';
import { translate } from 'src/app/project/features/translate/translate';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';

export function getAdvancedTextOptions(): TSelectOption[] {
  return [
    {
      value: EAdvancedFilterOptions.CONTAINS,
      label: translate('contains') + '...',
    },
    {
      value: EAdvancedFilterOptions.DOES_NOT_CONTAIN,
      label: translate('does_not_contain') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS,
      label: translate('is') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_NOT,
      label: translate('is_not') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_EMPTY,
      label: translate('is_empty') + '.',
    },
    {
      value: EAdvancedFilterOptions.IS_NOT_EMPTY,
      label: translate('is_not_empty') + '.',
    },
  ];
}
