import { translate } from 'src/app/project/features/translate/translate';
import { TAccount } from 'src/app/project/modules/account/account.model';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

export function getAccountHeaderAccountName(
  ppAccountName: string,
  ppWorkspaces: string[],
  workspaces: TWorkspacesById,
  accounts: TAccount[],
): {
  accountName: string;
  tooltip: string;
} {
  let accountName: string;
  let accountNames: string[];

  if (ppAccountName) {
    accountName = ppAccountName;
    accountNames = [ppAccountName];
  } else if (!ppWorkspaces || ppWorkspaces.length === 0) {
    accountName = translate('all_accounts');
    accountNames = [];
  } else {
    accountNames = ppWorkspaces.map((workspaceId) => {
      const accountId = workspaces[workspaceId]?.accountId;
      const account = accounts.find((account) => account.accountId === accountId);
      return account ? account.accountName : '';
    });

    if (new Set(accountNames).size === 1) {
      accountName = accountNames[0];
    } else {
      accountName = translate('multiple_accounts');
    }
  }

  return {
    accountName,
    tooltip: getAccountTooltip(accountName, accountNames),
  };
}

function getAccountTooltip(accountName: string, accountNames: string[]): string {
  const uniqueAccounts = Array.from(new Set(accountNames));

  if (uniqueAccounts.length < 2) {
    return accountName;
  }

  let accountsList = '';

  uniqueAccounts.forEach((account) => {
    accountsList += `<li>${account}</li>`;
  });

  const tooltip = `<div class="tooltip__fleetStatuses">
    <div>${translate('showing_accounts')}:</div>
    <ul>
      ${accountsList}
    </ul>
  </div>`;

  return tooltip;
}
