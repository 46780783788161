import Tooltip from 'src/app/project/features/tooltip/Tooltip';

export function createSettingsTooltip(_element: HTMLElement): Tooltip {
  const settingsTooltip = new Tooltip({
    options: {},
    title: 'Site Settings',
    element: _element,
    mobileTooltip: false,
    childTooltip: false,
    trimTooltip: false,
  });

  settingsTooltip.createTooltip();

  return settingsTooltip;
}
