import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { LayoutModule } from '../layout/layout.module';

import { authRoutes } from './auth.routes';
import { AuthService } from './auth.service';

import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { ButtonModule } from '../../components/button/button.module';
import { CountdownModule } from '../../components/countdown/countdown.module';
import { IconModule } from '../../components/icon/icon-module';
import { CheckboxModule } from '../../components/input/checkbox/checkbox.module';
import { DigitInputModule } from '../../components/input/digit-input/digit-input.module';
import { RadioModule } from '../../components/input/radio/radio.module';
import { MultipleInputSelectModule } from '../../components/multiple-input-select/multiple-input-select.module';
import { PhoneInputModule } from '../../components/phone-input/phone-input.module';
import { AutonumericModule } from '../../directives/autonumeric/autonumeric.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SwitchCasesModule } from '../../directives/switch-cases/switch-cases.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { RemindersModule } from '../reminders/reminders.module';
import { TwoFactorAuthenticationCodePageComponent } from './2fa/two-factor-authentication-code-page/two-factor-authentication-code-page.component';
import { TwoFactorAuthenticationConfirmCodeButtonComponent } from './2fa/two-factor-authentication-confirm-code-button/two-factor-authentication-confirm-code-button.component';
import { TwoFactorAuthenticationLoginComponent } from './2fa/two-factor-authentication-login/two-factor-authentication-login.component';
import { TwoFactorAuthenticationModePickerOptionComponent } from './2fa/two-factor-authentication-mode-picker/two-factor-authentication-mode-picker-option/two-factor-authentication-mode-picker-option.component';
import { TwoFactorAuthenticationModePickerComponent } from './2fa/two-factor-authentication-mode-picker/two-factor-authentication-mode-picker.component';
import { TwoFactorAuthenticationNumberPageComponent } from './2fa/two-factor-authentication-number-page/two-factor-authentication-number-page.component';
import { TwoFactorAuthenticationSetupComponent } from './2fa/two-factor-authentication-setup/two-factor-authentication-setup.component';
import { TwoFactorAuthenticationSuccessPageComponent } from './2fa/two-factor-authentication-success/two-factor-authentication-success-page.component';
import { LoginComponent } from './login/login.component';
import { NotAuthorizedLayoutComponent } from './not-authorized-layout/not-authorized-layout.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { PasswordResetService } from './password-reset/password-reset.service';
import { PasswordUpdateNewPasswordPageComponent } from './password-update/password-update-new-password-page/password-update-new-password-page.component';
import { PasswordUpdateComponent } from './password-update/password-update.component';
import { PasswordUpdateService } from './password-update/password-update.service';
import { PasswordValidationComponent } from './password-validation/password-validation.component';
import { PasswordVisibilityComponent } from './password-visibility/password-visibility.component';
import { RegisterComponent } from './register/register.component';
import { RegisterService } from './register/register.service';
import { UserCreateComponent } from './user-create/user-create.component';
import { VerifyComponent } from './verify/verify.component';
import { VerifyService } from './verify/verify.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(authRoutes),
    CheckboxModule,
    ButtonModule,
    LayoutModule,
    RemindersModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    PipesModule,
    IconModule,
    DigitInputModule,
    CountdownModule,
    PhoneInputModule,
    RadioModule,
    MultipleInputSelectModule,
  ],
  exports: [
    LoginComponent,
    PasswordResetComponent,
    VerifyComponent,
    RegisterComponent,
    PasswordUpdateComponent,
    UserCreateComponent,
    PasswordValidationComponent,
    PasswordVisibilityComponent,
    NotAuthorizedLayoutComponent,
    TwoFactorAuthenticationConfirmCodeButtonComponent,
  ],
  providers: [
    AuthService,
    PasswordResetService,
    VerifyService,
    RegisterService,
    PasswordUpdateService,
  ],
  declarations: [
    LoginComponent,
    PasswordResetComponent,
    VerifyComponent,
    RegisterComponent,
    PasswordUpdateComponent,
    UserCreateComponent,
    PasswordValidationComponent,
    PasswordVisibilityComponent,
    NotAuthorizedLayoutComponent,
    TwoFactorAuthenticationSetupComponent,
    TwoFactorAuthenticationNumberPageComponent,
    TwoFactorAuthenticationCodePageComponent,
    TwoFactorAuthenticationLoginComponent,
    TwoFactorAuthenticationSuccessPageComponent,
    TwoFactorAuthenticationConfirmCodeButtonComponent,
    PasswordUpdateNewPasswordPageComponent,
    TwoFactorAuthenticationModePickerComponent,
    TwoFactorAuthenticationModePickerOptionComponent,
  ],
})
export class AuthModule {}
