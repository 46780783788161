import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TFormulaPiece } from './utils/formula-input.type';

@Component({
  selector: 'pp-formula-builder',
  templateUrl: './formula-builder.component.html',
  styleUrl: './formula-builder.component.scss',
})
export class FormulaBuilderComponent {
  @Input() ppWorkspaceId: string;
  @Input() ppFormula: TFormulaPiece[];
  @Input() ppFieldId: string;
  @Output() ppFormulaChange = new EventEmitter<TFormulaPiece[]>();

  selectOperator(operator: TFormulaPiece): void {
    this.ppFormula.push(operator);

    this.updateFormula(this.ppFormula);
  }

  updateFormula(formula: TFormulaPiece[]): void {
    this.ppFormulaChange.emit(formula);
  }
}
