import { TSelectOption } from 'src/app/project/components/input/select/select.model';
import { translate } from 'src/app/project/features/translate/translate';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';

export function getAdvancedFormulaOptions(): TSelectOption[] {
  return [
    {
      value: EAdvancedFilterOptions.IS,
      label: translate('is') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_NOT,
      label: translate('is_not') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_GREATER_THAN,
      label: translate('is_greater_than') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_LESS_THAN,
      label: translate('is_less_than') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_EMPTY,
      label: translate('is_empty') + '.',
    },
    {
      value: EAdvancedFilterOptions.IS_NOT_EMPTY,
      label: translate('is_not_empty') + '.',
    },
  ];
}

export function getAdvancedSequenceFormulaOptions(): TSelectOption[] {
  return [
    {
      value: EAdvancedFilterOptions.IS,
      label: translate('is') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_NOT,
      label: translate('is_not') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_GREATER_THAN,
      label: translate('is_greater_than') + '...',
    },
    {
      value: EAdvancedFilterOptions.IS_LESS_THAN,
      label: translate('is_less_than') + '...',
    },
  ];
}
