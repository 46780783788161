import { TPoint } from '../../../points/points.model';
import { TColumn } from '../columns/column.model';
import { sortNumbers } from './sort-numbers';
import { sortTitle } from './sort-title';
import { sort } from './sorting';

export function sortUpdated(
  firstPoint: TPoint,
  secondPoint: TPoint,
  sortColumns: Partial<TColumn>[],
  index: number,
): number {
  const result = sortNumbers(firstPoint, secondPoint, sortColumns, index, {
    property: 'header.updatedEpochMillis',
  });

  if (result) {
    return result;
  }

  if (sortColumns[index + 1]) {
    return sort(firstPoint, secondPoint, sortColumns, index + 1);
  } else {
    return sortTitle(firstPoint, secondPoint, sortColumns, index);
  }
}
