import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { TReaction } from 'src/app/project/modules/reactions/reactions.model';
import { PermissionsService } from 'src/app/project/modules/share/permissions.service';
import { TUser } from 'src/app/project/modules/user/user.model';
import { UserService } from 'src/app/project/modules/user/user.service';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventNotifications,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { ReactionsService } from '../../../../services/reactions.service';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { TPushNotification } from '../../notification.model';
import { NotificationsService } from '../../notifications.service';
import { ENotificationType } from '../notification-type.enum';

@Component({
  selector: 'pp-notification-footer',
  templateUrl: './notification-footer.component.html',
  styleUrls: ['../notification.component.scss'],
})
export class NotificationFooterComponent implements OnInit {
  @Input() ppNotification: TPushNotification;
  @Input() ppRespondingToComment: boolean;
  @Input() ppCommentSent: boolean;
  @Input() ppReaction: TReaction;
  @Input() ppAccountName: string;
  @Output() ppOpenPoint = new EventEmitter<void>();
  @Output() ppOpenSite = new EventEmitter<Event>();
  @Output() ppRespond = new EventEmitter<Event>();

  ENotificationType = ENotificationType;
  ECustomFieldType = ECustomFieldType;
  activeUser: TUser;

  clearHover = false;
  commentsEditable = false;
  canRespond = false;
  EIconPath = EIconPath;

  constructor(
    private notificationsService: NotificationsService,
    private reactionsService: ReactionsService,
    private permissionsService: PermissionsService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.activeUser = this.userService.getUser();

    this.commentsEditable = this.checkCommentsPermissions();
    this.canRespond = this.checkResponsePermissions();
  }

  markAsRead(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.notificationsService.changeNotificationReadStatus(this.ppNotification.id, true);

    logEventInGTAG(EGoogleEventNotifications.NOTIFICATIONS__MARK_AS_READ, {
      event_category: EGoogleEventCategory.NOTIFICATIONS,
    });
  }

  markAsUnread(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.notificationsService.changeNotificationReadStatus(this.ppNotification.id, false);

    logEventInGTAG(EGoogleEventNotifications.NOTIFICATIONS__MARK_AS_UNREAD, {
      event_category: EGoogleEventCategory.NOTIFICATIONS,
    });
  }

  removeNotification(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.notificationsService.deleteNotification(this.ppNotification.id);
  }

  onClearHover(hover: boolean): void {
    this.clearHover = hover;
  }

  respond(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.ppRespond.emit(event);
  }

  likeComment(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    let like = true;

    if (this.ppReaction) {
      like = !this.ppReaction.like.includes(this.activeUser.userId);
    }

    this.reactionsService
      .likeComment(this.ppNotification.changeBody.commentId, this.ppNotification.refObject.id, like)
      .subscribe(() => {
        this.ppReaction = this.reactionsService
          .getReactions()
          .notifications.data.find(
            (_reaction) => _reaction.targetRef.id === this.ppNotification.changeBody?.commentId,
          );
      });
  }

  openPoint(): void {
    this.ppOpenPoint.emit();
  }

  checkCommentsPermissions(): boolean {
    const commentPermissions = this.permissionsService.getCommentPermissions(
      this.ppNotification.workspaceId,
    );

    return commentPermissions.edit;
  }

  openSite(event: Event): void {
    this.ppOpenSite.emit(event);
  }

  checkResponsePermissions(): boolean {
    return (
      ((this.ppNotification.pushNotificationType === ENotificationType.POINT_EDITION_COMMENT ||
        this.ppNotification.pushNotificationType === ENotificationType.POINT_MENTION_CUSTOM_FIELD ||
        this.ppNotification.pushNotificationType === ENotificationType.POINT_EDITION_DESCRIPTION ||
        this.ppNotification.pushNotificationType === ENotificationType.POINT_MENTION_COMMENT ||
        this.ppNotification.pushNotificationType === ENotificationType.POINT_MENTION_DESCRIPTION) &&
        this.ppNotification.changeBody &&
        this.ppNotification.changeBody !== 'undefined') ||
      this.ppNotification.changeBody?.cfFieldType === ECustomFieldType.RICHTEXT
    );
  }
}
