import { TReminderList } from '../reminder-list.model';

export function getInitialReminders(
  oldPastReminders: TReminderList,
  oldTodayReminders: TReminderList,
  oldFutureReminders: TReminderList,
): {
  futureReminders: TReminderList;
  todayReminders: TReminderList;
  pastReminders: TReminderList;
} {
  const pastReminders: TReminderList = {
    ...oldPastReminders,
    numberOfDisplayedValues: 0,
    totalValues: 0,
  };

  const todayReminders: TReminderList = {
    ...oldTodayReminders,
    numberOfDisplayedValues: 0,
    totalValues: 0,
  };

  const futureReminders: TReminderList = {
    ...oldFutureReminders,
    numberOfDisplayedValues: 0,
    totalValues: 0,
  };
  return { futureReminders, todayReminders, pastReminders };
}
