export const API_user_is_super_admin = (): string => '/api/v1/users/isSuperAdmin';
export const API_user_self = (): string => '/api/v1/users/self';
export const API_user_simplified = (userId: string): string => `/api/v1/users/${userId}/simplified`;
export const API_user_change_password = (): string => '/api/v1/users/changePassword';
export const API_user_change_workspace = (): string => '/api/v1/users/switch-workspace';
export const API_user_avatar = (userId: string): string => `/api/v1/users/${userId}/avatar`;
export const API_user_type = (userId: string): string => `/api/v1/users/${userId}/user-type`;
export const API_user_add_image = (type: string, id: string): string =>
  `/api/v1/users/${id}/avatar`;
export const API_user_update_email = (userId: string): string =>
  `/api/v1/users/${userId}/simple-update`;
