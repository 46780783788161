import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { TCustomField } from '../../custom-fields.model';
import { TBulkCustomFields, TBulkCustomFieldsExtended } from '../bulk-custom-fields.model';

@Component({
  selector: 'pp-bulk-field-cost',
  templateUrl: './bulk-field-cost.component.html',
  styleUrls: ['../bulk-custom-fields-modal.component.scss'],
})
export class BulkFieldCostComponent {
  @Input() ppCustomField: TCustomField;
  @Input() ppCustomFields: TBulkCustomFields<number>;
  @Input() ppFieldId: string;
  @Input() ppCustomFieldsExtended: TBulkCustomFieldsExtended<number>;

  @Output() ppCustomFieldsExtendedChange = new EventEmitter<TBulkCustomFieldsExtended>();
  @Output() ppCustomFieldsChange = new EventEmitter<TBulkCustomFields>();

  EIconPath = EIconPath;

  updateCost(cost: string, field: TCustomField): void {
    this.ppCustomFields[field.id] = +cost;

    this.ppCustomFieldsExtended[field.id] = {
      value: this.ppCustomFields[field.id],
      label: field.label,
      type: field.type,
    };

    this.ppCustomFieldsChange.emit(this.ppCustomFields);
    this.ppCustomFieldsExtendedChange.emit(this.ppCustomFieldsExtended);
  }
}
