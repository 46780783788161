import { TPoint } from '../../../points/points.model';
import { EAdvancedFilterOptions } from '../../models/advanced-filter-options.enum';
import { TAdvancedFilterNumber } from '../../models/advanced-filter.model';
import { filterIdToPointProperty } from '../../utils/filter-id-to-property';

export function filterNumberAdvanced(point: TPoint, filter: TAdvancedFilterNumber): boolean {
  const filterValue = filter.value ? filter.value : 0;
  const value = filterIdToPointProperty(filter.id, point) as number;
  const fieldValue = value ? value : 0;

  switch (filter.option) {
    case EAdvancedFilterOptions.IS:
      return +fieldValue === +filterValue;
    case EAdvancedFilterOptions.IS_NOT:
      return +fieldValue !== +filterValue;
    case EAdvancedFilterOptions.IS_GREATER_THAN:
      return +fieldValue > +filterValue;
    case EAdvancedFilterOptions.IS_LESS_THAN:
      return +fieldValue < +filterValue;
    case EAdvancedFilterOptions.IS_EMPTY:
      return !fieldValue;
    case EAdvancedFilterOptions.IS_NOT_EMPTY:
      return !!fieldValue;
  }
}
