import { createElement } from 'src/app/core/helpers/dom';
import { createTimelineStatusElement } from './create-timeline-status-element';

export function createTimelinePriorityElement(status: string, priority: string): HTMLElement {
  const element = createElement('div', {
    attrs: {
      class: ['timeline__fieldElement__priority', `timelinePriority--${priority}`],
    },
    children: [createTimelineStatusElement(status)],
  });

  return element;
}
