import { TFleet, TFleetStatus } from '../fleet.consts';

export function areFleetsEqual(oldFleet: Partial<TFleet>, newFleet: Partial<TFleet>): boolean {
  const nameMatches = oldFleet.name === newFleet.name;
  const accountIdMatches = oldFleet.accountId === newFleet.accountId;

  let statusSettingsMatch = true;

  const statuses = Object.keys(oldFleet.statusSettings);

  for (let i = 0; i < statuses.length; i++) {
    const status = statuses[i];
    statusSettingsMatch = checkStatusMatch(
      oldFleet.statusSettings[status],
      newFleet.statusSettings[status],
    );

    if (!statusSettingsMatch) {
      break;
    }
  }

  return nameMatches && accountIdMatches && statusSettingsMatch;
}

function checkStatusMatch(oldStatus: TFleetStatus, newStatus: TFleetStatus) {
  return oldStatus.weight === newStatus.weight && oldStatus.include === newStatus.include;
}
