<div class="filterDropdown__section filterDropdown__section--status">
  <div class="filterDropdown__heading">
    <h6>{{ 'status' | ppTranslate }}</h6>

    <button class="filterDropdown__clear--text" *ngIf="!isStatusDefault" (click)="clearStatus()">
      {{ 'clear' | ppTranslate }}
    </button>
  </div>

  <div class="filterDropdown__content filterDropdown__content--status">
    <div
      class="filterDropdown__checkbox filterDropdown__checkbox--margin statusCheckbox"
      *ngFor="let status of ppFilters.status"
    >
      <pp-checkbox
        [ppChecked]="status.value"
        (ppOnValueChange)="updateStatusValue(status)"
        ppType="solid"
      >
        <pp-status-button [ppStatus]="status.label"></pp-status-button>
      </pp-checkbox>
    </div>
  </div>

  <hr />
</div>
