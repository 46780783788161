import { Injectable, OnDestroy } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterAllPoints } from 'src/app/project/modules/points/points.actions';

import { TPoint, TPointIdsByWorkspace, TPoints } from 'src/app/project/modules/points/points.model';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { TAllFilters, TFilters } from './site-filter.model';

import { PointsService } from 'src/app/project/modules/points/points.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { PlanDataService, TPlanData } from '../plan/plan-data.service';
import { PlanPinsService } from '../plan/plan-pins.service';
import { SiteTableColumnsDataService } from '../site/site-table/site-table-columns-data.service';
import { SiteService } from '../site/site.service';
import { WorkspaceService } from '../workspace/workspace.service';
import { CheckPointService } from './check-point.service';

import { clone } from 'lodash';
import { EStore } from '../../shared/enums/store.enum';
import { AdvancedFilterService } from '../filters-advanced/advanced-filter.service';
import { CheckAdvancedPointService } from '../filters-advanced/check-advanced-point-service/check-advanced-point.service';
import { EPlanModule } from '../plan/plan-module.enum';
import { getSelectedPoints, removeSelectedPoint } from '../points/selected-points';
import { CustomTableService } from '../site/site-table/custom-table/custom-table.service';
import { GET_TIMELINE } from '../site/site-timeline/timeline.ui.store';
import { checkCustomWorkspaceId } from '../workspace/workspace';
import { checkLocation } from './filter-logic/filter-location';

@Injectable({
  providedIn: 'root',
})
export class SitePointFilterService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private filters: TFilters;
  private plan: TPlanData = this.planDataService.getPlan();

  siteFilters$ = new Observable<TAllFilters>();
  workspaces$ = new Observable<TWorkspacesById>();
  workspaceId: string;
  allFilters: TAllFilters;

  constructor(
    private store: Store<{
      points: TPoints;
      siteFilter: TAllFilters;
      workspaces: TWorkspacesById;
    }>,
    private siteTableColumnsDataService: SiteTableColumnsDataService,
    private planDataService: PlanDataService,
    private activeService: ActiveService,
    private pointsService: PointsService,
    private workspaceService: WorkspaceService,
    private siteService: SiteService,
    private planPinsService: PlanPinsService,
    private customTableService: CustomTableService,
    private checkPointService: CheckPointService,
    private advancedFilterService: AdvancedFilterService,
    private checkAdvancedPointService: CheckAdvancedPointService,
  ) {
    this.siteFilters$ = this.store.pipe(select(EStore.SITE_FILTER));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.siteFilters$.pipe(takeUntil(this.destroy$)).subscribe((allFilters: TAllFilters) => {
      this.allFilters = allFilters;
      this.filters = this.allFilters?.[this.workspaceId];
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.workspaceId = this.activeService.getActiveWorkspaceId();

      if (!this.workspaceId) {
        this.workspaceId = checkCustomWorkspaceId();
      }

      this.filters = this.allFilters?.[this.workspaceId];
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  filterPoints({
    _keepScrollPosition = false,
    resetTable = true,
  }: { _keepScrollPosition?: boolean; resetTable?: boolean } = {}): void {
    const workspaceId = this.activeService.getActiveWorkspaceId();
    let points: TPoint[];

    this.siteTableColumnsDataService.clearColumns({ totalCost: true });

    if (workspaceId) {
      points = this.filterWorkspacePoints(workspaceId);
    } else {
      points = this.filterOverviewPoints();
    }

    this.updateTable(points, _keepScrollPosition, resetTable);

    this.checkSelectedPoints(points);

    this.planPinsService.updatePointMarkers(EPlanModule.SITE);
    this.siteService.setTableFetched(true);
  }

  filterWorkspacePoints(workspaceId: string): TPoint[] {
    if (this.advancedFilterService.getAdvancedFilter(workspaceId).enabled) {
      return this.filterAdvancedWorkspacePoints(workspaceId);
    }

    let points = [];

    if (this.pointsService.getPointsData(workspaceId)) {
      // Map points don't check location (so they shouldn't dissapear from a site plan and are visible when panning).
      // Otherwise you wouldn't see anything when pannig site plan until you dropped it
      const mapPoints = this.pointsService
        .getPoints()
        .filter((point) => this.checkPointService.checkPoint(point, true));

      points = mapPoints.filter((point) => checkLocation(point, this.filters, this.plan));

      this.pointsService.setMapPoints(mapPoints);
    }

    return points;
  }

  filterAdvancedWorkspacePoints(workspaceId: string): TPoint[] {
    let points: TPoint[] = [];

    if (this.pointsService.getPointsData(workspaceId)) {
      // Map points don't check location (so they shouldn't dissapear from a site plan and are visible when panning).
      // Otherwise you wouldn't see anything when pannig site plan until you dropped it
      const mapPoints = this.pointsService
        .getPoints()
        .filter((point) =>
          this.checkAdvancedPointService.checkPoint(point, workspaceId, this.filters),
        );

      points = mapPoints.filter((point) => checkLocation(point, this.filters, this.plan));

      this.pointsService.setMapPoints(mapPoints);
    }

    return points;
  }

  filterOverviewPoints(): TPoint[] {
    const visiblePointIdsByWorkspace: TPointIdsByWorkspace = {};
    let totalVisiblePoints = [];

    const workspaces = this.workspaceService.getWorkspaces();
    const pointsToToggle = [];
    const selectedPointsIds = getSelectedPoints();

    Object.keys(workspaces).forEach((_workspaceId) => {
      if (this.pointsService.getPointsData(_workspaceId)) {
        const workspacePoints = this.pointsService.getPoints(_workspaceId);
        const visiblePoints = workspacePoints.filter((point) =>
          this.checkPointService.checkPoint(point),
        );
        visiblePointIdsByWorkspace[_workspaceId] = visiblePoints.map((point) => point._id);
        totalVisiblePoints = [...visiblePoints, ...totalVisiblePoints];

        workspacePoints.forEach((point) => {
          if (
            selectedPointsIds.includes(point._id) &&
            !visiblePointIdsByWorkspace[_workspaceId].includes(point._id)
          ) {
            pointsToToggle.push(point._id);
          }
        });
      }
    });

    this.store.dispatch(
      new FilterAllPoints({
        pointsByWorkspace: visiblePointIdsByWorkspace,
        pointsToToggle,
      }),
    );

    return totalVisiblePoints;
  }

  private checkSelectedPoints(points: TPoint[]): void {
    const selectedPoints = clone(getSelectedPoints());

    selectedPoints.forEach((pointId) => {
      const pointFound = points.find((point) => point._id === pointId);

      if (!pointFound) {
        removeSelectedPoint(pointId);
      }
    });
  }

  private updateTable(points: TPoint[], _keepScrollPosition: boolean, resetTable: boolean): void {
    const table = this.customTableService.getTable();
    const timeline = GET_TIMELINE();

    if (table) {
      table.updatePoints(points);
      resetTable ? table.load(_keepScrollPosition) : null;
    }

    if (timeline) {
      timeline.updatePoints(points);
      timeline.load(_keepScrollPosition);
    }
  }
}
