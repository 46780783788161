import { cloneDeep } from 'lodash';
import { UpdatePoints } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';

export function updatePoints(
  oldState: TPointsByWorkspace,
  action: UpdatePoints,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);
  let pointIndex = null;

  action.payload.changedPoints.forEach((point) => {
    const workspaceId = point.workspaceRef.id;

    if (state[workspaceId]) {
      pointIndex = state[workspaceId].entities.findIndex((_point) => _point._id === point._id);

      if (pointIndex > -1) {
        state[workspaceId].entities.splice(pointIndex, 1, point);

        return state;
      }

      state[workspaceId].entities = [...state[workspaceId].entities, point];
    } else {
      if (!state[workspaceId]) {
        state[workspaceId] = {};
      }

      state[workspaceId].entities = [point];
    }
  });

  return state;
}
