import { EDashletType } from '../dashlets-enums';
import { TDashlet } from '../../preferences/preferences.model';

type TDashledGrid = {
  rows: number;
  cols: number;
};

export function updateDashletSize(dashlets: TDashlet[]): TDashlet[] {
  return dashlets.map((dashlet) => ({
    ...dashlet,
    ...getDashletGrid(dashlet),
  }));
}

const commonDashletTypes: EDashletType[] = [
  EDashletType.HIGH,
  EDashletType.MEDIUM,
  EDashletType.LOW,
  EDashletType.OPEN,
  EDashletType.IN_PROGRESS,
  EDashletType.ONHOLD,
  EDashletType.CLOSED,
  EDashletType.COMMENT_CREATE,
  EDashletType.DEFECT_CREATE,
];

function getDashletGrid(dashlet: TDashlet): TDashledGrid {
  if (dashlet.rows < 5 || dashlet.cols < 4) {
    if (commonDashletTypes.includes(dashlet.name)) {
      return {
        rows: 6,
        cols: 6,
      };
    } else if (dashlet.name === EDashletType.ACTIVITIES) {
      return {
        rows: 12,
        cols: 18,
      };
    }
    return {
      rows: 10,
      cols: 18,
    };
  }
}
