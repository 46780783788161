<div
  class="chip2FA"
  [class.chip2FA--enabled]="ppTwoFactorAuthenticationEnabled"
  [class.chip2FA--disabled]="!ppTwoFactorAuthenticationEnabled"
  ppTooltip
  [ppTitle]="tooltipText"
>
  <span>{{ '2fa' | ppTranslate }}</span>
  <pp-icon [ppSrc]="iconSrc" ppClass="setSize14" ppColor="inherit"></pp-icon>
</div>
