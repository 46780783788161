<div class="filterDropdown__section filterDropdown__section--location">
  <div class="filterDropdown__heading">
    <h6>{{ 'flags' | ppTranslate }}</h6>

    <button
      class="filterDropdown__clear--text"
      *ngIf="!ppFilters.showFlaggedPoints || !ppFilters.showUnflaggedPoints"
      (click)="clearFlag()"
      role="button"
    >
      {{ 'clear' | ppTranslate }}
    </button>
  </div>

  <div class="filterDropdown__content filterDropdown__checkbox filterDropdown__checkbox--location">
    <pp-checkbox
      class="customField__toggle"
      [ppChecked]="ppFilters.showFlaggedPoints"
      (ppOnValueChange)="toggleFlaggedPoints()"
      ppType="solid"
    >
      {{ 'flagged' | ppTranslate }}
    </pp-checkbox>
  </div>

  <div class="filterDropdown__content filterDropdown__checkbox filterDropdown__checkbox--location">
    <pp-checkbox
      class="customField__toggle"
      [ppChecked]="ppFilters.showUnflaggedPoints"
      (ppOnValueChange)="toggleUnflaggedPoints()"
      ppType="solid"
    >
      {{ 'unflagged' | ppTranslate }}
    </pp-checkbox>
  </div>

  <hr />
</div>
