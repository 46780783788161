<div class="filterDropdown__section">
  <div class="filterDropdown__heading">
    <h6>{{ 'date_updated' | ppTranslate }}</h6>
    <button
      *ngIf="ppFilters.date.updated.startDate || ppFilters.date.updated.endDate"
      class="filterDropdown__clear--text"
      (click)="clearDateUpdated()"
    >
      {{ 'clear' | ppTranslate }}
    </button>
  </div>

  <div class="filterContent__date-range">
    <div class="filterContent__date-wrapper">
      <pp-datepicker
        class="siteFilter__date-picker"
        [ppFilterDatepicker]="true"
        [ppDate]="ppFilters.date.updated.startDate"
        [ppMaxDate]="ppFilters.date.updated.endDate"
        (ppSelect)="updateDate($event, 'startDate')"
        [ppPlaceholder]="'from' | ppTranslate"
      >
      </pp-datepicker>
    </div>

    <div class="filterContent__date-wrapper">
      <pp-datepicker
        class="siteFilter__date-picker"
        [ppFilterDatepicker]="true"
        [ppDate]="ppFilters.date.updated.endDate"
        [ppMinDate]="ppFilters.date.updated.startDate"
        (ppSelect)="updateDate($event, 'endDate')"
        [ppPlaceholder]="'to' | ppTranslate | lowercase"
      >
      </pp-datepicker>
    </div>
  </div>

  <hr />
</div>
