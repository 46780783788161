<button
  id="renameViewBtn"
  data-m-target="Rename view button"
  class="dropdownItem"
  (click)="renameView()"
  *ngIf="type === ESavedView.PERSONAL || isAdminUser"
>
  <pp-icon
    class="dropdownItem__svgIcon"
    [ppSrc]="EIconPath.ICON_MISC_EDIT_18"
    ppColor="grey-600"
    [ppDisplayContents]="true"
  ></pp-icon>

  {{ 'rename_view' | ppTranslate }}
</button>

<button
  id="setDefaultViewBtn"
  data-m-target="Set my default view button"
  class="dropdownItem"
  (click)="setPersonalDefaultView()"
  *ngIf="views.personal.defaultViewId !== view.id"
>
  <pp-icon
    class="dropdownItem__svgIcon"
    [ppSrc]="EIconPath.ICON_NAV_TABLE_VIEW_18"
    ppColor="grey-600"
    [ppDisplayContents]="true"
  ></pp-icon>
  {{ 'set_my_default_view' | ppTranslate }}
</button>

<button
  id="setDefaultSiteViewBtn"
  data-m-target="Set shared default view button"
  class="dropdownItem"
  (click)="setSharedDefaultView()"
  *ngIf="type === ESavedView.SHARED && isAdminUser && views.shared.sharedDefaultViewId !== view.id"
>
  <pp-icon
    class="dropdownItem__svgIcon"
    [ppSrc]="EIconPath.ICON_NAV_TABLE_VIEW_18"
    ppColor="grey-600"
    [ppDisplayContents]="true"
  ></pp-icon>
  {{ 'set_shared_default_view' | ppTranslate }}
</button>

<button
  id="removeDefaultViewBtn"
  data-m-target="Remove my default view button"
  class="dropdownItem"
  (click)="clearPersonalDefaultView()"
  *ngIf="views.personal.defaultViewId === view.id"
>
  <pp-icon
    class="dropdownItem__svgIcon"
    [ppSrc]="EIconPath.ICON_NAV_TABLE_VIEW_18"
    ppColor="grey-600"
    [ppDisplayContents]="true"
  ></pp-icon>
  {{ 'clear_my_default_view' | ppTranslate }}
</button>

<button
  id="removeDefaultSiteViewBtn"
  data-m-target="Remove shared default view button"
  class="dropdownItem"
  (click)="clearSharedDefaultView()"
  *ngIf="type === ESavedView.SHARED && isAdminUser && views.shared.sharedDefaultViewId === view.id"
>
  <pp-icon
    class="dropdownItem__svgIcon"
    [ppSrc]="EIconPath.ICON_NAV_TABLE_VIEW_18"
    ppColor="grey-600"
    [ppDisplayContents]="true"
  ></pp-icon>
  {{ 'clear_shared_default_view' | ppTranslate }}
</button>

<button
  id="duplicateViewBtn"
  data-m-target="Duplicate view button"
  class="dropdownItem"
  (click)="duplicateView()"
  *ngIf="(type === ESavedView.SHARED && isAdminUser) || type === ESavedView.PERSONAL"
>
  <pp-icon
    class="dropdownItem__svgIcon"
    [ppSrc]="EIconPath.ICON_SAVED_VIEWS_DUPLICATE"
    ppColor="grey-600"
    [ppDisplayContents]="true"
  ></pp-icon>
  {{ 'duplicate_view' | ppTranslate }}
</button>

<button
  id="duplicateToSharedViewsBtn"
  data-m-target="Duplicate to shared views button"
  class="dropdownItem"
  (click)="duplicateViewToShared()"
  *ngIf="type === ESavedView.PERSONAL && isAdminUser"
>
  <pp-icon
    class="dropdownItem__svgIcon"
    [ppSrc]="EIconPath.ICON_NAV_TABLE_VIEW_18"
    ppColor="grey-600"
    [ppDisplayContents]="true"
  ></pp-icon>
  {{ 'duplicate_view_to_shared' | ppTranslate }}
</button>

<button
  id="duplicateToPersonalViewsBtn"
  data-m-target="Duplicate to my views button"
  class="dropdownItem"
  (click)="duplicateViewToPersonal()"
  *ngIf="type === ESavedView.SHARED"
>
  <pp-icon
    class="dropdownItem__svgIcon"
    [ppSrc]="EIconPath.ICON_NAV_TABLE_VIEW_18"
    ppColor="grey-600"
    [ppDisplayContents]="true"
  ></pp-icon>
  {{ 'duplicate_view_to_personal' | ppTranslate }}
</button>

<button
  id="deleteViewBtn"
  data-m-target="Delete view button"
  class="dropdownItem"
  (click)="deleteView()"
  *ngIf="type === ESavedView.PERSONAL || isAdminUser"
>
  <pp-icon
    class="dropdownItem__svgIcon"
    [ppSrc]="EIconPath.ICON_BIN_18"
    [ppDisplayContents]="true"
    ppColor="inherit"
  ></pp-icon>
  {{ 'delete_view' | ppTranslate }}
</button>
