import { setDefaultPanelTextHover } from './set-default-panel-text-hover';
import { setPanelText } from './set-panel-text';
import { setPanelTextHover } from './set-panel-text-hover';
import { TWhiteLabelStore } from '../white-label.model';
import { setDefaultFontFamily } from './set-default-font-family';
import { setDefaultPanelAccent } from './set-default-panel-accent';
import { setDefaultPanelBg } from './set-default-panel-bg';
import { setDefaultPanelFont } from './set-default-panel-font';
import { setDefaultPanelFontHighlight } from './set-default-panel-font-highlight';
import { setDefaultPanelText } from './set-default-panel-text';
import { setFontFamily } from './set-font-family';
import { setPanelAccent } from './set-panel-accent';
import { setPanelBg } from './set-panel-bg';
import { setPanelFont } from './set-panel-font';
import { setPanelFontHighlight } from './set-panel-font-highlight';
import {
  setPrimary50,
  setDefaultPrimary50,
  setPrimary75,
  setDefaultPrimary75,
  setPrimary100,
  setDefaultPrimary100,
  setPrimary200,
  setDefaultPrimary200,
  setPrimary300,
  setDefaultPrimary300,
  setPrimary400,
  setDefaultPrimary400,
  setPrimary500,
  setDefaultPrimary500,
  setPrimary600,
  setDefaultPrimary600,
  setPrimary700,
  setDefaultPrimary700,
  setPrimary800,
  setDefaultPrimary800,
  setPrimary900,
  setDefaultPrimary900,
} from './set-primary-colors';

export function setWhiteLabelStyles(
  themeWrapper: HTMLBodyElement,
  whiteLabel: TWhiteLabelStore,
): void {
  if (whiteLabel.data && whiteLabel.data.panelFontHighlight) {
    setPanelFontHighlight(themeWrapper, whiteLabel);
  } else {
    setDefaultPanelFontHighlight(themeWrapper);
  }

  if (whiteLabel.data && whiteLabel.data.panelFont) {
    setPanelFont(themeWrapper, whiteLabel);
  } else {
    setDefaultPanelFont(themeWrapper);
  }
  if (whiteLabel.data && whiteLabel.data.panelBg) {
    setPanelBg(themeWrapper, whiteLabel);
  } else {
    setDefaultPanelBg(themeWrapper);
  }

  if (whiteLabel.data && whiteLabel.data.panelAccent) {
    setPanelAccent(themeWrapper, whiteLabel);
  } else {
    setDefaultPanelAccent(themeWrapper);
  }

  if (whiteLabel.data && whiteLabel.data.fontFamily) {
    setFontFamily(themeWrapper, whiteLabel);
  } else {
    setDefaultFontFamily(themeWrapper);
  }

  if (whiteLabel.data && whiteLabel.data.panelText) {
    setPanelText(themeWrapper, whiteLabel);
  } else {
    setDefaultPanelText(themeWrapper);
  }

  if (whiteLabel.data && whiteLabel.data.panelTextHover) {
    setPanelTextHover(themeWrapper, whiteLabel);
  } else {
    setDefaultPanelTextHover(themeWrapper);
  }

  if (whiteLabel.data && whiteLabel.data.primary50) {
    setPrimary50(themeWrapper, whiteLabel);
  } else {
    setDefaultPrimary50(themeWrapper);
  }

  if (whiteLabel.data && whiteLabel.data.primary75) {
    setPrimary75(themeWrapper, whiteLabel);
  } else {
    setDefaultPrimary75(themeWrapper);
  }

  if (whiteLabel.data && whiteLabel.data.primary100) {
    setPrimary100(themeWrapper, whiteLabel);
  } else {
    setDefaultPrimary100(themeWrapper);
  }

  if (whiteLabel.data && whiteLabel.data.primary200) {
    setPrimary200(themeWrapper, whiteLabel);
  } else {
    setDefaultPrimary200(themeWrapper);
  }

  if (whiteLabel.data && whiteLabel.data.primary300) {
    setPrimary300(themeWrapper, whiteLabel);
  } else {
    setDefaultPrimary300(themeWrapper);
  }

  if (whiteLabel.data && whiteLabel.data.primary400) {
    setPrimary400(themeWrapper, whiteLabel);
  } else {
    setDefaultPrimary400(themeWrapper);
  }

  if (whiteLabel.data && whiteLabel.data.primary500) {
    setPrimary500(themeWrapper, whiteLabel);
  } else {
    setDefaultPrimary500(themeWrapper);
  }

  if (whiteLabel.data && whiteLabel.data.primary600) {
    setPrimary600(themeWrapper, whiteLabel);
  } else {
    setDefaultPrimary600(themeWrapper);
  }

  if (whiteLabel.data && whiteLabel.data.primary700) {
    setPrimary700(themeWrapper, whiteLabel);
  } else {
    setDefaultPrimary700(themeWrapper);
  }

  if (whiteLabel.data && whiteLabel.data.primary800) {
    setPrimary800(themeWrapper, whiteLabel);
  } else {
    setDefaultPrimary800(themeWrapper);
  }

  if (whiteLabel.data && whiteLabel.data.primary900) {
    setPrimary900(themeWrapper, whiteLabel);
  } else {
    setDefaultPrimary900(themeWrapper);
  }
}
