import { Component, OnInit } from '@angular/core';
import { cloneDeep, isEmpty } from 'lodash';
import { finalize, tap } from 'rxjs/operators';
import { Modal, ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { AccountSubscriptionService } from '../../../../account-subscription.service';
import {
  EAccountSubscriptionType,
  TAccountSubscription,
  TAccountSubscriptionUpdate,
} from '../../../../account.model';
import { TSubscriptionUpdateModalData } from './subscription-update-modal.consts';

@Component({
  selector: 'pp-account-settings-subscription-update-modal',
  templateUrl: './account-settings-subscription-update-modal.component.html',
  styleUrls: ['./account-settings-subscription-update-modal.component.scss'],
})
export class AccountSettingsSubscriptionUpdateModalComponent implements OnInit {
  processing = false;
  modal: Modal;
  EAccountSubscriptionType = EAccountSubscriptionType;

  subscriptionData: TAccountSubscription;
  accountId: string;
  EIconPath = EIconPath;

  constructor(
    private modalService: ModalService,
    private accountSubscriptionService: AccountSubscriptionService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
  ) {}

  ngOnInit(): void {
    this.modal = this.modalService.getModal();
    const modalData: TSubscriptionUpdateModalData = cloneDeep(this.modal.data);

    this.subscriptionData = modalData.subscription;
    this.accountId = modalData.accountId;
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  changeLimits(): void {
    if (this.processing) {
      return;
    }

    this.processing = true;

    const body: TAccountSubscriptionUpdate = {
      accountId: this.accountId,
      subscriptionType: this.subscriptionData.subscriptionType,
      limits:
        this.subscriptionData.subscriptionType === EAccountSubscriptionType.LIMITED
          ? this.subscriptionData.limits
          : {},
    };

    this.accountSubscriptionService
      .updateSubscription(body, this.accountId)
      .pipe(
        tap(() => {
          const prompt = this.translationPipe.transform('prompt_subscription_type_change');

          this.promptService.showSuccess(prompt);
          this.modalService.hideModal(false);
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  toggleUpgrade(): void {
    if (this.subscriptionData.subscriptionType === EAccountSubscriptionType.LIMITED) {
      this.subscriptionData.subscriptionType = EAccountSubscriptionType.UNLIMITED;

      delete this.subscriptionData.limits;
    } else {
      this.subscriptionData.subscriptionType = EAccountSubscriptionType.LIMITED;

      if (!this.subscriptionData.limits || isEmpty(this.subscriptionData.limits)) {
        this.subscriptionData.limits = {
          SHARES_ACCOUNT_ADMIN: +this.subscriptionData.usages.SHARES_ACCOUNT_ADMIN,
          SHARES_ADMIN: +this.subscriptionData.usages.SHARES_ADMIN,
          SHARES_GUEST: +this.subscriptionData.usages.SHARES_GUEST,
          SHARES_NORMAL: +this.subscriptionData.usages.SHARES_NORMAL,
          SITES: +this.subscriptionData.usages.SITES,
        };
      }
    }
  }
}
