import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { TAccount } from '../account.model';

export function searchAccount(
  items: TAccount[],
  filter: string,
  workspaces: TWorkspacesById,
): TAccount[] {
  if (!items || !filter) {
    return items;
  }

  return items.filter((account) => {
    if (account.accountName.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
      return true;
    }

    let matchedAccount = null;

    for (let i = 0; i < account.workspaces.length; i++) {
      const workspace = workspaces[account.workspaces[i]];
      const workspaceName = workspace.siteName;

      if (!workspace) {
        continue;
      }

      if (workspaceName.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
        matchedAccount = account;
      }
    }

    return matchedAccount !== null;
  });
}
