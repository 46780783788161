import { Injectable } from '@angular/core';

import { ResponseErrorService } from '../../errors/response-error.service';

import { AuthApiProviderService } from '@core/api';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TSignupModel } from 'src/app/project/data-providers/api-providers/auth-api-provider/auth-signup-request.model';
import { TUserResponse } from 'src/app/project/modules/user/response-models/user-response-model';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  constructor(
    private authApiProviderService: AuthApiProviderService,
    private responseErrorService: ResponseErrorService,
  ) {}

  signup(user: TSignupModel): Observable<TUserResponse> {
    return this.authApiProviderService
      .signup(user)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }
}
