import { Component, EventEmitter, Input, Output } from '@angular/core';
import { humanizeStatus } from 'src/app/project/modules/points/statuses';
import { EStatus } from 'src/app/project/shared/enums/status.enum';

@Component({
  selector: 'pp-fleet-management-new-fleet-status-checkbox',
  templateUrl: './fleet-management-new-fleet-status-checkbox.component.html',
  styleUrls: ['./fleet-management-new-fleet-status-checkbox.component.scss'],
})
export class FleetManagementNewFleetStatusCheckboxComponent {
  @Input() ppStatus: EStatus;
  @Input() ppInclude: boolean;
  @Output() ppIncludeChange = new EventEmitter<boolean>();

  status: string;

  ngOnChanges(): void {
    this.status = humanizeStatus(this.ppStatus);
  }

  updateInclude(): void {
    this.ppIncludeChange.emit(!this.ppInclude);
  }
}
