<div
  class="selectDropdown selectDropdown--tags"
  [ngStyle]="{ width: isMobile ? 'unset' : dropdownWidth }"
>
  <pp-hide-dropdown (ppHideDropdown)="hideDropdown()"></pp-hide-dropdown>

  <pp-search-box
    [ppPlaceholder]="'Filter tags'"
    (ppAction)="setSearchBox($event)"
    [ppFocusOnInit]="true"
    ppSize="wide"
    class="dropdown__searchBar"
  ></pp-search-box>

  <div class="warning__message" *ngIf="message">
    <ng-container *ngIf="message">
      {{ message }}
    </ng-container>

    <ng-container *ngIf="filteredItems?.length === 0 && !message">
      {{ 'no_search_result' | ppTranslate }}
    </ng-container>
  </div>

  <ul id="tagListElement" class="dropdownWrapper dropdownWrapper--scrollbar">
    <li
      class="ppTag_list"
      *ngFor="let item of filteredItems"
      (click)="selectItem(item)"
      (ppOnRendered)="updateDropdownWidth($event, item)"
    >
      <pp-tag [ppText]="item" [ppDisabled]="true"></pp-tag>
    </li>
  </ul>

  <div class="tags__buttons" *ngIf="!hideButtons">
    <pp-button ppStyle="secondary" (ppAction)="clearAll()">
      {{ 'clear_all' | ppTranslate }}
    </pp-button>

    <pp-button ppStyle="primary" (ppAction)="hideDropdown()">
      {{ 'done' | ppTranslate }}
    </pp-button>
  </div>

  <div class="tags__buttons" *ngIf="noneAccess">
    <pp-button ppStyle="primary" (ppAction)="clearAll()">
      {{ 'unrestrict_tag_access' | ppTranslate }}
    </pp-button>
  </div>
</div>
