<ng-container>
  <td class="checkbox__row" (click)="selectUser()">
    <pp-checkbox
      [ppChecked]="ppUserSelected && !(ppAlreadySelected || ppAlreadyExists)"
      [ppDisabled]="ppAlreadyExists || ppAlreadySelected"
      ppSize="medium"
      ppType="solid"
    >
    </pp-checkbox>
  </td>

  <td class="name__row" (click)="selectUser()" #nameRow>
    <img
      ppImage
      class="userAvatar"
      [class.userAvatar--disabled]="ppAlreadySelected || ppAlreadyExists"
      [src]="users[ppUser.userId].avatarPublicUrl"
      *ngIf="users[ppUser.userId].avatarPublicUrl"
    />

    <img
      class="userAvatar"
      [src]="EIconPath.PLACEHOLDER_USER"
      *ngIf="!users[ppUser.userId].avatarPublicUrl"
    />

    <ul class="userDetails">
      <li
        (mouseenter)="mouseEnterField(users[ppUser.userId].userName)"
        (mouseleave)="mouseLeaveField()"
        class="userDetails__name copyHeadingReg"
        [class.userDetails__name--disabled]="ppAlreadySelected || ppAlreadyExists"
        *ngIf="users[ppUser.userId].userName"
      >
        {{ users[ppUser.userId].userName }}
      </li>

      <li
        (mouseenter)="mouseEnterField(ppUser.email)"
        (mouseleave)="mouseLeaveField()"
        [class.userDetails__email--disabled]="ppAlreadySelected || ppAlreadyExists"
        class="userDetails__email"
      >
        {{ ppUser.email }}
      </li>
    </ul>
  </td>

  <td class="accessRow">
    <span class="accessLevel">{{ ppRole }}</span>
  </td>

  <td class="tagsRow">
    <pp-share-import-tag-list
      [ppLimitedTags]="ppLimitedTags"
      [ppDisabled]="ppAlreadySelected || ppAlreadyExists"
      [ppTags]="ppTags"
    ></pp-share-import-tag-list>
  </td>

  <td class="actionRow" [class.actionRow__disabled]="ppAlreadyExists || ppAlreadySelected">
    <pp-button
      ppStyle="primary"
      (ppAction)="editShare()"
      ppSize="small"
      [ppDisabled]="ppAlreadyExists || ppAlreadySelected"
    >
      <span class="editText">{{ 'edit' | ppTranslate }}</span>
    </pp-button>
  </td>
</ng-container>
