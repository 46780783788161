import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '../icon/icon-module';
import { AuthorizationProgressComponent } from './authorization-progress.component';
import { AuthorizationProgressIconComponent } from './authorization-progress-icon/authorization-progress-icon.component';
import { AuthorizationProgressBarComponent } from './authorization-progress-bar/authorization-progress-bar.component';

@NgModule({
  declarations: [AuthorizationProgressComponent, AuthorizationProgressIconComponent, AuthorizationProgressBarComponent],
  imports: [CommonModule, IconModule],
  exports: [AuthorizationProgressComponent],
})
export class AuthorizationProgressModule {}
