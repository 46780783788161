<article class="notificationsPanel__content">
  <ng-container *ngIf="ppExpanded">
    <header class="notificationsPanel__header">
      <div class="notificationsPanel__title-container">
        <h4>Notifications</h4>

        <pp-notification-top-toolbar
          class="notificationToolbar"
          [ppAllNotificationsRead]="allNotificationsRead"
          (ppMarkAllAsRead)="markAllAsRead()"
          (ppFilter)="filterNotifications()"
        ></pp-notification-top-toolbar>
      </div>

      <pp-notification-mode-select
        (ppFirstChoice)="switchMode('all')"
        (ppSecondChoice)="switchMode('unread')"
        (ppThirdChoice)="switchMode('mentioned')"
        (ppFourthChoice)="switchMode('assigned')"
        [ppSelected]="
          notificationsMode.mode === 'all'
            ? 0
            : notificationsMode.mode === 'unread'
            ? 1
            : notificationsMode.mode === 'mentioned'
            ? 2
            : 3
        "
      ></pp-notification-mode-select>
    </header>

    <section class="notificationsPanel__body" (scroll)="onScroll($event)" #notifications>
      <div id="notificationsScroll" (ppOnRendered)="onScrollRendered()">
        <p
          class="notificationsPanel__no-notifications"
          *ngIf="
            todayNotifications.length === 0 &&
            yesterdayNotifications.length === 0 &&
            olderNotifications.length === 0 &&
            !loading
          "
        >
          No notifications to display.
        </p>

        <h6 class="notificationsPanel__day" *ngIf="todayNotifications.length > 0">TODAY</h6>

        <ng-container *ngFor="let notification of todayNotifications">
          <pp-notification
            *ngIf="workspaces && usersFetched"
            [ppNotificationId]="notification.id"
            [ppDataFetched]="dataFetched"
            [ppAccountName]="workspaces[notification.workspaceId]?.accountName"
            [ppDateFormat]="format"
          ></pp-notification>
        </ng-container>

        <div *ngIf="yesterdayNotifications.length > 0">
          <h6 class="notificationsPanel__day">YESTERDAY</h6>

          <ng-container *ngFor="let notification of yesterdayNotifications">
            <pp-notification
              *ngIf="workspaces && usersFetched"
              [ppNotificationId]="notification.id"
              [ppDataFetched]="dataFetched"
              [ppAccountName]="workspaces[notification.workspaceId]?.accountName"
              [ppDateFormat]="format"
            ></pp-notification>
          </ng-container>
        </div>

        <div *ngIf="olderNotifications.length > 0">
          <h6 class="notificationsPanel__day">OLDER</h6>

          <ng-container *ngFor="let notification of olderNotifications">
            <pp-notification
              *ngIf="workspaces && usersFetched"
              [ppNotificationId]="notification.id"
              [ppDataFetched]="dataFetched"
              [ppAccountName]="workspaces[notification.workspaceId]?.accountName"
              [ppDateFormat]="format"
            ></pp-notification>
          </ng-container>
        </div>

        <div class="notifications__spinner-wrapper">
          <app-spinner class="notifications__spinner" *ngIf="loading"></app-spinner>

          <pp-button
            *ngIf="
              !loading &&
              !(
                todayNotifications.length === 0 &&
                yesterdayNotifications.length === 0 &&
                olderNotifications.length === 0
              ) &&
              !finishedFetching
            "
            ppStyle="secondary"
            (ppAction)="fetchMoreNotifications()"
          >
            {{ 'load_more' | ppTranslate }}
          </pp-button>
        </div>
      </div>
    </section>
  </ng-container>
</article>
