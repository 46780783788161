import * as ExcelJS from 'exceljs';
import { GET_PREFERENCES } from '../../preferences/preferences.store';
import { ETimelineTimeframe } from '../../site/site-timeline/site-timeline-utils/timeframes-enums';
import { basicColumns } from '../timeline-export.const';

export function styleTimelineHeaders(worksheet: ExcelJS.Worksheet): void {
  const headerRow = worksheet.getRow(1);
  const cellCount = headerRow.cellCount;
  const timeframe = GET_PREFERENCES().timeline?.timeframe;

  setHeaderRowHeight(timeframe, headerRow);

  for (let cellIndex = 0; cellIndex < cellCount; cellIndex++) {
    const cell = headerRow.getCell(cellIndex + 1);

    cell.alignment = {
      vertical: 'middle',
      wrapText: true,
      horizontal: 'center',
    };

    if (!basicColumns.includes(cell.text)) {
      styleTimelineCustomColumn(cell, worksheet, cellIndex, timeframe);
    }
  }
}

function styleTimelineCustomColumn(
  cell: ExcelJS.Cell,
  worksheet: ExcelJS.Worksheet,
  cellIndex: number,
  timeframe: ETimelineTimeframe,
) {
  const column = worksheet.getColumn(cellIndex + 1);

  tryStyleWeekendDays(timeframe, column, cell);

  cell.alignment = {
    textRotation: 90,
  };
}

function tryStyleWeekendDays(
  timeframe: ETimelineTimeframe,
  column: ExcelJS.Column,
  cell: ExcelJS.Cell,
) {
  if (timeframe === ETimelineTimeframe.DAY) {
    const weekDay = new Date(column.key).getDay();

    const isWeekend = weekDay === 0 || weekDay === 6;

    if (isWeekend) {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'E9F0F8',
        },
      };
    }
  }
}

function setHeaderRowHeight(timeframe: ETimelineTimeframe, headerRow: ExcelJS.Row): void {
  if (timeframe === ETimelineTimeframe.DAY) {
    headerRow.height = 70;
  } else if (timeframe === ETimelineTimeframe.WEEK) {
    headerRow.height = 90;
  } else {
    headerRow.height = 100;
  }
}
