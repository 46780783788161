<div *ngIf="addedImagesNumber" class="dashletActivity">
  <img class="dashletActivity__icon" [src]="EIconPath.BASIC_FIELD_IMAGE" />

  <div class="dashletActivity__text">
    {{ 'added' | ppTranslate }}
    <span class="timeline__strong">{{ addedImagesNumber }}</span>
    {{ addedImagesNumber > 1 ? ('images' | ppTranslate) : ('image' | ppTranslate) }}.
  </div>
</div>

<div *ngIf="deletedImagesNumber" class="dashletActivity">
  <img class="dashletActivity__icon" [src]="EIconPath.BASIC_FIELD_IMAGE" />

  <div class="dashletActivity__text">
    {{ 'removed' | ppTranslate }}
    <span class="timeline__strong">{{ deletedImagesNumber }}</span>
    {{ deletedImagesNumber > 1 ? ('images' | ppTranslate) : ('image' | ppTranslate) }}.
  </div>
</div>
