import { CookieService } from '../../services/cookie.service';
import { DeviceService } from '../../../core/services/device.service';
import { Injectable } from '@angular/core';
import { SUPPORTED_BROWSERS_COOKIE_NAME } from './supported-browser.constants';

@Injectable({
  providedIn: 'root',
})
export class SupportedBrowsersService {
  constructor(private deviceService: DeviceService, private cookieService: CookieService) {}

  readCookie() {
    return this.cookieService.readCookie(SUPPORTED_BROWSERS_COOKIE_NAME);
  }

  setCookie() {
    this.cookieService.setCookie(SUPPORTED_BROWSERS_COOKIE_NAME, 'true', 7);
  }

  isBrowserUnsupported() {
    if (
      this.deviceService.isBrowserSafari() ||
      this.deviceService.isBrowserFirefox() ||
      this.deviceService.isBrowserChrome() ||
      this.deviceService.isBrowserOpera() ||
      this.deviceService.isBrowserEdge()
    ) {
      return false;
    }

    return true;
  }
}
