import { Injectable } from '@angular/core';
import { DeviceService } from 'src/app/core/services/device.service';
import { createDraggingStyle } from './create-dragging-style';
import { createDrawingStyle } from './create-drawing-style';
import { createNormalStyle } from './create-normal-style';
import Feature from 'ol/Feature';
import { EPriority } from 'src/app/project/modules/points/priorities';
import Style from 'ol/style/Style';

@Injectable({
  providedIn: 'root',
})
export class PlanPointStylesService {
  constructor(private deviceService: DeviceService) {}

  createDrawingStyle(priority: EPriority, feature: Feature): Style[] {
    return createDrawingStyle(priority, feature);
  }

  createDraggingStyle(priority: EPriority): Style[] {
    const isTouchDevice = this.deviceService.isTouchDevice();

    return createDraggingStyle(priority, isTouchDevice);
  }

  createNormalStyle(priority: EPriority): Style[] {
    const isTouchDevice = this.deviceService.isTouchDevice();

    return createNormalStyle(priority, isTouchDevice);
  }
}
