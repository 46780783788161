<pp-modal>
  <ng-container heading>
    <div ppTooltip [ppTrimTooltip]="true">
      <strong>{{ siteName }}</strong>
    </div>
  </ng-container>

  <ng-container body>
    <form class="modalNotifications" name="shareForm" novalidate="" (submit)="save()">
      <h5 class="modal__body-title">
        {{ 'email_notification_frequency' | ppTranslate }}
      </h5>
      <div class="modalNotifications__radio" *ngFor="let sub of subscriptionTypes">
        <pp-radio
          [ppChecked]="sub.value === subscriptionType"
          (ppOnValueChange)="toggleSubscriptionType(sub)"
          ppStyle="blue"
        >
          <label class="control-custom control-custom--radio">
            {{ sub.label | ppTranslate }}
          </label>
        </pp-radio>
      </div>
    </form>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'save' | ppTranslate"
      (ppCancel)="hideModal()"
      (ppConfirm)="save()"
      [ppProcess]="processing"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
