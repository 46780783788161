<pp-modal>
  <ng-container heading>
    {{ heading | ppTranslate }}
  </ng-container>

  <ng-container body>
    <p *ngIf="!archiveLimitError" class="modal__bold modal__extra-padding-top">
      {{ 'unarchive_site_confirm' | ppTranslate }}
    </p>
    <p *ngIf="archiveLimitError" class="modal__bold modal__extra-padding-top">
      {{ 'unarchive_site_confirm_limit_exceeded' | ppTranslate }}
    </p>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'unarchive' | ppTranslate"
      (ppCancel)="hideModal(true)"
      (ppConfirm)="unarchiveSite()"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
