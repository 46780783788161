import imageCompression from 'browser-image-compression';

import { Inject, Injectable } from '@angular/core';
import { TFileToUpload } from '../point-modal/point-attachments/point-attachments.service';
import { DOCUMENT } from '@angular/common';
import { EFileType } from 'src/app/project/shared/enums/file-type.enum';

@Injectable({
  providedIn: 'root',
})
export class AttachmentImageCompressionService {
  rotationData = { degree: 0, boundaryRad: 0 };

  constructor(@Inject(DOCUMENT) private document: Document) {}

  async fixImageRotation(image: TFileToUpload): Promise<File> {
    return this.fixRotation(image, true);
  }

  async fixImageRotationJPG(image: TFileToUpload): Promise<File> {
    return this.fixRotation(image);
  }

  async compressImage(image: File): Promise<File> {
    return new Promise(async (resolve, reject) => {
      const options = {
        maxSizeMB: 1,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(image, options);

        resolve(compressedFile);
      } catch (error) {
        reject(error);
      }
    });
  }

  calcProjectedRectSizeOfRotatedRect(
    size: {
      width: number;
      height: number;
    },
    rad: number,
  ): { width: number; height: number } {
    const { width, height } = size;

    const rectProjectedWidth = Math.abs(width * Math.cos(rad)) + Math.abs(height * Math.sin(rad));
    const rectProjectedHeight = Math.abs(width * Math.sin(rad)) + Math.abs(height * Math.cos(rad));

    return { width: rectProjectedWidth, height: rectProjectedHeight };
  }

  async compressImageBMP(image: TFileToUpload): Promise<unknown> {
    const reader = new FileReader();

    reader.readAsDataURL(image.file);

    return new Promise((resolve) => {
      reader.onload = (event: Event): void => {
        const img = new Image();
        img.src = event.target['result'] as string;

        img.onload = (): void => {
          const elem = this.document.createElement('canvas');

          if (img.width > 1920) {
            elem.width = 1920;
            elem.height = (img.height * 1920) / img.width;
          } else if (img.height > 1080) {
            elem.height = 1080;
            elem.width = (img.width * 1080) / img.height;
          } else if (img.width > 1280) {
            elem.width = 1280;
            elem.height = (img.height * 1280) / img.width;
          } else if (img.height > 720) {
            elem.height = 720;
            elem.width = (img.width * 720) / img.height;
          } else {
            elem.width = img.width;
            elem.height = img.height;
          }

          const ctx = elem.getContext('2d');

          ctx.drawImage(img, 0, 0, elem.width, elem.height);
          ctx.canvas.toBlob(resolve, EFileType.JPEG, 0.7);
        };
      };
    });
  }

  private fixRotation(image: TFileToUpload, keepAngle: boolean = false): Promise<File> {
    return new Promise((resolve: (value: File) => void) => {
      const reader = new FileReader();

      reader.onload = (event: Event): void => {
        imageCompression.getExifOrientation(image.file).then(async (orientation) => {
          const img = new Image();

          img.src = event.target['result'] as string;

          img.onload = (): void => {
            const boundaryRad = Math.atan(img.width / img.height);
            const canvas = this.document.createElement('canvas');

            this.rotationData.boundaryRad = boundaryRad;

            let degree = this.rotationData.degree;

            if (!keepAngle) {
              if (orientation === 6) {
                degree = 270;
              }

              if (orientation === 3) {
                degree = 180;
              }

              if (orientation === 8) {
                degree = 90;
              }
            }

            const rad = (degree * Math.PI) / 180 || 0;

            const { width, height } = this.calcProjectedRectSizeOfRotatedRect(
              {
                width: img.width,
                height: img.height,
              },
              rad,
            );

            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');

            ctx.save();

            const sin_Height = img.height * Math.abs(Math.sin(rad));
            const cos_Height = img.height * Math.abs(Math.cos(rad));
            const cos_Width = img.width * Math.abs(Math.cos(rad));
            const sin_Width = img.width * Math.abs(Math.sin(rad));

            const { xOrigin, yOrigin } = this.getOrigin(
              rad,
              sin_Height,
              cos_Width,
              width,
              cos_Height,
              sin_Width,
              height,
            );

            ctx.translate(xOrigin, yOrigin);
            ctx.rotate(rad);
            ctx.drawImage(img, 0, 0);
            ctx.restore();
            ctx.canvas.toBlob(resolve, EFileType.JPEG, 1);
          };
        });
      };

      reader.readAsDataURL(image.file);
    });
  }

  private getOrigin(
    rad: number,
    sin_Height: number,
    cos_Width: number,
    width: number,
    cos_Height: number,
    sin_Width: number,
    height: number,
  ): { xOrigin: number; yOrigin: number } {
    let xOrigin, yOrigin;

    if (rad < this.rotationData.boundaryRad) {
      xOrigin = Math.min(sin_Height, cos_Width);
      yOrigin = 0;
    } else if (rad < Math.PI / 2) {
      xOrigin = Math.max(sin_Height, cos_Width);
      yOrigin = 0;
    } else if (rad < Math.PI / 2 + this.rotationData.boundaryRad) {
      xOrigin = width;
      yOrigin = Math.min(cos_Height, sin_Width);
    } else if (rad < Math.PI) {
      xOrigin = width;
      yOrigin = Math.max(cos_Height, sin_Width);
    } else if (rad < Math.PI + this.rotationData.boundaryRad) {
      xOrigin = Math.max(sin_Height, cos_Width);
      yOrigin = height;
    } else if (rad < (Math.PI / 2) * 3) {
      xOrigin = Math.min(sin_Height, cos_Width);
      yOrigin = height;
    } else if (rad < (Math.PI / 2) * 3 + this.rotationData.boundaryRad) {
      xOrigin = 0;
      yOrigin = Math.max(cos_Height, sin_Width);
    } else if (rad < Math.PI * 2) {
      xOrigin = 0;
      yOrigin = Math.min(cos_Height, sin_Width);
    }
    return { xOrigin, yOrigin };
  }
}
