<div class="reminderList__wrapper">
  <div class="reminderList__header" (click)="toggleGroup()">
    <div class="reminderList__rectangle">
      <img
        class="reminderList__rectangle-icon"
        [src]="ppGroupExpanded ? EIconPath.REMINDER_COLLAPSE : EIconPath.REMINDER_EXPAND"
      />
    </div>

    <h6 class="reminderList__header-text">
      {{ ppNameKey | ppTranslate }} ({{ ppReminders.totalValues }})
    </h6>
  </div>

  <div *ngIf="ppGroupExpanded" class="reminderList__elements">
    <p *ngIf="ppReminders.values.length === 0" class="reminderList__no-reminders-text">
      {{ 'no_reminders_to_display' | ppTranslate }}
    </p>

    <button
      (click)="loadMore()"
      class="reminderList__load-more"
      *ngIf="!(ppReminders.numberOfDisplayedValues === ppReminders.totalValues)"
    >
      {{ 'load_more_reminders' | ppTranslate }}
    </button>

    <div *ngFor="let reminderGroup of ppReminders.values">
      <div class="reminderList__date-row">
        {{ reminderGroup.formattedDate }}
      </div>

      <ng-container *ngFor="let reminderId of reminderGroup.values">
        <pp-reminder-element
          *ngIf="ppGroupedReminders[reminderId]"
          [ppId]="reminderId"
          [ppWorkspaceId]="ppGroupedReminders[reminderId].workspaceId"
          [ppDate]="ppGroupedReminders[reminderId].timestampEpochMillis"
          [ppPointId]="ppGroupedReminders[reminderId].refObject?.id"
          [ppPoint]="ppGroupedReminders[reminderId].point"
          [ppFetched]="ppFetched"
          [ppOffline]="ppOffline"
          [ppDescription]="ppGroupedReminders[reminderId].note"
        ></pp-reminder-element>
      </ng-container>
    </div>
  </div>
</div>
