import { cloneDeep } from 'lodash';
import { TWhiteLabel, TWhiteLabelStore } from '../white-label.model';
import { EWhiteLabelFont } from '../white-label-fonts.enum';
import {
  DEFAULT_FONT_FAMILY,
  DEFAULT_PANEL_ACCENT,
  DEFAULT_PANEL_BG,
  DEFAULT_PANEL_FONT,
  DEFAULT_PANEL_FONT_HIGHLIGHT,
  DEFAULT_PANEL_TEXT,
  DEFAULT_PANEL_TEXT_HOVER,
  DEFAULT_PRIMARY_100,
  DEFAULT_PRIMARY_200,
  DEFAULT_PRIMARY_300,
  DEFAULT_PRIMARY_400,
  DEFAULT_PRIMARY_50,
  DEFAULT_PRIMARY_500,
  DEFAULT_PRIMARY_600,
  DEFAULT_PRIMARY_700,
  DEFAULT_PRIMARY_75,
  DEFAULT_PRIMARY_800,
  DEFAULT_PRIMARY_900,
} from './white-label-defaults.const';

export function setWhiteLabel({
  data,
  accountId,
  oldWhiteLabel,
  isDefaultLabel,
}: {
  data: TWhiteLabel;
  accountId: string;
  oldWhiteLabel: TWhiteLabelStore;
  isDefaultLabel: boolean;
}): TWhiteLabelStore {
  const whiteLabelData: TWhiteLabel = cloneDeep(data);
  const whiteLabel = cloneDeep(oldWhiteLabel);

  if (Object.keys(whiteLabelData).length !== 0) {
    whiteLabel.created = !isDefaultLabel;

    if (!whiteLabelData.fontFamily) {
      whiteLabelData.fontFamily = DEFAULT_FONT_FAMILY;
    }

    // Fix to an old issue where the font family was not being set correctly
    if (whiteLabelData.fontFamily === ("'Source Sans Pro', sans-serif" as EWhiteLabelFont)) {
      whiteLabelData.fontFamily = EWhiteLabelFont.SOURCE_SANS_PRO;
    }

    if (!whiteLabelData.panelAccent) {
      whiteLabelData.panelAccent = DEFAULT_PANEL_ACCENT;
    }

    if (!whiteLabelData.panelBg) {
      whiteLabelData.panelBg = DEFAULT_PANEL_BG;
    }

    if (!whiteLabelData.panelFont) {
      whiteLabelData.panelFont = DEFAULT_PANEL_FONT;
    }

    if (!whiteLabelData.panelFontHighlight) {
      whiteLabelData.panelFontHighlight = DEFAULT_PANEL_FONT_HIGHLIGHT;
    }

    if (!whiteLabelData.panelText) {
      whiteLabelData.panelText = DEFAULT_PANEL_TEXT;
    }

    if (!whiteLabelData.panelTextHover) {
      whiteLabelData.panelTextHover = DEFAULT_PANEL_TEXT_HOVER;
    }

    if (!whiteLabelData.primary50) {
      whiteLabelData.primary50 = DEFAULT_PRIMARY_50;
    }

    if (!whiteLabelData.primary75) {
      whiteLabelData.primary75 = DEFAULT_PRIMARY_75;
    }

    if (!whiteLabelData.primary100) {
      whiteLabelData.primary100 = DEFAULT_PRIMARY_100;
    }

    if (!whiteLabelData.primary200) {
      whiteLabelData.primary200 = DEFAULT_PRIMARY_200;
    }

    if (!whiteLabelData.primary300) {
      whiteLabelData.primary300 = DEFAULT_PRIMARY_300;
    }

    if (!whiteLabelData.primary400) {
      whiteLabelData.primary400 = DEFAULT_PRIMARY_400;
    }

    if (!whiteLabelData.primary500) {
      whiteLabelData.primary500 = DEFAULT_PRIMARY_500;
    }

    if (!whiteLabelData.primary600) {
      whiteLabelData.primary600 = DEFAULT_PRIMARY_600;
    }

    if (!whiteLabelData.primary700) {
      whiteLabelData.primary700 = DEFAULT_PRIMARY_700;
    }

    if (!whiteLabelData.primary800) {
      whiteLabelData.primary800 = DEFAULT_PRIMARY_800;
    }

    if (!whiteLabelData.primary900) {
      whiteLabelData.primary900 = DEFAULT_PRIMARY_900;
    }
  }

  if (accountId !== undefined) {
    whiteLabel.accountId = accountId;
    whiteLabel.data = whiteLabelData;
  }

  return whiteLabel;
}
