import { TCombinedCustomField } from '../../../custom-fields/combine-custom-fields/combined-custom-field.model';
import { TFilters } from '../../../filters/site-filter.model';
import { TWorkspacesById } from '../../../workspace/workspace.model';
import { addNewFieldToFilter } from './add-new-field-to-filter';
import { checkIfFieldExists } from './check-if-field-exists';
import { updateExistingCustomFieldFilter } from './update-existing-custom-field-filter';

export function addCustomFieldsToFilters(
  combinedCustomFields: TCombinedCustomField[],
  filters: TFilters,
  workspaces: TWorkspacesById,
): void {
  combinedCustomFields.forEach((combinedCustomField) => {
    const existingField = checkIfFieldExists(filters, combinedCustomField);

    if (existingField) {
      combinedCustomField.combinedIds.forEach((customFieldIds) => {
        updateExistingCustomFieldFilter(customFieldIds, existingField, workspaces);
      });
    } else {
      addNewFieldToFilter(filters, combinedCustomField, workspaces);
    }
  });
}
