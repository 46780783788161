import { cloneDeep } from 'lodash';

import { Injectable, OnDestroy } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SetSiteFilter } from 'src/app/project/modules/filters/site-filter.actions';

import { TAllFilters, TFilters } from 'src/app/project/modules/filters/site-filter.model';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { SortingService } from '@core/helpers';
import { GET_ACCOUNTS } from 'src/app/project/modules/account/account.store';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { GET_CUSTOM_FIELDS } from 'src/app/project/modules/custom-fields/custom-fields.store';
import { EWorkspaces } from 'src/app/project/modules/workspace/workspaces.enum';
import { generateFilterTooltip } from 'src/app/project/shared/tooltip-sites-filter';
import { EStore } from '../../../shared/enums/store.enum';
import { AccountService } from '../../account/account-service/account.service';
import { TAccount } from '../../account/account.model';
import { CustomFieldsService } from '../../custom-fields/custom-fields.service';
import { WorkspaceService } from '../../workspace/workspace.service';

@Injectable({
  providedIn: 'root',
})
export class SiteTimelineService implements OnDestroy {
  private filters: TFilters;

  private readonly destroy$ = new Subject<void>();

  private siteFilters$ = new Observable<TAllFilters>();
  private workspaces$ = new Observable<TWorkspacesById>();
  private allFilters: TAllFilters;

  constructor(
    private store: Store<{
      siteFilter: TAllFilters;
      workspaces: TWorkspacesById;
    }>,
    private workspaceService: WorkspaceService,
    private accountService: AccountService,
    private customFieldsService: CustomFieldsService,
    private sortingService: SortingService,
  ) {
    this.siteFilters$ = this.store.pipe(select(EStore.SITE_FILTER));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.siteFilters$.pipe(takeUntil(this.destroy$)).subscribe((allFilters) => {
      this.allFilters = cloneDeep(allFilters);
      this.filters = this.allFilters?.[EWorkspaces.TIMELINE];
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.filters = this.allFilters?.[EWorkspaces.TIMELINE];
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  updateTimelineFilter(): void {
    let filters = cloneDeep(this.filters);

    filters = this.addTimelineFilters(filters);
    this.updateCustomFieldFilters(filters);
  }

  updateCustomFieldFilters(filters: TFilters): void {
    const workspaces = this.workspaceService.getWorkspaces();
    const customFields = this.customFieldsService.getCustomFields();
    let listCustomFieldLabelsCount = {};

    filters.customFields = filters.customFields.filter((_customField) => {
      if (!customFields[_customField.workspaceId]?.[_customField.id]) {
        return false;
      } else {
        return true;
      }
    });

    Object.keys(workspaces).forEach((workspaceId) => {
      let addToFilters = false;

      for (let i = 0; i < workspaces[workspaceId].customFields.length; i++) {
        if (
          customFields[workspaceId][workspaces[workspaceId].customFields[i]].type ===
          ECustomFieldType.TIMELINE
        ) {
          addToFilters = true;
        }
      }

      if (addToFilters) {
        workspaces[workspaceId].customFields.forEach((fieldId) => {
          if (customFields[workspaceId][fieldId].type === ECustomFieldType.DATE) {
            this.updateDateCustomFieldFilter(filters, workspaceId, fieldId);
          } else if (customFields[workspaceId][fieldId].type === ECustomFieldType.COST) {
            this.updateCostCustomFieldFilter(filters, workspaceId, fieldId);
          } else if (customFields[workspaceId][fieldId].type === ECustomFieldType.LIST) {
            listCustomFieldLabelsCount = this.updateListCustomFieldFilter(
              filters,
              workspaceId,
              fieldId,
            );
          } else if (customFields[workspaceId][fieldId].type === ECustomFieldType.NUMBERS) {
            this.updateNumbersCustomFieldFilter(filters, workspaceId, fieldId);
          } else if (customFields[workspaceId][fieldId].type === ECustomFieldType.TIME) {
            this.updateTimeCustomFieldFilter(filters, workspaceId, fieldId);
          } else if (customFields[workspaceId][fieldId].type === ECustomFieldType.TIMELINE) {
            this.updateTimelineCustomFieldFilter(filters, workspaceId, fieldId);
          } else if (customFields[workspaceId][fieldId].type === ECustomFieldType.PERCENTAGE) {
            this.updatePercentageCustomFieldFilter(filters, workspaceId, fieldId);
          } else if (customFields[workspaceId][fieldId].type === ECustomFieldType.FORMULA) {
            // this.updateFormulaCustomFieldFilter(filters, workspaceId, fieldId);
            // TODO Formula
          } else if (customFields[workspaceId][fieldId].type === ECustomFieldType.CHECKBOX) {
            this.updateCheckboxCustomFieldFilter(filters, workspaceId, fieldId);
          }
        });
      }
    });

    Object.keys(listCustomFieldLabelsCount)
      .filter((label) => listCustomFieldLabelsCount[label] > 1)
      .forEach(() => {
        filters.customFields.forEach((filterCustomField) => {
          filterCustomField.siteName = workspaces[filterCustomField.workspaceId].siteName;
          filterCustomField.accountName = workspaces[filterCustomField.workspaceId].accountName;
        });

        filters.customFields.forEach((customFieldFilter) => {
          customFieldFilter.tooltip = generateFilterTooltip(customFieldFilter);
        });
      });

    this.allFilters[EWorkspaces.TIMELINE] = filters;

    this.store.dispatch(
      new SetSiteFilter({
        workspaceId: EWorkspaces.TIMELINE,
        filter: filters,
      }),
    );
  }

  addTimelineFilters(filters: TFilters): TFilters {
    const accounts = this.accountService.getAccounts();

    accounts.forEach((account) => {
      let hasTimeline = false; // so we don't show empty accounts in filters

      account.workspaces.forEach((workspaceId) => {
        const customFields = GET_CUSTOM_FIELDS();
        const workspace = this.workspaceService.findWorkspace(workspaceId);

        workspace.customFields.forEach((_customFieldId) => {
          if (customFields[_customFieldId].type === ECustomFieldType.TIMELINE) {
            hasTimeline = true;
          }
        });
      });

      if (account.accountFeatures?.timeline && hasTimeline) {
        this.checkForNewAccounts(account, filters);

        const index = filters.sites.findIndex((_site) => _site.accountName === account.accountName);

        this.checkForNewWorkspaces(account, filters, index);
        this.checkRemovedWorkspaces(account, filters, index);
      }
    });

    this.checkRemovedAccounts(filters, accounts);

    filters.sitesDefault = this.isSitesDefault(filters);

    return filters;
  }

  checkForNewAccounts(account: TAccount, filters: TFilters): void {
    const existingAccount = filters.sites.find(
      (savedAccount) => account.accountName === savedAccount.accountName,
    );

    if (!existingAccount) {
      filters.sites.push({
        accountName: account.accountName,
        value: true,
        appliedValue: true,
        default: true,
        workspaces: [],
      });
    }

    filters.sites = filters.sites.sort((a, b) =>
      this.sortingService.naturalSort(a.accountName, b.accountName),
    );
  }

  checkForNewWorkspaces(account: TAccount, filters: TFilters, index: number): void {
    account.workspaces.forEach((workspaceId) => {
      const workspace = this.workspaceService.findWorkspace(workspaceId);
      const customFields = GET_CUSTOM_FIELDS();

      let hasTimeline = false;

      workspace.customFields.forEach((_customFieldId) => {
        if (customFields[_customFieldId].type === ECustomFieldType.TIMELINE) {
          hasTimeline = true;
        }
      });

      if (hasTimeline) {
        let existingWorkspace = null;

        existingWorkspace = filters.sites[index].workspaces.find(
          (savedWorkspace) => savedWorkspace.id === workspace.workspaceId,
        );

        if (!existingWorkspace) {
          filters.sites[index].workspaces.push({
            name: workspace.siteName,
            id: workspace.workspaceId,
            value: true,
            appliedValue: true,
            default: true,
          });
        }
      }
    });
  }

  checkRemovedAccounts(filters: TFilters, accounts: TAccount[]): void {
    filters.sites = filters.sites.filter((_account) => {
      let accountFound = false;

      for (let i = 0; i < Object.keys(accounts).length; i++) {
        if (accounts[Object.keys(accounts)[i]].accountName === _account.accountName) {
          accountFound = true;

          break;
        }
      }

      return accountFound;
    });
  }

  checkRemovedWorkspaces(account: TAccount, filters: TFilters, index: number): void {
    filters.sites[index].workspaces = filters.sites[index].workspaces.filter((_workspace) =>
      account.workspaces.includes(_workspace.id),
    );
  }

  isSitesDefault(filters: TFilters): boolean {
    const appliedAccounts = filters.sites.filter((account) => account.value);

    if (appliedAccounts.length !== filters.sites.length) {
      return false;
    } else {
      for (let i = 0; i < filters.sites.length; i++) {
        const appliedSites = filters.sites[i].workspaces.filter((site) => site.value);

        if (appliedSites.length !== filters.sites[i].workspaces.length) {
          return false;
        }
      }
    }

    return true;
  }

  updateDateCustomFieldFilter(filters: TFilters, workspaceId: string, fieldId: string): void {
    const workspaces = this.workspaceService.getWorkspaces();
    const customFields = this.customFieldsService.getCustomFields();
    const accounts = GET_ACCOUNTS();
    const workspace = workspaces[workspaceId];
    const account = accounts.find((_account) => _account.accountId === workspace.accountId);

    let addCustomFieldToList = true;

    if (!account.accountFeatures.timeline) {
      addCustomFieldToList = false;
    }

    for (let i = 0; i < filters.customFields.length; i++) {
      const customField = customFields[workspaceId][fieldId];
      const filterCustomField =
        customFields[filters.customFields[i].workspaceId][filters.customFields[i].id];

      if (
        filterCustomField.type === ECustomFieldType.DATE &&
        filterCustomField.label === customField.label
      ) {
        addCustomFieldToList = false;

        this.checkFieldAlreadyAdded(filters, i, fieldId, workspaces, workspaceId);

        break;
      }
    }

    if (addCustomFieldToList) {
      filters.customFields.push({
        id: fieldId,
        workspaceId,
        appliedValues: {
          startDate: null,
          endDate: null,
        },
        startDate: null,
        endDate: null,
        sites: [
          {
            siteName: workspaces[workspaceId].siteName,
            accountName: workspaces[workspaceId].accountName,
            workspaceId,
            filterId: fieldId,
          },
        ],
      });
    }
  }

  private checkFieldAlreadyAdded(
    filters: TFilters,
    i: number,
    fieldId: string,
    workspaces: TWorkspacesById,
    workspaceId: string,
  ): void {
    const existingCf = filters.customFields.find((cf) => cf.id === filters.customFields[i].id);

    if (existingCf && existingCf.sites) {
      const fieldAlreadyAdded = existingCf.sites.find((site) => site.filterId === fieldId);

      if (!fieldAlreadyAdded) {
        existingCf.sites.push({
          siteName: workspaces[workspaceId].siteName,
          accountName: workspaces[workspaceId].accountName,
          workspaceId,
          filterId: fieldId,
        });
      }
    }
  }

  updateCostCustomFieldFilter(filters: TFilters, workspaceId: string, fieldId: string): void {
    const workspaces = this.workspaceService.getWorkspaces();
    const customFields = this.customFieldsService.getCustomFields();

    let addCustomFieldToList = true;

    for (let i = 0; i < filters.customFields.length; i++) {
      const customField = customFields[workspaceId][fieldId];
      const filterCustomField =
        customFields[filters.customFields[i].workspaceId][filters.customFields[i].id];

      if (
        filterCustomField.type === ECustomFieldType.COST &&
        filterCustomField.currencyCode === customField.currencyCode &&
        filterCustomField.label === customField.label
      ) {
        addCustomFieldToList = false;

        this.checkFieldAlreadyAdded(filters, i, fieldId, workspaces, workspaceId);

        break;
      }
    }

    if (addCustomFieldToList) {
      filters.customFields.push({
        id: fieldId,
        workspaceId,
        appliedValues: {
          min: null,
          max: null,
        },
        min: null,
        max: null,
        sites: [
          {
            siteName: workspaces[workspaceId].siteName,
            accountName: workspaces[workspaceId].accountName,
            workspaceId,
            filterId: fieldId,
          },
        ],
      });
    }
  }

  updateListCustomFieldFilter(
    filters: TFilters,
    workspaceId: string,
    fieldId: string,
  ): { [key: string]: number } {
    const workspaces = this.workspaceService.getWorkspaces();
    const customFields = this.customFieldsService.getCustomFields();
    const listCustomFieldLabelsCount: {
      [key: string]: number;
    } = {};
    let addCustomFieldToList = true;

    const label = customFields[workspaceId][fieldId].label;

    for (let i = 0; i < filters.customFields.length; i++) {
      const customField = JSON.stringify(customFields[workspaceId][fieldId]);
      const filterCustomField = JSON.stringify(
        customFields[filters.customFields[i].workspaceId][filters.customFields[i].id],
      );

      const splitCustomField = customField.split(',');
      const splitFilterCustomField = filterCustomField.split(',');

      splitCustomField.forEach((parameter, index) => {
        if (parameter.indexOf('"id":') > -1) {
          splitCustomField[index] = '{';
        }
      });

      splitFilterCustomField.forEach((parameter, index) => {
        if (parameter.indexOf('"id":') > -1) {
          splitFilterCustomField[index] = '{';
        }
      });

      if (splitCustomField.join('').localeCompare(splitFilterCustomField.join('')) === 0) {
        addCustomFieldToList = false;

        this.checkFieldAlreadyAdded(filters, i, fieldId, workspaces, workspaceId);

        break;
      }
    }

    if (addCustomFieldToList) {
      filters.customFields.push({
        id: fieldId,
        workspaceId,
        appliedValues: {
          idOfChosenElement: null,
          path: null,
          text: null,
        },
        idOfChosenElement: null,
        path: null,
        text: null,
        sites: [
          {
            siteName: workspaces[workspaceId].siteName,
            accountName: workspaces[workspaceId].accountName,
            workspaceId,
            filterId: fieldId,
          },
        ],
      });

      if (listCustomFieldLabelsCount[label]) {
        listCustomFieldLabelsCount[label] = listCustomFieldLabelsCount[label] + 1;
      } else {
        listCustomFieldLabelsCount[label] = 1;
      }
    }

    return listCustomFieldLabelsCount;
  }

  updateNumbersCustomFieldFilter(filters: TFilters, workspaceId: string, fieldId: string): void {
    const workspaces = this.workspaceService.getWorkspaces();
    const customFields = this.customFieldsService.getCustomFields();

    let addCustomFieldToList = true;

    for (let i = 0; i < filters.customFields.length; i++) {
      const customField = customFields[workspaceId][fieldId];
      const filterCustomField =
        customFields[filters.customFields[i].workspaceId][filters.customFields[i].id];

      if (
        filterCustomField.type === ECustomFieldType.NUMBERS &&
        filterCustomField.unit === customField.unit &&
        filterCustomField.label === customField.label
      ) {
        addCustomFieldToList = false;

        this.checkFieldAlreadyAdded(filters, i, fieldId, workspaces, workspaceId);

        break;
      }
    }

    if (addCustomFieldToList) {
      filters.customFields.push({
        id: fieldId,
        workspaceId,
        appliedValues: {
          min: null,
          max: null,
        },
        min: null,
        max: null,
        sites: [
          {
            siteName: workspaces[workspaceId].siteName,
            accountName: workspaces[workspaceId].accountName,
            workspaceId,
            filterId: fieldId,
          },
        ],
      });
    }
  }

  updateTimeCustomFieldFilter(filters: TFilters, workspaceId: string, fieldId: string): void {
    const workspaces = this.workspaceService.getWorkspaces();
    const customFields = this.customFieldsService.getCustomFields();

    let addCustomFieldToList = true;

    for (let i = 0; i < filters.customFields.length; i++) {
      const customField = customFields[workspaceId][fieldId];
      const filterCustomField =
        customFields[filters.customFields[i].workspaceId][filters.customFields[i].id];

      if (
        filterCustomField.type === ECustomFieldType.TIME &&
        filterCustomField.label === customField.label
      ) {
        addCustomFieldToList = false;

        this.checkFieldAlreadyAdded(filters, i, fieldId, workspaces, workspaceId);

        break;
      }
    }

    if (addCustomFieldToList) {
      filters.customFields.push({
        id: fieldId,
        workspaceId,
        appliedValues: {
          min: null,
          max: null,
        },
        min: null,
        max: null,
        sites: [
          {
            siteName: workspaces[workspaceId].siteName,
            accountName: workspaces[workspaceId].accountName,
            workspaceId,
            filterId: fieldId,
          },
        ],
      });
    }
  }

  updateTimelineCustomFieldFilter(filters: TFilters, workspaceId: string, fieldId: string): void {
    const workspaces = this.workspaceService.getWorkspaces();
    const customFields = this.customFieldsService.getCustomFields();

    let addCustomFieldToList = true;

    for (let i = 0; i < filters.customFields.length; i++) {
      const customField = customFields[workspaceId][fieldId];
      const filterCustomField =
        customFields[filters.customFields[i].workspaceId][filters.customFields[i].id];

      if (
        filterCustomField.type === ECustomFieldType.TIMELINE &&
        filterCustomField.label === customField.label
      ) {
        addCustomFieldToList = false;

        this.checkFieldAlreadyAdded(filters, i, fieldId, workspaces, workspaceId);

        break;
      }
    }

    if (addCustomFieldToList) {
      filters.customFields.push({
        id: fieldId,
        workspaceId,
        appliedValues: {
          startDate: null,
          endDate: null,
        },
        startDate: null,
        endDate: null,
        sites: [
          {
            siteName: workspaces[workspaceId].siteName,
            accountName: workspaces[workspaceId].accountName,
            workspaceId,
            filterId: fieldId,
          },
        ],
      });
    }
  }

  updatePercentageCustomFieldFilter(filters: TFilters, workspaceId: string, fieldId: string): void {
    const workspaces = this.workspaceService.getWorkspaces();
    const customFields = this.customFieldsService.getCustomFields();

    let addCustomFieldToList = true;

    for (let i = 0; i < filters.customFields.length; i++) {
      const customField = customFields[workspaceId][fieldId];
      const filterCustomField =
        customFields[filters.customFields[i].workspaceId][filters.customFields[i].id];

      if (
        filterCustomField.type === ECustomFieldType.PERCENTAGE &&
        filterCustomField.label === customField.label
      ) {
        addCustomFieldToList = false;

        this.checkFieldAlreadyAdded(filters, i, fieldId, workspaces, workspaceId);

        break;
      }
    }

    if (addCustomFieldToList) {
      filters.customFields.push({
        id: fieldId,
        workspaceId,
        appliedValues: {
          min: null,
          max: null,
        },
        min: null,
        max: null,
        sites: [
          {
            siteName: workspaces[workspaceId].siteName,
            accountName: workspaces[workspaceId].accountName,
            workspaceId,
            filterId: fieldId,
          },
        ],
      });
    }
  }

  updateCheckboxCustomFieldFilter(filters: TFilters, workspaceId: string, fieldId: string): void {
    const workspaces = this.workspaceService.getWorkspaces();
    const customFields = this.customFieldsService.getCustomFields();

    let addCustomFieldToList = true;

    for (let i = 0; i < filters.customFields.length; i++) {
      const customField = customFields[workspaceId][fieldId];
      const filterCustomField =
        customFields[filters.customFields[i].workspaceId][filters.customFields[i].id];

      if (
        filterCustomField.type === ECustomFieldType.CHECKBOX &&
        filterCustomField.label === customField.label
      ) {
        addCustomFieldToList = false;

        this.checkFieldAlreadyAdded(filters, i, fieldId, workspaces, workspaceId);

        break;
      }
    }

    if (addCustomFieldToList) {
      filters.customFields.push({
        id: fieldId,
        workspaceId,
        appliedValues: {
          min: null,
          max: null,
        },
        min: null,
        max: null,
        sites: [
          {
            siteName: workspaces[workspaceId].siteName,
            accountName: workspaces[workspaceId].accountName,
            workspaceId,
            filterId: fieldId,
          },
        ],
      });
    }
  }
}
