import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationModeSelectComponent } from './notification-mode-select.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
  declarations: [NotificationModeSelectComponent],
  imports: [CommonModule, PipesModule],
  exports: [NotificationModeSelectComponent],
})
export class NotificationModeSelectModule {}
