import { TImportShareWorkspaceUsersDTO, TShare } from '../../share.model';
import { TSelectedUsers } from './share-import.models';

export function getImportUserList(
  selectedUsers: TSelectedUsers,
  editedShares: {
    [userId: string]: TShare;
  },
): TImportShareWorkspaceUsersDTO {
  const userList: TImportShareWorkspaceUsersDTO = [];

  Object.keys(selectedUsers).forEach((accountId) => {
    const account = selectedUsers[accountId];

    Object.keys(account).forEach((workspaceId) => {
      const workspace = account[workspaceId];

      Object.keys(workspace).forEach((userId) => {
        const user = workspace[userId];

        const userToAdd = {
          shareOption: user.role,
          userId,
        };

        userList.push(userToAdd);
      });
    });
  });

  userList.forEach((user) => {
    const share: TShare = editedShares[user.userId];

    if (share) {
      user.advancedAccessLevels = share.advancedAccessLevels;
      user.defectTags = share.defectTags;
      user.tagLimited = share.tagLimited;
      user.shareOption = share.shareOption;
    }
  });

  return userList;
}
