<pp-action-bar></pp-action-bar>

<pp-not-authorized-layout [ppHideLogo]="true">
  <section class="authBox">
    <header>
      <section class="authPage__logoWrapper">
        <a class="authPage__backBtn" (click)="goBack()">
          <pp-icon
            [ppSrc]="EIconPath.ICON_LEFT_MENU_ARROW_BACK"
            ppColor="grey-600"
            alt="black arrow pointing back"
          ></pp-icon>
        </a>

        <img [src]="EIconPath.LOGO_2FA" alt="Pinpoint Works logo" title="Pinpoint Works" />
      </section>
    </header>

    <div class="authPage__subheader">
      <h4>{{ '2fa_code_header' | ppTranslate }}</h4>
      <p class="authBox__copy">
        {{ '2fa_code_description_1' | ppTranslate }}
        <span class="codePage__number">{{ confirmationPhoneNumber }}</span>
        {{ '2fa_code_description_2' | ppTranslate }}
      </p>
    </div>

    <form class="authBox__inputs-container" novalidate>
      <div>
        <label class="sr-only" for="signin-email"> {{ 'email' | ppTranslate }} </label>
        <pp-digit-input
          [ppShowingError]="!!errorMessage"
          [ppDigitsNumber]="codeLength"
          [ppInput]="code"
          (ppInputChange)="setCode($event)"
          (ppConfirm)="verifyCode()"
        ></pp-digit-input>
      </div>
    </form>

    <footer class="authBox__footer--gap-s">
      <pp-two-factor-authentication-confirm-code-button
        [ppCode]="code"
        [ppCodeLength]="codeLength"
        (ppConfirm2FA)="verifyCode()"
      ></pp-two-factor-authentication-confirm-code-button>

      <div class="login__row">
        <pp-countdown
          ppTranslationKey="2fa_resend_code_1"
          ppTranslationKeyPrimaryColor="2fa_resend_code_2"
          [ppDurationSeconds]="60"
          (ppCallback)="resend()"
        ></pp-countdown>
      </div>

      <div class="authPage__error" *ngIf="errorMessage">
        <pp-icon
          [ppSrc]="EIconPath.ICON_BADGE_INFO"
          ppColor="inherit"
          ppClass="setSize14"
        ></pp-icon>

        <span>
          {{ errorMessage }}
        </span>
      </div>
    </footer>
  </section>
</pp-not-authorized-layout>
