import { Component, Input } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-site-settings-integrations-tooltips-tooltip',
  templateUrl: './site-settings-integrations-tooltips-tooltip.component.html',
  styleUrls: ['./site-settings-integrations-tooltips-tooltip.component.scss'],
})
export class SiteSettingsIntegrationsTooltipsTooltipComponent {
  @Input() ppTooltip: string;
  @Input() ppAssetName: string;
  @Input() ppText: string;

  EIconPath = EIconPath;
}
