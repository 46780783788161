import { clone } from 'lodash';

type THighlightedText = {
  text: string;
  highlight: boolean;
};

export function checkForKeyword(text: string, keyword: string): THighlightedText[] {
  if (keyword && text) {
    const result = [];
    let processedText = clone(text);

    while (true) {
      const startIndex = processedText
        ? processedText.toLowerCase().indexOf(keyword.toLowerCase())
        : -1;

      if (startIndex !== -1) {
        const endIndex = startIndex + keyword.length;

        result.push({
          text: processedText.substring(0, startIndex),
          highlight: false,
        });

        result.push({
          text: processedText.substring(startIndex, endIndex),
          highlight: true,
        });

        processedText = processedText.substring(endIndex);
      } else {
        result.push({
          text: processedText,
          highlight: false,
        });

        break;
      }
    }

    return result;
  } else {
    const result = [
      {
        text: text,
        highlight: false,
      },
    ];

    return result;
  }
}
