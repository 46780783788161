import { createElement } from '@core/helpers';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { createMarkedKeywordSidePanelElement } from '../utils/side-panel-search';

export function createNameElement(folderId: string, site: TWorkspace): HTMLElement {
  const classList = ['siteNav__site-name-text', 'filterPanel__tooltip'];

  if (folderId) {
    classList.push('siteNav__site-name-text--inFolder');
  }

  const nameElement = createElement('span', {
    attrs: {
      class: classList,
    },
    children: [createMarkedKeywordSidePanelElement(site.name)],
  });

  return nameElement;
}
