import { GET_PREFERENCES } from 'src/app/project/modules/preferences/preferences.store';
import { GET_TIMELINE_COLUMNS } from '../timeframes/timeline-timeframes';
import { checkCurrentWeek } from './check-week';
import { ETimelineTimeframe } from './timeframes-enums';

export function checkCurrentDate(_index: number): boolean {
  const timeframe = GET_PREFERENCES().timeline?.timeframe;
  const timelineData = GET_TIMELINE_COLUMNS();

  const today = new Date();

  if (timeframe === ETimelineTimeframe.WEEK) {
    // Date thinks that week starts on Sunday and pulls previous week because of that
    let midWeek = timelineData.timeframes[_index];

    if (timelineData.timeframes[_index - 1]) {
      midWeek = (timelineData.timeframes[_index] + timelineData.timeframes[_index - 1]) / 2;
    }

    return checkCurrentWeek(midWeek);
  } else {
    if (_index > 0) {
      if (
        timelineData.timeframes[_index - 1] < today.getTime() &&
        timelineData.timeframes[_index] >= today.getTime()
      ) {
        return true;
      }
    }
  }

  return false;
}
