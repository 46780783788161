import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TGroupedReminders, TReminderList } from '../reminder-list.model';
import { TTranslationKey } from 'src/app/project/features/translate/types';

@Component({
  selector: 'pp-reminder-section',
  templateUrl: './reminder-section.component.html',
  styleUrls: ['./reminder-section.component.scss'],
})
export class ReminderSectionComponent {
  @Input() ppGroupExpanded: boolean;
  @Input() ppReminders: TReminderList;
  @Input() ppGroupedReminders: TGroupedReminders;
  @Input() ppFetched: boolean;
  @Input() ppOffline: boolean;
  @Input() ppNameKey: TTranslationKey;
  @Output() ppToggle = new EventEmitter<void>();
  @Output() ppLoadMore = new EventEmitter<void>();

  EIconPath = EIconPath;

  toggleGroup(): void {
    this.ppToggle.emit();
  }

  loadMore(): void {
    this.ppLoadMore.emit();
  }
}
