import { TDashletGraphOptions } from '../../dashlet-graph.model';
import { createBarGraphTooltipLabel } from './create-bar-graph-tooltip-label';
import { createDashletTooltip } from './dashlet-tooltip';

const fontColor = '#586378';

export function generateStackedBarGraphOptions(isExport: boolean): TDashletGraphOptions {
  const fontSize = isExport ? 42 : 12;

  return {
    devicePixelRatio: window.devicePixelRatio,
    scales: {
      x: {
        stacked: true,
        ticks: {
          font: {
            size: fontSize,
            color: fontColor,
          },
        },
      },
      y: {
        stacked: true,
        ticks: {
          min: 0,
          font: {
            size: fontSize,
            color: fontColor,
          },
          maxTicksLimit: 7,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        onClick: null,
        position: 'top',
        labels: {
          pointStyle: 'circle',
          boxWidth: 10,
          boxHeight: 10,
          font: {
            size: fontSize,
            color: fontColor,
          },
        },
      },
      tooltip: {
        enabled: true,
        custom: function (tooltipModel): void {
          return createDashletTooltip(tooltipModel, this._chart);
        },
        callbacks: {
          label: function (tooltipItem) {
            return createBarGraphTooltipLabel(tooltipItem);
          },
        },
      },

      datalabels: {
        display: (value) => {
          return false;
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    cutout: 55,
  };
}
