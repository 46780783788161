import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TNewCustomField } from 'src/app/project/data-providers/api-providers/workspace-api-provider/workspace-requests.model';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { setChangedAccounts } from 'src/app/project/modules/account/account';
import { AddCustomFieldToFilterService } from 'src/app/project/modules/filters/filter-custom-fields/add-custom-field-to-filter.service';
import { setChangedWorkspace } from 'src/app/project/modules/workspace/workspace';
import { AddCustomField } from 'src/app/project/modules/workspace/workspace.actions';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSettings,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { TCustomField } from '../custom-fields.model';
import { CustomFieldsService } from '../custom-fields.service';

@Injectable({
  providedIn: 'root',
})
export class AddCustomFieldService {
  constructor(
    private store: Store,
    private translationPipe: TranslationPipe,
    private customFieldsService: CustomFieldsService,
    private addCustomFieldToFilterService: AddCustomFieldToFilterService,
    private promptService: PromptService,
    private modalService: ModalService,
  ) {}

  addCustomField(workspaceId: string, newFieldData: TNewCustomField): Observable<TCustomField> {
    return this.customFieldsService.addCustomField(workspaceId, newFieldData).pipe(
      tap((response) => {
        this.processNewCustomFieldResponse(response, workspaceId);
      }),
      catchError((error) => {
        this.handleAddCustomFieldError(error);

        return throwError(error);
      }),
    );
  }

  processNewCustomFieldResponse(response: TCustomField, workspaceId: string): void {
    const promptText = this.translationPipe.transform('prompt_field_created');
    const newCustomField = response;

    logEventInGTAG(EGoogleEventSettings.SETTINGS__CF__NEW, {
      event_category: EGoogleEventCategory.SETTINGS,
    });

    this.customFieldsService.addWorkspaceCustomField(workspaceId, newCustomField as TCustomField);

    this.store.dispatch(
      new AddCustomField({
        workspaceId,
        customFieldId: newCustomField.id.toString(),
      }),
    );

    this.addCustomFieldToFilterService.addCustomFieldToFilter(newCustomField, workspaceId);
    setChangedWorkspace(workspaceId);
    setChangedAccounts(true);

    this.promptService.showSuccess(promptText);
    this.modalService.hideModal(false);
  }

  handleAddCustomFieldError(error: HttpErrorResponse): void {
    let promptText = this.translationPipe.transform('prompt_field_create_error');

    if (error.status === EStatusCode.BAD_REQUEST) {
      promptText = this.translationPipe.transform('prompt_field_create_error_timeline_doubled');
    } else if (error.status === EStatusCode.FORBIDDEN) {
      promptText = this.translationPipe.transform('prompt_field_create_error_timeline_not_enabled');
    }

    this.promptService.showError(promptText);
  }

  addFieldFromExcel(workspaceId: string, file: File): Observable<TCustomField> {
    return this.customFieldsService.importCustomField(workspaceId, file).pipe(
      tap((response) => {
        this.processNewCustomFieldResponse(response, workspaceId);
      }),
    );
  }
}
