import { Subject } from 'rxjs';
import { TNotifications, TPushNotification } from './notification.model';

const notifications: TNotifications = {
  all: [],
  unread: [],
  mentioned: [],
  assigned: [],
};

export const notificationsChange$ = new Subject<void>();

export const GET_NOTIFICATIONS = (): TNotifications => notifications;

export const SET_NOTIFICATIONS = (_notifications: TPushNotification[], mode: string): void => {
  notifications[mode] = _notifications;

  notificationsChange$.next();
};

export const MARK_NOTIFICATION_AS_READ = (notificationId: string): void => {
  Object.keys(notifications).forEach((notificationType) => {
    const notification = notifications[notificationType].find(
      (notification_) => notification_.id === notificationId,
    );

    if (notification) {
      notification.markedAsRead = true;
    }
  });

  notificationsChange$.next();
};

export const MARK_NOTIFICATION_AS_ANSWERED = (notificationId: string): void => {
  Object.keys(notifications).forEach((notificationType) => {
    const notification = notifications[notificationType].find(
      (notification_) => notification_.id === notificationId,
    );

    if (notification) {
      notification.markedAsRead = true;
      notification.markedAsAnswered = true;
    }
  });

  notificationsChange$.next();
};

export const MARK_NOTIFICATION_AS_UNREAD = (notificationId: string): void => {
  Object.keys(notifications).forEach((notificationType) => {
    const notification = notifications[notificationType].find(
      (notification_) => notification_.id === notificationId,
    );

    if (notification) {
      notification.markedAsRead = false;
    }
  });

  notificationsChange$.next();
};

export const DELETE_NOTIFICATION = (notificationId: string): void => {
  Object.keys(notifications).forEach((notificationType) => {
    const notificationIndex = notifications[notificationType].findIndex(
      (notification_) => notification_.id === notificationId,
    );

    notifications[notificationType].splice(notificationIndex, 1);
  });

  notificationsChange$.next();
};

export const CLEAR_NOTIFICATIONS = (): void => {
  notifications.all = [];
  notifications.unread = [];
  notifications.mentioned = [];
  notifications.assigned = [];

  notificationsChange$.next();
};

export const MARK_NOTIFICATIONS_READ = (): void => {
  Object.keys(notifications).forEach((notificationType) => {
    notifications[notificationType].forEach((notification) => {
      notification.markedAsRead = true;
    });
  });

  notificationsChange$.next();
};
