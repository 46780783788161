import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { TCustomField } from '../../custom-fields.model';
import { TBulkCustomFields, TBulkCustomFieldsExtended } from '../bulk-custom-fields.model';

@Component({
  selector: 'pp-bulk-field-checkbox',
  templateUrl: './bulk-field-checkbox.component.html',
  styleUrls: ['../bulk-custom-fields-modal.component.scss'],
})
export class BulkFieldCheckboxComponent {
  @Input() ppCustomField: TCustomField;
  @Input() ppCustomFields: TBulkCustomFields<boolean>;
  @Input() ppFieldId: string;
  @Input() ppCustomFieldsExtended: TBulkCustomFieldsExtended<boolean>;
  @Input() ppWorkspaceId: string;

  @Output() ppCustomFieldsExtendedChange = new EventEmitter<TBulkCustomFieldsExtended>();
  @Output() ppCustomFieldsChange = new EventEmitter<TBulkCustomFields>();

  EIconPath = EIconPath;

  constructor() {}

  updateCheckbox(value: boolean, field: TCustomField): void {
    this.ppCustomFields[field.id] = value;

    this.ppCustomFieldsExtended[field.id] = {
      value: this.ppCustomFields[field.id],
      label: field.label,
      type: field.type,
    };

    this.ppCustomFieldsChange.emit(this.ppCustomFields);
    this.ppCustomFieldsExtendedChange.emit(this.ppCustomFieldsExtended);
  }
}
