import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import AutoNumeric from 'autonumeric';
import { createAutonumeric, TAutoNumericOptions } from '../../../core/helpers/create-autonumeric';

@Directive({
  selector: '[ppAutonumeric]',
})
export class AutonumericDirective implements OnChanges {
  @Input() public ppAutonumeric: TAutoNumericOptions;

  private element: HTMLInputElement;
  private autonumeric: AutoNumeric;

  constructor(private elementRef: ElementRef) {
    this.element = this.elementRef.nativeElement;
  }

  ngOnChanges() {
    if (!this.autonumeric) {
      this.autonumeric = createAutonumeric(this.element, this.ppAutonumeric);
    } else {
      this.autonumeric.update(this.ppAutonumeric);
    }
  }
}
