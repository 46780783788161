<div class="advancedCFAccess__section">
  <h6 class="shareModalHeader">
    {{ 'comments' | ppTranslate }}

    <i
      ppTooltip
      [ppMobileTooltip]="true"
      [ppOptions]="{ placement: 'right' }"
      class="fa fa-info-circle settingsSection__header-info"
      aria-hidden="true"
      [ppSanitize]="false"
      ppTitle="
      <div style='text-align: left'>
        <ul style='padding-left: 0; margin: 0'>
          <li>•	<strong>{{ 'hide_comments' | ppTranslate }}</strong> – {{
        'hide_comments_description' | ppTranslate
      }}</li>
          <li>•	<strong>{{ 'view_comments' | ppTranslate }}</strong> – {{
        'view_comments_description' | ppTranslate
      }}</li>
          <li>•	<strong>{{ 'make_comments' | ppTranslate }}</strong> – {{
        'make_comments_description' | ppTranslate
      }}</li>
        </ul>
      </div>"
    ></i>
  </h6>

  <div class="shareModal__radio-row">
    <pp-radio
      ppStyle="blue"
      [ppChecked]="
        !ppShare.advancedAccessLevels.timeline.comments.permission.edit &&
        !ppShare.advancedAccessLevels.timeline.comments.permission.read
      "
      (ppOnValueChange)="correctProperty(EGoogleEventLabel.COMMENT, accessParameters.HIDE)"
    >
      <label class="control-custom control-custom--radio">
        {{ 'hide_comments' | ppTranslate }}
      </label>
    </pp-radio>

    <pp-radio
      ppStyle="blue"
      [ppChecked]="
        !ppShare.advancedAccessLevels.timeline.comments.permission.edit &&
        ppShare.advancedAccessLevels.timeline.comments.permission.read
      "
      (ppOnValueChange)="correctProperty(EGoogleEventLabel.COMMENT, accessParameters.READ)"
    >
      <label class="control-custom control-custom--radio">
        {{ 'view_comments' | ppTranslate }}
      </label>
    </pp-radio>

    <pp-radio
      ppStyle="blue"
      [ppChecked]="
        ppShare.advancedAccessLevels.timeline.comments.permission.edit &&
        ppShare.advancedAccessLevels.timeline.comments.permission.read
      "
      (ppOnValueChange)="correctProperty(EGoogleEventLabel.COMMENT, accessParameters.EDIT)"
    >
      <label class="control-custom control-custom--radio">
        {{ 'make_comments' | ppTranslate }}
      </label>
    </pp-radio>
  </div>
</div>
