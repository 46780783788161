<section class="headerOptions">
  <button class="headerOptions__option" (click)="export()" *ngIf="canExport">
    <img class="headerOptions__icon icon-export" [src]="EIconPath.EXPORT_EXPORT" />
    {{ 'export' | ppTranslate }}
  </button>

  <button class="headerOptions__option" (click)="copyPoint()" *ngIf="canCopy">
    <img [src]="EIconPath.POINT_INTERACTION_COPY" class="headerOptions__icon icon-copy" />
    {{ 'copy' | ppTranslate }}
  </button>

  <button class="headerOptions__option" (click)="movePoint()" *ngIf="canMove">
    <img [src]="EIconPath.POINT_INTERACTION_MOVE" class="headerOptions__icon icon-move" />
    {{ 'move' | ppTranslate }}
  </button>

  <button class="headerOptions__option" (click)="linkPoint()">
    <img [src]="EIconPath.ICON_COPY_LINK_24" class="headerOptions__icon icon-link" />
    {{ 'copy_link_to_point' | ppTranslate }}
  </button>

  <hr class="headerOptions__hr" *ngIf="canDelete" />

  <button
    class="headerOptions__option headerOptions__option--delete"
    (click)="deletePoint()"
    *ngIf="canDelete"
  >
    <img
      class="headerOptions__icon icon__delete"
      [src]="EIconPath.INTERACTION_DELETE_ATTACHMENT_ICON"
    />
    {{ 'delete_point' | ppTranslate }}
  </button>
</section>
