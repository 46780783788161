import { cloneDeep } from 'lodash';
import { EIntegrationStatus, TCustomField, TCustomFieldDTO } from '../custom-fields.model';

export class CustomFieldModelFactory {
  static createFromDTO(response: TCustomFieldDTO | TCustomField): TCustomField {
    const dto = cloneDeep(response);

    let showTotal: boolean = dto.showTotal;
    let showCommas: boolean = dto.showCommas;
    let display = dto.display;
    let lockedTemplate = dto.lockedTemplate;
    let lockedValue = dto.lockedValue;
    let volyIntegrationActive: EIntegrationStatus = null;

    if (dto.showTotal) {
      if ((dto.showTotal as any) === 'true') {
        showTotal = true;
      } else if ((dto.showTotal as any) === 'false') {
        showTotal = false;
      }
    }

    if (dto.showCommas) {
      if ((dto.showCommas as any) === 'true') {
        showCommas = true;
      } else if ((dto.showCommas as any) === 'false') {
        showCommas = false;
      }
    }

    if (dto.display === undefined) {
      display = true;
    }

    if (dto.lockedTemplate === undefined) {
      lockedTemplate = false;
    }

    if (dto.lockedValue === undefined) {
      lockedValue = false;
    }

    if (typeof dto.volyIntegrationActive === 'string') {
      volyIntegrationActive = dto.volyIntegrationActive as EIntegrationStatus;
    } else if (dto.volyIntegrationActive === false) {
      volyIntegrationActive = EIntegrationStatus.DISABLED;
    } else if (dto.volyIntegrationActive === true) {
      volyIntegrationActive = EIntegrationStatus.ON;
    } else {
      volyIntegrationActive = EIntegrationStatus.OFF;
    }

    const customField: TCustomField = {
      label: dto.label,
      id: dto.id,
      maxListIndex: dto.maxListIndex,
      subList: dto.subList,
      currencyCode: dto.currencyCode,
      currencySymbol: dto.currencySymbol,
      type: dto.type,
      unit: dto.unit,
      decimalPlaces: dto.decimalPlaces,
      showTotal,
      showCommas,
      showHoursOnly: dto.showHoursOnly,
      fieldError: false,
      workspaceId: dto.workspaceId,
      lockedTemplate,
      lockedValue,
      display,
      volyIntegrationActive,
      outputType: dto.outputType,
      formula: dto.formula,
      nestingLevel: dto.nestingLevel,
    };

    return customField;
  }
}
