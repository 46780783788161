import { Component } from '@angular/core';

@Component({
  selector: 'pp-notification-new-point-mention',
  templateUrl: './notification-new-point-mention.component.html',
  styleUrls: ['../notification.component.scss'],
})
export class NotificationNewPointMentionComponent {
  constructor() {}
}
