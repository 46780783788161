import { createElement } from 'src/app/core/helpers/dom';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { EIconPath } from '../../../../../shared/enums/icons.enum';
import { TColumn } from '../../columns/column.model';
import { createCellElement } from '../createCellElement';

export function createCheckboxElement(
  _index: number,
  _column: TColumn,
  _customField: TPointCustomField<string>,
): HTMLElement {
  let value = !!_customField?.value;

  if (_customField?.value === 'false') {
    value = false;
  }

  if (
    _column.customFieldIds &&
    !_column.customFieldIds.includes(_customField.customFieldTemplateId.toString())
  ) {
    value = false;
  }

  const checkboxValue = value;
  let child;

  if (checkboxValue) {
    child = createElement('img', {
      attrs: {
        class: 'table__checkboxCF',
        src: EIconPath.INPUT_CHECKBOX,
      },
    });
  } else {
    child = '';
  }

  return createCellElement({
    index: _column.index,
    width: `${_column.width}px`,
    child: createElement('div', {
      attrs: {
        class: 'customSiteTable__text',
      },
      children: [child],
    }),
  });
}
