import { TFleet } from '../../fleet.consts';
import { areFleetsEqual } from '../are-fleets-equal';
import { getDefaultFleetStatuses } from './get-default-fleet-statuses';

export function checkEmptyFleetChanges(newFleet: Partial<TFleet>): boolean {
  const oldFleet: Partial<TFleet> = {
    name: '',
    accountId: '',
    statusSettings: getDefaultFleetStatuses(),
  };

  return !areFleetsEqual(oldFleet, newFleet);
}
