<div>
  <div class="tagSearch__sub-header copy">
    <p>{{ 'including_tags' | ppTranslate }}</p>

    <button
      class="filterDropdown__clear--text"
      *ngIf="filters.tags.value.length > 0 || filters.tags.combinedTags"
      (click)="clearAll()"
    >
      {{ 'clear' | ppTranslate }}
    </button>
  </div>

  <pp-tag-select
    ppClass="tagSelect__auto-width"
    [ppSelectedTags]="filters.tags.value"
    (ppOnChange)="selectTags($event)"
    [ppWorkspaceId]="workspaceId"
    [ppOptions]="tagSelectOptionsForInclude"
    (ppOnClose)="selectTags($event)"
  ></pp-tag-select>

  <div class="margin__top">
    <pp-checkbox [ppChecked]="filters.tags.combinedTags" (ppOnValueChange)="toggleCombineTags()">
      <p class="checkbox__label">
        {{ 'combine_selected_tags' | ppTranslate }}

        <i
          ppTooltip
          class="fa fa-info-circle"
          aria-hidden="true"
          [ppMobileTooltip]="true"
          [ppTitle]="'combine_selected_tags_description' | ppTranslate"
        ></i>
      </p>
    </pp-checkbox>
  </div>
</div>

<div>
  <div class="tagSearch__sub-header copy tagSearch__sub-header--marginTop">
    <p>{{ 'excluding_tags' | ppTranslate }}</p>

    <button
      class="filterDropdown__clear--text"
      *ngIf="filters.excludedTags.value.length > 0 || filters.excludedTags.combinedTags"
      (click)="clearAllExcluded()"
    >
      {{ 'clear' | ppTranslate }}
    </button>
  </div>

  <pp-tag-select
    ppClass="tagSelect__auto-width"
    [ppSelectedTags]="filters.excludedTags.value"
    (ppOnChange)="selectExcludedTags($event)"
    (ppOnClose)="selectExcludedTags($event)"
    [ppWorkspaceId]="workspaceId"
    [ppOptions]="tagSelectOptionsForExclude"
  ></pp-tag-select>

  <div class="margin__top">
    <pp-checkbox
      [ppChecked]="filters.excludedTags.combinedTags"
      (ppOnValueChange)="toggleCombineExcludedTags()"
    >
      <p class="checkbox__label">
        {{ 'combine_selected_tags' | ppTranslate }}

        <i
          ppTooltip
          class="fa fa-info-circle"
          aria-hidden="true"
          [ppMobileTooltip]="true"
          [ppTitle]="'combine_tags_description_exclude' | ppTranslate"
        ></i>
      </p>
    </pp-checkbox>
  </div>
</div>
