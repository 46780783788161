import { Component, OnInit } from '@angular/core';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';

import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { translate } from 'src/app/project/features/translate/translate';

@Component({
  selector: 'pp-site-options-more-floating-actions-edit-dropdown',
  templateUrl: './site-options-more-floating-actions-edit-dropdown.component.html',
  styleUrls: ['./site-options-more-floating-actions-edit-dropdown.component.scss'],
})
export class SiteOptionsMoreFloatingActionsEditDropdownComponent implements OnInit {
  dropdown: TDropdown = this.dropdownService.getDropdown();

  canEditTags = false;
  canEditCustomFields = false;
  canEditFlags: boolean = true;
  pointsHaveDifferentFlags: boolean = false;
  flagTooltipText = '';

  constructor(private dropdownService: DropdownService) {}

  ngOnInit() {
    if (!this.dropdown.data) {
      throw new Error('Data for this dropdown is required');
    }

    if (!this.dropdown.callback) {
      throw new Error('Callback for this dropdown is required');
    }

    this.canEditTags = this.dropdown.data.canEditTags;
    this.canEditCustomFields = this.dropdown.data.canEditCustomFields;
    this.canEditFlags = this.dropdown.data.canEditFlag;
    this.canEditTags = this.dropdown.data.canEditTags;
    this.pointsHaveDifferentFlags = this.dropdown.data.haveDifferentFlags;
    this.flagTooltipText = this.generateFlagTooltipText();
  }

  generateFlagTooltipText(): string {
    if (!this.canEditFlags) {
      return translate('flag_no_permission');
    }

    if (this.pointsHaveDifferentFlags) {
      return translate('flag_clash');
    }

    return '';
  }

  hideDropdown(): void {
    this.dropdownService.hideDropdown();
  }

  bulkChangeFlag(): void {
    if (!this.pointsHaveDifferentFlags || this.canEditFlags) {
      this.dropdown.callback('flag');
      this.hideDropdown();
    }
  }

  bulkChangePriority(): void {
    this.dropdown.callback('priority');
    this.hideDropdown();
  }

  bulkChangeStatus(): void {
    this.dropdown.callback('status');
    this.hideDropdown();
  }

  bulkChangeCustomFields(): void {
    this.dropdown.callback('customFields');
    this.hideDropdown();
  }

  bulkAddTags(): void {
    this.dropdown.callback('tags');
    this.hideDropdown();
  }

  bulkChangeAssignees(): void {
    this.dropdown.callback('assignees');
    this.hideDropdown();
  }
}
