import { EIconPath } from '../../shared/enums/icons.enum';

export enum EAuthorizationProgressStep {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
}

export type TAuthorizationProgressIcon = {
  src: EIconPath;
  spinning: boolean;
  rotation: number;
  active: boolean;
};
