import { createElement } from '@core/helpers';
import { GET_TIMELINE_COLUMNS } from '../../timeframes/timeline-timeframes';
import { GET_TIMELINE } from '../../timeline.ui.store';
import { createTimelineCellTypeElement } from './create-cell-type-element';

export function createTimelineColumnCellsElement(): HTMLElement {
  const timeline = GET_TIMELINE();
  const timelineData = GET_TIMELINE_COLUMNS();
  const offsetX = timeline.timelineBody.virtualScroller.offsetX;
  const visibleXIndexes = timeline.timelineBody.virtualScroller.visibleXIndexes;
  const children = [];

  visibleXIndexes.forEach((_visibleXIndex) => {
    const columnIndex = timeline.visibleColumnIndexes[_visibleXIndex];

    if (timelineData.bottomElements[columnIndex]) {
      children.push(createTimelineCellTypeElement(columnIndex));
    }
  });

  const element = createElement('div', {
    attrs: {
      class: 'table__columnCells',
      style: {
        transform: `translateX(${offsetX}px)`,
      },
    },
    children,
  });

  return element;
}
