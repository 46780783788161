import { Component, Input } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TActivityChange } from '../activity-change.model';

@Component({
  selector: 'pp-dashlet-activity-move',
  templateUrl: './dashlet-activity-move.component.html',
  styleUrls: ['../dashlet-activity.component.scss'],
})
export class DashletActivityMoveComponent {
  @Input() ppChange: TActivityChange;

  EIconPath = EIconPath;
}
