import { Component, Input, OnInit } from '@angular/core';
import { TAllUsers } from '@project/view-models';
import { EIconPath } from '../../../../../../../shared/enums/icons.enum';
import { PointActivityMergeService } from '../../../../../../points/point-modal/point-timeline/point-activity-merge.service';

@Component({
  selector: 'pp-dashlet-activity-assignees',
  templateUrl: './dashlet-activity-assignees.component.html',
  styleUrls: ['../../dashlet-activity.component.scss'],
})
export class DashletActivityAssigneesComponent implements OnInit {
  // FIXME refactor Activities to use single type
  @Input() ppChange: any;
  @Input() ppAllUsers: TAllUsers;

  EIconPath = EIconPath;

  constructor(private pointActivityMergeService: PointActivityMergeService) {}

  ngOnInit(): void {
    if (!this.ppChange.added && !this.ppChange.deleted) {
      this.pointActivityMergeService.checkAndInitAssigneesChanges(this.ppChange);
    }
  }
}
