import { Component, EventEmitter, Input, Output } from '@angular/core';
import { catchError, of, tap } from 'rxjs';
import { blurInput } from 'src/app/core/helpers/blur-input';
import { preventNonNumberInput } from 'src/app/core/helpers/check-if-number';
import { getErrorMessage } from 'src/app/project/helpers/database/get-error-message';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { UserService } from '../../../user/user.service';
import { LoginRedirectService } from '../../login-redirect.service';
import { ETwoFactorAuthenticationChannel } from '../two-factor-authentication.consts';
import { TwoFactorAuthenticationService } from '../two-factor-authentication.service';
import { twoFactorAuthenticationChannelOptions } from './two-factor-authentication-number-page.consts';

@Component({
  selector: 'pp-two-factor-authentication-number-page',
  templateUrl: './two-factor-authentication-number-page.component.html',
  styleUrls: ['./two-factor-authentication-number-page.component.scss'],
})
export class TwoFactorAuthenticationNumberPageComponent {
  @Input() ppPhoneNumber: string;
  @Output() ppPhoneNumberChange = new EventEmitter<string>();

  @Input() ppChannel: ETwoFactorAuthenticationChannel;
  @Output() ppChannelChange = new EventEmitter<ETwoFactorAuthenticationChannel>();

  @Input() ppProcessing: boolean;
  @Output() ppStepChange = new EventEmitter<void>();

  EIconPath = EIconPath;
  errorMessage: string;
  twoFactorAuthenticationChannelOptions = twoFactorAuthenticationChannelOptions;

  constructor(
    private twoFactorAuthenticationService: TwoFactorAuthenticationService,
    private loginRedirectService: LoginRedirectService,
    private userService: UserService,
  ) {}

  phoneNumberChange(): void {
    this.ppPhoneNumberChange.emit(this.ppPhoneNumber);
  }

  setUp2FA(): void {
    this.twoFactorAuthenticationService
      .setup2FA(this.ppPhoneNumber, this.ppChannel)
      .pipe(
        tap(() => {
          this.ppStepChange.next();
        }),
        catchError((error) => {
          getErrorMessage(error).then((errorMessage) => {
            this.errorMessage = errorMessage;
          });

          return of();
        }),
      )
      .subscribe();
  }

  blurInput(event: Event): void {
    blurInput(event.target);
  }

  setUpLater(): void {
    this.loginRedirectService.redirectAfterLogin();
    this.twoFactorAuthenticationService.askLaterFor2FA(this.userService.getUser().userId);
  }

  preventNonNumberInput(event: KeyboardEvent): void {
    preventNonNumberInput(event);
  }

  checkValidation(event: string): void {
    this.errorMessage = event;
  }

  onChannelChange(channel: string): void {
    this.ppChannelChange.emit(channel as ETwoFactorAuthenticationChannel);
  }
}
