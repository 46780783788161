import { ELocalStorageItems } from 'src/app/project/shared/enums/local-storage-items.enum';

export function getTimelineGroupColumnWidth(): number {
  const themeWrapper = document.querySelector('body');
  const groupWidth = parseFloat(
    themeWrapper.style.getPropertyValue('--timelineGroupWidth').replace('px', ''),
  );

  return groupWidth;
}

export function setTimelineGroupColumnWidth(): void {
  const timelineGroupColWidth = localStorage.getItem(ELocalStorageItems.TIMELINE_GROUP_COL_WIDTH);
  const themeWrapper = document.querySelector('body');

  if (timelineGroupColWidth) {
    themeWrapper.style.setProperty('--timelineGroupWidth', timelineGroupColWidth);
  } else {
    themeWrapper.style.setProperty('--timelineGroupWidth', '240px');
  }
}
