import { getEmptyGroupName } from './empty-group-name';

export function transformTagsValue(_value: string[]): string {
  const emptyGroupValue = getEmptyGroupName();

  if (!_value.length) {
    return emptyGroupValue;
  }

  return _value.join(', ');
}
