<pp-advanced-filter-option-select
  class="advancedFilter__option"
  [ppItems]="advancedFilterTextOptions"
  [ppOption]="option"
  (ppOptionChange)="changeFieldCondition($event)"
  ppClass="advancedFilter__option--condition"
></pp-advanced-filter-option-select>

<ng-container
  *ngIf="
    option === EAdvancedFilterOptions.IS ||
    option === EAdvancedFilterOptions.IS_NOT ||
    option === EAdvancedFilterOptions.CONTAINS ||
    option === EAdvancedFilterOptions.DOES_NOT_CONTAIN
  "
>
  <pp-advanced-filter-text-input
    [(ppValue)]="value"
    (ppValueChange)="changeFieldValue($event)"
  ></pp-advanced-filter-text-input>
</ng-container>
