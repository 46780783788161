import { GET_TABLE } from '../../table.ui.store';
import { createElement } from 'src/app/core/helpers/dom';
import { createCellElement } from '../createCellElement';
import { TColumn } from '../../columns/column.model';
import Tooltip from 'src/app/project/features/tooltip/Tooltip';
import { sanitizeHTML } from 'src/app/core/helpers/text-sanitizer';
import Table from '../../custom-table/table/Table';

export function createTagsElement(_index: number, _column: TColumn): HTMLElement {
  const table = GET_TABLE();

  const tagsElement = createElement('div', {
    attrs: {
      class: 'table__tags__container',
    },
    children: table.points[_index].tags.map((tag) => {
      const matchesKeyword =
        table.keyword && tag.toLowerCase().indexOf(table.keyword.toLowerCase()) !== -1;

      return createElement('div', {
        attrs: {
          class: matchesKeyword ? 'site-table__tag site-table__tag--selected' : 'site-table__tag',
          'data-id': tag,
        },
        children: [tag],
      });
    }),
  });

  const element = createCellElement({
    index: _column.index,
    width: `${_column.width}px`,
    child: tagsElement,
  });

  createTagsTooltip(table, _index, tagsElement);

  return element;
}

function createTagsTooltip(table: Table, _index: number, tagsElement: HTMLElement): void {
  let tagsTooltip = '<div class="tooltip_tagList">';

  for (const tag of table.points[_index].tags) {
    tagsTooltip += '<span class="sw-tag-sm tooltipTag">' + sanitizeHTML(tag) + '</span>';
  }

  tagsTooltip += '</div>';

  const nameTooltip = new Tooltip({
    title: tagsTooltip,
    mobileTooltip: false,
    childTooltip: false,
    element: tagsElement,
    trimTooltip: true,
    trimElement: tagsElement,
    sanitize: false,
  });

  nameTooltip.createTooltip();
}
