import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';
import { usersReducer } from './users.reducer';

import { UsersService } from './users.service';
import { UsersDropdownComponent } from './users-dropdown/users-dropdown.component';
import { UserDropdownComponent } from './user-dropdown/user-dropdown.component';
import { ButtonModule } from '../../components/button/button.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { SearchBoxModule } from '../../components/search-box/search-box.module';
import { AutonumericModule } from '../../directives/autonumeric/autonumeric.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SwitchCasesModule } from '../../directives/switch-cases/switch-cases.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { SecureSrcModule } from '../../directives/secure-src/secure-src.module';
import { ToggleModule } from '../../components/toggle/toggle.module';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('users', usersReducer),
    FormsModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    SearchBoxModule,
    ButtonModule,
    PipesModule,
    SecureSrcModule,
    ToggleModule,
  ],
  providers: [UsersService],
  declarations: [UsersDropdownComponent, UserDropdownComponent],
})
export class UsersModule {}
