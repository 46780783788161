import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import {
  TAttachment,
  TAttachments,
} from 'src/app/project/modules/points/attachments/attachments.model';
import { TPoint } from 'src/app/project/modules/points/points.model';

import { AttachmentsMediaGalleryService } from 'src/app/project/modules/points/attachments/attachments-media-gallery.service';
import { AttachmentsService } from 'src/app/project/modules/points/attachments/attachments.service';
import { PointHalfModalService } from 'src/app/project/modules/points/point-half-modal/point-half-modal.service';
import { PointAttachmentsDeleteService } from 'src/app/project/modules/points/point-modal/point-attachments/point-attachments-delete.service';
import { PointsService } from 'src/app/project/modules/points/points.service';
import {
  GALLERY_OVERLAY_LEFT,
  GALLERY_OVERLAY_RIGHT,
  ListenerService,
} from '../../../core/helpers/listener.service';
import { ActiveService } from '../../services/active/active.service';
import { PromptService } from '../prompt/prompt.service';

import { DOCUMENT } from '@angular/common';
import { ApiService } from '@core/http';
import {
  API_files_custom_type,
  API_files_images_size_original,
} from 'src/app/project/data-providers/api-providers/files-api-provider/files-paths';
import { EWorkspaces } from 'src/app/project/modules/workspace/workspaces.enum';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { getExtenstion } from '../../../core/helpers/get-extenstion';
import { TranslationPipe } from '../../features/translate/translation.pipe';
import { TMediaObject } from '../../modules/points/point-modal/point-attachments/point-attachments.service';
import { DownloadService } from '../../services/download.service';
import { EFileType } from '../../shared/enums/file-type.enum';
import { GalleryOverlayComponent } from './gallery-overlay.component';

export type TOverlay = {
  visible: boolean;
  index: number;
  elements: TMediaObject[];
};

@Injectable({
  providedIn: 'root',
})
export class GalleryOverlayService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  overlay: TOverlay = {
    visible: false,
    index: null,
    elements: [],
  };

  media: TAttachments['media'] = {
    dates: [],
    attachments: {},
  };

  attachmentId: string;
  items: TMediaObject[];
  point: TPoint;
  overlayComponent: GalleryOverlayComponent;
  workspaceId = '';
  _id = '';

  tempRotation: {
    rotation: number;
  } = {
    rotation: null,
  };

  private attachments$: Observable<TAttachments>;
  private imageElement: HTMLElement;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<{
      attachments: TAttachments;
    }>,
    private listenerService: ListenerService,
    private attachmentsMediaGalleryService: AttachmentsMediaGalleryService,
    private attachmentsService: AttachmentsService,
    private pointAttachmentsDeleteService: PointAttachmentsDeleteService,
    private promptService: PromptService,
    private pointsService: PointsService,
    private router: Router,
    private activeService: ActiveService,
    private pointHalfModalService: PointHalfModalService,
    private translationPipe: TranslationPipe,
    private downloadService: DownloadService,
    private apiService: ApiService,
  ) {
    this.attachments$ = this.store.pipe(select(EStore.ATTACHMENTS));

    this.attachments$.pipe(takeUntil(this.destroy$)).subscribe((attachments) => {
      this.media = attachments.media;
      this.items = this.attachmentsMediaGalleryService.createMediaArray(this.media);
      this.overlay.elements = this.items;

      if (this.overlay.visible) {
        this.removeListeners();
      }

      this.overlay.elements
        .filter((element, _index) => element.type === 'Image')
        .forEach((image) => {
          this.apiService
            .getFile(image.src)
            .pipe(
              tap((response) => {
                if (!response) {
                  image.base64src = null;
                  image.w = null;
                  image.h = null;

                  return;
                }

                response.blob().then((blob) => {
                  const reader = new window.FileReader();

                  reader.readAsDataURL(blob);
                  reader.onloadend = () => {
                    const base64data = reader.result;

                    const imageExtension = image.extension;
                    const imageElement = new Image();

                    imageElement.onload = (): void => {
                      image.base64src = this.convertImgToBase64(imageElement, imageExtension);
                      image.w = imageElement.naturalWidth;
                      image.h = imageElement.naturalHeight;
                    };

                    imageElement.src = base64data as string;
                  };
                });
              }),
            )
            .subscribe();
        });

      if (this.overlay.visible) {
        this.addListeners();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  getOverlay(): TOverlay {
    return this.overlay;
  }

  show(index: number, elements: TMediaObject[]): void {
    this.overlay.visible = true;
    this.overlay.index = index;
    this.overlay.elements = elements;
  }

  addListeners(): void {
    this.listenerService.add(GALLERY_OVERLAY_LEFT, () => this.previous());
    this.listenerService.add(GALLERY_OVERLAY_RIGHT, () => this.next());
  }

  previous(): void {
    if (!this.overlay.visible) {
      return;
    }

    let id = null;

    if (!this.overlay.elements[this.overlay.index - 1]) {
      id = this.overlay.elements[this.overlay.elements.length - 1].id;
    } else {
      id = this.overlay.elements[this.overlay.index - 1].id;
    }

    const url = this.getUrl(id);

    if (url) {
      this.router.navigate(url);
    }
  }

  next(): void {
    if (!this.overlay.visible) {
      return;
    }

    let id = null;

    if (!this.overlay.elements[this.overlay.index + 1]) {
      id = this.overlay.elements[0].id;
    } else {
      id = this.overlay.elements[this.overlay.index + 1].id;
    }

    const url = this.getUrl(id);

    if (url) {
      this.router.navigate(url);
    }
  }

  hide(): void {
    this.removeListeners();

    this.overlay.visible = false;
    const url = this.getUrl();

    if (url) {
      this.router.navigate(url);
    }
  }

  removeListeners(): void {
    this.listenerService.remove(GALLERY_OVERLAY_LEFT);
    this.listenerService.remove(GALLERY_OVERLAY_RIGHT);
  }

  convertImgToBase64(imageElement: HTMLImageElement, imageExtension: string): string {
    const canvas = this.document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = imageElement.naturalWidth;
    canvas.height = imageElement.naturalHeight;

    ctx.drawImage(imageElement, 0, 0);

    return canvas.toDataURL(`image/${imageExtension}`);
  }

  async downloadImage(): Promise<void> {
    const element = this.overlay.elements[this.overlay.index];
    const attachment = this.attachmentsService.findMediaAttachment(element.id);

    if (attachment.type === 'Image') {
      const mediaUrl = this.attachmentsService.createMediaUrl(attachment);
      const fileName = this.attachmentsService.createMediaFileName(
        '',
        attachment.fileName,
        attachment.mimeType,
      );

      this.downloadService.downloadGET({ url: mediaUrl, fileName }).subscribe();
    } else if (attachment.type === 'Image360') {
      const mediaSize = this.calculateSelectedSize(attachment);
      const promptText = this.translationPipe
        .transform('prompt_download_media_size')
        .replace('#', mediaSize);

      this.downloadSingleImage360(attachment);
      this.promptService.showSuccess(promptText);
    } else {
      const mediaSize = (attachment.originalFileSize / Math.pow(1024, 2)).toFixed(2);
      const promptText = this.translationPipe.transform('prompt_attachments_size_info', {
        size: mediaSize,
      });

      const type = attachment.type.toLowerCase();
      const encodedUri = encodeURI(API_files_custom_type(type, attachment.attachmentId));
      let attachmentName = attachment.fileName ? attachment.fileName : ' unnamed';

      const extension = getExtenstion(attachmentName);

      if ((!extension || extension.length === 0) && attachment.mimeType === EFileType.MP4) {
        attachmentName += '.mp4';
      }

      this.promptService.showSuccess(promptText);
      this.downloadService.downloadGET({ url: encodedUri, fileName: attachmentName }).subscribe();
    }
  }

  downloadSingleImage360(mediaItem: TAttachment): void {
    const mediaUrl = API_files_images_size_original(mediaItem.attachmentId);

    this.downloadService.downloadGET({ url: mediaUrl, fileName: mediaItem.fileName }).subscribe();
  }

  deleteImage(): void {
    const index = this.overlay.index;
    const image = this.overlay.elements[index];
    const mediaList = this.attachmentsMediaGalleryService.getMediaList();

    const imageToDelete = mediaList.find((media) => media.attachmentId === image.id);

    this.pointAttachmentsDeleteService
      .deleteAttachment(imageToDelete)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.hide();
        }),
      )
      .subscribe();
  }

  calculateSelectedSize(attachment: TAttachment): string {
    const size = attachment.originalFileSize;
    const totalSizeMB = size / Math.pow(1024, 2);

    return totalSizeMB.toFixed(2);
  }

  openGalleryFromId(): void {
    this.overlay.index = this.overlay.elements.findIndex(
      (element) => element.id === this.attachmentId,
    );

    this.overlayComponent.setData(this._id, this.workspaceId, this.attachmentId);

    if (this.overlay.index > -1) {
      this.show(this.overlay.index, this.items);
    }
  }

  getUrl(id?: string): (string | { outlets: { full: string[] } })[] {
    const _id = this.activeService.getActivePointId();
    const url = this.router.url;
    const isOverview = url.search(EWorkspaces.OVERVIEW) !== -1;
    const isTimeline = url.search(EWorkspaces.TIMELINE) !== -1;
    const isReminders = url.search(EWorkspaces.REMINDERS) !== -1;

    const isHalfModal = this.pointHalfModalService.getModal().visible;

    this.point = this.pointsService.findPoint(_id);

    if (!this.point) {
      return null;
    } else {
      if (!isOverview && !isTimeline && !isReminders) {
        if (!isHalfModal) {
          if (id) {
            return [
              '/site',
              this.point.workspaceRef.id,
              { outlets: { full: ['point', this.point._id, 'gallery', id] } },
            ];
          } else {
            return [
              '/site',
              this.point.workspaceRef.id,
              { outlets: { full: ['point', this.point._id] } },
            ];
          }
        } else {
          if (id) {
            return ['/site', this.point.workspaceRef.id, 'point', this.point._id, 'gallery', id];
          } else {
            return ['/site', this.point.workspaceRef.id, 'point', this.point._id];
          }
        }
      } else if (isOverview) {
        if (!isHalfModal) {
          if (id) {
            return [
              '/site/overview',
              { outlets: { full: ['point', this.point._id, 'gallery', id] } },
            ];
          } else {
            return ['/site/overview', { outlets: { full: ['point', this.point._id] } }];
          }
        } else {
          if (id) {
            return ['/site/overview', 'point', this.point._id, 'gallery', id];
          } else {
            return ['/site/overview', 'point', this.point._id];
          }
        }
      } else if (isTimeline) {
        if (!isHalfModal) {
          if (id) {
            return [
              '/site/timeline',
              { outlets: { full: ['point', this.point._id, 'gallery', id] } },
            ];
          } else {
            return ['/site/timeline', { outlets: { full: ['point', this.point._id] } }];
          }
        } else {
          if (id) {
            return ['/site/timeline', 'point', this.point._id, 'gallery', id];
          } else {
            return ['/site/timeline', 'point', this.point._id];
          }
        }
      } else if (isReminders) {
        if (!isHalfModal) {
          if (id) {
            return ['/reminders', { outlets: { full: ['point', this.point._id, 'gallery', id] } }];
          } else {
            return ['/reminders', { outlets: { full: ['point', this.point._id] } }];
          }
        } else {
          if (id) {
            return ['/reminders', 'point', this.point._id, 'gallery', id];
          } else {
            return ['/reminders', 'point', this.point._id];
          }
        }
      }
    }
  }

  navigateToAttachment(attachmentId: string): void {
    const link = this.getUrl(attachmentId);

    if (link) {
      this.router.navigate(link);
    }
  }

  openGallery({
    _id = '',
    attachmentId = '',
    workspaceId = '',
  }: {
    _id?: string;
    attachmentId?: string;
    workspaceId?: string;
  }): void {
    this.attachmentId = attachmentId;
    this.workspaceId = workspaceId;
    this._id = _id;

    this.openGalleryFromId();
  }

  setOverlayComponent(component: GalleryOverlayComponent): void {
    this.overlayComponent = component;
  }

  updateRotatedImage(oldId: string, newId: string): void {
    if (this.attachmentId === oldId && this.overlay.visible) {
      this.navigateToAttachment(newId);
    }
  }

  setTempRotation(rotation: number): void {
    this.tempRotation.rotation = rotation;
  }

  getTempRotation(): { rotation: number } {
    return this.tempRotation;
  }

  setImageElement(imageElement: HTMLElement): void {
    this.imageElement = imageElement;
  }

  getImageElement(): HTMLElement {
    return this.imageElement;
  }
}
