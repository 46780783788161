<li
  ppTooltip
  [ppTitle]=" 'fleet_management' | ppTranslate "
  class="mainNav__element mainNav__element--with-image"
  [class.mainNav__element--active]="ppRouterPath.startsWith('/fleet-management')"
  [class.mainNav__element--spacing]="ppRemindersEnabled && !ppIsLandscape"
  [class.mainNav__element--no-spacing]="ppIsLandscape"
  [class.mainNav__element--disabled]="ppDisabled"
  [ppOptions]="{ placement: 'right' }"
  (click)="goToFleetManagement()"
>
  <pp-icon
    id="fleetManagementMenuItem"
    data-m-target="Fleet Management icon"
    data-test="fleetManagementMenuItem"
    class="filterPanel__tooltip"
    ppColor="white"
    [ppSrc]="EIconPath.ICON_LEFT_MENU_BOAT_24"
  />
  <h6
    class="filterPanel__title filterPanel__title--img"
    *ngIf="ppIsMobile && !sidePanel.rightPanelExpanded"
  >
    {{ 'fleet_management' | ppTranslate }}
  </h6>
</li>
