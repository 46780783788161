import { TPoint } from '../../../points/points.model';
import { EAdvancedFilterOptions } from '../../models/advanced-filter-options.enum';
import { EAdvancedFilterTimelineDate } from '../../models/advanced-filter-timeline-date.enum';
import { TAdvancedFilterTimeline } from '../../models/advanced-filter.model';
import { filterIdToPointProperty } from '../../utils/filter-id-to-property';

export function filterTimelineAdvanced(point: TPoint, filter: TAdvancedFilterTimeline): boolean {
  const filterValue = filter.value ? filter.value : [null, null];

  const fieldValueString = filterIdToPointProperty(filter.id, point);
  const fieldValue = fieldValueString ? (fieldValueString.split('~') as string[]) : null;
  let fieldValueToFilter: number;
  let date: Date;

  switch (filter.date) {
    case EAdvancedFilterTimelineDate.IS_EMPTY:
      return !fieldValue?.length;
    case EAdvancedFilterTimelineDate.IS_NOT_EMPTY:
      return !!fieldValue?.length;
  }

  if (fieldValue?.length) {
    switch (filter.date) {
      case EAdvancedFilterTimelineDate.START_DATE:
        date = new Date(+fieldValue[0]);
        date.setHours(0, 0, 0, 0);
        fieldValueToFilter = date.getTime();
        break;
      case EAdvancedFilterTimelineDate.END_DATE:
        date = new Date(+fieldValue[1]);
        date.setHours(0, 0, 0, 0);
        fieldValueToFilter = date.getTime();
        break;
    }
  }

  switch (filter.option) {
    case EAdvancedFilterOptions.IS:
      return fieldValueToFilter && fieldValueToFilter === filterValue[0];
    case EAdvancedFilterOptions.IS_BEFORE:
      return fieldValueToFilter && fieldValueToFilter < filterValue[0];
    case EAdvancedFilterOptions.IS_AFTER:
      return fieldValueToFilter && fieldValueToFilter > filterValue[0];
    case EAdvancedFilterOptions.IS_BETWEEN:
      return (
        fieldValueToFilter &&
        fieldValueToFilter >= filterValue[0] &&
        fieldValueToFilter <= filterValue[1]
      );
    case EAdvancedFilterOptions.IS_EMPTY:
      return !fieldValueToFilter;
    case EAdvancedFilterOptions.IS_NOT_EMPTY:
      return !!fieldValueToFilter;
  }
}
