import { Component, Input, OnChanges } from '@angular/core';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { AccountService } from '../../../account/account-service/account.service';
import { AddUsersModalComponent } from '../../../account/account-settings/add-users-modal/add-users-modal.component';
import { TAddUsersModalData } from '../../../account/account-settings/add-users-modal/add-users-modal.consts';
import { checkIfHasRole } from '../../../account/check-if-has-role';
import { AccountSharesService } from '../../../share/account-shares.service';
import { EUserRole } from '../../../share/share-utils/user-roles';
import { WorkspaceService } from '../../../workspace/workspace.service';
import { AccountUserManagementService } from '../account-user-management-service/account-user-management.service';
@Component({
  selector: 'pp-account-user-management-header-buttons',
  templateUrl: './account-user-management-header-buttons.component.html',
  styleUrls: ['./account-user-management-header-buttons.component.scss'],
})
export class AccountUserManagementHeaderButtonsComponent implements OnChanges {
  @Input() ppAccountId: string;

  isAccountAdmin: boolean;

  constructor(
    private modalService: ModalService,
    private accountSharesService: AccountSharesService,
    private accountUserManagementService: AccountUserManagementService,
    private accountService: AccountService,
    private workspaceService: WorkspaceService,
  ) {}

  ngOnChanges(): void {
    this.checkIfAccountAdmin();
  }

  openAddUsersModal(): void {
    this.setAddUsersModalData();

    this.modalService.showModal(AddUsersModalComponent, {
      callback: () => {
        this.accountSharesService.resetAccountShares(this.ppAccountId).subscribe();
      },
    });
  }

  trySetKeyword(keyword: string): void {
    if (keyword === this.accountUserManagementService.getKeyword()) {
      return;
    }

    this.accountUserManagementService.setKeyword(keyword);
  }

  private setAddUsersModalData(): void {
    this.modalService.setData<TAddUsersModalData>({
      accountId: this.ppAccountId,
    });
  }

  private checkIfAccountAdmin(): void {
    const account = this.accountService.getAccount(this.ppAccountId);

    this.isAccountAdmin = checkIfHasRole(
      EUserRole.ACCOUNT_ADMIN,
      account?.workspaces,
      this.workspaceService.getWorkspaces(),
    );
  }
}
