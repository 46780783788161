import { createElement } from 'src/app/core/helpers/dom';
import { getTimelineGroupColumnWidth } from '../resizer-width';
import { GET_TIMELINE } from '../../timeline.ui.store';
import { createTimelineCellElement } from '../body-cells/create-timeline-cell-element';
import { createEmptyMessageNameElement } from './create-empty-message-name-element';

export function createTimelineEmptyMessageElement(): HTMLElement {
  const timeline = GET_TIMELINE();
  const groupWidth = getTimelineGroupColumnWidth();

  const width = timeline?.timelineBody?.virtualScroller.viewportWidth
    ? `${timeline.timelineBody.virtualScroller.viewportWidth}px`
    : groupWidth;

  const nameElement = createElement('div', {
    attrs: {
      class: ['timeline__emptyMessageElement--text'],
    },
    children: [createEmptyMessageNameElement()],
  });

  return createTimelineCellElement({
    className: 'timeline__emptyMessageElement',
    width: width,
    center: true,

    child: nameElement,
  });
}
