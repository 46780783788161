import { Injectable } from '@angular/core';

import { naturalSort } from '../helpers/natural-sort';

@Injectable({
  providedIn: 'root',
})
export class SortingService {
  constructor() {}

  naturalSort(a: string | number, b: string | number): number {
    return naturalSort(a?.toString(), b?.toString());
  }
}
