import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { AccountShareUpdateService } from '../../share/account-share-update.service';
import { TShare, TUpdateShareDTO } from '../../share/share.model';

@Injectable({
  providedIn: 'root',
})
export class UserManagementTableCellsService {
  constructor(
    private usersService: UsersService,
    private accountShareUpdateService: AccountShareUpdateService,
    private promptService: PromptService,
    private translationPipe: TranslationPipe,
  ) {}

  updateShare(userId: string, shareId: string, share: TUpdateShareDTO): Observable<TShare> {
    const user = this.usersService.getUser(userId);

    return this.accountShareUpdateService.updateShare(shareId, share).pipe(
      tap(() => {
        this.promptService.showSuccess(
          this.translationPipe.transform('user_management_permission_changed_prompt', {
            userName: user.userName ? user.userName : user.email,
          }),
        );
      }),
    );
  }
}
