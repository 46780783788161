import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';

import { DeviceService } from '@core/services';
import { TAllUsers } from '@project/view-models';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { ScreenService } from '../../../../core/services/window/screen.service';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { UsersService } from '../users.service';
import { TUsersDropdownData } from './users-dropdown.model';

@Component({
  selector: 'pp-users-dropdown',
  templateUrl: './users-dropdown.component.html',
  styleUrls: ['./users-dropdown.component.scss'],
})
export class UsersDropdownComponent implements OnInit, OnDestroy {
  @ViewChild('list') tableElement: ElementRef;

  dropdown: TDropdown = this.dropdownService.getDropdown();
  users: TAllUsers = {};
  unselectedUserIds: string[];
  disabled: boolean;
  searchBox = '';
  selectableUsers: string[];
  hideExtraOptions = false;
  type = '';
  maxHeight = 300;
  dropdownWidth: string = 'unset';
  isMobile: boolean;
  EIconPath = EIconPath;
  allUsers: string[];
  showToggle: boolean;
  scrolledToBottom = false;

  private destroy$ = new Subject<void>();

  constructor(
    private dropdownService: DropdownService,
    private usersService: UsersService,
    private screenService: ScreenService,
    private deviceService: DeviceService,
  ) {}

  ngOnInit() {
    if (!this.dropdown.data) {
      throw new Error('Data for this dropdown is required');
    }

    if (!this.dropdown.callback) {
      throw new Error('Callback for this dropdown is required');
    }

    this.users = this.usersService.getUsers();
    this.isMobile = this.deviceService.isMobile();
    this.setDataFromDropdown();

    this.dropdownService.dropdownDataChanged$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setDataFromDropdown();
    });

    const top = this.dropdown.dropdownElement.style.top;

    if (top) {
      const insetHeight = top.substring(0, top.indexOf('p'));

      const { innerWidth, innerHeight } = this.screenService.getDimensions();

      const remainingHeight = innerHeight - parseFloat(insetHeight);

      if (remainingHeight > 230 && remainingHeight < 400 && innerWidth > 849) {
        this.maxHeight = remainingHeight - 115;
      }
    }

    if (this.dropdown.data.width) {
      this.dropdownWidth = this.dropdown.data.width;
    }

    if (this.isMobile) {
      this.dropdownWidth = 'unset';
    }
  }

  setDataFromDropdown(): void {
    const dropdownData: TUsersDropdownData = this.dropdown.data;
    this.unselectedUserIds = dropdownData.unselectedUsers?.length
      ? dropdownData.unselectedUsers
      : [];
    this.disabled = dropdownData.disabled;
    this.hideExtraOptions = dropdownData.hideExtraOptions;
    this.type = dropdownData.type;
    this.allUsers = dropdownData.allUsers?.length ? dropdownData.allUsers : [];
    this.showToggle = dropdownData.showToggle;

    this.filterUsers();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  filterUsers(): void {
    if (!this.dropdown.visible) {
      return;
    }

    this.selectableUsers = [];

    this.allUsers.forEach((userId) => {
      if (
        this.users[userId].verified &&
        ((this.users[userId].userName &&
          this.users[userId].userName.toLowerCase().includes(this.searchBox.toLowerCase())) ||
          (this.users[userId].email &&
            this.users[userId].email.toLowerCase().includes(this.searchBox.toLowerCase())))
      ) {
        this.selectableUsers.push(userId);
      }
    });

    this.selectableUsers = this.usersService.sortUsers(this.selectableUsers);

    if (this.tableElement) {
      this.updateScrolledToBottom();
    }
  }

  hideDropdown(): void {
    this.dropdownService.hideDropdown();
  }

  selectUser(userId: string): void {
    this.dropdown.callback(userId);

    if (this.unselectedUserIds.includes(userId)) {
      this.unselectedUserIds = this.unselectedUserIds.filter((user) => user !== userId);
    } else {
      this.unselectedUserIds.push(userId);
    }

    this.filterUsers();
  }

  clearUsers(): void {
    this.dropdown.callback(null);
    this.hideDropdown();
  }

  handleScroll(event: Event): void {
    if (event.target instanceof HTMLElement) {
      this.scrolledToBottom =
        event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
    }
  }

  setSearchBox(keyword: string): void {
    this.searchBox = keyword;

    this.filterUsers();
  }

  updateScrolledToBottom(): void {
    if (
      this.tableElement.nativeElement.scrollHeight === this.tableElement.nativeElement.clientHeight
    ) {
      this.scrolledToBottom = true;
    }
  }
}
