import { EDashletPeriod } from '../../dashboard-timeframes-enums';

export function addCommonStyles(element: HTMLElement): void {
  element.style.marginLeft = 'auto';
  element.style.marginRight = 'auto';
  element.style.color = 'white';
  element.style.textAlign = 'center';
  element.style.position = 'absolute';
  element.style.left = '50%';
  element.style.transform = 'translateX(-50%)';
  element.style.width = '100%';
}

export function getStartDate({ period }: { period: EDashletPeriod }): string {
  let date = new Date();

  switch (period) {
    case EDashletPeriod.DAY:
      return '';
    case EDashletPeriod.WEEK:
      date = getStartOfWeek(date);
      break;
    case EDashletPeriod.LAST_WEEK:
      date.setDate(date.getDate() - 7);

      break;
    case EDashletPeriod.MONTH:
      date = getStartOfMonth(date);

      break;
    case EDashletPeriod.LAST_MONTH:
      date.setDate(date.getDate() - 30);

      break;
    case EDashletPeriod.YEAR:
      date = getStartOfYear(date);

      break;
    case EDashletPeriod.LAST_YEAR:
      date.setDate(date.getDate() - 365);

      break;
  }

  const monthNumber = date.getMonth() + 1;
  const month = monthNumber < 10 ? `0${monthNumber}` : monthNumber;

  if (period === EDashletPeriod.LAST_YEAR) {
    return `${date.getDate()}.${month}.${date.getFullYear()}`;
  } else {
    return `${date.getDate()}.${month}`;
  }
}

export function getEndDate(): string {
  const date = new Date();
  const monthNumber = date.getMonth() + 1;

  const month = monthNumber < 10 ? `0${monthNumber}` : monthNumber;
  return `${date.getDate()}.${month}.${date.getFullYear()}`;
}

function getStartOfWeek(date: Date): Date {
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday

  return new Date(date.setDate(diff));
}

function getStartOfMonth(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

function getStartOfYear(date: Date): Date {
  return new Date(date.getFullYear(), 0, 1);
}
