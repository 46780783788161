import { Component } from '@angular/core';
import { cloneDeep } from 'lodash';
import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { TAccount } from 'src/app/project/modules/account/account.model';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { NewAssetSitePickerExpandingService } from './new-asset-site-picker-expanding.service';
import { NewAssetSitePickerService } from './new-asset-site-picker.service';

@Component({
  selector: 'pp-new-asset-site-picker',
  templateUrl: './new-asset-site-picker.component.html',
  styleUrls: ['./new-asset-site-picker.component.scss'],
})
export class NewAssetSitePickerComponent {
  private accounts: TAccount[] = [];
  filteredAccounts: TAccount[] = [];
  keyword = '';

  constructor(
    private accountService: AccountService,
    private workspaceService: WorkspaceService,
    private customFieldsService: CustomFieldsService,
    private newAssetSitePickerService: NewAssetSitePickerService,
    private newAssetSitePickerExpandingService: NewAssetSitePickerExpandingService,
  ) {
    this.accounts = this.accountService.getAccounts();
    this.newAssetSitePickerService.setKeyword('');
    this.filterAccountsAndWorkspaces();
    this.newAssetSitePickerExpandingService.clearAllExpanded();
  }

  updateSearchBox(keyword: string): void {
    this.keyword = keyword;

    this.filterAccountsAndWorkspaces();
    this.newAssetSitePickerService.setKeyword(keyword);
  }

  private filterAccountsAndWorkspaces(): void {
    const filteredItems: TAccount[] = [];
    const accountsClone = cloneDeep(this.accounts);

    accountsClone.forEach((account) => {
      const accountNameMatch = account.accountName
        .toLowerCase()
        .includes(this.keyword.toLowerCase());

      if (accountNameMatch) {
        filteredItems.push(account);
        return;
      }

      account.workspaces = this.filterAccountWorkspaces(account);

      const accountWorkspacesMatch = account.workspaces.length > 0;

      if (accountWorkspacesMatch) {
        filteredItems.push(account);
      }
    });

    this.filteredAccounts = filteredItems;
  }

  private filterAccountWorkspaces(account: TAccount): string[] {
    return account.workspaces.filter((workspaceId) => {
      const workspace = this.workspaceService.getWorkspace(workspaceId);

      const workspaceNameMatch = workspace.siteName
        .toLowerCase()
        .includes(this.keyword.toLowerCase());

      if (workspaceNameMatch) {
        return true;
      }

      const customFields = this.customFieldsService.getWorkspaceCustomFields(workspaceId);

      for (let customFieldId of Object.keys(customFields)) {
        const customField = customFields[customFieldId];

        const customFieldValueMatch = customField.label
          .toLowerCase()
          .includes(this.keyword.toLowerCase());

        if (customFieldValueMatch) {
          return true;
        }
      }

      return false;
    });
  }
}
