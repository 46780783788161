import { createElement } from 'src/app/core/helpers/dom';
import { transformTimeCF } from 'src/app/project/modules/custom-fields/custom-field-time/custom-field-time-transformer';
import { GET_CUSTOM_FIELDS } from 'src/app/project/modules/custom-fields/custom-fields.store';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { TColumn } from '../../columns/column.model';
import { createMarkedKeywordElement } from '../body-cells-search';
import { createCellElement } from '../createCellElement';

export function createTimeElement(
  _index: number,
  _column: TColumn,
  _customField: TPointCustomField<number | string>,
): HTMLElement {
  const customFields = GET_CUSTOM_FIELDS();
  const customField = customFields[_customField.customFieldTemplateId];

  const showHoursOnly = customField.showHoursOnly;

  let value = _customField.value;

  if (
    _column.customFieldIds &&
    !_column.customFieldIds.includes(_customField.customFieldTemplateId.toString())
  ) {
    value = '';
  }

  const timeValue = transformTimeCF(value, showHoursOnly);

  return createCellElement({
    index: _column.index,
    width: `${_column.width}px`,
    child: createElement('div', {
      attrs: {
        class: 'customSiteTable__text customSiteTable__text--cost',
      },
      children: [createMarkedKeywordElement(timeValue ? timeValue : '')],
    }),
  });
}
