import { GET_COLUMNS } from '../../site-table/columns/columns.store';

import { createElement } from 'src/app/core/helpers/dom';

import AbstractDropdown, {
  TCustomDropdownParams,
} from 'src/app/project/components/dropdown/AbstractDropdown';
import { TColumn } from '../../site-table/columns/column.model';
import { translate } from 'src/app/project/features/translate/translate';

export default abstract class AbstractActionFieldsDropdown extends AbstractDropdown {
  protected columns: TColumn[] = [];

  constructor(dropdownProperties: TCustomDropdownParams, orderedColumns: TColumn[]) {
    super(dropdownProperties);

    this.columns = GET_COLUMNS();

    orderedColumns.forEach((_orderedColumn) => {
      this.columns = this.columns.filter((_column) => _column.name !== _orderedColumn.name);
    });

    this.contentElement = this.createContentElement();

    this.init();
  }

  createContentElement(): HTMLElement {
    return createElement('div', {
      attrs: {
        class: 'actionFieldsDropdown',
      },
      children: [this.createTitleElement(), this.createColumnSectionElement()],
    });
  }

  createTitleElement(): HTMLElement {
    return createElement('div', {
      attrs: {
        class: 'actionFieldsDropdown__title',
      },
      children: [translate('select_a_field')],
    });
  }

  createNoMoreElement(): HTMLElement {
    return createElement('p', {
      attrs: {
        class: 'actionFieldsDropdown__copy',
      },
      children: [translate('no_more_fields_to_choose_from')],
    });
  }

  createColumnSectionElement(): HTMLElement {
    let children = [];

    if (this.columns.length) {
      children = [...this.columns.map((_column) => this.createFieldElement(_column))];
    } else {
      children.push(this.createNoMoreElement());
    }

    this.columnSectionElement = createElement('div', {
      children,
    });

    return this.columnSectionElement;
  }

  abstract createFieldElement(_column: TColumn): HTMLElement;
}
