<div
  #planElement
  data-m-target="Point site plan"
  class="point__site-plan-preview"
  [class.point__site-plan-preview--active]="activePlan.active"
  [class.point__site-plan-preview--full]="ppFull"
  [class.point__site-plan-preview--half]="!ppFull"
  *ngIf="workspaces[this.ppWorkspaceId]?.sitePlan"
  data-test="pointSitePlan"
>
  <div
    class="plan__preview-container"
    (click)="activatePlan($event)"
    (touchend)="onTouchEnd($event)"
  >
    <div class="plan__preview" (ppOnRendered)="onPlanContainerRendered()">
      <pp-plan
        [ppModule]="EPlanModule.POINT"
        [class.plan__preview--active]="activePlan.active"
        [ppWorkspaceId]="ppWorkspaceId"
        [ppPointId]="ppPointId"
        [ppHalfModal]="true"
        [ppCanEdit]="ppCanEdit"
        [ppActive]="activePlan.active"
        *ngIf="planRendered"
      ></pp-plan>
    </div>
  </div>

  <div class="point__site-plan-button-container">
    <pp-button
      ppStyle="primary"
      ppId="saveAccTypeBtn"
      *ngIf="activeButtons && ppPointId !== NEW_POINT_ID && ppCanEdit && !activePlan.drawingPolygon"
      (ppAction)="save()"
      ppSize="small"
      [ppProcess]="updatingPlan"
    >
      {{ noLocation ? 'Confirm' : 'Confirm location' }}
    </pp-button>

    <pp-button
      ppStyle="secondary"
      ppId="saveAccTypeBtn"
      *ngIf="activeButtons && ppPointId !== NEW_POINT_ID && ppCanEdit && !activePlan.drawingPolygon"
      (ppAction)="cancel()"
      ppSize="small"
    >
      {{ 'cancel' | ppTranslate }}
    </pp-button>
  </div>

  <div
    #deleteIcon
    id="deleteIcon"
    class="point__site-plan-delete"
    [class.point__site-plan-delete--visible]="deleteIconVisible"
    (drop)="
      mode === EPlanPointMode.POLYGON || mode === EPlanPointMode.DRAW
        ? deleteVertice(verticeToDelete)
        : deleteFeature()
    "
    (mouseup)="
      mode === EPlanPointMode.POLYGON || mode === EPlanPointMode.DRAW
        ? deleteVertice(verticeToDelete)
        : deleteFeature()
    "
    (dragend)="
      mode === EPlanPointMode.POLYGON || mode === EPlanPointMode.DRAW
        ? deleteVertice(verticeToDelete)
        : deleteFeature()
    "
  >
    <div class="point__site-plan-delete-icon"></div>
  </div>

  <div
    class="point__site-plan-prompt point__site-plan-prompt--long point__site-plan-prompt--pins"
    *ngIf="showingPrompt && ppCanEdit && mode === EPlanPointMode.POINT && activePlan.active"
    (click)="hidePrompt()"
  >
    <h6 class="point__site-plan-prompt-text">
      {{ 'click_or_tap_plan_to_add_pins' | ppTranslate }} <br />
      {{ 'drag_and_drop_to_edit_pin_location' | ppTranslate }}
    </h6>
  </div>

  <div
    class="point__site-plan-prompt point__site-plan-prompt--long"
    *ngIf="showingSaveWarningPrompt && ppCanEdit && activePlan.active"
    (click)="hideSaveWarningPrompt()"
  >
    <h6 class="point__site-plan-prompt-text">
      {{ 'make_sure_closed_area' | ppTranslate }}
    </h6>
  </div>

  <div
    class="point__site-plan-prompt point__site-plan-prompt--long point__site-plan-prompt--area"
    *ngIf="showingPrompt && ppCanEdit && mode === EPlanPointMode.DRAW && activePlan.active"
    (click)="hidePrompt()"
  >
    <h6 class="point__site-plan-prompt-text">
      {{ 'click_or_tap_plan_to_start_area' | ppTranslate }}
    </h6>
  </div>

  <div
    class="point__site-plan-prompt point__site-plan-prompt--long point__site-plan-prompt--info"
    *ngIf="showingPrompt && ppCanEdit && mode === EPlanPointMode.POLYGON && activePlan.active"
    (click)="hidePrompt()"
  >
    <h6 class="point__site-plan-prompt-text">
      {{ 'edit_area_description' | ppTranslate }}
    </h6>
  </div>

  <div
    *ngIf="
      clickedPinCoordinates &&
      clickedPinCoordinates[0] &&
      clickedPinCoordinates[1] &&
      featureClicked.clicked &&
      activePlan.active
    "
    class="pinDropdown"
    [ngStyle]="{
      'top.px': clickedPinCoordinates[1] - 45,
      'left.px': clickedPinCoordinates[0] - 60
    }"
    (click)="deleteFeature(true)"
  >
    <span class="pinDropdown__text"> {{ 'delete_pin' | ppTranslate }} </span>
  </div>

  <div
    *ngIf="
      clickedVertice &&
      clickedVerticeCoordinates[0] &&
      clickedVerticeCoordinates[1] &&
      activePlan.active
    "
    class="pinDropdown"
    [ngStyle]="{ 'top.px': clickedCoordinates[1] - 45, 'left.px': clickedCoordinates[0] - 60 }"
    (click)="deleteVertice()"
  >
    <span class="pinDropdown__text"> {{ 'delete_pin' | ppTranslate }} </span>
  </div>

  <div
    *ngIf="
      polygonClickedCoordinates &&
      polygonClickedCoordinates[0] &&
      polygonClickedCoordinates[1] &&
      polygonClicked &&
      activePlan.active
    "
    class="pinDropdown"
    [ngStyle]="{
      'top.px': polygonClickedCoordinates[1] - 45,
      'left.px': polygonClickedCoordinates[0] - 60
    }"
    (click)="deleteArea()"
  >
    <span class="pinDropdown__text"> {{ 'delete_area' | ppTranslate }} </span>
  </div>

  <div
    class="point__site-plan-prompt point__site-plan-prompt--long point__site-plan-prompt--area"
    *ngIf="activePlan.drawingPolygon && activePlan.active"
    (click)="hidePrompt(); activePlan.drawingPolygon = false"
  >
    <h6 class="point__site-plan-prompt-text">
      {{ 'complete_area_description' | ppTranslate }}
    </h6>
  </div>

  <div class="point__site-plan-mode" *ngIf="activePlan.active && ppCanEdit">
    <div class="point__site-plan-mode-options">
      <div
        ppTooltip
        [ppTitle]="mode === EPlanPointMode.POLYGON ? 'Select an area' : 'Switch to areas'"
        [ppOptions]="{ placement: 'right' }"
        class="point__site-plan-mode-option"
        [class.point__site-plan-mode-option--active]="
          mode === EPlanPointMode.POLYGON || mode === EPlanPointMode.DRAW
        "
        (click)="switchMode($event, EPlanPointMode.DRAW)"
      >
        <div
          class="point__site-plan-mode-option--image point__site-plan-mode-option--image-polygon"
        >
          <pp-icon
            [ppDisplayContents]="true"
            [ppSrc]="EIconPath.ICON_SITE_PLAN_AREA_14"
            [ppColor]="
              mode === EPlanPointMode.POLYGON || mode === EPlanPointMode.DRAW
                ? 'primary-600'
                : 'grey-600'
            "
          ></pp-icon>
        </div>
      </div>

      <div
        ppTooltip
        [ppTitle]="mode === EPlanPointMode.POINT ? 'Drop a pin' : 'Switch to pins'"
        [ppOptions]="{ placement: 'right' }"
        class="point__site-plan-mode-option"
        [class.point__site-plan-mode-option--active]="mode === 'Point'"
        (click)="switchMode($event, EPlanPointMode.POINT)"
      >
        <div class="point__site-plan-mode-option--image point__site-plan-mode-option--image-pin">
          <pp-icon
            [ppDisplayContents]="true"
            [ppSrc]="EIconPath.ICON_SITE_PLAN_PIN_14"
            [ppColor]="mode === EPlanPointMode.POINT ? 'primary-600' : 'grey-600'"
          ></pp-icon>
        </div>
      </div>
    </div>

    <div
      ppTooltip
      ppTitle="Clear all"
      *ngIf="plan.point && (plan.point.polygons[0] || plan.point.pins[0])"
      [ppOptions]="{ placement: 'right' }"
      class="point__site-plan-mode-delete"
      [class.point__site-plan-mode-option--active]="true"
      (click)="clearAll()"
    >
      <img
        class="point__site-plan-mode-option--image point__site-plan-mode-option--image-polygon"
        [src]="EIconPath.INTERACTION_DELETE_ATTACHMENT_ICON"
      />
    </div>
  </div>

  <div
    class="point__site-plan-prompt point__site-plan-prompt--clickable"
    (click)="undoDelete()"
    *ngIf="showUndoDelete"
  >
    <div class="point__prompt-container">
      <h6 class="point__site-plan-prompt-text">{{ 'pin_deleted' | ppTranslate }} -</h6>

      <h6 class="point__site-plan-prompt-text point__site-plan-prompt-text--undo">
        {{ 'undo' | ppTranslate }}
      </h6>
    </div>
  </div>

  <i class="fas fa-check sitePlan__saved-check" aria-hidden="true" *ngIf="locationSaved"></i>
</div>
