import { EWhiteLabelFont } from '../white-label-fonts.enum';

export const DEFAULT_PANEL_BG = '#24292D';
export const DEFAULT_PANEL_FONT = '#2E3B4A';
export const DEFAULT_PANEL_TEXT = '#ffffff';
export const DEFAULT_PANEL_TEXT_HOVER = '#41545d';
export const DEFAULT_PANEL_FONT_HIGHLIGHT = '#0084F8';
export const DEFAULT_PANEL_ACCENT = '#2D3A40';
export const DEFAULT_PRIMARY_50 = '#F5FAFF';
export const DEFAULT_PRIMARY_75 = '#EBF5FF';
export const DEFAULT_PRIMARY_100 = '#CCE6FE';
export const DEFAULT_PRIMARY_200 = '#A3D3FC';
export const DEFAULT_PRIMARY_300 = '#7ABFFB';
export const DEFAULT_PRIMARY_400 = '#52ABFA';
export const DEFAULT_PRIMARY_500 = '#2E9DFF';
export const DEFAULT_PRIMARY_600 = '#0084F8';
export const DEFAULT_PRIMARY_700 = '#006FD0';
export const DEFAULT_PRIMARY_800 = '#005AA9';
export const DEFAULT_PRIMARY_900 = '#004581';
export const DEFAULT_FONT_FAMILY = EWhiteLabelFont.SOURCE_SANS_PRO;
