<pp-modal
  [ppScrollOnDefault]="modal.data.customField.type === ECustomFieldType.LIST"
  [ppWidth]="653"
>
  <ng-container heading> {{ 'edit_custom_field' | ppTranslate }} </ng-container>

  <ng-container body *ngIf="editedCustomField">
    <div>
      <pp-warning-box
        *ngIf="editedCustomField.type === ECustomFieldType.FORMULA"
        ppOpeningMessageKey="formula_update_warning"
      ></pp-warning-box>
      <pp-edit-custom-field-details
        [(ppCustomField)]="editedCustomField"
        (ppListError)="updateListError($event)"
        [ppWorkspaceId]="workspaceId"
        [ppEdit]="true"
      ></pp-edit-custom-field-details>
    </div>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'save' | ppTranslate"
      [ppProcess]="processing"
      (ppCancel)="hideModal()"
      (ppConfirm)="save()"
      [ppDisabled]="!editedCustomField.label"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
