import { Component, Input } from '@angular/core';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventFleetManagement,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { TAssetDetails, TAssetLabel } from '../../../../asset-service/asset.consts';

@Component({
  selector: 'pp-asset-card-information',
  templateUrl: './asset-card-information.component.html',
  styleUrls: ['./asset-card-information.component.scss'],
})
export class assetCardInformationComponent {
  @Input() ppDetails: TAssetDetails;
  @Input() ppLabels: TAssetLabel[];

  isDetailsToggled = true;

  setToggle(isDetailsToggled: boolean) {
    this.isDetailsToggled = isDetailsToggled;
  }

  logLabelsScroll(): void {
    logEventInGTAG(EGoogleEventFleetManagement.ASSET_LABEL_SCROLL, {
      event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
    });
  }
}
