import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PointHeaderService {
  private pointTitle: string;

  setPointTitle(title?: string): void {
    this.pointTitle = typeof title === 'string' ? title : null;
  }

  getPointTitle(): string {
    return this.pointTitle;
  }
}
