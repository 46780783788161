import { EExportData } from './export-data-enum';

export const points_detaillist_data = [
  { name: 'ID', id: EExportData.ID },
  { name: 'Title', id: EExportData.TITLE },
  { name: 'Description', id: EExportData.DESCRIPTION },
  { name: 'Priority', id: EExportData.PRIORITY },
  { name: 'Status', id: EExportData.STATUS },
  { name: 'Tags', id: EExportData.TAGS },
  { name: 'Custom Fields', id: EExportData.CUSTOM_FIELDS },
  { name: 'Exported By', id: EExportData.EXPORTED_BY },
  { name: 'Export Date', id: EExportData.EXPORT_DATE },
  { name: 'Comments', id: EExportData.COMMENTS },
  { name: 'Site Plan', id: EExportData.SITE_MAP },
  { name: 'Assignees', id: EExportData.ASSIGNEES },
  { name: 'Created By', id: EExportData.CREATED_BY },
  { name: 'Created Date', id: EExportData.CREATED_DATE },
  { name: 'Luerssen signature', id: EExportData.LUERSSEN_SIGNATURE },
  { name: 'Images', id: EExportData.IMAGES },
  { name: 'Flags', id: EExportData.SHOW_FLAGS },
];
