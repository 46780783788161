<button
  id="selectAllPermissionsButton"
  data-m-target="Select All Permissions Button"
  class="dropdownItem"
  (click)="selectAll()"
  *ngIf="!isAccountAdminSelected"
>
  <pp-icon
    class="dropdownItem__svgIcon"
    [ppSrc]="EIconPath.ICON_FIELDS_CHECKBOX_18"
    ppColor="grey-600"
    ppClass="setSize18"
  ></pp-icon>

  {{ 'select_all_permissions' | ppTranslate }}
</button>

<button
  id="resendInviteButton"
  data-m-target="Resend Invite Button"
  class="dropdownItem"
  (click)="resendInvite()"
  *ngIf="!user.verified"
>
  <pp-icon
    class="dropdownItem__svgIcon"
    [ppSrc]="EIconPath.ICON_USER_SETTINGS_SEND_MAIL_18"
    ppColor="grey-600"
    ppClass="setSize18"
  ></pp-icon>

  {{ 'resend_invitation' | ppTranslate }}
</button>

<button
  id="upgradeToAccountAdminButton"
  data-m-target="Upgrade to Account Admin Button"
  class="dropdownItem"
  (click)="upgradeToAccountAdmin()"
  *ngIf="!isAccountAdminSelected && !isAccountAdminActive"
>
  <pp-icon
    class="dropdownItem__svgIcon"
    [ppSrc]="EIconPath.ICON_USER_SETTINGS_UPGRADE_18"
    ppColor="grey-600"
    ppClass="setSize18"
  ></pp-icon>

  {{ 'upgrade_to_account_admin' | ppTranslate }}
</button>

<button
  id="downgradeAccountAdminButton"
  data-m-target="Downgrade Account Admin Button"
  class="dropdownItem"
  (click)="downgradeAccountAdmin()"
  *ngIf="isAccountAdminSelected && !isAccountAdminActive"
>
  <pp-icon
    class="dropdownItem__svgIcon dropdownItem__svgIcon--rotate180"
    [ppSrc]="EIconPath.ICON_USER_SETTINGS_UPGRADE_18"
    ppColor="grey-600"
    ppClass="setSize18"
  ></pp-icon>

  {{ 'downgrade_account_admin' | ppTranslate }}
</button>

<button
  id="reset2FAButton"
  data-m-target="Reset 2FA Button"
  class="dropdownItem"
  (click)="reset2FA()"
  *ngIf="accountUser.enabled2fa && !isAccountAdminActive"
>
  <pp-icon
    class="dropdownItem__svgIcon"
    [ppSrc]="EIconPath.ICON_USER_GROUPS_RESET_PW_18"
    ppColor="grey-600"
    ppClass="setSize18"
  ></pp-icon>

  {{ 'reset_2fa' | ppTranslate }}
</button>

<button
  id="deleteUserButton"
  data-m-target="Delete User Button"
  class="dropdownItem dropdownItem--red"
  (click)="deleteUser()"
  *ngIf="!isAccountAdminSelected"
>
  <pp-icon
    class="dropdownItem__svgIcon"
    [ppSrc]="EIconPath.ICON_BIN_18"
    ppClass="setSize18"
    ppColor="inherit"
  ></pp-icon>
  {{ 'remove_from_all_sites' | ppTranslate }}
</button>

<button
  id="deleteAccountAdminUserButton"
  data-m-target="Delete Account Admin User Button"
  class="dropdownItem dropdownItem--red"
  (click)="deleteAccountAdmin()"
  *ngIf="isAccountAdminSelected && !isAccountAdminActive"
>
  <pp-icon
    class="dropdownItem__svgIcon"
    [ppSrc]="EIconPath.ICON_BIN_18"
    ppClass="setSize18"
    ppColor="inherit"
  ></pp-icon>
  {{ 'remove_from_all_sites' | ppTranslate }}
</button>
