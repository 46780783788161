import { cloneDeep } from 'lodash';
import { TColumn } from './column.model';

let sorting: TColumn[] = [];

// GET

export const GET_SORTING = (): TColumn[] => sorting;

// SET & MODIFY

export const SET_SORTING = (_sorting: TColumn[]): void => {
  sorting = _sorting;
};

// CLEAR

export const CLEAR_SORTING = (): void => {
  sorting.length = 0;
};

export const REMOVE_SORT = (column: TColumn): void => {
  sorting = sorting.filter((_column) => _column.index !== column.index);

  sorting.forEach((_column, _index) => {
    _column.groupIndex = _index;
  });
};

export const MOVE_SORT_COLUMN = (columnIndex, destinationIndex): void => {
  const column = cloneDeep(sorting[columnIndex]);

  sorting.splice(columnIndex, 1);
  sorting.splice(destinationIndex, 0, column);

  sorting.forEach((_column, _index) => {
    _column.sortIndex = _index;
  });
};
