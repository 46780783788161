<div
  ppTooltip
  [ppTitle]="ppTooltipTitle | ppTranslate"
  class="notification__check notification__button notification__clear"
  [ngClass]="ppActive ? 'notification__button--read' : 'notification__button--closeUnread'"
  (click)="callback($event)"
  [class.notification__enable-on-hover]="ppEnableOnHover"
  [class.notification__clear--all]="ppClearAll"
  (mouseenter)="onClearHover(true)"
  (mouseleave)="onClearHover(false)"
>
  <img
    class="closeUnread sidePanel__close-notifications-image"
    [src]="EIconPath.INTERACTION_CLOSE_BLUE"
  />
</div>
