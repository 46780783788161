import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { TAccount } from 'src/app/project/modules/account/account.model';
import { TDashlet, TDashletSiteRange } from 'src/app/project/modules/preferences/preferences.model';
import { PermissionsService } from 'src/app/project/modules/share/permissions.service';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EStore } from 'src/app/project/shared/enums/store.enum';

@Component({
  selector: 'pp-dashlet-workspace-select-multiple',
  templateUrl: './dashlet-workspace-select-multiple.component.html',
  styleUrl: './dashlet-workspace-select-multiple.component.scss',
})
export class DashletWorkspaceSelectMultipleComponent implements OnDestroy {
  @Input() ppDashlet: TDashlet;
  @Input() ppDefaultWorkspaces: string[];
  @Input() ppSiteRange: TDashletSiteRange;
  @Input() ppLimitedWorkspaces: boolean;
  @Output() ppSiteRangeChange = new EventEmitter<TDashletSiteRange>();

  accounts: TAccount[] = null;
  filteredAccounts: TAccount[] = null;
  accounts$: Observable<TAccount[]>;
  workspaces$: Observable<TWorkspacesById>;
  workspaces: TWorkspacesById;
  private readonly destroy$ = new Subject<void>();
  disabledWorkspaces: string[] = [];
  EIconPath = EIconPath;

  constructor(
    private store: Store<{ accounts: TAccount[]; workspaces: TWorkspacesById }>,
    private permissionsService: PermissionsService,
  ) {
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces: TWorkspacesById) => {
      this.workspaces = workspaces;
      this.setDisabledWorkspaces();
    });

    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
      this.accounts = accounts;

      this.filterAccounts();
    });
  }

  ngOnChanges(): void {
    if (this.accounts && this.workspaces) {
      this.filterAccounts();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  updateSiteRange(range: TDashletSiteRange): void {
    this.ppSiteRange = range;

    this.ppSiteRangeChange.emit(this.ppSiteRange);
  }

  toggleAccount(accountId: string): void {
    if (this.ppLimitedWorkspaces) {
      return;
    }

    if (this.ppSiteRange.accountId === accountId) {
      this.clearSiteRange();

      return;
    } else {
      this.updateSiteRange({
        workspaceIds: [],
        accountId,
      });
    }
  }

  clearSiteRange(): void {
    if (this.ppLimitedWorkspaces) {
      return;
    }

    this.ppSiteRange = {
      workspaceIds: [],
      accountId: null,
    };

    this.ppSiteRangeChange.emit(this.ppSiteRange);
  }

  private setDisabledWorkspaces(): void {
    this.disabledWorkspaces = [];

    Object.keys(this.workspaces).forEach((workspaceId) => {
      const activitiesEnabled = this.permissionsService.areActivitiesEnabled(workspaceId);
      const canSeeTags = this.permissionsService.getTagPermissions(workspaceId).read;
      const canSeeComments = this.permissionsService.getCommentPermissions(workspaceId).read;

      if ((!activitiesEnabled || !canSeeTags) && !canSeeComments) {
        this.disabledWorkspaces.push(workspaceId);
      }
    });
  }

  private filterAccounts(): void {
    if (!this.ppDefaultWorkspaces) {
      this.filteredAccounts = this.accounts;

      return;
    }

    this.filteredAccounts = this.accounts.filter((account) => {
      const accountWorkspaces = account.workspaces;

      return accountWorkspaces.some((workspaceId) =>
        this.ppDefaultWorkspaces.includes(workspaceId),
      );
    });
  }
}
