import { TPoint } from 'src/app/project/modules/points/points.model';
import { TFilters } from '../site-filter.model';

import { NEW_POINT_ID } from 'src/app/project/shared/constants/point.const';

export function checkCreatedBy(point: TPoint, filters: TFilters): boolean {
  if (point.header.createdBy) {
    const createdBy = point.header.createdBy.id;

    if (point._id === NEW_POINT_ID) {
      return false;
    } else if (!createdBy) {
      return true;
    }

    if (filters.createdBy.appliedValue.length === 0) {
      return true;
    }

    for (const filter of filters.createdBy.appliedValue) {
      if (filter === createdBy) {
        return true;
      }
    }
  }

  return false;
}
