<pp-modal>
  <ng-container heading> {{ 'delete_comment' | ppTranslate }} </ng-container>

  <ng-container body>
    <p>
      {{ 'delete_comment_confirm' | ppTranslate }}
    </p>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'Delete' | ppTranslate"
      (ppCancel)="hideModal()"
      (ppConfirm)="deleteComment()"
      [ppError]="true"
      [ppProcess]="processing"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
