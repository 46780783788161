import { GET_PREFERENCES } from 'src/app/project/modules/preferences/preferences.store';
import { TPoint } from 'src/app/project/modules/points/points.model';
import { GET_WORKSPACES } from '../../../workspace/workspace.store';
import { transformDefaultValue } from './transform-default-value';
import { timelineGroupBy } from './timeline-group-by';
import { TGroupedPoints } from './group-model';

export function groupTimeline(_points: TPoint[]): TGroupedPoints[] {
  const preferences = GET_PREFERENCES();
  const timelineGroup = preferences.timeline?.group ? preferences.timeline.group : null;
  const property =
    timelineGroup === 'SITE' ? 'Site' : timelineGroup === 'ACCOUNT' ? 'Account' : null;

  const groupedPointIndexes = groupTimelinePoints(_points, property);
  groupedPointIndexes.sort((_a, _b) => timelineGroupBy(_a, _b));

  return groupedPointIndexes;
}

function groupTimelinePoints(_points: TPoint[], property: string): TGroupedPoints[] {
  const groupedPointIndexes: TGroupedPoints[] = [];
  const valueIndexes: {
    [key: string]: number;
  } = {};

  _points.forEach((_point, _index) => {
    let value: string = null;

    if (property === 'Site') {
      const workspaces = GET_WORKSPACES();
      const workspace = _point ? workspaces[_point.workspaceRef.id] : null;

      value = workspace?.siteName;
    } else if (property === 'Account') {
      const workspaces = GET_WORKSPACES();
      const workspace = _point ? workspaces[_point.workspaceRef.id] : null;

      value = workspace?.accountName;
    }

    value = transformDefaultValue(value);

    if (!Object.prototype.hasOwnProperty.call(valueIndexes, value)) {
      valueIndexes[value] = groupedPointIndexes.length;
    }

    if (!groupedPointIndexes[valueIndexes[value]]) {
      groupedPointIndexes[valueIndexes[value]] = {
        points: [],
        value,
      };
    }

    groupedPointIndexes[valueIndexes[value]].points.push(_index);
  });

  return groupedPointIndexes;
}
