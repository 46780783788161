<pp-modal>
  <ng-container heading>
    {{ 'reminder' | ppTranslate }}

    <pp-icon
      [ppSrc]="EIconPath.ICON_BADGE_INFO"
      ppTooltip
      class="reminderHeader-info"
      [ppSanitize]="false"
      [ppTitle]="
        '<div style=\'text-align: left\'>' +
        ('create_reminder_description' | ppTranslate) +
        '<br><br>' +
        ('create_reminder_description_2' | ppTranslate) +
        '</div>'
      "
      ppColor="white"
    ></pp-icon>
  </ng-container>

  <ng-container body>
    <pp-datepicker
      [ppDate]="reminderValue"
      (ppSelect)="updateDate($event)"
      [ppMinDate]="allowPastDates ? null : todayDateEpoch"
    ></pp-datepicker>

    <div
      #input
      class="pointCF__input textCF__input reminder__text-input"
      [class.pointCF__input--error]="error"
    >
      <input
        #reminderNameInput
        class="pointCF__input--text"
        [placeholder]="
          _id
            ? ('add_note_to_reminder' | ppTranslate) + ' (' + ('optional' | ppTranslate) + ')'
            : ('add_note_to_reminder' | ppTranslate)
        "
        [(ngModel)]="note"
        (blur)="onFocusChange(false)"
        (focus)="onFocusChange(true)"
        (change)="checkCharacterLimit()"
        (input)="checkError($event)"
        (keydown.enter)="saveReminder()"
        (keyup)="checkKeyup($event)"
      />

      <pp-save-indicator
        [ppUpdating]="false"
        [ppShowClearBtn]="true"
        (ppAction)="clearDescription()"
        [ppFocused]="isFocused"
      ></pp-save-indicator>
    </div>

    <div
      class="richTextComponent__characterLimit reminder__character-limit"
      [class.richTextComponent__characterLimit--green]="characterLimit - note.length >= 0"
      [class.richTextComponent__characterLimit--red]="characterLimit - note.length < 0"
      *ngIf="note && characterLimit - note.length < 50"
    >
      {{ note.length }} / {{ characterLimit }}
    </div>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'save' | ppTranslate"
      (ppCancel)="hideModal()"
      (ppConfirm)="saveReminder()"
      [ppDisabled]="error || (!_id && !note) || !reminderValue"
      [ppProcess]="processing"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
