import { Injectable } from '@angular/core';
import { TFilters } from 'src/app/project/modules/filters/site-filter.model';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { PointsService } from '../points/points.service';
import { PointExportService } from './point-export.service';
import { TExportCustomField } from './utils/export-custom-field';
import { TExportData } from './utils/export-data';
import { TExportModalData } from './utils/export-modal-data';
import { EExportTypeName } from './utils/export-type-names-enum';
import { Observable } from 'rxjs';
import { getCoords } from './utils/get-coords';
import { WorkspaceService } from '../workspace/workspace.service';
import { PlanDataService, TPlanData } from '../plan/plan-data.service';

@Injectable({
  providedIn: 'root',
})
export class PointExportZipService {
  private planData: TPlanData = this.planDataService.getPlan();

  constructor(
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private pointExportService: PointExportService,
    private pointsService: PointsService,
    private workspaceService: WorkspaceService,
    private planDataService: PlanDataService,
  ) {}

  exportZip({
    _id,
    isPdf,
    data,
    exportData,
    filters,
    exportedCustomFields,
  }: {
    _id: string;
    isPdf: boolean;
    data: TExportModalData;
    exportData: TExportData;
    filters: TFilters;
    exportedCustomFields: TExportCustomField[] | string[];
  }): Observable<Response> {
    const customFields = [];
    let pointIds = [];
    const workspaces = this.workspaceService.getWorkspaces();

    if (!_id) {
      pointIds = this.pointsService.getSelectedPointsExport();
    } else {
      pointIds.push(_id);
    }

    exportedCustomFields.forEach((field) => {
      if (field.enabled) {
        customFields.push(field.id);
      }
    });

    const promptText = this.translationPipe.transform(
      pointIds.length > 1 ? 'prompt_export_start' : 'prompt_export_start_single',
    );

    this.promptService.showSuccess(promptText, { duration: 15 });

    const coords = getCoords(workspaces, data.workspaceId, this.planData);

    return this.pointExportService.export({
      workspaceId: data.workspaceId,
      pointIds,
      customFieldIds: customFields,
      exportData: exportData,
      filters: filters,
      isPdf: isPdf,
      exportType: EExportTypeName.ZIP,
      coords,
      isOverview: false,
    });
  }
}
