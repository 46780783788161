export let tileStore: {
  [key: string]: Blob;
} = {};

export function SET_TILES(_tiles: { [key: string]: Blob }): void {
  tileStore = _tiles;
}

export function CLEAR_TILES(): void {
  tileStore = {};
}
