import { Component, Input, OnChanges } from '@angular/core';

import { sanitizeHTML } from 'src/app/core/helpers/text-sanitizer';

@Component({
  selector: 'pp-share-import-tag-list',
  templateUrl: './share-import-tag-list.component.html',
  styleUrls: ['./share-import-tag-list.component.scss'],
})
export class ShareImportTagListComponent implements OnChanges {
  @Input() ppTags: string[];
  @Input() ppDisabled = false;
  @Input() ppLimitedTags = false;

  tagsTooltip = '';

  constructor() {}

  ngOnChanges() {
    if (this.ppTags.length > 2) {
      this.tagsTooltip = `
      <div class="tooltip_tagList">`;

      for (let i = 2; i < this.ppTags.length; i++) {
        this.tagsTooltip +=
          '<span class="sw-tag-sm tooltipTag">' + sanitizeHTML(this.ppTags[i]) + '</span>';
      }

      this.tagsTooltip += `
      </div>
    `;
    }
  }
}
