import { EBasicField } from 'src/app/project/shared/enums/basic-fields-enums';
import { TColumn } from '../../../columns/column.model';
import { GET_TABLE } from '../../../table.ui.store';
import { createGroupedHeaderNameElement } from './create-header--cell-element';
import { getGroupValueById } from '../getGroupValueById';

export function createHeaderRowCellTypeElement(
  index: number,
  column: TColumn,
  groupingLevel: number,
): HTMLElement {
  const table = GET_TABLE();
  const groupValue = getGroupValueById(table.items[index].id);

  switch (column.name) {
    case EBasicField.PRIORITY:
    case EBasicField.STATUS:
    case EBasicField.SEQUENCE_NUMBER:
      return createGroupedHeaderNameElement({
        width: column.width,
        column,
        center: true,
        isGroupedHeader: true,
        index,
        groupingLevel,
      });
    case EBasicField.TITLE:
    case EBasicField.DESCRIPTION:
    case EBasicField.CREATED:
    case EBasicField.UPDATED:
    case EBasicField.ASSIGNEES:
    case EBasicField.TAGS:
    case EBasicField.CREATED_BY:
      return createGroupedHeaderNameElement({
        width: column.width,
        column,
        center: false,
        isGroupedHeader: true,
        index,
        groupingLevel,
      });
    default:
      return createGroupedHeaderNameElement({
        width: column.width,
        column,
        center: false,
        isGroupedHeader: true,
        points: groupValue.pointObjects,
        index,
        groupingLevel,
      });
  }
}
