import { TColumn } from '../columns/column.model';

export function sortBoolean(
  firstValue: boolean,
  secondValue: boolean,
  sortColumns: Partial<TColumn>[],
  index: number,
): number {
  const values = {
    a: firstValue,
    b: secondValue,
  };

  if (
    (values.a === true && (values.b === false || values.b === null)) ||
    (values.a === false && values.b === null)
  ) {
    return sortColumns[index].sortOrder === 'ASC' ? -1 : 1;
  } else if (
    ((values.a === false || values.a === null) && values.b === true) ||
    (values.a === null && values.b === false)
  ) {
    return sortColumns[index].sortOrder === 'ASC' ? 1 : -1;
  }

  return 0;
}
