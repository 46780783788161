import { MINIMUM_WIDTH_FOR_EXPANDED_TIMELINE_ELEMENT } from './timeline-variables';

export function getTimelineBasicElementStyling({
  width,
  _canEdit,
}: {
  width: number;
  _canEdit: boolean;
}): string {
  let classList =
    width > MINIMUM_WIDTH_FOR_EXPANDED_TIMELINE_ELEMENT
      ? 'timeline_fieldElement timeline_fieldElement--long'
      : 'timeline_fieldElement timeline_fieldElement--short';

  if (_canEdit) {
    classList += ' timeline_fieldElement--editable';
  }

  return classList;
}
