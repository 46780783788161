import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { TAutoNumericOptions } from 'src/app/core/helpers/create-autonumeric';
import { buildFormula } from 'src/app/project/components/formula-builder/utils/build-formula';
import { deconstructFormula } from 'src/app/project/components/formula-builder/utils/deconstruct-formula';
import { TFormulaPiece } from 'src/app/project/components/formula-builder/utils/formula-input.type';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { TCurrencyCode, TCurrencyData } from '../currencies/currencies.consts';
import { ECustomFieldType } from '../custom-field-types-enums';
import { getIsContainsSlash } from '../custom-fields-service.utils';
import { TCustomFieldTypeData, customFieldTypesData } from '../custom-fields.data';
import { TCustomField } from '../custom-fields.model';
import { CustomFieldsService, ECustomFieldsEventType } from '../custom-fields.service';

@Component({
  selector: 'pp-edit-custom-field-details',
  templateUrl: './edit-custom-field-details.component.html',
  styleUrls: [
    './edit-custom-field-details.component.scss',
    '../add-custom-field-modal/add-custom-field-modal.component.scss',
  ],
})
export class EditCustomFieldDetailsComponent implements OnChanges, OnInit, OnDestroy {
  @Input() ppCustomField: TCustomField;
  @Input() ppWorkspaceId: string;
  @Input() ppEdit = false;

  @Output() ppCustomFieldChange = new EventEmitter();
  @Output() ppListError = new EventEmitter();
  @Output() ppGoBack = new EventEmitter();

  private readonly destroy$ = new Subject<void>();

  customFieldDisplayData: TCustomFieldTypeData;

  customFieldTypes = ECustomFieldType;
  customFieldTypesData = customFieldTypesData;
  focused = false;
  currency: TCurrencyCode;
  unit: string = null;
  EIconPath = EIconPath;
  currencies: TCurrencyData[] = [];
  formula: TFormulaPiece[] = [];

  sorted = this.customFieldsService.getAllSorted();
  maxListDepthExceeded = false;

  autonumericOptions: TAutoNumericOptions = {
    watchExternalChanges: true,
    modifyValueOnWheel: true,
    minimumValue: '0',
    maximumValue: '4',
    allowDecimalPadding: false,
    decimalPlaces: 0,
    decimalPlacesRawValue: 0,
  };

  private listDepth = 0;
  private maxListDepth = 0;

  constructor(private customFieldsService: CustomFieldsService) {}

  ngOnInit(): void {
    merge(
      this.customFieldsService.depthChange$.pipe(
        tap(() => {
          this.listDepth = this.customFieldsService.getCustomFieldListDepth(
            this.ppCustomField.subList || [],
          );
        }),
      ),
      this.customFieldsService.maxListDepthChange$.pipe(
        tap((depth) => {
          this.maxListDepth = depth;
        }),
      ),
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.checkDepthExceeded();
      });
  }

  ngOnChanges(): void {
    this.customFieldDisplayData = this.customFieldTypesData.find(
      (cf) => cf.value === this.ppCustomField.type,
    );

    this.currency = this.ppCustomField.currencyCode;

    if (
      (this.ppCustomField.type === ECustomFieldType.NUMBERS ||
        this.ppCustomField.type === ECustomFieldType.PERCENTAGE) &&
      !this.ppCustomField.decimalPlaces &&
      this.ppCustomField.decimalPlaces !== 0
    ) {
      this.updateCustomField({
        ...this.ppCustomField,
        decimalPlaces: 0,
      });
    }

    if (this.ppCustomField.type === ECustomFieldType.FORMULA) {
      this.formula = deconstructFormula(this.ppCustomField.formula);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  clearName(): void {
    this.updateCustomField({
      ...this.ppCustomField,
      label: '',
    });
  }

  onInputFocus(): void {
    this.focused = true;
  }

  onInputFocusLost(): void {
    this.focused = false;
  }

  alphabetizeListEntries(): void {
    this.customFieldsService.emit(ECustomFieldsEventType.ALPHABETIZE, this.sorted.sorted);

    this.customFieldsService.setAllSorted(true);
    this.customFieldsService.emit(ECustomFieldsEventType.ALL_SORT);
  }

  blurInput(event: Event): void {
    (event.target as HTMLElement).blur();
  }

  setCurrency(newCurrency: TCurrencyData): void {
    this.currency = newCurrency.currencyCode;

    this.updateCustomField({
      ...this.ppCustomField,
      currencyCode: newCurrency.currencyCode,
    });
  }

  toggleAddComma(): void {
    let showCommas;

    if (typeof this.ppCustomField.showCommas === 'undefined') {
      showCommas = false;
    } else {
      showCommas = !this.ppCustomField.showCommas;
    }

    this.updateCustomField({
      ...this.ppCustomField,
      showCommas,
    });
  }

  toggleShowTotal(): void {
    let showTotal;

    if (this.ppCustomField.showTotal === undefined) {
      showTotal = false;
    } else {
      showTotal = !this.ppCustomField.showTotal;
    }

    this.updateCustomField({
      ...this.ppCustomField,
      showTotal,
    });
  }

  toggleHoursOnly(): void {
    this.updateCustomField({
      ...this.ppCustomField,
      showHoursOnly: !this.ppCustomField.showHoursOnly,
    });
  }

  increaseDecimal(): void {
    let decimalPlaces = this.ppCustomField.decimalPlaces || 0;

    if (+decimalPlaces < 4) {
      decimalPlaces = +decimalPlaces + 1;
    }

    this.updateCustomField({
      ...this.ppCustomField,
      decimalPlaces,
    });
  }

  decreaseDecimal(): void {
    let decimalPlaces = +this.ppCustomField.decimalPlaces - 1;

    if (decimalPlaces < 0) {
      decimalPlaces = 0;
    }

    this.updateCustomField({
      ...this.ppCustomField,
      decimalPlaces,
    });
  }

  checkListCF(): void {
    const slashFound = getIsContainsSlash(this.ppCustomField.subList);

    this.ppListError.emit(slashFound);
  }

  updateCustomField(field: TCustomField): void {
    this.ppCustomFieldChange.emit(field);
  }

  back(): void {
    this.ppGoBack.emit();
  }

  onFormulaChange(formula: TFormulaPiece[]): void {
    const formulaString = buildFormula(formula);

    this.ppCustomField.formula = formulaString;
    this.updateCustomField(this.ppCustomField);
  }

  private checkDepthExceeded(): void {
    this.maxListDepthExceeded = this.listDepth > this.maxListDepth;
  }
}
