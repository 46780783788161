import { GET_PREFERENCES } from 'src/app/project/modules/preferences/preferences.store';
import { ETimelineTimeframe } from '../site-timeline-utils/timeframes-enums';
import { setMonth } from './set-month';
import { setWeek } from './set-week';
import { setDay } from './set-day';
import { getDateRange } from './get-date-range';
import { TTimelineColumns } from './timeline-timeframes';
import { getBaseTimelineDateRange } from './get-base-timeline-date-range';
import { getTimelineYearRangeData } from './get-timeline-year-range-data';

export const GET_TIMELINE_COLUMNS = (): TTimelineColumns => columns;

export let columns: TTimelineColumns = {
  topWidth: 147,
  bottomWidth: 49,

  topElements: [],
  bottomElements: [],
  timeframes: [],
};

export function CALCULATE_COLUMNS(): void {
  const timeframe = GET_PREFERENCES().timeline?.timeframe;
  const [startDate, endDate] = getBaseTimelineDateRange();

  if (timeframe === ETimelineTimeframe.DAY || timeframe === ETimelineTimeframe.WEEK) {
    const [from, to] = getDateRange(timeframe, startDate, endDate);

    switch (timeframe) {
      case ETimelineTimeframe.DAY:
        columns = setDay(from, to);

        break;
      case ETimelineTimeframe.WEEK:
        columns = setWeek(from, to);

        break;
    }

    return;
  }

  // Using ETimelineTimeframe.MONTH by default
  const [startYear, numberOfYears] = getTimelineYearRangeData(timeframe, startDate, endDate);
  columns = setMonth(startYear, numberOfYears);
}

export function CLEAR_TIMELINE_COLUMNS(): void {
  columns = {
    topWidth: 147,
    bottomWidth: 49,

    topElements: [],
    bottomElements: [],
    timeframes: [],
  };
}
