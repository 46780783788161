<div
  class="dashletBody__container"
  #dashletActivities
  (scroll)="onScroll($event)"
  (drag)="onDrag($event)"
  (click)="dashletClicked($event)"
>
  <div class="dashletBody">
    <div class="alert alert-info" *ngIf="activities.length === 0 && fetched">
      {{ 'no_recent_activity' | ppTranslate }}
    </div>

    <ul
      #activityList
      (ppOnRendered)="dashletActivitiesRendered$.next()"
      class="dashletActivities__ul"
      *ngIf="activities.length > 0 && fetched; else placeholderActivities"
    >
      <pp-dashlet-activity
        *ngFor="let activity of activities; let i = index"
        [ppActivity]="activity"
        [ppAllUsers]="allUsers"
        [ppWorkspaces]="workspaces"
      ></pp-dashlet-activity>
    </ul>

    <ng-template #placeholderActivities>
      <pp-dashlet-activities-placeholder *ngIf="!fetched"></pp-dashlet-activities-placeholder>
    </ng-template>

    <div class="dashlet-activities-spinner" *ngIf="ppLoading">
      <app-spinner [ppDashboard]="true"></app-spinner>
    </div>

    <h5
      class="dashlet-activities-loadMore"
      *ngIf="!allActivitiesLoaded && !ppLoading"
      (click)="fetchActivities()"
    >
      {{ 'load_more' | ppTranslate }}
    </h5>
  </div>
</div>
