import { cloneDeep } from 'lodash';

import { Injectable, OnDestroy } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, of } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { ClearUsers, SetUsers } from './users.actions';

import { ResponseErrorService } from '../errors/response-error.service';
import { UserService } from '../user/user.service';

import { WorkspaceApiProviderService } from '@core/api';
import { TAllUsers, TWorkspaceUser, TWorkspaceUserDTO } from '@project/view-models';
import { EStore } from '../../shared/enums/store.enum';
import { SET_USERS } from './users.store';

@Injectable({
  providedIn: 'root',
})
export class UsersService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private users: TAllUsers;
  private users$: Observable<TAllUsers>;
  private fetchUsers$: Observable<TWorkspaceUser[]> = null;

  constructor(
    private store: Store<{
      users: TAllUsers;
    }>,
    private responseErrorService: ResponseErrorService,
    private userService: UserService,
    private workspaceApiProviderService: WorkspaceApiProviderService,
  ) {
    this.users$ = this.store.pipe(select(EStore.USERS));

    this.users$.pipe(takeUntil(this.destroy$)).subscribe((users) => {
      this.users = users;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  fetchWorkspaceUsers(workspaceId: string): Observable<TWorkspaceUserDTO[]> {
    return this.workspaceApiProviderService.fetchWorkspaceUsers(workspaceId).pipe(
      tap((response) => {
        const users: TWorkspaceUser[] = [];

        for (const value of response) {
          const user: TWorkspaceUser = {
            userName: value.name,
            email: value.email,
            userId: value.userId,
            avatarPublicUrl: value.avatarPublicUrl,
            workspaces: [workspaceId],
            verified: value.verified,
            lastActivityEpochMillis: value.lastActivityEpochMillis,
          };

          users.push(user);
        }

        this.setUsers(users);
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  setUsers(users: TWorkspaceUser[]): void {
    const usersToSave = cloneDeep(this.users);

    users.forEach((user) => {
      if (!usersToSave[user.userId]) {
        usersToSave[user.userId] = user;
      }
    });

    this.users = usersToSave;

    SET_USERS(this.users);
    this.store.dispatch(new SetUsers(this.users));
  }

  setAllUsers(users: TWorkspaceUser[]): void {
    const usersToSave = cloneDeep(this.users);

    users.forEach((user) => {
      usersToSave[user.userId] = user;
    });

    this.store.dispatch(new SetUsers(usersToSave));
    SET_USERS(usersToSave);
  }

  getUsers(): TAllUsers {
    return this.users;
  }

  getUser(userId: string): TWorkspaceUser {
    return this.users[userId];
  }

  clearUsers(): void {
    this.store.dispatch(new ClearUsers());
    this.fetchUsers$ = null;
  }

  fetchAllUsers({
    refetch = false,
  }: {
    refetch?: boolean;
  } = {}): Observable<TWorkspaceUser[]> {
    if (!this.fetchUsers$ || refetch) {
      const user = this.userService.getUser();

      if (user.isSuperUser) {
        this.fetchUsers$ = null;

        return of([]);
      }

      this.fetchUsers$ = this.workspaceApiProviderService.fetchAllUsers().pipe(
        tap((users) => {
          this.fetchUsers$ = null;

          this.setAllUsers(users);
        }),
      );
    }

    return this.fetchUsers$;
  }

  sortUsers(users: string[]): string[] {
    const sortedUsers = users.sort((a, b) => {
      if (!this.users[a] || !this.users[a].userName) {
        return -1;
      }

      if (!this.users[b] || !this.users[b].userName) {
        return 1;
      }

      return this.users[a].userName.toLowerCase() > this.users[b].userName.toLowerCase() ? 1 : -1;
    });

    return sortedUsers;
  }
}
