import { cloneDeep } from 'lodash';

export function splitYearIntoWeeks(start: Date, end: Date): Date[][] {
  const weeks = [];
  const current = new Date(start);

  let endReached = false;

  while (!endReached) {
    const startOfTheWeek = cloneDeep(current);
    const endOfTheWeek = new Date(cloneDeep(current).setDate(current.getDate() + 6));

    const element = [startOfTheWeek, endOfTheWeek];

    if (endOfTheWeek >= end) {
      element[1] = end;

      endReached = true;
    }

    current.setDate(current.getDate() + 7);

    weeks.push(element);
  }

  return weeks;
}
