<div class="userManagementUserRow" (click)="toggleExpand()">
  <div class="userManagementUserRow__content">
    <section class="userManagementUserRowSection">
      <div class="userManagementUserRowArrowWrapper">
        <pp-icon
          ppColor="grey-400"
          ppClass="setSize24"
          [ppSrc]="
            ppExpanded ? EIconPath.ICON_NAV_ARROW_DOWN_24 : EIconPath.ICON_NAV_ARROW_RIGHT_24
          "
        ></pp-icon>
      </div>

      <section class="userManagementUserRowUser">
        <div class="userManagementUserRowWrapper">
          <img
            class="userManagementUserRowAvatar"
            [src]="user.avatarPublicUrl ? user.avatarPublicUrl : EIconPath.PLACEHOLDER_USER"
          />

          <section class="userManagementUserRow__details">
            <div class="userManagementUserRow__detailsName">
              {{ user.userName }}
            </div>

            <div
              ppTooltip
              [ppTrimTooltip]="true"
              [ppTitle]="ppUser.email"
              [ppOptions]="{ placement: 'right' }"
              class="userManagementUserRow__detailsEmail"
            >
              {{ ppUser.email }}
            </div>
          </section>
        </div>

        <pp-user-management-user-two-factor-authentication-chip
          [ppTwoFactorAuthenticationEnabled]="ppUser.enabled2fa"
        ></pp-user-management-user-two-factor-authentication-chip>
      </section>

      <pp-user-management-sites-chip
        [ppAccountId]="ppAccountId"
        [ppUser]="ppUser"
      ></pp-user-management-sites-chip>
    </section>

    <section class="userManagementUserRowLogin__wrapper">
      <div class="userManagementUserRowLogin__wrapper">
        {{ 'last_login' | ppTranslate }}:
        {{
          ppUser.lastActivityEpochMillis
            ? (ppUser.lastActivityEpochMillis | ppDate)
            : ('N/A' | ppTranslate)
        }}
      </div>
    </section>
  </div>
</div>
