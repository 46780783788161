<pp-reminder-section
  [ppGroupExpanded]="groupsExpanded.past"
  [ppReminders]="pastReminders"
  [ppGroupedReminders]="groupedReminders"
  [ppFetched]="ppFetched"
  [ppOffline]="ppOffline"
  (ppLoadMore)="loadMorePast()"
  (ppToggle)="toggleGroup('past')"
  ppNameKey="past"
></pp-reminder-section>

<pp-reminder-section
  [ppGroupExpanded]="groupsExpanded.today"
  [ppReminders]="todayReminders"
  [ppGroupedReminders]="groupedReminders"
  [ppFetched]="ppFetched"
  [ppOffline]="ppOffline"
  (ppLoadMore)="loadMoreToday()"
  (ppToggle)="toggleGroup('today')"
  ppNameKey="today"
></pp-reminder-section>

<pp-reminder-section
  [ppGroupExpanded]="groupsExpanded.future"
  [ppReminders]="futureReminders"
  [ppGroupedReminders]="groupedReminders"
  [ppFetched]="ppFetched"
  [ppOffline]="ppOffline"
  (ppLoadMore)="loadMoreFuture()"
  (ppToggle)="toggleGroup('future')"
  ppNameKey="future"
></pp-reminder-section>
