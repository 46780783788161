import Table from './custom-table/table/Table';

const collapsedGroup: Set<string> = new Set();

let table: Table = null;

// GETTERS

export const GET_TABLE = (): Table => table;

export const GET_COLLAPSED_GROUPS = (): Set<string> => collapsedGroup;

// SETTERS

export const ADD_COLLAPSED_GROUP = (id: string): void => {
  collapsedGroup.add(id);
};

export const DELETE_COLLAPSED_GROUP = (id: string): void => {
  collapsedGroup.delete(id);
};

export const SET_TABLE = (_table: Table): void => {
  table = _table;
};

// CLEAR

export const CLEAR_TABLE = (): void => {
  table = null;
};

export const CLEAR_COLLAPSED_GROUPS = (): void => {
  collapsedGroup.clear();
};
