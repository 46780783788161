import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  API_workspace,
  API_workspace_S3_credentials,
  API_workspace_account_site,
  API_workspace_all_users,
  API_workspace_archive,
  API_workspace_custom_field,
  API_workspace_custom_field_import,
  API_workspace_customm_fields as API_workspace_custom_fields,
  API_workspace_name,
  API_workspace_site_plan,
  API_workspace_site_plan_import,
  API_workspace_tags,
  API_workspace_unarchive,
  API_workspace_update_cf_list,
  API_workspace_users,
} from '@core/api/paths';
import { ApiService } from '@core/http';
import { TWorkspaceUser, TWorkspaceUserDTO, UserViewModelFactory } from '@project/view-models';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { getAuthHeaders } from 'src/app/core/http/user-auth';
import { TAccountUsageResponse } from 'src/app/project/modules/account/account-usage/account-usage.consts';
import {
  TCustomField,
  TCustomFieldDTO,
} from 'src/app/project/modules/custom-fields/custom-fields.model';
import { CustomFieldModelFactory } from 'src/app/project/modules/custom-fields/utils/custom-field-model-factory';
import { ResponseErrorService } from 'src/app/project/modules/errors/response-error.service';
import { EPlanFormat } from 'src/app/project/modules/site/site-new/site-plan-format/plan-format.enum';
import { TSiteDTO, TSiteResponse } from 'src/app/project/modules/workspace/site-response.model';
import { TAWSCredentialsDTO } from 'src/app/project/view-models/aws-credentials-response-model';
import { TWorkspaceResponse } from 'src/app/project/view-models/workspace-response.model';
import {
  TImportCustomFieldsRequest,
  TNewCustomField,
  TNewSiteRequest,
} from './workspace-requests.model';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceApiProviderService {
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private responseErrorService: ResponseErrorService,
  ) {}

  fetchAllUsers(): Observable<TWorkspaceUser[]> {
    const url = API_workspace_all_users();

    return this.apiService.get<TWorkspaceUserDTO[]>(url).pipe(
      map((dto) => UserViewModelFactory.createFromDTO(dto)),
      catchError((error) => {
        this.responseErrorService.handleRequestError(error);

        return of([]);
      }),
    );
  }

  deleteWorkspace(workspaceId: string): Observable<null> {
    const url = API_workspace(workspaceId);

    return this.apiService.delete<null>(url);
  }

  addCustomField(workspaceId: string, body: TNewCustomField): Observable<TCustomField> {
    const url = API_workspace_custom_fields(workspaceId);

    return this.apiService
      .post<TCustomFieldDTO>(url, body)
      .pipe(map((response) => CustomFieldModelFactory.createFromDTO(response)));
  }

  updateCustomField(workspaceId: string, body: Partial<TCustomField>): Observable<TCustomField> {
    const url = API_workspace_custom_fields(workspaceId);

    return this.apiService
      .put<TCustomFieldDTO>(url, body)
      .pipe(map((response) => CustomFieldModelFactory.createFromDTO(response)));
  }

  deleteCustomField(workspaceId: string, customFieldId: string): Observable<null> {
    const url = API_workspace_custom_field(workspaceId, customFieldId);

    return this.apiService.delete<null>(url);
  }

  getSitePlanCredentials(workspaceId: string): Observable<HttpResponse<TAWSCredentialsDTO>> {
    const url = API_workspace_S3_credentials(workspaceId);

    // FIXME add api Service when observe is implemented

    return this.http.get<TAWSCredentialsDTO>(url, {
      headers: {
        ...getAuthHeaders(),
      },
      observe: 'response',
    });
  }

  importSitePlan(sourceWorkspaceId: string, workspaceId: string): Observable<TSiteResponse> {
    const url = API_workspace_site_plan_import(sourceWorkspaceId, workspaceId);

    return this.apiService.post<TSiteResponse>(url, null);
  }

  updateSiteName(workspaceId: string, body: string): Observable<TSiteResponse> {
    const url = API_workspace_name(workspaceId);

    return this.apiService.post<TSiteResponse>(url, body);
  }

  addTags(workspaceId: string, body: string[]): Observable<TSiteResponse> {
    const url = API_workspace_tags(workspaceId);

    return this.apiService.post<TSiteResponse>(url, body);
  }

  deleteTags(workspaceId: string, body: string[]): Observable<TSiteResponse> {
    const url = API_workspace_tags(workspaceId);

    return this.apiService.delete<TSiteResponse>(url, body);
  }

  importCustomFields(
    workspaceId: string,
    body: TImportCustomFieldsRequest,
  ): Observable<TSiteResponse> {
    const url = API_workspace_custom_field_import(workspaceId);

    return this.apiService.post<TSiteDTO>(url, body).pipe(
      map((response) => {
        const customFields = response.customFields.map((field) =>
          CustomFieldModelFactory.createFromDTO(field),
        );

        return {
          ...response,
          customFields,
        };
      }),
    );
  }

  fetchWorkspace(workspaceId: string): Observable<TWorkspaceResponse> {
    const url = API_workspace(workspaceId);

    return this.apiService.get<TWorkspaceResponse>(url).pipe(
      map((response) => {
        const customFields = response.customFields.map((field) =>
          CustomFieldModelFactory.createFromDTO(field),
        );

        return {
          ...response,
          customFields,
        };
      }),
    );
  }

  reorderCustomFields(workspaceId: string, body: string[]): Observable<TSiteResponse> {
    const url = API_workspace_update_cf_list(workspaceId);

    return this.apiService.post<TSiteResponse>(url, body);
  }

  addSite(accountId: string, body: TNewSiteRequest): Observable<TSiteResponse> {
    const url = API_workspace_account_site(accountId);

    return this.apiService.post<TSiteResponse>(url, body);
  }

  uploadSitePlan(
    workspaceId: string,
    formData: FormData,
    planFormat: EPlanFormat,
  ): Observable<TSiteResponse> {
    const url = API_workspace_site_plan(workspaceId, planFormat);

    return this.apiService.postWithFormData<TSiteResponse>(url, formData);
  }

  fetchWorkspaceUsers(workspaceId: string): Observable<TWorkspaceUserDTO[]> {
    const url = API_workspace_users(workspaceId);

    return this.apiService.get(url);
  }

  archiveSites(workspaceIds: string[]): Observable<TAccountUsageResponse> {
    const url = API_workspace_archive();

    return this.apiService.post<TAccountUsageResponse>(url, workspaceIds);
  }

  unarchiveSites(workspaceIds: string[]): Observable<TAccountUsageResponse> {
    const url = API_workspace_unarchive();

    return this.apiService.post<null>(url, workspaceIds);
  }
}
