import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { SetActiveAccountId, SetActiveWorkspaceId } from '../../../services/active/active.actions';
import { SetBasicFetched } from '../../offline/sync.actions';
import { TUISettings } from '../../ui/ui.model';

import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { PointsFetchingService } from 'src/app/project/modules/points/points-fetching.service';
import { AccountService } from '../../account/account-service/account.service';
import { SidePanelService } from '../../layout/side-panel/side-panel.service';
import { SyncService } from '../../offline/sync.service';
import { EditPointService } from '../../points/point-full-modal/edit-point.service';
import { PreferencesService } from '../../preferences/preferences-service/preferences.service';
import { WorkspaceService } from '../../workspace/workspace.service';
import { SiteDataService, TSiteData } from '../site-data.service';
import { SiteTableColumnsService } from '../site-table/site-table-columns.service';
import { SiteTimelineService } from './site-timeline.service';

import { Title } from '@angular/platform-browser';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { GET_CUSTOM_FIELDS } from 'src/app/project/modules/custom-fields/custom-fields.store';
import { SelectableFiltersService } from 'src/app/project/modules/filters/update-selectable-filters/selectable-filters.service';
import { TSidePanel } from 'src/app/project/modules/layout/side-panel/side-panel.model';
import { EStore } from '../../../shared/enums/store.enum';
import { TAccount } from '../../account/account.model';

@Component({
  selector: 'pp-site-timeline',
  templateUrl: './site-timeline.component.html',
  styleUrls: ['./site-timeline.component.scss'],
})
export class SiteTimelineComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  site: TSiteData = this.siteDataService.getSite();
  sidePanel: TSidePanel = this.sidePanelService.getSidePanel();

  ui$: Observable<TUISettings>;
  ui: TUISettings;
  offline$: Observable<boolean>;
  offline = false;

  dataFetched = false;

  constructor(
    private store: Store<{
      offline: boolean;
      ui: TUISettings;
    }>,
    private siteDataService: SiteDataService,
    private sidePanelService: SidePanelService,
    private workspaceService: WorkspaceService,
    private syncService: SyncService,
    private preferencesService: PreferencesService,
    private accountService: AccountService,
    private siteTableColumnsService: SiteTableColumnsService,
    private sitePointFilterService: SitePointFilterService,
    private editPointService: EditPointService,
    private pointsFetchingService: PointsFetchingService,
    private siteTimelineService: SiteTimelineService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private router: Router,
    private updateSelectableFiltersService: SelectableFiltersService,
    private titleService: Title,
  ) {
    this.ui$ = this.store.pipe(select(EStore.UI));
    this.offline$ = this.store.pipe(select(EStore.OFFLINE));

    this.offline$.pipe(takeUntil(this.destroy$)).subscribe((offline) => {
      this.offline = offline;
    });
  }

  ngOnInit() {
    this.titleService.setTitle('Loading... | Pinpoint Works');

    const workspaces = this.workspaceService.getWorkspaces();
    const accounts = this.accountService.getAccounts();
    this.store.dispatch(new SetActiveAccountId(null));
    this.sidePanelService.initSidePanel({ expandedInitially: false });

    if (!this.offline && (!Array.isArray(accounts) || Object.entries(workspaces).length === 0)) {
      this.store.dispatch(new SetActiveWorkspaceId(null));

      this.syncService
        .firstLoad()
        .pipe(
          takeUntil(this.destroy$),
          tap(() => {
            this.generateWorkspaces();
          }),
        )
        .subscribe();
    } else {
      this.store.dispatch(new SetActiveWorkspaceId(null));

      this.generateWorkspaces();
    }

    this.ui$.pipe(takeUntil(this.destroy$)).subscribe((ui) => {
      this.ui = ui;
    });
  }

  ngOnDestroy() {
    this.siteDataService.clearSite();
    this.editPointService.hideModal();
    this.destroy$.next();
  }

  updateTitle(): void {
    this.titleService.setTitle('Timeline | Pinpoint Works');
  }

  getTimelineAccounts(accounts: TAccount[]): string[] {
    const timelineAccounts = [];

    accounts.forEach((account) => {
      if (account.accountFeatures.timeline) {
        timelineAccounts.push(account.accountId);
      }
    });

    return timelineAccounts;
  }

  getTimelineCustomFields(timelineAccounts: string[]): string[] {
    const timelineCustomFields = [];
    const accounts = this.accountService.getAccounts();
    const workspaces = this.workspaceService.getWorkspaces();
    const customFields = GET_CUSTOM_FIELDS();

    timelineAccounts.forEach((accountId) => {
      const timelineAccount = accounts.find((account) => account.accountId === accountId);

      timelineAccount.workspaces.forEach((workspaceId) => {
        workspaces[workspaceId].customFields.forEach((customFieldId) => {
          if (customFields[customFieldId].type === ECustomFieldType.TIMELINE) {
            timelineCustomFields.push(customFieldId);
          }
        });
      });
    });

    return timelineCustomFields;
  }

  checkTimelineAccess(): boolean {
    return !!this.accountService
      .getAccounts()
      .some((account) => !!account.accountFeatures.timeline);
  }

  redirectFromTimeline(): void {
    const prompt = this.translationPipe.transform('prompt_timeline_access_denied');

    this.promptService.showError(prompt);
    this.router.navigate(['/site']);
  }

  generateTimeline(timelineAccounts: string[], customFields: string[]): void {
    this.updateTitle();
    this.updateSelectableFiltersService.updateSelectableTags();
    this.updateSelectableFiltersService.updateSelectableExcludedTags();
    this.siteTableColumnsService.initAllColumns();

    this.pointsFetchingService
      .fetchAllPoints({
        timeline: true,
        accounts: timelineAccounts,
        customFields,
      })
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.dataFetched = true;

          this.store.dispatch(new SetBasicFetched(true));
          this.siteTimelineService.updateTimelineFilter();
          this.sitePointFilterService.filterPoints();
        }),
      )
      .subscribe();
  }

  generateWorkspaces(): void {
    this.workspaceService
      .generateWorkspaces()
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => this.preferencesService.fetchPreferences()),
        tap(() => {
          const accounts = this.accountService.getAccounts();

          const timelineAccounts = this.getTimelineAccounts(accounts);
          const customFields = this.getTimelineCustomFields(timelineAccounts);
          const timelineEnabled = this.checkTimelineAccess();

          if (!timelineEnabled) {
            this.redirectFromTimeline();
          } else {
            this.generateTimeline(timelineAccounts, customFields);
          }
        }),
      )
      .subscribe();
  }
}
