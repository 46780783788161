import { Component } from '@angular/core';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { getSelectedPoints } from '../../../points/selected-points';
import { CustomTableService } from '../custom-table/custom-table.service';
import { TTableItem } from '../custom-table/table.model';
import { TGroupedPoints } from '../grouping/group-model';
import { SiteTableEventsService } from '../site-table-events.service';
import {
  ADD_COLLAPSED_GROUP,
  DELETE_COLLAPSED_GROUP,
  GET_COLLAPSED_GROUPS,
} from '../table.ui.store';
import { TTableGroupDropdownData } from './table-group-dropdown.model';

@Component({
  selector: 'pp-table-group-dropdown',
  templateUrl: './table-group-dropdown.component.html',
  styleUrls: ['./table-group-dropdown.component.scss'],
})
export class TableGroupDropdownComponent {
  dropdown: TDropdown<TTableGroupDropdownData> = this.dropdownService.getDropdown();
  headerIndex: number;
  headerElement: TTableItem;
  collapsed: boolean;
  allSelected = false;
  private group: TGroupedPoints;

  constructor(
    private dropdownService: DropdownService,
    private siteTableEventsService: SiteTableEventsService,
    private customTableService: CustomTableService,
  ) {}

  ngOnInit() {
    const groupsCollapsed = GET_COLLAPSED_GROUPS();

    if (!this.dropdown.data) {
      throw new Error('Data for this dropdown is required');
    }

    this.headerIndex = this.dropdown.data.index;
    this.headerElement = this.dropdown.data.item;
    this.collapsed = groupsCollapsed.has(this.headerElement.id);
    this.group = this.findGroup(this.customTableService.getTable().groupedPoints);

    if (this.group) {
      this.allSelected = this.group.pointObjects.every((point) =>
        getSelectedPoints().includes(point._id),
      );
    }
  }

  collapseGroup(): void {
    const table = this.customTableService.getTable();

    ADD_COLLAPSED_GROUP(this.headerElement.id);
    table.sortTable();

    this.hide();
  }

  expandGroup(): void {
    const table = this.customTableService.getTable();

    DELETE_COLLAPSED_GROUP(this.headerElement.id);
    table.sortTable();

    this.hide();
  }

  toggleAllPoints(): void {
    const table = this.customTableService.getTable();

    this.group.pointObjects.forEach((point) => {
      if (this.allSelected) {
        table.selectedPoints.delete(point._id);
      } else {
        table.selectedPoints.add(point._id);
      }
    });

    this.siteTableEventsService.selectPoints({
      points: this.group.pointObjects,
      selected: !this.allSelected,
    });

    table.sortTable();
    this.hide();
  }

  private hide(): void {
    this.dropdownService.hideDropdown();
  }

  private findGroup(points: TGroupedPoints[]): TGroupedPoints {
    for (const groupedPoint of points) {
      if (groupedPoint.id === this.headerElement.id) {
        return groupedPoint;
      }

      if (groupedPoint.group) {
        const foundGroup = this.findGroup(groupedPoint.group);

        if (foundGroup) {
          return foundGroup;
        }
      }
    }

    return null;
  }
}
