import * as Sentry from '@sentry/browser';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

import { ErrorHandler, Injectable } from '@angular/core';

import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { environment } from 'src/environments/environment';
import { VERSION } from '../../../environments/version';
import { getSentryEnabled, logErrorInSentry } from '../modules/errors/response-error';
import { EInstance } from '../shared/enums/instances';

enum ESentryEnvironment {
  ASM = 'asm',
  CLONE = 'clone',
  CLONE2 = 'clone2',
  RELEASE = 'release-ecs',
  CLONE3 = 'clone3',
  LIVE = 'live',
  LOCAL = 'local',
  ECS = 'ecs',
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  dsn = 'https://5259db0120e54d55a3f1634a1be8bfc9@sentry.io/1309101';
  ua = window.navigator.userAgent;
  isIE = /MSIE|Trident/.test(this.ua);

  constructor() {
    this.initSentry();
  }

  handleError(error): void {
    if (getSentryEnabled()) {
      logErrorInSentry(error.originalError || error);
      console.error(error.originalError || error);
    }

    throw error;
  }

  private initSentry(): void {
    if (this.isIE) {
      return;
    }

    const url = window.location.host;

    let sentryEnvironment: string = this.getSentryEnvironment(url);

    if (getSentryEnabled()) {
      Sentry.init({
        dsn: this.dsn,
        environment: sentryEnvironment,
        attachStacktrace: true,
        normalizeDepth: 5,
        release: 'front-end_' + sentryEnvironment + '_' + VERSION.version + '_' + VERSION.hash,
        integrations: [
          new TracingIntegrations.BrowserTracing({
            tracingOrigins: [
              EInstance.CLONE,
              EInstance.CLONE2,
              EInstance.RELEASE,
              EInstance.CLONE3,
              EInstance.LIVE,
              EInstance.ASM,
              EInstance.LOCALHOST,
              /^\//,
            ],
          }),
        ],
        tracesSampleRate: 0.2,
        beforeSend(event) {
          const eventErrorStatus = (event?.exception?.values?.[0]?.value as any)?.status;
          const errorStatus = (event?.message as any)?.status;

          const isUnauthorized =
            event.tags?.error_status === EStatusCode.UNAUTHORIZED ||
            errorStatus === EStatusCode.UNAUTHORIZED ||
            eventErrorStatus === EStatusCode.UNAUTHORIZED;

          const isForbidden =
            event.tags?.error_status === EStatusCode.FORBIDDEN ||
            errorStatus === EStatusCode.FORBIDDEN ||
            eventErrorStatus === EStatusCode.FORBIDDEN;

          if (isUnauthorized || isForbidden) {
            return null;
          } else {
            return event;
          }
        },
      });
    }
  }

  private getSentryEnvironment(url: string) {
    let sentryEnvironment: string;

    if (url === EInstance.ASM) {
      sentryEnvironment = ESentryEnvironment.ASM;
    } else if (environment.production) {
      sentryEnvironment = ESentryEnvironment.LIVE;
    } else if (environment.clone) {
      sentryEnvironment = ESentryEnvironment.CLONE;
    } else if (environment.clone2) {
      sentryEnvironment = ESentryEnvironment.CLONE2;
    } else if (environment.clone3) {
      sentryEnvironment = ESentryEnvironment.CLONE3;
    } else if (environment.ecs) {
      sentryEnvironment = ESentryEnvironment.ECS;
    } else if (environment.release) {
      sentryEnvironment = ESentryEnvironment.RELEASE;
    } else {
      sentryEnvironment = ESentryEnvironment.LOCAL;
    }
    return sentryEnvironment;
  }
}
