<div class="errorBox">
  <pp-icon
    [ppSrc]="EIconPath.ICON_BADGE_WARNING_18"
    ppColor="inherit"
    ppClass="setSize18"
  ></pp-icon>

  <section class="errorBox__text">
    {{ ppOpeningMessageKey | ppTranslate }}
    <ul class="errorBox__list">
      <li *ngFor="let error of ppErrors">{{ error }}.</li>
    </ul>
    {{ ppClosingMessageKey | ppTranslate }}
  </section>
</div>
