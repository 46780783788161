import { Injectable } from '@angular/core';
import {
  API_dashboard_activities,
  API_dashboard_cost_comparison,
  API_dashboard_current,
  API_dashboard_events,
  API_dashboard_report,
  API_dashboard_stacked_status,
} from '@core/api/paths';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import {
  TDashletActivityRequestData,
  TDashletCostComparisonRequestData,
  TDashletCostComparisonResponse,
  TDashletEventResponse,
  TDashletLiveResponse,
  TDashletReportResponse,
  TDashletRequestData,
  TDashletStackedResponse,
} from 'src/app/project/modules/dashboard/dashboard.consts';
import { TDashletActivity } from 'src/app/project/modules/dashboard/dashlet/dashlet-activities/dashlet-activity/dashlet-activity.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardApiProviderService {
  constructor(private apiService: ApiService) {}

  fetchEvents(body: TDashletRequestData): Observable<TDashletEventResponse> {
    const url = API_dashboard_events();

    return this.apiService.post<TDashletEventResponse>(url, body);
  }

  fetchActivities(body: TDashletActivityRequestData): Observable<TDashletActivity[]> {
    const url = API_dashboard_activities();

    return this.apiService.post<TDashletActivity[]>(url, body);
  }

  fetchReports(body: TDashletRequestData): Observable<TDashletReportResponse> {
    const url = API_dashboard_report();

    return this.apiService.post<TDashletReportResponse>(url, body);
  }

  fetchCurrentTypeCount(body: TDashletRequestData): Observable<TDashletLiveResponse> {
    const url = API_dashboard_current();

    return this.apiService.post<TDashletLiveResponse>(url, body);
  }

  fetchStackedStatus(body: TDashletRequestData): Observable<TDashletStackedResponse> {
    const url = API_dashboard_stacked_status();

    return this.apiService.post<TDashletStackedResponse>(url, body);
  }

  fetchCostComparison(
    body: TDashletCostComparisonRequestData,
  ): Observable<TDashletCostComparisonResponse> {
    const url = API_dashboard_cost_comparison();

    return this.apiService.post<TDashletCostComparisonResponse>(url, body);
  }
}
