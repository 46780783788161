import { Injectable } from '@angular/core';
import { PointsApiProviderService } from '@core/api';
import { TGuid } from '@core/helpers';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NEW_POINT_ID } from '../../shared/constants/point.const';
import { EAccessField } from '../../shared/enums/access-field.enum';
import { ResponseErrorService } from '../errors/response-error.service';

@Injectable({
  providedIn: 'root',
})
export class PointsUsersService {
  constructor(
    private responseErrorService: ResponseErrorService,
    private pointsApiProviderService: PointsApiProviderService,
  ) {}

  fetchUsersWithAccessToPoint(
    pointId: TGuid,
    areaMentioned: EAccessField,
    customFieldId?: TGuid,
  ): Observable<any> {
    if (pointId === NEW_POINT_ID) {
      return of(undefined);
    }

    return this.pointsApiProviderService
      .fetchUsersWithAccessToPoint(pointId, areaMentioned, customFieldId)
      .pipe(catchError(this.responseErrorService.handleRequestError));
  }
}
