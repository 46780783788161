import { Component, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import {
  EIntegrationStatus,
  TCustomField,
} from 'src/app/project/modules/custom-fields/custom-fields.model';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { EFormulaPieceType, TFormulaPiece } from '../utils/formula-input.type';
import { FORMULA_MAX_CUSTOM_FIELDS, FORMULA_MAX_NESTING_LEVEL } from '../utils/formula.consts';

@Component({
  selector: 'pp-formula-builder-custom-fields',
  templateUrl: './formula-builder-custom-fields.component.html',
  styleUrl: './formula-builder-custom-fields.component.scss',
})
export class FormulaBuilderCustomFieldsComponent implements OnInit, DoCheck {
  @Input() ppWorkspaceId: string;
  @Input() ppFormula: TFormulaPiece[] = [];
  @Input() ppFieldId: string;
  @Output() ppSelectOperator = new EventEmitter<TFormulaPiece>();

  customFields: TFormulaPiece[] = [];
  disabled: boolean = false;
  FORMULA_MAX_NESTING_LEVEL = FORMULA_MAX_NESTING_LEVEL;
  private _previousListLength: number = 0;

  constructor(private customFieldsService: CustomFieldsService) {}

  ngDoCheck(): void {
    if (this.ppFormula && this.ppFormula.length !== this._previousListLength) {
      this._previousListLength = this.ppFormula.length;
      this.checkFormulaCustomFieldsDisabled();
    }
  }

  ngOnInit(): void {
    const customFields = this.customFieldsService.getWorkspaceCustomFields(this.ppWorkspaceId);

    this.customFields = Object.values(customFields)
      .filter((customFields) => {
        return (
          customFields.id !== this.ppFieldId &&
          customFields.display &&
          (customFields.type === ECustomFieldType.COST ||
            customFields.type === ECustomFieldType.NUMBERS ||
            customFields.type === ECustomFieldType.PERCENTAGE ||
            customFields.type === ECustomFieldType.FORMULA)
        );
      })
      .map((customField: TCustomField) => ({
        type: EFormulaPieceType.CUSTOM_FIELD,
        value: customField.id.toString(),
      }));
  }

  addCustomField(customField: TFormulaPiece): void {
    this.ppSelectOperator.emit(customField);
  }

  private checkFormulaCustomFieldsDisabled(): void {
    this.disabled =
      this.ppFormula.filter((piece) => piece.type === EFormulaPieceType.CUSTOM_FIELD).length >=
      FORMULA_MAX_CUSTOM_FIELDS;
  }
}
