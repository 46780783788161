import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { EDropdownWidth, TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { GET_DROPDOWN } from '../../../../components/dropdown/dropdown.ui.store';
import { EIconPath } from '../../../../shared/enums/icons.enum';
import { TColumn } from '../../site-table/columns/column.model';
import { TableSortingService } from '../../site-table/columns/table-sorting.service';
import { CustomTableService } from '../../site-table/custom-table/custom-table.service';
import { SiteOptionsService } from '../site-options.service';
import { SortButtonDropdownComponent } from './sort-button-dropdown/sort-button-dropdown.component';

@Component({
  selector: 'pp-site-options-sort-button',
  templateUrl: './site-options-sort-button.component.html',
  styleUrls: ['./site-options-sort-button.component.scss'],
})
export class SiteOptionsSortButtonComponent implements OnInit {
  @Input() ppDataFetched: boolean;
  @ViewChild('sortDropdown') element: ElementRef;

  buttonHovered: boolean;
  sorting: TColumn[];
  EIconPath = EIconPath;
  sortDropdownButtonId = 'sortDropdownBtn';
  dropdown: TDropdown = this.dropdownService.getDropdown();
  private dropdownWidth = EDropdownWidth.PX_427;

  constructor(
    private siteOptionsService: SiteOptionsService,
    private customTableService: CustomTableService,
    private dropdownService: DropdownService,
    private tableSortingService: TableSortingService,
  ) {}

  ngOnInit(): void {
    this.update({ updateTable: false });

    this.siteOptionsService.setSortButton(this);
  }

  update({
    updateTable,
    closeDropdown,
  }: { updateTable?: boolean; closeDropdown?: boolean } = {}): void {
    const sorting = this.tableSortingService.getSorting();
    const table = this.customTableService.getTable();

    this.sorting = sorting;

    if (table?.tableHead && updateTable) {
      table.tableHead.load(true);

      if (closeDropdown) {
        const sortByDropdown = GET_DROPDOWN('SITE_SORT_BY');

        if (sortByDropdown) {
          sortByDropdown.destroy();
        }
      }
    }
  }

  toggleSortDropdown(): void {
    if (this.dropdown.visible && this.dropdown.buttonId === this.sortDropdownButtonId) {
      this.dropdownService.hideDropdown();
    } else {
      this.dropdownService.setData({});

      this.dropdownService.showDropdown(this.sortDropdownButtonId, SortButtonDropdownComponent, {
        onClose: () => this.hideDropdown(),
        popper: {
          positionFixed: true,
          placement: 'bottom-start',
        },
        width: this.dropdownWidth,
      });
    }
  }

  hideDropdown(): void {
    this.dropdown.visible = false;
    this.update();
  }
}
