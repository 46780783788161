import { Component, Input } from '@angular/core';
import { ResetTableService } from '../../reset-table.service';

@Component({
  selector: 'pp-reset-table-button',
  templateUrl: './reset-table-button.component.html',
  styleUrls: ['./reset-table-button.component.scss'],
})
export class ResetTableButtonComponent {
  @Input() ppWorkspaceId: string;

  constructor(private resetTableService: ResetTableService) {}

  resetTable(): void {
    this.resetTableService.resetTable(this.ppWorkspaceId);
  }
}
