import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TCustomFieldFilter } from 'src/app/project/modules/filters/site-filter.model';
import { blurInput } from 'src/app/core/helpers/blur-input';

@Component({
  selector: 'pp-filter-field-checkbox',
  templateUrl: './filter-field-checkbox.component.html',
  styleUrls: ['../../../site-filter-dropdown/site-filter-dropdown.component.scss'],
})
export class FilterFieldCheckboxComponent {
  @Input() ppField: TCustomFieldFilter;
  @Output() ppApply = new EventEmitter();

  constructor() {}

  updateCheckboxCustomField(field: TCustomFieldFilter): void {
    field.value = !field.value;

    field.excluded = false;

    this.applyFilters();
  }

  blurInput(event: Event): void {
    blurInput(event.target);
  }

  applyFilters(): void {
    this.ppApply.emit();
  }
}
