import { TSelectOption } from 'src/app/project/components/input/select-tag/select-tag.model';
import { translate } from 'src/app/project/features/translate/translate';
import { EUserRole } from '../../share-utils/user-roles';

export function SHARE_ROLE_OPTIONS(): TSelectOption[] {
  const shareRoleOptions: TSelectOption[] = [
    {
      label: translate('account_admin'),
      value: EUserRole.ACCOUNT_ADMIN,
    },
    {
      label: translate('admin'),
      value: EUserRole.SITE_ADMIN,
    },
    {
      label: translate('normal'),
      value: EUserRole.NORMAL,
    },
    {
      label: translate('guest'),
      value: EUserRole.LIMITED,
    },
  ];

  return shareRoleOptions;
}

export function getUserRole(access: EUserRole): string {
  return SHARE_ROLE_OPTIONS().find((option) => option.value === access).label;
}
