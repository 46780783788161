<div
  #planElement
  id="container{{ ppModule }}"
  class="sitePlan"
  [class.sitePlan--border]="!ppHalfModal"
>
  <div
    class="sitePlan__placeholder-wrapper"
    [class.sitePlan__placeholder-wrapper--hidden]="!loadingPlan"
  >
    <div class="sitePlan__placeholder-content">
      <img [src]="EIconPath.PLACEHOLDER_MAP" alt="" />
    </div>
  </div>
</div>
