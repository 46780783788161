import { Action } from '@ngrx/store';
import { TAccountFolder } from '../../view-models/account-simple-response.model';

import { TAccount } from './account.model';

export enum EActionTypes {
  SET_ACCOUNTS = '[Accounts] Set',
  CLEAR_ACCOUNTS = '[Accounts] Clear',
  UPDATE_ACCOUNT_NAME = '[Accounts] Update account name',
  UPDATE_ACCOUNT_WEBSITE_URL = '[Accounts] Update account website url',
  SET_DEFAULT_ACCOUNT_FEATURES = '[Accounts] Set default account features',
  SET_ACCOUNT = '[Accounts] Set Account',
  UPDATE_ACCOUNT_FOLDERS = '[Accounts] Update account folders',
  UPDATE_ACCOUNT = '[Accounts] Update Account',
  UPDATE_ACCOUNT_LOGO = '[Accounts] Update account logo',
}

export class SetAccounts implements Action {
  readonly type = EActionTypes.SET_ACCOUNTS;
  constructor(readonly payload: TAccount[]) {}
}

export class UpdateAccount implements Action {
  readonly type = EActionTypes.UPDATE_ACCOUNT;
  constructor(
    readonly payload: {
      accountId: TAccount['accountId'];
      account: TAccount;
    },
  ) {}
}

export class SetAccount implements Action {
  readonly type = EActionTypes.SET_ACCOUNT;
  constructor(readonly payload: TAccount) {}
}

export class ClearAccounts implements Action {
  readonly type = EActionTypes.CLEAR_ACCOUNTS;
  constructor() {}
}

export class UpdateAccountName implements Action {
  readonly type = EActionTypes.UPDATE_ACCOUNT_NAME;
  constructor(
    readonly payload: {
      accountId: TAccount['accountId'];
      accountName: TAccount['accountName'];
    },
  ) {}
}
export class UpdateAccountWebsiteUrl implements Action {
  readonly type = EActionTypes.UPDATE_ACCOUNT_WEBSITE_URL;
  constructor(
    readonly payload: {
      accountId: TAccount['accountId'];
      websiteUrl: TAccount['websiteUrl'];
    },
  ) {}
}

export class SetDefaultAccountFeatures implements Action {
  readonly type = EActionTypes.SET_DEFAULT_ACCOUNT_FEATURES;
  constructor(
    readonly payload: {
      accountId: TAccount['accountId'];
    },
  ) {}
}

export class UpdateAccountStructure implements Action {
  readonly type = EActionTypes.UPDATE_ACCOUNT_FOLDERS;
  constructor(
    readonly payload: {
      accountId: TAccount['accountId'];
      accountFolders: TAccountFolder[];
    },
  ) {}
}

export class UpdateAccountLogo implements Action {
  readonly type = EActionTypes.UPDATE_ACCOUNT_LOGO;
  constructor(
    readonly payload: {
      accountId: TAccount['accountId'];
      logoId: string;
    },
  ) {}
}
export type ActionsUnion =
  | SetAccounts
  | ClearAccounts
  | UpdateAccountName
  | UpdateAccountWebsiteUrl
  | SetDefaultAccountFeatures
  | SetAccount
  | UpdateAccountStructure
  | UpdateAccount
  | UpdateAccountLogo;
