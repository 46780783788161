import { Component, Input, OnDestroy } from '@angular/core';
import { TWhiteLabelStore } from '../../../../white-label/white-label.model';
import { SidePanelUtilsService } from '../side-panel-utils.service';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { EStore } from '../../../../../shared/enums/store.enum';
import { takeUntil } from 'rxjs/operators';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-side-panel-logo',
  templateUrl: './side-panel-logo.component.html',
  styleUrls: ['./side-panel-logo.component.scss'],
})
export class SidePanelLogoComponent implements OnDestroy {
  @Input() ppMobile: boolean;

  EIconPath = EIconPath;
  whiteLabel$: Observable<TWhiteLabelStore>;
  whiteLabel: TWhiteLabelStore;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store<{
      whiteLabel: TWhiteLabelStore;
    }>,

    private sidePanelUtilsService: SidePanelUtilsService,
  ) {
    this.whiteLabel$ = this.store.pipe(select(EStore.WHITE_LABEL));

    this.whiteLabel$.pipe(takeUntil(this.destroy$)).subscribe((whiteLabel) => {
      this.whiteLabel = whiteLabel;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  collapsePanel(): void {
    this.sidePanelUtilsService.collapsePanel();
  }
}
