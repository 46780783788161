import { Component, Input, OnChanges } from '@angular/core';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { EIntegrationStatus } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { GET_CUSTOM_FIELDS } from 'src/app/project/modules/custom-fields/custom-fields.store';
import { EIconPath } from '../../../../../../../shared/enums/icons.enum';
import { TActivityChange } from '../../activity-change.model';

@Component({
  selector: 'pp-dashlet-activity-custom-field',
  templateUrl: './dashlet-activity-custom-field.component.html',
  styleUrls: ['../../dashlet-activity.component.scss'],
})
export class DashletActivityCustomFieldComponent implements OnChanges {
  @Input() ppChange: TActivityChange<string>;

  customFieldTypes = ECustomFieldType;
  EIntegrationStatus = EIntegrationStatus;
  EIconPath = EIconPath;
  type: ECustomFieldType | EIntegrationStatus;

  ngOnChanges(): void {
    const customFields = GET_CUSTOM_FIELDS();
    const customFieldId = this.ppChange.cfTemplateId.toString();

    if (customFieldId) {
      const customField = customFields[customFieldId];

      if (customField.volyIntegrationActive === EIntegrationStatus.ON) {
        this.type = EIntegrationStatus.ON;
      } else if (customField.volyIntegrationActive === EIntegrationStatus.DISABLED) {
        this.type = EIntegrationStatus.DISABLED;
      } else {
        this.type = customField.type;
      }
    }
  }
}
