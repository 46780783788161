import { Injectable } from '@angular/core';
import { compareColumns } from '../preferences/preferences-service/compare-columns';
import { TColumn } from '../site/site-table/columns/column.model';
import { EDefaultColumnWidths } from '../site/site-table/columns/default-column-widths-enum';

@Injectable({
  providedIn: 'root',
})
export class CompareColumnsWithSavedViewService {
  constructor() {}

  compareColumns(savedViewColumns: TColumn[], tableColumns: TColumn[]): boolean {
    let match = true;

    for (let i = 0; i < tableColumns.length; i++) {
      const column = tableColumns[i];
      const viewColumn = savedViewColumns.find(
        (savedViewColumn) => savedViewColumn.name === column.name,
      );

      if (!viewColumn) {
        // Check if column is default
        match =
          column.width === EDefaultColumnWidths.CUSTOM_FIELD &&
          !column.hidden &&
          !column.groupIndex &&
          !column.sortIndex &&
          !column.groupOrder &&
          !column.sortOrder;
      } else {
        match = compareColumns([viewColumn], [column]);
      }

      if (!match) {
        return false;
      }
    }

    return match;
  }
}
