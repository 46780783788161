import { TWorkspace, TWorkspacesById } from './workspace.model';

let workspaces: TWorkspacesById = {};

export const GET_WORKSPACE = (workspaceId: string): TWorkspace => workspaces[workspaceId];

export const GET_WORKSPACES = (): TWorkspacesById => workspaces;

export const SET_WORKSPACES = (_workspaces: TWorkspacesById): void => {
  workspaces = _workspaces;
};

export const CLEAR_WORKSPACES = (): void => {
  workspaces = {};
};
