import { TPoint } from '../../../points/points.model';
import { EAdvancedFilterOptions } from '../../models/advanced-filter-options.enum';
import { TAdvancedFilterLocation } from '../../models/advanced-filter.model';
import { filterIdToPointProperty } from '../../utils/filter-id-to-property';

export function filterLocationAdvanced(point: TPoint, filter: TAdvancedFilterLocation): boolean {
  const fieldValue = filterIdToPointProperty(filter.id, point) as boolean;

  switch (filter.option) {
    case EAdvancedFilterOptions.IS_EMPTY:
      return fieldValue;
    case EAdvancedFilterOptions.IS_NOT_EMPTY:
      return !fieldValue;
  }
}
