import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  TAdvancedFilterPriority,
  TAdvancedFilterSingle,
  TFilterPriorityOptions,
} from '../../../../models/advanced-filter.model';
import { EPriority } from 'src/app/project/modules/points/priorities';
import {
  getAdvancedPriorityOptions,
  getAdvancedPriorityValues,
} from './advanced-filter-priority-utils';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';

@Component({
  selector: 'pp-advanced-filter-priority',
  templateUrl: './advanced-filter-priority.component.html',
  styleUrls: ['./advanced-filter-priority.component.scss'],
})
export class AdvancedFilterPriorityComponent {
  @Input() ppFilter: TAdvancedFilterSingle; // If we do TAdvancedFilterPriority it will throw ESLINT error in HTML
  @Input() ppIndex: number;
  @Output() ppFilterChange = new EventEmitter<TAdvancedFilterSingle>();

  value: EPriority[];
  option: TFilterPriorityOptions;
  filter: TAdvancedFilterPriority;

  ADVANCED_FILTER_PRIORITY_OPTIONS = getAdvancedPriorityOptions();
  ADVANCED_FILTER_PRIORITY_VALUES = getAdvancedPriorityValues();
  EAdvancedFilterOptions = EAdvancedFilterOptions;

  ngOnChanges(): void {
    this.filter = this.ppFilter as TAdvancedFilterPriority;

    this.value = this.filter.value ? this.filter.value : [];
    this.option = this.filter.option;

    this.ADVANCED_FILTER_PRIORITY_VALUES.forEach((priority) => {
      priority.selected = this.filter.value?.includes(priority.value as EPriority);
    });
  }

  changeFieldCondition(newCondition: TFilterPriorityOptions): void {
    if (
      (newCondition === EAdvancedFilterOptions.IS ||
        newCondition === EAdvancedFilterOptions.IS_NOT) &&
      this.filter.value?.length
    ) {
      this.filter.value.length = 1;
      this.value = this.filter.value;

      this.ADVANCED_FILTER_PRIORITY_VALUES.forEach((priority) => {
        priority.selected = this.filter.value?.includes(priority.value as EPriority);
      });
    }

    this.ppFilter.option = newCondition;
    this.option = newCondition;
    this.applyChanges();
  }

  changeFieldValue(newValue: EPriority): void {
    if (this.filter.value?.length) {
      this.ppFilter.value[0] = newValue;
    } else {
      this.ppFilter.value = [newValue];
    }

    this.applyChanges();
  }

  changeFieldValueMultiple(): void {
    this.filter.value = this.ADVANCED_FILTER_PRIORITY_VALUES.filter((value) => value.selected).map(
      (value) => value.value,
    ) as EPriority[];

    this.applyChanges();
  }

  applyChanges(): void {
    this.ppFilterChange.emit(this.ppFilter);
  }
}
