export function getTimeZone(): string {
  const date = new Date();
  const offset = date.getTimezoneOffset();
  const formattedOffset = offset / -60;

  let timeZone = 'GMT';

  if (formattedOffset > 0) {
    timeZone += `+${formattedOffset}`;
  } else if (formattedOffset < 0) {
    timeZone += `${formattedOffset}`;
  }

  return timeZone;
}
