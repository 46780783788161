import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EGoogleEventLabel } from 'src/app/project/services/analytics/google-analytics.consts';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TCustomFieldAccessOption, TShare } from '../../../share.model';
import { EAccessParameter } from '../access-parameters';
import { ShareEditService } from '../share-edit.service';

@Component({
  selector: 'pp-share-edit-activity',
  templateUrl: './share-edit-activity.component.html',
  styleUrls: ['./share-edit-activity.component.scss'],
})
export class ShareEditActivityComponent {
  @Input() ppShare: TShare;
  @Input() ppWorkspaceId: string;
  @Output() ppChange = new EventEmitter<void>();
  @Output() ppShareChange = new EventEmitter<TShare>();

  EIconPath = EIconPath;
  EGoogleEventLabel = EGoogleEventLabel;
  accessParameters = EAccessParameter;

  timelineDisabled: boolean;

  constructor(private shareEditService: ShareEditService) {}

  ngOnChanges(): void {
    this.timelineDisabled = !this.ppShare.advancedAccessLevels.tags.permission.read;
  }

  correctProperty(
    property: EGoogleEventLabel,
    parameter?: EAccessParameter,
    field?: TCustomFieldAccessOption,
  ): void {
    this.ppShare = this.shareEditService.correctProperty({
      workspaceId: this.ppWorkspaceId,
      share: this.ppShare,
      property,
      parameter,
      field,
    });

    this.ppChange.emit();
  }
}
