import { AccountService } from '../../../account/account-service/account.service';
import { TAccount } from '../../../account/account.model';
import { TFilters } from '../../../filters/site-filter.model';
import { TWorkspacesById } from '../../../workspace/workspace.model';
import { generateSiteWorkspaceFilters } from './generate-site-workspace-filters';
import { removeMissingAccounts } from './remove-missing-accounts';

export function createSitesFilter(
  accounts: TAccount[],
  filters: TFilters,
  workspaces: TWorkspacesById,
): void {
  accounts.forEach((account) => {
    const existingAccount = AccountService.findMatchingAccount(account, filters);

    if (!existingAccount) {
      filters.sites.push({
        accountName: account.accountName,
        value: true,
        appliedValue: true,
        default: true,
        workspaces: [],
      });
    }

    generateSiteWorkspaceFilters(account, filters, workspaces);
  });

  removeMissingAccounts(filters, accounts);
}
