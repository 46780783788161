<div #columnGroupOrderButton class="select groupOrderSelect" [class.select--inputFocused]="dropdown" (click)="action()">
  <span class="groupOrder-content">
    <pp-icon
      *ngIf="getOrder() || ppOrder"
      [ppSrc]="!getOrder() ? null : getOrder() === 'ASC' ? EIconPath.ICON_MISC_A_Z_18 : EIconPath.ICON_MISC_Z_A_18"
      ppClass="setSize14"
    ></pp-icon>
    {{ getOrder() ? transformOrder(getOrder()) : '' }} {{ ppOrder ? transformOrder(ppOrder) : '' }}
    <span class="groupOrder--placeholder" *ngIf="!getOrder() && !ppOrder">{{'select_option' | ppTranslate}}</span>
  </span>

  <pp-icon [ppSrc]="dropdown ? EIconPath.ICON_MISC_UP_ARROW_18 : EIconPath.ICON_MISC_DOWN_ARROW_18" ppClass="setSize18" ppColor="grey-400"/>
</div>
