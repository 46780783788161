<ul class="dashletSiteacc__site-ul">
  <ng-container
    *ngFor="let workspace of workspaceList; let index = index; trackBy: trackByFunction"
  >
    <pp-dashlet-timeline-single-workspace
      *ngIf="!workspace.blocked"
      [ppLimitedWorkspaces]="ppLimitedWorkspaces"
      [ppWorkspace]="ppWorkspaces[workspace.workspaceId]"
      [ppSiteRange]="ppSiteRange"
      (ppToggleWorkspace)="toggleWorkspace($event)"
    ></pp-dashlet-timeline-single-workspace>

    <li class="dashletSiteacc__site--disabled" *ngIf="workspace.blocked">
      <pp-checkbox [ppChecked]="false" [ppDisabled]="true"></pp-checkbox>

      <p class="dashletSiteacc__site-label">
        {{ ppWorkspaces[workspace.workspaceId]?.siteName }}
      </p>
    </li>
  </ng-container>
</ul>
