<div class="fleetStatusInput__wrapper">
  <div class="fleetStatusInput__innerWrapper">
    <pp-status-button [ppStatus]="ppStatus" [ppDisabled]="true"></pp-status-button>

    <div class="fleetStatusInput">
      <input
        class="fleetStatusInput__input"
        type="number"
        pattern="/^[0-9]+(\.[0-9]{1,2})?$/"
        step="1"
        min="0"
        max="100"
        [data-test]="'status-' + ppStatus + '-Input'"
        [(ngModel)]="ppWeight"
        (keypress)="preventNonNumberInput($event)"
        (input)="onInput($event)"
        (ngModelChange)="onWeightChange($event)"
        (change)="preventEmptyInputValue($event)"
      />
      <div class="fleetStatusInput__unit">%</div>
    </div>
  </div>
</div>
