import { GET_GROUPING } from '../../../../columns/grouping.store';
import { TTableItem } from '../../../../custom-table/table.model';

export function getClassList(
  groupsCollapsed: Set<string>,
  item: TTableItem,
  groupLevel: number,
): string {
  const grouping = GET_GROUPING();
  let classList = 'table__row table__row--header';

  if (groupsCollapsed.has(item.id)) {
    classList += ' table_row--collapsed';
  }

  if (groupLevel) {
    if (grouping.length !== 1) {
      if (groupLevel === 2 && grouping.length === 2) {
        classList += ` table__row--header-3`;
      } else {
        classList += ` table__row--header-${groupLevel}`;
      }

      classList += ` table__row--header-${groupLevel}-margin`;

      if (groupLevel === 3) {
        classList += ' table__row--header-3--border';
      } else if (groupLevel === 2) {
        if (groupsCollapsed.has(item.id)) {
          classList += ' table__row--header-2--border';
        }
      } else {
        if (groupsCollapsed.has(item.id)) {
          classList += ' table__row--header-1--border';
        }
      }
    } else {
      classList += ' table__row--header-1-margin';
      classList += ' table__row--header-3';
    }
  }
  return classList;
}
