import { environment } from 'src/environments/environment';
import {
  TGoogleConfigDetails,
  TGoogleEventParams,
  TGoogleTagEventsUnion,
} from './google-analytics.consts';

declare let gtag: Function;

export function logEventInGTAG(
  eventName: TGoogleTagEventsUnion,
  eventParams: TGoogleEventParams,
): void {
  if (environment?.production) {
    gtag('event', eventName, eventParams);
  } else {
    logEventInConsole(eventName, eventParams);
  }
}

export function configGTAG(code: string, configParams: TGoogleConfigDetails): void {
  gtag('config', code, {
    page_path: configParams.path,
    app_version: configParams.version,
    accessLevel: configParams.shareOption,
    industry_type: configParams.industryType,
    account_type: configParams.accountType,
  });
}

function logEventInConsole(
  eventName: TGoogleTagEventsUnion,
  eventDetails: TGoogleEventParams,
): void {
  console.groupCollapsed(eventName);
  console.log('Event details:', eventDetails);
  console.groupEnd();
}
