import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SelectDropdownSelectedMarkComponent } from './select-dropdown-selected-mark.component';

@NgModule({
  declarations: [SelectDropdownSelectedMarkComponent],
  imports: [CommonModule],
  exports: [SelectDropdownSelectedMarkComponent],
})
export class SelectDropdownSelectedMarkModule {}
