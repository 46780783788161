<pp-select
  [class]="ppClass"
  [ppItems]="ppItems"
  [ppSelectedItem]="{ value: ppOption, label: '' }"
  (ppSelectItem)="changeFieldCondition($event)"
  [ppNoSearchBar]="true"
  require
>
  <ng-container selected>
    <span
      class="advancedFilterOption__select-content"
      *ngIf="selectedOption"
      ppTooltip
      [ppTitle]="selectedOption.tooltip"
    >
      <pp-icon
        *ngIf="selectedOption.iconSrc"
        [ppSrc]="selectedOption.iconSrc"
        ppClass="setSize14"
      ></pp-icon>
      <span class="advancedFilterOption__select-content--text">
        {{ selectedOption.label }}
      </span>
    </span>

    <span class="advancedFilterOption__select-placeholder" *ngIf="!selectedOption">
      {{ 'select_option' | ppTranslate }}
    </span>
  </ng-container>
</pp-select>
