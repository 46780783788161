import { createElement } from 'src/app/core/helpers/dom';
import Tooltip from 'src/app/project/features/tooltip/Tooltip';
import { EIconPath } from '../../../../../shared/enums/icons.enum';
import { TColumn } from '../../columns/column.model';
import { GET_TABLE } from '../../table.ui.store';
import { createCellElement } from '../createCellElement';

export function createCreatedByElement(_index: number, _column: TColumn): HTMLElement {
  const table = GET_TABLE();
  const user = table.users.find(
    (_user) => _user.userId === table.points[_index].header.createdBy.id,
  );

  let matchesKeyword = false;

  if (user && table.keyword) {
    matchesKeyword = user.userName.toLowerCase().indexOf(table.keyword.toLowerCase()) !== -1;
  } else if (table.keyword) {
    matchesKeyword =
      table.points[_index].header.createdBy.caption
        .toLowerCase()
        .indexOf(table.keyword.toLowerCase()) !== -1;
  }

  const image = createElement('img', {
    attrs: {
      class: user
        ? matchesKeyword
          ? 'table__avatar table__avatar--highlight'
          : 'table__avatar'
        : matchesKeyword
        ? 'table__avatar table__avatar--disabled table__avatar--highlight'
        : 'table__avatar table__avatar--disabled',
      src: user ? user.avatarPublicUrl : EIconPath.MISC_USER,
      'data-id': table.points[_index].header.createdBy.id,
    },
  });

  const nameTooltip = new Tooltip({
    title: user ? user.userName : table.points[_index].header.createdBy.caption + '(deleted)',
    mobileTooltip: false,
    childTooltip: false,
    element: image,
    trimTooltip: false,
    trimElement: image,
  });

  nameTooltip.createTooltip();

  return createCellElement({
    index: _column.index,
    width: `${_column.width}px`,
    child: image,
  });
}
