import { Injectable } from '@angular/core';
import {
  API_notification_subscribe,
  API_notifications_filters,
  API_notifications_mark_all_as_read,
  API_notifications_mark_as_commented,
  API_notifications_mark_as_read,
  API_notifications_notification,
  API_notifications_timestamp,
  API_notifications_update_unread,
  API_NOTIFICATIONS,
} from '@core/api/paths';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { TPushNotification } from 'src/app/project/modules/notifications/notification.model';
import { TResponseEntity } from '../../../view-models/response.models';
import {
  TMarkNotificationAsCommentedRequest,
  TMarkNotificationAsReadRequest,
} from './notification-requests.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationsApiProviderService {
  constructor(private apiService: ApiService) {}

  fetchNotifications(
    mode: string,
    offset: number,
    account: string,
    site: string,
  ): Observable<TPushNotification[]> {
    const url = API_notifications_filters(mode, offset, account, site);

    return this.apiService.get<TPushNotification[]>(url);
  }

  checkForNewNotifications(
    mode: string,
    timeSinceLastSync: number,
    account: string,
    site: string,
  ): Observable<TPushNotification[]> {
    const url = API_notifications_timestamp(mode, timeSinceLastSync, account, site);

    return this.apiService.get<TPushNotification[]>(url);
  }

  markNotificationAsRead(
    notificationId: string,
    status: boolean,
    body: TMarkNotificationAsReadRequest,
  ): Observable<TResponseEntity> {
    const url = API_notifications_mark_as_read(notificationId, status);

    return this.apiService.put<TResponseEntity>(url, body);
  }

  markNotificationAsCommented(
    notificationId: string,
    body: TMarkNotificationAsCommentedRequest,
  ): Observable<TResponseEntity> {
    const url = API_notifications_mark_as_commented(notificationId);

    return this.apiService.put<TResponseEntity>(url, body);
  }

  updateUnreadStatus(body: Record<string, never>): Observable<TResponseEntity> {
    const url = API_notifications_update_unread();

    return this.apiService.put<TResponseEntity>(url, body);
  }

  markAllNotificationsRead(body: Record<string, never>): Observable<TResponseEntity> {
    const url = API_notifications_mark_all_as_read();

    return this.apiService.put<TResponseEntity>(url, body);
  }

  deleteNotification(notificationId: string): Observable<void> {
    const url = API_notifications_notification(notificationId);

    return this.apiService.delete<void>(url);
  }

  deleteNotifications(): Observable<void> {
    const url = API_NOTIFICATIONS();

    return this.apiService.delete<void>(url);
  }

  subscribeToPointNotifications(
    _id: string,
    status: boolean,
    body: Record<string, never>,
  ): Observable<TResponseEntity> {
    const url = API_notification_subscribe(_id, status);

    return this.apiService.put<TResponseEntity>(url, body);
  }
}
