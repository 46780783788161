import { cloneDeep } from 'lodash';
import { TCustomFieldAccessOption, TShare } from '../../share.model';
import { EAccessLevel, EAccessParameter } from './access-parameters';
import { correctParameter } from './correct-parameter';

export function correctCFAccessLogic(
  field: TCustomFieldAccessOption,
  parameter: EAccessParameter,
  share: TShare,
): TCustomFieldAccessOption[] {
  let access: EAccessParameter | EAccessLevel = parameter;
  const customFields = cloneDeep(share.advancedAccessLevels.customFields);

  const { canRead, canEdit } = correctParameter(parameter);

  customFields.forEach((customField) => {
    if (customField.templateId === field.templateId) {
      customField.permission.read = canRead;
      customField.permission.edit = canEdit;
    }
  });

  if (parameter === EAccessParameter.READ) {
    access = EAccessLevel.VIEW_ONLY;
  }

  return customFields;
}
