import { OverflowBehavior, OverlayScrollbars } from 'overlayscrollbars';

type TOverflowParams = {
  x?: OverflowBehavior;
  y?: OverflowBehavior;
};

export function createCustomScroller(
  scrollbarTheme: string,
  element: HTMLElement,
  overflow?: TOverflowParams,
): OverlayScrollbars {
  const overflowOptions: TOverflowParams = overflow
    ? overflow
    : {
        y: 'hidden',
      };

  return OverlayScrollbars(
    {
      target: element,
    },
    {
      overflow: overflowOptions,
      scrollbars: {
        theme: scrollbarTheme,
      },
    },
  );
}
