<div
  #selectComponent
  [id]="buttonId"
  class="select"
  [class.select--inputFocused]="dropdown.visible && dropdown.buttonId === buttonId"
  (click)="toggleDropdown()"
>
  <div #selectContent class="select__content">
    <span class="currencyPicker__content" *ngIf="selectedIndex !== -1">
      <span class="currencyDropdownShort">
        {{ currencies[selectedIndex].currencyCode }}
      </span>
      <span class="currencyDropdownName">
        {{ currencies[selectedIndex].currencySymbol }}
        {{ currencies[selectedIndex].currencyDisplayName }}
      </span>
    </span>

    <span class="currencyPicker__placeholder" *ngIf="selectedIndex === -1">
      {{ 'select_currency' | ppTranslate }}
    </span>
  </div>

  <div class="select__caret">
    <div class="select__icon-container">
      <pp-icon
        [ppSrc]="
          dropdown.visible && dropdown.buttonId === buttonId
            ? EIconPath.ICON_MISC_UP_ARROW_18
            : EIconPath.ICON_MISC_DOWN_ARROW_18
        "
        ppColor="grey-400"
        ppClass="setSize18"
      ></pp-icon>
    </div>
  </div>
</div>
