import { ETimelineTimeframe } from '../site-timeline-utils/timeframes-enums';

export function getTimelineYearRangeData(
  timeframe: ETimelineTimeframe | null,
  minDate: Date,
  maxDate: Date,
): [number, number] {
  const fieldsFilled = minDate !== null;
  const endYearAdditionValue = timeframe === ETimelineTimeframe.MONTH ? 3 : 2;

  if (fieldsFilled) {
    return [
      minDate.getFullYear() - 1,
      maxDate.getFullYear() - minDate.getFullYear() + endYearAdditionValue,
    ];
  }

  return [new Date().getFullYear() - 1, 3];
}
