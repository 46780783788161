import { GET_TABLE } from '../../table.ui.store';
import { humanizePriority } from 'src/app/project/modules/points/priorities';
import { createElement } from 'src/app/core/helpers/dom';
import { createCellElement } from '../createCellElement';
import { TColumn } from '../../columns/column.model';

export function createPriorityElement(_index: number, _column: TColumn): HTMLElement {
  const table = GET_TABLE();

  return createCellElement({
    index: _column.index,
    width: `${_column.width}px`,
    center: true,
    child: createElement('div', {
      attrs: {
        class: `customSiteTable__priority-indicator customSiteTable__priority--${table.points[_index].priority}`,
        title: humanizePriority(table.points[_index].priority),
      },
    }),
  });
}
