import { ETimelineTimeframe } from './timeframes-enums';

export type TTimeframeOption = {
  name: string;
  value: ETimelineTimeframe;
};

export function getTimeframeOptions(): TTimeframeOption[] {
  return [
    { name: 'Day', value: ETimelineTimeframe.DAY },
    { name: 'Week', value: ETimelineTimeframe.WEEK },
    { name: 'Month', value: ETimelineTimeframe.MONTH },
  ];
}
