import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorPickerComponent } from './color-picker.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [ColorPickerComponent],
  imports: [CommonModule, PipesModule, FormsModule],
  exports: [ColorPickerComponent],
})
export class ColorPickerModule {}
