import { translate } from 'src/app/project/features/translate/translate';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

export function getDashletHeaderSiteName(
  ppWorkspaces: string[],
  workspaces: TWorkspacesById,
): {
  siteName: string;
  tooltip: string;
} {
  let sitesNames: string[];
  let siteName: string;

  if (!ppWorkspaces) {
    sitesNames = [];
    siteName = translate('all_sites');
  } else {
    sitesNames = ppWorkspaces.map((workspaceId) => workspaces[workspaceId]?.siteName || '');

    if (sitesNames.length === 0) {
      siteName = translate('all_sites');
    } else if (sitesNames.length === 1) {
      siteName = sitesNames[0];
    } else {
      siteName = translate('multiple_sites');
    }
  }

  return {
    siteName,
    tooltip: setSiteTooltips(sitesNames, siteName),
  };
}

function setSiteTooltips(sitesNames: string[], siteName: string): string {
  if (sitesNames.length < 2) {
    return siteName;
  }

  let sitesList = '';

  sitesNames.forEach((site) => {
    sitesList += `<li>${site}</li>`;
  });

  const tooltip = `<div class="tooltip__fleetStatuses">
    <div>${translate('showing_sites')}:</div>
    <ul>
      ${sitesList}
    </ul>
  </div>`;

  return tooltip;
}
