import { cloneDeep } from 'lodash';

import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { AddComponentToView, RemoveComponentFromView } from '../ui/ui.actions';
import { TUISettings } from '../ui/ui.model';

import { TAccount } from '../account/account.model';
import { TAllFilters, TFilters } from '../filters/site-filter.model';
import { TWorkspacesById } from '../workspace/workspace.model';
import { TReminder } from './reminder.model';
import { ReminderListComponent } from './reminders/reminder-list/reminder-list.component';

import { SortingService } from '@core/helpers';
import { PromptService } from '../../components/prompt/prompt.service';
import { CookieService } from '../../services/cookie.service';
import { AccountService } from '../account/account-service/account.service';
import { SiteOverviewService } from '../site/site-overview/site-overview.service';
import { WorkspaceService } from '../workspace/workspace.service';

import { RemindersApiProviderService } from '@core/api';
import { TGuid } from '@core/helpers';
import { TranslationPipe } from '../../features/translate/translation.pipe';
import { logEventInGTAG } from '../../services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventReminders,
} from '../../services/analytics/google-analytics.consts';
import { EStore } from '../../shared/enums/store.enum';
import { logErrorInSentry } from '../errors/response-error';
import { EWorkspaces } from '../workspace/workspaces.enum';
import { REMINDERS_ALERT_COOKIE_NAME } from './reminders.constants';

@Injectable({
  providedIn: 'root',
})
export class RemindersService implements OnDestroy {
  private reminders: TReminder[] = [];
  private remindersNumber = {
    number: 0,
  };

  private remindersEnabled = false;
  private reminderList: ReminderListComponent;
  private sortedReminders: string[] = [];

  private filters: TFilters;
  private allFilters: TAllFilters;
  private siteFilters$ = new Observable<TAllFilters>();

  private accounts$: Observable<TAccount[]>;
  private workspaces$ = new Observable<TWorkspacesById>();

  private groupsExpanded = {
    past: false,
    today: true,
    future: false,
  };

  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store<{
      ui: TUISettings;
      accounts: TAccount[];
      siteFilter: TAllFilters;
      workspaces: TWorkspacesById;
    }>,
    private router: Router,
    private cookieService: CookieService,
    private sortingService: SortingService,
    private siteOverviewService: SiteOverviewService,
    private accountService: AccountService,
    private workspaceService: WorkspaceService,
    private remindersApiProviderService: RemindersApiProviderService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
  ) {
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.siteFilters$ = this.store.pipe(select(EStore.SITE_FILTER));

    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
      if (Array.isArray(accounts)) {
        let remindersEnabled = false;

        accounts.forEach((account) => {
          if (account.accountFeatures.reminders) {
            remindersEnabled = true;
          }
        });

        this.remindersEnabled = remindersEnabled;
      }
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.filters = this.allFilters?.[EWorkspaces.REMINDERS];
    });

    this.siteFilters$.pipe(takeUntil(this.destroy$)).subscribe((allFilters) => {
      this.allFilters = allFilters;
      this.filters = this.allFilters?.[EWorkspaces.REMINDERS];
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  showRemindersPopup(): void {
    this.store.dispatch(
      new AddComponentToView({
        componentName: 'reminder',
      }),
    );
  }

  hideRemindersPopup(redirect: boolean): void {
    if (redirect) {
      const routerLink = '/reminders';

      logEventInGTAG(EGoogleEventReminders.REMINDERS_OPEN_FROM_ALERT, {
        event_category: EGoogleEventCategory.SIDE_PANEL,
      });

      this.router.navigate([routerLink]);
    }

    this.store.dispatch(
      new RemoveComponentFromView({
        componentName: 'reminder',
      }),
    );
  }

  getRemindersForCurrentDay(): void {
    const cookie = this.cookieService.readCookie(REMINDERS_ALERT_COOKIE_NAME);

    if (this.remindersEnabled) {
      this.remindersApiProviderService
        .getCurrentRemindersCount()
        .pipe(
          tap((remindersCount) => {
            this.remindersNumber.number = remindersCount;

            if (cookie !== 'true' && this.remindersNumber.number > 0) {
              this.showRemindersPopup();
            }

            this.cookieService.setReminderCookie();
          }),
          catchError((error) => {
            const prompt = 'Fetching reminders number failed.';

            this.promptService.showError(prompt);
            this.cookieService.setReminderCookie();

            logErrorInSentry(error);
            return of([]);
          }),
        )
        .subscribe();
    }
  }

  fetchReminders(): Observable<TReminder[]> {
    return this.remindersApiProviderService.getAll().pipe(
      tap((reminders) => {
        this.reminders = cloneDeep(reminders).sort((a, b) =>
          this.sortingService.naturalSort(a.timestampEpochMillis, b.timestampEpochMillis),
        );
      }),
      catchError((error) => {
        this.promptService.showError(this.translationPipe.transform('prompt_error'));
        logErrorInSentry(error);
        return of([]);
      }),
    );
  }

  createReminder(_id: string, date: number, description: string): Observable<TReminder> {
    return this.remindersApiProviderService.create(_id, date, description);
  }

  updateReminder(
    reminderId: TGuid,
    _id: TGuid,
    date: number,
    description: string,
  ): Observable<TReminder> {
    return this.remindersApiProviderService.update(reminderId, _id, date, description).pipe(
      tap(() => {
        const currentReminder = this.reminders.find(
          (reminder) => reminder.reminderId === reminderId,
        );

        if (currentReminder) {
          currentReminder.timestampEpochMillis = date;
          currentReminder.note = description;
        }
      }),
    );
  }

  deleteReminder(reminderId: string): Observable<void> {
    return this.remindersApiProviderService.delete(reminderId).pipe(
      tap(() => {
        const index = this.reminders.findIndex((reminder) => reminder.reminderId === reminderId);

        if (index !== -1) {
          this.reminders.splice(index, 1);
        }
      }),
    );
  }

  getReminders(): TReminder[] {
    return this.reminders;
  }

  getRemindersNumber(): { number: number } {
    return this.remindersNumber;
  }

  setRemindersNumber(number: number): void {
    this.remindersNumber.number = number;
  }

  clearRemindersNumber(): void {
    this.remindersNumber.number = 0;
  }

  setReminderList(reminderList: ReminderListComponent): void {
    this.reminderList = reminderList;
  }

  addReminder(reminder: TReminder): void {
    this.reminders.push(reminder);

    if (this.reminderList) {
      this.reminderList.updateReminders();
    }
  }

  getSortedReminders(): string[] {
    return this.sortedReminders;
  }

  setSortedReminders(reminders: string[]): void {
    this.sortedReminders = reminders;
  }

  updateRemindersFilter(): void {
    let filters = cloneDeep(this.filters);

    filters = this.addRemindersFilters(filters);
    this.siteOverviewService.updateCustomFieldFilters(filters, true);
  }

  addRemindersFilters(filters: TFilters): TFilters {
    const accounts = this.accountService.getAccounts();

    accounts.forEach((account) => {
      if (account.accountFeatures?.reminders) {
        const existingAccount = AccountService.findMatchingAccount(account, filters);

        if (!existingAccount) {
          filters.sites.push({
            accountName: account.accountName,
            value: true,
            appliedValue: true,
            default: true,
            workspaces: [],
          });
        }

        const index = filters.sites.findIndex((_site) => _site.accountName === account.accountName);

        account.workspaces.forEach((workspaceId) => {
          const workspace = this.workspaceService.findWorkspace(workspaceId);

          const existingWorkspace = filters.sites[index].workspaces.find(
            (savedWorkspace) => savedWorkspace.id === workspace.workspaceId,
          );

          if (!existingWorkspace) {
            filters.sites[index].workspaces.push({
              name: workspace.siteName,
              id: workspace.workspaceId,
              value: true,
              appliedValue: true,
              default: true,
            });
          } else {
          }
        });

        filters.sites[index].workspaces = filters.sites[index].workspaces.filter((_workspace) =>
          account.workspaces.includes(_workspace.id),
        );
      }
    });

    filters.sites = filters.sites.filter((_account) => {
      let accountFound = false;

      for (let i = 0; i < Object.keys(accounts).length; i++) {
        if (accounts[Object.keys(accounts)[i]].accountName === _account.accountName) {
          accountFound = true;

          break;
        }
      }

      return accountFound;
    });

    filters.sitesDefault = this.siteOverviewService.isSitesDefault(filters);

    return filters;
  }

  getGroupsExpanded(): { past: boolean; today: boolean; future: boolean } {
    return this.groupsExpanded;
  }
}
