import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

export type TWindowDimensions = Pick<Window, 'innerHeight' | 'innerWidth'>;

@Injectable({
  providedIn: 'root',
})
export class WindowService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private readonly _resize$: BehaviorSubject<TWindowDimensions>;
  readonly resize$: Observable<TWindowDimensions>;
  private readonly window: Window & typeof globalThis;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView;
    this._resize$ = new BehaviorSubject(this.getWindowDimensions());
    this.resize$ = this._resize$.asObservable();

    fromEvent(this.window, 'resize')
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(30),
        tap(() => {
          this._resize$.next(this.getWindowDimensions());
        }),
      )
      .subscribe();
  }

  getGlobalObject(): Window & typeof globalThis {
    return this.window;
  }

  getWindowDimensions(): TWindowDimensions {
    return {
      innerWidth: this.window.innerWidth,
      innerHeight: this.window.innerHeight,
    };
  }

  get orientation(): string | number {
    return this.window.orientation;
  }

  confirm(message: string): boolean {
    return this.window.confirm(message);
  }

  open(url: string): void {
    this.window.open(url);
  }

  reload(): void {
    this.window.location.reload();
  }

  getCookiehub(): Record<string, any> {
    return this.window['cookiehub'];
  }

  getIntercom(): (string, ...any) => any {
    return this.window['Intercom'];
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
