<div [ngStyle]="{ width: dropdownWidth }">
  <pp-hide-dropdown (ppHideDropdown)="hideDropdown()"></pp-hide-dropdown>

  <ul class="dropdownWrapper">
    <li
      *ngFor="let item of items"
      (click)="selectItem(item)"
      class="dropdownItem"
      [ngStyle]="{ 'font-family': item.value }"
      [class.dropdownItem--selected]="selectedItem?.value === item.value"
    >
      {{ item.label }}
    </li>
  </ul>
</div>
