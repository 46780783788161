import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InputFocusService {
  private inputFocusTimerMs = 200;

  constructor(private ngZone: NgZone) {}

  immediateFocus(target: EventTarget) {
    this.focus(target, 0);
  }

  focus(target: EventTarget, timerMs = this.inputFocusTimerMs): void {
    if (!target['focus']) {
      throw new Error('Element does not accept focus.');
    }

    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        if (target instanceof HTMLElement) {
          target.focus();
        }
      }, timerMs);
    });
  }
}
