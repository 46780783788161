import { GET_TIMELINE } from '../../timeline.ui.store';
import { timelineProperties } from './timeline-variables';
import { TAnyFunction } from '@core/helpers';

export function onResizeHandleMouseDown({
  _canEdit,
  initialScroll,
  _event,
  startOffset,
  resizeHandlerElement,
  offsetX,
  resizeHandlerElementRect,
  mouseup,
  mousemove,
  scrollmove,
}: {
  _canEdit: boolean;
  initialScroll: number;
  _event: MouseEvent;
  startOffset: number;
  resizeHandlerElement: HTMLElement;
  offsetX: number;
  resizeHandlerElementRect: Partial<DOMRect>;
  mouseup: TAnyFunction;
  mousemove: TAnyFunction;
  scrollmove: TAnyFunction;
}): {
  initialScroll: number;
  startOffset: number;
  offsetX: number;
  resizeHandlerElementRect: Partial<DOMRect>;
} {
  if (_canEdit) {
    const timeline = GET_TIMELINE();

    timelineProperties.timelineTable = document.getElementById('timeline__body');
    initialScroll = timeline.timelineBody.virtualScroller.scrollElement.scrollLeft;

    _event.stopPropagation();
    _event.preventDefault();

    timelineProperties.active = true;
    startOffset = timeline.timelineBody.virtualScroller.offsetX;

    (resizeHandlerElement.parentNode as HTMLElement).style.cursor = 'ew-resize';
    (resizeHandlerElement.parentNode.parentNode as HTMLElement).style.cursor = 'ew-resize';

    offsetX = _event.offsetX;
    resizeHandlerElementRect = resizeHandlerElement.getBoundingClientRect();

    document.onmouseup = mouseup;
    document.onmousemove = mousemove;
    timelineProperties.timelineTable.onscroll = scrollmove;
  }
  return { initialScroll, startOffset, offsetX, resizeHandlerElementRect };
}
