import { GET_TABLE } from '../../../table.ui.store';
import { GET_COLUMNS } from '../../../columns/columns.store';
import { createElement } from 'src/app/core/helpers/dom';

export function createResizeHandleElement(): HTMLElement {
  const table = GET_TABLE();
  let resizeHandlerElementRect: Partial<DOMRect> = {};
  let colIndex = null;
  let cellWidth = 0;
  let rowWidth = 0;
  let initialRowWidth = 0;
  let initialCellWidth = 0;
  let offsetX = 0;

  const mouseup = (_event: MouseEvent): void => {
    _event.preventDefault();

    if (colIndex || colIndex === 0) {
      colIndex = null;

      table.updateVisibleColumnPositions();
      table.updateWidth();
      table.savePreferencesCallback();
      table.tableHead.load(true);
    }

    table.tableHead.resizeElement = null;

    resizeHandlerElement.classList.remove('table__head__resizeHandle--active');

    document.onmouseup = null;
    document.onmousemove = null;
  };

  const mousemove = (_event: MouseEvent): void => {
    _event.preventDefault();

    const changeAmount = _event.x - resizeHandlerElementRect.left - offsetX;
    let newCellWidth = cellWidth + changeAmount;
    let newRowWidth = 0;

    const update = (): void => {
      (resizeHandlerElement.parentNode as HTMLElement).style.width = `${newCellWidth}px`;
      colIndex =
        Array.from(resizeHandlerElement.parentNode.parentNode.children).indexOf(
          resizeHandlerElement.parentNode as HTMLElement,
        ) - 1;

      table.tableHead.updateWidth(newRowWidth);
      table.tableFooter.updateWidth(newRowWidth);

      table.tableBody.virtualScroller.updateWidth(newRowWidth);
      table.tableBody.virtualScroller.columnWidths[colIndex] = newCellWidth;
      GET_COLUMNS()[table.visibleColumnIndexes[colIndex]].width = newCellWidth;

      const visibleColIndex = table.tableBody.virtualScroller.visibleXIndexes.findIndex(
        (_visibleXIndex) => _visibleXIndex === colIndex,
      );

      // TODO: optimization: change only visible elements and then the rest on mouse release
      table.tableBody.virtualScroller.dataElements.forEach((_element) => {
        if (_element) {
          if (!_element.classList.contains('table__row--empty')) {
            const columnCellsElement = _element.children[1];

            if (columnCellsElement.children[visibleColIndex]) {
              (
                columnCellsElement.children[visibleColIndex] as HTMLElement
              ).style.width = `${newCellWidth}px`;
            }
          }
        }
      });

      table.tableBody.virtualScroller.updateVisibleXIndexes(false);
    };

    if (newCellWidth >= table.tableHead.minColumnWidth) {
      newRowWidth = rowWidth + changeAmount;

      update();
    } else if (
      (resizeHandlerElement.parentNode as HTMLElement).offsetWidth !==
      table.tableHead.minColumnWidth
    ) {
      newCellWidth = table.tableHead.minColumnWidth;
      newRowWidth = initialRowWidth - initialCellWidth + table.tableHead.minColumnWidth;

      update();
    }
  };

  const resizeHandlerElement: HTMLElement = createElement<HTMLElement>('div', {
    attrs: {
      class: 'table__head__resizeHandle',
    },
    eventListeners: {
      mousedown: (_event) => {
        _event.stopPropagation();

        offsetX = _event.offsetX;

        resizeHandlerElementRect = resizeHandlerElement.getBoundingClientRect();

        initialCellWidth = (resizeHandlerElement.parentNode as HTMLElement).offsetWidth;
        initialRowWidth = table.tableHead.rowElement.offsetWidth;

        cellWidth = initialCellWidth;
        rowWidth = initialRowWidth;

        table.tableHead.resizeElement = resizeHandlerElement;

        resizeHandlerElement.classList.add('table__head__resizeHandle--active');

        document.onmouseup = mouseup;
        document.onmousemove = mousemove;
      },
      mouseenter: (): void => {
        if (!table.tableHead.resizeElement) {
          resizeHandlerElement.classList.add('table__head__resizeHandle--active');
        }
      },
      mouseleave: (): void => {
        if (table.tableHead.resizeElement !== resizeHandlerElement) {
          resizeHandlerElement.classList.remove('table__head__resizeHandle--active');
        }
      },
    },
  });

  return resizeHandlerElement;
}
