import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DeviceService } from '@core/services';
import { GridsterConfig } from 'angular-gridster2';
import { cloneDeep } from 'lodash';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { TGridsterItemCallbacks } from 'src/app/project/modules/dashboard/dashboard-gridster-config/gridster-config';
import { NewDashletModalComponent } from 'src/app/project/modules/dashboard/new-dashlet-modal/new-dashlet-modal.component';
import { TNewDashletModalData } from 'src/app/project/modules/dashboard/new-dashlet-modal/new-dashlet-modal.consts';
import { TDashlet } from 'src/app/project/modules/preferences/preferences.model';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { TAsset } from '../../../../asset-service/asset.consts';
import { FleetService } from '../../../../fleet-service/fleet.service';
import { EFleetShareOption } from '../../../../fleet-sharing/fleet-sharing.consts';
import { AssetDashboardService } from './asset-dashboard.service';
import { getAssetGridsterConfig, getAssetGridsterConfigTouch } from './asset-gridster-config';

@Component({
  selector: 'pp-asset-dashboard',
  templateUrl: './asset-dashboard.component.html',
  styleUrl: './asset-dashboard.component.scss',
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: '*',
        }),
      ),
      state(
        'closed',
        style({
          height: '0',
        }),
      ),
      transition('open => closed', [animate('0.5s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),
  ],
})
export class AssetDashboardComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() ppAsset: TAsset;
  @Input() ppIsClosing: boolean;
  private isTouchDevice: boolean;
  gridsterOptions: GridsterConfig;
  canEdit: boolean;

  isOpen = false;

  dashlets: TDashlet[] = [];
  EIconPath = EIconPath;
  defaultWorkspaces: string[];

  constructor(
    private deviceService: DeviceService,
    private fleetService: FleetService,
    private ngZone: NgZone,
    private modalService: ModalService,
    private assetDashboardService: AssetDashboardService,
  ) {
    this.isTouchDevice = this.deviceService.isTouchDevice();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const fleet = this.fleetService.getFleet(this.ppAsset.fleetId);

    if (fleet) {
      this.canEdit = fleet.fleetShareOption === EFleetShareOption.EDIT;
    }

    if (this.ppIsClosing) {
      this.isOpen = false;
    }

    this.defaultWorkspaces = this.ppAsset.sites.map((site) => site.workspaceId);
    this.dashlets = cloneDeep(this.ppAsset.dashlets);
  }

  ngOnInit(): void {
    if (this.isTouchDevice) {
      this.gridsterOptions = getAssetGridsterConfigTouch();
    } else {
      const gridsterCallbacks: TGridsterItemCallbacks = {
        itemChangeCallback: () => this.updateUserDashlets(),
        itemResizeCallback: () => this.updateUserDashlets(),
      };

      this.gridsterOptions = getAssetGridsterConfig(gridsterCallbacks, this.canEdit);
    }
  }

  ngAfterViewInit(): void {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.ngZone.run(() => {
          this.isOpen = true;
        });
      });
    });
  }

  updateUserDashlets(): void {
    if (!this.canEdit) {
      return;
    }

    this.assetDashboardService.updateAssetDashboard(this.dashlets, this.ppAsset.id);
  }

  addDashlet(): void {
    this.modalService.setData<TNewDashletModalData>({
      assetId: this.ppAsset.id,
    });

    this.modalService.showModal(NewDashletModalComponent);
  }
}
