import { TTimelineColumns } from './timeline-timeframes';
import { getWeekTimeframes } from './get-week-timeframes';
import { getWeekBottomElements } from './get-week-bottom-elements';
import { getWeekTopElements } from './get-week-top-elements';

// --------------------------------------------------
// --------------------- WEEK -----------------------
// --------------------------------------------------

export function setWeek(from: Date, to: Date): TTimelineColumns {
  return {
    topWidth: 147,
    bottomWidth: 98,

    topElements: getWeekTopElements(from, to),
    bottomElements: getWeekBottomElements(from, to),
    timeframes: getWeekTimeframes(from, to),
  };
}
