import { Component, OnInit } from '@angular/core';
import { TWorkspaceUser } from '@project/view-models';
import { catchError, of, tap } from 'rxjs';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EditAccountUserModalDataService } from 'src/app/project/modules/account/account-settings/account-user-modal/edit-account-user-modal-data.service';
import { DowngradeAccountUserModalComponent } from 'src/app/project/modules/account/account-settings/downgrade-account-user-modal/downgrade-account-user-modal.component';
import { TDowngradeAccountUserModalData } from 'src/app/project/modules/account/account-settings/downgrade-account-user-modal/downgrade-account-user-modal.component.consts';
import { TwoFactorAuthenticationService } from 'src/app/project/modules/auth/2fa/two-factor-authentication.service';
import { AccountSharesService } from 'src/app/project/modules/share/account-shares.service';
import { SharesService } from 'src/app/project/modules/share/shares.service';
import { TAccountUser } from 'src/app/project/modules/users/account.user.model';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { NewAccountAdminModalComponent } from '../../../../account/new-account-admin-modal/new-account-admin-modal.component';
import { TAddAccountAdminModalData } from '../../../../account/new-account-admin-modal/new-account-admin-modal.consts';
import { DeleteAccountUserModalComponent } from '../../../delete-account-user-modal/delete-account-user-modal.component';
import { TDeleteAccountUserModalData } from '../../../delete-account-user-modal/delete-account-user-modal.model';
import { SelectedSharesService } from '../../../services/selected-shares.service';
import { TUserManagementUserOptionsDropdownData } from './user-management-user-options-dropdown.model';

@Component({
  selector: 'pp-user-management-user-options-dropdown',
  templateUrl: './user-management-user-options-dropdown.component.html',
  styleUrls: ['./user-management-user-options-dropdown.component.scss'],
})
export class UserManagementUserOptionsDropdownComponent implements OnInit {
  EIconPath = EIconPath;

  private dropdown: TDropdown<TUserManagementUserOptionsDropdownData>;
  user: TWorkspaceUser;
  accountUser: TAccountUser;
  accountId: string;
  isAccountAdminSelected: boolean;
  isAccountAdminActive: boolean;

  constructor(
    private dropdownService: DropdownService,
    private usersService: UsersService,
    private modalService: ModalService,
    private selectedSharesService: SelectedSharesService,
    private sharesService: SharesService,
    private translationPipe: TranslationPipe,
    private promptService: PromptService,
    private twoFactorAuthenticationService: TwoFactorAuthenticationService,
    private accountSharesService: AccountSharesService,
    private editAccountUserModalDataService: EditAccountUserModalDataService,
  ) {}

  ngOnInit(): void {
    this.dropdown = this.dropdownService.getDropdown();

    this.setDropdownData();

    this.user = this.usersService.getUser(this.accountUser.userId);
  }

  hideDropdown(): void {
    this.dropdownService.hideDropdown();
  }

  selectAll(): void {
    const shareIds = this.accountUser.shares.map((share) => share.shareId);

    this.selectedSharesService.selectAllUserShares(shareIds);
    this.hideDropdown();
  }

  resendInvite(): void {
    const shareId = this.accountUser.shares[0].shareId;

    this.sharesService
      .resendInvitation(shareId)
      .pipe(
        tap(() => {
          this.showResendInviteSuccessPrompt();
        }),
        catchError(() => {
          this.showResendInviteErrorPrompt();

          return of(null);
        }),
      )
      .subscribe();

    this.hideDropdown();
  }

  deleteUser(): void {
    this.modalService.setData<TDeleteAccountUserModalData>({
      user: this.user,
      accountId: this.accountId,
      isAccountAdmin: this.isAccountAdminSelected,
    });

    this.modalService.showModal(DeleteAccountUserModalComponent);

    this.hideDropdown();
  }

  reset2FA(): void {
    this.twoFactorAuthenticationService
      .delete2FA(this.user.userId)
      .pipe(
        tap(() => {
          const prompt = this.translationPipe.transform('prompt_2fa_reset_success');
          this.promptService.showSuccess(prompt);
          this.accountSharesService.updateAccountUserTwoFactorAuthentication(
            false,
            this.user.userId,
          );
        }),
        catchError(() => {
          const prompt = this.translationPipe.transform('prompt_2fa_reset_error');
          this.promptService.showError(prompt);

          return of(null);
        }),
      )
      .subscribe();

    this.hideDropdown();
  }

  upgradeToAccountAdmin(): void {
    this.modalService.setData<TAddAccountAdminModalData>({
      accountId: this.accountId,
      user: this.user,
    });

    this.modalService.showModal(NewAccountAdminModalComponent);

    this.hideDropdown();
  }

  downgradeAccountAdmin(): void {
    this.modalService.setData<TDowngradeAccountUserModalData>({
      email: this.accountUser.email,
      accountId: this.accountId,
    });
    this.editAccountUserModalDataService.clearData();
    this.editAccountUserModalDataService.setEmailList([this.accountUser.email]);

    this.modalService.showModal(DowngradeAccountUserModalComponent, {
      callback: () => {
        this.accountSharesService.resetAccountShares(this.accountId).subscribe();
      },
    });

    this.hideDropdown();
  }

  deleteAccountAdmin(): void {
    this.modalService.setData<TDeleteAccountUserModalData>({
      user: this.user,
      accountId: this.accountId,
      isAccountAdmin: this.isAccountAdminSelected,
    });

    this.modalService.showModal(DeleteAccountUserModalComponent);

    this.hideDropdown();
  }

  private setDropdownData(): void {
    if (!this.dropdown.data) {
      throw new Error('Data for this dropdown is required');
    }

    const dropdownData: TUserManagementUserOptionsDropdownData = this.dropdown.data;

    this.accountUser = dropdownData.user;
    this.accountId = dropdownData.accountId;
    this.isAccountAdminActive = dropdownData.isAccountAdminActive;
    this.isAccountAdminSelected = dropdownData.isAccountAdminSelected;
  }

  private showResendInviteErrorPrompt(): void {
    const promptText = this.translationPipe.transform('prompt_invitation_resend_error');

    this.promptService.showError(promptText);
  }

  private showResendInviteSuccessPrompt(): void {
    const promptText = this.translationPipe.transform('prompt_invitation_resend');

    this.promptService.showSuccess(promptText);
  }
}
