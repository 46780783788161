/**
 * @module ol/renderer/canvas/common
 */
/**
 * Context options to disable image smoothing.
 * @type {Object}
 */
export var IMAGE_SMOOTHING_DISABLED = {
  imageSmoothingEnabled: false,
  msImageSmoothingEnabled: false
};
/**
 * Context options to enable image smoothing.
 * @type {Object}
 */
export var IMAGE_SMOOTHING_ENABLED = {
  imageSmoothingEnabled: true,
  msImageSmoothingEnabled: true
};
