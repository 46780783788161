<button class="countdown" (click)="tryEmitCallback()">
  {{ ppTranslationKey | ppTranslate }}
  <span class="countdown__resend"
    >{{ ppTranslationKeyPrimaryColor | ppTranslate }}

    <ng-container *ngIf="timeLeft > 0">
      {{ 'countdown_timer' | ppTranslate: { time: timeLeft } }}
    </ng-container>
  </span>
</button>
