import { Component, Input } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-info-icon',
  templateUrl: './info-icon.component.html',
  styleUrls: ['./info-icon.component.scss'],
})
export class InfoIconComponent {
  @Input() ppClass = '';

  EIconPath = EIconPath;
}
