<ul class="settingsSite__tabs">
  <ng-container *ngFor="let tab of accountSettingsTabs">
    <li
      *ngIf="!tab.hidden || showHiddenOptions"
      class="settingsSite__tab"
      routerLinkActive="settingsSite__tab--active"
      [attr.data-m-target]="tab.dataTarget"
    >
      <a
        class="settingsSite__tab-link"
        [routerLink]="['/settings/account/', ppAccountId, tab.path]"
      >
        {{ tab.translationKey | ppTranslate }}
      </a>
    </li>
  </ng-container>
</ul>
