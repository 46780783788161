import { Component } from '@angular/core';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIconPath } from '../../../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-account-user-modal-edit-warning',
  templateUrl: './account-user-modal-edit-warning.component.html',
  styleUrls: ['./account-user-modal-edit-warning.component.scss'],
})
export class AccountUserModalEditWarningComponent {
  EIconPath = EIconPath;

  warningMessages: string[] = [];

  constructor(private translationPipe: TranslationPipe) {}

  ngOnInit(): void {
    this.warningMessages = [this.translationPipe.transform('note_permissions_replaces_with_new')];
  }
}
