import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FleetSharingModalService {
  private emailList: string[] = [];
  private confirmClose: boolean = false;

  constructor() {}

  getEmailList(): string[] {
    return this.emailList;
  }

  setEmailList(emailList: string[]): void {
    this.emailList = emailList;
  }

  clearEmailList(): void {
    this.emailList = [];
  }

  setConfirmClose(confirmClose: boolean): void {
    this.confirmClose = confirmClose;
  }

  getConfirmClose(): boolean {
    return this.confirmClose;
  }
}
