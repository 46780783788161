import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-bulk-changes-button',
  templateUrl: './bulk-changes-button.component.html',
  styleUrls: ['./bulk-changes-button.component.scss'],
})
export class BulkChangesButtonComponent {
  @Input() ppIconPath: EIconPath;
  @Input() ppText: string;
  @Input() ppDisabled: boolean;
  @Output() ppAction = new EventEmitter<void>();

  EIconPath = EIconPath;

  action(): void {
    if (this.ppDisabled) {
      return;
    }

    this.ppAction.emit();
  }
}
