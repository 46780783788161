import { cloneDeep } from 'lodash';

import { Injectable, OnDestroy } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SetSiteFilter } from 'src/app/project/modules/filters/site-filter.actions';

import { TAllFilters, TFilters } from 'src/app/project/modules/filters/site-filter.model';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { EWorkspaces } from 'src/app/project/modules/workspace/workspaces.enum';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { EStore } from '../../../shared/enums/store.enum';
import { AccountService } from '../../account/account-service/account.service';
import { CombineCustomFieldsService } from '../../custom-fields/combine-custom-fields/combine-custom-fields.service';
import { CustomFieldsService } from '../../custom-fields/custom-fields.service';
import { checkCustomWorkspaceId } from '../../workspace/workspace';
import { WorkspaceService } from '../../workspace/workspace.service';
import { addCustomFieldsToFilters } from './utils/add-custom-fields-to-filter';
import { createSitesFilter } from './utils/create-sites-filter';
import { generateFilterTooltips } from './utils/generate-filter-tooltips';
import { removeDeletedCustomFieldsFromFilters } from './utils/remove-deleted-custom-fields-from-filters';
import { updateFilterSiteNames } from './utils/update-filter-site-names';

@Injectable({
  providedIn: 'root',
})
export class SiteOverviewService implements OnDestroy {
  private filters: TFilters;

  private readonly destroy$ = new Subject<void>();

  private siteFilters$ = new Observable<TAllFilters>();
  private workspaces$ = new Observable<TWorkspacesById>();
  private workspaceId: string;
  private allFilters: TAllFilters;

  constructor(
    private store: Store<{
      siteFilter: TAllFilters;
      workspaces: TWorkspacesById;
    }>,
    private workspaceService: WorkspaceService,
    private accountService: AccountService,
    private activeService: ActiveService,
    private customFieldsService: CustomFieldsService,
    private combineCustomFieldService: CombineCustomFieldsService,
  ) {
    this.addStoreListeners();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  isSitesDefault(filters: TFilters): boolean {
    const appliedAccounts = filters.sites.filter((account) => account.value);

    if (appliedAccounts.length !== filters.sites.length) {
      return false;
    } else {
      for (let i = 0; i < filters.sites.length; i++) {
        const site = filters.sites[i];

        const appliedSites = site.workspaces.filter((filteredSite) => filteredSite.value);

        if (appliedSites.length !== site.workspaces.length) {
          return false;
        }
      }
    }

    return true;
  }

  updateOverviewFilter(): void {
    let filters = cloneDeep(this.filters);

    filters = this.addOverviewFilters(filters);
    this.updateCustomFieldFilters(filters);
  }

  updateCustomFieldFilters(filters: TFilters, reminders: boolean = false): void {
    const workspaces = this.workspaceService.getWorkspaces();
    const customFields = this.customFieldsService.getCustomFields();
    const combinedCustomFields = this.combineCustomFieldService.combineCustomFields(
      Object.values(workspaces),
    );

    removeDeletedCustomFieldsFromFilters(filters, customFields);
    addCustomFieldsToFilters(combinedCustomFields, filters, workspaces);
    updateFilterSiteNames(filters, workspaces);
    generateFilterTooltips(filters);
    this.saveFiltersToStore(reminders, filters);
  }

  private setFilters(): void {
    this.filters = this.allFilters?.[this.workspaceId];
  }

  private saveFiltersToStore(reminders: boolean, filters: TFilters): void {
    if (reminders) {
      this.allFilters[EWorkspaces.REMINDERS] = filters;

      this.store.dispatch(
        new SetSiteFilter({
          workspaceId: EWorkspaces.REMINDERS,
          filter: filters,
        }),
      );
    } else {
      this.allFilters[EWorkspaces.OVERVIEW] = filters;

      this.store.dispatch(
        new SetSiteFilter({
          workspaceId: EWorkspaces.OVERVIEW,
          filter: filters,
        }),
      );
    }
  }

  private addOverviewFilters(filters: TFilters): TFilters {
    const accounts = this.accountService.getAccounts();

    createSitesFilter(accounts, filters, this.workspaceService.getWorkspaces());

    filters.sitesDefault = this.isSitesDefault(filters);

    return filters;
  }

  private addStoreListeners(): void {
    this.siteFilters$ = this.store.pipe(select(EStore.SITE_FILTER));
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.siteFilters$.pipe(takeUntil(this.destroy$)).subscribe((allFilters) => {
      this.allFilters = cloneDeep(allFilters);
      this.setFilters();
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.workspaceId = this.activeService.getActiveWorkspaceId();

      if (!this.workspaceId) {
        this.workspaceId = checkCustomWorkspaceId();
      }

      this.filters = this.allFilters?.[this.workspaceId];
    });
  }
}
