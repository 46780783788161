import * as Sentry from '@sentry/browser';
import i18next from 'i18next';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { environment } from 'src/environments/environment';

export function logErrorInSentry(error): void {
  if (error?.status === EStatusCode.UNAUTHORIZED || error?.status === EStatusCode.FORBIDDEN) {
    return;
  }

  if (getSentryEnabled()) {
    Sentry.setTag('error_status', error.status ? `${error.status}` : 'other');
    Sentry.captureException(error);
  }

  if (!environment.production) {
    console.groupCollapsed('Error for Sentry');
    console.log(error);
    console.trace();
    console.groupEnd();
  }
}

export const generateErrorPrompt = (error, defaultPrompt: string): string => {
  if (error.status === EStatusCode.UNAUTHORIZED) {
    return i18next.t('prompt_request_logged_out');
  } else {
    return i18next.t(defaultPrompt);
  }
};

export function getSentryEnabled(): boolean {
  return (
    environment.production ||
    environment.clone ||
    environment.clone2 ||
    environment.release ||
    environment.clone3 ||
    environment.ecs
  );
}
