<section class="tagList">
  <button class="selectTags__back" (click)="back()">
    <img [src]="EIconPath.ARROW_LEFT" />
    <ng-container *ngIf="ppFileLoaded">{{ 'back_to_excel_csv_import' | ppTranslate }}</ng-container>
    <ng-container *ngIf="!ppFileLoaded">{{ 'back_to_site_selection' | ppTranslate }}</ng-container>
  </button>

  <div class="tagList__row">
    <h6 *ngIf="ppFileLoaded" class="selectTags__spreadsheet-name">
      {{ 'select_tags_from' | ppTranslate }} <strong>{{ ppFileName }}</strong>
    </h6>

    <h6 *ngIf="!ppFileLoaded" class="selectTags__spreadsheet-name">
      {{ 'select_tags_from' | ppTranslate }} <strong>{{ ppSite.siteName }}</strong>
    </h6>

    <button
      class="tagList__select-all-button"
      *ngIf="
        (ppSite &&
          selectableTags.length !== ppSelectedTags[ppSite.accountId][ppSite.workspaceId].length) ||
        (!ppSite && selectableTags.length !== ppSelectedFileTags.length)
      "
      (click)="selectAll()"
    >
      {{ 'select_all' | ppTranslate }}
    </button>

    <button
      class="tagList__select-all-button"
      *ngIf="
        ((ppSite &&
          selectableTags.length === ppSelectedTags[ppSite.accountId][ppSite.workspaceId].length) ||
          (!ppSite && selectableTags.length === ppSelectedFileTags.length)) &&
        (ppSelectedFileTags?.length > 0 ||
          ppSelectedTags[ppSite.accountId][ppSite.workspaceId]?.length > 0)
      "
      (click)="deselectAll()"
    >
      {{ 'deselect_all' | ppTranslate }}
    </button>

    <button
      *ngIf="
        ((ppSite &&
          selectableTags.length === ppSelectedTags[ppSite.accountId][ppSite.workspaceId].length) ||
          (!ppSite && selectableTags.length === ppSelectedFileTags.length)) &&
        ppSelectedFileTags?.length === 0 &&
        ppSelectedTags[ppSite.accountId][ppSite.workspaceId]?.length === 0
      "
      class="selectAll selectAll--disabled"
    >
      {{ 'select_all' | ppTranslate }}
    </button>
  </div>

  <ul class="tagsContainer">
    <ng-container *ngFor="let tag of ppData">
      <li
        ppTooltip
        [ppTitle]="tag"
        [ppTrimTooltip]="true"
        class="tag"
        [class.tag--selected]="ppSelectedTags[ppSite.accountId][ppSite.workspaceId].includes(tag)"
        (click)="selectTag(tag)"
        *ngIf="
          !ppInitialWorkspaceTags.includes(tag) &&
          ppSite &&
          !(
            ppMergedTags.includes(tag) &&
            !ppSelectedTags[ppSite.accountId][ppSite.workspaceId].includes(tag)
          )
        "
      >
        {{ tag }}
      </li>

      <li
        ppTooltip
        [ppTitle]="tag"
        class="tag"
        [class.tag--selected]="ppSelectedFileTags.includes(tag)"
        (click)="selectTag(tag)"
        *ngIf="!ppInitialWorkspaceTags.includes(tag) && !ppSite"
      >
        {{ tag }}
      </li>

      <li
        ppTooltip
        [ppTitle]="'tag_already_selected_on_different_site' | ppTranslate"
        class="tag tag--disabled"
        *ngIf="
          ppMergedTags.includes(tag) &&
          !ppSelectedTags[ppSite.accountId][ppSite.workspaceId].includes(tag)
        "
      >
        {{ tag }}
      </li>

      <li
        ppTooltip
        ppTitle="This tag already exists on this site"
        class="tag tag--disabled"
        *ngIf="ppInitialWorkspaceTags.includes(tag)"
      >
        {{ tag }}
      </li>
    </ng-container>
  </ul>
</section>
