import { cloneDeep } from 'lodash';
import { GET_TABLE } from '../../site/site-table/table.ui.store';
import { ToggleAllPointsSelection } from '../points.actions';
import { TPointsByWorkspace } from '../points.model';
import { addSelectedPoint, setSelectedPoints } from '../selected-points';

export function toggleAllPointsSelection(
  oldState: TPointsByWorkspace,
  action: ToggleAllPointsSelection,
): TPointsByWorkspace {
  const state = cloneDeep(oldState);
  const visiblePointsIds = GET_TABLE().getVisiblePointIds();
  const allSelected = action.payload.allSelected;

  let selectAll = false;

  setSelectedPoints([]);

  Object.keys(state).forEach((workspaceId) => {
    if (allSelected || selectAll) {
      selectAll = true;
    } else {
      state[workspaceId].entities.forEach((point) => {
        if (visiblePointsIds.includes(point._id)) {
          addSelectedPoint(point._id);
        }
      });
    }
  });

  return state;
}
