import { Component, Output, EventEmitter } from '@angular/core';
import { EIconPath } from '../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-mobile-new-point-button',
  templateUrl: './mobile-new-point-button.component.html',
  styleUrls: ['./mobile-new-point-button.component.scss'],
})
export class MobileNewPointButtonComponent {
  @Output() ppAction = new EventEmitter();

  EIconPath = EIconPath;

  action(): void {
    this.ppAction.emit();
  }
}
