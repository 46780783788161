<div class="bulkChangesBar" [style.z-index]="ppZIndex">
  <div class="bulkChangesBar__left">
    <span class="bulkChangesBar__left-text">
      {{ ppSelectedNumber }}
    </span>
  </div>

  <div class="bulkChangesBar__right">
    <h2 class="bulkChangesBar__permissions">
      {{ ppSelectedTextKey | ppTranslate }}
    </h2>

    <div class="bulkChangesBar__buttons">
      <ng-content></ng-content>
    </div>

    <button class="bulkChangesBar__close" (click)="close()">
      <div class="bulkChangesBar__close--icon">
        <pp-icon
          [ppSrc]="EIconPath.ICON_CLOSE_18"
          ppColor="inherit"
          ppClass="setSize18"
          [ppDisplayContents]="true"
        ></pp-icon>
      </div>
    </button>
  </div>
</div>
