import { Injectable } from '@angular/core';
import { API_comment, API_comment_point, API_comment_search } from '@core/api/paths';
import { ApiService } from '@core/http';
import { Observable } from 'rxjs';
import { TCommentData } from 'src/app/project/modules/points/comment-response.model';
import { TCommentSearchData } from 'src/app/project/view-models/comment-search-response.model';
import { TNewComment, TSearchCommentRequest } from './comment-requests.model';

@Injectable({
  providedIn: 'root',
})
export class CommentApiProviderService {
  constructor(private apiService: ApiService) {}

  fetchComments(_id: string): Observable<TCommentData[]> {
    const url = API_comment_point(_id);

    return this.apiService.get<TCommentData[]>(url);
  }

  deleteComment(commentId: string): Observable<null> {
    const url = API_comment(commentId);

    return this.apiService.delete<null>(url);
  }

  searchComment(body: TSearchCommentRequest): Observable<TCommentSearchData> {
    const url = API_comment_search();

    return this.apiService.post<TCommentSearchData>(url, body);
  }

  addComment(_id: string, body: TNewComment): Observable<TCommentData> {
    const url = API_comment_point(_id);

    return this.apiService.post<TCommentData>(url, body);
  }
}
