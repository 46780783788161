import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TAllCustomFields } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { TCustomFieldFilter, TFilters } from 'src/app/project/modules/filters/site-filter.model';

@Component({
  selector: 'pp-filter-field-date',
  templateUrl: './filter-field-date.component.html',
  styleUrls: ['../../../site-filter-dropdown/site-filter-dropdown.component.scss'],
})
export class FilterFieldDateComponent {
  @Input() ppFilters: TFilters;
  @Input() ppCustomFields: TAllCustomFields;
  @Input() ppField: TCustomFieldFilter;
  @Output() ppApply = new EventEmitter();

  constructor(private customFieldsService: CustomFieldsService) {}

  updateDate(selectedDates: Date[], label: string, type: string): void {
    const customFields = this.customFieldsService.getCustomFields();

    const index = this.ppFilters.customFields.findIndex(
      (field) => customFields[field.workspaceId][field.id].label === label,
    );

    this.ppFilters.customFields[index][type] = selectedDates[0].getTime();
    this.ppFilters.customFields[index].excluded = false;

    this.applyFilters();
  }

  applyFilters(): void {
    this.ppApply.emit();
  }
}
