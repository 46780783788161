import { fabric } from 'fabric';

import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import {
  EImageAnnotationsState,
  ImageAnnotationsStateService,
} from './image-annotations-state.service';
import { Subject } from 'rxjs';
import { TAnnotationsCoords } from './image-annotation.model';

@Injectable({
  providedIn: 'root',
})
export class ImageAnnotationsEllipseService implements OnDestroy {
  canvasFabric: fabric.Canvas = null;
  currentMode: EImageAnnotationsState = null;

  private readonly destroy$ = new Subject<void>();

  constructor(private imageAnnotationsStateService: ImageAnnotationsStateService) {
    this.imageAnnotationsStateService.modeChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe((newMode) => {
        this.currentMode = newMode;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  setCanvasFabric(canvasFabric: fabric.Canvas): void {
    this.canvasFabric = canvasFabric;
  }

  addEllipse(color: string, coordinates?: TAnnotationsCoords): void {
    let ellipse = this.makeEllipse(coordinates, color);

    this.canvasFabric.add(ellipse);
    this.canvasFabric.selectionColor = 'transparent';
    this.canvasFabric.selectionBorderColor = 'transparent';

    this.canvasFabric.on('mouse:move', (e) => {
      if (this.currentMode === EImageAnnotationsState.ADDING_ELLIPSE && ellipse) {
        this.onMouseMove(e, ellipse, coordinates);
      }
    });

    this.canvasFabric.on('mouse:up', () => {
      if (this.currentMode === EImageAnnotationsState.ADDING_ELLIPSE && ellipse) {
        this.onMouseUp(ellipse);
        ellipse = null;
      }

      this.canvasFabric.off('mouse:up');
    });
  }

  private onMouseMove(
    e: MouseEvent,
    ellipse: fabric.Ellipse,
    coordinates: TAnnotationsCoords,
  ): void {
    const p = e['pointer'];

    let rx = Math.abs(+coordinates.x - p.x) / 2;
    let ry = Math.abs(+coordinates.y - p.y) / 2;

    if (rx > ellipse.strokeWidth) {
      rx -= ellipse.strokeWidth / 2;
    }

    if (ry > ellipse.strokeWidth) {
      ry -= ellipse.strokeWidth / 2;
    }

    ellipse.set({
      rx,
      ry,
    });

    if (coordinates.x > p.x) {
      ellipse.set({ originX: 'right' });
    } else {
      ellipse.set({ originX: 'left' });
    }

    if (coordinates.y > p.y) {
      ellipse.set({ originY: 'bottom' });
    } else {
      ellipse.set({ originY: 'top' });
    }

    this.canvasFabric.renderAll();
  }

  private onMouseUp(ellipse: fabric.Ellipse): void {
    ellipse.setCoords();

    this.canvasFabric.discardActiveObject();
    this.canvasFabric.selectionColor = 'rgba(100, 100, 255, 0.3)';
    this.canvasFabric.off('mouse:move');
    this.canvasFabric.renderAll();
  }

  private makeEllipse(coords: TAnnotationsCoords, color: string): fabric.Ellipse {
    const e = new fabric.Ellipse({
      left: coords.x,
      top: coords.y,
      fill: 'transparent',
      originX: 'left',
      originY: 'top',
      rx: 0,
      ry: 0,
      stroke: color,
      strokeWidth: 2,
      hasControls: false,
      hasRotatingPoint: true,
      perPixelTargetFind: true,
      lockScalingX: true,
      lockScalingY: true,
    });

    e.name = 'ellipse';

    return e;
  }
}
