import { cloneDeep } from 'lodash';

import { ActionsUnion, EActionType } from './user.actions';
import { TUser } from './user.model';

// TODO Make missing user null instead of empty object
export const initialState: TUser = {};

let newState: TUser = {};

export function userReducer(state: TUser = initialState, action: ActionsUnion): TUser {
  switch (action.type) {
    case EActionType.SET_USER:
      return action.payload;

    case EActionType.CLEAR_USER:
      return {};

    case EActionType.SET_AVATAR_ID:
      newState = cloneDeep(state);

      newState.avatarId = action.payload;

      return newState;

    case EActionType.UPDATE_USER_NAME:
      newState = cloneDeep(state);

      newState.userName = action.payload.userName;

      return newState;

    case EActionType.UPDATE_USER_EMAIL:
      newState = cloneDeep(state);

      newState.email = action.payload.email;

      return newState;

    case EActionType.UPDATE_USER_TYPE:
      newState = cloneDeep(state);

      newState.userType = action.payload.userType;

      return newState;

    case EActionType.UPDATE_ACTIVE_WORKSPACE_ID:
      newState = cloneDeep(state);

      newState.activeWorkspaceId = action.payload.activeWorkspaceId;

      return newState;

    case EActionType.UPDATE_USER_UNREAD_NOTIFICATIONS:
      newState = cloneDeep(state);

      newState.unreadNotifications = action.payload;

      return newState;

    case EActionType.UPDATE_USER_TWO_FACTOR_AUTHENTICATION:
      newState = cloneDeep(state);

      newState.enabled2fa = action.payload.enabled2fa;

      return newState;

    default:
      return state;
  }
}
