import { TFilters } from '../site-filter.model';
import { areArraysEqualSets } from './are-arrays-equal-sets';

export function compareFilterCreatedBy(firstFilter: TFilters, secondFilter: TFilters): boolean {
  const createdBy1 = firstFilter.createdBy;
  const createdBy2 = secondFilter.createdBy;

  if (createdBy1.value.length !== createdBy2.value.length) {
    return false;
  }

  return areArraysEqualSets(createdBy1.value, createdBy2.value);
}
