<div
  class="sharesHeaders__customFields_edit sharesHeaders__customFields_width"
  ppTooltip
  [ppTitle]="
    disabledIfAdmin
      ? disabledIfAdminTooltipText
      : ppDisabledByCustomFields
      ? ('no_custom_fields_exist' | ppTranslate)
      : ''
  "
>
  <pp-button
    ppStyle="primary"
    (ppAction)="showCustomFieldsTable()"
    ppSize="small"
    [ppDisabled]="disabledIfAdmin || ppDisabledByCustomFields"
  >
    {{ 'edit' | ppTranslate }}
  </pp-button>
</div>
