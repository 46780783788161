{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-04-28",
    "endpointPrefix": "cloudhsmv2",
    "jsonVersion": "1.1",
    "protocol": "json",
    "protocols": [
      "json"
    ],
    "serviceAbbreviation": "CloudHSM V2",
    "serviceFullName": "AWS CloudHSM V2",
    "serviceId": "CloudHSM V2",
    "signatureVersion": "v4",
    "signingName": "cloudhsm",
    "targetPrefix": "BaldrApiService",
    "uid": "cloudhsmv2-2017-04-28"
  },
  "operations": {
    "CopyBackupToRegion": {
      "input": {
        "type": "structure",
        "required": [
          "DestinationRegion",
          "BackupId"
        ],
        "members": {
          "DestinationRegion": {},
          "BackupId": {},
          "TagList": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DestinationBackup": {
            "type": "structure",
            "members": {
              "CreateTimestamp": {
                "type": "timestamp"
              },
              "SourceRegion": {},
              "SourceBackup": {},
              "SourceCluster": {}
            }
          }
        }
      }
    },
    "CreateCluster": {
      "input": {
        "type": "structure",
        "required": [
          "HsmType",
          "SubnetIds"
        ],
        "members": {
          "BackupRetentionPolicy": {
            "shape": "Sd"
          },
          "HsmType": {},
          "SourceBackupId": {},
          "SubnetIds": {
            "type": "list",
            "member": {}
          },
          "TagList": {
            "shape": "S4"
          },
          "Mode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "Sl"
          }
        }
      }
    },
    "CreateHsm": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId",
          "AvailabilityZone"
        ],
        "members": {
          "ClusterId": {},
          "AvailabilityZone": {},
          "IpAddress": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Hsm": {
            "shape": "So"
          }
        }
      }
    },
    "DeleteBackup": {
      "input": {
        "type": "structure",
        "required": [
          "BackupId"
        ],
        "members": {
          "BackupId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Backup": {
            "shape": "S17"
          }
        }
      }
    },
    "DeleteCluster": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId"
        ],
        "members": {
          "ClusterId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "Sl"
          }
        }
      }
    },
    "DeleteHsm": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId"
        ],
        "members": {
          "ClusterId": {},
          "HsmId": {},
          "EniId": {},
          "EniIp": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HsmId": {}
        }
      }
    },
    "DescribeBackups": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "S1h"
          },
          "SortAscending": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Backups": {
            "type": "list",
            "member": {
              "shape": "S17"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeClusters": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S1h"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Clusters": {
            "type": "list",
            "member": {
              "shape": "Sl"
            }
          },
          "NextToken": {}
        }
      }
    },
    "InitializeCluster": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId",
          "SignedCert",
          "TrustAnchor"
        ],
        "members": {
          "ClusterId": {},
          "SignedCert": {},
          "TrustAnchor": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "State": {},
          "StateMessage": {}
        }
      }
    },
    "ListTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId"
        ],
        "members": {
          "ResourceId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TagList"
        ],
        "members": {
          "TagList": {
            "shape": "S4"
          },
          "NextToken": {}
        }
      }
    },
    "ModifyBackupAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "BackupId",
          "NeverExpires"
        ],
        "members": {
          "BackupId": {},
          "NeverExpires": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Backup": {
            "shape": "S17"
          }
        }
      }
    },
    "ModifyCluster": {
      "input": {
        "type": "structure",
        "required": [
          "BackupRetentionPolicy",
          "ClusterId"
        ],
        "members": {
          "BackupRetentionPolicy": {
            "shape": "Sd"
          },
          "ClusterId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Cluster": {
            "shape": "Sl"
          }
        }
      }
    },
    "RestoreBackup": {
      "input": {
        "type": "structure",
        "required": [
          "BackupId"
        ],
        "members": {
          "BackupId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Backup": {
            "shape": "S17"
          }
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "TagList"
        ],
        "members": {
          "ResourceId": {},
          "TagList": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "TagKeyList"
        ],
        "members": {
          "ResourceId": {},
          "TagKeyList": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sd": {
      "type": "structure",
      "members": {
        "Type": {},
        "Value": {}
      }
    },
    "Sl": {
      "type": "structure",
      "members": {
        "BackupPolicy": {},
        "BackupRetentionPolicy": {
          "shape": "Sd"
        },
        "ClusterId": {},
        "CreateTimestamp": {
          "type": "timestamp"
        },
        "Hsms": {
          "type": "list",
          "member": {
            "shape": "So"
          }
        },
        "HsmType": {},
        "PreCoPassword": {},
        "SecurityGroup": {},
        "SourceBackupId": {},
        "State": {},
        "StateMessage": {},
        "SubnetMapping": {
          "type": "map",
          "key": {},
          "value": {}
        },
        "VpcId": {},
        "Certificates": {
          "type": "structure",
          "members": {
            "ClusterCsr": {},
            "HsmCertificate": {},
            "AwsHardwareCertificate": {},
            "ManufacturerHardwareCertificate": {},
            "ClusterCertificate": {}
          }
        },
        "TagList": {
          "shape": "S4"
        },
        "Mode": {}
      }
    },
    "So": {
      "type": "structure",
      "required": [
        "HsmId"
      ],
      "members": {
        "AvailabilityZone": {},
        "ClusterId": {},
        "SubnetId": {},
        "EniId": {},
        "EniIp": {},
        "HsmId": {},
        "State": {},
        "StateMessage": {}
      }
    },
    "S17": {
      "type": "structure",
      "required": [
        "BackupId"
      ],
      "members": {
        "BackupId": {},
        "BackupState": {},
        "ClusterId": {},
        "CreateTimestamp": {
          "type": "timestamp"
        },
        "CopyTimestamp": {
          "type": "timestamp"
        },
        "NeverExpires": {
          "type": "boolean"
        },
        "SourceRegion": {},
        "SourceBackup": {},
        "SourceCluster": {},
        "DeleteTimestamp": {
          "type": "timestamp"
        },
        "TagList": {
          "shape": "S4"
        },
        "HsmType": {},
        "Mode": {}
      }
    },
    "S1h": {
      "type": "map",
      "key": {},
      "value": {
        "type": "list",
        "member": {}
      }
    }
  }
}