import { Component, Input } from '@angular/core';
import { EIconPath } from '../../../../../../../shared/enums/icons.enum';
import { TActivityChange } from '../../activity-change.model';

@Component({
  selector: 'pp-dashlet-activity-description',
  templateUrl: './dashlet-activity-description.component.html',
  styleUrls: ['../../dashlet-activity.component.scss'],
})
export class DashletActivityDescriptionComponent {
  @Input() ppChange: TActivityChange<string>;

  EIconPath = EIconPath;
}
