import { TPoint } from '../../points/points.model';
import { TColumn } from '../../site/site-table/columns/column.model';

export function calculateGroupColumnSum(points: TPoint[], column: TColumn): number {
  let value = 0;

  points.forEach((point) => {
    column.customFieldIds.forEach((customFieldId) => {
      if (point.customFieldsMap[customFieldId]) {
        value += Number(point.customFieldsMap[customFieldId].value);
      }
    });
  });
  return value;
}
