<pp-button
  [ppDisabled]="!view || !viewEditable"
  ppStyle="secondary"
  [ppProcess]="ppProcessing"
  (ppAction)="saveToCurrentView()"
  ppTooltip
  [ppTitle]="tooltipTitle"
>
  {{ 'save_to_current_view' | ppTranslate }}
</pp-button>
