import { translate } from 'src/app/project/features/translate/translate';
import { EDashletType } from '../../dashlets-enums';

export function getChartName(chartType) {
  switch (chartType) {
    case EDashletType.OVER_TIME_PRIORITY:
      return translate('dashlet_over_time_priority');
    case EDashletType.OVER_TIME_STATUS:
      return translate('dashlet_over_time_status');
    case EDashletType.CURRENT_PRIORITY:
      return translate('dashlets_live_priority');
    case EDashletType.CURRENT_STATUS:
      return translate('dashlets_live_status');
    case EDashletType.CURRENT_STACKED_STATUS:
      return translate('dashlet_stacked_status');
    case EDashletType.COST_COMPARISON:
      return translate('dashlet_cost_comparison');
    default:
      return '';
  }
}
