import { Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { NotificationsService } from 'src/app/project/modules/notifications/notifications.service';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSidePanel,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EStore } from '../../../../shared/enums/store.enum';
import { PointAttachmentsService } from '../../../points/point-modal/point-attachments/point-attachments.service';
import { UpdateUserUnreadNotifications } from '../../../user/user.actions';
import { TWorkspacesById } from '../../../workspace/workspace.model';
import { AccountListService } from '../account-list.service';
import { TSidePanel } from '../side-panel.model';
import { SidePanelService } from '../side-panel.service';

@Injectable({
  providedIn: 'root',
})
export class SidePanelUtilsService implements OnDestroy {
  private sidePanel: TSidePanel = this.sidePanelService.getSidePanel();
  private updatingNotificationsStatus = false;
  private notificationsAvailable = this.notificationsService.notificationsAvailable();
  private workspaces$: Observable<TWorkspacesById>;
  private hasWorkspaces = false;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
    }>,
    private sidePanelService: SidePanelService,
    private accountListService: AccountListService,
    private notificationsService: NotificationsService,
    private pointAttachmentsService: PointAttachmentsService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.hasWorkspaces = !!workspaces;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  collapsePanel(): void {
    this.sidePanelService.setRightPanelExpanded(false);
    const clickOutsideHandler = this.sidePanelService.getClickOutsideHandler();

    if (this.sidePanel.expanded) {
      this.sidePanelService.setSidePanel({ expanded: false });
      this.accountListService.setAccountsPanel(false);
      clickOutsideHandler.disable();
    }
  }

  toggleNotifications(): void {
    const isUploading = this.pointAttachmentsService.getAttachmentUploading();

    if (isUploading) {
      return;
    }

    if (this.notificationsAvailable.fetched && this.hasWorkspaces) {
      this.sidePanel.notificationsExpanded = !this.sidePanel.notificationsExpanded;

      this.collapsePanel();

      if (this.sidePanel.notificationsExpanded) {
        logEventInGTAG(EGoogleEventSidePanel.SIDE_PANEL_NOTIFICATIONS, {
          event_category: EGoogleEventCategory.SIDE_PANEL,
        });
      }

      this.updateNotificationStatus();
    }
  }

  private updateNotificationStatus(): void {
    if (!this.updatingNotificationsStatus) {
      this.updatingNotificationsStatus = true;

      this.notificationsService
        .updateUnreadStatus()
        .pipe(
          takeUntil(this.destroy$),
          tap(() => {
            this.store.dispatch(new UpdateUserUnreadNotifications(false));

            this.updatingNotificationsStatus = false;
          }),
        )
        .subscribe();
    }
  }

  expandPanel(): void {
    this.sidePanel.notificationsExpanded = false;

    if (!this.sidePanel.expanded) {
      const clickOutsideHandler = this.sidePanelService.getClickOutsideHandler();

      this.sidePanelService.setSidePanel({ expanded: true });
      this.accountListService.setAccountsPanel(true);
      clickOutsideHandler.enable();

      logEventInGTAG(EGoogleEventSidePanel.SIDE_PANEL_PANEL_EXPAND, {
        event_category: EGoogleEventCategory.SIDE_PANEL,
      });
    }
  }
}
