import { Injectable } from '@angular/core';
import { TColumn } from './column.model';
import {
  CLEAR_SORTING,
  GET_SORTING,
  MOVE_SORT_COLUMN,
  REMOVE_SORT,
  SET_SORTING,
} from './sorting.store';

@Injectable({
  providedIn: 'root',
})
export class TableSortingService {
  getSorting(): TColumn[] {
    return GET_SORTING();
  }

  setSorting(sorting: TColumn[]): void {
    SET_SORTING(sorting);
  }

  clearSorting(): void {
    CLEAR_SORTING();
  }

  removeSort(column: TColumn): void {
    REMOVE_SORT(column);
  }

  moveSortColumn(columnIndex: number, destinationIndex: number): void {
    MOVE_SORT_COLUMN(columnIndex, destinationIndex);
  }
}
