import { Component, OnInit } from '@angular/core';

import { PromptService } from '../../../components/prompt/prompt.service';
import { WhiteLabelService } from '../../white-label/white-label.service';
import { RegisterService } from './register.service';

import { catchError, finalize, tap } from 'rxjs/operators';
import { blurInput } from 'src/app/core/helpers/blur-input';
import { EStatusCode } from 'src/app/core/helpers/error-codes';
import { TSignupModel } from 'src/app/project/data-providers/api-providers/auth-api-provider/auth-signup-request.model';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { TTranslationKey } from '../../../features/translate/types';
import { AUTH_EMAIL_REGEX } from '../../../shared/constants/auth.constants';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { TPasswordInputVisibilityType } from '../auth.types';

@Component({
  selector: 'pp-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  credentials: TSignupModel;
  processing: boolean;
  passInputType: TPasswordInputVisibilityType;
  acceptedTerms: boolean;
  requestSent: boolean;
  inputDirty = false;
  correctPassword = false;
  confirmPassword = '';
  EIconPath = EIconPath;

  constructor(
    private registerService: RegisterService,
    private promptService: PromptService,
    private whiteLabelService: WhiteLabelService,
    private translationPipe: TranslationPipe,
  ) {}

  ngOnInit(): void {
    this.credentials = {
      email: '',
      companyName: '',
      passwordHash: '',
    };

    this.processing = false;
    this.passInputType = 'password';
    this.acceptedTerms = false;
    this.requestSent = false;

    this.whiteLabelService.setDefaultStyle();
  }

  signup(): void {
    if (
      this.processing ||
      !this.correctPassword ||
      this.credentials.passwordHash !== this.confirmPassword
    ) {
      return;
    }

    if (!AUTH_EMAIL_REGEX.test(this.credentials.email)) {
      const promptText = this.translationPipe.transform('prompt_email_invalid');

      this.promptService.showError(promptText);

      return;
    }

    this.processing = true;

    this.registerService
      .signup(this.credentials)
      .pipe(
        tap(() => {
          this.requestSent = true;
        }),
        catchError((error) => {
          this.showErrorPrompt(error.status);

          throw new Error(error);
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  checkPassword(): void {
    this.inputDirty = true;
  }

  toggleAcceptedTerms(): void {
    this.acceptedTerms = !this.acceptedTerms;
  }

  validatePassword(valid: boolean): void {
    this.correctPassword = valid;
  }

  blurInput(event: Event): void {
    blurInput(event.target);
  }

  private showErrorPrompt(errorStatus: number): void {
    let key: TTranslationKey;

    switch (errorStatus) {
      case EStatusCode.BAD_REQUEST:
        key = 'prompt_email_already_registered';

        break;
      case EStatusCode.UNAUTHORIZED:
        key = 'register_unauthorized';

        break;
      default:
        key = 'prompt_error';
    }

    const promptText = this.translationPipe.transform(key);
    this.promptService.showError(promptText);
  }
}
