import { Injectable } from '@angular/core';
import {
  API_share,
  API_share_account,
  API_share_id,
  API_share_import_excel,
  API_share_resend,
  API_share_simple_update,
  API_share_workspace,
  API_share_workspace_import,
  API_share_workspace_target,
} from '@core/api/paths';
import { TGuid } from '@core/helpers';
import { ApiService } from '@core/http';

import { Observable } from 'rxjs';
import {
  TCreateAccountAdminDTO,
  TCreateShareDTO,
  TImportShareWorkspaceUsersDTO,
  TNewUser,
  TShare,
  TUpdateShareDTO,
} from 'src/app/project/modules/share/share.model';
import { TAccountUser } from 'src/app/project/modules/users/account.user.model';
import {
  API_share_account_admin,
  API_share_account_admin_delete,
  API_share_account_admin_downgrade,
  API_share_account_user,
  API_share_bulk,
  API_share_bulk_edit,
} from './share-paths';

@Injectable({
  providedIn: 'root',
})
export class SharesApiProviderService {
  constructor(private apiService: ApiService) {}

  getAll(refetch: boolean = false): Observable<TShare[]> {
    const url = API_share();

    return this.apiService.get<TShare[]>(url);
  }

  getShare(shareId: TGuid): Observable<TShare> {
    const url = API_share_id(shareId);

    return this.apiService.get<TShare>(url);
  }

  simpleUpdateShare(shareId: TGuid, share: TUpdateShareDTO): Observable<TShare> {
    const url = API_share_simple_update(shareId);

    return this.apiService.put<TShare>(url, share);
  }

  deleteShare(shareId: TGuid): Observable<void> {
    const url = API_share_id(shareId);

    return this.apiService.delete<void>(url);
  }

  resendShare(shareId: TGuid): Observable<void> {
    const url = API_share_resend(shareId);

    return this.apiService.put<void>(url, null);
  }

  getWorkspaceShare(workspaceId: string): Observable<TShare> {
    const url = API_share_workspace(workspaceId);

    return this.apiService.get<TShare>(url);
  }

  getTargetShares(workspaceId: string): Observable<TShare[]> {
    const url = API_share_workspace_target(workspaceId);

    return this.apiService.get<TShare[]>(url);
  }

  importShareWorkspaceUsers(
    targetWorkspaceId: string,
    shareUserList: TImportShareWorkspaceUsersDTO,
  ): Observable<TShare[]> {
    const url = API_share_workspace_import(targetWorkspaceId);

    return this.apiService.post<TShare[]>(url, shareUserList);
  }

  getShareAccount(accountId: TGuid): Observable<TAccountUser[]> {
    const url = API_share_account(accountId);

    return this.apiService.get<TAccountUser[]>(url);
  }

  importExcelShares(workspaceId: string, formData: FormData): Observable<TNewUser[]> {
    const url = API_share_import_excel(workspaceId);

    return this.apiService.postWithFormData<TNewUser[]>(url, formData);
  }

  bulkRemoveShares(shares: string[]): Observable<null> {
    const url = API_share_bulk();

    return this.apiService.delete(url, shares);
  }

  removeAccountUser(userId: string, accountId: string): Observable<null> {
    const url = API_share_account_user(userId, accountId);

    return this.apiService.delete(url);
  }

  bulkEditShares(shares: TUpdateShareDTO[]): Observable<TNewUser[]> {
    const url = API_share_bulk_edit();

    return this.apiService.put(url, shares);
  }

  bulkCreateShare(shares: TCreateShareDTO[]): Observable<TNewUser[]> {
    const url = API_share_bulk();

    return this.apiService.post(url, shares);
  }

  createAccountAdmin(body: TCreateAccountAdminDTO[]): Observable<TShare[]> {
    const url = API_share_account_admin();

    return this.apiService.post(url, body);
  }

  removeAccountAdmin(body: TCreateAccountAdminDTO): Observable<null> {
    const url = API_share_account_admin_delete();

    return this.apiService.delete(url, body);
  }

  downgradeAccountAdmin(shares: TCreateShareDTO[]): Observable<TNewUser[]> {
    const url = API_share_account_admin_downgrade();

    return this.apiService.put(url, shares);
  }
}
