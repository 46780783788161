<div class="dashletActivity dashboard__tags-activity">
  <div class="dashletActivity__tags-text-container">
    <img class="dashletActivity__icon" [src]="EIconPath.BASIC_FIELD_TAGS" />

    <div class="dashletActivity__text">
      <span class="tagDescription"> {{ ppLabel }}: </span>
    </div>
  </div>

  <div class="dashletActivity__tags-container">
    <ng-container *ngFor="let tag of ppTags; let last = last">
      <span class="dashletActivities__item-quoted">
        <pp-tag [ppText]="tag" [ppDeletable]="false" [ppDisabled]="true"></pp-tag>
      </span>
    </ng-container>
  </div>
</div>
