import { checkElement } from '@core/helpers';
import { createElement } from 'src/app/core/helpers/dom';
import { formatCurrency } from 'src/app/core/helpers/format-currency';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { GET_CUSTOM_FIELDS } from 'src/app/project/modules/custom-fields/custom-fields.store';
import { TPoint } from 'src/app/project/modules/points/points.model';
import { TColumn } from '../../../columns/column.model';
import { GET_TABLE } from '../../../table.ui.store';
import { createFooterTooltip } from './create-footer-tooltip';

export function createTotalCostCellInnerElement(
  cellNameElement: HTMLElement,
  column: TColumn,
  points: TPoint[],
): void {
  const table = GET_TABLE();
  const customFields = GET_CUSTOM_FIELDS();

  let value = 0;
  cellNameElement.classList.add('table__head__cell--innerSum');

  const searchedPoints = points ? points : table.points;

  searchedPoints.forEach((point) => {
    if (column.customFieldIds) {
      column.customFieldIds.forEach((customFieldId) => {
        if (point.customFieldsMap[customFieldId]) {
          value += +point.customFieldsMap[customFieldId].value;
        }
      });
    } else if (point.customFieldsMap[column.customFieldIds[0]]) {
      value += +point.customFieldsMap[column.customFieldIds[0]].value;
    }
  });

  const costCurrencySymbol = customFields[column.customFieldIds[0]].currencySymbol;

  const decimalPlaces =
    customFields[column.customFieldIds[0]].type === ECustomFieldType.COST
      ? 2
      : +customFields[column.customFieldIds[0]].decimalPlaces;

  const formattedValue = formatCurrency(value, costCurrencySymbol, decimalPlaces);

  const valueElement = createElement<HTMLElement>('span', {
    attrs: {
      class: 'site-table__total',
    },
    children: [formattedValue],
  });

  cellNameElement.appendChild(checkElement(valueElement));

  createFooterTooltip(formattedValue, valueElement);
}
