<div class="authorizationProgressBar">
  <div
    class="authorizationProgressBar__left"
    [class.authorizationProgressBar__active]="ppStep !== EAuthorizationProgressBarStep.EMPTY"
  ></div>
  <div
    class="authorizationProgressBar__right"
    [class.authorizationProgressBar__active]="ppStep === EAuthorizationProgressBarStep.FULL"
  ></div>
</div>
