import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { ImportTagModalService } from '../import-tag-modal.service';

@Component({
  selector: 'pp-import-tag-select',
  templateUrl: './import-tag-select.component.html',
  styleUrls: ['../import-tag-modal.component.scss'],
})
export class ImportTagSelectComponent implements OnInit, OnDestroy {
  @Input() ppFileName: string;
  @Input() ppInitialWorkspaceTags: string[];
  @Input() ppProcessingTags: boolean;
  @Output() ppBack = new EventEmitter();
  @Output() ppDeselectAll = new EventEmitter();
  @Output() ppSelectAll = new EventEmitter();
  @Output() ppCalculateTotalSelectedTagsNumber = new EventEmitter();

  @Input() ppFileLoaded: boolean;
  @Output() ppFileLoadedChange = new EventEmitter<boolean>();

  @Input() ppSite: TWorkspace;

  @Input() ppSelectedTags: {
    [accountId: string]: {
      [workspaceId: string]: string[];
    };
  };
  @Output() ppSelectedTagsChange = new EventEmitter<{
    [accountId: string]: {
      [workspaceId: string]: string[];
    };
  }>();

  @Input() ppMergedTags: string[];
  @Output() ppMergedTagsChange = new EventEmitter<string[]>();

  @Input() ppData: string[];

  @Input() ppTagsPerAccount: {
    [accountId: string]: number;
  };
  @Output() ppTagsPerAccountChange = new EventEmitter<{
    [accountId: string]: number;
  }>();

  @Input() ppTagsPerWorkspace: {
    [workspaceId: string]: number;
  };
  @Output() ppTagsPerWorkspaceChange = new EventEmitter<{
    [workspaceId: string]: number;
  }>();

  @Input() ppSelectedFileTags: string[];
  @Output() ppSelectedFileTagsChange = new EventEmitter<string[]>();

  selectableTags: string[];
  EIconPath = EIconPath;

  private destroy$ = new Subject<void>();

  constructor(private importTagModalService: ImportTagModalService) {
    this.selectableTags = this.importTagModalService.getSelectableTags();

    this.importTagModalService.selectableTagsChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.selectableTags = this.importTagModalService.getSelectableTags();
      });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  back(): void {
    this.ppBack.emit();
  }

  deselectAll(): void {
    this.ppDeselectAll.emit();
  }

  selectAll(): void {
    this.ppSelectAll.emit();
  }

  selectTag(selectedTag: string): void {
    if (this.ppProcessingTags) {
      return;
    }

    if (this.ppSite) {
      if (
        this.ppMergedTags.includes(selectedTag) &&
        !this.ppSelectedTags[this.ppSite.accountId][this.ppSite.workspaceId].includes(selectedTag)
      ) {
        return;
      }

      const tagIndex = this.ppSelectedTags[this.ppSite.accountId][
        this.ppSite.workspaceId
      ].findIndex((tag) => tag === selectedTag);

      const addedTagIndex = this.ppMergedTags.findIndex((tag) => tag === selectedTag);

      if (!this.ppTagsPerAccount[this.ppSite.accountId]) {
        this.ppTagsPerAccount[this.ppSite.accountId] = 0;
      }

      if (!this.ppTagsPerWorkspace[this.ppSite.workspaceId]) {
        this.ppTagsPerWorkspace[this.ppSite.workspaceId] = 0;
      }

      if (tagIndex > -1) {
        this.ppSelectedTags[this.ppSite.accountId][this.ppSite.workspaceId].splice(tagIndex, 1);

        if (this.ppSite) {
          this.ppTagsPerAccount[this.ppSite.accountId] -= 1;
          this.ppTagsPerWorkspace[this.ppSite.workspaceId] -= 1;
        }
      } else if (addedTagIndex > -1) {
        Object.keys(this.ppSelectedTags).forEach((accountId) => {
          Object.keys(this.ppSelectedTags[accountId]).forEach((workspaceId) => {
            const tagToRemoveIndex = this.ppSelectedTags[accountId][workspaceId].findIndex(
              (tag) => tag === selectedTag,
            );

            this.ppSelectedTags[accountId][workspaceId].splice(tagToRemoveIndex, 1);

            if (this.ppSite) {
              this.ppTagsPerWorkspace[workspaceId] -= 1;
              this.ppTagsPerAccount[accountId] -= 1;
            }
          });
        });
      } else {
        this.ppSelectedTags[this.ppSite.accountId][this.ppSite.workspaceId].push(selectedTag);

        if (this.ppSite) {
          this.ppTagsPerWorkspace[this.ppSite.workspaceId] += 1;
          this.ppTagsPerAccount[this.ppSite.accountId] += 1;
        }
      }
    } else {
      const tagIndex = this.ppSelectedFileTags.findIndex((tag) => tag === selectedTag);

      if (tagIndex > -1) {
        this.ppSelectedFileTags.splice(tagIndex, 1);
      } else {
        this.ppSelectedFileTags.push(selectedTag);
      }
    }

    this.ppCalculateTotalSelectedTagsNumber.emit();
    this.ppTagsPerAccountChange.emit(this.ppTagsPerAccount);
    this.ppTagsPerWorkspaceChange.emit(this.ppTagsPerWorkspace);
    this.ppSelectedTagsChange.emit(this.ppSelectedTags);
    this.ppSelectedFileTagsChange.emit(this.ppSelectedFileTags);
  }
}
