import { Injectable } from '@angular/core';
import { ApiService } from '@core/http';
import { TWorkspaceResponse } from '@project/view-models';
import { Observable } from 'rxjs';
import {
  EVolyIntegrationStatus,
  TAuditLog,
} from 'src/app/project/modules/integrations/utils/integrations.consts';
import {
  API_integration_voly_audit_log,
  API_integration_voly_custom_field_visibility_change,
  API_integration_voly_status_change,
} from './integrations-paths';

@Injectable({
  providedIn: 'root',
})
export class IntegrationsApiProviderService {
  constructor(private apiService: ApiService) {}

  changeVolyIntegrationStatus(
    workspaceId: string,
    status: EVolyIntegrationStatus,
  ): Observable<TWorkspaceResponse> {
    const url = API_integration_voly_status_change(workspaceId, status);

    return this.apiService.post(url);
  }

  changeVolyCustomFieldVisibility(
    workspaceId: string,
    customFieldId: string,
    display: boolean,
  ): Observable<void> {
    const url = API_integration_voly_custom_field_visibility_change(
      workspaceId,
      customFieldId,
      display,
    );

    return this.apiService.put(url);
  }

  getAuditLog(workspaceId: string): Observable<TAuditLog[]> {
    const url = API_integration_voly_audit_log(workspaceId);

    return this.apiService.get(url);
  }
}
