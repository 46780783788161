import { Component, Input } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EActivityPropName } from '../../../../../../shared/enums/activity-prop-name.enum';

@Component({
  selector: 'pp-attachment-deleted-activity',
  templateUrl: './attachment-deleted-activity.component.html',
  styleUrls: ['../activity-change.component.scss'],
})
export class AttachmentDeletedActivityComponent {
  @Input() ppChange;

  EActivityPropName = EActivityPropName;
  EIconPath = EIconPath;

  constructor() {}
}
