import { Component } from '@angular/core';
import { SavedViewsDropdownService } from '../saved-views-dropdown.service';

@Component({
  selector: 'pp-saved-views-done-button',
  templateUrl: './saved-views-done-button.component.html',
  styleUrls: ['./saved-views-done-button.component.scss'],
})
export class SavedViewsDoneButtonComponent {
  constructor(private savedViewsDropdownService: SavedViewsDropdownService) {}

  hideDropdown(): void {
    this.savedViewsDropdownService.getDropdownService().hideDropdown();
  }
}
