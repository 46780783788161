<div class="notification__image-wrapper">
  <img class="notification__image" [src]="EIconPath.NOTIFICATION_ICON_PRIORITY" />
</div>

<span>
  <span class="notification__blue-text">{{ 'changed' | ppTranslate }}</span>
  {{ 'the' | ppTranslate }}
  <span class="notification__bold">{{ ppNotification.changeBody.label }}</span>
  {{ 'of_a_point_you' | ppTranslate }}
  {{ notificationReason }}
  {{ 'from' | ppTranslate }}
  <span class="notification__bold notificationStatus--{{ oldPriorityColor }}">
    {{ oldPriorityText }}
  </span>
  {{ 'to' | ppTranslate | lowercase }}
  <span class="notification__bold notificationStatus--{{ newPriorityColor }}">{{
    newPriorityText
  }}</span
  >.
</span>
