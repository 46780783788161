import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ClearFilterService } from '../../clear-filter-service/clear-filter.service';
import { TFilters, TPriorityFilter } from '../../site-filter.model';

@Component({
  selector: 'pp-filter-field-priority',
  templateUrl: './filter-field-priority.component.html',
  styleUrls: ['../../site-filter-dropdown/site-filter-dropdown.component.scss'],
})
export class FilterFieldPriorityComponent implements OnChanges {
  @Input() ppFilters: TFilters;
  @Output() ppApply = new EventEmitter();

  isPriorityDefault = true;

  prioritiesColors = {
    HIGH: 'red',
    MEDIUM: 'yellow',
    LOW: 'blue',
  };

  constructor(private clearFilterService: ClearFilterService) {}

  ngOnChanges(): void {
    this.checkPriorityDefault();
  }

  clearPriority(): void {
    this.ppFilters.priority = this.clearFilterService.clearPriority();
    this.checkPriorityDefault();

    this.applyFilters();
  }

  updatePriorityValue(priority: TPriorityFilter): void {
    priority.value = !priority.value;
    this.checkPriorityDefault();

    this.applyFilters();
  }

  checkPriorityDefault(): void {
    this.isPriorityDefault = true;

    this.ppFilters.priority.forEach((priority) => {
      if (priority.value !== priority.default) {
        this.isPriorityDefault = false;
      }
    });
  }

  applyFilters(): void {
    this.ppApply.emit();
  }
}
