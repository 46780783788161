import { createElement } from 'src/app/core/helpers/dom';
import { TPoint } from 'src/app/project/modules/points/points.model';
import { TColumn } from '../../../columns/column.model';
import { GET_COLUMNS } from '../../../columns/columns.store';
import { GET_GROUPING } from '../../../columns/grouping.store';
import { checkShowingTotal } from '../../../custom-table/table-footer/utils/check-showing-total';
import { createGroupedHeaderCellInnerElement } from './create-grouped-header-cell-inner-element';
import { createHeaderEndElements } from './utils/create-header-end-elements';

export function createGroupedHeaderCellElement({
  child = null,
  className = null,
  center = false,
  width = null,
  column = null,
  isGroupedHeader = false,
  points = null,
  index = null,
  groupingLevel = null,
}: {
  child?: HTMLElement;
  className?: string;
  center?: boolean;
  resize?: boolean;
  width?: number;
  column?: TColumn;
  isGroupedHeader?: boolean;
  points?: TPoint[];
  index?: number;
  groupingLevel?: number;
}): HTMLElement {
  const childrenList: HTMLElement[] = [];
  const cellInnerElement = createGroupedHeaderCellInnerElement(child, column, points);
  const classList = ['table__cell__groupedHeader'];

  childrenList.push(cellInnerElement);

  const grouping = GET_GROUPING();
  const isLast = GET_COLUMNS().length - 1 === column?.index;

  if (isLast && grouping.length) {
    childrenList.push(...createHeaderEndElements(groupingLevel, index));

    if (
      grouping.length === 1 ||
      (grouping.length === 2 && groupingLevel === 2) ||
      (grouping.length === 3 && groupingLevel === 3)
    ) {
      classList.push('table__cell__groupedHeader--last');

      if (width && typeof width === 'number') {
        switch (groupingLevel) {
          case 1:
            width -= 2;
            break;
          case 2:
            width -= 0;
            break;
          case 3:
            width += 1;
            break;
        }
      }
    }
  }

  if (center) {
    cellInnerElement.style.textAlign = 'center';
    cellInnerElement.style.padding = '0';

    cellInnerElement.style.display = 'flex';
    cellInnerElement.style.justifyContent = 'center';
    cellInnerElement.style.alignItems = 'center';
  }

  const isShowingTotal = checkShowingTotal(column);

  if (className) {
    classList.push(className);
  }

  if (isShowingTotal && !isGroupedHeader) {
    classList.push('table__cell__groupedHeader--showTotal');
  }

  const cellElement = createElement<HTMLElement>('div', {
    attrs: {
      class: classList.join(' '),
      style: {
        width: typeof width === 'number' ? `${width}px` : 'auto',
      },
    },
    children: childrenList,
  });

  return cellElement;
}
