<div [ngStyle]="{ 'width': dropdownWidth }">
  <pp-hide-dropdown (ppHideDropdown)="hideDropdown()"></pp-hide-dropdown>

  <ul class="dropdownWrapper">
    <li *ngFor="let item of items" (click)="selectItem(item)" class="dropdownItem">
      <tr class="multipleSelect__row">
        <pp-toggle
          [ppId]="'multipleSelectToggle' + item.value"
          [ppChecked]="item.selected"
          ppStyle="filter"
          (ppAction)="selectItem(item)"
        ></pp-toggle>

        <pp-icon [ppDisplayContents]="true" [ppClass]="iconClass" ppColor="grey-500" [ppSrc]="item.iconSrc" *ngIf="item.iconSrc" />

        {{
          item.label
        }}
      </tr>
    </li>
  </ul>
</div>
