import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { preventNonNumberInput } from 'src/app/core/helpers/check-if-number';

@Component({
  selector: 'pp-digit',
  templateUrl: './digit.component.html',
  styleUrls: ['./digit.component.scss'],
})
export class DigitComponent implements OnChanges {
  @Input() ppDigit: number;
  @Output() ppDigitChange = new EventEmitter<string>();
  @Input() ppIndex: number;
  @Input() ppShowingError: boolean;

  digit: number;

  ngOnChanges(): void {
    this.digit = this.ppDigit;
  }

  onDigitChange(event: Event): void {
    if (event instanceof KeyboardEvent) {
      this.ppDigitChange.emit(event.key);
    }
  }

  preventNonNumberInput(event: KeyboardEvent): void {
    preventNonNumberInput(event);
  }
}
