<input
  class="digit"
  [class.digit--filled]="digit || digit === 0"
  [class.digit--error]="ppShowingError"
  autocomplete="none"
  type="text"
  [(ngModel)]="digit"
  (change)="onDigitChange($event)"
  (keypress)="preventNonNumberInput($event)"
  (keyup)="onDigitChange($event)"
  maxlength="1"
/>
