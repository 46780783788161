<h6 class="siteList__title">
  {{ 'select_site' | ppTranslate }}
</h6>

<div
  class="siteList__account"
  [class.account--expanded]="expandedAccounts.includes(account.accountId)"
  *ngFor="let account of ppAccounts"
>
  <div
    class="siteList__accountName"
    [class.siteList__accountName--expanded]="expandedAccounts.includes(account.accountId)"
    (click)="toggleAccount(account.accountId)"
  >
    <img
      [class.arrowDown]="expandedAccounts.includes(account.accountId)"
      [src]="EIconPath.INTERACTION_EXPAND_ICON"
    />

    <h6 class="siteList__accountName--text">
      {{ account.accountName }}
    </h6>

    <p
      class="selectedCustomFieldsCounter copy"
      *ngIf="
        !expandedAccounts.includes(account.accountId) &&
        ppFieldsPerAccount[account.accountId] &&
        ppFieldsPerAccount[account.accountId] > 1
      "
    >
      {{ ppFieldsPerAccount[account.accountId] }}
      {{ SiteListSelectTypesMultiple[ppType] | ppTranslate }}
    </p>

    <p
      *ngIf="
        !expandedAccounts.includes(account.accountId) &&
        ppFieldsPerAccount[account.accountId] &&
        ppFieldsPerAccount[account.accountId] === 1
      "
      class="selectedCustomFieldsCounter copy"
    >
      {{ ppFieldsPerAccount[account.accountId] }}
      {{ SiteListSelectTypesSingle[ppType] | ppTranslate }}
    </p>
  </div>

  <ng-container *ngIf="expandedAccounts.includes(account.accountId)">
    <pp-site-list-select-field
      *ngFor="let workspace of account.workspaces; let last = last"
      [ppActiveWorkspaceId]="activeWorkspaceId"
      [ppFieldsPerWorkspace]="ppFieldsPerWorkspace"
      [ppType]="ppType"
      [ppWorkspaceId]="workspace"
      (ppAction)="openSiteCallback($event)"
    ></pp-site-list-select-field>
  </ng-container>
</div>
