import { Component, Input } from '@angular/core';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { AccountSharesService } from 'src/app/project/modules/share/account-shares.service';
import { NewAccountAdminModalComponent } from '../../../../account/new-account-admin-modal/new-account-admin-modal.component';
import { TAddAccountAdminModalData } from '../../../../account/new-account-admin-modal/new-account-admin-modal.consts';

@Component({
  selector: 'pp-account-user-management-create-account-admin-button',
  templateUrl: './account-user-management-create-account-admin-button.component.html',
  styleUrls: ['./account-user-management-create-account-admin-button.component.scss'],
})
export class AccountUserManagementCreateAccountAdminButtonComponent {
  @Input() ppAccountId: string;

  constructor(
    private modalService: ModalService,
    private accountSharesService: AccountSharesService,
  ) {}

  openAddAccountAdminModal(): void {
    this.setAddAccountAdminModalData();

    this.modalService.showModal(NewAccountAdminModalComponent);
  }

  private setAddAccountAdminModalData(): void {
    this.modalService.setData<TAddAccountAdminModalData>({
      accountId: this.ppAccountId,
    });
  }
}
