<div class="floatingActions__wrapper">
  <div class="floatingActions">
    <ng-container *ngIf="user">
      <div class="floatingActions_points" *ngIf="ppNumberOfSelectedPoints < 201">
        {{ ppNumberOfSelectedPoints }}
      </div>
      <div class="floatingActions_points" *ngIf="ppNumberOfSelectedPoints >= 201">200+</div>

      <h3 class="floatingActions_description">
        {{ (ppNumberOfSelectedPoints > 1 ? 'points_selected' : 'point_selected') | ppTranslate }}
      </h3>

      <div
        class="dropup floatingActions_action"
        *ngIf="!offline && workspace && canEditPoint"
        id="edit__button"
        (mouseenter)="buttonHover('edit__button')"
        (mouseleave)="buttonHoverEnd()"
      >
        <div id="editButton" class="dropbtn" (click)="toggleOptionsDropdown('editButton')" #dropup>
          <div class="iconWrapper">
            <pp-icon
              [ppSrc]="EIconPath.ICON_USER_GROUPS_EDITABLE_24"
              [ppColor]="hovered === 'edit__button' ? 'primary-600' : 'grey-600'"
              [ppDisplayContents]="true"
            ></pp-icon>
          </div>

          <p class="floatingActions_label floatingActions--movedDown">Edit</p>
        </div>
      </div>
      <div
        class="floatingActions_action"
        (click)="copyPoints()"
        *ngIf="
          !offline &&
          workspace &&
          (workspace.share.shareOption === EUserRole.OWNER ||
            workspace.share.shareOption === EUserRole.SITE_ADMIN ||
            workspace.share.shareOption === EUserRole.ACCOUNT_ADMIN ||
            user.isSuperUser ||
            (workspace.share.shareOption === EUserRole.NORMAL && normalWithPermissions))
        "
        id="copy__button"
        (mouseenter)="buttonHover('copy__button')"
        (mouseleave)="buttonHoverEnd()"
      >
        <div class="iconWrapper">
          <pp-icon
            [ppSrc]="EIconPath.ICON_ACTIVITIES_COPY_24"
            [ppColor]="hovered === 'copy__button' ? 'primary-600' : 'grey-600'"
            [ppDisplayContents]="true"
          ></pp-icon>
        </div>

        <p class="floatingActions_label floatingActions--movedDown">Copy</p>
      </div>

      <div
        class="floatingActions_action"
        (click)="movePoints()"
        *ngIf="
          !offline &&
          workspace &&
          (workspace.share.shareOption === EUserRole.OWNER ||
            workspace.share.shareOption === EUserRole.SITE_ADMIN ||
            workspace.share.shareOption === EUserRole.ACCOUNT_ADMIN ||
            user.isSuperUser)
        "
        id="move__button"
        (mouseenter)="buttonHover('move__button')"
        (mouseleave)="buttonHoverEnd()"
      >
        <div class="iconWrapper">
          <pp-icon
            [ppColor]="hovered === 'move__button' ? 'primary-600' : 'grey-600'"
            [ppSrc]="EIconPath.ICON_MISC_MOVE_24"
            [ppDisplayContents]="true"
          ></pp-icon>
        </div>

        <p class="floatingActions_label floatingActions--movedDown">Move</p>
      </div>

      <div
        ppTooltip
        [ppTitle]="canExport ? '' : ('export_permission_denied' | ppTranslate)"
        class="floatingActions_action"
        (click)="export()"
        [class.floatingActions_action--disabled]="!canExport"
        id="export__button"
        (mouseenter)="buttonHover('export__button')"
        (mouseleave)="buttonHoverEnd()"
      >
        <div class="iconWrapper">
          <pp-icon
            [ppColor]="hovered === 'export__button' ? 'primary-600' : 'grey-600'"
            [ppSrc]="EIconPath.ICON_TABLE_EXPORT"
            [ppDisplayContents]="true"
          ></pp-icon>
        </div>

        <p class="floatingActions_label floatingActions_icon--label">Export</p>
      </div>

      <div
        class="floatingActions_action"
        (click)="deletePoints()"
        *ngIf="!offline && canDeletePoint"
        id="delete__button"
        (mouseenter)="buttonHover('delete__button')"
        (mouseleave)="buttonHoverEnd()"
      >
        <div class="iconWrapper">
          <pp-icon
            [ppSrc]="EIconPath.ICON_BIN_24"
            [ppColor]="hovered === 'delete__button' ? 'primary-600' : 'grey-600'"
            [ppDisplayContents]="true"
          ></pp-icon>
        </div>

        <p class="floatingActions_label floatingActions_icon--label">Delete</p>
      </div>

      <div class="point__control-btn" (click)="closeBulkChanges()">
        <img
          data-m-target="Bulk changes close button"
          class="floatingActions_close--button"
          [src]="EIconPath.FLOATING_ACTIONS_CLEAR"
        />
      </div>
    </ng-container>
  </div>
</div>
