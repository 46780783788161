import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslationPipe } from '../../project/features/translate/translation.pipe';
import { HighlightFragmentPipe } from './custom-field-list-select.pipe';
import { FirstLetterPipe } from './first-letter.pipe';
import { HighlightKeywordPipe } from './highlight-keyword.pipe';
import { DatePipe } from './date.pipe';
import { StatusImagePipe } from './status-image.pipe';

@NgModule({
  declarations: [
    TranslationPipe,
    HighlightKeywordPipe,
    DatePipe,
    FirstLetterPipe,
    HighlightFragmentPipe,
    StatusImagePipe,
  ],
  imports: [CommonModule],
  exports: [
    TranslationPipe,
    HighlightKeywordPipe,
    DatePipe,
    FirstLetterPipe,
    HighlightFragmentPipe,
    StatusImagePipe,
  ],
  providers: [
    TranslationPipe,
    HighlightKeywordPipe,
    DatePipe,
    FirstLetterPipe,
    HighlightFragmentPipe,
    StatusImagePipe,
  ],
})
export class PipesModule {}
