import { TAccount } from 'src/app/project/modules/account/account.model';
import { TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { EWorkspaces } from '../../../workspace/workspaces.enum';

export function checkAdvancedFiltersEnabled(
  workspaces: TWorkspacesById,
  accounts: TAccount[],
  workspaceId: string,
): boolean {
  if (
    !workspaceId ||
    workspaceId === EWorkspaces.OVERVIEW ||
    workspaceId === EWorkspaces.TIMELINE ||
    workspaceId === EWorkspaces.REMINDERS
  ) {
    return false;
  }

  if (workspaces[workspaceId]) {
    const account = accounts.find(
      (_account) => _account.accountId === workspaces[workspaceId].accountId,
    );

    return account.accountFeatures.advancedFilters;
  }

  return false;
}
