import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { blurInput } from 'src/app/core/helpers/blur-input';
import { EKeyboardKeyCode } from 'src/app/core/helpers/keyboard-codes';

@Component({
  selector: 'pp-digit-input',
  templateUrl: './digit-input.component.html',
  styleUrls: ['./digit-input.component.scss'],
})
export class DigitInputComponent implements OnInit, AfterViewInit {
  @ViewChild('digitInput') digitInput: ElementRef<HTMLInputElement>;

  @Input() ppInput: string;
  @Output() ppInputChange = new EventEmitter<string>();
  @Input() ppDigitsNumber: number;
  @Input() ppShowingError: boolean;
  @Output() ppConfirm = new EventEmitter<void>();

  digits: number[];

  constructor(private ngZone: NgZone) {}

  ngOnInit(): void {
    this.setDigits();
  }

  ngAfterViewInit(): void {
    this.focusElement(0);
  }

  blurInput(event: Event) {
    blurInput(event.target);
  }

  confirmInput(): void {
    if (this.ppInput.length === this.ppDigitsNumber) {
      this.ppConfirm.emit();
    }
  }

  onDigitChange(eventKey: string, index: number): void {
    const digitToFocus = this.updateDigit(eventKey, index);

    this.ppInputChange.emit(this.digits.join(''));

    this.changeFocusedElement(eventKey, digitToFocus);
  }

  private updateDigit(eventKey: string, index: number): number {
    if (this.isNumber(eventKey)) {
      this.digits[index] = +eventKey;

      return index;
    } else if (eventKey === EKeyboardKeyCode.Backspace) {
      let newIndex = this.moveAndClearToLastFilledDigit(index);

      return newIndex + 1; // +1 to deal with Angular change detection
    }
  }

  private moveAndClearToLastFilledDigit(index: number): number {
    let newIndex = index;

    while (newIndex >= 0) {
      if (this.digits[newIndex] || this.digits[newIndex] === 0) {
        this.digits[newIndex] = null;
        break;
      } else {
        newIndex--;
      }
    }

    return newIndex;
  }

  private changeFocusedElement(eventKey: string, index: number): void {
    if (eventKey === EKeyboardKeyCode.Backspace) {
      this.goBack(index);
    } else {
      this.focusInputElement(index);
    }
  }

  private goBack(index: number): void {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        const previousElementExists = index > 0;

        if (previousElementExists) {
          this.focusElement(index - 1);
        } else {
          this.focusElement(index);
        }
      });
    });
  }

  private focusInputElement(index: number): void {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        const nextElementExists = index < this.ppDigitsNumber - 1;
        const currentElementFilled = !!this.digits[index] || this.digits[index] === 0;

        if (nextElementExists && currentElementFilled) {
          this.focusElement(index + 1);
        } else {
          this.focusElement(index);
        }
      });
    });
  }

  private focusElement(index: number): void {
    const input = this.digitInput.nativeElement.children[index].children[0] as HTMLInputElement;

    input.focus();
  }

  private setDigits(): void {
    this.digits = Array(this.ppDigitsNumber)
      .fill(null)
      .map((x, i) => null);

    this.digits.forEach((digit, index) => {
      this.digits[index] = this.ppInput[index] ? +this.ppInput[index] : null;
    });
  }

  private isNumber(eventKey: string): boolean {
    return !isNaN(+eventKey);
  }
}
