import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  TAdvancedFilterSingle,
  TAdvancedFilterStatus,
  TFilterStatusOptions,
} from '../../../../models/advanced-filter.model';
import { EStatus } from 'src/app/project/shared/enums/status.enum';
import { EAdvancedFilterOptions } from '../../../../models/advanced-filter-options.enum';
import { getAdvancedStatusOptions, getAdvancedStatusValues } from './advanced-filter-status-utils';

@Component({
  selector: 'pp-advanced-filter-status',
  templateUrl: './advanced-filter-status.component.html',
  styleUrls: ['./advanced-filter-status.component.scss'],
})
export class AdvancedFilterStatusComponent {
  @Input() ppFilter: TAdvancedFilterSingle;
  @Input() ppIndex: number;
  @Output() ppFilterChange = new EventEmitter<TAdvancedFilterSingle>();

  value: EStatus[];
  option: TFilterStatusOptions;
  filter: TAdvancedFilterStatus;

  ADVANCED_FILTER_STATUS_OPTIONS = getAdvancedStatusOptions();
  ADVANCED_FILTER_STATUS_VALUES = getAdvancedStatusValues();
  EAdvancedFilterOptions = EAdvancedFilterOptions;

  ngOnChanges(): void {
    this.filter = this.ppFilter as TAdvancedFilterStatus;

    this.value = this.filter.value ? this.filter.value : [];
    this.option = this.filter.option;

    this.ADVANCED_FILTER_STATUS_VALUES.forEach((status) => {
      status.selected = this.filter.value?.includes(status.value as EStatus);
    });
  }

  changeFieldCondition(newCondition: TFilterStatusOptions): void {
    if (
      (newCondition === EAdvancedFilterOptions.IS ||
        newCondition === EAdvancedFilterOptions.IS_NOT) &&
      this.filter.value?.length
    ) {
      this.filter.value.length = 1;
      this.value = this.filter.value;

      this.ADVANCED_FILTER_STATUS_VALUES.forEach((priority) => {
        priority.selected = this.filter.value?.includes(priority.value as EStatus);
      });
    }

    this.ppFilter.option = newCondition;
    this.option = newCondition;
    this.applyChanges();
  }

  changeFieldValue(newValue: EStatus): void {
    if (this.filter.value?.length) {
      this.ppFilter.value[0] = newValue;
    } else {
      this.ppFilter.value = [newValue];
    }

    this.applyChanges();
  }

  changeFieldValueMultiple(): void {
    this.filter.value = this.ADVANCED_FILTER_STATUS_VALUES.filter((value) => value.selected).map(
      (value) => value.value,
    ) as EStatus[];

    this.applyChanges();
  }

  applyChanges(): void {
    this.ppFilterChange.emit(this.ppFilter);
  }
}
