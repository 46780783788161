import { Component } from '@angular/core';
import { EIconPath } from '../../../../../../shared/enums/icons.enum';

@Component({
  selector: 'pp-dashlet-activity-delete',
  templateUrl: './dashlet-activity-delete.component.html',
  styleUrls: ['../dashlet-activity.component.scss'],
})
export class DashletActivityDeleteComponent {
  EIconPath = EIconPath;
}
