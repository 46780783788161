import { Component, EventEmitter, Output } from '@angular/core';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventFleetManagement,
} from 'src/app/project/services/analytics/google-analytics.consts';

@Component({
  selector: 'pp-asset-card-information-toggle',
  templateUrl: './asset-card-information-toggle.component.html',
  styleUrls: ['./asset-card-information-toggle.component.scss'],
})
export class assetCardInformationToggleComponent {
  @Output() ppToggle = new EventEmitter<boolean>();
  detailsSelected = true;

  constructor() {}

  toggleDetails(): void {
    this.detailsSelected = true;
    this.ppToggle.emit(this.detailsSelected);

    logEventInGTAG(EGoogleEventFleetManagement.ASSET_DETAILS_CLICK, {
      event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
    });
  }

  toggleLabels(): void {
    this.detailsSelected = false;
    this.ppToggle.emit(this.detailsSelected);

    logEventInGTAG(EGoogleEventFleetManagement.ASSET_LABEL_CLICK, {
      event_category: EGoogleEventCategory.FLEET_MANAGEMENT,
    });
  }
}
