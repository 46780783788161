import { cloneDeep } from 'lodash';
import { TShare } from 'src/app/project/modules/share/share.model';
import { TWorkspace } from '../workspace.model';

export type TAddShareToWorkspaceResponse = {
  [workspaceId: string]: Partial<TWorkspace>;
};

export function addSharesToWorkspace(_shares: TShare[]): TAddShareToWorkspaceResponse {
  const workspaces: TAddShareToWorkspaceResponse = {};
  const shares: TShare[] = cloneDeep(_shares); // data from request is read-only

  if (shares && shares.length > 0) {
    for (let i = 0; i < shares.length; i += 1) {
      workspaces[shares[i].workspaceId] = {
        share: shares[i],
      };
    }
  }

  return workspaces;
}
