import { createElement } from 'src/app/core/helpers/dom';
import { createTimelineResizeElement } from '../resize-element';
import { createTimelineCellElement } from '../body-cells/create-timeline-cell-element';
import { createHeaderNameElement } from './create-header-name-element';
import { createPointCountElement } from './create-point-count-element';

export function createTimelineLeftElementGroupHeader(_index: number): HTMLElement {
  const nameElement = createElement('div', {
    children: [createHeaderNameElement(_index), createPointCountElement(_index)],
  });

  const resizeElement = createTimelineResizeElement();

  const element = createTimelineCellElement({
    className: 'timeline__groupedTitleCell timelineColumnWidth',
    center: true,

    child: nameElement,
  });

  element.appendChild(resizeElement);

  return element;
}
