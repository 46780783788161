<div class="overlay" *ngIf="overlay.visible && account" [class.site--down]="ui.actionBar">
  <div class="overlay__top-bar" *ngIf="!annotationsActive">
    <p class="overlay__counter">{{ overlay.index + 1 }} / {{ overlay.elements.length }}</p>

    <div class="overlay__actions">
      <div
        ppTooltip
        class="overlay__actions__item"
        ppTitle="Annotate"
        *ngIf="
          !annotationsActive &&
          (workspace?.share.shareOption === EUserRole.OWNER ||
            workspace?.share.shareOption === EUserRole.SITE_ADMIN ||
            workspace?.share.shareOption === EUserRole.NORMAL ||
            workspace?.share.shareOption === EUserRole.ACCOUNT_ADMIN) &&
          overlay.elements[overlay.index]?.type === 'Image' &&
          !isMobile &&
          !isTablet
        "
        (click)="addAnnotation(true)"
      >
        <img class="overlay_actions_itemIcon" [src]="EIconPath.GALLERY_FREE_DRAWING" />
      </div>

      <pp-image-rotation
        ppTooltip
        ppTitle="Rotate Image"
        class="overlay__actions__item"
        *ngIf="
          !annotationsActive &&
          (workspace?.share.shareOption === EUserRole.OWNER ||
            workspace?.share.shareOption === EUserRole.SITE_ADMIN ||
            workspace?.share.shareOption === EUserRole.NORMAL ||
            workspace?.share.shareOption === EUserRole.ACCOUNT_ADMIN) &&
          overlay.elements[overlay.index]?.type === 'Image'
        "
        [ppImageId]="overlay.elements[overlay.index]?.id"
        [ppOverlayIndex]="overlay.index"
        [ppRotationAngle]="overlay.elements[overlay.index]?.rotationAngle"
        [ppImageName]="overlay.elements[overlay.index]?.fileName"
        [ppImageMimeType]="overlay.elements[overlay.index]?.mimeType"
        [ppImageCreatedOn]="overlay.elements[overlay.index]?.createdOn"
        (ppRotationAngleChanged)="rotateImage($event)"
        [ppPointId]="point._id"
      ></pp-image-rotation>

      <div
        ppTooltip
        *ngIf="overlay.elements[overlay.index]?.type === 'Image'"
        class="overlay__actions__item"
        [ngClass]="{ 'overlay_actions_item--active': isZoomed }"
        ppTitle="Zoom"
        (click)="zoomImage()"
      >
        <img class="overlay_actions_itemIcon" [src]="EIconPath.GALLERY_ZOOM" *ngIf="!isZoomed" />

        <div class="overlay_actions_itemIcon" *ngIf="isZoomed">
          <img class="" [src]="EIconPath.GALLERY_ZOOM_OUT" alt="" />
        </div>
      </div>

      <div ppTooltip class="overlay__actions__item" ppTitle="Download" (click)="downloadImage()">
        <img class="overlay_actions_itemIcon" [src]="EIconPath.GALLERY_DOWNLOAD" />
      </div>

      <div
        ppTooltip
        class="overlay__actions__item"
        ppTitle="Delete"
        (click)="deleteImage()"
        *ngIf="canDelete"
      >
        <img class="overlay_actions_itemIcon" [src]="EIconPath.GALLERY_DELETE" />
      </div>

      <div
        ppTooltip
        class="overlay__actions__item imageClose__btn"
        [ngClass]="{ 'overlay__btn--disabled': annotationsActive }"
        ppTitle="Close (Esc)"
        (click)="hide(); $event.stopPropagation()"
      >
        <img class="overlay_actions_itemIcon" [src]="EIconPath.GALLERY_CLOSE" />
      </div>
    </div>
  </div>

  <img
    ppTooltip
    class="overlay__btn--left"
    [ngClass]="{
      'overlay__btn--hidden': annotationsActive,
      'overlay__btn--disabled': overlay.elements.length === 1
    }"
    [src]="EIconPath.GALLERY_GO_LEFT"
    (click)="previous(); $event.stopPropagation()"
    ppTitle="Previous"
  />

  <img
    ppTooltip
    class="overlay__btn--right"
    [ngClass]="{
      'overlay__btn--hidden': annotationsActive,
      'overlay__btn--disabled': overlay.elements.length === 1
    }"
    [src]="EIconPath.GALLERY_GO_RIGHT"
    ppTitle="Next"
    (click)="next(); $event.stopPropagation()"
  />

  <div
    class="overlay__image-wrapper"
    *ngIf="overlay.elements[overlay.index]?.type === 'Image'"
    (click)="$event.stopPropagation()"
  >
    <canvas #zoomCanvasElement id="zoomCanvasElement" class="overlay_zoomCanvasElement"></canvas>

    <pp-image-annotations
      *ngIf="
        annotationsActive &&
        (workspace?.share.shareOption === EUserRole.OWNER ||
          workspace?.share.shareOption === EUserRole.SITE_ADMIN ||
          workspace?.share.shareOption === EUserRole.NORMAL ||
          workspace?.share.shareOption === EUserRole.ACCOUNT_ADMIN) &&
        overlay.elements[overlay.index]?.type === 'Image' &&
        !isMobile &&
        !isTablet
      "
      [ppImageId]="overlay.elements[overlay.index]?.id"
      [ppWidth]="overlay.elements[overlay.index]?.w"
      [ppHeight]="overlay.elements[overlay.index]?.h"
      [ppOverlayIndex]="overlay.index"
      [ppImageName]="overlay.elements[overlay.index]?.fileName"
      [ppImageCreatedOn]="overlay.elements[overlay.index]?.createdOn"
      [ppRotationAngle]="overlay.elements[overlay.index]?.rotationAngle"
      (ppGetButtonsStatusChange)="addAnnotation($event)"
      (ppAnnotationSaving)="updateAnnotationSavingState($event)"
      (ppAnnotationAdded)="updateImageWithAnnotation($event)"
      (ppRotationAngleChanged)="rotateImage($event)"
      [ppPointId]="point._id"
    >
    </pp-image-annotations>

    <img
      ppImage
      #imageElement
      (ppOnRendered)="onImageElementRendered($event)"
      id="imageElement"
      class="overlay__img"
      [ngClass]="{
        'overlay__img--rotate-90deg':
          tempRotation?.rotation || tempRotation?.rotation === 0
            ? tempRotation.rotation === 90
            : overlay.elements[overlay.index]?.rotationAngle === 90,
        'overlay__img--rotate-180deg':
          tempRotation?.rotation || tempRotation?.rotation === 0
            ? tempRotation.rotation === 180
            : overlay.elements[overlay.index]?.rotationAngle === 180,
        'overlay__img--rotate-270deg':
          tempRotation?.rotation || tempRotation?.rotation === 0
            ? tempRotation.rotation === 270
            : overlay.elements[overlay.index]?.rotationAngle === 270,
        'overlay__img--fullscreen': !annotationsActive
      }"
      [src]="loadedURL"
    />

    <img
      [src]="overlay.elements[overlay.index]?.base64src"
      (load)="loadImage(overlay.elements[overlay.index])"
      hidden
    />
  </div>

  <div
    *ngIf="overlay.elements[overlay.index]?.type === 'Image360'"
    (click)="$event.stopPropagation()"
  >
    <pp-gallery-overlay-spherical
      [ppImageId]="overlay.elements[overlay.index]?.id"
    ></pp-gallery-overlay-spherical>
  </div>

  <div
    class="overlay__video-wrapper"
    *ngIf="overlay.elements[overlay.index]?.type === 'Video'"
    (click)="$event.stopPropagation()"
  >
    <pp-gallery-overlay-video
      [ppVideo]="overlay.elements[overlay.index]"
    ></pp-gallery-overlay-video>
  </div>
</div>
