import { fabric } from 'fabric';

import { Injectable } from '@angular/core';
import { TAnnotationsCoords } from './image-annotation.model';

type TImageAnnotationsTextState = {
  left: number;
  top: number;
  angle: number;
  text: string;
};

@Injectable({
  providedIn: 'root',
})
export class ImageAnnotationsTextService {
  canvasFabric: fabric.Canvas = null;
  textFabric: fabric.IText = null;
  private textStates: TImageAnnotationsTextState[] = [];
  private textStatesCurrentIndex = 0;

  setCanvasFabric(canvasFabric: fabric.Canvas): void {
    this.canvasFabric = canvasFabric;
  }

  addText(color: string, newText: boolean, coordinates?: TAnnotationsCoords): void {
    this.textFabric = null;

    if (newText) {
      const textCoordinates = {
        x: coordinates.x,
        y: coordinates.y,
      };

      this.resetState();
      this.textFabric = this.makeText(color, +textCoordinates.x, +textCoordinates.y, 0, '');
      this.updateTextState(+textCoordinates.x, +textCoordinates.y, 0, '');

      this.canvasFabric.add(this.textFabric);
      this.canvasFabric.setActiveObject(this.textFabric);
      this.textFabric.enterEditing();
    } else {
      const lastState = this.textStates[this.textStatesCurrentIndex];

      this.textFabric = this.makeText(
        color,
        lastState.left,
        lastState.top,
        lastState.angle,
        lastState.text,
      );
      this.canvasFabric.add(this.textFabric);
    }
  }

  enterTextObjectEditing(): void {
    const activeObjects = this.canvasFabric.getActiveObjects();

    activeObjects.forEach((object) => {
      if (object.name === 'text') {
        object.enterEditing();
        object.hiddenTextarea.focus();
      }
    });
  }

  cancelEditing(): void {
    if (this.textFabric) {
      this.textFabric.exitEditing();
      this.textFabric = null;
    }
  }

  resetState(): void {
    this.textStates = [];
    this.textStatesCurrentIndex = -1;
  }

  private makeText(
    color: string,
    left: number,
    top: number,
    angle: number,
    text: string,
  ): fabric.IText {
    const t = new fabric.IText('Type something', {
      left: left,
      top: top,
      angle: angle,
      text: text,
      fontFamily: 'Source Sans Pro',
      fill: color,
      hasControls: false,
      lockUniScaling: true,
      originX: 'left',
      originY: 'top',
    });

    t.name = 'text';

    return t;
  }

  private updateTextState(left: number, top: number, angle: number, text: string): void {
    const index = this.textStates.length - 1;
    const lastState = this.textStates[index];

    if (!lastState || lastState.left !== left || lastState.top !== top || lastState.text !== text) {
      this.textStates.push({
        left: left,
        top: top,
        angle: angle,
        text: text,
      });

      this.textStatesCurrentIndex = index;
    }
  }
}
