import { Component, Input } from '@angular/core';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { SiteDataService, TSiteData } from '../../site/site-data.service';

@Component({
  selector: 'pp-site-table-placeholder',
  templateUrl: './site-table-placeholder.component.html',
  styleUrls: ['./site-table-placeholder.component.scss'],
})
export class SiteTablePlaceholderComponent {
  @Input() ppPlanWidth = 0;

  placeholders: number[];
  numberOfPlaceholders = 6;
  site: TSiteData = this.diteDataService.getSite();
  EIconPath = EIconPath;

  constructor(private diteDataService: SiteDataService) {
    this.placeholders = Array(this.numberOfPlaceholders)
      .fill(null)
      .map((x, i) => i);
  }
}
