<div class="costComparisonSetup">
  <section class="costComparisonSetup__header" *ngIf="ppCanEdit">
    <p class="costComparisonSetup__header-title">
      {{ 'cost_comparison_setup_header' | ppTranslate }}
    </p>

    <pp-button ppStyle="secondary" (ppAction)="editDashlet()" *ngIf="ppCanEdit">
      <span class="costComparisonSetup__button">
        <pp-icon
          [ppSrc]="EIconPath.ICON_NAV_COG_24"
          ppClass="setSize24"
          ppColor="inherit"
        ></pp-icon>
        {{ 'start_setup' | ppTranslate }}
      </span>
    </pp-button>
  </section>

  <section class="costComparisonSetup__icon">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 303 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <g clip-path="url(#clip0_732_27607)">
        <path d="M0 23H47.5V78H0V23Z" fill="#EAECF0" />
        <path d="M47.5 -18H95V78H47.5V-18Z" fill="#D0D5DD" />
      </g>
      <g clip-path="url(#clip1_732_27607)">
        <path d="M111 -6.5H155V66.5H111V-6.5Z" fill="#EAECF0" />
        <path d="M155 22.5H199V66.5H155V22.5Z" fill="#D0D5DD" />
      </g>
      <g clip-path="url(#clip2_732_27607)">
        <path d="M215 42H259V75H215V42Z" fill="#EAECF0" />
        <path d="M259 -15H303V75H259V-15Z" fill="#D0D5DD" />
      </g>
      <defs>
        <clipPath id="clip0_732_27607">
          <rect width="95" height="60" fill="white" />
        </clipPath>
        <clipPath id="clip1_732_27607">
          <rect width="88" height="60" fill="white" transform="translate(111)" />
        </clipPath>
        <clipPath id="clip2_732_27607">
          <rect width="88" height="60" fill="white" transform="translate(215)" />
        </clipPath>
      </defs>
    </svg>
  </section>
</div>
