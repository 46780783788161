import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LayoutModule } from 'src/app/project/modules/layout/layout.module';
import { SpinnerModule } from '../../spinner/spinner.module';
import { EndButtonComponent } from './end-button.component';

@NgModule({
  declarations: [EndButtonComponent],
  imports: [CommonModule, FormsModule, LayoutModule, SpinnerModule],
  exports: [EndButtonComponent],
})
export class EndButtonModule {}
