import { TCombinedCustomField } from '../../../custom-fields/combine-custom-fields/combined-custom-field.model';
import { TCustomFieldFilter, TFilters } from '../../../filters/site-filter.model';

export function checkIfFieldExists(
  filters: TFilters,
  combinedCustomField: TCombinedCustomField,
): TCustomFieldFilter {
  return filters.customFields.find((filterField) => {
    const filterSites = filterField.sites;
    const combinedIds = combinedCustomField.combinedIds;

    const customFieldIds = combinedIds.map((id) => id.customFieldId);

    const existingSite = filterSites.find((site) => customFieldIds.includes(site.filterId));
    return existingSite;
  });
}
