<video
  #videoContainer
  controls
  class="overlay__video"
  [src]="videoUrl"
  *ngIf="!isBrowserSafari"
></video>

<img
  ppImage
  id="img-tag"
  class="overlay__videoSafari"
  [ppSecureSrc]="'api/v1/video/' + this.ppVideo.id + '/file'"
  *ngIf="isBrowserSafari"
/>
