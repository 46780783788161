export const API_account_folders = (_accountId: string): string =>
  `api/v1/accounts/${_accountId}/folders`;

export const API_account_folder = (_accountId: string, _folderId: string): string =>
  `api/v1/accounts/${_accountId}/folders/${_folderId}`;

export const API_account_workspace_info = (_accountId: string): string =>
  `/api/v1/account/${_accountId}`;

export const API_account_all_workspaces = (
  _showHidden: boolean,
  showUnsubscribed: boolean,
): string =>
  `/api/v1/account/allworkspaces?showHidden=${_showHidden}&showUnsubscribed=${showUnsubscribed}`;

export const API_account_all_workspaces_simple = (
  _showHidden: boolean,
  showUnsubscribed: boolean,
): string =>
  `/api/v1/account/allworkspaces/simple?showHidden=${_showHidden}&showUnsubscribed=${showUnsubscribed}`;

export const API_account_update = (_accountId: string): string =>
  `api/v1/account/${_accountId}/simple-update`;

export const API_account_usage = (_accountId: string): string =>
  `/api/v1/account/${_accountId}/usage`;

export const API_account_subscription = (accountId: string): string =>
  `/api/v1/account-subscriptions/${accountId}`;

export const API_account_users_export = (accountId: string): string =>
  `/api/v1/account-export/${accountId}/shares`;

export const API_account_simulated_usage = (accountId: string): string =>
  `api/v1/account-subscriptions/${accountId}/simulated-usage`;
