<div id="tabContent" class="tabContent" *ngIf="user">
  <pp-grid [ppOptions]="{ bodyPadding: true }" *ngIf="workspaces">
    <ng-container title> {{ 'site_plan' | ppTranslate }} </ng-container>

    <ng-container titleBar-right>
      <div class="siteSettings__button-container">
        <pp-button
          ppStyle="secondary"
          ppSize="small"
          *ngIf="(uploadedImage || workspaces[workspaceId].siteImageRef) && !uploadedPdf"
          (ppAction)="downloadPlan()"
          >{{ 'download' | ppTranslate }}</pp-button
        >

        <pp-button
          ppId="updateSitePlanBtn--cancel"
          ppStyle="secondary"
          ppSize="small"
          *ngIf="newSitePlan || uploadedPdf"
          (ppAction)="cancelNewSitePlan()"
        >
          {{ 'cancel' | ppTranslate }}
        </pp-button>

        <pp-button
          [ppStyle]="newSitePlan ? 'secondary' : 'primary'"
          ppSize="small"
          *ngIf="!newSitePlan && !uploadedPdf"
        >
          <label
            class="settingsNewSite__upload-button"
            [class.settingsNewSite__upload-button--disabled]="offline"
            for="sitePlanUpload"
          >
            {{ (workspaces[workspaceId].siteImageRef ? 'update' : 'add') | ppTranslate }}
            {{ 'site_plan' | ppTranslate }}
          </label>

          <pp-upload
            id="sitePlanUpload--update"
            [ppId]="'sitePlanUpload'"
            (ppUpload)="setSitePlan($event)"
            ppAccept=".jpg, .jpeg, .png, .bmp, .pdf"
            [ppDisabled]="offline"
          >
          </pp-upload>
        </pp-button>

        <pp-site-plan-format (ppSelectFormat)="selectFormat($event)"></pp-site-plan-format>

        <pp-button
          ppId="updateSitePlanBtn--update"
          [ppStyle]="newSitePlan || uploadedPdf ? 'primary' : 'secondary'"
          ppSize="small"
          [ppDisabled]="!(newSitePlan || uploadedPdf) || offline"
          [ppProcess]="processingSitePlanUpdate"
          *ngIf="newSitePlan || uploadedPdf || uploadedPdf"
          (ppAction)="updateSitePlan()"
        >
          {{ 'confirm' | ppTranslate }}
        </pp-button>
      </div>
    </ng-container>

    <ng-container body>
      <div
        class="settingsSection__no-content"
        *ngIf="!workspaces[workspaceId].siteImageRef && !uploadedImage && !uploadedPdf"
      >
        {{ 'no_site_plan_found' | ppTranslate }}
      </div>

      <div
        *ngIf="
          !workspaces &&
          !workspaces[workspaceId] &&
          !workspaces[workspaceId].siteImageRef &&
          offline
        "
      >
        <h5 class="settingsSection__notAvailable">
          {{ 'data_unavailable_offline' | ppTranslate }}
        </h5>
      </div>

      <img
        #sitePlanImage
        ppImage
        class="siteSettingsPlan__site-plan"
        [ppSecureSrc]="'api/v1/images/' + planId + '/file/size/original'"
        *ngIf="workspaces[workspaceId].siteImageRef && !uploadedImage && !uploadedPdf"
        (load)="updateImageSize('site')"
      />

      <img
        #uploadedPlanImage
        ppImage
        class="siteSettingsPlan__site-plan"
        [src]="uploadedImage"
        *ngIf="uploadedImage"
        (load)="updateImageSize('upload')"
      />

      <div *ngIf="uploadedPdf">{{ 'pdf_preview_not_available' | ppTranslate }}</div>

      <div *ngIf="planWidth || planHeight" class="settingsSection__plan-details">
        <p>{{ 'image_width' | ppTranslate }}: {{ planWidth }}px</p>
        <p>{{ 'image_height' | ppTranslate }}: {{ planHeight }}px</p>
      </div>
    </ng-container>
  </pp-grid>
</div>
